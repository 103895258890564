// Customizable Area Start
import React from "react";
import Grid from '@material-ui/core/Grid';
import './StudentDocument.css'
import {
    Avatar,
    Box,
    TextField,
    Dialog,
    Typography,
    Divider,
    Button,
    Menu,
    FormLabel,
    withStyles
} from "@material-ui/core";
import ViewDocumentController, { Props } from './ViewDocumentController.web';
import moment from "moment";
import Spinner from "../../../shared/SideBar/src/Spinner";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { approveIcon, backArrowIcon, moreIcon, rejectIcon, resetIcon, trophy, zoomIcon } from "../assets";
import { ImagePreview } from "./ImagePreview.web";
import MesDialog from "./MesDialog.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
// Customizable Area Start
const formInputTextfield = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
    color: "#3f526d",
    fontSize: "1.125rem",
    fontFamily: "Open sans",
} as const;
// Customizable Area End
// Customizable Area Start
export class ViewDocument extends ViewDocumentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const today = moment();
        const disableFutureDt = (current: any) => {

            return current.isBefore(today);
        };
        // Customizable Area End
        // Customizable Area Start
        return (
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                className="UserDocBox"
            >
                {this.state.loadingSpinner && <Spinner spinnerModal={this.state.loadingSpinner} />}
                <Box
                    display="flex"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box>
                        <Typography 
                            className="UserViewDocBack"
                            onClick={() => this.backButton()}
                        >
                            <img src={backArrowIcon} className="UserViewDocBackArrow" /> Back
                        </Typography>
                    </Box>
                </Box>

                <Grid container className="UserDocContainer">
                    <Grid item xs={12}>
                        <Grid container className="UserDocAlignCenter">
                            <Grid item xs={12} md={8} className="UserViewDocDetails">
                                {Object.keys(this.state.userData).length > 0 && <>
                                    <Avatar
                                        src={this.state.userData?.attributes?.avatar}
                                        className="UserViewDocAvatar"
                                     />
                                    <div>
                                        <Typography 
                                            className={`UserViewDocName ${this.props.classes.heading_font}`}
                                        >{`${this.state.userData?.attributes?.first_name} ${this.state.userData?.attributes?.last_name}`}</Typography>
                                        {this.state.userData?.attributes?.type == "TeacherAccount" ? <Typography
                                            className={`UserViewDocClassDetails ${this.props.classes.heading_font}`}
                                        ><span className="SingleLineDiv UserViewDocClassName">{`${this.state.userData?.attributes?.school_account?.data.map((_userdata: any) => {
                                            return (
                                                _userdata.attributes?.class?.class_name
                                            )

                                        }).join(", ")}`}</span>{` | ${this.state.userData?.attributes?.type.replace("Account", '')}`}</Typography> :
                                            <Typography
                                                className={`UserViewDocSchool ${this.props.classes.heading_font}`}
                                            >{`${this.state.userData?.attributes?.school_account?.data[0]?.attributes?.class?.class_name} | ${this.state.userData?.attributes?.type.replace("Account", '')}`}</Typography>
                                        }
                                    </div>
                                </>}

                            </Grid>
                            {this.state.isEdit ? <>
                                <Grid item md={4} xs={12} className="UserViewDocBtnItems" >
                                    <Button
                                        fullWidth
                                        className="UserViewDocCancelBtn"
                                        onClick={() => this.setState({ isEdit: false ,changeStatus:[] }, () => this.get_document())}
                                    >
                                        <Typography
                                            className={`UserViewDocCancelBtnText ${this.props.classes.heading_font}`}
                                        >
                                            Cancel
                                        </Typography>
                                    </Button>
                                    <Button
                                        fullWidth
                                        className="UserViewDocEditBtn"
                                        disableFocusRipple
                                        onClick={() => this.setState({ notifyDialog: true })}
                                    >
                                        <Typography className={`UserViewDocEditBtnText ${this.props.classes.heading_font}`}>
                                            Save and Notify
                                        </Typography>
                                    </Button>
                                </Grid>
                            </> : <Grid item md={4} xs={12} className="UserViewDocBtnItems">
                                <Button
                                    fullWidth
                                    className="UserViewDocEditBtn"
                                    onClick={() => this.setState({ isEdit: true })}
                                >
                                    <Typography
                                        className={`UserViewDocEditBtnText ${this.props.classes.heading_font}`}
                                    >
                                        Edit
                                    </Typography>
                                </Button>
                            </Grid>}

                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="UserViewDocDividerGrid">
                        <Divider className="UserViewDocDivider" />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {this.state.userDocument.map((_item: any) => {
                                return (<Grid item md={6} sm={6} xs={12} lg={4} key={_item?.id}>
                                    <Grid item xs={12} className="UserDocumentItem">
                                        <img src={zoomIcon} className="UserDocumentZoomIcon" onClick={() => {
                                            this.setState({ previewData: _item?.attributes }, () => this.setState({ previewModal: true }))
                                        }} />
                                        {_item?.attributes?.upload_type === "image/jpeg" ||
                                            _item?.attributes?.upload_type === "image/jpg" ||
                                            _item?.attributes?.upload_type === "image/png" ? (
                                            <img
                                                className="UserDocumentZoomImg embedImg"
                                                src={_item?.attributes?.doc_attachment}
                                            />
                                        ) : (
                                            <embed
                                                type="text/pdf"
                                                src={_item?.attributes?.doc_attachment}
                                                height="100%"
                                                width="100%"
                                                onLoad={() => this.handleFileLoad()}
                                            />
                                        )}

                                        {this.state.isEdit && <Grid item xs={12} className="DocApproveRejectGrid">
                                            {_item?.attributes?.document_status === "approved" && <><img src={approveIcon} className="DocApprovedImg" />
                                                <Typography 
                                                    className={`DocApprovedText ${this.props.classes.heading_font}`}
                                                >Approved</Typography></>}
                                            {_item?.attributes?.document_status === "rejected" && <> <img src={rejectIcon} className="DocApprovedImg" />
                                                <Typography
                                                    className={`DocRejectedText ${this.props.classes.heading_font}`}
                                                >Rejected</Typography>
                                                <img src={moreIcon} className="DocMoreOptionImg" onClick={(event: any) => this.setState({ selectedId: _item.id, anchorEl: event.currentTarget })} />
                                            </>}
                                            {_item?.attributes?.document_status === "under_review" && <>
                                                <img src={rejectIcon} className="UnderReviewRejectIcon" onClick={() => this.setState({ selectedId: _item.id,selectedFileName:_item?.attributes?.name },()=>this.setState({rejectDialog: true }))} />
                                                <img src={approveIcon} className="UnderReviewApproveIcon" onClick={() => this.onApproveClick(_item.id)} />
                                            </>}
                                        </Grid>}
                                    </Grid>
                                    <Typography
                                        className={`UserViewDocFileText ${this.props.classes.heading_font}`}
                                    >{_item?.attributes?.name}</Typography>
                                </Grid>)
                            })}
                        </Grid>
                    </Grid>

                </Grid>

                {
                    this.state.previewModal && <ImagePreview previewModal={this.state.previewModal} previewImage={this.state.previewData} handleClose={this.handleClose} />
                }

                <Menu
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: "right",
                    }}
                    aria-haspopup="true"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={() => this.setState({ anchorEl: null })}
                    className="ChangeStatusModal"
                >
                    <Box className="ChangeStatusModalBox">
                        <div className="ChangeStatusDiv" onClick={() => {
                            this.setState({ statusDialog: true }, () => {
                                this.setState({ anchorEl: null })
                            })
                        }}>
                            <img src={resetIcon} className="ChangeStatusResetIcon" />
                            <span
                                className={`ChangeStatusText ${this.props.classes.heading_font}`}
                            >
                                Change Status
                            </span>
                        </div>

                    </Box>
                </Menu>


                {this.state.statusDialog && <MesDialog
                    title="Change Status "
                    deleteModal={this.state.statusDialog}
                    onCloseModal={() => {
                        this.setState({ statusDialog: false });
                    }}
                    confirmMes="Are you sure you want to change the status?"
                    description="This item will be reverted to review state."
                    cancelButtonText="Cancel"
                    okButtonText="Confirm"
                    deleteClick={() => this.onChangeClick(this.state.selectedId)} />
                }
                {this.state.notifyDialog && <MesDialog
                    title="Save and Notify"
                    deleteModal={this.state.notifyDialog}
                    onCloseModal={() => {
                        this.setState({ notifyDialog: false });
                    }}
                    confirmMes="Are you sure you want to save and notify?"
                    description="Document will be sent to user with there status."
                    cancelButtonText="Cancel"
                    okButtonText="Confirm"
                    deleteClick={() => this.onSave()} />
                }

                {/* Reject dialog */}
                <Dialog
                    PaperProps={{
                        style: { borderRadius: 8 }
                    }}
                    aria-labelledby="customized-dialog-title"
                    maxWidth="md"
                    open={this.state.rejectDialog}
                    onClose={() => this.setState({ rejectDialog: false, selectedId: '', rejectReason: '' , selectedFileName:'' })}
                    closeAfterTransition

                    disableAutoFocus={true}
                    className="RejectDocumentDialog"
                >
                    <Grid item xs={12} className="RejectDocumentDialogGrid">
                        <Grid item xs={12} className="RejectDocumentHeader">
                            <FormLabel className="RejectDocumentLabel">
                                Reject Document
                            </FormLabel>
                        </Grid>
                        <Divider className="RejectDocumentDivider" />
                        <Grid item xs={12} className="RejectFileNameGrid">
                            <Typography
                            className='SingleLineDiv RejectDocumentFileName'
                            >
                               {this.state.selectedFileName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid item xs={12} className="RejectDocReasonGrid">
                                <FormLabel className="RejectDocReasonText">
                                    Reason
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    id="standard-basic"
                                    placeholder="Write Reject Reason"
                                    style={formInputTextfield}
                                    variant="outlined"
                                    value={this.state.rejectReason}
                                    onKeyPress={(event: any) => {
                                        if (event.target.value.length >= 110) {
                                            event.preventDefault();
                                            event.stopPropagation();
                                        }
                                    }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = event.target.value.trim();

                                        if (value.length === 0) {
                                            this.setState({
                                                rejectDialogError: true,
                                                rejectDialogErrorMes: "Reason is required.",
                                            });
                                        } else {
                                            this.setState({
                                                rejectDialogError: false,
                                                rejectDialogErrorMes: "",

                                            });
                                        }
                                        if (
                                            value.length > 0
                                        ) {
                                            this.setState({ rejectReason: event.target.value })
                                        } else {
                                            this.setState({ rejectReason: value })
                                        }

                                    }}
                                    onBlur={() => {
                                        if (
                                            this.state.rejectReason === null ||
                                            this.state.rejectReason.length === 0
                                        ) {
                                            this.setState({
                                                rejectDialogError: true,
                                                rejectDialogErrorMes: "Reason is required.",
                                            });
                                        } else {
                                            this.setState({
                                                rejectDialogError: false,
                                                rejectDialogErrorMes: "",
                                            });
                                        }
                                    }}
                                    error={this.state.rejectDialogError}
                                    helperText={this.state.rejectDialogErrorMes}

                                    inputProps={{ style: { fontFamily: "Open sans", fontSize: '1.125rem', color: "#3f526d", padding: '1rem 1.875rem' } }}
                                    InputProps={{
                                        style: {
                                            fontSize: "1.125rem",
                                            color: '#3f526d',
                                            fontFamily: "OpenSans",
                                            opacity: 1,
                                            paddingLeft: 0,
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className="RejectDocBtnGrid">
                            <Button
                                style={{
                                    opacity: this.state.rejectReason.length === 0 ? '0.7' : '1',
                                }}
                                className="RejectDocBtn"
                                disabled={this.state.rejectReason.length === 0 ? true : false}
                                onClick={() => {
                                    this.onRejectClick(this.state.selectedId)
                                }}
                            >
                                <Typography className={`RejectDocBtnText ${this.props.classes.heading_font}`}>
                                    Reject
                                </Typography>
                            </Button>
                            <Button
                                className="CancelRejectDocBtn"
                                onClick={() => {
                                    this.setState({ rejectDialog: false, rejectReason: '', selectedId: '',selectedFileName:'' })
                                }}
                            >
                                <Typography className={`RejectDocCancelBtnText ${this.props.classes.heading_font}`}>
                                    Cancel
                                </Typography>
                            </Button>
                        </Grid>

                    </Grid>
                </Dialog>
            </Box>
        )
        // Customizable Area End
    }
}

export default HOCAuth(
    withStyles(themeCreateStyle)(ViewDocument as React.ComponentType<any>),
    "AdminAccount"
  );

// Customizable Area End
