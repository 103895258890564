// Customizable Area Start
import React, { Component } from 'react';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import AudioVisualiser from './AudioVisualiser';
// Customizable Area End

  // Customizable Area Start
  // Customizable Area End
export interface Props {
  navigation?: any;
  id?: string;
  location?: any;
  // Customizable Area Start
  audioStream: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  audioData: any;
  audioContext: any;
  analyser: any;
  dataArray: any;
  source: any;
  rafId: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AudioAnalyser extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      audioData: new Uint8Array(0),
      audioContext: '',
      analyser: '',
      dataArray: '',
      source: '',
      rafId: ''

    };
    this.frameTick = this.frameTick.bind(this);
  // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.initAudioAnalyser();
  }

  initAudioAnalyser = () => {
    const audioContext = new AudioContext;
    const analyser = audioContext.createAnalyser()
    analyser.fftSize = 256;
    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    const source = audioContext.createMediaStreamSource(this.props.audioStream);
    source.connect(analyser);
    this.setState({
      audioContext,
      analyser,
      dataArray,
      source,
      rafId: requestAnimationFrame(this.frameTick)
    });
  }


  frameTick() {
    this.state.analyser.getByteTimeDomainData(this.state.dataArray);
    this.setState({ audioData: this.state.dataArray, rafId: requestAnimationFrame(this.frameTick) });
  }

  async componentWillUnmount() {
    cancelAnimationFrame(this.state.rafId);
    this.state.analyser.disconnect();
    this.state.source.disconnect();
  }
  // Customizable Area End

  // Customizable Area Start
  render() {
    return <AudioVisualiser audioData={this.state.audioData} />;
  }
  // Customizable Area End
}

