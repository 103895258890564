// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef, RefObject } from "react";
import {
  checkForRequiredField,
  generateKey,
  computeChecksumMd5,
} from "./utils/common";
import { toast } from "react-toastify";
import { videoFileType, validFileType, imageFileType } from "./utils/constants";
import * as SparkMD5 from "spark-md5";
import axios from "axios";
import moment from 'moment'
import convert from "xml-js";

import {
  imgPasswordInVisible,
  imgPasswordVisible,
  locationPin,
  birthCertification,
  camera,
  aadhaar,
  certificate2,
  pngIcon,
  jpgIcon,
  pdfIcon,
  docIcon,
} from "./assets";
import { validEmail, validAadhar } from "./utils/common";
import { HISTORY } from "../../../components/src/comman";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
  navigation?: any;
  classes?: any;
  // id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export const screenName = {
  DocumentDetails: "DocumentDetails",
  UploadMandatoryDoc: "UploadMandatoryDoc",
  UploadingMandatoryDoc: "UploadingMandatoryDoc",
  UploadedMandatoryDoc: "UploadedMandatoryDoc",
  AdditionalDoc: "AdditionalDoc",
};

interface IUploadProps {
  responseJson: any;
  imageData: File;
  shopImagesData: string;
  visitingCardData: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  position: number;
  tabValue: number;
  teacherPersonalDetails: any;
  organisationList: any;
  teacherPersonalDetailsError: any;
  occupation: any;
  classes: any;
  badgesDetails: any;
  recentActivities: any;
  data: any;
  hovered: boolean;
  educationalDetails: any;
  educationalDetailsError: any;
  allSubjects: any;
  documentDetails: any;
  teacherDocuments: any;
  isFileUpload: boolean;
  notificationDetails: any;
  updatedNotification: any;
  openBadgeModal: boolean;
  fileUploaded: any;
  viewDocument: any;
  anchorEl: any;
  deleteModal: boolean;
  openCreateVideoModal: boolean;
  bulkFileUploaded: any;
  bulkFilesName: string;
  openFileUploadSuccessModal: boolean;
  createVideo: any;
  uploadedVideo: any;
  alertModal: boolean;
  alertMessage: string;
  fileDescription: string;
  showDescriptionField: boolean;
  uploadPercentage: number | null;
  showLoader: boolean;
  teacherAdditionalDocuments: any;
  uploadVideoPercentage: number | null;
  previewPhoto: boolean;
  previewItem: any;
  openPreviewVideoModal: boolean;
  videoAnchorEl: any;
  deleteVideoModal: boolean;
  displayScreen: string;
  disableUploadButton: boolean;
  descriptionError: boolean;
  descriptionErrorText: string;
  uploadSuccess: boolean;
  videoFile: any;
  profilePhotoSignedUrl: string;
  loadingSpinner: boolean;
  introVideoFile: any;
  addPhotoAnchorEl: any;
  deletePhotoModal: boolean;
  takePictureModal: boolean;
  profilePhotoFile: any;
  showSpinner: boolean;
  notificationDynamicDetails: any;
  isLockProfile: boolean;
  videoValidations: number;
  singleFileForBulkUpload: any;
  fileDataBase64: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  educationalDetailsCopy: any;
  apiGetTeacherPersonalInfoDetails: string = "";
  apiGetOrganinsationUnitList: string = "";
  apiGetOccupationList: string = "";
  apiGetClassesList: string = "";
  apiGetBadgesDetails: string = "";
  apiGetRecentActivitiesDetails: string = "";
  apiPutTeacherPersonalInfoDetails: string = "";
  apiGetEducationalDetails: string = "";
  apiPutTeacherEducationalInfoDetails: string = "";
  apigetAllSubjectsId: string = "";
  apiGetDocumentDetails: string = "";
  apiGetNotificationDetails: string = "";
  apiPutTeacherNotificationDetails: string = "";
  apiPostStudentUplaodDoc: string = "";
  apiDeleteTeacherDocumentsDetails: any;
  apiPreSignedID: string = "";
  apiVideoPreSignedID: any = [];
  apiVideoBlockUploadId: string = "";
  apiDeleteVideo: string = "";
  apiPhotoPreSignedID: string = "";
  apiUploadBlockId: string = "";
  apiDeleteProfilePicture: string = "";
  apiUploadFile: string = "";
  apiGetVideoValidations: string = "";
  apiBulkDocumentUploadId: any = [];
  apiBulkUploadBlockId: any = [];
  apiMandatoryDocumentUpload: string = "";
  clientSidePercentage = 0; // 25% weightage
  presignedPercentage = 0; // 25% weightage
  awsPercentage = 0; // 50% weightage

  defaultVideoValidations = 10;

  teacherPersonalDetailsCopy: any;
  readonly inputOpenFileRef: RefObject<HTMLInputElement>;
  readonly inputOpenVideoFileRef: RefObject<HTMLInputElement>;
  readonly inputOpenPhotoRef: RefObject<HTMLInputElement>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleModalClick = this.handleModalClick.bind(this);
    const teacherDocumentsFormat = [
      {
        id: "",
        name: "Residential Address Proof",
        imgSource: locationPin,
        fileName: "",
        isReviewed: false,
        reviewedDoc: "",
        imgHeight: 125,
        imgWidth: 104,
        doc_attachment: "",
        isUploaded: false,
      },
      {
        id: "",
        name: "Latest Passport Size Photo",
        imgSource: camera,
        fileName: "",
        isReviewed: false,
        reviewedDoc: "",
        imgHeight: 125,
        imgWidth: 164,
        doc_attachment: "",
        isUploaded: false,
      },
      {
        id: "",
        name: "Aadhar Card",
        imgSource: aadhaar,
        fileName: "",
        isReviewed: false,
        reviewedDoc: "",
        imgHeight: 124,
        imgWidth: 136,
        doc_attachment: "",
        isUploaded: false,
      },
      {
        id: "",
        name: "Domicile Certificate",
        imgSource: certificate2,
        fileName: "",
        isReviewed: false,
        reviewedDoc: "",
        imgHeight: 133,
        imgWidth: 164,
        doc_attachment: "",
        isUploaded: false,
      },
    ];

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.inputOpenFileRef = createRef();
    this.inputOpenVideoFileRef = createRef();
    this.inputOpenPhotoRef = createRef();
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      position: 0,
      hovered: false,
      tabValue: 0,
      teacherPersonalDetails: {},
      organisationList: [],
      descriptionError: false,
      descriptionErrorText: "",
      teacherPersonalDetailsError: {
        organization_unit: false,
        name: false,
        gender: false,
        aadhar_card_no: false,
        date_of_birth: false,
        email: false,
        preferred_classes: false,
        preferred_subjects: false,
        home_phone_no: false,
        residential_address: false,
        religion: false,
        written_language: false,
        office_phone_no: false,
        nationality: false,
        category: false,
        spoken_language: false,
        messages: {
          email: "",
        },
      },
      occupation: [],
      classes: [],
      badgesDetails: [],
      recentActivities: [],
      data: {},
      educationalDetails: {},
      educationalDetailsError: {
        profile_created: false,
        total_marks: false,
        qualification: false,
        specialization: false,
        institute: false,
        year_of_passing: false,
        messages: {},
      },
      allSubjects: [],
      documentDetails: {},
      teacherDocuments: teacherDocumentsFormat,
      isFileUpload: false,
      notificationDetails: {},
      updatedNotification: { data: [] },
      openBadgeModal: false,
      fileUploaded: {},
      viewDocument: {},
      anchorEl: null,
      deleteModal: false,
      openCreateVideoModal: false,
      bulkFileUploaded: [],
      bulkFilesName: "",
      openFileUploadSuccessModal: false,
      createVideo: {},
      uploadedVideo: {},
      alertModal: false,
      alertMessage: "",
      fileDescription: "",
      showDescriptionField: false,
      uploadPercentage: null,
      showLoader: false,
      teacherAdditionalDocuments: [],
      uploadVideoPercentage: null,
      previewPhoto: false,
      previewItem: {},
      disableUploadButton: false,
      openPreviewVideoModal: false,
      videoAnchorEl: null,
      deleteVideoModal: false,
      displayScreen: screenName.DocumentDetails,
      uploadSuccess: false,
      videoFile: null,
      loadingSpinner: false,
      introVideoFile: null,
      addPhotoAnchorEl: null,
      deletePhotoModal: false,
      takePictureModal: false,
      profilePhotoFile: null,
      profilePhotoSignedUrl: "",
      showSpinner: false,
      notificationDynamicDetails: [],
      isLockProfile: true,
      videoValidations: this.defaultVideoValidations,
      singleFileForBulkUpload: null,
      fileDataBase64: null,
    };
    this.getTeacherPersonalInfoDetails = this.getTeacherPersonalInfoDetails.bind(
      this
    );
    this.getOrganisationUnit = this.getOrganisationUnit.bind(this);
    this.getOccupation = this.getOccupation.bind(this);
    this.getClassesList = this.getClassesList.bind(this);
    this.getBadgesDetails = this.getBadgesDetails.bind(this);
    this.getRecentActivities = this.getRecentActivities.bind(this);
    this.getEducationalDetails = this.getEducationalDetails.bind(this);
    this.getAllSubjects = this.getAllSubjects.bind(this);
    this.getDocumentDetails = this.getDocumentDetails.bind(this);
    this.getNotificationDetails = this.getNotificationDetails.bind(this);
    this.userdata = this.userdata.bind(this);
    this.getVideoValidations = this.getVideoValidations.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End

  }

  // Customizable Area Start
  handleEnter = (action: string) => (event: any) => {
    const { educationalDetails } = this.state;
    if (event.key === 'Enter') {

      switch (action) {

        case "savePersonalInfo":
          this.savePersonalInfo(event);
          break;
        case "saveEducationalInfo":     
          this.saveEducationalInfo(event);
          break;
      }
      return false;
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    switch (apiRequestCallId) {
      case this.apiGetTeacherPersonalInfoDetails:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              this.setState({
                teacherPersonalDetails: responseJson.data,
                introVideoFile:
                  responseJson.data?.attributes?.intro_video?.data,
              });
              this.teacherPersonalDetailsCopy = responseJson?.data?.attributes;
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiGetOrganinsationUnitList:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              this.setState({ organisationList: responseJson?.data });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiGetOccupationList:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              this.setState({ occupation: responseJson.data });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiGetClassesList:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              this.setState({ classes: responseJson ? responseJson.data : [] });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiGetBadgesDetails:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              this.setState({ badgesDetails: responseJson.data });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiGetRecentActivitiesDetails:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              this.setState({ recentActivities: responseJson?.data });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiDeleteTeacherDocumentsDetails:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              this.setState({
                anchorEl: null,
                displayScreen: screenName.DocumentDetails,
                fileUploaded: {},
                viewDocument: {},
                deleteModal: false,
              });
              this.getDocumentDetails();
              toast.success("File Deleted Successfully.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiPutTeacherPersonalInfoDetails:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              if (responseJson.data) {
                toast.success(configJSON.ProfileModuleStatusMessage.PERSONAL_INFO_SUCCESS);
                const nameWords = responseJson?.data?.attributes?.name?.split(
                  " "
                );
                let lastName,
                  firstName = "";
                if (nameWords && nameWords.length > 1) {
                  lastName = nameWords[nameWords.length - 1];
                  nameWords.pop();
                  firstName = nameWords?.join(" ");
                } else {
                  firstName = responseJson?.data?.attributes?.name;
                }
                const userData = {
                  ...this.state.data,
                  first_name: firstName,
                  last_name: lastName,
                };
                this.teacherPersonalDetailsCopy =
                  responseJson?.data?.attributes;
                this.setState({
                  teacherPersonalDetails: responseJson.data,
                  introVideoFile:
                    responseJson.data?.attributes?.intro_video?.data,
                });
                localStorage.setItem("user_data", JSON.stringify(userData));
                const elName = document.getElementById("teacherName");
                if (elName) {
                  elName.innerText = responseJson?.data?.attributes?.name;
                }
                const elAdmissionClass = document.getElementById(
                  "teacherAdmissionClass"
                );
                if (elAdmissionClass) {
                  elAdmissionClass.innerText = responseJson?.data?.attributes
                    ?.admission_class
                    ? `(${responseJson?.data?.attributes?.admission_class})`
                    : "";
                }
              } else {
                toast.warning(responseJson?.message[0]);
              }
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiGetEducationalDetails:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              this.educationalDetailsCopy = responseJson?.data?.attributes;
              this.setState({
                educationalDetails: responseJson.data?.attributes,
              });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiPutTeacherEducationalInfoDetails:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              toast.success(
                " The educational info has been saved successfully."
              );
              this.educationalDetailsCopy = responseJson?.data?.attributes;
              this.setState({
                educationalDetails: responseJson.data?.attributes,
              });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apigetAllSubjectsId:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              let { allSubjects } = this.state;
              responseJson?.data?.map((teacher: any, index: number) => {
                allSubjects = [...allSubjects, teacher?.attributes?.subject_name];
              });
              this.setState({ allSubjects: allSubjects.sort() });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiGetDocumentDetails:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              const { teacherDocuments } = this.state;
              const attachedDocName: any = [];
              teacherDocuments.map((item: any) => {
                attachedDocName.push(item.name);
                const doc = responseJson?.data?.find(
                  (ele: any) => ele.attributes.name === item.name
                );
                if (doc) {
                  (item.id = doc.id),
                    (item.doc_attachment = doc.attributes.doc_attachment);
                  item.isUploaded = true;
                  item.fileName = doc.attributes.filename;
                  item.isReviewed =
                    doc.attributes.document_status;
                  // NOTE: "Approved" will come if docuemnt is approved
                  item.reviewedDoc = doc.attributes.doc_attachment;
                  item.upload_type = doc.attributes.upload_type;
                } else {
                  item.isUploaded = false;
                  item.fileName = "";
                }
              });
              this.setState({
                documentDetails: responseJson.data,
                teacherDocuments: teacherDocuments,
              });
              const filteredArray = responseJson?.data?.filter((item: any) => {
                return !attachedDocName.includes(item.attributes.name);
              });
              this.setState({ teacherAdditionalDocuments: filteredArray });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiGetNotificationDetails:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              let notifDetails: any = {};
              let notifDynamicDetails: any = [];
              responseJson.data.map((item: any) => {
                notifDetails = {
                  ...notifDetails,
                  [item.attributes.name]: item.attributes,
                };
                notifDynamicDetails = [...notifDynamicDetails, item.attributes];
              });

              this.setState({
                notificationDetails: notifDetails,
                notificationDynamicDetails: notifDynamicDetails,
              });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiPutTeacherNotificationDetails:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              toast.success(
                "Notification details have been updated successfully."
              );
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiPostStudentUplaodDoc:
        {
          if (responseJson != null) {
            if (!responseJson.error) {
              this.getDocumentDetails();
              this.setState({
                displayScreen: screenName.DocumentDetails,
                isFileUpload: false,
                fileUploaded: {},
                bulkFileUploaded: [],
                fileDescription: "",
              });
              this.apiBulkDocumentUploadId = [];
              toast.success("File Uploaded Successfully.");
            } else {
              this.parseApiErrorResponse(responseJson);
              toast.error(responseJson?.error)
            }
            this.setState({ disableUploadButton: false })
          }
          this.parseApiCatchErrorResponse(errorReponse);
          this.setState({ showSpinner: false });
        }
        break;
      case this.apiPreSignedID:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              const imageData: any = this.state.bulkFileUploaded[0];
              const updatedBulkDocument = [...this.state.bulkFileUploaded];
              const msg: Message = new Message(
                getName(MessageEnum.UploadMediaMessage)
              );
              updatedBulkDocument[0].messageId = msg.messageId;
              updatedBulkDocument[0].progress = 50;
              this.setState({ bulkFileUploaded: updatedBulkDocument, uploadPercentage: 50 })
              const uploadFileData: any = {
                responseJson: responseJson,
                imageData: imageData.file,
                messageId: msg.messageId
              };
              msg.addData(
                getName(MessageEnum.UploadMediaDataMessage),
                uploadFileData
              );
              this.apiMandatoryDocumentUpload = msg.messageId;
              runEngine.sendMessage(msg.id, msg)
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiVideoPreSignedID.indexOf(apiRequestCallId) === -1
        ? ""
        : apiRequestCallId:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              const file = this.state.videoFile
              const msg: Message = new Message(
                getName(MessageEnum.UploadMediaMessage)
              );
              const uploadFileData: any = {
                responseJson: responseJson,
                imageData: file,
                messageId: msg.messageId
              };
              msg.addData(
                getName(MessageEnum.UploadMediaDataMessage),
                uploadFileData
              );
              this.apiVideoBlockUploadId = msg.messageId;
              runEngine.sendMessage(msg.id, msg)
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiVideoBlockUploadId:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              const keyValue = JSON.parse(
                convert.xml2json(responseJson, {
                  spaces: 1,
                  compact: true,
                  ignoreComment: true,
                  alwaysChildren: true,
                  ignoreDeclaration: true,
                })
              );
              this.updatePersonalInfo(
                "intro_video",
                keyValue.PostResponse.Key._text
              );
              this.setState({
                loadingSpinner: false,
                createVideo: {},
                openCreateVideoModal: false,
                openFileUploadSuccessModal: true,
              });
            } else {
              this.parseApiErrorResponse(responseJson);
              this.updatePersonalInfo("intro_video", null);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;

      case this.apiDeleteVideo:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              this.teacherPersonalDetailsCopy.intro_video = undefined;
              this.setState({ introVideoFile: null });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiPhotoPreSignedID:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              const imageData = this.state.profilePhotoFile
            
              const msg: Message = new Message(
                getName(MessageEnum.UploadMediaMessage)
              );
              const uploadFileData: any = {
                responseJson: responseJson,
                imageData,
                messageId: msg.messageId
              };
              msg.addData(
                getName(MessageEnum.UploadMediaDataMessage),
                uploadFileData
              );
              this.apiUploadBlockId = msg.messageId;
              runEngine.sendMessage(msg.id, msg)
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiDeleteProfilePicture:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              const userData = {
                ...this.state.data,
                avatar_id: null,
                avatar: null,
              };
              localStorage.setItem("user_data", JSON.stringify(userData));
              this.userdata();
              HISTORY.push("/TeacherProfile");
              this.setState({ showSpinner: false });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiUploadFile:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              if (responseJson?.data?.[0]?.attributes.url) {
                const userData = {
                  ...this.state.data,
                  avatar_id: responseJson?.data?.[0]?.id,
                  avatar: responseJson?.data?.[0]?.attributes.url,
                };
                localStorage.setItem("user_data", JSON.stringify(userData));
                this.userdata();
                HISTORY.push("/TeacherProfile");
                this.setState({ showSpinner: false, takePictureModal: false });
                this.handleAddPhotoModalClose()
              } else {
                this.saveProfilePicture(this.state.profilePhotoSignedUrl)
              }
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiGetVideoValidations:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              const validation = responseJson?.data?.filter((el: any) => el.user_type === "Teacher")[0]?.max_duration;
              this.setState({
                videoValidations: validation ? validation : this.defaultVideoValidations,
              });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
          this.apiGetClassesList = "";
        }
        break;
      case this.apiUploadBlockId:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              const keyValue = JSON.parse(
                convert.xml2json(responseJson, {
                  spaces: 1,
                  compact: true,
                  ignoreComment: true,
                  alwaysChildren: true,
                  ignoreDeclaration: true,
                })
              );
              this.setState({ profilePhotoSignedUrl: keyValue.PostResponse.Key._text }, () => { this.saveProfilePicture(keyValue.PostResponse.Key._text) })
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);

        }
        break;
      case this.apiBulkDocumentUploadId.indexOf(apiRequestCallId) != -1
        && apiRequestCallId:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              const indexOfUploadDoc = this.apiBulkDocumentUploadId.indexOf(apiRequestCallId)
              const imageData: any = this.state.bulkFileUploaded[indexOfUploadDoc];
              const updatedBulkDocument = [...this.state.bulkFileUploaded];
          
              const msg: Message = new Message(
                getName(MessageEnum.UploadMediaMessage)
              );
              updatedBulkDocument[indexOfUploadDoc].messageId = msg.messageId;
              updatedBulkDocument[indexOfUploadDoc].progress = 50;
              this.setState({ bulkFileUploaded: updatedBulkDocument })
              const uploadFileData: any = {
                responseJson: responseJson,
                imageData: imageData.file,
                messageId: msg.messageId
              };
              msg.addData(
                getName(MessageEnum.UploadMediaDataMessage),
                uploadFileData
              );

              this.apiBulkUploadBlockId[this.apiBulkUploadBlockId.length] = msg.messageId;

              runEngine.sendMessage(msg.id, msg)
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiBulkUploadBlockId.indexOf(apiRequestCallId) === -1
        ? ""
        : apiRequestCallId:
        {
          if (responseJson != null) {
            if (!responseJson.errors) {
              const keyValue = JSON.parse(
                convert.xml2json(responseJson, {
                  spaces: 1,
                  compact: true,
                  ignoreComment: true,
                  alwaysChildren: true,
                  ignoreDeclaration: true,
                })
              );

              const { bulkFileUploaded } = this.state;
              const updatedDocIndex = this.state.bulkFileUploaded.findIndex((item: any) => item.messageId === apiRequestCallId)
              const updated = {
                ...bulkFileUploaded[updatedDocIndex],
                signedInId: keyValue.PostResponse.Key._text,
                progress: 100,
                fileData: this.state.fileDataBase64
              };
              bulkFileUploaded[updatedDocIndex] = updated;

              this.setState({
                bulkFileUploaded: [...bulkFileUploaded],
                fileDataBase64: null
              });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
      case this.apiMandatoryDocumentUpload:
        {
          if (responseJson != null) {
            const { bulkFileUploaded } = this.state;
            if (!responseJson.errors) {
              const keyValue = JSON.parse(
                convert.xml2json(responseJson, {
                  spaces: 1,
                  compact: true,
                  ignoreComment: true,
                  alwaysChildren: true,
                  ignoreDeclaration: true,
                })
              );
              bulkFileUploaded?.map((item: any, index: number) => {
                item.signedInId = keyValue.PostResponse.Key._text;
              });
              this.clientSidePercentage = 100;
              this.setState({ bulkFileUploaded: bulkFileUploaded, uploadSuccess: true, uploadPercentage: 100 }, () => {
                if (
                  this.state.uploadPercentage === 100 &&
                  this.state.displayScreen === "UploadingMandatoryDoc"
                ) {
                  this.setState({
                    displayScreen: screenName.UploadedMandatoryDoc,
                    uploadSuccess: false,
                  });
                }
                if (this.state.uploadPercentage === 100) {
                  this.setState({ uploadSuccess: false });
                }
              });
            } else {
              bulkFileUploaded[0].signedInId = "";
              bulkFileUploaded[0].url = "";
              bulkFileUploaded[0].checksum = "";
              bulkFileUploaded[0].file = {};
              this.resetLoader();
              this.setState({ bulkFileUploaded: bulkFileUploaded });
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
    }
    // Customizable Area End
  }

  handleSave = (file: File) => {
    console.log(file);
  };

  deleteUploadedDocument = () => {
    const { viewDocument } = this.state;
    this.setState({ deleteModal: false });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiDeleteTeacherDocumentsDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentDeleteDoc + "/" + viewDocument.id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
  };

  async componentDidMount() {
    super.componentDidMount();
    this.userdata();
    this.getTeacherPersonalInfoDetails();
    this.getOrganisationUnit();
    this.getOccupation();
    this.getClassesList();
    this.getBadgesDetails();
    this.getRecentActivities();
    this.getEducationalDetails();
    this.getAllSubjects();
    this.getDocumentDetails();
    this.getNotificationDetails();
    this.getVideoValidations();
  }

  handleModalClick = () => {
    this.setState({ openBadgeModal: !this.state.openBadgeModal });
  };

  getTeacherPersonalInfoDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetTeacherPersonalInfoDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentProfilePersonalInfo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOrganisationUnit = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetOrganinsationUnitList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentOrganisationUnit
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOccupation = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetOccupationList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.occupation
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getClassesList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetClassesList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.classes
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBadgesDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetBadgesDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentBadgesInfo + `?badge_type=assign_to`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRecentActivities = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetRecentActivitiesDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentRecentActivities
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getEducationalDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetEducationalDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentEducationalInfo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllSubjects = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetAllSubjectsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherAllSubjectGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDocumentDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDocumentDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentDocumentInfo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showOpenFileDlg = () => {
    this.inputOpenFileRef?.current?.click();
  };

  getNotificationDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetNotificationDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentNotificationInfo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleTansChange = {
    onChangeTab: (num: number) => {
      this.setState({ tabValue: num });
    },
  };

  inputEvent = (prop: any) => async (event: any) => {
    if (prop === 'date_of_birth') {
      const currentDate = moment().format('YYYY-MM-DD').replaceAll("-", "")
      const dateOfBirth = event.target.value.replaceAll("-", "")
      if (currentDate < dateOfBirth) {
        await this.setState({
          teacherPersonalDetailsError: {
            ...this.state.teacherPersonalDetailsError,
            date_of_birth: true,
            messages: {
              ...this.state.teacherPersonalDetailsError.messages,
              date_of_birth: "date of birth should be past date",
            },

          }
        })
      } else {
        const newVal = event.target.value;
        this.updatePersonalInfo(prop, newVal);
      }
    } else {

      const newVal = await event.target.value;
      this.updatePersonalInfo(prop, newVal);
    }

  };

  updatePersonalInfo = (prop: string, newVal: any) => {
    let { teacherPersonalDetailsError } = this.state;
    let newTeacherPersonalDetails = { ...this.state.teacherPersonalDetails };
    switch (prop) {
      case "bpl_certificate_no":
        break;
      case "bpl_certificate_issuing_authority":
        break;
      case "intro_video":
        break;
      case "aadhar_card_no":
        break;
      case "organization_unit":
        break;
      case "preferred_classes":
        break;
      case "office_phone_no":
        break;
      default:
        const isValid: any = this.personalInfoValidData(
          prop.replaceAll("_", " "),
          newVal
        );
        if (isValid) {
          teacherPersonalDetailsError = {
            ...teacherPersonalDetailsError,
            [prop]: isValid.error,
            messages: {
              ...teacherPersonalDetailsError.messages,
              [prop]: isValid.errorText,
            },
          };
        }
        break;
    }

    newTeacherPersonalDetails
      ? (newTeacherPersonalDetails.attributes = {
        ...newTeacherPersonalDetails?.attributes,
        [prop]: newVal,
      })
      : (newTeacherPersonalDetails = { attributes: { [prop]: newVal } });
    this.setState({
      teacherPersonalDetails: newTeacherPersonalDetails,
      teacherPersonalDetailsError: teacherPersonalDetailsError,
    });
  };

  handleEducationalInputChange = (prop: any) => (event: any) => {
    let newVal = event.target.value;
    let { educationalDetailsError } = this.state;

    if (prop === "specialization") {

      const oldValues = this.state.educationalDetails?.attributes
        ?.specialization
        ? this.state.educationalDetails?.attributes?.specialization
        : [];

      newVal = [...oldValues, ...newVal];
    }

    const newEducationalDetails = {
      ...this.state.educationalDetails,
      [prop]: newVal,
    };

    this.setState({
      educationalDetails: newEducationalDetails,
      educationalDetailsError: educationalDetailsError,
    });
  };

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };
  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  public setPosition(vaule: object) {
    this.setState(vaule);
  }

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  isPersonalInfoValid = () => {
    const { teacherPersonalDetails, teacherPersonalDetailsError } = this.state;

    let isValid = true;
    if (
      teacherPersonalDetails === undefined ||
      teacherPersonalDetails?.attributes === undefined
    ) {
      const errors = {
        name: true,
        gender: true,
        date_of_birth: true,
        email: true,
        home_phone_no: true,
        residential_address: true,
        religion: true,
        written_language: true,
        nationality: true,
        category: true,
        spoken_language: true,
        messages: {
          email: "Email is required.",
          name: "Name is required.",
          date_of_birth: "Date of birth is required.",
          home_phone_no: "Home phone no is required.",
          residential_address: "Residential address is required.",
          category: "Category is required.",
          gender: "Gender is required.",
          religion: "Religion is required.",
          written_language: "Written language is required.",
          nationality: "Nationality is required.",
          spoken_language: "Spoken language is required.",
        },
      };
      this.setState({ teacherPersonalDetailsError: errors });
      isValid = false;
    } else {
      for (const key in teacherPersonalDetailsError) {
        if (key !== "messages") {
          const isValidData: any = this.personalInfoValidData(
            key,
            teacherPersonalDetails?.attributes[key]
          );
          if (isValidData && isValidData.error) {
            teacherPersonalDetailsError[key] = isValidData.error;
            teacherPersonalDetailsError["messages"][key] =
              isValidData.errorText;
            isValid = false;
          } else teacherPersonalDetailsError[key] = false;
        }
      }

      this.setState({
        teacherPersonalDetailsError: teacherPersonalDetailsError,
      });
    }
    return isValid;
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleVideoModalClose = () => {
    this.setState({ videoAnchorEl: null });
  };

  handleVideoModalClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      videoAnchorEl: event.currentTarget,
    });
  };

  handleDeleteClose = () => {
    this.setState({ deleteModal: false });
  };

  handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      deleteModal: true,
    });
  };

  personalInfoValidData = (prop: string, newVal: string) => {
    switch (prop) {
      case "email":
        return validEmail(newVal);
      case "aadhar card no":
        return validAadhar(newVal);
      case "office_phone_no":
        break;
      case "aadhar_card_no":
        break;
      case "organization_unit":
        break;
      case "preferred_classes":
        break;
      case "preferred_subjects":
        break;
      case "office_phone_no":
        break;
      default:
        return checkForRequiredField(newVal, prop.replaceAll("_", " "));
    }
  };

  savePersonalInfo = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!this.isPersonalInfoValid()) {
      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token: localStorage.getItem("token"),
      };

      const httpBody = {
        data: {
          attributes: {
            name: this.state.teacherPersonalDetails.attributes.name?.trim(),
            date_of_birth: this.state.teacherPersonalDetails.attributes.date_of_birth,
            gender: this.state.teacherPersonalDetails.attributes.gender,
            email: this.state.teacherPersonalDetails.attributes.email?.trim(),
            home_phone_no: this.state.teacherPersonalDetails.attributes
              .home_phone_no,
            spoken_language: this.state.teacherPersonalDetails.attributes
              .spoken_language,
            written_language: this.state.teacherPersonalDetails.attributes
              .written_language,
            office_phone_no: this.state.teacherPersonalDetails.attributes
              .office_phone_no,
            residential_address: this.state.teacherPersonalDetails.attributes.residential_address?.trim(),
            nationality: this.state.teacherPersonalDetails.attributes
              .nationality,
            religion: this.state.teacherPersonalDetails.attributes.religion,
            category: this.state.teacherPersonalDetails.attributes.category?.trim(),
            intro_video:
              typeof this.state.teacherPersonalDetails?.attributes
                ?.intro_video === "string"
                ? this.state.teacherPersonalDetails?.attributes?.intro_video
                : null,
          },
        },
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.baseURL
      );

      this.apiPutTeacherPersonalInfoDetails = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.studentProfilePersonalInfo
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateProfileApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  saveEducationalInfo = (e: React.SyntheticEvent) => {
    e.preventDefault();
    
    if (
      !(
        JSON.stringify({
          ...this.state.educationalDetails,
          total_marks: this.state.educationalDetails?.total_marks?.toString(),
        }) ===
        JSON.stringify({
          ...this.educationalDetailsCopy,
          total_marks: this.educationalDetailsCopy?.total_marks?.toString(),
        })
      )
    ) {
      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token: localStorage.getItem("token"),
      };

      const httpBody = {
        data: {
          attributes: {
            profile_created: this.state.educationalDetails?.profile_created,
            total_marks: this.state.educationalDetails?.total_marks,
            qualification: this.state.educationalDetails?.qualification?.trim(),
            specialization: this.state.educationalDetails?.specialization,
            institute: this.state.educationalDetails?.institute?.trim(),
            year_of_passing: this.state.educationalDetails?.year_of_passing,
          },
        },
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.baseURL
      );

      this.apiPutTeacherEducationalInfoDetails = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.studentEducationalInfo
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateProfileApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  saveNotification = (updatedNotification: any) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = updatedNotification;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiPutTeacherNotificationDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentNotificationInfo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateProfileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNotificationInputChange = (
    notifiyName: string,
    propertyName: string
  ) => (event: any) => {
    const { notificationDetails, updatedNotification } = this.state;
    const newValue = !notificationDetails[notifiyName][propertyName];
    updatedNotification.data = [
      {
        id: notificationDetails[notifiyName].id,
        [propertyName]: newValue,
      },
    ];
    notificationDetails[notifiyName][propertyName] = newValue;
    this.setState({
      notificationDetails: notificationDetails,
      updatedNotification: updatedNotification,
    });
    this.saveNotification(updatedNotification);
  };

  inputDigestEvents = (
    event: any,
    notification_type: string,
    notifiyName: string,
    propertyName: string
  ) => {
    let newVal = event.target.value;
    const { notificationDetails, updatedNotification } = this.state;
    let alreadyUpdated = false;
    updatedNotification?.data?.map((element: any) => {
      if (
        element.id === notificationDetails[notification_type][notifiyName]?.id
      ) {
        alreadyUpdated = true;
        element[propertyName] = newVal;
      }
      this.setState({ notificationDetails: notificationDetails });
    });
    if (!alreadyUpdated) {
      updatedNotification.data.push({
        id: notificationDetails[notification_type][notifiyName]?.id,
        [propertyName]: newVal,
      });
    }

    notificationDetails[notification_type][notifiyName][propertyName] = newVal;
    this.setState({
      notificationDetails: notificationDetails,
      updatedNotification: updatedNotification,
    });
  };

  cancelNotificationChanges = () => {
    this.getNotificationDetails();
  };

  handleUpload = (
    event: React.ChangeEvent<any>,
    allowMultipleFiles: boolean,
    filesFromDragAndDrop: any,
    reDirectScreen?: string
  ) => {
    let files: any;
    let allowLoop: boolean = true;
    if (filesFromDragAndDrop !== null) {
      files = filesFromDragAndDrop;
    } else {
      files = event.target.files;
    }
    const keys = Object.keys(files);
    keys?.map((key: any) => {
      const file = files[key];
      if (!validFileType.includes(file?.type)) {
        this.setState({
          alertMessage: "Only .png, .jpg, .jpeg or .pdf are allowed.",
          alertModal: true,
        });
        allowLoop = false;
      }
    });

    if (allowLoop) {
      let percent = 0;
      this.setState({
        showDescriptionField: allowMultipleFiles,
        uploadPercentage: percent,
        displayScreen: reDirectScreen
          ? reDirectScreen
          : this.state.displayScreen,
        showLoader: true,
        bulkFileUploaded: [],
        fileUploaded: {},
        fileDescription: "",
      });
      let { bulkFileUploaded } = this.state;

      keys?.map(async (key: any) => {
        const file = files[key];
        if (allowMultipleFiles) {
          let fileUpload: any = {};
          fileUpload = {
            id: generateKey(file.name),
            fileName: file.name,
            name: file.name.split(".")[0],
            isExistingFile: false,
            fileSize: file.size,
            percentage: 0,
            file: file,
            content_type: file.type,
          };
          bulkFileUploaded = [...bulkFileUploaded, fileUpload];

          this.setState({ bulkFileUploaded: bulkFileUploaded });
          this.setState({ displayScreen: screenName.AdditionalDoc });
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.addEventListener("progress", (event) => {
            if (event.loaded && event.total) {
              this.clientSidePercentage = (event.loaded / event.total) * 100;
              this.calcTotalPercentage();
            }
          });

          const extension = file.name.split(".")[
            file.name.split(".").length - 1
          ];
          let fileUpload = {
            id: 0,
            reUploadDocId: bulkFileUploaded?.[0]?.id,
            file: file,
            fileName: bulkFileUploaded?.[0]?.isExistingFile
              ? bulkFileUploaded?.[0].name + "." + extension
              : file.name,
            name: bulkFileUploaded?.[0]?.name,
            isExistingFile: bulkFileUploaded?.[0]?.isExistingFile,
            fileSize: file.size,
            content_type: file.type,
          };

          bulkFileUploaded[0] = fileUpload;
          bulkFileUploaded = [...bulkFileUploaded];

          this.setState({
            bulkFileUploaded: bulkFileUploaded,
            fileUploaded: fileUpload,
            uploadPercentage: 25
          });
          this.getPreSignedId(fileUpload);
        }
      });
    }
  };

  calcTotalPercentage = () => {
    const totalPrc =
      this.clientSidePercentage / 4 +
      this.presignedPercentage / 4 +
      this.awsPercentage / 2;
    this.setState({ uploadPercentage: Math.round(totalPrc) });
    if (totalPrc === 100) {
      this.setState({
        displayScreen: screenName.UploadedMandatoryDoc,
        uploadSuccess: false,
      });
      this.resetLoader();
    }
  };

  resetLoader = () => {
    this.clientSidePercentage = 0;
    this.presignedPercentage = 0;
    this.awsPercentage = 0;
  };

  deleteUploadedFile = () => {
    const { bulkFileUploaded } = this.state;
    this.setState({
      uploadPercentage: 0,
      displayScreen: screenName.UploadMandatoryDoc,
      uploadSuccess: false,
      fileDescription: "",
      fileUploaded: {
        name: bulkFileUploaded[0].name,
        isExistingFile: bulkFileUploaded[0].isExistingFile,
      },
      bulkFileUploaded: [
        {
          name: bulkFileUploaded[0].name,
          isExistingFile: bulkFileUploaded[0].isExistingFile,
        },
      ],
    });
  };

  saveImageUsingURL = async (preSignedResponse: any) => {
    const { bulkFileUploaded } = this.state;

    let ind: number = 0;
    bulkFileUploaded?.map((item: any, index: number) => {
      if (item.signedInId === preSignedResponse?.signed_id) {
        ind = index;
      }
    });

    const header:any = {
      "Content-Type": preSignedResponse?.headers?.["Content-Type"],
      "Content-MD5": preSignedResponse?.headers?.["Content-MD5"],
      token: localStorage.getItem("token"),
    };

    const httpBody = bulkFileUploaded[ind].file;

    try {
      await axios({
        method: "put",
        url: preSignedResponse.url,
        data: httpBody,
        headers: header,
        onUploadProgress: (progressEvent) => {
          this.awsPercentage = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          this.calcTotalPercentage();
        },
      })
        .then((s3Response) => {
          this.setState({ uploadSuccess: true });
          if (
            this.state.uploadPercentage === 100 &&
            this.state.displayScreen === "UploadingMandatoryDoc"
          ) {
            this.setState({
              displayScreen: screenName.UploadedMandatoryDoc,
              uploadSuccess: false,
            });
          }
          if (this.state.uploadPercentage === 100) {
            this.setState({ uploadSuccess: false });
          }
        })
        .catch((s3error) => {
          toast.error("Something went wrong.");
          bulkFileUploaded.splice(ind, 1);
          this.setState({ bulkFileUploaded: bulkFileUploaded });
        });
    } catch (e) {
      toast.error("Something went wrong.");
      bulkFileUploaded[ind].signedInId = "";
      bulkFileUploaded[ind].url = "";
      bulkFileUploaded[ind].checksum = "";
      bulkFileUploaded[ind].file = {};
      this.resetLoader();
      this.setState({ bulkFileUploaded: bulkFileUploaded });
    }
  };

  saveUploadedFile = () => {
    const {
      bulkFileUploaded,
      fileDescription,
      showDescriptionField,
    } = this.state;
    if (fileDescription.length === 0 && showDescriptionField) {
      this.setState({
        descriptionError: true,
        descriptionErrorText: "Please add description.",
      });
      return false;
    }
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    if (bulkFileUploaded.length > 0 && bulkFileUploaded[0].signedInId) {
      if (!this.state.disableUploadButton) {
        this.setState({ disableUploadButton: true, showSpinner: true });
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          token: localStorage.getItem("token"),
          "school": data.school_id,
        };

        const bodyParams: any = [];
        bulkFileUploaded.map((file: any) => {
          const params = {
            attributes: {
              name: file.name,
              filename: file.fileName,
              description: fileDescription,
              doc_attachment: file.signedInId
            },
          };
          bodyParams.push(params);
        });

        const httpBody = { data: bodyParams };

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestbaseURLMessage),
          configJSON.baseURL
        );

        this.apiPostStudentUplaodDoc = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.studentDocumentUpload
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    } else {
      toast.error("Please upload file");
    }
  };

  saveReuploadedFile = () => {
    const { bulkFileUploaded } = this.state;
    const fileUploaded = bulkFileUploaded[0];
    if (fileUploaded.signedInId) {
      if (!this.state.disableUploadButton) {
        this.setState({ disableUploadButton: true });
        const user_data = localStorage.getItem("user_data");
        const data = JSON.parse(user_data || "{}");
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          token: localStorage.getItem("token"),
          school: data?.school_id,
        };

        const httpBody = {
          data: {
            attributes: {
              name: fileUploaded.name,
              filename: fileUploaded.fileName,
              doc_attachment: fileUploaded.signedInId,
            },
          },
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestbaseURLMessage),
          configJSON.baseURL
        );

        this.apiPostStudentUplaodDoc = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.studentReUploadDoc + "/" + fileUploaded.reUploadDocId
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    } else {
      toast.error("Please upload file");
    }
  };

  handleFileName = (event: any) => {
    const { fileUploaded } = this.state;
    const newVal = event.target.value;
    // TODO: Name should also go, and be fixed like "Additional Document"
    this.setState({
      fileUploaded: { ...fileUploaded, fileName: newVal },
    });
    this.setState({
      bulkFileUploaded: [{ ...fileUploaded, fileName: newVal }],
    });
  };

  uploadVideo = async (uploadVideoUrl: any, callback: any) => {
    this.setState({ loadingSpinner: true, introVideoFile: uploadVideoUrl });

    let videoFile: any = null;
    if (this.state.createVideo?.videoData) {
      videoFile = this.state.videoFile;
    } else {
      const uploadVideo: any = new Blob([uploadVideoUrl], {
        type: "video/mp4",
      });
      const file = this.convertBlobToFile(uploadVideo);
      this.setState({ videoFile: file });
      videoFile = file;
    }
    const videoDetails = {
      fileName: videoFile.name,
      fileSize: videoFile.size,
      content_type: videoFile.type,
    };

    this.getVideoPreSignedId(videoDetails);
  };

  convertBlobToFile(blob: any) {
    const fileName =
      this.state.teacherPersonalDetails?.attributes?.name.replace(/\s+/g, "") +
      "_self_video.mp4";
    return new File([blob], fileName, {
      type: blob.type,
    });
  }

  updateVideo = (intro_video: any) => {
    this.updatePersonalInfo("intro_video", intro_video);
    this.setState({
      createVideo: {},
      openCreateVideoModal: false,
      openFileUploadSuccessModal: true,
    });
  };

  deleteUploadedVideo = () => {
    const { introVideoFile } = this.state;

    this.setState({ deleteVideoModal: false });
    if (introVideoFile?.id) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("token"),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.baseURL
      );

      this.apiDeleteVideo = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteVideo + "/" + this.state.introVideoFile?.id
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else if (this.teacherPersonalDetailsCopy?.intro_video != undefined) {
      this.updatePersonalInfo(
        "intro_video",
        this.teacherPersonalDetailsCopy?.intro_video
      );
      this.setState({
        introVideoFile: this.teacherPersonalDetailsCopy?.intro_video?.data,
      });
    } else {
      this.updatePersonalInfo("intro_video", null);
      this.setState({ introVideoFile: null });
    }
  };

  showOpenVideoFileDlg = () => {
    this.inputOpenVideoFileRef?.current?.click();
  };

  handleVideoUpload = (
    event: React.ChangeEvent<any> | null,
    fileFromDragAndDrop: any
  ) => {
    let files: any;
    if (event !== null) {
      files = event?.target.files[0];
    } else {
      files = fileFromDragAndDrop;
    }

    if (!videoFileType.includes(files.type)) {
      this.setState({
        alertMessage: "Please Upload Valid Video",
        alertModal: true,
      });
    } else if (files.size > 524288000) {
      this.setState({
        alertMessage: "File size should not exceed 500MB",
        alertModal: true,
      });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.addEventListener("progress", async (event) => {
        if (event.loaded && event.total) {
          const newPercent = (event.loaded / event.total) * 100;
          this.setState({
            uploadVideoPercentage: Math.round(newPercent),
            openCreateVideoModal: false,
          });
          if (newPercent === 100) {
            this.setState({ videoFile: files });
            let blobURL = window.URL.createObjectURL(files);
            this.setState({
              createVideo: {
                isCreateUploadVideo: "Upload",
                videoData: blobURL,
              },
              openCreateVideoModal: false,
            });
            this.setState({ uploadVideoPercentage: null });
          }
        }
      });
    }
  };

  getImageSource = (fileName: string) => {
    const extension = fileName?.split(".").pop();

    if (extension?.toLowerCase() === "png") {
      return pngIcon;
    } else if (
      extension?.toLowerCase() === "jpg" ||
      extension?.toLowerCase() === "jpeg"
    ) {
      return jpgIcon;
    } else if (extension?.toLowerCase() === "pdf") {
      return pdfIcon;
    } else return docIcon;
  };

  getPreSignedId = async (fileUploadDetail: any) => {
    const { bulkFileUploaded } = this.state;
    const header = {
      token: localStorage.getItem("token"),
    };
    let formData = new FormData();
    formData.append('content_type', fileUploadDetail.content_type)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiPreSignedID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  computeChecksumMd5 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject("MD5 computation failed - error reading the file");
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  };

  getVideoPreSignedId = (videoUploadDetail: any) => {
    const header = {
      token: localStorage.getItem("token"),
    };

    let formData = new FormData();
    formData.append('content_type', videoUploadDetail.content_type)

    const httpBody = {
      filename: videoUploadDetail.fileName,
      byte_size: videoUploadDetail.fileSize,
      checksum: videoUploadDetail.checksum,
      content_type: videoUploadDetail.content_type,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiVideoPreSignedID[this.apiVideoPreSignedID.length] =
      requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    const result = runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveVideoUsingURL = async (preSignedResponse: any) => {
    const uploadURL = preSignedResponse.url.replace(/\/+$/, "");
    const urlFields = preSignedResponse.url_fields;

    if (uploadURL != null && uploadURL !== undefined) {
      const fileKey = urlFields.key.replace("${filename}", this.state.videoFile.name);

      const formData = new FormData();
      formData.append("key", fileKey);
      formData.append(
        "success_action_status",
        urlFields.success_action_status
      );
      formData.append("Content-Type", urlFields["Content-Type"]);
      formData.append(
        "Content-Disposition",
        urlFields["Content-Disposition"]
      );
      formData.append("policy", urlFields.policy);
      formData.append("x-amz-credential", urlFields["x-amz-credential"]);
      formData.append("x-amz-algorithm", urlFields["x-amz-algorithm"]);
      formData.append("x-amz-date", urlFields["x-amz-date"]);
      formData.append("x-amz-signature", urlFields["x-amz-signature"]);
      formData.append("file", this.state.videoFile);
      try {
        const s3Response = await axios({
          method: "post",
          url: uploadURL,
          data: formData,
        });
        if (s3Response.status === 201) {
          const keyValue = JSON.parse(
            convert.xml2json(s3Response.data, {
              spaces: 1,
              compact: true,
              ignoreComment: true,
              alwaysChildren: true,
              ignoreDeclaration: true,
            })
          );
          this.updatePersonalInfo(
            "intro_video",
            keyValue.PostResponse.Key._text
          );
          this.setState({
            loadingSpinner: false,
            createVideo: {},
            openCreateVideoModal: false,
            openFileUploadSuccessModal: true,
          });
        }
      } catch (e) {
        toast.error("Something went wrong.");
        this.updatePersonalInfo("intro_video", null);
      }
    }

  };

  handleAddPhotoModalClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      addPhotoAnchorEl: event.currentTarget,
    });
  };

  handleAddPhotoModalClose = () => {
    this.setState({ addPhotoAnchorEl: null });
  };

  deleteProfilePhoto = () => {
    this.setState({ deletePhotoModal: false, showSpinner: true });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiDeleteProfilePicture = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteVideo + "/" + this.state.data?.avatar_id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showOpenPhotoDlg = () => {
    this.inputOpenPhotoRef?.current?.click();
  };

  handlePhotoUpload = async (event: React.ChangeEvent<any>) => {
    this.setState({ addPhotoAnchorEl: null });
    const file = event.target.files[0];
    if (!imageFileType.includes(file?.type)) {
      this.setState({
        alertMessage: "Please upload valid file format: .png, .jpg, .jpeg",
        alertModal: true,
      });
      return;
    }
    this.setState({ profilePhotoFile: file, showSpinner: true });
    await this.computeChecksumMd5(file).then((md5: any) => {
      const fileDetails = {
        fileName: file.name,
        fileSize: file.size,
        checksum: md5,
        content_type: file.type,
      };
      this.getPhotoPreSignedId(fileDetails);
    });
  };


  handleWebcamPhotoUpload = async (file: File) => {
    this.setState({ addPhotoAnchorEl: null });
    this.setState({ profilePhotoFile: file });
    this.setState({ loadingSpinner: true });

    const fileDetails = {
      fileName: file.name,
      fileSize: file.size,
      content_type: file.type,
    };
    this.getPhotoPreSignedId(fileDetails);
  };

  getPhotoPreSignedId = (photoDetail: any) => {
    const header = {
      token: localStorage.getItem("token"),
    };
    let formData = new FormData();
    formData.append('content_type', photoDetail.content_type)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiPhotoPreSignedID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBulkPhotoPreSignedId = (file: any) => {
    this.setState({
      fileDataBase64: file.fileData
    });
    const header = {
      token: localStorage.getItem("token"),
    };
    let formData = new FormData();
    formData.append('content_type', file.content_type)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiBulkDocumentUploadId[this.apiBulkDocumentUploadId.length] = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveProfilePicture = (signedId: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: [
        {
          attachment: signedId,
          upload_for: "Profile picture",
        },
      ],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiUploadFile = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadFile
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  bulkUploadDocumentHandleDelete = (itemId: string) => {
    const { bulkFileUploaded } = this.state;
    const index = bulkFileUploaded.findIndex((item: any) => item.id === itemId);
    bulkFileUploaded.splice(index, 1);
    this.apiBulkDocumentUploadId.splice(index, 1)
    this.setState({ bulkFileUploaded: bulkFileUploaded });
    if (bulkFileUploaded?.length === 0) {
      this.setState({
        isFileUpload: false,
        fileUploaded: {},
        bulkFileUploaded: [],
        showLoader: false,
        uploadPercentage: 0,
      });
    }
  };

  getVideoValidations = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetVideoValidations = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.videoValidations
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
