// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import moment from "moment";
import { string } from "yup";
export const configJSON = require("./config.js");
// Customizable Area End
export interface Props {
    // Customizable Area Start
    popoverClose: any;
    datecalendarAnchorEl: any;
    navigation: any;
    type:any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    closeModal: boolean;
    data: any;
    datecalendarAnchorEl:any;
    date:any;
    // Customizable Area End
}
interface SS {
    id: any;
}


export default class DateCalendarScreenController extends BlockComponent<Props, S, SS> {

// Customizable Area Start
    apideleteGoalId: string = '';
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            closeModal: false,
            data: [],
            datecalendarAnchorEl:'',
            date:[],
        };
        this.userdata = this.userdata.bind(this);
        // Customizable Area End
    }

    async componentDidMount() {
        this.userdata();
        super.componentDidMount();
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);
// Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apideleteGoalId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        toast.success("The goal has been deleted successfully.")
                        this.modalhandleClose()
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }
    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }
    deletePost = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apideleteGoalId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getGoalsEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.popoverClose(this.state.closeModal)
    }
}
