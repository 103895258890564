// Customizable Area Start
import React from 'react';
import {
  Grid,
  Button,
  withStyles,
  Box,
} from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import Datetime from 'react-datetime';
import StudentTimeTableController, {
  ITimeTableEventsProps,
  Props,
} from './StudentTimeTableController.web';
import TimeTableEventWrapper from './CalendarCustomFile/TimeTableEventWrapper.web';
import WeekEvent from './CalendarCustomFile/WeekEvent.web';
import MonthEvent from './CalendarCustomFile/MonthEvent.web';
import CustomToolbar from './CalendarCustomFile/CustomToolbar.web';
import CustomHeader from './CalendarCustomFile/CustomHeader.web';
import CustomWeekHeader from './CalendarCustomFile/CustomWeekHeader.web';
import CustomTimeGutterHeader from './CalendarCustomFile/CustomTimeGutterHeader.web';
import './TimeTableEvents.css';
import './StudentTimeTable.web.css';
import ZoomMeeting from '../ZoomMeeting/ZoomMeeting.web';
import Spinner from '../../../shared/SideBar/src/Spinner';
import SelectedTab from '../SelectedTab.web';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
moment.locale('en-GB', {
  week: {
    dow: 1,
    doy: 1,
  },
});
const today = moment();

const localizer = momentLocalizer(moment);
const formats = {
  weekdayFormat: (date: any, culture: any, localizer: any) =>
    localizer.format(date, 'dddd', culture),
  dayFormat: (date: any, culture: any, localizer: any) =>
    localizer.format(date, 'dddd', culture),
  timeGutterFormat: (date: any, culture: any, localizer: any) => {
    return `${localizer.format(date, 'h A', culture)} - ${localizer.format(
      date.setHours(date.getHours() + 1),
      'h A',
      culture
    )}`;
  },
  dateFormat: (date: any, culture: any, localizer: any) =>
    localizer.format(date, 'DD MMM', culture),
};
// Customizable Area End


class StudentTimeTable extends StudentTimeTableController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  eventStyleGetter = () => (
    event: any,
    start: any,
    end: any,
    isSelected: any
  ) => {
    const lectureTime = moment(event.end).format('DD MMM YYYY HH:mma');
    const currentTime = moment();
    const duration = moment.duration(
      moment(event.end).diff(moment(event.start))
    );
    const hours = duration.asHours();
    const size: number = hours * 94;
    const style = {
      backgroundColor: event.hexBgColor,
      color: 'black',
      borderLeft: `6px solid ${event.borderColor}`,
      display: 'block',
      maxHeight: hours === 1 ? `${size}px` : `${size + 6}px`,
    };
    return {
      style: style,
    };
  };

  DayEventContainer = () => (props: any) => {
    const { event } = props;
    return (
      <TimeTableEventWrapper
        event={event as ITimeTableEventsProps}
        isTeacher={false}
        joinMeeting={() => {
          this.setState({ selectedEvent: event }, () =>
            this.handleOpenZoomMeeting(event)
          );
        }}
      />
    );
  };

  WeekEventContainer = () => (props: any) => {
    const { event } = props;
    return (
      <WeekEvent
        event={event as ITimeTableEventsProps}
        isTeacher={false}
        joinMeeting={() => {
          this.setState({ selectedEvent: event }, () =>
            this.handleOpenZoomMeeting(event)
          );
        }}
      />
    );
  };

  MonthEventContainer = () => (props: any) => {
    const { event } = props;
    return (
      <MonthEvent
        event={event as ITimeTableEventsProps}
        isTeacher={false}
        joinMeeting={() => {
          this.setState({ selectedEvent: event }, () =>
            this.handleOpenZoomMeeting(event)
          );
        }}
      />
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        {this.state.showSpinner && (
          <Spinner spinnerModal={this.state.showSpinner} />
        )}
        <Box className='StudentTimeTableMainTab'>
          <SelectedTab isTeacher={false} type={'Time Table'} />
        </Box>
        {!this.state.isStartMeeting ? (
          <Grid
            container
            className='StudentTimeTableStartMeetingMainGrid'
          >
            <Grid item xs={12}>
              <Grid container className='StudentTimeTableRowDirection'>
                <Grid item xs={12} sm={12} md={6} className='StudentTimeTableFlexContainer'>
                  <Grid container className='StudentTimeTableRowDirection'>
                    <Grid item xs={12} sm={3}>
                      <div
                        onClick={() => {
                          this.getStudentPeriods(
                            moment(new Date()).toDate(),
                            this.state.selectedView
                          );
                          this.setState({ date: moment(new Date()).toDate() });
                        }}
                      >
                        {moment(this.state.date, 'DD-MM-YYYY').isSame(
                          moment(),
                          'day'
                        ) ? (
                          <span
                            className={`StudentTimeTableBtn today-btn ${this.props.classes.timeTable_classAndSection_active_button_color
                              }`}
                          >
                            Today
                          </span>
                        ) : (
                          <span
                            className={`StudentTimeTableBtn today-btn ${this.props.classes.timeTable_classAndSection_active_border_color
                              }`}
                          >
                            Today
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid className="Attendance-flex" item xs={12} sm={9}>
                      <div
                        className='StudentTimeTableCalendarDiv'
                      >
                        <Datetime
                          dateFormat={'DD MMMM,YYYY'}
                          timeFormat={false}
                          strictParsing={true}
                          input={true}
                          value={moment(this.state.date, 'DD-MM-YYYY').format(
                            'DD MMMM, YYYY'
                          )}
                          initialValue={moment().format('DD MMMM ,YYYY')}
                          className="date-input-student"
                          inputProps={{
                            placeholder: 'Date of Birth',
                            className: 'Date_of_birth_calendar_Icon_register',
                            readOnly: true,
                          }}
                          closeOnSelect
                          onChange={(date) => {
                            this.setState({ date: date });
                            this.getStudentPeriods(
                              date,
                              this.state.selectedView
                            );
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className='content-center'
                  item
                  xs={12}
                  sm={12}
                  md={6}

                >
                  <Button
                    className={`StudentTimeTableDailyBtnDiv daily-btn ${this.state.selectedView === 'day'
                      ? this.props.classes.timeTable_classAndSection_active_button_color
                      : this.props.classes.timeTable_classAndSection_active_border_color
                      }`}
                    onClick={() => this.viewClick('day')}
                  >
                    Daily
                  </Button>
                  <Button
                    className={`StudentTimeTableWeeklyBtnDiv daily-btn ${this.state.selectedView === 'week'
                      ? this.props.classes.timeTable_classAndSection_active_button_color
                      : this.props.classes.timeTable_classAndSection_active_border_color
                      }`}
                    onClick={() => this.viewClick('week')}
                  >
                    Weekly
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className='StudentTimeTableCalendarDiv'
            >
              <Calendar
                formats={formats}
                events={this.state.calendarEvents}
                localizer={localizer}
                className="small-calendar"
                onShowMore={(event, date) =>
                  this.setState({
                    date: date,
                    selectedView: 'day',
                  })
                }
                showAllEvents={true}
                min={new Date(today.year(), today.month(), today.date(), 0)}
                max={new Date(today.year(), today.month(), today.date(), 23)}
                startAccessor="start"
                endAccessor="end"
                date={this.state.date}
                view={this.state.selectedView}
                views={['month', 'week', 'day']}
                defaultView={this.state.selectedView}
                style={{ minHeight: '100vh', overflowX: 'auto' }}
                eventPropGetter={this.eventStyleGetter()}
                timeslots={1}
                step={60}
                components={{
                  timeGutterHeader: CustomTimeGutterHeader,
                  month: {
                    event: this.MonthEventContainer(),
                    header: CustomHeader,
                  },
                  week: {
                    event: this.WeekEventContainer(),
                    header: CustomWeekHeader,
                  },
                  day: {
                    event: this.DayEventContainer(),
                  },
                  toolbar: CustomToolbar,
                }}
                dayLayoutAlgorithm="no-overlap"
              />
            </Grid>
          </Grid>
        ) : (
          <ZoomMeeting currentEvent={this.state.selectedEvent} />
        )}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(StudentTimeTable);
// Customizable Area End
