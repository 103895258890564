// Customizable Area Start
import React from "react";
import {
    Button,
    Grid,
    Typography,
    CircularProgress,
    FormHelperText,
    Box,
    TextField,
    InputAdornment
} from "@material-ui/core";
import SortingTypeQuestionController, {
    Props,
} from "./SortingTypeQuestionController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import {
    imageDragSVG,
    imageMarksMinusSVG,
    imageMarksPlusSVG,
    imgBackArrowSVG,
} from "../assets";
import QuestionMetaData from "../Components/QuestionMetaData.web";
import "../TeacherAssessment.web.css";
import QuestionSolution from "../Components/QuestionSolution.web";
import { invalidCharsForNumberType } from "../../../profiles/src/utils/common";
import AlertModal from "../../../alert/src/AlertModal.web";
import { HISTORY } from "../../../../components/src/comman";
import TextEditor from "./TextEditor/TextEditor.web";
import { withStyles } from "@material-ui/core/styles";
import QuestionSidebarCollapse from "./QuestionSidebarCollapse.web";
import QuestionPreviewModal from "../Components/QuestionPreviewModal.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import './AddQuestion.web.css';

// Customizable Area End
// Customizable Area Start
const formInputHintNumber = {
    width: "97%",
    borderRadius: "10px",
    // backgroundColor: "#f9f8fd",
} as const;
// Customizable Area End
export class SortingTypeQuestion extends SortingTypeQuestionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const {
            marks,
            negativeMarks,
            selectedCategoryQuestionData,
            sectiondata,
            questionList,
            possibleResponseList
        } = this.state;

        const dragTouchStart = (e: any) => {
            e.preventDefault();
        };

        const dragTouchMove = (e: any) => {
            e.stopPropagation();
        };

        const touchDropEndInternal = (e: any) => {
            e.preventDefault();
            const tempItemArray: any = [...this.state.questionList];
            const target = e.currentTarget;
            const div_id = target.id;

            let x = e.changedTouches[0].pageX - window.pageXOffset;
            let y = e.changedTouches[0].pageY - window.pageYOffset;
            let releaseTarget: any = document.elementFromPoint(x, y);

            let dropDestinationIndex = Array.from(releaseTarget?.children).findIndex(
                (child: any) => child.getBoundingClientRect()?.bottom > e?.changedTouches?.[0]?.clientY
            );
            let dropItemIndex = Array.from(releaseTarget?.children).findIndex(
                (child: any) => child.id === div_id
            );
            if (dropDestinationIndex === -1) return;
            else {
                const [reorderedItem] = tempItemArray.splice(dropItemIndex, 1);
                // Add dropped item
                tempItemArray.splice(dropDestinationIndex, 0, reorderedItem);
                // Update State
            }
            this.setState({ questionList: tempItemArray });
        };

        const touchDropEndInternalResponse = (e: any) => {
            e.preventDefault();
            const tempItemArray: any = [...this.state.possibleResponseList];
            const target = e.currentTarget;
            const div_id = target.id;

            let x = e.changedTouches[0].pageX - window.pageXOffset;
            let y = e.changedTouches[0].pageY - window.pageYOffset;
            let releaseTarget: any = document.elementFromPoint(x, y);

            let dropDestinationIndex = Array.from(releaseTarget?.children).findIndex(
                (child: any) => child.getBoundingClientRect()?.bottom > e?.changedTouches?.[0]?.clientY
            );
            let dropItemIndex = Array.from(releaseTarget?.children).findIndex(
                (child: any) => child.id === div_id
            );
            if (dropDestinationIndex === -1) return;
            else {
                const [reorderedItem] = tempItemArray.splice(dropItemIndex, 1);
                // Add dropped item
                tempItemArray.splice(dropDestinationIndex, 0, reorderedItem);
                // Update State
            }
            this.setState({ possibleResponseList: tempItemArray });
        };

        const drop = (e: any) => {
            e.preventDefault();
            const tempItemArray: any = [...this.state.questionList];
            const div_id = e.dataTransfer.getData("div_id");
            let dropDestinationIndex = Array.from(e.target.children).findIndex(
                (child: any) => child.getBoundingClientRect().bottom > e.clientY
            );
            let dropItemIndex = Array.from(e.target.children).findIndex(
                (child: any) => child.id === div_id
            );
            if (dropDestinationIndex === -1) return;
            else {
                const [reorderedItem] = tempItemArray.splice(dropItemIndex, 1);
                // Add dropped item
                tempItemArray.splice(dropDestinationIndex, 0, reorderedItem);
                // Update State
            }

            this.setState({ questionList: tempItemArray });
        };

        const dragOver1 = (e: any) => {
            e.preventDefault();
        };

        const dragStart = (e: any) => {
            const target = e.target;
            e.dataTransfer.setData("div_id", target.id);
        };

        const dragOver = (e: any) => {
            e.stopPropagation();
        };

        const dropResponse = (e: any) => {
            e.preventDefault();
            const tempItemArray: any = [...this.state.possibleResponseList];
            const div_id = e.dataTransfer.getData("div_id");
            let dropDestinationIndex = Array.from(e.target.children).findIndex(
                (child: any) => child.getBoundingClientRect().bottom > e.clientY
            );
            let dropItemIndex = Array.from(e.target.children).findIndex(
                (child: any) => child.id === div_id
            );
            if (dropDestinationIndex === -1) return;
            else {
                const [reorderedItem] = tempItemArray.splice(dropItemIndex, 1);
                // Add dropped item
                tempItemArray.splice(dropDestinationIndex, 0, reorderedItem);
                // Update State
            }

            this.setState({ possibleResponseList: tempItemArray, responseSequenceChanged: true });
        };

        const dragOver1Response = (e: any) => {
            e.preventDefault();
        };

        const dragStartResponse = (e: any) => {
            const target = e.target;
            e.dataTransfer.setData("div_id", target.id);
        };

        const dragOverResponse = (e: any) => {
            e.stopPropagation();
        };

        return (
            <>
                <div>
                    {this.state.showLoader && (
                        <Spinner spinnerModal={this.state.showLoader} />
                    )}
                    <Grid
                        container
                        className="marks_section"
                    >
                        <Grid
                            item
                            xs={12}
                            sm={10}
                            className="question_flex_column"
                        >
                            <Typography
                                className={`back_with_arrow ${this.props.classes.bodyText_font}`}
                            >
                                <span onClick={HISTORY.goBack}>
                                    <img
                                        src={imgBackArrowSVG}
                                        className="question_back_arrow"
                                    ></img>
                                    Back
                                </span>

                                <span
                                    className={`${this.props.classes.bodyText_font} textlabel_breadcrum`}
                                >
                                    <span className="text-style-light">
                                        {this.state.editButton
                                            ? "Edit question "
                                            : "Create new question "}
                                    </span>
                                    (
                                    {selectedCategoryQuestionData?.question_category_id === 1 ? (
                                        <>Objective</>
                                    ) : (
                                        <>Subjective</>
                                    )}{" "}
                                    - {selectedCategoryQuestionData?.name?.replaceAll("_", " ")})
                                </span>
                            </Typography>

                            <div className="marks_wrap_div">
                                <div className="marks_div">
                                    <Typography
                                        className={`question_marks_text ${this.props.classes.subHeading_font}`}
                                    >
                                        Marks
                                    </Typography>
                                    <div>
                                        <div
                                            className={this.state.marksError ? "view_marksbox red_border" : "view_marksbox none_border"}
                                        >
                                            <img
                                                src={imageMarksPlusSVG}
                                                className="image_marks"
                                                onClick={this.plusMarksClick}
                                            ></img>
                                            <input
                                                placeholder=""
                                                type="number"
                                                step="0.25"
                                                min="0.00"
                                                onChange={(event) => {
                                                    const value: number = parseFloat(
                                                        parseFloat(event.target.value)
                                                            .toFixed(3)
                                                            .slice(0, -1)
                                                    );
                                                    if (value > 0 || marks > 0) {
                                                        this.setState({ marksError: false });
                                                    } else {
                                                        this.setState({
                                                            marksError: true,
                                                            marks: 0,
                                                            marksErrorText: "Please enter marks.",
                                                        });
                                                    }
                                                    if (value < this.state.sectiondata?.remaining_marks) {
                                                        this.setState({ marks: value, marksError: false });
                                                    } else if (
                                                        value ==
                                                        this.state.sectiondata?.remaining_marks - 1
                                                    ) {
                                                        this.setState({ marks: value, marksError: false });
                                                    }
                                                    if (value >= this.state.negativeMarks) {
                                                        this.setState({
                                                            marks: value,
                                                            negmarksError: false,
                                                        });
                                                    } else {
                                                        this.setState({
                                                            marks: value,
                                                            negmarksError: true,
                                                        });
                                                    }

                                                    if (this.state.editButton) {
                                                        if (
                                                            value >
                                                            this.state.sectiondata?.remaining_marks +
                                                            HISTORY.location.state?.questionData?.attributes
                                                                ?.marks
                                                        ) {
                                                            this.setState({
                                                                marks: value,
                                                                marksError: true,
                                                                marksErrorText:
                                                                    "Marks not greater than section marks.",
                                                            });
                                                        } else {
                                                            this.setState({
                                                                marks: value,
                                                            });
                                                        }
                                                    } else {
                                                        if (
                                                            value > this.state.sectiondata?.remaining_marks
                                                        ) {
                                                            this.setState({
                                                                marks: value,
                                                                marksError: true,
                                                                marksErrorText:
                                                                    "Marks not greater than section marks.",
                                                            });
                                                        } else {
                                                            this.setState({
                                                                marks: value,
                                                            });
                                                        }
                                                    }
                                                }}
                                                onKeyPress={(event) => {
                                                    if (invalidCharsForNumberType.includes(event.key)) {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                    }
                                                }}
                                                value={marks}
                                                className={`question_marks_input ${this.props.classes.bodyText_font}`}
                                            />
                                        </div>
                                        {this.state.marksError && (
                                            <FormHelperText className="red_text">
                                                {this.state.marksErrorText}
                                            </FormHelperText>
                                        )}
                                    </div>
                                </div>

                                {this.state.assessmentHasNegativeMarking && (
                                    <div className="marks_div">
                                        <Typography
                                            className={`question_marks_text ${this.props.classes.subHeading_font}`}
                                        >
                                            Negative Marks
                                        </Typography>
                                        <div
                                            className={this.state.negmarksError ? "view_marksbox red_border" : "view_marksbox none_border"}
                                        >
                                            <img
                                                src={imageMarksMinusSVG}
                                                className="image_marks"
                                                onClick={this.minusMarksClick}
                                            ></img>
                                            <input
                                                placeholder=""
                                                type="number"
                                                step="0.25"
                                                min="0.00"
                                                onChange={(event) => {
                                                    const value: number = parseFloat(
                                                        parseFloat(event.target.value)
                                                            .toFixed(3)
                                                            .slice(0, -1)
                                                    );
                                                    if (value > this.state.marks) {
                                                        this.setState({ negmarksError: true });
                                                    } else {
                                                        this.setState({ negmarksError: false });
                                                    }
                                                    this.setState({ negativeMarks: value });
                                                }}
                                                onKeyPress={(event) => {
                                                    if (invalidCharsForNumberType.includes(event.key)) {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                    }
                                                }}
                                                value={negativeMarks}
                                                className={`question_marks_input ${this.props.classes.bodyText_font}`}
                                            />
                                        </div>
                                        {this.state.negmarksError && (
                                            <FormHelperText className="red_text">
                                                Negative marking not greater then marks.
                                            </FormHelperText>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={2} className="remaining_section_marks">
                            <Typography
                                className={`remaining_marks ${this.props.classes.subHeading_font}`}
                            >
                                Remaining Section Marks
                            </Typography>

                            <div className="circular_div">
                                <CircularProgress
                                    variant="static"
                                    className="grey_circular"
                                    size={92}
                                    thickness={2}
                                    value={100}
                                />
                                <CircularProgress
                                    variant="static"
                                    className="colored_circular"
                                    size={92}
                                    value={this.state.remainSectionMarkPr}
                                    thickness={2}
                                />
                                <Typography
                                    className={`circular_marks_text ${this.props.classes.bodyText_font}`}
                                >
                                    {(
                                        sectiondata?.section_marks - sectiondata?.remaining_marks
                                    ).toFixed(2)}
                                    /{sectiondata?.section_marks}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Typography
                        className={`question_text ${this.props.classes.subHeading_font}`}
                    >
                        Question
                    </Typography>
                    <div ref={this.questionEditorRef}>
                        <TextEditor
                            myref={this.questionEditorRef}
                            handleChange={(value: any) => {
                                this.setState({ question: value }, () => {
                                    if (
                                        this.state.question === null ||
                                        this.state.question === ""
                                    ) {
                                        this.setState({ questionError: true });
                                    } else {
                                        this.setState({ questionError: false });
                                    }
                                });
                            }}
                            editorData={
                                HISTORY.location.state?.questionData?.attributes?.title
                                    ? HISTORY.location.state?.questionData?.attributes?.title
                                    : ""
                            }
                            isEdit={this.state.editButton}
                        />
                    </div>

                    {this.state.questionError && (
                        <FormHelperText className="question_error">
                            Please enter question.
                        </FormHelperText>
                    )}

                    <div className="match_type_div">
                        <Typography
                            className={`match_list_text ${this.props.classes.subHeading_font}`}
                        >
                            List
                        </Typography>
                        <div
                            onDrop={drop}
                            onDragOver={dragOver1}
                            className="match_questions_list"
                        >
                            {questionList
                                ?.filter((item: any) => !item._destroy)
                                ?.map((data: any, index: number) => (
                                    <div
                                        className="match_question_div"
                                        draggable
                                        id={`question-${data.questionId}`}
                                        onDragStart={dragStart}
                                        onDragOver={dragOver}
                                    >
                                        <TextField
                                            id="standard-basic"
                                            placeholder={`Choice ${String.fromCharCode(
                                                65 + index
                                            )}`}
                                            style={formInputHintNumber}
                                            variant="outlined"
                                            defaultValue={data?.question}
                                            value={data?.question}
                                            inputProps={{
                                                style: {
                                                    fontFamily: "Open sans",
                                                    fontSize: 15.8,
                                                    color: "#03014c",
                                                },
                                            }}
                                            onChange={() =>
                                                this.questionOnchange(event, data.questionId)
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img
                                                            id={`question-${data.questionId}`}
                                                            src={imageDragSVG}
                                                            className="match_drag_icon"
                                                            onClick={() => { }}
                                                            onTouchStart={dragTouchStart}
                                                            onTouchMove={dragTouchMove}
                                                            onTouchEnd={(e: any) => touchDropEndInternal(e)}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <CloseIcon
                                            className="remove_match_icon"
                                            onClick={() =>
                                                this.removeQuestionOnClick(data.questionId, index, data.question)
                                            }
                                        />
                                    </div>
                                ))}
                        </div>
                        <Button
                            onClick={this.addQuestionOnClick}
                            className={`add_new_match_btn ${this.props.classes.outline_button_no_hover}`}
                        >
                            <AddIcon className="add_new_match_icon" />
                            Add New
                        </Button>
                    </div>

                    <div className="match_type_div">
                        <Typography
                            className={`match_list_text ${this.props.classes.subHeading_font}`}
                        >
                            Set Correct Answer(s)
                        </Typography>
                        <div
                            onDrop={dropResponse}
                            onDragOver={dragOver1Response}
                            className="match_responses_list"
                        >
                            {possibleResponseList
                                ?.filter((item: any) => !item._destroy)
                                ?.map((data: any, index: number) => (
                                    <div
                                        className="match_question_div"
                                        draggable
                                        id={`response-${data.questionId}`}
                                        onDragStart={dragStartResponse}
                                        onDragOver={dragOverResponse}
                                    >
                                        <TextField
                                            id="standard-basic"
                                            placeholder={`Choice ${String.fromCharCode(
                                                65 + index
                                            )}`}
                                            style={formInputHintNumber}
                                            variant="outlined"
                                            disabled
                                            value={data?.question}
                                            inputProps={{
                                                style: {
                                                    fontFamily: "Open sans",
                                                    fontSize: 15.8,
                                                    color: "#03014c",
                                                },
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img
                                                            id={`response-${data.questionId}`}
                                                            src={imageDragSVG}
                                                            className="match_drag_icon"
                                                            onClick={() => { }}
                                                            onTouchStart={dragTouchStart}
                                                            onTouchMove={dragTouchMove}
                                                            onTouchEnd={(e: any) => touchDropEndInternalResponse(e)}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>

                    <QuestionSolution
                        hintAnswer={this.state.hintAnswer}
                        hintAnswerOnchange={(value: any, id: any) => {
                            let tempData = this.state.hintAnswer;
                            const index = this.state.hintAnswer.findIndex(
                                (res: any) => res.hintId === id
                            );
                            tempData[index].hint = value.trim();
                            this.setState({
                                hintAnswer: tempData,
                            });
                        }}
                        explaination={this.getExplanation()}
                        getPreSignedValue={(
                            preSignedId: string,
                            typeofAttachment: string,
                            blobUrl: string
                        ) => {
                            if (typeofAttachment === "hints") {
                                let tempData = this.state.hintAnswer;
                                tempData[0].files = [preSignedId];
                                tempData[0].src = blobUrl;
                                this.setState({
                                    hintAnswer: tempData,
                                    hintId: preSignedId,
                                });
                            } else {
                                this.setState({ solutionImageSignedId: preSignedId });
                            }
                        }}
                        explainationOnchange={(value: any) => {
                            this.setState({
                                explaination: value,
                            });
                        }}
                        attachmentExplanationUrl={this.state.attachmentExplanationUrl}
                        hintsExplanationUrl={this.state.hintsExplanationUrl}
                        removeSolutionImage={(attachmentType: string) => {
                            if (attachmentType === "hints") {
                                this.setState({
                                    hintsExplanationUrl: "",
                                    hintId: null,
                                });
                            } else {
                                this.setState({
                                    attachmentExplanationUrl: "",
                                    solutionImageSignedId: null,
                                });
                            }
                        }}
                    />
                    <QuestionMetaData
                        selectedSubject={this.state.selectedSubject}
                        onChangeSubject={(e: any) => {
                            this.setState({ selectedSubject: e.target.value });
                        }}
                        chapterListData={this.state.chapterListData}
                        selectedChapter={this.state.selectedChapter}
                        onChangeChapter={(e: any) => {
                            this.setState(
                                {
                                    selectedChapter: e.target.value,
                                    metaDataErrors: {
                                        ...this.state.metaDataErrors,
                                        chapter: false,
                                    },
                                },
                                () => this.get_unit_list(this.state.selectedChapter, true)
                            );
                        }}
                        unitListData={this.state.unitListData}
                        selectedUnit={this.state.selectedUnit}
                        onChangeUnit={(e: any) => {
                            this.setState({
                                selectedUnit: e.target.value,
                                metaDataErrors: { ...this.state.metaDataErrors, unit: false },
                            });
                        }}
                        selectedSkill={this.state.selectedSkill}
                        onChangeSkill={(e: any) => {
                            this.setState({
                                selectedSkill: e.target.value,
                                metaDataErrors: { ...this.state.metaDataErrors, skill: false },
                            });
                        }}
                        selectedProficiency={this.state.selectedProficiency}
                        onChangeProficiency={(e: any) => {
                            this.setState({
                                selectedProficiency: e.target.value,
                                metaDataErrors: {
                                    ...this.state.metaDataErrors,
                                    proficiency: false,
                                },
                            });
                        }}
                        selectedDifficultyLevel={this.state.selectedDifficultyLevel}
                        onChangeDifficultyLevel={(e: any) => {
                            this.setState({
                                selectedDifficultyLevel: e.target.value,
                                metaDataErrors: {
                                    ...this.state.metaDataErrors,
                                    difficultyLevel: false,
                                },
                            });
                        }}
                        keyword={this.state.keyword}
                        keywordsList={this.state.keywordsList}
                        onChangeKeywords={this.onChangeKeywords}
                        onBlurKeywords={this.onBlurKeywords}
                        onKeyDownKeywords={this.onKeyDownKeywords}
                        handleDeleteKeyword={this.handleDeleteKeyword}
                        metaDataErrors={this.state.metaDataErrors}
                        selectedBloomTaxonomy={this.state.selectedBloomTaxonomy}
                        onChangeBloomTaxonomy={(e: any) => {
                            this.setState({
                                selectedBloomTaxonomy: e.target.value,
                                metaDataErrors: {
                                    ...this.state.metaDataErrors,
                                    bloomTaxonomy: false,
                                },
                            });
                        }}
                    />

                    <div className="question_action_items">
                        <Button
                            disableRipple={true}
                            variant="outlined"
                            className={`question_save_btn ${this.props.classes.background_button_no_hover}`}
                            onClick={() => {
                                this.setState({ prevQuestionModal: true });
                            }}
                        >
                            <Box className="preview_btn_box">
                                <VisibilityIcon className="eye_preview" />
                                <Typography> Preview</Typography>
                            </Box>
                        </Button>
                        <Button
                            disableRipple={true}
                            variant="outlined"
                            className={`question_save_btn ${this.props.classes.button_color}`}
                            onClick={this.saveQuestion}
                        >
                            {this.state.editButton ? "Update" : "Save"}
                        </Button>
                        <Button
                            disableRipple={true}
                            variant="outlined"
                            className={`question_cancel_btn ${this.props.classes.outline_button_no_hover}`}
                            onClick={()=>HISTORY.push("/TeacherAssessmentCreateSection")}
                        >
                            Cancel
                        </Button>
                    </div>
                    <QuestionPreviewModal
                        questionType={"sorting list"}
                        selectedImageBlobUrl={this.state.selectedImageBlobUrl}
                        questionPreviewModal={this.state.prevQuestionModal}
                        closeModalFun={() => {
                            this.setState({ prevQuestionModal: false });
                        }}
                        question={this.state.question}
                        questionHint={this.state.hintAnswer}
                        sortingResponseList={possibleResponseList}
                    />

                    <AlertModal
                        alertModal={this.state.alertModal}
                        isTeacher={true}
                        onCloseModal={() => {
                            this.setState({ alertModal: false });
                        }}
                        alertTitle="Alert!"
                        alertMessage={this.state.alertMessage}
                        buttonText="OK"
                    />
                </div>
                {this.state.editButton && (
                    <QuestionSidebarCollapse
                        editedQuestionData={HISTORY.location.state?.questionData}
                    />
                )}
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(
    SortingTypeQuestion as React.ComponentType<any>
);

// Customizable Area End
