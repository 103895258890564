// Customizable Area Start
export const impProgressTrophy = require("../assets/object-2-click-to-open.png").default;
export const impBiologyIcon = require("../assets/bitmap.png");
export const impChemistryIcon = require("../assets/chemistry_icon.png");
export const impMathIcon = require("../assets/maths_icon.png");
export const impEnglishIcon = require("../assets/english_icon.png");
export const impNewGoalIcon = require("../assets/my_goal_icon.png");
export const impMyGoalIcon = require("../assets/new_goal_icon.png");
export const imgProfilePicOutline = require("../assets/profile_pic_outline.png");
export const impOrangeMeter = require("../assets/orange_meter.png");
export const imgRedPin = require("../assets/red_pin.png");
export const impBlackBoardIcon = require("../assets/blackboard_icon.png");
export const imgZoomIcon = require("../assets/zoom_icon.png");
export const impBlackBoardDisabledIcon = require("../assets/blackboard_icon_disabled.png");
export const imgZoomDisabledIcon = require("../assets/zoom_icon_disabled.png");
export const imgMenuIcon = require("../assets/menu_icon.png");
export const imgMenu1Icon = require("../assets/menu_1_icon.png");
export const chatIcon = require("../assets/group-19.png");
export const ic_calendar = require("../assets/019-calendar.png");
export const ic_computer = require("../assets/030-computer.png");
export const ic_pie_chart = require("../assets/032-pie-chart.png");
export const ic_suitcase = require("../assets/051-suitcase.png");
export const ic_support = require("../assets/063-support.png");
export const ic_book = require("../assets/067-download.png");
export const biology = require("../assets/biology.png");
export const bitmap = require("../assets/bitmap.png");
export const bitmap1 = require("../assets/bitmap1.png");
export const bitmap2 = require("../assets/bitmap2.png");
export const bitmap3 = require("../assets/bitmap3.png");
export const mathematics = require("../assets/mathematics.png");
export const ic_messages = require("../assets/messages.png");
export const ic_userIcon = require("../assets/userIcon.png");
export const ic_edit = require("../assets/group-29.png");
export const ic_back = require("../assets/group-39.png");
export const ic_homework = require("../assets/homework-4.png");
export const ic_presentation = require("../assets/presentation.png");
export const ic_zoom = require("../assets/zoom-icon.png");
export const ic_clock = require("../assets/clock-1.png");
export const ic_graduate = require("../assets/graduate-1.png");
export const ic_oval = require("../assets/oval.png");
export const logo = require("../assets/fill-158.png");
export const ic_message = require("../assets/group-2.png");
export const book = require("../assets/group-20.png");
export const knowledge = require("../assets/knowledge.png");
export const img_bell = require("../assets/image-bell.png");
export const img_englishFlag = require("../assets/image-englishuk.png");
export const img_font = require("../assets/image-font.png");
export const img_support = require("../assets/image-support.png");
export const img_undo = require("../assets/image-undo.png");
export const menu = require("../assets/menu.png");
export const note = require("../assets/note.png");
export const birthday_bg = require("../assets/birthday_bg.png");
export const thoughts_bg = require("../assets/thoughts_bg.png");
export const student_default_profile_pic = require("../assets/student-default-profile-pic.png");
export const country_icon = require("../assets/country_icon.png");
export const support_icon = require("../assets/support_icon.png");
export const notification_icon = require("../assets/notification_icon.png");
export const img_font_icon = require("../assets/image-font_icon.png");
export const refresh_icon = require("../assets/image-refresh.png");
export const edit_note_img = require("../assets/editNotes.png");
export const setNewGoal = require("../assets/setNewGoal.png").default;
export const studentcup = require("../assets/studentcup.png").default;
export const studentGoal = require("../assets/studentGoal.png").default;
export const SUBJECTS_NO_DATA = require("../assets/learning-curve-no-data.png");
export const UPCOMING_CLASSES_NO_DATA = require("../assets/upcoming-classes-no-data.png").default;
export const EVENTS_NO_DATA = require("../assets/events-no-data.png");
export const NOTES_NO_DATA = require("../assets/notes-no-data.png").default;
export const NOTIFICATIONS_NO_DATA = require("../assets/no-notifications.png");
export const FOLDER_ICON = require("../assets/doc-icon.png");
export const NOTIFICATION_DASHBOARD_ICON = require("../assets/dashboard_notification_icon.png");
export const CREATE_ICON = require("../assets/pencil_icon.png");
export const MESSAGE_ICON = require("../assets/message_icon.png");
export const ANNOUNCEMENT_DASHBOARD_ICON = require("../assets/announcement_icon.png");
// Customizable Area End