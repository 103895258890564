// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { nounCheckSVG, nounCloseSVG } from "../assets";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
interface Props {
    // Customizable Area Start
    questionData: any;
    isTeacher: boolean;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
const uuidv4 = require("uuid/v4");

export const SortingQuestionType = ({
    questionData,
    isTeacher,
    classes,
}: Props) => {
    const [possibleResponseList, setPossibleResponseList] = useState<any[]>([]);
    const [attemptedList, setAttemptedList] = useState<any[]>([]);

    useEffect(() => {
        const answersData = questionData?.answers?.[0]
        let tempResponsesList: any = [];
        let tempAttemptedList: any = [];
        const answers = answersData.correct_sorting_answers;
        const attempt = questionData?.selected_options?.data?.attributes?.sorting_answers

        answers?.map((que: any) => {
            tempResponsesList.push({
                questionId: uuidv4(),
                question: que,
                _destroy: false,
            })
        })
        attempt?.map((item: any) => {
            tempAttemptedList.push({
                questionId: uuidv4(),
                question: item,
                _destroy: false,
            })
        })
        setPossibleResponseList(tempResponsesList);
        setAttemptedList(tempAttemptedList)
    }, [])

    return (
        <Grid container>
            <Grid item xs={12}>
                {attemptedList
                    ?.filter((item: any) => !item._destroy)
                    ?.map((data: any, index: number) => (
                        <div className="sorting-attempted-div"
                            key={`question-${index}`}
                        >
                            <span
                                className={data.question === possibleResponseList?.[index]?.question ? `${classes.heading_font} sorting-field correct-ans-bg` : `${classes.heading_font} sorting-field in-correct-ans-bg`}
                            >{data?.question}
                                {data.question === possibleResponseList?.[index].question ?
                                    <img src={nounCheckSVG} alt="check" className="right-check-icon absolute-icon" />
                                    :
                                    <img src={nounCloseSVG} alt="close" className="close-red-icon absolute-icon" />}
                            </span>
                        </div>
                    ))}
            </Grid>
            <div className="correct_answer_section">
                <Grid container>
                    <Grid item xs={12} sm={6} className="correct_answer_left">
                        {!questionData?.selected_options?.data &&
                            <Typography className={`not_attemped_text ${classes.bodyText_font}`}>Not attempted</Typography>
                        }
                        <Typography className={`correct_answer_text ${classes.heading_font}`}>Correct Answer</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className="score_section">
                        <Typography className={`correct_answer_text ${classes.heading_font}`}>Score</Typography>
                        <div className="correct_answer_rectangle">
                            <Typography className="score_text">
                                {questionData?.selected_options?.data?.attributes?.score ? questionData?.selected_options?.data?.attributes?.score : 0}
                            </Typography>
                        </div>
                        <Typography className="score_text"> / {questionData?.marks}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="sorting-correct-block">
                            {possibleResponseList
                                ?.filter((item: any) => !item._destroy)
                                ?.map((data: any, index: number) => (
                                    <div className="sorting-attempted-div"
                                        key={`response-${index}`}
                                    >
                                        <span className={`${classes.heading_font} sorting-border-field`}>{data?.question}</span>
                                    </div>
                                ))}
                        </div>
                    </Grid>
                    {(!isTeacher && questionData?.selected_options?.data?.attributes?.comments) &&
                        <Grid item xs={12}>
                            <Typography className={`title_teachercomment ${classes.theme_font}`}>Teacher Comment:
                                <span className={`textlabel_teachercomment ${classes.heading_font}`} dangerouslySetInnerHTML={{
                                    __html: questionData?.selected_options?.data?.attributes?.comments,
                                }}></span></Typography>
                        </Grid>
                    }
                </Grid>
            </div>
        </Grid>
    );
};
// Customizable Area End


// Customizable Area Start

export default withStyles(themeCreateStyle)(SortingQuestionType);
// Customizable Area End
