// Customizable Area Start
import { Box, Button, Dialog, TextField, Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
import DeleteBlock from '../../alert/src/DeleteBlock.web';
import EditNoteController, { Props } from './EditNoteController.web';
import './TeacherDashboard.web.css';
// Customizable Area End
export class EditNote extends EditNoteController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    const { deleteModal } = this.state;
    return (
      <>
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          open={this.props.openModal}
          onClose={this.modalhandleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={this.props.openModal} style={{ border: 'none' }}>
            <div className="Creat-Notepopup">
              <Box
                style={{
                  height: '100%',
                  backgroundColor: '#fff',
                  padding: '20px',
                  overflowX: 'hidden',
                }}
              >
                <form>
                  <Box style={{ justifyContent: 'start' }}>
                    <Box
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        borderBottom: '0.3px solid #979797',
                        margin: '0 0px 26px 1px',
                      }}
                    >
                      <Typography
                        style={{
                          textTransform: 'none',
                          fontSize: 24,
                          fontWeight: 'bold',
                          margin: '0 0px 26px 0px',
                        }}
                        className={this.props.classes.title_font}
                      >
                        {this.props?.role==="parent"?"View Note":"Edit Note"}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'start',
                        margin: '0 0px 0px 1px',
                      }}
                    >
                      <Typography
                        style={{
                          textTransform: 'none',
                          fontSize: 16,
                          fontWeight: 600,
                          margin: '23px 24px 15px 0px',
                        }}
                        className={this.props.classes.subTitle_font}
                      >
                        Heading:
                      </Typography>
                      <Box
                        style={{
                          margin: '0px 0px 16px 0px',
                        }}
                      >
                        <TextField
                          id="standard-basic"
                          type="text"
                          disabled={this.props?.role === 'parent'}
                          style={{
                            width: '100%',
                            backgroundColor: '#f6f6f6',
                            borderRadius: 8.8,
                            margin: '0px 35px 0px 1px',
                            border: 'none',
                          }}
                          defaultValue={this.props.heading}
                          placeholder="Write your note heading here"
                          autoComplete="off"
                          variant="outlined"
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              opacity: 0.5,
                            },
                            maxLength: 15,
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            this.setState({ noteHeader: value });
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'start',
                        margin: '0 0px 0px 1px',
                      }}
                    >
                      <Typography
                        style={{
                          textTransform: 'none',
                          fontSize: 16,
                          fontWeight: 600,
                          margin: '23px 24px 15px 0px',
                        }}
                        className={this.props.classes.subTitle_font}
                      >
                        Note:
                      </Typography>
                      <Box
                        style={{
                          margin: '0px 0px 16px 0px',
                        }}
                      >
                        <TextField
                          id="standard-basic"
                          type="text"
                          disabled={this.props?.role === 'parent'}
                          style={{
                            width: '100%',
                            backgroundColor: '#f6f6f6',
                            borderRadius: 8.8,
                            margin: '0px 35px 0px 1px',
                            border: 'none',
                          }}
                          multiline
                          rows={4}
                          defaultValue={this.props.description}
                          placeholder="Write your note heading here"
                          variant="outlined"
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              opacity: 0.5,
                            },
                            maxLength: 256,
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            this.setState({ noteDescription: value });
                          }}
                        />
                      </Box>
                    </Box>
                   {this.props?.role!=="parent"&&
                    (<Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        margin: '48px 33px 0px 1px',
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: '#fff',
                          borderRadius: 3,
                          fontSize: 18,
                          fontFamily: 'Open sans',
                          fontWeight: 600,
                          color: '#ff0000',
                          padding: '13px 33px 13px 35px',
                          boxShadow: 'none',
                          textTransform: 'none',
                        }}
                        onClick={() => {
                          this.setState({
                            deleteModal: true,
                          });
                        }}
                      >
                        Delete
                      </Button>

                      <Button
                        variant="contained"
                        style={{
                          padding: '13px 59px 13px 60px',
                          borderRadius: 3,
                          color: '#ffffff',
                          fontSize: 18,
                          fontWeight: 600,
                          boxShadow: 'none',
                          textTransform: 'none',
                        }}
                        className={this.props.classes.button_color}
                        onClick={() => {
                          this.update_notes();
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                    )}
                  </Box>
                </form>
              </Box>
              {deleteModal && (
                <>
                  <DeleteBlock
                    deleteModal={deleteModal}
                    title={'Delete Note'}
                    message={'Are you sure you want to delete this note?'}
                    closeModalFun={this.closeModal}
                    saveCloseModalFun={this.saveCloseModal}
                  />
                </>
              )}
            </div>
          </Fade>
        </Dialog>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(EditNote as React.ComponentType<
  any
>);
// Customizable Area End
