// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  spinner: boolean,
  date: any,
  time: any,
  dateError:boolean
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  openModal: boolean;
  messageString?: string;
  handleOnClose: React.Dispatch<React.SetStateAction<boolean>>;
  handleOnSchedule:any;
  currentTime?: any;
  // Customizable Area End
}
export default class ScheduleModalController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    // Customizable Area Start
    super(props); 
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {

      spinner: false,
      date: new Date(),
      time: new Date(),
      dateError:false
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

  }

  componentDidUpdate(prevProps:any) {
    if (this.props.currentTime !== prevProps.currentTime ) {
      this.setState({ time: new Date() })
    }
}

  // Customizable Area End
}