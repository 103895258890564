// Customizable Area Start
import React from "react";
import Grid from '@material-ui/core/Grid';
import {
    Button,
    Dialog,
    Typography,
    TextField,
    NativeSelect,
    FormHelperText,
    InputBase,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    TimePicker,
} from '@material-ui/pickers';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import './BatchAndPeriod.web.css'
import { periodTypesList } from "../utils/constants";
import moment from "moment";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface Props {
    // Customizable Area Start
    addEditPeriodDialog: boolean;
    handleAddEditPeriodDialogClose: any;
    isEditPeriod: boolean;
    periodName: string;
    periodNameError: any;
    periodNameErrorMsg: string;
    onChangePeriodName: any;
    onBlurPeriodName: any;
    selectedStartTime: any;
    selectedStartTimePeriodLabel: string;
    selectedEndTime: any;
    selectedEndTimePeriodLabel: string;
    handleStartTimeChange: any;
    handleEndTimeChange: any;
    addEditPeriodClick: any;
    batchData: any;
    selectedBatch: any;
    selectedBatchError: any;
    onChangeBatch: any;
    onBlurBatch: any;
    selectedPeriodEndTimeError: any;
    selectedPeriodEndTimeErrorMsg: string;
    editPeriodEnable: string;
    handleChangeEditPeriod: any;
    selectedPeriodType: any;
    onChangePeriodType: any;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
const AddEditPeriod = ({
    addEditPeriodDialog,
    handleAddEditPeriodDialogClose,
    isEditPeriod,
    periodName,
    periodNameError,
    periodNameErrorMsg,
    onChangePeriodName,
    onBlurPeriodName,
    selectedStartTime,
    selectedStartTimePeriodLabel,
    selectedEndTime,
    selectedEndTimePeriodLabel,
    handleStartTimeChange,
    handleEndTimeChange,
    addEditPeriodClick,
    batchData,
    selectedBatch,
    selectedBatchError,
    onChangeBatch,
    onBlurBatch,
    selectedPeriodEndTimeError,
    selectedPeriodEndTimeErrorMsg,
    editPeriodEnable,
    handleChangeEditPeriod,
    selectedPeriodType,
    onChangePeriodType, classes
}: Props) => {
  
    return (
        <Dialog
            PaperProps={{
                className: "dynamic_popup_width ViewBatchModal"
            }}
            aria-labelledby="customized-dialog-title"
            maxWidth="md"
            open={addEditPeriodDialog}
            onClose={() => handleAddEditPeriodDialogClose()}
            closeAfterTransition
            fullWidth={true}
            disableAutoFocus={true}
            className="ViewBatchModal"
            id="admin-add-edit-period-modal"
            data-test-id="admin-add-edit-period-modal"
        >
            <div className="PeriodModalDiv">
                <Grid container >
                    <Grid item xs={12} className="ViewBatchHead">
                        <Typography className={`ViewBatchText ${classes.title_font}`}>
                            {isEditPeriod ? 'Edit Period' : 'Add Period'}
                        </Typography>
                        <CloseIcon
                            data-test-id="add-edit-period-close-btn"
                            className="ViewBatchClose"
                            onClick={() => handleAddEditPeriodDialogClose()}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} className="AddEditFields">
                        <div>
                            <Typography
                                className={`AddEditTitle ${classes.subTitle_font}`}
                            >
                                Name of Period
                            </Typography>
                            <TextField
                                id="standard-basic"
                                data-test-id="add-edit-period-text-field"
                                placeholder="Example Period 1, Break"
                                className={`AddPeriodTextfieldStyle ${classes.subTitle_font}`}
                                variant="outlined"
                                value={periodName}
                                onChange={onChangePeriodName}
                                onBlur={onBlurPeriodName}
                                error={periodNameError}
                                helperText={periodNameErrorMsg}
                                inputProps={{
                                    className: `PeriodInputProps ${classes.subTitle_font}`
                                }}
                                InputProps={{
                                    className: `PeriodNameInputProps ${classes.subTitle_font}`
                                }}
                            />
                        </div>
                        <div className="AddEditFieldSpace">
                            <Typography
                                className={`AddEditTitle ${classes.subTitle_font}`}
                            >
                                Period Type
                            </Typography>
                            <div>
                                <NativeSelect
                                    id="select"
                                    data-test-id="add-edit-select-period-type"
                                    placeholder="Select Period Type*"
                                    className={`AddPeriodTextfieldStyle ${classes.subTitle_font}`}
                                    variant="outlined"
                                    IconComponent={ExpandMoreIcon}
                                    value={selectedPeriodType}
                                    onChange={onChangePeriodType}
                                    inputProps={{
                                        className: `PeriodInputProps ${classes.subTitle_font}`
                                    }}
                                    // input={<BootstrapInput1 />}
                                    disableUnderline
                                    classes={{
                                        select : classes.AddEditPeriodClassesAndSectionBootstrapInput
                                    }}
                                >
                                    <option value="" disabled>Select Period Type</option>
                                    {periodTypesList?.map((item: any) =>
                                        <option value={item.value}>{item?.label}</option>
                                    )}
                                </NativeSelect>
                            </div>
                        </div>
                        <div className="AddEditFieldSpace">
                            <Typography
                                className={`AddEditTitle ${classes.subTitle_font}`}
                            >
                                Select Batch
                            </Typography>
                            <div>
                                <NativeSelect
                                    id="select"
                                    data-test-id="add-edit-period-select-batch"
                                    placeholder="Select Batch*"
                                    className={`AddPeriodTextfieldStyle ${classes.subTitle_font}`}
                                    variant="outlined"
                                    IconComponent={ExpandMoreIcon}
                                    value={selectedBatch}
                                    error={selectedBatchError}
                                    onChange={onChangeBatch}
                                    onBlur={onBlurBatch}
                                    inputProps={{
                                        className: `PeriodInputProps ${classes.subTitle_font}`
                                    }}
                                    // input={<BootstrapInput1 />}
                                    disableUnderline
                                    classes={{
                                        select : classes.AddEditPeriodClassesAndSectionBootstrapInput
                                    }}
                                >
                                    <option value="" disabled>Select Batch</option>
                                    {batchData?.map((item: any) =>
                                        <option value={item.id}>{item?.attributes?.name} ({moment(item.attributes.start_time).format("hh:mm A")} to {moment(item.attributes.end_time).format("hh:mm A")} )</option>
                                    )}

                                </NativeSelect>
                                {selectedBatchError && (
                                    <FormHelperText className="SelectBatchText">
                                        Please select batch.
                                    </FormHelperText>
                                )}
                            </div>
                        </div>

                        <Grid container className="AddEditFieldSpace">
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    className={`AddEditTitle ${classes.subTitle_font}`}
                                >
                                    Start Time
                                </Typography>
                                <div className="AddEditFlexRow">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimePicker
                                            autoOk
                                            inputVariant="outlined"
                                            data-test-id="period-popup-start-time"
                                            format="hh:mm"
                                            className="periodTimePicker"
                                            value={selectedStartTime}
                                            onChange={(e: any) => handleStartTimeChange(e)} />
                                    </MuiPickersUtilsProvider>
                                    <div className="timePeriodBox">
                                        <Typography className="timePeriodText">{selectedStartTimePeriodLabel}</Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    className={`AddEditTitle padding-top-15 ${classes.subTitle_font}`}
                                >
                                    End Time
                                </Typography>

                                <div className="AddEditFlexRow">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimePicker
                                            autoOk
                                            inputVariant="outlined"
                                            format="hh:mm"
                                            data-test-id="period-popup-end-time"
                                            className="periodTimePicker"
                                            value={selectedEndTime}
                                            onChange={(e: any) => handleEndTimeChange(e)}
                                            error={selectedPeriodEndTimeError}
                                            helperText={selectedPeriodEndTimeErrorMsg} />
                                    </MuiPickersUtilsProvider>
                                    <div className="timePeriodBox">
                                        <Typography className="timePeriodText">{selectedEndTimePeriodLabel}</Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <div className="CreatePeriodDiv">
                    <Button
                        data-test-id="update-create-period-btn"
                        className={`CreatePeriodBtn ${classes.button_color}`}
                        onClick={(e: any) => {
                            addEditPeriodClick()
                        }}
                    >
                        <Typography className="CreatePeriodText">
                            {isEditPeriod ? 'Update' : 'Create'}
                        </Typography>
                    </Button>
                </div>

            </div>
        </Dialog>
    );
};
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(AddEditPeriod);
// Customizable Area End
