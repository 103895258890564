// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const ChatIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M5.626 14.752c0-.544.441-.985.986-.985h5.846a.986.986 0 0 1 0 1.971H6.612a.986.986 0 0 1-.986-.986zm.986-2.19h11.367a.986.986 0 1 0 0-1.972H6.612a.986.986 0 1 0 0 1.972zm0-3.177h11.367a.986.986 0 1 0 0-1.971H6.612a.986.986 0 1 0 0 1.971zm-2.7 10.174A11.604 11.604 0 0 1 .72 11.576C.72 5.193 5.912 0 12.295 0a11.506 11.506 0 0 1 6.162 1.78c.265-.017.531-.027.796-.027 6.383 0 11.576 5.193 11.576 11.576a11.605 11.605 0 0 1-3.193 7.983c.898 1.271 1.958 2.088 3.223 2.488a.986.986 0 0 1-.09 1.904 13.212 13.212 0 0 1-8.752-1.131 11.605 11.605 0 0 1-8.934-1.448 11.615 11.615 0 0 1-3.551-.305A13.212 13.212 0 0 1 .778 23.95a.986.986 0 0 1-.09-1.904c1.266-.4 2.325-1.217 3.224-2.488zM20.928 3.87a11.53 11.53 0 0 1 2.944 7.705c0 4.993-3.178 9.258-7.617 10.879a9.652 9.652 0 0 0 5.615.118.986.986 0 0 1 .74.084 11.243 11.243 0 0 0 4.788 1.356 10.213 10.213 0 0 1-1.873-2.336.986.986 0 0 1 .183-1.235 9.626 9.626 0 0 0 3.15-7.113c0-4.725-3.43-8.664-7.93-9.458zM5.84 18.688a.986.986 0 0 1 .184 1.236 10.213 10.213 0 0 1-1.874 2.335 11.242 11.242 0 0 0 4.789-1.356.986.986 0 0 1 .74-.083c.851.24 1.731.36 2.616.36 5.295 0 9.604-4.308 9.604-9.604 0-5.296-4.309-9.604-9.605-9.604-5.295 0-9.604 4.308-9.604 9.604a9.626 9.626 0 0 0 3.15 7.112z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default ChatIcon;
// Customizable Area End