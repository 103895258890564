// Customizable Area Start
import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';
import { HISTORY } from '../../../../components/src/comman';
// Customizable Area End

// Customizable Area Start
export const configJSON = require('./config');
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  data: any;
  collapaseHeader: boolean;
  anchorEl: any;
  logoutModal: boolean;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HeaderController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      txtInputValue: '',
      txtSavedValue: 'A',
      enableField: false,
      collapaseHeader: false,
      anchorEl: null,
      logoutModal: false,
      data: {},
    };
    this.userdata = this.userdata.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  userdata = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}');
    this.setState({ data: data });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}');
    this.setState({ data: data });
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        'Change Value',
        'From: ' + this.state.txtSavedValue + ' To: ' + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: 'email',
    keyboardType: 'email-address',
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onlogoutCloseModal = (closeModal: boolean) => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}');
    var bodyStyles = document.body.style;
    bodyStyles.setProperty('--color-scrollBar', 'rgba(56, 44, 124,0.5)');
    if (data.role === 'schooladmin') {
      HISTORY.push('/SchooladminLogin');
    } else if (data.role === '21kadmin') {
      HISTORY.push('/SuperAdminLogin');
    } else {
      HISTORY.push('/EmailAccountLoginBlock');
    }
    localStorage.removeItem('user_data');
    this.setState({ logoutModal: closeModal });
  };
  onCloseModal = (closeModal: boolean) => {
    this.setState({ logoutModal: closeModal });
  };
  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  // Customizable Area End
}
