// Customizable Area Start
import { HISTORY } from "../../../../components/src/comman";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  answerData: any[];
  expandArr: any[];
  pagination_page: 0;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
const data: any[] = [
  {
    question: "It is a long Established fact that a reader will be distracted?",
    option: [
      "Printing and typesetting industry",
      "Printing and typesetting industry",
      "Printing and typesetting industry",
      "Printing and typesetting industry",
    ],
    correct: 1,
    answerDetail:
      "There are many variations of passages of Lorem, ipsum dolor sit amet consectetur adipisicing elit. At voluptas eaque, veniam officiis consectetur nihil quidem sunt quaerat quam illum accusantium asperiores quod temporibus pariatur quisquam assumenda praesentium. Consequatur nobis nam facilis iure ut.",
    attachment: [
      "Test paper solution.jpg",
      "Test paper solution.jpg",
      "Test paper solution.jpg",
    ],
  },
  {
    question: "When an unknown printer took a gallery of type and scrambled?",
    option: [
      "Typesetting industry",
      "Typesetting industry",
      "Typesetting industry",
      "Typesetting industry",
    ],
    correct: 2,
    answerDetail:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, consectetur!",
    attachment: [
      "Test paper solution.jpg",
      "Test paper solution.jpg",
      "Test paper solution.jpg",
    ],
  },
  {
    question: "It is a long Established fact that a reader will be distracted?",
    option: [
      "Printing and typesetting industry",
      "Printing and typesetting industry",
      "Printing and typesetting industry",
      "Printing and typesetting industry",
    ],
    correct: 1,
    answerDetail:
      "There are many variations of passages of Lorem, ipsum dolor sit amet consectetur adipisicing elit. At voluptas eaque, veniam officiis consectetur nihil quidem sunt quaerat quam illum accusantium asperiores quod temporibus pariatur quisquam assumenda praesentium. Consequatur nobis nam facilis iure ut.",
    attachment: [
      "Test paper solution.jpg",
      "Test paper solution.jpg",
      "Test paper solution.jpg",
    ],
  },
  {
    question: "It is a long Established fact that a reader will be distracted?",
    option: [
      "Printing and typesetting industry",
      "Printing and typesetting industry",
      "Printing and typesetting industry",
      "Printing and typesetting industry",
    ],
    correct: 4,
    answerDetail:
      "There are many variations of passages of Lorem, ipsum dolor sit amet consectetur adipisicing elit. At voluptas eaque, veniam officiis consectetur nihil quidem sunt quaerat quam illum accusantium asperiores quod temporibus pariatur quisquam assumenda praesentium. Consequatur nobis nam facilis iure ut.",
    attachment: [
      "Test paper solution.jpg",
      "Test paper solution.jpg",
      "Test paper solution.jpg",
    ],
  },
  {
    question: "It is a long Established fact that a reader will be distracted?",
    option: [
      "Printing and typesetting industry",
      "Printing and typesetting industry",
      "Printing and typesetting industry",
      "Printing and typesetting industry",
    ],
    correct: 2,
    answerDetail:
      "There are many variations of passages of Lorem, ipsum dolor sit amet consectetur adipisicing elit. At voluptas eaque, veniam officiis consectetur nihil quidem sunt quaerat quam illum accusantium asperiores quod temporibus pariatur quisquam assumenda praesentium. Consequatur nobis nam facilis iure ut.",
    attachment: [
      "Test paper solution.jpg",
      "Test paper solution.jpg",
      "Test paper solution.jpg",
    ],
  },
  {
    question: "It is a long Established fact that a reader will be distracted?",
    option: [
      "Printing and typesetting industry",
      "Printing and typesetting industry",
      "Printing and typesetting industry",
      "Printing and typesetting industry",
    ],
    correct: 3,
    answerDetail:
      "There are many variations of passages of Lorem, ipsum dolor sit amet consectetur adipisicing elit. At voluptas eaque, veniam officiis consectetur nihil quidem sunt quaerat quam illum accusantium asperiores quod temporibus pariatur quisquam assumenda praesentium. Consequatur nobis nam facilis iure ut.",
    attachment: [
      "Test paper solution.jpg",
      "Test paper solution.jpg",
      "Test paper solution.jpg",
    ],
  },
];
// Customizable Area End
export default class AnswerSheetController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getQuestionListId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      answerData: data,
      expandArr: [],
      pagination_page: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.setState({ expandArr: new Array(data.length) });
  }

  handleChange = (index: number) => {
    let newArr: boolean[] = this.state.expandArr;
    newArr[index] = !newArr[index];
    this.setState({ expandArr: newArr });
  };

  onSelectQuestionType = (questionData: any) => {
    if (questionData.title === "Long Answer") {
      HISTORY.push("/LongAnswerTypeQuestion");
    }
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getQuestionListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  get_question_list = (page: number) => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.questionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
