import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  FlatList,
  ActivityIndicator
} from "react-native";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import {
  Search,
  Replay,
  NotificationsNone,
  HeadsetMicOutlined,
  Title,
  TitleOutlined,
  KeyboardArrowLeft,
  EditOutlined,
  MoreVertOutlined,
  KeyboardArrowDown
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import DashboardController, { Props } from "./DashboardController";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
// Customizable Area End
class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {data} = this.state;
    return (
      <Box
        display="flex"
        height="100%"
        width="100%"
        style={{
          background: "linear-gradient(to top, #aa97fc 125%, #7f6cfc 0%)"
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="10%"
          pt="25px"
        >
          <Avatar src={"/"} style={{ width: 62, height: 54 }} />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            mt="70px"
            height="60%"
          >
            <Link href="#">
              <Avatar src={"/"} style={{ width: 31, height: "auto" }} />
            </Link>
            <Link href="#">
              <Avatar src={"/"} style={{ width: 31, height: "auto" }} />
            </Link>
            <Link href="#">
              <Avatar src={"/"} style={{ width: 31, height: "auto" }} />
            </Link>
            <Link href="#">
              <Avatar src={"/"} style={{ width: 31, height: "auto" }} />
            </Link>
            <Link href="#">
              <Avatar src={"/"} style={{ width: 31, height: "auto" }} />
            </Link>
            <Link href="#">
              <Avatar src={"/"} style={{ width: 31, height: "auto" }} />
            </Link>
            <Link href="#">
              <Avatar src={"/"} style={{ width: 31, height: "auto" }} />
            </Link>
            <Link href="#">
              <Avatar src={"/"} style={{ width: 31, height: "auto" }} />
            </Link>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="90%"
          height="100%"
          pl="45px"
          pr="40px"
          style={{
            backgroundColor: "white",
            borderTopLeftRadius: 40,
            borderBottomLeftRadius: 40,
            overflowY: "scroll"
          }}
        >
          <Box
            display="flex"
            width="100%"
            pt="20px"
            pb="20px"
            alignItems="center"
            justifyContent="space-between"
            borderBottom="solid 1px rgba(0, 0, 0, 0.17)"
          >
            <Box
              display="flex"
              alignItems="center"
              pb="10px"
              width="40%"
              style={{ borderBottom: "solid 1px rgba(0, 0, 0, 0.46)" }}
            >
              <Search style={{ height: 22, width: 22 }} />
              <input
                placeholder="Search"
                style={{
                  border: "none",
                  outline: "none",
                  marginLeft: 10,
                  color: "#484848",
                  opacity: 0.45,
                  fontSize: 18,
                  width: "100%",
                  paddingRight: 10
                }}
              />
            </Box>
            <Box
              display="flex"
              width="40%"
              alignItems="center"
              justifyContent="space-between"
            >
              <IconButton>
                <Replay style={{ height: 22, width: 22 }} />
              </IconButton>
              <IconButton>
                <NotificationsNone style={{ height: 22, width: 22 }} />
              </IconButton>
              <IconButton>
                <HeadsetMicOutlined style={{ height: 22, width: 22 }} />
              </IconButton>
              <IconButton>
                <TitleOutlined style={{ height: 22, width: 22 }} />
              </IconButton>
              <IconButton>
                <Avatar src={"/"} style={{ height: 22, width: 22 }} />
              </IconButton>
              <Box
                ml="50px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <IconButton>
                  <Box
                    style={{
                      width: 28,
                      height: 28,
                      display: "flex",
                      borderRadius: 14,
                      justifyContent: "center",
                      alignItems: "center",
                      background: "linear-gradient(to bottom, #b6a5fe, #7f6cfc)"
                    }}
                  >
                    <KeyboardArrowLeft
                      style={{ height: 22, width: 22, color: "white" }}
                    />
                  </Box>
                </IconButton>
                <Avatar
                  src={"/"}
                  style={{ width: 55, height: 55, marginLeft: 10 }}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" width="100%" pb="20px">
            <Box display="flex" flexDirection="column" width="70%" pr="50px">
              <Box
                mt="24px"
                padding="16px 10px 30px 50px"
                display="flex"
                style={{
                  borderRadius: 15,
                  boxShadow: "0 24px 48px 0 rgba(30, 28, 46, 0.08)",
                  backgroundColor: "#fff",
                  height: 190
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Avatar src="/" style={{ height: 110, width: 110 }} />
                  <Typography
                    style={{
                      fontSize: 16,
                      fontFamily: "Open sans",
                      fontWeight: "normal",
                      color: "#3f526d",
                      marginTop: 9
                    }}
                  >
                    {data?.type}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" ml="60px">
                  <Typography
                    style={{
                      fontSize: 27,
                      fontFamily: "Open sans",
                      fontWeight: "bold",
                      color: "black"
                    }}
                  >
                    Hello,{" "}
                    <Typography
                      style={{
                        fontSize: 27,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "black"
                      }}
                      component="span"
                    >
                      {data?.first_name}
                    </Typography>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 19,
                      fontFamily: "Open sans",
                      fontWeight: "normal",
                      color: "#3f526d",
                      width: "70%",
                      marginTop: 11
                    }}
                  >
                    Your child completed 75% of the lessions. Progress is very
                    good.
                  </Typography>

                  <Button
                    style={{
                      marginTop: 28,
                      outline: "none",
                      width: 156,
                      height: 41,
                      borderRadius: 8,
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#7f6cfc"
                    }}
                    variant="outlined"
                  >
                    <Typography
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        fontFamily: "Open sans",
                        fontWeight: 600,
                        color: "black"
                      }}
                    >
                      View Progress
                    </Typography>
                  </Button>
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                mt="32px"
                padding="23px 41px 43px 30px"
                style={{
                  borderRadius: 20,
                  height: 582,
                  boxShadow: "0 8px 27px 0 rgba(224, 224, 224, 0.5)",
                  backgroundColor: "#fff"
                }}
              >
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="85%"
                  >
                    <Box
                      style={{
                        padding: "15px 13px 10px 14px",
                        width: 170,
                        height: 60,
                        borderRadius: 10,
                        background:
                          "linear-gradient(224deg, #ffcc79 67%, #ffb84d 6%)"
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Open sans",
                          fontWeight: 600,
                          color: "white"
                        }}
                      >
                        My Classes
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Open sans",
                          fontWeight: 600,
                          color: "rgba(255, 255, 255, 0.88)",
                          marginTop: 4
                        }}
                      >
                        2 active
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        padding: "15px 13px 10px 14px",
                        width: 170,
                        height: 60,
                        borderRadius: 10,
                        background:
                          "linear-gradient(249deg, #fd8585 129%, #f68d8d)"
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Open sans",
                          fontWeight: 600,
                          color: "white"
                        }}
                      >
                        My Students
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Open sans",
                          fontWeight: 600,
                          color: "rgba(255, 255, 255, 0.88)",
                          marginTop: 4
                        }}
                      >
                        100
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        padding: "15px 13px 10px 14px",
                        width: 170,
                        height: 60,
                        borderRadius: 10,
                        background:
                          "linear-gradient(249deg, #a293e0 129%, #7c67cf)"
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Open sans",
                          fontWeight: 600,
                          color: "white"
                        }}
                      >
                        Assigment
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Open sans",
                          fontWeight: 600,
                          color: "rgba(255, 255, 255, 0.88)",
                          marginTop: 4
                        }}
                      >
                        10
                      </Typography>
                    </Box>
                  </Box>

                  <IconButton style={{ padding: 0 }}>
                    <MoreVertOutlined
                      style={{
                        height: 24,
                        width: 24,
                        color: "rgba(151, 135, 219, 0.57)"
                      }}
                    />
                  </IconButton>
                </Box>

                <Box
                  mt="24px"
                  padding="16px 10px 30px 50px"
                  display="flex"
                  style={{
                    borderRadius: 8,
                    backgroundColor: "#f9f8fd",
                    height: 90
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Avatar src="/" style={{ height: 70, width: 70 }} />
                  </Box>
                  <Box display="flex" flexDirection="column" ml="60px">
                    <Typography
                      style={{
                        fontSize: 18,
                        fontFamily: "Open sans",
                        fontWeight: 600,
                        color: "black"
                      }}
                    >
                      Science & Technology
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "#9aa3b2",
                        width: "70%",
                        marginTop: 2
                      }}
                    >
                      (Class 10)
                    </Typography>

                    <Box display="flex" alignItems="center" mt="15px">
                      <Box display="flex" alignItems="center">
                        <Avatar style={{ height: 13, width: 13 }} />
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "Open sans",
                            fontWeight: "normal",
                            color: "#3f526d",
                            marginLeft: 5
                          }}
                        >
                          21 Units
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" ml="35px">
                        <Avatar style={{ height: 13, width: 13 }} />
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "Open sans",
                            fontWeight: "normal",
                            color: "#3f526d",
                            marginLeft: 5
                          }}
                        >
                          5 Assignments
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" ml="35px">
                        <Avatar style={{ height: 13, width: 13 }} />
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "Open sans",
                            fontWeight: "normal",
                            color: "#3f526d",
                            marginLeft: 5
                          }}
                        >
                          70 Hours
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center" ml="35px">
                        <Avatar style={{ height: 13, width: 13 }} />
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "Open sans",
                            fontWeight: "normal",
                            color: "#3f526d",
                            marginLeft: 5
                          }}
                        >
                          240 Students
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    ml="43px"
                    position="relative"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress
                      thickness={4}
                      variant="determinate"
                      value={47}
                      style={{ width: 70, height: 70 }}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        style={{
                          fontSize: 19,
                          fontFamily: "Open sans",
                          fontWeight: 600,
                          color: "#3f526d"
                        }}
                      >
                        47%
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  mt="24px"
                  padding="16px 10px 30px 50px"
                  display="flex"
                  style={{
                    borderRadius: 8,
                    backgroundColor: "#f9f8fd",
                    height: 90
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Avatar src="/" style={{ height: 70, width: 70 }} />
                  </Box>
                  <Box display="flex" flexDirection="column" ml="60px">
                    <Typography
                      style={{
                        fontSize: 18,
                        fontFamily: "Open sans",
                        fontWeight: 600,
                        color: "black"
                      }}
                    >
                      Science & Technology
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "#9aa3b2",
                        width: "70%",
                        marginTop: 2
                      }}
                    >
                      (Class 10)
                    </Typography>

                    <Box display="flex" alignItems="center" mt="15px">
                      <Box display="flex" alignItems="center">
                        <Avatar style={{ height: 13, width: 13 }} />
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "Open sans",
                            fontWeight: "normal",
                            color: "#3f526d",
                            marginLeft: 5
                          }}
                        >
                          21 Units
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" ml="35px">
                        <Avatar style={{ height: 13, width: 13 }} />
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "Open sans",
                            fontWeight: "normal",
                            color: "#3f526d",
                            marginLeft: 5
                          }}
                        >
                          5 Assignments
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" ml="35px">
                        <Avatar style={{ height: 13, width: 13 }} />
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "Open sans",
                            fontWeight: "normal",
                            color: "#3f526d",
                            marginLeft: 5
                          }}
                        >
                          70 Hours
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center" ml="35px">
                        <Avatar style={{ height: 13, width: 13 }} />
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "Open sans",
                            fontWeight: "normal",
                            color: "#3f526d",
                            marginLeft: 5
                          }}
                        >
                          240 Students
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    ml="43px"
                    position="relative"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress
                      thickness={4}
                      variant="determinate"
                      value={47}
                      style={{ width: 70, height: 70 }}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        style={{
                          fontSize: 19,
                          fontFamily: "Open sans",
                          fontWeight: 600,
                          color: "#3f526d"
                        }}
                      >
                        47%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  mt="24px"
                  padding="16px 10px 30px 50px"
                  display="flex"
                  style={{
                    borderRadius: 8,
                    backgroundColor: "#f9f8fd",
                    height: 90
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Avatar src="/" style={{ height: 70, width: 70 }} />
                  </Box>
                  <Box display="flex" flexDirection="column" ml="60px">
                    <Typography
                      style={{
                        fontSize: 18,
                        fontFamily: "Open sans",
                        fontWeight: 600,
                        color: "black"
                      }}
                    >
                      Science & Technology
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "#9aa3b2",
                        width: "70%",
                        marginTop: 2
                      }}
                    >
                      (Class 10)
                    </Typography>

                    <Box display="flex" alignItems="center" mt="15px">
                      <Box display="flex" alignItems="center">
                        <Avatar style={{ height: 13, width: 13 }} />
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "Open sans",
                            fontWeight: "normal",
                            color: "#3f526d",
                            marginLeft: 5
                          }}
                        >
                          21 Units
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" ml="35px">
                        <Avatar style={{ height: 13, width: 13 }} />
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "Open sans",
                            fontWeight: "normal",
                            color: "#3f526d",
                            marginLeft: 5
                          }}
                        >
                          5 Assignments
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" ml="35px">
                        <Avatar style={{ height: 13, width: 13 }} />
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "Open sans",
                            fontWeight: "normal",
                            color: "#3f526d",
                            marginLeft: 5
                          }}
                        >
                          70 Hours
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center" ml="35px">
                        <Avatar style={{ height: 13, width: 13 }} />
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "Open sans",
                            fontWeight: "normal",
                            color: "#3f526d",
                            marginLeft: 5
                          }}
                        >
                          240 Students
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    ml="43px"
                    position="relative"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress
                      thickness={4}
                      variant="determinate"
                      value={47}
                      style={{ width: 70, height: 70 }}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        style={{
                          fontSize: 19,
                          fontFamily: "Open sans",
                          fontWeight: 600,
                          color: "#3f526d"
                        }}
                      >
                        47%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                mt="32px"
                padding="26px 0px 60px 40px"
                style={{
                  borderRadius: 20,
                  height: 220,
                  boxShadow: "0 24px 48px 0 rgba(30, 28, 46, 0.08)",
                  backgroundColor: "#fff"
                }}
              >
                <Typography
                  style={{
                    fontSize: 16,
                    fontFamily: "Open sans",
                    fontWeight: "bold",
                    color: "balck"
                  }}
                >
                  Reminder & Quote
                </Typography>
                <Box display="flex" mt="23px">
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{
                      padding: "35px 30px 38px 30px",
                      width: 263,
                      height: 120,
                      borderRadius: 15,
                      backgroundColor: "#ff8282"
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 21,
                        fontFamily: "Open sans",
                        fontWeight: "bold",
                        color: "white"
                      }}
                    >
                      Upcoming Birthday
                    </Typography>

                    <Typography
                      style={{
                        fontSize: 25,
                        fontFamily: "Open sans",
                        fontWeight: "bold",
                        color: "white",
                        marginTop: 23
                      }}
                    >
                      Vijay Mishra
                    </Typography>

                    <Typography
                      style={{
                        fontSize: 16,
                        fontFamily: "Open sans",
                        fontWeight: "bold",
                        color: "white",
                        marginTop: 10
                      }}
                    >
                      8th Feb, 2021
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{
                      padding: "35px 30px 38px 30px",
                      width: 346,
                      height: 120,
                      borderRadius: 15,
                      marginLeft: 17,
                      backgroundColor: "rgba(6, 176, 182, 0.95)"
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 21,
                        fontFamily: "Open sans",
                        fontWeight: "bold",
                        color: "white"
                      }}
                    >
                      Thought for the day
                    </Typography>

                    <Typography
                      style={{
                        fontSize: 16,
                        fontFamily: "Open sans",
                        fontWeight: "bold",
                        color: "white",
                        marginTop: 26
                      }}
                    >
                      If you want something in your life you've never had,
                      you'll have to do something, you've never done.
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                mt="32px"
                padding="26px 34px 0px 40px"
                style={{
                  borderRadius: 20,
                  height: 400,
                  boxShadow: "0 24px 48px 0 rgba(30, 28, 46, 0.08)",
                  backgroundColor: "#fff"
                }}
              >
                <Typography
                  style={{
                    fontSize: 16,
                    fontFamily: "Open sans",
                    fontWeight: "bold",
                    color: "balck"
                  }}
                >
                  Result
                </Typography>

                <Box display="flex" mt="21px">
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 6,
                      background:
                        "linear-gradient(290deg, #b6a5fe 106%, #7f6cfc)",
                      fontSize: 16,
                      fontFamily: "Open sans",
                      fontWeight: "normal",
                      color: "white",
                      textTransform: "none"
                    }}
                  >
                    Class Name
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 6,
                      backgroundColor: "#f9f8fd",
                      fontSize: 16,
                      fontFamily: "Open sans",
                      fontWeight: "normal",
                      color: "black",
                      textTransform: "none",
                      marginLeft: 25
                    }}
                  >
                    Class Name
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 6,
                      backgroundColor: "#f9f8fd",
                      fontSize: 16,
                      fontFamily: "Open sans",
                      fontWeight: "normal",
                      color: "black",
                      textTransform: "none",
                      marginLeft: 25
                    }}
                  >
                    Class Name
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 6,
                      backgroundColor: "#f9f8fd",
                      fontSize: 16,
                      fontFamily: "Open sans",
                      fontWeight: "normal",
                      color: "black",
                      textTransform: "none",
                      marginLeft: 25
                    }}
                  >
                    Class Name
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 6,
                      backgroundColor: "#f9f8fd",
                      fontSize: 16,
                      fontFamily: "Open sans",
                      fontWeight: "normal",
                      color: "black",
                      textTransform: "none",
                      marginLeft: 25
                    }}
                  >
                    Class Name
                  </Button>
                </Box>

                <Box display="flex" mt="40px">
                  <Box display="flex" mr="80px">
                    <Select
                      disableUnderline={true}
                      classes={{
                        select: this.props.classes.dropdown
                      }}
                      IconComponent={() => (
                        <KeyboardArrowDown
                          style={{
                            position: "absolute",
                            right: 8,
                            top: 20,
                            height: 30,
                            width: 30,
                            color: "#8E8E8E"
                          }}
                        />
                      )}
                      renderValue={(value) => "Select Exam"}
                      displayEmpty={true}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={""}
                    >
                      <MenuItem
                        style={{
                          color: "#8E8E8E",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 14
                        }}
                        value={""}
                      >
                        {"Select Exam"}
                      </MenuItem>
                    </Select>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#3f526d"
                        }}
                      >
                        Total Students
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 33,
                          fontFamily: "Open sans",
                          fontWeight: 600,
                          color: "balck"
                        }}
                      >
                        500
                      </Typography>
                    </Box>

                    <Box display="flex" flexDirection="column">
                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#3f526d"
                        }}
                      >
                        Subject
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: "Open sans",
                          fontWeight: 600,
                          color: "balck"
                        }}
                      >
                        Biology
                      </Typography>
                    </Box>

                    <Box display="flex" flexDirection="column">
                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#3f526d"
                        }}
                      >
                        Exam Date
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: "Open sans",
                          fontWeight: 600,
                          color: "balck"
                        }}
                      >
                        10/10/2021
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  mt="33px"
                  style={{
                    padding: "24px 34px",
                    height: "100%",
                    borderRadius: 13,
                    backgroundColor: "#f9f8fd"
                  }}
                >
                  <Box display="flex" mb="13px" width="100%">
                    <Typography
                      style={{
                        fontSize: 11,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "#3f526d",
                        width: "60%"
                      }}
                    >
                      Student Name
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "#3f526d",
                        width: "20%"
                      }}
                    >
                      Rank
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "#3f526d",
                        width: "20%"
                      }}
                    >
                      Marks
                    </Typography>
                  </Box>

                  <Box display="flex" mb="10px" width="100%">
                    <Box display="flex" width="60%">
                      <Typography
                        style={{
                          fontSize: 11,
                          fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "#000"
                        }}
                      >
                        Jayesh Joshi
                      </Typography>
                    </Box>

                    <Box display="flex" width="20%" pl="50px">
                      <Typography
                        style={{
                          fontSize: 11,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#000"
                        }}
                      >
                        1
                      </Typography>
                    </Box>

                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 11,
                          fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "#3f526d"
                        }}
                      >
                        98%
                      </Typography>
                    </Box>
                  </Box>

                  <Box display="flex" mb="10px" width="100%">
                    <Box display="flex" width="60%">
                      <Typography
                        style={{
                          fontSize: 11,
                          fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "#000"
                        }}
                      >
                        Jayesh Joshi
                      </Typography>
                    </Box>

                    <Box display="flex" width="20%" pl="50px">
                      <Typography
                        style={{
                          fontSize: 11,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#000"
                        }}
                      >
                        1
                      </Typography>
                    </Box>

                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 11,
                          fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "#3f526d"
                        }}
                      >
                        98%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                mt="28px"
                padding="26px 34px 0px 40px"
                style={{
                  borderRadius: 20,
                  height: 400,
                  boxShadow: "0 24px 48px 0 rgba(30, 28, 46, 0.08)",
                  backgroundColor: "#fff"
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    style={{
                      fontSize: 16,
                      fontFamily: "Open sans",
                      fontWeight: "bold",
                      color: "balck"
                    }}
                  >
                    Upcoming Classes
                  </Typography>
                  <Select
                    disableUnderline={true}
                    classes={{
                      select: this.props.classes.dropdown
                    }}
                    IconComponent={() => (
                      <KeyboardArrowDown
                        style={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          height: 30,
                          width: 30,
                          color: "#8E8E8E"
                        }}
                      />
                    )}
                    renderValue={(value) => "Select Class"}
                    displayEmpty={true}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={""}
                  >
                    <MenuItem
                      style={{
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 14
                      }}
                      value={""}
                    >
                      {"Select Class"}
                    </MenuItem>
                  </Select>
                </Box>

                <Box display="flex" mt="21px">
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 6,
                      background:
                        "linear-gradient(290deg, #b6a5fe 106%, #7f6cfc)",
                      fontSize: 16,
                      fontFamily: "Open sans",
                      fontWeight: "normal",
                      color: "white",
                      textTransform: "none"
                    }}
                  >
                    Biology
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 6,
                      backgroundColor: "#f9f8fd",
                      fontSize: 16,
                      fontFamily: "Open sans",
                      fontWeight: "bold",
                      color: "black",
                      textTransform: "none",
                      marginLeft: 25
                    }}
                  >
                    Chimistry
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 6,
                      backgroundColor: "#f9f8fd",
                      fontSize: 16,
                      fontFamily: "Open sans",
                      fontWeight: "bold",
                      color: "black",
                      textTransform: "none",
                      marginLeft: 25
                    }}
                  >
                    English
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 6,
                      backgroundColor: "#f9f8fd",
                      fontSize: 16,
                      fontFamily: "Open sans",
                      fontWeight: "bold",
                      color: "black",
                      textTransform: "none",
                      marginLeft: 25
                    }}
                  >
                    Hindi
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 6,
                      backgroundColor: "#f9f8fd",
                      fontSize: 16,
                      fontFamily: "Open sans",
                      fontWeight: "bold",
                      color: "black",
                      textTransform: "none",
                      marginLeft: 25
                    }}
                  >
                    Math
                  </Button>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  mt="33px"
                  style={{
                    padding: "24px 34px",
                    height: "100%",
                    borderRadius: 13,
                    backgroundColor: "#f9f8fd"
                  }}
                >
                  <Box display="flex" mb="13px" width="100%">
                    <Typography
                      style={{
                        fontSize: 11,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "#3f526d",
                        width: "20%"
                      }}
                    >
                      Unit
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "#3f526d",
                        width: "20%"
                      }}
                    >
                      Rank
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "#3f526d",
                        width: "20%"
                      }}
                    >
                      Time
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "#3f526d",
                        width: "20%"
                      }}
                    >
                      Date
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "#3f526d",
                        width: "20%"
                      }}
                    >
                      Class Type
                    </Typography>
                  </Box>

                  <Box display="flex" mb="10px" width="100%">
                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 12,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#000"
                        }}
                      >
                        Name of the Chapter
                      </Typography>
                    </Box>

                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 12,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#000"
                        }}
                      >
                        Biology
                      </Typography>
                    </Box>

                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 12,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#3f526d"
                        }}
                      >
                        12:30 Pm
                      </Typography>
                    </Box>
                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 12,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#3f526d"
                        }}
                      >
                        20-09-21
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" width="20%">
                      <Avatar src={"/"} style={{ height: 20, width: 20 }} />
                      <Typography
                        style={{
                          fontSize: 25,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#3f526d"
                        }}
                      >
                        /
                      </Typography>
                      <Avatar src={"/"} style={{ height: 20, width: 20 }} />
                    </Box>
                  </Box>

                  <Box display="flex" mb="10px" width="100%">
                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 12,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#000"
                        }}
                      >
                        Name of the Chapter
                      </Typography>
                    </Box>

                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 12,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#000"
                        }}
                      >
                        Biology
                      </Typography>
                    </Box>

                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 12,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#3f526d"
                        }}
                      >
                        12:30 Pm
                      </Typography>
                    </Box>
                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 12,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#3f526d"
                        }}
                      >
                        20-09-21
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" width="20%">
                      <Avatar src={"/"} style={{ height: 20, width: 20 }} />
                      <Typography
                        style={{
                          fontSize: 25,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#3f526d"
                        }}
                      >
                        /
                      </Typography>
                      <Avatar src={"/"} style={{ height: 20, width: 20 }} />
                    </Box>
                  </Box>
                  <Box display="flex" mb="10px" width="100%">
                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 12,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#000"
                        }}
                      >
                        Name of the Chapter
                      </Typography>
                    </Box>

                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 12,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#000"
                        }}
                      >
                        Biology
                      </Typography>
                    </Box>

                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 12,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#3f526d"
                        }}
                      >
                        12:30 Pm
                      </Typography>
                    </Box>
                    <Box display="flex" width="20%">
                      <Typography
                        style={{
                          fontSize: 12,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#3f526d"
                        }}
                      >
                        20-09-21
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" width="20%">
                      <Avatar src={"/"} style={{ height: 20, width: 20 }} />
                      <Typography
                        style={{
                          fontSize: 25,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#3f526d"
                        }}
                      >
                        /
                      </Typography>
                      <Avatar src={"/"} style={{ height: 20, width: 20 }} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" width="30%">
              <Box display="flex" justifyContent="flex-end" mt="15px">
                <Button
                  style={{
                    background:
                      "linear-gradient(255deg, #b6a5fe 115%, #7f6cfc)",
                    height: 39,
                    width: 123,
                    borderRadius: 8
                  }}
                  variant="contained"
                  onClick={()=>{this.logoutHandleClick()}}
                >
                  <Typography
                    style={{
                      textTransform: "none",
                      fontSize: 15,
                      fontFamily: "Open sans",
                      fontWeight: 600,
                      color: "white"
                    }}
                  >
                    Logout
                  </Typography>
                </Button>
              </Box>
              <Box
                mt="10px"
                display="flex"
                flexDirection="column"
                style={{
                  height: 160,
                  borderRadius: 10,
                  background:
                    "linear-gradient(73deg, #f7627b -8%, #f38d9e 125%)",
                  padding: "22px 20px 20px 30px"
                }}
              >
                <Box display="flex" alignItems="center">
                  <Avatar src="/" style={{ height: 37, width: 37 }} />
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Open sans",
                      fontWeight: 600,
                      color: "white",
                      marginLeft: 20
                    }}
                  >
                    Notification
                  </Typography>
                </Box>
                <Typography
                  style={{
                    fontSize: 16,
                    fontFamily: "Open sans",
                    fontWeight: 600,
                    color: "white",
                    marginTop: 23
                  }}
                >
                  Tomorrow we are having annual meeting at 12 O’cl… READ MORE
                </Typography>
                <Box display="flex" mt="16px">
                  <Button
                    disableRipple={true}
                    style={{ padding: 0, backgroundColor: "transparent" }}
                  >
                    <Typography
                      style={{
                        fontSize: 13,
                        fontFamily: "Open sans",
                        fontWeight: 600,
                        color: "white",
                        textDecoration: "underline"
                      }}
                    >
                      VIEW ALL
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box
                mt="20px"
                display="flex"
                flexDirection="column"
                style={{
                  height: 536,
                  borderRadius: 10,
                  boxShadow: "0 7px 22px 0 rgba(227, 218, 218, 0.5)",
                  backgroundColor: "white",
                  padding: "30px 20px 23px 20px"
                }}
              >
                <Box
                  mb="6px"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    style={{
                      fontSize: 24,
                      fontFamily: "Open sans",
                      fontWeight: "normal",
                      color: "black"
                    }}
                  >
                    Notes
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <IconButton>
                      <EditOutlined style={{ height: 22, width: 22 }} />
                    </IconButton>
                    <IconButton
                      disableRipple={true}
                      style={{ padding: 0, backgroundColor: "transparent" }}
                    >
                      <MoreVertOutlined style={{ height: 22, width: 22 }} />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  mt="12px"
                  display="flex"
                  flexDirection="column"
                  bgcolor="#ffeeca"
                  borderRadius="20px"
                  height="90px"
                  padding="16px 23px 23px 23px"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      style={{
                        fontSize: 16,
                        fontFamily: "Open sans",
                        fontWeight: 600,
                        color: "black"
                      }}
                    >
                      Biology lecture Note
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 12,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "black"
                      }}
                    >
                      10 feb,2020
                    </Typography>
                  </Box>

                  <Typography
                    style={{
                      fontSize: 14,
                      fontFamily: "Open sans",
                      fontWeight: "normal",
                      color: "black",
                      marginTop: 14
                    }}
                  >
                    simply dummy text of the printing and typesetting industry.
                  </Typography>
                </Box>
                <Box
                  mt="12px"
                  display="flex"
                  flexDirection="column"
                  bgcolor="#caf2ff"
                  borderRadius="20px"
                  height="90px"
                  padding="16px 23px 23px 23px"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      style={{
                        fontSize: 16,
                        fontFamily: "Open sans",
                        fontWeight: 600,
                        color: "black"
                      }}
                    >
                      Biology lecture Note
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 12,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "black"
                      }}
                    >
                      10 feb,2020
                    </Typography>
                  </Box>

                  <Typography
                    style={{
                      fontSize: 14,
                      fontFamily: "Open sans",
                      fontWeight: "normal",
                      color: "black",
                      marginTop: 14
                    }}
                  >
                    simply dummy text of the printing and typesetting industry.
                  </Typography>
                </Box>
                <Box
                  mt="12px"
                  display="flex"
                  flexDirection="column"
                  bgcolor="#e6e2ff"
                  borderRadius="20px"
                  height="90px"
                  padding="16px 23px 23px 23px"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      style={{
                        fontSize: 16,
                        fontFamily: "Open sans",
                        fontWeight: 600,
                        color: "black"
                      }}
                    >
                      Biology lecture Note
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 12,
                        fontFamily: "Open sans",
                        fontWeight: "normal",
                        color: "black"
                      }}
                    >
                      10 feb,2020
                    </Typography>
                  </Box>

                  <Typography
                    style={{
                      fontSize: 14,
                      fontFamily: "Open sans",
                      fontWeight: "normal",
                      color: "black",
                      marginTop: 14
                    }}
                  >
                    simply dummy text of the printing and typesetting industry.
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" mt="17px">
                  <Button
                    style={{
                      outline: "none",
                      width: 117,
                      height: 41,
                      borderRadius: 20,
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#7f6cfc"
                    }}
                    variant="outlined"
                  >
                    <Typography
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        fontFamily: "Open sans",
                        fontWeight: 600,
                        color: "black"
                      }}
                    >
                      View All
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    //padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#ffffff"
  },
  dashboardItemView: {
    flex: 1,
    marginHorizontal: 5,
    marginVertical: hp("2%"),
    width: Platform.OS === "web" ? "80vw" : wp("90%"),
    maxWidth: Platform.OS === "web" ? 600 : wp("90%"),
    borderRadius: hp("3%"),
    backgroundColor: "#ffffff",
    shadowColor: "#c3c3c3",
    shadowOffset: {
      width: 2,
      height: 3
    },
    shadowOpacity: 0.6,
    shadowRadius: 5.62,
    elevation: 6
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});

export const DashboardCreateStyle = {
  dropdown: {
    padding: 0,
    border: "1px solid #e6e6e6",
    height: 45,
    width: 180,
    color: "#3f526d",
    fontFamily: "Open sans",
    fontWeight: 400,
    fontSize: 18,
    paddingLeft: 16,
    outline: "none",
    borderRadius: 9,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 9
      // backgroundColor: "white",
      // zIndex: 2
    }
  }
};

export default withStyles(DashboardCreateStyle)(
  Dashboard as React.ComponentType<any>
);
// Customizable Area End
