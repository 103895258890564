// Customizable Area Start
import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import { CKEditor } from "ckeditor4-react";
// Customizable Area End

interface ITextEditorProps {
  // Customizable Area Start
  handleChange: (value: any) => void;
  editorData: any;
  isEdit?: boolean;
  myref?: any;
}

interface IEditorProps {
  editor: any;
}

const useStyles = makeStyles(() => ({
  editorDiv: {
    marginBottom: "2%",
  },
}));
// Customizable Area End

// Customizable Area Start
export const TextEditor: React.FC<ITextEditorProps> = ({
  handleChange,
  editorData,
  myref,
}) => {
  const classes = useStyles();
  const handleOnChange = (event: any) => {
    handleChange(event?.editor.getData());
  };

  return (
    <div ref={myref} className={classes.editorDiv}>
      <CKEditor
        //debug={true}
        //name="textEditor"
        initData={editorData}
        //onInstanceReady={(e: any) => setRef(e)}
        onChange={(e: any) => handleOnChange(e)}
        config={{
          removeButtons:
            "Underline,Paste,PasteText,PasteFromWord,SpellCheckAsYouType,Source,RemoveFormat,Anchor,Image,Table,Iframe,Maximize,About,Scayt,HorizontalRule,Flash,Link,Unlink,Copy,Cut,InsertPre,PageBreak,Iframe,Styles,Indent",
          // toolbarGroups: "JustifyLeft,JustifyCenter",
          extraPlugins: [
            "ckeditor_wiris",
            "codesnippet",
            "editorplaceholder",
            "colorbutton",
            "colordialog",
          ],
          editorplaceholder: "Type here...",
          codeSnippet_theme: "monokai",
          allowedContent: true,
          // toolbar: [
          //   {
          //     name: "wiris",
          //     items: [
          //       "ckeditor_wiris_formulaEditor",
          //       "ckeditor_wiris_formulaEditorChemistry",
          //     ],
          //   },
          //   { name: "wiris", items: ["Wiris"] },
          // ],
        }}
        onBeforeLoad={(CKEDITOR) => {
          CKEDITOR.plugins.addExternal(
            "ckeditor_wiris",
            "/mathtype-ckeditor4/",
            "plugin.js"
          );
        }}
      />
    </div>
  );
};
// Customizable Area End

// Customizable Area Start
export default TextEditor;
// Customizable Area End
