// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Grid ,Typography} from '@material-ui/core';import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
}),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    }
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

function createData(title: string, term: number, term2: number, term3: number) {
  return { title, term, term2, term3 };
}

const rows = [
  createData('Total Attendence of the learner', 20, 10, 5),
  createData('Total Number of working days', 20, 10, 4.0)
];
// Customizable Area End

// Customizable Area Start
export default function ProfileDetails() {
  const classes = useStyles();
  const [selectclass, setSelectclass] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date('2014-08-18T21:11:54'),
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectclass(event.target.value as string);
  };
  
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  return (
    <div>
      <div className='profileTitle'>
      <Button className="backBtn"><KeyboardBackspaceOutlinedIcon /> Back</Button>
      <h2>Part 1 - Assessment of Learning </h2>
      <Button className="saveChangebtn commonBtnSecondary">Enable Edit <EditOutlinedIcon /> </Button>
      <Button className="editBtn commonBtnPrimary">Save Changes <BookmarkOutlinedIcon /> </Button>
      </div>
      <div className="tableContent">
         <TableContainer className="tableSectionpart">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff", borderTopLeftRadius: "5px" }}>
                                Scholastic Areas <EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell colSpan={6} align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                                Term 1 (100 Marks) <EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff", borderTopRightRadius: "5px" }}>
                                <EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                <Typography style={{ textAlign: "center" }}>Weekly Test</Typography>
                                <Box style={{ margin: '-16px', paddingTop: "29px" }}>
                                    <Grid container >
                                        <Grid item xs={6} style={{ borderLeft: "0px", borderBottom: "0px", borderTop: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center", padding: "10px" }}>
                                            WA<br />(20)
                                        </Grid>
                                        <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9", borderBottom: "0px", borderTop: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center", padding: "10px" }}>
                                            WA<br />(20)
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center" }}>
                                <Typography style={{ paddingBottom: "12px" }}>Best out of 2 WA</Typography>
                                <br />(20)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center" }}>
                                <Typography style={{ paddingBottom: "12px" }}>Notebook <br />Maintenance</Typography>
                                (10)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center" }}>
                                <Typography style={{ paddingBottom: "12px" }}>Subject<br /> Enrichment</Typography>
                                (20)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center" }}>
                                <Typography style={{ paddingBottom: "30px" }}>CC SA 1</Typography>
                                (50)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center" }}>
                                <Typography > Marks <br />Obtained</Typography>
                                <br />
                                (100)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center" }}>
                                <Typography>  Grade</Typography>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                                Language 1:<br />  English
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={6} style={{ borderLeft: "0px", borderBottom: "0px", textAlign: "center", padding: "36px" }}>
                                        </Grid>
                                        <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9", borderBottom: "0px", textAlign: "center", padding: "36px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                                Language 2
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={6} style={{ borderLeft: "0px", borderBottom: "0px", textAlign: "center", padding: "31px" }}>
                                        </Grid>
                                        <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9", borderBottom: "0px", textAlign: "center", padding: "31px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                                Mathematics
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={6} style={{ borderLeft: "0px", borderBottom: "0px", textAlign: "center", padding: "31px" }}>
                                        </Grid>
                                        <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9", borderBottom: "0px", textAlign: "center", padding: "31px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                                EVS
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={6} style={{ borderLeft: "0px", borderBottom: "0px", textAlign: "center", padding: "31px" }}>
                                        </Grid>
                                        <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9", borderBottom: "0px", textAlign: "center", padding: "31px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={6} style={{ borderLeft: "0px", borderBottom: "0px", textAlign: "center", padding: "20px" }}>
                                        <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                                        </Grid>
                                        <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9", borderBottom: "0px", textAlign: "center", padding: "20px" }}>
                                        <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            <TableContainer className="tableSectionpart">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff", borderTopLeftRadius: "5px" }}>
                                Scholastic Areas <EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell colSpan={7} align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff", borderTopRightRadius: "5px" }}>
                                Term 2 (100 Marks) <EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell colSpan={7} align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff", borderTopLeftRadius: "5px" }}>
                                <EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                <Typography style={{ textAlign: "center" }}>Weekly Test</Typography>
                                <Box style={{ margin: '-16px', paddingTop: "29px" }}>
                                    <Grid container >
                                        <Grid item xs={12} style={{ borderLeft: "0px", borderBottom: "0px", borderTop: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center", padding: "10px" }}>
                                            WA<br />(20)
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                <Typography style={{ paddingBottom: "12px" }}>Notebook <br /> Maintenance</Typography>
                                <br />(10)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                <Typography style={{ paddingBottom: "12px" }}> Subject <br /> Enrichment</Typography>
                                <br />(20)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                <Typography style={{ paddingBottom: "30px" }}> CC SA 2</Typography>
                                <br />(50)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                <Typography> Marks <br /> Obtained</Typography>
                                <br />(100)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                <Typography>  Grade</Typography>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                                Language 1:<br /> English
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                                Language 2
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                                Mathematics
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                                EVS
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", borderRadius:"0px",textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" , textAlign: "center"}}>
                            <AddOutlinedIcon style={{ color: "#D9D9D9" }} />

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            <TableContainer className="tableSectionpart">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff", borderTopLeftRadius: "5px" }}>
                                Scholastic Areas <EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell colSpan={6} align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff", borderRadius: "0px" }}>
                                Term 3 (100 Marks) <EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff", borderTopRightRadius: "5px" }}>
                                <EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                <Typography style={{ textAlign: "center" }}>Weekly Test</Typography>
                                <Box style={{ margin: '-16px', paddingTop: "29px" }}>
                                    <Grid container >
                                        <Grid item xs={12} style={{ borderLeft: "0px", borderBottom: "0px", borderTop: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center", padding: "10px" }}>
                                            WA<br />(20)
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>

                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                <Typography style={{ paddingBottom: "12px" }}>Notebook <br />Maintenance</Typography>
                                <br /> (10)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                <Typography style={{ paddingBottom: "12px" }}>Subject<br /> Enrichment</Typography>
                                <br />(20)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                <Typography style={{ paddingBottom: "12px" }}>CC SA 3</Typography>
                                <br />(50)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                <Typography style={{ paddingBottom: "12px" }}>Marks <br />Obtained</Typography>
                                <br />(100)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                <Typography> Grade</Typography>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                                Language 1:<br />  English
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                                Language 2
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                                Mathematics
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", backgroundColor: "#F2F2F2" }}>
                                EVS
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", borderRadius:"0px",textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px", textAlign: "center" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            {/* table 3 end */}
      </div>
    </div>
  )
}
// Customizable Area End
