// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createRef, RefObject } from "react";
import { computeChecksumMd5 } from "../utils/common";
import axios from "axios";
import { toast } from "react-toastify";
import convert from "xml-js";
import { HISTORY } from "../../../../components/src/comman";

export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  location?: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  questionId: any;
  data: any;
  showLoader: boolean;
  marks: any;
  negativeMarks: any;
  wordLimit: any;
  selectedSubject: any;
  selectedChapter: any;
  selectedUnit: any;
  selectedSkill: any;
  selectedProficiency: any;
  selectedDifficultyLevel: any;
  keywordsList: Array<any>;
  keyword: string;
  remainSectionMarkPr: number;
  alertModal: boolean;
  alertMessage: string;
  openSelectImageModal: boolean;
  question: string;
  chapterListData: any;
  unitListData: any;
  selectedImageBlobUrl: any;
  selectedImageFile: any;
  selectedImageSignedId: any;
  hintAnswer: any;
  hintsNumberr: number;
  explaination: string;
  showLoaderForImageUpload: boolean;
  openChooseFromGalleryModal: boolean;
  solutionImageSignedId: any;
  selectedCategoryQuestionData: any;
  assessmentSectionId: number;
  selectedGalleryImageId: number;
  selectedGalleryImageUrl: string;
  marksError: boolean;
  questionError: boolean;
  metaDataErrors: any;
  negmarksError: boolean;
  marksErrorText: string;
  sectiondata: any;
  editButton: boolean;
  attachmentExplanationUrl: any;
  hintsExplanationUrl: string;
  explanationId: string;
  assessmentHasNegativeMarking: boolean;
  explainationAttachmentUploadId: string;
  selectedBloomTaxonomy: any;
  hintId: any;
  hintsAttachmentUploadId: string;
  prevQuestionModal: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ShortAnswerTypeQuestionContoller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuestionListId: string = "";
  deleteQuestionId: string = "";
  getChapterListId: string = "";
  getTopicListId: string = "";
  apiSaveQuesionId: string = "";
  apiPhotoPreSignedID: string = "";
  apiUploadID: string = "";
  apiDeleteSolutionAttachment: string = "";
  readonly inputOpenFileRef: RefObject<HTMLInputElement>;
  questionEditorRef: React.Ref<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      questionId: null,
      data: "",
      showLoader: false,
      marks: 0,
      negativeMarks: 0,
      wordLimit: "",
      selectedSubject: "",
      selectedChapter: "",
      selectedUnit: "",
      selectedSkill: "",
      selectedProficiency: "",
      selectedDifficultyLevel: "",
      keywordsList: [],
      keyword: "",
      hintId: null,
      hintsAttachmentUploadId: "",
      remainSectionMarkPr: 0,
      alertModal: false,
      alertMessage: "",
      openSelectImageModal: false,
      question: "",
      hintsExplanationUrl: "",
      chapterListData: [],
      unitListData: [],
      selectedImageBlobUrl: "",
      selectedImageFile: null,
      selectedImageSignedId: null,
      showLoaderForImageUpload: false,
      openChooseFromGalleryModal: false,
      explaination: "",
      hintsNumberr: 0,
      solutionImageSignedId: null,
      hintAnswer: [],
      explanationId: "",
      editButton: false,
      negmarksError: false,
      marksErrorText: "",
      selectedCategoryQuestionData: null,
      assessmentSectionId: 0,
      selectedGalleryImageId: 0,
      selectedGalleryImageUrl: "",
      marksError: false,
      questionError: false,
      metaDataErrors: {
        chapter: false,
        unit: false,
        skill: false,
        proficiency: false,
        keywords: false,
        difficultyLevel: false,
        bloomTaxonomy: false,
      },
      sectiondata: {},
      attachmentExplanationUrl: "",
      assessmentHasNegativeMarking: JSON.parse(
        localStorage.getItem("assessmentData") || "{}"
      )?.negative_marking,
      explainationAttachmentUploadId: "",
      selectedBloomTaxonomy: "",
      prevQuestionModal: false,
    };
    this.userdata = this.userdata.bind(this);
    this.inputOpenFileRef = createRef();
    this.questionEditorRef = createRef();

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  handleEditQuestionData = (data: any) => {
    const edit = data.attributes;
    this.setState({
      marks: edit.marks,
      negativeMarks: edit.negative_marks,
      wordLimit: edit.word_limit,
      question: edit.title,
      assessmentSectionId: data.assessmentSectionId,
    });
  };

  async componentDidMount() {
    super.componentDidMount();
    this.get_chapter_list();
    const { questionData, sectiondata, sectionId } = HISTORY.location.state;
    if (HISTORY.location.state?.isEdit) {
      const index = questionData?.attributes?.solutions?.data.length - 1;
      let ImageIndex = questionData?.attributes?.attachments?.data?.length - 1;

      this.setState({
        editButton: HISTORY.location.state?.isEdit,
        marks: questionData?.attributes?.marks,
        negativeMarks: questionData?.attributes?.negative_marks,
        question: questionData?.attributes?.title,
        wordLimit: questionData?.attributes?.word_limit,
        explaination:
          questionData?.attributes?.solutions?.data[index]?.attributes
            ?.explaination,
        explanationId: questionData?.attributes?.solutions?.data[index]?.id,
        selectedCategoryQuestionData: questionData?.attributes?.type,
        selectedChapter:
          questionData?.attributes?.metadata?.data?.attributes?.chapter_id,
        selectedUnit:
          questionData?.attributes?.metadata?.data?.attributes?.unit_id,
        selectedBloomTaxonomy:
          questionData?.attributes?.metadata?.data?.attributes?.bloom_taxonomy,
        selectedProficiency:
          questionData?.attributes?.metadata?.data?.attributes?.proficiency,
        selectedSkill:
          questionData?.attributes?.metadata?.data?.attributes?.skill,
        selectedDifficultyLevel:
          questionData?.attributes?.metadata?.data?.attributes
            ?.difficulty_level,
        keywordsList:
          questionData?.attributes?.metadata.data.attributes.keywords,
        questionId: questionData?.id,
        remainSectionMarkPr:
          ((sectiondata?.section_marks - sectiondata?.remaining_marks) * 100) /
          sectiondata?.section_marks,
        sectiondata: sectiondata,
        selectedImageBlobUrl:
          questionData?.attributes?.attachments?.data?.[ImageIndex]?.attributes
            ?.url,
        attachmentExplanationUrl:
          questionData?.attributes?.solutions?.data?.[0]?.attributes
            ?.attachments?.data?.[0]?.attributes?.url,
        explainationAttachmentUploadId:
          questionData?.attributes?.solutions?.data?.[0]?.attributes
            ?.attachments?.data?.[0]?.id,
        hintsAttachmentUploadId:
          questionData?.attributes?.hints?.data?.[0]?.attributes?.attachments
            ?.data?.[0]?.id,
        assessmentSectionId: sectionId,
        hintsExplanationUrl: questionData?.attributes?.hints?.data?.[0]?.attributes?.attachments?.data?.[0]?.attributes?.url,
      });

      if (questionData?.attributes?.hints.data.length != 0) {
        questionData?.attributes?.hints.data.map((data: any, index: number) => {
          this.state.hintAnswer.push({
            id: data.id,
            hintId: index + 1,
            hint: data.attributes.hint,
            src:
              data.attributes.attachments.data.length != 0
                ? data.attributes.attachments.data[0].attributes.url
                : "",
          });
        });
      }
      else {
        this.setState({
          hintAnswer: [
            {
              hintId: 0,
              hint: "",
            },
          ]
        })
      }
      this.setState({
        hintsNumberr: questionData?.attributes?.hints?.data?.length,
      });
    } else {
      this.setState({
        hintAnswer: [
          {
            hintId: 0,
            hint: "",
          },
        ],
        sectiondata: sectiondata,
        selectedCategoryQuestionData: questionData,
        assessmentSectionId: sectionId,
        remainSectionMarkPr:
          ((sectiondata?.section_marks - sectiondata?.remaining_marks) * 100) /
          sectiondata?.section_marks,
      });
    }
  }

  getExplanation = () => {
    if (HISTORY?.location?.state) {
      const { questionData } = HISTORY.location.state;
      const index = questionData?.attributes?.solutions?.data.length - 1;
      if (
        questionData?.attributes?.solutions?.data[index]?.attributes?.explaination
      )
        return questionData?.attributes?.solutions?.data[index]?.attributes
          ?.explaination;

      return "";
    } else {
      HISTORY.push("/TeacherAssessmentCreateSection");
    }
  };

  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
  };

  plusMarksClick = () => {
    if (this.state.marks + 1 > 0) {
      this.setState({ marksError: false });
    } else {
      this.setState({
        marksError: true,
        marksErrorText: "Please enter marks.",
      });
    }
    if (this.state.marks < this.state.sectiondata?.remaining_marks) {
      this.setState({ marks: this.state.marks + 1, marksError: false });
    } else if (
      this.state.marks ===
      this.state.sectiondata?.remaining_marks - 1
    ) {
      this.setState({ marks: this.state.marks + 1, marksError: false });
    }
    if (
      this.state.marks + 1 > this.state.negativeMarks ||
      this.state.marks + 1 == this.state.negativeMarks
    ) {
      this.setState({ marks: this.state.marks + 1, negmarksError: false });
    }

    if (this.state.editButton) {
      if (
        this.state.marks + 1 >
        this.state.sectiondata?.remaining_marks +
        HISTORY.location.state?.questionData?.attributes?.marks
      ) {
        this.setState({
          marks: this.state.marks + 1,
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
      }
    } else {
      if (this.state.marks + 1 > this.state.sectiondata?.remaining_marks) {
        this.setState({
          marks: this.state.marks + 1,
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
      }
    }

    this.setState({
      marks: this.state.marks + 1,
    });
  };

  minusMarksClick = () => {
    if (this.state.negativeMarks + 1 > this.state.marks) {
      this.setState({ negmarksError: true });
    }
    this.setState({ negativeMarks: this.state.negativeMarks + 1 });
  };

  onChangeKeywords = (e: any) => {
    this.setState({ keyword: e.target.value });
  };

  onBlurKeywords = (e: any) => {
    this.setState({ keyword: e.target.value });
    if (e.target.value !== "") {
      let { keywordsList } = this.state;
      keywordsList = [...keywordsList, e.target.value];
      this.setState({ keywordsList: keywordsList, keyword: "" });
      if (keywordsList.length > 0) {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: false },
        });
      } else {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: true },
        });
      }
    }
  };

  onKeyDownKeywords = (e: any) => {
    this.setState({ keyword: e.target.value });
    if (e?.key === "Enter" && e.target.value !== "") {
      let { keywordsList } = this.state;
      keywordsList = [...keywordsList, e.target.value];
      this.setState({ keywordsList: keywordsList, keyword: "" });
      if (keywordsList.length > 0) {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: false },
        });
      } else {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: true },
        });
      }
    }
  };

  handleDeleteKeyword = (item: any) => {
    let { keywordsList } = this.state;
    keywordsList = [...keywordsList.filter((x: any) => x !== item)];
    this.setState({ keywordsList: keywordsList, keyword: "" });
    if (keywordsList.length > 0) {
      this.setState({
        metaDataErrors: { ...this.state.metaDataErrors, keywords: false },
      });
    } else {
      this.setState({
        metaDataErrors: { ...this.state.metaDataErrors, keywords: true },
      });
    }
  };

  showOpenFileDlg = () => {
    this.inputOpenFileRef?.current?.click();
  };

  handleFileOnChange = async (
    event: React.ChangeEvent<any>,
    filesFromDragAndDrop: any
  ) => {
    let files: any;
    if (filesFromDragAndDrop !== null) {
      files = filesFromDragAndDrop;
    } else {
      files = event.target.files;
    }

    const file = files[0];
    var blobURL = URL.createObjectURL(file);
    this.setState({
      openSelectImageModal: false,
      selectedImageBlobUrl: blobURL,
      selectedImageFile: file,
      showLoaderForImageUpload: true,
    });

    await computeChecksumMd5(file).then((md5: any) => {
      const fileDetails = {
        fileName: file.name,
        fileSize: file.size,
        checksum: md5,
        content_type: file.type,
      };
      this.getPhotoPreSignedId(fileDetails);
    });
  };

  removeSelectedImage = () => {
    this.setState({
      selectedImageBlobUrl: "",
      selectedImageFile: null,
      selectedImageSignedId: null,
      selectedGalleryImageId: 0,
      selectedGalleryImageUrl: "",
    });
  };

  onClickGalleryImageChoose = () => {
    this.setState({
      openChooseFromGalleryModal: false,
      selectedImageBlobUrl: this.state.selectedGalleryImageUrl,
      selectedImageFile: null,
      selectedImageSignedId: null,
    });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getChapterListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ chapterListData: responseJson?.data }, () =>
              this.get_unit_list(this.state.selectedChapter)
            );
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getTopicListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            // 
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiSaveQuesionId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            if (this.state.editButton) {
              toast.success("Question updated successfully.");
            } else {
              toast.success("Question created successfully.");
            }
            // Update question to section list
            this.updateSectionQuestion(responseJson.data)
            // Update question to section list END
            this.setState({ showLoader: false });
            HISTORY.push("/TeacherAssessmentCreateSection");
          } else {
            this.parseApiErrorResponse(responseJson);
            this.setState({ showLoader: false });
          }
        }
        this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiPhotoPreSignedID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const imageData = this.state.selectedImageFile;
            const msg: Message = new Message(
              getName(MessageEnum.UploadMediaMessage)
            );
            const uploadFileData: any = {
              responseJson: responseJson,
              imageData,
              messageId: msg.messageId,
            };
            msg.addData(
              getName(MessageEnum.UploadMediaDataMessage),
              uploadFileData
            );
            this.apiUploadID = msg.messageId;
            runEngine.sendMessage(msg.id, msg);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiUploadID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const keyValue = JSON.parse(
              convert.xml2json(responseJson, {
                spaces: 1,
                compact: true,
                ignoreComment: true,
                alwaysChildren: true,
                ignoreDeclaration: true,
              })
            );
            if (keyValue?.PostResponse) {
              this.setState({
                selectedImageSignedId: keyValue.PostResponse.Key._text,
                showLoaderForImageUpload: false,
                selectedGalleryImageId: 0,
              });
            } else {
              toast.error("Something went wrong while uploading the file!");
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false, showLoaderForImageUpload: false });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  updateSectionQuestion = (questionData: any) => {
    const { sectionId } = HISTORY.location.state;
    const assessmentData = JSON.parse(localStorage.getItem("assessmentData") || "{}");

    const question = {
      id: questionData.id,
      title: questionData?.attributes?.title,
      type: questionData?.attributes?.type?.name,
      marks: questionData?.attributes?.marks,
      negative_marks: questionData?.attributes?.negative_marks,
    };
    assessmentData.assessment_sections_attributes.map((sectionData: any, index: number) => {
      if (sectionData.id === sectionId) {
        // setting question data
        if (sectionData.question_data?.length > 0) {
          const qIndex = sectionData.question_data.findIndex((data: any) => Number(data.id) === Number(question.id));
          if (qIndex != -1) {
            sectionData.question_data[qIndex] = question;
          } else {
            sectionData.question_data.push(question);
          }
        } else {
          sectionData.question_data = [question];
        }
        // setting question data END

        // setting section marks
        const totalQuestionMarks = sectionData.question_data.reduce((sum: any, current: any) => (sum + current.marks), 0);
        sectionData.remaining_marks = sectionData.section_marks - totalQuestionMarks;
        // setting section marks END
        return;
      }
    })
    localStorage.setItem("assessmentData", JSON.stringify(assessmentData))
  }

  get_chapter_list = () => {
    const assessment_data = localStorage.getItem("assessmentData");
    const assessmentData = JSON.parse(assessment_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getChapterListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chapterEndPoint + `?subject_id=${assessmentData?.subject_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_topic_list = (id?: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTopicListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topicEndPoint + `?chapter_id=${Number(id)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_unit_list = (id?: any, resetUnit?: boolean) => {
    const { chapterListData } = this.state;
    const chapterUnits = chapterListData?.find(
      (chapter: any) => Number(chapter.id) === Number(id)
    );
    this.setState({ unitListData: chapterUnits?.attributes?.units?.data });

    if (resetUnit) {
      this.setState({ selectedUnit: "" });
    }
  };

  getEmptyState = () => ({
    marksError: false,
    questionError: false,
    metaDataErrors: {
      chapter: false,
      unit: false,
      skill: false,
      proficiency: false,
      keywords: false,
      difficultyLevel: false,
      bloomTaxonomy: false,
    },
  });

  validFormData = () => {
    let isValid = true;

    const {
      question,
      marks,
      selectedChapter,
      selectedUnit,
      selectedSkill,
      selectedProficiency,
      selectedDifficultyLevel,
      keywordsList,
      selectedBloomTaxonomy,
    } = this.state;

    const errors = this.getEmptyState().metaDataErrors;
    let questionErr = this.getEmptyState().questionError;
    let marksErr = this.getEmptyState().marksError;

    if (marks === null || Number(marks) === 0) {
      this.setState({
        marksError: true,
        marksErrorText: "Please enter marks.",
      });
      isValid = false;
    }
    if (this.state.editButton) {
      if (
        this.state.marks >
        this.state.sectiondata?.remaining_marks +
        HISTORY.location.state?.questionData?.attributes?.marks
      ) {
        this.setState({
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
        isValid = false;
      }
    } else {
      if (this.state.marks > this.state.sectiondata?.remaining_marks) {
        this.setState({
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
        isValid = false;
      }
    }

    if (this.state.negativeMarks > this.state.marks) {
      this.setState({ negmarksError: true });
      isValid = false;
    }
    if (question === null || question === "") {
      questionErr = true;
      isValid = false;
    }

    if (selectedChapter === null || selectedChapter === "") {
      errors.chapter = true;
      isValid = false;
    }

    if (selectedUnit === null || selectedUnit === "") {
      errors.unit = true;
      isValid = false;
    }

    if (selectedSkill === null || selectedSkill === "") {
      errors.skill = true;
      isValid = false;
    }

    if (selectedProficiency === null || selectedProficiency === "") {
      errors.proficiency = true;
      isValid = false;
    }

    if (selectedDifficultyLevel === null || selectedDifficultyLevel === "") {
      errors.difficultyLevel = true;
      isValid = false;
    }

    if (keywordsList.length === 0) {
      errors.keywords = true;
      isValid = false;
    }

    if (selectedBloomTaxonomy === null || selectedBloomTaxonomy === "") {
      errors.bloomTaxonomy = true;
      isValid = false;
    }

    this.setState({ metaDataErrors: errors, questionError: questionErr });

    return isValid;
  };

  saveQuestion = () => {
    if (this.validFormData()) {
      this.saveQuestionApi();
    }
  };

  saveQuestionApi = () => {
    let final_hint_answer: any = [];
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id,
    };

    const {
      editButton,
      marks,
      negativeMarks,
      wordLimit,
      explanationId,
      question,
      hintAnswer,
      selectedChapter,
      explaination,
      solutionImageSignedId,
      selectedUnit,
      selectedSkill,
      selectedProficiency,
      selectedDifficultyLevel,
      keywordsList,
      selectedImageSignedId,
      selectedCategoryQuestionData,
      assessmentSectionId,
      selectedGalleryImageId,
      hintId,
      selectedBloomTaxonomy,
      explainationAttachmentUploadId,
    } = this.state;
    hintAnswer?.map((data: any) => {
      final_hint_answer.push({
        id: data.id,
        files: data.files ? data.files : [],
        hint: data.hint,
      });
    });
    const assessmentData = JSON.parse(localStorage.getItem("assessmentData") || "{}");
    const httpBody: any = {
      subject_id: assessmentData.subject_id,
      grade_id: assessmentData.grade_id,
      marks: marks,
      word_limit: Number(wordLimit),
      title: question,
      question_sub_category_id: selectedCategoryQuestionData?.id,
      negative_marks: negativeMarks,
      is_negative_marking: negativeMarks > 0 ? true : false,
      assessment_section_id: assessmentSectionId,
      solutions_attributes: [
        {
          id: explanationId,
          explaination: explaination,
        },
      ],
      hints_attributes: final_hint_answer,
      metadata_attributes: {
        chapter_id: selectedChapter,
        unit_id: selectedUnit,
        skill: selectedSkill,
        proficiency: selectedProficiency,
        difficulty_level: selectedDifficultyLevel,
        bloom_taxonomy: selectedBloomTaxonomy,
        keywords: keywordsList,
      },
    };

    if (
      this.state.editButton &&
      HISTORY.location.state?.questionData?.attributes?.solutions?.data
        ?.length != 0
    ) {
      // remove attachment when no new image selected
      if (
        this.state.explainationAttachmentUploadId &&
        !solutionImageSignedId &&
        !this.state.attachmentExplanationUrl
      ) {
        this.removeExplainationAttachment(
          this.state.explainationAttachmentUploadId
        );
      } else {
        // insert new image if initialy no image uploaded
        if (
          (this.state.explainationAttachmentUploadId === undefined ||
            this.state.explainationAttachmentUploadId === "") &&
          solutionImageSignedId !== null
        ) {
          httpBody.solutions_attributes[0].files =
            solutionImageSignedId === "" || solutionImageSignedId === null
              ? []
              : [solutionImageSignedId];
        }
        // Update Image
        else if (solutionImageSignedId && explainationAttachmentUploadId) {
          httpBody.solutions_attributes[0].files = [solutionImageSignedId];
          httpBody.solutions_attributes[0].old_upload_id =
            HISTORY.location.state?.questionData?.attributes?.solutions?.data?.[0]?.attributes?.attachments?.data[0]?.id;
        }
      }
    } else {
      httpBody.solutions_attributes[0].files =
        solutionImageSignedId === "" || solutionImageSignedId === null
          ? []
          : [solutionImageSignedId];
    }
    if (selectedGalleryImageId) {
      if (HISTORY.location.state?.questionData?.attributes?.attachments?.data?.length > 0) {
        const ImageIndex = HISTORY.location.state?.questionData?.attributes?.attachments?.data?.length - 1;
        httpBody.old_upload_id = HISTORY.location.state?.questionData?.attributes?.attachments?.data?.[ImageIndex]?.id;
      }
      httpBody.upload_id = selectedGalleryImageId;
    } else {
      const ImageIndex =
        HISTORY.location.state?.questionData?.attributes?.attachments?.data
          ?.length - 1;
      if (
        this.state.editButton && selectedImageSignedId &&
        HISTORY.location.state?.questionData?.attributes?.attachments?.data
          ?.length != 0
      ) {
        httpBody.files = [selectedImageSignedId];
        httpBody.old_upload_id =
          HISTORY.location.state?.questionData?.attributes?.attachments?.data?.[
            ImageIndex
          ]?.id;
      } else if (selectedImageSignedId) {
        httpBody.files =
          selectedImageSignedId === "" || selectedImageSignedId === null
            ? []
            : [selectedImageSignedId];
      }
    }
    // Hint Attributes

    if (
      this.state.editButton &&
      HISTORY.location.state?.questionData?.attributes?.hints?.data?.length != 0
    ) {
      // remove attachment when no new image selected
      if (
        this.state.hintsAttachmentUploadId &&
        hintId === null &&
        this.state.hintsExplanationUrl === ""
      ) {
        this.removeExplainationAttachment(this.state.hintsAttachmentUploadId);
      } else {
        // insert new image if initialy no image uploaded
        if (
          (this.state.hintsAttachmentUploadId === undefined ||
            this.state.hintsAttachmentUploadId === "") &&
          hintId !== null
        ) {
          httpBody.hints_attributes[0].files =
            hintId === "" || hintId === null ? [] : [hintId];
        }
        // Update Image
        else if (hintId && this.state.hintsAttachmentUploadId) {
          httpBody.hints_attributes[0].files = [hintId];
          httpBody.hints_attributes[0].old_upload_id = this.state.hintsAttachmentUploadId
        }
      }
    } else {
      httpBody.hints_attributes[0].files =
        hintId === "" || hintId === null ? [] : [hintId];
    }
    if ((final_hint_answer[0].files && final_hint_answer[0].files == 0) && final_hint_answer[0].hint == '') {
      delete httpBody.hints_attributes
    }
    this.setState({ showLoader: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSaveQuesionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.editButton
        ? configJSON.saveQuestionEndPoint + `/${this.state.questionId}`
        : configJSON.saveQuestionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.editButton ? configJSON.PUT : configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPhotoPreSignedId = (photoDetail: any) => {
    const header = {
      token: localStorage.getItem("token"),
    };

    let formData = new FormData();
    formData.append("content_type", photoDetail.content_type);

    const httpBody = {
      filename: photoDetail.fileName,
      byte_size: photoDetail.fileSize,
      checksum: photoDetail.checksum,
      content_type: photoDetail.content_type,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );

    this.apiPhotoPreSignedID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  savePhotoUsingURL = async (preSignedResponse: any) => {
    const uploadURL = preSignedResponse.url.replace(/\/+$/, "");
    const urlFields = preSignedResponse.url_fields;
    if (uploadURL != null && uploadURL !== undefined) {
      const fileKey = urlFields.key.replace(
        "${filename}",
        this.state.selectedImageFile.name
      );

      const formData = new FormData();
      formData.append("key", fileKey);
      formData.append("success_action_status", urlFields.success_action_status);
      formData.append("Content-Type", urlFields["Content-Type"]);
      formData.append("Content-Disposition", urlFields["Content-Disposition"]);
      formData.append("policy", urlFields.policy);
      formData.append("x-amz-credential", urlFields["x-amz-credential"]);
      formData.append("x-amz-algorithm", urlFields["x-amz-algorithm"]);
      formData.append("x-amz-date", urlFields["x-amz-date"]);
      formData.append("x-amz-signature", urlFields["x-amz-signature"]);
      formData.append("file", this.state.selectedImageFile);
      try {
        const s3Response = await axios({
          method: "post",
          url: uploadURL,
          data: formData,
        });
        if (s3Response.status === 201) {
          const keyValue = JSON.parse(
            convert.xml2json(s3Response.data, {
              spaces: 1,
              compact: true,
              ignoreComment: true,
              alwaysChildren: true,
              ignoreDeclaration: true,
            })
          );

          this.setState({
            selectedImageSignedId: keyValue.PostResponse.Key._text,
            showLoaderForImageUpload: false,
            selectedGalleryImageId: 0,
          });
        }
      } catch (e) {
        toast.error("Something went wrong.");
      }
    }
  };

  removeExplainationAttachment = (id: any) => {
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteSolutionAttachment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.galleryImagesEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
