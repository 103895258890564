// Customizable Area Start
import React, { RefObject } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import ClearIcon from "@material-ui/icons/Clear";
import { optionImage } from '../assets';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export interface Props {
    navigation?: any;
    id?: string;
    location?: any;
    // Customizable Area Start
    classes:any;
    data: any;
    index: number;
    refIndex:number;
    multipleResponse:boolean;
    multipleInputRef:RefObject<HTMLInputElement>[];
    onOptionImageClick: any;
    removeOptionImage:any;
    answerOptionInputChange:any;
    onOptionUploadImageClick:any;
    onOptionFileInputChange:any;
    onOptionRadioChange:any;
    onOptionMultiRadioChange:any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class McqOption extends React.Component<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
        };

        this.onOptionImageClick = this.onOptionImageClick.bind(this);
        this.removeOptionImage = this.removeOptionImage.bind(this);
        this.answerOptionInputChange = this.answerOptionInputChange.bind(this);
        this.onOptionUploadImageClick = this.onOptionUploadImageClick.bind(this);
        this.onOptionRadioChange = this.onOptionRadioChange.bind(this);
        this.onOptionMultiRadioChange = this.onOptionMultiRadioChange.bind(this);
        // Customizable Area End
    }

    // Customizable Area Start
    onOptionImageClick() {
        this.props.onOptionImageClick(this.props.data)
    }
    removeOptionImage() {
        this.props.removeOptionImage(this.props.index)
    }
    answerOptionInputChange(event:any){
        this.props.answerOptionInputChange(event,this.props.data)
    }
    onOptionUploadImageClick(){
        const {data, refIndex} = this.props;
        this.props.onOptionUploadImageClick(data, refIndex)
    }
    onOptionRadioChange(){
        this.props.onOptionRadioChange(this.props.data)
    }
    onOptionMultiRadioChange(){
        this.props.onOptionMultiRadioChange(this.props.data)
    }
    // Customizable Area End

    // Customizable Area Start
    render() {
        const { data,refIndex } = this.props;
        const WhiteRadio = withStyles({
            root: {
              color: "#3f526d",
              padding: "0px",
              "&$checked": {
                color: "white",
                backgroundColor: "#3f526d",
                padding: "2px",
              },
            },
            checked: {},
          })((props: RadioProps) => <Radio color="default" {...props} />);
        return (
            <>
                <Grid item xs={7} sm={7} md={8}>
                    {data.isImageAvailble ? (
                        <Box className="theme_option_image_box">
                            <img
                                src={data.src}
                                className="theme_option_image"
                                onClick={this.onOptionImageClick}
                            />
                            <Typography
                                className={`theme_option_text ${this.props.classes.bodyText_font}`}
                            >
                                {data.name}
                            </Typography>
                            <ClearIcon
                                className={`match_img_clear ${this.props.classes.icon_button_color}`}
                                onClick={this.removeOptionImage}
                            />
                        </Box>
                    ) : (
                        <TextField
                            id="standard-basic"
                            placeholder="Add answer option"
                            className={`no-underline ${this.props.classes.input_theme_style} add_answer_field`}
                            autoComplete="off"
                            value={data.answer}
                            autoFocus={false}
                            inputProps={{
                                className: "add_answer_input"
                            }}
                            onChange={this.answerOptionInputChange}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    className="theme_option_img_upload_div"
                >
                    <img
                        src={optionImage}
                        className="theme_option_img_upload"
                        onClick={this.onOptionUploadImageClick}
                    />
                    <input
                        ref={this.props.multipleInputRef[refIndex]}
                        accept="image/png, image/jpg, image/jpeg,"
                        className="hidden_input"
                        type="file"
                        onChange={this.props.onOptionFileInputChange}
                    />
                    {!this.props.multipleResponse ? (
                        <RadioGroup
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                            onChange={this.onOptionRadioChange}
                        >
                            <WhiteRadio
                                value={data.charNumber}
                                checked={data.right_answer}
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "A" }}
                                checkedIcon={<CheckCircleOutlineIcon />}
                            />
                        </RadioGroup>
                    ) : (
                        <WhiteRadio
                            value={data.charNumber}
                            checked={data.right_answer}
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "A" }}
                            onClick={this.onOptionMultiRadioChange}
                            checkedIcon={<CheckCircleOutlineIcon />}
                        />
                    )}
                </Grid>
            </>
        )
    }
    // Customizable Area End
}

