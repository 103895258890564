// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
import _ from "lodash";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    assessmentResult: any;
    showLoader: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class StudentAssessmentResultController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetStudentAssessmentResult: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            assessmentResult: null,
            showLoader: false,
        }

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apiGetStudentAssessmentResult) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ assessmentResult: responseJson?.data, showLoader: false });
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showLoader: false })
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }
    
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    handleBack = () => {
        HISTORY.goBack();
    };

    getStudentAssessmentResult = () => {
        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token,
            school: school_Data?.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetStudentAssessmentResult = requestMessage.messageId;

        let apiEndPoint = configJSON.getStudentAssessmentResultEndPoint

        this.setState({ showLoader: true })

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.baseUrlAssessment
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // Customizable Area End
}
