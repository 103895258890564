// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import StudentDocument from "./StudentDocument.web";
import { HISTORY } from "../../../../components/src/comman";
import TeacherDocument from "./TeacherDocument.web";
// Customizable Area End


// Customizable Area Start
// Customizable Area End



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  data:any;
  studentSubjectList: any;
  goals: any;
  progress: any;
  upcomingClasses: any;
  selectedUpcomingSubject: string;
  notes: any;
  learningCurve: any;
  assessment: any;
  selectedTab:string;
  selectedIndex:number;
  listView:string;
  membersListView: string;
  dialogOpen:boolean;
  uploadStudent:boolean;
  dialogOpenTeacher:boolean;
  uploadTeacher:boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserDocumentController extends BlockComponent<Props, S, SS> {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleSelectTab=this.handleSelectTab.bind(this)
   
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      data:'',
      selectedTab:'teachers',
      selectedIndex:1,
      listView:'list',
      loading: false,
      studentSubjectList: {},
      goals: {},
      progress: {},
      upcomingClasses: [],
      selectedUpcomingSubject: '',
      notes: {},
      learningCurve: {},
      assessment: {},
      membersListView: 'list',
      dialogOpen:false,
      uploadStudent:false,
      dialogOpenTeacher:false,
  uploadTeacher:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const tabsList = [
  
      {
        key: 1,
        value: "teachers",
        label: "Teachers",
      },
      {
        key: 2,
        value: "students",
        label: "Students",
      },
      
      
    ];
    let from = localStorage.getItem("userdocument")
      
         if(from !==null){
          tabsList.map((item:any , index:any)=>{
            if(from ==item.value){
              
              this.setState({
               
                selectedTab:item.value,
                selectedIndex:item.key
              })
            }
          })
           
         localStorage.removeItem("userdocument")
         }
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
       
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSelectTab=(tab:string,key:number)=>{
    this.setState({
      selectedTab:tab,
      selectedIndex:key
    })
  }
 handleDialogClose=()=>{
   this.setState({dialogOpen:false})
 }

 onCloseModal = () => {
  this.setState({ uploadStudent: false })}

  handleTeacherDialogClose=()=>{
    this.setState({dialogOpenTeacher:false})
  }
 
  onTeacherCloseModal = () => {
   this.setState({ uploadTeacher: false })}
 

   screen = () => {
    switch(this.state.selectedTab) {
      case "teachers":   return <TeacherDocument />;
      
      case "students":   return <StudentDocument />;
    
      default:      return <h1>No project match</h1>
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
  // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));



    }
    // Customizable Area End
  }
 
  // }

  // Area End
  // Customizable Area End
}
