// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
import * as SparkMD5 from "spark-md5";
import { toast } from "react-toastify";
import axios from "axios";
import convert from "xml-js";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  primaryColor: any;
  buttonColor: any;
  iconsColor: any;
  titleFontColor: any;
  subTitleFontColor: any;
  bodyHeadingColor: any;
  bodySubheadingColor: any;
  bodyTextColor: any;
  backgroundColor: any;
  titleFont: any;
  subtitleFont: any;
  bodySubheadingFont: any;
  bodyTextFont: any;
  BodyHeadingFont: any;
  uploadFile: any;
  alertMessage: any;
  alertModal: boolean;
  fontFamilyList: any;
  customId: any;
  loadingSpinner: boolean;
  backgroundImage: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CustomThemesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPreSignedID: any = [];
  apiThemeUploadID: string = '';
  applyCustomThemeApiId: any;
  getCustomThemeApiID: any;
  getCustomThemeFontApiID: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      primaryColor: "#5e72e4",
      buttonColor: "#acb0f1",
      iconsColor: "#acb0f1",
      titleFontColor: "#141caa",
      subTitleFontColor: "#7279eb",
      bodyHeadingColor: "#232875",
      bodySubheadingColor: "#141caa",
      bodyTextColor: "#565656",
      backgroundColor: '',
      titleFont: "Open sans",
      subtitleFont: "Open sans",
      bodySubheadingFont: "Open sans",
      bodyTextFont: "Open sans",
      BodyHeadingFont: "Open sans",
      uploadFile: [],
      alertMessage: "",
      alertModal: false,
      customId: null,
      backgroundImage: '',
      fontFamilyList: [], loadingSpinner: false
    };
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  async componentDidMount() {
    // Customizable Area Start
    const details: any = localStorage.getItem("customThemeID")
    const data = JSON.parse(details || "{}");
    this.getCustomeTheme()
    this.getCustomThemeFont()
    // Customizable Area End
  }

  // Customizable Area Start
  handleUpload = async (event: any) => {
    let allowUpload: boolean = true;
    const validFileType = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
    ];

    let file: any = event.target.files[0];
    if (!validFileType.includes(file?.type)) {
      allowUpload = false;
      this.setState({
        alertMessage:
          "Please upload valid file format: .png .jpg .jpeg .gif",
        alertModal: true,
      });

    }

    if (allowUpload) {
      const arr: any = []
      let fileUpload: any = {}
      fileUpload = {
        fileName: file.name,
        name: file.name,
        isExistingFile: false,
        fileSize: file.size,
        percentage: 0,
        file: file,
        content_type: file.type,
        localUrl: URL.createObjectURL(file)
      };
      arr.push(fileUpload)
      this.setState({ uploadFile: arr }, () => { this.getPreSignedId(fileUpload) })
    }


  }
  computeChecksumMd5 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152;
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0;

      fileReader.onerror = function (): void {
        reject("MD5 computation failed - error reading the file");
      };

      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result);
        cursor += chunkSize;
        if (cursor < file.size) {
          processChunk(cursor);
        } else {
          resolve(btoa(spark.end(true)));
        }
      };

      processChunk(0);
    });
  };
  getPreSignedId = async (fileUploadDetail: any) => {
    this.setState({ loadingSpinner: true })
    const { uploadFile } = this.state;
    const header = {
      token: localStorage.getItem("token"),
    };

    let formData = new FormData();
    formData.append('content_type', fileUploadDetail.content_type)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.SignIDUrl
    );

    this.apiPreSignedID[this.apiPreSignedID.length] = requestMessage.messageId;
    uploadFile.map((item: any) => {
      if (item.checksum === fileUploadDetail.checksum) {
        item.requestID = requestMessage.messageId;
      }
    });

    this.setState({ uploadFile: uploadFile }, () => {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.fileUploadS3
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    });


  };

  saveImageS3 = async (preSignedResponse: any) => {

    const { uploadFile } = this.state;

    let ind: number = 0;
    const uploadURL = preSignedResponse.url.replace(/\/+$/, "");
    const urlFields = preSignedResponse.url_fields;
    if (uploadURL != null && uploadURL !== undefined) {
      const fileKey = urlFields.key.replace("${filename}", uploadFile[0].fileName);
      const formData = new FormData();
      formData.append("key", fileKey);
      formData.append(
        "success_action_status",
        urlFields.success_action_status
      );
      formData.append("Content-Type", urlFields["Content-Type"]);
      formData.append(
        "Content-Disposition",
        urlFields["Content-Disposition"]
      );
      formData.append("policy", urlFields.policy);
      formData.append("x-amz-credential", urlFields["x-amz-credential"]);
      formData.append("x-amz-algorithm", urlFields["x-amz-algorithm"]);
      formData.append("x-amz-date", urlFields["x-amz-date"]);
      formData.append("x-amz-signature", urlFields["x-amz-signature"]);
      formData.append("file", uploadFile[ind].file);
      const s3Response = await axios({
        method: "post",
        url: uploadURL,
        data: formData,
      });
      if (s3Response.status === 201) {
        const keyValue = JSON.parse(
          convert.xml2json(s3Response.data, {
            spaces: 1,
            compact: true,
            ignoreComment: true,
            alwaysChildren: true,
            ignoreDeclaration: true,
          })
        );
        let uploadDoc = this.state.uploadFile
        uploadDoc[0].signedInId = keyValue.PostResponse.Key._text
        return { data: keyValue.PostResponse.Key._text };
      }
    }
  }


  getCustomeTheme = () => {
    this.setState({ loadingSpinner: true })
    const id = localStorage.getItem("customThemeID")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomThemeApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getThemeEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getCustomThemeFont = () => {
    this.setState({ loadingSpinner: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomThemeFontApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customeThemeFontTypeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  onUpdateClick = () => {
    const customId: any = localStorage.getItem("customThemeID")
    const schoolId: any = localStorage.getItem("schoolId")
    const data = {
      data: {
        "attributes": {
          "primary_color": this.state.primaryColor,
          "button_color": this.state.buttonColor,
          "icons_color": this.state.iconsColor,
          "title_font_color": this.state.titleFontColor,
          "subtitle_font_color": this.state.subTitleFontColor,
          "body_heading_color": this.state.bodyHeadingColor,
          "body_subheading_color": this.state.bodySubheadingColor,
          "body_text_color": this.state.bodyTextColor,
          "title_font": this.state.titleFont,
          "subtitle_font": this.state.subtitleFont,
          "body_heading": this.state.BodyHeadingFont,
          "body_subheading": this.state.bodySubheadingFont,
          "body_text": this.state.bodyTextFont,
          "background_color": this.state.backgroundColor,
          "background_image": this.state.uploadFile.length > 0 ? [this.state.uploadFile[0].signedInId] : [this.state.backgroundImage]
        },
      },
      "school_id": parseInt(schoolId),
    }

    this.setState({ loadingSpinner: true })
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.applyCustomThemeApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatedCustomThemeEndPoint + `${customId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeEditDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.apiPreSignedID.indexOf(apiRequestCallId) === -1
          ? ""
          : apiRequestCallId:
          {

            if (responseJson != null) {
              if (!responseJson.errors) {
                const imageData = this.state.uploadFile[0].file
                const msg: Message = new Message(
                  getName(MessageEnum.UploadMediaMessage)
                );
                const uploadFileData: any = {
                  responseJson: responseJson,
                  imageData,
                  messageId: msg.messageId
                };
                msg.addData(
                  getName(MessageEnum.UploadMediaDataMessage),
                  uploadFileData
                );
                this.apiThemeUploadID = msg.messageId;
                runEngine.sendMessage(msg.id, msg)
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.getCustomThemeFontApiID:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({ fontFamilyList: responseJson.data })
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.setState({ loadingSpinner: false })

          }
          break;

        case this.apiThemeUploadID:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const keyValue = JSON.parse(
                  convert.xml2json(responseJson, {
                    spaces: 1,
                    compact: true,
                    ignoreComment: true,
                    alwaysChildren: true,
                    ignoreDeclaration: true,
                  })
                );
                let uploadDoc = this.state.uploadFile
                uploadDoc[0].signedInId = keyValue.PostResponse.Key._text
                this.setState({ uploadFile: uploadDoc })
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.setState({ loadingSpinner: false })

          }
          break;
        case this.getCustomThemeApiID: {
          this.setState({ loadingSpinner: false })
          if (responseJson != null) {
            if (!responseJson.errors) {
              const data = responseJson.data.attributes
              this.setState({
                primaryColor: data.primary_color,
                buttonColor: data.button_color,
                iconsColor: data.icons_color,
                titleFontColor: data.title_font_color,
                subTitleFontColor: data.subtitle_font_color,
                bodyHeadingColor: data.body_heading_color,
                bodySubheadingColor: data.body_subheading_color,
                bodyTextColor: data.body_text_color,
                backgroundColor: data.background_color,
                titleFont: data.title_font,
                subtitleFont: data.subtitle_font,
                bodySubheadingFont: data.body_subheading,
                bodyTextFont: data.body_text,
                BodyHeadingFont: data.body_heading,
                customId: responseJson.data.id,
                loadingSpinner: false,
                backgroundImage: data.background_image.data[0]?.attributes?.url
              })
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
          break;
        case this.applyCustomThemeApiId: {
          if (responseJson != null) {
            if (!responseJson.errors) {
              toast.success("Saved successfully")
              this.props.navigation.navigate("ThemesAndFonts")
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
          break;
      }
    }
    // Customizable Area End
  }

}
