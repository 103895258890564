// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const FeedbackIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g id="Teacher-Assesment-" fill={`${props.fillColor}`} fill-rule="nonzero" {...props}>
                <g id="icons" transform="translate(-455.000000, -258.000000)" fill={`${props.fillColor}`} fill-rule="nonzero" {...props}>
                    <g id="feedback" transform="translate(455.000000, 258.000000)" {...props}>
                        <path d="M31.7656062,3.43430083 L28.5654056,0.234295952 C28.2534678,-0.0778376095 27.7472048,-0.0782289745 27.4348755,0.233904537 C27.4348755,0.234100219 27.4346798,0.234295952 27.4342884,0.234295952 L17.8344695,9.83450628 C17.6843714,9.98440864 17.6002225,10.1877358 17.6002225,10.3998692 L17.6002225,13.6000698 C17.6002225,14.0417534 17.9583444,14.3998753 18.4000281,14.3998753 L21.6002225,14.3998753 C21.812362,14.3998753 22.0156892,14.3157264 22.1655915,14.1656283 L31.7656062,4.5656137 C32.0779355,4.25348014 32.0781311,3.74721712 31.7661933,3.43488793 C31.7659976,3.43488793 31.7659976,3.43449652 31.7656062,3.43430083 Z M21.268917,12.8000686 L19.2000293,12.8000686 L19.2000293,10.7313765 L28.0000427,1.93136312 L30.0687347,4.00005513 L21.268917,12.8000686 Z" id="Shape"></path>
                        <path d="M29.5998495,9.60006368 C29.1581658,9.60006368 28.8000439,9.95818553 28.8000439,10.3998692 L28.8000439,21.5998863 C28.7986741,22.9247415 27.7248955,23.9985201 26.4000403,24.0000856 L18.4000281,24.0000856 C18.226838,23.9996942 18.0583446,24.0558587 17.9199882,24.1599685 L12.8000195,28.0000918 L12.8000195,24.8000869 C12.8000195,24.3582075 12.4418976,24.0000856 12.0000183,24.0000856 L4,24.0000856 C2.67515088,23.9985201 1.60137232,22.9247415 1.60019812,21.600082 L1.60019812,7.19986428 C1.601568,5.87520474 2.67515088,4.80142618 4,4.80005635 L18.4000281,4.80005635 C18.8419074,4.80005635 19.2000293,4.44193445 19.2000293,4.00005513 C19.2000293,3.55837145 18.8419074,3.2002496 18.4000281,3.2002496 L4,3.2002496 C1.79178356,3.2024022 0.00215264767,4.99203315 0,7.19986428 L0,21.600082 C0.00215264767,23.8081088 1.79178356,25.5977397 4,25.6000881 L11.2000171,25.6000881 L11.2000171,29.6000942 C11.2000171,30.0417778 11.558139,30.3998997 12.0000183,30.3998997 C12.1732084,30.3998997 12.3415061,30.3437352 12.4800582,30.2400169 L18.6675432,25.5998924 L26.4000403,25.5998924 C28.6082628,25.5977397 30.397698,23.8081088 30.4000464,21.5998863 L30.4000464,10.4000649 C30.4000464,9.95818553 30.0417288,9.60006368 29.5998495,9.60006368 Z" id="Path"></path>
                        <path d="M13.5998251,8.00006124 L5.60020423,8.00006124 C5.15812923,8.00006124 4.80000732,8.35818309 4.80000732,8.80006246 C4.80000732,9.24174609 5.15812923,9.59986795 5.60020423,9.59986795 L13.5998251,9.59986795 C14.0417044,9.59986795 14.400022,9.24174609 14.400022,8.80006246 C14.400022,8.35818309 14.0417044,8.00006124 13.5998251,8.00006124 L13.5998251,8.00006124 Z" id="Path"></path>
                        <path d="M5.60020423,14.400071 L13.5998251,14.400071 C14.0417044,14.400071 14.400022,14.0417534 14.400022,13.6000698 C14.400022,13.1581904 14.0417044,12.8000686 13.5998251,12.8000686 L5.60020423,12.8000686 C5.15812923,12.8000686 4.80000732,13.1581904 4.80000732,13.6000698 C4.80000732,14.0417534 5.15812923,14.400071 5.60020423,14.400071 Z" id="Path"></path>
                        <path d="M5.60020423,19.1998826 L24.8000378,19.1998826 C25.2419172,19.1998826 25.5998434,18.8417607 25.5998434,18.3998814 C25.5998434,17.9581977 25.2417215,17.6000759 24.8000378,17.6000759 L5.60020423,17.6000759 C5.15812923,17.6000759 4.80000732,17.9581977 4.80000732,18.3998814 C4.80000732,18.8417607 5.15812923,19.1998826 5.60020423,19.1998826 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default FeedbackIcon;
// Customizable Area End