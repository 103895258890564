// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
// import DateFnsUtils from '@date-io/date-fns';
// import { KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
}),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    }
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

function createData(title: string, term: number, term2: number, term3: number) {
  return { title, term, term2, term3 };
}

const rows = [
  createData('Total Attendence of the learner', 20, 10, 5),
  createData('Total Number of working days', 20, 10, 4.0)
];

// Customizable Area End

// Customizable Area Start
export default function ReportCardPartOneFourFiveEdit() {
  const classes = useStyles();
  const [selectclass, setSelectclass] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date('2014-08-18T21:11:54'),
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectclass(event.target.value as string);
  };
  
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  return (
    <div>
      <div className='profileTitle'>
      <Button className="backBtn"><KeyboardBackspaceOutlinedIcon /> Back</Button>
      <h2>Part 1 - Assessment of Learning </h2>
      <Button className="editBtn commonBtnSecondary">Enable Edit <EditOutlinedIcon /> </Button>
      </div>
      <div className="tableContent">
         {/*  table 1 start */}
         <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={9} align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff", borderTopLeftRadius: "5px",borderTopRightRadius:"5px" }}>
                                Term 1 (100 Marks) <EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell colSpan={2} style={{ border: "1px solid #D9D9D9", display: "flex", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                Weekly Test

                                <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                    WA (20)
                                </TableCell>

                                <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                    WA (20)
                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                    WA (20)
                                </TableCell>

                            </TableCell>

                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2"}}>
                                PA 1 Best of 3

                                (20)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                Notebook Maintenance

                                (10)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                Subject Enrichment

                                (20)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                CCSA 1

                                (50)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                Marks Obtained
                                <br></br>
                                (100)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                Grade
                            </TableCell>
                          <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                Language 1: English
                            </TableCell>

                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                               
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                Language 2
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>

                            </TableCell>

                          <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                Language 3
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>

                            </TableCell>

                            <TableCell style={{ border: "1px solid #D9D9D9",textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                Mathematics
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>

                            </TableCell>
                          <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                EVS / Science
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>

                            </TableCell>
                          <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                Social Science
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>

                            </TableCell>
                          <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                Computer
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>

                            </TableCell>
                         <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px",textAlign:"center" }}>
                            <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer >

            {/* table 1 end */}

            {/* table 2 start */}
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={9} align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff", borderTopLeftRadius: "5px" }}>
                                Term 2 (100 Marks) <EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell colSpan={2} style={{ border: "1px solid #D9D9D9", display: "flex", backgroundColor: "#F2F2F2" }}>
                                Weekly Test

                                <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                    WA (20)
                                </TableCell>

                                <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                    WA (20)
                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                    WA (20)
                                </TableCell>

                            </TableCell>

                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                PA 2 Best of 3

                                (20)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                Notebook Maintenance

                                (10)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                Subject Enrichment

                                (20)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                CCSA 2

                                (50)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                Marks Obtained
                                <br></br>
                                (100)
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                Grade
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px", textAlign: "center"}}>
                            <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                Language 1: English
                            </TableCell>

                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>

                            </TableCell>
                         <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                Language 2
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>

                            </TableCell>
                         <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                Language 3
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>

                            </TableCell>
                         <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                Mathematics
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>

                            </TableCell>
                         <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                EVS / Science
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>

                            </TableCell>
                         <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                Social Science
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>

                            </TableCell>
                         <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                Computer
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>

                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>

                            </TableCell>
                         <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px",textAlign:"center" }}>
                            <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",textAlign:"center" }}>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                                </TableCell>
                                <TableCell style={{ border: "1px solid #D9D9D9" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                                </TableCell>

                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",textAlign:"center" }}>
                            <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",textAlign:"center" }}>
                            <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",textAlign:"center" }}>
                            <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",textAlign:"center" }}>
                            <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",textAlign:"center" }}>
                            <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" ,textAlign:"center"}}>
                            <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                         <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer >

            {/* table 2 end */}
      </div>
    </div>
  )
}
// Customizable Area End
