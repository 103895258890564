// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const AssessmentIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g fill={`${props.fillColor}`} fill-rule="nonzero">
                <path d="M19.938 21.885h-5.5a.938.938 0 0 0 0 1.875h5.5a.938.938 0 0 0 0-1.875zM19.938 14.56h-5.5a.938.938 0 0 0 0 1.874h5.5a.938.938 0 0 0 0-1.875zM9.038 13.28a.938.938 0 0 0-1.32.12l-1.583 1.895-.36-.717a.937.937 0 1 0-1.676.84l1 1.996a.938.938 0 0 0 1.558.18l2.5-2.993a.938.938 0 0 0-.119-1.32zM7.982 20.23a.938.938 0 0 0-1.307.219l-.487.682-.488-.682a.938.938 0 0 0-1.525 1.09l.86 1.205-.86 1.205a.938.938 0 0 0 1.525 1.09l.487-.682.488.682a.936.936 0 1 0 1.525-1.09l-.86-1.205.86-1.205a.938.938 0 0 0-.218-1.308z"/>
                <path d="M21.375 3.216v-.404A2.816 2.816 0 0 0 18.562 0H6.313A2.816 2.816 0 0 0 3.5 2.813v.402A4.7 4.7 0 0 0 0 7.75v19.563A4.693 4.693 0 0 0 4.688 32h15.5a4.693 4.693 0 0 0 4.687-4.688V7.75a4.7 4.7 0 0 0-3.5-4.534zm-16-.404c0-.516.42-.937.938-.937h12.25c.516 0 .937.42.937.938v2.312c0 .517-.42.938-.938.938H6.313a.939.939 0 0 1-.937-.938V2.812zM23 27.313a2.816 2.816 0 0 1-2.813 2.813h-15.5a2.816 2.816 0 0 1-2.812-2.813V7.75c0-1.12.664-2.1 1.627-2.55a2.815 2.815 0 0 0 2.81 2.737h12.25A2.815 2.815 0 0 0 21.373 5.2 2.82 2.82 0 0 1 23 7.75v19.563z"/>
            </g>
        </SvgIcon>
    );
}

export default AssessmentIcon;
// Customizable Area End