// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
// Customizable Area End


// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  spinner: boolean;
  csvFile: string
  // Customizable Area End
}
export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  openModal: boolean;
  uploadedQuestions: number;
  totalQuestions: number;
  ReUpload: any;
  errorCSVDownloadLink: string;
  handleOnClose: React.Dispatch<React.SetStateAction<boolean>>;
  // Customizable Area End
}
export default class ErrorUploadAssesmentModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiDeleteCSVFile: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area Start
    this.state = {
      csvFile: '',
      spinner: false,

    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.csvFileDownloadURLhandler(this.props);
  }
  csvFileDownloadURLhandler = (props: any): any => {
    const csvFileUrl = props.errorCSVDownloadLink ? props.errorCSVDownloadLink.split("/") : []
    if (csvFileUrl && csvFileUrl.length > 0) {
      const csvFileWithParam = csvFileUrl[csvFileUrl.length - 1].split("?");
      this.setState({
        csvFile: csvFileWithParam[0]
      })
    }
  }
  handleClose = () => {
    this.props.handleOnClose(false)
  }
  closeIconClickHandler = () => {
    const csvFileUrl = this.props.errorCSVDownloadLink && this.props.errorCSVDownloadLink.split("/")
    let csvFile1 = ''
    if (csvFileUrl && csvFileUrl.length > 0) {
      csvFile1 = csvFileUrl[csvFileUrl.length - 1]
      this.DeleteCsvFile(csvFile1)
    } else {
      this.props.handleOnClose(false)
    }
  }
  reUploadClickHandler = () => {
    this.props.handleOnClose(false)
    this.props.ReUpload()
  }
  DeleteCsvFile = (fileName?: any) => {
    this.setState({
      spinner: true
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteCSVFile = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteAssessmentCSV}?file_name=${fileName}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethodType
    );
    console.log("REQUEST: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeleteCsv = (props: any, response?: any): any => {
    if (response) {
      this.setState({
        spinner: false
      })
      props.handleOnClose(false)
    } else {
      toast.error("something went wrong")
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.apiDeleteCSVFile) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.handleDeleteCsv(responseJson, this.props)
          } else {
            this.setState({
              spinner: false
            })
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }

    }
    // Customizable Area End
  }

}