// Customizable Area Start
import React from "react"
import { withStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";
import '../Sidebar.css'
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const AdminClassIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
                <path d="M30.933 0a1.067 1.067 0 0 1 0 2.133V25.6a1.067 1.067 0 0 1 0 2.133H20.926l1.362 2.723c.105.211.137.45.093.679l-.036.136a1.066 1.066 0 0 1-1.965.14l-1.839-3.678h-5.082l-1.838 3.677c-.108.217-.287.39-.505.49l-.112.045a1.066 1.066 0 0 1-1.291-1.489l1.361-2.723H1.067c-.243 0-.477-.082-.665-.232l-.09-.08a1.067 1.067 0 0 1 .755-1.821V2.133a1.067 1.067 0 0 1 0-2.133h29.866zM28 2.133h-24L4 25h24l-.001-22.867z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default withStyles(themeCreateStyle)(AdminClassIcon);
// Customizable Area End