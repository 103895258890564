// Customizable Area Start
import React from "react";
import {
    Grid,
    Modal,
    Button,
} from "@material-ui/core";
// import './BatchAndPeriod.web.css';
// Customizable Area End
// Customizable Area Start
const deleteDiv = {
    fontFamily: "Open sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: 'center',
    color: "#3f526d",
    marginBottom: '14px',
} as const;
const deleteMes1 = {
    fontFamily: "Open sans",
    fontSize: "24px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: 'center',
    color: "#3f526d",
    margin: '31px 2px 6px 3px',
} as const;
const deleteMes2 = {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: 'center',
    color: "#959393",
    margin: '6px 53px 40px 54px',
} as const;
const cancelText = {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: 'center',
    color: "#fff",
} as const;
const deleteText = {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: 'center'
} as const;
const cancelButton = {
    margin: '0 20px 0 0',
    padding: '9px 20px 12px 22px',
    borderRadius: '3px',
    background: '#7f6cfc',
    color: 'white',
    textTransform: 'initial',
} as const;
const deleteButton = {
    margin: '0 0px 0 20px',
    padding: '9px 20px 12px 22px',
    borderRadius: '3px',
    backgroundColor: '#f6f6f6',
    textTransform: 'initial',
} as const;
// Customizable Area End
interface Props {
    // Customizable Area Start
    title: string;
    deleteModal: boolean;
    onCloseModal: any;
    deleteClick: any;
    confirmMes:any;
    description:any;
    cancelButtonText:any;
    okButtonText:any
    // Customizable Area End
}

export const MesDialog = ({
    // Customizable Area Start
    title,
    deleteModal,
    onCloseModal,
    deleteClick,
    confirmMes,
    description,
    cancelButtonText,
    okButtonText
    // Customizable Area End
}: Props) => {
    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    return (
        <>
            <Modal
                open={deleteModal}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid 
                    className="modal-width-90 UserDocMesDialog"
                >
                    <div style={deleteDiv}>{title}</div>
                    <div className="UserDocMesDivider"></div>
                    <div style={deleteMes1}>{confirmMes}</div>
                    <div style={deleteMes2}>{description}</div>
                    <Grid className="UserDocMesActions">
                        <Button variant="contained" style={cancelButton} onClick={onCloseModal}><span style={cancelText}>{cancelButtonText}</span></Button>
                        <Button variant="contained" style={deleteButton} onClick={deleteClick}><span style={deleteText}>{okButtonText}</span></Button>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
    // Customizable Area End
};
  // Customizable Area Start
export default MesDialog;
  // Customizable Area End