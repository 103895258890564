// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: any;
  ref: any
  classes: any;
  period_id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  resourceData: any;
  data: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  studentResourcetList: any;
  teacherAttendanceList: any;
  studentResourceFolders: any;
  classesList: any;
  subjectList: any;
  categoryList: any;
  teachersList: any;
  studentList: any;
  anchorEl: any;
  openModal: boolean;
  selectedItem: string;
  selectedId: string;
  selectedIds: any;
  selectAll: boolean;
  selectedClass: any;
  selectedSubject: any;
  selectedStudens: any;
  selectedTeacher: any;
  selectedListType: string;
  modalType: string;
  selectedFiles: any,
  currentFile: any,
  progress: number,
  message: string,
  isError: boolean,
  fileInfos: any,
  categoriesName: string,
  createCategory: boolean,
  uploadResource: any,
  displayList: string,
  uploadPercentage: number,
  studentChecked: boolean,
  teacherChecked: boolean,
  parentChecked: boolean,
  shared_with: any,
  folderName: string,
  createSelectedAll: boolean,
  createSelected: any,
  fileSelectedAll: boolean,
  fileSelected: any,
  queryTerm: string,
  viewFolders: any,
  viewResource: any,
  movetoFolderId: any,
  openDialog: boolean,
  fileToBeAdded: any,
  fromDate: any,
  toDate: any,
  date: any,
  selectedStudentAttendence: any,
  attendenceChartData: any;
  atttendancetypeselected: any;
  totalPage: any;
  currentPage: any;
  perPage: any;
  searchError: boolean;
  searchErrorText: any;
  totalCount: any;
  showTooltip: boolean;
  loadingReport: boolean;
  loadingSpinner: boolean;
  selectedStudentId: any;
  selected_period: any;
  prevComment: any;
  displayClassName: string;
  periodList: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherAttendanceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiupdateTeacherAttendanceId: any;
  apigetTeacherAttendanceDate: any;
  apigetTeacherAttendanceStatus: any;
  apigetTeacherAttendanceSearch: any;
  apigetTeacherAttendanceChart: any;
  getTeacherPeriodId: string = '';
  // Customizable Area End


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      attendenceChartData: [],
      resourceData: [],
      data: '',
      errorMsg: "",
      anchorEl: null,
      token: "",
      loading: false,
      studentResourcetList: {},
      teacherAttendanceList: [],
      studentResourceFolders: {},
      classesList: {},
      subjectList: [],
      teachersList: {},
      studentList: {},
      openModal: false,
      selectedItem: "",
      selectedId: "",
      selectedIds: [],
      categoryList: [],
      selectAll: false,
      selectedClass: null,
      selectedSubject: null,
      selectedStudens: null,
      selectedTeacher: null,
      selectedListType: "",
      modalType: "",
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 10,
      message: "",
      isError: false,
      fileInfos: [],
      categoriesName: '',
      createCategory: false,
      displayList: 'student',
      selected_period: '',
      displayClassName: 'Class Name',
      uploadResource: [
        {
          id: 1
        }
      ],
      uploadPercentage: 0,
      studentChecked: false,
      teacherChecked: false,
      parentChecked: false,
      shared_with: [],
      folderName: "",
      createSelectedAll: false,
      createSelected: [],
      fileSelectedAll: false,
      fileSelected: [],
      queryTerm: "",
      viewFolders: [],
      viewResource: [],
      movetoFolderId: undefined,
      openDialog: false,
      fileToBeAdded: [],
      fromDate: moment().format("DD/MM/YYYY"),
      toDate: moment().format("DD/MM/YYYY"),
      date: moment().format("DD/MM/YYYY"),
      selectedStudentAttendence: [],
      atttendancetypeselected: "",
      totalPage: 1,
      currentPage: 0,
      perPage: 10,
      searchError: false,
      searchErrorText: '',
      totalCount: 1,
      showTooltip: false,
      loadingReport: false,
      loadingSpinner: false,
      selectedStudentId: '',
      prevComment: '',
      periodList: [],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.userdata = this.userdata.bind(this);
    this.get_Teacher_Period = this.get_Teacher_Period.bind(this)
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.userdata();
    this.getTeacherAttendanceByDate();
    this.get_Teacher_Period()
  }

  userdata = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}')
    this.setState({ data: data });
  }

  handleCloseResourceModal = () => {
    this.setState({
      openModal: false,
      categoriesName: '',
      selectedSubject: null,
      selectedClass: null,
      shared_with: [],
      selectedFiles: undefined,
      uploadPercentage: 0,
      studentChecked: false,
      teacherChecked: false,
      parentChecked: false,
      uploadResource: [{}],
      attendenceChartData: [],
      selectedStudentId: ''

    })
  }
  isSelected = (id: any) => this.state.createSelected.indexOf(id) !== -1;
  handleModalClick = (type: any) => {
    this.setState({
      openModal: !this.state.openModal,
      selectedStudentAttendence: []
    })
  }
  disableFutureDt = (current: any) => {
    const today = moment();
    return current.isBefore(today);
  };
  disableFutureFrom = (current: any) => {
    return current.isBefore(
      moment(this.state.toDate, "DD/MM/YYYY").add("1", "days")
    );
  };
  periodChangeHandler = (event: any) => {
    this.setState({ selected_period: event.target.value }, () => {
      const displayIndex = this.state.periodList.find(
        (x: any) => x.period_id == this.state.selected_period
      );
      if (displayIndex) {
        this.setState({
          displayClassName: displayIndex.display_name,
        });
      }
      else {
        this.setState({
          displayClassName: 'Class Name',
        });
      }
      this.getTeacherAttendanceByDate();
    });
  }
  searchChangeHandler = (event: any) => {
    this.setState(
      {
        queryTerm: event.target.value,
        createSelected: "",
        atttendancetypeselected: "",
        perPage: "10",
        currentPage: "0",
      },
      () => {
        this.getTeacherAttendanceBySearch();
      }
    );
  }
  searchKeyPressHandler = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (this.state.queryTerm.trim() != '') {
        this.getTeacherAttendanceBySearch();
      } else {
        this.getTeacherAttendanceByDate();
      }
    }
  }
  calendarChangeHandler = (date: any) => {
    this.setState(
      {
        date: moment(date).format("DD/MM/YYYY"),
        queryTerm: "",
        createSelected: "",
        atttendancetypeselected: "",
        perPage: "10",
        currentPage: "0",
      },
      () => this.getTeacherAttendanceByDate()
    );
  }
  handleSelectStudentClick = (id?: any, index?: number): any => {
    this.setState(
      {
        selectedStudentId: id,
      },
      () => {
        this.handleSelectedStudentClick(index);
      }
    )
  }
  studentAttandanceHandler = (row: any, index: number, attandanceType: string): any => {
    const arr = this.state.teacherAttendanceList;
    if (row.attributes.status !== attandanceType) {
      arr.data[index].attributes.status =
        attandanceType;
      this.setState({
        teacherAttendanceList: arr,
      });
      this.updateTeacherAttendanceStatus(
        row.id,
        attandanceType
      );
    } else {
      arr.data[index].attributes.status =
        "Unassigned";
      this.setState({
        teacherAttendanceList: arr,
      });
      this.updateTeacherAttendanceStatus(
        row.id,
        "Unassigned"
      );
    }
  }
  commentHandler = (comment: any): any => {
    this.setState({ prevComment: comment })
  }
  commentKeyPressHandler = (event: any) => {
    if (event.target.value.length >= 100) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
  commentOnChangeHandler = (event: any, index: number): any => {
    const arr = this.state.teacherAttendanceList;
    arr.data[index].attributes.comment =
      event.target.value.charAt(0).toUpperCase() +
      event.target.value.slice(1);
    this.setState({ teacherAttendanceList: arr });
  }
  pageChangeHandler = (e: any) => {
    this.setState({ currentPage: e.selected }, () => {
      if (this.state.queryTerm.length !== 0) {
        this.getTeacherAttendanceBySearch();
      } else if (this.state.atttendancetypeselected !== "") {
        this.getTeacherAttendanceByStatus();
      } else {
        this.getTeacherAttendanceByDate();
      }
    })
  }
  fromDateChangeHandler = (date: any) => {
    this.setState(
      {
        fromDate: moment(date).format("DD/MM/YYYY"),
      },
      () => {
        this.getTeacherAttendanceChart();
      }
    );
  }
  toDateChangeHandler = (date: any) => {
    if (
      date.isBefore(
        moment(this.state.fromDate, "DD/MM/YYYY").add(
          "1",
          "days"
        )
      )
    ) {
      this.setState({
        fromDate: moment(date).format("DD/MM/YYYY"),
      });
    }
    this.setState(
      {
        toDate: moment(date).format("DD/MM/YYYY"),
      },
      () => this.getTeacherAttendanceChart()
    );
  }
  handleSelectedStudentClick = (index: any) => {
    this.getTeacherAttendanceChart()
    this.setState({
      loadingReport: true,
      selectedStudentAttendence: this.state.teacherAttendanceList.data[index]
    }, () => this.setState({ openModal: true }))
  }
  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  updateTeacherAttendanceStatus = (id: any, status: any) => {
    const todate = this.state.date === "" ? moment().format('DD/MM/YYYY') : this.state.date
    const teacherId = localStorage.getItem("id")
    const header = {};
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiupdateTeacherAttendanceId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTeacherAttendanceApi + `/${id}?status=${status}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiPutMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ loadingSpinner: true })
  }
  updateTeacherAttendanceComment = (index: any, id: any): any => {
    const comment = this.state.teacherAttendanceList.data[index].attributes.comment != undefined ? this.state.teacherAttendanceList.data[index].attributes.comment.trim() : ''
    const header = {};
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiupdateTeacherAttendanceId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTeacherAttendanceApi + `/${id}?comment=${comment}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiPutMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ loadingSpinner: true })
  }
  get_Teacher_Period = () => {
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem("token"),
      "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTeacherPeriodId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeachePeriodEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getTeacherAttendanceByDate = () => {
    const date = this.state.date === "" ? moment().format('DD/MM/YYYY') : this.state.date
    const teacherId = localStorage.getItem("id")
    const header = {
      "Content-Type": configJSON.resourceContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherAttendanceDate = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherAttendanceByFilter + `?period_id=${this.state.selected_period}&teacher_id=${teacherId}&date=${date}&page=${parseInt(this.state.currentPage) + 1}&per_page=${this.state.perPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ loadingSpinner: true })
  }
  getTeacherAttendanceBySearch = () => {
    const todate = this.state.date === "" ? moment().format('DD/MM/YYYY') : this.state.date
    const teacherId = localStorage.getItem("id")
    let query = `?period_id=${this.state.selected_period}&teacher_id=${teacherId}&date=${todate}&search=${this.state.queryTerm.trim()}&page=${parseInt(this.state.currentPage) + 1}&per_page=${this.state.perPage}`
    const header = {
      "Content-Type": configJSON.resourceContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherAttendanceSearch = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTeacherAttendanceByFilter}${query}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getTeacherAttendanceByStatus = () => {
    const todate = this.state.date === "" ? moment().format('DD/MM/YYYY') : this.state.date
    const teacherId = localStorage.getItem("id")
    const header = {
      "Content-Type": configJSON.resourceContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherAttendanceStatus = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherAttendanceByFilter + `?period_id=${this.state.selected_period}&teacher_id=${teacherId}&date=${todate}&status=${this.state.atttendancetypeselected}&page=${parseInt(this.state.currentPage) + 1}&per_page=${this.state.perPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ loadingSpinner: true })
  }
  getTeacherAttendanceChart = () => {
    const teacherId = localStorage.getItem("id")
    const todate = this.state.toDate === "" ? moment().format('DD/MM/YYYY') : this.state.toDate
    const fromdate = this.state.fromDate === "" ? moment().format('DD/MM/YYYY') : this.state.fromDate
    const header = {
      "Content-Type": configJSON.resourceContentType,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherAttendanceChart = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getChartTeacher + `?period_id=${this.state.selected_period}&student_id=${this.state.selectedStudentId}&from=${fromdate}&to=${todate}&teacher_id=${teacherId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ loadingSpinner: true })
  }
  // Customizable Area End


  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.apiupdateTeacherAttendanceId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            toast.success("Attendance Updated Successfully")
            this.getTeacherAttendanceByDate()
          } else {
            toast.error("Something wents wrong")
            this.getTeacherAttendanceByDate()
            this.parseApiErrorResponse(responseJson)
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
      else if (apiRequestCallId === this.apigetTeacherAttendanceDate) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const totalPage = Math.ceil(responseJson.total_count / this.state.perPage);
            this.setState({ teacherAttendanceList: responseJson, totalPage, totalCount: responseJson.total_count })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
      else if (apiRequestCallId === this.apigetTeacherAttendanceSearch) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const totalPage = Math.ceil(responseJson.total_count / this.state.perPage);
            this.setState({ teacherAttendanceList: responseJson, totalPage, totalCount: responseJson.total_count })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
      else if (apiRequestCallId === this.apigetTeacherAttendanceStatus) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const totalPage = Math.ceil(responseJson.total_count / this.state.perPage);
            this.setState({ teacherAttendanceList: responseJson, totalPage, totalCount: responseJson.total_count })
            if (!responseJson.message) {
              const filterData = responseJson.data.filter((n: any) => n.attributes.status === this.state.atttendancetypeselected)
              this.setState({ createSelected: filterData })
            }
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
      else if (apiRequestCallId === this.getTeacherPeriodId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ periodList: responseJson.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetTeacherAttendanceChart) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ attendenceChartData: responseJson, loadingReport: false })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }
        this.setState({ loadingSpinner: false })
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End

  }
  // Customizable Area Start
  // Customizable Area End
}
