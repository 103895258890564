// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createRef, RefObject } from "react";

export const configJSON = require("../config.js");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    openChooseFromGalleryModal: boolean;
    onCloseModal: any;
    onClickImage: any;
    onClickChoose: any;
    selectedGalleryImageId: number;
    onTabChangeClearId: any;
    classes:any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    data: any;
    showLoader: boolean;
    openChooseFromGalleryModal: boolean;
    galleryImagelist: any;
    selectedTabValue: number;
    page: number;
    galleryImagelistMeta: any;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ChooseFromGalleryModalController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    getImagesListId: string = "";

    readonly inputOpenFileRef: RefObject<HTMLInputElement>;
    // Customizable Area End
    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);
        // Customizable Area Start
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            data: '',
            showLoader: false,
            openChooseFromGalleryModal: false,
            galleryImagelist: [],
            selectedTabValue: 0,
            page: 0,
            galleryImagelistMeta: {},
        };
        this.userdata = this.userdata.bind(this);
        this.inputOpenFileRef = createRef();
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.openChooseFromGalleryModal && this.props.openChooseFromGalleryModal !== prevProps.openChooseFromGalleryModal) {
            this.get_images_list();
        }
      }

    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }

    onClose = () => {
        this.setState({
            selectedTabValue:0
        }, () => {
            this.props.onTabChangeClearId()
            this.props.onCloseModal()
        })  
    }

    onBackClick = () => {
        this.setState({
            selectedTabValue:0
        }, () => {
            this.props.onCloseModal()
        })  
    }

    onChoose = () => {
        if (this.props.selectedGalleryImageId) { 
            this.setState({
                selectedTabValue:0
            }, () => {
                this.props.onClickChoose()
            })
        }
    }

    onPageChange = (e:any) => {
        if (e.selected !== this.state.page) {
            this.setState({ page: e.selected }, () => {
                if (this.state.selectedTabValue === 1) {
                    this.get_images_list(this.state.selectedTabValue)
                } else {
                    this.get_images_list()
                }
            })
        }
    }

    onChangeTab = (num: number) => {
        this.setState({ selectedTabValue: num, page: 0 }, () => {
            if(num === 1){
                this.get_images_list(num);
            } else {
                this.get_images_list();
            }
        });
        this.props?.onTabChangeClearId();
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.getImagesListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ galleryImagelist: responseJson?.data, galleryImagelistMeta: responseJson?.meta })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showLoader: false })
                this.parseApiCatchErrorResponse(errorResponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    get_images_list = (tabValue?: number) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem('token'),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getImagesListId = requestMessage.messageId;

        let endPoint = configJSON.galleryImagesEndPoint + `?module_type=Question&type=image&page=${this.state.page + 1}&per=9`
        if(tabValue === 1){
            endPoint = endPoint + `&created_by=${localStorage.getItem('id')}`
        }
        this.setState({ showLoader: true })
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.userManagementURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End

}