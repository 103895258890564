// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import HelpIcon from "@material-ui/icons/Help";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import Button from "@material-ui/core/Button";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import ReactPhoneInput from "react-phone-input-2";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Spinner from "../../shared/SideBar/src/Spinner";
import { img21kSchholLogo } from "./assets";
import AdminAccountRegistrationController, {
  Props,
} from "./AdminAccountRegistrationController.web";
import "./EmailAccountRegistration.web.css";
import "./AdminAccountRegistration.web.css";
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class AdminAccountRegistration extends AdminAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneReg = /^(?:[0-9]�?){10,15}[0-9]$/;
    const name = /^[a-zA-Z ]*$/;
    const BootstrapInput = withStyles((theme) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: "8.8px",
        position: "relative",
        border: this.state.instituteTypeerror
          ? "1px solid red"
          : "1px solid #cfcfcf",
        fontSize: 16,
        padding: "18.5px 14px",
        width: "95%",
        height: "30px",
        backgroundColor: "#f6f6f6",
        color: this.state.instituteType === "" ? "#a6a5c0" : "#03014c",
        lineHeight: "21px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
          borderRadius: "8.8px",
          borderColor: "#cfcfcf",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);
    return (
      <>
        {this.state.loadingSpinner && (
          <Spinner spinnerModal={this.state.loadingSpinner} />
        )}
        <Grid container className="AdminAccountRegistrationParentBox">
          <Grid className="Header accountRegistration_div" item xs={12}>
            <img src={img21kSchholLogo} alt="Logo Image" className="logoImage" />
            <HelpIcon className="accountRegistration_helpIcon" />
          </Grid>
          <Grid item xs={12}>
            <Grid container className="typesofAccount_div">
              <Grid item xs={12} className={`typesofAccount_textLabel ${this.props.classes.theme_font}`}>
                Start using 21k school for your today
              </Grid>
              <Grid item xs={12} className={`Follow_the_instructions ${this.props.classes.theme_font}`}>
                Follow the instructions to make it easier to register and you
                will be able to explore inside.
              </Grid>
              <form onSubmit={this.onSubmit}>
                <Grid container className="textfield_div">
                  <Grid item xs={12} sm={6} className="mb3_perc">
                    <TextField
                      id="standard-basic"
                      type="text"
                      placeholder="First Name"
                      className={`forrmInput_textfield InputPropsStyle ${this.props.classes.theme_font}`}
                      variant="outlined"
                      value={this.state.firstname}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value = event.target.value.trim();
                        if (value.length === 0) {
                          this.setState({
                            fnameerror: true,
                            fnameerrorText: "First name is required.",
                          });
                        } else {
                          this.setState({
                            fnameerror: false,
                            fnameerrorText: "",
                          });
                        }
                        if (
                          event.target.value === "" ||
                          name.test(event.target.value)
                        ) {
                          this.setState({ firstname: event.target.value });
                        }
                      }}
                      onBlur={() => {
                        if (
                          this.state.firstname === null ||
                          this.state.firstname.length === 0
                        ) {
                          this.setState({
                            fnameerror: true,
                            fnameerrorText: "First name is required.",
                          });
                        } else {
                          this.setState({
                            fnameerror: false,
                            fnameerrorText: "",
                          });
                        }
                      }}
                      error={this.state.fnameerror}
                      helperText={this.state.fnameerrorText}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlineOutlinedIcon
                              fontSize="small"
                              className="IconStyle"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className="mb3_perc">
                    <TextField
                      id="standard-basic"
                      placeholder="Last Name"
                      className={`forrmInput_textfield InputPropsStyle ${this.props.classes.theme_font}`}
                      variant="outlined"
                      value={this.state.lastname}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value = event.target.value.trim();
                        if (value.length === 0) {
                          this.setState({
                            lnameerror: true,
                            lnameerrorText: " Last name is required.",
                          });
                        } else {
                          this.setState({
                            lnameerror: false,
                            lnameerrorText: "",
                          });
                        }
                        if (
                          event.target.value === "" ||
                          name.test(event.target.value)
                        ) {
                          this.setState({ lastname: event.target.value });
                        }
                      }}
                      onBlur={() => {
                        if (
                          this.state.lastname === null ||
                          this.state.lastname.length === 0
                        ) {
                          this.setState({
                            lnameerror: true,
                            lnameerrorText: " Last name is required.",
                          });
                        } else {
                          this.setState({
                            lnameerror: false,
                            lnameerrorText: "",
                          });
                        }
                      }}
                      error={this.state.lnameerror}
                      helperText={this.state.lnameerrorText}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlineOutlinedIcon
                              fontSize="small"
                              className="IconStyle"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className="mb3_perc">
                    <TextField
                      id="standard-basic"
                      placeholder="Email ID"
                      className={`forrmInput_textfield InputPropsStyle ${this.props.classes.theme_font}`}
                      variant="outlined"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value = event.target.value.trim();
                        if (event.target.value.length === 0) {
                          this.setState({
                            emailerror: true,
                            emailerrorText: "Email is required.",
                          });
                        } else if (!emailReg.test(value)) {
                          this.setState({
                            emailerror: true,
                            emailerrorText: "Enter a valid Email.",
                          });
                        } else if (emailReg.test(value)) {
                          this.setState({
                            emailerror: false,
                            emailerrorText: "",
                          });
                        }
                        this.setState({ email: event.target.value.trim() });
                      }}
                      onBlur={() => {
                        if (
                          this.state.email === null ||
                          this.state.email.length === 0
                        ) {
                          this.setState({
                            emailerror: true,
                            emailerrorText: "Email is required.",
                          });
                        } else if (!emailReg.test(this.state.email)) {
                          this.setState({
                            emailerror: true,
                            emailerrorText: "Enter a valid Email.",
                          });
                        } else if (emailReg.test(this.state.email)) {
                          this.setState({
                            emailerror: false,
                            emailerrorText: "",
                          });
                        } else {
                          this.setState({
                            emailerror: false,
                            emailerrorText: "",
                          });
                        }
                      }}
                      error={this.state.emailerror}
                      helperText={this.state.emailerrorText}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlinedIcon
                              fontSize="small"
                              className="IconStyles"
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="PhoneInputBox"
                  >
                    <div className="w95_per">
                      <ReactPhoneInput
                        country={"in"}
                        value={this.state.phonenumber}
                        placeholder="Phone Number"
                        alwaysDefaultMask={false}
                        autoFormat={false}
                        inputStyle={{
                          width: "100%",
                          height: "70px",
                          fontFamily: "Open sans",
                          fontSize: "15.8px",
                          borderRadius: "8.8px",
                          padding: "5px 48px",
                          backgroundColor: "#f6f6f6",
                          border: this.state.phoneerror
                            ? "1px solid red"
                            : "1px solid lightgray",
                          borderLeft: this.state.phoneerror
                            ? "0px"
                            : "1px solid lightgray",
                        }}
                        buttonStyle={{
                          backgroundColor: "#f1f1f1",
                          border: this.state.phoneerror
                            ? "1px solid red"
                            : "1px solid lightgray",
                          borderRight: this.state.phoneerror
                            ? "0px"
                            : "1px solid lightgray",
                          borderBottomLeftRadius: "8.8px",
                          borderTopLeftRadius: "8.8px",
                          padding: "1px",
                        }}
                        dropdownStyle={{ width: "300px" }}
                        searchStyle={{
                          margin: "0",
                          width: "97%",
                          height: "30px",
                        }}
                        searchClass="search-class"
                        disableSearchIcon
                        enableSearch={true}
                        countryCodeEditable={false}
                        onChange={(value, country: any) => {
                          if (this.state.dialCode !== country.countryCode) {
                            this.setState({
                              phonenumber: country.dialCode,
                              dialCode: country.countryCode,
                            });
                          } else {
                            this.setState({
                              phonenumber: value,
                              dialCode: country.countryCode,
                            });
                          }
                          if (value.length === 0) {
                            this.setState({
                              phoneerror: true,
                              phoneerrorText: "Phone number is required.",
                            });
                          } else if (phoneReg.test(value)) {
                            this.setState({
                              phoneerror: false,
                              phoneerrorText: "",
                            });
                          } else if (!phoneReg.test(value)) {
                            this.setState({
                              phoneerror: true,
                              phoneerrorText: "Enter a valid phone number.",
                            });
                          } else {
                            this.setState({
                              phoneerror: false,
                              phoneerrorText: "",
                            });
                          }
                        }}
                        onBlur={(value: any) => {
                          if (
                            this.state.phonenumber === null ||
                            this.state.phonenumber.length === 0
                          ) {
                            this.setState({
                              phoneerror: true,
                              phoneerrorText: "Phone number is required.",
                            });
                          } else if (!phoneReg.test(this.state.phonenumber)) {
                            this.setState({
                              phoneerror: true,
                              phoneerrorText: "Enter a valid phone number.",
                            });
                          } else if (phoneReg.test(this.state.phonenumber)) {
                            this.setState({
                              phoneerror: false,
                              phoneerrorText: "",
                            });
                          } else {
                            this.setState({
                              phoneerror: false,
                              phoneerrorText: "",
                            });
                          }
                        }}
                      />
                      {this.state.phoneerror && (
                        <FormHelperText
                          className="ErrorText"
                        >
                          {this.state.phoneerrorText}
                        </FormHelperText>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} className="mb3_perc">
                    <TextField
                      id="standard-basic"
                      placeholder="Name of institute"
                      className={`forrmInput_textfield InputPropsStyle2 ${this.props.classes.theme_font}`}
                      variant="outlined"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.value.length === 0) {
                          this.setState({
                            instituteerror: true,
                            instituteerrorText:
                              "Name of institute is required.",
                          });
                        } else {
                          this.setState({
                            instituteerror: false,
                            instituteerrorText: "",
                          });
                        }

                        this.setState({
                          nameofInstitute: event.target.value.trim(),
                        });
                      }}
                      onBlur={() => {
                        if (
                          this.state.nameofInstitute === null ||
                          this.state.nameofInstitute.length === 0
                        ) {
                          this.setState({
                            instituteerror: true,
                            instituteerrorText:
                              "Name of institute is required.",
                          });
                        } else {
                          this.setState({
                            instituteerror: false,
                            instituteerrorText: "",
                          });
                        }
                      }}
                      error={this.state.instituteerror}
                      helperText={this.state.instituteerrorText}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className="mb3_perc">
                    <TextField
                      className={`forrmInput_textfield InputPropsStyle2 ${this.props.classes.theme_font}`}
                      id="filled-number"
                      placeholder="Number of students"
                      type="number"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.value.length === 0) {
                          this.setState({
                            studenterror: true,
                            studenterrorText: "Number of students is required.",
                          });
                        } else {
                          this.setState({
                            studenterror: false,
                            studenterrorText: "",
                          });
                        }

                        this.setState({
                          numberOfStudents: event.target.value.trim(),
                        });
                      }}
                      onBlur={() => {
                        if (
                          this.state.nameofInstitute === null ||
                          this.state.nameofInstitute.length === 0
                        ) {
                          this.setState({
                            studenterror: true,
                            studenterrorText: "Number of students is required.",
                          });
                        } else {
                          this.setState({
                            studenterror: false,
                            studenterrorText: "",
                          });
                        }
                      }}
                      error={this.state.studenterror}
                      helperText={this.state.studenterrorText}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} className="mb3_perc">
                    <FormControl className="selectType">
                      <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={this.state.instituteType}
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          if (event.target.value != 0) {
                            this.setState({ instituteTypeerror: true });
                          }
                          this.setState({
                            instituteTypeerror: false,
                            instituteType: event.target.value as string,
                          });
                        }}
                        input={<BootstrapInput />}
                        displayEmpty
                      >
                        <MenuItem
                          className={`MenuItemStyle ${this.props.classes.theme_font}`}
                          value=""
                          disabled
                        >
                          Name of the Institute
                        </MenuItem>
                        <MenuItem
                          className={`MenuItemStyle ${this.props.classes.theme_font}`}
                          value="college"
                        >
                          college
                        </MenuItem>
                        <MenuItem
                          className={`MenuItemStyle ${this.props.classes.theme_font}`}
                          value="school"
                        >
                          school
                        </MenuItem>
                        <MenuItem
                          className={`MenuItemStyle ${this.props.classes.theme_font}`}
                          value="institute"
                        >
                          institute
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {this.state.instituteTypeerror && (
                      <FormHelperText
                        className="ErrorText"
                      >
                        Please select name of the institute
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="PhoneInputBox"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="requestDemoBtn"
                    >
                      Request For Demo
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(AdminAccountRegistration);
// Customizable Area End