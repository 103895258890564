Object.defineProperty(exports, "__esModule", {
  value: true,
});
const urlConfig = require("../../../../../framework/src/config");
// Customizable Area Start

exports.getEnrollStudent = "/account_block/get_accounts_details";
exports.getStudentsByClassIdAndSubjectId =
  "/bx_block_scheduling/classes/get_students_by_class_and_subject";

exports.enrollContentType = "application/json";
exports.enrollApiMethodType = "GET";
exports.enrollApiPutMethodType = "PUT";
exports.enrollApiPostMethodType = "POST";
exports.enrollApiDeleteMethodType = "DELETE";
exports.enrollHost = "<calculated when request is sent>";
exports.enrollUserAgent = "PostmanRuntime/7.26.5";
exports.enrollAccept = "*/*";
exports.enrollAcceptEncoding = "gzip, deflate, br";
exports.enrollConnection = "keep-alive";
exports.enrolltoken = "";
exports.labelTitleText = "resource";
exports.labelBodyText = "resource Body";
exports.formDataContentType = "multipart/form-data";
exports.instituteURL = urlConfig.instituteURL;
exports.baseUrl = urlConfig.baseURL;
exports.getClassEndPoint =
  "bx_block_scheduling/school_classes/get_uniq_school_classes";
exports.getAllClassesEndpoint =
  "bx_block_scheduling/school_classes?page=1&per=100";
exports.getSubjectEndPoint =
  "bx_block_scheduling/subjects/class_section_subjects";
exports.getFilterEndPoint = "/account_block/enroll_student_by_filter";
exports.addStudentEndPoint = "account_block/admin_enroll_student";
exports.addTeacherEndPoint = "account_block/admin_enroll_teacher";
exports.editStudentEndPoint = "account_block/accounts";
exports.downloadEnrollStudent =
  "/bx_block_bulk_uploading/user_exports/export_teachers";
exports.getStudentBySearchEndPoint = "/account_block/search_accounts";
exports.sendStudentCredEndPoint = "/account_block/send_credential_to_users";
exports.addBulkClassEndPointTeacher =
  "/bx_block_bulk_uploading/user_imports/import_teachers";
exports.resetPasswordEndPoint = "/account_block/resend_password";
exports.restrictionEndPoint =
  "/bx_block_scheduling/subjects/fetch_account_subjects";
exports.getStudentById = "/account_block/accounts";
exports.getAccountDetailsById = "/account_block/fetch_account_data?account_id=";
// Customizable Area End
