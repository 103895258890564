// Customizable Area Start
import React from 'react';
import {
  Grid,
  Typography,
  NativeSelect,
  TextField,
  FormLabel,
  InputBase,
  Chip,
  Divider,
  FormHelperText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { imageClose, imageDropdown } from '../assets';
import '../TeacherAssessment.web.css';
import './common.css';
import {
  skillList,
  proficiencyList,
  difficultyLevelList,
  bloomsTaxonomyList,
} from '../utils/constants';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const forrmInput_textfield = {
  width: '100%',
  borderRadius: '8px',
  textAlign: 'start',
  // color: "#3f526d",
  fontSize: '18px',
  fontWeight: 600,
  // fontFamily: "Open sans",
  backgroundColor: '#f9f8fd',
} as const;
const forrmInput_textfield_error = {
  width: '100%',
  borderRadius: '8px',
  textAlign: 'start',
  // color: "#3f526d",
  fontSize: '18px',
  fontWeight: 600,
  // fontFamily: "Open sans",
  backgroundColor: '#f9f8fd',
  border: '1px solid red',
} as const;
const BootstrapInput1 = withStyles(() => ({
  input: {
    width: '100%',
    textAlign: 'start',
    // color: "#3f526d",
    fontSize: '18px',
    // fontFamily: "Open sans",
    // border: "solid 0.3px rgba(63, 82, 109, 0.5)"
  },
}))(InputBase);
// Customizable Area End

interface Props {
  // Customizable Area Start
  selectedSubject: any;
  onChangeSubject: any;
  chapterListData: any;
  selectedChapter: any;
  onChangeChapter: any;
  unitListData: any;
  selectedUnit: any;
  onChangeUnit: any;
  selectedSkill: any;
  onChangeSkill: any;
  selectedProficiency: any;
  onChangeProficiency: any;
  selectedDifficultyLevel: any;
  onChangeDifficultyLevel: any;
  keyword: string;
  keywordsList: any;
  onChangeKeywords: any;
  onBlurKeywords: any;
  onKeyDownKeywords: any;
  handleDeleteKeyword: any;
  metaDataErrors: any;
  selectedBloomTaxonomy: any;
  onChangeBloomTaxonomy: any;
  classes: any;
  // Customizable Area End
}

export const QuestionMetaData = ({
  // Customizable Area Start
  selectedSubject,
  onChangeSubject,
  chapterListData,
  selectedChapter,
  onChangeChapter,
  unitListData,
  selectedUnit,
  onChangeUnit,
  selectedSkill,
  onChangeSkill,
  selectedProficiency,
  onChangeProficiency,
  selectedDifficultyLevel,
  onChangeDifficultyLevel,
  keyword,
  keywordsList,
  onChangeKeywords,
  onBlurKeywords,
  onKeyDownKeywords,
  handleDeleteKeyword,
  metaDataErrors,
  selectedBloomTaxonomy,
  onChangeBloomTaxonomy,
  classes,
}: // Customizable Area End
  Props) => {
  // Customizable Area Start
  return (
    <>
      <Typography className={`${classes.heading_font} typography`}>
        Meta Data
      </Typography>

      <div>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <FormLabel
                  className={`meta_data_title ${classes.subHeading_font}`}
                >
                  Chapter*
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <NativeSelect
                  id="select"
                  placeholder="Select chapter"
                  style={
                    metaDataErrors.chapter
                      ? forrmInput_textfield_error
                      : forrmInput_textfield
                  }
                  variant="outlined"
                  IconComponent={() => (
                    <img src={imageDropdown} className="width-margin-right" />
                  )}
                  // IconComponent={ExpandMoreIcon}
                  value={selectedChapter}
                  error={metaDataErrors.chapter}
                  onChange={onChangeChapter}
                  // onBlur={onBlurBatch}
                  inputProps={{
                    style: {
                      fontSize: '18px',
                      padding: '1rem 1.2rem',
                      lineHeight: '22px',
                      height: 28,
                    },
                  }}
                  input={<BootstrapInput1 />}
                  className={classes.bodyText_font}
                >
                  <option value="" disabled>
                    Select chapter
                  </option>
                  {chapterListData?.map((item: any) => (
                    <option value={item.id} key={item.id}>
                      {item?.attributes?.name}
                    </option>
                  ))}
                </NativeSelect>
                {metaDataErrors.chapter && (
                  <FormHelperText className="color-red">
                    Please select chapter.
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <FormLabel
                  className={`meta_data_title ${classes.subHeading_font}`}
                >
                  Unit*
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <NativeSelect
                  id="select"
                  placeholder="Select unit"
                  style={
                    metaDataErrors.unit
                      ? forrmInput_textfield_error
                      : forrmInput_textfield
                  }
                  variant="outlined"
                  IconComponent={() => (
                    <img src={imageDropdown} className="width-margin-right" />
                  )}
                  value={selectedUnit}
                  error={metaDataErrors.unit}
                  onChange={onChangeUnit}
                  inputProps={{
                    style: {
                      fontSize: '18px',
                      padding: '1rem 1.2rem',
                      lineHeight: '22px',
                      height: 28,
                    },
                  }}
                  input={<BootstrapInput1 />}
                  className={classes.bodyText_font}
                >
                  <option value="" disabled>
                    Select unit
                  </option>
                  {unitListData?.map((item: any) => (
                    <option value={item.id} key={item.id}>
                      {item?.attributes?.unit_name}
                    </option>
                  ))}
                </NativeSelect>
                {metaDataErrors.unit && (
                  <FormHelperText className="color-red">
                    Please select unit.
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <FormLabel
                  className={`meta_data_title ${classes.subHeading_font}`}
                >
                  Skill*
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-basic"
                  placeholder="Enter skill"
                  style={
                    metaDataErrors.skill
                      ? forrmInput_textfield_error
                      : forrmInput_textfield
                  }
                  value={selectedSkill}
                  className={`no-underline ${classes.questionMetaData_AssessmentTest_input_theme_style}`}
                  onChange={onChangeSkill}
                  error={metaDataErrors.skill}
                  inputProps={{
                    style: {
                      fontSize: '18px',
                      padding: '1rem 1.2rem',
                      border: 'none',
                      borderColor: 'transparent',
                      height: 28,
                      fontWeight: 600,
                    },
                  }}
                />
                {/*
                                <NativeSelect
                                    id="select"
                                    placeholder="Select skill"
                                    style={metaDataErrors.skill ? forrmInput_textfield_error : forrmInput_textfield}
                                    variant="outlined"
                                    IconComponent={() => (
                                        <img src={imageDropdown} style={{ marginRight: 20, width: 20 }} />
                                    )}
                                    value={selectedSkill}
                                    error={metaDataErrors.skill}
                                    onChange={onChangeSkill}
                                    inputProps={{ style: { fontFamily: "Open sans", fontSize: '18px', color: "#3f526d", padding: '1rem 1.2rem', lineHeight: "22px", height: 28 } }}
                                    input={<BootstrapInput1 />}
                                >
                                    <option value="" disabled>Select skill</option>
                                    {skillList?.map((item: any) => (
                                        <option value={item.value} key={item.value}> {item.label} </option>
                                    ))}

                                </NativeSelect> */}
                {metaDataErrors.skill && (
                  <FormHelperText className="color-red">
                    Please select skill.
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <FormLabel
                  className={`meta_data_title ${classes.subHeading_font}`}
                >
                  Proficiency*
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-basic"
                  placeholder="Enter proficiency"
                  style={
                    metaDataErrors.proficiency
                      ? forrmInput_textfield_error
                      : forrmInput_textfield
                  }
                  value={selectedProficiency}
                  className={`no-underline ${classes.questionMetaData_AssessmentTest_input_theme_style}`}
                  onChange={onChangeProficiency}
                  error={metaDataErrors.proficiency}
                  inputProps={{
                    style: {
                      fontSize: '18px',
                      padding: '1rem 1.2rem',
                      border: 'none',
                      borderColor: 'transparent',
                      height: 28,
                      fontWeight: 600,
                    },
                  }}
                />
                {/* <NativeSelect
                                    id="select"
                                    placeholder="Select proficiency"
                                    style={metaDataErrors.proficiency ? forrmInput_textfield_error : forrmInput_textfield}
                                    variant="outlined"
                                    IconComponent={() => (
                                        <img src={imageDropdown} style={{ marginRight: 20, width: 20 }} />
                                    )}
                                    value={selectedProficiency}
                                    error={metaDataErrors.proficiency}
                                    onChange={onChangeProficiency}
                                    inputProps={{ style: { fontFamily: "Open sans", fontSize: '18px', color: "#3f526d", padding: '1rem 1.2rem', lineHeight: "22px", height: 28 } }}
                                    input={<BootstrapInput1 />}
                                >
                                    <option value="" disabled>Select proficiency</option>
                                    {proficiencyList?.map((item: any) => (
                                        <option value={item.value} key={item.value}> {item.label} </option>
                                    ))}

                                </NativeSelect> */}
                {metaDataErrors.proficiency && (
                  <FormHelperText className="color-red">
                    Please select proficiency.
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <FormLabel
                  className={`meta_data_title ${classes.subHeading_font}`}
                >
                  Difficulty Level*
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <NativeSelect
                  id="select"
                  placeholder="Select difficulty level"
                  style={
                    metaDataErrors.difficultyLevel
                      ? forrmInput_textfield_error
                      : forrmInput_textfield
                  }
                  variant="outlined"
                  IconComponent={() => (
                    <img src={imageDropdown} className="width-margin-right" />
                  )}
                  value={selectedDifficultyLevel}
                  error={metaDataErrors.difficultyLevel}
                  onChange={onChangeDifficultyLevel}
                  inputProps={{
                    style: {
                      fontSize: '18px',
                      padding: '1rem 1.2rem',
                      lineHeight: '22px',
                      height: 28,
                    },
                  }}
                  input={<BootstrapInput1 />}
                  className={classes.bodyText_font}
                >
                  <option value="" disabled>
                    Select difficulty level
                  </option>
                  {difficultyLevelList?.map((item: any) => (
                    <option value={item.value} key={item.value}>
                      {' '}
                      {item.label}{' '}
                    </option>
                  ))}
                </NativeSelect>
                {metaDataErrors.difficultyLevel && (
                  <FormHelperText className="color-red">
                    Please select difficulty level.
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <FormLabel
                  className={`meta_data_title ${classes.subHeading_font}`}
                >
                  Bloom’s Taxonomy*
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <NativeSelect
                  id="select"
                  placeholder="Select bloom’s taxonomy"
                  style={
                    metaDataErrors.bloomTaxonomy
                      ? forrmInput_textfield_error
                      : forrmInput_textfield
                  }
                  variant="outlined"
                  IconComponent={() => (
                    <img
                      src={imageDropdown}
                      style={{ marginRight: 20, width: 20 }}
                    />
                  )}
                  value={selectedBloomTaxonomy}
                  error={metaDataErrors.bloomTaxonomy}
                  onChange={onChangeBloomTaxonomy}
                  inputProps={{
                    style: {
                      fontSize: '18px',
                      padding: '1rem 1.2rem',
                      lineHeight: '22px',
                      height: 28,
                    },
                  }}
                  input={<BootstrapInput1 />}
                  className={classes.bodyText_font}
                >
                  <option value="" disabled>
                    Select bloom’s taxonomy
                  </option>
                  {bloomsTaxonomyList?.map((item: any) => (
                    <option value={item.value} key={item.value}>
                      {' '}
                      {item.label}{' '}
                    </option>
                  ))}
                </NativeSelect>
                {metaDataErrors.bloomTaxonomy && (
                  <FormHelperText className="color-red">
                    Please select bloom’s taxonomy.
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <FormLabel
                  className={`meta_data_title ${classes.subHeading_font}`}
                >
                  Tags*
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-basic"
                  placeholder="Tag"
                  style={
                    metaDataErrors.keywords
                      ? forrmInput_textfield_error
                      : forrmInput_textfield
                  }
                  // variant="outlined"
                  value={keyword}
                  className={`no-underline ${classes.questionMetaData_AssessmentTest_input_theme_style}`}
                  onChange={onChangeKeywords}
                  onBlur={onBlurKeywords}
                  onKeyDown={onKeyDownKeywords}
                  error={metaDataErrors.keywords}
                  // helperText="Please enter keyword."
                  inputProps={{
                    style: {
                      fontSize: '18px',
                      padding: '1rem 1.2rem',
                      border: 'none',
                      borderColor: 'transparent',
                      height: 28,
                    },
                  }}
                  InputProps={{
                    startAdornment: keywordsList?.map((item: any) => (
                      <Chip
                        key={item}
                        tabIndex={-1}
                        label={item}
                        deleteIcon={
                          <img src={imageClose} className="chip-img" />
                        }
                        onDelete={() => handleDeleteKeyword(item)}
                        className={`${classes.questionMetaData_AssessmentTest_Tags_style} ${classes.background_color} chip`}
                      />
                    )),
                    style: {
                      fontSize: '18px',
                      color: '#3f526d',
                      fontFamily: 'OpenSans',
                      opacity: 1,
                      paddingLeft: 0,
                      border: 'none',
                    },
                  }}
                />
                {metaDataErrors.keywords ? (
                  <FormHelperText className="color-red">
                    Please enter tag.
                  </FormHelperText>
                ) : (
                  <FormHelperText className={classes.bodyText_font}>
                    Please hit enter to Add new tag.
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
  // Customizable Area End
};

// Customizable Area Start
//@ts-ignore
export default withStyles(themeCreateStyle)(QuestionMetaData);
// Customizable Area End
