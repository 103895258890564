// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    showLoader: boolean;
    teacherSubjects: Array<{}>;
    gradesList: Array<{}>;
    grade_id: string;
    teacherData: any;
    selectedClass: any;
    // Customizable Area End
}
export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    // Customizable Area End
}
export default class AssessmentTabController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetGradeList: string = "";
    apiGetSubjectWithAssessment: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            showLoader: false,
            teacherSubjects: [],
            gradesList: [],
            grade_id: "",
            teacherData: {},
            selectedClass: null
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.handleClassChange = this.handleClassChange.bind(this);
        this.handleShowAssessments = this.handleShowAssessments.bind(this);
        this.getGradeList = this.getGradeList.bind(this);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const localUserData = localStorage.getItem('user_data');
        if (localUserData) {
            this.setState({ teacherData: JSON.parse(localUserData) }, () => {
                this.getGradeList();
            });
        }
    }

    handleClassChange(event: any) {
        const classSelected = this.state.gradesList?.find((item: any) => parseInt(item?.id) === parseInt(event.target.value))
        this.setState({ grade_id: event.target.value, selectedClass: classSelected, showLoader: true }, () => {
            this.getSubjectListWithAssessments();
        });
    }

    handleShowAssessments(subjectData: any) {
        HISTORY.push({
            pathname: "/LibraryAssessmentList",
            state: { subject:subjectData, currentClass: this.state.selectedClass },
        });
        // for assessment edit back flow
        localStorage.setItem('assessment_library_setting', JSON.stringify({ subject:subjectData, currentClass: this.state.selectedClass }));
        localStorage.setItem('came_from_assessment_list', 'true');
    }

    // Customizable Area End
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if (responseJson != null) {
                if (!responseJson.errors) {
                    if (apiRequestCallId === this.apiGetGradeList) {
                        this.setState({
                            gradesList: responseJson.data
                        })
                    } else if (apiRequestCallId === this.apiGetSubjectWithAssessment) {
                        this.setState({
                            teacherSubjects: responseJson.data
                        })
                    }
                } else {
                    this.parseApiErrorResponse(responseJson);
                }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.setState({ showLoader: false });
        }
        // Customizable Area End
    }
    // Customizable Area Start
    getGradeList = () => {
        this.setState({ showLoader: true });
        const { teacherData } = this.state;
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: localStorage.getItem('token'),
            school: teacherData?.school_id
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetGradeList = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTeachersGradeEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSubjectListWithAssessments = () => {
        const { teacherData, grade_id } = this.state;
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: localStorage.getItem('token'),
            school: teacherData?.school_id
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetSubjectWithAssessment = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getSubjectsWithAssessmentCount + `?grade_id=${grade_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}