// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    listView: string;
    classes:any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    openModal: boolean;
    feedback: string | null;
    teacherAssociated: string | null;
    // teacherMembers: any;
    allTeacherMembers: any;
    // studentMembers: any;
    allStudentMembers: any;
    data: any;
    queryTerm: string;
    viewAllTeachers: boolean;
    viewAllStudents: boolean;
    searchedTeacherMembers: any;
    searchedStudentMembers: any;
    selectAll: boolean;
    createSelected: any,
    selectAllStudents: boolean;
    createSelectedStudents: any,
    anchorEl:any;
    page_no: number;
    teacher_page_no:number;
    hasMore:boolean;
    member_type:string;
    hasteacherMore:boolean;
    per_page:number;
    showSpinner:boolean;
    showTeacherSpinner:boolean;
    divisionDropDownAnchorEl: any;
    divisionList: any;
    filterDivisionId: any;
    tempDivision: any;
    allStudentMembersCount: number;
    filterApplied: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class StudentMembersController extends BlockComponent<Props, S, SS>{
    // Customizable Area Start
    apiGetTeachersList: string = "";
    apiGetStudentsList: string = "";
    apigetClassDivisionlId: string = "";
    apiGetStudentsListByDivision: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.getTeachersList = this.getTeachersList.bind(this);
        this.getStudentsList = this.getStudentsList.bind(this);

        this.state = {
            openModal: false,
            feedback: "",
            teacherAssociated: "",
            // teacherMembers: [],
            allTeacherMembers: [],
            // studentMembers: [],
            allStudentMembers: [],
            data: '',
            queryTerm: "",
            viewAllTeachers: false,
            viewAllStudents: false,
            searchedTeacherMembers: [],
            searchedStudentMembers: [],
            selectAll: false,
            createSelected: [],
            selectAllStudents: false,
            createSelectedStudents: [],
            anchorEl:null,
            page_no: 1,
            teacher_page_no:1,
            hasMore:true,
            member_type:'',
            hasteacherMore:true,
            per_page:20,
            showSpinner: false,
            showTeacherSpinner: false,
            divisionDropDownAnchorEl: null,
            divisionList: [],
            filterDivisionId: "",
            tempDivision: [],
            allStudentMembersCount: 0,
            filterApplied: false,
        }
        // Customizable Area End
    }
    
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        this.userdata();
        this.getTeachersList();
        this.getStudentsList();
        this.get_division();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener('willFocus', () => {
                this.getToken();
            });
        }
    }
    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        console.log("user data", data)
        this.setState({ data: data });
    }

    getToken = () => {
        const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
        this.send(msg);
    }

    getTeachersList = () => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        this.setState({ showTeacherSpinner: true })
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem('token')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetTeachersList = requestMessage.messageId;
        let apiEndPoint = configJSON.getTeachers+ `?page=${this.state.teacher_page_no}&per_page=8&grade_id=${school_Data?.school_account?.data[0]?.attributes?.grade_id}`
        if(this.state.queryTerm) {
            apiEndPoint = apiEndPoint + `&search=${this.state.queryTerm}`;
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getStudentsList = () => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        this.setState({ showSpinner: true })
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem('token')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetStudentsList = requestMessage.messageId;
        let apiEndPoint = configJSON.getStudents+ `?page=${this.state.page_no}&per_page=8&grade_id=${school_Data?.school_account?.data[0]?.attributes?.grade_id}`
        if(this.state.queryTerm) {
            apiEndPoint = apiEndPoint + `&search=${this.state.queryTerm}`;
        }
        
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            switch (apiRequestCallId) {
                case this.apiGetTeachersList: {
                    if (responseJson != null) {
                        if (!responseJson.errors) {
                           
                            if(this.state.teacher_page_no === 1){
                                this.setState({ allTeacherMembers: [] });
                            } 
                            // this.setState({ teacherMembers: responseJson?.data?.slice(0, 8) });
                            if (responseJson?.data !== null) {
                                const user_data = [...this.state.allTeacherMembers, ...responseJson?.data];
                                this.setState({ allTeacherMembers: user_data });
                            }
                            if(responseJson.meta.pagination.next_page === null){
                                this.setState({hasteacherMore : false})
                            }else{
                                this.setState({hasteacherMore : true})
                            }

                           
                            this.setState({ showTeacherSpinner: false });
                            console.log('teachers List =======>>>>>>', responseJson?.data);
                        } else {
                            this.parseApiErrorResponse(responseJson);
                        }
                    }
                    this.setState({ showTeacherSpinner: false });
                    this.parseApiCatchErrorResponse(errorReponse);
                }
                    break;
                case this.apiGetStudentsList: {
                    if (responseJson != null) {
                        if (!responseJson.errors) {
                            
                            if(this.state.page_no === 1){
                                this.setState({ allStudentMembers: [] });
                            }            
                            if (responseJson?.data !== null) {
                                const user_data = [...this.state.allStudentMembers, ...responseJson?.data];
                                this.setState({ allStudentMembers: user_data, allStudentMembersCount: responseJson?.meta?.pagination?.total_count });
                            } else {
                                this.setState({ allStudentMembers: [], showSpinner: false, allStudentMembersCount: 0 });
                            }
                            if(responseJson.meta.pagination.next_page === null){
                                this.setState({hasMore : false})
                            }else{
                                this.setState({hasMore : true})
                            }
                            this.setState({ showSpinner: false })                                          
                            console.log('Students List =======>>>>>>', responseJson?.data);
                        } else {
                            this.parseApiErrorResponse(responseJson);
                        }
                    }
                    this.setState({ showSpinner: false });
                    this.parseApiCatchErrorResponse(errorReponse);
                }
                    break;
                case this.apigetClassDivisionlId: {
                    if (responseJson != null) {
                        if (!responseJson.errors) {
                            this.setState({ divisionList: responseJson?.data })                                               
                        } else {
                            this.parseApiErrorResponse(responseJson);
                        }
                    }

                    this.parseApiCatchErrorResponse(errorReponse);
                }
                    break;
                case this.apiGetStudentsListByDivision: {
                    if (responseJson != null) {
                        if (!responseJson.errors) {
                            if(this.state.page_no === 1){
                                this.setState({ allStudentMembers: [] });
                            }                       
                            if (responseJson?.data?.length === 0) {
                                this.setState({ hasMore: false })
                            } else {
                                this.setState({ hasMore: true })
                            }
                            if(this.state.page_no === 1){
                                this.setState({ allStudentMembers: [] });
                            } 
                            if (responseJson?.data != null) {
                                const user_data = [...this.state.allStudentMembers, ...responseJson?.data];
                                this.setState({ allStudentMembers: user_data, showSpinner: false, allStudentMembersCount: responseJson?.meta?.pagination?.total_count });
                            } else{
                                this.setState({ allStudentMembers: [], showSpinner: false, allStudentMembersCount: 0 });
                            }
                        } else {
                            this.parseApiErrorResponse(responseJson);
                        }
                    }
                    this.parseApiCatchErrorResponse(errorReponse);
                }
                    break;
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getRoleFromAccountType = (roleAccount: string) => {
        let memberRole: string = ""
        switch (roleAccount) {
            case "TeacherAccount":
                memberRole = "Teacher"
                break;
            case "StudentAccount":
                memberRole = "Student"
                break;
            case "ParentAccount":
                memberRole = "Parent"
                break;              
            case "AdminAccount":
                memberRole = "Admin"
                break;    
            default:
                break;
        }
        return memberRole
    }

    onChangeSearchTerm= (event: any) => {
        const { allTeacherMembers, allStudentMembers} = this.state
        this.setState({ queryTerm: event?.target?.value})
    }

    fetchMoreData = () => {
        this.setState({ page_no: this.state.page_no + 1 }, () =>{
        if (this.state.filterApplied) {
            this.applyDivisionFilter()
        } else {
            this.getStudentsList()
        }  }
        )
    }

    fetchMoreTeacherData = () => {
        this.setState({ teacher_page_no: this.state.teacher_page_no + 1 },()=>this.getTeachersList())
        
    }

    handleCheckboxClick = (event: any, id: any) => {
        event.stopPropagation();
        // console.log('event, id', event, id)
        const { createSelected, allTeacherMembers } = this.state;
        const selectedIndex = createSelected.indexOf(id);
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(createSelected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(createSelected.slice(1));
        } else if (selectedIndex === createSelected.length - 1) {
            newSelected = newSelected.concat(createSelected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                createSelected.slice(0, selectedIndex),
                createSelected.slice(selectedIndex + 1)
            );
        }
        // console.log('newSelected', newSelected)
        this.setState({ createSelected: newSelected });
        if(newSelected?.length === allTeacherMembers?.length){
            this.setState({ selectAll: true })
        }else{
            this.setState({ selectAll: false })
        }      
    };

    onMenuClose = () => {
        this.setState({anchorEl:null})
      };
    

    handleSelectAll = () => {       
        const { createSelected } = this.state;
        let newSelected: any[] = [];
        newSelected = this.state.allTeacherMembers?.map((item: any) => {            
            return item.id;       
        })
        // console.log('newSelected', newSelected)
        if(!this.state.selectAll){
            this.setState({ createSelected: newSelected }); 
        }else{
            this.setState({ createSelected: [] }); 
        }
           
        this.setState({ selectAll: !this.state.selectAll })
    }

    isSelected = (id: any) => {
        // console.log('this.state.createSelected', this.state.createSelected)
        return !!this.state.createSelected.find((item: any) => item === id)
    }

    handleStudentCheckboxClick = (event: any, id: any) => {
        event.stopPropagation();
        // console.log('event, id', event, id)
        const { createSelectedStudents, allStudentMembers } = this.state;
        const selectedIndex = createSelectedStudents.indexOf(id);
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(createSelectedStudents, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(createSelectedStudents.slice(1));
        } else if (selectedIndex === createSelectedStudents.length - 1) {
            newSelected = newSelected.concat(createSelectedStudents.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                createSelectedStudents.slice(0, selectedIndex),
                createSelectedStudents.slice(selectedIndex + 1)
            );
        }
        // console.log('newSelected', newSelected)
        this.setState({ createSelectedStudents: newSelected });
        if(newSelected?.length === allStudentMembers?.length){
            this.setState({ selectAllStudents: true })
        }else{
            this.setState({ selectAllStudents: false })
        }      
    };

    handleSelectAllStudents = () => {       
        let newSelected: any[] = [];
        newSelected = this.state.allStudentMembers?.map((item: any) => {            
            return item.id;       
        })
        // console.log('newSelected', newSelected)
        if(!this.state.selectAllStudents){
            this.setState({ createSelectedStudents: newSelected }); 
        }else{
            this.setState({ createSelectedStudents: [] }); 
        }
           
        this.setState({ selectAllStudents: !this.state.selectAllStudents })
    }

    isSelectedStudents = (id: any) => {
        // console.log('this.state.createSelectedStudents', this.state.createSelectedStudents)
        return !!this.state.createSelectedStudents.find((item: any) => item === id)
    }

    get_division = () => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem('token')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetClassDivisionlId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDivisionEndPoint +`?grade_id=${school_Data?.school_account?.data[0]?.attributes?.grade_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    applyDivisionFilter = () => {
        const arrOfNumDivisionId = this.state.filterDivisionId.map((strId: any) => {
            return Number(strId);
        });
        this.setState({ showSpinner: true, anchorEl:null, tempDivision: [] ,filterApplied: true});
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem('token'),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetStudentsListByDivision = requestMessage.messageId;
     
        const httpBody = {         
            grade_id: school_Data?.school_account?.data[0]?.attributes?.grade_id,
            division_ids: arrOfNumDivisionId 
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        let apiEndPoint =  configJSON.getStudentsByDivision + `?page=${this.state.page_no}&per_page=8`
        if(this.state.queryTerm) {
            apiEndPoint = apiEndPoint + `&search=${this.state.queryTerm}`;
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}