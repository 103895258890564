// Customizable Area Start
import React, { useState } from "react";
import { ILiveClassesProps } from "../TeacherLiveClassController";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from "@material-ui/core/styles";
import {
  image_info,
  image_lunch_time,
  information_1, infoIcon,
  clock,
  bitmap_camera,
  imageCameraicon, subjectIcon, video_camera_live
} from "../assets";
import { Typography, Button, Grid, Tooltip } from "@material-ui/core";
import moment from "moment";
import EventToolTip from "./EventToolTip.web";
import { MeetingStatus } from "../../utils/constants";
import { useTheme } from '@material-ui/core/styles';
import '../../StudentClassesAndSection.web.css'
import DivisionSelectDivController from "../../DivisionSelectDivController.web";
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

interface IDayEvent {
  // Customizable Area Start
  event: ILiveClassesProps;
  isTeacher: boolean;
  joinMeeting: () => void;
  onBodyClick?: () => void;
  handleOpenZoomMeeting?: () => void;
  classes: any
  // Customizable Area End
}
// Customizable Area Start
const DayEvent: React.FC<IDayEvent> = ({
  event,
  isTeacher,
  joinMeeting,
  onBodyClick, handleOpenZoomMeeting, classes
}: IDayEvent) => {
  const {
    title,
    start,
    end,
    image,
    subject,
    meetingTitle,
    unit,
  } = event as ILiveClassesProps;

  console.log("event",event)
  // Customizable Area Start
  const duration = moment.duration(moment(event.end).diff(moment(event.start)));
  const today = moment();
  const lectureEndTime = moment(event.end, "DD MMM YYYY HH:mma").isBefore(today);
  const lectureStartTime = moment(event.start).diff(moment(new Date()), 'minutes') >= 10

  const hours = duration.asHours();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleModalClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };
  const theme = useTheme();
  const screenSize = useMediaQuery(theme.breakpoints.up('sm'));
  // Customizable Area End

  return (
    <>
      {event.type === "lunchTime" ? (
        <div
          className="day-event"
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "32px",
            paddingBottom: "2px",
          }}
          onClick={onBodyClick}
        >
          <img src={image_lunch_time} style={{ height: 36, width: 36 }} />
          <div
            className="font_14"
            style={{
              fontFamily: "Open sans",
              // fontSize: 14,
              fontWeight: 600,
              color: "#3f526d",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              marginLeft: 8,
            }}
          >
            {title}
          </div>
        </div>
      ) : (
        <Grid
          container
          className="day-event"
          style={{
            padding: screenSize ? "5px 5px 5px 0px" : '2px 2px 2px 0',
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "inherit",
            overflow: "auto"
          }}
        >
          {screenSize && <Grid

            item
            xs={1}
            style={{
              //height: 60,
              //width: 60,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                height: 60,
                width: 60,
                borderRadius: 40,
                backgroundColor: "#fff2de",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {event?.subjectImage ? <img
                style={{
                  height: "40px",
                  width: "40px",
                  alignSelf: "center",
                }}
                src={event.subjectImage}
              /> : <img
                style={{
                  height: "40px",
                  width: "40px",
                  alignSelf: "center",
                }}
                src={subjectIcon}
              />}
            </span>
          </Grid>}

          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              height:'100%',
              alignItems: "center",
            }}
          >
            <div
              style={{
                // fontSize: 18,
                fontWeight: 600,
              }}
              className={`font_18
              `}
              //  ${classes.title_font}
            >
              {subject}
            </div>
            <div style={{
                fontSize: 13,
                fontWeight: 600,
              }}
              className={`
              `
            }>
               {/* ${classes.title_font} */}
              {event.teacherName}
            </div>
            {isTeacher && (
              <div
                className="font_14"
                style={{
                  fontFamily: "Open sans",
                  // fontSize: 14,
                  color: "rgb(66 70 78)",
                  marginTop: "5%",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden"
                }}
              >
                (Class: {event.class})
              </div>
            )}
          </Grid>

          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              style={{ height: 20, width: 32, objectFit: "contain" }}
              src={video_camera_live}
            />
            <div
              style={{
                // fontSize: 14,
                fontWeight: 600,
                lineHeight: 1.67,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              className={`font_14 ${classes.subTitle_font}`}
            >
              {/* {title} */}
              Online Class
            </div>
          </Grid>

          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                // fontSize: 16,
                fontWeight: 500,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                lineHeight: 'normal'
              }}
              className={`font_16 ${classes.subTitle_font}`}
            >
              {title}
            </div>
            {/* <div
              style={{
                fontFamily: "Open sans",
                fontSize: 14,
                color: "#000",
              }}
            >
              {unit}
            </div> */}
          </Grid>

          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{
                height: 20,
                width: 20,
                marginLeft: 10,
              }}
              src={infoIcon}
              onClick={(event: any) => handleModalClick(event)}
            />
          </Grid>

          <Grid item xs={2}>
            <Typography
              className="font_18"
              component="span"
              style={{
                fontFamily: "Open sans",
                // fontSize: 18,
                color: "#3f526d",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  height: 20,
                  width: 20,
                  marginRight: 10,
                }}
                src={clock}
              />
              {/* <Tooltip
                title={
                  <h6 style={{ fontSize: "14px", margin: "2px 0" }}>
                    Duration: {hours} hour
                  </h6>
                }
                placement="top"
                enterTouchDelay={100}
              >
                <span
                  style={{
                    fontWeight: 600, fontSize: "14px", textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden"
                  }}
                  className="ellipse-text"
                >
                  {`Duration: ${hours} hour`}
                </span>
              </Tooltip> */}
              <span

                style={{
                  fontWeight: 600, textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden"
                }}
                className={`ellipse-text font_14 ${classes.subTitle_font}`}
              >
                {screenSize ? `Duration: ${hours} hour` : `${hours} hour`}
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {event?.status?.toLowerCase() === MeetingStatus.Ended ? <Button
              disabled={true}
              className={"btn_ended_meet"}
              style={{ fontFamily: "Open sans" }}
            //onClick={joinMeeting}

            >
              <Typography className="label_end_class font_14">End Class</Typography>
            </Button>
              :
              <Button
                className={`live_classes_view_joinnow ${event?.status?.toLowerCase() === MeetingStatus.Ongoing ? classes.button_color : classes.button_color_disabled}`}
                style={{
                  opacity: event?.status &&
                    (isTeacher ?
                      event?.status && event.status?.toLowerCase() === MeetingStatus.Upcoming || MeetingStatus.Ongoing
                      : event?.status && event.status?.toLowerCase() === MeetingStatus.Ongoing) &&
                    (!lectureEndTime && !lectureStartTime) ? 1 : 0.4,
                  // borderImageSource: event?.status?.toLowerCase() === MeetingStatus.Ongoing ? "linear-gradient(to top, #4cb90d, #68e953)" : "linear-gradient(to top, #af38cb, #471c51)",
                  // backgroundImage: event?.status?.toLowerCase() === MeetingStatus.Ongoing ? "linear-gradient(to top, #4cb90d, #68e953),linear-gradient(to top, #4cb90d, #68e953)" : "linear-gradient(to top, #af38cb, #471c51),linear-gradient(to top, #af38cb, #471c51)"
                }}
                disabled={!(event?.status &&
                  (isTeacher ?
                    event?.status && event.status?.toLowerCase() === MeetingStatus.Upcoming || MeetingStatus.Ongoing
                    : event?.status && event.status?.toLowerCase() === MeetingStatus.Ongoing) &&
                  (!lectureEndTime && !lectureStartTime))}
                onClick={joinMeeting}
              //disabled={!isTeacher && moment(lectureStartTime, "DD MMM YYYY HH:mma").isAfter(today)}
              >
                <Typography className="label_joinnow font_14">{event?.status?.toLowerCase() === MeetingStatus.Ongoing ? "Ongoing" : isTeacher ? "Start Class" : "Join Class"}</Typography>
              </Button>}
          </Grid>
          <EventToolTip
            event={event}
            anchorEl={anchorEl}
            handleClose={handleClose}
            isTeacher={isTeacher}
            joinMeeting={joinMeeting}
          />
        </Grid>
      )}
    </>
  );
};
// Customizable Area End
// Customizable Area Start
export default withStyles(themeCreateStyle)(DayEvent);
// Customizable Area End
