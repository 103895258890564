export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const img21kSchholLogo = require("../assets/HeyCampus.png").default;
export const student_at = require("../assets/group-2.png");
export const parent_at = require("../assets/screenshot-2021-08-27-at-4-13-16-pm.png");
export const teacher_at = require("../assets/group-3.png");
export const student_background = require("../assets/rectangle.svg");
export const teacher_background = require("../assets/oval.svg");
export const parent_background = require("../assets/rectanglegreen.svg");
export const shield_image = require("../assets/secure-shield-4013131-3328653-0.png");
export const openEmail_image = require("../assets/open-mail-3732323-3121305.png");
export const check = require("../assets/group-6@3x.png");
export const img21kSchholLogo1 = require("../assets/logo.png")
export const imgloginScreenBackground = require("../assets/5960511fc2d87d4daf4cc703ead7575b56e784c2.png")
  .default;
export const manImage = require("../assets/e6c715c3911a4b94bcec01de06660f7797f20906.png")
  .default;
export const bigCloud = require("../assets/1648e95fb4228d91b398ec2537155970ea177e68.png")
  .default;
export const smallCloud = require("../assets/c06e06dbe043cae67d56fbfc0834974f5831a8c7.png")
  .default;
export const successIcon = require("../assets/image-checkedgreen@3x.jpg");
export const errorIcon = require("../assets/image-checkedgreen@3x.png");
export const schoolIcon = require("../assets/school.png");
export const demoSchoolIcon = require("../assets/schoolIcon.png");
export const topNavigation = require("../assets/topNavigation.png");
export const upload = require("../assets/upload.png");
export const dateIcon = require("../assets/dateIcon.png");
export const boardIcon = require("../assets/boardIcon.png");
export const location = require("../assets/location.png");
export const affiliationIcon = require("../assets/affiliationIcon.png");
export const editColor = require("../assets/editColor.png");
export const pending = require("../assets/pending.png");
export const successFull = require("../assets/successFull.png");
export const addicon = require("../assets/add.png");
export const deleteicon = require("../assets/delete-outline.png");
export const calendar = require("../assets/addCalendar.png").default;
