// Customizable Area Start
import React from "react";
import { Modal } from "@material-ui/core";
import CapturePhotoViaWebcam from "./CapturePhotoViaWebcam.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface Props {
  // Customizable Area Start
  isTeacher: boolean;
  takePictureModal: boolean;
  onCloseModal: any;
  handleSave: (file: File) => void;
  loadingSpinner: boolean;
  // Customizable Area End
}

export const TakeProfilePictureModal = ({
  // Customizable Area Start
  isTeacher,
  takePictureModal,
  onCloseModal,
  handleSave,
  loadingSpinner,
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  return (
    <Modal
      open={takePictureModal}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CapturePhotoViaWebcam
        onCloseModal={onCloseModal}
        onFileSave={handleSave}
        loadingSpinner={loadingSpinner}
      />
    </Modal>
  );
  // Customizable Area End
};
// Customizable Area Start
export default TakeProfilePictureModal;
// Customizable Area End
