// Customizable Area Start
import 'react-quill/dist/quill.snow.css'
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "./EditorStyle.css"
// Customizable Area End

interface props{
// Customizable Area Start
handleText:any
editorText:any
isEvent?:boolean
// Customizable Area End
}

// Customizable Area Start
const toolbarOptions = ["bold","italic"];
// Customizable Area End

const  Editor:React.FC<props>=({
  handleText,
  editorText,
  isEvent
})=>  {
  // Customizable Area Start
  const [textVal,setText]=useState<string>(editorText)
  // Customizable Area End

  // Customizable Area Start
   const quillToolbar = {
    toolbar: ["bold", "italic"]
  };
  
  const handleChange = (t:any) => {
    const t1 = t.replace('</p>','')
    const text = t1.replace('<p>','')
    // text = text !== "<p><br></p>" ? text : "";
   setText(text)
   handleText(text)
 };
    // Customizable Area End

  return (
      <div className="bul" >  
    <ReactQuill
      id="root"
      theme="snow"
      modules={quillToolbar}
      bounds={'#root'}
      placeholder={ isEvent ? "Write description here..." : "Write thought of the day…" }
      defaultValue={textVal}
      onChange={handleChange}
    />  
    </div>
  );
}
export default Editor;
