// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Table, TableContainer, TableHead, TableCell, TableRow, TableBody, } from "@material-ui/core";
import TaskEvaluationController, {
    Props,
} from "./TaskEvaluationController.web";
import ReactPaginate from "react-paginate";
import { withStyles } from '@material-ui/core/styles';
import {
    Search,
} from "@material-ui/icons";
import moment from "moment";
import Datetime from "react-datetime";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import './TaskEvaluation.web.css';
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class TaskEvaluation extends TaskEvaluationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { taskEvaluationData, school_class_id, isLoading, subject_id, totalPages, currentPage } = this.state;
        return (
            <>
                <Box className={`teacher_assessment_box`}>
                    {isLoading && (
                        <Spinner spinnerModal={isLoading} />
                    )}
                    <Box
                        className="TaskEvaluationMainDiv"
                    >
                        <Grid container className="TaskEvaluationGridContainerDiv">
                            <Grid item xs={12} md={10} lg={10}>
                                <Grid container spacing={2} className="TaskEvaluationGridContainerDiv">
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Box
                                            className={`Searchmob TaskEvaluationSearchDiv ${this.props.classes.border_Color}`}
                                        >
                                            <input
                                                placeholder="Search..."
                                                className={`TaskEvaluationSearchInput ${this.props.classes.bodyText_font}`}
                                                value={this.state.searchText}
                                                onChange={((event: React.ChangeEvent<HTMLInputElement>) => {
                                                    this.setState({ searchText: event.target.value })
                                                    this.get_student_task_submission(this.state.start_date, this.state.end_date, school_class_id, subject_id, event.target.value, currentPage)
                                                })}
                                            />
                                            <Search
                                                className={`TaskEvaluationSearchIcon ${this.props.classes.icon_color}`}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={7} lg={7}>
                                        <Grid container spacing={1} className='TaskEvaluationAlignIems'>
                                            <Grid item xs={12} md={5} lg={5}>
                                                <Datetime
                                                    dateFormat={"DD MMMM, YYYY"}
                                                    timeFormat={false}
                                                    strictParsing={true}
                                                    input={true}
                                                    value={moment(this.state.start_date, "DD-MM-YYYY").format(
                                                        "DD MMMM, YYYY"
                                                    )}
                                                    initialValue={moment().format("DD MMMM, YYYY")}
                                                    className={"date-input"}
                                                    inputProps={{
                                                        placeholder: "Date of Birth",
                                                        className: "Date_of_birth_calendar_Icon_register",
                                                        readOnly: true,
                                                    }}
                                                    closeOnSelect
                                                    onChange={(date) => {
                                                        this.setState(
                                                            {
                                                                start_date: moment(date).format('DD/MM/YYYY'),
                                                            },
                                                            () => {
                                                                this.get_student_task_submission(moment(date).format('DD/MM/YYYY'), this.state.end_date, school_class_id, subject_id, this.state.searchText, currentPage)
                                                            }
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Typography className="TaskEvaluationToTxt">
                                                    To
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5}>
                                                <Datetime
                                                    dateFormat={"DD MMMM, YYYY"}
                                                    timeFormat={false}
                                                    strictParsing={true}
                                                    input={true}
                                                    value={moment(this.state.end_date, "DD-MM-YYYY").format(
                                                        "DD MMMM, YYYY"
                                                    )}
                                                    initialValue={moment().format("DD MMMM, YYYY")}
                                                    className={"date-input"}
                                                    inputProps={{
                                                        placeholder: "Date of Birth",
                                                        className: "Date_of_birth_calendar_Icon_register",
                                                        readOnly: true,
                                                    }}
                                                    closeOnSelect
                                                    onChange={(date: any) => {
                                                        this.setState(
                                                            {
                                                                end_date: moment(date).format('DD/MM/YYYY'),
                                                            },
                                                            () => {
                                                                this.get_student_task_submission(this.state.start_date, moment(date).format('DD/MM/YYYY'), school_class_id, subject_id, this.state.searchText, currentPage)
                                                            }
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <div className="view_task_verticalbox TaskEvaluationTableMainDiv">
                        <TableContainer className="TaskEvaluationTableContainer">
                            <Table >
                                <TableHead>
                                    <TableRow className="TaskEvaluationTableRow">
                                        <TableCell className={`TaskEvaluationMainTableCell TaskEvaluationW25 ${this.props.classes.subHeading_font}`} >Students</TableCell>
                                        <TableCell className={`TaskEvaluationMainTableCell TaskEvaluationW15 ${this.props.classes.subHeading_font}`} >Status</TableCell>
                                        <TableCell className={`TaskEvaluationMainTableCell TaskEvaluationW10 ${this.props.classes.subHeading_font}`} >Grade</TableCell>
                                        <TableCell className={`TaskEvaluationMainTableCell TaskEvaluationW30 paragrapWrapClass ${this.props.classes.subHeading_font}`}>Student Comments</TableCell>
                                        <TableCell className={`TaskEvaluationMainTableCell TaskEvaluation20 TaskEvaluationTextAlign ${this.props.classes.subHeading_font}`}>

                                            File Submitted
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="TaskEvaluationMainTableBody" >
                                    {
                                        taskEvaluationData?.length === 0 || taskEvaluationData === undefined
                                            ?
                                            <TableRow>
                                                <TableCell
                                                    colSpan={7}
                                                    className='TaskEvaluationNoRecordFoundTxt'
                                                >
                                                    No record(s) found.
                                                </TableCell>
                                            </TableRow>
                                            :
                                            <>
                                                {taskEvaluationData?.map((taskData: any) => (
                                                    <>
                                                        <TableRow className="TaskEvaluationTableRowDiv">
                                                            <TableCell className={`TaskEvaluationMainTableCell TaskEvaluationW25 ${this.props.classes.bodyText_font}`}>{taskData.attributes.member.attributes.first_name} {taskData.attributes.member.attributes.last_name}</TableCell>
                                                            <TableCell className={`TaskEvaluationMainTableCell TaskEvaluationW15 ${this.props.classes.bodyText_font}`} style={{
                                                                color: taskData.attributes.status === 'submitted' ? '#3dbb3d' : '#f26767',
                                                            }}>{taskData.attributes.status}</TableCell>
                                                            <TableCell className={`TaskEvaluationMainTableCell TaskEvaluationW10 ${this.props.classes.bodyText_font}`} style={{ color: taskData.attributes.status === 'submitted' ? '#3dbb3d' : '#f26767' }}>{taskData.attributes.score}</TableCell>
                                                            <TableCell className={`TaskEvaluationMainTableCell TaskEvaluationW30 ${this.props.classes.bodyText_font}`} >{taskData.attributes.comment}</TableCell>
                                                            <TableCell className={`TaskEvaluationMainTableCell TaskEvaluationW20 TaskEvaluationViewAllTxt  ${this.props.classes.bodyText_font}`} style={{ opacity: taskData.attributes.status === 'submitted' ? 1 : 0.5 }}
                                                                onClick={() => {
                                                                    if (taskData.attributes.status === 'submitted') {
                                                                        this.goToTaskMarkSubmission(taskData)
                                                                    }
                                                                }}>
                                                                View all
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </>
                                    }
                                </TableBody>
                            </Table>
                            {/* Table END */}
                        </TableContainer>
                    </div>
                    <Box> {
                        taskEvaluationData?.length === 0 || taskEvaluationData === undefined
                            ? null :
                            <div className="TaskEvaluationPaginationDiv choose_from_gallery">
                                <ReactPaginate
                                    previousLabel={<>←   <span className="xs_display">Previous</span></>}
                                    nextLabel={<><span className="xs_display">Next</span>   →</>}
                                    breakLabel="..."
                                    initialPage={currentPage}
                                    pageCount={totalPages}
                                    onPageChange={(e: any) => {
                                        if (e.selected !== currentPage) {
                                            this.setState({ currentPage: e.selected }, () => this.get_student_task_submission(this.state.start_date, this.state.end_date, school_class_id, subject_id, this.state.searchText, currentPage))
                                        }
                                    }}
                                    containerClassName={"paginationBttns"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                />
                            </div>
                    }
                    </Box>
                </Box>
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(TaskEvaluation);
// Customizable Area End