// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef, RefObject } from "react";
import {
  locationPin,
  birthCertification,
  camera,
  aadhaar,
  certificate2,
  pngIcon,
  jpgIcon,
  pdfIcon,
  docIcon,
} from "./assets";
import {
  checkForRequiredField,
  validAadhar,
  validEmail,
  computeChecksumMd5,
  generateKey,
} from "./utils/common";
import { toast } from "react-toastify";
import moment from "moment";
import { videoFileType, validFileType, imageFileType } from "./utils/constants";
import axios from "axios";
import convert from "xml-js";
import { HISTORY } from "../../../components/src/comman";

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  classes?: any;
  // Customizable Area End
}

// Customizable Area Start
export const screenName = {
  DocumentDetails: "DocumentDetails",
  UploadMandatoryDoc: "UploadMandatoryDoc",
  UploadingMandatoryDoc: "UploadingMandatoryDoc",
  UploadedMandatoryDoc: "UploadedMandatoryDoc",
  AdditionalDoc: "AdditionalDoc",
};
// Customizable Area End

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  hovered: boolean;
  tabValue: number;
  dateValue: Date;
  studentPersonalDetails: any;
  organisationList: any;
  occupation: any;
  classes: any;
  educationalDetails: any;
  documentDetails: any;
  notificationDetails: any;
  updatedNotification: any;
  badgesDetails: any;
  recentActivities: any;
  studentsDocuments: any;
  data: any;
  educationalDetailsError: any;
  studentPersonalDetailsError: any;
  studentSubjects: string;
  isFileUplaod: boolean;
  fileUploaded: any;
  viewDocument: any;
  anchorEl: any;
  openBadgeModal: boolean;
  deleteModal: boolean;
  createVideo: any;
  uploadedVideo: any;
  openCreateVideoModal: boolean;
  bulkFileUploaded: any;
  alertMessage: string;
  alertModal: boolean;
  openFileUploadSuccessModal: boolean;
  showDescriptionField: boolean;
  uploadPercentage: number | null;
  showLoader: boolean;
  additionalDocuments: any;
  uploadVideoPercentage: number | null;
  previewPhoto: boolean;
  previewItem: any;
  fileDescription: string;
  openPreviewVideoModal: boolean;
  videoAnchorEl: any;
  deleteVideoModal: boolean;
  disableUploadButton: boolean;
  displayScreen: string;
  uploadSuccess: boolean;
  descriptionError: boolean;
  descriptionErrorText: string;
  videoFile: any;
  loadingSpinner: boolean;
  introVideoFile: any;
  addPhotoAnchorEl: any;
  deletePhotoModal: boolean;
  takePictureModal: boolean;
  profilePhotoFile: any;
  showSpinner: boolean;
  notificationDynamicDetails: any;
  isLockProfile: boolean;
  videoValidations: number;
  checkValidDate: boolean;
  profilePhotoSignedUrl: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudentProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  studentPersonalDetailsCopy: any;
  educationalDetailsCopy: any;
  apiGetStudentPersonalInfoDetails: string = "";
  apiPutStudentPersonalInfoDetails: string = "";
  apiGetOrganinsationUnitList: string = "";
  apiGetOccupationList: string = "";
  apiGetClassesList: string = "";
  apiGetEducationalDetails: string = "";
  apiPutStudentEducationalInfoDetails: string = "";
  apiGetDocumentDetails: string = "";
  apiGetNotificationDetails: string = "";
  apiGetBadgesDetails: string = "";
  apiGetRecentActivitiesDetails: string = "";
  apiPutStudentNotificationDetails: string = "";
  apigetStudentSubjectsId: string = "";
  apiPostStudentUplaodDoc: string = "";
  apiDeleteStudentDocumentsDetails: string = "";
  apiPreSignedID: string = ""; // any = [];
  apiVideoPreSignedID: any = [];
  apiVideoBlockUploadId: string = "";
  apiBulkDocumentUploadId: any = [];
  apiBulkUploadBlockId: any = [];
  apiMandatoryDocumentUpload: string = "";
  apiDeleteVideo: string = "";
  apiPhotoPreSignedID: string = "";
  apiUploadBlockId: string = "";
  apiDeleteProfilePicture: string = "";
  apiUploadFile: string = "";
  apiGetVideoValidations: string = "";

  clientSidePercentage = 0; // 25% weightage

  defaultVideoValidations = 10;

  readonly inputOpenFileRef: RefObject<HTMLInputElement>;
  readonly inputOpenVideoFileRef: RefObject<HTMLInputElement>;
  readonly inputOpenPhotoRef: RefObject<HTMLInputElement>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    const studentsDocumentsFormat = [
      {
        id: "",
        name: "Residential Address Proof",
        imgSource: locationPin,
        fileName: "", // Residential Address Proof.jpeg",
        isReviewed: false,
        reviewedDoc: "",
        imgHeight: 125,
        imgWidth: 104,
        doc_attachment: "",
        isUploaded: false,
      },
      {
        id: "",
        name: "Birth Certificate",
        imgSource: birthCertification,
        fileName: "", // Birth Certificate.pdf",
        isReviewed: false,
        reviewedDoc: "",
        imgHeight: 125,
        imgWidth: 164,
        doc_attachment: "",
        isUploaded: false,
      },
      {
        id: "",
        name: "Latest Passport Size Photo",
        imgSource: camera,
        fileName: "", // Latest Passport size photo signed by Parents.jpeg",
        isReviewed: false,
        reviewedDoc: "",
        imgHeight: 125,
        imgWidth: 164,
        doc_attachment: "",
        isUploaded: false,
      },
      {
        id: "",
        name: "Aadhar Card",
        imgSource: aadhaar,
        fileName: "", // Aadhar Card.jpeg",
        isReviewed: false,
        reviewedDoc: "",
        imgHeight: 124,
        imgWidth: 136,
        doc_attachment: "",
        isUploaded: false,
      },
      {
        id: "",
        name: "Domicile Certificate",
        imgSource: certificate2,
        fileName: "", // Domicile Certificate.jpeg",
        isReviewed: false,
        reviewedDoc: "",
        imgHeight: 133,
        imgWidth: 164,
        doc_attachment: "",
        isUploaded: false,
      },
    ];
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.inputOpenFileRef = createRef();
    this.inputOpenVideoFileRef = createRef();
    this.inputOpenPhotoRef = createRef();
    this.state = {
      profilePhotoSignedUrl: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      tabValue: 0,
      hovered: false,
      dateValue: new Date(),
      studentPersonalDetails: {},
      organisationList: [],
      occupation: [],
      classes: [],
      educationalDetails: {},
      documentDetails: {},
      notificationDetails: {},
      badgesDetails: [],
      recentActivities: [],
      updatedNotification: { data: [] },
      studentsDocuments: studentsDocumentsFormat,
      data: {},
      educationalDetailsError: {
        previous_school_name: false,
        previous_board: false,
        marks_obtained: false,
        previous_class: false,
        percentage: false,
        previous_representative: false,
        messages: {},
      },
      studentPersonalDetailsError: {
        name: false,
        date_of_birth: false,
        gender: false,
        email: false,
        father_name: false,
        mother_name: false,
        father_occupation: false,
        mother_occupation: false,
        offical_address: false,
        home_phone_no: false,
        spoken_language: false,
        written_language: false,
        residential_address: false,
        nationality: false,
        religion: false,
        category: false,
        messages: {
          email: "",
        },
      },
      studentSubjects: "",
      isFileUplaod: false,
      fileUploaded: {},
      viewDocument: {},
      anchorEl: null,
      openBadgeModal: false,
      deleteModal: false,
      createVideo: {},
      uploadedVideo: {},
      openCreateVideoModal: false,
      bulkFileUploaded: [],
      alertMessage: "",
      alertModal: false,
      openFileUploadSuccessModal: false,
      showDescriptionField: false,
      uploadPercentage: null,
      showLoader: false,
      additionalDocuments: [],
      uploadVideoPercentage: null,
      previewPhoto: false,
      previewItem: {},
      fileDescription: "",
      openPreviewVideoModal: false,
      videoAnchorEl: null,
      deleteVideoModal: false,
      disableUploadButton: false,
      displayScreen: screenName.DocumentDetails,
      uploadSuccess: false,
      descriptionError: false,
      descriptionErrorText: "",
      videoFile: null,
      loadingSpinner: false,
      introVideoFile: null,
      addPhotoAnchorEl: null,
      deletePhotoModal: false,
      takePictureModal: false,
      profilePhotoFile: null,
      showSpinner: false,
      notificationDynamicDetails: [],
      isLockProfile: true,
      videoValidations: this.defaultVideoValidations,
      checkValidDate: false,
    };
    this.userdata = this.userdata.bind(this);
    this.getOrganisationUnit = this.getOrganisationUnit.bind(this);
    this.getOccupation = this.getOccupation.bind(this);
    this.getClassesList = this.getClassesList.bind(this);
    this.getStudentPersonalInfoDetails = this.getStudentPersonalInfoDetails.bind(
      this
    );
    this.getEducationalDetails = this.getEducationalDetails.bind(this);
    this.getDocumentDetails = this.getDocumentDetails.bind(this);
    this.getNotificationDetails = this.getNotificationDetails.bind(this);
    this.getBadgesDetails = this.getBadgesDetails.bind(this);
    this.getRecentActivities = this.getRecentActivities.bind(this);
    this.getStudentSubjects = this.getStudentSubjects.bind(this);
    this.getVideoValidations = this.getVideoValidations.bind(this);
    this.handleModalClick = this.handleModalClick.bind(this);
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start

  handleSave = (file: File) => {
  };

  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });

    const id = localStorage.getItem("id");
  };

  logoutHandleClick = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
    localStorage.removeItem("token");
    localStorage.removeItem("user_data");
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getOrganisationUnit();
    this.getOccupation();
    this.getClassesList();
    this.getStudentPersonalInfoDetails();
    this.getEducationalDetails();
    this.getDocumentDetails();
    this.getNotificationDetails();
    this.getBadgesDetails();
    this.getRecentActivities();
    this.getStudentSubjects();
    this.getVideoValidations();
    this.userdata();
  }

  handleEnter = (action: string) => (event: any) => {
    const { educationalDetails } = this.state;
    if (event.keyCode === 13) {
      switch (action) {
        case "savePersonalInfo":
          if (
            JSON.stringify(this.studentPersonalDetailsCopy) !== JSON.stringify(this.state.studentPersonalDetails?.attributes)) {
            this.savePersonalInfo(event);
          }
          break;
        case "saveEducationalInfo":
          if (JSON.stringify(this.educationalDetailsCopy) !== JSON.stringify(educationalDetails)) {
            this.saveEducationalInfo(event);
          }
          break;
      }
      return false;
    }
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getOrganisationUnit = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetOrganinsationUnitList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentOrganisationUnit
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOccupation = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetOccupationList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.occupation
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getClassesList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetClassesList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.classes
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStudentPersonalInfoDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetStudentPersonalInfoDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentProfilePersonalInfo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getEducationalDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetEducationalDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentEducationalInfo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDocumentDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDocumentDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentDocumentInfo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNotificationDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetNotificationDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentNotificationInfo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBadgesDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetBadgesDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentBadgesInfo + `?badge_type=assign_to`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRecentActivities = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetRecentActivitiesDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentRecentActivities
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStudentSubjects = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentSubjectsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentSubjectGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      switch (apiRequestCallId) {
        case this.apiGetStudentPersonalInfoDetails:
          {
            if (responseJson != null) {
              //  let obj: any = {};
              if (!responseJson.errors) {
                // if (responseJson.data?.attributes?.intro_video)
                //   responseJson.data.attributes.intro_video = "";
                this.setState({
                  studentPersonalDetails: responseJson.data,
                  introVideoFile:
                    responseJson.data?.attributes?.intro_video?.data,
                });
                this.studentPersonalDetailsCopy = responseJson?.data?.attributes;
                // , studentPersonalDetailsError: obj
                this.apiGetStudentPersonalInfoDetails = "";
              } else {
                this.parseApiErrorResponse(responseJson);
              }
              this.apiGetStudentPersonalInfoDetails = "";
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiPutStudentPersonalInfoDetails:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                if (responseJson.data) {
                  toast.success(configJSON.ProfileModuleStatusMessage.PERSONAL_INFO_SUCCESS);
                  // if (responseJson.data?.attributes?.intro_video)
                  //   responseJson.data.attributes.intro_video = "";
                  const nameWords = responseJson?.data?.attributes?.name?.split(' ');
                  let lastName, firstName = ''
                  if (nameWords && nameWords.length > 1) {
                    lastName = nameWords[nameWords.length - 1];
                    nameWords.pop()
                    firstName = nameWords?.join(' ');
                  } else {
                    firstName = responseJson?.data?.attributes?.name
                  }
                  const userData = {
                    ...this.state.data,
                    first_name: firstName,
                    last_name: lastName
                  };
                  this.studentPersonalDetailsCopy = responseJson?.data?.attributes;
                  this.setState({
                    studentPersonalDetails: responseJson.data,
                    // tabValue: 1,
                    introVideoFile:
                      responseJson.data?.attributes?.intro_video?.data,
                    data: userData,
                  });
                  localStorage.setItem("user_data", JSON.stringify(userData));
                  const elName = document.getElementById("studentName");
                  if (elName) {
                    elName.innerText = responseJson?.data?.attributes?.name
                  }
                  const elAdmissionClass = document.getElementById("studentAdmissionClass");
                  if (elAdmissionClass) {
                    elAdmissionClass.innerText = responseJson?.data?.attributes?.admission_class ? `(${responseJson?.data?.attributes?.admission_class})` : ""
                  }
                } else {
                  toast.warning(responseJson?.message[0]);
                }
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiGetOrganinsationUnitList:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({ organisationList: responseJson.data });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.apiGetOrganinsationUnitList = "";
          }
          break;
        case this.apiGetOccupationList:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({ occupation: responseJson.data });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.apiGetOccupationList = "";
          }
          break;
        case this.apiGetClassesList:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({
                  classes: responseJson ? responseJson.data : [],
                });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.apiGetClassesList = "";
          }
          break;
        case this.apiGetEducationalDetails:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.educationalDetailsCopy = responseJson?.data?.attributes;
                this.setState({
                  educationalDetails: responseJson?.data?.attributes,
                });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.apiGetEducationalDetails = "";
          }
          break;
        case this.apiPutStudentEducationalInfoDetails:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                toast.success(
                  " The educational info has been saved successfully."
                );
                this.educationalDetailsCopy = responseJson?.data?.attributes;
                this.setState({
                  educationalDetails: responseJson.data.attributes,
                  // tabValue: 2,
                });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiGetDocumentDetails:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const { studentsDocuments } = this.state;
                const attachedDocName: any = [];
                studentsDocuments.map((item: any) => {
                  attachedDocName.push(item.name);
                  const doc = responseJson?.data?.find(
                    (ele: any) => ele.attributes.name === item.name
                  );
                  if (doc) {
                    (item.id = doc.id),
                      (item.doc_attachment = doc.attributes.doc_attachment);
                    item.isUploaded = true;
                    item.fileName = doc.attributes.filename;
                    item.isReviewed =
                      doc.attributes.document_status;
                    // NOTE: "Approved" will come if docuemnt is approved
                    item.reviewedDoc = doc.attributes.doc_attachment;
                    item.upload_type = doc.attributes.upload_type;
                  } else {
                    item.isUploaded = false;
                    item.fileName = "";
                  }
                });
                this.setState({
                  documentDetails: responseJson.data,
                  studentsDocuments: studentsDocuments,
                });
                const filteredArray = responseJson?.data?.filter(
                  (item: any) => {
                    return !attachedDocName.includes(item.attributes.name);
                  }
                );
                this.setState({ additionalDocuments: filteredArray });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiGetNotificationDetails:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                let notifDetails: any = {};
                let notifDynamicDetails: any = [];
                responseJson.data.map((item: any) => {
                  notifDetails = {
                    ...notifDetails,
                    [item.attributes.name]: item.attributes,
                  };
                  notifDynamicDetails = [
                    ...notifDynamicDetails,
                    item.attributes,
                  ];
                });
                this.setState({
                  notificationDetails: notifDetails,
                  notificationDynamicDetails: notifDynamicDetails,
                });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiGetBadgesDetails:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({ badgesDetails: responseJson.data });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiGetRecentActivitiesDetails:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({ recentActivities: responseJson.data });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiPutStudentNotificationDetails:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                // this.setState({ notificationDetails: responseJson.data });
                toast.success(
                  " Notification details have been updated successfully."
                );
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apigetStudentSubjectsId:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                let { studentSubjects } = this.state;
                responseJson?.data?.map((student: any, index: number) => {
                  studentSubjects =
                    studentSubjects +
                    (index !== 0 ? " | " : "") +
                    student?.subject_name;
                });
                this.setState({ studentSubjects: studentSubjects });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiPostStudentUplaodDoc:
          {
            if (responseJson != null) {
              if (!responseJson.error) {
                this.getDocumentDetails();
                // this.setState({ isFileUplaod: false, fileUploaded: {} })
                this.setState({
                  displayScreen: screenName.DocumentDetails,
                  isFileUplaod: false,
                  fileUploaded: {},
                  bulkFileUploaded: [],
                  // bulkFilesName: "",
                  fileDescription: "",
                });
                this.apiBulkDocumentUploadId = [];
                toast.success("File Uploaded Successfully.");
              } else {
                this.parseApiErrorResponse(responseJson);
                toast.error(responseJson?.error)
              }
              this.setState({ disableUploadButton: false })
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.setState({ showSpinner: false });
          }
          break;
        case this.apiDeleteStudentDocumentsDetails:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({
                  anchorEl: null,
                  // isFileUplaod: false,
                  displayScreen: screenName.DocumentDetails,
                  fileUploaded: {},
                  viewDocument: {},
                  deleteModal: false,
                });
                this.getDocumentDetails();
                toast.success("File Deleted Successfully.");
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiPreSignedID: // .indexOf(apiRequestCallId) === -1
          // ? ""
          // : apiRequestCallId:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                //const { bulkFileUploaded } = this.state;
                const imageData: any = this.state.bulkFileUploaded[0];
                const updatedBulkDocument = [...this.state.bulkFileUploaded];
                const msg: Message = new Message(
                  getName(MessageEnum.UploadMediaMessage)
                );
                updatedBulkDocument[0].messageId = msg.messageId;
                updatedBulkDocument[0].progress = 50;
                this.setState({ bulkFileUploaded: updatedBulkDocument, uploadPercentage: 50 })
                const uploadFileData: any = {
                  responseJson: responseJson,
                  imageData: imageData.file,
                  messageId: msg.messageId
                };
                msg.addData(
                  getName(MessageEnum.UploadMediaDataMessage),
                  uploadFileData
                );
                this.apiMandatoryDocumentUpload = msg.messageId;
                runEngine.sendMessage(msg.id, msg)

              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }

            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiVideoPreSignedID.indexOf(apiRequestCallId) === -1
          ? ""
          : apiRequestCallId:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const file = this.state.videoFile
                const msg: Message = new Message(
                  getName(MessageEnum.UploadMediaMessage)
                );
                const uploadFileData: any = {
                  responseJson: responseJson,
                  imageData: file,
                  messageId: msg.messageId
                };
                msg.addData(
                  getName(MessageEnum.UploadMediaDataMessage),
                  uploadFileData
                );
                this.apiVideoBlockUploadId = msg.messageId;
                runEngine.sendMessage(msg.id, msg)

              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiVideoBlockUploadId:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const keyValue = JSON.parse(
                  convert.xml2json(responseJson, {
                    spaces: 1,
                    compact: true,
                    ignoreComment: true,
                    alwaysChildren: true,
                    ignoreDeclaration: true,
                  })
                );
                this.updatePersonalInfo(
                  "intro_video",
                  keyValue.PostResponse.Key._text
                );
                this.setState({
                  loadingSpinner: false,
                  createVideo: {},
                  openCreateVideoModal: false,
                  openFileUploadSuccessModal: true,
                });
              } else {
                this.parseApiErrorResponse(responseJson);
                this.updatePersonalInfo("intro_video", null);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiBulkDocumentUploadId.indexOf(apiRequestCallId) === -1
          ? ""
          : apiRequestCallId:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const indexOfUploadDoc = this.apiBulkDocumentUploadId.indexOf(apiRequestCallId)
                const imageData: any = this.state.bulkFileUploaded[indexOfUploadDoc];
                const updatedBulkDocument = [...this.state.bulkFileUploaded];
                const msg: Message = new Message(
                  getName(MessageEnum.UploadMediaMessage)
                );
                updatedBulkDocument[indexOfUploadDoc].messageId = msg.messageId;
                updatedBulkDocument[indexOfUploadDoc].progress = 50;
                this.setState({ bulkFileUploaded: updatedBulkDocument })
                const uploadFileData: any = {
                  responseJson: responseJson,
                  imageData: imageData.file,
                  messageId: msg.messageId
                };
                msg.addData(
                  getName(MessageEnum.UploadMediaDataMessage),
                  uploadFileData
                );

                this.apiBulkUploadBlockId[this.apiBulkUploadBlockId.length] = msg.messageId;

                runEngine.sendMessage(msg.id, msg)
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiDeleteVideo:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.updatePersonalInfo("intro_video", null);
                this.setState({ introVideoFile: null });
                this.studentPersonalDetailsCopy.intro_video = undefined;
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiPhotoPreSignedID:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const imageData = this.state.profilePhotoFile

                const msg: Message = new Message(
                  getName(MessageEnum.UploadMediaMessage)
                );
                const uploadFileData: any = {
                  responseJson: responseJson,
                  imageData,
                  messageId: msg.messageId
                };
                msg.addData(
                  getName(MessageEnum.UploadMediaDataMessage),
                  uploadFileData
                );
                this.apiUploadBlockId = msg.messageId;

                runEngine.sendMessage(msg.id, msg)
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);

            // if (responseJson != null) {
            //   if (!responseJson.errors) {
            //     this.savePhotoUsingURL(responseJson);
            //   } else {
            //     this.parseApiErrorResponse(responseJson);
            //   }
            // }
            // this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiDeleteProfilePicture:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const userData = {
                  ...this.state.data,
                  avatar_id: null,
                  avatar: null,
                };
                localStorage.setItem("user_data", JSON.stringify(userData));
                this.userdata();
                HISTORY.push("/StudentProfile");
                this.setState({ showSpinner: false });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiUploadFile:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                if (responseJson?.data?.[0]?.attributes.url) {
                  const userData = {
                    ...this.state.data,
                    avatar_id: responseJson?.data?.[0]?.id,
                    avatar: responseJson?.data?.[0]?.attributes.url,
                  };
                  localStorage.setItem("user_data", JSON.stringify(userData));
                  this.userdata();
                  HISTORY.push("/StudentProfile");
                  this.setState({ showSpinner: false, takePictureModal: false });
                  this.handleAddPhotoModalClose()
                } else {
                  this.saveProfilePicture(this.state.profilePhotoSignedUrl)
                }
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiGetVideoValidations:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const validation = responseJson?.data?.filter((el: any) => el.user_type === "Student")[0]?.max_duration;

                this.setState({
                  videoValidations: validation ? validation : this.defaultVideoValidations,
                });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.apiGetClassesList = "";
          }
          break;
        case this.apiUploadBlockId:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const keyValue = JSON.parse(
                  convert.xml2json(responseJson, {
                    spaces: 1,
                    compact: true,
                    ignoreComment: true,
                    alwaysChildren: true,
                    ignoreDeclaration: true,
                  })
                );
                //this.saveProfilePicture(keyValue.PostResponse.Key._text);
                this.setState({ profilePhotoSignedUrl: keyValue.PostResponse.Key._text }, () => { this.saveProfilePicture(keyValue.PostResponse.Key._text) })
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiBulkUploadBlockId.indexOf(apiRequestCallId) === -1
          ? ""
          : apiRequestCallId:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const keyValue = JSON.parse(
                  convert.xml2json(responseJson, {
                    spaces: 1,
                    compact: true,
                    ignoreComment: true,
                    alwaysChildren: true,
                    ignoreDeclaration: true,
                  })
                );

                const { bulkFileUploaded } = this.state;
                const updatedDocIndex = this.state.bulkFileUploaded.findIndex((item: any) => item.messageId === apiRequestCallId)
                const updated = {
                  ...bulkFileUploaded[updatedDocIndex],
                  signedInId: keyValue.PostResponse.Key._text,
                  progress: 100,
                };
                bulkFileUploaded[updatedDocIndex] = updated;
                this.setState({
                  bulkFileUploaded: [...bulkFileUploaded],
                });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiMandatoryDocumentUpload:
          {
            if (responseJson != null) {
              const { bulkFileUploaded } = this.state;
              if (!responseJson.errors) {
                const keyValue = JSON.parse(
                  convert.xml2json(responseJson, {
                    spaces: 1,
                    compact: true,
                    ignoreComment: true,
                    alwaysChildren: true,
                    ignoreDeclaration: true,
                  })
                );
                bulkFileUploaded?.map((item: any, index: number) => {
                  item.signedInId = keyValue.PostResponse.Key._text;
                });
                this.clientSidePercentage = 100;
                this.setState({ bulkFileUploaded: bulkFileUploaded, uploadSuccess: true, uploadPercentage: 100 }, () => {
                  if (
                    this.state.uploadPercentage === 100 &&
                    this.state.displayScreen === "UploadingMandatoryDoc"
                  ) {
                    this.setState({
                      displayScreen: screenName.UploadedMandatoryDoc,
                      uploadSuccess: false,
                    });
                  }
                  if (this.state.uploadPercentage === 100) {
                    this.setState({ uploadSuccess: false });
                  }
                });
              } else {
                bulkFileUploaded[0].signedInId = "";
                bulkFileUploaded[0].url = "";
                bulkFileUploaded[0].checksum = "";
                bulkFileUploaded[0].file = {};
                this.resetLoader();
                this.setState({ bulkFileUploaded: bulkFileUploaded });
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
      }
    }
    // Customizable Area End
  }

  handleTansChange = {
    onChangeTab: (num: number) => {
      this.setState({ tabValue: num });
    },
  };
  handleDateChange = {
    onChangeDate: (newDate: Date) => {
      this.setState({ dateValue: newDate });
    },
  };
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  inputEvent = (prop: any) => async (event: any) => {
    if (prop === 'date_of_birth') {
      const currentDate = moment().format('YYYY-MM-DD').replaceAll("-", "")
      const dateOfBirth = event.target.value.replaceAll("-", "")

      if (currentDate < dateOfBirth) {
        await this.setState({
          studentPersonalDetailsError: {
            ...this.state.studentPersonalDetailsError,
            date_of_birth: true,
            messages: {
              ...this.state.studentPersonalDetailsError.messages,
              date_of_birth: "date of birth should be past date",
            },

          }
        })
      } else {
        const newVal = event.target.value;
        this.updatePersonalInfo(prop, newVal);
      }
    } else {

      const newVal = await event.target.value;
      this.updatePersonalInfo(prop, newVal);
    }

  };

  updatePersonalInfo = (prop: string, newVal: any) => {
    let { studentPersonalDetailsError } = this.state;
    let newStudentPersonalDetails = { ...this.state.studentPersonalDetails };
    switch (prop) {
      case "notification_to_father":
        newVal = !newStudentPersonalDetails.attributes.notification_to_father;
        break;
      case "notification_to_mother":
        newVal = !newStudentPersonalDetails.attributes.notification_to_mother;
        break;
      case "intro_video":
        break;
      default:
        const isValid: any = this.personalInfoValidData(
          prop.replaceAll("_", " "),
          newVal
        );
        if (isValid) {
          studentPersonalDetailsError = {
            ...studentPersonalDetailsError,
            [prop]: isValid.error,
            messages: {
              ...studentPersonalDetailsError.messages,
              [prop]: isValid.errorText,
            },
          };
        }
        break;
    }

    newStudentPersonalDetails
      ? (newStudentPersonalDetails.attributes = {
        ...newStudentPersonalDetails?.attributes,
        [prop]: newVal,
      })
      : (newStudentPersonalDetails = { attributes: { [prop]: newVal } });
    this.setState({
      studentPersonalDetails: { ...newStudentPersonalDetails },
      studentPersonalDetailsError: studentPersonalDetailsError,
    });
  };

  handleEducationalInputChange = (prop: any) => (event: any) => {
    let newVal = event.target.value;  
    if (prop === 'marks_obtained' || prop === 'percentage') {
      newVal = parseInt(newVal)
    }
    const newEducationalDetails = {
      ...this.state.educationalDetails,
      [prop]: newVal,
    };
    this.setState({
      educationalDetails: { ...newEducationalDetails },
    });
  };

  isPersonalInfoValid = () => {
    let { studentPersonalDetails, studentPersonalDetailsError } = this.state;
    let isValid = true;
    if (
      studentPersonalDetails === undefined ||
      studentPersonalDetails?.attributes === undefined
    ) {
      studentPersonalDetailsError = {
        name: true,
        date_of_birth: true,
        gender: true,
        email: true,
        father_name: true,
        mother_name: true,
        father_occupation: true,
        mother_occupation: true,
        offical_address: true,
        home_phone_no: true,
        spoken_language: true,
        written_language: true,
        residential_address: true,
        nationality: true,
        religion: true,
        category: true,
        messages: {
          email: "Email is required.",
          name: "Name is required.",
          date_of_birth: "Date of birth is required.",
          father_name: "Father name is required.",
          mother_name: "Mother name is required.",
          offical_address: "Official address is required.",
          home_phone_no: "Home phone no is required.",
          residential_address: "Residential address is required.",
          category: "Category is required.",
        },
      };
      isValid = false;
      this.setState({
        studentPersonalDetailsError: studentPersonalDetailsError,
      });
    } else {
      for (const key in studentPersonalDetailsError) {
        if (key !== "messages") {
          const isValidData: any = this.personalInfoValidData(
            key,
            studentPersonalDetails?.attributes[key]
          );
          if (isValidData && isValidData.error) {
            studentPersonalDetailsError[key] = isValidData.error;
            studentPersonalDetailsError["messages"][key] =
              isValidData.errorText;
            isValid = false;
          } else studentPersonalDetailsError[key] = false;
        }
      }
      this.setState({
        studentPersonalDetailsError: studentPersonalDetailsError,
      });
    }
    return isValid;
  };

  personalInfoValidData = (prop: string, newVal: string) => {
    switch (prop) {
      case "email":
        return validEmail(newVal);
      case "aadhar card no":
      case "aadhar_card_no":
        return validAadhar(newVal);
      case "bpl certificate no":
        break;
      case "bpl certificate issuing authority":
        break;
      case "office_phone_no":
        break;
      default:
        return checkForRequiredField(newVal, prop.replaceAll("_", " "));
    }
  };

  savePersonalInfo = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (this.isPersonalInfoValid()) {
      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token: localStorage.getItem("token"),
      };

      const httpBody: any = {
        data: {
          attributes: {
            name: this.state.studentPersonalDetails.attributes.name?.trim(),
            date_of_birth: this.state.studentPersonalDetails.attributes
              .date_of_birth,
            gender: this.state.studentPersonalDetails.attributes.gender?.trim(),
            email: this.state.studentPersonalDetails.attributes.email?.trim(),
            admission_class: this.state.studentPersonalDetails.attributes
              .admission_class,
            father_name: this.state.studentPersonalDetails.attributes.father_name?.trim(),
            mother_name: this.state.studentPersonalDetails.attributes.mother_name?.trim(),
            father_occupation: this.state.studentPersonalDetails.attributes
              .father_occupation,
            mother_occupation: this.state.studentPersonalDetails.attributes
              .mother_occupation,
            offical_address: this.state.studentPersonalDetails.attributes.offical_address?.trim(),
            home_phone_no: this.state.studentPersonalDetails.attributes.home_phone_no?.trim(),
            spoken_language: this.state.studentPersonalDetails.attributes
              .spoken_language,
            written_language: this.state.studentPersonalDetails.attributes
              .written_language,
            office_phone_no: this.state.studentPersonalDetails.attributes.office_phone_no?.trim(),
            residential_address: this.state.studentPersonalDetails.attributes.residential_address?.trim(),
            nationality: this.state.studentPersonalDetails.attributes
              .nationality,
            religion: this.state.studentPersonalDetails.attributes.religion,
            category: this.state.studentPersonalDetails.attributes.category?.trim(),
            bpl_certificate_no: this.state.studentPersonalDetails.attributes.bpl_certificate_no?.trim(),
            aadhar_card_no: this.state.studentPersonalDetails.attributes.aadhar_card_no?.trim(),
            bpl_certificate_issuing_authority: this.state.studentPersonalDetails.attributes.bpl_certificate_issuing_authority?.trim(),
            notification_to_father: this.state.studentPersonalDetails
              ?.attributes?.notification_to_father
              ? this.state.studentPersonalDetails.attributes
                .notification_to_father
              : false,
            notification_to_mother: this.state.studentPersonalDetails
              ?.attributes?.notification_to_mother
              ? this.state.studentPersonalDetails.attributes
                .notification_to_mother
              : false,
            // TODO: Need to uncomment for recorded & uploaded video
          },
        },
      };
      if (
        this.state.studentPersonalDetails?.attributes?.intro_video ||
        this.state.studentPersonalDetails?.attributes?.intro_video !== ""
      )
        httpBody.data.attributes.intro_video =
          typeof this.state.studentPersonalDetails?.attributes?.intro_video ===
            "string"
            ? this.state.studentPersonalDetails?.attributes?.intro_video
            : null;

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.baseURL
      );

      this.apiPutStudentPersonalInfoDetails = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.studentProfilePersonalInfo
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateProfileApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  isEducationalInfoValid = () => {
    let { educationalDetails, educationalDetailsError } = this.state;
    let isValid = true;
    if (
      educationalDetails === undefined ||
      educationalDetails === null ||
      educationalDetails === {}
    ) {
      educationalDetailsError = {
        previous_school_name: true,
        previous_board: true,
        marks_obtained: true,
        previous_class: true,
        percentage: true,
        previous_representative: true,
        messages: {
          previous_school_name: "School name is required.",
          previous_board: "Board is required.",
          marks_obtained: "Marks obtained is required.",
          previous_class: "Class is required.",
          percentage: "Percentage is required.",
          previous_representative: "Representative is required.",
        },
      };
      isValid = false;
    } else {
      for (const key in educationalDetailsError) {
        if (key !== "messages") {
          const isValidData: any = checkForRequiredField(
            educationalDetails[key],
            key.replaceAll("_", " ")
          );
          if (isValidData && isValidData.error) {
            educationalDetailsError[key] = isValidData.error;
            educationalDetailsError["messages"][key] = isValidData.errorText;
            isValid = false;
          } else {
            educationalDetailsError[key] = false;
          }
        }
      }
    }
    this.setState({ educationalDetailsError: educationalDetailsError });
    return isValid;
  };

  saveEducationalInfo = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          previous_school_name: this.state.educationalDetails?.previous_school_name?.trim(),
          previous_board: this.state.educationalDetails?.previous_board?.trim(),
          marks_obtained: this.state.educationalDetails?.marks_obtained,
          previous_class: this.state.educationalDetails?.previous_class?.trim(),
          percentage: this.state.educationalDetails?.percentage,
          previous_representative: this.state.educationalDetails?.previous_representative?.trim(),
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiPutStudentEducationalInfoDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentEducationalInfo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateProfileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // }
  };

  saveNotification = (updatedNotification: any) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = updatedNotification;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiPutStudentNotificationDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentNotificationInfo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateProfileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNotificationInputChange = (
    notifiyName: string,
    propertyName: string
  ) => (event: any) => {
    const { notificationDetails, updatedNotification } = this.state;
    const newValue = !notificationDetails[notifiyName][propertyName];
    updatedNotification.data = [
      {
        id: notificationDetails[notifiyName].id,
        [propertyName]: newValue,
      },
    ];
    notificationDetails[notifiyName][propertyName] = newValue;
    this.setState({
      notificationDetails: notificationDetails,
      updatedNotification: updatedNotification,
    });
    this.saveNotification(updatedNotification);
  };

  handleUpload = (
    event: React.ChangeEvent<any>,
    allowMultipleFiles: boolean,
    filesFromDragAndDrop: any,
    reDirectScreen?: string
  ) => {
    let files: any;
    let allowLoop: boolean = true;
    if (filesFromDragAndDrop !== null) {
      files = filesFromDragAndDrop;
    } else {
      files = event.target.files;
    }
    const keys = Object.keys(files);

    keys?.map((key: any) => {
      const file = files[key];
      if (!validFileType.includes(file?.type)) {
        this.setState({
          alertMessage: "Only .png, .jpg, .jpeg or .pdf are allowed.",
          alertModal: true,
        });
        allowLoop = false;
      }
    });

    if (allowLoop) {
      let percent = 0;
      this.setState({
        showDescriptionField: allowMultipleFiles,
        uploadPercentage: percent,
        displayScreen: reDirectScreen
          ? reDirectScreen
          : this.state.displayScreen, // screenName.UploadingMandatoryDoc,
        showLoader: true,
        bulkFileUploaded: [],
        fileUploaded: {},
        fileDescription: "",
      });
      let { fileUploaded, bulkFileUploaded } = this.state;

      keys?.map(async (key: any) => {
        const file = files[key];

        if (allowMultipleFiles) {
          let fileUpload: any = {};
          fileUpload = {
            id: generateKey(file.name),
            fileName: file.name,
            name: file.name.split(".")[0],
            isExistingFile: false,
            fileSize: file.size,
            percentage: 0,
            file: file,
            content_type: file.type,
          };
          bulkFileUploaded = [...bulkFileUploaded, fileUpload];
          this.setState({ bulkFileUploaded: bulkFileUploaded });
          this.setState({ displayScreen: screenName.AdditionalDoc });
        } else {
          this.clientSidePercentage = 25;
          const extension = file.name.split(".")[file.name.split(".").length - 1];
          let fileUpload = {
            id: 0,
            reUploadDocId: bulkFileUploaded?.[0]?.id,
            file: file,
            fileName: bulkFileUploaded?.[0]?.isExistingFile
              ? bulkFileUploaded?.[0].name + "." + extension
              : file.name,
            name: bulkFileUploaded?.[0]?.name,
            isExistingFile: bulkFileUploaded?.[0]?.isExistingFile,
            fileSize: file.size,
            content_type: file.type,
          };

          bulkFileUploaded[0] = fileUpload;
          bulkFileUploaded = [...bulkFileUploaded];

          this.setState({
            bulkFileUploaded: bulkFileUploaded,
            fileUploaded: fileUpload,
            uploadPercentage: 25
          });
          this.getPreSignedId(fileUpload);
        }
      });
    }
  };

  calcTotalPercentage = () => {
    const totalPrc =
      this.clientSidePercentage;
    this.setState({ uploadPercentage: Math.round(totalPrc) });
    if (totalPrc === 100) {
      this.setState({
        displayScreen: screenName.UploadedMandatoryDoc,
        uploadSuccess: false,
      });
      this.resetLoader();
    }
  };

  deleteUploadedFile = () => {
    const { bulkFileUploaded } = this.state;
    this.setState({
      uploadPercentage: 0,
      displayScreen: screenName.UploadMandatoryDoc,
      uploadSuccess: false,
      fileDescription: "",
      fileUploaded: {
        name: bulkFileUploaded[0].name,
        isExistingFile: bulkFileUploaded[0].isExistingFile,
      },
      bulkFileUploaded: [
        {
          name: bulkFileUploaded[0].name,
          isExistingFile: bulkFileUploaded[0].isExistingFile,
        },
      ],
    });
  };

  showOpenFileDlg = () => {
    this.inputOpenFileRef?.current?.click();
  };

  saveUploadedFile = () => {
    const {
      bulkFileUploaded,
      fileDescription,
      showDescriptionField,
    } = this.state;
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");

    if (fileDescription.length === 0 && showDescriptionField) {
      this.setState({
        descriptionError: true,
        descriptionErrorText: "Please add description.",
      });
      return false;
    }

    if (bulkFileUploaded.length > 0 && bulkFileUploaded[0].signedInId) {
      if (!this.state.disableUploadButton) {
        this.setState({ disableUploadButton: true, showSpinner: true });
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          token: localStorage.getItem("token"),
          "school": data.school_id,
        };

        const bodyParams: any = [];
        bulkFileUploaded.map((file: any) => {
          const params = {
            attributes: {
              name: file.name,
              filename: file.fileName,
              description: fileDescription,
              doc_attachment: file.signedInId,
            },
          };
          bodyParams.push(params);
        });

        const httpBody = { data: bodyParams };

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestbaseURLMessage),
          configJSON.baseURL
        );

        this.apiPostStudentUplaodDoc = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.studentDocumentUpload
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    } else {
      toast.error("Please upload file");
    }
  };

  saveReuploadedFile = () => {
    const { bulkFileUploaded } = this.state;

    const fileUploaded = bulkFileUploaded[0];

    if (fileUploaded.signedInId) {
      if (!this.state.disableUploadButton) {
        this.setState({ disableUploadButton: true });
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          token: localStorage.getItem("token"),
          //@ts-ignore
          "school": JSON.parse(localStorage.getItem("user_data")).school_id,
        };

        const httpBody = {
          data: {
            attributes: {
              name: fileUploaded.name,
              filename: fileUploaded.fileName,
              doc_attachment: fileUploaded.signedInId,
            },
          },
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestbaseURLMessage),
          configJSON.baseURL
        );

        this.apiPostStudentUplaodDoc = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.studentReUploadDoc + "/" + fileUploaded.reUploadDocId
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    } else {
      toast.error("Please upload file");
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick = (event: any) => {
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  deleteUploadedDocument = () => {
    const { viewDocument } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiDeleteStudentDocumentsDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentDeleteDoc + "/" + viewDocument.id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleModalClick = () => {
    this.setState({ openBadgeModal: !this.state.openBadgeModal });
  };

  handleDeleteClose = () => {
    this.setState({ deleteModal: false });
  };

  handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      deleteModal: true,
    });
  };

  handleFileName = (event: any) => {
    const { fileUploaded } = this.state;
    const newVal = event.target.value;
    // TODO: Name shud also go, and be fixed like "Additional Document"
    this.setState({
      fileUploaded: { ...fileUploaded, fileName: newVal },
    });
    this.setState({
      bulkFileUploaded: [{ ...fileUploaded, fileName: newVal }],
    });
  };

  uploadVideo = async (uploadVideoUrl: any, videoBlob?: any) => { // callback: any,
    this.setState({ loadingSpinner: true, introVideoFile: uploadVideoUrl });

    let videoFile: any = null;
    if (this.state.createVideo?.videoData) {
      videoFile = this.state.videoFile;
    } else {
      let uploadVideo;
      if (videoBlob) {
        uploadVideo = videoBlob;
      } else {
        uploadVideo = new Blob([uploadVideoUrl], {
          type: "video/mp4",
        });
      }
      const file = this.convertBlobToFile(uploadVideo);
      this.setState({ videoFile: file });
      videoFile = file;
    }
    const videoDetails = {
      fileName: videoFile.name,
      fileSize: videoFile.size,
      content_type: videoFile.type,
    };
    this.getVideoPreSignedId(videoDetails);
  };

  convertBlobToFile(blob: any) {
    const fileName =
      this.state.studentPersonalDetails?.attributes?.name.replace(/\s+/g, "") +
      "_self_video.mp4";
    return new File([blob], fileName, {
      type: blob.type,
    });
  }

  showOpenVideoFileDlg = () => {
    this.inputOpenVideoFileRef?.current?.click();
  };

  handleVideoUpload = (
    event: React.ChangeEvent<any> | null,
    fileFromDragAndDrop: any
  ) => {
    let files: any;
    if (event !== null) {
      files = event?.target.files[0];
    } else {
      files = fileFromDragAndDrop;
    }

    if (!videoFileType.includes(files.type)) {
      this.setState({
        alertMessage: "Please Upload Valid Video",
        alertModal: true,
      });
    } else if (files.size > 524288000) {
      this.setState({
        alertMessage: "File size should not exceed 500MB",
        alertModal: true,
      });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.addEventListener("progress", async (event) => {
        if (event.loaded && event.total) {
          const newPercent = (event.loaded / event.total) * 100;
          this.setState({
            uploadVideoPercentage: Math.round(newPercent),
            openCreateVideoModal: false,
          });
          if (newPercent === 100) {
            this.setState({ videoFile: files });
            let blobURL = window.URL.createObjectURL(files);
            this.setState({
              createVideo: {
                isCreateUploadVideo: "Upload",
                videoData: blobURL,
              },
              openCreateVideoModal: false,
            });
            this.setState({ uploadVideoPercentage: null });
          }
        }
      });
    }
  };

  inputDigestEvents = (
    event: any,
    notification_type: string,
    notifiyName: string,
    propertyName: string
  ) => {
    let newVal = event.target.value;
    const { notificationDetails, updatedNotification } = this.state;
    let alreadyUpdated = false;
    updatedNotification?.data?.map((element: any) => {
      if (
        element.id === notificationDetails[notification_type][notifiyName]?.id
      ) {
        alreadyUpdated = true;
        element[propertyName] = newVal;
      }
      this.setState({ notificationDetails: notificationDetails });
    });
    if (!alreadyUpdated) {
      updatedNotification.data.push({
        id: notificationDetails[notification_type][notifiyName]?.id,
        [propertyName]: newVal,
      });
    }

    notificationDetails[notification_type][notifiyName][propertyName] = newVal;
    this.setState({
      notificationDetails: notificationDetails,
      updatedNotification: updatedNotification,
    });
  };
  cancelNotificationChanges = () => {
    this.getNotificationDetails();
  };
  getImageSource = (fileName: string) => {
    const extension = fileName?.split(".").pop();

    if (extension?.toLowerCase() === "png") {
      return pngIcon;
    } else if (
      extension?.toLowerCase() === "jpg" ||
      extension?.toLowerCase() === "jpeg"
    ) {
      return jpgIcon;
    } else if (extension?.toLowerCase() === "pdf") {
      return pdfIcon;
    } else return docIcon;
  };

  handleVideoModalClose = () => {
    this.setState({ videoAnchorEl: null });
  };

  handleVideoModalClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      videoAnchorEl: event.currentTarget,
    });
  };

  deleteUploadedVideo = () => {
    const { introVideoFile } = this.state;
    this.setState({ deleteVideoModal: false });
    if (introVideoFile?.id) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("token"),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.baseURL
      );

      this.apiDeleteVideo = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteVideo + "/" + this.state.introVideoFile?.id
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else if (this.studentPersonalDetailsCopy?.intro_video != undefined) {
      this.updatePersonalInfo("intro_video", this.studentPersonalDetailsCopy?.intro_video);
      this.setState({ introVideoFile: this.studentPersonalDetailsCopy?.intro_video?.data });
    } else {
      this.updatePersonalInfo("intro_video", null);
      this.setState({ introVideoFile: null });
    }
  };

  resetLoader = () => {
    this.clientSidePercentage = 0;
  };

  getPreSignedId = async (fileUploadDetail: any) => {
    const header = {
      token: localStorage.getItem("token"),
    };

    let formData = new FormData();
    formData.append('content_type', fileUploadDetail.content_type)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiPreSignedID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    const result = runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBulkPhotoPreSignedId = (file: any) => {
    const header = {
      token: localStorage.getItem("token"),
    };
    let formData = new FormData();
    formData.append('content_type', file.content_type)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiBulkDocumentUploadId[this.apiBulkDocumentUploadId.length] = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getVideoPreSignedId = (videoUploadDetail: any) => {
    const header = {
      token: localStorage.getItem("token"),
    };
    let formData = new FormData();
    formData.append('content_type', videoUploadDetail.content_type)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiVideoPreSignedID[this.apiVideoPreSignedID.length] =
      requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveVideoUsingURL = async (preSignedResponse: any) => {
    const uploadURL = preSignedResponse.url.replace(/\/+$/, "");
    const urlFields = preSignedResponse.url_fields;

    if (uploadURL != null && uploadURL !== undefined) {
      const fileKey = urlFields.key.replace("${filename}", this.state.videoFile.name);
      this.updatePersonalInfo(
        "intro_video",
        fileKey
      );
      const formData = new FormData();
      formData.append("key", fileKey);
      formData.append(
        "success_action_status",
        urlFields.success_action_status
      );
      formData.append("Content-Type", urlFields["Content-Type"]);
      formData.append(
        "Content-Disposition",
        urlFields["Content-Disposition"]
      );
      formData.append("policy", urlFields.policy);
      formData.append("x-amz-credential", urlFields["x-amz-credential"]);
      formData.append("x-amz-algorithm", urlFields["x-amz-algorithm"]);
      formData.append("x-amz-date", urlFields["x-amz-date"]);
      formData.append("x-amz-signature", urlFields["x-amz-signature"]);
      formData.append("file", this.state.videoFile);
      try {
        const s3Response = await axios({
          method: "post",
          url: uploadURL,
          data: formData,
        });
        if (s3Response.status === 201) {
          const keyValue = JSON.parse(
            convert.xml2json(s3Response.data, {
              spaces: 1,
              compact: true,
              ignoreComment: true,
              alwaysChildren: true,
              ignoreDeclaration: true,
            })
          );
          this.setState({
            loadingSpinner: false,
            createVideo: {},
            openCreateVideoModal: false,
            openFileUploadSuccessModal: true,
          });
        }
      } catch (e) {
        toast.error("Something went wrong.");
        this.updatePersonalInfo("intro_video", null);
      }
    }
  };

  handleAddPhotoModalClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      addPhotoAnchorEl: event.currentTarget,
    });
  };

  handleAddPhotoModalClose = () => {
    this.setState({ addPhotoAnchorEl: null });
  };

  deleteProfilePhoto = () => {
    this.setState({ deletePhotoModal: false, showSpinner: true });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiDeleteProfilePicture = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteVideo + "/" + this.state.data?.avatar_id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showOpenPhotoDlg = () => {
    this.inputOpenPhotoRef?.current?.click();
  };

  handlePhotoUpload = async (event: React.ChangeEvent<any>) => {
    this.setState({ addPhotoAnchorEl: null });
    const file = event.target.files[0];
    if (!imageFileType.includes(file?.type)) {
      this.setState({
        alertMessage: "Please upload valid file format: .png, .jpg, .jpeg",
        alertModal: true,
      });
      return;
    }

    this.setState({ profilePhotoFile: file, showSpinner: true });

    await computeChecksumMd5(file).then((md5: any) => {
      const fileDetails = {
        fileName: file.name,
        fileSize: file.size,
        checksum: md5,
        content_type: file.type,
      };
      this.getPhotoPreSignedId(fileDetails);
    });
  };

  handleWebcamPhotoUpload = async (file: File) => {
    this.setState({ addPhotoAnchorEl: null });
    this.setState({ profilePhotoFile: file });
    this.setState({ loadingSpinner: true });
    const fileDetails = {
      fileName: file.name,
      fileSize: file.size,
      content_type: file.type,
    };
    this.getPhotoPreSignedId(fileDetails);
  };

  getPhotoPreSignedId = (photoDetail: any) => {
    const header = {
      token: localStorage.getItem("token"),
    };

    let formData = new FormData();
    formData.append('content_type', photoDetail.content_type)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiPhotoPreSignedID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveProfilePicture = (signedId: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: [
        {
          attachment: signedId,
          upload_for: "Profile picture",
        },
      ],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );

    this.apiUploadFile = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadFile
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  bulkUploadDocumentHandleDelete = (itemId: string) => {
    const { bulkFileUploaded } = this.state;
    const index = bulkFileUploaded.findIndex((item: any) => item.id === itemId);
    bulkFileUploaded.splice(index, 1);
    this.apiBulkDocumentUploadId.splice(index, 1)
    this.setState({ bulkFileUploaded: bulkFileUploaded });
    if (bulkFileUploaded?.length === 0) {
      this.setState({
        isFileUplaod: false,
        fileUploaded: {},
        bulkFileUploaded: [],
        showLoader: false,
        uploadPercentage: 0,
      });
    }
  };

  getVideoValidations = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetVideoValidations = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.videoValidations
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
