// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    uploadClass: boolean;
    closeModalFun: any;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    data: any;
    closeModal: boolean;
    fileName: string;
    bulk_file: any;
    alertModal: boolean;
    alertMessage: string;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class UploadClassController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiaddStudentClassesId: string = "";
    child: React.RefObject<any>;
    // Customizable Area End

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.child = React.createRef();
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            data: '',
            closeModal: false,
            fileName: '',
            bulk_file: [],
            alertModal: false,
            alertMessage: '',
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.userdata = this.userdata.bind(this);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.closeModalFun(this.state.closeModal)
    }
    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apiaddStudentClassesId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        toast.success("The File has been uploaded successfully.")
                        this.props.closeModalFun(this.state.closeModal)
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }


        }
    }
    set_data = (file: any) => {
        let fileUpload: any = {};
        let checksum = "";
        let allowUpload: boolean = true;
        const validFileType = [
            "application/vnd.ms-excel",
            "text/csv",
            "text/x-csv",
            "application/csv",
            "application/x-csv"
        ];
        fileUpload = {
            fileName: file.name,
            name: file.name.split('.')[0],
            fileSize: file.size,
            file: file,
            content_type: file.type
        };
        if (!validFileType.includes(fileUpload.content_type)) {
            allowUpload = false;
            this.setState({
                alertMessage:
                    "Only csv file is allowed.",
                alertModal: true,
            });
        }
        if (allowUpload) {
            this.setState({ bulk_file: file })
        }
    }
    add_student_Class = () => {
        const { bulk_file } = this.state
        let formData = new FormData();
        formData.append("file", bulk_file);
        const header = {
            token: localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiaddStudentClassesId = requestMessage.messageId;
       
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addBulkClassEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiPostMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
