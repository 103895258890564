// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { invalidCharsForNumberType } from "./CreateCustomReportCardController.web";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showLoader: boolean;
  assessmentsList: any;
  tasksList: any;
  categoryDetailsTabList: any;
  selectedCategoryTab: any;
  selectedCategoryIndex: number;
  assessmentWeightage: any;
  taskWeightage: any;
  assessmentWeightageId: number;
  taskWeightageId: number;
  typesOfMarkingList: any;
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}
export default class CategoryDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetGradingList: string = "";
  apiGetCategoryDetails: string = "";
  apiSaveCategoryDetails: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      showLoader: false,
      assessmentsList: [],
      tasksList: [],
      categoryDetailsTabList: [
        {
          key: 0,
          value: "assessments",
          label: "Assessments",
        },
        {
          key: 1,
          value: "task",
          label: "Task",
        },
      ],
      selectedCategoryTab: 'assessments',
      selectedCategoryIndex: 0,
      assessmentWeightage: "",
      taskWeightage: "",
      assessmentWeightageId: 0,
      taskWeightageId: 0,
      typesOfMarkingList: [],
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getGradingList();
    // Customizable Area End
  }

  // Customizable Area Start
  handleSelectCategoryTab = (tab: string, key: number) => {
    this.setState({ selectedCategoryTab: tab, selectedCategoryIndex: key });
  };

  onChangeAssessmentWeightage = (e: any) => {
    this.setState({ assessmentWeightage: e.target.value })
  }

  addAssessmentClick = () => {
    const { assessmentsList } = this.state;
    const item = {
      assessmentName: "",
      typeOfMarking: "",
      typeOfMarkingId: "",
    }
    if (assessmentsList?.length === 0) {
      this.setState({ assessmentsList: [...assessmentsList, item] })
    } else {
      const lastElement = assessmentsList[assessmentsList?.length - 1];
      if (!lastElement.assessmentName) {
        toast.error('Please add assessment name.')
      } else if (!lastElement?.typeOfMarking) {
        toast.error('Please select type of marking.')
      } else {
        this.setState({ assessmentsList: [...assessmentsList, item] })
      }
    }
  }

  onChangeAssessmentName = (e: any, index: any) => {
    const updatedAssessmentsList = this.state.assessmentsList?.map((item: any, ind: number) => {
      if (ind === index) {
        item.assessmentName = e.target.value
        if (item.id) {
          item.isEdit = true;
        } else {
          item.isEdit = false;
        }
      }
      return item
    })
    this.setState({ assessmentsList: updatedAssessmentsList })
  }

  onChangeTypeOfMarking = (e: any, index: any, selectedType: any) => {
    const updatedList = this.state.assessmentsList?.map((item: any, ind: number) => {
      if (ind === index) {
        item.typeOfMarkingId = e.target.value
        item.typeOfMarking = selectedType
        if (item.id) {
          item.isEdit = true;
        } else {
          item.isEdit = false;
        }
      }
      return item
    })
    this.setState({ assessmentsList: updatedList })
  };

  onChangeIndividualWeightage = (e: any, index: any) => {
    const updatedAssessmentsList = this.state.assessmentsList?.map((item: any, ind: number) => {
      if (ind === index) {
        item.assessmentWeightage = e.target.value
        if (item.id) {
          item.isEdit = true;
        } else {
          item.isEdit = false;
        }
      }
      return item
    })
    this.setState({ assessmentsList: updatedAssessmentsList })
  }

  onChangeTaskWeightage = (e: any) => {
    this.setState({ taskWeightage: e.target.value })
  }

  addTaskClick = () => {
    const { tasksList } = this.state;
    const item = {
      taskName: "",
      typeOfMarking: "",
      typeOfMarkingId: "",
    }
    if (tasksList?.length === 0) {
      this.setState({ tasksList: [...tasksList, item] })
    } else {
      const lastElement = tasksList[tasksList?.length - 1];
      if (!lastElement.taskName) {
        toast.error('Please add task name.')
      } else if (!lastElement?.typeOfMarking) {
        toast.error('Please select type of marking.')
      } else {
        this.setState({ tasksList: [...tasksList, item] })
      }
    }
  }

  onChangeTaskName = (e: any, index: any) => {
    const updatedTasksList = this.state.tasksList?.map((item: any, ind: number) => {
      if (ind === index) {
        item.taskName = e.target.value
        if (item.id) {
          item.isEdit = true;
        } else {
          item.isEdit = false;
        }
      }
      return item
    })
    this.setState({ tasksList: updatedTasksList })
  }

  onChangeTypeOfMarkingTask = (e: any, index: any, selectedType: any) => {
    const updatedList = this.state.tasksList?.map((item: any, ind: number) => {
      if (ind === index) {
        item.typeOfMarkingId = e.target.value
        item.typeOfMarking = selectedType
        if (item.id) {
          item.isEdit = true;
        } else {
          item.isEdit = false;
        }
      }
      return item
    })
    this.setState({ tasksList: updatedList })
  };

  onChangeIndividualTaskWeightage = (e: any, index: any) => {
    const updatedTasksList = this.state.tasksList?.map((item: any, ind: number) => {
      if (ind === index) {
        item.taskWeightage = e.target.value
        if (item.id) {
          item.isEdit = true;
        } else {
          item.isEdit = false;
        }
      }
      return item
    })
    this.setState({ tasksList: updatedTasksList })
  }

  onKeyPressNumberField = (e: any) => {
    if (invalidCharsForNumberType.includes(e.key) || e?.target?.value?.length === 3) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  getGradingList = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    this.setState({ showLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetGradingList = requestMessage.messageId;
    let apiEndPoint = configJSON.getGradesForCategoryEndPoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCategoryDetailsList = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    this.setState({ showLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCategoryDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveCategoryDetails = () => {
    if (this.validateCategory()) {
      this.saveCategoryDetailsApi()
    }
  }

  validateCategory = () => {
    const { assessmentsList, tasksList, assessmentWeightage, taskWeightage } = this.state;
    let isValidAssessments = true;
    let isValidTasks = true;
    let isValidAssessmentWeightage = true;
    let isValidTaskWeightage = true;

    if (assessmentWeightage.length === 0) {
      toast.error('Please enter for weightage assessments.')
      isValidAssessmentWeightage = false;
    } else {
      isValidAssessmentWeightage = true;
    }

    if (taskWeightage.length === 0) {
      toast.error('Please enter for weightage tasks.')
      isValidTaskWeightage = false;
    } else {
      isValidTaskWeightage = true;
    }

    if ((Number(assessmentWeightage) + Number(taskWeightage)) > 100) {
      toast.error('Total weightage of assessment and task should not exceed 100.')
      isValidTaskWeightage = false;
    } else {
      isValidTaskWeightage = true;
    }

    const lastElementAssessment = assessmentsList[assessmentsList?.length - 1];
    if (!lastElementAssessment.assessmentName) {
      toast.error('Please add assessment name.')
      isValidAssessments = false;
    } else if (!lastElementAssessment?.typeOfMarking) {
      toast.error('Please select type of marking for assessment.')
      isValidAssessments = false;
    } else {
      isValidAssessments = true;
    }

    const lastElementTask = tasksList[tasksList?.length - 1];
    if (!lastElementTask.taskName) {
      toast.error('Please add task name.')
      isValidTasks = false;
    } else if (!lastElementTask?.typeOfMarking) {
      toast.error('Please select type of marking for task.')
      isValidTasks = false;
    } else {
      isValidTasks = true;
    }

    return isValidAssessmentWeightage && isValidAssessments && isValidTaskWeightage && isValidTasks;
  }

  saveCategoryDetailsApi = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id
    };

    const { assessmentWeightage, taskWeightage, assessmentsList, tasksList, assessmentWeightageId, taskWeightageId } = this.state;

    let createdAssessmentItems: any = [];
    let editedAssessmentItems: any = [];
    let finalAssessmentItems: any = [];

    assessmentsList?.filter((item: any) => item.id && item.isEdit)?.map((item: any) => {
      const assessmentData: any = {
        id: item?.id,
        name: item.assessmentName.trim(),
        report_grade_id: item.typeOfMarkingId,
      }
      editedAssessmentItems = [...editedAssessmentItems, assessmentData]
    })
    assessmentsList.filter((item: any) => !item.id)?.map((item: any) => {
      const assessmentData: any = {
        name: item.assessmentName.trim(),
        report_grade_id: item.typeOfMarkingId,
      }
      createdAssessmentItems = [...createdAssessmentItems, assessmentData]
    })
    finalAssessmentItems = [...editedAssessmentItems, ...createdAssessmentItems]

    let typeAssessmentItem: any = {
      type: "assessment",
      weightage: Number(assessmentWeightage),
      report_categories_attributes: finalAssessmentItems
    }
    if (assessmentWeightageId) {
      typeAssessmentItem.id = assessmentWeightageId
    }

    let createdTaskItems: any = [];
    let editedTaskItems: any = [];
    let finalTaskItems: any = [];

    tasksList?.filter((item: any) => item.id && item.isEdit)?.map((item: any) => {
      const taskData: any = {
        id: item?.id,
        name: item.taskName.trim(),
        report_grade_id: item.typeOfMarkingId,
      }
      editedTaskItems = [...editedTaskItems, taskData]
    })
    tasksList.filter((item: any) => !item.id)?.map((item: any) => {
      const taskData: any = {
        name: item.taskName.trim(),
        report_grade_id: item.typeOfMarkingId,
      }
      createdTaskItems = [...createdTaskItems, taskData]
    })
    finalTaskItems = [...editedTaskItems, ...createdTaskItems]

    let typeTaskItem: any = {
      type: "task",
      weightage: Number(taskWeightage),
      report_categories_attributes: finalTaskItems
    }
    if (taskWeightageId) {
      typeTaskItem.id = taskWeightageId
    }

    const httpBody: any = {
      report_categories: [typeAssessmentItem, typeTaskItem]
    };

    this.setState({ showLoader: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );

    this.apiSaveCategoryDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (assessmentWeightageId || taskWeightageId) ? configJSON.editCategoryDetailsEndPoint : configJSON.categoryDetailsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Received', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.apiSaveCategoryDetails) {
        if (responseJson != null) {
          if (responseJson?.data && responseJson?.data?.length > 0) {
            this.getCategoryDetailsList()
            const { assessmentWeightageId, taskWeightageId } = this.state;
            if (assessmentWeightageId || taskWeightageId) {
              toast.success("Category Details updated Successfully.")
            } else {
              toast.success("Category Details added Successfully.")
            }
          } else {
            const key: any = Object.keys(responseJson?.errors?.[0])
            toast.error(`${key} ${responseJson?.errors?.[0][key]}`)
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetGradingList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ typesOfMarkingList: responseJson }, () => this.getCategoryDetailsList());
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetCategoryDetails) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setAllCategoryData(responseJson.data)
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }

  setAllCategoryData = (categoryData: any) => {
    categoryData?.map((item: any) => {
      if (item?.attributes?.type === "assessment") {
        let assessmentList: any = [];
        item?.attributes?.categories?.map((assessmentCategory: any) => {
          const selectedType = this.state.typesOfMarkingList?.find((type: any) => parseInt(type.id) === parseInt(assessmentCategory?.report_grade_id))
          const data = {
            id: assessmentCategory?.id,
            assessmentName: assessmentCategory?.name,
            typeOfMarking: selectedType,
            typeOfMarkingId: assessmentCategory?.report_grade_id,
            assessmentWeightage: assessmentCategory?.weightage,
            isEdit: false,
          }
          assessmentList = [...assessmentList, data]
        })

        this.setState({
          assessmentWeightage: item?.attributes?.weightage,
          assessmentWeightageId: item?.attributes?.id,
          assessmentsList: assessmentList
        })
      } else if (item?.attributes?.type === "task") {
        let taskList: any = [];
        item?.attributes?.categories?.map((taskCategory: any) => {
          const selectedType = this.state.typesOfMarkingList?.find((type: any) => parseInt(type.id) === parseInt(taskCategory?.report_grade_id))
          const data = {
            id: taskCategory?.id,
            taskName: taskCategory?.name,
            typeOfMarking: selectedType,
            typeOfMarkingId: taskCategory?.report_grade_id,
            taskWeightage: taskCategory?.weightage,
            isEdit: false,
          }
          taskList = [...taskList, data]
        })

        this.setState({
          taskWeightage: item?.attributes?.weightage,
          taskWeightageId: item?.attributes?.id,
          tasksList: taskList
        })
      }
    })
  }

  onChangeMarkingAssessmentType = (e: React.ChangeEvent<any>, index: number) => {
    const { typesOfMarkingList } = this.state;
    const selectedType = typesOfMarkingList?.filter((type: any) => parseInt(type.id) === parseInt(e.target.value))
    this.onChangeTypeOfMarking(e, index, selectedType[0])
  }

  onChangeMarkingTaskType = (e: React.ChangeEvent<any>, taskIndex: number) => {
    const { typesOfMarkingList } = this.state;
    const selectedType = typesOfMarkingList.filter((type: any) => parseInt(type.id) === parseInt(e.target.value))
    this.onChangeTypeOfMarkingTask(e, taskIndex, selectedType[0])
  }
  // Customizable Area End
}