import React from "react";
import Grid from '@material-ui/core/Grid';
import {
    Box,
    Button,
} from "@material-ui/core";
import { withStyles, } from '@material-ui/core/styles';
import DeleteBlockController, { Props } from "./DeleteBlockController.web";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

class DeleteBlock extends DeleteBlockController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const deleteMes1 = {
            fontSize: "24px",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            margin: '31px 32px 6px 33px',
        } as const;
        const deleteDiv = {
            fontSize: "24px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            marginBottom: '14px',
        } as const;
        const deleteMes2 = {
            fontSize: "14px",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            margin: '6px 53px 40px 54px',
        } as const;
        const cancelButton = {
            margin: '0 20px 0 0',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',

        } as const;
        const deleteButton = {
            margin: '0 0px 0 20px',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',
        } as const;
        const cancelText = {
            fontSize: "14px",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center',
        } as const;
        const deleteText = {
            fontSize: "14px",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center'
        } as const;
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    open={this.props.deleteModal}
                    onClose={this.modalhandleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableAutoFocus={true}
                >
                    <Fade in={this.props.deleteModal} style={{ border: "none" }}>
                        <Box
                            style={{
                                justifyContent: "center",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'white',

                                borderRadius: "29px",
                                padding: "32px 18px",
                                width: "576px",

                            }}
                        >
                            <div>
                                <div style={deleteDiv} className={this.props.classes.title_font}>{this.props.title}</div>
                                <div style={{
                                    height: "1px",
                                    margin: "14px 0 31px 0",
                                }}
                                    className={this.props.classes.solid_border}
                                >

                                </div>
                                <div style={deleteMes1} className={this.props.classes.subTitle_font}>{this.props.message}</div>
                                <div style={deleteMes2} className={this.props.classes.bodyText_font}>This item will be deleted immediately. You cannot undo the action.</div>
                                <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        style={cancelButton}
                                        className={this.props.classes.button_color_disabled}
                                        onClick={this.modalhandleClose}
                                    >
                                        <span style={cancelText}>
                                            Cancel
                                        </span>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={deleteButton}
                                        className={this.props.classes.button_color}
                                        onClick={this.saveModalhandleClose}
                                    >
                                        <span style={deleteText}>
                                            Delete
                                        </span>
                                    </Button>
                                </Grid>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            </>
        )
    }
}
export const themeCreatStyle = ((theme: any) => ({
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        fontFamily: theme.props.textFont,
        '&:hover': {
            background: theme.props.buttonColor,
        }
    },
    solid_border: {
        border: `0.3px solid ${theme.props.buttonColor}`,
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "#f6f6f6",
        fontFamily: theme.props.textFont,
        '&:hover': {
            background: "#f6f6f6",
        }
    }
}))
export default withStyles(themeCreatStyle)(DeleteBlock);