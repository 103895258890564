// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, NativeSelect, InputBase, Table, TableContainer, TableHead, TableCell, TableRow, TableBody, Avatar, Divider, Button } from "@material-ui/core";
import { imgBackArrowSVG } from "../assets";
import ReactPaginate from "react-paginate";
import { withStyles } from '@material-ui/core/styles';
import { evaluationStatusList } from "../utils/constants";
import Spinner from "../../../shared/SideBar/src/Spinner";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { ArrowDropDown, Search } from "@material-ui/icons";
import './AssessmentEvaluation.web.css';
import AssessmentEvaluationController from "./AssessmentEvaluationController.web";
import BorderAllRoundedIcon from '@material-ui/icons/BorderAllRounded';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import moment from "moment";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme) => ({
    root: {
        width: "100%",
        "label + &": {
            marginTop: theme.spacing(1),
        },
    },
    input: {
        borderRadius: 8,
        position: "relative",
        backgroundColor: "#fff",
        boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
        border: '1px solid #f9f8fd',
        padding: "8px 26px 8px 12px",
        height: "42px",
        fontWeight: 600,
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: 'Open sans',
        color: "#3f526d",
        fontSize: 18,
        lineHeight: "22px",
        "&:focus": {
            borderRadius: 8,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase);
// Customizable Area End

export class AssessmentEvaluation extends AssessmentEvaluationController {
    // Customizable Area Start
    render() {
        const { studentList, isListSelected, publishBtnEnable, selectedStatus, assessmentMetaData } = this.state;
        return (
            <>
                {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
                <div>
                    {assessmentMetaData &&
                        <div className="evaluation_heading_section">
                            <Typography className={`${this.props.classes.subTitle_font} assessment_name_textlabel`} >
                                {assessmentMetaData?.[0]?.assessment_name}
                            </Typography>
                            <Typography className={`${this.props.classes.subTitle_font} assessment_subject_textlabel`}>
                                | {assessmentMetaData?.[1]?.subject_name}
                            </Typography>
                        </div>
                    }
                    <Typography data-test-id="evaluation-back-icon" className={`back_text_evaluation ${this.props.classes.heading_font}`} onClick={this.handleBack}>
                        <img src={imgBackArrowSVG} className="back_arrow_img" alt="back"></img>
                        Back
                    </Typography>
                    <Typography className={`textlabel_evaluation_details ${this.props.classes.heading_font}`}>{assessmentMetaData?.[2]?.assessment_heading}</Typography>
                    <div className="section_rectangle_right">
                        <Typography className={`textlabel_students ${this.props.classes.subHeading_font}`}>Students</Typography>
                        <Button
                            data-test-id="publish-btn"
                            disabled={!publishBtnEnable}
                            className={publishBtnEnable ? `publish_evaluation_btn ${this.props.classes.button_color} ${this.props.classes.bodyText_font}` : `publish_evaluation_disable_btn ${this.props.classes.bodyText_font}`}
                            onClick={this.publishAssessment}>Publish</Button>
                    </div>

                    <Grid container spacing={3} className="student_search_grid">
                        <Grid item xs={12} sm={7} md={7} lg={7} xl={8}>
                            <Box
                                display="flex"
                                alignItems="center"
                                className="search_input_box"
                            >
                                <input
                                    placeholder="Search student by name"
                                    className="search_field"
                                    data-test-id="search-student-item"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChangeSearchTerm(event)}
                                    onKeyDown={(e: any) => {
                                        if (e?.key === 'Enter') {
                                            this.setState({ page: 0 }, () => this.getStudentList())
                                        }
                                    }}
                                />
                                <Search className="student_search_icon" />
                            </Box>
                        </Grid>
                        <Grid item xs={7} sm={3} md={3} lg={3} xl={3}>
                            <NativeSelect
                                id="select"
                                data-test-id="status-drop-down"
                                value={selectedStatus}
                                input={<BootstrapInput />}
                                IconComponent={ArrowDropDown}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.handleStatusChange(event)}
                            >
                                <option value="">All Status</option>
                                {evaluationStatusList?.map((item: any) => (
                                    <option value={item.value} key={item.value}>{item?.label}</option>
                                ))}
                            </NativeSelect>
                        </Grid>
                        <Grid item xs={5} sm={2} md={2} lg={2} xl={1}>
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <FormatListBulletedIcon data-test-id="format-list-bullet-icon" className={isListSelected ? "selected_list_bullet_icon list_view_icon" : "not_selected_list_bullet_icon list_view_icon"}
                                    onClick={() => { this.setState({ isListSelected: true }) }}
                                />

                                <BorderAllRoundedIcon data-test-id="all-round-icon" className={!isListSelected ? "selected_all_round_icon list_view_icon" : "not_selected_all_round_icon list_view_icon"}
                                    onClick={() => { this.setState({ isListSelected: false }) }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    {isListSelected ?
                        <div className="view_verticalbox display_flex_column">
                            <TableContainer className="student_table_container">
                                <Table>
                                    <TableHead className="student_list_head">
                                        <TableRow>
                                            <TableCell className={`list_student_name ${this.props.classes.heading_font}`}>Name</TableCell>
                                            <TableCell className={`list_student_details ${this.props.classes.heading_font}`}>Submission Date & Time</TableCell>
                                            <TableCell className={`list_student_details ${this.props.classes.heading_font}`}>Marks({studentList?.[0]?.attributes?.maximum_marks})</TableCell>
                                            <TableCell className={`list_student_details ${this.props.classes.heading_font}`}>Evaluation Status</TableCell>
                                            <TableCell className={`list_student_details text_align_center ${this.props.classes.heading_font}`}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="student_list_table_body">
                                        {
                                            studentList?.length === 0 || studentList === undefined
                                                ?
                                                <TableRow>
                                                    <TableCell
                                                        className={`no_student_record ${this.props.classes.heading_font}`}
                                                        colSpan={7}
                                                        align="center"
                                                    >
                                                        No record(s) found.
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                <>
                                                    {studentList?.map((data: any) => (
                                                        <React.Fragment key={data.id}>
                                                            <TableRow>
                                                                <TableCell colSpan={7} className="blank_cell_row"></TableCell>
                                                            </TableRow>
                                                            <TableRow key={data.id} className="student_cell_row">
                                                                <TableCell className={`cell_student_name ${this.props.classes.heading_font}`}>{data?.attributes?.student?.data?.[0]?.attributes?.first_name} {data?.attributes?.student?.data?.[0]?.attributes?.last_name}</TableCell>
                                                                <TableCell className={`cell_student_details ${this.props.classes.heading_font}`}>{data?.attributes?.submission_date ? `${moment(data?.attributes?.submission_date).format("DD MMM YYYY")} (${data?.attributes?.submitted_time})` : "-"}</TableCell>
                                                                <TableCell className={`cell_student_details ${this.props.classes.heading_font}`}>{data?.attributes?.result ? data?.attributes?.result : "-"}</TableCell>
                                                                <TableCell className={`cell_student_details ${this.props.classes.heading_font}`}>{this.getEvaluationStatus(data?.attributes?.evaluation_status)}</TableCell>
                                                                <TableCell data-test-id="view-student-btn"
                                                                    className={(data?.attributes?.student && data?.attributes?.submission_status && data?.attributes?.submission_status !== "not_taken")
                                                                        ? `opacity_1 cell_student_details cell_student_view ${this.props.classes.subHeading_font}`
                                                                        : `opacity_light cell_student_details cell_student_view ${this.props.classes.subHeading_font}`}
                                                                    onClick={() => this.navigateToDetails(data)}>View</TableCell>
                                                            </TableRow>
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        :
                        <Grid container spacing={3}>
                            {studentList?.length === 0 || studentList === undefined ?
                                <Typography
                                    className={`grid_no_student_record ${this.props.classes.heading_font}`}
                                >
                                    No record(s) found.
                                </Typography> :
                                <>
                                    {studentList?.map((data: any, index: number) => (
                                        <Grid item xs={12} sm={6} md={4} key={index}>
                                            <div className={(data?.attributes?.student && data?.attributes?.submission_status && data?.attributes?.evaluation_status !== "not_taken") ? "student_grid_view" : "student_grid_view_disabled"} onClick={() => this.navigateToDetails(data)}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4} className="student_img_section">
                                                        <Avatar
                                                            src={data?.attributes?.student?.data?.[0]?.attributes?.avatar ? data?.attributes?.student?.data?.[0]?.attributes?.avatar : "/"}
                                                            className={data?.attributes?.student?.data?.[0]?.attributes?.avatar ? "student_img bg_white" : "student_img"}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8} className="student_detail_section">
                                                        <Typography className={`grid_student_name ${this.props.classes.heading_font}`}>{data?.attributes?.student?.data?.[0]?.attributes?.first_name} {data?.attributes?.student?.data?.[0]?.attributes?.last_name}</Typography>
                                                        <Typography className={`grid_student_date ${this.props.classes.subHeading_font}`}>Submition: {data?.attributes?.submission_date ? `${moment(data?.attributes?.submission_date).format("DD MMM YYYY")} (${data?.attributes?.submitted_time})` : "-"}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider className="student_detail_divider" />
                                                <Grid className="status_and_marks_section">
                                                    <Typography className={`grid_student_status ${this.props.classes.subHeading_font}`}>Status: <span className={`status_color ${this.props.classes.heading_font}`}>{data?.attributes?.evaluation_status ? data?.attributes?.evaluation_status === "not_taken" ? this.getEvaluationStatus(data?.attributes?.evaluation_status) : `Evaluation ${this.getEvaluationStatus(data?.attributes?.evaluation_status)}` : "-"}</span></Typography>
                                                    <Typography className={!data?.attributes?.result ? `grid_student_yellow_marks ${this.props.classes.bodyText_font}` : `grid_student_green_marks ${this.props.classes.bodyText_font}`}>{data?.attributes?.result ? data?.attributes?.result : "- "}/{data?.attributes?.maximum_marks}</Typography>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    ))}
                                </>}
                        </Grid>
                    }
                    {this.state.studentListMeta?.pagination?.total_count > 10 &&
                        <div className="overflow_x_auto choose_from_gallery">
                            <ReactPaginate
                                previousLabel={<>←   <span className="xs_display">Previous</span></>}
                                nextLabel={<><span className="xs_display">Next</span>   →</>}
                                breakLabel="..."
                                initialPage={this.state.page}
                                pageCount={this.state.studentListMeta?.pagination?.total_pages}
                                onPageChange={(e: any) => {
                                    if (e.selected !== this.state.page) {
                                        this.setState({ page: e.selected }, () => this.getStudentList())
                                    }
                                }}
                                containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                                previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                                nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                                disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                                activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                            />
                        </div>
                    }
                </div>
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(
    HOCAuth(AssessmentEvaluation as React.ComponentType<any>, "TeacherAccount")
);
// Customizable Area End