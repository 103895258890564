// Customizable Area Start
import * as SparkMD5 from 'spark-md5';
import * as markerjs2 from 'markerjs2';
import * as cropro from 'cropro';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export const downloadPDF = (id: string, fileName: string) => {
  const input = document.getElementById(id);

  html2canvas(input as HTMLElement, {})
    .then((canvas) => {
      const contentDataURL = canvas.toDataURL('image/jpeg', 1);

      const doc = new jsPDF('l', 'mm');

      const imgWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const margin = 5;
      let heightLeft = imgHeight;
      let position = 0;

      doc.addImage(
        contentDataURL,
        'PNG',
        margin,
        margin,
        imgWidth - margin * 2,
        pageHeight - margin * 2
      );

      // heightLeft -= pageHeight;

      // while (heightLeft >= 0) {
      //   position = heightLeft - imgHeight;
      //   doc.addPage();
      //   doc.addImage(contentDataURL, 'PNG', margin, margin, imgWidth - (margin * 2), pageHeight - (margin * 2));
      //   heightLeft -= pageHeight;
      // }
      doc.save(fileName);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const computeChecksumMd5 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const chunkSize = 2097152; // Read in chunks of 2MB
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    let cursor = 0; // current cursor in file

    fileReader.onerror = function(): void {
      reject('MD5 computation failed - error reading the file');
    };

    // read chunk starting at `cursor` into memory
    function processChunk(chunk_start: number): void {
      const chunk_end = Math.min(file.size, chunk_start + chunkSize);
      fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
    }

    // when it's available in memory, process it
    // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
    // of `any` for `e` variable, otherwise stick with `any`
    // See https://github.com/Microsoft/TypeScript/issues/25510
    fileReader.onload = function(e: any): void {
      spark.append(e.target.result); // Accumulate chunk to md5 computation
      cursor += chunkSize; // Move past this chunk

      if (cursor < file.size) {
        // Enqueue next chunk to be accumulated
        processChunk(cursor);
      } else {
        // Computation ended, last chunk has been processed. Return as Promise value.
        // This returns the base64 encoded md5 hash, which is what
        // Rails ActiveStorage or cloud services expect
        resolve(btoa(spark.end(true)));

        // If you prefer the hexdigest form (looking like
        // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
        // resolve(spark.end());
      }
    };

    processChunk(0);
  });
};

export const generateKey = (pre?: any) => {
  return `${pre && pre}_${new Date().getTime()}`;
};

export const checkForRequiredField = (newVal: any, prop: string) => {
  const props = prop.charAt(0).toUpperCase() + prop.slice(1);
  const value = typeof newVal === 'string' ? newVal?.trim() : newVal;
  if (
    value === '' ||
    value === null ||
    value === {} ||
    value?.length === 0 ||
    value === undefined
  ) {
    return { error: true, errorText: `${props} is required.` };
  } else {
    return { error: false, errorText: '' };
  }
};

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const showMarkerArea = (
  index: number,
  imageRef: any,
  questionId: number,
  setImageUrl?: any,
  fileName?: string
) => {
  if (imageRef.current[index].current !== null) {
    // create a marker.js MarkerArea
    const markerArea = new markerjs2.MarkerArea(
      imageRef.current[index].current
    );
    markerArea.settings.displayMode = 'popup';
    markerArea.availableMarkerTypes = [
      markerjs2.FrameMarker,
      markerjs2.FreehandMarker,
      markerjs2.ArrowMarker,
    ];
    markerArea.uiStyleSettings.redoButtonVisible = true;
    markerArea.uiStyleSettings.zoomButtonVisible = true;
    markerArea.uiStyleSettings.zoomOutButtonVisible = true;
    // markerjs2.Style.CLASS_PREFIX = "custom_markerjs2_";

    // attach an event handler to assign annotated image back to our image element
    markerArea.addEventListener('render', (event: any) => {
      if (imageRef.current[index].current) {
        imageRef.current[index].current.src = event.dataUrl;
        const annotatedFile = convertDataURLtoFile(event.dataUrl, fileName);
        setImageUrl(questionId, event.dataUrl, annotatedFile);
      }
    });

    // launch marker.js
    markerArea.show();
  }
};
export const annotateImageWithImgTag = (
  target: any,
  setImageUrl?: any,
  fileName?: string
) => {
  const markerArea = new markerjs2.MarkerArea(target);
  markerArea.settings.displayMode = 'popup';
  markerArea.addEventListener('render', (event) => {
    target.src = event.dataUrl;
    const annotatedFile = convertDataURLtoFile(event.dataUrl, fileName);
    setImageUrl(event.dataUrl, annotatedFile);
  });

  markerArea.addEventListener('markercreate', (event: any) => {
    event.markerArea.createNewMarker(event.marker.typeName);
  });
  markerArea.show();
};

export const annotateImage = (
  imageRef: any,
  setImageUrl?: any,
  fileName?: string
) => {
  if (imageRef.current !== null) {
    const dummy = document.createElement('img');
    dummy.src = imageRef.current.src;
    dummy.crossOrigin = 'anonymous';
    dummy.style.visibility = 'hidden';
    dummy.style.width = imageRef.current.style?.width;
    dummy.style.height = imageRef.current.style?.height;
    //dummy.style.objectFit = imageRef.current.style?.objectFit;
    document.body.appendChild(dummy);

    // create a marker.js MarkerArea
    const markerArea = new markerjs2.MarkerArea(dummy);
    markerArea.settings.displayMode = 'popup';
    markerArea.availableMarkerTypes = [
      markerjs2.FrameMarker,
      markerjs2.FreehandMarker,
      markerjs2.ArrowMarker,
    ];
    markerArea.uiStyleSettings.redoButtonVisible = true;
    markerArea.uiStyleSettings.zoomButtonVisible = true;
    markerArea.uiStyleSettings.zoomOutButtonVisible = true;
    // markerjs2.Style.CLASS_PREFIX = "custom_markerjs2_";

    // attach an event handler to assign annotated image back to our image element
    markerArea.addEventListener('render', (event: any) => {
      if (imageRef.current) {
        imageRef.current.src = event.dataUrl;
        const annotatedFile = convertDataURLtoFile(event.dataUrl, fileName);
        setImageUrl(event.dataUrl, annotatedFile);
      }
    });

    markerArea.addEventListener('close', (event) => {
      document.body.removeChild(dummy);
    });

    // launch marker.js
    markerArea.show();
  }
};

export const showMarkerAreaSingle = (
  imageRef: any,
  setImageUrl?: any,
  fileName?: string
) => {
  if (imageRef.current !== null) {
    // create a marker.js MarkerArea
    let targetImageId: any = document.getElementById(`badgeImage`);
    const markerArea = new markerjs2.MarkerArea(targetImageId);
    const targetImageBoxId: any = document.getElementById(
      'target_image_badge_box'
    );
    markerArea.targetRoot = targetImageBoxId;
    // const markerArea = new markerjs2.MarkerArea(imageRef.current);
    markerArea.availableMarkerTypes = [
      markerjs2.FrameMarker,
      markerjs2.FreehandMarker,
      markerjs2.ArrowMarker,
    ];
    markerArea.uiStyleSettings.redoButtonVisible = true;
    markerArea.uiStyleSettings.zoomButtonVisible = true;
    markerArea.uiStyleSettings.zoomOutButtonVisible = true;

    // attach an event handler to assign annotated image back to our image element
    markerArea.addEventListener('render', (event: any) => {
      if (imageRef.current) {
        targetImageId.src = event.dataUrl;
        const annotatedFile = convertDataURLtoFile(event.dataUrl, fileName);
        downloadFile(annotatedFile, fileName);
        setImageUrl(event.dataUrl, annotatedFile);
      }
    });

    // launch marker.js
    markerArea.show();
  }
};

export function convertDataURLtoFile(dataurl: any, filename: any) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function downloadFile(file: any, fileName: any) {
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export function showCropArea(target: any, setImageUrl?: any) {
  const cropArea = new cropro.CropArea(target);
  cropArea.addRenderEventListener((imgURL: any) => {
    target.src = imgURL;
    const annotatedFile = convertDataURLtoFile(imgURL, 'cropedImg');
    downloadFile(annotatedFile, 'cropedImg');
    setImageUrl(imgURL, annotatedFile);
  });
  cropArea.show();
}

export async function s3UrltoDataURL(url: any, fileName: any) {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], fileName, {
    type: blob.type,
  });
  return file;
}
// Customizable Area End
