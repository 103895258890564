// Customizable Area Start
import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { Modal } from "@material-ui/core";
import { Box, Button, Typography, Divider } from "@material-ui/core";
import { backArrowIcon } from "./assets";
import { Close } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End


// Customizable Area Start
interface IVideoRecordWebcamModal {
  handleClose: () => void;
  handleOnBack?: () => void;
  handleSave?: () => void;
  handleCancel?: () => void;
  createVideo: any;
  loadingSpinner: boolean;
}

const spinnerBar = {
  color: "rgba(255, 255, 255, 1)",
  height: "30px",
  width: "30px",
  margin: "auto",
  top: "4px",
  position: "absolute",
  right: "33%",
} as const;
// Customizable Area End

export const PreviewUploadedVideo: React.FC<IVideoRecordWebcamModal> = ({
  // Customizable Area Start
  handleClose,
  handleOnBack,
  handleSave,
  handleCancel,
  createVideo,
  loadingSpinner,
  // Customizable Area End
}) => {
  // Customizable Area Start
  const handleAlertOnModalClose = useCallback((e: any) => {
    e.preventDefault();
    toast.warn("The video uploaded will be lost upon closing the popup.");
  }, []);

  return (
    <Modal
      open={createVideo?.isCreateUploadVideo === "Upload"}
      onClose={(e) => handleAlertOnModalClose(e)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="preview_uploaded_video_model">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="10%"
        >
          <Box display="flex" id="modal-modal-title">
            <Button
              disableRipple={true}
              className="PreviewVideoBackBtn"
              onClick={handleCancel}
            >
              <div className="PreviewVideoBack">
                <img src={backArrowIcon} className="PreviewVideoBackIcon" />
              <Typography className="back_text PreviewVideoBackText"
              >Back</Typography>
              </div>
            </Button>
          </Box>
          <Typography
            id="modal-modal-title"
            component="h2"
            className="preview_video_uploaded"
          >
            Preview Video
          </Typography>

          <Typography id="modal-modal-title" component="h2">
            <Close onClick={handleClose} className="CreateVideoModalClose" />
          </Typography>
        </Box>
        <Divider className="upload_video_divider" />
        <div className="uploaded_video_height">
          <Box display="flex" flexDirection="column" height="85%">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              className="PreviewVideoDiv"
            >
              <video
                controls
                height="100%"
                src={createVideo?.videoData}
                className="uploaded_video"
              />
            </Box>
          </Box>
          <div>
            <Button
              onClick={handleSave}
              className="PreviewSubmitBtn"
            >
              <span className="PreviewSubmitBtnText">
                {loadingSpinner ? (
                  <CircularProgress style={spinnerBar} />
                ) : (
                  <>Submit</>
                )}
              </span>
            </Button>
            <Button
              onClick={handleCancel}
              className="PreviewCancelBtn"
              style={{
                opacity: loadingSpinner ? 0.5 : 1,
              }}
              disabled={loadingSpinner}
            >
              <Typography className="PreviewCancelBtnText">
                Cancel
              </Typography>
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
  // Customizable Area End
};

// Customizable Area Start
export default PreviewUploadedVideo;
// Customizable Area End
