// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const MailIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
           <path d="M28.362 9.143C30.369 9.143 32 10.8 32 12.84V24.27c0 2.04-1.631 3.697-3.638 3.697H16.89l-3.343 3.707a.986.986 0 0 1-1.092.258 1.009 1.009 0 0 1-.632-.94v-3.025h-1.654c-2.007 0-3.638-1.657-3.638-3.697v-3.361h1.984v3.36c0 .927.742 1.682 1.654 1.682h2.646a1 1 0 0 1 .993 1.008v1.441l1.914-2.123a.985.985 0 0 1 .732-.326h11.908c.911 0 1.653-.755 1.653-1.681V12.84c0-.926-.742-1.68-1.653-1.68h-.993V9.143h.993zM21.783 0c2.034 0 3.686 1.668 3.686 3.72V15.22c0 2.052-1.652 3.72-3.686 3.72H3.686C1.653 18.939 0 17.27 0 15.219V3.72C0 1.668 1.653 0 3.686 0h18.097zM2.01 5.83v9.389c0 .932.753 1.69 1.676 1.69h18.097c.924 0 1.676-.758 1.676-1.69l-.001-9.388-9.574 5.612a2.283 2.283 0 0 1-2.3.001L2.011 5.83zm19.773-3.8H3.686c-.847 0-1.55.638-1.66 1.463l10.544 6.18c.101.06.228.06.329 0l10.545-6.18a1.684 1.684 0 0 0-1.661-1.464z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default MailIcon;
// Customizable Area End