// Customizable Area Start
import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import Spinner from '../../shared/SideBar/src/Spinner';
import './EmailLogin.web.css';
import { Hidden } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {
  img21kSchholLogo,
  imgloginScreenBackground,
  manImage,
  bigCloud,
  smallCloud,
  ic_google,
  ic_facebook,
  ic_apple,
  ic_microsoft,
} from './assets';
import SchoolAdminLoginController, {
  Props,
} from './SchoolAdminLoginController.web';
import { HISTORY } from '../../../components/src/comman';
import './SchoolAdminLogin.web.css';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
import { withStyles } from '@material-ui/core';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class SchoolAdminLogin extends SchoolAdminLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
      <div className="SchoolAdminLoginMainDiv">
        {this.state.loadingSpinner && (
          <Spinner spinnerModal={this.state.loadingSpinner} />
        )}
        <div className="SchoolAdminLoginRoot">
          <Grid container className="SchoolAdminLoginH100">
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={6}
              spacing={0}
              className="SchoolAdminLoginLoginImageDiv"
            >
              <img
                src={imgloginScreenBackground}
                className="SchoolAdminLoginHalfCircleImage"
                alt="Background Image"
              />
              <img
                src={manImage}
                alt="Student Illustrator Image"
                className="student_illustrator_img SchoolAdminLoginImageStudenIllustratorImage"
              />
              <div>
                <img
                  className="SchholLogo SchoolAdminLoginLogoImage"
                  src={img21kSchholLogo}
                  alt="Logo Image"
                />
              </div>
              <div className="learn_together_text SchoolAdminLoginTextlabelQuoteText">
                <Hidden only={['lg', 'xl']}>Let’s learn Together</Hidden>
                <Hidden only={['xs', 'sm', 'md']}>
                  Let’s learn
                  <br />
                  Together
                </Hidden>
              </div>
              <img
                src={smallCloud}
                alt="Small Cloud Image"
                className="cloud_small_img SchoolAdminLoginImageCloudSmallImage"
              />
              <img
                src={bigCloud}
                alt="Big Cloud Image"
                className="cloud_big_img SchoolAdminLoginImageCloudBigImage"
              />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={6}>
              <Grid container className="main_right_div">
                <Grid
                  item
                  xs={12}
                  className="login_div SchoolAdminLoginLoginDiv"
                >
                  <div className="attributed_textlabel SchoolAdminLoginAttrbutedTextlabelDiv">
                    <span className="SchoolAdminLoginAttrbutedTextlabelSigninText">
                      Do you have account?
                    </span>
                    <Link
                      href="/EmailAccountRegistration"
                      className="SchoolAdminLoginAttrbutedTextlabelSigninText2"
                    >
                      Register
                    </Link>
                  </div>
                  <div>
                    <div className="welcome_text SchoolAdminLoginTextlabelWelcomeText">
                      Administrator Login
                    </div>
                    <p className="Sub-h SchoolAdminLoginTextlabelFollowInstructionText">
                      Follow the instructions to make it easier to register and
                      you will be able to explore inside.
                    </p>
                  </div>
                  <div>
                    <form onSubmit={this.onSubmit}>
                      <TextField
                        id="standard-basic"
                        placeholder="Enter Email Id "
                        className="input_props SchoolAdminLoginTextField"
                        variant="outlined"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const value = event.target.value.trim();
                          if (value.length === 0) {
                            this.setState({
                              emailerror: true,
                              emailerrorText: 'Email is required.',
                            });
                          } else if (!emailReg.test(value)) {
                            this.setState({
                              emailerror: true,
                              emailerrorText: 'Enter a valid Email.',
                            });
                          } else if (emailReg.test(value)) {
                            this.setState({
                              emailerror: false,
                              emailerrorText: '',
                            });
                          }
                          this.setState({ email: event.target.value.trim() });
                        }}
                        onBlur={() => {
                          if (
                            this.state.email === null ||
                            this.state.email.length === 0
                          ) {
                            this.setState({
                              emailerror: true,
                              emailerrorText: 'Email is required.',
                            });
                          } else if (!emailReg.test(this.state.email)) {
                            this.setState({
                              emailerror: true,
                              emailerrorText: 'Enter a valid Email.',
                            });
                          } else if (emailReg.test(this.state.email)) {
                            this.setState({
                              emailerror: false,
                              emailerrorText: '',
                            });
                          } else {
                            this.setState({
                              emailerror: false,
                              emailerrorText: '',
                            });
                          }
                        }}
                        error={this.state.emailerror}
                        helperText={this.state.emailerrorText}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ marginRight: '0' }}
                            >
                              <PersonOutlineOutlinedIcon
                                fontSize="small"
                                className="input_start_adornment_icon"
                              />
                            </InputAdornment>
                          ),
                          className: 'login_forrmInput_textfield',
                        }}
                      />

                      <div className="SchoolAdminLoginMT20">
                        <TextField
                          id="standard-basic"
                          placeholder="Password"
                          type={
                            this.state.passwordVisible ? 'text' : 'password'
                          }
                          className="input_props SchoolAdminLoginTextField"
                          variant="outlined"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            if (event.target.value.length === 0) {
                              this.setState({
                                pswderror: true,
                                pswderrorText: 'Password is required.',
                              });
                            } else {
                              this.setState({
                                pswderror: false,
                                pswderrorText: '',
                              });
                            }

                            this.setState({ password: event.target.value });
                          }}
                          onBlur={() => {
                            if (
                              this.state.password === null ||
                              this.state.password.length === 0
                            ) {
                              this.setState({
                                pswderror: true,
                                pswderrorText: 'Password is required.',
                              });
                            } else {
                              this.setState({
                                pswderror: false,
                                pswderrorText: '',
                              });
                            }
                          }}
                          error={this.state.pswderror}
                          helperText={this.state.pswderrorText}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                style={{ marginRight: '0' }}
                              >
                                <LockOpenOutlinedIcon
                                  fontSize="small"
                                  className="input_start_adornment_icon"
                                />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    this.togglePasswordVisibility()
                                  }
                                >
                                  {this.state.passwordVisible ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            className: 'login_forrmInput_textfield',
                          }}
                        />
                      </div>
                      <div className="SchoolAdminLoginForgotPasswordText">
                        <Link
                          onClick={() => {
                            HISTORY.push({
                              pathname: '/ForgotPassword',
                              state: {
                                emailId: this.state.email,
                              },
                            });
                          }}
                          color="inherit"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        className="textlabel_loginText"
                        type="submit"
                      >
                        Log In
                      </Button>
                    </form>
                  </div>
                  <div>
                    <h2 className="h2-title SchoolAdminLoginOrTextLine">
                      <span className="SchoolAdminLoginOrTextLabel">or</span>
                    </h2>
                  </div>

                  <Grid container className="SchoolAdminLoginSocialMedia">
                    <Grid xs={6} sm={6}>
                      <div className="social_media_div">
                        <img
                          src={ic_google}
                          alt="Google Icon"
                          className="social_media_img"
                        />
                        Google
                      </div>
                    </Grid>
                    <Grid xs={6} sm={6} className="SchoolAdminLoginSocialMedia">
                      <div className="social_media_div">
                        <img
                          src={ic_facebook}
                          alt="Facebook Icon"
                          className="social_media_img"
                        />
                        Facebook
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="SchoolAdminLoginSocialMediaDiv">
                    <Grid xs={6} sm={6}>
                      <div className="social_media_div">
                        <img
                          src={ic_apple}
                          alt="Apple Icon"
                          className="social_media_img"
                        />
                        Apple
                      </div>
                    </Grid>
                    <Grid xs={6} sm={6} className="SchoolAdminLoginSocialMedia">
                      <div className="social_media_div">
                        <img
                          src={ic_microsoft}
                          alt="Microsoft Icon"
                          className="social_media_img"
                        />
                        Microsoft 360
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(SchoolAdminLogin);
// Customizable Area End
