// Customizable Area Start
import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// Customizable Area End

// Customizable Area Start
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
// Customizable Area End

interface Props {
    // Customizable Area Start
    classes?: any;
    graph_axis_settings_attributes: any;
    graph_plotting_categories_attributes: any;
    graph_properties: any;
    isPreview?: boolean;
    // Customizable Area End
} 

export const LineChart = ({
    // Customizable Area Start
    classes,
    graph_axis_settings_attributes,
    graph_plotting_categories_attributes,
    graph_properties,
    isPreview
    // Customizable Area End
}: Props) => {
    // Customizable Area Start
    const [labels, setLabels] = useState<string[]>([]);
    const [labelValues, setLabelValues] = useState<any>([]);
    const [barColors, setBarColors] = useState<any>([]);

    useEffect(() => {
        if (graph_plotting_categories_attributes.length > 0) {
            let chartLabels: string[] = [];
            let chartLabelValues: any = [];
            let chartBarColors: any = [];
            graph_plotting_categories_attributes.forEach((data: any) => {
                chartLabels.push(data.label);
                chartLabelValues.push(Number(data.correct_answer_value));
                chartBarColors.push(data.bar_color);
            });

            setLabels(chartLabels);
            setLabelValues(chartLabelValues);
            setBarColors(chartBarColors);
        }else{
            setLabels([]);
            setLabelValues([]);
            setBarColors([]);
        }
    }, [graph_plotting_categories_attributes])
    // Customizable Area End
    
    // Customizable Area Start
    return (
        <div className="graph_main_div">    
            <Line
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                        title: {
                            display: false,
                        },
                        tooltip: {
                            enabled: graph_properties.display_position_on_hover
                        }
                    },
                    scales: {
                        y: {
                            title: {
                                display: true,
                                text: graph_axis_settings_attributes.y_axis_label,
                                color: '#3f526d',
                                font: {
                                    size: 20,
                                    weight: '600',
                                    family: 'OpenSans'
                                }
                            },
                            max: parseInt(graph_axis_settings_attributes.maximum_value !== '' ? graph_axis_settings_attributes.maximum_value : 10),
                            min: parseInt(graph_axis_settings_attributes.minimum_value !== '' ? graph_axis_settings_attributes.minimum_value : 0),
                            ticks: {
                                stepSize: parseInt(graph_axis_settings_attributes.step_size !== '' ? graph_axis_settings_attributes.step_size : 1)
                            },
                            grid: {
                                display: graph_properties.show_grid
                            }
                        },
                        x: {
                            title: {
                                display: true,
                                text: graph_axis_settings_attributes.x_axis_label,
                                color: '#3f526d',
                                font: {
                                    size: 20,
                                    weight: '600',
                                    family: 'OpenSans'
                                }
                            },
                            grid: {
                                display: false
                            }
                        }
                    }
                }}
                data={{
                    labels,
                    datasets: [
                        {
                            data: labelValues,
                            borderColor: '#c1c1c1',
                            pointBorderWidth: 6,
                            backgroundColor: '#c1c1c1'
                        }
                    ],
                }}
            />
        </div>
    )
    // Customizable Area End
}

// Customizable Area Start
export default LineChart;
// Customizable Area End