// Customizable Area Start
import {
  Box,
  Breadcrumbs,
  Typography,
  IconButton,
  Divider,
  AccordionSummary,
  Accordion,
  Grid,
  AccordionDetails,
  withStyles,
} from '@material-ui/core';

import React from 'react';
import QuestionsLayoutController from './QuestionsLayoutController.web';
import { Link } from 'react-router-dom';
import { ArrowBack, Remove } from '@material-ui/icons';
import './common.css';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class QuestionsLayout extends QuestionsLayoutController {
  // Customizable Area Start
  render() {
    const getImage = (questionType: string, subItem: string, item: string) => {
      const usedColor =
        this.state.selectedQuestions === subItem && item === 'objective'
          ? '#f6627a'
          : this.state.selectedQuestions === subItem && item === 'subjective'
          ? '#0091fe'
          : '#3F526D';

      switch (questionType) {
        case 'mcq':
          return (
            <svg
              width="38"
              height="36"
              viewBox="0 0 38 36"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g stroke={usedColor} fill="none" fill-rule="evenodd">
                <path
                  d="M4 2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 20h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2z"
                  stroke-width="2.5"
                  fill={'none'}
                />
                <path
                  d="m37 30.5.5 1.5-18.5.5-.5-1.5 18.5-.5zm0-8 .5 1.5-18.5.5-.5-1.5 18.5-.5zm0-12 .5 1.5-18.5.5-.5-1.5 18.5-.5zm0-8 .5 1.5-18.5.5-.5-1.5L37 2.5z"
                  fill={'none'}
                />
              </g>
            </svg>
          );
        case 'fill in blank':
          return (
            <svg
              width="40"
              height="36"
              viewBox="0 0 40 36"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 2h32a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 20h32a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2z"
                stroke={usedColor}
                stroke-width="2.5"
                fill={'none'}
                fill-rule="evenodd"
              />
            </svg>
          );
        case 'match':
          return (
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 22h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V26a2 2 0 0 1 2-2zM26 2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H26a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 22h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H26a2 2 0 0 1-2-2V26a2 2 0 0 1 2-2z"
                stroke={usedColor}
                stroke-width="2.5"
                fill="none"
                fill-rule="evenodd"
              />
            </svg>
          );
        case 'rating scale':
          return (
            <svg
              width="38"
              height="14"
              viewBox="0 0 38 14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g stroke={usedColor} fill="none" fill-rule="evenodd">
                <rect
                  fill={usedColor}
                  x=".5"
                  y="5.5"
                  width="37"
                  height="3"
                  rx="1.5"
                />
                <path
                  d="M13.5 7c0-1.519.616-2.894 1.61-3.89A5.483 5.483 0 0 1 19 1.5c1.519 0 2.894.616 3.89 1.61A5.483 5.483 0 0 1 24.5 7a5.483 5.483 0 0 1-1.61 3.89A5.483 5.483 0 0 1 19 12.5a5.483 5.483 0 0 1-3.89-1.61A5.483 5.483 0 0 1 13.5 7z"
                  stroke-width="3"
                  fill="#FFF"
                />
              </g>
            </svg>
          );
        case 'graph plotting':
          return (
            <svg
              width="38"
              height="37"
              viewBox="0 0 38 37"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                stroke={usedColor}
                stroke-width="2.5"
                fill="none"
                fill-rule="evenodd"
              >
                <path d="M2 33V22.7a2 2 0 0 1 2-2h3.7a2 2 0 0 1 2 2V33a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zM15.2 33V4a2 2 0 0 1 2-2h3.7a2 2 0 0 1 2 2v29a2 2 0 0 1-2 2h-3.7a2 2 0 0 1-2-2zM28.4 33V11.7a2 2 0 0 1 2-2h3.7a2 2 0 0 1 2 2V33a2 2 0 0 1-2 2h-3.7a2 2 0 0 1-2-2z" />
              </g>
            </svg>
          );
        case 'math':
          return (
            <svg
              width="37"
              height="36"
              viewBox="0 0 37 36"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                transform="translate(1 1)"
                stroke={usedColor}
                fill="none"
                fill-rule="evenodd"
              >
                <rect stroke-width="2" width="14" height="14" rx="2" />
                <g transform="translate(3 3)" fill={usedColor}>
                  <rect x=".5" y="3.7" width="7" height="1" rx=".5" />
                  <rect
                    transform="rotate(90 4 4)"
                    x=".5"
                    y="3.7"
                    width="7"
                    height="1"
                    rx=".5"
                  />
                </g>
                <rect stroke-width="2" y="20" width="14" height="14" rx="2" />
                <g transform="rotate(-45 33.263 11.879)" fill={usedColor}>
                  <rect x=".5" y="3.7" width="7" height="1" rx=".5" />
                  <rect
                    transform="rotate(90 4 4)"
                    x=".5"
                    y="3.7"
                    width="7"
                    height="1"
                    rx=".5"
                  />
                </g>
                <rect stroke-width="2" x="21" width="14" height="14" rx="2" />
                <rect
                  x=".5"
                  y=".5"
                  width="7"
                  height="1"
                  rx=".5"
                  transform="translate(24 6)"
                  fill={usedColor}
                />
                <rect
                  stroke-width="2"
                  x="21"
                  y="20"
                  width="14"
                  height="14"
                  rx="2"
                />
                <g fill={usedColor}>
                  <rect
                    x=".5"
                    y=".5"
                    width="7"
                    height="1"
                    rx=".5"
                    transform="translate(24 26)"
                  />
                  <rect
                    x=".5"
                    y=".5"
                    width="1"
                    height="1"
                    rx=".5"
                    transform="translate(27 23)"
                  />
                  <rect
                    x=".5"
                    y=".5"
                    width="1"
                    height="1"
                    rx=".5"
                    transform="translate(27 29)"
                  />
                </g>
              </g>
            </svg>
          );
        case 'text':
          return (
            <svg width="37" height="31" xmlns="http://www.w3.org/2000/svg">
              <path
                d="m36 28.5.5 1.5-35.5.5L.5 29l35.5-.5zm0-8 .5 1.5-35.5.5L.5 21l35.5-.5zm0-12 .5 1.5-35.5.5L.5 9 36 8.5zm0-8 .5 1.5L1 2.5.5 1 36 .5z"
                fill={usedColor}
                stroke={usedColor}
                fill-rule="evenodd"
              />
            </svg>
          );
        case 'coding':
          return (
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="32.000000pt"
              height="26.000000pt"
              viewBox="0 0 32.000000 26.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,26.000000) scale(0.100000,-0.100000)"
                fill={usedColor}
                stroke="none"
              >
                <path
                  d="M166 239 c-19 -85 -34 -225 -26 -233 11 -11 14 2 34 139 10 61 12
103 6 109 -5 5 -11 0 -14 -15z"
                />
                <path
                  d="M40 175 l-44 -45 44 -45 c24 -24 47 -42 53 -38 5 3 -8 23 -29 44
l-38 39 38 39 c21 21 34 41 29 44 -6 4 -29 -14 -53 -38z"
                />
                <path
                  d="M227 213 c-4 -3 9 -24 30 -45 l37 -38 -38 -39 c-21 -21 -34 -41 -29
-44 6 -4 29 14 54 39 l43 44 -45 45 c-25 25 -48 42 -52 38z"
                />
              </g>
            </svg>
          );
        case 'scan & upload':
          return (
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="32.000000pt"
              height="32.000000pt"
              viewBox="0 0 32.000000 32.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                fill={usedColor}
                stroke="none"
              >
                <path
                  d="M12 308 c-14 -14 -16 -78 -2 -78 6 0 10 16 10 35 0 33 2 35 35 35 19
           0 35 5 35 10 0 14 -64 12 -78 -2z"
                />
                <path
                  d="M230 310 c0 -5 16 -10 35 -10 33 0 35 -2 35 -35 0 -19 5 -35 10 -35
           14 0 12 64 -2 78 -14 14 -78 16 -78 2z"
                />
                <path
                  d="M57 263 c-11 -10 -8 -53 3 -53 6 0 10 9 10 20 0 13 7 20 20 20 11 0
           20 5 20 10 0 11 -43 14 -53 3z"
                />
                <path
                  d="M210 261 c0 -6 9 -11 20 -11 13 0 20 -7 20 -20 0 -11 5 -20 11 -20 6
           0 9 12 7 28 -2 21 -9 28 -30 30 -16 2 -28 -1 -28 -7z"
                />
                <path
                  d="M0 160 c0 -6 60 -10 160 -10 100 0 160 4 160 10 0 6 -60 10 -160 10
           -100 0 -160 -4 -160 -10z"
                />
                <path
                  d="M50 92 c0 -10 5 -23 12 -30 15 -15 48 -16 48 -2 0 6 -9 10 -20 10
           -13 0 -20 7 -20 20 0 11 -4 20 -10 20 -5 0 -10 -8 -10 -18z"
                />
                <path
                  d="M250 90 c0 -13 -7 -20 -20 -20 -11 0 -20 -4 -20 -10 0 -14 33 -13 48
           2 15 15 16 48 2 48 -5 0 -10 -9 -10 -20z"
                />
                <path
                  d="M0 57 c0 -18 5 -38 12 -45 14 -14 78 -16 78 -2 0 6 -16 10 -35 10
           -33 0 -35 2 -35 35 0 19 -4 35 -10 35 -5 0 -10 -15 -10 -33z"
                />
                <path
                  d="M300 55 c0 -33 -2 -35 -35 -35 -19 0 -35 -4 -35 -10 0 -14 64 -12 78
           2 14 14 16 78 2 78 -5 0 -10 -16 -10 -35z"
                />
              </g>
            </svg>
          );
        case 'drawing':
          return (
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="32.000000pt"
              height="32.000000pt"
              viewBox="0 0 32.000000 32.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                fill={usedColor}
                stroke="none"
              >
                <path
                  d="M154 238 c-61 -59 -87 -92 -95 -120 -7 -27 -15 -38 -29 -38 -24 0
           -38 -44 -20 -65 9 -11 34 -15 96 -15 80 0 85 1 96 25 10 22 18 25 59 25 26 0
           51 4 54 10 4 6 -17 10 -55 10 -55 0 -61 -2 -70 -25 -9 -24 -13 -25 -90 -25
           -79 0 -81 1 -78 23 3 19 11 23 56 27 50 5 56 9 137 90 53 53 85 92 85 106 0
           23 -29 54 -50 54 -7 0 -50 -37 -96 -82z m125 43 c16 -30 -11 -47 -33 -22 -10
           11 -15 25 -11 30 10 17 33 13 44 -8z m-113 -89 c-36 -36 -69 -63 -73 -59 -9
           10 118 138 130 130 5 -3 -20 -35 -57 -71z m84 40 c0 -14 -126 -133 -133 -125
           -4 4 21 35 55 70 59 60 78 73 78 55z m-155 -132 c3 -5 -1 -10 -9 -10 -9 0 -16
           5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z"
                />
              </g>
            </svg>
          );
        default:
          return null;
      }
    };

    return (
      <Box marginTop={3}>
        {/* Breadcrumbs start here */}
        <Box display={'flex'} alignItems="center">
          <IconButton className="padding_10">
            <ArrowBack />
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/" className={this.props.classes.subHeading_font}>
              Create new assessment
            </Link>
            <Link to="/" className={this.props.classes.subHeading_font}>
              test name
            </Link>
            <Typography className={this.props.classes.subHeading_font}>
              select question type
            </Typography>
          </Breadcrumbs>
        </Box>
        {/* Breadcrumbs end here */}
        <Box padding={'10px'}>
          <Typography
            variant="subtitle1"
            className={this.props.classes.subTitle_font}
          >
            Select a Question Or Resource
          </Typography>
          <Divider className="margin_15_0" />

          <Grid container className="margin_15_0">
            <Grid item xs={12} lg={3} className="question_grid">
              {/* Question accordions map starts here */}
              {this.state.questions.length > 0 &&
                this.state.questions.map((item) => {
                  const chosenBackgroundColor =
                    item.name === 'objective'
                      ? '#f6627a'
                      : item.name === 'subjective'
                      ? '#0091fe'
                      : '#fd915b';
                  return (
                    <Accordion
                      style={{
                        backgroundColor: chosenBackgroundColor,
                        margin: '10px 0px',
                      }}
                      key={item.id}
                    >
                      <AccordionSummary
                        expandIcon={<Remove className="color_fff" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="capitalized_white_text">
                          {item.name.replaceAll('_', ' / ')}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="accordion_details">
                        {item.sub_categories.map((subItem: any) => {
                          const usedColor =
                            this.state.selectedQuestions === subItem.name &&
                            item.name === 'objective'
                              ? '#f6627a'
                              : this.state.selectedQuestions === subItem.name &&
                                item.name === 'subjective'
                              ? '#0091fe'
                              : this.state.selectedQuestions === subItem.name &&
                                item.name === 'audio_video'
                              ? '#fd915b'
                              : '#3F526D';
                          return (
                            <Box
                              display="flex"
                              alignItems="center"
                              className="pointer_cursor color_f9f8fd"
                              width="100%"
                              padding={'12px'}
                              borderRadius="5px"
                              margin={'5px 0px'}
                              key={subItem.id}
                              onClick={() =>
                                this.selectQuestionCategory(
                                  subItem.name,
                                  item.name
                                )
                              }
                            >
                              {getImage(
                                subItem.name.toLowerCase(),
                                subItem.name,
                                item.name
                              )}
                              <Typography
                                style={{
                                  marginLeft: '15px',
                                  fontSize: '18px',
                                  textTransform: 'capitalize',
                                  color: usedColor,
                                }}
                              >
                                {subItem.name.replaceAll('_', ' / ')}
                              </Typography>
                            </Box>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </Grid>
            <Grid item xs={12} lg={9} className="padding_10">
              <Grid container alignItems="center">
                {/* Question types populate starts here */}
                {this.state.types.map((item) => {
                  if (item.image_url !== '') {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        key={item.id}
                        className={
                          this.state.selectedType === item.name
                            ? `${
                                this.props.classes.border_Color
                              } question_type_grid`
                            : 'question_type_grid'
                        }
                        onClick={() => this.setSelectedType(item.name)}
                      >
                        <img src={item.image_url} className="width_100" />
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(
  QuestionsLayout as React.ComponentType<any>
);
// Customizable Area End
