// Customizable Area Start
import React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  FlatList,
  ActivityIndicator,
} from 'react-native';
import ReactPaginate from 'react-paginate';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Datetime from 'react-datetime';
// import './TeacherDashboard.web.css';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  NativeSelect,
} from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
  Search,
  Replay,
  NotificationsNone,
  HeadsetMicOutlined,
  Title,
  TitleOutlined,
  KeyboardArrowLeft,
  EditOutlined,
  MoreVertOutlined,
  KeyboardArrowDown,
  ArrowDropDown,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import CreateOnlineLectureController, {
  Props,
} from './CreateOnlineLectureController.web';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import { RFValue } from 'react-native-responsive-fontsize';
import moment from 'moment';
import Chart from 'react-google-charts';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Header from '../../shared/Header/src/Header';
import FormHelperText from '@material-ui/core/FormHelperText';
import SideBar from '../../shared/SideBar/src/SideBar';
import Logout from '../../shared/SideBar/src/Logout';
import { HISTORY } from '../../../components/src/comman';
import { filter } from './assets';
import ClearIcon from '@material-ui/icons/Clear';
import CommanSelectDiv from './CommanSelectDiv.web';
import DivisionSelectDiv from './DivisionSelectDiv.web';
import CloseIcon from '@material-ui/icons/Close';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import Spinner from '../../shared/SideBar/src/Spinner';
import './StudentClassesAndSection.web.css';
import AlertModal from '../../alert/src/AlertModal.web';
import './CreateOnlineLecture.web.css';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End
// Customizable Area Start
const noBorder = {
  border: '0px',
};

const avtarIcon = {
  width: '31px',
  height: '31px',
  margin: '20px 23px 20px 19px',
} as const;
// Customizable Area End

export class CreateOnlineLecture extends CreateOnlineLectureController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const WhiteRadio = withStyles({
      root: {
        color: '#3462bf',
        padding: '2px 4px 2px 4px',
        '&$checked': {
          color: '#3462bf',
          padding: '2px 4px 2px 4px',
        },
      },
      checked: {},
    })((props: RadioProps) => <Radio color="default" {...props} />);
    const BootstrapInput2 = withStyles((theme: any) => ({
      root: {
        'label + &': {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: 'relative',
        border: this.state.subjectError ? '1px solid red' : 'solid 1px #ebebeb',
        backgroundColor: '#fff',
        padding: '18px 22px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        fontWeight: 600,
        fontSize: 18,
        '&:focus': {
          borderRadius: 4,
          borderColor: '#80bdff',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
        lineHeight: '21px',
      },
    }))(InputBase);

    const BootstrapInput1 = withStyles((theme: any) => ({
      root: {
        'label + &': {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: 'relative',
        border: this.state.periodError ? '1px solid red' : 'solid 1px #ebebeb',
        backgroundColor: '#fff',
        padding: '18px 22px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        fontWeight: 600,
        fontSize: 18,
        '&:focus': {
          borderRadius: 4,
          borderColor: '#80bdff',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
        lineHeight: '21px',
      },
    }))(InputBase);
    const BootstrapInput = withStyles((theme: any) => ({
      root: {
        'label + &': {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: 'relative',
        border: this.state.classerror ? '1px solid red' : 'solid 1px #ebebeb',
        backgroundColor: '#fff',
        padding: '18px 22px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        textTransform: 'none',
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        fontWeight: 600,
        fontSize: 18,
        '&:focus': {
          borderRadius: 4,
          borderColor: '#80bdff',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
        lineHeight: '21px',
      },
    }))(InputBase);
    const today = moment().subtract(1, 'day');
    const disableFutureDt = (current: any) => {
      return current.isAfter(today);
    };
    const {
      ClassData,
      userData,
      getTeacherSubjects,
      getTeacherPeriod,
    } = this.state;
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="online_lecture_modal"
          open={this.props.createEventModal}
          onClose={this.modalhandleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={this.props.createEventModal} style={{ border: 'none' }}>
            <div
              className="Create-Event-Popup create_event_popup"
            >
              {this.state.showSpinner && (
                <Spinner spinnerModal={this.state.showSpinner} />
              )}
              <form onSubmit={this.addClass} className="h_100">
                <Box
                  className="Eventtitle online_lecture_modal_container"
                >
                  <Box
                    className='online_lecture_title_box'
                  >
                    <Typography
                      className={`${this.props.classes.title_font} online_lecture_title_typo`}
                    >
                      Create Lecture
                    </Typography>
                  </Box>
                  <Box>
                    <CloseIcon
                      className="modal_close_icon"
                      onClick={this.modalhandleClose}
                    />
                  </Box>
                </Box>

                <Box
                  className='form_container'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <Box
                        className="center_box_items"
                      >
                        <Typography
                          className={`${this.props.classes.title_font} form_group_label`}
                        >
                          Lecture Title
                        </Typography>
                        <Box
                          className='form_group'
                        >
                          <TextField
                            id="standard-basic"
                            type="text"
                            value={this.state.title}
                            autoComplete="off"
                            className={`${this.props.classes.bodyText_font} textbox`}
                            variant="outlined"
                            inputProps={{
                              style: {
                                fontSize: 15.8,
                              },
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = event.target.value.trim();
                              if (value.length === 0) {
                                this.setState({
                                  titleError: true,
                                  titleErrorText: 'Tittle is required.',
                                });
                              } else {
                                this.setState({
                                  titleError: false,
                                  titleErrorText: '',
                                });
                              }
                              this.setState({ title: event.target.value });
                            }}
                            onBlur={() => {
                              if (
                                this.state.title === null ||
                                this.state.title.length === 0
                              ) {
                                this.setState({
                                  titleError: true,
                                  titleErrorText: 'Tittle is required.',
                                });
                              } else {
                                this.setState({
                                  titleError: false,
                                  titleErrorText: '',
                                });
                              }
                            }}
                            error={this.state.titleError}
                          />
                          <FormHelperText className="error_msg">
                            {this.state.titleErrorText}
                          </FormHelperText>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box
                        className='center_box_items'
                      >
                        <Typography
                          className={`${this.props.classes.title_font} form_group_label`}
                        >
                          Date
                        </Typography>
                        <Box className='w_100'>
                          <Datetime
                            dateFormat={'DD MMMM, YYYY'}
                            timeFormat={false}
                            strictParsing={true}
                            input={true}
                            isValidDate={disableFutureDt}
                            value={this.state.periodDate}
                            className={
                              this.state.periodDateError
                                ? "inputDueDateError"
                                : "inputDueDate"
                            }
                            inputProps={{
                              placeholder: 'Select Date',
                              className: 'Date_of_birth_calendar_Icon_register',
                              readOnly: true,
                            }}
                            onClose={() => {
                              if (this.state.periodDate.length === 0) {
                                this.setState({
                                  periodDateError: true,
                                  periodDateErrorText:
                                    'Please select Due date.',
                                });
                              }
                            }}
                            closeOnSelect
                            onChange={(date) => {
                              console.log(date);
                              if (
                                moment(date).format('DD/MM/YYYY').length === 0
                              ) {
                                this.setState({
                                  periodDateError: true,
                                  periodDateErrorText:
                                    ' Please select date of birth',
                                });
                              }
                              this.setState({
                                periodDate: moment(date).format('DD/MM/YYYY'),
                                periodDateError: false,
                                periodDateErrorText: '',
                                period_id: '',
                              });
                              this.get_period(
                                moment(date).format('DD/MM/YYYY'),
                                this.state.class_id
                              );
                            }}
                          />

                          {this.state.periodDateError && (
                            <FormHelperText className="error_msg">
                              {this.state.periodDateErrorText}
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Box
                        className='center_box_items'
                      >
                        <Typography
                          className={`${this.props.classes.title_font} form_group_label`}
                        >
                          Class
                        </Typography>
                        <Box className="form_group">
                          <NativeSelect
                            id="select"
                            value={this.state.class_id}
                            input={<BootstrapInput />}
                            className="native_select"
                            inputProps={{
                              style: {
                                textTransform: 'capitalize',
                              },
                            }}
                            IconComponent={ArrowDropDown}
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              console.log(event.target.value);
                              const value = event.target.value;
                              if (value.length === 0) {
                                this.setState({
                                  classerror: true,
                                });
                              } else {
                                this.setState({
                                  class_id: event.target.value,
                                  classerror: false,
                                });
                                this.get_teacher_subjects(event.target.value);
                                this.get_period(
                                  this.state.periodDate,
                                  event.target.value
                                );
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select Class
                            </option>
                            {ClassData.map((getTeacherClass: any) => (
                              <option
                                value={getTeacherClass.attributes.class.id}
                                className="options"
                              >
                                {getTeacherClass.attributes.class.display_name}
                              </option>
                            ))}
                          </NativeSelect>
                          {this.state.classerror && (
                            <FormHelperText
                              className="error_msg"
                            >
                              Please select class.
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Box
                        className='center_box_items'
                      >
                        <Typography
                          className={`${this.props.classes.title_font} form_group_label`}
                        >
                          Period
                        </Typography>
                        <Box
                          className='form_group'
                        >
                          <NativeSelect
                            id="select"
                            value={this.state.period_id}
                            input={<BootstrapInput1 />}
                            className="w_100"
                            inputProps={{
                              style: {
                                textTransform: 'capitalize',
                              },
                            }}
                            IconComponent={ArrowDropDown}
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              const data = getTeacherPeriod.find(
                                (res: any) => res.id === event.target.value
                              );
                              this.setState({
                                period_id: event.target.value,
                                periodError: false,
                                periodTime: moment(data.attributes.start_time).format('hh:mm A'),
                                batch_id:
                                  data.attributes.batch != null
                                    ? data.attributes.batch.id
                                    : '',
                              });
                            }}
                            onBlur={() => {
                              if (
                                this.state.period_id === null ||
                                this.state.period_id.length === 0
                              ) {
                                this.setState({ periodError: true });
                              } else if (this.state.period_id.length > 0) {
                                this.setState({ periodError: false });
                              } else {
                                this.setState({ periodError: false });
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select Period
                            </option>
                            {getTeacherPeriod.map((teacherPeriod: any) => (
                              <option
                                value={teacherPeriod.id}
                                className="options"
                              >
                                {teacherPeriod.attributes.period_name}
                              </option>
                            ))}
                          </NativeSelect>
                          {this.state.periodDate.length === 0 &&
                            this.state.class_id.length === 0 ? (
                            <FormHelperText
                              style={{
                                color: this.state.periodError
                                  ? 'red'
                                  : '#3f526d',
                                margin: '3px 14px 0',
                              }}
                            >
                              Please select date first.
                            </FormHelperText>
                          ) : (
                            this.state.periodError && (
                              <FormHelperText
                                className="error_msg"
                              >
                                Please select period.
                              </FormHelperText>
                            )
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Box
                        className='center_box_items'
                      >
                        <Typography
                          className={`${this.props.classes.title_font} form_group_label`}
                        >
                          Subject
                        </Typography>
                        <Box
                          className='form_group'
                        >
                          <NativeSelect
                            id="select"
                            value={this.state.subject_id}
                            input={<BootstrapInput2 />}
                            className='w_100'
                            inputProps={{
                              style: {
                                textTransform: 'capitalize',
                              },
                            }}
                            IconComponent={ArrowDropDown}
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              this.setState({
                                subject_id: event.target.value,
                                subjectError: false,
                              });
                            }}
                            onBlur={() => {
                              if (
                                this.state.subject_id === null ||
                                this.state.subject_id.length === 0
                              ) {
                                this.setState({ subjectError: true });
                              } else if (this.state.subject_id.length > 0) {
                                this.setState({ subjectError: false });
                              } else {
                                this.setState({ subjectError: false });
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select Subject
                            </option>
                            {getTeacherSubjects.map((teacherSubject: any) => (
                              <option
                                value={teacherSubject.id}
                                className="options"
                              >
                                {teacherSubject.subject_name}
                              </option>
                            ))}
                          </NativeSelect>
                          {this.state.subjectError && (
                            <FormHelperText
                              className="error_msg"
                            >
                              Please select subject.
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Box
                        className='center_box_items'
                      >
                        <Typography
                          className={`${this.props.classes.title_font} form_group_label`}
                        >
                          Time
                        </Typography>
                        <Box
                          className="form_group"
                        >
                          <TextField
                            id="standard-basic"
                            type="text"
                            disabled={true}
                            value={this.state.periodTime}
                            autoComplete="off"
                            className="textbox_disabled"
                            variant="outlined"
                            inputProps={{
                              style: {
                                fontSize: 15.8,
                                opacity: 0.5,
                              },
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    {this.state.isMeetLink && !userData.meeting_paid_account && (
                      <Grid item xs={4}>
                        <Box
                          className='center_box_items'
                        >
                          <Typography
                            className={`${this.props.classes.title_font} form_group_label`}
                          >
                            Meeting Link
                          </Typography>
                          <Box
                            className="form_group"
                          >
                            <TextField
                              id="standard-basic"
                              type="text"
                              value={this.state.meetLink}
                              autoComplete="off"
                              className='textbox'
                              variant="outlined"
                              inputProps={{
                                style: {
                                  fontSize: 15.8,
                                },
                              }}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const value = event.target.value.trim();
                                this.setState({ meetLink: value });
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box>
                        <RadioGroup
                          aria-label="position"
                          name="position"
                          defaultValue="top"
                          className='radiogroup'
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.value === 'online') {
                              this.setState({ isMeetLink: true });
                            } else {
                              this.setState({ isMeetLink: false });
                            }
                          }}
                        >
                          <Box
                            className='radiogroup mr_10'
                          >
                            <WhiteRadio
                              value="online"
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': 'A' }}
                            />
                            <Typography
                              className={`${this.props.classes.title_font} radio_group_label`}
                            >
                              Online
                            </Typography>
                          </Box>
                          <Box
                            className='radiogroup'
                          >
                            <WhiteRadio
                              value="offline"
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': 'A' }}
                            />
                            <Typography
                              className={`${this.props.classes.title_font} radio_group_label`}
                            >
                              Offline
                            </Typography>
                          </Box>
                        </RadioGroup>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        className="center_box_items margin_box"
                      >
                        <Typography
                          className={`${this.props.classes.title_font} form_group_label`}
                        >
                          Description
                        </Typography>

                        <TextField
                          id="standard-basic"
                          type="text"
                          className='description_field'
                          variant="outlined"
                          value={this.state.description}
                          inputProps={{
                            style: {
                              fontSize: 15.8,
                            },
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value;
                            this.setState({ description: value });
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  className='button_container'
                >
                  <Button
                    variant="contained"
                    className={`${this.props.classes.button_color} add_btn`}
                    type="submit"
                  >
                    Add
                  </Button>
                </Box>
              </form>
            </div>
          </Fade>
        </Modal>
        <AlertModal
          alertModal={this.state.alertModal}
          isTeacher={true}
          onCloseModal={() => {
            this.setState({ alertModal: false });
          }}
          alertTitle="Alert!"
          alertMessage={this.state.alertMessage}
          buttonText="OK"
        />
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  CreateOnlineLecture
) as React.ComponentType<any>;

// Customizable Area End
