// Customizable Area Start
import React, { useState } from 'react'
import {
    Button,
    Modal,
    Backdrop,
    Grid,
    IconButton,
    TextField,
    FormHelperText,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import './CreateCustomReportCard.web.css';
// Customizable Area End

// Customizable Area Start
const form_input_text_field = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
} as const;

const form_input_text_field_error = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
    border: "1px solid red"
} as const;
// Customizable Area End
export default function AddAttributesModal(props: any) {
    // Customizable Area Start
    const [attribute, setAttribute] = useState<string>('');
    const [attributeError, setAttributeError] = useState<boolean>(false);

    const handleOnChangeAttribute = (value: string) => {
        setAttribute(value)
    }

    const handleAddAttribute = () => {
        let existingName = props.attributeList.filter((data: any) => data.attributes.name == attribute);
        if (existingName.length > 0) {
            setAttributeError(true);
            return;
        }
        resetInit()
        props.handleAddAttribute(attribute)
    }

    const handleClose = () => {
        resetInit()
        props?.handleOnClose()
    }

    const resetInit = () => {
        setAttribute('');
        setAttributeError(false);
    }

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="AddAttrModal"
                open={props.openModal}
                onClose={() => handleClose()}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                data-test-id="add-attribute-modal"
            >
                <Grid container spacing={0} className="AddAttrModalGrid">
                    {/* Close Btn */}
                    <Grid item xs={12} className="AddAttrCloseDiv">
                        <IconButton onClick={() => handleClose()} className="closeIcon AddAttrCloseIcon" data-test-id="add-attribute-close-icon">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    {/* Close Btn END */}

                    <Grid item xs={12} sm={6} md={6} lg={4} className="AddAttrMainGrid">
                        <div className="w-100">
                            <div className={`${props.classes.subTitle_font} grade-detail-title`}>
                                Enter Attribute Name
                            </div>
                            <TextField
                                id="standard-basic"
                                placeholder="Scholastic Area"
                                style={attributeError ? form_input_text_field_error : form_input_text_field}
                                variant="outlined"
                                value={attribute}
                                onChange={(e: any) => handleOnChangeAttribute(e.target.value)}
                                inputProps={{ className: `term-text-field` }}
                                data-test-id="add-attribute-name-field"
                            />
                            {attributeError && (
                                <FormHelperText className="AddAttrError">
                                    {'Attribute already exist'}
                                </FormHelperText>
                            )}
                        </div>
                    </Grid>
                    <div className="grade-next-btn-div">
                        <Button
                            className={`${props.classes.button_color} done-btn AddAttributeBtn`}
                            disabled={attribute.trim() === ''}
                            onClick={handleAddAttribute}
                            data-test-id="add-attribute-btn"
                        >
                            Add Attribute
                        </Button>
                    </div>
                </Grid>
            </Modal>
        </>
    )
    // Customizable Area End
}
