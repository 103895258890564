// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { imgDifficult, imgTotalTime, imgSections, imgDeleteGray } from "./assets";
import FormHelperText from "@material-ui/core/FormHelperText";

import TeacherAssessmentCreateDetailController, {
  Props,
} from "./TeacherAssessmentCreateDetailController.web";
import { examDifficulties } from "./utils/constants";
import HOCAuth from "../../shared/Header/src/HOCAuth.web";
import Spinner from "../../shared/SideBar/src/Spinner";
import WarningModal from "./WarningModal.web";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = {
  settingcontainer: {
    textAlign: "center",
    backgroundColor: "#3a60d7",
    padding: 10,
    color: "#FFF",
    paddingBottom: 5,
    width: "fit-content",
    marginLeft: "auto",
    cursor: "pointer",
    marginTop: 10,
  },
  assetmentDetailsWraper: {
    borderRadius: 6,
    "-webkit-box-shadow": "0 0 15px 0 rgba(0, 0, 0, 0.1)",
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
    background: "#fff",
    padding: "22px",
    margin: "20px 0px",
  },

  labelText: {
    marginBottom: 15,
    marginTop: 20,
    fontSize: 18,
    fontWeight: 600,
  },
  error: {
    border: "solid 0.5px #ff0000",
  },
  inputTextDifficultyLevel: {
    padding: "14px 16px",
    borderRadius: 5,
    backgroundColor: "#f9f8fd",
    width: "100%",
    border: "none",
    fontSize: 20,
    fontWeight: 600,
    height: 58,
  },
  inputText: {
    padding: "14px 16px",
    borderRadius: 5,
    backgroundColor: "#f9f8fd",
    width: "100%",
    border: "none",
    fontSize: 16,
    fontWeight: 600,
    height: 58,
  },
  inputTextWithBorder: {
    padding: "14px 16px",
    borderRadius: 5,
    width: "100%",
    border: "solid 0.5px #3f526d",
    marginBottom: 15,
    fontSize: 20,
    fontWeight: 600,
    height: 58,
  },
  inputTextWithBorderRed: {
    padding: "14px 16px",
    borderRadius: 5,
    width: "100%",
    border: "solid 0.5px red",
    marginBottom: 0,
    color: "#3f526d",
    fontSize: 20,
    fontWeight: 600,
    fontFamily: "Open sans",
    height: 58,
  },
  numberText: {
    padding: "14px 16px",
    borderRadius: 5,
    width: "100%",
    border: "solid 0.5px #3f526d",
    fontSize: 16,
    fontWeight: 600,
    height: 58,
  },
  selectSection: {
    display: "flex",
    columnGap: 30,
    paddingBottom: 40,
  },
  checkBoxStyle: {
    backgroundColor: "#f9f8fd",
    marginLeft: 0,
    width: "100%",
    marginBottom: 0,
    height: 58,
    paddingLeft: 10,
  },
  questionPaperSection: {
    display: "flex",
    columnGap: 30,
    paddingBottom: 30,
  },
  sectionHeading: {
    display: "flex",
    alignItems: "center",
    columnGap: 30,
  },
  sectionTitle: {
    padding: "10px 15px",
    background: "#e5ecff",
    borderRadius: 5,
    color: "#3f526d",
    fontSize: 20,
    fontWeight: 600,
    height: 58,
    display: "flex",
    alignItems: "center",
  },
  sectionInputData: {
    padding: "10px 0px",
    borderRadius: 5,
    fontWeight: "bold",
  },
  sectionDeleteIcon: {
    cursor: "pointer",
  },
  sectionDeleteIconVisibility: {
    visibility: "hidden",
  },
  bottomSection: {
    marginTop: 15,
  },
  plusIcon: {
    borderRadius: 5,
    padding: "10px 0px",
    textAlign: "center",
    textDecoration: "none",
    marginRight: 10,
  },
  sectionNextButton: {
    borderRadius: 5,
    textAlign: "center",
    textDecoration: "none",
    padding: "10px 25px",
  },
  backScreen: {
    cursor: "pointer",
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    margin:'15px 0px',
    left: '0%'
  },
} as const;
// Customizable Area End

export class TeacherAssessmentCreateDetail extends TeacherAssessmentCreateDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const {
      classList,
      divisionList,
      subjectList,
      title,
      classId,
      divisionId,
      subjectId,
      examType,
      assessment_category_id,
      maxMark,
      terms,
      includeNagativeMark,
      difficultyLevel,
      totalTime,
      sectionList,
      error,
      termList,
      categoryList
    } = this.state;

    return (
      <>
        {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
        <Box display="flex" alignItems="center" justifyContent="center" className="CreateAssessmentBox">
          <Box
            id="create-assessment-back-btn"
            style={styles.backScreen}
            onClick={() => {
              
              const assessmentData = localStorage.getItem("assessmentData");
              const data = JSON.parse(assessmentData || "{}");
              if (data.created_through === "library") {
                this.handleBackToLibrary(data);
              } else {
                this.setState({
                  warningTitle: 'Go Back?',
                  warningSubTitle: 'Assessment data will reset!!!',
                  warningParagraph: 'Saved assessment will be discarded.',
                  changeType: 'go_back',
                  openWarningModal: true,
                })
              }
            }
            }
          >
            <KeyboardBackspaceIcon className={this.props.classes.icon_color} />
            <Typography
              className={`CreateNewAssessmentText ${this.props.classes.bodyText_font}`}
            >Create New Assessment</Typography>
          </Box>
        </Box>
        <Box style={styles.assetmentDetailsWraper}>
          <Box>
            <Box mb={1}>
              <Typography
                className={`AssessmentDetailText ${this.props.classes.heading_font}`}>
                Assessment Details
              </Typography>
            </Box>
            <Box className="AssessmentTitleBox">
              <label style={styles.labelText} className={this.props.classes.subHeading_font}>Assessment Title</label>
              <input
                style={
                  error.title
                    ? { ...styles.inputText, ...styles.error }
                    : { ...styles.inputText }
                }
                type="text"
                name="title"
                value={title}
                placeholder="Enter assessment title here.."
                className={this.props.classes.bodyText_font}
                onChange={this.handleChange}
              />
              {error.title && (
                <FormHelperText className="CreateAssessmentErrorText">
                  Please enter assessment title
                </FormHelperText>
              )}
            </Box>
            <Grid container spacing={4}>

              <Grid item xs={12} sm={3} md={3} lg={3}>
                <label style={styles.labelText} className={this.props.classes.subHeading_font}>Subject</label>
                <Box className="AssessmentRelativeDiv">
                  <select
                    id="create-assessment-subject"
                    value={this.state.subjectId ? this.state.subjectId : ""}
                    name="subjectId"
                    style={
                      error.subjectId
                        ? { ...styles.inputText, ...styles.error }
                        : { ...styles.inputText }
                    }
                    onChange={(event: any) => {
                      if (this.state.assessmentHasQuestions) {
                        this.setState({
                          warningTitle: 'Questions will be deleted!!!',
                          warningSubTitle: 'Are you sure you want to change the subject ?',
                          warningParagraph: 'Question added in sections will be deleted.',
                          changeType: 'subject',
                          changeEventName: event.target.name,
                          changeEventValue: event.target.value,
                          openWarningModal: true,
                        })
                      } else {
                        this.handleSubjectChange(event.target.name, event.target.value)
                      }
                    }}
                    className={this.props.classes.bodyText_font}
                  >
                    <option value="" disabled>
                      Select subject
                    </option>
                    {subjectList.length != 0 &&
                      subjectList.map((subjectItem: any) => (
                        <option value={subjectItem.id}>
                          {subjectItem?.attributes?.subject_name}
                        </option>
                      ))}
                  </select>
                  {error.subjectId && (
                    <FormHelperText className="CreateAssessmentErrorText">
                      Please select subject
                    </FormHelperText>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3}>
                <label style={styles.labelText} className={`xs_mt_0 ${this.props.classes.subHeading_font}`}>Class</label>
                <Box className="AssessmentRelativeDiv">
                  <select
                    id="create-assessment-class-list"
                    value={this.state.classId ? this.state.classId : ""}
                    name="classId"
                    style={
                      error.classId
                        ? { ...styles.inputText, ...styles.error }
                        : { ...styles.inputText }
                    }
                    onChange={(event: any) => {
                      if (this.state.assessmentHasQuestions) {
                        this.setState({
                          warningTitle: 'Questions will be deleted!!!',
                          warningSubTitle: 'Are you sure you want to change the class ?',
                          warningParagraph: 'Question added in sections will be deleted.',
                          changeType: 'class',
                          changeEventName: event.target.name,
                          changeEventValue: event.target.value,
                          openWarningModal: true,
                        })
                      } else {
                        this.handleClassChange(event.target.name, event.target.value)
                      }
                    }}
                    className={this.props.classes.bodyText_font}
                  >
                    <option value="" disabled>
                      Select class
                    </option>
                    {classList.length != 0 &&
                      classList.map((classItem: any) => (
                        <option value={classItem.id}>
                          {classItem?.attributes?.name}
                        </option>
                      ))}
                  </select>
                  {error.classId && (
                    <FormHelperText className="CreateAssessmentErrorText">
                      Please select class
                    </FormHelperText>
                  )}
                </Box>
              </Grid>


              <Grid item xs={12} sm={3} md={3} lg={3}>
                <label style={styles.labelText} className={`xs_mt_0 ${this.props.classes.subHeading_font}`}>Exam Type</label>
                <Box className="AssessmentRelativeDiv">
                  <select
                    value={examType}
                    name="examType"
                    style={
                      error.examType
                        ? { ...styles.inputText, ...styles.error }
                        : { ...styles.inputText }
                    }
                    onChange={this.handleChange}
                    className={this.props.classes.bodyText_font}
                  >
                    <option value="" disabled>Select exam type</option>
                    <option value="formative">Formative</option>
                    <option value="summative">Summative</option>
                    <option value="practice_paper">Practice Paper</option>
                  </select>
                  {error.examType && (
                    <FormHelperText className="CreateAssessmentErrorText">
                      Please select exam type
                    </FormHelperText>
                  )}
                </Box>
              </Grid>            

               {/* Assessment Category start */}
               <Grid item xs={12} sm={3} md={3} lg={3}>
                <label style={styles.labelText} className={`xs_mt_0 ${this.props.classes.subHeading_font}`}> Category</label>
                <Box className="AssessmentRelativeDiv">
                  <select
                    value={assessment_category_id ? assessment_category_id : ""}
                    name="assessment_category_id"
                    style={
                      error.assessment_category_id
                        ? { ...styles.inputText, ...styles.error }
                        : { ...styles.inputText }
                    }
                    onChange={this.handleChange}
                    className={this.props.classes.bodyText_font}
                  >
                    <option value="" disabled>Select category</option>
                    {categoryList.length != 0 &&
                      categoryList.map((categoryItem: any) => (
                        <option value={categoryItem.id}>
                          {categoryItem?.attributes?.name}
                        </option>
                      ))}
                  </select>
                  {error.assessment_category_id && (
                    <FormHelperText className="CreateAssessmentErrorText">
                      Please select categroy type
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              {/* Assessment Category end */}
              
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <label style={styles.labelText} className={`xs_mt_0 ${this.props.classes.subHeading_font}`}>Max Marks</label>
                <input
                  style={
                    error.maxMark
                      ? { ...styles.numberText, ...styles.error }
                      : { ...styles.numberText }
                  }
                  type="number"
                  name="maxMark"
                  value={maxMark}
                  placeholder="Enter max mark"
                  onChange={this.handleChange}
                  className={this.props.classes.bodyText_font}
                />
                {error.maxMark && (
                  <FormHelperText className="CreateAssessmentErrorText">
                    Please enter max mark
                  </FormHelperText>
                )}
              </Grid>

         

              <Grid item xs={12} sm={3} md={3} lg={3}>
                <label style={styles.labelText} className={`xs_mt_0 ${this.props.classes.subHeading_font}`}>Terms</label>
                <Box className="AssessmentRelativeDiv">
                  <select
                    id="create-assessment-term-list"
                    value={this.state.terms ? this.state.terms : ""}
                    name="terms"
                    style={
                      error.terms
                        ? { ...styles.inputText, ...styles.error }
                        : { ...styles.inputText }
                    }
                    onChange={this.handleChange}
                    className={this.props.classes.bodyText_font}
                  >
                    <option value="" disabled>
                      Select Terms
                    </option>
                    {termList.length != 0 &&
                      termList.map((termItem: any) => (
                        <option value={termItem.id}>
                          {termItem?.attributes?.title}
                        </option>
                      ))}
                  </select>
                  {error.terms && (
                    <FormHelperText className="CreateAssessmentErrorText">
                      Please select terms
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <label style={styles.labelText} className={`xs_mt_0 ${this.props.classes.subHeading_font}`}>
                  Including Negative Marking?
                </label>
                <br />
                <FormControlLabel
                  style={styles.checkBoxStyle}
                  control={
                    <Checkbox
                      checked={includeNagativeMark}
                      onChange={this.handleChangeNegativeMarks}
                      name="includingNagativeMark"
                      color="primary"
                    />
                  }
                  className={this.props.classes.bodyText_font}
                  label="Yes"
                />
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Box mt={5} mb={1}>
              <Typography 
                className={`AssessmentDetailText ${this.props.classes.heading_font}`}
              >
                Question Paper Description
              </Typography>
            </Box>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <label style={styles.labelText} className={`xs_mt_0 ${this.props.classes.subHeading_font}`}>
                  <Box display="flex" alignItems="center" mt={1} mb={1}>
                    <img
                      src={imgDifficult}
                      className="AssessmentDetailImg"
                    />
                    Difficulty Level
                  </Box>
                </label>
                <select
                  value={difficultyLevel}
                  name="difficultyLevel"
                  onChange={this.handleChange}
                  style={styles.inputTextDifficultyLevel}
                  className={this.props.classes.bodyText_font}
                >
                  {examDifficulties?.map((item: any) => (
                    <option value={item.value} key={item.value}> {item.name} </option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3} className="AssessmentFlexColumn">
                <label style={styles.labelText} className={`xs_mt_0 ${this.props.classes.subHeading_font}`}>
                  <Box display="flex" alignItems="center" mt={1} mb={1}>
                    <img
                      src={imgTotalTime}
                      className="AssessmentDetailImg"
                    />
                    Total Time
                  </Box>
                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    autoOk
                    ampm={false}
                    defaultValue={"00:00"}
                    placeholder="--:--"
                    className={`no-underline ${this.props.classes.teacherAssessmentCreateDetail_AssessmentTest_time_duration_input}`}
                    inputProps={{
                      style: { fontSize: '23px', padding: '14px 16px', border: error.totalTime ? "1px solid red" : "1px solid #3f526d", borderRadius: 6, width: "50%", fontWeight: 600 },
                    }}
                    value={moment(totalTime, "HH:mm")}
                    onChange={(event: any) => this.setState({ totalTime: moment(event).format("HH:mm") })}
                  />
                </MuiPickersUtilsProvider>
                {error.totalTime && (
                  <FormHelperText className="CreateAssessmentErrorText">
                    Please enter time
                  </FormHelperText>
                )}
              </Grid>
            </Grid>

            <Box>
              <label style={styles.labelText} className={`xs_mt_0 ${this.props.classes.subHeading_font}`}>
                <Box display="flex" alignItems="center" mt={2.5} mb={2}>
                  <img
                    src={imgSections}
                    className="AssessmentDetailImg"
                  />
                  Sections
                </Box>
              </label>
              <div className="AssessmentOverflowAuto">
                <Box style={styles.sectionHeading}>
                  <Box className="width_100_per">
                    <div style={styles.sectionTitle} className={`section_title ${this.props.classes.teacherAssessmentCreateDetail_AssessmentTest_subHeading_font_imp}`}>
                      Section Name
                    </div>
                  </Box>
                  <Box className="width_100_per">
                    <div style={styles.sectionTitle} className={`section_title ${this.props.classes.teacherAssessmentCreateDetail_AssessmentTest_subHeading_font_imp}`}>
                      Section Marks
                    </div>
                  </Box>
                  <Box className="width_100_per">
                    <div style={styles.sectionTitle} className={`section_title ${this.props.classes.teacherAssessmentCreateDetail_AssessmentTest_subHeading_font_imp}`}>
                      Total Questions
                    </div>
                  </Box>
                  <Box className="width_100_per">
                    <div style={styles.sectionTitle} className={`section_title ${this.props.classes.teacherAssessmentCreateDetail_AssessmentTest_subHeading_font_imp}`}>
                      Time Limit (minutes)
                    </div>
                  </Box>
                  <Box style={styles.sectionDeleteIconVisibility}>
                    <DeleteIcon />
                  </Box>
                </Box>
                <div className="SectionListDiv">
                  {sectionList.map((section: any, index: number) => (
                    <Box style={styles.sectionHeading}>
                      <Box className="width_100_per">
                        <div style={styles.sectionInputData}>
                          <input
                            id="update-section-input"
                            style={section.error?.sectionName ? styles.inputTextWithBorderRed : styles.inputTextWithBorder}
                            className={`section_input ${section.error?.sectionName ? '' : this.props.classes.bodyText_font}`}
                            type="text"
                            name="name"
                            value={section.name}
                            placeholder="Enter Section Name"
                            onChange={(e: any) => this.handleUpdateSection(index, e)}
                          />

                          {section.error?.sectionName && (
                            <FormHelperText className="CreateAssessmentErrorText">
                              Please enter section name
                            </FormHelperText>
                          )}
                        </div>
                      </Box>
                      <Box className="width_100_per">
                        <div style={styles.sectionInputData}>
                          <input
                            id="update-section-input-marks"
                            style={section.error?.sectionMarks ? styles.inputTextWithBorderRed : styles.inputTextWithBorder}
                            className={`section_input ${section.error?.sectionMarks ? '' : this.props.classes.bodyText_font}`}
                            type="number"
                            name="section_marks"
                            value={section.section_marks}
                            placeholder="Enter Section Mark"
                            onChange={(e: any) => this.handleUpdateSection(index, e)}
                          />
                          {section.error?.sectionMarks && (
                            <>
                              {
                                section?.question_data
                                  ?
                                  (
                                    section?.question_data?.reduce((result: number, data: any) => result += data.marks, 0) > section.section_marks && (
                                      <FormHelperText className="CreateAssessmentErrorText">
                                        Minimum marks should be {section?.question_data?.reduce((result: number, data: any) => result += data.marks, 0)}.
                                      </FormHelperText>
                                    )
                                  )
                                  :
                                  <FormHelperText className="CreateAssessmentErrorText">
                                    Please enter section marks
                                  </FormHelperText>
                              }

                            </>

                          )}
                        </div>
                      </Box>
                      <Box className="width_100_per">
                        <div style={styles.sectionInputData}>
                          <input
                            id="update-section-input-Questions"
                            style={section.error?.sectionQuestion ? styles.inputTextWithBorderRed : styles.inputTextWithBorder}
                            className={`section_input ${section.error?.sectionQuestion ? '' : this.props.classes.bodyText_font}`}
                            type="number"
                            name="total_questions"
                            min={section?.question_data ? section?.question_data.length : 0}
                            value={section.total_questions}
                            placeholder="Enter Total Questions"
                            onChange={(e: any) => this.handleUpdateSection(index, e)}
                          />
                          {section.error?.sectionQuestion && (
                            <>
                              {
                                section?.question_data
                                  ?
                                  (
                                    section.total_questions < section?.question_data.length && (
                                      <FormHelperText className="CreateAssessmentErrorText">
                                        Please add at least {section?.question_data.length} question{section?.question_data.length > 1 && 's'}.
                                      </FormHelperText>
                                    )
                                  )
                                  :
                                  <FormHelperText className="CreateAssessmentErrorText">
                                    Please enter section total questions
                                  </FormHelperText>
                              }

                            </>
                          )}
                        </div>
                      </Box>
                      <Box className="width_100_per">
                        <div style={styles.sectionInputData}>
                          <input
                            id="update-section-input-time"
                            style={styles.inputTextWithBorder}
                            className={`section_input ${this.props.classes.bodyText_font}`}
                            type="number"
                            name="time_limit"
                            value={section.time_limit}
                            placeholder="Enter Time Limits"
                            onChange={(e: any) => this.handleUpdateSection(index, e)}
                          />
                        </div>
                      </Box>
                      <Box
                        id="assessment-delete-question"
                        style={styles.sectionDeleteIcon}
                        onClick={() => {
                          if (section?.question_data?.length > 0) {
                            this.setState({
                              warningTitle: 'Questions will be deleted!!!',
                              warningSubTitle: `Are you sure you want to delete ${section?.name}?`,
                              warningParagraph: `Question added in ${section?.name} will be deleted.`,
                              changeType: 'section',
                              sectionIdToDelete: section.id,
                              openWarningModal: true,
                            })
                          } else {
                            this.handleDeleteSection(section.id)
                          }
                        }}
                      >
                        <img src={imgDeleteGray} />
                      </Box>
                    </Box>
                  ))}
                </div>
              </div>
            </Box>

            <Box style={styles.bottomSection}>
              <Button id="add-questions" style={styles.plusIcon} className={this.props.classes.button_color} onClick={this.handleAddSection}>
                <AddIcon />
              </Button>
              <Button  id="next-button-click" style={styles.sectionNextButton} className={this.props.classes.button_color} onClick={this.onNext}>
                Next
              </Button>
            </Box>
          </Box>
        </Box>
        <WarningModal
          title={this.state.warningTitle}
          subTitle={this.state.warningSubTitle}
          paragraph={this.state.warningParagraph}
          warningModal={this.state.openWarningModal}
          onCloseModal={() => {
            this.setState({
              openWarningModal: false,
            });
          }}
          warningClickOk={() => {
            switch (this.state.changeType) {
              case 'subject':
                this.handleSubjectChange(this.state.changeEventName, this.state.changeEventValue)
                this.removeSectionQuestions()
                break;
              case 'class':
                this.handleClassChange(this.state.changeEventName, this.state.changeEventValue)
                this.removeSectionQuestions()
                break;
              case 'section':
                this.handleDeleteSection(this.state.sectionIdToDelete)
                break;
              case 'go_back':
                this.clearAssessmentAndGoBack()
                break;
            }
          }}
        />
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(TeacherAssessmentCreateDetail as React.ComponentType<any>, "TeacherAccount"));
// Customizable Area End
