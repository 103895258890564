// Customizable Area Start
import React from "react";
import {
  Box,
  Modal,
  Divider,
  Typography,
  Button,
  Grid,
  withStyles,
} from "@material-ui/core";
import { alertImg } from "../assets";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import "./QuestionBank.web.css";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

interface IProps {
  // Customizable Area Start
  alertModal: boolean;
  onCloseModal: any;
  onConfirmGoBack: any;
  alertTitle?: string;
  alertMessage: string;
  classes?:any;
  // Customizable Area End
}

export const QuestionBankAlertModalPopup: React.FC<IProps> = ({
  // Customizable Area Start
  alertModal,
  onCloseModal,
  onConfirmGoBack,
  alertTitle = "Alert!",
  alertMessage,
  classes
  // Customizable Area End
}) => {
  // Customizable Area Start
  return (
    <Modal
      open={alertModal}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="LibraryAlertModalBox"
      >
        <div className="ImgDiv">
          <img className="AlertImage" src={alertImg} alt="" />
        </div>
        <div className={`TitleDiv ${classes.title_font}`}>{alertTitle}</div>
        <Divider />

        <Typography
          className={`TitleText ${classes.subTitle_font}`}
        >
          {alertMessage}
        </Typography>

        <Grid className="FlexCenter">
          <Button
            className={`Button ${classes.button_color}`}
            onClick={onCloseModal}
          >
            Cancel
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            className={`Button ${classes.outline_button}`}
            onClick={onConfirmGoBack}
          >
            Go Back
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
  // Customizable Area Start
};

// Customizable Area Start
export default withStyles(themeCreateStyle)(QuestionBankAlertModalPopup);
// Customizable Area End
