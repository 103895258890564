
// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import EvaluationStepper from './EvaluationStepper.web';
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
interface Props {
    // Customizable Area Start
    questionData: any;
    isTeacher: boolean;
    textFieldType: string;
    isPercentageType?: boolean;
    isEmojiType?: boolean;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
const uuidv4 = require("uuid/v4");

export const RatingScaleQuestionType = ({
    questionData,
    isTeacher,
    textFieldType,
    isPercentageType,
    isEmojiType,
    classes,
}: Props) => {
    const [stepperActiveStep, setStepperActiveStep] = useState<number>(0);
    const [stepsItems, setStepsItems] = useState<any[]>([]);
    const [correctAnswer, setCorrectAnswer] = useState<string>("");

    useEffect(() => {
        const stepsArray: any = [];
        questionData?.answers?.map((data: any, index: number) => {
            if (Number(data.id) === Number(questionData?.selected_options?.data?.attributes?.answer_ids?.[0])) {
                setStepperActiveStep(index)
            }
            stepsArray.push({
                id: Number(data?.id),
                uniqueId: uuidv4(),
                textValue: data?.answer,
                right_answer: data?.is_right,
                emoji: data?.emoji,
                attmptedId: Number(questionData?.selected_options?.data?.attributes?.answer_ids?.[0]),
            })
            if (data?.is_right) {
                setCorrectAnswer(data.answer)
            }
            setStepsItems(stepsArray)
        }
        );
    }, [])

    return (
        <Grid container>
            <Grid item xs={12} className="rating-scale-stepper">
                <EvaluationStepper
                    activeStep={stepperActiveStep}
                    steps={stepsItems}
                    textFieldType={textFieldType}
                    isPercentageType={isPercentageType}
                    isEmojiType={isEmojiType}
                />
            </Grid>
            <div className="correct_answer_section">
                <Grid container>
                    <Grid item xs={12} sm={6} md={8} className="correct_answer_left">
                        {!questionData?.selected_options?.data &&
                            <Typography className={`not_attemped_text ${classes.bodyText_font}`}>Not attempted</Typography>
                        }
                        <Typography className={`correct_answer_text ${classes.heading_font}`}>Correct Answer</Typography>
                        <TextField
                            id="standard-basic"
                            placeholder=""
                            className="evaluation-rating"
                            variant="outlined"
                            value={correctAnswer}
                            disabled
                            inputProps={{
                                className: `rating-text-field-evaluation ${classes.heading_font}`,
                            }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className="score_section">
                        <Typography className={`correct_answer_text ${classes.heading_font}`}>Score</Typography>
                        <div className="correct_answer_rectangle">
                            <Typography className="score_text">
                                {questionData?.selected_options?.data?.attributes?.score ? questionData?.selected_options?.data?.attributes?.score : 0}
                            </Typography>
                        </div>
                        <Typography className="score_text"> / {questionData?.marks}</Typography>
                    </Grid>
                    {(!isTeacher && questionData?.selected_options?.data?.attributes?.comments) &&
                        <Grid item xs={12}>
                            <Typography className={`title_teachercomment ${classes.theme_font}`}>Teacher Comment:
                                <span className={`textlabel_teachercomment ${classes.heading_font}`} dangerouslySetInnerHTML={{
                                    __html: questionData?.selected_options?.data?.attributes?.comments,
                                }}></span></Typography>
                        </Grid>
                    }
                </Grid>
            </div>
        </Grid>
    );
};
// Customizable Area End

// Customizable Area Start

export default withStyles(themeCreateStyle)(RatingScaleQuestionType);
// Customizable Area End
