// Customizable Area Start
import React, { useState } from "react";
import {
  Box,
  Grid,
  Modal,
  Typography,
  Menu,
  MenuItem,
  Avatar,
} from "@material-ui/core";
import {
  delete_outline_black,
  restart_alt_black,
  other_options_icon,
} from "./assets";
import { Close } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End

// Customizable Area Start
const menuIcon = {
  width: "20px",
  height: "20px",
  objectFit: "contain",
} as const;

const loader = {
  color: "blue",
  height: "30px",
  width: "30px",
  margin: "auto",
} as const;

const file_name_div = {
  fontFamily: "Open sans",
  fontSize: "24px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "center",
  color: "#3f526d",
  padding: "2px 44px 4px 44px",
} as const;
// Customizable Area End
interface Props {
  // Customizable Area Start
  onCloseModal: any;
  viewDocument: any;
  anchorEl: any;
  open: any;
  onMenuClose: any;
  otherOptionsClick: any;
  reUploadClick: any;
  deleteClick: any;
  closeClick: any;
  // Customizable Area End
}

export const ViewDocumentModal = ({
  // Customizable Area Start
  onCloseModal,
  viewDocument,
  anchorEl,
  open,
  onMenuClose,
  otherOptionsClick,
  reUploadClick,
  deleteClick,
  closeClick,
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  const [isLoaded, setIsLoaded] = useState(false);

  const handleFileLoad = () => {
    setIsLoaded(true);
  };

  const handleClose = () => {
    onCloseModal();
    setIsLoaded(false);
  };

  return (
    <Modal
      open={viewDocument.name}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="viewModal">
        <Box display="flex" flexDirection="column" height="100%">
          <div className="ViewDocHeadImgDiv">
            <img
              src={viewDocument?.imgSource}
              className="ViewDocHeadImg"
            />

            <Menu
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={onMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Box className="ViewDocOptionBox">
                {
                  viewDocument.isReviewed !== "approved" &&  <MenuItem
                  className="ViewDocOptionMenu"
                  onClick={reUploadClick}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={4} className="ViewDocOptionFlexBasis">
                      <Avatar
                        src={restart_alt_black}
                        variant="square"
                        style={menuIcon}
                      />
                    </Grid>
                    <Grid item xs={8} className="ViewDocOptionFlexBasis">
                      <Typography className="ViewDocOptionText">
                        Re Upload
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
                }
                {
                  viewDocument.isReviewed !== "approved" && <MenuItem onClick={deleteClick}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} className="ViewDocOptionFlexBasis">
                      <Avatar
                        src={delete_outline_black}
                        variant="square"
                        style={menuIcon}
                      />
                    </Grid>
                    <Grid item xs={8} className="ViewDocOptionFlexBasis">
                      <Typography className="ViewDocOptionText">
                        Delete
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
                }
                <MenuItem onClick={closeClick}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} className="ViewDocOptionFlexBasis">
                      <Close className="ViewDocCloseOption" />
                    </Grid>
                    <Grid item xs={8} className="ViewDocOptionFlexBasis">
                      <Typography className="ViewDocOptionText">
                        Close
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              </Box>
            </Menu>
          </div>

          <div className="ViewDocOptionsDiv">
            <img
              src={other_options_icon}
              onClick={otherOptionsClick}
              className="ViewDocOptions"
            />
          </div>
          <div className="ViewDocStatusDiv"
            style={{          
              backgroundColor:
                viewDocument.isReviewed === "approved"
                  ? "#4caf50"
                  : viewDocument.isReviewed === "rejected"
                  ? "rgb(179 3 55)"
                  : "#ffb84d",           
            }}
          >
            <Typography className="ViewDocStatusText">
              {viewDocument.isReviewed === "approved"
                ? "Approved"
                : viewDocument.isReviewed === "rejected"
                ? "Rejected"
                : "Under Review"}
            </Typography>
          </div>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            width="95%"
            height="70%"
            className="ViewDocImageDiv"
          >
            {!isLoaded && (
              <div className="ViewDocLoader">
                <CircularProgress style={loader} />
              </div>
            )}
            {viewDocument?.upload_type === "image/jpeg" ||
            viewDocument?.upload_type === "image/jpg" ||
            viewDocument?.upload_type === "image/png" ? (
              <img
                className="viewModalImg"
                onLoad={() => handleFileLoad()}
                src={viewDocument?.doc_attachment}
              />
            ) : (
              <embed
                type="text/pdf"
                src={viewDocument?.doc_attachment}
                onLoad={() => handleFileLoad()}
                height="300px"
                width="100%"
              />
            )}
          </Box>
          <div id="fileNameText" style={file_name_div}>
            {viewDocument?.fileName
              ? viewDocument?.fileName
              : viewDocument?.name}
          </div>
        </Box>
      </Box>
    </Modal>
  );
  // Customizable Area End
};
// Customizable Area Start
export default ViewDocumentModal;
// Customizable Area End