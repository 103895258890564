Object.defineProperty(exports, "__esModule", {
  value: true,
});

const urlConfig = require("../../../../framework/src/config");

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.getTeachers = "/bx_block_scheduling/members/get_teachers";
exports.getStudents = "/bx_block_scheduling/members/get_students";
exports.getTeachersByClassAndYear =
  "/bx_block_scheduling/classes/get_class_teachers";
exports.getStudentsByClassAndYear =
  "/bx_block_scheduling/classes/get_class_students";
exports.getTeachersEndPoint = "/account_block/get_teachers";
exports.getStudentEndPoint = "/account_block/get_students";
exports.getTeachersAndAdmiBySchool = "/account_block/get_teachers_and_admin";
exports.getDivisionEndPoint =
  "/bx_block_scheduling/school_classes/get_class_divisions";
exports.getStudentsByDivision =
  "/bx_block_scheduling/members/get_students_by_division";
exports.instituteURL = urlConfig.instituteURL;
exports.userURL = urlConfig.baseURL;

// Customizable Area End
