// Customizable Area Start
import React from "react";
import { Box, Grid, Modal, Button, Typography } from "@material-ui/core";
import { oval, checkSuccess } from "./assets";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface Props {
  // Customizable Area Start
  isTeacher: boolean;
  openFileUploadSuccessModal: boolean;
  onCloseModal: any;
  doneClick: any;
  // Customizable Area End
}

export const VideoUploadSuccessModal = ({
  // Customizable Area Start
  isTeacher,
  openFileUploadSuccessModal,
  onCloseModal,
  doneClick,
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  return (
    <>
      <Modal
        open={openFileUploadSuccessModal}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="VideoSuccessModal"
      >
        <Box className="video_success_modal">
          <Grid
            container
            className="VideoSuccessGrid"
          >
            <img
              src={oval}
              className="VideoSuccessOval"
            />

            <img src={checkSuccess} className="check_success_img" />
            <Typography className="video_uploaded_text">
              Video Uploaded Successfully
            </Typography>

            <Typography className="fill_profile_detail_text">
              Please fill all the profile details to complete profile.
            </Typography>

            <Button className="success_done_btn" onClick={doneClick}>
              Done
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
export default VideoUploadSuccessModal;
// Customizable Area End
