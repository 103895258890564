// Customizable Area Start
import React from 'react';
import './StudentAttemptSummary.web.css';
// Customizable Area End

// Customizable Area Start
export const configJSON = require('../config');

interface SAS {
  index: number;
  isMarked: boolean;
  isGreen: boolean;
  questionNumber: number;
  jumpQuestionHandler: any;
  classes: AnalyserNode;
}
// Customizable Area End
export const StudentAttemptSummary: React.FC<SAS> = (props) => {
  // Customizable Area Start
  return (
    <>
      <div>
        <div
          id={props.index!.toString()}
          className={`${
            props.isMarked
              ? 'numberoutline bg-yellow '
              : props.isGreen
              ? 'numberoutline green'
              : props.questionNumber === props.index
              ? 'selectNumberoutline'
              : 'numberoutline'
          }`}
          onClick={() => props.jumpQuestionHandler(props.index)}
        >
          {props.index}
        </div>
      </div>
    </>
  );
  // Customizable Area End
};

// Customizable Area Start
export default StudentAttemptSummary;
// Customizable Area End
