// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";

export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export interface Props {
    // Customizable Area Start
    navigation: any;
    selectedYear: string;
    classId: number;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    openModal: boolean;
    showSpinner:boolean;
    feedback: string | null;
    teacherAssociated: string | null;
    allTeacherMembers: any;
    studentMembers: any;
    allStudentMembers: any;
    queryTerm: string;
    changeview: boolean;
    viewAllTeachers: boolean;
    viewAllStudents: boolean;
    classId: number;
    selectAll: boolean;
    createSelected: any,
    searchedTeacherMembers: any;
    searchedStudentMembers: any;
    selectAllStudents: boolean;
    createSelectedStudents: any,
    rows: any,
    rowsPerPage: number,
    page: number,
    teacherMembersMeta: any,
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class StudentMembersController extends BlockComponent<Props, S, SS>{
    // Customizable Area Start
    apiGetTeachersListByYearAndClass: string = "";
    data:any = {};
    // Customizable Area End
    
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        
        this.getTeachersList = this.getTeachersList.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleSelectAllStudents = this.handleSelectAllStudents.bind(this)
        
        this.state = {
            openModal: false,
            feedback: "",
            teacherAssociated: "",
            allTeacherMembers: [],
            studentMembers: [],
            allStudentMembers: [],
            showSpinner:false,
            queryTerm: "",
            changeview: false,
            viewAllTeachers: false,
            viewAllStudents: false,   
            classId: 0,    
            selectAll: false,
            createSelected: [],
            searchedTeacherMembers: [],
            searchedStudentMembers: [],
            selectAllStudents: false,
            createSelectedStudents: [],
            rows: null,
            rowsPerPage: 12,
            page: 0,
            teacherMembersMeta: {},
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        this.userdata();
        this.getTeachersList();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener('willFocus', () => {
                this.getToken();
            });
        }
    }
    
    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const userdata = JSON.parse(user_data || '{}')
        console.log("user data", userdata)
        this.data = userdata;
    }

    getToken = () => {
        const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
        this.send(msg);
    }
   
    getTeachersList = () => {
        this.setState({
            showSpinner:true
        })
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem('token')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetTeachersListByYearAndClass = requestMessage.messageId;
        let apiEndPoint = configJSON.getTeachersAndAdmiBySchool + `?school_id=${this.data?.school_id}&page=${this.state.page + 1}&per=${this.state.rowsPerPage}`
        if(this.state.queryTerm) {
            apiEndPoint = apiEndPoint + `&search=${this.state.queryTerm}`;
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),         
            apiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.userURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            switch (apiRequestCallId) {                            
                case this.apiGetTeachersListByYearAndClass: 
                    if (responseJson != null) {
                        if (!responseJson.errors) {
                            this.setState({ allTeacherMembers: responseJson?.data ,teacherMembersMeta: responseJson?.meta}); 
                        } else {
                            this.parseApiErrorResponse(responseJson);
                        }
                    }
                    this.parseApiCatchErrorResponse(errorReponse);
                    break;  
                default:
                    break;
            }
        }
        this.setState({
            showSpinner:false
        })
        // Customizable Area End
    }

    // Customizable Area Start
    getRoleFromAccountType = (roleAccount: string) => {
        let memberRole: string = ""
        switch (roleAccount) {
            case "TeacherAccount":
                memberRole = "Teacher"
                break;
            case "StudentAccount":
                memberRole = "Student"
                break;
            case "ParentAccount":
                memberRole = "Parent"
                break;              
            case "AdminAccount" :
                memberRole = "Admin"
                break;
            case "EmailAccount":
                memberRole = "Teacher"
                break;    
            case "PrincipalAccount":
                memberRole = "Principal"
                break;   
            default:
                break;
        }
        return memberRole
    }

    handleCheckboxClick = (event: any, id: any) => {
        event.stopPropagation();
        console.log('event, id', event, id)
        const { createSelected, allTeacherMembers } = this.state;
        const selectedIndex = createSelected.indexOf(id);
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(createSelected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(createSelected.slice(1));
        } else if (selectedIndex === createSelected.length - 1) {
            newSelected = newSelected.concat(createSelected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                createSelected.slice(0, selectedIndex),
                createSelected.slice(selectedIndex + 1)
            );
        }
        console.log('newSelected', newSelected)
        this.setState({ createSelected: newSelected });
        if(newSelected?.length === allTeacherMembers?.length){
            this.setState({ selectAll: true })
        }else{
            this.setState({ selectAll: false })
        }      
    };

    handleSelectAll = () => {       
        const { createSelected } = this.state;
        let newSelected: any[] = [];
        newSelected = this.state.allTeacherMembers?.map((item: any) => {            
            return item.id;       
        })
        console.log('newSelected', newSelected)
        if(!this.state.selectAll){
            this.setState({ createSelected: newSelected }); 
        }else{
            this.setState({ createSelected: [] }); 
        }
           
        this.setState({ selectAll: !this.state.selectAll })
    }

    isSelected = (id: any) => {
        return !!this.state.createSelected.find((item: any) => item === id)
    }

    handleStudentCheckboxClick = (event: any, id: any) => {
        event.stopPropagation();
        console.log('event, id', event, id)
        const { createSelectedStudents, studentMembers } = this.state;
        const selectedIndex = createSelectedStudents.indexOf(id);
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(createSelectedStudents, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(createSelectedStudents.slice(1));
        } else if (selectedIndex === createSelectedStudents.length - 1) {
            newSelected = newSelected.concat(createSelectedStudents.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                createSelectedStudents.slice(0, selectedIndex),
                createSelectedStudents.slice(selectedIndex + 1)
            );
        }
        console.log('newSelected', newSelected)
        this.setState({ createSelectedStudents: newSelected });
        if(newSelected?.length === studentMembers?.length){
            this.setState({ selectAllStudents: true })
        }else{
            this.setState({ selectAllStudents: false })
        }      
    };

    handleSelectAllStudents = () => {       
        let newSelected: any[] = [];
        newSelected = this.state.studentMembers?.map((item: any) => {            
            return item.id;       
        })
        console.log('newSelected', newSelected)
        if(!this.state.selectAllStudents){
            this.setState({ createSelectedStudents: newSelected }); 
        }else{
            this.setState({ createSelectedStudents: [] }); 
        }
           
        this.setState({ selectAllStudents: !this.state.selectAllStudents })
    }

    isSelectedStudents = (id: any) => {
        return !!this.state.createSelectedStudents.find((item: any) => item === id)
    }
    // Customizable Area End
}