// Customizable Area Start
import React from "react";
import {
    Avatar,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    MenuItem,
    Select,
    Grid,
} from "@material-ui/core";
import {
    ArrowDropDown,
} from "@material-ui/icons";
import Datetime from "react-datetime";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import StudentAttendanceController, { Props } from "./StudentAttendanceController.web";
import {
    NoDataFound,
    subjectIcon
} from "./assets"
import Chart from "react-google-charts";
import "./TeacherAttendance.web.css"
import InputBase from "@material-ui/core/InputBase";
import Spinner from "../../../shared/SideBar/src/Spinner";
import SelectedTab from '../SelectedTab.web';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import "./StudentAttendance.web.css";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class StudentAttendance extends StudentAttendanceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            studentAttendanceList,
            studentAttendanceReport
        } = this.state;

        return (
            <>
                <Box className="StudentAttendanceSelectedTab">
                    <SelectedTab isTeacher={false} type={'Attendance'} />
                </Box>
                <Box
                    className="StudentAttendanceParentBox"
                >
                    {this.state.loadingSpinner && (
                        <Spinner spinnerModal={this.state.loadingSpinner} />
                    )}
                    <Box
                        className="FlexCol W100"
                    >
                        <Box className="Attendance-flex FlexCenter W100 Pb15">
                            <Box
                                onClick={this.handleTodayBtnClick}
                                className={`HeaderBox Attendance-header ${moment(this.state.date, 'DD MMMM, YYYY').isSame(moment(), 'day') ? this.props.classes.button_color : this.props.classes.border_Color}`}

                            >
                                <span className={`${this.props.classes.theme_font} ${moment(this.state.date, 'DD MMMM, YYYY').isSame(moment(), 'day') ? `attendance_ltoday` : `attendance_nottoday`}`}>
                                    Today
                                </span>
                            </Box>
                            <Box className="cal-mob FlexCenter">

                                <Datetime
                                    dateFormat={"DD MMMM, YYYY"}
                                    timeFormat={false}
                                    strictParsing={true}
                                    input={true}
                                    closeOnSelect={true}
                                    isValidDate={this.disableFutureDt}
                                    value={this.state.date}
                                    className="date-input-student"
                                    inputProps={{
                                        placeholder: "Date of Birth",
                                        className: "Date_of_birth_calendar_Icon_register",
                                        readOnly: true,
                                    }}
                                    onChange={this.calendarChangeHandler}
                                />
                            </Box>
                            <Box className="status StatusBox">
                                <div className="status-icon StatusIcons bgGreen"></div>
                                <span className={`${this.props.classes.heading_font} presentAbsent`}>Present</span>
                                <div className="status-icon StatusIcons bgRed"></div>
                                <span className={`${this.props.classes.heading_font} presentAbsent`}>Absent</span>
                                <div className="status-icon StatusIcons bgBlue"></div>
                                <span className={`${this.props.classes.heading_font} presentAbsent`}>Leave</span>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        className="FlexCol Mb20"
                    >
                        <TableContainer className="Attendance-table" >
                            <Table className="TableWidth" aria-label="simple table">
                                <TableHead className="">
                                    <TableRow>
                                        <TableCell className={`${this.props.classes.subHeading_font} TableCellStyle W40_Per Mr15`}>Subject Name</TableCell>
                                        <TableCell className={`${this.props.classes.subHeading_font} TableCellStyle W30_Per`} align="center">Attendance</TableCell>
                                        <TableCell className={`${this.props.classes.subHeading_font} TableCellStyle`} align="left">Teacher Comments</TableCell>

                                    </TableRow>
                                </TableHead>
                                {studentAttendanceList.length > 0 && studentAttendanceList.map((row: any, index: any) => {
                                    return (
                                        <>
                                            <Box className="h10"></Box>
                                            <TableBody>
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    className="TableRowstyle"
                                                >
                                                    <TableCell component="th" scope="row" className="TableCellPadding">
                                                        <Box className="FlexJustifyStart">
                                                            <Avatar src={row.image == null || row.image.length === 0 ? subjectIcon : row?.image?.file_path} variant="square" className="Avatar" />

                                                            <Typography
                                                                className={`${this.props.classes.bodyText_font} SubjectNameTypo`}
                                                            >
                                                                {row.subject_name}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>

                                                    <TableCell align="center" className="p10">
                                                        <Box className="FlexJustifyCenter">
                                                            <Box
                                                                className="FlexBoxCenter W35_Per"
                                                            >
                                                                <Box className={`${row.status === "Present" ? 'attendance_letter_p' : 'attendance_letter_table_outer_notselected_p'}`}>
                                                                    <span className={`${this.props.classes.theme_font} ${row.status === "Present" ? 'attendance_letter_table_selected' : 'attendance_letter_table_notselected_p'}`}>P</span>
                                                                </Box>
                                                                <Box className={`${this.props.classes.theme_font} ${row.status === "Absent" ? "attendance_letter_a" : "attendance_letter_table_outer_notselected_a"}`}>
                                                                    <span className={`${row.status === "Absent" ? 'attendance_letter_table_selected' : 'attendance_letter_table_notselected_a'}`}>A</span>
                                                                </Box>
                                                                <Box className={`${this.props.classes.theme_font} ${row.status === "Leave" ? 'attendance_letter_l' : 'attendance_letter_table_outer_notselected_l'}`}>
                                                                    <span className={`${row.status === "Leave" ? 'attendance_letter_table_selected' : 'attendance_letter_table_notselected_l'}`}>L</span>
                                                                </Box>

                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="left"
                                                        className={`${this.props.classes.bodyText_font} CommentBox`}
                                                    >
                                                        <div className="CommentTypo">
                                                            {row.comments}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </>
                                    )
                                })}
                            </Table>
                        </TableContainer>
                        {studentAttendanceList.length == 0 ? <div className={`${this.props.classes.theme_font} NoRecordFoundText`}>No record(s) found.</div> : <></>}
                    </Box>
                    {studentAttendanceReport && <>
                        <Box className="AttendanceReportGrph">
                            <Typography className={`graphtitle attendance_modale_graph_heading ${this.props.classes.heading_font}`}>
                                Attendance report graph
                            </Typography>
                        </Box>
                        <Box
                            className="AttendanceReportGrphMainBox"
                        >
                            <Box
                                className="FlexCenter W100"
                            >
                            </Box>
                            <Box
                                className="AttendanceReportInnerBox"
                            >
                                <Box className="graph-info FlexJustifyBetween">
                                    <Grid item lg={8} md={7} xs={6}></Grid>
                                    <Grid item lg={4} md={5} xs={6} className="Flex">
                                        <Box className="BoxMargin">
                                            <Select className="customized-select"
                                                labelId="demo-customized-select-label"
                                                id="demo-customized-select"
                                                classes={{
                                                    root: this.props.classes.TeacherAttendanceClassesAndSectionBootstrapRoot,
                                                    select: this.props.classes.TeacherAttendanceClassesAndSectionBootstrapInput
                                                }}
                                                disableUnderline
                                                IconComponent={ArrowDropDown}
                                                value={this.state.selectedMonth ? this.state.selectedMonth : moment().format("MMM").toLowerCase()}
                                                onChange={this.monthdropdownChangeHandler}
                                            >
                                                <MenuItem className="JustifyCenter" value="month" disabled>Select Month</MenuItem>
                                                <MenuItem className="JustifyCenter" value="jan">Jan</MenuItem>
                                                <MenuItem className="JustifyCenter" value="feb">Feb</MenuItem>
                                                <MenuItem className="JustifyCenter" value="mar">Mar</MenuItem>
                                                <MenuItem className="JustifyCenter" value="apr">Apr</MenuItem>
                                                <MenuItem className="JustifyCenter" value="may">May</MenuItem>
                                                <MenuItem className="JustifyCenter" value="jun">Jun</MenuItem>
                                                <MenuItem className="JustifyCenter" value="jul">Jul</MenuItem>

                                                <MenuItem className="JustifyCenter" value="aug">Aug</MenuItem>
                                                <MenuItem className="JustifyCenter" value="sep">Sep</MenuItem>
                                                <MenuItem className="JustifyCenter" value="oct">Oct</MenuItem>
                                                <MenuItem className="JustifyCenter" value="nov">Nov</MenuItem>
                                                <MenuItem className="JustifyCenter" value="dec">Dec</MenuItem>
                                            </Select>
                                        </Box>
                                        <Box className="Mt15">
                                            <div className={`year-menu ${this.props.classes.bodyText_font} year1`}>{`${moment().format("YYYY")}-${moment().add("1", "years").format("YY")}`}</div>
                                        </Box>
                                    </Grid>

                                </Box>


                                {studentAttendanceReport.present || studentAttendanceReport.absent || studentAttendanceReport.leave || studentAttendanceReport.weekend ? <Box
                                    className="ChartMainBox"
                                >
                                    <Box className="Chart ChartInnerBox"
                                    >
                                        <Chart
                                            width={"100%"}
                                            height={"400px"}
                                            className={`${this.props.classes.bodyText_font} PieChart`}

                                            chartType="PieChart"

                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                ["Task", "Hours per Day", { role: "tooltip", type: "string", p: { html: true }, textStyle: { color: 'black', fontName: 'Open sans', bold: true } }],
                                                ["Present", studentAttendanceReport.present, `Present: ${studentAttendanceReport.present} (${Number(studentAttendanceReport.present / (studentAttendanceReport.present + studentAttendanceReport.absent + studentAttendanceReport.leave + studentAttendanceReport.weekend) * 100).toFixed(2)}%)`],
                                                ["Absent", studentAttendanceReport.absent, `Absent: ${studentAttendanceReport.absent} (${Number(studentAttendanceReport.absent / (studentAttendanceReport.present + studentAttendanceReport.absent + studentAttendanceReport.leave + studentAttendanceReport.weekend) * 100).toFixed(2)}%)`],
                                                ["Leave", studentAttendanceReport.leave, `Leave: ${studentAttendanceReport.leave}  (${Number(studentAttendanceReport.leave / (studentAttendanceReport.present + studentAttendanceReport.absent + studentAttendanceReport.leave + studentAttendanceReport.weekend) * 100).toFixed(2)}%)`],
                                                ["Weekend", studentAttendanceReport.weekend, `Weekend: ${studentAttendanceReport.weekend}  (${Number(studentAttendanceReport.weekend / (studentAttendanceReport.present + studentAttendanceReport.absent + studentAttendanceReport.leave + studentAttendanceReport.weekend) * 100).toFixed(2)}%)`]

                                            ]}
                                            options={{
                                                colors: ["#3dbb3d", "#e02020", "#327095", "#e69416"],
                                                selectionMode: 'none',
                                                pieHole: 0.6,
                                                labels: 'none',
                                                legend: { position: 'labeled', labeledValueText: 'none', tooltip: 'value', textStyle: { color: "#3f526d", fontSize: "20px", fontStyle: 'Open sans', bold: true, }, style: { color: "#3dbb3d" } },
                                                pieSliceText: 'none',
                                                backgroundColor: 'transparent'

                                            }}
                                            rootProps={{ "data-testid": "4" }}
                                        />

                                    </Box>
                                    <Box className="Chart-info ChartInfoW"
                                    >
                                        <Box className="grap-status FlexJustifyBetween">
                                            <Box className="Flex">
                                                <Box className="Icons bgGreen StatusIcons2">
                                                </Box>
                                                <div className={`${this.props.classes.bodyText_font} attendance_under_present`}>Present</div>
                                            </Box>
                                            <div className={`${this.props.classes.bodyText_font} attendance_number`}>{studentAttendanceReport.present}</div>
                                        </Box>
                                        <Box className="grap-status FlexJustifyBetween Mt15">
                                            <Box className="Flex">
                                                <Box className="Icons bgRed StatusIcons2">
                                                </Box>
                                                <div className={`${this.props.classes.bodyText_font} attendance_under_present`}>Absent</div>
                                            </Box>
                                            <div className={`${this.props.classes.bodyText_font} attendance_number`}>{studentAttendanceReport.absent}</div>
                                        </Box>
                                        <Box className="grap-status FlexJustifyBetween Mt15">
                                            <Box className="Flex">
                                                <Box className="Icons bgBlue StatusIcons2">

                                                </Box>
                                                <div className={`${this.props.classes.bodyText_font} attendance_under_present`}>Leave</div>
                                            </Box>
                                            <div className={`${this.props.classes.bodyText_font} attendance_number`}>{studentAttendanceReport.leave}</div>
                                        </Box>
                                        <Box className="grap-status FlexJustifyBetween Mt15">
                                            <Box className="Flex">
                                                <Box className="Icons bgYellow StatusIcons2">

                                                </Box>
                                                <div className={`${this.props.classes.bodyText_font} attendance_under_present`}>Weekend </div>
                                            </Box>
                                            <div className={`${this.props.classes.bodyText_font} attendance_number`}>{studentAttendanceReport.weekend}</div>
                                        </Box>
                                    </Box>
                                </Box> : <Box className="NoTextBox"><img src={NoDataFound} className="NotFoundImg" />
                                    <div className={`${this.props.classes.theme_font} NoTextTypo`}>No record(s) found.</div></Box>}
                            </Box>
                        </Box>
                    </>}
                </Box>
            </>
        );
    }
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(StudentAttendance);
// Customizable Area End
