import React from "react";
 // Customizable Area Start
import { Grid, Modal, Button, Typography, TextField } from "@material-ui/core";
import "../ZoomMeeting/MeetingFeedback/MeetingFeedbackTwo.web.css";
import AdminFeedbackController from "./AdminFeedbackController.web";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
// import MeetingFeedbackSuccessPopup from "./"
import { audio, conectivity, teacher, vedio } from "../assets";
 // Customizable Area End
  // Customizable Area Start
   // Customizable Area End
export interface Props {
   // Customizable Area Start
  openModal: any;
  onCloseModal: any;
  reviewData: any;
  reviewComment: any;
   // Customizable Area End
}
interface S {
   // Customizable Area Start
    // Customizable Area End
}
interface SS {
   // Customizable Area Start
    // Customizable Area End
}

class BadResponsePopup extends BlockComponent<Props, S, SS> {
   // Customizable Area Start
    // Customizable Area End
    // Customizable Area Start
  render() {
    console.log("review data ", this.props.reviewData);
    
    // console.log("feedback res", this.state.feedbackResponse);

    const { openModal, onCloseModal, reviewData, reviewComment } = this.props;

    return (
      <>
        <Modal
          open={openModal}
          onClose={onCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          id="modal-feedback-bad-response"
          className="feedback-bad-response-modal"
        >
          <Grid
            style={{
              justifyContent: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "30px 30px",
              width: "500px",
            }}
            className="modal-width-90"
          >
            <div className="feedback-form-container">
              <div className="feedback-form-heading">
                <Typography
                  className="font-family-feedback"
                  variant="h5"
                  style={{
                    fontSize: "18px",
                    letterSpacing: "1.78px",
                    color: "#3f526d",
                    fontWeight: "bold",
                  }}
                >
                  Feedback
                </Typography>
                <hr style={{ border: "1px solid #d3defa", margin: "15px 0" }} />
              </div>
              {reviewData.length >0 && reviewData?.map((item: any, index: number) => (
                <div key={index} className="feedback-questions-container">
                  <div className="feedback-questions">
                    <div className="questions">
                      {/* <img
                        className="question-icons"
                        height={30}
                        width={30}
                        src={
                          item.question.toLowerCase().includes("video")
                            ? vedio
                            : item.question.toLowerCase().includes("audio")
                            ? audio
                            : item.question
                                .toLowerCase()
                                .includes("connectivity")
                            ? conectivity
                            : item.question.toLowerCase().includes("manner") &&
                              teacher
                        }
                        alt="na"
                      /> */}
                      <Typography
                        className="font-family-feedback"
                        variant="h4"
                        style={{
                          fontSize: "13px",
                          color: "#3f526d",
                          letterSpacing: "1.50px",
                          fontWeight: 500,
                        }}
                      >
                        {item.question}
                      </Typography>
                    </div>
                    <div className="answer-button">
                      <Button
                        disabled
                        //   onClick={() => this.handleSelectAnswer(index, "YES")}
                        className={`${
                          item.answer !== null && item.answer === "YES"
                            ? "active-btn"
                            : "inactive-btn"
                        } btn`}
                      >
                        YES
                      </Button>
                      <Button
                        disabled
                        //   onClick={() => this.handleSelectAnswer(index, "NO")}
                        className={`${
                          item.answer !== null && item.answer === "NO"
                            ? "active-btn"
                            : "inactive-btn"
                        } btn`}
                      >
                        NO
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              {reviewComment.length >0 && reviewComment?.map((item: any, index: number) => {
                <div className="feedback-questions-container">
                  <div className="feedback-questions">
                  <div className="questions">
                  <Typography
                        className="font-family-feedback"
                        variant="h4"
                        style={{
                          fontSize: "13px",
                          color: "#3f526d",
                          letterSpacing: "1.50px",
                          fontWeight: 500,
                          marginBottom: "10px",
                        }}
                      >
                        Student Comment
                  </Typography>
                      <Typography
                        className="font-family-feedback"
                        variant="h3"
                        style={{
                          fontSize: "10px",
                          color: "#3f526d",
                          letterSpacing: "1px",
                          fontWeight: 400,
                        }} >
                    {item.attributes.comment}
                  </Typography>
                </div>
                </div>
                </div>
              })}
            </div>
          </Grid>
        </Modal>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default BadResponsePopup;
// Customizable Area End
