// Customizable Area Start
import React from "react";
import { Grid, Modal, Button, Typography, TextField } from "@material-ui/core";
import "../ZoomMeeting/MeetingFeedback/MeetingFeedbackTwo.web.css";
import AdminAssessmentReportController from "./AdminAssessmentReportController.web";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
// import MeetingFeedbackSuccessPopup from "./"
import { audio, conectivity, teacher, vedio } from "../assets";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  openModal: any;
  onCloseModal: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  // Customizable Area End

}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

class BadResponsePopup extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  render() {
    // console.log("feedback res", this.state.feedbackResponse);

    const { openModal, onCloseModal } = this.props;

    return (
      <>
        <Modal
          open={openModal}
          onClose={onCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          id="modal-bad-response"
          className="bad-response-modal"
        >
          <Grid
            style={{
              justifyContent: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "30px 30px",
              width: "500px",
            }}
            className="modal-width-90"
          >
            <div className="feedback-form-container">
              <div className="feedback-form-heading">
                <Typography
                  className="font-family-feedback"
                  variant="h5"
                  style={{
                    fontSize: "18px",
                    letterSpacing: "1.78px",
                    color: "#3f526d",
                    fontWeight: "bold",
                  }}
                >
                  Feedback
                </Typography>
                <hr style={{ border: "1px solid #d3defa", margin: "15px 0" }} />
              </div>
            </div>
          </Grid>
        </Modal>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default BadResponsePopup;
// Customizable Area End
