// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  questionsList: any[];
  selectedQuestionObj: any;
  selectedQuestionId: any;
  query: string;
  assessmentId: any;
  showLoader: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
const questionData: any[] = [
  {
    id: 1,
    question: "It is a long Established fact that a reader will be distracted?",
  },
  {
    id: 2,
    question: "When an unknown printer took a gallery of type and scrambled?",
  },
  {
    id: 3,
    question: "It is a long Established fact that a reader will be distracted?",
  },
  {
    id: 4,
    question: "It is a long Established fact that a reader will be distracted?",
  },
  {
    id: 5,
    question: "It is a long Established fact that a reader will be distracted?",
  },
  {
    id: 6,
    question: "It is a long Established fact that a reader will be distracted?",
  },
];
// Customizable Area End
export default class StudentAnswersheetQueryResolutionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuestionListId: string = "";
  apiSubmitQuery: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      questionsList: [],
      selectedQuestionId: "",
      selectedQuestionObj: null,
      query: "",
      assessmentId: "",
      showLoader: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const { assessmentId } = HISTORY?.location?.state;

    if (assessmentId) {
        this.setState({ assessmentId: assessmentId }, () => this.get_question_list())
    }
  }

  handleSelectQuestion = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    const selectedQuestion = this.state.questionsList.find(
      (item: any) => item.id === parseInt(id)
    );
    this.setState({
      selectedQuestionId: parseInt(id),
      selectedQuestionObj: selectedQuestion,
    });
  };

  handleSubmitQuery = (e: any) => {
    e.preventDefault();
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id,
    };

    this.setState({ showLoader: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSubmitQuery = requestMessage.messageId;
    const httpBody = {
      assessment_id: Number(this.state.assessmentId),
      question_id: Number(this.state.selectedQuestionId),
      query: this.state.query,
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createQueriesEndPoint 
    );
    requestMessage.addData( 
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getQuestionListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ questionsList: responseJson?.data, showLoader: false })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiSubmitQuery) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ showLoader: false })
            toast.success('Query submitted successfully.')
            HISTORY.push('/StudentAssessment')
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  get_question_list = () => {
    this.setState({ showLoader: true });
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentQuestionEndPoint + `${this.state.assessmentId}/assessment_question`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
