// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import {profile_logo} from "../assets";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
}),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    }
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

function createData(title: string, term: number, term2: number, term3: number) {
  return { title, term, term2, term3 };
}

const rows = [
  createData('Remarks of Class Teacher & Sign.', 20, 10, 5),
  createData('Principle', 20, 10, 4.0),
  createData("Parent's Signature'", 20, 10, 4.0)
];
// Customizable Area End


// Customizable Area Start
export default function ProfileDetails() {
  const classes = useStyles();
  const [selectclass, setSelectclass] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date('2014-08-18T21:11:54'),
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectclass(event.target.value as string);
  };
  
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  return (
    <div>
      <div className='profileTitle'>
      <Button className="backBtn"><KeyboardBackspaceOutlinedIcon /> Back</Button>
      <h2>Profile Details</h2>
      <Button className="editBtn commonBtnSecondary">Enable Edit <EditOutlinedIcon /> </Button>
      </div>
      <div className='profileContent'>
        <div className='topHeader'>
          <img src={profile_logo} />
          <div className='schoolTitle'>
            <h3>Lorem Vidhyasharam School</h3>
            Lorem Ipsum is simply dummy text of the printing and industry
          </div>
          <Button className="commonBtnPrimary">Report Card - Class 1</Button>
        </div>
       <div className="studentProfile">
         <div className="profileForm">
         <form className={classes.root} noValidate autoComplete="off">
      <div className="formGroup full">
        <div>
        <label>Name</label>
        <TextField
          type="text"
          placeholder='Name'
          variant="outlined"
        /> 
        </div>
      </div>
      <div className="formGroup threeCol">
        <div>
          <label>Class</label>
          <Select
            labelId="demo-simple-select-label"
            value="Class"
            variant="outlined"
            onChange={handleChange}
          >
            <MenuItem value={"Class 1"}>Class 1</MenuItem>
            <MenuItem value={"Class 2"}>Class 2</MenuItem>
            <MenuItem value={"Class 3"}>Class 3</MenuItem>
          </Select>
        </div>
        <div>
          <label>Section</label>
          <Select
            labelId="demo-simple-select-label"
            value="Section"
            variant="outlined"
            onChange={handleChange}
          >
            <MenuItem value={"Section 1"}>Section 1</MenuItem>
            <MenuItem value={"Section 2"}>Section 2</MenuItem>
            <MenuItem value={"Section 3"}>Section 3</MenuItem>
          </Select>
        </div>
        <div>
          <label>Admission No.</label>
          <TextField
            type="text"
            placeholder="Wing"
            variant="outlined"
          />
        </div>
        <div>
          <label>Roll No.</label>
          <TextField
            type="text"
            placeholder="Roll No."
            variant="outlined"
          />
        </div>         
      </div>
      <div className="formGroup twoCol">
        <div>
          <label>Mother's Name</label>
          <TextField
            type="text"
            placeholder="Mother's Name"
            variant="outlined"
          /> 
        </div>
        <div>
          <label>Father's / Guardian's Name</label>
          <TextField
            type="text"
            placeholder="Father's / Guardian's Name"
            variant="outlined"
          /> 
        </div>
      </div>
    </form>
         </div>
    </div>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Attendance</StyledTableCell>
            <StyledTableCell align="right">Term 1</StyledTableCell>
            <StyledTableCell align="right">Term 2</StyledTableCell>
            <StyledTableCell align="right">Term 3</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.title}>
              <StyledTableCell component="th" scope="row">
                {row.title}
              </StyledTableCell>
              <StyledTableCell align="right">{row.term}</StyledTableCell>
              <StyledTableCell align="right">{row.term2}</StyledTableCell>
              <StyledTableCell align="right">{row.term3}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <div>
      General Rules:
    </div>
    </div>
    </div>
  )
}
// Customizable Area End
