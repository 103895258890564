import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  Link,
  MenuItem,
  Select,
  Button,
  NativeSelect,
  TextField,
  Typography
} from "@material-ui/core";
import { Platform, Alert } from "react-native";
import { Message } from "../../../framework/src/Message";
import { cancel } from './assets';
interface Props {
  navigation: any;
}

interface S {

  title: string;
  body: string;
  btnPositiveText: string;
  btnNegativeText: string;
  btnNeutralText: string;

}

interface SS { }

export default class AlertBlock extends BlockComponent<Props, S, SS> {

  messagePositiveButton: any
  messageNegativeButton: any
  messageNeutralButton: any


  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

    ]
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      title: "",
      body: "",
      btnPositiveText: "",
      btnNegativeText: "",
      btnNeutralText: "",
    }

    this.GetTitleView = this.GetTitleView.bind(this);
    this.GetMainView = this.GetMainView.bind(this);

    this.receive = this.receive.bind(this);
  }


  render() {

    return (




      (this.state.title || this.state.body || this.state.btnNeutralText || this.state.btnNegativeText || this.state.btnPositiveText)
        ?
        <div className="Inner-Alert" style={{
          padding: "30px 30px 38px",
          borderRadius: 8,
          boxShadow: "0 0 24px 4px rgba(0, 0, 0, 0.2)",
          color: " #3f526d",
        }}>
          <this.GetMainView></this.GetMainView>
        </div>
        : null




    );
  }



  onNegativeButtonPress() {
    if (this.messageNegativeButton) {

      this.send(this.messageNegativeButton)

    } else {
      this.props.navigation.goBack();
    }
  }

  onPositiveButtonPress() {
    if (this.messagePositiveButton) {

      this.send(this.messagePositiveButton)

    } else {
      this.props.navigation.goBack();
    }
  }
  onNeutralButtonPress() {
    if (this.messageNeutralButton) {

      this.send(this.messageNeutralButton)

    } else {
      this.props.navigation.goBack();
    }
  }


  GetTitleView() {

    return (

      this.state.title ?
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <Typography style={{
              fontSize: 26,
              fontWeight: 600,
              color: "#3f526d",
            }}>
              {this.state.title}
            </Typography>
          </div>
          <img src={cancel} style={{ width: 24, height: 24, cursor: "pointer" }}
            onClick={() => {
              this.onNeutralButtonPress()
            }} />
        </div> : null

    );
  }


  GetMainView() {

    return (

      <View
        style={styles.modalParentContainer}
      >


        <this.GetTitleView></this.GetTitleView>

        <Typography className="otptext" style={{
          fontFamily: "Open sans",
          fontSize: 22,
          fontWeight: 600,
          lineHeight: 1,
          marginTop: 40,
        }}>{this.state.body}</Typography>


        {(this.state.btnPositiveText || this.state.btnNegativeText || this.state.btnNeutralText) ? <View style={styles.allButtonContainer}>



          {
            //Neutral Button

            this.state.btnNeutralText ?
              <div className="ok-btn" style={{
                marginTop: 40,
              }}>
                <Button
                  onClick={() => {
                    this.onNeutralButtonPress()
                  }}
                  style={{
                    backgroundColor: "#7f6cfc",
                    padding: "12px 49px 12px 50px",
                    borderRadius: 8,
                    color: "#fff",
                    fontSize: 18,
                    fontWeight: 600,
                    fontFamily: "Open sans"
                  }}
                >
                  {this.state.btnNeutralText}
                </Button>
              </div>
              : null
          }



          {
            //Positive & Negative Button Container


            (this.state.btnNegativeText || this.state.btnPositiveText) ?

              <View style={styles.twoButtonContainer}>

                {
                  this.state.btnNegativeText ?
                    <div style={{
                      marginTop: 40,
                    }}>
                      <Button
                        onClick={() => {
                          this.onNegativeButtonPress()
                        }}
                        style={{
                          backgroundColor: "#7f6cfc",
                          padding: "12px 49px 12px 50px",
                          borderRadius: 8,
                          color: "#fff",
                          fontSize: 18,
                          fontWeight: 600,
                          fontFamily: "Open sans"
                        }}
                      >
                        {this.state.btnNegativeText}
                      </Button>
                    </div> : null
                }
                {

                  this.state.btnPositiveText ?
                    <div style={{
                      marginTop: 40,
                    }}>
                      <Button
                        onClick={() => {
                          this.onPositiveButtonPress()
                        }}
                        style={{
                          backgroundColor: "#7f6cfc",
                          padding: "12px 49px 12px 50px",
                          borderRadius: 8,
                          color: "#fff",
                          fontSize: 18,
                          fontWeight: 600,
                          fontFamily: "Open sans"
                        }}
                      >
                        {this.state.btnPositiveText}
                      </Button>
                    </div> : null

                }


              </View>

              : null


          }


        </View>

          : null}


      </View>




    );

  }


  async receive(from: string, message: Message) {

    if (getName(MessageEnum.AlertMessage) === message.id) {
      this.navigateToAlertPage(message);
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {

      let alertType = message.getData(getName(MessageEnum.AlertTypeMessage));
      let title = message.getData(getName(MessageEnum.AlertTitleMessage));
      let body = message.getData(getName(MessageEnum.AlertBodyMessage));

      let btnPositiveText = message.getData(getName(MessageEnum.AlertButtonPositiveText));
      let btnPositiveMessage = message.getData(getName(MessageEnum.AlertButtonPositiveMessage));

      let btnNegativeText = message.getData(getName(MessageEnum.AlertButtonNegativeText));
      let btnNegativeMessage = message.getData(getName(MessageEnum.AlertButtonNegativeMessage));

      let btnNeutralText = message.getData(getName(MessageEnum.AlertButtonNeutralText));
      let btnNeutralMessage = message.getData(getName(MessageEnum.AlertButtonNeutralMessage));

      this.setState({ title: title, body: body, btnPositiveText: btnPositiveText, btnNegativeText: btnNegativeText, btnNeutralText: btnNeutralText })

      this.messagePositiveButton = btnPositiveMessage;
      this.messageNegativeButton = btnNegativeMessage;
      this.messageNeutralButton = btnNeutralMessage;


    }

  }


  navigateToAlertPage(message: Message) {

    const msg: Message = new Message(getName(MessageEnum.NavigationAlertWebMessage));
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      message.getData(getName(MessageEnum.NavigationPropsMessage))
    )

    msg.copyAllPropertiesOf(message);

    this.send(msg)
  }




}



const styles = StyleSheet.create({
  allButtonContainer: { flexDirection: "row", justifyContent: "space-between" },
  twoButtonContainer: { flexDirection: "row", },
  modalParentContainer: {
    flex: 1
  },
});
