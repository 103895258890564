// Customizable Area Start
import React from "react";
import { Typography, Avatar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../../../shared/Header/src/HOCAuth.web";
import { ANNOUNCEMENT_DASHBOARD_ICON, NOTIFICATIONS_NO_DATA } from "../../assets";
import ParentAnnouncementController, {
  Props,
} from "./ParentAnnouncementController.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import "./ParentAnnouncement.web.css";
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

export class ParentAnnouncement extends ParentAnnouncementController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    // Customizable Area Start
    const { loader, announcements } = this.state;
    // Customizable Area End

    return (
      <>
        {loader && <Spinner spinnerModal={loader} />}
        <Grid className="announcement_notification_box" container>
          <Grid item xs={12} className="center_align">
            <Avatar src={ANNOUNCEMENT_DASHBOARD_ICON} className="announcement_msg_icon" />
            <Typography
              className={`${this.props.classes.parentAnnouncement_dashboard_text_font_family} announcement_text`}
            >
                Announcement
            </Typography>
          </Grid>

          {announcements?.length === 0 && (
            <>
              <Grid item xs={12} className="no_announcement_img_block">
                <span className="no_announcement_img_block_span">
                  <img src={NOTIFICATIONS_NO_DATA} alt="No data found" />
                </span>
              </Grid>
              <Grid item xs={12} className="no_announcement_img_block">
                <span
                  className={`${
                    this.props.classes.heading_font
                  }`}
                >
                  No Announcement Yet!
                </span>
              </Grid>
            </>
          )}

          {announcements?.length >= 0 && 
            announcements?.map((announcement: any,index:number)=>(
              <Grid key={announcement.id} item xs={12}>
                <Typography className={this.props.classes.parentAnnouncement_dashboard_announcement_title}>
                  {announcement.title}
                </Typography>
              </Grid>
            ))
          }

          {announcements?.length > 0 && (
            <Grid item xs={12}>
              <Typography className={`view_all_text ${this.props.classes.parentAnnouncement_dashboard_announcement_view_all}`}>
                VIEW ALL
              </Typography>
            </Grid>
          )}        
        </Grid>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(ParentAnnouncement, "TeacherAccount")
);
// Customizable Area End
