// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const ReportCardIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M21.18 0a1.077 1.077 0 0 1 0 2.154H2.154v23.692H21.18a1.077 1.077 0 0 1 0 2.154H1.077A1.077 1.077 0 0 1 0 26.923V1.077C0 .482.482 0 1.077 0H21.18zm1.79 3.408c.595 0 1.078.481 1.078 1.077v1.24h1.438c.595 0 1.077.482 1.077 1.076v6.3c0 .592-.482 1.076-1.077 1.076A1.078 1.078 0 0 1 24.41 13.1V7.878h-.362v14.059c0 .308-.134.603-.365.807l-1.792 1.58a1.077 1.077 0 0 1-1.424 0l-1.792-1.58a1.077 1.077 0 0 1-.365-.807V4.485c0-.596.483-1.077 1.077-1.077h3.584zm-1.076 5.9h-1.43V21.45l.715.63.715-.63V9.308zm-6.814 8.655a1.077 1.077 0 0 1 0 2.154H7.9a1.077 1.077 0 0 1 0-2.154zm0-5.04a1.077 1.077 0 0 1 0 2.154H7.9a1.077 1.077 0 0 1 0-2.154zm0-5.04a1.077 1.077 0 0 1 0 2.154H7.9a1.077 1.077 0 0 1 0-2.154zm6.814-2.322h-1.43v1.593h1.43V5.56z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default ReportCardIcon;
// Customizable Area End