// Customizable Area Start
import React from "react";
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    Avatar,
    Box,
    Button,
    Typography,
    Menu,
    MenuItem,
} from "@material-ui/core";
import {
    Search,
    MoreVertOutlined,
} from "@material-ui/icons";
import {
    reName,
    move,
    viewDetails,
} from './assets';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import TaskUnitController, { Props } from "./TaskUnitController.web";
import Datetime from "react-datetime";
import moment from "moment";
import CreateTask from "./CreateTask.web";
import DeleteTask from "./DeleteTask.web";
import './TaskUnit.web.css';
import { subjectIcon } from "../Attendance/assets";
import Spinner from "../../../shared/SideBar/src/Spinner";
import ShareTask from "./ShareTask.web";
import { shareTaskIcon } from "./assets";
import CreateUnit from "./CreateUnit.web";
import TeacherViewModal from './TeacherViewModal.web'
import ReactPaginate from "react-paginate";
import ShareModalPopup from "./ShareModalPopup.web";
import ShareModalPopupStudent from "./ShareModalPopupStudent.web";
import SelectedTab from '../SelectedTab.web'
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class TaskUnit extends TaskUnitController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    render() {
        const { teacherTasks, teacherId, createrId } = this.state;
        const BorderLinearProgress = withStyles((theme: Theme) =>
            createStyles({
                root: {
                    height: 1,
                    borderRadius: 3.5,
                    marginTop: 14
                },
                colorPrimary: {
                    backgroundColor: "lightgray",
                },
                bar: {
                    borderRadius: 3.5,
                    backgroundColor: '#f26767',
                },
            }),
        )(LinearProgress);
        return (
            <>
                <Box className="taskUnitContentainer">
                    <Grid className="classesGrid" container spacing={2}>
                        <Grid item xs={6}>
                            <Box className="classBox">
                                <Typography className={`classesButtonText ${this.props.classes.heading_font}`}>
                                    Classes
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    {this.state.categoryModal && <><CreateUnit unitModal={this.state.categoryModal} closeModalFun={this.onCateogryCloseModal} onDeleteModalFun={this.deleteCategoryHandler} /></>}
                    <Box className="mt20">
                        <SelectedTab isTeacher={true} type={'Task'} />
                    </Box>
                    <Box className="searchTaskBox">
                        {this.state.showSpinner && (
                            <Spinner spinnerModal={this.state.showSpinner} />
                        )}
                        <Box className="searchOuter">
                            <Box className={`searchMob ${this.props.classes.border_Color}`}>
                                <Search className={`searchIcon ${this.props.classes.icon_color}`} />
                                <input 
                                    placeholder="Search for task"
                                    className={`searchTaskInput ${this.props.classes.bodyText_font}`}
                                    value={this.state.query}
                                    onChange={((event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ query: event.target.value }, () => this.get_default_task());
                                    }
                                    )}
                                />
                            </Box>
                            <Box className="calBox calBoxTask">
                                <Box className="mt23">
                                    <Button variant="contained" className={`todayButton ${this.props.classes.button_color}`}
                                        onClick={() => {
                                            this.setState({ todayBtn: true, dateofbirth: moment().format("YYYY-MM-DD") }, () => {
                                                this.get_default_task()
                                            })
                                        }}
                                    >
                                        Today
                                    </Button>
                                </Box>
                                <Box className="mt23">
                                    <Datetime
                                        dateFormat={"DD MMMM, YYYY"}
                                        timeFormat={false}
                                        strictParsing={true}
                                        input={true}
                                        initialValue={moment().format('DD MMMM, YYYY')}
                                        value={this.state.todayBtn ? moment().format('DD MMMM, YYYY') : this.state.dateofbirth}
                                        className="date-input-student"
                                        inputProps={{
                                            placeholder: "Date of Birth",
                                            className: "Date_of_birth_calendar_Icon_register",
                                            readOnly: true,
                                        }}
                                        closeOnSelect
                                        onChange={(date) => {
                                            this.setState({
                                                todayBtn: false,
                                                dateofbirth: moment(date).format("YYYY-MM-DD")
                                            }, () => { this.get_default_task() })

                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box className="addtaskCol">
                                <Box>
                                    <Button disableRipple={true} variant="contained"
                                        className={`addTaskButton ${this.props.classes.button_color}`}
                                        onClick={() => { this.setState({ unitModal: !this.state.unitModal }) }}>
                                        Add Task
                                    </Button>
                                </Box>
                            </Box>
                            {this.state.unitModal && <><CreateTask unitModal={this.state.unitModal} closeModalFun={this.onCloseModal} savcloseModalFun={this.onsaveCloseModal} task={null} /></>}
                        </Box>
                    </Box>
                    <Box className="taskOuterBox">
                        <Box className="taskCenter">
                            {
                                teacherTasks?.length === 0
                                    ?
                                    <Typography className="noRecordText">No record(s) found. </Typography>
                                    :
                                    <>
                                        {(teacherTasks.length !== 0) && <Grid className="teacherTaskTab" container spacing={3}>
                                            {
                                                teacherTasks.map((task: any, index: number) => {
                                                    const submissionCount = task.attributes.task_members.data.filter((res: any) => res.attributes.status === 'submitted')
                                                    return (
                                                        <>
                                                            {this.state.viewAllTask
                                                                ?
                                                                <>
                                                                    <Grid item xs={4}>
                                                                        <Box className={`taskMainMemberBox ${index == 0 ? "boxBgfirst" : (index == 1 ? "boxBgsecond" : "boxBgthird")}`}>
                                                                            <Box className="taskAttributesList">
                                                                                <Box className="attributeImg">
                                                                                    <Box>
                                                                                        <Avatar className="attributeAvtar" src={task?.attributes?.subject?.data?.attributes?.image?.file_path == null ? subjectIcon : task?.attributes?.subject?.data?.attributes?.image?.file_path} variant="square" />
                                                                                    </Box>
                                                                                    <Box display="flex" flexDirection="column">
                                                                                        <Typography className="taskSubjectName">
                                                                                            {task?.attributes?.subject?.data?.attributes?.subject_name}
                                                                                        </Typography>
                                                                                        <Typography className="taskName">
                                                                                            ({task.attributes?.grade?.data?.attributes?.name})
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                                <Box>
                                                                                    <MoreVertOutlined className="moreIconPoint"
                                                                                        onClick={(event: any) => {
                                                                                            this.setState({ task_id: task.id, anchorEl: event.currentTarget, createrId: task.attributes.creator.id, studentIndexNo: index }, () => {
                                                                                                this.setState({ isOpen: Boolean(this.state.anchorEl) })
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                    <Menu
                                                                                        id="basic-menu"
                                                                                        anchorEl={this.state.anchorEl}
                                                                                        open={this.state.isOpen}
                                                                                        onClose={() => { this.setState({ anchorEl: '', isOpen: false }) }}
                                                                                        MenuListProps={{
                                                                                            'aria-labelledby': 'basic-button',
                                                                                        }}
                                                                                    >
                                                                                        <Box className="width203">
                                                                                            {createrId == teacherId &&
                                                                                                <>
                                                                                                    <MenuItem
                                                                                                        onClick={() => {
                                                                                                            if ((teacherTasks)[this.state.studentIndexNo].attributes.student_members.length == 0) {
                                                                                                                this.setState({ editModal: true })
                                                                                                            }
                                                                                                            else {
                                                                                                                this.setState({ viewModal: true })
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid container spacing={1}>
                                                                                                            <Grid item xs={4} className="flexBase">
                                                                                                                <Avatar className="menuIcon" src={teacherTasks[this.state.studentIndexNo].attributes.student_members.length == 0 ? reName : viewDetails} variant="square" />
                                                                                                            </Grid>
                                                                                                            <Grid item xs={8} className="flexBase">
                                                                                                                <Typography className="viewType">
                                                                                                                    {teacherTasks[this.state.studentIndexNo].attributes.student_members.length == 0 ? <>Edit</> : <>View</>}
                                                                                                                </Typography>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </MenuItem>
                                                                                                    {
                                                                                                        this.state.editModal
                                                                                                        &&
                                                                                                        this.state.task_id === task.id
                                                                                                        &&
                                                                                                        <CreateTask
                                                                                                            unitModal={this.state.editModal}
                                                                                                            closeModalFun={this.onCloseModal}
                                                                                                            savcloseModalFun={this.onsaveCloseModal}
                                                                                                            task={task}
                                                                                                        />

                                                                                                    }
                                                                                                    <MenuItem
                                                                                                        onClick={() => {
                                                                                                            this.setState({ shareModal: true })
                                                                                                            const findIndex = teacherTasks.findIndex((res: any) => res.id === this.state.task_id)
                                                                                                            this.get_props_data(teacherTasks[findIndex])
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid container spacing={1}>
                                                                                                            <Grid item xs={4} className="flexBase">
                                                                                                                <Avatar src={shareTaskIcon} variant="square" className="menuIcon" />
                                                                                                            </Grid>
                                                                                                            <Grid item xs={8} className="flexBase">
                                                                                                                <Typography className="viewType">
                                                                                                                    Share
                                                                                                                </Typography>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </MenuItem>
                                                                                                    {
                                                                                                        this.state.shareModal
                                                                                                        &&
                                                                                                        this.state.task_id === task.id
                                                                                                        &&
                                                                                                        <ShareTask
                                                                                                            unitModal={this.state.shareModal}
                                                                                                            closeModalFun={this.onCloseModal}
                                                                                                            task={task}
                                                                                                            savecloseModalFun={this.onsaveCloseModal}
                                                                                                            openSelectUserModal={this.selectUserModal}
                                                                                                            isShareChanges={this.state.isShareChanges}
                                                                                                            sharewithstudent={this.state.sharewithstudent}
                                                                                                            sharewithteacher={this.state.sharewithteacher}
                                                                                                        />

                                                                                                    }

                                                                                                    <MenuItem
                                                                                                        onClick={() => {
                                                                                                            this.setState({ deleteModal: true })
                                                                                                        }}>
                                                                                                        <Grid container spacing={1}>
                                                                                                            <Grid item xs={4} className="flexBase">
                                                                                                                <Avatar src={move} variant="square" className="menuIcon" />
                                                                                                            </Grid>
                                                                                                            <Grid item xs={8} className="flexBase">
                                                                                                                <Typography className="viewType">
                                                                                                                    Delete
                                                                                                                </Typography>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </MenuItem>
                                                                                                </>
                                                                                            }
                                                                                            {createrId != teacherId &&
                                                                                                <>
                                                                                                    <MenuItem
                                                                                                        onClick={() => {
                                                                                                            this.setState({ viewModal: true })
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid container spacing={1}>
                                                                                                            <Grid item xs={4} className="flexBase">
                                                                                                                <Avatar src={viewDetails} variant="square" className="menuIcon" />
                                                                                                            </Grid>
                                                                                                            <Grid item xs={8} className="flexBase">
                                                                                                                <Typography className="viewType">
                                                                                                                    View
                                                                                                                </Typography>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </MenuItem>
                                                                                                </>
                                                                                            }
                                                                                            {this.state.viewModal && this.state.task_id === task.id &&

                                                                                                <TeacherViewModal viewModal={this.state.viewModal} closeModalFun={this.onCloseModal} task={task} />
                                                                                            }
                                                                                            {this.state.deleteModal && this.state.task_id === task.id && <DeleteTask task_id={this.state.task_id} deleteModal={this.state.deleteModal} closeModalFun={this.onCloseModal} savcloseModalFun={this.onsaveCloseModal} />}
                                                                                        </Box>
                                                                                    </Menu>
                                                                                </Box>
                                                                            </Box>
                                                                            <Box className={`taskAttributesName ${index == 0 ? "colorFirst" : (index == 1 ? "colorSecond" : "colorThird")}`} >
                                                                                {task?.attributes?.name ? task?.attributes?.name : <>John Doe</>}
                                                                            </Box>
                                                                            <Box className="unitAssignBox">
                                                                                <Typography className="dueDate">
                                                                                    Unit Name:
                                                                                </Typography>
                                                                                <Typography className="unitNameAttributs">
                                                                                    {task?.attributes?.unit?.data?.attributes?.unit_name}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box className={`taskAssignBox ${index == 0 ? "borderBottomFirst" : (index == 1 ? "borderBottomSecond" : "borderBottomThird")}`}></Box>
                                                                            <Box className="assigneeBox">
                                                                                <Typography className="assigneeBoxTitle">
                                                                                    Assignee:
                                                                                </Typography>
                                                                                <Avatar className="assigneeAvtar" src={task?.attributes?.creator?.attributes?.avatar} variant="rounded" />
                                                                                <Typography className="flName">
                                                                                    {task?.attributes?.creator?.attributes?.first_name} {task?.attributes?.creator?.attributes?.last_name}                                                                
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box className="dueDateTimeBox">
                                                                                <Typography className="dueDate">
                                                                                    Due Date & Time:
                                                                                </Typography>
                                                                                <Typography className="dueDateAttributes">
                                                                                    {moment(task?.attributes?.due_date).format("DD MMM, YYYY")} at {moment(task?.attributes?.due_date).format("hh:mm A")}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box className="submissionBox">
                                                                                <Typography className="submissionTitle">
                                                                                    Submission :
                                                                                </Typography>
                                                                                <Typography className="submissionlength">
                                                                                    {submissionCount.length}/
                                                                                </Typography>
                                                                                <Typography className="memberLength">
                                                                                    {task.attributes.student_members.length}
                                                                                </Typography>
                                                                            </Box>

                                                                            <Box>
                                                                                <Grid className="borderLineProgressGrid" container>
                                                                                    <Grid item xs={12}>
                                                                                        <BorderLinearProgress variant="determinate" value={30} />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                            <Box className="taskMembersAttributes">
                                                                                <Box className="taskMembersAttributesBox">
                                                                                    {
                                                                                        task.attributes?.task_members != null &&
                                                                                            task.attributes?.task_members?.data.length < 3 ?
                                                                                            <>
                                                                                                {task.attributes?.task_members?.data.filter((res: any) => res.attributes.account_type != 'teacher').map((data: any) => (
                                                                                                    <>
                                                                                                        <Avatar className="taskMemberAvtar" src={data?.attributes?.member?.attributes?.avatar} variant="rounded" />
                                                                                                    </>
                                                                                                ))}
                                                                                            </>
                                                                                            :
                                                                                            task.attributes?.task_members?.data.filter((res: any) => res.attributes.account_type != 'teacher').map((data: any, index: any) => (
                                                                                                index < 3 && (
                                                                                                    <>
                                                                                                        <Avatar className="taskMemberAvtar" src={data?.attributes?.member?.attributes?.avatar} variant="rounded" />
                                                                                                    </>
                                                                                                )
                                                                                            ))

                                                                                    }
                                                                                </Box>
                                                                                <Box className={`taskBg ${index == 0 ? "taskBgFirst" : (index == 1 ? "taskBgSecond" : "taskBgThird")}`}>
                                                                                    <Typography className="taskTitle">
                                                                                        {task?.attributes?.category?.data != null ? task?.attributes?.category?.data?.attributes?.name : <>Task</>}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                </>
                                                                :
                                                                <>
                                                                    {index < 3 && (
                                                                        <Grid item xs={4}>
                                                                            <Box className={`taskMainMemberBox ${index == 0 ? "boxBgfirst" : (index == 1 ? "boxBgsecond" : "boxBgthird")}`}>
                                                                                <Box className="taskAttributesList">
                                                                                    <Box className="attributeImg">
                                                                                        <Box>
                                                                                            <Avatar className="attributeAvtar" src={task?.attributes?.subject?.data?.attributes?.image?.file_path == null ? subjectIcon : task?.attributes?.subject?.data?.attributes?.image?.file_path} variant="square" />
                                                                                        </Box>
                                                                                        <Box display="flex" flexDirection="column">
                                                                                        <Typography className="taskSubjectName">
                                                                                                {task?.attributes?.subject?.data?.attributes?.subject_name}
                                                                                            </Typography>
                                                                                            <Typography className="taskName">
                                                                                                ({task.attributes?.grade?.data?.attributes?.name})
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <MoreVertOutlined className="moreIconPoint"
                                                                                            onClick={(event: any) => {
                                                                                                this.setState({ task_id: task.id, anchorEl: event.currentTarget, createrId: task.attributes.creator.id, studentIndexNo: index }, () => {
                                                                                                    this.setState({ isOpen: Boolean(this.state.anchorEl) })
                                                                                                })
                                                                                            }}
                                                                                        />
                                                                                        <Menu
                                                                                            id="basic-menu"
                                                                                            anchorEl={this.state.anchorEl}
                                                                                            open={this.state.isOpen}
                                                                                            onClose={() => { this.setState({ anchorEl: '', isOpen: false }) }}
                                                                                            MenuListProps={{
                                                                                                'aria-labelledby': 'basic-button',
                                                                                            }}
                                                                                        >
                                                                                            <Box className="width203">
                                                                                                {createrId == teacherId &&
                                                                                                    <>
                                                                                                        <MenuItem
                                                                                                            onClick={() => {
                                                                                                                if (teacherTasks[this.state.studentIndexNo].attributes.student_members.length == 0) {
                                                                                                                    this.setState({ editModal: true })
                                                                                                                }
                                                                                                                else {
                                                                                                                    this.setState({ viewModal: true })
                                                                                                                }
                                                                                                            }}
                                                                                                        >
                                                                                                            <Grid container spacing={1}>
                                                                                                                <Grid item xs={4} className="flexBase">
                                                                                                                    <Avatar className="menuIcon" src={teacherTasks[this.state.studentIndexNo].attributes.student_members.length == 0 ? reName : viewDetails} variant="square" />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={8} className="flexBase">
                                                                                                                    <Typography className="viewType">
                                                                                                                        {teacherTasks[this.state.studentIndexNo].attributes.student_members.length == 0 ? <>Edit</> : <>View</>}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </MenuItem>
                                                                                                        {
                                                                                                            this.state.editModal
                                                                                                            &&
                                                                                                            this.state.task_id === task.id
                                                                                                            &&
                                                                                                            <CreateTask
                                                                                                                unitModal={this.state.editModal}
                                                                                                                closeModalFun={this.onCloseModal}
                                                                                                                savcloseModalFun={this.onsaveCloseModal}
                                                                                                                task={task}
                                                                                                            />

                                                                                                        }
                                                                                                        <MenuItem
                                                                                                            onClick={() => {
                                                                                                                this.setState({ shareModal: true })
                                                                                                                const findIndex = teacherTasks.findIndex((res: any) => res.id === this.state.task_id)
                                                                                                                this.get_props_data(teacherTasks[findIndex])
                                                                                                            }}
                                                                                                        >
                                                                                                            <Grid container spacing={1}>
                                                                                                                <Grid item xs={4} style={{ flexBasis: 0 }}>
                                                                                                                    <Avatar src={shareTaskIcon} variant="square" className="menuIcon" />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={8} className="flexBase">
                                                                                                                    <Typography className="viewType">
                                                                                                                        Share
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </MenuItem>
                                                                                                        {
                                                                                                            this.state.shareModal
                                                                                                            &&
                                                                                                            this.state.task_id === task.id
                                                                                                            &&
                                                                                                            <ShareTask
                                                                                                                unitModal={this.state.shareModal}
                                                                                                                closeModalFun={this.onCloseModal}
                                                                                                                task={task}
                                                                                                                savecloseModalFun={this.onsaveCloseModal}
                                                                                                                openSelectUserModal={this.selectUserModal}
                                                                                                                isShareChanges={this.state.isShareChanges}
                                                                                                                sharewithstudent={this.state.sharewithstudent}
                                                                                                                sharewithteacher={this.state.sharewithteacher}
                                                                                                            />

                                                                                                        }

                                                                                                        <MenuItem
                                                                                                            onClick={() => {
                                                                                                                this.setState({ deleteModal: true })
                                                                                                            }}>
                                                                                                            <Grid container spacing={1}>
                                                                                                                <Grid item xs={4} className="flexBase">
                                                                                                                    <Avatar src={move} variant="square" className="menuIcon" />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={8} className="flexBase">
                                                                                                                <Typography className="viewType">
                                                                                                                        Delete
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </MenuItem>
                                                                                                    </>
                                                                                                }
                                                                                                {createrId != teacherId &&
                                                                                                    <>
                                                                                                        <MenuItem
                                                                                                            onClick={() => {
                                                                                                                this.setState({ viewModal: true })
                                                                                                            }}
                                                                                                        >
                                                                                                            <Grid container spacing={1}>
                                                                                                                <Grid item xs={4} className="flexBase">
                                                                                                                    <Avatar src={viewDetails} variant="square" className="menuIcon" />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={8} className="flexBase">
                                                                                                                    <Typography className="viewType">
                                                                                                                        View
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </MenuItem>
                                                                                                    </>
                                                                                                }
                                                                                                {this.state.viewModal && this.state.task_id === task.id &&
                                                                                                    <TeacherViewModal viewModal={this.state.viewModal} closeModalFun={this.onCloseModal} task={task} />
                                                                                                }
                                                                                                {this.state.deleteModal && this.state.task_id === task.id && <DeleteTask task_id={this.state.task_id} deleteModal={this.state.deleteModal} closeModalFun={this.onCloseModal} savcloseModalFun={this.onsaveCloseModal} />}
                                                                                            </Box>

                                                                                        </Menu>
                                                                                    </Box>
                                                                                </Box>
                                                                                <Box className={`taskAttributesName ${index == 0 ? "colorFirst" : (index == 1 ? "colorSecond" : "colorThird")}`} >
                                                                                    {task?.attributes?.name ? task?.attributes?.name : <>John Doe</>}
                                                                                </Box>

                                                                                <Box className="unitAssignBox">
                                                                                    <Typography className="dueDate">
                                                                                        Unit Name:
                                                                                    </Typography>
                                                                                    <Typography className="unitNameAttributs">
                                                                                        {task?.attributes?.unit?.data?.attributes?.unit_name}
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box className={`taskAssignBox ${index == 0 ? "borderBottomFirst" : (index == 1 ? "borderBottomSecond" : "borderBottomThird")}`}></Box>

                                                                                <Box className="assigneeBox">
                                                                                    <Typography className="assigneeBoxTitle">
                                                                                        Assignee:
                                                                                    </Typography>
                                                                                    <Avatar className="assigneeAvtar" src={task?.attributes?.creator?.attributes?.avatar} variant="rounded" />
                                                                                    <Typography className="flName">
                                                                                        {task?.attributes?.creator?.attributes?.first_name} {task?.attributes?.creator?.attributes?.last_name}                                                                
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box className="dueDateTimeBox">
                                                                                    <Typography className="dueDate">
                                                                                        Due Date & Time:
                                                                                    </Typography>
                                                                                    <Typography className="dueDateAttributes">
                                                                                        {moment(task.attributes.due_date).format("DD MMM, YYYY")} at {moment(task.attributes.due_date).format("hh:mm A")}
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box className="submissionBox">
                                                                                <Typography className="submissionTitle">
                                                                                        Submission :
                                                                                    </Typography>
                                                                                    <Typography className="submissionlength">
                                                                                    {submissionCount.length}/
                                                                                </Typography>
                                                                                <Typography className="memberLength">
                                                                                    {task.attributes.student_members.length}
                                                                                </Typography>
                                                                                </Box>
                                                                                <Box>
                                                                                    <Grid className="borderLineProgressGrid" container>
                                                                                        <Grid item xs={12}>
                                                                                            <BorderLinearProgress variant="determinate" value={30} />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                                <Box className="taskMembersAttributes">
                                                                                    <Box className="taskMembersAttributesBox">
                                                                                        {
                                                                                            task.attributes?.task_members != null &&
                                                                                                task.attributes?.task_members?.data.length < 3 ?
                                                                                                <>
                                                                                                    {task.attributes?.task_members?.data.filter((res: any) => res.attributes.account_type != 'teacher').map((data: any) => (
                                                                                                        <>
                                                                                                            <Avatar src={data?.attributes?.member?.attributes?.avatar} variant="rounded" className="taskMemberAvtar" />

                                                                                                        </>
                                                                                                    ))}
                                                                                                </>
                                                                                                :
                                                                                                task.attributes?.task_members?.data.filter((res: any) => res.attributes.account_type != 'teacher').map((data: any, index: any) => (
                                                                                                    index < 3 && (
                                                                                                        <>
                                                                                                            <Avatar src={data?.attributes?.member?.attributes?.avatar} variant="rounded" className="taskMemberAvtar" />
                                                                                                        </>
                                                                                                    )
                                                                                                ))

                                                                                        }

                                                                                    </Box>
                                                                                    <Box className={`taskBg ${index == 0 ? "taskBgFirst" : (index == 1 ? "taskBgSecond" : "taskBgThird")}`}>
                                                                                    <Typography className="taskTitle">
                                                                                            {task.attributes.category.data != null ? task.attributes.category.data.attributes.name : <>Task</>}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        </Grid>
                                                                    )}
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                }
                                                )
                                            }
                                        </Grid>
                                        } </>}
                        </Box>
                        {this.state.viewAllTask ?
                            <Box className="paginateBox">
                                <ReactPaginate
                                    previousLabel={"←   Previous"}
                                    nextLabel={"Next   →	"}
                                    initialPage={this.state.task_page}
                                    pageCount={this.state.timeLineMeta?.pagination?.total_pages}
                                    onPageChange={(e: any) => {
                                        if (e.selected !== this.state.task_page) {
                                            this.setState({ task_page: e.selected })
                                            this.get_task(e.selected + 1)
                                        }
                                    }}
                                    containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                                    previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                                    nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                                    disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                                    activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                                    pageRangeDisplayed={4}
                                    marginPagesDisplayed={0}
                                />
                            </Box>
                            :
                            <>
                                {
                                     teacherTasks.length > 3 ?
                                        <Box className="viewBtnBox">
                                            <Button className="viewAllBtn" variant="contained" onClick={() => { this.setState({ viewAllTask: !this.state.viewAllTask }) }}>
                                                View All
                                            </Button>
                                        </Box>
                                        :
                                        <></>
                                }
                            </>
                        }
                    </Box>
                </Box>
                {this.state.openShareModal && this.state.userTypeIsTeacher &&
                    <ShareModalPopup
                        openModal={this.state.openShareModal}
                        closeModalFun={this.handleCloseSharePopup}
                        usersList={this.state.classTeacherList}
                        isTeacher={true}
                        filterUser={this.state.sharewithteacher}
                        saveSelectedUsers={(list: any) => this.saveSelectedUsersId(list)}
                    />}
                {this.state.openShareModal && !this.state.userTypeIsTeacher &&
                    <ShareModalPopupStudent
                        openModal={this.state.openShareModal}
                        closeModalFun={this.handleCloseSharePopup}
                        usersList={this.state.classStudentList}
                        isTeacher={false}
                        filterUser={this.state.sharewithstudent}
                        saveSelectedUsers={(list: any) => this.saveSelectedUsersId(list)}
                    />
                }
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(TaskUnit);
// Customizable Area End

