const urlConfig = require("../../../../framework/src/config")
// Customizable Area Start
exports.coScholasticListEndPoint = '/bx_block_assessmenttest/report_cards/';
exports.profileSchoolDetailsEndPoint = '/bx_block_scheduling/schools/';
exports.templateClassEndPoint = '/bx_block_assessmenttest/report_cards/select_from_template';
exports.getSchoolDataEndPoint = '/bx_block_scheduling/schools/';
exports.getTermListEndPoint = '/bx_block_assessmenttest/terms/get_basic_terms_data'
exports.getScholasticDataEndPoint = '/bx_block_assessmenttest/report_cards/'
exports.getGradeDataEndPoint = '/bx_block_assessmenttest/report_grade/'
exports.dashboarApiMethodType = "GET";
exports.dashboarContentType = "application/json";
exports.instituteURL = urlConfig.instituteURL;
exports.examinationUrl = urlConfig.examinationUrl;
exports.resultUrl = urlConfig.resultUrl;
// Customizable Area End