// Customizable Area Start
import React from "react";
import {
    Box,
    Avatar,
} from "@material-ui/core";
import './Member.web.css'
import {
    makeStyles,
    createStyles,
  } from "@material-ui/core/styles";
  import './MembersBox.web.css';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme:any) =>
  createStyles({
    background_color: {
        backgroundColor: theme.palette.primary.main
      },
      
      heading_font: {
        color: `${theme.props.headingColor} !important`,
        fontFamily: theme.props.headingFont
      },
     
      bodyText_font: {
        color: `${theme.props.mainColor} !important`,
        fontFamily: theme.props.textFont
      },
      
      image_Border_Color: {
        border: `3px solid ${theme.props.buttonColor} `
      },
      icon_color: {
        color: `${theme.props.iconColor} !important`
      },
      subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
      },
      
  
    
  })
);
// Customizable Area End

interface Props {
    // Customizable Area Start
    id: number;
    name: string;
    imgSource: any;
    role: string;
    gender: string;
    dateOfBirth: string;
    handleCheckboxClick: (event: any, id: number) => void;
    isSelected: boolean;
    selectAll: boolean;
    // Customizable Area End
}

export const StudentMembersBox = ({
    // Customizable Area Start
    id,
    name,
    imgSource,
    role,
    gender,
    dateOfBirth,
    handleCheckboxClick,
    isSelected,
    selectAll,
    // Customizable Area End
}: Props) => {

    // Customizable Area Start
    const classes = useStyles();
    const displayFocused = (id: number) => {
        const element = document.getElementById(`focus-id-${id}`)
        if (element) {
            element.style.boxShadow = "0 0 15px 0 rgba(0, 0, 0, 0.25)"
        }
    };

    const displayNormal = (id: number) => {
        const element = document.getElementById(`focus-id-${id}`)
        if (element) {
            element.style.boxShadow = "0 0 8px 0 rgba(0, 0, 0, 0.1)"
        }
    };
    // Customizable Area End
    return (
        <Box
        className="profileOuterBox memberBoxContainer maxWidth_420"
            id={`focus-id-${id}`}
            onMouseEnter={() => displayFocused(id)}
            onMouseLeave={() => displayNormal(id)}
        >
            <div className="flexRow">
               <div >
                <div  className={`image_box imageBox ${classes.image_Border_Color}`}>                  
                     <Avatar
                        className="avatar"
                        src={imgSource ? imgSource : "/"}                   
                        style={{
                            backgroundColor: imgSource && "#fff",
                        }}
                    />
                </div>
               </div>
               <div className="flexColumn">
                <div className={`username ${classes.subHeading_font} title`} 
                 id='fileNameTextDoc font_18'
                >
                    {name}
                </div>

            
                    <span
                   className={`font_16 ${classes.bodyText_font} fw_600`} 
                    >
                        {role}
                    </span>
                    </div>

            </div>

        </Box>
    );
};

// Customizable Area Start
export default StudentMembersBox;
// Customizable Area End