// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import {
  file_uploaded_check_icon,
  file_uploaded_pic,
  openIcon,
  cancelIcon,
  fileIcon,
} from "./assets";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { computeChecksumMd5, toBase64 } from "./utils/common";
import axios from "axios";
import { toast } from "react-toastify";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End
interface Props {
  // Customizable Area Start
  fileName: string;
  fileSize: number;
  deleteClick: any;
  previewClick: any;
  onChangeFileName: any;
  getBulkPhotoPreSignedId: any;
  // onBlurFileName: any;
  updateProgress: any;
  file: any;
  progress: number;
  // Customizable Area End
}

export const StudentBulkDocumentBox = ({
  // Customizable Area Start
  fileName,
  fileSize,
  deleteClick,
  previewClick,
  onChangeFileName,
  getBulkPhotoPreSignedId,
  // onBlurFileName,
  updateProgress,
  file,
  progress
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  const [percentage, setPercentage] = useState<number>(progress);
  let clientSidePercentage = 0; // 25% weightage
  let presignedPercentage = 0; // 25% weightage
  let awsPercentage = 0; // 50% weightage
  let signedId: string | null = null;
  let fileData: any = null;

  useEffect(() => {
    calcTotalPercentage();
  }, [progress]);
  useEffect(() => {
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.addEventListener("progress", async (event) => {
    //   console.log('event', event)
    //   if (event.loaded && event.total) {
    //     clientSidePercentage = (event.loaded / event.total) * 100;
    //     calcTotalPercentage();
    //   }
    // });
    uploadFile();
  }, []);

  const uploadFile = async () => {
    //fileData = await toBase64(file);
    const fileDetails = {
      fileName: file.name,
      fileSize: file.size,
      //checksum: md5,
      content_type: file.type,
    };
    await getBulkPhotoPreSignedId(fileDetails);
    // await computeChecksumMd5(file).then((md5: any) => {
    //   const fileDetails = {
    //     fileName: file.name,
    //     fileSize: file.size,
    //     checksum: md5,
    //     content_type: file.type,
    //   };
    //   getPreSignedId(fileDetails);
    // });
  }

  const calcTotalPercentage = () => {
    // const totalPrc =
    //   clientSidePercentage / 4 + presignedPercentage / 4 + awsPercentage / 2;
    setPercentage(progress);
    if (progress >= 100) {
      updateProgress(progress, signedId, fileData);
    }
  };

  return (
    <Grid
      className="xs_p_15 BulkDocumentUploadDiv"
    >
      {percentage >= 100 ? (
        <>
          <Box className="DocumentRelative">
            <img src={file_uploaded_pic} className="file_uploaded_img" />
            <img
              src={file_uploaded_check_icon}
              className="DocumentUploadedCheckIcon"
            />
          </Box>

          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid className="DocumentInputItem">
                <TextField
                  variant={"outlined"}
                  size={"medium"}
                  className="uploaded_file_name_field"
                  value={fileName.slice(0, fileName?.lastIndexOf("."))}
                  onChange={(e) => {
                    onChangeFileName(e.target.value);
                  }}
                  disabled={true}
                />
                <Typography className="StudentDocumentSizeText">
                  {" "}
                  Size:{" "}
                  {fileSize > 1048576
                    ? `${(fileSize / 1048576).toFixed(2)} MB`
                    : `${(fileSize / 1024).toFixed(2)} KB`}
                </Typography>
              </Grid>

              <Grid className="open_cancel_img_div">
                <img
                  src={openIcon}
                  className="UploadedDocOpenImg"
                  title="Preview"
                  onClick={previewClick}
                />
                <img
                  src={cancelIcon}
                  className="UploadedDocDeleteImg"
                  title="Delete"
                  onClick={deleteClick}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Box className="BulkUploadingBox">
            <CircularProgressbarWithChildren
              value={percentage ? percentage : 0}
              strokeWidth={2}
              styles={{
                path: {
                  stroke: "#3f526d",
                  strokeLinecap: "round",
                },
              }}
            >
              <img
                src={fileIcon}
                className="UploadingFileIcon"
              />
            </CircularProgressbarWithChildren>
          </Box>
          <Grid className="DocumentInputItem">
            <Typography className="BulkUploadingFileText">
              Uploading File...
            </Typography>
            <Typography className="ThisMayTakeWhileText">
              {" "}
              This may take a while{" "}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
  // Customizable Area End
};
// Customizable Area Start
export default StudentBulkDocumentBox;
// Customizable Area End