// Customizable Area Start
import React from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
    Box,
    Button,
    Typography,
} from "@material-ui/core";
import DeleteAlertModalController, { Props } from "./DeleteAlertModalController.web";
import {
    deleteAlert,
    SuccessCheckMark,
} from "./assets";
import './CommonDeleteModal.web.css';
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class DeleteAlertModal extends DeleteAlertModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {

        return (
            <>
                {this.state.sucessFullModal ?
                    <>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className="delete_division_modal_container"
                            open={this.props.deleteAlertModal}
                            onClose={this.modalhandleClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                            disableAutoFocus={true}
                        >
                            <Fade in={this.props.deleteAlertModal} style={{ border: "none" }}>
                                <div className="p_40 delete_division_modal">
                                    <img
                                        src={SuccessCheckMark}

                                    />
                                    <Typography
                                        className="delete_division_delete_text_head mt_10"
                                    >
                                        Deleted
                                    </Typography>

                                    <Typography
                                        className="mt_14 delete_division_delete_text_para"
                                    >
                                        The class has been deleted successfully.
                                    </Typography>
                                    <Button
                                        className="delete_division_delete_btn_ok mt_30 dlt_classes_modal_ok_btn_p"
                                        onClick={this.modalhandleClose}
                                    >
                                        ok
                                    </Button>
                                </div>
                            </Fade>
                        </Modal>
                    </>
                    :
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className="delete_division_modal_container"
                        open={this.props.deleteAlertModal}
                        onClose={this.modalhandleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        disableAutoFocus={true}
                    >
                        <Fade in={this.props.deleteAlertModal} style={{ border: "none" }}>
                            <div className="delete_division_modal p_40">
                                <img
                                    src={deleteAlert}
                                />
                                <Typography
                                    className="delete_division_delete_text_head mt_10">
                                    Are you sure?
                                </Typography>

                                <Typography
                                    className="delete_division_delete_text_para mt_14">
                                    Do you want to delete this “Class” ?
                                </Typography>
                                <Box
                                   className="delete_division_action_container mt_30"
                                >
                                    <Button
                                        className="dlt_modal_classes_delete_btn_spacing delete_division_delete_button"
                                        onClick={this.delete_class}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        className="delete_division_cancel_button"
                                        onClick={this.modalhandleClose}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </div>
                        </Fade>
                    </Modal>
                }
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start

export default DeleteAlertModal as React.ComponentType<any>

// Customizable Area End
