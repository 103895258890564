// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  FormLabel,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Grid,
} from "@material-ui/core";
import TeacherProfileController, {
  Props,
} from "./TeacherProfileController.web";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class TeacherProfile extends TeacherProfileController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const {
      educationalDetails,
      educationalDetailsError,
      allSubjects,
    } = this.state;
    return (
      <form onSubmit={this.saveEducationalInfo}>
        <div
          tabIndex={0}
          onKeyDown={this.handleEnter("saveEducationalInfo")}
        >
          <Box className="personal_info_main_div">
            <Grid container direction="row">
              <Grid item xs={12}>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6} className="flex_column">
                    <FormLabel className={`profile_info_label_top ${this.props.classes.bodyText_font}`}>
                      Date of profile creation/Enrollment
                    </FormLabel>

                    <TextField
                      className={`${this.props.classes.textField} ${this.props.classes.bodyText_font}`}
                      variant={"outlined"}
                      size={"small"}
                      type="date"
                      value={educationalDetails?.profile_created}
                      onChange={this.handleEducationalInputChange(
                        "profile_created"
                      )}
                      error={educationalDetailsError?.profile_created}
                      disabled
                      helperText={
                        educationalDetailsError?.messages?.profile_created
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="flex_column spacing_pt_0"
                  >
                    <FormLabel className={`profile_info_label_top ${this.props.classes.bodyText_font}`}>
                      Subject(s) Specialization
                    </FormLabel>
                    <Select
                      variant="outlined"
                      className={`this.props.classes.selectDropDown ${this.props.classes.bodyText_font}`}
                      value={
                        educationalDetails?.specialization
                          ? educationalDetails?.specialization
                          : []
                      }
                      onChange={this.handleEducationalInputChange(
                        "specialization"
                      )}
                      displayEmpty
                      multiple
                      renderValue={() => {
                        return educationalDetails?.specialization?.join(", ");
                      }}
                      error={educationalDetailsError?.specialization}
                      style={{
                        marginBottom: educationalDetailsError?.specialization
                          ? "0px"
                          : "10px",
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                      }}
                    >
                      {allSubjects.map((item: any) => (
                        <MenuItem key={item} value={item}>
                          <ListItemText primary={item} />
                          <ListItemIcon>
                            <Checkbox
                              className={this.props.classes.bodyText_font}
                              checked={
                                educationalDetails?.specialization?.indexOf(
                                  item
                                ) > -1
                              }
                              style={{
                                color:
                                  educationalDetails?.specialization?.indexOf(
                                    item
                                  ) > -1
                                    ? "#af38cb"
                                    : "#3f526d",
                              }}
                            />
                          </ListItemIcon>
                        </MenuItem>
                      ))}
                    </Select>
                 
                    {educationalDetailsError?.specialization && (
                      <FormHelperText className="SpecializationError">
                        {educationalDetailsError?.messages?.specialization}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={5}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="flex_column spacing_pb_0"
                  >
                    <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                      Qualification
                    </FormLabel>
                    <TextField
                      className={`${this.props.classes.textField} ${this.props.classes.bodyText_font}`}
                      variant={"outlined"}
                      size={"small"}
                      value={educationalDetails?.qualification}
                      onChange={this.handleEducationalInputChange(
                        "qualification"
                      )}
                      error={educationalDetailsError?.qualification}
                      helperText={
                        educationalDetailsError?.messages?.qualification
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="flex_column spacing_pt_0"
                  >
                    <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                      Year of Passing
                    </FormLabel>
                    <TextField
                      className={`${this.props.classes.textField} ${this.props.classes.bodyText_font}`}
                      variant={"outlined"}
                      size={"small"}
                      type="text"
                      error={educationalDetailsError?.year_of_passing}
                      helperText={
                        educationalDetailsError?.messages?.year_of_passing
                      }
                      value={educationalDetails?.year_of_passing}
                      onChange={this.handleEducationalInputChange(
                        "year_of_passing"
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={5}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="flex_column spacing_pb_0"
                  >
                    <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                      Total Marks
                    </FormLabel>
                    <TextField
                      className={`${this.props.classes.textField} ${this.props.classes.bodyText_font}`}
                      variant={"outlined"}
                      size={"small"}
                      type="text"
                      error={educationalDetailsError?.total_marks}
                      helperText={
                        educationalDetailsError?.messages?.total_marks
                      }
                      value={educationalDetails?.total_marks}
                      onChange={this.handleEducationalInputChange(
                        "total_marks"
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="flex_column spacing_pt_0"
                  >
                    <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                      School/College/University
                    </FormLabel>
                    <TextField
                      className={`${this.props.classes.textField} ${this.props.classes.bodyText_font}`}
                      variant={"outlined"}
                      size={"small"}
                      value={educationalDetails?.institute}
                      error={educationalDetailsError?.institute}
                      helperText={educationalDetailsError?.messages?.institute}
                      onChange={this.handleEducationalInputChange("institute")}
                      style={{
                        marginBottom: educationalDetailsError?.institute
                          ? "0px"
                          : "10px",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Box mt={5}>
              <Button
                className={`teacher_update_btn ${this.props.classes.button_color_disabled}`}
                style={{
                  opacity:
                    JSON.stringify({
                      ...educationalDetails,
                      total_marks: educationalDetails?.total_marks?.toString(),
                    }) ===
                      JSON.stringify({
                        ...this.educationalDetailsCopy,
                        total_marks: this.educationalDetailsCopy?.total_marks?.toString(),
                      })
                      ? 0.4
                      : 1,
                }}
                disabled={
                  JSON.stringify({
                    ...educationalDetails,
                    total_marks: educationalDetails?.total_marks?.toString(),
                  }) ===
                  JSON.stringify({
                    ...this.educationalDetailsCopy,
                    total_marks: this.educationalDetailsCopy?.total_marks?.toString(),
                  })
                }
                type="submit"
              >
                Update
              </Button>
            </Box>
          </Box>
        </div>
      </form>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export const teacherEducationalInfoStyles = {
  textField: {
    "& .MuiOutlinedInput-input": {
      padding: "14px",
      height: "50px",
      boxSizing: "border-box",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ebebeb",
      },
    },
  },
  selectDropDown: {
    "& .MuiSelect-root": {
      height: "50px",
      padding: "14px",
      boxSizing: "border-box",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "none",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#ebebeb",
      paddingLeft: 14,
      borderRadius: 8,
    },
    "&.Mui-error > .MuiInputBase-input": {
      borderColor: "red !important",
    },
  },
};
// Customizable Area End

// Customizable Area Start
const combineStyles = (...styles:any) => {
  return function CombineStyles(theme:any) {
    const outStyles = styles.map((arg:any) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

return outStyles.reduce((acc:any, val:any) => Object.assign(acc, val));
  };
}

const combinedStyle = combineStyles(themeCreateStyle,teacherEducationalInfoStyles);
export default withStyles(combinedStyle) (
  TeacherProfile as React.ComponentType<any>
);
// Customizable Area End
