// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ReactPhoneInput from "react-phone-input-2";
import Steppers from "./Steppers.web";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import HelpIcon from "@material-ui/icons/Help";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { Box, Link, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import Datetime from "react-datetime";
import {
  NativeSelect,
  ListItemText,
  ListItemIcon,
  Checkbox,
} from "@material-ui/core";
import "./TeacherAccountRegistration.css";
import { addicon, deleteicon, img21kSchholLogo } from "./assets";
import TeacherAccountRegistrationController, {
  Props,
} from "./TeacherAccountRegistrationController.web";
import Spinner from "../../shared/SideBar/src/Spinner";
import "./EmailAccountRegistration.web.css";
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
const accountRegistration_div = {
  justifyContent: "space-between",
  display: "flex",

} as const;
const accountRegistration_helpIcon = {
  color: "#f57b42",
  padding: "20px",
} as const;
const logoImage = {
  width: "151px",
  position: "relative",
  objectFit: 'cover'
} as const;
const typesofAccount_textLabel = {
  fontSize: "35.1px",
  fontWeight: "bold",
  color: "#000",
} as const;
const avatar = {
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  padding: "5px 0px",
  margin: "0px 10px",
  verticalAlign: "text-top",
  cursor: "pointer",
} as const;
const accountRegistration_footer = {
  justifyContent: "space-between",
  display: "flex",
} as const;
const Follow_the_instructions = {
  marginTop: "11.6px",
  width: "50%",
  fontSize: "14px",
  fontStretch: "normal",
  opacity: 0.8,
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "center",
  color: "#8c8aad",
  padding: "0 20%",
} as const;
const textfield_div = {
  margin: "auto",
  justifyContent: "center",
  marginTop: "18.9px",
} as const;
const textfield_pswd_div = {
  margin: "auto",
  justifyContent: "center",
  marginBottom: "16.5px",
  marginTop: "18.9px",
} as const;
const forrmInput_textfield = {
  width: "95%",
  borderRadius: "8.8px",
  textAlign: "start",
  fontSize: "15.8px",
} as const;
const textfield_avtar_div = {
  padding: "0 10px",
  justifyContent: "center",
};
const avtar_textlabel = {
  fontSize: "22.1px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "black",
  marginTop: "10px",
  textAlign: "start",
} as const;
const input = {
  display: "none",
} as const;
const avtar_scroolbar_div = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  width: "100%",
} as const;
const uploadimage_btn_grid = {
  textAlign: "center",
  fontSize: "13.1px",
  color: "#000",
  fontWeight: "bold",
  verticalAlign: "middle",
  margin: "auto",
  position: "relative",
  display: "flex",
  justifyContent: "flex-start",
} as const;
const orGrid = {
  textAlign: "center",
  fontSize: "13.1px",
  color: "#000",
  fontWeight: "bold",
  verticalAlign: "middle",
  margin: "auto",
  position: "relative",
  display: "flex",
  justifyContent: "center",
} as const;

const scrollmenu = {
  overflowX: "auto",
  whiteSpace: "nowrap",
} as const;

const spinnerBar = {
  color: "rgba(255, 255, 255, 1)",
} as const;
// Customizable Area End

export class TeacherAccountRegistration extends TeacherAccountRegistrationController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  parseDate = (str: any) => {
    var m = str.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/);
    return m ? new Date(m[3], m[2] - 1, m[1]) : null;
  };

  handleDate = async (date: any) => {
    const prop = "date_of_birth";

    if (typeof date == "string") {
      const format = this.parseDate(date);
      if (date.trim().length != 10 && format == null) {
        await this.setState({
          dateerror: false,
          dateerrorText: "invalid date",
        });
        this.setState({
          dateofbirth: date,
        });
      }
    } else {
      const format = moment(date).format("YYYY-MM-DD");
      if (prop === "date_of_birth") {
        const currentDate = moment()
          .format("YYYY-MM-DD")
          .replaceAll("-", "");
        const dateOfBirth = format.replaceAll("-", "");
        if (currentDate < dateOfBirth) {
          await this.setState({
            dateerror: false,
            dateerrorText: "date of birth can't be future date",
          });
        } else {
          this.setState({
            dateofbirth: moment(date).format("DD-MM-YYYY"),
            dateerror: false,
            dateerrorText: "",
          });
        }
      }
    }
  };

  render() {
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const phoneReg = /^(?:[0-9]�?){10,15}[0-9]$/;
    const pswdspaceReg = /^\S$|^\S[\s\S]*\S$/;
    const name = /^[a-zA-Z ]*$/;
    const { schoolData, classList } = this.state;
    const today = moment();
    const disableFutureDt = (current: any) => {
      return current.isBefore(today);
    };
    const BootstrapInput = withStyles((theme) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: "8.8px",
        position: "relative",
        border: this.state.gendererror ? "1px solid red" : "1px solid #cfcfcf",
        fontSize: 16,
        padding: "18.5px 14px",
        width: "95%",
        height: "30px",
        backgroundColor: "#f6f6f6",
        color: this.state.gender === "" ? "#a6a5c0" : "#03014c",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
          borderRadius: "8.8px",
          borderColor: "#cfcfcf",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);
    const BootstrapInput1 = withStyles((theme) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: "8.8px",
        width: "95%",
        height: "30px",
        backgroundColor: "#f6f6f6",
        position: "relative",
        border: this.state.schoolerror ? "1px solid red" : "1px solid #cfcfcf",
        fontSize: 16,
        padding: "18.5px 14px",
        color: this.state.school === "" ? "#a6a5c0" : "#03014c",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        lineHeight: "26px !important",
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
          borderRadius: "8.8px",
          borderColor: "#cfcfcf",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);
    const BootstrapInput5 = withStyles((theme) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: "8.8px",
        position: "relative",
        width: "95%",
        height: "30px",
        backgroundColor: "#f6f6f6",
        padding: "18px 22px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: "Open sans",
        textTransform: "none",
        fontWeight: 600,
        fontSize: 18,
        "&:focus": {
          borderRadius: "8.8px",
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        lineHeight: "21px",
      },
    }))(InputBase);
    const BootstrapInput2 = withStyles((theme) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: "8.8px",
        position: "relative",
        width: "95%",
        height: "30px",
        backgroundColor: "#f6f6f6",
        border: this.state.classerror ? "1px solid red" : "1px solid #cfcfcf",
        fontSize: 16,
        padding: "18.5px 14px",
        color: "inherit",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
          borderRadius: "8.8px",
          borderColor: "#cfcfcf",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);

    return (
      <>
        {this.state.loadingSpinner && (
          <Spinner spinnerModal={this.state.loadingSpinner} />
        )}

        <Grid container>
          <Grid className="Header" item xs={12} style={accountRegistration_div}>
            <img src={img21kSchholLogo} alt="Logo Image" style={logoImage} />
            <HelpIcon style={accountRegistration_helpIcon} />
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={this.onteacherSubmit} autoComplete="off">
              <input type="hidden" value="something" />

              <Grid container style={accountRegistration_footer}>
                <Grid item xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link href="EmailAccountRegistration">
                    <IconButton
                      aria-label="delete"
                      className="arrowStyleButtonLeft"
                      color="primary"
                      onClick={() => {
                        localStorage.removeItem("user_data");
                      }}
                    >
                      <ArrowBackRoundedIcon style={{ color: "white" }} />
                    </IconButton>
                  </Link>
                </Grid>
                <Grid item xs={10}>
                  <Grid container className="typesofAccount_div">
                    <Grid item xs={12}>
                      <Steppers activeStep={0} />
                    </Grid>
                    <Grid className={`welcome_text ${this.props.classes.theme_font}`} item xs={12} style={typesofAccount_textLabel}>
                      Create Teacher Account
                    </Grid>
                    <Grid className={`${this.props.classes.theme_font} Instructions-Info`} item xs={12} style={Follow_the_instructions}>
                      Follow the instructions to make it easier to register and
                      you will be able to explore inside.
                    </Grid>
                    <Grid container style={textfield_div}>
                      <Grid item xs={12} sm={6} style={{ marginBottom: "3%" }}>
                        <TextField
                          id="standard-basic"
                          type="text"
                          placeholder="First Name"
                          style={forrmInput_textfield}
                          className={`${this.props.classes.theme_font}`}
                          value={this.state.firstname}
                          variant="outlined"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            if (value.length === 0) {
                              this.setState({
                                fnameerror: true,
                                fnameerrorText: "First name is required.",
                              });
                            } else {
                              this.setState({
                                fnameerror: false,
                                fnameerrorText: "",
                              });
                            }
                            if (
                              event.target.value === "" ||
                              name.test(event.target.value)
                            ) {
                              this.setState({
                                firstname: event.target.value,
                              });
                            }
                          }}
                          onBlur={() => {
                            if (
                              this.state.firstname === null ||
                              this.state.firstname.length === 0
                            ) {
                              this.setState({
                                fnameerror: true,
                                fnameerrorText: "First name is required.",
                              });
                            } else {
                              this.setState({
                                fnameerror: false,
                                fnameerrorText: "",
                              });
                            }
                          }}
                          error={this.state.fnameerror}
                          helperText={this.state.fnameerrorText}
                          inputProps={{
                            style: {
                              fontFamily: "Open sans",
                              fontSize: 15.8,
                              color: "#03014c",
                              backgroundColor: "#f6f6f6",
                              padding: "5px 20px 5px 20px",
                              height: "60px",
                              borderRadius: "8.8px",
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonOutlineOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    color: "#7c7ba0",
                                    paddingLeft: "20px",
                                  }}
                                />
                              </InputAdornment>
                            ),
                            style: {
                              backgroundColor: "#f6f6f6",
                              borderRadius: "8.8px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginBottom: "3%" }}>
                        <TextField
                          id="standard-basic"
                          placeholder="Last Name"
                          style={forrmInput_textfield}
                          className={`${this.props.classes.theme_font}`}
                          variant="outlined"
                          value={this.state.lastname}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            if (value.length === 0) {
                              this.setState({
                                lnameerror: true,
                                lnameerrorText: " Last name is required.",
                              });
                            } else {
                              this.setState({
                                lnameerror: false,
                                lnameerrorText: "",
                              });
                            }

                            if (
                              event.target.value === "" ||
                              name.test(event.target.value)
                            ) {
                              this.setState({ lastname: event.target.value });
                            }
                          }}
                          onBlur={() => {
                            if (
                              this.state.lastname === null ||
                              this.state.lastname.length === 0
                            ) {
                              this.setState({
                                lnameerror: true,
                                lnameerrorText: " Last name is required.",
                              });
                            } else {
                              this.setState({
                                lnameerror: false,
                                lnameerrorText: "",
                              });
                            }
                          }}
                          error={this.state.lnameerror}
                          helperText={this.state.lnameerrorText}
                          inputProps={{
                            style: {
                              fontFamily: "Open sans",
                              fontSize: 15.8,
                              color: "#03014c",
                              backgroundColor: "#f6f6f6",
                              padding: "5px 20px 5px 20px",
                              height: "60px",
                              borderRadius: "8.8px",
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonOutlineOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    color: "#7c7ba0",
                                    paddingLeft: "20px",
                                  }}
                                />
                              </InputAdornment>
                            ),
                            style: {
                              backgroundColor: "#f6f6f6",
                              borderRadius: "8.8px",
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} style={{ marginBottom: "3%" }}>
                        <TextField
                          id="standard-basic"
                          placeholder="Email ID"
                          style={forrmInput_textfield}
                          className={`${this.props.classes.theme_font}`}
                          type="text"
                          autoComplete="off"
                          value={this.state.email}
                          variant="outlined"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            if (value.length === 0) {
                              this.setState({
                                emailerror: true,
                                emailerrorText: "Email is required.",
                              });
                            } else if (!emailReg.test(value)) {
                              this.setState({
                                emailerror: true,
                                emailerrorText: "Enter a valid Email.",
                              });
                            } else if (emailReg.test(value)) {
                              this.setState({
                                emailerror: false,
                                emailerrorText: "",
                              });
                            }
                            this.setState({
                              email: event.target.value.trim(),
                            });
                          }}
                          onBlur={() => {
                            if (
                              this.state.email === null ||
                              this.state.email.length === 0
                            ) {
                              this.setState({
                                emailerror: true,
                                emailerrorText: "Email is required.",
                              });
                            } else if (!emailReg.test(this.state.email)) {
                              this.setState({
                                emailerror: true,
                                emailerrorText: "Enter a valid Email.",
                              });
                            } else if (emailReg.test(this.state.email)) {
                              this.setState({
                                emailerror: false,
                                emailerrorText: "",
                              });
                            } else {
                              this.setState({
                                emailerror: false,
                                emailerrorText: "",
                              });
                            }
                          }}
                          error={this.state.emailerror}
                          helperText={this.state.emailerrorText}
                          inputProps={{
                            style: {
                              fontFamily: "Open sans",
                              fontSize: 15.8,
                              color: "#03014c",
                              backgroundColor: "#f6f6f6",
                              padding: "5px 20px 5px 20px",
                              height: "60px",
                              borderRadius: "8.8px",
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    color: "#7c7ba0",
                                    paddingLeft: "20px",
                                  }}
                                />
                              </InputAdornment>
                            ),
                            style: {
                              backgroundColor: "#f6f6f6",
                              borderRadius: "8.8px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          marginBottom: "3%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "95%",
                          }}
                        >
                          <ReactPhoneInput
                            country={"in"}
                            placeholder="Phone Number"
                            alwaysDefaultMask={false}
                            autoFormat={false}
                            value={this.state.phonenumber}
                            inputStyle={{
                              width: "100%",
                              height: "70px",
                              fontFamily: "Open sans",
                              fontSize: "15.8px",
                              borderRadius: "8.8px",
                              padding: "5px 48px",
                              backgroundColor: "#f6f6f6",
                              border: this.state.phoneerror
                                ? "1px solid red"
                                : "1px solid lightgray",
                              borderLeft: this.state.phoneerror
                                ? "0px"
                                : "1px solid lightgray",
                            }}
                            buttonStyle={{
                              backgroundColor: "#f1f1f1",
                              border: this.state.phoneerror
                                ? "1px solid red"
                                : "1px solid lightgray",
                              borderRight: this.state.phoneerror
                                ? "0px"
                                : "1px solid lightgray",
                              borderBottomLeftRadius: "8.8px",
                              borderTopLeftRadius: "8.8px",
                              padding: "1px",
                            }}
                            dropdownStyle={{ width: "300px" }}
                            searchStyle={{
                              margin: "0",
                              width: "97%",
                              height: "30px",
                            }}
                            searchClass="search-class"
                            disableSearchIcon
                            enableSearch={true}
                            countryCodeEditable={false}
                            onChange={(value, country: any) => {
                              if (this.state.dialCode !== country.countryCode) {
                                this.setState({
                                  phonenumber: country.dialCode,
                                  dialCode: country.countryCode,
                                });
                              } else {
                                this.setState({
                                  phonenumber: value,
                                  dialCode: country.countryCode,
                                });
                              }
                              if (value.length === 0) {
                                this.setState({
                                  phoneerror: true,
                                  phoneerrorText: "Phone number is required.",
                                });
                              } else if (phoneReg.test(value)) {
                                this.setState({
                                  phoneerror: false,
                                  phoneerrorText: "",
                                });
                              } else if (!phoneReg.test(value)) {
                                this.setState({
                                  phoneerror: true,
                                  phoneerrorText: "Enter a valid phone number.",
                                });
                              } else {
                                this.setState({
                                  phoneerror: false,
                                  phoneerrorText: "",
                                });
                              }
                            }}
                            onBlur={(value: any) => {
                              if (
                                this.state.phonenumber === null ||
                                this.state.phonenumber.length === 0
                              ) {
                                this.setState({
                                  phoneerror: true,
                                  phoneerrorText: "Phone number is required.",
                                });
                              } else if (
                                !phoneReg.test(this.state.phonenumber)
                              ) {
                                this.setState({
                                  phoneerror: true,
                                  phoneerrorText: "Enter a valid phone number.",
                                });
                              } else if (
                                phoneReg.test(this.state.phonenumber)
                              ) {
                                this.setState({
                                  phoneerror: false,
                                  phoneerrorText: "",
                                });
                              } else {
                                this.setState({
                                  phoneerror: false,
                                  phoneerrorText: "",
                                });
                              }
                            }}
                          />
                          {this.state.phoneerror && (
                            <FormHelperText
                              style={{ color: "red", margin: "3px 14px 0" }}
                            >
                              {this.state.phoneerrorText}
                            </FormHelperText>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginBottom: "3%" }}>
                        <FormControl style={forrmInput_textfield} className={`${this.props.classes.theme_font}`}>
                          <NativeSelect
                            id="select"
                            value={this.state.gender}
                            onChange={(
                              event: React.ChangeEvent<{ value: unknown }>
                            ) => {
                              if (event.target.value != 0) {
                                this.setState({ gendererror: false });
                              }
                              this.setState({
                                gender: event.target.value as string,
                              });
                            }}
                            input={<BootstrapInput />}
                            onBlur={() => {
                              if (
                                this.state.gender === null ||
                                this.state.gender.length === 0
                              ) {
                                this.setState({ gendererror: true });
                              } else if (this.state.gender.length != 0) {
                                this.setState({ gendererror: false });
                              } else {
                                this.setState({ gendererror: false });
                              }
                            }}
                          >
                            <option
                              value=""
                              style={{ color: "#a6a5c0" }}
                              disabled
                            >
                              Gender
                            </option>
                            <option value="Male" style={{ color: "#03014c" }}>
                              Male
                            </option>
                            <option value="Female" style={{ color: "#03014c" }}>
                              Female
                            </option>
                            <option value="Other" style={{ color: "#03014c" }}>
                              Other
                            </option>
                          </NativeSelect>
                          {this.state.gendererror && (
                            <FormHelperText
                              style={{ color: "red", margin: "3px 14px 0" }}
                            >
                              Please select gender
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          marginBottom: "3%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ width: "95%" }}>
                          <Datetime
                            dateFormat={"DD-MM-YYYY"}
                            timeFormat={false}
                            strictParsing={true}
                            input={true}
                            value={this.state.dateofbirth}
                            className={
                              this.state.dateerror
                                ? "input_date_css_error_format_registration"
                                : "input_date_cal_css_format_registration"
                            }
                            inputProps={{
                              placeholder: "Date of Birth",
                              className: "Date_of_birth_calendar_Icon_register",
                            }}
                            isValidDate={disableFutureDt}
                            onClose={() => {
                              if (this.state.dateofbirth.length === 0) {
                                this.setState({
                                  dateerror: true,
                                  dateerrorText:
                                    " Please select date of birth.",
                                });
                              }
                            }}
                            onChange={(date) => {
                              this.handleDate(date);
                            }}
                          />
                          <FormHelperText
                            style={{ color: "red", margin: "3px 14px 0" }}
                          >
                            {this.state.dateerrorText}{" "}
                          </FormHelperText>
                        </div>
                      </Grid>

                      <Grid item xs={12} style={{ marginBottom: "3%" }}>
                        <FormControl className="schoolSelect">
                          <Select
                            id="select"
                            variant="outlined"
                            input={<BootstrapInput1 />}
                            inputProps={{
                              style: {
                                borderColor: this.state.schoolerror
                                  ? "1px solid red"
                                  : "#ebebeb",
                              },
                            }}
                            displayEmpty={true}
                            value={this.state.school}
                            onChange={(
                              event: React.ChangeEvent<{ value: unknown }>
                            ) => {
                              if (event.target.value != 0) {
                                this.setState({ schoolerror: false });
                              }
                              this.setState(
                                {
                                  school: event.target.value,
                                  classesAndSubject: [
                                    { class: "", subject_data: [] },
                                  ],
                                  selectedIndexId: "",
                                  subjectWithClassId: [],
                                  selectedClassId: "",
                                  finalSelectedClass: [],
                                },
                                () => this.get_class(this.state.school)
                              );
                            }}
                            onBlur={() => {
                              if (
                                this.state.school === null ||
                                this.state.school.length === 0
                              ) {
                                this.setState({ schoolerror: true });
                              } else if (this.state.school.length != 0) {
                                this.setState({ schoolerror: false });
                              } else {
                                this.setState({ schoolerror: false });
                              }
                            }}
                            error={this.state.schoolerror}
                          >
                            <MenuItem
                              value=""
                              style={{
                                color: "#a6a5c0",
                                fontFamily: "Open sans",
                              }}
                              disabled
                            >
                              Select School
                            </MenuItem>
                            {schoolData.map((data: any) => (
                              <MenuItem
                                value={data.id}
                                style={{
                                  color: "#03014c",
                                  fontFamily: "Open sans",
                                }}
                              >
                                {data.attributes.name}
                              </MenuItem>
                            ))}
                            {schoolData.length === 0 && (
                              <MenuItem
                                value="disabled"
                                style={{
                                  color: "#03014c",
                                  fontFamily: "Open sans",
                                  justifyContent: "center",
                                }}
                                disabled
                              >
                                No School Available
                              </MenuItem>
                            )}
                          </Select>
                          {this.state.schoolerror && (
                            <FormHelperText
                              style={{ color: "red", margin: "3px 14px 0" }}
                            >
                              Please select school
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      {this.state.classesAndSubject.map(
                        (_classAndSubject: any, _index: any) => (
                          <Grid className="selectitem"
                            container
                            style={{
                              justifyContent: "space-between",
                              alignItems: "start",
                              position: "relative",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{ marginBottom: "3%" }}
                            >
                              <FormControl style={forrmInput_textfield} className={`${this.props.classes.theme_font}`}>
                                <Select
                                  id="select"
                                  variant="outlined"
                                  displayEmpty={true}
                                  className={this.props.classes.TeacherAccountRegistration_EmailAccountRegistration_selectDropDown}
                                  style={{
                                    color:
                                      _classAndSubject.class === ""
                                        ? "#a6a5c0"
                                        : "#03014c",
                                  }}
                                  inputProps={{
                                    style: {
                                      fontFamily: "Open sans",
                                      color:
                                        _classAndSubject.class === ""
                                          ? "#a6a5c0"
                                          : "#03014c",
                                    },
                                  }}
                                  value={_classAndSubject.class}
                                  onChange={(
                                    event: React.ChangeEvent<{ value: unknown }>
                                  ) => {
                                    let data: any = this.state
                                      .classesAndSubject;
                                    data[_index].class = event.target.value;
                                    data[_index].subject_data = [];

                                    this.setState(
                                      {
                                        classesAndSubject: data,
                                        selectedClassId: event.target.value,
                                      },
                                      () => {
                                        let arr: any[] = [];

                                        this.state.classesAndSubject.map(
                                          (_data: any) => {
                                            arr.push(_data.class);
                                          }
                                        );
                                        this.setState({
                                          finalSelectedClass: arr,
                                        });
                                        this.get_subject(
                                          this.state.selectedClassId
                                        );
                                        if (
                                          _index === 0 &&
                                          this.state.classesAndSubject[_index]
                                            .class !== ""
                                        ) {
                                          this.setState({ classerror: false });
                                        }
                                      }
                                    );
                                  }}
                                  onBlur={() => {
                                    if (
                                      _index === 0 &&
                                      this.state.classesAndSubject[_index]
                                        .class === ""
                                    ) {
                                      this.setState({ classerror: true });
                                    }
                                    if (
                                      _index === 0 &&
                                      this.state.classesAndSubject[_index]
                                        .class !== ""
                                    ) {
                                      this.setState({ classerror: false });
                                    }
                                  }}
                                  error={
                                    _index === 0 ? this.state.classerror : false
                                  }
                                >
                                  <MenuItem
                                    style={{
                                      color: "#a6a5c0",
                                      fontFamily: "Open sans",
                                    }}
                                    value=""
                                    disabled
                                  >
                                    {" "}
                                    Select Class
                                  </MenuItem>
                                  {classList
                                    .filter((post: any) => {
                                      if (
                                        (this.state.finalSelectedClass.indexOf(
                                          parseInt(post.id)
                                        ) === -1 &&
                                          this.state.finalSelectedClass.indexOf(
                                            post.id
                                          ) === -1) ||
                                        this.state.classesAndSubject[_index]
                                          ?.class == parseInt(post.id)
                                      ) {
                                        return post;
                                      }
                                    })
                                    .map((item: any, ind: number) => (
                                      <MenuItem
                                        style={{
                                          marginTop: "0",
                                          marginBottom: 0,
                                        }}
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item?.attributes?.display_name}
                                      </MenuItem>
                                    ))}
                                  {classList.length === 0 && (
                                    <MenuItem
                                      value="diabled"
                                      style={{
                                        color: "#03014c",
                                        fontFamily: "Open sans",
                                        justifyContent: "center",
                                      }}
                                      disabled
                                    >
                                      No Classes Available
                                    </MenuItem>
                                  )}
                                </Select>
                                {_index === 0 && this.state.classerror && (
                                  <FormHelperText
                                    style={{
                                      color: "red",
                                      margin: "3px 14px 0",
                                    }}
                                  >
                                    Please select class
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{
                                marginBottom: "3%",
                                position: "relative",
                              }}
                              className="select"
                            >
                              <FormControl style={forrmInput_textfield} className={`${this.props.classes.theme_font}`}>
                                <Select
                                  variant="outlined"
                                  displayEmpty={true}
                                  className={this.state.subjectError ? this.props.classes.TeacherAccountRegistration_EmailAccountRegistration_selectErrorDropDown : this.props.classes.TeacherAccountRegistration_EmailAccountRegistration_selectDropDown}

                                  value={
                                    _classAndSubject.subject_data.length !== 0
                                      ? _classAndSubject.subject_data
                                      : []
                                  }
                                  onChange={(
                                    event: React.ChangeEvent<{ value: unknown }>
                                  ) => {
                                    let data: any = this.state
                                      .classesAndSubject;
                                    data[_index].subject_data =
                                      event.target.value;
                                    this.setState({ classesAndSubject: data, subjectError: false });
                                  }}
                                  multiple
                                  MenuProps={{ getContentAnchorEl: null }}
                                  renderValue={(selected: any) => {
                                    if (selected.length === 0) {
                                      return (
                                        <span
                                          style={{
                                            opacity: 0.4,
                                            fontFamily: "Open sans",
                                          }}
                                        >
                                          Select Subject
                                        </span>
                                      );
                                    } else {
                                      return selected.map(
                                        (_index: any, index: any) => {
                                          return this.state.subjectWithClassId.map(
                                            (_data1: any) => {
                                              if (
                                                _data1.class ==
                                                _classAndSubject?.class
                                              ) {
                                                return _data1.subjectList
                                                  .filter(
                                                    (x: any) => x.id === _index
                                                  )
                                                  .map((_x: any) => {
                                                    return index ===
                                                      _classAndSubject
                                                        ?.subject_data.length -
                                                      1
                                                      ? _x.attributes
                                                        ?.subject_name
                                                      : `${_x.attributes?.subject_name}, `;
                                                  });
                                              }
                                            }
                                          );
                                        }
                                      );
                                    }
                                  }}
                                >
                                  <MenuItem
                                    style={{ marginTop: "0", marginBottom: 0 }}
                                    value="defaultValue"
                                    disabled
                                  >
                                    {" "}
                                    Select Subject
                                  </MenuItem>

                                  {this.state.subjectWithClassId.map(
                                    (_data1: any) => {
                                      if (
                                        _data1.class == _classAndSubject?.class
                                      ) {
                                        return _data1.subjectList.map(
                                          (_data: any, _index: any) => (
                                            <MenuItem
                                              style={{
                                                marginTop: "0",
                                                marginBottom: 0,
                                              }}
                                              key={_data.id}
                                              value={_data.id}
                                            >
                                              <ListItemText
                                                primary={
                                                  _data?.attributes
                                                    ?.subject_name
                                                }
                                              />
                                              <ListItemIcon>
                                                <Checkbox
                                                  size="small"
                                                  checked={
                                                    _classAndSubject.subject_data.indexOf(
                                                      _data.id
                                                    ) > -1
                                                  }
                                                  style={{
                                                    padding: 0,
                                                    color:
                                                      _classAndSubject.subject_data.indexOf(
                                                        _data.id
                                                      ) > -1
                                                        ? "#af38cb"
                                                        : "#3f526d",
                                                  }}
                                                />
                                              </ListItemIcon>
                                            </MenuItem>
                                          )
                                        );
                                      }
                                    }
                                  )}

                                  {this.state.subjectWithClassId.map(
                                    (_data1: any) => {
                                      if (
                                        _data1.class == _classAndSubject?.class
                                      ) {
                                        return (
                                          _data1.subjectList.length === 0 && (
                                            <MenuItem
                                              value="diabled"
                                              style={{
                                                color: "#03014c",
                                                fontFamily: "Open sans",
                                                justifyContent: "center",
                                              }}
                                              disabled
                                            >
                                              No Subject Available
                                            </MenuItem>
                                          )
                                        );
                                      }
                                    }
                                  )}
                                </Select>
                              </FormControl>
                              {_index !== 0 && (
                                <img
                                  src={deleteicon}
                                  className="classSubjectDeleteIcon"
                                  onClick={() => {
                                    let finalClass = this.state.finalSelectedClass.filter(
                                      (_data: any, index: any) => {
                                        if (
                                          parseInt(_data) !==
                                          parseInt(_classAndSubject.class)
                                        ) {
                                          return _data;
                                        }
                                      }
                                    );

                                    let classData = this.state
                                      .classesAndSubject;
                                    classData.splice(_index, 1);
                                    this.setState({
                                      classesAndSubject: classData,
                                      finalSelectedClass: finalClass,
                                    });
                                  }}
                                />
                              )}
                              {_index ==
                                this.state.classesAndSubject.length - 1 &&
                                this.state.classesAndSubject.length <
                                this.state.classList.length && (
                                  <img
                                    src={addicon}
                                    className="classSubjectAddIcon"
                                    onClick={() => {
                                      let tempArr = this.state
                                        .classesAndSubject;
                                      tempArr.push({
                                        class: "",
                                        subject_data: [],
                                      });
                                      this.setState(
                                        { classesAndSubject: tempArr },
                                        () => { }
                                      );
                                    }}
                                  />
                                )}
                              {_index === 0 && this.state.subjectError && (
                                <FormHelperText
                                  style={{
                                    color: "red",
                                    margin: "3px 14px 0",
                                  }}
                                >
                                  Please select subject
                                </FormHelperText>
                              )}
                            </Grid>
                          </Grid>
                        )
                      )}
                      <Grid style={{ marginBottom: "3%", position: 'relative' }} xs={12} sm={6}>
                        <TextField
                          type="Password"
                          placeholder="Password"
                          autoComplete="new-password"
                          style={forrmInput_textfield}
                          className={`${this.props.classes.theme_font}`}
                          value={this.state.password}
                          variant="outlined"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            if (event.target.value.length === 0) {
                              this.setState({
                                pswderror: true,
                                openToolTip: false,
                                pswderrorText: "Password is required",
                              });
                            } else if (!pswdspaceReg.test(event.target.value)) {
                              this.setState({
                                pswderror: true,
                                openToolTip: false,
                                pswderrorText:
                                  "Your password can't start or end with a blank space.",
                              });
                            } else if (value.length > 0) {
                              if (!passwordReg.test(value.replace(" ", "@"))) {
                                this.setState({
                                  pswderror: true,
                                  openToolTip: true,
                                  // pswderrorText:
                                  //   "Password must be 8 characters long and contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character.",
                                });
                              } else if (
                                passwordReg.test(value.replace(" ", "@"))
                              ) {
                                this.setState({
                                  pswderror: false,
                                  openToolTip: false,
                                  pswderrorText: "",
                                });
                              } else {
                                this.setState({
                                  pswderror: false,
                                  openToolTip: false,
                                  pswderrorText: "",
                                });
                              }
                            }
                            if (this.state.confirmPassword.length > 0) {
                              if (
                                event.target.value ===
                                this.state.confirmPassword
                              ) {
                                this.setState({
                                  cpswderror: false,
                                  cpswderrorText: "",
                                });
                              }
                            }

                            this.setState({ password: event.target.value, });
                          }}
                          onBlur={() => {
                            if (
                              this.state.password === null ||
                              this.state.password.length === 0
                            ) {
                              this.setState({
                                pswderror: true,
                                pswderrorText: "Password is required",
                              });
                            } else if (
                              !pswdspaceReg.test(this.state.password)
                            ) {
                              this.setState({
                                pswderror: true,
                                pswderrorText:
                                  "Your password can't start or end with a blank space.",
                              });
                            } else if (
                              !passwordReg.test(
                                this.state.password.replace(" ", "@")
                              )
                            ) {
                              this.setState({
                                pswderror: true,
                                // pswderrorText:
                                //   "Password must be 8 characters long and contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character.",
                              });
                            } else {
                              this.setState({
                                pswderror: false,
                                pswderrorText: "",
                              });
                            }
                            if (this.state.confirmPassword.length > 0) {
                              if (
                                this.state.password !=
                                this.state.confirmPassword
                              ) {
                                this.setState({
                                  cpswderror: true,
                                  cpswderrorText:
                                    "Password and Confirm password must match.",
                                });
                              }
                            }
                          }}
                          error={this.state.pswderror}
                          helperText={this.state.pswderrorText}
                          inputProps={{
                            style: {
                              fontFamily: "Open sans",
                              fontSize: 15.8,
                              color: "#03014c",
                              backgroundColor: "#f6f6f6",
                              padding: "5px 20px 5px 20px",
                              height: "60px",
                              borderRadius: "8.8px",
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockOpenOutlinedIcon className="pl-0"
                                  style={{
                                    fontSize: "20px",
                                    opacity: 0.5,
                                    paddingLeft: "20px",
                                  }}
                                />
                              </InputAdornment>
                            ),
                            style: {
                              backgroundColor: "#f6f6f6",
                              borderRadius: "8.8px",
                            },
                          }}
                        />
                        {this.state.openToolTip &&
                          <Box
                            style={{
                              padding: '10px',
                              top: "110%",
                              boxShadow: '0 0 10px 0 rgb(30 28 46 / 8%)',
                              background: "white", color: "black", width: '93%', position: 'absolute', borderRadius: '8.8px'
                            }}>
                            <Typography style={{ fontSize: 18, textAlign: 'start', fontFamily: "Open sans", margin: '20px 0px' }}>
                              Password must:
                            </Typography>
                            <ul style={{ textAlign: 'start', fontFamily: "Open sans" }}>
                              <li>Have at least 8 characters.</li>
                              <li>Have at least 1 letter(a,b,c...).</li>
                              <li>Have at least 1 number(1,2,3...).</li>
                              <li>Have at least 1 special character(@,#,$...).</li>
                              <li>Include both uppercase and lowercase characters.</li>
                            </ul>
                          </Box>
                        }
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginBottom: "3%" }}>
                        <TextField
                          type="Password"
                          placeholder="Confirm Password"
                          style={forrmInput_textfield}
                          className={`${this.props.classes.theme_font}`}
                          variant="outlined"
                          value={this.state.confirmPassword}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.value.length === 0) {
                              this.setState({
                                cpswderror: true,
                                cpswderrorText: "Confirm Password is required.",
                              });
                            } else if (!pswdspaceReg.test(event.target.value)) {
                              this.setState({
                                cpswderror: true,
                                cpswderrorText:
                                  "Your password can't start or end with a blank space.",
                              });
                            } else if (event.target.value.trim().length > 0) {
                              if (this.state.password != event.target.value) {
                                this.setState({
                                  cpswderror: true,
                                  cpswderrorText:
                                    "Password and Confirm password must match. ",
                                });
                              } else {
                                this.setState({
                                  cpswderror: false,
                                  cpswderrorText: "",
                                });
                              }
                            }
                            this.setState({
                              confirmPassword: event.target.value,
                            });
                          }}
                          onBlur={() => {
                            if (
                              this.state.confirmPassword === null ||
                              this.state.confirmPassword.length === 0
                            ) {
                              this.setState({
                                cpswderror: true,
                                cpswderrorText: "Confirm Password is required.",
                              });
                            } else if (
                              !pswdspaceReg.test(this.state.confirmPassword)
                            ) {
                              this.setState({
                                pswderror: true,
                                pswderrorText:
                                  "Your password can't start or end with a blank space.",
                              });
                            } else if (
                              this.state.password != this.state.confirmPassword
                            ) {
                              this.setState({
                                cpswderror: true,
                                cpswderrorText:
                                  "Password and Confirm password must match. ",
                              });
                            } else {
                              this.setState({
                                cpswderror: false,
                                cpswderrorText: "",
                              });
                            }
                          }}
                          error={this.state.cpswderror}
                          helperText={this.state.cpswderrorText}
                          inputProps={{
                            style: {
                              fontFamily: "Open sans",
                              fontSize: 15.8,
                              color: "#03014c",
                              backgroundColor: "#f6f6f6",
                              padding: "5px 20px 5px 20px",
                              height: "60px",
                              borderRadius: "8.8px",
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockOpenOutlinedIcon className="pl-0"
                                  style={{
                                    fontSize: "20px",
                                    opacity: 0.5,
                                    paddingLeft: "20px",
                                  }}
                                />
                              </InputAdornment>
                            ),
                            style: {
                              backgroundColor: "#f6f6f6",
                              borderRadius: "8.8px",
                            },
                          }}
                        />
                      </Grid>

                      <Grid
                        container
                        xs={12}
                        style={textfield_avtar_div}
                      >
                        <Grid item xs={12}>
                          <div style={avtar_textlabel} className={`${this.props.classes.theme_font}`}>Select your avatar</div>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <div className="Mob-scroolbar" style={avtar_scroolbar_div}>
                              <Grid item xs={7}>
                                <div className="Create-Account_block" style={scrollmenu}>
                                  {configJSON.avtar_array.map(
                                    (image: any, index: any) => (
                                      <>
                                        <img
                                          src={image.source}
                                          alt="Avatar"
                                          style={avatar}
                                          key={index}
                                          onClick={() => {
                                            if (
                                              this.state.imagemapcallId ===
                                              index
                                            ) {
                                              this.setState({
                                                avtarclick: !this.state
                                                  .avtarclick,
                                                uploadavtar: "",
                                                avtar: image.source,
                                                imagemapcallId: index,
                                              });
                                            } else {
                                              this.setState({
                                                avtarclick: true,
                                                uploadavtar: "",
                                                avtar: image.source,
                                                imagemapcallId: index,
                                              });
                                            }
                                            const file = this.dataURLtoFile(
                                              image.source,
                                              image.name
                                            );

                                            this.avtarUpload(file);
                                          }}
                                        />
                                        {this.state.imagemapcallId === index &&
                                          this.state.avtarclick ? (
                                          <CheckCircleRoundedIcon
                                            style={{
                                              color: "#ed9e31",
                                              fontSize: 15,
                                              position: "relative",
                                              cursor: "pointer",
                                              left: "-5%",
                                            }}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )
                                  )}
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={this.state.uploadavtar.length != 0 ? 1 : 2}
                                style={orGrid}
                                className={`${this.props.classes.theme_font}`}
                              >
                                <div style={{ textAlign: "center" }}>or</div>
                              </Grid>
                              <Grid className={`upload_btn-mob ${this.props.classes.theme_font}`} item xs={3} style={uploadimage_btn_grid}>
                                <input
                                  accept="image/*"
                                  style={input}
                                  id="contained-button-file"
                                  type="file"
                                  multiple
                                  onChange={(event: React.ChangeEvent<any>) => {
                                    if (event.target.files[0]) {
                                      const self = this;
                                      const reader = new FileReader();
                                      var file = event.target.files[0];

                                      reader.onload = function (upload) {
                                        self.setState({
                                          avtar: upload.target!.result,
                                          uploadavtar: upload.target!.result,
                                          clickavtar: "",
                                          avtarclick: false,
                                        });
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                    this.handleUpload(event);
                                  }}
                                />
                                <label htmlFor="contained-button-file">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="uploadimage_btn"
                                    component="span"
                                  >
                                    Upload Image
                                  </Button>
                                </label>
                              </Grid>
                              {this.state.uploadavtar.length != 0 && (
                                <Grid xs={1} style={uploadimage_btn_grid} className={`${this.props.classes.theme_font}`}>
                                  <CancelIcon
                                    style={{
                                      color: "#ed9e31",
                                      height: 20,
                                      width: 20,
                                      position: "absolute",
                                      cursor: "pointer",
                                      top: "-15px",
                                      right: "-15px",
                                    }}
                                    onClick={() =>
                                      this.setState({ uploadavtar: "" })
                                    }
                                  />
                                  {this.state.uploadavtar.length != 0 && (
                                    <img
                                      src={this.state.uploadavtar}
                                      style={{ height: 50, width: 50 }}
                                    />
                                  )}
                                </Grid>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    className="arrowStyleButtonRight"
                    color="primary"
                    type="submit"
                  >
                    {this.state.loadingSpinner ? (
                      <CircularProgress style={spinnerBar} />
                    ) : (
                      <>
                        Create Account
                        <ArrowForwardRoundedIcon style={{ color: "white" }} />
                      </>
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(
  TeacherAccountRegistration as React.ComponentType<any>
);
// Customizable Area End