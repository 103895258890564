// Customizable Area Start
import React from "react";
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import {
    Avatar,
    Box,
    Button,
    Typography
} from "@material-ui/core";
import {
    Search,
} from "@material-ui/icons";
import {
    mathematics,
} from './assets';
import StudentSubjectsController, { Props } from "./StudentSubjectsController.web";
import { withStyles } from '@material-ui/core/styles';
import SelectedTab from '../SelectedTab.web';
import "./StudentSubject.web.css";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

export class StudentSubjects extends StudentSubjectsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }


    // Customizable Area Start
    render() {
        const { studentSubjects, listView } = this.state;
        return (
            <Box className="studentPart">
                <Box className="studentSubjouter">
                    <Box className="studentSubjinner">
                        <Typography className={`classesButtonText ${this.props.classes.heading_font}`}>
                            Classes
                        </Typography>
                        <Button variant="outlined" className={`${this.props.classes.image_Border_Color} moreInfoButton`}>
                            <InfoIcon className={`${this.props.classes.icon_color} moreInfoIcon`} />
                            <Typography className="moreInfoText">
                                More Info
                            </Typography>
                        </Button>
                    </Box>
                    <Box className="iconsOuter">
                        <Box className="iconsInner">
                            <Box display="flex" justifyContent="flex-end">
                                <FormatListBulletedIcon className={`ListIcon ${listView === 'list' ? this.props.classes.subjects_classAndSection_active_button_color : this.props.classes.subjects_classAndSection_normal_button_color}`}
                                    data-test-id={"listview-btn"}
                                    onClick={() => {
                                        this.setState({
                                            listView: 'list'
                                        })
                                    }}
                                />
                            </Box>
                            <Box className="borderIcon" display="flex" justifyContent="flex-end">
                                <BorderAllIcon className={`ListIcon ${listView === 'grid' ? this.props.classes.subjects_classAndSection_active_button_color : this.props.classes.subjects_classAndSection_normal_button_color}`}
                                    data-test-id={"gridview-btn"}
                                    onClick={() => {
                                        this.setState({
                                            listView: 'grid'
                                        })
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <SelectedTab type={'Subject'} isTeacher={false} />
                </Box>
                <Box display="flex" flexDirection="column" width="100%" className="mainBox">
                    <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
                        <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
                            <Box className={`searchBox ${this.props.classes.border_Color}`}
                                display="flex" alignItems="center">
                                <input className="Search-Subject" placeholder="Search subject"
                                    value={this.state.searchQuery}
                                    data-test-id={"search-item"}
                                    onChange={((event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ searchQuery: event.target.value }, () => {
                                        })
                                    })}
                                    onKeyPress={(event: any) => {
                                        this.handleKeyPress(event)
                                    }}
                                />
                                <Search className={`searchIcon ${this.props.classes.icon_color}`}
                                data-test-id={"search-icon"}
                                    onClick={() => {
                                      this.handleSearchBtnClick()
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>

                    {listView === 'list' &&
                        <div className="subjectListMainDiv">
                            <Box className={this.props.classes.button_color + " subjectBox"}>
                                <Typography className="subjectBoxTitle">
                                    Subject Name
                                </Typography>
                                <Typography className="subjectBoxTitle">
                                    Teacher
                                </Typography>
                            </Box>
                            {studentSubjects.length != 0 ? studentSubjects.map((studentSubject: any) => (
                                <Box className="subjectDataBox">
                                    <Box className="subjectSubjectimg">
                                        <img src={studentSubject?.attributes?.image != null ? studentSubject?.attributes?.image.file_path : mathematics}
                                            className="subjectIcon" />
                                        <Typography className="subjectName">
                                            {studentSubject.attributes.subject_name}
                                        </Typography>
                                    </Box>
                                    <Box width="60%" className="studentAvatar">
                                        <Avatar src={studentSubject?.attributes?.teacher?.avatar} className="teacherIcon" />
                                        <Typography className="teacherName">
                                            {studentSubject.attributes?.teacher?.name}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))
                                :
                                <Typography className="noRecordfound">
                                    No record(s) found.
                                </Typography>
                            }
                        </div>
                    }

                    {listView === 'grid' &&
                        <Box className="subjectGridouter">
                            <Box className="subjectGridouterbox">
                                <Typography className="classesButtonText">
                                    Subjects
                                </Typography>
                            </Box>
                            <Box className="subjectContentBox">
                                <Grid container spacing={2}>
                                    {studentSubjects.length != 0 ? studentSubjects.map((studentSubject: any,index:number) => (
                                            <Grid key={index} item xs={12} sm={6} md={4} lg={3} className="borderRadius8">
                                                <Box className="subjecBox">
                                                    <Box className="subjectImgBox">
                                                        <img className="subjectImg" src={studentSubject?.attributes?.image != null ? studentSubject?.attributes?.image.file_path : mathematics} />
                                                        <Box display="flex" flexDirection="column" >
                                                            <Typography className='subjectTitle'>
                                                                {studentSubject.attributes.subject_name}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Typography className='subjTilte'>Teacher:</Typography>
                                                    <Box className="questionList">
                                                        <Avatar className="questionListIcon" src={studentSubject?.attributes?.teacher?.avatar} />
                                                        <Typography className="questionListName">
                                                            {studentSubject?.attributes?.teacher?.name}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                    ))
                                        :
                                        <div className="noRecordfound">
                                            <span className={`${this.props.classes.bodyText_font}`}>
                                                No record(s) found.
                                            </span>
                                        </div>
                                    }
                                </Grid>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
    StudentSubjects as React.ComponentType<any>
)
// Customizable Area End