import React from "react";
// Customizable Area Start
import {
    StyleSheet,
    Platform,
} from "react-native";
import Picker from "emoji-picker-react";

import Grid from '@material-ui/core/Grid';
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    IconButton,
    Link,
    MenuItem,
    Select,
    NativeSelect,
    TextField,
    Typography,
    Popover
} from "@material-ui/core";
import {
    Search,
    Replay,
    NotificationsNone,
    HeadsetMicOutlined,
    Title,
    TitleOutlined,
    KeyboardArrowLeft,
    EditOutlined,
    MoreVertOutlined,
    KeyboardArrowDown,
    DesktopAccessDisabledOutlined,
    ArrowDropDown
} from "@material-ui/icons";
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import ShareScreenController, { Props } from "./ShareScreenController.web";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FileDrop } from 'react-file-drop';
import { grid } from "../../ClassesAndSections/src/Attendance/assets";
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    EmailShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    EmailIcon,
    WhatsappIcon,
} from "react-share";
import {
    facebook,
    sharechat,
    twitter,
    email,
    copylink,
    linkedin
} from './assets';
// Customizable Area End

// Customizable Area Start
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
// Customizable Area End

export default class ShareScreen extends ShareScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    render() {
        return (
            <>
                <Popover
                    id="2"
                    anchorEl={this.props.shareScreenAnchorEl}
                    open={Boolean(this.props.shareScreenAnchorEl)}
                    onClose={this.props.popoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    style={{
                        marginTop: '20px',
                    }}
                >
                    <Grid container style={{
                        textAlign: "center",
                        padding: "20px 20.5px 20px 20px",
                        borderRadius: 8,
                        boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.25)",
                        backgroundColor: "#fff"
                    }}>
                        {/* <Grid item xs={4}>
                            <FacebookShareButton url={`${process.env.REACT_APP_BASE_URL}ViewPost/${this.props.id}?type=${this.props.type}`}>
                                <img src={facebook} style={{ width: 25, height: 25 }} />
                                <Typography style={{
                                    fontSize: "0.875rem",
                                    color: "#3f526d",
                                }}>
                                    Facebook
                                </Typography>
                            </FacebookShareButton>
                        </Grid>
                        <Grid item xs={4}>
                            <TwitterShareButton url={`${process.env.REACT_APP_BASE_URL}ViewPost/${this.props.id}?type=${this.props.type}`}>
                                <img src={twitter} style={{ width: 25, height: 25 }} />
                                <Typography style={{
                                    fontSize: "0.875rem",
                                    color: "#3f526d",
                                }}>
                                    Twitter
                                </Typography>
                            </TwitterShareButton>
                        </Grid>
                        <Grid item xs={4}>
                            <LinkedinShareButton url={`${process.env.REACT_APP_BASE_URL}ViewPost/${this.props.id}?type=${this.props.type}`}>
                                <img src={linkedin} style={{ width: 25, height: 25 }} />
                                <Typography style={{
                                    fontSize: "0.875rem",
                                    color: "#3f526d",
                                }}>
                                    Linked In
                                </Typography>
                            </LinkedinShareButton>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: 16 }}>
                            <EmailShareButton url={`${process.env.REACT_APP_BASE_URL}ViewPost/${this.props.id}?type=${this.props.type}`}>
                                <img src={email} style={{ width: 25, height: 25 }} />
                                <Typography style={{
                                    fontSize: "0.875rem",
                                    color: "#3f526d",
                                }}>
                                    Email
                                </Typography>
                            </EmailShareButton>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: 16 }}>
                            <img src={sharechat} style={{ width: 25, height: 25 }} />
                            <Typography style={{
                                fontSize: "0.875rem",
                                color: "#3f526d",
                            }}>
                                Chats
                            </Typography>
                        </Grid> */}
                        <Grid item xs={4} style={{ marginTop: 16 }}>
                            <img src={copylink} style={{ width: 25, height: 25, cursor: "pointer" }}
                                onClick={
                                    () => { navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}ViewPost/${this.props.id}?type=${this.props.type}`) }
                                }
                            />
                            <Typography style={{
                                fontSize: "0.875rem",
                                color: "#3f526d",
                            }}>
                                Copy link
                            </Typography>
                        </Grid>

                    </Grid>

                </Popover>
            </>
        )
    }
    // Customizable Area End
}
