// Customizable Area Start
import React from 'react';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { withStyles } from '@material-ui/core/styles';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface IProps {
  // Customizable Area Start
  data: any;
  classes: any;
  // Customizable Area End
}

// Customizable Area Start
const useStyles = makeStyles({});
export const StudentProgress: React.FC<IProps> = ({ data, classes }) => {
  const innerClasses = useStyles();
  return (
    <Box marginTop={3}>
      <Typography className={classes.titleFont}>
        Students Progress over time
      </Typography>
      <TableContainer className="t-assessment-table table-container-progress">
        {/* Table */}
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.table_head_cell}>
                Students
              </TableCell>
              <TableCell className={classes.table_head_cell}>Class</TableCell>
              <TableCell className={classes.table_head_cell}>
                Division
              </TableCell>
              <TableCell className={classes.table_head_cell}>
                End-of-Module 3
                <br />
                Assessment (Topic B)
              </TableCell>
              <TableCell className={classes.table_head_cell}>
                Topic A: Writing and Solving Linear Equations
              </TableCell>
              <TableCell className={classes.table_head_cell}>
                Topic B: Linear Equations in two Variables and their graphs
              </TableCell>
              <TableCell className={classes.table_head_cell}>
                End of Module 1
                <br />
                Assessment (Topic B)
              </TableCell>
              <TableCell className={classes.table_head_cell}>
                End of Module 2
                <br />
                Assessment (Topic A)
              </TableCell>

              {/* <TableCell className={classes.bodyText_font} style={{ padding: "4px 5px 4px 5px", fontSize: 20, fontWeight: 600, borderBottom: 0 , borderBottomRightRadius:'10px' }}>&nbsp;</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody className={classes.table_body}>
            {/* {
                    assessmentList?.length === 0 || assessmentList === undefined
                      ?
                      <TableRow>
                        <TableCell
                          style={{ padding: "17px 0px 16px 20px", color: "#3f526d", fontFamily: 'Open sans', fontSize: 18 }}
                          colSpan={8}
                          align="center"
                        >
                          No record(s) found.
                        </TableCell>
                      </TableRow>
                      :
                      <>
                        {assessmentList?.map((data: any) => ( */}
            {Array.from({ length: 20 }).map((itm, indx) => {
              return (
                <>
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      className={classes.table_cell_first}
                    />
                  </TableRow>
                  <TableRow className={classes.table_row}>
                    <TableCell className={classes.table_cell}>
                      Azer, Melida
                    </TableCell>
                    <TableCell className={classes.table_cell_capitalize}>
                      Class
                    </TableCell>
                    <TableCell className={classes.table_cell_capitalize}>
                      {moment().format('MMM DD, YYYY')}
                    </TableCell>
                    <TableCell className={classes.table_cell_capitalize}>
                      15
                    </TableCell>
                    <TableCell className={classes.table_cell_capitalize}>
                      8
                    </TableCell>
                    <TableCell className={classes.table_cell_capitalize}>
                      28
                    </TableCell>
                    <TableCell
                      className={classes.table_cell_clickable}
                      // onClick={() => this.openAssessmentEvaluation(data?.id)}
                    >
                      30
                    </TableCell>
                    <TableCell
                      className={classes.table_cell_clickable}
                      // onClick={() => this.openAssessmentEvaluation(data?.id)}
                    >
                      30
                    </TableCell>

                    {/* <TableCell style={{ padding: "4px 5px 4px 5px", border: 0 }}>
                                {
                                  (data.attributes.status.toLowerCase() === 'save as draft' || data.attributes.status.toLowerCase() === 'pending') ?
                                    <AssessmentActionMenu assessment_id={data.id} handleEditAssessment={(id: any) => this.handleEditAssessment(id)} />
                                    : ''
                                }

                              </TableCell> */}
                  </TableRow>
                </>
              );
            })}
            {/* ))}
                      </>
                   } */}
          </TableBody>
        </Table>
        <ReactPaginate
          previousLabel={
            <span>←{/* <span className="xs_display">Previous</span> */}</span>
          }
          nextLabel={
            <span>{/* <span className="xs_display">Next</span>  */}→</span>
          }
          breakLabel="..."
          initialPage={0}
          // pageCount={this.state.assessmentListMeta?.pagination?.total_pages}
          pageCount={3}
          // onPageChange={(e: any) => {
          //   if (e.selected !== this.state.page) {
          //     this.setState({ page: e.selected }, () => this.getAssessmentList())
          //   }
          // }}
          containerClassName={`${classes.Pagination_PaginationBttns}`}
          previousLinkClassName={`${classes.Pagination_PreviousBttn}`}
          nextLinkClassName={`${classes.Pagination_NextBttn}`}
          disabledClassName={`${classes.Pagination_PaginationDisabled}`}
          activeClassName={`${classes.Pagination_PaginationActive}`}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
        />
        {/* Table END */}
      </TableContainer>
    </Box>
  );
};

export default withStyles(themeCreateStyle)(StudentProgress);
// Customizable Area End
