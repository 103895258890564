// Customizable Area Start
import React from 'react';
import {
    Grid,
    Modal,
    Button,
    withStyles,
    TextField,
    Typography,
    Box,
    Tooltip,
} from "@material-ui/core";
import './AssessmentSummaryModal.web.css';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import { difficultyCopyIcon, difficultyIcon, sectionIcon } from '../assets';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export interface Props {
    navigation?: any;
    id?: string;
    location?: any;
    // Customizable Area Start
    classes?: any;
    assessmentSummary: any;
    summaryModal: boolean;
    onCloseModal: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export class AssessmentSummaryModal extends React.Component<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
        };
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    render() {
        const { assessmentSummary, classes } = this.props;
        return <>
            <Modal
                open={this.props.summaryModal}
                onClose={this.props.onCloseModal}
            >
                <div className='SummaryModalMainDiv'>
                    <Typography className={`TitleText ${classes.heading_font}`} >
                        Assessment Summary
                    </Typography>

                    <Box className='ContentBox'>
                        <Grid container spacing={2}>
                            {/* Assessment Name */}
                            <Grid item xs={12}>
                                <Typography className={`SubTitleText ${classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                    Assessment Title
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    placeholder=""
                                    value={assessmentSummary?.assessment?.assessment_name}
                                    disabled={true}
                                    defaultValue=""
                                    className={classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                    inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                />
                            </Grid>
                            {/* Assessment Name END */}

                            <Grid item xs={12} className='DetailColumnGrid'>
                                <Grid container spacing={1}>
                                    {/* Subject */}
                                    <Grid item xs={12} sm={4}>
                                        <Typography className={`DetailItemLabel ${classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`} >
                                            Subject
                                        </Typography>

                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            type="text"
                                            defaultValue=""
                                            value={assessmentSummary?.subject?.subject_name}
                                            disabled={true}
                                            className={classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />

                                    </Grid>
                                    {/* Subject END */}

                                    {/* Class & Division */}
                                    <Grid item xs={12} sm={4}>

                                        <Typography className={`DetailItemLabel ${classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                            Class
                                        </Typography>

                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            type="text"
                                            defaultValue=""
                                            value={assessmentSummary?.grade?.attributes?.name}
                                            disabled={true}
                                            className={classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />
                                    </Grid>
                                    {/* Class & Division END */}

                                    {/* Exam Type */}
                                    <Grid item xs={12} sm={4}>
                                        <Typography className={`DetailItemLabel ${classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                            Exam Type
                                        </Typography>

                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            type="text"
                                            defaultValue=""
                                            value={assessmentSummary?.assessment?.exam_type}
                                            disabled={true}
                                            className={classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />
                                    </Grid>
                                    {/* Exam Type END */}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className='ContentDetailGrid'>
                                <Grid container spacing={1}>
                                    {/* Max marks */}
                                    <Grid item xs={12} sm={4}>
                                        <Typography className={`DetailItemLabel ${classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                            Max marks
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            type="text"
                                            defaultValue=""
                                            value={assessmentSummary?.assessment?.maximum_marks}
                                            disabled={true}
                                            className={classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />
                                    </Grid>
                                    {/* Max marks END */}
                                    {/* Terms */}
                                    <Grid item xs={12} sm={4}>
                                        <Typography className={`DetailItemLabel ${classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                            Terms
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            type="text"
                                            defaultValue=""
                                            value={assessmentSummary?.term?.title}
                                            disabled={true}
                                            className={classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />
                                    </Grid>
                                    {/* Terms END */}

                                    {/* Include Negative Marking */}
                                    <Grid item xs={12} sm={4} >
                                        <Tooltip title="Negative Marking Included">
                                        <Typography className={`DetailItemLabelNegativeMarking ${classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>Negative Marking Included</Typography>
                                        </Tooltip>
                                        <Box
                                            className={classes.studentAssessmentSummary_AssessmentTest_BoxStyle}
                                        >
                                            <Typography
                                                className={`NegativeMarkingText ${classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>{assessmentSummary?.assessment?.negative_marking ? 'Yes' : 'No'}</Typography>
                                        </Box>
                                    </Grid>
                                    {/* Include Negative Marking END */}

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className='ContentDetailGrid'>
                                <Typography
                                    className={`DetailSectionTitle ${classes.heading_font}`}
                                >
                                    Question paper Description
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className='ContentDetailGrid'>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                        <Box className='studentAssessmentSummary_txtDiv'>
                                            <img src={difficultyIcon} className='IconImage' />
                                            <Typography
                                                style={{ marginLeft: 20 }}
                                                className={`DetailLabelText ${classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                                Difficulty Level
                                            </Typography>
                                        </Box>

                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            type="text"
                                            defaultValue=""
                                            value={assessmentSummary?.assessment?.difficulty_level}
                                            disabled={true}
                                            className={classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box className='studentAssessmentSummary_txtDiv'>
                                            <img src={difficultyCopyIcon} className='IconImage' />
                                            <Typography
                                                className={`DetailLabelText ${classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                                Total Duration
                                            </Typography>
                                        </Box>
                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            defaultValue=""
                                            value={assessmentSummary?.assessment?.time_limit}
                                            disabled={true}
                                            className={`studentAssessmentSummary_textField ${classes.studentAssessmentSummary_AssessmentTest_textFieldNum}`}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className='ContentDetailGrid'>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Box className='ContentDetailTitleBox'>
                                            <img src={sectionIcon} className='IconImage' />
                                            <Typography
                                                className={`DetailLabelText ${classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                                Section
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box className='ContentDetailTitleBox'>
                                            <Typography
                                                className={classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}>
                                                Total Marks
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {assessmentSummary?.assessment_sections?.map((sectionData: any) => (
                                <Grid key={sectionData?.id} item xs={12} className='ContentDetailGrid'>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6} sm={4}>
                                            <TextField
                                                variant="outlined"
                                                placeholder=""
                                                defaultValue=""
                                                value={sectionData?.name}
                                                disabled={true}
                                                className={classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                                inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <TextField
                                                variant="outlined"
                                                placeholder=""
                                                defaultValue=""
                                                value={sectionData?.section_marks}
                                                disabled={true}
                                                className={classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                                inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}


                            <Grid item xs={12} className="ActionBtnGrid">
                                <Button data-test-id="PreviewOkButton" variant="contained" className={`OkButton ${this.props.classes.button_color}`} onClick={this.props.onCloseModal}><span className={`ButtonText`}>Ok</span></Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Modal>
        </>
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(AssessmentSummaryModal);
// Customizable Area End