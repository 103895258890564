// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  NativeSelect,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import { ArrowDropDown } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import moment from 'moment';
import React from 'react';
import Calendar from 'react-calendar';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
import HOCAuth from '../../shared/Header/src/HOCAuth.web';
import {
  birthday_bg, book, edit_note_img, EVENTS_NO_DATA, ic_clock, ic_graduate, ic_homework, ic_message, ic_presentation, imgZoomDisabledIcon,
  imgZoomIcon, impBlackBoardDisabledIcon,
  impBlackBoardIcon, note, NOTES_NO_DATA, NOTIFICATIONS_NO_DATA, SUBJECTS_NO_DATA, thoughts_bg, UPCOMING_CLASSES_NO_DATA
} from './assets';
import CreateNotes from './CreateNotes.web';
import EditNote from './EditNote.web';
import './TeacherDashboard.web.css';
import TeacherDashboardController, {
  Props
} from './TeacherDashboardController.web';
import ViewAllEventsModal from "./ViewAllEventsModal";
import ViewAllNotesModal from './ViewAllNotesModal.web';
import ViewThoughtModalWeb from './ViewThoughtModal.web';
export const configJSON = require('./config');
// Customizable Area End

// Customizable Area Start
const BootstrapInput2 = withStyles((theme: any) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    backgroundColor: theme.palette.background.paper,
    padding: '10px 26px 10px 12px',
    width: '100%',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: theme.props.textFont,
    color: theme.props.textColor,
    fontSize: 18,
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);
// Customizable Area End
export class TeacherDashboard extends TeacherDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      data,
      gradeData,
      reminders,
      schoolEvents,
      dinvisionData,
      response_data,
      teacherupcomingClasses,
      teacherClasses,
      teacherNotes,
      teacherAssegments,
      teacherSubjects,
    } = this.state;
    const reminderEvents: any = reminders?.events;
    const reminderThoughts: any = reminders?.thoughts;
    const noReminderData = reminderEvents || reminderThoughts;
    const BootstrapInput5 = withStyles((theme: any) => ({
      root: {
        'label + &': {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 9,
        position: 'relative',
        border: 'solid 1px #e6e6e6',
        backgroundColor: '#fff',
        padding: '10px 23.2px 11px 17.5px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        fontWeight: 600,
        fontSize: 18,
        '&:focus': {
          borderRadius: 4,
          borderColor: '#80bdff',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
      },
    }))(InputBase);
    return (
      <Box
        className="fluid-flex"
        style={{ display: 'flex', width: '100%', marginTop: '25px', paddingBottom: '20px' }}
      >
        <Box
          className="leftsidebar-col"
        // style={{
        //   display: 'flex',
        //   flexDirection: 'column',
        //   width: '70%',
        //   paddingRight: 35,
        //   marginTop: 30,
        // }}
        >
          <Grid className='teacher-profile-card'
            container
            alignItems="center"
          >
            <Grid item>
              {data.avatar ? (
                <img
                  src={data.avatar}
                  className={this.props.classes.image_Border_Color}
                  style={{
                    cursor: 'pointer',
                    borderRadius: '50%',
                    height: 100,
                    width: 100,
                    padding: 3,
                  }}
                />
              ) : (
                <Avatar
                  src={'/'}
                  className={this.props.classes.image_Border_Color}
                  style={{
                    cursor: 'pointer',
                    height: 100,
                    width: 100,
                    padding: 3,
                  }}
                />
              )}
            </Grid>
            <Grid>
              <Box
                className="TeachaerInfo"
                display="flex"
                flexDirection="column"
                ml="65px"
              >
                <Typography
                  style={{
                    fontSize: 27,
                  }}
                >
                  <span
                    className={this.props.classes.heading_font}
                    style={{ fontSize: 27, fontWeight: 600 }}
                  >
                    Hello,{' '}
                  </span>
                  <span className={this.props.classes.heading_font}>
                    {data?.first_name} {data?.last_name}
                  </span>
                </Typography>
                <Typography
                  style={{
                    fontSize: 18,
                  }}
                  className={this.props.classes.subHeading_font}
                >
                  {data?.type === 'TeacherAccount' ? (
                    <>Teacher</>
                  ) : (
                    <>Student</>
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            className="GridBox"
            container
            spacing={1}

          >
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              spacing={1}
              style={{ marginBottom: '20px' }}
            >
              <Grid
                className="Colxs8"
                item
                xs={12}
                style={{
                  display: 'flex',
                }}
              >
                <Grid className='card-subject'
                  item

                  style={{
                    // padding: '15px 13px 10px 14px',
                    // width: 170,
                    // borderRadius: 10,
                    backgroundImage: `url(${book})`,
                    // backgroundSize: 'cover',

                  }}
                >
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: 'Open sans',
                      fontWeight: 600,
                      color: 'white',
                    }}
                  >
                    My Classes
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: 'Open sans',
                      fontWeight: 600,
                      color: 'rgba(255, 255, 255, 0.88)',
                      marginTop: 4,
                    }}
                  >
                    {teacherAssegments?.active_classes
                      ? teacherAssegments?.active_classes
                      : 0}{' '}
                    active
                  </Typography>
                </Grid>
                <Grid
                  className="MyClasses card-subject"
                  item

                  style={{
                    // padding: '15px 13px 10px 14px',
                    // width: 170,
                    // borderRadius: 10,
                    backgroundImage: `url(${note})`,
                    // backgroundSize: 'cover',
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: 'Open sans',
                      fontWeight: 600,
                      color: 'white',
                    }}
                  >
                    Assignment
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: 'Open sans',
                      fontWeight: 600,
                      color: 'rgba(255, 255, 255, 0.88)',
                      marginTop: 4,
                    }}
                  >
                    {teacherAssegments?.assessments_count
                      ? teacherAssegments?.assessments_count
                      : 0}{' '}
                    active
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <NativeSelect
                className="SelectMenu"
                id="select"
                value={this.state.gradeName}
                input={<BootstrapInput5 />}
                style={{
                  width: '30%',
                  marginRight: 32,
                }}
                IconComponent={ArrowDropDown}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  if (event.target.value != '') {
                    this.get_teacher_division(event.target.value);
                  }
                  else {
                    this.setState({
                      divisionName: '',
                      gradeName: '',
                      teacherSubjects: [],
                      dinvisionData: [],
                    })
                  }
                }}
              >
                <option value="" style={{ color: 'rgb(63, 82, 109,0.32)' }}>
                  Select Grade
                </option>
                {gradeData.map((grade: any, index: number) => (
                  <option value={grade.id} key={grade.id}>
                    {grade.attributes.school_grade_name}
                  </option>
                ))}
              </NativeSelect>
              <NativeSelect
                className="SelectMenu"
                id="select"
                value={this.state.divisionName}
                input={<BootstrapInput5 />}
                style={{
                  width: '30%',
                }}
                IconComponent={ArrowDropDown}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  if (event.target.value != '') {
                    this.get_teacher_subjectbygradeAndDivision(
                      event.target.value
                    );
                  }
                  else {
                    this.setState({
                      divisionName: '',
                      teacherSubjects: []
                    })
                  }
                }}
              >
                <option value="" style={{ color: 'rgb(63, 82, 109,0.32)' }}>
                  Select Division
                </option>
                {dinvisionData.map((divison: any, index: number) => (
                  <option value={divison.id} key={index}>{divison.attributes.name}</option>
                ))}
              </NativeSelect>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                maxHeight: 480,
                overflowY: 'auto'
              }}
            >
              {teacherSubjects?.length < 0 || teacherSubjects?.length === 0 ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      marginTop: '7%',
                    }}
                  >
                    <img
                      style={{ height: '182px', objectFit: 'contain' }}
                      src={SUBJECTS_NO_DATA}
                      alt="No data found"
                    />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      marginTop: '7%',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Open sans',
                        opacity: 0.5,
                        fontSize: '22px',
                        fontWeight: 600,
                        color: '#3f526d',
                      }}
                    >
                      No Data Found!
                    </span>
                  </div>
                </>
              ) : (
                teacherSubjects.map((subject: any, index: number) => (
                  <Box
                    className="ScrollTb asslist"
                    style={{
                      display: 'flex',
                      borderRadius: 8,
                      marginTop: 24,

                      backgroundColor: '#f9f8fd',
                      flexDirection: 'row',

                    }}
                    key={index}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        src={subject?.subject?.image?.file_path}
                        variant="rounded"
                        style={{ height: 70, width: 70 }}
                      />
                    </Box>
                    <Box
                      className="Md-col"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Typography
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                        }}
                        className={this.props.classes.title_font}
                      >
                        {subject.subject.name}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 'normal',
                          marginTop: 2,
                        }}
                        className={this.props.classes.subTitle_font}
                      >
                        ({subject.class_name})
                      </Typography>

                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '15px',
                        }}
                      >
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            src={ic_presentation}
                            variant="square"
                            style={{ height: 13, width: 13 }}
                          />
                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight: 'normal',
                              marginLeft: 5,
                            }}
                            className={this.props.classes.bodyText_font}
                          >
                            {subject.units} Units
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '35px',
                          }}
                        >
                          <Avatar
                            src={ic_homework}
                            variant="square"
                            style={{ height: 13, width: 13 }}
                          />
                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight: 'normal',
                              marginLeft: 5,
                            }}
                            className={this.props.classes.bodyText_font}
                          >
                            {subject.assessments} Assignments
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '35px',
                          }}
                        >
                          <Avatar
                            src={ic_clock}
                            variant="square"
                            style={{ height: 13, width: 13 }}
                          />
                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight: 'normal',
                              marginLeft: 5,
                            }}
                            className={this.props.classes.bodyText_font}
                          >
                            {subject.hours} Hours
                          </Typography>
                        </Box>

                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '35px',
                          }}
                        >
                          <Avatar
                            src={ic_graduate}
                            variant="square"
                            style={{ height: 13, width: 13 }}
                          />
                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight: 'normal',
                              marginLeft: 5,
                            }}
                            className={this.props.classes.bodyText_font}
                          >
                            {subject.students} Students
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress
                        thickness={4}
                        variant="determinate"
                        value={47}
                        style={{ width: 70, height: 70 }}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        style={{
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          style={{
                            fontSize: 19,
                            fontWeight: 600,
                          }}
                          className={this.props.classes.bodyText_font}
                        >
                          47%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))
              )}
            </Grid>
          </Grid>
          <Grid
            className="GridBox"
            container
          >
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
                className={this.props.classes.heading_font}
              >
                Upcoming Classes
              </Typography>
              <NativeSelect
                className="SelectMenu SelectMenu2"
                id="select"
                value={this.state.selectedClass}
                input={<BootstrapInput5 />}
                style={{
                  width: '28%',
                }}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  this.get_upcoming_classes(event.target.value);
                }}
              >
                <option value="" style={{ color: 'rgb(63, 82, 109,0.32)' }}>
                  Select Class
                </option>
                {teacherClasses.map((grade: any, index: number) => (
                  <option value={grade.id} key={index}>{grade.display_name}</option>
                ))}
              </NativeSelect>
            </Grid>
            <Grid
              className="Upcoming-Classes"
              item
              xs={12}
              style={{
                padding: '24px 18px 13px 16px',
                borderRadius: 13,
                backgroundColor: '#f9f8fd',
                marginTop: 30,
                maxHeight: '480px',
                overflowY: 'auto'
              }}
            >
              {teacherupcomingClasses?.length > 0 && (
                <Grid container>
                  <Grid
                    item
                    xs={2}
                    style={{
                      fontSize: 11,
                      letterSpacing: 0.26,
                    }}
                    className={this.props.classes.subHeading_font}
                  >
                    Subject
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{
                      fontSize: 11,
                      letterSpacing: 0.26,
                    }}
                    className={this.props.classes.subHeading_font}
                  >
                    Class
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{
                      fontSize: 11,
                      letterSpacing: 0.26,
                      textAlign: 'center',
                    }}
                    className={this.props.classes.subHeading_font}
                  >
                    Division
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      fontFamily: 'Open sans',
                      fontSize: 11,
                      letterSpacing: 0.26,
                      textAlign: 'center',
                    }}
                    className={this.props.classes.subHeading_font}
                  >
                    Time
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      fontSize: 11,
                      letterSpacing: 0.26,
                      textAlign: 'center',
                    }}
                    className={this.props.classes.subHeading_font}
                  >
                    Date
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      fontSize: 11,
                      letterSpacing: 0.26,
                      textAlign: 'center',
                    }}
                    className={this.props.classes.subHeading_font}
                  >
                    Status
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      fontSize: 11,
                      letterSpacing: 0.26,
                      textAlign: 'center',
                    }}
                    className={this.props.classes.subHeading_font}
                  >
                    Class Type
                  </Grid>
                </Grid>
              )}
              {teacherupcomingClasses?.length === 0 && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      marginTop: '7%',
                    }}
                  >
                    <img
                      style={{ height: '273px', objectFit: 'contain' }}
                      src={UPCOMING_CLASSES_NO_DATA}
                      alt="No data found"
                    />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      marginTop: '5%',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Open sans',
                        opacity: 0.5,
                        fontSize: '22px',
                        fontWeight: 600,
                        color: '#3f526d',
                      }}
                    >
                      No Upcoming Classes Found!
                    </span>
                  </div>
                </>
              )}
              {teacherupcomingClasses?.map((upcoming: any, index: number) => (
                <Grid
                  container
                  style={{
                    marginTop: 30,
                    paddingBottom: 9,
                    borderBottom: '1px solid #d8d8d8',
                    alignItems: 'center',
                  }}
                  key={index}
                >
                  <Grid
                    item
                    xs={2}
                    className={this.props.classes.bodyText_font}
                  >
                    {
                      upcoming?.data?.attributes?.subject.data?.attributes
                        ?.subject_name
                    }
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className={this.props.classes.bodyText_font}
                  >
                    {upcoming?.data?.attributes?.display_name?.split('-')[0]}
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className={this.props.classes.bodyText_font}
                    style={{ textAlign: 'center' }}
                  >
                    {upcoming?.data?.attributes?.display_name?.split('-').pop()}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={this.props.classes.bodyText_font}
                    style={{ textAlign: 'center' }}
                  >
                    {upcoming?.data?.attributes?.start_date
                      ? moment(upcoming?.data?.attributes?.start_date).format("hh:mm A")
                      : '--'}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={this.props.classes.bodyText_font}
                    style={{ textAlign: 'center' }}
                  >
                    {upcoming?.data?.attributes?.start_date
                      ? moment(upcoming?.data?.attributes?.start_date).format(
                        'DD-MM-YYYY'
                      )
                      : '--'}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'center' }}>
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: 500,
                        padding: '4px 16px 3px',
                        borderRadius: 4,
                        color: '#7c3d2d',
                        backgroundColor: '#ffece7',
                      }}
                    >
                      Upcoming
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        src={
                          upcoming?.data?.attributes?.is_online
                            ? impBlackBoardDisabledIcon
                            : impBlackBoardIcon
                        }
                        variant="square"
                        style={{
                          height: 20,
                          width: 20,
                          cursor: upcoming?.data?.attributes?.is_online
                            ? 'not-allowed'
                            : 'pointer',
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: 25,
                          fontFamily: 'Open sans',
                          fontWeight: 'normal',
                          color: '#3f526d',
                        }}
                      >
                        /
                      </Typography>
                      <Avatar
                        src={
                          upcoming?.data?.attributes?.is_online
                            ? imgZoomIcon
                            : imgZoomDisabledIcon
                        }
                        style={{
                          height: 20,
                          width: 20,
                          cursor: upcoming?.data?.attributes?.is_online
                            ? 'pointer'
                            : 'not-allowed',
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Box
            className="GridBox"
            style={{

              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 'bold',
              }}
              className={this.props.classes.heading_font}
            >
              Reminder & Quote
            </Typography>
            {noReminderData && (
              <Box
                style={{
                  overflowX: 'auto',
                  display: 'flex',
                }}
              >
                <Box className='reminder-quote-outer'
                  style={{
                    display: 'flex',
                    marginTop: '23px',


                  }}
                >
                  {reminderEvents.map((reminderevent: any, index: number) => (
                    <Box
                      style={{
                        padding: 30,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 15,
                        minWidth: 280,
                        height: 200,
                        backgroundImage: `url(${birthday_bg})`,
                        backgroundSize: 'cover',
                        marginLeft: '10px',
                        marginRight: '10px',
                      }}
                      key={index}
                    >
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "white",
                          textTransform: 'capitalize'
                        }}
                        className={this.props.classes.subHeading_font}
                      >
                        {reminderevent.attributes.name[0].toUpperCase()}{reminderevent.attributes.name.slice(1)}
                      </Typography>

                      <Typography
                        style={{
                          fontSize: 23,
                          fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "white",
                          width: 288,
                          marginTop: 23,
                        }}
                        className={this.props.classes.bodyText_font}
                      >
                        {reminderevent.attributes.user_name}
                      </Typography>

                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "white",
                          marginTop: 10,
                        }}
                        className={this.props.classes.bodyText_font}
                      >
                        {moment(reminderevent.attributes.start_time).format('LL')}
                      </Typography>
                    </Box>
                  ))}

                  {reminderThoughts.map((reminderthought: any, index: number) => {
                    return (
                      <Box className='reminder-quote-outer'
                        style={{
                          padding: 30,
                          width: 400,
                          height: 200,
                          display: 'flex',
                          flexDirection: 'column',
                          borderRadius: 15,
                          backgroundImage: `url(${thoughts_bg})`,
                          backgroundSize: 'cover',
                          marginLeft: '10px',
                          marginRight: '10px',
                        }}
                        key={index}
                      >
                        <Typography
                          style={{
                            fontSize: 19,
                            fontFamily: "Open sans",
                            fontWeight: "bold",
                            color: "white",
                          }}
                          className={this.props.classes.subHeading_font}

                        >
                          Thought for the day
                        </Typography>

                        <Typography
                          style={{
                            // fontSize: 16,
                            // fontWeight: 'bold',
                            fontFamily: "Open sans",
                            color: "white",
                            marginTop: 20,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            height: 93,
                            display: '-webkit-box',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            this.setState({ mainReminder: reminderthought, mainReminderOpen: true })
                          }}
                          className={this.props.classes.bodyText_font}
                          dangerouslySetInnerHTML={{ __html: reminderthought.attributes.thought_title }}
                        />
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            )}
            {!reminderThoughts && (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: '7%',
                  }}
                >
                  <img
                    style={{ height: '273px', objectFit: 'contain' }}
                    src={UPCOMING_CLASSES_NO_DATA}
                    alt="No data found"
                  />
                </div>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '5%',
                  }}
                >
                  <span
                    style={{
                      fontFamily: 'Open sans',
                      opacity: 0.5,
                      fontSize: '22px',
                      fontWeight: 600,
                      color: '#3f526d',
                    }}
                  >
                    No Reminder & quotes Found!
                  </span>
                </div>
              </>
            )}
          </Box>
        </Box>
        <Box
          className="right-sidebar"
        // style={{
        //   display: 'flex',
        //   flexDirection: 'column',
        //   width: '30%',
        //   marginTop: 30,
        // }}
        >
          <Box
            style={{
              borderRadius: 10,
              display: 'flex',
              flexDirection: 'column',
              padding: '22px 34px 22px 26.5px',
              background: 'linear-gradient(73deg, #f7627b -8%, #f38d9e 125%)',
            }}
          >
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={ic_message} style={{ height: 37, width: 37 }} />
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  marginLeft: 22,
                  color: '#fff'
                }}
                className={this.props.classes.heading_font}
              >
                Notification
              </Typography>
            </Box>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                marginTop: '7%',
              }}
            >
              <span
                style={{
                  width: '62px',
                  height: '62px',
                  borderRadius: '50%',
                  backgroundColor: '#cccccc33',
                  padding: '17px',
                }}
              >
                <img
                  style={{ height: '28px', objectFit: 'contain' }}
                  src={NOTIFICATIONS_NO_DATA}
                  alt="No data found"
                />
              </span>
            </div>
            <div
              style={{ width: '100%', textAlign: 'center', marginTop: '7%' }}
            >
              <span
                style={{
                  fontFamily: 'Open sans',
                  opacity: 0.5,
                  fontSize: '16px',
                  color: '#fff',
                }}
              >
                No Notification Yet!
              </span>
            </div>
          </Box>
          <Box className='cal-pd'
            style={{

              borderRadius: 20,
              boxShadow: '0 8px 27px 0 rgba(224, 224, 224, 0.5)',
              backgroundColor: '#fff',
              marginTop: 30,
            }}
          >
            <Typography
              style={{
                fontSize: 21,
                fontWeight: 600,
                marginBottom: 15,
              }}
              className={this.props.classes.heading_font}
            >
              Calendar
            </Typography>
            <Grid item>
              <Calendar
                className="noborder full-width"
                value={this.state.calendarDate}
                onChange={(date: any) => {
                  this.setState({ calendarDate: new Date(date) }, () => {
                    this.get_timetable();
                    this.get_school_events();
                  });
                }}
                next2Label={null}
                prev2Label={null}
                calendarType="US"
              />
              <Box
                style={{
                  marginTop: 30,
                  marginBottom: 12,
                  textAlign: 'end',
                }}
              >
                <NativeSelect
                  id="select"
                  input={<BootstrapInput2 />}
                  IconComponent={KeyboardArrowDownIcon}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    if (event.target.value === 'Today') {
                      this.setState(
                        { dateoftoday: 'Today', calendarDate: new Date() },
                        () => {
                          this.get_school_events();
                        }
                      );
                    } else if (event.target.value === 'Tomorrow') {
                      let tomorrow = new Date();
                      tomorrow.setDate(new Date().getDate() + 1);
                      this.setState(
                        { dateoftoday: 'Tomorrow', calendarDate: tomorrow },
                        () => {
                          this.get_school_events();
                        }
                      );
                    }
                  }}
                >
                  <option value="Today">Today</option>
                  <option value="Tomorrow">Tomorrow</option>
                </NativeSelect>
              </Box>
            </Grid>
            <Box
              style={{
                padding: '20px 17px 2px 18px',
                backgroundColor: 'rgba(124, 106, 247, 0.27)',
                borderLeft: '3px solid #7c6af7',
              }}
            >
              {schoolEvents?.length === 0 && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      marginTop: '7%',
                    }}
                  >
                    <img
                      style={{ height: '180px', objectFit: 'contain' }}
                      src={EVENTS_NO_DATA}
                      alt="No data found"
                    />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      marginTop: '7%',
                      marginBottom: '7%',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Open sans',
                        opacity: 0.5,
                        fontSize: '22px',
                        fontWeight: 600,
                        color: '#3f526d',
                      }}
                    >
                      No Event Found!
                    </span>
                  </div>
                </>
              )}
              {schoolEvents?.length > 0 &&
                schoolEvents.map((schoolevent: any, index: number) => (
                  <Box
                    key={index}
                    style={{
                      padding: "12px 64px 12px 13px",
                      borderRadius: 6,
                      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.14)",
                      backgroundColor: "#fff",
                      marginBottom: 7,
                      cursor: schoolEvents?.length <= 3 ? 'pointer' : 'default'
                    }}
                    onClick={() => {
                      schoolEvents?.length <= 3 && this.setState({
                        allEventsModal: !this.state.allEventsModal,
                      })
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 18,
                        color: "#3f526d",
                        marginBottom: 8,
                      }}
                      className={this.props.classes.subHeading_font}
                    >
                      {schoolevent.attributes.name}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 14,
                      }}
                      className={this.props.classes.bodyText_font}
                    >
                      {this.handleGetStartAndEndTime(
                        schoolevent?.attributes?.start_time
                      )}{" "}
                      to{" "}
                      {this.handleGetStartAndEndTime(
                        schoolevent?.attributes?.end_time
                      )}
                    </Typography>
                    {/* <Box display={'flex'}  alignItems='center' style={{
                    marginTop:'6px'
                  }}>
                  <PersonOutline
                  style={{color:'#3f526d'}}
                  />
                  <Typography     style={{
                      fontSize: 14,
                    }}
                    className={this.props.classes.bodyText_font}>
                   {schoolevent?.attributes?.user_name}
                  </Typography>
                    </Box> */}
                  </Box>
                ))}
              {schoolEvents?.length > 3 && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "17px",
                  }}
                >
                  <Button
                    style={{
                      outline: "none",
                      width: 117,
                      height: 41,
                      borderRadius: 20,
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#7f6cfc",
                      margin: "10px 0px",
                    }}
                    variant="outlined"
                    onClick={() => {
                      this.setState({
                        allEventsModal: !this.state.allEventsModal,
                      });
                    }}
                  >
                    <Typography
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        fontFamily: "Open sans",
                        fontWeight: 600,
                        color: "#9aa3b2",
                      }}
                    >
                      View All
                    </Typography>
                  </Button>
                </Box>
              )}
              {this.state.allEventsModal && (
                <>
                  <ViewAllEventsModal
                    allEventsModal={this.state.allEventsModal}
                    modalHandleClose={this.onCloseEventsModal}
                    allEvents={schoolEvents}
                  />
                </>
              )}
            </Box>
          </Box>

          <Box className='cal-pd'
            style={{

              borderRadius: 20,
              boxShadow: '0 8px 27px 0 rgba(224, 224, 224, 0.5)',
              backgroundColor: '#fff',
              marginTop: 30,
            }}
          >
            <Box
              style={{
                marginBottom: '6px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                style={{
                  fontSize: 24,
                  fontWeight: 'normal',
                }}
                className={this.props.classes.heading_font}
              >
                Notes
              </Typography>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton>
                  <img
                    src={edit_note_img}
                    style={{ height: 22, width: 22 }}
                    onClick={() => {
                      this.setState({ createNotes: !this.state.createNotes });
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
            {this.state.createNotes && (
              <CreateNotes
                createNoteModal={this.state.createNotes}
                closeModalFun={this.onCloseModal}
              />
            )}
            <Box>
              {teacherNotes?.length > 0 ? (
                teacherNotes?.map(
                  (teacherNote: any, index: any) =>
                    index < 3 && (
                      <>
                        <Box
                          key={teacherNote.attributes.title}
                          style={{
                            cursor: 'pointer',
                            marginTop: '12px',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor:
                              index == 0
                                ? '#ffeeca'
                                : index == 1
                                  ? '#caf2ff'
                                  : '#e6e2ff',
                            borderRadius: '20px',
                            height: 'auto',
                            padding: '16px 23px 23px 23px',
                          }}
                          onClick={() => {
                            this.setState({
                              notes_id: teacherNote.id,
                              editNoteModal: !this.state.editNoteModal,
                            });
                          }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 16,
                                fontWeight: 600,
                                textTransform: 'capitalize',
                              }}
                              className={this.props.classes.subHeading_font}
                            >
                              {teacherNote.attributes.title}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'normal',
                              }}
                              className={this.props.classes.subHeading_font}
                            >
                              {moment(teacherNote.attributes.updated_at).format(
                                'DD MMM,YYYY'
                              )}
                            </Typography>
                          </Box>

                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: 'normal',
                              marginTop: 14,
                              textTransform: 'capitalize',
                            }}
                            className={this.props.classes.bodyText_font}
                          >
                            {teacherNote.attributes.description}
                          </Typography>
                        </Box>
                        {this.state.mainReminder && <ViewThoughtModalWeb classes={this.props.classes} mainReminder={this.state.mainReminder} mainReminderOpen={this.state.mainReminderOpen} modalhandleClose={this.modalhandleClose} />}
                        {this.state.editNoteModal &&
                          this.state.notes_id === teacherNote.id && (
                            <EditNote
                              notes_id={teacherNote.id}
                              heading={teacherNote.attributes.title}
                              description={teacherNote.attributes.description}
                              openModal={this.state.editNoteModal}
                              closeModalFun={this.onCloseModal}
                            />
                          )}
                      </>
                    )
                )
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      marginTop: '7%',
                    }}
                  >
                    <img
                      style={{ height: '224px', objectFit: 'contain' }}
                      src={NOTES_NO_DATA}
                      alt="No data found"
                    />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      marginTop: '7%',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Open Sans',
                        opacity: 0.5,
                        fontSize: '22px',
                        fontWeight: 600,
                        color: '#3f526d',
                      }}
                    >
                      No Notes!
                    </span>
                  </div>
                </>
              )}
            </Box>
            {teacherNotes?.length > 3 && (
              <Box display="flex" justifyContent="center" mt="17px">
                <Button
                  style={{
                    outline: 'none',
                    width: 117,
                    height: 41,
                    borderRadius: 20,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: '#7f6cfc',
                  }}
                  variant="outlined"
                  onClick={() => {
                    this.setState({ allNotesModal: !this.state.allNotesModal });
                  }}
                >
                  <Typography
                    style={{
                      textTransform: 'none',
                      fontSize: 14,
                      fontFamily: 'Open sans',
                      fontWeight: 600,
                      color: 'black',
                    }}
                  >
                    View All
                  </Typography>
                </Button>
              </Box>
            )}
            {this.state.allNotesModal && (
              <>
                <ViewAllNotesModal
                  allNotesModal={this.state.allNotesModal}
                  closeModalFun={this.onCloseModal}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(TeacherDashboard, 'TeacherAccount')
);
// Customizable Area End
