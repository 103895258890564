// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
import { toast } from "react-toastify";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showLoader: boolean;
    selectedTab: any;
    selectedIndex: number;
    scholasticDetails: any;
    coScholasticDetails: any;
    studentData: any;
    tabsList: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

// Customizable Area Start
export const invalidCharsForNumberType = ["-", "e", "+", "E"];
// Customizable Area End

export default class ReportCardDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetModulesName: string = "";
    apiSaveScholasticChanges: string = "";
    apiSaveCoScholasticChanges: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            showLoader: false,
            selectedTab: "profileDetails",
            selectedIndex: 0,
            scholasticDetails: null,
            coScholasticDetails: null,
            studentData: null,
            tabsList: [
                {
                    key: 0,
                    value: "profileDetails",
                    label: "Profile Details",
                },
                {
                    key: 1,
                    value: "scholastic",
                    label: "Scholastic",
                },
                {
                    key: 2,
                    value: "coScholastic",
                    label: "Co Scholastic",
                },
                {
                    key: 3,
                    value: "gradingDetails",
                    label: "Grading Details",
                },
            ],
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Received", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.apiGetModulesName) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        const { tabsList } = this.state;
                        responseJson?.map((item: any) => {
                            if (item.type === "scholastic") {
                                const tabs = tabsList?.map((tab: any) => {
                                    if (tab.key === 1) {
                                        tab.label = item.name
                                    }
                                    return tab;
                                })
                                this.setState({ tabsList: tabs })
                            } else if (item.type === "co_scholastic") {
                                const tabs = tabsList?.map((tab: any) => {
                                    if (tab.key === 2) {
                                        tab.label = item.name
                                    }
                                    return tab;
                                })
                                this.setState({ tabsList: tabs })
                            }
                        })
                        this.setState({ showLoader: false });
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showLoader: false })
                this.parseApiCatchErrorResponse(errorResponse);
            } else if (apiRequestCallId === this.apiSaveScholasticChanges) {
                if (responseJson != null) {
                    if (!responseJson.error) {
                        this.setState({ showLoader: false, scholasticDetails: [] });
                        toast.success('Saved changes successfully.')
                    } else {
                        toast.error('Something went wrong.')
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showLoader: false })
                this.parseApiCatchErrorResponse(errorResponse);
            } else if (apiRequestCallId === this.apiSaveCoScholasticChanges) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ showLoader: false });
                        toast.success('Saved changes successfully.')
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showLoader: false })
                this.parseApiCatchErrorResponse(errorResponse);
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getModulesName();
        if (HISTORY.location.state) {
            this.setState({ studentData: HISTORY.location.state?.studentData })
        }
        // Customizable Area End
    }

    handleSelectTab = (tab: string, key: number) => {
        this.setState({ selectedTab: tab, selectedIndex: key });
    };

    handleBack = () => {
        HISTORY.goBack();
    }

    setScholasticDetails = (data: any) => {
        this.setState({ scholasticDetails: data })
    }

    setCoScholasticDetails = (data: any) => {
        this.setState({ coScholasticDetails: data })
    }

    getModulesName = () => {
        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token,
            school: school_Data?.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetModulesName = requestMessage.messageId;

        this.setState({ showLoader: true })

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getModulesNameEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.resultUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    saveChanges = () => {
        const { selectedIndex } = this.state;
        if (selectedIndex === 1) {
            this.saveScholasticChanges()
        } else if (selectedIndex === 2) {
            this.saveCoScholasticChanges()
        }
    }

    saveScholasticChanges = () => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("token"),
            school: school_Data?.school_id,
        };

        const { scholasticDetails, studentData } = this.state;

        const tempScholasticItems: any = [];
        scholasticDetails?.map((scholasticItem: any) => {
            tempScholasticItems.push({
                category_type: scholasticItem.category_type,
                marks: scholasticItem.marks , 
                sub_category_id: scholasticItem.sub_category_id
            })
        })

        const httpBody: any = {
            results: tempScholasticItems
        }

        this.setState({ showLoader: true })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.resultUrl
        );

        this.apiSaveScholasticChanges = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.saveScholasticChangesEndPoint + `?student_id=${studentData?.data?.id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    saveCoScholasticChanges = () => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("token"),
            school: school_Data?.school_id,
        };

        const { coScholasticDetails, studentData } = this.state;

        const tempCoScholasticItems: any = [];

        const reportTopics = coScholasticDetails?.attributes?.report_topics

        reportTopics?.map((topicData: any) => {
            topicData?.report_attributes?.map((data: any) => {
                data?.marks?.map((nestedMarks: any) => {
                    const coItem = {
                        report_attribute_id: data?.id,
                        term_id: nestedMarks?.term_id,
                        marks: nestedMarks?.marks,
                    }
                    tempCoScholasticItems.push(coItem)
                    return nestedMarks;
                })

                return data;
            })
            return topicData
        })
        const httpBody: any = {
            results: tempCoScholasticItems
        }

        this.setState({ showLoader: true })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.resultUrl
        );

        this.apiSaveCoScholasticChanges = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.saveCoScholasticChangesEndPoint + `?student_id=${studentData?.data?.id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // Customizable Area End
}
