// Customizable Area Start
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import React from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import Select from 'react-select';
import '../AddQuestion/common.css';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

// Customizable Area End
// Customizable Area Start
const configJSON = require('../config.js');
const noData = require('../assets/no-data.png').default;
// Customizable Area End
// Customizable Area Start
interface IProps {
  classes: any;
  donughtData: any;
  barData: any;
  tableData: any;
  assessmentData: any;
  id: string;
}
// Customizable Area End

export const AssessmentStudents: React.FC<IProps> = (props) => {
  // Customizable Area Start
  const {
    classes,
    donughtData,
    barData,
    tableData,
    assessmentData,
    id,
  } = props;
  const options = [
    { value: 10, label: configJSON.ASSESSMENT_STUDENTS.ANALYZE_BY_SCORE },
    { value: 20, label: 'Twenty' },
    { value: 30, label: 'Thirt' },
  ];
  const excelentValues = [
    { value: 'EXCELLENT', label: configJSON.ASSESSMENT_STUDENTS.EXCELLENT },
  ];

  return (
    <Box marginTop={3} id={id}>
      {/* Title starts here */}
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs={12} md={6} lg={10}>
          <Typography variant="h6" className={classes.subTitle_font}>
            {configJSON.ASSESSMENT_STUDENTS.STUDENTS_PERFORMANCE_BANDS}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <Select
            className="custom_select"
            defaultValue={options[0]}
            isSearchable={false}
          />
        </Grid>
      </Grid>
      {/* Title ends here */}
      <Box marginTop={3}>
        <Grid container justify="space-around">
          <Grid item xs={12} md={6} lg={6}>
            {donughtData?.data?.datasets[0]?.data?.length === 0 ? (
              <Box
                className={`assessmentSummaryReport_AssessmentTest_main_table no_data_box`}
              >
                <p className={`no_data_title_big`}>
                  {configJSON.ASSESSMENT_REPORTS.NO_DATA_FOUND}
                </p>
              </Box>
            ) : (
              <Doughnut
                {...donughtData}
                className={classes.graph_height}
                plugins={[ChartDataLabels]}
              />
            )}
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            {barData?.data?.datasets[0]?.data?.length === 0 ? (
              <Box
                className={`assessmentSummaryReport_AssessmentTest_main_table no_data_box`}
              >
                <p className={`no_data_title_big`}>
                  {configJSON.ASSESSMENT_REPORTS.NO_DATA_FOUND}
                </p>
              </Box>
            ) : (
              <Box
                className={
                  classes.AssessmentStandardsReport_TeacherReports_graph_height
                }
              >
                <Bar {...barData} plugins={[ChartDataLabels]} />
              </Box>
            )}
          </Grid>
        </Grid>
        <Box marginTop={3}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={12} md={6} lg={10}>
              <Typography variant="h6" className={classes.subTitle_font}>
                {configJSON.ASSESSMENT_STUDENTS.STUDENTS_PERFORMANCE} |{' '}
                {tableData?.assessment_name} (ID:{tableData?.assessment_id})
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              {/* <Select
                value="Performance Band - Excellence"
                variant="filled"
                className="custom_select"
              >
                <MenuItem value="Performance Band - Excellence" selected>
                  <em>{configJSON.ASSESSMENT_STUDENTS.EXCELLENT}</em>
                </MenuItem>
              </Select> */}
              <Select
                options={excelentValues}
                className="custom_select"
                defaultValue={excelentValues[0]}
                isSearchable={false}
              />
              {/* <select value="Performance Band - Excellence" defaultValue="Performance Band - Excellence" className='custom_select'> */}
              {/* <option value="Performance Band - Excellence" selected className='view_scorebox'>
                 {configJSON.ASSESSMENT_STUDENTS.EXCELLENT}
                </option> */}
              {/* <MenuItem value="Performance Band - Excellence" selected>
                  <em>{configJSON.ASSESSMENT_STUDENTS.EXCELLENT}</em>
                </MenuItem> */}

              {/* <MenuItem value={10}>
                  {configJSON.ASSESSMENT_STUDENTS.TEN}
                </MenuItem>
                <MenuItem value={20}>
                  {configJSON.ASSESSMENT_STUDENTS.TWENTY}
                </MenuItem>
                <MenuItem value={30}>
                  {configJSON.ASSESSMENT_STUDENTS.THIRTY}
                </MenuItem> */}
              {/* </select> */}
            </Grid>
          </Grid>
          <Box marginTop={3}>
            <TableContainer className="table_container">
              <Table className="separte-border-collapse">
                <TableHead className="table_head">
                  <TableRow>
                    <TableCell
                      className={`${classes.subHeading_font} table_cell`}
                      align="center"
                    >
                      {configJSON.ASSESSMENT_STUDENTS.PERFORMANCE_BAND}
                    </TableCell>
                    <TableCell
                      className={`${classes.subHeading_font} table_cell`}
                      align="center"
                    >
                      {configJSON.ASSESSMENT_STUDENTS.STUDENT}
                    </TableCell>
                    <TableCell
                      className={`${classes.subHeading_font} table_cell`}
                      align="center"
                    >
                      {configJSON.ASSESSMENT_STUDENTS.ASSESSMENT_DATE}
                    </TableCell>
                    <TableCell
                      className={`${classes.subHeading_font} table_cell`}
                      align="center"
                    >
                      {configJSON.ASSESSMENT_STUDENTS.ASSESSMENT_SCORE}
                    </TableCell>
                    <TableCell
                      className={`${classes.subHeading_font} table_cell`}
                      align="center"
                    >
                      {configJSON.ASSESSMENT_STUDENTS.CLASS_AVERAGE}
                    </TableCell>
                    <TableCell
                      className={`${classes.subHeading_font} table_cell`}
                      align="center"
                    >
                      {configJSON.ASSESSMENT_STUDENTS.STUDENT_SCORE}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  style={{ marginTop: 10, maxHeight: 340, overflow: 'auto' }}
                >
                  {tableData?.performance_wise_data?.assessment_performance?.map(
                    (item: any, index: number) => {
                      return (
                        <TableRow>
                          <TableCell
                            align="center"
                            className={`${classes.bodyText_font} table_body_cell`}
                          >
                            {item?.performance_band}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.bodyText_font} table_body_cell`}
                          >
                            {item?.student_name}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.bodyText_font} table_body_cell`}
                          >
                            {moment(
                              item?.assessment_date,
                              'MM-DD-YYYY'
                            )?.format('MMM DD,YYYY hh:mm A')}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.bodyText_font} table_body_cell`}
                          >
                            {item?.student_assessment_score} /{' '}
                            {item?.assessment_marks}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.bodyText_font} table_body_cell`}
                          >
                            {item?.student_class_score_percentage} %
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.bodyText_font} table_body_cell`}
                          >
                            {item?.student_score_percentage} %
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
  // Customizable Area End
};

// Customizable Area Start
export default AssessmentStudents;
// Customizable Area End
