// Customizable Area Start
import React from "react";
import "../StudentGradeBook.web.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Grid,
  IconButton
} from "@material-ui/core";
import TaskGradeBookController, {
  Props,
} from "./TaskGradeBookController.web";
import { filter } from "../../assets";
import Datetime from "react-datetime";
import moment from 'moment';
import _ from 'lodash'
import Spinner from "../../../../shared/SideBar/src/Spinner";
import './TaskGradebook.web.css';
// Customizable Area End

// Customizable Area Start
const today = moment();
const disableFutureDt = (current: any) => {
  return current.isBefore(today);
};
// Customizable Area End
// Customizable Area Start
export default class TaskGradeBook extends TaskGradeBookController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    return (
      <div>
        {this.state.showLoader && (
          <Spinner spinnerModal={this.state.showLoader} />
        )}
        <Grid container>
          <Grid item xs={12} className="grade-book-filter-div">
            <Datetime
              dateFormat={"DD MMM, YYYY"}
              timeFormat={false}
              strictParsing={true}
              input={true}
              value={moment(this.state.startDate, "DD-MM-YYYY").format(
                "DD MMM, YYYY"
              )}
              initialValue={moment().format("DD MMM, YYYY")}
              className="date-input"
              inputProps={{
                placeholder: "Start date",
                className: "Date_of_birth_calendar_Icon_register",
                readOnly: true,
              }}
              closeOnSelect
              isValidDate={disableFutureDt}
              onChange={(date) => {
                this.setState({ startDate: date }, () => this.checkEndDate());
              }}
            />

            <span className="to-text TaskGradebookMarginLR20">To</span>

            <Datetime
              dateFormat={"DD MMM, YYYY"}
              timeFormat={false}
              strictParsing={true}
              input={true}
              value={moment(this.state.endDate, "DD-MM-YYYY").format(
                "DD MMM, YYYY"
              )}
              initialValue={moment().format("DD MMM, YYYY")}
              className="date-input"
              inputProps={{
                placeholder: "Start date",
                className: "Date_of_birth_calendar_Icon_register",
                readOnly: true,
              }}
              closeOnSelect
              isValidDate={disableFutureDt}
              onChange={(date) => {
                this.setState({ endDate: date }, () => this.checkEndDate());
              }}
            />

            <div className="view-filter">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={() => this.searchFilterClick()}
              >
                <img
                  src={filter}
                  className="filter-grade-book-icon"
                />
              </IconButton>
            </div>

          </Grid>
        </Grid>

        {/* Gradebook table */}
        <div className="rrll">
          <div className="dropshadowtd" />
          <TableContainer className="gradebookTable">
            <Table aria-label="sticky table" className="TaskGradebookSubTable">
              <TableHead>
                {/* Subject Header */}
                {this.state.subjectHeaders.length > 0 &&
                  <TableRow>
                    <TableCell className="gradebook-header-cell gradebook-first-column-textlabel">Subjects</TableCell>
                    {this.state.subjectHeaders?.map((item: any) => (
                      <TableCell
                        // rowSpan={!tableHeader.childHeaders?.length ? 2 : undefined}
                        colSpan={item.assessmentLength}
                        key={item.key}
                        className="gradebook-header-cell"
                      >
                        <span className="gradebook-header-cell-textlabel">{item.subject_name}</span>
                      </TableCell>
                    ))}
                  </TableRow>
                }
                {/* Subject Header END */}

                {/* Assessment Name and Type */}
                {this.state.assessmentList.length > 0 &&
                  <TableRow>
                    <TableCell className="assignmentTitle gradebook-header-cell gradebook-first-column-textlabel">Task</TableCell>
                    {this.state.assessmentList?.map((item: any, index: number) => (
                      <TableCell key={index}
                        className="gradebook-header-cell vertical-header-cell"
                        style={{ backgroundColor: `${item?.background_color}` }}
                      >
                        <Tooltip title={`${item?.name} - ${item?.type}`}>
                          <div className="gradebook-sub-header-cell">
                            {item?.name} - {item?.type}
                          </div>
                        </Tooltip>
                      </TableCell>
                    ))}
                  </TableRow>
                }
                {/* Assessment Name and Type END */}

                {/* Due Date */}
                {this.state.dueDateList.length > 0 &&
                  <TableRow>
                    <TableCell className="gradebook-header-cell gradebook-first-column-textlabel gradeTitle">Due Date</TableCell>
                    {this.state.dueDateList?.map((item: any, index: number) => (
                      <TableCell
                        className="TaskGradebookDueDateTableCell"
                        key={index}> {item?.due_date} </TableCell>
                    ))}
                  </TableRow>
                }
                {/* Due Date END */}

                {/* Grade */}
                {this.state.gradeList.length > 0 &&
                  <TableRow>
                    <TableCell className="gradebook-header-cell gradebook-first-column-textlabel">Grade</TableCell>
                    {this.state.gradeList?.map((item: any, index: number) => (
                      <TableCell
                        key={index}
                        className="TaskGradebookTableCell"
                      >
                        {item?.grade}
                      </TableCell>
                    ))}
                  </TableRow>
                }
                {/* Grade END */}

              </TableHead>
              <TableBody>

                {this.state.studentList.length > 0 &&
                  this.state.studentList?.map((item: any) => (
                    <TableRow key={item.student_id}>
                      {/* First Cell */}
                      <TableCell scope="row"
                        className="gradebook-first-column-textlabel TaskGradebookFirstTableCell"
                      >

                        {item?.student_name}
                      </TableCell>
                      {/* First Cell END */}

                      {/* Grades */}
                      {
                        item?.grades?.map((grade: any, index: number) => (
                          <TableCell
                            key={index}
                            style={{
                              color: grade.text_color
                            }}
                            className='TaskGradebookTableCell'
                          >
                            {grade.grade}
                          </TableCell>
                        ))
                      }
                      {/* Grades END */}
                    </TableRow>
                  ))
                }

              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* Gradebook table END */}

      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area End