// Customizable Area Start
import React from "react";
import RecentActivityController, { Props } from "./RecentActivityController";
import { Box, Button, Typography } from "@material-ui/core";
import { backArrowIcon } from "./assets";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class TeacherRecentActivity extends RecentActivityController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  goBack = () => {
    window.history.back();
  };

  render() {
    const { recentActivities } = this.state;
    return (
      <>
        <Box className="RecentActivityMainBox">
          <Box display="flex" mt="16px">
            <Button
              disableRipple={true}
              className="UploadingBackBtn"
              onClick={this.goBack}
            >
              <Typography className="BackArrowIconDocument">
                <img src={backArrowIcon} />
              </Typography>
              <Typography className="RecentBackText">
                Back
              </Typography>
            </Button>
          </Box>
          <Box display="flex">
            <Typography className="recent_activity">
              Recent Activity:
            </Typography>
            <Typography className="login_session_text">
              Last 5 login session with IP and country details.
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            className="recent_activity_main_div"
          >
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              className="RecentActivityHeaderBox"
            >
              <Box
                display="flex"
                mb="13px"
                className="recent_activity_header_div"
              >
                <Typography
                  className="recent_activity_header_text RecentActivityBrowser"
                >
                  Browser
                </Typography>
                <Typography
                  className="recent_activity_header_text RecentActivityOS"
                >
                  Operating System
                </Typography>
                <Typography
                  className="recent_activity_header_text RecentActivityDateTime"
                >
                  Date
                </Typography>
                <Typography
                  className="recent_activity_header_text RecentActivityDateTime"
                >
                  Time
                </Typography>
                <Typography
                  className="recent_activity_header_text RecentActivityLocation"
                >
                  Location (IP Address)
                </Typography>
              </Box>

              {recentActivities?.map((item: any) => (
                <Box display="flex" mb="10px" width="100%" className="xs_pl_10">
                  <Box display="flex" width="17%" className="spacing_15">
                    <Typography className="recent_activity_detail_text">
                      {item.attributes.browser}
                    </Typography>
                  </Box>

                  <Box display="flex" width="18%" className="spacing_15">
                    <Typography className="recent_activity_detail_text">
                      {item.attributes.operating_system}
                    </Typography>
                  </Box>

                  <Box display="flex" width="15%" className="spacing_15">
                    <Typography className="recent_activity_detail_text">
                      {moment(
                        item.attributes.date,
                        "DD-MM-YYYY HH:mm:ss"
                      ).format("DD MMM YYYY")}
                    </Typography>
                  </Box>
                  <Box display="flex" width="15%" className="spacing_15">
                    <Typography className="recent_activity_detail_text">
                      {item.attributes.date.split(" ")[1]}{" "}
                      {item.attributes.date.split(" ")[2]}
                    </Typography>
                  </Box>
                  <Box display="flex" width="35%" className="spacing_15">
                    <Typography className="recent_activity_detail_text ellipsis_text">
                      {item.attributes.location} ({item.attributes.ip_address})
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </>
    );
  }
  // Customizable Area End
}

export const teacherProfileRecentActivityStyles = {
  // Customizable Area Start
  bodyWrapper: {
    backgroundColor: "#ffffff",
    paddingHorizontal: 35,
    width: "100%",
  },
  // Customizable Area End
};
// Customizable Area Start
export default withStyles(teacherProfileRecentActivityStyles)(
  TeacherRecentActivity as React.ComponentType<any>
);
// Customizable Area End