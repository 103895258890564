// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { profile_logo } from "../assets";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { HISTORY } from "../../../../components/src/comman";
import ProfileDetailsController, { Props } from './ProfileDetailsController.web';
import Spinner from '../../../shared/SideBar/src/Spinner';
import InputBase from "@material-ui/core/InputBase";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme: any) => ({
  root: {
      "label + &": {
          marginTop: theme.spacing(1),
      },
  },
  input: {
      borderRadius: 4,
      position: "relative",
      boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: theme.palette.background.paper,
      padding: "10px 26px 10px 12px",
      width: "7rem",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: theme.props.textColor,
      fontFamily: theme.props.textFont,
      fontSize: 18,
      "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
  },
}))(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      fontFamily: 'Open sans',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    table: {
      minWidth: 700,
    },
     
  }),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      fontFamily: 'Open sans',
    }
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,        
      },
    },
  }),
)(TableRow);

function createData(title: string, term: number, term2: number, term3: number) {
  return { title, term, term2, term3 };
}

const rows = [
  createData('Total Attendence of the learner', 20, 10, 5),
  createData('Total Number of working days', 20, 10, 4.0)
];
// Customizable Area End

// Customizable Area Start
class ProfileDetails extends ProfileDetailsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        selectclass: event.target.value as string
      })
    };

    const handleDateChange = (date: Date | null) => {
      this.setState({
        selectedDate: date
      })
    };
    const handleBack = () => {
      HISTORY.goBack();
    }
    const { schoolData, isLoader, schoolLogoUrl, termListData, classTitle, data } = this.state

    const handleClick = () => {
      HISTORY.push({
        pathname: "/AssessmentLearning",
        state: {
          selectedClassData: data,
        }
      });
    };
    return (
      <>
        {isLoader && <Spinner spinnerModal={isLoader} />}
        <div>
          <div className='profileTitle'>
            <Button className={`backBtn ${this.props.classes.bodyText_font}`} onClick={() => handleBack()}><KeyboardBackspaceOutlinedIcon /> Back</Button>
            <h2 className={this.props.classes.bodyText_font}>Profile Details</h2>
            <Button className={`editBtn ${this.props.classes.bodyText_font}`} onClick={() => handleClick()}>Next <KeyboardBackspaceOutlinedIcon /></Button>
          </div>
          <div className='profileContent schoolAdmin'>
            <div className='topHeader'>
              <div className='schoolLogo'>
                <img src={schoolLogoUrl != '' ? schoolLogoUrl : profile_logo} />
              </div>
              <div className='schoolTitle'>
                <h3 className={this.props.classes.bodyText_font}>{schoolData?.attributes?.name}</h3>
              </div>
              <span className="commonBtnPrimary">Report Card - {classTitle}</span>
            </div>
            {/* <div className='blockVision'>
            <h4 className={this.props.classes.bodyText_font}>Vision of {schoolData?.attributes?.name}</h4>
            <p className={this.props.classes.bodyText_font}>It is a long established face that a reader will be distracted by the readable content of  a page when looking as a its layout.</p>
            </div>  */}
            <h5 className={this.props.classes.bodyText_font}>Student Profile</h5>
            <Grid container alignItems="flex-start">
              <Grid className="studentProfile" item xs={12} lg={2}>
                <Box className={`uploadPhoto ${this.props.classes.bodyText_font}`}>
                  Student's photo with Signature (arrested by the School Principle along with School seal). <a href="javascript:void(0)" aria-disabled>Browser</a>
                </Box>
              </Grid>
              <Grid item xs={12} lg={10}>
                <Box className="profileForm textLeft">
                  <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={12} spacing={3}>
                        <label className={this.props.classes.bodyText_font}>Name</label>
                        <TextField fullWidth
                          type="text"
                          placeholder='Name'
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={4} sm={6} spacing={3}>
                        <label className={this.props.classes.bodyText_font}>Class</label>
                        <Select fullWidth
                          labelId="demo-simple-select-label"
                          value="Class"
                          variant="outlined"
                          onChange={handleChange}
                          disabled
                        >
                          <MenuItem value={"Class 1"}>Class 1</MenuItem>
                          <MenuItem value={"Class 2"}>Class 2</MenuItem>
                          <MenuItem value={"Class 3"}>Class 3</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} lg={4} sm={6}>
                        <label className={this.props.classes.bodyText_font}>Section</label>
                        <Select fullWidth
                          labelId="demo-simple-select-label"
                          value="Section"
                          variant="outlined"
                          onChange={handleChange}
                          disabled
                        >
                          <MenuItem value={"Section 1"}>Section 1</MenuItem>
                          <MenuItem value={"Section 2"}>Section 2</MenuItem>
                          <MenuItem value={"Section 3"}>Section 3</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} lg={4} sm={6}>
                        <label className={this.props.classes.bodyText_font}>Wing</label>
                        <TextField fullWidth
                          type="text"
                          placeholder='Wing'
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={4} sm={6} spacing={3}>
                        <label className={this.props.classes.bodyText_font}>Admission No.</label>
                        <TextField fullWidth
                          type="text"
                          placeholder="Admission No."
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} lg={4} sm={6}>
                        <label className={this.props.classes.bodyText_font}>Roll No.</label>
                        <TextField fullWidth
                          type="text"
                          placeholder='Roll No.'
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} lg={4} sm={6}>
                        <label className={this.props.classes.bodyText_font}>DOB</label>
                        <div className="datePicker">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              format="MM/dd/yyyy"
                              id="date-picker-inline"
                              value={this.state.selectedDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              disabled
                            />
                          </MuiPickersUtilsProvider>
                        </div>

                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6} spacing={3}>
                        <label className={this.props.classes.bodyText_font}>Class Teacher's Name</label>
                        <TextField fullWidth
                          type="text"
                          placeholder="Class Teacher's Name"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} lg={6} spacing={3}>
                        <label className={this.props.classes.bodyText_font}>Residential Address</label>
                        <TextField fullWidth
                          type="text"
                          placeholder='Residential Address'
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6} spacing={3}>
                        <label className={this.props.classes.bodyText_font}>Mother's Name</label>
                        <TextField fullWidth
                          type="text"
                          placeholder="Mother's Name"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} lg={6} spacing={3}>
                        <label className={this.props.classes.bodyText_font}>Father's / Guardian's Name</label>
                        <TextField fullWidth
                          type="text"
                          placeholder="Father's / Guardian's Name"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6} spacing={3}>
                        <label className={this.props.classes.bodyText_font}>Email ID</label>
                        <TextField fullWidth
                          type="text"
                          placeholder="Email ID"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} lg={6} spacing={3}>
                        <label className={this.props.classes.bodyText_font}>Mobile No.</label>
                        <TextField fullWidth
                          type="text"
                          placeholder="Mobile No."
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Grid>
            </Grid>


            <TableContainer component={Paper} style={{ marginTop: 25 }}>
              <Table style={{
                minWidth: 700,
              }}
                aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{color: '#fff'}} className={this.props.classes.bodyText_font}>Attendance</StyledTableCell>
                    {termListData.map((data: any) => (
                      <StyledTableCell align="center" style={{color: '#fff'}} className={this.props.classes.bodyText_font}>{data.title}</StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow className='tableRow' key={row.title}>
                      <StyledTableCell style={{color: '#000'}} className={this.props.classes.bodyText_font} component="td" scope="row">
                        {row.title}
                      </StyledTableCell>
                      {termListData.map((data: any) => (
                        <StyledTableCell style={{color: '#000'}} className={this.props.classes.bodyText_font} align="center"></StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
                </Table>                
            </TableContainer>

            <TableContainer component={Paper} style={{ marginTop: 0 }}>
              <Table className='' style={{
                minWidth: 700,
              }}
                aria-label="customized table">
                 <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={4} style={{borderRadius:'0', color: '#fff'}} className={this.props.classes.bodyText_font}>Health Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow className='noTableRow'>
                      <StyledTableCell style={{color: '#000'}} component="td" className={this.props.classes.bodyText_font}>
                        Height:   cm
                      </StyledTableCell>
                      <StyledTableCell style={{color: '#000'}} component="td" className={this.props.classes.bodyText_font}>
                        Weight:   kg
                      </StyledTableCell>
                      <StyledTableCell style={{color: '#000'}} component="td" className={this.props.classes.bodyText_font}>
                        Blood:                      </StyledTableCell> 
                    </StyledTableRow>
                    <StyledTableRow className='noTableRow'>
                      <StyledTableCell style={{color: '#000'}} component="td" className={this.props.classes.bodyText_font}>
                        Vision(L)
                      </StyledTableCell>
                      <StyledTableCell style={{color: '#000'}} component="td" className={this.props.classes.bodyText_font}>
                      Vision(R)
                      </StyledTableCell>
                      <StyledTableCell component="td" >
                                              </StyledTableCell>
                                               
                    </StyledTableRow>
                </TableBody>
                </Table>                
            </TableContainer>
            
          </div>
        </div>
      </>
    );
  }
}
// Customizable Area End
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(ProfileDetails as React.ComponentType<any>, "AdminAccount")
);
// Customizable Area End
