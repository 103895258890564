// Customizable Area Start
import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Datetime from 'react-datetime';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EditOnlineLectureController, {
  Props,
} from './EditOnlineLectureController.web';

import FormHelperText from '@material-ui/core/FormHelperText';
import CloseIcon from '@material-ui/icons/Close';
import Spinner from '../../shared/SideBar/src/Spinner';
import './EditOnlineLecture.web.css';
// Customizable Area End
// Customizable Area Start
const noBorder = {
  border: '0px',
};

const avtarIcon = {
  width: '31px',
  height: '31px',
  margin: '20px 23px 20px 19px',
} as const;
// Customizable Area End

export class EditOnlineLecture extends EditOnlineLectureController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const WhiteRadio = withStyles({
      root: {
        color: '#b620e0',
        padding: '2px 4px 2px 4px',
        '&$checked': {
          color: '#b620e0',
          padding: '2px 4px 2px 4px',
        },
      },
      checked: {},
    })((props: RadioProps) => <Radio color="default" {...props} />);
    const { userData } = this.state;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="edit_online_lecture_parent_modal"
        open={this.props.editModal}
        onClose={this.modalhandleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableAutoFocus={true}
      >
        <Fade in={this.props.editModal} style={{ border: 'none' }}>
          <div
           className='main_container'
          >
            {this.state.showSpinner && (
              <Spinner spinnerModal={this.state.showSpinner} />
            )}
            <form onSubmit={this.updateClass} className="h_100">
              <Box
               className='top_heading_container'
              >
                <Box
                 className='head_container'
                >
                  <Typography
                    className="head_typo"
                  >
                    Edit Lecture
                  </Typography>
                </Box>
                <Box>
                  <CloseIcon
                    className="modal_close_icon"
                    onClick={this.modalhandleClose}
                  />
                </Box>
              </Box>
              <Box
               className='form_inner_box'
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Box
                      className='form_group'
                    >
                      <Typography
                        className="form_group_label"
                      >
                        Lecture Tittle
                      </Typography>
                      <Box
                        className='input_container'
                      >
                        <TextField
                          id="standard-basic"
                          type="text"
                          value={this.state.title}
                          autoComplete="off"
                          className="input_box"
                          variant="outlined"
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                            },
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            if (value.length === 0) {
                              this.setState({
                                titleError: true,
                                titleErrorText: 'Tittle is required.',
                              });
                            } else {
                              this.setState({
                                titleError: false,
                                titleErrorText: '',
                              });
                            }
                            this.setState({ title: event.target.value });
                          }}
                          onBlur={() => {
                            if (
                              this.state.title === null ||
                              this.state.title.length === 0
                            ) {
                              this.setState({
                                titleError: true,
                                titleErrorText: 'Tittle is required.',
                              });
                            } else {
                              this.setState({
                                titleError: false,
                                titleErrorText: '',
                              });
                            }
                          }}
                          error={this.state.titleError}
                        />
                        <FormHelperText
                          className="error_msg"
                        >
                          {this.state.titleErrorText}
                        </FormHelperText>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                     className='form_group'
                    >
                      <Typography
                        className="form_group_label"
                      >
                        Date
                      </Typography>
                      <Box className="w_100">
                        <Datetime
                          dateFormat={'DD MMMM,YYYY'}
                          timeFormat={false}
                          strictParsing={true}
                          input={true}
                          value={this.state.lectureDate}
                          className="input_date_css_format"
                          inputProps={{
                            placeholder: 'Select Date',
                            className: 'Date_of_birth_calendar_Icon_register',
                            style: {
                              fontWeight: 400,
                            },
                            readOnly: true,
                            disabled: true,
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      className='form_group'
                    >
                      <Typography
                        className="form_group_label"
                      >
                        Period
                      </Typography>
                      <Box
                        className='input_container'
                      >
                        <TextField
                          id="standard-basic"
                          type="text"
                          disabled={true}
                          value={this.state.period_name}
                          autoComplete="off"
                          className="input_box"
                          variant="outlined"
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              opacity: 0.5,
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      className='form_group'
                    >
                      <Typography
                        className="form_group_label"
                      >
                        Class
                      </Typography>
                      <Box
                        className="input_container"
                      >
                        <TextField
                          id="standard-basic"
                          type="text"
                          disabled={true}
                          value={this.state.class}
                          autoComplete="off"
                          className='input_box'
                          variant="outlined"
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              opacity: 0.5,
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      className='form_group'
                    >
                      <Typography
                        className='form_group_label'
                      >
                        Subject
                      </Typography>
                      <Box
                        className='input_container'
                      >
                        <TextField
                          id="standard-basic"
                          type="text"
                          disabled={true}
                          value={this.state.subject}
                          autoComplete="off"
                          className='input_box'
                          variant="outlined"
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              opacity: 0.5,
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      className='form_group'
                    >
                      <Typography
                        className='form_group_label'
                      >
                        Time
                      </Typography>
                      <Box
                        className='input_container'
                      >
                        <TextField
                          id="standard-basic"
                          type="text"
                          disabled={true}
                          value={this.state.lectureTime}
                          autoComplete="off"
                          className='input_box'
                          variant="outlined"
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              opacity: 0.5,
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {!this.state.isMeetLink && !userData.meeting_paid_account && (
                    <Grid item xs={4}>
                      <Box
                        className='form_group'
                      >
                        <Typography
                          className='form_group_label'
                        >
                          Meeting Link
                        </Typography>
                        <Box
                          className='input_container'
                        >
                          <TextField
                            className="disabled_input_box"
                            disabled={
                              this.props.data.link !== '' &&
                              this.props.data.link !== null
                                ? true
                                : false
                            }
                            id="standard-basic"
                            type="text"
                            value={
                              this.props.data.link === ''
                                ? this.state.meetLink
                                : this.props.data.link
                            }
                            autoComplete="off"
                            style={{
                              backgroundColor:
                                this.props.data.link !== '' &&
                                this.props.data.link !== null
                                  ? '#f6f6f6'
                                  : '#fff',
                            }}
                            variant="outlined"
                            inputProps={{
                              style: {
                                fontFamily: 'Open sans',
                                fontSize: 15.8,
                                color: '#03014c',
                              },
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = event.target.value.trim();
                              this.setState({ meetLink: value });
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box>
                      <RadioGroup
                        aria-label="position"
                        name="position"
                        defaultValue="top"
                        className="radio_group"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (event.target.value === 'online') {
                            this.setState({ isMeetLink: true });
                          } else {
                            this.setState({ isMeetLink: false });
                          }
                        }}
                      >
                        <Box
                          className='radio_group_box'
                        >
                          <WhiteRadio
                            checked={this.state.isMeetLink ? true : false}
                            value="online"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'A' }}
                          />
                          <Typography
                            className="radio_group_label"
                          >
                            Online
                          </Typography>
                        </Box>
                        <Box
                          className='flex_box_row'
                        >
                          <WhiteRadio
                            checked={!this.state.isMeetLink ? true : false}
                            value="offline"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'A' }}
                          />
                          <Typography
                            className='radio_group_label'
                          >
                            Offline
                          </Typography>
                        </Box>
                      </RadioGroup>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      className='form_group description_margin'
                    >
                      <Typography
                        className="form_group_label"
                      >
                        Description
                      </Typography>

                      <TextField
                        id="standard-basic"
                        type="text"
                        className='description_field'
                        variant="outlined"
                        value={this.state.description}
                        inputProps={{
                          style: {
                            fontFamily: 'Open sans',
                            fontSize: 15.8,
                            color: '#03014c',
                          },
                        }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const value = event.target.value;
                          this.setState({ description: value });
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box
                className='btn_container'
              >
                <Button
                  variant="contained"
                  type="submit"
                  className="update_button"
                >
                  Update
                </Button>
              </Box>
            </form>
          </div>
        </Fade>
      </Modal>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default EditOnlineLecture as React.ComponentType<any>;

// Customizable Area End
