export const cloud = require("../../assets/image-cloud@3x.png")
export const drive = require("../../assets/image-drive@3x.png")
export const deleteIcon = require("../../assets/image-delete@3x.png")
export const folder = require("../../assets/image-folder@3x.png")
export const iconMore = require("../../assets/image-more.png")
export const pdf = require("../../assets/image-pdffile@3x.png")
export const xls = require("../../assets/image-xlsfile@3x.png")
export const doc = require("../../assets/image-docfile@3x.png")
export const list = require("../../assets/group-23.png")
export const grid = require("../../assets/group-22.png")
export const ic_info = require("../../assets/information-1@2x.jpg")

// more modal
export const chalender = require("../../assets/image-support@3x.jpg")
export const share = require("../../assets/image-share@3x.png")
export const getlink = require("../../assets/image-getlink@3x.png")
export const folderlocation = require("../../assets/image-folderlocation@3x.png")
export const moveto = require("../../assets/image-moveto@3x.png")
export const reName = require("../../assets/image-rename@3x.png")
export const viewDetails = require("../../assets/image-viewdetails@3x.png")
export const makeACopy = require("../../assets/image-makecopy@3x.png")
export const download = require("../../assets/image-download@3x.png")
export const move = require("../../assets/image-folder-delete@3x.png")
export const disabled_list = require("../../assets/disabledlist.png")
export const active_grid = require("../../assets/activegrid.png")
export const calendarIcon = require("../../assets/image-support@3x.jpg")
export const subjectIcon = require("../../assets/subject-default.png").default

export const NoDataFound = require("../../assets/NoDataFound.png").default
