// Customizable Area Start
import React from "react"
import {
    withStyles,
    IconButton,
} from "@material-ui/core";
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import StopOutlinedIcon from '@material-ui/icons/StopOutlined';
import { recordBtnIconSVG } from "../assets";
import WebNativeAudioRecorderController from "./WebNativeAudioRecorderController.web";
import AudioAnalyser from "./AudioAnalyser";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class WebNativeAudioRecorder extends WebNativeAudioRecorderController {

    // Customizable Area Start
    render() {
        const { error, mediaStream, isRecording, canvasRef, status, time } = this.state;
        return (
            <div className="graph_main_div">
                {error == 'permission_denied' ? (
                    <div className="allow_camera_access_text">
                        Please allow 21KSchoolPhase1 to access microphone in order
                        to start recording audio!
                    </div>
                )
                    :
                    (
                        <>
                            <div className="audio_recorder_div">
                                {`${this.formatTimeDigit(time.h)}:${this.formatTimeDigit(time.m)}:${this.formatTimeDigit(time.s)}`}
                            </div>
                            <div className="audio_analyser_div">
                                {
                                    (mediaStream && isRecording) ? <AudioAnalyser audioStream={mediaStream} /> :
                                        (
                                            <canvas className={`canvas`} ref={canvasRef} />
                                        )
                                }
                            </div>

                            <div className="audio_recorder_actions">
                                {
                                    (status === 'idle' || status === 'stopped') &&
                                    <IconButton onClick={this.handleStartRecording} >
                                        <img src={recordBtnIconSVG} height={50} width={50} />
                                    </IconButton>
                                }
                                {
                                    status === 'recording' &&
                                    <IconButton onClick={this.handlePauseRecording} >
                                        <PauseCircleFilledIcon className="audio_record_icon" />
                                    </IconButton>
                                }

                                {
                                    status === 'paused' &&
                                    <IconButton onClick={this.handleResumeRecording} >
                                        <PlayCircleFilledIcon className="audio_record_icon" />
                                    </IconButton>
                                }

                                <IconButton
                                    onClick={this.handleStopRecording}
                                    className="audio_record_stop"
                                    disabled={!(status === 'recording' || status === 'paused')}
                                >
                                    <StopOutlinedIcon className="audio_record_stop_icon" />
                                </IconButton>

                            </div>
                        </>
                    )
                }


            </div >
        )
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(WebNativeAudioRecorder);
// Customizable Area End