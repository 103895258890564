
// Customizable Area Start
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { nounCheckSVG, nounCloseSVG } from "../assets";
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

interface Props {
  // Customizable Area Start
  questionData: any;
  isTeacher: boolean;
  classes?: any;
  // Customizable Area End
}

// Customizable Area Start
export const OneWordQuestionType = ({
  questionData,
  isTeacher,
  classes
}: Props) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={6} className={questionData?.selected_options?.data?.attributes?.answer_details === questionData?.answers?.[0]?.answer ? "correct_answer_div one_word_main_div" : "wrong_answer_div one_word_main_div"}>
        <Typography className="mcq_option_text">{questionData?.selected_options?.data?.attributes?.answer_details}</Typography>
        {questionData?.selected_options?.data?.attributes?.answer_details === questionData?.answers?.[0]?.answer ?
          <img src={nounCheckSVG} alt="check" />
          :
          <img src={nounCloseSVG} alt="close" />
        }
      </Grid>
      <div className="correct_answer_section">
        <Grid container>
          <Grid item xs={12} sm={6} className="correct_answer_left">
            {!questionData?.selected_options?.data &&
              <Typography className={`not_attemped_text ${classes.bodyText_font}`}>Not attempted</Typography>
            }
          </Grid>
          <Grid item xs={12} sm={6} className="score_section">
            <Typography className={`correct_answer_text ${classes.heading_font}`}>Score</Typography>
            <div className="correct_answer_rectangle">
              <Typography className="score_text">
                {questionData?.selected_options?.data?.attributes?.score ? questionData?.selected_options?.data?.attributes?.score : 0}
              </Typography>
            </div>
            <Typography className="score_text"> / {questionData?.marks}</Typography>
          </Grid>
          {(!isTeacher && questionData?.selected_options?.data?.attributes?.comments) &&
            <Grid item xs={12}>
              <Typography className={`title_teachercomment ${classes.theme_font}`}>Teacher Comment:
                <span className={`textlabel_teachercomment ${classes.heading_font}`} dangerouslySetInnerHTML={{
                  __html: questionData?.selected_options?.data?.attributes?.comments,
                }}></span></Typography>
            </Grid>
          }
        </Grid>
      </div>
    </Grid>
  );
};
// Customizable Area End


// Customizable Area Start

export default withStyles(themeCreateStyle)(OneWordQuestionType);
// Customizable Area End
