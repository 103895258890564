// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import _ from "lodash";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    unitModal: boolean;
    closeModalFun: any;
    task: any;
    classes: any;
    savecloseModalFun: any;
    openSelectUserModal: any;
    sharewithstudent: any;
    sharewithteacher: any;
    isShareChanges: boolean;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    sharewithstudent: [];
    sharewithteacher: [];
    shareWithData: any;
    editModal: boolean;
    openShareModal: boolean;
    classTeacherList: any;
    classStudentList: any;
    userTypeIsTeacher: boolean;
    showSpinner: boolean;
    prevIds: any;
    closeModal: boolean;
    isShareChanges: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ShareTaskController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    apiCreateTaskApiId: string = '';
    getTeacherListId: string = '';
    getStudentListId: string = '';
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            sharewithstudent: [],
            sharewithteacher: [],
            shareWithData: [],
            showSpinner: false,
            editModal: false,
            openShareModal: false,
            classTeacherList: [],
            classStudentList: [],
            userTypeIsTeacher: false,
            closeModal: false,
            prevIds: [],
            isShareChanges: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.get_props_data()

    }
    handleCloseSharePopup = () => {
        this.setState({ openShareModal: false })
    }

    get_props_data = () => {

        const { task } = this.props
        let uniqueStudentIds: any = []
        let uniqueTeacherIds: any = []
        let uniqueStudent: any = _.uniqBy(task?.attributes?.student_members, "account_id")
        uniqueStudent.map((_data: any) => uniqueStudentIds.push(_data.account_id))
        let uniqueTeacher: any = _.uniqBy(task?.attributes?.teacher_members, "account_id")
        uniqueTeacher.map((_data: any) => uniqueTeacherIds.push(_data.account_id))
        this.setState({
            sharewithstudent: uniqueStudentIds,
            sharewithteacher: uniqueTeacherIds,
            prevIds: [...task?.attributes?.teacher_members, ...task?.attributes?.student_members]
        })
        this.get_class_teacher(task.attributes.grade_id);
        this.get_class_student(task.attributes.grade_id);


        this.setState({ showSpinner: false })

    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.getTeacherListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ classTeacherList: responseJson?.teachers })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showSpinner: false })
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.getStudentListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        let objectKeys = Object.keys(responseJson.data).map((item: any) => item);
                        let tempStudentList: any = {};
                        objectKeys.map((element: any) => {
                            let classStudents = responseJson.data[element].map((element: any) => {
                                return {
                                    student_id: element.account_id,
                                    first_name: element?.account?.data?.attributes?.first_name,
                                    last_name: element?.account?.data?.attributes?.last_name,
                                    gender: element?.account?.data?.attributes?.gender,
                                    avatar: element?.account?.data?.attributes?.avatar,
                                    email: element?.account?.data?.attributes?.email
                                }
                            });
                            tempStudentList[element] = classStudents;
                        })
                        this.setState({ classStudentList: tempStudentList })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showSpinner: false })
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiCreateTaskApiId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ showSpinner: false })
                        toast.success("Shared successfully")
                        this.props.savecloseModalFun(this.state.closeModal)
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showSpinner: false })
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.closeModalFun(this.state.closeModal)
    }

    get_class_teacher = (id: any) => {
        this.setState({ showSpinner: true })
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": data.school_id,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTeacherListId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.classTeachersEndPoint + `?grade_id=${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    get_class_student = (id: any) => {
        this.setState({ showSpinner: true })
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getStudentListId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.gradeStudentEndPoint + `?grade_id=${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    saveSelectedUsersId = (list: any) => {
        if (this.state.userTypeIsTeacher) {
            this.setState({ sharewithteacher: list })
        } else {
            this.setState({ sharewithstudent: list })
        }
        this.setState({ openShareModal: false, userTypeIsTeacher: false, isShareChanges: true })
    }

    editTask = (e: React.SyntheticEvent) => {
        e.preventDefault();
        this.setState({ showSpinner: true })
        let shareData: any = []
        this.props.sharewithteacher.map((_data: any) => {
            shareData.push({
                account_id: _data,
                account_type: "teacher",
            })
        })
        this.props.sharewithstudent.map((_data: any) => {
            shareData.push({
                account_id: _data,
                account_type: "student",
            })
        })
        this.state.prevIds.map((_data: any) => {
            shareData.push({
                id: _data.id,
                _destroy: true,
            })
        })
        const data: any = {
            task_members_attributes: shareData,
        }

        const school_data = localStorage.getItem("user_data");
        const Schooldata = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": Schooldata.school_id,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCreateTaskApiId = requestMessage.messageId;

        const httpBody = data;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createTaskEndPoint + `/${this.props.task.id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.EditMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    // Customizable Area End
}
