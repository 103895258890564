Object.defineProperty(exports, "__esModule", {
  value: true,
});

const urlConfig = require("../../../../framework/src/config");

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard8/dashboards/get_teacher_classes";
exports.teacherSubjects =
  "/bx_block_dashboard8/dashboards/get_teacher_subjects";
exports.teacherAssignment =
  "/bx_block_dashboard8/dashboards/get_teacher_assessments";
exports.teacherNotes = "/bx_block_dashboard8/dashboards/get_notes";
exports.numberofStudents =
  "/bx_block_dashboard8/dashboards/get_teacher_students";
exports.studentSubjectGetUrl =
  "/bx_block_dashboard8/dashboards/get_student_subjects";
exports.studentUpcomingClassesGetUrl =
  "/bx_block_dashboard8/dashboards/get_student_upcoming_classes";
(exports.studentGoalsGetUrl =
  "/bx_block_dashboard8/dashboards/get_student_goals"),
  (exports.studentProgressGetUrl =
    "/bx_block_dashboard8/dashboards/get_student_progress"),
  (exports.studentNotesGetUrl = "/bx_block_dashboard8/dashboards/get_notes"),
  (exports.studentCurveGetUrl =
    "/bx_block_dashboard8/dashboards/get_student_learning_curve"),
  (exports.studentAssessmentUrl =
    "/bx_block_dashboard8/dashboards/get_student_assessments"),
  (exports.studentSubjectJoinEndPoint =
    "/bx_block_scheduling/classes/enrol_student_in_class");
exports.getTeacherSubjectsEndPoint =
  "/bx_block_scheduling/subjects/subject_by_teacher";
exports.getSearchEndPoint = "/bx_block_scheduling/subjects/search_subjects";
exports.getStudentSubjects =
  "/bx_block_scheduling/subjects/subject_class_section_subjects";
exports.teacherSubjectRemove =
  "/bx_block_scheduling/subjects/delete_class_subject";
exports.getFreePeriodEndPoint =
  "/bx_block_scheduling/school_periods/get_unscheduled_periods_of_teacher";
exports.dashboarContentType = "application/json";
exports.formDataContentType = "multipart/form-data";
exports.dashboarApiMethodType = "GET";
exports.classesListEndPoint = "/bx_block_scheduling/school_classes";
exports.getQuestion = "account_block/feedback_questions";
exports.dashboarApiPostMethodType = "POST";
exports.dashboarApiPutMethodType = "PUT";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
// exports.deleteAPiURL = "account_block/feedback_questions/4"
exports.schoolClassEndPoint = "/bx_block_scheduling/school_classes";
exports.gradeEndPoint = "/bx_block_scheduling/grades";
exports.deleteSchoolMethodType = "DELETE";
exports.getSubjectsEndPoint =
  "/bx_block_scheduling/schools/get_school_subjects";
exports.getClassSubjectsEndPoint =
  "/bx_block_scheduling/subjects/class_section_subjects";
exports.getDivisionEndPoint = "/account_block/divisions";
exports.addClassEndPoint = "/bx_block_scheduling/school_classes";
exports.addBulkClassEndPoint = "bx_block_bulk_uploading/import_school_classes";
exports.dashboardURL = urlConfig.dashboardURL;
exports.instituteURL = urlConfig.instituteURL;
exports.userManagementURL = urlConfig.baseURL;
exports.attendanceURL = urlConfig.attendanceURL;
exports.getDivisionEndPoint = "account_block/divisions/";
exports.createEventEndPoint = "/account_block/events";
exports.enrollApiPutMethodType = "PUT";
exports.enrollApiPostMethodType = "POST";
exports.updateApiMethodType = "PATCH";
exports.createPeriodEndPoint = "/bx_block_scheduling/periods";
exports.getTeachePeriodEndPoint =
  "/bx_block_scheduling/periods/get_teacher_periods";
exports.schoolPeriod = "/bx_block_scheduling/school_periods?school_id=";
exports.createEvent = "bx_block_time_table/events";
exports.getPreSignedId = "/bx_block_bulk_uploading/uploads/pre_signed_url";
exports.updatedClassEndPoint = "/bx_block_scheduling/periods/";
exports.getSubjectURL = "bx_block_scheduling/subjects/class_section_subjects";
exports.getRecordingList = "/bx_block_scheduling/meetings";
exports.getReviewEndpoint = "/account_block/reviews";
// Customizable Area End
