Object.defineProperty(exports, "__esModule", {
  value: true,
});
const urlConfig = require("../../../../framework/src/config");

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.labelTitleText = "SideBar";
exports.labelBodyText = "SideBar Body";
exports.baseUrl = urlConfig.baseURL;
exports.userManagment = urlConfig.baseURL;

// Customizable Area End
