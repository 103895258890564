// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormLabel,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import StudentProfileController, {
  Props,
} from "./StudentProfileController";
import { checkFieldLengthForNumber, checkNameField } from "./utils/common";
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class StudentProfile extends StudentProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  render() {
    const { educationalDetails } = this.state;
    return (
      <form>
        <Box 
          className="ProfileEduInfo"
          tabIndex={0}
          onKeyDown={this.handleEnter("saveEducationalInfo")}
          id="educationalInfoForm"
        >
          <Typography className={`academic_details_text ${this.props.classes.heading_font}`}>
            Current Academic Details
          </Typography>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={6} className="flex_column">
              <FormLabel className={`profile_info_label_top ${this.props.classes.bodyText_font}`}>
                Date of Profile Creation/Enrollment
              </FormLabel>
              <TextField
                className={this.props.classes.textField}
                type="date"
                variant={"outlined"}
                size={"small"}
                value={educationalDetails?.profile_created}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className="flex_column spacing_pt_0">
              <FormLabel className={`profile_info_label_top ${this.props.classes.bodyText_font}`}>
                Representative
              </FormLabel>
              <TextField
                className={this.props.classes.textField}
                variant={"outlined"}
                size={"small"}
                value={educationalDetails?.current_representative}
                disabled
              />
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={6} className="flex_column">
              <FormLabel className={`profile_info_label_student ${this.props.classes.bodyText_font}`}>
                School Name
              </FormLabel>
              <TextField
                className={this.props.classes.textField}
                variant={"outlined"}
                size={"small"}
                onKeyPress={(event) => checkNameField(event)}
                value={educationalDetails?.current_school_name}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className="flex_column spacing_pt_0">
              <FormLabel className={`profile_info_label_student ${this.props.classes.bodyText_font}`}>
                Board
              </FormLabel>
              <TextField
                className={this.props.classes.textField}
                variant={"outlined"}
                size={"small"}
                value={educationalDetails?.current_board}
                disabled
              />
            </Grid>
          </Grid>

          <Typography className={`academic_details_text pt_50 ${this.props.classes.heading_font}`}>
            Previous Academic Details
          </Typography>

          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={6} className="flex_column">
                  <FormLabel className={`profile_info_label_top ${this.props.classes.bodyText_font}`}>
                    School Name
                  </FormLabel>
                  <TextField
                    className={this.props.classes.textField}
                    variant={"outlined"}
                    size={"small"}
                    onKeyPress={(event) => checkNameField(event)}
                    value={educationalDetails?.previous_school_name}
                    onChange={this.handleEducationalInputChange(
                      "previous_school_name"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="flex_column spacing_pt_0">
                  <FormLabel className={`profile_info_label_top ${this.props.classes.bodyText_font}`}>
                    Board
                  </FormLabel>
                  <TextField
                    className={this.props.classes.textField}
                    variant={"outlined"}
                    size={"small"}
                    value={educationalDetails?.previous_board}
                    onChange={this.handleEducationalInputChange(
                      "previous_board"
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={6} className="flex_column">
                  <FormLabel className={`profile_info_label_student ${this.props.classes.bodyText_font}`}>
                    Marks Obtained
                  </FormLabel>
                  <TextField
                    className={this.props.classes.textField}
                    variant={"outlined"}
                    size={"small"}
                    type="number"
                    onKeyPress={(event) => checkFieldLengthForNumber(event, 4)}
                    value={educationalDetails?.marks_obtained}
                    onChange={this.handleEducationalInputChange(
                      "marks_obtained"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="flex_column spacing_pt_0">
                  <FormLabel className={`profile_info_label_student ${this.props.classes.bodyText_font}`}>
                    Class
                  </FormLabel>
                  <TextField
                    className={this.props.classes.textField}
                    variant={"outlined"}
                    size={"small"}
                    value={educationalDetails?.previous_class}
                    onChange={this.handleEducationalInputChange(
                      "previous_class"
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={6} className="flex_column">
                  <FormLabel className={`profile_info_label_student ${this.props.classes.bodyText_font}`}>
                    Percentage
                  </FormLabel>
                  <TextField
                    className={this.props.classes.textField}
                    variant={"outlined"}
                    size={"small"}
                    type="number"
                    onKeyPress={(event) => checkFieldLengthForNumber(event, 3)}
                    value={educationalDetails?.percentage}
                    onChange={this.handleEducationalInputChange("percentage")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography className="EndAdornmentPer">
                            %
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="flex_column spacing_pt_0">
                  <FormLabel className={`profile_info_label_student ${this.props.classes.bodyText_font}`}>
                    Representative
                  </FormLabel>
                  <TextField
                    className={this.props.classes.textField}
                    variant={"outlined"}
                    size={"small"}
                    value={educationalDetails?.previous_representative}
                    onChange={this.handleEducationalInputChange(
                      "previous_representative"
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box mt={5}>
            <Button
              className={`student_update_btn ${this.props.classes.button_color_disabled}`}
              style={{
                opacity:
                  JSON.stringify(this.educationalDetailsCopy) ===
                  JSON.stringify(educationalDetails)
                    ? 0.4
                    : 1,
              }}
              type="button"
              onClick={this.saveEducationalInfo}
              disabled={
                JSON.stringify(this.educationalDetailsCopy) ===
                JSON.stringify(educationalDetails)
              }
            >
              Update
            </Button>
          </Box>
        </Box>
      </form>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export const studentEducationalInfoStyles = {
  textField: {
    "& .MuiOutlinedInput-input": {
      padding: "14px",
      height: "50px",
      boxSizing: "border-box",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ebebeb",
      },
    },
  },
};
// Customizable Area End

// Customizable Area Start
const combineStyles = (...styles:any) => {
  return function CombineStyles(theme:any) {
    const outStyles = styles.map((arg:any) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

return outStyles.reduce((acc:any, val:any) => Object.assign(acc, val));
  };
}

const combinedStyle = combineStyles(themeCreateStyle,studentEducationalInfoStyles);
export default withStyles(combinedStyle) (
  StudentProfile as React.ComponentType<any>
);
// Customizable Area End