// Customizable Area Start
import { HISTORY } from "../../../../components/src/comman";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import moment from "moment";
import convert from "xml-js";
export const configJSON = require("../config.js");
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    assesmentQuestionSummary: any;
    classes: any;
    questionNumber: any;
    numberHandler: any;
    markForReview: boolean;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AssesmentSummaryViewController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getStudentQuestionListId: string = "";
    apiSubmitPaper: string = "";
    submitQuestionAnswerId: string = "";
    apiPostPresignID: string = '';
    apiUploadBlockId: string = '';
    // Customizable Area End
    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

}
