'use strict';
const baseURL = '__MARKER_FOR_BACKEND_URL_REPLACEMENT';

//dev urls
// const baseURL =
// 'https://usermanagement.b91002.dev.us-east-1.aws.svc.builder.cafe';


const getEnvUrl = repo => {
  let url = `https://${repo}.b91002.dev.us-east-1.aws.svc.builder.cafe`;
  if (baseURL.includes('stage')) {
    url = `https://${repo}.b91002.stage.us-east-1.aws.svc.builder.ai`;
  } else if (baseURL.includes('prod')) {
    url = `https://${repo}.b91002.prod.us-east-1.aws.svc.builder.ai`;
  }
  return url;
};

const dashboardURL = getEnvUrl('dashboard');
const instituteURL = getEnvUrl('institute-management');
const attendanceURL = getEnvUrl('attendance-classroom');
const examinationUrl = getEnvUrl('examination');
const resultUrl = getEnvUrl('result-grading');

exports.baseURL = baseURL;
exports.dashboardURL = dashboardURL;
exports.instituteURL = instituteURL;
exports.attendanceURL = attendanceURL;
exports.examinationUrl = examinationUrl;
exports.resultUrl = resultUrl;
