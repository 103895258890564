// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  Select,
  MenuItem
} from "@material-ui/core";
import LabelImagewithDropDownController, {
  Props,
} from "./LabelImagewithDropDownController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import {
  imageMarksMinusSVG,
  imageMarksPlusSVG,
  imgBackArrowSVG,
  imgPlusIconSVG,
  imageErrorSVG,
  imgDeleteGraySVG,
} from "../assets";
import { FileDrop } from "react-file-drop";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import QuestionMetaData from "../Components/QuestionMetaData.web";
import "../TeacherAssessment.web.css";
import QuestionSolution from "../Components/QuestionSolution.web";
import SelectImageModal from "./SelectImageModal.web";
import AlertModal from "../../../alert/src/AlertModal.web";
import { HISTORY } from "../../../../components/src/comman";
import { ImagePreivewModal } from "../Components/ImagePreivewModal.web";
import ChooseFromGalleryModal from "./ChooseFromGalleryModal.web";
import { invalidCharsForNumberType } from "../../../profiles/src/utils/common";
import TextEditor from "./TextEditor/TextEditor.web";
import QuestionSidebarCollapse from "./QuestionSidebarCollapse.web";
import QuestionPreviewModal from "../Components/QuestionPreviewModal.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import './AddQuestion.web.css';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";

// Customizable Area End

// Customizable Area Start
const form_input_hint_number = {
  width: "97%",
  borderRadius: "10px",
  // backgroundColor: "#f9f8fd",
} as const;
// Customizable Area End

export class LabelImagewithDropDown extends LabelImagewithDropDownController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const {
      marks,
      negativeMarks,
      sectiondata,
      charNumber,
      selectedCategoryQuestionData,
      inputBlocksAddedOnQuestionImage,
    } = this.state;
    const WhiteRadio = withStyles({
      root: {
        color: "#3f526d",
        padding: "0px",
        "&$checked": {
          color: "white",
          backgroundColor: "#3f526d",
          padding: "2px",
        },
      },
      checked: {},
    })((props: RadioProps) => <Radio color="default" {...props} />);


    const dropAnswer = (e: any, questionUniqId: any) => {
      e.preventDefault();
      const div_id = e.dataTransfer.getData("div_id");
      const block = document.getElementById(div_id);
      let myimg = null;
      let mysrc = null;
      if (block) {
        myimg = block.getElementsByTagName("img")[0];
      }
      if (myimg) {
        mysrc = myimg.src;
      }

      let tempQuestionList = this.state.inputBlocksAddedOnQuestionImage;
      const index = this.state.inputBlocksAddedOnQuestionImage.findIndex(
        (res: any) => res.uniqId === questionUniqId
      );
      const data = tempQuestionList[index];
      tempQuestionList.map((item: any) => {
        if (item.correctAnswerId === div_id.replace("answer-", "")) {
          item.correctAnswerId = "";
          item.answer = "";
        }
        return item;
      });
      tempQuestionList[index] = {
        ...data,
        correctAnswerId: div_id.replace("answer-", ""),
        answer: block?.innerText ? block?.innerText : "",
      };

      this.setState(
        {
          inputBlocksAddedOnQuestionImage: tempQuestionList,
          internallyDraggedAnswer: false,
        }
      );
    };

    const dragOver1Answer = (e: any) => {
      e.preventDefault();
    };

    const dragStartAnswer = (e: any) => {
      const target = e.target;
      e.dataTransfer.setData("div_id", target.id);
      this.setState({ internallyDraggedAnswer: true });
    };

    return (
      <>
        <div>
          {this.state.showLoader && (
            <Spinner spinnerModal={this.state.showLoader} />
          )}
          <Grid
            container
            className="marks_section"
          >
            <Grid
              item
              xs={12}
              sm={10}
              className="question_flex_column"
            >
              <Typography
                className={`back_with_arrow ${this.props.classes.bodyText_font}`}
              >
                <span onClick={HISTORY.goBack}>
                  <img
                    src={imgBackArrowSVG}
                    className="question_back_arrow"
                  ></img>
                  Back
                </span>

                <span
                  className={`${this.props.classes.bodyText_font} textlabel_breadcrum`}
                >
                  <span className={`text-style-light`}>
                    {this.state.isEdit
                      ? "Edit question "
                      : "Create new question "}{" "}
                  </span>
                  (
                  {selectedCategoryQuestionData?.question_category_id === 1 ? (
                    <>Objective</>
                  ) : (
                    <>Subjective</>
                  )}{" "}
                  - {selectedCategoryQuestionData?.name?.replaceAll("_", " ")})
                </span>
              </Typography>

              <div className="marks_wrap_div">
                <div className="marks_div">
                  <Typography
                    className={`question_marks_text ${this.props.classes.subHeading_font}`}
                  >
                    Marks
                  </Typography>
                  <div>
                    <div
                      className={this.state.marksError ? `view_marksbox red_border` : `view_marksbox none_border`}
                    >
                      <img
                        src={imageMarksPlusSVG}
                        className="image_marks"
                        onClick={this.plusMarksClick}
                      ></img>

                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={(event) => {
                          const value: number = parseFloat(
                            parseFloat(event.target.value)
                              .toFixed(3)
                              .slice(0, -1)
                          );
                          if (value > 0 || marks > 0) {
                            this.setState({ marksError: false });
                          } else {
                            this.setState({
                              marksError: true,
                              marks: 0,
                              marksErrorText: "Please enter marks.",
                            });
                          }
                          if (value < this.state.sectiondata?.remaining_marks) {
                            this.setState({ marks: value, marksError: false });
                          } else if (
                            value ==
                            this.state.sectiondata?.remaining_marks - 1
                          ) {
                            this.setState({ marks: value, marksError: false });
                          }
                          if (value >= this.state.negativeMarks) {
                            this.setState({
                              marks: value,
                              negmarksError: false,
                            });
                          } else {
                            this.setState({
                              marks: value,
                              negmarksError: true,
                            });
                          }
                          if (this.state.isEdit) {
                            if (
                              value >
                              this.state.sectiondata?.remaining_marks +
                              HISTORY.location.state?.questionData?.attributes
                                ?.marks
                            ) {
                              this.setState({
                                marks: value,
                                marksError: true,
                                marksErrorText:
                                  "Marks not greater than section marks.",
                              });
                            } else {
                              this.setState({
                                marks: value,
                              });
                            }
                          } else {
                            if (
                              value > this.state.sectiondata?.remaining_marks
                            ) {
                              this.setState({
                                marks: value,
                                marksError: true,
                                marksErrorText:
                                  "Marks not greater than section marks.",
                              });
                            } else {
                              this.setState({
                                marks: value,
                              });
                            }
                          }
                        }}
                        onKeyPress={(event) => {
                          if (invalidCharsForNumberType.includes(event.key)) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        value={marks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.marksError && (
                      <FormHelperText className="red_text">
                        {this.state.marksErrorText}
                      </FormHelperText>
                    )}
                  </div>
                </div>

                {this.state.assessmentHasNegativeMarking && (
                  <div className="marks_div">
                    <Typography
                      className={`question_marks_text ${this.props.classes.subHeading_font}`}
                    >
                      Negative Marks
                    </Typography>
                    <div
                      className={this.state.negmarksError ? "view_marksbox red_border" : "view_marksbox none_border"}
                    >
                      <img
                        src={imageMarksMinusSVG}
                        className="image_marks"
                        onClick={this.minusMarksClick}
                      ></img>

                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={(event) => {
                          const value: number = parseFloat(
                            parseFloat(event.target.value)
                              .toFixed(3)
                              .slice(0, -1)
                          );
                          if (value > this.state.marks) {
                            this.setState({ negmarksError: true });
                          } else {
                            this.setState({ negmarksError: false });
                          }
                          this.setState({ negativeMarks: value });
                        }}
                        onKeyPress={(event) => {
                          if (invalidCharsForNumberType.includes(event.key)) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        value={negativeMarks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.negmarksError && (
                      <FormHelperText className="red_text">
                        Negative marking not greater then marks.
                      </FormHelperText>
                    )}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={2} className="remaining_section_marks">
              <Typography
                className={`remaining_marks ${this.props.classes.subHeading_font}`}
              >
                Remaining Section Marks
              </Typography>

              <div className="circular_div">
                <CircularProgress
                  variant="static"
                  className="grey_circular"
                  size={92}
                  thickness={2}
                  value={100}
                />
                <CircularProgress
                  variant="static"
                  disableShrink
                  className="colored_circular"
                  size={92}
                  value={this.state.remainSectionMarkPr}
                  thickness={2}
                />
                <Typography
                  className={`circular_marks_text ${this.props.classes.bodyText_font}`}
                >
                  {(
                    sectiondata?.section_marks - sectiondata?.remaining_marks
                  ).toFixed(2)}
                  /{sectiondata?.section_marks}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Typography
            className={`question_text ${this.props.classes.subHeading_font}`}
          >
            Question
          </Typography>
          <div ref={this.questionEditorRef}>
            <TextEditor
              myref={this.questionEditorRef}
              handleChange={(value: any) =>
                this.setState({ question: value }, () => {
                  if (
                    this.state.question === null ||
                    this.state.question === ""
                  ) {
                    this.setState({ questionError: true });
                  } else {
                    this.setState({ questionError: false });
                  }
                })
              }
              editorData={
                HISTORY.location.state?.questionData?.attributes?.title
                  ? HISTORY.location.state?.questionData?.attributes?.title
                  : ""
              }
              isEdit={this.state.isEdit}
            />
          </div>
          {this.state.questionError && (
            <FormHelperText className="question_error">
              Please enter question.
            </FormHelperText>
          )}
          <FileDrop
            onDrop={(files: any, event: any) => {
              window.addEventListener(
                "drop",
                function (e) {
                  e.preventDefault();
                },
                false
              );

              let totalSize: number = 0;
              const keys = Object.keys(files);
              keys?.map(async (key: any) => {
                const file = files[key];
                totalSize = totalSize + file.size;
              });
              if (files.length <= 1 && totalSize < 524288000) {
                this.handleFileOnChange(event, files);
              } else {
                if (files.length > 1) {
                  this.setState({
                    alertMessage: "You can upload maximum 1 files",
                    alertModal: true,
                  });
                } else {
                  this.setState({
                    alertMessage: "Maximum files size: 500MB",
                    alertModal: true,
                  });
                }
              }
            }}
          >
            <div
              className={this.state.imageError ? "upload_image_error_div" : "upload_image_div"}
            >
              {this.state.showLoaderForImageUpload ? (
                <div className="image_uploading_div">
                  <CircularProgress />
                  <Typography
                    className={`image_uploading_text ${this.props.classes.bodyText_font}`}
                  >
                    Uploading...
                  </Typography>
                </div>
              ) : (
                <>
                  {this.state.selectedImageBlobUrl ? (
                    <>
                      <div className="position_relative">
                        <img
                          src={this.state.selectedImageBlobUrl}
                          className="hot_spot_image"
                          onClick={this.handlePlaceInputBlock}
                          onError={() => {
                            this.setState({
                              selectedImageBlobUrl: imageErrorSVG,
                            });
                          }}
                        ></img>
                        {inputBlocksAddedOnQuestionImage?.length > 0 &&
                          inputBlocksAddedOnQuestionImage
                            .filter((element: any) => !element._destroy)
                            .map((item: any, index: number) => (
                              <div
                                key={item?.uniqId || index}
                                style={{
                                  top: `${item.top}%`,
                                  left: `${item.left}%`,
                                }}
                                className={`${this.props.classes.bodyText_font} label_image_block label_image_question`}
                              >
                                <img
                                  src={imgDeleteGraySVG}
                                  className="hotspot_question_delete_icon"
                                  onClick={() => {
                                    this.handleRemoveInputBlock(item.uniqId);
                                  }}
                                ></img>
                              </div>
                            ))}
                      </div>
                      <Button
                        disableRipple={true}
                        variant="outlined"
                        className={`remove_question_image ${this.props.classes.subHeading_font}`}
                        onClick={this.removeSelectedImage}
                      >
                        Remove
                      </Button>
                    </>
                  ) : (
                    <>
                      <img
                        src={imgPlusIconSVG}
                        className="img_plus_icon"
                        onClick={() =>
                          this.setState({
                            openSelectImageModal: true,
                          })
                        }
                      />
                      <input
                        ref={this.inputFirstOpenFileRef}
                        accept="image/png, image/jpg, image/jpeg,"
                        className="hidden_input"
                        type="file"
                        onChange={(e: any) => {
                          let totalSize: number = 0;
                          let isAllImageTypeFiles = false;
                          var files = e.target.files;
                          const keys = Object.keys(files);
                          keys?.map(async (key: any) => {
                            const file = files[key];
                            totalSize = totalSize + file.size;
                            let fileExtension = file.name.split(".").pop();
                            if (
                              fileExtension === "png" ||
                              fileExtension === "jpg" ||
                              fileExtension === "jpeg"
                            ) {
                              isAllImageTypeFiles = true;
                            } else {
                              isAllImageTypeFiles = false;
                            }
                          });
                          if (isAllImageTypeFiles) {
                            if (
                              e.target.files.length <= 1 &&
                              totalSize < 524288000
                            ) {
                              this.handleFileOnChange(e, null);
                            } else {
                              if (e.target.files.length > 1) {
                                this.setState({
                                  alertMessage:
                                    "You can upload maximum 1 files",
                                  alertModal: true,
                                });
                              } else {
                                this.setState({
                                  alertMessage: "Maximum files size: 500MB",
                                  alertModal: true,
                                });
                              }
                            }
                          } else {
                            this.setState({
                              alertMessage: "only image file can be upload",
                              alertModal: true,
                            });
                          }
                        }}
                      />

                      <Typography
                        className={`upload_image_text ${this.props.classes.bodyText_font}`}
                      >
                        Drag and drop file here or browse them from your
                        computer.
                      </Typography>
                    </>
                  )}
                </>
              )}
            </div>
            {this.state.imageError && (
              <FormHelperText className="upload_image_warning">
                Please upload image.
              </FormHelperText>
            )}
          </FileDrop>

          {this.state.selectedImageBlobUrl && (
            <>
              {inputBlocksAddedOnQuestionImage?.length > 0 &&
                inputBlocksAddedOnQuestionImage
                  .filter((element: any) => !element._destroy)
                  .map((item: any, index: number) => (
                    <>
                      <div className="match_type_div">
                        <Typography
                          className={`match_list_text ${this.props.classes.subHeading_font}`}
                        >
                          Response {index + 1}
                        </Typography>
                        <div className="match_responses_list">
                          {item.possibleResponseList?.filter((item: any) => !item._destroy)
                            ?.map((data: any, responseIndex: number) => (
                              <div
                                key={data.responseId || responseIndex}
                                className="label_image_response"
                                id={`response-${data.responseId}`}
                              >
                                <TextField
                                  id="standard-basic"
                                  placeholder={`Choice ${String.fromCharCode(65 + responseIndex)}`}
                                  style={form_input_hint_number}
                                  variant="outlined"
                                  value={data?.response}
                                  inputProps={{
                                    style: {
                                      fontFamily: "Open sans",
                                      fontSize: 15.8,
                                      color: "#03014c",
                                    },
                                  }}
                                  onChange={(event: any) =>
                                    this.possibleResponseOnchange(event, item.uniqId, responseIndex)
                                  }
                                />

                                <CloseIcon
                                  className="remove_match_icon"
                                  onClick={() =>
                                    this.removePossibleResponseOnClick(data.responseId, item.uniqId, responseIndex)
                                  }
                                />
                              </div>
                            ))}
                        </div>
                        <Button
                          onClick={() => this.addPossibleResponseOnClick(item.uniqId)}
                          className={`add_new_match_btn ${this.props.classes.outline_button_no_hover}`}
                        >
                          <AddIcon className="add_new_match_icon" />
                          Add New
                        </Button>
                      </div>
                    </>
                  ))}
            </>
          )}

          {this.state.selectedImageBlobUrl && (
            <>
              <div className="hot_spot_set_answer_div">
                <Typography
                  className={`hot_spot_set_answer_text ${this.props.classes.subHeading_font}`}
                >
                  Set Correct Answer(s)
                </Typography>
              </div>
              {this.state.showLoaderForImageUpload ? (
                <div className="image_uploading_div">
                  <CircularProgress />
                  <Typography
                    className={`image_uploading_text ${this.props.classes.bodyText_font}`}
                  >
                    Uploading...
                  </Typography>
                </div>
              ) : (
                <div className="upload_image_div">
                  <div className="position_relative">
                    <img
                      src={this.state.selectedImageBlobUrl}
                      className="hot_spot_image"
                      onError={() => {
                        this.setState({
                          selectedImageBlobUrl: imageErrorSVG,
                        });
                      }}
                    ></img>
                    {inputBlocksAddedOnQuestionImage?.length > 0 &&
                      inputBlocksAddedOnQuestionImage
                        .filter((element: any) => !element._destroy)
                        .map((item: any, index: number) => (
                          <div
                            key={item?.uniqId || index}
                            style={{
                              top: `${item.top}%`,
                              left: `${item.left}%`,
                            }}
                            className={`${this.props.classes.bodyText_font} label_image_block label_image_answer`}
                            onDrop={(e: any) => dropAnswer(e, item?.uniqId)}
                            id={`answer-${item.correctAnswerId}`}
                          >

                            <Select
                              value={item.correctAnswerId}
                              className={`full_width_select ${this.props.classes.input_theme_style}`}
                              disableUnderline
                              onChange={(e) => this.setSelectedAnswer(e, item.uniqId)}
                            >
                              {
                                item?.possibleResponseList?.map((options: any, idx: number) => (
                                  <MenuItem key={idx} value={options.responseId}>{options.response}</MenuItem>
                                ))
                              }
                            </Select>
                          </div>
                        ))}
                  </div>
                </div>
              )}
            </>
          )}
          <QuestionSolution
            hintAnswer={this.state.hintAnswer}
            hintAnswerOnchange={(value: any, id: any) => {
              let tempData = this.state.hintAnswer;
              const index = this.state.hintAnswer.findIndex(
                (res: any) => res.hintId === id
              );
              tempData[index].hint = value.trim();
              this.setState({
                hintAnswer: tempData,
              });
            }}
            explaination={this.getExplanation()}
            getPreSignedValue={(
              preSignedId: string,
              typeofAttachment: string,
              blobUrl: string
            ) => {
              if (typeofAttachment === "hints") {
                let tempData = this.state.hintAnswer;
                tempData[0].files = [preSignedId];
                tempData[0].src = blobUrl;
                this.setState({
                  hintAnswer: tempData,
                  hintId: preSignedId,
                });
              } else {
                this.setState({ solutionImageSignedId: preSignedId });
              }
            }}
            explainationOnchange={(value: any) => {
              this.setState({
                explaination: value,
              });
            }}
            attachmentExplanationUrl={this.state.attachmentExplanationUrl}
            hintsExplanationUrl={this.state.hintsExplanationUrl}
            removeSolutionImage={(attachmentType: string) => {
              if (attachmentType === "hints") {
                this.setState({
                  hintsExplanationUrl: "",
                  hintId: null,
                });
              } else {
                this.setState({
                  attachmentExplanationUrl: "",
                  solutionImageSignedId: null,
                });
              }
            }}
          />
          <QuestionMetaData
            selectedSubject={this.state.selectedSubject}
            onChangeSubject={(e: any) => {
              this.setState({ selectedSubject: e.target.value });
            }}
            chapterListData={this.state.chapterListData}
            selectedChapter={this.state.selectedChapter}
            onChangeChapter={(e: any) => {
              this.setState(
                {
                  selectedChapter: e.target.value,
                  metaDataErrors: {
                    ...this.state.metaDataErrors,
                    chapter: false,
                  },
                },
                () => this.get_unit_list(this.state.selectedChapter, true)
              );
            }}
            unitListData={this.state.unitListData}
            selectedUnit={this.state.selectedUnit}
            onChangeUnit={(e: any) => {
              this.setState({
                selectedUnit: e.target.value,
                metaDataErrors: { ...this.state.metaDataErrors, unit: false },
              });
            }}
            selectedSkill={this.state.selectedSkill}
            onChangeSkill={(e: any) => {
              this.setState({
                selectedSkill: e.target.value,
                metaDataErrors: { ...this.state.metaDataErrors, skill: false },
              });
            }}
            selectedProficiency={this.state.selectedProficiency}
            onChangeProficiency={(e: any) => {
              this.setState({
                selectedProficiency: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  proficiency: false,
                },
              });
            }}
            selectedDifficultyLevel={this.state.selectedDifficultyLevel}
            onChangeDifficultyLevel={(e: any) => {
              this.setState({
                selectedDifficultyLevel: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  difficultyLevel: false,
                },
              });
            }}
            keyword={this.state.keyword}
            keywordsList={this.state.keywordsList}
            onChangeKeywords={this.onChangeKeywords}
            onBlurKeywords={this.onBlurKeywords}
            onKeyDownKeywords={this.onKeyDownKeywords}
            handleDeleteKeyword={this.handleDeleteKeyword}
            metaDataErrors={this.state.metaDataErrors}
            selectedBloomTaxonomy={this.state.selectedBloomTaxonomy}
            onChangeBloomTaxonomy={(e: any) => {
              this.setState({
                selectedBloomTaxonomy: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  bloomTaxonomy: false,
                },
              });
            }}
          />

          <div className="question_action_items">
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.background_button_no_hover}`}
              onClick={() => {
                this.setState({ prevQuestionModal: true });
              }}
            >
              <Box className="preview_btn_box">
                <VisibilityIcon className="eye_preview" />
                <Typography> Preview</Typography>
              </Box>
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.button_color}`}
              onClick={this.saveQuestion}
            >
              {this.state.isEdit ? "Update" : "Save"}
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_cancel_btn ${this.props.classes.outline_button_no_hover}`}
              onClick={()=>HISTORY.push("/TeacherAssessmentCreateSection")}
            >
              Cancel
            </Button>
          </div>

          <AlertModal
            alertModal={this.state.alertModal}
            isTeacher={true}
            onCloseModal={() => {
              this.setState({ alertModal: false });
            }}
            alertTitle="Alert!"
            alertMessage={this.state.alertMessage}
            buttonText="OK"
          />

          <SelectImageModal
            openSelectImageModal={this.state.openSelectImageModal}
            onCloseModal={() => this.setState({ openSelectImageModal: false })}
            onClickChooseFromGallery={() => {
              this.setState({
                openChooseFromGalleryModal: true,
                openSelectImageModal: false,
              });
            }}
            onClickUploadFromLocal={this.showFirstOpenFileDlg}
          />
          <QuestionPreviewModal
            questionType={"label_image_with_text"}
            selectedImageBlobUrl={this.state.selectedImageBlobUrl}
            questionPreviewModal={this.state.prevQuestionModal}
            closeModalFun={() => {
              this.setState({ prevQuestionModal: false });
            }}
            optionAnswer={[]}
            question={this.state.question}
            questionHint={this.state.hintAnswer}
            boxCoordinates={inputBlocksAddedOnQuestionImage}
          />
          <ChooseFromGalleryModal
            openChooseFromGalleryModal={this.state.openChooseFromGalleryModal}
            onCloseModal={() =>
              this.setState({ openChooseFromGalleryModal: false })
            }
            onClickImage={(id: any, url: any) => {
              this.setState({
                mainGalleryImageId: id,
                selectedGalleryImageId: id,
                selectedGalleryImageUrl: url,
              });
            }}
            onClickChoose={() => this.onClickGalleryImageChoose()}
            selectedGalleryImageId={this.state.selectedGalleryImageId}
            onTabChangeClearId={() =>
              this.setState({ selectedGalleryImageId: 0 })
            }
          />
        </div>
        {this.state.isEdit && (
          <QuestionSidebarCollapse
            editedQuestionData={HISTORY.location.state?.questionData}
          />
        )}
        <ImagePreivewModal
          previewImageModal={this.state.previewImageModal}
          modalhandleClose={() => this.setState({ previewImageModal: false })}
          previewImage={this.state.previewImage}
        />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(
  LabelImagewithDropDown as React.ComponentType<any>
);

// Customizable Area End
