Object.defineProperty(exports, "__esModule", {
  value: true,
});

const urlConfig = require("../../../../framework/src/config");

// Customizable Area Start
exports.dashboardContentType = "application/json";
exports.formDataContentType = "multipart/form-data";

exports.dashboardApiMethodType = "GET";
exports.dashboardApiPostMethodType = "POST";
exports.dashboardApiPutMethodType = "PUT";
exports.deleteSchoolMethodType = "DELETE";
exports.dashboardURL = urlConfig.dashboardURL;
exports.instituteURL = urlConfig.instituteURL;
exports.userManagementURL = urlConfig.baseURL;
exports.attendanceURL = urlConfig.attendanceURL;
exports.getSubjectEndPoint="bx_block_scheduling/subjects/class_section_subjects";
exports.periodEndPoint = "/bx_block_scheduling/school_periods";
exports.batchesEndPoint = "/bx_block_organisationhierarchy/batches";
exports.FileUploadUrlEndPoint = "/bx_block_bulk_uploading/import_periods"
exports.classTeachersEndPoint =
  "/bx_block_scheduling/school_classes/get_class_teachers";
exports.subjectsEndPoint =
  "/account_block/school_accounts/get_teacher_classes_subjects";
exports.classesListEndPoint = "/bx_block_scheduling/school_classes";
exports.addEditPeriodEndPoint = "/bx_block_scheduling/periods";
exports.getSchoolSubjectPoint = "/bx_block_scheduling/schools/get_school_subjects"
exports.addPeriodEndPoint = "bx_block_scheduling/periods/generate_automatic_timetable";
exports.getSchoolPeriodEndPoint = "bx_block_scheduling/school_periods/school_period_by_day";
exports.addPeriodCreateEndPoint = "/bx_block_scheduling/periods/admin_create_period ";

// Customizable Area End
