import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from 'react-toastify'
import { config } from "process";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtSavedValue: string;
  CreateOrEdit: string | null;
  dashboardData: any;
  totalPage: any;
  currentPage: number;
  dialogOpen: boolean;
  thoughts: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  data: any;
  studentGradeRange: any;
  selectedIndex: number;
  listView: string;
  editGrade: any;
  perPage: number;
  totalCount: any,
  nextPage: any,
  offset: any,
  removeModal: boolean;
  id: string;
  grandeNameError: boolean,
  grandeNameErrorMssg: string,
  minMarksError: boolean,
  minMarksErrorMssg: string,
  maxMarksError: boolean,
  maxMarksErrorMssg: string,
  labelError: boolean,
  labelErrorMssg: string,
  paginationMeta: any,
  grade: {
    name: string;
    min_marks: number | null;
    max_marks: number | null;
    label: string;
    field_id: number | null;
  };
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}
export default class AdminGradeViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getThoughtlist: string = "";
  gradeMessageID: string;
  deleteMessageID: string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      grade: { name: "", min_marks: null, max_marks: null, label: "", field_id: null, },
      txtSavedValue: "",
      dashboardData: [],
      thoughts: "",
      token: "",
      errorMsg: "",
      loading: false,
      data: "",
      dialogOpen: false,
      studentGradeRange: 100,
      selectedIndex: 2,
      listView: "",
      editGrade: "",
      totalPage: null,
      currentPage: 0,
      CreateOrEdit: null,
      perPage: 9,
      totalCount: 1,
      nextPage: 2,
      offset: 0,
      removeModal: false,
      id: "",
      grandeNameError: false,
      grandeNameErrorMssg: "",
      minMarksError: false,
      minMarksErrorMssg: "",
      maxMarksError: false,
      maxMarksErrorMssg: "",
      labelError: false,
      labelErrorMssg: "",
      paginationMeta: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  handleDialogClose=()=>{
    this.setState({
      dialogOpen:false,
      grandeNameError: false,
      grandeNameErrorMssg: "",
      minMarksError: false,
      minMarksErrorMssg: "",
      maxMarksError: false,
      maxMarksErrorMssg: "",
      labelError: false,
      labelErrorMssg: "",
      // grade: { name: "", min_marks: null, max_marks: null, label: "", field_id: null }
 })
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getGrade();
  }

  OpenPostEventModal = () => {
    this.setState({
      // postEvent:true
    });
  };

  closePostEventModal = () => {
    this.setState({
      // postEvent:false
    });
  };

  handleremoveClose = () => {
    this.setState({
      removeModal: false
    });
  };

  handleOpenRemoveModal = (id: string) => {
    this.setState({
      removeModal: true,
      id
    });
  };


  getGrade = () => {
    const user_data = localStorage.getItem("user_data");

    const data = JSON.parse(user_data || "{}");

    const header = {
      "Content-Type": configJSON.gradeContentType,
      token: localStorage.getItem("token"),
      "Access-Control-Allow-Origin": "*",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getThoughtlist = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getThoughtListEndPoint + `school_id=${data.school_id}&per=${this.state.perPage}&page=${this.state.offset}`
      // configJSON.getThoughtListEndPoint + `school_id=${data.school_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.BASEURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  resetValidation = () => {
    this.setState({
      grandeNameError: false,
      grandeNameErrorMssg: "",
      labelError: false,
      labelErrorMssg: "",
      minMarksError: false,
      minMarksErrorMssg: "",
      maxMarksError: false,
      maxMarksErrorMssg: "",
    });
  }

  isValidData = () => {
    let isValid = true;
    const name = this.state.grade.name.trim();
    if (name.length === 0) {
      this.setState({
        grandeNameError: true,
        grandeNameErrorMssg: "Grade name is required.",
      });
      isValid = false;
    }
    const label = this.state.grade.label.trim();
    if (label.length === 0) {
      this.setState({
        labelError: true,
        labelErrorMssg: "Label is required.",
      });
      isValid = false;
    }
    const minMarks = this.state.grade.min_marks;
    const maxMarks = this.state.grade.max_marks;
    if (minMarks === null || minMarks < 0) {
      this.setState({
        minMarksError: true,
        minMarksErrorMssg: "Min marks is required.",
      });
      isValid = false;
    }
    if (maxMarks === null || maxMarks < 0) {
      this.setState({
        maxMarksError: true,
        maxMarksErrorMssg: "Max marks is required.",
      });
      isValid = false;
    }
    if (minMarks !== null && maxMarks !== null && maxMarks <= minMarks) {
      this.setState({
        maxMarksError: true,
        maxMarksErrorMssg: "Max marks should be greater than min marks.",
      });
      isValid = false;
    }

    return isValid;
  };

  createGrade = () => {
    
    if (this.isValidData() === false) return; 
    this.resetValidation();
    const user_data = localStorage.getItem("user_data");

    const data = JSON.parse(user_data || "{}");

    const header = {
      "Content-Type": configJSON.gradeContentType,
      token: localStorage.getItem("token"),
      "Access-Control-Allow-Origin": "*",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.gradeMessageID = requestMessage.messageId;
    // this.getThoughtlist = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createGradeApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.BASEURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    if (this.state.CreateOrEdit == 'create') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
    }
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ ...this.state.grade, school_id: data.school_id })
    );



    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

 handleKeyDown=(e:any)=>{
  if(e.key==='Enter'){
   if(this.state.CreateOrEdit === 'create'){
      this.createGrade()

     }
   else{ 
     this.editGrade()
    }
  }
}
 

  editGrade = () => {
   
    if (this.isValidData() === false) return; 
    this.resetValidation();
    const user_data = localStorage.getItem("user_data");

    const data = JSON.parse(user_data || "{}");

    const header = {
      "Content-Type": configJSON.gradeContentType,
      token: localStorage.getItem("token"),
      "Access-Control-Allow-Origin": "*",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.gradeMessageID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createGradeApi}/${this.state.grade.field_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.BASEURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ ...this.state.grade, school_id: data.school_id })
    );




    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteGrade = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.gradeContentType,
      token: localStorage.getItem("token"),
      "Access-Control-Allow-Origin": "*",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteMessageID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createGradeApi}/${this.state.grade.field_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.BASEURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),

      `${configJSON.createGradeApi}/${this.state.grade.field_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ school_id: data.school_id })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteGradeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);



  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getThoughtlist) {
        this.setState({ loading: false });

        if (responseJson != null) {
          console.log('responseJson', responseJson)
          console.log('errorReponse', errorReponse)
          if (!responseJson.errors) {
            this.setState({
              data: responseJson.data,
              nextPage: responseJson?.data?.pagination?.next_page,
              totalPage: Math.ceil(responseJson.data.length / this.state.perPage),
              paginationMeta: responseJson?.meta,
            }, () => {

            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }

        }
        //-----
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({
          //spinner:false
        });
      }
      if (apiRequestCallId === this.gradeMessageID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            toast.success('Success.')
            this.setState(
              {
                dialogOpen: false,
                grade: { name: "", min_marks: null, max_marks: null, label: "", field_id: null },
              },
              () => {
                this.getGrade();
              }
            );
          }
          else {
            responseJson?.errors.map((_key:any, index:any)=>{
              let key: any = Object.keys(responseJson?.errors[index])
                        toast.error(`${responseJson?.errors[index][key]}`)
            })
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.deleteMessageID) {
        toast.success('Deleted successfully.')
        this.getGrade();
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
