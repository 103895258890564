// Customizable Area Start
export const cloud = require("../../assets/image-cloud@3x.png")
export const drive = require("../../assets/image-drive@3x.png")
export const deleteIcon = require("../../assets/image-delete@3x.png")
export const folder = require("../../assets/image-folder@3x.png")
export const iconMore = require("../../assets/image-more.png")
export const pdf = require("../../assets/image-pdffile@3x.png")
export const jpg = require("../../assets/jpg-icon.png")
export const xls = require("../../assets/image-xlsfile@3x.png")
export const doc = require("../../assets/image-docfile@3x.png")
export const list = require("../../assets/group-23.png")
export const grid = require("../../assets/group-22.png")
export const deleteRedIcon = require("../../assets/image-delete-red.png")

// more modal
export const share = require("../../assets/image-share@3x.png")
export const getlink = require("../../assets/image-getlink@3x.png")
export const folderlocation = require("../../assets/image-folderlocation@3x.png")
export const moveto = require("../../assets/image-moveto@3x.png")
export const reName = require("../../assets/image-rename@3x.png")
export const viewDetails = require("../../assets/image-viewdetails@3x.png")
export const makeACopy = require("../../assets/image-makecopy@3x.png")
export const download = require("../../assets/image-download@3x.png")
export const move = require("../../assets/image-folder-delete@3x.png")
export const disabled_list = require("../../assets/disabledlist.png")
export const active_grid = require("../../assets/activegrid.png")
export const fileupload = require("../../assets/fileupload.png")
export const fileuploaded =require("../../assets/Groupfile done.png")
export const ic_assignee = require("../../assets/assignee.png")
export const ic_minus = require("../../assets/minus.png")
export const image_upload = require("../../assets/image-upload.png")
export const image_file = require("../../assets/image-file.png")
export const file_download = require("../../assets/file_download.png")
export const docICon = require("../../assets/image-docfile.png")
export const xlsIcon = require("../../assets/image-xlsfile.png")
export const fileUploadImage = require("../../assets/fileUploadImage.png")
export const close_icon = require("../../assets/close.png")
export const png_icon = require("../../assets/png-icon.png")
export const gif_icon = require("../../assets/gif.png")
export const closegrey_icon = require("../../assets/crossButton.png")
export const user_icon = require("../../assets/image-adduser-big.png")
export const fileicon = require("../../assets/file-icon.png")
// Customizable Area End

