// Customizable Area Start
import React from 'react';
import 'react-phone-input-2/lib/style.css';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import 'react-datetime/css/react-datetime.css';
import './TeacherAccountRegistration.css';
import GradesAndLevelsController, {
  Props,
} from './GradesAndLevelsController.web';
import OnBoardingSideBar from './OnBoardingSideBar.web';
import Spinner from '../../shared/SideBar/src/Spinner';
import AlertModal from '../../alert/src/AlertModal.web';
import HOCAuth from '../../shared/Header/src/HOCAuth.web';
import "./GradesAndLevels.web.css";
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
import { withStyles } from '@material-ui/core'
// Customizable Area End

// Customizable Area Start
export const configJSON = require('./config');
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class GradesAndLevels extends GradesAndLevelsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    return (
      <>
        <Grid container className="GradesAndLevelParentBox">
          {this.state.loadingSpinner && (
            <Spinner spinnerModal={this.state.loadingSpinner} />
          )}

          <Grid item xs={12} sm={3} md={3} lg={3}>
            <OnBoardingSideBar activePage={3} />
          </Grid>
          <Grid
            item
            sm={9}
            md={9}
            lg={9}
            xs={12}
          >
            <Grid container>
              <Grid item xs={12} className="HeadingBox">
                <div className={`textlabel_welcomeText ${this.props.classes.theme_font}`}>Grades & Levels</div>
              </Grid>
              <Grid
                item
                xs={12}
                className="BoardName"
              >
                <div className={`textlabel_Header ${this.props.classes.theme_font}`}>{this.state.boardName}</div>
              </Grid>
              <Grid item xs={12}>
                <Grid container className="Container">
                  <Grid item sm={9} md={9} lg={6} xs={12}>
                    {this.state.classesDetails.map((_data: any, index: any) => {
                      return (
                        <Grid
                          container
                          spacing={0}
                          className="DataBox"
                          key={index}
                        >
                          <Grid
                            item
                            xs={5}
                            className={`Typo ${this.props.classes.theme_font}`}
                          >
                            {_data.className}
                          </Grid>
                          <Grid item xs={1} className="CheckboxContainer">
                            <Checkbox
                              checked={_data.onSchool}
                              onChange={(event: any) => {
                                let arr = this.state.classesDetails;
                                arr[index].onSchool = event.target.checked;
                                this.setState({ classesDetails: arr });
                              }}
                              className="checkbox"
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <TextField
                              id="standard-basic"
                              placeholder="Enter Class Name"
                              className={`forrmInput_textfield`}
                              variant="outlined"
                              value={_data.showClassName}
                              onChange={(event: any) => {
                                let arr = this.state.classesDetails;
                                arr[index].showClassName = event.target.value;
                                arr[index].error = false;
                                this.setState({ classesDetails: arr });
                              }}
                              error={_data.error}
                              helperText={
                                _data.error ? 'Class name is required.' : ''
                              }
                              inputProps={{
                                style: {
                                  fontFamily: 'Open sans',
                                  fontSize: '1rem',
                                  color: '#03014c',
                                  padding: '8px',
                                },
                                className: 'onboarding_text_fields',
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              className="BtnContainer"
            >
              <Button
                className="NextBtn"
                onClick={(event: any) => this.goNext(event)}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <AlertModal
          alertModal={this.state.alertModal}
          isTeacher={false}
          onCloseModal={() => {
            this.setState({ alertModal: false });
          }}
          alertTitle="Alert!"
          alertMessage={'Please select grade.'}
          buttonText="OK"
        />
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(GradesAndLevels, "SuperAdminAccount"));
// Customizable Area End
