// Customizable Area Start
import React from "react";
import ReactPaginate from "react-paginate";
import {
  Typography,
  Grid,
  Box
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import AnswerSheetController, { Props } from "./AnswerSheetController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import {
  imgBackArrow,
  exam
} from "../assets";
import { HISTORY } from "../../../../components/src/comman";
import './AnswerSheet.web.css';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class AnswerSheet extends AnswerSheetController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return (
      <div>
        <div>
          {/* {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />} */}
          <div className="answerSheet_mainDiv"
          >
            <Typography
              className="answerSheet_backArrowDiv"
              onClick={HISTORY.goBack}
            >
              <img
                src={imgBackArrow}
                className='answerSheet_backArrowImg'
              />
              Back
            </Typography>
          </div>
          <div>
            <div
              className="answerSheet_topicDiv"
            >
              <div>
                Assessment for Established fact Quiz submitted on 25.07.2021, by
                <span
                  className="answerSheet_userTxt"
                >
                  {" "}
                  Dante Alighieri
                </span>
              </div>
            </div>
            <div
              className="answerSheet_topicDiv"
            >
              <Grid container>
                <Grid item xs={3} md={2}>
                  {`Score : `}<span className="answerSheet_spantxt">48 (out of 50)</span>
                </Grid>
                <Grid item xs={5} md={3}>
                  <div>
                    {`Percentage Score : `}<span className="answerSheet_spantxt">96%</span>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  {`Question attempts : `}
                  <span className="answerSheet_spantxt">50 (out of 50 )</span>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="answerSheet_questionDiv">
            <Box
              fontSize={{ sx: "13px", md: "20px" }}
              className='answerSheet_subQuestionDiv'
            >
              <div>Question</div>
              <div className="answerSheet_scoreDiv">
                <Box marginRight={{ xs: "15px", md: "40px" }}>Score</Box>
                <div>Your Answer</div>
              </div>
            </Box>
            {this.state.answerData.map((item: any, index: number) => {
              return <div
                className="answerSheet_answerDiv">
                <div className="answerSheet_subAnswerDiv"
                  onClick={(e: any) => { this.handleChange(index) }}
                >
                  <div className="answerSheet_questionNoDiv">
                    {`${index + 1}`.length !== 2 ? `0${index + 1}` : index + 1}. {item.question}
                  </div>
                  <div className="answerSheet_scoreDiv">
                    <Box
                      width="max-content"
                      marginRight={{ xs: "10px", md: "40px" }}
                    >
                      1 of 1
                    </Box>
                    <Box
                      minWidth={{ xs: "90px", md: "130px" }}
                      className='answerSheet_checkIconDv'
                    >
                      <p className="answerSheet_correctAnswerIcon">
                        <CheckIcon className="answerSheet_checkIcon" />
                      </p>
                    </Box>
                  </div>
                </div>
                <div
                  className={this.state.expandArr[index] ? 'answerSheet_myAnsBlockDiv' : 'answerSheet_myAnsNoneDiv'}
                >
                  <div className="answerSheet_myAnsSubDiv">
                    <p
                      className="answerSheet_myAnsSubTxt"
                    >
                      Your Answer
                    </p>
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4} xl={3} className='answerSheet_optionDiv'>
                          <div
                            className="answerSheet_optionDivTxt"
                          >
                            A
                          </div>
                          <p
                            className="answerSheet_optionAnswerTxt"
                          >
                            {item?.option?.[0]}
                          </p>
                          {item.correct === 1 &&
                            <p className="answerSheet_correctAnswerIcon">
                              <CheckIcon className="answerSheet_checkIcon" />
                            </p>
                          }
                        </Grid>
                        <Grid item xs={12} md={4} xl={3} className='answerSheet_optionDiv'>
                          <div
                            className="answerSheet_grayOptionDivTxt"
                          >
                            B
                          </div>
                          <p
                            className="answerSheet_optionAnswerTxt"
                          >
                            {item?.option?.[1]}
                          </p>
                          {item.correct === 2 &&
                            <p className="answerSheet_correctAnswerIcon">
                              <CheckIcon className="answerSheet_checkIcon" />
                            </p>
                          }
                        </Grid>
                        <Grid container item spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={3}
                            className='answerSheet_optionDiv'
                          >
                            <div
                              className="answerSheet_yellowOptionDivTxt"
                            >
                              C
                            </div>
                            <p
                              className="answerSheet_optionAnswerTxt"
                            >
                              {item?.option?.[2]}
                            </p>
                            {item.correct === 3 &&
                              <p className="answerSheet_correctAnswerIcon">
                                <CheckIcon className="answerSheet_checkIcon" />
                              </p>
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={3}
                            className='answerSheet_optionDiv'
                          >
                            <div
                              className="answerSheet_blueOptionDivTxt"
                            >
                              D
                            </div>
                            <p
                              className="answerSheet_optionAnswerTxt"
                            >
                              {item?.option?.[3]}
                            </p>
                            {item.correct === 4 &&
                              <p className="answerSheet_correctAnswerIcon">
                                <CheckIcon className="answerSheet_checkIcon" />
                              </p>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                  <div className="answerSheet_answerDetailsDiv">
                    <p className="answerSheet_answerDetailsMainTxt">Answer Details</p>
                    <p>
                      {item.answerDetail}
                    </p>
                  </div>
                  <div className="answerSheet_answerDetailsDiv">
                    <p className="answerSheet_answerDetailsTxt">Attachment</p>
                    <Grid container spacing={3} className='answerSheet_answerDetailcontainer'>
                      <Grid item xs={12} sm={4} md={2}>
                        <img
                          src={exam}
                          className='answerSheet_answerDetailImg'
                        />
                        <p>Test paper solution.jpg</p>
                        <p>890.86kb</p>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default AnswerSheet as React.ComponentType<any>;
// Customizable Area End
