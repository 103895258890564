// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
import * as SparkMD5 from "spark-md5";
import { toast } from "react-toastify";
import axios from "axios";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstname: string;
  lastname: string;
  email: string;
  phonenumber: string;
  gender: string;
  dateofbirth: string;
  password: string;
  confirmPassword: string;
  avtar: any;
  avtarname: string;
  avtarclick: boolean;
  textdatetype: string;
  fnameerror: boolean;
  fnameerrorText: string;
  lnameerror: boolean;
  lnameerrorText: string;
  emailerror: boolean;
  emailerrorText: string;
  phoneerrorText: string;
  phoneerror: boolean;
  pswderror: boolean;
  pswderrorText: string;
  cpswderror: boolean;
  cpswderrorText: string;
  loadingSpinner: boolean;
  gendererror: boolean;
  dateerror: boolean;
  dateerrorText: string;
  imagemapcallId: any;
  clickavtar: any;
  uploadavtar: any;
  openToolTip: boolean;
  sihnIdData: any;
  fileUploadDetail: any;
  dialCode: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ParentAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postLoginApiId: any;
  postPresignID: any;
  emailReg: RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      firstname: "",
      dateerrorText: "",
      imagemapcallId: null,
      lastname: "",
      email: "",
      phonenumber: "",
      clickavtar: [],
      uploadavtar: [],
      gender: "",
      dateofbirth: "",
      avtar: configJSON.defaultavtar,
      password: "",
      confirmPassword: "",
      textdatetype: "",
      openToolTip: false,
      avtarname: "",
      avtarclick: false,
      fnameerror: false,
      fnameerrorText: "",
      gendererror: false,
      dateerror: false,
      lnameerror: false,
      lnameerrorText: "",
      emailerror: false,
      emailerrorText: "",
      phoneerror: false,
      phoneerrorText: "",
      pswderror: false,
      pswderrorText: "",
      cpswderror: false,
      cpswderrorText: "",
      loadingSpinner: false,
      sihnIdData: [],
      fileUploadDetail: [],
      dialCode: "af"

    };
    this.emailReg = new RegExp("");
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      this.onParentSubmit(event)
    }
  }

  async componentDidMount() {
    window.addEventListener('keydown', this.handleEnter)
    const file = this.dataURLtoFile(configJSON.defaultavtar, 'default_avtar')
    this.avtarUpload(file)
    if (localStorage.getItem("user_data") !== null) {
      const user_data = localStorage.getItem("user_data");
      const data = JSON.parse(user_data || "{}");
      this.setState({
        firstname: data.first_name,
        lastname: data.last_name,
        email: data.email,
        phonenumber: data.full_phone_number,
        gender: data.gender === null ? "" : data.gender,
        dateofbirth: data.date_of_birth == "" || data.date_of_birth === null ? "" : moment(data.date_of_birth).format("DD-MM-YYYY"),
        avtar: data.avtar
      });
    }
  }
  async componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEnter)
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.postLoginApiId) {
        if (responseJson != null) {
          localStorage.setItem("account_type", "TeacherAccount");
          if (!responseJson.errors) {
            localStorage.setItem(
              "user_data",
              JSON.stringify(responseJson.data.attributes)
            );
            localStorage.setItem("token", responseJson.meta.token);
            sessionStorage.setItem("otp-token", responseJson.meta.token);
            sessionStorage.setItem(
              "sms-token",
              responseJson.meta.sms_token
            );
            this.props.navigation.navigate("AccountPhoneVerify");
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false });
      }
      else if (apiRequestCallId === this.postPresignID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ sihnIdData: responseJson.data[0] })
            this.saveImageUsingURL()
          }
          else {
            this.parseApiErrorResponse(responseJson);
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false });
      }
    }
  }
  computeChecksumMd5 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152;
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0;
      fileReader.onerror = function (): void {
        reject('MD5 computation failed - error reading the file');
      };

      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result);
        cursor += chunkSize;

        if (cursor < file.size) {
          processChunk(cursor);
        } else {
          resolve(btoa(spark.end(true)));
        }
      };

      processChunk(0);
    });
  }

  avtarUpload = (file: any) => {
    let fileUpload: any = {};
    let checksum = "";
    fileUpload = {
      fileName: file.name,
      name: file.name.split('.')[0],
      fileSize: file.size,
      file: file,
      content_type: file.type
    };
    this.computeChecksumMd5(file).then(
      (md5: any) => {
        checksum = md5
        fileUpload.checksum = checksum;
        this.setState({ fileUploadDetail: fileUpload })
        this.getPreSignedId()
      }
    );
  }

  handleUpload = (event: any) => {
    let file: any = []
    let fileUpload: any = {};
    let checksum = "";
    file = event.target.files[0];

    fileUpload = {
      fileName: file.name,
      name: file.name.split('.')[0],
      fileSize: file.size,
      file: file,
      content_type: file.type
    };

    this.computeChecksumMd5(file).then(
      (md5: any) => {
        checksum = md5
        fileUpload.checksum = checksum;
        this.setState({ fileUploadDetail: fileUpload })
        this.getPreSignedId()
      }
    );
  }

  getPreSignedId = () => {
    const { fileUploadDetail } = this.state;
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postPresignID = requestMessage.messageId;

    const httpBody = {
      "filename": fileUploadDetail.fileName,
      "byte_size": fileUploadDetail.fileSize,
      "checksum": fileUploadDetail.checksum,
      "content_type": fileUploadDetail.content_type
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedIdEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.SignIDUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveImageUsingURL = async () => {
    const { sihnIdData } = this.state
    const header = {
      "Content-Type": sihnIdData?.headers?.["Content-Type"],
      "Content-MD5": sihnIdData?.headers?.["Content-MD5"],
    };

    const httpBody = this.state.fileUploadDetail.file

    try {
      const s3Response = await axios({
        method: "put",
        url: sihnIdData.url,
        data: httpBody,
        headers: header
      });
    } catch (e) {
      toast.error('Something went wrong.');
    }
  }
  dataURLtoFile = (base64: any, fileName: string) => {
    var arr = base64.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  }

  onParentSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const pswdspaceReg = /^\S$|^\S[\s\S]*\S$/;
    const { firstname, lastname, phonenumber, email, dateofbirth, gender, password, avtar, confirmPassword } = this.state;
    this.emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (firstname.length === 0 || lastname.length === 0 || gender?.length === 0 || email.length === 0 || password.length === 0 || confirmPassword.length === 0) {
      if (
        this.state.firstname === null ||
        this.state.firstname.length === 0
      ) {
        this.setState({ fnameerror: true, fnameerrorText: 'Firstname is required.' });

      }
      if (this.state.lastname === null || this.state.lastname.length === 0) {
        this.setState({
          lnameerror: true,
          lnameerrorText: "Lastname is required.",
        });
      }
      if (this.state.email === null || this.state.email.length === 0) {
        this.setState({
          emailerror: true,
          emailerrorText: "Email is required.",
        });
      }
      if (
        this.state.phonenumber === null ||
        this.state.phonenumber.length === 0
      ) {
        this.setState({
          phoneerror: true,
          phoneerrorText: "Phone number is required.",
        });
      }
      if (this.state.gender === null || this.state.gender?.length === 0) {
        this.setState({ gendererror: true });
      }

      if (
        this.state.dateofbirth === null ||
        this.state.dateofbirth.length === 0
      ) {
        this.setState({
          dateerror: true,
          dateerrorText: "Please select date of birth.",
        });
      }
      if (!pswdspaceReg.test(this.state.password)) {
        this.setState({
          pswderror: true,
          pswderrorText: "Your password can't start or end with a blank space.",
        });
      }
      if (this.state.password === null || this.state.password.length === 0) {
        this.setState({
          pswderror: true,
          pswderrorText: "Password is required.",
        });
      }
      if (!pswdspaceReg.test(this.state.confirmPassword)) {
        this.setState({
          cpswderror: true,
          cpswderrorText: "Your password can't start or end with a blank space.",
        });
      }
      if (this.state.password != this.state.confirmPassword) {
        this.setState({ cpswderror: true, cpswderrorText: 'Password and Confirm password must match. ' })

      }
      if (this.state.confirmPassword === null || this.state.confirmPassword.length === 0) {
        this.setState({ cpswderror: true, cpswderrorText: 'Confirm Password is required.' });

      }

      return false;
    }

    const data = {
      data: {
        type: "parent_account",
        attributes: {
          first_name: firstname,
          last_name: lastname,
          email: email,
          full_phone_number: phonenumber,
          date_of_birth: dateofbirth,
          gender: gender,
          avatar: this.state.sihnIdData.signed_id,
          password: password.trim(),
        },
      },
    };

    this.setState({ loadingSpinner: true });
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postLoginApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsparentAccountEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
