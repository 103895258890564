// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { audio, conectivity, teacher, vedio } from "../assets";
// Customizable Area End
// Customizable Area Start


export const configJSON = require("./config.js");
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  openModal: boolean;
  deleteId: any;
  updateId: any;
  onCloseModal: any;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  feedbackData: any;
  pagination_page: number;
  pagination_page_two: number;
  pageCount: number;
  pageCount_two: number;
  loading: boolean;
  reviewType: string;
  classList: any;
  questionList: any;
  selectedClass: any,
  openPopup: boolean,
  openBadResponsePopup: boolean,
  deletePopup: boolean,  
  updatePopup: boolean,
  secondFeedbackQuestions: any;
  review_answers: any;
  selectedTab: string;
  selectedIndex: number;
  removeModal: boolean;
  openModal: boolean;
  deleteId: any;
  updateId: any;
  modalType: string;
  updateQuestion: string;
  folderNameErrorMes: any;
  deleteResource: boolean;
  updateQuestionInput: string,
  questionInput: string;
  selectedQuestionId: any;
  selectedUpdateQuestionId: any;
  // Customizable Area End
 }
interface SS {
   // Customizable Area Start
    // Customizable Area ENd
}

export default class AdminFeedbackController extends BlockComponent<
  Props,
  S,
  SS
> {
   // Customizable Area Start
  getPeriodListId: string = "";
  getClassesListId: string = "";
  getQuestionsListId: string = "";
  postQuestionsListId: string = "";
  deleteApiCallId: string = "";
  updateQuestionId: string = "";
   // Customizable Area End
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      feedbackData: [],
      pagination_page: 0,
      pagination_page_two: 0,
      pageCount: 1,
      pageCount_two: 1,
      loading: false,
      reviewType: "",
      classList: [],
      questionList: [],
      updateQuestion: "",
      selectedClass: "",
      openPopup: false,
      deletePopup: false,
      updatePopup: false,
      updateId: "",
      openBadResponsePopup:false,
      review_answers: [],
      selectedTab: 'report',
      questionInput: "",
      updateQuestionInput: "",
      selectedUpdateQuestionId:"",
      selectedIndex: 1,
      removeModal: false,
      deleteId: "",
      openModal: false,
      modalType: "",
      folderNameErrorMes: "",
      selectedQuestionId: "",
      deleteResource: false,
      secondFeedbackQuestions: [
        {
          img: audio,
          question: "Was there an issue with your Audio?",
          chooseActive: null,
        },
        {
          img: vedio,
          question: "Was there an issue with your Video?",
          chooseActive: null,
        },
        {
          img: teacher,
          question:
            "Was teacher unable to explain the topic in a detailed manner?",
          chooseActive: null,
        },
        {
          img: conectivity,
          question: "Was there an issue with connectivity at teacher's end?",
          chooseActive: null,
        },
      ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
 // Customizable Area Start
  handlePopup = () => {
    this.setState({openPopup: !this.state.openPopup, deletePopup: !this.state.deletePopup, updatePopup: !this.state.updatePopup})
  }

  handleModalClick = (type: any) => {
    this.setState({
      openModal: !this.state.openModal,
      modalType: type,
      folderNameErrorMes: '',
    })
  }

  handleSelectTab = (tab: string, key: number) => {
    this.setState({
      selectedTab: tab,
      selectedIndex: key,
    })
  }
 

  handlePopupOpen = (reviewDetails?:any) => {
    let arr: any = [];
    Object.entries(reviewDetails).forEach(([key, value]) => {
      arr.push({
        question: key,
        answer: value
      });
    });
    this.setState({ review_answers: arr }, () => {
      this.setState({openBadResponsePopup: true})
    })
  }

  handleCloseBadResponsePopup = () => {
    this.setState({ openBadResponsePopup: false })
  }

  handleUpdateQuestionInput = (value:any) => {
    this.setState({ updateQuestion: value });
  }

  handleUpdateQuestion = (value:any) => {
    this.setState({ questionInput: value });
  }

  handleCloseUpdateQuestionPopup = () => {
    this.setState({ updatePopup: false })
  }

  handleCloseCreateQuestionPopup = () => {
    this.setState({ openPopup: false })
  }

  handleCloseDeleteQuestionPopup = () => {
    this.setState({ deletePopup: false })
  }

  handleFeedbackPagination =(e:any) => {
    if (e.selected !== this.state.pagination_page) {
      this.setState({ pagination_page: e.selected }, () => {
        this.get_school_period_list();
      });
    }
  }

  handleFeedbackQuestionPagination =(e:any) => {
    if (e.selected !== this.state.pagination_page_two) {
      this.setState({ pagination_page_two: e.selected }, () => {
        this.get_question_list();
      });
    }
  }

  async componentDidMount() {
    super.componentDidMount()
    // this.get_school_period_list()
    this.get_classes_list();
    // this.get_question_list();
    
    // this.delete_question();
    // console.log("+");
    
  }

  get_school_period_list = () => {
    this.setState({ loading: true });
    console.log("===");
    
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: school_Data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPeriodListId = requestMessage.messageId;
    let apiEndPoint =
      configJSON.getReviewEndpoint + `?school_class_id=${this.state.selectedClass}&per=5&page=${this.state.pagination_page + 1}`;
    if (this.state.reviewType !== "") {
      apiEndPoint = apiEndPoint + `&status=${this.state.reviewType}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_question_list = () => {
    this.setState({ loading: true });
    
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: school_Data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionsListId = requestMessage.messageId;
    let apiEndPoint =
      configJSON.getQuestion + `?&per=5&page=${this.state.pagination_page_two+ 1}`;
    // if (this.state.reviewType !== "") {
    //   apiEndPoint = apiEndPoint + `&status=${this.state.reviewType}`
    // }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  post_question_list = () => {
    this.setState({ loading: true });
    const data = {
      "attributes": {
        "question": this.state.questionInput,
      }
      
    };
    const httpBody = {
      data: data,
    };

    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: school_Data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postQuestionsListId = requestMessage.messageId;
    let apiEndPoint =
      configJSON.getQuestion;
    // if (this.state.reviewType !== "") {
    //   apiEndPoint = apiEndPoint + `&status=${this.state.reviewType}`
    // }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.enrollApiPostMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  update_question_list = () => {
    this.setState({ loading: true });
    const data = {
      "attributes": {
        "question": this.state.updateQuestion,
      }
      
    };
    const httpBody = {
      data: data,
    };

    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const updateId = parseInt(this.state.selectedUpdateQuestionId);

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: school_Data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateQuestionId  = requestMessage.messageId;
    let apiEndPoint =
      configJSON.getQuestion + `/${updateId}`;
    
    // if (this.state.reviewType !== "") {
    //   apiEndPoint = apiEndPoint + `&status=${this.state.reviewType}`
    // }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  delete_question = () => {

    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: school_Data.school_id,

    };
    const deleteId = parseInt(this.state.selectedQuestionId);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuestion + `/${deleteId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteSchoolMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_classes_list = () => {
    this.setState({ loading: true });
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClassesListId = requestMessage.messageId;
    let apiEndPoint =
      configJSON.classesListEndPoint + `?page=1&per=1000&school_id=${school_Data.school_id}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType

    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getPeriodListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ feedbackData: responseJson.data, pageCount: responseJson.meta.pagination.total_pages})
            // console.log('feedbackData', this.state.feedbackData)
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loading: false });
      }  else if (apiRequestCallId === this.getClassesListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ classList: responseJson?.data, selectedClass: responseJson.data[0].id, loading: false }, () => {
              this.get_school_period_list();
            })
            // this.handleSetClass(responseJson?.data[0])
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loading: false });
      }else if (apiRequestCallId === this.getQuestionsListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ questionList: [...responseJson.data], pageCount_two: responseJson.meta.pagination.total_pages });
            // this.handleSetClass(responseJson?.data[0])
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.deleteApiCallId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const updatedQuestionList = this.state.questionList.filter(
              (item: any) =>
                parseInt(item.id) !== parseInt(this.state.selectedQuestionId)
            );
            this.setState({
              questionList: this.state.questionList.filter(
                (item: any) =>
                  parseInt(item.id) !== parseInt(this.state.selectedQuestionId)
              ),
              selectedQuestionId: "",
            });
            // this.handleSetClass(responseJson?.data[0])
            //this.get_question_list();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.postQuestionsListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.get_question_list();
            this.setState({
              // questionList: [...this.state.questionList, responseJson],
              loading: false,
            });
            // window.location.reload();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.updateQuestionId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
           
            this.setState({
              questionList: this.state.questionList.map(
                (item: any) => {
                  if (item.id === this.state.selectedUpdateQuestionId) {
                     item.question = responseJson?.question
                  }
                  return item;
              }
              )
            })
            // this.update_question_list();
            // window.location.reload();
            
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loading: false });
      }
    }
     // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
