// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const HomeIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M12.333 21.333h-10A2.336 2.336 0 0 0 0 23.667v6A2.336 2.336 0 0 0 2.333 32h10a2.336 2.336 0 0 0 2.334-2.333v-6a2.336 2.336 0 0 0-2.334-2.334zM2.333 30A.333.333 0 0 1 2 29.667v-6c0-.184.15-.334.333-.334h10c.184 0 .334.15.334.334v6c0 .184-.15.333-.334.333h-10zm10-30h-10A2.336 2.336 0 0 0 0 2.333v14a2.336 2.336 0 0 0 2.333 2.334h10a2.336 2.336 0 0 0 2.334-2.334v-14A2.336 2.336 0 0 0 12.333 0zm-10 16.667A.333.333 0 0 1 2 16.333v-14C2 2.15 2.15 2 2.333 2h10c.184 0 .334.15.334.333v14c0 .184-.15.334-.334.334h-10zM29.667 0h-10a2.336 2.336 0 0 0-2.334 2.333v6a2.336 2.336 0 0 0 2.334 2.334h10A2.336 2.336 0 0 0 32 8.333v-6A2.336 2.336 0 0 0 29.667 0zm-10 8.667a.333.333 0 0 1-.334-.334v-6c0-.184.15-.333.334-.333h10c.184 0 .333.15.333.333v6c0 .184-.15.334-.333.334h-10zm10 4.666h-10a2.336 2.336 0 0 0-2.334 2.334v14A2.336 2.336 0 0 0 19.667 32h10A2.336 2.336 0 0 0 32 29.667v-1.584h-2v1.584c0 .184-.15.333-.333.333h-10a.333.333 0 0 1-.334-.333v-14c0-.184.15-.334.334-.334h10c.184 0 .333.15.333.334v9.749h2v-9.75a2.336 2.336 0 0 0-2.333-2.333z" fill={`${props.fillColor}`} fill-rule="nonzero" />
        </SvgIcon>
    );
}

export default HomeIcon;
// Customizable Area End