Object.defineProperty(exports, "__esModule", {
  value: true
});
const urlConfig = require("../../../../framework/src/config");
// Customizable Area Start
exports.createresourceEndPoint="bx_block_fileattachment/resource_folders"
exports.studentResourceFolder = "bx_block_fileattachment/resource_folders/user_resource_folders";
exports.createResource = "bx_block_fileattachment/resource_uploads"
exports.moveResource = "bx_block_fileattachment/resource_uploads/move_file"
exports.studentGetResource = "bx_block_fileattachment/resource_uploads/user_resource_uploads";
exports.getCategoryList= "bx_block_categories/categories"
exports.SignIDUrl = urlConfig.baseURL;
exports.getPreSignedIdEndPoint = "bx_block_bulk_uploading/uploads/pre_signed_url";
exports.deleteAll = "/bx_block_fileattachment/resource_uploads/delete_all_resource_file_folders"
exports.deleteSelected = "/bx_block_fileattachment/resource_folders/delete_folders_and_files"

exports.getClasses = "/bx_block_scheduling/school_classes?page=1&per=100"; // "/bx_block_scheduling/classes/";
exports.getSubjectsByClassId= "/bx_block_scheduling/subjects/class_section_subjects"
exports.getTeachersByClassId= "/bx_block_scheduling/classes/get_class_teachers"
exports.getStudentsByClassIdAndSubjectId = "/bx_block_scheduling/classes/get_students_by_class_and_subject"
exports.submitCourseWorkEndPoint = "/bx_block_fileattachment/resource_uploads";
exports.shareWithUserFolder = "/bx_block_fileattachment/resource_folders/shared_with_users";
exports.shareWithUserFile = "/bx_block_fileattachment/resource_uploads/shared_with_users";
exports.openedByFolder = "/bx_block_fileattachment/resource_folders";
exports.openedByFile = "/bx_block_fileattachment/resource_uploads";
exports.exampleApiContentType = "application/json";
exports.resourceContentType = "application/json";
exports.resourceApiMethodType = "GET";
exports.resourceApiPutMethodType = "PUT";
exports.resourceApiPostMethodType = "POST";
exports.resourceApiPatchMethodType = "PATCH";
exports.resourceApiDeleteMethodType = "DELETE";
exports.resourceHost = "<calculated when request is sent>";
exports.attandanceURL= urlConfig.attendanceURL;
exports.resourceUserAgent = "PostmanRuntime/7.26.5";
exports.resourceAccept = "*/*";
exports.resourceAcceptEncoding = "gzip, deflate, br";
exports.resourceConnection = "keep-alive";
exports.resourcetoken = "";
exports.labelTitleText = "resource";
exports.labelBodyText = "resource Body";
exports.instituteURL = urlConfig.instituteURL;
exports.resourceURL = urlConfig.attendanceURL;
exports.studentList=urlConfig.instituteURL+`/bx_block_scheduling/school_classes/get_class_students`;
exports.fileUploadS3="bx_block_bulk_uploading/uploads/s3_signed_url"
// Customizable Area End