// Customizable Area Start
import React from "react";
import "react-phone-input-2/lib/style.css";
import InputBase from "@material-ui/core/InputBase";
import IconButton from '@material-ui/core/IconButton';
import AlertModal from "../../alert/src/AlertModal.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import InputColor from 'react-input-color';
import Button from "@material-ui/core/Button";
import "react-datetime/css/react-datetime.css";
import "./CustomThemes.web.css";
import { editColor } from "./assets";
import CustomThemesController, {
    Props,
} from "./CustomThemesController.web";
import OnBoardingSideBar from "./OnBoardingSideBar.web"
import {
    NativeSelect, withStyles
} from "@material-ui/core";
import Spinner from "../../shared/SideBar/src/Spinner";
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const BootstrapInput1 = withStyles(() => ({
    input: {
        width: "100%",
        borderRadius: "12px",
        textAlign: "start",
        color: "#3b3b3b",
        fontSize: "1.125rem",
        fontFamily: "Open sans",

        border: "solid 1px #979797"

    },
    error: {
        borderColor: "red !important",
    },
}))(InputBase);
// Customizable Area End

export class CustomThemes extends CustomThemesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        return (
            <>
                <Grid container>
                    {this.state.loadingSpinner && <Spinner spinnerModal={this.state.loadingSpinner} />}

                    <Grid item xs={12} sm={3} md={3} lg={3}>
                        <OnBoardingSideBar activePage={6} />
                    </Grid>
                    <Grid item sm={9} md={9} lg={9} xs={12}
                        className="grade_Level_responsiveClass CustomThemesParentBox"
                    >
                        <Grid container >
                            <Grid item xs={12}>
                                <div className={`textlabel_welcomeText ${this.props.classes.theme_font}`}>Themes & Fonts</div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={`textlabel_Header ${this.props.classes.theme_font}`}>Black Theme</div>
                            </Grid>
                            <Grid item sm={7} md={7} lg={7} xs={12} className="mt_6">
                                <div className={`textlabel_school_des ${this.props.classes.theme_font}`}>Set a default colour theme for all users at your school. Users can individually set their own theme choice from their profile settings.

                                </div>
                            </Grid>
                            {this.state.customId !== null && <><Grid item xs={12} className="customIdBox">
                                <Grid container spacing={3} >
                                    <Grid item xs={12}>
                                        <div className={`textlabel_font ${this.props.classes.theme_font}`}>Theme Color</div>
                                    </Grid>
                                    <Grid item sm={6} md={4} lg={4} xs={12}>
                                        <Grid container spacing={0} className="ItemCenter" >
                                            <Grid item xs={8}>
                                                <div className={`textlabel_Title ${this.props.classes.theme_font}`}>Primary  Color</div>
                                            </Grid>
                                            <Grid className="inputColor ItemBox" item xs={4}>
                                                <span className="SpanStyle" style={{ backgroundColor: this.state.primaryColor }}> </span>

                                                <IconButton className="IconBtn">
                                                    <img src={editColor} className="ImgEdit" />
                                                    <InputColor
                                                        initialValue={this.state.primaryColor}
                                                        onChange={(color: any) => { this.setState({ primaryColor: color.hex }) }}
                                                        placement="right"


                                                    />
                                                </IconButton>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6} md={4} lg={4} xs={12}>
                                        <Grid container spacing={0} className="ItemCenter">
                                            <Grid item xs={8}>
                                                <div className={`textlabel_Title ${this.props.classes.theme_font}`}>Button Color</div>
                                            </Grid>
                                            <Grid className="inputColor ItemBox" item xs={4}>
                                                <span className="SpanStyle" style={{ backgroundColor: this.state.buttonColor }}> </span>

                                                <IconButton className="IconBtn">
                                                    <img src={editColor} className="ImgEdit" />
                                                    <InputColor
                                                        initialValue={this.state.buttonColor}
                                                        onChange={(color: any) => this.setState({ buttonColor: color.hex })}
                                                        placement="right"


                                                    />
                                                </IconButton>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6} md={4} lg={4} xs={12}>
                                        <Grid container spacing={0} className="ItemCenter">
                                            <Grid item xs={8}>
                                                <div className={`textlabel_Title ${this.props.classes.theme_font}`}>Icons Color</div>
                                            </Grid>
                                            <Grid className="inputColor ItemBox" item xs={4}>
                                                <span className="SpanStyle" style={{ backgroundColor: this.state.iconsColor }}> </span>

                                                <IconButton className="IconBtn">
                                                    <img src={editColor} className="ImgEdit" />
                                                    <InputColor
                                                        initialValue={this.state.iconsColor}
                                                        onChange={(color: any) => this.setState({ iconsColor: color.hex })}
                                                        placement="right"


                                                    />
                                                </IconButton></Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6} md={4} lg={4} xs={12}>
                                        <Grid container spacing={0} className="ItemCenter" >
                                            <Grid item xs={8}>
                                                <div className={`textlabel_Title ${this.props.classes.theme_font}`}>Title Font Color</div>
                                            </Grid>
                                            <Grid className="inputColor ItemBox" item xs={4}>
                                                <span className="SpanStyle" style={{ backgroundColor: this.state.titleFontColor }}> </span>

                                                <IconButton className="IconBtn">
                                                    <img src={editColor} className="ImgEdit" />
                                                    <InputColor
                                                        initialValue={this.state.titleFontColor}
                                                        onChange={(color: any) => this.setState({ titleFontColor: color.hex })}
                                                        placement="right"


                                                    />
                                                </IconButton>  </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6} md={4} lg={4} xs={12}>
                                        <Grid container spacing={0} className="ItemCenter">
                                            <Grid item xs={8}>
                                                <div className={`textlabel_Title ${this.props.classes.theme_font}`}>Subtitle Font Color</div>
                                            </Grid>
                                            <Grid className="inputColor ItemBox" item xs={4}>

                                                <span className="SpanStyle" style={{backgroundColor: this.state.subTitleFontColor }}> </span>

                                                <IconButton className="IconBtn">
                                                    <img src={editColor} className="ImgEdit" />
                                                    <InputColor
                                                        initialValue={this.state.subTitleFontColor}
                                                        onChange={(color: any) => this.setState({ subTitleFontColor: color.hex })}
                                                        placement="right"


                                                    />
                                                </IconButton></Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6} md={4} lg={4} xs={12}>
                                        <Grid container spacing={0} className="ItemCenter">
                                            <Grid item xs={8}>
                                                <div className={`textlabel_Title ${this.props.classes.theme_font}`}>Body Heading</div>
                                            </Grid>
                                            <Grid className="inputColor ItemBox" item xs={4}>
                                                <span className="SpanStyle" style={{ backgroundColor: this.state.bodyHeadingColor }}> </span>

                                                <IconButton className="IconBtn">
                                                    <img src={editColor} className="ImgEdit" />
                                                    <InputColor
                                                        initialValue={this.state.bodyHeadingColor}
                                                        onChange={(color: any) => this.setState({ bodyHeadingColor: color.hex })}
                                                        placement="right"


                                                    />
                                                </IconButton> </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6} md={4} lg={4} xs={12}>
                                        <Grid container spacing={0} className="ItemCenter">
                                            <Grid item xs={8}>
                                                <div className={`textlabel_Title ${this.props.classes.theme_font}`}>Body Subheading</div>
                                            </Grid>
                                            <Grid className="inputColor ItemBox" item xs={4}>
                                                <span className="SpanStyle" style={{ backgroundColor: this.state.bodySubheadingColor }}> </span>

                                                <IconButton className="IconBtn">
                                                    <img src={editColor} className="ImgEdit" />
                                                    <InputColor
                                                        initialValue={this.state.bodySubheadingColor}
                                                        onChange={(color: any) => this.setState({ bodySubheadingColor: color.hex })}
                                                        placement="right"


                                                    />
                                                </IconButton></Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6} md={4} lg={4} xs={12}>
                                        <Grid container spacing={0} className="ItemCenter" >
                                            <Grid item xs={8}>
                                                <div className={`textlabel_Title ${this.props.classes.theme_font}`}>Body Text</div>
                                            </Grid>
                                            <Grid className="inputColor ItemBox" item xs={4}>
                                                <span className="SpanStyle" style={{ backgroundColor: this.state.bodyTextColor }}> </span>

                                                <IconButton className="IconBtn">
                                                    <img src={editColor} className="ImgEdit" />
                                                    <InputColor
                                                        initialValue={this.state.bodyTextColor}
                                                        onChange={(color: any) => this.setState({ bodyTextColor: color.hex })}
                                                        placement="right"


                                                    />
                                                </IconButton></Grid>

                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>
                                <Grid item xs={11} className="Box">
                                    <Grid container spacing={4} >
                                        <Grid item xs={12}>
                                            <div className={`textlabel_font  ${this.props.classes.theme_font}`}>Fonts</div>
                                        </Grid>
                                        <Grid item sm={6} md={6} lg={6} xs={12} >
                                            <Grid container spacing={0} className="ItemCenter" >
                                                <Grid item xs={5}>
                                                    <div className={`textlabel_Title ${this.props.classes.theme_font}`}>Title Font</div>
                                                </Grid>
                                                <Grid item xs={7} >
                                                    <NativeSelect
                                                        id="select"
                                                        placeholder="Title Font"
                                                        className={`forrmInput_textfield InputPropsStyles ${this.props.classes.theme_font}`}
                                                        variant="outlined"
                                                        IconComponent={ExpandMoreIcon}
                                                        inputProps={{
                                                            style: {
                                                                fontFamily: this.state.titleFont,  
                                                            }
                                                        }}
                                                        value={this.state.titleFont}
                                                        onChange={(event: any) => this.setState({ titleFont: event.target.value })}

                                                        input={<BootstrapInput1 />}
                                                    >{
                                                            this.state.fontFamilyList.map((_data: any, index: any) => {
                                                                return (
                                                                    <option value={_data.attributes.name}>{_data.attributes.name}</option>
                                                                )
                                                            })
                                                        }


                                                    </NativeSelect>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6} md={6} lg={6} xs={12}>
                                            <Grid container spacing={0} className="ItemCenter" >
                                                <Grid item xs={5}>
                                                    <div className={`textlabel_Title ${this.props.classes.theme_font}`}>Subtitle Font</div>
                                                </Grid>
                                                <Grid item xs={7} >
                                                    <NativeSelect
                                                        id="select"
                                                        placeholder="Subtitle Font"
                                                        className={`forrmInput_textfield InputPropsStyles ${this.props.classes.theme_font}`}
                                                        variant="outlined"
                                                        IconComponent={ExpandMoreIcon}
                                                        inputProps={{
                                                            style: {
                                                                fontFamily: this.state.subtitleFont
                                                            }
                                                        }}
                                                        value={this.state.subtitleFont}
                                                        onChange={(event: any) => this.setState({ subtitleFont: event.target.value })}

                                                        input={<BootstrapInput1 />}
                                                    >
                                                        {
                                                            this.state.fontFamilyList.map((_data: any, index: any) => {
                                                                return (
                                                                    <option value={_data.attributes.name}>{_data.attributes.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </NativeSelect>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6} md={6} lg={6} xs={12}>
                                            <Grid container spacing={0} className="ItemCenter" >
                                                <Grid item xs={5}>
                                                    <div className={`textlabel_Title ${this.props.classes.theme_font}`}>Body Subheading</div>
                                                </Grid>
                                                <Grid item xs={7} >
                                                    <NativeSelect
                                                        id="select"
                                                        placeholder="Body Subheading"
                                                        className={`forrmInput_textfield InputPropsStyles ${this.props.classes.theme_font}`}
                                                        variant="outlined"
                                                        IconComponent={ExpandMoreIcon}
                                                        inputProps={{
                                                            style: {
                                                                fontFamily: this.state.bodySubheadingFont
                                                            }
                                                        }}
                                                        value={this.state.bodySubheadingFont}
                                                        onChange={(event: any) => this.setState({ bodySubheadingFont: event.target.value })}

                                                        input={<BootstrapInput1 />}
                                                    >
                                                        {
                                                            this.state.fontFamilyList.map((_data: any, index: any) => {
                                                                return (
                                                                    <option value={_data.attributes.name}>{_data.attributes.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </NativeSelect>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6} md={6} lg={6} xs={12}>
                                            <Grid container spacing={0} className="ItemCenter">
                                                <Grid item xs={5}>
                                                    <div className={`textlabel_Title ${this.props.classes.theme_font}`}>Body Text</div>
                                                </Grid>
                                                <Grid item xs={7} >
                                                    <NativeSelect
                                                        id="select"
                                                        placeholder="Body Text"
                                                        className={`forrmInput_textfield InputPropsStyles ${this.props.classes.theme_font}`}
                                                        variant="outlined"
                                                        IconComponent={ExpandMoreIcon}
                                                        inputProps={{
                                                            style: {
                                                                fontFamily: this.state.bodyTextFont
                                                            }
                                                        }}
                                                        value={this.state.bodyTextFont}
                                                        onChange={(event: any) => this.setState({ bodyTextFont: event.target.value })}

                                                        input={<BootstrapInput1 />}
                                                    >
                                                        {
                                                            this.state.fontFamilyList.map((_data: any, index: any) => {
                                                                return (
                                                                    <option value={_data.attributes.name}>{_data.attributes.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </NativeSelect>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6} md={6} lg={6} xs={12}>
                                            <Grid container spacing={0} className="ItemCenter" >
                                                <Grid item xs={5}>
                                                    <div className={`textlabel_Title ${this.props.classes.theme_font}`}>Body Heading</div>
                                                </Grid>
                                                <Grid item xs={7} >
                                                    <NativeSelect
                                                        id="select"
                                                        placeholder="Body Heading"
                                                        className={`forrmInput_textfield InputPropsStyles ${this.props.classes.theme_font}`}
                                                        variant="outlined"
                                                        IconComponent={ExpandMoreIcon}
                                                        inputProps={{
                                                            style: {
                                                                fontFamily: this.state.BodyHeadingFont
                                                            }
                                                        }}
                                                        value={this.state.BodyHeadingFont}
                                                        onChange={(event: any) => this.setState({ BodyHeadingFont: event.target.value })}

                                                        input={<BootstrapInput1 />}
                                                    >
                                                        {
                                                            this.state.fontFamilyList.map((_data: any, index: any) => {
                                                                return (
                                                                    <option value={_data.attributes.name}>{_data.attributes.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </NativeSelect>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className="Box">
                                    <Grid container spacing={3} >
                                        <Grid item xs={12}>
                                            <div className={`textlabel_font  ${this.props.classes.theme_font}`}>Background </div>
                                        </Grid>
                                        <Grid item sm={4} md={4} lg={4} xs={6}>
                                            <div className="ButtonsContainer" style={{ backgroundColor: this.state.backgroundColor }}>
                                                <Button
                                                    className="backgroundColorPicker ColorBtn"
                                                >
                                                    Colors
                                                    <InputColor
                                                        initialValue={this.state.backgroundColor}
                                                        onChange={(color: any) => this.setState({ backgroundColor: color.hex })}
                                                        placement="right"


                                                    />
                                                </Button>
                                            </div>
                                        </Grid>
                                        <Grid item sm={4} md={4} lg={4} xs={6}>
                                            <div style={{ backgroundImage: this.state.uploadFile.length !== 0 ? `url(${this.state.uploadFile[0].localUrl})` : `url(${this.state.backgroundImage})` }} className="ContainedBtnFileBox">
                                                <input
                                                    className="DNone"
                                                    id="contained-button-file"

                                                    type="file"
                                                    accept="image/png, image/jpg, image/jpeg, image/gif )"

                                                    onChange={(event: React.ChangeEvent<any>) => { this.handleUpload(event) }}
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <Button className={`BrowseBtnStyle ${this.props.classes.theme_font}`}
                                                        component="span">
                                                        browse
                                                    </Button>
                                                </label>

                                            </div>
                                        </Grid>



                                    </Grid>
                                </Grid></>}
                        </Grid>

                        <Grid item xs={12} className="UpdateBtnBox">

                            <Button
                                className="UpdateBtn"
                                onClick={() => this.onUpdateClick()}
                            >
                                Update

                            </Button>
                        </Grid>
                    </Grid>
                    <AlertModal
                        alertModal={this.state.alertModal}
                        isTeacher={false}
                        onCloseModal={() => { this.setState({ alertModal: false }) }}
                        alertTitle="Alert!"
                        alertMessage={this.state.alertMessage}
                        buttonText="OK"
                    />
                </Grid>

            </>
        )
    }
    // Customizable Area End

}

// Customizable Area Start
export default withStyles(themeCreateStyle)(CustomThemes);
// Customizable Area End