//@ts-nocheck
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import { toast } from "react-toastify";

export const configJSON = require("./config.js");
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export interface Props {
  // Customizable Area Start
  unitModal: boolean;
  closeModalFun: any;
  onDeleteModalFun: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  data: any;
  teacherupcomingClasses: any;
  teacherClasses: any;
  teacherNotes: any;
  totalClasses: string;

  teacherAssegments: string;
  teacherofStudents: string;
  createnotediv: boolean;

  selectedUpcomingSubject: string;
  calendarDate: string;
  liveClasses: any;
  teacherTimetable: any;
  liveClassDate: string;
  searchResult: boolean;
  getTeacherClasses: any;

  closeModal: boolean;
  class_id: any;
  subject_id: any;
  getTeacherSubjects: any;
  unitHours: any;
  uploadFile: any;
  fileName: string;
  unitName: string;
  startdate: string;
  unitErrorText: string;
  unitError: boolean;
  classerror: boolean;
  subjecterror: boolean;
  hourerror: boolean;
  startdateerror: boolean;
  startdateerrorText: string;
  enddateerror: boolean;
  enddateerrorText: string;
  enddate: string;
  img_upload: boolean;
  unitDescription: string;
  saveAsDraft: boolean;
  cattegoriesArray: any;
  showSpinner: boolean;
  openModal: boolean;
  openSettingModal: boolean;
  openEditModal: boolean;
  modalType: any;
  categoeryName: any;
  openModalNewCat: boolean;
  rows: any;
  showColor: boolean;
  gradeName: any;
  createGradeScale: any;
  selectedGradeData: any;
  selectedGradeId: any
  performaceBands: any;
  tempPerformanBands: any;
  fieldDisabled: boolean;
  editSelected: any;
  color_code: any;
  from: any;
  grade: any;
  grade_scale_id: any;
  name: any;
  to: any;
  selectedTaskId: any;
  gradeN: any
  selectedValues: any;
  gradedropdown: any;
  tabRow: any;
  selectedGradeIds: any;
  showDeleteAlert: boolean;
  categoryError: boolean;
  categoryErrorText: any;
  deleteWarning: boolean;
  bandName: string;
  gradeSelected: boolean;
  scaleRowdelete: boolean;
  deleData: any;
  isDestoryData: any;
  deleteIconHidden: boolean;
  scaleSettingDelete: boolean;
  gradeDeleteWarning: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CreateUnitController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  apigetTeacherSubjectsId: string = "";
  apigetSearchSubjectsId: string = ""; zz
  apigetSubjectRemoveId: string = "";
  apigetTeacherClassesId: string = "";
  apigetTeacherCateogoryId: string = "";
  editCategoryAPIId: string = "";
  createGradeScaleAPIId: string = "";
  editGradeScaleAPIId: string = "";
  deleteCategoryAPIId: string = "";
  addCategoryAPIId: string = '';
  selectGradeScaleAPIId: string = '';
  apigetGradeListByGradeId: string = "";
  task_categoeryGrade_ScalesAPIId: string = '';
  gradeScaleDeleteAPIID: string = "";
  tempData: any = []
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.handleModalClick = this.handleModalClick.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      selectedValues: "",
      gradedropdown: "",
      tabRow: "",
      teacherNotes: [],
      teacherTimetable: [],
      data: '',
      liveClassDate: '',
      selectedUpcomingSubject: '',
      totalClasses: '',
      teacherClasses: [],
      teacherupcomingClasses: [],
      liveClasses: [],
      teacherAssegments: '',
      teacherofStudents: '',
      createnotediv: false,
      calendarDate: '',
      searchResult: false,
      getTeacherClasses: [],
      closeModal: false,
      class_id: '',
      subject_id: '',
      getTeacherSubjects: [],
      unitHours: '',
      uploadFile: [],
      fileName: '',
      unitName: '',
      startdate: '',
      enddate: '',
      unitError: false,
      unitErrorText: '',
      classerror: false,
      subjecterror: false,
      hourerror: false,
      startdateerror: false,
      startdateerrorText: '',
      enddateerror: false,
      enddateerrorText: '',
      img_upload: false,
      unitDescription: '',
      saveAsDraft: false,
      cattegoriesArray: [],
      showSpinner: false,
      openModal: false,
      openSettingModal: false,
      openEditModal: false,
      modalType: "",
      categoeryName: "",
      openModalNewCat: false,
      rows: [],
      showColor: false,
      gradeName: "",
      createGradeScale: [],
      selectedGradeData: "",
      selectedGradeId: "",
      performaceBands: [],
      tempPerformanBands: [],
      fieldDisabled: true,
      editSelected: null,
      color_code: "",
      from: 0,
      grade: "",
      grade_scale_id: 0,
      name: "",
      to: 0,
      gradeN: [],
      selectedGradeIds: [],
      showDeleteAlert: false,
      categoryError: false,
      categoryErrorText: "",
      deleteWarning: false,
      selectedTaskId: "",
      bandName: "",
      gradeSelected: false,
      scaleRowdelete: false,
      deleData: "",
      isDestoryData: "",
      deleteIconHidden: true,
      scaleSettingDelete: false,
      gradeDeleteWarning: "",

    };
    this.get_userdata = this.get_userdata.bind(this);
    this.get_teacher_category = this.get_teacher_category.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.get_teacher_category();
    this.selectGradeScale();
  }
  handleModalClick = (type: any) => {
    this.setState({
      openModal: !this.state.openModal,
      modalType: type,

    })
  }

  get_userdata = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}')
    this.setState({ data: data });
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apigetTeacherCateogoryId) {
        if (responseJson != null) {

          if (!responseJson.errors) {
            this.setState({ cattegoriesArray: responseJson.data },
              this.setdatadropdown(responseJson.data))
          } else {

            this.parseApiErrorResponse(responseJson);
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);

      }
      else if (apiRequestCallId === this.addCategoryAPIId) {
        if (responseJson != null) {

          if (!responseJson.errors) {
            toast.success("Category added successfully")
            this.get_teacher_category()
          } else {
            const key: any = Object.keys(responseJson.errors[0])
            toast.error(`${responseJson.errors[0][key]}`)
            this.parseApiErrorResponse(responseJson);
          }
        }

        this.setState({ showSpinner: false })
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ openModalNewCat: false })
      }
      else if (apiRequestCallId === this.editCategoryAPIId) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            toast.success("Category edited successfully")
            this.get_teacher_category()
          } else {
            const key: any = Object.keys(responseJson.errors[0])
            toast.error(`${responseJson.errors[0][key]}`)
            this.parseApiErrorResponse(responseJson);
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false })
        this.get_teacher_category()
      }

      else if (apiRequestCallId === this.deleteCategoryAPIId) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            toast.success("Category deleted successfully")
            this.get_teacher_category()
            this.modalhandleClose()
            this.props.closeModalFun(this.state.closeModal)
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.get_teacher_category()
        this.setState({ showSpinner: false })
      }

      else if (apiRequestCallId === this.createGradeScaleAPIId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            toast.success("Grade created successfully")
            this.selectGradeScale();
            this.setState({ openModal: false });
            this.setState({ rows: [] })
          } else {
            const key: any = Object.keys(responseJson.errors[0])
            toast.error(`${responseJson.errors}`)
            this.parseApiErrorResponse(responseJson);
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false })
      }
      else if (apiRequestCallId === this.editGradeScaleAPIId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              fieldDisabled: true,
              editSelected: null,
              color_code: "",
              from: 0,
              grade: "",
              grade_scale_id: 0,
              name: "",
              to: 0,
            })

            this.getGradeScaleByGradeId(this.state.selectedGradeId)

            this.setState({ openEditModal: false })
            toast.success("The grade scale details have been saved successfully.")

          } else {
            const key: any = Object.keys(responseJson.errors[0])
            toast.error(`${responseJson.errors[0][key]}`)
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false })
        this.get_teacher_category()
      }

      else if (apiRequestCallId === this.selectGradeScaleAPIId) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            this.setState({ createGradeScale: responseJson })
            this.setState({ selectedGradeId: responseJson[0]?.id })
            this.getGradeScaleByGradeId(responseJson[0]?.id)
            this.get_teacher_category()

          } else {
            const key: any = Object.keys(responseJson.errors[0])
            toast.error(`${responseJson.errors[0][key]}`)
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false })
      }

      else if (apiRequestCallId === this.task_categoeryGrade_ScalesAPIId) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            toast.success("The task details have been saved successfully.")


            this.props.closeModalFun(false)
          } else {
            const key: any = Object.keys(responseJson.errors[0])
            toast.error(`${responseJson.errors}`)
            this.parseApiErrorResponse(responseJson);
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false })
        this.get_teacher_category();

      }
      else if (apiRequestCallId === this.apigetGradeListByGradeId) {


        if (responseJson != null) {

          if (!responseJson.errors) {
            this.setState({ performaceBands: responseJson.data })
            this.setState({ gradeDeleteWarning: responseJson.data.attributes.flag })

            this.tempData = JSON.parse(JSON.stringify(responseJson.data.attributes.settings.data.map((item) => { return { ...item, _destroy: false } })))
            this.setState({ isDestoryData: this.tempData })

          } else {
            toast.error(`${responseJson.errors}`)
            this.parseApiErrorResponse(responseJson);
            this.setState({ showSpinner: false })
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false })
      }

      else if (apiRequestCallId === this.gradeScaleDeleteAPIID) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            this.setState({ scaleSettingDelete: false })
          } else {
            const key: any = Object.keys(responseJson.errors[0])
            toast.error(`${responseJson.errors}`)
            this.parseApiErrorResponse(responseJson);
            this.setState({ scaleSettingDelete: false })
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false })
        this.setState({ openSettingModal: false })
        this.get_teacher_category();
        this.selectGradeScale()
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start
  modalhandleClose = () => {
    this.setState({ closeModal: true })
    this.props.closeModalFun(this.state.closeModal)
  }
  get_teacher_category = () => {

    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherCateogoryId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  addCategory = () => {
    this.add_Category()
  }

  setdatadropdown = (data: any) => {
    let arr: any = []

    data.forEach((data: any) => {
      if (data.attributes.grade.grade_name == null) {
        arr.push("select grade")
      }
      else {
        arr.push(data.attributes.grade.grade_id)
      }

    })
    this.setState({ gradeN: arr })

  }
  deleteCategoryText = () => {
    for (const data of this.state.cattegoriesArray) {
      if (data.selectedCateogry) {
        if (data.attributes.has_tasks === true) {
          this.setState({ deleteWarning: data.attributes.has_tasks })
          break;
        }
      }
    }
  }

  deleteCategory = () => {
    let arr: any = []
    this.state.cattegoriesArray.map((data: any) => {
      if (data.selectedCateogry) {
        arr.push(data.id)
      }

    })
    if (arr != "") {
      this.delete_Category(arr)
    } else { toast.error("Select any task") }
  }

  taskCategoeryGradeScales = (e: any) => {

    const arr: any = []
    this.state.cattegoriesArray.map((data: any) => {
      if (data.selectedCateogry) {
        arr.push(Number(data.id))
      }
    })

    const result = arr.reduce((accumulator: any, currentId: any, index: any) => {
      return accumulator.concat({
        task_category_id: currentId,
        grade_scale_id: (this.state.selectedGradeIds[index])
      });
    }, []);


    if (arr.length === 0) {
      toast.error("Please select check box to save the data")
    }
    else if (this.state.selectedGradeIds.length === 0) {
      toast.error("Please select grade scale for type")
    }
    else {
      this.task_categoeryGrade_Scales(result)
    }
  }

  delete_Category = (id: any) => {
    this.setState({ showSpinner: true })
    const data1 = {
      "task_category_ids": id
    }
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoryAPIId = requestMessage.messageId;
    const httpBody = data1;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.Bulk_destroy
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  add_Category = () => {
    if (this.state.categoeryName != "") {

      this.setState({ showSpinner: true })

      const data = {
        name: this.state.categoeryName,
      }
      const school_data = localStorage.getItem("user_data");
      const Schooldata = JSON.parse(school_data || "{}");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
        "school": Schooldata.school_id,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryAPIId = requestMessage.messageId;

      const httpBody = data;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.instituteURL
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);


    } else {
      this.setState({
        categoryError: true,
        categoryErrorText: 'Please enter category name'
      });

    }
  }

  update_Category = (editdata: any) => {

    const data = {
      name: editdata.attributes.name,
    }
    const school_data = localStorage.getItem("user_data");
    const Schooldata = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": Schooldata.school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editCategoryAPIId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.attandanceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryEndPoint + `/${editdata.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.EditMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createGradeScales = () => {

    let compArr = []
    let validation = false;
    let greaterValue = false;
    let toArr = [];
    let fromArr = [];
    let val = null;
    let gradeName = ""
    let firstIndex = "";
    let isMatch = false;
    let overLapValue = ""
    let valueOverLap = ""
    const data = {
      "grade_scale": {
        "name": this.state.gradeName,
        "settings_attributes": this.state.rows
      }
    }

    if (this.state.rows.length > 0) {
      validation = this.state.rows.every(row => row.name)
    }
    this.state.rows.map((row, i) => {
      if (row.to !== "" && row.from !== "") {
        toArr.push(row.to), fromArr.push(row.from)
      }
      compArr = [...toArr, ...fromArr]
    })

    if (compArr.length !== 0) {

      for (var a = 0; a < compArr.length; a++) {
        for (var b = a + 1; b < compArr.length; b++) {
          if (compArr[a] === compArr[b]) {


            firstIndex = compArr.indexOf(compArr[a]);
            isMatch = true;
            break;
          }
        }
        if (isMatch) { break; }
      }
    }

    for (const row of this.state.rows) {

      if (Number(row.to) < Number(row.from)) {
        greaterValue = true
        val = row.from;
        gradeName = row.name;
        break;
      }
    }
    function checkRangeOverlap(ranges) {
      var n = ranges.length
      for (var i = 0; i < n; i++) {
        for (var u = 0; u < n; u++) {
          if (i == u)
            continue
          var rangeA = ranges[i]
          var rangeB = ranges[u]
          if (rangeA.from < rangeB.to && rangeA.to > rangeB.from) {
            overLapValue = rangeB.from
            return true
          }
        }
      }
      return false
    }
    valueOverLap = checkRangeOverlap(this.state.rows)

    if (this.state.gradeName === "") {
      toast.error("Please add performance band.")
    }

    else if (!validation) {

      toast.error("Please add name.")
    }

    else if (greaterValue) {
      toast.error(`To value must be greater than ${val} for band ${gradeName}.`)
    }
    else if (isMatch) {

      toast.error(`The value ${compArr[a]} is already used in the other range.`)
    }
    else if (valueOverLap) {

      toast.error(`The value ${overLapValue} is already used in the other range.`)
    }
    else {

      this.setState({ showSpinner: true })
      const school_data = localStorage.getItem("user_data");
      const Schooldata = JSON.parse(school_data || "{}");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
        "school": Schooldata.school_id,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createGradeScaleAPIId = requestMessage.messageId;

      const httpBody = data;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.resultUrl
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createGradeEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

    }

  }

  editGradeScales = () => {

    let compArr = []
    let validation = false;
    let greaterValue = false;
    let toArr = [];
    let fromArr = [];
    let val = null;
    let gradeName = ""
    let firstIndex = "";
    let isMatch = false;
    let attributesData: any = []
    let overLapValue = ""
    let valueOverLap = ""
    let reptValue = ""

    this.state.isDestoryData.forEach((item: any) => {
      let tempObj = {
        "id": item.id,
        "color_code": item.attributes.color_code,
        "name": item.attributes.name,
        "grade": item.attributes.grade,
        "from": item.attributes.from,
        "to": item.attributes.to,
        "_destroy": item._destroy

      }
      attributesData.push(tempObj)
    })

    if (this.state.isDestoryData.length > 0) {
      validation = this.state.isDestoryData.every(row => row.attributes.name)
    }
    this.state.isDestoryData?.map((row, i) => {
      if (row.attributes.to !== null && row.attributes.from !== null) {
        toArr.push(row.attributes.to), fromArr.push(row.attributes.from)
      }
      compArr = [...toArr, ...fromArr]
    })

    if (compArr.length != 0) {
      for (var a = 0; a < compArr.length; a++) {
        for (var b = a + 1; b < compArr.length; b++) {
          if (compArr[a] === compArr[b]) {

            firstIndex = compArr.indexOf(compArr[a]);
            reptValue = compArr[a]
            isMatch = true;
            break;
          }
        }
        if (isMatch) { break; }
      }
    }

    for (const row of this.state.isDestoryData) {

      if (Number(row.attributes.to) < Number(row.attributes.from)) {
        greaterValue = true
        val = row.attributes.from;
        gradeName = row.attributes.name;
        break;
      }
    }
    function checkRangeOverlap(ranges) {
      var n = ranges.length
      for (var i = 0; i < n; i++) {
        for (var u = 0; u < n; u++) {
          if (i == u)
            continue
          var rangeA = ranges[i]
          var rangeB = ranges[u]
          if (rangeA.attributes.from < rangeB.attributes.to && rangeA.attributes.to > rangeB.attributes.from) {
            overLapValue = rangeB.attributes.from
            return true
          }
        }
      }
      return false
    }
    valueOverLap = checkRangeOverlap(this.state.isDestoryData)

    if (this.state.rows.length > 0) {
    }
    else if (!validation) {

      toast.error("Please add name.")
    }
    else if (greaterValue) {
      toast.error(`To value must be greater than ${val} for band ${gradeName}.`)
    }
    else if (isMatch) {
      toast.error(`The value ${compArr[a]} is already used in the other range.`)
    }
    else if (valueOverLap) {

      toast.error(`The value ${overLapValue} is already used in the other range.`)
    }


    else {

      const data = {
        "grade_scale": {
          "name": this.tempData?.attributes?.name,
          "settings_attributes": attributesData
        }
      }
      this.setState({ showSpinner: true })
      const school_data = localStorage.getItem("user_data");
      const Schooldata = JSON.parse(school_data || "{}");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
        "school": Schooldata.school_id,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editGradeScaleAPIId = requestMessage.messageId;

      const httpBody = data;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.resultUrl
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createGradeEndPoint}/${this.state.selectedGradeId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.EditMethodType
      );


      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getGradeScaleByGradeId = (id: any) => {
    this.setState({ showSpinner: true })
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetGradeListByGradeId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createGradeEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  selectGradeScale = () => {
    this.setState({ showSpinner: true })
    const school_data = localStorage.getItem("user_data");
    const Schooldata = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": Schooldata.school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.selectGradeScaleAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.selectGradeScale
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  task_categoeryGrade_Scales = (taskId: any) => {
    this.setState({ showSpinner: true })
    const data = {
      task_category_grade_scales: taskId
    }

    const school_data = localStorage.getItem("user_data");
    const Schooldata = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": Schooldata.school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.task_categoeryGrade_ScalesAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.task_categoeryGrade_Scales
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  gradeScaleDelete = () => {
    this.setState({ showSpinner: true });
    const school_data = localStorage.getItem("user_data");
    const Schooldata = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": Schooldata.school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.gradeScaleDeleteAPIID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.grade_delete}/${this.state.selectedGradeId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
