// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import { encryptData } from "./utils/common";
import { HISTORY } from "../../../components/src/comman";
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any
  // Customizable Area End
}
export interface ILiveClassesProps {
  // Customizable Area Start
  id: any;
  isAllDay?: boolean;
  start: Date;
  end: Date;
  title: string;
  type?: string;
  hexBgColor?: string;
  borderColor?: string;
  image?: any;
  subjectImage?: any;
  subject?: string;
  meetingDate?: any;
  meetingTitle?: string;
  teacherImage?: any;
  teacherName?: string;
  unit?: string;
  link?: string;
  class?: string;
  description?: string;
  status?: string;
  recordings?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  date: any;
  loadingSpinner: boolean;
  calendarEvents: any;
  selectedLiveClass: any;
  numberOfSubject: number;
  studentSubjects: any;
  studentTasks: any;
  studentAttendanceList: any;
  timeLineMeta: any;
  openFeedbackPopup: boolean;
  showLoader: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudentClassesAndSectionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiaddStudentClassesId: string = "";
  apigetStudentSubjectsId: string = '';
  apigetStudentTaskId: string = '';
  apigetStudentAttendanceId: string = '';
  apiGetLiveClasses: string = '';
  child: React.RefObject<any>;
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.child = React.createRef();
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      date: moment(new Date()),
      loadingSpinner: false,
      calendarEvents: [],
      selectedLiveClass: null,
      studentSubjects: [],
      numberOfSubject: 0,
      studentTasks: [],
      studentAttendanceList: [],
      timeLineMeta: [],
      openFeedbackPopup: false,
      showLoader: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const meetingID = localStorage.getItem("meeting-detail");
    const meetingId = JSON.parse(meetingID || "{}");
    const prevUrl = document.referrer;
    if (prevUrl.includes("/Meeting") && meetingId.meetingId) {
      this.handleToggle();
    }
    super.componentDidMount();
    this.getStudentLiveClasses();
    this.get_student_subjects();
    this.get_task();
    this.getStudentAttendance();
  }

  handleToggle = () => {
    this.setState({ openFeedbackPopup: !this.state.openFeedbackPopup });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apiaddStudentClassesId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            console.log("Response Json", responseJson)
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiGetLiveClasses) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.handleTimeTableData(responseJson?.data,);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
          this.setState({ loadingSpinner: false })
          this.setState({ showLoader: false })
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetStudentSubjectsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ studentSubjects: responseJson.data, numberOfSubject: responseJson.data.length })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetStudentTaskId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              studentTasks: responseJson.tasks.data,
              timeLineMeta: responseJson.meta,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetStudentAttendanceId) {

        if (responseJson != null) {
          if (!responseJson.errors) {
            if (responseJson.data != undefined) {
              this.setState({
                studentAttendanceList: responseJson.data,
              });
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  handleGetStartAndEndTime = (startDate: any) => {
    const dateObj = new Date(startDate);
    const month = dateObj.getMonth(); //index of month
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const formattedTime = moment(startDate).format("HH:mm");
    const formattedTimeHours = parseInt(formattedTime.split(":")[0]);
    const formattedTimeMins = parseInt(formattedTime.split(":")[1]);
    return new Date(year, month, day, formattedTimeHours, formattedTimeMins);
  };
  handleTimeTableData = (periodInfo: Array<any>) => {
    if (periodInfo.length) {
      const periodInfoDetails = periodInfo.map((period) => {
        const info = {
          title:
            period.attributes.topic ||
            period.attributes.subject.data.attributes.subject,
          start: this.handleGetStartAndEndTime(
            moment(period.attributes.start_time).format()
          ),
          end: this.handleGetStartAndEndTime(
            moment(period.attributes.end_time).format()
          ),
          hexBgColor: "#b1e4b1",
          borderColor: "#3dbb3d",
          subjectImage:
            period.attributes.subject.data.attributes.image?.file_path ? period.attributes.subject.data.attributes.image?.file_path : "",
          subject: period.attributes.subject?.data?.attributes?.subject_name,
          meetingDetails: period.attributes.signature,
          meetingId: period?.id,
          meetingTitle: period.attributes.topic,
          teacherImage: period.attributes.teacher_name?.data?.attributes?.avatar,
          teacherName:
            `${period.attributes.teacher_name?.data?.attributes?.first_name
            } ${period.attributes.teacher_name?.data?.attributes?.last_name}`,
          link: period.attributes.join_url,
          class: period.attributes?.class_name,
          description: period.attributes.description,
          status: period.attributes.status,
          recordings: period.attributes.recordings
        };
        return info;
      });
      this.setState({ calendarEvents: periodInfoDetails });
    }
  };

  sectionBtnHandler = (type: string) => {
    if (type === 'Time Table') {
      HISTORY.push({
        pathname: "/StudentTimeTable",
        state: {
          type: type,
        },
      });
    }
    else if (type === 'Gradebook') {
      HISTORY.push({
        pathname: "/StudentGradeBook",
        state: {
          type: type,
        },
      });
    }
    else if (type === 'Resources') {
      HISTORY.push({
        pathname: "/StudentResources",
        state: {
          type: type,
        },
      });
    }
    else if (type === 'Subjects') {
      HISTORY.push({
        pathname: "/StudentSubject",
        state: {
          studentSubjects: this.state.studentSubjects,
          type: type,
        },
      });
    }
    else if (type === 'Task') {
      HISTORY.push({
        pathname: "/StudentTaskUnit",
        state: {
          studentTasks: this.state.studentTasks,
          timeLineMeta: this.state.timeLineMeta,
          type: type,
        },
      });
    } else if (type === 'Members') {
      HISTORY.push({
        pathname: "/StudentMembers",
        state: {
          type: type,
        },
      });
    }
    else {
      HISTORY.push({
        pathname: "/StudentAttendance",
        state: {
          studentAttendanceList: this.state.studentAttendanceList,
          type: type,
        },
      });
    }
  }
  handleDateNavigate = (count: any, type: any, navigationType: any) => {
    let futureDate: any = "";
    if (navigationType === "backward") {
      if (type === "months") {
        futureDate = moment(this.state.date).subtract(count, "M");
      } else {
        futureDate = moment(this.state.date).subtract(count, "Y");
      }
    } else {
      if (type === "months") {
        futureDate = moment(this.state.date).add(count, "M");
      } else {
        futureDate = moment(this.state.date).add(count, "Y");
      }
    }
    this.setState({ date: futureDate });
    this.addLunchEvent(futureDate);
  };
  addLunchEvent = (currentDate?: any) => {
    let updatedCalendarEvents: any = [];
    let { calendarEvents, date } = this.state;
    const selectedDate = currentDate ? currentDate : date;
    const currentMonth = moment(selectedDate).format("M");
    const currentYear = moment(selectedDate).format("YYYY");

    const oddMonths = [1, 3, 5, 7, 8, 10, 12]; // Month num with 31 days
    let monthDays = 30;
    if (oddMonths.includes(Number(currentMonth))) {
      monthDays = 31;
    } else if (Number(currentMonth) === 2) {
      const leapYear = moment(selectedDate).isLeapYear();
      if (leapYear) {
        monthDays = 29;
      } else {
        monthDays = 28;
      }
    }

    for (let date = 0; date < monthDays; date++) {
      const lunchEvent = {
        id: `${date}-${Number(currentMonth)}-${Number(currentYear)}`,
        title: "Lunch Time",
        start: new Date(
          Number(currentYear),
          Number(currentMonth) - 1,
          date,
          11,
          0,
          0,
          0
        ),
        end: new Date(
          Number(currentYear),
          Number(currentMonth) - 1,
          date,
          12,
          0,
          0,
          0
        ),
        type: "lunchTime",
        hexBgColor: "#f1eff9",
        borderColor: "#f1eff9",
      };
      if (lunchEvent.start.getDay() !== 0) {
        updatedCalendarEvents = [...updatedCalendarEvents, lunchEvent];
      }
    }
  }
  handleOpenZoomMeeting = (event: any) => {
    const userData = localStorage.getItem("user_data");
    const parsedUserData = JSON.parse(userData || "{}");
    if (parsedUserData.meeting_paid_account) {
      const { api_key, api_secret, id } = event.meetingDetails;
      const role = 0;
      const meetingParameters = {
        ...event,
        meetingDetails: {
          api_key: encryptData(api_key, "meeting-api-key"),
          api_secret: encryptData(
            api_secret,
            "meeting-api-secret"
          ),
          id: encryptData(
            id,
            "meeting-id"
          ),
          role: encryptData(
            role.toString(),
            "meeting-role"
          )
        }
      };
      localStorage.setItem("meeting-detail", JSON.stringify(meetingParameters));
      localStorage.setItem("role", "student");
      const meetingDetail = localStorage.getItem("meeting-detail");
      if (meetingDetail) {
        const currentUrl = window.location.origin;
        const redirectUrl = `${currentUrl}/Meeting`;
        window.open(redirectUrl, "_blank");
      }
    } else {
      window.open(event.link, "_blank");
    }
  }
  get_student_subjects = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentSubjectsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherSubjectsEndPoint + `?class_id=${data.school_account.data[0].attributes.class.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  get_task = () => {
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: data.school_id,
    };
    this.setState({ showLoader: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentTaskId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentTaskEndPoint +
      `?user_id=${localStorage.getItem(
        "id"
      )}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getStudentAttendance = () => {
    this.setState({ loadingSpinner: true })
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentAttendanceId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentAttendance + `?student_id=${localStorage.getItem("id")}&date=${moment().format('DD-MM-YYYY')}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.attendanceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStudentLiveClasses = (date?: any) => {
    this.setState({ loadingSpinner: true })
    this.setState({ showLoader: true })
    const selectedDate = date
      ? moment(date).format("DD/MM/YYYY")
      : moment(this.state.date).format("DD/MM/YYYY");
    const userData = localStorage.getItem("user_data");
    const parsedUserData = JSON.parse(userData || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetLiveClasses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherLiveClassesEndPoint +
      `?start_date=${selectedDate}&school_id=${parsedUserData.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
