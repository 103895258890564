// Customizable Area Start
import {
  Box,
  IconButton,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  Tooltip as MuiToolTip,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { BarChartOutlined, TimelineOutlined } from '@material-ui/icons';
import { Bar, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// Customizable Area End
// Customizable Area Start
const useStyles = makeStyles({
  button_padding: {
    padding: '10px 0px 10px 0px',
  },
  timeline: {
    cursor: 'pointer',
    padding: 5,
    fontSize: 45,
    borderRadius: 6,
  },
  barchart: {
    cursor: 'pointer',
    fontSize: 45,
    padding: 5,
    borderRadius: 6,
  },
  table_container: {
    borderRadius: 6,
    border: 0,
    padding: 5,
  },
  table_cell: {
    opacity:'0.58 !important',
    fontSize: '14px !important',
    borderBottom: '0px !important',
    color: '#3f526d !important',
  },
  normal_table_cell: {
    fontSize: 18,
  },
  table_head: {
    backgroundColor: '#f9f8fd',
  },
 active_button: {
    backgroundColor: '#a9a9a9 !important',
  },
  inactive_button: {
    backgroundColor: 'white !important',
  },
});

const configJSON = require('../config.js');

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
// Customizable Area End
// Customizable Area Start

interface IProps {
  classes: any;
  sections: any;
  assessmentData: any;
  activeSection: any;
  id:string;
  toggleActiveSection: (index: number) => void;
}
// Customizable Area End

export const AssessmentAnalysis: React.FC<IProps> = (props) => {
  // Customizable Area Start
  const customClasses = useStyles();
  const {
    classes,
    sections,
    assessmentData,
    toggleActiveSection,
    activeSection,
    id,
  } = props;
  const [tabValue, setTabValue] = React.useState<number>(0);
  const handleChangeTabs = useCallback((value: number) => {
    setTabValue(value);
  }, []);
  const [isBar, setIsBar] = React.useState<boolean>(true);
console.log(isBar);

  const lineData = {
    data: {
      labels: activeSection?.assessment_sec_ques
        ? activeSection?.assessment_sec_ques?.map(
            (item: any) => item?.question_number
          )
        : [],
      datasets: [
        {
          data: activeSection?.assessment_sec_ques
            ? activeSection?.assessment_sec_ques?.map(
                (item: any) => item?.time_duration
              )
            : [],
          backgroundColor: [
            '#26c165',
            '#4fdbb6',
            '#5887a3',
            '#ffcc70',
            '#f24a3d',
          ],
          tension: 0.4,
          borderRadius: 15,
          borderSkipped: 'bottom',
        },

      ],
    },
    options: {
      scales: {
        yAxes:[{
          ticks:{
            beginAtZero:true,

          }
        }],
        x: {
          suggestedMin: 0,
          suggestedMax: 100,
          grid: {
            display: false,
          },
          title: {
            display: false,
            text: 'Questions',
            color: '#000',
          },
        },
        y: {
          min: 0,
          title: {
            display: true,
            text: 'Time (seconds)',
            font:{
            size:'18px',
            weight:'bold'
            }
          },
          ticks:{
            callback:function(value:any){
              return value+":00"
            }
          }
        },
        // A: {
        //   position:'left',
        //   id: 'A',
        //   suggestedMin: 0,
        //   suggestedMax: 100,
        //   grid: {
        //     display: true,
        //   },
        //   title: {
        //     display: true,
        //     text: 'Average Score (%)',
        //     color: '#000',
        //   }
        // },
        // B:{
        //   id: 'B',
        //   position: 'right',
        //   type: 'linear',
        //   grid:{
        //     display:false
        //   },
        //   display: true,
        //   title: {
        //     display: true,
        //     text: 'Seconds',
        //     color: '#000',
        //   }
        // }
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          color: 'black',
          formatter: Math.round,
          anchor: 'end',
          offset: -12,
          align: 'start',
        },
        title: {
          display: true,
          text: 'Questions',
          position: 'bottom' as const,
          font:{
            size:'18px',
            weight:'bold'
                        }
        },
        tooltip: {
          enabled: true,
        },
      },
    },
  };

  const data = {
    data: {
      labels: activeSection?.assessment_sec_ques
        ? activeSection?.assessment_sec_ques?.map(
            (item: any) => item?.question_number
          )
        : [],
      datasets: [
        {
          data: activeSection?.assessment_sec_ques
            ? activeSection?.assessment_sec_ques?.map(
                (item: any) => item?.score
              )
            : [],
          backgroundColor: [
            '#26c165',
            '#4fdbb6',
            '#5887a3',
            '#ffcc70',
            '#f24a3d',
          ],
          tension: 0.4,
          borderRadius: 15,
          borderSkipped: 'bottom',
          yAxisID: 'A',
        },
        // {
        //   data: activeSection?.assessment_sec_ques
        //     ? activeSection?.assessment_sec_ques?.map(
        //         (item: any, index: number) => item?.time_duration
        //       )
        //     : [],
        //   yAxisID: 'B',
        //   label: 'Time Duration',
        //   type: 'line',
        //   tension: 0.4,
        // },
      ],
    },
    options: {
      layout: {
        padding: {
          top: 15
        }
      },
      scales: {
        x: {
          suggestedMin: 0,
          suggestedMax: 100,
          grid: {
            display: false,
          },
        },
        A: {
          position: 'left',
          id: 'A',
          suggestedMin: 0,
          suggestedMax: 100,
          grid: {
            display: true,
          },
          title: {
            display: true,
            text: 'Average Score (%)',
            font:{
              size:'18px',
              weight:'bold'
            }
          },
        },
        // B: {
        //   id: 'B',
        //   position: 'right',
        //   type: 'linear',
        //   grid: {
        //     display: false,
        //   },
        //   display: true,
        //   title: {
        //     display: true,
        //     text: 'Seconds',
        //     color: '#000',
        //   },
        // },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          color: 'black',
          formatter: Math.round,
          anchor: 'end',
          offset: -15,
          align: 'start',
        },
        title: {
          display: true,
          text: 'Questions',
          position: 'bottom' as const,
          font:{
            size:'18px',
            weight:'bold'
          }
        },
        tooltip: {
          enabled: true,
        },
      },
    },
  };

  if (!activeSection) return null;

  return (
    <Box marginTop={3} id={id}>
      <Typography variant="h6" className={classes.subTitle_font}>
        {configJSON.ASSESSMENT_SUMMARY.ANALYSIS_TITLE}{' '}
        {assessmentData?.assessment_name}
      </Typography>
      {/* Graph starts here */}
      <Box marginTop={3}>
        <Tabs
          value={tabValue}
          onChange={(_, value) => handleChangeTabs(value)}
          TabIndicatorProps={{
            style: {
              background: classes.button_color,
            },
          }}
        >
          {sections.map((_: any, index: number) => {
            return (
              <Tab
                key={index}
                className={` ${classes.subHeading_font}`}
                label={`Section ${index + 1}`}
                id={`${tabValue}`}
                onClick={() => toggleActiveSection(index)}
              />
            );
          })}
        </Tabs>
        <Box marginTop={3}>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent={'flex-end'}
            className={customClasses.button_padding}
          >
            <MuiToolTip
              title="Line chart"
              className={`ListIcon ${
                !isBar
                  ? customClasses.active_button
                  : customClasses.inactive_button
              } ${customClasses.timeline}`}
              onClick={() => {
                setIsBar(false);
              }}
            >
              <IconButton>
                <TimelineOutlined
                  className={`ListIcon ${
                    !isBar
                    ? customClasses.active_button
                    : classes.inactive_button
                  } ${customClasses.timeline}`}
                  onClick={() => {
                    setIsBar(false);
                  }}
                />
              </IconButton>
            </MuiToolTip>

            <MuiToolTip
              title="Bar chart"
              className={`ListIcon ${
                isBar
                ? customClasses.active_button
                : classes.inactive_button
              } ${customClasses.timeline}`}
              onClick={() => {
                setIsBar(true);
              }}
            >
              <IconButton
                onClick={() => {
                  setIsBar(true);
                }}
              >
                <BarChartOutlined
                  className={`ListIcon ${
                    isBar
                    ? customClasses.active_button
                    : classes.inactive_button
                  } ${customClasses.barchart}`}
                />
              </IconButton>
            </MuiToolTip>
          </Box>
          {isBar ? (
            <Box
              className={
                classes.AssessmentAnalysis_TeacherReports_full_width_graph_height
              }
            >
              {/* @ts-ignore */}
              <Bar {...data} plugins={[ChartDataLabels]} />
            </Box>
          ) : (
            <Box
              className={
                classes.AssessmentAnalysis_TeacherReports_full_width_graph_height
              }
            >
              {/* @ts-ignore */}
              <Line {...lineData} />
            </Box>
          )}
        </Box>
      </Box>
      {/* Graph ends here */}

      {/* Table starts here */}
      <Box marginTop={3}>
        <Typography variant="h6" className={classes.subTitle_font}>
          {configJSON.ASSESSMENT_ANALYSIS.DETAILED_PERFORMANCE} |
          {assessmentData?.assessment_name} (ID:{assessmentData?.assessment_id})
        </Typography>
        <Box marginTop={3}>
          <TableContainer className={customClasses.table_container}>
            <Table className="separte-border-collapse">
              <TableHead className={customClasses.table_head}>
                <TableRow>
                  <TableCell
                    style={{}}
                    className={`${(classes.subHeading_font,
                    customClasses.table_cell)}`}
                    align="center"
                  >
                    {configJSON.ASSESSMENT_ANALYSIS.QUESTION}
                  </TableCell>
                  <TableCell
                    className={`${(classes.subHeading_font,
                    customClasses.table_cell)}`}
                    align="center"
                  >
                    {configJSON.ASSESSMENT_ANALYSIS.CLASS}
                  </TableCell>
                  <TableCell
                    className={`${(classes.subHeading_font,
                    customClasses.table_cell)}`}
                    align="center"
                  >
                    {configJSON.ASSESSMENT_ANALYSIS.MARK}
                  </TableCell>
                  <TableCell
                    className={`${(classes.subHeading_font,
                    customClasses.table_cell)}`}
                    align="center"
                  >
                    {configJSON.ASSESSMENT_ANALYSIS.DISTRICT}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                style={{ marginTop: 10, maxHeight: 340, overflow: 'auto' }}
              >
                {activeSection.assessment_sec_ques?.map(
                  (item: any, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          align="center"
                          className={`${classes.bodyText_font} ${customClasses.normal_table_cell}`}
                        >
                          {item?.question_number}
                        </TableCell>
                        <TableCell
                          className={`${classes.bodyText_font} ${customClasses.normal_table_cell}`}
                          align="center"
                        >
                          {item?.grade_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.bodyText_font} ${customClasses.normal_table_cell}`}
                        >
                          {item?.question_marks}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.bodyText_font} ${customClasses.normal_table_cell}`}
                        >
                          {item?.score.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* Table ends here  */}
    </Box>
  );
  // Customizable Area End
};

// Customizable Area Start
export default AssessmentAnalysis;
// Customizable Area End
