// Customizable Area Start
import React, { useState, useCallback, useRef } from "react";
import Webcam from "react-webcam";
import { Box, Grid, Button, Typography, Divider } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { convertDataURLtoFile } from "./utils/common";
import { camera_icon } from "./assets";
// Customizable Area End

// Customizable Area Start
interface ICapturePhotoViaWebcam {
  onCloseModal: () => void;
  onFileSave: (file: File) => void;
  loadingSpinner?: boolean;
}

const takePictureDiv = {
  fontFamily: "Open sans",
  fontSize: "24px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "center",
  color: "#3f526d",
  marginBottom: "14px",
} as const;

const spinnerBar = {
  color: "rgba(255, 255, 255, 1)",
  height: "30px",
  width: "30px",
  margin: "auto",
};

const loader = {
  color: "blue",
  height: "30px",
  width: "30px",
  margin: "auto",
};
// Customizable Area End

export const CapturePhotoViaWebcam: React.FC<ICapturePhotoViaWebcam> = ({
  // Customizable Area Start
  onCloseModal,
  onFileSave,
  loadingSpinner,
  // Customizable Area End
}) => {
  // Customizable Area Start
  const webcamRef: React.MutableRefObject<any> = useRef(null);
  const [image, setImage] = useState("");
  const [canTakePhotoViaWebcam, setCanTakePhotoViaWebcam] = useState(false);
  const [isCameraPermissionDenied, setIsCameraPermissionDenied] = useState(
    false
  );
  const capture = useCallback(() => {
    setImage(webcamRef.current.getScreenshot());
  }, [webcamRef]);

  const reset = useCallback(() => {
    setImage("");
    setCanTakePhotoViaWebcam(false);
  }, []);

  const handleCaptureButtonClick = () => {
    setCanTakePhotoViaWebcam(true);
  };

  const handleImageUpload = useCallback(() => {
    const file = convertDataURLtoFile(image, "image.jpeg");
    onFileSave(file);
  }, [image]);

  const handleError = useCallback((e) => {
    setIsCameraPermissionDenied(true);
  }, []);

  return (
    <Box className="capture_photo_modal">
      <Grid className="CapturePhotoGrid">
        <div className="take_picture_title">Take Picture</div>
        <Close 
          className="CapturePhotoClose"
          onClick={onCloseModal}
        />
      </Grid>

      <Divider />
      {!canTakePhotoViaWebcam && !isCameraPermissionDenied && (
        <div className="CapturePhotoLoader">
          <CircularProgress style={loader} />
        </div>
      )}
      {isCameraPermissionDenied && (
        <div className="CapturePhotoNotAllowedText">
          Please allow 21KSchoolPhase1 to access camera in order to take
          picture!
        </div>
      )}
      {!isCameraPermissionDenied && (
        <>
          <Grid className="CapturePhotoAllowedGrid">
            {image && (
              <img
                className="CapturedImage"
                src={image}
                onDragStart={(e) => e.preventDefault()}
                alt=""
              />
            )}

            {!image && (
              <Webcam
                audio={false}
                ref={webcamRef}
                onUserMediaError={handleError}
                onUserMedia={handleCaptureButtonClick}
                screenshotFormat="image/jpeg"
                width="100%"
                height="300px"
                className="WebCamCapture"
              />
            )}
          </Grid>

          <Grid className="CapturePhotoActions">
            {!image ? (
              <Button
                onClick={capture}
                className="CaptureCameraIcon"
                style={{
                  opacity: canTakePhotoViaWebcam ? 1 : 0.6,
                }}
                disabled={!canTakePhotoViaWebcam}
              >
                <img className="camera_icon" src={camera_icon} />
              </Button>
            ) : (
              <>
                <Button
                  onClick={handleImageUpload}
                  className="PhotoSubmitBtn"
                >
                  <Typography className="PhotoSubmitBtnText">
                    {loadingSpinner ? (
                      <CircularProgress style={spinnerBar} />
                    ) : (
                      <>Submit</>
                    )}
                  </Typography>
                </Button>
                <Button
                  onClick={reset}
                  className="PhotoCancelBtn"
                  style={{                 
                    opacity: loadingSpinner ? 0.5 : 1,
                  }}
                  disabled={loadingSpinner}
                >
                  <Typography className="PhotoCancelBtnText">
                    Cancel
                  </Typography>
                </Button>
              </>
            )}
          </Grid>
        </>
      )}
    </Box>
  );
  // Customizable Area End
};

// Customizable Area Start
export default CapturePhotoViaWebcam;
// Customizable Area End
