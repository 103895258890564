//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
// Customizable Area Start
import React from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import {
  img21kSchholLogo,
  imgloginScreenBackground,
  manImage,
  bigCloud,
  smallCloud,
} from "./assets";
import "./ForgotPassword.web.css";
import { Hidden, Typography } from "@material-ui/core";

import NewPasswordController, { Props } from "./NewPasswordController.web";
// Customizable Area End
// Customizable Area Start
const root = {
  flexGrow: 1,
  height: "100%"
} as const;
const loginImageDiv = {
  position: "relative",
  // height:"100vh",
} as const;
const halfcircleImage = {
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(142, 123, 253, 1)",
  position: "absolute",
  zIndex: 0,
  objectFit: "cover",
} as const;
const logoImage = {
  width: "auto",
  height: "68px",
  position: "relative",
  margin: "33px 29px 29px 30px",
} as const;
const textlabel_quoteText = {
  color: "rgba(255, 255, 255, 1)",
  fontWeight: "bold",
  position: "relative",
  fontFamily: "Open sans",
  fontSize: "4.5rem",
  margin: "25px 34px",
} as const;
const image_studentillustratorImage = {
  width: "56%",
  height: "auto",
  bottom: "0%",
  position: "absolute",
  transform: "translateX(105%)",
} as const;
const image_cloudsmallImage = {
  width: "65px",
  height: "51px",
  position: "absolute",
  top: "61%",
  left: "30%",
} as const;
const image_cloudbigImage = {
  width: "151px",
  height: "117px",
  bottom: "10%",
  left: "8%",
  position: 'absolute',
} as const;
const attrbuted_textlabel_div = {
  textAlign: "right",
  fontFamily: "Open sans",
  fontSize: "16px",
  // marginTop: "10px",
  margin: "5% 0px 4% 0px"
} as const;
const attrbuted_textlabel_signinText = {
  color: "rgba(3, 1, 76, 1)",
} as const;
const attrbuted_textlabel_signinText2 = {
  color: "rgba(0, 0, 0, 1)",
  fontWeight: "bold",
  marginLeft: "10px",
  marginRight: "5px",
} as const;
const loginDiv = {
  justifyContent: "center",
  // margin: "auto",
  width: "60%",
  // paddingLeft: "30px",
  margin: "5% 0px 4% 0px",
} as const;
const textlabel_welcomeText = {
  fontSize: "35.1px",
  fontWeight: "bold",
  marginTop: "10px",
  fontFamily: "Open sans",
  paddingTop: "32%",
  maxWidth: "90%",
} as const;
const textlabel_followinstructionText = {
  color: "#03014c",
  fontSize: "14px",
  fontFamily: "Open sans",
  margin: "20px 0 40px 0",
  opacity: 0.5,
} as const;
const textlabel_loginText = {
  fontWeight: "bold",
  color: "rgba(255, 255, 255, 1)",
  marginTop: "20px",
  width: "100%",
  backgroundColor: "rgba(142, 123, 253, 1)",
  padding: "10px",
} as const;
// Customizable Area End

export default class SucessfullPasswordChange extends NewPasswordController {

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return (
      <div style={{ height: "100vh" }}>
        <div style={root}>
          <Grid container style={{ height: "100%" }}>
            <Grid xs={12} sm={12} md={12} lg={6} spacing={0} style={loginImageDiv}>
              <img
                src={imgloginScreenBackground}
                style={halfcircleImage}
                alt="Background Image"
              />
              <img
                src={manImage}
                alt="Student Illustrator Image"
                style={image_studentillustratorImage}
                className="student_illustrator_img"
              />
              <div>
                <img className="SchholLogo"
                  src={img21kSchholLogo}
                  alt="Logo Image"
                  style={logoImage}
                />
              </div>
              <div style={textlabel_quoteText} className="learn_together_text">
                <Hidden only={['lg', 'xl']}>
                  Let’s learn Together
                </Hidden>
                <Hidden only={['xs', 'sm', 'md']}>
                  Let’s learn
                  <br />
                  Together
                </Hidden>
              </div>
              <img
                src={smallCloud}
                alt="Small Cloud Image"
                style={image_cloudsmallImage}
                className="cloud_small_img"
              />
              <img
                src={bigCloud}
                alt="Big Cloud Image"
                style={image_cloudbigImage}
                className="cloud_big_img"
              />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={6}>
              <Grid container className="main_right_div">
                <Grid item xs={12} style={loginDiv} className="login_div">
                  <div style={attrbuted_textlabel_div} className="attributed_textlabel">
                    <span style={attrbuted_textlabel_signinText}>
                      Remember Password ?
                    </span>
                    <Link href="/EmailAccountLoginBlock" style={attrbuted_textlabel_signinText2}>
                      Login
                    </Link>
                  </div>

                  <div style={textlabel_welcomeText} className="welcome_text max_width_100">Congrats! Password Changed</div>
                  <Typography style={textlabel_followinstructionText}>
                    Your password has been changed successfully
                  </Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    // style={textlabel_loginText}
                    className="textlabel_loginText"
                    onClick={this.backtologinhandle}
                  >
                    Go Back To Login
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End