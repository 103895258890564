// Customizable Area Start

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import HelpIcon from '@material-ui/icons/Help';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Steppers from './Steppers.web';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import FormControl from '@material-ui/core/FormControl';
import CancelIcon from '@material-ui/icons/Cancel';
import { Box, Link, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './TeacherAccountRegistration.css';
import { img21kSchholLogo } from './assets';
import ParentAccountRegistrationController, {
  Props,
} from './ParentAccountRegistrationController.web';
import { NativeSelect } from '@material-ui/core';
import Spinner from '../../shared/SideBar/src/Spinner';
import './EmailAccountRegistration.web.css';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';

export const configJSON = require('./config');
// Customizable Area End

// Customizable Area Start
const accountRegistration_div = {
  justifyContent: 'space-between',
  display: 'flex',
} as const;
const accountRegistration_helpIcon = {
  color: '#f57b42',
  padding: '20px',
} as const;
const logoImage = {
  width: '151px',
  position: 'relative',
  objectFit: 'cover',
} as const;
const typesofAccount_textLabel = {
  fontSize: '35.1px',
  fontWeight: 'bold',
  color: '#000',
} as const;
const avatar = {
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  padding: '5px 0px',
  margin: '0px 10px',
  verticalAlign: 'text-top',
  cursor: 'pointer',
} as const;
const accountRegistration_footer = {
  justifyContent: 'space-between',
  display: 'flex',
} as const;
const Follow_the_instructions = {
  marginTop: '11.6px',
  width: '50%',
  margin: 'auto',
  fontSize: '14px',
  fontStretch: 'normal',
  opacity: 0.5,
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: '#8c8aad',
  marginBottom: '24px',
  padding: '0 20%',
} as const;
const textfield_div = {
  justifyContent: 'center',
  marginTop: '18.9px',
} as const;
const forrmInput_textfield = {
  width: '95%',
  borderRadius: '8.8px',
  textAlign: 'start',
  color: '#03014c',
  fontSize: '15.8px',
} as const;
const textfield_avtar_div = {
  padding: '0 5%',
  justifyContent: 'center',
  paddingBottom: '4%',
};
const avtar_textlabel = {
  fontSize: '22.1px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: 'black',
  marginTop: '10px',
  textAlign: 'start',
} as const;
const input = {
  display: 'none',
} as const;
const avtar_scroolbar_div = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: '100%',
} as const;
const uploadimage_btn_grid = {
  textAlign: 'center',
  fontSize: '13.1px',
  color: '#000',
  fontWeight: 'bold',
  verticalAlign: 'middle',
  margin: 'auto',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
} as const;
const orGrid = {
  textAlign: 'center',
  fontSize: '13.1px',
  color: '#000',
  fontWeight: 'bold',
  verticalAlign: 'middle',
  margin: 'auto',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
} as const;
const scrollmenu = {
  overflowX: 'auto',
  whiteSpace: 'nowrap',
} as const;
const spinnerBar = {
  color: 'rgba(255, 255, 255, 1)',
} as const;
// Customizable Area End

export class ParentAccountRegistration extends ParentAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const phoneReg = /^(?:[0-9]�?){10,15}[0-9]$/;
    const pswdspaceReg = /^\S$|^\S[\s\S]*\S$/;
    const name = /^[a-zA-Z ]*$/;
    const today = moment();
    const disableFutureDt = (current: any) => {
      return current.isBefore(today);
    };
    const BootstrapInput = withStyles((theme) => ({
      root: {
        'label + &': {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: '8.8px',
        position: 'relative',
        width: '90%',
        height: '30px',
        border: this.state.gendererror ? '1px solid red' : '1px solid #cfcfcf',
        backgroundColor: '#f6f6f6',
        fontSize: 16,
        padding: '18.5px 14px',
        color: this.state.gender === '' ? '#a6a5c0' : '#03014c',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
          borderRadius: '8.8px',
          borderColor: '#cfcfcf',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
      },
    }))(InputBase);

    return (
      <>
        {this.state.loadingSpinner && (
          <Spinner spinnerModal={this.state.loadingSpinner} />
        )}
        <Grid container>
          <Grid className="Header" item xs={12} style={accountRegistration_div}>
            <img src={img21kSchholLogo} alt="Logo Image" style={logoImage} />
            <HelpIcon style={accountRegistration_helpIcon} />
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={this.onParentSubmit}>
              <Grid container style={accountRegistration_footer}>
                <Grid item xs={1}>
                  <Link href="/EmailAccountRegistration">
                    <IconButton
                      aria-label="delete"
                      className="arrowStyleButtonLeft"
                      color="primary"
                      onClick={() => {
                        localStorage.removeItem('user_data');
                      }}
                    >
                      <ArrowBackRoundedIcon style={{ color: 'white' }} />
                    </IconButton>
                  </Link>
                </Grid>
                <Grid item xs={10}>
                  <Grid container className="typesofAccount_div">
                    <Grid item xs={12}>
                      <Steppers activeStep={0} />
                    </Grid>
                    <Grid item xs={12} className={`${this.props.classes.theme_font}`} style={typesofAccount_textLabel}>
                      Create Parent Account
                    </Grid>
                    <Grid item xs={12} className={`${this.props.classes.theme_font}`} style={Follow_the_instructions}>
                      Follow the instructions to make it easier to register and
                      you will be able to explore inside.
                    </Grid>

                    <Grid container style={textfield_div}>
                      <Grid item xs={12} sm={6} style={{ marginBottom: '3%' }}>
                        <TextField
                          id="standard-basic"
                          type="text"
                          placeholder="First Name"
                          style={forrmInput_textfield}
                          className={`${this.props.classes.theme_font}`}
                          value={this.state.firstname}
                          variant="outlined"
                          autoComplete="off"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            if (value.length === 0) {
                              this.setState({
                                fnameerror: true,
                                fnameerrorText: 'First name is required.',
                              });
                            } else {
                              this.setState({
                                fnameerror: false,
                                fnameerrorText: '',
                              });
                            }
                            if (
                              event.target.value === '' ||
                              name.test(event.target.value)
                            ) {
                              this.setState({
                                firstname: event.target.value,
                              });
                            }
                          }}
                          onBlur={() => {
                            if (
                              this.state.firstname === null ||
                              this.state.firstname.length === 0
                            ) {
                              this.setState({
                                fnameerror: true,
                                fnameerrorText: 'First name is required.',
                              });
                            } else {
                              this.setState({
                                fnameerror: false,
                                fnameerrorText: '',
                              });
                            }
                          }}
                          error={this.state.fnameerror}
                          helperText={this.state.fnameerrorText}
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              backgroundColor: '#f6f6f6',
                              padding: '5px 20px 5px 20px',
                              height: '60px',
                              borderRadius: '8.8px',
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonOutlineOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    color: '#7c7ba0',
                                    paddingLeft: '20px',
                                  }}
                                />
                              </InputAdornment>
                            ),
                            style: {
                              backgroundColor: '#f6f6f6',
                              borderRadius: '8.8px',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginBottom: '3%' }}>
                        <TextField
                          id="standard-basic"
                          placeholder="Last Name"
                          style={forrmInput_textfield}
                          className={`${this.props.classes.theme_font}`}
                          value={this.state.lastname}
                          variant="outlined"
                          autoComplete="off"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            if (value.length === 0) {
                              this.setState({
                                lnameerror: true,
                                lnameerrorText: ' Last name is required.',
                              });
                            } else {
                              this.setState({
                                lnameerror: false,
                                lnameerrorText: '',
                              });
                            }

                            if (
                              event.target.value === '' ||
                              name.test(event.target.value)
                            ) {
                              this.setState({ lastname: event.target.value });
                            }
                          }}
                          onBlur={() => {
                            if (
                              this.state.lastname === null ||
                              this.state.lastname.length === 0
                            ) {
                              this.setState({
                                lnameerror: true,
                                lnameerrorText: ' Last name is required.',
                              });
                            } else {
                              this.setState({
                                lnameerror: false,
                                lnameerrorText: '',
                              });
                            }
                          }}
                          error={this.state.lnameerror}
                          helperText={this.state.lnameerrorText}
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              backgroundColor: '#f6f6f6',
                              padding: '5px 20px 5px 20px',
                              height: '60px',
                              borderRadius: '8.8px',
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonOutlineOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    color: '#7c7ba0',
                                    paddingLeft: '20px',
                                  }}
                                />
                              </InputAdornment>
                            ),
                            style: {
                              backgroundColor: '#f6f6f6',
                              borderRadius: '8.8px',
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} style={{ marginBottom: '3%' }}>
                        <TextField
                          id="standard-basic"
                          placeholder="Email ID"
                          style={forrmInput_textfield}
                          className={`${this.props.classes.theme_font}`}
                          variant="outlined"
                          autoComplete="off"
                          value={this.state.email}
                          autoFocus={false}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.value.length === 0) {
                              this.setState({
                                emailerror: true,
                                emailerrorText: 'Email is required.',
                              });
                            } else if (!emailReg.test(event.target.value)) {
                              this.setState({
                                emailerror: true,
                                emailerrorText: 'Enter a valid Email.',
                              });
                            } else if (emailReg.test(event.target.value)) {
                              this.setState({
                                emailerror: false,
                                emailerrorText: '',
                              });
                            }
                            this.setState({
                              email: event.target.value.trim(),
                            });
                          }}
                          onBlur={() => {
                            if (
                              this.state.email === null ||
                              this.state.email.length === 0
                            ) {
                              this.setState({
                                emailerror: true,
                                emailerrorText: 'Email is required.',
                              });
                            } else if (!emailReg.test(this.state.email)) {
                              this.setState({
                                emailerror: true,
                                emailerrorText: 'Enter a valid Email.',
                              });
                            } else {
                              this.setState({
                                emailerror: false,
                                emailerrorText: '',
                              });
                            }
                          }}
                          error={this.state.emailerror}
                          helperText={this.state.emailerrorText}
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              backgroundColor: '#f6f6f6',
                              padding: '5px 20px 5px 20px',
                              height: '60px',
                              borderRadius: '8.8px',
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    color: '#7c7ba0',
                                    paddingLeft: '20px',
                                  }}
                                />
                              </InputAdornment>
                            ),
                            style: {
                              backgroundColor: '#f6f6f6',
                              borderRadius: '8.8px',
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          marginBottom: '3%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div style={{ width: '95%' }}>
                          <ReactPhoneInput
                            country={'in'}
                            value={this.state.phonenumber}
                            placeholder="Phone Number"
                            alwaysDefaultMask={false}
                            autoFormat={false}
                            inputStyle={{
                              width: '100%',
                              height: '70px',
                              fontFamily: 'Open sans',
                              fontSize: '15.8px',
                              borderRadius: '8.8px',
                              padding: '5px 48px',
                              backgroundColor: '#f6f6f6',
                              border: this.state.phoneerror
                                ? '1px solid red'
                                : '1px solid lightgray',
                              borderLeft: this.state.phoneerror
                                ? '0px'
                                : '1px solid lightgray',
                            }}
                            buttonStyle={{
                              backgroundColor: '#f1f1f1',
                              border: this.state.phoneerror
                                ? '1px solid red'
                                : '1px solid lightgray',
                              borderRight: this.state.phoneerror
                                ? '0px'
                                : '1px solid lightgray',
                              borderBottomLeftRadius: '8.8px',
                              borderTopLeftRadius: '8.8px',
                              padding: '1px',
                            }}
                            dropdownStyle={{ width: '300px' }}
                            searchStyle={{
                              margin: '0',
                              width: '97%',
                              height: '30px',
                            }}
                            searchClass="search-class"
                            disableSearchIcon
                            enableSearch={true}
                            countryCodeEditable={false}
                            onChange={(value, country: any) => {
                              if (this.state.dialCode !== country.countryCode) {
                                this.setState({
                                  phonenumber: country.dialCode,
                                  dialCode: country.countryCode,
                                });
                              } else {
                                this.setState({
                                  phonenumber: value,
                                  dialCode: country.countryCode,
                                });
                              }
                              if (value.length === 0) {
                                this.setState({
                                  phoneerror: true,
                                  phoneerrorText: 'Phone number is required.',
                                });
                              } else if (phoneReg.test(value)) {
                                this.setState({
                                  phoneerror: false,
                                  phoneerrorText: '',
                                });
                              } else if (!phoneReg.test(value)) {
                                this.setState({
                                  phoneerror: true,
                                  phoneerrorText: 'Enter a valid phone number.',
                                });
                              } else {
                                this.setState({
                                  phoneerror: false,
                                  phoneerrorText: '',
                                });
                              }
                            }}
                            onBlur={(value: any) => {
                              if (
                                this.state.phonenumber === null ||
                                this.state.phonenumber.length === 0
                              ) {
                                this.setState({
                                  phoneerror: true,
                                  phoneerrorText: 'Phone number is required.',
                                });
                              } else if (
                                !phoneReg.test(this.state.phonenumber)
                              ) {
                                this.setState({
                                  phoneerror: true,
                                  phoneerrorText: 'Enter a valid phone number.',
                                });
                              } else if (
                                phoneReg.test(this.state.phonenumber)
                              ) {
                                this.setState({
                                  phoneerror: false,
                                  phoneerrorText: '',
                                });
                              } else {
                                this.setState({
                                  phoneerror: false,
                                  phoneerrorText: '',
                                });
                              }
                            }}
                          />
                          {this.state.phoneerror && (
                            <FormHelperText
                              style={{ color: 'red', margin: '3px 14px 0' }}
                            >
                              {this.state.phoneerrorText}
                            </FormHelperText>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginBottom: '3%' }}>
                        <FormControl style={forrmInput_textfield} className={`${this.props.classes.theme_font}`}>
                          <NativeSelect
                            id="select"
                            value={this.state.gender}
                            onChange={(
                              event: React.ChangeEvent<{ value: unknown }>
                            ) => {
                              if (event.target.value != 0) {
                                this.setState({ gendererror: false });
                              }
                              this.setState({
                                gender: event.target.value as string,
                              });
                            }}
                            input={<BootstrapInput />}
                            onBlur={() => {
                              if (
                                this.state.gender === null ||
                                this.state.gender.length === 0
                              ) {
                                this.setState({ gendererror: true });
                              } else if (this.state.gender.length != 0) {
                                this.setState({ gendererror: false });
                              } else {
                                this.setState({ gendererror: false });
                              }
                            }}
                          >
                            <option
                              value=""
                              style={{ color: '#a6a5c0' }}
                              disabled
                            >
                              Gender
                            </option>
                            <option value="Male" style={{ color: '#03014c' }}>
                              Male
                            </option>
                            <option value="Female" style={{ color: '#03014c' }}>
                              Female
                            </option>
                            <option value="Other" style={{ color: '#03014c' }}>
                              Other
                            </option>
                          </NativeSelect>
                          {this.state.gendererror && (
                            <FormHelperText
                              style={{ color: 'red', margin: '3px 14px 0' }}
                            >
                              Please select gender
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          marginBottom: '3%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div style={{ width: '95%' }}>
                          <Datetime
                            dateFormat={'DD-MM-YYYY'}
                            timeFormat={false}
                            strictParsing={true}
                            input={true}
                            value={this.state.dateofbirth}
                            className={
                              this.state.dateerror
                                ? 'input_date_css_error_format_registration'
                                : 'input_date_cal_css_format_registration'
                            }
                            inputProps={{
                              placeholder: 'Date of Birth',
                              className: 'Date_of_birth_calendar_Icon_register',
                              readOnly: true,
                            }}
                            isValidDate={disableFutureDt}
                            onClose={() => {
                              if (this.state.dateofbirth.length === 0) {
                                this.setState({
                                  dateerror: true,
                                  dateerrorText:
                                    ' Please select date of birth.',
                                });
                              }
                            }}
                            onChange={(date) => {
                              if (
                                moment(date).format('DD/MM/YYYY').length === 0
                              ) {
                                this.setState({
                                  dateerror: true,
                                  dateerrorText:
                                    ' Please select date of birth.',
                                });
                              }
                              this.setState({
                                dateofbirth: moment(date).format('DD/MM/YYYY'),
                                dateerror: false,
                                dateerrorText: '',
                              });
                            }}
                          />
                          <FormHelperText
                            style={{ color: 'red', margin: '3px 14px 0' }}
                          >
                            {this.state.dateerrorText}{' '}
                          </FormHelperText>
                        </div>
                      </Grid>
                      <Grid
                        style={{ marginBottom: '3%', position: 'relative' }}
                        xs={12}
                        sm={6}
                      >
                        <TextField
                          type="Password"
                          placeholder="Password"
                          autoComplete="new-password"
                          style={forrmInput_textfield}
                          className={`${this.props.classes.theme_font}`}
                          value={this.state.password}
                          variant="outlined"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            if (event.target.value.length === 0) {
                              this.setState({
                                pswderror: true,
                                openToolTip: false,
                                pswderrorText: 'Password is required',
                              });
                            } else if (!pswdspaceReg.test(event.target.value)) {
                              this.setState({
                                pswderror: true,
                                openToolTip: false,
                                pswderrorText:
                                  "Your password can't start or end with a blank space.",
                              });
                            } else if (value.length > 0) {
                              if (!passwordReg.test(value.replace(' ', '@'))) {
                                this.setState({
                                  pswderror: true,
                                  openToolTip: true,
                                  // pswderrorText:
                                  //   "Password must be 8 characters long and contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character.",
                                });
                              } else if (
                                passwordReg.test(value.replace(' ', '@'))
                              ) {
                                this.setState({
                                  pswderror: false,
                                  openToolTip: false,
                                  pswderrorText: '',
                                });
                              } else {
                                this.setState({
                                  pswderror: false,
                                  openToolTip: false,
                                  pswderrorText: '',
                                });
                              }
                            }
                            if (this.state.confirmPassword.length > 0) {
                              if (
                                event.target.value ===
                                this.state.confirmPassword
                              ) {
                                this.setState({
                                  cpswderror: false,
                                  cpswderrorText: '',
                                });
                              }
                            }

                            this.setState({ password: event.target.value });
                          }}
                          onBlur={() => {
                            if (
                              this.state.password === null ||
                              this.state.password.length === 0
                            ) {
                              this.setState({
                                pswderror: true,
                                pswderrorText: 'Password is required',
                              });
                            } else if (
                              !pswdspaceReg.test(this.state.password)
                            ) {
                              this.setState({
                                pswderror: true,
                                pswderrorText:
                                  "Your password can't start or end with a blank space.",
                              });
                            } else if (
                              !passwordReg.test(
                                this.state.password.replace(' ', '@')
                              )
                            ) {
                              this.setState({
                                pswderror: true,
                                // pswderrorText:
                                //   "Password must be 8 characters long and contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character.",
                              });
                            } else {
                              this.setState({
                                pswderror: false,
                                pswderrorText: '',
                              });
                            }
                            if (this.state.confirmPassword.length > 0) {
                              if (
                                this.state.password !=
                                this.state.confirmPassword
                              ) {
                                this.setState({
                                  cpswderror: true,
                                  cpswderrorText:
                                    'Password and Confirm password must match.',
                                });
                              }
                            }
                          }}
                          error={this.state.pswderror}
                          helperText={this.state.pswderrorText}
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              backgroundColor: '#f6f6f6',
                              padding: '5px 20px 5px 20px',
                              height: '60px',
                              borderRadius: '8.8px',
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockOpenOutlinedIcon
                                  className="pl-0"
                                  style={{
                                    fontSize: '20px',
                                    opacity: 0.5,
                                    paddingLeft: '20px',
                                  }}
                                />
                              </InputAdornment>
                            ),
                            style: {
                              backgroundColor: '#f6f6f6',
                              borderRadius: '8.8px',
                            },
                          }}
                        />
                        {this.state.openToolTip && (
                          <Box
                            style={{
                              padding: '10px',
                              top: '110%',
                              boxShadow: '0 0 10px 0 rgb(30 28 46 / 8%)',
                              background: 'white',
                              color: 'black',
                              width: '93%',
                              position: 'absolute',
                              borderRadius: '8.8px',
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 18,
                                textAlign: 'start',
                                fontFamily: 'Open sans',
                                margin: '20px 0px',
                              }}
                            >
                              Password must:
                            </Typography>
                            <ul
                              style={{
                                textAlign: 'start',
                                fontFamily: 'Open sans',
                              }}
                            >
                              <li>Have at least 8 characters.</li>
                              <li>Have at least 1 letter(a,b,c...).</li>
                              <li>Have at least 1 number(1,2,3...).</li>
                              <li>
                                Have at least 1 special character(@,#,$...).
                              </li>
                              <li>
                                Include both uppercase and lowercase characters.
                              </li>
                            </ul>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginBottom: '3%' }}>
                        <TextField
                          type="Password"
                          placeholder="Confirm Password"
                          style={forrmInput_textfield}
                          className={`${this.props.classes.theme_font}`}
                          variant="outlined"
                          value={this.state.confirmPassword}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.value.length === 0) {
                              this.setState({
                                cpswderror: true,
                                cpswderrorText: 'Confirm Password is required.',
                              });
                            } else if (!pswdspaceReg.test(event.target.value)) {
                              this.setState({
                                cpswderror: true,
                                cpswderrorText:
                                  "Your password can't start or end with a blank space.",
                              });
                            } else if (event.target.value.trim().length > 0) {
                              if (this.state.password != event.target.value) {
                                this.setState({
                                  cpswderror: true,
                                  cpswderrorText:
                                    'Password and Confirm password must match. ',
                                });
                              } else {
                                this.setState({
                                  cpswderror: false,
                                  cpswderrorText: '',
                                });
                              }
                            }
                            this.setState({
                              confirmPassword: event.target.value,
                            });
                          }}
                          onBlur={() => {
                            if (
                              this.state.confirmPassword === null ||
                              this.state.confirmPassword.length === 0
                            ) {
                              this.setState({
                                cpswderror: true,
                                cpswderrorText: 'Confirm Password is required.',
                              });
                            } else if (
                              !pswdspaceReg.test(this.state.confirmPassword)
                            ) {
                              this.setState({
                                pswderror: true,
                                pswderrorText:
                                  "Your password can't start or end with a blank space.",
                              });
                            } else if (
                              this.state.password != this.state.confirmPassword
                            ) {
                              this.setState({
                                cpswderror: true,
                                cpswderrorText:
                                  'Password and Confirm password must match. ',
                              });
                            } else {
                              this.setState({
                                cpswderror: false,
                                cpswderrorText: '',
                              });
                            }
                          }}
                          error={this.state.cpswderror}
                          helperText={this.state.cpswderrorText}
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              backgroundColor: '#f6f6f6',
                              padding: '5px 20px 5px 20px',
                              height: '60px',
                              borderRadius: '8.8px',
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockOpenOutlinedIcon
                                  style={{
                                    fontSize: '20px',
                                    opacity: 0.5,
                                    paddingLeft: '20px',
                                  }}
                                />
                              </InputAdornment>
                            ),
                            style: {
                              backgroundColor: '#f6f6f6',
                              borderRadius: '8.8px',
                            },
                          }}
                        />
                      </Grid>

                      <Grid container xs={12} style={textfield_avtar_div}>
                        <Grid item xs={12}>
                          <div className={this.props.classes.theme_font} style={avtar_textlabel}>Select your avatar</div>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <div style={avtar_scroolbar_div}>
                              <Grid xs={7}>
                                <div style={scrollmenu}>
                                  {configJSON.avtar_array.map(
                                    (image: any, index: any) => (
                                      <>
                                        <img
                                          src={image.source}
                                          alt="Avatar"
                                          style={avatar}
                                          key={index}
                                          onClick={() => {
                                            if (
                                              this.state.imagemapcallId ===
                                              index
                                            ) {
                                              this.setState({
                                                avtarclick: !this.state
                                                  .avtarclick,
                                                uploadavtar: '',
                                                avtar: image.source,
                                                imagemapcallId: index,
                                              });
                                            } else {
                                              this.setState({
                                                avtarclick: true,
                                                uploadavtar: '',
                                                avtar: image.source,
                                                imagemapcallId: index,
                                              });
                                            }
                                            const file = this.dataURLtoFile(
                                              this.state.avtar,
                                              image.name
                                            );
                                            this.avtarUpload(file);
                                          }}
                                        />
                                        {this.state.imagemapcallId === index &&
                                        this.state.avtarclick ? (
                                          <CheckCircleRoundedIcon
                                            style={{
                                              color: '#ed9e31',
                                              fontSize: 15,
                                              position: 'relative',
                                              cursor: 'pointer',
                                              left: '-5%',
                                            }}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )
                                  )}
                                </div>
                              </Grid>
                              <Grid
                                xs={this.state.uploadavtar.length != 0 ? 1 : 2}
                                style={orGrid}
                                className={`${this.props.classes.theme_font}`}
                              >
                                <div style={{ textAlign: 'center' }}>or</div>
                              </Grid>
                              <Grid xs={3} style={uploadimage_btn_grid} className={this.props.classes.theme_font}>
                                <input
                                  accept="image/*"
                                  style={input}
                                  id="contained-button-file"
                                  type="file"
                                  multiple
                                  onChange={(event: React.ChangeEvent<any>) => {
                                    if (event.target.files[0]) {
                                      const self = this;
                                      const reader = new FileReader();
                                      var file = event.target.files[0];

                                      reader.onload = function(upload) {
                                        self.setState({
                                          avtar: upload.target!.result,
                                          uploadavtar: upload.target!.result,
                                          clickavtar: '',
                                          avtarclick: false,
                                        });
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                    this.handleUpload(event);
                                  }}
                                />
                                <label htmlFor="contained-button-file">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="uploadimage_btn"
                                    component="span"
                                  >
                                    Upload Image
                                  </Button>
                                </label>
                              </Grid>
                              {this.state.uploadavtar.length != 0 && (
                                <Grid xs={1} style={uploadimage_btn_grid} className={`${this.props.classes.theme_font}`}>
                                  <CancelIcon
                                    style={{
                                      color: '#ed9e31',
                                      height: 20,
                                      width: 20,
                                      position: 'absolute',
                                      cursor: 'pointer',
                                      top: '-15px',
                                      right: '-15px',
                                    }}
                                    onClick={() =>
                                      this.setState({ uploadavtar: '' })
                                    }
                                  />
                                  {this.state.uploadavtar.length != 0 && (
                                    <img
                                      src={this.state.uploadavtar}
                                      style={{ height: 50, width: 50 }}
                                    />
                                  )}
                                </Grid>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="delete"
                    className="arrowStyleButtonRight"
                    color="primary"
                    type="submit"
                  >
                    {this.state.loadingSpinner ? (
                      <CircularProgress style={spinnerBar} />
                    ) : (
                      <>
                        Create Account
                        <ArrowForwardRoundedIcon style={{ color: 'white' }} />
                      </>
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>
    );
  }
  // Customizable Area End
}


// Customizable Area Start
export default withStyles(themeCreateStyle)(ParentAccountRegistration);
// Customizable Area End
