// Customizable Area Start
import React from "react";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import Datetime from "react-datetime";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  Avatar,
  Box,
  Button,
  NativeSelect,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  ArrowDropDown,
} from "@material-ui/icons";
import {
  fileupload,
  fileuploaded,
} from "./assets";
import {
  withStyles,
} from "@material-ui/core/styles";
import CreateTaskController, { Props } from "./CreateTaskController.web";
import "./CreateTask.web.css";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { FileDrop } from "react-file-drop";
import ShareModalPopup from "./ShareModalPopup.web";
import moment from "moment";
import ShareModalPopupStudent from "./ShareModalPopupStudent.web";
import AlertModal from "../../../alert/src/AlertModal.web";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class CreateTask extends CreateTaskController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
  handleGradeName = () => {
    this.state.taskCategories && this.state.taskCategories.map((elem: any) => {
      if (elem.id === this.state.category) {
        this.setState({ gradeNameValue: elem.attributes.grade.grade_name, gradeError: false })
      }
      else {
        this.state.taskCategories.map((elem: any) => {
          if (elem.id === this.state.category && elem.attributes.grade.grade_name == null) {
            this.setState({ gradeNameValue: '', gradeError: true })
          }
        })
      }
    })
  }
  changeBackground = (e: any) => {
    e.target.style.background = '#e4e4e4';

  }
  handleChangeBackground = (e: any) => {
    e.target.style.background = '';

  }
  // Customizable Area End
  // Customizable Area Start
  render() {
    const {
      teacherChapters,
      teacherUnits,
      taskCategories,
      teacherClass
    } = this.state;
    const today = moment().subtract(1, "day");
    const disableFutureDt = (current: any) => {
      return current.isAfter(today);
    };
    const BootstrapInput2 = withStyles((theme: any) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: "relative",
        border: this.state.subjecterror ? "1px solid red" : "solid 1px #ebebeb",
        backgroundColor: "#fff",
        padding: "18px 22px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        color: theme.props.textColor,
        fontFamily: theme.props.textFont,
        fontWeight: 600,
        fontSize: 18,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        lineHeight: "21px",
      },
    }))(InputBase);
    const BootstrapInput4 = withStyles((theme: any) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: "relative",
        border: this.state.categoryerror
          ? "1px solid red"
          : "solid 1px #ebebeb",
        backgroundColor: "#fff",
        padding: "18px 22px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        textTransform: "none",
        color: theme.props.textColor,
        fontFamily: theme.props.textFont,
        fontWeight: 600,
        fontSize: 18,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        lineHeight: "21px",
      },
    }))(InputBase);
    const BootstrapInput5 = withStyles((theme: any) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: "relative",
        border: this.state.timeerror ? "1px solid red" : "solid 1px #ebebeb",
        backgroundColor: "#fff",
        padding: "18px 22px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        color: theme.props.textColor,
        fontFamily: theme.props.textFont,
        fontWeight: 600,
        fontSize: 18,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        lineHeight: "21px",
      },
    }))(InputBase);
    const BootstrapInput6 = withStyles((theme: any) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: "relative",
        border: this.state.chapterError ? "1px solid red" : "solid 1px #ebebeb",
        backgroundColor: "#fff",
        padding: "18px 22px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        color: theme.props.textColor,
        fontFamily: theme.props.textFont,
        fontWeight: 600,
        fontSize: 18,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        lineHeight: "21px",
      },
    }))(InputBase);
    const BootstrapInput7 = withStyles((theme: any) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: "relative",
        border: this.state.uniterror ? "1px solid red" : "solid 1px #ebebeb",
        backgroundColor: "#fff",
        padding: "18px 22px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        color: theme.props.textColor,
        fontFamily: theme.props.textFont,
        fontWeight: 600,
        fontSize: 18,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        lineHeight: "21px",
      },
    }))(InputBase);
    const BootstrapInput8 = withStyles((theme: any) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: "relative",
        border: this.state.typeofAssessmentError ? "1px solid red" : "solid 1px #ebebeb",
        backgroundColor: "#fff",
        padding: "18px 22px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        color: theme.props.textColor,
        fontFamily: theme.props.textFont,
        fontWeight: 600,
        fontSize: 18,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        lineHeight: "21px",
      },
    }))(InputBase);
    const BootstrapInput = withStyles((theme: any) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: "relative",
        border: this.state.classerror ? "1px solid red" : "solid 1px #ebebeb",
        backgroundColor: "#fff",
        padding: "18px 22px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        color: theme.props.textColor,
        fontFamily: theme.props.textFont,
        textTransform: "none",
        fontWeight: 600,
        fontSize: 18,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        lineHeight: "21px",
      },
    }))(InputBase);
    const timeArray = [
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
      "24:00",
    ];
    return (
      <>
        <Modal className="createTaskmodal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.props.unitModal}
          onClose={this.modalhandleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={this.props.unitModal} style={{ border: "none" }}>
            <div
              className="addTaskPopup">
              {this.state.showSpinner && (
                <Spinner spinnerModal={this.state.showSpinner} />
              )}
              <form className="h100"
                onSubmit={
                  this.state.editModal ? this.editTask : this.createTask
                }>
                <Box className="taskDetailsBox">
                  <Typography className={`tasktitleMain ${this.props.classes.title_font}`}>
                    {this.state.editModal ? <>Edit Task</> : <>Create Task</>}
                  </Typography>
                </Box>
                <Box className="innerScrollBox">
                  <Grid className="innerPad" container spacing={2}>
                    <Grid item xs={4}>
                      <Box className="taskNameBox">
                        <Typography className={`tasknameTitle ${this.props.classes.subTitle_font}`}>
                          Task Name
                        </Typography>
                        <Box>
                          <TextField className="enterTaskInput"
                            id="standard-basic"
                            type="text"
                            value={this.state.taskName}
                            placeholder="Enter Task name"
                            autoComplete="off"
                            variant="outlined"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = event.target.value.trim();
                              if (value.length === 0) {
                                this.setState({
                                  taskError: true,
                                  taskErrorText: "Task name is required.",
                                });
                              } else {
                                this.setState({
                                  taskError: false,
                                  taskErrorText: "",
                                });
                              }
                              this.setState({ taskName: event.target.value });
                            }}
                            onBlur={() => {
                              if (
                                this.state.taskName === null ||
                                this.state.taskName.length === 0
                              ) {
                                this.setState({
                                  taskError: true,
                                  taskErrorText: "Task name is required.",
                                });
                              } else {
                                this.setState({
                                  taskError: false,
                                  taskErrorText: "",
                                });
                              }
                            }}
                            error={this.state.taskError}
                          />
                          <FormHelperText className="helpText">
                            {this.state.taskErrorText}
                          </FormHelperText>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="dueDateBox">
                        <Typography className={`dueDateBoxTitle ${this.props.classes.subTitle_font}`}>
                          Due Date
                        </Typography>
                        <Box className="fullWidth">
                          <Datetime
                            dateFormat={"DD MMMM, YYYY"}
                            timeFormat={false}
                            strictParsing={true}
                            input={true}
                            isValidDate={disableFutureDt}
                            value={this.state.duedate}
                            className={
                              this.state.duedateerror
                                ? "inputDueDateError"
                                : "inputDueDate"
                            }
                            inputProps={{
                              placeholder: "Select Date",
                              className: "Date_of_birth_calendar_Icon_register",
                              readOnly: true,
                            }}
                            onClose={() => {
                              if (this.state.duedate.length === 0) {
                                this.setState({
                                  duedateerror: true,
                                  duedateerrorText: "Please select Due date.",
                                });
                              }
                            }}
                            closeOnSelect
                            onChange={(date) => {
                              if (
                                moment(date).format("DD/MM/YYYY").length === 0
                              ) {
                                this.setState({
                                  duedateerror: true,
                                  duedateerrorText:
                                    " Please select date of birth",
                                });
                              }
                              if (!moment(this.state.tasktime).isAfter(moment()) && moment(moment().format("DD/MM/YYYY")).isSame(moment(date).format("DD/MM/YYYY"))) {
                                this.setState({
                                  timeerror: true, timeErrorMsg: 'Time should be greater than Current Time.'
                                })
                              }
                              else {
                                this.setState({ timeErrorMsg: '', timeerror: false })
                              }
                              this.setState({
                                duedate: moment(date).format("DD/MM/YYYY"),
                                dueDateCond: date,
                                duedateerror: false,
                                duedateerrorText: "",
                              });
                            }
                            }
                          />

                          {this.state.duedateerror && (
                            <FormHelperText className="helpText">
                              {this.state.duedateerrorText}
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="timeBox">
                        <Box>
                          <Typography className={`timeBoxTitle ${this.props.classes.subTitle_font}`}>
                            Time
                          </Typography>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                              autoOk
                              ampm={false}
                              // inputVariant="outlined"
                              defaultValue={"00:00"}
                              placeholder="--:--"
                              //format="hh:mm"
                              className={`no-underline ${this.props.classes.teacherAssessmentCreateDetail_AssessmentTest_time_duration_input}`}
                              inputProps={{
                                style: { fontSize: '18px', width: '100%', padding: '17px 16px', border: this.state.timeerror ? "1px solid red" : "1px solid #3f526d", borderRadius: 6, fontWeight: 600 },
                              }}
                              value={moment(this.state.tasktime, "HH:mm")}
                              onChange={(event: any) => {
                                if (moment(moment().format("DD/MM/YYYY")).isSame(moment(this.state.dueDateCond).format("DD/MM/YYYY"))) {
                                  if (moment(event).isAfter(moment())) {
                                    this.setState({ timeErrorMsg: '', tasktime: moment(event).format("HH:mm"), timeerror: false })
                                  }
                                  else {
                                    this.setState({
                                      timeerror: true, timeErrorMsg: 'Time should be greater than Current Time.'
                                    })
                                  }
                                }
                                else {
                                  this.setState({ timeErrorMsg: '', tasktime: moment(event).format("HH:mm"), timeerror: false })
                                }
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          {this.state.timeerror && (
                            <FormHelperText className="helpText">
                              Please select time.
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="subjectBox">
                        <Typography className={`timeBoxTitle ${this.props.classes.subTitle_font}`}>
                          Subject
                        </Typography>
                        <Box className="selectSubjectBox">
                        <NativeSelect className={`selectSubjectDropdown ${this.state.subject_id === "" ? "activeColor" : "disableColor"}`}
                            id="select"
                            value={this.state.subject_id}
                            input={<BootstrapInput2 />}
                            IconComponent={ArrowDropDown}
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              this.setState({
                                subject_id: event.target.value,
                                subjecterror: false,
                                sharewithstudent: [],
                                sharewithteacher: [],
                                class_id: ''
                              });
                              this.get_teacher_classes(event.target.value)
                              this.get_teacher_Chapter(event.target.value);
                            }}
                            onBlur={() => {
                              if (
                                this.state.subject_id === null ||
                                this.state.subject_id.length === 0
                              ) {
                                this.setState({ subjecterror: true });
                              } else if (this.state.subject_id.length > 0) {
                                this.setState({ subjecterror: false });
                              } else {
                                this.setState({ subjecterror: false });
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select Subject
                            </option>
                            {this.state.getTeacherSubjects.map(
                              (teacherSubject: any) => (
                                <option className="capitalize" value={teacherSubject.id}>
                                  {teacherSubject.attributes.subject_name}
                                </option>
                              )
                            )}
                          </NativeSelect>
                          {this.state.subjecterror && (
                            <FormHelperText className="helpText">
                              Please select subject.
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="addTitle">
                        <Typography className={`commonTitle ${this.props.classes.subTitle_font}`}>
                          Grade
                        </Typography>
                        <Box className="selectSubjectBox">
                          <NativeSelect className={`selectSubjectDropdown ${this.state.class_id === "" ? "activeColor" : "disableColor"}`}
                            id="select"
                            value={this.state.class_id}
                            input={<BootstrapInput />}
                            IconComponent={ArrowDropDown}
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              this.setState({
                                class_id: event.target.value,
                                classerror: false,
                                sharewithstudent: [],
                                sharewithteacher: [],
                              });
                              this.get_class_teacher(event.target.value);
                              this.get_class_student(event.target.value);
                              this.getTermList(Number(event.target.value));
                            }}
                          >
                            <option value="" disabled>
                              Select Grade
                            </option>
                            {teacherClass.map((getTeacherClass: any) => (
                              <option className="capitalize" value={getTeacherClass.id}>
                                {getTeacherClass.attributes.school_grade_name}
                              </option>
                            ))}
                          </NativeSelect>
                          {this.state.classerror && (
                            <FormHelperText className="helpText">
                              Please select class.
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="categoryBox">
                        <Typography className={`commonTitle ${this.props.classes.subTitle_font}`}>
                          Category
                        </Typography>
                        <Box className="selectSubjectBox">
                        <NativeSelect className={`selectSubjectDropdown ${this.state.category === "" ? "activeColor" : "disableColor"}`} 
                            id="select"
                            value={this.state.category}
                            input={<BootstrapInput4 />}
                            IconComponent={ArrowDropDown}
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              this.setState({
                                category: event.target.value,
                                categoryerror: false,
                              }, () => {
                                if (this.state.typeofAssessment === "graded") {
                                  this.handleGradeName()
                                }

                              });
                            }}
                            onBlur={() => {
                              if (
                                this.state.category === null ||
                                this.state.category.length === 0
                              ) {
                                this.setState({ categoryerror: true });
                              } else if (this.state.category.length > 0) {
                                this.setState({ categoryerror: false });
                              } else {
                                this.setState({ categoryerror: false });
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select Category
                            </option>
                            {taskCategories.map((categorie: any) => (
                              <option value={categorie.id}>
                                {categorie.attributes.name}
                              </option>
                            ))}
                          </NativeSelect>
                          {this.state.categoryerror && (
                            <FormHelperText className="helpText">
                              Please select category.
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="dueDateBox">
                        <Typography 
                          className={`commonTitle ${this.props.classes.subTitle_font}`}>
                          Chapter Name
                        </Typography>
                        <Box className="selectSubjectBox">
                        <NativeSelect className={`selectSubjectDropdown ${this.state.chapter_id === "" ? "activeColor" : "disableColor"}`} 
                            id="select"
                            value={this.state.chapter_id}
                            input={<BootstrapInput6 />}
                            IconComponent={ArrowDropDown}
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              const teacherUnit = teacherChapters.filter(
                                (res: any) => res.id === event.target.value
                              );
                              this.setState({
                                chapter_id: event.target.value,
                                chapterError: false,
                                teacherUnits:
                                  teacherUnit[0].attributes.units.data,
                              });
                            }}
                            onBlur={() => {
                              if (
                                this.state.chapter_id === null ||
                                this.state.chapter_id.length === 0
                              ) {
                                this.setState({ chapterError: true });
                              } else if (this.state.chapter_id.length > 0) {
                                this.setState({ chapterError: false });
                              } else {
                                this.setState({ chapterError: false });
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select Chapter
                            </option>
                            {teacherChapters.map((chapter: any) => (
                              <option className="capitalize" value={chapter.id}>
                                {chapter.attributes.name}
                              </option>
                            ))}
                          </NativeSelect>
                          {this.state.chapterError && (
                            <FormHelperText className="helpText">
                              Please select chapter.
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="dueDateBox">
                        <Typography className={`commonTitle ${this.props.classes.subTitle_font}`}>
                          Unit
                        </Typography>
                        <Box className="selectSubjectBox">
                        <NativeSelect className={`selectSubjectDropdown ${this.state.unit_name === "" ? "activeColor" : "disableColor"}`}
                            id="select"
                            value={this.state.unit_name}
                            input={<BootstrapInput7 />}
                            IconComponent={ArrowDropDown}
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              this.setState({
                                unit_name: event.target.value,
                                uniterror: false,
                              });
                            }}
                            onBlur={() => {
                              if (
                                this.state.unit_name === null ||
                                this.state.unit_name.length === 0
                              ) {
                                this.setState({ uniterror: true });
                              } else if (this.state.unit_name.length > 0) {
                                this.setState({ uniterror: false });
                              } else {
                                this.setState({ uniterror: false });
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select Unit
                            </option>
                            {teacherUnits.map((unit: any) => (
                              <option className="transformNone"
                                value={unit.id}>
                                {unit.attributes.unit_name}
                              </option>
                            ))}
                          </NativeSelect>
                          {this.state.uniterror && (
                            <FormHelperText className="helpText">
                              Please select unit.
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box className="categoryBox">
                        <Typography className={`commonTitle ${this.props.classes.subTitle_font}`}>
                          Type of Assessment
                        </Typography>
                        <NativeSelect className={`selectSubjectDropdown ${this.state.typeofAssessment === "" ? "activeColor" : "disableColor"}`}
                          id="select"
                          value={this.state.typeofAssessment}
                          input={<BootstrapInput8 />}
                          IconComponent={ArrowDropDown}
                          onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            this.setState({
                              typeofAssessment: event.target.value,
                              typeofAssessmentError: false,
                            }, () => {
                              if (this.state.typeofAssessment === "graded") {
                                this.handleGradeName()
                              }
                            });
                          }}
                          onBlur={() => {
                            if (
                              this.state.typeofAssessment === null ||
                              this.state.typeofAssessment.length === 0
                            ) {
                              this.setState({ typeofAssessmentError: true });
                            } else if (this.state.typeofAssessment.length > 0) {
                              this.setState({ typeofAssessmentError: false });
                            } else {
                              this.setState({ typeofAssessmentError: false });
                            }
                          }}
                        >
                          <option value="" disabled>
                            Select Assessment Type
                          </option>
                          <option className="noneTransform"
                            value="graded">
                            Graded
                          </option>
                          <option
                            value="nongraded" className="noneTransform">
                            Non- Graded
                          </option>
                        </NativeSelect>
                        {this.state.typeofAssessmentError && (
                          <FormHelperText className="helpText">
                            Please select Assessment Type.
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="dueDateBox">
                        <Typography className={`timeBoxTitle ${this.props.classes.subTitle_font}`}>
                          Marks
                        </Typography>
                        <Box>
                          <TextField
                            id="standard-basic"
                            type="number"
                            value={this.state.marks}
                            placeholder="Enter Marks"
                            autoComplete="off"
                            className="markTextField"
                            variant="outlined"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = event.target.value.trim();
                              if (value.length === 0) {
                                this.setState({
                                  marksError: true,
                                  marksErrorText: "Marks is required.",
                                });
                              } else {
                                this.setState({
                                  marksError: false,
                                  marksErrorText: "",
                                });
                              }
                              this.setState({ marks: event.target.value });
                            }}
                            onBlur={() => {
                              if (
                                this.state.marks === null ||
                                this.state.marks.length === 0
                              ) {
                                this.setState({
                                  marksError: true,
                                  marksErrorText: "Marks is required.",
                                });
                              } else {
                                this.setState({
                                  marksError: false,
                                  marksErrorText: "",
                                });
                              }
                            }}
                            error={this.state.marksError}
                          />
                          <FormHelperText className="helptext">
                            {this.state.marksErrorText}
                          </FormHelperText>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="subjectBox">
                        <Box>
                          <Typography className={`timeBoxTitle ${this.props.classes.subTitle_font}`}>
                            Term
                          </Typography>
                          <Box>
                          <NativeSelect className={`selectSubjectDropdown ${this.state.selectedTerm === "" ? "activeColor" : "disableColor"} ${this.state.termError ? "redBorder" : "normalBorder"}`} 
                              id="select"
                              value={this.state.selectedTerm}
                              input={<BootstrapInput5 />}
                              IconComponent={ArrowDropDown}
                              onChange={(
                                event: React.ChangeEvent<HTMLSelectElement>
                              ) => {
                                this.setState({
                                  selectedTerm: event.target.value,
                                  termError: false,
                                });
                              }}
                              onBlur={() => {
                                if (
                                  this.state.selectedTerm === ''
                                ) {
                                  this.setState({ termError: true });
                                } else {
                                  this.setState({ termError: false });
                                }
                              }}
                            >
                              <option value="" disabled>
                                Select Term
                              </option>
                              {this.state.termList.map((term: any) => (
                                <React.Fragment key={term.id}>
                                  <option value={term.id}>{term.attributes.title}</option>
                                </React.Fragment>
                              ))}
                            </NativeSelect>
                            {this.state.termError && (
                              <FormHelperText className="helpText">
                                Please select term.
                              </FormHelperText>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    {
                      this.state.typeofAssessment === "graded" ?
                        <Grid item xs={4}>
                          <Box className="timeBox">
                            <Typography className={`timeBoxTitle ${this.props.classes.subTitle_font}`}>
                              Grade Scale
                            </Typography>
                            <Box>
                              <TextField
                                disabled={true}
                                className="inp markTextField notAllow"
                                id="standard-basic"
                                value={this.state.gradeNameValue}
                                placeholder="First select any Category"
                                autoComplete="off"
                                onMouseOver={this.changeBackground}
                                onMouseOut={this.handleChangeBackground}
                                error={this.state.gradeError}
                                variant="outlined"
                              />
                              {this.state.gradeError && (
                                <FormHelperText className="helpText">
                                  Please assign grade to selected category from manage category.
                                </FormHelperText>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                        :
                        ""
                    }
                    <Grid item xs={12}>
                      <Box className="timeBox">
                        <Typography className={`commonTitle ${this.props.classes.subTitle_font}`}>
                          Upload file here
                        </Typography>
                        {this.state.fileUploadDetail?.length != 0 ? (
                          <>
                            <FileDrop
                              onDrop={(files: any, event: any) => {
                                window.addEventListener(
                                  "drop",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  false
                                );
                                this.setState({ fileName: files[0].name });
                                this.handleUpload(files);
                              }}
                            >
                              <Box className={`browse_box ${this.props.classes.border_Color}`}>
                                <Box>
                                  <img className="fileUploadIcon"
                                    src={fileuploaded}
                                  />
                                </Box>
                                <Box>
                                  <Typography className={`fileUploadTitle ${this.props.classes.bodyText_font}`}>
                                    File Uploaded
                                  </Typography>
                                </Box>
                                <Box className="textStart">
                                  <Typography className={`fileNameTitle ${this.props.classes.bodyText_font}`}>
                                    File Name
                                  </Typography>
                                  <TextField
                                    id="standard-basic"
                                    type="text"
                                    className="fileTextField"
                                    value={this.state.fileName}
                                    disabled={true}
                                    variant="outlined"
                                  />
                                </Box>
                                <Box>
                                  <input className="none"
                                    id="contained-button-file"
                                    type="file"
                                    onChange={(
                                      event: React.ChangeEvent<any>
                                    ) => {
                                      this.setState({
                                        fileName: event.target.files[0].name,
                                      });
                                      if (this.state.editModal && this.state.fileUploadDetail[0]?.id && this.state.fileUploadDetail[0]?.id !== '') {
                                        this.setState({
                                          deletedFileId: this.state.fileUploadDetail[0]?.id,
                                        });
                                      }
                                      this.handleUpload(event.target.files);
                                    }}
                                  />
                                  <Box className="updateBox">
                                    <label htmlFor="contained-button-file">
                                      <Button
                                        variant="contained"
                                        component="span"
                                        className={`updateBoxButton ${this.props.classes.button_color}`}>
                                        {!this.state.editModal ? (
                                          <>ReUpdate</>
                                        ) : this.props.task.attributes.file
                                          .length != 0 ? (
                                          <>ReUpdate</>
                                        ) : (
                                          <>Update</>
                                        )}
                                      </Button>
                                    </label>
                                    <Button className="updateBoxButton removeBtn"
                                      variant="contained"
                                      onClick={() => {
                                        if (this.state.editModal && this.state.fileUploadDetail[0]?.id && this.state.fileUploadDetail[0]?.id !== '') {
                                          this.setState({
                                            deletedFileId: this.state.fileUploadDetail[0]?.id,
                                          }, () => this.setState({ fileUploadDetail: [], sihnIdData: '' }));
                                        }
                                        else {
                                          this.setState({ fileUploadDetail: [], sihnIdData: '' })
                                        }
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </FileDrop>
                          </>
                        ) : (
                          <>
                            <FileDrop
                              onDrop={(files: any, event: any) => {
                                window.addEventListener(
                                  "drop",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  false
                                );
                                this.setState({ fileName: files[0].name });
                                this.handleUpload(files);
                              }}
                            >
                              <Box className={`uploadBox ${this.props.classes.createTask_classAndSection_dashed_border}`}>
                                <Box>
                                  <Avatar className="uploadBoxIcon"
                                    src={fileupload}
                                  />
                                </Box>
                                <Box className="dragDrop">
                                  <Typography className={`dragDropTitle ${this.props.classes.bodyText_font}`}>
                                    Drag and drop file here or
                                  </Typography>
                                  <input className="none"
                                    id="contained-button-file"
                                    type="file"
                                    onChange={(
                                      event: React.ChangeEvent<any>
                                    ) => {
                                      this.setState({
                                        fileName: event.target.files[0].name,
                                      });
                                      this.handleUpload(event.target.files);
                                    }}
                                  />
                                  <label htmlFor="contained-button-file">
                                    <Button className={`browseBtn ${this.props.classes.createTask_classAndSection_browse_button_color}`}
                                      component="span">
                                      browse.
                                    </Button>
                                  </label>
                                </Box>
                                <Box>
                                  <Typography className={`supportFormats ${this.props.classes.bodyText_font}`}>
                                    supported formats: .png, .jpeg, .jpg, .gif, .pdf, MS Excel google sheets
                                  </Typography>
                                </Box>
                              </Box>
                            </FileDrop>
                          </>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className="noteBox">
                        <Typography className={`noteBoxTitle ${this.props.classes.subTitle_font}`}>
                          Note
                        </Typography>
                        <TextField
                          id="standard-basic"
                          type="text"
                          className="fileTextField"
                          variant="outlined"
                          multiline
                          rows={3}
                          value={this.state.unitDescription}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value;
                            this.setState({ unitDescription: value });
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className="shareWithBox">
                        <Typography className={`shareWithTitle ${this.props.classes.subTitle_font}`}>
                          Share With
                        </Typography>
                        <Box className="teachersBox">
                          <Button
                            variant="contained"
                            className={`teachersBoxButton ${this.state.sharewithteacher.length !== 0 ? this.props.classes.button_color : this.props.classes.button_color_disabled} ${this.state.class_id === '' ? "halfOpacity" : "fullOpacity"}`}
                            disabled={this.state.class_id !== '' ? false : true}
                            onClick={() => {
                              this.setState({ userTypeIsTeacher: true }, () => {
                                this.setState({ openShareModal: true })
                              })
                            }}
                          >
                            Teacher(s)
                          </Button>
                          <Button
                            variant="contained"
                            className={`studentsButton ${this.state.sharewithstudent.length !== 0 ? this.props.classes.button_color : this.props.classes.button_color_disabled} ${this.state.class_id === '' ? "halfOpacity" : "fullOpacity"}`}
                            disabled={this.state.class_id !== '' ? false : true}
                            onClick={() => {
                              this.setState({ userTypeIsTeacher: false }, () => {
                                this.setState({ openShareModal: true })
                              })
                            }}
                          >
                            Student(s)
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="updateBtnbox">
                  <Button variant="contained" type="submit"
                    className={`updateBtn ${this.props.classes.button_color}`}>
                    {this.state.editModal ? <>Update</> : <>Add</>}
                  </Button>
                  <Button className="updateCancelBtn"
                    variant="contained"
                    onClick={this.modalhandleClose}>
                    Cancel
                  </Button>
                </Box>
              </form>
            </div>
          </Fade>
        </Modal>
        {this.state.openShareModal && this.state.userTypeIsTeacher &&
          <ShareModalPopup
            openModal={this.state.openShareModal}
            closeModalFun={this.handleCloseSharePopup}
            usersList={this.state.classTeacherList}
            isTeacher={true}
            filterUser={this.state.sharewithteacher}
            saveSelectedUsers={(list: any) => this.saveSelectedUsersId(list)}
          />}
        {this.state.openShareModal && !this.state.userTypeIsTeacher &&
          <ShareModalPopupStudent
            openModal={this.state.openShareModal}
            closeModalFun={this.handleCloseSharePopup}
            usersList={this.state.classStudentList}
            isTeacher={false}
            filterUser={this.state.sharewithstudent}
            saveSelectedUsers={(list: any) => this.saveSelectedUsersId(list)}
          />}
        <AlertModal
          alertModal={this.state.alertModal}
          isTeacher={true}
          onCloseModal={() => {
            this.setState({ alertModal: false });
          }}
          alertTitle="Alert!"
          alertMessage={this.state.alertMessage}
          buttonText="OK"
        />
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(CreateTask);
// Customizable Area End