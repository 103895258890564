// Customizable Area Start
import React from 'react';
import 'react-phone-input-2/lib/style.css';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import 'react-datetime/css/react-datetime.css';
import './TeacherAccountRegistration.css';
import SubjectsController, { Props } from './SubjectsController.web';
import OnBoardingSideBar from './OnBoardingSideBar.web';
import Spinner from '../../shared/SideBar/src/Spinner';
import AlertModal from '../../alert/src/AlertModal.web';
import HOCAuth from '../../shared/Header/src/HOCAuth.web';
export const configJSON = require('./config');
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
import { withStyles } from '@material-ui/core';
// Customizable Area End

// Customizable Area Start
const textlabel_welcomeText = {
  fontSize: '35.1px',
  fontWeight: 'bold' as 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
} as const;
const textlabel_Header = {
  fontSize: '24px',
  fontWeight: 'bold' as 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#fff',
} as const;

const forrmInput_textfield = {
  width: '100%',
  borderRadius: '6px',
  textAlign: 'start',
  color: '#03014c',
  fontSize: '15.8px',
} as const;
// Customizable Area End

export class Subjects extends SubjectsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    return (
      <>
        <Grid container>
          {this.state.loadingSpinner && (
            <Spinner spinnerModal={this.state.loadingSpinner} />
          )}

          <Grid item xs={12} sm={3} md={3} lg={3}>
            <OnBoardingSideBar activePage={4} />
          </Grid>
          <Grid
            item
            sm={9}
            md={9}
            lg={9}
            xs={12}
            // className="grade_Level_responsiveClass"
          >
            <Grid container>
              <Grid item xs={12} style={{ padding: '2rem 20px 0 20px' }}>
                <div style={textlabel_welcomeText} className={`${this.props.classes.theme_font}`}>Subjects</div>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '2rem',
                  padding: '1rem 1.95rem 1rem 1.25rem',
                  backgroundColor: '#9584fd',
                }}
              >
                <div style={textlabel_Header} className={`${this.props.classes.theme_font}`}>{this.state.boardName}</div>
              </Grid>
              <Grid item xs={12}>
                <Grid container style={{ padding: '1.5rem 2.6875rem' }}>
                  <Grid item sm={7} md={7} lg={7} xs={12}>
                    {this.state.subjectsDetails.map(
                      (_data: any, index: any) => {
                        return (
                          <Grid
                            container
                            spacing={0}
                            style={{ alignItems: 'center', marginTop: '23px' }}
                            key={index}
                          >
                            <Grid item xs={1} style={{ margin: '0 10px 0 0' }}>
                              <Checkbox
                                checked={_data.selectedSubject}
                                onChange={(event: any) => {
                                  let arr = this.state.subjectsDetails;
                                  arr[index].selectedSubject =
                                    event.target.checked;

                                  this.setState({ subjectsDetails: arr });
                                  console.log(
                                    this.state.subjectsDetails[index]
                                  );
                                }}
                                style={{ color: '#9584fd' }}
                              />
                            </Grid>
                            <Grid item xs={10}>
                              <TextField
                                id="standard-basic"
                                placeholder="Enter Subject Name"
                                style={forrmInput_textfield}
                                className={`${this.props.classes.theme_font}`}
                                variant="outlined"
                                value={_data.subjectName}
                                onChange={(event: any) => {
                                  let arr = this.state.subjectsDetails;
                                  arr[index].subjectName = event.target.value;
                                  arr[index].error = false;
                                  this.setState({ subjectsDetails: arr });
                                  console.log(
                                    this.state.subjectsDetails[index]
                                  );
                                }}
                                error={_data.error}
                                helperText={
                                  _data.error ? 'Subject name is required.' : ''
                                }
                                inputProps={{
                                  style: {
                                    fontFamily: 'Open sans',
                                    fontSize: '1rem',
                                    fontWeight: 'bold',
                                    color: '#03014c',
                                    padding: '1.15rem 1rem 1.15rem 1rem',
                                    textTransform: 'uppercase',
                                  },
                                  className: 'onboarding_text_fields',
                                }}
                                InputProps={{
                                  style: {
                                    fontSize: '1rem',
                                    color: '#03014c',
                                    fontFamily: 'OpenSans',
                                    opacity: 0.5,
                                    textTransform: 'uppercase',
                                    backgroundColor: '#f1f1f1',
                                    fontWeight: 'bold',
                                  },
                                  className: 'onboarding_text_fields',
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '75px',
                }}
              >
                <Button
                  style={{
                    border: 'solid 1px #5542f6',
                    borderRadius: '7px',
                    color: '#4a4a4a',
                    textTransform: 'capitalize',
                    fontSize: 16,
                    padding: '10px 39px 7px 39.8px',
                  }}
                  onClick={() => {
                    let subjectArr = this.state.subjectsDetails;
                    const arr = {
                      id: this.state.subjectsDetails.length + 1,
                      selectedSubject: false,
                      subjectName: '',
                      error: false,
                      type: 'new',
                    };
                    subjectArr.push(arr);
                    this.setState({ subjectsDetails: subjectArr });
                  }}
                >
                  Add Subject
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                margin: '3.125rem 0px 10px 0px ',
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '44px',
              }}
            >
              <Button
                style={{
                  backgroundImage:
                    'linear-gradient(289deg, #aca2ff 119%, #776be8)',
                  borderRadius: '8px',
                  color: '#fff',
                  width: '17%',
                  padding: '.85rem',
                  textTransform: 'capitalize',
                  fontSize: '1rem',
                }}
                onClick={(event: any) => this.goNext(event)}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <AlertModal
          alertModal={this.state.alertModal}
          isTeacher={false}
          onCloseModal={() => {
            this.setState({ alertModal: false });
          }}
          alertTitle="Alert!"
          alertMessage={'Please select subject.'}
          buttonText="OK"
        />
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(Subjects, 'SuperAdminAccount'));
// Customizable Area End
