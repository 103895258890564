// Customizable Area Start
import React from "react";
import {
    Grid, Button, TableContainer, TableHead, TableRow, Table, TableBody, TableCell, TextField,
    InputBase, NativeSelect
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ScholasticAreaController, {
    Props,
} from "./ScholasticAreaController.web";
import { addIcon, imgBackArrow, editPurpleIcon } from "../assets";
import { withStyles } from '@material-ui/core/styles';
import Spinner from "../../../shared/SideBar/src/Spinner";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import './AdminReportCard.web.css';
import PopoverComponent from './PopoverComponent.web';
import { imageDropdown } from "../../../AssessmentTest/src/assets";

// Customizable Area End
// Customizable Area Start
const form_input_textfield = {
    width: "100%",
    borderRadius: "8px",
    textAlign: "start",
    backgroundColor: '#f9f8fd',
} as const;
const BootstrapInput1 = withStyles(() => ({
    input: {
        width: "100%",
        textAlign: "start",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "19px",
        padding: "16px"
    },
}))(InputBase);
// Customizable Area End
// Customizable Area Start
class ScholasticArea extends ScholasticAreaController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { addedRowItems, addedColumnItems, anchorEl, columnName, markingTypeList, typeOfMarking,
            anchorElRowName, subjectName, termList, totalMarks, subColumnCount } = this.state;

        const popoverContentAddColumn = () => {
            return (
                <Grid container spacing={4} style={{ display: "flex", flexDirection: "column" }}>
                    <Grid item xs={12} className={`${this.props.classes.title_font} add-column-text`}>
                        Add column
                    </Grid>

                    <Grid item xs={12}>
                        <div className={`${this.props.classes.subTitle_font} name-text`}>
                            Name or Title
                        </div>
                        <TextField
                            id="standard-basic"
                            placeholder="Eg. T1, Test 1, Weekly Test"
                            style={form_input_textfield}
                            onChange={this.onChangeColumnName}
                            value={columnName}
                            className={`no-underline ${this.props.classes.bodyText_font}`}
                            InputProps={{ className: "report-text-field" }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <div className={`${this.props.classes.subTitle_font} name-text`}>
                            Select type of marking
                        </div>
                        <NativeSelect
                            id="select"
                            placeholder="Eg. Marks or Grade"
                            style={form_input_textfield}
                            variant="outlined"
                            IconComponent={ArrowDropDownRoundedIcon}
                            value={typeOfMarking}
                            onChange={this.onChangeTypeOfMarking}
                            input={<BootstrapInput1 />}
                            className={`${this.props.classes.bodyText_font} dropdown-report`}>
                            <option style={{ color: "red" }} value="" disabled>Eg. Marks or Grade</option>
                            {markingTypeList?.map((item: any) =>
                                <option value={item.id} key={item.id}>{item?.attributes?.name}</option>
                            )}
                        </NativeSelect>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={`${this.props.classes.subTitle_font} name-text`}>
                            Total no. of marks
                        </div>
                        <TextField
                            id="standard-basic"
                            placeholder=" Eg. 10, 20, 50"
                            style={form_input_textfield}
                            onChange={this.onChangeTotalMarks}
                            value={totalMarks}
                            className={`no-underline ${this.props.classes.bodyText_font}`}
                            InputProps={{ className: "report-text-field" }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button className={`${this.props.classes.btn_border} add-sub-column-btn`} onClick={this.addSubColumn}>
                            Add sub column
                        </Button>
                        {subColumnCount > 0 && <div>{subColumnCount}</div>}
                    </Grid>
                    <Grid item xs={12} className="report-bottom-btn-div">
                        <Button className={`${this.props.classes.button_color} ${this.props.classes.bodyText_font} save-column-btn`}
                            onClick={() => this.saveColumnData()} disabled={this.checkSaveColumnBtnDisabled()}
                            style={{ opacity: this.checkSaveColumnBtnDisabled() ? 0.5 : 1 }}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            );
        }

        const popoverContentRowName = () => {
            return (
                <Grid container spacing={4} style={{ display: "flex", flexDirection: "column" }}>
                    <Grid item xs={12} className={`${this.props.classes.title_font} add-column-text`}>
                        Edit row name
                    </Grid>

                    <Grid item xs={12}>
                        <div className={`${this.props.classes.subTitle_font} name-text`}>
                            Name
                        </div>
                        <TextField
                            id="standard-basic"
                            placeholder="Subject Name 1"
                            style={form_input_textfield}
                            onChange={this.onChangeSubjectName}
                            value={subjectName}
                            className={`no-underline ${this.props.classes.bodyText_font}`}
                            InputProps={{ className: "report-text-field" }}
                        />
                    </Grid>

                    <Grid item xs={12} className="report-bottom-btn-div">
                        <Button className={`${this.props.classes.button_color} ${this.props.classes.bodyText_font} save-column-btn`}
                            onClick={() => this.saveSubjectName()}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            );
        }
        return (
            <>
                {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}

                <div className={`teacher_assessment_box`}>
                    <div className="flex-space-between">
                        <span className={`${this.props.classes.bodyText_font} back-text-report`} onClick={this.handleBack}>
                            <img src={imgBackArrow} className="back-arrow-img"></img>
                            Back
                        </span>
                        <div className="co-scholastic-right-btn-div">
                            <Button className={`${this.props.classes.btn_border} module-edit-details-btn mr-23`}>
                                Preview
                            </Button>
                            <Button className={`${this.props.classes.btn_border} module-edit-details-btn`}>
                                Save
                            </Button>
                        </div>

                    </div>

                    <div className={`${this.props.classes.bodyText_font} text-label-part-2`}>
                        Part -1 Scholastic Area<span className={`${this.props.classes.bodyText_font} text-style-bold`}>(8 point scale)</span>
                    </div>

                    {termList.map((term: any, index: number) =>
                        <Grid key={index} container direction="row" style={{ width: "100%", marginBottom: "2%" }}>
                            <Grid item xs={12}>
                                <div style={{ fontWeight: 600, backgroundColor: "#ded9ff", padding: "10px", borderTopLeftRadius: "6px", borderTopRightRadius: "6px", display: "flex", justifyContent: "center", alignItems: "center" }}>{term.term}</div>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table style={{ width: "fit-content", borderLeft: "1px solid rgba(224, 224, 224, 1)", borderTop: "1px solid rgba(224, 224, 224, 1)" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell rowSpan={2} style={{ fontWeight: 600, backgroundColor: "#f9f8fd", maxWidth: 206, minWidth: 206, borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                                                    <div className="subject-cell">
                                                        Subject
                                                    </div>
                                                </TableCell>
                                                {addedColumnItems?.map((columnItem: any, indexCol: number) => (
                                                    <TableCell key={`${indexCol}-${columnItem.name}`} colSpan={columnItem?.subColumnArray?.length} style={{ fontWeight: 600, backgroundColor: "#f9f8fd", maxWidth: 206, minWidth: 206, borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                                                        <div className="subject-cell">
                                                            {columnItem?.name}
                                                            <img src={editPurpleIcon} className="edit-icon"></img>
                                                        </div>
                                                        <div className="subject-cell">
                                                            ({columnItem?.totalMarks})
                                                        </div>
                                                    </TableCell>
                                                ))}

                                                <TableCell style={{ fontWeight: 600, backgroundColor: "#f9f8fd", maxWidth: 72, borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                                                    <img src={addIcon} alt="add" className="plus-icon" onClick={(e: any) => this.addTableColumn(e)}></img>
                                                </TableCell>
                                            </TableRow>
                                            {this.state.totalSubColumn?.length > 0 &&
                                                <TableRow>
                                                    {this.state.totalSubColumn?.map((item: any) => (
                                                        <TableCell style={{ fontWeight: 600, backgroundColor: "#f9f8fd", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{item.name}</TableCell>
                                                    ))}
                                                </TableRow>
                                            }
                                        </TableHead>
                                        <TableBody>
                                            {addedRowItems?.map((rowItem: any, indexRow: number) => (
                                                <TableRow key={indexRow} style={{ height: 63 }}>
                                                    <TableCell style={{ fontWeight: 600, backgroundColor: "#f9f8fd", maxWidth: 206, minWidth: 206, borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                                                        <div className="subject-cell">
                                                            {rowItem?.name}
                                                            <img src={editPurpleIcon} className="edit-icon" onClick={(e: any) => this.openEditRowNamePopover(e, rowItem)}></img>
                                                        </div>
                                                    </TableCell>
                                                    {addedColumnItems?.map((columnItem: any, indexCol: number) => (
                                                        <TableCell colSpan={columnItem?.subColumnArray?.length} key={`${indexCol}-${rowItem.name}`} style={{ fontWeight: 600, maxWidth: 206, minWidth: 206, borderRight: "1px solid rgba(224, 224, 224, 1)" }}>

                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 600, backgroundColor: "#f9f8fd", borderRadius: "6px", maxWidth: 206, minWidth: 206, borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                                                    <div className="subject-cell">
                                                        <img src={addIcon} alt="add" className="plus-icon" onClick={() => this.addTableRow()}></img>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>)}
                </div>

                <PopoverComponent
                    anchorEl={anchorEl}
                    onClose={() => {
                        this.setState({ anchorEl: null })
                    }}
                    popoverContent={popoverContentAddColumn}
                />

                <PopoverComponent
                    anchorEl={anchorElRowName}
                    onClose={() => {
                        this.setState({ anchorElRowName: null })
                    }}
                    popoverContent={popoverContentRowName}
                />
            </>
        );
    }
}

export const reportCardInfoStyles = {
    notchPopOver: {
        position: "relative",
        "&::before": {
            backgroundColor: "white",
            content: '""',
            display: "block",
            position: "absolute",
            width: 25,
            height: 25,
            top: 15,
            transform: "rotate(45deg)",
            left: -7,
            boxShadow: "0 2px 0px 0 rgba(98, 98, 98, 0.5)",
        }
    }
};

export const themeCreateStyle = ((theme: any) => ({
    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: theme.props.buttonColor,
        }
    },
    image_Border_Color: {
        border: `3px solid ${theme.props.buttonColor}`
    },
    btn_border: {
        border: `1px solid ${theme.props.buttonColor}`,
        color: theme.props.buttonColor
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
}))

const combineStyles = (...styles: any) => {
    return function CombineStyles(theme: any) {
        const outStyles = styles.map((arg: any) => {
            // Apply the "theme" object for style functions.
            if (typeof arg === 'function') {
                return arg(theme);
            }
            // Objects need no change.
            return arg;
        });

        return outStyles.reduce((acc: any, val: any) => Object.assign(acc, val));
    };
}

const combinedStyle = combineStyles(themeCreateStyle, reportCardInfoStyles);
export default withStyles(combinedStyle)(HOCAuth(ScholasticArea, "AdminAccount"));
// Customizable Area End
