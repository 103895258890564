// Customizable Area Start
import React from "react";
import { Grid, Modal, Typography } from "@material-ui/core";
import "./TeacherAssessment.web.css";
import {
  writing1,
  fillInTheBlanksSVG,
  matchSVG,
  oneWordSVG,
  pictionarySVG,
  hotspotSVG,
  flashCardSVG,
  mcqSVG,
  trueFalseSVG,
  sortListSVG,
  textRatingScaleSVG,
  percentageRatingScaleSVG,
  graphicRatingScaleSVG,
  numericRatingScaleSVG,
  labelImageDropDownSVG,
  labelImageTextSVG,
  labelImageDragAndDropSVG,
  twoByTwoGridSVG,
  graphSVG,
  lineChartSVG,
  longAnswerSVG,
  shortAnswerSVG,
  audioSVG,
  videoSVG
} from "./assets";
// Customizable Area End

// Customizable Area Start
interface Props {
  openSelectQuestionTypeModal: boolean;
  onCloseModal: any;
  onSelectQuestionType: any;
  questionsTypeList: any;
  theme?: any;
}
// Customizable Area End

export const SelectQuestionTypeModal = ({
  // Customizable Area Start
  openSelectQuestionTypeModal,
  onCloseModal,
  onSelectQuestionType,
  questionsTypeList,
  
  theme,
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  const displayFocused = (name: any, id: number) => {
    const element = document.getElementById(`focus-${name}-id-${id}`);
    if (element) {
      element.style.border = "solid 2px #3a60d7";
    }
  };

  const displayNormal = (name: any, id: number) => {
    const element = document.getElementById(`focus-${name}-id-${id}`);
    if (element) {
      element.style.border = "solid 2px transparent";
    }
  };

  const getImage = (questionType: string) => {
    switch (questionType) {
      case "mcq":
        return mcqSVG;
      case "true false":
        return trueFalseSVG;
      case "fill in blank":
        return fillInTheBlanksSVG;
      case "match":
        return matchSVG;
      case "one word":
        return oneWordSVG;
      case "pictionary":
        return pictionarySVG;
      case "long answer":
        return longAnswerSVG;
      case "short answer":
        return shortAnswerSVG;
      case "text_rating_scale":
        return textRatingScaleSVG;
      case "numeric_rating_scale":
        return numericRatingScaleSVG;
      case "percentage_rating_scale":
        return percentageRatingScaleSVG;
      case "graphic_rating_scale":
        return graphicRatingScaleSVG;
      case "audio":
        return audioSVG;
      case "video":
        return videoSVG;
      case "sort_list":
        return sortListSVG;
      case "hotspot":
        return hotspotSVG;
      case "graph_plotting":
        return graphSVG;
      case "line_chart":
        return lineChartSVG;
      case "flash_card":
        return flashCardSVG;  
      case "label_image_with_dropdown":
        return labelImageDropDownSVG; 
      case "label_image_with_text":
        return labelImageTextSVG; 
      case "label_image_with_drag_and_drop":
        return labelImageDragAndDropSVG;   
      case "2x2_grid":
        return twoByTwoGridSVG;  
      default:
        return writing1;
    }
  };

  return (
    <>
      <Modal 
        className="SelectQuestionTypeModal"
        open={openSelectQuestionTypeModal}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid className="modal-width-dynamic select-question-modal">
          <Typography
            className={`font-22 ${theme.title_font} SelectQuestionTypeText`}
          >
            Select the Question Type
          </Typography>

          {questionsTypeList?.map((item: any) => (
            <React.Fragment key={item.id}>
              <Typography
                className={`font-20 ${theme.subTitle_font} QuestionTypeName`}
              >
                {(item?.attributes?.name[0].toUpperCase() +
                  item?.attributes?.name.slice(1)).replaceAll("_", " ")}
              </Typography>
              <Grid container spacing={3}>
                {item?.attributes?.question_sub_categories?.map(
                  (questionData: any) => (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      lg={2}
                      key={questionData.id}
                    >
                      <div 
                        className="QuestionTypeDiv"
                        id={`focus-${item?.attributes?.name}-id-${questionData.id}`}
                        onMouseEnter={() =>
                          displayFocused(
                            item?.attributes?.name,
                            questionData.id
                          )
                        }
                        onMouseLeave={() =>
                          displayNormal(item?.attributes?.name, questionData.id)
                        }
                        onClick={() => {
                          onSelectQuestionType(questionData);
                        }}
                      >
                        <img
                          src={getImage(questionData?.name.toLowerCase())}
                          className="small-que-type-img"
                        ></img>
                        <Typography
                          className={`font-16 ${theme.bodyText_font} QuestionName`}
                        >
                          {questionData.name.replaceAll("_", " ")}
                        </Typography>
                      </div>
                    </Grid>
                  )
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Modal>
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
export default SelectQuestionTypeModal;
// Customizable Area End
