import React from "react";
// Customizable Area Start
import {
    Button,
    Grid,
} from "@material-ui/core";
import { plusIcon } from "../assets";
import { Search } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import AdminBadgesController, { Props } from "./AdminBadgesController.web";
import './AdminBadges.web.css';
import AssignBadgesModal from "./AssignBadgesModal.web";
import TeacherAssignBadgesModal from "./TeacherAssignBadgesModal.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";

// Customizable Area End
// Customizable Area Start
// Customizable Area End

export class AdminAssignBadges extends AdminBadgesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
        // Customizable Area Start
        // Customizable Area End
    render() {
        // Customizable Area Start
        const { adminBadges, userData,
            gradesList, selectedGrade,
            queryTerm, selectAll,
            memberNumber, selectedTemplate,
            selectedCategoryId, queryTermBadge,
            selectedBadgesList, classStudentsList,
            teacherClassDetails, selectedClassId } = this.state;

            const filteredData = selectedBadgesList?.filter((item: any) => {
                if (queryTermBadge === '') {
                    return item;
                } else if (item?.name && (item?.name.toLowerCase().includes(queryTermBadge.toLowerCase()))) {
                    return item;
                }
            })

        return (
            <>
                {this.state.showSpinner && <Spinner spinnerModal={this.state.showSpinner} />}
                <Grid container>
                    <Grid item xs={12} className="badges-top-grid">
                        <span className={`${this.props.classes.heading_font} create-new-badges-text`}>Create New Badge</span>
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={12} sm={5} md={4}>
                        <div className="badges-templates-div">
                            <div className="search-badge"

                            >
                                <Search className="AdminBadgeSearch" />

                                <input
                                    placeholder="Search Badge Templates"
                                    className="AdminBadgeSearchInput"
                                    onChange={this.onChangeSearchBadge}
                                />
                            </div>

                            <div className="AdminBadgeItemDiv">
                                {Object.keys(adminBadges)?.map((badgeItem: any, index: number) => (
                                    <div className={selectedCategoryId === badgeItem ? "selected-category-rectangle" : "category-rectangle"} key={index} onClick={() => this.chooseCategory(badgeItem)} data-test-id={`badge-category-${index}`}>
                                        <span className="category-name">{badgeItem}</span>
                                    </div>
                                ))}
                            </div>

                            <Grid container className="badges-list-view">
                                <Grid item xs={6} className="badge-box">
                                    <div className={`badge-template-card`} onClick={this.addNewBadge}>
                                        <img src={plusIcon} className="badge-img-template" alt="badge"></img>
                                        <span className={`${this.props.classes.title_font} badges-text-assign`}>Add New</span>
                                    </div>
                                </Grid>
                                {filteredData?.map((badge: any) => (
                                    <Grid item xs={6} key={badge?.id} className="badge-box">
                                        <div className={Number(selectedTemplate?.id) === Number(badge?.id) ? `badge-template-card selected-template-card` : `badge-template-card`}
                                            onClick={() => this.selectBadgeTemplate(badge)} data-test-id={`badge-template-${badge.id}`}>
                                            <img src={badge?.badge_attachment} className="badge-img-template" alt="badge"></img>
                                            <span className={`${this.props.classes.title_font} badges-text-assign ellipsis-text`}>{badge?.name}</span>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid className="rightside-badge" item xs={12} sm={7} md={8}>
                        {selectedTemplate ?
                            <>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} sm={6} className="badge-detail-field">
                                        <span className={`${this.props.classes.title_font} badge-detail-title`}>
                                            Badge Name*
                                        </span>
                                        <div className={`${this.props.classes.subTitle_font} badge-detail-view`}>{selectedTemplate?.attributes?.name}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="badge-detail-field">
                                        <span className={`${this.props.classes.title_font} badge-detail-title`}>
                                            Badge Category*
                                        </span>
                                        <div className={`${this.props.classes.subTitle_font} badge-detail-view`}>{selectedTemplate?.attributes?.category}</div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} className="badge-detail-field">
                                        <span className={`${this.props.classes.title_font} badge-detail-title`}>
                                            Description
                                        </span>
                                        <div className={`${this.props.classes.subTitle_font} badge-detail-view`}>{selectedTemplate?.attributes?.description}</div>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} className="badge-detail-field">
                                        <div className="badge-img-rectangle">
                                            <img src={selectedTemplate?.attributes?.badge_attachment} alt="image" className="selected-badge-img"></img>
                                            <span className={`${this.props.classes.heading_font} selected-badge-name`}>{selectedTemplate?.attributes?.name}</span>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className="bottom-assign-div">
                                    <Button className={`${this.props.classes.button_color} assign-badge-btn`}
                                        onClick={this.modalHandleOpen}>Assign Badge</Button>
                                </div>
                            </> :
                            <>
                                {filteredData?.length > 0 ?
                                    <span className={`${this.props.classes.heading_font} no-record-text`}>
                                        Please select any badge to see details.
                                    </span>
                                    : <span className={`${this.props.classes.heading_font} no-record-text`}>
                                        No record(s) found.
                                    </span>}
                            </>
                        }
                    </Grid>
                </Grid>

                <AssignBadgesModal
                    openAssignBadgeModal={this.state.openAssignBadgeModal}
                    modalHandleClose={this.modalHandleClose}
                    userData={userData}
                    gradesList={gradesList}
                    selectedGrade={selectedGrade}
                    queryTerm={queryTerm}
                    selectAll={selectAll}
                    memberNumber={memberNumber}
                    isSelected={this.isSelected}
                    handleCheckboxClick={this.handleCheckboxClick}
                    onChangeRadioGroup={this.onChangeRadioGroup}
                    handleGradeChange={this.handleGradeChange}
                    handleSearchChange={this.handleSearchChangeAssignBadges}
                    onChangeSelectAll={this.onChangeSelectAll}
                    onChangeCheckBox={this.onChangeCheckBox}
                    assignBadgeClick={this.assignBadgeClick}
                />
                <TeacherAssignBadgesModal
                    openAssignBadgeModal={this.state.openTeacherAssignBadgeModal}
                    modalHandleClose={this.modalHandleClose}
                    userData={classStudentsList}
                    teacherClassDetails={teacherClassDetails}
                    selectedClass={selectedClassId}
                    queryTerm={queryTerm}
                    selectAll={selectAll}
                    memberNumber={memberNumber}
                    isSelected={this.isSelected}
                    handleCheckboxClick={this.handleCheckboxClick}
                    onChangeRadioGroup={this.onChangeRadioGroup}
                    handleClassChange={this.handleClassChange}
                    handleSearchChange={this.handleSearchChangeAssignBadges}
                    onChangeSelectAll={this.onChangeSelectAll}
                    onChangeCheckBox={this.onChangeCheckBox}
                    assignBadgeClick={this.assignBadgeClick}
                />
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(AdminAssignBadges);
// Customizable Area End