// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
export const configJSON = require("./config");
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import { HISTORY } from "../../../components/src/comman";
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  otp_code: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  cursorPointer: any;
  loadingSpinner: boolean;
  otperror: boolean;
  otperrorText: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailLoginOTPController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  postApiId: any;
  resendOtpApiId: any;
  recentActivityApiId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      otp_code: '',
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      cursorPointer: 0,
      loadingSpinner: false,
      otperror: false,
      otperrorText: '',
    };
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    if (localStorage.getItem('token') && localStorage.getItem('user_data') && localStorage.getItem('device_id')) {
      const user_data = localStorage.getItem('user_data');
      const data = JSON.parse(user_data || '{}');
      this.deleteFirebaseToken(localStorage.getItem('token'), localStorage.getItem('device_id'), data.school_id)
    }
    // Customizable Area End
  }
  // Customizable Area Start
  deleteFirebaseToken = (token: any, deviceId: any, schoolId: any) => {
    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token: token,
      school: schoolId

    };
    axios({
      method: 'delete',
      url:
        configJSON.userManagementURL + `/account_block/devices/${deviceId}`,
      headers: header,
    })
      .then((response: any) => {
        localStorage.removeItem('device_id')
      })
      .catch((s3error: any) => {
      });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.postApiId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            localStorage.setItem('token', responseJson.meta.token);
            localStorage.setItem("user_login", "true")
            localStorage.setItem('user_data', JSON.stringify(responseJson.data.attributes));

            localStorage.setItem('id', responseJson.data.id);
            this.saveLoginDetails()

            if (responseJson.data.attributes.type === "SuperAdminAccount") {
              HISTORY.push('/OnBoardingSchoolDeatils');
            }
            if (responseJson.data.attributes.role === "schooladmin") {
              HISTORY.push('/AdminClassesAndSections');
              localStorage.removeItem('school_admin_login');
            }
            if (responseJson.data.attributes.type === "ParentAccount") {
              HISTORY.push('/ParentDashboard');
            }
            if (responseJson.data.attributes.type === "StudentAccount") {
              HISTORY.push('/StudentDashboard');
            }
            if (responseJson.data.attributes.type === "TeacherAccount") {
              HISTORY.push('/TeacherDashboard');
            }

            localStorage.setItem('selectedMenuId', '1');
            localStorage.removeItem('otptoken');

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
      else if (apiRequestCallId === this.resendOtpApiId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            toast.success(responseJson.meta.message)

            localStorage.setItem('otptoken', responseJson.meta.token)

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
      else if (apiRequestCallId === this.recentActivityApiId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  emailOTPhandleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { otp1, otp2, otp3, otp4, otp_code } = this.state
    if ((otp1 + otp2 + otp3 + otp4).length == 0) {
      this.setState({ otperror: true, otperrorText: 'OTP is required.' });
    }
    else if ((otp1 + otp2 + otp3 + otp4).length < 4) {
      this.setState({ otperror: true, otperrorText: 'Enter a valid OTP.' });
    }
    else {
      this.setState({ otperror: false });
    }

    if (otp1.length === 0 || otp2.length === 0 || otp3.length === 0 || otp4.length === 0) {
      this.setState({ otperror: true, otperrorText: 'OTP is required.' })
      if (otp_code.concat(otp1, otp2, otp3, otp4).length < 4 && otp_code.concat(otp1, otp2, otp3, otp4).length > 0) {
        this.setState({ otperror: true, otperrorText: 'Enter a valid OTP.' });
      }
      return false;
    }

    const data:any = {
      data: {
        type: "email_otp",
        token: localStorage.getItem('otptoken'),
        otp_code: otp_code.concat(otp1, otp2, otp3, otp4),
      }
    }
    if(localStorage.getItem('school_admin_login') && localStorage.getItem('school_admin_login') === 'TRUE'){
      data.data.role = 'SchoolAdmin';
    }
    this.setState({ loadingSpinner: true })
    const header = {
      'Content-Type': configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiOTPEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  resendOtp = () => {
    const { otp1, otp2, otp3, otp4 } = this.state

    this.setState({ loadingSpinner: true, otp1: " ", otp2: " ", otp3: " ", otp4: " " })
    const header = {
      'Content-Type': configJSON.loginApiContentType,
      token: localStorage.getItem('otptoken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendOtpApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginResendOTPEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveLoginDetails = async () => {
    const response = await axios.get(`https://freegeoip.app/json/`);

    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: localStorage.getItem("token"),
    };

    const details = response.data;
    let locationDetail = "";
    if (details.city) {
      locationDetail = locationDetail + details.city + ", ";
    }
    if (details.region_name) {
      locationDetail = locationDetail + details.region_name + ", ";
    }
    locationDetail = locationDetail + details.country_name;
    const httpBody = {
      data: {
        attributes: {
          browser: this.detectBrowser(),
          operating_system: this.getOS(),
          date: moment().format("DD-MM-YYYY HH:mm:ss"),
          location: locationDetail,
          ip_address: details.ip
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.recentActivityApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.recentActivity
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  detectBrowser = () => {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else {
      return 'Unknown';
    }
  }

  getOS = () => {
    let userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      iosPlatforms = /(iphone|ipad|ipod)/i,
      os = null;

    if (macosPlatforms.test(userAgent)) {
      os = "MacOS";
    } else if (iosPlatforms.test(userAgent)) {
      os = "IOS";
    } else if (windowsPlatforms.test(userAgent)) {
      os = "Windows";
    } else if (/android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /linux/.test(userAgent)) {
      os = "Linux";
    }

    return os;
  }
  // Customizable Area End
}