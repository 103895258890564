// Customizable Area Start
import React from 'react'
import {
    Typography, Box,
    Checkbox,
    Button,
    Grid,
    Menu,
    Divider,
    Popover
} from "@material-ui/core"
import { Search } from '@material-ui/icons';
import StudentMembersController from './StudentMembersController.web'
import StudentMembersBox from './StudentMembersBox.web'
import "./Member.web.css"
import { downArrow, rightArrow } from "./assets";
import moment from 'moment';
import Spinner from '../../../shared/SideBar/src/Spinner';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import '../StudentClassesAndSection.web.css'
import { withStyles } from "@material-ui/core/styles";
import SelectedTab from '../SelectedTab.web'
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import './CommonMembers.web.css';
// Customizable Area End

// Customizable Area Start
export class StudentMembers extends StudentMembersController {
    // Customizable Area Start
    render() {
        const { allTeacherMembers, allStudentMembers, searchedTeacherMembers, searchedStudentMembers, selectAllStudents, selectAll } = this.state;

        return (
            <>
                {(this.state.showSpinner || this.state.showTeacherSpinner) && (
                    <Spinner spinnerModal={this.state.showSpinner || this.state.showTeacherSpinner} />
                )}
                <Box className='mt20'>
                    <SelectedTab isTeacher={false} type={'Members'} />
                </Box>
                <Box className='mainBox membersParentContainer'>
                    <Box
                        className='flexBetween w100'
                    >
                        <Grid item xs={12} sm={10} md={6}

                            className={`${this.props.classes.border_color} searchBoxContainer`}
                        >
                            <input
                                placeholder="Search teachers, students or members..."
                                onChange={event => this.onChangeSearchTerm(event)}
                                value={this.state.queryTerm}
                                className={`font_18 textBox ${this.props.classes.studentMembers_classAndSection_text_font}`}
                                onKeyDown={(e: any) => {
                                    if (e?.key === 'Enter') {
                                        this.setState({ page_no: 1, teacher_page_no: 1 }, () => {
                                            this.getTeachersList();
                                            if (this.state.filterApplied) {
                                                this.applyDivisionFilter()
                                            } else {
                                                this.getStudentsList()
                                            }
                                        })
                                    }
                                }}
                            />
                           
                            <Search className={`${this.props.classes.icon_color} searchIcon`}
                                onClick={() => this.setState({ page_no: 1, teacher_page_no: 1 }, () => {
                                    this.getTeachersList();
                                    if (this.state.filterApplied) {
                                        this.applyDivisionFilter()
                                    } else {
                                        this.getStudentsList()
                                    }
                                })}
                            />
                        </Grid>
                    </Box>

                    <Box className='adminTeacherArrowContainer'>
                        <Box className="up_downArrow_outer viewAllBox" onClick={() => {
                            if (this.state.viewAllTeachers) {
                                this.setState({
                                    viewAllTeachers: false
                                })
                            } else {
                                this.setState({ viewAllTeachers: true })
                            }

                        }}>
                            {this.state.viewAllTeachers ? <img className="up_downArrow" src={downArrow} /> : <img src={rightArrow} className="up_downArrow" />}
                        </Box>

                        <Typography
                            className={`font_20 heading ${this.props.classes.heading_font}`}
                           >Admin & Teachers</Typography>



                    </Box>

                    <hr className='horizontalLine' />

                    {allTeacherMembers?.length === 0 ||
                        allTeacherMembers?.length === undefined ?
                        <Typography
                            className={`font_18 noRecordText ${this.props.classes.bodyText_font}`}
                           >No record(s) found. </Typography> :
                        <>

                            <Grid container spacing={2} className="p10">
                       
                                {this.state.viewAllTeachers && <>
                                    {allTeacherMembers?.length > 0
                                        && allTeacherMembers?.map((item: any) => (
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <StudentMembersBox
                                                    id={item.id}
                                                    name={item.attributes?.type === "AdminAccount" ? item.attributes.first_name : `${item.attributes.first_name} ${item.attributes.last_name}`}
                                                    imgSource={item.attributes.avatar}
                                                    role={this.getRoleFromAccountType(item.attributes.type)}
                                                    gender={item.attributes.gender}
                                                    dateOfBirth={moment(item.attributes.date_of_birth).format("DD/MM/YYYY")}
                                                    handleCheckboxClick={this.handleCheckboxClick}
                                                    isSelected={this.isSelected(item.id)}
                                                    selectAll={this.state.selectAll}
                                                />
                                            </Grid>
                                        ))}
                                    {this.state.hasteacherMore &&
                                        <Grid xs={12} className="loadMoreBox"> <Button
                                            className={`font_16 loadMoreButton ${this.props.classes.button_color}`}
                                            onClick={() => this.fetchMoreTeacherData()}
                                        >
                                            Load More
                                        </Button></Grid>}
                                </>}
                            </Grid>
                        </>
                    }

                    <Box className='filtermob filterBoxMob'>
                        <Box display="flex" flexDirection='row'>
                            <Box display='flex' flexDirection='row' className="up_downArrow_outer upDownArrows"
                                onClick={() => {
                                    if (this.state.viewAllStudents) {
                                        this.setState({
                                            viewAllStudents: false
                                        })
                                    } else {
                                        this.setState({ viewAllStudents: true })
                                    }

                                }}>
                                {this.state.viewAllStudents ? <img src={downArrow} className="up_downArrow" /> : <img src={rightArrow} className="up_downArrow" />}
                            </Box>
                            <Typography
                                className={`font_20 titleText ${this.props.classes.heading_font}`}
                                >Students</Typography>


                            <Typography
                                className={`font_18 count ${this.props.classes.heading_font}`}
                                >
                                {`( ${this.state.allStudentMembersCount} )`}
                            </Typography>
                        </Box>

                        <Button
                            variant="contained"

                            className={`filterbtn filterButton ${this.state.filterApplied ? this.props.classes.button_color : this.props.classes.button_color_disabled}`}
                            style={{
                                opacity: this.state.viewAllStudents ? 1 : 0.5
                            }}
                            disabled={!this.state.viewAllStudents}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => { this.setState({ anchorEl: event.currentTarget }) }}
                        >
                            Filter
                        </Button>

                    </Box>
                    <hr className='horizontalLine'/>

                    {allStudentMembers?.length === 0 ||
                        allStudentMembers?.length === undefined ?
                        <Typography
                            className={`font_18 noRecordText ${this.props.classes.bodyText_font}`}
                            >No record(s) found. </Typography>
                        :
                        <>
                            <Grid container spacing={2} className="p10" id="scrollableDiv">
                                {this.state.viewAllStudents && <>
                                    {allStudentMembers?.length > 0
                                        && allStudentMembers?.map((item: any) => (
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <StudentMembersBox
                                                    id={item.id}
                                                    name={`${item.attributes.first_name} ${item.attributes.last_name}`}
                                                    imgSource={item.attributes.avatar}
                                                    role={this.getRoleFromAccountType(item.attributes.type)}
                                                    gender={item.attributes.gender}
                                                    dateOfBirth={moment(item.attributes.date_of_birth).format("DD/MM/YYYY")}
                                                    handleCheckboxClick={this.handleStudentCheckboxClick}
                                                    isSelected={this.isSelectedStudents(item.id)}
                                                    selectAll={this.state.selectAllStudents}
                                                />
                                            </Grid>
                                        ))}
                                    {this.state.hasMore &&
                                        <Grid xs={12} className="loadMoreBox"> <Button
                                            className={`font_16 loadMoreButton ${this.props.classes.button_color}`}
                                            onClick={() => this.fetchMoreData()}
                                        >
                                            Load More
                                        </Button></Grid>}
                                </>}
                            </Grid>
                        </>
                    }
                    <Menu
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        aria-haspopup="true"
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.onMenuClose}
                        className="menu_css"
                    >
                        <Box className='filter_box'>
                            <Typography
                                className={`filtersHead font_18 ${this.props.classes.heading_font}`}
                            >
                                Filters
                            </Typography>
                            <Divider className='divider' />

                            <Grid container >
                                <Grid item xs={12} className="filterByBox">
                                    <Typography
                                        className={`font_16 filtersHead ${this.props.classes.subHeading_font}`}
                                    >
                                        Filter by Division
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className="filterValueContainer"
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        this.setState({
                                            tempDivision: this.state.filterDivisionId,
                                            divisionDropDownAnchorEl: event.currentTarget,
                                        })
                                    }}>
                                    <Box

                                        className={`filter_value_box filterValues ${this.props.classes.bodyText_font}`}
                                        >
                                        {this.state.filterDivisionId.length != 0 ?
                                            <Box className="fileNameText22 flexRow">
                                                {
                                                    this.state.filterDivisionId.map((data1: any, index: number) => {
                                                        return (
                                                            this.state.divisionList.filter((x: any) => x.id === data1)
                                                                .map((x: any) => x.attributes?.name)
                                                        )
                                                    }
                                                    ).join(", ")}
                                            </Box>
                                            :
                                            <Typography
                                                className={`font_16 ${this.props.classes.bodyText_font}`}
                                            >
                                                Select Division
                                            </Typography>
                                        }
                                        <Box className='flex'>
                                            <KeyboardArrowDownIcon />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Popover
                                    id="1"
                                    anchorEl={this.state.divisionDropDownAnchorEl}
                                    open={Boolean(this.state.divisionDropDownAnchorEl)}
                                    onClose={() => {
                                        this.setState({
                                            divisionDropDownAnchorEl: null
                                        })
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    className="popOver"
                                    PaperProps={{
                                        style: {
                                            maxHeight: '70%',
                                            boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.2)",
                                            backgroundColor: "#fff",
                                            maxWidth: '288px'
                                        },

                                    }}
                                >
                                    <Box className="option_menu">
                                        {this.state.divisionList?.map((_dataDivision: any, index: any) => {
                                            return (
                                                <Box
                                                    className="optionsBox"
                                                    onClick={() => {
                                                        {

                                                            let temp1: any = [];
                                                            temp1 = this.state.tempDivision;
                                                            let temp2: any = temp1;

                                                            if (this.state.tempDivision.indexOf(
                                                                _dataDivision.id
                                                            ) > -1 || this.state.tempDivision.indexOf(
                                                                parseInt(_dataDivision.id)
                                                            ) > -1) {

                                                                var array: any[] = this.state.tempDivision.filter((item: any) => {
                                                                    if (item !== _dataDivision.id && item !== parseInt(_dataDivision.id)) {
                                                                        return item
                                                                    }
                                                                }
                                                                );
                                                                this.setState({ tempDivision: array }, () => { })
                                                            }
                                                            else {
                                                                this.setState({ tempDivision: [...this.state.tempDivision, _dataDivision.id] }, () => { })

                                                            }
                                                        }
                                                    }}
                                                >
                                                    <Typography
                                                        className={`font_15 optionsBoxTypo ${this.props.classes.bodyText_font}`}
                                                        >
                                                        {_dataDivision.attributes.name}
                                                    </Typography>
                                                    <Checkbox
                                                        checked={this.state.tempDivision.indexOf(
                                                            _dataDivision.id
                                                        ) > -1}
                                                        size="small"
                                                        className={`${this.props.classes.icon_color} transparentBg`}
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    />
                                                </Box>)
                                        })}
                                        {
                                            this.state.divisionList?.length === 0 ? <div className="noDataBox">  <Typography
                                                className={`font_16 optionsBoxTypo ${this.props.classes.bodyText_font}`}
                                                >No Data Found.</Typography> </div> : ''
                                        }
                                        <Box
                                            className='btnContainer'
                                        >
                                            <Button
                                                className={`font_16 okButton button_height ${this.props.classes.button_color}`}
                                                onClick={(event: any) => {
                                                    event.stopPropagation();
                                                    event.cancelBubble = true;
                                                    this.setState({
                                                        filterDivisionId: this.state.tempDivision,
                                                        divisionDropDownAnchorEl: null,

                                                    }, () => {
                                                        this.setState({ tempDivision: [] })
                                                    })
                                                }}
                                            >
                                                OK
                                            </Button>
                                            <Button
                                                className={`font_16 cancelButton button_height ${this.props.classes.button_color_disabled}`}
                                                onClick={() => {
                                                    this.setState({
                                                        divisionDropDownAnchorEl: null,
                                                        tempDivision: [],
                                                    })
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Box>
                                </Popover>
                            </Grid>

                            <Grid container >
                                <Grid item xs={12} className="pt15">
                                    <Button
                                        className={`font_16 applyBtn button_height ${this.props.classes.button_color}`}
                                        onClick={() => {
                                            this.setState({ page_no: 1 }, () => this.applyDivisionFilter())
                                        }}
                                    >
                                        Apply
                                    </Button>

                                    <Button
                                        className={`font_16 cancelButton button_height ${this.props.classes.button_color_disabled}`}
                                        onClick={() => { this.setState({ anchorEl: null, tempDivision: [], }) }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>

                            </Grid>

                        </Box>
                    </Menu>
                </Box>

            </>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(StudentMembers);
// Customizable Area End