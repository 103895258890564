// Customizable Area Start
import {
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  ArrowBack,
  CloudDownload,
  FilterList,
  PrintOutlined,
  ShareOutlined,
} from '@material-ui/icons';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import React from 'react';
import { HISTORY } from '../../../../components/src/comman';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import PerformanceOverTime from '../Components/PerformanceOverTime.web';
import StudentProgress from '../Components/StudentProgress.web';
import './common.css';
import MultipleAssessmentReportController from './MultipleAssessmentReportController.web';
// Customizable Area End

// Customizable Area Start
ChartJS.register(ArcElement, Tooltip, Legend);
// Customizable Area End
export class MultipleAssessmentReport extends MultipleAssessmentReportController {
  // Customizable Area Start
  getStatusTextColor = (status: string) => {
    switch (status) {
      case 'Scheduled':
        return '#06b0b6';
      case 'Ongoing':
        return '#3a60d7';
      case 'Completed':
        return '#4bb14b';
      default:
        return '#3f526d';
    }
  };
  data = {
    data: {
      labels: [
        'Lorem Ipsum',
        'Established',
        'Hampden',
        'Generator',
        'Sentence',
        'lorem ipsum',
        'Established',
        'Hampden',
        'Sentence',
        'Structure',
      ],
      datasets: [
        {
          label: 'Avg. score (%)',
          data: [0, 10, 20, 60, 65, 50, 65, 25, 80, 45],
          backgroundColor: [
            '#f24a3d',
            '#f24a3d',
            '#f24a3d',
            '#26c165',
            '#26c165',
            '#ffcc70',
            '#26c165',
            '#f24a3d',
            '#26c165',
            '#ffcc70',
          ],
        },
      ],
    },
    options: {
      scales: {
        x: {
          suggestedMin: 0,
          suggestedMax: 100,
          grid: {
            display: false,
          },
        },
        y: {
          suggestedMin: 0,
          suggestedMax: 100,
          grid: {
            display: true,
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: true,
          color: 'black',
          formatter: Math.round,
          anchor: 'end',
          offset: -20,
          align: 'start',
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
      },
    },
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    const renderTable = () => {
      switch (this.state.tabValue) {
        case 0:
          return (
            <PerformanceOverTime
              classes={this.props.classes}
              data={this.data}
              getStatusTextColor={this.getStatusTextColor}
            />
          );

        case 1:
          return (
            <StudentProgress classes={this.props.classes} data={this.data} />
          );
        default:
          break;
      }
    };
    return (
      <Box marginTop={3}>
        <Box
          display="flex"
          alignItems="center"
          className={this.props.classes.icon_button_color}
        >
          <ArrowBack
            onClick={() => {
              HISTORY.go(-1);
            }}
          />
          <Typography
            variant="h5"
            className={`${this.props.classes.title_font} margin_left_10`}
            // style={{ marginLeft: '10px' }}
          >
            MultipleAssessmentReport
          </Typography>
        </Box>

        <Box marginTop={3}>
          <Grid container>
            <Grid item xs={12} md={12} lg={8}>
              <Tabs
                value={this.state.tabValue}
                onChange={(_, value) => this.handleChangeTabs(value)}
                TabIndicatorProps={{
                  style: {
                    background: this.props.classes.button_color,
                  },
                }}
              >
                <Tab
                  className={` ${this.props.classes.subHeading_font}`}
                  label="Performance Over Time"
                  id={`simple-tab-${this.state.tabValue}`}
                />
                <Tab
                  className={` ${this.props.classes.subHeading_font}`}
                  label="Student Progress"
                  id={`simple-tab-${this.state.tabValue}`}
                />
              </Tabs>
            </Grid>
            <Grid item xs={3} md={3} lg={1}>
              <IconButton>
                <FilterList />
              </IconButton>
            </Grid>
            <Grid item xs={3} md={3} lg={1}>
              <IconButton>
                <ShareOutlined />
              </IconButton>
            </Grid>
            <Grid item xs={3} md={3} lg={1}>
              <IconButton>
                <PrintOutlined />
              </IconButton>
            </Grid>
            <Grid item xs={3} md={3} lg={1}>
              <IconButton>
                <CloudDownload />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        {renderTable()}
      </Box>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(
  (MultipleAssessmentReport as unknown) as React.ComponentType<any>
);
// Customizable Area End
