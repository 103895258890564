// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const DivisionIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g stroke-width="1" fill="none" fill-rule="nonzero" {...props}>
                <g id="icons" transform="translate(-455.000000, -258.000000)" fill="#FFFFFF" fill-rule="nonzero" {...props}>
                    <g transform="translate(455.000000, 258.000000)" {...props}>
                        <path d="M4.496 28.504C2.029 28.504 0 26.464 0 23.997v-3C0 18.528 2.029 16.5 4.496 16.5h9.003v-3.001H7.497c-2.397 0-4.38-1.914-4.49-4.286L3 9.003V6.002c0-2.467 2.029-4.507 4.496-4.507H13.5A1.499 1.499 0 0 1 16.061.439c.28.28.438.66.44 1.056h6.002c2.396 0 4.38 1.925 4.49 4.297l.006.21v3.001c0 2.467-2.03 4.496-4.496 4.496H16.5V16.5h9.004c2.397 0 4.38 1.915 4.491 4.286l.005.21v3.001c0 2.468-2.029 4.507-4.496 4.507l-9.004.001a1.5 1.5 0 0 1-.34.946l-.1.11a1.501 1.501 0 0 1-2.56-1.056zm9.003-9.003H4.496c-.857 0-1.495.638-1.495 1.495v3.001c0 .858.638 1.507 1.495 1.507l9.003-.001v-6.002zm12.005 0H16.5v6.002h9.004a1.46 1.46 0 0 0 1.488-1.356l.007-.15v-3c0-.858-.638-1.496-1.495-1.496zM13.499 4.496H7.497c-.857 0-1.495.649-1.495 1.506v3.001c0 .857.638 1.495 1.495 1.495H13.5V4.496zm9.004 0H16.5v6.002h6.003c.806 0 1.419-.565 1.488-1.346l.007-.149v-3c0-.858-.638-1.507-1.495-1.507z" fill={`${props.fillColor}`} fill-rule="nonzero" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default DivisionIcon;
// Customizable Area End