// Customizable Area Start
import React from "react"
import {
    Box,
    Button,
    withStyles,
    Typography,
    Modal,
    Backdrop,
    IconButton
} from "@material-ui/core";
import uploadAssesmentModalController, { Props } from "./uploadAssesmentModalController.web";
import { borderRadius } from "react-select/src/theme";
import { exam } from "../assets";
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End
// Customizable Area Start
// Customizable Area End

export class UploadAssesmentModalWeb extends uploadAssesmentModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { classes } = this.props
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                    }}
                    open={this.props.openModal}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Box className="uplaod_assessment_modal">
                        <Box style={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'end',
                            flexDirection: 'column',
                        }}>
                            <IconButton onClick={this.handleClose}>
                                <CloseIcon style={{ fontSize: 36 }} />
                            </IconButton>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Box className="exam_yellow_bg" >
                                <img src={exam} className="exam_pencil_img" />
                            </Box>

                            <Typography className="do_you_want_to_create_text">
                                Do you want to create assesment using same question ?
                            </Typography>

                            <Box className="bottom_button_section">

                                <Button className="no_button" onClick={this.handleClicknavigationNOHandler}>
                                    No
                                </Button>
                                <Button className="yes_button" onClick={this.handleClicknavigationYESHandler}>
                                    Yes
                                </Button>

                            </Box>
                        </Box>

                    </Box>

                </Modal>
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
const styles = {
    UploadBtn: {
        marginLeft: 15,
        marginRight: 15,
        background: "linear-gradient(to top, rgb(175, 56, 203), rgb(71, 28, 81))",
        color: "#fff",
        padding: "0px 30px",
        height: 36,
    },
    BottomBtnTextFont: {
        fontSize: 16
    },
    CancelBtn: {
        marginLeft: 15,
        marginRight: 15,
        background: "#fff",
        border: "solid 1px  rgb(175, 56, 203)",
        color: "linear-gradient(to top, rgb(175, 56, 203), rgb(71, 28, 81))",
        padding: "0px 30px",
        height: 36,
    },
}
export default withStyles(styles)(UploadAssesmentModalWeb);
// Customizable Area End