Object.defineProperty(exports, "__esModule", {
  value: true,
});
const urlConfig = require("../../../framework/src/config");
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "StudentProfile";
exports.labelBodyText = "StudentProfile Body";
exports.studentProfilePersonalInfo = "bx_block_profile/personal_infos";
exports.updateProfileApiMethodType = "PUT";
exports.studentOrganisationUnit = "bx_block_profile/organizations";
exports.occupation = "bx_block_profile/occupations";
exports.classes = "/bx_block_scheduling/school_classes?page=1&per=100"; // "bx_block_scheduling/classes";
exports.studentEducationalInfo = "bx_block_profile/educational_infos";
exports.studentDocumentInfo = "bx_block_profile/documents";
exports.studentNotificationInfo = "bx_block_profile/notification_settings";
exports.studentBadgesInfo = "bx_block_profile/badges";
exports.studentRecentActivities = "bx_block_profile/recent_activities";
exports.studentSubjectGetUrl =
  "/bx_block_dashboard8/dashboard/get_student_subjects";
exports.teacherSubjectGetUrl =
  "/bx_block_scheduling/subjects/subject_by_teacher";
exports.teacherAllSubjectGetUrl = "/bx_block_scheduling/subjects";
exports.instituteURL = urlConfig.instituteURL;
exports.dashboardURL = urlConfig.dashboardURL;
exports.btnExampleTitle = "CLICK ME";
exports.studentDocumentUpload = "/bx_block_profile/documents/upload_doc";
exports.studentDeleteDoc = "bx_block_profile/documents";
exports.studentReUploadDoc = "bx_block_profile/documents";
exports.deleteApiMethodType = "DELETE";
exports.studentDocumentReUpload = "/bx_block_profile/documents";
exports.uploadFile = "/bx_block_bulk_uploading/uploads";
exports.getPreSignedId = "/bx_block_bulk_uploading/uploads/pre_signed_url";
exports.getPreSignedUrl = "bx_block_bulk_uploading/uploads/s3_signed_url";
exports.deleteVideo = "/bx_block_bulk_uploading/uploads";
exports.videoValidations = "/bx_block_dashboard8/dashboard/get_video_settings";
exports.userManagementURL = urlConfig.baseURL;
exports.statusEndPoint = "/bx_block_profile/documents/save_and_notify";

const baseURL = urlConfig.baseURL;

//Profile Module Status Messages
exports.ProfileModuleStatusMessage={
  PERSONAL_INFO_SUCCESS:"Personal info has been saved successfully."
}
// Customizable Area End
