// Customizable Area Start
import React from "react";
import LibraryAssessmentListController from "./LibraryAssessmentListController.web";
import {
    Avatar,
    Box,
    Button,
    Grid,
    NativeSelect,
    Tooltip,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@material-ui/core";
import { ArrowDropDown, KeyboardBackspaceOutlined, Search } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import './LibraryAssessmentList.web.css';
import { deleteIconSvg, imgEditSvg, imgEyeIconSvg } from "../assets";
import DeleteModal from "./DeleteModal.web";
import { FilterSelect, FilterSelectDisabled } from "./LibraryAssessmentList.style.web";
import { difficultyLevelList, librarayAssessmentStatus } from "../utils/constants";
import AssessmentSummaryModal from "./AssessmentSummaryModal.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class LibraryAssessmentList extends LibraryAssessmentListController {
    // Customizable Area Start
    render() {
        const {
            showLoader,
            assessmentList,
            userId,
            teacherList,
            selectedTeacherId,
            selectedAssessmentStatus,
            selectedDifficultyType,
            currentClass,
            currentSubject,
            searchText
        } = this.state;
        const { classes } = this.props;
        const isFilterEnabled = this.filterStatus();

        const getActionButtons = (data: any) => {
            if (data.state === 'draft') {
                return <>
                    <span
                        className="PreviewQuestion"
                        data-test-id={`editAssessment-${data.id}`}
                        onClick={() => this.handleEditAssessment(Number(data.id))}
                    >
                        <img
                            className="ActionIcon"
                            src={imgEditSvg}
                            alt=""
                        />
                    </span>
                    <span
                        className="PreviewQuestion"
                        data-test-id={`deleteAssesssment-${data.id}`}
                        onClick={() => this.openDeleteModal(Number(data.id))}
                    >
                        <img
                            className="ActionIcon"
                            src={deleteIconSvg}
                            alt=""
                        />
                    </span>
                </>
            }
        }

        const getPreviewButton = (data: any) => {
            return <>
                <span
                    className="PreviewQuestion"
                    data-test-id={`previewAssessment-${data.id}`}
                    onClick={() => this.handleViewAssessment(Number(data.id))}
                >
                    <img
                        className="ActionIcon"
                        src={imgEyeIconSvg}
                        alt=""
                    />
                </span>
            </>
        }
        return (
            <>
                <Spinner spinnerModal={showLoader} />
                <Grid container >
                    <Grid item xs={12} className="ALBackGrid">
                        <Typography
                            className={`BackText ${this.props.classes.bodyText_font}`}
                            onClick={this.handleBackClick}
                        >
                            <KeyboardBackspaceOutlined className={`BackIcon ${classes.icon_color}`} />
                            Assessment Library
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" className="LibraryAssessmentListContainer">
                    <Grid className="SubjectGrid" item xs={12}>
                        <Typography
                            className={`SubjectTextColor ${classes.bodyText_font}`}
                            component="span"
                        >{`${currentClass?.attributes?.name} > ${currentSubject?.subject_name}`}</Typography>
                    </Grid>
                    {/* Search Box */}
                    <Grid className="SearchGrid" item xs={12}>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={`BoxStyle ${classes.border_Color}`}
                        >
                            <input
                                placeholder="Search for the assessment.."
                                className={`SearchInput ${classes.bodyText_font}`}
                                value={searchText}
                                data-test-id="SearchAssessment"
                                onChange={this.onChangeSearch}
                                onKeyPress={this.onEnterKeyPress}
                            />
                            <Search
                                className={`${classes.icon_color} SearchIcon`}
                                onClick={this.onSearchBtnClick}
                            />
                        </Box>
                    </Grid>
                    {/* Search Box END */}
                    <Grid className="ALFilterGrid" item xs={12}>
                    <Accordion elevation={0} className="AccordionStyles">
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid container direction="row">
                                <Grid className="JustifyStart" item xs={6}>
                                    <span className="FilterBox">
                                        <ArrowDropDown fontSize="large" className="DropDownIcon" />
                                        <span className={`filter ${this.props.classes.theme_font}`}>Filters</span>
                                    </span>
                                </Grid>
                                <Grid className="JustifyEnd" item xs={6} onClick={this.clearAll} data-test-id="ClearFilter">
                                    <span className={`ClearFilter ${this.props.classes.theme_font} ${this.props.classes.subTitle_font}`}>Clear all</span>
                                </Grid>
                                <hr className="horizontalLine" />
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails> 
                            <Grid className="MarginTop" item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Grid container direction="row">
                                            <Grid item xs={12}>
                                                <Typography component="span"
                                                    className={`DropdownLabels ${this.props.classes.theme_font}`}>
                                                    Uploaded by
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <NativeSelect
                                                    className="nativeSelect"
                                                    data-test-id="FilterByTeacher"
                                                    value={selectedTeacherId}
                                                    input={selectedTeacherId == "" ? <FilterSelectDisabled /> : <FilterSelect />}
                                                    IconComponent={ArrowDropDown}
                                                    onChange={this.filterTeacherOnChange}
                                                >
                                                    <option value="" disabled>Uploaded by</option>
                                                    {
                                                        teacherList.length > 0 && teacherList.map((item: any) => (
                                                            <option key={item.id} value={item.id} >
                                                                {
                                                                    item.id === parseInt(userId) ? "Upload by me" : `${item?.first_name} ${item?.last_name !== null ? item.last_name : ""}`
                                                                }
                                                            </option>
                                                        ))
                                                    }
                                                </NativeSelect>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Grid container direction="row">
                                            <Grid item xs={12}>
                                                <Typography component="span"
                                                    className={`DropdownLabels ${this.props.classes.theme_font}`}>
                                                    Filter by difficulty level
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <NativeSelect
                                                    className="nativeSelect"
                                                    data-test-id="FilterByDifficultyLevel"
                                                    value={selectedDifficultyType}
                                                    input={selectedDifficultyType == "" ? <FilterSelectDisabled /> : <FilterSelect />}
                                                    IconComponent={ArrowDropDown}
                                                    onChange={this.filterDifficultyTypeOnChange}
                                                >
                                                    <option value="" disabled>Select Difficulty</option>
                                                    {difficultyLevelList?.map((item: any) => (
                                                        <option value={item.value} key={item.value}>
                                                            {item.label}
                                                        </option>
                                                    ))}
                                                </NativeSelect>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Grid container direction="row">
                                            <Grid item xs={12}>
                                                <Typography component="span"
                                                    className={`DropdownLabels ${this.props.classes.theme_font}`}>
                                                    Filter by status
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <NativeSelect
                                                    className="nativeSelect"
                                                    data-test-id="FilterByStatus"
                                                    value={selectedAssessmentStatus}
                                                    input={selectedAssessmentStatus == "" ? <FilterSelectDisabled /> : <FilterSelect />}
                                                    IconComponent={ArrowDropDown}
                                                    onChange={this.filterByStatus}
                                                >
                                                    <option value="" disabled>Select Status</option>
                                                    {librarayAssessmentStatus?.map((item: any) => (
                                                        <option value={item.value} key={item.value}>
                                                            {item.label}
                                                        </option>
                                                    ))}
                                                </NativeSelect>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className="ApplyFilterGrid" item xs={12}>
                                        <Button
                                            disabled={isFilterEnabled}
                                            data-test-id="FilterButton"
                                            className={`${this.props.classes.button_color} ${this.props.classes.theme_font} BtnDisabled FilterBtn`} onClick={this.applyFilter}>
                                            Apply Filter
                                        </Button>
                                    </Grid>
                                </Grid>
                                <hr className="horizontalLine2" />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    </Grid>
                    <Grid className="AssessmentListGrid" item xs={12}>
                        {/* <AssessmentList /> */}
                        <Grid container direction="row">
                            <Grid item xs={12}>
                                {
                                    assessmentList?.map((assessment: any) => (
                                        <Grid
                                            className="questionContainer assessment-library-outer"
                                            container
                                            direction="row"
                                            key={assessment.id}
                                        >
                                            <Grid className="QuestionRow" item xs={12}>
                                                <Grid container direction="row">
                                                    <Grid className="JustifyStart" item xs={6} sm={8}>
                                                        <Tooltip title={assessment?.assessment_name}>
                                                            <Typography
                                                                className={`AssessmentTitle ${this.props.classes.subHeading_font}`}
                                                            >
                                                                {assessment?.assessment_name}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid className="JustifyEnd" item xs={6} sm={4}>
                                                        {getPreviewButton(assessment)}
                                                        {
                                                            Number(userId) !== Number(assessment?.teacher_id) ?
                                                                <span className="TeacherInfo">
                                                                    <span className="AvatarSpan">
                                                                        {assessment?.account?.attributes?.avatar ? (
                                                                            <img
                                                                                className="Avatar"
                                                                                src={assessment?.account?.attributes?.avatar}
                                                                                alt=""
                                                                            />
                                                                        ) : (
                                                                            <Avatar src="" className="Avatar" />
                                                                        )}
                                                                    </span>
                                                                    <span className={`TeacherName ${classes.bodyText_font}`}>{assessment?.account?.attributes?.first_name}</span>
                                                                </span>
                                                                :
                                                                (
                                                                    <>
                                                                        {getActionButtons(assessment)}
                                                                    </>
                                                                )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))
                                }

                                {
                                    assessmentList.length === 0 &&
                                    <div className={`no-items ${classes.bodyText_font}`}>
                                        <span>No record(s) found</span>
                                    </div>
                                }

                                {this.state.timeLineMeta && this.state.pagination_page > 0 && (
                                    <div className="LoadMoreBtnDiv">
                                        <Button
                                            variant="outlined"
                                            data-test-id="LoadMoreBtn"
                                            className={`LoadMoreBtn ${classes.outline_button_no_hover} ${classes.theme_font}`}
                                            onClick={this.handleLoadMore}
                                        >
                                            Load More...
                                        </Button>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <DeleteModal
                    title={"Delete Assessment?"}
                    subTitle={"Are you sure you want to delete?"}
                    paragraph={"Please confirm!"}
                    assessmentId={this.state.deleteAssessmentId}
                    deleteModal={this.state.isDeleteModalOpen}
                    onCloseModal={this.closeDeleteModal}
                    onModalOk={this.handleDeleteAssessment}
                />

                <AssessmentSummaryModal
                    onCloseModal={this.closeSummaryModal}
                    assessmentSummary={this.state.assessmentSummary}
                    summaryModal={this.state.isSummaryModalOpen}
                />
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(LibraryAssessmentList);
// Customizable Area End
