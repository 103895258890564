// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
export const configJSON = require("./config");
import { toast } from "react-toastify";
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  otp: any;
  loadingSpinner:boolean;
  otperror:boolean;
  otperrorText:string;
  otp_code: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordAdminOTPController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  postLoginApiId: any;
  emailReg: RegExp;
  resendOtpApiId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      otp: '',
      loadingSpinner:false,
      otp_code: '',
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      otperror:false,
      otperrorText:'',
    };
    this.emailReg = new RegExp("");
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.postLoginApiId) {
        console.log("ID Match")
        if (responseJson != null) {
          console.log('RES===>', responseJson);
          if (!responseJson.errors) {
            console.log("Error Free")
            this.props.navigation.navigate('NewPassword')
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
         this.setState({loadingSpinner:false})
      }
      else if (apiRequestCallId === this.resendOtpApiId) {
        console.log("ID Match")
        if (responseJson != null) {
          console.log("JSON NOt NUll")
          if (!responseJson.errors) {
            toast.success(responseJson.meta.message)
            localStorage.setItem('otptoken',responseJson.meta.token)
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        console.log("responseJson nulll")
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { otp1, otp2, otp3, otp4, otp_code } = this.state
    const reg = /^\d+$/;
    console.log(otp_code.concat(otp1, otp2, otp3, otp4))
    if (otp1.length === 0 || otp2.length === 0 || otp3.length === 0 || otp4.length === 0) {
      this.setState({ otperror: true, otperrorText: 'OTP is required.' })
      if (otp_code.concat(otp1, otp2, otp3, otp4).length < 4 && otp_code.concat(otp1, otp2, otp3, otp4).length > 0) {
        this.setState({ otperror: true, otperrorText: 'Enter a valid OTP.' });
      }
      return false;
    }

    //Validation here
    const data = {
      data: {
        type: "email_otp",
        token: localStorage.getItem('otptoken'),
        otp_code: otp_code.concat(otp1, otp2, otp3, otp4)
      }
    }
    this.setState({loadingSpinner:true})
    const header = {
      'Content-Type': configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postLoginApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    console.log('REQUEST: ', requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  resendOtp = () => {
    //Validation here
    const { otp1, otp2, otp3, otp4 } = this.state
    console.log(otp1, otp2, otp3, otp4,)
    this.setState({ loadingSpinner: true,otp1:" ",otp2:" ",otp3:" ",otp4:" "})
    const header = {
      'Content-Type': configJSON.forgotPasswordAPiContentType,
      token: localStorage.getItem('otptoken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendOtpApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginResendOTPEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    console.log('REQUEST: ', requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
