// Customizable Area Start
import React from "react";
import {
  Button,
  Grid,
  Typography,
  NativeSelect,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Menu,
  Box,
  MenuItem,
  Divider,
  Tooltip
} from "@material-ui/core";
import Datetime from "react-datetime";
import { ArrowDropDown } from "@material-ui/icons";
import ReactPaginate from "react-paginate";
import AdminClassPeriodController, {
  Props,
} from "./AdminClassPeriodController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import FilterScreen from "./FilterScreen.web"
import { autoGenerateIcon, filter, moreItem, uploadIcon, addIcon } from "../assets";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import UploadModal from "./UploadModal.web";
import AddEditClassPeriod from "./AddEditClassPeriod.web";
import './AdminClassPeriod.web.css'
import ViewClassPeriod from "./ViewClassPeriod.web";
import { editable } from "../TimeTable/assets";
import AutomaticClassPeriod from "./AutomaticClassPeriod.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class AdminClassPeriod extends AdminClassPeriodController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const BootstrapInput = withStyles((theme: any) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: "relative",
        border: "solid 1px #ebebeb",
        backgroundColor: "#fff",

        transition: theme.transitions.create(["border-color", "box-shadow"]),
        color: theme.props.textColor,
        fontFamily: theme.props.textFont,
        fontWeight: 600,
        fontSize: 18,
        lineHeight: "21px",
        boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
        "&:focus": {
          borderRadius: 8,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        [theme.breakpoints.only('lg')]: {
          padding: "16.5px 23px",
          fontSize: 18,
        },
        [theme.breakpoints.only('xs')]: {
          padding: "10px 17px",
          maxWidth: 190,

          fontSize: 14,
        },
        [theme.breakpoints.only('md')]: {
          padding: "15.5px 21px",

          fontSize: 18,
        },
        [theme.breakpoints.only('sm')]: {
          padding: "13px 19px",

          fontSize: 16,
        },
      },
    }))(InputBase);


    const {
      weekList,
      selectedWeekId,
      openAddEditClassPeriod,
      isEditPeriod,
      classStartDate,
      weekDay,
      batchData,
      schoolperiodData,
      selectedPeriod,
      classData,
      selectedClass,
      subjectData,
      selectedSubject,
      selectedSubjectError,
      teacherData,
      selectedTeacher,
      selectedTeacherError } = this.state;
    return (
      <>
        <div >
          {this.state.showLoader && (
            <Spinner spinnerModal={this.state.showLoader} />
          )}
          <div
            className="adminClassPeriodHeaderPart"
          >
            <Grid container direction="row" spacing={2}>
              <Grid item className="topFilterBar" xs={6} sm={2} md={2}>
                <Typography
                  className={`${this.props.classes.heading_font} mainHeading`}
                >
                  Class Period
                </Typography>
              </Grid>
              <Grid item className="topFilterBar" xs={6} sm={3} md={2}>
                <NativeSelect
                  id="select"
                  className="dropDownSelect"
                  value={this.state.selectedClassId}
                  input={<BootstrapInput />}
                  IconComponent={ArrowDropDown}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { this.handleChangeClass(event) }}
                >
                  <option value="" disabled>Select Class</option>
                  {
                    classData.length && classData.map((classItem: any) => (
                      <option key={classItem.id} value={classItem.id} >{classItem.attributes?.display_name}</option>
                    ))
                  }
                </NativeSelect>
              </Grid>

              <Grid item className="topFilterBar" xs={5} sm={3} md={2}>
                <NativeSelect
                  id="batchselect"
                  value={this.state.selectedBatch}
                  className="dropDownSelect"
                  input={<BootstrapInput />}
                  IconComponent={ArrowDropDown}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { this.handleChangeBatch(event) }}
                >
                  <option value="" disabled>Select Batch</option>
                  {
                    batchData.length && batchData.map((batch: any) => (
                      <option key={batch.id} value={batch.id} >{batch.attributes?.name}</option>
                    ))
                  }
                </NativeSelect>
              </Grid>
              <Grid item className="topFilterBar" xs={5} sm={3} md={2}>
                <Datetime
                  dateFormat={"MMMM,YYYY"}
                  timeFormat={false}
                  strictParsing={true}
                  input={true}
                  value={moment(this.state.selectedMonthDate, "DD-MM-YYYY").format(
                    "MMMM,YYYY"
                  )}
                  initialValue={moment().format("MMMM,YYYY")}

                  className={`custom-date-time ${this.props.classes.theme_font}`}
                  inputProps={{
                    placeholder: "Date of Birth",
                    className: "dropdown-image",
                    readOnly: true,
                  }}
                  onNavigateBack={(count, type) => {
                    this.handleDateNavigate(count, type, "backward");
                  }}
                  onNavigateForward={(count, type) => {
                    this.handleDateNavigate(count, type, "forward");
                  }}
                  closeOnSelect
                  onChange={(date) => {
                    this.handleDateTimeChange(date)
                  }}
                />
              </Grid>
              <Grid item className="topFilterBar more_icon flexEnd" xs={2} sm={1} md={4}>
                <img src={moreItem} className="moreIconImg" onClick={(event: any) => {
                  this.setState({ anchorEl1: event.currentTarget }
                  )
                }}
                />
              </Grid>
            </Grid>
          </div>

          <div
            className="adminClassPeriodMainContainer"
          >
            <Grid
              className="innnerBox"
              container
              direction="row"
            >
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={12} sm={10} md={9} className="weekListing">
                    {weekList?.map((item: any) => (
                      <div key={item.id}
                        className={`font_18 weekTypo week_list ${selectedWeekId === item.id ? this.props.classes.button_color : this.props.classes.button_color_disabled}`}
                        onClick={() => this.weekChangeEvent(item.id)}>
                        {item.name}
                      </div>
                    ))}
                  </Grid>
                  <Grid
                    className="flexEnd mb12"
                    item
                    xs={12}
                    sm={2}
                    md={3}
                  >
                    <Button
                      disableRipple={true}
                      variant="contained"
                      className="font_18 filterButton"
                      id="1"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        this.setState({ filterAnchorEl: event.currentTarget });
                      }}
                    >
                      <img
                        src={filter}
                        className="filter_icon"
                      />
                      {this.state.isFilterApply ? `Filter (${this.state.filterElement.subject && this.state.filterElement.teacher ? 2 : 1})` : "Filter"}
                    </Button>
                    {
                      this.state.filterAnchorEl
                      &&

                      <FilterScreen
                        filterAnchorEl={this.state.filterAnchorEl}
                        navigation={undefined}
                        popoverClose={() => { this.setState({ filterAnchorEl: null }) }}
                        periodData={this.state.schoolperiodData}
                        teacherData={this.state.teacherData}
                        schoolSubjectData={this.state.schoolSubject}
                        filterElement={this.state.filterElement}
                        applyFilterClick={(subject: any, period: any, teacher: any)=>{this.applyFilterClickProp(subject, period, teacher)}}
                      />}

                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="tableContainerBox">
                <TableContainer>
                  <Table aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {this.state.dayColumns.map((column: any) => (
                          <TableCell
                            key={column.id}
                            className="tableCell"
                          >
                            <div className="dayDateBox" style={{ backgroundColor: column.id !== "period" ? "#efefef" : "" }}>{column.id !== "period" && <div>
                              <span className="font_15">{column.label}</span>
                              <span className="font_14 dateTypo">{moment(column.dayDate).format("DD/MM")}</span>
                            </div>}</div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {this.state.classPeriodTimeTableRow?.length ?
                        this.state.classPeriodTimeTableRow?.map((row: any, index: any) => {
                          return (
                            <TableRow role="checkbox" tabIndex={-1} key={index}>


                              {this.state.dayColumns.map((column: any, indexTwo: any) => {
                                let value: any = ""
                                if (column.id === "monday") {
                                  if (Object.keys(row.monday).length) {
                                    let key = Object.keys(row.monday)
                                    value = row.monday[key[0]]
                                  }
                                  else {
                                    value = ""
                                  }
                                } else if (column.id === "tuesday") {
                                  if (Object.keys(row.tuesday).length) {
                                    let key = Object.keys(row.tuesday)
                                    value = row.tuesday[key[0]]
                                  }
                                  else {
                                    value = ""
                                  }
                                } else if (column.id === "wednesday") {
                                  if (Object.keys(row.wednesday).length) {
                                    let key = Object.keys(row.wednesday)
                                    value = row.wednesday[key[0]]
                                  }
                                  else {
                                    value = ""
                                  }
                                } else if (column.id === "thursday") {
                                  if (Object.keys(row.thursday).length) {
                                    let key = Object.keys(row.thursday)
                                    value = row.thursday[key[0]]
                                  }
                                  else {
                                    value = ""
                                  }
                                } else if (column.id === "friday") {

                                  if (Object.keys(row.friday).length) {
                                    let key = Object.keys(row.friday)
                                    value = row.friday[key[0]]
                                  }
                                  else {
                                    value = ""
                                  }

                                } else if (column.id === "saturday") {
                                  if (Object.keys(row.saturday).length) {
                                    let key = Object.keys(row.saturday)
                                    value = row.saturday[key[0]]
                                  }
                                  else {
                                    value = ""
                                  }
                                } else if (column.id === "sunday") {
                                  if (Object.keys(row.sunday).length) {
                                    let key = Object.keys(row.sunday)
                                    value = row.sunday[key[0]]
                                  }
                                  else {
                                    value = ""
                                  }
                                }

                                return column.id === "period" ? (
                                  <TableCell
                                    key={indexTwo}
                                    className="periodTableCell fw600"
                                  >
                                    <Typography className="font_16 fw600">
                                      {row.schoolPeriodDetails?.attributes?.name}
                                    </Typography>
                                    <Typography className="font_14 darkColor" component="p">
                                      {`${moment(row.schoolPeriodDetails.attributes.start_time).format('hh:mm A')}-${moment(row.schoolPeriodDetails.attributes.end_time).format('hh:mm A')}`}
                                    </Typography>
                                  </TableCell>
                                ) : (

                                  <>



                                    {
                                      value && value[0]?.attributes?.periods.length > 0 && value[0]?.attributes?.periods[1]?.data && Object.keys(value[0]?.attributes?.periods[1]?.data).length ? <TableCell
                                        key={indexTwo}
                                        className="tableCell"
                                      >

                                        <div className="emptyCell empty" onClick={() => {

                                          this.setState({ selectedDataForView: value[0], disableEditButton: this.handleIsValidTime(column.dayDate, row) }, () => this.setState({ viewClassPeriodDialog: true }))
                                        }
                                        } style={{ backgroundColor: this.getCellBgColor(column.id),}}>
                                          <Grid className="w100">
                                            <Tooltip
                                              title={
                                                <h6 className="dataTypo">
                                                  {value[0]?.attributes?.periods[1]?.data?.attributes?.subject?.data?.attributes?.subject_name}
                                                </h6>
                                              }
                                              placement="top"
                                              enterTouchDelay={100}
                                            ><span className="fileNameText22 font_18 fw500" style={{ color: this.getCellTextColor(column.id) }}> {value[0]?.attributes?.periods[1]?.data?.attributes?.subject?.data?.attributes?.subject_name}</span>

                                            </Tooltip>
                                            <div className="flexBetween">
                                              <Avatar
                                                src={value[0]?.attributes?.periods[1]?.data?.attributes?.teacher?.avatar}
                                                className="avatarImg"
                                              />
                                              <img
                                                className="editImg"
                                                src={editable}
                                                style={{
                                                  opacity: this.handleIsValidTime(column.dayDate, row) ? '0.6' : "1"
                                                }}
                                                onClick={(e: any) => {
                                                  this.editImgOnClick(e,column, row,value)
                                                }}
                                              />
                                            </div>
                                          </Grid>
                                        </div>
                                      </TableCell> :
                                        value ? <TableCell
                                          key={indexTwo}
                                          className="tableCell tablecell-outer"
                                        >
                                          <div className="emptyCell" style={{ backgroundColor: this.getCellBgColor(column.id) }}>
                                            {value[0]?.attributes?.period_type === "Break" || (value[0]?.attributes?.periods[0]?.meta && value[0]?.attributes?.periods[0]?.meta.filter_data && value[0]?.attributes?.periods[0]?.meta?.period_present) ? <div className="h48"> </div> :
                                              <img
                                                className="addImg"
                                                src={addIcon}
                                                style={{
                                                  cursor: this.handleIsValidTime(column.dayDate, row) ? "unset" : "pointer",
                                                  opacity: this.handleIsValidTime(column.dayDate, row) ? '0.5' : "1",
                                                }}
                                                onClick={() => {
                                                  this.addImgOnClick(column, row, value)
                                                }}
                                              />
                                            }
                                          </div>
                                        </TableCell> : <TableCell key={indexTwo}
                                          className="tableCell"></TableCell>
                                    }
                                  </>
                                );
                              })}
                            </TableRow>
                          );
                        }) :
                        <span className="font_18 fw500">No data</span>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            {this.state.timeLineMeta?.pagination?.total_pages > 1 && (
              <div className="paginationBox">
                <ReactPaginate
                  previousLabel={"←   Previous"}
                  nextLabel={"Next   →	"}
                  breakLabel="..."
                  initialPage={this.state.pagination_page}
                  pageCount={this.state.timeLineMeta?.pagination?.total_pages}
                  onPageChange={(e: any) => this.onPaginateChange(e)}
                  containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                  previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                  nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                  disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                  activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                  pageRangeDisplayed={
                    this.state.timeLineMeta?.pagination?.total_pages
                  }
                  marginPagesDisplayed={0}
                />
              </div>
            )}
          </div>

          <UploadModal
            open={this.state.uploadModal}
            close={() => this.closeUploadModal()}
          />
        </div>
        <AddEditClassPeriod
          addEditClassPeriodDialog={openAddEditClassPeriod}
          handleAddEditClassPeriodDialogClose={this.handleAddEditClassPeriodDialogClose}
          isEditPeriod={isEditPeriod}
          classStartDate={classStartDate}
          onChangeStartDate={(date: any) => { this.setState({ classStartDate: moment(date).format("DD MM YYYY") })}}
          weekDay={weekDay}
          addEditClassPeriodClick={() => { this.add_edit_period() }}
          batchData={batchData}
          selectedBatch={this.state.selectedBatchData}
          periodData={schoolperiodData}
          selectedPeriod={selectedPeriod}
          schoolPeriodName={this.state.schoolPeriodName}
          onChangeSchoolPeriodName={this.onChangeSchoolPeriodName}
          classData={classData}
          selectedClass={selectedClass}
          subjectData={subjectData}
          selectedMonthDate={this.state.selectedMonthDate}
          selectedSubject={selectedSubject}
          isEditable={this.state.isEditable}
          isOneTime={this.state.isOneTime}
          isOnline={this.state.isOnline}
          selectedSubjectError={selectedSubjectError}
          teacherData={teacherData}
          selectedTeacher={selectedTeacher}
          selectedTeacherError={selectedTeacherError}
          onChangeSubject={this.onChangeSubject}
          onBlurSubject={this.onBlurSubject}
          onChangeTeacher={this.onChangeTeacher}
          onBlurTeacher={this.onBlurTeacher}
          onChangeIsEditable={this.onChangeIsEditable}
          onChangeIsOneTime={this.onChangeIsOneTime}
          onChangeIsOnline={this.onChangeIsOnline}
        />

        <ViewClassPeriod
          viewClassPeriodDialog={this.state.viewClassPeriodDialog}
          disableEditButton={this.state.disableEditButton}
          handleViewClassPeriodDialogClose={() => this.setState({ viewClassPeriodDialog: false, disableEditButton: false })}
          selectedDataForView={this.state.selectedDataForView}
          editClassPeriodClick={this.edit_class_period_from_view}
          deleteClassPeriodClick={this.delete_class_period}
        />

        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: "right",
          }}
          id="basic-menu"
          anchorEl={this.state.anchorEl1}
          open={Boolean(this.state.anchorEl1)}
          onClose={() => { this.setState({ anchorEl1: '' }) }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          className="menuBoxContainer"
        >
          <Box className="mainBox">
            <MenuItem

              onClick={() => {
                this.openUploadModal(), this.setState({ anchorEl1: '' })
              }}
            >
              <Grid container className="justifyC">
                <Grid item xs={12} className="flexCenter">
                  <img src={uploadIcon} className="img" />
                  <Typography
                    className={`uploadTypo ${this.props.classes.theme_font}`}
                  >Upload File</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <Divider className="divider" />
            <MenuItem

              onClick={() => this.setState({ createPeriodDialog: true }, () => this.setState({ anchorEl1: '' }))}
            >
              <Grid container className="justifyC">
                <Grid item xs={12} className="flexCenter" >
                  <img src={autoGenerateIcon} className="imgAutoGenerated" />
                  <Typography
                    className={`uploadTypo ${this.props.classes.theme_font}`}
                  >Auto Generate</Typography>
                </Grid>
              </Grid>
            </MenuItem>


          </Box>
        </Menu>
        {this.state.createPeriodDialog && <AutomaticClassPeriod
          createPeriodDialog={this.state.createPeriodDialog}
          handleDialogClose={this.handleDialogClose}
        />}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(AdminClassPeriod);
// Customizable Area End