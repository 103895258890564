    // Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  Modal,
  Backdrop,
  Box,
  withStyles,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import QuestionsComponentWeb from "./QuestionsComponent.web";
import { HISTORY } from "../../../../components/src/comman";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import "./QuestionBank.web.css";
// Customizable Area End

// Customizable Area Start
interface IClassProps {
  id: any;
  display_name: string;
}
interface IBaseClassProps {
  class?: IClassProps;
  attributes?: {
    name: string;
  };
}
interface ISubjectProps {
  id: any;
  subject_name: string;
}
interface IProps {
  openModal: boolean;
  classes?: any;
  questionsList?: any;
  handleOnClose: React.Dispatch<React.SetStateAction<boolean>>;
}
// Customizable Area End

export const PreviousSelectedQuestion: React.FC<IProps> = ({
  // Customizable Area Start
  openModal,
  classes,
  questionsList,
  handleOnClose,
  // Customizable Area End
}) => {
  // Customizable Area Start
  const [currentClass, setCurrentClass] = useState<IBaseClassProps>();
  const [currentSubject, setCurrentSubject] = useState<ISubjectProps>();
  const [questionlist, setQuestionList] = useState([]);

  useEffect(() => {
    if (openModal) {
      setQuestionList(questionsList);
    }
    if (HISTORY.location.state) {
      setCurrentClass(HISTORY.location.state.currentClass);
      setCurrentSubject(HISTORY.location.state.subject);
      window.history.replaceState(HISTORY.location.pathname, "");
    }
  }, [openModal]);
  // Customizable Area End

  // Customizable Area Start
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="PreviewQuestionsMainModal"
        open={openModal}
        onClose={() => {
          handleOnClose(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        id="previous-selected-question-modal"
        data-test-id="previous-selected-question-modal"
      >
        <Box className="PreviewQuestionListModal">
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Box
                  className="TitleBox"
                >
                  <Typography className={`PreviewTitle ${classes.subTitle_font}`}>
                    {`Assessment Library > Question Bank > ${currentClass?.attributes?.name} > ${currentSubject?.subject_name}`}
                  </Typography>
                  <Typography>
                    <CloseIcon
                      className="CloseIcon"
                      data-test-id="question-close-icon"
                      onClick={() => handleOnClose(false)}
                    />
                  </Typography>
                </Box>
                <Box className="TotalQuestionBox">
                  <Typography className={`PreviewTitle ${classes.subTitle_font}`}>
                    Total {questionlist.length} questions have been selected.
                  </Typography>
                </Box>
                <Typography className="QuestionDivider" />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <div className="PreviewQuestionsDiv">
                {questionlist.length > 0 &&
                  questionlist?.map((question: any, index: any) => (
                    <React.Fragment key={index}>
                      <QuestionsComponentWeb
                        question={question}
                        index={index}
                      />
                    </React.Fragment>
                  ))}
              </div>
            </Grid>

            <Grid item xs={12} className="ButtonGrid">
              <Button
                onClick={() => handleOnClose(false)}
                data-test-id="question-ok-btn"
                className={`OkButton ${classes.button_color}`}
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
  // Customizable Area End
};

// Customizable Area Start
export default withStyles(themeCreateStyle)(PreviousSelectedQuestion);
// Customizable Area End
