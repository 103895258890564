// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import moment from "moment";
import { HISTORY } from "../../../../../components/src/comman";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: any;
  listView: string;
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  openModal: boolean;
  feedback: string | null;
  teacherAssociated: string | null;
  changeView: boolean;
  subjectView: boolean;
  currentYear: boolean;
  pdfView: boolean;
  page: number;
  queryTerm: any;
  startDate: any;
  endDate: any;
  tabValue: number;
  classData: any;
  subjectData: any;
  selectedClassId: string;
  selectedSubjectId: string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class GradeBookController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetClassData: string = "";
  apiGetSubjectData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      openModal: false,
      feedback: "",
      teacherAssociated: "",
      changeView: false,
      currentYear: false,
      tabValue: 0,
      subjectView: false,
      pdfView: false,
      page: 0,
      queryTerm: "",
      startDate: moment(new Date()),
      endDate: moment(new Date()),
      classData: [],
      subjectData: [],
      selectedClassId: '',
      selectedSubjectId: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    this.getClassData()
    if (HISTORY.location.state.tabValue != undefined) {
      this.setState({
        tabValue: HISTORY.location.state.tabValue,
      })
    }
  }

  getSubHeaderBg = (index: number) => {
    switch (index) {
      case 1:
        return "#e9fffa"
      case 2:
        return "#e7e3fa"
      case 3:
        return "#ffefd6"
      case 4:
        return "#d6f0ff"
      case 5:
        return "#f7dcff"
      default:
        return "#e9fffa"
    }
  }

  onChangeSearchTerm = (event: any) => {
    this.setState({ queryTerm: event.target.value })
  }

  handleTansChange = {
    onChangeTab: (num: number) => {
      this.setState({ tabValue: num });
    },
  };

  handleSubjectChange = (subject_id: any) => {
    this.setState({
      selectedSubjectId: subject_id
    })
  }

  handleClassChange = (class_id: any) => {
    this.setState({
      selectedClassId: class_id
    }, () => {
      this.getSubjectData(class_id)
    })
  }
  // Customizable Area End


  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      switch (apiRequestCallId) {
        case this.apiGetClassData:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({
                  classData: responseJson.data,
                  selectedClassId: responseJson.data[0].id,
                  selectedSubjectId: ''
                }, () => {
                  this.getSubjectData(responseJson.data[0].id)
                })
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiGetSubjectData:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({
                  subjectData: responseJson.data,
                  selectedSubjectId: '',
                })
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
      }

    }
    // Customizable Area End
  }
  // Customizable Area Start

  getClassData = () => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
      school: school_Data?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetClassData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClassListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSubjectData = (class_id: any) => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
      school: school_Data?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSubjectData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectListEndPoint + `?class_id=${class_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
