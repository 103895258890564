import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";

// Customizable Area Start
// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showLoader: boolean;
  classList: any;
  title: string;
  selectedClass: any;
  numbersList: any;
  selectedNumberOfPart: any;
  modulesList: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateReportCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostReportCardList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      showLoader: false,
      classList: [],
      title: "",
      selectedClass: "",
      numbersList: [1, 2, 3],
      selectedNumberOfPart: "",
      modulesList: [
        {
          id: 1,
          name: "General Details",
          description: "",
        },
        {
          id: 2,
          name: "Part-1 Scholastic Area",
          description: "(8 point scale)",
        },
        {
          id: 3,
          name: "Part-2 Co-Scholastic Area",
          description: "(3 point scale)",
        },
        {
          id: 4,
          name: "Discipline",
          description: "(3 point scale)",
        },
        {
          id: 5,
          name: "Grading System",
          description: "",
        },
      ],
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleBack = () => {
    HISTORY.goBack();
  };

  onChangeReportTitle = (event: any) => {
    this.setState({ title: event.target.value })
  }

  onChangeClass = (event: any) => {
    this.setState({ selectedClass: event.target.value })
  }

  onChangeNumberOfPart = (event: any) => {
    this.setState({ selectedNumberOfPart: event.target.value })
  }

  startToCreateClick = () => [
    HISTORY.push('/CreateReportCardStep2')
  ]

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apiPostReportCardList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ showLoader: false });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }


  // Customizable Area End
}
