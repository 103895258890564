// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as SparkMD5 from "spark-md5";
import { toast } from "react-toastify";
import axios from "axios";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
import { boolean } from "yup";
import { FieldArray } from "formik";
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
    // Customizable Area Start
    navigation: any;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    addProtfolioType: any;
    fileName: any;
    uploadFile: any;
    data: any;
    showCompletedGoals: boolean;
    imageArray: any;
    imageCount: number;
    fileArray: any;
    fileUploadDetail: any;
    sihnIdData: any;
    anchorEl: any;
    isgoalCompleted: boolean;
    isLiked: boolean;
    inputStr: string;
    showPicker: boolean;
    studentGoals: any;
    goal_id: any;
    deleteGoal: boolean;
    deleteResource: boolean;
    pendingStudentGoals: any;
    completedStudentGoals: any;
    bulkFileUploaded: any;
    post_type: any;
    post_description: string;
    alertModal: boolean;
    alertMessage: string;
    files: any;
    resourceId: any;
    openComment: boolean;
    shareScreenAnchorEl: any;
    filterAnchorEl: any;
    timelineData: any;
    timelineanchorEl: any;
    deleteTimeline: boolean;
    start_date: any;
    end_date: any;
    post_page: number;
    query: string;
    searchPost: any;
    timeLineMeta: any;
    edit_type: any;
    edit_goal_id: any;
    userAnchorEl: boolean;
    userData: any;
    previewImage: boolean;
    dropDownValue: boolean;
    id: any;
    prePostPreview: boolean;
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class StudentProtfolioController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    postPresignID: string = '';
    apigetStudentGoalsId: string = '';
    apigetStudentFilterGoalsId: string = '';
    apiStudentlikesGoalsId: string = '';
    apiCompletedGoalsId: string = '';
    apiPreSignedID: any = [];
    apiSubmitPostId: string = '';
    apideleteGoalId: string = '';
    apigetTimelineId: string = '';
    apigetUserSearchId: string = '';
    apideleteResourceId: any;
    apiGetFiles: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            addProtfolioType: '',
            fileName: '',
            uploadFile: '',
            data: [],
            showCompletedGoals: false,
            imageArray: [],
            imageCount: 0,
            fileArray: [],
            fileUploadDetail: [],
            sihnIdData: [],
            anchorEl: null,
            isgoalCompleted: false,
            isLiked: false,
            inputStr: '',
            showPicker: false,
            studentGoals: [],
            goal_id: [],
            deleteGoal: false,
            deleteResource: false,
            pendingStudentGoals: [],
            completedStudentGoals: [],
            bulkFileUploaded: [],
            post_type: 'public_post',
            post_description: '',
            alertModal: false,
            alertMessage: '',
            files: '',
            resourceId: '',
            openComment: false,
            shareScreenAnchorEl: '',
            filterAnchorEl: null,
            timelineData: [],
            timelineanchorEl: null,
            deleteTimeline: false,
            start_date: '',
            end_date: '',
            post_page: 0,
            query: '',
            searchPost: [],
            timeLineMeta: null,
            edit_type: [],
            edit_goal_id: [],
            userAnchorEl: false,
            userData: [],
            previewImage: false,
            dropDownValue: false,
            id: [],
            prePostPreview: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.userdata = this.userdata.bind(this);
        this.get_student_goals = this.get_student_goals.bind(this);
        this.get_timeline_posts = this.get_timeline_posts.bind(this);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.get_student_goals();
        this.userdata();
        this.getFiles();
        this.get_timeline_posts();
    }

    cancelResourceChanges = () => {
        this.setState({ addProtfolioType: '' }, () => { this.props.navigation.navigate("StudentFiles") })
    }
    goToResource = () => {
        localStorage.setItem("portfolioType", "Resource")
        this.props.navigation.navigate("StudentAddResouceAndGoals")
    }
    goToEditGoals = () => {
        localStorage.setItem("portfolioType", "EditGoal")
        localStorage.setItem("editGoalId", this.state.goal_id)
        this.props.navigation.navigate("StudentAddResouceAndGoals")
    }
    goToGoals = () => {
        // this.props.navigation.navigate("StudentAddGoals")
        localStorage.setItem("portfolioType", "Goals")
        this.props.navigation.navigate("StudentAddResouceAndGoals")
    }

    onShareClose = () => {
        this.setState({ shareScreenAnchorEl: null })
    }
    onFilterClose = () => {
        this.setState({ filterAnchorEl: null })
    }

    deletePost = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('token'),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apideleteGoalId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getGoalsEndPoint + `${this.state.goal_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteResource = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apideleteResourceId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.deleteResourceApi + `${this.state.resourceId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.SignIDUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    getFiles = () => {
        const header = {
            // "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("token")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetFiles = requestMessage.messageId;

        const httpBody = {
        };

        // requestMessage.addData(
        //   getName(MessageEnum.RestAPIRequestBodyMessage),
        //   JSON.stringify(httpBody)
        // );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postResourceApi
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.baseURLPortfolio
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    goToTimeline = () => {
        this.props.navigation.navigate("StudentProtfolio")
    }
    goToFiles = () => {
        this.props.navigation.navigate("StudentFiles")
    }
    saveFile = (url: any, name: any) => {

        //saveAs()
        //const url1 = URL.createObjectURL(url)
        const header = {
            // "Content-Type": preSignedResponse?.headers?.["Content-Type"],
            // "Content-MD5": preSignedResponse?.headers?.["Content-MD5"],
            token: localStorage.getItem("token"),
        };


        // axios({
        //     url:url,
        //     method:'GET',
        //     responseType:'blob',
        //     headers: header,
        // }).then((response:any)=>{
        //     const url = window.URL.createObjectURL(new Blob([response.data]))
        //     var anchor = document.createElement("a");
        //     anchor.setAttribute("href", url);
        //     //anchor.href = await this.toDataURL(url);
        //     anchor.setAttribute("download", name);
        //     anchor.click();
        // })

        const url1: any = window.URL.createObjectURL(new Blob([url]))

        var anchor = document.createElement("a");
        anchor.href = url
        anchor.download = name
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        // anchor.setAttribute("href", url);
        // //anchor.href = await this.toDataURL(url);
        // anchor.setAttribute("download","download");
        // anchor.click();
        //    a.download =name;

        // 

        // anchor.setAttribute("target", "blank");

        //toast.success("List exported successfully.")
    }


    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }
    imageHandler = (event: any) => {
        const validFileType = [
            "image/png",
            "image/jpg",
            "image/jpeg",
            "image/gif",
            "video/mp4",
        ];
        if (event.target.files.length > 0) {
            for (let i = 0; i < event.target.files.length; i++) {
                if (!validFileType.includes(event.target.files[i].type)) {
                    this.setState({
                        alertMessage:
                            "Please upload valid file format: .png .jpg .jpeg .gif .mp4",
                        alertModal: true,
                    });
                }
                else if (event.target.files[i].size > 16777216) {
                    this.setState({
                        alertMessage: "Maximum files size: 16MB",
                        alertModal: true,
                    });
                }
                else {
                    this.getBase64(event.target.files[i], i)
                }

            }
        }
        this.handleUpload(event.target.files)
    }
    fileHandler = (event: any) => {
        const validFileType = [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel"
        ];
        if (event.target.files.length > 0) {
            for (let i = 0; i < event.target.files.length; i++) {
                if (!validFileType.includes(event.target.files[i].type)) {
                    this.setState({
                        alertMessage:
                            "Please upload valid file format: .doc .xls or .pdf",
                        alertModal: true,
                    });

                }
                else if (event.target.files[i].size > 16777216) {
                    this.setState({
                        alertMessage: "Maximum files size: 16MB",
                        alertModal: true,
                    });
                }
                else {
                    this.getFileBase64(event.target.files[i])
                }
            }
        }
        this.filehandleUpload(event.target.files)

    }
    getFileBase64 = (file: any) => {
        this.setState((previousState) => ({
            fileArray: [...previousState.fileArray, file]
        }))

    }
    getBase64 = (file: any, index: number) => {
        const self = this;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            self.setState((previousState) => ({
                imageArray: [...previousState.imageArray, { id: index, src: reader.result, type: file.type }]
            }))
        };
        reader.onerror = function (error) {
        };
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));


            if (apiRequestCallId === this.apiGetFiles) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        const arr = []
                        this.setState({ files: responseJson.data })
                        responseJson.data.map((_data: any) => {
                            _data.attributes.file.data.map((_file: any) => {

                                //this.setState({files:[...this.state.files , _file]})
                            })
                        })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }

            else if (apiRequestCallId === this.apigetStudentGoalsId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({
                            pendingStudentGoals: responseJson.data.filter((res: any) => res.attributes.is_completed === false),
                            completedStudentGoals: responseJson.data.filter((goals: any) => goals.attributes.is_completed === true)
                        })
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }

            else if (apiRequestCallId === this.apigetStudentGoalsId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({
                            pendingStudentGoals: responseJson.data.filter((res: any) => res.attributes.is_completed === false),
                            completedStudentGoals: responseJson.data.filter((goals: any) => goals.attributes.is_completed === true)
                        })
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }

            else if (apiRequestCallId === this.apigetStudentFilterGoalsId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({
                            pendingStudentGoals: responseJson.data.filter((res: any) => res.attributes.is_completed === false),
                            completedStudentGoals: responseJson.data.filter((goals: any) => goals.attributes.is_completed === true)
                        })
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apigetTimelineId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ timelineData: responseJson.data, timeLineMeta: responseJson.meta })
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apigetUserSearchId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ userData: responseJson.data })
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiStudentlikesGoalsId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.get_timeline_posts()
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiCompletedGoalsId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (responseJson.data.attributes.is_completed === true) {
                            toast.success("Goal Completed")
                        }
                        this.get_student_goals();
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiSubmitPostId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({
                            post_description: '',
                            fileArray: [],
                            imageArray: [],
                            bulkFileUploaded: [],
                            sihnIdData: []
                        })
                        toast.success("The post has been added successfully.")
                        this.get_timeline_posts();
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiGetFiles) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        const arr = []
                        this.setState({ files: responseJson.data })
                        responseJson.data.map((_data: any) => {
                            _data.attributes.file.data.map((_file: any) => {

                                //this.setState({files:[...this.state.files , _file]})
                            })
                        })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apideleteGoalId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        toast.success("The goal has been deleted successfully.")
                        this.setState({ deleteGoal: !this.state.deleteGoal, anchorEl: null })
                        this.get_student_goals();
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apideleteResourceId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        toast.success("The file has been deleted successfully.")
                        this.setState({ deleteResource: false, anchorEl: null })
                        this.getFiles();
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === ((this.apiPreSignedID.indexOf(apiRequestCallId) != -1) && apiRequestCallId)) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        const { bulkFileUploaded } = this.state;
                        bulkFileUploaded?.map((item: any, index: number) => {
                            if (item.requestID === apiRequestCallId) {
                                item.signedInId = responseJson?.data[0].signed_id;
                                item.url = responseJson?.data[0].url;
                            }
                        });

                        this.setState({ bulkFileUploaded: bulkFileUploaded });

                        this.saveImageUsingURL(responseJson?.data[0]);
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }

        }
        // Customizable Area End
    }
    onClose = () => {
        this.setState({ deleteGoal: false, openComment: false, anchorEl: null })
        this.get_student_goals()
        this.get_timeline_posts()
    }

    onCloseResource = () => {
        this.setState({ deleteResource: false })
    }


    saveImageUsingURL = async (preSignedResponse: any) => {
        const { bulkFileUploaded } = this.state;
        let ind: number = 0;
        bulkFileUploaded?.map((item: any, index: number) => {
            if (item.signedInId === preSignedResponse?.signed_id) {
                ind = index;
            }
        });

        const header = {
            "Content-Type": preSignedResponse?.headers?.["Content-Type"],
            "Content-MD5": preSignedResponse?.headers?.["Content-MD5"],
            token: localStorage.getItem("token"),
        };

        const httpBody = bulkFileUploaded[ind].file;


        try {
            // const s3Response = await axios({
            //     method: "put",
            //     url: preSignedResponse.url,
            //     data: httpBody,
            //     headers: header
            // });
        }
        catch (e) {
            toast.error('Something went wrong.');
        }
    }

    computeChecksumMd5 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const chunkSize = 2097152; // Read in chunks of 2MB
            const spark = new SparkMD5.ArrayBuffer();
            const fileReader = new FileReader();

            let cursor = 0; // current cursor in file

            fileReader.onerror = function (): void {
                reject('MD5 computation failed - error reading the file');
            };

            // read chunk starting at `cursor` into memory
            function processChunk(chunk_start: number): void {
                const chunk_end = Math.min(file.size, chunk_start + chunkSize);
                fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
            }

            // when it's available in memory, process it
            // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead 
            // of `any` for `e` variable, otherwise stick with `any`
            // See https://github.com/Microsoft/TypeScript/issues/25510
            fileReader.onload = function (e: any): void {
                spark.append(e.target.result); // Accumulate chunk to md5 computation
                cursor += chunkSize; // Move past this chunk

                if (cursor < file.size) {
                    // Enqueue next chunk to be accumulated
                    processChunk(cursor);
                } else {
                    // Computation ended, last chunk has been processed. Return as Promise value.
                    // This returns the base64 encoded md5 hash, which is what
                    // Rails ActiveStorage or cloud services expect
                    resolve(btoa(spark.end(true)));

                    // If you prefer the hexdigest form (looking like
                    // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
                    // resolve(spark.end());
                }
            };

            processChunk(0);
        });
    }

    filehandleUpload = (files: any) => {
        let fileSize: number = 0;
        let allowUpload: boolean = true;
        let { bulkFileUploaded } = this.state;
        const validFileType = [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel"
        ];
        const keys = Object.keys(files);
        keys?.map((key: any) => {
            const file = files[key];
            if (!validFileType.includes(file?.type)) {
                allowUpload = false;
                this.setState({
                    alertMessage:
                        "Please upload valid file format: .doc .xls or .pdf",
                    alertModal: true,
                });
            }
        });
        keys?.map((key: any) => {
            const file = files[key].size;
            fileSize = fileSize + file;
            if (fileSize > 16777216) {
                allowUpload = false;
                this.setState({
                    alertMessage: "Maximum files size: 16MB",
                    alertModal: true,
                });
            }
        });
        if (allowUpload) {
            keys?.map(async (key: any) => {
                const file = files[key];
                let fileUpload: any = {};

                fileUpload = {
                    fileName: file.name,
                    name: file.name.split(".")[0],
                    isExistingFile: false,
                    fileSize: file.size,
                    percentage: 0,
                    file: file,
                    content_type: file.type,
                };
                bulkFileUploaded = [...bulkFileUploaded, fileUpload];
                this.setState({ bulkFileUploaded: bulkFileUploaded });
                let checksum = "";
                await this.computeChecksumMd5(file).then((md5: any) => {
                    checksum = md5;
                    fileUpload.checksum = checksum;
                    this.getPreSignedId(fileUpload);
                });
            });
        }
    }
    handleUpload = (files: any) => {
        let fileSize: number = 0;
        let allowUpload: boolean = true;
        let { bulkFileUploaded } = this.state;
        const validFileType = [
            "image/png",
            "image/jpg",
            "image/jpeg",
            "image/gif",
            "video/mp4",
        ];
        const keys = Object.keys(files);
        keys?.map((key: any) => {
            const file = files[key];
            if (!validFileType.includes(file?.type)) {
                allowUpload = false;
                this.setState({
                    alertMessage:
                        "Please upload valid file format: .png .jpg .gif .mp4 or .jpeg",
                    alertModal: true,
                });
            }
        });
        keys?.map((key: any) => {
            const file = files[key].size;
            fileSize = fileSize + file;
            if (fileSize > 16777216) {
                allowUpload = false;
                this.setState({
                    alertMessage: "Maximum files size: 16MB",
                    alertModal: true,
                });
            }
        });
        if (allowUpload) {
            keys?.map(async (key: any) => {
                const file = files[key];
                let fileUpload: any = {};

                fileUpload = {
                    fileName: file.name,
                    name: file.name.split(".")[0],
                    isExistingFile: false,
                    fileSize: file.size,
                    percentage: 0,
                    file: file,
                    content_type: file.type,
                };
                bulkFileUploaded = [...bulkFileUploaded, fileUpload];
                this.setState({ bulkFileUploaded: bulkFileUploaded });
                let checksum = "";
                await this.computeChecksumMd5(file).then((md5: any) => {
                    checksum = md5;
                    fileUpload.checksum = checksum;
                    this.getPreSignedId(fileUpload);
                });
            });
        }
    }

    getPreSignedId = async (fileUploadDetail: any) => {
        const { bulkFileUploaded } = this.state;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("token"),
        };

        const httpBody = {
            filename: fileUploadDetail.fileName,
            byte_size: fileUploadDetail.fileSize,
            checksum: fileUploadDetail.checksum,
            content_type: fileUploadDetail.content_type,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.SignIDUrl
        );

        this.apiPreSignedID[this.apiPreSignedID.length] = requestMessage.messageId;

        bulkFileUploaded.map((item: any) => {
            if (item.checksum === fileUploadDetail.checksum) {
                item.requestID = requestMessage.messageId;
            }
        });

        await this.setState({ bulkFileUploaded: bulkFileUploaded });

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fileUploadEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );

        const result = runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    dataURLtoFile = (base64: any, fileName: string) => {
        var arr = base64.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
    }

    get_student_goals = () => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetStudentGoalsId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getGoalsEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    get_user_search = (value: any) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetUserSearchId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.userSearchEndPoint + `?search=${value}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.SignIDUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    get_filter_student_goals = (startdate: any, endDate: any) => {
        this.setState({ start_date: moment(startdate).format("YYYY-MM-DD"), end_date: moment(endDate).format("YYYY-MM-DD") })
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetStudentFilterGoalsId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getGoalsEndPoint + `?from=${moment(startdate).format("DD-MM-YYYY")}&to=${moment(endDate).format("DD-MM-YYYY")}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    post_like = (type: any, id: any, like: boolean) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiStudentlikesGoalsId = requestMessage.messageId;
        const httpBody = {
            post_type: type === 'post' ? 'Post' : (type === 'goals' && 'Goal'),
            id: parseInt(id),
            liked: like,
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.likeGoalsEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    post_Submit = () => {
        let { bulkFileUploaded, post_type, sihnIdData, post_description } = this.state;
        if (post_type.length === 0) {
            toast.error("please select post type")
            return false
        }

        bulkFileUploaded.map((item: any) => {
            sihnIdData = [...sihnIdData, item.signedInId];
            this.setState({ sihnIdData: sihnIdData });

        })

        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiSubmitPostId = requestMessage.messageId;

        const httpBody = {
            description: post_description,
            visibility_type: post_type,
            attachments: sihnIdData
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.submitPostEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    completed_goals = (completed: any, id: any) => {
        const data = {
            "is_completed": completed
        }
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCompletedGoalsId = requestMessage.messageId;

        const httpBody = data;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getGoalsEndPoint + `${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    get_timeline_posts = (pageNumber?: number) => {
        const page = (typeof pageNumber === "number") ? pageNumber : this.state.post_page
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetTimelineId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTimelineEndPoint + `?page=${page}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    get_search_posts = (text: any) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetTimelineId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTimelineEndPoint + `?search=${text}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}