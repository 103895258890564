// Customizable Area Start
import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../../../shared/Header/src/HOCAuth.web";
import ParentFeesController, {
  Props,
} from "./ParentFeesController.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import "./ParentFees.web.css";
import { Button, Typography, Chip } from "@material-ui/core";
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const StyleChip = withStyles({
  root: {
    backgroundColor: '#f6ecdd',
    color: '#e69416',
    fontSize: '14px',
    padding: '4px 22px',
    marginLeft: '17px'
  }
})(Chip);
// Customizable Area End

export class ParentFees extends ParentFeesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { loader } = this.state;
    return (
      <>
        {loader && <Spinner spinnerModal={loader} />}
        <Grid container className={this.props.classes.parentFees_dashboard_margin_tb} spacing={2}>
          <Grid item xs={12} sm={8} >
            <Typography className={`${this.props.classes.parentFees_dashboard_font_20} ${this.props.classes.subHeading_font} fees-fw-600`}>
              Term 2 Tution Fees <StyleChip color='primary' label="Payment Due" />
            </Typography>
            <Typography className={`${this.props.classes.parentFees_dashboard_font_14} ${this.props.classes.bodyText_font} ${this.props.classes.parentFees_dashboard_margin_top} fees-fw-600`}>Date: 01/01/2020 to 31/12/2021</Typography>
          </Grid>
          <Grid container item xs={12} sm={4} justify="flex-end">
            <Button variant="contained" className={`${this.props.classes.button_color} fees_button`}>
              <Typography
                className={`${this.props.classes.font_22} fees-fw-600`}
              >
                Pay ₹5,500.00
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(ParentFees, "TeacherAccount")
);
// Customizable Area End
