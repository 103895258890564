// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
import _ from "lodash";
import { toast } from "react-toastify";
import convert from "xml-js";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
export interface SectionListItem {
    id: number;
    name: string;
    section_marks: any;
    questions: any;
    question_page_no: number;
    question_per_page: number;
    meta_data: any;
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    sectionList: Array<SectionListItem>;
    sectionListMeta: any;
    page: number;
    rowsPerPage: number;
    showLoader: boolean;
    selectedSectionId: number;
    openSubmitModal: boolean;
    showSubmitSection: boolean;
    previewImageModal: boolean;
    previewImageSrc: any;
    assessmentId: any;
    studentDetails: any;
    annotatedFile: any;
    selectedAnnotationQuestionId: number;
    selectedAnnotationAttachmentId: number;
    sectionId: number;
    totalAttempted: any;
    totalMarks: any;
    isSubmitable: boolean;
    sectionPageNum: number;
    sectionPerPage: number;
    questionListMeta: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AssessmentEvaluationDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetSectionList: string = "";
    apiSaveSectionList: string = "";
    apiSubmitSectionList: string = "";
    apiFilePreSignedID: string = "";
    apiUploadBlockId: string = "";
    apiGetSectionQuestionsList: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            sectionList: [],
            sectionListMeta: {},
            page: 0,
            rowsPerPage: 10,
            showLoader: false,
            selectedSectionId: 0,
            openSubmitModal: false,
            showSubmitSection: false,
            previewImageModal: false,
            previewImageSrc: "",
            assessmentId: "",
            studentDetails: "",
            annotatedFile: null,
            selectedAnnotationQuestionId: 0,
            selectedAnnotationAttachmentId: 0,
            sectionId: 0,
            totalAttempted: "",
            totalMarks: "",
            isSubmitable: false,
            sectionPageNum: 1,
            sectionPerPage: 15,
            questionListMeta: {},
        }

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            switch (apiRequestCallId) {
                case this.apiGetSectionList:
                    {
                        if (responseJson != null) {
                            if (!responseJson.errors) {
                                const responseData = responseJson?.data;
                                const sectionArray: any = []
                                responseData?.sections?.map((item: any) => {
                                    const updatedItem = {
                                        id: item?.id,
                                        name: item?.name,
                                        section_marks: item?.section_marks,
                                        questions: item?.questions,
                                        question_page_no: 1,
                                        question_per_page: 5
                                    }
                                    sectionArray.push(updatedItem)
                                })

                                if (this.state.sectionPageNum === 1) {
                                    this.setState({
                                        sectionList: sectionArray,
                                    })
                                } else {
                                    this.setState({
                                        sectionList: [...this.state.sectionList, ...sectionArray],
                                    })
                                }
                                this.setState({
                                    totalAttempted: responseData?.total_attempted,
                                    totalMarks: responseData?.total_marks,
                                    isSubmitable: responseData?.is_submitable,
                                    sectionListMeta: responseJson?.meta,
                                })
                            } else {
                                this.parseApiErrorResponse(responseJson);
                            }
                        }
                        this.setState({ showLoader: false })
                        this.parseApiCatchErrorResponse(errorReponse);
                    }
                    break;
                case this.apiSaveSectionList:
                    {
                        if (responseJson != null) {
                            if (!responseJson.errors) {
                                const metaData = responseJson?.meta;
                                this.setState({
                                    totalAttempted: metaData?.total_attempted,
                                    totalMarks: metaData?.total_marks,
                                    isSubmitable: metaData?.is_submitable,
                                })
                                toast.success('Evaluation details saved successfully.')
                            } else {
                                this.parseApiErrorResponse(responseJson);
                            }
                        }
                        this.setState({ showLoader: false })
                        this.parseApiCatchErrorResponse(errorReponse);
                    }
                    break;
                case this.apiSubmitSectionList:
                    {
                        if (responseJson != null) {
                            if (!responseJson.errors) {
                                toast.success('Evaluation details submitted successfully.')
                                HISTORY.push({
                                    pathname: "/AssessmentEvaluation",
                                    state: { assessmentId: this.state.assessmentId },
                                });
                            } else {
                                this.parseApiErrorResponse(responseJson);
                            }
                        }
                        this.setState({ showLoader: false })
                        this.parseApiCatchErrorResponse(errorReponse);
                    }
                    break;
                case this.apiFilePreSignedID:
                    {
                        if (responseJson != null) {
                            if (!responseJson.errors) {
                                const imageData = this.state.annotatedFile

                                const msg: Message = new Message(
                                    getName(MessageEnum.UploadMediaMessage)
                                );
                                const uploadFileData: any = {
                                    responseJson: responseJson,
                                    imageData,
                                    messageId: msg.messageId
                                };
                                msg.addData(
                                    getName(MessageEnum.UploadMediaDataMessage),
                                    uploadFileData
                                );
                                this.apiUploadBlockId = msg.messageId;
                                runEngine.sendMessage(msg.id, msg)
                            } else {
                                this.parseApiErrorResponse(responseJson);
                            }
                        }
                        this.parseApiCatchErrorResponse(errorReponse);
                    }
                    break;
                case this.apiUploadBlockId:
                    {
                        if (responseJson != null) {
                            if (!responseJson.errors) {
                                const keyValue = JSON.parse(
                                    convert.xml2json(responseJson, {
                                        spaces: 1,
                                        compact: true,
                                        ignoreComment: true,
                                        alwaysChildren: true,
                                        ignoreDeclaration: true,
                                    })
                                );
                                const { sectionList, selectedAnnotationQuestionId, selectedAnnotationAttachmentId } = this.state;
                                const updatedSectionList = sectionList?.map((data: any) => {
                                    data.attributes.questions = data?.attributes?.questions?.map((item: any) => {
                                        if (item.id === selectedAnnotationQuestionId) {
                                            const updatedItem = item;
                                            if (updatedItem?.selected_options?.data?.attributes?.attachments?.data?.length > 0) {
                                                updatedItem?.selected_options?.data?.attributes?.attachments?.data?.map((item: any) => {
                                                    if (item?.id === selectedAnnotationAttachmentId) {
                                                        item.attributes.signedId = keyValue.PostResponse.Key._text
                                                    }
                                                    return item;
                                                })
                                            }
                                            return updatedItem
                                        }
                                        return item
                                    })
                                    return data
                                })
                                this.setState({ sectionList: updatedSectionList })
                            } else {
                                this.parseApiErrorResponse(responseJson);
                            }
                        }
                        this.parseApiCatchErrorResponse(errorReponse);
                    }
                    break;
                case this.apiGetSectionQuestionsList:
                    {
                        if (responseJson != null) {
                            if (!responseJson.errors) {
                                const { sectionList, sectionId } = this.state;
                                sectionList.map((sectionItem: any) => {
                                    if (sectionItem.id === sectionId) {
                                        if (sectionItem.question_page_no === 1) {
                                            sectionItem.questions = responseJson?.data?.questions
                                        } else {
                                            sectionItem.questions = [...sectionItem.questions, ...responseJson?.data?.questions]
                                        }
                                        if (responseJson?.meta?.pagination.next_page) {
                                            sectionItem.question_page_no = sectionItem.question_page_no + 1
                                        }
                                        sectionItem.meta_data = responseJson?.meta
                                    }

                                    return sectionItem;
                                })

                                this.setState({
                                    sectionList: sectionList,
                                    questionListMeta: responseJson?.meta,
                                })
                            } else {
                                this.parseApiErrorResponse(responseJson);
                            }
                        }
                        this.setState({ showLoader: false })
                        this.parseApiCatchErrorResponse(errorReponse);
                    }
                    break;
                default:
                    break;
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        if (HISTORY?.location?.state) {
            const { assessmentId, studentDetails } = HISTORY?.location?.state;

            if (studentDetails) {
                this.setState({ studentDetails: studentDetails })
            }
            if (assessmentId) {
                this.setState({ assessmentId: assessmentId }, () => this.getSectionList())
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleBack = () => {
        HISTORY.goBack();
    };

    sectionArrowClick = (sectionId: number) => {
        if (sectionId !== this.state.selectedSectionId) {
            this.setState({ selectedSectionId: sectionId })
        } else {
            this.setState({ selectedSectionId: 0 })
        }
    }

    openSubmitModal = () => {
        this.setState({ openSubmitModal: true })
    }

    closeSubmitModal = () => {
        this.setState({ openSubmitModal: false })
    }

    editOnClick = () => {
        this.setState({ showSubmitSection: true })
    }

    previewImageModalClose = () => {
        this.setState({ previewImageModal: false })
    }

    setPreviewImageSrc = (src: any) => {
        this.setState({ previewImageSrc: src, previewImageModal: true })
    }

    getSectionList = () => {
        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token,
            school: school_Data?.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetSectionList = requestMessage.messageId;

        const { studentDetails, sectionPageNum, sectionPerPage } = this.state;
        let apiEndPoint = configJSON.getAssessmentSectionEndPoint + `?student_id=${Number(studentDetails?.id)}&assessment_id=${this.state.assessmentId}&page=${sectionPageNum}&per=${sectionPerPage}`
        this.setState({ showLoader: true })

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    sectionClick = (id: number) => {
        this.setState({ sectionId: id },
            () => {
                this.getSectionQuestionsList()
            })
    }

    getSectionQuestionsList = () => {
        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token,
            school: school_Data?.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetSectionQuestionsList = requestMessage.messageId;

        const { sectionList, sectionId, studentDetails } = this.state;
        let pageNo: number = 0;
        let perPage: number = 0;
        sectionList.map((item: any) => {
            if (item.id === sectionId) {
                pageNo = item.question_page_no;
                perPage = item.question_per_page;
            }
            return item;
        })

        let apiEndPoint = configJSON.getAssessmentSectionQuestionEndPoint + `/${sectionId}/get_evalution_section_questions?student_id=${Number(studentDetails?.id)}&page=${pageNo}&per=${perPage}`
        this.setState({ showLoader: true })

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleSectionLoadMore = () => {
        this.setState({ sectionPageNum: this.state.sectionPageNum + 1 },
            () => this.getSectionList())
    }

    saveSectionList = () => {
        if (this.validMarks()) {
            this.saveSectionListApi()
        }
    }

    validMarks = () => {
        let isValid = true;
        const { sectionList } = this.state;
        sectionList.map((data: any) => {
            data?.questions?.map((question: any) => {
                if (question?.question_type === 'long answer' || question?.question_type === 'short answer' ||
                    question?.question_type === 'audio' || question?.question_type === 'video') {
                    if (Number(question?.selected_options?.data?.attributes?.score) > Number(question?.marks)) {
                        toast.error('Obtained marks should not greater than total marks')
                        isValid = false
                    } 
                }
            })
        })
        return isValid;
    }

    saveSectionListApi = () => {
        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token,
        };
        const { sectionList, studentDetails } = this.state;

        let final_question_list: any = []
        sectionList.map((data: any) => {
            data?.questions?.map((question: any) => {
                if (question?.selected_options?.data) {
                    const questionItem: any = {
                        id: question?.selected_options?.data?.id,
                        score: question?.selected_options?.data?.attributes?.score ? question?.selected_options?.data?.attributes?.score : 0,
                        comments: question?.comments,
                    }
                    let attachmentsData: Array<any> = []
                    if (question?.selected_options?.data?.attributes?.attachments?.data?.length > 0) {
                        question?.selected_options?.data?.attributes?.attachments?.data?.map((item: any) => {
                            if (item?.attributes?.signedId) {
                                const data = {
                                    upload_id: item.id,
                                    url: item?.attributes?.signedId
                                }
                                attachmentsData.push(data)
                            }
                        })
                        if (attachmentsData?.length > 0) {
                            questionItem.attachments = attachmentsData
                        }
                    }

                    final_question_list.push(questionItem)
                }
            })
        })
        const httpBody = {
            student_id: Number(studentDetails?.id),
            student_assessment_answers: final_question_list
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiSaveSectionList = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        let apiEndPoint = configJSON.getAssessmentEndPoint + `/${this.state.assessmentId}/save_evaluation?school_id=${school_Data?.school_id}`

        this.setState({ showLoader: true })

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PATCH
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    submitSectionList = () => {
        const user_data = localStorage.getItem("user_data");
        const school_Data = JSON.parse(user_data || "{}");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("token"),
        };

        this.setState({ showLoader: true });
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiSubmitSectionList = requestMessage.messageId;
        const httpBody = {
            student_id: Number(this.state.studentDetails?.id),
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAssessmentEndPoint + `/${this.state.assessmentId}/submit_evaluation?school_id=${school_Data?.school_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PUT
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    setImageUrl = (id: number, url: string, file: any, attachmentId: number) => {
        this.setState({ annotatedFile: file, selectedAnnotationQuestionId: id, selectedAnnotationAttachmentId: attachmentId })
        const fileDetails = {
            fileName: file.name,
            fileSize: file.size,
            content_type: file.type,
        };
        this.getFilePreSignedId(fileDetails);
    }

    getFilePreSignedId = (fileDetail: any) => {
        const header = {
            token: localStorage.getItem("token"),
        };
        let formData = new FormData();
        formData.append('content_type', fileDetail.content_type)

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.userManagementURL
        );

        this.apiFilePreSignedID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getPreSignedUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}
