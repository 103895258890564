Object.defineProperty(exports, "__esModule", {
  value: true,
});

const urlConfig = require("../../../../framework/src/config");
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.GET = "GET";
exports.POST = "POST";
exports.PUT = "PUT";
exports.PATCH = "PATCH";
exports.DELETE = "DELETE";

exports.instituteURL = urlConfig.instituteURL;
exports.examinationUrl = urlConfig.examinationUrl;
exports.resultUrl = urlConfig.resultUrl;
exports.userManagementUrl = urlConfig.baseURL;

exports.getModulesNameEndPoint = "/bx_block_assessmenttest/teachers/report_cards/get_module_names";
exports.getClassListEndPoint = "account_block/school_accounts/get_teacher_classes";
exports.getStudentListEndPoint = "/bx_block_assessmenttest/teachers/report_cards";
exports.getGradeDataEndPoint = "bx_block_assessmenttest/teachers/report_cards/grade_scale_for_student";
exports.getScholasticDataEndPoint = "bx_block_assessmenttest/teachers/report_cards/scholastic";
exports.getStudentDataEndPoint = "account_block/get_student_info";
exports.coScholasticListEndPoint = "bx_block_assessmenttest/teachers/report_cards/co_scholastic";
exports.saveScholasticChangesEndPoint = "/bx_block_assessmenttest/teachers/report_cards/submit_scholastic_results";
exports.saveCoScholasticChangesEndPoint = "/bx_block_assessmenttest/teachers/report_cards/submit_co_scholastic_results";
exports.publishReportsEndPoint = "/bx_block_assessmenttest/teachers/report_cards/publish";
// Customizable Area End
