// Customizable Area Start
import { InputBase, withStyles } from "@material-ui/core";

const commonStyle:any = {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "#f9f8fd",
    border: '1px solid #f9f8fd',
    padding: "8px 26px 8px 12px",
    height: "30px",
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    "&:focus": {
        borderRadius: 8,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    }
}
export const FilterSelect = withStyles((theme: any) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
        "& .MuiNativeSelect-icon": {
            width: 36,
            height: 36,
            top: 5
        },
    },
    input: {
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        ...commonStyle
    },
}))(InputBase);

export const FilterSelectDisabled = withStyles((theme: any) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
        "& .MuiNativeSelect-icon": {
            width: 36,
            height: 36,
            top: 5
        },
    },
    input: {
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        opacity:0.5,
        ...commonStyle
    },
}))(InputBase);
// Customizable Area End