// Customizable Area Start
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import ReactPaginate from 'react-paginate';
import './common.css';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

interface IProps {
  // Customizable Area Start
  data: any;
  classes: any;
  getStatusTextColor: any;
  // Customizable Area End
}

export const PerformanceOverTime: React.FC<IProps> = (props) => {
  // Customizable Area Start
  const { classes, data, getStatusTextColor } = props;

  return (
    <Box>
      <Box marginTop={3}>
        <Box
          display={'flex'}
          justifyContent="space-between"
          alignItems={'center'}
        >
          <Typography className={`${classes.titleFont} main_text`}>
            Performance in Assessments over time
          </Typography>

          <FormControl>
            <InputLabel id="demo-simple-select-label">Analyze By </InputLabel>
            <Select
              autoWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={age}
              label="Age"
              // onChange={handleChange}
            >
              <MenuItem value="" unselectable="off" className="pointer_none">
                Analyze by
              </MenuItem>
              <MenuItem value={10} selected>
                Score (%)
              </MenuItem>
              <MenuItem value={20}>Row Score</MenuItem>
              <MenuItem value={30}>Above/Below Standard</MenuItem>
              <MenuItem value={30}>Proficiency Band</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <div
          className={
            classes.AssessmentStandardsReport_TeacherReports_graph_height
          }
        >
          <Bar
            data={data.data}
            plugins={[ChartDataLabels]}
            options={data.options}
          />
        </div>

        <Box marginTop={5}>
          <Typography
            className={`${classes.titleFont} main_text_margin_vertical`}
          >
            Assessment Statistics
          </Typography>
          <TableContainer className="t-assessment-table border_radius_6">
            {/* Table */}
            <Table>
              <TableHead className="blue_bg">
                <TableRow>
                  <TableCell
                    className={`${classes.bodyText_font} table_cell_name`}
                  >
                    Assessment Name
                  </TableCell>
                  <TableCell className={`${classes.bodyText_font} table_cell`}>
                    Type
                  </TableCell>
                  <TableCell className={`${classes.bodyText_font} table_cell`}>
                    Assessment Date
                  </TableCell>
                  <TableCell className={`${classes.bodyText_font} table_cell`}>
                    Max. Possible Score
                  </TableCell>
                  <TableCell className={`${classes.bodyText_font} table_cell`}>
                    Questions
                  </TableCell>
                  <TableCell className={`${classes.bodyText_font} table_cell`}>
                    Assigned
                  </TableCell>
                  <TableCell
                    className={`${classes.bodyText_font} table_cell_padded`}
                  >
                    Submitted
                  </TableCell>
                  <TableCell
                    className={`${classes.bodyText_font} table_cell_padded`}
                  >
                    Absent
                  </TableCell>
                  <TableCell
                    className={`${classes.bodyText_font} table_cell_padded`}
                  >
                    Min. Score
                  </TableCell>
                  <TableCell
                    className={`${classes.bodyText_font} table_cell_padded`}
                  >
                    Max. Score
                  </TableCell>
                  <TableCell
                    className={`${classes.bodyText_font} table_cell_padded`}
                  >
                    Avg. Student (Score%)
                  </TableCell>
                  {/* <TableCell className={classes.bodyText_font} style={{ padding: "4px 5px 4px 5px", fontSize: 20, fontWeight: 600, borderBottom: 0 , borderBottomRightRadius:'10px' }}>&nbsp;</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody className="margin_top_10">
                {/* {
                    assessmentList?.length === 0 || assessmentList === undefined
                      ?
                      <TableRow>
                        <TableCell
                          style={{ padding: "17px 0px 16px 20px", color: "#3f526d", fontFamily: 'Open sans', fontSize: 18 }}
                          colSpan={8}
                          align="center"
                        >
                          No record(s) found.
                        </TableCell>
                      </TableRow>
                      :
                      <>
                        {assessmentList?.map((data: any) => ( */}
                {Array.from({ length: 10 }).map((itm, indx) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell colSpan={7} className="table_cell_empty" />
                      </TableRow>
                      <TableRow className="table_row">
                        <TableCell className="normal_table_cell">
                          Lorem Ipsum dor sit
                          <br />
                          Section 4 (Ch 1-6)
                        </TableCell>
                        <TableCell className="capitalized_center">
                          Class
                        </TableCell>
                        <TableCell className="center_table_cell">
                          {moment().format('MMM DD, YYYY')}
                        </TableCell>
                        <TableCell className="center_table_cell">15</TableCell>
                        <TableCell className="capitalized_center">8</TableCell>
                        <TableCell className="center_table_cell">28</TableCell>
                        <TableCell className="center_table_cell">30</TableCell>
                        <TableCell className="center_table_cell">8</TableCell>
                        <TableCell className="center_table_cell">
                          0.00
                        </TableCell>
                        <TableCell className="center_table_cell">
                          100.0
                        </TableCell>
                        <TableCell className="center_table_cell">
                          88.0%
                        </TableCell>
                        {/* <TableCell style={{ padding: "4px 5px 4px 5px", border: 0 }}>
                                {
                                  (data.attributes.status.toLowerCase() === 'save as draft' || data.attributes.status.toLowerCase() === 'pending') ?
                                    <AssessmentActionMenu assessment_id={data.id} handleEditAssessment={(id: any) => this.handleEditAssessment(id)} />
                                    : ''
                                }

                              </TableCell> */}
                      </TableRow>
                    </>
                  );
                })}
                {/* ))}
                      </>
                   } */}
              </TableBody>
            </Table>
            <ReactPaginate
              previousLabel={
                <span>
                  ←{/* <span className="xs_display">Previous</span> */}
                </span>
              }
              nextLabel={
                <span>{/* <span className="xs_display">Next</span>  */}→</span>
              }
              breakLabel="..."
              initialPage={0}
              // pageCount={this.state.assessmentListMeta?.pagination?.total_pages}
              pageCount={3}
              // onPageChange={(e: any) => {
              //   if (e.selected !== this.state.page) {
              //     this.setState({ page: e.selected }, () => this.getAssessmentList())
              //   }
              // }}
              containerClassName={`${classes.Pagination_PaginationBttns}`}
              previousLinkClassName={`${classes.Pagination_PreviousBttn}`}
              nextLinkClassName={`${classes.Pagination_NextBttn}`}
              disabledClassName={`${classes.Pagination_PaginationDisabled}`}
              activeClassName={`${classes.Pagination_PaginationActive}`}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
            />
            {/* Table END */}
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
  // Customizable Area End
};

// Customizable Area Start
export default PerformanceOverTime;
// Customizable Area End
