// Customizable Area Start
import { Box, Button, Divider, Grid, Modal, Typography } from "@material-ui/core";
import React from "react"
import { HISTORY } from "../../../../components/src/comman"
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import AlertModal from "../../../alert/src/AlertModal.web"
import { Close } from "@material-ui/icons";
export const configJSON = require("./config.js");
import axios from 'axios';
// Customizable Area End

const HOCAuth = (PrevComponent: any, accountType: any) => {
    // Customizable Area Start
    type S = {

    }
    type SS = {
        validUser: any
        alertModal: boolean
    }
    return (

        class NewComponent extends React.Component<S, SS>{
            constructor(props: any) {
                super(props)
                this.state = {
                    validUser: false,
                    alertModal: false

                }
            }
            componentDidMount() {

                const user_data = localStorage.getItem("user_data");
                const data = JSON.parse(user_data || "{}");
                if (accountType?.toLowerCase() == data?.type?.toLowerCase()) {
                    this.setState({ validUser: true })
                } else {
                    this.setState({ alertModal: true })
                }

            }
            deleteFirebaseToken = (token: any, deviceId: any, schoolId: any) => {
                const header = {
                    'Content-Type': configJSON.validationApiContentType,
                    token: token,
                    school: schoolId

                };
                axios({
                    method: 'delete',
                    url:
                        configJSON.userManagment + `/account_block/devices/${deviceId}`,
                    headers: header,
                })
                    .then((response: any) => {
                        console.log(response)

                    })
                    .catch((s3error: any) => {
                        console.log(s3error);
                    });
            };

            onClose = () => {
                this.setState({ alertModal: false })
                if (accountType === "SuperAdminAccount") {
                    HISTORY.push(`/superadminlogin`);
                } else if (accountType === "AdminAccount") {
                    HISTORY.push(`/schooladminlogin`);
                } else {
                    HISTORY.push(`/EmailAccountLoginBlock`);
                }
                if (localStorage.getItem('token') && localStorage.getItem('user_data') && localStorage.getItem('device_id')) {
                    const user_data = localStorage.getItem('user_data');
                    const data = JSON.parse(user_data || '{}');
                    this.deleteFirebaseToken(localStorage.getItem('token'), localStorage.getItem('device_id'), data.school_id)
                }
                localStorage.clear()
            }

            onCancel = () => {
                this.setState({ alertModal: false })
                HISTORY.goBack()
            }

            render() {
                return (
                    <>
                        {this.state.validUser ? <PrevComponent {...this.props} /> :
                            //         <AlertModal
                            //     alertModal={this.state.alertModal}
                            //     isTeacher={false}
                            //     onCloseModal={() => {  this.onClose() }}
                            //     alertTitle="Alert!"
                            //     alertMessage={"You are not authorize to access this URL"}
                            //     buttonText="Login"
                            // />
                            <Modal
                                open={this.state.alertModal}
                                onClose={() => this.onClose()}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box
                                    style={{
                                        justifyContent: "center",
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: 'white',
                                        borderRadius: "29px",
                                        padding: "32px 18px",
                                        width: "576px",
                                    }}
                                >
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography
                                            style={{
                                                height: "33px",
                                                fontFamily: "Open sans",
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                                fontStretch: "normal",
                                                fontStyle: "normal",
                                                lineHeight: "normal",
                                                letterSpacing: "normal",
                                                textAlign: "center",
                                                color: "#3f526d",

                                                width: "100%",
                                                marginLeft: "34px",
                                            }}
                                        >
                                            Alert!
                                        </Typography>

                                        <Typography
                                            id="modal-modal-title"
                                            component="h2"
                                        //   style={{ marginTop: "32px", marginRight: "32px" }}
                                        >
                                            <Close onClick={() => this.onClose()} style={{ cursor: "pointer" }} />
                                        </Typography>
                                    </Box>
                                    {/* <div style={titleDiv}>Alert!</div> */}
                                    <Divider />

                                    <Typography
                                        style={{
                                            margin: "30px",
                                            fontFamily: "Open sans",
                                            fontSize: "20px",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "normal",
                                            textAlign: "center",
                                            color: "#3f526d"
                                        }}
                                    >
                                        You are not authorize to access this URL
                                    </Typography>

                                    <Grid style={{ display: 'flex', justifyContent: 'center' }}>

                                        <Button
                                            style={{
                                                color: "#fff",
                                                width: "22%",

                                                textTransform: "capitalize",
                                                fontSize: 18,
                                                backgroundColor: "#7f6cfc",
                                            }}
                                            onClick={() => { this.onClose() }}
                                        >
                                            Login
                                        </Button>
                                    </Grid>
                                </Box>
                            </Modal>

                        }
                    </>
                )
            }

        }
    )
    // Customizable Area End
}
// Customizable Area Start
export default HOCAuth
// Customizable Area End