// Customizable Area Start
import React from "react";
import { Box, Tabs, Button, Typography } from "@material-ui/core";
import AdminEnrollUserAccountController, {
  Props,
} from "./AdminEnrollUserAccountController.web";
import "./StudentClassesAndSection.web.css";
import './AddClass.web.css'
import HOCAuth from "../../shared/Header/src/HOCAuth.web";
import { withStyles } from "@material-ui/core/styles";
import './AdminEnrollUserAccount.web.css';
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const tabsList = [
  {
    key: 1,
    value: "principals",
    label: "Principals",
  },
  {
    key: 2,
    value: "teachers",
    label: "Teachers",
  },
  {
    key: 3,
    value: "supervisors",
    label: "Supervisors",
  },
  {
    key: 4,
    value: "students",
    label: "Students",
  },
  {
    key: 5,
    value: "parents",
    label: "Parents",
  },
  {
    key: 6,
    value: "pending",
    label: "Pending Status",
  },

];
// Customizable Area End
export class AdminEnrollUserAccount extends AdminEnrollUserAccountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const {
      selectedTab,
      selectedIndex,
    } = this.state;
    return (
      <Box className="admin_enroll_user_w_100">
        <Box className="flex-wrap admin_enroll_user_top_part">
          <Box className="user-account-left left_side">
            <Box className="left_side_heading">
              <Typography
                className={`{$this.props.classes.heading_font} left_side_heading_typography`}
              >
                User Account
              </Typography>
            </Box>

          </Box>


          {selectedTab === "students" &&

            <>
              <Box className="user-account-right right_side">
                <Button
                  className={`${this.props.classes.button_color_disabled} right_side_upload_btn`}
                  onClick={() => {
                    this.setState({ uploadStudent: true }, () => { })
                  }}
                >
                  <Typography
                    className="right_side_btn_typography"
                  >
                    Upload Students
                  </Typography>
                </Button>


                <Button
                  className={`${this.props.classes.button_color} right_side_add_btn`}
                  onClick={() => {
                    this.setState({ dialogOpen: true })
                  }}
                >
                  <Typography
                    className="right_side_btn_typography"
                  >
                    Add Student
                  </Typography>
                </Button>
              </Box>

            </>
          }
          {selectedTab === "teachers" &&

            <>
              <Box className="user-account-right right_side">
                <Button
                  className={`${this.props.classes.button_color_disabled} right_side_upload_btn`}
                  onClick={() => {
                    this.setState({ uploadTeacher: true }, () => { })
                  }}
                >
                  <Typography
                    className="right_side_btn_typography"
                  >
                    Upload Teachers
                  </Typography>
                </Button>


                <Button
                  className={`${this.props.classes.button_color} right_side_add_btn`}
                  onClick={() => {
                    this.setState({ dialogOpenTeacher: true })
                  }}
                >
                  <Typography
                    className="right_side_btn_typography"
                  >
                    Add Teacher
                  </Typography>
                </Button>
              </Box>

            </>
          }


        </Box>

        <Box className="admin_enroll_user_w_100">
          <Box className="admin_enroll_user_tabs">
            <Tabs
              value={"resources"}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabsList.map((item, i) => (
                <Button
                  className={`${item.key === selectedIndex ? this.props.classes.button_color : this.props.classes.button_color_disabled} tab_btn`}
                  key={item.key}
                  onClick={() => {
                    this.handleSelectTab(item.value, item.key);
                  }}
                >
                  <Typography className="tab_btn_text"
                  >
                    {item.label}
                  </Typography>
                </Button>
              ))}

            </Tabs>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          className="admin_enroll_user_w_100"
        >
          <Box
            className="admin_enroll_user_listing_screen"
          >
            {this.screen()}
          </Box>
        </Box>
      </Box>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(AdminEnrollUserAccount as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End