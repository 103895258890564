// Customizable Area Start
import React from "react";
import { Box, Grid, Modal, Button } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
const noText = {
  fontFamily: "Open sans",
  fontSize: "14px",
  fontWeight: 600,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "0.1px",
  textAlign: "center",
  color: "#000",
} as const;
const yesText = {
  fontFamily: "Open sans",
  fontSize: "14px",
  fontWeight: 600,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "0.1px",
  textAlign: "center",
  color: "#fff"
} as const;
const noButton = {
  width: 88,
  height: 40,
  margin: "0 20px 0 0",
  borderRadius: "3px",
  textTransform: "initial",
  backgroundColor: "#f6f6f6",
} as const;
const yesButton = {
  width: 88,
  height: 40,
  borderRadius: "3px",
  backgroundColor: "#253873",
  textTransform: "initial",
  border: "solid 1px #253873",
} as const;
// Customizable Area End

interface Props {
  // Customizable Area Start
  openSubmitModal: boolean;
  onCloseModal: any;
  submitYesClick: any;
  // Customizable Area End
}

// Customizable Area Start
export const SubmitModal = ({
  openSubmitModal,
  onCloseModal,
  submitYesClick,
}: Props) => {
  return (
    <>
      <Modal
        open={openSubmitModal}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="delete_modal_main_div">
          <div className="delete_msg_1">
            Are you sure you want to submit ?
          </div>

          <Grid className="submit_btn_section">
            <Button style={noButton} onClick={onCloseModal}>
              <span style={noText}>No</span>
            </Button>
            <Button style={yesButton} onClick={submitYesClick}>
              <span style={yesText}>Yes</span>
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
// Customizable Area End

// Customizable Area Start
export default SubmitModal;
// Customizable Area End
