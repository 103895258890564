// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End
export interface Props {
  // Customizable Area Start
  classes: any
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  timeTable: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  data: any;
  student_id: number;
  searchResult: boolean;
  teacherupcomingClasses: any;
  teacherClasses: any;
  teacherNotes: any;
  totalClasses: string;
  studentSubjects: any;
  teacherAssegments: string;
  teacherofStudents: string;
  createnotediv: boolean;
  subject_id: number;
  class_id: any;
  selectedUpcomingSubject: string;
  title: string;
  calendarDate: string;
  description: string;
  liveClasses: any;
  teacherTimetable: any;
  boxHeight: boolean;
  liveClassDate: string;
  getTeacherClasses: any;
  changeview: boolean;
  anchorEl: any;
  accountData: any;
  createSelected: any;
  selectAll: boolean;
  anchorEl1: any;
  dialogOpen: any;
  currentPage: any;
  nextPage: any;
  totalPage: any;
  totalCount: any;
  viewStudent: any;
  studentEdit: any;
  editDialog: any;
  deleteAlertModal: boolean;
  sendAlertModal: boolean;

  teacherSubjectList: any;
  classList: any;
  divisionList: any;
  filterUserType: any;
  filterDivisionId: any;
  filterStatus: any;
  loadingSpinner: boolean;
  searchQuery: any;
  applyFilterStudent: boolean;
  fileName: string;
  bulk_file: any;
  alertModal: boolean;
  alertMessage: any;
  perPage: any;
  selectStatusDropDownDiv: any;
  selectClassDropDownDiv: any;
  statusDropDownAnchorEl: any;
  classDropDownAnchorEl: any;
  data_Type: any;
  tempFilter: any;
  tempClass: any;
  searchClass: any;
  searchClassLength: any;
  csvFileError: any;
  csvFileDilogError: any;
  accountType: any;
  rejectAlert: boolean;
  editID: any;
  editType: any;
  actionModal: boolean;
  actionType: any;
  actionId: any;
  schoolName: any;
  teacherRestircitionError: any;
  teacherRestircition: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PendingAccountController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apigetStudentSubjectsId: string = "";
  apigetSearchStudent: string = "";
  apigetSubjectRemoveId: string = "";
  apigetTeacherClassesId: string = "";
  apigetPendingAccountId: string = "";
  apigetClasslId: any;
  apigetClassDivisionlId: any;
  apifilteraccountData: any;
  apiaddStudentClassesId: string = "";
  rejectApiId: any;
  approveApiId: any;
  apigetrestritionTeacher: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      teacherSubjectList: [],
      dashboardData: [],
      boxHeight: false,
      timeTable: [],
      teacherNotes: [],
      searchResult: false,
      teacherTimetable: [],
      errorMsg: "",
      token: "",
      student_id: 100,
      subject_id: 1,
      class_id: '',
      data: '',
      liveClassDate: '',
      loading: false,
      selectedUpcomingSubject: '',
      totalClasses: '',
      studentSubjects: [],
      teacherClasses: [],
      teacherupcomingClasses: [],
      liveClasses: [],
      teacherAssegments: '',
      teacherofStudents: '',
      createnotediv: false,
      description: '',
      title: '',
      calendarDate: '',
      changeview: false,
      getTeacherClasses: [],
      anchorEl: null,
      accountData: [],
      createSelected: [],
      selectAll: false,
      anchorEl1: null,
      dialogOpen: false,
      currentPage: 0,
      nextPage: null,
      totalPage: 1,
      totalCount: 1,
      viewStudent: {},
      studentEdit: false,
      editDialog: false,
      deleteAlertModal: false,
      sendAlertModal: false,
      classList: [],
      divisionList: [],
      filterUserType: [],
      filterDivisionId: "",
      filterStatus: [],
      fileName: '',
      bulk_file: [],
      loadingSpinner: false,
      searchQuery: '',
      applyFilterStudent: false,
      alertModal: false,
      alertMessage: '',
      perPage: 10,
      selectStatusDropDownDiv: false,
      selectClassDropDownDiv: false,
      statusDropDownAnchorEl: null,
      classDropDownAnchorEl: null,
      data_Type: '',
      tempFilter: [],
      tempClass: [],
      searchClass: '',
      searchClassLength: [],
      csvFileError: [],
      csvFileDilogError: false,
      accountType: [
        "student",
        "teacher",
        "parent",
        "supervisor"
      ],
      rejectAlert: false,
      editID: null,
      editType: '',
      actionModal: false,
      actionType: '',
      actionId: null,
      schoolName: '',
      teacherRestircitionError: [],
      teacherRestircition: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.get_userdata = this.get_userdata.bind(this);
    this.get_pending_account = this.get_pending_account.bind(this);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.get_pending_account();
  }

  handleDialogClose = () => {
    this.setState({ editDialog: false, editID: null, editType: '', currentPage: 0 }, () => {

      this.get_pending_account()
    })
  }

  isSelected = (id: any) => this.state.createSelected.indexOf(id) !== -1;

  handleSelectAllClick = (event: any) => {
    if (event.target.checked) {

      this.setState({
        createSelected: this.state.accountData && this.state.accountData.map((n: any) => n.id),
        selectAll: true
      });
      return;
    }
    this.setState({ createSelected: [], selectAll: false });
  };

  onDeleteCloseModal = (closeModal: boolean) => {
    this.setState({ deleteAlertModal: closeModal, anchorEl: null })
    this.get_pending_account();

  }

  onSendCloseModal = (closeModal: boolean) => {
    this.setState({ sendAlertModal: closeModal })
  }

  handleCheckboxClick = (event: any, id: any) => {
    event.stopPropagation();
    const { createSelected } = this.state;
    const selectedIndex = createSelected.indexOf(id);
    let newSelected: any[] = [];
    console.log(selectedIndex)
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(createSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(createSelected.slice(1));
    } else if (selectedIndex === createSelected.length - 1) {
      newSelected = newSelected.concat(createSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        createSelected.slice(0, selectedIndex),
        createSelected.slice(selectedIndex + 1)
      );
    }

    this.setState({ createSelected: newSelected, selectAll: false });
  };

  onMenuClose = () => {
    this.setState({ anchorEl: null })
  };

  get_userdata = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}')
    this.setState({ data: data });
  }

  get_filter_student() {
    if (this.state.filterUserType.length !== 0 || this.state.filterStatus.length !== 0) {

      this.setState({ applyFilterStudent: true, anchorEl: null })
      const school_data = localStorage.getItem("user_data");
      const schoolData = JSON.parse(school_data || "{}");
      const status = this.state.filterStatus.length === 0 ? '' : this.state.filterStatus[0]
      const header = {
        "Content-Type": configJSON.enrollContentType,
      };
      const classUrl = this.state.filterUserType.map((_userdata: any) => {
        return (
          `types[]=${_userdata === "student" ? "StudentAccount" : _userdata === "teacher" ? "TeacherAccount" :
            _userdata === "parent" ? "ParentAccount" : _userdata === "supervisor" ? "SupervisorAccount" : ''
          }`
        )

      }).join("&")
      console.log(classUrl)
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apifilteraccountData = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getStudentBySearchEndPoint +
        `?status=${status}&${classUrl}&school_id=${schoolData.school_id}&account_name=${this.state.searchQuery.trim()}&page=${parseInt(this.state.currentPage) + 1}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.baseUrl
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.enrollApiMethodType
      );
      console.log('REQUEST: ', requestMessage);
      runEngine.sendMessage(requestMessage.id, requestMessage);

    } else {
      this.setState({ applyFilterStudent: false, anchorEl: null, currentPage: 0 }, () => this.get_pending_account())

    }
    this.setState({ loadingSpinner: true })

  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apigetStudentSubjectsId) {
        console.log("ID Match")
        if (responseJson != null) {
          console.log("JSON NOt NUll")
          if (!responseJson.errors) {
            this.setState({ studentSubjects: responseJson })
            console.log(responseJson)

          } else {
            this.setState({ studentSubjects: [] })
            this.parseApiErrorResponse(responseJson);
          }
        }
        console.log("responseJson nulll")
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiaddStudentClassesId) {
        console.log("ID Match")
        if (responseJson != null) {
          this.setState({ loadingSpinner: false })
          console.log("JSON NOt NUll")
          if (!responseJson.errors) {
            toast.success("The File has been uploaded successfully.")
            this.setState({ bulk_file: [], fileName: '' })
            // this.props.onCloseModal()
            this.get_pending_account()
            if (responseJson.data && responseJson.data.errors_with_details.length !== 0) {
              this.setState({
                csvFileDilogError: true,
                csvFileError: responseJson.data.errors_with_details
              })
            }
          } else {
            toast.error(responseJson.errors[0])
            this.parseApiErrorResponse(responseJson);
          }
        }
        console.log("responseJson nulll")

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.rejectApiId) {
        console.log("ID Match")
        if (responseJson != null) {
          console.log("JSON NOt NUll")
          if (!responseJson.errors) {
            toast.success(responseJson.message)
            this.setState({ dialogOpen: false, loadingSpinner: false, schoolName: '', actionId: null, actionModal: false, actionType: '' })
            if (this.state.applyFilterStudent && this.state.searchQuery.trim().length !== 0) {
              this.setState({ currentPage: 0 }, () => this.get_filter_student)
            }
            else if (this.state.searchQuery.trim().length !== 0) {
              this.setState({ currentPage: 0 }, () => this.search_student())
            }
            else if (this.state.applyFilterStudent) {

              this.setState({ currentPage: 0 }, () => this.get_filter_student)
            } else { this.setState({ currentPage: 0 }, () => this.get_pending_account()) }


          } else {
            toast.error(responseJson.message)
            this.parseApiErrorResponse(responseJson);
          }
        }
        console.log("responseJson nulll")
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
      else if (apiRequestCallId === this.approveApiId) {
        console.log("ID Match")
        if (responseJson != null) {
          console.log("JSON NOt NUll")
          if (!responseJson.errors) {
            toast.success(responseJson.message)
            this.setState({ dialogOpen: false, loadingSpinner: false, schoolName: '', actionId: null, actionModal: false, actionType: '' })
            if (this.state.applyFilterStudent && this.state.searchQuery.trim().length !== 0) {
              this.setState({ currentPage: 0 }, () => this.get_filter_student)
            }
            else if (this.state.searchQuery.trim().length !== 0) {
              this.setState({ currentPage: 0 }, () => this.search_student())
            }
            else if (this.state.applyFilterStudent) {

              this.setState({ currentPage: 0 }, () => this.get_filter_student)
            } else { this.setState({ currentPage: 0 }, () => this.get_pending_account()) }


          } else {
            toast.error(responseJson.message)
            this.parseApiErrorResponse(responseJson);
          }
        }
        console.log("responseJson nulll")
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
      else if (apiRequestCallId === this.apigetSearchStudent) {
        console.log("ID Match")
        if (responseJson != null) {
          console.log("JSON NOt NUll")
          if (!responseJson.errors) {
            console.log(responseJson)
            if (responseJson.data != null) {
              this.setState({
                accountData: responseJson?.data,

              })
              console.clear()
              console.log(Object.keys(responseJson.meta).length, Object.keys(responseJson.meta))
              if (responseJson.data.length !== 0) {
                if (Object.keys(responseJson.meta).length === 0) {
                  this.setState({

                    nextPage: '',
                    totalPage: 1, totalCount: 1
                  })
                }
                else {

                  this.setState({

                    nextPage: responseJson.meta.pagination?.next_page,
                    totalPage: responseJson.meta.pagination?.total_pages, totalCount: responseJson.meta.pagination?.total_count
                  })
                }
              } else {
                this.setState({
                  studentSubjects: [], nextPage: '',
                  totalPage: 1, totalCount: 1
                })
              }

              // this.get_teacher_subjects(responseJson?.data[0]?.id)
            }

          } else {
            this.setState({
              studentSubjects: [], nextPage: '',
              totalPage: 1, totalCount: 1
            })
            this.parseApiErrorResponse(responseJson);
          }
        }
        console.log("responseJson nulll")
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
      else if (apiRequestCallId === this.apifilteraccountData) {
        console.log("ID Match")
        console.log(responseJson.errors !== null)
        if (responseJson != null) {
          console.log("JSON NOt NUll")
          if (!responseJson.errors) {
            console.log(responseJson)
            if (responseJson.data != null) {
              this.setState({
                accountData: responseJson?.data,
                loadingSpinner: false
              })
              if (responseJson.data.length !== 0) {

                if (Object.keys(responseJson.meta).length !== 0) {
                  this.setState({

                    nextPage: responseJson.meta.pagination?.next_page,
                    totalPage: responseJson.meta.pagination?.total_pages, totalCount: responseJson.meta.pagination?.total_count
                  })
                }
                else {
                  this.setState({

                    nextPage: '',
                    totalPage: 1, totalCount: 1
                  })
                }
              } else {
                this.setState({
                  studentSubjects: [], nextPage: '',
                  totalPage: 1, totalCount: 1
                })
              }

              // this.get_teacher_subjects(responseJson?.data[0]?.id)
            }


          } else {
            this.setState({
              studentSubjects: [], nextPage: '',
              totalPage: 1, totalCount: 1
            })
            this.parseApiErrorResponse(responseJson);
          }
        }
        console.log("responseJson nulll")
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
      else if (apiRequestCallId === this.apigetPendingAccountId) {
        console.log("ID Match")
        if (responseJson != null) {
          console.log("JSON NOt NUll")
          if (!responseJson.errors) {
            console.log(responseJson)
            if (responseJson.data != null) {
              this.setState({
                accountData: responseJson?.data,
                loadingSpinner: false

              })
              if (responseJson.data.length !== 0) {
                if (Object.keys(responseJson.meta).length !== 0) {
                  this.setState({

                    nextPage: responseJson.meta.pagination?.next_page,
                    totalPage: responseJson.meta.pagination?.total_pages, totalCount: responseJson.meta.pagination?.total_count
                  })
                }
                else {
                  this.setState({

                    nextPage: '',
                    totalPage: 1, totalCount: 1
                  })
                }
              } else {
                this.setState({
                  studentSubjects: [], nextPage: '',
                  totalPage: 1, totalCount: 1
                })
              }

              // this.get_teacher_subjects(responseJson?.data[0]?.id)
            }

          } else {
            this.setState({
              studentSubjects: [], nextPage: '',
              totalPage: 1, totalCount: 1
            })
            this.parseApiErrorResponse(responseJson);
          }
        }
        console.log("responseJson nulll")
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
      if (apiRequestCallId === this.apigetrestritionTeacher) {

        console.log("ID Match")
        if (responseJson != null) {
          console.log("JSON NOt NUll")
          if (!responseJson.errors) {

            if (responseJson.success_data) {
              this.approve_user(this.state.actionId, this.state.schoolName)
            } else {
              this.setState({
                teacherRestircitionError: responseJson?.reject_data,

              }, () => {

                this.setState({ teacherRestircition: true })
              })
            }

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        console.log("responseJson nulll")
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  get_pending_account = () => {
    this.setState({ loadingSpinner: true })
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.enrollContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetPendingAccountId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPendingAccount + `?school_id=${data.school_id}&page=${parseInt(this.state.currentPage) + 1}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.enrollApiMethodType
    );
    console.log('REQUEST: ', requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  set_data = (file: any) => {
    let fileUpload: any = {};
    let checksum = "";
    let allowUpload: boolean = true;
    const validFileType = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    console.log(file)
    fileUpload = {
      fileName: file.name,
      name: file.name.split('.')[0],
      fileSize: file.size,
      file: file,
      content_type: file.type
    };
    console.log(fileUpload)
    // if (fileUpload.fileSize > 524288000) {
    //     console.log("run File type ")
    //     allowUpload = false;
    //     this.setState({
    //         alertMessage: "Maximum files size: 500MB",
    //         alertModal: true,
    //     });
    // }
    console.log(file)
    this.setState({ bulk_file: file })
    console.log(this.state.bulk_file)
  }
  search_student = () => {
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.enrollContentType,


    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetSearchStudent = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentBySearchEndPoint + `?account_name=${this.state.searchQuery.trim()}&page=${parseInt(this.state.currentPage) + 1}&school_id=${data.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.enrollApiMethodType
    );
    console.log('REQUEST: ', requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ loadingSpinner: true })

  }

  add_student_Class = () => {
    const { bulk_file } = this.state
    console.log(bulk_file)
    let formData = new FormData();
    formData.append("file", bulk_file);
    console.log(formData)
    for (var value of formData.values()) {
      console.log(value);
    }
    const header = {
      // "Content-Type": configJSON.formDataContentType,
      token: localStorage.getItem('token'),

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiaddStudentClassesId = requestMessage.messageId;
    // const httpBody = {
    //     formData,
    // }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addBulkClassEndPointTeacher
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.enrollApiPostMethodType
    );
    console.log('REQUEST: ', requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ loadingSpinner: true })

  }
  onClose = () => {
    this.setState({ bulk_file: [], fileName: '' })
    // this.props.onCloseModal()

  }
  get_restriction = async (e: any) => {
    e.preventDefault()
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    this.setState({ loadingSpinner: true })
    let arrSubject: any[] = []
    this.state.viewStudent?.attributes?.school_account?.data.map((_data: any) => {
      arrSubject.push({ class: `${_data.attributes?.class?.id}`, subject_data: [] })

    })
    this.state.viewStudent?.attributes.teacher_classes_subjects?.teacher_class_subject_data.map((_subject: any) => {
      arrSubject.map((_class: any) => {
        if (_class.class == _subject.class_id || parseInt(_class.class) == parseInt(_subject.class_id)) {
          _class.subject_data.push(`${_subject.id}`)
        }
      })
    })
    // this.state.classesAndSubject.map((_data: any) => {
    //     arr.push(_data.class)
    //     arrSubject.push({ class: _data.class, subject_data: _data.subject_data })

    // })
    let fulldata = JSON.stringify(arrSubject)

    const header = {
      "Content-Type": configJSON.enrollContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetrestritionTeacher = requestMessage.messageId;
    let apiEndPoint = configJSON.restrictionEndPoint + `?school_id=${data.school_id}&account_id=${this.state.actionId}`

    apiEndPoint = apiEndPoint + `&subjects=${JSON.stringify(arrSubject)}`



    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      decodeURIComponent(apiEndPoint)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    // const httpBody = bodydata;

    // requestMessage.addData(
    //     getName(MessageEnum.RestAPIRequestBodyMessage),
    //     JSON.stringify(httpBody)
    // );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.enrollApiMethodType
    );
    console.log('REQUEST: ', requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);




  }

  reject_user = (id: any, school_name: any) => {
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    this.setState({ loadingSpinner: true })
    let formData = new FormData();
    formData.append("account_ids[]", id);
    formData.append("school_id", data.school_id);
    formData.append("school_name", school_name);
    const header = {

      "token": localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rejectApiId = requestMessage.messageId;



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rejectUserEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.enrollApiPutMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrl
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  approve_user = (id: any, school_name: any) => {
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    this.setState({ loadingSpinner: true })
    let formData = new FormData();
    formData.append("account_ids[]", id);
    formData.append("school_id", data.school_id);
    formData.append("school_name", school_name);
    const header = {

      "token": localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.approveApiId = requestMessage.messageId;



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.approveUserEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.enrollApiPutMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrl
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
