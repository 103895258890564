// Customizable Area Start
import React from "react";
import { blockIcon, deleteIcon, editIcon } from "../assets";
import {
  Box,
  Typography,
  WithStyles,
  withStyles,
  IconButton,
  Menu,
  MenuItem,
  // MoreVertOutlined,
} from "@material-ui/core";
import RemoveModal from "./RemoveModal.web";
import InActiveModal from "./ThoughtInactive.web";
import PostThoughtModalWeb from "./PostThoughtModal.web";
// import { styles } from "./ThoughtBox.web";
// Customizable Area End

interface IProps {
  // Customizable Area Start
  thought?: any;
  classes?: any;
  anchorEl: any;
  setAnchorEl: any;
  status: string;
  setThoughtToInactive: any;
  createThought?: any;
  updateThought?: any;
  thoughtId?: any
  createThoughtDelete2?: any;
  updateStateEditThough?:any;
  // Customizable Area End
}
const ThoughtBoxMenu: React.FC<IProps> = ({
  thought,
  classes,
  anchorEl,
  setAnchorEl,
  status,
  setThoughtToInactive,
  createThought,
  updateThought,
  thoughtId,
  createThoughtDelete2,
  updateStateEditThough
}) => {
  // Customizable Area Start
  const [removeModal, setRemoveModal] = React.useState(false);
  const [inactiveModal, setInactiveModal] = React.useState(false);
  const [PostThoughtModal, setPostThoughModal] = React.useState(false);
  // Customizable Area End

  // Customizable Area Start
  const open = Boolean(anchorEl);
  const removeAction = () => {
    handleClose();
    setRemoveModal(true);
  };

  const InactiveAction = () => {
    handleClose();
    setInactiveModal(true);
  };

  const PostThoughtAction = () => {
    handleClose();
    setPostThoughModal(true);
  };

  const handleClose = () => {
    setAnchorEl();
  };
  const handleremoveClose = () => {
    setRemoveModal(false);
  };

  const handleInactiveClose = () => {
    setInactiveModal(false);
  };
  const handleInactiveSave = () => {
    setThoughtToInactive()
    setInactiveModal(false);
  }

  const handlePostThoughtClose = async () => {
    // await createThought()
    
    await setPostThoughModal(false);
  };
  const createThoughtDelete1 = (obj: any) => {
    createThoughtDelete2(obj)
  }
const updateStateEditThoughBoxMenu=(obj:any,id:any)=>{
updateStateEditThough(obj,id)
}
// Customizable Area End

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div style={{ width: "200px" }}>
          {status !== "Inactive" && (
            <MenuItem onClick={InactiveAction}>
              <Box
                style={{ display: "flex", width: "100%", alignItems: "center" }}
              >
                <Box style={{ width: "20%" }}>
                  <img
                    src={blockIcon}
                    style={{
                      height: 15,
                      width: 15,
                    }}
                  />
                </Box>
                <Box>
                  <Typography className={classes.MenuItemFont}>
                    Move to Inactive
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          )}
          <Box className={classes.Line} />
          <MenuItem onClick={PostThoughtAction}>
            <Box
              style={{ display: "flex", width: "100%", alignItems: "center" }}
            >
              <Box style={{ width: "20%" }}>
                <img
                  src={editIcon}
                  style={{
                    height: 15,
                    width: 15,
                  }}
                />
              </Box>
              <Box>
                <Typography className={classes.MenuItemFont}>Edit</Typography>
              </Box>
            </Box>
          </MenuItem>
          <Box className={classes.Line} />
          <MenuItem onClick={removeAction}>
            <Box
              style={{ display: "flex", width: "100%", alignItems: "center" }}
            >
              <Box style={{ width: "20%" }}>
                <img
                  src={deleteIcon}
                  style={{
                    height: 20,
                    width: 20,
                    marginTop: 4,
                  }}
                />
              </Box>
              <Box>
                <Typography className={classes.MenuItemFont}>Remove</Typography>
              </Box>
            </Box>
          </MenuItem>
        </div>
      </Menu>

      <RemoveModal
        createThought={createThought}
        thoughtId={thoughtId}
        RemoveModal={removeModal}
        closeModalFun={handleremoveClose}
        createThoughtDelete1={createThoughtDelete1}
      />
      <PostThoughtModalWeb
        createThought={createThought}
        thought={thought}
        thoughtId={thoughtId}
        open={PostThoughtModal}
        close={handlePostThoughtClose}
        updateStateEditThoughBoxMenu={updateStateEditThoughBoxMenu}
        
      />
      <InActiveModal
        handleInactiveSave={handleInactiveSave}
        InActiveModal={inactiveModal}
        closeModalFun={handleInactiveClose}
      />
    </>
  );
};
export const themeCreatStyle = ((theme: any) => ({
  // Customizable Area Start
  Line: {
    // border: `solid 0.5px ${theme.props.buttonColor}`,
  },
  MenuItemFont: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  // Customizable Area End
}))

// Customizable Area Start
export default withStyles(themeCreatStyle)(ThoughtBoxMenu);
// Customizable Area End
