// Customizable Area Start
import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';
import { HISTORY } from '../../../../components/src/comman';
import {
  menu,
  ic_computer_active,
  admin_class,
  active_class,
  division,
  active_division,
  period_2,
  period,
  gradebook,
  grade,
  reminder,
  user,
  user2,
} from './assets';
// Customizable Area End

// Customizable Area Start
export const configJSON = require('./config');
// Customizable Area End

export interface Props {
  // Customizable Area Start
  isTeacherProfile?: boolean;
  handleDrawer: (newVal: boolean) => void;
  open: boolean;
  drawerWidth: number;
  drawerCloseWidth: number;
  classes: any;
  navigation: any;
  hide: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  selected: string;
  isOpen: boolean;
  selectedMenuId: string;
  schoolAdmin: boolean;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserAvatarController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  checkTokenApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      txtInputValue: '',
      txtSavedValue: 'A',
      enableField: false,
      selected: '1',
      isOpen: true,
      selectedMenuId: '1',
      schoolAdmin: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.checkToken();
    const menuID = localStorage.getItem('selectedMenuId');
    const user_data = localStorage.getItem('user_data');

    const data = JSON.parse(user_data || '{}');
    const adminMenuList: any = [
      {
        id: '1',
        href: '#',
        img: menu, //this.state.selected === "1" ? menu : menu_disabled
        text: 'Home',
      },
      {
        id: '2',
        href: '/admindivision',
        img: this.state.selected === '2' ? active_division : division,
        text: 'Division',
      },
      {
        id: '3',
        href: '/AdminClassesAndSections',
        img: this.state.selected === '3' ? active_class : admin_class,
        text: 'Class',
      },
      {
        id: '4',
        href: '/AdminEnrollUserAccount',
        img: user,
        text: 'User',
      },
      {
        id: '5',
        href: '/AdminTerm',
        img: ic_computer_active, // this.state.selected === "2" ? ic_computer_active : ic_computer
        text: 'Term',
      },
      {
        id: '6',
        href: '/AdminClassPeriod',
        img: user2,
        text: 'Class Period',
      },
      {
        id: '7',
        href: '/AdminBatchAndPeriods',
        img: period,
        text: 'School Period',
      },
      {
        id: '8',
        href: '#',
        img: period_2,
        text: 'Portfolio',
      },
      {
        id: '9',
        href: '/AdminReminder',
        img: reminder,
        text: 'Reminder',
      },
      {
        id: '10',
        href: '#',
        img: gradebook,
        text: 'Grade Book',
      },
      {
        id: '11',
        href: '/AdminGradeView',
        img: grade,
        text: 'Grade',
      },
      {
        id: '12',
        href: '/Badges',
        text: 'Badge',
      },
      {
        id: '13',
        href: '/AdminReportCard',
        text: 'Report Card',
      },
      {
        id: '14',
        href: '#',
        text: 'Theme',
      },
      {
        id: '15',
        href: '/UserDocument',
        text: 'Document',
      },
      {
        id: '16',
        href: '/AdminFeedback',
        text: 'Feedback',
      },
    ];
    let count: number = 0;
    if (data.role === 'schooladmin') {
      adminMenuList.map((item: any, index: any) => {
        if (
          window.location.href.toLowerCase().includes(item.href.toLowerCase())
        ) {
          count++;
          this.setState({
            selectedMenuId: item.id,
            selected: item.id,
          });
        } else {
          if (count === 0) {
            this.setState({ selectedMenuId: menuID ? menuID : '1' });
          }
        }
      });
      this.setState({
        schoolAdmin: true,
      });
    } else {
      if (
        window.location.href.indexOf('ClassesAndSections') > -1 ||
        window.location.href.toLowerCase().includes('classesandsections')
      ) {
        this.setState(
          {
            selected: '2',
            selectedMenuId: '2',
          },
          () => {}
        );
      } else if (
        window.location.href.indexOf('TeacherReportsDashboard') > -1 ||
        window.location.href
          .toLowerCase()
          .includes('teacherreportsdashboard') ||
        window.location.href.toLowerCase().includes('assessmentsummaryreport')
      ) {
        this.setState(
          {
            selected: '9',
            selectedMenuId: '9',
          },
          () => {}
        );
      } else if (
        window.location.href.indexOf('Dashboard') > -1 ||
        window.location.href.toLowerCase().includes('dashboard')
      ) {
        this.setState({
          selected: '1',
          selectedMenuId: '1',
        });
      } else if (
        window.location.href.indexOf('Protfolio') > -1 ||
        window.location.href.toLowerCase().includes('protfolio')
      ) {
        this.setState({
          selected: '6',
          selectedMenuId: '6',
        });
      } else if (
        window.location.href.indexOf('Assessment') > -1 ||
        window.location.href.toLowerCase().includes('assessment')
      ) {
        this.setState(
          {
            selected: '3',
            selectedMenuId: '3',
          },
          () => {}
        );
      } else if (
        window.location.href.indexOf('Badges') > -1 ||
        window.location.href.toLowerCase().includes('badges')
      ) {
        this.setState(
          {
            selected: '11',
            selectedMenuId: '11',
          },
          () => {}
        );
      } else if (
        window.location.href.indexOf('report') > -1 ||
        window.location.href.toLowerCase().includes('report')
      ) {
        this.setState(
          {
            selected: '12',
            selectedMenuId: '12',
          },
          () => {}
        );
      } else {
        this.setState({ selectedMenuId: menuID ? menuID : '1' });
      }
    }
    // this.child.current.getStudentClasses()
  }
  changeColor = (id: any) => {
    this.setState({ selected: id });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.checkTokenApiCallId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
          } else {
            HISTORY.push(`/EmailAccountLoginBlock`);
            //this.props.navigation.navigate("EmailAccountLoginBlock")
          }
        }
        //HISTORY.push(`/EmailAccountLoginBlock`);
        // this.props.navigation.navigate("EmailAccountLoginBlock")
      }
      if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
        let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

        this.showAlert(
          'Change Value',
          'From: ' + this.state.txtSavedValue + ' To: ' + value
        );

        this.setState({ txtSavedValue: value });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkToken() {
    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
    };
    const data = {};

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.checkTokenApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkTokenEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: 'email',
    keyboardType: 'email-address',
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }


  handleClickMenuItem = (item: any) => {
    localStorage.setItem('selectedMenuId', item.id);
    HISTORY.push(item.href);
    this.props.handleDrawer(false);
  };
  // Customizable Area End
}
