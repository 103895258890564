// Customizable Area Start
import React from 'react';
// import './AdminReportCardTemplate.web.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ScholasticAreasController, { Props } from './ScholasticAreasController.web';
import Spinner from '../../../shared/SideBar/src/Spinner';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class ScholasticAreas extends ScholasticAreasController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { isLoader, scholasticListData } = this.state

        return (
            <>
                {isLoader && <Spinner spinnerModal={isLoader} />}
                <div>
                    {scholasticListData?.length != 0 && scholasticListData?.attributes?.terms?.map((termData: any) => (
                        <TableContainer key={termData.id} className="tableContent">
                            <Table>
                                <TableHead className={this.props.classes.ReportCardDetails_Feedback_background_color}>
                                    <TableRow>
                                        <TableCell className={`ScholasticCell ${this.props.classes.ReportCardDetails_Feedback_tableRowCell}`} align="center">
                                            <span className={`assessment-name-header`}>{this.props.moduleName}</span>
                                        </TableCell>
                                        <TableCell colSpan={termData.colSpan} className={`ScholasticCell ${this.props.classes.ReportCardDetails_Feedback_tableRowCell}`} align="center">
                                            <span className={`assessment-name-header`}>{termData?.title}</span>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        {scholasticListData?.attributes?.report_categories?.map((categoryData: any, categoryIndex: number) => (
                                            <React.Fragment key={categoryIndex}>
                                                {
                                                    categoryData?.categories?.map((data: any, tempIndex: number) => (
                                                        <React.Fragment key={tempIndex}>
                                                            {categoryData.type === "assessment" ?
                                                                <>
                                                                    {data?.sub_category?.data?.length > 0 && data?.sub_category?.data?.filter((subData: any) => {
                                                                        if (subData.attributes.term_id === termData.id) {
                                                                            return subData;
                                                                        }
                                                                    }).length > 0 && <TableCell align='center' className="ScholasticCategoryCell" colSpan={data?.sub_category?.data?.filter((subData: any) => {
                                                                        if (subData.attributes.term_id === termData.id) {
                                                                            return subData;
                                                                        }
                                                                    }).length}>
                                                                            <span className={`${this.props.classes.heading_font} category-name-header`}>{data.name}</span>
                                                                        </TableCell>}
                                                                </> :
                                                                <>
                                                                    {data?.sub_category?.length > 0 && data?.sub_category?.filter((subData: any) => {
                                                                        if (subData.term_id === termData.id) {
                                                                            return subData;
                                                                        }
                                                                    }).length > 0 && <TableCell align='center' className="ScholasticCategoryCell" colSpan={data?.sub_category?.filter((subData: any) => {
                                                                        if (subData.term_id === termData.id) {
                                                                            return subData;
                                                                        }
                                                                    }).length}>
                                                                            <span className={`${this.props.classes.heading_font} category-name-header`}>{data.name}</span>
                                                                        </TableCell>}
                                                                </>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                            </React.Fragment>
                                        ))}
                                    </TableRow>

                                    <TableRow>
                                        <TableCell></TableCell>
                                        {scholasticListData?.attributes?.report_categories?.map((categoryData: any, categoryIndex: number) => (
                                            <React.Fragment key={categoryIndex}>
                                                {
                                                    categoryData?.categories?.map((data: any) => (
                                                        <React.Fragment key={data.id}>
                                                            {categoryData.type === "assessment" ?
                                                                <>
                                                                    {data?.sub_category?.data?.length > 0 && data?.sub_category?.data?.filter((subData: any) => {
                                                                        if (subData.attributes.term_id === termData.id) {
                                                                            return subData;
                                                                        }
                                                                    }).map((finalSubData: any) => {
                                                                        return <TableCell align='center' className="ScholasticLeftBorderCell" key={finalSubData.id}>
                                                                            <span className={`${this.props.classes.heading_font} category-name-header`}>
                                                                                {finalSubData.attributes.name}
                                                                                <br />({finalSubData?.attributes?.total_marks ? finalSubData?.attributes?.total_marks : "-"})
                                                                            </span>
                                                                        </TableCell>
                                                                    })}
                                                                </> :
                                                                <>
                                                                    {data?.sub_category?.length > 0 && data?.sub_category?.filter((subData: any) => {
                                                                        if (subData.term_id === termData.id) {
                                                                            return subData;
                                                                        }
                                                                    }).map((finalSubData: any) => {
                                                                        return <TableCell align='center' className="ScholasticLeftBorderCell" key={finalSubData.id}>
                                                                            <span className={`${this.props.classes.heading_font} category-name-header`}>
                                                                                {finalSubData.name}
                                                                                <br />({finalSubData?.total_marks ? finalSubData?.total_marks : "-"})
                                                                            </span>
                                                                        </TableCell>
                                                                    })}
                                                                </>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                            </React.Fragment>
                                        ))}
                                    </TableRow>

                                    {scholasticListData?.attributes?.class_subjects?.map((subjectData: any) => (
                                        <TableRow key={subjectData.id}>
                                            <TableCell className={`ScholasticCell ${this.props.classes.ScholasticAreas_Feedback_commonCss}`}>
                                                <span className={`${this.props.classes.heading_font} category-name-header`}>{subjectData?.subject_name}</span>
                                            </TableCell>
                                            {scholasticListData?.attributes?.report_categories?.map((categoryData: any, categoryIndex: number) => (
                                                <React.Fragment key={categoryIndex}>
                                                    {
                                                        categoryData?.categories?.map((data: any) => (
                                                            <React.Fragment key={data.id}>
                                                                {categoryData.type === "assessment" ?
                                                                    <>
                                                                        {data?.sub_category?.data?.length > 0 && data?.sub_category?.data?.filter((subData: any) => {
                                                                            if (subData.attributes.term_id === termData.id) {
                                                                                return subData;
                                                                            }
                                                                        }).map((finalSubData: any) => {
                                                                            return <TableCell align='center' className="ScholasticRightBorderCell" key={finalSubData.id}>

                                                                                {Number(finalSubData.attributes.subject_id) === Number(subjectData.id) ?
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        placeholder='Marks'
                                                                                        type="number"
                                                                                        value={finalSubData?.attributes?.marks ? finalSubData?.attributes?.marks : ""}
                                                                                        className="no-underline"
                                                                                        inputProps={{
                                                                                            className: `${this.props.classes.heading_font} table-field-style`
                                                                                        }}
                                                                                        data-test-id={`assessment-scholastic-text-field-${finalSubData.id}`}
                                                                                        onKeyPress={(e: any) => this.onKeyPressMarks(e)}
                                                                                        onChange={(e: any) => this.subCategoryMarksChange(e, data.id, categoryData.type, finalSubData, termData, subjectData)}
                                                                                    />
                                                                                    :
                                                                                    <span>-</span>
                                                                                }
                                                                            </TableCell>
                                                                        })}
                                                                    </> :
                                                                    <>
                                                                        {data?.sub_category?.length > 0 && data?.sub_category?.filter((subData: any) => {
                                                                            if (subData.term_id === termData.id) {
                                                                                return subData;
                                                                            }
                                                                        }).map((finalSubData: any) => {
                                                                            return <TableCell align='center' className="ScholasticRightBorderCell" key={finalSubData.id}>
                                                                                {Number(finalSubData.subject_id) === Number(subjectData.id) ?
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        placeholder='Marks'
                                                                                        type="number"
                                                                                        value={finalSubData?.marks ? finalSubData?.marks : ""}
                                                                                        className="no-underline"
                                                                                        inputProps={{
                                                                                            className: `${this.props.classes.heading_font} table-field-style`
                                                                                        }}
                                                                                        data-test-id={`scholastic-text-field-${finalSubData.id}`}
                                                                                        onKeyPress={(e: any) => this.onKeyPressMarks(e)}
                                                                                        onChange={(e: any) => this.subCategoryMarksChange(e, data.id, categoryData.type, finalSubData, termData, subjectData)}
                                                                                    />
                                                                                    :
                                                                                    <span>-</span>
                                                                                }
                                                                            </TableCell>
                                                                        })}
                                                                    </>
                                                                }
                                                            </React.Fragment>
                                                        ))}
                                                </React.Fragment>
                                            ))}
                                        </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    ))}
                </div>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(
    ScholasticAreas as React.ComponentType<any>
);
// Customizable Area End