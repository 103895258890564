// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
// Customizable Area Start
import { withRouter } from 'react-router-dom';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import 'react-calendar/dist/Calendar.css';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import AddressManagement from '../../blocks/AddressManagement/src/AddressManagement';
import Subtitles from '../../blocks/Subtitles/src/Subtitles';
import Gamification from '../../blocks/Gamification/src/Gamification';
import Analytics from '../../blocks/Analytics/src/Analytics';
import ElasticSearch from '../../blocks/ElasticSearch/src/ElasticSearch';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import AdminDashboard from '../../blocks/dashboard/src/AdminDashboard.web';
import ParentDashboard from '../../blocks/dashboard/src/ParentDashboard/ParentNewDashboard.web';
import StudentDashboard from '../../blocks/dashboard/src/StudentDashboard.web';
import TeacherDashboard from '../../blocks/dashboard/src/TeacherDashboard.web';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import AssessmentTest from '../../blocks/AssessmentTest/src/AssessmentTest';
import AssessmentSummary from '../../blocks/AssessmentTest/src/AssessmentQuestionBankLibrary/AssessmentSummary';
import AssessmentQuestionBankLibrary from '../../blocks/AssessmentTest/src/AssessmentQuestionBankLibrary/AssessmentLibrary';
import LongAnswerTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/LongAnswerTypeQuestion';
import ShortAnswerTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/ShortAnswerTypeQuestion';
//import QuestionTheme from '../../blocks/AssessmentTest/src/QuestionTheme';
import AdminGradeView from '../../blocks/Feedback/src/AdminGradeView';
import AdminTerm from '../../blocks/Feedback/src/AdminTerm.web';
//import StudentAssessment from '../../blocks/AssessmentTest/src/StudentAssessment.web';
import McqAnswerTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/McqAnswerTypeQuestion';
import GraphPlottingTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/GraphPlottingTypeQuestion';
import GraphPlottingLineChart from '../../blocks/AssessmentTest/src/AddQuestion/GraphPlottingLineChart';
import FlashCardTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/FlashCardTypeQuestion';
import AudioTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/AudioTypeQuestion';
import OneWordTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/OneWordTypeQuestion';
import TrueFalseTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/TrueFalseTypeQuestion';
import FillInTheBlanksTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/FillInTheBlanksTypeQuestion';
import PictionaryTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/PictionaryTypeQuestion';
import MatchTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/MatchTypeQuestion';
import VideoTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/VideoTypeQuestion';
import EmojiRatingTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/EmojiRatingTypeQuestion';
import TextRatingTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/TextRatingTypeQuestion';
import NumericRatingTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/NumericRatingTypeQuestion';
import PercentageRatingTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/PercentageRatingTypeQuestion';
import LabelImagewithDragAndDrop from '../../blocks/AssessmentTest/src/AddQuestion/LabelImagewithDragAndDrop';
import LabelImagewithDropDown from '../../blocks/AssessmentTest/src/AddQuestion/LabelImagewithDropDown';
import LabelImagewithTextInput from '../../blocks/AssessmentTest/src/AddQuestion/LabelImagewithTextInput';
import TwoByTwoGridTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/TwoByTwoGridTypeQuestion';
import HotspotTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/HotspotTypeQuestion';
import SortingTypeQuestion from '../../blocks/AssessmentTest/src/AddQuestion/SortingTypeQuestion';
import StudentAssessmentGuideline from '../../blocks/AssessmentTest/src/StudentAssessmentTest/StudentAssessmentGuideline.web';
import StudentAssessmentSummary from '../../blocks/AssessmentTest/src/StudentAssessmentTest/StudentAssessmentSummary.web';
import WhiteboardCollaboration from '../../blocks/WhiteboardCollaboration/src/WhiteboardCollaboration';
import Notifications from '../../blocks/Notifications/src/Notifications';
import Videos from '../../blocks/Videos/src/Videos';
import DynamicContent from '../../blocks/DynamicContent/src/DynamicContent';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import LiveStreaming from '../../blocks/LiveStreaming/src/LiveStreaming';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import CameraAccess from '../../blocks/CameraAccess/src/CameraAccess';
import VideoTrimming from '../../blocks/VideoTrimming/src/VideoTrimming';
import Gallery from '../../blocks/Gallery/src/Gallery';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ForgotPasswordAdmin from '../../blocks/forgot-password/src/ForgotPasswordAdmin.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP.web';
import ForgotPasswordAdminOTP from '../../blocks/forgot-password/src/ForgotPasswordAdminOTP.web';
import NewPassword from '../../blocks/forgot-password/src/NewPassword.web';
import NewPasswordAdmin from '../../blocks/forgot-password/src/NewPasswordAdmin.web';
import SucessfullPasswordChange from '../../blocks/forgot-password/src/SucessfullPasswordChange.web';
import SucessfullPasswordAdminChange from '../../blocks/forgot-password/src/SucessfullPasswordAdminChange.web';
import VideoManagement from '../../blocks/VideoManagement/src/VideoManagement';
import FileAttachment from '../../blocks/FileAttachment/src/FileAttachment';
import BiometricIntegrationForAttendence from '../../blocks/BiometricIntegrationForAttendence/src/BiometricIntegrationForAttendence';
import AccountScoreranking from '../../blocks/AccountScoreranking/src/AccountScoreranking';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailLogin.web';
import EmailLoginOTP from '../../blocks/email-account-login/src/EmailLoginOTP.web';
import SuperAdminLogin from '../../blocks/email-account-login/src/SuperAdminLogin.web';
import SchoolAdminLogin from '../../blocks/email-account-login/src/SchoolAdminLogin.web';
import AnimationsAndTransition from '../../blocks/AnimationsAndTransition/src/AnimationsAndTransition';
import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import PeopleManagement from '../../blocks/PeopleManagement/src/PeopleManagement';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import ItemAvailability from '../../blocks/ItemAvailability/src/ItemAvailability';
import SummaryCard from '../../blocks/SummaryCard/src/SummaryCard';
import PushNotifications from '../../blocks/PushNotifications/src/PushNotifications';
import GraphicalCharts from '../../blocks/GraphicalCharts/src/GraphicalCharts';
import CustomisedOrderStatus from '../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus';
import MatchAlgorithm from '../../blocks/MatchAlgorithm/src/MatchAlgorithm';
import GoalManagement from '../../blocks/GoalManagement/src/GoalManagement';
import DragDropInterface from '../../blocks/DragDropInterface/src/DragDropInterface';
import GoogleCalendarSync from '../../blocks/GoogleCalendarSync/src/GoogleCalendarSync';
import SingleSignonsso from '../../blocks/SingleSignonsso/src/SingleSignonsso';
import Themes from '../../blocks/Themes/src/Themes';
import WhiteBoardIntegration from '../../blocks/WhiteBoardIntegration/src/WhiteBoardIntegration';
import CallRecording from '../../blocks/CallRecording/src/CallRecording';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import Surveys from '../../blocks/Surveys/src/Surveys';
import QuestionBank from '../../blocks/QuestionBank/src/QuestionBank';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm';
import EmotionAnalysisIntegration from '../../blocks/EmotionAnalysisIntegration/src/EmotionAnalysisIntegration';
import Leaderboard from '../../blocks/Leaderboard/src/Leaderboard';
import Scheduling from '../../blocks/Scheduling/src/Scheduling';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import DocumentDistribution from '../../blocks/DocumentDistribution/src/DocumentDistribution';
import Calendar from '../../blocks/Calendar/src/Calendar';
// import AdminPeriodCreation from '../../blocks/Calendar/src/AdminPeriodCreation';
import PortfolioManagement from '../../blocks/PortfolioManagement/src/PortfolioManagement';
import CertificationTracking from '../../blocks/CertificationTracking/src/CertificationTracking';
import InventoryManagement from '../../blocks/InventoryManagement/src/InventoryManagement';
import Turni1n from '../../blocks/Turni1n/src/Turni1n';
import EmailIntegration from '../../blocks/EmailIntegration/src/EmailIntegration';
import TabletSupport from '../../blocks/TabletSupport/src/TabletSupport';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import StudentAccountRegistration from '../../blocks/email-account-registration/src/StudentAccountRegistration.web';
import TeacherAccountRegistration from '../../blocks/email-account-registration/src/TeacherAccountRegistration.web';
import AccountVerify from '../../blocks/email-account-registration/src/AccountVerify.web';
import OnBoardingSchoolDeatils from '../../blocks/email-account-registration/src/OnBoardingSchoolDeatils.web';
import AccountSucessFull from '../../blocks/email-account-registration/src/AccountSucessFull.web';
import EmailVerificationLink from '../../blocks/email-account-registration/src/EmailVerificationLink.web';
import LogoAndPicture from '../../blocks/email-account-registration/src/LogoAndPicture.web';
import GradesAndLevels from '../../blocks/email-account-registration/src/GradesAndLevels.web';
import Subjects from '../../blocks/email-account-registration/src/Subjects.web';
import ThemesAndFonts from '../../blocks/email-account-registration/src/ThemesAndFonts.web';
import CustomThemes from '../../blocks/email-account-registration/src/CustomThemes.web';
import AccountPhoneVerify from '../../blocks/email-account-registration/src/AccountPhoneVerify.web';
import ParentAccountRegistration from '../../blocks/email-account-registration/src/ParentAccountRegistration.web';
import AdminAccountRegistration from '../../blocks/email-account-registration/src/AdminAccountRegistration.web';
import DisplaySettings from '../../blocks/DisplaySettings/src/DisplaySettings';
import BiometricAuthorization from '../../blocks/BiometricAuthorization/src/BiometricAuthorization';
import VideoAutoplay from '../../blocks/VideoAutoplay/src/VideoAutoplay';
import Print from '../../blocks/Print/src/Print';
import VideoBackgrounds from '../../blocks/VideoBackgrounds/src/VideoBackgrounds';
import HelpCentre from '../../blocks/HelpCentre/src/HelpCentre';
import PerformanceTracker from '../../blocks/PerformanceTracker/src/PerformanceTracker';
import ShoppingCart from '../../blocks/ShoppingCart/src/ShoppingCart';
import DocumentOpener from '../../blocks/DocumentOpener/src/DocumentOpener';
import LiveChatSummary from '../../blocks/LiveChatSummary/src/LiveChatSummary';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import ScreenSharing from '../../blocks/ScreenSharing/src/ScreenSharing';
import TaskAllocator from '../../blocks/TaskAllocator/src/TaskAllocator';
import Chat from '../../blocks/Chat/src/Chat';
import UserAvatar from '../../blocks/UserAvatar/src/UserAvatar';
import CustomHeader from '../../blocks/CustomHeader/src/CustomHeader';
import BlockUsers from '../../blocks/BlockUsers/src/BlockUsers';
import TimesheetManagement from '../../blocks/TimesheetManagement/src/TimesheetManagement';
import Unicheck from '../../blocks/Unicheck/src/Unicheck';
import CustomizableTerminology from '../../blocks/CustomizableTerminology/src/CustomizableTerminology';
import VideoEditingTools from '../../blocks/VideoEditingTools/src/VideoEditingTools';
import ImageCapture from '../../blocks/ImageCapture/src/ImageCapture';
import Feedback from '../../blocks/Feedback/src/Feedback';
import AdminReportCard from '../../blocks/Feedback/src/AdminReportCard/AdminReportCard.web';
import CreateReportCard from '../../blocks/Feedback/src/AdminReportCard/CreateCustomReportCard/CreateCustomReportCard.web';
import AddCustomReportCard from '../../blocks/Feedback/src/AdminReportCard/CreateCustomReportCard/AddCustomReportCard.web';
import DraftReportCard from '../../blocks/Feedback/src/AdminReportCard/DraftReportCard/DraftReportCard.web';
import CreateReportCardStep2 from '../../blocks/Feedback/src/AdminReportCard/CreateReportCardStep2.web';
import CreateCoScholasticArea from '../../blocks/Feedback/src/AdminReportCard/CreateCoScholasticArea.web';
import ScholasticArea from '../../blocks/Feedback/src/AdminReportCard/ScholasticArea.web';
import AdminBadges from '../../blocks/Feedback/src/AdminBadges/AdminBadges.web';
import AdminAssignBadges from '../../blocks/Feedback/src/AdminBadges/AdminAssignBadges.web';
import CreateBadge from '../../blocks/Feedback/src/AdminBadges/CreateBadge.web';
import RecordingOfTestTaker from '../../blocks/RecordingOfTestTaker/src/RecordingOfTestTaker';
import OfflineBrowsing from '../../blocks/OfflineBrowsing/src/OfflineBrowsing';
import LogoDesign from '../../blocks/LogoDesign/src/LogoDesign';
import GroupVideoCall from '../../blocks/GroupVideoCall/src/GroupVideoCall';
import PledgeTracking from '../../blocks/PledgeTracking/src/PledgeTracking';
import CarouselDisplay0 from '../../blocks/CarouselDisplay0/src/CarouselDisplay0';
import OrderDetailView from '../../blocks/OrderDetailView/src/OrderDetailView';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import SoundProcessing from '../../blocks/SoundProcessing/src/SoundProcessing';
import PrivacySettings from '../../blocks/PrivacySettings/src/PrivacySettings';
import Translation from '../../blocks/Translation/src/Translation';
import CustomizablePortalIntegration from '../../blocks/CustomizablePortalIntegration/src/CustomizablePortalIntegration';
import TaskList from '../../blocks/TaskList/src/TaskList';
import VideoEmbeddingYoutube from '../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import SurroundingMonitoringmultiplePersonPresenceByImageProcessing from '../../blocks/SurroundingMonitoringmultiplePersonPresenceByImageProcessing/src/SurroundingMonitoringmultiplePersonPresenceByImageProcessing';
import VideoCall from '../../blocks/VideoCall/src/VideoCall';
import VideoLibrary from '../../blocks/VideoLibrary/src/VideoLibrary';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import LanguageOptions from '../../blocks/LanguageOptions/src/LanguageOptions';
import ProductRecommendationEngine from '../../blocks/ProductRecommendationEngine/src/ProductRecommendationEngine';
import Annotations from '../../blocks/Annotations/src/Annotations';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import MuteunmuteChat from '../../blocks/MuteunmuteChat/src/MuteunmuteChat';
import Chatbot from '../../blocks/Chatbot/src/Chatbot';
import CustomForm from '../../blocks/CustomForm/src/CustomForm';
import StudentProfile from '../../blocks/profiles/src/StudentProfile';
import StudentClassesAndSections from '../../blocks/ClassesAndSections/src/StudentClassesAndSections.web';
import StudentSubject from '../../blocks/ClassesAndSections/src/Subjects/StudentSubject.web';
import StudentTaskUnit from '../../blocks/ClassesAndSections/src/TaskUnit/StudentTaskUnit.web';
import StudentTimeTable from '../../blocks/ClassesAndSections/src/TimeTable/StudentTimeTable.web';
import StudentAttendance from '../../blocks/ClassesAndSections/src/Attendance/StudentAttendance.web';
import StudentMembers from '../../blocks/ClassesAndSections/src/Members/StudentMembers.web';
import { default as StudentGradeBook } from '../../blocks/ClassesAndSections/src/GradeBook/Student/GradeBook.web';
import StudentResources from '../../blocks/ClassesAndSections/src/Resources/StudentResources.web';
import TeacherSubject from '../../blocks/ClassesAndSections/src/Subjects/TeacherSubjects.web';
import TeacherTaskUnit from '../../blocks/ClassesAndSections/src/TaskUnit/TaskUnit.web';
import TeacherTimeTable from '../../blocks/ClassesAndSections/src/TimeTable/TeacherTimeTable.web';
import TeacherAttendance from '../../blocks/ClassesAndSections/src/Attendance/TeacherAttendance.web';
import TeacherMembers from '../../blocks/ClassesAndSections/src/Members/TeacherMembers.web';
import TeacherGradeBook from '../../blocks/ClassesAndSections/src/GradeBook/TeacherGradeBook.web';
import AssessmentGradeBook from '../../blocks/ClassesAndSections/src/GradeBook/TeacherTabs/AssessmentGradeBook.web';
import TeacherResources from '../../blocks/ClassesAndSections/src/Resources/TeacherResources.web';
import AdminAssessmentReport from '../../blocks/ClassesAndSections/src/AdminAssessmentReport/AdminAssessmentReport.web';
import TaskEvaluation from '../../blocks/ClassesAndSections/src/GradeBook/TeacherTabs/TaskEvaluation.web';
import TaskMarkSubmission from '../../blocks/ClassesAndSections/src/TaskUnit/TaskMarkSubmission.web';
import TaskMarkSubmissionTest from '../../blocks/ClassesAndSections/src/TaskUnit/TaskMarkSubmissionTest.web';
import LiveClassRecordings from '../../blocks/ClassesAndSections/src/Recordings/LiveClassRecordings.web';
import AdminEnrollUserAccount from '../../blocks/ClassesAndSections/src/AdminEnrollUserAccount.web';
import AdminDivision from '../../blocks/ClassesAndSections/src/AdminDivision.web';
import AdminClassesAndSections from '../../blocks/ClassesAndSections/src/AdminClassesAndSections.web';
import TeacherProfile from '../../blocks/profiles/src/TeacherProfile.web';
import TeacherClassesAndSections from '../../blocks/ClassesAndSections/src/TeacherClassesAndSections.web';
import AssessmentSummaryView from '../../blocks/AssessmentTest/src/Components/AssesmentSummaryView.web';
import TeacherAssessment from '../../blocks/AssessmentTest/src/TeacherAssessment.web';
import TeacherAssessmentModule from '../../blocks/AssessmentTest/src/TeacherAssessmentModule.web';
import TeacherAssessmentCreateDetail from '../../blocks/AssessmentTest/src/TeacherAssessmentCreateDetail.web';
import TeacherAssessmentCreateGuideline from '../../blocks/AssessmentTest/src/TeacherAssessmentCreateGuideline.web';
import TeacherAssessmentCreateSection from '../../blocks/AssessmentTest/src/TeacherAssessmentCreateSection.web';
import TeacherAssessmentCreateTheme from '../../blocks/AssessmentTest/src/TeacherAssessmentCreateTheme.web';
import AssessmentEvaluation from '../../blocks/AssessmentTest/src/AssessmentEvaluation/AssessmentEvaluation.web';
import AssessmentEvaluationDetails from '../../blocks/AssessmentTest/src/AssessmentEvaluation/AssessmentEvaluationDetails.web';
import StudentAssessmentAnswersheet from '../../blocks/AssessmentTest/src/AssessmentEvaluation/StudentAssessmentAnswersheet.web';
import StudentAssessmentResult from '../../blocks/AssessmentTest/src/StudentAssessmentTest/StudentAssessmentResult.web';
import AnswerSheet from '../../blocks/AssessmentTest/src/StudentAssessmentTest/AnswerSheet.web';
import StudentAssesmentAnswer from '../../blocks/AssessmentTest/src/StudentAssessment/StudentAssesmentAnswer.web';
import StudentAssessment from '../../blocks/AssessmentTest/src/StudentAssessmentTest/StudentAssessment.web';
import StudentAnswersheetQueryResolution from '../../blocks/AssessmentTest/src/StudentAssessmentTest/StudentAnswersheetQueryResolution.web';
import TeacherRecentActivity from '../../blocks/profiles/src/TeacherRecentActivity.web';
import StudentProtfolio from '../../blocks/PortfolioManagement/src/StudentProtfolio.web';
import StudentFiles from '../../blocks/PortfolioManagement/src/StudentFiles.web';
import StudentAddResouceAndGoals from '../../blocks/PortfolioManagement/src/StudentAddResouceAndGoals.web';
import ZoomMeetingHomePage from '../../blocks/ClassesAndSections/src/ZoomMeeting/ZoomMeetingHomePage.web';
import ViewPost from '../../blocks/PortfolioManagement/src/ViewPost.web';
// import AdminReminder from '../../blocks/info-page/src/AdminRQ/AdminReminder.web';
import AdminReminder from '../../blocks/Scheduling/src/AdminRQ/AdminReminder.web';
import { toast, ToastContainer } from 'react-toastify';
import AdminBatchAndPeriods from '../../blocks/ClassesAndSections/src/AdminBatchAndPeriod/AdminBatchAndPeriod.web';
import AdminClassPeriod from '../../blocks/ClassesAndSections/src/AdminClassPeriod/AdminClassPeriod.web';
import AdminFeedback from '../../blocks/ClassesAndSections/src/AdminFeedback/AdminFeedback.web';
import AssessmentUpload from '../../blocks/AssessmentTest/src/AssessmentUpload/AssessmentUpload.web';
import QuestionBankList from '../../blocks/AssessmentTest/src/AssessmentQuestionBankLibrary/QuestionBankList.web';
import LibraryAssessmentList from '../../blocks/AssessmentTest/src/AssessmentQuestionBankLibrary/LibraryAssessmentList.web';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import UserDocument from '../../blocks/profiles/src/UserDocument/UserDocument.web';
import ViewDocument from '../../blocks/profiles/src/UserDocument/ViewDocument.web';
import BulkUploadMediaAdapter from '../../blocks/adapters/src/BulkUploadMediaAdapter';
import AdminReportCardTemplate from '../../blocks/ClassesAndSections/src/AdminReportCard/AdminReportCardTemplate.web';
import ProfileDetails from '../../blocks/ClassesAndSections/src/AdminReportCard/ProfileDetails.web';
import StudentReportCard from '../../blocks/ClassesAndSections/src/StudentReportCard/StudentReportCard.web';
import StudentScholasticAreas from '../../blocks/ClassesAndSections/src/StudentReportCard/StudentScholasticAreas.web';
import StudentCoScholasticAreas from '../../blocks/ClassesAndSections/src/StudentReportCard/StudentCoScholasticAreas.web';
import StudentGradingDetails from '../../blocks/ClassesAndSections/src/StudentReportCard/StudentGradingDetails.web';
import GradingSystem from '../../blocks/ClassesAndSections/src/AdminReportCard/GradingSystem.web';
import GradingSystemEdit from '../../blocks/ClassesAndSections/src/AdminReportCard/GradingSystemEdit.web';
import AssessmentLearning from '../../blocks/ClassesAndSections/src/AdminReportCard/AssessmentLearning.web';
import AssessmentLearningEdit from '../../blocks/ClassesAndSections/src/AdminReportCard/AssessmentLearningEdit.web';
import AssessmentLearning2 from '../../blocks/ClassesAndSections/src/AdminReportCard/AssessmentLearning2.web';
import AssessmentLearning2Edit from '../../blocks/ClassesAndSections/src/AdminReportCard/AssessmentLearning2Edit.web';
import ReportCardEvaluation from '../../blocks/ClassesAndSections/src/AdminReportCard/ReportCardEvaluation.web';
import ReportCardEvaluationEdit from '../../blocks/ClassesAndSections/src/AdminReportCard/ReportCardEvaluationEdit.web';
import ReportCardPartOneFourFive from '../../blocks/ClassesAndSections/src/AdminReportCard/ReportCardPartOneFourFive.web';
import ReportCardPartOneFourFiveEdit from '../../blocks/ClassesAndSections/src/AdminReportCard/ReportCardPartOneFourFiveEdit.web';
import ReportCardTwoFourFive from '../../blocks/ClassesAndSections/src/AdminReportCard/ReportCardTwoFourFive.web';
import ReportCardTwoFourFiveEdit from '../../blocks/ClassesAndSections/src/AdminReportCard/ReportCardTwoFourFiveEdit.web';
import EvaluationFourFive from '../../blocks/ClassesAndSections/src/AdminReportCard/EvaluationFourFive.web';
import ProfileDetailsFull from '../../blocks/ClassesAndSections/src/AdminReportCard/ProfileDetailsFull.web';
import QuestionsLayout from '../../blocks/AssessmentTest/src/AddQuestion/QuestionsLayout.web.tsx';
import TeacherReportCard from '../../blocks/Feedback/src/TeacherReportCard/TeacherReportCard.web';
import ReportCardDetails from '../../blocks/Feedback/src/TeacherReportCard/ReportCardDetails.web';
import TeacherReportsDashboard from '../../blocks/AssessmentTest/src/AddQuestion/TeacherReportsDashboard.web.tsx';
import AssessmentSummaryReport from '../../blocks/AssessmentTest/src/TeacherAssessmentReports/AssessmentSummaryReport.web';
import ReportCardOneSixTen from '../../blocks/ClassesAndSections/src/AdminReportCard/ReportCardOneSixTen.web';
import ReportCardTwoCoScholastic from '../../blocks/ClassesAndSections/src/AdminReportCard/ReportCardTwoCoScholastic.web';
import ReportCardGradingSystem from '../../blocks/ClassesAndSections/src/AdminReportCard/ReportCardGradingSystem.web';
import ReportCardElevenTwelve from '../../blocks/ClassesAndSections/src/AdminReportCard/ReportCardElevenTwelve.web';
import ParentNotificationList from '../../blocks/dashboard/src/ParentDashboard/ParentNotification/ParentNotificationList.web';
import ParentAnnouncementList from '../../blocks/dashboard/src/ParentDashboard/ParentAnnouncement/ParentAnnouncementList.web';
import { pink, red } from '@material-ui/core/colors';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datetime/css/react-datetime.css';
import { backGroundLayout } from '../../blocks/shared/SideBar/src/LayoutBackground';
import axios from 'axios';
export const configJSON = require('./config');
import firebase from 'firebase/app';
import 'firebase/messaging';
import moment from 'moment';
// Customizable Area End
// new line
// new line
// new line
// new line
// new line
import MultipleAssessmentReport from '../../blocks/AssessmentTest/src/AddQuestion/MultipleAssessmentReport.web.tsx';
const hello = () => {
  return <h1>hello</h1>;
};

const routeMap = {
  // Customizable Area Start
  AddressManagement: {
    component: AddressManagement,
    path: '/AddressManagement',
    type: ''
  },
  AssessmentSummaryReport: {
    component: AssessmentSummaryReport,
    path: '/AssessmentSummaryReport',
    type: ''
  },
  AnswerSheet: {
    component: AnswerSheet,
    path: '/StudentViewAnswerSheet'
  },
  QuestionsLayout: {
    component: QuestionsLayout,
    path: '/QuestionsLayout'
  },
  StudentProfile: {
    component: StudentProfile,
    path: '/StudentProfile'
  },
  ViewPost: {
    component: ViewPost,
    path: '/ViewPost'
  },
  TeacherReportsDashboard: {
    component: TeacherReportsDashboard,
    path: '/TeacherReportsDashboard'
  },
  StudentProtfolio: {
    component: StudentProtfolio,
    path: '/StudentProtfolio'
  },
  StudentFiles: {
    component: StudentFiles,
    path: '/StudentFiles'
  },
  StudentAddResouceAndGoals: {
    component: StudentAddResouceAndGoals,
    path: '/StudentAddResouceAndGoals'
  },
  StudentClassesAndSections: {
    component: StudentClassesAndSections,
    path: '/StudentClassesAndSections'
  },
  StudentSubject: {
    component: StudentSubject,
    path: '/StudentSubject'
  },
  TeacherSubject: {
    component: TeacherSubject,
    path: '/TeacherSubject'
  },
  StudentTaskUnit: {
    component: StudentTaskUnit,
    path: '/StudentTaskUnit'
  },
  TeacherTaskUnit: {
    component: TeacherTaskUnit,
    path: '/TeacherTaskUnit'
  },
  TaskEvaluation: {
    component: TaskEvaluation,
    path: '/TaskEvaluation'
  },
  TaskMarkSubmission: {
    component: TaskMarkSubmission,
    path: '/TaskMarkSubmission'
  },
  TaskMarkSubmissionTest: {
    component: TaskMarkSubmissionTest,
    path: '/TaskMarkSubmissionTest'
  },
  StudentTimeTable: {
    component: StudentTimeTable,
    path: '/StudentTimeTable'
  },
  TeacherTimeTable: {
    component: TeacherTimeTable,
    path: '/TeacherTimeTable'
  },
  StudentAttendance: {
    component: StudentAttendance,
    path: '/StudentAttendance'
  },
  TeacherAttendance: {
    component: TeacherAttendance,
    path: '/TeacherAttendance'
  },
  StudentMembers: {
    component: StudentMembers,
    path: '/StudentMembers'
  },
  TeacherMembers: {
    component: TeacherMembers,
    path: '/TeacherMembers'
  },
  StudentGradeBook: {
    component: StudentGradeBook,
    path: '/StudentGradeBook'
  },
  TeacherGradeBook: {
    component: TeacherGradeBook,
    path: '/TeacherGradeBook'
  },
  TeacherAssessmentGradeBook: {
    component: AssessmentGradeBook,
    path: '/TeacherAssessmentGradeBook'
  },
  StudentResources: {
    component: StudentResources,
    path: '/StudentResources'
  },
  TeacherResources: {
    component: TeacherResources,
    path: '/TeacherResources'
  },
  LiveClassRecordings: {
    component: LiveClassRecordings,
    path: '/LiveClassRecordings'
  },
  AdminEnrollUserAccount: {
    component: AdminEnrollUserAccount,
    path: '/AdminEnrollUserAccount'
  },
  UserDocument: {
    component: UserDocument,
    path: '/UserDocument'
  },
  ViewDocument: {
    component: ViewDocument,
    path: '/ViewDocument'
  },
  AdminDivision: {
    component: AdminDivision,
    path: '/AdminDivision'
  },
  AdminClassesAndSections: {
    component: AdminClassesAndSections,
    path: '/AdminClassesAndSections'
  },
  AdminBatchAndPeriods: {
    component: AdminBatchAndPeriods,
    path: '/AdminBatchAndPeriods'
  },
  AdminFeedback: {
    component: AdminFeedback,
    path: '/AdminFeedback'
  },
  AdminReportCard: {
    component: AdminReportCard,
    path: '/AdminReportCard'
  },
  CreateReportCard: {
    component: CreateReportCard,
    path: '/CreateEvaluationDetails'
  },
  AddCustomReportCard: {
    component: AddCustomReportCard,
    path: '/AddCustomReportCard'
  },

  DraftReportCard: {
    component: DraftReportCard,
    path: '/DraftReportCard'
  },
  CreateReportCardStep2: {
    component: CreateReportCardStep2,
    path: '/CreateReportCardStep2'
  },
  ScholasticArea: {
    component: ScholasticArea,
    path: '/ScholasticArea'
  },
  CreateCoScholasticArea: {
    component: CreateCoScholasticArea,
    path: '/CoScholasticArea'
  },
  TeacherReportCard: {
    component: TeacherReportCard,
    path: '/TeacherReportCard'
  },
  ReportCardDetails: {
    component: ReportCardDetails,
    path: '/ReportCardDetails'
  },
  AdminBadges: {
    component: AdminBadges,
    path: '/Badges'
  },
  AdminAssignBadges: {
    component: AdminAssignBadges,
    path: '/AssignBadges'
  },
  CreateBadge: {
    component: CreateBadge,
    path: '/CreateBadge'
  },
  AdminAssessmentReport: {
    component: AdminAssessmentReport,
    path: '/AdminAssessmentReport'
  },
  AdminClassPeriod: {
    component: AdminClassPeriod,
    path: '/AdminClassPeriod'
  },
  AssessmentUpload: {
    component: AssessmentUpload,
    path: '/AssessmentUpload'
  },
  TeacherClassesAndSections: {
    component: TeacherClassesAndSections,
    path: '/TeacherClassesAndSections'
  },
  TeacherAssessment: {
    component: TeacherAssessment,
    path: '/TeacherAssessment'
  },
  TeacherAssessmentModule: {
    component: TeacherAssessmentModule,
    path: '/TeacherAssessmentModule'
  },
  TeacherAssessmentCreateGuideline: {
    component: TeacherAssessmentCreateGuideline,
    path: '/TeacherAssessmentCreateGuideline'
  },
  TeacherAssessmentCreateDetail: {
    component: TeacherAssessmentCreateDetail,
    path: '/TeacherAssessmentCreateDetail'
  },
  TeacherAssessmentCreateSection: {
    component: TeacherAssessmentCreateSection,
    path: '/TeacherAssessmentCreateSection'
  },
  TeacherAssessmentCreateTheme: {
    component: TeacherAssessmentCreateTheme,
    path: '/TeacherAssessmentCreateTheme'
  },
  AssessmentEvaluation: {
    component: AssessmentEvaluation,
    path: '/AssessmentEvaluation'
  },
  AssessmentEvaluationDetails: {
    component: AssessmentEvaluationDetails,
    path: '/AssessmentEvaluationDetails'
  },
  StudentAssessmentResult: {
    component: StudentAssessmentResult,
    path: '/StudentAssessmentResult'
  },
  StudentAnswersheetQueryResolution: {
    component: StudentAnswersheetQueryResolution,
    path: '/StudentAssessmentQueryResolution'
  },
  StudentAssessmentAnswersheet: {
    component: StudentAssessmentAnswersheet,
    path: '/StudentAssessmentAnswersheet'
  },
  AssessmentSummaryView: {
    component: AssessmentSummaryView,
    path: '/AssessmentSummaryView'
  },
  StudentAssesmentAnswer: {
    component: StudentAssesmentAnswer,
    path: '/StudentAssesmentAnswer',
    type: 'Guest'
  },
  StudentProfile: {
    component: StudentProfile,
    path: '/StudentProfile'
  },
  TeacherProfile: {
    component: TeacherProfile,
    path: '/TeacherProfile'
  },
  Subtitles: {
    component: Subtitles,
    path: '/Subtitles'
  },
  Gamification: {
    component: Gamification,
    path: '/Gamification'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: '/ElasticSearch'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  TeacherDashboard: {
    component: TeacherDashboard,
    path: '/TeacherDashboard'
  },
  StudentDashboard: {
    component: StudentDashboard,
    path: '/StudentDashboard'
  },
  ParentDashboard: {
    component: ParentDashboard,
    path: '/ParentDashboard'
  },
  AdminDashboard: {
    component: AdminDashboard,
    path: '/AdminDashboard'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: '/AssessmentTest'
  },
  AssessmentQuestionBankLibrary: {
    component: AssessmentQuestionBankLibrary,
    path: '/AssessmentQuestionBankLibrary'
  },
  AssessmentSummary: {
    component: AssessmentSummary,
    path: '/AssessmentSummary'
  },
  AssessmentQuestionBankList: {
    component: QuestionBankList,
    path: '/QuestionBank'
  },
  LibraryAssessmentList:{
    component: LibraryAssessmentList,
    path: '/LibraryAssessmentList'
  },
  LongAnswerTypeQuestion: {
    component: LongAnswerTypeQuestion,
    path: '/LongAnswerTypeQuestion'
  },
  ShortAnswerTypeQuestion: {
    component: ShortAnswerTypeQuestion,
    path: '/ShortAnswerTypeQuestion'
  },
  McqAnswerTypeQuestion: {
    component: McqAnswerTypeQuestion,
    path: '/McqAnswerTypeQuestion'
  },
  GraphPlottingTypeQuestion: {
    component: GraphPlottingTypeQuestion,
    path: '/GraphPlottingTypeQuestion'
  },
  FlashCardTypeQuestion: {
    component: FlashCardTypeQuestion,
    path: '/FlashCardTypeQuestion'
  },
  GraphPlottingLineChart: {
    component: GraphPlottingLineChart,
    path: '/GraphPlottingLineChart'
  },
  AudioTypeQuestion: {
    component: AudioTypeQuestion,
    path: '/AudioTypeQuestion'
  },
  OneWordTypeQuestion: {
    component: OneWordTypeQuestion,
    path: '/OneWordTypeQuestion'
  },
  TrueFalseTypeQuestion: {
    component: TrueFalseTypeQuestion,
    path: '/TrueFalseTypeQuestion'
  },
  LabelImagewithDragAndDrop: {
    component: LabelImagewithDragAndDrop,
    path: '/LabelImagewithDragAndDrop'
  },
  LabelImagewithDropDown: {
    component: LabelImagewithDropDown,
    path: '/LabelImagewithDropDown'
  },
  LabelImagewithTextInput: {
    component: LabelImagewithTextInput,
    path: '/LabelImagewithTextInput'
  },
  TwoByTwoGridTypeQuestion: {
    component: TwoByTwoGridTypeQuestion,
    path: '/TwoByTwoGridTypeQuestion'
  },
  HotspotTypeQuestion: {
    component: HotspotTypeQuestion,
    path: '/HotspotTypeQuestion'
  },
  FillInTheBlanksTypeQuestion: {
    component: FillInTheBlanksTypeQuestion,
    path: '/FillInTheBlanksTypeQuestion'
  },
  PictionaryTypeQuestion: {
    component: PictionaryTypeQuestion,
    path: '/PictionaryTypeQuestion'
  },
  MatchTypeQuestion: {
    component: MatchTypeQuestion,
    path: '/MatchTypeQuestion'
  },
  VideoTypeQuestion: {
    component: VideoTypeQuestion,
    path: '/VideoTypeQuestion'
  },
  TextRatingTypeQuestion: {
    component: TextRatingTypeQuestion,
    path: '/TextRatingTypeQuestion'
  },
  EmojiRatingTypeQuestion: {
    component: EmojiRatingTypeQuestion,
    path: '/EmojiRatingTypeQuestion'
  },
  NumericRatingTypeQuestion: {
    component: NumericRatingTypeQuestion,
    path: '/NumericRatingTypeQuestion'
  },
  PercentageRatingTypeQuestion: {
    component: PercentageRatingTypeQuestion,
    path: '/PercentageRatingTypeQuestion'
  },
  SortingTypeQuestion: {
    component: SortingTypeQuestion,
    path: '/SortingTypeQuestion'
  },
  StudentAssessment: {
    component: StudentAssessment,
    path: '/StudentAssessment'
  },
  StudentAssessmentGuideline: {
    component: StudentAssessmentGuideline,
    path: '/StudentAssessmentGuideline'
  },
  StudentAssessmentSummary: {
    component: StudentAssessmentSummary,
    path: '/StudentAssessmentSummary'
  },
  WhiteboardCollaboration: {
    component: WhiteboardCollaboration,
    path: '/WhiteboardCollaboration'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  DynamicContent: {
    component: DynamicContent,
    path: '/DynamicContent'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  LiveStreaming: {
    component: LiveStreaming,
    path: '/LiveStreaming'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  CameraAccess: {
    component: CameraAccess,
    path: '/CameraAccess'
  },
  VideoTrimming: {
    component: VideoTrimming,
    path: '/VideoTrimming'
  },
  Gallery: {
    component: Gallery,
    path: '/Gallery'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword',
    type: 'Guest'
  },
  ForgotPasswordAdmin: {
    component: ForgotPasswordAdmin,
    path: '/ForgotPasswordAdmin',
    type: 'Guest'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP',
    type: 'Guest'
  },
  ForgotPasswordAdminOTP: {
    component: ForgotPasswordAdminOTP,
    path: '/ForgotPasswordAdminOTP',
    type: 'Guest'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword',
    type: 'Guest'
  },
  NewPasswordAdmin: {
    component: NewPasswordAdmin,
    path: '/NewPasswordAdmin',
    type: 'Guest'
  },
  SucessfullPasswordChange: {
    component: SucessfullPasswordChange,
    path: '/SucessfullPasswordChange',
    type: 'Guest'
  },

  SucessfullPasswordAdminChange: {
    component: SucessfullPasswordAdminChange,
    path: '/SucessfullPasswordAdminChange',
    type: 'Guest'
  },
  VideoManagement: {
    component: VideoManagement,
    path: '/VideoManagement'
  },
  FileAttachment: {
    component: FileAttachment,
    path: '/FileAttachment'
  },
  BiometricIntegrationForAttendence: {
    component: BiometricIntegrationForAttendence,
    path: '/BiometricIntegrationForAttendence'
  },
  AccountScoreranking: {
    component: AccountScoreranking,
    path: '/AccountScoreranking'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock',
    type: 'Guest'
  },

  AdminReportCardTemplate: {
    component: AdminReportCardTemplate,
    path: '/AdminReportCardTemplate'
  },

  ProfileDetails: {
    component: ProfileDetails,
    path: '/ProfileDetails'
  },

  StudentReportCard: {
    component: StudentReportCard,
    path: '/StudentReportCard'
  },

  StudentScholasticAreas: {
    component: StudentScholasticAreas,
    path: '/StudentScholasticAreas'
  },

  StudentCoScholasticAreas: {
    component: StudentCoScholasticAreas,
    path: '/StudentCoScholasticAreas'
  },

  StudentGradingDetails: {
    component: StudentGradingDetails,
    path: '/StudentGradingDetails'
  },

  AssessmentLearning: {
    component: AssessmentLearning,
    path: '/AssessmentLearning'
  },

  AssessmentLearningEdit: {
    component: AssessmentLearningEdit,
    path: '/AssessmentLearningEdit'
  },

  GradingSystem: {
    component: GradingSystem,
    path: '/GradingSystem'
  },

  GradingSystemEdit: {
    component: GradingSystemEdit,
    path: '/GradingSystemEdit'
  },

  AssessmentLearning2: {
    component: AssessmentLearning2,
    path: '/AssessmentLearning2'
  },

  ProfileDetailsFull: {
    component: ProfileDetailsFull,
    path: '/ProfileDetailsFull'
  },

  // create new route start
  AssessmentLearning2Edit: {
    component: AssessmentLearning2Edit,
    path: '/AssessmentLearning2Edit'
  },

  ReportCardEvaluation: {
    component: ReportCardEvaluation,
    path: '/ReportCardEvaluation'
  },
  ReportCardEvaluationEdit: {
    component: ReportCardEvaluationEdit,
    path: '/ReportCardEvaluationEdit'
  },
  ReportCardPartOneFourFive: {
    component: ReportCardPartOneFourFive,
    path: '/ReportCardPartOneFourFive'
  },
  ReportCardPartOneFourFiveEdit: {
    component: ReportCardPartOneFourFiveEdit,
    path: '/ReportCardPartOneFourFiveEdit'
  },
  ReportCardTwoFourFive: {
    component: ReportCardTwoFourFive,
    path: '/ReportCardTwoFourFive'
  },
  ReportCardTwoFourFiveEdit: {
    component: ReportCardTwoFourFiveEdit,
    path: '/ReportCardTwoFourFiveEdit'
  },
  EvaluationFourFive: {
    component: EvaluationFourFive,
    path: '/EvaluationFourFive'
  },

  ReportCardOneSixTen: {
    component: ReportCardOneSixTen,
    path: '/ReportCardOneSixTen'
  },
  ReportCardTwoCoScholastic: {
    component: ReportCardTwoCoScholastic,
    path: '/ReportCardTwoCoScholastic'
  },
  ReportCardGradingSystem: {
    component: ReportCardGradingSystem,
    path: '/ReportCardGradingSystem'
  },
  ReportCardElevenTwelve: {
    component: ReportCardElevenTwelve,
    path: '/ReportCardElevenTwelve'
  },

  //  end new route

  SuperAdminLogin: {
    component: SuperAdminLogin,
    path: '/SuperAdminLogin',
    type: 'Guest'
  },
  SchoolAdminLogin: {
    component: SchoolAdminLogin,
    path: '/SchoolAdminLogin',
    type: 'Guest'
  },
  EmailLoginOTP: {
    component: EmailLoginOTP,
    path: '/EmailLoginOTP',
    type: 'Guest'
  },
  StudentAccountRegistration: {
    component: StudentAccountRegistration,
    path: '/StudentAccountRegistration',
    type: 'Guest'
  },
  TeacherAccountRegistration: {
    component: TeacherAccountRegistration,
    path: '/TeacherAccountRegistration',
    type: 'Guest'
  },
  AccountSucessFull: {
    component: AccountSucessFull,
    path: '/AccountSucessFull',
    type: 'Guest'
  },
  EmailVerificationLink: {
    component: EmailVerificationLink,
    path: '/EmailVerificationLink',
    type: 'Guest'
  },

  AccountVerify: {
    component: AccountVerify,
    path: '/AccountVerify',
    type: 'Guest'
  },
  OnBoardingSchoolDeatils: {
    component: OnBoardingSchoolDeatils,
    path: '/OnBoardingSchoolDeatils',
    type: 'Guest'
  },
  LogoAndPicture: {
    component: LogoAndPicture,
    path: '/LogoAndPicture',
    type: 'Guest'
  },
  GradesAndLevels: {
    component: GradesAndLevels,
    path: '/GradesAndLevels',
    type: 'Guest'
  },
  Subjects: {
    component: Subjects,
    path: '/Subjects',
    type: 'Guest'
  },
  ThemesAndFonts: {
    component: ThemesAndFonts,
    path: '/ThemesAndFonts',
    type: 'Guest'
  },
  CustomThemes: {
    component: CustomThemes,
    path: '/CustomThemes',
    type: 'Guest'
  },
  AccountPhoneVerify: {
    component: AccountPhoneVerify,
    path: '/AccountPhoneVerify',
    type: 'Guest'
  },
  ParentAccountRegistration: {
    component: ParentAccountRegistration,
    path: '/ParentAccountRegistration',
    type: 'Guest'
  },
  AdminAccountRegistration: {
    component: AdminAccountRegistration,
    path: '/AdminAccountRegistration',
    type: 'Guest'
  },
  AnimationsAndTransition: {
    component: AnimationsAndTransition,
    path: '/AnimationsAndTransition'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  PeopleManagement: {
    component: PeopleManagement,
    path: '/PeopleManagement'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  ItemAvailability: {
    component: ItemAvailability,
    path: '/ItemAvailability'
  },
  SummaryCard: {
    component: SummaryCard,
    path: '/SummaryCard'
  },
  PushNotifications: {
    component: PushNotifications,
    path: '/PushNotifications'
  },
  GraphicalCharts: {
    component: GraphicalCharts,
    path: '/GraphicalCharts'
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: '/CustomisedOrderStatus'
  },
  MatchAlgorithm: {
    component: MatchAlgorithm,
    path: '/MatchAlgorithm'
  },
  GoalManagement: {
    component: GoalManagement,
    path: '/GoalManagement'
  },
  DragDropInterface: {
    component: DragDropInterface,
    path: '/DragDropInterface'
  },
  GoogleCalendarSync: {
    component: GoogleCalendarSync,
    path: '/GoogleCalendarSync'
  },
  SingleSignonsso: {
    component: SingleSignonsso,
    path: '/SingleSignonsso'
  },
  Themes: {
    component: Themes,
    path: '/Themes'
  },
  WhiteBoardIntegration: {
    component: WhiteBoardIntegration,
    path: '/WhiteBoardIntegration'
  },
  CallRecording: {
    component: CallRecording,
    path: '/CallRecording'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  Surveys: {
    component: Surveys,
    path: '/Surveys'
  },
  // QuestionBank: {
  //   component: QuestionBank,
  //   path: '/QuestionBank'
  // },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  EmotionAnalysisIntegration: {
    component: EmotionAnalysisIntegration,
    path: '/EmotionAnalysisIntegration'
  },
  Leaderboard: {
    component: Leaderboard,
    path: '/Leaderboard'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  DocumentDistribution: {
    component: DocumentDistribution,
    path: '/DocumentDistribution'
  },
  Calendar: {
    component: Calendar,
    path: '/Calendar'
  },
  PortfolioManagement: {
    component: PortfolioManagement,
    path: '/PortfolioManagement'
  },
  CertificationTracking: {
    component: CertificationTracking,
    path: '/CertificationTracking'
  },
  InventoryManagement: {
    component: InventoryManagement,
    path: '/InventoryManagement'
  },
  Turni1n: {
    component: Turni1n,
    path: '/Turni1n'
  },

  EmailIntegration: {
    component: EmailIntegration,
    path: '/EmailIntegration'
  },
  TabletSupport: {
    component: TabletSupport,
    path: '/TabletSupport'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration',
    type: 'Guest'
  },
  DisplaySettings: {
    component: DisplaySettings,
    path: '/DisplaySettings'
  },
  BiometricAuthorization: {
    component: BiometricAuthorization,
    path: '/BiometricAuthorization'
  },
  VideoAutoplay: {
    component: VideoAutoplay,
    path: '/VideoAutoplay'
  },
  Print: {
    component: Print,
    path: '/Print'
  },
  VideoBackgrounds: {
    component: VideoBackgrounds,
    path: '/VideoBackgrounds'
  },
  HelpCentre: {
    component: HelpCentre,
    path: '/HelpCentre'
  },
  PerformanceTracker: {
    component: PerformanceTracker,
    path: '/PerformanceTracker'
  },
  ShoppingCart: {
    component: ShoppingCart,
    path: '/ShoppingCart'
  },
  DocumentOpener: {
    component: DocumentOpener,
    path: '/DocumentOpener'
  },
  LiveChatSummary: {
    component: LiveChatSummary,
    path: '/LiveChatSummary'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  ScreenSharing: {
    component: ScreenSharing,
    path: '/ScreenSharing'
  },
  TaskAllocator: {
    component: TaskAllocator,
    path: '/TaskAllocator'
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  UserAvatar: {
    component: UserAvatar,
    path: '/UserAvatar'
  },
  CustomHeader: {
    component: CustomHeader,
    path: '/CustomHeader'
  },
  BlockUsers: {
    component: BlockUsers,
    path: '/BlockUsers'
  },
  TimesheetManagement: {
    component: TimesheetManagement,
    path: '/TimesheetManagement'
  },
  Unicheck: {
    component: Unicheck,
    path: '/Unicheck'
  },
  CustomizableTerminology: {
    component: CustomizableTerminology,
    path: '/CustomizableTerminology'
  },
  VideoEditingTools: {
    component: VideoEditingTools,
    path: '/VideoEditingTools'
  },
  ImageCapture: {
    component: ImageCapture,
    path: '/ImageCapture'
  },
  Feedback: {
    component: Feedback,
    path: '/Feedback'
  },
  RecordingOfTestTaker: {
    component: RecordingOfTestTaker,
    path: '/RecordingOfTestTaker'
  },
  OfflineBrowsing: {
    component: OfflineBrowsing,
    path: '/OfflineBrowsing'
  },
  LogoDesign: {
    component: LogoDesign,
    path: '/LogoDesign'
  },
  GroupVideoCall: {
    component: GroupVideoCall,
    path: '/GroupVideoCall'
  },
  PledgeTracking: {
    component: PledgeTracking,
    path: '/PledgeTracking'
  },
  CarouselDisplay0: {
    component: CarouselDisplay0,
    path: '/CarouselDisplay0'
  },
  OrderDetailView: {
    component: OrderDetailView,
    path: '/OrderDetailView'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  SoundProcessing: {
    component: SoundProcessing,
    path: '/SoundProcessing'
  },
  PrivacySettings: {
    component: PrivacySettings,
    path: '/PrivacySettings'
  },
  Translation: {
    component: Translation,
    path: '/Translation'
  },
  CustomizablePortalIntegration: {
    component: CustomizablePortalIntegration,
    path: '/CustomizablePortalIntegration'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  VideoEmbeddingYoutube: {
    component: VideoEmbeddingYoutube,
    path: '/VideoEmbeddingYoutube'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  SurroundingMonitoringmultiplePersonPresenceByImageProcessing: {
    component: SurroundingMonitoringmultiplePersonPresenceByImageProcessing,
    path: '/SurroundingMonitoringmultiplePersonPresenceByImageProcessing'
  },
  VideoCall: {
    component: VideoCall,
    path: '/VideoCall'
  },
  VideoLibrary: {
    component: VideoLibrary,
    path: '/VideoLibrary'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: '/LanguageOptions'
  },
  ProductRecommendationEngine: {
    component: ProductRecommendationEngine,
    path: '/ProductRecommendationEngine'
  },
  Annotations: {
    component: Annotations,
    path: '/Annotations'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  MuteunmuteChat: {
    component: MuteunmuteChat,
    path: '/MuteunmuteChat'
  },
  Chatbot: {
    component: Chatbot,
    path: '/Chatbot'
  },
  CustomForm: {
    component: CustomForm,
    path: '/CustomForm'
  },

  Home: {
    component: Filteritems,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  // AdminReminder: {
  //   component: AdminReminder,
  //   path: '/AdminReminder'
  // },
  AdminGradeView: {
    component: AdminGradeView,
    path: '/AdminGradeView'
  },
  AdminTerm: {
    component: AdminTerm,
    path: '/AdminTerm'
  },

  AdminReminder: {
    component: AdminReminder,
    path: '/AdminReminder'
  },

  // AdminReminder1: {
  //   component: AdminReminder1,
  //   path: '/AdminReminder1'
  // },

  MultipleAssessmentReport: {
    component: MultipleAssessmentReport,
    path: '/MultipleAssessmentReport'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true,
    type: 'Guest'
  },
  RecentActivity: {
    component: TeacherRecentActivity,
    path: '/RecentActivity',
    exact: true
  },
  ZoomMeetingHomePage: {
    component: ZoomMeetingHomePage,
    path: '/Meeting',
    exact: true
  },
  ParentNotificationList: {
    component: ParentNotificationList,
    path: '/Parent/Notification'
  },
  ParentAnnouncementList : {
    component: ParentAnnouncementList,
    path: '/Parent/Announcement'
  },
  // Customizable Area End
};

const firebaseConfig = {
  apiKey: 'AIzaSyDgLv8cEZs3rmOOw4bqy3oqnnIhajX-9iY',
  authDomain: 'kschool-588ba.firebaseapp.com',
  projectId: 'kschool-588ba',
  storageBucket: 'kschool-588ba.appspot.com',
  messagingSenderId: '261403963134',
  appId: '1:261403963134:web:34295fc4cad4e2b45d3faf',
  measurementId: 'G-8W5Y4J2BXL'
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

const messaging = firebase.messaging();
class App extends Component {
  state = {
    // Customizable Area Start
    themeData: {},
    mainTheme: false,
    bulkUploadInstance: null
    // Customizable Area End
  };
  // Customizable Area Start
  handlecheckThemeData = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}');
    if (
      localStorage.getItem('token') ||
      window.location.href.toLowerCase().includes('TeacherDashboard') ||
      window.location.href.toLowerCase().includes('StudentDashboard') ||
      window.location.href.toLowerCase().includes('AdminClassesAndSections') ||
      data.theme_id != null
    ) {
      this.getThemeData();
    }
  };
  // Customizable Area Start
  componentDidMount() {
    // Customizable Area Start
    this.setState({ bulkUploadInstance: new BulkUploadMediaAdapter() });
    window.addEventListener('load', this.handlecheckThemeData);
    
    let login_user = localStorage.getItem('user_login');
    if (localStorage.getItem('token') && login_user == 'true') {
      Notification.requestPermission().then(permission => {
        if (permission !== 'granted') {
          if (
            !window.location.href.toLowerCase().includes('meeting') ||
            !window.location.href
              .toLowerCase()
              .includes('emailverificationlink')
          ) {
            toast.error('Notification permission is required.');
          }
          Notification.requestPermission();
        }
      });
      this.saveFireBase();
      this.getNotificationMsg();
    }
    if (
      window.location.href.toLowerCase().includes('emailverificationlink') ||
      window.location.href
        .toLowerCase()
        .includes('parentaccountregistration') ||
      window.location.href.toLowerCase().includes('adminaccountregistration') ||
      window.location.href
        .toLowerCase()
        .includes('studentaccountregistration') ||
      window.location.href
        .toLowerCase()
        .includes('teacheraccountregistration') ||
      window.location.href.toLowerCase().includes('emailaccountloginblock') ||
      window.location.href.toLowerCase().includes('superadminlogin') ||
      window.location.href.toLowerCase().includes('schooladminlogin') ||
      window.location.href.toLowerCase().includes('emailloginotp') ||
      window.location.href.toLowerCase().includes('emailaccountregistration') ||
      window.location.href.toLowerCase().includes('forgotpassword') ||
      window.location.href.toLowerCase().includes('forgotpasswordadmin') ||
      window.location.href.toLowerCase().includes('forgotpasswordotp') ||
      window.location.href.toLowerCase().includes('forgotpasswordadminotp') ||
      window.location.href.toLowerCase().includes('newpassword') ||
      window.location.href.toLowerCase().includes('newpasswordadmin') ||
      window.location.href.toLowerCase().includes('sucessfullpasswordchange') ||
      window.location.href
        .toLowerCase()
        .includes('sucessfullpasswordadminChange') ||
      window.location.href.toLowerCase().includes('alertweb') ||
      window.location.href.toLowerCase().includes('helpcentre') ||
      window.location.href.toLowerCase().includes('OnBoardingSchoolDeatils') ||
      localStorage.getItem('token')
    ) {
    } else {
      const arr = window.location.href.split('/');
      const url = arr[arr.length - 1];
      localStorage.setItem('urlData', url);

      this.props.history.push(`/EmailAccountLoginBlock`);
    }
    // Customizable Area End
  }
  componentDidUpdate() {
    // Customizable Area Start
    let login_user = localStorage.getItem('user_login');
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}');
    if (localStorage.getItem('token') && login_user == 'true') {
      this.saveFireBase();
      if (
        Object.keys(this.state.themeData).length == 0 &&
        data.theme_id != null
      ) {
        this.getThemeData();
      }
    }
    // Customizable Area End
  }
  async componentWillUnmount() {
    const self = this;
    // Customizable Area Start
    window.removeEventListener('load', this.handlecheckThemeData);
    // Customizable Area End
  }
  // Customizable Area Start
  getNotificationMsg = () =>
    new Promise(resolve => {
      messaging.onMessage(payload => {
        const notificationTitle = payload.data.title;
        const notificationOptions = {
          body: payload.data.message,
          icon: './21klogo.png',
          data: payload.data
        };
        var notification = new Notification(
          notificationTitle,
          notificationOptions
        );
        // notification.show()
        notification.addEventListener('click', event => {
          event.preventDefault(); // prevent the browser from focusing the Notification's tab
          this.props.history.push(payload.data.app_url);
        });
        resolve(payload);
      });
    });

  deleteFirebaseToken = deviceId => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}');
    const header = {
      'Content-Type': configJSON.exampleApiContentType,
      token: localStorage.getItem('token'),
      school: data?.school_id
    };
    axios({
      method: 'delete',
      url: configJSON.baseURL + `/account_block/devices/${deviceId}`,
      headers: header
    })
      .then(response => {
        console.log(response);
      })
      .catch(s3error => {
        console.log(s3error);
      });
  };

  saveFireBase = async () => {
    let expTime = localStorage.getItem('expireTokenTime');
    let isTokenValid = moment().isAfter(expTime);
    let fireBaseToken = localStorage.getItem('fireBaseToken');
    if (fireBaseToken == null || expTime == null || isTokenValid) {
      messaging
        .getToken({
          vapidKey:
            'BBYcxl8vVbziq3yXHL45ggKqXgZ2rzTzhslwpquEN0770mTLEQmCSefcxknYbpzLVJnndzGkAdqd3rxy5xYSaEw'
        })
        .then(currentToken => {
          if (currentToken) {
            if (currentToken !== fireBaseToken) {
              localStorage.setItem('fireBaseToken', currentToken);
              if (localStorage.getItem('device_id')) {
                this.deleteFirebaseToken(localStorage.getItem('device_id'));
              }
              this.saveFirebaseToken(currentToken);
            } else {
              localStorage.setItem(
                'expireTokenTime',
                moment().add(30, 'minutes')
              );
            }
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
          } else {
            console.log(
              'No registration token available. Request permission to generate one.'
            );
            // shows on the UI that permission is required
          }
        })
        .catch(err => {
          console.log('An error occurred while retrieving token. ', err);
          // catch error while creating client token
        });
    }
  };
  getThemeData = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}');
    const header = {
      'Content-Type': configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };
    axios({
      method: 'get',
      url:
        configJSON.instituteURL +
        configJSON.ThemeEndPoint +
        `${data.theme_id === undefined ? 21 : data.theme_id}`,
      headers: header
    })
      .then(response => {
        const data = response.data.data.attributes;
        // backGroundLayout.backgroundColor = data.primary_color;
        Object.freeze(backGroundLayout);
        this.setState({
          themeData: response.data,
          mainTheme: true
        });
      })
      .catch(s3error => {
        console.log(s3error);
      });
  };
  saveFirebaseToken = token => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}');
    const header = {
      'Content-Type': configJSON.exampleApiContentType,
      token: localStorage.getItem('token'),
      school: data.school_id
    };
    const body = {
      data: {
        type: 'device',
        attributes: {
          device_id: token
        }
      }
    };
    axios({
      method: 'post',
      url: configJSON.baseURL + configJSON.notificationEndPoint,
      headers: header,
      data: JSON.stringify(body)
    })
      .then(response => {
        localStorage.setItem(
          'expireTokenTime',
          moment(response.data.expire_at)
        );
        localStorage.setItem('device_id', response?.data?.data?.id);
      })
      .catch(s3error => {
        console.log(s3error);
      });
  };
  // Customizable Area End
  render() {
    // var crapyIframeRemover = setInterval(() => {
    //   let iframe = document.querySelector('iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"]')
    //   console.log("finding iframe...")
    //   if (iframe !== null) {
    //     iframe.remove();
    //     console.log("iframe removed...")
    //     clearInterval(crapyIframeRemover);
    //   }
    // }, 500)
    // Crapy iframe remover ENDs
    // console.log('window.env.DEMO_BASE_URL', window.env.DEMO_BASE_URL);

    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');
    // Customizable Area Start
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}');
    const { themeData, mainTheme } = this.state;
    const theme = createMuiTheme({
      props: {
        backgroundColor: themeData.data?.attributes?.background_color,
        mainColor: themeData.data?.attributes?.primary_color,
        buttonColor: themeData.data?.attributes?.button_color,
        iconColor: themeData.data?.attributes?.icons_color,
        titleColor: themeData.data?.attributes?.title_font_color,
        titleFont: themeData.data?.attributes?.title_font,
        subtitleColor: themeData.data?.attributes?.subtitle_font_color,
        subtitleFont: themeData.data?.attributes?.subtitle_font,
        textColor: themeData.data?.attributes?.body_text_color,
        textFont: themeData.data?.attributes?.body_text,
        headingColor: themeData.data?.attributes?.body_heading_color,
        headingFont: themeData.data?.attributes?.body_heading,
        subheadingColor: themeData.data?.attributes?.body_subheading_color,
        subheadingFont: themeData.data?.attributes?.body_subheading,
        mainColor: themeData.data?.attributes?.primary_color,
        backgroundImage: {}
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 767,
          md: 1024,
          lg: 1280,
          xl: 1920
        }
      }
    });

    const defaulttheme = createMuiTheme({
      props: {
        backgroundColor: '#a439cb',
        mainColor: '#a439cb',
        buttonColor: '#a439cb',
        iconColor: '#a439cb',
        titleColor: '#9aa3b2',
        titleFont: 'Open sans',
        subtitleColor: '#9aa3b2',
        subtitleFont: 'Open sans',
        textColor: '#9aa3b2',
        textFont: 'Open sans',
        headingColor: '#9aa3b2',
        headingFont: 'Open sans',
        subheadingColor: '#9aa3b2',
        subheadingFont: 'Open sans',
        backgroundImage: {}
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 767,
          md: 1024,
          lg: 1280,
          xl: 1920
        }
      }
    });

    var bodyStyles = document.body.style;
    if (themeData && themeData?.data?.attributes?.primary_color != undefined) {
      bodyStyles.setProperty(
        '--color-scrollBar',
        themeData.data.attributes.primary_color
      );
    } else {
      bodyStyles.setProperty('--color-scrollBar', 'rgba(56, 44, 124,0.5)');
    }
    // Customizable Area End
    return (
      <MuiThemeProvider
        theme={
          data.theme_id != null || data.theme_id != undefined
            ? theme
            : defaulttheme
        }
      >
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        <ToastContainer
          autoClose={2000}
          position="top-right"
          className="toast-container"
          toastClassName="dark-toast"
        />
      </MuiThemeProvider>
    );
  }
}
export default withRouter(App);
