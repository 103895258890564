// Customizable Area Start
export const ic_calendar = require("../assets/019-calendar.png");
export const ic_computer = require("../assets/030-computer.png");
export const ic_pie_chart = require("../assets/032-pie-chart.png");
export const ic_suitcase = require("../assets/051-suitcase.png");
export const ic_support = require("../assets/063-support.png");
export const ic_book = require("../assets/067-download.png");
export const ic_doc = require("../assets/document.png");
export const ic_messages = require("../assets/messages.png");
export const ic_userIcon = require("../assets/userIcon.png");
export const ic_edit = require("../assets/group-29.png");
export const ic_back = require("../assets/group-39.png");
export const ic_homework = require("../assets/homework-4.png");
export const ic_presentation = require("../assets/presentation.png");
export const ic_zoom = require("../assets/zoom-icon.png");
export const ic_clock = require("../assets/clock-1.png");
export const ic_graduate = require("../assets/graduate-1.png");
export const ic_oval = require("../assets/oval.png");
export const logo = require("../assets/fill-158.png");
export const ic_message = require("../assets/group-2.png");
export const book = require("../assets/group-20.png");
export const knowledge = require("../assets/knowledge.png");
export const note = require("../assets/note.png");
export const menu = require("../assets/menu.png");
export const arrowImg = require("../../../TeacherProfile/assets/group_2021-10-19/group.png");
export const ic_computer_active = require("../assets/active-class.png");
export const menu_disabled = require("../assets/disabledhome.png");
export const image_logo = require("../assets/image-logo.png");
export const spingif = require("../assets/spin.json");
export const calendar = require("../assets/calendar_icon.png");
export const mail = require("../assets/mail_icon.png");
export const chat = require("../assets/chat_icon.png");
export const library = require("../assets/library_icon.png");
export const portfolio = require("../assets/portfolio_icon.png");
export const reports = require("../assets/reports_icon.png");
export const social_media = require("../assets/socialmedia_icon.png");
export const image_assessment_active = require("../assets/image-assessment.png");
export const feedback = require("../assets/feedback.png");
export const image_assessment_white = require("../assets/assessment-white.png")
  .default;

export const admin_class = require("../assets/adminassets/ClassWhite.png")
  .default;
export const active_class = require("../assets/adminassets/ClassYellow.png")
  .default;
export const division = require("../assets/adminassets/DivisionWhite.png")
  .default;
export const active_division = require("../assets/adminassets/DivisionYellow.png")
  .default;
export const badged = require("../assets/adminassets/BadgeaddWhite.png")
  .default;
export const active_badged = require("../assets/adminassets/BadgeaddYellow.png")
  .default;
export const period_2 = require("../assets/adminassets/ClassperiodWhite 2.png")
  .default;
export const active_period_2 = require("../assets/adminassets/ClassperiodYellow 2.png")
  .default;
export const period = require("../assets/adminassets/ClassperiodWhite.png")
  .default;
export const active_period = require("../assets/adminassets/ClassperiodYellow.png")
  .default;
export const gradebook = require("../assets/adminassets/GradebookWhite.png")
  .default;
export const active_gradebook = require("../assets/adminassets/GradebookYellow.png")
  .default;
export const grade = require("../assets/adminassets/GradedetailsWhite.png")
  .default;
export const active_grade = require("../assets/adminassets/GradedetailsYellow.png")
  .default;
export const reminder = require("../assets/adminassets/ReminderquotesWhite.png")
  .default;
export const active_reminder = require("../assets/adminassets/ReminderquotesYellow.png")
  .default;
export const report_card = require("../assets/adminassets/ReportcardWhite.png")
  .default;
export const active_report_card = require("../assets/adminassets/ReportcardYellow.png")
  .default;
export const theme = require("../assets/adminassets/ThemeWhite.png").default;
export const active_theme = require("../assets/adminassets/ThemeYellow.png")
  .default;
export const user = require("../assets/adminassets/UseraccountWhite.png")
  .default;
export const active_user = require("../assets/adminassets/UseraccountYellow.png")
  .default;
export const user2 = require("../assets/adminassets/UseraccountWhite2.png")
  .default;
export const active_user2 = require("../assets/adminassets/Useraccount Yellow2.png")
  .default;
export const ToasterFullLogo = require("../assets/Popup.png");
export const PoweredByFullLogo = require("../assets/Logo (1).png");
export const PoweredByMainLogo = require("../assets/poweredByLogo.png");
// Customizable Area End