// Customizable Area Start
import { HISTORY } from "../../../../components/src/comman";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  assessmentList: any;
  assessmentListMeta: any;
  queryTerm: any;
  page: number;
  rowsPerPage: number;
  showLoader: boolean;
  subjectList: any;
  termsList: any;
  selectedSubjectId: any;
  selectedTerm: any;
  selectedStatus: any;
  filterData: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudentAssessmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAssessmentList: string = "";
  apiGetSubjectList: string = "";
  apiGetTermsList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      assessmentList: [],
      assessmentListMeta: {},
      queryTerm: "",
      page: 0,
      rowsPerPage: 10,
      showLoader: false,
      subjectList: [],
      termsList: [],
      selectedSubjectId: '',
      selectedTerm: '',
      selectedStatus: '',
      filterData: [],
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apiGetAssessmentList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ assessmentList: responseJson?.data, assessmentListMeta: responseJson?.meta, showLoader: false });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiGetSubjectList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ subjectList: responseJson?.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiGetTermsList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ termsList: responseJson?.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getAssessmentList();
    this.getSubjectsList();
    this.getTermsList();
  }


  handleBack = () => {
    HISTORY.goBack();
  };

  getStatusTextColor = (status: string) => {
    switch (status) {
      case "Submitted":
        return "#0ab610"
    }
  }

  onChangeSearchTerm = (event: any) => {
    this.setState({ queryTerm: event.target.value, page: 0 }, () => this.getAssessmentList())
  }

  getAssessmentList = () => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAssessmentList = requestMessage.messageId;

    let apiEndPoint = configJSON.getStudentAssessmentListEndPoint + `?per=${this.state.rowsPerPage}`
    if (this.state.queryTerm) {
      apiEndPoint = apiEndPoint + `&search=${this.state.queryTerm}`;
    }
    else {
      apiEndPoint = apiEndPoint + `&page=${this.state.page + 1}`;
    }

    if (this.state.selectedSubjectId) {
      apiEndPoint = apiEndPoint + `&subject_id=${Number(this.state.selectedSubjectId)}`;
    }

    if (this.state.selectedTerm) {
      apiEndPoint = apiEndPoint + `&term_id=${Number(this.state.selectedTerm)}`;
    }

    if (this.state.selectedStatus) {
      apiEndPoint = apiEndPoint + `&status=${this.state.selectedStatus}`;
    }
    if (this.state.queryTerm != '') {
      this.setState({ showLoader: false })
    }
    else {
      this.setState({ showLoader: true })
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubjectsList = () => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const class_id = school_Data.school_account.data[0].attributes.class.id;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSubjectList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectsEndPoint + `?class_id=${class_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTermsList = () => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const grade_id = school_Data.school_account.data[0].attributes.grade.id;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      // school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetTermsList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentAssessmentTermsEndPoint + `?grade_id=${grade_id}&school_id=${school_Data?.school_id}&per=100`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSubjectChange = (event: any) => {
    const value = event.target.value;
    this.setState({ selectedSubjectId: value, page: 0 }, () => this.getAssessmentList())
  }

  handleTermChange = (event: any) => {
    const value = event.target.value;
    this.setState({ selectedTerm: value, page: 0 }, () => this.getAssessmentList())
  }

  handleStatusChange = (event: any) => {
    const value = event.target.value;
    this.setState({ selectedStatus: value, page: 0 }, () => this.getAssessmentList())
  }

  openAssessmentAnswers = (assessmentId: number, result: any) => {
    if (!this.isStringNullOrBlank(result)) {
      HISTORY.push({
        pathname: "/StudentAssessmentAnswersheet",
        state: { assessmentId: assessmentId },
      });
    }
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
  // Customizable Area End
}
