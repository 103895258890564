// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../../components/src/comman";

export const configJSON = require("../config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showLoader: boolean;
  reportCardList: any;
  reportCardListMeta: any;
  page: number;
  rowsPerPage: number;
  selectedReportCardId: any;
  openDeleteWarningModal: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
export const invalidCharsForNumberType = ["-", "e", "+", "E"];
// Customizable Area End
export default class DraftReportCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDraftReportCards: string = "";
  apiGetEditReportCardData: string = "";
  apiDeleteReportCard: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      reportCardList: [],
      reportCardListMeta: [],
      page: 0,
      rowsPerPage: 10,
      showLoader: false,
      selectedReportCardId: '',
      openDeleteWarningModal: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetDraftReportCards) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ showLoader: false });
            this.setState({
              reportCardList: responseJson?.data,
              reportCardListMeta: responseJson?.meta
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetEditReportCardData) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ showLoader: false });
            HISTORY.push({
              pathname: "/AddCustomReportCard",
              state: {
                reportCardData: responseJson.data,
                isEdit: true,
              },
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiDeleteReportCard) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.getDraftReportCards();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getDraftReportCards();
    // Customizable Area End
  }

  // Handle Back
  handleBack = () => {
    HISTORY.push({
      pathname: 'AdminReportCard'
    });
  };
  // Handle Back End

  // Get draft report cards 
  getDraftReportCards = () => {
    this.setState({
      showLoader: true
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDraftReportCards = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDraftReportCardListEndPoint + `?report_status=as_draft&page=${this.state.page + 1}&per=${this.state.rowsPerPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Get draft report cards end

  getEditReportCardData = () => {
    const { selectedReportCardId } = this.state;
    if (selectedReportCardId === '') {
      return false
    }
    this.setState({
      showLoader: true
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetEditReportCardData = requestMessage.messageId;

    let apiEndPoint = configJSON.apiPublishReportCardEndPoint + `/${selectedReportCardId}`

    this.setState({ showLoader: true })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  deleteReportCard = () => {
    const { selectedReportCardId } = this.state;
    if (selectedReportCardId === '') {
      return false
    }
    this.setState({
      showLoader: true,
      openDeleteWarningModal: false
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteReportCard = requestMessage.messageId;

    let apiEndPoint = configJSON.apiPublishReportCardEndPoint + `/${selectedReportCardId}`

    this.setState({ showLoader: true })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onClickDeleteIcon = (data: any) => {
    this.setState({
      openDeleteWarningModal: true,
      selectedReportCardId: data?.id
    })
  }

  onClickEditIcon = (data: any) => {
    this.setState({
      selectedReportCardId: data.id
    }, () => this.getEditReportCardData())
  }

  onCloseWarningModal = () => {
    this.setState({
      openDeleteWarningModal: false
    })  
  }

  onPageChange= (e: any) => {
    if (e.selected !== this.state.page) {
      this.setState({ page: e.selected }, () => this.getDraftReportCards())
    }
  }

  // Customizable Area End
}
