// Customizable Area Start
import React from "react";
import { Box, Grid, Modal, Button } from "@material-ui/core";
// Customizable Area End
// Customizable Area Start
const cancelText = {
  fontFamily: "Open sans",
  fontSize: "14px",
  fontWeight: 600,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "0.1px",
  textAlign: "center",
  color: "#fff",
} as const;
const deleteText = {
  fontFamily: "Open sans",
  fontSize: "14px",
  fontWeight: 600,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "0.1px",
  textAlign: "center",
} as const;
const cancelButton = {
  margin: "0 20px 0 0",
  padding: "9px 20px 12px 22px",
  borderRadius: "3px",
  background: "linear-gradient(to top, #af38cb 101%, #471c51 33%)",
  color: "white",
  textTransform: "initial",
} as const;
const teacherCancelButton = {
  margin: "0 20px 0 0",
  padding: "9px 20px 12px 22px",
  borderRadius: "3px",
  background: "linear-gradient(262deg, #3a60d7 111%, #253873 -31%)",
  color: "white",
  textTransform: "initial",
} as const;
const deleteButton = {
  margin: "0 0px 0 20px",
  padding: "9px 20px 12px 22px",
  borderRadius: "3px",
  backgroundColor: "#f6f6f6",
  textTransform: "initial",
} as const;
// Customizable Area End
interface Props {
  // Customizable Area Start
  isTeacher: boolean;
  deleteDocumentModal: boolean;
  onCloseModal: any;
  deleteDocumentClick: any;
  // Customizable Area Start
}

export const DeleteDocumentModal = ({
  // Customizable Area Start
  isTeacher,
  deleteDocumentModal,
  onCloseModal,
  deleteDocumentClick,
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  return (
    <>
      <Modal
        open={deleteDocumentModal}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="delete_modal_main_div">
          <div className="delete_title">Delete Document</div>
          <div className="delete_modal_divider"></div>
          <div className="delete_msg_1">
            Are you sure you want to delete the item?
          </div>
          <div className="delete_msg_2">
            This item will be deleted immediately. You cannot undo the action.
          </div>
          <Grid className="DeleteDocumentActions">
            <Button
              variant="contained"
              style={isTeacher ? teacherCancelButton : cancelButton}
              onClick={onCloseModal}
            >
              <span style={cancelText}>Cancel</span>
            </Button>
            <Button
              variant="contained"
              style={deleteButton}
              onClick={deleteDocumentClick}
            >
              <span style={deleteText}>Delete</span>
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
export default DeleteDocumentModal;
// Customizable Area End