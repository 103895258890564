// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
import * as SparkMD5 from "spark-md5";
import { toast } from "react-toastify";
import axios from "axios";
import convert from "xml-js";
const uuidv4 = require("uuid/v4");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  alertMessage: any;
  alertModal: boolean;
  uploadFile: any;
  loadingSpinner: boolean;
  schoolLogo: any;
  topNavigation: any;
  printLogo: any;
  uploadDataType: any;
  schoolLogoError: any;
  topNavigationLogoError: boolean;
  printLogoError: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LogoAndPictureController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPreSignedID: any = [];
  apiBulkUploadID: any = [];
  logoAndPicturesDetailsApiId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      alertMessage: "",
      alertModal: false,
      uploadFile: [],
      loadingSpinner: false,
      schoolLogo: { logo: {} },
      topNavigation: { logo: {}, logoType: 'Square Format', topNavigationAppearance: 'Show School Name' },
      printLogo: { logo: {}, logoAppearance: 'Normal (30% width)' },
      uploadDataType: '',
      schoolLogoError: false,
      topNavigationLogoError: false,
      printLogoError: false,

    };
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  async componentDidMount() { 
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  computeChecksumMd5 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152;
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0;
      fileReader.onerror = function (): void {
        reject("MD5 computation failed - error reading the file");
      };

      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result);
        cursor += chunkSize;

        if (cursor < file.size) {
          processChunk(cursor);
        } else {
          resolve(btoa(spark.end(true)));

        }
      };

      processChunk(0);
    });
  };

  handleUpload = async (event: any, name: any) => {
    let allowUpload: boolean = true;
    const validFileType = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",

    ];
    let files: any;

    files = event.target.files

    let totalSize: number = 0
    const keys2 = Object.keys(files)
    keys2?.map(async (key: any) => {
      const file = files[key]
      totalSize = totalSize + file.size
    })
    keys2?.map((key: any) => {
      const file = files[key];
      if (!validFileType.includes(file?.type)) {
        allowUpload = false;
        this.setState({
          alertMessage:
            "Please upload valid file format: .png .jpg .jpeg .gif",
          alertModal: true,
        });

      }
    });
    if (name === '') {
      if ((files.length + this.state.uploadFile.length) <= 10 && totalSize < 524288000) {
      } else {
        allowUpload = false;
        if (files.length + this.state.uploadFile.length > 10) {
          this.setState({ alertMessage: "Please upload maximum 10 files.", alertModal: true })
        } else {
          this.setState({ alertMessage: "Maximum files size: 500MB", alertModal: true })
        }
      }
    }
    if (allowUpload) {
      let arr: any
      if (name === '') {
        arr = this.state.uploadFile
      }
      const keys = Object.keys(files);
      keys?.map(async (key: any) => {
        let fileUpload: any = {}
        const file: any = files[key];


        fileUpload = {
          id: uuidv4(),
          fileName: file.name,
          name: file.name.split(".")[0],
          isExistingFile: false,
          fileSize: file.size,
          percentage: 0,
          file: file,
          content_type: file.type,
          localUrl: URL.createObjectURL(file)
        };
        if (name === '') {
          arr.push(fileUpload)
          this.setState({ uploadFile: arr })
        } else {
          this.setState({ uploadDataType: name })
          if (name === "schoolLogo") {
            let data: any = this.state.schoolLogo;
            data.logo = fileUpload
            this.setState({ schoolLogo: data, schoolLogoError: false })
          }
          if (name === "topNavigation") {
            let data: any = this.state.topNavigation;
            data.logo = fileUpload
            this.setState({ topNavigation: data, topNavigationLogoError: false })
          }
          if (name === "printLogo") {
            let data: any = this.state.printLogo;
            data.logo = fileUpload
            this.setState({ printLogo: data, printLogoError: false })
          }
        }
        let checksum = "";
        this.getPreSignedId(fileUpload);
      })
    }


  }

  getPreSignedId = async (fileUploadDetail: any) => {
    this.setState({ loadingSpinner: true })
    const { uploadFile } = this.state;
    const header = {
      token: localStorage.getItem("token"),
    };
    let formData = new FormData();
    formData.append('content_type', fileUploadDetail.content_type)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.SignIDUrl
    );

    this.apiPreSignedID[this.apiPreSignedID.length] = requestMessage.messageId;
    if (this.state.uploadDataType === '') {
      uploadFile.map((item: any) => {
        if (item.id === fileUploadDetail.id) {
          item.requestID = requestMessage.messageId;
        }
      });
    }

    this.setState({ uploadFile: uploadFile }, () => {
    });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fileUploadS3
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveImageUsingURL = async (preSignedResponse: any) => {

    const { uploadFile } = this.state;

    let ind: number = 0;
    uploadFile?.map((item: any, index: number) => {
      if (item.requestID == preSignedResponse?.apiRequestCallId) {
        ind = index;
      }
    });
    let httpBody: any;
    if (this.state.uploadDataType === "") {
      httpBody = uploadFile[ind];
    }
    else {
      if (this.state.uploadDataType === "schoolLogo") {

        let data: any = this.state.schoolLogo;
        httpBody = data.logo
      }
      if (this.state.uploadDataType === "topNavigation") {
        let data: any = this.state.topNavigation;
        httpBody = data.logo
      }
      if (this.state.uploadDataType === "printLogo") {
        let data: any = this.state.printLogo;
        httpBody = data.logo
      }
    }
    const uploadURL = preSignedResponse.url.replace(/\/+$/, "");
    const urlFields = preSignedResponse.url_fields;
    if (uploadURL != null && uploadURL !== undefined) {
      const fileKey = urlFields.key.replace("${filename}", httpBody.fileName);
      const formData = new FormData();
      formData.append("key", fileKey);
      formData.append(
        "success_action_status",
        urlFields.success_action_status
      );
      formData.append("Content-Type", urlFields["Content-Type"]);
      formData.append(
        "Content-Disposition",
        urlFields["Content-Disposition"]
      );
      formData.append("policy", urlFields.policy);
      formData.append("x-amz-credential", urlFields["x-amz-credential"]);
      formData.append("x-amz-algorithm", urlFields["x-amz-algorithm"]);
      formData.append("x-amz-date", urlFields["x-amz-date"]);
      formData.append("x-amz-signature", urlFields["x-amz-signature"]);
      formData.append("file", httpBody.file);
      const s3Response = await axios({
        method: "post",
        url: uploadURL,
        data: formData,
      });
      if (s3Response.status === 201) {
        const keyValue = JSON.parse(
          convert.xml2json(s3Response.data, {
            spaces: 1,
            compact: true,
            ignoreComment: true,
            alwaysChildren: true,
            ignoreDeclaration: true,
          })
        );
        if (this.state.uploadDataType === "") {
          const arr: any = this.state.uploadFile
          arr[ind].signedInId = keyValue.PostResponse.Key._text;

          this.setState({ uploadFile: arr });
        }
        else {

          if (this.state.uploadDataType === "schoolLogo") {

            let data: any = this.state.schoolLogo;
            data.logo.signedInId = keyValue.PostResponse.Key._text;
            this.setState({ schoolLogo: data });
          }
          if (this.state.uploadDataType === "topNavigation") {
            let data: any = this.state.topNavigation;
            data.logo.signedInId = keyValue.PostResponse.Key._text;
            this.setState({ topNavigation: data });
          }
          if (this.state.uploadDataType === "printLogo") {
            let data: any = this.state.printLogo;
            data.logo.signedInId = keyValue.PostResponse.Key._text;
            this.setState({ printLogo: data });
          }
        }
      }
    }

    this.setState({ uploadDataType: '' })
  };


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      switch (apiRequestCallId) {
        case this.apiPreSignedID.indexOf(apiRequestCallId) === -1
          ? ""
          : apiRequestCallId:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                let data = {
                  ...responseJson,
                  apiRequestCallId
                }
                const indexOfUploadDoc = this.apiPreSignedID.indexOf(apiRequestCallId)
                let imageData: any;

                const msg: Message = new Message(
                  getName(MessageEnum.UploadMediaMessage)
                );

                if (this.state.uploadDataType === "") {
                  imageData = this.state.uploadFile[indexOfUploadDoc];
                  const updatedBulkDocument = [...this.state.uploadFile];
                  updatedBulkDocument[indexOfUploadDoc].messageId = msg.messageId;
                  updatedBulkDocument[indexOfUploadDoc].progress = 50;
                  this.setState({ uploadFile: updatedBulkDocument })
                }
                else {
                  if (this.state.uploadDataType === "schoolLogo") {

                    let data: any = this.state.schoolLogo;
                    imageData = data.logo
                  }
                  if (this.state.uploadDataType === "topNavigation") {
                    let data: any = this.state.topNavigation;
                    imageData = data.logo
                  }
                  if (this.state.uploadDataType === "printLogo") {
                    let data: any = this.state.printLogo;
                    imageData = data.logo
                  }
                }
                const uploadFileData: any = {
                  responseJson: responseJson,
                  imageData: imageData.file,
                  messageId: msg.messageId
                };
                msg.addData(
                  getName(MessageEnum.UploadMediaDataMessage),
                  uploadFileData
                );
                this.apiBulkUploadID[this.apiBulkUploadID.length] = msg.messageId;
                runEngine.sendMessage(msg.id, msg)
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiBulkUploadID.indexOf(apiRequestCallId) === -1
          ? ""
          : apiRequestCallId:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const keyValue = JSON.parse(
                  convert.xml2json(responseJson, {
                    spaces: 1,
                    compact: true,
                    ignoreComment: true,
                    alwaysChildren: true,
                    ignoreDeclaration: true,
                  })
                );
                if (this.state.uploadDataType === "") {
                  const arr: any = this.state.uploadFile
                  const updatedDocIndex = this.state.uploadFile.findIndex((item: any) => item.messageId === apiRequestCallId)
                  arr[updatedDocIndex].signedInId = keyValue.PostResponse.Key._text;
                  this.setState({ uploadFile: arr });
                  const uploadIndex = this.apiBulkUploadID?.findIndex((item: any) => item === apiRequestCallId)
                  this.apiBulkUploadID.splice(uploadIndex, 1)
                }
                else {
                  if (this.state.uploadDataType === "schoolLogo") {
                    let data: any = this.state.schoolLogo;
                    data.logo.signedInId = keyValue.PostResponse.Key._text;
                    this.setState({ schoolLogo: data });
                  }
                  if (this.state.uploadDataType === "topNavigation") {
                    let data: any = this.state.topNavigation;
                    data.logo.signedInId = keyValue.PostResponse.Key._text;
                    this.setState({ topNavigation: data });
                  }
                  if (this.state.uploadDataType === "printLogo") {
                    let data: any = this.state.printLogo;
                    data.logo.signedInId = keyValue.PostResponse.Key._text;
                    this.setState({ printLogo: data });
                  }
                  this.apiBulkUploadID = []
                }
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.apiPreSignedID = []
            if (!this.apiBulkUploadID?.length) this.setState({ uploadDataType: '', loadingSpinner: false })
          }
          break;
        case this.logoAndPicturesDetailsApiId: {
          this.setState({ loadingSpinner: false })
          if (responseJson != null) {
            if (!responseJson.errors) {
              localStorage.setItem("schoolId", responseJson.data.id)
              this.props.navigation.navigate("GradesAndLevels")
              toast.success("Logo and Pictures submitted successfully.")
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
          break;
      }


    }

  }

  goNext = (event: any) => {
    const { schoolLogo, topNavigation, printLogo, uploadFile } = this.state
    const arr: any = [];
    this.state.uploadFile.map(async (key: any, index: any) => {
      arr.push(this.state.uploadFile[index].signedInId)
    })
    event.preventDefault();
    if (!(schoolLogo.logo && schoolLogo.logo.signedInId) || !(topNavigation.logo && topNavigation.logo.signedInId) || !(printLogo.logo && printLogo.logo.signedInId)) {
      if (!(schoolLogo.logo && schoolLogo.logo.signedInId)) {
        this.setState({ schoolLogoError: true })

      }
      if (!(topNavigation.logo && topNavigation.logo.signedInId)) {
        this.setState({ topNavigationLogoError: true })

      }
      if (!(printLogo.logo && printLogo.logo.signedInId)) {
        this.setState({ printLogoError: true })
      }
      return false
    }
    const data = {
      "school_id": localStorage.getItem("schoolId"),
      "school_logo": {
        "attachments": [schoolLogo.logo.signedInId]
      },

      "top_navigation": {
        "attachments": [topNavigation.logo.signedInId],
        "logo_type": topNavigation.logoType,
        "top_navigation_apperance": topNavigation.topNavigationAppearance

      },
      "print_logo": {
        "attachments": [printLogo.logo.signedInId],
        "print_logo_apperance": printLogo.logoAppearance
      },

      "pictures": {
        "attachments": arr
      }
    }
    this.setState({ loadingSpinner: true })
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.logoAndPicturesDetailsApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoAndpictureEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}
