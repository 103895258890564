  // Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
import { accountTypes } from "../utils/constants";
import { toast } from "react-toastify";
export const configJSON = require("./config.js");

import { v4 as uuidv4 } from "uuid";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  userId: any;
  data: any;
  pagination_page: number;
  totalPages: number;
  questionData: any;
  timeLineMeta: any;
  showLoader: boolean;
  chapterList: Array<any>;
  topicList: Array<any>;
  questionTypes: Array<any>;
  questionDifficulty: Array<any>;
  selectedQuestion: any;
  selectedQuestionList: any;
  selectedQuestionIdsFromHistory: Array<any>;
  isFirstTime: boolean;
  openDeleteQuestionModal: boolean;
  selectedQuestionType: any;
  selectedChapterId: any;
  selectedTeacherId: any;
  selectedTopic: any;
  selectedTopicId: any;
  selectedDifficulty: any;
  currentSubject: any;
  currentClass: any;
  PreviousSelectedQuestion: boolean;
  PreviousQuestion: boolean;
  openDataLossAlertPopup: boolean;
  currentAssessmentId: any;
  selectedQuestionMetadata: Array<any>;
  selectedQuestionAnswer: Array<any>;
  teacherList: Array<any>;
  questionsTypeList: Array<any>;
  selectedQuestionTypeId: any;
  selectedDifficultyType: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class QuestionBankController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuestionListId: string = "";
  postAssessmentDetails: string = "";
  getQuestionsMetadataID: string = "";
  getQuestionAnswersID: string = "";
  getTeacherListId: string = "";
  apiGetQuestionsTypeList: string = "";
  getChapterListId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      PreviousSelectedQuestion: false,
      PreviousQuestion: false,
      userId: "",
      data: "",
      pagination_page: 0,
      totalPages: 0,
      questionData: [],
      timeLineMeta: [],
      showLoader: false,
      chapterList: [],
      topicList: [],
      questionTypes: [],
      questionDifficulty: [],
      selectedQuestionIdsFromHistory: HISTORY.location?.state
        ?.selectedQuestionIds?.length
        ? [...HISTORY.location?.state?.selectedQuestionIds]
        : [],
      isFirstTime: true,
      selectedQuestionType: null,
      selectedChapterId: "",
      selectedTeacherId: "",
      selectedTopic: null,
      selectedTopicId: "",
      selectedDifficulty: null,
      selectedQuestion: null,
      selectedQuestionList: this.getSelectedQuestionList(),
      openDeleteQuestionModal: false,
      currentSubject: null,
      currentClass: null,
      openDataLossAlertPopup: false,
      currentAssessmentId: "",
      selectedQuestionMetadata: [],
      selectedQuestionAnswer: [],
      teacherList: [],
      questionsTypeList: [],
      selectedQuestionTypeId: "",
      selectedDifficultyType: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.userdata = this.userdata.bind(this);
    this.get_question_list = this.get_question_list.bind(this);
    this.get_selected_questions_metadata = this.get_selected_questions_metadata.bind(
      this
    );
    this.get_selected_question_answer = this.get_selected_question_answer.bind(
      this
    );
    // Customizable Area End
  }

  // Customizable Area Start
  getSelectedQuestionList = () => {
    if (HISTORY.location.state?.selectedQuestionIds?.length) {
      let ids = [...HISTORY.location.state.selectedQuestionIds];

      let previewData: any[] = [];
      if (localStorage.getItem("preview_data") !== null) {
        const preview_data = localStorage.getItem("preview_data");
        const newPreviewData = JSON.parse(preview_data || "{}");
        previewData = [...newPreviewData];
      }
      return previewData;
    }
  };

  async componentDidMount() {
    super.componentDidMount();
    this.userdata();
    const userData = localStorage.getItem("user_data");
    const parsedUserData = JSON.parse(userData || "{}");
    const isTeacherAccount = parsedUserData?.type === accountTypes.TEACHER;
    if (HISTORY.location.state && isTeacherAccount) {
      const subjectId = HISTORY.location.state.subject?.id
        ? HISTORY.location.state.subject?.id
        : HISTORY.location.state.subject?.subject_id;

      const schoolClassId = HISTORY.location.state.schoolClassId
        ? HISTORY.location.state.schoolClassId
        : HISTORY.location.state.currentClass?.id;
      this.setState({
        currentSubject: HISTORY.location.state.subject,
        currentClass: {
          ...HISTORY.location.state.currentClass,
          school_class_id: schoolClassId,
        },
      });
      if (HISTORY.location.state.assessmentId) {
        this.setState({
          currentAssessmentId: HISTORY.location.state.assessmentId,
        });
      }
      this.get_question_list(subjectId, schoolClassId);
      this.get_teachers_list(subjectId, schoolClassId);
      this.getQuestionsTypeList();
      this.getChaptersList(subjectId);

    } else {
      HISTORY.push("/AssessmentQuestionBankLibrary");
    }
  }

  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const id = localStorage.getItem("id");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data, userId: id });
  };

  filterTeacherOnChange = (event: any) => {
    this.setState({ selectedTeacherId: event.target.value });
  };

  filterQuestionTypeOnChange = (event: any) => {
    this.setState({ selectedQuestionTypeId: event.target.value });
  };

  filterDifficultyTypeOnChange = (event: any) => {
    this.setState({ selectedDifficultyType: event.target.value });
  };

  filterChapterOnChange = (event: any) => {
    this.setState({ selectedChapterId: event.target.value });
  };

  handleToggleQuestionSelect = (question: any) => {
    const currentIndex = this.state.selectedQuestionList.findIndex(
      (item: any) => item.id === question.id
    );
    const newList = [...this.state.selectedQuestionList];

    if (currentIndex === -1) {
      newList.push(question);
    } else {
      newList.splice(currentIndex, 1);
    }

    this.setState({ selectedQuestionList: newList });
  };

  onSelectQuestionType = (questionTypeData: any) => {
    this.setState({ selectedQuestionType: questionTypeData });
  };

  handleOpenSelectedQuestionPreview = () => {
    this.setState({
      PreviousQuestion: true,
    });
  };

  handleLoadMore = () => {
    this.get_question_list();
  };

  handleCreateAssessment = () => {
    if (this.state.selectedQuestionList?.length) {
      if (localStorage.getItem("assessmentData") !== null) {
        const assessmentData = JSON.parse(localStorage.getItem("assessmentData") || "{}");
        if(assessmentData.import_from_library){
          this.importFromLibrarySaveAssessment();
        }else{
          this.saveAssessmentAndContine();
        }
      }else{
        this.saveAssessmentAndContine();
      }
     
      localStorage.setItem(
        "preview_data",
        JSON.stringify(this.state.selectedQuestionList)
      );
    }
  };

  handleOpenDataLossAlertPopup = () => {
    this.setState({ openDataLossAlertPopup: true });
  };

  handleCloseDataLossAlertPopup = () => {
    this.setState({ openDataLossAlertPopup: false });
  };
  handleCloseDataLossAlertPopupAndRedirect = () => {
    this.setState({ openDataLossAlertPopup: false, selectedQuestionList: [] });
    // clear local storage
    localStorage.removeItem("assessmentData")
    // clear local storage END
    this.props.navigation.navigate("AssessmentQuestionBankLibrary");
  };

  handleBackClick = () => {
    if (this.state.selectedQuestionList?.length) {
      this.handleOpenDataLossAlertPopup();
    } else {
      this.props.navigation.navigate("AssessmentQuestionBankLibrary");
    }
  };

  validateForm = () => {
    let isValid = true;
    let {
      selectedQuestionIdsFromHistory,
      selectedQuestionList,
    } = this.state

    const data = JSON.parse(localStorage.getItem("assessmentData") || "{}");

    let total_marks = 0;
    let new_assessment_questions_data: any = selectedQuestionList.reduce((result: any, data: any) => {
      if (!selectedQuestionIdsFromHistory.includes(Number(data.id))) {
        total_marks += data.marks;
        result.push({
          id: data.id,
          title: data.title,
          type: data?.question_type,
          marks: data.marks,
          negative_marks: data.negative_marks
        })
      }
      return result;
    }, [])

    data.assessment_sections_attributes.map((item:any, index:any)=>{
      if(item?.id ==  HISTORY.location?.state?.sectiondata?.id){
        // section marks validation
        const sectionTotalMarks = item?.question_data ? (item?.question_data?.reduce((sum: any, data: any) => (sum + data.marks), 0)) : 0;
        let total_of_all_marks = sectionTotalMarks + total_marks;
        if (total_of_all_marks > Number(item.section_marks)) {
          toast.error(`Section marks of ${item.name} section should not be greater than ${item.section_marks} marks.`)
          isValid = false;
        }
        // section marks validation END

        // section Questions validation START
        let sectionTotalQuestions = item?.question_data ? item?.question_data?.length : 0;
        let new_total_questions = sectionTotalQuestions + new_assessment_questions_data.length;

        if(new_total_questions > Number(item.total_questions)){
          toast.error(`Total Question of ${item.name} should not be More than ${item.total_questions} Questions.`)
          isValid = false;
        }
        // section Questions validation END
      }
    });

    return isValid 
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getQuestionListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            let currentLoadedQuestionsIds = responseJson.data.map(
              (_item: any) => _item.id
            );
            let listOfQuestions = [...this.state.questionData];
            let currentLoadedQuestions: any = [];
            let selectedQuestions: any[] = this.state.selectedQuestionList
              ?.length
              ? this.state.selectedQuestionList
              : [];
            let paginationPage = 0;
            if (responseJson?.data?.length) {
              listOfQuestions = [...listOfQuestions, ...responseJson.data];
              currentLoadedQuestions = [...responseJson.data];
            }

            if (responseJson?.meta?.pagination?.next_page) {
              paginationPage = responseJson.meta.pagination.next_page;
            }
            const alreadySelectedQuestions = [
              ...this.state.selectedQuestionIdsFromHistory,
            ];

            selectedQuestions = [
              ...selectedQuestions.filter(
                (item: any) =>
                  !currentLoadedQuestionsIds.some(
                    (id: any) => parseInt(id) == parseInt(item.id)
                  )
              ),
              ...currentLoadedQuestions.filter((item: any) =>
                alreadySelectedQuestions.some(
                  (id: any) => parseInt(id) === parseInt(item.id)
                )
              ),
            ];
            let filterArray: any = [];
            if (localStorage.getItem("preview_data") !== null) {
              const alreadySelectedQuestions = [
                ...this.state.selectedQuestionIdsFromHistory,
              ];
              const preview_data = localStorage.getItem("preview_data");
              const previewData = JSON.parse(preview_data || "{}");
              filterArray = previewData.filter((_data: any) =>
                alreadySelectedQuestions.includes(_data.id)
              );
            }
            this.setState({
              questionData: listOfQuestions,
              selectedQuestionList: selectedQuestions,
              timeLineMeta: responseJson?.meta?.pagination,
              pagination_page: paginationPage,
              totalPages: responseJson?.meta?.pagination?.total_pages,
              showLoader: false,
              isFirstTime: false,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.postAssessmentDetails) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            if (responseJson?.data?.id) {
              this.setState({ showLoader: false });
              HISTORY.push({
                pathname: "/TeacherAssessmentCreateDetail",
                state: { assessmentId: responseJson?.data?.id },
              });
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getQuestionsMetadataID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            if (responseJson?.data?.length) {
              const questionMetaData = responseJson.data.map((item: any) => {
                const questionTitle = this.state.selectedQuestionList?.find(
                  (question: any) =>
                    parseInt(question.id) ===
                    parseInt(item.meta_data.question_id)
                );
                return { ...item, title: questionTitle.title };
              });
              this.setState(
                { selectedQuestionMetadata: questionMetaData },
                () => {
                  this.setState({
                    PreviousSelectedQuestion: true,
                  });
                }
              );
            } else {
              this.setState({ selectedQuestionMetadata: [] });
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getQuestionAnswersID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            if (responseJson?.ques_ans_data?.length) {
              this.setState(
                { selectedQuestionAnswer: responseJson.ques_ans_data },
                () => {
                  this.handleOpenSelectedQuestionPreview();
                }
              );
            } else {
              this.setState({ selectedQuestionAnswer: [] });
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getTeacherListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            if (responseJson?.accounts_data?.length) {
              this.setState(
                { teacherList: responseJson?.accounts_data }
              );
            } else {
              this.setState({ teacherList: [] });
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiGetQuestionsTypeList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ questionsTypeList: responseJson?.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getChapterListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              chapterList: responseJson?.data
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  get_question_list = (subjectId?: number, classId?: number, page?: number) => {
    const {selectedTeacherId, selectedChapterId, selectedDifficultyType, selectedQuestionTypeId} = this.state;
    this.setState({ showLoader: true });
    const user_data = localStorage.getItem("user_data");
    const userData = JSON.parse(user_data || "{}");
    const pageNo = this.state.pagination_page ? this.state.pagination_page : 1;
    const subjectid = subjectId
      ? subjectId
      : this.state.currentSubject?.id
        ? this.state.currentSubject?.id
        : this.state.currentSubject?.subject_id;
    const classid = classId ? classId : this.state.currentClass?.id;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.questionLibraryEndPoint +
      `?school_id=${userData.school_id}&subject_id=${subjectid}&grade_id=${classid}&page=${pageNo}&per=30&teacher_id=${selectedTeacherId}&chapter_id=${selectedChapterId}&difficulty_level=${selectedDifficultyType}&question_sub_category_id=${selectedQuestionTypeId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_teachers_list = (subjectId?: number, classId?: number) => {
    const user_data = localStorage.getItem("user_data");
    const userData = JSON.parse(user_data || "{}");

    const subjectid = subjectId
      ? subjectId
      : this.state.currentSubject?.id
        ? this.state.currentSubject?.id
        : this.state.currentSubject?.subject_id;
    const classid = classId ? classId : this.state.currentClass?.id;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: userData.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTeacherListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeachersListEndPoint + `?subject_id=${subjectid}&grade_id=${classid}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getQuestionsTypeList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetQuestionsTypeList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuestionsTypeListEndPoint

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getChaptersList = (subjectId?: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getChapterListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chapterEndPoint + `?subject_id=${subjectId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_selected_questions_metadata = () => {
    this.setState({ showLoader: true });
    const user_data = localStorage.getItem("user_data");
    const userData = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: userData.school_id,
    };
    const question_ids: any[] = this.state.selectedQuestionList?.map(
      (item: any) => item.id
    );
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionsMetadataID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.questionMetadataEndPoint +
      `?question_ids=${JSON.stringify(question_ids)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  applyFilter = () => {
    this.setState({
      questionData: [],
      pagination_page: 0
    }, ()=>this.get_question_list())
  }

  clearAll = () => {
    this.setState({
      selectedTeacherId: "",
      selectedChapterId: "",
      selectedQuestionTypeId: "",
      selectedDifficultyType: "",
      questionData: [],
      pagination_page: 0
    }, ()=>this.get_question_list())
  }

  get_selected_question_answer = async (questionId?: number) => {
    this.state.questionData?.map((data: any)=>{
      if(data?.id == questionId){
        if(data?.question_type == "short answer" || data.question_type == "long answer"){
          this.setState(
            { selectedQuestionAnswer: [] },
            () => {
              this.handleOpenSelectedQuestionPreview();
            }
          );
        }else{
          this.setState({ showLoader: true });
          const user_data = localStorage.getItem("user_data");
          const userData = JSON.parse(user_data || "{}");
          const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("token"),
            school: userData.school_id,
          };
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.getQuestionAnswersID = requestMessage.messageId;
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.questionAnswerEndPoint + `/${questionId}/get_question_preview`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
        }
      }
    })
  };

  createAssessment = () => {
    this.setState({ showLoader: true });
    const user_data = localStorage.getItem("user_data");
    const userData = JSON.parse(user_data || "{}");
    const subjectid = this.state.currentSubject?.subject_id;
    const classid = this.state.currentClass?.id;
    const selectedQuestionIds = this.state.selectedQuestionList.map(
      (question: any) => {
        return parseInt(question.id);
      }
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postAssessmentDetails = requestMessage.messageId;

    const httpBody: any = {
      question_ids: selectedQuestionIds,
      subject_id: subjectid,
      grade_id: Number(classid),
    };
    if (this.state.currentAssessmentId)
      httpBody.assessment_id = this.state.currentAssessmentId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiBuildAssessmentEndPoint + `?school_id=${userData.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveAssessmentAndContine = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const subjectid = this.state.currentSubject?.subject_id;
    const classid = this.state.currentClass?.id;


    let total_marks = 0;
    let new_assessment_questions_data: any = this.state.selectedQuestionList.reduce((result: any, data: any) => {
      if (!this.state.selectedQuestionIdsFromHistory.includes(Number(data.id))) {
        total_marks += data.marks;
        result.push({
          id: data.id,
          title: data.title,
          type: data?.question_type,
          marks: data.marks,
          negative_marks: data.negative_marks
        })
      }
      return result;
    }, [])



    if (localStorage.getItem("assessmentData") !== null) {

      // remove questions from storage if some history questions is deselected
      const questionIdSelected = this.state.selectedQuestionList?.map((data: any) => data.id);
      let removedIds = this.state.selectedQuestionIdsFromHistory.reduce((result: any, id: any) => {
        if (!questionIdSelected.includes(Number(id))) {
          result.push(Number(id));
        }
        return result
      }, [])

      const preAssessmentData = JSON.parse(localStorage.getItem("assessmentData") || "{}");
      preAssessmentData.assessment_sections_attributes?.map((item: any) => {
        if (item?.question_data) {
          item.question_data = item?.question_data.filter((question: any) => (!removedIds.includes(Number(question.id))))
        }
      })
      localStorage.setItem("assessmentData", JSON.stringify(preAssessmentData))
      // remove questions from storage END

      
      // managing question section wise
      const data = JSON.parse(localStorage.getItem("assessmentData") || "{}");
      let sectionZeroTotalMarks = data.assessment_sections_attributes?.[0]?.question_data?.reduce((result: any, data: any) => {
        result += data.marks
        return result;
      }, 0);
      let sectionZeroTotalQuestions = data.assessment_sections_attributes?.[0]?.question_data?.length;
      let finalSectionZeroQuestionData = [...data.assessment_sections_attributes?.[0]?.question_data, ...new_assessment_questions_data];
      data.assessment_sections_attributes[0].section_marks = sectionZeroTotalMarks + total_marks;
      data.assessment_sections_attributes[0].total_questions = sectionZeroTotalQuestions + new_assessment_questions_data.length;
      data.assessment_sections_attributes[0].question_data = finalSectionZeroQuestionData;
      data.assessment_sections_attributes[0].remaining_marks = 0;
      // managing question section wise END
      
      localStorage.setItem("assessmentData", JSON.stringify(data))
    } else {

      let section_data: any = {
        id: uuidv4(),
        name: "Section 1",
        section_marks: total_marks,
        total_questions: new_assessment_questions_data.length,
        time_limit: '',
        question_data: new_assessment_questions_data
      }
      const data: any = {
        school_id: school_Data?.school_id,
        assessment_name: '',
        grade_id: Number(classid),
        grade: '',
        subject_id: Number(subjectid),
        subject: '',
        exam_type: '',
        maximum_marks: '',
        term_id: '',
        term: '',
        negative_marking: false,
        time_limit: '00:00',
        difficulty_level: 'easy',
        assessment_sections_attributes: [section_data],
        created_through: 'library'
      };

      localStorage.setItem("assessmentData", JSON.stringify(data))
    }
    HISTORY.push({
      pathname: "/TeacherAssessmentCreateDetail",
    });
  }

  importFromLibrarySaveAssessment = () => {
    let total_marks = 0;
    let new_assessment_questions_data: any = this.state.selectedQuestionList.reduce((result: any, data: any) => {
      if (!this.state.selectedQuestionIdsFromHistory.includes(Number(data.id))) {
        total_marks += data.marks;
        result.push({
          id: data.id,
          title: data.title,
          type: data?.question_type,
          marks: data.marks,
          negative_marks: data.negative_marks
        })
      }
      return result;
    }, [])

    if (localStorage.getItem("assessmentData") !== null) {
      // remove questions from storage if some history questions is deselected
      const questionIdSelected = this.state.selectedQuestionList?.map((data: any) => data.id);
      let removedIds = this.state.selectedQuestionIdsFromHistory.reduce((result: any, id: any) => {
        if (!questionIdSelected.includes(Number(id))) {
          result.push(Number(id));
        }
        return result
      }, [])

      const preAssessmentData = JSON.parse(localStorage.getItem("assessmentData") || "{}");
      preAssessmentData.assessment_sections_attributes.map((item: any) => {
        if (item?.question_data) {
          item.question_data = item?.question_data.filter((question: any) => (!removedIds.includes(Number(question.id))))
        }
      })
      localStorage.setItem("assessmentData", JSON.stringify(preAssessmentData))
      // remove questions from storage END

      // manage validation 
      if(this.validateForm()){
        // managing question section wise
        const data = JSON.parse(localStorage.getItem("assessmentData") || "{}");
  
        data.assessment_sections_attributes.map((selectedSection:any, index:any)=>{
          if(selectedSection?.id ==  HISTORY.location?.state?.sectiondata?.id){
            let finalSectionZeroQuestionData = [...(selectedSection?.question_data ? selectedSection?.question_data : []  ), ...new_assessment_questions_data];
            selectedSection.question_data = finalSectionZeroQuestionData;
            const totalQuestionMarks = selectedSection?.question_data.reduce((sum: any, current: any) => (sum + current.marks), 0);
            selectedSection.remaining_marks = selectedSection.section_marks - totalQuestionMarks;
          }
        })
        // managing question section wise END
        
        data.import_from_library = false;

        localStorage.setItem("assessmentData", JSON.stringify(data))
        HISTORY.push({
          pathname: "/TeacherAssessmentCreateSection",
        });
      }
      // manage validation end 
    }
  }

  handleOnClosePreviousSelectedQuestion = () => {
    this.setState({ PreviousSelectedQuestion: false });
  }

  handleOnClosePreviousQuestion = () => {
    this.setState({ PreviousQuestion: false });
  }
  // Customizable Area End
}
