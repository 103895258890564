// Customizable Area Start
import React from "react"
import {
    Button,
    withStyles,
    Grid,
    TextField,
    Select,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Divider,
    Hidden
} from "@material-ui/core";
import CategoryDetailsController, { Props } from "./CategoryDetailsController.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const form_input_text_field = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
} as const;
// Customizable Area End

export class CategoryDetails extends CategoryDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { assessmentsList, tasksList, categoryDetailsTabList, selectedCategoryIndex, selectedCategoryTab,
            assessmentWeightage, taskWeightage, typesOfMarkingList } = this.state;
        return (
            <>
                {this.state.showLoader && (
                    <Spinner spinnerModal={this.state.showLoader} />
                )}

                <div className={`enter-grading-scale-text ${this.props.classes.heading_font}`}>
                    Enter Final Evaluation Details
                </div>

                <div className={`category-sub-field-text ${this.props.classes.heading_font}`}>
                    Assessment
                </div>

                <Grid container className="flex-column">
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <div className="category-textfield-div">
                            <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                Enter Weightage for Assessments (%)
                            </div>
                            <TextField
                                id="standard-basic"
                                type="number"
                                placeholder="Eg- 80, 70"
                                style={form_input_text_field}
                                variant="outlined"
                                value={assessmentWeightage}
                                onChange={(e: any) => this.onChangeAssessmentWeightage(e)}
                                onKeyPress={(e: any) => this.onKeyPressNumberField(e)}
                                inputProps={{ className: `term-text-field` }}
                                data-test-id="assessment-weightage-text-field"
                            />
                        </div>
                    </Grid>

                    <div>
                        {assessmentsList?.map((assessmentItem: any, index: number) => (
                            <React.Fragment key={assessmentItem?.id}>
                                <Grid container className="category-textfield-div category-details-spacing" spacing={4}>
                                    <Grid item xs={12} sm={4} md={4} lg={4} className="flex-column">
                                        <div>
                                            <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                                Assessment Name
                                            </div>
                                            <TextField
                                                id="standard-basic"
                                                placeholder="Assessment name"
                                                style={form_input_text_field}
                                                variant="outlined"
                                                value={assessmentItem?.assessmentName}
                                                onChange={(e: any) => this.onChangeAssessmentName(e, index)}
                                                inputProps={{ className: `term-text-field` }}
                                                data-test-id={`assessment-name-${index}`}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} lg={4} className="flex-column">
                                        <div>
                                            <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                                Select type of marking
                                            </div>
                                            <Select
                                                className={`${this.props.classes.Modules_Feedback_selectDropDown} ${this.props.classes.bodyText_font} multi-dropdown-report`}
                                                value={
                                                    assessmentItem?.typeOfMarkingId
                                                        ? assessmentItem?.typeOfMarkingId
                                                        : ""
                                                }
                                                IconComponent={ArrowDropDownRoundedIcon}
                                                onChange={(e: React.ChangeEvent<any>) => this.onChangeMarkingAssessmentType(e, index)}
                                                variant="outlined"
                                                inputProps={{
                                                    id: "select-multiple-native",
                                                    className: assessmentItem?.typeOfMarkingId ? "CategoryErrorInputProps" : "CategoryInputProps",
                                                }}
                                                displayEmpty
                                                MenuProps={{ getContentAnchorEl: null }}
                                                renderValue={() => {
                                                    return assessmentItem?.typeOfMarkingId ? assessmentItem?.typeOfMarking?.name : "Select type of marking"
                                                }}
                                                data-test-id={`select-marking-type-assessment-${index}`}
                                            >
                                                {typesOfMarkingList?.map((item: any) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        <ListItemText primary={item.name} />
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                checked={
                                                                    assessmentItem?.typeOfMarkingId === item.id
                                                                }
                                                                className={this.props.classes.TermDetails_Feedback_term_checkbox}
                                                                style={{
                                                                    color:
                                                                        assessmentItem?.typeOfMarkingId === item.id
                                                                            ? this.props.classes.TermDetails_Feedback_checkbox_color
                                                                            : "#3f526d",
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </div>
                                    </Grid>

                                </Grid>
                                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                    <Divider className="term-divider" />
                                </Hidden>
                            </React.Fragment>
                        ))}
                    </div>

                    <Button
                        className={`${this.props.classes.GradingScale_Feedback_btn_border} add-assessment-btn`}
                        onClick={this.addAssessmentClick}
                    >
                        + Add Assessment
                    </Button>
                </Grid>

                <Divider className="category-divider" />

                <div className={`category-sub-field-text ${this.props.classes.heading_font}`}>
                    Task
                </div>

                <Grid container className="flex-column">
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <div className="category-textfield-div">
                            <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                Enter Weightage for Task (%)
                            </div>
                            <TextField
                                id="standard-basic"
                                type="number"
                                placeholder="Eg- 80, 70"
                                style={form_input_text_field}
                                variant="outlined"
                                value={taskWeightage}
                                onChange={(e: any) => this.onChangeTaskWeightage(e)}
                                onKeyPress={(e: any) => this.onKeyPressNumberField(e)}
                                inputProps={{ className: `term-text-field` }}
                                data-test-id="task-weightage-text-field"
                            />
                        </div>
                    </Grid>

                    <div>
                        {tasksList?.map((taskItem: any, taskIndex: number) => (
                            <React.Fragment key={taskItem?.id}>
                                <Grid container className="category-textfield-div category-details-spacing" spacing={4}>
                                    <Grid item xs={12} sm={4} md={4} lg={4} className="flex-column">
                                        <div>
                                            <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                                Task Name
                                            </div>
                                            <TextField
                                                id="standard-basic"
                                                placeholder="Task name"
                                                style={form_input_text_field}
                                                variant="outlined"
                                                value={taskItem?.taskName}
                                                onChange={(e: any) => this.onChangeTaskName(e, taskIndex)}
                                                inputProps={{ className: `term-text-field` }}
                                                data-test-id={`task-name-${taskIndex}`}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} lg={4} className="flex-column">
                                        <div>
                                            <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                                Select type of marking
                                            </div>
                                            <Select
                                                className={`${this.props.classes.Modules_Feedback_selectDropDown} ${this.props.classes.bodyText_font} multi-dropdown-report`}
                                                value={
                                                    taskItem?.typeOfMarkingId
                                                        ? taskItem?.typeOfMarkingId
                                                        : ""
                                                }
                                                IconComponent={ArrowDropDownRoundedIcon}
                                                onChange={(e: React.ChangeEvent<any>) => this.onChangeMarkingTaskType(e, taskIndex)}
                                                variant="outlined"
                                                inputProps={{
                                                    id: "select-multiple-native",
                                                    className: taskItem?.typeOfMarkingId ? "CategoryErrorInputProps" : "CategoryInputProps",
                                                }}
                                                displayEmpty
                                                MenuProps={{ getContentAnchorEl: null }}
                                                renderValue={() => {
                                                    return taskItem?.typeOfMarkingId ? taskItem?.typeOfMarking?.name : "Select type of marking"
                                                }}
                                                data-test-id={`select-marking-type-task-${taskIndex}`}
                                            >
                                                {typesOfMarkingList.map((item: any) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        <ListItemText primary={item.name} />
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                checked={
                                                                    taskItem?.typeOfMarkingId === item.id
                                                                }
                                                                className={this.props.classes.TermDetails_Feedback_term_checkbox}
                                                                style={{
                                                                    color:
                                                                        taskItem?.typeOfMarkingId === item.id
                                                                            ? this.props.classes.TermDetails_Feedback_checkbox_color
                                                                            : "#3f526d",
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </div>
                                    </Grid>                                  
                                </Grid>
                                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                    <Divider className="term-divider" />
                                </Hidden>
                            </React.Fragment>
                        ))}
                    </div>

                    <Button
                        className={`${this.props.classes.GradingScale_Feedback_btn_border} add-assessment-btn`}
                        onClick={this.addTaskClick}
                    >
                        + Add Task
                    </Button>
                </Grid>

                <div className="grade-next-btn-div">
                    <Button
                        className={`${this.props.classes.button_color} done-btn mb-20`}
                        disabled={!(assessmentsList?.length > 0 || tasksList?.length > 0)}
                        onClick={this.saveCategoryDetails}>
                        Done
                    </Button>
                </div>

            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(CategoryDetails as React.ComponentType<any>);
// Customizable Area End
