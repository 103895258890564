// Customizable Area Start
import React, { useEffect, useState } from "react";
import { deleteDivision, editDivision } from "./assets";
import ReactPaginate from "react-paginate";
import SideBar from "../../shared/SideBar/src/SideBar";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  Link,
  Select,
  TextField,
  Typography,
  Menu,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  FormLabel,
  Tabs,
} from "@material-ui/core";
import { DeleteForever } from '@material-ui/icons';
import AdminGradeViewController, { Props } from "./AdminGradeViewController";
import Spinner from "../../shared/SideBar/src/Spinner";

import { withStyles } from "@material-ui/core/styles";
import { GradeData } from "./dummy/mock";
import RemoveModal from "./RemoveModal.web"
import CloseIcon from '@material-ui/icons/Close';
import HOCAuth from "../../shared/Header/src/HOCAuth.web";

// Customizable Area End
// Customizable Area Start
const avtarIcon = {
  width: "31px",
  height: "31px",
  margin: "20px 23px 20px 19px",
} as const;
const forrmInput_textfield = {
  width: "100%",
  borderRadius: "6px",
  textAlign: "start",
  color: "#3f526d",
  fontSize: "1.125rem",
  fontFamily: "Open sans",
} as const;

const BootstrapInput2 = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    backgroundColor: theme.palette.background.paper,
    padding: "10px 26px 10px 12px",
    width: "108px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: "Open sans",
    color: "#3f526d",
    fontSize: 18,
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
// Customizable Area End
class AdminGradeView extends AdminGradeViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  //move to controller
  componentDidMount(): Promise<void> {    // useEffect with empty bracket

    this.getGrade();

    return super.componentDidMount();
  }
  //controller
  onEdit = (data: { name: string, min_marks: number, max_marks: number, label: string, id: number }, field_id: number) => {

    this.setState({
      CreateOrEdit: 'edit',
      dialogOpen: true,
      grade: { name: data.name, min_marks: data.min_marks, max_marks: data.max_marks, label: data.label, field_id }
    });
  }
  onDelete = (data: { name: string, min_marks: number, max_marks: number, label: string, id: number }, field_id: number) => {
    this.setState({
      grade: { name: data.name, min_marks: data.min_marks, max_marks: data.max_marks, label: data.label, field_id }
    }, () => { this.deleteGrade() })

  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const nameReg = /^[\s]+$/;

    const { grandeNameError, grandeNameErrorMssg, minMarksError, minMarksErrorMssg,
      maxMarksError, maxMarksErrorMssg, labelError, labelErrorMssg } = this.state;

    return (
      <>

        <Box>
          <Box
            style={{
              margin: "14px 0px 21px 0px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                style={{
                  fontSize: "1.25rem",
                  fontWeight: 600,
                }}
                className={this.props.classes.heading_font}
              >
                <b>Grade Range</b>
              </Typography>
            </Box>
            <Box>
              <Button
                disableRipple={true}
                variant="outlined"
                style={{
                  cursor: "pointer",
                  borderRadius: "6px",
                  padding: " 11px 32px 11px 33px",
                  textTransform: "capitalize",
                }}

                className={this.props.classes.button_color}
                onClick={() => {
                  this.setState({ dialogOpen: true });
                  this.setState({ CreateOrEdit: 'create', grade: { name: "", min_marks: null, max_marks: null, label: "", field_id: null } })
                }}
              >
                Add Grade
              </Button>
            </Box>
          </Box>

          <Box
            style={{
              padding: "22px 20px 26px",
              marginTop: 20,
              borderRadius: 8,
              boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={12} style={{ marginTop: "3%" }}>
              <TableContainer>
                {this.state.loading && <Box justifyContent={"center"} display={"flex"} alignItems={"center"}>
                  <Spinner spinnerModal={this.state.loading} />
                </Box>}
                <Table style={{ width: "100%" }} aria-label="simple table">

                  <TableHead style={{ borderBottom: "none" }}>
                    <TableRow>

                      <TableCell
                        align="left"
                        style={{
                          width: "26%",
                          fontSize: "1rem",
                          fontWeight: 600,
                          borderTopLeftRadius: 6,
                          borderBottomLeftRadius: 6,
                          padding: "12px",
                          paddingLeft: 15,
                          lineHeight: "normal",
                          backgroundColor: "#ded9ff",
                          borderBottom: "none",
                        }}
                        className={this.props.classes.subHeading_font}
                      >
                        Grade Name
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          width: "12%",
                          fontSize: "1rem",
                          fontWeight: 600,
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          padding: "12px",
                          paddingLeft: 15,
                          lineHeight: "normal",
                          backgroundColor: "#ded9ff",
                          borderBottom: "none",
                        }}
                        className={this.props.classes.subHeading_font}
                      >
                        Min Marks
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          width: "12%",
                          fontSize: "1rem",
                          fontWeight: 600,
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          padding: "12px",
                          paddingLeft: 15,
                          lineHeight: "normal",
                          backgroundColor: "#ded9ff",
                          borderBottom: "none",
                        }}

                        className={this.props.classes.subHeading_font}
                      >
                        Max Marks
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          width: "30%",
                          fontSize: "1rem",
                          fontWeight: 600,
                          padding: "12px",
                          lineHeight: "normal",
                          backgroundColor: "#ded9ff",
                          borderBottom: "none",
                        }}
                        className={this.props.classes.subHeading_font}
                      >
                        Label
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          width: "10%",
                          fontSize: "1rem",
                          fontWeight: 600,
                          padding: "12px",
                          lineHeight: "normal",
                          backgroundColor: "#ded9ff",
                          borderTopRightRadius: 6,
                          borderBottomRightRadius: 6,
                          borderBottom: "none",
                        }}
                        className={this.props.classes.subHeading_font}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>


                  {!this.state.loading && this.state.data && this.state.data.map((item: any, index: number) => {
                    return (
                      <React.Fragment key={item.id}>
                        <Box style={{ height: "10px" }} />
                        <TableBody>
                          <TableRow
                            hover
                            //role="checkbox"
                            tabIndex={-1}
                            style={{
                              background: "#f9f8fd",
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              style={{
                                padding: "12px",
                                paddingLeft: 25,
                                borderBottom: "none",
                                borderTopLeftRadius: 6,
                                borderBottomLeftRadius: 6,
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "1.125rem",
                                  fontWeight: 600,
                                }}
                                className={this.props.classes.bodyText_font}
                              >
                                {item.attributes.name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ padding: 12, borderBottom: "none" }}
                            >
                              <Typography
                                style={{
                                  fontSize: "1.125rem",
                                  fontWeight: 600,
                                  lineHeight: "normal",
                                  textTransform: "capitalize",
                                }}
                                className={this.props.classes.bodyText_font}
                              >
                                {item.attributes.min_marks}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ padding: 12, borderBottom: "none" }}
                            >
                              <Typography
                                style={{
                                  fontSize: "1.125rem",
                                  fontWeight: 600,
                                  lineHeight: "normal",
                                  textTransform: "capitalize",
                                }}
                                className={this.props.classes.bodyText_font}
                              >
                                {item.attributes.max_marks}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ padding: 12, borderBottom: "none" }}
                            >
                              <Typography
                                style={{
                                  fontSize: "1.125rem",
                                  fontWeight: 600,
                                  lineHeight: "normal",
                                  textTransform: "capitalize",
                                }}
                                className={this.props.classes.bodyText_font}
                              >
                                {item.attributes.label}
                              </Typography>
                            </TableCell>


                            <TableCell component="th" scope="row" align="center" style={{ padding: 12, borderTopRightRadius: 6, borderBottomRightRadius: 6, borderBottom: 'none', display: 'flex', justifyContent: 'space-evenly' }} >
                              <div>
                                <img src={editDivision} onClick={(_) => this.onEdit(item.attributes, item.id)} style={{ cursor: 'pointer' }} />
                              </div>
                              <div >
                                <img src={deleteDivision} onClick={() => this.handleOpenRemoveModal(item.id)} style={{ cursor: 'pointer' }} />
                              </div>

                            </TableCell>


                          </TableRow>

                        </TableBody>
                      </React.Fragment>
                    );
                    // }else return false;
                  })}
                </Table>
              </TableContainer>
              {GradeData?.length === 0 ? <div style={{

                display: 'flex',
                justifyContent: 'center',
                fontSize: '16px',
                margin: "10px 23px",
                fontFamily: "Open Sans",
                color: "#3f526d",
                fontWeight: 600,
              }}>No Data Found</div> : <></>}
              {true && <Box style={{ textAlign: "end", marginTop: 25 }}>


                <ReactPaginate
                  previousLabel={"←   Previous"}
                  initialPage={this.state.currentPage}
                  pageCount={this.state.paginationMeta?.pagination?.total_pages}
                  onPageChange={(data: any) => this.setState({ currentPage: data.selected }, () => {

                    let selected = data.selected;
                    let offset = data.selected + 1;

                    this.setState({ offset: offset }, () => {
                      this.getGrade();
                    });
                  })}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                  pageRangeDisplayed={this.state.paginationMeta?.pagination?.total_pages}
                  marginPagesDisplayed={1}
                  breakLabel="..."
                />
              </Box>}


            </Grid>
          </Box>
          <Dialog
            PaperProps={{
              style: { borderRadius: 8 },
            }}
            aria-labelledby="customized-dialog-title"
            onClose={() => {
              this.handleDialogClose()
            }}
            maxWidth="md"
            open={this.state.dialogOpen}
            closeAfterTransition
            disableAutoFocus={true}
            style={{ borderRadius: 8 }}
          // onKeyUp={(e) => {
          //   const ENTER = 13;
          //   if (e.keyCode === ENTER) {
          //     props.onSubmit();
          //   }
          // }}
          >

            <div tabIndex={0} onKeyPress={this.handleKeyDown}>
              <Grid
                item
                xs={12}
                style={{
                  padding: "60px 62px 62px 62px",
                  borderRadius: 8,
                  boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                  minWidth: "600px",
                }}
              >
                <form onSubmit={(e) => {
                  this.state.CreateOrEdit === 'create' ? this.createGrade() : this.editGrade()
                  e.preventDefault();
                }}>
                  <Grid item xs={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Typography style={{
                      fontSize: "1.25rem",
                      color: "#3f526d",
                      fontWeight: 600,
                    }}
                    />
                    <Typography style={{
                      fontSize: "1.5rem",
                      fontWeight: 600
                    }}
                      className={this.props.classes.title_font}
                    >
                      {this.state.CreateOrEdit === 'create' ? 'Add Grade' : 'Edit Grade'}
                    </Typography>
                    <CloseIcon
                      style={{
                        cursor: "pointer",
                        fontSize: 38,
                        color: "lightGray"
                      }}
                      onClick={this.handleDialogClose}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} style={{ marginTop: 14, marginBottom: 10 }}>
                      <FormLabel
                        style={{
                          fontSize: "1.25rem",
                          fontWeight: 600,
                        }}
                        className={this.props.classes.subTitle_font}
                      >
                        Grade Name
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="standard-basic"
                        placeholder="Example Period 1, Break"
                        style={forrmInput_textfield}
                        variant="outlined"
                        value={this.state.grade.name}
                        error={grandeNameError}
                        helperText={grandeNameErrorMssg}

                        onChange={(event) => {
                          const value = event.target.value
                          if (value.trim().length === 0) {
                            this.setState({
                              grandeNameError: true,
                              grandeNameErrorMssg: "Grade name is required.",
                            });
                          } else {
                            this.setState({
                              grandeNameError: false,
                              grandeNameErrorMssg: "",
                            });
                          }

                          this.setState({ grade: { ...this.state.grade, name: value } })
                          if (event.target.value.length >= 30) {

                            event.stopPropagation();

                          }
                        }}
                        onBlur={() => {
                          if (
                            this.state.grade.name === null ||
                            this.state.grade.name.length === 0
                          ) {
                            this.setState({
                              grandeNameError: true,
                              grandeNameErrorMssg: "Grade name is required.",
                            });
                          } else {
                            this.setState({
                              grandeNameError: false,
                              grandeNameErrorMssg: "",
                            });
                          }
                        }}
                        inputProps={{
                          style: {
                            fontFamily: "Open sans",
                            fontSize: "1.125rem",
                            color: "#3f526d",
                            padding: "1rem 1.875rem",
                          },
                        }}
                        InputProps={{

                          style: {
                            fontSize: "1.125rem",
                            color: "#3f526d",
                            fontFamily: "OpenSans",
                            opacity: 1,
                            paddingLeft: 0,

                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <FormLabel
                          style={{
                            fontSize: '1.125rem',
                            fontWeight: 600,
                          }}
                          className={this.props.classes.subTitle_font}
                        >
                          Min Marks Value
                        </FormLabel>
                      </Grid>
                      <Grid item xs={12} >
                        <TextField
                          id="standard-basic"
                          placeholder="Out of 100"
                          style={forrmInput_textfield}
                          variant="outlined"
                          type="number"
                          value={this.state.grade.min_marks}
                          error={minMarksError}
                          helperText={minMarksErrorMssg}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value == null || Number(value) <= 0) {
                              this.setState({
                                minMarksError: true,
                                minMarksErrorMssg: "Min marks is required.",
                              });
                            } else {
                              this.setState({
                                minMarksError: false,
                                minMarksErrorMssg: "",
                              });
                            }
                            this.setState({ grade: { ...this.state.grade, min_marks: e.target.value ? parseInt(e.target.value) : null } })
                          }

                          }
                          onBlur={() => {
                            if (
                              this.state.grade.min_marks === null ||
                              this.state.grade.min_marks <= 0
                            ) {
                              this.setState({
                                minMarksError: true,
                                minMarksErrorMssg: "Min marks is required.",
                              });
                            } else {
                              this.setState({
                                minMarksError: false,
                                minMarksErrorMssg: "",
                              });
                            }
                          }}
                          inputProps={{ max: 100, min: 0, style: { fontFamily: "Open sans", fontSize: '1.125rem', color: "#3f526d", padding: '1rem 1.875rem' } }}
                          InputProps={{

                            style: {
                              fontSize: "1.125rem",
                              color: '#3f526d',
                              fontFamily: "OpenSans",
                              opacity: 1,
                              paddingLeft: 0,


                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <FormLabel
                          style={{
                            fontSize: '1.125rem',
                            fontWeight: 600,
                          }}
                          className={this.props.classes.subTitle_font}
                        >
                          Max Marks Value
                        </FormLabel>
                      </Grid>
                      <Grid item xs={12} >
                        <TextField
                          id="standard-basic"
                          placeholder="Out of 100"
                          style={forrmInput_textfield}
                          variant="outlined"
                          value={this.state.grade.max_marks}
                          error={maxMarksError}
                          helperText={maxMarksErrorMssg}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value == null || Number(value) <= 0) {
                              this.setState({
                                maxMarksError: true,
                                maxMarksErrorMssg: "Max marks is required.",
                              });
                            } else {
                              this.setState({
                                maxMarksError: false,
                                maxMarksErrorMssg: "",
                              });
                            }
                            this.setState({ grade: { ...this.state.grade, max_marks: e.target.value ? parseInt(e.target.value) : null } })
                          }

                          }
                          onBlur={() => {
                            if (
                              this.state.grade.max_marks === null ||
                              this.state.grade.max_marks <= 0
                            ) {
                              this.setState({
                                maxMarksError: true,
                                maxMarksErrorMssg: "Max marks is required.",
                              });
                            } else {
                              this.setState({
                                maxMarksError: false,
                                maxMarksErrorMssg: "",
                              });
                            }
                          }}
                          inputProps={{ max: 100, min: 0, style: { fontFamily: "Open sans", fontSize: '1.125rem', color: "#3f526d", padding: '1rem 1.875rem' } }}
                          InputProps={{
                            // startAdornment: (
                            //   <InputAdornment position="start" style={{ marginRight: "0" }}>
                            //     <img src={schoolIcon} style={{ width: '1.465rem', height: '1.465rem', color: "#7c7ba0", padding: '1.15rem 1.87rem 1.15rem 1.56rem', marginRight: "0px" }} />
                            //   </InputAdornment>
                            // ),
                            style: {
                              fontSize: "1.125rem",
                              color: '#3f526d',
                              fontFamily: "OpenSans",
                              opacity: 1,
                              paddingLeft: 0,
                              // border: "1px solid #cfcfcf",
                              //backgroundColor: "#f1f1f1",

                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12} style={{ marginTop: -10, marginBottom: 10 }}>
                        <FormLabel
                          style={{
                            fontSize: "1.25rem",
                            fontWeight: 600,
                          }}
                          className={this.props.classes.subTitle_font}
                        >
                          Label
                        </FormLabel>
                      </Grid>
                      <Grid item xs={12}>

                        <TextField
                          id="standard-basic"
                          placeholder="Good, Need improvment, Very good"
                          style={forrmInput_textfield}
                          variant="outlined"
                          value={this.state.grade.label}
                          error={labelError}
                          helperText={labelErrorMssg}
                          //onChange={(e) => this.setState({grade: {...this.state.grade, label : e.target.value}})}
                          onChange={(event) => {
                            const value = event.target.value
                            if (value.trim().length === 0) {
                              this.setState({
                                labelError: true,
                                labelErrorMssg: "Label is required.",
                              });
                            } else {
                              this.setState({
                                labelError: false,
                                labelErrorMssg: "",
                              });
                            }

                            this.setState({ grade: { ...this.state.grade, label: value } })
                            if (event.target.value.length >= 30) {

                              event.stopPropagation();

                            }
                          }}
                          onBlur={() => {
                            if (
                              this.state.grade.label === null ||
                              this.state.grade.label.length === 0
                            ) {
                              this.setState({
                                labelError: true,
                                labelErrorMssg: "Label is required.",
                              });
                            } else {
                              this.setState({
                                labelError: false,
                                labelErrorMssg: "",
                              });
                            }
                          }}
                          inputProps={{
                            style: {
                              fontFamily: "Open sans",
                              fontSize: "1.125rem",
                              color: "#3f526d",
                              padding: "1rem 1.875rem",
                            },
                          }}
                          InputProps={{

                            style: {
                              fontSize: "1.125rem",
                              color: "#3f526d",
                              fontFamily: "OpenSans",
                              opacity: 1,
                              paddingLeft: 0,

                            },
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ display: "flex", marginTop: "10px" }}>
                      <Button
                        type="submit"
                        style={{
                          padding: true ? "13px 47px " : "13px 62px 13px 62px",
                          borderRadius: 8,
                        }}

                        className={this.props.classes.button_color}
                      >
                        <Typography
                          style={{
                            textTransform: "none",
                            fontSize: "1.125rem",
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        />
                        {this.state.CreateOrEdit == 'create' ? 'Create' : 'Update'}
                      </Button>
                      <Button
                        style={{
                          //border:'solid 0.5px #7f6cfc',
                          padding: "11px 24px 11px 25px",
                          borderRadius: 6,
                          marginLeft: 20,
                        }}

                        className={this.props.classes.button_color_disabled}
                      //  variant="contained"
                      >
                        <Typography
                          style={{
                            textTransform: "none",
                            fontSize: "1.125rem",
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                            color: "#3f526d",
                          }}
                          onClick={() => {
                            this.handleDialogClose()



                          }}
                        >
                          Cancel
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </div>
          </Dialog>
        </Box>

        <RemoveModal
          RemoveModal={this.state.removeModal}
          closeModalFun={this.handleremoveClose}
          id={this.state.id}
          callback={this.getGrade}
        />

      </>
    );
    // Customizable Area End
  }
}

export const themeCreatStyle = ((theme: any) => ({
  background_color: {
    backgroundColor: theme.palette.primary.main
  },
  title_font: {
    color: theme.props.titleColor,
    fontFamily: theme.props.titleFont
  },
  subTitle_font: {
    color: theme.props.subtitleColor,
    fontFamily: theme.props.subtitleFont
  },
  heading_font: {
    color: theme.props.headingColor,
    fontFamily: theme.props.headingFont
  },
  subHeading_font: {
    color: theme.props.subheadingColor,
    fontFamily: theme.props.subheadingFont
  },
  bodyText_font: {
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  button_color: {
    backgroundColor: theme.props.buttonColor,
    color: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    '&:hover': {
      background: theme.props.buttonColor,
    }
  },
  image_Border_Color: {
    border: `3px solid ${theme.props.buttonColor}`
  },
  icon_color: {
    color: theme.props.iconColor
  },
  button_color_disabled: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
  }
}))
// Customizable Area Start
export default withStyles(themeCreatStyle)(HOCAuth(AdminGradeView as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End