const urlConfig = require("../../../../framework/src/config");

// Customizable Area Start
exports.getStudentSubjects = "/bx_block_scheduling/subjects/subject_class_section_subjects";
exports.instituteURL = urlConfig.instituteURL;
exports.studentSubjectJoinEndPoint = "/bx_block_scheduling/classes/enrol_student_in_class";
exports.getTeacherSubjectsEndPoint = "/bx_block_scheduling/subjects/class_section_subjects";
exports.getSubjectsEndPoint = "/bx_block_scheduling/subjects/class_section_subjects";
exports.getTeacherSubjectsEndPoint = "bx_block_scheduling/subjects/account_subjects";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.SignIDUrl = urlConfig.baseURL;
exports.dashboardURL = urlConfig.dashboardURL;
exports.getSearchEndPoint = "/bx_block_scheduling/subjects/search_subjects";
exports.teacherSubjectRemove = "/bx_block_scheduling/subjects/delete_class_subject";
exports.dashboardGetUrl = "/bx_block_dashboard8/dashboards/get_teacher_classes";
exports.getTeacherGrades = "bx_block_scheduling/grades";
exports.instituteURL = urlConfig.instituteURL;
exports.getPreSignedIdEndPoint = "bx_block_bulk_uploading/uploads/pre_signed_url";
exports.fileUploadS3 = "bx_block_bulk_uploading/uploads/s3_signed_url"
exports.classTeachersEndPoint =
  "/bx_block_scheduling/school_classes/get_grade_teachers";

exports.classStudentEndPoint = '/bx_block_scheduling/school_classes/get_class_students'
exports.gradeStudentEndPoint = '/bx_block_scheduling/school_classes/student_by_divisions'
exports.showTaskEvaluationEndPoint = '/bx_block_grading/task_evaluations/show_task_evaluation'
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.dashboardGetUrl = "/bx_block_dashboard8/dashboards/get_teacher_classes";
exports.teacherSubjects = "/bx_block_dashboard8/dashboards/get_teacher_subjects";
exports.teacherAssignment = "/bx_block_dashboard8/dashboards/get_teacher_assessments";
exports.teacherNotes = "/bx_block_dashboard8/dashboards/get_notes";
exports.numberofStudents = "/bx_block_dashboard8/dashboards/get_teacher_students";
exports.studentSubjectGetUrl = "/bx_block_dashboard8/dashboards/get_student_subjects";
exports.studentUpcomingClassesGetUrl = "/bx_block_dashboard8/dashboards/get_student_upcoming_classes";
exports.studentGoalsGetUrl = "/bx_block_dashboard8/dashboards/get_student_goals",
  exports.studentProgressGetUrl = "/bx_block_dashboard8/dashboards/get_student_progress",
  exports.studentNotesGetUrl = "/bx_block_dashboard8/dashboards/get_notes",
  exports.studentCurveGetUrl = "/bx_block_dashboard8/dashboards/get_student_learning_curve",
  exports.studentAssessmentUrl = "/bx_block_dashboard8/dashboards/get_student_assessments",
  exports.studentSubjectJoinEndPoint = "/bx_block_scheduling/classes/enrol_student_in_class"
// exports.getTeacherSubjectsEndPoint = "/bx_block_scheduling/subjects/teacher_class_section_subjects";
exports.getSearchEndPoint = "/bx_block_scheduling/subjects/search_subjects";
exports.getStudentSubjects = "/bx_block_scheduling/subjects/subject_class_section_subjects";
exports.teacherSubjectRemove = "/bx_block_scheduling/subjects/delete_class_subject";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.dashboardURL = urlConfig.dashboardURL;
exports.instituteURL = urlConfig.instituteURL;
exports.attandanceURL = urlConfig.attendanceURL;
exports.resultUrl = urlConfig.resultUrl;
exports.examinationUrl = urlConfig.examinationUrl;
// resultUrl
exports.createUnitEndPoint = "/bx_block_scheduling/units";
exports.createTaskEndPoint = "/bx_block_task/tasks";
exports.getTaskEndPoint = "/bx_block_task/tasks/get_teacher_tasks";
exports.getStudentTaskEndPoint = "/bx_block_task/tasks/get_student_tasks";
exports.getUnitEndPoint = "/bx_block_task/units/get_unit_details";
exports.getTeacherData = "/bx_block_scheduling/classes/get_class_teachers";
exports.getTeachersEndPoint = "/account_block/get_teachers";
exports.getStudentEndPoint = "/account_block/get_students";
exports.searchFunctionEndPoint = "/bx_block_task/tasks/search_tasks";
exports.submitCourseWorkEndPoint = "/bx_block_task/task_courseworks";
exports.taskDeleteEndPoint = "/bx_block_task/tasks/";
exports.editTaskEndPoint = "bx_block_task/tasks/";
exports.categoryEndPoint = "/bx_block_task/task_categories";
exports.getTeacherChapterEndPoint = "/bx_block_scheduling/chapters"
exports.apiMethodType = "POST";
exports.deleteMethodType = "DELETE";
exports.EditMethodType = 'PUT'
exports.uploadTaskApiEndPoint = 'bx_block_task/tasks/'
exports.createGradeEndPoint = "/bx_block_grading/grade_scales"
exports.uploadTaskApiEndPoint = 'bx_block_task/tasks/';
exports.getStudetTaskSubmissionEndPoint = '/bx_block_task/tasks/get_teacher_evalution_tasks';
exports.getTeacherClassesEndPoint = '/account_block/school_accounts/get_teacher_classes';
exports.getTeacherSubjectEndPoint = '/bx_block_scheduling/subjects/account_subjects'
exports.selectGradeScale = '/bx_block_grading/grade_scales/grades_for_select';
exports.task_categoeryGrade_Scales = '/bx_block_grading/task_category_grade_scales';
exports.Bulk_destroy = "/bx_block_task/task_categories/bulk_destroy";
exports.grade_delete = "bx_block_grading/grade_scales"
exports.task_category_list = "bx_block_assessmenttest/report_category_types/list";
exports.termListEndPoint = "bx_block_assessmenttest/terms/term_list";

exports.getTeacherMaskSubmitEndPoint = '/bx_block_grading/task_evaluations';
// exports.getTaskGradeScaleEndPoint = '/bx_block_grading/grade_scales/fetch_grade_scale' //old one
exports.getTaskGradeScaleEndPoint = 'bx_block_assessmenttest/report_grade/fetch_report_grade_scale'
exports.getPreSignedUrl = "bx_block_bulk_uploading/uploads/s3_signed_url";
exports.selectGradeScale = '/bx_block_grading/grade_scales/grades_for_select';
  // Customizable Area End
