// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Typography,
    Checkbox,
    Popover,
} from "@material-ui/core";
import {
    Search,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import FilterSubjectController, { Props } from "./FilterSubjectController.web";
import 'react-calendar/dist/Calendar.css';
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
import './CommanSelectDiv.web.css';
// Customizable Area End
// Customizable Area Start
// Customizable Area End
export class FilterSubject extends FilterSubjectController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { dataType, optionalData, subjectData, searchData } = this.state
        return (
            <>
                <Popover
                    id="1"
                    anchorEl={this.props.dropDownAnchorEl}
                    open={Boolean(this.props.dropDownAnchorEl)}
                    onClose={this.modalhandleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    className="common_select_div_popover mt_5"
                    PaperProps={{
                        style: {
                            maxHeight: '70%',
                            padding: "20px 20px",
                            borderRadius: 8,
                            boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.2)",
                            backgroundColor: "#fff"
                        },
                    }}
                >
                    <Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            width="100%"
                            className={`${this.props.classes.filter_classAndSection_border_Color} search_container2`}
                        >
                            <Search 
                                className={`${this.props.classes.icon_color} search`}
                            />
                            <input
                                placeholder="Search subject"
                                className="search_input transparent_bg"
                                onChange={
                                    ((event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ queryTerm: event.target.value.trim() })

                                        const filtered = subjectData?.filter((member: any) => {
                                            const memberName = ` ${member.subject_name}`
                                            if (event.target.value === '') {
                                                return member;
                                            }
                                            else if (member && (member.subject_name && member.subject_name.toLowerCase().includes(event.target.value.trim().toLowerCase()))) {
                                                return member;
                                            }
                                        })

                                        this.setState({ searchData: filtered })

                                    })}
                            />
                        </Box>
                        <Box
                            className="container2"
                        >
                            {
                                (this.state.queryTerm === '' ? subjectData : searchData).length === 0
                                    ?
                                    <>
                                        <Typography className="no_record_found">
                                            No record(s) found.
                                        </Typography>
                                    </>
                                    :
                                    (this.state.queryTerm === '' ? subjectData : searchData).map((data: any, index: any) => (
                                        <Box
                                            className="data_list2"
                                            onClick={() => {
                                                const tempresponseData = dataType === 'subject' ? subjectData : optionalData
                                                if (this.state.queryTerm === '') {
                                                    tempresponseData[index].checked = !tempresponseData[index].checked
                                                    this.setState({ subjectData: tempresponseData })
                                                }
                                                else {
                                                    const findIndex = subjectData.findIndex((res: any) => res.id === data.id)
                                                    tempresponseData[findIndex].checked = !tempresponseData[findIndex].checked
                                                    this.setState({ subjectData: tempresponseData })
                                                }
                                            }
                                            }
                                        >
                                            <Typography
                                                className={`${this.props.classes.bodyText_font} title`}
                                            >
                                                {data.subject_name}
                                            </Typography>
                                            <Box
                                                onClick={(event: any) => {
                                                    event.stopPropagation();
                                                    const tempresponseData = dataType === 'subject' ? subjectData : optionalData
                                                    if (this.state.queryTerm === '') {
                                                        tempresponseData[index].checked = !tempresponseData[index].checked
                                                        this.setState({ subjectData: tempresponseData })
                                                    }
                                                    else {
                                                        const findIndex = subjectData.findIndex((res: any) => res.id === data.id)
                                                        tempresponseData[findIndex].checked = !tempresponseData[findIndex].checked
                                                        this.setState({ subjectData: tempresponseData })
                                                    }
                                                }}>
                                                <Checkbox
                                                    checked={data.checked ? data.checked : false}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    className={`${this.props.classes.checkBox_color} transparent_bg`}
                                                />
                                            </Box>
                                        </Box>
                                    ))
                            }
                        </Box>
                        <Box
                            className="btn_container2"
                        >
                            <Button
                                className={`${this.props.classes.button_color} save_btn2`}
                                onClick={this.datasavemodalClose}
                            >
                                Save
                            </Button>
                            <Button
                                className={`${this.props.classes.button_color_disabled} cancel_btn`}
                                onClick={this.modalhandleClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Popover>
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(FilterSubject);

// Customizable Area End
