// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    Checkbox,
} from "@material-ui/core";
import StudentAssessmentSummaryController, { Props } from './StudentAssessmentSummaryController.web';
import { difficultyCopyIcon, difficultyIcon, imgBackArrow, sectionIcon } from "../assets";
import { withStyles } from "@material-ui/styles";
import Spinner from "../../../shared/SideBar/src/Spinner";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import './StudentAssessmentSummary.web.css';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class Assessment extends StudentAssessmentSummaryController {
    // Customizable Area Start
    render() {
        const { classes } = this.props
        const { assessmentSummary } = this.state;
        return (
            <>
                {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
                <Typography
                    className={`studentAssessmentSummary_ExamTxt ${this.props.classes.heading_font}`}
                >
                    <img src={imgBackArrow}
                        className='studentAssessmentSummary_backIcon'
                        onClick={() => { this.props.navigation.goBack() }}
                    />
                    Exam - {assessmentSummary?.subject?.subject_name} [{assessmentSummary?.term?.title}]
                </Typography>

                <div className='studentAssessmentSummary_titleDiv'>
                    <Typography className={`studentAssessmentSummary_titleTxt ${this.props.classes.heading_font}`} >
                        Assessment Summary
                    </Typography>

                    <Box className='studentAssessmentSummary_subtitleDiv'>
                        <Grid container spacing={2}>
                            {/* Assessment Name */}
                            <Grid item xs={12}>
                                <Typography className={`studentAssessmentSummary_subtitleTxt ${this.props.classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                    Assessment Title
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    placeholder=""
                                    value={assessmentSummary?.assessment?.assessment_name}
                                    disabled={true}
                                    defaultValue=""
                                    className={this.props.classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                    inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                />
                            </Grid>
                            {/* Assessment Name END */}

                            <Grid item xs={12} className='studentAssessmentSummary_assesmentNameDiv'>
                                <Grid container spacing={3}>
                                    {/* Subject */}
                                    <Grid item xs={3}>
                                        <Typography className={`studentAssessmentSummary_assesmentNameTxt ${this.props.classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`} >
                                            Subject
                                        </Typography>

                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            type="text"
                                            defaultValue=""
                                            value={assessmentSummary?.subject?.subject_name}
                                            disabled={true}
                                            className={this.props.classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />

                                    </Grid>
                                    {/* Subject END */}

                                    {/* Class & Division */}
                                    <Grid item xs={3}>

                                        <Typography className={`studentAssessmentSummary_assesmentNameTxt ${this.props.classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                            Class
                                        </Typography>

                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            type="text"
                                            defaultValue=""
                                            //value={assessmentSummary?.attributes?.class_name?.attributes?.class_name + " " + (assessmentSummary?.attributes?.division_name?.attributes?.division_name ? assessmentSummary?.attributes?.division_name?.attributes?.division_name : '')}
                                            value={assessmentSummary?.grade?.attributes?.name}
                                            disabled={true}
                                            className={this.props.classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />
                                    </Grid>
                                    {/* Class & Division END */}

                                    {/* Exam Type */}
                                    <Grid item xs={3}>
                                        <Typography className={`studentAssessmentSummary_assesmentNameTxt ${this.props.classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                            Exam Type
                                        </Typography>

                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            type="text"
                                            defaultValue=""
                                            value={assessmentSummary?.assessment?.exam_type}
                                            disabled={true}
                                            className={this.props.classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />
                                    </Grid>
                                    {/* Exam Type END */}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className='studentAssessmentSummary_assesmentNameTopDiv'>
                                <Grid container spacing={3}>
                                    {/* Max marks */}
                                    <Grid item xs={3}>
                                        <Typography className={`studentAssessmentSummary_assesmentNameTxt ${this.props.classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                            Max marks
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            type="text"
                                            defaultValue=""
                                            value={assessmentSummary?.assessment?.maximum_marks}
                                            disabled={true}
                                            className={this.props.classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />
                                    </Grid>
                                    {/* Max marks END */}
                                    {/* Terms */}
                                    <Grid item xs={3}>
                                        <Typography className={`studentAssessmentSummary_assesmentNameTxt ${this.props.classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                            Terms
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            type="text"
                                            defaultValue=""
                                            value={assessmentSummary?.term?.title}
                                            disabled={true}
                                            className={this.props.classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />
                                    </Grid>
                                    {/* Terms END */}

                                    {/* Include Negative Marking */}
                                    <Grid item xs={3}>
                                        <Typography className={`studentAssessmentSummary_assesmentNameTxt ${this.props.classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                            Include Negative Marking?
                                        </Typography>
                                        <Box
                                            className={this.props.classes.studentAssessmentSummary_AssessmentTest_BoxStyle}
                                        >
                                            <Checkbox
                                                disabled={true}
                                                checked={assessmentSummary?.assessment?.negative_marking ? true : false}
                                            />
                                            <Typography
                                                className={`studentAssessmentSummary_checkboxTxt ${this.props.classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>Yes</Typography>
                                        </Box>
                                    </Grid>
                                    {/* Include Negative Marking END */}

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className='studentAssessmentSummary_assesmentNameTopDiv'>
                                <Typography
                                    className={`studentAssessmentSummary_questionPaperDesc ${this.props.classes.heading_font}`}
                                >
                                    Question paper Description
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className='studentAssessmentSummary_assesmentNameTopDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <Box className='studentAssessmentSummary_txtDiv'>
                                            <img src={difficultyIcon} className='studentAssessmentSummary_txtDivImg' />
                                            <Typography
                                                style={{ marginLeft: 20 }}
                                                className={`studentAssessmentSummary_txtDivTxt ${this.props.classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                                Difficulty Level
                                            </Typography>
                                        </Box>

                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            type="text"
                                            defaultValue=""
                                            value={assessmentSummary?.assessment?.difficulty_level}
                                            disabled={true}
                                            className={this.props.classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box className='studentAssessmentSummary_txtDiv'>
                                            <img src={difficultyCopyIcon} className='studentAssessmentSummary_txtDivImg' />
                                            <Typography
                                                className={`studentAssessmentSummary_txtDivTxt ${this.props.classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                                Total Duration
                                            </Typography>
                                        </Box>
                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            defaultValue=""
                                            value={assessmentSummary?.assessment?.time_limit}
                                            disabled={true}
                                            className={`studentAssessmentSummary_textField ${this.props.classes.studentAssessmentSummary_AssessmentTest_textFieldNum}`}
                                            inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                        />

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} className='studentAssessmentSummary_assesmentNameTopDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Box className='studentAssessmentSummary_assesmentNameSubDiv'>
                                            <img src={sectionIcon} className='studentAssessmentSummary_txtDivImg' />
                                            <Typography
                                                className={`studentAssessmentSummary_txtDivTxt ${this.props.classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}`}>
                                                Section
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box className='studentAssessmentSummary_assesmentNameSubDiv'>
                                            <Typography
                                                className={this.props.classes.studentAssessmentSummary_AssessmentTest_AssessmentTitle}>
                                                Total Marks
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {assessmentSummary?.assessment_sections?.map((sectionData: any) => (
                                <Grid key={sectionData?.id} item xs={12} className='studentAssessmentSummary_assesmentNameTopDiv'>
                                    <Grid container spacing={3}>
                                        <Grid item xs={3}>
                                            <TextField
                                                variant="outlined"
                                                placeholder=""
                                                defaultValue=""
                                                value={sectionData?.name}
                                                disabled={true}
                                                className={this.props.classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                                inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                variant="outlined"
                                                placeholder=""
                                                defaultValue=""
                                                value={sectionData?.section_marks}
                                                disabled={true}
                                                className={this.props.classes.studentAssessmentSummary_AssessmentTest_textFieldNum}
                                                inputProps={{ style: { textTransform: "capitalize", fontWeight: 600 } }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}


                        </Grid>
                    </Box>

                    <Box className='studentAssessmentSummary_assesmentBtnDiv'>
                        <Button
                            className={`${!this.state.checkStartBtn ? `studentAssessmentSummary_disabledBtn` : `studentAssessmentSummary_activeBtn`} ${this.props.classes.button_color}`}
                            disabled={!this.state.checkStartBtn}
                            onClick={() => this.startClick()}
                        >
                            <Typography className="studentAssessmentSummary_assesmentBtnTxt">
                                Start
                            </Typography>

                        </Button>

                    </Box>
                </div>

            </>
        )
    }
    // Customizable Area End
}

// Customizable Area Start

export default withStyles(themeCreateStyle)(HOCAuth(Assessment, "StudentAccount"));
// Customizable Area End