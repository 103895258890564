// Customizable Area Start
import React from "react"
import {
    Box,
    Button,
    withStyles,
    Typography,
    Modal,
    Backdrop,
    IconButton,
    SvgIcon
} from "@material-ui/core";
import ErrorUploadAssesmentModalController, { Props } from "./ErrorUploadAssesmentModalController.web";
import { errorModalImg } from "../assets";
import CloseIcon from '@material-ui/icons/Close';
import Spinner from "../../../shared/SideBar/src/Spinner";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class ErrorUploadAssesmentModal extends ErrorUploadAssesmentModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        return (
            <>
                <Spinner spinnerModal={this.state.spinner} />
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={`${this.props.classes.assesmentUpload_AssessmentTest_flexCenter}`}
                    open={this.props.openModal}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >

                    <Box className="error_upload_modal">
                        <Box
                            className={`assesmentUpload_AssessmentTest_closeBtnBox`}
                        >
                            <IconButton
                                onClick={this.closeIconClickHandler}
                            ><CloseIcon className={`${this.props.classes.assesmentUpload_AssessmentTest_font36}`} />
                            </IconButton>
                        </Box>
                        <Box
                            className={`${`assesmentUpload_AssessmentTest_errorBox`}`}
                        >

                            <Box
                                className={`assesmentUpload_AssessmentTest_textAlignCenter`}
                            >
                                <img src={errorModalImg} className={`${this.props.classes.assesmentUpload_AssessmentTest_area64}`} />
                                <br />
                                <Typography className={`${this.props.classes.title_font} error_in_uploading`}>
                                    Error in uploading
                                </Typography>
                            </Box>

                            <Typography className={`${this.props.classes.subTitle_font} upload_error_text`}>
                                A total of <b>{this.props.uploadedQuestions} out of {this.props.totalQuestions} questions</b> have been uploaded,<br />Please check CSV file with error.
                            </Typography>


                            <Box
                                className={`${this.props.classes.assesmentUpload_AssessmentTest_mt4} assesmentUpload_AssessmentTest_textAlignCenter`}
                            >
                                <Typography className={`${this.props.classes.bodyText_font} upload_error_text`}>
                                    {this.state.csvFile}<a
                                        href={this.props.errorCSVDownloadLink}
                                        target="_blank"
                                        download
                                        className={`${this.props.classes.assesmentUpload_AssessmentTest_downloadCsvBtn}`}
                                    >
                                        <SvgIcon strokeWidth="0" width="22" height="22" viewBox="0 0 22 22" className={this.props.classes.assesmentUpload_AssessmentTest_svg_icon_stroke}>
                                            <g fill="none" fillRule="evenodd">
                                                <path d="M0 0h22v22H0z" />
                                                <path d="M3.667 15.583v1.834c0 1.012.82 1.833 1.833 1.833h11c1.013 0 1.833-.82 1.833-1.833v-1.834M6.417 10.083 11 14.667l4.583-4.584M11 3.667v11" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                        </SvgIcon>
                                    </a>
                                </Typography>
                            </Box>


                            <Box
                                className={`${this.props.classes.assesmentUpload_AssessmentTest_mt6} ${this.props.classes.assesmentUpload_AssessmentTest_dFlex}`}
                            >

                                <Button className={`${this.props.classes.button_color} csv_reupload_button`} onClick={this.reUploadClickHandler}>
                                    Reupload

                                </Button>
                                <Button className={`csv_cancel_button  ${this.props.classes.outline_button}`}
                                    onClick={this.closeIconClickHandler}
                                >
                                    <Typography className={`${this.props.classes.assesmentUpload_AssessmentTest_outline_button_text}`}>Cancel</Typography>
                                </Button>

                            </Box>

                        </Box>
                    </Box>

                </Modal>
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(ErrorUploadAssesmentModal);
// Customizable Area End