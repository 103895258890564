// Customizable Area Start
import React from "react";
import moment from "moment";
import {
  Box,
  Typography,
  WithStyles,
  withStyles,
  IconButton,
  Menu,
  MenuItem
  // MoreVertOutlined,
} from "@material-ui/core";
import { MoreVertOutlined } from "@material-ui/icons";
import { blockIcon, deleteIcon, editIcon } from "../assets";
import ThoughtBoxMenuWeb from "./ThoughtBoxMenu.web";
// Customizable Area End

interface IProps {
  // Customizable Area Start
  classes?: any;
  thoughtDetail: any;
  setThoughtToInactive?: any;
  createThought?: any;
  updateThought?: any;
  createThoughtDelete?: any;
  updateStatEditThough?: any;
  // Customizable Area End
}

const ThoughtBox: React.FC<IProps> = ({ classes,
  thoughtDetail, setThoughtToInactive, createThought, updateThought, createThoughtDelete, updateStatEditThough

}) => {
  // Customizable Area Start
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [thoughtId, setThoughtId] = React.useState<any>(null);
  // Customizable Area End

  // Customizable Area Start
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setThoughtId(thoughtDetail.id)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInactive = () => {
    setThoughtToInactive(thoughtId)
  }
  const createThoughtDelete2 = (obj: any) => {
    createThoughtDelete(obj)
  }
  const updateStateEditThough = (obj: any, id:any) => {
    updateStatEditThough(obj,id)
  }
  // Customizable Area End

  return (
    <>
      <Box className={classes.thoughtContainer}>
        <Box
          style={{
            margin: "8px",
          }}
        >
          <Box style={{ display: "flex" }}>
            <Box style={{ width: "90%" }}>
              <Typography className={classes.contentFont}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: thoughtDetail?.attributes?.thought_title,
                  }}
                />
              </Typography>
            </Box>
            <Box style={{ width: "10%", height: "15px", marginLeft: "20px" }}>
              <IconButton
                onClick={handleClick}
              >
                <MoreVertOutlined />
              </IconButton>
            </Box>
          </Box>
          <Box style={{ marginTop: "20px" }}>
            <Box
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography className={classes.DateTextFont}>
                  From :{' '}
                  {moment(thoughtDetail?.attributes?.valid_from).format(`MMM DD YYYY`)} at{" "}
                  {moment(thoughtDetail?.attributes?.valid_from).format(`hh:mm A`)}
                </Typography>
              </div>
              <div>
                <Typography className={classes.DateTextFont}>
                  To :{' '}
                  {moment(thoughtDetail?.attributes?.valid_to).format(`MMM DD YYYY`)} at{" "}
                  {moment(thoughtDetail?.attributes?.valid_to).format(`hh:mm A`)}
                </Typography>
              </div>
              <div>
                <Typography className={classes.ActiveFont}>{thoughtDetail?.attributes?.status}</Typography>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
      <ThoughtBoxMenuWeb
        updateThought={updateThought}
        createThought={createThought}
        setThoughtToInactive={handleInactive}
        thought={thoughtDetail?.attributes}
        thoughtId={thoughtDetail?.id}
        anchorEl={anchorEl}
        status={thoughtDetail?.attributes?.status}
        setAnchorEl={handleClose}
        createThoughtDelete2={createThoughtDelete2}
        updateStateEditThough={updateStateEditThough}
      />

      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div style={{width:'200px'}}>
        <MenuItem >
        <Box style={{display:'flex',width:'100%',alignItems:'center'}}>
          <Box style={{width:'20%'}}>
          <img src= {blockIcon} 
          style={{
            height:15,
            width:15
          }}
          />
          </Box>
          <Box>
          <Typography
          className={classes.MenuItemFont} 
          >
          Move to Inactive
          </Typography>
          </Box>
        </Box>
        </MenuItem>
        <Box className={classes.Line} />
        <MenuItem >
        <Box style={{display:'flex',width:'100%',alignItems:'center'}}>
          <Box style={{width:'20%'}}>
          <img src= {editIcon} 
          style={{
            height:15,
            width:15
          }}
          />
          </Box>
          <Box>
          <Typography 
          className={classes.MenuItemFont}
          >
          Edit
          </Typography>
          </Box>
        </Box>
        </MenuItem>
        <Box className={classes.Line} />
        <MenuItem  onClick={removeAction}>
        <Box style={{display:'flex',width:'100%',alignItems:'center'}}>
          <Box style={{width:'20%'}}>
          <img src= {deleteIcon} 
          style={{
            height:20,
            width:20,
            marginTop:4
          }}
          />
          </Box>
          <Box>
          <Typography 
          className={classes.MenuItemFont}
          >
          Remove
          </Typography>
          </Box>
        </Box>
        </MenuItem>
        </div>
      </Menu> */}
    </>
  );
};
export const themeCreatStyle = ((theme: any) => ({
  // Customizable Area Start
  ActiveFont: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: 1.71,
    letterSpacing: "0.1px",
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },

  DateTextFont: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: 1.71,
    letterSpacing: "0.1px",
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },

  thoughtContainer: {
    borderRadius: "8px",
    padding: "10px",
    margin: "10px",
    backgroundColor: "#fafafb",
  },
  contentFont: {
    fontSize: "16px",
    fontWeight: 600,
    color: theme.props.subheadingColor,
    fontFamily: theme.props.subheadingFont,
    "& p": {
      margin:0,
    }
  },
  background_color: {
    backgroundColor: theme.palette.primary.main
  },
  title_font: {
    color: theme.props.titleColor,
    fontFamily: theme.props.titleFont
  },
  subTitle_font: {
    color: theme.props.subtitleColor,
    fontFamily: theme.props.subtitleFont
  },
  heading_font: {
    color: theme.props.headingColor,
    fontFamily: theme.props.headingFont
  },
  subHeading_font: {
    color: theme.props.subheadingColor,
    fontFamily: theme.props.subheadingFont
  },
  bodyText_font: {
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  checkBox_color: {
    color: theme.props.buttonColor
  },
  button_color: {
    backgroundColor: theme.props.buttonColor,
    color: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    '&:hover': {
      background: theme.props.buttonColor,
    }
  },
  border_Color: {
    border: `0.3px solid ${theme.props.buttonColor}`
  },
  icon_color: {
    color: theme.props.iconColor
  },
  file_border_Color: {
    border: `dashed 0.5px ${theme.props.buttonColor}`,
  },
  button_color_disabled: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    '&:hover': {
      background: "#fff",
    }
  },
  normal_button_color: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
  },
  dashed_Border: {
    border: `0.3px dashed ${theme.props.buttonColor}`,
  },
  // Customizable Area End
}))

// Customizable Area Start
export default withStyles(themeCreatStyle)(ThoughtBox);
// Customizable Area End
