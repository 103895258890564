// Customizable Area Start
import React from 'react'
import {
    Typography, Box, Avatar, Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles";
import { downArrow, rightArrow, chat2Icon } from "./assets";
import { Search } from '@material-ui/icons';
import TeacherMembersController from './TeacherMembersController.web'
import TeacherMembersBox from './TeacherMembersBox.web'
import './Member.web.css'
import { Image } from "react-native";
import moment from 'moment';
import ReactPaginate from "react-paginate";
import Spinner from '../../../shared/SideBar/src/Spinner';
import SelectedTab from '../SelectedTab.web'
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import './CommonMembers.web.css';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class TeacherMembers extends TeacherMembersController {
    // Customizable Area Start
    render() {
        const { allTeacherMembers, selectAll } = this.state;
        return (
            <>
                {this.state.showSpinner && (
                    <Spinner spinnerModal={this.state.showSpinner} />
                )}

                <Box className='mt20'>
                    <SelectedTab isTeacher={true} type={'Members'} />
                </Box>
                <Box className='mainBox membersParentContainer'>
                    <Box
                        className='flexBetween w100'
                    >
                        <Box
                            className='flexBetween w100'
                        >
                            <Box
                                className={`${this.props.classes.border_Color} searchBox`}

                            >
                                <Search className={`${this.props.classes.icon_color} searchIcon`}
                                    onClick={() => this.setState({ page: 0 }, () => {
                                        this.getTeachersList();
                                    })}
                                />

                                <input
                                    placeholder="Search for members…"
                                    className='inputBox'
                                    onChange={event => {
                                        this.setState({ queryTerm: event.target.value })
                                    }}
                                    value={this.state.queryTerm}
                                    onKeyDown={(e: any) => {
                                        if (e?.key === 'Enter') {
                                            this.setState({ page: 0 }, () => {
                                                this.getTeachersList();
                                            })
                                        }
                                    }}
                                />

                            </Box>

                        </Box>

                    </Box>


                    <Box className='flexRow filterBox2'>
                        {this.state.allTeacherMembers ? <img className="up_downArrow arrowImgBox" src={downArrow} /> : <img src={rightArrow} className="rightArrow" />}
                        <Typography className={`font_20 headingTitle ${this.props.classes.heading_font}`}>Admin & Teachers</Typography>
                    </Box>

                    <hr className='horizontalLine' />

                    {allTeacherMembers?.length === 0 ||
                        allTeacherMembers?.length === undefined ?
                        <Typography className={`font_18 noRecordText ${this.props.classes.bodyText_font}`}>No record(s) found. </Typography> :
                        <>
                            {true ?
                                <Grid container spacing={2} className="p10" >

                                    {allTeacherMembers?.map((item: any) => (
                                        <Grid item lg={3} md={4} sm={6} xs={12}>
                                            <TeacherMembersBox
                                                id={item.id}
                                                name={item.attributes?.type === "AdminAccount" ? item.attributes.first_name : `${item.attributes.first_name} ${item.attributes.last_name}`}
                                                imgSource={item.attributes.avatar}
                                                role={this.getRoleFromAccountType(item.attributes.type)}
                                                gender={item.attributes.gender}
                                                dateOfBirth={moment(item.attributes.date_of_birth).format("DD/MM/YYYY")}
                                                handleCheckboxClick={this.handleCheckboxClick}
                                                isSelected={this.isSelected(item.id)}
                                                selectAll={this.state.selectAll}
                                            />
                                        </Grid>
                                    ))}
                                    <ReactPaginate
                                        previousLabel={"←   Previous"}
                                        nextLabel={"Next   →	"}
                                        breakLabel="..."
                                        initialPage={this.state.page}
                                        pageCount={this.state.teacherMembersMeta?.pagination?.total_pages}
                                        onPageChange={(e: any) => {
                                            if (e.selected !== this.state.page) {
                                                this.setState({ page: e.selected }, () => this.getTeachersList())
                                            }
                                        }}
                                        containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                                        previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                                        nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                                        disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                                        activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                                        pageRangeDisplayed={2}
                                        marginPagesDisplayed={10}
                                    />


                                </Grid>
                                :
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    mb="20px"
                                    padding="5px 0px"
                                >
                                    <TableContainer component={Paper}>
                                        <Table className="w650" aria-label="simple table">
                                            <TableHead className='bgColor'>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align="left">Role</TableCell>
                                                    <TableCell align="left">Gender</TableCell>
                                                    <TableCell align="left">Birthdate</TableCell>
                                                    <TableCell align="left"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {allTeacherMembers?.map((row: any, index: any) => {
                                                    const isSelected = this.isSelected(row.id);
                                                    return (

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={row.id}
                                                        >
                                                            <TableCell scope="row" className='w15'>
                                                                <Checkbox
                                                                    onClick={event =>
                                                                        this.handleCheckboxClick(event, row.id)
                                                                    }
                                                                    className="selectCheckbox checkboxColor"
                                                                    checked={isSelected || selectAll}
                                                                    size="small"
                                                                />
                                                            </TableCell>
                                                            <TableCell scope="row">
                                                                <Box display="flex" justifyContent="flex-start" alignItems="center">
                                                                    <Avatar
                                                                        src={row.attributes.avatar ? row.attributes.avatar : "/"}
                                                                        className="avatarImage"
                                                                        style={{
                                                                            backgroundColor: row.attributes.avatar && "#fff",
                                                                        }}
                                                                    />

                                                                    <Typography
                                                                        className="nameAttr"
                                                                    >
                                                                        {row.attributes.first_name} {row.attributes.last_name}
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography
                                                                    className='roleAttr'
                                                                >
                                                                    {this.getRoleFromAccountType(row.attributes.type)}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography
                                                                   className="genderAttr"
                                                                >
                                                                    {row.attributes.gender}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography
                                                                    className="genderAttr"
                                                                >
                                                                    {moment(row.attributes.date_of_birth).format("DD MMMM,YYYY")}

                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Image
                                                                    source={chat2Icon}
                                                                    style={{
                                                                        width: 28,
                                                                        height: 28,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            }</>
                    }




                </Box>

            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(TeacherMembers);
// Customizable Area End