// Customizable Area Start
import React, { useRef, useEffect } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  NativeSelect,
  TextField,
  FormLabel,
  InputBase,
  FormHelperText,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Typography,
  Grid,
} from "@material-ui/core";
import Datetime from "react-datetime";
import TeacherProfileController, {
  Props,
} from "./TeacherProfileController.web";
import { withStyles } from "@material-ui/core/styles";
import {
  genderList,
  nationalityList,
  religionList,
  languageList,
  categoryList,
} from "./utils/constants";
import { checkNameField, checkFieldLength } from "./utils/common";
import moment from "moment";
import "react-circular-progressbar/dist/styles.css";
import "./TeacherProfile.web.css";
import DeleteVideoModal from "./DeleteVideoModal.web";
import VideoUploadSuccessModal from "./VideoUploadSuccessModal.web";
import UploadVideoPercentageModal from "./UploadVideoPercentageModal.web";
import CreateVideoModal from "./CreateVideoModal.web";
import PreviewVideoModal from "./PreviewVideoModal.web";
import VideoRecordViaWebcamModal from "./VideoRecordViaWebcamModal.web";
import AlertModal from "../../alert/src/AlertModal.web";
import PreviewUploadedVideo from "./PreviewUploadedVideo.web";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    backgroundColor: "none",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ebebeb",
    paddingLeft: 14,
    height: 35,
  },
  error: {
    borderColor: "red !important",
  },
}))(InputBase);

const spinnerBar = {
  color: "rgba(255, 255, 255, 1)",
  height: "30px",
  width: "30px",
  margin: "auto",
} as const;
// Customizable Area End

export class PersonalInfo extends TeacherProfileController {
  // Customizable Area Start
  stopVideoRecording: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.stopVideoRecording = React.createRef();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  updateStateOnClose = () => {
    this.setState({ createVideo: {} }); 
  };

  today = moment();
  disableFutureDt = (current: any) => {
    return current.isBefore(this.today);
  };

  parseDate = (str: any) => {
    var m = str.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/);
    return (m) ? new Date(m[3], m[2] - 1, m[1]) : null;
  }

  handleDate = async (date: any) => {
    const prop = "date_of_birth"

    if (typeof (date) == "string") {
      const format = this.parseDate(date)
      if (date.trim().length != 10 && format == null) {

        await this.setState({
          teacherPersonalDetailsError: {
            ...this.state.teacherPersonalDetailsError,
            date_of_birth: true,
            messages: {
              ...this.state.teacherPersonalDetailsError.messages,
              date_of_birth: "The date format must be dd-mm-yyyy",
            },

          }
        })
        this.setState({
          teacherPersonalDetails: {
            ...this.state.teacherPersonalDetails,
            attributes: {
              ...this.state.teacherPersonalDetails.attributes,
              date_of_birth: date
            },
          }
        })

      }

    } else {
      const format = moment(date).format('YYYY-MM-DD')
      if (prop === 'date_of_birth') {
        const currentDate = moment().format('YYYY-MM-DD').replaceAll("-", "")
        const dateOfBirth = format.replaceAll("-", "")

        if (currentDate < dateOfBirth) {
          await this.setState({
            teacherPersonalDetailsError: {
              ...this.state.teacherPersonalDetailsError,
              date_of_birth: true,
              messages: {
                ...this.state.teacherPersonalDetailsError.messages,
                date_of_birth: "date of birth can't be future date",
              },

            }
          })
        } else {
          await this.setState({
            teacherPersonalDetailsError: {
              ...this.state.teacherPersonalDetailsError,
              date_of_birth: false,
              messages: {
                ...this.state.teacherPersonalDetailsError.messages,
                date_of_birth: "",
              },
            }
          })
          this.setState({
            teacherPersonalDetails: {
              ...this.state.teacherPersonalDetails,
              attributes: {
                ...this.state.teacherPersonalDetails.attributes,
                date_of_birth: format
              },
            }
          })
        }
      }
    }

  }
  // Customizable Area End

  // Customizable Area Start
  render() {
    const {
      teacherPersonalDetails,
      organisationList,
      teacherPersonalDetailsError,
      occupation,
      classes,
      createVideo,
      openCreateVideoModal,
      uploadVideoPercentage,
      openPreviewVideoModal,
      introVideoFile,
      videoValidations
    } = this.state;
    const open = Boolean(this.state.videoAnchorEl);
    return (
      <>
        <form onSubmit={this.savePersonalInfo}>
          <div
            tabIndex={0}
            onKeyDown={this.handleEnter("savePersonalInfo")}
          >
            <Box className="personal_info_main_div">
              <Grid
                container
                direction="row"
                className="PersonalInfoContainer"
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={5}
                    className="PersonalInfoDetail"
                  >
                    <Grid item xs={12} sm={6}>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label_top ${this.props.classes.bodyText_font}`}>
                            Name <span className="PersonalInfoMandatory"> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={this.props.classes.textField}
                            variant={"outlined"}
                            size={"small"}
                            onKeyPress={(event) => checkNameField(event)}
                            onChange={this.inputEvent("name")}
                            value={teacherPersonalDetails?.attributes?.name}
                            error={teacherPersonalDetailsError?.name}
                            helperText={
                              teacherPersonalDetailsError?.messages?.name
                            }
                            InputProps={{ style: { borderRadius: 8 } }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} className="spacing_pt_0">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label_top ${this.props.classes.bodyText_font}`}>
                            Date of Birth
                            <span className="PersonalInfoMandatory"> *</span>
                          </FormLabel>
                        </Grid>

                        <Grid item xs={12}>
                          <Datetime
                            dateFormat={"DD-MM-YYYY"}
                            timeFormat={false}
                            strictParsing={true}
                            input={true}
                            value={
                              teacherPersonalDetailsError?.date_of_birth
                                ? teacherPersonalDetails?.attributes
                                  ?.date_of_birth
                                : teacherPersonalDetails?.attributes
                                  ?.date_of_birth &&
                                moment(
                                  teacherPersonalDetails?.attributes
                                    ?.date_of_birth
                                ).format("DD-MM-YYYY")
                            }
                            className={
                              teacherPersonalDetailsError?.date_of_birth
                                ? "input_date_of_birth_css_error_format"
                                : 
                                "input_date_of_birth_css_format"
                            }
                            inputProps={{
                              placeholder: "dd-mm-yyyy",
                              className: "StudentProfile Date_of_birth_calendar_Icon_register",
                            }}
                            onClose={() => {
                              if (
                                teacherPersonalDetails?.attributes
                                  ?.date_of_birth &&
                                teacherPersonalDetails?.attributes
                                  ?.date_of_birth.length === 0
                              ) {
                                let {
                                  teacherPersonalDetailsError,
                                } = this.state;
                                teacherPersonalDetailsError = {
                                  ...teacherPersonalDetailsError,
                                  date_of_birth: true,
                                  messages: {
                                    ...teacherPersonalDetailsError.messages,
                                    date_of_birth: "Date of birth is required.",
                                  },
                                };
                                this.setState({
                                  teacherPersonalDetailsError: teacherPersonalDetailsError,
                                });
                              }
                            }}
                            closeOnClickOutside
                            closeOnSelect
                            isValidDate={this.disableFutureDt}
                            onChange={(date) => {
                              this.handleDate(date);
                            }}
                          />
                         
                          <FormHelperText className="PersonalInfoErrorText">
                            {
                              teacherPersonalDetailsError?.messages
                                ?.date_of_birth
                            }
                          </FormHelperText>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={5}
                    className="PersonalInfoDetail"
                  >
                    <Grid item xs={12} sm={6} className="spacing_pb_0">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                            Gender<span className="PersonalInfoMandatory"> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <NativeSelect
                            input={<BootstrapInput />}
                            className={`${this.props.classes.selectDropDown} ${this.props.classes.bodyText_font}`}
                            inputProps={{
                              style: {
                                borderColor: teacherPersonalDetailsError?.gender
                                  ? "1px solid red"
                                  : "#ebebeb",
                              },
                            }}
                            value={`${teacherPersonalDetails?.attributes?.gender}`}
                            onChange={this.inputEvent("gender")}
                            error={teacherPersonalDetailsError?.gender}
                          >
                            <option value="" hidden></option>
                            {genderList.map((item: any) => (
                              <option value={item.value}> {item.label} </option>
                            ))}
                          </NativeSelect>
                          {teacherPersonalDetailsError?.gender && (
                            <FormHelperText className="PersonalInfoErrorText">
                              {teacherPersonalDetailsError?.messages?.gender}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} className="spacing_pt_0">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                            Email Id<span className="PersonalInfoMandatory"> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            id="email"
                            variant="outlined"
                            type="email"
                            value={teacherPersonalDetails?.attributes?.email}
                            onChange={this.inputEvent("email")}
                            error={teacherPersonalDetailsError?.email}
                            helperText={
                              teacherPersonalDetailsError?.messages?.email
                            }
                            className={this.props.classes.textField}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={5}
                    className="PersonalInfoDetail"
                  >
                    <Grid item xs={12} sm={6} className="spacing_pb_0">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                            Class
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={this.props.classes.textField}
                            size="small"
                            id="admissionClass"
                            variant="outlined"
                            type="admissionClass"
                            value={teacherPersonalDetails?.attributes?.preferred_classes.join(
                              ", "
                            )}
                            disabled
                          />
                         
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} className="spacing_pt_0">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                            Subjects
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={this.props.classes.textField}
                            size="small"
                            id="admissionClass"
                            variant="outlined"
                            type="admissionClass"
                            value={teacherPersonalDetails?.attributes?.preferred_subjects.join(
                              ", "
                            )}
                            disabled
                          />                         
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item xs={12} sm={6} >
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                            Self Video Introduction
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            className={`create-video-btn ${this.props.classes.button_color}`}
                            onClick={() => {
                              if (introVideoFile) {
                                this.setState({ openPreviewVideoModal: true });
                              } else {
                                this.setState({ openCreateVideoModal: true });
                              }
                            }}
                          >
                            <Typography className="create_video_btn_text" >
                              {introVideoFile
                                ? "Preview Video"
                                : "Create Video"}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item xs={12} sm={6} className="spacing_pb_0">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                            Mobile Number
                            <span className="PersonalInfoMandatory"> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={this.props.classes.textField}
                            type="number"
                            variant={"outlined"}
                            size={"small"}
                            onChange={this.inputEvent("home_phone_no")}
                            value={
                              teacherPersonalDetails?.attributes?.home_phone_no
                            }
                            onKeyPress={(event) => checkFieldLength(event, 15)}
                            error={teacherPersonalDetailsError?.home_phone_no}
                            helperText={
                              teacherPersonalDetailsError?.messages
                                ?.home_phone_no
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} className="spacing_pt_0">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                            Emergency Number
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={this.props.classes.textField}
                            variant={"outlined"}
                            size={"small"}
                            type="number"
                            error={teacherPersonalDetailsError?.office_phone_no}
                            helperText={
                              teacherPersonalDetailsError?.messages
                                ?.office_phone_no
                            }
                            onKeyPress={(event) => checkFieldLength(event, 15)}
                            onChange={this.inputEvent("office_phone_no")}
                            value={
                              teacherPersonalDetails?.attributes
                                ?.office_phone_no
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item xs={12} sm={6} className="spacing_pb_0">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                            Residential Address
                            <span className="PersonalInfoMandatory"> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={this.props.classes.textField}
                            variant={"outlined"}
                            size={"small"}
                            error={
                              teacherPersonalDetailsError?.residential_address
                            }
                            helperText={
                              teacherPersonalDetailsError?.messages
                                ?.residential_address
                            }
                            onChange={this.inputEvent("residential_address")}
                            value={
                              teacherPersonalDetails?.attributes
                                ?.residential_address
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} className="spacing_pt_0">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                            Nationality<span className="PersonalInfoMandatory"> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <NativeSelect
                            input={<BootstrapInput />}
                            className={`${this.props.classes.selectDropDown} ${this.props.classes.bodyText_font}`}
                            inputProps={{
                              style: {
                                borderColor: teacherPersonalDetailsError?.nationality
                                  ? "1px solid red"
                                  : "#ebebeb",
                              },
                            }}
                            value={`${teacherPersonalDetails?.attributes?.nationality}`}
                            onChange={this.inputEvent("nationality")}
                            error={teacherPersonalDetailsError?.nationality}
                          >
                            <option value="" hidden></option>
                            {nationalityList.map((item: any, ind: number) => (
                              <option value={item.value} key={ind}>
                                {item.label}
                              </option>
                            ))}
                          </NativeSelect>
                          {teacherPersonalDetailsError?.nationality && (
                            <FormHelperText className="PersonalInfoErrorText">
                              {
                                teacherPersonalDetailsError?.messages
                                  ?.nationality
                              }
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item xs={12} sm={6} className="spacing_pb_0">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                            Religion<span className="PersonalInfoMandatory"> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <NativeSelect
                            input={<BootstrapInput />}
                            className={`${this.props.classes.selectDropDown} ${this.props.classes.bodyText_font}`}
                            inputProps={{
                              style: {
                                borderColor: teacherPersonalDetailsError?.religion
                                  ? "1px solid red"
                                  : "#ebebeb",
                              },
                            }}
                            value={`${teacherPersonalDetails?.attributes?.religion}`}
                            onChange={this.inputEvent("religion")}
                            error={teacherPersonalDetailsError?.religion}
                          >
                            <option value="" hidden></option>
                            {religionList.map((item: any, ind: number) => (
                              <option value={item.value} key={ind}>
                                {item.label}
                              </option>
                            ))}
                          </NativeSelect>
                          {teacherPersonalDetailsError?.religion && (
                            <FormHelperText className="PersonalInfoErrorText">
                              {teacherPersonalDetailsError?.messages?.religion}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} className="spacing_pt_0">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                            Language known (Spoken)
                            <span className="PersonalInfoMandatory"> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <Select
                            variant="outlined"
                            className={this.props.classes.selectDropDown}
                            inputProps={{
                              style: {
                                borderColor: teacherPersonalDetailsError?.spoken_language
                                  ? "1px solid red"
                                  : "#ebebeb",
                              },
                            }}
                            value={
                              teacherPersonalDetails?.attributes
                                ?.spoken_language
                                ? teacherPersonalDetails?.attributes
                                  ?.spoken_language
                                : []
                            }
                            onChange={this.inputEvent("spoken_language")}
                            multiple
                            MenuProps={{ getContentAnchorEl: null }}
                            renderValue={() => {
                              return teacherPersonalDetails?.attributes?.spoken_language?.join(
                                ", "
                              );
                            }}
                            error={teacherPersonalDetailsError?.spoken_language}
                          >
                            {languageList.map((item: any, ind: number) => (
                              <MenuItem key={item.value} value={item.value}>
                                <ListItemText primary={item.label} />
                                <ListItemIcon>
                                  <Checkbox
                                    checked={
                                      teacherPersonalDetails?.attributes?.spoken_language?.indexOf(
                                        item.label
                                      ) > -1
                                    }
                                    style={{
                                      color:
                                        teacherPersonalDetails?.attributes?.spoken_language?.indexOf(
                                          item.label
                                        ) > -1
                                          ? "#af38cb"
                                          : "#3f526d",
                                    }}
                                  />
                                </ListItemIcon>
                              </MenuItem>
                            ))}
                          </Select>
                          {teacherPersonalDetailsError?.spoken_language && (
                            <FormHelperText className="PersonalInfoErrorText">
                              {
                                teacherPersonalDetailsError?.messages
                                  ?.spoken_language
                              }
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item xs={12} sm={6} className="spacing_pb_0">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                            Language known (Written)
                            <span className="PersonalInfoMandatory"> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <Select
                            variant="outlined"
                            className={`${this.props.classes.selectDropDown} ${this.props.classes.bodyText_font}`}
                            inputProps={{
                              style: {
                                borderColor: teacherPersonalDetailsError?.written_language
                                  ? "1px solid red"
                                  : "#ebebeb",
                              },
                            }}
                            value={
                              teacherPersonalDetails?.attributes
                                ?.written_language
                                ? teacherPersonalDetails?.attributes
                                  ?.written_language
                                : []
                            }
                            onChange={this.inputEvent("written_language")}
                            multiple
                            MenuProps={{ getContentAnchorEl: null }}
                            renderValue={() => {
                              return teacherPersonalDetails?.attributes?.written_language?.join(
                                ", "
                              );
                            }}
                            error={
                              teacherPersonalDetailsError?.written_language
                            }
                          >
                            {languageList.map((item: any, ind: number) => (
                              <MenuItem key={item.value} value={item.value}>
                                <ListItemText primary={item.label} />
                                <ListItemIcon>
                                  <Checkbox
                                    checked={
                                      teacherPersonalDetails?.attributes?.written_language?.indexOf(
                                        item.label
                                      ) > -1
                                    }
                                    style={{
                                      color:
                                        teacherPersonalDetails?.attributes?.written_language?.indexOf(
                                          item.label
                                        ) > -1
                                          ? "#af38cb"
                                          : "#3f526d",
                                    }}
                                  />
                                </ListItemIcon>
                              </MenuItem>
                            ))}
                          </Select>
                          {teacherPersonalDetailsError?.written_language && (
                            <FormHelperText className="PersonalInfoErrorText">
                              {
                                teacherPersonalDetailsError?.messages
                                  ?.written_language
                              }
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} className="spacing_pt_0">

                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="UpdatePersonalInfoDiv"
                >
                  <Button className={`teacher_update_btn ${this.props.classes.button_color_disabled}`}
                    style={{
                      opacity:
                        JSON.stringify(this.teacherPersonalDetailsCopy) ===
                          JSON.stringify(teacherPersonalDetails?.attributes)
                          ? 0.4
                          : 1,
                    }}
                    type="submit"
                    disabled={
                      JSON.stringify(this.teacherPersonalDetailsCopy) ==
                      JSON.stringify(teacherPersonalDetails?.attributes)
                    }
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>            
            </Box>
          </div>
        </form>

        <CreateVideoModal
          isTeacher={true}
          onCloseModal={() => {
            this.setState({ openCreateVideoModal: false });
          }}
          openCreateVideoModal={openCreateVideoModal}
          recordVideoClick={() => {
            this.setState({
              createVideo: { isCreateUploadVideo: "Create" },
              openCreateVideoModal: false,
            });
          }}
          openVideoBoothClick={() => {
            this.setState({
              createVideo: { isCreateUploadVideo: "Create" },
              openCreateVideoModal: false,
            });
          }}
          showOpenVideoFileDlg={this.showOpenVideoFileDlg}
          inputOpenVideoFileRef={this.inputOpenVideoFileRef}
          inputOnChange={(e: any) => this.handleVideoUpload(e, null)}
          onFileDrop={(files: any, event: any) => {
            window.addEventListener(
              "drop",
              function (e) {
                e.preventDefault();
              },
              false
            );
            this.handleVideoUpload(null, files[0]);
          }}
          videoDuration={videoValidations}
        />

        <VideoRecordViaWebcamModal
          handleSave={(mediaUrl: any) =>
            this.uploadVideo(mediaUrl, this.updateVideo)
          }
          handleClose={this.updateStateOnClose}
          handleOnBack={() => {
            this.setState({
              createVideo: { isCreateUploadVideo: false },
              openCreateVideoModal: true,
            });
          }}
          createVideo={createVideo}
          loadingSpinner={this.state.loadingSpinner}
          MaxVideoRecordingTime={videoValidations}
        />

        <PreviewUploadedVideo
          handleSave={() =>
            this.uploadVideo(createVideo.videoData, this.updateVideo)
          } 
          handleClose={this.updateStateOnClose}
          handleCancel={() => {
            this.setState({
              createVideo: {},
              openCreateVideoModal: true,
            });
          }}
          handleOnBack={() =>
            this.setState({ createVideo: {}, openCreateVideoModal: true })
          }
          createVideo={createVideo}
          loadingSpinner={this.state.loadingSpinner}
        />

        <VideoUploadSuccessModal
          isTeacher={true}
          openFileUploadSuccessModal={this.state.openFileUploadSuccessModal}
          onCloseModal={() => {
            this.setState({ openFileUploadSuccessModal: false });
          }}
          doneClick={() =>
            this.setState({
              openFileUploadSuccessModal: false,
            })
          }
        />

        <AlertModal
          alertModal={this.state.alertModal}
          isTeacher={true}
          onCloseModal={() => {
            this.setState({ alertModal: false });
          }}
          alertTitle="Alert!"
          alertMessage={this.state.alertMessage}
          buttonText="OK"
        />

        <UploadVideoPercentageModal
          isTeacher={true}
          uploadVideoPercentage={uploadVideoPercentage}
          onCloseModal={() => {
            this.setState({ uploadVideoPercentage: null });
          }}
          backArrowClick={() => {
            this.setState({
              uploadVideoPercentage: null,
              createVideo: {},
              openCreateVideoModal: true,
            });
          }}
        />

        <PreviewVideoModal
          isTeacher={true}
          onCloseModal={() => {
            this.setState({
              openPreviewVideoModal: false,
              videoAnchorEl: null,
            });
          }}
          openPreviewVideoModal={openPreviewVideoModal}
          videoAnchorEl={this.state.videoAnchorEl}
          openMenu={open}
          handleVideoModalClose={this.handleVideoModalClose}
          reUploadClick={() => {
            this.setState({
              videoAnchorEl: null,
              openPreviewVideoModal: false,
              openCreateVideoModal: true,
            });
          }}
          deleteClick={() => {
            this.setState({
              deleteVideoModal: true,
              openPreviewVideoModal: false,
              videoAnchorEl: null,
            });
          }}
          closeClick={() => {
            this.setState({
              openPreviewVideoModal: false,
              videoAnchorEl: null,
            });
          }}
          otherOptionsClick={(event: any) => this.handleVideoModalClick(event)}
          videoSrc={
            introVideoFile?.attributes?.url
              ? introVideoFile?.attributes?.url
              : introVideoFile
          }
          isIdAvailable={!!introVideoFile?.id}
        />

        <DeleteVideoModal
          isTeacher={true}
          deleteVideoModal={this.state.deleteVideoModal}
          onCloseModal={() => {
            this.setState({ deleteVideoModal: false });
          }}
          deleteVideoClick={this.deleteUploadedVideo}
        />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// TODO: Add this component in comman shared folder
const VideoPreview = ({
  stream,
  stop,
}: {
  stream: MediaStream | null;
  stop: any;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return (
    <video
      height="100%"
      ref={videoRef}
      autoPlay
      onTimeUpdate={(event) => {
        const momentDurationFormatSetup = require("moment-duration-format");
        momentDurationFormatSetup(moment);
        const currentTime = videoRef?.current?.currentTime;
        if (currentTime && currentTime >= 600) {
          stop();
        } else {
          const duration: any = moment.duration(currentTime, "seconds");
          const formatted = duration.format("mm:ss", { trim: false });
          const durtionEl = document.getElementById("recorderVideoPreview");
          durtionEl && (durtionEl.innerHTML = formatted);
        }
      }}
    />
  );
};

export const studentProfilePersonalInfoStyles = {
  selectDropDown: {
    width: "100%",
    "& .MuiSelect-root": {
      height: "50px",
      padding: "14px",
      boxSizing: "border-box",
      display: "flex",
      placeItems: "center",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "none",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#ebebeb",
      paddingLeft: 14,
      borderRadius: 8,
    },
    "&.Mui-error > .MuiInputBase-input": {
      borderColor: "red !important",
    },
  },
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "14px",
      height: "50px",
      boxSizing: "border-box",
      borderRadius: "8px",
      border: "solid 1px #ebebeb",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      "& fieldset": {
        borderColor: "#ebebeb",
      },
    },
  },
};
// Customizable Area End

// Customizable Area Start
const combineStyles = (...styles: any) => {
  return function CombineStyles(theme: any) {
    const outStyles = styles.map((arg: any) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc: any, val: any) => Object.assign(acc, val));
  };
}

const combinedStyle = combineStyles(themeCreateStyle, studentProfilePersonalInfoStyles);
export default withStyles(combinedStyle)(
  PersonalInfo as React.ComponentType<any>
);
// Customizable Area End