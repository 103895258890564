// Customizable Area Start
import { IBlock } from '../../../framework/src/IBlock';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import { runEngine } from '../../../framework/src/RunEngine';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { Message } from '../../../framework/src/Message';
export const configJSON = require('./config');
import { HISTORY } from '../../../components/src/comman';
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  confirmPassword: string;
  pswderror: boolean;
  pswderrorText: string;
  cpswderror: boolean;
  cpswderrorText: string;
  loadingSpinner: boolean;
  cpswdVisible: boolean;
  pswdVisible: boolean;
  role: string;
  openToolTip: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postLoginApiId: any;
  emailReg: RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      password: '',
      confirmPassword: '',
      pswderror: false,
      pswderrorText: '',
      cpswderror: false,
      cpswderrorText: '',
      loadingSpinner: false,
      cpswdVisible: false,
      pswdVisible: false,
      role: '',
      openToolTip: false,
    };
    this.emailReg = new RegExp('');
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
   }

   // Customizable Area Start
  togglePasswordVisible = () => {
    this.setState({ pswdVisible: !this.state.pswdVisible });
  };

  toggleConfirmPasswordVisible = () => {
    this.setState({ cpswdVisible: !this.state.cpswdVisible });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.postLoginApiId) {
        console.log('ID Match');
        if (responseJson != null) {
          console.log('JSON NOt NUll');
          if (!responseJson.errors) {
            console.log('Error Free');
            console.log(responseJson);
            this.setState({ role: responseJson.data.attributes.role });
            localStorage.setItem(
              'user_data',
              JSON.stringify(responseJson.data.attributes)
            );
            this.props.navigation.navigate('SucessfullPasswordChange');
            console.log(this.state.role);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false });
      }
    }
  }
  onPassswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ password: event.target.value });
  };
  onConfirmPassswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({ confirmPassword: event.target.value });
  };
  handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const pswdspaceReg = /^\S$|^\S[\s\S]*\S$/;
    const { password, confirmPassword } = this.state;
    if (password.length === 0 || confirmPassword.length === 0) {
      if (!pswdspaceReg.test(this.state.password)) {
        this.setState({
          pswderror: true,
          pswderrorText: "Your password can't start or end with a blank space.",
        });
      }

      if (this.state.password === null || this.state.password.length === 0) {
        this.setState({
          pswderror: true,
          pswderrorText: 'Password is required.',
        });
      }
      if (!pswdspaceReg.test(this.state.confirmPassword)) {
        this.setState({
          cpswderror: true,
          cpswderrorText:
            "Your password can't start or end with a blank space.",
        });
      }

      if (
        this.state.confirmPassword === null ||
        this.state.confirmPassword.length === 0
      ) {
        this.setState({
          cpswderror: true,
          cpswderrorText: 'Confirm password is required.',
        });
      }

      if (this.state.password != this.state.confirmPassword) {
        this.setState({
          cpswderror: true,
          cpswderrorText: 'Password and Confirm password must match. ',
        });
      }
      return false;
    }

    const data = {
      data: {
        token: localStorage.getItem('otptoken'),
        new_password: password.trim(),
      },
    };
    console.log(data);
    this.setState({ loadingSpinner: true });
    const header = {
      'Content-Type': configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postLoginApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    console.log('REQUEST: ', requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  backtologinhandle = () => {
    const userdata = localStorage.getItem('user_data');
    const data = JSON.parse(userdata || '{}');
    if (data.role === '21kadmin') {
      this.props.navigation.navigate('SuperAdminLogin');
    } else if (data.role === 'schooladmin') {
      this.props.navigation.navigate('SchoolAdminLogin');
    } else {
      this.props.navigation.navigate('EmailAccountLoginBlock');
    }
  };
  // Customizable Area End
}
