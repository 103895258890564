Object.defineProperty(exports, "__esModule", {
  value: true
});
const urlConfig = require("../../../framework/src/config");
// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard8/dashboards/get_teacher_classes";
exports.teacherSubjects = "/bx_block_dashboard8/dashboards/get_teacher_subjects";
exports.teacherAssignment = "bx_block_dashboard8/dashboard/get_teacher_classes_and_assessments";
exports.teacherNotes = "/bx_block_dashboard8/dashboards/get_notes";
exports.teacherUpcomingclasses = "/bx_block_dashboard8/dashboards/get_teacher_upcoming_classes";
exports.teacherLiveclasses ="bx_block_dashboard8/dashboards/get_teacher_live_classes";
exports.teacherCreateNotes ="bx_block_scheduling/notes";
exports.getteacherTimeTable ="/bx_block_dashboard8/dashboards/get_teacher_time_table";
exports.numberofStudents ="/bx_block_dashboard8/dashboards/get_teacher_students";
exports.studentSubjectGetUrl = "bx_block_dashboard8/dashboard/get_student_subjects";
exports.studentUpcomingClassesGetUrl = "/bx_block_dashboard8/dashboard/get_upcomming_classes_of_student";
exports.studentGoalsGetUrl = "/bx_block_dashboard8/dashboard/student_goals",
exports.studentProgressGetUrl = "/bx_block_dashboard8/dashboard/student_score",
exports.studentProgressUpdatedGetUrl = "/bx_block_dashboard8/dashboard/get_student_progress_data",
exports.studentNotesGetUrl = "/bx_block_scheduling/notes",
exports.studentCurveGetUrl = "/bx_block_dashboard8/dashboard/student_learning_curve_data", // "/bx_block_dashboard8/dashboards/get_student_learning_curve",
exports.studentAssessmentUrl = "/bx_block_dashboard8/dashboards/get_student_assessments",
exports.studentTimeTableUrl = "bx_block_dashboard8/dashboards/get_student_time_table",
exports.studentCreateNotesUrl = "bx_block_scheduling/notes",
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboarPOSTMethodType = "POST";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.CRUDNotesEndPoint ="/bx_block_scheduling/notes/";
exports.dashboarDELETEMethodType="DELETE";
exports.dashboarPUTMethodType="PUT";
exports.getTeacherSubjectEndPoint = "/bx_block_scheduling/subjects/subject_by_teacher";
exports.getTeacherGradeEndPoint = "bx_block_dashboard8/dashboard/get_teacher_grades";
exports.getTeacherDivisionEndPoint = "bx_block_dashboard8/dashboard/get_teacher_divisions_by_grade"
exports.getTeacherSubjectByGradeAndDivisionEndPoint = "bx_block_dashboard8/dashboard/get_teacher_subjects_by_grade_and_division"
exports.getTeacherUpcomingClassesEndPoint = "bx_block_dashboard8/dashboard/get_upcomming_classes_of_teacher"
exports.getReminderEndPoint = "account_block/general_events/get_reminder_quotes"
exports.getSchoolEventEndPoint = "account_block/general_events/get_school_events"
exports.getTeacherClassesEndPoint = "account_block/school_accounts/get_teacher_classes"
exports.teacherLiveClassesMakeHostEndPoint = "bx_block_scheduling/meetings/make_meeting_host";

exports.dashboardURL = urlConfig.dashboardURL;
exports.InstituteURL = urlConfig.instituteURL;
// Customizable Area End


