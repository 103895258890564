// Customizable Area Start
import React from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FileDrop } from 'react-file-drop';
import {
    Box,
    Button,
    TextField,
    Typography
} from "@material-ui/core";
import { fileuploaded } from './assets'
import { withStyles } from "@material-ui/core/styles";
import UploadClassController, { Props } from "./UploadClassController.web";
import 'react-calendar/dist/Calendar.css';
import CloseIcon from '@material-ui/icons/Close';
import AlertModal from "../../alert/src/AlertModal.web";
import './UploadClass.web.css'
import './AddClass.web.css'
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

export class UploadClass extends UploadClassController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {

        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="upload_class_flex_center"
                open={this.props.uploadClass}
                onClose={this.modalhandleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableAutoFocus={true}
            >
                <Fade in={this.props.uploadClass} style={{ border: "none" }}>
                    <div className="uploadclass-popup">
                        <Box
                            className="upload_class_flex_between"
                        >
                            <Typography
                                className={`${this.props.classes.title_font} upload_class_head_text`}
                            >
                                Upload File
                            </Typography>
                            <Box>
                                <CloseIcon className="upload_class_close_icon"
                                    onClick={this.modalhandleClose}
                                />
                            </Box>
                        </Box>
                        {
                            this.state.bulk_file.length === 0 ?
                                <>
                                    <FileDrop
                                        onDrop={(files: any, event: any) => {
                                            window.addEventListener("drop", function (e) {
                                                e.preventDefault();
                                            }, false);
                                            if (files[0]) {
                                                this.setState({ fileName: files[0].name })
                                                this.set_data(files[0])
                                            }

                                        }}
                                    >
                                        <Box
                                            className={`${this.props.classes.uploadClass_classAndSection_border_Color} upload_class_file_container`}
                                        >
                                            <Box className="upload_class_flex_center_row">
                                                <input
                                                    className="upload_class_d_none"
                                                    id="contained-button-file"
                                                    type="file"
                                                    onChange={(event: React.ChangeEvent<any>) => {
                                                        if (event.target.files[0]) {
                                                            var file = event.target.files[0];
                                                            this.setState({ fileName: file.name })
                                                            this.set_data(file)
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <Button
                                                        className={`${this.props.classes.button_color} upload_class_add_file_btn`}
                                                        component="span"
                                                    >
                                                        Add File
                                                    </Button>
                                                </label>
                                            </Box>
                                            <Typography
                                                className={`${this.props.classes.bodyText_font} upload_class_typo`}
                                            >
                                                Drag and drop files here or browse them from your computer.
                                            </Typography>
                                        </Box>
                                    </FileDrop>
                                </>
                                :
                                <>
                                    <FileDrop
                                        onDrop={(files: any, event: any) => {
                                            window.addEventListener("drop", function (e) {
                                                e.preventDefault();
                                            }, false);
                                            if (files[0]) {
                                                this.setState({ fileName: files[0].name })
                                                this.set_data(files[0])
                                            }

                                        }}
                                    >
                                        <Box
                                            className={`${this.props.classes.uploadClass_classAndSection_file_border_Color} upload_class_uploader_box`}
                                        >
                                            <Box>
                                                <img src={fileuploaded} className="upload_class_file_upload_img" />
                                            </Box>
                                            <Box>
                                                <Typography
                                                    className={`${this.props.classes.subHeading_font} upload_class_heading`}
                                                >
                                                    File Uploaded
                                                </Typography>
                                            </Box>
                                            <Box className="upload_class_align">
                                                <Typography    
                                                    className={`${this.props.classes.subHeading_font} upload_class_text`}
                                                >
                                                    File Name
                                                </Typography>

                                                <TextField
                                                    id="standard-basic"
                                                    type="text"
                                                    value={this.state.fileName}
                                                    className="upload_class_text_field"
                                                    disabled={true}
                                                    variant="outlined"
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: "Open sans",
                                                            fontSize: 15.8,
                                                            color: "#03014c",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box>
                                                <input
                                                    className="upload_class_d_none"
                                                    id="contained-button-file"
                                                    multiple
                                                    type="file"
                                                    onChange={(event: React.ChangeEvent<any>) => {
                                                        if (event.target.files[0]) {
                                                            var file = event.target.files[0];
                                                            this.setState({ fileName: file.name })
                                                            this.set_data(file)
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <Button variant="contained" component="span"
                                                        className={`${this.props.classes.button_color} upload_class_update_btn`}
                                                    >
                                                        Update
                                                    </Button>
                                                </label>
                                            </Box>
                                        </Box>
                                    </FileDrop>
                                </>
                        }

                        <Box className="upload_class_btn_container">
                            <Button
                                disableRipple={true}
                                variant="contained"
                                style={{
                                    opacity: this.state.bulk_file.length === 0 ? 0.36 : 1
                                }}
                                className={`${this.props.classes.button_color} upload_class_upload_btn`}
                                onClick={this.add_student_Class}
                            >
                                Upload
                            </Button>

                            <Button
                                disableRipple={true}
                                variant="contained"
                                className={`${this.props.classes.button_color_disabled} upload_class_cancel_btn`}
                                onClick={this.modalhandleClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                        <AlertModal
                            alertModal={this.state.alertModal}
                            isTeacher={false}
                            onCloseModal={() => { this.setState({ alertModal: false }) }}
                            alertTitle="Alert!"
                            alertMessage={this.state.alertMessage}
                            buttonText="OK"
                        />
                    </div>
                </Fade>

            </Modal>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(UploadClass);
// Customizable Area End
