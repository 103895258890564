// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { HISTORY } from "../../../components/src/comman";
import {
  writing1,
  writing2,
  writing3,
  writing4,
  writing5,
  writing6,
  writingLongAns,
  writingShortAns,
} from "./assets";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  assessmentDetail: any;
  sectionList: any;
  showLoader: boolean;
  selectedQuestion: any;
  openDeleteQuestionModal: boolean;
  openSelectQuestionTypeModal: boolean;
  objectiveQueTypeList: any;
  subjectiveQueTypeList: any;
  selectedQuestionType: any;
  selectedDataforMove: any;
  contextMenu: any;
  openSectionMenu: any;
  SectionNameText: any;
  questionsTypeList: any;
  targetSectionToaddQuestion: any;
  selectedSectionId: any;
  sectiondata: any;
  selectedTheme_id: string;
  alertModal: boolean;
  alertMessage: string;
  selectedQuestionSection: any;
  editQuestionData: any;
  questionDetails: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherAssessmentCreateSectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAssessmentDetail: string = "";
  apiUpdateAssessmentSaveAsDraft: string = "";
  getQuestionListId: string = "";
  deleteQuestionId: string = "";
  apiCopyQuestionId: string = "";
  apiGetQuestionsTypeList: string = "";
  apiMoveQuestionToSection: string = "";
  apiGetQuestionDetails: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      openSectionMenu: null,
      contextMenu: null,
      SectionNameText: null,
      targetSectionToaddQuestion: {},
      selectedDataforMove: [],
      assessmentDetail: {},
      selectedSectionId: "",
      sectiondata: {},
      selectedTheme_id: "",
      sectionList: [
        {
          id: 1,
          name: "Section - 1",
          questionsList: [
            {
              id: 1,
              question: "Q1. Lorem Ipsum is simply dummy text industry?",
              type: "MCQ's",
              marks: "+ 05",
              negMarks: "- 05",
            },
            {
              id: 2,
              question:
                "Q2. It is a long established fact that a reader will be distracted?",
              type: "MCQ's",
              marks: "+ 08",
              negMarks: "- 08",
            },
            {
              id: 3,
              question:
                "Q3. There are many variations of passages of Lorem Ipsum available?",
              type: "MCQ's",
              marks: "+ 02",
              negMarks: "- 02",
            },
            {
              id: 4,
              question: "Q4. Contrary to popular belief?",
              type: "MCQ's",
              marks: "+ 10",
              negMarks: "- 10",
            },
            {
              id: 5,
              question: "Q5. Lorem Ipsum is simply dummy text industry?",
              type: "MCQ's",
              marks: "+ 10",
              negMarks: "- 10",
            },
          ],
        },
        {
          id: 2,
          name: "Section - 2",
          questionsList: [
            {
              id: 1,
              question: "Q1. Lorem Ipsum is simply dummy text industry?",
              type: "True False",
              marks: "+ 05",
              negMarks: "- 05",
            },
            {
              id: 2,
              question:
                "Q2. It is a long established fact that a reader will be distracted?",
              type: "True False",
              marks: "+ 08",
              negMarks: "- 08",
            },
          ],
        },
      ],
      showLoader: false,
      selectedQuestion: null,
      openDeleteQuestionModal: false,
      openSelectQuestionTypeModal: false,
      objectiveQueTypeList: [
        {
          id: 1,
          imageSrc: writing1,
          title: "MCQ",
        },
        {
          id: 2,
          imageSrc: writing2,
          title: "True False",
        },
        {
          id: 3,
          imageSrc: writing3,
          title: "Fill in Blank",
        },
        {
          id: 4,
          imageSrc: writing4,
          title: "Match",
        },
        {
          id: 5,
          imageSrc: writing5,
          title: "One Word",
        },
        {
          id: 6,
          imageSrc: writing6,
          title: "Pictionary",
        },
      ],
      subjectiveQueTypeList: [
        {
          id: 1,
          imageSrc: writingLongAns,
          title: "Long Answer",
        },
        {
          id: 2,
          imageSrc: writingShortAns,
          title: "Short Answer",
        },
      ],
      selectedQuestionType: null,
      questionsTypeList: [],
      alertModal: false,
      alertMessage: "",
      selectedQuestionSection: null,
      editQuestionData: null,
      questionDetails: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.get_question_list = this.get_question_list.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetTargetSectionToAddQuestion = (
    targetSectionId: any,
    currentSectionId: string
  ) => {
    const assessmentData: any = JSON.parse(
      localStorage.getItem("assessmentData") || "{}"
    );

    const currentSectionIndex = assessmentData.assessment_sections_attributes.findIndex(
      (data: any) => data.id === currentSectionId
    );
    const targetSectionIndex = assessmentData.assessment_sections_attributes.findIndex(
      (data: any) => data.id === targetSectionId
    );

    const selectedQuestionIdArray: any = [];
    const SelectedQuestionArray = this.state.selectedDataforMove
      .filter(
        (item: any) =>
          item.checked &&
          item.section_id !== targetSectionId &&
          item.section_id === currentSectionId
      )
      .map((obj: any) => {
        const data = {
          id: obj.id,
          marks: obj.marks,
          negative_marks: obj.negative_marks,
          title: obj.title,
          type: obj.type,
        };
        selectedQuestionIdArray.push(data.id);
        return data;
      });

    // remove questions from current
    const remainingQuestionInCurrentSection = assessmentData.assessment_sections_attributes[
      currentSectionIndex
    ].question_data.filter(
      (item: any) => !selectedQuestionIdArray.includes(item.id)
    );
    assessmentData.assessment_sections_attributes[
      currentSectionIndex
    ].question_data = remainingQuestionInCurrentSection;
    // remove questions from current END

    // Updating remaining marks
    const totalQuestionMarksCurrent = assessmentData.assessment_sections_attributes[
      currentSectionIndex
    ].question_data.reduce((sum: any, current: any) => sum + current.marks, 0);

    assessmentData.assessment_sections_attributes[
      currentSectionIndex
    ].remaining_marks =
      assessmentData.assessment_sections_attributes[currentSectionIndex]
        .section_marks - totalQuestionMarksCurrent;
    // Updating remaining marks END

    // move selected questions to target section
    const existingQuestionsInTarget =
      assessmentData.assessment_sections_attributes[targetSectionIndex]
        ?.question_data;
    assessmentData.assessment_sections_attributes[
      targetSectionIndex
    ].question_data = [
        ...(existingQuestionsInTarget ? existingQuestionsInTarget : []),
        ...SelectedQuestionArray,
      ];
    // move selected questions to target section END

    // Updating remaining marks
    const totalQuestionMarksTarget = assessmentData.assessment_sections_attributes[
      targetSectionIndex
    ].question_data.reduce(
      (sum: number, current: any) => sum + Number(current.marks),
      0
    );

    assessmentData.assessment_sections_attributes[
      targetSectionIndex
    ].remaining_marks =
      assessmentData.assessment_sections_attributes[targetSectionIndex]
        .section_marks - totalQuestionMarksTarget;
    // Updating remaining marks END

    // Updating state & storage
    this.setState({
      assessmentDetail: assessmentData,
      selectedDataforMove: [],
    });
    localStorage.setItem("assessmentData", JSON.stringify(assessmentData));
    // Updating state & storage END

    this.handleClose();
  };

  handleClose = () => {
    this.setState({
      contextMenu: null,
    });
  };

  handleSectionMenu = (event: any) => {
    event.preventDefault();
    this.setState({
      openSectionMenu: event.currentTarget,
    });
  };
  handleMoveQuestiontoSectionResponse = () => {
    this.setState({
      selectedDataforMove: [],
    });
    this.getAssessmentDetail();
  };
  handleSectionMenuClose = () => {
    this.setState({
      openSectionMenu: null,
    });
  };
  handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    this.setState({
      contextMenu:
        this.state.contextMenu === null
          ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    });
  };

  handleDeleteQuestion = (response: any) => {
    if (response) {
      toast.success("Question deleted successfully");
      this.getAssessmentDetail();
      this.setState({
        openDeleteQuestionModal: false,
        selectedQuestion: null,
        selectedQuestionSection: null,
      });
    } else {
      toast.error("something went wrong");
      this.setState({ showLoader: false });
    }
  };

  handleEditQuestion = () => {
    const data = this.state.editQuestionData;
    const target = data?.questionData?.type.toLowerCase();

    switch (target) {
      case "long answer":
        HISTORY.push({
          pathname: "/LongAnswerTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "mcq":
        HISTORY.push({
          pathname: "/McqAnswerTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "short answer":
        HISTORY.push({
          pathname: "/ShortAnswerTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "true false":
        HISTORY.push({
          pathname: "/TrueFalseTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "one word":
        HISTORY.push({
          pathname: "/OneWordTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "fill in blank":
        HISTORY.push({
          pathname: "/FillInTheBlanksTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "pictionary":
        HISTORY.push({
          pathname: "/PictionaryTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "match":
        HISTORY.push({
          pathname: "/MatchTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "text_rating_scale":
        HISTORY.push({
          pathname: "/TextRatingTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "percentage_rating_scale":
        HISTORY.push({
          pathname: "/PercentageRatingTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "numeric_rating_scale":
        HISTORY.push({
          pathname: "/NumericRatingTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "graphic_rating_scale":
        HISTORY.push({
          pathname: "/EmojiRatingTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "graph_plotting":
        HISTORY.push({
          pathname: "/GraphPlottingTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "line_chart":
        HISTORY.push({
          pathname: "/GraphPlottingLineChart",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "video":
        HISTORY.push({
          pathname: "/VideoTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "audio":
        HISTORY.push({
          pathname: "/AudioTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "sort_list":
        HISTORY.push({
          pathname: "/SortingTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "label_image_with_drag_and_drop":
        HISTORY.push({
          pathname: "/LabelImagewithDragAndDrop",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "label_image_with_dropdown":
        HISTORY.push({
          pathname: "/LabelImagewithDropDown",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      case "label_image_with_text":
        HISTORY.push({
          pathname: "/LabelImagewithTextInput",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
        case "hotspot":
          HISTORY.push({
            pathname: "/HotspotTypeQuestion",
            state: {
              questionData: this.state.questionDetails,
              sectiondata: data.sectiondata,
              sectionId: data.sectiondata?.id,
              isEdit: true,
            },
          });
          break;
        case "flash_card":
            HISTORY.push({
              pathname: "/FlashCardTypeQuestion",
              state: {
                questionData: this.state.questionDetails,
                sectiondata: data.sectiondata,
                sectionId: data.sectiondata?.id,
                isEdit: true,
              },
            });
            break;
      case "2x2_grid":
        HISTORY.push({
          pathname: "/TwoByTwoGridTypeQuestion",
          state: {
            questionData: this.state.questionDetails,
            sectiondata: data.sectiondata,
            sectionId: data.sectiondata?.id,
            isEdit: true,
          },
        });
        break;
      default:
        break;
    }
  };

  handleGoToLibrary = (data: any) => {
    data.import_from_library = true;
    localStorage.setItem("assessmentData", JSON.stringify(data));

    const selectedQuestionIds: any = [];
    if (data?.assessment_sections_attributes?.length) {
      data.assessment_sections_attributes.map((section: any) => {
        if (section?.question_data?.length) {
          section.question_data.map((question: any) => {
            selectedQuestionIds.push(Number(question.id));
            return question;
          });
        }
        return section;
      });
    }

    HISTORY.push({
      pathname: "/QuestionBank",
      state: {
        subject: data?.subject[0]?.attributes,
        currentClass: data?.grade[0],
        schoolClassId: data?.grade_id,
        selectedQuestionIds: selectedQuestionIds,
        sectiondata: this.state.sectiondata,
        sectionId: this.state.selectedSectionId,
      },
    });
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiGetAssessmentDetail) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              assessmentDetail: responseJson,
              showLoader: false,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiUpdateAssessmentSaveAsDraft) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const assessmentData: any = JSON.parse(
              localStorage.getItem("assessmentData") || "{}"
            );
            if (responseJson.data?.id) {
              assessmentData.assessment_id = responseJson.data?.id;

              // Updating Sections
              responseJson.data?.attributes?.assessment_sections?.data?.map(
                (item: any, index: number) => {
                  assessmentData.assessment_sections_attributes[
                    index
                  ].section_id = item.id;
                }
              );
              // Updating Sections END

              // removing deleted sections
              assessmentData.deleted_sections = [];
              // removing deleted sections END

              // Updating Guidelines
              responseJson.data?.attributes?.assessment_guide_lines?.map(
                (item: any, index: number) => {
                  assessmentData.assessment_guidelines[index].guideline_id =
                    item.id;
                }
              );
              // Updating Guidelines END

              // removing deleted guidelines
              assessmentData.guideline_to_destroy = [];
              // removing deleted guidelines END
            } else if (responseJson.assessment?.id) {
              assessmentData.assessment_id = responseJson.assessment?.id;
            }
            // Updating state & storage
            this.setState({
              assessmentDetail: assessmentData,
            });
            localStorage.setItem(
              "assessmentData",
              JSON.stringify(assessmentData)
            );
            // Updating state & storage END
            toast.success("Saved as draft successfully.");
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({
          showLoader: false,
        });
      } else if (apiRequestCallId === this.deleteQuestionId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.handleDeleteQuestion(responseJson);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiGetQuestionsTypeList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ questionsTypeList: responseJson?.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiMoveQuestionToSection) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.handleMoveQuestiontoSectionResponse();
          } else {
            this.setState({
              showLoader: false,
            });
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getQuestionListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              showLoader: false,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiCopyQuestionId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.updateSectionQuestion(responseJson.data);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else if (apiRequestCallId === this.apiGetQuestionDetails) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ questionDetails: responseJson?.data?.[0] }, () =>
              this.handleEditQuestion()
            );
          } else {
            this.parseApiErrorResponse(responseJson);
          }
          this.setState({ showLoader: false });
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
      // Customizable Area End
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getQuestionsTypeList();
    // local theme
    const assessmentData = localStorage.getItem("assessmentData");
    if (assessmentData !== null) {
      const data = JSON.parse(assessmentData || "{}");
      this.setState({
        assessmentDetail: data,
      });
    } else {
      // redirect back to create assessment
      HISTORY.push({
        pathname: "/TeacherAssessmentCreateDetail",
      });
      // redirect back to create assessment END
    }
    // local theme END
  }

  handleBack = () => {
    HISTORY.push({
      pathname: "/TeacherAssessmentCreateTheme",
    });
  };

  move_Question = () => {
    this.setState({
      showLoader: true,
    });
    const SelectedQuestionArray = this.state.selectedDataforMove
      .filter((item: any) => item.checked)
      .map((obj: any) => obj.id);
    const token = localStorage.getItem("token");
    const assessmentId = JSON.parse(localStorage.getItem("assessmentId") || "");
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id,
    };
    const httpBody = {
      assessment_id: JSON.parse(assessmentId),
      question_ids: SelectedQuestionArray,
      current_section_id: JSON.parse(this.state.SectionNameText),
      new_section_id: JSON.parse(this.state.targetSectionToaddQuestion),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiMoveQuestionToSection = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiMoveQuestionsToSection
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAssessmentDetail = () => {
    this.setState({
      showLoader: true,
    });
    const token = localStorage.getItem("token");
    const assessmentId = JSON.parse(localStorage.getItem("assessmentId") || "");
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAssessmentDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAssessmentsEndPoint}/${assessmentId}/get_assessment_questions`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateAssessmentSaveAsDraft = () => {
    // work pending
    this.setState({
      showLoader: true,
    });
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const assessmentData: any = JSON.parse(
      localStorage.getItem("assessmentData") || "{}"
    );

    let requestType = configJSON.POST;
    let endPoint = `${configJSON.getAssessmentsEndPoint}`;

    if (assessmentData?.assessment_id) {
      requestType = configJSON.PUT;
      endPoint = `${configJSON.getAssessmentsEndPoint}/${assessmentData?.assessment_id}`;
    }

    // Section data
    let assessmentSections = assessmentData?.assessment_sections_attributes.map(
      (item: any) => {
        let questionIds = [];
        if (item?.question_data?.length > 0) {
          questionIds = item?.question_data.map((obj: any) => obj.id);
        }

        const data: any = {
          name: item?.name,
          section_marks: item?.section_marks,
          total_questions: item?.total_questions,
          time_limit: item?.time_limit,
          question_ids: questionIds,
        };

        if (item?.section_id) {
          data.id = item?.section_id;
        }

        return data;
      }
    );

    // pushing deleted sections
    if (assessmentData?.deleted_sections?.length > 0) {
      assessmentSections = [
        ...assessmentSections,
        ...assessmentData?.deleted_sections,
      ];
    }
    // pushing deleted sections END
    // Section data END

    // Guidelines data
    let assessmentGuidelines = assessmentData?.assessment_guidelines.map(
      (item: any) => {
        const data: any = {
          description: item.description,
          school_id: school_Data?.school_id,
        };
        if (item?.guideline_id) {
          data.id = item?.guideline_id;
        }
        return data;
      }
    );

    // pushing deleted guidelines
    if (assessmentData?.guideline_to_destroy?.length > 0) {
      assessmentGuidelines = [
        ...assessmentGuidelines,
        ...assessmentData?.guideline_to_destroy,
      ];
    }
    // pushing deleted guidelines END
    // Guidelines data END

    const httpBody: any = {
      assessment_name: assessmentData?.assessment_name,
      grade_id: assessmentData?.grade_id,
      assessment_theme_id: assessmentData?.assessment_theme?.id,
      subject_id: assessmentData?.subject_id,
      exam_type: assessmentData?.exam_type,
      maximum_marks: assessmentData?.maximum_marks,
      term_id: assessmentData?.term_id,
      negative_marking: assessmentData?.negative_marking,
      time_limit: assessmentData?.time_limit,
      difficulty_level: assessmentData?.difficulty_level,
      state: "draft",
      assessment_sections_attributes: assessmentSections
        ? assessmentSections
        : [],
      assessment_guide_lines_attributes: assessmentGuidelines
        ? assessmentGuidelines
        : [],
      assessment_category_id: assessmentData?.assessment_category_id,
    };

    this.apiUpdateAssessmentSaveAsDraft = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      requestType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onSelectQuestionType = (questionData: any) => {
    this.setState({
      selectedQuestionType: questionData,
      openSelectQuestionTypeModal: false,
    });

    if (questionData.name.toLowerCase() === "long answer") {
      HISTORY.push({
        pathname: "/LongAnswerTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "short answer") {
      HISTORY.push({
        pathname: "/ShortAnswerTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "mcq") {
      HISTORY.push({
        pathname: "/McqAnswerTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
          isEdit: false,
        },
      });
    } else if (questionData.name.toLowerCase() === "fill in blank") {
      HISTORY.push({
        pathname: "/FillInTheBlanksTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
          isEdit: false,
        },
      });
    } else if (questionData.name.toLowerCase() === "one word") {
      HISTORY.push({
        pathname: "/OneWordTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "true false") {
      HISTORY.push({
        pathname: "/TrueFalseTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "pictionary") {
      HISTORY.push({
        pathname: "/PictionaryTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    }
    else if (questionData.name.toLowerCase() === "match") {
      HISTORY.push({
        pathname: "/MatchTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    }
    else if (questionData.name.toLowerCase() === "text_rating_scale") {
      HISTORY.push({
        pathname: "/TextRatingTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    }
    else if (questionData.name.toLowerCase() === "graph_plotting") {
      HISTORY.push({
        pathname: "/GraphPlottingTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "line_chart") {
      HISTORY.push({
        pathname: "/GraphPlottingLineChart",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "percentage_rating_scale") {
      HISTORY.push({
        pathname: "/PercentageRatingTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "numeric_rating_scale") {
      HISTORY.push({
        pathname: "/NumericRatingTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "graphic_rating_scale") {
      HISTORY.push({
        pathname: "/EmojiRatingTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "video") {
      HISTORY.push({
        pathname: "/VideoTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    }
    else if (questionData.name.toLowerCase() === "audio") {
      HISTORY.push({
        pathname: "/AudioTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "sort_list") {
      HISTORY.push({
        pathname: "/SortingTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "label_image_with_drag_and_drop") {
      HISTORY.push({
        pathname: "/LabelImagewithDragAndDrop",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "label_image_with_text") {
      HISTORY.push({
        pathname: "/LabelImagewithTextInput",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "label_image_with_dropdown") {
      HISTORY.push({
        pathname: "/LabelImagewithDropDown",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "2x2_grid") {
      HISTORY.push({
        pathname: "/TwoByTwoGridTypeQuestion", state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "hotspot") {
      HISTORY.push({
        pathname: "/HotspotTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    } else if (questionData.name.toLowerCase() === "flash_card") {
      HISTORY.push({
        pathname: "/FlashCardTypeQuestion",
        state: {
          questionData: questionData,
          sectiondata: this.state.sectiondata,
          sectionId: this.state.selectedSectionId,
        },
      });
    }
  };

  get_question_list = () => {
    this.setState({ showLoader: true });
    const header = {
      "Content-Type": configJSON.dashboardContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.questionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteQuestionFromStorage = () => {
    const assessmentData = JSON.parse(
      localStorage.getItem("assessmentData") || "{}"
    );
    const { selectedQuestion, selectedQuestionSection } = this.state;

    // Removing question
    const sectionIndex = assessmentData.assessment_sections_attributes.findIndex(
      (data: any) => data.id === selectedQuestionSection?.id
    );
    const filteredQuestionArray = assessmentData.assessment_sections_attributes[
      sectionIndex
    ].question_data.filter((item: any) => item.id !== selectedQuestion?.id);
    assessmentData.assessment_sections_attributes[
      sectionIndex
    ].question_data = filteredQuestionArray;
    // Removing question END

    // updating remaining marks
    const totalQuestionMarks = assessmentData.assessment_sections_attributes[
      sectionIndex
    ].question_data.reduce((sum: any, current: any) => sum + current.marks, 0);
    assessmentData.assessment_sections_attributes[
      sectionIndex
    ].remaining_marks =
      assessmentData.assessment_sections_attributes[sectionIndex]
        .section_marks - totalQuestionMarks;
    // updating remaining marks END

    // setting new state
    this.setState({
      assessmentDetail: assessmentData,
      openDeleteQuestionModal: false,
      selectedQuestion: null,
      selectedQuestionSection: null,
      selectedDataforMove: [],
    });
    // setting new state END

    // Updating Storage Data
    localStorage.setItem("assessmentData", JSON.stringify(assessmentData));
    // Updating Storage Data END
  };

  updateSectionQuestion = (questionData: any) => {
    const { selectedQuestionSection } = this.state;
    const assessmentData = JSON.parse(
      localStorage.getItem("assessmentData") || "{}"
    );

    const question = {
      id: questionData.id,
      title: questionData?.attributes?.title,
      type: questionData?.attributes?.type?.name,
      marks: questionData?.attributes?.marks,
      negative_marks: questionData?.attributes?.negative_marks,
    };
    assessmentData.assessment_sections_attributes.map(
      (sectionData: any, index: number) => {
        if (sectionData.id === selectedQuestionSection.id) {
          // setting question data
          if (sectionData.question_data?.length > 0) {
            const qIndex = sectionData.question_data.findIndex(
              (data: any) => Number(data.id) === Number(question.id)
            );
            if (qIndex != -1) {
              sectionData.question_data[qIndex] = question;
            } else {
              sectionData.question_data.push(question);
            }
          } else {
            sectionData.question_data = [question];
          }
          // setting question data END

          // setting section marks
          const totalQuestionMarks = sectionData.question_data.reduce(
            (sum: any, current: any) => sum + current.marks,
            0
          );
          sectionData.remaining_marks =
            sectionData.section_marks - totalQuestionMarks;
          // setting section marks END
          return;
        }
      }
    );

    this.setState({
      assessmentDetail: assessmentData,
      selectedQuestion: null,
      selectedQuestionSection: null,
      showLoader: false,
    });
    localStorage.setItem("assessmentData", JSON.stringify(assessmentData));
  };

  delete_question = () => {
    this.setState({
      showLoader: true,
    });
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const assessmentId = this.state.assessmentDetail?.assessment?.id;
    const questionId = this.state.selectedQuestion?.id;
    const sectionId = this.state.selectedQuestionSection?.id;
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      school: school_Data.school_id,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteQuestionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteSectionQuestionEndPoint +
      `?assessment_id=${assessmentId}&section_id=${sectionId}&question_id=${questionId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  copyQuestion = () => {
    this.setState({
      showLoader: true,
    });
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      school: school_Data.school_id,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCopyQuestionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deletequestionEndPoint +
      `/create_question_copy?id=${this.state.selectedQuestion?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getQuestionsTypeList = () => {
    const token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetQuestionsTypeList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuestionsTypeListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNext = () => {
    let showAlert: boolean = false;
    let alertMessage: string = "";
    let sectionNameList: string = "";
  
    this.state.assessmentDetail?.assessment_sections_attributes?.map(
      (item: any) => {
        const questionAdded = item?.question_data
          ? item.question_data.length
          : 0;
        const totalQuestions = item.total_questions;
        const sectionName = item.name;

        const sectionMarks = item.section_marks
        let totalQuestionMarks: number = 0;
        item?.question_data?.map((question: any) => {
          totalQuestionMarks = totalQuestionMarks + Number(question.marks)
        })
        if(sectionMarks !== totalQuestionMarks) {
          if(sectionNameList) {
            sectionNameList = sectionNameList + " and " + sectionName
          } else{
            sectionNameList = sectionName
          }     
        }
       
        if (questionAdded !== totalQuestions) {
          showAlert = true;
          const remainingQuestions = totalQuestions - questionAdded;
          alertMessage =
            alertMessage +
            "Please add " +
            remainingQuestions +
            " more question(s) in " +
            sectionName +
            ". ";
        } else if (sectionMarks !== totalQuestionMarks) {
          showAlert = true;
          alertMessage = "Total marks of all questions should be equal to section total marks in " +
            sectionNameList + ". "; 
        }
      }
    );
    if (!showAlert) {
      this.props.navigation.navigate("AssessmentSummary");
    } else {
      this.setState({
        alertModal: true,
        alertMessage: alertMessage,
      });
    }
  };

  getQuestionDetails = (questionId: number) => {
    this.setState({ showLoader: true });
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetQuestionDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saveQuestionEndPoint}/${questionId}/get_question`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
