// Customizable Area Start
import React, { useState, useEffect } from "react";
import { Grid, Button, Typography, Divider } from "@material-ui/core";
import { chatOutlineBlueSVG } from "../assets";
import ObjectiveQuestionType from "./ObjectiveQuestionType.web";
import SubjectiveQuestionType from "./SubjectiveQuestionType.web";
import OneWordQuestionType from "./OneWordQuestionType.web";
import { ImagePreivewModal } from "../Components/ImagePreivewModal.web";
import TextEditor from "../AddQuestion/TextEditor/TextEditor.web";
import RatingScaleQuestionType from "./RatingScaleQuestionType.web";
import SortingQuestionType from './SortingQuestionType.web';
import MatchQuestionType from './MatchQuestionType.web';
import VideoQuestionType from './VideoQuestionType.web';
import AudioQuestionType from './AudioQuestionType.web';
import GraphPlottingQuestionType from './GraphPlottingQuestionType.web';
import LabelImageQuestionType from './LabelImageQuestionType.web';
import HotSpotQuestionType from './HotSpotQuestionType.web';
import FlashCardQuestionType from './FlashCardQuestionType.web';
import TwoByTwoGridQuestionType from './TwoByTwoGridQuestionType.web';
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
interface Props {
    // Customizable Area Start
    index: number;
    questionData: any;
    previewImageModal: boolean;
    previewImageModalClose: any;
    previewImageSrc: any;
    setPreviewImageSrc: any;
    isTeacher: boolean;
    setImageUrl?: any;
    classes?: any;
    // Customizable Area End
}

// Customizable Area Start
export const QuestionSection = ({
    index,
    questionData,
    previewImageModal,
    previewImageModalClose,
    previewImageSrc,
    setPreviewImageSrc,
    isTeacher,
    setImageUrl,
    classes,
}: Props) => {
    const [isOpenCommentBox, setIsOpenCommentBox] = useState(false);
    const [editorData, setEditorData] = useState(questionData?.selected_options?.data?.attributes?.comments);

    useEffect(() => {
        questionData.comments = questionData?.selected_options?.data?.attributes?.comments;
    }, [])

    const addCommentClick = () => {
        if(questionData?.selected_options?.data) {
            setIsOpenCommentBox(true)
        }
    }

    const doneCommentClick = () => {
        setIsOpenCommentBox(false)
    }

    const questionType = () => {
        switch (questionData?.question_type) {
            case 'mcq': return (
                <ObjectiveQuestionType questionData={questionData} setPreviewImageSrc={setPreviewImageSrc} isTeacher={isTeacher} />
            );
            case 'long answer': return (
                <SubjectiveQuestionType questionData={questionData} setPreviewImageSrc={setPreviewImageSrc} isTeacher={isTeacher} setImageUrl={setImageUrl} />
            );
            case 'short answer': return (
                <SubjectiveQuestionType questionData={questionData} setPreviewImageSrc={setPreviewImageSrc} isTeacher={isTeacher} setImageUrl={setImageUrl} />
            );
            case 'one word': return (
                <OneWordQuestionType questionData={questionData} isTeacher={isTeacher} />
            );
            case 'true false': return (
                <ObjectiveQuestionType questionData={questionData} setPreviewImageSrc={setPreviewImageSrc} isTeacher={isTeacher} />
            );
            case 'pictionary': return (
                <ObjectiveQuestionType questionData={questionData} setPreviewImageSrc={setPreviewImageSrc} isTeacher={isTeacher} />
            );
            case 'fill in blank':
                if (questionData?.text_input) {
                    return (<OneWordQuestionType questionData={questionData} isTeacher={isTeacher} />);
                }
                else {
                    return (<ObjectiveQuestionType questionData={questionData} setPreviewImageSrc={setPreviewImageSrc} isTeacher={isTeacher} />);
                }
            case 'text_rating_scale': return (
                <RatingScaleQuestionType questionData={questionData} isTeacher={isTeacher} textFieldType="text" />
            );
            case 'numeric_rating_scale': return (
                <RatingScaleQuestionType questionData={questionData} isTeacher={isTeacher} textFieldType="number" />
            );
            case 'percentage_rating_scale': return (
                <RatingScaleQuestionType questionData={questionData} isTeacher={isTeacher} textFieldType="number" isPercentageType={true} />
            );
            case 'graphic_rating_scale': return (
                <RatingScaleQuestionType questionData={questionData} isTeacher={isTeacher} textFieldType="text" isEmojiType={true} />
            );
            case 'sort_list': return (
                <SortingQuestionType questionData={questionData} isTeacher={isTeacher} />
            );
            case 'match': return (
                <MatchQuestionType questionData={questionData} isTeacher={isTeacher} />
            );
            case 'video': return (
                <VideoQuestionType questionData={questionData} isTeacher={isTeacher} />
            );
            case 'audio': return (
                <AudioQuestionType questionData={questionData} isTeacher={isTeacher} />
            );
            case 'graph_plotting': return (
                <GraphPlottingQuestionType questionData={questionData} isTeacher={isTeacher} />
            );
            case 'line_chart': return (
                <GraphPlottingQuestionType questionData={questionData} isTeacher={isTeacher} />
            );
            case 'label_image_with_drag_and_drop': return (
                <LabelImageQuestionType questionData={questionData} isTeacher={isTeacher} />
            );
            case 'label_image_with_dropdown': return (
                <LabelImageQuestionType questionData={questionData} isTeacher={isTeacher} />
            );
            case 'label_image_with_text': return (
                <LabelImageQuestionType questionData={questionData} isTeacher={isTeacher} />
            );
            case 'hotspot': return (
                <HotSpotQuestionType questionData={questionData} isTeacher={isTeacher} />
            );   
            case 'flash_card': return (
                <FlashCardQuestionType questionData={questionData} isTeacher={isTeacher} />
            );    
            case '2x2_grid': return (
                <TwoByTwoGridQuestionType questionData={questionData} isTeacher={isTeacher} />
            );     
            default:
                return null;
        }
    }

    return (
        <div>
            <Divider className="evaluation_question_divider" />

            <div className="question_row">
                <Typography className={`section_question_title ${classes.bodyText_font}`} dangerouslySetInnerHTML={{
                    __html: `Q${index + 1}. ${questionData?.title}`,
                }}>
                </Typography>
                <Typography className={`total_marks_text ${classes.theme_font}`}>
                    ({questionData?.marks} marks)
                </Typography>
            </div>

            {questionType()}

            {isTeacher &&
                <>
                    {!isOpenCommentBox ?
                        <div className={questionData?.selected_options?.data ? "add_comment_div" : "add_comment_div_disabled"} onClick={addCommentClick}>
                            <img src={chatOutlineBlueSVG} alt="chat" />
                            <Typography className={`add_comment_text ${classes.theme_font}`}>{editorData ? "Edit Comment" : "Add Comment"}</Typography>
                        </div>
                        :
                        <div className="long_ans_add_comment_div">
                            <Typography className={`correct_answer_text ${classes.heading_font}`}>{editorData ? "Edit Comment" : "Add Comment"}</Typography>
                            <div className="text_editor_div">
                                <TextEditor
                                    handleChange={(value: any) => {
                                        setEditorData(value);
                                        questionData.comments = value;
                                    }}
                                    editorData={editorData}
                                />
                            </div>
                            <div className="done_comment_div">
                                <Button className={`done_comment_btn ${classes.button_color} ${classes.theme_font}`} onClick={doneCommentClick}>Done</Button>
                            </div>
                        </div>
                    }
                </>
            }

            {previewImageModal && (
                <ImagePreivewModal
                    previewImageModal={previewImageModal}
                    modalhandleClose={() => previewImageModalClose()}
                    previewImage={previewImageSrc}
                />
            )}
        </div>
    );
};
// Customizable Area End

// Customizable Area Start

export default withStyles(themeCreateStyle)(QuestionSection);
// Customizable Area End
