// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
import { validForNumberType } from "./ScholasticAreasController.web";
import { toast } from "react-toastify";
export const configJSON = require("./config.js");
// Customizable Area End

// Customizable Area Start
export interface Props {
    classes?: any;
    setCoScholasticDetails?: any;
}

interface S {
    coScholasticListData: any;
    isLoader: boolean;
    data: any;
    studentData: any;
}
interface SS { }
// Customizable Area End

export default class CoScholasticAreasController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCoScholasticListId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

        this.state = {
            coScholasticListData: null,
            isLoader: false,
            data: [],
            studentData: null,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.get_co_scholastic_list = this.get_co_scholastic_list.bind(this);
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount()
        // Customizable Area Start
        if (HISTORY.location.state?.studentData) {
            this.setState({
                studentData: HISTORY.location.state?.studentData
            })
            this.get_co_scholastic_list(HISTORY.location.state?.studentData?.data?.id)
        }
        // Customizable Area End
    }

    // Customizable Area Start
    marksOnChange = (e: any, marksData: any, reportAttributeId: number) => {
        const { coScholasticListData } = this.state;
        const reportTopics = coScholasticListData?.attributes?.report_topics
        if (Math.floor(e.target.value) > 100) {
            toast.error("Marks should be less than 100.")
            return;
        }

        const updatedTopics = reportTopics?.map((topicData: any) => {
            topicData?.report_attributes?.map((data: any) => {
                data?.marks?.map((nestedMarks: any) => {
                    if (nestedMarks.term_id === marksData.term_id && data.id === reportAttributeId) {
                        nestedMarks.marks = e.target.value
                    }
                    return nestedMarks;
                })
                
                return data;
            })
            return topicData
        })
        coScholasticListData.attributes.report_topics = updatedTopics
        this.setState({ coScholasticListData: coScholasticListData })
        this.props.setCoScholasticDetails(coScholasticListData)
    }

    onKeyPressMarks = (e: any) => {
        if (!validForNumberType.includes(e.key) || e?.target?.value.length === 5) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);    
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.getCoScholasticListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (responseJson.data != null) {
                            if (responseJson.data != null) {
                                this.setState({
                                    coScholasticListData: responseJson.data,
                                    isLoader: false,
                                })
                            }
                            this.setState({
                                isLoader: false
                            })

                        }
                    } else {
                        this.parseApiErrorResponse(responseJson);
                        this.setState({ isLoader: false });
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ isLoader: false });
            }
        }
        // Customizable Area End
    }

    get_co_scholastic_list = (studentId: any) => {
        this.setState({ isLoader: true });
        const user_data = localStorage.getItem("user_data");
        const school_Data = JSON.parse(user_data || "{}");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("token"),
            school: school_Data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCoScholasticListId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.coScholasticListEndPoint + `?student_id=${studentId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.resultUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}
