export const GradeData = [
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",


    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },{
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },{
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },{
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    },
    {
        name : "A1",
        min : 91,
        max : 100,
        label : "Outs",
        

    }
]