// Customizable Area Start
import React from "react";
import { Typography, Grid, Button, Divider } from "@material-ui/core";
import CreateReportCardController, {
    Props,
} from "./CreateReportCardController.web";
import { editWhite, imgBackArrow } from "../assets";
import { withStyles } from '@material-ui/core/styles';
import Spinner from "../../../shared/SideBar/src/Spinner";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import './AdminReportCard.web.css';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
class CreateReportCardStep2 extends CreateReportCardController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { modulesList } = this.state;
        return (
            <>
                {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}

                <div className={`teacher_assessment_box`}>
                    <span className={`${this.props.classes.bodyText_font} back-text-report`} onClick={this.handleBack}>
                        <img src={imgBackArrow} className="back-arrow-img"></img>
                        Back
                    </span>

                    <div className="flex-space-between">
                        <Typography className={`create-custom-report-card-text ${this.props.classes.heading_font}`}>Create Custom Report Card</Typography>
                        <Button className={`module-edit-details-btn`}>
                            Edit details
                        </Button>
                    </div>

                    <Typography className={`report-step-text ${this.props.classes.heading_font}`}>Step 2: Complete Modules</Typography>

                    <Grid container spacing={4}>
                        {modulesList?.map((item: any) => (
                            <Grid item xs={12} sm={6} md={4} key={item?.id}>
                                <div className="report-module-box">
                                    <div className="module-detail-div flex-column">
                                        <span className={`module-text ${this.props.classes.title_font}`}>{item?.name}</span>
                                        {item?.description && <span className={`module-desc-text ${this.props.classes.subTitle_font}`}>{item?.description}</span>}
                                    </div>
                                    <Divider />
                                    <div className="report-bottom-btn-div">
                                        <Button className={`${this.props.classes.button_color} module-edit-btn`}>
                                            Edit
                                            <img src={editWhite}></img>
                                        </Button>

                                    </div>

                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </>
        );
    }
}

export const reportCardInfoStyles = {
    textField: {
        "& .MuiOutlinedInput-input": {
            padding: "14px",
            height: "50px",
            boxSizing: "border-box",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#ebebeb",
            },
        },
    },
    selectDropDown: {
        width: "100%",
        "& .MuiSelect-root": {
            height: "50px",
            padding: "14px",
            boxSizing: "border-box",
            display: "flex",
            placeItems: "center",
        },
        "& .MuiInputBase-input": {
            // height: "43px",
            backgroundColor: "none",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#ebebeb",
            paddingLeft: 14,
            borderRadius: 8,
        },
        "&.Mui-error > .MuiInputBase-input": {
            borderColor: "red !important",
        },
    },
};


export const themeCreateStyle = ((theme: any) => ({
    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: theme.props.buttonColor,
        }
    },
    image_Border_Color: {
        border: `3px solid ${theme.props.buttonColor}`
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
}))

const combineStyles = (...styles: any) => {
    return function CombineStyles(theme: any) {
        const outStyles = styles.map((arg: any) => {
            // Apply the "theme" object for style functions.
            if (typeof arg === 'function') {
                return arg(theme);
            }
            // Objects need no change.
            return arg;
        });

        return outStyles.reduce((acc: any, val: any) => Object.assign(acc, val));
    };
}

const combinedStyle = combineStyles(themeCreateStyle, reportCardInfoStyles);
export default withStyles(combinedStyle)(HOCAuth(CreateReportCardStep2, "AdminAccount"));
// Customizable Area End
