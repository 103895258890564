// Customizable Area Start
import React from "react";
import { Grid, Modal, Button, Typography } from "@material-ui/core";
import "./MeetingFeedbackOne.web.css";
import "./MeetingFeedbackTwo.web.css";
import MeetingFeedbackController from "./MeetingFeedbackController.web";
import { checkMark } from "../../assets";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
class MeetingFeedbackSuccessPopup extends MeetingFeedbackController {
  // Customizable Area Start
  render() {
    // console.log("feedback res", this.state.feedbackResponse);

    const { openModal, onCloseModal } = this.props;

    return (
      <>
        <Modal
          open={openModal}
          onClose={onCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            style={{
              justifyContent: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "20px",
              width: "600px",
            }}
            className="modal-width-90"
          >
            <div className="success-popup-container">
              <div className="success-popup-img">
                <img src={checkMark} alt="checkMark" />
              </div>
              <div className="success-popup-text">
                <Typography
                  variant="h5"
                  style={{
                    color: "#3f526d",
                    fontWeight: 600,
                    fontSize: "22px",
                    marginBottom: "10px",
                    letterSpacing: "1.30px",
                  }}
                >
                  Thank You
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    color: "#3f526d",
                    fontWeight: "normal",
                    fontSize: "16px",
                    letterSpacing: "1.30px",
                  }}
                >
                  Your form has been submitted successfully
                </Typography>
              </div>
              <div className="success-popup-button">
                <Button
                  className="form-btn"
                  style={{
                    width: "80px",
                    height: "40px",
                    marginRight: "10px",
                    borderRadius: "6",
                    border: "1px solid #af38cb",
                    background:
                      "linear-gradient(to top, #af38cb, #471c51 200%)",
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                  onClick={this.props.onCloseModal}
                >
                  <Typography style={{ color: "white" }}>OK</Typography>
                </Button>
              </div>
            </div>
          </Grid>
        </Modal>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default MeetingFeedbackSuccessPopup;
// Customizable Area End
