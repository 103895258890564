// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const GradebookIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
           <path d="M22.693 0c.636 0 1.153.517 1.153 1.154v12.308a1.154 1.154 0 0 1-2.307 0V2.308H2.308v25.384h7.075a1.154 1.154 0 0 1 0 2.308h-8.23A1.154 1.154 0 0 1 0 28.846V1.154C0 .517.517 0 1.154 0h21.539zm-8.881 14.784c.495 0 .94.302 1.125.761l3.235 8.087a1.213 1.213 0 0 1-2.253.902l-.804-2.013h-2.607l-.806 2.014a1.213 1.213 0 0 1-2.253-.903l3.235-8.087c.185-.459.631-.761 1.128-.761zm7.03 2.405c.67 0 1.213.544 1.213 1.213v.426h.426a1.213 1.213 0 0 1 0 2.426h-.426v.425a1.213 1.213 0 0 1-2.426 0v-.425h-.427a1.214 1.214 0 0 1-1.213-1.213c0-.67.545-1.213 1.213-1.213h.427v-.426c0-.67.544-1.213 1.213-1.213zm-7.03 2.073-.334.833h.667l-.333-.833zm3.495-13.108a1.154 1.154 0 0 1 0 2.307H6.538a1.154 1.154 0 0 1 0-2.307z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default GradebookIcon;
// Customizable Area End