export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const editDivision = require("../assets/divisionEdit.png")
export const deleteDivision = require("../assets/divisionDelete.png")
export const group = require("../assets/Group.png")
export const bitmap = require("../assets/bitmap.png")
export const moreIconBlack = require("../assets/more-vert-black-24-dp.png")
export const plusIcon = require("../assets/plus-icon.png")
export const uploadIcon = require("../assets/upload-icon.png")
export const deleteGrayIcon = require("../assets/delete-gray.png")
export const imgBackArrow = require("../assets/image-back-arrow.png")

// Admin report card
export const bubbleSquareImg = require("../assets/group-10.png");
export const bubbleImg2 = require("../assets/group-13.png");
export const createNewIcon = require("../assets/create-new-icon.png");
export const selectFromTemplate = require("../assets/select-from-template.png");
export const draftIcon = require("../assets/draft.png");
export const imageOptions = require("../assets/image-options.png");
export const editWhite = require("../assets/image-edit-white.png");
export const addIcon = require("../assets/add-icon.png");
export const editPurpleIcon = require("../assets/edit-purple.png");
export const nextWhiteArrow = require("../assets/next-white-arrow.png");
export const warningIcon = require("../assets/image-warning-icon.png");
export const deleteOutlineIcon = require("../assets/delete-outline-black-24-dp@2x.png");
export const addBigIcon = require("../assets/add-group-14.png");
export const editIcon = require("../assets/image-editicon@2x.png");
export const userIcon = require("../assets/user_image.png").default;
export const checkMarkIcon = require("../assets/image-check-mark-icon.png");
export const saveIcon = require("../assets/image-save-icon.png");
export const schoolLogo = require("../assets/image-school-logo.png");
// Customizable Area Start
// Customizable Area End