// Customizable Area Start
import React, { useRef, createRef, useState, useEffect } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import { checkFieldLengthForNumber } from "../../../profiles/src/utils/common";
import { showMarkerArea } from "../utils/common";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
interface Props {
    // Customizable Area Start
    questionData: any;
    setPreviewImageSrc?: any;
    isTeacher: boolean;
    setImageUrl?: any;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
const form_input_text_field = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
    fontSize: "20px",
    fontWeight: 600,
} as const;
// Customizable Area End

// Customizable Area Start
export const SubjectiveQuestionType = ({
    questionData,
    setPreviewImageSrc,
    isTeacher,
    setImageUrl,
    classes,
}: Props) => {

    const [marks, setMarks] = useState<any>(questionData?.selected_options?.data?.attributes?.score)
    const imgRef: any = useRef(questionData?.selected_options?.data?.attributes?.attachments?.data?.map(() => createRef()));

    useEffect(() => {
        if (!questionData?.selected_options?.data) {
            setMarks(0);
        }
    }, []);

    return (
        <Grid container>
            <Typography className={`ans_text ${classes.heading_font}`}>
                Ans :
            </Typography>
            <div className="long_answer_rectangle">
                <Typography className={`long_answer_text ${classes.subHeading_font}`}>{questionData?.selected_options?.data?.attributes?.answer_details}</Typography>
                {questionData?.selected_options?.data?.attributes?.attachments?.data?.length > 0 &&
                    <div className="display_flex">
                        {questionData?.selected_options?.data?.attributes?.attachments?.data?.map((item: any, index: number) => (
                            <div className="view_attach_file_box">
                                <Typography className={`textlabel_attachfile ${classes.bodyText_font}`}>{item?.attributes?.file_name}</Typography>
                               
                                <img
                                    ref={imgRef.current[index]}
                                    src={item?.attributes?.url}
                                    key={index}
                                    alt="answer-img"
                                    crossOrigin="anonymous"
                                    className="subjective_image"
                                    onClick={() => {
                                        if (isTeacher) {
                                            showMarkerArea(index, imgRef, questionData?.id, (id: number, url: string, file: any) => setImageUrl(id, url, file, item?.id), item?.attributes?.file_name)
                                        } else {
                                            setPreviewImageSrc(item?.attributes?.url)
                                        }
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                }
            </div>

            <div className="correct_answer_section">
                <Grid container>
                    <Grid item xs={12} sm={6} className="correct_answer_left">
                        {!questionData?.selected_options?.data &&
                            <Typography className={`not_attemped_text ${classes.bodyText_font}`}>Not attempted</Typography>
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} className="score_section">
                        <Typography className={`correct_answer_text ${classes.heading_font}`}>Score</Typography>
                        <div className="correct_answer_rectangle">
                            {isTeacher ?
                                <TextField
                                    placeholder=""
                                    type="number"
                                    value={marks}
                                    style={form_input_text_field}
                                    className={classes.input_theme_style}
                                    inputProps={{ className: "score_text" }}
                                    disabled={!questionData?.selected_options?.data}
                                    onChange={(e: any) => {
                                        if (Number(e.target.value) > Number(questionData?.marks)) {
                                            toast.error('Obtained marks should not greater than total marks')
                                        }
                                        setMarks(e.target.value)
                                        questionData.selected_options.data.attributes.score = e.target.value
                                    }}
                                    onKeyPress={(event: any) => checkFieldLengthForNumber(event, 3)}
                                />
                                :
                                <Typography className={`score_text ${classes.bodyText_font}`}>
                                    {questionData?.selected_options?.data?.attributes?.score ? questionData?.selected_options?.data?.attributes?.score : 0}
                                </Typography>
                            }
                        </div>
                        <Typography className={`score_text ${classes.bodyText_font}`}> / {questionData?.marks}</Typography>
                    </Grid>
                    {(!isTeacher && questionData?.selected_options?.data?.attributes?.comments) &&
                        <Grid item xs={12}>
                            <Typography className={`title_teachercomment ${classes.theme_font}`}>Teacher Comment:
                                <span className={`textlabel_teachercomment ${classes.heading_font}`} dangerouslySetInnerHTML={{
                                    __html: questionData?.selected_options?.data?.attributes?.comments,
                                }}></span></Typography>
                        </Grid>
                    }
                </Grid>
            </div>
        </Grid>
    );
};
// Customizable Area End

// Customizable Area Start

export default withStyles(themeCreateStyle)(SubjectiveQuestionType);
// Customizable Area End
