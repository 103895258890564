// Customizable Area Start
import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
  Box,
  Button,
  InputBase,
  TextField,
  Typography,
  NativeSelect,
} from '@material-ui/core';

import { ArrowDropDown } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import AddClassController, { Props } from './AddClassController.web';
import 'react-calendar/dist/Calendar.css';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FormHelperText from '@material-ui/core/FormHelperText';
import CommanSelectDiv from './CommanSelectDiv.web';
import DivisionSelectDiv from './DivisionSelectDiv.web';
import Spinner from '../../shared/SideBar/src/Spinner';
import './AddClass.web.css'
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class AddClass extends AddClassController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const BootstrapInput5 = withStyles((theme: any) => ({
      root: {
        'label + &': {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: 'relative',
        border: this.state.gradeerror
          ? 'solid 1px red'
          : `solid 1px ${theme.props.buttonColor}`,
        backgroundColor: '#fff',
        padding: '18px 22px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        fontSize: 20,
        '&:focus': {
          borderRadius: 4,
          borderColor: '#80bdff',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
      },
    }))(InputBase);
    const {isLoader} =this.state
    return (
      <>
      <Spinner spinnerModal={isLoader} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="add_class_modal_container"
        open={this.props.addClass}
        onClose={this.modalhandleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableAutoFocus={true}
      >
        <Fade in={this.props.addClass} style={{ border: 'none' }}>
          <div className='uploadclass-popup'
           
          >
            <Typography
              className={`${this.props.classes.title_font} add_class_modal_title`}
            >
              Add Class
            </Typography>
            <Box
              className="add_class_form_box"
            >
              <Box className='addclass-row add_class_mt_10'>
                <Typography
                  className={`${this.props.classes.subTitle_font} add_class_form_label`}
                >
                  Subject
                </Typography>
                <Box
                  className="add_class_cursor_pointer"
                  onClick={(event: any) => {
                    this.setState({
                      selectDropDownDiv: true,
                      dropDownAnchorEl: event.currentTarget,
                      data_Type: 'subject',
                    });
                  }}
                >
                  <Box
                    className={`add_class_select_options_inner_box
                      ${this.state.subjectError
                        ? this.props.classes.addClass_classAndSection_error_Border_Color
                        : this.props.classes.addClass_classAndSection_border_Color}
                    `}
                  >
                    {this.state.subject_data.length != 0 &&
                    this.state.subjectData.length != 0 ? (
                      <Box className="add_class_flex_box_row">
                        {this.state.subject_data.map(
                          (data: any, index: number) => (
                            <Box>
                              <Typography
                               className="add_class_input_text"
                              >
                                {
                                index === this.state.subject_data.length - 1 ? (
                                  <>{data.subject_name}</>
                                ) : (
                                  <>{data.subject_name},&nbsp;</>
                                )
                                }
                              </Typography>
                            </Box>
                          )
                        )}
                      </Box>
                    ) : (
                      <Typography
                       className="add_class_text"
                      >
                        Select Subject
                      </Typography>
                    )}
                    <Box className='add_class_flex_box'>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </Box>
                </Box>
                {this.state.subjectError && (
                  <FormHelperText
                    className="add_class_error_text"
                  >
                    Please select subject.
                  </FormHelperText>
                )}
              </Box>
              {this.state.selectDropDownDiv && (
                <Box className="add_class_full_width">
                  <CommanSelectDiv
                    dropDownAnchorEl={this.state.dropDownAnchorEl}
                    closeModalFun={this.dropdownClose}
                    datasaveModalFun={this.dataSaveClose}
                    data_Type={this.state.data_Type}
                    subjectData={this.state.subjectData}
                    editData={false}
                    navigation={undefined}
                    optionalSubjectData={undefined}
                  />
                </Box>
              )}
              <Box className='addclass-row add_class_mt_10'>
                <Typography
                  className={`${this.props.classes.subTitle_font} add_class_form_label`}
                >
                  Optional Subject
                </Typography>
                <Box
                  className='add_class_cursor_pointer'
                  onClick={(event: any) => {
                    this.setState({
                      optionalDropDownDiv: true,
                      optionaldropDownAnchorEl: event.currentTarget,
                      data_Type: 'optional_subject',
                    });
                  }}
                >
                  <Box
                    className={`${this.props.classes.addClass_classAndSection_border_Color} add_class_select_options_inner_box`}
                  >
                    {this.state.optional_data.length != 0 &&
                    this.state.optionalSubjectData.length != 0 ? (
                      <Box className='add_class_flex_box_row'>
                        {this.state.optional_data.map(
                          (data: any, index: number) => (
                            <Box>
                              <Typography
                               className="add_class_input_text"
                              >
                                {index ===
                                this.state.optional_data.length - 1 ? (
                                  <>{data.subject_name}</>
                                ) : (
                                  <>{data.subject_name},&nbsp;</>
                                )}
                              </Typography>
                            </Box>
                          )
                        )}
                      </Box>
                    ) : (
                      <Typography
                        className='add_class_text'
                      >
                        Select Optional Subject
                      </Typography>
                    )}
                    <Box className='add_class_flex_box'>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </Box>
                </Box>
              </Box>
              {this.state.optionalDropDownDiv && (
                <Box className='add_class_full_width'>
                  <CommanSelectDiv
                    dropDownAnchorEl={this.state.optionaldropDownAnchorEl}
                    closeModalFun={this.dropdownClose}
                    datasaveModalFun={this.dataSaveClose}
                    data_Type={this.state.data_Type}
                    optionalSubjectData={this.state.optionalSubjectData}
                    editData={false}
                    subjectData={undefined}
                    navigation={undefined}
                  />
                </Box>
              )}
              <Box className='addclass-row add_class_mt_10'>
                <Typography
                  className={`${this.props.classes.subTitle_font} add_class_form_label`}
                >
                  Class Name
                </Typography>
                <TextField
                  id="standard-basic"
                  type="text"
                  value={this.state.className}
                  autoComplete="off"
                  className='add_class_textfield'
                  variant="outlined"
                  inputProps={{
                    style: {
                      fontFamily: 'Open sans',
                      fontSize: 15.8,
                      color: '#03014c',
                    },
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value.trim();
                    if (value.length === 0) {
                      this.setState({
                        nameError: true,
                        nameErrorText: 'Name is required.',
                      });
                    } else {
                      this.setState({
                        nameError: false,
                        nameErrorText: '',
                      });
                    }
                    this.setState({ className: event.target.value });
                  }}
                  onBlur={() => {
                    if (
                      this.state.className === null ||
                      this.state.className.length === 0
                    ) {
                      this.setState({
                        nameError: true,
                        nameErrorText: 'Name is required.',
                      });
                    } else {
                      this.setState({
                        nameError: false,
                        nameErrorText: '',
                      });
                    }
                  }}
                  error={this.state.nameError}
                />
                <FormHelperText className='add_class_error_text'>
                  {this.state.nameErrorText}
                </FormHelperText>
              </Box>
              <Box className='addclass-row add_class_mt_10'>
                <Typography
                  className={`${this.props.classes.subTitle_font} add_class_form_label`}
                >
                  Grade
                </Typography>
                <NativeSelect
                  id="select"
                  value={this.state.grade_id}
                  input={<BootstrapInput5 />}
                  className="add_class_native_select"
                  IconComponent={ArrowDropDown}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    this.setState({
                      grade_id: event.target.value,
                      gradeerror: false,
                    });
                  }}
                  onBlur={() => {
                    if (
                      this.state.grade_id === null ||
                      this.state.grade_id.length === 0
                    ) {
                      this.setState({ gradeerror: true });
                    } else if (this.state.grade_id.length > 0) {
                      this.setState({ gradeerror: false });
                    } else {
                      this.setState({ gradeerror: false });
                    }
                  }}
                >
                  <option value="" className='add_class_opacity'>
                    Select Grade
                  </option>
                  {this.state.gradeData.map((data: any) => (
                    <>
                      <option value={data.id}>
                        {data.attributes.school_grade_name}
                      </option>
                    </>
                  ))}
                </NativeSelect>
                {this.state.gradeerror && (
                  <FormHelperText
                    className="add_class_error_text"
                  >
                    Please select grade.
                  </FormHelperText>
                )}
              </Box>
              <Box className="add_class_mt_10">
                <Typography
                  className={`${this.props.classes.subTitle_font} add_class_form_label`}
                >
                  Division
                </Typography>
                <Box
                  className="add_class_cursor_pointer"
                  onClick={(event: any) => {
                    this.setState({
                      divisionDropDownDiv: true,
                      divisiondropDownAnchorEl: event.currentTarget,
                    });
                  }}
                >
                  <Box
                    className={`add_class_select_options_inner_box
                      ${this.state.divisionError
                        ? this.props.classes.addClass_classAndSection_error_Border_Color
                        : this.props.classes.addClass_classAndSection_border_Color}
                    `}
                  >
                    {this.state.divisionData.length != 0 &&
                    this.state.division_Data.length != 0 ? (
                      <Box className="add_class_flex_box_row">
                        {this.state.division_Data.map(
                          (data: any, index: number) => (
                            <Box>
                              <Typography
                                className="add_class_input_text"
                              >
                                {index ===
                                this.state.division_Data.length - 1 ? (
                                  <>{data.attributes.name}</>
                                ) : (
                                  <>{data.attributes.name},&nbsp;</>
                                )}
                              </Typography>
                            </Box>
                          )
                        )}
                      </Box>
                    ) : (
                      <Typography
                        className="add_class_text"
                      >
                        Select Division
                      </Typography>
                    )}
                    <Box className="add_class_flex_box">
                      <KeyboardArrowDownIcon />
                    </Box>
                  </Box>
                  {this.state.divisionError && (
                    <FormHelperText
                      className="add_class_error_text"
                    >
                      Please select division.
                    </FormHelperText>
                  )}
                </Box>
              </Box>
            </Box>
            {this.state.divisionDropDownDiv && (
              <Box className='add_class_full_width'>
                <DivisionSelectDiv
                  divisiondropDownAnchorEl={this.state.divisiondropDownAnchorEl}
                  closeModalFun={this.dropdownClose}
                  datasaveDivisionModalFun={this.dvisionSaveClose}
                  division_Data={this.state.division_Data}
                  editData={false}
                  subjectData={undefined}
                  navigation={undefined}
                />{' '}
              </Box>
            )}
            <Box
             className='add_class_action_btn_container'
            >
              <Button
                className={`${this.props.classes.button_color} add_class_save_btn`}
                onClick={this.add_Class}
              >
                Save
              </Button>
              <Button
                className="add_class_cancel_btn"
                onClick={this.modalhandleClose}
              >
                Cancel
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(AddClass);
// Customizable Area End
