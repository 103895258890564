// Customizable Area Start
import React from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Box, Grid } from "@material-ui/core";
import { closeIcon } from "../assets";
import './StudentDocument.css'
// Customizable Area End
// Customizable Area Start

export const ImagePreview = (props: any) => {
    // Customizable Area Start
    const handleFileLoad =()=>{
        
    }
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.previewModal}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}

                disableAutoFocus={true}
            >
                <Fade in={props.previewModal} style={{ border: "none" }}>
                    <Grid
                        className="modal-width-90-image ImagePreviewGrid"
                    >
                        <Box className="ImagePreviewBox">
                            <img src={closeIcon} className="ImagePreviewCloseIcon" onClick={props.handleClose}/>
                            {props.previewImage.upload_type === "image/jpeg" ||
                                props.previewImage.upload_type === "image/jpg" ||
                                props.previewImage.upload_type === "image/png" ? (
                                <img
                                    className="UserPreviewImage"
                                    src={props.previewImage.doc_attachment}
                                onLoad={() => handleFileLoad()}
                                />
                            ) : (
                                <embed
                                    type="text/pdf"
                                    src={props.previewImage.doc_attachment}
                                    height="100%"
                                    width="100%"
                                />
                            )}
                        </Box>
                    </Grid>
                </Fade>
            </Modal>
        </>
    )
    // Customizable Area End
}

// Customizable Area End