// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  editModal: boolean;
  savecloseModalFun: any;
  closeModalFun: any;
  data: any;
  updateEvents: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  closeModal: boolean;
  title: string;
  subject: string;
  class: string;
  meetLink: string;
  lectureDate: string;
  lectureTime: string;
  description: string;
  titleErrorText: string;
  titleError: boolean;
  period_name: string;
  isMeetLink: boolean;
  userData: any;
  showSpinner: boolean;
  batch_id: string;
  week_day_school_period: string;

  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EditOnlineLectureController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateClassId: string = "";
  child: React.RefObject<any>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      closeModal: false,
      title: "",
      subject: "",
      class: "",
      meetLink: "",
      lectureDate: "",
      lectureTime: "",
      description: "",
      titleError: false,
      titleErrorText: "",
      period_name: "",
      isMeetLink: false,
      userData: [],
      showSpinner: false,
      week_day_school_period: "",
      batch_id: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.set_data();
  }

  set_data = () => {
    const { data } = this.props;
    const user_data = localStorage.getItem("user_data");
    const tempdata = JSON.parse(user_data || "{}");
    console.log("helllo", data);
    this.setState({
      userData: tempdata,
      title: data.title,
      subject: data.subject,
      class: data.class,
      description: data.description,
      lectureDate: moment(data.start).format("DD MMMM,YYYY"),
      lectureTime: moment(data.start).format("HH:mm A"),
      period_name: data.period_name,
      isMeetLink: data.is_online,
      week_day_school_period: data.week_day_school_period,
      batch_id: data.batch_id,
    });
  };
  modalhandleClose = () => {
    this.setState({ closeModal: true });
    console.log("modal closed", this.state.closeModal);
    this.props.closeModalFun(this.state.closeModal);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.updateClassId) {
        console.log("ID Match");
        if (responseJson != null) {
          console.log("JSON NOt NUll");
          if (!responseJson.errors) {
            this.setState({
              showSpinner: false,
            });
            toast.success("Lecture has been updated successfully");
            this.props.savecloseModalFun(true);
          } else {
            this.parseApiErrorResponse(responseJson);
            toast.error("something went wrong");
          }
        }
        console.log("responseJson nulll");
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  updateClass = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const userData = localStorage.getItem("user_data");
    const parsedUserData = JSON.parse(userData || "{}");
    console.log(parsedUserData);
    const {
      description,
      title,
      meetLink,
      week_day_school_period,
      batch_id,
      isMeetLink,
    } = this.state;
    if (title.length === 0) {
      if (title === null || title.length === 0) {
        this.setState({
          titleError: true,
          titleErrorText: "Tittle is required.",
        });
      }
      return false;
    }
    const data = {
      name: title,
      description: description,
      online_class_link: meetLink,
      school_id: parsedUserData.school_id,
      is_online: isMeetLink,
      week_day_school_period_id: week_day_school_period,
      batch_id: batch_id,
    };
    console.log("red", data);
    this.setState({
      showSpinner: true,
    });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateClassId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatedClassEndPoint + `${this.props.data.period_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.enrollApiPutMethodType
    );
    console.log("REQUEST: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
