// Customizable Area Start
import { Box, Button, Tabs, Typography, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { HISTORY } from "../../../components/src/comman";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export const SelectedTab = (props: any) => {
    // Customizable Area Start
    const [clickTab, setClickTab] = useState(props.type)
    const tabsList = [
        {
            key: 1,
            value: "subject",
            label: "Subject",
        },
        {
            key: 2,
            value: "taskandunit",
            label: "Task",
        },
        {
            key: 3,
            value: "timetable",
            label: "Time Table",
        },
        {
            key: 4,
            value: "attendance",
            label: "Attendance",
        },
        {
            key: 5,
            value: "resources",
            label: "Resources",
        },
        {
            key: 6,
            value: "members",
            label: "Members",
        },
        {
            key: 7,
            value: "gradebook",
            label: "Gradebook",
        },
    ];

    const handleSelectTab = (type: string) => {
        if (props.isTeacher) {
            if (type === 'Time Table') {
                HISTORY.push({
                    pathname: "/TeacherTimeTable",
                    state: {
                        type: type,
                    },
                });
            }
            else if (type === 'Gradebook') {
                HISTORY.push({
                    pathname: "/TeacherGradeBook",
                    state: {
                        type: type,
                    },
                });
            }
            else if (type === 'Resources') {
                HISTORY.push({
                    pathname: "/TeacherResources",
                    state: {
                        type: type,
                    },
                });
            }
            else if (type === 'Subject') {
                HISTORY.push({
                    pathname: "/TeacherSubject",
                    state: {
                        type: type,
                    },
                });
            }
            else if (type === 'Task') {
                HISTORY.push({
                    pathname: "/TeacherTaskUnit",
                    state: {
                        type: type,
                    },
                });
            } else if (type === 'Members') {
                HISTORY.push({
                    pathname: "/TeacherMembers",
                    state: {
                        type: type,
                    },
                });
            }
            else {
                HISTORY.push({
                    pathname: "/TeacherAttendance",
                    state: {
                        type: type,
                    },
                });
            }
        }
        else {
            if (type === 'Time Table') {
                HISTORY.push({
                    pathname: "/StudentTimeTable",
                    state: {
                        type: type,
                    },
                });
            }
            else if (type === 'Gradebook') {
                HISTORY.push({
                    pathname: "/StudentGradeBook",
                    state: {
                        type: type,
                    },
                });
            }
            else if (type === 'Resources') {
                HISTORY.push({
                    pathname: "/StudentResources",
                    state: {
                        type: type,
                    },
                });
            }
            else if (type === 'Subject') {
                HISTORY.push({
                    pathname: "/StudentSubject",
                    state: {
                        type: type,
                    },
                });
            }
            else if (type === 'Task') {
                HISTORY.push({
                    pathname: "/StudentTaskUnit",
                    state: {
                        type: type,
                    },
                });
            } else if (type === 'Members') {
                HISTORY.push({
                    pathname: "/StudentMembers",
                    state: {
                        type: type,
                    },
                });
            }
            else {
                HISTORY.push({
                    pathname: "/StudentAttendance",
                    state: {
                        type: type,
                    },
                });
            }
        }
    }
    return (
        <>
            <Box>
                <Box className="selected_tab_container">
                    <Tabs
                        value={"resources"}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {tabsList.map((item, i) => (
                            <Button
                                className={item.label === clickTab ? `${props.classes.button_color} tabsButton` : `${props.classes.button_color_disabled} tabsButton selected_tab_button`}
                                key={item.key}
                                onClick={() => {
                                    console.log("item.label", item.label)
                                    handleSelectTab(item.label);
                                    setClickTab(item.label)
                                }}

                            >
                                <Typography
                                    className="tabsButtonText selected_tab_label"
                                    style={{
                                        color: item.label === clickTab ? "white" : "#471c51",
                                    }}
                                >
                                    {item.label}
                                </Typography>
                            </Button>
                        )
                        )}
                    </Tabs>
                </Box>
            </Box>
        </>
    );
    // Customizable Area End
};
// Customizable Area Start
export default withStyles(themeCreateStyle)(SelectedTab);
// Customizable Area End
