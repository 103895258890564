// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import axios from "axios";
import * as SparkMD5 from "spark-md5";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: any;
  // Customizable Area Start
  classes: any;
  ref: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  resourceData: any;
  data: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  studentResourcetList: any;
  studentResourceFolders: any;
  classesList: any;
  subjectList: any;
  categoryList: any;
  teachersList: any;
  studentList: any;
  shareStudentList: any;
  anchorEl: any;
  openModal: boolean;
  selectedItem: string;
  selectedId: string;
  selectedIds: any;
  selectAll: boolean;
  selectedClass: any;
  selectedSubject: any;
  selectedStudens: any;
  selectedTeacher: any;
  selectedListType: string;
  modalType: string;
  selectedFiles: any,
  currentFile: any,
  progress: number,
  message: string,
  isError: boolean,
  fileInfos: any,
  categoriesName: string,
  createCategory: boolean,
  uploadResource: any,
  displayList: string,
  uploadPercentage: number,
  studentChecked: boolean,
  teacherChecked: boolean,
  parentChecked: boolean,
  shared_with: any,
  folderName: string,
  folderNameError: boolean;
  folderNameErrorMes: any;
  createSelectedAll: boolean,
  createSelected: any,
  fileSelectedAll: boolean,
  fileSelected: any,
  filefl: any[],
  tempfileval: any,
  queryTerm: string,
  viewFolders: any,
  viewResource: any,
  movetoFolderId: any,
  openDialog: boolean,
  fileToBeAdded: any
  showSpinner: boolean;
  listView: string
  alertMessage: any;
  alertModal: boolean;
  deleteResource: boolean;
  uploadFile: any;
  selectedFolderDetails: any;
  shareWithList: any;
  query: any;
  selectedFolderId: any;
  page: number;
  resourcesFoldersMeta: any;
  rowsPerPage:number;
  resourcesFilesMeta: any;
  activeFolderStyle: any;
  openFolder: boolean;
  activeFolder: any;
  changeview: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherResourcesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apigetStudentResourceId: string = "";
  apiCreateStudentResourceId: string = "";
  apiShowStudentResourceId: string = "";
  apiDeleteStudentResourceId: string = "";
  apiDeleteStudentSelectedId: string = "";
  apiDeleteAllId: string = "";
  apiShareStudentResourceId: string = "";
  apiGetUserStudentResourceId: string = "";
  apiUploadResourceId: string = "";
  apiViewUploadResourceId: string = "";
  apiShareWithUsers: string = "";
  apiRenameUploadResourceId: string = "";
  apiMakeaCopy: string = "";
  apiMoveTo: string = ""
  // for folders
  apigetStudentResourceFolderId: string = "";
  apigetStudentListId: string = "";
  apiCreateStudentResourceFolderId: string = "";
  apiDeleteStudentResourceFolderId: string = "";
  apiShowStudentResourceFolderId: string = "";
  apiGetUsersStudentResourceFolderId: string = "";
  apiSearchStudentResourceFolderId: string = "";
  apiShareStudentResourceFolderId: string = "";
  apigetCategoryList: string = '';
  apicreateCategory: string = '';
  apiOpenedBy: string = '';
  apicreateFolder: string = ''
  apiViewFolderResourceId: string = ""
  apiviewResourceFoldersFiles: string = ""
  apiRenameFolderResourceId: string = ""
  // for student and teacher list
  apiGetClassesId: string = "";
  apiGetClassStudentId: string = "";
  apiGetClassSubjectId: string = "";
  apiGetClassTeacherId: string = "";
  apiGetStudentsByClassAndSubjectId: string = "";
  apiPreSignedID: any = [];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleModalClick = this.handleModalClick.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.deleteStudentResource = this.deleteStudentResource.bind(this)
    this.deleteStudentResourceFolders = this.deleteStudentResourceFolders.bind(this)
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    this.state = {
      filefl: [],
      tempfileval: {},
      resourceData: [],
      data: '',
      errorMsg: "",
      anchorEl: null,
      token: "",
      loading: false,
      studentResourcetList: {},
      studentResourceFolders: {},
      classesList: {},
      subjectList: [],
      teachersList: {},
      studentList: {},
      shareWithList: [],
      shareStudentList: [],
      deleteResource: false,
      openModal: false,
      selectedItem: "",
      selectedId: "",
      selectedIds: [],
      categoryList: [],
      selectAll: false,
      selectedClass: null,
      selectedSubject: null,
      selectedStudens: null,
      selectedTeacher: null,
      selectedListType: "",
      folderNameError: false,
      folderNameErrorMes: "",
      modalType: "",
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 10,
      message: "",
      isError: false,
      fileInfos: [],
      categoriesName: '',
      createCategory: false,
      displayList: 'student',
      uploadFile: [],
      changeview: false,
      uploadResource: [
        {
          id: 1,
          value: {
            class: null,
            subject: null,
            category: null,
            sharewith: { student: false, teacher: false, parents: false }
          }
        }
      ],
      uploadPercentage: 0,
      studentChecked: false,
      teacherChecked: false,
      parentChecked: false,
      shared_with: [],
      folderName: "",
      createSelectedAll: false,
      createSelected: [],
      fileSelectedAll: false,
      fileSelected: [],
      queryTerm: "",
      viewFolders: [],
      viewResource: [],
      movetoFolderId: undefined,
      openDialog: false,
      fileToBeAdded: [],
      showSpinner: false,
      listView: "grid",
      alertMessage: '',
      alertModal: false,
      selectedFolderDetails: null,
      query: "",
      selectedFolderId: "",
      rowsPerPage: 12,
      page: 0,
      resourcesFoldersMeta: {},
      resourcesFilesMeta:{},
      activeFolderStyle: "",
      openFolder: false,
      activeFolder:""
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.getStudentResource = this.getStudentResource.bind(this);
    this.getStudentResourceFolders = this.getStudentResourceFolders.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.userdata = this.userdata.bind(this);
    this.handleSelectClass = this.handleSelectClass.bind(this)
    this.handleSelectSubject = this.handleSelectSubject.bind(this)
    this.handleSelectCategories = this.handleSelectCategories.bind(this)
    this.handleCreateCategory = this.handleCreateCategory.bind(this)
    this.handleCancelCategory = this.handleCancelCategory.bind(this)

  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.userdata();
    this.getStudentResource();
    this.viewStudentList();
    this.getStudentResourceFolders();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  }

  fileuploadapi() {
    return `${configJSON.attandanceURL}/bx_block_fileattachment/resource_uploads`
  }
  handleGrid = (type: string) => {
    //this.setState({ listView: type })
  }
  uploadFile = async (e: any) => {
    var data = new FormData();
    this.state.filefl.forEach((value, index) => {
      data.append(`resource_upload[][${index}][category_id]`, value.category_id);
      data.append(`resource_upload[][${index}][class_id]`, value.class_id);
      data.append(`resource_upload[][${index}][subject_id]`, value.subject_id);
      data.append(`resource_upload[][${index}][shared_with]`, value.share_with);
      data.append(`resource_upload[][${index}][uploaded_file]`, value.uploaded_file);
    });
    for (var pair of data.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    const token:any = localStorage.getItem('token')
    var resdata = await axios.post(this.fileuploadapi(), data, { headers: { token: token } })
    this.setState({
      openModal: false, filefl: [],
      tempfileval: {}, uploadResource: [
        {
          id: 1,
          value: {
            class: null,
            subject: null,
            category: null,
            sharewith: { student: false, teacher: false, parents: false }
          }
        }
      ],
    });
    // this.handleModalClick();
  }
  isSelected = (id: any) => this.state.createSelected.indexOf(id) !== -1;
  isFileSelected = (id: any) => this.state.fileSelected.indexOf(id) !== -1;

  getPreSignedId = async (fileUploadDetail: any) => {
    this.setState({ showSpinner: true })
    const { uploadFile } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      filename: fileUploadDetail.fileName,
      byte_size: fileUploadDetail.fileSize,
      checksum: fileUploadDetail.checksum,
      content_type: fileUploadDetail.content_type,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.SignIDUrl
    );

    this.apiPreSignedID[this.apiPreSignedID.length] = requestMessage.messageId;
    uploadFile.map((item: any) => {
      if (item.checksum === fileUploadDetail.checksum) {
        item.requestID = requestMessage.messageId;
      }
    });

    await this.setState({ uploadFile: uploadFile });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fileUploadEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    const result = runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUpload = (event: any, dragAndDrop: boolean, fileFromDragAndDrop: any) => {
    let allowUpload: boolean = true;
    const validFileType = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "application/pdf",
      "application/msword",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ];
    let files: any;
    if (dragAndDrop) {
      files = fileFromDragAndDrop
    }
    else {
      files = event.target.files
    }
    let totalSize: number = 0
    const keys2 = Object.keys(files)
    keys2?.map(async (key: any) => {
      const file = files[key]
      totalSize = totalSize + file.size
    })
    keys2?.map((key: any) => {
      const file = files[key];
      if (!validFileType.includes(file?.type)) {
        allowUpload = false;
        this.setState({
          alertMessage:
            "Please upload valid file format: .png .jpg .jpeg .doc xls or .pdf",
          alertModal: true,
        });

      }
    });
    if ((files.length + this.state.uploadFile.length) <= 10 && totalSize < 524288000) {
    } else {
      allowUpload = false;
      if (files.length + this.state.uploadFile.length > 10) {
        this.setState({ alertMessage: "Please upload maximum 10 files.", alertModal: true })
      } else {
        this.setState({ alertMessage: "Maximum files size: 500MB", alertModal: true })
      }
    }
    if (allowUpload) {
      const arr: any = this.state.uploadFile
      const keys = Object.keys(files);
      keys?.map(async (key: any) => {
        let fileUpload: any = {}
        const file: any = files[key];
        fileUpload = {
          fileName: file.name,
          name: file.name.split(".")[0],
          isExistingFile: false,
          fileSize: file.size,
          percentage: 0,
          file: file,
          content_type: file.type,
          localUrl: URL.createObjectURL(file)
        };
        arr.push(fileUpload)
        this.setState({ uploadFile: arr })
        let checksum = "";
        await this.computeChecksumMd5(file).then((md5: any) => {
          checksum = md5;
          fileUpload.checksum = checksum;
          this.getPreSignedId(fileUpload);
        });
      })
    }
  }

  computeChecksumMd5 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject("MD5 computation failed - error reading the file");
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  };

  saveImageUsingURL = async (preSignedResponse: any) => {
    const { uploadFile } = this.state;

    let ind: number = 0;
    uploadFile?.map((item: any, index: number) => {
      if (item.signedInId === preSignedResponse?.signed_id) {
        ind = index;
      }
    });

    const header:any = {
      "Content-Type": preSignedResponse?.headers?.["Content-Type"],
      "Content-MD5": preSignedResponse?.headers?.["Content-MD5"],
      token: localStorage.getItem("token"),
    };

    const httpBody = uploadFile[ind].file;

    try {
      const s3Response = await axios({
        method: "put",
        url: preSignedResponse.url,
        data: httpBody,
        headers: header,
      });
    } catch (e) {
      toast.error("Something went wrong.");

      uploadFile[ind].signedInId = "";
      uploadFile[ind].url = "";
      uploadFile[ind].checksum = "";
      uploadFile[ind].file = {};

      this.setState({ uploadFile: uploadFile });
    }
  };

  handleCheckboxClick = (event: any, id: any) => {
    event.stopPropagation();
    const { createSelected, studentResourceFolders } = this.state;
    const selectedIndex = createSelected.indexOf(id);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(createSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(createSelected.slice(1));
    } else if (selectedIndex === createSelected.length - 1) {
      newSelected = newSelected.concat(createSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        createSelected.slice(0, selectedIndex),
        createSelected.slice(selectedIndex + 1)
      );
    }

    this.setState({ createSelected: newSelected });
    if (newSelected?.length === studentResourceFolders?.length) {
      this.setState({ selectAll: true })
    } else {
      this.setState({ selectAll: false })
    }
  };
  handleCheckboxClickFile = (event: any, id: any) => {
    event.stopPropagation();
    const { fileSelected, studentResourcetList } = this.state;
    const selectedIndex = fileSelected.indexOf(id);
    let newFileSelected: any[] = [];

    if (selectedIndex === -1) {
      newFileSelected = newFileSelected.concat(fileSelected, id);
    } else if (selectedIndex === 0) {
      newFileSelected = newFileSelected.concat(fileSelected.slice(1));
    } else if (selectedIndex === fileSelected.length - 1) {
      newFileSelected = newFileSelected.concat(fileSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newFileSelected = newFileSelected.concat(
        fileSelected.slice(0, selectedIndex),
        fileSelected.slice(selectedIndex + 1)
      );
    }

    this.setState({ fileSelected: newFileSelected });
    if (newFileSelected?.length === studentResourcetList?.length) {
      this.setState({ selectAll: true })
    } else {
      this.setState({ selectAll: false })
    }
  };
  handleCloseResourceModal = () => {
    this.setState({
      openModal: false,
      categoriesName: '',
      selectedSubject: null,
      selectedClass: null,
      shared_with: [],
      selectedFiles: undefined,
      uploadPercentage: 0,
      studentChecked: false,
      teacherChecked: false,
      parentChecked: false,
      uploadResource: [{}]

    })
  }
  handleCloseModal = () => {
    this.setState({
      openModal: false,
    })
  }
  handleCheckBoxChange = (e: any, type: any) => {
    const {
      target: { checked },
    } = e;
    if (type === 'student') {

      this.setState({ studentChecked: !this.state.studentChecked, shared_with: [...this.state.shared_with, checked && 'student'] });
    } else if (type === 'teacher') {
      this.setState({ teacherChecked: !this.state.teacherChecked, shared_with: [...this.state.shared_with, checked && 'teacher'] });
    } else {
      this.setState({ parentChecked: !this.state.parentChecked, shared_with: [...this.state.shared_with, checked && 'parent'] });
    }
  };
  handleCheckBoxChange2 = (e: any, type: any, index: any) => {
    const {
      target: { checked },
    } = e;
    var temp = this.state.uploadResource;
    if (type === 'student') {
      this.state.tempfileval.share_with = "student";
      temp[index].value.sharewith.student = !temp[index].value.sharewith.student;
      this.setState({ uploadResource: temp });
    } else if (type === 'teacher') {
      this.state.tempfileval.share_with = "teacher";
      temp[index].value.sharewith.teacher = !temp[index].value.sharewith.teacher;
      this.setState({ uploadResource: temp })
    } else {
      this.state.tempfileval.share_with = "parent";
      temp[index].value.sharewith.parent = !temp[index].value.sharewith.parent;
      this.setState({ uploadResource: temp })
    }
  };
  handleFiles = (e: any) => {
    var temp = this.state.tempfileval;
    temp.uploaded_file = e.target.files[0];
    var temp2 = this.state.filefl;
    temp2.push(temp);

    this.handleFileUpload(e);
    this.setState({ tempfileval: temp, filefl: temp2 });
  }
  handleFileUpload = (e: any) => {
    this.setState({
      selectedFiles: e.target.files,
    })
    let data = new FormData();
    if (this.state.selectedFiles && this.state.selectedFiles.length > 0)
      data.append('file', this.state.selectedFiles && this.state.selectedFiles[0])
    const options = {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total)
        if (percent < 100) {
          this.setState({ uploadPercentage: percent })
        }
      }
    }
    this.setState({ uploadPercentage: 100 }, () => {

    })

  }
  handleAddMoreFile = () => {
    var max = this.state.uploadResource.reduce(function (prev: any, current: any) {
      if (+current.id > +prev.id) {
        return current;
      } else {
        return prev;
      }
    });
    const item = {
      id: max.id + 1,
      value: { class: null, subject: null, category: null, sharewith: { student: false, teacher: false, parents: false } }
    }
    this.setState({
      uploadResource: [
        ...this.state.uploadResource,
        item
      ]
    })
  }
  handleRemoveMoreFile = (id: any) => {
    this.setState({
      uploadResource: this.state.uploadResource.filter((item: any) => item.id !== id)
    })
  }
  handleModalClick = (type: any) => {
    this.setState({
      openModal: !this.state.openModal,
      modalType: type,
      folderNameErrorMes: '',
      folderName: '',
      folderNameError: false,
      filefl: [],
      tempfileval: {},
      uploadResource: [
        {
          id: 1,
          value: {
            class: null,
            subject: null,
            category: null,
            sharewith: { student: false, teacher: false, parents: false }
          }
        }
      ],
    })
  }
  handleCreateCategory = () => {
    this.setState({ createCategory: true })
  }
  handleCancelCategory = () => {
    this.setState({ createCategory: false })
  }
  handleSelectCategories2 = (event: any, index: any) => {
    const { target } = event;
    var temp = this.state.tempfileval;
    temp.category_id = parseInt(target.value);
    var temp2 = this.state.uploadResource;
    temp2[index].value.catagory = target.value;
    this.setState({ tempfileval: temp, uploadResource: temp2 });
  }
  handleSelectCategories = (event: any) => {
    const {
      target: { value },
    } = event;
    this.setState({
      categoriesName: value
    })
  }
  handleSelectClass2 = (event: any, index: any) => {
    const { target } = event;
    var temp = this.state.tempfileval;
    temp.class_id = parseInt(target.value);
    var temp2 = this.state.uploadResource;
    temp2[index].value.class = parseInt(target.value)
    this.setState({ tempfileval: temp, uploadResource: temp2 });
    this.getStudentSubject(parseInt(target.value))
    this.getTeacherByClassId(parseInt(target.value))
    this.getStudentByClassIdAndSubjectId(target.value)
  }
  handleSelectClass = (event: any) => {
    const {
      target: { value },
    } = event;
    this.setState({ selectedClass: parseInt(value) })
    this.getStudentSubject(parseInt(value))
    this.getTeacherByClassId(parseInt(value))
  }
  handleSelectSubject = (event: any) => {
    const {
      target: { value },
    } = event;
    this.setState({ selectedSubject: value })
    this.getStudentByClassIdAndSubjectId(value)
  }
  handleClick = (event: any, type: any, id: string) => {
    this.setState({ showSpinner: true });
    event.stopPropagation()
    this.setState({
      anchorEl: event.currentTarget,
      selectedItem: type,
      selectedId: id
    });
    if (type === 'file') {
      this.viewStudentResourceUploads(id)
    } else if (type === 'folder') {
      this.viewStudentResourceFolders(id)
    }
  };

  handleviewDetailsClick = (event: any, viewFolders: any) => {
  // this.setState({
  //     selectedFolderDetails: viewFolders && viewFolders.data && viewFolders.data.attributes && viewFolders.data.attributes
  //    });
};



  handleDelete = () => {
    this.handleClose()
    if (this.state.selectedItem === 'file') {
      this.deleteStudentResource()

    } else {
      this.deleteStudentResourceFolders(parseInt(this.state.selectedId))
    }
  }
  handleSelectAll = (event: any) => {
    this.setState({openFolder: false});
    if (this.state.queryTerm.length === 0 || this.state.queryTerm === "") {
      if (event.target.checked) {

        this.setState({
          createSelected: this.state.studentResourceFolders && this.state.studentResourceFolders.data && this.state.studentResourceFolders.data.map((n: any) => n.id),
          fileSelected: this.state.studentResourcetList && this.state.studentResourcetList.data && this.state.studentResourcetList.data.map((n: any) => n.id),
          selectAll: true
        });
        return;
      } else { this.setState({ createSelected: [], fileSelected: [], selectAll: false }); }
    } else {
      if (event.target.checked) {

        this.setState({
          createSelected: this.state.studentResourceFolders && this.state.studentResourceFolders.data
            .filter((post: any) => {
              if (post.attributes && post.attributes.folder_name && post.attributes.folder_name.toLowerCase().includes(this.state.queryTerm.toLowerCase())) {
                return post;
              }
            })
            .map((n: any) => n.id),
          fileSelected: this.state.studentResourcetList && this.state.studentResourcetList.data
            .filter((post: any) => {
              if (post.attributes && post.attributes.file_name && post.attributes.file_name.toLowerCase().includes(this.state.queryTerm.toLowerCase())) {
                return post;
              }
            })
            .map((n: any) => n.id),
          selectAll: true
        });
        return;
      } else { this.setState({ createSelected: [], fileSelected: [], selectAll: false }); }
    }

  }

  handelShareWithPeople = (params: any) => {
    this.setState({ shareWithList: params })
  }


  handleSelect = (id: any) => {
    this.setState({ selectedIds: this.state.selectedIds.concat(id) })
  }
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  userdata = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}')
    this.setState({ data: data });
  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }
  // view files inside folders
  viewResourceFoldersFiles = (event: any, item: any) => {

    // debugger;
    this.setState({ showSpinner: true })
    this.setState({ activeFolder: item.id})

    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem('token'),

    };
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiviewResourceFoldersFiles = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createresourceEndPoint + `/${item.id}?school_id=${school_Data?.school_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  // view details
  viewStudentResourceFolders = (id: any) => {

    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem('token'),

    };
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiViewFolderResourceId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createresourceEndPoint + `/${id}?school_id=${school_Data?.school_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  viewStudentResourceUploads = (id: any) => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')

    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem('token'),

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiViewUploadResourceId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createResource + `/${id}?school_id=${school_Data?.school_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //Student List
  viewStudentList = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentList + `?school_id=${school_Data?.school_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Rename
  renameStudentResourceUploads = () => {

    this.setState({ showSpinner: true })
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      token: localStorage.getItem('token'),
    };
    let formData = new FormData();
    formData.append("file_name", this.state.folderName);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      data: formData,
    };

    this.apiRenameUploadResourceId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createResource + `/${this.state.selectedId}?school_id=${school_Data?.school_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiPutMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  renameStudentResourceFolders = () => {
    this.setState({ showSpinner: true })
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      token: localStorage.getItem('token'),
    };
    let formData = new FormData();
    formData.append("folder_name", this.state.folderName);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      data: formData,
    };
    this.apiRenameFolderResourceId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createresourceEndPoint + `/${this.state.selectedId}?school_id=${school_Data?.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiPutMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // download
  download = (e: any) => {
    fetch(e.target.href, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then((buffer) => {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.state.viewResource && this.state.viewResource.data && this.state.viewResource.data.attributes && this.state.viewResource.data.attributes.file_name}`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
  // make a copy
  makeACopyStudentResourceUploads = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    let formData = new FormData();
    formData.append("id", this.state.selectedId);
    const header = {
      token: localStorage.getItem('token'),

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiMakeaCopy = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createResource + `/make_copy` + `?school_id=${school_Data?.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiPostMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Move to
  moveToResourceUploads = () => {
    this.setState({ showSpinner: true })
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem('token'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      "id": this.state.selectedId,
      "folder_id": this.state.movetoFolderId
    };

    this.apiMoveTo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.moveResource + `?school_id=${school_Data?.school_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiPatchMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.handleClose()
  }

  //share with user
  shareWithUsers = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem('token'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      "id": Number(this.state.selectedId),
      "shared_user_ids": this.state.shareWithList
    };
    this.apiShareWithUsers = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    if(this.state.shareWithList.length > 0 ) {
      if (this.state.selectedItem === "folder") {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.shareWithUserFolder + `?school_id=${school_Data?.school_id}`
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.shareWithUserFile + `?school_id=${school_Data?.school_id}`
        );
      }
    }
    else{
      toast.success("Please select atleast one people")
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiPostMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //Opened By
  openedBy = (type: string, id: string) => {
    this.setState({ showSpinner: true })
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem('token'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      "opened_by": Number(localStorage.getItem('id')),
    };

    this.apiOpenedBy = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    if (type === "folder") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.openedByFolder + `/${id}/opened_by_user?school_id=${school_Data?.school_id}`
      );
    } else if (type === "file") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.openedByFile + `/${id}/opened_by_user?school_id=${school_Data?.school_id}`
      );
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiPatchMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCreateFolder = () => {
    let allow: boolean = true;
    if (this.state.folderName === "" || this.state.folderName.length === 0) {
      this.setState({
        folderNameError: true,
        folderNameErrorMes: 'Folder name is required'
      })
      allow = false
      return false
    } if (allow) {
      const data = {
        "class_id": this.state.selectedClass,
        "subject_id": this.state.selectedSubject,
        "folder_name": this.state.folderName,
        "shared_user_ids": this.state.createSelected,
        "shared_with": this.state.shared_with
      }
      const httpBody = data
      const user_data = localStorage.getItem('user_data');
      const school_Data = JSON.parse(user_data || '{}')
      const header = {
        "Content-Type": configJSON.resourceContentType,
        token: localStorage.getItem('token'),

      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apicreateFolder = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createresourceEndPoint + `?school_id=${school_Data?.school_id}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.resourceURL
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.resourceApiPostMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleUploadResource = () => {
    let currentFile
    if (this.state.selectedFiles && this.state.selectedFiles.length > 0) {
      currentFile = this.state.selectedFiles[0];
    }
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    let formData = new FormData();

    formData.append("uploaded_file", currentFile);
    formData.append("class_id", this.state.selectedClass);
    formData.append("category_id", this.state.categoriesName);
    formData.append("subject", this.state.selectedSubject);
    formData.append("shared_with", this.state.shared_with);
    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem('token'),

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      data: formData,
    };
    this.apiUploadResourceId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createResource + `?school_id=${school_Data?.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiPostMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  createCategoryList = () => {
    const category = {
      "categories": [
        {
          "name": this.state.categoriesName
        }
      ]
    }
    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem('token'),

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = category;
    this.apicreateCategory = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiPostMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCategoryList = () => {
    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetCategoryList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStudentResource = () => {
    this.setState({ showSpinner: true });
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentResourceId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentGetResource + `?school_id=${school_Data?.school_id}&page=${this.state.page + 1}&per=${this.state.rowsPerPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStudentClasses = () => {
    const header = {
      "Content-Type": configJSON.resourceContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetClassesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClasses
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStudentSubject = (value: any) => {
    const header = {
      "Content-Type": configJSON.resourceContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetClassSubjectId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectsByClassId + `?class_id=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStudentByClassIdAndSubjectId = (value: any) => {
    const header = {
      "Content-Type": configJSON.resourceContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetStudentsByClassAndSubjectId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentsByClassIdAndSubjectId + `?class_id=${this.state.selectedClass}&subject_id=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getTeacherByClassId = (value: any) => {
    const header = {
      "Content-Type": configJSON.resourceContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetClassTeacherId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeachersByClassId + `?class_id=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteStudentResource = () => {
    if (this.state.selectedId) {

      const user_data = localStorage.getItem('user_data');
      const school_Data = JSON.parse(user_data || '{}')

      const header = {
        "Content-Type": configJSON.resourceContentType,
        token: localStorage.getItem('token'),

      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiDeleteStudentResourceId = requestMessage.messageId;
      if (this.state.selectedItem === "folder") {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.createresourceEndPoint + `/${parseInt(this.state.selectedId)}/?school_id=${school_Data?.school_id}`
        );

      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.createResource + `/${parseInt(this.state.selectedId)}/?school_id=${school_Data?.school_id}`
        );
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.resourceURL
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.resourceApiDeleteMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    this.setState({ deleteResource: false })
  }
  handledeleteAll = () => {
    if (this.state.selectAll) {
      const header = {
        "Content-Type": configJSON.resourceContentType,
        token: localStorage.getItem('token'),

      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiDeleteAllId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteAll
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.resourceURL
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.resourceApiPostMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    this.setState({ deleteResource: false })
  }
  handledeleteSelected = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    if (this.state.fileSelected.length > 0 || this.state.createSelected.length > 0) {
      const data = {
        "file_ids": this.state.fileSelected,
        "folder_ids": this.state.createSelected
      }

      const header = {
        "Content-Type": configJSON.resourceContentType,
        token: localStorage.getItem('token'),

      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const httpBody = data;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      this.apiDeleteStudentSelectedId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteSelected + `?school_id=${school_Data?.school_id}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.resourceURL
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.resourceApiPostMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    this.setState({ deleteResource: false })
  }
  onCloseResource = () => {
    this.setState({ deleteResource: false })

  }
  getStudentResourceFolders = () => {
    this.setState({ showSpinner: true });
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem('token'),

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetStudentResourceFolderId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentResourceFolder + `?school_id=${school_Data?.school_id}&page=${this.state.page + 1}&per=${this.state.rowsPerPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteStudentResourceFolders = (id: number) => {
    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem('token'),

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteStudentResourceFolderId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentResourceFolder + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiDeleteMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlerviewDetailsClick = (event: any, type: any, id: string) => {
    event.stopPropagation()

    this.setState({
      anchorEl: event.currentTarget,
      selectedItem: type,
      selectedId: id
    });
    if (type === 'file') {
      this.viewStudentResourceUploads(id)
    } else if (type === 'folder')
      this.viewStudentResourceFolders(id)
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.apigetStudentResourceFolderId) {
        if (responseJson) {
          if (!responseJson.errors) {
            this.setState({ studentResourceFolders: responseJson , resourcesFoldersMeta:responseJson?.meta})
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false });
      }
      else if (apiRequestCallId === this.apigetStudentResourceId) {

        if (responseJson) {

          if (!responseJson.errors) {
            this.setState({ studentResourcetList: responseJson ,resourcesFilesMeta:responseJson?.meta })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false });
      }
      else if (apiRequestCallId === this.apicreateFolder) {

        if (responseJson) {

          if (!responseJson.errors) {
            toast.success(responseJson.message)

            this.setState({
              openModal: false,
              selectedSubject: null,
              selectedClass: null,
              shared_with: [],
              selectedFiles: undefined,
              uploadPercentage: 0,
              studentChecked: false,
              teacherChecked: false,
              parentChecked: false,
              folderName: '',
              folderNameError: false,
              folderNameErrorMes: ''
            })
            this.getStudentResource()
            this.getStudentResourceFolders()
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiUploadResourceId) {

        if (responseJson) {

          if (!responseJson.errors) {
            toast.success("Uploaded Successfully")

            this.getStudentResource()
            this.setState({
              openModal: false,
              categoriesName: '',
              selectedSubject: null,
              selectedClass: null,
              shared_with: [],
              selectedFiles: undefined,
              uploadPercentage: 0,
              studentChecked: false,
              teacherChecked: false,
              parentChecked: false

            })
            this.getStudentResource()

          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apicreateCategory) {

        if (responseJson) {

          if (!responseJson.errors) {
            this.setState({ categoriesName: '', createCategory: false })
            this.getCategoryList()

          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiGetClassTeacherId) {

        if (responseJson) {

          if (!responseJson.errors) {
            this.setState({ teachersList: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetStudentListId) {

        if (responseJson) {

          if (!responseJson.errors) {
            this.setState({ shareStudentList: responseJson.students })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiGetStudentsByClassAndSubjectId) {

        if (responseJson) {

          if (!responseJson.errors) {
            this.setState({ studentList: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiGetClassesId) {

        if (responseJson) {

          if (!responseJson.errors) {
            this.setState({ classesList: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiGetClassSubjectId) {

        if (responseJson) {

          if (!responseJson.errors) {
            this.setState({ subjectList: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetCategoryList) {

        if (responseJson) {

          if (!responseJson.errors) {
            this.setState({ categoryList: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (
        apiRequestCallId === this.apiDeleteStudentResourceId && !responseJson.errors
      ) {
        toast.success(responseJson.message)

        this.getStudentResource();
        this.getStudentResourceFolders();
        this.setState({ deleteResource: false, anchorEl: null })

      }
      else if (
        apiRequestCallId === this.apiDeleteStudentResourceId && responseJson.errors
      ) {
        this.parseApiErrorResponse(responseJson)
        this.setState({ deleteResource: false, anchorEl: null })
        toast.error(responseJson.errors)

      }
      else if (
        apiRequestCallId === this.apiDeleteStudentSelectedId && !responseJson.errors
      ) {
        toast.success(responseJson.message)
        this.getStudentResource();
        this.getStudentResourceFolders();
        this.setState({ alertModal: false })
        this.setState({ deleteResource: false })
        this.setState({selectAll:false})

      }
      else if (
        apiRequestCallId === this.apiDeleteStudentSelectedId && responseJson.errors
      ) {
        this.parseApiErrorResponse(responseJson)
        this.setState({ alertModal: false })
        toast.error(responseJson.errors)
        this.setState({ deleteResource: false, createSelected: [], fileSelected: [], selectAll: false })

      }
      else if (
        apiRequestCallId === this.apiDeleteStudentResourceFolderId && !responseJson.errors
      ) {
        toast.success(responseJson.message)
        this.getStudentResource();
        this.setState({ alertModal: false })
        this.getStudentResourceFolders();
        this.setState({ deleteResource: false })
      }
      else if (
        apiRequestCallId === this.apiDeleteStudentResourceFolderId && responseJson.errors
      ) {
        this.parseApiErrorResponse(responseJson)
        this.setState({ alertModal: false })
        toast.error(responseJson.errors)
        this.setState({ deleteResource: false })
      }
      else if (
        apiRequestCallId === this.apiDeleteAllId && !responseJson.errors
      ) {
        this.getStudentResourceFolders();
        this.getStudentResource();
        toast.success("Deleted Successfully")
        this.setState({ alertModal: false })
        this.setState({ deleteResource: false })
      }
      else if (
        apiRequestCallId === this.apiDeleteAllId && responseJson.errors
      ) {
        this.parseApiErrorResponse(responseJson)
      }
      else if (apiRequestCallId === this.apiViewFolderResourceId) {

        if (responseJson) {

          if (!responseJson.errors) {
            this.setState({ viewFolders: responseJson })

          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false });
      }

      else if (apiRequestCallId === this.apiviewResourceFoldersFiles) {

        if (responseJson) {

          if (!responseJson.errors) {
            this.setState({ studentResourcetList: responseJson.data.attributes.resource_uploads })

          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false })
      }
      else if (apiRequestCallId === this.apiViewUploadResourceId) {

        if (responseJson) {

          if (!responseJson.errors) {
            this.setState({ viewResource: responseJson, viewFolders: responseJson })

          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false });
      }
      else if (apiRequestCallId === this.apiRenameUploadResourceId) {

        if (responseJson) {

          if (!responseJson.errors) {
            this.setState({
              openModal: false,
              viewResource: [],
              anchorEl: null,
              selectedId: ''
            })
            this.getStudentResource()
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false })
      }
      else if (apiRequestCallId === this.apiRenameFolderResourceId) {
        if (responseJson) {
          if (!responseJson.errors) {
            this.setState({
              openModal: false,
              viewFolders: [],
              anchorEl: null,
              selectedId: ''

            })
            this.getStudentResourceFolders()

          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false })
      }
      else if (apiRequestCallId === this.apiShareWithUsers) {
        if (responseJson) {
          if (!responseJson.errors) {
            toast.success(responseJson.message)
            this.setState({
              openModal: false,
              shareWithList: [],
              anchorEl: null,
              selectedId: ''

            })
          } else {
            this.parseApiErrorResponse(responseJson)
            toast.error(responseJson.errors)
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiMakeaCopy) {

        if (responseJson) {

          if (!responseJson.errors) {
            this.getStudentResource()
            this.handleClose()
            this.setState({
              anchorEl: null,
              selectedId: '',
            })
          } else {
            this.setState({
              anchorEl: null,
              selectedId: ''
            })
            this.parseApiErrorResponse(responseJson)
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiMoveTo) {

        if (responseJson) {
          if (!responseJson.errors) {
            this.getStudentResource()

            this.setState({ anchorEl: null, selectedId: "", openModal: false });


          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false })
      } else if (apiRequestCallId === this.apiOpenedBy) {

        if (responseJson) {
          if (!responseJson.errors) {
            this.setState({ selectedFolderDetails: responseJson.data?.attributes});

          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false })
      }
    }
    // Customizable Area End

  }
  // Customizable Area Start
  // Customizable Area End
}