// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const ReminderQuotesIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
           <path d="M18.34 13.234c.647 0 1.172.524 1.172 1.17v2.884a5.856 5.856 0 0 1 4.682 5.733v3.129a5.849 5.849 0 0 1-5.85 5.85 5.852 5.852 0 0 1-5.857-5.85V23.02a5.849 5.849 0 0 1 4.683-5.733v-2.883c0-.647.525-1.171 1.17-1.171zM23.025 0c.647 0 1.171.524 1.171 1.17V13.66a1.171 1.171 0 0 1-2.341 0V2.34H2.34v25.756h7.18a1.171 1.171 0 0 1 0 2.342H1.17C.524 30.439 0 29.914 0 29.268V1.171C0 .524.524 0 1.17 0h21.854zm-4.68 19.512c-.937 0-1.83.37-2.488 1.027a3.515 3.515 0 0 0-1.027 2.482v3.129c0 .93.37 1.823 1.027 2.482a3.52 3.52 0 0 0 4.97 0 3.515 3.515 0 0 0 1.027-2.482V23.02c0-.93-.37-1.823-1.027-2.482a3.515 3.515 0 0 0-2.482-1.027zm-.003 1.561c.646 0 1.17.524 1.17 1.17v1.562a1.171 1.171 0 0 1-2.34 0v-1.561c0-.647.524-1.171 1.17-1.171zm-6.244-8.585a1.171 1.171 0 0 1 0 2.341H6.634a1.171 1.171 0 0 1 0-2.341zm5.463-6.244a1.171 1.171 0 0 1 0 2.341H6.634a1.171 1.171 0 0 1 0-2.341z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default ReminderQuotesIcon;
// Customizable Area End