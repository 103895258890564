// Customizable Area Start
import React, { useState, useCallback } from "react";
import { ILiveClassesProps } from "../TeacherLiveClassController";
import CreateIcon from '@material-ui/icons/Create';
import EditOnlineLecture from '../../EditOnlineLecture.web';
import { Typography, Divider, Button, Menu, Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  image_clock,
  image_user,
  image_presentation,
  image_videoicon,
  image_class,
  image_calendar,
  image_profile,
  clock,
  user,
  presentation_1,
  video_camera,
  class_1,
  calendar, subjectIcon
} from "../assets";
import moment from "moment";
import "./EventToolTip.web.css";
import { MeetingStatus } from "../../utils/constants";
import { HISTORY } from "../../../../../components/src/comman";
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

interface props {
  // Customizable Area Start
  event: ILiveClassesProps;
  anchorEl: any;
  handleClose: any;
  isTeacher: boolean;
  joinMeeting: () => void;
  classes: any;
  // Customizable Area End
}

// Customizable Area Start
const EventToolTip: React.FC<props> = ({
  event,
  anchorEl,
  handleClose,
  isTeacher,
  joinMeeting,
  classes,
}) => {
  const duration = moment.duration(moment(event.end).diff(moment(event.start)));
  const today = moment();
  const lectureEndTime = moment(event.end, "DD MMM YYYY HH:mma").isBefore(today);
  //const lectureStartTime = moment(event.start, "DD MMM YYYY HH:mma").isAfter(today)
  const lectureStartTime = moment(event.start).diff(moment(new Date()), 'minutes') >= 10

  const hours = duration.asHours();
  const openModal = Boolean(anchorEl);

  const handleOpenRecordingVideoLink = useCallback(() => {
    if (isTeacher) {
      const recording = event?.recordings?.data?.[event?.recordings?.data?.length - 1].attributes;
      window.open(recording.play_url, "_blank")
    } else {
      HISTORY.push({
        pathname: '/LiveClassRecordings',
        state: {
          recordingDate: event?.recordings?.data?.[event?.recordings?.data?.length - 1]?.attributes?.recording_end,
          recordingId: event?.recordings?.data?.[event?.recordings?.data?.length - 1]?.id,
          meetingId: event?.meetingId
        }
      })
    }

  }, [])

  return (
    <Menu
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorEl={anchorEl}
      open={openModal}
      onClose={handleClose}
      getContentAnchorEl={null}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      className="timeTableTooltip"
    >
      <div className="Tooltip-outer">
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 600,
              marginBottom: "2%",
            }}
            className={classes.title_font}
          >
            Online Class
          </Typography>
          {!isTeacher &&
            <Button
              className={classes.button_color}
              onClick={handleOpenRecordingVideoLink}
            >Recording</Button>
          }
        </div>
        <div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                height: 69,
                width: 69,
                borderRadius: 6,
                backgroundColor: "#fff2de",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {event?.subjectImage ? <img
                style={{
                  height: "40px",
                  width: "40px",
                  alignSelf: "center",
                }}
                src={event.subjectImage}
              /> : <img
                style={{
                  height: "40px",
                  width: "40px",
                  alignSelf: "center",
                }}
                src={subjectIcon}
              />}
            </div>

            <Typography
              style={{
                fontSize: 22,
                fontWeight: 600,
                alignSelf: "center",
                marginLeft: "20px",
              }}
              className={classes.subTitle_font}
            >
              {event.subject}
            </Typography>
          </div>

          <div
            style={{
              height: "fit-content",
              width: "100%",
              borderRadius: 8,
              backgroundColor: event.borderColor,
              display: "flex",
              margin: "20px 0",
              padding: "3% 0",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Open sans",
                  fontSize: 20,
                  color: "#fff",
                  fontWeight: 600,
                  marginLeft: "20px",
                }}
              >
                {moment(event.start).format("DD")}{" "}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Open sans",
                  fontSize: 20,
                  color: "#fff",
                  fontWeight: 600,
                  marginLeft: "20px",
                }}
              >
                {moment(event.start).format("MMM")}{" "}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Open sans",
                  fontSize: 20,
                  color: "#fff",
                  fontWeight: 600,
                  marginLeft: "20px",
                }}
              >
                {event.title}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Open sans",
                  fontSize: 18,
                  color: "#fff",
                  marginLeft: "20px",
                }}
              >
                Online Class
              </Typography>
            </div>
          </div>

          <div
            style={{ display: "flex", flexDirection: "row", padding: "12px 0" }}
          >
            <img
              style={{
                height: "24px",
                width: "24px",
              }}
              src={isTeacher ? image_clock : image_clock}
            />
            <Typography
              style={{
                fontSize: 18,
                marginLeft: "10px",
              }}
              className={classes.bodyText_font}
            >
              <span style={{ fontWeight: 600 }}
                className={classes.subTitle_font}>Duration: </span>
              {hours} hour from {moment(event.start).format("HH:mm A")}-
              {moment(event.end).format("HH:mm A")}
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "12px 0",
              alignItems: "center",
            }}
          >
            <img
              style={{
                height: "24px",
                width: "24px",
              }}
              src={isTeacher ? image_user : image_user}
            />
            <span
              style={{
                fontSize: 18,
                marginLeft: "10px",
                width: "100%",
                display: "flex"
              }}
              className={classes.bodyText_font}
            >
              <span style={{ fontWeight: 600 }}
                className={classes.subTitle_font}>Teacher: </span>
              <span style={{
                width: 24,
                height: 24,
                margin: "0 2%",
              }} >
                <Avatar
                  src={event?.teacherImage != null || event?.teacherImage != undefined ? event?.teacherImage : '/'}
                  style={{
                    width: 24,
                    height: 24,
                    objectFit: "contain",
                    //margin: "-10px 10px",
                  }}
                />
              </span>
              {event?.teacherName}
            </span>
          </div>

          {/* <div
            style={{ display: "flex", flexDirection: "row", padding: "12px 0" }}
          >
            <img
              style={{
                height: "24px",
                width: "24px",
              }}
              src={isTeacher ? image_presentation : image_presentation}
            />
            <Typography
              style={{
                fontFamily: "Open sans",
                fontSize: 18,
                color: "#3f526d",
                marginLeft: "10px",
              }}
            >
              <span style={{ fontWeight: 600 }}>Unit: </span>
              {event.unit}
            </Typography>
          </div>

         {event?.link && <div
            style={{ display: "flex", flexDirection: "row", padding: "12px 0" }}
          >
            <img
              style={{
                height: "24px",
                width: "24px",
              }}
              src={isTeacher ? image_videoicon : image_videoicon}
            />
            <Typography
              style={{
                fontFamily: "Open sans",
                fontSize: 18,
                color: "#3f526d",
                marginLeft: "10px",
              }}
            >
              <span style={{ fontWeight: 600 }}>Link: </span>
              <a
                href={event.link}
                target="_"
                style={{ wordBreak: "break-all" }}
              >
                {event.link}
              </a>
            </Typography>
          </div>} */}

          <div
            style={{ display: "flex", flexDirection: "row", padding: "12px 0" }}
          >
            <img
              style={{
                height: "24px",
                width: "24px",
              }}
              src={isTeacher ? image_class : image_class}
            />
            <Typography
              style={{
                fontSize: 18,
                marginLeft: "10px",
              }}
              className={classes.bodyText_font}
            >
              <span style={{ fontWeight: 600 }}
                className={classes.subTitle_font}>Class: </span>
              {event.class}
            </Typography>
          </div>

          <div
            style={{ display: "flex", flexDirection: "row", padding: "12px 0" }}
          >
            <img
              style={{
                height: "24px",
                width: "24px",
              }}
              src={isTeacher ? image_calendar : image_calendar}
            />
            <Typography
              style={{
                fontSize: 18,
                marginLeft: "10px",
              }}
              className={classes.bodyText_font}
            >
              <span style={{ fontWeight: 600 }}
                className={classes.subTitle_font}>Start Date & Time: </span>
              {moment(event.start).format("dddd, MMM DD, HH:mm A")}
            </Typography>
          </div>

          <Divider />

          {event?.description && <div style={{ margin: "20px 0" }}>
            <Typography
              style={{
                fontSize: 18,
                fontWeight: 600,
              }}
              className={classes.subTitle_font}
            >
              Description
            </Typography>

            <Typography
              style={{
                fontSize: 18,
                // maxWidth: "450px",
                marginTop: "10px",
              }}
              className={classes.bodyText_font}
            >
              {event?.description}
            </Typography>
          </div>}

          {event?.link && <>
            {event?.status?.toLowerCase() === MeetingStatus.Ended ? <Button
              disabled={true}
              className={"btn_ended_meet"}
              style={{ fontFamily: "Open sans" }}

            >
              <Typography className="label_end_class">End Class</Typography>
            </Button>
              :
              <Button
                className={`live_classes_view_joinnow ${event?.status?.toLowerCase() === MeetingStatus.Ongoing ? classes.button_color : classes.button_color_disabled}`}
                style={{
                  opacity: event?.status &&
                    (isTeacher ?
                      event?.status && event.status?.toLowerCase() === MeetingStatus.Upcoming || MeetingStatus.Ongoing
                      : event?.status && event.status?.toLowerCase() === MeetingStatus.Ongoing) &&
                    (!lectureEndTime && !lectureStartTime) ? 1 : 0.4,
                }}
                disabled={!(event?.status &&
                  (isTeacher ?
                    event?.status && event.status?.toLowerCase() === MeetingStatus.Upcoming || MeetingStatus.Ongoing
                    : event?.status && event.status?.toLowerCase() === MeetingStatus.Ongoing) &&
                  (!lectureEndTime && !lectureStartTime))}
                onClick={joinMeeting}
              >
                <Typography className="label_joinnow">{event?.status?.toLowerCase() === MeetingStatus.Ongoing ? "Ongoing" : isTeacher ? "Start Class" : "Join Class"}</Typography>
              </Button>}</>}
        </div>
      </div>
    </Menu>
  );
};
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(EventToolTip);
// Customizable Area End