// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  NativeSelect,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import StudentAssessmentController, {
  Props,
} from "./StudentAssessmentController.web";
import { imgBackArrow, iconlyLightPlay, iconlyLightDocument } from "../assets";
import ReactPaginate from "react-paginate";
import { withStyles } from "@material-ui/core/styles";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { ArrowDropDown } from "@material-ui/icons";
import { Search } from "@material-ui/icons";
import { studentAssessmentStatusList } from "../utils/constants";
import moment from "moment";
import { HISTORY } from "../../../../components/src/comman";
import "./StudentAssessment.web.css";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme: any) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "#fff",
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
    border: "1px solid #f9f8fd",
    padding: "8px 26px 8px 12px",
    height: "42px",
    fontWeight: 600,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: theme.props.textFont,
    color: theme.props.textColor,
    fontSize: 18,
    lineHeight: "22px",
    "&:focus": {
      borderRadius: 8,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
// Customizable Area End

export class StudentAssessment extends StudentAssessmentController {
  // Customizable Area Start
  render() {
    const { assessmentList } = this.state;
    const getTimeData = (timeLimit: any, publishDate: any) => {
      const timeSplit = timeLimit.split(":");
      const endTime = moment(publishDate)
        .add(timeSplit[0], "hours")
        .add(timeSplit[1], "minutes");
      return `${timeLimit}hr [${moment(publishDate).format(
        "hh:mma"
      )} - ${endTime.format("hh:mma")}]`;
    };

    return (
      <>
        {this.state.showLoader && (
          <Spinner spinnerModal={this.state.showLoader} />
        )}

        <Box className="studentAssessment_mainDiv">
          <Typography className={`studentAssessment_subDiv_Title ${this.props.classes.heading_font}`} >
            Student Assessment List
          </Typography>
          <div className="view_verticalbox studentAssessment_subDiv" >
            <Grid
              className="studentAssessment_inputDiv">
              <Grid item xs={12} sm={12} md={6}>
                <div className={`studentAssessment_subInputDiv ${this.props.classes.image_Border_Color}`}>
                  <input
                    placeholder="Search by subject name, assigned by..."
                    onChange={(event) => this.onChangeSearchTerm(event)}
                    value={this.state.queryTerm}
                    className={`studentAssessment_input ${this.props.classes.bodyText_font}`}
                    onKeyDown={(e: any) => {
                      if (e?.key === "Enter") {
                        this.setState({ page: 0 }, () =>
                          this.getAssessmentList()
                        );
                      }
                    }}
                  />
                  <Search
                    className={`studentAssessment_searchIcon ${this.props.classes.icon_color}`}
                    onClick={() =>
                      this.setState({ page: 0 }, () => this.getAssessmentList())
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Grid container>
                  <Grid item xs={4}>
                    <NativeSelect
                      className="studentAssessment_dropDown"
                      id="select"
                      value={this.state.selectedSubjectId}
                      input={<BootstrapInput />}
                      IconComponent={ArrowDropDown}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                        this.handleSubjectChange(event)
                      }
                    >
                      <option value="" disabled>
                        Select subject
                      </option>

                      {this.state.subjectList?.map((item: any) => (
                        <option value={item.id} key={item.id}>
                          {item?.attributes?.subject_name}
                        </option>
                      ))}
                    </NativeSelect>
                  </Grid>
                  <Grid item xs={4}>
                    <NativeSelect
                      className="studentAssessment_dropDown"
                      id="select"
                      value={this.state.selectedTerm}
                      input={<BootstrapInput />}
                      IconComponent={ArrowDropDown}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                        this.handleTermChange(event)
                      }
                    >
                      <option value="" disabled>
                        Select term
                      </option>
                      {this.state.termsList?.map((item: any) => (
                        <option value={item.id} key={item.id}>
                          {item?.attributes?.title}
                        </option>
                      ))}
                    </NativeSelect>
                  </Grid>
                  <Grid item xs={4}>
                    <NativeSelect
                      className="studentAssessment_dropDown"
                      id="select"
                      value={this.state.selectedStatus}
                      input={<BootstrapInput />}
                      IconComponent={ArrowDropDown}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                        this.handleStatusChange(event)
                      }
                    >
                      <option value="" disabled>
                        Select status
                      </option>
                      {studentAssessmentStatusList?.map((item: any) => (
                        <option value={item.value} key={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <TableContainer>
              <div className="studentAssessment_tableContainerDiv">
                <Table className="borderClass">
                  <TableHead className={`studentAssessment_tableHead ${this.props.classes.main_Primary_Color}`} >
                    <TableRow>
                      <TableCell className={`studentAssessment_tableCell studentAssessment_headingTxt`}>
                        Assessment Title
                      </TableCell>

                      <TableCell className={`studentAssessment_tableCellCenter studentAssessment_headingTxt`}>
                        Subject
                      </TableCell>
                      <TableCell className={`studentAssessment_tableCellCenter studentAssessment_headingTxt`}>
                        Term
                      </TableCell>
                      <TableCell className={`studentAssessment_tableCellCenter studentAssessment_headingTxt`}>
                        Time
                      </TableCell>
                      <TableCell className={`studentAssessment_tableCellCenter studentAssessment_headingTxt`}>
                        Date
                      </TableCell>
                      <TableCell className={`studentAssessment_tableCellCenter studentAssessment_headingTxt`}>
                        Assigned by
                      </TableCell>
                      <TableCell className={`studentAssessment_tableCellCenter studentAssessment_headingTxt`}>
                        Status
                      </TableCell>
                      <TableCell className={`studentAssessment_tableCellResults studentAssessment_headingTxt`}>
                        Results
                      </TableCell>
                      <TableCell
                        className={`studentAssessment_blankTableCell studentAssessment_headingTxt`}

                      ></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {assessmentList?.length === 0 ? (
                      <>
                        <Typography className='studentAssessment_noRecords'>
                          No record(s) found.
                        </Typography>
                      </>
                    ) : (
                      <>
                        {assessmentList?.map((data: any) => (
                          <>
                            <tr>
                              <td colSpan={7} className='studentAssessment_tableCellHeight'></td>
                            </tr>
                            <TableRow>

                              <TableCell className={`studentAssessment_tableSubCell ${this.props.classes.bodyText_font}`}>
                                {
                                  data.attributes?.assessment_name
                                }
                              </TableCell>
                              <TableCell className={`studentAssessment_tableSubCellCenter ${this.props.classes.bodyText_font}`}>
                                {
                                  data.attributes?.subject_name?.data
                                    ?.subject_name
                                }
                              </TableCell>
                              <TableCell className={`studentAssessment_tableSubCellCenter ${this.props.classes.bodyText_font}`}>
                                {data.attributes?.term?.title}
                              </TableCell>
                              <TableCell className={`studentAssessment_tableSubCellCenter ${this.props.classes.bodyText_font}`}>
                                {getTimeData(
                                  data.attributes?.time_limit,
                                  data.attributes?.publish_date
                                )}
                              </TableCell>
                              <TableCell className={`studentAssessment_tableSubCellCenter ${this.props.classes.bodyText_font}`}>
                                {moment(data.attributes?.publish_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </TableCell>
                              <TableCell className={`studentAssessment_tableSubCellCenter ${this.props.classes.bodyText_font}`}>
                                {data.attributes?.teacher_name?.[0]?.first_name}{" "}
                                {data.attributes?.teacher_name?.[0]?.last_name}
                              </TableCell>
                              <TableCell className={`studentAssessment_tableSubCellCenter ${this.props.classes.bodyText_font}`}
                                style={{ color: this.getStatusTextColor(data.attributes.assessment_status) }}>
                                {data.attributes?.assessment_status}
                              </TableCell>
                              <TableCell className={`studentAssessment_tableSubCellCenter studentAssessment_tableResultCellCenter ${this.props.classes.bodyText_font}`}>
                                {!this.isStringNullOrBlank(
                                  data.attributes?.result
                                )
                                  ? `${data.attributes?.result} %`
                                  : "-"}
                              </TableCell>
                              <TableCell className={`studentAssessment_tableSubCellCenter ${this.props.classes.bodyText_font}`}>
                                {data.attributes?.assessment_status ===
                                  "Assigned" ||
                                  data.attributes?.assessment_status ===
                                  "Inprogress" || data.attributes?.assessment_status ===
                                  "started" ? (
                                  <>
                                    <img
                                      src={iconlyLightPlay}
                                      className='studentAssessment_tableCellImg'
                                      onClick={() => {
                                        HISTORY.push({
                                          pathname:
                                            "/StudentAssessmentGuideline",
                                          state: {
                                            assessmentId: data?.id,
                                            examTitle: `Exam - ${data?.attributes?.subject_name?.data?.subject_name} [${data?.attributes?.term?.title}]`,
                                            timeLimit:
                                              data.attributes?.time_limit,
                                            publishDate:
                                              data.attributes?.publish_date,
                                          },
                                        });
                                      }}
                                    ></img>
                                  </>
                                ) : (
                                  <>
                                    {data.attributes?.assessment_status ===
                                      "Submitted" && (
                                        <img
                                          src={iconlyLightDocument}
                                          className={!this.isStringNullOrBlank(
                                            data.attributes?.result
                                          ) ? 'studentAssessment_isString' : 'studentAssessment_notString '}
                                          onClick={() =>
                                            this.openAssessmentAnswers(
                                              data?.id,
                                              data.attributes?.result
                                            )
                                          }
                                        ></img>
                                      )}
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>

            {this.state.assessmentListMeta?.pagination?.total_count > 10 && (
              <ReactPaginate
                previousLabel={"←   Previous"}
                nextLabel={"Next   →	"}
                breakLabel="..."
                initialPage={this.state.page}
                pageCount={
                  this.state.assessmentListMeta?.pagination?.total_pages
                }
                onPageChange={(e: any) => {
                  if (e.selected !== this.state.page) {
                    this.setState({ page: e.selected }, () =>
                      this.getAssessmentList()
                    );
                  }
                }}
                containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
              />
            )}
          </div>
        </Box>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start

export default withStyles(themeCreateStyle)(
  HOCAuth(StudentAssessment, "StudentAccount")
);
// Customizable Area End
