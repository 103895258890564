Object.defineProperty(exports, "__esModule", {
  value: true,
});

const urlConfig = require("../../../../framework/src/config");

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getAssessmentGradebookEndPoint = "bx_block_grading/assessments/gradebook";
exports.getTaskGradebookEndPoint = "bx_block_grading/task_evaluations";
exports.getStudentListEndPoint = "bx_block_scheduling/school_classes/get_class_students";
exports.getClassListEndPoint = "account_block/school_accounts/get_teacher_classes";
exports.getSubjectListEndPoint = "bx_block_scheduling/subjects/account_subjects";
exports.getStudetTaskSubmissionEndPoint = '/bx_block_task/tasks/get_teacher_evalution_tasks';

exports.getSubjectsEndPoint =
  "/bx_block_scheduling/subjects/class_section_subjects";
exports.assessmentGradebookEndPoint = "bx_block_assessmenttest/assessments/gradebook"
exports.taskGradebookEndPoint = "bx_block_grading/task_evaluations/student_task_list"
exports.getTaskTypeListEndPoint = "bx_block_assessmenttest/report_category_types/list?type=task"
  
exports.GET = "GET";

exports.dashboardURL = urlConfig.dashboardURL;
exports.instituteURL = urlConfig.instituteURL;
exports.userManagementURL = urlConfig.baseURL;
exports.attendanceURL = urlConfig.attendanceURL;
exports.resultUrl = urlConfig.resultUrl;
exports.examinationUrl = urlConfig.examinationUrl;


// Teacher Gradebook Strings
exports.teacherGradbookStrings = {
  dateFormat:"YYYY-MM-DDTHH:mm:ss",
  dueDateFormat:"D MMM"
}
// Teacher Gradebook Strings END
  // Customizable Area End