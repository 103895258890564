// Customizable Area Start
import React from "react";
import Datetime from "react-datetime";
import TeacherBulkDocumentBox from "./TeacherBulkDocumentBox.web";
import AlertModal from "../../alert/src/AlertModal.web";
import TeacherCreateEventModalController, { Props } from "./TeacherCreateEventModalController.web"
import {
  Modal,
  Backdrop,
  Box,
  withStyles,
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormHelperText,
  Button,
  NativeSelect,

} from "@material-ui/core";
import {
  ArrowDropDown
} from "@material-ui/icons";
import InputBase from "@material-ui/core/InputBase";
import {
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import moment from "moment";
import { calendar } from './assets'
import Fade from '@material-ui/core/Fade';
import DateFnsUtils from '@date-io/date-fns';
import './StudentClassesAndSection.web.css';
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
import './TeacherCreateEvent.web.css';
// Customizable Area End

// Customizable Area Start
const mock = [
  { label: "Mathmatics", value: "Mathmatics" },
  { label: "English", value: "English" },
  { label: "Science", value: "Science" },
  { label: "Economics", value: "Economics" },
];
// Customizable Area End

export class TeacherCreateEvent extends TeacherCreateEventModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  // Customizable Area Start
  disablePastDt = (current: any) => {
    const today = moment().subtract(1, 'day')
    return current.isAfter(today);
  };
  render() {
    const { classes } = this.props
    const { formState, endTimeError, teacherData, shareWithTeacher, schoolClassError, startTime, endTime, startTimeError, endDate, startDate } = this.state
    const BootstrapInput = withStyles((theme: any) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 4,
        position: "relative",
        boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
        border: schoolClassError ? '1px solid red' : '0px',
        backgroundColor: theme.palette.background.paper,
        padding: "14px 26px 14px 12px",
        width: "100%",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        fontSize: 18,
        lineHeight: 1.2,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="teacher_create_event_parent_modal"
          open={this.props?.createEventModal}
          onClose={() => {
            this.ResetState()
            this.props?.handleOnClose(false)
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.props.createEventModal} style={{ border: "none" }}>
            <div className="Create-Event-Popup create_event_popup">
              <form onSubmit={this.createEvent} className="h_100">
                <Box className="Eventtitle title_box">
                  <Typography
                    className={`${this.props.classes.title_font} title_typo`}
                  >
                    Create Event
                  </Typography>
                </Box>
                <Box className="form_container">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box className="form_group">
                        <Typography
                          className={`${this.props.classes.title_font} form_label`}
                        >
                          Event Name
                        </Typography>
                        <Box>
                          <TextField
                            className={classes.textField}
                            variant={"outlined"}
                            placeholder="Event Name"
                            value={formState?.Name}
                            onBlur={this.handleOnBlur('Name')}
                            onChange={this.handleOnChange("Name")}
                            error={this.state.nameError?.error}
                            helperText={this.state.nameError?.errorText}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Box className="form_group">
                        <Typography
                          className={`${this.props.classes.title_font} form_label`}
                        >
                          Start Date
                        </Typography>
                        <Box className="w_100">
                          <Datetime
                            dateFormat={"DD-MM-YYYY"}
                            timeFormat={false}
                            strictParsing={true}
                            input={true}
                            isValidDate={this.disablePastDt}
                            className={
                              this.state.startDateerror
                                ? "input_date_of_event_css_error_format"
                                : "input_date_of_event_css_format"
                            }
                            inputProps={{
                              placeholder: "dd-mm-yyyy",
                              className:
                                "Teacher_Profile Date_Icon",
                              readOnly: true,
                            }}
                            value={startDate === '' ? moment().format("DD-MM-YYYY") : startDate}
                            onClose={() => {
                              if (
                                startDate === ''
                              ) {
                                this.setState({
                                  startDateerror: true,
                                  startDateerrorText: " Please select start date.",
                                });
                              } else {
                                this.setState({
                                  startDateerror: false,
                                  startDateerrorText: "",
                                })
                              }
                            }}
                            closeOnClickOutside
                            closeOnSelect
                            onChange={(date) => {
                              console.log(date)
                              if (moment(date).format("DD/MM/YYYY").length === 0) {
                                this.setState({
                                  startDateerror: true,
                                  startDateerrorText: " Please select start date",
                                });
                              }
                              this.setState({
                                startDate: moment(date).format("DD/MM/YYYY"),
                                startDateerror: false,
                                startDateerrorText: '',
                              });
                            }}
                          />
                          {this.state.startDateerror && (
                            <FormHelperText
                              className="error_text"
                            >
                              {this.state.startDateerrorText}
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={7} sm={5} md={5} lg={2}>
                      <Box
                        className="form_group"
                      >
                        <Typography
                          className={`${this.props.classes.title_font} form_label`}
                        >
                          Start Time
                        </Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <TimePicker
                            autoOk
                            ampm={false}
                            defaultValue={"00:00"}
                            placeholder="--:--"
                            className="no-underline"
                            inputProps={{ style: { fontFamily: "Open sans", color: "#3f526d", padding: '14px 16px', border: startTimeError ? "1px solid red" : "1px solid #3f526d", borderRadius: 6, fontWeight: 600, width: '100%' } }}
                            value={moment(startTime, "HH:mm")}
                            onChange={(event: any) => this.setState({ startTime: moment(event).format("HH:mm") })}
                          />
                        </MuiPickersUtilsProvider>
                        {startTimeError && (
                          <FormHelperText className="error_text">
                            Please start start time.
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Box
                        className="form_group">
                        <Typography
                          className={`${this.props.classes.title_font} form_label`}
                        >
                          End Date
                        </Typography>
                        <Box>
                          <Datetime
                            dateFormat={"DD-MM-YYYY"}
                            timeFormat={false}
                            strictParsing={true}
                            input={true}
                            isValidDate={this.disablePastDt}
                            className={
                              this.state.startDateerror
                                ? "input_date_of_event_css_error_format"
                                : "input_date_of_event_css_format"
                            }
                            inputProps={{
                              placeholder: "dd-mm-yyyy",
                              className:
                                "Teacher_Profile Date_Icon",
                              readOnly: true,
                            }}
                            value={endDate === '' ? moment().format("DD-MM-YYYY") : endDate}
                            onClose={() => {
                              if (
                                endDate === ''
                              ) {
                                this.setState({
                                  endDateerror: true,
                                  endDateerrorText: " Please select end date.",
                                });
                              } else {
                                this.setState({
                                  endDateerror: false,
                                  endDateerrorText: "",
                                })
                              }
                            }}
                            closeOnClickOutside
                            closeOnSelect
                            onChange={(date) => {
                              console.log(date)
                              if (moment(date).format("DD/MM/YYYY").length === 0) {
                                this.setState({
                                  endDateerror: true,
                                  endDateerrorText: " Please select start date",
                                });
                              }
                              this.setState({
                                endDate: moment(date).format("DD/MM/YYYY"),
                                endDateerror: false,
                                endDateerrorText: '',
                              });
                            }}
                          />
                          {this.state.endDateerror && (
                            <FormHelperText
                              className="error_text"
                            >
                              {this.state.endDateerrorText}
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={7} sm={5} md={5} lg={2}>
                      <Box
                        className="form_group"
                       >
                        <Typography
                          className={`${this.props.classes.title_font} form_label`}
                        >
                          End Time
                        </Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <TimePicker
                            autoOk
                            ampm={false}
                            defaultValue={"00:00"}
                            placeholder="--:--"
                            className="no-underline"
                            inputProps={{ style: { fontFamily: "Open sans", color: "#3f526d", padding: '14px 16px', border: endTimeError ? "1px solid red" : "1px solid #3f526d", borderRadius: 6, width: "100%", fontWeight: 600 } }}
                            value={moment(endTime, "HH:mm")}
                            onChange={(event: any) => this.setState({ endTime: moment(event).format("HH:mm") })}
                          />
                        </MuiPickersUtilsProvider>
                        {endTimeError && (
                          <FormHelperText className="error_text">
                            Please select end time.
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Box
                        className="select_form_group"
                      >
                        <Typography
                          className={`${this.props.classes.title_font} form_label`}
                        >
                          Class
                        </Typography>
                        <NativeSelect
                          id="select"
                          value={this.state.school_class_id ? this.state.school_class_id : ""}
                          input={<BootstrapInput />}
                          IconComponent={ArrowDropDown}
                          className="w_96"
                          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            this.setState({ school_class_id: event.target.value, schoolClassError: false })
                          }}
                        >
                          <option value="" disabled>Select Class</option>
                          {
                            teacherData.length != 0 && teacherData.school_account.data.map((getTeacherClass: any) => (
                              <option value={getTeacherClass.attributes.class.id} >{getTeacherClass.attributes?.class?.display_name}</option>
                            ))
                          }
                        </NativeSelect>
                        {schoolClassError && (
                          <FormHelperText className="error_text">
                            Please select class.
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        className="form_group margin_12"
                      >
                        <Typography
                          className={`${this.props.classes.title_font} checkbox_label`}
                        >
                          Members
                        </Typography>
                        <Box className={classes.members} >
                          <Checkbox
                            className="checkbox"
                            checked={shareWithTeacher}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              this.setState({
                                shareWithTeacher: !shareWithTeacher
                              })
                            }}
                          />
                          <Typography className={this.props.classes.title_font}>
                            Teachers
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className="form_group margin_12">
                        <Typography
                          className={`${this.props.classes.title_font} checkbox_label`}
                        >
                          Detail
                        </Typography>
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows="3"
                          value={formState.details}
                          className={classes.textFieldMultiLine}
                          onBlur={this.handleOnBlur('details')}
                          variant="outlined"
                          onChange={this.handleOnChange('details')}
                          error={this.state.detailError?.error}
                          helperText={this.state.detailError?.errorText}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="btn_container">
                  <Box className="flex_center">
                    <Box>
                      <Button
                        className={`${this.props.classes.button_color} add_btn`}
                        type='submit'
                      >
                        Add Event
                      </Button>
                    </Box>
                    <Box>
                      <Button  className="AddEvent-Btn cancel_btn"
                        onClick={() => {
                          this.ResetState()
                          this.props.handleOnClose(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            </div>
          </Fade>
        </Modal>
        <AlertModal
          alertModal={this.state.alertModal}
          isTeacher={false}
          onCloseModal={() => {
            this.setState({ alertModal: false });
          }}
          alertTitle="Alert!"
          alertMessage={this.state.alertMessage}
          buttonText="OK"
        />
      </>
    )
  }
  // Customizable Area End
}
// Customizable Area Start
const styles = (theme: any) => ({
  timeField: {
    '& .MuiInputBase-input': {
      height: "14px"
    }
  },
  Text: {
    fontSize: '20px',    
    fontWeight: 600,
  },
  members: {
    display: 'flex',
    alignItems: 'center'
  },
  HeadText: {
    font: "Open Sans",
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: 20,
    color: "#3f526d",
  },
  Line: {
    border: "solid 0.5px #979797",
  },
  selectDropDown: {
    width: "90%",
    "& .MuiSelect-root": {
      height: "50px",
      padding: "14px",
      boxSizing: "border-box",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "none",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#ebebeb",
      paddingLeft: 14,
      borderRadius: 4,
    },
    "&.Mui-error > .MuiInputBase-input": {
      borderColor: "red !important",
    },
    "& .MuiSelect-icon": {
      top: `calc(30% - 12px)`,
      width: 45,
      height: 45,
    },
  },

  textField: {
    width: "100%",

    "& .MuiOutlinedInput-input": {
      padding: "14px",
      height: "50px",
      boxSizing: "border-box",
      borderRadius: "8px",
      border: "solid 1px #ebebeb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ebebeb",
        borderRadius: "8px",
        border: "solid 1px #ebebeb",
      },
    },
  },

  textFieldMultiLine: {
    width: '100%',
    "& .MuiOutlinedInput-input": {

    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ebebeb",
        borderRadius: "8px",
        border: "solid 1px #ebebeb",
      },
    },
  },

  ListItem: {
    "&.MuiListItemText-root": {
      marginTop: "-2px",
    },
  },

  DateTime: {
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url${calendar}`,
    backgroundSize: '20px 20px',
    backgroundPosition: 'top 9px right 12px',
  },
});
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(TeacherCreateEvent);
// Customizable Area End