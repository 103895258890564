// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const ClassPeriodIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
           <path d="M21.705 0c3.466 0 6.294 2.838 6.294 6.305v15.4C28 25.172 25.171 28 21.705 28h-15.4C2.838 28 0 25.17 0 21.705v-15.4C0 2.84 2.838.001 6.305.001L21.705 0zM8.402 19.597H2.8l.001 2.108c0 1.963 1.54 3.493 3.504 3.493h2.097v-5.601zm8.403 0h-5.602v5.601h5.602v-5.601zm8.393 0h-5.602v5.601h2.109c1.897 0 3.39-1.43 3.488-3.298l.005-.195v-2.108zM8.402 11.204H2.8v5.602h5.602v-5.602zm8.403 0h-5.602v5.602h5.602v-5.602zm8.393 0h-5.602v5.602h5.602v-5.602zm-3.493-8.402L19.596 2.8v5.602h5.602V6.305c0-1.897-1.43-3.4-3.299-3.498l-.194-.005zM8.402 2.8H6.305A3.468 3.468 0 0 0 2.8 6.305L2.8 8.403h5.602V2.801zm8.403 0h-5.602v5.602h5.602V2.801z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default ClassPeriodIcon;
// Customizable Area End