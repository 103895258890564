// Customizable Area Start
import React from "react";
import InputBase from "@material-ui/core/InputBase";
import Grid from '@material-ui/core/Grid';
import ReactPaginate from "react-paginate";
import Datetime from "react-datetime";
import './StudentDocument.css'
import {
    Box,
    NativeSelect,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
import {
    Search,   
    ArrowDropDown,
} from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import StudentDocumentController, { Props } from './StudentDocumentController.web';
import moment from "moment";
import AlertModal from "../../../alert/src/AlertModal.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
// Customizable Area Start
const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
    },
    input: {
        borderRadius: 8,
        position: "relative",
        boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: theme.palette.background.paper,
        padding: "10px 26px 10px 12px",
        width: "125px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontWeight: 600,
        fontFamily: 'Open sans',
        color: "#3f526d",
        fontSize: 18,
        lineHeight: 1.2,
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase);
// Customizable Area End
// Customizable Area Start
export class StudentDocument extends StudentDocumentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        const today = moment();
        const disableFutureDt = (current: any) => {

            return current.isBefore(today);
        };

        return (
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                className="UserDocBox"
            >
                {this.state.loadingSpinner && <Spinner spinnerModal={this.state.loadingSpinner} />}
                <Box
                    display="flex"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        className="UserDocSearchBox"
                    >
                        <input
                            placeholder="Search for Student..."
                            value={this.state.searchQuery}
                            className="UserDocSearchInput"
                            onChange={((event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ searchQuery: event.target.value }, () => {

                                })
                            })}
                            onKeyPress={(event: any) => {
                                if (event.key === "Enter") {
                                    event.preventDefault()
                                    this.setState({ currentPage: 0 }, () => this.get_user())
                                }
                            }}
                        />
                        <Search 
                            className="UserDocSearchImg"
                            onClick={() => {
                                this.setState({ currentPage: 0 }, () => this.get_user())
                            }} />
                    </Box>

                </Box>

                <Grid container className="UserDocContainer">
                    <Grid item xs={12}>
                        <Grid container className="UserDocAlignCenter">
                            <Grid item xs={2} className="UserDocFlex">
                                <Typography
                                    className={`ListOfPermissionTitle ${this.props.classes.Heading_font}`}
                                >List of permission</Typography>
                            </Grid>

                            <Grid item xs={3} className="UserDocFilterGrid">
                                <NativeSelect
                                    id="select"
                                    value={this.state.period ? this.state.period : ""}
                                    input={<BootstrapInput />}
                                    IconComponent={ArrowDropDown}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { this.setState({ period: event.target.value ,currentPage: 0 }, () => this.get_user()) }}
                                >
                                    <option value="all">All Time</option>
                                    <option value="specific_date" >Specific Date</option>
                                </NativeSelect>
                            </Grid>

                            {this.state.period === "specific_date" &&
                                <Grid item xs={3} className="UserDocFilterGrid">
                                    <Datetime
                                        dateFormat={"DD MMMM,YYYY"}
                                        timeFormat={false}
                                        strictParsing={true}
                                        input={true}
                                        closeOnSelect={true}
                                        initialValue={moment().format('DD MMMM,YYYY')}
                                        isValidDate={disableFutureDt}
                                        value={moment(this.state.date, 'DD/MM/YYYY').format('DD MMMM,YYYY')}
                                        className="input_today_date_css_format_doc"
                                        inputProps={{
                                            placeholder: "Date of Birth",
                                            className: "Date_of_birth_calendar_Icon_register_doc",
                                            readOnly: true,
                                        }}
                                        onChange={(date: any) => {
                                            this.setState({
                                                date: moment(date).format("DD/MM/YYYY"),

                                                perPage: '10', currentPage: '0'

                                            }, () => this.get_user()
                                            );
                                        }}

                                    />
                                </Grid>
                            }
                          
                            <Grid item xs={3} className="UserDocFilterGrid">
                                <NativeSelect
                                    id="select"
                                    value={this.state.filterClassId ? this.state.filterClassId : ""}
                                    input={<BootstrapInput />}
                                    IconComponent={ArrowDropDown}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { this.setState({ filterClassId: event.target.value , currentPage: 0 }, () => this.get_user()) }}
                                >
                                    <option value="">Class</option>
                                    {this.state.classList.map((item: any) => {
                                        return (
                                            <>
                                                <option value={item.id}>{item.attributes?.display_name}</option>
                                            </>
                                        )
                                    })}
                                </NativeSelect>

                            </Grid>
                            <Grid item xs={3} className="UserDocFilterGrid">
                                <NativeSelect
                                    id="select"
                                    value={this.state.filterStatus ? this.state.filterStatus : ""}
                                    input={<BootstrapInput />}
                                    IconComponent={ArrowDropDown}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { this.setState({ filterStatus: event.target.value ,currentPage: 0 }, () => this.get_user()) }}
                                >
                                    <option value="">All Status</option>
                                    <option value="under_review" >Under Review</option>
                                    <option value="approved" >Approved</option>
                                    <option value="rejected" >Rejected</option>
                                </NativeSelect>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="UserDocDataGrid">
                        <TableContainer >
                            <Table className="UserDocTable" aria-label="simple table">
                                <TableHead className="UserDocTableHead">
                                    <TableRow>
                                        <TableCell align="left" className={`fontSizeHeader UserDocHeaderUserName ${this.props.classes.subHeading_font}`}>User Name</TableCell>
                                        <TableCell align="left" className={`fontSizeHeader UserDocHeaderTitle ${this.props.classes.subHeading_font}`}>Date</TableCell>
                                        <TableCell align="left" className={`fontSizeHeader UserDocHeaderTitle ${this.props.classes.subHeading_font}`}>Class</TableCell>
                                        <TableCell align="left" className={`fontSizeHeader UserDocHeaderTitle ${this.props.classes.subHeading_font}`}>Review</TableCell>
                                        <TableCell align="left" className={`fontSizeHeader UserDocHeaderTitle ${this.props.classes.subHeading_font}`}>Approved</TableCell>
                                        <TableCell align="left" className={`fontSizeHeader UserDocHeaderTitle ${this.props.classes.subHeading_font}`}>Rejected</TableCell>
                                        <TableCell align="left" className={`fontSizeHeader UserDocHeaderAction ${this.props.classes.subHeading_font}`}>Action</TableCell>
                                    </TableRow>
                                </TableHead>

                                {this.state.userData.map((_data: any, index: any) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Box className="UserDocRowSpace"></Box>
                                            <TableBody>
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={_data.id}
                                                    className="UserDocTableRowBg"
                                                >
                                                    <TableCell scope="row" align="left" className="UserDocTableDataUserName">
                                                        <Typography
                                                             className={`fontSizeBody UserDocDataUserNameText ${this.props.classes.bodyText_font}`}
                                                        >
                                                            {_data.account?.attributes?.first_name} {_data.account?.attributes?.last_name}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell scope="row" align="left" className="UserDocTableDataCell">
                                                        <Typography
                                                             className={`fontSizeBody UserDocDataOtherText ${this.props.classes.bodyText_font}`}
                                                        >
                                                            {moment(_data?.date).format("DD/MM/YYYY")}
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell scope="row" align="left" className="UserDocTableDataCell">
                                                        <Typography
                                                             className={`fontSizeBody UserDocDataOtherText ${this.props.classes.bodyText_font}`}
                                                        >
                                                            {_data.account?.attributes?.school_account?.data[0]?.attributes?.class?.class_name}
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell scope="row" align="left" className="UserDocTableDataCell">
                                                        <Typography className={`fontSizeBody UserDocDataUserNameText ${this.props.classes.bodyText_font}`}>
                                                            {_data?.under_review}
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell scope="row" align="left" className="UserDocTableDataCell">
                                                        <Typography
                                                             className={`fontSizeBody UserDocDataOtherText ${this.props.classes.bodyText_font}`}
                                                        >
                                                            {_data?.approved}
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell scope="row" align="left" className="UserDocTableDataCell">
                                                        <Typography
                                                             className={`fontSizeBody UserDocDataOtherText ${this.props.classes.bodyText_font}`}
                                                        >
                                                            {_data?.rejected}
                                                        </Typography>

                                                    </TableCell>

                                                    <TableCell scope="row" align="left" className="UserDocTableDataView">
                                                        <Typography
                                                            className={`fontSizeBody UserDocDataViewText ${this.props.classes.bodyText_font}`}
                                                            onClick={() => this.viewdetails(_data.account?.id)}
                                                        >
                                                            View
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </React.Fragment>
                                    )
                                })}
                            </Table>
                        </TableContainer>
                        {this.state.userData.length === 0 && <span className="NoUserDataFoundText">No Data Found.</span>}
                        {this.state.totalPage > 1 && <Box className="UserDocPaginationBox">
                            <ReactPaginate
                                previousLabel={"←   Previous"}
                                nextLabel={"Next   →	"}
                                initialPage={this.state.currentPage}
                                forcePage={this.state.currentPage}
                                pageCount={this.state.totalPage}
                                onPageChange={(e: any) => this.setState({ currentPage: e.selected }, () => {
                                    this.get_user()

                                })}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                            />
                        </Box>}
                    </Grid>
                </Grid>

                <AlertModal
                    alertModal={this.state.alertModal}
                    isTeacher={false}
                    onCloseModal={() => { this.setState({ alertModal: false }) }}
                    alertTitle="Alert!"
                    alertMessage={this.state.alertMessage}
                    buttonText="OK"
                />

            </Box>
        )
    }
}

export const DashboardCreateStyle = {
    dropdown: {
        padding: 0,
        border: "1px solid #e6e6e6",
        height: 45,
        width: 180,
        color: "#3f526d",
        fontFamily: "Open sans",
        fontWeight: 400,
        fontSize: 18,
        paddingLeft: 16,
        outline: "none",
        borderRadius: 9,
        zIndex: 12,
        display: "flex",
        alignItems: "center",
        "&:focus": {
            borderRadius: 9
        }
    }
};

  export default withStyles(themeCreateStyle)(
    StudentDocument as React.ComponentType<any>
  );


// Customizable Area End
