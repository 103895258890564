// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createRef, RefObject } from "react";
import { toast } from "react-toastify";
import { threadId } from "worker_threads";
import { AnySrvRecord } from "dns";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area End


// Customizable Area Start
export const configJSON = require("../AssessmentQuestionBankLibrary/config");
// Customizable Area End
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  uploadModal: boolean,
  spinner: boolean,
  isMedia: boolean,
  files: any,
  alertModal: boolean,
  alertMessage: string,
  errorModal: boolean,
  uploadResponse: any,
  isErrorCSV: boolean;
  page: number;
  errorCSVMetaData: any;
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any
  // Customizable Area End
}
export default class AssessmentUploadController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  readonly inputOpenFileRef: RefObject<HTMLInputElement>;

  apiAssessmentUpload: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area Start
    this.inputOpenFileRef = createRef<HTMLInputElement>();
    this.state = {
      uploadModal: false,
      spinner: false,
      isMedia: true,
      files: undefined,
      errorModal: false,
      alertModal: false,
      alertMessage: '',
      uploadResponse: {},
      isErrorCSV: false,
      page: 0,
      errorCSVMetaData: {},
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }
  navigationBackHandler = () => {
    HISTORY.goBack()
  }
  onClickUploadCSVHandler = () => {
    this.setState({
      isMedia: true,
      isErrorCSV: false,
    })
  }
  onClickErrorCSVHandler = () => {
    this.setState({
      isErrorCSV: true,
      isMedia: false,
    })
  }
  onClickBrowseHandler = () => {
    this.inputOpenFileRef?.current?.click()
  }
  onChangeInputHandler = (e: any) => {
    let totalSize: number = 0;
    var files = e.target.files[0];
    let fileExtension = files.name.split('.').pop()
    if (fileExtension !== 'csv'
    ) {
      this.setState({
        alertMessage: "only csv file can upload",
        alertModal: true
      })
    } else {
      const bulkFileUploaded = [files];
      this.setState({
        files: bulkFileUploaded
      })
    }
  }
  onClickCancelHandler = () => {
    this.setState({
      files: undefined
    })
  }
  paginationChangeHandler = (e: any) => {
    if (e.selected !== this.state.page) {
      this.setState({ page: e.selected },)

    }
  }
  uploadOnCloseHandler = () => {
    this.setState({
      uploadModal: false
    })
  }
  alertCloseHandler = () => {
    this.setState({ alertModal: false });
  }
  errorReUploadHandler = () => {
    this.setState({
      files: undefined
    })
  }
  errorCloseHandler = () => {
    this.setState({
      errorModal: false,
      files: undefined
    })
  }
  isMediaOnDropHandler = (files: any) => {
    window.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
      },
      false
    );
    var filesObj = files[0];
    let fileExtension = filesObj.name.split('.').pop()
    if (this.state.isMedia) {
      if (fileExtension !== 'csv') {
        this.setState({
          alertMessage: "only csv file can upload",
          alertModal: true
        })
      } else {
        const bulkFileUploaded = [filesObj];
        this.setState({
          files: bulkFileUploaded
        })
      }
    } else {
      this.setState({
        alertMessage: "only csv file can upload",
        alertModal: true
      })
    }
  }
  metaDataHandler = (metaData: any) => {
    this.setState({
      errorCSVMetaData: metaData,
    })
  }
  uploadAssessment = () => {
    this.setState({
      spinner: true
    })
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: data.school_id
    };
    const FormData = require('form-data');
    let bodyFormData = new FormData();
    bodyFormData.append("file", this.state.files[0])
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAssessmentUpload = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiAssessmentUpload
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      bodyFormData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleUpload = (response: any) => {
    const res = response.data
    if (res) {
      if (res.fail_count) {
        this.setState({
          uploadResponse: res,
          errorModal: true
        })
        toast.warning(response.message);
      } else if (res.message) {
        this.setState({
          uploadResponse: res,
          uploadModal: true
        })
        toast.success(res.message)
      }
      else {
        this.setState({
          files: undefined
        })
        toast.error(response.message ? response.message[0] : "Something Went Wrong")
      }
    }
  }
  // Customizable Area End


  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apiAssessmentUpload) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              spinner: false
            })
            this.handleUpload(responseJson)
          } else {
            this.setState({
              spinner: false
            })
            this.parseApiErrorResponse(responseJson);
            // toast.error("Something went wrong!")
          }
        }
      }

    }
    // Customizable Area End
  }

}