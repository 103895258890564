// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { List, ListItem,FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Grid ,Typography} from '@material-ui/core';
import { HISTORY } from "../../../../components/src/comman";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
}),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    }
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

function createData(title: string, term: number, term2: number, term3: number) {
  return { title, term, term2, term3 };
}

const rows = [
  createData('Total Attendence of the learner', 20, 10, 5),
  createData('Total Number of working days', 20, 10, 4.0)
];


function ReportCardPartOneFourFive(props:any) {
  const classes = useStyles();
  const [selectclass, setSelectclass] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date('2014-08-18T21:11:54'),
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectclass(event.target.value as string);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleBack = () => {
    HISTORY.goBack();
  }

  return (
    <div>
      <div className='profileTitle'>
      <Button className="backBtn" onClick={()=>handleBack()}><KeyboardBackspaceOutlinedIcon /> Back</Button>
      <h2 className={props.classes.heading_font}>Part 1 - Assessment of Learning </h2>
      <Button className={props.classes.button_color} style={{position: "absolute",right: 0,marginRight: "10px"}}>Enable Edit <EditOutlinedIcon /> </Button>
      </div>
      <div className="tableContent">
         {/*  table 1 start */}
         <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={`${props.classes.reportCardOneSpartOneFourFive_classAndSection_tableHeadCell} ${props.classes.bodyText_font} `} colSpan={8} align="center" style={{borderRadius:"0px"}}>
                                Term 1 (100 Marks)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                             <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                              <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{borderRadius:"0px"}}>
                                <Typography align="center" >Weekly Test</Typography>
                                <Box style={{ margin: '-16px', paddingTop: "29px" }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_box_grid} style={{ borderLeft: "0px", borderBottom: "0px" }}>
                                            WA<br />(20)
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_box_grid} style={{ borderBottom: "0px"}}>
                                            WA<br />(20)
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_box_grid} style={{ borderBottom: "0px"}}>
                                            WA<br />(20)
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>

                           <TableCell align="center" className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tableBodyCell} style={{borderRadius:"0px" }}>
                                <Typography>
                                PA 1<br/> Best of 3
                                    </Typography>
                                    <Typography style={{ paddingTop: "30px" }}>
                                    (20)

                                </Typography>
                            </TableCell>
                           <TableCell align="center" className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tableBodyCell} style={{borderRadius:"0px" }}>
                                <Typography>
                                Notebook<br/> Maintenance
                                    </Typography>
                                    <Typography style={{ paddingTop: "30px" }}>
                                    (10)

                                </Typography>
                            </TableCell>
                           <TableCell align="center" className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tableBodyCell} style={{borderRadius:"0px" }}>
                                <Typography>
                                Subject<br/> Enrichment
                                    </Typography>
                                    <Typography style={{ paddingTop: "30px" }}>
                                    (20)

                                </Typography>
                            </TableCell>
                           <TableCell align="center" className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tableBodyCell} style={{borderRadius:"0px" }}>
                                <Typography>
                                CCSA 1
                                    </Typography>
                                    <Typography style={{ paddingTop: "46px" }}>
                                    (50)

                                </Typography>
                            </TableCell>
                           <TableCell align="center" className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tableBodyCell} style={{borderRadius:"0px" }}>
                                <Typography>
                                Marks<br/>Obtained
                                    </Typography>
                                    <Typography style={{ paddingTop: "30px" }}>
                                    (100)

                                </Typography>
                            </TableCell>
                           <TableCell align="center" className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tableBodyCell} style={{borderRadius:"0px" }}>
                                <Typography>
                                Grade
                                    </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{ borderRadius:"0px" }}>
                            <Typography> Language 1:<br/> English</Typography>
                            </TableCell>

                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={4} className={`test ${props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid}`} style={{ borderLeft: "0px", borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={`test ${props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid}`} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={`test ${props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid}`} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{borderRadius:"0px"}}>

                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{ borderRadius:"0px" }}>
                                Language 2
                            </TableCell>
                           <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderLeft: "0px", borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }}>

                            </TableCell>


                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{ borderRadius:"0px" }}>
                                Language 3
                            </TableCell>
                           <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderLeft: "0px", borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }}>

                            </TableCell>


                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{ borderRadius:"0px" }}>
                                Mathematics
                            </TableCell>
                           <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderLeft: "0px", borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }}>

                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{ borderRadius:"0px" }}>
                                EVS / Science
                            </TableCell>
                          <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderLeft: "0px", borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }}>

                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{ borderRadius:"0px" }}>
                                Social Science
                            </TableCell>
                          <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderLeft: "0px", borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{borderRadius:"0px"}}>

                            </TableCell>

                        </TableRow>

                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{ borderRadius:"0px" }}>
                                Computer
                            </TableCell>
                          <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderLeft: "0px", borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }}>

                            </TableCell>

                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer >

            {/* table 1 end */}

            {/* table 2 start */}
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={`${props.classes.reportCardOneSpartOneFourFive_classAndSection_tableHeadCell} ${props.classes.bodyText_font} `} colSpan={8} align="center" style={{ borderRadius:"0px"}}>
                                Term 2 (100 Marks)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{ borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} align="center" style={{borderRadius:"0px"}}>
                                <Typography align="center" >Weekly Test</Typography>
                                <Box style={{ margin: '-16px', paddingTop: "29px" }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabBoxGrid} style={{borderLeft:"0px", borderBottom: "0px" }}>
                                            WA<br />(20)
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabBoxGrid} style={{ borderBottom: "0px" }}>
                                            WA<br />(20)
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabBoxGrid} style={{ borderBottom: "0px" }}>
                                            WA<br />(20)
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                           <TableCell align="center" className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tableBodyCell} style={{borderRadius:"0px" }}>
                                <Typography>
                                PA 2<br/> Best of 3
                                    </Typography>
                                    <Typography style={{ paddingTop: "30px" }}>
                                    (20)

                                </Typography>
                            </TableCell>
                           <TableCell align="center" className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tableBodyCell} style={{borderRadius:"0px" }}>
                                <Typography>
                                Notebook<br/> Maintenance
                                    </Typography>
                                    <Typography style={{ paddingTop: "30px" }}>
                                    (10)

                                </Typography>
                            </TableCell>
                           <TableCell align="center" className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tableBodyCell} style={{borderRadius:"0px" }}>
                                <Typography>
                                Subject<br/> Enrichment
                                    </Typography>
                                    <Typography style={{ paddingTop: "30px" }}>
                                    (20)

                                </Typography>
                            </TableCell>
                           <TableCell align="center" className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tableBodyCell} style={{borderRadius:"0px" }}>
                                <Typography>
                                CCSA 2
                                    </Typography>
                                    <Typography style={{ paddingTop: "42px" }}>
                                    (50)

                                </Typography>
                            </TableCell>
                            <TableCell align="center" className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tableBodyCell} style={{borderRadius:"0px" }}>
                                <Typography>
                                Marks<br/>Obtained
                                    </Typography>
                                    <Typography style={{ paddingTop: "28px" }}>
                                    (100)

                                </Typography>
                            </TableCell>
                             <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{borderRadius:"0px"}}>
                             <Typography style={{ textAlign:"center" }}> Grade</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{ borderRadius:"0px" }}>
                            <Typography> Language 1:<br/> English </Typography>
                            </TableCell>

                          <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderLeft: "0px", borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }}>

                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{ borderRadius:"0px" }}>
                            <Typography> Language 2</Typography>
                            </TableCell>
                          <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderLeft: "0px", borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }}>

                            </TableCell>


                        </TableRow>
                        <TableRow>
                             <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{borderRadius:"0px"}}>
                            <Typography>Language 3</Typography>
                            </TableCell>
                          <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderLeft: "0px", borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }}>

                            </TableCell>


                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{ borderRadius:"0px" }}>
                            <Typography> Mathematics</Typography>
                            </TableCell>
                          <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderLeft: "0px", borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{borderRadius:"0px"}}>

                            </TableCell>

                        </TableRow>
                        <TableRow>
                             <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{borderRadius:"0px"}}>
                            <Typography> EVS / Science</Typography>
                            </TableCell>
                          <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderLeft: "0px", borderBottom: "0px"}}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{  borderBottom: "0px"}}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{  borderBottom: "0px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }}>

                            </TableCell>

                        </TableRow>
                        <TableRow>
                             <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{borderRadius:"0px"}}>
                            <Typography> Social Science</Typography>
                            </TableCell>
                          <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container >
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderLeft: "0px", borderBottom: "0px"}}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid}style={{  borderBottom: "0px"}}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px" }}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{borderRadius:"0px"}}>

                            </TableCell>

                        </TableRow>

                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabRowCell} style={{ borderRadius:"0px" }}>
                            <Typography> Computer</Typography>
                            </TableCell>
                          <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }} >
                                <Box style={{ margin: '-16px' }}>
                                    <Grid container>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{borderLeft:"0px", borderBottom: "0px"}}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px"}}>
                                        </Grid>
                                        <Grid item xs={4} className={props.classes.reportCardOneSpartOneFourFive_classAndSection_tabGrid} style={{ borderBottom: "0px"}}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell}>

                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSpartOneFourFive_classAndSection_border_cell} style={{ borderRadius:"0px" }}>

                            </TableCell>

                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer >

            {/* table 2 end */}
      </div>
    </div>
  )
}
// Customizable Area End


// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(ReportCardPartOneFourFive as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End
