// Customizable Area Start
import React from "react";
import {
    Box,
    Modal,
    Divider,
    Typography,
    Button,
    Grid,
} from "@material-ui/core";
import { errorImg } from "./assets";
import { withStyles } from '@material-ui/core/styles';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const titleDiv = {
    fontFamily: "Open sans",
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: 'center',
    color: "#3a3a3a",
    marginBottom: '34px',
} as const;
// Customizable Area End
interface Props {
    // Customizable Area Start
    alertModal: boolean;
    onCloseModal: any;
    classes: any;
    csvError: any;
    teacherRestircition?: boolean;
    // Customizable Area End

}

export const CsvError = ({
    alertModal,
    onCloseModal,
    csvError,
    teacherRestircition,
    classes
}: Props) => {
    // Customizable Area Start
    return (
        <Modal
            open={alertModal}
            onClose={onCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                style={{
                    justifyContent: "center",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    borderRadius: "29px",
                    padding: "15px 8px",
                    maxWidth: '650px'
                }}
            >
                <Grid item xs={12} style={{
                    maxHeight: '550px',
                    overflowY: 'auto',
                    padding: '10px 20px'
                }}>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <img src={errorImg} style={{
                            width: '162px',
                            height: '150px',
                            marginBottom: '30px'
                        }} />
                        <div style={titleDiv}>Please check the errors mentioned below, correct them and then {teacherRestircition ? "resubmit" : "reupload"}.</div>
                    </Grid>
                    {/* <Divider /> */}
                    {teacherRestircition ?
                        <Grid container >
                            {
                                csvError?.map((_data: any) => {
                                    return (
                                        <Grid item xs={12} style={{ marginBottom: '15px' }}>

                                            <Typography
                                                style={{

                                                    fontFamily: "Open sans",
                                                    fontSize: "16px",
                                                    fontWeight: 600,
                                                    fontStretch: "normal",
                                                    fontStyle: "normal",
                                                    lineHeight: "normal",
                                                    letterSpacing: "normal",
                                                    marginLeft: 10,
                                                    color: "#e02020"
                                                }}
                                            >

                                                {_data}
                                            </Typography>
                                        </Grid>)
                                })}

                        </Grid>
                        : <>
                            <Grid container>
                                <Grid item xs={5}><Typography
                                    style={{

                                        fontFamily: "Open sans",
                                        fontSize: "18px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "normal",
                                        //textAlign:'center',
                                        color: "rgba(0, 0, 0, 0.85)",
                                        marginBottom: 5
                                    }}
                                >
                                    For User ID
                                </Typography></Grid>
                                <Grid item xs={7}><Typography
                                    style={{

                                        fontFamily: "Open sans",
                                        fontSize: "18px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "normal",
                                        // textAlign:'center',
                                        color: "rgba(0, 0, 0, 0.85)",
                                        marginLeft: 10,
                                        marginBottom: 5
                                    }}
                                >
                                    Error Field
                                </Typography></Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container >
                                    {
                                        csvError?.map((_data: any) => {
                                            return (
                                                <Grid item xs={12} style={{ marginBottom: '15px' }}>
                                                    <Grid container>
                                                        <Grid item md={5} xs={12}>
                                                            <Typography
                                                                style={{

                                                                    fontFamily: "Open sans",
                                                                    fontSize: "18px",
                                                                    fontWeight: 600,
                                                                    fontStretch: "normal",
                                                                    fontStyle: "normal",
                                                                    lineHeight: "normal",
                                                                    letterSpacing: "normal",

                                                                    color: "rgba(0, 0, 0, 0.85)"
                                                                }}
                                                            >
                                                                {_data.email}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={7} xs={12}>
                                                            {_data.errors.map((_error: any) => {
                                                                return (


                                                                    <Grid item xs={12} style={{ marginBottom: 5 }}>
                                                                        <Typography
                                                                            style={{

                                                                                fontFamily: "Open sans",
                                                                                fontSize: "16px",
                                                                                fontWeight: 600,
                                                                                fontStretch: "normal",
                                                                                fontStyle: "normal",
                                                                                lineHeight: "normal",
                                                                                letterSpacing: "normal",
                                                                                marginLeft: 10,
                                                                                color: "#e02020"
                                                                            }}
                                                                        >

                                                                            {_error}
                                                                        </Typography>
                                                                    </Grid>


                                                                )
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </>}
                    <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button
                            style={{
                                background: "#3a60d7",
                                color: "#fff",
                                width: "22%",

                                textTransform: "capitalize",
                                fontSize: 18,
                            }}
                            onClick={onCloseModal}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
    // Customizable Area End
};
// Customizable Area Start
export default withStyles(themeCreateStyle)(CsvError);
// Customizable Area End