

export const adminDownload = require("../../assets/adminDownload.png")
export const adminFilter = require("../../assets/adminFilter.png")
export const moreOption = require("../../assets/image-more.png")
export const cancelOption = require("../../assets/image-cancelmark.png")
export const deleteAlert = require("../../assets/deleteIcon.png")
export const SuccessCheckMark = require("../../assets/succefullyIcon.png")
export const send = require("../../assets/image-downloadcredentials.png")
export const fileuploaded = require("../../assets/Groupfile done.png")
export const addMore = require("../../assets/addMoreClass.png")
export const deleteMore = require("../../assets/deleteClassAndSubject.png")
export const errorImg = require("../../assets/group-21.png")
export const reject = require("../../assets/reject.png")
export const approve = require("../../assets/approve.png")
export const editImg = require("../../assets/divisionEdit.png")
export const driveEdit = require("../../assets/driveEdit.png")
export const editAction = require("../../assets/editAction.png")