// Customizable Area Start
import React from "react";
import {
  Grid,
  Button,
  Typography,
  withStyles,
  Box,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Datetime from "react-datetime";
import TeacherTimeTableController, {
  ITimeTableEventsProps,
  Props,
} from "./TeacherTimeTableController.web";
import TimeTableEventWrapper from "./CalendarCustomFile/TimeTableEventWrapper.web";
import WeekEvent from "./CalendarCustomFile/WeekEvent.web";
import MonthEvent from "./CalendarCustomFile/MonthEvent.web";
import CustomToolbar from "./CalendarCustomFile/CustomToolbar.web";
import CustomHeader from "./CalendarCustomFile/CustomHeader.web";
import CustomWeekHeader from "./CalendarCustomFile/CustomWeekHeader.web";
import { camera_white } from "./assets";
import "./TimeTableEvents.css";
import "./TeacherTimeTableEvents.css";
import './TeacherTimeTable.web.css';
import ZoomMeeting from "../ZoomMeeting/ZoomMeeting.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import CreateOnlineLecture from "../CreateOnlineLecture.web";
import InputBase from "@material-ui/core/InputBase";
import TeacherCreateEventModal from "../TeacherCreateEvent.web";
import SelectedTab from "../SelectedTab.web";
import { ArrowDropDown } from "@material-ui/icons";
import { I_Support } from "./assets";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
moment.locale("en-GB", {
  week: {
    dow: 1,
    doy: 1,
  },
});
const today = moment();
const localizer = momentLocalizer(moment);
const formats = {
  weekdayFormat: (date: any, culture: any, localizer: any) =>
    localizer.format(date, "dddd", culture),
  dayFormat: (date: any, culture: any, localizer: any) =>
    localizer.format(date, "dddd", culture),
  timeGutterFormat: (date: any, culture: any, localizer: any) => {
    return `${localizer.format(date, "h A", culture)} - ${localizer.format(
      date.setHours(date.getHours() + 1),
      "h A",
      culture
    )}`;
  },
  dateFormat: (date: any, culture: any, localizer: any) =>
    localizer.format(date, "DD MMM", culture),
};
const BootstrapInput = withStyles((theme: any) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.background.paper,
    padding: "8px 26px 8px 12px",
    width: "140px",
    height: "35px",
    display: "flex",
    alignItems: "center",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: theme.props.textColor,
    fontFamily: theme.props.textFont,
    fontSize: 18,
    lineHeight: 1.2,
    "&:focus": {
      borderRadius: 8,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
// Customizable Area End
class TeacherTimeTable extends TeacherTimeTableController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  eventStyleGetter =
    () => (event: any, start: any, end: any, isSelected: any) => {
      const duration = moment.duration(
        moment(event.end).diff(moment(event.start))
      );
      const hours = duration.asHours();
      const size: number = hours * 92;
      var style = {
        backgroundColor: event.hexBgColor,
        color: "black",
        display: "block",
        maxHeight: hours === 1 ? `${size}px` : `${size + 8}px`,
      };
      return {
        style: style,
      };
    };

  DayEventContainer = () => (props: any) => {
    const { event } = props;
    return (
      <TimeTableEventWrapper
        event={event as ITimeTableEventsProps}
        isTeacher={true}
        joinMeeting={() =>
          this.setState({ selectedEvent: event }, () =>
            this.makeTeacherLiveClassHost()
          )
        }
        getTimeTableUpdatedData={this.apicall}
      />
    );
  };

  WeekEventContainer = () => (props: any) => {
    const { event } = props;
    return (
      <WeekEvent
        event={event as ITimeTableEventsProps}
        isTeacher={true}
        updateEvents={this.props.updateEvents}
        joinMeeting={() => {
          this.setState({ selectedEvent: event }, () => {
            this.makeTeacherLiveClassHost();
          });
        }}
        getTimeTableUpdatedData={this.apicall}
      />
    );
  };

  MonthEventContainer = () => (props: any) => {
    const { event } = props;
    return (
      <MonthEvent
        event={event as ITimeTableEventsProps}
        isTeacher={true}
        joinMeeting={() => {
          this.setState({ selectedEvent: event }, () =>
            this.makeTeacherLiveClassHost()
          );
        }}
      />
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    const { data } = this.state;
    return (
      <>
        {!this.state.isStartMeeting ? (
          <Box>
            <Grid
              container
              spacing={2}
              className='TeacherTimeTableMainGrid'
            >
              <Grid
                container
                spacing={2}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="TeacherTimeTableSubMainGrid"
              >
                <Grid
                  item
                  className="TeacherTimeTableTitleMainGrid"
                >
                  <Box
                    className="TeacherTimeTableTitleMainDiv"
                  >
                    <Typography
                      className={`TeacherTimeTableTitleTxt classesButtonText ${this.props.classes.heading_font}`}
                    >
                      Classes
                    </Typography>
                  </Box>
                  <div
                    className={`TeacherTimeTableYearTxt ${this.props.classes.bodyText_font}`}
                  >
                    2022-23
                  </div>
                </Grid>
                <Grid item>
                  <Select
                    className="Teacher-Slect"
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={
                      this.state.memberClassId
                        ? data.school_account.data.length != 0 &&
                        data.school_account.data.map(
                          (getTeacherClass: any) => {
                            if (
                              getTeacherClass.attributes.class.id ===
                              this.state.memberClassId
                            ) {
                              return getTeacherClass.attributes?.class
                                ?.display_name;
                            }
                          }
                        )
                        : "Select class"
                    }
                    onChange={(event) => {
                      console.log(event.target.value);
                      this.setState({ memberClassId: event.target.value });
                    }}
                    input={<BootstrapInput />}
                    IconComponent={ArrowDropDown}
                    renderValue={() =>
                      this.state.memberClassId
                        ? data.school_account.data.length != 0 &&
                        data.school_account.data.map(
                          (getTeacherClass: any) => {
                            if (
                              getTeacherClass.attributes.class.id ===
                              this.state.memberClassId
                            ) {
                              return getTeacherClass.attributes?.class
                                ?.display_name;
                            }
                          }
                        )
                        : "Select class"
                    }
                  >
                    <MenuItem value="">Select Class</MenuItem>
                    {data?.school_account?.data?.length != 0 &&
                      data?.school_account?.data.map((getTeacherClass: any) => (
                        <MenuItem value={getTeacherClass.attributes.class.id}>
                          {getTeacherClass.attributes?.class?.display_name}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>

              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                xl={6}
                sm={12}
                xs={12}
                className='TeacherTimeTableCreateEventBtnGrid'
              >
                <Button
                  className="TeacherTimeTableCreateEventBtn event-btn"
                  onClick={() => {
                    this.setState({
                      creatEventModal: !this.state.creatEventModal,
                    });
                  }}
                >
                  <img src={I_Support} />
                  <Typography
                    className="TeacherTimeTableCreateEventBtnTxt"
                  >
                    Create Event
                  </Typography>
                </Button>
              </Grid>

            </Grid>
            <Box>
              <SelectedTab isTeacher={true} type={"Time Table"} />
            </Box>
            <TeacherCreateEventModal
              createEventModal={this.state.creatEventModal}
              navigation={this.props.navigation}
              getSchoolPeriod={this.state.getSchoolPeriod}
              Events={this.state.Events}
              updateEvents={(bool: boolean) => {
                this.setState({
                  Events: bool,
                });
              }}
              handleOnClose={() => {
                this.setState({
                  creatEventModal: false,
                });
              }}
            />
            <Grid
              container
              className="TeacherTimeTableTodayBtnGrid"
            >
              <Grid item xs={12}>
                <Grid container className="TeacherTimeTableTodayBtnSubGrid">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    className={"TeacherTimeTableTodayBtnSubDiv grid-sibling1"}
                  >
                    <Grid container className="TeacherTimeTableTodayBtnSubGrid ">
                      <Grid item xs={12} sm={3}>
                        <div
                          onClick={() => {
                            this.getStudentPeriods(
                              moment(new Date()).toDate(),
                              this.state.selectedView
                            );
                          }}
                        >
                          {moment(this.state.date, "DD-MM-YYYY").isSame(
                            moment(),
                            "day"
                          ) ? (
                            <span
                              className={`TeacherTimeTableBtn today-btn ${this.props.classes.timeTable_classAndSection_active_button_color}`}
                            >
                              Today
                            </span>
                          ) : (
                            <span
                              className={`TeacherTimeTableBtn today-btn ${this.props.classes.timeTable_classAndSection_active_border_color}`}
                            >
                              Today
                            </span>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={9} md={8}>
                        <div
                          className="TeacherTimeTableCalendarDiv faltuCalender Attendance-flex"
                        >
                          <Datetime
                            dateFormat={"DD MMMM,YYYY"}
                            timeFormat={false}
                            strictParsing={true}
                            input={true}
                            value={moment(this.state.date, "DD-MM-YYYY").format(
                              "DD MMMM, YYYY"
                            )}
                            initialValue={moment().format("DD MMMM, YYYY")}
                            className="date-input"
                            inputProps={{
                              placeholder: "Date of Birth",
                              className: "Date_of_birth_calendar_Icon_register",
                              readOnly: true,
                            }}
                            closeOnSelect
                            onChange={(date) => {
                              this.setState({ date: date });
                              this.getStudentPeriods(
                                date,
                                this.state.selectedView
                              );
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    className={"TeacherTimeTableBtnMainDiv grid-sibling2 FlexEnd responsive-margin-top"}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <Button
                      className={`TeacherTimeTableDailyBtnDiv daily-btn ${this.state.selectedView === "day"
                        ? this.props.classes.timeTable_classAndSection_active_button_color
                        : this.props.classes.timeTable_classAndSection_active_border_color
                        }`}
                      onClick={() => this.viewClick("day")}
                    >
                      Daily
                    </Button>
                    <Button
                      className={`TeacherTimeTableWeeklyBtnDiv daily-btn ${this.state.selectedView === "week"
                        ? this.props.classes.timeTable_classAndSection_active_button_color
                        : this.props.classes.timeTable_classAndSection_active_border_color
                        }`}
                      onClick={() => this.viewClick("week")}
                    >
                      Weekly
                    </Button>
                    <Button
                      className="TeacherTimeTableAddClassBtnDiv calendar-view-btn addclass-btn"
                      onClick={() => {
                        this.setState({
                          createEventModal: true,
                        });
                      }}
                    >
                      <>
                        <img
                          src={camera_white}
                          alt=""
                          className="TeacherTimeTableAddClassBtnImg less-padding-small-screen"
                        />

                        <Typography
                          className="TeacherTimeTableAddClassBtnTxt"
                        >
                          Add Class
                        </Typography>
                      </>
                    </Button>
                  </Grid>
                </Grid>
                {this.state.createEventModal && (
                  <CreateOnlineLecture
                    createEventModal={this.state.createEventModal}
                    savecloseModalFun={() => {
                      this.setState({ createEventModal: false });
                      this.apicall();
                    }}
                    closeModalFun={() => {
                      this.setState({ createEventModal: false });
                    }}
                  />
                )}
              </Grid>

              <Grid
                item
                xs={12}
                className='TeacherTimeTableFullCalendarDiv'
              >
                <Calendar
                  formats={formats}
                  events={this.state.calendarEvents}
                  localizer={localizer}
                  dayLayoutAlgorithm="no-overlap"
                  className="small-calendar"
                  min={new Date(today.year(), today.month(), today.date(), 0)}
                  max={new Date(today.year(), today.month(), today.date(), 23)}
                  startAccessor="start"
                  endAccessor="end"
                  date={this.state.date}
                  view={this.state.selectedView}
                  views={["month", "week", "day"]}
                  defaultView={this.state.selectedView}
                  style={{ minHeight: "100vh", overflowX: "auto" }}
                  eventPropGetter={this.eventStyleGetter()}
                  timeslots={1}
                  step={60}
                  onShowMore={(events, date) =>
                    this.setState({ date: date, selectedView: "day" })
                  }
                  components={{
                    month: {
                      event: this.MonthEventContainer(),
                      header: CustomHeader,
                    },
                    week: {
                      event: this.WeekEventContainer(),
                      header: CustomWeekHeader,
                    },
                    day: {
                      event: this.DayEventContainer(),
                    },
                    toolbar: CustomToolbar,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <ZoomMeeting currentEvent={this.state.selectedEvent} isTeacher />
        )}
        {this.state.showSpinner && (
          <Spinner spinnerModal={this.state.showSpinner} />
        )}
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(TeacherTimeTable);
// Customizable Area End
