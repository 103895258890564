import React from 'react';
// Customizable Area Start
import { StyleSheet, Platform } from 'react-native';
import { Chart } from 'react-google-charts';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import Calendar from 'react-calendar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LinearProgress, {
  LinearProgressProps,
} from '@material-ui/core/LinearProgress';
import Spinner from '../../shared/SideBar/src/Spinner';
import { FileDrop } from 'react-file-drop';
import {
  Avatar,
  Box,
  Checkbox,
  Button,
  CircularProgress,
  IconButton,
  Link,
  Select,
  TextField,
  NativeSelect,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import {
  Search,
  Replay,
  NotificationsNone,
  HeadsetMicOutlined,
  Title,
  TitleOutlined,
  KeyboardArrowLeft,
  EditOutlined,
  MoreVertOutlined,
  KeyboardArrowDown,
  ArrowDropDown,
  BorderTop,
} from '@material-ui/icons';
import PublicIcon from '@material-ui/icons/Public';
import {
  back,
  chat,
  closeBack,
  doc,
  download,
  folder,
  goal,
  heart,
  pdf,
  share,
  xls,
} from './assets';
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
} from '@material-ui/core/styles';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import Datetime from 'react-datetime';
import { RFValue } from 'react-native-responsive-fontsize';
import moment from 'moment';
import AlertModal from '../../alert/src/AlertModal.web';
import StudentAddResourceAndGoalsController, {
  Props,
} from './StudentAddResourceAndGoalsController.web';
import { uploadFile } from '../../profiles/src/assets';
import { Prompt } from 'react-router-dom';
import HOCAuth from '../../shared/Header/src/HOCAuth.web';
// Customizable Area End

// Customizable Area Start
const avtarIcon = {
  width: '31px',
  height: '31px',
  margin: '20px 23px 20px 19px',
} as const;

const selectedDiv = {
  width: '16%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  border: 'solid 0.5px #471c51',
  borderRadius: 8,
  alignItems: 'center',
  cursor: 'not-allowed',
  height: 55,
} as const;
const selectedResourceDiv = {
  width: '16%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  border: 'solid 0.5px #471c51',
  margin: '0 29px 0px 0px',
  borderRadius: 8,
  alignItems: 'center',
  cursor: 'not-allowed',
  height: 55,
} as const;
const unselectedResourceDiv = {
  width: '16%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  border: 'solid 0.5px #471c51',
  margin: '0 29px 0px 0px',
  borderRadius: 8,
  alignItems: 'center',
  cursor: 'pointer',
  height: 55,
} as const;
const unSelectedDiv = {
  width: '16%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  border: 'solid 0.5px #471c51',
  borderRadius: 8,
  alignItems: 'center',
  cursor: 'pointer',
  height: 55,
} as const;
// Customizable Area End
class StudentAddResouceAndGoals extends StudentAddResourceAndGoalsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { title, descprition, uploadFile, startDate, endDate } = this.state;
    const today = moment();
    const disableFutureDt = (current: any) => {
      return current.isAfter(moment().subtract('1', 'days'));
    };
    const BootstrapInput = withStyles((theme) => ({
      root: {
        'label + &': {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: 'relative',
        border: 'solid 1px #ebebeb',
        backgroundColor: '#fff',
        padding: '13px 22px',
        height: 30,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: 'Open sans',
        color: '#000',
        fontWeight: 600,
        fontSize: '1.125rem',
        '&:focus': {
          borderRadius: 4,
          borderColor: '#80bdff',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
      },
    }))(InputBase);
    return (
      <>
        <Prompt
          when={
            title !== '' ||
            descprition !== '' ||
            uploadFile.length !== 0 ||
            startDate !== moment().format('DD-MM-YYYY') ||
            endDate !== moment().format('DD-MM-YYYY')
          }
          message={(location) =>
            `Are you sure you want to leave? \n there are unsaved changes.`
          }
        />

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              style={{
                margin: '21px 0px 21px 0',
                fontSize: '1.25rem',
                color: '#3f526d',
                fontWeight: 600,
                cursor: 'pointer',
              }}
              onClick={() => this.backButton()}
            >
              <img
                src={back}
                style={{ width: '18px', height: '16px', marginRight: '6px' }}
              />{' '}
              Back
            </Typography>
          </Box>
        </Box>
        {this.state.loadingSpinner && (
          <Spinner spinnerModal={this.state.loadingSpinner} />
        )}
        <Box
          style={{
            marginTop: 20,
            marginBottom: 25,
            padding: '20px 19px 50px 20px',
            borderRadius: 8,
            boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
          >
            <Box
              style={
                this.state.addProtfolioType === 'Resource'
                  ? selectedResourceDiv
                  : unselectedResourceDiv
              }
              onClick={this.goToResource}
            >
              <img
                src={folder}
                style={{ width: 36, height: 36, margin: '0 10px 0 0' }}
              />
              <Typography
                style={{
                  fontSize: '1.25rem',
                  color: '#3f526d',
                  fontWeight: 600,
                  backgroundColor: '#fff',
                  borderRadius: '0px 8px 8px 0px',
                  cursor: 'pointer',
                }}
              >
                Add Resource
              </Typography>
            </Box>
            <Box
              style={
                this.state.addProtfolioType === 'Goals'
                  ? selectedDiv
                  : unSelectedDiv
              }
              onClick={this.goToGoals}
            >
              <img
                src={goal}
                style={{ width: 36, height: 36, margin: '0 10px 0 0' }}
              />
              <Typography
                style={{
                  fontSize: '1.25rem',
                  color: '#3f526d',
                  fontWeight: 600,
                  backgroundColor: '#fff',
                  borderRadius: '0px 8px 8px 0px',
                  cursor: 'pointer',
                }}
              >
                New Goal
              </Typography>
            </Box>
          </Box>
          {this.state.addProtfolioType === 'Resource' && (
            <>
              <Box
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'start',
                  margin: '23px 1px',
                  position: 'relative',
                }}
              >
                <Typography
                  style={{
                    textTransform: 'none',
                    fontSize: '1.125rem',
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    color: '#3f526d',
                    margin: '2px 24px 15px 0px',
                  }}
                >
                  Title*
                </Typography>
                <Box
                  style={{
                    margin: '0px 0px 16px 0px',
                  }}
                >
                  <TextField
                    id="standard-basic"
                    type="text"
                    style={{
                      width: '100%',

                      borderRadius: 6,
                      margin: '0px 35px 0px 1px',

                      border: '0.5px solid #af38cb',
                    }}
                    placeholder="Add title here..."
                    variant="outlined"
                    inputProps={{
                      style: {
                        fontFamily: 'Open sans',
                        fontSize: '1.125rem',
                        color: '#3f526d',
                        opacity: 0.8,
                      },
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value.trim();
                      console.log(value);
                      this.setState({ title: value });
                      if (value.length > 0) {
                        this.setState({
                          titleError: false,
                        });
                      }
                    }}
                  />
                </Box>
                {this.state.titleError && (
                  <FormHelperText
                    style={{
                      position: 'absolute',
                      marginTop: '-15px',
                      color: 'red',
                    }}
                  >
                    {this.state.titleErrMessage}
                  </FormHelperText>
                )}
              </Box>
              <Box
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'start',
                  margin: '23px 1px',
                }}
              >
                <Typography
                  style={{
                    textTransform: 'none',
                    fontSize: '1.125rem',
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    color: '#3f526d',
                    margin: '2px 24px 15px 0px',
                  }}
                >
                  Description
                </Typography>
                <Box
                  style={{
                    margin: '0px 0px 16px 0px',
                  }}
                >
                  <TextField
                    id="standard-basic"
                    type="text"
                    style={{
                      width: '100%',

                      borderRadius: 6,
                      margin: '0px 35px 0px 1px',

                      border: '0.5px solid #af38cb',
                    }}
                    placeholder="Add description here..."
                    variant="outlined"
                    inputProps={{
                      style: {
                        fontFamily: 'Open sans',
                        fontSize: '1.125rem',
                        color: '#3f526d',
                        opacity: 0.8,
                      },
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value.trim();
                      console.log(value);
                      this.setState({ descprition: value });
                    }}
                  />
                </Box>
              </Box>
              <FileDrop
                onDrop={(files: any, event: any) => {
                  this.handleUpload(event, true, files);
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    border: '1px dashed #8b78fd',
                    backgroundColor: '#fff',
                    borderRadius: '12px',
                    padding: '34px 98px 34px 98px',
                  }}
                >
                  <Box>
                    <img
                      src={folder}
                      style={{
                        width: 60,
                        height: 60,
                        margin: '0 0px 27px 0px',
                        objectFit: 'none',
                      }}
                    />
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      style={{
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontFamily: 'Open Sans',
                        color: '#3f526d',
                      }}
                    >
                      Drag and drop files here or
                    </Typography>
                    <input
                      style={{ display: 'none' }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      accept="image/png, image/jpg, image/jpeg, image/gif ,application/pdf , application/msword ,application/vnd.ms-excel , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={(event: React.ChangeEvent<any>) => {
                        this.handleUpload(event, false, null);
                      }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        style={{
                          backgroundColor: 'white',
                          boxShadow: 'none',
                          textTransform: 'none',
                          lineHeight: '0',
                          fontFamily: 'Open Sans',
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          color: '#3f526d',
                          textDecoration: 'underline',
                        }}
                        component="span"
                      >
                        browse.
                      </Button>
                    </label>
                    <Typography
                      style={{
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontFamily: 'Open Sans',
                        color: '#3f526d',
                      }}
                    >
                      them from your computer
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontFamily: 'Open Sans',
                        color: '#3f526d',
                        margin: '4px 0px 0',
                      }}
                    >
                      Supported images formats: .png .jpg .pdf .doc .xls or
                      .gif. Up to 10 files can be uploaded at once, should not
                      exceed 500MB
                    </Typography>
                  </Box>
                </Box>
              </FileDrop>
              {this.state.fileError && (
                <FormHelperText
                  style={{
                    position: 'relative',
                    marginTop: '05px',
                    color: 'red',
                  }}
                >
                  {this.state.fileErrMessage}
                </FormHelperText>
              )}
              {console.log(this.state.uploadFile)}
              {this.state.uploadFile.map((row: any, index: any) => {
                console.log('row ===>', row);
                return (
                  <Box
                    style={{
                      padding: '15px 20px',
                      borderRadius: 6,

                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '15px 0',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        margin: '0px 20px 0px 0',
                      }}
                    >
                      {row.content_type === 'image/jpeg' ||
                        row.content_type === 'image/png' ||
                        row.content_type === 'image/gif' ? (
                        <img
                          src={row.localUrl}
                          style={{ height: 55, width: '60px' }}
                        />
                      ) : row.content_type === 'application/pdf' ? (
                        <img src={pdf} style={{ height: 55 }} />
                      ) : row.content_type === 'application/msword' ||
                        row.content_type ===
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                        <img src={doc} style={{ height: 55 }} />
                      ) : row.content_type === 'application/vnd.ms-excel' ||
                        row.content_type ===
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                        <img src={xls} style={{ height: 55 }} />
                      ) : (
                        ''
                      )}

                      <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                          style={{
                            fontSize: '1.125rem',
                            color: '#202020',
                            fontFamily: 'Open sans',
                            margin: '0px 20px',
                            fontWeight: 500,
                          }}
                        >
                          {row.fileName}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: '0px 20px 0px 0',
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: '0.875rem',
                              color: 'black',
                              fontFamily: 'Open sans',
                              margin: '0px 5px 0px 20px',
                              fontWeight: 500,
                            }}
                          >
                            {row.fileSize > 1048576
                              ? `${(row.fileSize / 1048576).toFixed(2)} MB`
                              : `${(row.fileSize / 1024).toFixed(2)} KB`}
                          </Typography>
                        </div>
                      </Box>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        style={{
                          margin: '0 10px 0 0px',
                        }}
                      >
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          style={{ padding: 0 }}
                          onClick={() => {
                            this.deleteDoc(index);
                          }}
                        //     onClick={(event: React.MouseEvent<HTMLElement>) => {
                        //         this.setState({ task_id: task.id, anchorEl: event.currentTarget })
                        //     }}
                        >
                          <img
                            src={closeBack}
                            style={{
                              margin: '3px 4px 1px 0',
                              width: 25,
                              height: 25,
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                );
              })}

              <Box
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'start',
                  margin: '23px 1px',
                }}
              >
                <Typography
                  style={{
                    textTransform: 'none',
                    fontSize: '1.125rem',
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    color: '#3f526d',
                    margin: '2px 24px 15px 0px',
                  }}
                >
                  Date
                </Typography>
                <Box display="flex" alignItems="center" width="30%">
                  <Datetime
                    dateFormat={'DD MMMM,YYYY'}
                    timeFormat={false}
                    strictParsing={true}
                    input={true}
                    isValidDate={disableFutureDt}
                    value={moment(this.state.startDate, 'DD-MM-YYYY').format(
                      'DD MMMM,YYYY'
                    )}
                    initialValue={moment().format('DD MMMM,YYYY')}
                    // value={this.state.dateofbirth}
                    // className={
                    //     this.state.dateerror
                    //         ? "input_date_css_error_format"
                    //         : "input_date_css_format"
                    // }
                    className="input_today_date_css_format"
                    inputProps={{
                      placeholder: 'Date of Birth',
                      className: 'Date_of_birth_calendar_Icon_register',
                      readOnly: true,
                    }}
                    // onClose={() => {
                    //     if (this.state.dateofbirth.length === 0) {
                    //         this.setState({
                    //             dateerror: true,
                    //             dateerrorText: " Please select date of birth",
                    //         });
                    //     }
                    // }}
                    onChange={(date) => {
                      console.log(date);

                      this.setState({
                        startDate: moment(date).format('DD-MM-YYYY'),
                      });
                    }}
                  />
                </Box>
              </Box>
              {/*
                            <Box
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: "start",
                                    margin: "23px 1px"
                                }}>
                                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0px 20px 0px 0", }}>
                                    <Checkbox
                                        value={this.state.draft}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        onChange={(event) => {
                                            this.setState({ draft: event.target.checked })
                                        }}
                                    // onClick={event =>
                                    //     this.handleCheckboxClick(event, data.id)
                                    // }

                                    />
                                    <Typography style={{
                                        fontSize: 16,
                                        color: "#3f526d",
                                        fontFamily: "Open sans",
                                        margin: "0px 10px",
                                    }}>
                                        Draft (Drafted are visible to Teachers & Principal)
                                    </Typography>
                                </Box>

                            </Box> */}

              <Box
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'start',
                  margin: '43px 1px 15px',
                }}
              >
                <Button
                  style={{
                    backgroundImage:
                      'linear-gradient(to top, #af38cb, #471c51), linear-gradient(to top, #af38cb, #471c51)',
                    borderRadius: '8px',
                    color: '#fff',
                    width: '260px',
                    height: 60,
                    textTransform: 'capitalize',
                    fontSize: '1.25rem',
                    marginRight: 24,
                  }}
                  //type="submit"
                  onClick={this.saveResource}
                >
                  Add
                </Button>

                <Button
                  variant="contained"
                  style={{
                    borderRadius: 8,
                    background:
                      'linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #3a60d7, #253873)',
                    fontSize: '1.125rem',
                    fontFamily: 'Open sans',
                    fontWeight: 600,
                    color: '#3f526d',
                    textTransform: 'none',
                    marginLeft: '10px',
                    borderStyle: 'solid',
                    borderWidth: '0.5px',
                    borderImageSource:
                      'linear-gradient(to top, #af38cb, #471c51 Infinity%)',
                    //borderColor:'#471c51',

                    width: 200,
                    height: 60,
                  }}
                  onClick={this.cancelResourceChanges}
                >
                  Cancel
                </Button>
              </Box>
            </>
          )}

          {this.state.addProtfolioType === 'Goals' && (
            <>
              <Box
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'start',
                  margin: '23px 1px',
                  position: 'relative',
                }}
              >
                <Typography
                  style={{
                    textTransform: 'none',
                    fontSize: '1.125rem',
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    color: '#3f526d',
                    margin: '2px 24px 15px 0px',
                  }}
                >
                  Title*
                </Typography>
                <Box
                  style={{
                    margin: '0px 0px 16px 0px',
                  }}
                >
                  <TextField
                    id="standard-basic"
                    type="text"
                    style={{
                      width: '100%',

                      borderRadius: 6,
                      margin: '0px 35px 0px 1px',

                      border: '0.5px solid #af38cb',
                    }}
                    placeholder="Add title here..."
                    variant="outlined"
                    inputProps={{
                      style: {
                        fontFamily: 'Open sans',
                        fontSize: '1.125rem',
                        color: '#3f526d',
                        opacity: 0.8,
                      },
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value.trim();
                      console.log(value);
                      this.setState({ title: value });
                      if (value.length > 0) {
                        this.setState({
                          titleError: false,
                        });
                      }
                    }}
                  />
                </Box>
                {this.state.titleError && (
                  <FormHelperText
                    style={{
                      position: 'absolute',
                      marginTop: '-15px',
                      color: 'red',
                    }}
                  >
                    {this.state.titleErrMessage}
                  </FormHelperText>
                )}
              </Box>
              <Box
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'start',
                  margin: '23px 1px',
                }}
              >
                <Typography
                  style={{
                    textTransform: 'none',
                    fontSize: '1.125rem',
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    color: '#3f526d',
                    margin: '2px 24px 15px 0px',
                  }}
                >
                  Description
                </Typography>
                <Box
                  style={{
                    margin: '0px 0px 16px 0px',
                  }}
                >
                  <TextField
                    id="standard-basic"
                    type="text"
                    style={{
                      width: '100%',

                      borderRadius: 6,
                      margin: '0px 35px 0px 1px',

                      border: '0.5px solid #af38cb',
                    }}
                    placeholder="Add description here..."
                    variant="outlined"
                    inputProps={{
                      style: {
                        fontFamily: 'Open sans',
                        fontSize: '0.9875rem',
                        color: '#3f526d',
                        opacity: 0.8,
                      },
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value.trim();
                      console.log(value);
                      this.setState({ descprition: value });
                    }}
                  />
                </Box>
              </Box>
              <FileDrop
                onDrop={(files: any, event: any) => {
                  this.handleUpload(event, true, files);
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    border: '1px dashed #8b78fd',
                    backgroundColor: '#fff',
                    borderRadius: '12px',
                    padding: '34px 98px 34px 98px',
                  }}
                >
                  <Box>
                    <img
                      src={folder}
                      style={{
                        width: 60,
                        height: 60,
                        margin: '0 0px 27px 0px',
                        objectFit: 'none',
                      }}
                    />
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      style={{
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontFamily: 'Open Sans',
                        color: '#3f526d',
                      }}
                    >
                      Drag and drop files here or
                    </Typography>
                    <input
                      style={{ display: 'none' }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      accept="image/png, image/jpg, image/jpeg, image/gif , application/pdf ,application/msword ,application/vnd.ms-excel ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={(event: React.ChangeEvent<any>) => {
                        this.handleUpload(event, false, null);
                      }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        style={{
                          backgroundColor: 'white',
                          boxShadow: 'none',
                          textTransform: 'none',
                          lineHeight: '0',
                          fontFamily: 'Open Sans',
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          color: '#3f526d',
                          textDecoration: 'underline',
                        }}
                        component="span"
                      >
                        browse.
                      </Button>
                    </label>
                    <Typography
                      style={{
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontFamily: 'Open Sans',
                        color: '#3f526d',
                      }}
                    >
                      them from your computer
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontFamily: 'Open Sans',
                        color: '#3f526d',
                        margin: '4px 0px 0',
                      }}
                    >
                      Supported images formats: .png .jpg .pdf .doc .xls or
                      .gif. Up to 10 files can be uploaded at once, should not
                      exceed 500MB
                    </Typography>
                  </Box>
                </Box>
              </FileDrop>
              {console.log(this.state.uploadFile)}
              {this.state.uploadFile.map((row: any, index: any) => {
                console.log('row ===>', row);
                return (
                  <Box
                    style={{
                      padding: '15px 20px',
                      borderRadius: 6,

                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '15px 0',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        margin: '0px 20px 0px 0',
                      }}
                    >
                      {row.content_type === 'image/jpeg' ||
                        row.content_type === 'image/png' ||
                        row.content_type === 'image/gif' ? (
                        <img
                          src={row.localUrl}
                          style={{ height: 55, width: '60px' }}
                        />
                      ) : row.content_type === 'application/pdf' ? (
                        <img src={pdf} style={{ height: 55 }} />
                      ) : row.content_type === 'application/msword' ||
                        row.content_type ===
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                        <img src={doc} style={{ height: 55 }} />
                      ) : row.content_type === 'application/vnd.ms-excel' ||
                        row.content_type ===
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                        <img src={xls} style={{ height: 55 }} />
                      ) : (
                        ''
                      )}

                      <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                          style={{
                            fontSize: '1.125rem',
                            color: '#202020',
                            fontFamily: 'Open sans',
                            margin: '0px 20px',
                            fontWeight: 500,
                          }}
                        >
                          {row.fileName}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: '0px 20px 0px 0',
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: '0.875rem',
                              color: 'black',
                              fontFamily: 'Open sans',
                              margin: '0px 5px 0px 20px',
                              fontWeight: 500,
                            }}
                          >
                            {row.fileSize > 1048576
                              ? `${(row.fileSize / 1048576).toFixed(2)} MB`
                              : `${(row.fileSize / 1024).toFixed(2)} KB`}
                          </Typography>
                        </div>
                      </Box>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        style={{
                          margin: '0 10px 0 0px',
                        }}
                      >
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          style={{ padding: 0 }}
                          onClick={() => {
                            this.deleteDoc(index);
                          }}
                        //     onClick={(event: React.MouseEvent<HTMLElement>) => {
                        //         this.setState({ task_id: task.id, anchorEl: event.currentTarget })
                        //     }}
                        >
                          <img
                            src={closeBack}
                            style={{
                              margin: '3px 4px 1px 0',
                              width: 25,
                              height: 25,
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',

                  textAlign: 'start',
                  margin: '23px 1px',
                }}
              >
                <Box
                  style={{
                    marginRight: '25px',
                  }}
                >
                  <Typography
                    style={{
                      textTransform: 'none',
                      fontSize: '1.125rem',
                      fontFamily: 'Open Sans',
                      fontWeight: 600,
                      color: '#3f526d',
                      margin: '2px 24px 15px 0px',
                    }}
                  >
                    Start Date
                  </Typography>
                  <Box display="flex" alignItems="center" width="30%">
                    <Datetime
                      dateFormat={'DD MMMM,YYYY'}
                      timeFormat={false}
                      strictParsing={true}
                      input={true}
                      isValidDate={disableFutureDt}
                      value={moment(this.state.startDate, 'DD-MM-YYYY').format(
                        'DD MMMM,YYYY'
                      )}
                      initialValue={moment().format('DD MMMM,YYYY')}
                      // value={this.state.dateofbirth}
                      // className={
                      //     this.state.dateerror
                      //         ? "input_date_css_error_format"
                      //         : "input_date_css_format"
                      // }
                      className="input_today_date_css_format"
                      inputProps={{
                        placeholder: 'Date of Birth',
                        className: 'Date_of_birth_calendar_Icon_register',
                        readOnly: true,
                      }}
                      // onClose={() => {
                      //     if (this.state.dateofbirth.length === 0) {
                      //         this.setState({
                      //             dateerror: true,
                      //             dateerrorText: " Please select date of birth",
                      //         });
                      //     }
                      // }}
                      onChange={(date) => {
                        console.log(date);

                        this.setState({
                          startDate: moment(date).format('DD-MM-YYYY'),
                        });
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography
                    style={{
                      textTransform: 'none',
                      fontSize: '1.125rem',
                      fontFamily: 'Open Sans',
                      fontWeight: 600,
                      color: '#3f526d',
                      margin: '2px 24px 15px 0px',
                    }}
                  >
                    End Date
                  </Typography>
                  <Box display="flex" alignItems="center" width="30%">
                    <Datetime
                      dateFormat={'DD MMMM,YYYY'}
                      timeFormat={false}
                      strictParsing={true}
                      input={true}
                      isValidDate={disableFutureDt}
                      value={moment(this.state.endDate, 'DD-MM-YYYY').format(
                        'DD MMMM,YYYY'
                      )}
                      initialValue={moment().format('DD MMMM,YYYY')}
                      // value={this.state.dateofbirth}
                      // className={
                      //     this.state.dateerror
                      //         ? "input_date_css_error_format"
                      //         : "input_date_css_format"
                      // }
                      className="input_today_date_css_format"
                      inputProps={{
                        placeholder: 'Date of Birth',
                        className: 'Date_of_birth_calendar_Icon_register',
                        readOnly: true,
                      }}
                      // onClose={() => {
                      //     if (this.state.dateofbirth.length === 0) {
                      //         this.setState({
                      //             dateerror: true,
                      //             dateerrorText: " Please select date of birth",
                      //         });
                      //     }
                      // }}
                      onChange={(date) => {
                        console.log(date);

                        this.setState({
                          endDate: moment(date).format('DD-MM-YYYY'),
                        });
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'start',
                  margin: '43px 1px 15px',
                }}
              >
                <Button
                  style={{
                    backgroundImage:
                      'linear-gradient(to top, #af38cb, #471c51), linear-gradient(to top, #af38cb, #471c51)',
                    borderRadius: '8px',
                    color: '#fff',
                    width: '260px',
                    height: 60,
                    textTransform: 'capitalize',
                    fontSize: '1.25rem',
                    marginRight: 24,
                  }}
                  //type="submit"
                  onClick={() => this.saveGoal()}
                >
                  Add to Goal
                </Button>

                <Button
                  variant="contained"
                  style={{
                    borderRadius: 8,
                    background:
                      'linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #3a60d7, #253873)',
                    fontSize: '1.125rem',
                    fontFamily: 'Open sans',
                    fontWeight: 600,
                    color: '#3f526d',
                    textTransform: 'none',
                    marginLeft: '10px',
                    borderStyle: 'solid',
                    borderWidth: '0.5px',
                    borderImageSource:
                      'linear-gradient(to top, #af38cb, #471c51 Infinity%)',
                    //borderColor:'#471c51',

                    width: 200,
                    height: 60,
                  }}
                  onClick={this.cancelGoalsChanges}
                >
                  Cancel
                </Button>
              </Box>
            </>
          )}

          {this.state.addProtfolioType === 'EditGoal' && (
            <>
              <Box
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'start',
                  margin: '23px 1px',
                  position: 'relative',
                }}
              >
                <Typography
                  style={{
                    textTransform: 'none',
                    fontSize: '1.125rem',
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    color: '#3f526d',
                    margin: '2px 24px 15px 0px',
                  }}
                >
                  Title*
                </Typography>
                <Box
                  style={{
                    margin: '0px 0px 16px 0px',
                  }}
                >
                  <TextField
                    id="standard-basic"
                    type="text"
                    style={{
                      width: '100%',

                      borderRadius: 6,
                      margin: '0px 35px 0px 1px',

                      border: '0.5px solid #af38cb',
                    }}
                    value={this.state.title}
                    placeholder="Add title here..."
                    variant="outlined"
                    inputProps={{
                      style: {
                        fontFamily: 'Open sans',
                        fontSize: '1.125rem',
                        color: '#3f526d',
                        opacity: 0.8,
                      },
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value.trim();
                      console.log(value);
                      this.setState({ title: event.target.value });
                      if (value.length > 0) {
                        this.setState({
                          titleError: false,
                        });
                      }
                    }}
                  />
                </Box>
                {this.state.titleError && (
                  <FormHelperText
                    style={{
                      position: 'absolute',
                      marginTop: '-15px',
                      color: 'red',
                    }}
                  >
                    {this.state.titleErrMessage}
                  </FormHelperText>
                )}
              </Box>
              <Box
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'start',
                  margin: '23px 1px',
                }}
              >
                <Typography
                  style={{
                    textTransform: 'none',
                    fontSize: '1.125rem',
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    color: '#3f526d',
                    margin: '2px 24px 15px 0px',
                  }}
                >
                  Description
                </Typography>
                <Box
                  style={{
                    margin: '0px 0px 16px 0px',
                  }}
                >
                  <TextField
                    id="standard-basic"
                    type="text"
                    style={{
                      width: '100%',

                      borderRadius: 6,
                      margin: '0px 35px 0px 1px',

                      border: '0.5px solid #af38cb',
                    }}
                    value={this.state.descprition}
                    placeholder="Add description here..."
                    variant="outlined"
                    multiline
                    rows={6}
                    inputProps={{
                      style: {
                        fontFamily: 'Open sans',
                        fontSize: '0.9875rem',
                        color: '#3f526d',
                        opacity: 0.8,
                      },
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value;
                      console.log(value);
                      this.setState({ descprition: value });
                    }}
                  />
                </Box>
              </Box>
              <FileDrop
                onDrop={(files: any, event: any) => {
                  this.handleUpload(event, true, files);
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    border: '1px dashed #8b78fd',
                    backgroundColor: '#fff',
                    borderRadius: '12px',
                    padding: '34px 98px 34px 98px',
                  }}
                >
                  <Box>
                    <img
                      src={folder}
                      style={{
                        width: 60,
                        height: 60,
                        margin: '0 0px 27px 0px',
                        objectFit: 'none',
                      }}
                    />
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      style={{
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontFamily: 'Open Sans',
                        color: '#3f526d',
                      }}
                    >
                      Drag and drop files here or
                    </Typography>
                    <input
                      style={{ display: 'none' }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      accept="image/png, image/jpg, image/jpeg, image/gif , application/pdf ,application/msword ,application/vnd.ms-excel , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={(event: React.ChangeEvent<any>) => {
                        this.handleUpload(event, false, null);
                      }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        style={{
                          backgroundColor: 'white',
                          boxShadow: 'none',
                          textTransform: 'none',
                          lineHeight: '0',
                          fontFamily: 'Open Sans',
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          color: '#3f526d',
                          textDecoration: 'underline',
                        }}
                        component="span"
                      >
                        browse.
                      </Button>
                    </label>
                    <Typography
                      style={{
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontFamily: 'Open Sans',
                        color: '#3f526d',
                      }}
                    >
                      them from your computer
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontFamily: 'Open Sans',
                        color: '#3f526d',
                        margin: '4px 0px 0',
                      }}
                    >
                      Supported images formats: .png .jpg .pdf .doc .xls or
                      .gif. Up to 10 files can be uploaded at once, should not
                      exceed 500MB
                    </Typography>
                  </Box>
                </Box>
              </FileDrop>
              {console.log(this.state.uploadFile)}
              {this.state.uploadFile.map((row: any, index: any) => {
                console.log('row ===>', row);
                return (
                  <Box
                    style={{
                      padding: '15px 20px',
                      borderRadius: 6,

                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '15px 0',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        margin: '0px 20px 0px 0',
                      }}
                    >
                      {row.content_type === 'image/jpeg' ||
                        row.content_type === 'image/png' ||
                        row.content_type === 'image/gif' ? (
                        <img
                          src={row.localUrl}
                          style={{ height: 55, width: '60px' }}
                        />
                      ) : row.content_type === 'application/pdf' ? (
                        <img src={pdf} style={{ height: 55 }} />
                      ) : row.content_type === 'application/msword' ||
                        row.content_type ===
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                        <img src={doc} style={{ height: 55 }} />
                      ) : row.content_type === 'application/vnd.ms-excel' ||
                        row.content_type ===
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                        <img src={xls} style={{ height: 55 }} />
                      ) : (
                        ''
                      )}

                      <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                          style={{
                            fontSize: '1.125rem',
                            color: '#202020',
                            fontFamily: 'Open sans',
                            margin: '0px 20px',
                            fontWeight: 500,
                          }}
                        >
                          {row.fileName}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: '0px 20px 0px 0',
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: '0.875rem',
                              color: 'black',
                              fontFamily: 'Open sans',
                              margin: '0px 5px 0px 20px',
                              fontWeight: 500,
                            }}
                          >
                            {row.fileSize > 1048576
                              ? `${(row.fileSize / 1048576).toFixed(2)} MB`
                              : `${(row.fileSize / 1024).toFixed(2)} KB`}
                          </Typography>
                        </div>
                      </Box>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        style={{
                          margin: '0 10px 0 0px',
                        }}
                      >
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          style={{ padding: 0 }}
                          onClick={() => {
                            this.deleteDoc(index);
                          }}
                        //     onClick={(event: React.MouseEvent<HTMLElement>) => {
                        //         this.setState({ task_id: task.id, anchorEl: event.currentTarget })
                        //     }}
                        >
                          <img
                            src={closeBack}
                            style={{
                              margin: '3px 4px 1px 0',
                              width: 25,
                              height: 25,
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              {this.state.editFiles.length >= 1 &&
                this.state.editFiles.map((row: any, index: any) => {
                  console.log('row ===>', row);
                  return (
                    <Box
                      style={{
                        padding: '15px 20px',
                        borderRadius: 6,

                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '15px 0',
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          margin: '0px 20px 0px 0',
                        }}
                      >
                        {row.attributes.content_type === 'image/jpeg' ||
                          row.attributes.content_type === 'image/png' ||
                          row.attributes.content_type === 'image/gif' ? (
                          <img
                            src={row.attributes.url}
                            style={{ height: 55, width: '60px' }}
                          />
                        ) : row.attributes.content_type ===
                          'application/pdf' ? (
                          <img src={pdf} style={{ height: 55 }} />
                        ) : row.attributes.content_type ===
                          'application/x-ole-storage' ||
                          row.attributes.content_type ===
                          'application/msword' ||
                          row.attributes.content_type ===
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                          <img src={doc} style={{ height: 55 }} />
                        ) : row.attributes.content_type ===
                          'application/vnd.ms-excel' ||
                          row.attributes.content_type ===
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                          <img src={xls} style={{ height: 55 }} />
                        ) : (
                          ''
                        )}

                        <Box
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <Typography
                            style={{
                              fontSize: '1.125rem',
                              color: '#202020',
                              fontFamily: 'Open sans',
                              margin: '0px 20px',
                              fontWeight: 500,
                            }}
                          >
                            {row.attributes.file_name}
                          </Typography>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              margin: '0px 20px 0px 0',
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: '0.875rem',
                                color: 'black',
                                fontFamily: 'Open sans',
                                margin: '0px 5px 0px 20px',
                                fontWeight: 500,
                              }}
                            >
                              {row.attributes.file_size > 1048576
                                ? `${(
                                  row.attributes.file_size / 1048576
                                ).toFixed(2)} MB`
                                : `${(row.attributes.file_size / 1024).toFixed(
                                  2
                                )} KB`}
                            </Typography>
                          </div>
                        </Box>
                      </Box>

                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          style={{
                            margin: '0 10px 0 0px',
                          }}
                        >
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            style={{ padding: 0 }}
                            onClick={() => {
                              this.deleteEditDoc(index);
                            }}
                          //     onClick={(event: React.MouseEvent<HTMLElement>) => {
                          //         this.setState({ task_id: task.id, anchorEl: event.currentTarget })
                          //     }}
                          >
                            <img
                              src={closeBack}
                              style={{
                                margin: '3px 4px 1px 0',
                                width: 25,
                                height: 25,
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',

                  textAlign: 'start',
                  margin: '23px 1px',
                }}
              >
                <Box
                  style={{
                    marginRight: '25px',
                  }}
                >
                  <Typography
                    style={{
                      textTransform: 'none',
                      fontSize: '1.125rem',
                      fontFamily: 'Open Sans',
                      fontWeight: 600,
                      color: '#3f526d',
                      margin: '2px 24px 15px 0px',
                    }}
                  >
                    Start Date
                  </Typography>
                  <Box display="flex" alignItems="center" width="30%">
                    <Datetime
                      dateFormat={'DD MMMM,YYYY'}
                      timeFormat={false}
                      strictParsing={true}
                      input={true}
                      value={moment(this.state.startDate, 'DD-MM-YYYY').format(
                        'DD MMMM,YYYY'
                      )}
                      initialValue={moment().format('DD MMMM,YYYY')}
                      // value={this.state.dateofbirth}
                      // className={
                      //     this.state.dateerror
                      //         ? "input_date_css_error_format"
                      //         : "input_date_css_format"
                      // }
                      className="input_today_date_css_format"
                      inputProps={{
                        placeholder: 'Date of Birth',
                        className: 'Date_of_birth_calendar_Icon_register',
                        readOnly: true,
                      }}
                      // onClose={() => {
                      //     if (this.state.dateofbirth.length === 0) {
                      //         this.setState({
                      //             dateerror: true,
                      //             dateerrorText: " Please select date of birth",
                      //         });
                      //     }
                      // }}
                      onChange={(date) => {
                        console.log(date);

                        this.setState({
                          startDate: moment(date).format('DD-MM-YYYY'),
                        });
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography
                    style={{
                      textTransform: 'none',
                      fontSize: '1.125rem',
                      fontFamily: 'Open Sans',
                      fontWeight: 600,
                      color: '#3f526d',
                      margin: '2px 24px 15px 0px',
                    }}
                  >
                    End Date
                  </Typography>
                  <Box display="flex" alignItems="center" width="30%">
                    <Datetime
                      dateFormat={'DD MMMM,YYYY'}
                      timeFormat={false}
                      strictParsing={true}
                      input={true}
                      value={moment(this.state.endDate, 'DD-MM-YYYY').format(
                        'DD MMMM,YYYY'
                      )}
                      initialValue={moment().format('DD MMMM,YYYY')}
                      // value={this.state.dateofbirth}
                      // className={
                      //     this.state.dateerror
                      //         ? "input_date_css_error_format"
                      //         : "input_date_css_format"
                      // }
                      className="input_today_date_css_format"
                      inputProps={{
                        placeholder: 'Date of Birth',
                        className: 'Date_of_birth_calendar_Icon_register',
                        readOnly: true,
                      }}
                      // onClose={() => {
                      //     if (this.state.dateofbirth.length === 0) {
                      //         this.setState({
                      //             dateerror: true,
                      //             dateerrorText: " Please select date of birth",
                      //         });
                      //     }
                      // }}
                      onChange={(date) => {
                        console.log(date);

                        this.setState({
                          endDate: moment(date).format('DD-MM-YYYY'),
                        });
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'start',
                  margin: '43px 1px 15px',
                }}
              >
                <Button
                  style={{
                    backgroundImage:
                      'linear-gradient(to top, #af38cb, #471c51), linear-gradient(to top, #af38cb, #471c51)',
                    borderRadius: '8px',
                    color: '#fff',
                    width: '260px',
                    height: 60,
                    textTransform: 'capitalize',
                    fontSize: '!.25rem',
                    marginRight: 24,
                  }}
                  //type="submit"
                  onClick={() => this.updateGoal()}
                >
                  Update Goal
                </Button>

                <Button
                  variant="contained"
                  style={{
                    borderRadius: 8,
                    background:
                      'linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #3a60d7, #253873)',
                    fontSize: '1.125rem',
                    fontFamily: 'Open sans',
                    fontWeight: 600,
                    color: '#3f526d',
                    textTransform: 'none',
                    marginLeft: '10px',
                    borderStyle: 'solid',
                    borderWidth: '0.5px',
                    borderImageSource:
                      'linear-gradient(to top, #af38cb, #471c51 Infinity%)',
                    //borderColor:'#471c51',

                    width: 200,
                    height: 60,
                  }}
                  onClick={this.cancelUpdateGoalsChanges}
                >
                  Cancel
                </Button>
              </Box>
            </>
          )}
        </Box>

        <AlertModal
          alertModal={this.state.alertModal}
          isTeacher={false}
          onCloseModal={() => {
            this.setState({ alertModal: false });
          }}
          alertTitle="Alert!"
          alertMessage={this.state.alertMessage}
          buttonText="OK"
        />
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default HOCAuth(StudentAddResouceAndGoals, 'StudentAccount');
// Customizable Area End