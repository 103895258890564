// Customizable Area Start
import React from "react";
import { Typography, Grid, Table, TableContainer, TableHead, TableCell, TableRow, TableBody, InputBase, IconButton, Button, NativeSelect } from "@material-ui/core";
import TeacherReportCardController, {
    Props,
} from "./TeacherReportCardController.web";
import { editPurpleIcon } from "../assets";
import ReactPaginate from "react-paginate";
import { withStyles } from '@material-ui/core/styles';
import Spinner from "../../../shared/SideBar/src/Spinner";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import './TeacherReportCard.web.css';
import moment from "moment";
import PublishReportModal from "./PublishReportModal.web";
import { ArrowDropDown } from "@material-ui/icons";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme: any) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
    },
    input: {
        borderRadius: 6,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        height: "30px",
        padding: "10px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        fontSize: 18,
        "&:focus": {
            borderRadius: 6,
            backgroundColor: theme.palette.background.paper,
        },
    },
}))(InputBase);
// Customizable Area End

export class TeacherReportCard extends TeacherReportCardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

     // Customizable Area Start
     // Customizable Area End

    render() {
        // Customizable Area Start
        const { studentList,studentListMeta, openPublishReportModal, canBePublished, selectedClassId, classData } = this.state;
        return (
            <>
                {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}

                <div className="teacher_assessment_box">
                    <Grid container spacing={3}>
                        <Grid item lg={9} md={8} sm={9} xs={12} className="teacher-report-card-text-title">
                            <Grid container>
                                <Grid item lg={2} md={3} sm={4} xs={12} className="ReportCardGrid">
                                    <Typography className={`${this.props.classes.heading_font} xs-mb-15`}>Report Card</Typography>
                                </Grid>
                                <Grid item lg={10} md={9} sm={8} xs={12}>
                                    <InputBase className={`${this.props.classes.heading_font} teacher-report-input-base`}
                                        value={`${Number(moment().format("YYYY"))}-${Number(moment().format("YY")) + 1}`}
                                    />
                                    <NativeSelect id="select"
                                        data-test-id="class-drop-down"
                                        value={selectedClassId}
                                        input={<BootstrapInput />}
                                        IconComponent={ArrowDropDown}
                                        className="teacher-report-input-select"
                                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                            this.handleClassChange(event.target.value)
                                        }}
                                    >
                                        <option value="" disabled>Select Class</option>
                                        {
                                            classData.length && classData.map((item: any) => (
                                                <option value={item.id} key={item.id}>{item?.display_name}</option>
                                            ))
                                        }
                                    </NativeSelect>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={3} md={4} sm={3} xs={12} className="rightBtn">
                            <Button className={`${this.props.classes.button_color} teacher-publish-report-btn`}
                                onClick={this.openPublishReportModal}>
                                Publish Report
                            </Button>
                        </Grid>
                    </Grid>

                    <div className="teacher-report-rectangle">
                        <TableContainer className="StudentListTableContainer">
                            <Table >
                                <TableHead className="StudentListTableHead">
                                    <TableRow>
                                        <TableCell className={`StudentNameCell ${this.props.classes.bodyText_font}`}>Student Name ({studentList?.length})</TableCell>
                                        <TableCell className={`EvaluationStatusCell ${this.props.classes.bodyText_font}`}>Evaluation Status</TableCell>
                                        <TableCell className={`ActionCell ${this.props.classes.bodyText_font}`}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="StudentListTableBody">
                                    {
                                        studentList?.length === 0 || studentList === undefined
                                            ?
                                            <TableRow>
                                                <TableCell
                                                    className={`NoStudentRecordFound ${this.props.classes.bodyText_font}`}
                                                    colSpan={7}
                                                    align="center"
                                                >
                                                    No record(s) found.
                                                </TableCell>
                                            </TableRow>
                                            :
                                            <>
                                                {studentList?.map((data: any) => (
                                                    <React.Fragment key={data.id}>
                                                        <TableRow>
                                                            <TableCell colSpan={3} className="BlankReportCell"></TableCell>
                                                        </TableRow>
                                                        <TableRow key={data.id} style={{
                                                            borderBottom: "1px solid lightgray", backgroundColor: "#f9f8fd"
                                                        }} >
                                                            <TableCell className={this.props.classes.bodyText_font} style={{ padding: "17px 5px 17px 40px", border: 0, fontSize: 18, fontWeight: 600, width: "60%" }}>{data.attributes.account.data.attributes.first_name}  {data.attributes.account.data.attributes.last_name}</TableCell>
                                                            <TableCell className={this.props.classes.bodyText_font} style={{ padding: "17px 5px", border: 0, fontSize: 18, fontWeight: 600, textTransform: "capitalize", textAlign: "center" }}>{data.attributes.evaluation_status}</TableCell>
                                                            <TableCell className={this.props.classes.bodyText_font} style={{ padding: "17px 20px 17px 5px", border: 0, fontSize: 18, fontWeight: 600, textAlign: "center" }}>
                                                                <div>
                                                                    <IconButton
                                                                        aria-label="more"
                                                                        aria-controls="long-menu"
                                                                        aria-haspopup="true"
                                                                        className="padding-7 ReportEditIconBtn"
                                                                        onClick={() => this.onEditClick(data)}
                                                                        data-test-id={`edit-report-card-icon-${data.id}`}
                                                                    >
                                                                        <img
                                                                            src={editPurpleIcon}
                                                                            className="ReportEditImg"
                                                                        />
                                                                    </IconButton>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                ))}
                                            </>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {
                            studentList?.length === 0 || studentList === undefined
                                ? null : studentListMeta?.pagination?.total_count > this.state.rowsPerPage &&
                                <div className="choose_from_gallery PaginationOverFlow">
                                    <ReactPaginate
                                        previousLabel={<>←   <span className="xs_display">Previous</span></>}
                                        nextLabel={<><span className="xs_display">Next</span>   →</>}
                                        breakLabel="..."
                                        initialPage={this.state.page}
                                        pageCount={studentListMeta?.pagination?.total_pages}
                                        onPageChange={this.onPageChangeReportCard}                                      
                                        containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                                        previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                                        nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                                        disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                                        activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                                        pageRangeDisplayed={2}
                                        marginPagesDisplayed={2}
                                    />
                                </div>
                        }
                    </div>
                </div>

                <PublishReportModal
                    openPublishReportModal={openPublishReportModal}
                    onCloseModal={this.closePublishReportModal}
                    isAlert={!canBePublished || studentListMeta?.pagination?.total_count === 0}
                />
            </>
        );
        // Customizable Area End
    }
}

 // Customizable Area Start
 export default withStyles(themeCreateStyle)(HOCAuth(TeacherReportCard, "TeacherAccount"));
// Customizable Area End