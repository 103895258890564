
// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import BarChart from "../AddQuestion/BarChart.web";
import { nounCheckSVG, nounCloseSVG } from "../assets";
import LineChart from "../AddQuestion/LineChart.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
interface Props {
    // Customizable Area Start
    questionData: any;
    isTeacher: boolean;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
export const GraphPlottingQuestionType = ({
    questionData,
    isTeacher,
    classes,
}: Props) => {
    const [correctAnswer, setCorrectAnswer] = useState<string>("");
    const [graphAxisSettingsAttributes, setGraphAxisSettingsAttributes] = useState<any>(
        {
            x_axis_label: '',
            y_axis_label: '',
            minimum_value: 0,
            maximum_value: 100,
            step_size: 10,
        }
    );
    const [graphPlottingCategoriesAttributes, setGraphPlottingCategoriesAttributes] = useState<any[]>([]);
    const [graphProperties, setGraphProperties] = useState<any>(
        {
            show_grid: true,
            show_multicolor_bar: true,
            display_position_on_hover: true,
        });
    const [studentAnswers, setStudentAnswers] = useState<any>(false);

    useEffect(() => {
        const graph_props: any = {
            show_grid: questionData?.chart_question_details?.show_grid,
            show_multicolor_bar: questionData?.chart_question_details?.multicolor_bar,
            display_position_on_hover: questionData?.chart_question_details?.display_position,
        }

        let graph_axis_config: any = {};
        questionData?.chart_question_details?.axis_setting?.map(
            (data: any) => {
                if (data.axis_type == 'x_axis') {
                    graph_axis_config.x_axis_label = data.axis_label;
                    graph_axis_config.x_axis_id = data.id;
                } else if (data.axis_type == 'y_axis') {
                    graph_axis_config.y_axis_id = data.id;
                    graph_axis_config.y_axis_label = data.axis_label;
                    graph_axis_config.minimum_value = data.minimum_value;
                    graph_axis_config.maximum_value = data.maximum_value;
                    graph_axis_config.step_size = data.step_size;
                }
            }
        );

        let graph_category_config: any = [];
        questionData?.chart_question_details?.points?.map(
            (data: any) => {
                graph_category_config.push({
                    category_id: data.id,
                    uniq_key: data?.uniq_key,
                    label: data.label,
                    initial_value: Number(data.initial_value),
                    teacher_answer_value: questionData?.answers?.[0]?.correct_chart_answers?.filter((item: any) => item.uniq_key == data.uniq_key)?.[0]?.right_answer,
                    correct_answer_value: questionData?.selected_options?.data?.attributes?.student_chart_answers?.filter((item: any) => item.uniq_key == data.uniq_key)?.[0]?.student_answer,
                    bar_color: generateColor(),
                    fraction_format_type: "fraction",
                    error: {
                        initial_value_error: false,
                        correct_answer_value_error: false,
                    }
                })
            }
        )

        const correctAnswerString = graph_category_config?.map((item: any) => item.teacher_answer_value).join(', ');
        graph_category_config?.map((item: any) => {
            if (item.correct_answer_value) {
                setStudentAnswers(true)
            }
        })
        setGraphProperties(graph_props)
        setGraphAxisSettingsAttributes(graph_axis_config);
        setGraphPlottingCategoriesAttributes(graph_category_config)
        setCorrectAnswer(correctAnswerString)
    }, [])

    const generateColor = () => {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    return (
        <Grid container>
            <Grid item xs={12} className="graph-item">
                <div className="graph-width">
                    {questionData?.question_type === "graph_plotting" ?
                        <BarChart
                            classes={classes}
                            graph_axis_settings_attributes={graphAxisSettingsAttributes}
                            graph_plotting_categories_attributes={graphPlottingCategoriesAttributes}
                            graph_properties={graphProperties}
                        />
                        :
                        <LineChart
                            classes={classes}
                            graph_axis_settings_attributes={graphAxisSettingsAttributes}
                            graph_plotting_categories_attributes={graphPlottingCategoriesAttributes}
                            graph_properties={graphProperties}
                        />
                    }
                    {!studentAnswers &&
                        <span className={`graph-no-data-found-text ${classes.heading_font}`}>No Data Found.</span>
                    }
                </div>
            </Grid>

            {questionData?.selected_options?.data &&
                <Grid item xs={12} className="graph-categories-section">
                    <Grid container>
                        {graphPlottingCategoriesAttributes?.map((categoryItem: any, index: number) => (
                            <Grid item xs={6} sm={4} md={3} key={index} className="graph-student-answer-grid">
                                <div className="graph-plotting-field-div">
                                    <span className={`${classes.heading_font} graph-field-label`}>
                                        {categoryItem.label}
                                    </span>
                                    <div className="graph-student-answer-div">
                                        <span
                                            className={Number(categoryItem.correct_answer_value) === Number(categoryItem.teacher_answer_value) ? `${classes.heading_font} graph-plotting-field correct-ans-bg` : `${classes.heading_font} graph-plotting-field in-correct-ans-bg`}
                                        >{categoryItem.correct_answer_value}
                                            {Number(categoryItem.correct_answer_value) === Number(categoryItem.teacher_answer_value) ?
                                                <img src={nounCheckSVG} alt="check" className="right-check-icon absolute-icon" />
                                                :
                                                <img src={nounCloseSVG} alt="close" className="close-red-icon absolute-icon" />}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            }

            <div className="correct_answer_section">
                <Grid container>
                    <Grid item xs={12} sm={6} md={8} className="correct_answer_left">
                        {!questionData?.selected_options?.data &&
                            <Typography className={`not_attemped_text ${classes.bodyText_font}`}>Not attempted</Typography>
                        }
                        <Typography className={`correct_answer_text ${classes.heading_font}`}>Correct Answer</Typography>
                        <TextField
                            id="standard-basic"
                            placeholder=""
                            className="evaluation-rating"
                            variant="outlined"
                            value={correctAnswer}
                            disabled
                            inputProps={{
                                className: `rating-text-field-evaluation ${classes.heading_font}`,
                            }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className="score_section">
                        <Typography className={`correct_answer_text ${classes.heading_font}`}>Score</Typography>
                        <div className="correct_answer_rectangle">
                            <Typography className="score_text">
                                {questionData?.selected_options?.data?.attributes?.score ? questionData?.selected_options?.data?.attributes?.score : 0}
                            </Typography>
                        </div>
                        <Typography className="score_text"> / {questionData?.marks}</Typography>
                    </Grid>
                    {(!isTeacher && questionData?.selected_options?.data?.attributes?.comments) &&
                        <Grid item xs={12}>
                            <Typography className={`title_teachercomment ${classes.bodyText_font}`}>Teacher Comment:
                                <span className={`textlabel_teachercomment ${classes.heading_font}`} dangerouslySetInnerHTML={{
                                    __html: questionData?.selected_options?.data?.attributes?.comments,
                                }}></span></Typography>
                        </Grid>
                    }
                </Grid>
            </div>
        </Grid>
    );
};
// Customizable Area End

// Customizable Area Start

export default withStyles(themeCreateStyle)(GraphPlottingQuestionType);
// Customizable Area End
