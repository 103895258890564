// Customizable Area Start
import {
  Avatar,
  Box,
  Tooltip,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import HOCAuth from "../../../../../shared/Header/src/HOCAuth.web";
import {
  edit_note_img,
} from "../../../assets";
import WardTasksController, { Props } from "./WardTasksController.web";
import "../WardDetails.web.css"
import { themeCreateStyle } from "../../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class WardTasks extends WardTasksController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { wardTasks } = this.state;

    return (
      <Grid className="child_activity_tab_height" container direction="row">
        {
          wardTasks?.length > 0 ? (
            <>
              {wardTasks.map((task: any, index: number) =>
                <Grid key={index} item xs={12}>
                  <Box mb="10px" className="ward_task_box">
                    <Grid container direction="row">
                      <Grid className="task_item_icon" item xs={2}>
                        {task?.subject_icon ? (
                          <img
                            src={task.subject_icon}
                          />
                        ) : (
                          <img
                            src={edit_note_img}
                          />
                        )}
                      </Grid>
                      <Grid className={`${this.props.classes.bodyText_font}     task_justify_center task_flex_column task_display_flex`} item xs={4}>
                        <Tooltip title={task.task_name} placement="top-start">
                          <div className={`${this.props.classes.subHeading_font} task_name`}><b>{task.task_name}</b></div>
                        </Tooltip>
                        <div className="task_mark">
                          <span className={`${this.props.classes.bodyText_font}`}><b>{`${task.marks} Marks`}</b></span>
                          <span className={`${this.props.classes.subTitle_font} task_subject`}>{task.subject_name}</span>
                          
                        </div>
                      </Grid>
                      <Grid className={`${this.props.classes.subTitle_font} task_justify_center task_center_align task_display_flex`} item xs={2}>
                        <span className={`${this.props.classes.bodyText_font} task_date`}>{task.timing}</span>
                      </Grid>
                      <Grid className={`${this.props.classes.subTitle_font} task_justify_center task_center_align task_display_flex`} item xs={4}>
                       <div>
                         {task?.teacher?.profile_pic ? (
                          <img
                            src={task.teacher.profile_pic}
                            className={`task_profile_img`}
                            style={{
                              borderRadius: '50%',
                            }}
                          />
                        ) : (
                          <Avatar
                            src={'/'}
                            className={`task_profile_img`}
                          />
                        )}
                        </div>
                        <div className={"task_teacher"}>{task?.teacher?.name}</div>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </>
          ) :
            <Grid item xs={12}><span>No tasks yet !</span></Grid>
        }
      </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(WardTasks, "TeacherAccount")
);
// Customizable Area End
