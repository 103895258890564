// Customizable Area Start
import React from "react";
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Divider,
  TextField,
  FormHelperText,
  Grid,
  NativeSelect,
} from "@material-ui/core";
import ReactPaginate from "react-paginate";
import { Search, Close, ArrowDropDown } from "@material-ui/icons";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import "./TeacherAttendance.web.css";
import Datetime from "react-datetime";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import TeacherAttendanceController, {
  Props,
} from "./TeacherAttendanceController.web";

import Chart from "react-google-charts";
import InputBase from "@material-ui/core/InputBase";
import Spinner from "../../../shared/SideBar/src/Spinner";
import SendIcon from '@material-ui/icons/Send';
import SelectedTab from '../SelectedTab.web';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class TeacherAttendance extends TeacherAttendanceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    const {
      teacherAttendanceList,
      selectAll,
      attendenceChartData,
    } = this.state;
    return (
      <Box
        className="TeacherAttendanceParentContainer"
      >
        {this.state.loadingSpinner && (
          <Spinner spinnerModal={this.state.loadingSpinner} />
        )}
        <Grid
          container
          spacing={2}
          className="HeaderContainer"
        >
          <Grid item>
            <Box className="TextStart">
              <Typography
                className={`classesButtonText ${this.props.classes.heading_font} TextTypo`}
              >
                Classes
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            className="co4Mob FlexCenter"
          >
            <div className={`${this.props.classes.bodyText_font} ${this.props.classes.theme_font} Year`}>
              2022-23
            </div>
          </Grid>
          <Grid className="co4Mob FlexBox" item>
            <NativeSelect
              id="select"
              value={this.state.selected_period}
              classes={{
                root: this.props.classes.TeacherAttendanceClassesAndSectionBootstrapRoot,
                select: this.props.classes.TeacherAttendanceClassesAndSectionBootstrapInput
              }}
              disableUnderline
              IconComponent={ArrowDropDown}
              onChange={this.periodChangeHandler}
            >
              <option value="" disabled>
                Select Period
              </option>
              {this.state.periodList.map((_data: any) => (
                <option value={_data.period_id}>{_data.period_name}</option>
              ))}
            </NativeSelect>
          </Grid>
          <Grid className="co4Mob FlexBox" item>
            <div id="fileNameText21" className={`classDiv ${this.props.classes.theme_font}`}>
              {this.state.displayClassName}
            </div>
          </Grid>
        </Grid>
        <Box>
          <SelectedTab isTeacher={true} type={'Attendance'} />
        </Box>
        <Grid
          className="Attendance-flex AlignItemC"
          container
        >
          <Grid xs={12} sm={12} md={3} className="Mt15">
            <Box
              className={`${this.props.classes.border_Color} InputBoxContainer`}
            >
              <input
                className="InputBox TextBox"
                placeholder="Search for Student..."
                value={this.state.queryTerm}
                onChange={this.searchChangeHandler}
                onKeyPress={this.searchKeyPressHandler}
              />
              <Search
                className={`${this.props.classes.icon_color} searchIcon`}
              />
            </Box>
          </Grid>

          <Grid xs={12} sm={4} md={4} className="Mt15">
            <Box
              className="cal-mob DateTimeContainerBox"
            >
              <Datetime
                dateFormat={"DD MMMM,YYYY"}
                timeFormat={false}
                strictParsing={true}
                input={true}
                closeOnSelect={true}
                initialValue={moment().format("DD MMMM, YYYY")}
                isValidDate={this.disableFutureDt}
                value={moment(this.state.date, "DD/MM/YYYY").format(
                  "DD MMMM, YYYY"
                )}
                className="date-input-student"
                inputProps={{
                  placeholder: "Date of Birth",
                  className: "Date_of_birth_calendar_Icon_register",
                  readOnly: true,
                }}
                onChange={this.calendarChangeHandler}
              />
            </Box>
          </Grid>

          <Grid xs={12} sm={8} md={5} className="Mt15">
            <Box
              className="status StatusBox"
            >
              <Box className="first-child AttendanceLetterP">
                <span className={`attendance_letter_table_selected ${this.props.classes.theme_font}`}>P</span>
              </Box>
              <span
                className={`${this.props.classes.heading_font} presentAbsent`}
              >
                Present
              </span>
              <Box className="attendance_letter_a">
                <span className={`attendance_letter_table_selected  ${this.props.classes.theme_font}`}>A</span>
              </Box>
              <span
                className={`${this.props.classes.heading_font} presentAbsent`}
              >
                Absent
              </span>
              <Box className="attendance_letter_l">
                <span className={`attendance_letter_table_selected  ${this.props.classes.theme_font}`}>L</span>
              </Box>
              <span
                className={`${this.props.classes.heading_font} presentAbsent`}
              >
                Leave
              </span>
            </Box>
          </Grid>
          {/* {this.state.searchError ? (
            <div
              className="Ml20"
            >
              <FormHelperText
                className="ErrorMsg"
              >
                {this.state.searchErrorText}
              </FormHelperText>
            </div>
          ) : (
            ""
          )} */}
        </Grid>
        <Box
          className="TableContainerMainBox"
        >
          <TableContainer className="Attendance-table">
            <Table className="Table" aria-label="simple table">
              <TableHead className="BorderNone">
                <TableRow>
                  <TableCell
                    className={`${this.props.classes.subHeading_font} TitleText`}
                  >
                    Student Name
                  </TableCell>
                  <TableCell
                    align="left"
                    className={`${this.props.classes.subHeading_font} TitleText2 W15`}
                  >
                    Period
                  </TableCell>
                  <TableCell
                    className={`${this.props.classes.subHeading_font} TitleText2 W20 Ml10`}
                    align="left"
                  >
                    Attendance
                  </TableCell>
                  <TableCell
                    className={`${this.props.classes.subHeading_font} TitleText3`}
                    align="left"
                  >
                    Comment
                  </TableCell>
                </TableRow>
              </TableHead>
              {teacherAttendanceList && teacherAttendanceList.data &&
                teacherAttendanceList.data.map((row: any, index: any) => (
                  <>
                    <Box className="H10"></Box>
                    <TableBody>
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={this.isSelected(row?.id)}
                        tabIndex={-1}
                        key={row.id}
                        selected={this.isSelected(row?.id)}
                        style={{
                          background: selectAll ? "#dae6d8" : "#f9f8fd",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="TableCellStyle"
                          onClick={this.handleSelectStudentClick(row.attributes.student_id, index)}
                        >
                          <Box
                            className="FlexStart"
                          >
                            <Avatar
                              src={row.attributes.student.avatar}
                              className={`${this.props.classes.image_Border_Color} listIcon`}
                            />
                            <Typography
                              className={`${this.props.classes.bodyText_font} Typo`}
                            >
                              {row.attributes?.student?.first_name}{" "}
                              {row.attributes?.student?.last_name}
                              <CalendarTodayOutlinedIcon
                                className="CalenderIcon"
                              />
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          align="left"
                          className="TableCell W15"
                        >
                          <Typography
                            id="fileNameText"
                            className={`${this.props.classes.bodyText_font} TableCellText`}
                          >
                            {row.attributes?.period?.name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          className="TableCell CursorPointer"
                        >
                          <Box
                            className="FlexCenter W10"
                          >
                            <Box
                              className="FlexStart MlNeg10"
                            ></Box>
                            {/* P letter here */}
                            <Box
                              className={`${row.attributes.status === "Present"
                                ? 'AttendanceLetterP'
                                : 'AttendanceLetterTableOuterNotselectedP'}`}
                              onClick={this.studentAttandanceHandler(row, index, "Present")}
                            >
                              <span
                                className={`${this.props.classes.theme_font} ${row.attributes.status === "Present" ? "attendance_letter_table_selected" : "attendance_letter_table_notselected_p"}`}
                              >
                                P
                              </span>
                            </Box>
                            <Box
                              className={`${row.attributes.status === "Absent" ? "attendance_letter_a" : "attendance_letter_table_outer_notselected_a"}`}
                              onClick={this.studentAttandanceHandler(row, index, "Absent")}
                            >
                              <span
                                className={`${this.props.classes.theme_font} ${row.attributes.status === "Absent" ? "attendance_letter_table_selected" : "attendance_letter_table_notselected_a"}`}
                              >
                                A
                              </span>
                            </Box>
                            <Box
                              className={`${row.attributes.status === "Leave" ? "attendance_letter_l" : "attendance_letter_table_outer_notselected_l"}`}
                              onClick={this.studentAttandanceHandler(row, index, "Leave")}
                            >
                              <span
                                className={`${this.props.classes.theme_font} ${row.attributes.status === "Leave" ? "attendance_letter_table_selected" : "attendance_letter_table_notselected_l"}`}
                              >
                                L
                              </span>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={this.commentHandler(row.attributes.comment)}
                          className="TableCell2"
                        >
                          <Box
                            className="FlexBetween"
                          >
                            <TextField
                              fullWidth
                              value={
                                row.attributes.comment === "null"
                                  ? ""
                                  : row.attributes.comment
                              }
                              multiline
                              rows={1}
                              placeholder="Add comment here..."
                              className={`inputProps ${this.props.classes.theme_font}`}
                              onKeyPress={this.commentKeyPressHandler}
                              InputProps={{ disableUnderline: true }}
                              onChange={this.commentOnChangeHandler(event, index)}
                            />
                            <SendIcon
                              className={`${this.props.classes.icon_color} CursorPointer Mr10`}
                              onClick={this.updateTeacherAttendanceComment(index, row.id)}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                )
                )}
            </Table>
            {teacherAttendanceList.length !== 0 &&
              teacherAttendanceList.data &&
              this.state.totalCount > this.state.perPage && (
                <ReactPaginate
                  previousLabel={"←   Previous"}
                  nextLabel={"Next   →	"}
                  initialPage={parseInt(this.state.currentPage)}
                  pageCount={this.state.totalPage}
                  forcePage={parseInt(this.state.currentPage)}
                  onPageChange={this.pageChangeHandler}
                  containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                  previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                  nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                  disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                  activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                  pageRangeDisplayed={4}
                  marginPagesDisplayed={2}
                />
              )}
          </TableContainer>
        </Box>
        <Modal
          open={this.state.openModal}
          onClose={this.handleModalClick}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="AttendanceReportModal"
        >
          <Box
            className="ModalInnerBox"
          >
            <Box
              className="InnerFlexBox"
            >
              <Typography id="modal-modal-title" component="h2"></Typography>
              <Typography
                id="modal-modal-title"
                component="h2"
                className={`${this.props.classes.title_font} AttendanceReportTypo`}
              >
                Attendance Report
              </Typography>
              <Typography id="modal-modal-title" component="h2">
                <Close
                  onClick={this.handleCloseResourceModal}
                  className={`${this.props.classes.icon_color} CursorPointer`}
                />
              </Typography>
            </Box>
            <Divider className="FullWidth" />
            {this.state.openModal &&
              this.state.selectedStudentAttendence &&
              this.state.selectedStudentAttendence.length !== 0 && (
                <Box
                  className="Box1"
                >
                  <Box>
                    <Box
                      className="Box2"
                    >
                      <Box
                        className="Box3"
                      >
                        <Avatar
                          src={
                            this.state.selectedStudentAttendence.attributes
                              .student.avatar
                          }
                          className={`${this.props.classes.image_Border_Color} AvatarImg`}
                        />
                        <div>
                          <div
                            className={`${this.props.classes.title_font} modal_attendance_name`}
                          >
                            {
                              this.state.selectedStudentAttendence.attributes
                                ?.student?.first_name
                            }{" "}
                            {
                              this.state.selectedStudentAttendence.attributes
                                ?.student?.last_name
                            }
                          </div>
                          <div
                            className={`${this.props.classes.subTitle_font} modal_attendance_name_type`}
                          >{`${this.state.selectedStudentAttendence.attributes?.period?.display_name}
                                            `}</div>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className="Box4"
                  >
                    <Box>
                      <Grid item className="FlexOnly">
                        <Box
                          className="FlexCenter Box5"
                        >
                          <div
                            className={`${this.props.classes.bodyText_font} FromTypo`}
                          >
                            From
                          </div>

                          <Datetime
                            dateFormat={"DD MMMM,YYYY"}
                            timeFormat={false}
                            strictParsing={true}
                            input={true}
                            closeOnSelect={true}
                            value={moment(
                              this.state.fromDate,
                              "DD/MM/YYYY"
                            ).format("DD MMMM,YYYY")}
                            initialValue={moment().format("DD MMMM,YYYY")}
                            isValidDate={this.disableFutureFrom}
                            className="input_today_date_css_format1"
                            inputProps={{
                              placeholder: "Date of Birth",
                              className:
                                "Date_of_birth_calendar_Icon_register1",
                              readOnly: true,
                            }}
                            onChange={this.fromDateChangeHandler}
                          />
                        </Box>
                        <Box
                          className="FlexCenter Box5 Ml30"
                        >
                          <div
                            className={`${this.props.classes.bodyText_font} FromTypo`}
                          >
                            To
                          </div>

                          <Datetime
                            dateFormat={"DD MMMM,YYYY"}
                            timeFormat={false}
                            strictParsing={true}
                            input={true}
                            closeOnSelect={true}
                            initialValue={moment().format("DD MMMM,YYYY")}
                            isValidDate={this.disableFutureDt}
                            value={moment(
                              this.state.toDate,
                              "DD/MM/YYYY"
                            ).format("DD MMMM,YYYY")}
                            className="input_today_date_css_format1"
                            inputProps={{
                              placeholder: "Date of Birth",
                              className:
                                "Date_of_birth_calendar_Icon_register1",
                              readOnly: true,
                            }}
                            onChange={this.toDateChangeHandler}
                          />
                        </Box>
                      </Grid>
                      {attendenceChartData &&
                        attendenceChartData.data &&
                        (attendenceChartData.data.present ||
                          attendenceChartData.data.absent ||
                          attendenceChartData.data.leave) ? (
                        <Box
                          className="FlexCenterBetween"
                        >
                          <Box
                            className="Mt20"
                          >
                            <Chart
                              width={"300px"}
                              height={"300px"}
                              className="ChartStyle"
                              chartType="PieChart"
                              loader={<div>Loading Chart</div>}
                              data={[
                                [
                                  "Attendence",
                                  "Day",
                                  {
                                    role: "tooltip",
                                    type: "string",
                                    p: { html: true },
                                    textStyle: {
                                      color: "black",
                                      fontName: "Open sans",
                                      bold: true,
                                    },
                                  },
                                ],
                                [
                                  "Present",
                                  attendenceChartData.data.present,
                                  `Present: ${attendenceChartData.data.present
                                  } (${Number(
                                    (attendenceChartData.data.present /
                                      (attendenceChartData.data.present +
                                        attendenceChartData.data.absent +
                                        attendenceChartData.data.leave)) *
                                    100
                                  ).toFixed(2)}%)`,
                                ],
                                [
                                  "Absent",
                                  attendenceChartData.data.absent,
                                  `Absent: ${attendenceChartData.data.absent
                                  } (${Number(
                                    (attendenceChartData.data.absent /
                                      (attendenceChartData.data.present +
                                        attendenceChartData.data.absent +
                                        attendenceChartData.data.leave)) *
                                    100
                                  ).toFixed(2)}%)`,
                                ],
                                [
                                  "Leave",
                                  attendenceChartData.data.leave,
                                  `Leave: ${attendenceChartData.data.leave
                                  } (${Number(
                                    (attendenceChartData.data.leave /
                                      (attendenceChartData.data.present +
                                        attendenceChartData.data.absent +
                                        attendenceChartData.data.leave)) *
                                    100
                                  ).toFixed(2)}%)`,
                                ],
                              ]}
                              options={{
                                legend: "none",
                                colors: ["#27d672", "#ff6c6c", "#13a0c5"],
                                backgroundColor: "transparent",
                              }}
                              rootProps={{ "data-testid": "1" }}
                            />
                          </Box>
                          <Box
                            className="Mt20"
                          >
                            <Box
                              className="FlexSpaceBetween"
                            >
                              <Box className="FlexOnly">
                                <Box
                                  className="AttendanceStatus"
                                >
                                  <span className={`${this.props.classes.theme_font} attendance_under_modal`}>P</span>
                                </Box>
                                <div
                                  className={`${this.props.classes.bodyText_font} attendance_under_present`}
                                >
                                  Present
                                </div>
                              </Box>
                              <div
                                className={`${this.props.classes.bodyText_font} attendance_number`}
                              >
                                {attendenceChartData.data.present}
                              </div>
                            </Box>
                            <Box
                              className="FlexSpaceBetween Mt15"
                            >
                              <Box className="FlexOnly">
                                <Box
                                  className="AbsentBox BgAbsent"
                                >
                                  <span className={`${this.props.classes.theme_font} attendance_under_modal`}>A</span>
                                </Box>
                                <div
                                  className={`${this.props.classes.bodyText_font} attendance_under_present`}
                                >
                                  Absent
                                </div>
                              </Box>
                              <div
                                className={`${this.props.classes.bodyText_font} attendance_number`}
                              >
                                {attendenceChartData.data.absent}
                              </div>
                            </Box>
                            <Box
                              className="Mt15 FlexSpaceBetween"
                            >
                              <Box className="FlexOnly">
                                <Box
                                  className="AbsentBox BgLeave"
                                >
                                  <span className={`${this.props.classes.theme_font} attendance_under_modal`}>L</span>
                                </Box>
                                <div
                                  className={`${this.props.classes.bodyText_font} attendance_under_present`}
                                >
                                  Leave
                                </div>
                              </Box>
                              <div
                                className={`${this.props.classes.bodyText_font} attendance_number`}
                              >
                                {attendenceChartData.data.leave}
                              </div>
                            </Box>

                            <Box
                              className="TotalParentDiv"
                            >
                              <div
                                className={`${this.props.classes.bodyText_font} attendance_under_present`}
                              >
                                Total
                              </div>
                              <div
                                className={`${this.props.classes.bodyText_font} attendance_number`}
                              >
                                {attendenceChartData.data.total}
                              </div>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <div className={`attendance_no_data ${this.props.classes.theme_font}`}>
                          {this.state.loadingReport
                            ? "Please wait Data is Loading.."
                            : "No record(s) found."}
                        </div>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
          </Box>
        </Modal>
      </Box>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(TeacherAttendance);
// Customizable Area End