// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const LibraryIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
          <path d="M25.143 18.701c.344 0 .623.303.623.678v6.36l1.774-1.848c.26-.27.68-.27.94 0 .26.27.26.709 0 .98l-2.66 2.77a.65.65 0 0 1-.577.194.533.533 0 0 1-.1.01c-.31 0-.568-.247-.616-.57l-2.306-2.404a.714.714 0 0 1 0-.98c.26-.27.68-.27.94 0l1.358 1.415v-5.927c0-.375.28-.678.624-.678zM23.633.127c.977.14 1.718 1.003 1.718 2.009l-.001.357h1.715c1.117 0 2.026.89 2.026 1.984v11.9a.668.668 0 0 1-.676.662.668.668 0 0 1-.675-.662v-11.9a.67.67 0 0 0-.675-.661l-1.715-.001v11.647a.667.667 0 0 1-.662.672.667.667 0 0 1-.662-.672l-.001-11.735a.658.658 0 0 1-.337-.572c0-.245.136-.458.337-.573v-.446c0-.34-.25-.632-.582-.679-2.907-.436-5.55.399-8.066 2.556v16.804c1.876-1.387 3.88-2.152 5.974-2.275.362-.034.679.262.7.632a.668.668 0 0 1-.623.71c-1.42.084-2.764.489-4.065 1.228h1.92a.664.664 0 1 1 0 1.33h-3.806c-.096.08-.192.164-.287.249a.653.653 0 0 1-.872 0 15.27 15.27 0 0 0-.29-.25H1.994c-1.1 0-1.995-.894-1.995-1.994V4.488c0-1.08.89-1.975 1.983-1.994l2.172-.001v-.357c0-1.006.742-1.87 1.723-2.01 3.064-.46 5.947.362 8.595 2.44a.59.59 0 0 1 .56-.002l-.28.227C17.479.543 20.467-.356 23.633.127zM4.154 3.823h-2.16a.678.678 0 0 0-.665.665v15.959c0 .366.298.665.665.665h10.142c-1.816-1.03-3.713-1.42-5.748-1.19a2.003 2.003 0 0 1-1.578-.503 2.002 2.002 0 0 1-.655-1.483L4.155 3.823zm1.913-2.367a.688.688 0 0 0-.587.68v15.8c0 .186.074.357.212.48.145.131.35.2.545.17 2.798-.315 5.447.43 7.892 2.233l-.001-16.806-.087-.075c-2.49-2.103-5.1-2.915-7.974-2.482zM31.335 32H19.367c-.368 0-.666-.279-.666-.623 0-.345.298-.624.666-.624h11.968c.367 0 .665.28.665.624s-.298.623-.665.623z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default LibraryIcon;
// Customizable Area End