// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
// Customizable Area End


// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    isLoader: boolean,
    errorCSVData: any;
    // Customizable Area End

}
export interface Props {
    // Customizable Area Start
    metaDataHandler: any
    page: any
    // Customizable Area End
}
export default class ErrorUploadAssesmentModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apigetErrorCSVFile: string = "";
    apiDeleteCSVFile: string = '';
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        // Customizable Area Start
        this.state = {
            errorCSVData: [],
            isLoader: false,

        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.getErrorCSVFile = this.getErrorCSVFile.bind(this);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getErrorCSVFile(this.props.page + 1);

    }
    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.page !== this.props.page) {
            this.getErrorCSVFile(this.props.page + 1);
        }
    }
    downloadIconHandler = (event: any) => {
        event.stopPropagation()
    }
    getErrorCSVFile = (page: any) => {
        this.setState({
            isLoader: true
        })
        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token,
            school: school_Data?.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetErrorCSVFile = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getErrorCSVEndPoint + `?page=${page}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.baseUrlAssessment
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        console.log("REQUEST: ", requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    deleteCsvFile = (error_Csv_File_Id: any):any => {
        this.setState({
            isLoader: true
        })
        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token,
            school: school_Data?.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiDeleteCSVFile = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeErrorCSVEndPoint + `?id=${error_Csv_File_Id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.baseUrlAssessment
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiDeleteMethodType
        );
        console.log("REQUEST: ", requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if (apiRequestCallId === this.apigetErrorCSVFile) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({
                            errorCSVData: responseJson.data,
                            isLoader: false
                        })
                        this.props.metaDataHandler(responseJson.meta)
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                        this.setState({
                            isLoader: false
                        })
                    }
                }
                this.setState({
                    isLoader: false
                })
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiDeleteCSVFile) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        toast.success("File has been deleted successfully")
                        this.getErrorCSVFile(this.props.page + 1)
                    }
                    else {
                        this.parseApiErrorResponse(responseJson)
                        this.setState({
                            isLoader: false
                        })
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }

        }
        // Customizable Area End
    }

}