// Customizable Area Start
import React from "react"
import { blockIcon, deleteIcon, editIcon } from "../assets";
import {
  Box,
  Typography,
  WithStyles,
  withStyles,
  IconButton,
  Menu,
  MenuItem
  // MoreVertOutlined,
} from "@material-ui/core";
import RemoveModal from "./RemoveModal.web"
import InActiveModal from "./ThoughtInactive.web";
import PostEventModalWeb from "./PostEventModal.web";
// import { styles } from "./ThoughtBox.web";
// Customizable Area End

interface IProps {
  // Customizable Area Start
  classes?: any;
  anchorEl: any;
  setAnchorEl: any;
  eventDetail?: any;
  eventId?: any;
  createEventDelete2?: any;
  updateStateEditEvent?: any;
  postEvent2: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  OpenPostEditEventModal: any;
  event: any;
  // createThoughtDelete1:any
  // Customizable Area End
}
const EventMenuBox: React.FC<IProps> = ({
  classes,
  anchorEl,
  setAnchorEl,
  eventDetail,
  eventId,
  createEventDelete2,
  updateStateEditEvent,
  postEvent2,
  close,
  OpenPostEditEventModal,
  event
}) => {
  // Customizable Area Start
  const [removeModal, setRemoveModal] = React.useState(false)
  const [postEvent, setPostEvent] = React.useState(false)
  // Customizable Area End

  // Customizable Area Start
  const open = Boolean(anchorEl);
  const removeAction = () => {
    handleClose()
    setRemoveModal(true)
  }

  const handleClose = () => {
    setAnchorEl()
  }
  const handleremoveClose = () => {
    setRemoveModal(false)
  }
  const handlePostEvent = async() => {
  //  setPostEvent(false)
  }

  const EditEventAction = () => {
    handleClose();
    setPostEvent(true)
    //OpenPostEditEventModal();
  }

  const handlePostEventClose = async () => {
    await setPostEvent(false);
  };
 
  const createEventDelete1 = (obj: any) => {
    createEventDelete2(obj)
  }

  const updateStateEditEventBoxMenu=(obj:any,id:any)=>{
    updateStateEditEvent(obj,id)
  }
  // Customizable Area End

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div style={{ width: '200px' }}>
          {/* <MenuItem  onClick={InactiveAction}>
        <Box style={{display:'flex',width:'100%',alignItems:'center'}}>
          <Box style={{width:'20%'}}>
          <img src= {blockIcon} 
          style={{
            height:15,
            width:15
          }}
          />
          </Box>
          <Box>
          <Typography
          className={classes.MenuItemFont} 
          >
          Move to Inactive
          </Typography>
          </Box>
        </Box>
        </MenuItem>*/}
          <MenuItem onClick={EditEventAction}>
            <Box style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              <Box style={{ width: '20%' }}>
                <img src={editIcon}
                  style={{
                    height: 15,
                    width: 15
                  }}
                />
              </Box>
              <Box>
                <Typography
                  className={classes.MenuItemFont}
                >
                  Edit
                </Typography>
              </Box>
            </Box>
          </MenuItem>
          <Box className={classes.Line} />
          <MenuItem onClick={removeAction}>
            <Box style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              <Box style={{ width: '20%' }}>
                <img src={deleteIcon}
                  style={{
                    height: 20,
                    width: 20,
                    marginTop: 4
                  }}
                />
              </Box>
              <Box>
                <Typography
                  className={classes.MenuItemFont}
                >
                  Remove
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        </div>
      </Menu>

      <RemoveModal
        RemoveModal={removeModal}
        closeModalFun={handleremoveClose}
        type={"deleteEvent"}
        eventId={eventId}
        createEventDelete1={createEventDelete1}
      />

      <PostEventModalWeb
        eventDetail={eventDetail}
        event={event}
        open={postEvent}
        close={handlePostEventClose}
        eventId={eventId}
        updateStateEditEventBoxMenu={updateStateEditEventBoxMenu}
        setAnchorEl={setAnchorEl}
      />

    </>
  )
}
export const themeCreatStyle = ((theme: any) => ({
  // Customizable Area Start
  Line: {
    //border: `0.5px solid ${theme.props.buttonColor}`,
  },
  MenuItemFont: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  title_font: {
    color: theme.props.titleColor,
    fontFamily: theme.props.titleFont
  },
  subTitle_font: {
    color: theme.props.subtitleColor,
    fontFamily: theme.props.subtitleFont
  },
  heading_font: {
    color: theme.props.headingColor,
    fontFamily: theme.props.headingFont
  },
  subHeading_font: {
    color: theme.props.subheadingColor,
    fontFamily: theme.props.subheadingFont
  },
  bodyText_font: {
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  checkBox_color: {
    color: theme.props.buttonColor
  },
  button_color: {
    backgroundColor: theme.props.buttonColor,
    color: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    '&:hover': {
      background: theme.props.buttonColor,
    }
  },
  border_Color: {
    border: `1px solid ${theme.props.buttonColor}`
  },
  icon_color: {
    color: theme.props.iconColor
  },
  file_border_Color: {
    border: `dashed 0.5px ${theme.props.buttonColor}`,
  },
  button_color_disabled: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    '&:hover': {
      background: "#fff",
    }
  },
  normal_button_color: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
  },
  dashed_Border: {
    border: `0.3px dashed ${theme.props.buttonColor}`,
  },
  // Customizable Area End
}))

// Customizable Area Start
export default withStyles(themeCreatStyle)(EventMenuBox);
// Customizable Area End
