Object.defineProperty(exports, "__esModule", {
  value: true
});

const urlConfig = require("../../../framework/src/config");

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putApiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Feedback";
exports.labelBodyText = "Feedback Body";
exports.gradeRangeType = "application/json";
exports.getThoughtListEndPoint = "/bx_block_grading/grade_books?";
exports.gradeContentType = "application/json";
//exports.gradeRangeGetUrl = "https://jsonplaceholder.typicode.com/users";
exports.btnExampleTitle = "CLICK ME";
exports.gradeRangeApiMethodType = "GET";
exports.BASEURL = urlConfig.resultUrl;
exports.GuidelineURL = urlConfig.examinationUrl;
exports.createGradeApi = 'bx_block_grading/grade_books';
exports.editGradeApi = 'bx_block_grading/grade_books';
exports.deleteGradeApiMethod = 'DELETE';
exports.termContentType = "application/json";
exports.createTermApiNew = "bx_block_assessmenttest/terms/create_term";
exports.createTermApi = "bx_block_assessmenttest/terms";
exports.createGuidelinesApi = "bx_block_assessmenttest/assessment_guidelines";
exports.termAPiMethod = "POST";
exports.getTermApi = "bx_block_assessmenttest/terms/term_list";
exports.getTermMethod = "GET";
exports.deleteTerm = "DELETE";
exports.instituteURL = urlConfig.instituteURL;
exports.userManagementURL = urlConfig.baseURL;
exports.enrollContentType = "application/json";
exports.getClassEndPoint="bx_block_scheduling/school_classes/get_uniq_school_classes";
exports.getGradeEndPoint = '/bx_block_scheduling/grades';
exports.getGuidelinesEndPoint="bx_block_assessmenttest/assessment_guidelines";
exports.enrollApiMethodType = "GET";
exports.getBoardEndPoint = "bx_block_scheduling/schools/get_board_list";
exports.getAdminBadges = "bx_block_profile/badges";
exports.getTeacherGradesList = "/bx_block_scheduling/grades";
exports.getTeacherClassesList = "account_block/school_accounts/list_teacher_classes";
exports.getAdminTeachersList = "/bx_block_scheduling/school_classes/get_grade_teachers";
exports.getAdminStudentsList = "/bx_block_scheduling/school_classes/get_grade_students";
exports.saveBadgeDetails = "/bx_block_profile/badges";
exports.getPreSignedUrl = "/bx_block_bulk_uploading/uploads/s3_signed_url";
exports.getCategoryList = "bx_block_profile/badge_category_list";
exports.saveAssignBadges = "/bx_block_profile/badges/assign_badges";
exports.getClassStudentsList = "bx_block_scheduling/school_classes/get_class_students";
// Customizable Area End