export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const img21kSchholLogo = require("../assets/logo.png");
export const imgloginScreenBackground = require("../assets/5960511fc2d87d4daf4cc703ead7575b56e784c2.png").default
export const manImage = require("../assets/e6c715c3911a4b94bcec01de06660f7797f20906.png").default
export const bigCloud = require("../assets/1648e95fb4228d91b398ec2537155970ea177e68.png").default
export const smallCloud = require("../assets/c06e06dbe043cae67d56fbfc0834974f5831a8c7.png").default
export const ic_google = require("../assets/c22861285cbbeaf7baa9173dd3e49bb5fca4eff9.png").default
export const ic_facebook = require("../assets/c6ddb9cf1b26d20e4b3af945eff313d7f1f88279.png").default
export const ic_apple = require("../assets/c878dc011ac5307ddb42928be0f663af9c85267b.png").default
export const ic_microsoft = require("../assets/65a9a767ae479c43a94f8e3b96d51e88c37b5cb3.png")