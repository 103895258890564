// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, NativeSelect, InputBase, Table, TableContainer, TableHead, TableCell, TableRow, TableBody, } from "@material-ui/core";
import TeacherAssessmentController, {
  Props,
} from "./TeacherAssessmentController.web";
import { createNewIcon, bubbleImg, bubbleSquareImg, bubbleImg2 } from "./assets";
import ReactPaginate from "react-paginate";
import { withStyles } from '@material-ui/core/styles';
import { examTypes } from "./utils/constants";
import Spinner from "../../shared/SideBar/src/Spinner";
import HOCAuth from "../../shared/Header/src/HOCAuth.web";
import { ArrowDropDown } from "@material-ui/icons";
import './TeacherAssessment.web.css';
import AssessmentActionMenu from "./AssessmentActionMenu.web";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme: any) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "#fff",
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
    border: '1px solid #f9f8fd',
    padding: "8px 26px 8px 12px",
    height: "30px",
    fontWeight: 600,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: theme.props.textFont,
    color: theme.props.textColor,
    fontSize: 18,
    lineHeight: "22px",
    "&:focus": {
      borderRadius: 8,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
// Customizable Area End

export class TeacherAssessment extends TeacherAssessmentController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { assessmentList, yearMonthsList } = this.state;
    return (
      <>
        {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}

        <Box className={`teacher_assessment_box AssessmentMargin`}>
          <Box
            p={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={`dashboard_assessment ${this.props.classes.heading_font}`}>
              {/* Assessment */}
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={9}>
              <div className="AssessmentStateDiv bg_img_height">
                <Grid container>
                  <Grid item xs={3} className="state_grid">
                    <div className="assessment_state_block">
                      <Typography className={`state_text font-16-md font-12-sm assesment_static_color_txt`}>
                        Total
                      </Typography>
                      <Typography className={`state_count assesment_static_color_txt`}>
                        {this.state.stateAssessment?.total_assessment}
                      </Typography>
                    </div>

                  </Grid>
                  <Grid item xs={3} className="state_grid">
                    <div className="assessment_state_block">
                      <Typography className={`state_text font-16-md font-12-sm assesment_static_color_txt`}>
                        Completed
                      </Typography>
                      <Typography className={`state_count assesment_static_color_txt`}>
                        {this.state.stateAssessment?.complete_assessment}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3} className="state_grid">
                    <div className="assessment_state_block">
                      <Typography className={`state_text font-16-md font-12-sm assesment_static_color_txt`}>
                        Schedule
                      </Typography>
                      <Typography className={`state_count assesment_static_color_txt`}>
                        {this.state.stateAssessment?.schedule_assessment}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3} className="state_grid">
                    <div className="assessment_state_block OngoingState">
                      <Typography className={`state_text font-16-md font-12-sm assesment_static_color_txt`}>
                        Ongoing
                      </Typography>
                      <Typography className={`state_count assesment_static_color_txt`}>
                        {this.state.stateAssessment?.ongoing_assessment}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <img
                  src={bubbleImg}
                  alt=""
                  className="bubble_img"
                />
                <img
                  src={bubbleSquareImg}
                  alt=""
                  className="bubble_square_img"
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={3} >
              <div className="AssessmentCreateDiv bg_img_height">
                <Grid container>
                  <Grid item xs={12} className="create_new_assessment_block" onClick={this.assessmentModuleUrl}>
                    <img src={createNewIcon} className="small-create-image AssessmentCreateIcon"></img>
                    <Typography className={`create_new_assessment assesment_static_color_txt`}>
                      Create new <br />Assessment
                    </Typography>

                  </Grid>
                </Grid >
                <img
                  src={bubbleImg2}
                  alt=""
                  className="create_bubble_img"
                />
              </div >
            </Grid >
          </Grid >

          <div className="view_verticalbox AssessmentFlexColumn">
            <Grid className="list-dropdown AssessmentFilters" container>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Typography
                  className={`ListOfAssessment ${this.props.classes.subHeading_font}`}
                >
                  List of Assessment
                </Typography>

              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2} className="xs_mt_10">
                <NativeSelect
                  className="filter_dropdown"
                  id="select-month"
                  // data-test-id = "select-month"
                  value={this.state.selectedMonth}
                  input={<BootstrapInput />}
                  IconComponent={ArrowDropDown}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.handleMonthChange(event)}
                >
                  <option value="" disabled>Select month</option>
                  {yearMonthsList?.map((item: any) => (
                    <option value={item.value} key={item.value}>{item?.label}</option>
                  ))}
                </NativeSelect>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2} className="xs_mt_10">
                <NativeSelect
                  className="filter_dropdown"
                  id="select-type"
                  value={this.state.selectedType}
                  input={<BootstrapInput />}
                  IconComponent={ArrowDropDown}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.handleTypeChange(event)}
                >
                  <option value="">All Type</option>
                  {examTypes?.map((item: any) => (
                    <option key={item.id} value={item.value}>{item.name}</option>
                  ))}
                </NativeSelect>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2} className="md_mt_10">
                <NativeSelect
                  className="filter_dropdown"
                  id="select-class"
                  value={this.state.selectedClassId}
                  input={<BootstrapInput />}
                  IconComponent={ArrowDropDown}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.handleClassChange(event)}
                >
                  <option value="" disabled>Select class</option>
                  {this.state.gradesList?.length != 0 &&
                    this.state.gradesList?.map((item: any) => (
                      <option value={item.id} key={item.id}>{item?.attributes?.name}</option>
                    ))}
                </NativeSelect>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2} className="md_mt_10">
                <NativeSelect
                  className="filter_dropdown"
                  id="select-term"
                  value={this.state.selectedTerm}
                  input={<BootstrapInput />}
                  IconComponent={ArrowDropDown}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.handleTermChange(event)}
                >
                  <option value="">All Terms</option>
                  {this.state.termsList?.map((termItem: any) =>
                    <option value={termItem.id} key={termItem.id}>
                      {termItem.attributes.title}
                    </option>
                  )}
                </NativeSelect>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2} className="md_mt_10">
                <NativeSelect
                  className="filter_dropdown"
                  id="select-subject"
                  value={this.state.selectedSubject}
                  input={<BootstrapInput />}
                  IconComponent={ArrowDropDown}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.handleSubjectChange(event)}
                >
                  <option value="" disabled>Select Subject</option>
                  {this.state.subjectList?.map((item: any) => (
                    <option value={item.id} key={item.id}>{item?.subject_name}</option>
                  ))}
                </NativeSelect>
              </Grid>

            </Grid>


            <TableContainer className="t-assessment-table AssessmentTableContainer">
              {/* Table */}
              <Table>
                <TableHead className="AssessmentListTableHead">
                  <TableRow>
                    <TableCell className={`AssessmentListName ${this.props.classes.bodyText_font}`}>Assessment Title</TableCell>
                    <TableCell className={`AssessmentListTitle ${this.props.classes.bodyText_font}`}>Type</TableCell>
                    <TableCell className={`AssessmentListTitle ${this.props.classes.bodyText_font}`}>Class</TableCell>
                    <TableCell className={`AssessmentListTitle ${this.props.classes.bodyText_font}`}>Term</TableCell>
                    <TableCell className={`AssessmentListTitle ${this.props.classes.bodyText_font}`}>Subject</TableCell>
                    <TableCell className={`AssessmentListTitle ${this.props.classes.bodyText_font}`}>Status</TableCell>
                    <TableCell className={`AssessmentListResult ${this.props.classes.bodyText_font}`}>Results</TableCell>
                    <TableCell className={`AssessmentListAction ${this.props.classes.bodyText_font}`}>&nbsp;</TableCell>
                  </TableRow >
                </TableHead >
                <TableBody className="AssessmentListTableBody" >
                  {
                    assessmentList?.length === 0 || assessmentList === undefined
                      ?
                      <TableRow>
                        <TableCell
                          className={`NoAssessmentListFound ${this.props.classes.bodyText_font}`}
                          colSpan={8}
                          align="center"
                        >
                          No record(s) found.
                        </TableCell>
                      </TableRow>
                      :
                      <>
                        {assessmentList?.map((data: any) => (
                          <React.Fragment key={data.id}>
                            <TableRow>
                              <TableCell colSpan={7} className="AssessmentBlankRow"></TableCell>
                            </TableRow>
                            <TableRow key={data.id} className="AssessmentDataRow">
                              <TableCell className={`AssessmentDataName ${this.props.classes.heading_font}`}>{data.attributes.assessment_name}</TableCell>
                              <TableCell className={`AssessmentListData ${this.props.classes.heading_font}`}>{data.attributes?.exam_type?.toLowerCase() === "practice_paper" ? "Practice Paper" : data.attributes.exam_type}</TableCell>
                              <TableCell className={`AssessmentDataOther ${this.props.classes.heading_font}`}>{data.attributes?.grade?.attributes?.name}</TableCell>
                              <TableCell className={`AssessmentDataOther ${this.props.classes.heading_font}`}>{data.attributes?.term?.title}</TableCell>
                              <TableCell className={`AssessmentListData ${this.props.classes.heading_font}`}>{data.attributes?.subject_name?.[0]?.subject_name}</TableCell>
                              <TableCell className={`AssessmentDataOther ${this.props.classes.heading_font}`} style={{ color: this.getStatusTextColor(data.attributes.status) }}>{data.attributes.status}</TableCell>
                              <TableCell className={`AssessmentDataResult ${this.props.classes.heading_font}`}
                                onClick={() => this.openAssessmentEvaluation(data.id)}>{data.attributes.result}</TableCell>
                              <TableCell className="AssessmentDataAction">
                                {
                                  (data.attributes.status.toLowerCase() === 'save as draft' || data.attributes.status.toLowerCase() === 'pending') ?
                                    <AssessmentActionMenu assessment_id={data.id} handleEditAssessment={(id: any) => this.handleEditAssessment(id)} />
                                    : ''
                                }

                              </TableCell>
                            </TableRow>
                          </React.Fragment >
                        ))
                        }
                      </>
                  }
                </TableBody >
              </Table >
              {/* Table END */}
            </TableContainer >

            {
              assessmentList?.length === 0 || assessmentList === undefined
                ? null :
                <div className="choose_from_gallery AssessmentPaginate">
                  <ReactPaginate
                    previousLabel={<span>←{" "}<span className="xs_display">Previous</span></span>}
                    nextLabel={<span><span className="xs_display">Next</span>{" "}→</span>}
                    breakLabel="..."
                    initialPage={this.state.page}
                    pageCount={this.state.assessmentListMeta?.pagination?.total_pages}
                    onPageChange={(e: any) => {
                      if (e.selected !== this.state.page) {
                        this.setState({ page: e.selected }, () => this.getAssessmentList())
                      }
                    }}
                    containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                    previousLinkClassName={"padded-span"}
                    nextLinkClassName={"padded-span"}
                    disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                    activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                  />
                </div>
            }

          </div >

        </Box >
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(TeacherAssessment, "TeacherAccount"));
// Customizable Area End
