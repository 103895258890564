// Customizable Area Start
import React from "react";
import AlertModal from "../../../alert/src/AlertModal.web";
import Grid from "@material-ui/core/Grid";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { FileDrop } from "react-file-drop";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import { cancelIcon, fileUploadImage, previewImageIcon } from "./assets";
import StudentTaskUploadController, { Props } from "./StudentTaskUploadController.web";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "./StudentTaskUpload.web.css";
import { ImagePreivewModal } from "../../../AssessmentTest/src/Components/ImagePreivewModal.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class StudentTaskUpload extends StudentTaskUploadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    const { bulkFileUploaded } = this.state;
    return (
      <>
        <Modal className="studentTaskUploadModal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={
            this.props.submitModal !== undefined
              ? this.props.submitModal
              : false
          }
          onClose={this.modalhandleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={this.props.submitModal} style={{ border: "none" }}>
            <div className="taskUploadContent">
              {this.state.showSpinner && (
                <Spinner spinnerModal={this.state.showSpinner} />
              )}
              <form className="taskUploadContentForm" onSubmit={this.submitCourseWork}>
                <Grid container className="taskUploadContentForm" >
                  <Grid item xs={12} className="taskUploadContentForm">
                    <Box className="uploadTaskFileBox">
                      <Typography className={`studentUploadFilesTitle ${this.props.classes.title_font}`}>
                        Upload files
                      </Typography>
                      {this.state.bulkFileUploaded.length > 0 ? (
                        <div className="bulkFileUpload" >
                          {bulkFileUploaded?.map((data: any) => (
                            <Grid item xs={12} className="mt20">
                              <Box className="bulkFileUploadBox">
                                <Grid container className="center">
                                  <Grid className="srcGrid" item xs={2}>
                                    <img className="srcGridImg" src={data.src} />
                                  </Grid>
                                  <Grid item xs={8} className="uploadFileGrid">
                                    <Typography className={`uploadFileName ${this.props.classes.bodyText_font}`}>
                                      {data.fileName}
                                    </Typography>
                                    <Typography className={`studentuploadFileSize ${this.props.classes.subTitle_font}`}>
                                      {data.fileSize > 1048576
                                        ? `${(data.fileSize / 1048576).toFixed(2)} MB`
                                        : `${(data.fileSize / 1024).toFixed(2)} KB`}
                                    </Typography>
                                  </Grid>
                                  <Grid className="pImgIconGrid" item xs={2} >
                                    <img className="pImgIcon" src={previewImageIcon}
                                      onClick={() => {
                                        this.setState(
                                          {
                                            selectedPreviewImageSrc: data.src,
                                          },
                                          () => {
                                            this.setState({ previewImageModal: true });
                                          }
                                        );
                                      }}
                                    />
                                    <img className="pImgIcon" src={cancelIcon}
                                      onClick={() => {
                                        this.bulkUploadDocumentHandleDelete(data.id);
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          ))}
                          <Grid className="fileUploadGrid" item xs={12}>
                            <input className="none"
                              id="contained-button-file"
                              multiple
                              type="file"
                              onChange={(event: React.ChangeEvent<any>) => {
                                if (event.target.files?.length > 0) {
                                  this.handleUpload(event.target.files);
                                }
                              }}
                            />
                            <label htmlFor="contained-button-file">
                              <Button variant="contained" component="span" className={`addMoreFiles ${this.props.classes.button_color_disabled}`}>
                                Add more files
                              </Button>
                            </label>
                          </Grid>
                        </div>
                      ) : (
                        <>
                          <FileDrop
                            onDrop={(files: any, event: any) => {
                              window.addEventListener(
                                "drop",
                                function (e) {
                                  e.preventDefault();
                                },
                                false
                              );
                              this.handleUpload(files);
                            }}
                          >
                            <Box className={`taskUploadBox ${this.props.classes.studentTaskUpload_classAndSection_solid_border}`}>
                              <Box>
                                <img src={fileUploadImage} className="uploadFileImage" />
                              </Box>
                              <Box>
                                <span className={`dragMessage ${this.props.classes.bodyText_font}`}>
                                  Drag and drop file here or
                                </span>
                                <input className="none"
                                  id="contained-button-file"
                                  multiple
                                  type="file"
                                  onChange={(event: React.ChangeEvent<any>) => {
                                    this.handleUpload(event.target.files);
                                  }}
                                />
                                <label htmlFor="contained-button-file">
                                  <Button className={`studentBrowseBtn ${this.props.classes.browse_button_color}`} component="span">
                                    browse.
                                  </Button>
                                </label>
                                <span className={`supportText ${this.props.classes.bodyText_font}`}>
                                  supported formats: .png, .jpg, .jpeg
                                </span>
                              </Box>
                              <Box>
                                <Typography className={`maxFileSize ${this.props.classes.bodyText_font}`} >
                                  Max file size:{" "}
                                  <span>500MB</span>
                                </Typography>
                              </Box>
                            </Box>
                          </FileDrop>
                        </>
                      )}
                    </Box>
                    <Box className="studentUploadCancelBox">
                      <Button
                        variant="contained"
                        onClick={this.submitCourseWork}
                        disabled={
                          this.state.fileUploadDetail === null ? true : false
                        }
                        className={`studentUploadBtn ${this.props.classes.button_color} ${this.state.fileUploadDetail === null ? "halfOpcaity" : "fullOpcaity"}`}>
                        Upload
                      </Button>
                      <Button variant="contained" className={`studentCancelBtn ${this.props.classes.button_color_disabled}`} onClick={this.modalhandleClose}>
                        Cancel
                      </Button>
                    </Box>
                    <AlertModal
                      alertModal={this.state.alertModal}
                      isTeacher={false}
                      onCloseModal={() => {
                        this.setState({ alertModal: false });
                      }}
                      alertTitle="Alert!"
                      alertMessage={this.state.alertMessage}
                      buttonText="OK"
                    />
                    <ImagePreivewModal
                      previewImageModal={this.state.previewImageModal}
                      modalhandleClose={() =>
                        this.setState({
                          previewImageModal: false,
                        })
                      }
                      previewImage={this.state.selectedPreviewImageSrc}
                    />
                  </Grid>
                </Grid>
              </form>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(StudentTaskUpload);
// Customizable Area End
