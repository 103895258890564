import * as React from 'react';
import { styled } from '@material-ui/styles';
import {
  AppBar,
  Box,
  Toolbar,
  CssBaseline,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Header from '../../blocks/shared/Header/src/Header';
import SideBarMenuList from '../../blocks/shared/SideBar/src/SideBarMenuList.web';
import { withStyles } from '@material-ui/core/styles';
import { backGroundLayout } from '../../blocks/shared/SideBar/src/LayoutBackground';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const drawerWidth = 190;
const drawerCloseWidth = 73;

interface IMiniDrawer {
  children: any;
  classes: any;
}

function Layout({ children, classes }: IMiniDrawer) {
  const [open, setOpen] = React.useState(false);

  // ON Component mount
  React.useEffect(() => {
    const menuOpen = localStorage.getItem('MenuOpen');
    setOpen(menuOpen === 'true');
    if (window.location.href.indexOf('StudentAssesmentAnswer') > -1) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, []);
  const handleDrawer = (newVal: boolean) => {
    // const newVal: boolean = !open;
    setOpen(newVal);
    localStorage.setItem('MenuOpen', `${newVal}`);
  };
  const [hide, setHide] = React.useState(false);
  const theme = useTheme();
  const screenSize = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box
      style={{
        display: 'flex',
        position: 'relative',
        backgroundColor:
        //@ts-ignore
          theme?.props?.mainColor,
      }}
      id="main-frame"
    >
      <CssBaseline />
      {!hide && (
        <SideBarMenuList
          handleDrawer={(newVal: boolean) => {
            handleDrawer(newVal);
          }}
          open={open}
          hide={hide}
          drawerWidth={drawerWidth}
          drawerCloseWidth={drawerCloseWidth}
          classes={undefined}
          navigation={undefined}
        />
      )}
      <div
        className="main-wrapper"
        style={{
          paddingLeft: hide
            ? ''
            : open
            ? `${drawerWidth}px`
            : `${drawerCloseWidth}px`,
          paddingTop:'5px',
          paddingBottom:'5px',
          flexShrink: 1,
          flexGrow: 1,
          flexBasis: '100%',
          maxWidth: '100%',
          // backgroundImage:
          //"linear-gradient(253deg, rgb(58, 96, 215) 111%, rgb(37, 56, 115) -30%)",
          borderTopLeftRadius: hide ? '0px' : '50px',
          transition: 'all 0.3s ease-out',
        }}
      >
        <div className="NavIcon" onClick={() => setOpen((open) => !open)}>
          <input type="checkbox" />
          <span className={classes.icon_color} />
          <span className={classes.icon_color} />
          <span className={classes.icon_color} />
        </div>
        <AppBar
          className="open-wrapper"
          // open={open}
          style={{
            zIndex: 999,
            position: 'fixed',
            backgroundColor: 'white',
            boxShadow: 'none',
            // transition: "none",
            transition: 'all 0.3s ease-out',
            borderTopLeftRadius: hide ? '0px' : '50px',
            marginLeft: hide ? '0px' : open ? drawerWidth : drawerCloseWidth,
            width: hide
              ? '100%'
              : `calc(100% - ${open ? drawerWidth : drawerCloseWidth}px)`,
          }}
        >
          <Toolbar>
            <Typography
              // variant="h6"
              style={{ width: '100%' }}
              // noWrap
              // component="div"
            >
              <Header />
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          className="Headerhight"
          style={{
            marginTop: '93px',
            width: '100%',
            overflow: 'auto',
            backgroundColor: 'white',
            borderBottomLeftRadius: hide ? '0px' : '50px',
            maxHeight: 'calc(100vh - 96px)',
            minHeight: 'calc(100vh - 96px)',
            padding: screenSize ? '0 30px' : '0 15px',
          }}
        >
          {' '}
          <div>{children}</div>
        </Box>
      </div>
    </Box>
  );
}
const ThemeCreateStyle = (theme: any) => ({
  icon_color: {
    backgroundColor: theme.props.iconColor,
  },
});
export default withStyles(ThemeCreateStyle)(Layout);
