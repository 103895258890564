export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const folder = require("../assets/folder.png");
export const goal = require("../assets/goal.png");
export const share = require("../assets/share.png");
export const heart = require("../assets/heart.png");
export const chat = require("../assets/chat.png");
export const image = require("../assets/image.png");
export const school = require("../assets/school.png");
export const download = require("../assets/download.png");
export const pdf = require("../assets/pdf.png");
export const doc = require("../assets/doc.png");
export const xls = require("../assets/xls.png");
export const closeBack = require("../assets/closeBack.png");
export const like = require("../assets/like.png");
export const deleteIcon = require("../assets/delete.png");
export const filter = require("../assets/filter.png");
export const calendar = require("../assets/calendar.png");
export const twitter = require("../assets/twitter.png");
export const sharechat = require("../assets/sharechat.png");
export const linkedin = require("../assets/linkedin.png");
export const facebook = require("../assets/facebook.png");
export const copylink = require("../assets/copylink.png");
export const email = require("../assets/email.png");
export const back = require("../assets/back.png");
