// Customizable Area Start
import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export interface Props {
    navigation?: any;
    id?: string;
    location?: any;
    // Customizable Area Start
    data: any;
    index: number;
    onOptionChange: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CustomRadio extends React.Component<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
        };

        this.handleOptionChange = this.handleOptionChange.bind(this)
        // Customizable Area End
    }

    // Customizable Area Start
    handleOptionChange() {
        this.props.onOptionChange(this.props.data)
    }
    // Customizable Area End

    // Customizable Area Start
    render() {
        const { data } = this.props;
        const WhiteRadio = withStyles({
            root: {
                color: "#3f526d",
                padding: "0px",
                "&$checked": {
                    color: "white",
                    backgroundColor: "#3f526d",
                    padding: "2px",
                },
            },
            checked: {},
        })((props: RadioProps) => <Radio color="default" {...props} />);
        return <RadioGroup
            aria-label="position"
            name="position"
            data-test-id={`option-${this.props.index}`}
            defaultValue="top"
            onChange={this.handleOptionChange}
        >
            <WhiteRadio
                value={data.charNumber}
                checked={data.right_answer}
                name="radio-button-demo"
                inputProps={{ "aria-label": "A" }}
                checkedIcon={<CheckCircleOutlineIcon />}
            />
        </RadioGroup>
    }
    // Customizable Area End
}

