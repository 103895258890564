// Customizable Area Start
import React from "react";
import { Box, Grid, Modal, Button } from "@material-ui/core";
import { warningIcon, checkMarkIcon } from "../assets";
import { withStyles } from '@material-ui/core/styles';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
// Customizable Area Start
// Customizable Area End
interface Props {
    // Customizable Area Start
    openPublishReportModal: boolean;
    onCloseModal: any;
    isAlert: boolean;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
export const PublishReportModal = ({
    openPublishReportModal,
    onCloseModal,
    isAlert,
    classes,
}: Props) => {
    return (
        <>
            <Modal
                open={openPublishReportModal}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id="publish-report-modal"
                className="publish-report-modal-class"
            >
                <Box className="delete_modal_main_div">
                    {isAlert ?
                        <>
                            <Grid className="flex-center">
                                <img src={warningIcon} alt="warning" className="alert-img-term"></img>
                            </Grid>

                            <div className={`${classes.heading_font} publish-modal-title`}>
                                Alert
                            </div>

                            <div className={`${classes.subHeading_font} publish-modal-text`}>
                                The result can only publish once the evaluation is completed for all students.
                            </div>
                        </>
                        :
                        <>
                            <Grid className="flex-center">
                                <img src={checkMarkIcon} alt="warning" className="alert-img-term"></img>
                            </Grid>

                            <div className={`${classes.heading_font} publish-modal-title`}>
                                Published
                            </div>

                            <div className={`${classes.subHeading_font} publish-modal-text`}>
                                The result has been published for all students.
                            </div>
                        </>
                    }

                    <Grid className="submit_btn_section">
                        <Button className={`${classes.button_color} publish-ok-button`} onClick={onCloseModal}>
                            <span className="publish-ok-text">Ok</span>
                        </Button>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(PublishReportModal);
// Customizable Area End