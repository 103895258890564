// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StudentEnroll from "./Enroll/Student/StudentEnroll.web";
import TeacherEnroll from "./Enroll/Teacher/TeacherEnroll.web";
import PendingAccount from "./Enroll/Pending/PendingAccount.web";
// Customizable Area End



// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  data:any;
  studentSubjectList: any;
  goals: any;
  progress: any;
  upcomingClasses: any;
  selectedUpcomingSubject: string;
  notes: any;
  learningCurve: any;
  assessment: any;
  selectedTab:string;
  selectedIndex:number;
  listView:string;
  membersListView: string;
  dialogOpen:boolean;
  uploadStudent:boolean;
  dialogOpenTeacher:boolean;
  uploadTeacher:boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AdminEnrollUserAccountController extends BlockComponent<Props, S, SS> {
  
    // Customizable Area Start
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    console.log(this);
    this.receive = this.receive.bind(this);
    this.handleSelectTab=this.handleSelectTab.bind(this)
   
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      data:'',
      selectedTab:'teachers',
      selectedIndex:2,
      listView:'list',
      loading: false,
      studentSubjectList: {},
      goals: {},
      progress: {},
      upcomingClasses: [],
      selectedUpcomingSubject: '',
      notes: {},
      learningCurve: {},
      assessment: {},
      membersListView: 'list',
      dialogOpen:false,
      uploadStudent:false,
      dialogOpenTeacher:false,
  uploadTeacher:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
   
       
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
       
      });
    }
  }

  
  handleSelectTab=(tab:string,key:number)=>{
    this.setState({
      selectedTab:tab,
      selectedIndex:key
    })
  }
 handleDialogClose=()=>{
   this.setState({dialogOpen:false})
 }

 onCloseModal = () => {
  this.setState({ uploadStudent: false })}

  handleTeacherDialogClose=()=>{
    this.setState({dialogOpenTeacher:false})
  }
 
  onTeacherCloseModal = () => {
   this.setState({ uploadTeacher: false })}
 

   screen = () => {
    switch(this.state.selectedTab) {
      case "principals":   return <p>Principal</p>;
      case "teachers":   return <TeacherEnroll addDialog={this.state.dialogOpenTeacher} handleDialogClose={this.handleTeacherDialogClose} onCloseModal={this.onTeacherCloseModal} uploadStudent={this.state.uploadTeacher}/>;
      case "supervisors":   return <p>supervisors</p>;
      case "students":   return <StudentEnroll addDialog={this.state.dialogOpen} handleDialogClose={this.handleDialogClose} onCloseModal={this.onCloseModal} uploadStudent={this.state.uploadStudent}/>;
      case "pending":   return <PendingAccount />
      default:      return <h1>No project match</h1>
    }
  }
  // Customizable Area End
  
  async receive(from: string, message: Message) {
      // Customizable Area Start
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    }
    // Customizable Area End
  }
}
