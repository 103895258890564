// Customizable Area Start
import React from "react";
import { Button, Table, TableContainer, TableHead, TableCell, TableRow, TableBody, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import AddEditGrade from "./AddEditGrade.web";
import AddEditGradeScale from "./AddEditGradeScale.web";
import { addBigIcon, deleteOutlineIcon, editPurpleIcon } from "../../assets";
import GradingScaleController from "./GradingScaleController.web";
import DeleteGradeModal from "./DeleteGradeModal.web";
import './CreateCustomReportCard.web.css';
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class GradingScale extends GradingScaleController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      addEditGradeDialog,
      isEditGradeScale,
      gradeName,
      gradeNameError,
      gradeNameErrorMsg,
      gradeLabel,
      gradeLabelError,
      gradeLabelErrorMsg,
      score,
      scoreError,
      scoreErrorMsg,
      minMarks,
      minMarksError,
      minMarksErrorMsg,
      maxMarks,
      maxMarksError,
      maxMarksErrorMsg,
      gradingList,
      addEditGradeScaleDialog,
      deleteModal,
      isDeleteAllGradeData
    } = this.state;
    return (
      <>
        {this.state.showLoader && (
          <Spinner spinnerModal={this.state.showLoader} />
        )}

        <div
          className={`enter-grading-scale-text ${this.props.classes.heading_font
            }`}
        >
          Enter Grading Details
        </div>

        <div>
          {gradingList?.length > 0 && gradingList?.map((gradeAllData: any, index: number) => (
            <React.Fragment key={index}>
              <div className={`grade-name-text ${this.props.classes.heading_font}`}>
                {gradeAllData?.attributes?.name}
                <img src={deleteOutlineIcon} alt="delete" className="delete-grade-icon" onClick={() => this.openDeleteModal(true, gradeAllData?.id)} data-test-id={`delete-grade-${index}`}></img>
              </div>
              <TableContainer className="grade-table-width GradingScaleTableContainer">
                <Table className="GradingScaleTable">
                  <TableHead className="GradingScaleTableHead">
                    <TableRow>
                      <TableCell className={`GradingScaleTableTitle ${this.props.classes.subTitle_font}`}>Label</TableCell>
                      <TableCell className={`GradingScaleTableTitle ${this.props.classes.subTitle_font}`}>Score</TableCell>
                      <TableCell className={`GradingScaleTableTitle ${this.props.classes.subTitle_font}`}>Min Marks</TableCell>
                      <TableCell className={`GradingScaleTableTitle ${this.props.classes.subTitle_font}`}>Max Marks</TableCell>
                      <TableCell className={`GradingScaleTableTitle ${this.props.classes.subTitle_font}`}>
                        <div className="GradingScaleActionHead">Action</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="GradingScaleTableBody">
                    {gradeAllData?.attributes?.report_grade_scales?.data?.map((data: any, ind: number) => (
                      <React.Fragment key={ind}>
                        <TableRow key={ind}>
                          <TableCell className={`GradingScaleTableData ${this.props.classes.subTitle_font}`}>{data?.attributes?.name}</TableCell>
                          <TableCell className={`GradingScaleTableScore ${this.props.classes.subTitle_font}`}>{data?.attributes?.score}</TableCell>
                          <TableCell className={`GradingScaleTableData ${this.props.classes.subTitle_font}`}>{data?.attributes?.min_marks}</TableCell>
                          <TableCell className={`GradingScaleTableData ${this.props.classes.subTitle_font}`}>{data?.attributes?.max_marks}</TableCell>
                          <TableCell className={`GradingScaleTableData ${this.props.classes.subTitle_font}`}>
                            <div className="GradingScaleActionData">                             
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                className="padding-7 GradingScaleEditDelete"
                                onClick={() => this.openDeleteModal(false, gradeAllData?.id, data?.id)}
                                data-test-id={`delete-grade-table-details-${ind}-${index}`}
                              >
                                <img
                                  src={deleteOutlineIcon}
                                  className="GradingScaleDeleteImg"
                                />
                              </IconButton>
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                className="padding-7 GradingScaleEditDelete"
                                onClick={() => this.editGradeScaleClick(gradeAllData, data, index)}
                                data-test-id={`edit-grade-table-details-${ind}-${index}`}
                              >
                                <img
                                  src={editPurpleIcon}
                                  className="GradingScaleEditImg"
                                />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                    <TableRow>
                      <TableCell colSpan={5} className={`AddGradingScaleCell ${this.props.classes.subTitle_font}`}>
                        <img src={addBigIcon} alt="add" className="plus-icon" onClick={() => this.openAddEditGradeScaleDialog(gradeAllData?.id, gradeAllData?.attributes?.name)} data-test-id={`add-grade-img-${index}`}></img>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          ))}
        </div>

        <Button
          className={`${this.props.classes.GradingScale_Feedback_btn_border} add-grade-btn mb-20`}
          onClick={this.openAddEditGradeDialog}
        >
          + Add
        </Button>

        <AddEditGrade
          addEditGradeDialog={addEditGradeDialog}
          handleAddEditGradeDialogClose={this.handleAddEditGradeDialogClose}
          gradeName={gradeName}
          gradeNameError={gradeNameError}
          gradeNameErrorMsg={gradeNameErrorMsg}
          gradeLabel={gradeLabel}
          gradeLabelError={gradeLabelError}
          gradeLabelErrorMsg={gradeLabelErrorMsg}
          score={score}
          scoreError={scoreError}
          scoreErrorMsg={scoreErrorMsg}
          minMarks={minMarks}
          minMarksError={minMarksError}
          minMarksErrorMsg={minMarksErrorMsg}
          maxMarks={maxMarks}
          maxMarksError={maxMarksError}
          maxMarksErrorMsg={maxMarksErrorMsg}
          onChangeHandle={this.onChangeHandle}
          onBlurHandle={this.onBlurHandle}
          addEditGradeClick={this.addEditGradeClick}
        />

        <AddEditGradeScale
          addEditGradeScaleDialog={addEditGradeScaleDialog}
          handleAddEditGradeScaleDialogClose={this.handleAddEditGradeScaleDialogClose}
          isEditGradeScale={isEditGradeScale}
          gradeName={gradeName}
          gradeLabel={gradeLabel}
          gradeLabelError={gradeLabelError}
          gradeLabelErrorMsg={gradeLabelErrorMsg}
          score={score}
          scoreError={scoreError}
          scoreErrorMsg={scoreErrorMsg}
          minMarks={minMarks}
          minMarksError={minMarksError}
          minMarksErrorMsg={minMarksErrorMsg}
          maxMarks={maxMarks}
          maxMarksError={maxMarksError}
          maxMarksErrorMsg={maxMarksErrorMsg}
          onChangeHandle={this.onChangeHandle}
          onBlurHandle={this.onBlurHandle}
          addEditGradeClick={this.addEditGradeClick}
        />

        <DeleteGradeModal
          deleteModal={deleteModal}
          onCloseModal={this.onCloseDeleteModal}
          deleteClick={isDeleteAllGradeData ? this.deleteAllGradeData : this.deleteGradeScaleData}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(
  GradingScale as React.ComponentType<any>
);
// Customizable Area End
