//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
// Customizable Area Start
import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CircularProgress from '@material-ui/core/CircularProgress';
import Spinner from "../../shared/SideBar/src/Spinner";
import {
  img21kSchholLogo,
  imgloginScreenBackground,
  manImage,
  bigCloud,
  smallCloud,
} from "./assets";

import ForgotPasswordAdminController, { Props } from "./ForgotPasswordAdminController.web";

// Customizable Area End

// Customizable Area Start
const root = {
  flexGrow: 1,
  overflowX: "hidden",
} as const;
const loginImageDiv = {
  position: "relative",
  height: "100vh",
} as const;
const halfcircleImage = {
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(142, 123, 253, 1)",
  position: "absolute",
  zIndex: 0,
} as const;
const logoImage = {
  width: "218px",
  height: "68px",
  position: "relative",
  margin: "20px"
} as const;
const textlabel_quoteText = {
  color: "rgba(255, 255, 255, 1)",
  fontWeight: "bold",
  position: "relative",
  fontFamily: "Open sans",
  fontSize: "4.5rem",
  margin: '30px 25px'
} as const;
const image_studentillustratorImage = {
  width: "68%",
  height: "auto",
  bottom: '0%',
  position: "absolute",
  transform: "translateX(80%)",
} as const;
const image_cloudsmallImage = {
  width: "65px",
  height: "51px",
  position: "absolute",
  top: "61%",
  left: "30%",
} as const;
const image_cloudbigImage = {
  width: "151px",
  height: "117px",
  bottom: "10%",
  left: "8%",
  position: 'absolute',
} as const;
const attrbuted_textlabel_div = {
  textAlign: "right",
  fontFamily: "Open sans",
  fontSize: "16px",
  margin: "59px 0.1px 137px 309.2px",
} as const;
const attrbuted_textlabel_signinText = {
  color: "#03014c",
} as const;
const attrbuted_textlabel_signinText2 = {
  color: "rgba(0, 0, 0, 1)",
  fontWeight: "bold",
  margin: "59px 3.5px 50px 9px",
} as const;
const loginDiv = {
  justifyContent: "center",
  margin: "auto",
  width: "60%",
  paddingLeft: "30px",
} as const;
const textlabel_welcomeText = {
  fontSize: "35.1px",
  fontWeight: "bold",
  fontFamily: "Open sans",
} as const;
const textlabel_followinstructionText = {
  width: "100%",
  color: "#03014c",
  fontSize: "12.5px",
  fontFamily: "Open sans",
  lineHeight: 1.5
} as const;
const formDiv = {
  width: "100%",
} as const;
const textlabel_loginText = {
  fontWeight: "bold",
  color: "rgba(255, 255, 255, 1)",
  marginTop: "20px",
  width: "100%",
  backgroundColor: "rgba(142, 123, 253, 1)",
  padding: "10px",
  textTransform: "none",
} as const;
const forrmInput_textfield = {
  width: "100%",
  borderRadius: "8.8px",
  marginBottom: "10px"
} as const;
const spinnerBar = {
  color: "rgba(255, 255, 255, 1)"
} as const;
// Customizable Area End

export default class ForgotPasswordAdmin extends ForgotPasswordAdminController {

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (
      <div>
        {this.state.loadingSpinner && <Spinner spinnerModal={this.state.loadingSpinner} />}
      <div style={root}>
        <Grid container spacing={0}>
          <Grid xs={12} sm={6} spacing={0} style={loginImageDiv}>
            <img
              src={imgloginScreenBackground}
              style={halfcircleImage}
              alt="Background Image"
            />
            <img
              src={manImage}
              alt="Student Illustrator Image"
              style={image_studentillustratorImage}
            />
            <div>
              <img
                src={img21kSchholLogo}
                alt="Logo Image"
                style={logoImage}
              />
            </div>
            <div style={textlabel_quoteText}>
              Let’s learn
              <br />
              Together
            </div>
            <img
              src={smallCloud}
              alt="Small Cloud Image"
              style={image_cloudsmallImage}
            />
            <img
              src={bigCloud}
              alt="Big Cloud Image"
              style={image_cloudbigImage}
            />
          </Grid>
          <Grid xs={12} sm={6} spacing={0}>
            <div>
              <div style={attrbuted_textlabel_div}>
                <span style={attrbuted_textlabel_signinText}>
                  Remember Password ?
                </span><Link href="/EmailAccountLoginBlock" style={attrbuted_textlabel_signinText2}>Login</Link>
              </div>
              <form onSubmit={this.forotpasswordSubmit}>
              <div style={loginDiv}>

                <div>
                  <div style={textlabel_welcomeText}>Forgot Password ?</div>
                  <p style={textlabel_followinstructionText}>Enter your registered email or phone number below to receive password reset instruction.</p>
                </div>
                <div style={formDiv}>
                  
                    <TextField
                      id="standard-basic"
                      placeholder="Enter Email Id "
                      style={forrmInput_textfield}
                      variant="outlined"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value.trim()
                        console.log(value)
                        if (value.length === 0) {
                          this.setState({ emailerror: true, emailerrorText: 'Email is required.' })
                        }
                        else if (!emailReg.test(value)) {
                          this.setState({ emailerror: true, emailerrorText: "Enter a valid Email." })
                        }
                        else if (emailReg.test(value)) {
                          this.setState({ emailerror: false, emailerrorText: "" })
                        }
                        this.setState({ email: event.target.value.trim() })
                      }
                      }
                      onBlur={() => {
                        if (this.state.email === null || this.state.email.length === 0) {
                          this.setState({ emailerror: true, emailerrorText: 'Email is required.' })
                        }
                        else if (!emailReg.test(this.state.email)) {
                          this.setState({ emailerror: true, emailerrorText: "Enter a valid Email." })
                        }
                        else if (emailReg.test(this.state.email)) {
                          this.setState({ emailerror: false, emailerrorText: "" })
                        }
                        else {
                          this.setState({ emailerror: false, emailerrorText: "" })
                        }
                      }}
                      error={this.state.emailerror}
                      helperText={this.state.emailerrorText}
                      inputProps={{ style: { fontFamily: "Open sans", fontSize: 15.8, color: "#03014c" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlineOutlinedIcon fontSize="small" style={{ color: "#7c7ba0", marginLeft: "27px", marginRight: "27px" }} />
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "15.8px",
                          color: '#03014c',
                          fontFamily: "OpenSans",
                          opacity: 0.5,
                          paddingRight: "27px",
                          border: "1px solid #cfcfcf",
                          backgroundColor: "#f1f1f1",
                        }
                      }}
                    />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={textlabel_loginText}
                  // onClick={() => { this.forotpasswordSubmit() }}
                >
                  Send
                </Button>
              </div>
              </form>
            </div>
                  
          </Grid>
        </Grid>
      </div>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End