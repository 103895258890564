// Customizable Area Start
import React, { useState, useEffect } from "react"
import {
    Popover, Box,
    Typography,
    Checkbox,
    Button
} from "@material-ui/core"
import {
    Search,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import './CommonFilterModal.web.css';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface IProps {
    // Customizable Area Start
    dropDownAnchorEl: any;
    data_Type?: any;
    closeModalFun: React.Dispatch<React.SetStateAction<any>>;
    subjectStateData: any;
    saveSubject: any;
    filterSubject: any;
    setFilterSubject: (data: any[]) => void;
    classes: any
    // Customizable Area End
}

export const FilterSubject: React.FC<IProps> = ({
    // Customizable Area Start
    dropDownAnchorEl,
    closeModalFun,
    subjectStateData,
    saveSubject,
    filterSubject,
    setFilterSubject,
    classes
    // Customizable Area End
}) => {
    // Customizable Area Start
    const [searchTerm, setSearchTerm] = useState('')
    const [filter, setFilter] = useState<any>(filterSubject)
    const [SubjectState, setSubjectState] = useState<any>([])

    useEffect(() => {
        setSubjectState(subjectStateData)
    }, [])

    const reset = () => {
        setSubjectState("")
        setSearchTerm('')
    }

    const ArrSubjectData =
        searchTerm === '' ? SubjectState :
            SubjectState.filter((data: any) => data?.subject_name.toLowerCase().includes(searchTerm.toLowerCase()))


    return (
        <>
            <Popover
                id="1"
                anchorEl={dropDownAnchorEl}
                open={Boolean(dropDownAnchorEl)}
                onClose={closeModalFun}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className="FilterPeriodPopover"
                PaperProps={{
                    style: {
                        maxHeight: '70%',
                        width: '27%',
                        padding: "20px 20px",
                        borderRadius: 8,
                        boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.2)",
                        backgroundColor: "#fff",
                        minWidth: '205px'
                    },
                }}
            >
                <Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        className={`${classes.border_Color} searchBox`}
                    >
                        <Search 
                            className={`${classes.icon_color} searchIcon`}
                        />
                        <input
                            placeholder="Search subject"
                            className="inputBox"
                            onChange={
                                ((event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSearchTerm(event.target.value.trim())
                                })}
                        />
                    </Box>

                    <Box
                        className="listingBox"
                    >
                        {ArrSubjectData.length === 0
                            ?
                            <>
                                <Typography className={`noREcordFound ${classes.theme_font}`}>
                                    No record(s) found.
                                </Typography>
                            </>
                            :
                            ArrSubjectData.map((data: any, index: any) => (
                                <Box
                                    key={data.id}
                                    className="subjectData"
                                    onClick={() => {
                                        let checked = ""
                                        if (filter == data.id || parseInt(filter) == parseInt(data.id)) {
                                            checked = ''
                                        } else {
                                            checked = data.id
                                        }
                                        setFilter(checked)
                                    }}
                                >
                                    <Typography
                                        className={`${classes.bodyText_font} attrTypo`}>
                                        {data.subject_name}
                                    </Typography>
                                    <Checkbox
                                        checked={filter == data.id ? true : false}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        className={`checkboxStyled ${classes.icon_color}`}
                                    />
                                </Box>
                            ))
                        }
                    </Box>
                    {SubjectState && SubjectState.length > 0 &&
                        <Box
                            className="btnContainer"
                        >
                            <Button
                                onClick={(e) => {
                                    setFilterSubject(filter)
                                    closeModalFun(e)
                                    reset()
                                }}
                                className={`${classes.button_color} saveBtn`}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={(e: any) => {

                                    closeModalFun(e)

                                }}
                                className={`${classes.button_color_disabled} cancelBtn`}
                            >
                                Cancel
                            </Button>
                        </Box>}
                </Box>
            </Popover>
        </>
    )
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(FilterSubject);
// Customizable Area End