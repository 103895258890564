// Customizable Area Start
import React from "react";
import Grid from '@material-ui/core/Grid';
import {
    Button,
    Dialog,
    Typography,
    TextField,
    NativeSelect,
    FormHelperText,
    InputBase,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import Datetime from "react-datetime";
import moment from 'moment';
import './AdminClassPeriod.web.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import './AddEditClassPeriod.web.css';
// Customizable Area End

// Customizable Area Start
const forrmInput_textfield = {
    textAlign: "start",
} as const;

const useStyles = makeStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
    },
    textFontFamily: {
        fontFamily: "Open sans",
    },
    textFiledCss: {
        fontFamily: "Open sans",
        color: "#3f526d",
        lineHeight: 1.2,

        fontWeight: 600,

        [theme.breakpoints.up('md')]: {
            fontSize: 18,
            padding: '16px 19.2px',
            opacity: 0.5

        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 13.5,
            padding: '12px 15.2px',
            opacity: 0.5
        },
        [theme.breakpoints.only('md')]: {
            fontSize: 16.5,
            padding: '15px 18.2px',
            opacity: 0.5
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 14.5,
            padding: '14px 16.5px',
            opacity: 0.5
        },

    },
    forrmInput_textfield: {
        width: "100%",
        borderRadius: "6px",
        color: "#3f526d",
        lineHeight: 1.2,
        fontWeight: 600,

        fontFamily: "Open sans",
        [theme.breakpoints.up('md')]: {
            fontSize: 18,

        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 13.5,
        },
        [theme.breakpoints.only('md')]: {
            fontSize: 16.5,
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 14.5,
        },

    },
    helperTextCss: {
        color: "#3f526d",
        fontWeight: 600,
        fontFamily: 'Open sans',
        paddingBottom: 10,
        [theme.breakpoints.up('md')]: {
            fontSize: 18,

        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 14.5,

        },
        [theme.breakpoints.only('md')]: {
            fontSize: 17.5,

        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 15.5,

        },
    },
    forrmInput_online_field: {
        backgroundColor: "#f9f6f6",
        marginLeft: 0,
        width: "100%",
        marginBottom: 0,

        paddingLeft: 10,



        [theme.breakpoints.up('md')]: {
            height: 56,
            borderRadius: '8.8px',

        },
        [theme.breakpoints.only('xs')]: {
            height: 43.84,
            borderRadius: '6px',

        },
        [theme.breakpoints.only('md')]: {
            height: 52.41,
            borderRadius: '6px',

        },
        [theme.breakpoints.only('sm')]: {
            height: 48.03,
            borderRadius: '6px',
        },

    },

}))

const BootstrapInput1 = withStyles((theme) => ({
    input: {
        width: "100%",
        textAlign: "start",
        color: "#3f526d",

        fontFamily: "Open sans",
        opacity: 0.9,
        lineHeight: 1.2,
        border: "solid 0.3px rgba(63, 82, 109, 0.5)",
        fontWeight: 600,
        [theme.breakpoints.up('md')]: {
            fontSize: 18,
            padding: '15px 19.2px',

        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 13.5,
            padding: '12px 15.2px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: 16.5,
            padding: '15px 18.2px',
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 14.5,
            padding: '14px 16.5px',
        },
    },
}))(InputBase);
// Customizable Area End
interface Props {
    // Customizable Area Start
    addEditClassPeriodDialog: boolean;
    handleAddEditClassPeriodDialogClose: any;
    isEditPeriod: boolean;
    classStartDate: any;
    onChangeStartDate: any;
    weekDay: string;
    addEditClassPeriodClick: any;
    batchData: any;
    selectedBatch: any;
    periodData: any;
    selectedPeriod: any;
    classData: any;
    selectedClass: any;
    subjectData: any;
    selectedSubject: any;
    selectedSubjectError: boolean;
    teacherData: any;
    selectedTeacher: any;
    selectedTeacherError: boolean;
    onChangeSubject: any;
    onBlurSubject: any;
    onChangeTeacher: any;
    selectedMonthDate: any
    onBlurTeacher: any;
    isEditable: boolean;
    onChangeIsEditable: any;
    onChangeSchoolPeriodName: any;
    schoolPeriodName: any;
    onChangeIsOnline: any;
    isOnline: boolean;
    isOneTime:boolean;
    onChangeIsOneTime:any;
    // Customizable Area End
}



export const AddEditClassPeriod = ({
    // Customizable Area Start
    addEditClassPeriodDialog,
    handleAddEditClassPeriodDialogClose,
    isEditPeriod,
    classStartDate,
    onChangeStartDate,
    weekDay,
    addEditClassPeriodClick,
    batchData,
    selectedBatch,
    periodData,
    selectedPeriod,
    classData,
    selectedClass,
    subjectData,
    selectedSubject,
    selectedSubjectError,
    teacherData,
    selectedMonthDate,
    selectedTeacher,
    selectedTeacherError,
    onChangeSubject,
    onBlurSubject,
    onChangeTeacher,
    onBlurTeacher,
    isEditable,
    isOneTime,
    onChangeIsOnline,
    onChangeIsEditable,
    onChangeSchoolPeriodName,
    schoolPeriodName,
    onChangeIsOneTime,
    isOnline
    // Customizable Area End
}: Props) => {
    // Customizable Area Start
    const theme = useTheme();
    const screenSize = useMediaQuery(theme.breakpoints.only('xs'));
    const disablePastDt = (current: any) => {
        const today = moment().subtract(1, 'day')
        return current.isAfter(today);
    };
    const classes = useStyles()
    return (
        <Dialog
            PaperProps={{
                style: { borderRadius: 8 }
            }}
            aria-labelledby="customized-dialog-title"
            maxWidth="md"
            open={addEditClassPeriodDialog}
            onClose={() => handleAddEditClassPeriodDialogClose()}
            closeAfterTransition
            fullWidth={true}
            disableAutoFocus={true}
            className="AddEditClassPeriodDialogBox"
        >
            <div className="innerBoxContainer">
                <Grid container >
                    <Grid item xs={12} className="flexBetween">
                        <Typography className={`headingText ${classes.textFontFamily}`}>
                            {isEditPeriod ? 'Edit Period' : 'Add New Period'}
                        </Typography>
                        <CloseIcon
                            onClick={() => handleAddEditClassPeriodDialogClose()}
                            className="closeIcon"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} className="formContainer">
                        <Grid container className="pt15">
                        <Grid item xs={12} sm={6} style={{ paddingRight: !screenSize ? 15 : 0 }}>
                                <Typography
                                    className={classes.helperTextCss}

                                >
                                    Name
                                </Typography>
                                <div>
                                    <TextField
                                        id="standard-basic"
                                        placeholder="Enter Name"
                                        style={forrmInput_textfield}
                                        className={`${classes.forrmInput_textfield} inputField ${classes.textFontFamily}`}
                                        variant="outlined"
                                        value={schoolPeriodName}
                                        onChange={onChangeSchoolPeriodName}
                                        inputProps={{
                                            className: classes.textFiledCss,
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ paddingLeft: !screenSize ? 15 : 0, paddingTop: screenSize ? 15 : 0 }}>
                                <Typography
                                    className={classes.helperTextCss}
                                >
                                    Start date
                                </Typography>
                                <div >
                                    <Datetime

                                        dateFormat={"DD/MM/YYYY"}
                                        timeFormat={false}
                                        strictParsing={true}
                                        isValidDate={disablePastDt}
                                        input={true}
                                        value={isEditPeriod ? moment(classStartDate, "DD MM YYYY").format(
                                            "DD/MM/YYYY") :
                                            moment(classStartDate, "DD MM YYYY").format(
                                                "DD/MM/YYYY"
                                            )}
                                        className="class-period-datepicker input_today_date_css_format"
                                        inputProps={{
                                            placeholder: "Date of Birth",
                                            className: "class-period-datepicker Date_of_birth_calendar_Icon_register cursorDefault",
                                            readOnly: true,
                                            disabled:true,
                                        }}
                                        closeOnSelect
                                        onChange={onChangeStartDate}
                                    />
                                </div>
                            </Grid>
                       </Grid>


                        <Grid container className="pt15">
                            <Grid item xs={12} sm={6} style={{ paddingRight: !screenSize ? 15 : 0 }}>
                                <Typography
                                    className={classes.helperTextCss}
                                >
                                    Batch
                                </Typography>
                                <div>

                                    <TextField
                                        id="standard-basic"
                                        placeholder="Batch"
                                        style={forrmInput_textfield}
                                        className={`${classes.forrmInput_textfield} inputFieldDisabled ${classes.textFontFamily}`}
                                        variant="outlined"
                                        value={selectedBatch?.attributes?.name}
                                        disabled
                                        inputProps={{
                                            className: classes.textFiledCss,
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ paddingLeft: !screenSize ? 15 : 0, paddingTop: screenSize ? 15 : 0 }}>
                                <Typography
                                    className={`${classes.helperTextCss} padding-top-15`}

                                >
                                    Period
                                </Typography>
                                <div>
                                    
                                    <TextField
                                        id="standard-basic"
                                        placeholder="week day"
                                        style={forrmInput_textfield}
                                        className={`${classes.forrmInput_textfield} inputFieldDisabled ${classes.textFontFamily}`}
                                        variant="outlined"
                                        value={selectedPeriod?.attributes?.name}
                                        disabled
                                        inputProps={{
                                            className: classes.textFiledCss
                                        }}
                                    />
                                </div>
                            </Grid>

                        </Grid>

                        <Grid container className="pt15">
                            <Grid item xs={12} sm={6} style={{ paddingRight: !screenSize ? 15 : 0 }}>
                                <Typography
                                    className={classes.helperTextCss}
                                >
                                    Class
                                </Typography>
                                <div>

                                    <TextField
                                        id="standard-basic"
                                        placeholder="week day"
                                        className={`${classes.forrmInput_textfield} inputFieldDisabled ${classes.textFontFamily}`}
                                        style={forrmInput_textfield}
                                        variant="outlined"
                                        value={selectedClass?.attributes?.display_name}
                                        disabled
                                        inputProps={{
                                            className: classes.textFiledCss
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ paddingLeft: !screenSize ? 15 : 0, paddingTop: screenSize ? 15 : 0 }}>
                                <Typography
                                    className={classes.helperTextCss}
                                >
                                    One Time Period
                                </Typography>
                                <div>

                                    <FormControlLabel
                                        className={classes.forrmInput_online_field}
                                        control={
                                            <Checkbox
                                                checked={isOneTime}
                                                onChange={onChangeIsOneTime}
                                                name="includingNagativeMark"
                                                className="checkboxStyle"
                                            />
                                        }
                                        label="Yes"
                                    />
                                </div>
                            </Grid>

                        </Grid>
                        <Grid container className="pt15">
                            <Grid item xs={12} sm={6} style={{ paddingRight: !screenSize ? 15 : 0 }}>
                                <Typography
                                    className={classes.helperTextCss}
                                >
                                    Is Online
                                </Typography>
                                <div>

                                    <FormControlLabel
                                        className={classes.forrmInput_online_field}
                                        control={
                                            <Checkbox
                                                checked={isOnline}
                                                onChange={onChangeIsOnline}
                                                name="includingNagativeMark"
                                                className="checkboxStyle"
                                            />
                                        }
                                        label="Yes"
                                    />
                                </div>

                            </Grid>
                            <Grid item xs={12} sm={6} style={{ paddingLeft: !screenSize ? 15 : 0, paddingTop: screenSize ? 15 : 0 }}>
                                <Typography
                                    className={classes.helperTextCss}
                                >
                                    Is Editable
                                </Typography>
                                <div>

                                    <FormControlLabel
                                        className={classes.forrmInput_online_field}
                                        control={
                                            <Checkbox
                                                checked={isEditable}
                                                onChange={onChangeIsEditable}
                                                name="includingNagativeMark"
                                                className="checkboxStyle"
                                            />
                                        }
                                        label="Yes"
                                    />
                                </div>
                            </Grid>

                        </Grid>
                        <Grid container className="pt15">
                            <Grid item xs={12} sm={6} style={{ paddingRight: !screenSize ? 15 : 0 }}>
                                <Typography
                                    className={classes.helperTextCss}
                                >
                                    Teacher
                                </Typography>
                                <div>
                                <NativeSelect
                                    id="select"
                                    placeholder="Select"
                                    className={classes.forrmInput_textfield}
                                    style={forrmInput_textfield}
                                    variant="outlined"
                                    IconComponent={ExpandMoreIcon}
                                    value={selectedTeacher}
                                    error={selectedTeacherError}
                                    onChange={onChangeTeacher}
                                    onBlur={onBlurTeacher}
                                    input={<BootstrapInput1 />}
                                >
                                    <option value="" disabled>Select Teacher</option>
                                    {teacherData?.map((item: any) =>
                                        <option value={item.id}>{item.attributes.first_name} {item.attributes.last_name}</option>
                                    )}
                                </NativeSelect>
                                {selectedTeacherError && (
                                    <FormHelperText className="errorText">
                                        Please select teacher.
                                    </FormHelperText>
                                )}
                            </div>

                            </Grid>
                            <Grid item xs={12} sm={6} style={{ paddingLeft: !screenSize ? 15 : 0, paddingTop: screenSize ? 15 : 0 }}>
                                <Typography
                                    className={classes.helperTextCss}
                                >
                                    Subject
                                </Typography>
                                <div>
                                <NativeSelect
                                    id="select"
                                    placeholder="Select"
                                    className={classes.forrmInput_textfield}
                                    style={forrmInput_textfield}
                                    variant="outlined"
                                    IconComponent={ExpandMoreIcon}
                                    value={selectedSubject}
                                    error={selectedSubjectError}
                                    onChange={onChangeSubject}
                                    onBlur={onBlurSubject}
                                    input={<BootstrapInput1 />}
                                >
                                    <option value="" disabled>Select Subject</option>
                                    {subjectData?.map((item: any) =>
                                        <option value={item.id}>{item.subject_name}</option>
                                    )}
                                </NativeSelect>
                                {selectedSubjectError && (
                                    <FormHelperText className="errorText">
                                        Please select subject.
                                    </FormHelperText>
                                )}
                            </div>
                            </Grid>

                        </Grid>



                    </Grid>
                </Grid>

                <div className="btnContainer">
                    <Button
                        className="updateBtn"
                        onClick={(e: any) => {
                            addEditClassPeriodClick()
                        }}
                    >
                        <Typography
                            className={`updateBtnTypo ${classes.textFontFamily}`}
                        >
                            {isEditPeriod ? 'Update' : 'Create'}
                        </Typography>
                    </Button>
                </div>

            </div>
        </Dialog>
    );
    // Customizable Area End
};
// Customizable Area Start
export default AddEditClassPeriod;
// Customizable Area End