// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import FillInTheBlanksTypeQuestionController, {
  Props,
} from "./FillInTheBlanksTypeQuestionController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import {
  imageMarksMinusSVG,
  imageMarksPlusSVG,
  imgBackArrowSVG,
} from "../assets";
import QuestionMetaData from "../Components/QuestionMetaData.web";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import "../TeacherAssessment.web.css";
import QuestionSolution from "../Components/QuestionSolution.web";
import SelectImageModal from "./SelectImageModal.web";
import AlertModal from "../../../alert/src/AlertModal.web";
import { HISTORY } from "../../../../components/src/comman";
import { ImagePreivewModal } from "../Components/ImagePreivewModal.web";
import ChooseFromGalleryModal from "./ChooseFromGalleryModal.web";
import { invalidCharsForNumberType } from "../../../profiles/src/utils/common";
import { typesOfFillInTheBlankQuestions } from "../utils/constants";
import { toast } from "react-toastify";
import TextEditor from "./TextEditor/TextEditor.web";
import QuestionSidebarCollapse from "./QuestionSidebarCollapse.web";
import QuestionPreviewModal from '../Components/QuestionPreviewModal.web';
import './AddQuestion.web.css';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const form_input_textfield = {
  width: "100%",
  borderRadius: "8px",
  margin: "0px 0px 20px 0px",
  boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  border: "none",
  outline: "none",
  fontSize: 20,
  fontWeight: 600,
  padding: "12px 20px",
} as const;
const form_input_textfield_error = {
  width: "100%",
  borderRadius: "8px",
  margin: "0px 0px 20px 0px",
  boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  border: "1px solid red",
  outline: "none",
  color: "#3f526d",
  fontSize: 20,
  fontWeight: 600,
  padding: "12px 20px",
} as const;
// Customizable Area End

export class FillInTheBlanksTypeQuestion extends FillInTheBlanksTypeQuestionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const {
      marks,
      negativeMarks,
      sectiondata,
      questionOptionList,
      charNumber,
      selectedCategoryQuestionData,
    } = this.state;
    const WhiteRadio = withStyles({
      root: {
        color: "#3f526d",
        padding: "0px",
        "&$checked": {
          color: "white",
          backgroundColor: "#3f526d",
          padding: "2px",
        },
      },
      checked: {},
    })((props: RadioProps) => <Radio color="default" {...props} />);

    const optionThemeStyles = (data: any, title: string, index: any) => {
      const assessmentData = localStorage.getItem("assessmentData");
      let themeName;
      if (assessmentData !== null) {
        const data = JSON.parse(assessmentData || "{}");
        if (data.assessment_theme !== null) {
          themeName = data.assessment_theme.name;
        } else {
          themeName = "Default Test"
        }
      } else {
        themeName = "Default Test"
      }

      switch (themeName) {
        case 'Default Test': case 'Rounded Rectangle Layout': return (
          <Grid item xs={11}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="theme_option_box question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="question_option_text"
                    style={{ backgroundColor: `${data.color}` }}
                  >
                    <Typography className="question_option_title">
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={8}>
                  {data.isImageAvailble ? (
                    <Box className="theme_option_image_box">
                      <img 
                        className="fill_option_image"
                        src={data.src}
                        onClick={() => {
                          this.setState({
                            previewImageModal: true,
                          });
                        }}
                      />
                      {this.state.previewImageModal && (
                        <ImagePreivewModal
                          previewImageModal={
                            this.state.previewImageModal
                          }
                          modalhandleClose={() =>
                            this.setState({
                              previewImageModal: false,
                            })
                          }
                          previewImage={data.src}
                        />
                      )}
                      <Typography
                        className={`theme_option_text ${this.props.classes.bodyText_font}`}
                      >
                        {data.name}
                      </Typography>
                      <ClearIcon
                        className={`match_img_clear ${this.props.classes.icon_color}`}
                        onClick={() => {
                          let tempResponseList = this.state
                            .questionOptionList;
                          const findData = this.state
                            .questionOptionList[index];
                          tempResponseList[index] = {
                            ...findData,
                            src: "",
                            name: "",
                            isImageAvailble: false,
                            upload_id: 0,
                          };
                          this.setState({
                            questionOptionList: tempResponseList,
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <TextField
                      id="standard-basic"
                      placeholder="Add answer option"
                      className={`no-underline ${this.props.classes.input_theme_style} add_answer_field`}
                      autoComplete="off"
                      value={data.answer}
                      autoFocus={false}
                      inputProps={{
                        className: "add_answer_input"
                      }}
                      onChange={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        const index = this.state.questionOptionList.findIndex(
                          (res: any) =>
                            res.charNumber ===
                            parseInt(data.charNumber)
                        );
                        const findData = this.state
                          .questionOptionList[index];
                        tempResponseList[index] = {
                          ...findData,
                          answer: event.target.value,
                          files: [],
                          right_answer: false,
                        };
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  className="fill_option_img_upload_div"
                >
                  {!this.state.multipleResponse ? (
                    <RadioGroup
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                      onChange={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        this.state.questionOptionList.forEach(
                          (listData: any, index: number) => {
                            if (
                              listData.charNumber ===
                              data.charNumber
                            ) {
                              const findData = this.state
                                .questionOptionList[index];
                              tempResponseList[index] = {
                                ...findData,
                                right_answer: true,
                              };
                              this.setState({
                                answerSelected: true,
                              });
                            } else {
                              const findData = this.state
                                .questionOptionList[index];
                              tempResponseList[index] = {
                                ...findData,
                                right_answer: false,
                              };
                            }
                          }
                        );
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                    >
                      <WhiteRadio
                        value={data.charNumber}
                        checked={data.right_answer}
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "A" }}
                        checkedIcon={<CheckCircleOutlineIcon />}
                      />
                    </RadioGroup>
                  ) : (
                    <WhiteRadio
                      value={data.charNumber}
                      checked={data.right_answer}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                      onClick={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        this.state.questionOptionList.forEach(
                          (listData: any, index: number) => {
                            if (
                              listData.charNumber ===
                              data.charNumber
                            ) {
                              const findData = this.state
                                .questionOptionList[index];
                              if (findData.right_answer) {
                                tempResponseList[index] = {
                                  ...findData,
                                  right_answer: false,
                                };
                              } else {
                                tempResponseList[index] = {
                                  ...findData,
                                  right_answer: true,
                                };
                              }
                            }
                          }
                        );
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                      checkedIcon={<CheckCircleOutlineIcon />}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
        case 'Outline Rounded Rectangle Layout': return (
          <Grid item xs={11}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="theme_option_box question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="outline_round_rect_theme question_option_text"
                    style={{ border: `solid 1px ${data.color}` }}
                  >
                    <Typography className="question_option_title" style={{ color: `${data.color}` }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={8}>
                  {data.isImageAvailble ? (
                    <Box className="theme_option_image_box">
                      <img
                        src={data.src}
                        className="fill_option_image"
                        onClick={() => {
                          this.setState({
                            previewImageModal: true,
                          });
                        }}
                      />
                      {this.state.previewImageModal && (
                        <ImagePreivewModal
                          previewImageModal={
                            this.state.previewImageModal
                          }
                          modalhandleClose={() =>
                            this.setState({
                              previewImageModal: false,
                            })
                          }
                          previewImage={data.src}
                        />
                      )}
                      <Typography
                        className={`theme_option_text ${this.props.classes.bodyText_font}`}
                      >
                        {data.name}
                      </Typography>
                      <ClearIcon
                        className={`match_img_clear ${this.props.classes.icon_color}`}
                        onClick={() => {
                          let tempResponseList = this.state
                            .questionOptionList;
                          const findData = this.state
                            .questionOptionList[index];
                          tempResponseList[index] = {
                            ...findData,
                            src: "",
                            name: "",
                            isImageAvailble: false,
                            upload_id: 0,
                          };
                          this.setState({
                            questionOptionList: tempResponseList,
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <TextField
                      id="standard-basic"
                      placeholder="Add answer option"
                      className={`no-underline ${this.props.classes.input_theme_style} add_answer_field`}
                      autoComplete="off"
                      value={data.answer}
                      autoFocus={false}
                      inputProps={{
                        className: "add_answer_input"
                      }}
                      onChange={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        const index = this.state.questionOptionList.findIndex(
                          (res: any) =>
                            res.charNumber ===
                            parseInt(data.charNumber)
                        );
                        const findData = this.state
                          .questionOptionList[index];
                        tempResponseList[index] = {
                          ...findData,
                          answer: event.target.value,
                          files: [],
                          right_answer: false,
                        };
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  className="fill_option_img_upload_div"
                >     
                  {!this.state.multipleResponse ? (
                    <RadioGroup
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                      onChange={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        this.state.questionOptionList.forEach(
                          (listData: any, index: number) => {
                            if (
                              listData.charNumber ===
                              data.charNumber
                            ) {
                              const findData = this.state
                                .questionOptionList[index];
                              tempResponseList[index] = {
                                ...findData,
                                right_answer: true,
                              };
                              this.setState({
                                answerSelected: true,
                              });
                            } else {
                              const findData = this.state
                                .questionOptionList[index];
                              tempResponseList[index] = {
                                ...findData,
                                right_answer: false,
                              };
                            }
                          }
                        );
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                    >
                      <WhiteRadio
                        value={data.charNumber}
                        checked={data.right_answer}
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "A" }}
                        checkedIcon={<CheckCircleOutlineIcon />}
                      />
                    </RadioGroup>
                  ) : (
                    <WhiteRadio
                      value={data.charNumber}
                      checked={data.right_answer}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                      onClick={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        this.state.questionOptionList.forEach(
                          (listData: any, index: number) => {
                            if (
                              listData.charNumber ===
                              data.charNumber
                            ) {
                              const findData = this.state
                                .questionOptionList[index];
                              if (findData.right_answer) {
                                tempResponseList[index] = {
                                  ...findData,
                                  right_answer: false,
                                };
                              } else {
                                tempResponseList[index] = {
                                  ...findData,
                                  right_answer: true,
                                };
                              }
                            }
                          }
                        );
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                      checkedIcon={<CheckCircleOutlineIcon />}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
        case 'Circle Layout': return (
          <Grid item xs={11}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="theme_option_box question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="circle_layout_theme question_option_text"
                    style={{ backgroundColor: `${data.color}` }}
                  >
                    <Typography className="question_option_title">
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={8}>
                  {data.isImageAvailble ? (
                    <Box className="theme_option_image_box">
                      <img
                        src={data.src}
                        className="fill_option_image"
                        onClick={() => {
                          this.setState({
                            previewImageModal: true,
                          });
                        }}
                      />
                      {this.state.previewImageModal && (
                        <ImagePreivewModal
                          previewImageModal={
                            this.state.previewImageModal
                          }
                          modalhandleClose={() =>
                            this.setState({
                              previewImageModal: false,
                            })
                          }
                          previewImage={data.src}
                        />
                      )}
                      <Typography
                        className={`theme_option_text ${this.props.classes.bodyText_font}`}
                      >
                        {data.name}
                      </Typography>
                      <ClearIcon
                        className={`match_img_clear ${this.props.classes.icon_color}`}
                        onClick={() => {
                          let tempResponseList = this.state
                            .questionOptionList;
                          const findData = this.state
                            .questionOptionList[index];
                          tempResponseList[index] = {
                            ...findData,
                            src: "",
                            name: "",
                            isImageAvailble: false,
                            upload_id: 0,
                          };
                          this.setState({
                            questionOptionList: tempResponseList,
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <TextField
                      id="standard-basic"
                      placeholder="Add answer option"
                      className={`no-underline ${this.props.classes.input_theme_style} add_answer_field`}
                      autoComplete="off"
                      value={data.answer}
                      autoFocus={false}
                      inputProps={{
                        className: "add_answer_input"
                      }}
                      onChange={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        const index = this.state.questionOptionList.findIndex(
                          (res: any) =>
                            res.charNumber ===
                            parseInt(data.charNumber)
                        );
                        const findData = this.state
                          .questionOptionList[index];
                        tempResponseList[index] = {
                          ...findData,
                          answer: event.target.value,
                          files: [],
                          right_answer: false,
                        };
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  className="fill_option_img_upload_div"
                >
                  {!this.state.multipleResponse ? (
                    <RadioGroup
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                      onChange={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        this.state.questionOptionList.forEach(
                          (listData: any, index: number) => {
                            if (
                              listData.charNumber ===
                              data.charNumber
                            ) {
                              const findData = this.state
                                .questionOptionList[index];
                              tempResponseList[index] = {
                                ...findData,
                                right_answer: true,
                              };
                              this.setState({
                                answerSelected: true,
                              });
                            } else {
                              const findData = this.state
                                .questionOptionList[index];
                              tempResponseList[index] = {
                                ...findData,
                                right_answer: false,
                              };
                            }
                          }
                        );
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                    >
                      <WhiteRadio
                        value={data.charNumber}
                        checked={data.right_answer}
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "A" }}
                        checkedIcon={<CheckCircleOutlineIcon />}
                      />
                    </RadioGroup>
                  ) : (
                    <WhiteRadio
                      value={data.charNumber}
                      checked={data.right_answer}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                      onClick={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        this.state.questionOptionList.forEach(
                          (listData: any, index: number) => {
                            if (
                              listData.charNumber ===
                              data.charNumber
                            ) {
                              const findData = this.state
                                .questionOptionList[index];
                              if (findData.right_answer) {
                                tempResponseList[index] = {
                                  ...findData,
                                  right_answer: false,
                                };
                              } else {
                                tempResponseList[index] = {
                                  ...findData,
                                  right_answer: true,
                                };
                              }
                            }
                          }
                        );
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                      checkedIcon={<CheckCircleOutlineIcon />}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
        case 'Outline Circle Layout': return (
          <Grid item xs={11}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="theme_option_box question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="circle_layout_theme question_option_text"
                    style={{ border: `1px solid ${data.color}` }}
                  >
                    <Typography className="question_option_title" style={{ color: `${data.color}` }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={8}>
                  {data.isImageAvailble ? (
                    <Box className="theme_option_image_box">
                      <img
                        src={data.src}
                        className="fill_option_image"
                        onClick={() => {
                          this.setState({
                            previewImageModal: true,
                          });
                        }}
                      />
                      {this.state.previewImageModal && (
                        <ImagePreivewModal
                          previewImageModal={
                            this.state.previewImageModal
                          }
                          modalhandleClose={() =>
                            this.setState({
                              previewImageModal: false,
                            })
                          }
                          previewImage={data.src}
                        />
                      )}
                      <Typography
                        className={`theme_option_text ${this.props.classes.bodyText_font}`}
                      >
                        {data.name}
                      </Typography>
                      <ClearIcon
                        className={`match_img_clear ${this.props.classes.icon_color}`}
                        onClick={() => {
                          let tempResponseList = this.state
                            .questionOptionList;
                          const findData = this.state
                            .questionOptionList[index];
                          tempResponseList[index] = {
                            ...findData,
                            src: "",
                            name: "",
                            isImageAvailble: false,
                            upload_id: 0,
                          };
                          this.setState({
                            questionOptionList: tempResponseList,
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <TextField
                      id="standard-basic"
                      placeholder="Add answer option"
                      className={`no-underline ${this.props.classes.input_theme_style} add_answer_field`}
                      autoComplete="off"
                      value={data.answer}
                      autoFocus={false}
                      inputProps={{
                        className: "add_answer_input"
                      }}
                      onChange={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        const index = this.state.questionOptionList.findIndex(
                          (res: any) =>
                            res.charNumber ===
                            parseInt(data.charNumber)
                        );
                        const findData = this.state
                          .questionOptionList[index];
                        tempResponseList[index] = {
                          ...findData,
                          answer: event.target.value,
                          files: [],
                          right_answer: false,
                        };
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  className="fill_option_img_upload_div"
                >     
                  {!this.state.multipleResponse ? (
                    <RadioGroup
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                      onChange={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        this.state.questionOptionList.forEach(
                          (listData: any, index: number) => {
                            if (
                              listData.charNumber ===
                              data.charNumber
                            ) {
                              const findData = this.state
                                .questionOptionList[index];
                              tempResponseList[index] = {
                                ...findData,
                                right_answer: true,
                              };
                              this.setState({
                                answerSelected: true,
                              });
                            } else {
                              const findData = this.state
                                .questionOptionList[index];
                              tempResponseList[index] = {
                                ...findData,
                                right_answer: false,
                              };
                            }
                          }
                        );
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                    >
                      <WhiteRadio
                        value={data.charNumber}
                        checked={data.right_answer}
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "A" }}
                        checkedIcon={<CheckCircleOutlineIcon />}
                      />
                    </RadioGroup>
                  ) : (
                    <WhiteRadio
                      value={data.charNumber}
                      checked={data.right_answer}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                      onClick={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        this.state.questionOptionList.forEach(
                          (listData: any, index: number) => {
                            if (
                              listData.charNumber ===
                              data.charNumber
                            ) {
                              const findData = this.state
                                .questionOptionList[index];
                              if (findData.right_answer) {
                                tempResponseList[index] = {
                                  ...findData,
                                  right_answer: false,
                                };
                              } else {
                                tempResponseList[index] = {
                                  ...findData,
                                  right_answer: true,
                                };
                              }
                            }
                          }
                        );
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                      checkedIcon={<CheckCircleOutlineIcon />}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
        case 'Rectangle Block Layout': return (
          <Grid item xs={11}>
            <Box
              style={{
                border: `solid 1px ${data.color}`,
                backgroundColor: `${data.color}`,
              }}
              className="theme_rect_block_option_box question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="rect_block_layout_theme question_option_text"
                    style={{ backgroundColor: `${data.color}` }}
                  >
                    <Typography className="question_option_title">
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={8}>
                  {data.isImageAvailble ? (
                    <Box className="theme_option_image_box">
                      <img
                        src={data.src}
                        className="fill_option_image"
                        onClick={() => {
                          this.setState({
                            previewImageModal: true,
                          });
                        }}
                      />
                      {this.state.previewImageModal && (
                        <ImagePreivewModal
                          previewImageModal={
                            this.state.previewImageModal
                          }
                          modalhandleClose={() =>
                            this.setState({
                              previewImageModal: false,
                            })
                          }
                          previewImage={data.src}
                        />
                      )}
                      <Typography
                        className={`theme_option_text ${this.props.classes.bodyText_font}`}
                      >
                        {data.name}
                      </Typography>
                      <ClearIcon                     
                        className={`match_img_clear ${this.props.classes.icon_color}`}
                        onClick={() => {
                          let tempResponseList = this.state
                            .questionOptionList;
                          const findData = this.state
                            .questionOptionList[index];
                          tempResponseList[index] = {
                            ...findData,
                            src: "",
                            name: "",
                            isImageAvailble: false,
                            upload_id: 0,
                          };
                          this.setState({
                            questionOptionList: tempResponseList,
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <TextField
                      id="standard-basic"
                      placeholder="Add answer option"
                      className={`no-underline ${this.props.classes.input_theme_style} add_answer_field`}
                      autoComplete="off"
                      value={data.answer}
                      autoFocus={false}
                      inputProps={{
                        className: "add_answer_input"
                      }}
                      onChange={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        const index = this.state.questionOptionList.findIndex(
                          (res: any) =>
                            res.charNumber ===
                            parseInt(data.charNumber)
                        );
                        const findData = this.state
                          .questionOptionList[index];
                        tempResponseList[index] = {
                          ...findData,
                          answer: event.target.value,
                          files: [],
                          right_answer: false,
                        };
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  className="fill_option_img_upload_div"
                >     
                  {!this.state.multipleResponse ? (
                    <RadioGroup
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                      onChange={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        this.state.questionOptionList.forEach(
                          (listData: any, index: number) => {
                            if (
                              listData.charNumber ===
                              data.charNumber
                            ) {
                              const findData = this.state
                                .questionOptionList[index];
                              tempResponseList[index] = {
                                ...findData,
                                right_answer: true,
                              };
                              this.setState({
                                answerSelected: true,
                              });
                            } else {
                              const findData = this.state
                                .questionOptionList[index];
                              tempResponseList[index] = {
                                ...findData,
                                right_answer: false,
                              };
                            }
                          }
                        );
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                    >
                      <WhiteRadio
                        value={data.charNumber}
                        checked={data.right_answer}
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "A" }}
                        checkedIcon={<CheckCircleOutlineIcon />}
                      />
                    </RadioGroup>
                  ) : (
                    <WhiteRadio
                      value={data.charNumber}
                      checked={data.right_answer}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                      onClick={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        this.state.questionOptionList.forEach(
                          (listData: any, index: number) => {
                            if (
                              listData.charNumber ===
                              data.charNumber
                            ) {
                              const findData = this.state
                                .questionOptionList[index];
                              if (findData.right_answer) {
                                tempResponseList[index] = {
                                  ...findData,
                                  right_answer: false,
                                };
                              } else {
                                tempResponseList[index] = {
                                  ...findData,
                                  right_answer: true,
                                };
                              }
                            }
                          }
                        );
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                      checkedIcon={<CheckCircleOutlineIcon />}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
        case 'Outline Rectangle Block Layout': return (
          <Grid item xs={11}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="question_option_box theme_fill_blank_rect_block_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="rect_block_layout_theme question_option_text"
                  >
                    <Typography className="question_option_title" style={{ color: `${data.color}` }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={8}>
                  {data.isImageAvailble ? (
                    <Box className="theme_option_image_box">
                      <img
                        src={data.src}
                        className="fill_option_image"
                        onClick={() => {
                          this.setState({
                            previewImageModal: true,
                          });
                        }}
                      />
                      {this.state.previewImageModal && (
                        <ImagePreivewModal
                          previewImageModal={
                            this.state.previewImageModal
                          }
                          modalhandleClose={() =>
                            this.setState({
                              previewImageModal: false,
                            })
                          }
                          previewImage={data.src}
                        />
                      )}
                      <Typography
                        className={`theme_option_text ${this.props.classes.bodyText_font}`}
                      >
                        {data.name}
                      </Typography>
                      <ClearIcon
                        className={`match_img_clear ${this.props.classes.icon_color}`}
                        onClick={() => {
                          let tempResponseList = this.state
                            .questionOptionList;
                          const findData = this.state
                            .questionOptionList[index];
                          tempResponseList[index] = {
                            ...findData,
                            src: "",
                            name: "",
                            isImageAvailble: false,
                            upload_id: 0,
                          };
                          this.setState({
                            questionOptionList: tempResponseList,
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <TextField
                      id="standard-basic"
                      placeholder="Add answer option"
                      className={`no-underline ${this.props.classes.input_theme_style} add_answer_field`}
                      autoComplete="off"
                      value={data.answer}
                      autoFocus={false}
                      inputProps={{
                        className: "add_answer_input"
                      }}
                      onChange={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        const index = this.state.questionOptionList.findIndex(
                          (res: any) =>
                            res.charNumber ===
                            parseInt(data.charNumber)
                        );
                        const findData = this.state
                          .questionOptionList[index];
                        tempResponseList[index] = {
                          ...findData,
                          answer: event.target.value,
                          files: [],
                          right_answer: false,
                        };
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  className="fill_option_img_upload_div"
                >
                  {!this.state.multipleResponse ? (
                    <RadioGroup
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                      onChange={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        this.state.questionOptionList.forEach(
                          (listData: any, index: number) => {
                            if (
                              listData.charNumber ===
                              data.charNumber
                            ) {
                              const findData = this.state
                                .questionOptionList[index];
                              tempResponseList[index] = {
                                ...findData,
                                right_answer: true,
                              };
                              this.setState({
                                answerSelected: true,
                              });
                            } else {
                              const findData = this.state
                                .questionOptionList[index];
                              tempResponseList[index] = {
                                ...findData,
                                right_answer: false,
                              };
                            }
                          }
                        );
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                    >
                      <WhiteRadio
                        value={data.charNumber}
                        checked={data.right_answer}
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "A" }}
                        checkedIcon={<CheckCircleOutlineIcon />}
                      />
                    </RadioGroup>
                  ) : (
                    <WhiteRadio
                      value={data.charNumber}
                      checked={data.right_answer}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                      onClick={(event: any) => {
                        let tempResponseList = this.state
                          .questionOptionList;
                        this.state.questionOptionList.forEach(
                          (listData: any, index: number) => {
                            if (
                              listData.charNumber ===
                              data.charNumber
                            ) {
                              const findData = this.state
                                .questionOptionList[index];
                              if (findData.right_answer) {
                                tempResponseList[index] = {
                                  ...findData,
                                  right_answer: false,
                                };
                              } else {
                                tempResponseList[index] = {
                                  ...findData,
                                  right_answer: true,
                                };
                              }
                            }
                          }
                        );
                        this.setState({
                          questionOptionList: tempResponseList,
                        });
                      }}
                      checkedIcon={<CheckCircleOutlineIcon />}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
      }
    }

    return (
      <>
        <div>
          {this.state.showLoader && (
            <Spinner spinnerModal={this.state.showLoader} />
          )}
          <Grid
            container
            className="marks_section"
          >
            <Grid
              item
              xs={12}
              sm={10}
              className="question_flex_column"
            >
              <Typography
                className={`back_with_arrow ${this.props.classes.bodyText_font}`}
              >
                <span onClick={HISTORY.goBack}>
                  <img
                    src={imgBackArrowSVG}
                    className="question_back_arrow"
                  ></img>
                  Back
                </span>

                <span
                  className={`${this.props.classes.bodyText_font} textlabel_breadcrum`}
                >
                  <span className="text-style-light">
                    {this.state.isEdit
                      ? "Edit question "
                      : "Create new question "}{" "}
                  </span>
                  (
                  {selectedCategoryQuestionData?.question_category_id === 1 ? (
                    <>Objective</>
                  ) : (
                    <>Subjective</>
                  )}{" "}
                  - {selectedCategoryQuestionData?.name})
                </span>
              </Typography>

              <div className="marks_wrap_div">
                <div className="marks_div">
                  <Typography
                    className={`question_marks_text ${this.props.classes.subHeading_font}`}
                  >
                    Marks
                  </Typography>
                  <div>
                    <div
                      className={this.state.marksError ? "view_marksbox red_border" : "view_marksbox none_border"}
                    >
                      <img
                        src={imageMarksPlusSVG}
                        className="image_marks"
                        onClick={this.plusMarksClick}
                      ></img>
                     
                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={(event) => {
                          const value: number = parseFloat(
                            parseFloat(event.target.value)
                              .toFixed(3)
                              .slice(0, -1)
                          );
                          if (value > 0 || marks > 0) {
                            this.setState({ marksError: false });
                          } else {
                            this.setState({
                              marksError: true,
                              marks: 0,
                              marksErrorText: "Please enter marks.",
                            });
                          }
                          if (
                            value <
                            this.state.sectiondata?.remaining_marks
                          ) {
                            this.setState({ marks: value, marksError: false });
                          } else if (
                            value ==
                            this.state.sectiondata?.remaining_marks - 1
                          ) {
                            this.setState({ marks: value, marksError: false });
                          }
                          if (value >= this.state.negativeMarks) {
                            this.setState({
                              marks: value,
                              negmarksError: false,
                            });
                          } else {
                            this.setState({
                              marks: value,
                              negmarksError: true,
                            });
                          }
                          if (this.state.isEdit) {
                            if (
                              value >
                              this.state.sectiondata?.remaining_marks +
                              HISTORY.location.state?.questionData?.attributes?.marks
                            ) {
                              this.setState({
                                marks: value,
                                marksError: true,
                                marksErrorText:
                                  "Marks not greater than section marks.",
                              });
                            } else {
                              this.setState({
                                marks: value,
                              });
                            }
                          } else {
                            if (
                              value > this.state.sectiondata?.remaining_marks
                            ) {
                              this.setState({
                                marks: value,
                                marksError: true,
                                marksErrorText:
                                  "Marks not greater than section marks.",
                              });
                            } else {
                              this.setState({
                                marks: value,
                              });
                            }
                          }
                        }}
                        onKeyPress={(event) => {
                          if (invalidCharsForNumberType.includes(event.key)) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        value={marks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.marksError && (
                      <FormHelperText className="red_text">
                        {this.state.marksErrorText}
                      </FormHelperText>
                    )}
                  </div>
                </div>

                {this.state.assessmentHasNegativeMarking && (
                  <div className="marks_div">
                    <Typography
                      className={`question_marks_text ${this.props.classes.subHeading_font}`}
                    >
                      Negative Marks
                    </Typography>
                    <div
                      className={this.state.negmarksError ? "view_marksbox red_border" : "view_marksbox none_border"}
                    >
                      <img
                        src={imageMarksMinusSVG}
                        className="image_marks"
                        onClick={this.minusMarksClick}
                      ></img>
                    
                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={(event) => {
                          const value: number = parseFloat(
                            parseFloat(event.target.value)
                              .toFixed(3)
                              .slice(0, -1)
                          );
                          if (value > this.state.marks) {
                            this.setState({ negmarksError: true });
                          } else {
                            this.setState({ negmarksError: false });
                          }
                          this.setState({ negativeMarks: value });
                        }}
                        onKeyPress={(event) => {
                          if (invalidCharsForNumberType.includes(event.key)) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        value={negativeMarks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.negmarksError && (
                      <FormHelperText className="red_text">
                        Negative marking not greater then marks.
                      </FormHelperText>
                    )}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={2} className="remaining_section_marks">
              <Typography
                className={`remaining_marks ${this.props.classes.subHeading_font}`}
              >
                Remaining Section Marks
              </Typography>

              <div className="circular_div">
                <CircularProgress
                  variant="static"
                  className="grey_circular"
                  size={92}
                  thickness={2}
                  value={100}
                />
                <CircularProgress
                  variant="static"
                  disableShrink
                  className="colored_circular"
                  size={92}
                  value={this.state.remainSectionMarkPr}
                  thickness={2}
                />
                <Typography
                  className={`circular_marks_text ${this.props.classes.bodyText_font}`}
                >
                  {(
                    sectiondata?.section_marks -
                    sectiondata?.remaining_marks
                  ).toFixed(2)}
                  /{sectiondata?.section_marks}
                </Typography>
              </div>
            </Grid>
          </Grid>
          
          <Typography
            className={`question_text ${this.props.classes.subHeading_font}`}
          >
            Question
          </Typography>
          <div ref={this.questionEditorRef}>
            <TextEditor
              myref={this.questionEditorRef}
              handleChange={(value: any) => {
                this.setState({ question: value }, () => {
                  if (
                    this.state.question === null ||
                    this.state.question === ""
                  ) {
                    this.setState({
                      questionError: true,
                      questionErrorText: "Please enter question.",
                    });
                  } else if (
                    this.state.question &&
                    !this.state.question.includes("_")
                  ) {
                    this.setState({
                      questionError: true,
                      questionErrorText:
                        "Please enter valid question type with blank(_).",
                    });
                  } else {
                    this.setState({ questionError: false });
                  }
                });
              }}
              editorData={
                HISTORY.location.state?.questionData?.attributes?.title
                  ? HISTORY.location.state?.questionData?.attributes?.title
                  : ""
              }
              isEdit={this.state.isEdit}
            />
          </div>
          {this.state.questionError && (
            <FormHelperText className="question_error">
              {this.state.questionErrorText}
            </FormHelperText>
          )}
        
          <div className="fill_blank_option_div">
            <span>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.handleQuestionType(event);
                  }}
                  defaultValue="top"
                >
                  {typesOfFillInTheBlankQuestions &&
                    typesOfFillInTheBlankQuestions.map((item: any) => (
                      <FormControlLabel
                        value="end"
                        control={
                          <WhiteRadio
                            value={item.value}
                            checked={
                              this.state.fillInTheBlankType === item.value
                            }
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "A" }}
                            className="fill_blank_option"
                            checkedIcon={<CheckCircleOutlineIcon />}
                          />
                        }
                        label={`${item.label}`}
                        className={`fill_blank_option_item ${this.props.classes.bodyText_font}`}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </span>
          </div>
          {this.state.fillInTheBlankType === "mcq" && (
            <Box className="match_type_div">
              <RadioGroup
                aria-label="position"
                name="position"
                defaultValue="top"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  let tempResponseList = this.state.questionOptionList;
                  const index = this.state.questionOptionList.findIndex(
                    (res: any) =>
                      res.charNumber === parseInt(event.target.value)
                  );
                  const findData = this.state.questionOptionList[index];
                  tempResponseList[index] = {
                    ...findData,
                    right_answer: true,
                  };
                  this.setState({
                    questionOptionList: tempResponseList,
                    answerSelected: true,
                  });
                }}
              >
                <Grid container spacing={4}>
                  {questionOptionList.map((data: any, index: number) => {
                    const title = String.fromCharCode(65 + index);
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className="align_item_center_question"
                      >
                        <Grid
                          container
                          spacing={1}
                          className="align_item_center_question"
                        >

                          {/* Theme Styling Block Start */}
                          {optionThemeStyles(data, title, index)}
                          {/* Theme Styling Block End */}

                          <Grid item xs={1}>
                            <ClearIcon
                              className={`fill_option_clear_icon ${this.props.classes.icon_color}`}
                              onClick={() => {
                                if (questionOptionList.length > 2) {
                                  const questionOptionListData = questionOptionList.filter(
                                    (res: any) =>
                                      res.charNumber != data.charNumber
                                  );
                                  this.setState({
                                    questionOptionList: questionOptionListData,
                                    charNumber: charNumber - 1,
                                  });
                                } else {
                                  toast.warn(
                                    "You need to keep at least 2 options !"
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </RadioGroup>
             
              <Box className="add_graph_line_div">
                <Button
                  onClick={() => {
                    const randomColor = this.generateColor();
                    let title: string = "";
                    title = String.fromCharCode(65 + charNumber) + title;
                    questionOptionList.push({
                      color: randomColor,
                      charNumber: charNumber + 1,
                      right_answer: false,
                      files: [],
                      answer: "",
                    });
                    this.setState({
                      questionOptionList: questionOptionList,
                      charNumber: charNumber + 1,
                    });
                  }}
                  className={`add_new_match_btn ${this.props.classes.outline_button_no_hover}`}
                >
                  <AddIcon className="add_new_match_icon" />
                  Add New
                </Button>
              </Box>
            </Box>
          )}
          {this.state.fillInTheBlankType === "text_type" && (
            <>
              <div className="fill_blank_text_type">
                <TextField
                  id="standard-basic"
                  placeholder="Write answer here…"
                  style={
                    this.state.answerError
                      ? form_input_textfield_error
                      : form_input_textfield
                  }
                  className={`no-underline ${this.props.classes.input_theme_style}`}
                  inputProps={{
                    className: "fill_blank_input"
                  }}
                  value={this.state.answer}
                  onChange={(event: any) =>
                    this.setState({ answer: event.target.value }, () => {
                      if (
                        this.state.answer === null ||
                        this.state.answer === ""
                      ) {
                        this.setState({ answerError: true });
                      } else {
                        this.setState({ answerError: false });
                      }
                    })
                  }
                  error={this.state.answerError}
                />
              </div>
              {this.state.answerError && (
                <FormHelperText className="question_error">
                  Please enter one word answer.
                </FormHelperText>
              )}
            </>
          )}
          <QuestionSolution
            hintAnswer={this.state.hintAnswer}
            hintAnswerOnchange={(value: any, id: any) => {
              let tempData = this.state.hintAnswer;
              const index = this.state.hintAnswer.findIndex(
                (res: any) => res.hintId === id
              );
              tempData[index].hint = value.trim();
              this.setState({
                hintAnswer: tempData,
              });
            }}
            explaination={this.getExplanation()}
            getPreSignedValue={(
              preSignedId: string,
              typeofAttachment: string,
              blobUrl: string,
            ) => {
              if (typeofAttachment === "hints") {
                let tempData = this.state.hintAnswer;
                tempData[0].files = [preSignedId];
                tempData[0].src = blobUrl;
                this.setState({
                  hintAnswer: tempData,
                  hintId: preSignedId,

                });
              } else {
                this.setState({ solutionImageSignedId: preSignedId });
              }
            }}
            explainationOnchange={(value: any) => {
              this.setState({
                explaination: value,
              });
            }}
            attachmentExplanationUrl={this.state.attachmentExplanationUrl}
            hintsExplanationUrl={this.state.hintsExplanationUrl}
            removeSolutionImage={(attachmentType: string) => {
              if (attachmentType === "hints") {
                this.setState({
                  hintsExplanationUrl: "",
                  hintId: null,
                });
              } else {
                this.setState({
                  attachmentExplanationUrl: "",
                  solutionImageSignedId: null,
                });
              }
            }}
          />
          <QuestionMetaData
            selectedSubject={this.state.selectedSubject}
            onChangeSubject={(e: any) => {
              this.setState({ selectedSubject: e.target.value });
            }}
            chapterListData={this.state.chapterListData}
            selectedChapter={this.state.selectedChapter}
            onChangeChapter={(e: any) => {
              this.setState(
                {
                  selectedChapter: e.target.value,
                  metaDataErrors: {
                    ...this.state.metaDataErrors,
                    chapter: false,
                  },
                },
                () => this.get_unit_list(this.state.selectedChapter, true)
              );
            }}
            unitListData={this.state.unitListData}
            selectedUnit={this.state.selectedUnit}
            onChangeUnit={(e: any) => {
              this.setState({
                selectedUnit: e.target.value,
                metaDataErrors: { ...this.state.metaDataErrors, unit: false },
              });
            }}
            selectedSkill={this.state.selectedSkill}
            onChangeSkill={(e: any) => {
              this.setState({
                selectedSkill: e.target.value,
                metaDataErrors: { ...this.state.metaDataErrors, skill: false },
              });
            }}
            selectedProficiency={this.state.selectedProficiency}
            onChangeProficiency={(e: any) => {
              this.setState({
                selectedProficiency: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  proficiency: false,
                },
              });
            }}
            selectedDifficultyLevel={this.state.selectedDifficultyLevel}
            onChangeDifficultyLevel={(e: any) => {
              this.setState({
                selectedDifficultyLevel: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  difficultyLevel: false,
                },
              });
            }}
            keyword={this.state.keyword}
            keywordsList={this.state.keywordsList}
            onChangeKeywords={this.onChangeKeywords}
            onBlurKeywords={this.onBlurKeywords}
            onKeyDownKeywords={this.onKeyDownKeywords}
            handleDeleteKeyword={this.handleDeleteKeyword}
            metaDataErrors={this.state.metaDataErrors}
            selectedBloomTaxonomy={this.state.selectedBloomTaxonomy}
            onChangeBloomTaxonomy={(e: any) => {
              this.setState({
                selectedBloomTaxonomy: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  bloomTaxonomy: false,
                },
              });
            }}
          />

          <div className="question_action_items">
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.background_button_no_hover}`}
              onClick={() => { this.setState({ prevQuestionModal: true }) }}
            >
              <Box className="preview_btn_box">
                <VisibilityIcon className="eye_preview" />
                <Typography> Preview</Typography>
              </Box>
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.button_color}`}
              onClick={this.saveQuestion}
            >
              {this.state.isEdit ? "Update" : "Save"}
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_cancel_btn ${this.props.classes.outline_button_no_hover}`}
              onClick={()=>HISTORY.push("/TeacherAssessmentCreateSection")}
            >
              Cancel
            </Button>
          </div>
          <QuestionPreviewModal questionType={"fill in blank"} selectedImageBlobUrl={this.state.selectedImageBlobUrl} questionPreviewModal={this.state.prevQuestionModal} textAnswer={this.state.answer} closeModalFun={() => { this.setState({ prevQuestionModal: false }) }} fillBlankType={this.state.fillInTheBlankType} optionAnswer={this.state.questionOptionList} question={this.state.question} questionHint={this.state.hintAnswer} />

          <AlertModal
            alertModal={this.state.alertModal}
            isTeacher={true}
            onCloseModal={() => {
              this.setState({ alertModal: false });
            }}
            alertTitle="Alert!"
            alertMessage={this.state.alertMessage}
            buttonText="OK"
          />

          <SelectImageModal
            openSelectImageModal={this.state.openSelectImageModal}
            onCloseModal={() => this.setState({ openSelectImageModal: false })}
            onClickChooseFromGallery={() => {
              this.setState({
                openChooseFromGalleryModal: true,
                openSelectImageModal: false,
              });
            }}
            onClickUploadFromLocal={
              this.state.optionImageSelection
                ? this.showOpenFileDlg
                : this.showFirstOpenFileDlg
            }
          />

          <ChooseFromGalleryModal
            openChooseFromGalleryModal={this.state.openChooseFromGalleryModal}
            onCloseModal={() =>
              this.setState({ openChooseFromGalleryModal: false })
            }
            onClickImage={(id: any, url: any) => {
              if (this.state.optionImageSelection) {
                let TempResponseData = this.state.questionOptionList;
                const findIndex = this.state.questionOptionList.findIndex(
                  (res: any) =>
                    res.charNumber === this.state.selectedOptionNumber
                );
                const findData = this.state.questionOptionList.find(
                  (res: any) =>
                    res.charNumber === this.state.selectedOptionNumber
                );
                TempResponseData[findIndex] = {
                  ...findData,
                  src: url,
                  upload_id: id,
                  name: "Gallery Image",
                  isImageAvailble: true,
                };
                this.setState({
                  selectedGalleryImageId: id,
                  questionOptionList: TempResponseData,
                });
              } else {
                this.setState({
                  mainGalleryImageId: id,
                  selectedGalleryImageId: id,
                  selectedGalleryImageUrl: url,
                });
              }
            }}
            onClickChoose={() => this.onClickGalleryImageChoose()}
            selectedGalleryImageId={this.state.selectedGalleryImageId}
            onTabChangeClearId={() =>
              this.setState({ selectedGalleryImageId: 0 })
            }
          />
        </div>
        {this.state.isEdit &&
          <QuestionSidebarCollapse editedQuestionData={HISTORY.location.state?.questionData} />
        }
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

export default withStyles(themeCreateStyle)(
  FillInTheBlanksTypeQuestion as React.ComponentType<any>
);
// Customizable Area End
