// Customizable Area Start
import React from "react";
import {
    Grid,
    Modal,
    Typography,
    Button,
} from "@material-ui/core";
import '../TeacherAssessment.web.css';
import { imgGallerySVG, imgUploadSVG } from '../assets';
import { withStyles } from '@material-ui/core/styles'
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

interface Props {
    // Customizable Area Start
    openSelectImageModal: boolean;
    onCloseModal: any;
    onClickChooseFromGallery: any;
    onClickUploadFromLocal: any;
    classes: any;
    // Customizable Area End
}

export const SelectImageModal = ({
    // Customizable Area Start
    openSelectImageModal,
    onCloseModal,
    onClickChooseFromGallery,
    onClickUploadFromLocal,
    classes
    // Customizable Area End
}: Props) => {
    // Customizable Area Start

    return (
        <>
            <Modal
                open={openSelectImageModal}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid

                    className="modal-width-dynamic select-image-modal"
                >
                    <Typography
                        className={`select-image-text font-22 ${classes.title_font}`}
                    >
                        Select Image
                    </Typography>


                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} className="select-image-grid padding-top">
                            <img src={imgGallerySVG}
                                className="choose-small-img"
                                onClick={onClickChooseFromGallery}></img>
                            <Typography
                                className={`choose-from-gallery-text font-16 ${classes.subTitle_font}`}
                            >
                                Choose from Gallery
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className="select-image-grid padding-bottom">
                            <img src={imgUploadSVG}
                                className="choose-small-img"
                                onClick={onClickUploadFromLocal}></img>
                            <Typography
                                className={`choose-from-gallery-text font-16 ${classes.subTitle_font}`}
                            >
                                Upload from Local
                            </Typography>
                        </Grid>
                    </Grid>

                    <Button
                        disableRipple={true}
                        variant="outlined"
                        onClick={onCloseModal}
                        className={`select-image-cancel-btn ${classes.outline_button_no_hover}`}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Modal>
        </>
    );
    // Customizable Area End
};

// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(SelectImageModal);
// Customizable Area End
