// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import moment from "moment";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  openScheduleModal: boolean
  spinner: boolean
  openPublishScheduleModal: boolean
  assessmentSummary: any;
  successMessage: string;
  showLoader: boolean;
  alertModal: boolean;
  alertMessage: string;
  publishRadioValue: string;
  addGroupModal: boolean;
  divisionList: any;
  error: {
    divisionId: boolean;
    studentList: boolean;
  };
  divisionId: string;
  studentList: any;
  studentIdList: any;
  publishType:any;
  scheduleLaterDate:any;
  scheduleLaterTime:any;
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}
export default class AssesmentSummaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPublishAssessment: string = "";
  apiGetDivisionList: string = "";
  apiGetStudentList: string = "";
  apiCreateAssessment: string = "";
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      openScheduleModal: false,
      spinner: false,
      openPublishScheduleModal: false,
      assessmentSummary: {},
      successMessage: '',
      showLoader: false,
      alertModal: false,
      alertMessage: "",
      publishRadioValue: "class",
      addGroupModal: false,
      divisionList: [],
      error: {
        divisionId: false,
        studentList: false
      },
      divisionId: '',
      studentList: {},
      studentIdList: [],
      publishType:'',
      scheduleLaterDate:'',
      scheduleLaterTime:''

    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // local guidelines
    const assessmentData = localStorage.getItem("assessmentData");

    if (assessmentData !== null) {
      const data = JSON.parse(assessmentData || "{}");
      this.setState({
        assessmentSummary: data ? data : []
      }, () => {
        this.getDivisionsList(data?.grade_id)
      })
    } else {
      // redirect back to create assessment
      HISTORY.push({
        pathname: "/TeacherAssessmentCreateDetail"
      });
      // redirect back to create assessment END
    }
    // local guidelines END
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Typical usage (don't forget to compare props):
    if (this.state.successMessage !== prevState.successMessage) {
      this.setState({
        openPublishScheduleModal: true
      })
    }
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      switch (apiRequestCallId) {
        case this.apiCreateAssessment:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {

                let assessmentId = responseJson.data?.id;
                if(responseJson.data?.id){
                  assessmentId = responseJson.data?.id;
                }else if(responseJson.assessment?.id){
                  assessmentId = responseJson.assessment?.id;
                }
                if(this.state.publishType === 'publish'){
                  this.onClickPublishNow(this.getPublishNowBody(assessmentId))
                }else if (this.state.publishType === 'schedule'){
                  this.onClickPublishNow(this.getScheduleLaterBody(this.state.scheduleLaterDate, this.state.scheduleLaterTime, assessmentId))
                }

              } else {
                if (this.state.openScheduleModal) {
                  this.setState({
                    openScheduleModal: false,
                  })
                }
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiPublishAssessment:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                if (responseJson?.data?.publish_type === 'now') {
                  this.setState({
                    successMessage: "Published",
                    showLoader: false
                  })
                } else {
                  this.setState({
                    successMessage: "Scheduled",
                    openScheduleModal: false,
                    showLoader: false
                  })
                }

                // Clearing local Assessment Data
                localStorage.removeItem("assessmentData")
                // Clearing local Assessment Data END

              } else {
                if (this.state.openScheduleModal) {
                  this.setState({
                    openScheduleModal: false,
                  })
                }
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.setState({
              showLoader: false
            })
          }
          break;
        case this.apiGetDivisionList:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {

                this.setState({ divisionList: responseJson.data });
                let objectKeys = Object.keys(responseJson.data).map((item: any) => item);
                let tempStudentList: any = {};
                objectKeys.map((element: any) => {
                  let classStudents = responseJson.data[element].map((element: any) => {
                    return {
                      student_id: element.account_id,
                      first_name: element?.account?.data?.attributes?.first_name,
                      last_name: element?.account?.data?.attributes?.last_name,
                      gender: element?.account?.data?.attributes?.gender,
                      avatar: element?.account?.data?.attributes?.avatar,
                      email: element?.account?.data?.attributes?.email
                    }
                  });
                  tempStudentList[element] = classStudents;
                })
                this.setState({
                  studentList: tempStudentList
                })
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.setState({
              showLoader: false
            })
          }
          break;
      }

    }
  }

  getEmptyState = () => ({
    error: {
      divisionId: false,
      studentList: false
    }
  })

  validate = () => {
    let isValid = true;
    const {
      publishRadioValue,
      divisionId,
      studentIdList
    } = this.state;

    const error = this.getEmptyState().error;

    if (publishRadioValue === 'class_division') {
      if (divisionId === '') {
        error.divisionId = true;
        isValid = false;
      }
    } else if (publishRadioValue === 'group') {
      if (studentIdList.length === 0) {
        error.studentList = true;
        isValid = false;
      }
    }

    this.setState({
      error,
    });

    return isValid;
  }

  getPublishNowBody = (assessment_id:number) => {
    if (this.state.publishRadioValue === 'class_division') {
      return {
        data: {
          assessment_id: assessment_id,
          publish_type: 'now',
          date_time: moment(new Date()).add(15, 'minutes').format(),
          published_to: 'class_and_division',
          school_class_id: [this.state.divisionId]
        }
      }
    } else if (this.state.publishRadioValue === 'group') {
      return {
        data: {
          assessment_id: assessment_id,
          publish_type: 'now',
          date_time: moment(new Date()).add(15, 'minutes').format(),
          published_to: 'student_group',
          student_ids: this.state.studentIdList
        }
      }
    } else {
      return {
        data: {
          assessment_id: assessment_id,
          publish_type: 'now',
          date_time: moment(new Date()).add(15, 'minutes').format(),
          published_to: 'grade',
          grade_id: this.state.assessmentSummary?.assessment?.grade_id
        }
      }
    }
  }


  getScheduleLaterBody = (date: any, time: any, assessment_id:number) => {
    if (this.state.publishRadioValue === 'class_division') {
      return {
        data: {
          assessment_id: assessment_id,
          publish_type: 'later',
          date_time: moment(moment(date + " " + time, "DD/MM/YYYY HH:mm").toDate()).format(),
          published_to: 'class_and_division',
          school_class_id: [this.state.divisionId]
        }
      }
    } else if (this.state.publishRadioValue === 'group') {
      return {
        data: {
          assessment_id: assessment_id,
          publish_type: 'later',
          date_time: moment(moment(date + " " + time, "DD/MM/YYYY HH:mm").toDate()).format(),
          published_to: 'student_group',
          student_ids: this.state.studentIdList
        }
      }
    } else {
      return {
        data: {
          assessment_id: assessment_id,
          publish_type: 'later',
          date_time: moment(moment(date + " " + time, "DD/MM/YYYY HH:mm").toDate()).format(),
          published_to: 'grade',
          grade_id: this.state.assessmentSummary?.assessment?.grade_id
        }
      }
    }
  }

  onClickPublishNow = (data: any) => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id
    };

    const httpBody = data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPublishAssessment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiAssessmentPublish
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPutMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDivisionChange = (event: any) => {
    this.setState({
      divisionId: event.target.value
    })
  }
  getDivisionsList = (gradeId: string) => {
    this.setState({
      showLoader: true
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
      school: school_Data?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDivisionList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDivisionsEndPoint + `?grade_id=${gradeId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleStudentToggle = (student_id: any) => {
    const currentIndex = this.state.studentIdList.indexOf(student_id);
    const newChecked = [...this.state.studentIdList];

    if (currentIndex === -1) {
      newChecked.push(student_id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({
      studentIdList: newChecked
    });

  }

  handleSelectAll = (studentIds: any) => {
    const set = new Set([...this.state.studentIdList, ...studentIds]);
    this.setState({
      studentIdList: [...set]
    })
  }


  handleDeSelectAll = (studentIds: any) => {
    const newChecked = [...this.state.studentIdList];
    studentIds.forEach((element: any) => {
      const currentIndex = newChecked.indexOf(element);
      newChecked.splice(currentIndex, 1);
    });
    this.setState({
      studentIdList: newChecked
    })
  }

  createAssessmentAndPublish = () => {
    if (!this.validate()) {
      return
    }
    this.setState({
      showLoader: true
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    const assessmentData: any = JSON.parse(localStorage.getItem("assessmentData") || "{}");

    let requestType:any = configJSON.POST;
    let endPoint = `${configJSON.createAssessmentsEndPoint}`;

    if(assessmentData?.assessment_id){
      requestType = configJSON.PUT;
      endPoint = `${configJSON.updateAssessmentsEndPoint}/${assessmentData?.assessment_id}/update_assessment`
    }

    // Section data
    let assessmentSections = assessmentData?.assessment_sections_attributes?.map((item: any) => {
      let questionIds = [];
      if (item?.question_data?.length > 0) {
        questionIds = item?.question_data.map((obj: any) => obj.id);
      }

      const data:any = {
        name: item?.name,
        section_marks: item?.section_marks,
        total_questions: item?.total_questions,
        time_limit: item?.time_limit,
        question_ids: questionIds
      };

      if (item?.section_id) {
        data.id = item?.section_id;
      }
      return  data;
    })
    // pushing deleted sections
    if(assessmentData?.deleted_sections?.length > 0){
      assessmentSections = [...assessmentSections, ...assessmentData?.deleted_sections]
    }
    // pushing deleted sections END
    // Section data END

    // Guidelines data
    let assessmentGuidelines = assessmentData?.assessment_guidelines?.map((item: any) => {
      const data: any = {
        description: item.description,
        school_id: school_Data?.school_id
      };
      if (item?.guideline_id) {
        data.id = item?.guideline_id;
      }
      return data;
    })

    // pushing deleted guidelines
    if(assessmentData?.guideline_to_destroy?.length > 0){
      assessmentGuidelines = [...assessmentGuidelines, ...assessmentData?.guideline_to_destroy]
    }
    // pushing deleted guidelines END
    // Guidelines data END

    const httpBody = {
      assessment_name: assessmentData?.assessment_name,
      grade_id: assessmentData?.grade_id,
      assessment_theme_id: assessmentData?.assessment_theme?.id,
      subject_id: assessmentData?.subject_id,
      exam_type: assessmentData?.exam_type,
      maximum_marks: assessmentData?.maximum_marks,
      term_id: assessmentData?.term_id,
      negative_marking: assessmentData?.negative_marking,
      time_limit: assessmentData?.time_limit,
      difficulty_level: assessmentData?.difficulty_level,
      assessment_sections_attributes: assessmentSections ? assessmentSections : [],
      assessment_guide_lines_attributes: assessmentGuidelines ? assessmentGuidelines : [],
      assessment_category_id: assessmentData?.assessment_category_id
    };

    this.apiCreateAssessment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      requestType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOnClose = () => {
    this.setState({ openPublishScheduleModal: false })
  }

  handleOnCloseScheduleModal = () => {
    this.setState({
      openScheduleModal: false
    })
  }

  onCloseAlertModal = () => {
    this.setState({ alertModal: false });
  }

  handleOnCloseAddGroup = () => {
    this.setState({ addGroupModal: false });
  }

  handleOnSchedule = (date: any, time: any) => {
    this.setState({
      publishType: 'schedule',
      scheduleLaterDate: date,
      scheduleLaterTime: time,
    }, () => {
      this.createAssessmentAndPublish()
    })
  }

  onClickPublishNowBtn = () => {
    let questionCountError = new Array();
    let sectionNameError = new Array();
    const { assessmentSummary } = this.state;

    assessmentSummary?.assessment_sections_attributes?.forEach((element: any) => {
      let sQuestionAdded = element?.question_data ? element.question_data.length : 0;
      let sTotalQuestion = element.total_questions;

      if (sQuestionAdded !== sTotalQuestion) {
        let remainingQuestions = sTotalQuestion - sQuestionAdded;
        questionCountError.push(remainingQuestions);
        sectionNameError.push(element?.name);
      }
    });

    if (questionCountError.length > 0) {
      let errorMessage: string = 'Please add ' + questionCountError.join(', ').replace(/, ([^,]*)$/, ' and $1') + ' more question(s) to ' + sectionNameError.join(', ').replace(/, ([^,]*)$/, ' and $1') + '.';
      this.setState({
        alertModal: true,
        alertMessage: errorMessage
      })
    } else {
      this.setState({
        publishType: 'publish',
      }, () => {
        this.createAssessmentAndPublish()
      })
    }
  }

  onClickScheduleLaterBtn = () => {
    let questionCountError = new Array();
    let sectionNameError = new Array();
    const { assessmentSummary } = this.state;

    assessmentSummary?.assessment_sections_attributes?.forEach((element: any) => {
      let sQuestionAdded = element?.question_data ? element.question_data.length : 0;
      let sTotalQuestion = element.total_questions;

      if (sQuestionAdded !== sTotalQuestion) {
        let remainingQuestions = sTotalQuestion - sQuestionAdded;
        questionCountError.push(remainingQuestions);
        sectionNameError.push(element?.name);
      }

    });

    if (questionCountError.length > 0) {
      let errorMessage: string = 'Please add ' + questionCountError.join(', ').replace(/, ([^,]*)$/, ' and $1') + ' more question(s) to ' + sectionNameError.join(', ').replace(/, ([^,]*)$/, ' and $1') + '.';
      this.setState({
        alertModal: true,
        alertMessage: errorMessage
      })
    } else {
      this.setState({
        openScheduleModal: this.validate()
      })
    }
  }
  // Customizable Area End
}