// Customizable Area Start
import React from "react";
import {
  Box,
  Modal,
  Divider,
  Grid,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
const previewVideoDiv = {
  fontFamily: "Open sans",
  fontSize: "24px",
  fontWeight: "bold",
  letterSpacing: "normal",
  textAlign: "center",
  color: "#3f526d",
  marginBottom: "14px",
} as const;
// Customizable Area End

interface Props {
  // Customizable Area Start
  onCloseModal: any;
  openPreviewVideoModal: boolean;
  videoSrc: any;
  // Customizable Area End
}

export const PreviewVideoModal = ({
  // Customizable Area Start
  onCloseModal,
  openPreviewVideoModal,
  videoSrc,
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  return (
    <>
      <Modal
        open={openPreviewVideoModal}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="question_preview_video_modal">
          <Grid className="preview_text_div">
            <div style={previewVideoDiv} className="preview_video_text">Preview Video</div>
          </Grid>

          <Divider className="preview_video_divider" />

          <video controlsList="nodownload" height="85%" width="100%" src={videoSrc} controls />
        </Box>
      </Modal>
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
export default PreviewVideoModal;
// Customizable Area End
