import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import moment from "moment";

import { toast } from "react-toastify";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area Start
export const configJSON = require("./../config.js");
// Customizable Area End


export interface Props {
    classes: any
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
     anchorEl: any;
    userData: any;
    anchorEl1: any;
    currentPage: any;
    nextPage: any;
    totalPage: any;
    totalCount: any;
    classList: any;
    divisionList: any;
    filterClassId: any;
    filterDivisionId: any;
    filterStatus: any;
    loadingSpinner: boolean;
    searchQuery: any;
    applyFilterStudent: boolean;
    alertModal: boolean;
    alertMessage: any;
    perPage: any;
     data: any;
     date:any;
    period:any;    

    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class TeacherDocumentController extends BlockComponent<Props, S, SS> {
// Customizable Area Start
    apigetStudentSubjectsId: string = "";
    apigetSearchStudent: string = "";
    apigetSubjectRemoveId: string = "";
    apigetTeacherClassesId: string = "";
    apigetStudentClasslId: string = "";
    apigetClasslId: any;
    apigetClassDivisionlId: any;
    apifilteruserData: any;
    apiaddStudentClassesId: string = "";
// Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
             anchorEl: null,
            userData: [],
               anchorEl1: null,
                currentPage: 0,
            nextPage: null,
            totalPage: 1,
            totalCount: 1,
            classList: [],
            divisionList: [],
            filterClassId: "",
            filterDivisionId: "",
            filterStatus: '',

            loadingSpinner: false,
            searchQuery: '',
            applyFilterStudent: false,
            alertModal: false,
            alertMessage: '',
            perPage: 10,
            data:[],
            date:moment().format("DD/MM/YYYY"),
            period: 'all',
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        this.get_userdata = this.get_userdata.bind(this);
        this.get_user = this.get_user.bind(this);
        // Customizable Area End
    }


    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.get_user();
         this.get_classes();
         // Customizable Area End
    }




// Customizable Area Start

    onMenuClose = () => {
        this.setState({ anchorEl: null })
    };

    get_userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }

    viewdetails=(id:any)=>{
        HISTORY.push({
            pathname: "/ViewDocument",
            state: { id:id ,
            from:'teachers'},
          });
          localStorage.setItem("userdocument","teachers")
    }
  
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));


            if (apiRequestCallId === this.apigetClasslId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ classList: responseJson.data })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }       
            else if (apiRequestCallId === this.apigetStudentClasslId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (responseJson.data != null) {
                            this.setState({
                                userData: responseJson?.data,
                            })
                            if (responseJson.data.length !== 0) {
                                if (Object.keys(responseJson.meta).length !== 0) {
                                    this.setState({
                                        nextPage: responseJson.meta.pagination?.next_page,
                                        totalPage: responseJson.meta.pagination?.total_pages, totalCount: responseJson.meta.pagination?.total_count
                                    })
                                }
                                else {
                                    this.setState({
                                        nextPage: '',
                                        totalPage: 1, totalCount: 1
                                    })
                                }
                            }
                            else {
                                this.setState({
                                    nextPage: '',
                                    totalPage: 1, totalCount: 1
                                })

                            }
                        }
                    } else {
                        this.setState({
                            nextPage: '',
                            totalPage: 1, totalCount: 1
                        })
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
        }
    }

    get_user = () => {
        let url :any='' 
        if(this.state.filterStatus !=="" ){
          url=  url.concat(`&status=${this.state.filterStatus}`)
        }
        if(this.state.searchQuery !==""){
          url=  url.concat(`&account_name=${this.state.searchQuery}`)
        }
        let endPoint:any;
        if(this.state.period === "all"){
            endPoint = "/bx_block_profile/documents/teacher_document_list" + `?page=${parseInt(this.state.currentPage) + 1}&type=TeacherAccount&all=true`+url
        }else{
            endPoint = "/bx_block_profile/documents/teacher_document_list" + `?page=${parseInt(this.state.currentPage) + 1}&type=TeacherAccount&date=${this.state.date}`+url
        }
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "school":data.school_id,
            "token":localStorage.getItem("token")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetStudentClasslId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.baseURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({ loadingSpinner: true })
    }
 
    get_classes = () => {
        const school_data = localStorage.getItem("user_data");
        const schoolData = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetClasslId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_scheduling/school_classes/get_uniq_school_classes" + `?school_id=${schoolData.school_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({ loadingSpinner: true })

    }
   
    // Customizable Area End

   
}
