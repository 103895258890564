// Customizable Area Start
import React from "react";
import InputBase from "@material-ui/core/InputBase";
import Grid from '@material-ui/core/Grid';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import './TeacherSubjects.web.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Avatar, Box, CircularProgress, Typography, NativeSelect} from "@material-ui/core";
import {Search, ArrowDropDown} from "@material-ui/icons";
import {ic_clock, subjectIcon, ic_presentation, SUBJECTS_NO_DATA} from './assets';
import { createStyles, withStyles, } from '@material-ui/core/styles';
import TeacherSubjectsController, { Props } from "./TeacherSubjectsController.web";
import SelectedTab from '../SelectedTab.web';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const tabsList = [
    {
        key: 1,
        value: "subject",
        label: "Subject",
    },
    {
        key: 2,
        value: "taskandunit",
        label: "Task",
    },
    {
        key: 3,
        value: "timetable",
        label: "Time Table",
    },
    {
        key: 4,
        value: "attendance",
        label: "Attendance",
    },
    {
        key: 5,
        value: "resources",
        label: "Resources",
    },
    {
        key: 6,
        value: "members",
        label: "Members",
    },
    {
        key: 7,
        value: "gradebook",
        label: "Gradebook",
    },
];
// Customizable Area End
export class TeacherSubjects extends TeacherSubjectsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { teacherSubjects, teacherClass , searchSubjects } = this.state;
       
        return (
            <Box className="teacherSubjectpart" display='flex' flexDirection='column' width="100%">
                <Grid container spacing={2} className="gridSubjects">
                    <Grid item>
                        <Box className="alignStart">
                            <Typography className={`classesButtonText ${this.props.classes.heading_font}`}>
                                Classes
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item className="mrleft">
                        <Box className="selectbox-outer">
                            <NativeSelect
                                id="select"
                                data-test-id="teacher-class-native"
                                value={this.state.class_id ? this.state.class_id : ""}
                                // input={<BootstrapInput />}
                                classes={{
                                    root: this.props.classes.TeacherSubjectSelectRoot,
                                    select: this.props.classes.TeacherSubjectSelectInput,
                                }}
                                disableUnderline
                                IconComponent={ArrowDropDown}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    this.setState({ class_id: event.target.value })
                                    this.get_teacher_class_subjects(event.target.value)
                                }}
                            >
                                <option value="" disabled>Select Class</option>
                                {
                                    teacherClass?.map((getTeacherClass: any) => (
                                        <option value={getTeacherClass.attributes.class.id} >{getTeacherClass.attributes?.class?.display_name}</option>
                                    ))
                                }
                            </NativeSelect>
                        </Box>
                    </Grid>
                </Grid>
                <Box className="mt20">
                    <SelectedTab type={'Subject'} isTeacher={true} />
                </Box>
                <Box className="searchBoxouter">
                    <Box className="searchBoxinner">
                        <Box className={`searchBox ${this.props.classes.border_Color}`}>
                            <input data-test-id="search-item" className="searchSubject"
                                placeholder="Search for subjects…"
                                onChange={((event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleFilterSearchedSubject(event)
                                })}
                            />
                            <Search className={`searchIcon ${this.props.classes.icon_input_color}`}/>
                        </Box>
                        <Box className="bulletBox">
                            <Box display="flex" justifyContent="flex-end">
                                <FormatListBulletedIcon data-test-id="teacherListViewBtn" className={`bulletListIcon ${!this.state.changeview ? this.props.classes.subjects_classAndSection_active_button_color : this.props.classes.subjects_classAndSection_normal_button_color}`}
                                    onClick={() => { this.setState({ changeview: false }) }}
                                />
                            </Box>
                            <Box display="flex" justifyContent="flex-end">
                                <BorderAllIcon data-test-id="teacherGridViewBtn" className={`borderListIcon ${this.state.changeview ? this.props.classes.subjects_classAndSection_active_button_color : this.props.classes.subjects_classAndSection_normal_button_color}`}
                                    onClick={() => { this.setState({ changeview: true }) }}
                                />
                            </Box>
                        </Box>
                    </Box>

                </Box>
                <Box>
                    <Box className={`mtop20 ${this.state.changeview == false ? "block" : "none"}`}>
                        {
                            this.state.query === ''
                                ?
                                teacherSubjects.length > 0
                                    ?
                                    teacherSubjects.map((teacherSubject: any) => (
                                        <Box className="subjectListing">
                                            <Box className="teacherSub">
                                                <Box className="avatarBox">
                                                    <Avatar className="avtarIcon" src={teacherSubject.attributes.image ? teacherSubject.attributes.image.file_path : subjectIcon} variant="rounded" />
                                                </Box>
                                                <Box className="teachersubject-title" display="flex" flexDirection="column">
                                                    <Typography className={`teacherSubjectname ${this.props.classes.title_font}`}>
                                                        {teacherSubject.attributes.subject_name}
                                                    </Typography>
                                                    <Typography className={`subsubjectname ${this.props.classes.subTitle_font}`}>
                                                        ({this.state.className})
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box className="teacherUnits teacherunitsTitle">
                                                <Box position="relative" display="flex" justifyContent="center" alignItems="center">
                                                    <CircularProgress className="circularProgressIcon"
                                                        thickness={4}
                                                        size={70}
                                                        variant="determinate"
                                                        value={47}/>
                                                    <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                                                        <Typography
                                                            variant="caption"
                                                            component="div"
                                                            className={`percentValue ${this.props.classes.bodyText_font}`}>
                                                            47%
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="unitBox" display="flex" alignItems="center">
                                                    <Avatar className="avatar-square" src={ic_presentation} variant="square" />
                                                    <Typography className={`percentValue ${this.props.classes.bodyText_font}`}>
                                                        21 Units
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center">
                                                    <Avatar className="avatar-square" src={ic_clock} variant="square" />
                                                    <Typography className={`percentValue ${this.props.classes.bodyText_font}`}>
                                                        70 Hours
                                                    </Typography>
                                                </Box>
                                            </Box>

                                        </Box>
                                    ))
                                    :
                                    <>
                                        <div className="nodataText">
                                            <img className="nodataIcon" src={SUBJECTS_NO_DATA} alt="No data found" />
                                        </div>
                                        <div className="noRecordfound">
                                            <span className={`${this.props.classes.bodyText_font}`}>
                                                {this.state.subjectSearch === true ? <>No record(s) found.</> : <>Please select the class to see the subject details.</>}
                                            </span>
                                        </div>
                                    </>
                                :
                                searchSubjects.length > 0
                                    ?
                                    searchSubjects.map((teacherSubject: any) => (
                                        <Box className="searchSubjectouter">
                                            <Box className="searchSubjectbox">
                                                <Box className="avatarBox">
                                                    <Avatar className="avtarIcon" src={teacherSubject.attributes.image ? teacherSubject.attributes.image.file_path : subjectIcon} variant="rounded" />
                                                </Box>
                                                <Box display="flex" flexDirection="column" className="teachersubject-title">
                                                <Typography className={`teacherSubjectname ${this.props.classes.title_font}`}>
                                                        {teacherSubject.attributes.subject_name}
                                                    </Typography>
                                                    <Typography className={`subsubjectname ${this.props.classes.subTitle_font}`}>
                                                        ({this.state.className})
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box className="teacherunitsTitle">
                                                <Box position="relative" display="flex" justifyContent="center" alignItems="center"
                                                >
                                                    <CircularProgress
                                                        thickness={4}
                                                        variant="determinate"
                                                        value={47}
                                                        size={58}
                                                    />
                                                    <Box
                                                        top={0}
                                                        left={0}
                                                        bottom={0}
                                                        right={0}
                                                        position="absolute"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <Typography
                                                            variant="caption"
                                                            component="div"
                                                            className={`percentValue ${this.props.classes.bodyText_font}`}>
                                                            47%
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box display="flex" alignItems="center">
                                                    <Avatar src={ic_presentation} variant="square" className="icPresentation" />
                                                    <Typography className={`percentValue ${this.props.classes.bodyText_font}`}>
                                                        21 Units
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center">
                                                    <Avatar src={ic_clock} variant="square" className="icPresentation" />
                                                    <Typography className={`percentValue ${this.props.classes.bodyText_font}`}>
                                                        70 Hours
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))
                                    :
                                    <>
                                        <div className="nodataText">
                                            <img className="nodataIcon" src={SUBJECTS_NO_DATA} alt="No data found" />
                                        </div>
                                        <div className="noRecordfound">
                                            <span className={`${this.props.classes.bodyText_font}`}>
                                                {this.state.subjectSearch === true ? <>No record(s) found.</> : <>Please select the class to see the subject details.</>}
                                            </span>
                                        </div>
                                    </>
                        }
                    </Box>
                    <Box className={`tsOuter ${!this.state.changeview == true ? "none" : "block"}`}>
                        <Grid className="teacherGridView" container spacing={3}>
                            {
                                this.state.query === ''
                                    ?
                                    teacherSubjects.length != 0
                                        ?
                                        teacherSubjects.map((teacherSubject: any) => (
                                            <Grid item xs={4}>
                                                <Box className="subjectListing subjectboxView tSubject">
                                                    <Box className="teacherSub">
                                                        <Box className="avatarBox">
                                                            <Avatar className="avtarIcon" src={teacherSubject.attributes.image ? teacherSubject.attributes.image.file_path : subjectIcon} variant="rounded" />
                                                        </Box>
                                                        <Box className="teachersubject-title" display="flex" flexDirection="column">
                                                        <Typography className={`teacherSubjectname ${this.props.classes.title_font}`}>
                                                        {teacherSubject.attributes.subject_name}
                                                        </Typography>
                                                        <Typography className={`subsubjectname ${this.props.classes.subTitle_font}`}>
                                                            ({this.state.className})
                                                        </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box className="grig-spc widthFull">
                                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                                            <Grid item xs={10}>
                                                                <LinearProgress classes={{
                                                                    root:this.props.classes.TeacherSubjectProgressBarRoot,
                                                                    colorPrimary:this.props.classes.TeacherSubjectProgressBarColorPrimary,
                                                                    bar:this.props.classes.TeacherSubjectProgressBarLinearBar
                                                                }} variant="determinate" value={47} />
                                                            </Grid>
                                                            <Grid item xs={1} >
                                                                <Box>
                                                                <Typography variant="caption" component="div" className={`percentValue ${this.props.classes.bodyText_font}`}>
                                                                    47%
                                                                </Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Box className="subGrid">
                                                        <Box display="flex" alignItems="center">
                                                            <Avatar className="avatar-square" src={ic_presentation} variant="square" />
                                                            <Typography className={`percentValue ${this.props.classes.bodyText_font}`}>
                                                                21 Units
                                                            </Typography>
                                                        </Box>
                                                        <Box display="flex" alignItems="center">
                                                            <Avatar className="avatar-square" src={ic_clock} variant="square" />
                                                            <Typography className={`percentValue ${this.props.classes.bodyText_font}`}>
                                                                70 Hours
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        ))
                                        :
                                        <>
                                            <div className="nodataText">
                                                <img className="nodataIcon" src={SUBJECTS_NO_DATA} alt="No data found" />
                                            </div>
                                            <div className="noRecordfound">
                                                <span className={`${this.props.classes.bodyText_font}`}>
                                                    {this.state.subjectSearch === true ? <>No record(s) found.</> : <>Please select the class to see the subject details.</>}
                                                </span>
                                            </div>
                                        </>
                                    :
                                    searchSubjects.length != 0
                                        ?
                                        searchSubjects.map((teacherSubject: any) => (
                                            <Grid item xs={4}>
                                                <Box className="subjectListing subjectboxView tSubject">
                                                    <Box className="teacherSub">
                                                        <Box className="avatarBox">
                                                            <Avatar className="avtarIcon" src={teacherSubject.attributes.image ? teacherSubject.attributes.image.file_path : subjectIcon} variant="rounded" />
                                                        </Box>
                                                        <Box display="flex" flexDirection="column" className="teachersubject-title">
                                                            <Typography className={`teacherSubjectname ${this.props.classes.title_font}`}>
                                                            {teacherSubject.attributes.subject_name}
                                                            </Typography>
                                                            <Typography className={`subsubjectname ${this.props.classes.subTitle_font}`}>
                                                                ({this.state.className})
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box className="borderLineprogress">
                                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                                            <Grid item xs={10}>
                                                                <LinearProgress classes={{
                                                                    root:this.props.classes.TeacherSubjectProgressBarRoot,
                                                                    colorPrimary:this.props.classes.TeacherSubjectProgressBarColorPrimary,
                                                                    bar:this.props.classes.TeacherSubjectProgressBarLinearBar
                                                                }} variant="determinate" value={47} />
                                                            </Grid>
                                                            <Grid item xs={1} >
                                                                <Box>
                                                                <Typography variant="caption" component="div" className={`percentValue ${this.props.classes.bodyText_font}`}>
                                                                    47%
                                                                </Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                                                        <Box className="unitBox" display="flex" alignItems="center">
                                                            <Avatar className="avatar-square" src={ic_presentation} variant="square" />
                                                            <Typography className={`percentValue ${this.props.classes.bodyText_font}`}>
                                                                21 Units
                                                            </Typography>
                                                        </Box>
                                                        <Box display="flex" alignItems="center">
                                                            <Avatar className="avatar-square" src={ic_clock} variant="square" />
                                                            <Typography className={`percentValue ${this.props.classes.bodyText_font}`}>
                                                                70 Hours
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        ))
                                        :
                                        <>
                                            <div className="nodataText">
                                                <img className="nodataIcon" src={SUBJECTS_NO_DATA} alt="No data found" />
                                            </div>
                                            <div className="noRecordfound">
                                                <span className={`${this.props.classes.bodyText_font}`}>
                                                    {this.state.subjectSearch === true ? <>No record(s) found.</> : <>Please select the class to see the subject details.</>}
                                                </span>
                                            </div>
                                        </>
                            }
                        </Grid>
                    </Box>
                </Box>
            </Box>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(TeacherSubjects);
// Customizable Area End