 // Customizable Area Start
import React from "react";
import { Grid, Modal, Button, Typography, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
 // Customizable Area End
  // Customizable Area Start
interface ICreateQuestion {
  openModal: boolean;
  onCloseModal: any;
  classes?: any;
  postQuestionList: any;
  handleUpdateQuestionInput: (value: any) => void;
  handleCreateQuestionInput: (value: any) => void;
}
 // Customizable Area End
const CreatePopup = ({
  // Customizable Area Start
  openModal,
  onCloseModal,
  classes,
  postQuestionList,
  handleUpdateQuestionInput,
  handleCreateQuestionInput,
  // Customizable Area End
}: ICreateQuestion) => {
   // Customizable Area Start
  return (
    <Modal
      open={openModal}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id="modal-create-feedback-question"
      className="create-feedback-question-modal"
    >
      <Grid
        style={{
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          borderRadius: "15px",
          padding: "30px 30px",
          width: "500px",
        }}
        className="modal-width-90"
      >
        <div className="feedback-form-container">
          <div>
            <div className="titleMain">
              <Typography
                variant="h5"
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                className={`feedback-form-heading + ${classes.title_font}`}
              >
                Create Question
              </Typography>
            </div>
            <div>
              <TextField
                style={{ width: "100%" }}
                id="standard-basic"
                data-test-id={"feedback-create-popup-textfield"}
                placeholder="Enter Question"
                variant="outlined"
                onChange={(e) => {
                  handleUpdateQuestionInput(e.target.value);
                }}
              />
              <Grid
                className="footerBtn"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 40,
                }}
              >
                <Button data-test-id={"feedback-create-popup-cancel-btn"} onClick={onCloseModal} variant="contained">
                  Cancel
                </Button>
                <Button
                  className={classes.button_color}
                  style={{ margin: "0 0 0 10px" }}
                  data-test-id={"feedback-create-popup-create-btn"}
                  variant="contained"
                  onClick={() => {
                    postQuestionList();
                    onCloseModal();
                    handleCreateQuestionInput("");
                  }}
                >
                  Create
                </Button>
              </Grid>
            </div>
          </div>
        </div>
      </Grid>
    </Modal>
  );
   // Customizable Area End
};

   // Customizable Area Start
export default withStyles(themeCreateStyle)(CreatePopup);
// Customizable Area End
