// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { List, ListItem,FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Grid ,Typography} from '@material-ui/core';
import { HISTORY } from "../../../../components/src/comman";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
 
}),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    }
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

function createData(title: string, term: number, term2: number, term3: number) {
  return { title, term, term2, term3 };
}

const rows = [
  createData('Total Attendence of the learner', 20, 10, 5),
  createData('Total Number of working days', 20, 10, 4.0)
];


function ReportCardGradingSystem(props:any) {
  const classes = useStyles();
  const [selectclass, setSelectclass] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date('2014-08-18T21:11:54'),
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectclass(event.target.value as string);
  };
  
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  return (
    <div>
      <div className='profileTitle'>
      <Button className="backBtn" onClick={()=>{HISTORY.goBack()}}><KeyboardBackspaceOutlinedIcon /> Back</Button>
      <h2 className={props.classes.heading_font}>Part 1 - Assessment of Learning </h2>
      <Button className={props.classes.button_color} style={{position: "absolute",right: 0,marginRight: "10px"}}>Enable Edit <EditOutlinedIcon /> </Button>
      </div>
      <div className="tableContent">
          {/*  table 1 start */}
          <Typography className={props.classes.subHeading_font} align="left" style={{ paddingLeft: "40px" }}>Grading scale for scholastic areas: Grades are awarded on a 8-points grading scale as follows </Typography>
            <TableContainer
                style={{ width: "61%", paddingTop: "10px", marginLeft: "40px" }} >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                             <TableCell className={props.classes.bodyText_font} style={{borderRadius:"0px"}}>
                                Mark Range
                            </TableCell>
                            <TableCell className={props.classes.bodyText_font} style={{borderRadius:"0px"}}>
                                Grade
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                             <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                90-100
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                A1
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                81-90
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                A2
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                71-80
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                B1
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                61-70
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                B2
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                51-60
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                C1
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                41-50
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                C2
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                33-40
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                D
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                32 & Below
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                E (Needs improvement)
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            {/* table 1 end */}
            {/*  table 2 start */}
            <Typography className={props.classes.subHeading_font} align="left" style={{ paddingLeft: "40px", paddingTop: "30px" }}>3 Pointing grading scale for Part - 2 & Part - 3</Typography>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={props.classes.bodyText_font} style={{ borderRadius:"0px"}}>
                                Grade
                            </TableCell>
                            <TableCell className={props.classes.bodyText_font} style={{ borderRadius:"0px"}}>
                                Grade Point
                            </TableCell>
                            <TableCell className={props.classes.bodyText_font} style={{ borderRadius:"0px"}}>
                                Grade Achievement
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                A
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                3
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                Outstanding
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                B
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                2
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                Very Good
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                C
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                1
                            </TableCell>
                            <TableCell className={props.classes.reportCardGradingSystem_classAndSection_tabBodyCel} style={{borderRadius:"0px"}}>
                                Fair
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            <Typography className={props.classes.subHeading_font} align="left" style={{ paddingLeft: "40px", paddingTop: "30px",paddingBottom:"30px" }}>Note: The assessment for part -3 discipline should be based on the factors like attendence, sincerity, behaviour, values, tidness, respectfulness for rules and regulations, attitude towards society, attitude nation and others.</Typography>
            {/* table 2 end */}
      </div>
    </div>
  )
}
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(ReportCardGradingSystem as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End