// Customizable Area Start
import React, { useState}from "react";
import Grid from '@material-ui/core/Grid';
import {
    Button,
    Dialog,
    Typography,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import './AdminClassPeriod.web.css'
import { calendar_black, clock } from "../assets";
import moment from 'moment';
import MesDialog from "./MesDialog.web";
import "./ViewClassPeriod.web.css";
import {themeCreateStyle} from '../../../../components/src/CustomThemeStyles.web';
import { withStyles } from '@material-ui/core/styles';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface Props {
    // Customizable Area Start
    viewClassPeriodDialog: boolean;
    handleViewClassPeriodDialogClose: any;
    selectedDataForView: any;
    editClassPeriodClick: any;
    deleteClassPeriodClick: any;
    disableEditButton:boolean;
    classes:any;
    // Customizable Area End
}

export const ViewClassPeriod = ({
    // Customizable Area Start
    viewClassPeriodDialog,
    handleViewClassPeriodDialogClose,
    selectedDataForView,
    editClassPeriodClick,
    deleteClassPeriodClick,
    disableEditButton,
    classes
    // Customizable Area End
}: Props) => {

    // Customizable Area Start
    const duration = moment.duration(moment((moment(selectedDataForView?.attributes?.end_time).format())).diff(moment(selectedDataForView?.attributes?.start_time).format()));
    const hours = duration.asHours();
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
   
    return (
        <>
        <Dialog
            PaperProps={{
                style: { borderRadius: 8 }
            }}
            aria-labelledby="customized-dialog-title"
            maxWidth="md"
            open={viewClassPeriodDialog}
            onClose={() => handleViewClassPeriodDialogClose()}
            closeAfterTransition
            fullWidth={true}
            disableAutoFocus={true}
            className="ViewClassPeriodDialogBox"
        >
            <div className="InnerBox">
                <Grid container >
                    <Grid item xs={12} className="FlexBetween">
                        <Typography className={`DataTypo ${classes.theme_font}`}>
                            {selectedDataForView?.attributes?.periods[1]?.data?.attributes?.name} 
                        </Typography>
                        <CloseIcon
                            className="CloseIcon"
                            onClick={() => handleViewClassPeriodDialogClose()}
                        />
                    </Grid>
                </Grid>

                <div
                    className="ContentBox"
                >
                    <div
                        className="ContentInnerBox"
                    >
                        <Typography
                            className={`ContentTypo ${classes.theme_font}`}
                        >
                           {selectedDataForView?.attributes?.periods[1]?.data?.attributes?.display_name}
                        </Typography>
                        <Typography
                            className={`ContentTypo ${classes.theme_font}`}
                        >
                            {selectedDataForView?.attributes?.batch?.data?.attributes?.name} 
                        </Typography>
                    </div>
                    <div
                        className="FlexCenter"
                    >
                        <Typography
                            className={`ContentTypo ${classes.theme_font}`}
                        >
                            {selectedDataForView?.attributes?.periods[1]?.data?.attributes?.subject?.data?.attributes?.subject_name}
                        </Typography>
                        <Typography
                            className={`ContentTypo2 ${classes.theme_font}`}
                        >
                            {selectedDataForView?.attributes?.periods[1]?.data?.attributes?.teacher?.first_name} {selectedDataForView?.attributes?.periods[1]?.data?.attributes?.teacher?.last_name}  
                        </Typography>
                    </div>
                </div>

                <div
                    className="DateTimeContent"
                >
                    <img
                        className="Img"
                        src={clock}
                    />
                    <Typography
                        className={`DateTimeContentTypo ${classes.theme_font}`}
                    >
                        <span className="Fw600">Duration: </span>
                        {hours.toFixed(2)} hour from {moment(selectedDataForView?.attributes?.start_time).format('hh:mm A')} -
                            {moment(selectedDataForView?.attributes?.end_time).format('hh:mm A')}
                    </Typography>
                </div>

                <div
                    className="DateTimeContent"
                >
                    <img
                        className="Img"
                        src={calendar_black}
                    />
                    <Typography
                        className={`DateTimeContentTypo ${classes.theme_font}`}
                    >
                        <span className="Fw600">Start Date & Time: </span>
                        {moment(selectedDataForView?.attributes?.periods[1]?.data?.attributes?.start_date).format("dddd, MMMM DD YYYY hh:mm A")}
                    </Typography>
                </div>

                <div className="ButtonContainer">
                    <Button
                    disabled={disableEditButton}
                        style={{
                            opacity:disableEditButton?"0.6":"1",
                        }}
                        className="BtnStyle"
                        onClick={(e: any) => { editClassPeriodClick() }}
                    >
                        <Typography
                            className={`BtnTypo ${classes.theme_font}`}
                        >
                            Edit
                        </Typography>
                    </Button>
                    <Button
                        className="BtnStyle Ml2"
                        onClick={(e: any) => { setDeleteDialog(true) }}
                    >
                        <Typography
                            className={`BtnTypo ${classes.theme_font}`}
                        >
                            Delete
                        </Typography>
                    </Button>
                </div>
            </div>
        </Dialog>
        {deleteDialog && <MesDialog
                    title="Create TimeTable"
                    deleteModal={deleteDialog}
                    onCloseModal={() => {
                        setDeleteDialog(false);
                    }}
                    confirmMes="Are you sure you want to delete this lecture?"
                    description="If this is recursive period, all periods will be deleted."
                    cancelButtonText="Cancel"
                    okButtonText="Yes"
                    deleteClick={(e: any) => { deleteClassPeriodClick() 
                        setDeleteDialog(false) }}
                    />
                }
        </>
    );
    // Customizable Area End
};

// Customizable Area Start
export default withStyles(themeCreateStyle)(ViewClassPeriod);
// Customizable Area End
