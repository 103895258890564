// Customizable Area Start
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
import { encryptData } from '../../ClassesAndSections/src/utils/common';

import moment from 'moment';

export const configJSON = require('./config.js');
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  editModalFun: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  data: any;
  teacherupcomingClasses: any;
  teacherClasses: any;
  teacherNotes: any;
  totalClasses: string;
  teacherSubjects: any;
  teacherAssegments: any;
  teacherofStudents: string;
  createnotediv: boolean;
  subject_id: number;
  class_id: any;
  selectedClass: any;
  selectedUpcomingSubject: string;
  title: string;
  calendarDate: any;
  description: string;
  liveClasses: any;
  teacherTimetable: any;
  boxHeight: boolean;
  liveClassDate: string;
  tablecallId: any;
  dateoftoday: string;
  allNotesModal: boolean;
  allEventsModal: boolean;
  anchorEl: any;
  createNotes: boolean;
  notes_id: any;
  editNoteModal: boolean;
  logoutModal: boolean;
  class_name: string;
  classData: any;
  response_data: any;
  gradeName: string;
  gradeData: any;
  divisionName: string;
  dinvisionData: any;
  reminders: any;
  schoolEvents: any;
  selectedEvent?: any;
  mainReminder: null | any;
  mainReminderOpen: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetTeacherClassesId: string = '';
  apigetTeacherSubjectsId: string = '';
  apigetTeacherGradeId: string = '';
  apigetTeacherDivisionId: string = '';
  apigetTeacherSubjectByGradeAndDivisionId: string = '';
  apigetReminderId: string = '';
  apigetSchholEventsId: string = '';
  apigetTeacherAssignmnetId: string = '';
  apigetTeacherofStudentId: string = '';
  apigetTeacherNotesId: string = '';
  apicreateTeacherNotesId: string = '';
  apigetupcomingClassesId: string = '';
  apigettimeTableId: string = '';
  apigetliveClassesId: string = '';
  apiMakeTeacherLiveClassHost: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      boxHeight: false,
      teacherNotes: [],
      teacherTimetable: [],
      tablecallId: [],
      subject_id: 1,
      class_id: 0,
      selectedClass: '',
      data: '',
      liveClassDate: '',
      selectedUpcomingSubject: '',
      totalClasses: '',
      teacherSubjects: [],
      teacherClasses: [],
      teacherupcomingClasses: [],
      liveClasses: [],
      teacherAssegments: {},
      teacherofStudents: '',
      createnotediv: false,
      description: '',
      title: '',
      calendarDate: new Date(),
      dateoftoday: '',
      anchorEl: null,
      allNotesModal: false,
      allEventsModal: false,
      createNotes: false,
      notes_id: [],
      editNoteModal: false,
      logoutModal: false,
      class_name: '',
      classData: [],
      response_data: [],
      gradeName: '',
      gradeData: [],
      divisionName: '',
      dinvisionData: [],
      reminders: [],
      schoolEvents: [],
      mainReminder: null,
      mainReminderOpen: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.get_userdata = this.get_userdata.bind(this);
    this.get_teacher_classes = this.get_teacher_classes.bind(this);
    // this.get_teacher_subjects = this.get_teacher_subjects.bind(this);
    this.get_teacher_assessment = this.get_teacher_assessment.bind(this);
    this.get_teacher_ofstudents = this.get_teacher_ofstudents.bind(this);
    this.get_teacher_notes = this.get_teacher_notes.bind(this);
    this.get_upcoming_classes = this.get_upcoming_classes.bind(this);
    this.get_timetable = this.get_timetable.bind(this);
    this.get_live_classes = this.get_live_classes.bind(this);
    this.get_reminder = this.get_reminder.bind(this);
    this.get_school_events = this.get_school_events.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  modalhandleClose = () => {
    this.setState({ mainReminderOpen: false });
  };
  // Customizable Area End
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const zoomMeetingBlock = document.getElementById('zmmtg-root');
    if (zoomMeetingBlock) zoomMeetingBlock.style.display = 'none';
    this.get_teacher_grade();
    // this.get_teacher_subjects();
    this.get_teacher_assessment();
    // this.get_teacher_ofstudents();
    this.get_teacher_notes();
    this.get_userdata();
    this.get_teacher_classes();
    this.get_reminder();
    this.get_school_events();
    // this.get_timetable();
    // Customizable Area End
  }

  // Customizable Area Start
  get_userdata = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}');
    this.setState({ data: data });
  };

  onCloseModal = (closeModal: boolean) => {
    this.setState({
      editNoteModal: closeModal,
      allNotesModal: closeModal,
      createNotes: closeModal,
    });
    this.get_teacher_notes();
  };
  openInNewTab = (url: any) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  onlogoutCloseModal = (closeModal: boolean) => {
    this.setState({ logoutModal: closeModal });
    if (
      localStorage.getItem('user_data') === null &&
      localStorage.getItem('token') === null
    ) {
      this.props.navigation.navigate('EmailAccountLoginBlock');
    }
  };
  handleGetStartAndEndTime = (startDate: any) => {
    const dateObj = new Date(startDate);
    // const hours = startDate.getHours(); //index of month
    // const min = startDate.getMinutes();
    const time = dateObj.getTime();
    // const year = dateObj.getFullYear();
    const formattedTime = moment(time, ['h:mm A']).format('HH:mm');
    const formattedTimeHours = parseInt(formattedTime.split(':')[0]);
    const formattedTimeMins = parseInt(formattedTime.split(':')[1]);
    const finaltime =
      formattedTimeHours > 12
        ? `${formattedTimeHours - 12}:${formattedTimeMins}PM`
        : `${formattedTime}AM`;
    return moment(startDate).format('h:mm A');
  };
  onCloseEventsModal = () => {
    this.setState({ allEventsModal: !this.state.allEventsModal });
    this.get_school_events();
  };
  handleOpenZoomMeeting = (event: any) => {
    const userData = localStorage.getItem('user_data');
    const parsedUserData = JSON.parse(userData || '{}');
    if (parsedUserData.meeting_paid_account) {
      const { api_key, api_secret, id } = event;
      const role = 1;
      const meetingDetails = {
        api_key: encryptData(api_key, 'meeting-api-key'),
        api_secret: encryptData(api_secret, 'meeting-api-secret'),
        id: encryptData(id, 'meeting-id'),
        role: encryptData(role.toString(), 'meeting-role'),
      };
      // event.meetingDetails = meetingDetails
      const meetingParameters = {
        ...event,
        meetingDetails: meetingDetails,
      };
      localStorage.setItem('meeting-detail', JSON.stringify(meetingParameters));
      localStorage.setItem('role', 'student');
      const meetingDetail = localStorage.getItem('meeting-detail');
      if (meetingDetail) {
        const currentUrl = window.location.origin;
        const redirectUrl = `${currentUrl}/Meeting`;
        window.open(redirectUrl, '_blank');
      }
    } else {
      window.open(event.link, '_blank');
    }
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apigetTeacherClassesId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              // totalClasses: responseJson.total_count,
              selectedClass: responseJson.data[0].id,
              teacherClasses: responseJson.data,
            });
            this.get_upcoming_classes(responseJson.data[0].id);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigetTeacherSubjectsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              response_data: responseJson.data,
              classData: responseJson.data,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigetTeacherAssignmnetId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ teacherAssegments: responseJson.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigetTeacherofStudentId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ teacherofStudents: responseJson.total_students });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigetTeacherNotesId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              teacherNotes: responseJson.data,
              createnotediv: false,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apicreateTeacherNotesId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.get_teacher_notes();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigetupcomingClassesId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ teacherupcomingClasses: responseJson });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigetliveClassesId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ liveClasses: responseJson.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigettimeTableId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ teacherTimetable: responseJson });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigetTeacherGradeId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              response_data: responseJson.data,
              gradeData: responseJson.data,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigetTeacherDivisionId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              response_data: responseJson.data,
              dinvisionData: responseJson.data,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (
        apiRequestCallId === this.apigetTeacherSubjectByGradeAndDivisionId
      ) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              response_data: responseJson.data,
              teacherSubjects: responseJson.data,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigetReminderId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              // response_data: responseJson.data,
              reminders: responseJson.data,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigetSchholEventsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              // response_data: responseJson.data,
              schoolEvents: responseJson.data,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiMakeTeacherLiveClassHost) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.handleOpenZoomMeeting(this.state.selectedEvent);
          } else {
            this.parseApiErrorResponse(responseJson);
            // toast.error("Something went wrong!")
          }
        }
        //this.setState({ loadingSpinner: false })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  get_teacher_grade = () => {
    const token = localStorage.getItem('token');
    const school_data = localStorage.getItem('user_data');
    const data = JSON.parse(school_data || '{}');
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token,
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherGradeId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherGradeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_teacher_division = (value: any) => {
    this.setState({ gradeName: value });
    const token = localStorage.getItem('token');
    const school_data = localStorage.getItem('user_data');
    const data = JSON.parse(school_data || '{}');
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token,
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherDivisionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherDivisionEndPoint + `?grade_id=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  get_teacher_classes = () => {
    const token = localStorage.getItem('token');
    const school_data = localStorage.getItem('user_data');
    const data = JSON.parse(school_data || '{}');
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token,
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherClassesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherClassesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  get_teacher_subjectbygradeAndDivision = (value: any) => {
    this.setState({ divisionName: value });
    const token = localStorage.getItem('token');
    const school_data = localStorage.getItem('user_data');
    const data = JSON.parse(school_data || '{}');
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token,
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherSubjectByGradeAndDivisionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherSubjectByGradeAndDivisionEndPoint +
        `?grade_id=${this.state.gradeName}&division_id=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  get_reminder = () => {
    const token = localStorage.getItem('token');
    const school_data = localStorage.getItem('user_data');
    const data = JSON.parse(school_data || '{}');
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token,
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetReminderId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReminderEndPoint + `?school_id=${data.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  get_school_events = () => {
    const token = localStorage.getItem('token');
    const school_data = localStorage.getItem('user_data');
    const data = JSON.parse(school_data || '{}');
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token,
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetSchholEventsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolEventEndPoint +
        `?school_id=${data.school_id}&date=${moment(
          this.state.calendarDate
        ).format('DD/MM/YYYY')}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  get_teacher_subjects = () => {
    const token = localStorage.getItem('token');
    const school_data = localStorage.getItem('user_data');
    const data = JSON.parse(school_data || '{}');
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token,
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherSubjectsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherSubjectEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_teacher_assessment = () => {
    const token = localStorage.getItem('token');
    const school_data = localStorage.getItem('user_data');
    const data = JSON.parse(school_data || '{}');
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token,
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherAssignmnetId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherAssignment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_teacher_ofstudents = () => {
    const school_data = localStorage.getItem('user_data');
    const data = JSON.parse(school_data || '{}');
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherofStudentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.numberofStudents
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.dashboardURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_teacher_notes = () => {
    const school_data = localStorage.getItem('user_data');
    const data = JSON.parse(school_data || '{}');
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherNotesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherCreateNotes
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addNote = () => {
    const { title, description } = this.state;
    const data = {
      title: title,
      description: description,
    };

    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apicreateTeacherNotesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherCreateNotes
    );
    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarPOSTMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_upcoming_classes = (id: any) => {
    this.setState({ selectedClass: id });
    const token = localStorage.getItem('token');
    const school_data = localStorage.getItem('user_data');
    const data = JSON.parse(school_data || '{}');
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token,
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetupcomingClassesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherUpcomingClassesEndPoint + `?school_class_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_live_classes = () => {
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetliveClassesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherLiveclasses +
        `?date=${this.state.liveClassDate}&class_id=${this.state.class_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.dashboardURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_timetable = () => {
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigettimeTableId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getteacherTimeTable +
        `?date=${this.state.calendarDate.toLocaleDateString('en-US', {
          year: 'numeric',
        }) +
          '-' +
          this.state.calendarDate.toLocaleDateString('en-US', {
            month: 'numeric',
          }) +
          '-' +
          this.state.calendarDate.toLocaleDateString('en-US', {
            day: 'numeric',
          })}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.dashboardURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  makeTeacherLiveClassHost = () => {
    //this.setState({ loadingSpinner: true })
    const school_data = localStorage.getItem('user_data');
    const data = JSON.parse(school_data || '{}');
    const header = {
      'Content-Type': configJSON.apiContentType,
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiMakeTeacherLiveClassHost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherLiveClassesMakeHostEndPoint +
        `?school_id=${data.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarPUTMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
