// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import { checkFieldLengthForNumber } from "../../../profiles/src/utils/common";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import AudioList from "../AddQuestion/AudioList.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
interface Props {
    // Customizable Area Start
    questionData: any;
    isTeacher: boolean;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
const form_input_text_field = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
    fontSize: "20px",
    fontWeight: 600,
} as const;
// Customizable Area End

// Customizable Area Start
export const AudioQuestionType = ({
    questionData,
    isTeacher,
    classes,
}: Props) => {
    const [audioList, setAudioList] = useState<any[]>([]);
    const [studentAudioList, setStudentAudioList] = useState<any[]>([]);
    const [marks, setMarks] = useState<any>(questionData?.selected_options?.data?.attributes?.score)

    useEffect(() => {
        let uploadedAudioList: any = [];
        questionData?.attachements?.data.map(
            (item: any, index: number) => {
                const audioItem = {
                    id: uuidv4(),
                    upload_id: item?.id,
                    file_name: item?.attributes?.file_name,
                    file_size: item?.attributes?.file_size,
                    file_type: item?.attributes?.content_type,
                    file_url: item?.attributes?.url,
                    moduleId: item?.attributes?.module_id,
                    duration: item?.attributes?.duration && moment(item?.attributes?.duration, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds')
                };
                uploadedAudioList = [...uploadedAudioList, audioItem];
            }
        );
        setAudioList(uploadedAudioList)

        let uploadedStudentAudioList: any = [];
        questionData?.selected_options?.data?.attributes?.attachments?.data?.map(
            (item: any) => {
                const audioItem = {
                    id: uuidv4(),
                    upload_id: item?.id,
                    file_name: item?.attributes?.file_name,
                    file_size: item?.attributes?.file_size,
                    file_type: item?.attributes?.content_type,
                    file_url: item?.attributes?.url,
                    moduleId: item?.attributes?.module_id,
                    duration: item?.attributes?.duration && moment(item?.attributes?.duration, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds')
                };
                uploadedStudentAudioList = [...uploadedStudentAudioList, audioItem];
            }
        );
        setStudentAudioList(uploadedStudentAudioList)
        if(!questionData?.selected_options?.data) {
            setMarks(0);
        }
    }, []);

    const setAudioDuration = (index: number, durationInSecond: number) => {
        let tempAudioList = audioList;
        tempAudioList[index].duration = durationInSecond;
        setAudioList(tempAudioList);
    }

    const setStudentAudioDuration = (index: number, durationInSecond: number) => {
        let tempAudioList = studentAudioList;
        tempAudioList[index].duration = durationInSecond;
        setStudentAudioList(tempAudioList);
    }

    return (
        <>
            <div className="evaluation_details_section pt-1">
                {audioList.length > 0 && audioList.map((audio: any, index: number) => {
                    return (
                        <AudioList
                            key={audio.id}
                            audio={audio}
                            showActions={true}
                            isStudent={false}
                            setAudioDuration={(durationInSecond: number) =>
                                setAudioDuration(index, durationInSecond)
                            }
                            fromEvaluation={true}
                        />
                    )
                })
                }
            </div>
            <Grid container>
                <Typography className={`ans_text ${classes.heading_font}`}>
                    Ans :
                </Typography>
                <div className="long_answer_rectangle">
                    <Typography className={`long_answer_text ${classes.subHeading_font}`}>{questionData?.selected_options?.data?.attributes?.answer_details}</Typography>
                    {studentAudioList.length > 0 &&
                        <div className="evaluation_details_section pt-1">
                            {studentAudioList?.map((audio: any, index: number) => {
                                return (
                                    <AudioList
                                        key={audio.id}
                                        audio={audio}
                                        showActions={true}
                                        isStudent={false}
                                        setAudioDuration={(durationInSecond: number) =>
                                            setStudentAudioDuration(index, durationInSecond)
                                        }
                                        fromEvaluation={true}
                                    />
                                )
                            })
                            }
                        </div>
                    }
                </div>

                <div className="correct_answer_section">
                    <Grid container>
                        <Grid item xs={12} sm={6} className="correct_answer_left">
                            {!questionData?.selected_options?.data &&
                                <Typography className={`not_attemped_text ${classes.bodyText_font}`}>Not attempted</Typography>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} className="score_section">
                            <Typography className={`correct_answer_text ${classes.heading_font}`}>Score</Typography>
                            <div className="correct_answer_rectangle">
                                {isTeacher ?
                                    <TextField
                                        placeholder=""
                                        type="number"
                                        value={marks}
                                        style={form_input_text_field}
                                        className={classes.input_theme_style}
                                        inputProps={{ className: "score_text" }}
                                        disabled={!questionData?.selected_options?.data}
                                        onChange={(e: any) => {
                                            if (Number(e.target.value) > Number(questionData?.marks)) {
                                                toast.error('Obtained marks should not greater than total marks')
                                            }
                                            setMarks(e.target.value)
                                            questionData.selected_options.data.attributes.score = e.target.value
                                        }}
                                        onKeyPress={(event: any) => checkFieldLengthForNumber(event, 3)}
                                    />
                                    :
                                    <Typography className={`score_text ${classes.bodyText_font}`}>
                                        {questionData?.selected_options?.data?.attributes?.score ? questionData?.selected_options?.data?.attributes?.score : 0}
                                    </Typography>
                                }
                            </div>
                            <Typography className={`score_text ${classes.bodyText_font}`}> / {questionData?.marks}</Typography>
                        </Grid>
                        {(!isTeacher && questionData?.selected_options?.data?.attributes?.comments) &&
                            <Grid item xs={12}>
                                <Typography className={`title_teachercomment ${classes.bodyText_font}`}>Teacher Comment:
                                    <span className={`textlabel_teachercomment ${classes.heading_font}`} dangerouslySetInnerHTML={{
                                        __html: questionData?.selected_options?.data?.attributes?.comments,
                                    }}></span></Typography>
                            </Grid>
                        }
                    </Grid>
                </div>
            </Grid>
        </>
    );
};
// Customizable Area End

// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(AudioQuestionType);
// Customizable Area End