// Customizable Area Start
import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Box,
  Button,
  InputBase,
  TextField,
  Typography,
  NativeSelect,
} from "@material-ui/core";

import {
  ArrowDropDown,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import EditClassController, { Props } from "./EditClassController.web";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FormHelperText from "@material-ui/core/FormHelperText";
import CommanSelectDiv from "./CommanSelectDiv.web";
import DivisionSelectDiv from "./DivisionSelectDiv.web";
import Spinner from "../../shared/SideBar/src/Spinner";
import './EditClass.web.css';
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class EditClass extends EditClassController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const BootstrapInput5 = withStyles((theme) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: "relative",
        border: this.state.gradeerror ? "solid 1px red" : "solid 1px #af38cb",
        backgroundColor: "#fff",
        padding: "18px 22px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: "Open sans",
        color: "rgb(63, 82, 109,0.32)",
        // fontWeight: 600,
        fontSize: 20,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);
    const { isLoader } = this.state
    return (
      <>
        <Spinner spinnerModal={isLoader} />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="edit_class_modal_container"
          open={this.props.EditClassModal}
          onClose={this.modalhandleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={this.props.EditClassModal} style={{ border: "none" }}>
            <div className="edit_class_popup">
              <Typography
                className="edit_class_modal_head_title"
              >
                Edit Class
              </Typography>
              <Box
                className="edit_class_form_box"
              >
                <Box className="edit_class_mt_10">
                  <Typography
                    className="edit_class_modal_head_title"
                  >
                    Subject
                  </Typography>
                  <Box
                    className="edit_class_cursor_pointer"
                    onClick={(event: any) => {
                      this.setState({
                        selectDropDownDiv: true,
                        dropDownAnchorEl: event.currentTarget,
                        data_Type: "subject",
                      });
                    }}
                  >
                    <Box
                      className="edit_class_select_options_inner_box"
                      style={{
                        border: this.state.subjectError
                          ? "0.5px solid red"
                          : "0.5px solid #af38cb",
                      }}
                    >
                      {this.state.subject_data.length != 0 ? (
                        <Box className="edit_class_flex_box_row">
                          {this.state.subject_data.map(
                            (data: any, index: number) => (
                              <Box>
                                <Typography
                                  className="edit_class_input_text"
                                >
                                  {index ===
                                    this.state.subject_data.length - 1 ? (
                                    <>{data.subject_name}</>
                                  ) : (
                                    <>{data.subject_name},&nbsp;</>
                                  )}
                                </Typography>
                              </Box>
                            )
                          )}
                        </Box>
                      ) : (
                        <Typography
                          className="edit_class_text"
                        >
                          Select Subject
                        </Typography>
                      )}
                      <Box className="edit_class_flex_box">
                        <KeyboardArrowDownIcon />
                      </Box>
                    </Box>
                  </Box>
                  {this.state.subjectError && (
                    <FormHelperText className="edit_class_error_text">
                      Please select subject.
                    </FormHelperText>
                  )}
                </Box>
                {this.state.selectDropDownDiv && (
                  <Box className="edit_class_full_width">
                    <CommanSelectDiv
                      dropDownAnchorEl={this.state.dropDownAnchorEl}
                      closeModalFun={this.dropdownClose}
                      datasaveModalFun={this.dataSaveClose}
                      data_Type={this.state.data_Type}
                      subjectData={this.state.subject_data}
                      editData={true}
                    />
                  </Box>
                )}
                <Box className="edit_class_mt_10">
                  <Typography
                    className="edit_class_modal_head_title"
                  >
                    Optional Subject
                  </Typography>
                  <Box
                    className="edit_class_cursor_pointer"
                    onClick={(event: any) => {
                      this.setState({
                        optionalDropDownDiv: true,
                        optionaldropDownAnchorEl: event.currentTarget,
                        data_Type: "optional_subject",
                      });
                    }}
                  >
                    <Box
                      className="edit_class_select_options_inner_box"
                      style={{
                        border: this.state.subjectError
                          ? "0.5px solid red"
                          : "0.5px solid #af38cb",
                      }}
                    >
                      {this.state.optional_data.length != 0 ? (
                        <Box className="edit_class_flex_box_row">
                          {this.state.optional_data.map(
                            (data: any, index: number) => (
                              <Box>
                                <Typography
                                  className="edit_class_input_text"
                                >
                                  {index ===
                                    this.state.optional_data.length - 1 ? (
                                    <>{data.subject_name}</>
                                  ) : (
                                    <>{data.subject_name},&nbsp;</>
                                  )}
                                </Typography>
                              </Box>
                            )
                          )}
                        </Box>
                      ) : (
                        <Typography
                         className="edit_class_text"
                        >
                          Select Optional Subject
                        </Typography>
                      )}
                      <Box className="edit_class_flex_box">
                        <KeyboardArrowDownIcon />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {this.state.optionalDropDownDiv && (
                  <Box className="edit_class_full_width">
                    <CommanSelectDiv
                      dropDownAnchorEl={this.state.optionaldropDownAnchorEl}
                      closeModalFun={this.dropdownClose}
                      datasaveModalFun={this.dataSaveClose}
                      data_Type={this.state.data_Type}
                      subjectData={this.state.optional_data}
                      editData={true}
                    />
                  </Box>
                )}
                <Box className="edit_class_mt_10">
                  <Typography
                    className="edit_class_modal_head_title"
                  >
                    Name
                  </Typography>
                  <TextField
                    id="standard-basic"
                    type="text"
                    value={this.state.className}
                    autoComplete="off"
                    className="edit_class_textfield"
                    variant="outlined"
                    inputProps={{
                      style: {
                        fontFamily: "Open sans",
                        fontSize: 15.8,
                        color: "#03014c",
                      },
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value.trim();
                      if (value.length === 0) {
                        this.setState({
                          nameError: true,
                          nameErrorText: "Name is required.",
                        });
                      } else {
                        this.setState({
                          nameError: false,
                          nameErrorText: "",
                        });
                      }
                      this.setState({ className: event.target.value });
                    }}
                    onBlur={() => {
                      if (
                        this.state.className === null ||
                        this.state.className.length === 0
                      ) {
                        this.setState({
                          nameError: true,
                          nameErrorText: "Name is required.",
                        });
                      } else {
                        this.setState({
                          nameError: false,
                          nameErrorText: "",
                        });
                      }
                    }}
                    error={this.state.nameError}
                  />
                  <FormHelperText className="edit_class_error_text">
                    {this.state.nameErrorText}
                  </FormHelperText>
                </Box>
                <Box className="edit_class_mt_10">
                  <Typography className="edit_class_modal_head_title">
                    Grade
                  </Typography>
                  <NativeSelect
                    id="select"
                    value={this.state.grade_id}
                    input={<BootstrapInput5 />}
                    className="edit_class_native_select"
                    IconComponent={ArrowDropDown}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      this.setState({
                        grade_id: event.target.value,
                        gradeerror: false,
                      });
                    }}
                    onBlur={() => {
                      if (
                        this.state.grade_id === null ||
                        this.state.grade_id.length === 0
                      ) {
                        this.setState({ gradeerror: true });
                      } else if (this.state.grade_id.length > 0) {
                        this.setState({ gradeerror: false });
                      } else {
                        this.setState({ gradeerror: false });
                      }
                    }}
                  >
                    <option disabled value="" className="edit_class_opacity">
                      Select Grade
                    </option>
                    {this.state.gradeData.map((data: any) => (
                      <>
                        <option disabled value={data.id}>
                          {data.attributes.school_grade_name}
                        </option>
                      </>
                    ))}
                  </NativeSelect>
                  {this.state.gradeerror && (
                    <FormHelperText
                      className="edit_class_error_text"
                    >
                      Please select grade.
                    </FormHelperText>
                  )}
                </Box>
                <Box className="edit_class_mt_10">
                  <Typography className="edit_class_modal_head_title">
                    Division
                  </Typography>
                  <TextField
                    id="standard-basic"
                    type="text"
                    disabled
                    value={this.props.editData?.attributes?.divisions.name}
                    autoComplete="off"
                    className="edit_class_textfield"
                    variant="outlined"
                    inputProps={{
                      style: {
                        fontFamily: "Open sans",
                        fontSize: 15.8,
                        color: "#03014c",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                className="edit_class_action_btn_container"
              >
                <Button
                  className="edit_class_save_btn"
                  onClick={this.add_Class}
                >
                  Save
                </Button>
                <Button
                  className="edit_class_cancel_btn"
                  onClick={this.modalhandleClose}
                >
                  Cancel
                </Button>
              </Box>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

export default EditClass as React.ComponentType<any>;

// Customizable Area End
