// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes?: any;
    intialPage?: number;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoader: boolean;
    // Customizable Area End
}
interface SS { 
  // Customizable Area Start
  // Customizable Area End
}

export default class StudentPaginationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];        
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            isLoader: false,
        };
        // Customizable Area End
    }

    // Customizable Area Start
    onTabchangeClick = (page: number) => { 
        switch(page) {
            case 0:
                HISTORY.push({
                    pathname: "/StudentReportCard",
                  });
            break;
            case 1:
                HISTORY.push({
                    pathname: "/StudentScholasticAreas",
                  });
            break;
            case 2:
                HISTORY.push({
                    pathname: "/StudentCoScholasticAreas",
                  });
            break;
            case 3:
                HISTORY.push({
                    pathname: "/StudentGradingDetails",
                  });
            break;
        }
      };
    // Customizable Area End
}
