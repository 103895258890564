// Customizable Area Start
import React from "react";
import {
    StyleSheet,
    Platform,
} from "react-native";
import Picker from "emoji-picker-react";
import Grid from '@material-ui/core/Grid';
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    IconButton,
    Link,
    MenuItem,
    Select,
    NativeSelect,
    TextField,
    Typography,
    
} from "@material-ui/core";
// import {
//     Search,
//     Replay,
//     NotificationsNone,
//     HeadsetMicOutlined,
//     Title,
//     TitleOutlined,
//     KeyboardArrowLeft,
//     EditOutlined,
//     MoreVertOutlined,
//     KeyboardArrowDown,
//     DesktopAccessDisabledOutlined,
//     ArrowDropDown
// } from "@material-ui/icons";
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import DeleteModalController, { Props } from "./DeleteModalController.web";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import { truncateSync } from "fs";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FileDrop } from 'react-file-drop';
// Customizable Area End

// Customizable Area Start
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
// Customizable Area End
export default class DeleteModal extends DeleteModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const deleteMes1 = {
            fontFamily: "Open sans",
            fontSize: "1.5rem",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#3f526d",
            margin: '31px 32px 6px 33px',

        } as const;

        const deleteDiv = {
            fontFamily: "Open sans",
            fontSize: "1.5rem",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#3f526d",
            marginBottom: '14px',

        } as const;
        const deleteMes2 = {
            fontFamily: "Open sans",
            fontSize: "0.875rem",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#959393",
            margin: '6px 53px 40px 54px',

        } as const;
        const cancelButton = {
            margin: '0 20px 0 0',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',
            backgroundImage: 'linear-gradient(to top, #af38cb 101%, #471c51 33%)',
            color: 'white'

        } as const;
        const deleteButton = {
            margin: '0 0px 0 20px',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',
            backgroundColor: '#f6f6f6',
        } as const;
        const cancelText = {
            fontFamily: "Open sans",
            fontSize: "0.875rem",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center',
            color: "#fff",

        } as const;
        const deleteText = {
            fontFamily: "Open sans",
            fontSize: "0.875rem",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center'

        } as const;
        
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    open={this.props.RemoveModal}
                    onClose={this.modalhandleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableAutoFocus={true}
                >
                    <Fade in={this.props.RemoveModal} style={{ border: "none" }}>
                        <Box
                            style={{
                                justifyContent: "center",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'white',

                                borderRadius: "29px",
                                padding: "32px 18px",
                                // width: 800,
                                width: "576px",

                            }}
                        >
                            <div style={deleteDiv}>Remove Item</div>
                            <div style={{
                                height: "1px",
                                margin: "14px 0 31px 0",
                                border: "solid 0.3px #979797"
                            }}>
                            </div>
                            <div style={deleteMes1}>Are you sure you want to Delete?</div>
                            <div style={{
                                //  width: "389px",
                                //  margin: 6px 123px 44px 115px;
                                 fontFamily: "Open Sans",
                                 fontSize: "14px",
                                 fontWeight: 500,
                                 textAlign: "center",
                                 color:" #959393"
                            }}>this item will be deleted immediately. You cannot undo the action</div>
                            <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
                                <Button variant="contained" style={cancelButton} 
                                onClick={()=>this.modalhandleClose("cancle")}
                                >
                                    <span style={cancelText}>Cancel</span></Button>
                                <Button variant="contained" style={deleteButton} 
                                
                                onClick={()=>this.modalhandleClose("delete")}
                                ><span style={deleteText}>Delete</span></Button>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </>
        )
    }
    // Customizable Area End
}
