// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Checkbox,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import McqAnswerTypeQuestionController, {
  Props,
} from "./McqAnswerTypeQuestionController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import {
  imageMarksMinusSVG,
  imageMarksPlusSVG,
  imgBackArrowSVG,
  imgPlusIconSVG,
} from "../assets";
import QuestionMetaData from "../Components/QuestionMetaData.web";
import { FileDrop } from "react-file-drop";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import "../TeacherAssessment.web.css";
import QuestionSolution from "../Components/QuestionSolution.web";
import SelectImageModal from "./SelectImageModal.web";
import AlertModal from "../../../alert/src/AlertModal.web";
import { HISTORY } from "../../../../components/src/comman";
import { ImagePreivewModal } from "../Components/ImagePreivewModal.web";
import ChooseFromGalleryModal from "./ChooseFromGalleryModal.web";
import TextEditor from "./TextEditor/TextEditor.web";
import QuestionSidebarCollapse from "./QuestionSidebarCollapse.web";
import QuestionPreviewModal from '../Components/QuestionPreviewModal.web';
import VisibilityIcon from '@material-ui/icons/Visibility';
import './AddQuestion.web.css';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import McqOption from "./McqOption.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class McqAnswerTypeQuestion extends McqAnswerTypeQuestionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const {
      marks,
      negativeMarks,
      sectiondata,
      questionOptionList,
      selectedCategoryQuestionData,
    } = this.state;

    const optionThemeStyles = (data: any, title: string, index: any) => {
      const assessmentData = localStorage.getItem("assessmentData");
      let themeName;
      if (assessmentData !== null) {
        const data = JSON.parse(assessmentData || "{}");
        if (data.assessment_theme !== null) {
          themeName = data.assessment_theme.name;
        } else {
          themeName = "Default Test"
        }
      } else {
        themeName = "Default Test"
      }
      const refIndex = this.state.questionOptionList.findIndex(
        (res: any) => res.charNumber === data.charNumber
      );
      this.setRef(refIndex);

      switch (themeName) {
        case 'Default Test': case 'Rounded Rectangle Layout': return (
          <Grid item xs={11}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="theme_option_box mcq_question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="question_option_text"
                    style={{ backgroundColor: `${data.color}` }}
                  >
                    <Typography className="question_option_title">
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <McqOption
                  classes={this.props.classes}
                  data={data}
                  index={index}
                  refIndex={refIndex} 
                  multipleResponse={this.state.multipleResponse} 
                  multipleInputRef={this.multipleInputRef} 
                  onOptionImageClick={this.onOptionImageClick} 
                  removeOptionImage={this.removeOptionImage} 
                  answerOptionInputChange={this.answerOptionInputChange} 
                  onOptionUploadImageClick={this.onOptionUploadImageClick} 
                  onOptionFileInputChange={this.onOptionFileInputChange} 
                  onOptionRadioChange={this.onOptionRadioChange} 
                  onOptionMultiRadioChange={this.onOptionMultiRadioChange}                  
                />
              </Grid>
            </Box>
          </Grid>
        );
        case 'Outline Rounded Rectangle Layout': return (
          <Grid item xs={11}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="theme_option_box mcq_question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="question_option_text outline_round_rect_theme"
                    style={{ border: `solid 1px ${data.color}` }}
                  >
                    <Typography className="question_option_title" style={{ color: `${data.color}` }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <McqOption
                  classes={this.props.classes}
                  data={data}
                  index={index}
                  refIndex={refIndex} 
                  multipleResponse={this.state.multipleResponse} 
                  multipleInputRef={this.multipleInputRef} 
                  onOptionImageClick={this.onOptionImageClick} 
                  removeOptionImage={this.removeOptionImage} 
                  answerOptionInputChange={this.answerOptionInputChange} 
                  onOptionUploadImageClick={this.onOptionUploadImageClick} 
                  onOptionFileInputChange={this.onOptionFileInputChange} 
                  onOptionRadioChange={this.onOptionRadioChange} 
                  onOptionMultiRadioChange={this.onOptionMultiRadioChange}                  
                />
              </Grid>
            </Box>
          </Grid>
        );
        case 'Circle Layout': return (
          <Grid item xs={11}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="theme_option_box mcq_question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="circle_layout_theme question_option_text"
                    style={{ backgroundColor: `${data.color}` }}
                  >
                    <Typography className="question_option_title">
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <McqOption
                  classes={this.props.classes}
                  data={data}
                  index={index}
                  refIndex={refIndex} 
                  multipleResponse={this.state.multipleResponse} 
                  multipleInputRef={this.multipleInputRef} 
                  onOptionImageClick={this.onOptionImageClick} 
                  removeOptionImage={this.removeOptionImage} 
                  answerOptionInputChange={this.answerOptionInputChange} 
                  onOptionUploadImageClick={this.onOptionUploadImageClick} 
                  onOptionFileInputChange={this.onOptionFileInputChange} 
                  onOptionRadioChange={this.onOptionRadioChange} 
                  onOptionMultiRadioChange={this.onOptionMultiRadioChange}                  
                />
              </Grid>
            </Box>
          </Grid>
        );
        case 'Outline Circle Layout': return (
          <Grid item xs={11}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="theme_option_box mcq_question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="circle_layout_theme question_option_text"
                    style={{ border: `1px solid ${data.color}` }}
                  >
                    <Typography className="question_option_title" style={{ color: `${data.color}` }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <McqOption
                  classes={this.props.classes}
                  data={data}
                  index={index}
                  refIndex={refIndex} 
                  multipleResponse={this.state.multipleResponse} 
                  multipleInputRef={this.multipleInputRef} 
                  onOptionImageClick={this.onOptionImageClick} 
                  removeOptionImage={this.removeOptionImage} 
                  answerOptionInputChange={this.answerOptionInputChange} 
                  onOptionUploadImageClick={this.onOptionUploadImageClick} 
                  onOptionFileInputChange={this.onOptionFileInputChange} 
                  onOptionRadioChange={this.onOptionRadioChange} 
                  onOptionMultiRadioChange={this.onOptionMultiRadioChange}                  
                />
              </Grid>
            </Box>
          </Grid>
        );
        case 'Rectangle Block Layout': return (
          <Grid item xs={11}>
            <Box
              style={{
                border: `solid 1px ${data.color}`,
                backgroundColor: `${data.color}`
              }}
              className="theme_rect_block_option_box mcq_question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="rect_block_layout_theme question_option_text"
                    style={{ backgroundColor: `${data.color}` }}
                  >
                    <Typography className="question_option_title">
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <McqOption
                  classes={this.props.classes}
                  data={data}
                  index={index}
                  refIndex={refIndex} 
                  multipleResponse={this.state.multipleResponse} 
                  multipleInputRef={this.multipleInputRef} 
                  onOptionImageClick={this.onOptionImageClick} 
                  removeOptionImage={this.removeOptionImage} 
                  answerOptionInputChange={this.answerOptionInputChange} 
                  onOptionUploadImageClick={this.onOptionUploadImageClick} 
                  onOptionFileInputChange={this.onOptionFileInputChange} 
                  onOptionRadioChange={this.onOptionRadioChange} 
                  onOptionMultiRadioChange={this.onOptionMultiRadioChange}                  
                />
              </Grid>
            </Box>
          </Grid>
        );
        case 'Outline Rectangle Block Layout': return (
          <Grid item xs={11}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="theme_option_box mcq_question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box className="question_option_text rect_block_layout_theme">
                    <Typography className="question_option_title" style={{ color: `${data.color}` }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <McqOption
                  classes={this.props.classes}
                  data={data}
                  index={index}
                  refIndex={refIndex} 
                  multipleResponse={this.state.multipleResponse} 
                  multipleInputRef={this.multipleInputRef} 
                  onOptionImageClick={this.onOptionImageClick} 
                  removeOptionImage={this.removeOptionImage} 
                  answerOptionInputChange={this.answerOptionInputChange} 
                  onOptionUploadImageClick={this.onOptionUploadImageClick} 
                  onOptionFileInputChange={this.onOptionFileInputChange} 
                  onOptionRadioChange={this.onOptionRadioChange} 
                  onOptionMultiRadioChange={this.onOptionMultiRadioChange}                  
                />
              </Grid>
            </Box>
          </Grid>
        );
      }
    }


    return (
      <>
        <div>
          {this.state.showLoader && (
            <Spinner spinnerModal={this.state.showLoader} />
          )}
          <Grid
            container
            className="marks_section"
          >
            <Grid
              item
              xs={12}
              sm={10}
              className="question_flex_column"
            >
              <Typography
                className={`back_with_arrow ${this.props.classes.bodyText_font}`}
              >
                <span onClick={HISTORY.goBack}>
                  <img
                    src={imgBackArrowSVG}
                    className="question_back_arrow"
                  ></img>
                  Back
                </span>

                <span className={`${this.props.classes.bodyText_font} textlabel_breadcrum`}>
                  <span className={`text-style-light`}>
                    {this.state.isEdit
                      ? "Edit question "
                      : "Create new question "}{" "}
                  </span>
                  (
                  {selectedCategoryQuestionData?.question_category_id === 1 ? (
                    <>Objective</>
                  ) : (
                    <>Subjective</>
                  )}{" "}
                  - {selectedCategoryQuestionData?.name})
                </span>
              </Typography>

              <div className="marks_wrap_div">
                <div className="marks_div">
                  <Typography
                    className={`question_marks_text ${this.props.classes.subHeading_font}`}
                  >
                    Marks
                  </Typography>
                  <div>
                    <div
                      className={this.state.marksError ? "view_marksbox red_border" : "view_marksbox none_border"}
                    >
                      <img
                        src={imageMarksPlusSVG}
                        className="image_marks"
                        onClick={this.plusMarksClick}
                      ></img>
                   
                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={this.onMarksInputChange}
                        onKeyPress={this.onMarksKeyPress}
                        value={marks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.marksError && (
                      <FormHelperText className="red_text">
                        {this.state.marksErrorText}
                      </FormHelperText>
                    )}
                  </div>
                </div>

                {this.state.assessmentHasNegativeMarking && (
                  <div className="marks_div">
                    <Typography
                      className={`question_marks_text ${this.props.classes.subHeading_font}`}
                    >
                      Negative Marks
                    </Typography>
                    <div
                      className={this.state.negmarksError ? "view_marksbox red_border" : "view_marksbox none_border"}
                    >
                      <img
                        src={imageMarksMinusSVG}
                        className="image_marks"
                        onClick={this.minusMarksClick}
                      ></img>
                   
                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={this.onNegativeMarksInputChange}
                        onKeyPress={this.onMarksKeyPress}
                        value={negativeMarks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.negmarksError && (
                      <FormHelperText className="red_text">
                        Negative marking not greater then marks.
                      </FormHelperText>
                    )}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={2} className="remaining_section_marks">
              <Typography
                className={`remaining_marks ${this.props.classes.subHeading_font}`}
              >
                Remaining Section Marks
              </Typography>

              <div className="circular_div">
                <CircularProgress
                  variant="static"
                  className="grey_circular"
                  size={92}
                  thickness={2}
                  value={100}
                />
                <CircularProgress
                  variant="static"
                  disableShrink
                  className="colored_circular"
                  size={92}
                  value={this.state.remainSectionMarkPr}
                  thickness={2}
                />
                <Typography
                  className={`circular_marks_text ${this.props.classes.bodyText_font}`}
                >
                  {(
                    sectiondata?.section_marks -
                    sectiondata?.remaining_marks
                  ).toFixed(2)}
                  /{sectiondata?.section_marks}
                </Typography>
              </div>
            </Grid>
          </Grid>
         
          <Typography className={`question_text ${this.props.classes.subHeading_font}`}>Question</Typography>
          <div ref={this.questionEditorRef}>
            <TextEditor
              myref={this.questionEditorRef}
              handleChange={this.onQuestionChange}
              editorData={
                HISTORY.location.state?.questionData?.attributes?.title
                  ? HISTORY.location.state?.questionData?.attributes?.title
                  : ""
              }
              isEdit={this.state.isEdit}
            />
          </div>
          {this.state.questionError && (
            <FormHelperText className="question_error">
              Please enter question.
            </FormHelperText>
          )}
          <FileDrop
            onDrop={this.onQuestionFileDrop}
          >
            <div className="question_main_image_div">
              {this.state.showLoaderForImageUpload ? (
                <div className="image_uploading_div">
                  <CircularProgress />
                  <Typography
                    className={`image_uploading_text ${this.props.classes.bodyText_font}`}
                  >
                    Uploading...
                  </Typography>
                </div>
              ) : (
                <>
                  {this.state.selectedImageBlobUrl ? (
                    <>
                      <img
                        src={this.state.selectedImageBlobUrl}
                        className="uploaded_question_image"
                        onError={this.onQuestionImageError}
                      ></img>
                      <Button
                        disableRipple={true}
                        variant="outlined"
                        className={`remove_question_image ${this.props.classes.subHeading_font}`}
                        onClick={this.removeSelectedImage}
                      >
                        Remove
                      </Button>
                    </>
                  ) : (
                    <>
                      <img
                        src={imgPlusIconSVG}
                        className="img_plus_icon"
                        onClick={this.onImagePlusClick}
                      />
                      <input
                        ref={this.inputFirstOpenFileRef}
                        accept="image/png, image/jpg, image/jpeg,"
                        className="hidden_input"
                        type="file"
                        onChange={this.onQuestionHiddenFileInputChange}
                      />

                      <Typography
                        className={`upload_image_text ${this.props.classes.bodyText_font}`}
                      >
                        Drag and drop file here or browse them from your
                        computer.
                      </Typography>
                    </>
                  )}
                </>
              )}
            </div>
          </FileDrop>
          <Box className="match_type_div">
            <RadioGroup
              aria-label="position"
              name="position"
              defaultValue="top"
              onChange={this.onOptionRadioGroupChange}
            >
              <Grid container spacing={4}>
                {questionOptionList?.filter((item: any) => !item?._destroy)?.map((data: any, index: number) => {
                  const title = String.fromCharCode(65 + index);
                  return (
                    <Grid item xs={12} sm={6} className="align_item_center_question" key={data.charNumber}>
                      <Grid
                        container
                        spacing={1}
                        className="align_item_center_question"
                      >

                        {/* Theme Styling Block Start */}
                        {optionThemeStyles(data, title, index)}
                        {/* Theme Styling Block End */}

                        <Grid item xs={1}>
                          <ClearIcon
                            className={`fill_option_clear_icon ${this.props.classes.icon_button_color}`}
                            onClick={() => this.removeOption(data)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </RadioGroup>
            <Box className="mcq_checkbox_div">
              <Checkbox
                checked={this.state.multipleResponse}
                className="mcq_checkbox"
                inputProps={{ "aria-label": "secondary checkbox" }}
                onChange={this.onMultipleResponseCheckboxChange}
              />

              <Typography
                className={`mcq_multiple_res ${this.props.classes.subHeading_font}`}
              >
                Multiple responses
              </Typography>
            </Box>
            <Box className="add_graph_line_div">
              <Button
                onClick={this.addNewOption}
                className={`add_new_match_btn ${this.props.classes.outline_button_no_hover}`}
              >
                <AddIcon className="add_new_match_icon" />
                Add New
              </Button>
            </Box>
          </Box>
          <QuestionSolution
            hintAnswer={this.state.hintAnswer}
            hintAnswerOnchange={this.hintAnswerOnchange}
            explaination={this.getExplanation()}
            getPreSignedValue={this.getQuestionSolutionPreSignedValue}
            explainationOnchange={this.explainationOnchange}
            attachmentExplanationUrl={this.state.attachmentExplanationUrl}
            hintsExplanationUrl={this.state.hintsExplanationUrl}
            removeSolutionImage={this.removeSolutionImage}
          />
          <QuestionMetaData
            selectedSubject={this.state.selectedSubject}
            onChangeSubject={this.onChangeSubject}
            chapterListData={this.state.chapterListData}
            selectedChapter={this.state.selectedChapter}
            onChangeChapter={this.onChangeChapter}
            unitListData={this.state.unitListData}
            selectedUnit={this.state.selectedUnit}
            onChangeUnit={this.onChangeUnit}
            selectedSkill={this.state.selectedSkill}
            onChangeSkill={this.onChangeSkill}
            selectedProficiency={this.state.selectedProficiency}
            onChangeProficiency={this.onChangeProficiency}
            selectedDifficultyLevel={this.state.selectedDifficultyLevel}
            onChangeDifficultyLevel={this.onChangeDifficultyLevel}
            keyword={this.state.keyword}
            keywordsList={this.state.keywordsList}
            onChangeKeywords={this.onChangeKeywords}
            onBlurKeywords={this.onBlurKeywords}
            onKeyDownKeywords={this.onKeyDownKeywords}
            handleDeleteKeyword={this.handleDeleteKeyword}
            metaDataErrors={this.state.metaDataErrors}
            selectedBloomTaxonomy={this.state.selectedBloomTaxonomy}
            onChangeBloomTaxonomy={this.onChangeBloomTaxonomy}
          />

          <div className="question_action_items">
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.background_button_no_hover}`}
              onClick={this.onPreviewBtnClick}
            >
              <Box className="preview_btn_box">
                <VisibilityIcon className="eye_preview" />
                <Typography> Preview</Typography>
              </Box>
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.button_color}`}
              onClick={this.saveQuestion}
            >
              {this.state.isEdit ? "Update" : "Save"}
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_cancel_btn ${this.props.classes.outline_button_no_hover}`}
              onClick={this.onClickCancelButton}
            >
              Cancel
            </Button>
          </div>

          <AlertModal
            alertModal={this.state.alertModal}
            isTeacher={true}
            onCloseModal={this.closeAlertModal}
            alertTitle="Alert!"
            alertMessage={this.state.alertMessage}
            buttonText="OK"
          />

          <SelectImageModal
            openSelectImageModal={this.state.openSelectImageModal}
            onCloseModal={this.closeSelectImageModal}
            onClickChooseFromGallery={this.onClickChooseFromGallery}
            onClickUploadFromLocal={this.onClickUploadFromLocal}
          />
          <QuestionPreviewModal questionType={'mcq'} selectedImageBlobUrl={this.state.selectedImageBlobUrl} questionPreviewModal={this.state.prevQuestionModal} closeModalFun={this.closeQuestionPreviewModal} optionAnswer={this.state.questionOptionList} question={this.state.question} questionHint={this.state.hintAnswer} />
          <ChooseFromGalleryModal
            openChooseFromGalleryModal={this.state.openChooseFromGalleryModal}
            onCloseModal={this.closeChooseFromGalleryModal}
            onClickImage={this.onClickImageChooseFromGalleryModal}
            onClickChoose={this.onClickGalleryImageChoose}
            selectedGalleryImageId={this.state.selectedGalleryImageId}
            onTabChangeClearId={this.onTabChangeClearId}
          />
        </div>
        {this.state.isEdit &&
          <QuestionSidebarCollapse editedQuestionData={HISTORY.location.state?.questionData} />
        }
        <ImagePreivewModal
          previewImageModal={this.state.previewImageModal}
          modalhandleClose={this.closeImagePreivewModal}
          previewImage={this.state.previewImage}
        />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(McqAnswerTypeQuestion as React.ComponentType<any>);
// Customizable Area End
