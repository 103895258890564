// Customizable Area Start
export const skillList = [
  { value: "Beginner", label: "Beginner" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Advanced", label: "Advanced" },
];

export const proficiencyList = [
  { value: "English", label: "English" },
  { value: "Math", label: "Math" },
  { value: "Science", label: "Science" },
];

export const difficultyLevelList = [
  { value: "Easy", label: "Easy" },
  { value: "Medium", label: "Medium" },
  { value: "Hard", label: "Hard" },
];

export const librarayAssessmentStatus = [
  { value: "draft", label: "Draft" },
  { value: "published", label: "Published" },
];

export const assessmentStatusList = [
  { value: "Scheduled", label: "Scheduled" },
  { value: "Ongoing", label: "Ongoing" },
  { value: "Completed", label: "Completed" },
];

export const accountTypes = {
  TEACHER: "TeacherAccount",
};

export const examTypes = [
  { id: 1, name: "Formative", value: "formative" },
  { id: 2, name: "Summative", value: "summative" },
  { id: 3, name: "Practice Paper", value: "practice_paper" },
];

export const examDifficulties = [
  { id: 1, name: "Easy", value: "easy" },
  { id: 2, name: "Medium", value: "medium" },
  { id: 3, name: "Hard", value: "hard" },
];

export const studentAssessmentStatusList = [
  { id: 0, name: "Assigned", value: "Assigned" },
  { id: 1, name: "Inprogress", value: "Inprogress" },
  { id: 2, name: "Skipped", value: "Skipped" },
  { id: 3, name: "Submitted", value: "Submitted" },
];

export const currentYearMonthsList = [
  { value: "January-2022", label: "January-2022" },
  { value: "February-2022", label: "February-2022" },
  { value: "March-2022", label: "March-2022" },
  { value: "April-2022", label: "April-2022" },
  { value: "May-2022", label: "May-2022" },
  { value: "June-2022", label: "June-2022" },
  { value: "July-2022", label: "July-2022" },
  { value: "August-2022", label: "August-2022" },
  { value: "September-2022", label: "September-2022" },
  { value: "October-2022", label: "October-2022" },
  { value: "November-2022", label: "November-2022" },
  { value: "December-2022", label: "December-2022" },
];

export const typesOfFillInTheBlankQuestions = [
  { id: 1, value: "text_type", label: "Text Input" },
  { id: 2, value: "mcq", label: "Multiple Choice" },
];

export const bloomsTaxonomyList = [
  { value: "Remember", label: "Remember" },
  { value: "Understand", label: "Understand" },
  { value: "Apply", label: "Apply" },
  { value: "Analyze", label: "Analyze" },
  { value: "Evaluate", label: "Evaluate" },
  { value: "Create", label: "Create" },
];

export const evaluationStatusList = [
  { value: "evaluation_pending", label: "Pending" },
  { value: "evaluation_completed", label: "Completed" },
  { value: "not_taken", label: "Not Taken" },
];
// Customizable Area End