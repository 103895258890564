import React from "react";
// Customizable Area Start
import {
    Button,
    Grid,
    TextField,
    NativeSelect,
    FormHelperText,
    InputBase
} from "@material-ui/core";
import { uploadIcon, deleteGrayIcon, imgBackArrow } from '../assets';
import { withStyles } from "@material-ui/core/styles";
import AdminBadgesController, { Props } from "./AdminBadgesController.web";
import './AdminBadges.web.css';
import Spinner from "../../../shared/SideBar/src/Spinner";
import AlertModal from "../../../alert/src/AlertModal.web";
import { HISTORY } from "../../../../components/src/comman";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";

// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme) => ({
    input: {
        borderRadius: 4,
        backgroundColor: "none",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#ebebeb",
        paddingLeft: 14,
        height: 35,
    },
    error: {
        borderColor: "red !important",
    },
}))(InputBase);
// Customizable Area End

export class CreateBadge extends AdminBadgesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
        // Customizable Area Start
        // Customizable Area End
    render() {
        // Customizable Area Start
        const { badgeCategoryList, badgeName, badgeCategory, badgeDescription, badgeNameError, badgeNameErrorText, badgeCategoryError, badgeCategoryErrorText, badgeImageBaseFileData, badgeImgError, badgeImgErrorText } = this.state;

        return (
            <>
                {this.state.showSpinner && <Spinner spinnerModal={this.state.showSpinner} />}
                <Grid container>
                    <Grid item xs={12} className="badges-top-grid">

                        <span onClick={HISTORY.goBack}
                            className={`${this.props.classes.bodyText_font} back-text-badge`}>
                            <img src={imgBackArrow} className="back-arrow-img"></img>
                            Back
                        </span>
                    </Grid>
                </Grid>

                <Grid container className="create-badge-main-div">
                    <Grid item xs={12} className="create-badge-container">
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={6} className="badge-detail-field">
                                <span className={`${this.props.classes.title_font} badge-detail-title`}>
                                    Badge Name*
                                </span>
                                <TextField
                                    className={`${this.props.classes.CreateBadges_Feedback_textField} ${this.props.classes.bodyText_font}`}
                                    placeholder="Name"
                                    variant={"outlined"}
                                    size={"small"}
                                    onChange={this.onChangeBadgeName}
                                    value={badgeName}
                                    error={badgeNameError}
                                    helperText={badgeNameErrorText}
                                    InputProps={{ className: "badge-text-field" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className="badge-detail-field xs-pt-0">
                                <span className={`${this.props.classes.title_font} badge-detail-title`}>
                                    Badge Category*
                                </span>
                                <div>
                                    <NativeSelect
                                        input={<BootstrapInput />}
                                        placeholder="Category"
                                        className={`${this.props.classes.CreateBadges_Feedback_selectDropDown} ${this.props.classes.bodyText_font}`}
                                        inputProps={{
                                            className: badgeCategoryError ? "CategoryInputError" : "CategoryInput"
                                        }}
                                        value={badgeCategory}
                                        onChange={this.onChangeBadgeCategory}
                                        error={badgeCategoryError}
                                    >
                                        <option value="">Select category</option>
                                        {badgeCategoryList?.map((item: any, ind: number) => (
                                            <option value={item?.id} key={ind} className="transform-capital">
                                                {item?.name}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                    {badgeCategoryError && (
                                        <FormHelperText className="BadgeCategoryError">
                                            {badgeCategoryErrorText}
                                        </FormHelperText>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} className="pt-10">
                            <Grid item xs={12} className="badge-detail-field">
                                <span className={`${this.props.classes.title_font} badge-detail-title`}>
                                    Description
                                </span>
                                <TextField
                                    className={`${this.props.classes.CreateBadges_Feedback_textField} ${this.props.classes.bodyText_font}`}
                                    placeholder="About the badge"
                                    variant={"outlined"}
                                    size={"small"}
                                    onChange={this.onChangeBadgeDesc}
                                    value={badgeDescription}
                                    InputProps={{ className: "badge-text-field" }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} className="create-badge-detail">
                                <div className={badgeImgError ? "badge-upload-img-rectangle red-border" : "badge-upload-img-rectangle"}>
                                    {badgeImageBaseFileData ?
                                        <div className="uploaded-rectangle">
                                            <img src={badgeImageBaseFileData} alt="badge-image" className="uploaded-badge-img"></img>
                                            <img src={deleteGrayIcon} alt="image" className="delete-img" onClick={this.removeUploadedBadge}></img>
                                        </div>
                                        :
                                        <>
                                            <span className={`${this.props.classes.heading_font} label-select-img`}>
                                                Select .png .jpg or .jpeg file to upload
                                            </span>
                                            <div className="upload-rectangle">
                                                <img src={uploadIcon} alt="image" className="upload-badge-img" onClick={this.showOpenFileDlg}></img>
                                                <input
                                                    ref={this.inputOpenFileRef}
                                                    className="AdminHiddenInput"
                                                    type="file"
                                                    accept="image/png, image/jpg, image/jpeg,"
                                                    onChange={(e: any) => this.handleFileUpload(e)}
                                                    data-test-id="create-badge-input"
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                                {badgeImgError && (
                                    <FormHelperText className="BadgeImgError">
                                        {badgeImgErrorText}
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                        <div className="bottom-create-badge-div">
                            <Button className={`${this.props.classes.button_color} add-new-badge-btn`}
                                onClick={this.addNewBadgeClick}>Add New Badge</Button>
                        </div>
                    </Grid>
                </Grid>

                <AlertModal
                    alertModal={this.state.alertModal}
                    isTeacher={false}
                    onCloseModal={this.onCloseCreateBadgeAlertModal}
                    alertTitle="Alert!"
                    alertMessage={this.state.alertMessage}
                    buttonText="OK"
                />
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(CreateBadge);
// Customizable Area End