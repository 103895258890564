// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  checkForRequiredField,
  generateKey,
  computeChecksumMd5,
  toBase64,
} from "./utils/common";
import { createRef, RefObject } from "react";
import { toast } from "react-toastify";
import * as SparkMD5 from "spark-md5";
import moment from "moment";
// Customizable Area End


// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End
export interface Props {
  navigation?: any;
  id?: string;
  getSchoolPeriod: any;
  createEventModal: boolean;
  Events: boolean;
  updateEvents: (bool: boolean) => any;
  handleOnClose: React.Dispatch<React.SetStateAction<boolean>>;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}
interface IField {
  // Customizable Area Start
  categoryList: any,
  Name: string,
  date: string,
  period: string,
  details: string,
  time: string,
  timeState: any,
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  formState: IField | any
  bulkFileUploaded: any;
  bullkFilePreSignedValue: any;
  periodId: number | undefined;
  classId: number | undefined;
  uploadPercentage: number | null;
  alertMessage: string,
  alertModal: boolean,
  nameError: IError | undefined,
  periodError: IError | undefined,
  timeError: IError | undefined,
  detailError: IError | undefined,
  dateError: IError | undefined,
  categoryPeriodList: any;
  startDate: string;
  startDateerror: boolean;
  startDateerrorText: string;
  endDate: string;
  endDateerrorText: string;
  endDateerror: boolean;
  startTime: string;
  startTimeError: boolean;
  endTime: string;
  endTimeError: boolean;
  teacherData: any;
  school_class_id: string;
  schoolClassError: boolean
  eventName: string;
  shareWithTeacher: boolean;
  // Customizable Area End
}
// Customizable Area Start
interface IError {
  error: boolean,
  errorText: string
}
// Customizable Area End
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
export const screenName = {
  DocumentDetails: "DocumentDetails",
  UploadMandatoryDoc: "UploadMandatoryDoc",
  UploadingMandatoryDoc: "UploadingMandatoryDoc",
  UploadedMandatoryDoc: "UploadedMandatoryDoc",
  AdditionalDoc: "AdditionalDoc",
};
// Customizable Area End


export default class TeacherClassesAndSectiionsController extends BlockComponent<Props, S, SS>{
  // Customizable Area Start
  readonly inputOpenFileRef: RefObject<HTMLInputElement>;
  apigetSchoolPeriod: string = "";
  apiPreSignedID: string = '';
  apiCreateEventId: string = '';
  clientSidePercentage = 0; // 25% weightage
  presignedPercentage = 0; // 25% weightage
  awsPercentage = 0; // 50% weightage
  signedId: string | null = null;
  fileData: any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    this.inputOpenFileRef = createRef();
    const dateArray: any[] = []
    this.props.getSchoolPeriod?.map((res: any) => {
      const tempDate = moment(res.attributes.start_date).format("DD-MM-YYYY")
      const todayDate = moment().format("DD-MM-YYYY")
      var x = moment(tempDate, "DD-MM-YYYY")
      var y = moment(todayDate, "DD-MM-YYYY")
      const date = x.isSame(y)
      if (date) {
        dateArray.push(res)
      }
    })
    const initialState = {
      uploadPercentage: null,
      periodId: undefined,
      classId: undefined,
      categoryPeriodList: dateArray,
      startDate: moment().format("DD-MM-YYYY"),
      startDateerror: false,
      shareWithTeacher: false,
      endTime: "00:00",
      endTimeError: false,
      startDateerrorText: '',
      teacherData: [],
      endDateerrorText: '',
      endDateerror: false,
      endDate: moment().format("DD-MM-YYYY"),
      startTime: "00:00",
      startTimeError: false,
      school_class_id: '',
      eventName: '',
      schoolClassError: false,
      formState: {
        categoryList: this.props.getSchoolPeriod,
        Name: '',
        date: moment().format("DD-MM-YYYY"),
        period: '',
        details: '',
        time: '',
        timeState: ['01:00', '02:00', '03:00', '04:00',
          '05:00', '06:00', '07:00', '08:00', '09:00',
          '10:00', '11:00', '12:00', '13:00', '14:00',
          '15:00', '16:00', '17:00', '18:00', '19:00',
          '20:00', '21:00', '22:00', '23:00', '24:00']
      },
      bulkFileUploaded: [] || null,
      bullkFilePreSignedValue: [] || null,
      alertModal: false,
      alertMessage: '',
      nameError: undefined,
      periodError: undefined,
      timeError: undefined,
      detailError: undefined,
      dateError: undefined,
    }
    this.state = initialState;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.userdata = this.userdata.bind(this);

  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.userdata();
  }
  handleOnChange = (field: string) => (e: any) => {
    const { formState } = this.state
    if (field === 'period') {
      const per = e.target.value
      console.log(moment(per?.attributes?.start_date).format("hh:mm A"))
      const z = moment(per?.attributes?.start_date).format("hh:mm A")
      const newState = { ...formState, [field]: per?.attributes?.name, time: z }
      console.log(newState)
      this.setState({
        formState: newState,
        periodId: per.id,
        classId: per?.attributes?.school_class_id,
      })
    } else {
      const newVal = e.target.value
      const name = this.createEventValidData(field, newVal.trim())
      if (name.error) {
        this.setState({
          nameError: field === 'Name' ? name : (this.state.nameError?.error) ? this.state.nameError : undefined,
          periodError: field === 'period' ? name : (this.state.periodError?.error) ? this.state.periodError : undefined,
          timeError: field === 'time' ? name : (this.state.timeError?.error) ? this.state.timeError : undefined,
          detailError: field === 'details' ? name : (this.state.detailError?.error) ? this.state.detailError : undefined,
          dateError: field === 'date' ? name : (this.state.dateError?.error) ? this.state.dateError : undefined,
        })
      } else {
        this.setState({
          nameError: field === 'Name' ? undefined : this.state.nameError,
          periodError: field === 'period' ? undefined : this.state.periodError,
          timeError: field === 'time' ? undefined : this.state.timeError,
          detailError: field === 'details' ? undefined : this.state.detailError,
          dateError: field === 'date' ? undefined : this.state.dateError,
        })
      }
      const newFormState = { ...formState, [field]: newVal }
      this.setState({
        formState: newFormState
      })

    }
  }

  handleOnBlur = (field: string) => (e: any) => {
    const newVal = e.target.value
    const name = this.createEventValidData(field, newVal.trim())
    if (name.error) {


      this.setState({
        nameError: field === 'Name' ? name : (this.state.nameError?.error) ? this.state.nameError : undefined,
        periodError: field === 'period' ? name : (this.state.periodError?.error) ? this.state.periodError : undefined,
        timeError: field === 'time' ? name : (this.state.timeError?.error) ? this.state.timeError : undefined,
        detailError: field === 'details' ? name : (this.state.detailError?.error) ? this.state.detailError : undefined,
        dateError: field === 'date' ? name : (this.state.dateError?.error) ? this.state.dateError : undefined,
      })
    } else {
      this.setState({
        nameError: field === 'Name' ? undefined : this.state.nameError,
        periodError: field === 'period' ? undefined : this.state.periodError,
        timeError: field === 'time' ? undefined : this.state.timeError,
        detailError: field === 'details' ? undefined : this.state.detailError,
        dateError: field === 'date' ? undefined : this.state.dateError,
      })
    }
  }


  checkFormValidity = () => {
    const { formState } = this.state
    for (var key in formState) {
      if (formState.hasOwnProperty(key)) {
        console.log(key + " -> " + formState[key]);
        const name = this.createEventValidData(key, formState[key])
        if (name.error) {
          if (key == 'Name') {
            this.setState({
              nameError: name
            })
          } else if (key == 'time') {
            this.setState({
              timeError: name
            })
          }
          else if (key == 'period') {
            this.setState({
              periodError: name
            })
          }
          else if (key == 'details') {
            this.setState({
              detailError: name
            })
          }
          else if (key == 'date') {
            this.setState({
              dateError: name
            })
          }
        }
      }
    }

  }

  createEventValidData = (prop: string, newVal: string) => {
    return checkForRequiredField(newVal, prop.replaceAll("_", " "));
  };


  createEvent = async (e: any) => {
    e.preventDefault();
    const { formState, bullkFilePreSignedValue, startDate, startTime, school_class_id, endDate, endTime, periodId, classId } = this.state
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    if (formState?.Name === '' || startDate === '' || startTime === '00:00' || school_class_id === '' || endDate === '' || endTime === '00:00' || formState.details === '') {
      if (
        formState?.Name === null ||
        formState?.Name.length === 0
      ) {
        let { nameError } = this.state;
        nameError = {
          ...nameError,
          error: true,
          errorText: "Name is required.",
        },
          this.setState({
            nameError: nameError,
          });
      }
      if (
        startDate === null ||
        startDate.length === 0
      ) {
        this.setState({
          startDateerror: true,
          startDateerrorText: " Please select start date.",
        });
      }
      if (
        startTime === '00:00' ||
        startTime.length === 0
      ) {
        this.setState({ startTimeError: true });
      }
      if (school_class_id === null || school_class_id.length === 0) {
        this.setState({
          schoolClassError: true,
        });
      }
      if (endDate === null || endDate.length === 0) {
        this.setState({
          endDateerror: true,
          endDateerrorText: " Please select start date",
        });
      }
      if (
        endTime === '00:00' ||
        endTime.length === 0
      ) {
        this.setState({ endTimeError: true });
      }

      if (
        formState.details === null ||
        formState.details.length === 0
      ) {
        let { detailError } = this.state;
        detailError = {
          ...detailError,
          error: true,
          errorText: "Detail is required.",
        },
          this.setState({
            detailError: detailError,
          });
      }
      return false;
    }
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id
    };
    const startDateFormatted = moment(startDate , "DD-MM-YYYY").format("YYYY-MM-DD")
    const endDateFormatted = moment(endDate , "DD-MM-YYYY").format("YYYY-MM-DD")
    const httpBody = {
      name: formState.Name,
      details: formState.details,
      start_date: moment(startDateFormatted + ' ' + startTime).format(),
      end_date: moment(endDateFormatted + ' ' + endTime).format(),
      school_class_id: school_class_id,
      share_with_teachers: false,
      share_with_parents: false,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    this.apiCreateEventId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEventEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiPostMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    const { formState } = this.state
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiCreateEventId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            toast.success("SUCCESS: Event created");
            this.ResetState();
            this.props.handleOnClose(false);
            this.props.updateEvents(true);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  calcTotalPercentage = () => {
    const totalPrc = this.clientSidePercentage / 4 + this.presignedPercentage / 4 + this.awsPercentage / 2;
    this.setState({
      uploadPercentage: totalPrc
    });
    if (totalPrc >= 100) {
    }
  }

  ResetState = () => {
    this.setState({
      uploadPercentage: null,
      periodId: undefined,
      classId: undefined,
      formState: {
        categoryList: this.props.getSchoolPeriod,
        Name: '',
        date: '',
        period: '',
        details: '',
        time: '',
      },
      bulkFileUploaded: [] || null,
      bullkFilePreSignedValue: [] || null,
      alertModal: false,
      alertMessage: '',
      nameError: undefined,
      periodError: undefined,
      timeError: undefined,
      detailError: undefined,
      dateError: undefined,
    })
  }

  handleUpload = async (
    event: React.ChangeEvent<any>,
    allowMultipleFiles: boolean,
    filesFromDragAndDrop: any,
    reDirectScreen?: string
  ) => {
    let files: any;
    let allowUplaod: boolean = false;
    let allowLoop: boolean = true;
    let { bulkFileUploaded } = this.state;

    if (filesFromDragAndDrop !== null) {
      files = filesFromDragAndDrop;
    } else {
      files = event.target.files;
    }

    const keys = Object.keys(files);

    if (files.length + bulkFileUploaded.length > 10) {
      this.setState({
        alertMessage: "You can upload maximum 10 files.",
        alertModal: true,
      });
      allowLoop = false;

    }
    if (allowLoop) {
      let percent = 0;
      this.setState({
        uploadPercentage: percent,
        bulkFileUploaded: [],
      });

      keys?.map(async (key: any) => {
        const file = files[key];
        if (allowMultipleFiles) {
          let fileUpload: any = {};

          fileUpload = {
            id: generateKey(file.name),
            fileName: file.name,
            name: file.name.split(".")[0],
            isExistingFile: false,
            fileSize: file.size,
            percentage: 0,
            file: file,
            content_type: file.type,
          };
          console.log(fileUpload, "fileUpload")
          bulkFileUploaded = [...bulkFileUploaded, fileUpload];
          this.setState({ bulkFileUploaded: bulkFileUploaded });

        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.addEventListener("progress", (event) => {
            if (event.loaded && event.total) {
            }
          });
          let checksum = "";

          await computeChecksumMd5(file)
            .then((md5: any) => {
              checksum = md5;
            });

          const extension = file.name.split(".")[
            file.name.split(".").length - 1
          ];
          let fileUpload = {
            id: 0,
            file: file,
            fileSize: file.size,
            checksum: checksum,
            content_type: file.type,
          };

        }
      });
    }
  };

  computeChecksumMd5 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject("MD5 computation failed - error reading the file");
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  };


  bulkUploadDocumentHandleDelete = (itemId: string) => {
    const { bulkFileUploaded, bullkFilePreSignedValue } = this.state;
    const index = bulkFileUploaded.findIndex((item: any) => item.id === itemId);
    const presignedvalueindex = bullkFilePreSignedValue.findIndex((item: any) => item.file === itemId);
    bullkFilePreSignedValue.splice(index, 1)
    bulkFileUploaded.splice(index, 1);
    this.setState({
      bulkFileUploaded: bulkFileUploaded,
      bullkFilePreSignedValue: bullkFilePreSignedValue
    });

    if (bulkFileUploaded?.length === 0) {
      this.setState({
        bulkFileUploaded: [],
        uploadPercentage: 0,
      });
    }
  };


  getPreSignedId = async (fileUploadDetail: any) => {
    const { bulkFileUploaded } = this.state;
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    const httpBody = {
      filename: fileUploadDetail.fileName,
      byte_size: fileUploadDetail.fileSize,
      checksum: fileUploadDetail.checksum,
      content_type: fileUploadDetail.content_type,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    this.apiPreSignedID = requestMessage.messageId; //[this.apiPreSignedID.length] = requestMessage.messageId;
    bulkFileUploaded.map((item: any) => {
      if (item.checksum === fileUploadDetail.checksum) {
        item.requestID = requestMessage.messageId;
      }
    });
    console.log("Map checksum id", bulkFileUploaded);

    await this.setState({ bulkFileUploaded: bulkFileUploaded });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    const result = runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log(result);
  };
  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ teacherData: data });
  };
  updateBulkFileSignedValue = (val: any, item: any) => {
    const bulk = [...this.state.bullkFilePreSignedValue, { value: val, file: item }]
    this.setState({
      bullkFilePreSignedValue: [...this.state.bullkFilePreSignedValue, { value: val, file: item }]
    })
  }
  // Customizable Area End


}
