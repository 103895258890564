// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { List, ListItem,FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Grid ,Typography} from '@material-ui/core';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);
// Customizable Area End

// Customizable Area Start
export default function ReportCardEvaluationEdit() {
  const classes = useStyles();

  return (
    <>
    <div className='profileTitle'>
    <Button className="backBtn"><KeyboardBackspaceOutlinedIcon /> Back</Button>
    <h2>Part 2 - Assessment of Learning </h2>
    <Button className="editBtn commonBtnSecondary">Enable Edit <EditOutlinedIcon /> </Button>
    </div>
    <div className="tableContent">
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>A. English</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",paddingBottom:"90px",borderRadius:"0px",borderTopLeftRadius:"5px"}}>
                                Specific Participation
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px",borderTopRightRadius:"5px" }}>

                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,paddingBottom:"90px",borderRadius:"0px" }}>
                                General Remarks
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderTopRightRadius:"5px",borderRadius:"0px" }}>

                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>

                                Attendence
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                                <Typography style={{ float: "left" }}> No. of Days Present <TextField /></Typography>
                                <Typography style={{ float: "right" }}>Total No. of Working Days  <TextField /></Typography>
                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px",borderBottomLeftRadius:"5px" }}>

                                Class Teachers
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px",borderBottomRightRadius:"5px" }}>
                                Principal / ACo
                                Parent
                            </TableCell>

                        </TableRow>

                    </TableBody>

                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>

                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,paddingBottom:"90px" ,borderRadius:"0px",borderTopLeftRadius:"5px"}}>
                                Specific Participation
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px",borderTopRightRadius:"5px"}}>

                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",paddingBottom:"90px" ,borderRadius:"0px"}}>
                                General Remarks
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9"  ,borderRadius:"0px" }}>

                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2"  ,borderRadius:"0px" }}>

                                Attendence
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px" }}>
                                <Typography style={{ float: "left" }}> No. of Days Present <TextField /></Typography>
                                <Typography style={{ float: "right" }}>Total No. of Working Days  <TextField /></Typography>
                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px",borderBottomLeftRadius:"5px" }}>

                                Class Teachers
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9"  ,borderRadius:"0px",borderBottomRightRadius:"5px"  }} >
                                Principal / ACo
                                Parent
                            </TableCell>

                        </TableRow>

                    </TableBody>

                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>

                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",paddingBottom:"90px" ,borderRadius:"0px",borderTopLeftRadius:"5px"  }}>
                                Specific Participation
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px",borderTopRightRadius:"5px" }}>

                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",paddingBottom:"90px",borderRadius:"0px"  }}>
                                General Remarks
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>

                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>

                                Attendence
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                                <Typography style={{ float: "left" }}> No. of Days Present <TextField /></Typography>
                                <Typography>Over all Attendence <TextField /> %</Typography>
                                <Typography style={{ float: "right" }}>Total No. of Working Days  <TextField /></Typography>
                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px",borderBottomLeftRadius:"5px" }}>

                                Class Teachers
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px",borderBottomRightRadius:"5px" }}>
                                Result: Qualified / Eligible for improvement of Performance (EIOP)
                                New Session begins on <TextField />
                                Principal / ACo
                                Parent
                            </TableCell>

                        </TableRow>

                    </TableBody>

                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>

                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" ,borderTopLeftRadius:"5px"}}>
                                A+
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" ,borderTopRightRadius:"5px" }}>
                                Outstanding
                            </TableCell>
                            <TableCell style={{ border: "0px" }}>

                                1)The Objective of the achievement record  is to facilate holistic learning in the school in a street free environment.

                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px"}}>
                                A
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                Excellent
                            </TableCell>
                            <TableCell style={{ border: "0px" }}>
                                2)The boards recommendeds a five points scale for indicating the achievement in the following order.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                B
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" }}>
                                Very Good
                            </TableCell>
                            <TableCell style={{ border: "0px" }}>
                                3)The Focus  is on identifying the talents of the learner and to empower him/her positive inputs.
                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px",borderBottomLeftRadius:"5px" }}>
                                C
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px",borderBottomRightRadius:"5px" }}>
                                Good
                            </TableCell>


                        </TableRow>

                    </TableBody>

                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>

  
    </div>
    </div>
    </>
  );
}
// Customizable Area End
