// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import TrueFalseTypeQuestionController, {
  Props,
} from "./TrueFalseTypeQuestionController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import QuestionPreviewModal from '../Components/QuestionPreviewModal.web';
import {
  imageMarksMinusSVG,
  imageMarksPlusSVG,
  imgBackArrowSVG,
  imgPlusIconSVG,
} from "../assets";
import QuestionMetaData from "../Components/QuestionMetaData.web";
import { FileDrop } from "react-file-drop";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import "../TeacherAssessment.web.css";
import QuestionSolution from "../Components/QuestionSolution.web";
import SelectImageModal from "./SelectImageModal.web";
import AlertModal from "../../../alert/src/AlertModal.web";
import { HISTORY } from "../../../../components/src/comman";
import ChooseFromGalleryModal from "./ChooseFromGalleryModal.web";
import TextEditor from "./TextEditor/TextEditor.web";
import QuestionSidebarCollapse from "./QuestionSidebarCollapse.web";
import VisibilityIcon from '@material-ui/icons/Visibility';
import './AddQuestion.web.css';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import CustomRadio from "./CustomRadio.web";
// Customizable Area End
// Customizable Area Start
// Customizable Area End

export class TrueFalseTypeQuestion extends TrueFalseTypeQuestionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const {
      marks,
      negativeMarks,
      sectiondata,
      questionOptionList,
      selectedCategoryQuestionData,
    } = this.state;

    const optionThemeStyles = (data: any, title: string, index: any) => {
      const assessmentData = localStorage.getItem("assessmentData");
      let themeName;
      if (assessmentData !== null) {
        const data = JSON.parse(assessmentData || "{}");
        if (data.assessment_theme !== null) {
          themeName = data.assessment_theme.name;
        } else {
          themeName = "Default Test"
        }
      } else {
        themeName = "Default Test"
      }

      switch (themeName) {
        case 'Default Test': case 'Rounded Rectangle Layout': return (
          <Grid item xs={12}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="true_false_theme_option_box question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="question_option_text"
                    style={{ backgroundColor: `${data.color}` }}
                  >
                    <Typography className="question_option_title">
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={8}>
                  {
                    <TextField
                      id="standard-basic"
                      placeholder="Add answer option" 
                      className={`no-underline ${this.props.classes.input_theme_style} add_answer_field`}
                      autoComplete="off"
                      value={data.answer}
                      disabled={true}
                      autoFocus={false}
                      inputProps={{
                        className: "add_answer_input"
                      }}
                    />
                  }
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  className="true_false_option_img_upload_div"
                >
                  <CustomRadio data={data} index={index} onOptionChange={this.onOptionChange} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
        case 'Outline Rounded Rectangle Layout': return (
          <Grid item xs={12}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="true_false_theme_option_box question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="outline_round_rect_theme question_option_text"
                    style={{ border: `solid 1px ${data.color}` }}
                  >
                    <Typography className="question_option_title" style={{ color: `${data.color}` }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={8}>
                  {
                    <TextField
                      id="standard-basic"
                      placeholder="Add answer option"
                      className={`no-underline ${this.props.classes.input_theme_style} add_answer_field`}
                      autoComplete="off"
                      value={data.answer}
                      disabled={true}
                      autoFocus={false}
                      inputProps={{
                        className: "add_answer_input"
                      }}
                    />
                  }
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  className="true_false_option_img_upload_div"
                >
                  <CustomRadio data={data} index={index} onOptionChange={this.onOptionChange} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
        case 'Circle Layout': return (
          <Grid item xs={12}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="true_false_theme_option_box question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="circle_layout_theme question_option_text"
                    style={{ backgroundColor: `${data.color}` }}
                  >
                    <Typography className="question_option_title">
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={8}>
                  {
                    <TextField
                      id="standard-basic"
                      placeholder="Add answer option"
                      className={`no-underline ${this.props.classes.input_theme_style} add_answer_field`}
                      autoComplete="off"
                      value={data.answer}
                      disabled={true}
                      autoFocus={false}
                      inputProps={{
                        className: "add_answer_input"
                      }}
                    />
                  }
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  className="true_false_option_img_upload_div"
                >
                  <CustomRadio data={data} index={index} onOptionChange={this.onOptionChange} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
        case 'Outline Circle Layout': return (
          <Grid item xs={12}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="true_false_theme_option_box question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="circle_layout_theme question_option_text"
                    style={{ border: `1px solid ${data.color}` }}
                  >
                    <Typography className="question_option_title" style={{ color: `${data.color}` }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={8}>
                  {
                    <TextField
                      id="standard-basic"
                      placeholder="Add answer option"
                      className={`no-underline ${this.props.classes.input_theme_style} add_answer_field`}
                      autoComplete="off"
                      value={data.answer}
                      disabled={true}
                      autoFocus={false}
                      inputProps={{
                        className: "add_answer_input"
                      }}
                    />
                  }
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  className="true_false_option_img_upload_div"
                >
                  <CustomRadio data={data} index={index} onOptionChange={this.onOptionChange} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
        case 'Rectangle Block Layout': return (
          <Grid item xs={12}>
            <Box
              style={{
                border: `solid 1px ${data.color}`,
                backgroundColor: `${data.color}`,
              }}
              className="question_option_box true_false_rect_block_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="rect_block_layout_theme question_option_text"
                    style={{ backgroundColor: `${data.color}` }}
                  >
                    <Typography className="question_option_title">
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={8}>
                  {
                    <TextField
                      id="standard-basic"
                      placeholder="Add answer option"
                      className={`no-underline ${this.props.classes.input_theme_style} add_answer_field`}
                      autoComplete="off"
                      value={data.answer}
                      disabled={true}
                      autoFocus={false}
                      inputProps={{
                        className: "add_answer_input"
                      }}
                    />
                  }
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  className="true_false_option_img_upload_div"
                >
                  <CustomRadio data={data} index={index} onOptionChange={this.onOptionChange} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
        case 'Outline Rectangle Block Layout': return (
          <Grid item xs={12}>
            <Box
              style={{ border: `solid 1px ${data.color}` }}
              className="true_false_theme_option_box question_option_box"
            >
              <Grid
                container
                spacing={1}
                className="align_item_center_question"
              >
                <Grid item xs={3} sm={3} md={2}>
                  <Box
                    className="rect_block_layout_theme question_option_text"
                  >
                    <Typography className="question_option_title" style={{ color: `${data.color}` }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={8}>
                  {
                    <TextField
                      id="standard-basic"
                      placeholder="Add answer option"
                      className={`no-underline ${this.props.classes.input_theme_style} add_answer_field`}
                      autoComplete="off"
                      value={data.answer}
                      disabled={true}
                      autoFocus={false}
                      inputProps={{
                        className: "add_answer_input"
                      }}
                    />
                  }
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  className="true_false_option_img_upload_div"
                >
                  <CustomRadio data={data} index={index} onOptionChange={this.onOptionChange} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
      }
    }

    const explainationString:string = this.getExplanation()
    return (
      <>
        <div>
          {this.state.showLoader && (
            <Spinner spinnerModal={this.state.showLoader} />
          )}
          <Grid
            container
            className="marks_section"
          >
            <Grid
              item
              xs={12}
              sm={10}
              className="question_flex_column"
            >
              <Typography
                className={`back_with_arrow ${this.props.classes.bodyText_font}`}
              >
                <span onClick={HISTORY.goBack}>
                  <img
                    src={imgBackArrowSVG}
                    className="question_back_arrow"
                  ></img>
                  Back
                </span>

                <span
                  className={`${this.props.classes.bodyText_font} textlabel_breadcrum`}
                >
                  <span className="text-style-light">
                    {this.state.isEdit
                      ? "Edit question "
                      : "Create new question "}
                  </span>
                  {this.state.isEdit ? "/Edit item" : ""}(
                  {selectedCategoryQuestionData?.question_category_id === 1 ? (
                    <>Objective</>
                  ) : (
                    <>Subjective</>
                  )}{" "}
                  - {selectedCategoryQuestionData?.name})
                </span>
              </Typography>

              <div className="marks_wrap_div">
                {/* Question Marks */}
                <div className="marks_div">
                  <Typography
                    className={`question_marks_text ${this.props.classes.subHeading_font}`}
                  >
                    Marks
                  </Typography>
                  <div>
                    <div
                      className={this.state.marksError ? `view_marksbox red_border` : `view_marksbox none_border`}
                    >
                      <img
                        src={imageMarksPlusSVG}
                        className="image_marks"
                        data-test-id="PlusMarkButton"
                        onClick={this.plusMarksClick}
                      ></img>
                 
                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        data-test-id="MarksInputBox"
                        onChange={this.onPlusMarkChange}
                        onKeyPress={this.onMarksKeyPress}
                        value={marks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.marksError && (
                      <FormHelperText className="red_text">
                        {this.state.marksErrorText}
                      </FormHelperText>
                    )}
                  </div>
                </div>

                {this.state.assessmentHasNegativeMarking && (
                  <div className="marks_div">
                    <Typography
                      className={`question_marks_text ${this.props.classes.subHeading_font}`}
                    >
                      Negative Marks
                    </Typography>
                    <div
                      className={this.state.negmarksError ? `view_marksbox red_border` : `view_marksbox none_border`}
                    >
                      <img
                        src={imageMarksMinusSVG}
                        className="image_marks"
                        data-test-id="MinusMarkButton"
                        onClick={this.minusMarksClick}
                      ></img>
                    
                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        data-test-id="NegativeMarksInput"
                        onChange={this.onNegativeMarkChange}
                        onKeyPress={this.onMarksKeyPress}
                        value={negativeMarks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.negmarksError && (
                      <FormHelperText className="red_text">
                        Negative marking not greater then marks.
                      </FormHelperText>
                    )}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={2} className="remaining_section_marks">
              <Typography
                className={`remaining_marks ${this.props.classes.subHeading_font}`}
              >
                Remaining Section Marks
              </Typography>

              <div className="circular_div">
                <CircularProgress
                  variant="static"
                  className="grey_circular"
                  size={92}
                  thickness={2}
                  value={100}
                />
                <CircularProgress
                  variant="static"
                  disableShrink
                  className="colored_circular"
                  size={92}
                  value={this.state.remainSectionMarkPr}
                  thickness={2}
                />
                <Typography
                  className={`circular_marks_text ${this.props.classes.bodyText_font}`}
                >
                  {(
                    sectiondata?.section_marks -
                    sectiondata?.remaining_marks
                  ).toFixed(2)}
                  /{sectiondata?.section_marks}
                </Typography>
              </div>
            </Grid>
          </Grid>
          {/* Question */}
        
          <Typography
            className={`question_text ${this.props.classes.subHeading_font}`}
          >
            Question
          </Typography>
          <div ref={this.questionEditorRef}>
            <TextEditor
              myref={this.questionEditorRef}
              handleChange={this.onQuestionChange}
              editorData={
                HISTORY.location.state?.questionData?.attributes?.title
                  ? HISTORY.location.state?.questionData?.attributes?.title
                  : ""
              }
              isEdit={this.state.isEdit}
            />
          </div>
          {this.state.questionError && (
            <FormHelperText className="question_error">
              Please enter question.
            </FormHelperText>
          )}
          {/* Question END */}

          {/* Question Image Upload */}
          <FileDrop
            data-test-id ="MainQuestionFileDrop"
            onDrop={this.onFileDrop}
          >
            <div className="question_main_image_div">
              {this.state.showLoaderForImageUpload ? (
                <div className="image_uploading_div">
                  <CircularProgress />
                  <Typography
                    className={`image_uploading_text ${this.props.classes.bodyText_font}`}
                  >
                    Uploading...
                  </Typography>
                </div>
              ) : (
                <>
                  {this.state.selectedImageBlobUrl ? (
                    <>
                      <img
                        src={this.state.selectedImageBlobUrl}
                        className="uploaded_question_image"
                        data-test-id="QuestionMainImage"
                        onError={this.onErrorSelectedBlobImage}
                      ></img>
                      <Button
                        disableRipple={true}
                        variant="outlined"
                        className={`remove_question_image ${this.props.classes.subHeading_font}`}
                        onClick={this.removeSelectedImage}
                      >
                        Remove
                      </Button>
                    </>
                  ) : (
                    <>
                      <img
                        src={imgPlusIconSVG}
                        data-test-id="UploadImagePlaceholder"
                        className="img_plus_icon"
                        onClick={this.openSelectImageModalFn}
                      />
                      <input
                        ref={this.inputFirstOpenFileRef}
                        accept="image/png, image/jpg, image/jpeg,"
                        className="hidden_input"
                        data-test-id="HiddenQuestionFileInput"
                        type="file"
                        onChange={this.hiddenFileInputChange}
                      />

                      <Typography
                        className={`upload_image_text ${this.props.classes.bodyText_font}`}
                      >
                        Drag and drop file here or browse them from your
                        computer.
                      </Typography>
                    </>
                  )}
                </>
              )}
            </div>
          </FileDrop>
          {/* Question Image Upload END */}
          <Box className="match_type_div">
            <RadioGroup
              aria-label="position"
              data-test-id="AnswerRadio"
              name="position"
              defaultValue="top"
              onChange={this.onAnswerRadioChange}
            >
              {/* Answer Grid */}
              <Grid container spacing={4}>
                {questionOptionList.map((data: any, index: number) => {
                  const title = String.fromCharCode(65 + index);
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      key={index}
                      className="align_item_center_question"
                    >
                      <Grid
                        container
                        spacing={5}
                        className="align_item_center_question"
                      >

                        {/* Theme Styling Block Start */}
                        {optionThemeStyles(data, title, index)}
                        {/* Theme Styling Block End */}

                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              {/* Answer Grid END */}
            </RadioGroup>
          </Box>

          <QuestionSolution
            hintAnswer={this.state.hintAnswer}
            hintAnswerOnchange={this.hintAnswerOnchange}
            explaination={explainationString}
            getPreSignedValue={this.getQuestionSolutionPreSignedValue}
            explainationOnchange={this.explainationOnchange}
            attachmentExplanationUrl={this.state.attachmentExplanationUrl}
            hintsExplanationUrl={this.state.hintsExplanationUrl}
            removeSolutionImage={this.removeSolutionImage}
          />

          <QuestionMetaData
            selectedSubject={this.state.selectedSubject}
            onChangeSubject={this.onChangeSubject}
            chapterListData={this.state.chapterListData}
            selectedChapter={this.state.selectedChapter}
            onChangeChapter={this.onChangeChapter}
            unitListData={this.state.unitListData}
            selectedUnit={this.state.selectedUnit}
            onChangeUnit={this.onChangeUnit}
            selectedSkill={this.state.selectedSkill}
            onChangeSkill={this.onChangeSkill}
            selectedProficiency={this.state.selectedProficiency}
            onChangeProficiency={this.onChangeProficiency}
            selectedDifficultyLevel={this.state.selectedDifficultyLevel}
            onChangeDifficultyLevel={this.onChangeDifficultyLevel}
            keyword={this.state.keyword}
            keywordsList={this.state.keywordsList}
            onChangeKeywords={this.onChangeKeywords}
            onBlurKeywords={this.onBlurKeywords}
            onKeyDownKeywords={this.onKeyDownKeywords}
            handleDeleteKeyword={this.handleDeleteKeyword}
            metaDataErrors={this.state.metaDataErrors}
            selectedBloomTaxonomy={this.state.selectedBloomTaxonomy}
            onChangeBloomTaxonomy={this.onChangeBloomTaxonomy}
          />

          <div className="question_action_items">
            <Button
              disableRipple={true}
              variant="outlined"
              data-test-id="PreviewButton"
              className={`question_save_btn ${this.props.classes.background_button_no_hover}`}
              onClick={this.onClickPreviewButton}
            >
              <Box className="preview_btn_box">
                <VisibilityIcon className="eye_preview" />
                <Typography> Preview</Typography>
              </Box>
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.button_color}`}
              onClick={this.saveQuestion}
            >
              {this.state.isEdit ? "Update" : "Save"}
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              data-test-id="CancelButton"
              className={`question_cancel_btn ${this.props.classes.outline_button_no_hover}`}
              onClick={this.onClickCancelButton}
            >
              Cancel
            </Button>
          </div>
          <QuestionPreviewModal questionType={'true false'} selectedImageBlobUrl={this.state.selectedImageBlobUrl} questionPreviewModal={this.state.prevQuestionModal} closeModalFun={this.onCloseQuestionPreviewModal} optionAnswer={this.state.questionOptionList} question={this.state.question} questionHint={this.state.hintAnswer} />

          <AlertModal
            alertModal={this.state.alertModal}
            isTeacher={true}
            onCloseModal={this.onCloseAlertModal}
            alertTitle="Alert!"
            alertMessage={this.state.alertMessage}
            buttonText="OK"
          />

          <SelectImageModal
            openSelectImageModal={this.state.openSelectImageModal}
            onCloseModal={this.onCloseSelectImageModal}
            onClickChooseFromGallery={this.onClickChooseFromGallery}
            onClickUploadFromLocal={this.showFirstOpenFileDlg}
          />

          <ChooseFromGalleryModal
            openChooseFromGalleryModal={this.state.openChooseFromGalleryModal}
            onCloseModal={this.onCloseChooseFromGalleryModal}
            onClickImage={this.onGallaryImageClick}
            onClickChoose={this.onClickGalleryImageChoose}
            selectedGalleryImageId={this.state.selectedGalleryImageId}
            onTabChangeClearId={this.onGallaryTabChangeClearId}
          />
        </div>
        {this.state.isEdit &&
          <QuestionSidebarCollapse editedQuestionData={HISTORY.location.state?.questionData} />
        }
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  TrueFalseTypeQuestion as React.ComponentType<any>
);
// Customizable Area End
