// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const GradeIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
           <path d="M20.923 0c.326 0 .64.13.87.361l3.282 3.282c.231.23.36.543.36.87v22.974c0 .68-.55 1.23-1.23 1.23h-2.051v2.052c0 .68-.552 1.231-1.231 1.231H1.23C.55 32 0 31.449 0 30.77V4.512c0-.679.551-1.23 1.23-1.23h2.052V1.23C3.282.55 3.834 0 4.513 0h16.41zm-6.154 5.744H2.462v23.794h17.23V10.667H16c-.68 0-1.23-.552-1.23-1.231V5.744zm5.644-3.282H5.743v.82h11.898c.327 0 .64.13.87.362l3.282 3.282c.231.23.36.543.36.87v18.46h.821V5.023l-2.561-2.561zM8.845 13.128c.502 0 .955.307 1.142.772l3.282 8.206a1.231 1.231 0 0 1-2.286.916l-.815-2.044H7.522l-.817 2.043a1.23 1.23 0 0 1-2.286-.915L7.702 13.9c.187-.465.64-.772 1.143-.772zm7.134 2.44c.68 0 1.23.552 1.23 1.231v.432h.432a1.231 1.231 0 0 1 0 2.461h-.432v.432a1.231 1.231 0 0 1-2.461 0v-.432h-.433c-.678 0-1.231-.551-1.231-1.23 0-.68.553-1.231 1.23-1.231h.434v-.432c0-.68.551-1.23 1.23-1.23zm-7.134 2.104-.338.845h.676l-.338-.845zm8.386-11.828v2.361h2.361l-2.361-2.361z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default GradeIcon;
// Customizable Area End