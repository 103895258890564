// Customizable Area Start

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import HelpIcon from '@material-ui/icons/Help';
import Grid from '@material-ui/core/Grid';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import TextField from '@material-ui/core/TextField';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import './AccountPhoneVerify.web.css';
import { img21kSchholLogo, shield_image, check } from './assets';
import AccountPhoneVerifyController, {
  Props,
} from './AccountPhoneVerifyController.web';
import Steppers from './Steppers.web';
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class AccountPhoneVerify extends AccountPhoneVerifyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { otp1, otp2, otp3, otp4, data, isOtpCorrect } = this.state;

    return (
      <div className="verify_main_div">
        <div className="root">
          <Grid container className="verify_container">
            <Grid item xs={12}>
              <form onSubmit={this.onteacherOtpSubmit}>
                <div className="Header accountRegistration_div">
                  <img
                    src={img21kSchholLogo}
                    alt="Logo Image"
                    className='logoImage'
                  />
                  <HelpIcon className="accountRegistration_helpIcon" />
                </div>
                <div className="accountRegistration_footer">
                  <IconButton
                    aria-label="delete"
                    className="arrow_style_button_left"
                    color="primary"
                    onClick={() => {
                      this.onBackArrowOtp();
                    }}
                  >
                    <ArrowBackRoundedIcon className="white-back-arrow" />
                  </IconButton>
                  <div className="verify_main_div typesofAccount_div">
                    <div>
                      <Steppers activeStep={1} />
                    </div>
                    <div
                      className={`verify_phone_text typesofAccount_textLabel ${this.props.classes.theme_font}`}
                    >
                      Verify Phone Number
                    </div>
                    <div
                      className={`enter_OTP_sent_text Follow_the_instructions ${this.props.classes.theme_font}`}
                    >
                      {' '}
                      Enter OTP Sent on your mobile number to verify your
                      account
                    </div>

                    <Grid container>
                      <Grid item xs={12} spacing={3} className="textfield_div">
                        <img
                          src={shield_image}
                          alt="Logo Image"
                          className="shield_image shieldImage"
                        />
                      </Grid>
                      <Grid item xs={12} spacing={3} className="textfield_div">
                        <div className="otp_form_div">
                          <div className='formDiv'>
                            <div className="justify-space-between">
                              <TextField
                                id="standard-basic"
                                className="otp_textfield_box forrmInput_textfield"
                                variant="outlined"
                                type="number"
                                name="otp_code"
                                autoFocus={true}
                                disabled={data.phone_activated}
                                value={this.state.otp1}
                                onPaste={(e) => {
                                  const otp = e.clipboardData.getData('Text');
                                  this.setState({
                                    otp1: otp[0],
                                    otp2: otp[1],
                                    otp3: otp[2],
                                    otp4: otp[3],
                                  });
                                }}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  this.setState(
                                    { otp1: event.target.value },
                                    () => {
                                      if (
                                        (this.state.otp1 + otp2 + otp3 + otp4)
                                          .length === 4
                                      ) {
                                        this.onteacherOtpSubmit(event);
                                      }
                                    }
                                  );
                                  if (event.target.value.length === 1) {
                                    const nextfield = document.querySelector(
                                      `input[name=otp2]`
                                    );
                                    if (nextfield !== null) {
                                      (nextfield as HTMLElement).focus();
                                    }
                                  }
                                }}
                                inputProps={{
                                  className: 'otp_input_props',
                                }}
                                onInput={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 1);
                                }}
                                InputProps={{
                                  className: "otp_text_input_props"
                                }}
                              />
                              <TextField
                                id="standard-basic"
                                className="otp_textfield_box forrmInput_textfield"
                                variant="outlined"
                                type="number"
                                name="otp2"
                                disabled={data.phone_activated}
                                value={this.state.otp2}
                                onPaste={(e) => {
                                  const otp = e.clipboardData.getData('Text');
                                  this.setState({
                                    otp1: otp[0],
                                    otp2: otp[1],
                                    otp3: otp[2],
                                    otp4: otp[3],
                                  });
                                }}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  this.setState(
                                    { otp2: event.target.value },
                                    () => {
                                      if (
                                        (otp1 + this.state.otp2 + otp3 + otp4)
                                          .length === 4
                                      ) {
                                        this.onteacherOtpSubmit(event);
                                      }
                                    }
                                  );
                                  if (event.target.value.length === 1) {
                                    const nextfield = document.querySelector(
                                      `input[name=otp3]`
                                    );
                                    if (nextfield !== null) {
                                      (nextfield as HTMLElement).focus();
                                    }
                                  }
                                }}
                                inputProps={{
                                  className: 'otp_input_props',
                                }}
                                onInput={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 1);
                                }}
                                InputProps={{
                                  className: "otp_text_input_props"
                                }}
                              />
                              <TextField
                                id="standard-basic"
                                className="otp_textfield_box forrmInput_textfield"
                                variant="outlined"
                                type="number"
                                name="otp3"
                                disabled={data.phone_activated}
                                value={this.state.otp3}
                                onPaste={(e) => {
                                  const otp = e.clipboardData.getData('Text');
                                  this.setState({
                                    otp1: otp[0],
                                    otp2: otp[1],
                                    otp3: otp[2],
                                    otp4: otp[3],
                                  });
                                }}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  this.setState(
                                    { otp3: event.target.value },
                                    () => {
                                      if (
                                        (otp1 + otp2 + this.state.otp3 + otp4)
                                          .length === 4
                                      ) {
                                        this.onteacherOtpSubmit(event);
                                      }
                                    }
                                  );
                                  if (event.target.value.length === 1) {
                                    const nextfield = document.querySelector(
                                      `input[name=otp4]`
                                    );
                                    if (nextfield !== null) {
                                      (nextfield as HTMLElement).focus();
                                    }
                                  }
                                }}
                                inputProps={{
                                  className: 'otp_input_props',
                                }}
                                onInput={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 1);
                                }}
                                InputProps={{
                                  className: "otp_text_input_props"
                                }}
                              />
                              <TextField
                                id="standard-basic"
                                className="otp_textfield_box forrmInput_textfield"
                                variant="outlined"
                                type="number"
                                name="otp4"
                                disabled={data.phone_activated}
                                value={this.state.otp4}
                                onPaste={(e) => {
                                  const otp = e.clipboardData.getData('Text');
                                  this.setState({
                                    otp1: otp[0],
                                    otp2: otp[1],
                                    otp3: otp[2],
                                    otp4: otp[3],
                                  });
                                }}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  this.setState(
                                    { otp4: event.target.value },
                                    () => {
                                      if (
                                        (otp1 + otp2 + otp3 + this.state.otp4)
                                          .length === 4
                                      ) {
                                        this.onteacherOtpSubmit(event);
                                      }
                                    }
                                  );
                                }}
                                inputProps={{
                                  className: 'otp_input_props',
                                }}
                                onInput={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => { }}
                                InputProps={{
                                  className: "otp_text_input_props"
                                }}
                              />
                            </div>
                          </div>

                          <img
                            hidden={!isOtpCorrect}
                            src={check}
                            alt="check"
                            className="check_image"
                          />
                        </div>

                        <Grid xs={12}>
                          {this.state.otperror && (
                            <FormHelperText className="otp_error_text">
                              {this.state.otperrorText}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={10} lg={9} spacing={3}>
                        <div
                          className={`center textlabel_forgotpasswordText  ${this.props.classes.theme_font}`}
                        >
                          {data.phone_activated ? (
                            <span className={`Follow_the_instructions ${this.props.classes.theme_font}`}>
                              Phone number already activated
                            </span>
                          ) : (
                            <>
                              <span>Didn't receive OTP?</span>{' '}
                              <Link
                                className="resendotp_text"
                                onClick={() => {
                                  const nextfield = document.querySelector(
                                    `input[name=otp_code]`
                                  );
                                  if (nextfield !== null) {
                                    (nextfield as HTMLElement).focus();
                                  }
                                  this.resendOtpClick();
                                }}
                              >
                                Resend
                              </Link>
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <IconButton
                    type="submit"
                    aria-label="delete"
                    className="arrow_style_button_right"
                    color="primary"
                  >
                    {this.state.loadingSpinner ? (
                      <CircularProgress className="spinnerBar" />
                    ) : (
                      <>
                        <ArrowForwardRoundedIcon className="white-back-arrow" />
                      </>
                    )}
                  </IconButton>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(AccountPhoneVerify);
// Customizable Area End
