// Customizable Area Start
import React from "react";
import {
    StyleSheet,
    Platform,
} from "react-native";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Chart } from "react-google-charts";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import Calendar from 'react-calendar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ReactPaginate from "react-paginate";
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    CircularProgress,
    IconButton,
    Link,
    Menu,
    MenuItem,
    NativeSelect,
    TextField,
    Dialog,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Divider,
    FormLabel,
    FormHelperText,
    Popover
} from "@material-ui/core";
import {
    Search,
    Replay,
    NotificationsNone,
    HeadsetMicOutlined,
    Title,
    TitleOutlined,
    KeyboardArrowLeft,
    EditOutlined,
    MoreVertOutlined,
    KeyboardArrowDown,
    ArrowDropDown,
    BorderBottom
} from "@material-ui/icons";

import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import AddTeacherController, { Props } from './AddTeacherController.web';
import "../AddStudent.web.css";

import moment from "moment";
import { addMore, adminDownload, adminFilter, deleteMore, moreOption } from "../assets";
import { parse } from "path";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import CsvError from "../CsvError.web";
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
const BootstrapInput1 = withStyles(() => ({
    input: {
        width: "100%",
        // borderRadius: "8.8px",
        textAlign: "start",
        color: "#3f526d",
        fontSize: "1.125rem",
        fontFamily: "Open sans",
        opacity: 0.9,
        border: "solid 0.3px rgba(63, 82, 109, 0.5)"

    },
}))(InputBase);
const forrmInput_textfield = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
    color: "#3f526d",
    fontSize: "1.125rem",
    fontFamily: "Open sans",
} as const;
// Customizable Area End
export class AddTeacher extends AddTeacherController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const name = /^[a-zA-Z ]*$/;
        const phoneReg = /^(?:[0-9]●?){10,15}[0-9]$/;
        const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const today = moment();
        const disableFutureDt = (current: any) => {
            return current.isBefore(today);
        };
        return (
            <>
                <Dialog
                    PaperProps={{
                        style: { borderRadius: 8 }
                    }}
                    aria-labelledby="customized-dialog-title"
                    maxWidth="md"
                    open={this.props.addDialog}
                    onClose={() => this.props.handleDialogClose()}
                    closeAfterTransition
                    fullWidth={true}
                    disableAutoFocus={true}
                    style={{ borderRadius: 8 }}
                >
                    {this.state.loadingSpinner && <Spinner spinnerModal={this.state.loadingSpinner} />}
                    <Grid item xs={12} style={{
                        padding: '2.5rem 6rem',
                        borderRadius: 8,
                        boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)'
                    }}>
                        <Grid container spacing={3}>
                            <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                                <Grid item xs={12} style={{ marginBottom: 10 }}>
                                    <FormLabel
                                        style={{
                                            fontSize: '1.125rem',
                                            fontWeight: 600,
                                        }}
                                        className={this.props.classes.subTitle_font}
                                    >
                                        First Name*
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        id="standard-basic"
                                        placeholder="Enter First Name"
                                        style={forrmInput_textfield}
                                        variant="outlined"
                                        value={this.state.firstName}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = event.target.value.trim();
                                            if (value.length === 0) {
                                                this.setState({
                                                    firstNameError: true,
                                                    firstNameErrorMes: " First name is required.",
                                                });
                                            } else {
                                                this.setState({
                                                    firstNameError: false,
                                                    firstNameErrorMes: "",
                                                });
                                            }

                                            if (
                                                event.target.value === "" ||
                                                name.test(event.target.value)
                                            ) {
                                                this.setState({ firstName: event.target.value });
                                            }
                                        }}
                                        onBlur={() => {
                                            if (
                                                this.state.firstName === null ||
                                                this.state.firstName.trim().length === 0
                                            ) {
                                                this.setState({
                                                    firstNameError: true,
                                                    firstNameErrorMes: " First name is required.",
                                                });
                                            } else {
                                                this.setState({
                                                    firstNameError: false,
                                                    firstNameErrorMes: "",
                                                });
                                            }
                                        }}
                                        error={this.state.firstNameError}
                                        helperText={this.state.firstNameErrorMes}

                                        inputProps={{ style: { fontFamily: "Open sans", fontSize: '1.125rem', color: "#3f526d", padding: '1rem 1.875rem' } }}
                                        InputProps={{
                                            // startAdornment: (
                                            //   <InputAdornment position="start" style={{ marginRight: "0" }}>
                                            //     <img src={schoolIcon} style={{ width: '1.465rem', height: '1.465rem', color: "#7c7ba0", padding: '1.15rem 1.87rem 1.15rem 1.56rem', marginRight: "0px" }} />
                                            //   </InputAdornment>
                                            // ),
                                            style: {
                                                fontSize: "1.125rem",
                                                color: '#3f526d',
                                                fontFamily: "OpenSans",
                                                opacity: 1,
                                                paddingLeft: 0,
                                                // border: "1px solid #cfcfcf",
                                                //backgroundColor: "#f1f1f1",

                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                                <Grid item xs={12} style={{ marginBottom: 10 }}>
                                    <FormLabel
                                        style={{
                                            fontSize: '1.125rem',
                                            fontWeight: 600,
                                        }}
                                        className={this.props.classes.subTitle_font}
                                    >
                                        Last Name*
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        id="standard-basic"
                                        placeholder="Enter Last Name"
                                        style={forrmInput_textfield}
                                        variant="outlined"
                                        value={this.state.lastName}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = event.target.value.trim();
                                            if (value.length === 0) {
                                                this.setState({
                                                    lastNameError: true,
                                                    lastNameErrorMes: " Last name is required.",
                                                });
                                            } else {
                                                this.setState({
                                                    lastNameError: false,
                                                    lastNameErrorMes: "",
                                                });
                                            }

                                            if (
                                                event.target.value === "" ||
                                                name.test(event.target.value)
                                            ) {
                                                this.setState({ lastName: event.target.value });
                                            }
                                        }}
                                        onBlur={() => {
                                            if (
                                                this.state.lastName === null ||
                                                this.state.lastName.trim().length === 0
                                            ) {
                                                this.setState({
                                                    lastNameError: true,
                                                    lastNameErrorMes: " Last name is required.",
                                                });
                                            } else {
                                                this.setState({
                                                    lastNameError: false,
                                                    lastNameErrorMes: "",
                                                });
                                            }
                                        }}
                                        error={this.state.lastNameError}
                                        helperText={this.state.lastNameErrorMes}

                                        inputProps={{ style: { fontFamily: "Open sans", fontSize: '1.125rem', color: "#3f526d", padding: '1rem 1.875rem' } }}
                                        InputProps={{
                                            // startAdornment: (
                                            //   <InputAdornment position="start" style={{ marginRight: "0" }}>
                                            //     <img src={schoolIcon} style={{ width: '1.465rem', height: '1.465rem', color: "#7c7ba0", padding: '1.15rem 1.87rem 1.15rem 1.56rem', marginRight: "0px" }} />
                                            //   </InputAdornment>
                                            // ),
                                            style: {
                                                fontSize: "1.125rem",
                                                color: '#3f526d',
                                                fontFamily: "OpenSans",
                                                opacity: 1,
                                                paddingLeft: 0,
                                                // border: "1px solid #cfcfcf",
                                                //backgroundColor: "#f1f1f1",

                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                                <Grid item xs={12} style={{ marginBottom: 10 }}>
                                    <FormLabel
                                        style={{
                                            fontSize: '1.125rem',
                                            fontWeight: 600,
                                        }}
                                        className={this.props.classes.subTitle_font}
                                    >
                                        DOB*
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={12} >
                                    <Datetime
                                        dateFormat={"DD/MM/YYYY"}
                                        timeFormat={false}
                                        strictParsing={true}
                                        input={true}

                                        value={this.state.DOB !== "" ? moment(this.state.DOB, "DD/MM/YYYY").format("DD/MM/YYYY") : "DD/MM/YYYY"}
                                        className={
                                            this.state.DOBError
                                                ? "input_date_css_error_format1"
                                                : "input_date_cal_css_format1"
                                        }
                                        inputProps={{
                                            placeholder: "Date of Birth1",
                                            className: "Date_of_birth_calendar_Icon1",
                                            readOnly: true,
                                        }}
                                        isValidDate={disableFutureDt}

                                        onChange={(date) => {
                                            if (moment(date).format("DD/MM/YYYY").length === 0) {
                                                this.setState({
                                                    DOBError: true,
                                                    DOBErrorMes: " Please select date of birth.",
                                                });
                                            }
                                            this.setState({
                                                DOB: moment(date).format("DD/MM/YYYY"),
                                                DOBError: false,
                                                DOBErrorMes: '',
                                            });
                                        }}
                                    />
                                    <FormHelperText
                                        style={{ color: "red", margin: "3px 14px 0" }}
                                    >
                                        {this.state.DOBErrorMes}
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                            <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                                <Grid item xs={12} style={{ marginBottom: 10 }}>
                                    <FormLabel
                                        style={{
                                            fontSize: '1.125rem',
                                            fontWeight: 600,
                                        }}
                                        className={this.props.classes.subTitle_font}
                                    >
                                        Gender*
                                    </FormLabel>
                                </Grid>

                                <Grid item xs={12} style={{ cursor: "pointer" }} onClick={(event: any) => { this.setState({ tempGender: this.state.gender, genderDropDownAnchorEl: event.currentTarget, }, () => this.setState({ genderClassDropDownDiv: true, })) }}>


                                    <Box style={{
                                        textAlign: "start",
                                        color: "#3f526d",
                                        fontSize: "1.125rem",
                                        fontFamily: "Open sans",

                                        border: this.state.genderError ? "0.4px solid red" : "0.4px solid rgb(196 196 196)",
                                        display: "flex",
                                        justifyContent: 'space-between',
                                        padding: "0.9rem 0.5rem 0.9rem 1.875rem",
                                        borderRadius: 4,
                                        //backgroundColor: "#f9f8fd",
                                        alignItems: "center",
                                        // backgroundImage: "linear-gradient(to bottom, #fff, #fff), linear-gradient(to top, #af38cb 126%, #7f6cfc -23%)"
                                    }}>
                                        {this.state.gender.length != 0 ?
                                            <Box style={{ display: "flex", flexDirection: "row" }}>
                                                <Box>
                                                    <Typography
                                                        style={{
                                                            lineHeight: 'initial',
                                                            fontSize: "1.125rem",
                                                            textTransform: 'capitalize'
                                                        }}
                                                        className={this.props.classes.bodyText_font}
                                                    >
                                                        {this.state.gender}
                                                    </Typography>

                                                </Box>

                                            </Box>
                                            :
                                            <Typography style={{

                                                fontSize: "1.125rem",
                                                fontFamily: "Open sans",
                                                color: "rgb(177 185 196)",
                                                lineHeight: 'initial'

                                            }}>
                                                Select Gender
                                            </Typography>
                                        }
                                        <Box style={{ display: "flex" }}>
                                            <KeyboardArrowDownIcon />
                                        </Box>
                                    </Box>
                                    {this.state.genderError && (
                                        <FormHelperText
                                            style={{ color: "red", margin: "3px 14px 0" }}
                                        >
                                            Please select gender.
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Popover
                                    id="3"
                                    anchorEl={this.state.genderDropDownAnchorEl}
                                    open={Boolean(this.state.genderDropDownAnchorEl)}
                                    onClose={() => {
                                        this.setState({
                                            genderDropDownAnchorEl: null
                                        })
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    style={{
                                        height: "auto"
                                    }}
                                    PaperProps={{
                                        style: {
                                            width: '30%',
                                            maxHeight: '70%',
                                            padding: "20px 25px 30px 25px",
                                            borderRadius: 8,
                                            boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.2)",
                                            backgroundColor: "#fff"
                                        },
                                    }}
                                >
                                    <Box>
                                        <Box
                                            style={{
                                                padding: "0px 0px 5px 0px",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                borderBottom: "solid 0.5px rgba(63, 82, 109,0.34)"
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: 17,
                                                    fontWeight: 600,
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    cursor: 'pointer',
                                                    opacity: this.state.tempGender === "Male" ? 1 : 0.7
                                                }}
                                                className={this.props.classes.bodyText_font}
                                                onClick={() => {
                                                    this.setState({ tempGender: 'Male' }, () => {

                                                    })
                                                }}>
                                                Male
                                            </Typography>

                                        </Box>
                                        <Box
                                            style={{
                                                padding: "5px 0px 5px 0px",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                borderBottom: "solid 0.5px rgba(63, 82, 109,0.34)"
                                            }}
                                        >

                                            <Typography
                                                style={{
                                                    fontSize: 17,
                                                    fontWeight: 600,
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    cursor: 'pointer',
                                                    opacity: this.state.tempGender == "Female" ? 1 : 0.7
                                                }}
                                                className={this.props.classes.bodyText_font}

                                                onClick={() => {
                                                    this.setState({ tempGender: 'Female' }, () => {

                                                    })
                                                }}>
                                                Female
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                padding: "5px 0px 5px 0px",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                borderBottom: "solid 0.5px rgba(63, 82, 109,0.34)"
                                            }}
                                        >

                                            <Typography
                                                style={{
                                                    fontSize: 17,
                                                    fontWeight: 600,
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    cursor: 'pointer',
                                                    opacity: this.state.tempGender == "Others" ? 1 : 0.7
                                                }}
                                                className={this.props.classes.bodyText_font}

                                                onClick={() => {
                                                    this.setState({ tempGender: 'Others' }, () => { })
                                                }}>
                                                Others
                                            </Typography>
                                        </Box>


                                        <Box
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "start",
                                                marginTop: 30,
                                                alignItems: "center",
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    borderRadius: '6px',
                                                    width: "40%",
                                                    height: 30,
                                                    textTransform: "capitalize",
                                                    fontSize: "1rem",
                                                    marginRight: 12,
                                                }}
                                                className={this.props.classes.button_color}
                                                onClick={() => {
                                                    if (this.state.tempGender !== "") {
                                                        this.setState({ genderError: false })
                                                    }
                                                    this.setState({
                                                        gender: this.state.tempGender,
                                                        genderDropDownAnchorEl: null

                                                    }, () => {
                                                        this.setState({
                                                            tempGender: ''
                                                        })
                                                    })
                                                }}
                                            >
                                                OK
                                            </Button>
                                            <Button
                                                style={{
                                                    borderRadius: 6,
                                                    fontSize: '1rem',
                                                    fontWeight: 600,
                                                    textTransform: "none",
                                                    marginLeft: "5px",
                                                    width: '40%',
                                                    height: 30
                                                }}
                                                className={this.props.classes.button_color_disabled}
                                                onClick={() => {
                                                    this.setState({
                                                        genderDropDownAnchorEl: null,
                                                        tempGender: ''
                                                    })
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Box>
                                </Popover>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                                <Grid item xs={12} style={{ marginBottom: 10 }}>
                                    <FormLabel
                                        style={{
                                            fontSize: '1.125rem',
                                            fontWeight: 600,

                                        }}
                                        className={this.props.classes.subTitle_font}
                                    >
                                        Phone No*
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={12} >
                                    <ReactPhoneInput
                                        country={"in"}
                                        placeholder="Phone Number"
                                        value={this.state.phoneNo}
                                        alwaysDefaultMask={false}
                                        autoFormat={false}
                                        disabled={this.props.isEdit}
                                        disableCountryGuess={true}
                                        inputStyle={{
                                            color: '#3f526d',
                                            width: "100%",
                                            height: "56px",
                                            fontFamily: "Open sans",
                                            fontSize: "15.8px",
                                            borderRadius: "5px",
                                            border: this.state.phoneNoError
                                                ? "1px solid red"
                                                : "1px solid lightgray",
                                            borderLeft: this.state.phoneNoError
                                                ? "0px"
                                                : "1px solid lightgray",

                                        }}
                                        buttonStyle={{
                                            backgroundColor: '#fff',
                                            borderLeft: this.state.phoneNoError
                                                ? "1px solid red"
                                                : "1px solid lightgray",
                                            borderTop: this.state.phoneNoError
                                                ? "1px solid red"
                                                : "1px solid lightgray",
                                            borderBottom: this.state.phoneNoError
                                                ? "1px solid red"
                                                : "1px solid lightgray",
                                            borderRight: '0px'
                                        }}
                                        dropdownStyle={{ width: "300px" }}
                                        searchStyle={{ margin: "0", width: "97%", height: "30px" }}
                                        searchClass="search-class"
                                        disableSearchIcon
                                        enableSearch={true}

                                        countryCodeEditable={false}
                                        onChange={(value, country: any) => {
                                            console.log(value);
                                            if (this.state.dialCode !== country.countryCode) {
                                                this.setState({ phoneNo: country.dialCode, dialCode: country.countryCode });
                                            } else {

                                                this.setState({ phoneNo: value, dialCode: country.countryCode });
                                            }
                                            if (value.length === 0) {
                                                this.setState({
                                                    phoneNoError: true,
                                                    phoneNoErrorMes: "Phone number is required.",
                                                });
                                            } else if (phoneReg.test(value)) {
                                                console.log("value", value);
                                                this.setState({
                                                    phoneNoError: false,
                                                    phoneNoErrorMes: "",
                                                });
                                            } else if (!phoneReg.test(value)) {
                                                this.setState({
                                                    phoneNoError: true,
                                                    phoneNoErrorMes: "Enter a valid phone number.",
                                                });
                                            } else {
                                                this.setState({
                                                    phoneNoError: false,
                                                    phoneNoErrorMes: "",
                                                });
                                            }
                                        }}
                                        onBlur={(value: any) => {
                                            if (
                                                this.state.phoneNo === null ||
                                                this.state.phoneNo.length === 0
                                            ) {
                                                this.setState({
                                                    phoneNoError: true,
                                                    phoneNoErrorMes: "Phone number is required.",
                                                });
                                            } else if (!phoneReg.test(this.state.phoneNo)) {
                                                this.setState({
                                                    phoneNoError: true,
                                                    phoneNoErrorMes: "Enter a valid phone number.",
                                                });
                                            } else if (phoneReg.test(this.state.phoneNo)) {
                                                this.setState({
                                                    phoneNoError: false,
                                                    phoneNoErrorMes: "",
                                                });
                                            } else {
                                                this.setState({
                                                    phoneNoError: false,
                                                    phoneNoErrorMes: "",
                                                });
                                            }
                                        }}
                                    />
                                    {this.state.phoneNoError && (
                                        <FormHelperText
                                            style={{ color: "red", margin: "3px 14px 0" }}
                                        >
                                            {this.state.phoneNoErrorMes}
                                        </FormHelperText>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                                <Grid item xs={12} style={{ marginBottom: 10 }}>
                                    <FormLabel
                                        style={{
                                            fontSize: '1.125rem',
                                            fontWeight: 600,
                                        }}
                                        className={this.props.classes.subTitle_font}
                                    >
                                        Email ID*
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        id="standard-basic"
                                        placeholder="Email ID"
                                        style={forrmInput_textfield}
                                        disabled={this.props.isEdit}
                                        variant="outlined"
                                        value={this.state.emailId}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = event.target.value.trim();
                                            console.log(value);
                                            if (value.length === 0) {
                                                this.setState({
                                                    emailIdError: true,
                                                    emailIdErrorMes: "Email is required.",
                                                });
                                            } else if (!emailReg.test(value)) {
                                                this.setState({
                                                    emailIdError: true,
                                                    emailIdErrorMes: "Enter a valid Email.",
                                                });
                                            } else if (emailReg.test(value)) {
                                                this.setState({
                                                    emailIdError: false,
                                                    emailIdErrorMes: "",
                                                });
                                            }
                                            this.setState({ emailId: event.target.value.trim() });
                                        }}
                                        onBlur={() => {
                                            if (
                                                this.state.emailId === null ||
                                                this.state.emailId.length === 0
                                            ) {
                                                this.setState({
                                                    emailIdError: true,
                                                    emailIdErrorMes: "Email is required.",
                                                });
                                            } else if (!emailReg.test(this.state.emailId)) {
                                                this.setState({
                                                    emailIdError: true,
                                                    emailIdErrorMes: "Enter a valid Email.",
                                                });
                                            } else if (emailReg.test(this.state.emailId)) {
                                                this.setState({
                                                    emailIdError: false,
                                                    emailIdErrorMes: "",
                                                });
                                            } else {
                                                this.setState({
                                                    emailIdError: false,
                                                    emailIdErrorMes: "",
                                                });
                                            }
                                        }}
                                        error={this.state.emailIdError}
                                        helperText={this.state.emailIdErrorMes}
                                        inputProps={{ style: { fontFamily: "Open sans", fontSize: '1.125rem', color: "#3f526d", padding: '1rem 1.875rem', cursor: this.props.isEdit ? "not-allowed" : '' } }}
                                        InputProps={{
                                            // startAdornment: (
                                            //   <InputAdornment position="start" style={{ marginRight: "0" }}>
                                            //     <img src={schoolIcon} style={{ width: '1.465rem', height: '1.465rem', color: "#7c7ba0", padding: '1.15rem 1.87rem 1.15rem 1.56rem', marginRight: "0px" }} />
                                            //   </InputAdornment>
                                            // ),
                                            style: {
                                                fontSize: "1.125rem",
                                                color: '#3f526d',
                                                fontFamily: "OpenSans",
                                                opacity: 1,
                                                paddingLeft: 0,
                                                cursor: this.props.isEdit ? "not-allowed" : ''
                                                // border: "1px solid #cfcfcf",
                                                //backgroundColor: "#f1f1f1",

                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {this.state.classesAndSubject.map((_classAndSubject: any, _index: any) => <Grid container spacing={3}>

                            <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                                <Grid item xs={12} style={{ marginBottom: 10 }}>
                                    <FormLabel
                                        style={{
                                            fontSize: '1.125rem',
                                            fontWeight: 600,
                                        }}
                                        className={this.props.classes.subTitle_font}
                                    >
                                        Class{_index === 0 && '*'}
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '0.625rem', cursor: "pointer" }} onClick={(event: any) => { this.setState({ tempClass: _classAndSubject.class, classDropDownAnchorEl: event.currentTarget, selectedIndexId: _index }, () => this.setState({ selectClassDropDownDiv: true, })) }}>
                                    <Box style={{
                                        textAlign: "start",
                                        fontSize: "1.125rem",
                                        border: _classAndSubject.classError ? "0.4px solid red" : "0.4px solid rgb(196 196 196)",
                                        display: "flex",
                                        justifyContent: 'space-between',
                                        padding: "0.9rem 0.5rem 0.9rem 1.875rem",
                                        borderRadius: 4,
                                        alignItems: "center",
                                        textTransform: 'capitalize'
                                    }}
                                        className={this.props.classes.bodyText_font}
                                    >
                                        {_classAndSubject.class.length != 0 ?
                                            <Box style={{ display: "flex", flexDirection: "row" }}>

                                                <Box>  <Typography
                                                    style={{
                                                        lineHeight: 'initial',
                                                        fontSize: "1.125rem",
                                                    }}
                                                    className={this.props.classes.bodyText_font}
                                                >
                                                    {
                                                        this.state.classList.filter((x: any) => x.id === _classAndSubject.class)
                                                            .map((x: any) => x.attributes?.display_name)
                                                    }
                                                </Typography>

                                                </Box>

                                            </Box>
                                            :
                                            <Typography style={{

                                                fontSize: "1.125rem",
                                                fontFamily: "Open sans",
                                                color: "rgb(177 185 196)",
                                                lineHeight: 'initial'

                                            }}>
                                                Select Class
                                            </Typography>
                                        }
                                        <Box style={{ display: "flex" }}>
                                            <KeyboardArrowDownIcon />
                                        </Box>
                                    </Box>

                                    {_classAndSubject.classError && (
                                        <FormHelperText
                                            style={{ color: "red", margin: "3px 14px 0" }}
                                        >
                                            Please select class.
                                        </FormHelperText>
                                    )}

                                </Grid>

                            </Grid>
                            <Grid item style={{ marginTop: "2%", position: 'relative' }} sm={6} xs={12} >
                                <Grid item xs={12} style={{ marginBottom: 10 }}>
                                    <FormLabel
                                        style={{
                                            fontSize: '1.125rem',
                                            fontWeight: 600,
                                        }}
                                        className={this.props.classes.subTitle_font}
                                    >
                                        Subject{_index === 0 && '*'}
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '0.625rem', cursor: "pointer" }} onClick={(event: any) => { this.setState({ tempSubject: _classAndSubject.subject_data, subjectDropDownAnchorEl: event.currentTarget, selectedIndexId: _index, }, () => this.setState({ selectSubjectDropDownDiv: true, })) }}>
                                    <Box style={{
                                        textAlign: "start",
                                        color: "#3f526d",
                                        fontSize: "1.125rem",
                                        fontFamily: "Open sans",

                                        border: _classAndSubject.subjectError ? "0.4px solid red" : "0.4px solid rgb(196 196 196)",
                                        display: "flex",
                                        justifyContent: 'space-between',
                                        padding: "0.9rem 0.5rem 0.9rem 1.875rem",
                                        borderRadius: 4,
                                        alignItems: "center",
                                        textTransform: 'capitalize'
                                    }}>
                                        {_classAndSubject?.subject_data?.length != 0 ?
                                            <Box className="fileNameText22" style={{ display: "flex", flexDirection: "row" }}>
                                                {
                                                    _classAndSubject.subject_data.map((data1: any, index: number) => {
                                                        console.log(_classAndSubject.class)

                                                        {
                                                            return (
                                                                this.state.subjectWithClassId.map((_class: any) => {
                                                                    if (_class.class == _classAndSubject.class) {
                                                                        return (_class.subjectList.filter((x: any) => x.id === data1)
                                                                            .map((x: any) => { return (index === _classAndSubject.subject_data.length - 1 ? x.attributes?.subject_name : ` ${x.attributes?.subject_name}, `) }))
                                                                    }

                                                                })
                                                            )
                                                        }
                                                    })}
                                            </Box>
                                            :
                                            <Typography style={{

                                                fontSize: "1.125rem",
                                                fontFamily: "Open sans",
                                                color: "rgb(177 185 196)",
                                                lineHeight: 'initial'

                                            }}>
                                                Select Subject
                                            </Typography>
                                        }
                                        <Box style={{ display: "flex" }}>
                                            <KeyboardArrowDownIcon />
                                        </Box>
                                    </Box>

                                    {_classAndSubject.subjectError && (
                                        <FormHelperText
                                            style={{ color: "red", margin: "3px 14px 0" }}
                                        >
                                            Please select subject.
                                        </FormHelperText>
                                    )}

                                </Grid>

                                {_index !== 0 && <img src={deleteMore} style={{


                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    marginLeft: '100%',
                                    top: '57%'
                                }}
                                    onClick={() => {
                                        // if(this.props.isEdit){
                                        //     this.setState({deletedclassesAndSubject:[...this.state.deletedclassesAndSubject ,this.state.classesAndSubject[_index] ]})
                                        // }
                                        let finalClass = this.state.finalSelectedClass.filter((_data: any, index: any) => {
                                            if (parseInt(_data) !== parseInt(_classAndSubject.class)) {
                                                return _data
                                            }
                                        })
                                        console.log(finalClass)
                                        let classData = this.state.classesAndSubject;
                                        classData.splice(_index, 1)
                                        this.setState({ classesAndSubject: classData, finalSelectedClass: finalClass })
                                    }}

                                />}
                            </Grid>

                        </Grid>)}
                        {this.state.classesAndSubject.length < this.state.classList.length &&
                            <Grid item xs={12} style={{ marginTop: '1rem' }}>
                                <img src={addMore} style={{
                                    border: 'solid 0.5px #7f6cfc',
                                    padding: '12px 14px',
                                    borderRadius: '6px',
                                    objectFit: 'contain',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => {
                                        let tempArr = this.state.classesAndSubject
                                        tempArr.push({ class: '', subject_data: [], classError: false, subjectError: false })
                                        this.setState({ classesAndSubject: tempArr }, () => {

                                        })
                                    }} />
                            </Grid>}
                        <Grid item xs={12} style={{ marginTop: '2rem' }}>
                            <Button
                                style={{

                                    padding: "8px 40.5px 8px 41.5px",
                                    borderRadius: 8,
                                }}
                                className={this.props.classes.button_color}
                                //  variant="contained"
                                onClick={(e: any) => {
                                    this.get_restriction(e)
                                }}
                            >
                                <Typography
                                    style={{
                                        textTransform: "none",
                                        fontSize: '1.125rem',
                                        fontFamily: "Open Sans",
                                        fontWeight: 600,
                                        color: "white",
                                    }}
                                >
                                    {this.props.isEdit ? "Update" : "Create User"}
                                </Typography>
                            </Button>
                            {this.props.isEdit && !this.props.isPending && <Button
                                style={{
                                    padding: "8px 20.5px 8px 21.5px",
                                    borderRadius: 8,
                                    marginLeft: 20
                                }}
                                onClick={() => {
                                    this.reset_student_password();
                                }}
                                className={this.props.classes.button_color_disabled}
                            >
                                <Typography
                                    style={{
                                        textTransform: "none",
                                        fontSize: '1.125rem',
                                        fontWeight: 600,
                                    }}

                                >
                                    Reset Password
                                </Typography>
                            </Button>}
                            <Button
                                style={{
                                    padding: '11px 24px 11px 25px',
                                    borderRadius: 6,
                                    marginLeft: 20
                                }}
                                className={this.props.classes.addStudentAndTeacher_classAndSection_normal_button_color}
                                onClick={() => {
                                    this.props.handleDialogClose()
                                }}
                            >
                                <Typography
                                    style={{
                                        textTransform: "none",
                                        fontSize: '1.125rem',
                                        fontWeight: 600,
                                    }}
                                >
                                    Cancel
                                </Typography>
                            </Button>
                        </Grid>

                    </Grid>
                </Dialog>
                <Popover
                    id="1"
                    anchorEl={this.state.classDropDownAnchorEl}
                    open={Boolean(this.state.classDropDownAnchorEl)}
                    onClose={() => {
                        this.setState({
                            classDropDownAnchorEl: null
                        })
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{
                        height: "auto"
                    }}
                    PaperProps={{
                        style: {
                            width: '30%',
                            maxWidth: '370px',
                            maxHeight: '70%',
                            padding: "20px 25px 30px 25px",
                            borderRadius: 8,
                            boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.2)",
                            backgroundColor: "#fff"
                        },
                    }}
                >
                    <Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            width="100%"
                            style={{
                                borderRadius: 8,
                                height: "40px",
                                //border: "solid 1px #af38cb",
                                position: "inherit",
                                top: "0%",
                                backgroundColor: '#f9f8fd'
                            }}
                        >
                            <Search style={{
                                height: 20,
                                width: 20,
                                marginRight: 20,
                                marginLeft: 15
                            }}
                                className={this.props.classes.icon_color}
                            />
                            <input
                                placeholder="Search class"

                                style={{
                                    border: "none",
                                    outline: "none",
                                    color: "#3f526d",
                                    opacity: 0.75,
                                    fontSize: "17px",
                                    width: "100%",
                                    backgroundColor: '#f9f8fd'
                                }}
                                onChange={
                                    ((event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ searchClass: event.target.value.trim() }, () => { })

                                    })}
                            />
                        </Box>
                        {this.state.classList
                            .filter((post: any) => {
                                console.log(this.state.finalSelectedClass.indexOf(
                                    post.id
                                ), parseInt(post.id))
                                if ((this.state.finalSelectedClass.indexOf(
                                    parseInt(post.id)
                                ) === -1 && this.state.finalSelectedClass.indexOf(
                                    post.id
                                ) === -1) || this.state.classesAndSubject[this.state.selectedIndexId]?.class ==
                                    parseInt(post.id)
                                ) {

                                    if (this.state.searchClass === '') {
                                        return post;
                                    } else if (post.attributes && post.attributes.display_name && post.attributes.display_name.toLowerCase().includes(this.state.searchClass.toLowerCase())) {
                                        return post;
                                    }
                                }
                            }).map((_data: any, index: any) => {
                                return (
                                    <Box
                                        style={{
                                            padding: "0px 0px 5px 0px",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            borderBottom: "solid 0.5px rgba(63, 82, 109,0.34)",
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            {

                                                let temp1: any = [];
                                                temp1 = this.state.tempClass;
                                                let temp2: any = temp1;
                                                // let length = temp1.length === 0 ? 0 : temp1.length -1
                                                console.log(temp1)
                                                if (this.state.tempClass.indexOf(
                                                    _data.id
                                                ) > -1 || this.state.tempClass.indexOf(
                                                    parseInt(_data.id)
                                                ) > -1) {

                                                    this.setState({
                                                        tempClass: ''
                                                    }, () => {

                                                    })

                                                }
                                                else {
                                                    this.setState({
                                                        tempClass: _data.id
                                                    }, () => {

                                                    })

                                                }


                                            }
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: 17,
                                                fontFamily: "Open sans",
                                                fontWeight: 600,
                                                color: "#3f526d",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",

                                            }}>
                                            {_data.attributes.display_name}
                                        </Typography>
                                        <Checkbox
                                            checked={this.state.tempClass.indexOf(
                                                _data.id
                                            ) > -1}
                                            size="small"
                                            style={{ backgroundColor: 'transparent' }}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            className={this.props.classes.icon_color}

                                        />
                                    </Box>)
                            })}

                        {this.state.classList
                            .filter((post: any) => {
                                console.log(this.state.finalSelectedClass.indexOf(
                                    post.id
                                ), parseInt(post.id))
                                if ((this.state.finalSelectedClass.indexOf(
                                    parseInt(post.id)
                                ) === -1 && this.state.finalSelectedClass.indexOf(
                                    post.id
                                ) === -1) || this.state.classesAndSubject[this.state.selectedIndexId]?.class ==
                                    parseInt(post.id)
                                ) {

                                    if (this.state.searchClass === '') {
                                        return post;
                                    } else if (post.attributes && post.attributes.display_name && post.attributes.display_name.toLowerCase().includes(this.state.searchClass.toLowerCase())) {
                                        return post;
                                    }
                                }
                            }).length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>  <Typography
                                style={{
                                    fontSize: 16,
                                    fontFamily: "Open sans",
                                    fontWeight: 600,
                                    color: "#3f526d",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",

                                }}>No Data Found.</Typography> </div> : ''}

                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                marginTop: 30,
                                alignItems: "center",
                            }}
                        >
                            <Button
                                style={{
                                    borderRadius: '6px',
                                    width: "30%",
                                    height: 30,
                                    textTransform: "capitalize",
                                    fontSize: "17px",
                                    marginRight: 12,
                                }}
                                className={this.props.classes.button_color}
                                onClick={() => {
                                    let tempArr = this.state.classesAndSubject
                                    if (this.state.tempClass == tempArr[this.state.selectedIndexId].class) {
                                        this.setState({

                                            classDropDownAnchorEl: null,
                                            searchClass: '',



                                        }, () => {

                                            this.setState({ tempClass: [], selectedClassId: this.state.classesAndSubject[this.state.selectedIndexId].class, selectedIndexId: '', }, () => {
                                                //this.get_subject(this.state.selectedClassId)
                                            })
                                        })
                                    }
                                    else {
                                        let arr = this.state.subjectWithClassId
                                        let arr2 = arr
                                            .filter((post: any) => {

                                                if ((this.state.tempClass.indexOf(
                                                    parseInt(post.class)
                                                ) > -1 || this.state.tempClass.indexOf(
                                                    post.class
                                                ) > -1)
                                                ) {

                                                    return post
                                                }
                                            })
                                        if (arr2.length === 0) {
                                            arr.push({ class: this.state.tempClass, subjectList: [], classError: false, subjectError: false })
                                        }
                                        tempArr[this.state.selectedIndexId].class = this.state.tempClass
                                        tempArr[this.state.selectedIndexId].subject_data = []
                                        if (this.state.tempClass.length !== 0) {
                                            tempArr[this.state.selectedIndexId].classError = false
                                        }
                                        this.setState({
                                            classs: tempArr,
                                            classDropDownAnchorEl: null,
                                            searchClass: '',
                                            subjectWithClassId: arr



                                        }, () => {

                                            this.setState({ tempClass: [], selectedClassId: this.state.classesAndSubject[this.state.selectedIndexId].class, selectedIndexId: '', }, () => {
                                                let arr: any[] = []

                                                this.state.classesAndSubject.map((_data: any) => {
                                                    arr.push(_data.class)

                                                })
                                                this.setState({ finalSelectedClass: arr })
                                                this.get_subject(this.state.selectedClassId)
                                            })
                                        })

                                    }

                                }}
                            >
                                Save
                            </Button>
                            <Button
                                style={{
                                    borderRadius: 6,
                                    fontSize: '17px',
                                    fontWeight: 600,
                                    textTransform: "none",
                                    marginLeft: "5px",
                                    backgroundColor: '#fff',
                                    width: '30%',
                                    height: 30
                                }}
                                className={this.props.classes.button_color_disabled}
                                onClick={() => {
                                    this.setState({
                                        classDropDownAnchorEl: null,
                                        tempClass: [],
                                        searchClass: '',
                                        selectedIndexId: ''
                                    })
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Popover>



                <Popover
                    id="1"
                    anchorEl={this.state.subjectDropDownAnchorEl}
                    open={Boolean(this.state.subjectDropDownAnchorEl)}
                    onClose={() => {
                        this.setState({
                            subjectDropDownAnchorEl: null
                        })
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{
                        height: "auto"
                    }}
                    PaperProps={{
                        style: {
                            width: '30%',
                            maxWidth: '370px',
                            maxHeight: '70%',
                            padding: "20px 25px 30px 25px",
                            borderRadius: 8,
                            boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.2)",
                            backgroundColor: "#fff"
                        },
                    }}
                >
                    <Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            width="100%"
                            style={{
                                borderRadius: 8,
                                height: "40px",
                                position: "inherit",
                                top: "0%",
                                backgroundColor: '#f9f8fd'
                            }}
                        >
                            <Search style={{
                                height: 20,
                                width: 20,
                                marginRight: 20,
                                marginLeft: 15
                            }}
                                className={this.props.classes.icon_color}
                            />
                            <input
                                placeholder="Search subject"

                                style={{
                                    border: "none",
                                    outline: "none",
                                    color: "#3f526d",
                                    opacity: 0.75,
                                    fontSize: "17px",
                                    width: "100%",
                                    backgroundColor: '#f9f8fd',
                                    fontStyle: 'Open sans'
                                }}
                                onChange={
                                    ((event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ searchSubject: event.target.value.trim() }, () => { })

                                    })}
                            />
                        </Box>

                        {this.state.subjectWithClassId
                            .map((_data1: any) => {
                                if (_data1.class == this.state.classesAndSubject[this.state.selectedIndexId]?.class) {
                                    return (_data1.subjectList
                                        .filter((post: any) => {
                                            console.log(post)
                                            if (this.state.searchSubject === '') {
                                                return post;
                                            } else if (post.attributes && post?.attributes?.subject_name && post?.attributes?.subject_name.toLowerCase().includes(this.state.searchSubject.toLowerCase())) {
                                                return post;
                                            }
                                        })
                                        .map((_data: any, index: any) => {

                                            return (
                                                <Box
                                                    style={{
                                                        padding: "0px 0px 5px 0px",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        borderBottom: "solid 0.5px rgba(63, 82, 109,0.34)",
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        {
                                                            if (this.state.tempSubject.indexOf(
                                                                _data.id
                                                            ) > -1 || this.state.tempSubject.indexOf(
                                                                parseInt(_data.id)
                                                            ) > -1) {
                                                                var array: any[] = this.state.tempSubject.filter((item: any) => {
                                                                    if (item !== _data.id && item !== parseInt(_data.id)) {
                                                                        return item
                                                                    }
                                                                }
                                                                );
                                                                this.setState({ tempSubject: array }, () => { })
                                                            }
                                                            else {
                                                                this.setState({ tempSubject: [...this.state.tempSubject, _data.id] }, () => { })
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: 17,
                                                            fontWeight: 600,
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                        className={this.props.classes.bodyText_font}
                                                    >
                                                        {_data.attributes.subject_name}
                                                    </Typography>
                                                    <Checkbox
                                                        checked={this.state.tempSubject.indexOf(
                                                            _data.id
                                                        ) > -1}
                                                        size="small"
                                                        style={{ backgroundColor: 'transparent' }}
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        className={this.props.classes.icon_color}
                                                    />
                                                </Box>)
                                        }))
                                }
                            })}
                        {this.state.subjectWithClassId
                            .map((_data1: any) => {
                                if (_data1.class == this.state.classesAndSubject[this.state.selectedIndexId]?.class) {

                                    return (_data1.subjectList


                                        .filter((post: any) => {
                                            console.log(post)
                                            if (this.state.searchSubject === '') {
                                                return post;
                                            } else if (post.attributes && post?.attributes?.subject_name && post?.attributes?.subject_name.toLowerCase().includes(this.state.searchSubject.toLowerCase())) {
                                                return post;
                                            }
                                        })).length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>  <Typography
                                            style={{
                                                fontSize: 16,
                                                fontFamily: "Open sans",
                                                fontWeight: 600,
                                                color: "#3f526d",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",

                                            }}>No Data Found.</Typography> </div> : ''
                                }
                            })
                        }


                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                marginTop: 30,
                                alignItems: "center",
                            }}
                        >
                            <Button
                                style={{
                                    borderRadius: '6px',
                                    width: "30%",
                                    height: 30,
                                    textTransform: "capitalize",
                                    fontSize: "17px",
                                    marginRight: 12,
                                }}
                                className={this.props.classes.button_color}
                                onClick={() => {
                                    let tempArr = this.state.classesAndSubject
                                    tempArr[this.state.selectedIndexId].subject_data = this.state.tempSubject
                                    if (this.state.tempSubject.length !== 0) {
                                        tempArr[this.state.selectedIndexId].subjectError = false
                                    }
                                    this.setState({
                                        subject: tempArr,
                                        subjectDropDownAnchorEl: null,
                                        searchSubject: '',
                                        selectedIndexId: ''


                                    }, () => {
                                        this.setState({ tempSubject: [] })
                                    })
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                style={{
                                    borderRadius: 6,
                                    fontSize: '17px',
                                    fontWeight: 600,
                                    textTransform: "none",
                                    marginLeft: "5px",
                                    width: '30%',
                                    height: 30
                                }}
                                className={this.props.classes.button_color_disabled}
                                onClick={() => {
                                    this.setState({
                                        subjectDropDownAnchorEl: null,
                                        tempSubject: [],
                                        searchSubject: '',
                                        selectedIndexId: ''

                                    })
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Popover>

                {this.state.teacherRestircition && <CsvError csvError={this.state.teacherRestircitionError} alertModal={this.state.teacherRestircition} teacherRestircition={this.state.teacherRestircition} onCloseModal={() => this.setState({ teacherRestircition: false, teacherRestircitionError: [] })} />}

            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(AddTeacher);
 // Customizable Area End
