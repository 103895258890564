// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  classesDetails: any;
  loadingSpinner: boolean;
  boardName: any;
  alertModal: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class GradesAndLevelsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postLoginApiId: any;
  postPresignID: any;
  emailReg: RegExp;
  getGradesApiId: any;
  submitGradesApiId: any;
  getSchoolApiId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      loadingSpinner: false,
      classesDetails: [],
      boardName: '',
      alertModal: false
    };
    this.emailReg = new RegExp("");
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  async componentDidMount() {
    // Customizable Area Start
    this.getGrades()
    this.getSchoolDetails()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getGradesApiId) {
        this.setState({ loadingSpinner: false })
        if (responseJson != null) {
          if (!responseJson.errors) {
            const arr: any = [];
            responseJson.data.map((_data: any) => {
              let data: any = {
                id: _data.id,
                className: _data.attributes.name,
                onSchool: false,
                showClassName: '',
                error: false

              }
              arr.push(data)
            })
            this.setState({ classesDetails: arr, loadingSpinner: false })

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.submitGradesApiId) {
        this.setState({ loadingSpinner: false })
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ loadingSpinner: false })
            this.props.navigation.navigate("Subjects")
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.getSchoolApiId) {
        this.setState({ loadingSpinner: false })
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ boardName: responseJson.data.attributes.board?.name, loadingSpinner: false })

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }


    }
    // Customizable Area End
  }

  // Customizable Area Start
  getSchoolDetails = () => {
    this.setState({ loadingSpinner: true })
    const id = localStorage.getItem("schoolId")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSchoolApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.schoolDetailsEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getGrades = () => {
    this.setState({ loadingSpinner: true })
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: localStorage.getItem('schoolId')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGradesApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllGradesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goNext = (event: any) => {

    event.preventDefault();
    let apicall: boolean = true;
    const arr: any = this.state.classesDetails
    let count: number = 0
    arr.map((_data: any, index: any) => {
      if (_data.onSchool && _data.showClassName.trim() === "") {
        _data.error = true;
        apicall = false;
      }
      if (_data.onSchool) {
        count++

      }
    })

    this.setState({ classesDetails: arr })
    arr.map((_data: any, index: any) => {
      if (_data.onSchool && _data.showClassName.trim() === "") {
        return false
      }
    })
    if (count === 0) {

      this.setState({ alertModal: true })
    }
    if (apicall && count !== 0) {
      const gradedata: any = []
      arr.map((_data: any, index: any) => {
        if (_data.onSchool) {
          gradedata.push({
            "grade_name": _data.showClassName,
            "grade_id": _data.id
          })
        }
      })

      const data = {
        "school_id": localStorage.getItem("schoolId"),
        "grades": gradedata
      }

      this.setState({ loadingSpinner: true })
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.submitGradesApiId = requestMessage.messageId;

      const httpBody = data;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postGradesEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.instituteURL
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  // Customizable Area End
}
