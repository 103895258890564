// Customizable Area Start
import React from "react"
import {
    Box,
    Button,
    withStyles,
    Typography,
    Modal,
    Backdrop,
    IconButton,
    Grid,
    Link
} from "@material-ui/core";
import ErrorCSVController, { Props } from "./ErrorCSVController.web";
import { errorCSVdelete, errorCSVDeleteFile, errorCSVdownload } from '../assets'
import Spinner from "../../../shared/SideBar/src/Spinner";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class ErrorCSV extends ErrorCSVController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { errorCSVData, isLoader } = this.state
        return (
            <Box>
                {isLoader &&
                    <Spinner spinnerModal={isLoader} />}
                <Box className="error_csv_section">
                    <Typography style={{
                        fontSize: 14,
                        fontWeight: 600,
                        color: '#3f526d',
                        fontFamily: 'Open sans',
                        marginBottom: 35
                    }}
                    >Errors occurred:
                    </Typography>

                    {errorCSVData.length != 0 && errorCSVData.map((csvData: any) => (
                        <Box style={{ marginBottom: 20 }}>
                            <Grid container style={{ alignItems: 'center' }}>
                                <Grid item xs={5} md={8} lg={8} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                                    <img src={errorCSVdelete} style={{ width: 16, height: 16, marginRight: 10 }} />
                                    <Typography style={{
                                        fontSize: 12,
                                        fontWeight: 600,
                                        color: '#3f526d',
                                        fontFamily: 'Open sans',
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                    }}>{csvData?.attributes?.attachments?.data[0]?.attributes?.file_name}</Typography>
                                </Grid>
                                <Grid item xs={5} md={3} lg={3} style={{ textAlign: 'center' }}>
                                    <Typography style={{
                                        fontSize: 12,
                                        fontWeight: 600,
                                        color: '#3f526d',
                                        fontFamily: 'Open sans',
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                    }}>{moment(csvData?.attributes?.updated_at).format("DD.MM.YYYY, HH:mm")}</Typography>
                                </Grid>
                                <Grid item xs={2} md={1} lg={1}>
                                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'end' }}>
                                        <Link
                                            onClick={this.downloadIconHandler}
                                            href={csvData?.attributes?.attachments?.data[0]?.attributes?.url}
                                            download
                                        >
                                            <img src={errorCSVdownload} style={{ width: 12, height: 12, marginRight: 14 }} />
                                        </Link>
                                        <img src={errorCSVDeleteFile} style={{ width: 12, height: 12, cursor: 'pointer' }}
                                            onClick={() => this.deleteCsvFile(csvData.id)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                </Box>
            </Box>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
export default (ErrorCSV);
// Customizable Area End