// Customizable Area Start
import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";
import ShareTaskController, { Props } from "./ShareTaskController.web";
import "./ShareTask.web.css";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class ShareTask extends ShareTaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <Modal className="shareTaskModal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.props.unitModal}
          onClose={this.modalhandleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={this.props.unitModal} style={{ border: "none" }}>
            <div className="shareTaskPopup">
              {this.state.showSpinner && (
                <Spinner spinnerModal={this.state.showSpinner} />
              )}
              <form className="shareTaskForm"
                onSubmit={(event: any) => {
                  if (this.props.isShareChanges) {
                    this.editTask(event)
                  }
                  else {
                    this.modalhandleClose()
                  }
                }}
              >
                <Box className="shareTaskBox">
                  <Typography className={`shareTaskTitle ${this.props.classes.title_font}`}>
                    Share Task
                  </Typography>
                </Box>
                <Box>
                  <Grid className="InnerPad" container spacing={2}>
                    <Grid item xs={12}>
                      <Box className="shareWithBox">
                        <Typography className={`shareWithTitle ${this.props.classes.subTitle_font}`}>
                          Share With
                        </Typography>
                        <Box className="teacherStudentBox">
                          <Button variant="contained" className={`shareWithTeacherBtn ${this.props.sharewithteacher.length !== 0 ? this.props.classes.button_color : this.props.classes.button_color_disabled}`}
                            onClick={() => {
                              this.setState({ userTypeIsTeacher: true, openShareModal: true }, () => {
                                this.props.openSelectUserModal(this.state.openShareModal, this.state.userTypeIsTeacher)
                              })
                            }}>
                            Teacher(s)
                          </Button>

                          <Button variant="contained" className={`shareWithStudentBtn ${this.props.sharewithstudent.length !== 0 ? this.props.classes.button_color : this.props.classes.button_color_disabled}`}
                            onClick={() => {
                              this.setState({ userTypeIsTeacher: false, openShareModal: true }, () => {
                                this.props.openSelectUserModal(this.state.openShareModal, this.state.userTypeIsTeacher)
                              })
                            }}
                          >
                            Student(s)
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box className="shareBtnBox">
                  <Button variant="contained" type="submit" className={`shareButton ${this.props.classes.button_color}`}>
                    Share
                  </Button>
                  <Button className="shareCancelBtn" variant="contained" onClick={this.modalhandleClose}>
                    Cancel
                  </Button>
                </Box>
              </form>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(ShareTask);
// Customizable Area End