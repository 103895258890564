import React from "react";
// Customizable Area Start
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    Typography,
    Grid,
    NativeSelect,
    InputBase
} from "@material-ui/core";
import {
    Search,
    ArrowDropDown
} from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import './AdminBadges.web.css';
import Backdrop from '@material-ui/core/Backdrop';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme: any) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
        width: "100%"
    },
    input: {
        borderRadius: 8,
        position: "relative",
        border: `solid 1px ${theme.props.buttonColor}`,
        backgroundColor: "#fff",
        padding: "14px 22px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        fontSize: 18,
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase);
// Customizable Area End

interface Props {
    // Customizable Area Start
    openAssignBadgeModal: any;
    modalHandleClose: any;
    userData: any;
    gradesList: any;
    selectedGrade: any;
    queryTerm: any;
    selectAll: any;
    memberNumber: any;
    isSelected: any;
    handleCheckboxClick: any;
    assignBadgeClick: any;
    onChangeRadioGroup: any;
    handleGradeChange: any;
    handleSearchChange: any;
    onChangeSelectAll: any;
    onChangeCheckBox: any;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
const WhiteRadio = withStyles({
    root: {
        color: "#000",
        '&$checked': {
            color: "#7f6cfc",
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);
// Customizable Area End

// Customizable Area Start
export const AssignBadgesModal = ({
    openAssignBadgeModal,
    modalHandleClose,
    userData,
    gradesList,
    selectedGrade,
    queryTerm,
    selectAll,
    isSelected,
    memberNumber,
    handleCheckboxClick,
    assignBadgeClick,
    onChangeRadioGroup,
    handleGradeChange,
    handleSearchChange,
    onChangeSelectAll,
    onChangeCheckBox,
    classes,
}: Props) => {
    const filteredData = userData.filter((member: any) => {
        const fullName = `${member?.attributes?.first_name} ${member?.attributes?.last_name}`
        if (queryTerm === '') {
            return member;
        } else if (member.attributes && (member.attributes.first_name && member.attributes.first_name.toLowerCase().includes(queryTerm.toLowerCase()) ||
            member.attributes.last_name && member.attributes.last_name.toLowerCase().includes(queryTerm.toLowerCase()) ||
            fullName?.toLowerCase().includes(queryTerm.toLowerCase()))) {
            return member;
        }
    })
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="AssignBadgeModal"
                open={openAssignBadgeModal}
                onClose={modalHandleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableAutoFocus={true}
                id="admin-assign-badge-modal"
            >
                <div className="assign-badge-modal">
                    <RadioGroup aria-label="position" name="position"
                        defaultValue="teacher"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeRadioGroup(event)}
                        data-test-id="assign-badge-radio-grp"
                    >
                        <Grid className="BadgeMemberGrid">
                            <Typography className={`BadgeSelectMembers ${classes.heading_font}`}>
                                Select members
                            </Typography>

                            <Box
                                display="flex"
                                alignItems="center"
                                className="BadgeSearchMemberBox"
                            >
                                <Search className="BadgeSearchMember" />

                                <input
                                    placeholder="Search for a participants, teacher, student, member…"
                                    className="BadgeSearchMemberInput"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(event)}
                                    data-test-id="assign-badge-search"
                                />
                            </Box>

                            <Grid container className="assign-badge-detail-div">
                                <Grid item xs={12} sm={6}>
                                    <NativeSelect
                                        id="select"
                                        value={selectedGrade ? selectedGrade : ""}
                                        input={<BootstrapInput />}
                                        IconComponent={ArrowDropDown}
                                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleGradeChange(event)}
                                        data-test-id="assign-badge-grade-select"
                                    >
                                        <option value="" disabled>Select Grade</option>
                                        {
                                            gradesList.length != 0 && gradesList.map((grade: any) => (
                                                <option key={grade?.id} value={grade?.id} >{grade?.attributes?.school_grade_name}</option>
                                            ))
                                        }
                                    </NativeSelect>
                                </Grid>
                                <Grid item xs={12} sm={6} className="assign-badge-radio-div">
                                    <Box className="Radio-btn BadgeRadioBox">
                                        <WhiteRadio
                                            value="teacher"
                                            className="BadgeRadio"
                                            name="radio-button-demo"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <Typography className={`TeachersText ${classes.subHeading_font}`}>
                                            Teachers
                                        </Typography>
                                    </Box>
                                    <Box className="BadgeRadioBox">
                                        <WhiteRadio
                                            value="student"
                                            className="BadgeRadio"
                                            name="radio-button-demo"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <Typography className={`TeachersText ${classes.subHeading_font}`}>
                                            Student
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            {userData?.length > 0 ?
                                <div className="UserDataDiv">
                                    {filteredData?.length > 0 ?
                                        <>
                                            {filteredData?.map((data: any) => {
                                                const isSelectedBoolean = isSelected(data.id);
                                                return (
                                                    <div key={data.id} className="UserMainDiv">
                                                        <Grid className="UserDetailsDiv">
                                                            <div className="DisplayFlex">
                                                                <Avatar src={data.attributes.avatar ? data.attributes.avatar : "/"} variant="rounded" className="user-avatar" />

                                                                <div className="FlexColumn">
                                                                    <div className={`UserNameText ${classes.subHeading_font}`}>
                                                                        {data.attributes.first_name} {data.attributes.last_name}
                                                                    </div>
                                                                    <div className={`UserEmailText ${classes.subHeading_font}`}>
                                                                        {data.attributes.email}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Checkbox
                                                                    className="AssignUserCheck"
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                    onClick={event =>
                                                                        handleCheckboxClick(event, data.id, data.attributes.first_name)
                                                                    }
                                                                    checked={isSelectedBoolean || selectAll}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeCheckBox(event)}
                                                                    data-test-id="assign-user-check"
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </div>
                                                )
                                            })}
                                        </>
                                        :
                                        <span className={`${classes.subTitle_font} no-record-text`}>
                                            No record(s) found.
                                        </span>
                                    }
                                </div>
                                :
                                <span className={`${classes.subTitle_font} no-record-text`}>
                                    No record(s) found.
                                </span>
                            }

                            <Box className="AssignBadgeBtnBox">
                                <div className="DisplayFlex">
                                    <Button variant="contained" className={`${classes.AssignBadgesModal_Feedback_button_color} assign-badge-btn-modal`}
                                        onClick={assignBadgeClick}
                                        disabled={!memberNumber}
                                    >
                                        Assign
                                    </Button>

                                    <Button variant="contained" className={`assign-badge-btn-modal CancelAssignBtn`}
                                        onClick={modalHandleClose}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div className="FlexAlignCenter">
                                    <Box className="FlexAlignCenter">
                                        <Typography className={`${classes.subHeading_font} SelectAllText`}
                                            onClick={onChangeSelectAll}
                                        >
                                            Select all
                                        </Typography>
                                    </Box>
                                    <Typography className={`SelectedText ${classes.subHeading_font}`}>
                                        {memberNumber}/{userData.length} selected
                                    </Typography>
                                </div>
                            </Box>
                        </Grid>
                    </RadioGroup>

                </div>

            </Modal>
        </>
    )
}
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(AssignBadgesModal);
// Customizable Area End