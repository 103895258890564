// Customizable Area Start
import React, { useEffect, useState } from "react"
import {
    Box,
    Button,
    Typography,
    Modal,
    Backdrop,
    Grid,
    IconButton,
    Checkbox,
    Avatar
} from "@material-ui/core"
import {
    makeStyles,
    createStyles,
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Search } from "@material-ui/icons";
import "./ShareModalPopup.web.css";
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: any) =>
    createStyles({
        BoxStyle: {
            width: '100%',
            height: '58px',
            padding: '0px 15px',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            background: "#f9f8fd"
        },
        SectionHeader: {
            width: '100%',
            height: '58px',
            padding: '0px 15px',
            borderRadius: '6px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: "#f9f8fd",
            "& .MuiAccordionSummary-content": {
                justifyContent: 'space-between',
                alignItems: 'center'
            }
        },
        AccordianHeader: {
            boxShadow: 'none',
            "& .MuiIconButton-edgeEnd": {
                margin: "0px",
                padding: "4px"

            }
        },
        DoneBtn: {
            background: theme.props.buttonColor,
            color: "#fff",
            padding: "12px 32px 12px 32px",
            height: 48,
            margin: "0px 4px",
            '&:hover': {
                background: theme.props.buttonColor,
            }
        },
        CancelBtn: {
            padding: "12px 32px 12px 32px",
            height: 48,
            margin: "0px 4px",
            color: theme.props.buttonColor,

        },
        title_font: {
            color: theme.props.titleColor,
            fontFamily: theme.props.titleFont
        },
        heading_font: {
            color: theme.props.headingColor,
            fontFamily: theme.props.headingFont
        },
        selectall_font: {
            color: theme.props.mainColor,
            fontFamily: theme.props.headingFont
        },
        subHeading_font: {
            color: theme.props.subheadingColor,
            fontFamily: theme.props.subheadingFont
        },
        bodyText_font: {
            color: theme.props.textColor,
            fontFamily: theme.props.textFont
        },
        icon_color: {
            color: theme.props.iconColor
        },
        checkbox: {
            color: theme.props.iconColor,
            background: 'transparent'
        }
    })
);
// Customizable Area End

interface IProps {
    // Customizable Area Start
    openModal: any;
    closeModalFun: React.Dispatch<React.SetStateAction<any>>;
    usersList?: any;
    saveSelectedUsers: (data: any[]) => void
    isTeacher?: boolean;
    filterUser: any;
    // Customizable Area End
}

export const ShareModalPopup: React.FC<IProps> = ({
    // Customizable Area Start
    openModal,
    closeModalFun,
    usersList,
    saveSelectedUsers,
    isTeacher,
    filterUser
    // Customizable Area End
}) => {
    // Customizable Area Start
    const classes = useStyles()
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedUsersId, setSelectedUsersId] = useState<any>(filterUser)
    const [selectedUsers, setSelectedUsers] = useState<any>([])
    const [isAllSelect, setIsAllSelect] = useState<boolean>(false)

    useEffect(() => {
        setSelectedUsers(usersList)
    }, [usersList])

    const reset = () => {
        setSelectedUsers([])
        setSearchTerm('')
    }
    const handleToggle = (value: any) => () => {
        const val = parseInt(value)
        const currentIndex = selectedUsersId.indexOf(val);
        const newChecked = [...selectedUsersId];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        if (newChecked.length === selectedUsers.length) {
            setIsAllSelect(true)
        }
        else {
            setIsAllSelect(false)
        }
        setSelectedUsersId(newChecked);
    };

    const filterUsersList =
        searchTerm == '' ? selectedUsers :
            selectedUsers?.filter((data: any) =>
                data?.attributes?.first_name?.toLowerCase()?.includes(searchTerm.trim().toLowerCase()) ||
                data?.attributes?.last_name?.toLowerCase()?.includes(searchTerm.trim().toLowerCase()) ||
                `${data?.attributes?.first_name} ${data?.attributes?.last_name}`.toLowerCase()?.includes(searchTerm.trim().toLowerCase()) ||
                `${data?.attributes?.last_name} ${data?.attributes?.first_name}`.toLowerCase()?.includes(searchTerm.trim().toLowerCase()) ||
                data?.attributes?.email?.toLowerCase()?.includes(searchTerm.trim().toLowerCase())
            )

    // Customizable Area End
    // Customizable Area Start
    return (
        <>
            <Modal className="shareModalPopup"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={closeModalFun}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className="addtaskSharePopup">
                    <Grid item xs={12} className="p20">
                        <Grid container spacing={0}>
                            <div className="closeIconBox">
                                <IconButton onClick={closeModalFun} className="closeIcon">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <Grid item xs={12} className={"parentGrid"}>
                                <Grid container className="mtb4">
                                    <Grid item xs={12} className="addTeacherGrid">
                                        <Typography component={"h2"} className={`addTeacherTitle ${classes.title_font}`}>
                                            {isTeacher ? "Add Teacher(s)" : "Add Student(s)"}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12}>
                                        <Box display="flex" alignItems="center" className={classes.BoxStyle}>
                                            <Search className={`iconSize ${classes.icon_color}`} />
                                            <input className={`searchParticipantsInput ${classes.bodyText_font}`}
                                                placeholder="Search for a participants"
                                                value={searchTerm}
                                                onChange={
                                                    ((event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setSearchTerm(event.target.value)
                                                    })}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container className="teacherListGrid">
                                    <Grid className="teacherListGridColoumn" xs={12}>
                                        <Typography className={`teacherTitle ${classes.heading_font}`}>
                                            {isTeacher ? "Teacher List" : "Student List"}
                                        </Typography>
                                        {filterUsersList?.length !== 0 && <Typography className={`selectAllTitle ${classes.selectall_font}`}
                                            onClick={() => {
                                                if (selectedUsersId.length == selectedUsers.length) {
                                                    setIsAllSelect(false)
                                                    setSelectedUsersId((preState: any) =>
                                                        []
                                                    )
                                                }
                                                else {
                                                    let tempSelected: any = []
                                                    filterUsersList.map((_data: any) => {
                                                        tempSelected.push(parseInt(_data.id))
                                                    })
                                                    if (filterUsersList.length == tempSelected.length) {
                                                        setIsAllSelect(true)
                                                    }
                                                    setSelectedUsersId((preState: any) => tempSelected)
                                                }
                                            }}>
                                            {isAllSelect ? <>Deselect All</> : <>Select All</>}
                                        </Typography>}
                                    </Grid>
                                    <Grid xs={12} className="addStudentGridlist">
                                        {
                                            filterUsersList?.map((student: any) => {
                                                return (
                                                    <Grid key={student.id} item xs={12}>
                                                        <Grid container spacing={3} className="addGridName" onClick={handleToggle(parseInt(student.id))}>
                                                            <Grid item xs={2} className="avtarGrid">
                                                                <Avatar className="userAvtar" src={student?.attributes?.avatar} />
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <Typography className={`studentFLname ${classes.subHeading_font}`}>
                                                                    {student?.attributes?.first_name + ' ' + student?.attributes?.last_name}
                                                                </Typography>
                                                                <Typography className={`studentEmail ${classes.bodyText_font}`}>
                                                                    {student?.attributes?.email}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid className="justifyEnd" item xs={2}>
                                                                <Checkbox
                                                                    checked={selectedUsersId.indexOf(student?.id) > -1 || selectedUsersId.indexOf(parseInt(student?.id)) > -1}
                                                                    className={classes.checkbox}
                                                                    inputProps={{
                                                                        "aria-labelledby":
                                                                            "checkbox-list-secondary-label-question",
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                        {
                                            filterUsersList?.length == 0 &&
                                            <div className="noDataBox">
                                                <Typography className={`noDataText ${classes.bodyText_font}`}>
                                                    No Data Found.
                                                </Typography>
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4} className="mtb4">
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center">
                                            <Button className={classes.DoneBtn} onClick={(event: any) => { saveSelectedUsers(selectedUsersId) }}> Done </Button>
                                            <Button className={classes.CancelBtn} onClick={closeModalFun}> Cancel </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </>
    )
    // Customizable Area End
}

// Customizable Area Start
export default ShareModalPopup
// Customizable Area End
