// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { List, ListItem,FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Grid ,Typography} from '@material-ui/core';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
}),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    }
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

function createData(title: string, term: number, term2: number, term3: number) {
  return { title, term, term2, term3 };
}

const rows = [
  createData('Total Attendence of the learner', 20, 10, 5),
  createData('Total Number of working days', 20, 10, 4.0)
];


function ReportCardTwoCoScholastic(props:any) {
  const classes = useStyles();
  const [selectclass, setSelectclass] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date('2014-08-18T21:11:54'),
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectclass(event.target.value as string);
  };
  
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  return (
    <div>
      <div className='profileTitle'>
      <Button className="backBtn"><KeyboardBackspaceOutlinedIcon /> Back</Button>
      <h2 className={props.classes.heading_font}>Part 1 - Assessment of Learning </h2>
      <Button className="editBtn">Next <KeyboardBackspaceOutlinedIcon /></Button>
      </div>
      <div className="tableContent">
      <Typography className={props.classes.subHeading_font} align="left" style={{ paddingLeft: "40px" }}>3 Point Scale</Typography>
      <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                             <TableCell align="center" className={props.classes.bodyText_font} style={{borderRadius: "0px" }}>
                                Co-Scholastic Activities
                            </TableCell>
                             <TableCell align="center"className={props.classes.bodyText_font} style={{borderRadius: "0px" }}>
                                Term 1 (Grade)
                            </TableCell>
                             <TableCell align="center" className={props.classes.bodyText_font} style={{borderRadius: "0px" }}>
                                Term 2 (Grade)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                                Handwork Work / Pre-vocational Education
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                                Art Education
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                                Health & Physical Education
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            {/* table 1 end */}
            {/*  table 2 start */}
            <Typography  className={props.classes.subHeading_font} align="left" style={{ paddingLeft: "40px", paddingTop: "30px" }}>Part 3 Discipline (3 Point Scale)</Typography>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                             <TableCell align="center" className={props.classes.bodyText_font} style={{borderRadius: "0px" }}>
                                Discipline
                            </TableCell>
                             <TableCell align="center" className={props.classes.bodyText_font} style={{borderRadius: "0px" }}>
                                Term 1 (Grade)
                            </TableCell>
                             <TableCell align="center" className={props.classes.bodyText_font} style={{borderRadius: "0px" }}>
                                Term 2 (Grade)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoCoScholastic_classAndSection_tabBodyCel} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
      </div>
    </div>
  )
}
// Customizable Area End


// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(ReportCardTwoCoScholastic as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End