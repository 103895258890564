// Customizable Area Start
export const ic_calendar = require("../assets/019-calendar.png");
export const ic_computer = require("../assets/030-computer.png");
export const ic_pie_chart = require("../assets/032-pie-chart.png");
export const ic_suitcase = require("../assets/051-suitcase.png");
export const ic_support = require("../assets/063-support.png");
export const ic_book = require("../assets/067-download.png");
export const ic_messages = require("../assets/messages.png");
export const ic_userIcon = require("../assets/userIcon.png");
export const ic_edit = require("../assets/group-29.png");
export const ic_back = require("../assets/group-39.png");
export const ic_homework = require("../assets/homework-4.png");
export const ic_presentation = require("../assets/presentation.png");
export const ic_zoom = require("../assets/zoom-icon.png");
export const ic_clock = require("../assets/clock-1.png");
export const ic_graduate = require("../assets/graduate-1.png");
export const ic_oval = require("../assets/oval.png");
export const logo = require("../assets/fill-158.png");
export const ic_message = require("../assets/group-2.png");
export const book = require("../assets/group-20.png");
export const knowledge = require("../assets/knowledge.png");
export const note = require("../assets/note.png");
export const menu = require("../assets/menu.png");
export const student_default_profile_pic = require("../assets/student-default-profile-pic.png")
export const country_icon = require("../assets/country_icon.png")
export const support_icon = require("../assets/support_icon.png")
export const notification_icon = require("../assets/notification_icon.png")
export const img_font_icon = require("../assets/image-font_icon.png")
export const refresh_icon = require("../assets/image-refresh.png")
// Customizable Area End