// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

const audioType = 'audio/*';

declare var MediaRecorder: any;

export const configJSON = require("../config");
// Customizable Area End
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    error: string;
    mediaStream: any;
    isRecording: boolean;
    canvasRef: any;
    status: string;
    time: any;
    miliseconds: number;
    medianotFound: boolean;
    audios: any;
    audioBlob: any;
    // Customizable Area End
}
export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    renderedStreamDuration: any;
    handleCountDown: any;
    mimeTypeToUseWhenRecording: string;
    handleAudioStop: any;
    handleReset: any;
    // Customizable Area End
}
export default class WebNativeAudioRecorderController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    timer: any;
    chunks: any;
    mediaRecorder: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            error: '',
            mediaStream: null,
            isRecording: false,
            canvasRef: React.createRef(),
            status: 'idle',
            time: {},
            miliseconds: 0,
            medianotFound: false,
            audios: [],
            audioBlob: null,
        }

        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        // Customizable Area End

    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.checkMicrophonePermission();
        this.drawEmptyStream();
    }

    drawEmptyStream = () => {
        const canvas: any = this.state.canvasRef.current;
        if (canvas.getContext) {
            const ctx = canvas.getContext('2d');
            this.drawLine(ctx, [0, 75], [canvas.width, 75], 2);
        }
    }

    drawLine = (ctx: any, begin: any, end: any, width = 2) => {
        var grad = ctx.createLinearGradient(0, 60, 1, 120);
        grad.addColorStop(0, "#f47d46");
        grad.addColorStop(.5, "#fb2e9b");
        grad.addColorStop(1, "#6407b8");

        ctx.strokeStyle = grad;


        if (width) {
            ctx.lineWidth = width;
        }

        ctx.beginPath();
        ctx.moveTo(...begin);
        ctx.lineTo(...end);
        ctx.stroke();
    }

    checkMicrophonePermission = () => {
        navigator.permissions.query({ name: "microphone" as PermissionName }).then(res => {
            if (res.state == "granted") {
                this.setState({
                    error: '',
                })
            } else if (res.state == "denied") {
                this.setState({
                    error: 'permission_denied',
                })
            }
        });
    }
    async initRecorder() {
        if (navigator.mediaDevices) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                if (this.props.mimeTypeToUseWhenRecording) {
                    this.mediaRecorder = new MediaRecorder(stream, { mimeType: this.props.mimeTypeToUseWhenRecording });
                } else {
                    this.mediaRecorder = new MediaRecorder(stream);
                }
                this.chunks = [];
                this.mediaRecorder.ondataavailable = (e: any) => {
                    if (e.data && e.data.size > 0) {
                        this.chunks.push(e.data);
                    }
                };
                this.setState({ mediaStream: stream });
            } catch (err) {
                this.setState({ error: 'permission_denied' })
            }

        }
    }

    handleStartRecording = async (event: any) => {
        event.preventDefault();
        // wipe old data chunks
        this.chunks = [];

        await this.initRecorder();
        // start recorder with 10ms buffer
        try {
            this.mediaRecorder.start(10);
            this.startTimer();

            this.setState({ isRecording: true, status: 'recording' });
        } catch (err) {
            console.log("error", err)
        }
    }

    startTimer() {
        this.timer = setInterval(this.countDown, 100);
    }

    countDown() {
        // limiting audio recording to 10 min
        if((this.state.miliseconds) > 600000){
            this.handleStopRecording();
            return;
        }
        // limiting audio recording to 10 min x END

        this.setState(prevState => {
            const miliseconds = prevState.miliseconds + 100;
            return ({ time: this.milisecondsToTime(miliseconds), miliseconds: miliseconds });
        });

        this.props.handleCountDown(this.state.time);
    }

    milisecondsToTime(milisecs: number) {

        let secs: number = milisecs / 1000;
        let hours: number = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes: number = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds: number = Math.ceil(divisor_for_seconds);

        return {
            h: hours,
            m: minutes,
            s: seconds,
            ms: milisecs
        };
    }

    handlePauseRecording = (event: any) => {
        event.preventDefault();
        clearInterval(this.timer);
        this.mediaRecorder.pause();

        this.setState({ isRecording: false, status: 'paused' }, () => {
            this.drawEmptyStream();
        });
    }

    handleResumeRecording = (event: any) => {
        event.preventDefault();
        this.startTimer();
        this.mediaRecorder.resume();
        this.setState({ isRecording: true, status: 'recording' });
    }

    handleStopRecording = () => {
        if (this.mediaRecorder.state === 'recording' || this.mediaRecorder.state === 'paused') {
            clearInterval(this.timer);
            if (this.state.mediaStream.getAudioTracks) {
                const tracks = this.state.mediaStream.getAudioTracks();
                tracks.forEach((track: any) => {
                    track.stop();
                });
            }

            this.mediaRecorder.stop();

            this.setState({ isRecording: false, status: 'stopped' }, () => {
                this.saveAudio();
                this.handleReset();
                this.drawEmptyStream();
            });
        }

    }

    saveAudio = () => {
        const blob = new Blob(this.chunks, { type: audioType });
        const audioURL = window.URL.createObjectURL(blob);
        const audios = [audioURL];
        this.setState({ audios, audioBlob: blob });
        this.props.handleAudioStop({
            url: audioURL,
            blob: blob,
            chunks: this.chunks,
            duration: this.state.time
        });
    }

    handleReset = () => {
        if (this.state.isRecording) {
            this.handleStopRecording();
        }
        this.setState({
            time: {},
            miliseconds: 0,
            isRecording: false,
            medianotFound: false,
            audios: [],
            audioBlob: null
        }, () => {
            this.props.handleReset(this.state);
        });

    }

    formatTimeDigit = (timeElement: number) => {
        return timeElement !== undefined ? `${timeElement <= 9 ? '0' + timeElement : timeElement}` :
            '00';
    }

    // Customizable Area End

}