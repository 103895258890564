// Customizable Area Start
import React from "react";
import { Typography, Grid, Table, TableContainer, TableHead, TableCell, TableRow, TableBody, Divider, IconButton, Button, Menu, Box, MenuItem, Avatar } from "@material-ui/core";
import AdminReportCardController, {
    Props,
} from "./AdminReportCardController.web";
import { createNewIcon, bubbleSquareImg, bubbleImg2, userIcon, selectFromTemplate, draftIcon, deleteOutlineIcon, editIcon } from "../assets";
import ReactPaginate from "react-paginate";
import { withStyles } from '@material-ui/core/styles';
import Spinner from "../../../shared/SideBar/src/Spinner";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import './AdminReportCard.web.css';
import moment from "moment";
import { MoreVertOutlined } from "@material-ui/icons";
import WarningModal from "../../../AssessmentTest/src/WarningModal.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
// Customizable Area Start
// Customizable Area End

class AdminReportCard extends AdminReportCardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const menuIcon = {
            width: "20px",
            height: "20px",
            objectFit: "contain",
            cursor: "pointer"
        } as const;
        const { reportCardList } = this.state;
        return (
            <>
                {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}

                <div className="teacher_assessment_box">
                    <Grid container spacing={3}>
                        <Grid item lg={9} md={8} sm={7} xs={12} className="report-card-text-title">
                            <Typography className={`${this.props.classes.heading_font}`}>Report Card</Typography>
                        </Grid>
                        <Grid item lg={3} md={4} sm={5} xs={12} className="rightBtn">
                            <Button className={`${this.props.classes.button_color} create-evaluation-details-btn`}
                                onClick={this.createEvaluationBtnClick}>
                                Create Evaluation Details
                            </Button>
                        </Grid>
                    </Grid>
                    <div className="threeBox mb-20">
                        <Grid container spacing={4}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <div style={{
                                    background: "linear-gradient(to bottom, rgba(63,146,248,1) 0%,rgba(67,150,250,1) 100%)",
                                }} className="report-top-section bg_img_height"
                                    onClick={this.createCustomCardClick}>
                                    <Grid container>
                                        <Grid item className="report-cards-block" >
                                            <img src={createNewIcon} className="admin-create-icon"></img>
                                            <Typography className={` ${this.props.classes.subHeading_font} report-cards-text`}>
                                                Create custom report card
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <img
                                        src={bubbleSquareImg}
                                        alt=""
                                        className="create_bubble_img"
                                    />
                                </div>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <div style={{
                                    background: "linear-gradient(to bottom, rgba(216,103,73,1) 0%,rgba(217,119,100,1) 100%)",
                                }}
                                    className="report-top-section bg_img_height"
                                    onClick={this.createTemplateCardClick}>
                                    <Grid container>
                                        <Grid item className="report-cards-block" >
                                            <img src={selectFromTemplate} className="admin-create-icon"></img>
                                            <Typography className={`report-cards-text ${this.props.classes.subHeading_font}`}>
                                                Select from template
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <img
                                        src={bubbleImg2}
                                        alt=""
                                        className="create_bubble_img"
                                    />
                                </div>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <div style={{
                                    background: "linear-gradient(to bottom, rgba(32,171,112,1) 0%,rgba(33,192,125,1) 100%)",
                                }} className="report-top-section bg_img_height"
                                    onClick={this.draftReportCardClick}>
                                    <Grid container>
                                        <Grid item className="report-cards-block" >
                                            <img src={draftIcon} className="admin-create-icon"></img>
                                            <Typography className={`report-cards-text ${this.props.classes.subHeading_font}`}>
                                                Draft
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <img
                                        src={bubbleImg2}
                                        alt=""
                                        className="create_bubble_img"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Typography className={`${this.props.classes.subHeading_font} published-text`}>
                            Published Report Card
                        </Typography>
                        <Divider style={{ marginBottom: 25 }} />

                        <TableContainer style={{
                            borderRadius: 6,
                            border: 0,
                            boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.2)",
                        }}>
                            <Table >
                                <TableHead style={{
                                    backgroundColor: "rgb(249, 248, 253)",
                                }}>
                                    <TableRow>
                                        <TableCell className={this.props.classes.bodyText_font} style={{ padding: "17px 5px 17px 20px", fontSize: 16, fontWeight: 600, borderBottom: 0, whiteSpace: 'nowrap' }}>List of Report Card</TableCell>
                                        <TableCell className={this.props.classes.bodyText_font} style={{ padding: "17px 5px 17px 17px", fontSize: 16, fontWeight: 600, borderBottom: 0, whiteSpace: 'nowrap' }}>Teachers</TableCell>
                                        <TableCell className={this.props.classes.bodyText_font} style={{ padding: "17px 5px 17px 17px", fontSize: 16, fontWeight: 600, borderBottom: 0, whiteSpace: 'nowrap' }}>Creation Date</TableCell>
                                        <TableCell className={this.props.classes.bodyText_font} style={{ padding: "17px 5px 17px 17px", fontSize: 16, fontWeight: 600, borderBottom: 0, whiteSpace: 'nowrap' }}>Class</TableCell>
                                        <TableCell className={this.props.classes.bodyText_font} style={{ padding: "17px 17px 17px 17px", fontSize: 16, fontWeight: 600, borderBottom: 0, whiteSpace: 'nowrap' }}>Division</TableCell>
                                        <TableCell className={this.props.classes.bodyText_font} style={{ padding: "17px 17px 17px 17px", fontSize: 16, fontWeight: 600, borderBottom: 0, whiteSpace: 'nowrap' }}></TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ marginTop: 10 }} >
                                    {
                                        reportCardList?.length === 0 || reportCardList === undefined
                                            ?
                                            <TableRow>
                                                <TableCell
                                                    style={{ padding: "17px 0px 16px 20px", color: "#3f526d", fontFamily: 'Open sans', fontSize: 18 }}
                                                    colSpan={7}
                                                    align="center"
                                                >
                                                    No record(s) found.
                                                </TableCell>
                                            </TableRow>
                                            :
                                            <>
                                                {reportCardList?.map((data: any) => (
                                                    <React.Fragment key={data.id}>
                                                        <TableRow style={{
                                                            borderBottom: "1px solid lightgray"
                                                        }} >
                                                            <TableCell style={{ padding: "17px 17px", border: 0, color: "#3f526d", fontFamily: 'Open sans', fontSize: 16, fontWeight: 600, whiteSpace: 'nowrap' }}>{data.attributes.title ? data.attributes.title : '-'}</TableCell>
                                                            <TableCell style={{ padding: "17px 5px 17px 17px", border: 0, color: "#3f526d", fontFamily: 'Open sans', fontSize: 16, fontWeight: 600, textTransform: "capitalize", whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                                                                {
                                                                    (data?.attributes?.teachers === null || data?.attributes?.teachers?.length === 0) && '-'
                                                                }
                                                                {
                                                                    data?.attributes?.teachers?.length > 4 ? (
                                                                        <>
                                                                            {
                                                                                data?.attributes?.teachers?.map((item: any, index: number) => {
                                                                                    if (index < 4) {
                                                                                        return (<img
                                                                                            key={item.id}
                                                                                            src={item?.attributes?.avatar ? item?.attributes?.avatar : userIcon}
                                                                                            style={{
                                                                                                ...{
                                                                                                    verticalAlign: 'middle',
                                                                                                    width: '32px',
                                                                                                    height: '32px',
                                                                                                    borderRadius: '50%'
                                                                                                }, ...(index > 0 ? {
                                                                                                    marginLeft: '-8px',
                                                                                                    boxShadow: "-1px 0px 4px 0px grey"
                                                                                                } : {})
                                                                                            }}
                                                                                        />)
                                                                                    }
                                                                                })
                                                                            }
                                                                            <span style={{ marginLeft: '8px' }}>{
                                                                                `+ ${(data?.attributes?.teachers?.length - 4)} ${(data?.attributes?.teachers?.length - 4) > 1 ? ` members` : ` member`}`
                                                                            }</span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                data?.attributes?.teachers?.map((item: any, index: number) => {

                                                                                    return <img
                                                                                        key={item.id}
                                                                                        src={item?.attributes?.avatar ? item?.attributes?.avatar : userIcon}
                                                                                        style={{
                                                                                            ...{
                                                                                                verticalAlign: 'middle',
                                                                                                width: '32px',
                                                                                                height: '32px',
                                                                                                borderRadius: '50%'
                                                                                            }, ...(index > 0 ? {
                                                                                                marginLeft: '-8px',
                                                                                                boxShadow: "-1px 0px 4px 0px grey"
                                                                                            } : {})
                                                                                        }}
                                                                                    />
                                                                                })
                                                                            }
                                                                        </>
                                                                    )
                                                                }

                                                            </TableCell>
                                                            <TableCell style={{ padding: "17px 5px 17px 17px", border: 0, color: "#3f526d", fontFamily: 'Open sans', fontSize: 16, fontWeight: 600, whiteSpace: 'nowrap' }}>{
                                                                moment(data.attributes?.created_date, "YYYY-MM-DD").format(
                                                                    "DD / MM / YYYY"
                                                                )}</TableCell>
                                                            <TableCell style={{ padding: "17px 5px 17px 17px", border: 0, color: "#3f526d", fontFamily: 'Open sans', fontSize: 16, fontWeight: 600, whiteSpace: 'nowrap' }}>
                                                                {
                                                                    (data?.attributes?.grades === null || data?.attributes?.grades?.length === 0) && '-'
                                                                }
                                                                {data.attributes?.grades?.[0]?.name}
                                                            </TableCell>
                                                            <TableCell style={{ padding: "17px 17px 17px 17px", border: 0, color: "#3f526d", fontFamily: 'Open sans', fontSize: 16, fontWeight: 600, textTransform: "capitalize", whiteSpace: 'nowrap' }}>
                                                                {
                                                                    (data?.attributes?.grade_divisions === null || data?.attributes?.grade_divisions?.length === 0) && '-'
                                                                }
                                                                {data.attributes?.grade_divisions?.join(", ")}
                                                            </TableCell>
                                                            <TableCell style={{ padding: "17px 17px 17px 17px", border: 0, color: "#3f526d", fontFamily: 'Open sans', fontSize: 16, fontWeight: 600, textTransform: "capitalize", whiteSpace: 'nowrap' }}>
                                                                <MoreVertOutlined
                                                                    style={{
                                                                        height: 24,
                                                                        width: 24,
                                                                        cursor: "pointer"
                                                                    }}
                                                                    onClick={(event: any) => {
                                                                        this.setState({ menuAnchorEl: event.currentTarget, selectedReportCardId: data.id }, () => {
                                                                            this.setState({ isMenuOpen: Boolean(this.state.menuAnchorEl) })
                                                                        })
                                                                    }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                ))}
                                            </>
                                    }
                                </TableBody>

                                <Menu
                                    anchorEl={this.state.menuAnchorEl}
                                    open={this.state.isMenuOpen}
                                    onClose={() => { this.setState({ selectedReportCardId: '', menuAnchorEl: '', isMenuOpen: false }) }}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <Box style={{ width: 203 }}>
                                        <MenuItem
                                            onClick={() => this.setState({
                                                openDeleteWarningModal: true
                                            })}
                                        >
                                            <Grid container spacing={1}>
                                                <Grid item xs={4} style={{ flexBasis: 0 }}>
                                                    <Avatar src={deleteOutlineIcon} variant="square" style={menuIcon} />
                                                </Grid>
                                                <Grid item xs={8} style={{ flexBasis: 0 }}>
                                                    <Typography
                                                        style={{
                                                            textTransform: "none",
                                                            fontSize: "16px",
                                                            fontFamily: "Open Sans",
                                                            fontWeight: 600,
                                                            color: "#3f526d"
                                                        }}
                                                    >
                                                        Delete
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                this.getEditReportCardData()
                                            }}
                                        >
                                            <Grid container spacing={1}>
                                                <Grid item xs={4} style={{ flexBasis: 0 }}>
                                                    <Avatar src={editIcon} variant="square" style={menuIcon} />
                                                </Grid>
                                                <Grid item xs={8} style={{ flexBasis: 0 }}>
                                                    <Typography
                                                        style={{
                                                            textTransform: "none",
                                                            fontSize: "16px",
                                                            fontFamily: "Open Sans",
                                                            fontWeight: 600,
                                                            color: "#3f526d"
                                                        }}
                                                    >
                                                        Edit
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    </Box>

                                </Menu>
                            </Table>
                        </TableContainer>

                        {
                            reportCardList?.length === 0 || reportCardList === undefined
                                ? null :
                                <div style={{ overflowX: "auto" }} className="choose_from_gallery">
                                    <ReactPaginate
                                        previousLabel={<>←   <span className="xs_display">Previous</span></>}
                                        nextLabel={<><span className="xs_display">Next</span>   →</>}
                                        breakLabel="..."
                                        initialPage={this.state.page}
                                        pageCount={this.state.reportCardListMeta?.pagination?.total_pages}
                                        onPageChange={(e: any) => {
                                            if (e.selected !== this.state.page) {
                                                this.setState({ page: e.selected }, () => this.getReportCards())
                                            }
                                        }}
                                        containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                                        previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                                        nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                                        disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                                        activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                                        pageRangeDisplayed={2}
                                        marginPagesDisplayed={2}
                                    />
                                </div>
                        }
                    </div>

                </div>
                <WarningModal
                    title={"Delete Report Card?"}
                    subTitle={"Are you sure you want to delete!"}
                    paragraph={""}
                    warningModal={this.state.openDeleteWarningModal}
                    onCloseModal={() => this.setState({
                        openDeleteWarningModal: false
                    })}
                    warningClickOk={this.deleteReportCard}
                />
            </>
        );
        // Customizable Area End
    }
}
export const themeCreateeStyle = ((theme: any) => ({
    // Customizable Area Start
    root: {
        flexGrow: 1,
    },
    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: theme.props.buttonColor,
        }
    },
    image_Border_Color: {
        border: `3px solid ${theme.props.buttonColor}`
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
    // Customizable Area End
}))
// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(AdminReportCard, "AdminAccount"));
// Customizable Area End