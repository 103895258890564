// Customizable Area Start
import React from "react";
import Grid from '@material-ui/core/Grid';
import {
    Button,
    Dialog,
    Typography,
    IconButton,
} from "@material-ui/core";
import ViewBatchController, { Props } from './ViewBatchController.web';
import CloseIcon from '@material-ui/icons/Close';
import { deleteDivision, editDivision } from "../assets";
import Spinner from "../../../shared/SideBar/src/Spinner";
import AddEditBatch from './AddEditBatch.web';
import moment from 'moment';
import DeleteModal from "./DeleteModal.web";
import './BatchAndPeriod.web.css'
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

export class ViewBatch extends ViewBatchController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    render() {
        const { batchData, isEditBatch, batchName, batchNameError, batchNameErrorMsg, selectedStartTime, selectedEndTime, selectedStartTimeBatchLabel, selectedEndTimeBatchLabel, selectedBatchEndTimeError, selectedBatchEndTimeErrorMsg } = this.state
        return (
            <>
                <Dialog
                    PaperProps={{
                        className: "dynamic_popup_width ViewBatchModal"
                    }}
                    aria-labelledby="customized-dialog-title"
                    maxWidth="md"
                    open={this.props.addDialog}
                    onClose={() => this.props.handleDialogClose()}
                    closeAfterTransition
                    fullWidth={true}
                    disableAutoFocus={true}
                    className="ViewBatchModal"
                    id="view-batch-modal"
                    data-test-id="admin-view-batch-modal"
                >
                    <div className="ViewBatchModalDiv">
                        {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
                        <Grid container >
                            <Grid item xs={12} className="ViewBatchHead">
                                <Typography
                                    className={`ViewBatchText ${this.props.classes.title_font}`}
                                >View Batch</Typography>
                                <CloseIcon
                                    className="ViewBatchClose"
                                    data-test-id="close-view-batch-btn"
                                    onClick={() => this.props.handleDialogClose()}
                                />
                            </Grid>
                        </Grid>

                        <div className="BatchListDiv">
                            <Grid className="BatchListHeader" container>
                                <Grid item xs={3}>
                                    <div
                                        className={`BatchHeadText ${this.props.classes.subTitle_font}`}
                                    >
                                        Batch Name
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div
                                        className={`BatchHeadTimeText ${this.props.classes.subTitle_font}`}
                                    >
                                        Start Time
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div
                                        className={`BatchHeadTimeText ${this.props.classes.subTitle_font}`}
                                    >
                                        End Time
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div
                                        className={`BatchHeadActionText ${this.props.classes.subTitle_font}`}
                                    >
                                        Action
                                    </div>
                                </Grid>
                            </Grid>
                            {
                                batchData?.length === 0
                                    ?
                                    <>
                                        <Typography
                                            className={`NoBatchRecordFound ${this.props.classes.heading_font}`}
                                        >
                                            No record(s) found.
                                        </Typography>
                                    </>
                                    :
                                    batchData?.map((data: any, index: number) => (
                                        <React.Fragment key={index}>
                                            <div className="BatchDataDiv">
                                                <Grid
                                                    container
                                                    className="BatchDataContainer"
                                                >
                                                    <Grid item xs={3}>
                                                        <div
                                                            className={`BatchNameText ${this.props.classes.bodyText_font}`}
                                                        >
                                                            {data.attributes.name}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <div
                                                            className={`BatchTimeText ${this.props.classes.bodyText_font}`}
                                                        >
                                                            {data.attributes.start_time}
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <div
                                                            className={`BatchTimeText ${this.props.classes.bodyText_font}`}
                                                        >
                                                            {data.attributes.end_time}
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <div className="BatchActionDiv">
                                                            <IconButton
                                                                aria-label="more"
                                                                aria-controls="long-menu"
                                                                aria-haspopup="true"
                                                                className="BatchActionIconBtn"
                                                            >
                                                                <img src={editDivision}
                                                                    data-test-id={`batch-edit-btn-${index}`}
                                                                    className="EditBatchPeriodImg"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            isEditBatch: true,
                                                                            openAddEditBatch: true,
                                                                            selectedBatch: data,
                                                                            batchName: data.attributes.name,
                                                                            selectedStartTime: this.handleGetStartAndEndTime(data.attributes?.start_time),
                                                                            selectedStartTimeBatchLabel: this.handleGetStartAndEndTimeLabel(data.attributes?.start_time),
                                                                            selectedEndTime: this.handleGetStartAndEndTime(data.attributes?.end_time),
                                                                            selectedEndTimeBatchLabel: this.handleGetStartAndEndTimeLabel(data.attributes?.end_time)
                                                                        })
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                aria-label="more"
                                                                aria-controls="long-menu"
                                                                aria-haspopup="true"
                                                                className="BatchActionIconBtn"
                                                            >
                                                                <img id={`batch-id-${data.id}`}
                                                                    data-test-id={`delete-batch-btn-${index}`}
                                                                    src={deleteDivision}
                                                                    className="DeleteBatchPeriodImg"
                                                                    onMouseEnter={() => {
                                                                        const layerElement = document.getElementById(`batch-id-${data.id}`)
                                                                        if (layerElement) {
                                                                            layerElement.style.opacity = "1";
                                                                        }
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        const layerElement = document.getElementById(`batch-id-${data.id}`)
                                                                        if (layerElement) {
                                                                            layerElement.style.opacity = "0.3";
                                                                        }
                                                                    }}
                                                                    onClick={() => this.setState({ openDeleteBatchModal: true, selectedBatch: data })}
                                                                />
                                                            </IconButton>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </React.Fragment>
                                    ))}
                        </div>

                        <div className="NewBatchDiv">
                            <Button
                                className={`NewBatchBtn ${this.props.classes.button_color}`}
                                data-test-id="add-new-batch-btn"
                                onClick={() => {
                                    this.setState({
                                        openAddEditBatch: true, isEditBatch: false, selectedBatch: null, batchName: "",
                                        selectedStartTime: new Date(), selectedStartTimeBatchLabel: moment(new Date()).format("A"),
                                        selectedEndTime: new Date(), selectedEndTimeBatchLabel: moment(new Date()).format("A")
                                    })
                                }}
                            >
                                <Typography className="AddNewBatchText">
                                    Add New
                                </Typography>
                            </Button>
                        </div>

                    </div>
                </Dialog>

                <AddEditBatch
                    addEditBatchDialog={this.state.openAddEditBatch}
                    handleAddEditBatchDialogClose={this.handleAddEditBatchDialogClose}
                    isEditBatch={isEditBatch}
                    batchName={batchName}
                    batchNameError={batchNameError}
                    batchNameErrorMsg={batchNameErrorMsg}
                    onChangeBatchName={this.handleOnChangeBatchName}
                    onBlurBatchName={this.handleOnBlurBatchName}
                    selectedStartTime={selectedStartTime}
                    selectedStartTimeBatchLabel={selectedStartTimeBatchLabel}
                    selectedEndTime={selectedEndTime}
                    selectedEndTimeBatchLabel={selectedEndTimeBatchLabel}
                    handleStartTimeChange={this.handleStartTimeChange}
                    handleEndTimeChange={this.handleEndTimeChange}
                    addEditBatchClick={this.add_edit_batch}
                    selectedBatchEndTimeError={selectedBatchEndTimeError}
                    selectedBatchEndTimeErrorMsg={selectedBatchEndTimeErrorMsg}
                />

                <DeleteModal
                    title="Delete Batch"
                    deleteModal={this.state.openDeleteBatchModal}
                    onCloseModal={this.handleCloseDeleteBatchModal}
                    deleteClick={this.delete_batch}
                />
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(ViewBatch);
// Customizable Area End
