// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createRef, RefObject } from "react";
import { toast } from "react-toastify";
import * as SparkMD5 from "spark-md5";
import convert from "xml-js";
import {
  checkForRequiredField,
  generateKey,
  computeChecksumMd5,
  toBase64,
} from "../utils/common";
import axios from "axios";
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bulkFileUploaded: Array<any>;
  alertModal: boolean;
  alertMessage: string;
  uploadPercentage: number | null;
  uploadResponse: any;
  presignID: Array<any>;
  presignedBlobUrls: Array<any>;
  answerAttachmentFiles: Array<any>;
  // Customizable Area End
}
export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  openModal: boolean;
  handlePresignIDClose: any;
  handleOnClose: any;
  questionNumber: any;
  sectionId: any;
  questionId: any;
  handlePresignBlobUrl: React.Dispatch<React.SetStateAction<any>>;
  handleAnswerAttachFileName: React.Dispatch<React.SetStateAction<any>>;
  // Customizable Area End
}

// Customizable Area Start
const configJSON = require("../config");
// Customizable Area End

export default class StudentUploadAssessmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetPreSignedId: any = [];
  apiUploadID: any = [];
  apiDeleteAttachment: string = "";
  readonly inputOpenFileRef: RefObject<HTMLInputElement>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area Start
    this.inputOpenFileRef = createRef();
    this.state = {
      uploadPercentage: null,
      bulkFileUploaded: [],
      alertModal: false,
      alertMessage: "",
      uploadResponse: {},
      presignID: [],
      presignedBlobUrls: [],
      answerAttachmentFiles: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  handleUpload = async (
    event: React.ChangeEvent<any>,
    allowMultipleFiles: boolean,
    filesFromDragAndDrop: any,
    reDirectScreen?: string
  ) => {
    let files: any;
    let allowUplaod: boolean = false;
    let allowLoop: boolean = true;
    let { bulkFileUploaded } = this.state;

    if (filesFromDragAndDrop !== null) {
      files = filesFromDragAndDrop;
    } else {
      files = event.target.files;
    }

    const keys = Object.keys(files);

    if (files.length + bulkFileUploaded.length > 10) {
      this.setState({
        alertMessage: "You can upload maximum 10 files.",
        alertModal: true,
      });
      allowLoop = false;
    }
    if (allowLoop) {
      let percent = 0;
      this.setState({
        //   showDescriptionField: allowMultipleFiles,
        uploadPercentage: percent,
        //   displayScreen: reDirectScreen
        //     ? reDirectScreen
        //     : this.state.displayScreen,
        //   showLoader: true,
        bulkFileUploaded: [],
        //   fileUploaded: {},
        //   fileDescription: "",
      });

      keys?.map(async (key: any) => {
        const file = files[key];
        if (allowMultipleFiles) {
          let fileUpload: any = {};

          fileUpload = {
            id: generateKey(file.name),
            fileName: file.name,
            name: file.name.split(".")[0],
            isExistingFile: false,
            fileSize: file.size,
            percentage: 0,
            file: file,
            content_type: file.type,
          };
          console.log(fileUpload, "fileUpload");
          bulkFileUploaded = [...bulkFileUploaded, fileUpload];
          this.setState({ bulkFileUploaded: bulkFileUploaded });
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.addEventListener("progress", (event) => {
            if (event.loaded && event.total) {
              // this.clientSidePercentage = (event.loaded / event.total) * 100;
              // this.calcTotalPercentage();
            }
          });
          // let checksum = "";

          // await computeChecksumMd5(file)
          //   .then((md5: any) => {
          //     checksum = md5;
          //   });

          const extension = file.name.split(".")[
            file.name.split(".").length - 1
          ];
          let fileUpload = {
            id: 0,
            file: file,
            fileSize: file.size,
            //checksum: checksum,
            content_type: file.type,
          };
        }
      });
    }
  };

  computeChecksumMd5 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function(): void {
        reject("MD5 computation failed - error reading the file");
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function(e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  };

  uploadFile = async () => {
    // let fileData: any;
    let imageUrls: any = [];
    let attachmentNames: any = [];
    this.state.bulkFileUploaded.map((item: any) => {
      let file = item.file;
      var blobURL = URL.createObjectURL(file);
      imageUrls.push(blobURL);
      attachmentNames.push(file.name);
      //this.props.handlePresignBlobUrl(blobURL);
      //this.props.handleAnswerAttachFileName(file.name);
      // fileData = await toBase64(file);
      const fileDetails = {
        filename: file.name,
        byte_size: file.size,
        //checksum: md5,
        content_type: file.type,
      };
      this.getPreSignedId(fileDetails);
    });
    this.setState({
      presignedBlobUrls: imageUrls,
      answerAttachmentFiles: attachmentNames,
    });
  };

  getPreSignedId = (fileDetails: any) => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      //"Content-Type": configJSON.validationApiContentType,
      token,
    };
    let formData = new FormData();
    formData.append("content_type", fileDetails.content_type);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetPreSignedId[this.apigetPreSignedId.length] =
      requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    // console.log("REQUEST: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUploadApiByAWS = async (preSignedResponse: any) => {
    const { bulkFileUploaded } = this.state;

    const header:any = {
      // "Content-Type": preSignedResponse[0]?.headers?.["Content-Type"],
      // "Content-MD5": preSignedResponse[0]?.headers?.["Content-MD5"],
      token: localStorage.getItem("token"),
    };

    const httpBody = bulkFileUploaded[0].file;

    try {
      await axios({
        method: "put",
        url: preSignedResponse[0].url,
        data: httpBody,
        headers: header,
      })
        .then((s3Response) => {
          toast.success("File Uploaded successfully !");
          console.log("hello aws", s3Response.data);
        })
        .catch((s3error) => {
          toast.error("Something went wrong.");
        });
    } catch (e) {
      toast.error("Something went wrong.");
    }
  };

  deleteAttachment = (documentID: number | null = null) => {
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteAttachment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.galleryImagesEndPoint + `/${documentID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    console.log("REQUEST: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.apigetPreSignedId.indexOf(apiRequestCallId) !== -1) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            //this.handleUploadApiByAWS(responseJson.data);
            // this.props.handlePresignIDClose(
            //   responseJson?.data[0].signed_id,
            //   this.props.sectionId,
            //   this.props.questionId
            // );
            const indexOfUploadDoc = this.apigetPreSignedId.indexOf(
              apiRequestCallId
            );
            const imageData: any = this.state.bulkFileUploaded[
              indexOfUploadDoc
            ];
            const updatedBulkDocument = [...this.state.bulkFileUploaded];
            const msg: Message = new Message(
              getName(MessageEnum.UploadMediaMessage)
            );
            updatedBulkDocument[indexOfUploadDoc].messageId = msg.messageId;
            this.setState({ bulkFileUploaded: updatedBulkDocument });
            const uploadFileData: any = {
              responseJson: responseJson,
              imageData: imageData.file,
              messageId: msg.messageId,
            };

            msg.addData(
              getName(MessageEnum.UploadMediaDataMessage),
              uploadFileData
            );
            this.apiUploadID[this.apiUploadID.length] = msg.messageId;
            runEngine.sendMessage(msg.id, msg);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.apiUploadID.indexOf(apiRequestCallId) !== -1) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.apiUploadID = this.apiUploadID.filter(
              (id: any) => id !== apiRequestCallId
            );
            const keyValue = JSON.parse(
              convert.xml2json(responseJson, {
                spaces: 1,
                compact: true,
                ignoreComment: true,
                alwaysChildren: true,
                ignoreDeclaration: true,
              })
            );
            if (keyValue?.PostResponse) {
              this.setState({
                presignID: [
                  ...this.state.presignID,
                  keyValue.PostResponse.Key._text,
                ],
              });
              if (!this.apiUploadID.length) {
                this.props.handlePresignIDClose(
                  this.state.presignID,
                  this.props.sectionId,
                  this.props.questionId
                );
                this.props.handlePresignBlobUrl(this.state.presignedBlobUrls);
                this.props.handleAnswerAttachFileName(
                  this.state.answerAttachmentFiles
                );
                toast.success("File Uploaded successfully !");
              }
            } else {
              toast.error("Something went wrong while uploading the file!");
            }
            // this.handleUploadApiByAWS(responseJson.data)
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }
}
