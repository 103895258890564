// Customizable Area Start
import React from "react";
import {
    Grid,
    Modal,
    Button,
} from "@material-ui/core";
import './BatchAndPeriod.web.css';
// Customizable Area End

// Customizable Area Start
const deleteDiv = {
    fontFamily: "Open sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: 'center',
    color: "#3f526d",
    marginBottom: '14px',
} as const;
const deleteMes1 = {
    fontFamily: "Open sans",
    fontSize: "24px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: 'center',
    color: "#3f526d",
    margin: '31px 32px 6px 33px',
} as const;
const deleteMes2 = {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: 'center',
    color: "#959393",
    margin: '6px 53px 40px 54px',
} as const;
const cancelText = {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: 'center',
    color: "#fff",
} as const;
const deleteText = {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: 'center'
} as const;
const cancelButton = {
    margin: '0 20px 0 0',
    padding: '9px 20px 12px 22px',
    borderRadius: '3px',
    background: '#7f6cfc',
    color: 'white',
    textTransform: 'initial',
} as const;
const deleteButton = {
    margin: '0 0px 0 20px',
    padding: '9px 20px 12px 22px',
    borderRadius: '3px',
    backgroundColor: '#f6f6f6',
    textTransform: 'initial',
} as const;
// Customizable Area End
interface Props {
    // Customizable Area Start
    title: string;
    deleteModal: boolean;
    onCloseModal: any;
    deleteClick: any;
    // Customizable Area End
}

// Customizable Area Start
const DeletePeriodModal = ({
    title,
    deleteModal,
    onCloseModal,
    deleteClick,
}: Props) => {
    return (
        <>
            <Modal
                open={deleteModal}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id="delete-period-modal"
                className="DeletePeriodModal"
            >
                <Grid className="DeleteBatchPeriodModal">
                    <div style={deleteDiv}>{title}</div>
                    <div className="DeleteBatchDivider"></div>
                    <div style={deleteMes1}>Are you sure you want to delete the item?</div>
                    <div style={deleteMes2}>This item will be deleted immediately. You cannot undo the action.</div>
                    <Grid className="DeleteBatchBtnDiv">
                        <Button variant="contained" style={cancelButton} data-test-id="admin-delete-period-cancel-btn" onClick={onCloseModal}><span style={cancelText}>Cancel</span></Button>
                        <Button variant="contained" style={deleteButton} data-test-id="admin-delete-period-delete-btn" onClick={deleteClick}><span style={deleteText}>Delete</span></Button>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
};
// Customizable Area End
// Customizable Area Start
export default DeletePeriodModal;
// Customizable Area End
