// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import StudentPagination from './StudentPagination.web'
import ReactPaginate from "react-paginate";
import Grid from '@material-ui/core/Grid';
import { profile_logo, profileImg } from "../assets";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { HISTORY } from "../../../../components/src/comman";
import StudentReportCardController, { Props } from './StudentReportCardController.web';
import Spinner from '../../../shared/SideBar/src/Spinner';
import InputBase from "@material-ui/core/InputBase";
import { schoolLogo } from '../../../Feedback/src/assets';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme: any) => ({
  root: {
      "label + &": {
          marginTop: theme.spacing(1),
      },
  },
  input: {
      borderRadius: 4,
      position: "relative",
      boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: theme.palette.background.paper,
      padding: "10px 26px 10px 12px",
      width: "7rem",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: theme.props.textColor,
      fontFamily: theme.props.textFont,
      fontSize: 18,
      "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
  },
}))(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      fontFamily: 'Open sans',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    table: {
      minWidth: 700,
    },
     
  }),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      fontFamily: 'Open sans',
    }
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,        
      },
    },
  }),
)(TableRow);

function createData(title: string, term: number, term2: number, term3: number) {
  return { title, term, term2, term3 };
}

const rows = [
  createData('Total Attendence of the learner', 20, 10, 5),
  createData('Total Number of working days', 20, 10, 4.0)
];
// Customizable Area End

export class StudentReportCard extends StudentReportCardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { schoolData, isLoader, schoolLogoUrl, termListData, data, profileDetails, secondAttendanceRow, thirdAttendanceRow } = this.state
    
    const handleDateChange = (date: Date | null) => {
      this.setState({
        selectedDate: date
      })
    };
    const handleBack = () => {
      HISTORY.goBack();
    }

    return (
      <>
        {isLoader && <Spinner spinnerModal={isLoader} />}
        <div>
          <div className='profileTitle'>
            <h2 className={this.props.classes.bodyText_font}>Report Card - Profile Detail</h2>
          </div>
          <div className='studentProfileContent'>
            <div className='reportTopHeader'>
              <svg viewBox="0 0 1920 561" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                      <linearGradient x1="6.06%" y1="45.739%" x2="100.976%" y2="54.546%" id="qzkvt3o0ga">
                          <stop stop-color="#B6A5FE" offset="0%"/>
                          <stop stop-color="#7F6CFC" offset="100%"/>
                      </linearGradient>
                  </defs>
                  <path d="M247 322h1907a7 7 0 0 1 7 7v483.943c-62.785 35.236-139.747 52.854-230.886 52.854-136.709 0-502.752-78.987-727.595-78.987-224.843 0-568.101 95.696-695.696 95.696-85.064 0-173.671-21.772-265.823-65.316V328a6 6 0 0 1 6-6z" transform="translate(-241 -322)" fill="url(#qzkvt3o0ga)" fill-rule="evenodd"/>
              </svg>
          </div>
            <div className='topHeader'>
              <div className='schoolLogo'>
                <img src={schoolLogoUrl ? schoolLogoUrl : schoolLogo} />
              </div>
              <div className='schoolTitle'>
                <h3 className={this.props.classes.heading_font}>
                {schoolData?.attributes?.name || ''}</h3>
              </div>
              <span className="commonBtnPrimary">Report Card - {`${profileDetails?.attributes?.school_class?.data?.attributes?.class_name || ''}`}</span>
            </div>
            <div className='blockVision'>
            <h4 className={this.props.classes.bodyText_font}>Vision of {schoolData?.attributes?.name}</h4>
            <p className={this.props.classes.bodyText_font}>It is a long established face that a reader will be distracted by the readable content of  a page when looking as a its layout.</p>
            </div> 
            <h5 className={`titleStudent ${this.props.classes.bodyText_font}`}>Student Profile</h5>
            <Grid container alignItems="flex-start" spacing={4}>
                        <Grid className="studentProfile" item xs={12} lg={2}>
                            <div className="student-profile-photo-div">
                                <Avatar src={profileDetails?.attributes?.avatar ? profileDetails?.attributes?.avatar : ""} className="student-profile-photo" />
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={10}>
                            <Box className="profileForm textLeft">
                                <form noValidate autoComplete="off">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Name</label>
                                            <TextField
                                                fullWidth
                                                placeholder='Name'
                                                variant="outlined"
                                                value={`${profileDetails?.attributes?.first_name || '' }  ${profileDetails?.attributes?.last_name || ''}`}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={4} sm={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Class</label>
                                            <TextField
                                                fullWidth
                                                placeholder='Class'
                                                variant="outlined"
                                                value={`${profileDetails?.attributes?.school_class?.data?.attributes?.class_name || ''}`}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4} sm={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Section</label>
                                            <TextField
                                                fullWidth
                                                placeholder='Section'
                                                variant="outlined"
                                                value={`${profileDetails?.attributes?.school_class?.data?.attributes?.divisions?.data?.attributes?.name || ''}`}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4} sm={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>DOB</label>
                                            <div className="datePicker">
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        format="MM/dd/yyyy"
                                                        id="date-picker-inline"
                                                        value={new Date(profileDetails?.attributes?.date_of_birth || this.state.selectedDate)}
                                                        onChange={() => { }}
                                                        disabled
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        inputProps={{
                                                            className: `${this.props.classes.heading_font} detail-field-style`
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Class Teacher's Name</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Class Teacher's Name"
                                                variant="outlined"
                                                value={this.state.teacherName}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Residential Address</label>
                                            <TextField
                                                fullWidth
                                                placeholder='Residential Address'
                                                variant="outlined"
                                                value={profileDetails?.attributes?.residential_address}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Mother's Name</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Mother's Name"
                                                variant="outlined"
                                                value={profileDetails?.attributes?.mother_name}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Father's / Guardian's Name</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Father's / Guardian's Name"
                                                variant="outlined"
                                                value={profileDetails?.attributes?.father_name}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Email ID</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Email ID"
                                                variant="outlined"
                                                value={profileDetails?.attributes?.email}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Mobile No.</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Mobile No."
                                                variant="outlined"
                                                value={profileDetails?.attributes?.phone_number}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Grid>
                    </Grid>


            <TableContainer component={Paper} style={{ marginTop: 60 }}>
                        <Table style={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead className={this.props.classes.studentReportCard_classAndSection_background_color}>
                                <TableRow>
                                    <TableCell className={`${this.props.classes.studentReportCard_classAndSection_tableRowCell} attendance-title`}>Attendance</TableCell>
                                    {termListData?.map((data: any, termIndex: number) => (
                                        <TableCell align="center" key={termIndex} className={`${this.props.classes.studentReportCard_classAndSection_tableRowCell} attendance-title`}>{data}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row: any, index: number) => (
                                    <TableRow className='tableRow' key={index}>
                                        <TableCell component="td" scope="row" className={`${this.props.classes.heading_font} attendance-details`}>
                                            {row.title}
                                        </TableCell>
                                        {index === 0 && secondAttendanceRow?.map((data: any, secondIndex: number) => (
                                            <TableCell align="center" key={secondIndex}> {data}</TableCell>
                                        ))}
                                        {index === 1 && thirdAttendanceRow?.map((data: any, thirdIndex: number) => (
                                            <TableCell align="center" key={thirdIndex}> {data}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
          </div>
           <StudentPagination intialPage={0} />
        </div>
      </>
    );
}
// Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(StudentReportCard as React.ComponentType<any>, "StudentAccount")
);
// Customizable Area End
