// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Checkbox,
  TextField,
  Typography,
  FormHelperText,
  FormControlLabel,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from "@material-ui/core";
import GraphPlottingTypeQuestionController, {
  Props,
} from "./GraphPlottingTypeQuestionController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import {
  imageMarksMinusSVG,
  imageMarksPlusSVG,
  imgBackArrowSVG,
} from "../assets";
import QuestionMetaData from "../Components/QuestionMetaData.web";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import "../TeacherAssessment.web.css";
import QuestionSolution from "../Components/QuestionSolution.web";
import SelectImageModal from "./SelectImageModal.web";
import AlertModal from "../../../alert/src/AlertModal.web";
import { HISTORY } from "../../../../components/src/comman";
import { ImagePreivewModal } from "../Components/ImagePreivewModal.web";
import ChooseFromGalleryModal from "./ChooseFromGalleryModal.web";
import { invalidCharsForNumberType } from "../../../profiles/src/utils/common";
import TextEditor from "./TextEditor/TextEditor.web";
import QuestionSidebarCollapse from "./QuestionSidebarCollapse.web";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { imgDeleteGraySVG } from "../assets";
import BarChart from "./BarChart.web";
import QuestionPreviewModal from "../Components/QuestionPreviewModal.web";
import './AddQuestion.web.css';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class GraphPlottingTypeQuestion extends GraphPlottingTypeQuestionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const {
      marks,
      negativeMarks,
      sectiondata,
      selectedCategoryQuestionData,
      graph_axis_settings_attributes,
      graph_plotting_categories_attributes
    } = this.state;
    const WhiteRadio = withStyles({
      root: {
        color: "#3f526d",
        padding: "0px",
        "&$checked": {
          color: "white",
          backgroundColor: "#3f526d",
          padding: "2px",
        },
      },
      checked: {},
    })((props: RadioProps) => <Radio color="default" {...props} />);

    const input_box = {
      borderRadius: '6px',
      boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
      backgroundColor: '#ffffff',
      width: '180px',
      height: '52px',
      textAlign: "center",
      justifyContent: "center",
      fontSize: 16,
      fontWeight: 600,
      padding: '0 10px'
    } as const;

    return (
      <>
        <div>
          {this.state.showLoader && (
            <Spinner spinnerModal={this.state.showLoader} />
          )}
          <Grid
            container
            className="marks_section"
          >
            <Grid
              item
              xs={12}
              sm={10}
              className="question_flex_column"
            >
              <Typography
                className={`back_with_arrow ${this.props.classes.bodyText_font}`}
              >
                <span onClick={HISTORY.goBack}>
                  <img
                    src={imgBackArrowSVG}
                    className="question_back_arrow"
                  ></img>
                  Back
                </span>

                <span className={`${this.props.classes.bodyText_font} textlabel_breadcrum`}>
                  <span className={`text-style-light`}>
                    {this.state.isEdit
                      ? "Edit question "
                      : "Create new question "}{" "}
                  </span>
                  (
                  {selectedCategoryQuestionData?.question_category_id === 1 ? (
                    <>Objective</>
                  ) : (
                    <>Subjective</>
                  )}{" "}
                  - {selectedCategoryQuestionData?.name.replaceAll("_", " ")}
                  {})
                </span>
              </Typography>

              <div className="marks_wrap_div">
                <div className="marks_div">
                  <Typography
                    className={`question_marks_text ${this.props.classes.subHeading_font}`}
                  >
                    Marks
                  </Typography>
                  <div>
                    <div
                      className={this.state.marksError ? "view_marksbox red_border" : "view_marksbox none_border"}
                    >
                      <img
                        src={imageMarksPlusSVG}
                        className="image_marks"
                        onClick={this.plusMarksClick}
                      ></img>

                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={(event) => {
                          const value: number = parseFloat(
                            parseFloat(event.target.value)
                              .toFixed(3)
                              .slice(0, -1)
                          );
                          if (value > 0 || marks > 0) {
                            this.setState({ marksError: false });
                          } else {
                            this.setState({
                              marksError: true,
                              marks: 0,
                              marksErrorText: "Please enter marks.",
                            });
                          }
                          if (
                            value <
                            this.state.sectiondata?.remaining_marks
                          ) {
                            this.setState({ marks: value, marksError: false });
                          } else if (
                            value ==
                            this.state.sectiondata?.remaining_marks -
                            1
                          ) {
                            this.setState({ marks: value, marksError: false });
                          }
                          if (value >= this.state.negativeMarks) {
                            this.setState({
                              marks: value,
                              negmarksError: false,
                            });
                          } else {
                            this.setState({
                              marks: value,
                              negmarksError: true,
                            });
                          }
                          if (this.state.isEdit) {
                            if (
                              value >
                              this.state.sectiondata?.remaining_marks +
                              HISTORY.location.state?.questionData?.attributes?.marks
                            ) {
                              this.setState({
                                marks: value,
                                marksError: true,
                                marksErrorText:
                                  "Marks not greater than section marks.",
                              });
                            } else {
                              this.setState({
                                marks: value,
                              });
                            }
                          } else {
                            if (
                              value >
                              this.state.sectiondata?.remaining_marks
                            ) {
                              this.setState({
                                marks: value,
                                marksError: true,
                                marksErrorText:
                                  "Marks not greater than section marks.",
                              });
                            } else {
                              this.setState({
                                marks: value,
                              });
                            }
                          }
                        }}
                        onKeyPress={(event) => {
                          if (invalidCharsForNumberType.includes(event.key)) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        value={marks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.marksError && (
                      <FormHelperText className="red_text">
                        {this.state.marksErrorText}
                      </FormHelperText>
                    )}
                  </div>
                </div>

                {this.state.assessmentHasNegativeMarking && (
                  <div className="marks_div">
                    <Typography
                      className={`question_marks_text ${this.props.classes.subHeading_font}`}
                    >
                      Negative Marks
                    </Typography>
                    <div
                      className={this.state.negmarksError ? "view_marksbox red_border" : "view_marksbox none_border"}
                    >
                      <img
                        src={imageMarksMinusSVG}
                        className="image_marks"
                        onClick={this.minusMarksClick}
                      ></img>

                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={(event) => {
                          const value: number = parseFloat(
                            parseFloat(event.target.value)
                              .toFixed(3)
                              .slice(0, -1)
                          );
                          if (value > this.state.marks) {
                            this.setState({ negmarksError: true });
                          } else {
                            this.setState({ negmarksError: false });
                          }
                          this.setState({ negativeMarks: value });
                        }}
                        onKeyPress={(event) => {
                          if (invalidCharsForNumberType.includes(event.key)) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        value={negativeMarks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.negmarksError && (
                      <FormHelperText className="red_text">
                        Negative marking not greater then marks.
                      </FormHelperText>
                    )}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={2} className="remaining_section_marks">
              <Typography
                className={`remaining_marks ${this.props.classes.subHeading_font}`}
              >
                Remaining Section Marks
              </Typography>

              <div className="circular_div">
                <CircularProgress
                  variant="static"
                  className="grey_circular"
                  size={92}
                  thickness={2}
                  value={100}
                />
                <CircularProgress
                  variant="static"
                  disableShrink
                  className="colored_circular"
                  size={92}
                  value={this.state.remainSectionMarkPr}
                  thickness={2}
                />
                <Typography
                  className={`circular_marks_text ${this.props.classes.bodyText_font}`}
                >
                  {(
                    sectiondata?.section_marks -
                    sectiondata?.remaining_marks
                  ).toFixed(2)}
                  /{sectiondata?.section_marks}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Typography className={`question_text ${this.props.classes.subHeading_font}`}>Question</Typography>
          <div ref={this.questionEditorRef}>
            <TextEditor
              myref={this.questionEditorRef}
              handleChange={(value: any) =>
                this.setState({ question: value }, () => {
                  if (
                    this.state.question === null ||
                    this.state.question === ""
                  ) {
                    this.setState({ questionError: true });
                  } else {
                    this.setState({ questionError: false });
                  }
                })
              }
              editorData={
                HISTORY.location.state?.questionData?.attributes?.title
                  ? HISTORY.location.state?.questionData?.attributes?.title
                  : ""
              }
              isEdit={this.state.isEdit}
            />
          </div>
          {this.state.questionError && (
            <FormHelperText className="question_error">
              Please enter question.
            </FormHelperText>
          )}

          {/* Chart Axis Section start */}
          <div className="graph_plotting">
            <Typography className={`question_text ${this.props.classes.subHeading_font}`}>Chart</Typography>

            <TableContainer className="chart_table_container">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={`first_blank_cell ${this.props.classes.subHeading_font}`}></TableCell>
                    <TableCell
                      className={`chart_header ${this.props.classes.subHeading_font}`}
                    >Label</TableCell>
                    <TableCell
                     className={`chart_header ${this.props.classes.subHeading_font}`}
                    >Minimum Value</TableCell>
                    <TableCell
                      className={`chart_header ${this.props.classes.subHeading_font}`}
                    >Maximum Value</TableCell>
                    <TableCell
                      className={`chart_header ${this.props.classes.subHeading_font}`}
                    >Step size</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={`axis_header ${this.props.classes.subHeading_font}`}
                    >X- Axis</TableCell>
                    <TableCell className="table_cell_value">
                      <TextField
                        style={input_box}
                        variant="standard"
                        name="x_axis_label"
                        onChange={this.handleInputChange}
                        value={graph_axis_settings_attributes.x_axis_label}
                        placeholder="X-axis"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className={this.props.classes.bodyText_font}
                      />
                    </TableCell>
                    <TableCell className="first_blank_cell"></TableCell>
                    <TableCell className="first_blank_cell"></TableCell>
                    <TableCell className="first_blank_cell"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className={`axis_header ${this.props.classes.subHeading_font}`}
                    >Y- Axis</TableCell>
                    <TableCell className="table_cell_value">
                      <TextField
                        style={input_box}
                        name="y_axis_label"
                        onChange={this.handleInputChange}
                        value={graph_axis_settings_attributes.y_axis_label}
                        variant="standard"
                        placeholder="Y-axis"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className={this.props.classes.bodyText_font}
                      />
                    </TableCell>
                    <TableCell className="table_cell_value">
                      <TextField
                        style={input_box}
                        name="minimum_value"
                        type="number"
                        onChange={this.handleInputChange}
                        value={graph_axis_settings_attributes.minimum_value}
                        placeholder="10"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className={this.props.classes.bodyText_font}
                      />
                    </TableCell>
                    <TableCell className="table_cell_value">
                      <TextField
                        style={input_box}
                        name="maximum_value"
                        type="number"
                        onChange={this.handleInputChange}
                        value={graph_axis_settings_attributes.maximum_value}
                        placeholder="100"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className={this.props.classes.bodyText_font}
                      />
                    </TableCell>
                    <TableCell className="table_cell_value">
                      <TextField
                        style={input_box}
                        name="step_size"
                        type="number"
                        onChange={this.handleInputChange}
                        value={graph_axis_settings_attributes.step_size}
                        placeholder="0"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className={this.props.classes.bodyText_font}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* Chart Section end */}

          {/* Categories */}
          <div className="graph_plotting">
            <Typography className={`question_text ${this.props.classes.subHeading_font}`}>Categories</Typography>
            <TableContainer className="chart_table_container">
              <Table>
                {graph_plotting_categories_attributes.length === 0 ? (
                  <TableRow>
                    <TableCell className="table_cell_value" colSpan={4}>
                      <Typography
                        className={`graph_no_lines_text ${this.props.classes.bodyText_font}`}
                      >
                        No Category Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell className="first_blank_cell"></TableCell>
                        <TableCell className="table_cell_value">
                          <Typography
                            className={`graph_title_text ${this.props.classes.subHeading_font}`}
                          >
                            Label
                          </Typography>
                        </TableCell>
                        <TableCell className="table_cell_value">
                          <Typography
                           className={`graph_title_text ${this.props.classes.subHeading_font}`}
                          >
                            Initial Value
                          </Typography>
                        </TableCell>
                        <TableCell className="table_cell_value"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {graph_plotting_categories_attributes.map((category: any, index: number) => (
                        <TableRow key={category.uniq_key}>
                          <TableCell className="table_cell_value">
                            <Typography
                             className={`graph_title_text ${this.props.classes.subHeading_font}`}
                            >
                              Point-{index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell className="table_cell_value">
                            <TextField
                              style={input_box}
                              variant="standard"
                              name="label"
                              value={category.label}
                              placeholder={`Bar ${index + 1}`}
                              className={this.props.classes.bodyText_font}
                              onChange={(e: any) => this.handleUpdateCategories(index, e)}
                              InputProps={{
                                disableUnderline: true,
                              }}
                            />
                          </TableCell>
                          <TableCell className="table_cell_value">
                            <div className="graph_axis_value_div">
                                <TextField
                                  style={{
                                    ...input_box,
                                    border: category.error.initial_value_error
                                      ? "1px solid red"
                                      : "none",
                                  }}
                                  variant="standard"
                                  name="initial_value"
                                  type="number"
                                  value={category.initial_value}
                                  placeholder="0"
                                  className={this.props.classes.bodyText_font}
                                  onChange={(e: any) => {
                                    this.handleUpdateCategories(index, e)
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                />
                                {
                                  category.error.initial_value_error && (
                                    <>
                                    {
                                      category.initial_value > Number(graph_axis_settings_attributes.maximum_value) ? 
                                      <FormHelperText className="graph_init_value_error">
                                        Initial value should not be more than { graph_axis_settings_attributes.maximum_value }
                                      </FormHelperText>
                                      : 
                                      <FormHelperText className="graph_init_value_error">
                                        Initial value should not be less than { graph_axis_settings_attributes.minimum_value }
                                      </FormHelperText>
                                    }
                                    </>
                                  )
                                } 
                            </div>
                          </TableCell>
                          <TableCell className="table_cell_value">
                            <Box
                              className="delete_cell_box"
                              onClick={() => {
                                this.handleDeleteCategorySection(category.uniq_key, index)
                              }}
                            >
                              <img src={imgDeleteGraySVG} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </>)}
              </Table>
            </TableContainer>

          </div>


          {/* Add new Point Btn */}
          <Box className="add_graph_line_div">
            <Button
              onClick={this.handleAddPoint}
              className={`add_new_match_btn ${this.props.classes.outline_button_no_hover}`}
            >
              <AddIcon className="add_new_match_icon" />
              Add New Point
            </Button>
          </Box>

          {/* Correct Answers */}
          <div>
            <Typography className={`question_text ${this.props.classes.subHeading_font}`}>Set Correct Answer(s)</Typography>
            <Grid container spacing={2} className="plot_graph_div">
              <Grid item xs={12} sm={7} md={8} lg={9}>
                <BarChart
                  classes={this.props.classes}
                  graph_axis_settings_attributes={this.state.graph_axis_settings_attributes}
                  graph_plotting_categories_attributes={this.state.graph_plotting_categories_attributes}
                  graph_properties={this.state.graph_properties}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={3} className="graph_setting_grid">
                {/* Show Grid */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.graph_properties.show_grid}
                      onChange={() => {
                        const graph_properties = this.state.graph_properties;
                        graph_properties.show_grid = !graph_properties.show_grid;
                        this.setState({ graph_properties });
                      }}
                      name="showGridLines"
                      color="primary"
                    />
                  }
                  className={`graph_setting_check ${this.props.classes.bodyText_font}`}
                  label="Show Grid"
                />
                {/* Show Grid END */}

                {/* Multicolor Bar */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.graph_properties.show_multicolor_bar}
                      onChange={() => {
                        const graph_properties = this.state.graph_properties;
                        graph_properties.show_multicolor_bar = !graph_properties.show_multicolor_bar;
                        this.setState({ graph_properties });
                      }}
                      name="showMultiColorBar"
                      color="primary"
                    />
                  }
                  className={`graph_setting_check ${this.props.classes.bodyText_font}`}
                  label="Multicolor Bar"
                />
                {/* Multicolor Bar END */}

                {/* Display position on hover */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.graph_properties.display_position_on_hover}
                      onChange={() => {
                        const graph_properties = this.state.graph_properties;
                        graph_properties.display_position_on_hover = !graph_properties.display_position_on_hover;
                        this.setState({ graph_properties });
                      }}
                      name="toolTipOnHover"
                      color="primary"
                    />
                  }
                  className={`graph_setting_check ${this.props.classes.bodyText_font}`}
                  label="Display position on hover"
                />
                {/* Display position on hover END */}
              </Grid>
            </Grid>
          </div>
          {/* Correct Answers END */}

          {/* Enter Correct Answer Start*/}
          <div className="graph_plotting">
            <Grid container spacing={5} className="graph_correct_answer_div">
              <Grid item xs={12} sm={12} md={3} lg={2}>
                <Typography
                  className={`enter_correct_ans_text ${this.props.classes.subHeading_font}`}
                >
                  Enter Correct Answer
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={10}>
                <TableContainer>
                  <Table>
                    <TableRow className="graph_attributes_row">
                      {graph_plotting_categories_attributes.map((category: any, index: number) => (
                        <TableCell key={index} className="table_cell_value">
                          <Box className="graph_bar_box">
                            <Typography
                              className={`graph_start_end_text ${this.props.classes.subHeading_font}`}
                            >
                              {category.label ? category.label : `Bar-${index + 1} `}
                            </Typography>

                            <div className="graph_axis_value_div">
                              <TextField
                                style={{
                                  ...input_box,
                                  border: category.error.correct_answer_value_error
                                    ? "1px solid red"
                                    : "none",
                                }}
                                variant="standard"
                                name="correct_answer_value"
                                type="number"
                                value={category.correct_answer_value}
                                placeholder="80"
                                className={this.props.classes.bodyText_font}
                                onChange={(e: any) => {
                                  this.handleUpdateCategories(index, e)
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                              {
                                  category.error.correct_answer_value_error && (
                                    <>
                                    {
                                      category.correct_answer_value > Number(graph_axis_settings_attributes.maximum_value) ? 
                                      <FormHelperText className="graph_init_value_error">
                                        Correct answer value should not be more than { graph_axis_settings_attributes.maximum_value }
                                      </FormHelperText>
                                      : 
                                      <FormHelperText className="graph_init_value_error">
                                        Correct answer value should not be less than { graph_axis_settings_attributes.minimum_value }
                                      </FormHelperText>
                                    }
                                    </>
                                  )
                                } 
                            </div>
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
          {/* Enter Correct Answer End*/}


          <QuestionSolution
            hintAnswer={this.state.hintAnswer}
            hintAnswerOnchange={(value: any, id: any) => {
              let tempData = this.state.hintAnswer;
              const index = this.state.hintAnswer.findIndex(
                (res: any) => res.hintId === id
              );
              tempData[index].hint = value.trim();
              this.setState({
                hintAnswer: tempData,
              });
            }}
            explaination={this.getExplanation()}
            getPreSignedValue={(
              preSignedId: string,
              typeofAttachment: string,
              blobUrl: string,
            ) => {
              if (typeofAttachment === "hints") {
                let tempData = this.state.hintAnswer;
                tempData[0].files = [preSignedId];
                tempData[0].src = blobUrl;
                this.setState({
                  hintAnswer: tempData,
                  hintId: preSignedId,

                });
              } else {
                this.setState({ solutionImageSignedId: preSignedId });
              }
            }}
            explainationOnchange={(value: any) => {
              this.setState({
                explaination: value,
              });
            }}
            attachmentExplanationUrl={this.state.attachmentExplanationUrl}
            hintsExplanationUrl={this.state.hintsExplanationUrl}
            removeSolutionImage={(attachmentType: string) => {
              if (attachmentType === "hints") {
                this.setState({
                  hintsExplanationUrl: "",
                  hintId: null,
                });
              } else {
                this.setState({
                  attachmentExplanationUrl: "",
                  solutionImageSignedId: null,
                });
              }
            }}
          />
          <QuestionMetaData
            selectedSubject={this.state.selectedSubject}
            onChangeSubject={(e: any) => {
              this.setState({ selectedSubject: e.target.value });
            }}
            chapterListData={this.state.chapterListData}
            selectedChapter={this.state.selectedChapter}
            onChangeChapter={(e: any) => {
              this.setState(
                {
                  selectedChapter: e.target.value,
                  metaDataErrors: {
                    ...this.state.metaDataErrors,
                    chapter: false,
                  },
                },
                () => this.get_unit_list(this.state.selectedChapter, true)
              );
            }}
            unitListData={this.state.unitListData}
            selectedUnit={this.state.selectedUnit}
            onChangeUnit={(e: any) => {
              this.setState({
                selectedUnit: e.target.value,
                metaDataErrors: { ...this.state.metaDataErrors, unit: false },
              });
            }}
            selectedSkill={this.state.selectedSkill}
            onChangeSkill={(e: any) => {
              this.setState({
                selectedSkill: e.target.value,
                metaDataErrors: { ...this.state.metaDataErrors, skill: false },
              });
            }}
            selectedProficiency={this.state.selectedProficiency}
            onChangeProficiency={(e: any) => {
              this.setState({
                selectedProficiency: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  proficiency: false,
                },
              });
            }}
            selectedDifficultyLevel={this.state.selectedDifficultyLevel}
            onChangeDifficultyLevel={(e: any) => {
              this.setState({
                selectedDifficultyLevel: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  difficultyLevel: false,
                },
              });
            }}
            keyword={this.state.keyword}
            keywordsList={this.state.keywordsList}
            onChangeKeywords={this.onChangeKeywords}
            onBlurKeywords={this.onBlurKeywords}
            onKeyDownKeywords={this.onKeyDownKeywords}
            handleDeleteKeyword={this.handleDeleteKeyword}
            metaDataErrors={this.state.metaDataErrors}
            selectedBloomTaxonomy={this.state.selectedBloomTaxonomy}
            onChangeBloomTaxonomy={(e: any) => {
              this.setState({
                selectedBloomTaxonomy: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  bloomTaxonomy: false,
                },
              });
            }}
          />

          <div className="question_action_items">
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.background_button_no_hover}`}
              onClick={() => { this.setState({ prevQuestionModal: true }) }}
            >
              <Box className="preview_btn_box">
                <VisibilityIcon className="eye_preview" />
                <Typography> Preview</Typography>
              </Box>
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.button_color}`}
              onClick={this.saveQuestion}
            >
              {this.state.isEdit ? "Update" : "Save"}
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_cancel_btn ${this.props.classes.outline_button_no_hover}`}
              onClick={()=>HISTORY.push("/TeacherAssessmentCreateSection")}
            >
              Cancel
            </Button>
          </div>

          <AlertModal
            alertModal={this.state.alertModal}
            isTeacher={true}
            onCloseModal={() => {
              this.setState({ alertModal: false });
            }}
            alertTitle="Alert!"
            alertMessage={this.state.alertMessage}
            buttonText="OK"
          />

          <SelectImageModal
            openSelectImageModal={this.state.openSelectImageModal}
            onCloseModal={() => this.setState({ openSelectImageModal: false })}
            onClickChooseFromGallery={() => {
              this.setState({
                openChooseFromGalleryModal: true,
                openSelectImageModal: false,
              });
            }}
            onClickUploadFromLocal={
              this.state.optionImageSelection
                ? this.showOpenFileDlg
                : this.showFirstOpenFileDlg
            }
          />
          <QuestionPreviewModal
            questionType={'bar_graph_plotting'}
            questionPreviewModal={this.state.prevQuestionModal}
            closeModalFun={() => { this.setState({ prevQuestionModal: false }) }}
            question={this.state.question}
            graphDetails={{
              categories: graph_plotting_categories_attributes,
              setting: this.state.graph_properties,
              axis: graph_axis_settings_attributes
            }
            }
            questionHint={this.state.hintAnswer} />
          <ChooseFromGalleryModal
            openChooseFromGalleryModal={this.state.openChooseFromGalleryModal}
            onCloseModal={() =>
              this.setState({ openChooseFromGalleryModal: false })
            }
            onClickImage={(id: any, url: any) => {
              if (this.state.optionImageSelection) {
                let TempResponseData = this.state.questionOptionList;
                const findIndex = this.state.questionOptionList.findIndex(
                  (res: any) =>
                    res.charNumber === this.state.selectedOptionNumber
                );
                const findData = this.state.questionOptionList.find(
                  (res: any) =>
                    res.charNumber === this.state.selectedOptionNumber
                );
                TempResponseData[findIndex] = {
                  ...findData,
                  src: url,
                  upload_id: id,
                  name: "Gallery Image",
                  isImageAvailble: true,
                };
                this.setState({
                  selectedGalleryImageId: id,
                  questionOptionList: TempResponseData,
                });
              } else {
                this.setState({
                  mainGalleryImageId: id,
                  selectedGalleryImageId: id,
                  selectedGalleryImageUrl: url,
                });
              }
            }}
            onClickChoose={() => this.onClickGalleryImageChoose()}
            selectedGalleryImageId={this.state.selectedGalleryImageId}
            onTabChangeClearId={() =>
              this.setState({ selectedGalleryImageId: 0 })
            }
          />
        </div>
        {
          this.state.isEdit &&
          <QuestionSidebarCollapse editedQuestionData={HISTORY.location.state?.questionData} />
        }
        <ImagePreivewModal
          previewImageModal={this.state.previewImageModal}
          modalhandleClose={() => this.setState({ previewImageModal: false })}
          previewImage={this.state.previewImage}
        />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(GraphPlottingTypeQuestion as React.ComponentType<any>);

// Customizable Area End
