// Customizable Area Start
import React, { useRef, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { convertDataURLtoFile } from "../utils/common";
import * as markerjs2 from "markerjs2";
import './TaskMarkSubmissionTest.web.css';
import { TriangleMarker } from "./TriangleMarker.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface Props {
    // Customizable Area Start
    studentFileData: any;
    getPreSignedId: any;
    // Customizable Area End
}
// Customizable Area Start
export const TaskMarkSubmissionTest = ({ studentFileData, getPreSignedId }: Props) => {
    const [FileData, setFileData] = useState([])
    useEffect(() => {
        setFileData(studentFileData)
    }, [studentFileData])
    let imgRef: any = useRef([]);

    const showMarkerArea = (imageRef: any, id: any) => {

        let targetImageId: any = document.getElementById(`annotate_${id}`);
        const markerArea = new markerjs2.MarkerArea(targetImageId);
        markerArea.settings.displayMode = 'popup';
        markerArea.availableMarkerTypes = [
            TriangleMarker,
            ...markerArea.BASIC_MARKER_TYPES
        ];
        markerArea.uiStyleSettings.redoButtonVisible = true;
        markerArea.uiStyleSettings.zoomButtonVisible = true;
        markerArea.uiStyleSettings.zoomOutButtonVisible = true;
        markerArea.addEventListener("render", (event: any) => {
            targetImageId.src = event.dataUrl;
            const tempfileData = studentFileData
            const findIndex = tempfileData.findIndex((data: any) => data.id === id)
            const data = tempfileData[findIndex]
            const obj = data
            obj.annotateImage = event.dataUrl
            const newS = obj
            tempfileData[findIndex] = newS
            const imageDataFileUrl = convertDataURLtoFile(event.dataUrl, tempfileData[findIndex].attributes.file_name)

            setFileData(tempfileData)
            const fileUpload = {
                fileName: imageDataFileUrl.name,
                name: imageDataFileUrl.name.split('.')[0],
                fileSize: imageDataFileUrl.size,
                file: imageDataFileUrl,
                content_type: imageDataFileUrl.type
            };
            getPreSignedId(fileUpload, imageDataFileUrl, id)
        });
        markerArea.show();
    }
    return (
        <>
            {FileData.map((data: any, index: any) => (
                <Box className="taskMarkSubmissionTest">
                    <img className="showMarkImg"
                        ref={(el) => (imgRef.current[index] = el)}
                        id={`annotate_${data.id}`}
                        src={`${data.annotateImage ? data.annotateImage : data.attributes.url}`}
                        alt="answer-img"
                        crossOrigin="anonymous"
                        onClick={() => {
                            showMarkerArea(imgRef, data.id)
                        }}
                    />
                </Box>
            ))}
        </>
    );
};
// Customizable Area End

// Customizable Area Start
export default TaskMarkSubmissionTest;
// Customizable Area End
