// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const TermIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g id="Teacher-Assesment-" fill={`${props.fillColor}`} fill-rule="nonzero" {...props}>
                <g id="icons" transform="translate(-160.000000, -653.000000)" fill={`${props.fillColor}`} fill-rule="nonzero" {...props}>
                    <g id="desktop-computer" transform="translate(160.000000, 653.000000)" {...props}>
                        <path d="M30,27.9912093 L2,27.9912093 C0.897333333,27.9912093 0,27.093876 0,25.9912093 L0,7.32454265 C0,6.22187598 0.897333333,5.32454265 2,5.32454265 L5.33333333,5.32454265 C5.70133333,5.32454265 6,5.62320931 6,5.99120931 C6,6.35920931 5.70133333,6.65787598 5.33333333,6.65787598 L2,6.65787598 C1.632,6.65787598 1.33333333,6.95654265 1.33333333,7.32454265 L1.33333333,25.9912093 C1.33333333,26.3592093 1.632,26.657876 2,26.657876 L30,26.657876 C30.368,26.657876 30.6666667,26.3592093 30.6666667,25.9912093 L30.6666667,7.32454265 C30.6666667,6.95654265 30.368,6.65787598 30,6.65787598 L26.6666667,6.65787598 C26.2986667,6.65787598 26,6.35920931 26,5.99120931 C26,5.62320931 26.2973333,5.32454265 26.6666667,5.32454265 L30,5.32454265 C31.1026667,5.32454265 32,6.22187598 32,7.32454265 L32,25.9912093 C32,27.093876 31.1026667,27.9912093 30,27.9912093 Z" id="Path"></path>
                        <path d="M20.6666667,31.9912093 L11.3333333,31.9912093 C11.064,31.9912093 10.82,31.8285426 10.7173333,31.5792093 C10.6133333,31.329876 10.6706667,31.0432093 10.8613333,30.8525426 C12.616,29.097876 12.6386667,27.3992093 12.6386667,27.3272093 C12.6386667,26.961876 12.936,26.6605426 13.3013333,26.6592093 L13.3053333,26.6592093 C13.6693333,26.6592093 13.9666667,26.949876 13.972,27.313876 C13.972,27.3912093 13.976,28.9085426 12.76,30.6565426 L19.236,30.6565426 C18.016,28.9125426 17.9986667,27.4005426 17.9986667,27.3232093 C17.9986667,26.9552093 18.2973333,26.6565426 18.6653333,26.6565426 C19.032,26.6565426 19.3306667,26.9525426 19.332,27.3192093 C19.3333333,27.4032093 19.3853333,29.1005426 21.1373333,30.8512093 C21.328,31.041876 21.3853333,31.3285426 21.2813333,31.577876 C21.18,31.8285426 20.936,31.9912093 20.6666667,31.9912093 L20.6666667,31.9912093 Z" id="Shape"></path>
                        <path d="M31.3333333,23.9912093 L0.666666667,23.9912093 C0.298666667,23.9912093 0,23.6925426 0,23.3245426 C0,22.9565426 0.298666667,22.657876 0.666666667,22.657876 L31.3333333,22.657876 C31.7013333,22.657876 32,22.9565426 32,23.3245426 C32,23.6925426 31.7013333,23.9912093 31.3333333,23.9912093 Z" id="Path"></path>
                        <path d="M16,13.9912093 C15.632,13.9912093 15.3333333,13.6925426 15.3333333,13.3245426 L15.3333333,2.32454265 C15.3333333,1.95654265 15.632,1.65787598 16,1.65787598 C16.368,1.65787598 16.6666667,1.95654265 16.6666667,2.32454265 L16.6666667,13.3245426 C16.6666667,13.6925426 16.368,13.9912093 16,13.9912093 Z" id="Path"></path>
                        <path d="M16,14.657876 C15.8533333,14.657876 15.7053333,14.6085426 15.5826667,14.5125426 C13.9106667,13.1752093 12.2973333,12.9725426 10.6506667,13.897876 C10.2306667,14.1352093 9.732,14.1312093 9.31733333,13.8885426 C8.91066667,13.6485426 8.66666667,13.221876 8.66666667,12.7472093 L8.66666667,2.66720931 C8.66666667,1.99920931 8.98133333,1.38987598 9.50666667,1.03654265 C11.6373333,-0.38612402 13.8706667,-0.34212402 16,1.15787598 C18.128,-0.34212402 20.3626667,-0.387457353 22.492,1.03787598 C23.0186667,1.38987598 23.3333333,1.99920931 23.3333333,2.66720931 L23.3333333,12.7472093 C23.3333333,13.221876 23.0893333,13.6485426 22.6813333,13.8872093 C22.2666667,14.1285426 21.768,14.133876 21.348,13.8965426 C19.7,12.9712093 18.088,13.173876 16.416,14.5112093 C16.2946667,14.6085426 16.1466667,14.657876 16,14.657876 Z M12.6813333,11.9885426 C13.792,11.9885426 14.9013333,12.377876 16,13.1565426 C17.98,11.7512093 19.9986667,11.6085426 22,12.733876 L22,2.66720931 C22,2.44720931 21.904,2.24854265 21.7506667,2.14454265 C19.9653333,0.94987598 18.2186667,1.06854265 16.4173333,2.51120931 C16.1733333,2.70587598 15.828,2.70587598 15.584,2.51120931 C13.7813333,1.06854265 12.0373333,0.948542647 10.2493333,2.14454265 C10.096,2.24720931 10,2.44720931 10,2.66720931 L10,12.7472093 C10.892,12.2405426 11.788,11.9885426 12.6813333,11.9885426 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default TermIcon;
// Customizable Area End