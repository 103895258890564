// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area End


// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  spinner: boolean
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  openModal: boolean;
  handleOnClose: React.Dispatch<React.SetStateAction<boolean>>;
  // Customizable Area End
}
export default class uploadAssesmentModalController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area Start
    this.state = {

      spinner: false,

    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

  }
  handleClose = () => {
    this.props.handleOnClose(false)
  }
  handleClicknavigationNOHandler = () => {
    HISTORY.push("/TeacherAssessment")
  }
  handleClicknavigationYESHandler = () => {
    HISTORY.push("/TeacherAssessmentModule")
  }
  // Customizable Area End


  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    // Customizable Area Start
    // Customizable Area End
  }

}