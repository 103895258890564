// Customizable Area Start
import React from "react";
import "react-phone-input-2/lib/style.css";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "react-datetime/css/react-datetime.css";
import "./TeacherAccountRegistration.css";
import AlertModal from "../../alert/src/AlertModal.web";
import ThemesAndFontsController, {
    Props,
} from "./ThemesAndFontsController.web";
import OnBoardingSideBar from "./OnBoardingSideBar.web"
import Spinner from "../../shared/SideBar/src/Spinner";
import HOCAuth from "../../shared/Header/src/HOCAuth.web";
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
import { withStyles } from "@material-ui/core";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config");
const textlabel_welcomeText = {
    fontSize: "35.1px",
    fontWeight: 'bold' as 'bold',
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
} as const;

const textlabel_font = {
    fontSize: "18px",
    fontWeight: 'bold' as 'bold',
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
} as const;

const textlabel_Header = {
    fontSize: "18px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: '#000'
} as const;


const textlabel_school_des = {
    fontSize: "12px",
    fontWeight: 'normal',
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
} as const;
const selectedThemeStyle = {
    padding: '16px 16px 22px',
    borderRadius: '17px',
    border: 'solid 1px #525252',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    cursor: 'pointer',
    position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'
} as const;
const themeStyle = {
    padding: '16px 16px 22px',
    cursor: 'pointer'
} as const;
// Customizable Area End

export class ThemesAndFonts extends ThemesAndFontsController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        return (
            <>
                <Grid container>
                    {this.state.loadingSpinner && <Spinner spinnerModal={this.state.loadingSpinner} />}

                    <Grid item xs={12} sm={3} md={3} lg={3}>
                        <OnBoardingSideBar activePage={5} />
                    </Grid>
                    <Grid item sm={9} md={9} lg={9} xs={12} style={{
                        padding:'18px',
                        margin:'0px'
                    }}
                    >
                        <Grid container >
                            <Grid item xs={12}>
                                <div style={textlabel_welcomeText} className={`${this.props.classes.theme_font}`}>Themes & Fonts</div>
                            </Grid>
                            <Grid item xs={12} style={{
                            }}>
                                <div style={textlabel_Header} className={`${this.props.classes.theme_font}`}>Black Theme</div>
                            </Grid>
                            <Grid item sm={7} md={7} lg={7} xs={12} style={{
                                marginTop: '6px'
                            }}>
                                <div style={textlabel_school_des} className={`${this.props.classes.theme_font}`}>Set a default colour theme for all users at your school. Users can individually set their own theme choice from their profile settings.

                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '5rem',display:'flex', justifyContent:'center' }} >
                                <Grid container spacing={4} >
                                    {this.state.themeDetails.map((_data: any, index: any) => {
                                        return (
                                            <Grid item sm={4} md={4} lg={4} xs={6} style={this.state.selectedTheme.length !== 0 && this.state.selectedTheme?.type === "theme_setting" && this.state.selectedTheme.id == _data.id ? selectedThemeStyle : themeStyle} onClick={() => {
                                                if (this.state.selectedTheme.length !== 0 && this.state.selectedTheme.id == _data.id) {
                                                    this.setState({ selectedTheme: {} })
                                                }
                                                else {
                                                    this.setState({ selectedTheme: this.state.themeDetails[index] })
                                                }
                                            }}>
                                                <Grid container spacing={0} style={{ borderRadius: '7px', border: "solid 1px #979797", padding: '7px 0px 5px', backgroundColor: _data.attributes.background_color }}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                            borderRadius: 4,
                                                            margin: "0 6px 3px 9px",
                                                            height: '10px',
                                                            backgroundColor: _data.attributes.primary_color
                                                        }}
                                                    ></Grid>
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        style={{
                                                            borderRadius: 6,
                                                            margin: "3px 2px 5px 10px",
                                                            height: '6px',

                                                            backgroundColor: _data.attributes.primary_color
                                                        }}
                                                    ></Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                            margin: "2px 0px",
                                                            height: '19px',
                                                            backgroundColor: _data.attributes.title_font_color
                                                        }}
                                                    ></Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        style={{ margin: "4px 0", padding: '0 11px' }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center'

                                                            }}
                                                        ><div style={{

                                                            borderRadius: "50%",
                                                            height: "12px",
                                                            width: "12px",
                                                            backgroundColor: `${_data.attributes.icons_color}`
                                                        }}></div> </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{ borderRadius: 5, height: '10px', backgroundColor: `${_data.attributes?.subtitle_font_color}` }}
                                                        ></Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        style={{ margin: "4px 0", padding: '0 11px' }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center'

                                                            }}
                                                        ><div style={{

                                                            borderRadius: "50%",
                                                            height: "12px",
                                                            width: "12px",
                                                            backgroundColor: `${_data.attributes?.icons_color}`
                                                        }}></div> </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{ borderRadius: 5, height: '10px', backgroundColor: `${_data.attributes?.subtitle_font_color}` }}
                                                        ></Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        style={{ margin: "4px 0", padding: '0 11px' }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center'

                                                            }}
                                                        ><div style={{

                                                            borderRadius: "50%",
                                                            height: "12px",
                                                            width: "12px",
                                                            backgroundColor: `${_data.attributes?.icons_color}`
                                                        }}></div> </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{ borderRadius: 5, height: '10px', backgroundColor: `${_data.attributes?.subtitle_font_color}` }}
                                                        ></Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        style={{ backgroundColor: _data.attributes.title_font_color, margin: "4px 0", padding: '4px 11px 5px 10px' }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center'

                                                            }}
                                                        ><div style={{

                                                            borderRadius: "50%",
                                                            height: "12px",
                                                            width: "12px",
                                                            backgroundColor: _data.attributes.icons_color,
                                                            backgroundImage: `${_data.attributes?.icons_color?.length !== 0 ? _data.attributes?.icons_color : "black"} !important`,
                                                        }}></div> </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{ borderRadius: 5, height: '10px', backgroundColor: `${_data.attributes?.subtitle_font_color}` }}
                                                        ></Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        style={{ margin: "4px 0", padding: '0 11px' }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{

                                                                borderRadius: "50%",
                                                                height: "10px",
                                                                width: "10px",

                                                            }}

                                                        ></Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{ borderRadius: 5, height: '10px', backgroundColor: _data.attributes.button_color }}
                                                        ></Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: '3.75rem' }}>
                                <Grid container spacing={4} >
                                    <Grid item xs={12}>
                                        <div style={textlabel_font} className={`${this.props.classes.theme_font}`}>Custom Themes

                                        </div>
                                    </Grid>

                                    {this.state?.customThemeDetails?.length !== 0 && this.state.customThemeDetails.map((_data: any, index: any) => {

                                        return (
                                            <Grid
                                                item
                                                sm={4} md={4} lg={4} xs={6}
                                                style={this.state.selectedTheme?.length !== 0
                                                    && this.state.selectedTheme?.type === 'school_theme_setting'
                                                    && this.state.selectedTheme.id === _data.id
                                                    ?
                                                    selectedThemeStyle
                                                    :
                                                    themeStyle
                                                }
                                                onClick={() => {
                                                    if (this.state.selectedTheme.length !== 0 && this.state.selectedTheme.id == _data.id) {
                                                        this.setState({ selectedTheme: {} })
                                                    }
                                                    else {
                                                        this.setState({ selectedTheme: this.state?.customThemeDetails[index] })
                                                    }
                                                }}>
                                                <Grid container spacing={0} style={{
                                                    borderRadius: '7px', border: "solid 1px #979797", padding: '7px 0px 5px', backgroundColor: _data?.attributes?.background_image?.data?.length === 0 ? _data?.attributes?.background_color : '',
                                                    backgroundImage: _data?.attributes?.background_image?.data[0]?.attributes?.url ? `url(${_data?.attributes?.background_image?.data[0]?.attributes?.url})` : "", backgroundSize: 'cover', backgroundRepeat: 'round'
                                                }}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                            borderRadius: 4,
                                                            margin: "0 6px 3px 9px",
                                                            height: '10px',
                                                            backgroundColor: _data?.attributes?.primary_color
                                                        }}
                                                    ></Grid>
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        style={{
                                                            borderRadius: 6,
                                                            margin: "3px 2px 5px 10px",
                                                            height: '6px',

                                                            backgroundColor: _data?.attributes?.primary_color
                                                        }}
                                                    ></Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                            margin: "2px 0px",
                                                            height: '19px',
                                                            backgroundColor: _data?.attributes?.title_font_color
                                                        }}
                                                    ></Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        style={{ margin: "4px 0", padding: '0 11px' }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center'

                                                            }}
                                                        ><div style={{

                                                            borderRadius: "50%",
                                                            height: "12px",
                                                            width: "12px",
                                                            backgroundColor: `${_data?.attributes?.icons_color}`
                                                        }}></div> </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{ borderRadius: 5, height: '10px', backgroundColor: `${_data.attributes?.subtitle_font_color}` }}
                                                        ></Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        style={{ margin: "4px 0", padding: '0 11px' }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center'

                                                            }}
                                                        ><div style={{

                                                            borderRadius: "50%",
                                                            height: "12px",
                                                            width: "12px",
                                                            backgroundColor: `${_data.attributes?.icons_color}`
                                                        }}></div> </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{ borderRadius: 5, height: '10px', backgroundColor: `${_data.attributes?.subtitle_font_color}` }}
                                                        ></Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        style={{ margin: "4px 0", padding: '0 11px' }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center'

                                                            }}
                                                        ><div style={{

                                                            borderRadius: "50%",
                                                            height: "12px",
                                                            width: "12px",
                                                            backgroundColor: `${_data.attributes?.icons_color}`
                                                        }}></div> </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{ borderRadius: 5, height: '10px', backgroundColor: `${_data.attributes?.subtitle_font_color}` }}
                                                        ></Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        style={{ backgroundColor: _data.attributes.title_font_color, margin: "4px 0", padding: '4px 11px 5px 10px' }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center'

                                                            }}
                                                        ><div style={{

                                                            borderRadius: "50%",
                                                            height: "12px",
                                                            width: "12px",
                                                            backgroundColor: _data.attributes.icons_color,
                                                            backgroundImage: `${_data.attributes?.icons_color?.length !== 0 ? _data.attributes?.icons_color : "black"} !important`,
                                                        }}></div> </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{ borderRadius: 5, height: '10px', backgroundColor: `${_data.attributes?.subtitle_font_color}` }}
                                                        ></Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        style={{ margin: "4px 0", padding: '0 11px' }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{

                                                                borderRadius: "50%",
                                                                height: "10px",
                                                                width: "10px",

                                                            }}

                                                        ></Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{ borderRadius: 5, height: '10px', backgroundColor: _data.attributes.button_color }}
                                                        ></Grid>
                                                    </Grid>

                                                </Grid>
                                                {/* <div style={{ width: '100%', height: '177px',border: 'solid 1px #979797', borderRadius: '23px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}
                                                {this.state.selectedTheme.length !== 0 && this.state.selectedTheme.id == _data.id && <Button
                                                    className="backgroundColorPicker"
                                                    style={{
                                                        backgroundImage: "linear-gradient(289deg, #aca2ff 119%, #776be8)",
                                                        borderRadius: '6px',
                                                        color: "#fff",
                                                        padding: '1px 20px 1px 21px',
                                                        textTransform: "capitalize",
                                                        fontSize: 22,

                                                        position: 'absolute',


                                                    }}
                                                    onClick={(event: any) => {
                                                        this.onCustomClick(event, _data)
                                                    }}
                                                //type="submit"

                                                >
                                                    Customize

                                                </Button>}
                                                {/* </div> */}
                                            </Grid>
                                        )
                                    })}

                                </Grid>
                            </Grid>


                        </Grid>

                        <Grid item xs={12} style={{
                            margin: '3.125rem 0px 10px 0px ',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                            <Button
                                style={{
                                    backgroundImage: "linear-gradient(289deg, #aca2ff 119%, #776be8)",
                                    borderRadius: '8px',
                                    color: "#fff",
                                    textTransform: "capitalize",
                                    fontSize: '1rem',

                                }}
                                onClick={() => {
                                    this.onApplyTheme()
                                }}

                            >
                                Apply
                            </Button>
                        </Grid>
                    </Grid>
                    <AlertModal
                        alertModal={this.state.alertModal}
                        isTeacher={false}
                        onCloseModal={() => { this.setState({ alertModal: false }) }}
                        alertTitle="Alert!"
                        alertMessage={this.state.alertMessage}
                        buttonText="OK"
                    />

                </Grid>

            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(ThemesAndFonts, 'SuperAdminAccount'));
// Customizable Area End