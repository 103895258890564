// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    data: any;
    uploadClass: boolean;
    addClass: boolean;
    pagination_page: number;
    schoolData: any;
    timeLineMeta: any;
    anchorEl: any;
    deleteAlertModal: boolean;
    school_class_id: any;
    filterAnchorEl: any;
    EditClass: boolean;
    subjectUrlEndPoint: string;
    divisionUrlEndPoint: string;
    className: string;
    grade_id: any;
    subjectData: any;
    divisionData: any;
    showLoader: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AdminClassesAndSectionsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getSchoolListId: string = "";
    child: React.RefObject<any>;
    // Customizable Area End

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.child = React.createRef();
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            data: '',
            uploadClass: false,
            addClass: false,
            pagination_page: 0,
            schoolData: [],
            timeLineMeta: [],
            anchorEl: null,
            deleteAlertModal: false,
            school_class_id: [],
            filterAnchorEl: null,
            EditClass: false,
            subjectUrlEndPoint: '',
            divisionUrlEndPoint: '',
            className: '',
            grade_id: '',
            subjectData: [],
            divisionData: [],
            showLoader: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.userdata = this.userdata.bind(this);
        this.get_School_Lists = this.get_School_Lists.bind(this);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.get_School_Lists(this.state.pagination_page + 1);
    }

    onCloseModal = (closeModal: boolean) => {
        this.setState({ uploadClass: closeModal })
        this.get_School_Lists(this.state.pagination_page + 1);
    }
    onAddCloseModal = (closeModal: boolean) => {
        this.setState({ addClass: false, anchorEl: null })
        this.get_School_Lists(this.state.pagination_page + 1);
    }
    onDeleteCloseModal = (closeModal: boolean) => {
        this.setState({ deleteAlertModal: closeModal, anchorEl: null })
        this.get_School_Lists(this.state.pagination_page + 1);
    }
    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.getSchoolListId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        this.setState({ schoolData: responseJson.data, timeLineMeta: responseJson.meta, showLoader: false })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }


        }
    }
    get_School_Lists = (page: any) => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.dashboarContentType,

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSchoolListId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.schoolClassEndPoint + `?page=${page}&school_id=${school_Data.school_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    applyFilter = (id?: any, name?: any, division?: any, subjects?: any) => {
        this.setState({ showLoader: true })
        this.setState({
            grade_id: id,
            className: name,
            subjectData: subjects,
            divisionData: division,
        })
        let subjectUrlEnd = this.state.subjectUrlEndPoint
        subjects.map((data: any) => {
            if (data.checked) {
                subjectUrlEnd += `subjects_name[]=${data.subject_name}&`
            }
        })
        let divisionUrlEnd = this.state.divisionUrlEndPoint
        division.map((data: any) => {
            if (data.checked) {
                divisionUrlEnd += `division_ids[]=${data.id}&`
            }
        })
        this.apply_Filter(id, name, divisionUrlEnd, subjectUrlEnd)
    }
    apply_Filter = (id?: any, name?: any, divisionUrlEnd?: string, subjectUrlEnd?: string) => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.dashboarContentType,

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSchoolListId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.schoolClassEndPoint + `?school_id=${school_Data.school_id}&grade_id=${id}&${subjectUrlEnd}${divisionUrlEnd}class_name=${name}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    search_data = (name?: any) => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.dashboarContentType,

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSchoolListId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.schoolClassEndPoint + `?school_id=${school_Data.school_id}&search_key=${name}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}

