// Customizable Area Start
import React, { useEffect, useState } from "react"
import {
    Box,
    Button,
    Typography,
    Modal,
    Backdrop,
    Grid,
    IconButton,
    Checkbox,
    FormControlLabel,
    Avatar,
    AccordionSummary,
    Accordion,
    AccordionDetails
} from "@material-ui/core"
import {
    makeStyles,
    createStyles,
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Search
} from "@material-ui/icons";
import "./ShareModalPopupStudent.web.css";
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: any) =>
    createStyles({
        SectionHeader: {
            width: '100%',
            height: '58px',
            padding: '0px 15px',
            borderRadius: '6px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: "#f9f8fd",
            "& .MuiAccordionSummary-content": {
                justifyContent: 'space-between',
                alignItems: 'center'
            }
        },
        AccordianHeader: {
            boxShadow: 'none',
            "& .MuiIconButton-edgeEnd": {
                margin: "0px",
                padding: "4px"

            }
        },
        DoneBtn: {
            background: theme.props.buttonColor,
            color: "#fff",
            padding: "12px 32px 12px 32px",
            height: 48,
            margin: "0px 4px",
            '&:hover': {
                background: theme.props.buttonColor,
            }
        },
        CancelBtn: {
            padding: "12px 32px 12px 32px",
            height: 48,
            margin: "0px 4px",
            color: theme.props.buttonColor,

        },
        title_font: {
            color: theme.props.titleColor,
            fontFamily: theme.props.titleFont
        },
        heading_font: {
            color: theme.props.headingColor,
            fontFamily: theme.props.headingFont
        },
        subHeading_font: {
            color: theme.props.subheadingColor,
            fontFamily: theme.props.subheadingFont
        },
        bodyText_font: {
            color: theme.props.textColor,
            fontFamily: theme.props.textFont
        },
        icon_color: {
            color: theme.props.iconColor
        },
        checkbox: {
            color: theme.props.iconColor,
            background: 'transparent'
        }
    })
);
// Customizable Area End

interface IProps {
    // Customizable Area Start
    openModal: any;
    closeModalFun: React.Dispatch<React.SetStateAction<any>>;
    usersList?: any;
    saveSelectedUsers: (data: any[]) => void
    isTeacher?: boolean;
    filterUser: any;
    // Customizable Area End
}

export const ShareModalPopupStudent: React.FC<IProps> = ({
    // Customizable Area Start
    openModal,
    closeModalFun,
    usersList,
    saveSelectedUsers,
    filterUser
    // Customizable Area End
}) => {
    // Customizable Area Start
    const classes = useStyles()
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedUsersId, setSelectedUsersId] = useState<any>(filterUser)
    const [selectedUsers, setSelectedUsers] = useState<any>([])
    const [tempStudentList, setTempStudentList] = useState<any>([])

    useEffect(() => {
        setSelectedUsers(usersList)
    }, [usersList])


    const allChecked = (list: any) => {
        return list.every((item: any) => {
            return selectedUsersId.includes(item);
        })
    }

    const handleDeSelectAll = (studentIds: any) => {
        const newChecked = [...selectedUsersId];
        studentIds.forEach((element: any) => {
            const currentIndex = newChecked.indexOf(element);
            newChecked.splice(currentIndex, 1);
        });
        setSelectedUsersId(newChecked)
    }

    const handleSelectAll = (studentIds: any) => {
        const set = new Set([...selectedUsersId, ...studentIds]);
        setSelectedUsersId([...set])
    }

    const handleSelectAllClick = (studentList: any) => {
        const studentIds = studentList.map((item: any) => item.student_id);
        if (!allChecked(studentIds)) {
            handleSelectAll(studentIds)
        } else {
            handleDeSelectAll(studentIds)
        }
    }

    const handleToggle = (value: any) => () => {
        let valu = parseInt(value)
        const currentIndex = selectedUsersId.indexOf(value);
        const newChecked = [...selectedUsersId];

        if (currentIndex === -1) {
            newChecked.push(valu);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setSelectedUsersId(newChecked);
    };

    const filterStudents = (event: any) => {
        setSearchTerm(event.target.vale)
        let search: any = event.target.value;
        const filtered = Object.keys(selectedUsers).map((member: any) => {

            let tempList = [...selectedUsers[member]];

            return {
                [member]: tempList.filter((student: any) => {
                    const fullName = `${student.first_name} ${student.last_name}`
                    if (search.trim()) {
                        return (
                            (student.first_name &&
                                student.first_name
                                    .toLowerCase()
                                    .includes(search.trim().toLowerCase())) ||
                            (student.last_name &&
                                student.last_name
                                    .toLowerCase()
                                    .includes(search.trim().toLowerCase())) ||
                            (fullName &&
                                fullName
                                    .toLowerCase()
                                    .includes(search.trim().toLowerCase())) ||
                            (student.email &&
                                student.email
                                    .toLowerCase()
                                    .includes(search.trim().toLowerCase()))
                        );
                    } else {
                        return true;
                    }
                })
            }
        })

        const updatedList: any = {};
        filtered.map((item: any) => {
            updatedList[Object.keys(item)[0]] = item[Object.keys(item)[0]];
        })
        setTempStudentList(updatedList)
    }
    // Customizable Area End


    // Customizable Area Start
    return (
        <div>
            <Modal className="shareModalPopupContent"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={closeModalFun}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                >
                <div className="addTaskPopup">
                    <Grid item xs={12} className="addTaskPopupGrid">
                        <Grid container spacing={0} >
                            <div className="iconButton">
                                <IconButton onClick={closeModalFun} className="closeIcon">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <Grid item xs={12} className={"parentGrid"}>
                                <Grid container className="m4">
                                    <Grid item xs={12} className="addStudentGrid">
                                        <Typography component={"h2"} className={`${classes.title_font} taskTitle`}>
                                            Add Student(s)
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12}>
                                        <Box display="flex" alignItems="center" className="boxStyle">
                                            <Search className={`${classes.icon_color} shareSearchIcon`} />
                                            <input
                                                className={` ${classes.bodyText_font} searchParticipantInput`}
                                                placeholder="Search for a participants"
                                                value={searchTerm}
                                                onChange={
                                                    ((event: React.ChangeEvent<HTMLInputElement>) => {
                                                        filterStudents(event)
                                                    })}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container className="studentListGrid">
                                    <Grid xs={12} className="studentInnerGrid">
                                        <Typography className={`${classes.heading_font} studentListTitle`} >
                                            Student List
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12} className="searchItemGrid">
                                        {
                                            searchTerm === '' ?
                                                Object.keys(selectedUsers).map((item: any) => {
                                                    return (
                                                        <>
                                                            <Grid xs={12} className="accordianGrid">
                                                                <Accordion className={classes.AccordianHeader}>
                                                                    <AccordionSummary
                                                                        id={"accordian_" + item}
                                                                        className={`${classes.SectionHeader} accordianSummary`}
                                                                        expandIcon={<ExpandMoreIcon />}>
                                                                        <Typography component={"h4"}>
                                                                            {item}
                                                                        </Typography>
                                                                        <FormControlLabel
                                                                            aria-label="Select All"
                                                                            onClick={(event) => event.stopPropagation()}
                                                                            onFocus={(event) => event.stopPropagation()}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={
                                                                                        allChecked(selectedUsers[item].map((item: any) => item.student_id)) ? true : false
                                                                                    }
                                                                                    onChange={(event: any) => handleSelectAllClick(selectedUsers[item])} />
                                                                            }
                                                                            label={"Select All"}
                                                                            labelPlacement='start'
                                                                        />
                                                                    </AccordionSummary>
                                                                    <AccordionDetails className="accordianDetails">
                                                                        {
                                                                            selectedUsers[item].length === 0
                                                                                ?
                                                                                <>
                                                                                    <div className="noDataTextBox">
                                                                                        <Typography className={`noDataFound ${classes.bodyText_font}`}> No Data Found.</Typography>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        selectedUsers[item].map((student: any) => {
                                                                                            return (
                                                                                                <Grid key={student.student_id} item xs={12}>
                                                                                                    <Grid container spacing={3} className="selectedUserGrid" onClick={ handleToggle(student.student_id)}>
                                                                                                        <Grid className="avtarGrid" item xs={2}>
                                                                                                            <Avatar className="userAvtar" src={student?.avatar} />
                                                                                                        </Grid>
                                                                                                        <Grid item xs={8}>
                                                                                                            <Typography className={`userFLname ${classes.subHeading_font}`}>
                                                                                                                {student?.first_name + ' ' + student?.last_name}
                                                                                                            </Typography>
                                                                                                            <Typography className={`studentEmail ${classes.bodyText_font}`}>
                                                                                                                {student?.email}
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                        <Grid className="justifyEnd" item xs={2}>
                                                                                                            <Checkbox checked={selectedUsersId.indexOf(student?.student_id) > -1 || selectedUsersId.indexOf(parseInt(student?.student_id)) > -1} className={classes.checkbox}
                                                                                                                inputProps={{
                                                                                                                    "aria-labelledby":
                                                                                                                        "checkbox-list-secondary-label-question",
                                                                                                                }}
                                                                                                            />
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                        </>
                                                    )
                                                }) :
                                                Object.keys(tempStudentList).map((item: any) => {
                                                    return (
                                                        <>
                                                            <Grid xs={12} className="accordianGrid">
                                                                <Accordion className={classes.AccordianHeader}>
                                                                    <AccordionSummary
                                                                        id={"accordian_" + item}
                                                                        className={`accordianSummary ${classes.SectionHeader}`}
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        >
                                                                        <Typography component={"h4"}>
                                                                            {item}
                                                                        </Typography>

                                                                        <FormControlLabel
                                                                            aria-label="Select All"
                                                                            onClick={(event) => event.stopPropagation()}
                                                                            onFocus={(event) => event.stopPropagation()}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={
                                                                                        tempStudentList[item].length === 0 ? false : allChecked(tempStudentList[item].map((item: any) => item.student_id)) ? true : false
                                                                                    }
                                                                                    onChange={(event: any) => handleSelectAllClick(tempStudentList[item])} />
                                                                            }
                                                                            label={"Select All"}
                                                                            labelPlacement='start'
                                                                        />
                                                                    </AccordionSummary>
                                                                    <AccordionDetails className="accordianDetails">
                                                                        {
                                                                            tempStudentList[item].length === 0
                                                                                ?
                                                                                <>
                                                                                    <div className="noDataTextBox">
                                                                                        <Typography className={`noDataFound ${classes.bodyText_font}`}> 
                                                                                            No Data Found.
                                                                                        </Typography>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        tempStudentList[item].map((student: any) => {
                                                                                            return (
                                                                                                <Grid key={student.student_id} item xs={12}>

                                                                                                    <Grid container spacing={3} className="selectedUserGrid"
                                                                                                        onClick={handleToggle(student.student_id)}>
                                                                                                        <Grid item xs={2} className="avtarGrid">
                                                                                                            <Avatar className="userAvtar" src={student?.avatar}/>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={8}>
                                                                                                            <Typography className={`userFLname ${classes.subHeading_font}`}>
                                                                                                                {student?.first_name + ' ' + student?.last_name}
                                                                                                            </Typography>
                                                                                                            <Typography className={`studentEmail ${classes.bodyText_font}`}>
                                                                                                                {student?.email}
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                        <Grid className="justifyEnd" item xs={2}>
                                                                                                            <Checkbox checked={selectedUsersId.indexOf(student?.student_id) > -1 || selectedUsersId.indexOf(parseInt(student?.student_id)) > -1}
                                                                                                                className={classes.checkbox}
                                                                                                                inputProps={{
                                                                                                                    "aria-labelledby":
                                                                                                                        "checkbox-list-secondary-label-question",
                                                                                                                }}
                                                                                                            />
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                        </>
                                                    )
                                                })
                                        }

                                    </Grid>
                                </Grid>
                                <Grid container spacing={4} className="studentListGrid">
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center">
                                            <Button className={classes.DoneBtn} onClick={(event: any) => { saveSelectedUsers(selectedUsersId) }}>
                                                Done
                                            </Button>
                                            <Button className={classes.CancelBtn} onClick={closeModalFun}>
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </div>
    )
}

// Customizable Area Start
export default ShareModalPopupStudent
// Customizable Area End