Object.defineProperty(exports, "__esModule", {
  value: true
});
const urlConfig = require("../../../../framework/src/config");
// Customizable Area Start
exports.studentResourceFolder = "bx_block_fileattachment/resource_folders";
exports.createResource = "bx_block_fileattachment/resource_uploads"
exports.studentGetResource = "bx_block_fileattachment/resource_uploads/user_resource_uploads";
exports.getChartTeacher = "bx_block_attendance/attendances/get_attendance_report";
exports.getTeacherAttendance = "bx_block_attendance/attendances/get_class_attendence";
exports.getTeacherAttendanceByFilter = "bx_block_attendance/attendances";
exports.getStudentAttendance = "bx_block_attendance/attendances/get_student_attendance";
exports.getStudentAttendanceReport = "bx_block_attendance/attendances/get_student_attendance_report_graph";
exports.createTeacherAttendanceApi = "bx_block_attendance/attendances"
exports.deleteAll = "bx_block_fileattachment/resource_uploads/delete_all_resource_file_folders"
exports.deleteSelected = "bx_block_fileattachment/resource_folders/delete_folders_and_files"
exports.updateTeacherAttendanceApi = "bx_block_attendance/attendances"

exports.getClasses = "/bx_block_scheduling/school_classes?page=1&per=100"; // "/bx_block_scheduling/classes/";
exports.getSubjectsByClassId = "/bx_block_scheduling/subjects/class_section_subjects"

exports.getTeachersByClassId = "/bx_block_scheduling/classes/get_class_teachers"
exports.getStudentsByClassIdAndSubjectId = "/bx_block_scheduling/classes/get_students_by_class_and_subject"
exports.getTeachePeriodEndPoint =
  "/bx_block_scheduling/periods/get_teacher_period_list";

exports.resourceContentType = "application/json";
exports.resourceApiMethodType = "GET";
exports.resourceApiPutMethodType = "PUT";
exports.resourceApiPostMethodType = "POST";
exports.resourceApiDeleteMethodType = "DELETE";
exports.resourceHost = "<calculated when request is sent>";
exports.resourceUserAgent = "PostmanRuntime/7.26.5";
exports.resourceAccept = "*/*";
exports.resourceAcceptEncoding = "gzip, deflate, br";
exports.resourceConnection = "keep-alive";
exports.resourcetoken = "";
exports.labelTitleText = "resource";
exports.labelBodyText = "resource Body";
exports.instituteURL = urlConfig.instituteURL;
exports.resourceURL = urlConfig.attendanceURL;
// Customizable Area End