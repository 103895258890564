// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { List, ListItem,FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Grid ,Typography} from '@material-ui/core';
import { HISTORY } from "../../../../components/src/comman";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
}),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    }
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

function createData(title: string, term: number, term2: number, term3: number) {
  return { title, term, term2, term3 };
}

const rows = [
  createData('Total Attendence of the learner', 20, 10, 5),
  createData('Total Number of working days', 20, 10, 4.0)
];


function ReportCardOneSixTen(props:any) {
  const classes = useStyles();
  const [selectclass, setSelectclass] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date('2014-08-18T21:11:54'),
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectclass(event.target.value as string);
  };
  
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const handleBack = () => {
    HISTORY.goBack();
  }
  return (
    <div>
      <div className='profileTitle'>
      <Button className="backBtn" onClick={()=>handleBack()}><KeyboardBackspaceOutlinedIcon /> Back</Button>
      <h2 className={props.classes.heading_font}>Part 1 - Assessment of Learning </h2>
      <Button className={props.classes.button_color} style={{position: "absolute",right: 0,marginRight: "10px"}}>Enable Edit <EditOutlinedIcon /> </Button>
      </div>
      <div className="tableContent">
          {/*  table 1 start */}
          <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={9} align="center" className={props.classes.bodyText_font} style={{borderRadius: "0px" }}>
                                Term 1 (Schlotic Areas)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography>  Subject</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} align="center" style={{borderRadius:"0px"}}>
                                <Typography>
                                    T1
                                    </Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >
                                        (10)
                                    
                                </Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} align="center" style={{borderRadius:"0px"}}>
                                <Typography>
                                    T2
                                    </Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >
                                        (10)
                                  
                                </Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} align="center" style={{borderRadius:"0px"}}>
                                <Typography>
                                    T3
                                    </Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >
                                        (10)
                                    
                                </Typography>
                            </TableCell>
                            <TableCell align="center" className={props.classes.reportCardOneSixTen_classAndSection_tabl_Cell}  style={{borderRadius:"0px" }}>
                                <Typography>
                                    Best of 3
                                    </Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >
                                        (10)
                                   
                                </Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} align="center" style={{borderRadius:"0px"}}>
                                <Typography>
                                    Notebook
                                    </Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >
                                        (05)
                                  
                                </Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} align="center" style={{borderRadius:"0px"}}>
                                <Typography>
                                    Subject
                                    </Typography>
                                    <Typography>
                                        Enrichment
                                        </Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >
                                        (05)
                                    </Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} align="center" style={{borderRadius:"0px"}}>
                                <Typography>
                                    Half-Yearly
                                    </Typography>
                                    <Typography>
                                        Exam
                                    </Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >
                                        (80)
                                    </Typography>
                            </TableCell>
                            <TableCell align="center" className={props.classes.reportCardOneSixTen_classAndSection_tabl_Cell} style={{borderRadius:"0px"}}>
                                <Typography>
                                    Marks
                                    </Typography>
                                    <Typography>
                                        Obtained
                                    </Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >
                                        (100)
                                    </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                                <Typography>  Language 1:<br/>English</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography>Language 2</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography> Language 3</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography> Mathematics</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography> Science</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography>  Social Science</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography> Computer Science</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            {/* table 1 end */}

              {/* Box 1 start */}
              <Box className={props.classes.reportCardOneSixTen_classAndSection_box_all}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography align="center" className={props.classes.bodyText_font} style={{ borderRight: "1px solid #D9D9D9",height: "130px",paddingTop: "45px",backgroundColor: "#F2F2F2"}}>Remarks by the <br/>Class Teachers:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                    </Grid>
                </Grid>
            </Box>
            {/* Box 1 end */}
            {/* Box 2 start */}
            <Box className={props.classes.reportCardOneSixTen_classAndSection_box_all}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography align="center" className={props.classes.bodyText_font} style={{ borderRight: "1px solid #D9D9D9",height: "130px",paddingTop: "45px",backgroundColor: "#F2F2F2"}}>Signature <br/>of the:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography className={props.classes.reportCardOneSixTen_classAndSection_grid_typography}>  Student </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={props.classes.reportCardOneSixTen_classAndSection_grid_typography}> Class Teacher</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={props.classes.reportCardOneSixTen_classAndSection_grid_typography}> ACo</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={props.classes.reportCardOneSixTen_classAndSection_grid_typography}>Principal</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={props.classes.reportCardOneSixTen_classAndSection_grid_typography}> Parent</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {/* Box 2 end */}
            {/* table 2 start */}
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={9} align="center" style={{ borderRadius:"0px",fontWeight: 600, backgroundColor: "#ded9ff", borderTopLeftRadius: "5px" }}>
                                Term 2 (Schlotic Areas)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography> Subject</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} align="center" style={{borderRadius:"0px"}}>
                                <Typography>
                                    T4
                                    </Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >
                                        (10)
                                    </Typography>
                                
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} align="center" style={{borderRadius:"0px"}}>
                                <Typography>
                                    T5
                                    </Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >
                                        (10)
                                    </Typography>
                            </TableCell>
                            <TableCell align="center" className={props.classes.reportCardOneSixTen_classAndSection_tabl_Cell}  style={{borderRadius:"0px" }}>
                                <Typography> Best of 2</Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} > (10)
                                    
                                </Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} align="center" style={{borderRadius:"0px"}}>
                                <Typography> Notebook</Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >(05)
                                    </Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} align="center" style={{borderRadius:"0px"}}>
                                <Typography>  Subject Enrichment</Typography>
                                <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >
                                        (05)
                                    </Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} align="center" style={{borderRadius:"0px"}}>
                                <Typography>Annual</Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >
                                        (05)
                                </Typography>
                            </TableCell>
                            <TableCell align="center" className={props.classes.reportCardOneSixTen_classAndSection_tabl_Cell}  style={{borderRadius:"0px" }}>
                                <Typography> Total Marks </Typography><Typography>Obtained</Typography>
                                    <Typography className={props.classes.reportCardOneSixTen_classAndSection_typography_padding} >
                                        (100)
                                    </Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} align="center" style={{borderRadius:"0px"}}>
                            <Typography> Grade</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography> Language 1:<br/>English</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography> Language 2</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography> Language 3</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography> Mathematics</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography>  Science</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography>  Social Science</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_tabBodyCel} style={{borderRadius: "0px" }}>
                            <Typography>Computer Science</Typography>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={props.classes.reportCardOneSixTen_classAndSection_cell_border} style={{borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            {/* table 2 end */}
            {/* Box 3 start */}
            <Box className={props.classes.reportCardOneSixTen_classAndSection_box_all} >
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography align="center" className={props.classes.bodyText_font} style={{ borderRight: "1px solid #D9D9D9",height: "130px",paddingTop: "45px",backgroundColor: "#F2F2F2"}}>Remarks by the <br/>Class Teachers:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                    </Grid>
                </Grid>
            </Box>
            {/* Box 3 end */}
            {/* Box 4 start */}
            <Box className={props.classes.reportCardOneSixTen_classAndSection_box_all}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography align="center" className={props.classes.bodyText_font} style={{ borderRight: "1px solid #D9D9D9",height: "130px",paddingTop: "45px",backgroundColor: "#F2F2F2"}}>Signature <br/>of the:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography className={props.classes.reportCardOneSixTen_classAndSection_grid_typography}>  Student </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={props.classes.reportCardOneSixTen_classAndSection_grid_typography}> Class Teacher</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={props.classes.reportCardOneSixTen_classAndSection_grid_typography}> ACo</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={props.classes.reportCardOneSixTen_classAndSection_grid_typography}>Principal</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={props.classes.reportCardOneSixTen_classAndSection_grid_typography}> Parent</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {/* Box 4 end */}
      </div>
    </div>
  )
}
// Customizable Area End
  
// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(ReportCardOneSixTen as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End