import React from "react";
// Customizable Area Start
import { Box, Typography, Grid, Button, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { imgBackArrowSVG, arrowDropdownBlueSVG } from "../assets";
import Spinner from "../../../shared/SideBar/src/Spinner";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import './AssessmentEvaluation.web.css';
import StudentAssessmentAnswersheetController from "./StudentAssessmentAnswersheetController.web";
import QuestionSection from "./QuestionSection.web";
import { withStyles } from '@material-ui/core/styles';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class StudentAssessmentAnswersheet extends StudentAssessmentAnswersheetController {
    // Customizable Area Start
    render() {
        const { sectionList, sectionListMeta } = this.state;

        return (
            <>
                {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
                <div>
                    <div className="status_and_marks_section">
                        <div className="status_and_marks_section">
                            <Typography data-test-id="student-evaluation-detail-back-icon" className={`back_text_evaluation ${this.props.classes.heading_font}`} onClick={this.handleBack}>
                                <img src={imgBackArrowSVG} className="back_arrow_img" alt="back"></img>
                                Back
                            </Typography>
                            <Typography className={`textlabel_answersheet ${this.props.classes.heading_font}`}>Answer Sheet</Typography>
                        </div>

                        <Button className={`raise_query_btn ${this.props.classes.outline_button_no_hover} ${this.props.classes.theme_font}`} onClick={this.onClickRaiseQuery}>Raise Query</Button>
                    </div>

                    {sectionList?.map((data: any) => (
                        <Accordion className="evaluation_accordion" key={data?.id}
                            data-test-id="student-evaluation-accordion-item"
                            onChange={(e: any, expanded: any) => {
                                if (expanded && !data.questions?.length) {
                                    this.sectionClick(Number(data?.id));
                                }
                            }}>
                            <AccordionSummary
                                expandIcon={<img src={arrowDropdownBlueSVG} alt="arrow" />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid container>
                                    <Grid item xs={12} sm={8} md={9}>
                                        <Typography className="evaluation_section_title">{data?.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <div className="section_rectangle_right">
                                            <Typography className="section_total_marks">Total Marks: {data?.section_marks}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className="evaluation_question_detail_section">
                                {data?.questions?.length > 0 &&
                                    data?.questions?.map((questionData: any, index: number) => (
                                        <React.Fragment key={questionData.id}>
                                            <QuestionSection
                                                index={index}
                                                questionData={questionData}
                                                previewImageModal={this.state.previewImageModal}
                                                previewImageModalClose={this.previewImageModalClose}
                                                previewImageSrc={this.state.previewImageSrc}
                                                setPreviewImageSrc={this.setPreviewImageSrc}
                                                isTeacher={false}
                                            />
                                        </React.Fragment>
                                    ))}
                                {data?.meta_data?.pagination?.next_page && (
                                    <div className="load-more-que-btn-div ">
                                        <Button
                                            variant="outlined"
                                            data-test-id="load-more-question-student-btn"
                                            className={`load-more-que-btn ${this.props.classes.button_color}`}
                                            onClick={() => this.sectionClick(Number(data?.id))}
                                        >
                                            Load More...
                                        </Button>
                                    </div>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))}

                    {sectionListMeta?.pagination?.next_page && (
                        <div className="load-more-que-btn-div ">
                            <Button
                                variant="outlined"
                                className={`load-more-que-btn ${this.props.classes.button_color}`}
                                onClick={this.handleSectionLoadMore}
                            >
                                Load More...
                            </Button>
                        </div>
                    )}
                </div>
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start

export default withStyles(themeCreateStyle)(
    HOCAuth(StudentAssessmentAnswersheet as React.ComponentType<any>, "StudentAccount")
);
// Customizable Area End
