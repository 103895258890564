// Customizable Area Start

import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import Grid from "@material-ui/core/Grid";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Steppers from "./Steppers.web";
import './AccountPhoneVerify.web.css'
import {
  img21kSchholLogo,
  successFull,
  pending,
} from "./assets";
import AccountSucessFullController, {
  Props,
} from "./AccountSucessFullController.web";
import { Typography } from "@material-ui/core";
import "./AccountSucessFull.web.css";
import { withStyles } from '@material-ui/core'
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class AccountSucessFull extends AccountSucessFullController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    return (
      <div className="AccountSuccessBox">
        <div className="Header accountRegistration_div">
          <img src={img21kSchholLogo} alt="Logo Image" className="logoImage" />
          <HelpIcon className="accountRegistration_helpIcon" />
        </div>
        <div className="accountRegistration_footer">
          <IconButton
            aria-label="delete"
            className="arrow_style_button_left"
            color="primary"
            onClick={() => this.onBackArrowAccountSucessFull()}
          >
            <ArrowBackRoundedIcon className="white-back-arrow" />
          </IconButton>
          <div className="account_created_div typesofAccount_div">
            <div>
              <Steppers activeStep={3} />
            </div>

            <Grid container>
              <Grid item xs={12} spacing={3} className="textfield_div">
                <div className="successfull_div">
                  <Grid container className="success_container">
                    <Grid item xs={2}>
                      <img src={successFull} alt="Loading.." className="successFull_img" />
                    </Grid>
                    <Grid item xs={10}>
                      <div className={`successFull_text typesofAccount_textLabel ${this.props.classes.theme_font}`}>
                        Account Created Successfully
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} spacing={3} className="textfield_div">
                <div className="pending_approval_div">
                  <Grid container className="success_container">
                    <Grid item xs={12}>
                      <img src={pending} alt="Loading.." className="pending_img" />

                    </Grid>
                    <Grid item xs={12}>
                      <div className={`pending_approval_text requestTest ${this.props.classes.theme_font}`}>
                        Pending Approval
                      </div>
                    </Grid>
                    <Grid item xs={12} className="pending_approval_sub_div">
                      <div className={`Follow_the_instructions ${this.props.classes.theme_font}`}>
                        Thank you for your valuable time in completing  your registration with <span className="reg_name_text">{this.state.data.school_account?.data[0]?.attributes?.school?.name}.</span>

                        <br />
                        We would get back to you with an update shortly.
                      </div>
                      <Grid container >
                        <Grid container className="account_detail_container xs_mt_15">
                          <Grid item xs={12} sm={4} className="justify_content_end xs_justify_content_center">
                            <Typography className="account_detail_title">Name</Typography>
                          </Grid>

                          <Grid item xs={12} sm={7} className="justify_content_start xs_justify_content_center">
                            <Typography className="account_detail_text">
                              {`${this.state.data?.first_name} ${this.state.data?.last_name}`}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container className="account_detail_container xs_mt_15">
                          <Grid item xs={12} sm={4} className="justify_content_end xs_justify_content_center">
                            <Typography className="account_detail_title">Email</Typography>
                          </Grid>

                          <Grid item xs={12} sm={7} className="justify_content_start xs_justify_content_center">
                            <Typography className="account_detail_text">
                              {this.state.data?.email}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container className="account_detail_container xs_mt_15">
                          <Grid item xs={12} sm={4} className="justify_content_end xs_justify_content_center">
                            <Typography className="account_detail_title">Mobile Number</Typography>
                          </Grid>

                          <Grid item xs={12} sm={7} className="justify_content_start xs_justify_content_center">
                            <Typography className="account_detail_text">
                              +{this.state.data?.full_phone_number}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className="success_okay_div">
                          <Button className="success_okay_btn"
                            onClick={this.backtologinhandle}
                          >
                            Okay
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(AccountSucessFull);
// Customizable Area End
