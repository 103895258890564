// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
// Customizable Area End
// Customizable Area Start
const CustomToolbar = (props: any) => {
  // Customizable Area Start
  const [viewState, setViewState] = useState("week");
  // Customizable Area End

  // Customizable Area Start
  const goToWeekView = () => {
    props.onView("week");
    setViewState("week");
  };
  const goToMonthView = () => {
    props.onView("month");
    setViewState("month");
  };
  // Customizable Area End

  // Customizable Area Start
  useEffect(() => {
    // console.log(props);
  }, []);
  // Customizable Area End


  return (
    <></>
    // <div
    //   style={{
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "flex-end",
    //     position: "absolute",
    //     top: "2%",
    //     right: "2%",
    //     zIndex: 100,
    //   }}
    // >
    //   <Button
    //     style={{
    //       width: "113px",
    //       height: "60px",
    //       marginRight: "10px",
    //       borderRadius: 6,
    //       border:
    //         viewState === "week" ? "1px solid #af38cb" : "1px solid #471c51",
    //       background:
    //         viewState === "week"
    //           ? "linear-gradient(to top, #af38cb, #471c51 200%)"
    //           : "#fff",
    //       textTransform: "capitalize",
    //     }}
    //     onClick={goToWeekView}
    //   >
    //     <Typography
    //       style={{
    //         fontFamily: "Open sans",
    //         fontSize: 18,
    //         fontWeight: 600,
    //         textAlign: "center",
    //         color: viewState === "week" ? "#fff" : "#471c51",
    //       }}
    //     >
    //       Week
    //     </Typography>
    //   </Button>

    //   <Button
    //     style={{
    //       width: "113px",
    //       height: "60px",
    //       borderRadius: 6,
    //       border:
    //         viewState === "month" ? "1px solid #af38cb" : "1px solid #471c51",
    //       background:
    //         viewState === "month"
    //           ? "linear-gradient(to top, #af38cb, #471c51 200%)"
    //           : "#fff",
    //       textTransform: "capitalize",
    //     }}
    //     onClick={goToMonthView}
    //   >
    //     <Typography
    //       style={{
    //         fontFamily: "Open sans",
    //         fontSize: 18,
    //         fontWeight: 600,
    //         textAlign: "center",
    //         color: viewState === "month" ? "#fff" : "#471c51",
    //       }}
    //     >
    //       Month
    //     </Typography>
    //   </Button>
    // </div>
  );
};

export default CustomToolbar;
// Customizable Area End