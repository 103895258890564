// Customizable Area Start
import React from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputBase,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";

import {
    Search,
    Replay,
    NotificationsNone,
    HeadsetMicOutlined,
    Title,
    TitleOutlined,
    KeyboardArrowLeft,
    EditOutlined,
    MoreVertOutlined,
    KeyboardArrowDown
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import SendTeacherController, { Props } from "./SendTeacherController.web";
import 'react-calendar/dist/Calendar.css';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// Customizable Area End

// Customizable Area Start
import {
    deleteAlert,
    SuccessCheckMark,
} from "../assets";
const noBorder = {
    border: "0px",
}
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
const BootstrapInput2 = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        border: "1px solid #ced4da",
        backgroundColor: theme.palette.background.paper,
        padding: "10px 26px 10px 12px",
        width: "108px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: 'Open sans',
        color: "#3f526d",
        fontSize: 18,
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase);
// Customizable Area End

// Customizable Area Start
export default class SendTeacher extends SendTeacherController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        return (
            <>
                {this.state.sucessFullModal ?
                    <>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            open={this.props.sendAlertModal}
                            onClose={this.modalhandleClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                            disableAutoFocus={true}
                        >
                            <Fade in={this.props.sendAlertModal} style={{ border: "none" }}>
                                <div style={{
                                    backgroundColor: "white",
                                    border: "1px solid #979797",
                                    outline: "none",
                                    borderRadius: 8,
                                    width: "auto",
                                    height: "auto",
                                    padding: "23px 83px 33px 83px",
                                    boxShadow: "0 0 24px 4px rgba(0, 0, 0, 0.2)",
                                    textAlign: "center"

                                }}>
                                    <img
                                        src={SuccessCheckMark}
                                        style={{ width: 80, height: 80, objectFit: 'contain' }}
                                    />
                                    <Typography
                                        style={{
                                            fontFamily: "Open sans",
                                            fontSize: "4.4vh",
                                            fontWeight: "bold",
                                            color: "#3f526d",
                                            textAlign: "center",
                                            marginTop: 20
                                        }}
                                    >
                                        Success!
                                    </Typography>

                                    <Typography
                                        style={{
                                            fontFamily: "Open sans",
                                            fontSize: "3vh",
                                            color: "#3f526d",
                                            textAlign: "center",
                                            marginTop: 20
                                        }}
                                    >
                                        Your message has been sent.
                                    </Typography>
                                    <Button
                                        style={{
                                            background: "#7f6cfc",
                                            color: "#fff",
                                            textTransform: "capitalize",
                                            fontSize: 18,
                                            borderRadius: 8,
                                            padding: "13px 49px 13px 50px",
                                            border: "solid 1px #7f6cfc",
                                            marginTop: 42,
                                        }}
                                        onClick={this.modalhandleClose}
                                    >
                                        ok
                                    </Button>
                                </div>
                            </Fade>
                        </Modal>
                    </>
                    :
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        open={this.props.sendAlertModal}
                        onClose={this.modalhandleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        disableAutoFocus={true}
                    >
                        <Fade in={this.props.sendAlertModal} style={{ border: "none" }}>
                            <div style={{
                                backgroundColor: "white",
                                border: "1px solid #979797",
                                outline: "none",
                                borderRadius: 8,
                                width: "auto",
                                height: "auto",
                                padding: "33px 73px 33px 73px",
                                boxShadow: "0 0 24px 4px rgba(0, 0, 0, 0.2)",
                                textAlign: "center"

                            }}>

                                <Typography
                                    style={{
                                        fontFamily: "Open sans",
                                        fontSize: "4.4vh",
                                        fontWeight: "bold",
                                        color: "#3f526d",
                                        marginTop: 30,
                                    }}>
                                    Are you sure <br />
                                    you want to send?
                                </Typography>


                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        marginTop: 59,
                                        alignItems: "center"
                                    }}
                                >
                                    <Button
                                        style={{
                                            background: "#7f6cfc",
                                            color: "#fff",
                                            textTransform: "capitalize",
                                            fontSize: 18,
                                            borderRadius: 8,
                                            padding: "13px 51px 13px 52px",
                                            margin: "0px 50px 0px 0px"
                                        }}
                                        onClick={() => this.send_cre()}
                                    >
                                        Send
                                    </Button>
                                    <Button
                                        style={{
                                            background: "#fff",
                                            color: "#3f526d",
                                            textTransform: "capitalize",
                                            fontSize: 16,
                                        }}
                                        onClick={this.modalhandleClose}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </div>
                        </Fade>
                    </Modal>
                }
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area End