// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { requirePropFactory } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
export const urlConfig = require("../../../framework/src/config")
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  password: string;
  emailerror: boolean;
  emailerrorText: string;
  pswderror: boolean;
  pswderrorText: string;
  loadingSpinner: boolean;
  passwordVisible: boolean,
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SchoolAdminLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  postLoginApiId: any;
  emailReg: RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      email: '',
      password: '',
      emailerror: false,
      emailerrorText: '',
      pswderror: false,
      pswderrorText: '',
      loadingSpinner: false,
      passwordVisible: false,

    };
    this.emailReg = new RegExp("");
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    localStorage.removeItem('fireBaseToken');
    localStorage.removeItem("expireTokenTime")
    localStorage.removeItem("user_login")
    // Customizable Area End
  }

  // Customizable Area Start
  togglePasswordVisibility = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.postLoginApiId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            localStorage.setItem('otptoken', responseJson?.meta?.token);
            localStorage.setItem('school_admin_login', "TRUE");
            this.props.navigation.navigate("EmailLoginOTP");
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
    }
  }
  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const pswdspaceReg = /^\S$|^\S[\s\S]*\S$/;
    if (email.length === 0 || password.length === 0) {

      if (
        this.state.email === null ||
        this.state.email.length === 0
      ) {
        this.setState({ emailerror: true, emailerrorText: 'Email is required.' })
      }
      if (this.state.password === null || this.state.password.length === 0) {
        this.setState({ pswderror: true, pswderrorText: 'Password is required.' })
      }
      return false;
    }
    if (!this.emailReg.test(this.state.email)) {
      this.setState({ emailerror: true, emailerrorText: "Enter a valid Email." })
      return false;
    }
    const data = {
      data: {
        attributes: {
          email: email,
          password: password.trim(),
          type: 'AdminAccount'
        }
      }
    }
    this.setState({ loadingSpinner: true })
    const header = {
      'Content-Type': configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postLoginApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End

}
