// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  spinner: boolean,
  tempStudentList: any,
  searchText: string
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  openModal: boolean;
  messageString?: string;
  handleOnClose: React.Dispatch<React.SetStateAction<boolean>>;
  handleOnSchedule: any;
  currentTime?: any;
  studentList?: any;
  updateList?: any;
  checkedStudents?: any;
  handleSelectAll?:any;
  handleDeSelectAll?:any;
  // Customizable Area End
}
export default class AddGroupModalController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {

      spinner: false,
      tempStudentList: [],
      searchText: ''
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      tempStudentList: this.props.studentList
    })
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.currentTime !== prevProps.currentTime) {
      this.setState({ tempStudentList: this.props.studentList })
    }
  }



  filterStudents = (event: any) => {

    this.setState({ searchText: event.target.value });

    let search = event.target.value;

    const filtered = Object.keys(this.props.studentList).map((member: any) => {

      let tempList = [...this.props.studentList[member]];

      return {
        [member]: tempList.filter((student: any) => {
          if (search.trim()) {
            return (
              (student.first_name &&
                student.first_name
                  .toLowerCase()
                  .includes(search.trim().toLowerCase())) ||
              (student.last_name &&
                student.last_name
                  .toLowerCase()
                  .includes(search.trim().toLowerCase())) ||
              (student.email &&
                student.email
                  .toLowerCase()
                  .includes(search.trim().toLowerCase()))
            );
          } else {
            return true;
          }
        })
      }
    })

    const updatedList: any = {};
    filtered.map((item: any) => {
      updatedList[Object.keys(item)[0]] = item[Object.keys(item)[0]];
    })

    this.setState({
      tempStudentList: updatedList
    })
  }

  handleSelectAll = (studentList: any) => {
    const studentIds = studentList.map((item: any) => item.student_id);
    if(!this.allChecked(studentIds)){
      this.props.handleSelectAll(studentIds)
    }else{
      this.props.handleDeSelectAll(studentIds)
    }
  }

  allChecked = (list: any) => {
    return list.every((item: any) => {
      return this.props.checkedStudents.includes(item);
    })
  }
  // Customizable Area End
}