// Customizable Area Start
import React from "react";
import ReactPaginate from "react-paginate";
import {
  Button,
  IconButton,
  Typography,
  NativeSelect,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from "@material-ui/core";
import AdminBatchAndPeriodController, {
  Props,
} from "./AdminBatchAndPeriodController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { editDivision, deleteDivision } from "../assets";
import ViewBatch from "./ViewBatch.web";
import AddEditPeriod from "./AddEditPeriod.web";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import DeleteModal from "./DeleteModal.web";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

export class AdminBatchAndPeriod extends AdminBatchAndPeriodController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {

    const {
      periodData,
      batchData,
      openAddEditPeriod,
      isEditPeriod,
      periodName,
      periodNameError,
      periodNameErrorMsg,
      selectedStartTime,
      selectedStartTimePeriodLabel,
      selectedEndTime,
      selectedEndTimePeriodLabel,
      selectedPeriodEndTimeError,
      selectedPeriodEndTimeErrorMsg,
      editPeriodEnable,
    } = this.state;
    return (
      <>
        <div className="BatchPeriodMainDiv">
          {this.state.showLoader && (
            <Spinner spinnerModal={this.state.showLoader} />
          )}
          <div className="BatchPeriodHeadDiv">
            <Typography className={`CreationText ${this.props.classes.title_font}`}>
              Batch & Period Creation
            </Typography>

            <div>
              <Button
                disableRipple={true}
                variant="outlined"
                data-test-id="admin-batch-view-btn"
                className={`ViewBatchBtn ${this.props.classes.button_color}`}
                onClick={() => {
                  this.setState({ viewBatch: !this.state.viewBatch });
                }}
              >
                View Batch
              </Button>
              {this.state.viewBatch && (
                <ViewBatch
                  addDialog={this.state.viewBatch}
                  handleDialogClose={this.onCloseViewBatchModal}
                />
              )}
              <Button
                disableRipple={true}
                data-test-id="admin-period-add-btn"
                variant="outlined"
                className={`AddPeriodBtn ${this.props.classes.button_color}`}
                onClick={() => this.onAddPeriodClick()}
              >
                Add Period
              </Button>
            </div>
          </div>

          <div className="BatchPeriodSpacingDiv">
            <div className="BatchPeriodBox">
              <NativeSelect
                id="select"
                data-test-id="admin-select-batch"
                value={this.state.selected_batch_id}
                //input={<BootstrapInput5 />}
                classes={{
                  root : this.props.classes.AdminBatchAndPeriodClassesAndSectionBootstrapRoot,
                  select : this.props.classes.AdminBatchAndPeriodClassesAndSectionBootstrapInput
                }}
                disableUnderline
                className="SelectBatchDropDown"
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  this.handleChangeBatch(event)
                }
              >
                <option value="">All Batches</option>
                {batchData?.map((item: any) => (
                  <option value={item.id} key={item.id}>{item.attributes.name}</option>
                ))}
              </NativeSelect>

              <TableContainer>
                <div className="PeriodsListContainer">
                  <Table className="borderClass">
                    <TableHead className="PeriodsListTableHead">
                      <TableRow>
                        <TableCell className={`period_list_header ${this.props.classes.subHeading_font}`}>
                          Period Name
                        </TableCell>
                        <TableCell className={`period_list_header ${this.props.classes.subHeading_font}`}>
                          Start Time
                        </TableCell>
                        <TableCell className={`period_list_header ${this.props.classes.subHeading_font}`}>
                          End Time
                        </TableCell>
                        <TableCell className={`period_list_header ${this.props.classes.subHeading_font}`}>
                          Type
                        </TableCell>
                        <TableCell className={`period_list_header ${this.props.classes.subHeading_font}`}>
                          Batches
                        </TableCell>
                        <TableCell className={`period_list_header ${this.props.classes.subHeading_font}`}>

                          <TableSortLabel
                            active={this.state.sortByDate === 'desc'}
                            direction={this.state.sortByDate}
                            onClick={this.handleSortByCreationDate}
                          >
                            Creation Date
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className={`period_list_header_center ${this.props.classes.subHeading_font}`}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {periodData?.length === 0 || periodData === undefined ? (
                        <>
                          <Typography
                            className={`NoPeriodsRecordFound ${this.props.classes.bodyText_font}`}
                          >
                            No record(s) found.
                          </Typography>
                        </>
                      ) : (
                        <>
                          {periodData?.map((data: any, index: number) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td colSpan={7} className="BlankPeriodCell"></td>
                              </tr>
                              <TableRow>
                                <TableCell className={`period_list_item ${this.props.classes.bodyText_font}`}>
                                  {data.attributes.name}
                                </TableCell>
                                <TableCell className={`period_list_item ${this.props.classes.bodyText_font}`}>
                                  {data.attributes?.start_time}
                                </TableCell>
                                <TableCell className={`period_list_item ${this.props.classes.bodyText_font}`}>
                                  {data.attributes?.end_time}
                                </TableCell>
                                <TableCell className={`period_list_item ${this.props.classes.bodyText_font}`}>
                                  {data.attributes?.period_type}
                                </TableCell>
                                <TableCell className={`period_list_item ${this.props.classes.bodyText_font}`}>
                                  {data.attributes.batch?.data?.attributes?.name
                                    ? data.attributes.batch?.data?.attributes
                                      ?.name
                                    : "-"}
                                </TableCell>
                                <TableCell className={`period_list_item ${this.props.classes.bodyText_font}`}>
                                  {moment(data.attributes.created_at).format(
                                    "DD MMM-yyyy"
                                  )}
                                </TableCell>
                                <TableCell className={`period_list_item_center ${this.props.classes.bodyText_font}`}>
                                  <div className="PeriodsActionDiv">
                                    <IconButton
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      className="padding-7 EditPeriodIconBtn"
                                    >
                                      <img
                                        src={editDivision}
                                        data-test-id={`period-edit-btn-${index}`}
                                        className="EditBatchPeriodImg"
                                        onClick={(event: any) => {
                                          this.onEditPeriodClick(data);
                                        }}
                                      />
                                    </IconButton>

                                    <IconButton
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      className="padding-7 DeletePeriodIconBtn"
                                    >
                                      <img
                                        id={`period-id-${data.id}`}
                                        data-test-id={`delete-period-btn-${index}`}
                                        src={deleteDivision}
                                        className="DeleteBatchPeriodImg"
                                        onMouseEnter={() => {
                                          const layerElement = document.getElementById(
                                            `period-id-${data.id}`
                                          );
                                          if (layerElement) {
                                            layerElement.style.opacity = "1";
                                          }
                                        }}
                                        onMouseLeave={() => {
                                          const layerElement = document.getElementById(
                                            `period-id-${data.id}`
                                          );
                                          if (layerElement) {
                                            layerElement.style.opacity = "0.3";
                                          }
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            openDeletePeriodModal: true,
                                            selectedPeriodForEdit: data,
                                          })
                                        }
                                      />
                                    </IconButton>
                                  </div>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </TableContainer>

              {this.state.timeLineMeta?.pagination?.total_pages > 1 && (
                <div className="BatchPeriodPaginateDiv">
                  <ReactPaginate
                    previousLabel={"←   Previous"}
                    nextLabel={"Next   →	"}
                    breakLabel="..."
                    initialPage={this.state.pagination_page}
                    pageCount={this.state.timeLineMeta?.pagination?.total_pages}
                    onPageChange={(e: any) => {
                      if (e.selected !== this.state.pagination_page) {
                        this.setState({ pagination_page: e.selected });
                        this.get_period_list(e.selected + 1);
                      }
                    }}
                    containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                    previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                    nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                    disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                    activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                    pageRangeDisplayed={
                      this.state.timeLineMeta?.pagination?.total_pages
                    }
                    marginPagesDisplayed={0}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <AddEditPeriod
          addEditPeriodDialog={openAddEditPeriod}
          handleAddEditPeriodDialogClose={this.handleAddEditPeriodDialogClose}
          isEditPeriod={isEditPeriod}
          periodName={periodName}
          periodNameError={periodNameError}
          periodNameErrorMsg={periodNameErrorMsg}
          onChangePeriodName={this.handleOnChangePeriodName}
          onBlurPeriodName={this.handleOnBlurPeriodName}
          selectedStartTime={selectedStartTime}
          selectedStartTimePeriodLabel={selectedStartTimePeriodLabel}
          selectedEndTime={selectedEndTime}
          selectedEndTimePeriodLabel={selectedEndTimePeriodLabel}
          handleStartTimeChange={this.handleStartTimeChange}
          handleEndTimeChange={this.handleEndTimeChange}
          addEditPeriodClick={this.add_edit_period}
          batchData={batchData}
          selectedBatch={this.state.selectedBatch}
          selectedBatchError={this.state.selectedBatchError}
          onChangeBatch={this.handleOnChangeBatch}
          onBlurBatch={this.handleOnBlurBatch}
          selectedPeriodEndTimeError={selectedPeriodEndTimeError}
          selectedPeriodEndTimeErrorMsg={selectedPeriodEndTimeErrorMsg}
          editPeriodEnable={editPeriodEnable}
          handleChangeEditPeriod={this.handleChangeEditPeriod}
          selectedPeriodType={this.state.selectedPeriodType}
          onChangePeriodType={this.handleOnChangePeriodType}
        />

        <DeleteModal
          title="Delete Period"
          deleteModal={this.state.openDeletePeriodModal}
          onCloseModal={this.handleOnCLoseDeleteModal}
          deleteClick={this.delete_period}
        />
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(
  AdminBatchAndPeriod as React.ComponentType<any>,
  "AdminAccount"
));
// Customizable Area End