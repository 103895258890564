import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import moment from "moment";
import { toast } from "react-toastify";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area Start
import { LiveTvSharp } from "@material-ui/icons";
// Customizable Area End

 // Customizable Area Start
export const configJSON = require("./../config.js");
 // Customizable Area Start

export interface Props {
    navigation: any
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    userDocument: any;
    loadingSpinner: boolean;
    isEdit: boolean;
    previewModal: boolean;
    previewData: any;
    userData: any;
    changeStatus: any;
    anchorEl: any;
    rejectReason: any;
    selectedId: any;
    statusDialog: boolean;
    notifyDialog: boolean;
    rejectDialog: boolean;
    rejectDialogErrorMes: any;
    rejectDialogError: boolean;
    selectedFileName: any;

    // Customizable Area End
}
interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class StudentDocumentController extends BlockComponent<Props, S, SS> {

// Customizable Area Start
    apigetDocument: any = '';
    apiPutDocument: any = '';
// Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            userDocument: [],
            loadingSpinner: false,
            isEdit: false,
            previewData: {},
            previewModal: false,
            userData: [],
            changeStatus: [],
            anchorEl: null,
            rejectReason: '',
            selectedId: '',
            statusDialog: false,
            notifyDialog: false,
            rejectDialog: false,
            rejectDialogErrorMes: "",
            rejectDialogError: false,
            selectedFileName: ''

        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        let id = HISTORY.location.state?.id;
        if (HISTORY.location.state === null || id === undefined) {
            this.props.navigation.navigate("UserDocument")
        } else {
            this.get_document()
        }
        // Customizable Area End
    }
    // Customizable Area Start
    handleFileLoad = () => {
    }
    backButton = () => {
        HISTORY.goBack();
    }
    handleClose = () => {
        this.setState({ previewData: {}, previewModal: false })
    }
    onApproveClick = (id: any) => {
        let index: any = this.state.userDocument.findIndex((_data: any) => _data.id == id)
        let arr = this.state.userDocument
        let isThere: any = this.state.changeStatus.some((_data: any) => _data.id == id)
        if (!isThere) {
            this.setState({ changeStatus: [...this.state.changeStatus, { id: id, status: "approved" }] })
        } else {
            let arr: [] = this.state.changeStatus.filter((_data: any) => _data.id !== id)
            this.setState({ changeStatus: arr }, () => {
                this.setState({ changeStatus: [...this.state.changeStatus, { id: id, status: "approved" }] })
            })
        }
        arr[index].attributes.document_status = "approved"
        this.setState({ userDocument: arr })
    }
    onRejectClick = (id: any) => {
        let index: any = this.state.userDocument.findIndex((_data: any) => _data.id == id)
        let arr = this.state.userDocument
        let isThere: any = this.state.changeStatus.some((_data: any) => _data.id == id)
        arr[index].attributes.document_status = "rejected"
        this.setState({ userDocument: arr, selectedId: '', rejectDialog: false })

        if (!isThere) {
            this.setState({ changeStatus: [...this.state.changeStatus, { id: id, status: "rejected", reason: this.state.rejectReason }] }, () => {
                this.setState({ selectedId: '', rejectDialog: false, rejectReason: '', selectedFileName: '' })
            })
        } else {
            let arr: [] = this.state.changeStatus.filter((_data: any) => _data.id !== id)
            this.setState({ changeStatus: arr }, () => {
                this.setState({ changeStatus: [...this.state.changeStatus, { id: id, status: "rejected", reason: this.state.rejectReason }] }, () => {
                    this.setState({ selectedId: '', rejectDialog: false, rejectReason: '', selectedFileName: '' })
                })
            })
        }
    }

    onChangeClick = (id: any) => {
        let index: any = this.state.userDocument.findIndex((_data: any) => _data.id == id)
        let arr = this.state.userDocument
        let isThere: any = this.state.changeStatus.some((_data: any) => _data.id == id)
        if (!isThere) {
            this.setState({ changeStatus: [...this.state.changeStatus, { id: id, status: "under_review" }] })
        } else {
            let arr: [] = this.state.changeStatus.filter((_data: any) => _data.id !== id)
            this.setState({ changeStatus: arr }, () => {
                this.setState({ changeStatus: [...this.state.changeStatus, { id: id, status: "under_review" }] })
            })
        }
        arr[index].attributes.document_status = "under_review"

        this.setState({ userDocument: arr, statusDialog: false })
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));


            if (apiRequestCallId === this.apigetDocument) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ userDocument: responseJson.documents?.data })
                        this.setState({ userData: responseJson.user_account?.data })
                    } else {

                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
            else if (apiRequestCallId === this.apiPutDocument) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (responseJson.document_success_response) {
                            responseJson.document_success_response.map((_data: any) =>
                                toast.success(_data.message)
                            )
                        }
                        if (responseJson.document_error_response) {
                            responseJson.document_error_response.map((_data: any) =>
                                toast.error(_data.message)
                            )
                        }
                        this.get_document()
                        this.setState({ isEdit: false, changeStatus: [] })
                    } else {
                        toast.error("Somethings went wrong.")
                        this.parseApiErrorResponse(responseJson);
                        this.get_document()
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
        }
    }

    get_document = () => {
        let id = HISTORY.location.state?.id;
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "school": data.school_id,
            "token": localStorage.getItem("token")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetDocument = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_profile/documents/document_view" + `?account_id=${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.baseURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({ loadingSpinner: true })
    }

    onSave = () => {

        const school_data = localStorage.getItem("user_data");
        const userData = JSON.parse(school_data || "{}");
        const data = {
            "data": this.state.changeStatus
        }


        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            school: userData.school_id,
            token: localStorage.getItem("token")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiPutDocument = requestMessage.messageId;

        const httpBody = data;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_profile/documents/save_and_notify"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateProfileApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.baseUrl
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({ notifyDialog: false, loadingSpinner: true })

    }

    // Customizable Area End


}
