// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createRef } from "react";
import { toast } from "react-toastify";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  location?: any;
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  questionId: any;
  data: any;
  showLoader: boolean;
  marks: any;
  negativeMarks: any;
  selectedSubject: any;
  selectedChapter: any;
  selectedUnit: any;
  selectedSkill: any;
  selectedProficiency: any;
  selectedDifficultyLevel: any;
  keywordsList: Array<any>;
  keyword: string;
  remainSectionMarkPr: number;
  alertModal: boolean;
  alertMessage: string;
  question: string;
  chapterListData: any;
  unitListData: any;
  selectedImageBlobUrl: any;
  hintAnswer: any;
  hintsNumberr: number;
  explaination: string;
  showLoaderForImageUpload: boolean;
  solutionImageSignedId: any;
  selectedCategoryQuestionData: any;
  assessmentSectionId: number;
  marksError: boolean;
  questionError: boolean;
  metaDataErrors: any;
  sectiondata: any;
  editButton: boolean;
  attachmentExplanationUrl: any;
  hintsExplanationUrl: string;
  explanationId: string;
  hintId: any;
  hintsAttachmentUploadId: string;
  negmarksError: boolean;
  marksErrorText: string;
  assessmentHasNegativeMarking: boolean;
  explainationAttachmentUploadId: string;
  selectedBloomTaxonomy: any;
  prevQuestionModal: boolean;
  stepsItems: any;
  stepperActiveStep: number;
  showEmojiPicker: boolean;
  selectedStepperRadioValue: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
const uuidv4 = require("uuid/v4");

export default class EmojiRatingTypeQuestionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuestionListId: string = "";
  deleteQuestionId: string = "";
  getChapterListId: string = "";
  getTopicListId: string = "";
  apiSaveQuestionId: string = "";
  apiDeleteSolutionAttachment: string = "";
  questionEditorRef: React.Ref<HTMLInputElement>;
  webcamRef: React.MutableRefObject<any>;
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);

    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      questionId: null,
      data: "",
      showLoader: false,
      marks: 0,
      negativeMarks: 0,
      selectedSubject: "",
      selectedChapter: "",
      selectedUnit: "",
      selectedSkill: "",
      selectedProficiency: "",
      selectedDifficultyLevel: "",
      keywordsList: [],
      keyword: "",
      remainSectionMarkPr: 0,
      alertModal: false,
      alertMessage: "",
      question: "",
      chapterListData: [],
      unitListData: [],
      selectedImageBlobUrl: "",
      negmarksError: false,
      marksErrorText: "",
      showLoaderForImageUpload: false,
      explaination: "",
      hintsNumberr: 0,
      solutionImageSignedId: null,
      hintAnswer: [],
      explanationId: "",
      hintId: null,
      hintsAttachmentUploadId: "",
      editButton: false,
      selectedCategoryQuestionData: null,
      assessmentSectionId: 0,
      marksError: false,
      questionError: false,
      showEmojiPicker: false,
      metaDataErrors: {
        chapter: false,
        unit: false,
        skill: false,
        proficiency: false,
        keywords: false,
        difficultyLevel: false,
        bloomTaxonomy: false,
      },
      sectiondata: {},
      attachmentExplanationUrl: "",
      hintsExplanationUrl: "",
      assessmentHasNegativeMarking: JSON.parse(
        localStorage.getItem("assessmentData") || "{}"
      )?.negative_marking,
      explainationAttachmentUploadId: "",
      selectedBloomTaxonomy: "",
      prevQuestionModal: false,
      stepsItems: [],
      stepperActiveStep: 0,
      selectedStepperRadioValue: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.userdata = this.userdata.bind(this);
    this.questionEditorRef = createRef();
    this.webcamRef = createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.get_chapter_list();
    if (HISTORY.location.state) {
      const { questionData, sectiondata, sectionId } = HISTORY.location.state;
      if (HISTORY.location.state?.isEdit) {
        const index = questionData?.attributes?.solutions?.data?.length - 1;
        let ImageIndex =
          questionData?.attributes?.attachments?.data?.length - 1;
        this.setState({
          editButton: HISTORY.location.state?.isEdit,
          marks: questionData?.attributes?.marks,
          negativeMarks: questionData?.attributes?.negative_marks,
          question: questionData?.attributes?.title,
          explaination:
            questionData?.attributes?.solutions?.data[index]?.attributes
              ?.explaination,
          explanationId: questionData?.attributes?.solutions?.data[index]?.id,
          hintsAttachmentUploadId:
            questionData?.attributes?.hints?.data?.[0]?.attributes?.attachments
              ?.data?.[0]?.id,
          selectedCategoryQuestionData: questionData?.attributes?.type,
          selectedChapter:
            questionData?.attributes?.metadata?.data?.attributes?.chapter_id,
          selectedUnit:
            questionData?.attributes?.metadata?.data?.attributes?.unit_id,
          selectedBloomTaxonomy:
            questionData?.attributes?.metadata?.data?.attributes
              ?.bloom_taxonomy,
          selectedProficiency:
            questionData?.attributes?.metadata?.data?.attributes?.proficiency,
          selectedSkill:
            questionData?.attributes?.metadata?.data?.attributes?.skill,
          selectedDifficultyLevel:
            questionData?.attributes?.metadata?.data?.attributes
              ?.difficulty_level,
          keywordsList:
            questionData?.attributes?.metadata?.data?.attributes?.keywords,
          questionId: questionData?.id,
          remainSectionMarkPr:
            ((sectiondata?.section_marks - sectiondata?.remaining_marks) *
              100) /
            sectiondata?.section_marks,
          sectiondata: sectiondata,
          selectedImageBlobUrl:
            questionData?.attributes?.attachments?.data?.[ImageIndex]
              ?.attributes?.url,
          attachmentExplanationUrl:
            questionData?.attributes?.solutions?.data?.[0]?.attributes
              ?.attachments?.data?.[0]?.attributes?.url,
          hintsExplanationUrl:
            questionData?.attributes?.hints?.data?.[0]?.attributes?.attachments
              ?.data?.[0]?.attributes?.url,
          explainationAttachmentUploadId:
            questionData?.attributes?.solutions?.data?.[0]?.attributes
              ?.attachments?.data?.[0]?.id,
          assessmentSectionId: sectionId,
        });
        if (questionData?.attributes?.hints.data.length != 0) {
          questionData?.attributes?.hints.data.map((data: any, index: number) => {
            this.state.hintAnswer.push({
              id: data.id,
              hintId: index + 1,
              hint: data.attributes.hint,
              src:
                data.attributes.attachments.data.length != 0
                  ? data.attributes.attachments.data[0].attributes.url
                  : "",
            });
          });
        }
        else {
          this.setState({
            hintAnswer: [
              {
                hintId: 0,
                hint: "",
              },
            ]
          })
        }
        this.setState({
          hintsNumberr: questionData?.attributes?.hints?.data.length,
        });
        const stepsArray: any = [];
        questionData?.attributes?.answers?.data?.map((data: any, index: number) => {
          if (data?.attributes?.right_answer) {
            this.setState({ stepperActiveStep: index })
          }
          stepsArray.push({
            id: data?.id,
            uniqueId: uuidv4(),
            textValue: data?.attributes?.answer,
            emoji: data?.attributes?.emoji,
            right_answer: data?.attributes?.right_answer,
          })
          stepsArray?.map((item: any) => {
            if (item?.right_answer) {
              this.setState({ selectedStepperRadioValue: item.uniqueId })
            }
          })
          this.setState({ stepsItems: stepsArray })
        }
        );
      } else {
        this.setState({
          hintAnswer: [
            {
              hintId: 0,
              hint: "",
            },
          ],
          sectiondata: sectiondata,
          selectedCategoryQuestionData: questionData,
          assessmentSectionId: sectionId,
          remainSectionMarkPr:
            ((sectiondata?.section_marks - sectiondata?.remaining_marks) *
              100) /
            sectiondata?.section_marks,
        });
      }
    }
  }

  getExplanation = () => {
    if (HISTORY?.location?.state) {
      const { questionData } = HISTORY.location.state;
      const index = questionData?.attributes?.solutions?.data.length - 1;
      if (
        questionData?.attributes?.solutions?.data[index]?.attributes
          ?.explaination
      )
        return questionData?.attributes?.solutions?.data[index]?.attributes
          ?.explaination;
      return "";
    } else {
      HISTORY.push("/TeacherAssessmentCreateSection");
    }
  };

  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
  };

  plusMarksClick = () => {
    if (this.state.marks + 1 > 0) {
      this.setState({ marksError: false });
    } else {
      this.setState({
        marksError: true,
        marksErrorText: "Please enter marks.",
      });
    }
    if (this.state.marks < this.state.sectiondata?.remaining_marks) {
      this.setState({ marks: this.state.marks + 1, marksError: false });
    } else if (
      this.state.marks ===
      this.state.sectiondata?.remaining_marks - 1
    ) {
      this.setState({ marks: this.state.marks + 1, marksError: false });
    }
    if (
      this.state.marks + 1 > this.state.negativeMarks ||
      this.state.marks + 1 == this.state.negativeMarks
    ) {
      this.setState({ marks: this.state.marks + 1, negmarksError: false });
    }
    if (this.state.editButton) {
      if (
        this.state.marks + 1 >
        this.state.sectiondata?.remaining_marks +
        HISTORY.location.state?.questionData?.attributes?.marks
      ) {
        this.setState({
          marks: this.state.marks + 1,
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
      }
    } else {
      if (this.state.marks + 1 > this.state.sectiondata?.remaining_marks) {
        this.setState({
          marks: this.state.marks + 1,
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
      }
    }

    this.setState({
      marks: this.state.marks + 1,
    });
  };

  minusMarksClick = () => {
    if (this.state.negativeMarks + 1 > this.state.marks) {
      this.setState({ negmarksError: true });
    }
    this.setState({ negativeMarks: this.state.negativeMarks + 1 });
  };

  onChangeKeywords = (e: any) => {
    this.setState({ keyword: e.target.value });
  };

  onBlurKeywords = (e: any) => {
    this.setState({ keyword: e.target.value });
    if (e.target.value !== "") {
      let { keywordsList } = this.state;
      keywordsList = [...keywordsList, e.target.value];
      this.setState({ keywordsList: keywordsList, keyword: "" });
      if (keywordsList.length > 0) {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: false },
        });
      } else {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: true },
        });
      }
    }
  };

  onKeyDownKeywords = (e: any) => {
    this.setState({ keyword: e.target.value });
    if (e?.key === "Enter" && e.target.value !== "") {
      let { keywordsList } = this.state;
      keywordsList = [...keywordsList, e.target.value];
      this.setState({ keywordsList: keywordsList, keyword: "" });
      if (keywordsList.length > 0) {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: false },
        });
      } else {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: true },
        });
      }
    }
  };

  handleDeleteKeyword = (item: any) => {
    let { keywordsList } = this.state;
    keywordsList = [...keywordsList.filter((x: any) => x !== item)];
    this.setState({ keywordsList: keywordsList, keyword: "" });
    if (keywordsList.length > 0) {
      this.setState({
        metaDataErrors: { ...this.state.metaDataErrors, keywords: false },
      });
    } else {
      this.setState({
        metaDataErrors: { ...this.state.metaDataErrors, keywords: true },
      });
    }
  };

  onChangeTextValue = (stepUniqueId: any, stepText: any) => {
    const { stepsItems } = this.state;
    const updatedStepsItems = stepsItems?.map((step: any) => {
      if (step.uniqueId === stepUniqueId) {
        step.textValue = stepText
      }
      return step;
    })
    this.setState({ stepsItems: updatedStepsItems })
  }

  onRemoveStep = (step: any) => {
    const { stepsItems } = this.state;
    if (stepsItems?.filter((item: any) => !item?._destroy)?.length <= 2) {
      toast.error("Need to keep minimum 2 responses.")
    } else {
      if (step?.id) {
        const updatedStepsItems = stepsItems.map((stepItem: any) => {
          if (stepItem?.uniqueId === step?.uniqueId) {
            stepItem._destroy = true;
            stepItem.right_answer = false;
          }
          return stepItem;
        })
        this.setState({ stepsItems: updatedStepsItems })
      } else {
        const updatedStepsItems = stepsItems?.filter((stepItem: any) => stepItem?.uniqueId !== step?.uniqueId)
        this.setState({ stepsItems: updatedStepsItems })
      }
    }
  }

  onAddEmoji = () => {
    this.setState({ showEmojiPicker: !this.state.showEmojiPicker });
  };

  onAddStep = (event: any, emoji: any) => {
    const { stepsItems } = this.state;
    const newItem = {
      uniqueId: uuidv4(),
      textValue: "",
      emoji: emoji.emoji,
    };
    this.setState({
      stepsItems: [...stepsItems, newItem],
      showEmojiPicker: false,
    });
  };

  onChangeStepperValue = (e: any) => {
    const { stepsItems } = this.state;
    const activeStepIndex = stepsItems?.filter((item: any) => !item?._destroy)?.findIndex((step: any) => step.uniqueId === e.target.value)
    const destroyedStepsItems = stepsItems?.filter((item: any) => item?._destroy);
    const updatedStepsItems = stepsItems?.filter((item: any) => !item?._destroy)?.map((step: any, index: number) => {
      if (index === activeStepIndex) {
        step.right_answer = true
      } else {
        step.right_answer = false
      }
      return step
    })
    this.setState({
      stepperActiveStep: activeStepIndex,
      selectedStepperRadioValue: e.target.value,
      stepsItems: [...updatedStepsItems, ...destroyedStepsItems]
    })
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getChapterListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ chapterListData: responseJson?.data }, () =>
              this.get_unit_list(this.state.selectedChapter)
            );
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getTopicListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            // 
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiSaveQuestionId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            if (this.state.editButton) {
              toast.success("Question updated successfully.");
            } else {
              toast.success("Question created successfully.");
            }
            // Update question to section list
            this.updateSectionQuestion(responseJson.data);
            // Update question to section list END
            this.setState({ showLoader: false, editButton: false });
            HISTORY.push("/TeacherAssessmentCreateSection");
          } else {
            this.parseApiErrorResponse(responseJson);
            this.setState({ showLoader: false });
          }
        }
        this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  updateSectionQuestion = (questionData: any) => {
    const { sectionId } = HISTORY.location.state;
    const assessmentData = JSON.parse(
      localStorage.getItem("assessmentData") || "{}"
    );

    const question = {
      id: questionData.id,
      title: questionData?.attributes?.title,
      type: questionData?.attributes?.type?.name,
      marks: questionData?.attributes?.marks,
      negative_marks: questionData?.attributes?.negative_marks,
    };
    assessmentData.assessment_sections_attributes.map(
      (sectionData: any, index: number) => {
        if (sectionData.id === sectionId) {
          // setting question data
          if (sectionData.question_data?.length > 0) {
            const qIndex = sectionData.question_data.findIndex(
              (data: any) => Number(data.id) === Number(question.id)
            );
            if (qIndex != -1) {
              sectionData.question_data[qIndex] = question;
            } else {
              sectionData.question_data.push(question);
            }
          } else {
            sectionData.question_data = [question];
          }
          // setting question data END

          // setting section marks
          const totalQuestionMarks = sectionData.question_data.reduce(
            (sum: any, current: any) => sum + current.marks,
            0
          );
          sectionData.remaining_marks =
            sectionData.section_marks - totalQuestionMarks;
          // setting section marks END
          return;
        }
      }
    );
    localStorage.setItem("assessmentData", JSON.stringify(assessmentData));
  };

  get_chapter_list = () => {
    const assessment_data = localStorage.getItem("assessmentData");
    const assessmentData = JSON.parse(assessment_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getChapterListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chapterEndPoint + `?subject_id=${assessmentData?.subject_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_topic_list = (id?: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTopicListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topicEndPoint + `?chapter_id=${Number(id)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_unit_list = (id?: any, resetUnit?: boolean) => {
    const { chapterListData } = this.state;
    const chapterUnits = chapterListData?.find(
      (chapter: any) => Number(chapter.id) === Number(id)
    );
    this.setState({ unitListData: chapterUnits?.attributes?.units?.data });

    if (resetUnit) {
      this.setState({ selectedUnit: "" });
    }
  };

  getEmptyState = () => ({
    marksError: false,
    questionError: false,
    metaDataErrors: {
      chapter: false,
      unit: false,
      skill: false,
      proficiency: false,
      keywords: false,
      difficultyLevel: false,
      bloomTaxonomy: false,
    },
  });

  validFormData = () => {
    let isValid = true;

    const {
      question,
      marks,
      selectedChapter,
      selectedUnit,
      selectedSkill,
      selectedProficiency,
      selectedDifficultyLevel,
      keywordsList,
      selectedBloomTaxonomy,
      stepsItems,
    } = this.state;

    const errors = this.getEmptyState().metaDataErrors;
    let questionErr = this.getEmptyState().questionError;
    let marksErr = this.getEmptyState().marksError;

    if (marks === null || Number(marks) === 0) {
      this.setState({
        marksError: true,
        marksErrorText: "Please enter marks.",
      });
      isValid = false;
    }
    if (this.state.editButton) {
      if (
        this.state.marks >
        this.state.sectiondata?.remaining_marks +
        HISTORY.location.state?.questionData?.attributes?.marks
      ) {
        this.setState({
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
        isValid = false;
      }
    } else {
      if (this.state.marks > this.state.sectiondata?.remaining_marks) {
        this.setState({
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
        isValid = false;
      }
    }

    if (this.state.negativeMarks > this.state.marks) {
      this.setState({ negmarksError: true });
      isValid = false;
    }
    if (question === null || question === "") {
      questionErr = true;
      isValid = false;
    }

    if (selectedChapter === null || selectedChapter === "") {
      errors.chapter = true;
      isValid = false;
    }

    if (selectedUnit === null || selectedUnit === "") {
      errors.unit = true;
      isValid = false;
    }

    if (selectedSkill === null || selectedSkill === "") {
      errors.skill = true;
      isValid = false;
    }

    if (selectedProficiency === null || selectedProficiency === "") {
      errors.proficiency = true;
      isValid = false;
    }

    if (selectedDifficultyLevel === null || selectedDifficultyLevel === "") {
      errors.difficultyLevel = true;
      isValid = false;
    }

    if (keywordsList.length === 0) {
      errors.keywords = true;
      isValid = false;
    }

    if (selectedBloomTaxonomy === null || selectedBloomTaxonomy === "") {
      errors.bloomTaxonomy = true;
      isValid = false;
    }

    if (stepsItems?.filter((item: any) => !item?._destroy)?.length < 2) {
      toast.error("Need to keep minimum 2 responses.")
      isValid = false;
    }
    let checkSelected: any = [];
    let checkedEmptyField: any = false;
    stepsItems?.filter((item: any) => !item?._destroy)?.map((step: any) => {
      if (!step?.textValue && !checkedEmptyField) {
        toast.error("Please enter text for all scale.")
        checkedEmptyField = true;
        isValid = false;
      }
      if (step?.right_answer) {
        checkSelected.push(step);
      }
    })

    if (!checkSelected?.length) {
      toast.error("Please set correct answer.")
      isValid = false;
    }

    this.setState({ metaDataErrors: errors, questionError: questionErr });

    return isValid;
  };

  saveQuestion = () => {
    if (this.validFormData()) {
      this.saveQuestionApi();
    }
  };

  saveQuestionApi = () => {
    let final_hint_answer: any = [];
    let final_option_list: any = [];
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id,
    };

    const {
      editButton,
      marks,
      negativeMarks,
      explanationId,
      hintId,
      question,
      hintAnswer,
      selectedChapter,
      explaination,
      solutionImageSignedId,
      selectedUnit,
      selectedSkill,
      selectedProficiency,
      selectedDifficultyLevel,
      keywordsList,
      selectedCategoryQuestionData,
      assessmentSectionId,
      selectedBloomTaxonomy,
      stepsItems,
      explainationAttachmentUploadId
    } = this.state;
    stepsItems?.map((step: any) => {
      if (editButton) {
        final_option_list.push({
          id: step?.id,
          answer: step.textValue,
          emoji: step.emoji,
          right_answer: step?.right_answer ? step?.right_answer : false,
          _destroy: step?._destroy,
        });
      } else {
        final_option_list.push({
          answer: step.textValue,
          emoji: step.emoji,
          right_answer: step?.right_answer ? step?.right_answer : false,
        });
      }
    })
    hintAnswer?.map((data: any) => {
      final_hint_answer.push({
        id: data.id,
        files: data.files ? data.files : [],
        hint: data.hint,
      });
    });
    const assessmentData = JSON.parse(
      localStorage.getItem("assessmentData") || "{}"
    );
    const httpBody: any = {
      subject_id: assessmentData.subject_id,
      grade_id: assessmentData.grade_id,
      marks: marks,
      title: question,
      question_sub_category_id: selectedCategoryQuestionData?.id,
      negative_marks: negativeMarks,
      is_negative_marking: negativeMarks > 0 ? true : false,
      assessment_section_id: assessmentSectionId,
      solutions_attributes: [
        {
          id: explanationId,
          explaination: explaination,
        },
      ],
      hints_attributes: final_hint_answer,
      answers_attributes: final_option_list,
      metadata_attributes: {
        chapter_id: selectedChapter,
        unit_id: selectedUnit,
        skill: selectedSkill,
        proficiency: selectedProficiency,
        difficulty_level: selectedDifficultyLevel,
        bloom_taxonomy: selectedBloomTaxonomy,
        keywords: keywordsList,
      },
    };
    if (
      this.state.editButton &&
      HISTORY.location.state?.questionData?.attributes?.solutions?.data
        ?.length != 0
    ) {
      // remove attachment when no new image selected
      if (
        this.state.explainationAttachmentUploadId &&
        !solutionImageSignedId &&
        !this.state.attachmentExplanationUrl
      ) {
        this.removeExplainationAttachment(
          this.state.explainationAttachmentUploadId
        );
      } else {
        // insert new image if initialy no image uploaded
        if (
          (this.state.explainationAttachmentUploadId === undefined ||
            this.state.explainationAttachmentUploadId === "") &&
          solutionImageSignedId !== null
        ) {
          httpBody.solutions_attributes[0].files =
            solutionImageSignedId === "" || solutionImageSignedId === null
              ? []
              : [solutionImageSignedId];
        }
        // Update Image
        else if (solutionImageSignedId && explainationAttachmentUploadId) {
          httpBody.solutions_attributes[0].files = [solutionImageSignedId];
          httpBody.solutions_attributes[0].old_upload_id =
            HISTORY.location.state?.questionData?.attributes?.solutions?.data?.[0]?.attributes?.attachments?.data[0]?.id;
        }
      }
    } else {
      httpBody.solutions_attributes[0].files =
        solutionImageSignedId === "" || solutionImageSignedId === null
          ? []
          : [solutionImageSignedId];
    }

    // Hint Attributes

    if (
      this.state.editButton &&
      HISTORY.location.state?.questionData?.attributes?.hints?.data?.length != 0
    ) {
      // remove attachment when no new image selected
      if (
        this.state.hintsAttachmentUploadId &&
        hintId === null &&
        this.state.hintsExplanationUrl === ""
      ) {
        this.removeExplainationAttachment(this.state.hintsAttachmentUploadId);
      } else {
        // insert new image if initialy no image uploaded
        if (
          (this.state.hintsAttachmentUploadId === undefined ||
            this.state.hintsAttachmentUploadId === "") &&
          hintId !== null
        ) {
          httpBody.hints_attributes[0].files =
            hintId === "" || hintId === null ? [] : [hintId];
        }
        // Update Image
        else if (hintId && this.state.hintsAttachmentUploadId) {
          httpBody.hints_attributes[0].files = [hintId];
          httpBody.hints_attributes[0].old_upload_id = this.state.hintsAttachmentUploadId;
        }
      }
    } else {
      httpBody.hints_attributes[0].files =
        hintId === "" || hintId === null ? [] : [hintId];
    }
    if ((final_hint_answer[0].files && final_hint_answer[0].files == 0) && final_hint_answer[0].hint == '') {
      delete httpBody.hints_attributes
    }
    this.setState({ showLoader: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSaveQuestionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.editButton
        ? configJSON.saveQuestionEndPoint + `/${this.state.questionId}`
        : configJSON.saveQuestionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.editButton ? configJSON.PUT : configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeExplainationAttachment = (id: any) => {
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteSolutionAttachment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.galleryImagesEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
