// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../../config");
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showLoader: boolean;
  queryTerm: any;
  startDate: any;
  endDate: any;
  subjectList: any;
  selectedSubjects: string[];
  gradebookData: any;
  gradebookDataMeta: any;
  page: number;
  rowsPerPage: number;
  selectChanged: boolean;
  isAllSubjectSelected: boolean;
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}
export default class AssessmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetGradebookData: string = "";
  apiGetSubjectList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      showLoader: false,
      queryTerm: "",
      startDate: moment(new Date()).subtract(7, 'days'),
      endDate: moment(new Date()),
      subjectList: [],
      selectedSubjects: [],
      gradebookData: [],
      gradebookDataMeta: [],
      page: 0,
      rowsPerPage: 10,
      selectChanged: false,
      isAllSubjectSelected:false,

    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getGradebookList();
    this.getSubjectsList();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
  }

  handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let selectedList = event.target.value as string[];
    let subjectList = this.state.subjectList.reduce((result: any, data: any) => {
      result.push(data.id);
      return result;
    }, []);
    if (selectedList[selectedList.length - 1] === '-1') {
      const updatedList = this.state.selectedSubjects.length === subjectList.length ? [] : subjectList;
      const isAllSelected = updatedList.length === subjectList.length;
      this.setState({
        selectedSubjects: updatedList,
        isAllSubjectSelected: isAllSelected,
        selectChanged: true
      })
      return;
    }
    const isAllSelected = selectedList.length === subjectList.length;
    this.setState({
      selectedSubjects: selectedList,
      selectChanged: true,
      isAllSubjectSelected: isAllSelected,
    })
  };

  getSelectedSubjectNames = (selected: any) => {
    if (selected.length === 0) {
      return "All Subjects"
    }
    return this.state.subjectList.reduce((result: string, subject: any) => {
      if (this.state.selectedSubjects.includes(subject.id)) {
        result = result + (result !== "" ? ", " : "") + subject.attributes.subject_name
      }
      return result
    }, "")
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      switch (apiRequestCallId) {
        case this.apiGetGradebookData:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({
                  gradebookData: responseJson.data,
                  gradebookDataMeta: responseJson.meta
                })
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.setState({
              showLoader: false
            })
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiGetSubjectList:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const isAllSelected = responseJson.data.length > 0 && this.state.selectedSubjects.length === responseJson.data.length;
                this.setState({
                  subjectList: responseJson.data,
                  isAllSubjectSelected: isAllSelected
                })
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  getSubjectsList = () => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const class_id = school_Data.school_account.data[0].attributes.class.id;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSubjectList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectsEndPoint + `?class_id=${class_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  validateDates = (start: any, end: any) => {
    let startDate = moment(moment(start).format('MM-DD-YYYY'), "MM-DD-YYYY");
    let endDate = moment(moment(end).format('MM-DD-YYYY'), "MM-DD-YYYY");
    if (endDate.diff(startDate, 'days') < 0) {
      toast.error('End Date should be greater or equal to Start Date')
      return false;
    }

    return true;
  }
  getGradebookList = () => {
    let filter = "";
    if (this.state.queryTerm !== "") {
      filter += `&name=${this.state.queryTerm}`;
    }
    let startDate = moment(this.state.startDate);
    let endDate = moment(this.state.endDate);
    if (endDate.diff(startDate, 'days') < 0) {
      toast.error('End Date should be greater or equal to Start Date')
      return
    } else {
      filter += `&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`;
    }
    if (this.state.selectedSubjects.length > 0 && !this.state.selectedSubjects.includes('-1')) {
      filter += `&subject_ids=[${this.state.selectedSubjects.join(',')}]`;
    }
    this.setState({
      showLoader: true
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
      school: school_Data?.school_id,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetGradebookData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assessmentGradebookEndPoint + `?page=${this.state.page + 1}&per=${this.state.rowsPerPage}${filter}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

}
