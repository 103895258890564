// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import { HISTORY } from "../../../../components/src/comman";
import AssessmentLearningController, { Props } from './AssessmentLearningController.web';
import Spinner from '../../../shared/SideBar/src/Spinner';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

class AssessmentLearning extends AssessmentLearningController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const useStyles = makeStyles((theme: Theme) =>
            createStyles({
                root: {
                    display: 'flex',
                    flexWrap: 'wrap',
                },
                margin: {
                    margin: theme.spacing(1),
                },
                withoutLabel: {
                    marginTop: theme.spacing(3),
                },
                table: {
                    minWidth: 700,
                },
            }),
        );

        const StyledTableCell = withStyles((theme: Theme) =>
            createStyles({
                head: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                },
                body: {
                    fontSize: 14,
                }
            }),
        )(TableCell);

        const StyledTableRow = withStyles((theme: Theme) =>
            createStyles({
                root: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: theme.palette.action.hover,
                    },
                },
            }),
        )(TableRow);

        function createData(title: string, term: number, term2: number, term3: number) {
            return { title, term, term2, term3 };
        }

        const rows = [
            createData('Total Attendence of the learner', 20, 10, 5),
            createData('Total Number of working days', 20, 10, 4.0)
        ];

        const handleBack = () => {
            HISTORY.goBack();
        }
        const { isLoader, data, scholasticListData } = this.state
        const handleClick = () => {
            HISTORY.push({
                pathname: "/AssessmentLearning2",
                state: {
                    selectedClassData: data,
                }
            });
        };
        // Customizable Area End
        return (
        // Customizable Area Start
            <>
                {isLoader && <Spinner spinnerModal={isLoader} />}
                <div>
                    <div className='profileTitle'>
                    <Button className={`backBtn ${this.props.classes.bodyText_font}`}  onClick={() => handleBack()}><KeyboardBackspaceOutlinedIcon /> Back</Button>
                        <h2 className={this.props.classes.heading_font}>
                            {scholasticListData?.attributes?.name}</h2>
                        <Button className={`editBtn ${this.props.classes.bodyText_font}`} onClick={() => handleClick()}>Next <KeyboardBackspaceOutlinedIcon /></Button>
                    </div>
                    <div className="tableContent">
                        {scholasticListData.length != 0 && scholasticListData.attributes.terms.map((termData: any) => (
                            <TableContainer className="tableSectionpart">
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={`${this.props.classes.assessmentLearning_classAndSection_tableHeadCell} ${this.props.classes.bodyText_font} search_input_class_section`} align="center" style={{ borderRadius: "0px" }}>
                                                Scholastic Areas
                                            </TableCell>
                                            <TableCell colSpan={7} className={`${this.props.classes.assessmentLearning_classAndSection_tableHeadCell} ${this.props.classes.bodyText_font} search_input_class_section`} align="center" style={{ borderRadius: "0px" }}>
                                                {termData.title}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={this.props.classes.assessmentLearning_classAndSection_commonCss} style={{ borderRadius: "0px" }}>
                                            </TableCell>
                                            {scholasticListData.attributes.report_categories.map((categoryData: any) => (
                                                <>
                                                    {
                                                        categoryData.categories.map((data: any) => (
                                                            <>{data.sub_category.data.length != 0
                                                                ?
                                                                <td align="center" className={this.props.classes.assessmentLearning_classAndSection_commonCss} style={{ borderRadius: '0px', paddingTop: "10px" }}>
                                                                    <Typography>{data.name}</Typography>
                                                                    <Table className='tableSection'>
                                                                        <tr>
                                                                            {data.sub_category.data.length > 0 && data.sub_category.data.map((subData: any, index: number) => {
                                                                                return (
                                                                                    <>
                                                                                        {subData.term_id === termData.id &&
                                                                                            <td>
                                                                                                {subData.assessment_name != undefined ? subData.assessment_name : subData.name}<br />{subData.weightage != undefined ? (subData.weightage) : (subData.marks)}
                                                                                            </td>
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </tr>
                                                                    </Table>
                                                                </td>
                                                                :
                                                                <td align="center" style={{ borderRadius: "0px", borderRight: "1px solid #D9D9D9", borderBottom: "1px solid #D9D9D9", lineHeight: "22px" }}>
                                                                    {data.name}
                                                                    <br />{(data.weightage)}
                                                                </td>
                                                            }
                                                            </>

                                                        ))
                                                    }
                                                </>
                                            ))}

                                        </TableRow>
                                        {scholasticListData.attributes.class_subjects.map((subjectData: any) => (
                                            <TableRow>
                                                <TableCell className={this.props.classes.assessmentLearning_classAndSection_commonCss} style={{ borderRadius: "0px" }}>
                                                    {subjectData.subject_name}
                                                </TableCell>
                                                {scholasticListData.attributes.report_categories.map((categoryData: any) => (
                                                    <>
                                                        {
                                                            categoryData.categories.map((data: any) => (
                                                                <TableCell align="center" className={this.props.classes.assessmentLearning_classAndSection_commonCss} style={{ borderRadius: "0px" }}>
                                                                    <Table>
                                                                        <tr>
                                                                            {data.sub_category.data.length > 0 && data.sub_category.data.map((subData: any, index: number) => {
                                                                                return (
                                                                                    <>
                                                                                        {subData.term_id === termData.id &&
                                                                                            <td>
                                                                                            </td>
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </tr>
                                                                    </Table>
                                                                </TableCell>
                                                            ))
                                                        }
                                                    </>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer >
                        ))}
                    </div>
                </div>
            </>
        // Customizable Area End
        );
    }
}
export default withStyles(themeCreateStyle)(
    // Customizable Area Start
    HOCAuth(AssessmentLearning as React.ComponentType<any>, "AdminAccount")
    // Customizable Area End
);
