// Customizable Area Start
import React from "react";
import { Box, Grid, Modal, Button } from "@material-ui/core";
import { warningIcon } from "../../assets";
// Customizable Area End

// Customizable Area Start
const noText = {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: "center",
    color: "#000",
} as const;
const yesText = {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: "center",
    color: "#fff"
} as const;
const noButton = {
    width: 88,
    height: 40,
    margin: "0 20px 0 0",
    borderRadius: "3px",
    textTransform: "initial",
    backgroundColor: "#f6f6f6",
} as const;
const yesButton = {
    width: 88,
    height: 40,
    borderRadius: "3px",
    backgroundColor: "#253873",
    textTransform: "initial",
    border: "solid 1px #253873",
} as const;
// Customizable Area End

interface Props {
    // Customizable Area Start
    openTermAlertModal: boolean;
    onCloseModal: any;
    submitYesClick: any;
    // Customizable Area End
}

// Customizable Area Start
const TermAlertModal = ({
    openTermAlertModal,
    onCloseModal,
    submitYesClick,
}: Props) => {
    return (
        <>
            <Modal
                open={openTermAlertModal}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="delete_modal_main_div">
                    <Grid className="flex-center">
                        <img src={warningIcon} alt="warning" className="alert-img-term"></img>
                    </Grid>

                    <div className="delete_msg_1">
                        This action will discard all data related to this term. Are you sure you want to edit?
                    </div>

                    <Grid className="submit_btn_section">
                        <Button style={noButton} onClick={onCloseModal}>
                            <span style={noText}>No</span>
                        </Button>
                        <Button style={yesButton} onClick={submitYesClick}>
                            <span style={yesText}>Yes</span>
                        </Button>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};
// Customizable Area End

export default TermAlertModal;
