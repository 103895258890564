// Customizable Area Start
export const camera_white = require("../../assets/camera-white.png");
export const info = require("../../assets/information.png");
export const camera_icon = require("../../assets/cameraicon.png");
export const clock = require("../../assets/clock-1.png");
export const image_info = require("../../assets/image-info.png");
export const image_camera = require("../../assets/image-camera.png");
export const image_camera_dark = require("../../assets/image-camera-dark.png");
export const image_profile = require("../../assets/image-profile.png");
export const image_mathematics = require("../../assets/image-mathematics.png");
export const image_clock = require("../../assets/image-clock.png");
export const image_user = require("../../assets/image-user.png");
export const image_presentation = require("../../assets/image-presentation.png");
export const image_videoicon = require("../../assets/image-videoicon.png");
export const image_class = require("../../assets/image-class.png");
export const image_calendar = require("../../assets/image-calendar.png");
export const image_lunch_time = require("../../assets/image-lunchtime.png");
export const information_1 = require("../../assets/information-1.png");
export const information = require("../../assets/information.png");
export const bitmap_camera = require("../../assets/bitmap-camera.png");
export const user = require("../../assets/user.png");
export const presentation_1 = require("../../assets/presentation-1.png");
export const video_camera = require("../../assets/video-camera.png");
export const video_camera_live = require("../../assets/camera_live.png").default

export const class_1 = require("../../assets/class.png");
export const calendar = require("../../assets/calendar.png");
export const clock_1 = require("../../assets/clock.png");
export const imageCameraicon = require("../../assets/image-cameraicon.png");
export const subjectIcon = require("../../assets/subject-default.png").default;
export const infoIcon = require("../../assets/infoButton.png").default;
// Customizable Area End