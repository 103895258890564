//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
// Customizable Area Start
import React from 'react';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import OtpInput from 'react-otp-input';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Spinner from '../../shared/SideBar/src/Spinner';
import {
  img21kSchholLogo,
  imgloginScreenBackground,
  manImage,
  bigCloud,
  smallCloud,
} from './assets';

//@ts-ignore
import './ForgotPasswordOTP.web.css';
import ForgotPasswordAdminOTPController, {
  Props,
} from './ForgotPasswordAdminOTPController.web';
// Customizable Area End

// Customizable Area Start
const root = {
  flexGrow: 1,
  overflowX: 'hidden',
} as const;
const loginImageDiv = {
  position: 'relative',
  height: '100vh',
} as const;
const halfcircleImage = {
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(142, 123, 253, 1)',
  position: 'absolute',
  zIndex: 0,
} as const;
const logoImage = {
  width: 'auto',
  height: '68px',
  position: 'relative',
  margin: '33px 29px 29px 30px',
} as const;
const textlabel_quoteText = {
  color: 'rgba(255, 255, 255, 1)',
  fontWeight: 'bold',
  position: 'relative',
  fontFamily: 'Open sans',
  fontSize: '4.5rem',
  margin: '25px 34px',
} as const;
const image_studentillustratorImage = {
  width: '68%',
  height: 'auto',
  bottom: '0%',
  position: 'absolute',
  transform: 'translateX(80%)',
} as const;
const image_cloudsmallImage = {
  width: '65px',
  height: '51px',
  position: 'absolute',
  top: '61%',
  left: '30%',
} as const;
const image_cloudbigImage = {
  width: '151px',
  height: '117px',
  bottom: '10%',
  left: '8%',
  position: 'absolute',
} as const;
const attrbuted_textlabel_div = {
  textAlign: 'right',
  fontFamily: 'Open sans',
  fontSize: '16px',
  marginTop: '10px',
  padding: '0 0 41.9px',
} as const;
const attrbuted_textlabel_signinText = {
  color: 'rgba(3, 1, 76, 1)',
} as const;
const attrbuted_textlabel_signinText2 = {
  color: 'rgba(0, 0, 0, 1)',
  fontWeight: 'bold',
  marginLeft: '10px',
  marginRight: '5px',
} as const;
const loginDiv = {
  justifyContent: 'center',
  margin: 'auto',
  width: '60%',
  paddingLeft: '30px',
  marginTop: '30px',
} as const;
const textlabel_welcomeText = {
  fontSize: '35.1px',
  fontWeight: 'bold',
  fontFamily: 'Open sans',
} as const;
const textlabel_followinstructionText = {
  width: '100%',
  color: '#03014c',
  fontSize: '14px',
  fontFamily: 'Open sans',
  lineHeight: 1.5,
} as const;
const formDiv = {
  width: '100%',
} as const;
const textlabel_forgotpasswordText = {
  textAlign: 'right',
  fontWeight: 'bold',
  fontSize: '16px',
  margin: '24.7px 0 0 0',
  fontFamily: 'Open sans',
} as const;
const textlabel_loginText = {
  fontWeight: 'bold',
  color: 'rgba(255, 255, 255, 1)',
  margin: '41.9px 0 0',
  width: '100%',
  backgroundColor: 'rgba(142, 123, 253, 1)',
  padding: '10px',
  textTransform: 'none',
} as const;
const forrmInput_textfield = {
  width: '20%',
  borderRadius: '8.8px',
  border: '1px solid #cfcfcf',
  backgroundColor: '#f1f1f1',
  marginTop: '20px',
} as const;
const resendotp_text = {
  fontSize: '22px',
  fontWeight: 'bold',
  color: '#8a77fd',
  cursor: 'pointer',
} as const;
const spinnerBar = {
  color: 'rgba(255, 255, 255, 1)',
} as const;
// Customizable Area End

export default class ForgotPasswordAdminOTP extends ForgotPasswordAdminOTPController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      otp: '',
      otp_code: '',
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      loadingSpinner: false,
      otperror: false,
      otperrorText: '',
    };
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const reg = /^\d+$/;
    const { otp1, otp2, otp3, otp4, otp_code } = this.state;
    return (
      <div>
        {this.state.loadingSpinner && (
          <Spinner spinnerModal={this.state.loadingSpinner} />
        )}
        <div style={root}>
          <Grid container spacing={0}>
            <Grid xs={12} sm={6} spacing={0} style={loginImageDiv}>
              <img
                src={imgloginScreenBackground}
                style={halfcircleImage}
                alt="Background Image"
              />
              <img
                src={manImage}
                alt="Student Illustrator Image"
                style={image_studentillustratorImage}
                className="student_illustrator_img"
              />
              <div>
                <img
                  className="SchholLogo"
                  src={img21kSchholLogo}
                  alt="Logo Image"
                  style={logoImage}
                />
              </div>
              <div style={textlabel_quoteText}>
                Let’s learn
                <br />
                Together
              </div>
              <img
                src={smallCloud}
                alt="Small Cloud Image"
                style={image_cloudsmallImage}
              />
              <img
                src={bigCloud}
                alt="Big Cloud Image"
                style={image_cloudbigImage}
              />
            </Grid>
            <Grid xs={12} sm={6} spacing={0}>
              <div>
                <div style={attrbuted_textlabel_div}>
                  <span style={attrbuted_textlabel_signinText}>
                    Do you have account?
                  </span>
                  <Link
                    href="/EmailAccountLoginBlock"
                    style={attrbuted_textlabel_signinText2}
                  >
                    Login
                  </Link>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div style={loginDiv}>
                    <div>
                      <div style={textlabel_welcomeText}>
                        Verify your account
                      </div>
                      <p style={textlabel_followinstructionText}>
                        Enter the verification code we just sent you on your
                        email or mobile number.
                      </p>
                    </div>
                    <div style={formDiv}>
                      {/* <OtpInput
                    value={this.state.otp}
                    onChange={(otp: any) => {
                      if (
                        otp === null ||
                        otp.length === 0
                      ) {
                        this.setState({ otperror: true, otperrorText: 'OTP is required.' });
                      }
                      else if (!reg.test(otp)) {
                        this.setState({ otperror: true, otperrorText: 'Enter a valid OTP.' });
                      }
                      else{
                        this.setState({otp:otp, otperror: false, otperrorText: '' });
                      }
                      this.setState({otp})
                    }}
                    isInputNum={true}
                    focusStyle={false}
                    inputStyle="emailLoginOTP"
                    numInputs={4}
                  /> */}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <TextField
                          id="standard-basic"
                          style={forrmInput_textfield}
                          variant="outlined"
                          type="number"
                          name="otp_code"
                          autoFocus={true}
                          value={this.state.otp1}
                          error={this.state.otperror}
                          onPaste={(e) => {
                            const otp = e.clipboardData.getData('Text');
                            console.log(
                              'hashdkjas',
                              e.clipboardData.getData('Text')
                            );
                            this.setState({
                              otp1: otp[0],
                              otp2: otp[1],
                              otp3: otp[2],
                              otp4: otp[3],
                            });
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            this.setState({ otp1: event.target.value });
                            if (
                              (event.target.value + otp2 + otp3 + otp4)
                                .length == 0
                            ) {
                              this.setState({
                                otperror: true,
                                otperrorText: 'OTP is required.',
                              });
                            } else if (
                              (event.target.value + otp2 + otp3 + otp4).length <
                              4
                            ) {
                              this.setState({
                                otperror: true,
                                otperrorText: 'Enter a valid OTP.',
                              });
                            } else {
                              this.setState({ otperror: false });
                            }
                            if (event.target.value.length === 1) {
                              const nextfield = document.querySelector(
                                `input[name=otp2]`
                              );
                              if (nextfield !== null) {
                                (nextfield as HTMLElement).focus();
                              }
                            }
                          }}
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              textAlign: 'center',
                            },
                          }}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 1);
                          }}
                          InputProps={{
                            style: {
                              fontSize: '15.8px',
                              color: '#03014c',
                              fontFamily: 'OpenSans',
                              fontWeight: 600,
                              // border: "1px solid #cfcfcf",
                              backgroundColor: '#f1f1f1',
                            },
                          }}
                        />
                        <TextField
                          id="standard-basic"
                          style={forrmInput_textfield}
                          variant="outlined"
                          type="number"
                          name="otp2"
                          value={this.state.otp2}
                          error={this.state.otperror}
                          onPaste={(e) => {
                            const otp = e.clipboardData.getData('Text');
                            console.log(
                              'hashdkjas',
                              e.clipboardData.getData('Text')
                            );
                            this.setState({
                              otp1: otp[0],
                              otp2: otp[1],
                              otp3: otp[2],
                              otp4: otp[3],
                            });
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            this.setState({ otp2: event.target.value });
                            if (
                              (event.target.value + otp1 + otp3 + otp4)
                                .length == 0
                            ) {
                              this.setState({
                                otperror: true,
                                otperrorText: 'OTP is required.',
                              });
                            } else if (
                              (event.target.value + otp1 + otp3 + otp4).length <
                              4
                            ) {
                              this.setState({
                                otperror: true,
                                otperrorText: 'Enter a valid OTP.',
                              });
                            } else {
                              this.setState({ otperror: false });
                            }
                            if (event.target.value.length === 1) {
                              const nextfield = document.querySelector(
                                `input[name=otp3]`
                              );
                              // If found, focus the next field
                              if (nextfield !== null) {
                                (nextfield as HTMLElement).focus();
                              }
                            }
                          }}
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              fontWeight: 600,
                              color: '#03014c',
                              textAlign: 'center',
                            },
                          }}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 1);
                          }}
                          InputProps={{
                            style: {
                              fontSize: 15.8,
                              color: '#03014c',
                              fontFamily: 'OpenSans',
                              fontWeight: 600,
                              // border: "1px solid #cfcfcf",
                              backgroundColor: '#f1f1f1',
                            },
                          }}
                        />
                        <TextField
                          id="standard-basic"
                          style={forrmInput_textfield}
                          variant="outlined"
                          type="number"
                          name="otp3"
                          value={this.state.otp3}
                          error={this.state.otperror}
                          onPaste={(e) => {
                            const otp = e.clipboardData.getData('Text');
                            console.log(
                              'hashdkjas',
                              e.clipboardData.getData('Text')
                            );
                            this.setState({
                              otp1: otp[0],
                              otp2: otp[1],
                              otp3: otp[2],
                              otp4: otp[3],
                            });
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            this.setState({ otp3: event.target.value });
                            if (
                              (event.target.value + otp1 + otp2 + otp4)
                                .length == 0
                            ) {
                              this.setState({
                                otperror: true,
                                otperrorText: 'OTP is required.',
                              });
                            } else if (
                              (event.target.value + otp1 + otp2 + otp4).length <
                              4
                            ) {
                              this.setState({
                                otperror: true,
                                otperrorText: 'Enter a valid OTP.',
                              });
                            } else {
                              this.setState({ otperror: false });
                            }
                            if (event.target.value.length === 1) {
                              const nextfield = document.querySelector(
                                `input[name=otp4]`
                              );
                              // If found, focus the next field
                              if (nextfield !== null) {
                                (nextfield as HTMLElement).focus();
                              }
                            }
                          }}
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              fontWeight: 600,
                              color: '#03014c',
                              textAlign: 'center',
                            },
                          }}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 1);
                          }}
                          InputProps={{
                            style: {
                              fontSize: 15.8,
                              color: '#03014c',
                              fontFamily: 'OpenSans',
                              fontWeight: 600,
                              // border: "1px solid #cfcfcf",
                              backgroundColor: '#f1f1f1',
                            },
                          }}
                        />
                        <TextField
                          id="standard-basic"
                          style={forrmInput_textfield}
                          variant="outlined"
                          type="number"
                          name="otp4"
                          value={this.state.otp4}
                          error={this.state.otperror}
                          onPaste={(e) => {
                            const otp = e.clipboardData.getData('Text');
                            console.log(
                              'hashdkjas',
                              e.clipboardData.getData('Text')
                            );
                            this.setState({
                              otp1: otp[0],
                              otp2: otp[1],
                              otp3: otp[2],
                              otp4: otp[3],
                            });
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            this.setState({ otp4: event.target.value });
                            if (
                              (event.target.value + otp1 + otp2 + otp3)
                                .length == 0
                            ) {
                              this.setState({
                                otperror: true,
                                otperrorText: 'OTP is required.',
                              });
                            } else if (
                              (event.target.value + otp1 + otp2 + otp3).length <
                              4
                            ) {
                              this.setState({
                                otperror: true,
                                otperrorText: 'Enter a valid OTP.',
                              });
                            } else {
                              this.setState({ otperror: false });
                            }
                          }}
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              fontWeight: 600,
                              color: '#03014c',
                              textAlign: 'center',
                            },
                          }}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 1);
                          }}
                          InputProps={{
                            style: {
                              fontSize: 15.8,
                              color: '#03014c',
                              fontFamily: 'OpenSans',
                              fontWeight: 600,
                              // border: "1px solid #cfcfcf",
                              backgroundColor: '#f1f1f1',
                            },
                          }}
                        />
                      </div>
                    </div>
                    {this.state.otperror && (
                      <FormHelperText
                        style={{
                          color: 'red',
                          margin: 'auto',
                          fontSize: '14px',
                        }}
                      >
                        {this.state.otperrorText}
                      </FormHelperText>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={textlabel_loginText}
                      // onClick={() => { this.handleSubmit() }}
                    >
                      Verify
                    </Button>
                    <div style={textlabel_forgotpasswordText}>
                      <span>Didn't receive OTP?</span>{' '}
                      <Link
                        style={resendotp_text}
                        onClick={() => {
                          const nextfield = document.querySelector(
                            `input[name=otp_code]`
                          );
                          // If found, focus the next field
                          if (nextfield !== null) {
                            (nextfield as HTMLElement).focus();
                          }
                          this.resendOtp();
                        }}
                      >
                        Resend
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
