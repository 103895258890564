// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  questionPreviewModal: boolean;
  closeModalFun?: any;
  classes?: any;
  questionType?: string;
  optionAnswer?: any;
  questionHint?: any;
  question?: any;
  fillBlankType?: any;
  textAnswer?: any;
  selectedImageBlobUrl?: any;
  audioList?: any;
  stepItems?: any;
  stepperSelectedRadioValue?: any;
  activeStepperStep?: any;
  isEmojiType?: any;
  videosList?: any;
  fromUploadVideo?: any;
  removeSelectedVideo?: any;
  handleSetDuration?: any;
  selectVideo?: any;
  matchQuestionList?: any;
  sortingResponseList?: any;
  boxCoordinates?: any;
  graph_axis_settings_attributes: any;
  graph_plotting_categories_attributes: any;
  graph_properties: any;
  graphDetails?:any;
  graph_lines_attributes?:any;
  flash_cards_list?:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  closeModal: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class QuestionPreviewModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      closeModal: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }
  modalhandleClose = () => {
    this.props.closeModalFun();
  };
  // Customizable Area End
}
