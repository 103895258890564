// Customizable Area Start
import React from "react";
import './LiveClassRecordings.web.css';
import {
    Grid,
    Button,
    Typography,
    Divider,
    Box,
} from "@material-ui/core";
import LiveClassRecordingsController, { Props } from "./LiveClassRecordingsController.web";
import Datetime from "react-datetime";
import moment from "moment";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { bgGroupImg } from "../assets";
// Customizable Area End
// Customizable Area Start
export default class LiveClassRecordings extends LiveClassRecordingsController {
    // Customizable Area Start
    render() {
        const { recordingsList, selectedRecording, selectedDate, multipleRecordingList } = this.state;
        const today = moment().subtract(0, 'day');
        const disableFutureDt = (current: any) => {
            return current.isBefore(today);
        };
        return (
            <>
                {this.state.showSpinner && (<Spinner spinnerModal={this.state.showSpinner} />)}
                <div>
                    <Typography className="textlabel_classes">Classes</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={5} md={4}>
                            <div className="view_whitebox_bg">
                                <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 15 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={4}>
                                        <Typography className="textlabel_recordings">Recordings</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={8} className="right_date_section">
                                        <Datetime
                                            dateFormat={"DD MMMM,YYYY"}
                                            timeFormat={false}
                                            strictParsing={true}
                                            input={true}
                                            isValidDate={disableFutureDt}
                                            value={moment(selectedDate, "DD-MM-YYYY").format("DD MMMM,YYYY")}
                                            initialValue={moment().format("DD MMMM,YYYY")}
                                            className="input_today_date_css_format2"
                                            inputProps={{
                                                placeholder: "Date of Birth",
                                                className: "Date_of_birth_calendar_Icon_register",
                                                readOnly: true,
                                            }}
                                            closeOnSelect
                                            onChange={(date) => {
                                                this.setState(
                                                    {
                                                        selectedDate: moment(date),
                                                        changedDate: true,
                                                    },
                                                    () => {
                                                        this.getRecordingList();
                                                    }
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Divider />

                                {recordingsList?.length > 0 ?
                                    <>
                                        {recordingsList?.map((recordingData: any) => (
                                            <div className={Number(recordingData.id) === Number(selectedRecording?.attributes?.meeting?.id) ? "recording_section_selected" : "recording_section"} onClick={() => this.onChangeRecording(recordingData)}>

                                                <Typography className="recording_title">{recordingData?.attributes?.topic}</Typography>
                                                <Typography className="recording_detail">
                                                    {recordingData?.attributes?.start_time}
                                                    {/* {moment(recordingData?.attributes?.recordings?.data?.[recordingData?.attributes?.recordings?.data?.length - 1]?.attributes?.recording_end).format("DD MMMM YYYY hh:mm A")} */}
                                                </Typography>

                                            </div>
                                        ))}
                                    </>
                                    :
                                    <Box mt={2}>
                                        <Typography className="detail_section_text">
                                            No recording found.
                                        </Typography>
                                    </Box>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={7} md={8}>
                            <div className="view_whitebox_bg_right">
                                {selectedRecording ?
                                    <>
                                        <Typography className="recording_header">{selectedRecording?.attributes?.meeting?.topic} ({this.state.selectedRecordingTitle})</Typography>
                                        <Typography className="detail_section_text">
                                            {moment(selectedRecording?.attributes?.recording_end).format("DD MMMM YYYY HH:mm A")}
                                        </Typography>
                                        {/* <Button className="view_recording_btn">View</Button> */}
                                        <video width="100%" controls disablePictureInPicture src={selectedRecording?.attributes?.play_url} className="recording_video" />
                                    </>
                                    :
                                    <>
                                        <Typography className="detail_section_text">
                                            No recording found.
                                        </Typography>
                                    </>}

                                <Grid container spacing={3} style={{ marginTop: "10px" }}>
                                    {multipleRecordingList?.map((recordingData: any, index: number) => (
                                        <Grid item xs={12} sm={6} md={6} lg={4}>
                                            <Typography className="recording_header_small">Recording {index + 1}</Typography>
                                            <Typography className="detail_section_text">
                                                {moment(recordingData?.attributes?.recording_end).format("DD MMMM YYYY HH:mm A")}
                                            </Typography>
                                            <div className="recording_video_small">
                                                <img src={bgGroupImg} style={{ cursor: "pointer", width: "100%" }} onClick={() => { this.setState({ selectedRecording: recordingData, selectedRecordingTitle: `Recording ${index + 1}` }) }}></img>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area End