// Customizable Area Start
import { IBlock } from '../../../framework/src/IBlock';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import { runEngine } from '../../../framework/src/RunEngine';
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
import { toast } from "react-toastify";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  schoolName: any;
  schoolNameError: boolean;
  schoolNameErrorMes: any;
  schoolBoard: any;
  schoolBoardError: boolean;
  schoolBoardErrorMes: any;
  schoolAffiliationNo: any;
  schoolAffiliationNoError: boolean;
  schoolAffiliationNoErrorMes: any;
  schoolStartDate: any;
  schoolStartDateError: boolean;
  schoolStartDateErrorMes: any;
  schoolEndDate: any;
  schoolEndDateError: boolean;
  schoolEndDateErrorMes: any;
  schoolMedium: any;
  schoolMediumError: boolean;
  schoolMediumErrorMes: any;
  schoolAddress: any;
  schoolAddressError: boolean;
  schoolAddressErrorMes: any;
  schoolCity: any;
  schoolCityError: boolean;
  schoolCityErrorMes: any;
  schoolState: any;
  schoolStateError: boolean;
  schoolStateErrorMes: any;
  schoolCountry: any;
  schoolCountryError: boolean;
  schoolCountryErrorMes: any;
  schoolPincode: any;
  schoolPincodeError: boolean;
  schoolPincodeErrorMes: any;
  schoolPhoneNo: any;
  schoolPhoneNoError: boolean;
  schoolPhoneNoErrorMes: any;
  schoolLandlineNo: any;
  schoolLandlineNoError: boolean;
  schoolLandlineNoErrorMes: any;
  schoolEmail: any;
  schoolEmailError: boolean;
  schoolEmailErrorMes: any;
  stateList: any;
  countryList: any;
  mediumList: any;
  selectedMedium: any;
  selectedBoard: any;
  loadingSpinner: boolean;
  boardList: any;
  counrtyCode: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OnBoardingSchoolDeatilsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCountry: any;
  apiGetCountryState: any;
  schoolDetailsApiId: any;
  apiGetBoards: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      schoolName: '',
      schoolNameError: false,
      schoolNameErrorMes: '',
      schoolBoard: '',
      schoolBoardError: false,
      schoolBoardErrorMes: '',
      schoolAffiliationNo: '',
      schoolAffiliationNoError: false,
      schoolAffiliationNoErrorMes: '',
      schoolStartDate: '',
      schoolStartDateError: false,
      schoolStartDateErrorMes: '',
      schoolEndDate: '',
      schoolEndDateError: false,
      schoolEndDateErrorMes: '',
      schoolMedium: '',
      schoolMediumError: false,
      schoolMediumErrorMes: '',
      schoolAddress: '',
      schoolAddressError: false,
      schoolAddressErrorMes: '',
      schoolCity: '',
      schoolCityError: false,
      schoolCityErrorMes: '',
      schoolState: '',
      schoolStateError: false,
      schoolStateErrorMes: '',
      schoolCountry: 'IN',
      schoolCountryError: false,
      schoolCountryErrorMes: '',
      schoolPincode: '',
      schoolPincodeError: false,
      schoolPincodeErrorMes: '',
      schoolPhoneNo: '',
      schoolPhoneNoError: false,
      schoolPhoneNoErrorMes: '',
      schoolLandlineNo: '',
      schoolLandlineNoError: false,
      schoolLandlineNoErrorMes: '',
      schoolEmail: '',
      schoolEmailError: false,
      schoolEmailErrorMes: '',
      stateList: [],
      countryList: [],
      mediumList: [
        {
          id: 1,
          name: 'English',
          value: 'English',
        },
        {
          id: 2,
          name: 'Marathi',
          value: 'Marathi',
        },
        {
          id: 3,
          name: 'Hindi',
          value: 'Hindi',
        },
        {
          id: 4,
          name: 'Punjabi',
          value: 'Punjabi',
        },
      ],
      selectedBoard: '',
      selectedMedium: '',
      loadingSpinner: false,
      boardList: [],
      counrtyCode: '',
    };
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getCountry();
    this.getCountryState();
    this.getBoards();
    const {
      schoolName,
      schoolBoard,
      schoolAffiliationNo,
      schoolStartDate,
      schoolEndDate,
      schoolMedium,
      schoolAddress,
      schoolCity,
      schoolCountry,
      schoolState,
      schoolPhoneNo,
      schoolLandlineNo,
      schoolPincode,
      schoolEmail,
    } = this.state;

    if (localStorage.getItem("school_data") !== null) {
      const school_data = localStorage.getItem("school_data");
      const data = JSON.parse(school_data || "{}");
      this.setState({
        schoolName: data.name,
        schoolAffiliationNo: data.school_code,
        schoolAddress: data.address,
        schoolCountry: data.country,
        schoolState: data.state,
        schoolCity: data.city,
        schoolPhoneNo: data.phone,
        schoolLandlineNo: data.landline,
        schoolMedium: data.medium,
        schoolPincode: data.pincode,
        schoolStartDate: data.session_start,
        schoolEndDate: data.session_end,
        schoolBoard: data.board_id,
        schoolEmail: data.email,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  onSchoolDetailsSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const {
      schoolName,
      schoolBoard,
      schoolAffiliationNo,
      schoolStartDate,
      schoolEndDate,
      schoolMedium,
      schoolAddress,
      schoolCity,
      schoolCountry,
      schoolState,
      schoolPhoneNo,
      schoolLandlineNo,
      schoolPincode,
      schoolEmail,
    } = this.state;
    if (
      schoolName.length === 0 ||
      schoolBoard.length === 0 ||
      schoolEndDate.length === 0 ||
      schoolStartDate.length === 0 ||
      schoolMedium.length === 0 ||
      schoolAddress.length === 0 ||
      schoolCity.length === 0 ||
      schoolCountry.length === 0 ||
      schoolState.length === 0 ||
      schoolPhoneNo.length === 0 ||
      schoolPincode.length === 0
    ) {
      if (this.state.schoolName === '' || this.state.schoolName.length === 0) {
        this.setState({
          schoolNameError: true,
          schoolNameErrorMes: 'School name is required.',
        });
      }
      if (
        this.state.schoolBoard === '' ||
        this.state.schoolBoard.length === 0
      ) {
        this.setState({
          schoolBoardError: true,
          schoolBoardErrorMes: 'Please select board.',
        });
      }

      if (
        this.state.schoolMedium === '' ||
        this.state.schoolMedium.length === 0
      ) {
        this.setState({
          schoolMediumError: true,
          schoolMediumErrorMes: 'Please select medium.',
        });
      }

      if (
        this.state.schoolEndDate === '' ||
        this.state.schoolEndDate.length === 0
      ) {
        this.setState({
          schoolEndDateError: true,
          schoolEndDateErrorMes: 'Please select end session.',
        });
      }
      if (
        this.state.schoolStartDate === '' ||
        this.state.schoolStartDate.length === 0
      ) {
        this.setState({
          schoolStartDateError: true,
          schoolStartDateErrorMes: 'Please select start session.',
        });
      }
      if (
        this.state.schoolAddress === '' ||
        this.state.schoolAddress.length === 0
      ) {
        this.setState({
          schoolAddressError: true,
          schoolAddressErrorMes: 'Address is required.',
        });
      }
      if (this.state.schoolCity === '' || this.state.schoolCity.length === 0) {
        this.setState({
          schoolCityError: true,
          schoolCityErrorMes: 'City is required.',
        });
      }
      if (
        this.state.schoolState === '' ||
        this.state.schoolState.length === 0
      ) {
        this.setState({
          schoolStateError: true,
          schoolStateErrorMes: 'Please select state.',
        });
      }
      if (
        this.state.schoolCountry === '' ||
        this.state.schoolCountry.length === 0
      ) {
        this.setState({
          schoolCountryError: true,
          schoolCountryErrorMes: 'Please select country.',
        });
      }
      if (
        this.state.schoolPhoneNo === '' ||
        this.state.schoolPhoneNo.length === 0
      ) {
        this.setState({
          schoolPhoneNoError: true,
          schoolPhoneNoErrorMes: 'Phone number is required.',
        });
      } else if (schoolPhoneNo.length < 7) {
        this.setState({
          schoolPhoneNoError: true,
          schoolPhoneNoErrorMes: ' Enter a valid phone number.',
        });
      }
      if (
        this.state.schoolPincode === '' ||
        this.state.schoolPincode.length === 0
      ) {
        this.setState({
          schoolPincodeError: true,
          schoolPincodeErrorMes: 'Pincode is required.',
        });
      } else if (schoolPincode.length < 4) {
        this.setState({
          schoolPincodeError: true,
          schoolPincodeErrorMes: ' Enter a valid pincode.',
        });
      }
      if (
        this.state.schoolEmail === '' ||
        this.state.schoolEmail.length === 0
      ) {
        this.setState({
          schoolEmailError: true,
          schoolEmailErrorMes: 'Email is required.',
        });
      } else if (!emailReg.test(schoolEmail)) {
        this.setState({
          schoolEmailError: true,
          schoolEmailErrorMes: ' Enter a valid email.',
        });
      }

      return false;
    }

    const data = {
      name: schoolName,
      school_code: schoolAffiliationNo,
      address: schoolAddress,
      country: schoolCountry,
      state: schoolState,
      city: schoolCity,
      phone: this.state.counrtyCode + schoolPhoneNo,
      landline: schoolLandlineNo,
      medium: schoolMedium,
      pincode: schoolPincode,
      session_start: schoolStartDate,
      session_end: schoolEndDate,
      board_id: schoolBoard,
      email: schoolEmail,
    };

    this.setState({ loadingSpinner: true });
    const header = {
      'Content-Type': configJSON.contentTypeApiAddDetail,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.schoolDetailsApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.schoolDetailsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCountry = () => {
    this.setState({ loadingSpinner: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCountry = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBoards = () => {
    this.setState({ loadingSpinner: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetBoards = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBoardEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCountryState = () => {
    this.setState({ loadingSpinner: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCountryState = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStateEndPoint + `?country_code=${this.state.schoolCountry}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiGetCountry) {
        this.setState({ loadingSpinner: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            let arr: any = [];
            for (const property in responseJson.data) {
              arr.push({
                name: responseJson.data[property],
                value: property,
              });
            }
            this.setState({ countryList: arr });
            this.setState({ loadingSpinner: false });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.apiGetBoards) {
        this.setState({ loadingSpinner: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            const arr: any = [];
            responseJson.data.map((_data: any) => {
              arr.push({
                id: _data.id,
                name: _data.name,
              });
            });
            this.setState({ boardList: arr });
            this.setState({ loadingSpinner: false });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.apiGetCountryState) {
        this.setState({ loadingSpinner: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            let arr: any = [];
            for (const property in responseJson.data) {
              arr.push({
                name: responseJson.data[property],
                value: property,
              });
            }
            this.setState({
              stateList: arr,
              counrtyCode: responseJson.country_code,
            });
            this.setState({ loadingSpinner: false });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.schoolDetailsApiId) {
        this.setState({ loadingSpinner: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            localStorage.setItem('schoolId', responseJson.data.id);
            localStorage.setItem(
              'school_data',
              JSON.stringify(responseJson.data.attributes)
            );
            this.props.navigation.navigate('LogoAndPicture');
            toast.success('School details submitted successfully.');
          } else {
            let responseJsonError = {
              "errors": [
                {
                  "account": responseJson.errors
                }
              ]
            }
            this.parseApiErrorResponse(responseJsonError);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  goNext = () => {
    this.props.navigation.navigate('LogoAndPicture');
  };
  // Customizable Area End
}
