// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import HOCAuth from "../../../../../shared/Header/src/HOCAuth.web";
import {
  FOLDER_ICON
} from "../../../assets";
import WardResourcesController, { Props } from "./WardResourcesController.web";
import "../WardDetails.web.css"
import { themeCreateStyle } from "../../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class WardResources extends WardResourcesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { data, WardResources } = this.state;

    return (
      <Grid className="child_activity_tab_height" container direction="row">
        {
          WardResources?.length > 0 ? (
            <>
              {WardResources.map((resource: any, index: number) =>
                <Grid className={`${this.props.classes.bodyText_font} task_center_align task_display_flex rescource_item_block`} key={index} item xs={12} sm={4} md={4} lg={3}>
                  <Grid container direction="row">
                    <Grid className="task_center_align task_display_flex task_justify_center resource_item" item xs={12}>
                      <img alt="Resource" src={FOLDER_ICON} />
                    </Grid>
                    <Grid className="task_center_align task_display_flex task_justify_center" item xs={12}>
                      <span className="resource_item_subject">{resource?.subject_name}</span>
                    </Grid>
                    <Grid className="task_center_align task_display_flex task_justify_center" item xs={12}>
                      <span className="resource_item_name">{resource?.task_name}</span>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          ) :
            <Grid item xs={12}><span>No tasks yet !</span></Grid>
        }
      </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(WardResources, "TeacherAccount")
);
// Customizable Area End
