// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as SparkMD5 from "spark-md5";
import axios from "axios";
import { toast } from "react-toastify";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation: any;
  portfolioType: any;
  // Customizable Area End

}
interface S {
  // Customizable Area Start
  addProtfolioType: any;
  fileName: any;
  uploadFile: any;
  fileSize: any;
  title: any;
  descprition: any;
  startDate: any;
  endDate: any;
  draft: any;
  titleError: boolean;
  titleErrMessage: any;
  alertMessage: any;
  alertModal: boolean;
  editGoalId: any;
  editFiles: any;
  loadingSpinner: boolean;
  fileError: boolean;
  fileErrMessage: any;
  deleteGoalsId: any;

  // Customizable Area End
}
interface SS {
  id: any;
}

export default class StudentAddResourceAndGoalsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apigetTeacherSubjectsId: string = "";
  apigetSearchSubjectsId: string = "";
  apigetSubjectRemoveId: string = "";
  apigetTeacherClassesId: string = "";
  apiCreateTaskApiId: string = '';
  apigetTeacherUnitId: string = '';
  apigetTeacherCateogoryId: string = '';
  apiPreSignedID: any = [];
  apiAddResource: any;
  apiAddGoal: any;
  apiupdateGoal: any;
  apigetStudentGoalsId: any;
  apideleteGoalId: any;
  // Customizable Area End


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      addProtfolioType: '',
      fileName: '',
      uploadFile: [],
      fileSize: '',
      title: '',
      descprition: '',
      startDate: moment().format("DD-MM-YYYY"),
      endDate: moment().format("DD-MM-YYYY"),
      draft: false,
      titleErrMessage: 'Title is required.',
      titleError: false,
      alertMessage: "",
      alertModal: false,
      editGoalId: "",
      editFiles: [],
      loadingSpinner: false,
      fileErrMessage: 'Resource is required.',
      fileError: false,
      deleteGoalsId: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const type: any = localStorage.getItem("portfolioType")
    if (type === "EditGoal") {
      this.get_student_goals()
    }
    this.setState({ addProtfolioType: type })
  }

  get_student_goals = () => {
    let id: any = localStorage.getItem('editGoalId')
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token'),

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentGoalsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGoalsEndPoint + `${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveImageUsingURL = async (preSignedResponse: any) => {
    const { uploadFile } = this.state;

    let ind: number = 0;
    uploadFile?.map((item: any, index: number) => {
      if (item.signedInId === preSignedResponse?.signed_id) {
        ind = index;
      }
    });

    const header: any = {
      "Content-Type": preSignedResponse?.headers?.["Content-Type"],
      "Content-MD5": preSignedResponse?.headers?.["Content-MD5"],
      token: localStorage.getItem("token"),
    };

    const httpBody = uploadFile[ind].file;

    try {
      const s3Response = await axios({
        method: "put",
        url: preSignedResponse.url,
        data: httpBody,
        headers: header,
      });
    } catch (e) {
      toast.error("Something went wrong.");

      uploadFile[ind].signedInId = "";
      uploadFile[ind].url = "";
      uploadFile[ind].checksum = "";
      uploadFile[ind].file = {};

      this.setState({ uploadFile: uploadFile });
    }
  };

  deleteDoc = (index: any) => {
    let afterDelArr = this.state.uploadFile.filter((row: any, _index: any) => { return (_index !== index) })
    this.setState({ uploadFile: afterDelArr })
  }
  deleteEditDoc = (index: any) => {
    let id: any = this.state.deleteGoalsId;
    id.push(this.state.editFiles[index].id)
    let afterDelArr = this.state.editFiles.filter((row: any, _index: any) => { return (_index !== index) })
    this.setState({ editFiles: afterDelArr, deleteGoalsId: id })
  }

  computeChecksumMd5 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152;
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0;

      fileReader.onerror = function (): void {
        reject("MD5 computation failed - error reading the file");
      };

      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result);
        cursor += chunkSize;

        if (cursor < file.size) {
          processChunk(cursor);
        } else {
          resolve(btoa(spark.end(true)));
        }
      };

      processChunk(0);
    });
  };

  deleteGoal = (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem('token'),

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apideleteGoalId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteResourceApi + `${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.SignIDUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveResource = () => {
    const arr: any = [];
    this.state.uploadFile.map(async (key: any, index: any) => {
      arr.push(this.state.uploadFile[index].signedInId)
    })
    if (this.state.title.length === 0 && this.state.uploadFile.length === 0) {

      this.setState({ titleError: true, fileError: true })
    }
    else if (this.state.title.length === 0) {

      this.setState({ titleError: true })
    }
    else if (this.state.uploadFile.length === 0) {
      this.setState({ fileError: true })

    }
    else {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("token")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiAddResource = requestMessage.messageId;

      const httpBody = {
        "title": this.state.title,
        "description": this.state.descprition,
        "date_added": this.state.startDate,

        "attachments": arr
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postResourceApi
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.baseURLPortfolio
      );


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }


  saveGoal = () => {
    const arr: any = [];
    this.state.uploadFile.map(async (key: any, index: any) => {
      arr.push(this.state.uploadFile[index].signedInId)
    })
    if (this.state.title.length === 0) {
      this.setState({ titleError: true })
    } else if (moment(this.state.endDate, "DD-MM-YYYY").isBefore(moment(this.state.startDate, "DD-MM-YYYY"))) {
      let responseJson = "Start Date can't be greater than End Date."
      this.parseApiCatchErrorResponse(responseJson)
    }
    else {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("token")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiAddGoal = requestMessage.messageId;

      const httpBody = {
        "title": this.state.title,
        "description": this.state.descprition,
        "start_date": this.state.startDate,
        "end_date": this.state.endDate,
        "attachments": arr
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postGoalsApi
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.baseURLPortfolio
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  updateGoal = async () => {
    const arr: any = [];
    await this.state.uploadFile.map(async (key: any, index: any) => {
      arr.push(this.state.uploadFile[index].signedInId)
    })
    await this.state.editFiles.map(async (key: any, index: any) => {

      arr.push(this.state.editFiles[index].attributes.url)
    })
    if (this.state.title.length === 0) {
      this.setState({ titleError: true })
    }
    else if (moment(this.state.endDate, "DD-MM-YYYY").isBefore(moment(this.state.startDate, "DD-MM-YYYY"))) {
      let responseJson = "Start Date can't be greater than End Date."
      this.parseApiCatchErrorResponse(responseJson)
    }
    else {
      this.state.deleteGoalsId.map((_data: any, index: any) => {
        this.deleteGoal(_data)
      })
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("token")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiupdateGoal = requestMessage.messageId;

      const httpBody = {
        "title": this.state.title,
        "description": this.state.descprition,
        "start_date": this.state.startDate,
        "end_date": this.state.endDate,
        "attachments": arr
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postGoalsApi + `${this.state.editGoalId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.baseURLPortfolio
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getPreSignedId = async (fileUploadDetail: any) => {
    this.setState({ loadingSpinner: true })
    const { uploadFile } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      filename: fileUploadDetail.fileName,
      byte_size: fileUploadDetail.fileSize,
      checksum: fileUploadDetail.checksum,
      content_type: fileUploadDetail.content_type,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.SignIDUrl
    );

    this.apiPreSignedID[this.apiPreSignedID.length] = requestMessage.messageId;
    uploadFile.map((item: any) => {
      if (item.checksum === fileUploadDetail.checksum) {
        item.requestID = requestMessage.messageId;
      }
    });
    await this.setState({ uploadFile: uploadFile });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fileUploadEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    const result = runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUpload = (event: any, dragAndDrop: boolean, fileFromDragAndDrop: any) => {
    let allowUpload: boolean = true;
    const validFileType = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "application/pdf",
      "application/msword",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ];
    let files: any;
    if (dragAndDrop) {
      files = fileFromDragAndDrop
    }
    else {
      files = event.target.files
    }
    let totalSize: number = 0
    const keys2 = Object.keys(files)
    keys2?.map(async (key: any) => {
      const file = files[key]
      totalSize = totalSize + file.size
    })
    keys2?.map((key: any) => {
      const file = files[key];
      if (!validFileType.includes(file?.type)) {
        allowUpload = false;
        this.setState({
          alertMessage:
            "Please upload valid file format: .png .jpg .jpeg .doc xls or .pdf",
          alertModal: true,
        });

      }
    });
    if ((files.length + this.state.uploadFile.length) <= 10 && totalSize < 524288000) {
    } else {
      allowUpload = false;
      if (files.length + this.state.uploadFile.length > 10) {
        this.setState({ alertMessage: "Please upload maximum 10 files.", alertModal: true })
      } else {
        this.setState({ alertMessage: "Maximum files size: 500MB", alertModal: true })
      }
    }
    if (allowUpload) {
      const arr: any = this.state.uploadFile
      const keys = Object.keys(files);
      keys?.map(async (key: any) => {
        let fileUpload: any = {}
        const file: any = files[key];


        fileUpload = {
          fileName: file.name,
          name: file.name.split(".")[0],
          isExistingFile: false,
          fileSize: file.size,
          percentage: 0,
          file: file,
          content_type: file.type,
          localUrl: URL.createObjectURL(file)
        };
        arr.push(fileUpload)
        this.setState({ uploadFile: arr })
        let checksum = "";
        await this.computeChecksumMd5(file).then((md5: any) => {
          checksum = md5;
          fileUpload.checksum = checksum;
          this.getPreSignedId(fileUpload);
        });
      })
    }


  }

  cancelResourceChanges = () => {
    localStorage.removeItem("portfolioType")
    this.setState({ addProtfolioType: '' }, () => { this.props.navigation.navigate("StudentFiles") })

  }
  cancelUpdateGoalsChanges = () => {
    localStorage.removeItem("portfolioType")
    localStorage.removeItem("editGoalId")

    this.setState({ addProtfolioType: '' }, () => { this.props.navigation.navigate("StudentProtfolio") })

  }
  cancelGoalsChanges = () => {
    localStorage.removeItem("portfolioType")
    this.setState({ addProtfolioType: '' }, () => { this.props.navigation.navigate("StudentProtfolio") })

  }
  backButton = () => {
    this.props.navigation.navigate("StudentProtfolio")
    localStorage.removeItem("portfolioType")
  }
  goToResource = () => {
    localStorage.setItem("portfolioType", "Resource")
    if (this.state.addProtfolioType !== "Resource") {
      this.setState({ addProtfolioType: 'Resource', title: '', descprition: '', draft: false, startDate: moment().format("DD-MM-YYYY"), endDate: moment().format("DD-MM-YYYY"), uploadFile: [], titleError: false })

    }



  }
  goToGoals = () => {
    localStorage.setItem("portfolioType", "Goals")
    if (this.state.addProtfolioType !== "Goals") {
      this.setState({ addProtfolioType: 'Goals', title: '', descprition: '', draft: false, startDate: moment().format("DD-MM-YYYY"), endDate: moment().format("DD-MM-YYYY"), uploadFile: [], titleError: false })

    }

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.apiPreSignedID.indexOf(apiRequestCallId) === -1
          ? ""
          : apiRequestCallId:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {

                const { uploadFile } = this.state;

                uploadFile?.map((item: any, index: number) => {
                  if (item.requestID === apiRequestCallId) {
                    item.signedInId = responseJson?.data[0].signed_id;

                    item.url = responseJson?.data[0].url;
                  }
                });
                this.setState({ uploadFile: uploadFile });
                this.saveImageUsingURL(responseJson?.data[0]);
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.setState({ loadingSpinner: false })
          }
          break;
        case this.apiAddGoal:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                toast.success("Saved Successfully.");
                localStorage.removeItem("portfolioType")
                this.props.navigation.navigate("StudentProtfolio")
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiupdateGoal:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                toast.success("Updated Successfully.");
                localStorage.removeItem("portfolioType")

                localStorage.removeItem("editGoalId")
                this.props.navigation.navigate("StudentProtfolio")
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apigetStudentGoalsId:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const dataR: any = responseJson.data.attributes
                this.setState({
                  editGoalId: responseJson.data.id, title: dataR.title,
                  descprition: dataR.description, startDate: moment(dataR.start_date, "YYYY-MM-DD").format("DD-MM-YYYY"), endDate: moment(dataR.end_date, "YYYY-MM-DD").format("DD-MM-YYYY"),
                  editFiles: dataR.file.data
                })


              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;

        case this.apiAddResource:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {


                toast.success("Saved Successfully.");
                localStorage.removeItem("portfolioType")
                this.props.navigation.navigate("StudentFiles")
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apideleteGoalId: {
          if (responseJson != null) {
            if (!responseJson.errors) {
              toast.success("Goal updated successfully.")
            }
            else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
          break;
      }


    }
    // Customizable Area End
  }
  // Customizable Area End
}