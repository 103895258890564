// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { computeChecksumMd5 } from "../utils/common";
import { createRef, RefObject } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import convert from "xml-js";
import { HISTORY } from "../../../../components/src/comman";
import { invalidCharsForNumberType } from "../../../profiles/src/utils/common";
import { imageErrorSVG } from "../assets";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  data: any;
  showLoader: boolean;
  marks: any;
  negativeMarks: any;
  wordLimit: any;
  selectedSubject: any;
  selectedChapter: any;
  selectedUnit: any;
  selectedSkill: any;
  selectedProficiency: any;
  selectedDifficultyLevel: any;
  keywordsList: any;
  keyword: string;
  questionOptionList: any;
  charNumber: number;
  chapterListData: any;
  unitListData: any;
  showLoaderForImageUpload: boolean;
  openSelectImageModal: boolean;
  alertMessage: string;
  alertModal: boolean;
  selectedImageFile: any;
  selectedImageBlobUrl: string;
  selectedImageSignedId: any;
  question: string;
  selectedOptionNumber: number;
  optionImageSelection: boolean;
  temp_answers_attributes: any;
  solutionImageSignedId: any;
  multipleResponse: boolean;
  hintAnswer: any;
  hintsNumberr: number;
  answerSelected: boolean;
  explaination: any;
  previewImageModal: boolean;
  openChooseFromGalleryModal: boolean;
  selectedCategoryQuestionData: any;
  assessmentSectionId: any;
  remainSectionMarkPr: number;
  selectedGalleryImageId: number;
  selectedGalleryImageUrl: string;
  marksError: boolean;
  questionError: boolean;
  metaDataErrors: any;
  sectiondata: any;
  isEdit: boolean;
  negmarksError: boolean;
  marksErrorText: string;
  questionId: any;
  explanationId: string;
  hintsExplanationUrl: string;
  attachmentExplanationUrl: string;
  assessmentHasNegativeMarking: boolean;
  mainGalleryImageId: any;
  explainationAttachmentUploadId: string;
  selectedBloomTaxonomy: any;
  hintId: any;
  hintsAttachmentUploadId: string;
  previewImage: string;
  prevQuestionModal: boolean;
  currentOptionRefIndex: any;
  mainImageRemoved:boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class McqAnswerTypeQuestionContoller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuestionListId: string = "";
  deleteQuestionId: string = "";
  getTopicListId: string = "";
  getChapterListId: string = "";
  apiPhotoPreSignedID: string = "";
  apiUploadID: string = "";
  apiSaveQuesionId: string = "";
  apiDeleteSolutionAttachment: string = "";
  readonly inputOpenFileRef: RefObject<HTMLInputElement>;
  readonly inputFirstOpenFileRef: RefObject<HTMLInputElement>;
  questionEditorRef: React.Ref<HTMLInputElement>;
  multipleInputRef: RefObject<HTMLInputElement>[] = [];
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);

    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      data: "",
      showLoader: false,
      marks: 0,
      negativeMarks: 0,
      wordLimit: "",
      selectedSubject: "",
      selectedChapter: "",
      selectedUnit: "",
      selectedSkill: "",
      hintId: null,
      hintsAttachmentUploadId: "",
      selectedProficiency: "",
      selectedDifficultyLevel: "",
      keywordsList: [],
      keyword: "",
      charNumber: 0,
      hintsNumberr: 0,
      questionOptionList: [],
      answerSelected: false,
      hintAnswer: [],
      chapterListData: [],
      unitListData: [],
      showLoaderForImageUpload: false,
      openSelectImageModal: false,
      alertMessage: "",
      alertModal: false,
      selectedImageFile: null,
      selectedImageBlobUrl: "",
      selectedImageSignedId: null,
      question: "",
      selectedOptionNumber: 0,
      optionImageSelection: false,
      temp_answers_attributes: [],
      multipleResponse: false,
      solutionImageSignedId: null,
      explaination: "",
      previewImageModal: false,
      openChooseFromGalleryModal: false,
      selectedCategoryQuestionData: null,
      assessmentSectionId: 0,
      negmarksError: false,
      marksErrorText: "",
      hintsExplanationUrl: "",
      selectedGalleryImageId: 0,
      selectedGalleryImageUrl: "",
      marksError: false,
      questionError: false,
      isEdit: false,
      metaDataErrors: {
        chapter: false,
        unit: false,
        skill: false,
        proficiency: false,
        keywords: false,
        difficultyLevel: false,
        bloomTaxonomy: false,
      },
      remainSectionMarkPr: 0,
      explanationId: "",
      sectiondata: {},
      attachmentExplanationUrl: "",
      mainGalleryImageId: 0,
      questionId: "",
      assessmentHasNegativeMarking: JSON.parse(
        localStorage.getItem("assessmentData") || "{}"
      )?.negative_marking,
      explainationAttachmentUploadId: "",
      selectedBloomTaxonomy: "",
      previewImage: "",
      prevQuestionModal: false,
      currentOptionRefIndex: '',
      mainImageRemoved:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.userdata = this.userdata.bind(this);
    this.get_chapter_list = this.get_chapter_list.bind(this);
    this.inputOpenFileRef = createRef();
    this.inputFirstOpenFileRef = createRef();
    this.questionEditorRef = createRef();
    this.onMarksInputChange = this.onMarksInputChange.bind(this);
    this.onMarksKeyPress = this.onMarksKeyPress.bind(this);
    this.onNegativeMarksInputChange = this.onNegativeMarksInputChange.bind(this);
    this.onQuestionChange = this.onQuestionChange.bind(this);
    this.onQuestionFileDrop = this.onQuestionFileDrop.bind(this);
    this.onQuestionImageError = this.onQuestionImageError.bind(this);
    this.onImagePlusClick = this.onImagePlusClick.bind(this);
    this.onQuestionHiddenFileInputChange = this.onQuestionHiddenFileInputChange.bind(this);
    this.onOptionRadioGroupChange = this.onOptionRadioGroupChange.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.removeOptionImage = this.removeOptionImage.bind(this);
    this.onMultipleResponseCheckboxChange = this.onMultipleResponseCheckboxChange.bind(this);
    this.addNewOption = this.addNewOption.bind(this);
    this.hintAnswerOnchange = this.hintAnswerOnchange.bind(this);
    this.getQuestionSolutionPreSignedValue = this.getQuestionSolutionPreSignedValue.bind(this);
    this.explainationOnchange = this.explainationOnchange.bind(this);
    this.removeSolutionImage = this.removeSolutionImage.bind(this);
    this.onChangeSubject = this.onChangeSubject.bind(this);
    this.onChangeChapter = this.onChangeChapter.bind(this);
    this.onChangeUnit = this.onChangeUnit.bind(this);
    this.onChangeSkill = this.onChangeSkill.bind(this);
    this.onChangeProficiency = this.onChangeProficiency.bind(this);
    this.onChangeDifficultyLevel = this.onChangeDifficultyLevel.bind(this);
    this.onChangeBloomTaxonomy = this.onChangeBloomTaxonomy.bind(this);
    this.onPreviewBtnClick = this.onPreviewBtnClick.bind(this);
    this.onClickCancelButton = this.onClickCancelButton.bind(this);
    this.closeAlertModal = this.closeAlertModal.bind(this);
    this.closeSelectImageModal = this.closeSelectImageModal.bind(this);
    this.onClickChooseFromGallery = this.onClickChooseFromGallery.bind(this);
    this.onClickUploadFromLocal = this.onClickUploadFromLocal.bind(this);
    this.showOpenFileDlg = this.showOpenFileDlg.bind(this);
    this.showFirstOpenFileDlg = this.showFirstOpenFileDlg.bind(this);
    this.closeQuestionPreviewModal = this.closeQuestionPreviewModal.bind(this);
    this.closeChooseFromGalleryModal = this.closeChooseFromGalleryModal.bind(this);
    this.onClickImageChooseFromGalleryModal = this.onClickImageChooseFromGalleryModal.bind(this);
    this.onClickGalleryImageChoose = this.onClickGalleryImageChoose.bind(this);
    this.onTabChangeClearId = this.onTabChangeClearId.bind(this);
    this.closeImagePreivewModal = this.closeImagePreivewModal.bind(this);
    this.answerOptionInputChange = this.answerOptionInputChange.bind(this);
    this.onOptionImageClick = this.onOptionImageClick.bind(this);
    this.onOptionFileInputChange = this.onOptionFileInputChange.bind(this);
    this.onOptionRadioChange = this.onOptionRadioChange.bind(this);
    this.onOptionMultiRadioChange = this.onOptionMultiRadioChange.bind(this);
    this.onOptionUploadImageClick = this.onOptionUploadImageClick.bind(this);
    this.saveQuestionApi = this.saveQuestionApi.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.get_chapter_list();
    const { questionData, sectiondata, sectionId } = HISTORY.location.state;
    if (HISTORY.location.state?.isEdit) {
      let index = questionData?.attributes?.solutions?.data.length - 1;
      let ImageIndex = questionData?.attributes?.attachments?.data?.length - 1;
      this.setState({
        isEdit: HISTORY.location.state?.isEdit,
        marks: questionData?.attributes?.marks,
        negativeMarks: questionData?.attributes?.negative_marks,
        question: questionData?.attributes?.title,
        wordLimit: questionData?.attributes
          ?.word_limit,
        explaination: questionData?.attributes?.solutions?.data[index]?.attributes?.explaination,
        explanationId: questionData?.attributes?.solutions?.data[index]?.id,
        selectedCategoryQuestionData: questionData?.attributes?.type,
        selectedChapter: questionData?.attributes?.metadata?.data?.attributes?.chapter_id,
        selectedUnit: questionData?.attributes?.metadata?.data?.attributes?.unit_id,
        selectedBloomTaxonomy: questionData?.attributes?.metadata?.data?.attributes?.bloom_taxonomy,
        selectedProficiency: questionData?.attributes?.metadata?.data?.attributes?.proficiency,
        selectedSkill: questionData?.attributes?.metadata?.data?.attributes?.skill,
        selectedDifficultyLevel: questionData?.attributes?.metadata?.data?.attributes?.difficulty_level,
        keywordsList: questionData?.attributes?.metadata?.data?.attributes?.keywords,
        questionId: questionData?.id,
        remainSectionMarkPr: ((sectiondata?.section_marks - sectiondata?.remaining_marks) * 100) /
          sectiondata?.section_marks,
        sectiondata: sectiondata,
        selectedImageBlobUrl: questionData?.attributes?.attachments?.data?.[ImageIndex]?.attributes?.url,
        attachmentExplanationUrl: questionData?.attributes
          ?.solutions?.data?.[0]?.attributes?.attachments?.data?.[0]
          ?.attributes?.url,
        explainationAttachmentUploadId: questionData?.attributes
          ?.solutions?.data?.[0]?.attributes?.attachments?.data?.[0]?.id,
        multipleResponse: questionData?.attributes?.multiple_response,
        hintsAttachmentUploadId: questionData?.attributes?.hints
          ?.data?.[0]?.attributes?.attachments?.data?.[0]?.id,
        assessmentSectionId: sectionId,
        hintsExplanationUrl: questionData?.attributes?.hints?.data?.[0]?.attributes?.attachments?.data?.[0]?.attributes?.url,
      });

      if (questionData?.attributes?.hints.data.length != 0) {
        questionData?.attributes?.hints.data.map((data: any, index: number) => {
          this.state.hintAnswer.push({
            id: data.id,
            hintId: index + 1,
            hint: data.attributes.hint,
            src:
              data.attributes.attachments.data.length != 0
                ? data.attributes.attachments.data[0].attributes.url
                : "",
          });
        });
      }
      else {
        this.setState({
          hintAnswer: [
            {
              hintId: 0,
              hint: "",
            },
          ]
        })
      }

      questionData?.attributes?.answers?.data?.map(
        (data: any, index: number) => {
          const randomColor = this.generateColor();
          if (data?.attributes?.right_answer) {
            this.setState({
              answerSelected: true,
            });
          }
          this.state.questionOptionList.push({
            id: data?.id,
            color: randomColor,
            charNumber: index + 1,
            right_answer: data?.attributes?.right_answer,
            files: [],
            oldUploadId: data?.attributes?.attachments?.data[0]?.id,
            name: data?.attributes?.attachments?.data[0]?.attributes?.file_name,
            src: data?.attributes?.attachments?.data[0]?.attributes?.url,
            isImageAvailble:
              data?.attributes?.attachments?.data?.length === 0 ? false : true,
            answer: data?.attributes?.answer,
          });
        }
      );

      this.setState({
        charNumber: questionData?.attributes?.answers?.data?.length,
      });
    } else {
      const randomColor = this.generateColor();
      this.setState({
        hintAnswer: [
          {
            hintId: 0,
            hint: "",
          },
        ],
        charNumber: 4,
        questionOptionList: [
          {
            charNumber: 1,
            color: "#C0392B",
          },
          {
            charNumber: 2,
            color: "#229954",
          },
          {
            charNumber: 3,
            color: "#2471A3",
          },
          {
            charNumber: 4,
            color: "#1F51FF",
          },
        ],
        sectiondata: sectiondata,
        selectedCategoryQuestionData: questionData,
        assessmentSectionId: sectionId,
        remainSectionMarkPr: ((sectiondata?.section_marks - sectiondata?.remaining_marks) * 100) /
          sectiondata?.section_marks,
      });
    }
  }

  onMarksInputChange(event:any){
    const {marks} = this.state;
    const value: number = parseFloat(
      parseFloat(event.target.value)
        .toFixed(3)
        .slice(0, -1)
    );
    if (value > 0 || marks > 0) {
      this.setState({ marksError: false });
    } else {
      this.setState({
        marksError: true,
        marks: 0,
        marksErrorText: "Please enter marks.",
      });
    }
    if (
      value <
      this.state.sectiondata?.remaining_marks
    ) {
      this.setState({ marks: value, marksError: false });
    } else if (
      value ==
      this.state.sectiondata?.remaining_marks -
      1
    ) {
      this.setState({ marks: value, marksError: false });
    }
    if (value >= this.state.negativeMarks) {
      this.setState({
        marks: value,
        negmarksError: false,
      });
    } else {
      this.setState({
        marks: value,
        negmarksError: true,
      });
    }
    if (this.state.isEdit) {
      if (
        value >
        this.state.sectiondata?.remaining_marks +
        HISTORY.location.state?.questionData?.attributes?.marks
      ) {
        this.setState({
          marks: value,
          marksError: true,
          marksErrorText:
            "Marks not greater than section marks.",
        });
      } else {
        this.setState({
          marks: value,
        });
      }
    } else {
      if (
        value >
        this.state.sectiondata?.remaining_marks
      ) {
        this.setState({
          marks: value,
          marksError: true,
          marksErrorText:
            "Marks not greater than section marks.",
        });
      } else {
        this.setState({
          marks: value,
        });
      }
    }
  }

  onNegativeMarksInputChange(event:any){
    const value: number = parseFloat(
      parseFloat(event.target.value)
        .toFixed(3)
        .slice(0, -1)
    );
    if (value > this.state.marks) {
      this.setState({ negmarksError: true });
    } else {
      this.setState({ negmarksError: false });
    }
    this.setState({ negativeMarks: value });
  }

  onMarksKeyPress(event:any){
    if (invalidCharsForNumberType.includes(event.key)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onQuestionChange(value: any){
    this.setState({ question: value }, () => {
      if (
        this.state.question === null ||
        this.state.question === ""
      ) {
        this.setState({ questionError: true });
      } else {
        this.setState({ questionError: false });
      }
    })
  }

  onQuestionFileDrop(files: any, event: any){
    window.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
      },
      false
    );

    let totalSize: number = 0;
    const keys = Object.keys(files);
    keys?.map(async (key: any) => {
      const file = files[key];
      totalSize = totalSize + file.size;
    });
    if (files.length <= 1 && totalSize < 524288000) {
      this.handleFileOnChange(event, files);
    } else {
      if (files.length > 1) {
        this.setState({
          alertMessage: "You can upload maximum 1 files",
          alertModal: true,
        });
      } else {
        this.setState({
          alertMessage: "Maximum files size: 500MB",
          alertModal: true,
        });
      }
    }
  }

  onQuestionImageError(){
    this.setState({
      selectedImageBlobUrl: imageErrorSVG,
    });
  }

  onImagePlusClick(){
    this.setState({
      openSelectImageModal: true,
      optionImageSelection: false,
    })
  }

  onQuestionHiddenFileInputChange(e: any){
    let totalSize: number = 0;
    let isAllImageTypeFiles = false;
    var files = e.target.files;
    const keys = Object.keys(files);
    keys?.map(async (key: any) => {
      const file = files[key];
      totalSize = totalSize + file.size;
      let fileExtension = file.name.split(".").pop();
      if (
        fileExtension === "png" ||
        fileExtension === "jpg" ||
        fileExtension === "jpeg"
      ) {
        isAllImageTypeFiles = true;
      } else {
        isAllImageTypeFiles = false;
      }
    });
    if (isAllImageTypeFiles) {
      if (
        e.target.files.length <= 1 &&
        totalSize < 524288000
      ) {
        this.handleFileOnChange(e, null);
      } else {
        if (e.target.files.length > 1) {
          this.setState({
            alertMessage: "You can upload maximum 1 files",
            alertModal: true,
          });
        } else {
          this.setState({
            alertMessage: "Maximum files size: 500MB",
            alertModal: true,
          });
        }
      }
    } else {
      this.setState({
        alertMessage: "only image file can be upload",
        alertModal: true,
      });
    }
  }

  onOptionRadioGroupChange(event: React.ChangeEvent<HTMLInputElement>){
    let tempResponseList = this.state.questionOptionList;
    const index = this.state.questionOptionList.findIndex(
      (res: any) => res.charNumber === parseInt(event.target.value)
    );
    const findData = this.state.questionOptionList[index];
    tempResponseList[index] = {
      ...findData,
      right_answer: true,
    };
    this.setState({
      questionOptionList: tempResponseList,
      answerSelected: true,
    });
  }

  removeOption(data:any){
    const {questionOptionList} = this.state;
    if (data?.id) {
      const questionOptionListData = questionOptionList.map((res: any) => {
        if (Number(res.id) === Number(data.id)) {
          res._destroy = true
        }
        return res;
      }
      );
      let charNum = 1;
      questionOptionListData.map((data: any, index: number) => {
        data.charNumber = index + 1;
        if (index === questionOptionListData.length - 1) {
          charNum = index + 1
        }
      })
      this.setState({
        questionOptionList: questionOptionListData,
        charNumber: charNum,
      });
    } else {
      const questionOptionListData = questionOptionList.filter(
        (res: any) => res.charNumber != data.charNumber
      );
      let charNum = 1;
      questionOptionListData.map((data: any, index: number) => {
        data.charNumber = index + 1;
        if (index === questionOptionListData.length - 1) {
          charNum = index + 1
        }
      })
      this.setState({
        questionOptionList: questionOptionListData,
        charNumber: charNum,
      });
    }
  }

  removeOptionImage(index:number){
    let tempResponseList = this.state.questionOptionList;
    const findData = this.state
      .questionOptionList[index];
    tempResponseList[index] = {
      ...findData,
      src: "",
      name: "",
      isImageAvailble: false,
      upload_id: 0,
    };
    this.setState({
      questionOptionList: tempResponseList,
    });
  }

  answerOptionInputChange(event: any, data:any){
    let tempResponseList = this.state
      .questionOptionList;
    const index = this.state.questionOptionList.findIndex(
      (res: any) =>
        res.charNumber ===
        parseInt(data.charNumber)
    );
    const findData = this.state
      .questionOptionList[index];
    tempResponseList[index] = {
      ...findData,
      answer: event.target.value,
      files: [],
      right_answer: false,
    };
    this.setState({
      questionOptionList: tempResponseList,
    });
  }

  onMultipleResponseCheckboxChange(){
    let tempResponseList = this.state.questionOptionList;
    if (this.state.multipleResponse) {
      this.state.questionOptionList.forEach(
        (listData: any, index: number) => {
          const findData = this.state.questionOptionList[
            index
          ];
          tempResponseList[index] = {
            ...findData,
            right_answer: false,
          };
        }
      );
    }
    this.setState({
      multipleResponse: !this.state.multipleResponse,
      questionOptionList: tempResponseList,
    });
  }

  onOptionImageClick(data:any){
    this.setState({
      previewImageModal: true,
      previewImage: data.src,
    });
  }

  addNewOption(){
    const {charNumber, questionOptionList} = this.state;
    const randomColor = this.generateColor();
    let title: string = "";
    title = String.fromCharCode(65 + charNumber) + title;

    questionOptionList.push({
      color: randomColor,
      charNumber: charNumber + 1,
      right_answer: false,
      files: [],
      answer: "",
    });
    this.setState({
      questionOptionList: questionOptionList,
      charNumber: charNumber + 1,
    });
  }

  onOptionFileInputChange(e: any){
    let totalSize: number = 0;
    let isAllImageTypeFiles = false;
    var files = e.target.files;
    const keys = Object.keys(files);
    keys?.map(async (key: any) => {
      const file = files[key];
      totalSize = totalSize + file.size;
      let fileExtension = file.name.split(".").pop();
      if (
        fileExtension === "png" ||
        fileExtension === "jpg" ||
        fileExtension === "jpeg"
      ) {
        isAllImageTypeFiles = true;
      } else {
        isAllImageTypeFiles = false;
      }
    });
    if (isAllImageTypeFiles) {
      if (
        e.target.files.length <= 1 &&
        totalSize < 524288000
      ) {
        this.handleOptionFileChange(
          e,
          null,
          this.state.selectedOptionNumber
        );
      } else {
        if (e.target.files.length > 1) {
          this.setState({
            alertMessage:
              "You can upload maximum 1 files",
            alertModal: true,
          });
        } else {
          this.setState({
            alertMessage:
              "Maximum files size: 500MB",
            alertModal: true,
          });
        }
      }
    } else {
      this.setState({
        alertMessage: "only image file can be upload",
        alertModal: true,
      });
    }
  }

  onOptionRadioChange(data:any){
    let tempResponseList = this.state.questionOptionList;
    this.state.questionOptionList.forEach(
      (listData: any, index: number) => {
        if (
          listData.charNumber ===
          data.charNumber
        ) {
          const findData = this.state
            .questionOptionList[index];
          tempResponseList[index] = {
            ...findData,
            right_answer: true,
          };
          this.setState({
            answerSelected: true,
          });
        } else {
          const findData = this.state
            .questionOptionList[index];
          tempResponseList[index] = {
            ...findData,
            right_answer: false,
          };
        }
      }
    );
    this.setState({
      questionOptionList: tempResponseList,
    });
  }

  onOptionMultiRadioChange(data:any){
    let tempResponseList = this.state.questionOptionList;
    this.state.questionOptionList.forEach(
      (listData: any, index: number) => {
        if (
          listData.charNumber ===
          data.charNumber
        ) {
          const findData = this.state
            .questionOptionList[index];
          if (findData.right_answer) {
            tempResponseList[index] = {
              ...findData,
              right_answer: false,
            };
          } else {
            tempResponseList[index] = {
              ...findData,
              right_answer: true,
            };
          }
        }
      }
    );
    this.setState({
      questionOptionList: tempResponseList,
    });
  }

  onOptionUploadImageClick(data:any, refIndex:any){
    this.setState({
      openSelectImageModal: true,
      selectedOptionNumber: data.charNumber,
      optionImageSelection: true,
      currentOptionRefIndex: refIndex,
    });
  }


  // QuestionSolution START
  hintAnswerOnchange(value: any, id: any){
    let tempData = this.state.hintAnswer;
    const index = this.state.hintAnswer.findIndex(
      (res: any) => res.hintId === id
    );
    tempData[index].hint = value.trim();
    this.setState({
      hintAnswer: tempData,
    });
  }
  getQuestionSolutionPreSignedValue(
    preSignedId: string,
    typeofAttachment: string,
    blobUrl: string,
  ){
    if (typeofAttachment === "hints") {
      let tempData = this.state.hintAnswer;
      tempData[0].files = [preSignedId];
      tempData[0].src = blobUrl;
      this.setState({
        hintAnswer: tempData,
        hintId: preSignedId,

      });
    } else {
      this.setState({ solutionImageSignedId: preSignedId });
    }
  }
  explainationOnchange(value: any){
    this.setState({
      explaination: value,
    });
  }
  removeSolutionImage(attachmentType: string){
    if (attachmentType === "hints") {
      this.setState({
        hintsExplanationUrl: "",
        hintId: null,
      });
    } else {
      this.setState({
        attachmentExplanationUrl: "",
        solutionImageSignedId: null,
      });
    }
  }
  // QuestionSolution END

  // QuestionMetaData START
  onChangeSubject(e: any){
    this.setState({ selectedSubject: e.target.value });
  }
  onChangeChapter(e: any){
    this.setState(
      {
        selectedChapter: e.target.value,
        metaDataErrors: {
          ...this.state.metaDataErrors,
          chapter: false,
        },
      },
      () => this.get_unit_list(this.state.selectedChapter, true)
    );
  }
  onChangeUnit(e: any){
    this.setState({
      selectedUnit: e.target.value,
      metaDataErrors: { ...this.state.metaDataErrors, unit: false },
    });
  }
  onChangeSkill(e: any){
    this.setState({
      selectedSkill: e.target.value,
      metaDataErrors: { ...this.state.metaDataErrors, skill: false },
    });
  }
  onChangeProficiency(e: any){
    this.setState({
      selectedProficiency: e.target.value,
      metaDataErrors: {
        ...this.state.metaDataErrors,
        proficiency: false,
      },
    });
  }
  onChangeDifficultyLevel(e: any){
    this.setState({
      selectedDifficultyLevel: e.target.value,
      metaDataErrors: {
        ...this.state.metaDataErrors,
        difficultyLevel: false,
      },
    });
  }
  onChangeBloomTaxonomy(e: any){
    this.setState({
      selectedBloomTaxonomy: e.target.value,
      metaDataErrors: {
        ...this.state.metaDataErrors,
        bloomTaxonomy: false,
      },
    });
  }
  // QuestionMetaData END

  onPreviewBtnClick(){
    this.setState({ prevQuestionModal: true })
  }

  onClickCancelButton(){
    HISTORY.push("/TeacherAssessmentCreateSection")
  }

  closeAlertModal(){
    this.setState({ alertModal: false });
  }

  closeSelectImageModal(){
    this.setState({ openSelectImageModal: false });
  }

  onClickChooseFromGallery(){
    this.setState({
      openChooseFromGalleryModal: true,
      openSelectImageModal: false,
    });
  }

  onClickUploadFromLocal(){
    if(this.state.optionImageSelection){
      this.showOpenFileDlg()
    }else{
      this.showFirstOpenFileDlg()
    }
  }

  closeQuestionPreviewModal(){
    this.setState({ prevQuestionModal: false })
  }

  closeChooseFromGalleryModal(){
    this.setState({ openChooseFromGalleryModal: false })
  }

  onClickImageChooseFromGalleryModal(id: any, url: any){
    if (this.state.optionImageSelection) {
      let TempResponseData = this.state.questionOptionList;
      const findIndex = this.state.questionOptionList.findIndex(
        (res: any) =>
          res.charNumber === this.state.selectedOptionNumber
      );
      const findData = this.state.questionOptionList.find(
        (res: any) =>
          res.charNumber === this.state.selectedOptionNumber
      );
      TempResponseData[findIndex] = {
        ...findData,
        src: url,
        upload_id: id,
        name: "Gallery Image",
        isImageAvailble: true,
      };
      this.setState({
        selectedGalleryImageId: id,
        questionOptionList: TempResponseData,
      });
    } else {
      this.setState({
        mainGalleryImageId: id,
        selectedGalleryImageId: id,
        selectedGalleryImageUrl: url,
      });
    }
  }

  onTabChangeClearId(){
    this.setState({ selectedGalleryImageId: 0 })
  }

  closeImagePreivewModal(){
    this.setState({ previewImageModal: false })
  }


  generateColor = () => {
    // Dark colors
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
    // Dark colors END
  };

  getExplanation = () => {
    if (HISTORY?.location?.state) {
      const { questionData } = HISTORY.location.state;
      const index = questionData?.attributes?.solutions?.data.length - 1;
      if (questionData?.attributes?.solutions?.data[index]?.attributes?.explaination)
        return questionData?.attributes?.solutions?.data[index]?.attributes?.explaination;

      return "";
    } else {
      HISTORY.push("/TeacherAssessmentCreateSection");
    }
  };

  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
  };

  plusMarksClick = () => {
    if (this.state.marks + 1 > 0) {
      this.setState({ marksError: false });
    } else {
      this.setState({
        marksError: true,
        marksErrorText: "Please enter marks.",
      });
    }

    if (
      this.state.marks < this.state.sectiondata?.remaining_marks
    ) {
      this.setState({ marks: this.state.marks + 1, marksError: false });
    } else if (
      this.state.marks ===
      this.state.sectiondata?.remaining_marks - 1
    ) {
      this.setState({ marks: this.state.marks + 1, marksError: false });
    }
    if (
      this.state.marks + 1 > this.state.negativeMarks ||
      this.state.marks + 1 == this.state.negativeMarks
    ) {
      this.setState({ marks: this.state.marks + 1, negmarksError: false });
    }
    if (this.state.isEdit) {
      if (
        this.state.marks + 1 >
        this.state.sectiondata?.remaining_marks +
        HISTORY.location.state?.questionData?.attributes?.marks
      ) {
        this.setState({
          marks: this.state.marks + 1,
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
      }
    } else {
      if (
        this.state.marks + 1 >
        this.state.sectiondata?.remaining_marks
      ) {
        this.setState({
          marks: this.state.marks + 1,
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
      }
    }

    this.setState({
      marks: this.state.marks + 1,
    });
  };

  minusMarksClick = () => {
    if (this.state.negativeMarks + 1 > this.state.marks) {
      this.setState({ negmarksError: true });
    }
    this.setState({ negativeMarks: this.state.negativeMarks + 1 });
  };

  onChangeKeywords = (e: any) => {
    this.setState({ keyword: e.target.value });
  };

  onBlurKeywords = (e: any) => {
    this.setState({ keyword: e.target.value });
    if (e.target.value !== "") {
      let { keywordsList } = this.state;
      keywordsList = [...keywordsList, e.target.value];
      this.setState({ keywordsList: keywordsList, keyword: "" });
      if (keywordsList.length > 0) {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: false },
        });
      } else {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: true },
        });
      }
    }
  };

  showOpenFileDlg = () => {
    this.multipleInputRef[this.state.currentOptionRefIndex]?.current?.click();
  };
  showFirstOpenFileDlg = () => {
    this.inputFirstOpenFileRef?.current?.click();
  };
  onKeyDownKeywords = (e: any) => {
    this.setState({ keyword: e.target.value });
    if (e?.key === "Enter" && e.target.value !== "") {
      let { keywordsList } = this.state;
      keywordsList = [...keywordsList, e.target.value];
      this.setState({ keywordsList: keywordsList, keyword: "" });
      if (keywordsList.length > 0) {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: false },
        });
      } else {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: true },
        });
      }
    }
  };

  handleDeleteKeyword = (item: any) => {
    let { keywordsList } = this.state;
    keywordsList = [...keywordsList.filter((x: any) => x !== item)];
    this.setState({ keywordsList: keywordsList, keyword: "" });
    if (keywordsList.length > 0) {
      this.setState({
        metaDataErrors: { ...this.state.metaDataErrors, keywords: false },
      });
    } else {
      this.setState({
        metaDataErrors: { ...this.state.metaDataErrors, keywords: true },
      });
    }
  };

  removeSelectedImage = () => {
    this.setState({
      selectedImageBlobUrl: "",
      selectedImageFile: null,
      selectedImageSignedId: null,
      selectedGalleryImageId: 0,
      selectedGalleryImageUrl: "",
      mainGalleryImageId: 0,
      mainImageRemoved:true,
    });
  };

  onClickGalleryImageChoose = () => {
    this.setState({
      openChooseFromGalleryModal: false,
      selectedImageBlobUrl: this.state.selectedGalleryImageUrl,
      selectedImageFile: null,
      selectedImageSignedId: null,
    });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getChapterListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ chapterListData: responseJson?.data }, () =>
              this.get_unit_list(this.state.selectedChapter)
            );
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getTopicListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            // 
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiSaveQuesionId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            if (this.state.isEdit) {
              toast.success("Question updated successfully.");
            } else {
              toast.success("Question created successfully.");
            }
            // Update question to section list
            this.updateSectionQuestion(responseJson.data)
            // Update question to section list END
            this.setState({ showLoader: false });
            HISTORY.push("/TeacherAssessmentCreateSection");
          } else {
            this.parseApiErrorResponse(responseJson);
            this.setState({ showLoader: false });
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiPhotoPreSignedID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const findData = this.state.questionOptionList.find(
              (item: any) => item.messageId === this.apiPhotoPreSignedID
            );
            const imageData = findData
              ? findData?.file
              : this.state.selectedImageFile;
            const msg: Message = new Message(
              getName(MessageEnum.UploadMediaMessage)
            );
            const uploadFileData: any = {
              responseJson: responseJson,
              imageData,
              messageId: msg.messageId,
            };
            msg.addData(
              getName(MessageEnum.UploadMediaDataMessage),
              uploadFileData
            );
            this.apiUploadID = msg.messageId;
            runEngine.sendMessage(msg.id, msg);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiUploadID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const keyValue = JSON.parse(
              convert.xml2json(responseJson, {
                spaces: 1,
                compact: true,
                ignoreComment: true,
                alwaysChildren: true,
                ignoreDeclaration: true,
              })
            );
            if (this.state.optionImageSelection) {
              let tempResponseList = this.state.questionOptionList;
              const index = this.state.questionOptionList.findIndex(
                (res: any) => res.charNumber === this.state.selectedOptionNumber
              );
              const data = tempResponseList[index];
              tempResponseList[index] = {
                ...data,
                files: [keyValue.PostResponse.Key._text],
                answer: "",
              };
              this.setState({ questionOptionList: tempResponseList });
            } else {
              this.setState({
                selectedImageSignedId: keyValue.PostResponse.Key._text,
              });
            }
            this.setState({
              showLoaderForImageUpload: false,
              selectedGalleryImageId: 0,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  updateSectionQuestion = (questionData: any) => {
    const { sectionId } = HISTORY.location.state;
    const assessmentData = JSON.parse(localStorage.getItem("assessmentData") || "{}");

    const question = {
      id: questionData.id,
      title: questionData?.attributes?.title,
      type: questionData?.attributes?.type?.name,
      marks: questionData?.attributes?.marks,
      negative_marks: questionData?.attributes?.negative_marks,
    };
    assessmentData.assessment_sections_attributes.map((sectionData: any, index: number) => {
      if (sectionData.id === sectionId) {
        // setting question data
        if (sectionData.question_data?.length > 0) {
          const qIndex = sectionData.question_data.findIndex((data: any) => Number(data.id) === Number(question.id));
          if (qIndex != -1) {
            sectionData.question_data[qIndex] = question;
          } else {
            sectionData.question_data.push(question);
          }
        } else {
          sectionData.question_data = [question];
        }
        // setting question data END

        // setting section marks
        const totalQuestionMarks = sectionData.question_data.reduce((sum: any, current: any) => (sum + current.marks), 0);
        sectionData.remaining_marks = sectionData.section_marks - totalQuestionMarks;
        // setting section marks END
        return;
      }
    })
    localStorage.setItem("assessmentData", JSON.stringify(assessmentData))
  }

  handleFileOnChange = async (
    event: React.ChangeEvent<any>,
    filesFromDragAndDrop: any
  ) => {
    let files: any;
    if (filesFromDragAndDrop !== null) {
      files = filesFromDragAndDrop;
    } else {
      files = event.target.files;
    }

    const file = files[0];
    var blobURL = URL.createObjectURL(file);
    this.setState({
      openSelectImageModal: false,
      selectedImageBlobUrl: blobURL,
      selectedImageFile: file,
      showLoaderForImageUpload: true,
    });
    await computeChecksumMd5(file).then((md5: any) => {
      const fileDetails = {
        fileName: file.name,
        fileSize: file.size,
        checksum: md5,
        content_type: file.type,
      };
      this.getPhotoPreSignedId(fileDetails);
    });
  };

  handleOptionFileChange = async (
    event: React.ChangeEvent<any>,
    filesFromDragAndDrop: any,
    dataID: any
  ) => {
    let files: any;
    if (filesFromDragAndDrop !== null) {
      files = filesFromDragAndDrop;
    } else {
      files = event.target.files;
    }

    const file = files[0];
    var blobURL = URL.createObjectURL(file);
    let tempResponseList = this.state.questionOptionList;
    const index = this.state.questionOptionList.findIndex(
      (res: any) => res.charNumber === dataID
    );
    const findData = this.state.questionOptionList[index];
    tempResponseList[index] = {
      ...findData,
      src: blobURL,
      name: file.name,
      isImageAvailble: true,
    };
    if (this.state.optionImageSelection) {
      this.setState({
        openSelectImageModal: false,
        questionOptionList: tempResponseList,
        selectedImageFile: file,
      });
    } else {
      this.setState({
        openSelectImageModal: false,
        selectedImageBlobUrl: blobURL,
        selectedImageFile: file,
        showLoaderForImageUpload: true,
      });
    }

    await computeChecksumMd5(file).then((md5: any) => {
      const fileDetails = {
        fileName: file.name,
        fileSize: file.size,
        checksum: md5,
        content_type: file.type,
      };
      this.getPhotoPreSignedId(fileDetails);
    });
  };

  getPhotoPreSignedId = (photoDetail: any) => {
    const header = {
      token: localStorage.getItem("token"),
    };

    let formData = new FormData();
    formData.append("content_type", photoDetail.content_type);

    const httpBody = {
      filename: photoDetail.fileName,
      byte_size: photoDetail.fileSize,
      checksum: photoDetail.checksum,
      content_type: photoDetail.content_type,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );

    this.apiPhotoPreSignedID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  savePhotoUsingURL = async (preSignedResponse: any) => {
    const uploadURL = preSignedResponse.url.replace(/\/+$/, "");
    const urlFields = preSignedResponse.url_fields;
    if (uploadURL != null && uploadURL !== undefined) {
      const fileKey = urlFields.key.replace(
        "${filename}",
        this.state.selectedImageFile.name
      );

      const formData = new FormData();
      formData.append("key", fileKey);
      formData.append("success_action_status", urlFields.success_action_status);
      formData.append("Content-Type", urlFields["Content-Type"]);
      formData.append("Content-Disposition", urlFields["Content-Disposition"]);
      formData.append("policy", urlFields.policy);
      formData.append("x-amz-credential", urlFields["x-amz-credential"]);
      formData.append("x-amz-algorithm", urlFields["x-amz-algorithm"]);
      formData.append("x-amz-date", urlFields["x-amz-date"]);
      formData.append("x-amz-signature", urlFields["x-amz-signature"]);
      formData.append("file", this.state.selectedImageFile);
      try {
        const s3Response = await axios({
          method: "post",
          url: uploadURL,
          data: formData,
        });
        if (s3Response.status === 201) {
          const keyValue = JSON.parse(
            convert.xml2json(s3Response.data, {
              spaces: 1,
              compact: true,
              ignoreComment: true,
              alwaysChildren: true,
              ignoreDeclaration: true,
            })
          );
          if (this.state.optionImageSelection) {
            let tempResponseList = this.state.questionOptionList;
            const index = this.state.questionOptionList.findIndex(
              (res: any) => res.charNumber === this.state.selectedOptionNumber
            );
            const data = tempResponseList[index];
            tempResponseList[index] = {
              ...data,
              files: [keyValue.PostResponse.Key._text],
              answer: "",
            };
            this.setState({ questionOptionList: tempResponseList });
          } else {
            this.setState({
              selectedImageSignedId: keyValue.PostResponse.Key._text,
            });
          }
          this.setState({
            showLoaderForImageUpload: false,
            selectedGalleryImageId: 0,
          });
        }
      } catch (e) {
        toast.error("Something went wrong.");
      }
    }
  };

  setRef = (index: number) => {
    if (this.multipleInputRef.length > index) {
      this.multipleInputRef[index] = createRef();
    } else {
      this.multipleInputRef.push(createRef())
    }
  }

  get_chapter_list = () => {
    const assessment_data = localStorage.getItem("assessmentData");
    const assessmentData = JSON.parse(assessment_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getChapterListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chapterEndPoint + `?subject_id=${assessmentData?.subject_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_topic_list = (id?: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTopicListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topicEndPoint + `?chapter_id=${Number(id)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_unit_list = (id?: any, resetUnit?: boolean) => {
    const { chapterListData } = this.state;
    const chapterUnits = chapterListData?.find(
      (chapter: any) => Number(chapter.id) === Number(id)
    );
    this.setState({ unitListData: chapterUnits?.attributes?.units?.data });

    if (resetUnit) {
      this.setState({ selectedUnit: "" });
    }
  };

  getEmptyState = () => ({
    marksError: false,
    questionError: false,
    metaDataErrors: {
      chapter: false,
      unit: false,
      skill: false,
      proficiency: false,
      keywords: false,
      difficultyLevel: false,
      bloomTaxonomy: false,
    },
  });

  validFormData = () => {
    let isValid = true;

    const {
      question,
      marks,
      selectedChapter,
      selectedUnit,
      selectedSkill,
      selectedProficiency,
      selectedDifficultyLevel,
      keywordsList,
      selectedBloomTaxonomy,
    } = this.state;

    const errors = this.getEmptyState().metaDataErrors;
    let questionErr = this.getEmptyState().questionError;
    let marksErr = this.getEmptyState().marksError;

    if (marks === null || Number(marks) === 0) {
      this.setState({
        marksError: true,
        marksErrorText: "Please enter marks.",
      });
      isValid = false;
    }
    if (this.state.isEdit) {
      if (
        this.state.marks >
        this.state.sectiondata?.remaining_marks +
        HISTORY.location.state?.questionData?.attributes?.marks
      ) {
        this.setState({
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
        isValid = false;
      }
    } else {
      if (
        this.state.marks > this.state.sectiondata?.remaining_marks
      ) {
        this.setState({
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
        isValid = false;
      }
    }

    if (this.state.negativeMarks > this.state.marks) {
      this.setState({ negmarksError: true });
      isValid = false;
    }
    if (question === null || question === "") {
      questionErr = true;
      isValid = false;
    }

    if (selectedChapter === null || selectedChapter === "") {
      errors.chapter = true;
      isValid = false;
    }

    if (selectedUnit === null || selectedUnit === "") {
      errors.unit = true;
      isValid = false;
    }

    if (selectedSkill === null || selectedSkill === "") {
      errors.skill = true;
      isValid = false;
    }

    if (selectedProficiency === null || selectedProficiency === "") {
      errors.proficiency = true;
      isValid = false;
    }

    if (selectedDifficultyLevel === null || selectedDifficultyLevel === "") {
      errors.difficultyLevel = true;
      isValid = false;
    }

    if (keywordsList.length === 0) {
      errors.keywords = true;
      isValid = false;
    }

    if (selectedBloomTaxonomy === null || selectedBloomTaxonomy === "") {
      errors.bloomTaxonomy = true;
      isValid = false;
    }

    this.setState({ metaDataErrors: errors, questionError: questionErr }); // removed marksError: marksErr

    return isValid;
  };

  saveQuestion = () => {
    if (this.validFormData()) {
      this.saveQuestionApi();
    }
  };

  saveQuestionApi = () => {
    let final_hint_answer: any = [];
    let final_option_list: any = [];

    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id,
    };

    const {
      isEdit,
      marks,
      negativeMarks,
      multipleResponse,
      hintId,
      mainGalleryImageId,
      explanationId,
      questionId,
      wordLimit,
      questionOptionList,
      hintAnswer,
      question,
      selectedChapter,
      selectedUnit,
      selectedSkill,
      selectedProficiency,
      selectedDifficultyLevel,
      keywordsList,
      selectedImageSignedId,
      explaination,
      solutionImageSignedId,
      selectedCategoryQuestionData,
      assessmentSectionId,
      selectedGalleryImageId,
      selectedBloomTaxonomy,
      explainationAttachmentUploadId,
    } = this.state;
    hintAnswer?.map((data: any) => {
      final_hint_answer.push({
        id: data.id,
        files: data.files ? data.files : [],
        hint: data.hint,
      });
    });
    questionOptionList.map((data: any) => {
      if (data.answer != undefined || data.files != undefined || data.upload_id != undefined) {
        if (this.state.isEdit) {
          if (data.oldUploadId && data.files?.length > 0) {
            final_option_list.push({
              id: data.id,
              answer: data.answer,
              right_answer: data.right_answer,
              files: data.files,
              old_upload_id: data.oldUploadId,
              _destroy: data?._destroy,
            });
          } else if (data.files?.length > 0) {
            final_option_list.push({
              id: data.id,
              answer: data.answer,
              right_answer: data.right_answer,
              files: data.files,
              _destroy: data?._destroy,
            });
          } else if (data.upload_id) {
            final_option_list.push({
              id: data.id,
              answer: data.answer,
              right_answer: data.right_answer,
              upload_id: data.upload_id,
              old_upload_id: data.oldUploadId,
              _destroy: data?._destroy,
            });
          } else if (data.oldUploadId && data.answer) {
            final_option_list.push({
              id: data.id,
              answer: data.answer,
              right_answer: data.right_answer,
              old_upload_id: data.oldUploadId,
              _destroy: data?._destroy,
            });
          } else {
            final_option_list.push({
              id: data.id,
              answer: data.answer,
              right_answer: data.right_answer,
              _destroy: data?._destroy,
            });
          }
        } else {
          final_option_list.push({
            id: data.id,
            answer: data.answer,
            right_answer: data.right_answer,
            files: data?.files,
            upload_id: data?.upload_id
          });
        }
      }
    });
    const filterQuestion = final_option_list.filter(
      (res: any) => res.answer != "" || res?.files?.length != 0 || res?.upload_id != undefined || res?.id != undefined
    );
    if (filterQuestion.length === 0) {
      this.setState({
        alertMessage: "Answer can't blank",
        alertModal: true,
      });
      return false;
    }
    let checkBool = false;
    filterQuestion.map((data: any) => {
      if (data.right_answer) {
        checkBool = true;
        this.setState({ answerSelected: false });
      }
    });
    if (!this.state.answerSelected || !checkBool) {
      this.setState({
        alertMessage: "Please Select Answer",
        alertModal: true,
      });
      return false;
    }
    const assessmentData = JSON.parse(localStorage.getItem("assessmentData") || "{}");
    const httpBody: any = {
      subject_id: assessmentData.subject_id,
      grade_id: assessmentData.grade_id,
      marks: marks,
      word_limit: Number(wordLimit),
      title: question,
      question_sub_category_id: selectedCategoryQuestionData.id,
      negative_marks: negativeMarks,
      is_negative_marking: negativeMarks > 0 ? true : false,
      assessment_section_id: assessmentSectionId,
      multiple_response: multipleResponse,
      solutions_attributes: [
        {
          id: explanationId,
          explaination: explaination,
        },
      ],
      hints_attributes:  final_hint_answer,
      answers_attributes: final_option_list,
      metadata_attributes: {
        chapter_id: selectedChapter,
        unit_id: selectedUnit,
        skill: selectedSkill,
        proficiency: selectedProficiency,
        difficulty_level: selectedDifficultyLevel,
        bloom_taxonomy: selectedBloomTaxonomy,
        keywords: keywordsList,
      },
    };
    if (
      this.state.isEdit &&
      HISTORY.location.state?.questionData?.attributes?.solutions?.data?.length != 0
    ) {
      // remove attachment when no new image selected
      if (
        this.state.explainationAttachmentUploadId &&
        !solutionImageSignedId &&
        !this.state.attachmentExplanationUrl
      ) {
        this.removeExplainationAttachment(
          this.state.explainationAttachmentUploadId
        );
      } else {
        // insert new image if initialy no image uploaded
        if (
          (this.state.explainationAttachmentUploadId === undefined ||
            this.state.explainationAttachmentUploadId === "") &&
          solutionImageSignedId !== null
        ) {
          httpBody.solutions_attributes[0].files =
            solutionImageSignedId === "" || solutionImageSignedId === null
              ? []
              : [solutionImageSignedId];
        }
        // Update Image
        else if (solutionImageSignedId && explainationAttachmentUploadId) {
          httpBody.solutions_attributes[0].files = [solutionImageSignedId];
          httpBody.solutions_attributes[0].old_upload_id =
            HISTORY.location.state?.questionData?.attributes?.solutions?.data?.[0]?.attributes?.attachments?.data[0]?.id;
        }
      }
    } else {
      httpBody.solutions_attributes[0].files =
        solutionImageSignedId === "" || solutionImageSignedId === null
          ? []
          : [solutionImageSignedId];
    }
    if (
      this.state.isEdit &&
      HISTORY.location.state?.questionData?.attributes?.hints
        ?.data?.length != 0
    ) {
      // remove attachment when no new image selected
      if (
        this.state.hintsAttachmentUploadId &&
        hintId === null &&
        this.state.hintsExplanationUrl === ""
      ) {
        this.removeExplainationAttachment(this.state.hintsAttachmentUploadId);
      } else {
        // insert new image if initialy no image uploaded
        if (
          (this.state.hintsAttachmentUploadId === undefined ||
            this.state.hintsAttachmentUploadId === "") &&
          hintId !== null
        ) {
          httpBody.hints_attributes[0].files =
            hintId === "" || hintId === null ? [] : [hintId];
        }
        // Update Image
        else if (hintId && this.state.hintsAttachmentUploadId) {
          httpBody.hints_attributes[0].files = [hintId];
          httpBody.hints_attributes[0].old_upload_id = this.state.hintsAttachmentUploadId
        }
      }
    } else {
      httpBody.hints_attributes[0].files =
        hintId === "" || hintId === null ? [] : [hintId];
    }
    if (mainGalleryImageId) {
      if (HISTORY.location.state?.questionData?.attributes?.attachments?.data?.length > 0) {
        const ImageIndex = HISTORY.location.state?.questionData?.attributes?.attachments?.data?.length - 1;
        httpBody.old_upload_id = HISTORY.location.state?.questionData?.attributes?.attachments?.data?.[ImageIndex]?.id;
      }
      httpBody.upload_id = mainGalleryImageId;
    } else {
      const ImageIndex =
        HISTORY.location.state?.questionData?.attributes?.attachments?.data
          ?.length - 1;
      if(ImageIndex >=0 && this.state.mainImageRemoved){
        httpBody.old_upload_id =
        HISTORY.location.state?.questionData?.attributes?.attachments?.data?.[
          ImageIndex
        ]?.id;
      }
      if (
        this.state.isEdit && selectedImageSignedId &&
        HISTORY.location.state?.questionData?.attributes?.attachments?.data
          ?.length != 0
      ) {
        httpBody.files = [selectedImageSignedId];
        httpBody.old_upload_id =
          HISTORY.location.state?.questionData?.attributes?.attachments?.data?.[
            ImageIndex
          ]?.id;
      } else if (selectedImageSignedId) {
        httpBody.files =
          selectedImageSignedId === "" || selectedImageSignedId === null
            ? []
            : [selectedImageSignedId];
      }
    }
    if((final_hint_answer[0].files && final_hint_answer[0].files == 0) && final_hint_answer[0].hint == ''){
      delete httpBody.hints_attributes
    }
    this.setState({ showLoader: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSaveQuesionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      isEdit
        ? configJSON.saveQuestionEndPoint + `/${questionId}`
        : configJSON.saveQuestionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      isEdit ? configJSON.examplePUTAPiMethod : configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeExplainationAttachment = (id: any) => {
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteSolutionAttachment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.galleryImagesEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
