// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import StudentAssessmentResultController, {
  Props,
} from "./StudentAssessmentResultController.web";
import { imgBackArrow } from "../assets";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { ic_userIcon } from "../../../ClassesAndSections/src/assets";
import { HISTORY } from "../../../../components/src/comman";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import './StudentAssessmentResult.web.css';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class StudentAssessmentResult extends StudentAssessmentResultController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { assessmentResult } = this.state;
    return (
      <>
        {this.state.showLoader && (
          <Spinner spinnerModal={this.state.showLoader} />
        )}
        <Box className='studentAssessmentResult_backArrowDiv'>
          <Typography
            className="studentAssessmentResult_backArrowTxt"
            onClick={this.handleBack}
          >
            <img
              src={imgBackArrow}
              className='studentAssessmentResult_backArrowImg'
            />
            Back
          </Typography>
          <div
            className="studentAssessmentResult_viewVerticalBox"
          >
            <div className="studentAssessmentResult_subViewVerticalBox">
              <TableContainer
                className="studentAssessmentResult_tableContainer"
              >
                <Table>
                  <TableHead className="studentAssessmentResult_tableHead">
                    <TableRow>
                      <TableCell
                        className="studentAssessmentResult_tableCell"
                      >
                        Assessment Name
                      </TableCell>
                      <TableCell
                        className="studentAssessmentResult_tableCell studentAssessmentResult_tabCellCenter"
                      >
                        Attempt No.
                      </TableCell>
                      <TableCell
                        className="studentAssessmentResult_tableCell studentAssessmentResult_tabCellCenter"
                      >
                        Submited
                      </TableCell>
                      <TableCell
                        className="studentAssessmentResult_tableCell studentAssessmentResult_tabCellCenter"
                      >
                        Score
                      </TableCell>
                      <TableCell
                        className="studentAssessmentResult_tableCell studentAssessmentResult_tabCellCenter"
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className="studentAssessmentResult_tableCellRight" >
                        Established fact that a reader distracted.
                      </TableCell>
                      <TableCell className="studentAssessmentResult_tableCellRight studentAssessmentResult_tableCellRightCenter">
                        01
                      </TableCell>
                      <TableCell className="studentAssessmentResult_tableCellRight studentAssessmentResult_tableCellRightCenter">
                        25 July 2021
                      </TableCell>
                      <TableCell className="studentAssessmentResult_tableCellRight studentAssessmentResult_tableCellRightCenter">
                        48 (out of 50)
                      </TableCell>
                      <TableCell className="studentAssessmentResult_tableCellRight studentAssessmentResult_tableCellRightCenter">
                        Submitted
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <Grid container className="studentAssessmentResult_mainGridContainer">
              <Typography className="studentAssessmentResult_mainGridContainerTxt">
                Test Information
              </Typography>

              <div className="studentAssessmentResult_queryDiv">
                <Typography
                  className="studentAssessmentResult_queryDivTxt"
                  onClick={() => {
                    HISTORY.push("/StudentAssessmentQueryResolution");
                  }}
                >
                  Raise Query
                </Typography>
                <Typography
                  className="studentAssessmentResult_queryDivTxt"
                  onClick={() => {
                    HISTORY.push("/StudentViewAnswerSheet");
                  }}
                >
                  View Answer Sheet
                </Typography>
              </div>
            </Grid>

            <Grid container spacing={4} className='studentAssessmentResult_assignedDiv'>
              <Grid item xs={12} sm={4} md={3} className='studentAssessmentResult_subAssignedDiv'>
                <Typography className='studentAssessmentResult_assignedDivTxt'>
                  Assigned By
                </Typography>
                <Typography className='studentAssessmentResult_userIconDiv'>
                  <img
                    src={ic_userIcon}
                    className='studentAssessmentResult_userIconImg' />
                  Andrew Smith
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={3} className='studentAssessmentResult_subAssignedDiv'>
                <Typography className='studentAssessmentResult_assignedDivTxt'>
                  Assessment Type
                </Typography>
                <Typography className='studentAssessmentResult_assignedSubTxt'>
                  Homework
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={3} className='studentAssessmentResult_subAssignedDiv'>
                <Typography className='studentAssessmentResult_assignedDivTxt'>
                  Number of questions
                </Typography>
                <Typography className='studentAssessmentResult_assignedSubTxt'>
                  50
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={3} className='studentAssessmentResult_subAssignedDiv'>
                <Typography className='studentAssessmentResult_assignedDivTxt' >
                  Time limit
                </Typography>
                <Typography className='studentAssessmentResult_assignedSubTxt'>
                  2 Hour
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Box>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default HOCAuth(StudentAssessmentResult as React.ComponentType<any>, 'StudentAccount');
// Customizable Area End
