import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  FlatList,
  ActivityIndicator,
} from "react-native";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Search,
  Replay,
  NotificationsNone,
  HeadsetMicOutlined,
  Title,
  TitleOutlined,
  KeyboardArrowLeft,
  EditOutlined,
  MoreVertOutlined,
  KeyboardArrowDown,
} from "@material-ui/icons";
import StudentDashboardController, {
  Props,
} from "./StudentDashboardController.web";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import {
  impProgressTrophy,
  impBiologyIcon,
  impChemistryIcon,
  impMathIcon,
  impEnglishIcon,
  edit_note_img,
  impNewGoalIcon,
  impMyGoalIcon,
  imgProfilePicOutline,
  impOrangeMeter,
  imgRedPin,
  imgZoomIcon,
  impBlackBoardIcon,
  imgZoomDisabledIcon,
  impBlackBoardDisabledIcon,
  logo,
  menu,
  ic_computer,
  ic_calendar,
  ic_messages,
  ic_book,
  ic_suitcase,
  ic_pie_chart,
  ic_support,
  ic_clock,
  ic_graduate,
  knowledge,
  note,
  book,
  birthday_bg,
  thoughts_bg,
  student_default_profile_pic,
  country_icon,
  support_icon,
  notification_icon,
  img_font_icon,
  refresh_icon,
  studentcup,
  setNewGoal,
  studentGoal,
  SUBJECTS_NO_DATA,
  UPCOMING_CLASSES_NO_DATA,
  NOTES_NO_DATA,
  EVENTS_NO_DATA,
  NOTIFICATIONS_NO_DATA,
} from "./assets";
import { RFValue } from "react-native-responsive-fontsize";
import hexToRgba from "hex-to-rgba";
import moment from "moment";
import "./StudentDashboard.web.css";
import Chart from "react-google-charts";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Header from "../../shared/Header/src/Header";
import FormHelperText from "@material-ui/core/FormHelperText";
import SideBar from "../../shared/SideBar/src/SideBar";
import Logout from "../../shared/SideBar/src/Logout";
import ViewAllNotesModal from "./ViewAllNotesModal.web";
import ViewAllEventsModal from "./ViewAllEventsModal";
import CreateNotes from "./CreateNotes.web";
import EditNote from "./EditNote.web";
import { HISTORY } from "../../../components/src/comman";
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../shared/Header/src/HOCAuth.web";
import { PersonOutline } from '@material-ui/icons'
import ViewThoughtModalWeb from "./ViewThoughtModal.web";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const noBorder = {
  border: "0px",
};

const avtarIcon = {
  width: "31px",
  height: "31px",
  margin: "20px 23px 20px 19px",
} as const;
// Customizable Area End
export class StudentDashboard extends StudentDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    const BootstrapInput2 = withStyles((theme) => ({
      input: {
        borderRadius: 9,
        position: "relative",
        border: "1px solid #ced4da",
        padding: "12px 26px 12px 12px",
        width: "108px",
        fontFamily: "Open sans",
        color: "#3f526d",
        fontSize: 18,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);
    const {
      schoolEvents,
      data,
      reminders,
      studentSubjectList,
      goals,
      progress,
      upcomingClasses,
      selectedUpcomingSubject,
      notes,
      learningCurve,
      assessment,
      timeTable,
      notesDescription,
      notesTitle,
      notesTitleError,
      notesDescriptionError,
      calendarDate,
      learningSelectedSubject,
      addNotesDisabled,
      learningCurveFullData,
    } = this.state;
    const reminderEvents: any = reminders?.events;
    const reminderThoughts: any = reminders?.thoughts;
    const noReminderData =
      reminderEvents?.length !== 0 || reminderThoughts?.length !== 0;

    const timeArray = [
      {
        id: 1,
        time_name: "00:00",
      },
      {
        id: 2,
        time_name: "00:30",
      },
      {
        id: 3,
        time_name: "01:00",
      },
      {
        id: 4,
        time_name: "01:30",
      },
      {
        id: 5,
        time_name: "02:00",
      },
      {
        id: 6,
        time_name: "02:30",
      },
      {
        id: 7,
        time_name: "03:00",
      },
      {
        id: 8,
        time_name: "03:30",
      },
      {
        id: 9,
        time_name: "04:00",
      },
      {
        id: 10,
        time_name: "04:30",
      },
      {
        id: 11,
        time_name: "05:00",
      },
      {
        id: 12,
        time_name: "05:30",
      },
      {
        id: 13,
        time_name: "06:00",
      },
      {
        id: 14,
        time_name: "06:30",
      },
      {
        id: 15,
        time_name: "07:00",
      },
      {
        id: 16,
        time_name: "07:30",
      },
      {
        id: 17,
        time_name: "08:00",
      },
      {
        id: 18,
        time_name: "08:30",
      },
      {
        id: 19,
        time_name: "09:00",
      },
      {
        id: 20,
        time_name: "09:30",
      },
      {
        id: 21,
        time_name: "10:00",
      },
      {
        id: 22,
        time_name: "10:30",
      },
      {
        id: 23,
        time_name: "11:00",
      },
      {
        id: 24,
        time_name: "11:30",
      },
      {
        id: 25,
        time_name: "12:00",
      },
      {
        id: 26,
        time_name: "12:30",
      },
      {
        id: 27,
        time_name: "13:00",
      },
      {
        id: 28,
        time_name: "13:30",
      },
      {
        id: 29,
        time_name: "14:00",
      },
      {
        id: 30,
        time_name: "14:30",
      },
      {
        id: 31,
        time_name: "15:00",
      },
      {
        id: 32,
        time_name: "15:30",
      },
      {
        id: 33,
        time_name: "16:00",
      },
      {
        id: 34,
        time_name: "16:30",
      },
      {
        id: 35,
        time_name: "17:00",
      },
      {
        id: 36,
        time_name: "17:30",
      },
      {
        id: 37,
        time_name: "18:00",
      },
      {
        id: 38,
        time_name: "18:30",
      },
      {
        id: 39,
        time_name: "19:00",
      },
      {
        id: 40,
        time_name: "19:30",
      },
      {
        id: 41,
        time_name: "20:00",
      },
      {
        id: 42,
        time_name: "20:30",
      },
      {
        id: 43,
        time_name: "21:00",
      },
      {
        id: 44,
        time_name: "21:30",
      },
      {
        id: 45,
        time_name: "22:00",
      },
      {
        id: 46,
        time_name: "22:30",
      },
      {
        id: 47,
        time_name: "23:00",
      },
      {
        id: 48,
        time_name: "23:30",
      },
      {
        id: 49,
        time_name: "23:59",
      },
    ];
    return (
      <Box className="wrap-div" style={{}}>
        <Box className="leftsidebar-col">
          <Box className="student-top-sec">
            <Box className="new-student-pro">
              <div
                style={{
                  width: "110px",
                  height: "110px",
                  margin: "0 3px 8px 0px",
                  // padding: "7px",
                  left: 0,
                  top: 0,
                  position: "relative",

                  // "border": "solid 5px #c5cad3",
                }}
              >
                {data.avatar ? (
                  <img
                    src={
                      data.avatar ? data.avatar : student_default_profile_pic
                    }
                    style={{
                      height: 110,
                      width: 110,
                      borderRadius: "50%",
                      padding: 3,
                    }}
                    className={this.props.classes.image_Border_Color}
                  />
                ) : (
                  <Avatar
                    src={"/"}
                    style={{
                      height: 110,
                      width: 110,
                      borderRadius: "50%",
                      padding: 3,
                    }}
                    className={this.props.classes.image_Border_Color}
                  />
                )}
              </div>

              <Typography
                style={{
                  fontSize: "16px",
                  // fontWeight: "bold",
                  // fontStretch: "normal",
                  // fontStyle: "normal",
                  // lineHeight: "normal",
                  // letterSpacing: "normal",
                  // color: "#9aa3b2",
                  // textTransform: "capitalize"
                }}
              >
                <span className={this.props.classes.heading_font}>
                  {data?.first_name} {data?.last_name}
                </span>
              </Typography>
            </Box>

            <Box className="progress-block">
              <Box
                className="flexwrap"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box className="studentcup-outer">
                  <img src={studentcup} className="studentcup-img"></img>
                </Box>
                <Box className="table-list">
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography className="progress-title">
                      Your Progress
                    </Typography>
                  </Box>
                  <Box
                    className="attendance-row"
                    style={{ display: "flex", width: "90%" }}
                  >
                    <Typography className="attendance-text">
                      Attendance
                    </Typography>
                    <Typography className="attendance-text">
                      {progress.data?.student_attendance_percentage || 0}%
                    </Typography>
                  </Box>
                  <Box
                    className="attendance-row"
                    style={{ display: "flex", width: "90%" }}
                  >
                    <Typography className="attendance-text">
                      Class rank
                    </Typography>
                    <Typography className="attendance-text">
                      {progress.data?.student_class_rank || "-"}
                    </Typography>
                  </Box>
                  <Box
                    className="attendance-row"
                    style={{ display: "flex", width: "90%" }}
                  >
                    <Typography className="attendance-text">Test</Typography>
                    <Typography className="attendance-text">
                      {progress.data?.student_assessment_percentage || 0}%
                    </Typography>
                  </Box>
                  <Box
                    className="attendance-row"
                    style={{ display: "flex", width: "90%" }}
                  >
                    <Typography className="attendance-text">
                      Home work
                    </Typography>
                    <Typography className="attendance-text">
                      {progress.data?.student_task_percentage || 0}%
                    </Typography>
                  </Box>
                </Box>
                <Box className="work-progress"
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 13,
                    }}
                  >
                    <div
                      style={{
                        padding: "7px",
                        left: 0,
                        top: 0,
                        position: "relative",
                      }}
                    >
                      <img
                        src={impOrangeMeter}
                        style={{
                          left: 0,
                          top: 0,
                          position: "relative",
                          width: "100%",
                        }}
                      />
                      <img
                        src={imgRedPin}
                        style={{
                          left: "40%",
                          bottom: 8,
                          position: "absolute",
                          transformOrigin: "center 40px",
                          transform: `rotate(${progress.data?.student_overoll_progress * (180 / 100) - 90
                            }deg)`,
                        }}
                      />
                    </div>
                  </Box>
                  <Box
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    {/* mb="13px" */}
                    <Box className="student-progress">
                      <Typography
                        style={{
                          fontFamily: "Open sans",
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          color: "#676262",
                          width: "100%",
                          margin: "3px 0px 5px 10px",
                        }}
                      >
                        Your Progress
                      </Typography>
{/* 
                      <Typography
                        style={{
                          fontFamily: "Open sans",
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          color: "#037c46",
                          width: "100%",
                          // margin: "3px 0px 10px 10px"
                        }}
                        className="student-progress-text"
                      >
                        is Pretty Good
                      </Typography> */}
                    </Box>

                    <Box
                      style={{
                        margin: "0px 10px 10px",
                        padding: "5px 6px",
                        borderRadius: "3px",
                        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.24)",
                        backgroundColor: "#fff",
                        fontFamily: "Open sans",
                        fontSize: "18px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        color: "#676262",
                      }}
                    >
                      {progress.data?.student_overoll_progress || 0}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="student-dashboard-right">
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Box className="my-subjects">
                <Box
                  className="card-subject"
                  style={{
                    // height: 60,
                    backgroundImage: `url(${book})`,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Open sans",
                      fontWeight: 600,
                      color: "white",
                    }}
                  >
                    My Subjects
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Open sans",
                      fontWeight: 600,
                      color: "rgba(255, 255, 255, 0.88)",
                      marginTop: 0,
                    }}
                  >
                    {studentSubjectList?.count}
                  </Typography>
                </Box>
                <Box
                  className="card-subject"
                  style={{
                    backgroundImage: `url(${note})`,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Open sans",
                      fontWeight: 600,
                      color: "white",
                    }}
                  >
                    Home Work
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Open sans",
                      fontWeight: 600,
                      color: "rgba(255, 255, 255, 0.88)",
                      marginTop: 0,
                    }}
                  >
                    {assessment.total_count}
                  </Typography>
                </Box>
              </Box>

              {/* TODO: This icon Will be needed in future */}
              <IconButton style={{ padding: 0, display: "none" }}>
                <MoreVertOutlined
                  style={{
                    height: 24,
                    width: 24,
                    color: "rgba(151, 135, 219, 0.57)",
                  }}
                />
              </IconButton>
            </Box>
            <Box
              className="services-boxs"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginTop: "15px",
                overflowX: "auto",
              }}
            >
              {studentSubjectList.data?.map((student: any, index: number) => (
                <Box className="cold" key={index}>
                  <Box
                    className="service-block"
                    key={index}
                    style={{
                      width: 173,
                      // height: 70, // 90,
                      borderRadius: 13,
                      margin: "76px 15.1px 28.7px 7px",

                      boxShadow: "0 6px 8px 0 rgba(121, 210, 255, 0.52)",
                      // backgroundImage: "radial-gradient(circle at 50% 3%, #57c9f9, #1862db 96%)"
                      backgroundImage: `${student?.background_color
                        ? student?.background_color
                        : "radial-gradient(circle at 50% 3%, #57c9f9, #1862db 96%)"
                        }`,
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={
                          student.subject_name === "biology"
                            ? impBiologyIcon
                            : student.subject_name === "mathematics"
                              ? impMathIcon
                              : student.subject_name === "english"
                                ? impEnglishIcon
                                : student.subject_name === "chemistry"
                                  ? impChemistryIcon
                                  : ""
                        }
                        style={{
                          width: "100px", // "87px", // 166.4px",
                          maxHeight: 115, // "173px",
                          marginTop: "-115px", // "-156px",
                          objectFit: "contain",
                          transform: "rotate(6deg)",
                          alignSelf: "center",
                        }}
                      ></img>
                    </Box>
                    <Typography
                      className="heading"
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "Open sans",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: "rgba(255, 255, 255, 0.88)",
                      }}
                    >
                      {student?.subject_name}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: "rgba(255, 255, 255, 0.88)",
                        margin: "3px 1px",
                      }}
                    >
                      Upcoming Classes
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: "rgba(255, 255, 255, 0.88)",
                        margin: "3px",
                      }}
                    >
                      Upcoming Test
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: "rgba(255, 255, 255, 0.88)",
                        margin: "3px",
                      }}
                    >
                      Home Work
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

          {/* Learning Curve */}

          <Box className="learning-curve">
            <Box className="learning-data">
              <Typography
                style={{
                  margin: "0 0 10px",
                  fontSize: "24px",
                  fontWeight: 400,
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                }}
                className={this.props.classes.title_font}
              >
                Learning Curve
              </Typography>
              {learningCurveFullData?.length > 0 ? (
                <Box style={{ marginTop: "23px" }}>
                  <Box style={{ display: "flex" }}>
                    {/* {studentSubjectList.data?.map((student: any, index: number) => ( */}
                    {learningCurveFullData?.map(
                      (student: any, index: number) => (
                        <Typography
                          key={index}
                          style={{
                            textTransform: "capitalize",
                            cursor: "pointer",
                            fontFamily: "Open sans",
                            fontSize: "15px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "center",
                            margin: "5px 10px",
                            color: `${learningSelectedSubject ===
                              student.subject?.subject_name
                              ? student.subject?.background_color
                                ? student.subject?.background_color?.split(
                                  ","
                                )[1]
                                : "red"
                              : "black"
                              }`,
                          }}
                          onClick={() => {
                            this.handleLearningCurveSubject(
                              student.subject?.subject_name
                            );
                          }}
                        >
                          {student.subject?.subject_name}
                        </Typography>
                      )
                    )}
                  </Box>
                  <div
                    style={{
                      width: "100%",
                      height: "350px",
                      overflowX: "auto",
                      overflowY: "hidden",
                    }}
                  >
                    {
                      learningCurve && learningCurve.length > 0 && (
                        <Chart
                          loader={<div>Learning Curve loading...</div>}
                          chartType="ColumnChart"
                          width={600}
                          height="100%"
                          data={learningCurve}
                          options={{
                            vAxis: {
                              minValue: 0,
                              maxValue: 100,
                              pointSize: 100,
                              baselineColor: "#fff",
                            },
                            legend: "none",
                            chartArea: {
                              left: 40,
                              width: "75%",
                              height: "75%",
                            },
                            bar: { width: 15 },
                          }}
                        />
                      )
                      // ) : (
                      //   <> No Subject Found</>
                      // )
                    }
                  </div>
                </Box>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "7%",
                    }}
                  >
                    <img
                      style={{ height: "182px", objectFit: "contain" }}
                      src={SUBJECTS_NO_DATA}
                      alt="No data found"
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "7%",
                    }}
                  >
                    <span
                      style={{
                        //fontFamily: "OpenSans",
                        opacity: 0.5,
                        fontSize: "22px",
                        fontWeight: 600,
                        color: "#3f526d",
                      }}
                    >
                      No Data Found!
                    </span>
                  </div>
                </>
              )}
            </Box>
            <Box className="goals">
              <Box
                className="set-goal"
                style={{
                  // height: 40,
                  borderRadius: 20,
                  margin: "33.3px 15.1px 5px 7px",

                  textAlign: "center",
                  boxShadow: "0 8px 27px 0 rgba(224, 224, 224, 0.5)",
                  backgroundImage:
                    "radial-gradient(circle at 50% 3%, #b1dffb, #9e45da 90%)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  HISTORY.push("/StudentAddResouceAndGoals");
                  localStorage.setItem("selectedMenuId", "6");
                  localStorage.setItem("portfolioType", "Goals");
                }}
              >
                <img
                  src={setNewGoal}
                  style={{
                    width: "108px",
                    height: "108px",
                    marginTop: "-156px",
                    objectFit: "contain",
                    //"transform": "rotate(6deg)"
                  }}
                ></img>
                <Typography
                  className="set-goal-text"
                  style={{
                    fontFamily: "Open sans",
                    fontSize: "15px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "rgba(255, 255, 255, 0.88)",
                    textTransform: "none",
                  }}
                >
                  Set New Goal
                </Typography>
              </Box>
              <Box
                className="set-goal"
                style={{
                  // height: 90,
                  borderRadius: 20,
                  textAlign: "center",
                  margin: "33.3px 15.1px 5px 7px",

                  boxShadow: "0 8px 27px 0 rgba(224, 224, 224, 0.5)",
                  backgroundImage:
                    "radial-gradient(circle at 50% 3%, #ffef71, #f32513 94%)",
                }}
              >
                <img
                  src={studentGoal}
                  style={{
                    width: "108px",
                    height: "108px",
                    marginTop: "-156px",
                    objectFit: "contain",
                    //"transform": "rotate(6deg)"
                  }}
                ></img>
                <Typography
                  style={{
                    fontFamily: "Open sans",
                    fontSize: "15px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "rgba(255, 255, 255, 0.88)",
                    padding: "0 5px 0px 5px",
                  }}
                >
                  My Goals
                </Typography>
                {/* TODO: ask from api to show only 2 records, order by latest */}
                {goals.data?.map(
                  (goal: any, index: number) =>
                    index < 2 && (
                      <Box
                        className="goal-col"
                        style={{ width: "100%" }}
                        key={index}
                      >
                        {/* mb="13px" */}
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography className="new-goal">
                            {goal.goal?.title}
                          </Typography>
                          <Typography className="new-goal">
                            {goal.goal_percentage} %
                          </Typography>
                        </Box>
                        <div>
                          <div
                            style={{
                              height: "2px",
                              margin: "3px 0px 10px 0px",
                              borderRadius: "1px",
                              backgroundColor: "#fff",
                            }}
                          >
                            <div
                              style={{
                                width: goal.goal_percentage + "%",
                                height: "2px",
                                borderRadius: "1px",
                                backgroundColor: "#ffd46b",
                              }}
                            >
                              {" "}
                            </div>
                          </div>
                        </div>
                      </Box>
                    )
                )}
                {goals?.total_count > 2 && (
                  <Box>
                    {/* display="flex" mt="16px" */}
                    <Button
                      disableRipple={true}
                      style={{ padding: 0, backgroundColor: "transparent" }}
                      onClick={() => {
                        HISTORY.push("/StudentProtfolio");
                        localStorage.setItem("selectedMenuId", "6");
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 13,
                          fontFamily: "Open sans",
                          fontWeight: 600,
                          color: "white",
                          textDecoration: "underline",
                          textTransform: "capitalize",
                        }}
                      >
                        View All
                      </Typography>
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {/*  */}

          {/* Reminder & Quotes */}
          <Box className="reminder-quote">
            <Typography
              style={{
                fontSize: 16,
                fontWeight: "bold",
              }}
              className={this.props.classes.title_font}
            >
              Reminder &amp; Quote
            </Typography>
            {noReminderData && (
              <Box
                style={{
                  overflowX: 'auto',
                  display: 'flex',
                }}
              >
                <Box className='reminder-quote-outer'
                  style={{
                    display: 'flex',
                    marginTop: '23px',


                  }}
                >
                  {reminderEvents?.map((reminderevent: any, index: number) => (
                    <Box
                      display="flex"
                      flexDirection="column"
                      style={{
                        padding: "35px 30px 38px 30px",
                        margin: "0 8px",
                        borderRadius: 15,
                        width: 346,
                        height: 220,
                        backgroundImage: `url(${birthday_bg})`,
                        backgroundSize: "cover",
                      }}
                      key={index}
                    >
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "white",
                          textTransform: 'capitalize'
                        }}
                      >
                        {reminderevent.attributes.name}
                      </Typography>

                      <Typography
                        style={{
                          fontSize: 23,
                          fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "white",
                          width: 288,
                          marginTop: 23,
                        }}
                      >
                        {reminderevent.attributes.user_name}
                      </Typography>

                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "white",
                          marginTop: 10,
                        }}
                      >
                        {moment(reminderevent.attributes.start_time).format("LL")}
                      </Typography>
                    </Box>
                  ))}

                  {reminderThoughts?.map((reminderthought: any, index: number) => (
                    <Box
                      display="flex"
                      flexDirection="column"
                      style={{
                        padding: "35px 30px 38px 30px",
                        width: 346,
                        height: 220,
                        borderRadius: 15,
                        marginLeft: 8,
                        backgroundImage: `url(${thoughts_bg})`,
                        backgroundSize: "cover",
                        // backgroundColor: "rgba(6, 176, 182, 0.95)"
                      }}
                      key={index}
                    >
                      <Typography
                        style={{
                          fontSize: 19,
                          fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        Thought for the day
                      </Typography>

                      <Typography
                        style={{
                          // fontSize: 16,
                          // fontWeight: 'bold',
                          fontFamily: "Open sans",
                          color: "white",
                          marginTop: 20,
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          height: 93,
                          display: '-webkit-box',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          this.setState({ mainReminder: reminderthought, mainReminderOpen: true })
                        }}
                        dangerouslySetInnerHTML={{
                          __html: reminderthought.attributes.thought_title,
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            {this.state.mainReminder && <ViewThoughtModalWeb classes={this.props.classes} mainReminder={this.state.mainReminder} mainReminderOpen={this.state.mainReminderOpen} modalhandleClose={this.modalhandleClose} />}
            {!noReminderData && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: "7%",
                  }}
                >
                  <img
                    className="dataimg"
                    style={{ height: "185px", objectFit: "contain" }}
                    src={UPCOMING_CLASSES_NO_DATA}
                    alt="No data found"
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Open sans",
                      opacity: 0.5,
                      fontSize: "22px",
                      fontWeight: 600,
                      color: "#3f526d",
                    }}
                  >
                    No Reminder & quotes Found!
                  </span>
                </div>
              </>
            )}
            {/* <Box style={{ display: "flex", marginTop: "23px" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "35px 30px 38px 30px",
                  // width: 263,
                  // height: 120,
                  borderRadius: 15,
                  // backgroundColor: "#ff8282"
                  backgroundImage: `url(${birthday_bg})`,
                  backgroundSize: "cover"
                }}
              >
                <Typography
                  style={{
                    fontSize: 21,
                    fontFamily: "Open sans",
                    fontWeight: "bold",
                    color: "white"
                  }}
                >
                  Upcoming Birthday
                </Typography>

                <Typography
                  style={{
                    fontSize: 25,
                    fontFamily: "Open sans",
                    fontWeight: "bold",
                    color: "white",
                    marginTop: 23
                  }}
                >
                  Vijay Mishra
                </Typography>

                <Typography
                  style={{
                    fontSize: 16,
                    fontFamily: "Open sans",
                    fontWeight: "bold",
                    color: "white",
                    marginTop: 10
                  }}
                >
                  8th Feb, 2021
                </Typography>
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "35px 30px 38px 30px",
                  width: 346,
                  // height: 120,
                  borderRadius: 15,
                  marginLeft: 17,
                  backgroundImage: `url(${thoughts_bg})`,
                  backgroundSize: "cover"
                  // backgroundColor: "rgba(6, 176, 182, 0.95)"
                }}
              >
                <Typography
                  style={{
                    fontSize: 21,
                    fontFamily: "Open sans",
                    fontWeight: "bold",
                    color: "white"
                  }}
                >
                  Thought for the day
                </Typography>

                <Typography
                  style={{
                    fontSize: 16,
                    fontFamily: "Open sans",
                    fontWeight: "bold",
                    color: "white",
                    marginTop: 26
                  }}
                >
                  If you want something in your life you've never had,
                  you'll have to do something, you've never done.
                </Typography>
              </Box>
            </Box> */}
          </Box>
          {/*  */}

          {/* Upcoming Classes */}
          <Box className="upcoming-classes">
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                className={this.props.classes.title_font}
              >
                Upcoming Classes
              </Typography>
            </Box>
            {Object.keys(upcomingClasses)?.length > 0 ? (
              <>
                <Box className="dates-tab">
                  {Object.keys(upcomingClasses)?.map(
                    (upClass: any, index: number) => (
                      <Box key={index}>
                        <Button
                          key={index}
                          variant="contained"
                          style={{
                            textTransform: "capitalize",
                            borderRadius: 6,
                            background:
                              upClass == selectedUpcomingSubject
                                ? "linear-gradient(290deg, #b6a5fe 106%, #7f6cfc)"
                                : "#f9f8fd",
                            fontSize: 16,
                            fontFamily: "Open sans",
                            fontWeight:
                              upClass == selectedUpcomingSubject
                                ? "normal"
                                : "bold",
                            color:
                              upClass == selectedUpcomingSubject
                                ? "white"
                                : "black",
                            // textTransform: "none",
                            marginLeft: index == 0 ? 0 : 25,
                          }}
                          onClick={() => {
                            this.handleUpcomingClasses(upClass);
                          }}
                        >
                          {moment(upClass).format("DD-MM-YYYY")}
                        </Button>
                      </Box>
                    )
                  )}
                </Box>
                <Box
                  className="table-classes"
                  style={{
                    maxHeight: "480px",
                    overflowY: "auto",
                  }}
                >
                  <Box
                    className="scroll-w"
                    style={{
                      display: "flex",
                      marginBottom: "13px",
                      justifyContent: "center",
                    }}
                  >
                    {/* <Typography
                    style={{
                      fontSize: 11,
                      fontFamily: "Open sans",
                      fontWeight: "normal",
                      color: "#3f526d",
                      width: "20%"
                    }}
                  >
                    Unit
                  </Typography> */}
                    <Typography
                      style={{
                        fontSize: 11,
                        letterSpacing: 0.26,
                        width: "35%",
                        alignItems: "center",
                        justifyContent: "left",
                        display: "flex",
                        textAlign: "start",
                      }}
                      className={this.props.classes.subHeading_font}
                    >
                      Subject
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 11,
                        letterSpacing: 0.26,
                        width: "20%",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        textAlign: "start",
                      }}
                      className={this.props.classes.subHeading_font}
                    >
                      Time
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        letterSpacing: 0.26,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        width: "30%",
                        textAlign: "start",
                      }}
                      className={this.props.classes.subHeading_font}
                    >
                      Period
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        letterSpacing: 0.26,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        width: "15%",
                        textAlign: "start",
                      }}
                      className={this.props.classes.subHeading_font}
                    >
                      Class Type
                    </Typography>
                  </Box>
                  {upcomingClasses[selectedUpcomingSubject]?.map(
                    (upClass: any, index: number) => (
                      <Box
                        className="scroll-w"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                          paddingBottom: "10px",
                          borderBottom: "1px solid rgb(216, 216, 216)",
                        }}
                        key={index}
                      >
                        {/* <Box style={{ display: "flex", width: "20%" }}>
                      <Typography
                        style={{
                          fontSize: 12,
                          fontFamily: "Open sans",
                          fontWeight: "normal",
                          color: "#000"
                        }}
                      >
                        {upClass.unit}
                      </Typography>
                    </Box> */}

                        <Box
                          style={{
                            display: "flex",
                            width: "35%",
                            alignItems: "center",
                            justifyContent: "left",
                            textAlign: "start",
                          }}
                        >
                          <Typography
                            className={this.props.classes.bodyText_font}
                          >
                            {
                              upClass?.data?.attributes?.subject.data
                                ?.attributes?.subject_name
                            }
                          </Typography>
                        </Box>

                        <Box
                          style={{
                            display: "flex",
                            width: "20%",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "start",
                          }}
                        >
                          <Typography
                            className={this.props.classes.bodyText_font}
                          >
                            {upClass?.data?.attributes?.start_date
                              ? moment(
                                upClass?.data?.attributes?.start_date
                              ).format("hh:mm A")
                              : "--"}
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            width: "30%",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "start",
                          }}
                        >
                          <Typography
                            className={this.props.classes.bodyText_font}
                          >
                            {upClass?.data?.attributes?.name}
                          </Typography>
                        </Box>
                        <Box
                          className={this.props.classes.bodyText_font}
                          style={{
                            width: "15%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "start",
                          }}
                        >
                          <Avatar
                            src={
                              upClass?.data?.attributes?.is_online
                                ? impBlackBoardDisabledIcon
                                : impBlackBoardIcon
                            }
                            variant="square"
                            style={{
                              height: 20,
                              width: 20,
                              cursor: upClass?.data?.attributes?.is_online
                                ? "not-allowed"
                                : "pointer",
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: 25,
                              fontFamily: "Open sans",
                              fontWeight: "normal",
                              color: "#3f526d",
                            }}
                          >
                            /
                          </Typography>
                          <Avatar
                            src={
                              upClass?.data?.attributes?.is_online
                                ? imgZoomIcon
                                : imgZoomDisabledIcon
                            }
                            style={{
                              height: 20,
                              width: 20,
                              cursor: upClass?.data?.attributes?.is_online
                                ? "pointer"
                                : "not-allowed",
                            }}
                          />
                        </Box>
                      </Box>
                    )
                  )}
                </Box>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: "7%",
                  }}
                >
                  <img
                    style={{ height: "273px", objectFit: "contain" }}
                    src={UPCOMING_CLASSES_NO_DATA}
                    alt="No data found"
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "5%",
                  }}
                >
                  <span
                    style={{
                      //fontFamily: "OpenSans",
                      opacity: 0.5,
                      fontSize: "22px",
                      fontWeight: 600,
                      color: "#3f526d",
                    }}
                  >
                    No Upcoming Classes Found!
                  </span>
                </div>
              </>
            )}
          </Box>
        </Box>
        {/*  */}

        <Box className="right-sidebar">
          {/* <Box display="flex" justifyContent="flex-end" mt="15px">
            <Button
              style={{
                background:
                  "linear-gradient(255deg, #b6a5fe 115%, #7f6cfc)",
                height: 39,
                width: 123,
                borderRadius: 8
              }}
              variant="contained"
              onClick={() => { this.setState({ logoutModal: !this.state.logoutModal }) }}
            >
              <Typography
                style={{
                  textTransform: "none",
                  fontSize: 15,
                  fontFamily: "Open sans",
                  fontWeight: 600,
                  color: "white"
                }}
              >
                Logout
                  </Typography>
            </Button>
            {this.state.logoutModal &&   logoutModal={this.state.logoutModal} closeModalFun={this.onlogoutCloseModal} navigation={undefined} />}
          </Box> */}
          <Box
            className="notification-block"
            style={{
              display: "flex",
              marginTop: 64,
              flexDirection: "column",
              borderRadius: 10,
              background: "linear-gradient(73deg, #f7627b -8%, #f38d9e 125%)",
              padding: "22px 20px 20px 30px",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Avatar src="/" style={{ width: 37 }} />
              {/* height: 37,  */}
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  marginLeft: 22,
                  color: '#fff'
                }}
                className={this.props.classes.heading_font}
              >
                Notification
              </Typography>
            </Box>
            {/* <Typography
              style={{
                fontSize: 16,
                fontFamily: "Open sans",
                fontWeight: 600,
                color: "white",
                marginTop: 23
              }}
            >
              Tomorrow we are having annual meeting at 12 O’cl… READ MORE
                </Typography>
            <Box display="flex" mt="16px">
              <Button
                disableRipple={true}
                style={{ padding: 0, backgroundColor: "transparent" }}
              >
                <Typography
                  style={{
                    fontSize: 13,
                    fontFamily: "Open sans",
                    fontWeight: 600,
                    color: "white",
                    textDecoration: "underline",
                    textTransform: 'capitalize'
                  }}
                >
                  View All
                    </Typography>
              </Button>
            </Box> */}
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "2%",
                }}
              >
                <span
                  style={{
                    width: "62px",
                    height: "62px",
                    borderRadius: "50%",
                    backgroundColor: "#cccccc33",
                    padding: "17px",
                  }}
                >
                  <img
                    style={{ height: "28px", objectFit: "contain" }}
                    src={NOTIFICATIONS_NO_DATA}
                    alt="No data found"
                  />
                </span>
              </div>
              <div
                style={{ width: "100%", textAlign: "center", marginTop: "2%" }}
              >
                <span
                  style={{
                    //fontFamily: "OpenSans",
                    opacity: 0.5,
                    fontSize: "16px",
                    color: "#fff",
                  }}
                >
                  No Notification Yet!
                </span>
              </div>
            </>
          </Box>
          <Box
            className="cal-pd"
            style={{
              display: "flex",
              marginTop: "30px",
              flexDirection: "column",
              borderRadius: 10,
              boxShadow: "0 7px 22px 0 rgba(227, 218, 218, 0.5)",
              backgroundColor: "white",
            }}
          >
            <Box
              className="cal-title"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: 21,
                  fontWeight: 600,
                }}
                className={this.props.classes.heading_font}
              >
                Calendar
              </Typography>
              {/* TODO: This icon Will be needed in future */}
              <Box style={{ display: "none", alignItems: "center" }}>
                <IconButton
                  disableRipple={true}
                  style={{ padding: 0, backgroundColor: "transparent" }}
                >
                  <MoreVertOutlined style={{ height: 22, width: 22 }} />
                </IconButton>
              </Box>
            </Box>
            <Calendar
              className="noborder"
              value={moment(calendarDate).toDate()}
              onChange={(date: any) => this.handleCalendarDate(date)}
              next2Label={null}
              prev2Label={null}
              calendarType="US"
            />
            <Box
              style={{
                display: "flex",
                marginTop: "32px",
                flexDirection: "column",
                padding: "25px 9px 0 19px",
                backgroundColor: "#fff",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "right",
                    margin: "0 0 10px 0",
                  }}
                >
                  <Box
                    className="down-arrow"
                    style={{
                      borderRadius: 10,
                    }}
                  >
                    <FormControl>
                      <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={calendarDate}
                        input={<BootstrapInput2 />}
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event: any) =>
                          this.handleCalendarDate(event.target.value)
                        }
                      >
                        <MenuItem value={moment().format("MM/DD/YYYY")}>
                          Today
                        </MenuItem>
                        <MenuItem
                          value={moment().add(1, "days").format("MM/DD/YYYY")}
                        >
                          Tomorrow
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                padding: "20px 17px 2px 18px",
                backgroundColor: "rgba(124, 106, 247, 0.27)",
                borderLeft: "3px solid #7c6af7",
              }}
            >
              {schoolEvents?.length === 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "7%",
                    }}
                  >
                    <img
                      style={{ height: "180px", objectFit: "contain" }}
                      src={EVENTS_NO_DATA}
                      alt="No data found"
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "7%",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Open sans",
                        opacity: 0.5,
                        fontSize: "22px",
                        fontWeight: 600,
                        color: "#3f526d",
                      }}
                    >
                      No Event Found!
                    </span>
                  </div>
                </>
              )}
              {schoolEvents?.length > 0 &&
                schoolEvents.map(
                  (schoolevent: any, index: number) =>
                    index < 3 && (
                      <Box
                        style={{
                          padding: "12px 64px 12px 13px",
                          borderRadius: 6,
                          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.14)",
                          backgroundColor: "#fff",
                          marginBottom: 7,
                          cursor: schoolEvents?.length <= 3 ? 'pointer' : 'default'
                        }}
                        onClick={() => {
                          schoolEvents?.length <= 3 && this.setState({
                            allEventsModal: !this.state.allEventsModal,
                          })
                        }}
                        key={index}
                      >
                        <Typography
                          style={{
                            fontSize: 18,
                            color: "#3f526d",
                            marginBottom: 8,
                          }}
                          className={this.props.classes.subHeading_font}
                        >
                          {schoolevent.attributes.name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 14,
                          }}
                          className={this.props.classes.bodyText_font}
                        >
                          {this.handleGetStartAndEndTime(
                            schoolevent?.attributes?.start_time
                          )}{" "}
                          to{" "}
                          {this.handleGetStartAndEndTime(
                            schoolevent?.attributes?.end_time
                          )}
                        </Typography>
                        {/* <Box display={'flex'}  alignItems='center' style={{
                          marginTop:'6px'
                        }}>
                        <PersonOutline
                        style={{color:'#3f526d'}}
                        />
                        <Typography     style={{
                            fontSize: 14,
                          }}
                          className={this.props.classes.bodyText_font}>
                         {schoolevent?.attributes?.user_name}
                        </Typography>
                          </Box> */}
                      </Box>
                    )
                )}
              {schoolEvents?.length > 3 && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "17px",
                  }}
                >
                  <Button
                    style={{
                      outline: "none",
                      width: 117,
                      height: 41,
                      borderRadius: 20,
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#7f6cfc",
                      margin: "10px 0px",
                    }}
                    variant="outlined"
                    onClick={() => {
                      this.setState({
                        allEventsModal: !this.state.allEventsModal,
                      });
                    }}
                  >
                    <Typography
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        fontFamily: "Open sans",
                        fontWeight: 600,
                        color: "#9aa3b2",
                      }}
                    >
                      View All
                    </Typography>
                  </Button>
                </Box>
              )}
              {this.state.allEventsModal && (
                <>
                  <ViewAllEventsModal
                    allEventsModal={this.state.allEventsModal}
                    modalHandleClose={this.onCloseEventsModal}
                    allEvents={schoolEvents}
                  />
                </>
              )}
            </Box>
          </Box>
          {/* Notes */}
          <Box
            className="notes-box cal-pd"
            style={{
              display: "flex",
              flexDirection: "column",
              borderRadius: 10,
              boxShadow: "0 7px 22px 0 rgba(227, 218, 218, 0.5)",
              backgroundColor: "white",
            }}
          >
            <Box
              style={{
                marginBottom: "0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: 24,
                  fontWeight: "normal",
                }}
                className={this.props.classes.heading_font}
              >
                Notes
              </Typography>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <IconButton>
                  <img
                    src={edit_note_img}
                    style={{ height: 19, width: 19 }}
                    onClick={() => {
                      this.setState({ createNotes: !this.state.createNotes });
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
            {this.state.createNotes && (
              <CreateNotes
                createNoteModal={this.state.createNotes}
                closeModalFun={this.onCloseModal}
              />
            )}
            <Box>
              {notes?.length > 0 ? (
                notes?.map(
                  (teacherNote: any, index: any) =>
                    index < 3 && (
                      <div key={index}>
                        <Box
                          style={{
                            marginTop: "12px",
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor:
                              index === 0
                                ? "#ffeeca"
                                : index == 1
                                  ? "#caf2ff"
                                  : "#e6e2ff",
                            borderRadius: "20px",
                            height: "auto",
                            padding: "16px 23px 23px 23px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.setState({
                              notes_id: teacherNote.id,
                              editNoteModal: !this.state.editNoteModal,
                            });
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 16,
                                fontWeight: 600,
                                textTransform: "capitalize",
                              }}
                              className={this.props.classes.subHeading_font}
                            >
                              {teacherNote.attributes.title}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: "normal",
                              }}
                              className={this.props.classes.subHeading_font}
                            >
                              {moment(teacherNote.attributes.updated_at).format(
                                "DD MMM,YYYY"
                              )}
                            </Typography>
                          </Box>

                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: "normal",
                              marginTop: 14,

                              textTransform: "capitalize",
                            }}
                            className={this.props.classes.bodyText_font}
                          >
                            {teacherNote.attributes.description}
                          </Typography>
                        </Box>
                        {this.state.editNoteModal &&
                          this.state.notes_id === teacherNote.id && (
                            <EditNote
                              notes_id={teacherNote.id}
                              heading={teacherNote.attributes.title}
                              description={teacherNote.attributes.description}
                              openModal={this.state.editNoteModal}
                              closeModalFun={this.onCloseModal}
                            />
                          )}
                      </div>
                    )
                )
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "7%",
                    }}
                  >
                    <img
                      style={{ height: "224px", objectFit: "contain" }}
                      src={NOTES_NO_DATA}
                      alt="No data found"
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "7%",
                    }}
                  >
                    <span
                      style={{
                        //fontFamily: "OpenSans",
                        opacity: 0.5,
                        fontSize: "22px",
                        fontWeight: 600,
                        color: "#3f526d",
                      }}
                    >
                      No Notes!
                    </span>
                  </div>
                </>
              )}
            </Box>
            {notes?.length > 3 && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "17px",
                }}
              >
                <Button
                  style={{
                    outline: "none",
                    width: 117,
                    height: 41,
                    borderRadius: 20,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#7f6cfc",
                  }}
                  variant="outlined"
                  onClick={() => {
                    this.setState({ allNotesModal: !this.state.allNotesModal });
                  }}
                >
                  <Typography
                    style={{
                      textTransform: "none",
                      fontSize: 14,
                      fontFamily: "Open sans",
                      fontWeight: 600,
                      color: "#9aa3b2",
                    }}
                  >
                    View All
                  </Typography>
                </Button>
              </Box>
            )}
            {this.state.allNotesModal && (
              <>
                <ViewAllNotesModal
                  allNotesModal={this.state.allNotesModal}
                  closeModalFun={this.onCloseModal}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default HOCAuth(
  withStyles(themeCreateStyle)(StudentDashboard as React.ComponentType<any>),
  "StudentAccount"
);
// Customizable Area End
