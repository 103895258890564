// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    addClass: boolean;
    closeModalFun: any;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    data: any;
    closeModal: boolean;
    selectDropDownDiv: boolean;
    dropDownAnchorEl: any;
    optionaldropDownAnchorEl: any;
    optionalDropDownDiv: boolean;
    divisionDropDownDiv: boolean;
    divisiondropDownAnchorEl: any;
    subjectData: any;
    data_Type: string;
    gradeData: any;
    grade_id: any;
    gradeerror: boolean;
    nameError: boolean;
    nameErrorText: string;
    className: string;
    optionalSubject: boolean;
    optionalSubjectData: any;
    division_Data: any;
    subjectError: boolean;
    divisionError: boolean;
    subject_data: any;
    optional_data: any;
    divisionData: any;
    isLoader: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AddClassController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apigetStudentgradeId: string = "";
    apiaddClassId: string = "";
    child: React.RefObject<any>;
    // Customizable Area End

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.child = React.createRef();
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            data: '',
            closeModal: false,
            selectDropDownDiv: false,
            divisionDropDownDiv: false,
            dropDownAnchorEl: null,
            divisiondropDownAnchorEl: null,
            subjectData: [],
            data_Type: '',
            gradeData: [],
            grade_id: '',
            gradeerror: false,
            nameError: false,
            nameErrorText: '',
            className: '',
            optionalSubject: false,
            optionalSubjectData: [],
            division_Data: [],
            optionalDropDownDiv: false,
            optionaldropDownAnchorEl: null,
            subjectError: false,
            divisionError: false,
            subject_data: [],
            optional_data: [],
            divisionData: [],
            isLoader: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.userdata = this.userdata.bind(this);
        this.get_student_grade = this.get_student_grade.bind(this);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.get_student_grade();
    }

    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.closeModalFun(this.state.closeModal)
    }
    dropdownClose = () => {
        this.setState({ optionaldropDownAnchorEl: null, dropDownAnchorEl: null, divisiondropDownAnchorEl: null, data_Type: '', optionalSubject: false })
    }
    dataSaveClose = (selected_Data: any) => {
        const { subjectData, optionalSubjectData, subject_data, optional_data } = this.state
        if (this.state.data_Type === 'subject') {
            this.setState({ dropDownAnchorEl: null, data_Type: '', subjectData: selected_Data })
            selected_Data.map((data: any) => {
                const arrayIndex = subject_data.findIndex((res: any) => res.id === data.id)
                if (data.checked && subject_data.length === 0) {
                    subject_data.push(data)
                }
                else if (data.checked && arrayIndex === -1) {
                    subject_data.push(data)
                }
                else if (!data.checked) {
                    const arrayIndex = subject_data.findIndex((res: any) => res.id === data.id)
                    if (arrayIndex != -1) {
                        subject_data.splice(arrayIndex, 1)
                    }
                }
            })
        }
        else {
            this.setState({ optionaldropDownAnchorEl: null, data_Type: '', optionalSubjectData: selected_Data })
            selected_Data.map((data: any) => {
                const arrayIndex = optional_data.findIndex((res: any) => res.id === data.id)
                if (data.checked && optional_data.length === 0) {
                    optional_data.push(data)
                }
                else if (data.checked && arrayIndex === -1) {
                    optional_data.push(data)
                }
                else if (!data.checked) {
                    const arrayIndex = optional_data.findIndex((res: any) => res.id === data.id)
                    if (arrayIndex != -1) {
                        optional_data.splice(arrayIndex, 1)
                    }
                }
            })
        }
    }
    dvisionSaveClose = (selected_Data?: any) => {
        this.setState({ divisiondropDownAnchorEl: null, divisionData: selected_Data })
        const { divisionData, division_Data } = this.state
        selected_Data.map((data: any) => {
            const arrayIndex = division_Data.findIndex((res: any) => res.id === data.id)
            if (data.checked && division_Data.length === 0) {
                division_Data.push(data)
            }
            else if (data.checked && arrayIndex === -1) {
                division_Data.push(data)
            }
            else if (!data.checked) {
                const arrayIndex = divisionData.findIndex((res: any) => res.id === data.id)
                if (arrayIndex != -1) {
                    division_Data.splice(arrayIndex, 1)
                }
            }
        })
    }

    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apigetStudentgradeId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        this.setState({ gradeData: responseJson.data })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiaddClassId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        this.props.closeModalFun(true)
                        toast.success("The Class has been added successfully.")
                        this.setState({
                            isLoader: false
                        })
                    } else {
                        const key: any = Object.keys(responseJson.errors[0])
                        toast.error(`${key} ${responseJson.errors[0][key]}`)
                        this.parseApiErrorResponse(responseJson.errors);

                        this.setState({
                            isLoader: false
                        })
                    }
                }
                this.setState({
                    isLoader: false
                })
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }

        }
    }
    get_student_grade = () => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}');
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            school: school_Data?.school_id
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetStudentgradeId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.gradeEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    add_Class = () => {
        const { subjectData, className, grade_id, division_Data, optionalSubjectData } = this.state
        const division: any[] = []
        const subject_attributes: any[] = []
        subjectData.map((data: any) => {
            if (data.checked) {
                subject_attributes.push(data)
            }
        })
        optionalSubjectData.map((data: any) => {
            if (data.checked && data.optional) {
                subject_attributes.push(data)
            }
        })
        division_Data.map((data: any) => {
            if (data.checked) {
                division.push(data.id)
                this.post_add_Class(data.id, subject_attributes)
            }
        })
    }
    post_add_Class = (id: any, subject_attributes: any) => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        let subjects: any[] = []
        const { subjectData, className, grade_id, division_Data, optionalSubjectData } = this.state
        if (subjectData.length === 0 || className.length === 0 || grade_id.length === 0 || division_Data.length === 0) {
            if (
                this.state.subjectData === null ||
                this.state.subjectData.length === 0
            ) {
                this.setState({ subjectError: true, });
            }
            if (this.state.className === null || this.state.className.length === 0) {
                this.setState({
                    nameError: true,
                    nameErrorText: "Name is required.",
                });
            }
            if (
                this.state.grade_id === null ||
                this.state.grade_id.length === 0
            ) {
                this.setState({ gradeerror: true, });
            }
            if (
                this.state.division_Data === null ||
                this.state.division_Data.length === 0
            ) {
                this.setState({ divisionError: true, });
            }
            return false;
        }
        subject_attributes.map((data: any) => {
            subjects.push({
                subject_id: data.id,
                optional: data.optional
            })
        })
        this.setState({
            isLoader: true
        })
        const data = {
            class_name: className,
            grade_id: parseInt(grade_id),
            school_id: school_Data.school_id,
            division_id: id,
            class_subjects_attributes: subjects,
        }
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };
        const httpBody = data;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiaddClassId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addClassEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiPostMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
