//@ts-nocheck
// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  WithStyles,
  withStyles,
  Grid,
  Button,
  IconButton,
  // MoreVertOutlined,
} from "@material-ui/core";

// import { showIcon } from "./assets";
// import moment from "moment";
import ThoughtBox from "./ThoughtBox.web";
import EventBoxWeb from "./EventBox.web";
import AdminReminderController, { Props } from "./AdminReminderController.web";
import PostEventModal from "./PostEventModal.web";
import PostThoughtModal from "./PostThoughtModal.web";
import Birthday from "./BirthdayBox.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
// Customizable Area End

class AdminReminder extends AdminReminderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  // Customizable Area Start
  render() {
    const { thoughts, schoolEvents, birthdayAndAnniverseries } = this.state
    
    const { classes } = this.props;
    return (
      <>
        <Spinner
          spinnerModal={this.state.spinner}
        />
        <Box>
          <Typography
            style={{
              margin: "20px 0px 25px 0",
              fontSize: "1.125rem",
              fontWeight: 600,
            }}
            className={this.props.classes.heading_font}
          >
            Reminder & Quotes
          </Typography>
        </Box>
        <Box className={classes.Box}>
          <Grid container spacing={6}>
            {/* first Half */}
            <Grid item sm={6} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className={classes.HeaderPost}>
                    <Box>
                      <Typography className={classes.Text}>
                        Thought for the day
                      </Typography>
                    </Box>
                    <Box>
                      <Button className={classes.Button}
                        onClick={this.OpenPostThoughtModal}
                      >
                        <Typography
                          style={{ textTransform: "none" }}
                          className={classes.ButtonText}
                        >
                          Post New Thought
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{
                    margin: "13.5px 0px 5.5px 0",
                    border: "solid 0.5px rgba(0, 0, 0, 0.17)",
                  }} />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    style={{
                      maxHeight: "520px",
                      height: "100%",
                      overflowY: "auto",
                    }}
                  >
                    {thoughts.length > 0 ?
                      thoughts.map((item: any) => {
                        return (
                          <ThoughtBox
                            createThought={this.getThoughts}
                            updateThought={this.updateThought}
                            setThoughtToInactive={this.setThoughtToInactive}
                            thoughtDetail={item}
                            createThoughtDelete={this.createThoughtDelete}
                            updateStatEditThough={this.updateStatEditThough}
                           
                            
                          />
                        )
                      }) :
                      <Box>
                        <Typography
                          style={{
                            margin: "20px 0px 25px 0",
                            fontSize: "1.125rem",
                            color: "#3f526d",
                            fontWeight: 600,
                            textAlign: 'center'
                          }}
                        >
                          No Data
                        </Typography>
                      </Box>

                    }
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* secong Half */}
            <Grid item sm={6} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className={classes.HeaderPost}>
                    <Box>
                      <Typography className={classes.Text}>
                        General events
                      </Typography>
                    </Box>
                    <Box>
                      <Button className={classes.Button}
                        onClick={this.OpenPostEventModal}
                      >
                        <Typography
                          style={{ textTransform: "none" }}
                          className={classes.ButtonText}
                        >
                          Post New Event
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>

                  <Box style={{
                    margin: "13.5px 0px 5.5px 0",
                    border: "solid 0.5px rgba(0, 0, 0, 0.17)",
                  }}
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <Box style={{ marginTop: 20 }}>
                    <Typography className={classes.Text}>Birthday's</Typography>
                  </Box>
                </Grid> */}
                <Grid item xs={12}>
                  <Box style={{
                    maxHeight: '180px',
                    height: '100%',
                    overflowY: "auto",
                    width:"100%",
                    textAlign:"center"
                  }}>
                    {/* Today's content Box */}
                    {birthdayAndAnniverseries.map((item: any) => {
                      return (
                        <Birthday birthday={item} />
                      )
                    })}
                    {/* Today's container end */}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {/* <Box style={{ marginTop: 20 }}>
                    <Typography className={classes.Text}>Events</Typography>
                  </Box > */}
                  <Box
                    style={{
                      maxHeight: '335px',
                      height: '100%',
                      overflowY: "auto",
                    }}
                  >
                    {schoolEvents.length > 0 ?
                      schoolEvents.map((item: any) => {
                        return (
                          <EventBoxWeb
                            eventDetail={item}
                            createEventDelete={this.createEventDelete}
                            updateStatEvent={this.updateStatEvent}
                            updateEvent={this.updateEvent}
                            postEvent={this.state.editPostEvent}
                            close={()=>this.closePostEditEventModal()}
                            OpenPostEditEventModal={this.OpenPostEditEventModal}
                          />
                        )
                      }) :
                      <Box>
                        <Typography
                          style={{
                            margin: "20px 0px 25px 0",
                            fontSize: "1.125rem",
                            color: "#3f526d",
                            fontWeight: 600,
                            textAlign: 'center'
                          }}
                        >
                          No Data
                        </Typography>
                      </Box>
                    }

                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <PostEventModal
          createEvent={this.createEvent}
          open={this.state.postEvent}
          close={() => this.closePostEventModal()}
          spinner={this.state.spinner}
         
        />

        <PostThoughtModal
          createThought={this.getThoughts}
          open={this.state.postThought}
          close={() => this.closePostThoughtModal()}
          appenedUpdateThought={this.appenedUpdateThought}
          postThought={this.postThought}
          
        />

      </>
    );
  }
  // Customizable Area End
}
export const themeCreatStyle = ((theme: any) => ({
  // Customizable Area Start
  Box: {
    marginTop: 15,
    marginBottom: 30,
    height: "640px",
    padding: "20px 19px 50px 20px",
    borderRadius: 8,
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  HeaderPost: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 8px",
  },
  Text: {
    fontSize: "1.25rem",
    fontWeight: 700,
    color: theme.props.subheadingColor,
    fontFamily: theme.props.subheadingFont
  },
  Button: {
    padding: "8px 16px",
    borderRadius: "8px",
    fontSize: "1.125rem",
    color: theme.props.buttonColor,
    backgroundColor: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    '&:hover': {
      background: "#fff",
    }
  },
  ButtonText: {
    fontSize: "1.125rem",
    fontWeight: 600,
  },
  background_color: {
    backgroundColor: theme.palette.primary.main
  },
  title_font: {
    color: theme.props.titleColor,
    fontFamily: theme.props.titleFont
  },
  subTitle_font: {
    color: theme.props.subtitleColor,
    fontFamily: theme.props.subtitleFont
  },
  heading_font: {
    color: theme.props.headingColor,
    fontFamily: theme.props.headingFont
  },
  subHeading_font: {
    color: theme.props.subheadingColor,
    fontFamily: theme.props.subheadingFont
  },
  bodyText_font: {
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  checkBox_color: {
    color: theme.props.buttonColor
  },
  button_color: {
    backgroundColor: theme.props.buttonColor,
    color: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    '&:hover': {
      background: theme.props.buttonColor,
    }
  },
  border_Color: {
    border: `1px solid ${theme.props.buttonColor}`
  },
  icon_color: {
    color: theme.props.iconColor
  },
  file_border_Color: {
    border: `dashed 0.5px ${theme.props.buttonColor}`,
  },
  button_color_disabled: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    '&:hover': {
      background: "#fff",
    }
  },
  normal_button_color: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
  },
  dashed_Border: {
    border: `0.3px dashed ${theme.props.buttonColor}`,
  },
  // Customizable Area End
}))

// Customizable Area Start
export default withStyles(themeCreatStyle)(HOCAuth(AdminReminder as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End
