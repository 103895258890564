// Customizable Area Start
import React, { useState } from "react"
import {
    Popover,
    Box,
    Typography,
    Grid,
    Button,
    Avatar
} from "@material-ui/core"
import ClearIcon from '@material-ui/icons/Clear';
import FilterSubject from "./FilterSubject.web"
import FilterPeriod from "./FilterPeriod.web"
import FilterTeacher from "./FilterTeacher.web"
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import './FilterScreen.web.css';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface IProps {
    // Customizable Area Start
    filterAnchorEl: any;
    navigation?: any;
    periodData: any;
    teacherData: any;
    schoolSubjectData: any;
    popoverClose: React.Dispatch<React.SetStateAction<any>>
    applyFilterClick: any;
    filterElement: any;
    classes: any;
    // Customizable Area End
}
export const FilterScreen: React.FC<IProps> = ({
    // Customizable Area Start
    filterAnchorEl,
    popoverClose,
    periodData,
    teacherData,
    schoolSubjectData,
    applyFilterClick,
    filterElement,
    classes
    // Customizable Area End
}) => {
    // Customizable Area Start
    const [dropDownAnchorEl, setDropDownAnchorEl] = useState<any>()
    const [subject, setSubject] = useState<any>(schoolSubjectData)
    const [filterSubject, setFilterSubject] = useState<any>(filterElement.subject)

    const [periodAnchor, setPeriodAnchor] = useState<any>()
    const [period, setPeriod] = useState<any>(periodData)
    const [filterPeriod, setFilterPeriod] = useState<any>(filterElement.period)

    const [TeacherAnchor, setTeacherAnchor] = useState<any>()
    const [Teacher, setTeacher] = useState<any>(teacherData ? teacherData : [])
    const [filterTeacher, setFilterTeacher] = useState<any>(filterElement.teacher)

    const Subject_data = [
        { id: 1, name: 'Hindi' },
        { id: 2, name: 'Bio' },
        { id: 3, name: 'Science' }]

    const handleClose = (event: any) => {
        event.stopPropagation();
        setDropDownAnchorEl(null)
    }


    const handlePeriodClose = (event: any) => {
        event.stopPropagation();
        setPeriodAnchor(null)
    }



    const handleTeacherClose = (event: any) => {
        event.stopPropagation();
        setTeacherAnchor(null)
    }

    const removePeriod = (event: any, data: any) => {
        event.stopPropagation();
        let check = filterPeriod.findIndex((item: any) => item.id === data.id)
        let itemcheck = filterPeriod.find((item: any) => item.id === data.id)
        const obj = [...filterPeriod]
        obj[check] = { ...itemcheck, checked: false }
        setFilterPeriod(obj)
    }
    const removeSubject = (event: any, data: any) => {
        event.stopPropagation();
        setFilterSubject('')
    }

    const removeTeacher = (event: any, data: any) => {
        event.stopPropagation();
        setFilterTeacher("")
    }

    const applyClick = () => {
        const filterPeriodIds = filterPeriod?.filter((data: any) => {
            return data.checked;
        }).map((item: any) => {
            return Number(item.id)
        })

        applyFilterClick(filterSubject, filterPeriodIds, filterTeacher)
    }

    return (
        <>
            <Popover
                id="1"
                anchorEl={filterAnchorEl}
                open={Boolean(filterAnchorEl)}
                onClose={() => {
                    popoverClose(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className="FilterScreenDialogModal"
                PaperProps={{
                    style: {
                        width: '30%',
                        minWidth: '250px'
                    },
                }}
            >

                <Box className="innerBox">

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                className={`${classes.title_font} headText`}
                            >
                                Filters
                            </Typography>
                            <Box
                                className={classes.filter_classAndSection_border_Color}
                            ></Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography className={classes.subTitle_font}>Select Subject</Typography>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        padding: filterSubject != "" ? "0px 5px 5px 5px" : "15px",
                                    }}
                                    className="listingContainer"
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        setDropDownAnchorEl(event.currentTarget)
                                    }}>
                                    {subject?.filter((_data: any, _index: any) =>
                                        filterSubject == _data.id
                                    ).map((data: any, index: number) => (

                                        <Grid item xs={12} className="item">
                                            <Box className="listingData">
                                                <Typography
                                                    className={`${classes.bodyText_font} listDataTypo`}
                                                >
                                                    {data.subject_name}
                                                </Typography>
                                                <ClearIcon
                                                    className="clearIcon"
                                                    onClick={(event: any) => removeSubject(event, data)}
                                                />
                                            </Box>
                                        </Grid>
                                    ))}
                                    {dropDownAnchorEl
                                        && (

                                            <FilterSubject
                                                dropDownAnchorEl={dropDownAnchorEl}
                                                closeModalFun={handleClose}

                                                saveSubject={(subjectState: any) => {
                                                    setSubject(subjectState)
                                                }}
                                                subjectStateData={subject}
                                                filterSubject={filterSubject}
                                                setFilterSubject={(periodstate: any) => {
                                                    setFilterSubject(periodstate)
                                                }}
                                            />

                                        )
                                    }

                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box>
                                <Typography className={classes.subTitle_font}>Select Teacher</Typography>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        padding: filterTeacher !== '' ? "0px 5px 5px 5px" : "15px",
                                    }}
                                    className="listingContainer"
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        setTeacherAnchor(event.currentTarget)
                                    }}>
                                    {Teacher?.filter((_data: any, _index: any) =>
                                        filterTeacher == _data.id
                                    ).map((data: any, index: number) => (

                                        <Grid item xs={12} className="item">
                                            <Box className="listContainer">

                                                <Typography
                                                    className={`${classes.bodyText_font} listDataTypo flex`}
                                                >
                                                    <Avatar src={data.attributes.avatar}
                                                        className="avatarImg"
                                                        variant="square" />

                                                    {data.attributes.first_name}
                                                </Typography>
                                                <ClearIcon
                                                    className="clearIcon"
                                                    onClick={(event: any) => removeTeacher(event, data)}
                                                />
                                            </Box>
                                        </Grid>
                                    ))}
                                    {TeacherAnchor
                                        && (

                                            <FilterTeacher
                                                dropDownAnchorEl={TeacherAnchor}
                                                closeModalFun={handleTeacherClose}

                                                saveTeacher={(Teacherstate: any) => {
                                                    setTeacher(Teacherstate)
                                                }}
                                                TeacherStateData={Teacher}
                                                filterTeacher={filterTeacher}
                                                setFilterTeacher={(Teacherstate: any) => {
                                                    setFilterTeacher(Teacherstate)
                                                }}
                                            />

                                        )
                                    }

                                </Grid>
                            </Box>
                        </Grid>
                        <Grid container className="btnContainer">
                            <Grid item xs={12}>
                                <Button
                                    className={`${classes.button_color} btnApply`}
                                    onClick={() => {
                                        applyClick()
                                        popoverClose(null)
                                    }}
                                >
                                    Apply
                                </Button>
                                <Button
                                    disableRipple={true}
                                    variant="contained"
                                    className={`${classes.button_color_disabled} cancelBtn`}

                                    onClick={popoverClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Box>
            </Popover>
        </>
    )
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(FilterScreen);
// Customizable Area End
