// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";

export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  data: any;
  studentSubjectList: any;
  goals: any;
  progress: any;
  upcomingClasses: any;
  selectedUpcomingSubject: string;
  notes: any;
  learningCurve: any;
  learningCurveFullData: any;
  assessment: any;
  timeTable: any;
  calendarDate: string;
  createnotediv: boolean;
  notesDescription: string;
  notesTitle: string;
  boxHeight: any;
  learningSelectedSubject: string;
  addNotesDisabled: boolean;
  notesTitleError: boolean,
  notesDescriptionError: boolean;
  logoutModal:boolean;
  createNotes:boolean;
  editNoteModal:boolean;
  allNotesModal:boolean;
  allEventsModal:boolean;
  notes_id:any;
  reminders:any;
  schoolEvents: any;
  mainReminderOpen:boolean
  mainReminder:null|any

  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudentDashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetQueryStrinurl: string = "";
  apigetStudentSubjectsId: string = "";
  apigetStudentClassesId: string = "";
  apigetStudentGoalsId: string = "";
  apigetStudentProgressId: string = "";
  apigetStudentNotesId: string = "";
  apigetLearningCurveId: string = "";
  apigetAssessmentId: string = "";
  apigetTimeTableId: string = "";
  apiCreateStudentNotesId: string = "";
  apigetReminderId: string = "";
  apigetSchholEventsId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      data: '',
      loading: false,
      studentSubjectList: {},
      goals: {},
      progress: {},
      upcomingClasses: [],
      selectedUpcomingSubject: '',
      notes: [],
      learningCurve: [],
      learningCurveFullData: [],
      assessment: {},
      timeTable: [],
      calendarDate: moment().format('MM/DD/YYYY'),
      createnotediv: false,
      notesDescription: '',
      notesTitle: '',
      boxHeight: -1,
      learningSelectedSubject: '',
      addNotesDisabled: false,
      notesTitleError: false,
      notesDescriptionError: false,
      logoutModal:false,
      createNotes:false,
      editNoteModal:false,
      allNotesModal:false,
      allEventsModal:false,
      notes_id:'',
      reminders:[],
      schoolEvents: [],
      mainReminder:null,
      mainReminderOpen:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.getStudentSubjects = this.getStudentSubjects.bind(this);
    this.getStudentUpcomingClasses = this.getStudentUpcomingClasses.bind(this);
    this.getStudentGoals = this.getStudentGoals.bind(this);
    this.getStudentProgress = this.getStudentProgress.bind(this);
    this.getStudentNotes = this.getStudentNotes.bind(this);
    this.getLearningCurve = this.getLearningCurve.bind(this);
    this.get_reminder = this.get_reminder.bind(this)
    this.getAssessment = this.getAssessment.bind(this);
    this.getTimeTable = this.getTimeTable.bind(this);
    this.userdata = this.userdata.bind(this);
    this.get_school_events = this.get_school_events.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const zoomMeetingBlock = document.getElementById("zmmtg-root");
    if (zoomMeetingBlock) zoomMeetingBlock.style.display = "none";
    this.getToken();
    this.userdata();
    this.getStudentSubjects();
    this.getStudentUpcomingClasses();
    this.getStudentGoals();
    this.getStudentProgress();
    this.getStudentNotes();
    this.getLearningCurve();
    this.get_reminder()
    // this.getAssessment();
    this.get_school_events();
    // this.getTimeTable();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }
  // Customizable Area Start
  modalhandleClose = () => {
    this.setState({ mainReminderOpen: false });
  };

  userdata = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}')
    this.setState({ data: data });
  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }
  // onlogoutCloseModal = (closeModal: boolean) => {
  //   this.setState({ logoutModal: closeModal })
  //   if (localStorage.getItem("user_data") === null && localStorage.getItem("token") === null) {
  //     this.props.navigation.navigate('EmailAccountLoginBlock')
  //   }
  // }
  onCloseModal = (closeModal: boolean) => {
    this.setState({ editNoteModal: closeModal, allNotesModal: closeModal, createNotes: closeModal, })
    this.getStudentNotes();
  }
  onCloseEventsModal = () => {
    this.setState({ allEventsModal: !this.state.allEventsModal })
    this.get_school_events();
  }
  getStudentSubjects = () => {
    const token = localStorage.getItem("token");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token,
      "school": data.school_id,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentSubjectsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentSubjectGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStudentUpcomingClasses = () => {
    const token = localStorage.getItem("token");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token,
      "school": data.school_id,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentClassesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentUpcomingClassesGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStudentGoals = () => {
    const token = localStorage.getItem("token");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token,
      "school": data.school_id,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentGoalsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentGoalsGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStudentProgress = () => {
    const token = localStorage.getItem("token");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token,
      "school": data.school_id,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentProgressId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentProgressUpdatedGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStudentNotes = () => {
    const token = localStorage.getItem("token");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token,
      "school": data.school_id,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentNotesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentNotesGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLearningCurve = () => {
    const token = localStorage.getItem("token");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token,
      "school": data.school_id,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetLearningCurveId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentCurveGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  convertUTCDateToLocalDate(convertDate: Date) {
    const newDate = new Date(convertDate);
    var zoneDate = new Date(newDate?.getTime() - newDate?.getTimezoneOffset() * 60 * 1000);
    return zoneDate;
  }

  getAssessment = () => {
    const token = localStorage.getItem("token");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token,
      "school": data.school_id,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetAssessmentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentAssessmentUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTimeTable = () => {
    const token = localStorage.getItem("token");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token,
      "school": data.school_id,

    };
    const param = {
      date: "20/10/2021"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTimeTableId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentTimeTableUrl + `?date=${this.state.calendarDate}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(param)
    // );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  logoutHandleClick = () => {
    this.props.navigation.navigate('EmailAccountLoginBlock')
    localStorage.removeItem('token');
    localStorage.removeItem('user_data');
  }

  handleUpcomingClasses = (selectedUpcomingSubject: string) => {
    this.setState({ selectedUpcomingSubject: selectedUpcomingSubject });
  }

  handleLearningCurveSubject = (selectedLearningSubject: string) => {
    this.createLearningCurveData(undefined, selectedLearningSubject);
  }
  get_reminder = () => {
    const token = localStorage.getItem("token");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token,
      // "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetReminderId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReminderEndPoint + `?school_id=${data.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  get_school_events = () => {
    const token = localStorage.getItem("token");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token,
      "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetSchholEventsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolEventEndPoint + `?school_id=${data.school_id}&date=${moment(this.state.calendarDate).format("DD/MM/YYYY")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apigetStudentClassesId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const newSelectedUpcomingSubject =Object.keys(responseJson)[0];
            this.setState({ upcomingClasses:  responseJson , selectedUpcomingSubject: newSelectedUpcomingSubject });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetStudentSubjectsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ studentSubjectList: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetStudentGoalsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ goals: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetStudentProgressId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ progress: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetStudentNotesId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            // TODO: Reverse notes data, as its coming in asc & we need in desc, shud be handle from api
            this.setState({ notes: responseJson?.data?.reverse(), createnotediv: false, notesTitle: "", notesDescription: "" });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetLearningCurveId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.createLearningCurveData(responseJson.data);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetAssessmentId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ assessment: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetTimeTableId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ timeTable: responseJson.data })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiCreateStudentNotesId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.getStudentNotes();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ addNotesDisabled: false, notesDescription: "", notesTitle: "", notesTitleError: false, notesDescriptionError: false });
        this.apiCreateStudentNotesId = "";
      }
      else if (apiRequestCallId === this.apigetReminderId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              // response_data: responseJson.data,
              reminders: responseJson.data,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }else if (apiRequestCallId === this.apigetSchholEventsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              // response_data: responseJson.data,
              schoolEvents: responseJson.data,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  createLearningCurveData = (responseJson = this.state.learningCurveFullData, selectedSubject = '') => {
    if (responseJson.length !== 0) {
      const selectedSubjectForLearningCurve = responseJson.find((item: any, index: number) => {
        if (selectedSubject !== '')
          return item.subject.subject_name.toLowerCase() === selectedSubject.toLowerCase();
        else {
          selectedSubject = responseJson[0].subject.subject_name;
          return index === 0;
        }
      })
      const data = [
        ["Month", "Marks", { role: "style" }],
        ["Jan", selectedSubjectForLearningCurve?.data?.jan, "#f6dbfe"],
        ["Feb", selectedSubjectForLearningCurve?.data?.feb, "#f6dbfe"],
        ["Mar", selectedSubjectForLearningCurve?.data?.mar, "#f6dbfe"],
        ["Apr", selectedSubjectForLearningCurve?.data?.apr, "color: #f6dbfe; borderRadius: 5"],
        ["May", selectedSubjectForLearningCurve?.data?.may, "#f6dbfe"],
        ["Jun", selectedSubjectForLearningCurve?.data?.jun, "#f6dbfe"],
        ["Jul", selectedSubjectForLearningCurve?.data?.jul, "#f6dbfe"],
        ["Aug", selectedSubjectForLearningCurve?.data?.aug, "color: #f6dbfe"],
        ["Sep", selectedSubjectForLearningCurve?.data?.sep, "#f6dbfe"],
        ["Oct", selectedSubjectForLearningCurve?.data?.oct, "#f6dbfe"],
        ["Nov", selectedSubjectForLearningCurve?.data?.nov, "#f6dbfe"],
        ["Dec", selectedSubjectForLearningCurve?.data?.dec, "color: #f6dbfe"]
      ];
      this.setState({ learningCurve: data, learningCurveFullData: responseJson, learningSelectedSubject: selectedSubject }) // responseJson
    } else {
      this.setState({ learningCurve: [], learningCurveFullData: [], learningSelectedSubject: "" }) // responseJson
    }
  }

  addNote = () => {
    if (this.apiCreateStudentNotesId === "") {
      const { notesTitle, notesDescription } = this.state;
      const data = {
        title: notesTitle,
        description: notesDescription,
      }
      if (data.title.trim() !== "" && data.description.trim() !== "") {
        const token = localStorage.getItem("token");
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
          "Content-Type": configJSON.dashboarContentType,
          token,
          "school": data.school_id,

        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiCreateStudentNotesId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.teacherCreateNotes
        );
        const httpBody = data;

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestbaseURLMessage),
          configJSON.InstituteURL
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboarPOSTMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      } else {
        // this.showAlert(
        //   "Invalid Notes Data",
        //   "Please enter both title & description!"
        // );
        (notesTitle.trim() === "") ? document.getElementById("student-notes-title")?.focus() :
          (notesDescription.trim() === "") && document.getElementById("student-notes-description")?.focus();
        this.setState({ addNotesDisabled: false, notesTitleError: notesTitle.trim() === "" ? true : false, notesDescriptionError: notesDescription.trim() === "" ? true : false, });
      }
    }
  }

  handleCalendarDate = (date: any) => {
    this.setState({ calendarDate: moment(date).format('MM/DD/YYYY') }, () => { this.get_school_events() });
  }
  handleGetStartAndEndTime = (startDate: any) => {

    const dateObj = new Date(startDate);
    // const hours = startDate.getHours(); //index of month
    // const min = startDate.getMinutes();
    const time = dateObj.getTime()
    // const year = dateObj.getFullYear();
    const formattedTime = moment(startDate, ["h:mm A"]).format("HH:mm");
    const formattedTimeHours = parseInt(formattedTime.split(":")[0]);
    const formattedTimeMins = parseInt(formattedTime.split(":")[1]);
    const finaltime = formattedTimeHours > 12 ? `${formattedTimeHours - 12}:${formattedTimeMins}PM` : `${formattedTime}AM`
    return moment(startDate).format('h:mm A');
  };
  // Customizable Area End
}
