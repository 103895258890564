// Customizable Area Start
import React from "react";
import {
    Box,
    Popover
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
// Customizable Area End

interface Props {
    // Customizable Area Start
    anchorEl: any;
    onClose: any;
    popoverContent: any;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
const PopoverComponent = ({
    anchorEl,
    onClose,
    popoverContent,
    classes
}: Props) => {
    // Customizable Area Start
    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: 0,
                    paddingLeft: 25,
                    marginTop: -15
                }
            }}
        >
            <Box className={classes.notchPopOver} />
            <div className="popover-component">
                {popoverContent()}
            </div>
        </Popover>
    )

    // Customizable Area End
}

export const popoverStyles = {
    notchPopOver: {
        position: "relative",
        "&::before": {
            backgroundColor: "white",
            content: '""',
            display: "block",
            position: "absolute",
            width: 25,
            height: 25,
            top: 15,
            transform: "rotate(45deg)",
            left: -7,
            boxShadow: "0 2px 0px 0 rgba(98, 98, 98, 0.5)",
        }
    }
};

export const themeCreateStyle = ((theme: any) => ({
    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    input_theme_style: {
        "& input": {
            color: theme.props.textColor,
            fontFamily: theme.props.textFont,
        }
    },
    icon_button_color: {
        color: theme.props.buttonColor
    },

    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
        "&:hover": {
            backgroundColor: theme.props.buttonColor,
            color: "white",
        }
    },

    outline_button_no_hover: {
        backgroundColor: "white",
        color: theme.props.buttonColor,
        border: `1px solid ${theme.props.buttonColor}`,
    },
}))

const combineStyles = (...styles: any) => {
    return function CombineStyles(theme: any) {
        const outStyles = styles.map((arg: any) => {
            // Apply the "theme" object for style functions.
            if (typeof arg === 'function') {
                return arg(theme);
            }
            // Objects need no change.
            return arg;
        });

        return outStyles.reduce((acc: any, val: any) => Object.assign(acc, val));
    };
}

const combinedStyle = combineStyles(themeCreateStyle, popoverStyles);
export default withStyles(combinedStyle)(PopoverComponent);
// Customizable Area End