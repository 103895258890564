// Customizable Area Start
export const userIcon = require("../../assets/userIcon.png")
export const chatIcon = require("../../assets/image-chat.png")
export const selectBoxIcon = require("../../assets/image-multiselectbox.png")
export const emailIcon = require("../../assets/image-emailicon.png")
export const chat2Icon = require("../../assets/chat-2.png")
export const ic_dustbin = require("../../assets/groupdusbin.png")
export const list = require("../../assets/group-23.png")
export const grid = require("../../assets/group-22.png")
export const disabled_list = require("../../assets/disabledlist.png")
export const active_grid = require("../../assets/activegrid.png")
export const search = require("../../assets/image-search.png")
export const rightArrow= require("../../assets/rightarrow.png")
export const downArrow= require("../../assets/Down Filled.png")
// Customizable Area End