// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const imgOnlineLearning = require("../assets/online-learning.png")
  .default;
export const imgOnlineTestTime = require("../assets/online-test-time.png")
  .default;
export const imgQuestionAndAnswer = require("../assets/question-and-answer.png")
  .default;

export const imgDifficult = require("../assets/image-difficultyicon1.png");
export const imgTotalTime = require("../assets/image-difficultyicon2.png");
export const imgSections = require("../assets/image-sectionicon3.png");
export const imgDeleteGray = require("../assets/delete-gray.png");
export const imgUndoCopy = require("../assets/image-undo-copy.png");
export const imgEdit = require("../assets/image-editicon.png");
export const imgEditSvg = require("../assets/edit-icon-pen.svg");
export const imgEyeIcon = require("../assets/image-eyeicon.png");
export const imgCopy = require("../assets/image-copyicon.png");
export const imgDelete = require("../assets/image-delete.png");
export const writing1 = require("../assets/writing-1.png");
export const writing2 = require("../assets/writing-2.png");
export const writing3 = require("../assets/writing-3.png");
export const writing4 = require("../assets/writing-4.png");
export const writing5 = require("../assets/writing-5.png");
export const writing6 = require("../assets/writing-6.png");
export const writingLongAns = require("../assets/writing-long-ans.png");
export const writingShortAns = require("../assets/writing-short-ans.png");
export const imageRatingScale = require("../assets/image-rating-scale.png");
export const imageAudio = require("../assets/image-audio.png");
export const imageVideo = require("../assets/image-video.png");
export const imageHotSpot = require("../assets/image-hotspot.png");
export const imageGraphPlotting = require("../assets/image-graph-plotting.png");
export const imageMarksPlus = require("../assets/image-marks.png");
export const imageMarksMinus = require("../assets/image-marks-copy.png");
export const uploadImg = require("../assets/group-2.png");
export const fileUpload = require("../assets/Groupfile_done.png");
export const errorModalImg = require("../assets/image-warningicon.png");
export const downloadIcon = require("../assets/download.png");
export const imageDropdown = require("../assets/image-dropdown.png");
export const imageClose = require("../assets/image-close.png");
export const imageCloseGray = require("../assets/image-close-gray.png");
export const imageAdd = require("../assets/image-add.png");
export const subjectRedirectIcon = require("../assets/subject-redirect-icon.png");
export const testData = require("../assets/01-Rounded-Rectangle-Layout.png")
  .default;
export const testData2 = require("../assets/02-Outline-Rounded-Rectangle-Layout.png")
  .default;
export const testData3 = require("../assets/03-Circle-Layout.png").default;
export const testData4 = require("../assets/04-Outline-Circle-Layout.png")
  .default;
export const testData5 = require("../assets/05-Rectangle-Block-Layout.png")
  .default;
export const testData6 = require("../assets/06-Outline-Rectangle-Block-Layout.png")
  .default;
export const imgBackArrow = require("../assets/image-back-arrow.png");
export const optionImage = require("../assets/uploadOptionImage.png");
export const imgPlusIcon = require("../assets/image-plus-icon.png");
export const imgGalary = require("../assets/image-galary.png");
export const imgUpload = require("../assets/image-upload.png");
export const imgTwoHand = require("../assets/image-two-hands.png");
export const scheduleModalImg = require("../assets/image-dealdone.png");
export const modalImgOrangeBackground = require("../assets/view-path.png");
export const DownloadIcon = require("../assets/DownloadIcon.png");
export const exam = require("../assets/exam.png");
export const filter = require("../assets/filter.png");
export const difficultyIcon = require("../assets/difficultyicon.png");
export const difficultyCopyIcon = require("../assets/difficultyicon-copy.png");
export const sectionIcon = require("../assets/sectionicon.png");
export const RightEditIcon = require("../assets/Rightediticon.png");
export const galleryImg1 = require("../assets/pexels-stein-egil-liland-3408744-copy.png")
  .default;
export const galleryImg2 = require("../assets/pexels-stein-egil-liland-3408744-copy-7.png")
  .default;
export const galleryImg3 = require("../assets/pexels-stein-egil-liland-3408744-copy-15.png")
  .default;
export const imageRight = require("../assets/image-right.png");
export const csvFile = require("../assets/questions.csv");
export const imageCheckmark = require("../assets/image-checkmark.png");
export const imageDownArrow = require("../assets/image-down-arrow.png");
export const imageSearch = require("../assets/image-search.png");
export const imageFiltericon = require("../assets/image-filtericon.png");
export const alertImg = require("../assets/alert.png").default;
export const Rename = require("../assets/Rename.png");
export const hintImageIcon = require("../assets/image-hint.png");
export const attachFilePinIcon = require("../assets/image-pin.png");
export const imgThumbUp = require("../assets/thumb_up_icon.png").default;
export const imgExamIcon = require("../assets/exam-icon.png").default;
export const iconlyLightPlay = require("../assets/iconly-light-play.png");
export const iconlyLightDocument = require("../assets/iconly-light-document.png");
export const zoomoutIcon = require("../assets/zoom-out.png");
export const arrowLeftIcon = require("../assets/arrowleft.png");
export const arrowRightIcon = require("../assets/arrowright.png");
export const arrowLeftWhite = require("../assets/image-arrowWhite.png");
export const arrowRightWhite = require("../assets/image_arrowrightWhite.png");
export const createNewIcon = require("../assets/create-new-icon.png");
export const bg = require("../assets/bg-copy.png").default;
export const bgSquare = require("../assets/bg_square.png");
export const newSchoolIcon = require("../assets/newSchoolIcon.png");
export const clock = require("../assets/clock.png");
export const matchGroup = require("../assets/Group.png");
export const imageDragicon = require("../assets/image_dragicon.png");
export const imageReUploadIcon = require("../assets/image-re-upload.png");
export const imageErrorIcon = require("../assets/image_error.png").default;
export const bubbleImg = require("../assets/group-8.png");
export const bubbleSquareImg = require("../assets/group-10.png");
export const bubbleImg2 = require("../assets/group-13.png");
export const arrowDropdownBlue = require("../assets/arrow-drop-down-blue.png");
export const chatOutlineBlue = require("../assets/chat-outline.png");
export const nounCheck = require("../assets/noun-check.png");
export const nounClose = require("../assets/noun-close.png");
export const recordVideoImg = require("../assets/record-video.png");
export const uploadVideoImg = require("../assets/upload-video.png");
export const recordAudioImg = require("../assets/image-microphone.png");
export const uploadAudioImg = require("../assets/image-uploadicon.png");
export const closeIconBlue = require("../assets/image-close-icon.png");
export const playImg = require("../assets/image-play-button.png");
export const playButtonImg = require("../assets/playButton.png");
export const pauseButtonImg = require("../assets/pauseButtton.png");
export const downloadBtnImg = require("../assets/download2.png");
export const recordBtnIcon = require("../assets/record-audio.png");
export const pauseButton = require("../assets/pause.png");
export const saveRecordingButton = require("../assets/saveRecording.png");
export const recordButton = require("../assets/record.png").default;
export const videoThumbnail = require("../assets/video-thumbnail.png").default;
export const errorCSVdelete = require("../assets/errorCSVdelete.png")
export const errorCSVDeleteFile = require("../assets/ErrorCSVDeleteFile.png")
export const errorCSVdownload = require("../assets/ErrorCSVdownload.png")
export const blackDeleteIcon = require("../assets/black_delete.png")
export const blueDeleteIcon = require("../assets/blue_delete.png").default;
export const uploadImage = require("../assets/upload.png");
export const deleteIconSvg = require("../assets/delete-icon.svg");
export const drawIcon = require("../assets/draw.png");

// svg files
export const arrowDropdownBlueSVG = require("../assets/arrow-drop-down-black-24-dp.svg");
export const nounCheckSVG = require("../assets/noun-check-3496109-copy-6.svg");
export const nounCloseSVG = require("../assets/noun-close-1075144-copy.svg");
export const matchGroupSVG = require("../assets/match-group.svg");
export const chatOutlineBlueSVG = require("../assets/chat-bubble-outline-black-24-dp-1-copy.svg");
export const zoomOutIconSVG = require("../assets/open-in-full-black-24-dp.svg");
export const uploadImageSVG = require("../assets/upload.svg");

export const imgBackArrowSVG = require("../assets/back.svg");
export const imageMarksPlusSVG = require("../assets/plus.svg");
export const imageMarksMinusSVG = require("../assets/minus-button.svg");
export const recordAudioSVG = require("../assets/microphone.svg");
export const uploadAudioSVG = require("../assets/document.svg");
export const recordVideoSVG = require("../assets/video.svg");
export const uploadVideoSVG = require("../assets/upload-video.svg");
export const imageAddSVG = require("../assets/image-add.svg");
export const libraryPlayIcon = require("../assets/play-button.svg");
export const subjectRedirectSvgIcon = require("../assets/subject_redirection_svg.svg");
export const imgEyeIconSvg = require("../assets/image-eyeicon.svg");
export const imgPlusIconSVG = require("../assets/add.svg");
export const recordButtonSVG = require("../assets/stop.svg");
export const recordBtnIconSVG = require("../assets/vinyl.svg");
export const playButtonSVG = require("../assets/play.svg");
export const downloadBtnSVG = require("../assets/download-1.svg");
export const pauseButtonSVG = require("../assets/pause-1.svg");
export const blueDeleteSVG = require("../assets/delete-blue.svg");
export const closeIconBlueSVG = require("../assets/close-blue.svg");
export const imgDeleteGraySVG = require("../assets/delete-gray.svg");
export const imageErrorSVG = require("../assets/image-error.svg");
export const imgGallerySVG = require("../assets/imageGallery.svg");
export const imgUploadSVG = require("../assets/imageUpload.svg");
export const imageDragSVG = require("../assets/imageDrag.svg");
export const optionImageSVG = require("../assets/uploadOption.svg");
export const imageCheckSVG = require("../assets/check.svg");

// select question type svg
export const fillInTheBlanksSVG = require("../assets/fillInTheBlanks.svg");
export const matchSVG = require("../assets/match.svg");
export const oneWordSVG = require("../assets/oneWord.svg");
export const pictionarySVG = require("../assets/pictionary.svg");
export const hotspotSVG = require("../assets/hotspot.svg");
export const flashCardSVG = require("../assets/flashCards.svg");
export const mcqSVG = require("../assets/mcq.svg");
export const trueFalseSVG = require("../assets/trueFalse.svg");
export const sortListSVG = require("../assets/sortList.svg");
export const textRatingScaleSVG = require("../assets/textRatingScale.svg");
export const percentageRatingScaleSVG = require("../assets/percentageRatingScale.svg");
export const graphicRatingScaleSVG = require("../assets/graphicRatingScale.svg");
export const numericRatingScaleSVG = require("../assets/numericRatingScale.svg");
export const labelImageDropDownSVG = require("../assets/dropDownMenu.svg");
export const labelImageTextSVG = require("../assets/labelImageText.svg");
export const labelImageDragAndDropSVG = require("../assets/dragAndDrop.svg");
export const twoByTwoGridSVG = require("../assets/twoByTwoGrid.svg");
export const graphSVG = require("../assets/barGraph.svg");
export const lineChartSVG = require("../assets/lineChart.svg");
export const longAnswerSVG = require("../assets/longAnswer.svg");
export const shortAnswerSVG = require("../assets/shortAnswer.svg");
export const audioSVG = require("../assets/audio.svg");
export const videoSVG = require("../assets/videoSvg.svg");
// Customizable Area End