// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const SocialMediaIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
           <path d="m26.863 17.358 3.599 3.602c2.26 2.259 2.008 5.535-.615 8.159l-.017.017-1.489 1.489C27.411 31.54 26.27 32 24.9 32c-1.297 0-2.801-.41-4.53-1.23-1.928-.915-2.855-.884-3.47-.854-.496.016-1.01.039-1.52-.47-.179-.179-.99-1.153.605-2.748.795-.796 1.888-1.025 2.836-1.04l-5.526-5.528a1.919 1.919 0 0 1-.568-1.374 1.935 1.935 0 0 1 .572-1.373c.367-.367.854-.57 1.372-.572h.003c.518 0 1.005.202 1.371.57l2.296 2.297c.092-.184.215-.353.364-.501a1.94 1.94 0 0 1 1.949-.483 1.943 1.943 0 0 1 3.1-.824 1.942 1.942 0 0 1 3.11-.51zm-1.804.947a.612.612 0 0 0-.005.86l.002.001.9.9a.665.665 0 0 1 0 .943.667.667 0 0 1-.943 0l-2.1-2.1a.608.608 0 0 0-.86.003.61.61 0 0 0-.003.86l1.499 1.501a.667.667 0 0 1-.943.943l-2.1-2.1a.61.61 0 0 0-.86.004.61.61 0 0 0-.004.86l1.5 1.5a.665.665 0 0 1 0 .944.666.666 0 0 1-.942 0l-5.1-5.1a.603.603 0 0 0-.427-.179.616.616 0 0 0-.614.613.6.6 0 0 0 .177.429l6.9 6.901a.667.667 0 0 1-.66 1.111c-.026-.007-2.414-.691-3.55.442-.516.517-.592.835-.577.905.057.039.067.055.498.037.121-.004.258-.01.412-.01.728 0 1.846.12 3.682.99 3.979 1.89 5.593.972 6.461.115l1.502-1.502.014-.013c1.589-1.608 2.679-4.183.612-6.25l-3.61-3.612a.611.611 0 0 0-.86.004zM10.665 0C16.546 0 21.33 4.786 21.33 10.67v2a3.337 3.337 0 0 1-3.333 3.334 3.338 3.338 0 0 1-3.096-2.1 5.318 5.318 0 0 1-4.236 2.1 5.34 5.34 0 0 1-5.332-5.335 5.339 5.339 0 0 1 5.332-5.334c1.591 0 3.022.7 4 1.81V6a.666.666 0 1 1 1.333 0v6.669c0 1.103.897 2 2 2 1.102 0 2-.897 2-2v-2c0-5.148-4.187-9.336-9.333-9.336s-9.332 4.188-9.332 9.335c0 5.147 4.186 9.336 9.332 9.336a.667.667 0 1 1 0 1.334C4.785 21.339 0 16.552 0 10.669S4.784 0 10.665 0zm0 6.668c-2.205 0-4 1.795-4 4.001a4.005 4.005 0 0 0 4 4.001c2.206 0 4-1.794 4-4a4.005 4.005 0 0 0-4-4.002z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default SocialMediaIcon;
// Customizable Area End