// Customizable Area Start
import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../../../shared/Header/src/HOCAuth.web";
import ParentAnnouncementListController, {
    Props,
} from "./ParentAnnouncementListController.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import "../ParentNotification/ParentNotification.web.css";
import { Divider } from "@material-ui/core";
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class ParentAnnouncementList extends ParentAnnouncementListController {
    render() {
        // Customizable Area Start
        const { isLoader, announcementList } = this.state;

        return (
            <>
                {isLoader && <Spinner spinnerModal={isLoader} />}
                <Grid container>
                    <Grid item xs={12}>
                        <div className={`notification_list_header ${this.props.classes.heading_font}`}>
                            Announcement
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="notification_list">
                            {announcementList?.map((announcement: any) => (
                                <div key={announcement.id} className="display_flex">
                                    <RadioButtonUncheckedOutlinedIcon className="announcement_indicator" />
                                    <div>
                                        <div className="announcement_title_div">
                                            <div className={`notification_title ${this.props.classes.heading_font}`}>
                                                {announcement.name}
                                            </div>
                                            <div className={`notification_date ${this.props.classes.subTitle_font}`}>
                                                {announcement.date}
                                            </div>
                                        </div>
                                        <div className={`announcement_desc ${this.props.classes.subTitle_font}`}>
                                            {announcement.description}
                                        </div>

                                        <Divider className="notification_list_divider" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
    HOCAuth(ParentAnnouncementList, "TeacherAccount")
);
// Customizable Area End
