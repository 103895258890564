// Customizable Area Start
import {
  Box,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import HOCAuth from "../../../../../shared/Header/src/HOCAuth.web";
import {
  book,mathematics
} from "../../../assets";
import WardSubjectsController, { Props } from "./WardSubjectsController.web";
import "../WardDetails.web.css"
import { themeCreateStyle } from "../../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class WardSubjects extends WardSubjectsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { data, wardSubjects } = this.state;

    return (
      <Grid className="child_activity_tab_height" container direction="row">
        {
          wardSubjects?.length > 0 ? (
            <>
              {wardSubjects.map((subject: any, index: number) =>
                <Grid className={`${this.props.classes.bodyText_font} task_center_align task_display_flex`} key={index} item xs={12} sm={12} md={6}>
                  <Box className="subject_box" mb="15px">
                    <span className="subject_icon" > {subject?.subject_icon ? (
                      <img
                        src={subject.subject_icon}
                        className={`subject_icon_img`}
                      />
                    ) : (
                      <img
                        src={mathematics}
                        className={`subject_icon_img`}
                      />
                    )}</span>
                    <span className={"subject_teacher"}>{subject?.subject_name}</span>
                  </Box>
                </Grid>
              )}
            </>
          ) :
            <Grid item xs={12}><span>No tasks yet !</span></Grid>
        }
      </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(WardSubjects, "TeacherAccount")
);
// Customizable Area End
