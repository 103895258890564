// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { HISTORY } from "../../../components/src/comman";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showLoader: boolean;
  classList: any;
  divisionList: any;
  subjectList: any;
  title: string;
  classId: string;
  divisionId: string;
  subjectId: string;
  examType: string;
  assessment_category_id: any;
  maxMark: string;
  terms: string;
  includeNagativeMark: boolean;
  difficultyLevel: string;
  totalTime: string;
  sectionList: any;
  selectedClass: any;
  customGuidelineList: any;
  maxHour: number;
  termList: any;
  categoryList: any;
  error: {
    title: boolean;
    classId: boolean;
    divisionId: boolean;
    subjectId: boolean;
    examType: boolean;
    assessment_category_id: boolean;
    maxMark: boolean;
    terms: boolean;
    totalTime: boolean;
    sectionList: boolean;
  };
  assessmentHasQuestions: boolean;
  openWarningModal: boolean;
  changeType: string;
  changeEventName: any;
  changeEventValue: any;
  sectionIdToDelete: any;
  warningTitle: string;
  warningSubTitle: string;
  warningParagraph: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherAssessmentCreateDetailController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiGetClassList: string = "";
  apiGetDivisionList: string = "";
  apiGetSubjectList: string = "";
  apigettermList: string = '';
  apigetcategoryList: string = '';
  apiCreateAssessment: string = "";
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = this.getEmptyState();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetClassList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ classList: responseJson.data });
            if (localStorage.getItem("assessmentData") !== null) {
              const user_data = localStorage.getItem("assessmentData");
              const data = JSON.parse(user_data || "{}");
              const newClass = responseJson.data.find(
                (classItem: any) => classItem.id == data.grade_id
              );
              this.setState({ selectedClass: newClass });
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({
          showLoader: false
        })
        this.parseApiCatchErrorResponse(errorReponse);
        // } else if (apiRequestCallId === this.apiGetDivisionList) {
        //   if (responseJson != null) {
        //     if (!responseJson.errors) {
        //       this.setState({ divisionList: responseJson.data });
        //     } else {
        //       this.parseApiErrorResponse(responseJson);
        //     }
        //   }
        //   this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigettermList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ termList: responseJson.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({
          showLoader: false
        })
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetcategoryList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ categoryList: responseJson.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({
          showLoader: false
        })
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiGetSubjectList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ subjectList: responseJson.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({
          showLoader: false
        })
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiCreateAssessment) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            localStorage.setItem("assessmentData", JSON.stringify(responseJson.data.attributes));
            localStorage.setItem("assessmentId", JSON.stringify(responseJson.data.id));

            this.createThemeUrl(responseJson.data.attributes.grade_id, responseJson.data.id);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // this.getClassList();
    this.getcategoryList()

    this.getSubjectsList('');
    if (HISTORY.location.state) {
      const customGuidelineListParsed = JSON.parse(HISTORY.location?.state?.customGuidelineList || "[]")
      const arrayOfItem = customGuidelineListParsed?.map((item: any) => {
        return { description: item.description }
      })
      this.setState({ customGuidelineList: arrayOfItem })
      window.history.replaceState(HISTORY.location.pathname, "")
    } if (localStorage.getItem("assessmentData") !== null) {
      const user_data = localStorage.getItem("assessmentData");
      const data = JSON.parse(user_data || "{}");
      let arr: any = []
      this.getClassList(data.subject_id)
      this.gettermList(data.grade_id)
      this.setState({
        title: data.assessment_name,
        classId: data.grade_id,
        divisionId: data.division_id,
        subjectId: data.subject_id,
        examType: data.exam_type,
        assessment_category_id: data?.assessment_category_id,
        maxMark: data.maximum_marks,
        terms: data.term_id,
        includeNagativeMark: data.negative_marking,
        difficultyLevel: data.difficulty_level,
        totalTime: data.time_limit,
        selectedClass: null,
      })
      data.assessment_sections_attributes?.map((_data: any) => {
        if (_data.name == "" && _data.section_marks == 0 && _data.total_questions == 0 && _data.time_limit == 0) {
          arr.push({
            id: uuidv4(),
            name: "",
            section_marks: "",
            total_questions: "",
            time_limit: "",
          })
        } else {
          if (_data?.question_data?.length > 0) {
            this.setState({
              assessmentHasQuestions: true
            })
          }
          arr.push({
            id: uuidv4(),
            section_id: _data?.section_id,
            name: _data.name,
            section_marks: _data.section_marks,
            total_questions: _data.total_questions,
            time_limit: _data.time_limit,
            question_data: _data?.question_data,
            error: {
              sectionName: false,
              sectionMarks: false,
              sectionQuestion: false,
              sectionTimeLimit: false,
            }
          })
        }
      })
      this.setState({
        sectionList: arr
      })
    }

  }

  getEmptyState = () => ({
    showLoader: false,
    classList: [],
    divisionList: [],
    termList: [],
    categoryList: [],
    subjectList: [],
    title: "",
    classId: "",
    divisionId: "",
    subjectId: "",
    examType: "",
    assessment_category_id: "",
    maxMark: "",
    minMark: "",
    terms: "",
    includeNagativeMark: false,
    difficultyLevel: "easy",
    totalTime: "00:00",
    maxHour: 0,
    sectionList: [
      {
        id: uuidv4(),
        name: "",
        section_marks: "",
        total_questions: "",
        time_limit: "",
        error: {
          sectionName: false,
          sectionMarks: false,
          sectionQuestion: false,
          sectionTimeLimit: false,
        }
      },
    ],
    selectedClass: null,
    customGuidelineList: null,
    error: {
      title: false,
      classId: false,
      divisionId: false,
      subjectId: false,
      examType: false,
      assessment_category_id: false,
      maxMark: false,
      minMark: false,
      terms: false,
      totalTime: false,
      sectionList: false,
    },
    assessmentHasQuestions: false,
    openWarningModal: false,
    changeType: '',
    changeEventName: '',
    changeEventValue: '',
    sectionIdToDelete: '',
    warningTitle: '',
    warningSubTitle: '',
    warningParagraph: ''
  });

  createThemeUrl = (grade_id: any, assessment_id?: any) => {
    HISTORY.push({
      pathname: "/TeacherAssessmentCreateGuideline",
      state: { grade_id, assessment_id },
    });
  };

  handleBackToLibrary = (data: any) => {
    let subjectData: any;
    let classData = ''

    this.state.subjectList.map((subjectItem: any) => {
      if (data.subject_id && (subjectItem.attributes?.id == data.subject_id)) {
        subjectData = subjectItem.attributes;
      }
    })

    this.state.classList.map((classItem: any) => {
      if (data.grade_id && (classItem.id == data.grade_id)) {
        classData = classItem;
      }
    })


    const selectedQuestionIds: any = [];
    if (data?.assessment_sections_attributes?.length) {
      data.assessment_sections_attributes.map((section: any) => {
        if (section?.question_data?.length) {
          section.question_data.map((question: any) => {
            selectedQuestionIds.push(Number(question.id))
            return question
          })
        }
        return section
      })
    }

    HISTORY.push({
      pathname: "/QuestionBank",
      state: {
        subject: subjectData,
        currentClass: classData,
        schoolClassId: data?.grade_id,
        selectedQuestionIds: selectedQuestionIds
      }
    })
  }
  handleBack = () => {
    if (localStorage.getItem('came_from_assessment_list') == 'true') {
      const assessmentSetting = JSON.parse(localStorage.getItem('assessment_library_setting') ?? '{}');
      HISTORY.push({
        pathname: "/LibraryAssessmentList",
        state: { subject:assessmentSetting?.subject, currentClass: assessmentSetting?.currentClass },
      });
      return;
    }
    HISTORY.push({
      pathname: "/TeacherAssessment"
    });
  };
  clearAssessmentAndGoBack = () => {
    localStorage.removeItem('assessmentData');
    this.handleBack()
  }
  handleClassChange = (eventTargetName: any, eventTargetValue: any) => {

    const name = eventTargetName;
    const value = eventTargetValue;

    const {
      termList,
      terms,
    } = this.getEmptyState();

    this.setState((preState) => ({
      ...preState,
      [name]: value,
      termList,
      terms,
    }));

    const newClass = this.state.classList.find(
      (classItem: any) => classItem.id == value
    );
    this.setState({ selectedClass: newClass })
    this.gettermList(newClass?.id)
  };

  handleSubjectChange = (eventTargetName: any, eventTargetValue: any) => {
    const name = eventTargetName;
    const value = eventTargetValue;

    const { classList, classId, termList, terms } = this.getEmptyState();
    this.setState((preState) => ({
      ...preState,
      [name]: value,
      classList,
      classId,
      termList,
      terms
    }));
    this.getClassList(value);
  }

  // handleDivisionChange = (e: any) => {
  //   const name = e.target.name;
  //   const value = e.target.value;

  //   const { subjectList, subjectId } = this.getEmptyState();

  //   this.setState((preState) => ({
  //     ...preState,
  //     [name]: value,
  //     subjectList,
  //     subjectId,
  //   }));
  //   this.getSubjectsList(value);
  // };

  handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "includingNagativeMark") {
      const checked = e.target.checked;
      this.setState((preState) => ({
        ...preState,
        [name]: checked,
      }));
    } else if (name) {
      this.setState((preState) => ({
        ...preState,
        [name]: value,
      }));
    }
  };

  handleChangeNegativeMarks = () => {
    this.setState({ includeNagativeMark: !this.state.includeNagativeMark })
  }

  handleAddSection = () => {
    this.setState((preState) => ({
      ...preState,
      sectionList: [
        ...this.state.sectionList,
        {
          id: uuidv4(),
          name: "",
          section_marks: "",
          total_questions: "",
          time_limit: "",
          error: {
            sectionName: false,
            sectionMarks: false,
            sectionQuestion: false,
            sectionTimeLimit: false,
          }
        },
      ],
    }));
  };

  handleUpdateSection = (index: number, e: any) => {
    const data = [...this.state.sectionList];
    const name = e.target.name;
    const value = e.target.value;
    data[index][name] = value;

    this.setState((preState) => ({
      ...preState,
      sectionList: data,
    }));
  };

  handleDeleteSection = (sectionId: string) => {
    let deletedSections: any = [];
    const filterSections = this.state.sectionList.filter(
      (section: any) => {
        if (section.id !== sectionId) {
          return section;
        } else {
          if (section?.section_id) {
            deletedSections.push({
              id: section.section_id,
              _destroy: true,
            })
          }
        }
      }
    );

    this.setState((preState) => ({
      ...preState,
      sectionList: filterSections,
    }));

    // handling storage
    if (deletedSections.length > 0) {
      const assessmentData = JSON.parse(localStorage.getItem('assessmentData') || "{}");
      assessmentData.deleted_sections = deletedSections;
      localStorage.setItem("assessmentData", JSON.stringify(assessmentData))
    }
    // handling storage END

    this.setState({
      openWarningModal: false
    })
  };

  onNext = async () => {
    if (this.validateForm()) {
      this.saveAssessmentAndContine();
    }
  };

  removeSectionQuestions = () => {
    const assessmentData = localStorage.getItem("assessmentData");
    const data = JSON.parse(assessmentData || "{}");
    data?.assessment_sections_attributes?.map((item: any) => {
      if (item?.question_data?.length > 0) {
        item.question_data = []
      }
    })
    this.setState({
      assessmentHasQuestions: false,
      openWarningModal: false
    })
    localStorage.setItem("assessmentData", JSON.stringify(data))
  }

  validateForm = () => {
    let isValid = true;
    const {
      title,
      classId,
      subjectId,
      examType,
      assessment_category_id,
      maxMark,
      terms,
      totalTime,
      sectionList,
    } = this.state;
    const error = this.getEmptyState().error;
    let minuteTime = 0;
    var timeParts = this.state.totalTime.split(":");
    minuteTime = Number(timeParts[0]) * 60 + Number(timeParts[1]);

    if (title === null || title.length === 0) {
      error.title = true;
      isValid = false;
    }

    if (classId === null || classId.length === 0) {
      error.classId = true;
      isValid = false;
    }

    if (subjectId === null || subjectId.length === 0) {
      error.subjectId = true;
      isValid = false;
    }

    if (examType === null || examType.length === 0) {
      error.examType = true;
      isValid = false;
    }

    if (assessment_category_id === null || assessment_category_id.length === 0) {
      error.assessment_category_id = true;
      isValid = false;
    }

    if (maxMark === null || maxMark.length === 0) {
      error.maxMark = true;
      isValid = false;
    }

    if (terms === null || terms?.length === 0) {
      error.terms = true;
      isValid = false;
    }

    if (totalTime === null || totalTime.length === 0 || totalTime === "00:00") {
      error.totalTime = true;
      isValid = false;
    }

    if (sectionList === null || sectionList.length === 0) {
      toast.error('Please add at least one section.')
      isValid = false;
    }

    else {
      let sectionTotalMarks: number = 0
      let sectionTotalTime: number = 0

      let sectionError: any = [];
      sectionList?.map((item: any, index: number) => {
        sectionTotalMarks = sectionTotalMarks + Number(item.section_marks)

        // time totaling
        sectionTotalTime += Number(item.time_limit)
        // time totaling END

        if (item.name === "" && Number(item.section_marks) === 0 && Number(item.total_questions) === 0 && Number(item.time_limit) === 0) {
          isValid = false;

          item.error.sectionName = true;
          item.error.sectionMarks = true;
          item.error.sectionQuestion = true;
          item.error.sectionTimeLimit = true;
        } else {
          item.error.sectionName = false;
          item.error.sectionMarks = false;
          item.error.sectionQuestion = false;
          item.error.sectionTimeLimit = false;
          isValid = true;
        }


        if (item.name === null || item.name === "") {
          item.error.sectionName = true;
          isValid = false;
        } else {
          item.error.sectionName = false;
          isValid = true;
        }
        if (Number(item.section_marks) === 0) {
          item.error.sectionMarks = true;
          isValid = false;
        } else {
          item.error.sectionMarks = false;
          isValid = true;
        }
        if (Number(item.total_questions) === 0) {
          item.error.sectionQuestion = true;
          isValid = false;
        } else {
          item.error.sectionQuestion = false;
          isValid = true;
        }

        if (item?.question_data) {
          if (Number(item.total_questions) < item?.question_data.length) {
            item.error.sectionQuestion = true;
            isValid = false;
          }

          // section marks validation
          const totalQuestionMarks = item?.question_data.reduce((result: number, data: any) => {
            result += data.marks
            return result;
          }, 0)

          if (totalQuestionMarks > Number(item.section_marks)) {
            item.error.sectionMarks = true;
            isValid = false;
          }
          // section marks validation END
        }

        // final check section
        if ((item.name === null || item.name === "") || (Number(item.section_marks) === 0) || Number(item.total_questions) === 0) {
          isValid = false;
        }
        // final check section END


        sectionError.push(isValid)
      })

      if (Number(maxMark) !== sectionTotalMarks) {
        toast.error('Total marks of all sections should be equal to Max marks.')
        isValid = false;
      }

      // check if any section has Error
      sectionError.map((data: any) => {
        if (!data) {
          isValid = false
        }
      })
      // check if any section has Error END
    }
    // final check
    if (
      (title === null || title.length === 0) ||
      (classId === null || classId.length === 0) ||
      (subjectId === null || subjectId.length === 0) ||
      (examType === null || examType.length === 0) ||
      (assessment_category_id === null || assessment_category_id.length === 0) ||
      (maxMark === null || maxMark.length === 0) ||
      (terms === null || terms?.length === 0) ||
      (totalTime === null || totalTime.length === 0 || totalTime === "00:00")
    ) {
      isValid = false;
    }
    // final check END

    this.setState({
      error,
    });

    return isValid;
  };

  getClassList = (subject_id?: string) => {
    this.setState({
      showLoader: true
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetClassList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGradesEndPoint + "?subject_id=" + subject_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDivisionsList = (gradeId: string) => {
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDivisionList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDivisionsEndPoint + `?grade_id=${gradeId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  gettermList = (classId: string) => {
    this.setState({
      showLoader: true
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigettermList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termListEndPoint + `?school_id=${school_Data?.school_id}&grade_id=${classId}&per=100`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Get Category list
  getcategoryList = () => {
    this.setState({
      showLoader: true
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetcategoryList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryListEndPoint + `?type=assessment`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Get Category End

  getSubjectsList = (divisionId: string) => {
    this.setState({
      showLoader: true
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSubjectList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolSubjectEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveAssessmentAndContine = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const {
      title,
      classId,
      subjectId,
      examType,
      assessment_category_id,
      maxMark,
      terms,
      includeNagativeMark,
      difficultyLevel,
      totalTime,
      sectionList,
      customGuidelineList,
      subjectList,
      classList,
      termList
    } = this.state;

    const selectedSubject = subjectList.filter((item: any) => item.id == subjectId)
    const selectedClass = classList.filter((item: any) => item.id == classId)
    const selectedTerm = termList.filter((item: any) => item.id == terms)

    const updatedSectionList = sectionList?.map((item: any) => {
      const total_question_marks = item?.question_data ? item?.question_data.reduce((result: any, data: any) => result += data.marks, 0) : 0;

      return {
        id: item.id,
        section_id: item?.section_id,
        name: item.name,
        section_marks: Number(item.section_marks),
        total_questions: Number(item.total_questions),
        time_limit: Number(item.time_limit),
        question_data: item?.question_data,
        remaining_marks: Number(item.section_marks) - Number(total_question_marks)
      }
    })

    if (localStorage.getItem("assessmentData") !== null) {
      const assessmentData = localStorage.getItem("assessmentData");
      const data = JSON.parse(assessmentData || "{}");
      data.school_id = school_Data?.school_id;
      data.assessment_name = title;
      data.grade_id = Number(classId);
      data.grade = selectedClass;
      data.subject_id = Number(subjectId);
      data.subject = selectedSubject;
      data.exam_type = examType;
      data.assessment_category_id = Number(assessment_category_id);
      data.maximum_marks = maxMark;
      data.term_id = Number(terms);
      data.term = selectedTerm;
      data.negative_marking = includeNagativeMark;
      data.time_limit = totalTime;
      data.difficulty_level = difficultyLevel;
      data.assessment_sections_attributes = updatedSectionList;

      if (customGuidelineList?.length) {
        data.assessment_guide_lines_attributes = customGuidelineList.filter((item: any) => item.description)
      }
      localStorage.setItem("assessmentData", JSON.stringify(data))
    } else {
      const data: any = {
        school_id: school_Data?.school_id,
        assessment_name: title,
        grade_id: Number(classId),
        grade: selectedClass,
        subject_id: Number(subjectId),
        subject: selectedSubject,
        exam_type: examType,
        assessment_category_id: Number(assessment_category_id),
        maximum_marks: maxMark,
        term_id: Number(terms),
        term: selectedTerm,
        negative_marking: includeNagativeMark,
        time_limit: totalTime,
        difficulty_level: difficultyLevel,
        assessment_sections_attributes: updatedSectionList,
      };

      if (customGuidelineList?.length) {
        data.assessment_guide_lines_attributes = customGuidelineList.filter((item: any) => item.description)
      }
      localStorage.setItem("assessmentData", JSON.stringify(data))
    }
    this.createThemeUrl(classId);
  }

  // Customizable Area End
}
