// Customizable Area Start
import React from "react"
import {
  Typography,
  Menu,
  MenuItem
  // MoreVertOutlined,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
interface IProps {
  classes?: any;
  anchorEl: any;
  setAnchorEl: any;
  sectionList: any;
  disabledItem: string | null;
  selectedQuestions: any;
  sendTargetSection: (val: any, currentSectionId: any) => any
}
// Customizable Area End

export const SectionMenu: React.FC<IProps> = ({
  // Customizable Area Start
  classes,
  anchorEl,
  sectionList,
  setAnchorEl,
  disabledItem,
  selectedQuestions,
  sendTargetSection,
  // Customizable Area End
}) => {
  // Customizable Area Start
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl()
  }
  const handleMoveQuestionToSection = (item: any) => {
    handleClose()
    sendTargetSection(item, disabledItem)
  }

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {sectionList && sectionList.map((item: any) => {
          let disabled = false;
          if (item.id === disabledItem) {
            disabled = true;
          } else {
            const filteredQuesions = selectedQuestions.filter((item: any) => item.checked && (item.section_id !== item.id) && (item.section_id === disabledItem));

            // total question validation
            let questionSum = (filteredQuesions ? filteredQuesions.length : 0 + item?.question_data ? item?.question_data.length : 0)
            let total_questions = item?.total_questions || 0;
            if (questionSum > total_questions) {
              disabled = true;
            }
            // total question validation END

            // total marks validation
            const totalMarksToMove = filteredQuesions?.reduce((sum: any, current: any) => (sum + current.marks), 0);
            const existingMarks = item?.question_data?.reduce((sum: any, current: any) => (sum + current.marks), 0);
            const totalUpdatedMarks = totalMarksToMove + existingMarks;
            
            if(totalUpdatedMarks > item.section_marks){
              disabled = true;
            }
            // total marks validation END
          }

          return (
            <MenuItem
              key={item.id}
              disabled={
                disabled ||
                (
                  item?.remaining_marks === 0 || item?.total_questions === item?.question_data?.length
                )
              }
              onClick={() => handleMoveQuestionToSection(item.id)}
            ><Typography
              className={`MoveSectionText ${classes.bodyText_font}`}
            >{item?.name}</Typography>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
  // Customizable Area End
}
// Customizable Area Start
export default SectionMenu
// Customizable Area End