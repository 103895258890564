// Customizable Area Start
import React, { useState } from "react";
import { Box, Grid, Modal, Divider, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End
// Customizable Area Start
const loader = {
  color: "blue",
  height: "30px",
  width: "30px",
  margin: "auto",
} as const;
const title = {
  fontFamily: "Open sans",
  fontSize: "24px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "center",
  color: "#3f526d",
  marginBottom: "14px",
} as const;
// Customizable Area End
interface Props {
  // Customizable Area Start
  previewPhotoModal: boolean;
  onCloseModal: any;
  contentType: any;
  fileData: any;
  fileName: string;
  // Customizable Area End
}

export const PreviewPhotoModal = ({
  // Customizable Area Start
  previewPhotoModal,
  onCloseModal,
  contentType,
  fileData,
  fileName,
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  const [isLoaded, setIsLoaded] = useState(false);

  const handleFileLoad = () => {
    setIsLoaded(true);
  };

  const handleClose = () => {
    onCloseModal();
    setIsLoaded(false);
  };
  return (
    <>
      <Modal
        open={previewPhotoModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="PreviewPhotoModal">
          <Grid className="PreviewPhotoModalHeader">
            <div style={title}>Preview File</div>
            <Close
              className="PreviewPhotoModalClose"
              onClick={handleClose}
            />
          </Grid>

          <Divider />

          <Grid className="PreviewPhotoDiv">
            {!isLoaded && (
              <div className="PreviewPhotoLoader">
                <CircularProgress style={loader} />
              </div>
            )}
            {contentType === "image/jpeg" ||
            contentType === "image/jpg" ||
            contentType === "image/png" ? (
              <img
                className="PreviewImg"
                src={fileData}
                onLoad={() => handleFileLoad()}
              />
            ) : (
              <embed
                type={contentType}
                src={fileData}
                height="400px"
                width="100%"
                onLoad={() => handleFileLoad()}
              />
            )}
          </Grid>
          <Typography className="PreviewPhotoName">
            {fileName}
          </Typography>
        </Box>
      </Modal>
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
export default PreviewPhotoModal;
// Customizable Area End