import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as SparkMD5 from "spark-md5";
import { toast } from "react-toastify";
import axios from "axios";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

import moment from "moment";
import { boolean } from "yup";
import { FieldArray } from "formik";
// Customizable Area Start
// Customizable Area End

export interface Props {
    navigation: any;

    // Customizable Area End
}
interface S {
    // Customizable Area Start
    addProtfolioType: any;
    fileName: any;
    uploadFile: any;
    data: any;
    showCompletedGoals: boolean;
    imageArray: any;
    imageCount: number;
    fileArray: any;
    fileUploadDetail: any;
    sihnIdData: any;
    anchorEl: any;
    isgoalCompleted: boolean;
    isLiked: boolean;
    inputStr: string;
    showPicker: boolean;
    studentGoals: any;
    goal_id: any;
    deleteGoal: boolean;
    deleteResource: boolean;
    pendingStudentGoals: any;
    completedStudentGoals: any;
    bulkFileUploaded: any;
    post_type: any;
    post_description: string;
    alertModal: boolean;
    alertMessage: string;
    files: any;
    resourceId: any;
    openComment: boolean;
    shareScreenAnchorEl: any;
    filterAnchorEl: any;
    timeline: any;
    timelineanchorEl: any;
    deleteTimeline: boolean;
    start_date: any;
    end_date: any;
    id: any;
    type: any;
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class ViewPostController extends BlockComponent<Props, S, SS> {

    apiStudentlikesGoalsId: string = '';
    apigetpostId: string = '';
    apigetStudentGoalsId: string = '';

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            addProtfolioType: '',
            fileName: '',
            uploadFile: '',
            data: [],
            showCompletedGoals: false,
            imageArray: [],
            imageCount: 0,
            fileArray: [],
            fileUploadDetail: [],
            sihnIdData: [],
            anchorEl: null,
            isgoalCompleted: false,
            isLiked: false,
            inputStr: '',
            showPicker: false,
            studentGoals: [],
            goal_id: [],
            deleteGoal: false,
            deleteResource: false,
            pendingStudentGoals: [],
            completedStudentGoals: [],
            bulkFileUploaded: [],
            post_type: [],
            post_description: '',
            alertModal: false,
            alertMessage: '',
            files: '',
            resourceId: '',
            openComment: false,
            shareScreenAnchorEl: '',
            filterAnchorEl: null,
            timeline: [],
            timelineanchorEl: null,
            deleteTimeline: false,
            start_date: '',
            end_date: '',
            id: [],
            type: [],
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.userdata = this.userdata.bind(this);
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.userdata();
        this.get_id();

    }
    get_id = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const path = window.location.pathname
        const z = path.split('/')
        this.setState({
            id: z[2],
            type: params.type

        })
        if (params.type === 'goals') {
            this.get_student_goals(z[2])
        }
        else {
            this.get_posts(z[2]);
        }
    }

    onShareClose = () => {
        this.setState({ shareScreenAnchorEl: null })
    }
    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));


            if (apiRequestCallId === this.apigetpostId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ timeline: responseJson.data })
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apigetStudentGoalsId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ timeline: responseJson.data })
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiStudentlikesGoalsId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (this.state.type === 'goals') {
                            this.get_student_goals(this.state.id)
                        }
                        else {
                            this.get_posts()
                        }
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }
    onClose = () => {
        this.setState({ deleteGoal: false, openComment: false, anchorEl: null })
    }

    onCloseResource = () => {
        this.setState({ deleteResource: false })
    }

    post_like = (type: any, id: any, like: boolean) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiStudentlikesGoalsId = requestMessage.messageId;
        const httpBody = {
            post_type: type === 'post' ? 'Post' : (type === 'goals' && 'Goal'),
            id: parseInt(id),
            liked: like,
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.likeGoalsEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    goToEditGoals = () => {
        localStorage.setItem("portfolioType", "EditGoal")
        localStorage.setItem("editGoalId", this.state.goal_id)
        this.props.navigation.navigate("StudentAddResouceAndGoals")
    }
    get_posts = (id?: any) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetpostId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.submitPostEndPoint + `${id ? id : this.state.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    get_student_goals = (id?: any) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetStudentGoalsId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getGoalsEndPoint + `${id ? id : this.state.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
