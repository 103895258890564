// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  studentList: any;
  studentListMeta: any;
  page: number;
  rowsPerPage: number;
  showLoader: boolean;
  openPublishReportModal: boolean;
  classData: any;
  selectedClassId: string;
  canBePublished: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherReportCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetClassData: string = "";
  apiGetStudentList: string = "";
  apiPublishReports: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      studentList: [],
      studentListMeta: {},
      page: 0,
      rowsPerPage: 10,
      showLoader: false,
      openPublishReportModal: false,
      classData: [],
      selectedClassId: '',
      canBePublished: false
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apiGetStudentList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              studentList: responseJson?.data,
              studentListMeta: responseJson?.meta,
              canBePublished: responseJson?.can_be_published,
              showLoader: false
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetClassData) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              classData: responseJson.data,
              selectedClassId: responseJson?.data?.[0]?.id
            }, () => {
              this.getStudentList();
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiPublishReports) {
        if (responseJson != null) {
          if (responseJson.success) {
            this.setState({ canBePublished: true })
          } else {
            this.setState({ canBePublished: false })
            this.parseApiErrorResponse(responseJson);
          }
          this.setState({ openPublishReportModal: true })
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getClassData();
    // Customizable Area End
  }

  // Customizable Area Start

  getClassData = () => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
      school: school_Data?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetClassData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClassListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStudentList = () => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetStudentList = requestMessage.messageId;

    const { selectedClassId } = this.state;

    let apiEndPoint = configJSON.getStudentListEndPoint + `?school_class_id=${Number(selectedClassId)}&page=${this.state.page + 1}&per=${this.state.rowsPerPage}&school_id=${school_Data?.school_id}`

    this.setState({ showLoader: true })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onEditClick = (studentData: any) => {
    HISTORY.push({
      pathname: '/ReportCardDetails',
      state: {
        studentData: studentData.attributes.account
      },
    });
  }

  openPublishReportModal = () => {
    this.publishReports()
  }

  closePublishReportModal = () => {
    this.setState({ openPublishReportModal: false })
  }

  handleClassChange = (classId: any) => {
    this.setState({ selectedClassId: classId }, () => {
      this.getStudentList();
    })
  }

  onPageChangeReportCard = (e: any) => {
    if (e.selected !== this.state.page) {
        this.setState({ page: e.selected }, () => this.getStudentList())
    }
  }

  publishReports = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id,
    };

    const httpBody: any = {
      school_class_id: Number(this.state.selectedClassId)
    }

    this.setState({ showLoader: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );

    this.apiPublishReports = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.publishReportsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
