// Customizable Area Start
import React, { useEffect, useState } from "react"
import {
    Popover, Box,
    Typography,
    Checkbox,
    Button,
    Avatar
} from "@material-ui/core"
import {
    Search,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import './CommonFilterModal.web.css';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface IProps {
    // Customizable Area Start
    dropDownAnchorEl: any;
    data_Type?: any;
    closeModalFun: React.Dispatch<React.SetStateAction<any>>;
    TeacherStateData: any;
    saveTeacher: (data: any[]) => void
    filterTeacher: any;
    setFilterTeacher: (data: any[]) => void;
    classes: any;
    // Customizable Area End
}

const FilterTeacher: React.FC<IProps> = ({
    // Customizable Area Start
    dropDownAnchorEl,
    closeModalFun,
    TeacherStateData,
    saveTeacher,
    filterTeacher,
    setFilterTeacher,
    classes,
    // Customizable Area End
}) => {
    // Customizable Area Start
    const [searchTerm, setSearchTerm] = useState('')
    const [filter, setFilter] = useState<any>(filterTeacher)
    const [TeacherState, setTeacherState] = useState<any>([])

    useEffect(() => {
        setTeacherState(TeacherStateData)
    }, [])

    const reset = () => {
        setTeacherState([])
        setSearchTerm('')
    }

    const ArrSubjectData =
        searchTerm === '' ? TeacherState :
            TeacherState.filter((data: any) => data?.attributes?.first_name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
                data?.attributes?.last_name?.toLowerCase()?.includes(searchTerm.toLowerCase())
            )

    return (
        <>
            <Popover
                id="1"
                anchorEl={dropDownAnchorEl}
                open={Boolean(dropDownAnchorEl)}
                onClose={closeModalFun}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className="FilterPeriodPopover"
                PaperProps={{
                    style: {
                        maxHeight: '70%',
                        width: '27%',
                        padding: "20px 20px",
                        borderRadius: 8,
                        boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.2)",
                        backgroundColor: "#fff",
                        minWidth: '205px'
                    },
                }}
            >
                <Box>
                    <Box
                        className={`${classes.border_Color} searchBox`}
                    >
                        <Search
                            className={`${classes.icon_color} searchIcon`}
                        />
                        <input
                            placeholder="Search Teacher"
                            className="inputBox"
                            onChange={
                                ((event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSearchTerm(event.target.value.trim())
                                })}
                        />
                    </Box>

                    <Box
                        className="listingBox"
                    >
                        {ArrSubjectData.length === 0
                            ?
                            <>
                                <Typography className={`noREcordFound ${classes.theme_font}`}>
                                    No record(s) found.
                                </Typography>
                            </>
                            :
                            ArrSubjectData.map((data: any, index: any) => (
                                <Box
                                    key={data.id}
                                    className="subjectData"
                                    onClick={() => {
                                        let checked = ""
                                        if (filter == data.id || parseInt(filter) == parseInt(data.id)) {
                                            checked = ''
                                        } else {
                                            checked = data.id
                                        }
                                        setFilter(checked)
                                    }}
                                >
                                    <div className="flexCenter">
                                        <Avatar src={data.attributes.avatar}
                                            className="avatarImg"
                                            variant="square" />
                                        <Typography
                                            className={`${classes.bodyText_font} ml7 attrTypo`}>
                                            {data.attributes.first_name}
                                        </Typography>
                                    </div>
                                    <Checkbox
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        className={`${classes.icon_color} checkboxStyled`}
                                        checked={filter == data.id ? true : false}
                                    />
                                </Box>
                            ))
                        }
                    </Box>
                    {TeacherState && TeacherState.length > 0 &&
                        <Box
                            className="btnContainer"
                        >
                            <Button
                                onClick={(e) => {
                                    setFilterTeacher(filter)
                                    closeModalFun(e)
                                    reset()
                                }}
                                className={`${classes.button_color} saveBtn`}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={(e: any) => {
                                    closeModalFun(e)

                                }}
                                className={`${classes.button_color_disabled} cancelBtn`}
                            >
                                Cancel
                            </Button>
                        </Box>
                    }
                </Box>
            </Popover>
        </>
    )
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(FilterTeacher);
// Customizable Area End
