// Customizable Area Start
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import StudentGradingDetailsController, { Props } from './StudentGradingDetailsController.web';
import Spinner from '../../../shared/SideBar/src/Spinner';
import StudentPagination from "./StudentPagination.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

export class StudentGradingDetails extends StudentGradingDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          display: "flex",
          flexWrap: "wrap",
        },
        margin: {
          margin: theme.spacing(1),
        },
        withoutLabel: {
          marginTop: theme.spacing(3),
        },
        table: {
          minWidth: 700,
        },
      })
    );

    const { reportGradeScaleData, isLoader } = this.state

    return (
      <>
        {isLoader && <Spinner spinnerModal={isLoader} />}
        <div>
          <p className={`${this.props.classes.heading_font} grading-detail-text`}>
             Grading scale for this report card.
          </p>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {
              reportGradeScaleData?.attributes?.report_grade_scales?.data?.length > 0 &&
              <TableContainer style={{ borderRadius: 6, border: 0 }}>
                <Table style={{ width: "calc(100% - 1px)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${this.props.classes.studentReportCard_classAndSection_background_color} border-radius-0`} style={{ padding: "17px 20px", fontSize: 20, fontWeight: "bold" }}>Label</TableCell>
                      <TableCell className={this.props.classes.studentReportCard_classAndSection_background_color} style={{ padding: "17px 20px", fontSize: 20, fontWeight: "bold" }}>Score</TableCell>
                      <TableCell className={this.props.classes.studentReportCard_classAndSection_background_color} style={{ padding: "17px 20px", fontSize: 20, fontWeight: "bold" }}>Max Marks</TableCell>
                      <TableCell className={`${this.props.classes.studentReportCard_classAndSection_background_color} border-radius-0`} style={{ padding: "17px 20px", fontSize: 20, fontWeight: "bold" }}>Min Marks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ marginTop: 10, backgroundColor: "#f9f8fd" }}>
                    {reportGradeScaleData?.attributes?.report_grade_scales?.data?.map((data: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell className={`${this.props.classes.heading_font} border-radius-0`} style={{ padding: "17px 20px", border: "1px solid lightgray", fontSize: 18, fontWeight: 600 }}>{data.attributes.name}</TableCell>
                        <TableCell className={this.props.classes.heading_font} style={{ padding: "17px 20px", border: "1px solid lightgray", fontSize: 18, fontWeight: 600, textTransform: "capitalize" }}>{data.attributes.score}</TableCell>
                        <TableCell className={this.props.classes.heading_font} style={{ padding: "17px 20px", border: "1px solid lightgray", fontSize: 18, fontWeight: 600 }}>{data.attributes.max_marks}</TableCell>
                        <TableCell className={`${this.props.classes.heading_font} border-radius-0`} style={{ padding: "17px 20px", border: "1px solid lightgray", fontSize: 18, fontWeight: 600 }}>{data.attributes.min_marks}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Grid>
        </div>
        <StudentPagination intialPage={3} />
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(StudentGradingDetails as React.ComponentType<any>);
// Customizable Area End
