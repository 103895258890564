// Customizable Area Start

import React from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Spinner from "../../shared/SideBar/src/Spinner";
import {
  img21kSchholLogo,
  imgloginScreenBackground,
  manImage,
  bigCloud,
  smallCloud,
} from "./assets";
import "./EmailLoginOTP.web.css";
import "./EmailLogin.web.css";
import { Hidden } from "@material-ui/core";
import EmailLoginOTPController, { Props } from "./EmailLoginOTPController.web";
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
import { withStyles } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class EmailLoginOTP extends EmailLoginOTPController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      otp_code: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      cursorPointer: 0,
      loadingSpinner: false,
      otperror: false,
      otperrorText: "",
    };
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const reg = /^\d+$/;
    const { otp1, otp2, otp3, otp4, otp_code } = this.state;

    return (
      <div className="EmailLoginOTPH100vh">
        {this.state.loadingSpinner && (
          <Spinner spinnerModal={this.state.loadingSpinner} />
        )}
        <div className="EmailLoginOTPRoot">
          <Grid container className="EmailLoginOTPH100">
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={6}
              spacing={0}
              className="EmailLoginOTPLoginImageDiv"
            >
              <img
                src={imgloginScreenBackground}
                className="EmailLoginOTPHalfCircleImage"
                alt="Background Image"
              />
              <img
                src={manImage}
                alt="Student Illustrator Image"
                className="student_illustrator_img EmailLoginOTPImageStudenIllustratorImage"
              />
              <div>
                <img
                  className="SchholLogo EmailLoginOTPLogoImage"
                  src={img21kSchholLogo}
                  alt="Logo Image"
                />
              </div>
              <div className="learn_together_text EmailLoginOTPTextlabelQuoteText">
                <Hidden only={["lg", "xl"]}>Let’s learn Together</Hidden>
                <Hidden only={["xs", "sm", "md"]}>
                  Let’s learn
                  <br />
                  Together
                </Hidden>
              </div>
              <img
                src={smallCloud}
                alt="Small Cloud Image"
                className="cloud_small_img EmailLoginOTPImageCloudSmallImage"
              />
              <img
                src={bigCloud}
                alt="Big Cloud Image"
                className="cloud_big_img EmailLoginOTPImageCloudBigImage"
              />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={6}>
              <Grid container className="main_right_div">
                <Grid item xs={12} className="login_div_otp EmailLoginDiv">
                  <div>
                    <div className="welcome_text EmailLoginOTPTextlabelWelcomeText">
                      Verify your account
                    </div>
                    <p className="EmailLoginOTPTextlabelFollowInstructionText">
                      Enter the verification code we just sent you on your email
                      or mobile number.
                    </p>
                  </div>
                  <form onSubmit={this.emailOTPhandleSubmit}>
                    <div className="email_otp_form_div EmailLoginOTPDiv">
                      <div className="EmailLoginOTPTextFieldDiv">
                        <TextField
                          id="standard-basic"
                          className="EmailLoginOTPForrmInputTextfield"
                          variant="outlined"
                          autoFocus={true}
                          type="number"
                          name="otp_code"
                          value={this.state.otp1}
                          error={this.state.otperror}
                          onPaste={(e) => {
                            const otp = e.clipboardData.getData("Text");
                            this.setState({
                              otp1: otp[0],
                              otp2: otp[1],
                              otp3: otp[2],
                              otp4: otp[3],
                            });
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (
                              this.state.otp2 !== "" &&
                              this.state.otp3 !== "" &&
                              this.state.otp4 !== ""
                            ) {
                              this.setState({
                                otp1: event.target.value,
                                otperror: false,
                              });
                            } else {
                              this.setState({
                                otp1: event.target.value,
                              });
                            }

                            if (event.target.value.length === 1) {
                              const nextfield = document.querySelector(
                                `input[name=otp2]`
                              );
                              if (nextfield !== null) {
                                (nextfield as HTMLElement).focus();
                              }
                            }
                          }}
                          inputProps={{
                            className: "login_otp_input_props",
                          }}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 1);
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "8.8px",
                              backgroundColor: "#f1f1f1",
                            },
                          }}
                        />
                        <TextField
                          id="standard-basic"
                          className="EmailLoginOTPForrmInputTextfield"
                          variant="outlined"
                          type="number"
                          name="otp2"
                          value={this.state.otp2}
                          error={this.state.otperror}
                          onPaste={(e) => {
                            const otp = e.clipboardData.getData("Text");
                            this.setState({
                              otp1: otp[0],
                              otp2: otp[1],
                              otp3: otp[2],
                              otp4: otp[3],
                            });
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (
                              this.state.otp1 !== "" &&
                              this.state.otp3 !== "" &&
                              this.state.otp4 !== ""
                            ) {
                              this.setState({
                                otp2: event.target.value,
                                otperror: false,
                              });
                            } else {
                              this.setState({
                                otp2: event.target.value,
                              });
                            }

                            if (event.target.value.length === 1) {
                              const nextfield = document.querySelector(
                                `input[name=otp3]`
                              );
                              if (nextfield !== null) {
                                (nextfield as HTMLElement).focus();
                              }
                            }
                          }}
                          inputProps={{
                            className: "login_otp_input_props",
                          }}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 1);
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "8.8px",
                              backgroundColor: "#f1f1f1",
                            },
                          }}
                        />
                        <TextField
                          id="standard-basic"
                          className="EmailLoginOTPForrmInputTextfield"
                          variant="outlined"
                          type="number"
                          name="otp3"
                          value={this.state.otp3}
                          error={this.state.otperror}
                          onPaste={(e) => {
                            const otp = e.clipboardData.getData("Text");
                            this.setState({
                              otp1: otp[0],
                              otp2: otp[1],
                              otp3: otp[2],
                              otp4: otp[3],
                            });
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (
                              this.state.otp1 !== "" &&
                              this.state.otp2 !== "" &&
                              this.state.otp4 !== ""
                            ) {
                              this.setState({
                                otp3: event.target.value,
                                otperror: false,
                              });
                            } else {
                              this.setState({
                                otp3: event.target.value,
                              });
                            }

                            if (event.target.value.length === 1) {
                              const nextfield = document.querySelector(
                                `input[name=otp4]`
                              );
                              if (nextfield !== null) {
                                (nextfield as HTMLElement).focus();
                              }
                            }
                          }}
                          inputProps={{
                            className: "login_otp_input_props",
                          }}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 1);
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "8.8px",
                              backgroundColor: "#f1f1f1",
                            },
                          }}
                        />
                        <TextField
                          id="standard-basic"
                          className="EmailLoginOTPForrmInputTextfield"
                          variant="outlined"
                          type="number"
                          name="otp4"
                          value={this.state.otp4}
                          error={this.state.otperror}
                          onPaste={(e) => {
                            const otp = e.clipboardData.getData("Text");
                            this.setState({
                              otp1: otp[0],
                              otp2: otp[1],
                              otp3: otp[2],
                              otp4: otp[3],
                            });
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (
                              this.state.otp1 !== "" &&
                              this.state.otp2 !== "" &&
                              this.state.otp3 !== ""
                            ) {
                              this.setState({
                                otp4: event.target.value,
                                otperror: false,
                              });
                            } else {
                              this.setState({
                                otp4: event.target.value,
                              });
                            }
                          }}
                          inputProps={{
                            className: "login_otp_input_props",
                          }}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 1);
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "8.8px",
                              backgroundColor: "#f1f1f1",
                            },
                          }}
                        />
                      </div>

                      {this.state.otperror && (
                        <FormHelperText className="EmailLoginOTPErrorColorDiv">
                          {this.state.otperrorText}
                        </FormHelperText>
                      )}
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="textlabel_loginText"
                    >
                      Verify
                    </Button>
                  </form>
                  <div className="EmailLoginOTPTextlabelForgotpasswordText">
                    <span>Didn't receive OTP?</span>{" "}
                    <Link
                      className="resendotp_text EmailLoginOTPResendOTPText"
                      onClick={() => {
                        const nextfield = document.querySelector(
                          `input[name=otp_code]`
                        );
                        if (nextfield !== null) {
                          (nextfield as HTMLElement).focus();
                        }
                        this.resendOtp();
                      }}
                    >
                      Resend
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(EmailLoginOTP);
// Customizable Area End
