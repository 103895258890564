// Customizable Area Start
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { AssignmentIndOutlined, AssignmentOutlined } from '@material-ui/icons';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import moment from 'moment';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import '../AddQuestion/common.css';
// Customizable Area End

// Customizable Area Start

const configJSON = require('../config.js');
const noData = require('../assets/no-data.png').default;

ChartJS.register(ArcElement, Tooltip, Legend);
// Customizable Area End
interface IProps {
  // Customizable Area Start
  data: any;
  classes: any;
  tableData: any;
  assessmentData: any;
  id: string;
  // Customizable Area End
}

export const AssessmentSummaryReports: React.FC<IProps> = (props) => {
  // Customizable Area Start
  const { classes, data, tableData = [], assessmentData, id } = props;
  // Customizable Area Start

  return (
    <div id={id}>
      <Box marginTop={3}>
        <Typography variant="h6" className={classes.subTitle_font}>
          {configJSON.ASSESSMENT_SUMMARY.OVERALL_STATISTICS}{' '}
          {assessmentData?.assessment_name}
        </Typography>
      </Box>
      {/* Assigned & submitted stats starts here */}
      <Box marginTop={3}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={12} lg={4} className="spacing_top_mobile">
            <Box className="green_card">
              <Box display="flex" width={'100%'} height="100%">
                <Box
                  width="100%"
                  display="flex"
                  justifyContent={'center'}
                  flexDirection={'column'}
                  flex="0.8"
                >
                  <AssignmentOutlined className="green_outlined_assignment" />
                  <Typography variant="h6" className="margined_green_text">
                    {configJSON.ASSESSMENT_SUMMARY.TOTAL}
                  </Typography>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent={'flex-end'}
                  alignItems="center"
                  flex="0.2"
                >
                  <Typography variant="h6" className="green_text">
                    <b>{tableData[0]?.total_assigned}</b>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={4} className="spacing_top_mobile">
            <Box className="purple_card">
              <Box display="flex" width={'100%'} height="100%">
                <Box
                  width="100%"
                  display="flex"
                  justifyContent={'center'}
                  flexDirection={'column'}
                  flex="0.8"
                >
                  <AssignmentIndOutlined className="purple_outlined_assignment" />
                  <Typography variant="h6" className="margined_purple_text">
                    {configJSON.ASSESSMENT_SUMMARY.STUDENTS_SUBMITTED}
                  </Typography>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent={'flex-end'}
                  alignItems="center"
                  flex="0.2"
                >
                  <Typography variant="h6" className="purple_text">
                    <b>{tableData[0]?.students_submitted}</b>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={4} className="spacing_top_mobile">
            <Box className="pink_card">
              <Box display="flex" width={'100%'} height="100%">
                <Box
                  width="100%"
                  display="flex"
                  justifyContent={'center'}
                  flexDirection={'column'}
                  flex="0.8"
                >
                  <AssignmentOutlined className="pink_outlined_assignment" />
                  <Typography className="margined_pink_text">
                    {configJSON.ASSESSMENT_SUMMARY.STUDENTS_NOT_SUBMITTED}
                  </Typography>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent={'flex-end'}
                  alignItems="center"
                  flex="0.2"
                >
                  <Typography className="pink_text">
                    <b>{tableData[0]?.student_in_progress}</b>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Assigned & submitted stats ends here */}
      {/* Average stats start here */}
      <Box marginTop={3}>
        <Grid container>
          <Grid item xs={12} md={12} lg={6}>
            <Box display="flex" className="yellow_card">
              <Box marginTop={'25px'} width="100%">
                <Typography className="bold_orange_text">
                  {configJSON.ASSESSMENT_SUMMARY.AVERAGE_SCORE}
                </Typography>
                <Typography className="bold_orange_text">
                  {tableData[0]?.avarage_score}
                </Typography>
              </Box>
              <Box display="flex" justifyContent={'flex-end'}>
                <AssignmentOutlined className="orange_outlined_assignment" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Box display="flex" className="blue_card">
              <Box marginTop={'25px'} width="100%">
                <Typography className="bold_blue_text">
                  {configJSON.ASSESSMENT_SUMMARY.AVERAGE_SCORE_PERCENTAGE}
                </Typography>
                <Typography className="bold_blue_text">
                  {tableData[0]?.avarage_score_parcentage}
                </Typography>
              </Box>
              <Box display="flex" justifyContent={'flex-end'}>
                <AssignmentOutlined className="blue_outlined_assignment" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Average stats end here */}
      {/* Graph starts here */}
      <Box marginTop={3}>
        <Typography variant="h6" className={classes.subTitle_font}>
          {configJSON.ASSESSMENT_SUMMARY.STUDENTS_PERFORMANCE_BANDS}
        </Typography>
        <Box marginTop={3}>
          <Grid container spacing={3} alignItems={'center'}>
            <Grid item xs={12} md={6} lg={8}>
              <Box display="flex" alignItems={'center'} justifyContent="center">
                {data?.data?.datasets[0]?.data?.length === 0 ||
                data?.data?.datasets[0]?.data?.every(
                  (val: any, i: number, arr: any) => val === 0
                ) ? (
                  <Box
                    className={`assessmentSummaryReport_AssessmentTest_main_table`}
                  >
                    <img
                      src={noData}
                      className={`no_data_image`}
                      alt="no data"
                    />

                    <p className={`no_data_title_big`}>
                      {configJSON.ASSESSMENT_REPORTS.NO_DATA_FOUND}
                    </p>
                  </Box>
                ) : (
                  <Box
                    className={
                      classes.AssessmentStandardsReport_TeacherReports_graph_height
                    }
                  >
                    <Doughnut {...data} />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box display="flex" alignItems="center" >
                <Box
                  width="60px"
                  height="60px"
                  borderRadius={'25%'}
                  className="green_box"
                />
                <Typography
                  variant="h6"
                  className={`${classes.subTitle_font} margin_left_20`}
                >
                  {configJSON.ASSESSMENT_SUMMARY.EXCELLENT}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" marginTop={3}>
                <Box
                  width="60px"
                  height="60px"
                  borderRadius={'25%'}
                  className="aqua_box"
                />
                <Typography
                  variant="h6"
                  className={`${classes.subTitle_font} margin_left_20`}
                >
                  {configJSON.ASSESSMENT_SUMMARY.GOOD}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" marginTop={3}>
                <Box
                  width="60px"
                  height="60px"
                  borderRadius={'25%'}
                  className="blue_box"
                />
                <Typography
                  variant="h6"
                  className={`${classes.subTitle_font} margin_left_20`}
                >
                  {configJSON.ASSESSMENT_SUMMARY.SATISFACTORY}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" marginTop={3}>
                <Box
                  width="60px"
                  height="60px"
                  borderRadius={'25%'}
                  className="yellow_box"
                />
                <Typography
                  variant="h6"
                  className={`${classes.subTitle_font} margin_left_20`}
                >
                  {configJSON.ASSESSMENT_SUMMARY.LOW_PASS}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" marginTop={3}>
                <Box
                  width="60px"
                  height="60px"
                  borderRadius={'25%'}
                  className="red_box"
                />
                <Typography
                  variant="h6"
                  className={`${classes.subTitle_font} margin_left_20`}
                >
                  {configJSON.ASSESSMENT_SUMMARY.UNSATISFACTORY}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* Graph ends here */}
      {/* Assignment statistics starts here */}
      <Box marginTop={3}>
        <Typography variant="h6" className={classes.subTitle_font}>
          {configJSON.ASSESSMENT_SUMMARY.OVERALL_STATISTICS}{' '}
          {assessmentData?.assessment_name}
        </Typography>
      </Box>
      <TableContainer className="table_container">
        <Table>
          <TableHead className="table_head">
            <TableRow>
              <TableCell className={`${classes.subHeading_font} table_cell`}>
                {configJSON.ASSESSMENT_SUMMARY.CLASS_NAME}
              </TableCell>
              <TableCell className={`${classes.subHeading_font} table_cell`}>
                {configJSON.ASSESSMENT_SUMMARY.ASSIGNED_ON}
              </TableCell>
              <TableCell className={`${classes.subHeading_font} table_cell`}>
                {configJSON.ASSESSMENT_SUMMARY.DUE_ON}
              </TableCell>
              <TableCell className={`${classes.subHeading_font} table_cell`}>
                {configJSON.ASSESSMENT_SUMMARY.TOTAL}
              </TableCell>
              <TableCell className={`${classes.subHeading_font} table_cell`}>
                {configJSON.ASSESSMENT_SUMMARY.STUDENTS_NOT_STARTED}
              </TableCell>
              <TableCell className={`${classes.subHeading_font} table_cell`}>
                {configJSON.ASSESSMENT_SUMMARY.AVERAGE_STUDENTS_SCORE}
              </TableCell>
              <TableCell className={`${classes.subHeading_font} table_cell`}>
                {configJSON.ASSESSMENT_SUMMARY.MIN_SCORE}
              </TableCell>
              <TableCell className={`${classes.subHeading_font} table_cell`}>
                {configJSON.ASSESSMENT_SUMMARY.MAX_SCORE}
              </TableCell>
              <TableCell className={`${classes.subHeading_font} table_cell`}>
                {configJSON.ASSESSMENT_SUMMARY.AVG_SCORE}
              </TableCell>
              <TableCell className={`${classes.subHeading_font} table_cell`}>
                {configJSON.ASSESSMENT_SUMMARY.STD_DEV}
              </TableCell>
              <TableCell className={`${classes.subHeading_font} table_cell`}>
                {configJSON.ASSESSMENT_SUMMARY.VARIANCE}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{ marginTop: 10, maxHeight: 340, overflow: 'auto' }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    align="center"
                    className={`${classes.bodyText_font} table_body_cell`}
                  >
                    {item?.grade_name}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={`${classes.bodyText_font} table_body_cell`}
                  >
                    {moment(item?.assigned_on).format('MMM DD,YYYY hh:mm A')}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={`${classes.bodyText_font} table_body_cell`}
                  >
                    {moment(item?.due_on).format('MMM DD,YYYY hh:mm A')}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={`${classes.bodyText_font} table_body_cell`}
                  >
                    {item?.total_assigned}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={`${classes.bodyText_font} table_body_cell`}
                  >
                    {item?.student_in_progress}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={`${classes.bodyText_font} table_body_cell`}
                  >
                    {item?.avarage_score_parcentage}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={`${classes.bodyText_font} table_body_cell`}
                  >
                    {item?.min_marks}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={`${classes.bodyText_font} table_body_cell`}
                  >
                    {item?.max_marks}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.bodyText_font} table_body_cell`}
                  >
                    {item?.avarage_score}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.bodyText_font} table_body_cell`}
                  >
                    {item?.deviance_score === null
                      ? '-'
                      : item?.deviance_score.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.bodyText_font} table_body_cell`}
                  >
                    {item?.variance_score === null
                      ? '-'
                      : item?.variance_score.toFixed(2)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Assignment statistics ends here */}
    </div>
  );
  // Customizable Area End
};

// Customizable Area Start
export default AssessmentSummaryReports;
// Customizable Area End
