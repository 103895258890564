// Customizable Area Start
import React from 'react';
import {
  Box,
  Button,
  withStyles,
  Typography,
  Modal,
  Backdrop,
  Grid,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleModalController, { Props } from './ScheduleModalController.web';
import { scheduleModalImg, modalImgOrangeBackground } from '../assets';
import Datetime from 'react-datetime';
import moment from 'moment';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './QuestionBank.web.css';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End
// Customizable Area Start
// Customizable Area End

export class ScheduleModal extends ScheduleModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { classes } = this.props;
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.ScheduleModal_root_modal} modal-schedule-assessment-class`}
          open={this.props.openModal}
          onClose={() => {
            this.props?.handleOnClose(false);
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          id="modal-schedule-assessment"
          data-test-id="assessment-schedule-modal"
        >
          <Box
            className={`schedule_modal Schedule_modal_root_box ${classes.ScheduleModal_root_box}`}
          >
            <Grid container spacing={0}>
              {/* Close Btn */}
              <div className='CloseIconDiv'>
                <IconButton onClick={() => this.props.handleOnClose(false)} data-test-id="schedule-close-icon">
                  <CloseIcon className={classes.ScheduleModal_Close_button} />
                </IconButton>
              </div>
              {/* Close Btn END */}

              {/* Schedule Title */}
              <Grid
                item
                xs={12}
                className={`${classes.ScheduleModal_Schedule_grid} schedule_modal_flex_direction`}
              >
                <Typography className={`kudos_text ${classes.title_font}`}>
                  Schedule
                </Typography>
              </Grid>
              {/* Schedule Title END */}

              {/* Img Icon */}
              <Grid item xs={12} className={`${classes.ScheduleModal_Image_grid} schedule_modal_flex_direction`}>
                <img
                  src={modalImgOrangeBackground}
                  className="schedule_img_orange_bg"
                />
                <img src={scheduleModalImg} className="schedule_modal_img" />
              </Grid>
              {/* Img Icon END */}

              {/* message String */}
              <Grid item xs={12} className={`${classes.ScheduleModal_Public_grid} schedule_modal_flex_direction`}>
                <Typography
                  className={`publish_this_text ${classes.subTitle_font}`}
                >
                  Publish this assessment on
                </Typography>
              </Grid>
              {/* message String END */}

              <Grid
                container
                spacing={5}
                className={classes.ScheduleModal_ContainerGrid}
              >
                {/* Date picker */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={`xs_p_10 ${`${classes.ScheduleModal_Date_container} schedule_modal_flex_direction`}`}
                >
                  <Typography
                    className={`BodyText ${classes.bodyText_font}`}
                  >
                    Select Date
                  </Typography>

                  <Datetime
                    dateFormat={'DD MMMM,YYYY'}
                    timeFormat={false}
                    strictParsing={true}
                    input={true}
                    value={moment(this.state.date, 'DD-MM-YYYY').format(
                      'DD MMMM,YYYY'
                    )}
                    initialValue={moment().format('DD MMMM,YYYY')}
                    className={`date-input-schedule ${classes.input_theme_style}`}
                    inputProps={{
                      placeholder: 'Select Date',
                      className: 'Date_of_birth_calendar_Icon_register',
                      readOnly: true,
                    }}
                    closeOnSelect
                    data-test-id="schedule-date-time"
                    onChange={(date) => {
                      this.setState({
                        date: date,
                      });
                    }}
                  />
                </Grid>
                {/* Date picker END*/}

                {/* Time picker */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={`xs_p_10 ${classes.ScheduleModal_Time_container} schedule_modal_flex_direction`}
                >
                  <Typography
                    className={`${classes.bodyText_font} ${classes.ScheduleModal_Time_title}`}
                  >
                    Select Time
                  </Typography>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      autoOk
                      inputVariant="outlined"
                      placeholder="hh:mm"
                      className={`scheduleTimePicker  ${classes.input_theme_style}`}
                      inputProps={{
                        placeholder: 'Select Time',
                        className: 'time_picker_icon',
                        readOnly: true,
                      }}
                      value={this.state.time}
                      data-test-id="schedule-time-picker"
                      onChange={(e: any) =>
                        this.setState({
                          time: e,
                        })
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {/* Time picker END */}

                {/* Date Error Validation */}
                {this.state.dateError && (
                  <Grid
                    item
                    xs={12}
                    className={`${classes.ScheduleModal_Date_error_container} schedule_modal_flex_direction`}
                  >
                    <FormHelperText className='ErrorText'>
                      DateTime should be greater than "
                      {moment(new Date())
                        .add(10, 'minutes')
                        .format('DD MMMM,YYYY HH:mm a')}
                      "
                    </FormHelperText>
                  </Grid>
                )}
                {/* Date Error Validation END */}
              </Grid>

              {/* Schedule Button */}
              <Grid
                item
                xs={12}
                className={`${classes.ScheduleModal_Submit_container} schedule_modal_flex_direction`}
              >
                <Button
                  className={`go_to_dashboard_btn ${classes.button_color}`}
                  data-test-id="schedule-modal-btn"
                  onClick={() => {
                    // Date Validation
                    let finalDate = moment(
                      moment(this.state.date).format('DD/MM/YYYY') +
                        ' ' +
                        moment(this.state.time).format('HH:mm'),
                      'DD/MM/YYYY HH:mm'
                    );
                    let minDate = moment(new Date()).add(10, 'minutes');
                    let diff = finalDate.diff(minDate, 'minutes');
                    let error = false;

                    if (diff < 0) {
                      error = true;
                      this.setState({
                        dateError: true,
                      });
                    } else {
                      this.setState({
                        dateError: false,
                      });
                      error = false;
                    }
                    // Date Validation END

                    if (!error) {
                      this.props.handleOnSchedule(
                        moment(this.state.date).format('DD/MM/YYYY'),
                        moment(this.state.time).format('HH:mm')
                      );
                    }
                  }}
                >
                  Schedule
                </Button>
              </Grid>
              {/* go to Dashboard Button END */}
            </Grid>
          </Box>
        </Modal>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(ScheduleModal);
// Customizable Area End
