// Customizable Area Start
import React from "react";
import { Box, Button, FormHelperText, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import TeacherAssessmentCreateGuidelineController, {
  Props,
} from "./TeacherAssessmentCreateGuidelineController.web";
import { imgDeleteGray } from "./assets";
import { withStyles } from "@material-ui/core/styles";
import Spinner from "../../shared/SideBar/src/Spinner";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = {
  settingcontainer: {
    backgroundColor: "#3a60d7",
    padding: 10,
    color: "#FFF",
    paddingBottom: 5,
    width: "fit-content",
    marginLeft: "auto",
    cursor: "pointer",
    marginTop: 10,
    marginBottom: 10,
  },
  guidelineSection: {
    borderRadius: "5px",
    background: "#fff",
    padding: " 15px",
  },
  subHeading: {
    fontSize: 24,
    fontWeight: 600,
  },
  guideList: {},
  guideLineBox: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f9f8fd",
    padding: "10px 30px",
    height:60,
    
  },
  guideLineInput: {
    display: "flex",
    alignItems: "center",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: 7,
    "& input": {
      border: "none",
      padding: 15,
      width: "100%",
      "&::-webkit-input-placeholder": {
        /* Edge */
        fontSize: 15,
        color: "red",
      },

      "&:-ms-input-placeholder": {
        /* Internet Explorer */
        fontSize: 15,
        color: "red",
      },
      "&::placeholder": {
        fontSize: 15,
        color: "red",
      },
    },
  },
  inputText: {
    padding: "17px 16px",
    borderRadius: 6,
    backgroundColor: "#fff",
    width: "100%",
    border: "none",
    fontSize: 18,
    fontWeight: 600,
  },

  error: {
    border: "solid 0.5px #ff0000",
    marginBottom:4,
  },
  errorText:{
    color: "red",
    paddingLeft:8,
    marginBottom:16
  },
  checkBoxStyle: {
    marginLeft: 0,
  },
  guidanceDelete: {
    height: 28,
    width: 28,
    objectFit: "contain",
    marginRight: 15,
    cursor: "pointer",
  },
  plusIcon: {
    borderRadius: 5,
    padding: "10px 0px",
    textAlign: "center",
    textDecoration: "none",
    height: 60,
    width: 60,
    marginRight: 10,
    marginTop: 10,
  },
  guidelineNextButton: {
    borderRadius: 5,
    textAlign: "center",
    textDecoration: "none",
    padding: "10px 25px",
    height: 60,
    width:100,
    marginTop: 10,
  },
  emptyBox: {
    padding: 6,
    backgroundColor: "#cac9ce",
  },
  backScreen: {
    cursor: "pointer",
    marginTop: 20,
    marginBottom: 20,
  },
} as const;
// Customizable Area End

export class TeacherAssessmentCreateGuideline extends TeacherAssessmentCreateGuidelineController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { preGuidelineList, customGuidelineList } = this.state;

    return (
      <>
        {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            display="flex"
            alignItems="center"
            style={styles.backScreen}
            onClick={this.handleBack}
          >
            <KeyboardBackspaceIcon className={this.props.classes.icon_color} /> <Typography 
              className={`CreateNewAssessmentText ${this.props.classes.bodyText_font}`}
            >Assessment Guidelines</Typography>
          </Box>

        </Box>
        <Box style={styles.guidelineSection}>
          <Box>

            <Box mt={1} mb={2}>
              <Typography variant="h6" style={styles.subHeading} className={`font-20 ${this.props.classes.heading_font}`}>
                Pre-guidelines :
              </Typography>
            </Box>
          </Box>

          <Box style={styles.guideList} mb={16}>
            {preGuidelineList && preGuidelineList.length > 0 ?
              <>
                {preGuidelineList?.map((guideline: any, index: number) => (
                  <Box style={styles.guideLineBox} my={1} key={guideline?.id}>
                    <Box mr={2} style={styles.emptyBox} />
                    <Box
                      className={`GuidelineDesc ${this.props.classes.bodyText_font}`}>{guideline?.attributes?.description}</Box>
                  </Box>
                ))}
              </> :
              <>
                <Typography 
                  className={`NoAssessmentGuideline ${this.props.classes.subHeading_font}`}
                >
                  No record(s) found.
                </Typography>
              </>
            }

          </Box>

          <Box>
            {customGuidelineList?.map((guideline: any, index: number) => (
              <Box key={guideline?.id}>
                <Box my={2} style={guideline?.error ? { ...styles.guideLineInput, ...styles.error } : { ...styles.guideLineInput }}>
                  <input
                    name="guidelines"
                    style={
                      styles.inputText
                    }
                    value={guideline.description}
                    className={`${this.props.classes.bodyText_font}`}
                    placeholder={`${index + 1}. Enter Guideline`}
                    onChange={(e: any) => this.handleUpdateGuideline(index, e)}
                  />

                  <img src={imgDeleteGray}
                    style={styles.guidanceDelete}
                    onClick={() => this.handleDeleteGuideline(guideline.id)}
                  />
                </Box>
                {guideline?.error && (
                  <FormHelperText style={styles.errorText}>
                    Guideline can't be empty.
                  </FormHelperText>
                )}
              </Box>
            ))}
          </Box>

          <Box>
            <Button style={styles.plusIcon} className={this.props.classes.button_color} onClick={this.handleAddGuideline}>
              <AddIcon />
            </Button>
            <Button
              style={styles.guidelineNextButton}
              onClick={this.onNext}
              className={this.props.classes.button_color}
            >
              Next
            </Button>
          </Box>
        </Box>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(TeacherAssessmentCreateGuideline as React.ComponentType<any>);
// Customizable Area End
