import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showLoader: boolean;
  addedRowItems: Array<any>;
  addedColumnItems: any;
  anchorEl: any;
  columnName: string;
  subColumnCount: number;
  totalMarks: any;
  markingTypeList: any;
  typeOfMarking: any;
  anchorElRowName: any;
  subjectName: string;
  termList: Array<any>;
  totalSubColumn: any;
  selectedSubject: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ScholasticAreaController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostReportCardList: string = "";
// Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      showLoader: false,
      addedRowItems: [
        {
          id: 1,
          name: "Subject 1"
        },
        {
          id: 2,
          name: "Subject 2"
        },
        {
          id: 3,
          name: "Subject 3"
        },
        {
          id: 4,
          name: "Subject 4"
        },
        {
          id: 5,
          name: "Subject 5"
        },
        {
          id: 6,
          name: "Subject 6"
        },
        {
          id: 7,
          name: "Subject 7"
        },],
      addedColumnItems: [],
      anchorEl: null,
      columnName: "",
      subColumnCount: 0,
      totalMarks: "",
      markingTypeList: [],
      typeOfMarking: "",
      anchorElRowName: null,
      subjectName: "",
      termList: [{
        id: 1,
        term: "Term 1"
      },
      {
        id: 2,
        term: "Term 2"
      }
      ],
      totalSubColumn: [],
      selectedSubject: null,
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleBack = () => {
    HISTORY.goBack();
  };

  addTableRow = () => {
    const { addedRowItems } = this.state;
    let item: any = null
    if (addedRowItems?.length > 0) {
      item = {
        id: addedRowItems?.length + 1,
        name: `Subject ${addedRowItems?.length + 1}`,
      };
    } else {
      item = {
        id: 1,
        name: `Subject 1`,
      };
    }

    this.setState({
      addedRowItems: [...this.state.addedRowItems, item]
    });
  }

  addTableColumn = (event: any) => {
    // const { addedColumnItems } = this.state;
    // let item: any = null
    // if (addedColumnItems?.length > 0) {
    //   item = {
    //     name: `Column ${addedColumnItems?.length + 1}`,
    //   };
    // } else {
    //   item = {
    //     name: `Column 1`,
    //   };
    // }

    this.setState({
      // addedColumnItems: [...this.state.addedColumnItems, item],
      anchorEl: event.currentTarget
    });
  }

  checkSaveColumnBtnDisabled = () => {
    return !this.state.columnName
  }

  addSubColumn = () => {
    this.setState({ subColumnCount: this.state.subColumnCount + 1 })
  }

  saveColumnData = () => {
    const { addedColumnItems, subColumnCount } = this.state;
    let subColumn: any = [];
    let itemsArray: any = [];

    for (let i = 0; i < subColumnCount; i++) {
      itemsArray.push(i)
    }
    itemsArray?.map((item: any, index: number) => {
      const sub = {
        id: index + 1,
        name: `Sub-column ${index + 1}`
      }
      subColumn?.push(sub)
    })

    const item = {
      name: this.state.columnName,
      totalMarks: this.state.totalMarks,
      subColumnArray: subColumn,
      subColumnCount: this.state.subColumnCount
    }

    this.setState({
      addedColumnItems: [...addedColumnItems, item],
      anchorEl: null,
      columnName: "",
      totalMarks: "",
      totalSubColumn: [...this.state.totalSubColumn, ...subColumn],
      subColumnCount: 0,
    });
  }

  onChangeColumnName = (event: any) => {
    this.setState({ columnName: event.target.value })
  }

  onChangeTotalMarks = (event: any) => {
    this.setState({ totalMarks: event.target.value })
  }

  onChangeTypeOfMarking = (event: any) => {
    this.setState({ typeOfMarking: event.target.value })
  }

  openEditRowNamePopover = (event: any, item: any) => {
    this.setState({ anchorElRowName: event.currentTarget, subjectName: item?.name, selectedSubject: item });
  }

  onChangeSubjectName = (event: any) => {
    this.setState({ subjectName: event.target.value })
  }

  saveSubjectName = () => {
    const { subjectName, addedRowItems, selectedSubject } = this.state;
    const mappedSubjects = addedRowItems?.map((row: any) => {
      if (Number(row?.id) === Number(selectedSubject?.id)) {
        row.name = subjectName
      }
      return row;
    })

    this.setState({ addedRowItems: mappedSubjects, anchorElRowName: null,subjectName: "", selectedSubject: null  })
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apiPostReportCardList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ showLoader: false });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }


  // Customizable Area End
}
