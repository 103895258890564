import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RefObject } from "react";
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  recentActivities: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class RecentActivityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetRecentActivitiesDetails: string = "";
  readonly inputOpenFileRef : RefObject<HTMLInputElement>
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    this.state = {
    recentActivities: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.getRecentActivities = this.getRecentActivities.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  logoutHandleClick = () => {
    this.props.navigation.navigate('EmailAccountLoginBlock')
    localStorage.removeItem('token');
    localStorage.removeItem('user_data');
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getRecentActivities();
  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }
  getRecentActivities = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetRecentActivitiesDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentRecentActivities
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      switch(apiRequestCallId){
        case this.apiGetRecentActivitiesDetails: {
          if (responseJson != null) {
            if (!responseJson.errors) {
              this.setState({ recentActivities: responseJson.data });
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        break;
        }
      }
    // Customizable Area End  
    }
    // Customizable Area End
  }
