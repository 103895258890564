// Customizable Area Start
import React from "react";
import { KeyboardArrowLeft } from "@material-ui/icons";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@material-ui/core";
import { student_default_profile_pic, country_icon, support_icon, notification_icon, img_font_icon } from "./assets";
import Logout from "../../SideBar/src/Logout";
import { HISTORY } from "../../../../components/src/comman";
import HeaderController, { Props } from "./HeaderController";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import { withStyles } from '@material-ui/core/styles';
import './Header.web.css';
// Customizable Area End
// Customizable Area Start
// Customizable Area End
export class Header extends HeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  render() {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");

    const pagesTitles = [
    {
      url:'/TeacherAssessment',
      title:'Assessment'
    },
    {
      url:'StudentClassesAndSections',
      title:'Class'
    },
    {
      url:'TeacherClassesAndSections',
      title:'Class'
    }
  ]

  const titleToBeUsed = pagesTitles.find((item)=>item.url === window.location.pathname)?.title ?? ''
    const profile_url = () => {
      var profileUrl = "#";
      switch (data.type) {
        case "TeacherAccount":
          profileUrl = "/TeacherProfile";
          break;
        case "StudentAccount":
          profileUrl = "/StudentProfile";
          break;
        default:
          profileUrl = "/StudentProfile";
      }
      localStorage.setItem("selectedMenuId", "0");
      HISTORY.push(profileUrl);
    };
    return (
      <Box className="MobileHeader">
        <Box className='LeftBox'>
          <Typography className="TitleUsed">
            {titleToBeUsed}
          </Typography>
        </Box>
        <Box className={`HeaderRight ${this.state.collapaseHeader ? "ContentSpace" : "ContentEnd"}`}
          onMouseLeave={() => {
            this.setState({
              collapaseHeader: false,
            })
          }}
          >
          {this.state.collapaseHeader && (
            <>
              <Tooltip title="Notification">
              <IconButton className="IconBg">
              <Avatar className="AvtarIcon" src={notification_icon} />
              </IconButton>
              </Tooltip>
              <Tooltip title="Support">
              <IconButton className="IconBg">
                <Avatar className="AvtarIcon" src={support_icon} />
              </IconButton>
              </Tooltip>
              <Tooltip title="Font" >
              <IconButton className="IconBg">
                <Avatar className="AvtarIcon" src={img_font_icon} />
              </IconButton>
              </Tooltip>
              <Tooltip title="Language">
              <IconButton className="IconBg">
                <Avatar className="AvtarIcon" src={country_icon} />
              </IconButton>
              </Tooltip>
            </>
          )}
          <Box className="ProfileBox">
            <IconButton onMouseEnter={() => this.setState({ collapaseHeader: true })}>
              <Box className={`KeyBoardBox ${this.props.classes.button_color}`}>
                {!this.state.collapaseHeader ? (
                  <KeyboardArrowLeft className="WhiteKey" />
                ) : (
                  <KeyboardArrowRightIcon className="WhiteKey"/>
                )}
              </Box>
            </IconButton>
            <div className="OnClickHandle" onClick={this.handleClick}>
              {data.avatar ? (
                <img className="DataAvtar" src={data.avatar ? data.avatar : student_default_profile_pic} />
              ) : (
                <Avatar className="Avatarimg" src={"/"} />
              )}
              <i>
                {this.state.anchorEl ? (
                  <ArrowDropUpIcon color="action" />
                ) : (
                  <ArrowDropDownIcon color="action" />
                )}
              </i>
            </div>
            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              {this.state.data?.type !== "AdminAccount" && <MenuItem
                onClick={() => {
                  profile_url();
                  this.handleClose();
                }}
              >
                Profile
              </MenuItem>}
              <MenuItem
                onClick={() => {
                  this.handleClose();
                  this.setState({ logoutModal: !this.state.logoutModal });
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        {this.state.logoutModal && (
          <Logout
            logoutModal={this.state.logoutModal}
            closeModalFun={this.onCloseModal}
            logoutCloseModalFun={this.onlogoutCloseModal}
            navigation={undefined}
          />
        )}
      </Box>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  Header as React.ComponentType<any>
)
// Customizable Area End