// Customizable Area Start
import React from "react";
import { withStyles, Grid, Typography, Tooltip } from "@material-ui/core";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import "./QuestionBank.web.css";
// Customizable Area End

// Customizable Area Start
interface Iprops {
  question: any;
  index: number;
  classes?: any;
}
// Customizable Area End

// Customizable Area Start
export const QuestionsComponent: React.FC<Iprops> = ({ question, index, classes }) => {
  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <div className="DFlex">
          <span className={`QuestionTypeComponent ${classes.subTitle_font}`}>{`Q${index + 1}.`}</span>
          <span
            dangerouslySetInnerHTML={{
              __html: question?.title,
            }}
            className={`QuestionTypeComponent HTMLContent ${classes.subTitle_font}`}
          ></span>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid className={`QuestionDetails`} container direction="row">
          <Grid item xs={12} sm={6} md={3}>
            <Tooltip
              placement="top"
              title={`${question?.meta_data?.question_sub_category}`}
            >
              <Typography className={`AnswerTypeComponent ${classes.bodyText_font}`}>
                {`Type of Questions: ${question?.meta_data?.question_sub_category}`}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Tooltip
              placement="top"
              title={`${question?.meta_data?.difficulty_level}`}
            >
              <Typography className={`AnswerTypeComponent ${classes.bodyText_font}`}>
                {`| Difficulty Level: ${question?.meta_data?.difficulty_level}`}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Tooltip placement="top" title={`${question?.meta_data?.chapter}`}>
              <Typography className={`AnswerTypeComponent ${classes.bodyText_font}`}>
                {`| Chapter: ${question?.meta_data?.chapter}`}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Tooltip placement="top" title={`${question?.meta_data?.unit}`}>
              <Typography className={`AnswerTypeComponent ${classes.bodyText_font}`}>
                {`| Topic: ${question?.meta_data?.unit}`}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(QuestionsComponent);
// Customizable Area End
