// Customizable Area Start
import React, { useRef } from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { galleryImg1, galleryImg2, galleryImg3 } from "../assets";
import { createRef, RefObject } from "react";
import { toast } from "react-toastify";
import { HISTORY } from "../../../../components/src/comman";
import convert from "xml-js";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  getPreSignedValue?: any;
  hintAnswer?: any;
  hintAnswerOnchange?: any;
  addHintOnClick?: any;
  removeHintOnClick?: any;
  explainationOnchange?: any;
  explainationOnKeyPress?: any;
  explaination: any;
  attachmentExplanationUrl?: any;
  hintsExplanationUrl?: any;
  removeSolutionImage: any;
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  data: any;
  openImageModal: boolean;
  openAlertModal: boolean;
  alertModalMessage: string;
  showLoaderForSolutionImageUpload: boolean;
  questionSolutionImageBlobUrl: string;
  questionSolutionHintsImageBlobUrl: string;
  showLoader: boolean;
  selectedFile: any;
  typeofAttachment: any;

  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class QuestionSolutionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPhotoPreSignedID: string = "";
  apiUploadID: string = "";
  readonly inputFile: RefObject<HTMLInputElement>;
  readonly HintInputFile: RefObject<HTMLInputElement>;
  explanationEditorRef: React.Ref<HTMLInputElement>;
  hintEditorRef: React.Ref<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      data: "",
      showLoader: false,
      openImageModal: false,
      openAlertModal: false,
      alertModalMessage: "",
      showLoaderForSolutionImageUpload: false,
      questionSolutionImageBlobUrl: "",
      questionSolutionHintsImageBlobUrl: "",
      selectedFile: "",
      typeofAttachment: "",
    };
    this.userdata = this.userdata.bind(this);
    this.inputFile = createRef();
    this.HintInputFile = createRef();
    this.explanationEditorRef = createRef();
    this.hintEditorRef = createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.userdata();
    if (this.props.attachmentExplanationUrl) {
      this.setState({
        questionSolutionImageBlobUrl: this.props.attachmentExplanationUrl,
      });
    }
    if (this.props.hintsExplanationUrl) {
      this.setState({
        questionSolutionHintsImageBlobUrl: this.props.hintsExplanationUrl,
      });
    }
  }

  async componentDidUpdate(prevProps: any) {
    if (this.props.attachmentExplanationUrl && this.props.attachmentExplanationUrl !== prevProps.attachmentExplanationUrl) {
      this.setState({
        questionSolutionImageBlobUrl: this.props.attachmentExplanationUrl,
      });
    }
    if (this.props.hintsExplanationUrl && this.props.hintsExplanationUrl !== prevProps.hintsExplanationUrl) {
      this.setState({
        questionSolutionHintsImageBlobUrl: this.props.hintsExplanationUrl,
      });
    }
  }

  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
  };

  handleFileOnChange = async (
    event: React.ChangeEvent<any>,
    filesFromDragAndDrop: any,
    typeofAttachment: string
  ) => {
    this.setState({ showLoaderForSolutionImageUpload: true });
    this.setState({ openImageModal: false });
    let files: any;
    if (filesFromDragAndDrop !== null) {
      files = filesFromDragAndDrop;
    } else {
      files = event.target.files;
    }
    let file = files[0];
    if (files.length >= 1) {
      var blobURL = URL.createObjectURL(file);
      this.setState({ openAlertModal: false });
      if (typeofAttachment === "hints") {
        this.setState({ questionSolutionHintsImageBlobUrl: blobURL });
        this.props.removeSolutionImage("hints");
      } else {
        this.setState({ questionSolutionImageBlobUrl: blobURL });
        this.props.removeSolutionImage("hints");
      }

      const fileDetails = {
        fileName: file.name,
        fileSize: file.size,
        //checksum: md5,
        content_type: file.type,
      };
      this.setState(
        { selectedFile: file, typeofAttachment: typeofAttachment },
        () => {
          this.getPreSignedId(fileDetails, typeofAttachment);
        }
      );
    } else {
      this.setState({ showLoaderForSolutionImageUpload: false });
    }
  };

  getPreSignedId = async (fileUploadDetail: any, typeofAttachment: string) => {
    const header = {
      //"Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };

    let bodyFormData = new FormData();
    bodyFormData.append("content_type", fileUploadDetail.content_type);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );

    this.apiPhotoPreSignedID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      bodyFormData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleOnChangeExplaination = (value: any) => {
    this.props.explainationOnchange(value);
  };

  removeSelectedSolutionImage = (attachmentType: string) => {
    if (attachmentType === "hints") {
      this.setState({ questionSolutionHintsImageBlobUrl: "" });
      this.props.removeSolutionImage("hints");
    } else {
      this.setState({ questionSolutionImageBlobUrl: "" });
      this.props.removeSolutionImage("explanation");
    }
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiPhotoPreSignedID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const imageData = this.state.selectedFile;
            //const uploadMediaAdapter = new BulkUploadMediaAdapter()
            //if (!this.state.bulkUploadInstance) this.setState({ bulkUploadInstance: new BulkUploadMediaAdapter() })
            const msg: Message = new Message(
              getName(MessageEnum.UploadMediaMessage)
            );
            const uploadFileData: any = {
              responseJson: responseJson,
              imageData,
              messageId: msg.messageId,
            };
            msg.addData(
              getName(MessageEnum.UploadMediaDataMessage),
              uploadFileData
            );
            this.apiUploadID = msg.messageId;
            runEngine.sendMessage(msg.id, msg);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        //this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiUploadID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const keyValue = JSON.parse(
              convert.xml2json(responseJson, {
                spaces: 1,
                compact: true,
                ignoreComment: true,
                alwaysChildren: true,
                ignoreDeclaration: true,
              })
            );
            this.props.getPreSignedValue(
              keyValue.PostResponse.Key._text,
              this.state.typeofAttachment,
              this.state.questionSolutionHintsImageBlobUrl
            );
            this.setState({ showLoaderForSolutionImageUpload: false });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }
}
