// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
import { toast } from "react-toastify";
export const configJSON = require("./config.js");

export const validForNumberType = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes?: any;
    setScholasticDetails?: any;
    moduleName?: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    scholasticListData: any;
    isLoader: boolean;
    data: any;
    termLength: any;
    editedScholasticListData: any;
    scholasticDynamicName: string;
    // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
 }

export default class ScholasticAreasController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetModulesName: string = "";
    getScholasticListId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

        this.state = {
            scholasticListData: {},
            isLoader: false,
            data: null,
            termLength: null,
            editedScholasticListData: [],
            scholasticDynamicName: '', 
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.get_scholastic_list_data = this.get_scholastic_list_data.bind(this);
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount()
        // Customizable Area Start
        const studentId = localStorage.getItem("id");
        if (studentId) {
            const user_data = localStorage.getItem("user_data");
            const studentData = JSON.parse(user_data || "{}"); 
            this.get_scholastic_list_data(Number(studentId));
            this.getModulesName();
        }
        // Customizable Area End
    }

    // Customizable Area Start
    subCategoryMarksChange = (e: any, categoryId: any, categoryType: any, subCategoryData: any, termData: any, subjectData: any) => {
        if (categoryType === "assessment" && Number(e.target.value) > Number(subCategoryData.attributes.total_marks)) {
            toast.error(`Maximum marks for this sub category is ${subCategoryData.attributes.total_marks}`)
            return;
        }
        if (categoryType === "task" && Number(e.target.value) > Number(subCategoryData.total_marks)) {
            toast.error(`Maximum marks for this sub category is ${subCategoryData.total_marks}`)
            return;
        }

        let { editedScholasticListData } = this.state;
        const item = {
            category_id: categoryId,
            category_type: categoryType,
            sub_category_id: subCategoryData.id,
            term_id: termData.id,
            subject_id: subjectData.id,
            marks: e.target.value
        }
        const existingScholasticData = editedScholasticListData.find((scholastic: any) => Number(scholastic.sub_category_id) === Number(subCategoryData.id))
        if (existingScholasticData) {
            existingScholasticData.marks = e.target.value
            editedScholasticListData = [...editedScholasticListData.filter((scholastic: any) => Number(scholastic.sub_category_id) !== Number(subCategoryData.id)), existingScholasticData]
        } else {
            editedScholasticListData.push(item)
        }
        const { scholasticListData } = this.state;
        const categories = scholasticListData?.attributes?.report_categories
        const updatedCategories = categories?.map((categoryData: any) => {
            categoryData?.categories?.map((data: any) => {
                if (categoryData.type === "assessment") {
                    data?.sub_category?.data?.map((subData: any) => {
                        if (subData.id === subCategoryData.id) {
                            subData.attributes.marks = e.target.value;
                        }
                        return subData;
                    })
                } else if (categoryData.type === "task") {
                    data?.sub_category?.map((subData: any) => {
                        if (subData.id === subCategoryData.id) {
                            subData.marks = e.target.value;
                        }
                        return subData;
                    })
                }
                return data;
            })
            return categoryData
        })
        scholasticListData.attributes.report_categories = updatedCategories
        this.props.setScholasticDetails(editedScholasticListData)
        this.setState({ scholasticListData: scholasticListData })
    }

    onKeyPressMarks = (e: any) => {
        if (!validForNumberType.includes(e.key) || e?.target?.value.length === 5) {
            e.preventDefault();
            e.stopPropagation();
        }
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.getScholasticListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (responseJson.data != null) {
                            const mainData = responseJson.data;
                            const tempTermData = mainData?.attributes?.terms?.map((termData: any) => {
                                let data: any = termData;
                                let length: number = 0;
                                mainData?.attributes?.report_categories?.map((categoryData: any) => {
                                    categoryData?.categories?.map((categoriesData: any) => {
                                        let categoryLength: number = 0;
                                        if (categoryData.type === "assessment") {
                                            categoryLength += categoriesData?.sub_category?.data?.length
                                            if (categoriesData?.sub_category?.data?.length > 0) {
                                                length += categoriesData?.sub_category?.data?.filter((subData: any) => {
                                                    if (subData.attributes.term_id === termData.id) {
                                                        return subData;
                                                    }
                                                }).length
                                            }
                                        } else {
                                            categoryLength += categoriesData?.sub_category?.length
                                            if (categoriesData?.sub_category?.length > 0) {
                                                length += categoriesData?.sub_category?.filter((subData: any) => {
                                                    if (subData.term_id === termData.id) {
                                                        return subData;
                                                    }
                                                }).length
                                            }
                                        }

                                        if (categoryLength === 0) {
                                            length++;
                                        }
                                    })
                                })

                                data.colSpan = length
                                return data
                            })
                            mainData.attributes.terms = tempTermData
                            this.setState({
                                scholasticListData: mainData,
                                isLoader: false
                            })
                        }
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                    this.setState({ isLoader: false })
                }
                this.parseApiCatchErrorResponse(errorResponse);
            } else if (apiRequestCallId === this.apiGetModulesName) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        responseJson?.map((item: any) => {
                            if (item.type === "scholastic") {
                                this.setState({scholasticDynamicName: item.name})
                            }
                        })
                        this.setState({ isLoader: false });
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ isLoader: false })
                this.parseApiCatchErrorResponse(errorResponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getModulesName = () => {
        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token,
            school: school_Data?.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetModulesName = requestMessage.messageId;

        this.setState({ isLoader: true })

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getModulesNameEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.resultUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    get_scholastic_list_data = (studentId: any) => {
        this.setState({ isLoader: true });
        const user_data = localStorage.getItem("user_data");
        const school_Data = JSON.parse(user_data || "{}");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("token"),
            school: school_Data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getScholasticListId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getScholasticDataEndPoint + `?student_id=${studentId}&school_id=${school_Data.school_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.resultUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}
