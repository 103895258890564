// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import * as SparkMD5 from "spark-md5";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
const uuidv4 = require("uuid/v4");
import convert from "xml-js";

import _ from "lodash";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    unitModal: boolean;
    closeModalFun: any;
    savcloseModalFun: any;
    task: any;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    teacherData: any;
    teacherTimetable: any;
    liveClassDate: string;
    searchResult: boolean;
    getTeacherClasses: any;
    closeModal: boolean;
    class_id: any;
    subject_id: any;
    getTeacherSubjects: any;
    unitHours: any;
    uploadFile: any;
    fileName: string;
    taskName: string;
    duedate: string;
    taskErrorText: string;
    taskError: boolean;
    classerror: boolean;
    subjecterror: boolean;
    timeerror: boolean;
    duedateerror: boolean;
    duedateerrorText: string;
    enddateerror: boolean;
    enddateerrorText: string;
    enddate: string;
    img_upload: boolean;
    unitDescription: string;
    saveAsDraft: boolean;
    tasktime: any;
    unit_name: any;
    uniterror: boolean;
    category: any;
    categoryerror: boolean;
    sharewithstudent: [];
    sharewithteacher: [];
    sharewithparent: boolean;
    class_name: string;
    shareWithData: any;
    AssigneeModal: boolean;
    assignError: boolean;
    assignErrorText: any;
    assign_id: string;
    assign_name: string;
    AddStudentModal: boolean;
    TaskMarks: string;
    shareIds: any;
    teacherChapters: any;
    taskCategories: any;
    showSpinner: boolean;
    chapter_id: any;
    chapterError: boolean;
    is_draft: boolean;
    alertMessage: string;
    alertModal: boolean;
    fileUploadDetail: any;
    sihnIdData: any;
    teacherClass: any;
    teacherUnits: any;
    editModal: boolean;
    openShareModal: boolean;
    classTeacherList: any;
    classStudentList: any;
    userTypeIsTeacher: boolean;
    prevClassId: any;
    prevIds: any;
    typeofAssessment: any;
    typeofAssessmentError: boolean;
    deletedFileId: any;
    marks: string;
    marksError: boolean;
    marksErrorText: string;
    createGradeScale: any;
    grade_name: any;
    unitGradeerror: any;
    gradeNameValue: any;
    gradeError: boolean;
    termList: any;
    selectedTerm: any;
    termError: boolean;
    dueDateCond: any;
    timeErrorMsg: string;
    // Customizable Area End

}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CreateTaskController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    selectGradeScaleAPIId: string = '';
    apigetTeacherSubjectsId: string = "";
    apigetSearchSubjectsId: string = "";
    apigetSubjectRemoveId: string = "";
    apigetTeacherClassesId: string = "";
    apiCreateTaskApiId: string = '';
    apigetTeacherChapterId: string = '';
    apigetTeacherCateogoryId: string = '';
    apiGetTermList: string = '';
    postPresignID: string = '';
    apiUploadBlockId: string = '';
    getTeacherListId: string = '';
    getStudentListId: string = '';
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            teacherTimetable: [],
            teacherData: '',
            liveClassDate: '',
            searchResult: false,
            getTeacherClasses: [],
            closeModal: false,
            class_id: '',
            subject_id: '',
            getTeacherSubjects: [],
            unitHours: '',
            uploadFile: [],
            fileName: '',
            taskName: '',
            duedate: '',
            enddate: '',
            taskError: false,
            taskErrorText: '',
            classerror: false,
            subjecterror: false,
            timeerror: false,
            duedateerror: false,
            duedateerrorText: '',
            enddateerror: false,
            enddateerrorText: '',
            img_upload: false,
            unitDescription: '',
            saveAsDraft: false,
            unit_name: '',
            uniterror: false,
            category: '',
            tasktime: "00:00",
            categoryerror: false,
            sharewithstudent: [],
            sharewithteacher: [],
            sharewithparent: false,
            class_name: '',
            gradeError: false,
            shareWithData: [],
            AssigneeModal: false,
            assignError: false,
            assignErrorText: '',
            assign_name: '',
            assign_id: '',
            AddStudentModal: false,
            TaskMarks: '',
            shareIds: [],
            teacherChapters: [],
            taskCategories: [],
            showSpinner: false,
            chapter_id: '',
            chapterError: false,
            is_draft: false,
            alertMessage: '',
            alertModal: false,
            fileUploadDetail: [],
            sihnIdData: '',
            teacherClass: [],
            teacherUnits: [],
            editModal: false,
            openShareModal: false,
            classTeacherList: [],
            classStudentList: [],
            userTypeIsTeacher: false,
            prevClassId: '',
            prevIds: [],
            typeofAssessment: '',
            typeofAssessmentError: false,
            deletedFileId: '',
            marks: '',
            marksError: false,
            marksErrorText: '',
            createGradeScale: [],
            grade_name: "",
            unitGradeerror: false,
            gradeNameValue: '',
            termList: [],
            selectedTerm: '',
            termError: false,
            dueDateCond: '',
            timeErrorMsg: '',
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.get_userdata = this.get_userdata.bind(this);
        this.get_teacher_classes = this.get_teacher_classes.bind(this);
        this.get_teacher_category = this.get_teacher_category.bind(this);
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.get_userdata();
        this.get_teacher_subjects();
        this.get_teacher_category();
        if (this.props.task != null) {
            this.setState({ showSpinner: true })
        }
    }

    get_userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ teacherData: data });
    }
    onCloseModal = (closeModal: boolean) => {
        this.setState({ AssigneeModal: closeModal })
    }

    handleCloseSharePopup = () => {
        this.setState({ openShareModal: false })
    }

    get_props_data = () => {
        if (this.props.task === null) {
            this.setState({ editModal: false })
        }
        else {
            const { task } = this.props
            const { teacherChapters } = this.state
            const teacherUnit = teacherChapters.filter((res: any) => (parseInt(res.id) === task.attributes.chapter_id))
            let uniqueStudentIds: any = []
            let uniqueTeacherIds: any = []
            let uniqueStudent: any = _.uniqBy(task?.attributes?.student_members, "account_id")
            uniqueStudent.map((_data: any) => uniqueStudentIds.push(_data.account_id))
            let uniqueTeacher: any = _.uniqBy(task?.attributes?.teacher_members, "account_id")
            uniqueTeacher.map((_data: any) => uniqueTeacherIds.push(_data.account_id))
            this.setState({
                editModal: true,
                taskName: task?.attributes?.name,
                sharewithstudent: uniqueStudentIds,
                class_id: task.attributes.grade_id,
                prevClassId: task.attributes.grade_id,
                sharewithteacher: uniqueTeacherIds,
                typeofAssessment: task.attributes.assessment_type,
                category: task.attributes.category.data != null ? task.attributes.category.data.id : '',
                duedate: (moment(task.attributes.due_date).format("DD MMMM, YYYY")),
                unitDescription: task.attributes.description,
                tasktime: `${(moment(task.attributes.due_date).format("HH:mm")).toString()}`,
                marks: task.attributes.marks,
                selectedTerm: task.attributes?.term_id,
                subject_id: task.attributes.subject_id,
                chapter_id: task.attributes.chapter_id,
                unit_name: task.attributes.unit_id,
                teacherUnits: teacherUnit[0].attributes.units.data,
                fileName: task?.attributes?.file?.length != 0 ? task?.attributes?.file[0]?.attributes?.file_name : '',
                fileUploadDetail: task?.attributes?.file?.length != 0 ? task?.attributes?.file : [],
                prevIds: [...task?.attributes?.teacher_members, ...task?.attributes?.student_members],
                deletedFileId: task?.attributes?.file[0]?.id,
            })
            this.get_teacher_classes(task.attributes.subject_id)
            this.get_class_teacher(task.attributes.grade_id);
            this.get_class_student(task.attributes.grade_id);
            this.getTermList(task.attributes.grade_id);


            this.setState({ showSpinner: false })
        }
    }
    onStudentCloseModal = (closeModal: boolean) => {
        this.setState({ AddStudentModal: closeModal })
    }
    assigneeData = (assign_id: any, assign_name: string) => {
        this.setState({ assign_name: assign_name, assign_id: assign_id })
    }
    addMemberFun = (id: any, name: any) => {
        this.setState({ shareIds: id, shareWithData: this.state.shareWithData.concat(name) })
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apigetTeacherSubjectsId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ getTeacherSubjects: responseJson.data })

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showSpinner: false })
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apigetTeacherClassesId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ teacherClass: responseJson.data })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.getTeacherListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ classTeacherList: responseJson?.teachers })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showSpinner: false })
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.getStudentListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        let objectKeys = Object.keys(responseJson.data).map((item: any) => item);
                        let tempStudentList: any = {};
                        objectKeys.map((element: any) => {
                            let classStudents = responseJson.data[element].map((element: any) => {
                                return {
                                    student_id: element.account_id,
                                    first_name: element?.account?.data?.attributes?.first_name,
                                    last_name: element?.account?.data?.attributes?.last_name,
                                    gender: element?.account?.data?.attributes?.gender,
                                    avatar: element?.account?.data?.attributes?.avatar,
                                    email: element?.account?.data?.attributes?.email
                                }
                            });
                            tempStudentList[element] = classStudents;
                        })
                        this.setState({ classStudentList: tempStudentList })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showSpinner: false })
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiCreateTaskApiId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ showSpinner: false })
                        if (this.state.editModal) {
                            toast.success("Task has been edited successfully")
                        }
                        else {
                            toast.success("Task has been added successfully")
                        }
                        this.setState({
                            classTeacherList: [],
                            classStudentList: [],
                            sharewithstudent: [],
                            sharewithteacher: [],
                        })
                        this.props.savcloseModalFun(this.state.closeModal)
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showSpinner: false })
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apigetTeacherChapterId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ teacherChapters: responseJson.data })
                        this.get_props_data()
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apigetTeacherCateogoryId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (this.props.task != null) {
                            const index = responseJson.data.findIndex((res: any) => res.id === this.props.task.attributes.category.data.id)
                            this.setState({
                                gradeNameValue: responseJson.data[index].attributes.grade.grade_name,
                            })
                        }
                        this.setState({ taskCategories: responseJson.data })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.postPresignID) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        const imageData = this.state.fileUploadDetail.file;
                        const msg: Message = new Message(
                            getName(MessageEnum.UploadMediaMessage)
                        );
                        const uploadFileData: any = {
                            responseJson: responseJson,
                            imageData,
                            messageId: msg.messageId,
                        };
                        msg.addData(
                            getName(MessageEnum.UploadMediaDataMessage),
                            uploadFileData
                        );
                        this.apiUploadBlockId = msg.messageId;
                        runEngine.sendMessage(msg.id, msg);
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiUploadBlockId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        const keyValue = JSON.parse(
                            convert.xml2json(responseJson, {
                                spaces: 1,
                                compact: true,
                                ignoreComment: true,
                                alwaysChildren: true,
                                ignoreDeclaration: true,
                            })
                        );
                        this.setState({ showSpinner: false })
                        this.setState({ sihnIdData: keyValue.PostResponse.Key._text })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.selectGradeScaleAPIId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {

                        this.setState({ createGradeScale: responseJson })
                    } else {
                        const key: any = Object.keys(responseJson.errors[0])
                        toast.error(`${responseJson.errors[0][key]}`)
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ showSpinner: false })
            } if (apiRequestCallId === this.apiGetTermList) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ termList: responseJson.data })
                    } else {
                        const key: any = Object.keys(responseJson.errors[0])
                        toast.error(`${responseJson.errors[0][key]}`)
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ showSpinner: false })
            }
        }
    }

    selectGradeScale = () => {
        this.setState({ showSpinner: true })
        const school_data = localStorage.getItem("user_data");
        const Schooldata = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": Schooldata.school_id,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.selectGradeScaleAPIId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.resultUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.selectGradeScale
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.closeModalFun(this.state.closeModal)
    }
    get_teacher_classes = (id: any) => {
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetTeacherClassesId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTeacherGrades + `?subject_id=${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    get_teacher_subjects = () => {
        this.setState({ showSpinner: true })
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetTeacherSubjectsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTeacherSubjectsEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    get_class_teacher = (id: any) => {
        this.setState({ showSpinner: true })
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": data.school_id,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTeacherListId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.classTeachersEndPoint + `?grade_id=${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    get_class_student = (id: any) => {
        this.setState({ showSpinner: true })
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getStudentListId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.gradeStudentEndPoint + `?grade_id=${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    get_teacher_Chapter = (subject_id: any) => {
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetTeacherChapterId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTeacherChapterEndPoint + `?subject_id=${subject_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    get_teacher_category = () => {
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetTeacherCateogoryId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.task_category_list + "?type=task"
            // configJSON.categoryEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.resultUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    getTermList = (grade_id: number) => {
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetTermList = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.termListEndPoint + `?grade_id=${grade_id}&per=100&school_id=${data.school_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }



    saveImageUsingURL = async (preSignedResponse: any) => {
        const uploadURL = preSignedResponse.url.replace(/\/+$/, "");
        const urlFields = preSignedResponse.url_fields;
        if (uploadURL != null && uploadURL !== undefined) {
            const fileKey = urlFields.key.replace("${filename}", this.state.fileUploadDetail.fileName);
            const formData = new FormData();
            formData.append("key", fileKey);
            formData.append(
                "success_action_status",
                urlFields.success_action_status
            );
            formData.append("Content-Type", urlFields["Content-Type"]);
            formData.append(
                "Content-Disposition",
                urlFields["Content-Disposition"]
            );
            formData.append("policy", urlFields.policy);
            formData.append("x-amz-credential", urlFields["x-amz-credential"]);
            formData.append("x-amz-algorithm", urlFields["x-amz-algorithm"]);
            formData.append("x-amz-date", urlFields["x-amz-date"]);
            formData.append("x-amz-signature", urlFields["x-amz-signature"]);
            formData.append("file", this.state.fileUploadDetail.file);
            const s3Response = await axios({
                method: "post",
                url: uploadURL,
                data: formData,
            });
            if (s3Response.status === 201) {
                const keyValue = JSON.parse(
                    convert.xml2json(s3Response.data, {
                        spaces: 1,
                        compact: true,
                        ignoreComment: true,
                        alwaysChildren: true,
                        ignoreDeclaration: true,
                    })
                );
                this.setState({ showSpinner: false })
                this.setState({ sihnIdData: keyValue.PostResponse.Key._text })
            }
        }
    }

    computeChecksumMd5 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const chunkSize = 2097152;
            const spark = new SparkMD5.ArrayBuffer();
            const fileReader = new FileReader();
            let cursor = 0;
            fileReader.onerror = function (): void {
                reject('MD5 computation failed - error reading the file');
            };
            function processChunk(chunk_start: number): void {
                const chunk_end = Math.min(file.size, chunk_start + chunkSize);
                fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
            }
            fileReader.onload = function (e: any): void {
                spark.append(e.target.result);
                cursor += chunkSize;

                if (cursor < file.size) {
                    processChunk(cursor);
                } else {
                    resolve(btoa(spark.end(true)));
                }
            };

            processChunk(0);
        });
    }

    getPreSignedId = () => {
        const { fileUploadDetail } = this.state;
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            token: localStorage.getItem('token'),
            "school": data.school_id,
        };
        let formData = new FormData();
        formData.append('content_type', fileUploadDetail.content_type)

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)

        );

        this.postPresignID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fileUploadS3
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.SignIDUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    saveSelectedUsersId = (list: any) => {
        if (this.state.userTypeIsTeacher) {
            this.setState({ sharewithteacher: list })
        } else {
            this.setState({ sharewithstudent: list })
        }
        this.setState({ openShareModal: false, userTypeIsTeacher: false })
    }

    handleUpload = (files: any) => {
        let file: any = []
        let fileUpload: any = {};
        let allowUpload: boolean = true;
        const validFileType = [
            "image/png",
            "image/jpg",
            "image/jpeg",
            "image/gif",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];
        file = files[0];
        fileUpload = {
            id: uuidv4(),
            fileName: file.name,
            name: file.name.split('.')[0],
            fileSize: file.size,
            file: file,
            content_type: file.type
        };
        this.setState({
            fileName: file.name,
        })
        if (fileUpload.fileSize > 524288000) {
            allowUpload = false;
            this.setState({
                alertMessage: "Maximum files size: 500MB",
                alertModal: true,
            });
        }
        if (!validFileType.includes(fileUpload.content_type)) {
            allowUpload = false;
            this.setState({
                alertMessage:
                    "Please upload valid file format:.png, .jpeg, .jpg, .doc, .pdf, MS Excel google sheets",
                alertModal: true,
            });
        }
        if (allowUpload) {
            this.setState({ showSpinner: true })
            this.setState({ fileUploadDetail: fileUpload }, () => {
                this.getPreSignedId()
            })
        }
    }

    createTask = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { taskName, marks, selectedTerm, unit_name, class_id, chapter_id, category, subject_id, tasktime, duedate, uploadFile, unitDescription, typeofAssessment } = this.state;

        if (taskName.length === 0 || class_id.length === 0 || this.state.unit_name.length === 0 || this.state.chapter_id.length === 0 || subject_id.length === 0 || category.length === 0 || duedate.length === 0 || tasktime.length === 0 || typeofAssessment.length === 0 || marks.length === 0) {
            if (
                this.state.taskName === null ||
                this.state.taskName.length === 0
            ) {
                this.setState({ taskError: true, taskErrorText: 'Task name is required' });

            }
            if (
                this.state.marks === null ||
                this.state.marks.length === 0
            ) {
                this.setState({ marksError: true, marksErrorText: 'Marks is required' });

            }
            if (
                this.state.duedate === null ||
                this.state.duedate.length === 0
            ) {
                this.setState({
                    duedateerror: true,
                    duedateerrorText: "Please select Due date.",
                });
            }
            if (
                this.state.tasktime === null ||
                this.state.tasktime.length === 0
            ) {
                this.setState({ timeerror: true });
            }
            if (this.state.class_id === null || this.state.class_id.length === 0) {
                this.setState({
                    classerror: true,
                });
            }
            if (this.state.subject_id === null || this.state.subject_id.length === 0) {
                this.setState({
                    subjecterror: true,
                });
            }
            if (
                this.state.category === null ||
                this.state.category.length === 0
            ) {
                this.setState({ categoryerror: true });
            }

            if (
                this.state.chapter_id === null ||
                this.state.chapter_id.length === 0
            ) {
                this.setState({ chapterError: true });
            }
            if (
                this.state.unit_name === null ||
                this.state.unit_name.length === 0
            ) {
                this.setState({ uniterror: true });
            }
            if (
                typeofAssessment === null ||
                typeofAssessment.length === 0
            ) {
                this.setState({ typeofAssessmentError: true });
            }

            if (selectedTerm === '') {
                this.setState({
                    termError: true
                })
            }

            return false;
        }
        this.setState({ showSpinner: true })
        let shareData: any = []
        this.state.sharewithteacher.map((_data: any) => {
            shareData.push({
                account_id: _data,
                account_type: "teacher",
            })
        })
        this.state.sharewithstudent.map((_data: any) => {
            shareData.push({
                account_id: _data,
                account_type: "student",
            })
        })
        const data: any = {
            name: taskName,
            due_date: duedate + ' ' + tasktime,
            subject_id: subject_id,
            grade_id: class_id,
            marks: marks,
            term_id: selectedTerm,
            created_by: localStorage.getItem('id'),
            task_category_id: parseInt(category),
            description: unitDescription.trim(),
            unit_id: unit_name,
            chapter_id: chapter_id,
            task_members_attributes: shareData,
            assessment_type: typeofAssessment,

        }
        if (this.state.sihnIdData !== '') {
            data.files = [this.state.sihnIdData]
        }
        else {
            data.files = []
        }
        const school_data = localStorage.getItem("user_data");
        const Schooldata = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": Schooldata.school_id,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCreateTaskApiId = requestMessage.messageId;

        const httpBody = data;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createTaskEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    editTask = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { taskName, marks, selectedTerm, unit_name, class_id, chapter_id, category, subject_id, tasktime, duedate, uploadFile, unitDescription, typeofAssessment } = this.state;

        if (taskName.length === 0 || class_id.length === 0 || this.state.unit_name.length === 0 || this.state.chapter_id.length === 0 || subject_id.length === 0 || category.length === 0 || duedate.length === 0 || tasktime.length === 0 || typeofAssessment.length === 0) {
            if (
                this.state.taskName === null ||
                this.state.taskName.length === 0
            ) {
                this.setState({ taskError: true, taskErrorText: 'Task name is required' });

            }

            if (
                this.state.marks === null ||
                this.state.marks.length === 0
            ) {
                this.setState({ marksError: true, marksErrorText: 'Marks is required' });

            }

            if (
                this.state.duedate === null ||
                this.state.duedate.length === 0
            ) {
                this.setState({
                    duedateerror: true,
                    duedateerrorText: "Please select Due date.",
                });
            }
            if (
                this.state.tasktime === null ||
                this.state.tasktime.length === 0
            ) {
                this.setState({ timeerror: true });
            }
            if (this.state.class_id === null || this.state.class_id.length === 0) {
                this.setState({
                    classerror: true,
                });
            }
            if (this.state.subject_id === null || this.state.subject_id.length === 0) {
                this.setState({
                    subjecterror: true,
                });
            }
            if (
                this.state.category === null ||
                this.state.category.length === 0
            ) {
                this.setState({ categoryerror: true });
            }

            if (
                this.state.chapter_id === null ||
                this.state.chapter_id.length === 0
            ) {
                this.setState({ chapterError: true });
            }
            if (
                this.state.unit_name === null ||
                this.state.unit_name.length === 0
            ) {
                this.setState({ uniterror: true });
            }
            if (
                typeofAssessment === null ||
                typeofAssessment.length === 0
            ) {
                this.setState({ typeofAssessmentError: true });
            }

            if (
                selectedTerm === ''
            ) {
                this.setState({ termError: true });
            }
            return false;
        }
        this.setState({ showSpinner: true })
        let shareData: any = []
        this.state.sharewithteacher.map((_data: any) => {
            shareData.push({
                account_id: _data,
                account_type: "teacher",
            })
        })
        this.state.sharewithstudent.map((_data: any) => {
            shareData.push({
                account_id: _data,
                account_type: "student",
            })
        })
        this.state.prevIds.map((_data: any) => {
            shareData.push({
                id: _data.id,
                _destroy: true,
            })
        })

        const data: any = {
            name: taskName,
            due_date: duedate + '&nbsp' + tasktime,
            subject_id: subject_id,
            grade_id: class_id,
            marks: marks,
            term_id: selectedTerm,
            created_by: localStorage.getItem('id'),
            task_category_id: category,
            description: unitDescription.trim(),
            unit_id: unit_name,
            chapter_id: chapter_id,
            task_members_attributes: shareData,
            assessment_type: typeofAssessment
        }
        if (this.state.deletedFileId !== '') {
            data.old_upload_id = parseInt(this.state.deletedFileId)
        }
        if (this.state.sihnIdData) {
            data.files = [this.state.sihnIdData]

        }
        const school_data = localStorage.getItem("user_data");
        const Schooldata = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": Schooldata.school_id,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCreateTaskApiId = requestMessage.messageId;

        const httpBody = data;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createTaskEndPoint + `/${this.props.task.id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.EditMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    // Customizable Area End
}
