// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
import _ from "lodash";

export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export interface SectionListItem {
    id: number;
    name: string;
    section_marks: any;
    questions: any;
    question_page_no: number;
    question_per_page: number;
    meta_data: any;
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    sectionList: Array<SectionListItem>;
    sectionListMeta: any;
    showLoader: boolean;
    selectedSectionId: number;
    openSubmitModal: boolean;
    showSubmitSection: boolean;
    previewImageModal: boolean;
    previewImageSrc: any;
    assessmentId: any;
    sectionId: number;
    sectionPageNum: number;
    sectionPerPage: number;
    questionListMeta: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class StudentAssessmentAnswersheetController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetSectionList: string = "";
    apiGetSectionQuestionsList: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            sectionList: [],
            sectionListMeta: {},
            showLoader: false,
            selectedSectionId: 0,
            openSubmitModal: false,
            showSubmitSection: false,
            previewImageModal: false,
            previewImageSrc: "",
            assessmentId: "",
            sectionId: 0,
            sectionPageNum: 1,
            sectionPerPage: 15,
            questionListMeta: {},
        }

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apiGetSectionList) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        const sectionArray: any = []
                        responseJson?.data?.sections?.map((item: any) => {
                           const updatedItem = {
                            id: item?.id,
                            name: item?.name,
                            section_marks: item?.section_marks,
                            questions: item?.questions,
                            question_page_no: 1,
                            question_per_page: 5
                          }
                          sectionArray.push(updatedItem)
                        })
                       
                        if (this.state.sectionPageNum === 1) {
                            this.setState({
                                sectionList: sectionArray,
                            })
                        } else {
                            this.setState({
                                sectionList: [...this.state.sectionList, ...sectionArray],
                            })
                        }
                        this.setState({                         
                            sectionListMeta: responseJson?.meta,
                        })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showLoader: false })
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiGetSectionQuestionsList) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        const { sectionList, sectionId } = this.state;
                        sectionList.map((sectionItem: any) => {
                            if (sectionItem.id === sectionId) {
                                if (sectionItem.question_page_no === 1) {
                                    sectionItem.questions = responseJson?.data?.questions                   
                                } else {
                                    sectionItem.questions = [...sectionItem.questions, ...responseJson?.data?.questions]
                                }
                                if(responseJson?.meta?.pagination.next_page) {
                                    sectionItem.question_page_no = sectionItem.question_page_no + 1
                                }
                                sectionItem.meta_data = responseJson?.meta
                            }

                            return sectionItem;
                        })

                        this.setState({
                            sectionList: sectionList,
                            questionListMeta: responseJson?.meta,
                        })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showLoader: false })
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const { assessmentId } = HISTORY?.location?.state;
        if (assessmentId) {
            this.setState({ assessmentId: assessmentId }, () => this.getSectionList())
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleBack = () => {
        HISTORY.goBack();
    };

    sectionArrowClick = (sectionId: number) => {
        if (sectionId !== this.state.selectedSectionId) {
            this.setState({ selectedSectionId: sectionId })
        } else {
            this.setState({ selectedSectionId: 0 })
        }
    }

    onClickRaiseQuery = () => {      
        HISTORY.push({
            pathname: "/StudentAssessmentQueryResolution",
            state: { assessmentId: this.state.assessmentId },
          });
    }

    previewImageModalClose = () => {
        this.setState({ previewImageModal: false })
    }

    setPreviewImageSrc = (src: any) => {
        this.setState({ previewImageSrc: src, previewImageModal: true })
    }

    sectionClick = (id: number) => {
        this.setState({ sectionId: id },
            () => {
                this.getSectionQuestionsList()
            })
    }

    handleSectionLoadMore = () => {
        this.setState({ sectionPageNum: this.state.sectionPageNum + 1 },
            () => this.getSectionList())
    }

    getSectionList = () => {
        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem('user_data');
        const user_id = localStorage.getItem('id');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token,
            school: school_Data?.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetSectionList = requestMessage.messageId;

        const { assessmentId, sectionPageNum, sectionPerPage } = this.state;
       
        let apiEndPoint = configJSON.getAssessmentSectionEndPoint + `?student_id=${Number(user_id)}&assessment_id=${assessmentId}&page=${sectionPageNum}&per=${sectionPerPage}`
        this.setState({ showLoader: true })

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getSectionQuestionsList = () => {
        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const user_id = localStorage.getItem('id');
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token,
            school: school_Data?.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetSectionQuestionsList = requestMessage.messageId;

        const { sectionList, sectionId } = this.state;
        let pageNo: number = 0;
        let perPage: number = 0;
        sectionList.map((item: any) => {
            if(item.id === sectionId) {
                pageNo = item.question_page_no;
                perPage = item.question_per_page;
            }
            return item;
        }) 
        
        let apiEndPoint = configJSON.getAssessmentSectionQuestionEndPoint + `/${sectionId}/get_evalution_section_questions?student_id=${Number(user_id)}&page=${pageNo}&per=${perPage}`
        this.setState({ showLoader: true })

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // Customizable Area End
}
