// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { createRef, RefObject } from "react";
import { toast } from "react-toastify";
import ItemAvailability from "../../../../ItemAvailability/src/ItemAvailability";
import {
  amazing,
  amazingSelected,
  audio,
  bad,
  badSelected,
  conectivity,
  okay,
  okaySelected,
  teacher,
  vedio,
} from "../../assets";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  title?: string;
  openModal: boolean;
  onCloseModal: any;
  deleteClick?: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  data: any;
  showLoader: boolean;
  openSecondFeedbackForm: boolean;
  openSuccessPopup: boolean
  feedbackResponse: string;
  feedbackOptions: any;
  secondFeedbackQuestions: any;
  formInput: string;
  feedBackError: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MeetingFeedbackController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitFeedback: string = "";
  getQuestionsListId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      data: "",
      showLoader: false,
      openSecondFeedbackForm: false,
      openSuccessPopup: false,
      feedbackResponse: "Amazing",
      formInput: "",
      feedBackError: false,
      feedbackOptions: [
        //add objects array for options
        { response: "Amazing", img: amazing, imgSelected: amazingSelected },
        { response: "Okay", img: okay, imgSelected: okaySelected },
        { response: "Bad", img: bad, imgSelected: badSelected },
      ],
      secondFeedbackQuestions: [],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.userdata = this.userdata.bind(this);
    // this.submit_feedback = this.submit_feedback.bind(this);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.get_question_list()
    // this.submit_feedback();
  }

  handleSuccessToggle = () => {
    this.setState({ openSuccessPopup: !this.state.openSuccessPopup })
  }

  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
  };


  handleSelectAnswer = (index: number, option: string) => {
    const newData = this.state.secondFeedbackQuestions.map(
      (item: any, i: number) => {
        if (i == index) {
          item.chooseActive = option;
        }
        return item;
      }
    );
    console.log("newData", newData)
    this.setState({ secondFeedbackQuestions: newData });
  };

  userID = localStorage.getItem("id");
  userId = JSON.parse(this.userID || "number");
  userDATA = localStorage.getItem("user_data");
  userData = JSON.parse(this.userDATA || "{}");


  handleSubmit = (e: any) => {
    if (this.state.feedbackResponse === "Bad") {
      this.props.onCloseModal();
      this.setState({
        openSecondFeedbackForm: !this.state.openSecondFeedbackForm,
      });
    }
    if (
      this.state.feedbackResponse === "Amazing" ||
      this.state.feedbackResponse == "Okay"
    ) {
      const meeting = localStorage.getItem("meeting-detail");
      const meetingDetails = JSON.parse(meeting || "{}")
      const submitfeedbackResponse: any = {};
      const finalFeedbackResponse: any = {}
      submitfeedbackResponse["account_id"] = this.userId;
      submitfeedbackResponse["reviewable_type"] = "BxBlockScheduling::Meeting";
      submitfeedbackResponse["reviewable_id"] = parseInt(meetingDetails?.meetingId)
      submitfeedbackResponse["review_experience"] = this.state.feedbackResponse;
      submitfeedbackResponse["school_id"] = this.userData.school_id;
      finalFeedbackResponse.data = {
        attributes: { ...submitfeedbackResponse }
      }

      this.submit_feedback(finalFeedbackResponse)
      // toast.info("form submitted");
      this.props.onCloseModal();
    }
  };

  handleSecondSubmit = () => {
    const finalFeedbackResponse: any = {}
    const submitfeedbackResponse: any = {};
    const meeting = localStorage.getItem("meeting-detail");
    const meetingDetails = JSON.parse(meeting || "{}")
    const questionsResponse: any = {};
    this.state.secondFeedbackQuestions.map((item: any, index: number) => {

      questionsResponse[item.question] = item.chooseActive;
    });
    const filterArray = this.state.secondFeedbackQuestions.filter((res: any) => res.chooseActive != null)
    if (this.state.formInput === '' && filterArray.length == 0) {
      this.setState({
        feedBackError: true
      })
      return false
    }
    submitfeedbackResponse["comment"] = this.state.formInput;
    submitfeedbackResponse["reviewable_type"] = "BxBlockScheduling::Meeting";
    submitfeedbackResponse["reviewable_id"] = parseInt(meetingDetails?.meetingId)
    submitfeedbackResponse["account_id"] = this.userId;
    submitfeedbackResponse["review_experience"] = "Bad";
    submitfeedbackResponse["school_id"] = this.userData.school_id;
    submitfeedbackResponse["review_answers"] = questionsResponse;

    finalFeedbackResponse.data = {
      attributes: { ...submitfeedbackResponse }
    }

    this.submit_feedback(finalFeedbackResponse)
    // toast.info("form submitted");
    this.props.onCloseModal();

  };

  get_question_list = () => {
    this.setState({ showLoader: true });
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("token"),
      school: school_Data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionsListId = requestMessage.messageId;
    let apiEndPoint =
      configJSON.getQuestion;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.submitFeedback) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            localStorage.removeItem("meeting-detail");
            this.handleSuccessToggle();
          } else {
            this.parseApiErrorResponse(responseJson);
            toast.error("Meeting feedback could not be submitted")
          }
        }
        this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getQuestionsListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            if (responseJson?.data.length > 0) {
              let feedbackQuestionList: any = []
              responseJson.data.map((item: any) => {
                feedbackQuestionList.push({
                  img: audio,
                  question: item.question,
                  chooseActive: null,
                })
              })
              this.setState({ secondFeedbackQuestions: [...feedbackQuestionList] });
            } else {
              this.setState({ secondFeedbackQuestions: [] })
            }
            // this.handleSetClass(responseJson?.data[0])
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showLoader: false });
      }
    }
  }

  submit_feedback = (finalFeedbackResponse: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("token"),
      school: this.userData?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitFeedback = requestMessage.messageId;

    let endPoint = configJSON.submitFeedbackApiEndpoint;

    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(finalFeedbackResponse)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
