// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Typography
} from "@material-ui/core";
import {
    edit_note_img
} from './assets';
import { withStyles } from '@material-ui/core/styles';
import ViewAllNotesModalController, { Props } from "./ViewAllNotesModalController.web";
import moment from "moment";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CreateNotes from './CreateNotes.web';
import './TeacherDashboard.web.css';
import EditNote from "./EditNote.web";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

export class ViewAllNotesModal extends ViewAllNotesModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    open={this.props.allNotesModal}
                    onClose={this.modalhandleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableAutoFocus={true}
                >
                    <Fade in={this.props.allNotesModal} style={{ border: "none" }}>
                        <div className="notepopup">
                            <Box style={{ height: "100%", }}>
                                <Box style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    borderBottom: "0.3px solid #979797",
                                    justifyContent: "space-between",
                                    height: "45px"
                                }}>
                                    <Box>
                                        <Typography
                                            style={{
                                                textTransform: "none",
                                                fontSize: 24,
                                                fontFamily: "Open Sans",
                                                fontWeight: "bold",
                                                color: "#3f526d",
                                                margin: "0 0px 26px 0px"
                                            }}
                                            className={this.props.classes.title_font}
                                        >
                                            Notes
                                        </Typography>
                                    </Box>
                                    {this.props?.role!=="parent"&&<Box>
                                        <img
                                            src={edit_note_img}
                                            alt="edit Note"
                                            style={{
                                                width: 30,
                                                height: 30,
                                                margin: "0 0px 26px 0px",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => { this.setState({ createNoteModal: !this.state.createNoteModal }) }} />
                                    </Box>}
                                </Box>
                                {this.state.createNoteModal && <CreateNotes createNoteModal={this.state.createNoteModal} closeModalFun={this.onCloseModal} />}
                                <Box style={{ height: "78%", overflowX: 'hidden', overflowY: "scroll", marginTop: "7px" }}>
                                    {this.state.teacherNotes?.map((teacherNote: any, index: any) => (
                                        <>
                                            <Box
                                                mt="12px"
                                                mr="10px"
                                                display="flex"
                                                flexDirection="column"
                                                bgcolor={index === 0 ? "#ffeeca" : (index % 3 === 0 ? "#ffeeca" : (index % 2 != 0 ? "#caf2ff" : (index % 2 === 0 && "#e6e2ff")))}
                                                borderRadius="20px"
                                                height="auto"
                                                padding="16px 23px 23px 23px"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    this.setState({ notes_id: teacherNote.id, editNoteModal: !this.state.editNoteModal })
                                                }}
                                            >
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: 16,
                                                            fontWeight: 600,
                                                        }}
                                                        className={this.props.classes.subHeading_font}
                                                    >
                                                        {teacherNote.attributes.title}
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            fontSize: 12,
                                                            fontWeight: "normal",
                                                        }}
                                                        className={this.props.classes.subHeading_font}

                                                    >
                                                        {moment(teacherNote.attributes.updated_at).format('DD MMM,YYYY')}
                                                    </Typography>
                                                </Box>

                                                <Typography
                                                    style={{
                                                        fontSize: 14,
                                                        fontWeight: "normal",
                                                        marginTop: 14,
                                                        overflow: "hidden",

                                                    }}
                                                    className={this.props.classes.bodyText_font}
                                                >
                                                    {teacherNote.attributes.description}
                                                </Typography>
                                            </Box>
                                            {this.state.editNoteModal && this.state.notes_id === teacherNote.id && <EditNote notes_id={teacherNote.id} heading={teacherNote.attributes.title} description={teacherNote.attributes.description} openModal={this.state.editNoteModal} closeModalFun={this.onEditCloseModal} role="parent" />}
                                        </>
                                    ))}
                                </Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textAlign: "center",
                                        borderRadius: "12px",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={this.modalhandleClose}
                                        style={{
                                            backgroundColor: "transparent",
                                            borderRadius: 3,
                                            color: "black",
                                            fontSize: 14,
                                            fontWeight: 600,
                                            letterSpacing: "0.1px",
                                            boxShadow: "none",
                                            padding: "13px 33px 13px 35px",
                                            marginRight: '10px'
                                        }}>
                                        Cancel
                                    </Button>

                                </Box>
                            </Box>
                        </div>
                    </Fade>
                </Modal>
            </>
        )
        // Customizable Area End
    }

}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
    ViewAllNotesModal as React.ComponentType<any>
)
// Customizable Area End