// Customizable Area Start
import React, { useState } from "react";
import {
    Grid,
    Modal,
    Button,
    Box,
    FormHelperText,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
const titleText = {
    fontSize: "22px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: 'center',
    marginBottom: '14px',
} as const;
const cancelText = {
    fontFamily: "Open sans",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: 'center',
} as const;
const saveText = {
    fontFamily: "Open sans",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: 'center',
    color: '#fff'
} as const;
const cancelButton = {
    margin: '0 20px 0 18px',
    padding: '13px 34px',
    borderRadius: '8px',
    background: 'white',
    textTransform: 'initial',
} as const;
const saveButton = {
    margin: '0 0px 0 20px',
    padding: '13px 42px',
    borderRadius: '8px',
    textTransform: 'initial',
} as const;

const inputText = {
    padding: "14px 16px",
    borderRadius: 5,
    backgroundColor: "#f9f8fd",
    width: "100%",
    border: "none",
    fontSize: 16,
    fontWeight: 600,
    height: 58,
} as const;
const error = {
    border: "solid 0.5px #ff0000",
} as const;
// Customizable Area End

interface Props {
    // Customizable Area Start
    classes: any;
    isModalOpen: boolean;
    onCloseModal: any;
    onClickOk: any;
    onClickCancel: any;
    // Customizable Area End
}

export const SetAudioFileName = ({
    // Customizable Area Start
    classes,
    isModalOpen,
    onCloseModal,
    onClickOk,
    onClickCancel,
    // Customizable Area End
}: Props) => {
    // Customizable Area Start
    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<boolean>(false)

    const handleNameChange = (event: any) => {
        setName(event.target.value);
        if (event.target.value === '') {
            setNameError(true);
        } else {
            setNameError(false);
        }
    }

    const handleOkClick = () => {
        if (name === '') {
            setNameError(true);
            return false;
        }

        onClickOk(name);
        setName('');
    }

    const handleCancelClick = () => {
        setName('');
        onClickCancel();
    }

    return (
        <>
            <Modal
                open={isModalOpen}
                onClose={(_, reason) => {
                    reason !== 'backdropClick' ? onCloseModal : null;
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid className="question_modal_main_div">
                    <div style={titleText} className={classes.title_font}>Save Recording</div>
                    <Box className="audio_file_name_input_div">
                        <input
                            style={
                                nameError
                                    ? { ...inputText, ...error }
                                    : { ...inputText }
                            }
                            type="text"
                            name="title"
                            value={name}
                            placeholder="File Name"
                            className={classes.bodyText_font}
                            onChange={handleNameChange}
                        />
                        {nameError && (
                            <FormHelperText className="red_text">
                                Please enter file name
                            </FormHelperText>
                        )}
                    </Box>
                    <Grid className="audio_file_name_action">
                        <Button variant="contained" className={classes.button_color} style={saveButton} onClick={handleOkClick}><span style={saveText}>Save</span></Button>
                        <Button variant="outlined" style={cancelButton} onClick={handleCancelClick}><span className={classes.icon_button_color} style={cancelText}>Cancel</span></Button>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
    // Customizable Area End
};
// Customizable Area Start
export default SetAudioFileName;
// Customizable Area End
