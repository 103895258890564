// Customizable Area Start
import React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import StudentTaskUpload from "./StudentTaskUpload.web";
import ReactPaginate from "react-paginate";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./StudentTaskUnit.web.css";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { Avatar, Box, Button, Link, Typography, Divider } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { image_upload, file_download, docICon, xlsIcon } from "./assets";
import { withStyles } from "@material-ui/core/styles";
import StudentTaskUnitController, {Props} from "./StudentTaskUnitController.web";
import Datetime from "react-datetime";
import moment from "moment";
import SelectedTab from "../SelectedTab.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class StudentTaskUnit extends StudentTaskUnitController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    const { teacherTasks, type } = this.state;
    return (
      <div className="studentTaskContent">
        <Box className="mt20">
          <SelectedTab isTeacher={false} type={"Task"} />
        </Box>
        <Box className="taskOuter">
          {this.state.showSpinner && (
            <Spinner spinnerModal={this.state.showSpinner} />
          )}
          <Grid container spacing={2} justify="space-between" alignItems="center">
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
              <Box className={`searchTaskInputBox ${this.props.classes.border_Color} ${this.state.queryTerm == "" ? "activeColor" : "disableColor"}`}>
                <input className="searchTaskInput"
                  placeholder="Search task work…"
                  value={this.state.queryTerm}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ queryTerm: event.target.value }, () => { });
                  }}
                  onKeyPress={(event: any) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      if (this.state.queryTerm !== "") {
                        this.get_search_task(this.state.queryTerm);
                      } else {
                        this.get_task(this.state.task_page + 1);
                      }
                    }
                  }}
                />
                <Search onClick={() => {
                    if (this.state.queryTerm !== "") {
                      this.get_search_task(this.state.queryTerm);
                    } else {
                      this.get_task(this.state.task_page + 1);
                    }
                  }}
                  className={`inputSearchIcon ${this.props.classes.icon_color}`}
                />
              </Box>
            </Grid>
            <Grid item justify="space-between" className="todayBtnOuter" xs={12} sm={4} md={4} lg={4} xl={4} spacing={2}>
              <Grid className="todayBtnOuterGrid" item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Button variant="contained" className={`todayButton ${this.props.classes.button_color}`}
                  onClick={() => {
                    this.setState({ todayBtn: true });
                    if (this.state.todayBtn === true) {
                      this.get_cal_task(moment().format("DD MMMM,YYYY"));
                    }
                    this.get_cal_task(moment().format("DD MMMM,YYYY"));
                  }}
                >
                  Today
                </Button>
              </Grid>
              <Grid className="dateGrid" item xs={9} sm={9} md={9} lg={9} xl={9}>
                <Datetime
                  dateFormat={"DD MMMM,YYYY"}
                  timeFormat={false}
                  strictParsing={true}
                  input={true}
                  value={
                    this.state.todayBtn
                      ? moment().format("DD MMMM,YYYY")
                      : this.state.task_date
                  }
                  className={"date-input-student"}
                  inputProps={{
                    placeholder: "Select Date",
                    className: "Date_of_birth_calendar_Icon_register",
                    readOnly: true,
                  }}
                  onChange={(date) => {
                    this.setState({
                      todayBtn: false,
                      task_date: moment(date).format("DD-MM-YYYY"),
                    });
                    this.get_cal_task(date);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <Grid container spacing={2} className="dFlex">
              {teacherTasks?.length === 0 ? (
                <Typography className="studentTaskNoRecordText">
                  No record(s) found.
                </Typography>
              ) : (
                teacherTasks
                  .slice(0, this.state.arraySlicenumber)
                  .map((task: any, index: any) => (
                    <Grid className="dueDateGrid" key={task?.task?.id} item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <Box className={`dueDateBg ${
                        moment().isAfter(task?.attributes?.due_date) &&
                        task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                          "pending"
                          ? "bgFirstOpacity"
                          : task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                            "pending"
                          ? "bgSecondOpacity"
                          : "bgThirdOpacity"}`}
                      >
                        <Box className="dialogOpenBox">
                          <Grid className="flexCenterGrid"
                            container
                            onClick={() => {
                              this.setState({ seletedTask: task }, () => {
                                this.setState({ dialogOpen: true });
                              });
                            }}
                            spacing={2}>
                            <Grid item xs={4} md={3} lg={2} xl={2}>
                              <Box className={`dateMonthBackground ${moment().isAfter(
                                      task?.attributes?.due_date
                                    ) &&
                                      task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                      "pending"
                                      ? "bgFirst"
                                      : task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                        "pending"
                                      ? "bgSecond"
                                      : "bgThird"} 
                                      ${
                                        moment().isAfter(
                                          task?.attributes?.due_date
                                        ) &&
                                        task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                          "pending"
                                          ? "borderFirst"
                                          : task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                            "pending"
                                          ? "borderSecond"
                                          : "borderThird"
                                      }`}>
                                <Typography className="textColorWhite">
                                  {moment(task?.attributes?.due_date).format("MMM")}
                                </Typography>
                                <Typography className="ddText">
                                  {moment(task?.attributes?.due_date).format("DD")}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={8} md={9} lg={10} xl={10}>
                              <Typography className={`studentAttributeText ${                                    moment().isAfter(
                                      task?.attributes?.due_date
                                    ) &&
                                      task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                      "pending"
                                      ? "colorFirst"
                                      : task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                        "pending"
                                      ? "colorSecond"
                                      : "colorThird"}`}>
                                {task?.attributes?.name}
                              </Typography>
                              <Typography className="ddhhmmText">
                                {moment(task?.attributes?.due_date).format(
                                  "dddd"
                                )}{" "}
                                at{" "}
                                {moment(task?.attributes?.due_date).format(
                                  "hh:mm A"
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                        <Grid className={`studentTasksubjectName ${
                          moment().isAfter(task?.attributes?.due_date) &&
                          task?.attributes?.task_members?.data.attributes?.status?.toLowerCase() ===
                            "pending"
                            ? "opacityborderFirst"
                            : task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                              "pending"
                            ? "opacityborderSecond"
                            : "opacityborderThird"               
                          }`}
                          item xs={12}
                          onClick={() => {
                            this.setState({ seletedTask: task }, () => {
                              this.setState({ dialogOpen: true });
                            });
                          }}
                        ></Grid>
                        <Grid className="subjectNameGrid" item xs={12}
                          onClick={() => {
                            this.setState({ seletedTask: task }, () => {
                              this.setState({ dialogOpen: true });
                            });
                          }}
                        >
                          <Typography className="subjectNameText">
                            Subject Name:
                          </Typography>
                          <Typography className="subjectNameTextAttirbutesBox">
                            {task?.attributes?.subject_name}
                          </Typography>
                        </Grid>
                        <Grid item className="dueDateGrid"
                          onClick={() => {
                            this.setState({ seletedTask: task }, () => {
                              this.setState({ dialogOpen: true });
                            });
                          }}
                          xs={12}
                        >
                          <Typography className="dueDateText">
                            Due Date:
                          </Typography>
                          <Typography className="dueDateAttributes">
                            {moment(task?.attributes?.due_date).format(
                              "DD MMM YYYY"
                            )}
                          </Typography>
                        </Grid>
                        <Grid item className="submissionStatus"
                          onClick={() => {
                            this.setState({ seletedTask: task }, () => {
                              this.setState({ dialogOpen: true });
                            });
                          }}
                          xs={12}>
                          <Typography className="subjectNameStatus">
                            Submission Status:
                          </Typography>
                          <Box className={`subjectNameBox ${moment().isAfter(task.attributes?.due_date) &&
                                task.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                  "pending"
                                  ? "bgFirst"
                                  : task.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                    "pending"
                                  ? "bgSecond"
                                  : "bgThird"}`}>
                            <Typography className="subjectNameTextAttirbutes">
                              {
                                task.attributes?.task_members?.data?.attributes
                                  ?.status
                              }
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item className={`fileGrid ${
                              moment().isAfter(task?.attributes?.due_date) &&
                              task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                "pending"
                                ? "opacityborderFirst"
                                : task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                  "pending"
                                ? "opacityborderSecond"
                                : "opacityborderThird"
                            }`}
                          onClick={() => {
                            this.setState({ seletedTask: task }, () => {
                              this.setState({ dialogOpen: true });
                            });
                          }}
                          xs={12}></Grid>
                        <Typography className="fileTextName">
                          File :
                        </Typography>
                        {task?.attributes?.task_members?.data?.attributes?.file
                          ?.length !== 0 ? (
                          <div className="multiple-file-div">
                            {task.attributes?.task_members?.data.attributes?.file?.map(
                              (fileData: any) => {
                                return (
                                  <Box className={`studentFileBox ${
                                    moment().isAfter(
                                      task?.task?.due_date
                                    ) &&
                                    task?.task?.task_members?.data?.[0]?.attributes?.status?.toLowerCase() ===
                                      "pending"
                                      ? "borderFirst"
                                      : task?.task?.task_members?.data?.[0]?.attributes?.status?.toLowerCase() ===
                                        "pending"
                                      ? "borderSecond"
                                      : "borderThird"
                                    }`}
                                    >
                                    <Box className="fileExtensionBox">
                                      {fileData?.attributes?.content_type ===
                                        "image/jpeg" ||
                                        fileData?.attributes?.content_type ===
                                        "image/png" ||
                                        fileData?.attributes?.content_type ===
                                        "image/gif" ? (
                                        <img className="fileExtensionImg"
                                          src={fileData?.attributes?.url} />
                                      ) : fileData?.attributes?.content_type ===
                                        "application/msword" ||
                                        fileData?.attributes?.content_type ===
                                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                        <img className="docIcon" src={docICon} />
                                      ) : fileData?.attributes?.content_type ===
                                        "application/vnd.ms-excel" ||
                                        fileData?.attributes?.content_type ===
                                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                                        <img className="docIcon" src={xlsIcon} />
                                      ) : (
                                        ""
                                      )}
                                      <Typography className="fileNameAttribute">
                                        {fileData?.attributes?.file_name}
                                        <br />
                                        <span className="spanFileSize">
                                          {fileData?.attributes?.file_size >
                                            1048576
                                            ? `${(
                                              fileData?.attributes
                                                ?.file_size / 1048576
                                            ).toFixed(2)} MB`
                                            : `${(
                                              fileData?.attributes
                                                ?.file_size / 1024
                                            ).toFixed(2)} KB`}{" "}
                                          {fileData?.attributes
                                            ?.content_type === "image/jpeg" ||
                                            fileData?.attributes?.content_type ===
                                            "image/png" ||
                                            fileData?.attributes?.content_type ===
                                            "image/gif"
                                            ? "Image"
                                            : fileData?.attributes
                                              ?.content_type ===
                                              "application/msword" ||
                                              fileData?.attributes
                                                ?.content_type ===
                                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                              ? "Word Document"
                                              : fileData?.attributes
                                                ?.content_type ===
                                                "application/vnd.ms-excel" ||
                                                fileData?.attributes
                                                  ?.content_type ===
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                ? "Excel Document"
                                                : ""}
                                        </span>
                                      </Typography>
                                    </Box>
                                    <Link className="fileDownloadIconLink"
                                      onClick={(event: any) =>
                                        event.stopPropagation()
                                      }
                                      href={fileData.attributes?.url}
                                      target="_blank"
                                      download
                                    >
                                      <img className="fileDownloadIcon" src={file_download} />
                                    </Link>
                                  </Box>
                                );
                              }
                            )}
                          </div>
                        ) : moment().isAfter(task.attributes?.due_date) &&
                          task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                          "pending" ? (
                          <>
                            <Box className={`submitCourseworkBtn ${
                                moment().isAfter(task.attributes.due_date) &&
                                task?.task?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                  "pending"
                                  ? "borderFirst"
                                  : task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                    "pending"
                                  ? "borderSecond"
                                  : "borderThird"
                              }`}>
                              <img src={image_upload} className="submitBtnImageUploadIcon" />
                              <Typography className="submitCourseworkTitle">
                                Submit Coursework
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              disableRipple={true}
                              className={`submitCourseworkBtn ${
                                moment().isAfter(task.attributes.due_date) &&
                                task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                  "pending"
                                  ? "borderFirst"
                                  : task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                    "pending"
                                  ? "borderSecond"
                                  : "borderThird"
                              }`}
                              disabled={
                                moment().isAfter(task.attributes.due_date) &&
                                  task?.attributes?.task_members?.data?.attributes?.status?.toLowerCase() ===
                                  "pending"
                                  ? true
                                  : false
                              }
                              onClick={(event: any) => {
                                event.stopPropagation();
                                this.setState({
                                  task_id: task?.id,
                                  submitModal: !this.state.submitModal,
                                });
                              }}
                            >
                              <img src={image_upload} className="submitBtnImageUploadIcon" />
                              <Typography className="submitCourseworkTitle">
                                Submit Coursework
                              </Typography>
                            </Button>
                          </>
                        )}
                        {this.state.submitModal &&
                          this.state.task_id === task?.id && (
                            <>
                              <StudentTaskUpload
                                submitModal={this.state.submitModal}
                                closeModalFun={this.onCouseWorkModal}
                                task_id={this.state.task_id}
                                studentTask={task.attributes}
                              />
                            </>
                          )}
                      </Box>
                    </Grid>
                  ))
              )}
            </Grid>
          </Box>
          {this.state.viewAllTask ? (
            <>
              <Box className="paginationBox">
                <ReactPaginate
                  previousLabel={"←   Previous"}
                  nextLabel={"Next   →	"}
                  initialPage={this.state.task_page}
                  pageCount={this.state.timeLineMeta?.pagination?.total_pages}
                  onPageChange={(e: any) => {
                    if (e.selected !== this.state.task_page) {
                      this.setState({ task_page: e.selected });
                      this.get_task(e.selected + 1);
                    }
                  }}
                  containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                  previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                  nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                  disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                  activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                  pageRangeDisplayed={4}
                  marginPagesDisplayed={0}
                />
              </Box>
            </>
          ) : (
            teacherTasks?.length > 3 && (
              <Box className="viewAllBox">
                <Button className="viewAllBtn"
                  variant="contained"
                  disableRipple={true}
                  onClick={() => {
                    this.setState({
                      viewAllTask: !this.state.viewAllTask,
                      arraySlicenumber: teacherTasks?.length,
                    });
                  }}
                >
                  View All
                </Button>
              </Box>
            )
          )}
          {this.state.seletedTask.attributes && (
            <Dialog className="taskDetailsDialog"
              aria-labelledby="customized-dialog-title"
              maxWidth="md"
              open={this.state.dialogOpen}
              onClose={() => this.setState({ dialogOpen: false })}
              closeAfterTransition
              fullWidth={true}
              disableAutoFocus={true}
            >
              <Grid className="taskDetailPopup" item xs={12}>
                <Grid className="taskTitle" item xs={12}>
                  Task Details
                </Grid>
                <Divider className="dividerTask" />
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} className="selectedGrid">
                      <Typography className="subjTitle"                      >
                        {this.state.seletedTask?.attributes.name}
                      </Typography>
                      <Typography className="daylist">
                        {moment(
                          this.state.seletedTask?.attributes.due_date
                        ).format("dddd")}{" "}
                        at{" "}
                        {moment(
                          this.state.seletedTask?.attributes.due_date
                        ).format("hh:mm A")}
                      </Typography>
                    </Grid>
                    <Grid className="subjectTitle" item xs={12}>
                      <Typography className="subjectTitleName">
                        Subject Name:
                      </Typography>
                      <Typography className="subjectTitleAttribute">
                        {this.state.seletedTask?.attributes.subject_name}
                      </Typography>
                    </Grid>
                    {this.state.seletedTask?.attributes.description != "" && (
                      <>
                        <Grid className="taskDetailsGrid" item xs={12}>
                          <Typography className="taskDetailsTitle">
                            Task Details:
                          </Typography>
                        </Grid>
                        <Grid className="subjectDescriptionAttribute" item xs={12} >
                          <Typography className="disc">
                            {this.state.seletedTask?.attributes.description}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} style={{ marginTop: 25 }}>
                      <Grid className="subjectTitleTeacher" container>
                        <Grid className="subjectTitleTeacherGrid" item xs={6}>
                          <Typography className="subjectTitleTeacherTitle">
                            Teacher:
                          </Typography>
                          {this.state.seletedTask?.attributes?.teacher
                            ?.attributes?.avatar ? (
                            <Avatar className="teacherAvtar"
                              src={
                                this.state.seletedTask?.attributes?.teacher
                                  ?.attributes?.avatar
                              }
                            />
                          ) : this.state.seletedTask.attributes.teacher
                              ?.attributes?.first_name ? (
                            <Avatar className="teacherAvtar" />
                          ) : (
                            ""
                          )}
                          <Typography className="tMl12" component={"span"}>
                            <Typography className="seletectedTaskName" component={"p"}>
                              {
                                this.state.seletedTask.attributes.teacher
                                  ?.attributes?.first_name
                              }{" "}
                              {
                                this.state.seletedTask.attributes.teacher
                                  ?.attributes?.last_name
                              }
                            </Typography>
                            <Typography className="seletectedTaskEmail">
                              {
                                this.state.seletedTask.attributes.teacher
                                  ?.attributes?.email
                              }
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className="subjectTitle" item xs={12}>
                      <Grid className="gradeStatuGrid" container>
                        <Grid className="gradeStatuGrid" item xs={6}>
                          <Typography className="statusTitle">
                            Submission Status:
                          </Typography>
                          <Box className={`statusBox ${this.state.seletedTask?.attributes.task_members?.data?.attributes?.status?.toLowerCase() ===
                                "pending" ? "activeStatus" : "pendingStatus"}`} >
                            <Typography className="statusAttirbutes">
                              {
                                this.state.seletedTask?.attributes.task_members
                                  ?.data?.attributes?.status
                              }
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid className="gradeStatuGrid" item xs={6}>
                          <Typography className="dueDateTitle">
                            Due Date:
                          </Typography>

                          <Typography className="dueAttribute"                          >
                            {moment(
                              this.state.seletedTask?.attributes.due_date
                            ).format("DD MMM YYYY")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {this.state.seletedTask?.attributes.task_file.length !=
                      0 && (
                      <Grid className="subjectTitle" item xs={12}>
                        <Typography className="fileTitle">
                          File:
                        </Typography>
                        <Box>
                          {this.state.seletedTask?.attributes.task_file.map(
                            (fileData: any) => (
                              <Box className="fileTypeBox">
                                {fileData?.attributes?.content_type ===
                                  "image/jpeg" ||
                                fileData?.attributes?.content_type ===
                                  "image/png" ||
                                fileData?.attributes?.content_type ===
                                  "image/gif" ? (
                                  <img className="fileIcons"
                                    src={fileData?.attributes?.url}
                                  />
                                ) : fileData?.attributes?.content_type ===
                                    "application/msword" ||
                                  fileData?.attributes?.content_type ===
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                  <img className="fileDocIcon" src={docICon} />
                                ) : fileData?.attributes?.content_type ===
                                    "application/vnd.ms-excel" ||
                                  fileData?.attributes?.content_type ===
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                                  <img className="fileDocIcon" src={xlsIcon} />
                                ) : (
                                  ""
                                )}
                                <Typography className="fileNameAttributes">
                                  {fileData?.attributes?.file_name}
                                  <br />
                                  <span className="fileNameSize">
                                    {fileData?.attributes?.file_size > 1048576
                                      ? `${(
                                          fileData?.attributes?.file_size /
                                          1048576
                                        ).toFixed(2)} MB`
                                        : `${(
                                          fileData?.attributes?.file_size / 1024
                                        ).toFixed(2)} KB`}{" "}
                                      {fileData?.attributes?.content_type ===
                                        "image/jpeg" ||
                                        fileData?.attributes?.content_type ===
                                        "image/png" ||
                                        fileData?.attributes?.content_type ===
                                        "image/gif"
                                        ? "Image"
                                        : fileData?.attributes?.content_type ===
                                          "application/msword" ||
                                          fileData?.attributes?.content_type ===
                                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                      ? "Word Document"
                                      : fileData?.attributes?.content_type ===
                                          "application/vnd.ms-excel" ||
                                        fileData?.attributes?.content_type ===
                                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                      ? "Excel Document"
                                      : ""}
                                  </span>
                                </Typography>
                                <Link className="downloadFile"
                                  onClick={(event: any) =>
                                    event.stopPropagation()
                                  }
                                  href={fileData?.attributes?.url}
                                  target="_blank"
                                  download={fileData?.attributes?.file_name}
                                >
                                  <img className="downloadFileIcon" src={file_download} />
                                </Link>
                              </Box>
                            )
                          )}
                        </Box>
                      </Grid>
                    )}
                    <Divider className="fullDividerTask" />
                    {this.state.seletedTask.attributes.task_members?.data
                      ?.attributes?.file?.length !== 0 ? (
                      <div className="multipleFilePopup">
                        {this.state.seletedTask?.attributes.task_members?.data?.attributes?.file?.map(
                          (fileData: any) => {
                            return (
                              <Box className="multipleFilePopupBox">
                                <Box className="multipleFileIconBox" 
                                  onClick={(event: any) =>
                                    event.stopPropagation()
                                  }
                                >
                                  {fileData?.attributes?.content_type ===
                                    "image/jpeg" ||
                                    fileData?.attributes?.content_type ===
                                    "image/png" ||
                                    fileData?.attributes?.content_type ===
                                    "image/gif" ? (
                                    <img className="fileDocIcon" src={fileData?.attributes?.url} />
                                  ) : fileData?.attributes?.content_type ===
                                    "application/msword" ||
                                    fileData?.attributes?.content_type ===
                                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                    <img className="fileDocIcon" src={docICon} />
                                  ) : fileData?.attributes?.content_type ===
                                    "application/vnd.ms-excel" ||
                                    fileData?.attributes?.content_type ===
                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                                    <img className="fileDocIcon" src={xlsIcon} />
                                  ) : (
                                    ""
                                  )}

                                  <Typography className="fileNameAttributes">
                                    {fileData?.attributes?.file_name}
                                    <br />
                                    <span className="fileNameSize">
                                      {fileData?.attributes?.file_size > 1048576
                                        ? `${(
                                          fileData?.attributes?.file_size /
                                          1048576
                                        ).toFixed(2)} MB`
                                        : `${(
                                          fileData?.attributes?.file_size /
                                          1024
                                        ).toFixed(2)} KB`}{" "}
                                      {fileData?.attributes?.content_type ===
                                        "image/jpeg" ||
                                        fileData?.attributes?.content_type ===
                                        "image/png" ||
                                        fileData?.attributes?.content_type ===
                                        "image/gif"
                                        ? "Image"
                                        : fileData?.attributes?.content_type ===
                                          "application/msword" ||
                                          fileData?.attributes?.content_type ===
                                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                          ? "Word Document"
                                          : fileData?.attributes?.content_type ===
                                            "application/vnd.ms-excel" ||
                                            fileData?.attributes?.content_type ===
                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            ? "Excel Document"
                                            : ""}
                                    </span>
                                  </Typography>
                                </Box>
                                <Link className="downloadFile" onClick={(event: any) => event.stopPropagation()}
                                  href={fileData?.attributes?.url} target="_blank" download={fileData?.attributes?.file_name}>
                                  <img className="downloadFileIcon marginLeft20" src={file_download} />
                                </Link>
                              </Box>
                            );
                          }
                        )}
                      </div>
                    ) : moment().isAfter(
                      this.state.seletedTask?.attributes.due_date
                    ) &&
                      this.state.seletedTask?.attributes.task_members?.data?.[0]?.attributes?.status?.toLowerCase() ===
                      "pending" ? (
                      <>
                        <Box className={`submitCourseworkBtn ${
                            moment().isAfter(
                              this.state.seletedTask?.attributes.due_date
                            ) &&
                            this.state.seletedTask?.attributes.task_members?.data?.[0]?.attributes?.status?.toLowerCase() ===
                              "pending"
                              ? "borderFirst"
                              : this.state.seletedTask?.attributes.task_members?.data?.[0]?.attributes?.status?.toLowerCase() ===
                                "pending"
                              ? "borderSecond"
                              : "borderThird"
                          }`}>
                          <img className="submitBtnImageUploadIcon" src={image_upload} />

                          <Typography className="submitCourseTitle">
                            Submit Coursework
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box className="fullWidth">
                          <Button
                            variant="contained"
                            className="submitCourseBtn"
                            disabled={
                              moment().isAfter(
                                this.state.seletedTask?.attributes.due_date
                              ) &&
                                this.state.seletedTask?.attributes.task_members?.data?.attributes?.status?.toLowerCase() ===
                                "pending"
                                ? true
                                : false
                            }
                            onClick={(event: any) => {
                              event.stopPropagation(),
                                this.setState({
                                  task_id: this.state.seletedTask?.id,
                                  submitModal: !this.state.submitModal,
                                  dialogOpen: false,
                                });
                            }}
                          >
                            <img className="submitBtnImageUploadIcon" src={image_upload} />
                            <Typography className="submitCourseworkTitle">
                              Submit Coursework
                            </Typography>
                          </Button>
                        </Box>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Dialog>
          )}
        </Box>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

export default withStyles(themeCreateStyle)(
  StudentTaskUnit as React.ComponentType<any>
);
// Customizable Area End
