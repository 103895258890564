// Customizable Area Start
import React from "react";
import StudentClassesAndSectionController, {
  ILiveClassesProps
} from "./StudentClassesAndSectionController.web";
import "./StudentClassesAndSection.web.css";
import {
  Box,
  Typography,
  Grid
} from "@material-ui/core";
import Datetime from "react-datetime";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../shared/Header/src/HOCAuth.web";
import CustomToolbar from "../src/LiveClass/CalendarCustomFile/CustomToolbar.web";
import TimeTableEventWrapper from "../src/LiveClass/CalendarCustomFile/TimeTableEventWrapper.web";
import {
  gradeBook_Image,
  resources_Image,
  subjects_Image,
  timeTable_Image,
  member_Image,
  subjectIcon,
} from "./assets";
import Slider from "react-slick";
import MeetingFeedbackOne from "./ZoomMeeting/MeetingFeedback/MeetingFeedbackOne.web";
import Spinner from "../../shared/SideBar/src/Spinner";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
import './CommonClassesAndSections.web.css';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class StudentClassesAndSections extends StudentClassesAndSectionController {
  // Customizable Area Start
  render() {
    const localizer = momentLocalizer(moment);
    const today = moment();
    const formats = {
      weekdayFormat: (date: any, culture: any, localizer: any) =>
        localizer.format(date, "dddd", culture),
      dayFormat: (date: any, culture: any, localizer: any) =>
        localizer.format(date, "dddd", culture),
      timeGutterFormat: (date: any, culture: any, localizer: any) => {
        return `${localizer.format(date, "h A", culture)}`;
      },
      dateFormat: (date: any, culture: any, localizer: any) =>
        localizer.format(date, "DD MMM", culture),
    };
    const eventStyleGetter = () => (
      event: any,
    ) => {
      const lectureTime = moment(event.end).format("DD MMM YYYY HH:mma");
      const currentTime = moment();

      const duration = moment.duration(moment(event.end).diff(moment(event.start)));
      const hours = duration.asHours();
      const size: number = hours * 94;

      const style = {
        maxHeight: hours === 1 ? `${size}px` : `${size + 6}px`,
        backgroundColor: moment(lectureTime, "DD MMM YYYY HH:mma").isBefore(currentTime) ? "#f9f8fd" : event.hexBgColor,
        color: "black",
        borderLeft: `6px solid #471c51`,
        display: "block",
        opacity: moment(lectureTime, "DD MMM YYYY HH:mma").isBefore(currentTime) ? "0.74" : "1",



      };
      return {
        style: style,
      };
    };
    const DayEventContainer = () => (props: any) => {
      const { event } = props;
      return (
        <TimeTableEventWrapper
          event={event as ILiveClassesProps}
          isTeacher={false}
          joinMeeting={() =>
            this.setState({ selectedLiveClass: event }, () =>
              this.handleOpenZoomMeeting(event)
            )
          }
          onBodyClick={() => this.setState({ selectedLiveClass: event })}
        />
      );
    };
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      appendDots: (dots: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined) => (
        <ul style={{ margin: "0px" }}> {dots} </ul>
      ),
    }
    const { date, studentAttendanceList, numberOfSubject, studentTasks } = this.state
    const studentTasksData = studentTasks.slice(0, 4)
    return (
      <>
        {this.state.showLoader && (
          <Spinner spinnerModal={this.state.showLoader} />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={9} lg={9} className="schedule_container">
            <Grid
              className="outer-liveclass main_box"
              container
              direction="row"
            >
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={12} className="head_part">
                    <Grid container spacing={2} className="flex_align_center">
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography className="schedule-title"

                        >
                          Today’s Schedule
                        </Typography>
                        <Typography
                          className={`${this.props.classes.subHeading_font} head_text`}>
                          {moment(date).format('dddd')}, {moment(date).format('DD MMMM YYYY')}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      sm={12}
                      md={6}
                        className="flex_center_end"
                      >
                        <div
                          className="innerCalendar flex_align_center"
                        >
                          <Datetime
                            dateFormat={"DD MMMM, YYYY"}
                            timeFormat={false}
                            strictParsing={true}
                            input={true}
                            value={moment(this.state.date, "DD-MM-YYYY").format(
                              "DD MMMM,YYYY"
                            )}
                            initialValue={moment().format("DD MMMM,YYYY")}
                            className={'date-input-student'}
                            inputProps={{
                              placeholder: "Date of Birth",
                              className: "Date_of_birth_calendar_Icon_register",
                              readOnly: true,
                            }}
                            onNavigateBack={(count, type) => {
                              this.handleDateNavigate(count, type, "backward");
                            }}
                            onNavigateForward={(count, type) => {
                              this.handleDateNavigate(count, type, "forward");
                            }}
                            closeOnSelect
                            onChange={(date) => {
                              this.setState(
                                {
                                  date: moment(date),
                                },
                                () => {
                                  this.getStudentLiveClasses(date);
                                }
                              );
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className="calender_part"
              >
                <Calendar
                  formats={formats}
                  events={this.state.calendarEvents}
                  localizer={localizer}
                  className="small-calendar1 calender2"
                  onShowMore={(event, date) =>
                    this.setState({
                      date: date,
                    })
                  }
                  showAllEvents={true}
                  min={new Date(today.year(), today.month(), today.date(), 0)}
                  max={new Date(today.year(), today.month(), today.date(), 23)}
                  startAccessor="start"
                  endAccessor="end"
                  date={this.state.date}
                  view={"day"}
                  views={["day"]}
                  defaultView={"day"}
                  eventPropGetter={eventStyleGetter()}
                  timeslots={1}
                  step={60}
                  components={{
                    day: {
                      event: DayEventContainer(),
                    },
                    toolbar: CustomToolbar,
                  }}
                  dayLayoutAlgorithm="no-overlap"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} lg={3} className="sidebar_widgets_container">
            <Grid className="time-table-row" container direction="row" spacing={2}>
              <Grid item xs={6} md={12} lg={12} className="flex">
                <Box className="widget_box bg1"
                  onClick={() => this.sectionBtnHandler('Time Table')}>
                  <img src={timeTable_Image} className="widget_box_img" />
                  <Typography className="subHeading"

                  >
                    Time Table
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={12} lg={12} className="flex">
                <Box className="widget_box bg2"
                  onClick={() => this.sectionBtnHandler('Gradebook')}>
                  <img src={gradeBook_Image} className="widget_box_img" />
                  <Typography className="subHeading"
                  >
                    Gradebook
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={12} lg={12} className="flex">
                <Box className="widget_box bg3"
                  onClick={() => this.sectionBtnHandler('Resources')}>
                  <img src={resources_Image} className="widget_box_img" />

                  <Typography className="subHeading"
                  >
                    Resources
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={12} lg={12} className="flex">
                <Box className="bg4 widget_box"
                  onClick={() => this.sectionBtnHandler('Subjects')}
                >
                  <img src={subjects_Image} className="widget_box_img" />

                  <Typography className="subHeading"
                  >
                    Subjects({numberOfSubject})
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={12} lg={12} className="flex">
                <Box className="bg5 widget_box"
                  onClick={() => this.sectionBtnHandler('Members')}
                >
                  <img src={member_Image} className="widget_box_img" />

                  <Typography className="subHeading"
                  >
                    Members
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="mob-spacing bottom_content_container" container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Box className="board-outer sliderTask-slider card_box ">
                <Box className="card_head_part mb_25">
                  <Typography className="schedule-title"
                  >
                    Task
                  </Typography>
                  <Typography
                    onClick={() => this.sectionBtnHandler('Task')}
                    className={`${this.props.classes.studentClassAndSections_classAndSection_button_color_disabled} view_link_typo`}
                  >
                    View Board
                  </Typography>
                </Box>

                {studentTasks.length === 0 ?
                  <>
                    <div className="no_data_found_box" >
                      <span className="no_data_found_text">
                        No Data Found!
                      </span>
                    </div>
                  </>
                  :
                  <>
                    <Slider
                      {...settings}
                      className="sliderTask"
                    >
                      {studentTasksData.map((taskData: any, index: number) => (
                        <>
                          {index < 4 && <>
                            <Box className="mt_25">
                              <Box className="card_data">
                                <Typography className="data_attr_title"
                                >
                                  {taskData.attributes.name}
                                </Typography>
                                <Typography
                                  className='paragrapWrapClass attribute_text mb_20'>

                                  {taskData.attributes.description}
                                </Typography>
                                <Typography className="mb_16 attribute_text">
                                  {moment(taskData.attributes.due_date).format('dddd')},<span>&nbsp;</span>{moment(taskData.attributes.due_date).format('DD MMMM YYYY')}
                                </Typography>
                                <Box className="mt_25 border_top"></Box>
                                <Box className="data_list_item">
                                  <Typography className="data_list_item_typo">
                                    Subject :<span>&nbsp;</span>
                                  </Typography>
                                  <Typography className="data_list_item_text">
                                    {taskData.attributes.subject_name}
                                  </Typography>
                                </Box>
                                <Box className="data_list_item">
                                  <Typography className="data_list_item_typo">
                                    Due Date :<span>&nbsp;</span>
                                  </Typography>
                                  <Typography className="data_list_item_text">

                                    {moment(taskData.attributes.due_date).format('dddd')},<span>&nbsp;</span>{moment(taskData.attributes.due_date).format('DD MMMM YYYY')}

                                  </Typography>
                                </Box>
                                <Box className="data_list_item">
                                  <Typography className="data_list_item_typo">
                                    Status :<span>&nbsp;</span>
                                  </Typography>
                                  <Typography className="data_list_item_status" style={{
                                    color: taskData.attributes.task_members.data.attributes.status === 'pending' ? '#ca8145' : '#177b1c',
                                  }}>
                                    {taskData.attributes.task_members.data.attributes.status}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </>
                          }
                        </>
                      ))}
                    </Slider>
                  </>
                }
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box className="board-outer sliderTask-slider card_box" >
                <Box className="card_head_part">
                  <Typography className="schedule-title"
                  >
                    Attendence
                  </Typography>
                  <Typography
                    onClick={() => this.sectionBtnHandler('Attandance')}
                    className={`${this.props.classes.studentClassAndSections_classAndSection_button_color_disabled} view_link_typo`}
                  >
                    View all record
                  </Typography>
                </Box>
                <Box className="mt_25">
                  {studentAttendanceList.length === 0 ?
                    <>
                      <div className="no_data_found_box">
                        <span className="no_data_found_text">
                          No Data Found!
                        </span>
                      </div>
                    </>
                    :
                    <>
                      {studentAttendanceList.map((data: any, index: number) => {
                        const start_time = moment(data?.period?.attributes?.start_date).format('HH:mm')
                        const end_time = moment(data?.period?.attributes?.end_date).format('HH:mm')
                        return (
                          <>
                            {index < 4 && <>

                              <Box className="border_top_rgba">
                                <Grid container spacing={2} className="my">
                                  <Grid item xs={2} className="flex_justify_center">
                                    <img src={data.image == null || data.image.length === 0 ? subjectIcon : data?.image?.file_path} className="img_part" />
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Typography className={`${this.props.classes.subHeading_font} fs_20 fw_500`}>{data.subject_name}</Typography>
                                    <Typography className={`${this.props.classes.bodyText_font} fs_16`}>{start_time} - {end_time} | {data?.period?.attributes?.name} </Typography>
                                  </Grid>
                                  <Grid item xs={2} className="flex_justify_center">
                                    <Box className="status_box" style={{ backgroundColor: data.status === "Present" ? 'rgba(23, 123, 28,0.32)' : data.status === "Absent" ? 'rgba(224, 32, 32,0.32)' : 'rgba(63, 82, 109,0.32)' }}>
                                      <Typography className="status_box_text" style={{
                                        color: data.status === "Present" ? '#177b1c' : data.status === "Absent" ? '#e02020' : '#3f526d'
                                      }}>
                                        {data.status === "Present" ? <>P</> : data.status === "Absent" ? <>A</> : <>L</>}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>

                              </Box>
                            </>
                            }
                          </>

                        )
                      })}
                    </>
                  }
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid >
        <MeetingFeedbackOne
          openModal={this.state.openFeedbackPopup}
          onCloseModal={() => {
            this.setState({ openFeedbackPopup: false });
          }} />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(StudentClassesAndSections, "StudentAccount"));
// Customizable Area End
