// Customizable Area Start

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import HelpIcon from '@material-ui/icons/Help';
import Grid from '@material-ui/core/Grid';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import { img21kSchholLogo, openEmail_image, check } from './assets';
import AccountVerifyController, { Props } from './AccountVerifyController.web';
import Steppers from './Steppers.web';
import './AccountPhoneVerify.web.css';
import './AccountVerify.web.css';
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
import { withStyles } from '@material-ui/core';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class AccountVerify extends AccountVerifyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    const userdata = localStorage.getItem('user_data');
    if (typeof userdata === 'string') {
      const email = JSON.parse(userdata);
      this.setState({ email: email.email });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    return (
      <div className="verify-mob verify_main_div">
        <div className="AccountVerifyParentBox">
          <Grid container className="verify_container">
            <Grid item xs={12}>
              <div className="Header accountRegistration_div">
                <img
                  src={img21kSchholLogo}
                  alt="Logo Image"
                  className='logoImage'
                />
                <HelpIcon className="accountRegistration_helpIcon" />
              </div>
              <div className="accountRegistration_footer">
                <IconButton
                  aria-label="delete"
                  className="arrow_style_button_left"
                  color="primary"
                  onClick={() => {
                    this.onBackArrowAccountEmail();
                  }}
                >
                  <ArrowBackRoundedIcon className="white-back-arrow" />
                </IconButton>
                <div className="verify_main_div typesofAccount_div">
                  <div>
                    <Steppers activeStep={2} />
                  </div>
                  <div
                    className={`verify_phone_text typesofAccount_textLabel  ${this.props.classes.theme_font}`}
                  >
                    Verify Your Email ID
                  </div>

                  <Grid container>
                    <Grid item xs={12} spacing={3} className="textfield_div">
                      <img
                        src={openEmail_image}
                        alt="Logo Image"
                        className="email_image shieldImage"
                      />
                    </Grid>
                    <Grid item xs={12} spacing={3} className="textfield_div">
                      <Grid container>
                        <Grid item xs={12} sm={11}>
                          <div
                            className={`verification_text verification_text_box ${this.props.classes.theme_font}`}
                          >
                            Verification link is sent to{' '}
                            <span className="dynamic_email">
                              {this.state.email}
                            </span>
                            .
                            <br /> Please verify your email.
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={1}
                          className="justify_content_center"
                        >
                          <img
                            src={check}
                            alt="check"
                            className="check_image check_image"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={11} lg={10} spacing={1}>
                      <div
                        className={`receive_link_text textlabel_forgotpasswordText  ${this.props.classes.theme_font}`}
                      >
                        <span>Didn’t Receive Verification Link?</span>{' '}
                        <Link
                          className="resendotp_text_verify resendotp_text"
                          onClick={() => {
                            this.resendOtpClick();
                          }}
                        >
                          Resend
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </div>

                <IconButton
                  aria-label="delete"
                  className="arrow_style_button_right"
                  color="primary"
                  onClick={() => {
                    this.onAccountEmailVerify();
                  }}
                >
                  {this.state.loadingSpinner ? (
                    <CircularProgress className="spinnerBar" />
                  ) : (
                    <>
                      <ArrowForwardRoundedIcon className="white-back-arrow" />
                    </>
                  )}
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(AccountVerify);
// Customizable Area End
