// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import moment from "moment";
import { encryptData } from "../utils/common";
import axios, { AxiosRequestConfig } from "axios"
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");

export interface ITimeTableEventsProps {
  id: any;
  isAllDay?: boolean;
  start: Date;
  end: Date;
  title: string;
  type?: string;
  hexBgColor?: string;
  borderColor?: string;
  image?: any;
  subjectImage?: any;
  status?: string | any;
  subject?: string;
  meetingDate?: any;
  meetingTitle?: string;
  meetingDetails?: string;
  meetingId?: any;
  period_name?: string;
  period_id?: number | string;
  teacherImage?: any;
  teacherName?: string;
  unit?: string;
  link?: string;
  class?: string;
  isEvent?: boolean;
  description?: string;
  is_online?: boolean | undefined;
  recordings?: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  state: any
  class_id: any
  Events: any
  updateEvents: (bool: boolean) => any
  classes: any
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  data: any;
  token: string;
  showSpinner: boolean;
  anchorEl: any;
  date: any;
  selectedView: any;
  calendarEvents: ITimeTableEventsProps[] | any;
  isStartMeeting: boolean;
  selectedEvent?: any;
  createEventModal: boolean;
  openFeedbackPopup: boolean;
  memberClassId: any;
  creatEventModal: boolean;
  Events: any;
  getSchoolPeriod: any
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherTimeTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetStudentPeriodsId: string = "";
  apiMakeTeacherLiveClassHost: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      showSpinner: false,
      data: null,
      anchorEl: null,
      token: "",
      date: moment(new Date()),
      selectedView: "week",
      isStartMeeting: false,
      createEventModal: false,
      openFeedbackPopup: false,
      calendarEvents: [],
      memberClassId: '',
      creatEventModal: false,
      Events: [],
      getSchoolPeriod: null,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.userdata = this.userdata.bind(this);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.userdata();
    await this.apicall();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  apicall = async () => {
    this.setState({
      showSpinner: true
    })
    await this.getStudentPeriods(this.state.date, this.state.selectedView);
    this.setState({
      showSpinner: false
    })
  }
  handleOpenZoomMeeting = (event: any) => {
    const userData = localStorage.getItem("user_data");
    const parsedUserData = JSON.parse(userData || "{}");
    if (parsedUserData.meeting_paid_account) {
      const { api_key, api_secret, id } = event.meetingDetails;
      const role = 1;
      const meetingParameters = {
        ...event,
        meetingDetails: {
          api_key: encryptData(api_key, "meeting-api-key"),
          api_secret: encryptData(
            api_secret,
            "meeting-api-secret"
          ),
          id: encryptData(
            id,
            "meeting-id"
          ),
          role: encryptData(
            role.toString(),
            "meeting-role"
          )
        }
      };
      localStorage.setItem("meeting-detail", JSON.stringify(meetingParameters));
      localStorage.setItem("role", "student");
      const meetingDetail = localStorage.getItem("meeting-detail");
      if (meetingDetail) {
        const currentUrl = window.location.origin;
        const redirectUrl = `${currentUrl}/Meeting`;
        window.open(redirectUrl, "_blank");
      }
    } else {
      window.open(event.link, "_blank");
    }
  }
  handleStartMeeting = (meetingInfo?: any) => {
    this.setState({ isStartMeeting: true })
  }

  addLunchEvent = (info?: any) => {
    const lunchEvent = {
      id: info.id,
      title: info.title,
      start: info.start,
      end: info.end,
      type: "lunchTime",
      hexBgColor: "#f1eff9",
      borderColor: "#f1eff9",
    }
    if (lunchEvent.start.getDay() !== 0) {

      return lunchEvent
    }
  }
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  viewClick = (view: string) => {
    this.setState({ selectedView: view });
    this.getStudentPeriods(this.state.date, view)
  };

  getStudentPeriods = async (date?: any, view?: string) => {
    const selectedDate = date
      ? moment(date).format("DD/MM/YYYY")
      : moment(this.state.date).format("DD/MM/YYYY");
    const from_date = date ? moment(date).startOf('week').format("DD/MM/YYYY") : moment(this.state.date).startOf('week').format("DD/MM/YYYY");
    const to_date = date ? moment(date).endOf('week').format("DD/MM/YYYY") : moment(this.state.date).endOf('week').format("DD/MM/YYYY");
    const userData = localStorage.getItem("id");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header: any = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("token"),
      school: data.school_id,
    };
    try {
      await axios({
        method: configJSON.getApiMethodType,
        url: configJSON.instituteURL +
          configJSON.studentPeriodEndPoint +
          `?school_class_id=${this.state.memberClassId !== null ? this.state.memberClassId : ''}&school_id=${data.school_id}&start_date=${view === 'week' ? from_date : selectedDate}&end_date=${view === 'week' ? to_date : selectedDate}`,
        headers: header,

      }).then(async (s3Response: AxiosRequestConfig) => {
        if (s3Response != null) {
          let responseItem: any = []
          const responseData = Object.entries(s3Response.data);
          responseData.map(([key1, value]) => {
            responseItem.push(value)
          })
          await this.handleTimeTableData(responseItem);
          return responseItem
        }
        return null
      })
    }
    catch (e) {
      return null
    }
  };

  handleGetStartAndEndTime = (startDate: any, time: string) => {
    const dateObj = new Date(startDate);
    const month = dateObj.getMonth();
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const formattedTime = moment(time).format("HH:mm");
    const formattedTimeHours = parseInt(formattedTime.split(":")[0]);
    const formattedTimeMins = parseInt(formattedTime.split(":")[1]);
    return new Date(year, month, day, formattedTimeHours, formattedTimeMins);
  };

  handleTimeTableData = async (periodInfo: Array<any>) => {
    let colors: any = [
      { Color: '#ffb84d', borderColor: 'rgb(255, 184, 77, 0.4)' },
      { Color: '#f26767', borderColor: 'rgb(242, 103, 103, 0.4)' },
      { Color: '#327095', borderColor: 'rgb(50, 112, 149, 0.4)' },
      { Color: '#3dbb3d', borderColor: 'rgb(61, 187, 61, 0.4)' },
      { Color: '#0091ff', borderColor: 'rgb(0, 145, 255, 0.4)' }
    ]
    if (periodInfo && periodInfo.length > 0) {
      let periodInfoDetails: any = []
      periodInfo.forEach((periodData) => {
        if (Object.keys(periodData).length != 0) {
          Object.keys(periodData)?.forEach((key, index) => {
            periodData[key].forEach((period: any) => {
              let info: any
              const color = colors[Math.floor(Math.random() * colors.length)]
              if (period.attributes.events.length != 0) {
                period.attributes.events[0].data.map((eventData: any) => {
                  info = {
                    id: period.id,
                    title: eventData?.attributes?.name ? eventData.attributes.name : period?.attributes?.periods[1]?.data?.attributes?.name,
                    start: this.handleGetStartAndEndTime(
                      eventData?.attributes?.start_date,
                      moment(eventData?.attributes?.start_date).format()
                    ),
                    end: this.handleGetStartAndEndTime(
                      eventData?.attributes?.end_date,
                      moment(eventData?.attributes?.end_date).format()
                    ),
                    batch_id: period?.attributes?.batch?.data?.id,
                    week_day_school_period: period?.attributes?.week_day_school_period_id,
                    isEvent: period.attributes.events.length != 0 ? true : false,
                    hexBgColor: color.borderColor,
                    borderColor: color.Color,
                    period_name: period?.attributes?.periods[1]?.data?.attributes?.name,
                    period_id: period?.attributes?.periods[1]?.data?.id,
                    subjectImage:
                      period?.attributes?.periods[1]?.data?.attributes?.subject?.data?.attributes?.image?.file_path,
                    subject: eventData.attributes.name,
                    meetingDate: new Date(),
                    meetingTitle: period?.attributes?.periods[1]?.data?.attributes?.online_class_link ? "Live Class" : "",
                    meetingDetails: period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.attributes?.signature,
                    meetingId: period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.id,
                    teacherImage: eventData.attributes?.creator?.avatar,
                    teacherName: `${eventData.attributes?.creator?.first_name != undefined ? eventData.attributes?.creator?.first_name : ''} ${eventData.attributes?.creator?.last_name != undefined ? eventData.attributes?.creator?.last_name : ''}`,
                    unit: undefined,
                    link: period?.attributes?.periods[1]?.data?.attributes?.online_class_link,
                    class: period?.attributes?.periods[1]?.data?.attributes?.display_name,
                    description: eventData.attributes.details,
                    is_online: period?.attributes?.periods[1]?.data?.attributes?.is_online,
                    status: period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.attributes?.status ?
                      period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.attributes?.status : null,
                    recordings: period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.attributes?.recordings
                  };
                  periodInfoDetails.push(info)
                })
              }
              else {
                info = {
                  id: period.id,
                  title: period?.attributes?.periods[1]?.data?.attributes?.name,
                  start: this.handleGetStartAndEndTime(
                    moment(period?.attributes?.periods[1]?.data?.attributes.start_date).format(),
                    moment(period.attributes.start_time).format()
                  ),
                  end: this.handleGetStartAndEndTime(
                    moment(period?.attributes?.periods[1]?.data?.attributes.start_date).format(),
                    moment(period.attributes.end_time).format()
                  ),
                  batch_id: period?.attributes?.batch?.id,
                  week_day_school_period: period?.attributes?.week_day_school_period_id,
                  hexBgColor: color.borderColor,
                  borderColor: color.Color,
                  period_name: period?.attributes?.periods[1]?.data?.attributes?.name,
                  period_id: period?.attributes?.periods[1]?.data?.id,
                  isEvent: false,
                  subjectImage:
                    period?.attributes?.periods[1]?.data?.attributes?.subject?.data?.attributes?.image?.file_path,
                  subject: period?.attributes?.periods[1]?.data?.attributes?.subject?.data?.attributes?.subject_name,
                  meetingDate: new Date(),
                  meetingTitle: period?.attributes?.periods[1]?.data?.attributes?.online_class_link ? "Live Class" : "",
                  meetingDetails: period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.attributes?.signature,
                  meetingId: period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.id,
                  teacherImage: period?.attributes?.periods[1]?.data?.attributes?.teacher?.avatar,
                  teacherName: `${period?.attributes?.periods[1]?.data?.attributes?.teacher?.first_name != undefined ? period?.attributes?.periods[1]?.data?.attributes?.teacher?.first_name : ''} ${period?.attributes?.periods[1]?.data?.attributes?.teacher?.last_name != undefined ? period?.attributes?.periods[1]?.data?.attributes?.teacher?.last_name : ''}`,
                  unit: undefined,
                  link: period?.attributes?.periods[1]?.data?.attributes?.online_class_link,
                  class: period?.attributes?.periods[1]?.data?.attributes?.display_name,
                  description: period?.attributes?.periods[1]?.data?.attributes?.description,
                  is_online: period?.attributes?.periods[1]?.data?.attributes?.is_online,
                  status: period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.attributes?.status ?
                    period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.attributes?.status : null,
                  recordings: period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.attributes?.recordings
                };
              }
              if (info.title === "Lunch Time") {
                const name = this.addLunchEvent(info)
                return name
              } else {
                if (period.attributes.events.length === 0) {
                  if (period.attributes.periods.length > 1) {
                    periodInfoDetails.push(info)
                  }
                }
                return info;
              }
            })
          })
        }
      });
      let periodInfodet = periodInfoDetails.filter((item: any) => item !== undefined)
      this.setState({ calendarEvents: [...periodInfodet] });
    }
  };

  makeTeacherLiveClassHost = () => {
    const userData = localStorage.getItem("user_data");
    const parsedUserData = JSON.parse(userData || "{}");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiMakeTeacherLiveClassHost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherLiveClassesMakeHostEndPoint +
      `?school_id=${parsedUserData.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = await message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = await message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = await message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apigetStudentPeriodsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            await this.handleTimeTableData(responseJson.data);
            this.props.state(responseJson.data);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiMakeTeacherLiveClassHost) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.handleOpenZoomMeeting(this.state.selectedEvent)
          } else {
            this.parseApiErrorResponse(responseJson);
            toast.error("Something went wrong!")
          }
        }
      }
    }
  }
  // Customizable Area End
}
