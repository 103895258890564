// Customizable Area Start
import {
  Backdrop,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import React from 'react';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import AudioList from '../AddQuestion/AudioList.web';
import BarChart from '../AddQuestion/BarChart.web';
import LineChart from '../AddQuestion/LineChart.web';
import PlotlyGraph from '../AddQuestion/PlotlyGraph.web';
import PreviewVideoModal from '../AddQuestion/PreviewVideoModal.web';
import StepperWithText from '../AddQuestion/StepperWithText.web';
import { imageCheckmark, matchGroup, videoThumbnail } from '../assets';
const uuidv4 = require('uuid/v4');
// Customizable Area End

// Customizable Area Start
interface IProps {
  openModal: boolean;
  selectedQuestion: string;
  selectedQuestionAnswers: any;
  classes?: any;
  handleOnClose: React.Dispatch<React.SetStateAction<boolean>>;
}
// Customizable Area End

export const QuestionPreview: React.FC<IProps> = ({
  // Customizable Area Start
  openModal,
  selectedQuestion,
  selectedQuestionAnswers,
  handleOnClose,
  classes,
  // Customizable Area End
}) => {
  // Customizable Area Start
  const [openPreviewVideoModal, setOpenPreviewVideoModal] = React.useState(
    false
  );
  const [selectedVideoSrc, setSelectedVideoSrc] = React.useState(false);
  // Customizable Area End

  // Customizable Area Start
  const onClosePreviewVideoModal = () => {
    setOpenPreviewVideoModal(false);
  };
  const selectVideo = (video: any) => {
    setSelectedVideoSrc(video?.videoSrc);
    setOpenPreviewVideoModal(true);
  };
  const generateColor = () => {
    const letters:string = '0123456789ABCDEF';
    let color:string = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  // Customizable Area End

  // Customizable Area Start
  const showAnswerOfQuestionTypes = (
    answer: any,
    alphabet: any,
    index: number
  ) => {
    let type = selectedQuestionAnswers[1]?.question_sub_category?.name;
    let boxCoordinates = selectedQuestionAnswers[2]?.box_coordinates?.map(
      (item: any) => {
        let right_ans;
        let right_ans_id;
        selectedQuestionAnswers[3]?.answer?.data[0]?.attributes?.label_question_answer?.map(
          (answers: any) => {
            if (answers.box_uniq === item.box_uniq_key) {
              if (
                selectedQuestionAnswers[1]?.question_sub_category?.name ===
                'label_image_with_text'
              ) {
                right_ans = answers.text_answer;
              } else if (
                selectedQuestionAnswers[1]?.question_sub_category?.name ===
                'label_image_with_drag_and_drop'
              ) {
                if (answers.answer_uniq_id === item.ans_uniq_id) {
                  right_ans = item.answer;
                }
              } else {
                item?.answers?.map((dropdownAns: any) => {
                  if (dropdownAns.ans_uniq_id === answers.answer_uniq_id) {
                    right_ans = dropdownAns.answer;
                  }
                });
              }
              right_ans_id = answers.box_uniq;
            }
          }
        );

        return {
          uniqId: item.box_uniq_key,
          top: item.margin_top,
          left: item.margin_left,
          answer: right_ans,
          correctAnswerId: right_ans_id,
        };
      }
    );

    let questionList: any = [];
    selectedQuestionAnswers[0]?.match_list_item?.data.map(
      (questionData: any, index: number) => {
        if (questionData?.attributes?.match_type === 'match_list') {
          let correctAnsIdOfList: any;
          let correctMatchAns;
          let correctAnsSrc;
          answer?.attributes?.match_list_answer?.map(
            (ans: any, index: number) => {
              if (ans.match_list_id === questionData?.attributes?.uniq_id) {
                correctAnsIdOfList = ans.match_response_id;
              }
              selectedQuestionAnswers[0]?.match_list_item?.data.map(
                (questionData: any, index: number) => {
                  if (
                    questionData?.attributes?.match_type === 'match_response'
                  ) {
                    if (
                      questionData?.attributes?.uniq_id === correctAnsIdOfList
                    ) {
                      correctMatchAns = questionData?.attributes?.list_name;
                      correctAnsSrc = questionData.attributes.attachments
                        ?.data[0]?.attributes?.url
                        ? questionData.attributes.attachments?.data[0]
                            ?.attributes?.url
                        : '';
                    }
                  }
                }
              );
            }
          );

          const item = {
            question: questionData?.attributes?.list_name,
            uniq_id: questionData?.attributes?.uniq_id,
            correctAnswerId: correctAnsIdOfList,
            answer: correctMatchAns,
            src: questionData.attributes.attachments?.data[0]?.attributes?.url
              ? questionData.attributes.attachments?.data[0]?.attributes?.url
              : '',
            answerSrc: correctAnsSrc,
          };
          questionList.push(item);
        }
      }
    );

    switch (type) {
      case 'one word':
      case 'fill in blank':
        return (
          <Grid key={answer.id} item xs={12} sm={6} md={3}>
            <div
              className="Width90P"
            >
              <Typography className={classes.PreviousQuestion_AssessmentQuestionBankLibrary_answer_type_text}>
                {answer.attributes?.answer}
              </Typography>
            </div>
          </Grid>
        );
      case 'pictionary':
      case 'mcq':
      case 'true false':
        const title = String.fromCharCode(65 + index);
        const randomColor = generateColor();
        return (
          <Grid
            key={answer.id}
            item
            xs={12}
            md={6}
            className="TrueFalseGrid"
          >
            <Grid
              container
              spacing={1}
              className="MainContainer"
            >
              <Grid item xs={2}>
                <Box
                  className="TitleBox"
                  style={{
                    backgroundColor: `${randomColor}`,
                  }}
                >
                  <Typography
                    className={`Title ${classes.subTitle_font}`}
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={7}
                className={`OptionGrid ${classes.bodyText_font}`}
              >
                {answer.attributes?.answer}

                {answer?.attributes?.attachments?.data?.length > 0 && (
                  <img
                    src={
                      answer.attributes?.attachments?.data[0]?.attributes?.url
                    }
                    className={`OptionImage`}
                  />
                )}
              </Grid>

              <Grid
                item
                xs={2}
                className={`CorrectOptionGrid`}
              >
                {answer.attributes?.right_answer != undefined &&
                  answer.attributes?.right_answer && (
                    <Box
                      className={`ImageBox`}
                    >
                      <img
                        src={imageCheckmark}
                        className={`Image`}
                      ></img>
                    </Box>
                  )}
              </Grid>
            </Grid>
          </Grid>
        );
      case 'flash_card':
        return (
          <Grid
            key={answer.id}
            item
            xs={11}
            md={5}
            className={`FlashCardGrid`}
          >
            <div
              className="FlashCardImageDiv"
            >
              <img
                src={answer.attributes?.attachments?.data[0]?.attributes?.url}
                className={`FlashCardImage`}
              />
            </div>
            <div className="graph_plotting">
              <div
                className={`FlashCardHintDiv ${classes.bodyText_font}`}
              >
                {answer.attributes?.hints}
              </div>
            </div>

            <div className={`MV20`}>
              <div
                className={`FlashCardAnswerDiv  ${classes.bodyText_font}`}
              >
                {answer.attributes?.answer}
              </div>
            </div>
            <span
              className={`CardCount ${classes.subTitle_font}`}
            >
              1/1
            </span>
          </Grid>
        );
      case 'hotspot':
        return (
          <Grid key={answer.id} item xs={12} className={`HotSpotGrid`}>
            <div
              className={`W90P`}
            >
              <img
                className={`HotSpotImage`}
                src={answer.attributes?.attachments?.data[0]?.attributes?.url}
              />
            </div>
          </Grid>
        );
      case 'sort_list':
        return answer.attributes?.correct_sorting_answers?.map(
          (data: any, index: any) => {
            return (
              <Grid container key={index}>
                <Grid item xs={12} sm={10}>
                  <TextField
                    id="standard-basic"
                    placeholder={`Choice ${String.fromCharCode(65 + index)}`}
                    variant="outlined"
                    disabled
                    value={data}
                  />
                </Grid>
              </Grid>
            );
          }
        );
      case 'label_image_with_text':
      case 'label_image_with_dropdown':
      case 'label_image_with_drag_and_drop':
        return (
          <div
            className={`LabelImageDiv`}
          >
            <div
              className={`PositionRelative`}
            >
              <img
                src={
                  selectedQuestionAnswers[4]?.question_attachment_files[0]
                    ?.attributes?.url
                }
                className="label_question_type_image"
              ></img>

              {boxCoordinates?.map((item: any, index: any) => {
                return (
                  <div
                    key={item?.uniqId || index}
                    style={{
                      top: `${item.top}%`,
                      left: `${item.left}%`,
                    }}
                    className={`BoxCoordinate`}
                    id={`answer-${item.correctAnswerId}`}
                  >
                    <span
                      className={`AnswerText ${classes.bodyText_font}`}
                    >
                      {item.answer}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      case 'match':
        return (
          <Grid
            item
            xs={12}
            className={`MatchGrid`}
          >
            {questionList?.map((data: any, index: number) => (
              <React.Fragment key={index}>
                {(data?.question || data?.src) && (
                  <Grid container className={`QuestionListItemGrid`}>
                    <Grid item xs={5}>
                      <div
                        className={`LeftSideDiv`}
                      >
                        {!data?.src ? (
                          <Typography
                            className={`QuestionText  ${classes.subTitle_font}`}
                          >
                            {data?.question}
                          </Typography>
                        ) : (
                          <>
                            <img
                              src={data.src}
                              className={`QuestionImage`}
                            />
                          </>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      className={`FlexCenter`}
                    >
                      <img src={matchGroup} className="match_grp_img_preview" />
                    </Grid>
                    <Grid item xs={5}>
                      <div
                        className={`RightSideDiv`}
                        id={`answer-${data.correctAnswerId}`}
                      >
                        {!data?.answerSrc ? (
                          <Typography
                            id={`answer-${data.correctAnswerId}`}
                            className={`QuestionText ${classes.subTitle_font}`}
                          >
                            {data?.answer}
                          </Typography>
                        ) : (
                          <>
                            <img
                              id={`answer-${data.correctAnswerId}`}
                              src={data.answerSrc}
                              className={`QuestionImage`}
                            />
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        );
      default:
        break;
    }
  };

  const showGraphTypeAnswers = () => {
    let type = selectedQuestionAnswers[1]?.question_sub_category?.name;

    let graph_properties;
    let graph_axis_settings_attributes;
    let graph_plotting_categories_attributes;
    let graph_lines_attributes: any = [];

    if (type === 'line_chart' || type === 'graph_plotting') {
      graph_properties = {
        show_grid:
          selectedQuestionAnswers[4]?.graph_setting_keys.graph_setting
            .show_grid,
        show_multicolor_bar:
          selectedQuestionAnswers[4]?.graph_setting_keys?.graph_setting
            ?.multicolor_bar,
        display_position_on_hover:
          selectedQuestionAnswers[4]?.graph_setting_keys.graph_setting
            .display_position,
      };
      graph_axis_settings_attributes = {
        x_axis_label:
          selectedQuestionAnswers[4]?.graph_setting_keys.graph_axis_setting[0]
            .axis_label,
        y_axis_label:
          selectedQuestionAnswers[4]?.graph_setting_keys.graph_axis_setting[1]
            .axis_label,
        minimum_value:
          selectedQuestionAnswers[4]?.graph_setting_keys.graph_axis_setting[1]
            .minimum_value,
        maximum_value:
          selectedQuestionAnswers[4]?.graph_setting_keys.graph_axis_setting[1]
            .maximum_value,
        step_size:
          selectedQuestionAnswers[4]?.graph_setting_keys.graph_axis_setting[1]
            .step_size,
      };
      graph_plotting_categories_attributes = selectedQuestionAnswers[4]?.graph_setting_keys.graph_categories.map(
        (categroies: any) => {
          let right_ans;
          selectedQuestionAnswers[3]?.answer?.data[0]?.attributes?.correct_chart_answers?.map(
            (correct: any) => {
              if (correct.uniq_key === categroies.uniq_key) {
                right_ans = correct.right_answer;
              }
            }
          );
          return {
            uniq_key: categroies.uniq_key,
            label: categroies.label,
            initial_value: categroies.initial_value,
            correct_answer_value: right_ans,
            bar_color: generateColor(),
          };
        }
      );
    } else {
      graph_axis_settings_attributes = {
        x_axis_label: selectedQuestionAnswers[3].line_graph_settings[0].label,
        x_minimum_value:
          selectedQuestionAnswers[3].line_graph_settings[0].minimum_value,
        x_maximum_value:
          selectedQuestionAnswers[3].line_graph_settings[0].maximum_value,
        y_axis_label: selectedQuestionAnswers[3].line_graph_settings[1].label,
        y_minimum_value:
          selectedQuestionAnswers[3].line_graph_settings[1].minimum_value,
        y_maximum_value:
          selectedQuestionAnswers[3].line_graph_settings[1].maximum_value,
        common_step_size:
          selectedQuestionAnswers[3].line_graph_settings[0].step_size,
      };

      selectedQuestionAnswers[4]?.answer?.data?.map((lineData: any) => {
        const item = {
          x1: lineData.attributes.line_graph_answer[0].x_axis,
          y1: lineData.attributes.line_graph_answer[0].y_axis,
          x2: lineData.attributes.line_graph_answer[1].x_axis,
          y2: lineData.attributes.line_graph_answer[1].y_axis,
        };
        graph_lines_attributes.push(item);
      });
    }

    switch (type) {
      case 'line_chart':
        return (
          <Grid container>
            <Grid item xs={12}>
              <LineChart
                graph_axis_settings_attributes={graph_axis_settings_attributes}
                graph_plotting_categories_attributes={
                  graph_plotting_categories_attributes
                }
                graph_properties={graph_properties}
              />
            </Grid>
          </Grid>
        );
      case 'graph_plotting':
        return (
          <Grid container>
            <Grid item xs={12}>
              <BarChart
                graph_axis_settings_attributes={graph_axis_settings_attributes}
                graph_plotting_categories_attributes={
                  graph_plotting_categories_attributes
                }
                graph_properties={graph_properties}
              />
            </Grid>
          </Grid>
        );
      case '2x2_grid':
        return (
          <Grid item xs={12} className="MTN20">
            <PlotlyGraph
              setting={graph_axis_settings_attributes}
              graph_lines={graph_lines_attributes}
            />
          </Grid>
        );
    }
  };

  const showMediaTypeAnswers = () => {
    let type = selectedQuestionAnswers[1]?.question_sub_category?.name;

    let audioList: any = [];
    let videosList: any = [];
    selectedQuestionAnswers[4]?.question_attachment_files?.map(
      (item: any, index: number) => {
        if (type === 'audio') {
          const audioItem = {
            id: item?.id,
            upload_id: item?.id,
            file_name: item?.attributes?.file_name,
            file_size: item?.attributes?.file_size,
            file_type: item?.attributes?.content_type,
            file_url: item?.attributes?.url,
            moduleId: item?.attributes?.module_id,
            duration:
              item?.attributes?.duration &&
              moment(item?.attributes?.duration, 'HH:mm:ss').diff(
                moment().startOf('day'),
                'seconds'
              ),
          };

          audioList.push(audioItem);
        } else {
          const videoItem = {
            id: item?.id,
            upload_id: item?.id,
            name: item?.attributes?.file_name,
            size: item?.attributes?.file_size,
            type: item?.attributes?.content_type,
            videoSrc: item?.attributes?.url,
            moduleId: item?.attributes?.module_id,
            duration: item?.attributes?.duration,
          };
          videosList.push(videoItem);
        }
      }
    );

    switch (type) {
      case 'audio':
        return (
          <Grid item xs={12}>
            {audioList.length > 0 &&
              audioList.map((audio: any, index: number) => {
                return <AudioList key={audio.id} audio={audio} />;
              })}
          </Grid>
        );
      case 'video':
        return (
          <Grid item xs={12}>
            {videosList?.map((videoItem: any, index: number) => (
              <React.Fragment key={videoItem?.id}>
                <Grid container>
                  <Grid
                    item
                    xs={11}
                    className="VideoGrid"
                  >
                    <img
                      src={videoThumbnail}
                      className="video-thumbnail-img"
                      alt="thumbnail"
                      onClick={() => selectVideo(videoItem)}
                    />
                    <video
                      id={`video-${index}`}
                      className="video-thumbnail-img"
                      src={videoItem.videoSrc}
                      controls
                      hidden
                    ></video>
                    <div
                      className="ColumnCenter"
                    >
                      <div className={`video-recording-text`}>
                        {videoItem?.name}
                      </div>
                      <div className={`video-recording-duration`}>
                        {videoItem?.duration}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Divider />
              </React.Fragment>
            ))}
          </Grid>
        );
      default:
        break;
    }
  };

  const showRatingTypeAnswers = () => {
    let type = selectedQuestionAnswers[1]?.question_sub_category?.name;
    const stepItems: any = [];
    let stepperActiveStep;
    let selectedStepperRadioValue;
    selectedQuestionAnswers[3]?.answer?.data?.map(
      (answer: any, index: number) => {
        if (answer?.attributes?.right_answer) {
          stepperActiveStep = index;
        }
        stepItems.push({
          id: answer?.id,
          uniqueId: uuidv4(),
          textValue: answer?.attributes?.answer,
          emoji: answer?.attributes?.emoji,
          right_answer: answer?.attributes?.right_answer,
        });
        stepItems?.map((item: any) => {
          if (item?.right_answer) {
            selectedStepperRadioValue = item.uniqueId;
          }
        });
      }
    );

    switch (type) {
      case 'numeric_rating_scale':
      case 'text_rating_scale':
      case 'percentage_rating_scale':
      case 'graphic_rating_scale':
        return (
          <Grid item xs={12}>
            <StepperWithText
              activeStep={stepperActiveStep}
              steps={stepItems}
              onChangeTextValue={(e: any) => {
                e.preventDefault();
              }}
              onRemoveStep={(e: any) => {
                e.preventDefault();
              }}
              isAnswerSection={true}
              onChangeStepperValue={(e: any) => {
                e.preventDefault();
              }}
              selectedStepperRadioValue={selectedStepperRadioValue}
              disableRadioButton
              isEmojiType={type === 'graphic_rating_scale' && true}
              isPercentageType={type === 'percentage_rating_scale' && true}
            />
          </Grid>
        );
    }
  };
  // Customizable Area End

  // Customizable Area Start
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="PreviewQuestionsMainModal"
        open={openModal}
        onClose={() => {
          handleOnClose(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        id="question-preview-modal"
        data-test-id="question-preview-modal"
      >
        <Grid container direction="row" className="PreviewQuestionModal">
          <Grid
            item
            xs={12}
            className={`FlexEnd`}
          >
            <CloseIcon
              className="CloseIcon"
              data-test-id="question-preview-close-icon"
              onClick={() => handleOnClose(false)}
            />
          </Grid>
          <Grid item xs={12}>
            <div className='DFlex'>
              <span className={`${classes.PreviousQuestion_AssessmentQuestionBankLibrary_question_text} ${classes.subTitle_font}`}>{`Q.`}</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: selectedQuestion,
                }}
                className={`${classes.PreviousQuestion_AssessmentQuestionBankLibrary_question_text} ${classes.subTitle_font} ML2P`}
              ></span>
            </div>
          </Grid>
          {selectedQuestionAnswers?.length > 0 && (
            <Grid item xs={12}>
              <Grid
                className='AnswerGrid'
                container
                direction="row"
              >
                <Grid item xs={12}>
                  <div>
                    <Typography
                      className={`${classes.PreviousQuestion_AssessmentQuestionBankLibrary_answer_type_text} ${classes.bodyText_font} MR10`}
                    >
                      {`Answer(s) :`}
                    </Typography>
                  </div>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  className="Content"
                >
                  {selectedQuestionAnswers[1]?.question_sub_category?.name ===
                    'line_chart' ||
                  selectedQuestionAnswers[1]?.question_sub_category?.name ===
                    'graph_plotting' ||
                  selectedQuestionAnswers[1]?.question_sub_category?.name ===
                    '2x2_grid'
                    ? showGraphTypeAnswers()
                    : selectedQuestionAnswers[1]?.question_sub_category
                        ?.name === 'audio' ||
                      selectedQuestionAnswers[1]?.question_sub_category
                        ?.name === 'video'
                    ? showMediaTypeAnswers()
                    : selectedQuestionAnswers[1]?.question_sub_category
                        ?.name === 'numeric_rating_scale' ||
                      selectedQuestionAnswers[1]?.question_sub_category
                        ?.name === 'text_rating_scale' ||
                      selectedQuestionAnswers[1]?.question_sub_category
                        ?.name === 'percentage_rating_scale' ||
                      selectedQuestionAnswers[1]?.question_sub_category
                        ?.name === 'graphic_rating_scale'
                    ? showRatingTypeAnswers()
                    : selectedQuestionAnswers[3]?.answer?.data?.map(
                        (answer: any, index: number) => {
                          const alphabet = String.fromCharCode(65 + index);
                          return showAnswerOfQuestionTypes(
                            answer,
                            alphabet,
                            index
                          );
                        }
                      )}
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            className="FlexCenter"
          >
            <Button
              onClick={() => handleOnClose(false)}
              data-test-id="question-preview-ok-btn"
              className={`OkButton ${classes.button_color}`}
            >
              Ok
            </Button>
          </Grid>
        </Grid>
      </Modal>

      <PreviewVideoModal
        onCloseModal={onClosePreviewVideoModal}
        openPreviewVideoModal={openPreviewVideoModal}
        videoSrc={selectedVideoSrc}
      />
    </>
  );
  // Customizable Area End
};

// Customizable Area Start
export default withStyles(themeCreateStyle)(QuestionPreview);
// Customizable Area End
