// Customizable Area Start
import React from "react";
import AssessmentLibraryController, { Props } from "./AssessmentLibraryController.web";
import {
    Typography,
    Tab,
    Tabs,
    Box,
    withStyles} from "@material-ui/core";
import Spinner from "../../../shared/SideBar/src/Spinner";
import QuestionBank from "./QuestionBank.web";
import AssessmentTab from "./AssessmentTab.web";
import "./AssessmentLibrary.web.css";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import { KeyboardBackspaceOutlined } from '@material-ui/icons';
// Customizable Area End
// Customizable Area Start
// Customizable Area End

export class AssessmentLibrary extends AssessmentLibraryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { classes } = this.props;
        return (
            <>
                <div>
                    {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
                    <div
                       className="BackBtnDiv"
                    >
                        <Box
                        display="flex"
                            className="BackBtn"
                            data-test-id="assessment-library-back"
                            onClick={(): void => {
                                this.props.navigation.navigate("TeacherAssessment")
                            }}
                        >
                            <KeyboardBackspaceOutlined className={classes.icon_color} />
                            <Typography
                                className={`BackBtnText ${classes.bodyText_font}`}
                            >{"   "}Back</Typography>
                        </Box>
                        <Typography
                            className={`BackBtnHeadingText ${classes.bodyText_font}`}
                        >
                            Assessment Library
                        </Typography>
                    </div>
                    <Box
                        className="TabBox"
                        display="flex"
                        flexDirection="row"
                    >
                        <Tabs
                            value={this.state.tabValue}
                            onChange={(event, value) =>
                                this.handleTansChange.onChangeTab(value)
                            }
                            data-test-id="assessment-library-tabs"
                            classes={{ indicator: classes.AssessmentLibraryActiveTabBorderBottom }}
                            variant="scrollable"
                            className={`Width100`}
                        >
                            <Tab
                                className={`LibraryTab ${this.props.classes.subHeading_font}`}
                                label="Question Bank"
                                id={`simple-tab-${this.state.tabValue}`}
                            />
                            <Tab
                                className={`LibraryTab ${this.props.classes.subHeading_font}`}
                                label="Assessments"
                                id={`simple-tab-${this.state.tabValue}`}
                            />
                        </Tabs>
                    </Box>

                    <Box>
                        {this.state.tabValue === 0 && (
                            <div className="LibraryTabDetail">
                                <QuestionBank />
                            </div>
                        )}
                    </Box>
                    <Box>
                        {this.state.tabValue === 1 && (
                            <div className="libraryTab">
                                <AssessmentTab />
                            </div>
                        )}
                    </Box>
                </div>
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start

export default withStyles(themeCreateStyle)(AssessmentLibrary);

// Customizable Area End
