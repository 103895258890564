// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstname: string;
  lastname: string;
  email: string;
  phonenumber: string;
  nameofInstitute: string;
  numberOfStudents: string;
  instituteType: string;
  fnameerror: boolean;
  fnameerrorText: string;
  lnameerror: boolean;
  lnameerrorText: string;
  emailerror: boolean;
  emailerrorText: string;
  phoneerrorText: string;
  phoneerror: boolean;
  instituteerror: boolean,
  instituteerrorText: string,
  studenterror: boolean,
  studenterrorText: string,
  loadingSpinner: boolean,
  instituteTypeerror: boolean,
  dialCode: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AdminAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  postLoginApiId: any;
  emailReg: RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      phonenumber: '',
      nameofInstitute: '',
      numberOfStudents: '',
      instituteType: '',
      fnameerror: false,
      fnameerrorText: '',
      lnameerror: false,
      lnameerrorText: '',
      emailerror: false,
      emailerrorText: '',
      phoneerror: false,
      phoneerrorText: '',
      dialCode: "af",

      instituteerror: false,
      instituteerrorText: '',

      studenterror: false,
      studenterrorText: '',
      instituteTypeerror: false,

      loadingSpinner: false,

    };
    this.emailReg = new RegExp("");
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.postLoginApiId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            localStorage.setItem('user_data', JSON.stringify(responseJson.data.attributes));
            this.props.navigation.navigate("AdminDashboard");
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { firstname, lastname, phonenumber, email, nameofInstitute, numberOfStudents, instituteType } = this.state;
    this.emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (firstname.length === 0 || lastname.length === 0 || phonenumber.length === 0 || email.length === 0 || nameofInstitute.length === 0 || numberOfStudents.length === 0 || instituteType.length === 0) {
      if (
        this.state.firstname === null ||
        this.state.firstname.length === 0
      ) {
        this.setState({ fnameerror: true, fnameerrorText: 'Firstname is required.' });

      }
      if (
        this.state.lastname === null ||
        this.state.lastname.length === 0
      ) {
        this.setState({ lnameerror: true, lnameerrorText: 'Lastname is required.' });

      }
      if (
        this.state.email === null ||
        this.state.email.length === 0
      ) {
        this.setState({ emailerror: true, emailerrorText: 'Email is required.' });

      }
      if (
        this.state.phonenumber === null ||
        this.state.phonenumber.length === 0
      ) {
        this.setState({ phoneerror: true, phoneerrorText: 'Phone number is required.' });

      }
      if (this.state.nameofInstitute === null || this.state.nameofInstitute.length === 0) {
        this.setState({ instituteerror: true, instituteerrorText: 'Name of institute is required.' });
      }

      if (this.state.numberOfStudents === null || this.state.numberOfStudents.length === 0) {
        this.setState({ studenterror: true, studenterrorText: "Number of students is required." });

      }
      if (this.state.instituteType === null || this.state.instituteType.length === 0) {
        this.setState({ instituteTypeerror: true });

      }
      return false;
    }
    const data = {
      data: {
        attributes: {
          first_name: firstname,
          last_name: lastname,
          email: email,
          phone_number: phonenumber,
          name_of_institute: nameofInstitute,
          number_of_student: numberOfStudents,
          institute_type: instituteType,
        }
      }
    }
    this.setState({ loadingSpinner: true })
    const header = {
      'Content-Type': configJSON.contentTypeApiAddDetail,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postLoginApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsrequestDemoEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End

}
