// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  typeofaccount: string;
  btnDisabled: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      typeofaccount: '',
      btnDisabled: true,
    };
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  handleEnter = (event: any) => {
    if (event.key === 'Enter' && !this.state.btnDisabled) {
      this.onSubmit();
    }
  }
  async componentDidMount() {
    window.addEventListener('keydown', this.handleEnter)
  }
  async componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEnter)
  }
  onAccountClick = (event: any) => {
    localStorage.removeItem("user_data")
    this.setState({ typeofaccount: event.target.value, btnDisabled: false })
    if (event.target.value === 'student_account') {
      this.props.navigation.navigate("StudentAccountRegistration")
    }
    if (event.target.value === 'teacher_account') {
      this.props.navigation.navigate("TeacherAccountRegistration")
    }
    if (event.target.value === 'parent_account') {
      this.props.navigation.navigate("ParentAccountRegistration")
    }
    if (event.target.value === 'admin') {
      this.props.navigation.navigate("AdminAccountRegistration")
    }
  }
  onSubmit = () => {
    const { typeofaccount } = this.state;

    if (typeofaccount === 'student_account') {
      this.props.navigation.navigate("StudentAccountRegistration")
    }
    if (typeofaccount === 'teacher_account') {
      this.props.navigation.navigate("TeacherAccountRegistration")
    }
    if (typeofaccount === 'parent_account') {
      this.props.navigation.navigate("ParentAccountRegistration")
    }
    if (typeofaccount === 'admin') {
      this.props.navigation.navigate("AdminAccountRegistration")
    }
  }
  onBackArrow = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }
  // Customizable Area End
}
