// Customizable Area Start
import React from "react"
import Grid from '@material-ui/core/Grid';
import {
    Box,
    Button,
    InputBase,
    NativeSelect,
    TextField,
    Typography,
    Popover
} from "@material-ui/core";
import { withStyles, Theme } from '@material-ui/core/styles';
import FilterScreenController, { Props } from "./FilterScreenController.web";
import ClearIcon from '@material-ui/icons/Clear';
import FilterSubject from "./FilterSubject.web";
import FilterDivision from "./FilterDivision.web";
import './FilterScreen.web.css'
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class FilterScreen extends FilterScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const BootstrapInput5 = withStyles((theme: any) => ({
            root: {
                "label + &": {
                    marginTop: theme.spacing(1),
                },
            },
            input: {
                borderRadius: 4,
                position: "relative",
                backgroundColor: "#fff",
                padding: "5px 10px",
                margin: "10px 0px",
                transition: theme.transitions.create(["border-color", "box-shadow"]),
                fontFamily: theme.props.textFont,
                color: theme.props.textColor,
                border: `solid 0.3px ${theme.props.buttonColor}`,
                fontWeight: 600,
                fontSize: "1rem",
                "&:focus": {
                    borderRadius: 4,
                    borderColor: "#80bdff",
                    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
                },
            },
        }))(InputBase);
        const { divisionData, subjectData, gradeData, subject_data, division_data } = this.state
        return (
            <>
                <Popover className="filterpopup filter_modal_popover_box"
                    id="1"
                    anchorEl={this.props.filterAnchorEl}
                    open={Boolean(this.props.filterAnchorEl)}
                    onClose={this.props.popoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style: {
                            width: '25%',
                        },
                    }}
                >
                    <Box className="filter_container">
                        <Box className="filter_flex_box">
                            <Typography
                                className={`${this.props.classes.title_font} filter_heading`}
                            >
                                Filter by
                            </Typography>
                            <Typography
                                className="filer_clear_text"
                                onClick={() => {
                                    this.setState({
                                        subjectData: [],
                                        subject_data: [],
                                        division_data: [],
                                        divisionData: [],
                                        grade_id: '',
                                        className: '',
                                    })
                                }}
                            >
                                Clear
                            </Typography>
                        </Box>
                        <form onSubmit={this.applyfilter}>
                            <Box className="filter_bottom_border">
                            </Box>
                            <Box>
                                <Typography
                                    className={`${this.props.classes.subTitle_font} filter_input_label filter_mb_5 filter_mt_10`}
                                >
                                    Select Subject
                                </Typography>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        padding: subject_data.length != 0 ? "0px 5px 5px 5px" : "12px",
                                    }}
                                    className={`${this.props.classes.filterScreeen_classAndSection_border_Color} filter_inner_modal`}
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        this.setState({ dropDownAnchorEl: event.currentTarget })
                                    }
                                    }
                                >
                                    {subject_data.map((data: any) => (
                                        <Grid item xs={3} className="filter_input_box">
                                            <Box className="filter_input_box_text_box">
                                                <Typography className={`${this.props.classes.bodyText_font} filter_input_box_text_box_typo`}
                                                >
                                                    {data.subject_name}
                                                </Typography>
                                                <ClearIcon
                                                    className="filter_input_box_clear_icon"
                                                    onClick={(event: any) => this.removeSubject(event, data.id)}
                                                />
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                                {this.state.dropDownAnchorEl
                                    &&
                                    <FilterSubject
                                        dropDownAnchorEl={this.state.dropDownAnchorEl}
                                        data_Type='subject'
                                        closeModalFun={() => {
                                            this.setState({ dropDownAnchorEl: null });
                                        }}
                                        datasaveModalFun={this.dataSubjectSave}
                                        subjectData={this.state.subjectData} navigation={undefined} />}
                            </Box>
                            <Box>
                                <Typography
                                    className={`${this.props.classes.subTitle_font} filter_input_label`}
                                >
                                    Enter ClassName
                                </Typography>
                                <TextField
                                    id="standard-basic"
                                    type="text"
                                    value={this.state.className}
                                    autoComplete="off"
                                    className="filter_textfield"
                                    variant="outlined"
                                    inputProps={{
                                        style: {
                                            fontFamily: "Open sans",
                                            fontSize: 15.8,
                                            color: "#03014c",
                                            padding: "5px",
                                        },
                                    }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = event.target.value.trim();
                                        this.setState({ className: event.target.value })
                                    }}
                                />
                            </Box>
                            <Box>
                                <Typography
                                    className={`${this.props.classes.subTitle_font} filter_input_label`}
                                >
                                    Select Grade
                                </Typography>

                                <NativeSelect
                                    id="select"
                                    value={this.state.grade_id}
                                    input={<BootstrapInput5 />}
                                    className="filter_full_width"
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { this.setState({ grade_id: event.target.value }) }}

                                >
                                    <option value="">Select Grade</option>
                                    {gradeData.map((data: any) => (
                                        <>
                                            <option value={data.id}>{data.attributes.school_grade_name}</option>
                                        </>
                                    ))}
                                </NativeSelect>
                            </Box>
                            <Box>
                                <Typography
                                    className={`${this.props.classes.subTitle_font} filter_input_label`}
                                >
                                    Select Division
                                </Typography>
                                <Grid container
                                    spacing={2}
                                    style={{
                                        padding: division_data.length != 0 ? "0px 5px 5px 5px" : "12px",
                                    }}
                                    className={`${this.props.classes.filter_classAndSection_border_Color} filter_flex_box_row filter_inner_modal`}
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        this.setState({ divisiondropDownAnchorEl: event.currentTarget })
                                    }
                                    }
                                >
                                    {divisionData.map((data: any) => (
                                        data.checked &&
                                        <Grid item xs={3} className="filter_input_box"
                                        >
                                            <Box className="filter_input_box_text_box">
                                                <Typography className={`${this.props.classes.bodyText_font} filter_input_box_text_box_typo`}>
                                                    {data.attributes.name}
                                                </Typography>
                                                <ClearIcon
                                                    className="filter_input_box_clear_icon"
                                                    onClick={(event: any) => this.removeDivision(event, data.id)}
                                                />
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                                {this.state.divisiondropDownAnchorEl && <FilterDivision divisiondropDownAnchorEl={this.state.divisiondropDownAnchorEl} closeModalFun={() => { this.setState({ divisiondropDownAnchorEl: null }); }} datasaveDivisionModalFun={this.savedivisionModal} divisionData={this.state.divisionData} navigation={undefined} subjectData={undefined} />}
                            </Box>
                            <Grid container className="filter_mt_20">
                                <Grid item xs={12}>
                                    <Button
                                        disableRipple={true}
                                        className={`${this.props.classes.button_color} filter_apply_button`}
                                        type='submit'
                                    >
                                        Apply
                                    </Button>
                                    <Button
                                        disableRipple={true}
                                        variant="contained"
                                      
                                        className={`${this.props.classes.button_color_disabled} filter_cancel_button`}
                                        onClick={this.props.popoverClose}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Popover>
            </>
        )
    }
    // Customizable Area End

}

// Customizable Area Start
export default withStyles(themeCreateStyle)(FilterScreen);
// Customizable Area End
