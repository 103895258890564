// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  NativeSelect,
  TextField,
  Typography,
  FormLabel,
  Switch,
  FormHelperText,
  ListItemText,
  Checkbox,
  ListItemIcon,
  Grid,
  InputBase,
} from "@material-ui/core";
import StudentProfileController, { Props } from "./StudentProfileController";
import {
  genderList,
  nationalityList,
  religionList,
  languageList,
  categoryList,
} from "./utils/constants";
import { createVideoIcon } from "./assets";
import moment from "moment";
import { checkFieldLengthForNumber, checkNameField } from "./utils/common";
import "react-circular-progressbar/dist/styles.css";
import { withStyles } from "@material-ui/core/styles";
import DeleteVideoModal from "./DeleteVideoModal.web";
import VideoUploadSuccessModal from "./VideoUploadSuccessModal.web";
import UploadVideoPercentageModal from "./UploadVideoPercentageModal.web";
import CreateVideoModal from "./CreateVideoModal.web";
import PreviewVideoModal from "./PreviewVideoModal.web";
import AlertModal from "../../alert/src/AlertModal.web";
import VideoRecordViaWebcamModal from "./VideoRecordViaWebcamModal.web";
import PreviewUploadedVideo from "./PreviewUploadedVideo.web";
import "./StudentProfile.web.css";
import Datetime from "react-datetime";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    backgroundColor: "none",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ebebeb",
    paddingLeft: 14,
    height: 35,
  },
  error: {
    borderColor: "red !important",
  },
}))(InputBase);
// Customizable Area End

export class StudentProfile extends StudentProfileController {
  stopVideoRecording: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.stopVideoRecording = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  updateStateOnClose = () => {
    this.setState({ createVideo: {} });
  };

  today = moment();
  disableFutureDt = (current: any) => {
    return current.isBefore(this.today);
  };

  parseDate = (str: any) => {
    var m = str.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/);
    return (m) ? new Date(m[3], m[2] - 1, m[1]) : null;
  }

  handleDate = async (date: any) => {
    const prop = "date_of_birth"

    if (typeof (date) == "string") {
      // const Regex =  new RegExp('/^[-+]?[0-9]*\.?[0-9]+$/')
      const format = this.parseDate(date)
      if (date.trim().length != 10 && format == null) {
        await this.setState({
          studentPersonalDetailsError: {
            ...this.state.studentPersonalDetailsError,
            date_of_birth: true,
            messages: {
              ...this.state.studentPersonalDetailsError.messages,
              date_of_birth: "The date format must be dd-mm-yyyy",
            },

          }
        })

        this.setState({
          studentPersonalDetails: {
            ...this.state.studentPersonalDetails,
            attributes: {
              ...this.state.studentPersonalDetails.attributes,
              date_of_birth: date
            },
          }
        })
      }

    } else {
      const format = moment(date).format('YYYY-MM-DD')
      if (prop === 'date_of_birth') {
        const currentDate = moment().format('YYYY-MM-DD').replaceAll("-", "")
        const dateOfBirth = format.replaceAll("-", "")
        if (currentDate < dateOfBirth) {
          await this.setState({
            studentPersonalDetailsError: {
              ...this.state.studentPersonalDetailsError,
              date_of_birth: true,
              messages: {
                ...this.state.studentPersonalDetailsError.messages,
                date_of_birth: "date of birth can't be future date",
              },

            }
          })
        } else {
          const dateFormat = moment(date).format('DD-MM-YYYY')
          await this.setState({
            studentPersonalDetailsError: {
              ...this.state.studentPersonalDetailsError,
              date_of_birth: false,
              messages: {
                ...this.state.studentPersonalDetailsError.messages,
                date_of_birth: "",
              },
            }
          })
          this.setState({
            studentPersonalDetails: {
              ...this.state.studentPersonalDetails,
              attributes: {
                ...this.state.studentPersonalDetails.attributes,
                date_of_birth: format
              },
            }
          })
        }
      }
    }

  }


  render() {
    const {
      studentPersonalDetails,
      occupation,
      classes,
      studentPersonalDetailsError,
      openCreateVideoModal,
      createVideo,
      uploadVideoPercentage,
      openPreviewVideoModal,
      introVideoFile,
      videoValidations
    } = this.state;
    const open = Boolean(this.state.videoAnchorEl);

    return (
      <form onSubmit={this.savePersonalInfo}>
        <Box
          className="personal_info_main_div"
          tabIndex={0}
          onKeyDown={this.handleEnter("savePersonalInfo")}
          id="educationalInfoForm"
        >
          <Grid
            container
            direction="row"
            className="PersonalInfoContainer"
          >
            <Grid item xs={12}>
              <Grid
                container
                spacing={5}
                className="PersonalInfoDetail"
              >
                <Grid item xs={12} sm={6}>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label_top ${this.props.classes.bodyText_font}`}>
                        Name*
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={this.props.classes.textField}
                        variant={"outlined"}
                        onKeyPress={(event) => checkNameField(event)}
                        onChange={this.inputEvent("name")}
                        value={studentPersonalDetails?.attributes?.name}
                        error={studentPersonalDetailsError?.name}
                        helperText={studentPersonalDetailsError?.messages?.name}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className="spacing_pt_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label_top ${this.props.classes.bodyText_font}`}>
                        Date of Birth*
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <Datetime
                        dateFormat={"DD-MM-YYYY"}
                        timeFormat={false}
                        strictParsing={true}
                        input={true}
                        value={
                          studentPersonalDetailsError?.date_of_birth
                            ? studentPersonalDetails?.attributes?.date_of_birth
                            : studentPersonalDetails?.attributes
                              ?.date_of_birth &&
                            moment(
                              studentPersonalDetails?.attributes
                                ?.date_of_birth
                            ).format("DD-MM-YYYY")
                        }
                        className={
                          studentPersonalDetailsError?.date_of_birth
                            ? "input_date_of_birth_css_error_format"
                            : 
                            "input_date_of_birth_css_format"
                        }
                        inputProps={{
                          placeholder: "dd-mm-yyyy",
                          className: "StudentProfile Date_of_birth_calendar_Icon_register",
                        }}
                        onClose={() => {
                          if (
                            studentPersonalDetails?.attributes?.date_of_birth &&
                            studentPersonalDetails?.attributes?.date_of_birth
                              .length === 0
                          ) {
                            let { studentPersonalDetailsError } = this.state;
                            studentPersonalDetailsError = {
                              ...studentPersonalDetailsError,
                              date_of_birth: true,
                              messages: {
                                ...studentPersonalDetailsError.messages,
                                date_of_birth: "Date of birth is required.",
                              },
                            };
                            this.setState({
                              studentPersonalDetailsError: studentPersonalDetailsError,
                            });
                          }
                        }}
                        closeOnClickOutside
                        closeOnSelect
                        isValidDate={this.disableFutureDt}
                        onChange={(date) => {
                          this.handleDate(date);
                        }}
                      />
                      <FormHelperText className="PersonalInfoErrorText">
                        {studentPersonalDetailsError?.messages?.date_of_birth}
                      </FormHelperText>               
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={5}
                className="PersonalInfoDetail"
              >
                <Grid item xs={12} sm={6} className="spacing_pb_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Gender*
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <NativeSelect
                        input={<BootstrapInput />}
                        className={`${this.props.classes.selectDropDown} ${this.props.classes.bodyText_font}`}
                        value={studentPersonalDetails?.attributes?.gender}
                        onChange={this.inputEvent("gender")}
                        error={studentPersonalDetailsError?.gender}
                      >
                        <option value="" hidden></option>
                        {genderList.map((item: any) => (
                          <option value={item.value}> {item.label} </option>
                        ))}
                      </NativeSelect>
                      {studentPersonalDetailsError?.gender && (
                        <FormHelperText className="PersonalInfoErrorText">
                          Please select gender.
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className="spacing_pt_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Email Id*
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={this.props.classes.textField}
                        size="small"
                        id="email"
                        variant="outlined"
                        type="email"
                        value={studentPersonalDetails?.attributes?.email}
                        onChange={this.inputEvent("email")}
                        error={studentPersonalDetailsError?.email}
                        helperText={
                          studentPersonalDetailsError?.messages?.email
                        }
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={5}
                className="PersonalInfoDetail"
              >
                <Grid item xs={12} sm={6} className="spacing_pb_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Student Aadhar Card No.
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={this.props.classes.textField}
                        variant={"outlined"}
                        size={"small"}
                        type="number"
                        onKeyPress={(event) =>
                          checkFieldLengthForNumber(event, 12)
                        }
                        error={studentPersonalDetailsError?.aadhar_card_no}
                        helperText={
                          studentPersonalDetailsError?.messages?.aadhar_card_no
                        }
                        onChange={this.inputEvent("aadhar_card_no")}
                        value={
                          studentPersonalDetails?.attributes?.aadhar_card_no
                        }
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 12);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className="spacing_pt_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Class
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={this.props.classes.textField}
                        size="small"
                        id="admissionClass"
                        variant="outlined"
                        type="admissionClass"
                        value={
                          studentPersonalDetails?.attributes?.admission_class
                        }
                        error={studentPersonalDetailsError?.admission_class}
                        helperText={
                          studentPersonalDetailsError?.messages?.admission_class
                        }
                        disabled
                      />                     
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                    Self Video Introduction
                  </FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    className={`create-video-btn ${this.props.classes.button_color}`}
                    onClick={() => {
                      if (introVideoFile) {
                        this.setState({ openPreviewVideoModal: true });
                      } else {
                        this.setState({ openCreateVideoModal: true });
                      }
                    }}
                  >
                    <img
                      src={createVideoIcon}
                      className="CreateVideoIcon"
                    />

                    {introVideoFile ? "Preview Video" : "Create Video"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={5}>
                <Grid item xs={12} sm={6} className="spacing_pb_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Father's Name*
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={this.props.classes.textField}
                        variant={"outlined"}
                        size={"small"}
                        error={studentPersonalDetailsError?.father_name}
                        helperText={
                          studentPersonalDetailsError?.messages?.father_name
                        }
                        onKeyPress={(event) => checkNameField(event)}
                        onChange={this.inputEvent("father_name")}
                        value={studentPersonalDetails?.attributes?.father_name}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className="spacing_pt_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Father's Occupation*
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <NativeSelect
                        input={<BootstrapInput />}
                        className={this.props.classes.selectDropDown}
                        value={`${studentPersonalDetails?.attributes?.father_occupation}`}
                        onChange={this.inputEvent("father_occupation")}
                        error={studentPersonalDetailsError?.father_occupation}
                      >
                        <option value="" hidden></option>
                        {occupation.length > 0 &&
                          occupation.map((val: any, ind: number) => (
                            <option value={val.attributes.name} key={ind}>
                              {val.attributes.name}
                            </option>
                          ))}
                      </NativeSelect>
                      {studentPersonalDetailsError?.father_occupation && (
                        <FormHelperText
                          className={`PersonalInfoErrorText ${this.props.classes.bodyText_font}`}
                        >
                          Please select father occupation.
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="ParentNotificationDiv" item xs={12}>
              <Switch
                checked={
                  `${studentPersonalDetails?.attributes?.notification_to_father}` ===
                  "true"
                }
                className={this.props.classes.toggleButton}
                onChange={this.inputEvent("notification_to_father")}
              />
              <FormLabel
                className={`profile_info_label ${this.props.classes.bodyText_font} ParentNotificationLabel`}>
                Enable Notification to Father's Mobile
              </FormLabel>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={5}>
                <Grid item xs={12} sm={6} className="spacing_pb_0 spacing_pt_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Mother's Name*
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={this.props.classes.textField}
                        variant={"outlined"}
                        size={"small"}
                        onKeyPress={(event) => checkNameField(event)}
                        error={studentPersonalDetailsError?.mother_name}
                        helperText={
                          studentPersonalDetailsError?.messages?.mother_name
                        }
                        onChange={this.inputEvent("mother_name")}
                        value={studentPersonalDetails?.attributes?.mother_name}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className="spacing_pt_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Mother's Occupation*
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <NativeSelect
                        input={<BootstrapInput />}
                        className={this.props.classes.selectDropDown}
                        value={`${studentPersonalDetails?.attributes?.mother_occupation}`}
                        onChange={this.inputEvent("mother_occupation")}
                        error={studentPersonalDetailsError?.mother_occupation}
                      >
                        <option value="" hidden></option>
                        {occupation.length > 0 &&
                          occupation.map((val: any, ind: number) => (
                            <option value={val.attributes.name} key={ind}>
                              {val.attributes.name}
                            </option>
                          ))}
                      </NativeSelect>
                      {studentPersonalDetailsError?.mother_occupation && (
                        <FormHelperText
                          className={`PersonalInfoErrorText ${this.props.classes.bodyText_font}`}
                        >
                          Please select mother occupation.
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="ParentNotificationDiv" item xs={12}>
              <Switch
                className={this.props.classes.toggleButton}
                checked={
                  `${studentPersonalDetails?.attributes?.notification_to_mother}` ===
                  "true"
                }
                onClick={this.inputEvent("notification_to_mother")}
              />
              <FormLabel
                className={`profile_info_label xs_mb_0 ${this.props.classes.bodyText_font} ParentNotificationLabel`}>
                Enable Notification to Mother's Mobile
              </FormLabel>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                    Father's/Mother's Official Address*
                  </FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={this.props.classes.textField}
                    variant={"outlined"}
                    size={"small"}
                    error={studentPersonalDetailsError?.offical_address}
                    helperText={
                      studentPersonalDetailsError?.messages?.offical_address
                    }
                    onChange={this.inputEvent("offical_address")}
                    value={studentPersonalDetails?.attributes?.offical_address}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={5}>
                <Grid item xs={12} sm={6} className="spacing_pb_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Telephone No. (Home)*
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      type="number"
                      variant={"outlined"}
                      size={"small"}
                      onChange={this.inputEvent("home_phone_no")}
                      value={studentPersonalDetails?.attributes?.home_phone_no}
                      onKeyPress={(event) =>
                        checkFieldLengthForNumber(event, 15)
                      }
                      error={studentPersonalDetailsError?.home_phone_no}
                      helperText={
                        studentPersonalDetailsError?.messages?.home_phone_no
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className="spacing_pt_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Telephone No. (Office)
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant={"outlined"}
                      size={"small"}
                      type="number"
                      onKeyPress={(event) =>
                        checkFieldLengthForNumber(event, 15)
                      }
                      onChange={this.inputEvent("office_phone_no")}
                      value={
                        studentPersonalDetails?.attributes?.office_phone_no
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={5}>
                <Grid item xs={12} sm={6} className="spacing_pb_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Residential Address*
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={this.props.classes.textField}
                        variant={"outlined"}
                        size={"small"}
                        error={studentPersonalDetailsError?.residential_address}
                        helperText={
                          studentPersonalDetailsError?.messages
                            ?.residential_address
                        }
                        onChange={this.inputEvent("residential_address")}
                        value={
                          studentPersonalDetails?.attributes
                            ?.residential_address
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className="spacing_pt_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Nationality*
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <NativeSelect
                        input={<BootstrapInput />}
                        className={`${this.props.classes.selectDropDown} ${this.props.classes.bodyText_font}`}
                        value={`${studentPersonalDetails?.attributes?.nationality}`}
                        onChange={this.inputEvent("nationality")}
                        error={studentPersonalDetailsError?.nationality}
                      >
                        <option value="" hidden></option>
                        {nationalityList.map((item: any, ind: number) => (
                          <option value={item.value} key={ind}>
                            {item.label}
                          </option>
                        ))}
                      </NativeSelect>
                      {studentPersonalDetailsError?.nationality && (
                        <FormHelperText
                          className={`PersonalInfoErrorText ${this.props.classes.bodyText_font}`}
                        >
                          Please select nationality.
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={5}>
                <Grid item xs={12} sm={6} className="spacing_pb_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Religion*
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <NativeSelect
                        input={<BootstrapInput />}
                        className={`${this.props.classes.selectDropDown} ${this.props.classes.bodyText_font}`}
                        value={`${studentPersonalDetails?.attributes?.religion}`}
                        onChange={this.inputEvent("religion")}
                        error={studentPersonalDetailsError?.religion}
                      >
                        <option value="" hidden></option>
                        {religionList.map((item: any, ind: number) => (
                          <option value={item.value} key={ind}>
                            {item.label}
                          </option>
                        ))}
                      </NativeSelect>
                      {studentPersonalDetailsError?.religion && (
                        <FormHelperText
                          className={`PersonalInfoErrorText ${this.props.classes.bodyText_font}`}
                        >
                          Please select religion.
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className="spacing_pt_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Category*
                      </FormLabel>                    
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        className={this.props.classes.selectDropDown}
                        value={
                          studentPersonalDetails?.attributes?.category
                            ? studentPersonalDetails?.attributes?.category
                            : ""
                        }
                        onChange={this.inputEvent("category")}
                        variant="outlined"
                        inputProps={{
                          id: "select-multiple-native",
                          style: {
                            borderColor: studentPersonalDetailsError?.category
                              ? "1px solid red"
                              : "#ebebeb",
                          },
                        }}
                        error={studentPersonalDetailsError?.category}
                        MenuProps={{ getContentAnchorEl: null }}
                        renderValue={() => {
                          return studentPersonalDetails?.attributes?.category;
                        }}
                      >
                        {categoryList.map((item: any, ind: number) => (
                          <MenuItem key={item.value} value={item.value}>
                            <ListItemText primary={item.label} />
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  studentPersonalDetails?.attributes
                                    ?.category === item.label
                                }
                                style={{
                                  color:
                                    studentPersonalDetails?.attributes
                                      ?.category === item.label
                                      ? "#af38cb"
                                      : "#3f526d",
                                }}
                              />
                            </ListItemIcon>
                          </MenuItem>
                        ))}
                      </Select>
                      {studentPersonalDetailsError?.category && (
                        <FormHelperText className="PersonalInfoErrorText">
                          {studentPersonalDetailsError?.messages?.category}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={5}>
                <Grid item xs={12} sm={6} className="spacing_pb_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        BPL/EWS Certificate No.
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={this.props.classes.textField}
                        variant={"outlined"}
                        size={"small"}
                        type="number"
                        onKeyPress={(event) =>
                          checkFieldLengthForNumber(event, 12)
                        }
                        error={studentPersonalDetailsError?.bpl_certificate_no}
                        helperText={
                          studentPersonalDetailsError?.messages
                            ?.bpl_certificate_no
                        }
                        onChange={this.inputEvent("bpl_certificate_no")}
                        value={
                          studentPersonalDetails?.attributes?.bpl_certificate_no
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className="spacing_pt_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        BPL/EWS Certificate Issuing Authority
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={this.props.classes.textField}
                        variant={"outlined"}
                        size={"small"}
                        onKeyPress={(event) => checkNameField(event)}
                        error={
                          studentPersonalDetailsError?.bpl_certificate_issuing_authority
                        }
                        helperText={
                          studentPersonalDetailsError?.messages
                            ?.bpl_certificate_issuing_authority
                        }
                        onChange={this.inputEvent(
                          "bpl_certificate_issuing_authority"
                        )}
                        value={
                          studentPersonalDetails?.attributes
                            ?.bpl_certificate_issuing_authority
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={5}>
                <Grid item xs={12} sm={6} className="spacing_pb_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Language Known (Written)*
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        className={this.props.classes.selectDropDown}
                        variant="outlined"
                        inputProps={{
                          id: "select-multiple-native",
                          style: {
                            borderColor: studentPersonalDetailsError?.written_language
                              ? "1px solid red"
                              : "#ebebeb",
                          },
                        }}
                        value={
                          studentPersonalDetails?.attributes?.written_language
                            ? studentPersonalDetails?.attributes
                              ?.written_language
                            : []
                        }
                        onChange={this.inputEvent("written_language")}
                        multiple
                        renderValue={() => {
                          return studentPersonalDetails?.attributes?.written_language?.join(
                            ", "
                          );
                        }}
                        error={studentPersonalDetailsError?.written_language}
                        MenuProps={{ getContentAnchorEl: null }}
                      >
                        {languageList.map((item: any, ind: number) => (
                          <MenuItem key={item.value} value={item.value}>
                            <ListItemText primary={item.label} />
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  studentPersonalDetails?.attributes?.written_language?.indexOf(
                                    item.label
                                  ) > -1
                                }
                                style={{
                                  color:
                                    studentPersonalDetails?.attributes?.written_language?.indexOf(
                                      item.label
                                    ) > -1
                                      ? "#af38cb"
                                      : "#3f526d",
                                }}
                              />
                            </ListItemIcon>
                          </MenuItem>
                        ))}
                      </Select>
                      {studentPersonalDetailsError?.written_language && (
                        <FormHelperText className="PersonalInfoErrorText">
                          Please select written language.
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className="spacing_pt_0">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <FormLabel className={`profile_info_label ${this.props.classes.bodyText_font}`}>
                        Language Known (Spoken)*
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        className={this.props.classes.selectDropDown}
                        variant="outlined"
                        inputProps={{
                          id: "select-multiple-native",
                          style: {
                            borderColor: studentPersonalDetailsError?.spoken_language
                              ? "1px solid red"
                              : "#ebebeb",
                          },
                        }}
                        value={
                          studentPersonalDetails?.attributes?.spoken_language
                            ? studentPersonalDetails?.attributes
                              ?.spoken_language
                            : []
                        }
                        onChange={this.inputEvent("spoken_language")}
                        multiple
                        renderValue={() => {
                          return studentPersonalDetails?.attributes?.spoken_language?.join(
                            ", "
                          );
                        }}
                        error={studentPersonalDetailsError?.spoken_language}
                        MenuProps={{ getContentAnchorEl: null }}
                      >
                        {languageList.map((item: any, ind: number) => (
                          <MenuItem key={item.value} value={item.value}>
                            <ListItemText primary={item.label} />
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  studentPersonalDetails?.attributes?.spoken_language?.indexOf(
                                    item.label
                                  ) > -1
                                }
                                style={{
                                  color:
                                    studentPersonalDetails?.attributes?.spoken_language?.indexOf(
                                      item.label
                                    ) > -1
                                      ? "#af38cb"
                                      : "#3f526d",
                                }}
                              />
                            </ListItemIcon>
                          </MenuItem>
                        ))}
                      </Select>
                      {studentPersonalDetailsError?.spoken_language && (
                        <FormHelperText className="PersonalInfoErrorText">
                          Please select spoken language.
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className="UpdatePersonalInfoDiv"
            >
              <Button className={`student_update_btn ${this.props.classes.button_color_disabled}`}
                style={{
                  opacity:
                    JSON.stringify(this.studentPersonalDetailsCopy) ===
                      JSON.stringify(studentPersonalDetails?.attributes)
                      ? 0.4
                      : 1,
                }}
                type="submit"
                onClick={this.savePersonalInfo}
                disabled={
                  JSON.stringify(this.studentPersonalDetailsCopy) ===
                  JSON.stringify(studentPersonalDetails?.attributes)
                }
              >
                Update
              </Button>
            </Grid>
          </Grid>

          <CreateVideoModal
            isTeacher={false}
            onCloseModal={() => {
              this.setState({ openCreateVideoModal: false });
            }}
            openCreateVideoModal={openCreateVideoModal}
            recordVideoClick={() => {
              this.setState({
                createVideo: { isCreateUploadVideo: "Create" },
                openCreateVideoModal: false,
              });
            }}
            openVideoBoothClick={() => {
              this.setState({
                createVideo: { isCreateUploadVideo: "Create" },
                openCreateVideoModal: false,
              });
            }}
            showOpenVideoFileDlg={this.showOpenVideoFileDlg}
            inputOpenVideoFileRef={this.inputOpenVideoFileRef}
            inputOnChange={(e: any) => this.handleVideoUpload(e, null)}
            onFileDrop={(files: any, event: any) => {
              window.addEventListener(
                "drop",
                function (e) {
                  e.preventDefault();
                },
                false
              );
              this.handleVideoUpload(null, files[0]);
            }}
            videoDuration={videoValidations}
          />

          <VideoRecordViaWebcamModal
            handleSave={(mediaUrl: any, mediaBlob: any) =>
              this.uploadVideo(mediaUrl, mediaBlob)
            }
            handleClose={this.updateStateOnClose}
            handleOnBack={() => {
              this.setState({
                createVideo: { isCreateUploadVideo: false },
                openCreateVideoModal: true,
              });
            }}
            createVideo={createVideo}
            loadingSpinner={this.state.loadingSpinner}
            MaxVideoRecordingTime={videoValidations}
          />

          <PreviewUploadedVideo
            handleSave={() => this.uploadVideo(createVideo.videoData)}
            handleClose={this.updateStateOnClose}
            handleCancel={() => {
              this.setState({
                createVideo: {},
                openCreateVideoModal: true,
              });
            }}
            handleOnBack={() =>
              this.setState({ createVideo: {}, openCreateVideoModal: true })
            }
            createVideo={createVideo}
            loadingSpinner={this.state.loadingSpinner}
          />

          <VideoUploadSuccessModal
            isTeacher={false}
            openFileUploadSuccessModal={this.state.openFileUploadSuccessModal}
            onCloseModal={() => {
              this.setState({ openFileUploadSuccessModal: false });
            }}
            doneClick={() =>
              this.setState({
                openFileUploadSuccessModal: false,
              })
            }
          />

          <AlertModal
            alertModal={this.state.alertModal}
            isTeacher={false}
            onCloseModal={() => {
              this.setState({ alertModal: false });
            }}
            alertTitle="Alert!"
            alertMessage={this.state.alertMessage}
            buttonText="OK"
          />

          <UploadVideoPercentageModal
            isTeacher={false}
            uploadVideoPercentage={uploadVideoPercentage}
            onCloseModal={() => {
              this.setState({ uploadVideoPercentage: null });
            }}
            backArrowClick={() => {
              this.setState({
                uploadVideoPercentage: null,
                createVideo: {},
                openCreateVideoModal: true,
              });
            }}
          />

          <PreviewVideoModal
            isTeacher={false}
            onCloseModal={() => {
              this.setState({
                openPreviewVideoModal: false,
                videoAnchorEl: null,
              });
            }}
            openPreviewVideoModal={openPreviewVideoModal}
            videoAnchorEl={this.state.videoAnchorEl}
            openMenu={open}
            handleVideoModalClose={this.handleVideoModalClose}
            reUploadClick={() => {
              this.setState({
                videoAnchorEl: null,
                openPreviewVideoModal: false,
                openCreateVideoModal: true,
              });
            }}
            deleteClick={() => {
              this.setState({
                deleteVideoModal: true,
                openPreviewVideoModal: false,
                videoAnchorEl: null,
              });
            }}
            closeClick={() => {
              this.setState({
                openPreviewVideoModal: false,
                videoAnchorEl: null,
              });
            }}
            otherOptionsClick={(event: any) =>
              this.handleVideoModalClick(event)
            }
            videoSrc={
              introVideoFile?.attributes?.url
                ? introVideoFile?.attributes?.url
                : introVideoFile
            }
            isIdAvailable={!!introVideoFile?.id}
          />

          <DeleteVideoModal
            isTeacher={false}
            deleteVideoModal={this.state.deleteVideoModal}
            onCloseModal={() => {
              this.setState({ deleteVideoModal: false });
            }}
            deleteVideoClick={this.deleteUploadedVideo}
          />
        </Box>
      </form>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export const studentProfilePersonalInfoStyles = {
  toggleButton: {
    width: "42px",
    height: "26px",
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#af38cb",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#fff",
        border: "6px solid #fff",
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
    },
  },
  selectDropDown: {
    width: "100%",
    "& .MuiSelect-root": {
      height: "50px",
      padding: "14px",
      boxSizing: "border-box",
      display: "flex",
      placeItems: "center",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "none",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#ebebeb",
      paddingLeft: 14,
      borderRadius: 8,
    },
    "&.Mui-error > .MuiInputBase-input": {
      borderColor: "red !important",
    },
  },
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "14px",
      height: "50px",
      boxSizing: "border-box",
      borderRadius: "8px",
      border: "solid 1px #ebebeb",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      "& fieldset": {
        borderColor: "#ebebeb",
      },
    },
  },
};
// Customizable Area End

// Customizable Area Start
const combineStyles = (...styles: any) => {
  return function CombineStyles(theme: any) {
    const outStyles = styles.map((arg: any) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc: any, val: any) => Object.assign(acc, val));
  };
}

const combinedStyle = combineStyles(themeCreateStyle, studentProfilePersonalInfoStyles);
export default withStyles(combinedStyle)(
  StudentProfile as React.ComponentType<any>
);
// Customizable Area End