// Customizable Area Start
import React from "react";
import {
  Typography,
  Grid,
  Avatar,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { imgBackArrowSVG, arrowDropdownBlueSVG } from "../assets";
import Spinner from "../../../shared/SideBar/src/Spinner";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import "./AssessmentEvaluation.web.css";
import AssessmentEvaluationDetailsController from "./AssessmentEvaluationDetailsController.web";
import SubmitModal from "./SubmitModal.web";
import QuestionSection from "./QuestionSection.web";
import { withStyles } from '@material-ui/core/styles';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";

// Customizable Area End

export class AssessmentEvaluationDetails extends AssessmentEvaluationDetailsController {
  // Customizable Area Start
  render() {
    const {
      sectionList,
      studentDetails,
      sectionListMeta,
      totalAttempted,
      totalMarks,
      isSubmitable,
    } = this.state;

    return (
      <>
        {this.state.showLoader && (
          <Spinner spinnerModal={this.state.showLoader} />
        )}
        <div>
          <div className="status_and_marks_section">
            <Typography data-test-id="evaluation-detail-back-icon" className={`back_text_evaluation ${this.props.classes.heading_font}`} onClick={this.handleBack}>
              <img
                src={imgBackArrowSVG}
                className="back_arrow_img"
                alt="back"
              ></img>
              Back
            </Typography>

            <div>
              <Button
                className={`save_evaluation_btn ${this.props.classes.outline_button_no_hover} ${this.props.classes.bodyText_font}`}
                onClick={this.saveSectionList}
              >
                Save
              </Button>
              <Button
                className={isSubmitable ? `opacity_1 edit_evaluation_btn ${this.props.classes.button_color} ${this.props.classes.theme_font}` : `opacity_light edit_evaluation_btn ${this.props.classes.button_color} ${this.props.classes.theme_font}`}
                disabled={!isSubmitable}
                onClick={this.openSubmitModal}
              >
                Submit
              </Button>
            </div>
          </div>
          <div className="evaluation_details_section">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} className="flex_display">
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    md={3}
                    lg={2}
                    className="student_img_section"
                  >
                    <Avatar
                      src={studentDetails?.profile}
                      className="student_img_details"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sm={9}
                    md={9}
                    lg={10}
                    className="student_detail_section"
                  >
                    <Typography className={`grid_student_name_details ${this.props.classes.bodyText_font}`}>
                      {studentDetails?.name}
                    </Typography>
                    <Typography className={`grid_student_date_details ${this.props.classes.subHeading_font}`}>
                      Submition: {studentDetails?.submitionDetails}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={3} md={3} className="xs_center_div">
                <Typography className={`total_title ${this.props.classes.bodyText_font}`}> Total Attemted</Typography>
                <Typography className={`total_detail ${this.props.classes.bodyText_font}`}>
                  {totalAttempted}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={3} className="xs_center_div">
                <Typography className={`total_title ${this.props.classes.bodyText_font}`}>Total Marks</Typography>
                <Typography className={`total_detail ${this.props.classes.bodyText_font}`}>
                  {totalMarks}
                </Typography>
              </Grid>
            </Grid>
          </div>

          {sectionList?.map((data: any) => (
            <Accordion data-test-id="evaluation-accordion-item" className="evaluation_accordion" key={data?.id}
              onChange={(e: any, expanded: any) => {
                if (expanded && !data.questions?.length) {
                  this.sectionClick(Number(data?.id));
                }
              }}>
              <AccordionSummary
                expandIcon={<img src={arrowDropdownBlueSVG} alt="arrow" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container>
                  <Grid item xs={12} sm={8} md={9}>
                    <Typography className={`evaluation_section_title ${this.props.classes.heading_font}`}>
                      {data?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <div className="section_rectangle_right">
                      <Typography className={`section_total_marks ${this.props.classes.subHeading_font}`}>
                        Total Marks: {data?.section_marks}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className="evaluation_question_detail_section">
                {data?.questions?.length > 0 &&
                  data?.questions?.map(
                    (questionData: any, index: number) => (
                      <React.Fragment key={questionData.id}>
                        <QuestionSection
                          index={index}
                          questionData={questionData}
                          previewImageModal={this.state.previewImageModal}
                          previewImageModalClose={
                            this.previewImageModalClose
                          }
                          previewImageSrc={this.state.previewImageSrc}
                          setPreviewImageSrc={this.setPreviewImageSrc}
                          isTeacher={true}
                          setImageUrl={this.setImageUrl}
                        />
                      </React.Fragment>
                    )
                  )}
                {data?.meta_data?.pagination?.next_page && (
                  <div className="load-more-que-btn-div ">
                    <Button
                      variant="outlined"
                      data-test-id="load-more-question-btn"
                      className={`load-more-que-btn ${this.props.classes.button_color}`}
                      onClick={() => this.sectionClick(Number(data?.id))}
                    >
                      Load More...
                    </Button>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          ))}

          {sectionListMeta?.pagination?.next_page && (
            <div className="load-more-que-btn-div ">
              <Button
                variant="outlined"
                className={`load-more-que-btn ${this.props.classes.button_color}`}
                onClick={this.handleSectionLoadMore}
              >
                Load More...
              </Button>
            </div>
          )}
        </div>

        <SubmitModal
          openSubmitModal={this.state.openSubmitModal}
          onCloseModal={this.closeSubmitModal}
          submitYesClick={() => this.submitSectionList()}
        />
      </>
    );
    // Customizable Area End
  }
  // Customizable Area End
}
// Customizable Area Start

export default withStyles(themeCreateStyle)(
  HOCAuth(AssessmentEvaluationDetails as React.ComponentType<any>, "TeacherAccount")
);
// Customizable Area End
