// Customizable Area Start
import React from "react";
import {
  Typography,
  Grid,
  Button,
  InputBase,
  NativeSelect,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import StudentAnswersheetQueryResolutionController, {
  Props,
} from "./StudentAnswerSheetQueryResolutionController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { ArrowDropDown } from "@material-ui/icons";
import { imgBackArrow, imgDelete } from "../assets";
import { HISTORY } from "../../../../components/src/comman";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import "./StudentAnswersheetQueryResolution.web.css"
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "#fff",
    //boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
    border: "1px solid #ccc",
    padding: "8px 26px 8px 12px",
    height: "42px",
    fontWeight: 600,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Open sans",
    color: "#3f526d",
    fontSize: 18,
    lineHeight: "22px",
    "&:focus": {
      borderRadius: 8,
      //boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      outline: "none"
    },
  },
}))(InputBase);
// Customizable Area End

export class StudentAnswersheetQueryResolution extends StudentAnswersheetQueryResolutionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    return (
      <>
        <Grid container className='studentAnswersheetQueryResolution_mainDiv'>
          {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
          <Grid item xs={12}>
            <Grid container className='studentAnswersheetQueryResolution_mainDiv'>
              <Grid item xs={12}>
                <div className="studentAnswersheetQueryResolution_subDiv" >
                  <Typography className="studentAnswersheetQueryResolution_backArrowDiv"
                    onClick={HISTORY.goBack}
                  >
                    <img
                      src={imgBackArrow}
                      className='studentAnswersheetQueryResolution_backArrowImg'
                    />
                    Back
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid className="studentAnswersheetQueryResolution_mainContainer"
              container
            >
              <Grid
                className="studentAnswersheetQueryResolution_subMainContainer"
                item
                xs={12}
              >
                <Typography
                  component="span" className="studentAnswersheetQueryResolution_raiseWQueryTxt"
                >
                  Raise Query
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container className="studentAnswersheetQueryResolution_mainDiv">
                  <Grid className="RaiseQuerySelectQuestion" item xs={12}>
                    <Typography component={"span"} className='studentAnswersheetQueryResolution_dropDownLabel'>
                      Select Question Number
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <NativeSelect
                      className="studentAnswersheetQueryResolution_dropDown"
                      id="select"
                      value={this.state.selectedQuestionId}
                      input={<BootstrapInput />}
                      IconComponent={ArrowDropDown}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.handleSelectQuestion(event)}
                    >
                      <option value="" disabled>Select question</option>

                      {this.state.questionsList?.map((item: any) => (
                        <option value={item.id} key={item.id} dangerouslySetInnerHTML={{
                          __html: item?.title,
                        }}></option>
                      ))}
                    </NativeSelect>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid className="studentAnswersheetQueryResolution_queryDiv" container direction="row" >
                  <Grid item xs={12}>
                    <Typography component={"span"} className='studentAnswersheetQueryResolution_dropDownLabel'>
                      Write your query
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <textarea
                      className="studentAnswersheetQueryResolution_textArea"
                      placeholder="Write your query"
                      value={this.state.query}
                      onChange={(e: any) => this.setState({ query: e.target.value })}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="studentAnswersheetQueryResolution_btnDiv" item xs={12}>
                <Button variant="contained" onClick={(e: any) => this.handleSubmitQuery(e)}
                  className={`studentAnswersheetQueryResolution_Btn ${!(this.state.selectedQuestionId && this.state.query) ? 'studentAnswersheetQueryResolution_disabledBtn' : 'studentAnswersheetQueryResolution_activeBtn'}`}
                  disabled={!(this.state.selectedQuestionId && this.state.query)}
                >Submit</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default HOCAuth(StudentAnswersheetQueryResolution as React.ComponentType<any>, 'StudentAccount');
// Customizable Area End