// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End


// Customizable Area Start
export const configJSON = require("./config.js");
const today = new Date();
// Customizable Area End


export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  filterAnchorEl: any;
  data: any;
  showLoader: boolean;
  uploadModal: boolean
  weekList: any;
  dayColumns: any;
  selectedWeekId: number;
  selectedMonthDate: any;
  openAddEditClassPeriod: boolean;
  isEditPeriod: boolean;
  classStartDate: any;
  weekDay: string;
  batchData: any;
  selectedBatch: any;
  selectedBatchData: any;
  schoolperiodData: any;
  selectedPeriod: any;
  classData: any;
  selectedClass: any;
  selectedClassId: any;
  subjectData: any;
  selectedSubject: any;
  selectedSubjectError: boolean;
  teacherData: any;
  selectedTeacher: any;
  selectedTeacherError: boolean;
  viewClassPeriodDialog: boolean;
  selectedPeriodData: any;
  classPeriodData: any;
  schoolSubject: any;
  datetracker: any;
  selectedMonth: any;
  weekdays: any;
  totalWeeks: any;
  currentWeekStartDate: any;
  currentWeekEndDate: any;
  classPeriodTimeTableRow: any;
  filterElement: any
  selectedDataForView: any;
  anchorEl1: any;
  createPeriodDialog: boolean;
  isEditable: boolean;
  isOneTime: boolean;
  isOnline: boolean;
  isFilterApply: boolean;
  schoolPeriodName: any;
  pagination_page: number;
  timeLineMeta: any;
  disableEditButton: boolean;

  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AdminClassPeriodController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPeriodListId: string = "";
  getBatchListId: string = "";
  getSubjectListId: string = "";
  getTeacherListId: string = "";
  getClassesListId: string = "";
  postPeriodId: string = "";
  getClassPeriodListId: string = "";
  getSchoolSubjectListId: string = "";
  deletePeriodId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      data: "",
      showLoader: false,
      uploadModal: false,
      filterAnchorEl: null,
      weekList: [],
      dayColumns: [],
      schoolperiodData: [],
      batchData: [],
      selectedMonthDate: moment(new Date()),
      selectedWeekId: 0,
      openAddEditClassPeriod: false,
      isEditPeriod: false,
      classStartDate: new Date(),
      weekDay: "",
      selectedBatch: "",
      selectedBatchData: null,
      selectedPeriod: "",
      classData: [],
      selectedClass: {},
      selectedClassId: "",
      schoolSubject: [],
      subjectData: [],
      selectedSubject: "",
      selectedSubjectError: false,
      teacherData: [],
      selectedTeacher: "",
      selectedTeacherError: false,
      viewClassPeriodDialog: false,
      selectedPeriodData: null,
      classPeriodData: [],
      datetracker: moment(),
      selectedMonth: moment().format("MMMM"),
      weekdays: [],
      totalWeeks: null,
      currentWeekStartDate: moment(new Date()),
      currentWeekEndDate: moment(new Date()),
      classPeriodTimeTableRow: [],
      filterElement: {
        period: [],
        subject: "",
        teacher: "",
      },
      pagination_page: 0,
      timeLineMeta: [],
      selectedDataForView: null,
      anchorEl1: null,
      createPeriodDialog: false,
      isEditable: false,
      isFilterApply: false,
      isOneTime: false,
      schoolPeriodName: '',
      isOnline: false,
      disableEditButton: false
    };
    this.userdata = this.userdata.bind(this);
    this.get_school_period_list = this.get_school_period_list.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    await this.get_batch_list();
    await this.get_classes_list();
    await this.get_school_subject();
    await this.getNumberOfWeeksInMonth();


  }

  openUploadModal = () => {
    this.setState({
      uploadModal: true
    })
  }

  closeUploadModal = () => {
    this.setState({
      uploadModal: false
    })
  }
  get_weekList = (month: any, day: any) => {
    const date = moment().set({ 'month': month, 'day': day }).format('DD/MM/YYYY')
    this.setState({
      selectedMonthDate: date
    })
  }


  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
  // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getPeriodListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ showLoader: false, schoolperiodData: responseJson.data, timeLineMeta: responseJson.meta })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.getTeacherListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ teacherData: responseJson?.teachers })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getBatchListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ batchData: responseJson?.data, })
            this.handleSetBatch(responseJson?.data[0])
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getSubjectListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ subjectData: responseJson?.teacher_class_subject_data })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getClassesListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ classData: responseJson?.data })
            // Comment the below line if you don't want any classes to be selected by default
            this.handleSetClass(responseJson?.data[0])
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getClassPeriodListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ classPeriodData: responseJson, showLoader: false })
            this.getClassTimeTable(responseJson)
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getSchoolSubjectListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ schoolSubject: responseJson?.data, showLoader: false })
          } else {
            this.parseApiErrorResponse(responseJson);
            this.setState({ showLoader: false })
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showLoader: false })
      }
      else if (apiRequestCallId === this.postPeriodId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ isFilterApply: false, classStartDate: '' })
            this.handleAddEditClassPeriodDialogClose(false)
            if (this.state.isEditPeriod) {
              toast.success('SUCCESS : Period is updated')
            } else {
              toast.success('SUCCESS : Period is created')
            }
            this.get_class_period_list();
            this.setState({ isEditPeriod: false })
          } else {
            // this.parseApiErrorResponse(responseJson);
            let key = Object.keys(responseJson?.errors[0])[0]
            this.setState({ showLoader: false })
            toast.error(`${key} ${responseJson?.errors[0][key]}`)
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showLoader: false })
      } else if (apiRequestCallId === this.deletePeriodId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ showLoader: false, viewClassPeriodDialog: false, isFilterApply: false, disableEditButton: false })
            this.get_class_period_list();
            toast.success('SUCCESS : Period is deleted')
          } else {
            this.setState({ showLoader: false })
            toast.warning(responseJson?.errors[0]?.message)
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showLoader: false })
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  setSchoolPeriodData = (data: any) => {
    this.setState({ schoolperiodData: data })
  }

  handleSetClass = (data: any) => {
    if (data) {
      this.setState({ selectedClass: data, selectedClassId: data.id })
      this.get_class_period_list(data.id, this.state.selectedBatch)
      this.get_teacher_list(data.id)
      if (this.state.selectedBatch) {
        this.get_class_period_list(data.id, this.state.selectedBatch)
      }
    }
  }

  handleSetBatch = (data: any) => {
    this.setState({ selectedBatch: data.id, selectedBatchData: data }, () => {
      this.get_school_period_list(this.state.pagination_page + 1)
    })
    if (this.state.selectedClassId) {
      this.get_class_period_list(this.state.selectedClassId, data.id)
    }
  }

  handleChangeClass = (event: any) => {
    const selectedClassItem = this.state.classData?.find((item: any) => item.id === event.target.value)
    this.handleSetClass(selectedClassItem)
  }
  handleDialogClose = () => {
    this.setState({ createPeriodDialog: false })
    this.get_class_period_list();

  }

  handleChangeBatch = (event: any) => {
    const selectedBatchItem = this.state.batchData?.find((item: any) => item.id === event.target.value)
    this.handleSetBatch(selectedBatchItem)
  }

  handleDateTimeChange = (date:any) => {
    this.setState(
      {
        selectedMonthDate: moment(date).startOf("month"),
      },
      () => {
        this.getNumberOfWeeksInMonth(moment(date));
      }
    )
  }

  applyFilterClickProp = (subject: any, period: any, teacher: any) => {
    this.setState({
      filterElement: {
        subject: subject,
        period: period,
        teacher: teacher
      }
    }, () => {
      if (this.state.filterElement?.teacher !== "" || this.state.filterElement?.subject !== "") {
        this.setState({ isFilterApply: true })
        this.get_class_period_filtered_list()
      }
      else {
        this.setState({ isFilterApply: false })
        this.get_class_period_list()
      }
    })
  }

  editImgOnClick = (e:any,column:any, row:any, value:any) => {
    if (!this.handleIsValidTime(column.dayDate, row)) {
      e.stopPropagation();
      this.setState({
        isEditPeriod: true,
        selectedPeriodData: value[0],
        selectedPeriod: value[0],
        selectedBatch: value[0]?.attributes?.batch?.data?.id,
        selectedTeacher: value[0]?.attributes?.periods[1]?.data?.attributes?.teacher?.id,
        selectedSubject: value[0]?.attributes?.periods[1]?.data?.attributes?.subject?.data?.id,
        isEditable: value[0]?.attributes?.periods[1]?.data?.attributes?.is_editable,
        isOnline: value[0]?.attributes?.periods[1]?.data?.attributes?.is_online == null ? false : value[0]?.attributes?.periods[1]?.data?.attributes?.is_online,
        classStartDate: moment(value[0]?.attributes?.periods[1]?.data?.attributes?.start_date).format("DD MM YYYY"),
        schoolPeriodName: value[0]?.attributes?.periods[1]?.data?.attributes?.name,
        isOneTime: value[0]?.attributes?.periods[1]?.data?.attributes?.period_type === "recursive" ? false : true,
      }, () => {
        this.get_subject_list(value[0]?.attributes?.periods[1]?.data?.attributes?.teacher?.id)
          this.handleAddEditClassPeriodDialogOpen()
      })
    }
  }

  addImgOnClick = (column:any, row:any, value:any) => {
    if (!this.handleIsValidTime(column.dayDate, row)) {
      this.setState({
        selectedPeriod: value[0],
        weekDay: column.label,
        selectedTeacher: "",
        selectedSubject: "",
        isEditPeriod: false,
        selectedTeacherError: false,
        selectedSubjectError: false,
        selectedPeriodData: value[0],
        isEditable: false,
        isOnline: false,
        schoolPeriodName: '',
        classStartDate: moment(column.dayDate).format(
          "DD MM YYYY"
        ),
        isOneTime: false,

      }, () => this.handleAddEditClassPeriodDialogOpen())
    }
  }

  onPaginateChange = (e:any) => {
    if (e.selected !== this.state.pagination_page) {
      this.setState({ pagination_page: e.selected });
      this.get_school_period_list(e.selected + 1);
      this.weekChangeEvent(this.state.selectedWeekId)
    }
  }

  handleDateNavigate = (count: any, type: any, navigationType: any) => {
    let futureDate: any = "";
    if (navigationType === "backward") {
      if (type === "months") {
        futureDate = moment(this.state.selectedMonthDate).subtract(count, "M");
      } else {
        futureDate = moment(this.state.selectedMonthDate).subtract(count, "Y");
      }
    } else {
      if (type === "months") {
        futureDate = moment(this.state.selectedMonthDate).add(count, "M");
      } else {
        futureDate = moment(this.state.selectedMonthDate).add(count, "Y");
      }
    }
    this.setState({ selectedMonthDate: futureDate }
      //() => {this.apiCall()}
    );

  };

  get_school_period_list = (page?: any) => {
    this.setState({ showLoader: true });
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPeriodListId = requestMessage.messageId;
    let apiEndPoint =
      configJSON.periodEndPoint + `?school_id=${school_Data.school_id}&page=${page}&batch_id=${this.state.selectedBatch}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_classes_list = () => {
    this.setState({ showLoader: true });
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClassesListId = requestMessage.messageId;
    let apiEndPoint =
      configJSON.classesListEndPoint + `?page=1&per=1000&school_id=${school_Data.school_id}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_batch_list = (page?: any) => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBatchListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.batchesEndPoint + `?school_id=${school_Data.school_id}&page=${page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  get_class_period_list = async (selectedClassId?: number, selectedBatchId?: number, weekStartDate?: any, weekEndDate?: any) => {
    await this.setState({ showLoader: true }, () => { });
    const weekStart = weekStartDate ? weekStartDate : moment(this.state.currentWeekStartDate).format("DD/MM/YYYY");
    const weekEnd = weekEndDate ? weekEndDate : moment(this.state.currentWeekEndDate).format("DD/MM/YYYY");

    const classId = selectedClassId ? selectedClassId : this.state.selectedClassId;
    const batchId = selectedBatchId ? selectedBatchId : this.state.selectedBatch;

    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClassPeriodListId = requestMessage.messageId;
    let apiEndPoint =
      configJSON.getSchoolPeriodEndPoint + `?school_id=${school_Data.school_id}`;
    if (classId) apiEndPoint = apiEndPoint + `&school_class_id=${classId}`;
    if (batchId) apiEndPoint = apiEndPoint + `&batch_id=${batchId}`;
    if (weekStart && weekEnd) apiEndPoint = apiEndPoint + `&start_date=${weekStart}&end_date=${weekEnd}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  isValidData = () => {
    let isValid = false
    if (this.state.selectedSubject === "" || this.state.selectedTeacher === "") {
      if (this.state.selectedSubject === "") this.setState({ selectedSubjectError: true })
      if (this.state.selectedTeacher === "") this.setState({ selectedTeacherError: true })
      isValid = false
    } else {
      isValid = true
    }



    return isValid;
  }

  add_edit_period = () => {
    if (this.isValidData()) {

      this.setState({ showLoader: true });
      const user_data = localStorage.getItem('user_data');
      const school_Data = JSON.parse(user_data || '{}');
      const tok = localStorage.getItem('token');
      const header = {
        "Content-Type": configJSON.dashboardContentType,
        token: localStorage.getItem("token"),
        school: school_Data.school_id
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.postPeriodId = requestMessage.messageId;

      const { isEditPeriod, selectedBatchData, selectedSubject, selectedTeacher, selectedPeriodData, classStartDate, selectedClassId, selectedClass } = this.state

      let data: any
      if (isEditPeriod) {
        data = {
          start_date: classStartDate,
          school_period_id: selectedPeriodData?.id,  // TO DO
          school_class_id: Number(selectedClassId),
          subject_id: Number(selectedSubject),
          batch_id: Number(selectedBatchData?.id),
          school_id: Number(school_Data.school_id),
          account_id: Number(selectedTeacher),
          division_id: Number(selectedClass?.attributes?.divisions?.id),
          is_editable: this.state.isEditable,
          is_online: this.state.isOnline,
          name: this.state.schoolPeriodName,
          period_type: this.state.isOneTime ? "one_time" : "recursive"
        }
      }
      else {

        let name = this.state.subjectData.filter((_data: any) => parseInt(_data.id) == parseInt(selectedSubject))

        data = {
          start_date: moment(classStartDate, "DD MM YYYY").format("DD MMM YYYY"),
          school_period_id: selectedPeriodData?.id,  // TO DO
          school_class_id: Number(selectedClassId),
          subject_id: Number(selectedSubject),
          batch_id: Number(selectedBatchData?.id),
          school_id: Number(school_Data.school_id),
          account_id: Number(selectedTeacher),
          division_id: Number(selectedClass?.attributes?.divisions?.id),
          is_editable: this.state.isEditable,
          is_online: this.state.isOnline,
          week_day: this.state.weekDay,
          name: this.state.schoolPeriodName,
          period_type: this.state.isOneTime ? "one_time" : "recursive"


        }
      }
      const httpBody = data

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        isEditPeriod ? configJSON.addEditPeriodEndPoint + `/${selectedPeriodData?.attributes?.periods[1]?.data?.id}` : configJSON.addPeriodCreateEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.instituteURL
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        isEditPeriod ? configJSON.dashboardApiPutMethodType : configJSON.dashboardApiPostMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  weekChangeEvent = (weekId: number) => {
    const differenceInWeeks = weekId - this.state.selectedWeekId
    this.setState({ selectedWeekId: weekId });
    this.handleChangeWeek(differenceInWeeks);
  }

  handleAddEditClassPeriodDialogClose = (closeModal: boolean) => {
    this.setState({
      openAddEditClassPeriod: closeModal,
      selectedTeacherError: false,
      selectedSubjectError: false,
      classStartDate: ''
    })
  }

  handleAddEditClassPeriodDialogOpen = () => {
    this.setState({
      openAddEditClassPeriod: true,

    })
  }

  onChangeSubject = (event: any) => {
    if (event.target.value !== "") {
      this.setState({ selectedSubjectError: false });
    }
    this.setState({ selectedSubject: event.target.value });
  }
  onChangeIsEditable = (event: any) => {
    this.setState({ isEditable: event.target.checked });
  }
  onChangeIsOneTime = (event: any) => {
    this.setState({ isOneTime: event.target.checked });
  }
  onChangeIsOnline = (event: any) => {
    this.setState({ isOnline: event.target.checked });
  }

  onChangeSchoolPeriodName = (event: any) => {
    const value = event.target.value.trim();
    this.setState({ schoolPeriodName: event.target.value });
  }



  onBlurSubject = () => {
    if (
      this.state.selectedSubject === "" ||
      this.state.selectedSubject?.length === 0
    ) {
      this.setState({ selectedSubjectError: true });
    } else if (this.state.selectedSubject?.length !== 0) {
      this.setState({ selectedSubjectError: false });
    } else {
      this.setState({ selectedSubjectError: false });
    }
  }

  onChangeTeacher = (event: any) => {
    if (event.target.value !== "") {
      this.setState({ selectedTeacherError: false });
    }
    this.setState({ selectedTeacher: event.target.value, selectedSubject: '' });
    this.get_subject_list(event.target.value)
  }

  onBlurTeacher = () => {
    if (
      this.state.selectedTeacher === "" ||
      this.state.selectedTeacher?.length === 0
    ) {
      this.setState({ selectedTeacherError: true });
    } else if (this.state.selectedTeacher?.length !== 0) {
      this.setState({ selectedTeacherError: false });
    } else {
      this.setState({ selectedTeacherError: false });
    }
  }


  get_subject_list = (selectedTeacher: any) => {
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      school: school_Data?.school_id,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubjectListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subjectsEndPoint + `?account_id=${Number(selectedTeacher)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  get_teacher_list = (id?: number) => {
    const classId = id ? id : Number(this.state.selectedClass?.id);
    const header = {
      "Content-Type": configJSON.dashboardContentType,
    };
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTeacherListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.classTeachersEndPoint + `?school_class_id=${classId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  get_school_subject = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
    };
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSchoolSubjectListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolSubjectPoint + `?id=${school_Data.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCellBgColor = (id: string) => {
    let bgColor: string = "";
    switch (id) {
      case "monday":
        bgColor = "#e4f2f5";
        break;
      case "tuesday":
        bgColor = "#e4f5ed";
        break;
      case "wednesday":
        bgColor = "#eef5e5";
        break;
      case "thursday":
        bgColor = "#f5e7e4";
        break;
      case "friday":
        bgColor = "#e4e8f4";
        break;
      case "saturday":
        bgColor = "#e8e4f5";
        break;
      case "sunday":
        bgColor = "#f5e5f2";
        break;
      default:
        bgColor = "#e4f2f5";
        break;
    }
    return bgColor
  }

  getCellTextColor = (id: string) => {
    let textColor: string = "";
    switch (id) {
      case "monday":
        textColor = "#8dc2f1";
        break;
      case "tuesday":
        textColor = "#79a892";
        break;
      case "wednesday":
        textColor = "#b6d382";
        break;
      case "thursday":
        textColor = "#dda390";
        break;
      case "friday":
        textColor = "#7587c4";
        break;
      case "saturday":
        textColor = "#8dc2f1";
        break;
      case "sunday":
        textColor = "#8dc2f1";
        break;
      default:
        textColor = "#8dc2f1";
        break;
    }
    return textColor
  }

  handleChangeWeek = (e: any) => {
    this.getCurrentWeek(null, e * 7);
  };

  getCurrentWeek = (month?: any, e?: any) => {
    let timeTracker;
    let weekStart;
    if (month) {
      timeTracker = month;
      weekStart = timeTracker.clone().startOf("days");
    } else if (e) {
      timeTracker = this.state.datetracker.add(e, "days");
      const startofmonth = moment(this.state.selectedMonthDate, "MMMM").startOf("month");
      if (startofmonth > timeTracker.clone().startOf("days")) {
        weekStart = startofmonth;
      } else {
        weekStart = timeTracker.clone().startOf("days");
      }
    } else {
      timeTracker = moment();
      weekStart = timeTracker.clone().startOf("isoWeek");
    }

    let monthData = month
      ? month
      : this.state.selectedMonth
        ? moment(this.state.selectedMonth, "MMMM")
        : new Date();

    let days = [];
    for (var i = 0; i <= 6; i++) {
      if (moment(weekStart).add(i, "days")) {
        days.push(moment(weekStart).add(i, "days"));
      }
    }
    if (days.length > 0) {
      const weekDayColumns = [{
        id: "period",
        label: "Period",
        dayDate: moment(),
      }]
      for (var i = 0; i < days.length; i++) {
        weekDayColumns.push({ id: `${moment(days[i]).format("dddd").toLowerCase()}`, label: `${moment(days[i]).format("dddd")}`, dayDate: moment(days[i]) })
      }
      this.setState({ datetracker: weekStart, weekdays: days, dayColumns: weekDayColumns, currentWeekStartDate: weekStart, currentWeekEndDate: days[days.length - 1] });
      if (this.state.isFilterApply) {
        this.get_class_period_filtered_list(moment(weekStart).format("DD/MM/YYYY"), moment(days[days.length - 1]).format("DD/MM/YYYY"))
      } else {
        this.get_class_period_list(this.state.selectedClassId, this.state.selectedBatch, moment(weekStart).format("DD/MM/YYYY"), moment(days[days.length - 1]).format("DD/MM/YYYY"))
      }
    }
  };

  getNumberOfWeeksInMonth = (date?: moment.Moment) => {
    const momentObj = date ? moment(date, "DD,MMMM,YYYY") : moment(moment(), "DD,MMMM,YYYY");
    const endOfMonth: any = moment(momentObj).endOf("month");
    const startOfMonth: any = moment(momentObj).startOf("month");
    this.getCurrentWeek(startOfMonth);
    // Create a range for the month we can iterate through
    const weeksInMonth =
      moment
        .duration(
          endOfMonth - startOfMonth
        )
        .weeks() + 1;
    const weeksTotal = [];
    for (var i = 0; i < weeksInMonth; i++) {
      weeksTotal.push({
        id: i,
        name: `Week ${i + 1}`,
      })
    }
    this.setState({ datetracker: moment(startOfMonth), totalWeeks: weeksInMonth, weekList: weeksTotal, selectedWeekId: 0 })
  }

  getClassTimeTable = (classPeriods: any) => {

    let timeTableRow: any = [];
    const schoolPeriods = [...this.state.schoolperiodData]

    if (schoolPeriods.length) {
      schoolPeriods.map((schoolPeriod: any) => {

        const classPeriodRowData = {
          schoolPeriodDetails: schoolPeriod,
          monday: Object.keys(classPeriods["Monday"]).length ? Object.fromEntries(Object.entries(classPeriods["Monday"]).filter(([key]) => {
            if (classPeriods["Monday"][key][0].id == schoolPeriod.id) {
              return classPeriods["Monday"][key]
            }
          })) : classPeriods["Monday"],
          tuesday: Object.keys(classPeriods["Tuesday"]).length ? Object.fromEntries(Object.entries(classPeriods["Tuesday"]).filter(([key]) => {
            if (classPeriods["Tuesday"][key][0].id == schoolPeriod.id) {
              return classPeriods["Tuesday"][key]
            }
          })) : classPeriods["Tuesday"],
          wednesday: Object.keys(classPeriods["Wednesday"]).length ? Object.fromEntries(Object.entries(classPeriods["Wednesday"]).filter(([key]) => {
            if (classPeriods["Wednesday"][key][0].id == schoolPeriod.id) {
              return classPeriods["Wednesday"][key]
            }
          })) : classPeriods["Wednesday"],
          thursday: Object.keys(classPeriods["Thursday"]).length ? Object.fromEntries(Object.entries(classPeriods["Thursday"]).filter(([key]) => {
            if (classPeriods["Thursday"][key][0].id == schoolPeriod.id) {
              return classPeriods["Thursday"][key]
            }
          })) : classPeriods["Thursday"],
          friday: Object.fromEntries(Object.entries(classPeriods["Friday"]).filter(([key]) => {
            if (classPeriods["Friday"][key][0].id == schoolPeriod.id) {
              return classPeriods["Friday"][key]
            }
          })),
          saturday: Object.keys(classPeriods["Saturday"]).length ? Object.fromEntries(Object.entries(classPeriods["Saturday"]).filter(([key]) => {
            if (classPeriods["Saturday"][key][0].id == schoolPeriod.id) {
              return classPeriods["Saturday"][key]
            }
          })) : classPeriods["Saturday"],
          sunday: Object.keys(classPeriods["Sunday"]).length ? Object.fromEntries(Object.entries(classPeriods["Sunday"]).filter(([key]) => {
            if (classPeriods["Sunday"][key][0].id == schoolPeriod.id) {
              return classPeriods["Sunday"][key]
            }
          })) : classPeriods["Sunday"],
        }
        timeTableRow.push(classPeriodRowData);
        return schoolPeriod;
      })
    }
    this.setState({ classPeriodTimeTableRow: timeTableRow })
  }

  delete_class_period = () => {
    this.setState({ showLoader: true });
    const tok = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePeriodId = requestMessage.messageId;

    const { selectedDataForView } = this.state

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addEditPeriodEndPoint + `/${selectedDataForView?.attributes?.periods[1]?.data?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteSchoolMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  edit_class_period_from_view = () => {
    const { selectedDataForView } = this.state;

    this.setState({
      isEditPeriod: true,
      selectedPeriod: selectedDataForView,
      selectedBatch: selectedDataForView?.attributes?.batch?.id,
      selectedTeacher: selectedDataForView?.attributes?.periods[1]?.data?.attributes?.teacher?.id,
      selectedSubject: selectedDataForView?.attributes?.periods[1]?.data?.attributes?.subject?.data?.id,
      viewClassPeriodDialog: false,
      isEditable: selectedDataForView?.attributes?.periods[1]?.data?.attributes?.is_editable,
      isOnline: selectedDataForView?.attributes?.periods[1]?.data?.attributes?.is_online == null ? false : selectedDataForView?.attributes?.periods[1]?.data?.attributes?.is_online,

      selectedPeriodData: selectedDataForView,
      // selectedPeriod: row,
      isOneTime: selectedDataForView?.attributes?.periods[1]?.data?.attributes?.period_type === "recursive" ? false : true,
      classStartDate: moment(selectedDataForView?.attributes?.periods[1]?.data?.attributes?.start_date).format("DD MM YYYY"),
      schoolPeriodName: selectedDataForView?.attributes?.periods[1]?.data?.attributes?.name
    }, () => { this.get_subject_list(selectedDataForView?.attributes?.periods[1]?.data?.attributes?.teacher?.id), this.handleAddEditClassPeriodDialogOpen() })
  }

  handleIsValidTime = (date: any, time: any) => {
    const today = moment();
    let periodDate = moment(date).format("DD MM YYYY")
    let periodTIme = time?.schoolPeriodDetails?.attributes?.end_time
    var timeAndDate = moment(periodDate + ' ' + periodTIme, 'DD MM YYYY HH:mm A');
    return moment(timeAndDate).isBefore(today)

  };

  get_class_period_filtered_list = (weekStartDate?: any, weekEndDate?: any) => {
    const weekStart = weekStartDate ? weekStartDate : moment(this.state.currentWeekStartDate).format("DD/MM/YYYY");
    const weekEnd = weekEndDate ? weekEndDate : moment(this.state.currentWeekEndDate).format("DD/MM/YYYY");

    this.setState({ showLoader: true });
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");

    const { filterElement } = this.state;

    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClassPeriodListId = requestMessage.messageId;
    let apiEndPoint =
      configJSON.getSchoolPeriodEndPoint + `?&start_date=${weekStart}&end_date=${weekEnd}&school_id=${school_Data.school_id}&filter=true`;
    if (filterElement?.subject !== "") {
      apiEndPoint = apiEndPoint + `&subjects_id=${parseInt(filterElement?.subject)}`;
    }
    // if (filterElement?.period !=="") {
    //   apiEndPoint = apiEndPoint + `&period_id=${Array(filterElement?.period)}`;
    // }
    if (filterElement?.teacher !== "") {
      apiEndPoint = apiEndPoint + `&account_id=${parseInt(filterElement?.teacher)}`;
    }
    if (this.state.selectedClassId) apiEndPoint = apiEndPoint + `&school_class_id=${parseInt(this.state.selectedClassId)}`;
    if (this.state.selectedBatch) apiEndPoint = apiEndPoint + `&batch_id=${parseInt(this.state.selectedBatch)}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
