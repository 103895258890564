// Customizable Area Start
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import { IBlock } from '../../../../framework/src/IBlock';
import { runEngine } from '../../../../framework/src/RunEngine';
// Customizable Area End

// Customizable Area Start
export const configJSON = require('../config.js');
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  location?: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  singleAssessmentReport: any[];
  multipleAssessmentReport: any[];
  standardsMasteryReport: any[];
  studentProfileReport: any[];
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class TextRatingTypeQuestionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start

    console.disableYellowBox = true;

    this.state = {
      singleAssessmentReport: [
        {
          id: 1,
          name: 'Assessment Summary',
          image: require('../assets/pie.png').default,
          alt: 'Assessment Summary',
          description:
            'View a high level of analysis of data for one assessment.',
          tabIndex: 0,
        },
        {
          id: 2,
          name: 'Question Analysis',
          image: require('../assets/multi-bars.png').default,
          alt: 'Question Analysis',
          description:
            'Identify the most difficult question for further analysis.',
          tabIndex: 1,
        },
        {
          id: 5,
          name: 'Performance by Students',
          image: require('../assets/pie.png').default,
          alt: 'Performance by Students',
          description:
            'Identity group of students by proficiency level to gauge next steps or develop different paths.',
          tabIndex: 2,
        },
      ],
      multipleAssessmentReport: [
        {
          id: 1,
          name: 'Performance Over Time',
          image: require('../assets/performance.png').default,
          alt: 'Performance Over Time',
          description:
            'Compare student performance across two or more assessments.',
        },
        {
          id: 2,
          name: 'Student Progress',
          image: require('../assets/progress.png').default,
          alt: 'Student Progress',
          description:
            'Explore trends in performance by students. Isolate those who are on, at, or below target to aid in differentiated instruction.',
        },
      ],
      standardsMasteryReport: [
        {
          id: 1,
          name: 'Standards Performance Summary',
          image: require('../assets/pie.png').default,
          alt: 'Standards Performance Summary',
          description:
            'View an aggregate of overall mastery on all domain assessed in a subject, filtered by individual, class or group.',
        },
        {
          id: 2,
          name: 'Standards Gradebook',
          image: require('../assets/pie.png').default,
          alt: 'Standards Gradebook',
          description:
            'View a summary of proficiency on all standard assessed on one or more tests. can be filtered by individual, class or group.',
        },
        {
          id: 3,
          name: 'Standards Progress',
          image: require('../assets/pie.png').default,
          alt: 'Standards Progress',
          description:
            'View a summary of proficiency levels on a specific standard across one or more assessments, can be filtered by individual, class or group.',
        },
      ],
      studentProfileReport: [
        {
          id: 1,
          name: 'Student Profile Summary',
          image: require('../assets/mastery.png').default,
          alt: 'Student Profile Summary',
          description:
            'View an overall snapshot of a student performance to date.',
        },
        {
          id: 2,
          name: 'Student Assessment Profile',
          image: require('../assets/single-bar.png'),
          alt: 'Student Assessment Profile',
          description:
            'See performance by score on each assessment taken, filtered by course.',
        },
        {
          id: 3,
          name: 'Student Standards Progress',
          image: require('../assets/table.png'),
          alt: 'Student Standards Progress',
          description:
            'Explore trends in performance of student on various standards over time.',
        },
      ],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }
  // Customizable Area End
}
