// Customizable Area Start
import axios from "axios";
import moment from "moment";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { encryptData } from "../utils/common";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");

export interface ITimeTableEventsProps {
  id: any;
  isAllDay?: boolean;
  start: Date;
  end: Date;
  title: string;
  type?: string;
  hexBgColor?: string;
  borderColor?: string;
  image?: any;
  subjectImage?: any;
  subject?: string;
  status?: string | any;
  meetingDate?: any;
  meetingTitle?: string;
  meetingDetails?: string;
  meetingId?: any;
  period_name?: string;
  period_id?: number | string;
  teacherImage?: any;
  teacherName?: string;
  unit?: string;
  link?: string;
  class?: string;
  isEvent?: boolean;
  description?: string;
  is_online?: boolean | undefined;
  recordings?: any
}
// Customizable Area End

export interface Props {
  navigation: any;
  class_id: any;
  classes: any
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  data: any;
  token: string;
  anchorEl: any;
  date: any;
  selectedView: any;
  calendarEvents: ITimeTableEventsProps[];
  isStartMeeting: boolean;
  selectedEvent: any;
  openFeedbackPopup: boolean
  showSpinner: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudentAttendanceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetEventsByMember: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      data: null,
      anchorEl: null,
      token: "",
      date: moment(new Date()),
      selectedView: "week",
      isStartMeeting: false,
      selectedEvent: null,
      calendarEvents: [],
      showSpinner: false,
      openFeedbackPopup: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.userdata = this.userdata.bind(this);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.userdata();
    await this.apiCall();

    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  apiCall = async () => {
    this.setState({
      showSpinner: true
    })
    await this.getStudentPeriods(this.state.date, this.state.selectedView);
  }



  handleOpenZoomMeeting = (event: any) => {
    const userData = localStorage.getItem("user_data");
    const parsedUserData = JSON.parse(userData || "{}");
    if (parsedUserData.meeting_paid_account) {
      const { api_key, api_secret, id } = event.meetingDetails;
      const role = 0;
      const meetingParameters = {
        ...event,
        meetingDetails: {
          api_key: encryptData(api_key, "meeting-api-key"),
          api_secret: encryptData(
            api_secret,
            "meeting-api-secret"
          ),
          id: encryptData(
            id,
            "meeting-id"
          ),
          role: encryptData(
            role.toString(),
            "meeting-role"
          )
        }
      };
      localStorage.setItem("meeting-detail", JSON.stringify(meetingParameters));
      localStorage.setItem("role", "student");
      const meetingDetail = localStorage.getItem("meeting-detail");
      if (meetingDetail) {
        const currentUrl = window.location.origin;
        const redirectUrl = `${currentUrl}/Meeting`;
        window.open(redirectUrl, "_blank");
      }
    } else {
      window.open(event.link, "_blank");
    }
  }

  handleGetStartAndEndTime = (startDate: any, time: string) => {
    const dateObj = new Date(startDate);
    const month = dateObj.getMonth();
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const formattedTime = moment(time).format("HH:mm");
    const formattedTimeHours = parseInt(formattedTime.split(":")[0]);
    const formattedTimeMins = parseInt(formattedTime.split(":")[1]);
    return new Date(year, month, day, formattedTimeHours, formattedTimeMins);
  };

  handleTimeTableData = async (periodInfo: Array<any>) => {
    let colors: any = [
      { Color: '#ffb84d', borderColor: 'rgb(255, 184, 77, 0.4)' },
      { Color: '#f26767', borderColor: 'rgb(242, 103, 103, 0.4)' },
      { Color: '#327095', borderColor: 'rgb(50, 112, 149, 0.4)' },
      { Color: '#3dbb3d', borderColor: 'rgb(61, 187, 61, 0.4)' },
      { Color: '#0091ff', borderColor: 'rgb(0, 145, 255, 0.4)' }
    ]
    if (periodInfo && periodInfo.length > 0) {
      let periodInfoDetails: any = []
      await periodInfo.map((periodData) => {
        if (Object.keys(periodData).length != 0) {
          Object.keys(periodData).map((key, index) => {
            periodData[key].map((period: any) => {
              let info: any
              const color = colors[Math.floor(Math.random() * colors.length)]
              if (period?.attributes?.events?.length != 0) {
              }
              else {
                if (period.attributes.periods.length > 1) {
                  info = {
                    id: period.id,
                    title: period?.attributes?.periods[1]?.data?.attributes?.name,
                    start: this.handleGetStartAndEndTime(
                      moment(period?.attributes?.periods[1]?.data?.attributes.start_date).format(),
                      moment(period.attributes.start_time).format()
                    ),
                    end: this.handleGetStartAndEndTime(
                      moment(period?.attributes?.periods[1]?.data?.attributes.start_date).format(),
                      moment(period.attributes.end_time).format()
                    ),
                    batch_id: period?.attributes?.batch?.data?.id,
                    week_day_school_period: period?.attributes?.week_day_school_period_id,
                    hexBgColor: color.borderColor,
                    borderColor: color.Color,
                    period_name: period?.attributes?.periods[1]?.data?.attributes?.name,
                    period_id: period?.attributes?.periods[1]?.data?.id,
                    isEvent: false,
                    subjectImage:
                      period?.attributes?.periods[1]?.data?.attributes?.subject?.data?.attributes?.image?.file_path,
                    subject: period?.attributes?.periods[1]?.data?.attributes?.subject?.data?.attributes?.subject_name,
                    meetingDate: new Date(),
                    meetingTitle: period?.attributes?.periods[1]?.data?.attributes?.online_class_link ? "Live Class" : "",
                    meetingDetails: period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.attributes?.signature,
                    meetingId: period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.id,
                    teacherImage: period?.attributes?.periods[1]?.data?.attributes?.teacher?.avatar,
                    teacherName: `${period?.attributes?.periods[1]?.data?.attributes?.teacher?.first_name != undefined ? period?.attributes?.periods[1]?.data?.attributes?.teacher?.first_name : ''} ${period?.attributes?.periods[1]?.data?.attributes?.teacher?.last_name != undefined ? period?.attributes?.periods[1]?.data?.attributes?.teacher?.last_name : ''}`,
                    unit: undefined,
                    link: period?.attributes?.periods[1]?.data?.attributes?.online_class_link,
                    class: period?.attributes?.periods[1]?.data?.attributes?.display_name,
                    description: period?.attributes?.periods[1]?.data?.attributes?.description,
                    is_online: period?.attributes?.periods[1]?.data?.attributes?.is_online,
                    status: period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.attributes?.status ?
                      period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.attributes?.status : null,
                    recordings: period?.attributes?.periods[1]?.data?.attributes?.meeting?.data?.attributes?.recordings
                  };
                }
              }
              if (info?.title === "Lunch Time") {
                const name = this.addLunchEvent(info)
                return name
              } else {
                if (period.attributes.events.length === 0) {
                  periodInfoDetails.push(info)
                }
                return info;
              }

            })
          })
        }
      });
      let periodInfodet = periodInfoDetails.filter((item: any) => item !== undefined)
      this.setState({ calendarEvents: [...periodInfodet] });
    }
    this.setState({
      showSpinner: false
    })
  };
  addEvents = async (periodInfo: Array<any>) => {
    const colors: any = [
      { Color: '#ffb84d', borderColor: 'rgb(255, 184, 77, 0.4)' },
      { Color: '#f26767', borderColor: 'rgb(242, 103, 103, 0.4)' },
      { Color: '#327095', borderColor: 'rgb(50, 112, 149, 0.4)' },
      { Color: '#3dbb3d', borderColor: 'rgb(61, 187, 61, 0.4)' },
      { Color: '#0091ff', borderColor: 'rgb(0, 145, 255, 0.4)' }
    ]
    if (periodInfo && periodInfo.length > 0) {
      const periodInfoDetails = await periodInfo?.map((data) => {
        let info: any
        const color = colors[Math.floor(Math.random() * colors.length)]
        const period = data?.attributes?.period?.data;
        if (period) {
          info = {
            id: period.id,
            title:
              period.attributes.name ||
              period.attributes.subject.data.attributes.subject_name,
            start: this.handleGetStartAndEndTime(
              period.attributes.start_date,
              period.attributes.school_period.data.attributes.start_time
            ),
            end: this.handleGetStartAndEndTime(
              period.attributes.start_date,
              period.attributes.school_period.data.attributes.end_time
            ),
            hexBgColor: color.borderColor,
            borderColor: color.Color,
            period_name: period?.attributes?.school_period?.data?.attributes?.name,
            period_id: period.attributes.school_period.data.id,
            subjectImage:
              period.attributes.subject.data.attributes.image?.file_path,
            subject: period.attributes.subject.data.attributes.subject_name,
            meetingDate: new Date(),
            meetingTitle: period.attributes.online_class_link ? "Live Class" : "",
            meetingDetails: period.attributes.meeting?.data?.attributes?.signature,
            meetingId: period.attributes.meeting?.data?.id,
            teacherImage: period.attributes?.teacher?.data?.attributes?.avatar,
            teacherName: `${period?.attributes.teacher.data?.attributes.first_name
              } ${period.attributes.teacher.data?.attributes.last_name}`,
            unit: undefined,
            link: period.attributes.online_class_link,
            class: period.attributes?.teacher?.data?.attributes?.school_account?.data[0]?.attributes?.class?.class_name,
            description: period.attributes.description,
            is_online: period.attributes.is_online,
            status: period.attributes.meeting?.data?.attributes?.status ?
              period.attributes.meeting?.data?.attributes?.status : null
          };

          if (this.checkEvent(info)) {
            if (info.title === "Lunch Time") {
              const name = this.addLunchEvent(info)
              return name
            } else {
              return info
            }
          } else {
            return
          }
        }
      });
      let periodInfodet = periodInfoDetails.filter((item) => item !== undefined)
      this.setState({ calendarEvents: [...this.state.calendarEvents, ...periodInfodet] })
    }
  };

  checkEvent = (event: any) => {
    let check = this.state.calendarEvents.findIndex((item: any) => item.id === event.id)
    if (check !== -1) {
      const obj = [...this.state.calendarEvents]
      obj[check] = { ...event, isEvent: true }
      if (event.title != "Lunch Time") {
        this.setState({
          calendarEvents: obj
        })
      } else {
        const obj = [...this.state.calendarEvents]
        const name = this.addLunchEvent(event)
        if (name !== undefined) { obj[check] = { ...name } }
        this.setState({
          calendarEvents: obj
        })
      }
      return false
    }
    else {

      return true

    }
  }



  handleDateNavigate = (count: any, type: any, navigationType: any) => {
    let futureDate: any = "";
    if (navigationType === "backward") {
      if (type === "months") {
        futureDate = moment(this.state.date).subtract(count, "M");
      } else {
        futureDate = moment(this.state.date).subtract(count, "Y");
      }
    } else {
      if (type === "months") {
        futureDate = moment(this.state.date).add(count, "M");
      } else {
        futureDate = moment(this.state.date).add(count, "Y");
      }
    }
    this.setState({ date: futureDate }, () => { this.apiCall() });
  };

  handleWeekChange = (newDate: Date) => {
    const currentDate = moment(this.state.date, "DD-MM-YYYY");
    const newMonth = moment(newDate).month()
    const currMonth = currentDate.month()
    const newWeek = moment(newDate).week()
    const currWeek = currentDate.week()
    const newYear = moment(newDate).year()
    const currYear = currentDate.year()
    const weekChanged = newWeek != currWeek ? true : (newMonth != currMonth ? true : (newYear != currYear ? true : false))
    if (weekChanged === true)
      this.setState({ date: newDate, selectedView: "day" }, () => { this.apiCall() });
    else
      this.setState(
        {
          date: moment(newDate),
          selectedView: "day",
        }
      );

  }
  addLunchEvent = (info?: any) => {
    const lunchEvent = {
      id: info.id,
      title: info.title,
      start: info.start,
      end: info.end,
      type: "lunchTime",
      hexBgColor: "#f1eff9",
      borderColor: "#f1eff9",
    }
    if (lunchEvent.start.getDay() !== 0) {

      return lunchEvent
    }
  }


  handleStartMeeting = (meetingInfo?: any) => {
    this.setState({ isStartMeeting: true })
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  viewClick = (view: string) => {
    this.setState({ selectedView: view });
    this.getStudentPeriods(this.state.date, view)
  };

  getStudentPeriods = async (date?: any, view?: string) => {
    const selectedDate = date
      ? moment(date).format("DD/MM/YYYY")
      : moment(this.state.date).format("DD/MM/YYYY");
    const from_date = date ? moment(date).startOf('week').format("DD/MM/YYYY") : moment(this.state.date).startOf('week').format("DD/MM/YYYY");
    const to_date = date ? moment(date).endOf('week').format("DD/MM/YYYY") : moment(this.state.date).endOf('week').format("DD/MM/YYYY");
    const userData = localStorage.getItem("id");
    const parsedUserData = JSON.parse(userData || "{}");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header:any = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("token"),
      school: data.school_id,
    };
    try {
      await axios({
        method: configJSON.getApiMethodType,
        url: configJSON.instituteURL +
          configJSON.studentPeriodEndPoint +
          `?school_class_id=${data?.school_account?.data[0]?.attributes.class.id}&school_id=${data.school_id}&start_date=${view === 'week' ? from_date : selectedDate}&end_date=${view === 'week' ? to_date : selectedDate}`,
        headers: header,

      }).then(async (s3Response) => {
        if (s3Response != null) {
          let responseItem: any = []
          const responseData = Object.entries(s3Response.data);
          responseData.map(([key1, value]) => {
            responseItem.push(value)
          })

          this.handleTimeTableData(responseItem);

          return responseItem
        }
        return null
      })
    } catch (e) {
      return null
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiGetEventsByMember) {
        if (responseJson != null) {
          if (!responseJson.errors) {
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  // Customizable Area End
}
