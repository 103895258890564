import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
    deleteModal: boolean;
    closeModalFun: any;
    classes: any;
    saveCloseModalFun: any;
    title: string;
    message: string;
}
interface S {
    closeModal: boolean;
}
interface SS {
    id: any;
}

export default class DeleteBlockController extends BlockComponent<Props, S, SS> {


    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            closeModal: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async componentDidMount() {
        super.componentDidMount();
    }
    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.closeModalFun(this.state.closeModal)
    }
    saveModalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.saveCloseModalFun(this.state.closeModal)
    }
}
