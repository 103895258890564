// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  IconButton,
} from "@material-ui/core";
import {
  file_uploaded_check_icon,
  file_uploaded_pic,
  uploadFile,
  delete_outline_black,
  backArrowIcon,
  fileIcon,
  addBox,
} from "./assets";
import TeacherDocumentBox from "./TeacherDocumentBox.web";
import TeacherProfileController, {
  Props,
  screenName,
} from "./TeacherProfileController.web";
import { FileDrop } from "react-file-drop";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import TeacherBulkDocumentBox from "./TeacherBulkDocumentBox.web";
import { withStyles } from "@material-ui/core/styles";
import PreviewPhotoModal from "./PreviewPhotoModal.web";
import DeleteDocumentModal from "./DeleteDocumentModal.web";
import AlertModal from "../../alert/src/AlertModal.web";
import ViewDocumentModal from "./ViewDocumentModal.web";
import { scrollToUp } from "./utils/common";
import Spinner from "../../shared/SideBar/src/Spinner";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End
// Customizable Area Start
// Customizable Area End
class TeacherDocument extends TeacherProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  reUploadDoc: boolean = false;
  render() {
    const {
      teacherDocuments,
      fileUploaded,
      viewDocument,
      bulkFileUploaded,
      alertModal,
      alertMessage,
      showDescriptionField,
      uploadPercentage,
      teacherAdditionalDocuments,
      fileDescription,
      previewPhoto,
      previewItem,
      displayScreen,
    } = this.state;
    const open = Boolean(this.state.anchorEl);

    return (
      <>
        {this.state.showSpinner && (
          <Spinner spinnerModal={this.state.showSpinner} />
        )}
        {displayScreen === screenName.DocumentDetails ? (
          <Grid container>
            <Grid container item xs={12} className="alignCenterGrid">
              <FileDrop
                onDrop={(files: any, event: any) => {
                  window.addEventListener(
                    "drop",
                    function(e) {
                      e.preventDefault();
                    },
                    false
                  );

                  let totalSize: number = 0;
                  const keys = Object.keys(files);
                  keys?.map(async (key: any) => {
                    const file = files[key];
                    totalSize = totalSize + file.size;
                  });
                  if (files.length <= 10 && totalSize < 524288000) {
                    this.handleUpload(
                      event,
                      true,
                      files,
                      screenName.AdditionalDoc
                    );
                  } else {
                    if (files.length > 10) {
                      this.setState({
                        alertMessage: "You can upload maximum 10 files",
                        alertModal: true,
                      });
                    } else {
                      this.setState({
                        alertMessage: "Maximum files size: 500MB",
                        alertModal: true,
                      });
                    }
                  }
                }}
              >
                <Box className="UploadDocumentDiv">
                  <img
                    src={uploadFile}
                    className="UploadDocumentImg"
                    onClick={this.showOpenFileDlg}
                  />
                  <input
                    ref={this.inputOpenFileRef}
                    accept="application/pdf, image/png, image/jpg, image/jpeg,"
                    className="UploadDocumentInput"
                    type="file"
                    multiple
                    onChange={(e: any) => {
                      let totalSize: number = 0;
                      var files = e.target.files;
                      const keys = Object.keys(files);
                      keys?.map(async (key: any) => {
                        const file = files[key];
                        totalSize = totalSize + file.size;
                      });
                      // 524288000 bytes = 500MB  1048576 bytes = 1 MB
                      if (
                        e.target.files.length <= 10 &&
                        totalSize < 524288000
                      ) {
                        this.handleUpload(
                          e,
                          true,
                          null,
                          screenName.AdditionalDoc
                        );
                      } else {
                        if (e.target.files.length > 10) {
                          this.setState({
                            alertMessage: "You can upload maximum 10 files",
                            alertModal: true,
                          });
                        } else {
                          this.setState({
                            alertMessage: "Maximum files size: 500MB",
                            alertModal: true,
                          });
                        }
                      }
                    }}
                  />
                  <Typography className={`add_upload_text ${this.props.classes.bodyText_font}`}>
                    Add New / Upload File
                  </Typography>
                  <Typography
                    className={`UploadDocumentText ${this.props.classes.bodyText_font}`}
                  >
                    Drag and drop files here or browse them from your computer.
                    Supported formats: .png .jpg .jpeg or .pdf. Up to 10 files
                    can be uploaded at once, should not exceed 500MB{" "}
                  </Typography>
                </Box>
              </FileDrop>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    className={`MandatoryDocumentsText ${this.props.classes.heading_font}`}
                  >
                    Mandatory Documents
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                className="MandatoryDocumentContainer"
              >
                {teacherDocuments.map((item: any) => (
                  <TeacherDocumentBox
                    imgSource={() => item.imgSource}
                    isReviewed={item.isReviewed}
                    name={item.name}
                    fileName={item.fileName}
                    imgWidth={item.imgWidth}
                    imgHeight={item.imgHeight}
                    isUploaded={item.isUploaded}
                    uploadDocument={() => {
                      scrollToUp();
                      this.reUploadDoc = false;
                      this.setState({
                        displayScreen: screenName.UploadMandatoryDoc, 
                        fileUploaded: {
                          fileName: item.fileName,
                          name: item.name,
                          isExistingFile: true,
                        },
                        bulkFileUploaded: [
                          {
                            fileName: item.fileName,
                            name: item.name,
                            isExistingFile: true,
                          },
                        ],
                        uploadPercentage: 0,
                        showDescriptionField: false,
                        fileDescription: "",
                      });
                    }}
                    reviewDocument={() => {
                      this.setState({
                        viewDocument: { ...item, isExistingFile: true },
                        showDescriptionField: false,
                        fileDescription: "",
                      });
                    }}
                  />
                ))}
              </Grid>
            </Grid>
            <Typography
              className={`AdditionalDocumentsText ${this.props.classes.heading_font}`}
            >
              Additional Documents
            </Typography>
            <Grid
              container
              className="AdditionalDocumentContainer"
            >
              {teacherAdditionalDocuments?.map((item: any) => (
                <TeacherDocumentBox
                  imgSource={() =>
                    this.getImageSource(item.attributes.filename)
                  }
                  isReviewed={
                    item.attributes.document_status
                  }
                  name={item.attributes.name}
                  fileName={item.attributes.filename}
                  imgWidth={164}
                  imgHeight={125}
                  isUploaded={true}
                  uploadDocument={() => {
                  }}
                  reviewDocument={() => {
                    this.setState(
                      {
                        viewDocument: {
                          ...item.attributes,
                          id: item.id,
                          imgSource: this.getImageSource(
                            item.attributes.filename
                          ),
                          isReviewed:
                            item.attributes.document_status,
                          isUploaded: true,
                        },
                        showDescriptionField: false,
                        fileDescription: "",
                      },
                      () => {} 
                    );
                  }}
                />
              ))}
              {teacherAdditionalDocuments?.length === 0 ? (
                <Box
                  className={`NoFilePreviewText ${this.props.classes.bodyText_font}`}
                >
                  <div>No Files To Preview </div>
                </Box>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              className="UploadingMainDiv"
            >
              <Grid
                item
                xs={12}
                className="UploadingDivItem"
              >
                <Button
                  disableRipple={true}
                  className="UploadingBackBtn"
                  onClick={() => {
                    this.setState({
                      displayScreen: screenName.DocumentDetails,
                      fileUploaded: {},
                      bulkFileUploaded: [],
                      showLoader: false,
                      uploadPercentage: 0,
                    });
                    this.apiBulkDocumentUploadId = []
                  }}
                >
                  <Typography className="BackArrowIconDocument">
                    <img src={backArrowIcon} />
                  </Typography>
                  <Typography className="TextBackArrowDocument">
                    Back
                  </Typography>
                </Button>
              </Grid>
             
              {displayScreen === screenName.UploadingMandatoryDoc && (
                // Uploading File
                <Grid className="alignCenterGrid" item xs={12}>
                  <Box className="uploadingBlock">
                    <div className="UploadingBlockDiv">
                      <CircularProgressbarWithChildren
                        value={uploadPercentage ? uploadPercentage : 0}
                        strokeWidth={2}
                        styles={{
                          path: {
                            stroke: "#3f526d",
                            strokeLinecap: "round",
                          },
                        }}
                      >
                        <img
                          src={fileIcon}
                          className="UploadingFileImg"
                        />
                      </CircularProgressbarWithChildren>
                    </div>
                      <Typography
                        className={`UploadingFileText ${this.props.classes.bodyText_font}`}
                      >
                      Uploading File
                    </Typography>
                      <Typography
                        className={`TakeWhileText ${this.props.classes.bodyText_font}`}
                    >
                      This may take a while.
                    </Typography>
                  </Box>
                </Grid>
                // Uploading File END
              )}
              {displayScreen === screenName.AdditionalDoc && (
                // Additional File
                <Grid item xs={12} className="FullWidthDiv">
                  <div className="FullWidthDiv">
                    <FileDrop
                      onDrop={(files: any, event: any) => {
                        window.addEventListener(
                          "drop",
                          function(e) {
                            e.preventDefault();
                          },
                          false
                        );

                        let totalSize: number = 0;
                        bulkFileUploaded?.map(async (file: any) => {
                          totalSize = totalSize + file.fileSize;
                        });
                        if (files.length > 0) {
                          const keys = Object.keys(files);
                          keys?.map(async (key: any) => {
                            const file = files[key];
                            totalSize = totalSize + file.size;
                          });
                          if (
                            files.length <= 10 - bulkFileUploaded?.length &&
                            totalSize < 524288000
                          ) {
                            this.handleUpload(event, true, files);
                          } else {
                            if (files.length > 10 - bulkFileUploaded?.length) {
                              this.setState({
                                alertMessage: "You can upload maximum 10 files",
                                alertModal: true,
                              });
                            } else {
                              this.setState({
                                alertMessage: "Maximum files size: 500MB",
                                alertModal: true,
                              });
                            }
                          }
                        }
                      }}
                    >
                        <Typography
                          className={`AdditionalFileUploadText ${this.props.classes.heading_font}`}
                      >
                        Additional File Upload
                      </Typography>

                      <input
                        ref={this.inputOpenFileRef}
                        accept="application/pdf, image/png, image/jpg, image/jpeg,"
                        className="UploadDocumentInput"
                        type="file"
                        multiple
                        onChange={(e: any) => {
                          let totalSize: number = 0;
                          bulkFileUploaded?.map(async (file: any) => {
                            totalSize = totalSize + file.fileSize;
                          });

                          var files = e.target.files;
                          if (files.length > 0) {
                            const keys = Object.keys(files);
                            keys?.map(async (key: any) => {
                              const file = files[key];
                              totalSize = totalSize + file.size;
                            });
                            if (
                              e.target.files.length <=
                                10 - bulkFileUploaded?.length &&
                              totalSize < 524288000
                            ) {
                              this.handleUpload(e, true, null);
                            } else {
                              if (
                                e.target.files.length >
                                10 - bulkFileUploaded?.length
                              ) {
                                this.setState({
                                  alertMessage:
                                    "You can upload maximum 10 files.",
                                  alertModal: true,
                                });
                              } else {
                                this.setState({
                                  alertMessage: "Maximum files size: 500MB",
                                  alertModal: true,
                                });
                              }
                            }
                          }
                        }}
                      />

                        <Button
                          className={`AddMoreFileBtn ${this.props.classes.button_color_disabled}`}
                        variant="outlined"
                        style={{                       
                          opacity: bulkFileUploaded?.length >= 10 ? 0.5 : 1,
                        }}
                        onClick={this.showOpenFileDlg}
                        disabled={bulkFileUploaded?.length >= 10}
                      >
                        <img
                          src={addBox}
                          className="AddFileBtnImg"
                        />
                          <Typography
                            className={`AddFileText ${this.props.classes.bodyText_font}`}
                          >
                          Add file
                        </Typography>
                      </Button>

                      {bulkFileUploaded.map(
                        (item: any, index: number) =>
                          item && (
                            <Grid container key={item.id}>
                              <TeacherBulkDocumentBox
                                fileName={item.fileName}
                                fileSize={item.fileSize}
                                file={item.file}
                                deleteClick={() => {
                                  this.bulkUploadDocumentHandleDelete(item.id);
                                }}
                                previewClick={() => {
                                  this.setState({
                                    previewPhoto: true,
                                    previewItem: item,
                                  });
                                }}
                                onChangeFileName={(value: any) => {
                                  const index = bulkFileUploaded.indexOf(item);

                                  if (
                                    index > -1 &&
                                    value.length >= 1 &&
                                    value.trim() !== "" &&
                                    value.indexOf(".") === -1
                                  ) {
                                    const updated = {
                                      ...item,
                                      fileName:
                                        value +
                                        "." +
                                        bulkFileUploaded[index].fileName.split(
                                          "."
                                        )[1],
                                    };

                                    bulkFileUploaded[index] = updated;
                                    this.setState({
                                      bulkFileUploaded: [...bulkFileUploaded],
                                    });
                                  }
                                }}
                                updateProgress={(
                                  percent: number,
                                  signedId: any,
                                  fileData?: any
                                ) => {
                                  this.setState({ singleFileForBulkUpload: item })
                                  const { bulkFileUploaded } = this.state;
                                    const updated = {
                                      ...item,
                                      percentage: percent,
                                    };
                                    const newIndex = bulkFileUploaded.findIndex(
                                      (el: any) => el.id === item.id
                                    );
                                    bulkFileUploaded[newIndex] = updated;
                                    this.setState({
                                      bulkFileUploaded: [...bulkFileUploaded],
                                    });
                                    const count = bulkFileUploaded?.filter(
                                      (item: any) => {
                                        return item.percentage < 100;
                                      }
                                    )?.length;
                                    count === 0 &&
                                      this.setState({ uploadPercentage: 100 });
                                  // }
                                }}
                                getBulkPhotoPreSignedId={this.getBulkPhotoPreSignedId}
                                progress={item?.progress ? item.progress : 0}
                              />
                            </Grid>
                          )
                      )}
                      {uploadPercentage === 100 && (
                        <Grid className="alignCenterGrid" item xs={12}>
                          <div className="AddDescriptionDiv">
                              <Typography
                                className={`AddDescriptionText ${this.props.classes.bodyText_font}`}
                              >
                              {" "}
                              Add Description*
                            </Typography>
                              <TextField
                                className={`AddDescriptionTextField ${this.props.classes.bodyText_font}`}
                              variant={"outlined"}
                              multiline
                              placeholder="Description"
                              value={fileDescription}
                              error={this.state.descriptionError}
                              helperText={this.state.descriptionErrorText}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const value = e.target.value.trim();
                                if (value.length === 0) {
                                  this.setState({
                                    descriptionError: true,
                                    descriptionErrorText:
                                      "Please add description.",
                                  });
                                } else {
                                  this.setState({
                                    descriptionError: false,
                                    descriptionErrorText: "",
                                  });
                                }
                                this.setState({
                                  fileDescription: e.target.value,
                                });
                              }}
                              InputProps={{
                                className: "FileNameInputProps"
                              }}
                            />
                          </div>
                            <Button
                              className={`documentButton ${this.props.classes.bodyText_font} UpdateAllBtn`}
                            style={{
                              opacity:
                                fileDescription.length === 0 &&
                                showDescriptionField
                                  ? 0.5
                                  : 1,                  
                            }}
                            onClick={this.saveUploadedFile}
                            disabled={
                              fileDescription.length === 0 &&
                              showDescriptionField
                            }
                          >
                            Update All
                          </Button>
                        </Grid>
                      )}
                    </FileDrop>
                  </div>
                </Grid>
                // Additional File END
              )}
              {(displayScreen === screenName.UploadMandatoryDoc ||
                displayScreen === screenName.UploadedMandatoryDoc) && (
                <>
                  {displayScreen === screenName.UploadMandatoryDoc && (
                    <Grid
                      className="UploadMandatoryDocumentGrid"
                      item
                      xs={12}
                    >
                      <img
                        src={uploadFile}
                        className="UploadMandatoryDocImg"
                        onClick={this.showOpenFileDlg}
                      />
                      <input
                        ref={this.inputOpenFileRef}
                        className="UploadDocumentInput"
                        type="file"
                        accept="application/pdf, image/png, image/jpg, image/jpeg,"
                        onChange={(e) =>
                          this.handleUpload(
                            e,
                            false,
                            null,
                            screenName.UploadingMandatoryDoc
                          )
                        }
                      />
                        <Typography className={`add_upload_text ${ this.props.classes.bodyText_font}`}>
                        Add New / Upload File
                      </Typography>
                    </Grid>
                  )}
                  {displayScreen === screenName.UploadedMandatoryDoc && (
                    <Grid className="alignCenterGrid" item xs={12}>
                      <Box display="flex">
                        <img
                          src={file_uploaded_pic}
                          className="FileUploadedImg"
                        />
                        <img
                          src={file_uploaded_check_icon}
                          className="FileUploadedCheckImg"
                        />
   
                      </Box>
                      <IconButton
                        className="DeleteUploadedFileIcon"
                        onClick={this.deleteUploadedFile}
                      >
                        <img
                          src={delete_outline_black}
                          className="DeleteUploadedImg"
                          title="Delete"
                        />
                      </IconButton>
                      <Box>
                        <Typography
                          className={`FileUploadedText ${this.props.classes.bodyText_font}`}
                        >
                          File Uploaded
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                      <Typography
                        className={`FileNameHeader ${this.props.classes.bodyText_font}`}
                    >
                      File Name*
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="textfield FileNameInput"
                      variant={"outlined"}
                      disabled={
                        bulkFileUploaded?.length > 0 &&
                        bulkFileUploaded[0].isExistingFile
                      }
                      InputProps={{
                        className: "FileNameInputProps"
                      }}
                      value={
                        bulkFileUploaded?.length > 0 &&
                        bulkFileUploaded[0].fileName
                          ? bulkFileUploaded[0].fileName
                          : ""
                      }
                      onChange={this.handleFileName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={`txt ${this.props.classes.bodyText_font}`}>
                      💡 Name of the file should be relevant to the document
                      attached for faster approval process.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      style={{
                        opacity:
                          displayScreen === screenName.UploadedMandatoryDoc
                            ? 1
                            : 0.5,
                      }}
                      className="documentButton"
                      variant="outlined"
                      disabled={
                        displayScreen === screenName.UploadedMandatoryDoc
                          ? false
                          : true
                      }
                      onClick={() => {
                        this.reUploadDoc
                          ? this.saveReuploadedFile()
                          : this.saveUploadedFile();
                      }}
                    >
                        <Typography
                          className={`UpdateBtnText ${this.props.classes.bodyText_font}`}
                      >
                        Update
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        )}

        <ViewDocumentModal
          onCloseModal={() => {
            this.setState({ viewDocument: {} });
          }}
          viewDocument={viewDocument}
          anchorEl={this.state.anchorEl}
          open={open}
          onMenuClose={this.handleClose}
          otherOptionsClick={(event: any) => this.handleClick(event)}
          reUploadClick={() => {
            scrollToUp();
            this.reUploadDoc = true;
            this.setState({
              anchorEl: null,
              displayScreen: screenName.UploadMandatoryDoc,
              fileUploaded: {
                fileName: viewDocument.fileName
                  ? viewDocument.fileName
                  : viewDocument.filename,
                name: viewDocument.name,
                id: viewDocument.id,
              },
              viewDocument: {},
              uploadPercentage: 0,
              showLoader: false,
              bulkFileUploaded: [
                {
                  ...bulkFileUploaded[0],
                  fileName:
                    viewDocument.isExistingFile &&
                    (viewDocument.fileName
                      ? viewDocument.fileName
                      : viewDocument.filename),
                  name: viewDocument.name,
                  id: viewDocument.id,
                  isExistingFile: viewDocument.isExistingFile,
                },
              ],
            });
          }}
          deleteClick={() => {
            this.setState({ deleteModal: true });
          }}
          closeClick={() => {
            this.setState({ viewDocument: {}, anchorEl: null });
          }}
        />

        <DeleteDocumentModal
          isTeacher={false}
          deleteDocumentModal={this.state.deleteModal}
          onCloseModal={() => {
            this.setState({ deleteModal: false });
          }}
          deleteDocumentClick={this.deleteUploadedDocument}
        />

        <AlertModal
          alertModal={this.state.alertModal}
          isTeacher={false}
          onCloseModal={() => {
            this.setState({ alertModal: false });
          }}
          alertTitle="Alert!"
          alertMessage={this.state.alertMessage}
          buttonText="OK"
        />

        <PreviewPhotoModal
          previewPhotoModal={previewPhoto}
          onCloseModal={() => {
            this.setState({ previewPhoto: false });
          }}
          contentType={previewItem?.content_type}
          fileData={previewItem?.fileData}
          fileName={previewItem?.fileName}
        />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export const teacherDocumentStyles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ebebeb",
      },
    },
  },
};
// Customizable Area End

// Customizable Area Start
const combineStyles = (...styles:any) => {
  return function CombineStyles(theme:any) {
    const outStyles = styles.map((arg:any) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

return outStyles.reduce((acc:any, val:any) => Object.assign(acc, val));
  };
}

const combinedStyle = combineStyles(themeCreateStyle,teacherDocumentStyles);
export default withStyles(combinedStyle) (
  TeacherDocument as React.ComponentType<any>
);
// Customizable Area End
