// Customizable Area Start
import React, { Component } from 'react';
import { BlockComponent } from '../../../../framework/src/BlockComponent';

import './CustomAudioRecorder.css'
// Customizable Area End
export interface Props {
  navigation?: any;
  id?: string;
  location?: any;
  // Customizable Area Start
  audioData: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  canvas: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export class AudioVisualiser extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      canvas: React.createRef(),
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate() {
    this.draw();
  }

  draw() {
    const { audioData } = this.props;
    const canvas = this.state.canvas.current;
    const height = canvas.height;
    const width = canvas.width;
    const context = canvas.getContext('2d');
    let x = 0;
    const sliceWidth = (width * 1.0) / audioData.length;

    const grad = context.createLinearGradient(0, 60, 1, 120);
    grad.addColorStop(0, "#f47d46");
    grad.addColorStop(.5, "#fb2e9b");
    grad.addColorStop(1, "#6407b8");
    context.lineWidth = 2;
    context.strokeStyle = grad;
    context.clearRect(0, 0, width, height);

    context.beginPath();
    context.moveTo(0, height / 2);
    for (const item of audioData) {
      const y = (item / 255.0) * height;
      context.lineTo(x, y);
      x += sliceWidth;
    }
    context.lineTo(x, height / 2);
    context.stroke();
  }

  render() {
    return <canvas className='canvas' ref={this.state.canvas} />;
  }
  // Customizable Area End
}

// Customizable Area Start
export default AudioVisualiser;
// Customizable Area End
