// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
import moment from 'moment';
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  spinner: boolean;
  assessmentSummary: any;
  successMessage: string;
  showLoader: boolean;
  checkStartBtn: boolean;
  // Customizable Area End
}
export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}
export default class StudentAssessmentSummaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAssessmentSummary: string = "";
  apiStartAssessmentTest: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area Start
    this.state = {
      spinner: false,
      assessmentSummary: {},
      successMessage: '',
      showLoader: false,
      checkStartBtn: false,

    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getAssessmentSummary();
  }

  startClick = () => {
    this.startTest()
  }

  navigateToTest = () => {
    HISTORY.push({
      pathname: '/StudentAssesmentAnswer',
      state: {
        id: HISTORY.location.state?.assessmentId
      },
    });
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      switch (apiRequestCallId) {
        case this.apiGetAssessmentSummary:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({
                  assessmentSummary: responseJson.data,
                  showLoader: false
                });
                const check = this.checkAbleToStartTest(responseJson?.data?.assessment?.time_limit, responseJson?.data?.assessment?.publish_date);
                this.setState({ checkStartBtn: check })
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.setState({
              showLoader: false
            })
          }
          break;
        case this.apiStartAssessmentTest:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({
                  showLoader: false
                });
                this.navigateToTest();
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.setState({
              showLoader: false
            })
          }
          break;

      }

    }
    // Customizable Area End
  }
  
  // Customizable Area Start
  getAssessmentSummary = () => {
    this.setState({
      showLoader: true
    })
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')

    const assessmentId = HISTORY.location.state?.assessmentId;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAssessmentSummary = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiAssessmentSummary + `/${Number(assessmentId)}/get_assessment_summary`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkAbleToStartTest = (timeLimit: any, publishDate: any) => {
    let ableToStart: boolean = false;
    const publishDateFormatted = moment(publishDate).format('DD MMM YYYY hh:mm A')
    const timeSplit = timeLimit.split(':');
    const endTime = moment(publishDateFormatted).add(timeSplit[0], 'hours').add(timeSplit[1], 'minutes')
    const currtime = new Date();
    ableToStart = moment(currtime).isBetween(moment(publishDateFormatted), moment(endTime));
    return ableToStart;
  }

  startTest = () => {
    this.setState({
      showLoader: true
    })
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')

    const assessmentId = HISTORY.location.state?.assessmentId;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiStartAssessmentTest = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.startAssessmentTest + `/${Number(assessmentId)}/start_assessment?assessment_start_time=${moment().format("DD MMM YYYY h:mm a")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}