// Customizable Area Start
import { Box, Button, withStyles, Typography, Link, SvgIcon } from "@material-ui/core";
import React from "react"
import { FileDrop } from "react-file-drop";
import { fileUpload, uploadImg, csvFile } from "../assets";
import AssessmentUploadController, { Props } from "./AssessmentUploadController.web";
import UploadAssesmentModalWeb from "./uploadAssesmentModal.web";
import { KeyboardBackspaceOutlined } from '@material-ui/icons';
import AlertModal from "../../../alert/src/AlertModal.web";
import ErrorUploadAssesmentModalWeb from "./ErrorUploadAssesmentModal.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import './AssessmentUpload.web.css';
import ErrorCSV from "./ErrorCSV.web";
import ReactPaginate from "react-paginate";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class AssessmentUpload extends AssessmentUploadController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { isMedia, isErrorCSV } = this.state
        const { classes } = this.props;
        return (
            <>
                <div className={`position-relative`}>
                    <Box
                        display="flex"
                        className={`back-btn-box`}
                        onClick={this.navigationBackHandler}
                    >
                        <KeyboardBackspaceOutlined className={classes.icon_color} />
                        <Typography
                            className={`back-btn-text ${classes.bodyText_font}`}
                        >{"   "}Back</Typography>
                    </Box>

                    <Box className={`flex-center`}>
                        <Box className="upload_csv_main_div">
                            <Box
                                className={`assessment-upload-btn-box`}
                            >
                                <Button className={`upload_csv_div ${!isErrorCSV && classes.border_Color}`}
                                    onClick={this.onClickUploadCSVHandler}
                                >
                                    <Typography className={`${classes.subHeading_font} upload_text`}>
                                        Upload CSV
                                    </Typography>
                                </Button>
                                <Button className="upload_csv_div upload_media_ml">
                                    <Typography className={`${classes.subHeading_font} upload_text`}>
                                        Upload Media
                                    </Typography>
                                </Button>
                                <Button
                                    className={`upload_csv_div upload_media_ml ${isErrorCSV && classes.border_Color}`}
                                    onClick={this.onClickErrorCSVHandler}
                                >
                                    <Typography className={`${classes.subHeading_font} upload_text`}>
                                        Error CSV
                                    </Typography>
                                </Button>
                            </Box>
                            {isMedia && <>
                                <FileDrop
                                    onDrop={this.isMediaOnDropHandler}
                                >
                                    <Box className="upload_csv_instruction_section">
                                        {isMedia && <Box>
                                            <Typography className={`${classes.subHeading_font} upload_text`} >You can bulk upload the questions using CSV files</Typography>
                                        </Box>}
                                        {isMedia && <Box>
                                            <Link href={csvFile}
                                                download="sample_format.csv"
                                                className={`sample-csv-link`}
                                            >
                                                <Button className={`${classes.border_Color} sample_csv_btn`}
                                                >
                                                    <SvgIcon strokeWidth="0" width="22" height="22" viewBox="0 0 22 22" className={classes.assesmentUpload_AssessmentTest_svg_icon_stroke}>
                                                        <g fill="none" fillRule="evenodd">
                                                            <path d="M0 0h22v22H0z" />
                                                            <path d="M3.667 15.583v1.834c0 1.012.82 1.833 1.833 1.833h11c1.013 0 1.833-.82 1.833-1.833v-1.834M6.417 10.083 11 14.667l4.583-4.584M11 3.667v11" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                                                        </g>
                                                    </SvgIcon>
                                                    <Typography className={`${classes.subHeading_font} sample_csv_text`}> Sample CSV</Typography>
                                                </Button>
                                            </Link>
                                        </Box>

                                        }
                                    </Box>
                                    <Box className="import_box">
                                        {this.state.files ?
                                            <>
                                                {this.state.files.map((item: any) => (
                                                    <Box
                                                        className={`text-align-center`}
                                                    >
                                                        <Box>
                                                            <img src={fileUpload} className={`selected-file-img`} />
                                                        </Box>
                                                        <Box>
                                                            <Typography
                                                                className={`${classes.subHeading_font} selected-file-name-text font_16_xs`}
                                                            >
                                                                {item.name}
                                                            </Typography>
                                                        </Box>
                                                        <Box className={`text-align-center`}>
                                                            <Typography
                                                                className={`${classes.bodyText_font} file-uploaded-text`}
                                                            >
                                                                File Uploaded
                                                            </Typography>

                                                        </Box>
                                                    </Box>
                                                ))}
                                            </>
                                            :
                                            <>
                                                <img src={uploadImg} className="upload_csv_img" />
                                                <Box className={`mt-3-p`}>
                                                    <Typography className={`${classes.bodyText_font} drag_and_drop_file_text`}>Drag and drop files here or <span
                                                        className={`${classes.bodyText_font} browse-text`}
                                                        onClick={this.onClickBrowseHandler}
                                                    >browse</span></Typography>
                                                </Box>
                                                <Box className={`mt-1-p`}>
                                                    {!isMedia ?
                                                        <Box>
                                                            <Typography className={`${classes.bodyText_font} drag_and_drop_file_text`}>supported formats .png .jpg .gif .pdf </Typography>
                                                            <Typography className={`${classes.bodyText_font} drag_and_drop_file_text text-align-center mt-2-p`} >MS Excel google sheets</Typography>
                                                        </Box>
                                                        :
                                                        <Typography className={`${classes.bodyText_font} drag_and_drop_file_text`}>supported formats .csv files</Typography>}
                                                </Box>
                                                <input
                                                    ref={this.inputOpenFileRef}
                                                    accept={!isMedia ? "image/*" : ".csv"}
                                                    className={`d-none`}
                                                    type="file"
                                                    onChange={this.onChangeInputHandler}
                                                />
                                            </>
                                        }
                                    </Box>
                                </FileDrop>
                                <Box
                                    className={`action-btn-box`}
                                >
                                    <Button className={`${classes.button_color} assessment_csv_upload_btn ${!this.state.files ? 'opacity-40' : 'opacity-100'}`}
                                        disabled={!this.state.files}
                                        onClick={this.uploadAssessment}
                                    >Upload
                                    </Button>
                                    <Button className={`${classes.outline_button} assessment_csv_cancel_btn ${!this.state.files ? 'opacity-40' : 'opacity-100'}`}
                                        disabled={!this.state.files}
                                        onClick={this.onClickCancelHandler}
                                    >
                                        <Typography className={`${classes.assesmentUpload_AssessmentTest_outline_button_text}`}>Cancel</Typography>
                                    </Button>
                                </Box>
                            </>
                            }
                            {isErrorCSV
                                &&
                                <div>
                                    <div className="ErrorCSV_PaginationBtmDiv">
                                        <ErrorCSV metaDataHandler={this.metaDataHandler} page={this.state.page} />
                                    </div>
                                    {this.state.errorCSVMetaData?.pagination?.total_pages > 1 &&
                                        <ReactPaginate
                                            previousLabel={<span>←{" "}<span className="xs_display">Previous</span></span>}
                                            nextLabel={<span><span className="xs_display">Next</span>{" "}→</span>}
                                            breakLabel="..."
                                            initialPage={this.state.page}
                                            pageCount={this.state.errorCSVMetaData?.pagination?.total_pages}
                                            onPageChange={this.paginationChangeHandler}
                                            containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                                            previousLinkClassName={"padded-span"}
                                            nextLinkClassName={"padded-span"}
                                            disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                                            activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                                            pageRangeDisplayed={2}
                                            marginPagesDisplayed={2}
                                        />
                                    }
                                </div>
                            }
                        </Box>
                    </Box>
                    <UploadAssesmentModalWeb
                        openModal={this.state.uploadModal}
                        handleOnClose={this.uploadOnCloseHandler}
                        navigation={this.props.navigation}
                    />
                    <AlertModal
                        alertModal={this.state.alertModal}
                        isTeacher={true}
                        onCloseModal={this.alertCloseHandler}
                        alertTitle="Alert!"
                        alertMessage={this.state.alertMessage}
                        buttonText="OK"
                    />
                    {/* Error Modal  */}
                    <ErrorUploadAssesmentModalWeb
                        openModal={this.state.errorModal}
                        ReUpload={this.errorReUploadHandler}
                        totalQuestions={this.state.uploadResponse.success_count + this.state.uploadResponse.fail_count}
                        uploadedQuestions={this.state.uploadResponse.success_count}
                        errorCSVDownloadLink={this.state.uploadResponse.file_path}
                        handleOnClose={this.errorCloseHandler}
                    />
                    {/* Error Modal END */}
                    {this.state.spinner &&
                        <Spinner spinnerModal={this.state.spinner} />}
                </div>
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(AssessmentUpload);
// Customizable Area End