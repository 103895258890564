// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  editImg,
} from "../../ClassesAndSections/src/Enroll/assets";
import ReactPaginate from "react-paginate";
import {
  Box,
  Button,
  InputBase,
  Typography,
  Dialog,
  Tabs,
  NativeSelect,
} from "@material-ui/core";
import { DeleteForever, Close } from "@material-ui/icons";
import AdminTermController, { Props } from "./AdminTermController.web";
import Spinner from "../../shared/SideBar/src/Spinner";
import DeleteModal from "./DeleteModal.web";
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../shared/Header/src/HOCAuth.web";
import moment from 'moment';

const listing = require("../assets/listing.png");
// Customizable Area End

// Customizable Area Start
const tabsList = [
  {
    key: 1,
    value: "terms",
    label: "Terms",
  },
  {
    key: 2,
    value: "assessment",
    label: "Assessment Guidelines",
  },
];
// Customizable Area End

// Customizable Area Start
const avtarIcon = {
  width: "31px",
  height: "31px",
  margin: "20px 23px 20px 19px",
} as const;
const forrmInput_textfield = {
  width: "100%",
  borderRadius: "6px",
  textAlign: "start",
  color: "#3f526d",
  fontSize: "1.125rem",
  fontFamily: "Open sans",
} as const;

const BootstrapInput2 = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    backgroundColor: theme.palette.background.paper,
    padding: "10px 26px 10px 12px",
    width: "108px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: "Open sans",
    color: "#3f526d",
    fontSize: 18,
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const BootstrapInput5 = withStyles((theme: any) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    border: "solid 1px #ebebeb",
    backgroundColor: "#fff",
    padding: "10px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: theme.props.textColor,
    fontFamily: theme.props.textFont,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "21px",
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
// Customizable Area End

class AdminTerm extends AdminTermController {
  // Customizable Area Start
  onEdit = (data: any, field_id: number) => {
    this.setState({
      CreateOrEdit: "edit",
      dialogOpen: true,
      term: { title: data?.attributes?.title, field_id: field_id },
    });
  };

  deleteHandler = (val: string) => {
    this.setState({ removeModal: false });
    if (val === "delete") {
      if (this.state.selectedTab === "terms") {
        this.deleteTerm();
      } else {
        this.deleteGuidelines();
      }
    }
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    const { selectedIndex, selectedTab } = this.state;
    return (
      <>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Box width="100%">
          <Box
            display="flex"
            width="100%"
            pb="20px"
            marginTop="2%"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="row"
              width="40%"
              alignItems="center"
            >
              <Box display="flex" justifyContent="flex-start">
                <Typography
                  style={{
                    textTransform: "none",
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                  className={this.props.classes.heading_font}
                >
                  Academic Planner
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box width="100%">
            <Box display="flex" flexDirection="column" pr="10px">
              <Tabs
                value={"resources"}
                // onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabsList?.map((item, i) => (
                  <Button
                    style={{
                      cursor: "pointer",
                      height: 39,

                      margin: "0px 10px 0px 0px",
                      padding: 10,
                      minWidth: "133px",
                    }}
                    key={item.key}
                    onClick={() => {
                      this.handleSelectTab(item.value, item.key);
                    }}
                    className={item.key === selectedIndex ? this.props.classes.button_color : this.props.classes.button_color_disabled}
                  >
                    <Typography
                      style={{
                        textTransform: "none",
                        fontSize: 15,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                        color: item.key === selectedIndex ? "white" : "#8F99A5",
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Button>
                ))}
              </Tabs>
            </Box>
          </Box>
          <Box>
            <div
              style={{
                margin: "15px 0px",
                borderBottom: "1px solid rgba(0, 0, 0, 0.17)",
              }}
            />
          </Box>
          <Box>
            {/* {selectedTab === "assessment" && (     //Need to implement again when board logic is clear
              <NativeSelect
                id="select"
                value={null}
                input={<BootstrapInput5 />}
                style={{
                  width: 180,
                  marginBottom: 20,
                  marginRight: 20,
                }}
                onChange={(event) => {}}
              >
                <option value="" disabled>Select Board</option>
                {this.state.boardList?.map(board=>{
                return <option value={board.id}>{board?.name}</option>
                })}
              </NativeSelect>
            )} */}
            <InputBase
              style={{
                border: "solid 1px #ebebeb",
                width: "100px",
                backgroundColor: "#fff",
                padding: "10px 10px",
                marginRight: "10px",
                height: "42px",
                fontFamily: "Open sans",
                color: "#3f526d",
                fontWeight: 600,
                fontSize: 16,
                lineHeight: "21px",
                boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
              }}
              value={`${Number(moment().format("YYYY"))}-${Number(moment().format("YY")) + 1}`}
            />
            <NativeSelect
              id="select"
              value={this.state.grade}
              input={<BootstrapInput5 />}
              style={{
                width: 180,
                marginBottom: 20,
              }}
              onChange={(event) => {
                this.changeClassDropdown(event);
              }}
            >
              <option value="" disabled>Select Grade</option>
              {this.state.gradeList?.map(post => {
                if (post.attributes && post.attributes.name) {
                  return <option value={post.id}>{post.attributes.school_grade_name}</option>
                }
              })}
            </NativeSelect>
          </Box>
          <div
            style={{
              padding: "30px 25px 26px",
              borderRadius: 8,
              boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
              minHeight: "50vh",
              position: "relative",
              marginBottom: "27px",
            }}
          >
            {selectedTab === "terms" && (
              <>
                <Typography
                  style={{
                    color: "#3f526d",
                    fontWeight: 600,
                    fontFamily: "Open sans",
                  }}
                >
                  August 2020 - June 2021
                </Typography>
                {this.state.terms !== [] && (
                  <>
                    {this.state.terms?.map((item: any, index: number) => (
                      <div>
                        <div
                          style={{
                            marginTop: "40px",
                            display: "flex",
                            alignItems: "center",
                            position: "relative"
                          }}
                        >
                          <div style={{
                            display: index < this.state.terms?.length - 1 ? "block" : "none",
                            height: "82px",
                            position: "absolute",
                            borderLeft: "1.5px solid rgba(0, 0, 0, 0.17)",
                            top: "36px",
                            left: "5px"
                          }}></div>
                          <div
                            style={{
                              backgroundColor: "#7F6CFC",
                              height: "15px",
                              width: "15px",
                              marginRight: "25px",
                            }}
                          />
                          <Box
                            style={{
                              background: "#f9f8fd",
                              width: "100%",
                              padding: "15px 20px",
                              borderRadius: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                              }}
                              className={this.props.classes.bodyText_font}
                            >
                              {item?.attributes?.title}
                            </Typography>
                            <img
                              src={editImg}
                              onClick={() => this.onEdit(item, item?.id)}
                              style={{
                                cursor: "pointer",
                                height: 25
                              }}
                            />
                          </Box>
                          <div
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <DeleteForever
                              onClick={() => {
                                this.setState({ removeModal: true });
                                this.setState({
                                  deleteData: { item, field_id: item?.id },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    {this.state.grade !== "" && (
                      <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }}>
                        <Button
                          variant="outlined"
                          style={{
                            marginLeft: this.state.terms?.length === 0 ? "0px" : "35px",
                            marginTop: "20px",
                            cursor: "pointer",
                            borderRadius: "6px",
                            padding: " 10px 32px",
                            textTransform: "capitalize",
                          }}
                          className={this.props.classes.button_color}
                          onClick={() => {
                            this.setState({ dialogOpen: true });
                            this.setState({ CreateOrEdit: "create" });
                            this.state.term.title = ''
                          }}
                        >
                          Add
                        </Button>
                        {this.state.total_pages > 1 && <Box style={{ marginRight: "20px" }}>
                          <ReactPaginate
                            previousLabel={"←   Previous"}
                            nextLabel={"Next   →	"}
                            initialPage={this.state.pagination_page}
                            // forcePage={1}
                            pageCount={this.state.total_pages}
                            onPageChange={(e: any) => {
                              if (e.selected !== this.state.pagination_page) {
                                this.setState({ pagination_page: e.selected });
                                this.getTerm(e.selected + 1)
                              }
                            }}
                            containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                        previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                        nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                        disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                        activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={1}
                          />
                        </Box>}
                      </div>
                    )}

                  </>
                )}
                {this.state.grade === "" && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="39vh"
                  >
                    <div style={{ textAlign: "center" }}>
                      <img src={listing} />
                      <h3 style={{ color: "#40526B" }}>Please Select Class</h3>
                    </div>
                  </Box>
                )}
              </>
            )}
            {selectedTab === "assessment" && (
              <>
                <h4 className={this.props.classes.heading_font}>Assessment Guidelines</h4>
                <h4 className={this.props.classes.subHeading_font} style={{ marginTop: "25px" }}>
                  Guidelines:
                </h4>
                {this.state.guidelines?.map((item: any) => (
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      style={{
                        background: "#f9f8fd",
                        width: "100%",
                        padding: "15px 20px",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            backgroundColor: "#D0D2DD",
                            height: "10px",
                            width: "10px",
                            marginTop: "8px",
                          }}
                        />
                        <Typography
                          style={{
                            marginLeft: "15px",
                            fontSize: "18px",
                            fontWeight: 600,
                          }}
                          className={this.props.classes.bodyText_font}
                        >
                          {item.attributes.description}
                        </Typography>
                      </div>
                      <div>
                        <DeleteForever
                          onClick={() => {
                            this.setState({ removeModal: true });
                            this.setState({
                              deleteData: { item, field_id: item.id },
                            });
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Box>
                  </div>
                ))}
                {this.state.grade !== "" && (
                  <>
                    <div style={{ position: "relative" }}>
                      <InputBase
                        style={{
                          border: "solid 1px black",
                          borderRadius: "10px",
                          width: "100%",
                          backgroundColor: "#fff",
                          padding: "10px 10px",
                          marginRight: "10px",
                          marginTop: "20px",
                          marginBottom: "50px",
                          fontFamily: "Open sans",
                          fontSize: 16,
                        }}
                        value={this.state.newGuideline}
                        onChange={(event: any) =>
                          this.setState({ newGuideline: event.target.value })
                        }
                        placeholder={`${this.state.guidelines?.length + 1}.Enter Guidelines`}
                        multiline={true}
                        rows={5}
                      />
                    </div>
                    <Button
                      style={{
                        position: "absolute",
                        bottom: 20,
                        left: 20,
                        padding: "10px 20px",
                      }}
                      className={this.props.classes.button_color}
                      onClick={this.createGuidelines}
                    >
                      Save
                    </Button>

                  </>
                )}
              </>
            )}
          </div>
        </Box>
        <Dialog
          PaperProps={{
            style: { borderRadius: 20 },
          }}
          aria-labelledby="customized-dialog-title"
          maxWidth="sm"
          fullWidth
          open={this.state.dialogOpen}
          closeAfterTransition
          disableAutoFocus={true}
        >
          <Box margin="10px 30px 70px">
            <Box display="flex" justifyContent="center">
              <h3 className={this.props.classes.title_font}>
                {this.state.CreateOrEdit == "create" ? "Create Term" : "Edit Term"}
              </h3>
              <Close
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  right: "30px",
                  top: "32px",
                }}
                onClick={() => this.setState({ dialogOpen: false })}
              />
            </Box>
            <Box>
              <div
                style={{
                  margin: "15px 0px",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.17)",
                }}
              />
            </Box>
            <Box marginTop="30px">
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "21px",
                  marginBottom: "5px",
                }}
                className={this.props.classes.subTitle_font}
              >
                Term Title
              </div>
              <InputBase
                fullWidth
                value={this.state.term?.title}
                style={{
                  border: "1px solid rgb(236 223 223)",
                  borderRadius: 5,
                  height: "50px",
                  paddingLeft: "10px",
                }}
                placeholder="Enter Term"
                onChange={(event) => {
                  this.setState({
                    term: { ...this.state.term, title: event.target.value },
                  });
                }}
              />
            </Box>
            <Box display="flex" justifyContent="center" margin="40px 0px">
              <Button
                style={{
                  textTransform: "none",
                  padding: "5px 25px",
                  marginRight: "10px",
                }}
                onClick={() => {
                  this.setState({ dialogOpen: false });
                  this.setState({ term: { title: "", field_id: null } });
                  this.state.CreateOrEdit === "create"
                    ? this.createTerm()
                    : this.editTerm()
                }}

                className={this.props.classes.button_color}
              >
                {this.state.CreateOrEdit == "create" ? "Add Term" : "Edit Term"}
              </Button>
              <Button
                style={{
                  padding: "5px 25px",
                  textTransform: "none",
                }}
                onClick={() => {
                  this.setState({ dialogOpen: false });
                  this.setState({ term: { title: "", field_id: null } });
                }}
                className={this.props.classes.button_color_disabled}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
        <DeleteModal
          RemoveModal={this.state.removeModal}
          closeModalFun={(val: any) => this.deleteHandler(val)}
        />
      </>
    );
  }
  // Customizable Area End
}
export const themeCreatStyle = ((theme: any) => ({
  // Customizable Area Start
  background_color: {
    backgroundColor: theme.palette.primary.main
  },
  title_font: {
    color: theme.props.titleColor,
    fontFamily: theme.props.titleFont
  },
  subTitle_font: {
    color: theme.props.subtitleColor,
    fontFamily: theme.props.subtitleFont
  },
  heading_font: {
    color: theme.props.headingColor,
    fontFamily: theme.props.headingFont
  },
  subHeading_font: {
    color: theme.props.subheadingColor,
    fontFamily: theme.props.subheadingFont
  },
  bodyText_font: {
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  checkBox_color: {
    color: theme.props.buttonColor
  },
  button_color: {
    backgroundColor: theme.props.buttonColor,
    color: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    '&:hover': {
      background: theme.props.buttonColor,
    }
  },
  border_Color: {
    border: `1px solid ${theme.props.buttonColor}`
  },
  icon_color: {
    color: theme.props.iconColor
  },
  file_border_Color: {
    border: `dashed 0.5px ${theme.props.buttonColor}`,
  },
  button_color_disabled: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    '&:hover': {
      background: "#fff",
    }
  },
  normal_button_color: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
  },
  dashed_Border: {
    border: `0.3px dashed ${theme.props.buttonColor}`,
  },
  // Customizable Area End
}))

// Customizable Area Start
export default withStyles(themeCreatStyle)(HOCAuth(AdminTerm as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End
