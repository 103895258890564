import React from "react";
// Customizable Area Start
import {
    StyleSheet,
    Platform,
} from "react-native";
import Picker from "emoji-picker-react";
import CloseIcon from '@material-ui/icons/Close';
import validator from 'validator';
import FormHelperText from "@material-ui/core/FormHelperText";
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    IconButton,
    Link,
    MenuItem,
    Select,
    NativeSelect,
    TextField,
    Typography
} from "@material-ui/core";
import {
    Search,
    Replay,
    NotificationsNone,
    HeadsetMicOutlined,
    Title,
    TitleOutlined,
    KeyboardArrowLeft,
    EditOutlined,
    MoreVertOutlined,
    KeyboardArrowDown,
    DesktopAccessDisabledOutlined,
    ArrowDropDown
} from "@material-ui/icons";
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import CommentModalController, { Props } from "./CommentModalController.web";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FileDrop } from 'react-file-drop';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
// Customizable Area End
class CommentModal extends CommentModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { showPicker, inputStr, commentData, commentLike, comment_id, nestedComment, commentDescription } = this.state
        const onEmojiClick = (event: any, emojiObject: any) => {
            this.setState({ inputStr: this.state.inputStr + emojiObject.emoji, showPicker: false })
        };
        console.log(commentData)
        let height = screen.height;
        console.log(height)
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'transparent',
                    }}
                    open={this.props.openComment}
                    onClose={this.modalhandleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableAutoFocus={true}
                >
                    <Fade in={this.props.openComment} style={{ border: "none" }}>
                        <div style={{
                            backgroundColor: "white",
                            border: "1px solid #979797",
                            outline: "none",
                            width: "75%",
                            height: this.state.showAllComment ? "90%" : "auto",
                            overflow: this.state.showAllComment ? "auto" : "hidden",
                            padding: "20px 20px 23px",
                            borderRadius: 8,
                            boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.25)"
                        }}>
                            <Box>
                                <Typography style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Open sans",
                                    fontWeight: 600,
                                    cursor: "pointer",
                                    color: "#af38cb",
                                    textDecoration: "underline"
                                }}
                                    onClick={() => { this.setState({ showAllComment: !this.state.showAllComment }) }}>
                                    {!this.state.showAllComment ? <>View Previews Comments</> : <> View Latest Comments</>}
                                </Typography>
                            </Box>
                            {
                                this.state.showAllComment ?
                                    commentData.map((comment: any, index: number) => (
                                        <>
                                            <Box style={{
                                                width: "100%",
                                                padding: "16px 0px 0px 0px",
                                                borderRadius: 8,
                                                backgroundColor: "#fff",
                                                flexDirection: "column",
                                            }}>
                                                <Box style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    width: "100%",
                                                }}>
                                                    <Box style={{ verticalAlign: "middle", position: "relative", marginRight: 10 }}>
                                                        <Avatar src={comment.attributes.added_by.data.attributes.avatar} style={{ width: 38, height: 38, }} />
                                                        {comment.attributes.comments.data.length != 0 &&
                                                            <Box style={{
                                                                border: "0.5px solid #979797",
                                                                height: "100%",
                                                                position: "absolute",
                                                                left: "50%",
                                                                opacity: 0.3,
                                                                marginTop: 10,
                                                                borderRadius: "0px 0px 0px 4px"
                                                            }}>
                                                            </Box>
                                                        }


                                                    </Box>
                                                    <Box style={{
                                                        width: "100%",
                                                        backgroundColor: "#f9f8fd",
                                                        padding: "8px 20px",
                                                        borderRadius: 8,
                                                    }}>
                                                        <Typography
                                                            style={{
                                                                textTransform: "capitalize",
                                                                fontSize: "0.8rem",
                                                                fontFamily: "Open Sans",
                                                                fontWeight: 600,
                                                                color: "#3f526d",
                                                                backgroundColor: "rgb(249, 248, 253)",
                                                            }}
                                                        >
                                                            {comment.attributes.added_by.data.attributes.first_name} {comment.attributes.added_by.data.attributes.last_name}
                                                        </Typography>
                                                        {
                                                            validator.isURL(comment.attributes.description)
                                                                ?
                                                                <>
                                                                    <Link
                                                                        target="_blank"
                                                                        href={comment.attributes.description}
                                                                        style={{
                                                                            textTransform: "none",
                                                                            fontSize: "0.8rem",
                                                                            fontFamily: "Open Sans",
                                                                            fontWeight: 600,
                                                                            color: "#3f526d",
                                                                            backgroundColor: "rgb(249, 248, 253)",
                                                                            opacity: 0.6,
                                                                        }}>
                                                                        {comment.attributes.description}
                                                                    </Link>
                                                                </>
                                                                :
                                                                <>
                                                                    <Typography
                                                                        style={{
                                                                            textTransform: "none",
                                                                            fontSize: "0.8rem",
                                                                            fontFamily: "Open Sans",
                                                                            fontWeight: 600,
                                                                            color: "#3f526d",
                                                                            backgroundColor: "rgb(249, 248, 253)",
                                                                            opacity: 0.6,
                                                                        }}
                                                                    >
                                                                        {comment.attributes.description}
                                                                    </Typography>
                                                                </>
                                                        }

                                                    </Box>
                                                </Box>
                                                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "end", marginTop: 10, }}>
                                                    <Typography
                                                        style={{
                                                            textTransform: "none",
                                                            fontSize: "0.8rem",
                                                            fontFamily: "Open Sans",
                                                            fontWeight: 600,
                                                            color: comment.attributes.likes === 1 ? "#af38cb" : "#471c51",
                                                            opacity: comment.attributes.likes === 1 ? 1 : 0.4,
                                                            marginRight: 20,
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => {
                                                            this.commentLike(comment.id, comment.type, !this.state.commentLike)
                                                            this.setState({ commentLike: !this.state.commentLike, comment_id: comment.id })
                                                        }}
                                                    >
                                                        Like
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            textTransform: "none",
                                                            fontSize: "0.8rem",
                                                            fontFamily: "Open Sans",
                                                            fontWeight: 600,
                                                            color: "#3f526d",
                                                            opacity: 0.4,
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => {
                                                            if (comment.attributes.comments.data.length === 0) {
                                                                this.setState({ nestedComment: true, nestedcomment_id: comment.attributes.id, commentDescription: comment.attributes.description })
                                                            }
                                                        }
                                                        }
                                                    >
                                                        Reply
                                                    </Typography>
                                                </Box>


                                                {/* Nested Comment start */}
                                                {
                                                    comment.attributes.comments.data.map((data: any) => (
                                                        <Box style={{
                                                            width: "100%",
                                                            padding: "14px 0px 12px 20px",
                                                            borderRadius: 8,
                                                            backgroundColor: "#fff",
                                                            flexDirection: "column",
                                                        }}>
                                                            <Box style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                            }}>
                                                                <Box style={{ display: "flex", flexDirection: "row" }}>
                                                                    <Box style={{
                                                                        border: "0.5px solid #979797",
                                                                        height: "28%",
                                                                        margin: "5px 0px 0px -1px",
                                                                        opacity: 0.3,
                                                                        borderRadius: "0px 0px 0px 4px"
                                                                    }}>
                                                                    </Box>
                                                                    <Box style={{ border: "0.5px solid #979797", opacity: 0.3, width: 10, borderRadius: 4, height: 1, marginTop: 20, marginRight: 10 }}></Box>

                                                                    <Avatar src={this.state.data.avatar} style={{ width: 38, height: 38, marginRight: 10 }} />
                                                                </Box>

                                                                <Box style={{
                                                                    width: "100%",
                                                                    backgroundColor: "#f9f8fd",
                                                                    padding: "11px 20px 11px 20px",
                                                                    borderRadius: 8,
                                                                }}>
                                                                    <Typography
                                                                        style={{
                                                                            textTransform: "capitalize",
                                                                            fontSize: "0.8rem",
                                                                            fontFamily: "Open Sans",
                                                                            fontWeight: 600,
                                                                            color: "#3f526d",
                                                                            backgroundColor: "rgb(249, 248, 253)",
                                                                        }}
                                                                    >
                                                                        {data.attributes.added_by.data.attributes.first_name} {data.attributes.added_by.data.attributes.last_name}
                                                                    </Typography>

                                                                    {
                                                                        validator.isURL(data.attributes.description)
                                                                            ?
                                                                            <>
                                                                                <Link
                                                                                    target="_blank"
                                                                                    href={data.attributes.description}
                                                                                    style={{
                                                                                        textTransform: "none",
                                                                                        fontSize: "0.8rem",
                                                                                        fontFamily: "Open Sans",
                                                                                        fontWeight: 600,
                                                                                        color: "#3f526d",
                                                                                        backgroundColor: "rgb(249, 248, 253)",
                                                                                        opacity: 0.6,
                                                                                    }}>
                                                                                    {data.attributes.description}
                                                                                </Link>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Typography
                                                                                    style={{
                                                                                        textTransform: "none",
                                                                                        fontSize: "0.8rem",
                                                                                        fontFamily: "Open Sans",
                                                                                        fontWeight: 600,
                                                                                        color: "#3f526d",
                                                                                        backgroundColor: "rgb(249, 248, 253)",
                                                                                        opacity: 0.6,
                                                                                    }}
                                                                                >
                                                                                    {data.attributes.description}
                                                                                </Typography>
                                                                            </>
                                                                    }

                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                {/* nested Comment End */}

                                            </Box>
                                        </>
                                    )
                                    )
                                    :
                                    commentData.map((comment: any, index: number) => (
                                        index < 2 && (
                                            <>
                                                <Box style={{
                                                    width: "100%",
                                                    padding: "14px 0px 0px 0px",
                                                    borderRadius: 8,
                                                    backgroundColor: "#fff",
                                                    flexDirection: "column",
                                                }}>
                                                    <Box style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: "100%",
                                                    }}>
                                                        <Box style={{ verticalAlign: "middle", position: "relative", marginRight: 10 }}>
                                                            <Avatar src={comment.attributes.added_by.data.attributes.avatar} style={{ width: 38, height: 38, }} />
                                                            {comment.attributes.comments.data.length != 0 &&
                                                                <Box style={{
                                                                    border: "0.5px solid #979797",
                                                                    height: "100%",
                                                                    position: "absolute",
                                                                    left: "50%",
                                                                    opacity: 0.3,
                                                                    marginTop: 10,
                                                                    borderRadius: "0px 0px 0px 4px"
                                                                }}>
                                                                </Box>
                                                            }


                                                        </Box>
                                                        <Box style={{
                                                            width: "100%",
                                                            backgroundColor: "#f9f8fd",
                                                            padding: "11px 20px 11px 20px",
                                                            borderRadius: 8,
                                                        }}>
                                                            <Typography
                                                                style={{
                                                                    textTransform: "capitalize",
                                                                    fontSize: "0.8rem",
                                                                    fontFamily: "Open Sans",
                                                                    fontWeight: 600,
                                                                    color: "#3f526d",
                                                                    backgroundColor: "rgb(249, 248, 253)",
                                                                }}
                                                            >
                                                                {comment.attributes.added_by.data.attributes.first_name} {comment.attributes.added_by.data.attributes.last_name}
                                                            </Typography>
                                                            {
                                                                validator.isURL(comment.attributes.description)
                                                                    ?
                                                                    <>
                                                                        <Link
                                                                            target="_blank"
                                                                            href={comment.attributes.description}
                                                                            style={{
                                                                                textTransform: "none",
                                                                                fontSize: "0.8rem",
                                                                                fontFamily: "Open Sans",
                                                                                fontWeight: 600,
                                                                                color: "#3f526d",
                                                                                backgroundColor: "rgb(249, 248, 253)",
                                                                                opacity: 0.6,
                                                                            }}>
                                                                            {comment.attributes.description}
                                                                        </Link>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Typography
                                                                            style={{
                                                                                textTransform: "none",
                                                                                fontSize: "0.8rem",
                                                                                fontFamily: "Open Sans",
                                                                                fontWeight: 600,
                                                                                color: "#3f526d",
                                                                                backgroundColor: "rgb(249, 248, 253)",
                                                                                opacity: 0.6,
                                                                            }}
                                                                        >
                                                                            {comment.attributes.description}
                                                                        </Typography>
                                                                    </>
                                                            }

                                                        </Box>
                                                    </Box>
                                                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "end", marginTop: 10, }}>
                                                        <Typography
                                                            style={{
                                                                textTransform: "none",
                                                                fontSize: "0.8rem",
                                                                fontFamily: "Open Sans",
                                                                fontWeight: 600,
                                                                color: comment.attributes.likes === 1 ? "#af38cb" : "#471c51",
                                                                opacity: comment.attributes.likes === 1 ? 1 : 0.4,
                                                                marginRight: 20,
                                                                cursor: "pointer"
                                                            }}
                                                            onClick={() => {
                                                                this.commentLike(comment.id, comment.type, !this.state.commentLike)
                                                                this.setState({ commentLike: !this.state.commentLike, comment_id: comment.id })
                                                            }}
                                                        >
                                                            Like
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                textTransform: "none",
                                                                fontSize: "0.8rem",
                                                                fontFamily: "Open Sans",
                                                                fontWeight: 600,
                                                                color: "#3f526d",
                                                                opacity: 0.4,
                                                                cursor: "pointer"
                                                            }}
                                                            onClick={() => {
                                                                if (comment.attributes.comments.data.length === 0) {
                                                                    this.setState({ nestedComment: true, nestedcomment_id: comment.attributes.id, commentDescription: comment.attributes.description })
                                                                }
                                                            }
                                                            }
                                                        >
                                                            Reply
                                                        </Typography>
                                                    </Box>


                                                    {/* Nested Comment start */}
                                                    {
                                                        comment.attributes.comments.data.map((data: any) => (
                                                            <Box style={{
                                                                width: "100%",
                                                                padding: "14px 0px 12px 20px",
                                                                borderRadius: 8,
                                                                backgroundColor: "#fff",
                                                                flexDirection: "column",
                                                            }}>
                                                                <Box style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    width: "100%",
                                                                }}>
                                                                    <Box style={{ display: "flex", flexDirection: "row" }}>
                                                                        <Box style={{
                                                                            border: "0.5px solid #979797",
                                                                            height: "28%",
                                                                            margin: "5px 0px 0px -1px",
                                                                            opacity: 0.3,
                                                                            borderRadius: "0px 0px 0px 4px"
                                                                        }}>
                                                                        </Box>
                                                                        <Box style={{ border: "0.5px solid #979797", opacity: 0.3, width: 10, borderRadius: 4, height: 1, marginTop: 20, marginRight: 10 }}></Box>
                                                                        <Avatar src={this.state.data.avatar} style={{ width: 38, height: 38, marginRight: 10 }} />
                                                                    </Box>

                                                                    <Box style={{
                                                                        width: "100%",
                                                                        backgroundColor: "#f9f8fd",
                                                                        padding: "11px 20px 11px 20px",
                                                                        borderRadius: 8,
                                                                    }}>
                                                                        <Typography
                                                                            style={{
                                                                                textTransform: "capitalize",
                                                                                fontSize: "0.8rem",
                                                                                fontFamily: "Open Sans",
                                                                                fontWeight: 600,
                                                                                color: "#3f526d",
                                                                                backgroundColor: "rgb(249, 248, 253)",
                                                                            }}
                                                                        >
                                                                            {data.attributes.added_by.data.attributes.first_name} {data.attributes.added_by.data.attributes.last_name}
                                                                        </Typography>
                                                                        <Typography
                                                                            style={{
                                                                                textTransform: "none",
                                                                                fontSize: "0.8rem",
                                                                                fontFamily: "Open Sans",
                                                                                fontWeight: 600,
                                                                                color: "#3f526d",
                                                                                backgroundColor: "rgb(249, 248, 253)",
                                                                                opacity: 0.6,
                                                                            }}
                                                                        >
                                                                            {data.attributes.description}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        ))}
                                                    {/* nested Comment End */}

                                                </Box>
                                            </>
                                        )
                                    ))
                            }

                            <Box style={{
                                width: "100%",
                                borderRadius: 8,
                                backgroundColor: "#fff",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}>
                                <Avatar src={this.state.data.avatar} style={{ width: 64, height: 64, margin: "0px 4px 7px 0" }} />
                                <Box style={{ width: "100%", margin: "0px 20px 0px 0px" }}>
                                    {
                                        nestedComment
                                        &&
                                        <Box
                                            style={{
                                                display: "flex",
                                                flexDirection: 'row',
                                                justifyContent: "space-between",
                                                margin: nestedComment ? "14px 0 0px 20px" : "2px 0 0px 20px",
                                                backgroundColor: "rgb(249, 248, 253)",
                                                border: "solid 0.3px #471c51",
                                                opacity: 0.6,
                                                width: '100%',
                                                borderBottom: "none",
                                                borderRadius: "6px 6px 0px 0px",
                                                padding: "15px 20px",
                                                alignItems: "center",

                                            }}>

                                            <Typography style={{
                                                fontSize: "0.9rem",
                                                fontFamily: "Open Sans",
                                                fontWeight: 600,
                                                color: "#3f526d",
                                                backgroundColor: "rgb(249, 248, 253)",
                                                opacity: 0.6,
                                            }}>
                                                {commentDescription}
                                            </Typography>
                                            <CloseIcon style={{
                                                fontSize: 30,
                                                backgroundColor: "transparant",
                                                color: "#3f526d",
                                                cursor: "pointer",
                                            }}
                                                onClick={() => {
                                                    this.setState({ nestedComment: false, commentDescription: null, nestedcomment_id: null })
                                                }}
                                            />
                                        </Box>
                                    }
                                    <div style={{
                                        position: "relative",
                                        width: "100%",
                                        marginTop: !nestedComment ? "14px" : "0px"

                                    }}>
                                        <input
                                            style={{
                                                margin: "0px 0 20px 20px",
                                                padding: "15px 20px",
                                                width: "100%",
                                                borderRadius: nestedComment ? "0px 0px 6px 6px" : 6,
                                                backgroundColor: "rgb(249, 248, 253)",
                                                border: this.state.commentError ? "solid 0.3px red" : "solid 0.3px #471c51",
                                                opacity: 0.6,

                                            }}
                                            placeholder="Write a comment"
                                            value={inputStr}
                                            className="input_Text_Emoji"
                                            onChange={(e) => {
                                                console.log(e.target.value)
                                                if (e.target.value.length === 0) {
                                                    this.setState({ commentError: true })
                                                }
                                                else {
                                                    this.setState({ commentError: false })
                                                }
                                                this.setState({ inputStr: e.target.value })
                                            }}
                                        />
                                        {this.state.commentError && (
                                            <FormHelperText
                                                style={{ color: "red", margin: "3px 20px 0" }}
                                            >
                                                Comment/Reply can't be blank
                                            </FormHelperText>
                                        )}
                                        <img
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: 10,
                                                right: 0,
                                                width: 30,
                                                height: 30,
                                                opacity: 0.6,
                                            }}
                                            alt="true"
                                            src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                                            onClick={() => this.setState({ showPicker: !showPicker })}
                                        />

                                        {showPicker && (
                                            <Picker
                                                disableAutoFocus={true}

                                                pickerStyle={{ width: "100%", position: "relative", }}
                                                onEmojiClick={onEmojiClick}
                                            />
                                        )}
                                    </div>
                                </Box>
                            </Box>

                            <Box style={{ textAlign: "end" }}>
                                <Button
                                    style={{
                                        borderRadius: '6px',
                                        color: "#fff",
                                        fontFamily: "Open sans",
                                        textTransform: "capitalize",
                                        fontSize: "1.1rem",
                                        fontWeight: 600,
                                    }}
                                    className={this.props.classes.button_color}
                                    onClick={() => {
                                        if (inputStr.length === 0) {
                                            this.setState({ commentError: true })
                                        }
                                        else {
                                            if (nestedComment) {
                                                this.sendnestedComment()
                                            }
                                            else {
                                                this.sendComment()
                                            }
                                        }
                                    }
                                    }
                                >
                                    Comment
                                </Button>
                            </Box>
                        </div>
                    </Fade>
                </Modal>
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
    CommentModal as React.ComponentType<any>
)
// Customizable Area End