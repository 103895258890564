// Customizable Area Start
import React from "react";
import AssessmentLibraryController, { Props } from "./AssessmentLibraryController.web";
import '../TeacherAssessment.web.css';
import {
    Box,
    Grid,
    IconButton,
    InputBase,
    NativeSelect,
    Typography,
} from "@material-ui/core";
import { libraryPlayIcon } from "../assets";
import { ArrowDropDown } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import Spinner from "../../../shared/SideBar/src/Spinner";
import './QuestionBank.web.css';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import { ClassSelect } from "./AssessmentTab.style.web";
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme: any) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
    },
    input: {
        borderRadius: 6,
        position: "relative",
        backgroundColor: "#f9f8fd",
        border: '1px solid #f9f8fd',
        padding: "8px 26px 8px 12px",
        width: "390px",
        height: "42px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        fontSize: 22,
        fontWeight: 600,
        lineHeight: 1.2,
    },
}))(InputBase);
// Customizable Area End

export class QuestionBank extends AssessmentLibraryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End

    }

    // Customizable Area Start
    render() {
        const { teacherSubjects, showLoader, gradesList } = this.state;
        return (
            <>

                {showLoader && <Spinner spinnerModal={this.state.showLoader} />}
                <Grid className="QuestionBankMainGrid">
                    <Grid item xs={3} className={`ClassBox`}>
                        <Box>
                            <Typography
                                className={`ClassDropDownTitle ${this.props.classes.subHeading_font}`}
                                >
                                Class
                            </Typography>

                            <NativeSelect
                                id="select"
                                value={this.state.grade_id ? this.state.grade_id : ""}
                                input={<ClassSelect />}
                                IconComponent={ArrowDropDown}
                                inputProps={{
                                    className: "questionBankClass"
                                }}
                                data-test-id="question-bank-class-drop-down"
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.handleClassChange(event)}
                            >
                                <option value="" disabled>Select Class</option>
                                {
                                    gradesList.length != 0 && gradesList.map((grade: any) => (
                                        <option key={grade?.id} value={grade?.id} >{grade?.attributes?.name}</option>
                                    ))
                                }
                            </NativeSelect>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={`ListBox`}>
                        {
                            teacherSubjects?.length === 0
                                ?
                                <>
                                    <Typography 
                                        className={`NoRecordText ${this.props.classes.bodyText_font}`}
                                    >
                                        No record(s) found.
                                    </Typography>
                                </>
                                :
                                teacherSubjects?.map((data: any,index:number) => (
                                    <React.Fragment key={index}>
                                        <div className={`ListItem`}>
                                            <Grid
                                                container
                                                className="QuestionBankSubject"
                                            >
                                                <Grid item xs={12} sm={6} md={7} lg={9}>
                                                    <div
                                                        className={`SubjectName ${this.props.classes.subHeading_font}`}
                                                    >
                                                        {data?.subject_name}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={10} sm={4} md={3} lg={2}>
                                                    <div
                                                        className={`TotalQuestions ${this.props.classes.bodyText_font}`}
                                                        >
                                                        Total Questions: {data?.questions_count}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={1}>
                                                    <div
                                                        className="ImageDiv"
                                                        >
                                                        <IconButton
                                                            aria-label="more"
                                                            aria-controls="long-menu"
                                                            aria-haspopup="true"
                                                            className="IconButton"
                                                        >
                                                            <img src={libraryPlayIcon} 
                                                                className="Icon"
                                                                data-test-id="subject-redirect-image"
                                                                onClick={(event: any) => {
                                                                    this.handleShowQuestionBankList(event, data)
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </React.Fragment>
                                ))}
                    </Grid>
                </Grid>
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start

export default  withStyles(themeCreateStyle)(QuestionBank);

// Customizable Area End
