// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End


// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showLoader: boolean;
  queryTerm: any;
  startDate: any;
  endDate: any;
  gradebookData: any;
  studentData: any;
  subjectHeaders: any;
  assessmentList: any;
  dueDateList: any;
  gradeList: any;
  studentList: any;
  totalColumnLength: number;
  endDateError: string;
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  school_class_id?: string;
  subject_id?: string;
  // Customizable Area End
}
export default class AssessmentGradeBookController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiGetGradebookData: string = "";
  apiGetStudentData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      showLoader: false,
      queryTerm: "",
      startDate: moment(new Date()),
      endDate: moment(new Date()),
      gradebookData: [],
      studentData: [],
      subjectHeaders: [],
      assessmentList: [],
      dueDateList: [],
      gradeList: [],
      studentList: [],
      totalColumnLength: 0,
      endDateError: "",
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getStudentData();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps?.school_class_id !== this.props?.school_class_id || prevProps?.subject_id !== this.props?.subject_id) {
      this.getStudentData();
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      switch (apiRequestCallId) {
        case this.apiGetGradebookData:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({
                  gradebookData: responseJson
                }, () => {
                  this.generateTableData()
                })
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apiGetStudentData:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                this.setState({
                  studentData: responseJson.students
                }, () => {
                  this.getGradebookData()
                })
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkEndDate = () => {
    if (moment(this.state.endDate, "DD-MM-YYYY").isBefore(moment(this.state.startDate, "DD-MM-YYYY"))) {
      this.setState({ endDateError: "Start Date can't be greater than End Date." }, () => {
        toast.error(this.state.endDateError)
      })     
    } else {
      this.setState({ endDateError: "" })
    }
  }

  searchFilterClick = () => {
    if(this.state.endDateError) {
      toast.error(this.state.endDateError)
    } else if (moment(this.state.endDate, "DD-MM-YYYY").isBefore(moment(this.state.startDate, "DD-MM-YYYY"))) {
      this.setState({ endDateError: "Start Date can't be greater than End Date." }, () => {
        toast.error(this.state.endDateError)
      })     
    } else {
      this.getGradebookData(true)
    }
  }

  getGradebookData = (applyDateFilter?: boolean) => {
    this.setState({
      showLoader: true
    })
    const { startDate, endDate } = this.state;
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
      school: school_Data?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetGradebookData = requestMessage.messageId;
    let endPoint = configJSON.getAssessmentGradebookEndPoint + `?school_class_id=${this.props?.school_class_id}${this.props?.subject_id !== '' ? `&subject_id=${this.props?.subject_id}` : ''}`
    
    if(applyDateFilter) {
      endPoint = endPoint + `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStudentData = () => {
    this.setState({
      showLoader: true
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
      school: school_Data?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetStudentData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentListEndPoint + `?school_class_id=${this.props?.school_class_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  generateLightColor = () => {
    return "hsl(" + Math.random() * 360 + ", 100%, 90%)";
  };

  generateTableData = () => {
    // Initializing temp data arrays
    let tempSubjectList: any = [];
    let tempAssessmentList: any = [];
    let tempDueDateList: any = [];
    let tempGradeList: any = [];
    let tempStudentList: any = [];
    // Initializing temp data arrays END

    // Generating data into temp arrays
    let totalColumns:number = 0
    this.state.gradebookData.forEach((item: any) => {
      // Subject
      tempSubjectList.push({
        id: item.id,
        subject_name: item.subject_name,
        assessmentLength: item.assessments?.length
      });
      // Subject END

      // column length
      if (item.assessments?.length === 0) {
        totalColumns++;
      }else{
        totalColumns += item.assessments?.length;
      }
      // column length END


      item.assessments.forEach((assessment: any) => {
        // Assessment Title
        let color = this.generateLightColor()
        tempAssessmentList.push({
          name: assessment.name,
          type: assessment?.exam_type,
          background_color: color
        });
        // Assessment Title END

        // Due Date
        tempDueDateList.push({
          due_date: moment(assessment?.publish_date, "YYYY-MM-DDTHH:mm:ss").format("D MMM")
        });
        // Due Date END

        // Grade Data
        tempGradeList.push({
          grade: assessment.grade
        });
        // Grade Data END

        // Student Data
        this.state.studentData.map((student: any) => {
          const tempStudent = assessment.students.find((data: any) => data.student_id === student.id);
          if (tempStudent) {
            const sIndex = tempStudentList.findIndex((data: any) => data.student_id === student.id);
            if (sIndex != -1) {
              tempStudentList[sIndex].grades.push(tempStudent.percentage);
            } else {
              tempStudentList.push({
                student_id: tempStudent.student_id,
                student_name: tempStudent.student_name,
                grades: [
                  tempStudent.percentage
                ]
              });
            }
          } else {
            const sIndex = tempStudentList.findIndex((data: any) => data.student_id === student.id);
            if (sIndex != -1) {
              tempStudentList[sIndex].grades.push('-');
            } else {
              tempStudentList.push({
                student_id: student.id,
                student_name: `${student.attributes.first_name} ${student.attributes.last_name}`,
                grades: ['-']
              });
            }
          }
        });
        // Student Data END
      });

    });
    // Generating data into temp arrays END

    if(tempGradeList.length < totalColumns){
      const length = totalColumns - tempGradeList.length;
      for(let i = 0; i < length; i++){
        tempAssessmentList.push({
          name: 'No',
          type: 'Data',
          background_color: this.generateLightColor()
        });
        tempDueDateList.push({
          due_date: '-'
        });
        tempGradeList.push({
          grade: '-'
        });

        tempStudentList.forEach((element:any) => {
          element.grades.push('-')
        });
      }
    }

    // Setting Data
    this.setState({
      subjectHeaders: tempSubjectList,
      assessmentList: tempAssessmentList,
      dueDateList: tempDueDateList,
      gradeList: tempGradeList,
      studentList: tempStudentList,
      showLoader: false,
    });
    // Setting Data END
  }
  // Customizable Area End
}
