// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const SchoolPeriodIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
             <path d="M22.1 0C24.238 0 26 1.762 26 3.9v18.2c0 2.138-1.762 3.9-3.9 3.9h-5.74a1.3 1.3 0 1 1 0-2.6h5.74c.743 0 1.3-.557 1.3-1.3V3.9c0-.743-.557-1.3-1.3-1.3H3.9c-.743 0-1.3.557-1.3 1.3v5.738a1.3 1.3 0 1 1-2.6 0V3.9C0 1.762 1.762 0 3.9 0h18.2zm-1.304 3.897.028.001.067.002.027.003.026.002.05.006-.103-.011a1.292 1.292 0 0 1 .83.378 1.3 1.3 0 0 1 .38.924v5.22a1.3 1.3 0 0 1-2.6 0L19.5 8.34l-6.882 6.883c.247.509.386 1.079.386 1.678V22.1c0 2.138-1.768 3.896-3.906 3.896H3.897A3.914 3.914 0 0 1 0 22.101V16.9a3.914 3.914 0 0 1 3.896-3.896h5.201c.596 0 1.164.137 1.672.38l6.895-6.887h-2.06a1.3 1.3 0 0 1-.811-.28l-.11-.097a1.3 1.3 0 0 1 .92-2.223h5.192zM9.098 15.605H3.897c-.743 0-1.296.552-1.296 1.295v5.201c0 .743.553 1.296 1.296 1.296h5.2c.744 0 1.306-.553 1.306-1.296v-5.2c0-.332-.112-.625-.301-.849l-.083-.074a1.301 1.301 0 0 1-.066-.07 1.302 1.302 0 0 0-.855-.303z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default SchoolPeriodIcon;
// Customizable Area End