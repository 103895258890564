// Customizable Area Start
import { ITimeTableEventsProps } from "../TeacherTimeTableController.web";
import React, { useState } from "react";
import {
  image_info,
  image_lunch_time,
  information,
  video_camera_live,
  blackBoard
} from "../assets";
import {
  Typography,
} from "@material-ui/core"
import moment from "moment";
import EventToolTip from "./EventToolTip.web";
import { I_Support } from "../../assets";
import './WeekEvent.web.css';
// Customizable Area End

// Customizable Area Start
interface IWeekEvent {
  event: ITimeTableEventsProps;
  isTeacher: boolean;
  getTimeTableUpdatedData?: any
  joinMeeting: () => void;
  updateEvents?: (bool: boolean) => any
}
// Customizable Area End



// Customizable Area Start

const WeekEvent: React.FC<IWeekEvent> = ({ event, isTeacher, getTimeTableUpdatedData, joinMeeting, updateEvents }: IWeekEvent) => {
  const { title, start, end } = event as ITimeTableEventsProps;
  const duration = moment.duration(moment(event.end).diff(moment(event.start)));
  const hours = duration.asHours();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleClose = (save: boolean) => {
    setAnchorEl(null);
  };
  const savehandleClose = () => {
    setAnchorEl(null);
    getTimeTableUpdatedData()

  };
  const handleModalClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      {event.type === "lunchTime" ? (
        <div
          className="WeekEventLunchTimeDiv week-event"
        >
          <img src={image_lunch_time} className='WeekEventLunchTimeImg' />
          <div
            className="WeekEventLunchTimeTxt"
          >
            {title}
          </div>
        </div>
      ) : (
        <div className="WeekEventPeriodMainDiv week-event">

          {event.isEvent && <img src={I_Support} className='WeekEventPeriodMainDivImg' />}

          <img
            style={event.isEvent ? { marginLeft: 10 } : { color: event.borderColor }}
            className='WeekEventPeriodMainImg'
            src={event.is_online ? video_camera_live : blackBoard} />
          <div style={hours < 1 ? { display: "flex", justifyContent: "space-between" } : {}}>
            <div
              style={{
                marginTop: hours >= 1 ? '4px' : "0px",
                color: isTeacher
                  ? "#3f526d"
                  : "#3f526d",
              }}
              className='WeekEventPeriodMainTxt'
            >
              {title}
            </div>
            {hours >= 1 && <div
              style={{
                color: isTeacher
                  ? "#3f526d"
                  : "#3f526d",
                display: event?.isEvent ? "block" : "none"
              }}
              className='WeekEventPeriodMainTxt'
            >
              {event.description && `${event.description}.`}
            </div>}
            <div
              style={{
                position: hours >= 1 ? isTeacher ? "absolute" : "absolute" : 'relative',
                width: hours >= 1 ? isTeacher ? "95%" : "95%" : '',
              }}
              className='WeekEventDateDiv'
            >
              {hours >= 1 && <Typography
                style={{
                  color: isTeacher ? "#000" : "#3f526d",
                }}
                className='WeekEventDateTxt'
              >
                {moment(start).format(isTeacher ? "HH:mm A" : "HH:mm A")}-
                {moment(end).format(isTeacher ? "HH:mm A" : "HH:mm A")}
              </Typography>}
              <img
                className='WeekEventDateImg'
                src={isTeacher ? information : image_info}
                onClick={(event: any) => handleModalClick(event)}
              />
            </div>
          </div>
          <EventToolTip
            event={event}
            anchorEl={anchorEl}
            updateEvents={updateEvents}
            handleClose={handleClose}
            savehandleClose={savehandleClose}
            isTeacher={isTeacher}
            joinMeeting={joinMeeting}
          />
        </div>
      )}
    </>
  );
};
// Customizable Area End

// Customizable Area Start
export default WeekEvent;
// Customizable Area End
