// Customizable Area Start
import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      borderImageSource: "linear-gradient(84deg, #b6a5fe -134%, #7f6cfc 100%, #7f6cfc 100%)",
    },
  },
  completed: {
    "& $line": {
      borderImageSource: "linear-gradient(84deg, #b6a5fe -134%, #7f6cfc 100%, #7f6cfc 100%)",
    },
  },
  line: {
    width: "auto",
    borderStyle: "solid",
    borderImageSlice: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: "46px",
    height: "46px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Open sans"
  },
  active: {
    backgroundImage: "linear-gradient(322deg, #b6a5fe 0%, #7f6cfc 118%)",
  },
  completed: {
    backgroundImage: " linear-gradient(322deg, #b6a5fe 0%, #7f6cfc 118%)",
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons: { [index: string]: React.ReactElement } = {
    1: <span>1</span>,
    2: <span>2</span>,
    3: <span>3</span>,
    4: <StarOutlineIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);
// Customizable Area End

export function Steppers(props: any) {
  // Customizable Area Start
  const classes = useStyles();
  const steps = [1, 2, 3, 4];
  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={props.activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon} />
          </Step>
        ))}
      </Stepper>
    </div>
  );
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(Steppers);
// Customizable Area End