// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { HISTORY } from "../../../components/src/comman";
// Customizable Area End


// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherAssessmentModuleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  createGuidelineUrl = () => {
    localStorage.removeItem("customGuidelineList");
    localStorage.removeItem("assessmentId");
    localStorage.removeItem("assessmentData");
    HISTORY.push("/TeacherAssessmentCreateDetail");
  };
  libraryUrl = () => {
    HISTORY.push("/AssessmentQuestionBankLibrary");
  };
  newTestUrl = () => {
    HISTORY.push("/AssessmentUpload");
  };

  
  handleBack = () => {
    HISTORY.goBack();
  };
  // Customizable Area End
}
