// Customizable Area Start
import React from "react";
import {
    Button,
    Typography,
    Tabs,
    Tab,
    Grid,
} from "@material-ui/core";
import ReportCardDetailsController from "./ReportCardDetailsController.web";
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import '../AdminReportCard/AdminReportCard.web.css';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import StudentProfileDetails from "./StudentProfileDetails.web";
import { saveIcon } from "../assets";
import GradingDetails from "./GradingDetails.web";
import ScholasticAreas from "./ScholasticAreas.web";
import CoScholasticAreas from "./CoScholasticAreas.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class ReportCardDetails extends ReportCardDetailsController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { tabsList, selectedIndex, selectedTab, studentData, scholasticDetails, coScholasticDetails } = this.state;
        return (
            <>
                {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
                <div>
                    <Grid container spacing={3} className="xs-mb-15">
                        <Grid item lg={9} md={8} sm={7} xs={12}>
                            <Typography className={`student-name-with-back ${this.props.classes.heading_font}`}>
                                <div className="teacher-back-arrow" onClick={() => this.handleBack()} data-test-id="report-card-detail-back"><KeyboardBackspaceOutlinedIcon /></div>
                                {studentData?.data?.attributes?.first_name} {studentData?.data?.attributes?.last_name}
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={4} sm={5} xs={12} className="save-changes-div">
                            {selectedTab !== "gradingDetails" &&
                                <Button className={`${this.props.classes.button_color} save-changes-btn`}
                                    onClick={this.saveChanges}
                                    disabled={(!scholasticDetails && !coScholasticDetails) || selectedIndex === 0}>
                                    Save Changes <img src={saveIcon}></img>
                                </Button>
                            }
                        </Grid>
                    </Grid>
                    <div>
                        <Tabs
                            value={selectedIndex}
                            textColor="secondary"
                            aria-label="secondary tabs example"
                            variant="scrollable"
                            scrollButtons="auto"
                            className={`${this.props.classes.ReportCardDetails_Feedback_reportCardTabs} report-card-tabs`}
                        >
                            {tabsList?.map((item: any, i: number) => (
                                <Tab key={i} label={
                                    <Button
                                        key={item.key}
                                        onClick={() => {
                                            this.handleSelectTab(item.value, item.key);
                                        }}
                                        className={item.key === selectedIndex ? `ReportDetailBtn ${this.props.classes.button_color} teacher-report-tab` : `ReportDetailBtn ${this.props.classes.button_color_disabled} teacher-report-tab`}
                                    >
                                        <Typography
                                            className={item.key !== selectedIndex ? `ReportDetailBtnText ${this.props.classes.heading_font}` : `ReportDetailSelectedBtnText ${this.props.classes.heading_font}`}
                                        >
                                            {item.label}
                                        </Typography>
                                    </Button>
                                }>
                                </Tab>
                            ))}
                        </Tabs>
                    </div>

                    {selectedTab === "profileDetails" &&
                        <StudentProfileDetails />}

                    {selectedTab === "scholastic" &&
                        <ScholasticAreas setScholasticDetails={this.setScholasticDetails} moduleName={tabsList[1].label} />}

                    {selectedTab === "coScholastic" &&
                        <CoScholasticAreas setCoScholasticDetails={this.setCoScholasticDetails} />}

                    {selectedTab === "gradingDetails" &&
                        <GradingDetails />}

                </div>

            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(ReportCardDetails as React.ComponentType<any>, "TeacherAccount"));
// Customizable Area End