
// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { nounCheckSVG, nounCloseSVG } from "../assets";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
interface Props {
    // Customizable Area Start
    questionData: any;
    isTeacher: boolean;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
export const FlashCardQuestionType = ({
    questionData,
    isTeacher,
    classes,
}: Props) => {
    const [correctAnswer, setCorrectAnswer] = useState<string>("");
    const [flashCardsList, setFlashCardsList] = useState<any>([]);

    useEffect(() => {
        let flashCards: any = [];
        const flashCardAnswers = questionData?.selected_options?.data?.attributes?.flash_card_answer
        questionData?.answers?.map((data: any) => {
            const attemptedAnswerItem = flashCardAnswers?.find((item: any) => Number(item.ans_id) === Number(data.id))
            flashCards.push({
                id: data?.id,
                name: data?.attachments?.data[0]?.attributes?.file_name,
                flash_card_image: data?.attachments?.data[0]?.attributes?.url,
                flash_card_hint: data?.hint,
                flash_card_answer: data?.answer,
                attempted_answer_value: attemptedAnswerItem?.answer,
            });
            setFlashCardsList(flashCards)
        }
        );
        const correctAnswerString = flashCards?.map((item: any) => item.flash_card_answer).join(', ');
        setCorrectAnswer(correctAnswerString)
    }, [])

    return (
        <Grid container spacing={4}>
            {flashCardsList && flashCardsList?.map((flashCardItem: any, index: number) => (
                <Grid item xs={12} sm={6} className="justify-center" key={index}>
                    <div className="flash-card">
                        <div className="flash-card-image-block">
                            <img src={flashCardItem?.flash_card_image}
                                className="flash-card-image" />
                        </div>

                        <p className={`${classes.bodyText_font} flash-card-hint-text`}>{flashCardItem?.flash_card_hint}</p>

                        <div className="flash-card-answer-field">
                            <div className={`${classes.heading_font} ${flashCardItem.flash_card_answer === flashCardItem.attempted_answer_value ? `flash-card-correct-field` : `flash-card-wrong-field`}`}>
                                <span className={`${classes.bodyText_font} flash-card-attempted-text`}>
                                    {flashCardItem.attempted_answer_value}
                                </span>
                                {flashCardItem.flash_card_answer === flashCardItem.attempted_answer_value ?
                                    <img src={nounCheckSVG} alt="check" className="right-check-icon absolute-icon" />
                                    :
                                    <img src={nounCloseSVG} alt="close" className="close-red-icon absolute-icon" />}
                            </div>
                        </div>

                        <div className={`${classes.bodyText_font} flash-card-count`}>{index + 1}/{Number(flashCardsList.length)}</div>
                    </div>
                </Grid>
            ))}

            <div className="correct_answer_section">
                <Grid container>
                    <Grid item xs={12} sm={6} md={8} className="correct_answer_left">
                        {!questionData?.selected_options?.data &&
                            <Typography className={`not_attemped_text ${classes.bodyText_font}`}>Not attempted</Typography>
                        }
                        <Typography className={`correct_answer_text ${classes.heading_font}`}>Correct Answer</Typography>
                        <TextField
                            id="standard-basic"
                            placeholder=""
                            className="evaluation-rating"
                            variant="outlined"
                            value={correctAnswer}
                            disabled
                            inputProps={{
                                className: `rating-text-field-evaluation ${classes.heading_font}`,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className="score_section">
                        <Typography className={`correct_answer_text ${classes.heading_font}`}>Score</Typography>
                        <div className="correct_answer_rectangle">
                            <Typography className="score_text">
                                {questionData?.selected_options?.data?.attributes?.score ? questionData?.selected_options?.data?.attributes?.score : 0}
                            </Typography>
                        </div>
                        <Typography className="score_text"> / {questionData?.marks}</Typography>
                    </Grid>
                    {(!isTeacher && questionData?.selected_options?.data?.attributes?.comments) &&
                        <Grid item xs={12}>
                            <Typography className={`title_teachercomment ${classes.bodyText_font}`}>Teacher Comment:
                                <span className={`textlabel_teachercomment ${classes.heading_font}`} dangerouslySetInnerHTML={{
                                    __html: questionData?.selected_options?.data?.attributes?.comments,
                                }}></span></Typography>
                        </Grid>
                    }
                </Grid>
            </div>
        </Grid>
    );
};
// Customizable Area End
// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(FlashCardQuestionType);
// Customizable Area End