// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import convert from "xml-js";
import { toBase64 } from "../../../AssessmentTest/src/utils/common";
import { generateKey } from "../utils/common";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  submitModal?: boolean;
  closeModalFun?: any;
  task_id?: any;
  classes: any;
  studentTask?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  closeModal: boolean;
  fileName: string;
  startdateerror: boolean;
  enddateerror: boolean;
  enddate: string;
  alertMessage: any;
  alertModal: boolean;
  fileUploadDetail: any;
  showSpinner: boolean;
  FileUploadModal: boolean;
  bulkFileUploaded: Array<any>;
  previewImageModal: boolean;
  selectedPreviewImageSrc: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudentTaskUploadController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apisubmitCourseworkApiId: string = "";
  postPresignID: any = [];
  apiUploadBlockId: any = [];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      closeModal: false,
      fileName: "",
      enddate: "",
      startdateerror: false,
      enddateerror: false,
      alertMessage: "",
      alertModal: false,
      fileUploadDetail: null,
      showSpinner: false,
      FileUploadModal: false,
      bulkFileUploaded: [],
      previewImageModal: false,
      selectedPreviewImageSrc: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }
  // Customizable Area End


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apisubmitCourseworkApiId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.props.closeModalFun(this.state.closeModal);
            this.setState({
              bulkFileUploaded: [],
              showSpinner: true
            })
            toast.success("Task submitted successfully.");
            this.postPresignID = [];
            this.apiUploadBlockId = [];
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({
          showSpinner: false
        })
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (this.postPresignID.indexOf(apiRequestCallId) !== -1) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const indexOfUploadDoc = this.postPresignID.indexOf(apiRequestCallId)
            const imageData: any = this.state.bulkFileUploaded[indexOfUploadDoc].file;
            const updatedBulkDocument = [...this.state.bulkFileUploaded];
            const msg: Message = new Message(
              getName(MessageEnum.UploadMediaMessage)
            );
            updatedBulkDocument[indexOfUploadDoc].messageId = msg.messageId;
            this.setState({ bulkFileUploaded: updatedBulkDocument })
            const uploadFileData: any = {
              responseJson: responseJson,
              imageData,
              messageId: msg.messageId,
            };
            msg.addData(
              getName(MessageEnum.UploadMediaDataMessage),
              uploadFileData
            );
            this.apiUploadBlockId[this.apiUploadBlockId.length] = msg.messageId;
            runEngine.sendMessage(msg.id, msg);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (this.apiUploadBlockId.indexOf(apiRequestCallId) !== -1) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const keyValue = JSON.parse(
              convert.xml2json(responseJson, {
                spaces: 1,
                compact: true,
                ignoreComment: true,
                alwaysChildren: true,
                ignoreDeclaration: true,
              })
            );
            const { bulkFileUploaded } = this.state;
            const updatedDocIndex = this.state.bulkFileUploaded.findIndex((item: any) => item.messageId === apiRequestCallId)
            const updated = {
              ...bulkFileUploaded[updatedDocIndex],
              signedInId: keyValue.PostResponse.Key._text,
            };
            bulkFileUploaded[updatedDocIndex] = updated;
            this.setState({
              bulkFileUploaded: [...bulkFileUploaded],
              showSpinner: false
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  bulkUploadDocumentHandleDelete = (itemId: string) => {
    const { bulkFileUploaded } = this.state;
    const index = bulkFileUploaded.findIndex((item: any) => item.id === itemId);
    bulkFileUploaded.splice(index, 1);
    this.apiUploadBlockId.splice(index, 1)
    this.postPresignID.splice(index, 1)
    this.setState({ bulkFileUploaded: bulkFileUploaded });
    if (bulkFileUploaded?.length === 0) {
      this.setState({
        bulkFileUploaded: [],
        showSpinner: false,
      });
    }
  };

  modalhandleClose = () => {
    this.setState({ closeModal: true });
    this.props.closeModalFun(this.state.closeModal);
  };
  getPreSignedId = (file: any) => {
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      token: localStorage.getItem("token"),
      school: data.school_id,
    };
    let formData = new FormData();
    formData.append("content_type", file.content_type);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postPresignID[this.postPresignID.length] = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fileUploadS3
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.SignIDUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleUpload = async (files: any) => {
    let { bulkFileUploaded } = this.state;
    let allowUpload: boolean = true;
    const validFileType = [
      "image/png",
      "image/jpg",
      "image/jpeg",

    ];
    const keys = Object.keys(files);
    let totalSize: number = 0;

    keys?.map((key: any) => {
      const file = files[key];
      totalSize = totalSize + file.size;

      if (!validFileType.includes(file?.type)) {
        this.setState({
          alertMessage: "Please upload valid file format: .png, .jpg, .jpeg",
          alertModal: true,
        });
        allowUpload = false;
      }
    });

    if (totalSize > 524288000) {
      allowUpload = false;
      this.setState({
        alertMessage: "Maximum files size: 500MB",
        alertModal: true,
      });
    }

    if (allowUpload) {
      this.setState({ showSpinner: true });

      keys?.map(async (key: any) => {
        const file = files[key];
        const FileSrc = await toBase64(file)

        let fileUpload: any = {};
        fileUpload = {
          id: generateKey(file.name),
          fileName: file.name,
          name: file.name.split(".")[0],
          isExistingFile: false,
          fileSize: file.size,
          percentage: 0,
          file: file,
          content_type: file.type,
          src: FileSrc
        };
        bulkFileUploaded = [...bulkFileUploaded, fileUpload];
        this.setState({ fileUploadDetail: fileUpload, bulkFileUploaded: bulkFileUploaded, FileUploadModal: true, showSpinner: true }, () => {
          this.getPreSignedId(fileUpload)
        })
      })
    }
  };
  submitCourseWork = () => {
    this.setState({
      showSpinner: true
    })
    const school_data = localStorage.getItem("user_data");
    const Schooldata = JSON.parse(school_data || "{}");
    const { bulkFileUploaded } = this.state;
    let taskMemberId = this.props.studentTask?.task_members?.data?.id;
    let files_array: any = [];
    bulkFileUploaded?.map((item: any) => {
      files_array.push(item.signedInId)
    })

    const data = {
      task_members_attributes: [
        {
          id: taskMemberId,
          files: files_array,
          comment: `Added Attachment12-${(new Date()).getTime()}`
        }
      ]
    };

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      school: Schooldata.school_id,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apisubmitCourseworkApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadTaskApiEndPoint + `${this.props.task_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.EditMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
