// Customizable Area Start
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField } from '@material-ui/core';
import CoScholasticAreasController, { Props } from './CoScholasticAreasController.web';
import Spinner from '../../../shared/SideBar/src/Spinner';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

export class CoScholasticAreas extends CoScholasticAreasController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { coScholasticListData, isLoader } = this.state

        return (
            <>
                {isLoader && <Spinner spinnerModal={isLoader} />}
                <div className="CoScholasticDiv">
                    {coScholasticListData?.attributes?.report_topics?.map((topicData: any) => (
                        <React.Fragment key={topicData.topic_id}>
                            <Accordion className='accordianPart'>
                                <AccordionSummary className='accordianPartsummary'
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={`${this.props.classes.subHeading_font} co-scholastic-topic`}>{topicData?.topic_name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className='tableContentarea'>
                                    <TableContainer>
                                        <Table className='tableRow'>
                                            <TableHead className={this.props.classes.ReportCardDetails_Feedback_background_color}>
                                                <TableRow>
                                                    <TableCell className={`${this.props.classes.ReportCardDetails_Feedback_tableRowCell}`} align="center">
                                                        <span className={`table-field-style`}>
                                                            {topicData?.topic_name}
                                                        </span>
                                                    </TableCell>
                                                    {coScholasticListData?.attributes?.terms?.map((termData: any, ind: number) => (
                                                        <TableCell key={ind} className={`${this.props.classes.ReportCardDetails_Feedback_tableRowCell}`} align="center">
                                                            <span className={`table-field-style`}>
                                                                {termData?.title}
                                                            </span>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {topicData?.report_attributes?.map((data: any) => (
                                                        <TableRow key={data.id}>
                                                            <TableCell className={`CoScholasticCell ${this.props.classes.tableRow}`}>
                                                                <span className={`${this.props.classes.heading_font} category-name-header`}>{data.name}</span>
                                                            </TableCell>
                                                            {data?.marks?.map((marksData: any, index: any) => (
                                                                <TableCell key={index} className={this.props.classes.border_Cell} style={{ borderRadius: coScholasticListData.attributes.terms.length === index + 1 ? "0px" : '10px 0px 0px 0px' }}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="number"
                                                                        placeholder='Marks'
                                                                        value={marksData?.marks}
                                                                        className="no-underline"
                                                                        inputProps={{
                                                                            className: `${this.props.classes.heading_font} table-field-style`
                                                                        }}
                                                                        onKeyPress={(e: any) => this.onKeyPressMarks(e)}
                                                                        onChange={(e: any) => this.marksOnChange(e, marksData, Number(data?.id))}
                                                                        data-test-id={`co-scholastic-text-field-${index}-${marksData?.term_id}-${data?.id}`}
                                                                    />
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>                                           
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer >
                                </AccordionDetails>
                            </Accordion>
                        </React.Fragment>
                    ))}
                </div>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(CoScholasticAreas as React.ComponentType<any>);
// Customizable Area End