// Customizable Area Start
import { IBlock } from '../../../../../framework/src/IBlock';
import { Message } from '../../../../../framework/src/Message';
import { BlockComponent } from '../../../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../../framework/src/RunEngine';
import { encryptData } from '../../../../ClassesAndSections/src/utils/common';
import moment from 'moment';

export const configJSON = require('../../config.js');
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  editModalFun: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  data: any;
  loader:boolean;
  reminders: any;
  mainReminder: null | any;
  mainReminderOpen: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ReminderQuotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetTeacherClassesId: string = '';
  apigetReminderId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      data: '',
      loader:false,
      reminders: [],
      mainReminder: null,
      mainReminderOpen: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.get_userdata = this.get_userdata.bind(this);
    this.get_reminder=this.get_reminder.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const zoomMeetingBlock = document.getElementById('zmmtg-root');
    if (zoomMeetingBlock) zoomMeetingBlock.style.display = 'none';
    this.get_userdata();
    this.get_reminder();
    // Customizable Area End
  }

  // Customizable Area Start
  get_userdata = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}');
    this.setState({ data: data });
  };

  get_reminder = () => {
    const token = localStorage.getItem('token');
    const school_data = localStorage.getItem('user_data');
    const data = JSON.parse(school_data || '{}');
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      token,
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({loader:true})
    this.apigetReminderId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReminderEndPoint + `?school_id=${data.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.InstituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  modalhandleClose = () => {
    this.setState({ mainReminderOpen: false });
  };

  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apigetTeacherClassesId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              // totalClasses: responseJson.total_count,             
            });
            
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }else if (apiRequestCallId === this.apigetReminderId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              // response_data: responseJson.data,
              reminders: responseJson.data,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({loader:false})
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End
}
