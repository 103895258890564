// Customizable Area Start
import React, { useRef, useCallback, useState, useEffect } from "react";
import Webcam from "react-webcam";
import { Box, Button } from "@material-ui/core";
import moment from "moment";
import {
  video_recording_btn_icon,
} from "../../../profiles/src/assets";
import {
  getFileSrc,
  convertBlobToFile,
} from "../../../profiles/src/utils/common";
import SaveVideoModal from "./SaveVideoModal.web";
import { saveRecordingButton, recordButtonSVG } from "../assets";
// Customizable Area End

// Customizable Area Start
interface IWebcam {
  onClose?: () => void;
  onBack?: () => void;
  loadingSpinner?: boolean;
  fileName?: string;
  isOpenSaveVideoModal: boolean;
  MaxVideoRecordingTime: number;
  fileNameError: boolean;
  fileNameErrorMsg: string;
  onOpenSaveVideoModal: () => void;
  onCloseSaveModal: () => void;
  onChangeFileName: (e: any) => void;
  onBlurFileName: (e: any) => void;
  saveVideoClick: (videoFile: any, videoSrc: any, recorderTime: string) => void;
}

const loader = {
  color: "blue",
  height: "30px",
  width: "30px",
  margin: "auto",
} as const;

const timerValue = (val: number) => {
  let valString = val + "";
  if (valString.length < 2) {
    return "0" + valString;
  } else {
    return valString;
  }
};
declare var MediaRecorder: any;
// Customizable Area End

export const RecordVideoWebCam: React.FC<IWebcam> = ({
  // Customizable Area Start
  // onFileSave,
  onClose,
  onBack,
  loadingSpinner,
  fileName,
  fileNameError,
  fileNameErrorMsg,
  MaxVideoRecordingTime,
  onOpenSaveVideoModal,
  onCloseSaveModal,
  onChangeFileName,
  onBlurFileName,
  isOpenSaveVideoModal,
  saveVideoClick,
  // Customizable Area End
}) => {
  // Customizable Area Start
  const webcamRef: React.MutableRefObject<any> = useRef();
  const mediaRecorderRef: React.MutableRefObject<any> = useRef();
  const [capturing, setCapturing] = useState(false);
  const [canRecordVideoViaWebcam, setCanRecordVideoViaWebcam] = useState(false);
  const [isMaxRecordingTimeUp, setIsMaxRecordingTimeUp] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [recorderTime, setRecorderTime] = useState("00:00:00");
  const [isCameraPermissionDenied, setIsCameraPermissionDenied] = useState(
    false
  );
  let totalSeconds: number = 0;
  const momentDurationFormatSetup = require("moment-duration-format");
  momentDurationFormatSetup(moment);
  const timerForRecorder = useCallback(() => {
    ++totalSeconds;
    const duration: any = moment.duration(totalSeconds, "seconds");
    const formatted = duration.format("hh:mm:ss", { trim: false });
    if (totalSeconds > MaxVideoRecordingTime) setIsMaxRecordingTimeUp(true);
    setRecorderTime(formatted);
  }, [totalSeconds]);

  useEffect(() => {
    let timer: any;
    if (!isMaxRecordingTimeUp && capturing) {
      timer = setInterval(() => {
        timerForRecorder();
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isMaxRecordingTimeUp, capturing]);

  useEffect(() => {
    if (isMaxRecordingTimeUp) handleStopCaptureClick();
  }, [isMaxRecordingTimeUp]);

  const handleResetTimer = () => {
    setRecorderTime("00:00:00");
    totalSeconds = 0;
  };

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data));
    }
  }, []);

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    setIsMaxRecordingTimeUp(false);
    let options;
    if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
      options = {
        mimeType: "video/webm; codecs=vp9",
      };
    }
    mediaRecorderRef.current = new MediaRecorder(
      webcamRef.current.stream,
      options
    );
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setCanRecordVideoViaWebcam(false);
    onOpenSaveVideoModal();
  }, [mediaRecorderRef]);

  const handleCancelSaveVideo = useCallback(() => {
    setRecordedChunks([]);
    handleResetTimer();
    onCloseSaveModal();
  }, []);

  const handleSave = useCallback(() => {
    if (recordedChunks.length) {
      setCapturing(false);
      const videoChunk: any = recordedChunks[0];
      const videoFile: any = convertBlobToFile(videoChunk, fileName); // blob
      const videoSrc: string = getFileSrc(videoFile);
      saveVideoClick(videoFile, videoSrc, recorderTime);
      setRecordedChunks([]);
      handleResetTimer();
    }
  }, [recordedChunks, fileName, recorderTime]);

  const handleCancel = useCallback(() => {
    setCapturing(false);
    handleResetTimer();
    setRecordedChunks([]);
  }, []);

  const handleRecordButtonClick = () => {
    setCanRecordVideoViaWebcam(true);
  };

  const handleError = useCallback(
    (e) => {
      setIsCameraPermissionDenied(true);
      handleCancel();
    },
    [handleCancel]
  );

  return (
    <Box>
      {isCameraPermissionDenied && (
        <div className="allow_camera_access_text">
          Please allow 21KSchoolPhase1 to access camera and microphone in order
          to start recording video!
        </div>
      )}
      <div>
        {!isCameraPermissionDenied && (
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              className="video_web_cam_box"
            >
              <Webcam
                audioConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                  autoGainControl: false,
                }}
                ref={webcamRef}
                onUserMediaError={handleError}
                onUserMedia={handleRecordButtonClick}
                className="video_webcam_style_assessment"
              />

              <>
                {!isCameraPermissionDenied && capturing && (
                  <div className="recording-time-div">
                    <span className="record_red_dot"></span>
                    <span className="recording-time-text">{recorderTime}</span>
                  </div>
                )}
                {!isCameraPermissionDenied && (
                  <div className="capture_video_div">
                    <Button
                      onClick={(e: any) => {
                        if (capturing) {
                          e.preventDefault;
                        } else {
                        }
                        handleStartCaptureClick();
                      }}
                      disableRipple={capturing}
                      className={!capturing ? `cursor_pointer background_button_no_hover` : `cursor_default background_button_no_hover`}
                    >
                      <img
                        className="assessment_video_recording_icon"
                        src={
                          !capturing ? video_recording_btn_icon : recordButtonSVG
                        }
                      ></img>
                    </Button>
                    {capturing && (
                      <Button
                        onClick={handleStopCaptureClick}
                        className="cursor_pointer"
                      >
                        <img
                          className="assessment_video_pause_icon"
                          src={saveRecordingButton}
                        ></img>
                      </Button>
                    )}
                  </div>
                )}
              </>
            </Box>
          </Box>
        )}
      </div>
      <SaveVideoModal
        openSaveVideoModal={isOpenSaveVideoModal}
        onCloseModal={handleCancelSaveVideo}
        fileName={fileName}
        onChangeFileName={onChangeFileName}
        onBlurFileName={onBlurFileName}
        fileNameError={fileNameError}
        fileNameErrorMsg={fileNameErrorMsg}
        saveVideoClick={handleSave}
      />
    </Box>
  );
  // Customizable Area End
};

// Customizable Area Start
export default RecordVideoWebCam;
// Customizable Area End
