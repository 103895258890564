//Customizable Area Start
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Bar } from 'react-chartjs-2';
const configJSON = require('../config.js');

//Customizable Area End

//Customizable Area Start
interface IProps {
  classes: any;
  assessmentData: any;
  barData: any;
  id:string;
}
//Customizable Area End

export const AsssessmentStandardsReport: React.FC<IProps> = (props) => {
  //Customizable Area Start
  const { classes, assessmentData, barData,id } = props;
  //Customizable Area End
  return (
    <Box marginTop={3} id={id}>
      <Typography variant="h6" className={classes.subTitle_font}>
        {configJSON.ASSESSMENT_STUDENTS.PERFORMANCE_BY_STANDARDS}:{' '}
        {assessmentData?.assessment_name}
      </Typography>
      <Box marginTop={3}>
        <Bar
          {...barData}
          plugins={[ChartDataLabels]}
          className={classes.AssessmentStandardsReport_TeacherReports_graph_height}
        />
      </Box>
      <Box marginTop={3}>
        <Typography variant="h6" className={classes.subTitle_font}>
          {configJSON.ASSESSMENT_STUDENTS.STANDARD_PERFORMANCE}:{' '}
          {assessmentData?.assessment_name}
        </Typography>
      </Box>
      <Box marginTop={3}>
        <TableContainer
          style={{
            borderRadius: 6,
            border: 0,
            padding: 5,
          }}
        >
          <Table className="separte-border-collapse">
            <TableHead
              style={{
                backgroundColor: '#f9f8fd',
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    padding: '17px 5px 17px 5px',
                    fontSize: 20,
                    fontWeight: 600,
                    borderBottom: 0,
                  }}
                  className={classes.subHeading_font}
                  align="center"
                >
                  {configJSON.ASSESSMENT_STUDENTS.STUDENTS}
                </TableCell>
                <TableCell
                  style={{
                    padding: '17px 5px 17px 5px',
                    fontSize: 20,
                    fontWeight: 600,
                    borderBottom: 0,
                  }}
                  className={classes.subHeading_font}
                  align="center"
                >
                  {configJSON.ASSESSMENT_STUDENTS.AVERAGE_STUDENT_PERFORMANCE}
                </TableCell>
                <TableCell
                  style={{
                    padding: '17px 5px 17px 5px',
                    fontSize: 20,
                    fontWeight: 600,
                    borderBottom: 0,
                  }}
                  className={classes.subHeading_font}
                  align="center"
                >
                  {configJSON.ASSESSMENT_STUDENTS.TEST_NAME_HERE}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{ marginTop: 10, maxHeight: 340, overflow: 'auto' }}
            >
              <TableRow>
                <TableCell
                  style={{
                    padding: '17px 0px 16px 20px',
                    fontSize: 18,
                    textTransform: 'capitalize',
                  }}
                  align="center"
                  className={classes.bodyText_font}
                >
                  Alcaide, Niko
                </TableCell>
                <TableCell
                  style={{
                    padding: '17px 0px 16px 20px',
                    fontSize: 18,
                  }}
                  align="center"
                  className={classes.bodyText_font}
                >
                  0%
                </TableCell>
                <TableCell
                  style={{
                    padding: '17px 0px 16px 20px',
                    fontSize: 18,
                  }}
                  align="center"
                  className={classes.bodyText_font}
                >
                  0%
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AsssessmentStandardsReport;
