// Customizable Area Start
import {
  Box,
  InputBase,
  NativeSelect,
  Typography,
  Grid,
  Table, TableContainer, TableHead, TableCell, TableRow, TableBody
} from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ReactPaginate from "react-paginate";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import AdminAssessmentReportController from "./AdminAssessmentReportController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import BadResponsePopup from "./BadResponsePopup.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class AdminAssessmentReport extends AdminAssessmentReportController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    
    // const subjectList = this.state.classList.subjects;

    return (
      <Box>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Box>
          <Typography
            className={`${this.props.classes.heading_font} ${this.props.classes.AdminAssessmentReportMainHeading}`}
          >
            Assessment Report
          </Typography>
        </Box>
        <Box
          className={`${this.props.classes.AdminAssessmentReportMainBox}`}
        >
          <Box
            className={`${this.props.classes.AdminAssessmentReportFilterBox}`}
          >
            <Grid container >
              {/* Class  */}
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <NativeSelect
                  className={`${this.props.classes.bodyText_font} ${this.props.classes.AdminAssessmentReportFilterSelect}`}
                  id="select"
                  data-test-id="select-class"
                  value={this.state.selectedClass}
                  classes={{
                      root: this.props.classes.AdminFeedbackClassesAndSectionBootstrapRoot,
                      select: this.props.classes.AdminFeedbackClassesAndSectionBootstrapInput
                    }}
                  disableUnderline
                  onChange={(event) => {
                    this.setState({ selectedClass: event.target.value, selectedSubject: "", selectedAssessment: "", status: "",assessmentReportList: "" })
                  }}
                >

                  <option
                    className={this.props.classes.bodyText_font}
                    value="" disabled>
                    Select Class
                  </option>
                  {this.state.classList.map((item: any) => {
                    return (
                      <option value={item.id}>
                        {item.display_name}
                      </option>
                    );
                  })}
                </NativeSelect>
              </Grid>
              {/* Class END */}

              {/* Subject */}
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <NativeSelect
                  className={`${this.props.classes.bodyText_font} ${this.props.classes.AdminAssessmentReportFilterSelect}`}
                  id="select_subject"
                  data-test-id="select-subject"
                  value={this.state.selectedSubject}
                  classes={{
                      root: this.props.classes.AdminFeedbackClassesAndSectionBootstrapRoot,
                      select: this.props.classes.AdminFeedbackClassesAndSectionBootstrapInput
                    }}
                  disableUnderline
                  onChange={(event) => {
                    this.setState({ selectedSubject: event.target.value }, () => {
                      this.get_assessment_list();
                    });
                  }}
                >
                  <option
                    className={this.props.classes.bodyText_font}
                    value="" disabled>
                    Select Subject
                  </option>
                  {this.state.classList.map((item: any) => {
                    if (item.id == this.state.selectedClass) {
                      // console.log("selected data", item.subjects);
                      return item.subjects.map((subject: any) => {
                        return (
                          <option value={subject.id}>
                            {subject.subject_name}
                          </option>
                        );
                      })
                    }
                    
                  })}
                </NativeSelect>
              </Grid>
              {/* Subject END */}

              {/* Assessment */}
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <NativeSelect
                  className={`${this.props.classes.bodyText_font} ${this.props.classes.AdminAssessmentReportFilterSelect}`}
                  id="select_assessment"
                  data-test-id="select-assessment"
                  value={this.state.selectedAssessment}
                  classes={{
                      root: this.props.classes.AdminFeedbackClassesAndSectionBootstrapRoot,
                      select: this.props.classes.AdminFeedbackClassesAndSectionBootstrapInput
                    }}
                  disableUnderline
                  onChange={(event) => {
                    this.setState({ selectedAssessment: event.target.value }, () => {
                      this.get_assessment_report_list();
                    });
                  }}
                >

                  <option
                    className={this.props.classes.bodyText_font}
                    value="" disabled>
                    Select Assessment
                  </option>
                  {this.state.assessmentList.map((item: any) => {
                    return (
                      <option value={item.id}>
                        {item.assessment_name}
                      </option>
                    );
                  })}
                </NativeSelect>
              </Grid>
              {/* Assessment END */}

              {/* Status */}
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <NativeSelect
                  className={this.props.classes.bodyText_font}
                  id="select_status"
                  data-test-id="select-status"
                  value={this.state.status}
                  classes={{
                      root: this.props.classes.AdminFeedbackClassesAndSectionBootstrapRoot,
                      select: this.props.classes.AdminFeedbackClassesAndSectionBootstrapInput
                    }}
                  disableUnderline
                  style={{
                    width: 180,
                    marginBottom: 20,
                  }}
                  disabled = {this.state.assessmentList.length <  1}
                  onChange={(event) => {
                    this.setState({
                      status: event.target.value
                    }, () => {
                      if (this.state.status !== "") {
                        this.get_assessment_report_list();
                      }
                    });
                  }}
                >
                  <option className={this.props.classes.bodyText_font} value="" disabled>
                    Select Status
                  </option>
                  <option className={this.props.classes.bodyText_font} value="skipped">
                    Skipped
                  </option>
                  <option className={this.props.classes.bodyText_font} value="pass">
                    Pass
                  </option>
                  <option className={this.props.classes.bodyText_font} value="fail">
                    Fail
                  </option>

                </NativeSelect>
              </Grid>
              {/* Status END */}
            </Grid>
          </Box>

          {/* Report Table */}
          <TableContainer className={`${this.props.classes.AdminAssessmentReportTableContainerStyle}`}>
            <Table>
              <TableHead className={`${this.props.classes.AdminAssessmentReportTableHeaderStyle}`}>
                <TableRow>
                  <TableCell className={`${this.props.classes.subHeading_font} ${this.props.classes.AdminAssessmentReportTableHeadingCell}`}>Student Name</TableCell>
                  <TableCell className={`${this.props.classes.subHeading_font} ${this.props.classes.AdminAssessmentReportTableHeadingCell}`}>Teacher Name</TableCell>
                  <TableCell className={`${this.props.classes.subHeading_font} ${this.props.classes.AdminAssessmentReportTableHeadingCell}`}>Status</TableCell>
                  <TableCell className={`${this.props.classes.subHeading_font} ${this.props.classes.AdminAssessmentReportTableHeadingCell}`}>Marks</TableCell>
                </TableRow>
              </TableHead>

              <TableBody className={`${this.props.classes.AdminAssessmentReportTableBodyStyle}`}>
                {
                  (this.state.assessmentReportList?.length === 0)
                    ?
                   ( <TableRow>
                      <TableCell className={`${this.props.classes.bodyText_font} ${this.props.classes.AdminAssessmentReportTableBodyCell}`} colSpan={4} align="center" > No record(s) found. </TableCell>
                    </TableRow>)
                    :
                   ( this.state.assessmentReportList.map((item: any) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell colSpan={4} className={`${this.props.classes.AdminAssessmentReportEmptyTableBodyRow}`}></TableCell>
                          </TableRow>
                          <TableRow className={`${this.props.classes.AdminAssessmentReportTableBodyRowStyle}`} >
                            <TableCell className={`${this.props.classes.bodyText_font} ${this.props.classes.AdminAssessmentReportTableBodyCell}`}>{item.attributes.student}</TableCell>
                            <TableCell className={`${this.props.classes.bodyText_font} ${this.props.classes.AdminAssessmentReportTableBodyCell}`}>{item.attributes.teacher}</TableCell>
                            <TableCell className={`${this.props.classes.bodyText_font} ${this.props.classes.AdminAssessmentReportTableBodyCell}`}>{item.attributes.status}</TableCell>
                            <TableCell className={`${this.props.classes.bodyText_font} ${this.props.classes.AdminAssessmentReportTableBodyCell}`}>{item.attributes.gain_marks}</TableCell>
                          </TableRow>
                        </>
                      )
                    }))
                }
              </TableBody>

            </Table>
          </TableContainer>
          {/* Report Table END */}

          {/* Pagination */}
          <Box style={{ textAlign: "end", marginTop: 25 }}>
            <ReactPaginate
              previousLabel={"←   Previous"}
              nextLabel={"Next   →	"}
              breakLabel="..."
              initialPage={this.state.pagination_page}
              pageCount={this.state.pageCount}
              onPageChange={this.handlePagination}
              containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
              previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
              nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
              disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
              activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
              pageRangeDisplayed={this.state.pageCount}
              marginPagesDisplayed={0}
            />
          </Box>
          {/* Pagination END */}
        </Box>
        <BadResponsePopup
          openModal={this.state.openPopup}
          onCloseModal={this.handleCloseBadResponsePopup}
        />
      </Box>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

// export default withStyles(useStyles)(StudentProfile)
export default withStyles(themeCreateStyle)(
  AdminAssessmentReport as React.ComponentType<any>
);

// export default AdminFeedback;

// Customizable Area End
