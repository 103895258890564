// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Avatar, TextField, Button } from "@material-ui/core";
import TaskMarkSubmissionController, {
    Props,
} from "./TaskMarkSubmissionController.web";
import { withStyles } from '@material-ui/core/styles';
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import './TaskMarkSubmission.web.css';
import { invalidCharsForNumberType } from "../utils/common";
import Spinner from "../../../shared/SideBar/src/Spinner";
import TaskMarkSubmissionTest from './TaskMarkSubmissionTest.web'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import './TaskMarkSubmission.web.css';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class TaskMarkSubmission extends TaskMarkSubmissionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    render() {
        const { teacherComment, isLoading, studentMarks, totalMarks, studentName, task_evalution, studentAvtar, teacherScoreData, studentFileData } = this.state;
        return (
            <>
                <Box className="taskMarkContainer">
                    {isLoading && (
                        <Spinner spinnerModal={isLoading} />
                    )}
                    <Box>
                        <Box className={`taskMarkBox ${this.props.classes.button_color}`}>
                            <ArrowBackIosIcon className="backIcon" onClick={this.goBack} />
                            <Typography className={`selectShort ${this.props.classes.bodyText_font}`}>
                                Read a Selected Short Story by {studentName}
                            </Typography>
                        </Box>
                        <Grid className="taskMarkStudentGrid" container spacing={2}>
                            <Grid item xs={12} lg={2} md={2}>
                                <Box>
                                    <Typography className={`markStudentTitle ${this.props.classes.subHeading_font}`}>
                                        Student
                                    </Typography>
                                    <Grid container spacing={2} className="markStudentMainGrid">
                                        <Grid item xs={3} className="markStudentGrid">
                                            <Avatar className="studentAvtar"
                                                src={studentAvtar != null || studentAvtar != undefined ? studentAvtar : "/"}
                                            />
                                        </Grid>
                                        <Grid item xs={9} className="start">
                                            <Typography className={`taskStudentAttribute ${this.props.classes.bodyText_font}`}>
                                                {studentName}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={3} md={3}>
                                <Box>
                                    <Typography className={`taskGradeMark ${this.props.classes.subHeading_font}`}>
                                        Assessment Marks / Grade
                                    </Typography>
                                    <Grid className="marginTop15" container spacing={2}>
                                        <Grid item xs={6} >
                                            <Box className="studentMarkBox">
                                                <input
                                                    placeholder=""
                                                    type="number"
                                                    step="0.25"
                                                    min="0.00"
                                                    onChange={(event) => {
                                                        const value: number = parseFloat(
                                                            parseFloat(event.target.value)
                                                                .toFixed(3)
                                                                .slice(0, -1)
                                                        );
                                                        this.setState({ studentMarks: value });
                                                    }}
                                                    disabled={task_evalution ? true : false}
                                                    onKeyPress={(event) => {
                                                        if (invalidCharsForNumberType.includes(event.key)) {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                        }
                                                    }}
                                                    value={studentMarks}
                                                    className={`teacherMarksBox ${this.props.classes.bodyText_font}`}
                                                />
                                                <Typography className={`studentTotalMarks ${this.props.classes.bodyText_font}`}>
                                                    /{totalMarks}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Box>
                                                <Button
                                                    onClick={() => {
                                                        if (!task_evalution) {
                                                            this.teacher_task_submission()
                                                        }
                                                    }}
                                                    disabled={task_evalution}
                                                    className={`taskSubmitBtn ${task_evalution ? this.props.classes.TaskMarkSubmission_ClassAndSections_button_color_disabled : this.props.classes.button_color}`}>
                                                    Submit
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} className="taskSubmissionGrid">
                                <Grid container spacing={4}>
                                    <Grid item xs={12} lg={9} md={9}>
                                        <Box className="taskSubmissionBox">
                                            <TaskMarkSubmissionTest studentFileData={studentFileData} getPreSignedId={this.getPreSignedId} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={3} md={3}>
                                        <Box className="teacherCommentBox">
                                            <Box className="teacherCommentBorder">
                                                <Typography className={`fontSizeTeacher ${this.props.classes.subHeading_font}`}>
                                                    Teacher Comment
                                                </Typography>
                                                <TextField className="teacherCommentInput" 
                                                    id="standard-basic"
                                                    type="text"
                                                    value={teacherComment}
                                                    multiline
                                                    rows={4}
                                                    placeholder="Write comment here.."
                                                    variant="outlined"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        this.setState({ teacherComment: value })
                                                    }}
                                                />
                                            </Box>
                                            <Box className="taskGradeMainBox">
                                                <Typography className={`fontSizeTeacher ${this.props.classes.subHeading_font}`}>
                                                    Task Grades
                                                </Typography>
                                                <Box className={`nameGradeBox ${this.props.classes.bodyText_font}`}>
                                                    <Typography className="studentNameSection">
                                                        Name
                                                    </Typography>
                                                    <Typography className="studentGradeSection">Grade</Typography>
                                                    <Typography className="toText">To</Typography>
                                                    <Typography className="fromText">From</Typography>
                                                </Box>
                                                {teacherScoreData.length > 0 && teacherScoreData.map((data: any) => (
                                                    <Box className={`teacherScoreDataBox ${this.props.classes.bodyText_font}`}>
                                                        <Typography className="markAttributesName">
                                                            {data?.attributes?.name != null ? <>{data?.attributes?.name}</> : <>-</>}
                                                        </Typography>
                                                        <Typography className="markAttributesName markAttributesScore">
                                                            {data?.attributes?.score != null ? <>{data?.attributes?.score}</> : <>-</>}
                                                        </Typography>
                                                        <Typography className="maxMarksAttributes">
                                                            {data?.attributes?.max_marks != null ? <>{data?.attributes?.max_marks}</> : <>-</>}
                                                        </Typography>
                                                        <Typography className="minMarksAttributes">
                                                            {data?.attributes?.min_marks != null ? <>{data?.attributes?.min_marks}</> : <>-</>}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(TaskMarkSubmission, "TeacherAccount"));
// Customizable Area End

