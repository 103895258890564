// Customizable Area Start
import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import { Box, Button, Typography, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import { withStyles } from "@material-ui/core/styles";
import TeacherAssessmentCreateThemeController, {
  Props,
} from "./TeacherAssessmentCreateThemeController.web";
import { imgBackArrow } from "./assets";

import HOCAuth from "../../shared/Header/src/HOCAuth.web";
import Spinner from "../../shared/SideBar/src/Spinner";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = {
  settingcontainer: {
    backgroundColor: "#3a60d7",
    padding: 10,
    color: "#FFF",
    paddingBottom: 5,
    width: "fit-content",
    marginLeft: "auto",
    cursor: "pointer",
    marginTop: 10,
    marginBottom: 10,
  },
  themeSection: {
    background: "#fff",
    padding: " 15px",
  },
  subHeading: {
    color: "#556276",
  },
  themeList: {
    display: "flex",
    gap: "20",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  themeBox: {
    alignItems: "center",
    padding: 15,
    borderRadius: '8px',
    boxShadow: '0 0 15px 0 rgba(224, 224, 224, 0.5)',
    backgroundColor: '#fff',
  },
  themeNextButton: {
    color: "#fff",
    borderRadius: 5,
    textAlign: "center",
    textDecoration: "none",
    padding: "10px 25px",
    backgroundColor: "#3a60d7",
  },
  backScreen: {
    cursor: "pointer",
    marginTop: 20,
    marginBottom: 10,
  },
  themeImage: {
    height: 180,
    width: 400,
  },
  selectButtonStyle: {
    margin: "20px 0px 0px 0px",
    // padding: "13px 49px",
    borderRadius: "6px",
    border: "solid 1px #3a60d7",
    // backgroundColor: selectedTheme_id === data.id ? "#3a60d7" : "#fff",
    // color: selectedTheme_id === data.id ? "#fff" : " #3f526d",
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Open sans",
    height: 40,
    width: 144,
  }
} as const;
// Customizable Area End

export class TeacherAssessmentCreateTheme extends TeacherAssessmentCreateThemeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { themeList, selectedTheme_id } = this.state;

    return (
      <>
        {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
        <Box className="theme_main_box">
          <Box
            display="flex"
            alignItems="center"
            style={styles.backScreen}
            onClick={this.handleBack}
          >
            <KeyboardBackspaceIcon className={this.props.classes.icon_color} /> <Typography
              className={`CreateNewAssessmentText ${this.props.classes.bodyText_font}`}
            >Select Theme</Typography>
          </Box>
          <Box className="theme_box_mt">
            <Grid container spacing={4}>
              {themeList?.map((data: any) => (
                < Grid item xs={12} sm={4} key={data?.id}>
                  <Box className="AssessmentThemeBox">
                    <Typography
                      className={`AssessmentThemeName ${this.props.classes.heading_font}`}
                    >
                      {data.attributes.name}
                    </Typography>
                    <img src={data.attributes.image} className="theme_image"></img>
                  </Box>
                  <Box>
                    <Button
                      style={styles.selectButtonStyle}

                      className={selectedTheme_id == data.id ? this.props.classes.button_color : this.props.classes.teacherAssessmentCreateTheme_classAndSection_outline_button_color}
                      onClick={() => {
                        this.setState({
                          selectedTheme_id: data.id,
                        })
                        this.saveThemeAndContine(data)
                      }}

                    >
                      Select
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box >
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(TeacherAssessmentCreateTheme as React.ComponentType<any>, "TeacherAccount"));
// Customizable Area End
