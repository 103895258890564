// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { List, ListItem, FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography } from '@material-ui/core';
import { HISTORY } from "../../../../components/src/comman";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);

function ReportCardEvaluation(props: any) {
    const classes = useStyles();

    const handleBack = () => {
        HISTORY.goBack();
    }
    return (
        <>
            <div className='profileTitle'>
                <Button className="backBtn" onClick={() => handleBack()}><KeyboardBackspaceOutlinedIcon /> Back</Button>
                <h2 className={props.classes.heading_font}>Part 2 - Assessment of Learning </h2>
                <Button className={props.classes.button_color} style={{ position: "absolute", right: 0, marginRight: "10px" }}>Enable Edit <EditOutlinedIcon /> </Button>
            </div>
            <div className="tableContent">
                <div className={classes.root}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={props.classes.subHeading_font}>Evaluation 1</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer
                                style={{ width: "96%", paddingTop: "30px", marginLeft: "40px" }} >
                                <Table stickyHeader>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={`${props.classes.bodyText_font} ${props.classes.tabRowCell}`} style={{ borderRadius: "0px" }}>
                                                Specific Participation
                                            </TableCell>
                                            <TableCell className={props.classes.border_Cell} style={{ borderRadius: "0px" }}>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={`${props.classes.bodyText_font} ${props.classes.tabRowCell}`} style={{ borderRadius: "0px" }}>
                                                General Remarks
                                            </TableCell>
                                            <TableCell className={props.classes.border_Cell} style={{ borderRadius: "0px" }}>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={`${props.classes.bodyText_font} ${props.classes.tabRowCell}`} style={{ borderRadius: "0px" }}>
                                                Attendence
                                            </TableCell>
                                            <TableCell className={props.classes.border_Cell} style={{ borderRadius: "0px" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Typography align="left"> No. of Days Present <TextField /></Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Typography align="right">Total No. of Working Days  <TextField /></Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={`${props.classes.bodyText_font} ${props.classes.table_cell}`} style={{ borderRadius: "0px", borderRight: "0px" }}>
                                                Class Teachers
                                            </TableCell>
                                            <TableCell className={props.classes.border_right} style={{ borderRadius: "0px" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Typography align="left" style={{ paddingLeft: "100px" }}> Principal / ACo</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Typography style={{ paddingLeft: "250px" }}> Parent</Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer >
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={props.classes.subHeading_font}>Evaluation 2</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer
                                style={{ width: "96%", paddingTop: "30px", marginLeft: "40px" }} >
                                <Table stickyHeader>

                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={`${props.classes.bodyText_font} ${props.classes.tabRowCell}`} style={{ borderRadius: "0px" }}>
                                                Specific Participation
                                            </TableCell>
                                            <TableCell className={props.classes.border_Cell} style={{ borderRadius: "0px" }}>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={`${props.classes.bodyText_font} ${props.classes.tabRowCell}`} style={{ borderRadius: "0px" }}>
                                                General Remarks
                                            </TableCell>
                                            <TableCell className={props.classes.border_Cell} style={{ borderRadius: "0px" }}>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={`${props.classes.bodyText_font} ${props.classes.tabRowCell}`} style={{ borderRadius: "0px" }}>
                                                Attendence
                                            </TableCell>
                                            <TableCell className={props.classes.border_Cell} style={{ borderRadius: "0px" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Typography align="left"> No. of Days Present <TextField /></Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Typography align="right">Total No. of Working Days  <TextField /></Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={`${props.classes.bodyText_font} ${props.classes.table_cell}`} style={{ borderRadius: "0px", borderRight: "0px" }}>
                                                Class Teachers
                                            </TableCell>
                                            <TableCell className={props.classes.border_right} style={{ borderRadius: "0px" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Typography align="left" style={{ paddingLeft: "100px" }}> Principal / ACo</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Typography style={{ paddingLeft: "250px" }}> Parent</Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>

                                </Table>
                            </TableContainer >
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={props.classes.subHeading_font}>Evaluation 3</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer
                                style={{ width: "96%", paddingTop: "30px", marginLeft: "40px" }} >
                                <Table stickyHeader>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={`${props.classes.bodyText_font} ${props.classes.tabRowCell}`} style={{ borderRadius: "0px" }}>
                                                Specific Participation
                                            </TableCell>
                                            <TableCell className={props.classes.border_Cell} style={{ borderRadius: "0px" }}>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={`${props.classes.bodyText_font} ${props.classes.tabRowCell}`} style={{ borderRadius: "0px" }}>
                                                General Remarks
                                            </TableCell>
                                            <TableCell className={props.classes.border_Cell} style={{ borderRadius: "0px" }}>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={`${props.classes.bodyText_font} ${props.classes.tabRowCell}`} style={{ borderRadius: "0px" }}>
                                                Attendence
                                            </TableCell>
                                            <TableCell className={props.classes.border_Cell} style={{ borderRadius: "0px" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Typography align="left"> No. of Days Present <TextField /></Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Typography align="right">Total No. of Working Days  <TextField /></Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={`${props.classes.bodyText_font} ${props.classes.table_cell}`} style={{ borderRadius: "0px", borderRight: "0px" }}>
                                                Class Teachers
                                            </TableCell>
                                            <TableCell className={props.classes.border_right} style={{ borderRadius: "0px" }}>
                                                <Typography>Result: Qualified / Eligible for improvement of Performance (EIOP)<br /><br /><br />
                                                    New Session begins on<TextField /></Typography>
                                                <Grid container spacing={2}>

                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Typography align="left" style={{ paddingLeft: "100px", paddingTop: "36px" }}> Principal / ACo</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={6}>

                                                        <Typography style={{ paddingLeft: "250px", paddingTop: "36px" }}> Parent</Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>

                                        </TableRow>
                                    </TableBody>

                                </Table>
                            </TableContainer >
                        </AccordionDetails>
                    </Accordion>

                    <TableContainer
                        style={{ width: "96%", paddingTop: "30px", paddingBottom: "30px", marginLeft: "40px" }} >
                        <Table stickyHeader>

                            <TableBody>
                                <TableRow>
                                    <TableCell className={props.classes.tabCell} style={{ borderRadius: "0px" }}>
                                        A+
                                    </TableCell>
                                    <TableCell className={props.classes.tabCell} style={{ borderRadius: "0px" }}>
                                        Outstanding
                                    </TableCell>
                                    <TableCell style={{ border: "0px" }}>

                                        1)The Objective of the achievement record  is to facilate holistic learning in the school in a street free environment.

                                    </TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell className={props.classes.tabCell} style={{ borderRadius: "0px" }}>
                                        A
                                    </TableCell>
                                    <TableCell className={props.classes.tabCell} style={{ borderRadius: "0px" }}>
                                        Excellent
                                    </TableCell>
                                    <TableCell style={{ border: "0px" }}>
                                        2)The Focus  is on identifying the talents of the learner and to empower him/her positive inputs.
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={props.classes.tabCell} style={{ borderRadius: "0px" }}>
                                        B
                                    </TableCell>
                                    <TableCell className={props.classes.tabCell} style={{ borderRadius: "0px" }}>
                                        Very Good
                                    </TableCell>
                                    <TableCell style={{ border: "0px" }}>
                                        3)The boards recommendeds a five points scale for indicating the achievement in the following order.

                                    </TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell className={props.classes.tabCell} style={{ borderRadius: "0px" }}>
                                        C
                                    </TableCell>
                                    <TableCell className={props.classes.tabCell} style={{ borderRadius: "0px" }}>
                                        Good
                                    </TableCell>


                                </TableRow>
                                <TableRow>
                                    <TableCell className={props.classes.tabCell} style={{ borderRadius: "0px" }}>
                                        D
                                    </TableCell>
                                    <TableCell className={props.classes.tabCell} style={{ borderRadius: "0px" }}>
                                        Average
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer >
                </div>
            </div>
        </>
    );
}
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(ReportCardEvaluation as React.ComponentType<any>, "AdminAccount"));
  // Customizable Area End