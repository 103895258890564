// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    reportGradeScaleData: any;
    isLoader: boolean;
    data: any;
    // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
 }

export default class GradingDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getGradeDataId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

        this.state = {
            reportGradeScaleData: null,
            isLoader: false,
            data: [],
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.get_grade_data = this.get_grade_data.bind(this);
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount()
        // Customizable Area Start
        const studentId = localStorage.getItem("id");
        if (studentId) {
            this.get_grade_data(Number(studentId))
        }
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.getGradeDataId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (responseJson.data != null) {
                            this.setState({
                                reportGradeScaleData: responseJson.data,
                                isLoader: false,
                            })
                        }
                    } else {
                        this.parseApiErrorResponse(responseJson);
                        this.setState({ isLoader: false });
                    }
                }
                this.parseApiCatchErrorResponse(errorResponse);
                this.setState({ isLoader: false });
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    get_grade_data = (studentId: any) => {
        this.setState({ isLoader: true });
        const user_data = localStorage.getItem("user_data");
        const school_Data = JSON.parse(user_data || "{}");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("token"),
            school: school_Data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getGradeDataId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getGradeDataEndPoint  + `?student_id=${Number(studentId)}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.resultUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}
