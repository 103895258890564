// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  InputBase,
  Checkbox,
  CheckboxProps,
  Grid,
} from "@material-ui/core";
import {
  web,
  email,
  add1,
  history,
  history1,
  min45,
  starred,
  pieChart,
  online,
  tickMark2,
} from "./assets";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StudentProfileController, {
  Props,
} from "./StudentProfileController";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 26,
    height: 26,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "linear-gradient(to top, #af38cb 101%, #471c51 34%)",
    backgroundImage: "linear-gradient(to top, #af38cb 101%, #471c51 34%);",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "linear-gradient(to top, #af38cb 101%, #471c51 34%)",
      content: "",
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});
const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    backgroundColor: "none",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ebebeb",
    paddingLeft: 14,
  },
  error: {
    borderColor: "red !important",
  },
}))(InputBase);

// Customizable Area End
export class StudentProfile extends StudentProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getImage = (notificationName: string): any => {
    switch (notificationName) {
      case "Events":
        return add1;
      case "Files":
        return history;
      case "Messages":
        return history1;
      case "Portfolio":
        return starred;
      case "Report Cards":
        return pieChart;
      case "Like & Shares":
        return online;
      case "New Tasks & Resources":
        return min45;
      default:
        return history;
    }
  };

  render() {
    const { notificationDetails, notificationDynamicDetails } = this.state;

    return (
      <>
        <Grid className="StudentProfileNotifications">
          <Box className="ProfileNotificationHead">
            <Box className="notification_header_left">
              <img src={web} className="notification_header_icon" />
              <Typography className="notification_header_text">Web</Typography>
            </Box>
            <Box className="notification_header_right">
              <img src={email} className="notification_header_icon" />
              <Typography className="notification_header_text">
                Email
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid className="StudentNotificationsDynamic">
          {notificationDynamicDetails.map((item: any) => (
            <Grid
              container
              className="NotificationContainer"
            >
              <Grid item xs={8} className="notification_item">
                <img
                  src={this.getImage(item.name)}
                  className="NotificationIcon"
                />
                <Box className="NotificationTextBox">
                  <Typography className={`NotificationName ${this.props.classes.bodyText_font}`}>
                    {item.name}
                  </Typography>
                  <Typography className={`NotificationDesc ${this.props.classes.bodyText_font}`}>
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                className="NotificationCheckGrid"
              >

                <Checkbox
                  disableRipple
                  icon={<FiberManualRecordIcon className="NotificationManual" />}
                  checkedIcon={<CircleCheckedFilled className={`NotificationChecked ${this.props.classes.icon_color}`} />}
                  checked={
                    notificationDetails && notificationDetails[item.name]?.web
                      ? true
                      : false
                  }
                  onChange={this.handleNotificationInputChange(
                    item.name,
                    "web"
                  )}
                  className={this.props.classes.icon_color}
                />
                <Checkbox
                  disableRipple
                  icon={<FiberManualRecordIcon className="NotificationManual" />}
                  checkedIcon={<CircleCheckedFilled className={`NotificationChecked ${this.props.classes.icon_color}`} />}
                  checked={
                    notificationDetails && notificationDetails[item.name]?.email
                      ? true
                      : false
                  }
                  className={this.props.classes.icon_color}
                  onChange={this.handleNotificationInputChange(
                    item.name,
                    "email"
                  )}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export const studentProfileNotificationsStyles = {
  selectDropDown: {
    // position:"none !important",
    "& .MuiInputBase-input": {
      minWidth: "135px",
      backgroundColor: "none",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#ebebeb",
      borderRadius: "8px",
      padding: "10px 15px",
    },
  },
};
// Customizable Area End

// Customizable Area Start
const combineStyles = (...styles: any) => {
  return function CombineStyles(theme: any) {
    const outStyles = styles.map((arg: any) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc: any, val: any) => Object.assign(acc, val));
  };
}

const combinedStyle = combineStyles(themeCreateStyle, studentProfileNotificationsStyles);
export default withStyles(combinedStyle)(
  StudentProfile as React.ComponentType<any>
);
// Customizable Area End