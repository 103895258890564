
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as SparkMD5 from "spark-md5";
import { toast } from "react-toastify";
import axios from "axios";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
import { boolean } from "yup";
import { FieldArray } from "formik";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    closeModalFun: any;
    openComment: boolean;
    id: any;
    type: any;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    closeModal: boolean;
    showPicker: boolean;
    inputStr: string;
    data: any;
    commentData: any;
    commentLike: boolean;
    comment_id: any;
    nestedComment: boolean;
    commentDescription: any;
    nestedcomment_id: any;
    commentError: boolean;
    showAllComment: boolean;
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class CommentModalController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    apiSendCommentId: string = '';
    apiGetCommentId: string = '';
    apiSendNestedCommentId: string = '';
    apiCommentLikeId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            closeModal: false,
            showPicker: false,
            inputStr: '',
            data: [],
            commentData: [],
            comment_id: [],
            commentLike: false,
            nestedComment: false,
            commentDescription: [],
            nestedcomment_id: [],
            commentError: false,
            showAllComment: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.userdata = this.userdata.bind(this);
        this.getComment = this.getComment.bind(this);
        // Customizable Area End
    }


    async componentDidMount() {
        super.componentDidMount();
        this.userdata();
        this.getComment();
    }
    sendnestedComment = () => {
        const { inputStr, nestedcomment_id } = this.state
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('token'),

        };
        const data = {
            id: nestedcomment_id,
            post_type: 'Comment',
            description: inputStr
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiSendNestedCommentId = requestMessage.messageId;
        const httpBody = data;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.commentAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getComment = () => {
        const { id, type } = this.props
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetCommentId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCommentAPIEndPoint + `?post_type=${type === 'post' ? 'Post' : (type === 'goals' && 'Goal')}&id=${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    sendComment = () => {
        const { id, type } = this.props
        const { inputStr } = this.state
        if (
            inputStr === null ||
            inputStr.length === 0
        ) {
            this.setState({ commentError: true });
            return false
        }
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('token'),

        };
        const data = {
            id: id,
            post_type: type === 'post' ? 'Post' : (type === 'goals' && 'Goal'),
            tag_user_ids: [],
            description: inputStr
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiSendCommentId = requestMessage.messageId;
        const httpBody = data;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.commentAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    commentLike = (id: any, type: any, commentLiked: boolean) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('token'),

        };
        const data = {
            id: id,
            post_type: type === 'comments' && 'Comment',
            liked: commentLiked,
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiCommentLikeId = requestMessage.messageId;
        const httpBody = data;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.likeGoalsEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.closeModalFun(this.state.closeModal)
    }

    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apiSendCommentId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ inputStr: '' })
                        this.getComment();
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiSendNestedCommentId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ nestedComment: false, commentDescription: null, inputStr: '' })
                        this.getComment();
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiGetCommentId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ commentData: responseJson.data })
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiCommentLikeId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.getComment()
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }
}