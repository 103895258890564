// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import React from 'react';
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { HISTORY } from "../../../../components/src/comman";
import convert from "xml-js";
import * as SparkMD5 from "spark-md5";
// Customizable Area End


// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    data: any;
    studentFileData: any;
    task_id: string;
    task_category_id: string;
    studentName: string;
    student_id: string;
    studentAvtar: string;
    task_member_id: string;
    studentMarks: any;
    teacherComment: string;
    teacherScoreData: any;
    annotationImageId: string;
    imageDataFile: any;
    profilePhotoFile: any;
    totalMarks: any;
    isLoading: boolean;
    task_evalution: boolean;
    report_grade_id:string;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class TaskMarkSubmissionController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    apigetTaskGradeScaleId: string = "";
    apiUploadBlockId: string = '';
    apiPostPresignID: string = '';
    apigetTaskEvaluationId: string = '';
    apigetTeacherTaskSubmitId: string = '';
    imgRef: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            data: null,
            studentFileData: [],
            task_id: '',
            task_category_id: '',
            studentName: '',
            student_id: '',
            studentAvtar: '',
            task_member_id: '',
            studentMarks: null,
            totalMarks: null,
            teacherComment: '',
            teacherScoreData: [],
            annotationImageId: '',
            imageDataFile: null,
            profilePhotoFile: null,
            isLoading: false,
            task_evalution: true,
            report_grade_id:'',
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.get_userdata = this.get_userdata.bind(this);
        this.imgRef = React.createRef<HTMLImageElement>();
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        
        const marksArray = HISTORY.location.state.studentFileData.attributes.score.split("/")
        
        this.setState({
            studentFileData: HISTORY.location.state.studentFileData.attributes.file,
            task_category_id: HISTORY.location.state.studentFileData.attributes.category.data.id,
            report_grade_id:HISTORY.location.state.studentFileData.attributes.category.data.attributes.report_grade_id,
            task_id: HISTORY.location.state.studentFileData.attributes.task_id,
            studentName: ` ${HISTORY.location.state.studentFileData.attributes.member.attributes.first_name} ${HISTORY.location.state.studentFileData.attributes.member.attributes.last_name}`,
            student_id: HISTORY.location.state.studentFileData.attributes.member.id,
            studentAvtar: HISTORY.location.state.studentFileData.attributes.member.attributes.avatar,
            task_member_id: HISTORY.location.state.studentFileData.id,
            annotationImageId: HISTORY.location.state.studentFileData.attributes.file[0].id,
            teacherComment: HISTORY.location.state.studentFileData.attributes.comment,
            totalMarks: parseInt(marksArray[1]),
            studentMarks: parseInt(marksArray[0]),
            task_evalution: HISTORY.location.state.studentFileData.attributes.task_evalution
        })
        if (HISTORY.location.state.studentFileData.attributes.task_evalution) {
            this.get_Show_TaskEvaluation(HISTORY.location.state.studentFileData.attributes.task_id, HISTORY.location.state.studentFileData.id)
        }
        this.get_Task_Grade_Scale(HISTORY.location.state.studentFileData.attributes.category.data.id)
    }
    // Customizable Area End
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apigetTeacherTaskSubmitId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({
                            isLoading: false,
                            task_evalution: true,
                        })
                        toast.success("Task Evaluation Submit successfully");
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }

            else if (apiRequestCallId === this.apigetTaskGradeScaleId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({
                            teacherScoreData: responseJson.data,

                        })
                        if (!HISTORY.location.state.studentFileData.attributes.task_evalution) {
                            this.setState({
                                isLoading: false,
                            })
                        }
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apigetTaskEvaluationId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({
                            studentFileData: responseJson.data.attributes.files.data,
                            studentMarks: responseJson.data.attributes.obtained_marks,
                            teacherComment: responseJson.data.attributes.comments,
                            isLoading: false,
                        })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiPostPresignID) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({
                            isLoading: true,
                        })
                        const imageData = this.state.profilePhotoFile;
                        const msg: Message = new Message(
                            getName(MessageEnum.UploadMediaMessage)
                        );
                        const uploadFileData: any = {
                            responseJson: responseJson,
                            imageData,
                            messageId: msg.messageId,
                        };
                        msg.addData(
                            getName(MessageEnum.UploadMediaDataMessage),
                            uploadFileData
                        );
                        this.apiUploadBlockId = msg.messageId;
                        runEngine.sendMessage(msg.id, msg);
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiUploadBlockId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        const keyValue = JSON.parse(
                            convert.xml2json(responseJson, {
                                spaces: 1,
                                compact: true,
                                ignoreComment: true,
                                alwaysChildren: true,
                                ignoreDeclaration: true,
                            })
                        );
                        const { studentFileData, annotationImageId } = this.state
                        const tempfileData = studentFileData
                        const findIndex = tempfileData.findIndex((data: any) => data.id === annotationImageId)
                        const data = tempfileData[findIndex]
                        const obj = data
                        obj.sihnId = keyValue.PostResponse.Key._text
                        const newS = obj
                        tempfileData[findIndex] = newS
                        this.setState({
                            isLoading: false,
                        })
                        this.setState({ studentFileData: tempfileData })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    get_userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data })
    }
    computeChecksumMd5 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const chunkSize = 2097152;
            const spark = new SparkMD5.ArrayBuffer();
            const fileReader = new FileReader();
            let cursor = 0;
            fileReader.onerror = function (): void {
                reject("MD5 computation failed - error reading the file");
            };
            function processChunk(chunk_start: number): void {
                const chunk_end = Math.min(file.size, chunk_start + chunkSize);
                fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
            }
            fileReader.onload = function (e: any): void {
                spark.append(e.target.result);
                cursor += chunkSize;

                if (cursor < file.size) {
                    processChunk(cursor);
                } else {
                    resolve(btoa(spark.end(true)));
                }
            };

            processChunk(0);
        });
    };
    goBack = () => {
        HISTORY.push({
            pathname: "/TeacherGradeBook",
            state: {
                tabValue: 2,
            }
        });
    }
    teacher_task_submission = async () => {
        const school_data = localStorage.getItem("user_data");
        const schoolData = JSON.parse(school_data || "{}");
        const { teacherComment, task_id, studentMarks, studentFileData, student_id, task_member_id, report_grade_id } = this.state
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": schoolData.school_id,
        };
        let teacher_File_sihnId_File_Data: any = []
        studentFileData.map((data: any) => {
            if (data.sihnId) {
                teacher_File_sihnId_File_Data.push(
                    {
                        upload_id: data.id,
                        url: data.sihnId
                    }
                )
            }
        })
        const data = {
            student_id: student_id,
            report_grade_id: report_grade_id,
            task_id: task_id,
            task_member_id: task_member_id,
            comments: teacherComment.trim(),
            obtained_marks: studentMarks,
            files: teacher_File_sihnId_File_Data
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.setState({
            isLoading: true,
        })
        this.apigetTeacherTaskSubmitId = requestMessage.messageId;

        const httpBody = data;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTeacherMaskSubmitEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.resultUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getPreSignedId = (photoDetail: any, imageDataFile: any, id: any) => {
        this.setState({
            profilePhotoFile: imageDataFile,
            annotationImageId: id,
        })
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            token: localStorage.getItem("token"),
            school: data.school_id,
        };
        let formData = new FormData();
        formData.append("content_type", photoDetail.content_type);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiPostPresignID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fileUploadS3
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.SignIDUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    get_Task_Grade_Scale = (id: any) => {
        this.setState({
            isLoading: true,
        })
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetTaskGradeScaleId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTaskGradeScaleEndPoint + `?report_category_id=${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.resultUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }



    get_Show_TaskEvaluation = (taskId: any, taskMemberId: any) => {
        this.setState({
            isLoading: true,
        })
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            "school": data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetTaskEvaluationId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.showTaskEvaluationEndPoint + `?task_id=${taskId}&task_member_id=${taskMemberId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.resultUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
