// Customizable Area Start
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
import CreateNotesController, { Props } from './CreateNotesController.web';
import './TeacherDashboard.web.css';
// Customizable Area End
export class CreateNotes extends CreateNotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    return (
      <>
        <Dialog className="createnote-popup"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
          open={this.props.createNoteModal}
          onClose={this.modalhandleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade
            in={this.props.createNoteModal}
            style={{
              border: 'none',
              margin: '0px',
              overflowX: 'hidden',
            }}
          >
            <div
              
              style={{
                // overflowX: 'hidden',
                // display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box className='mob-padd'
                style={{
                  height: '100%',
                  backgroundColor: '#fff',
                  padding: '20px',
                  overflowX: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <form onSubmit={this.addNote}>
                  <Box style={{ justifyContent: 'center' }}>
                    <Box
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        borderBottom: '0.3px solid #979797',
                        margin: '0 0px 26px 1px',
                      }}
                    >
                      <Typography
                        style={{
                          textTransform: 'none',
                          fontSize: 24,
                          fontWeight: 'bold',
                          margin: '0 0px 26px 0px',
                        }}
                        className={this.props.classes.title_font}
                      >
                        Create Note
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'start',
                        margin: '0 0px 0px 1px',
                      }}
                    >
                      <Typography
                        style={{
                          textTransform: 'none',
                          fontSize: 16,
                          fontWeight: 600,
                          margin: '23px 24px 15px 0px',
                        }}
                        className={this.props.classes.subTitle_font}
                      >
                        Heading:
                      </Typography>
                      <Box
                        style={{
                          margin: '0px 0px 16px 0px',
                        }}
                      >
                        <TextField
                          id="standard-basic"
                          type="text"
                          style={{
                            width: '100%',
                            backgroundColor: '#f6f6f6',
                            borderRadius: 8.8,
                            margin: '0px 35px 0px 1px',
                            border: 'none',
                          }}
                          placeholder="Write your note heading here"
                          variant="outlined"
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              opacity: 0.5,
                            },
                            maxLength: 15,
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            this.setState({ noteHeader: value });
                            if (this.state.noteDescription.length > 0) {
                              this.setState({ disabledbutton: false });
                            } else if (
                              this.state.noteDescription.length === 0
                            ) {
                              this.setState({ disabledbutton: true });
                            }
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'start',
                        margin: '0 0px 0px 1px',
                      }}
                    >
                      <Typography
                        style={{
                          textTransform: 'none',
                          fontSize: 16,
                          fontWeight: 600,
                          margin: '23px 24px 15px 0px',
                        }}
                        className={this.props.classes.subTitle_font}
                      >
                        Note:
                      </Typography>
                      <Box
                        style={{
                          margin: '0px 0px 16px 0px',
                        }}
                      >
                        <TextField
                          id="standard-basic"
                          type="text"
                          style={{
                            width: '100%',
                            backgroundColor: '#f6f6f6',
                            borderRadius: 8.8,
                            margin: '0px px 0px px',
                            border: 'none',
                          }}
                          multiline
                          rows={4}
                          placeholder="Write your note here"
                          variant="outlined"
                          inputProps={{
                            style: {
                              fontFamily: 'Open sans',
                              fontSize: 15.8,
                              color: '#03014c',
                              opacity: 0.5,
                            },
                            maxLength: 256,
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            this.setState({ noteDescription: value });
                            if (this.state.noteHeader.length > 0) {
                              this.setState({ disabledbutton: false });
                            } else if (this.state.noteHeader.length === 0) {
                              this.setState({ disabledbutton: true });
                            }
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        margin: '48px 33px 0px 1px',
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={this.modalhandleClose}
                        style={{
                          backgroundColor: 'white',
                          borderRadius: 3,
                          color: 'black',
                          fontSize: 14,
                          fontWeight: 600,
                          letterSpacing: '0.1px',
                          boxShadow: 'none',
                          padding: '13px 33px 13px 35px',
                          marginRight: '20px',
                        }}
                      >
                        Cancel
                      </Button>

                      {this.state.loadingSpinner ? (
                        <Box
                          style={{
                            borderRadius: 3,
                            color: '#ffffff',
                            fontSize: 18,
                            fontWeight: 600,
                            letterSpacing: '0.1px',
                            boxShadow: 'none',
                            padding: '13px 33px 13px 35px',
                          }}
                          className={this.props.classes.button_color}
                        >
                          <CircularProgress
                            style={{
                              color: 'rgba(255, 255, 255, 1)',
                            }}
                          />
                        </Box>
                      ) : (
                        <Button
                          variant="contained"
                          type="submit"
                          disableRipple={true}
                          disabled={this.state.disabledbutton}
                          style={{
                            borderRadius: 3,
                            color: '#ffffff',
                            fontSize: 18,
                            fontWeight: 600,
                            letterSpacing: '0.1px',
                            boxShadow: 'none',
                            padding: '13px 59px 13px 60px',
                            textTransform: 'none',
                            opacity: this.state.disabledbutton ? 0.34 : 1,
                          }}
                          className={this.props.classes.button_color}
                        >
                          Create
                        </Button>
                      )}
                    </Box>
                  </Box>
                </form>
              </Box>
            </div>
          </Fade>
        </Dialog>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(CreateNotes as React.ComponentType<
  any
>);
// Customizable Area End
