// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Menu,
  MenuItem,
  NativeSelect,
  Select,
  Tabs,
  Typography,
} from "@material-ui/core";
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { filter, hint, deleteiconfeedback, editiconfeedback } from "../assets";
import { withStyles } from "@material-ui/core/styles";
import ReactPaginate from "react-paginate";
import "./AdminFeedback.web.css";
import AdminFeedbackController from "./AdminFeedbackController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import CreatePopup from "./CreatePopup.web";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import DeletePopup from "./DeletePopup.web";
import UpdatePopup from "./UpdatePopup.web";
import BadResponsePopup from "./BadResponsePopup.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
// Customizable Area Start

const tabsList = [
  {
    key: 1,
    value: "report",
    label: "Feedback Report",
  },
  {
    key: 2,
    value: "questions",
    label: "Feedback Questions",
  },
];
// Customizable Area End

export class AdminFeedback extends AdminFeedbackController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    // Customizable Area Start
    // Customizable Area End
    
    // Customizable Area Start
  render() {
    const { selectedIndex, selectedTab } = this.state;
    return (
      <>
        <Box>
          {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
          <Box>
            <Typography
              className={this.props.classes.title_font}
              style={{
                margin: "1rem 0",
                fontSize: "1.25rem",
                fontWeight: 600
              }}
            >
              Feedback
            </Typography>
            <Box width="100%"  >
              <Box width="100%" className="mainTab" display="flex" flexDirection="column" pr="10px">
                <Tabs
                  value={"resources"}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {tabsList?.map((item, i) => (
                    <Button
                      style={{
                        cursor: "pointer",
                        height: 39,
                        margin: "0px 10px 0px 0px",
                        padding: 10,
                      }}
                      key={item.key}
                      data-test-id={`feedback-question-btn-${i}`}
                      onClick={() => {
                        this.handleSelectTab(item.value, item.key);
                        if (item.value === "questions") {
                          this.get_question_list();
                        }
                      }}
                      className={item.key === selectedIndex ? this.props.classes.button_color : this.props.classes.button_color_disabled}
                    >
                      <Typography
                        style={{
                          textTransform: "none",
                          fontSize: 15,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                          color: item.key === selectedIndex ? "white" : "#8F99A5",
                        }}
                      >
                        {item.label}
                      </Typography>
                    </Button>
                  ))}
                  <Box display="flex" width="100%" justifyContent="flex-end" >
                    {selectedTab === "questions" && (
                      <Button onClick={() => this.setState({ openPopup: true })}
                        style={{
                          cursor: "pointer",
                          height: 39,
                          margin: "0px 10px 0px 0px",
                          padding: 10,
                        }}
                        data-test-id={"create-new-btn"}
                        className={this.props.classes.button_color}
                      >
                        <Typography
                          style={{
                            textTransform: "none",
                            fontSize: 15,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }} >
                          Create New
                        </Typography>
                      </Button>
                    )}</Box>
                </Tabs>

              </Box>
            </Box>

            <Box>
              <div
                style={{
                  margin: "15px 0px",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.17)",
                }}
              />
            </Box>
            {selectedTab === "report" && (
              <Box
                style={{
                  padding: "22px 20px 26px",
                  marginTop: 20,
                  marginBottom: 20,
                  borderRadius: 8,
                  boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                }}
              >
                <Box
                  style={{
                    // alignItems: "center",
                    display: "flex",
                    marginBottom: 20,
                  }}
                >
                  <NativeSelect
                    className={`AdminFeedbackSelectClassDropDown ${this.props.classes.bodyText_font}`}
                    id="select"
                    data-test-id={"select-class"}
                    value={this.state.selectedClass}
                    //input={<BootstrapInput5 />}
                    classes={{
                      root: this.props.classes.AdminFeedbackClassesAndSectionBootstrapRoot,
                      select: this.props.classes.AdminFeedbackClassesAndSectionBootstrapInput
                    }}
                    disableUnderline
                    // style={{
                    //   width: 180,
                    //   marginBottom: 20,
                    //   marginRight: 20,
                    // }}
                    onChange={(event) => {
                      this.setState({ selectedClass: event.target.value }, () => {
                        this.get_school_period_list();
                      });
                    }}
                  >

                    <option
                      className={this.props.classes.bodyText_font}
                      value="" disabled>
                      Select Class
                    </option>
                    {this.state.classList.map((item: any) => {
                      return (
                        <option value={item.id}>
                          {item.attributes?.display_name}
                        </option>
                      );
                    })}
                  </NativeSelect>

                  <NativeSelect
                    className={`AdminFeedbackReviewDropDown ${this.props.classes.bodyText_font}`}
                    id="select"
                    data-test-id={"select-reviewType"}
                    value={this.state.reviewType}
                    //input={<BootstrapInput5 />}
                    classes={{
                      root: this.props.classes.AdminFeedbackClassesAndSectionBootstrapRoot,
                      select: this.props.classes.AdminFeedbackClassesAndSectionBootstrapInput
                    }}
                    disableUnderline
                    onChange={(event) => {
                      this.setState({
                        reviewType: event.target.value
                      }, () => {
                        if (this.state.reviewType !== "") {
                          this.get_school_period_list();
                        }
                      });
                    }}
                  >
                    <option className={this.props.classes.bodyText_font} value="" disabled>
                      Select Review
                    </option>
                    <option className={this.props.classes.bodyText_font} value="Amazing">
                      Amazing
                    </option>
                    <option className={this.props.classes.bodyText_font} value="Okay">
                      Okay
                    </option>
                    <option className={this.props.classes.bodyText_font} value="Bad">
                      Bad
                    </option>

                  </NativeSelect>
                </Box>

                <Grid
                  container
                  style={{
                    backgroundColor: "#ded9ff",
                    borderRadius: 6,
                  }}
                >
                  <Grid item xs={3}>
                    <Box
                      className={this.props.classes.heading_font}
                      style={{
                        padding: "17px 0px 16px 0px",
                        textAlign: "center",
                        fontSize: "1.25rem",
                        fontWeight: 600,
                      }}
                    >
                      Period Name
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box
                      className={this.props.classes.heading_font}
                      style={{
                        padding: "17px 0px 16px 0px",
                        textAlign: "center",
                        fontSize: "1.25rem",
                        fontWeight: 600,
                      }}
                    >
                      Subject
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box
                      className={this.props.classes.heading_font}
                      style={{
                        padding: "17px 0px 16px 0px",
                        textAlign: "center",
                        fontSize: "1.25rem",
                        fontWeight: 600,
                      }}
                    >
                      Teacher
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box
                      className={this.props.classes.heading_font}
                      style={{
                        padding: "17px 0px 16px 0px",
                        textAlign: "center",
                        fontSize: "1.25rem",
                        fontWeight: 600,
                      }}
                    >
                      Student
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box
                      className={this.props.classes.heading_font}
                      style={{
                        padding: "17px 0px 16px 0px",
                        textAlign: "center",
                        fontSize: "1.25rem",
                        fontWeight: 600,
                      }}
                    >
                      Feedback Status
                    </Box>
                  </Grid>
                </Grid>

                {this.state.feedbackData.map((item: any) => {
                  return (
                    <Grid
                      key={item.id}
                      container
                      style={{
                        backgroundColor: "#f9f8fd",
                        borderRadius: 6,
                        marginTop: 10,
                      }}
                    >
                      <Grid item xs={3}>
                        <Box
                          className={this.props.classes.bodyText_font}
                          style={{
                            padding: "17px 0px 16px 0px",
                            textAlign: "center",
                            fontSize: "1.25rem",
                            fontWeight: 600,
                          }}
                        >
                          {item.attributes?.reviewable?.data?.attributes?.topic}
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          className={this.props.classes.bodyText_font}
                          style={{
                            padding: "17px 0px 16px 0px",
                            textAlign: "center",
                            fontSize: "1.25rem",
                            fontWeight: 600,
                          }}
                        >
                          {
                            item.attributes?.reviewable?.data?.attributes?.subject?.data?.attributes?.subject_name
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          className={this.props.classes.bodyText_font}
                          style={{
                            padding: "17px 0px 16px 0px",
                            textAlign: "center",
                            fontSize: "1.25rem",
                            fontWeight: 600,
                          }}
                        >
                          {
                            item.attributes?.reviewable?.data?.attributes?.teacher_name
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          className={this.props.classes.bodyText_font}
                          style={{
                            padding: "17px 0px 16px 0px",
                            textAlign: "center",
                            fontSize: "1.25rem",
                            fontWeight: 600,
                          }}
                        >
                          {item.attributes?.account}
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          className={this.props.classes.bodyText_font}
                          style={{
                            padding: "17px 0px 16px 0px",
                            textAlign: "center",
                            fontSize: "1.25rem",
                            fontWeight: 600,
                          }}
                        >
                          {item.attributes.review_experience}
                        </Box>
                      </Grid>
                      <Grid item xs={1}>
                        {item.attributes?.review_experience === "Bad" &&
                          <Box
                            style={{
                              padding: "17px 0px 16px 0px",
                              textAlign: "center",
                              cursor: "pointer"
                            }}
                            data-test-id={"bad-review-popup-btn"}
                            onClick={() => {
                              this.handlePopupOpen(item.attributes.review_answers);
                            }}
                          >
                            <img height="20" width="20" src={hint} alt="hint_img" />
                          </Box>
                        }
                      </Grid>
                    </Grid>
                  );
                })}

                <Box style={{ textAlign: "end", marginTop: 25 }}>
                  <ReactPaginate
                    previousLabel={"←   Previous"}
                    nextLabel={"Next   →	"}
                    breakLabel="..."
                    initialPage={this.state.pagination_page}
                    pageCount={this.state.pageCount}
                    onPageChange={this.handleFeedbackPagination}
                    containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                    previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                    nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                    disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                    activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                    pageRangeDisplayed={this.state.pageCount}
                    marginPagesDisplayed={0}
                  />
                </Box>
              </Box>
            )}

            {selectedTab === "questions" && (
              <>
                {this.state.questionList.map((item: any, index: number) =>
                  <Box key={index} className="questionList">
                    <p className="questionTitle">{item.question}</p>
                    <div className="iconBox">
                      <div data-test-id={"feedback-delete-question-btn"} onClick={() =>
                        this.setState({ selectedQuestionId: item.id }, () => {
                          this.setState({
                            deletePopup: true,
                          });
                        })
                      } className="iconQuestion">
                        <DeleteOutlined style={{ color: red[600] }} />
                      </div>
                      <div data-test-id={"feedback-update-question-btn"} onClick={() =>
                        this.setState({ selectedUpdateQuestionId: item.id, updateQuestion: item.question }, () => {
                          this.setState({
                            updatePopup: true,
                          });
                        })
                      } className="iconQuestion">
                        <EditOutlined className={this.props.classes.title_font} />
                      </div>
                    </div>
                  </Box>
                )}
                <Box style={{ textAlign: "end", marginTop: 25 }}>
                  <ReactPaginate
                    previousLabel={"←   Previous"}
                    nextLabel={"Next   →	"}
                    breakLabel="..."
                    data-test-id="feedback-pagination"
                    initialPage={this.state.pagination_page_two}
                    pageCount={this.state.pageCount_two}
                    onPageChange={this.handleFeedbackQuestionPagination}
                    containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                    previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                    nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                    disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                    activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                    pageRangeDisplayed={this.state.pageCount_two}
                    marginPagesDisplayed={0}
                  />
                </Box>
              </>
            )}
          </Box>

          <DeletePopup
            openModal={this.state.deletePopup}
            onCloseModal={this.handleCloseDeleteQuestionPopup}
            deleteQuestion={this.delete_question}
          // reviewData = {this.state.review_answers}
          />
          <CreatePopup
            openModal={this.state.openPopup}
            onCloseModal={this.handleCloseCreateQuestionPopup}
            postQuestionList={this.post_question_list}
            handleUpdateQuestionInput={this.handleUpdateQuestion}
            handleCreateQuestionInput={this.handleUpdateQuestion}
          />

          <UpdatePopup
            openModal={this.state.updatePopup}
            updateQuestion={this.state.updateQuestion}
            onCloseModal={this.handleCloseUpdateQuestionPopup}
            updateQuestionList={this.update_question_list}
            handleUpdatedQuestionInput={this.handleUpdateQuestionInput}
          />

          <BadResponsePopup openModal={this.state.openBadResponsePopup} onCloseModal={this.handleCloseBadResponsePopup} reviewData={this.state.review_answers} reviewComment={this.state.feedbackData} />


        </Box>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  AdminFeedback as React.ComponentType<any>
);
// Customizable Area End