// Customizable Area Start
import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../../../shared/Header/src/HOCAuth.web";
import ParentNotificationListController, {
    Props,
} from "./ParentNotificationListController.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import "./ParentNotification.web.css";
import { Divider } from "@material-ui/core";
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class ParentNotificationList extends ParentNotificationListController {
    render() {
        // Customizable Area Start
        const { isLoader, notificationList } = this.state;

        return (
            <>
                {isLoader && <Spinner spinnerModal={isLoader} />}
                <Grid container>
                    <Grid item xs={12}>
                        <div className={`notification_list_header ${this.props.classes.heading_font}`}>
                            Notification
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="notification_list">
                            {notificationList?.map((notification: any) => (
                                <div key={notification.id}>
                                    <div className={`notification_title ${this.props.classes.heading_font}`}>
                                        {notification.name}
                                    </div>
                                    <div className={`notification_desc ${this.props.classes.subTitle_font}`}>
                                        {notification.description}
                                    </div>
                                    <div className={`notification_date ${this.props.classes.subTitle_font}`}>
                                        {notification.date} | {notification.time}
                                    </div>
                                    <Divider className="notification_list_divider" />
                                </div>
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(
    HOCAuth(ParentNotificationList, "TeacherAccount")
);
// Customizable Area End
