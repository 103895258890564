// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const ReportIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M25.334 18.667A6.675 6.675 0 0 1 32 25.333 6.674 6.674 0 0 1 25.334 32a6.675 6.675 0 0 1-6.668-6.667 6.675 6.675 0 0 1 6.668-6.666zm.665 1.374L26 25.333c0 .177-.07.347-.195.472l-3.74 3.74a5.307 5.307 0 0 0 3.268 1.122 5.34 5.34 0 0 0 5.334-5.334 5.342 5.342 0 0 0-4.668-5.292zM19.334 0c.368 0 .666.298.666.667v4.666h2c.369 0 .666.298.666.667v9.333a.666.666 0 1 1-1.332 0V6.667h-18c-.75 0-1.444-.25-2.001-.67V26c0 1.103.897 2 2 2h12a.666.666 0 1 1 0 1.333h-12A3.338 3.338 0 0 1 0 26V3.333A3.338 3.338 0 0 1 3.334 0h16zm5.333 20.041A5.342 5.342 0 0 0 20 25.333c0 1.23.419 2.365 1.122 3.269l3.544-3.545v-5.016zm-6-18.708H3.332c-1.103 0-2 .898-2 2 0 1.103.897 2 2 2h15.333v-4z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default ReportIcon;
// Customizable Area End