// Customizable Area Start
import {
  LinearProgress,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import HOCAuth from "../../../../../shared/Header/src/HOCAuth.web";
import WardAttendanceController, { Props } from "./WardAttendanceController.web";
import "./WardAttendance.web.css"
import CustomCircularProgress from "./CustomCircularProgress.web";
import { themeCreateStyle } from "../../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const BorderLinearProgressPresent = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#c8e6c9',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#4caf50',
  },
}))(LinearProgress);

const BorderLinearProgressAbsent = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#ffccbc',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#ff5722',
  },
}))(LinearProgress);
// Customizable Area End
export class WardAttendance extends WardAttendanceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Grid className={`attendance-main-grid ${this.props.classes.wardAttendance_dashboard_main_grid}`} container direction="row">
        <Grid item xs={12}>
          <Typography
            className={`${this.props.classes.heading_font} attendance-title-text`}
          >
            Overall Attendence
          </Typography>
        </Grid>
        {/* Linear Progress */}
        <Grid item xs={12} sm={8} md={7}>
          <Grid container>
            <Grid item xs={12} className={`attendance-linear-progress-grid`}>
              <div className={`attendance-ratio`}>
                <Typography className={`attendance-fw-600 ${this.props.classes.wardAttendance_dashboard_font_18} ${this.props.classes.subHeading_font}`}>
                  Present
                </Typography>
                <Typography className={`${this.props.classes.wardAttendance_dashboard_font_14} ${this.props.classes.subHeading_font}`}>
                  165/200
                </Typography>
              </div>
              <BorderLinearProgressPresent variant="determinate" value={90} />
            </Grid>
            <Grid item xs={12} className={`attendance-linear-progress-grid`}>
              <div className={`attendance-ratio`}>
                <Typography className={`attendance-fw-600 ${this.props.classes.wardAttendance_dashboard_font_18} ${this.props.classes.subHeading_font}`}>
                  Absent
                </Typography>
                <Typography className={`${this.props.classes.wardAttendance_dashboard_font_14} ${this.props.classes.subHeading_font}`}>
                  4 of 20
                </Typography>
              </div>
              <BorderLinearProgressAbsent variant="determinate" value={10} />
            </Grid>
          </Grid>
        </Grid>
        {/* Linear Progress */}
        {/* Circular Progress */}
        <Grid item xs={12} sm={4} md={5} className={`attendance-circular-progress-grid`}>
          <CustomCircularProgress present={40} absent={60} circular_percentage={65} classes={this.props.classes}/>
        </Grid>
        {/* Circular Progress END */}
      </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(WardAttendance, "TeacherAccount")
);
// Customizable Area End
