//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
// Customizable Area Start
import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Spinner from '../../shared/SideBar/src/Spinner';
import './ForgotPassword.web.css';
import { Box, Hidden, Typography } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { IconButton } from '@material-ui/core';

import {
  img21kSchholLogo,
  imgloginScreenBackground,
  manImage,
  bigCloud,
  smallCloud,
} from './assets';

//@ts-ignore

import NewPasswordController, { Props } from './NewPasswordController.web';
import './NewPassword.web.css';

// Customizable Area End
// Customizable Area Start
const root = {
  flexGrow: 1,
  height: '100%',
} as const;
const loginImageDiv = {
  position: 'relative',
  // height: "100vh",
} as const;
const halfcircleImage = {
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(142, 123, 253, 1)',
  position: 'absolute',
  zIndex: 0,
  objectFit: 'cover',
} as const;
const logoImage = {
  width: 'auto',
  height: '68px',
  position: 'relative',
  margin: '33px 29px 29px 30px',
} as const;
const textlabel_quoteText = {
  color: 'rgba(255, 255, 255, 1)',
  fontWeight: 'bold',
  position: 'relative',
  fontFamily: 'Open sans',
  fontSize: '4.5rem',
  margin: '25px 34px',
} as const;
const image_studentillustratorImage = {
  width: '56%',
  height: 'auto',
  bottom: '0%',
  position: 'absolute',
  transform: 'translateX(105%)',
} as const;
const image_cloudsmallImage = {
  width: '65px',
  height: '51px',
  position: 'absolute',
  top: '61%',
  left: '30%',
} as const;
const image_cloudbigImage = {
  width: '151px',
  height: '117px',
  bottom: '10%',
  left: '8%',
  position: 'absolute',
} as const;
const attrbuted_textlabel_div = {
  textAlign: 'right',
  fontFamily: 'Open sans',
  fontSize: '16px',
  // marginTop: "10px",
  // padding: "13px 19.5px 14px 19px",
  // margin: "50px 0px 36px 146.1px",
  // width: "60%"
  margin: '5% 0px 4% 0px',
} as const;
const attrbuted_textlabel_signinText = {
  color: 'rgba(3, 1, 76, 1)',
} as const;
const attrbuted_textlabel_signinText2 = {
  color: 'rgba(0, 0, 0, 1)',
  fontWeight: 'bold',
  marginLeft: '10px',
  marginRight: '5px',
} as const;
const loginDiv = {
  justifyContent: 'center',
  // margin: "auto",
  width: '60%',
  // paddingLeft: "30px",
  margin: '5% 0px 4% 0px',
} as const;
const textlabel_welcomeText = {
  fontSize: '35.1px',
  fontWeight: 'bold',
  marginTop: '10px',
  fontFamily: 'Open sans',
  paddingTop: '20%',
} as const;
const textlabel_followinstructionText = {
  color: '#03014c',
  fontSize: '14px',
  fontFamily: 'Open sans',
  margin: '20px 0 28px 0',
  opacity: 0.5,
} as const;
const formDiv = {
  marginTop: '20px',
  width: '100%',
} as const;
const textlabel_loginText = {
  fontWeight: 'bold',
  color: 'rgba(255, 255, 255, 1)',
  marginTop: '20px',
  width: '100%',
  backgroundColor: 'rgba(142, 123, 253, 1)',
  padding: '10px',
  marginBottom: '20px',
} as const;
const forrmInput_textfield = {
  width: '100%',
  borderRadius: '8.8px',
  marginTop: '20px',
} as const;
const spinnerBar = {
  color: 'rgba(255, 255, 255, 1)',
} as const;
// Customizable Area End

export default class NewPassword extends NewPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const pswdspaceReg = /^\S$|^\S[\s\S]*\S$/;

    return (
      <div style={{ height: '100vh' }}>
        {this.state.loadingSpinner && (
          <Spinner spinnerModal={this.state.loadingSpinner} />
        )}
        <div style={root}>
          <Grid container style={{ height: '100%' }}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={6}
              spacing={0}
              style={loginImageDiv}
            >
              <img
                src={imgloginScreenBackground}
                style={halfcircleImage}
                alt="Background Image"
              />
              <img
                src={manImage}
                alt="Student Illustrator Image"
                style={image_studentillustratorImage}
                className="student_illustrator_img"
              />
              <div>
                <img
                  className="SchholLogo"
                  src={img21kSchholLogo}
                  alt="Logo Image"
                  style={logoImage}
                />
              </div>
              <div style={textlabel_quoteText} className="learn_together_text">
                <Hidden only={['lg', 'xl']}>Let’s learn Together</Hidden>
                <Hidden only={['xs', 'sm', 'md']}>
                  Let’s learn
                  <br />
                  Together
                </Hidden>
              </div>
              <img
                src={smallCloud}
                alt="Small Cloud Image"
                style={image_cloudsmallImage}
                className="cloud_small_img"
              />
              <img
                src={bigCloud}
                alt="Big Cloud Image"
                style={image_cloudbigImage}
                className="cloud_big_img"
              />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={6}>
              <Grid container className="main_right_div">
                <Grid item xs={12} style={loginDiv} className="login_div">
                  <form onSubmit={this.handleSubmit}>
                    <div
                      style={attrbuted_textlabel_div}
                      className="attributed_textlabel"
                    >
                      <span style={attrbuted_textlabel_signinText}>
                        Remember Password ?
                      </span>
                      <Link
                        href="/EmailAccountLoginBlock"
                        style={attrbuted_textlabel_signinText2}
                      >
                        Login
                      </Link>
                    </div>
                    <div>
                      <div
                        style={textlabel_welcomeText}
                        className="welcome_text"
                      >
                        Create New Password
                      </div>
                      <Typography style={textlabel_followinstructionText}>
                        Follow the instructions to make it easier to register
                        and you will be able to explore inside.
                      </Typography>
                    </div>
                    <div style={formDiv}>
                      <div className="ios" style={{ marginBottom: '20px', }}>
                        <div style={{ position: 'relative' }}>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'start',
                              position: 'relative'
                            }}
                          >
                            <TextField
                              type={this.state.pswdVisible ? 'text' : 'password'}
                              placeholder="Password"
                              error={this.state.pswderror}
                              variant="outlined"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ marginRight: '0' }}
                                  >
                                    <LockOpenOutlinedIcon
                                      fontSize="small"
                                      className="input_start_adornment_icon"
                                    />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.togglePasswordVisible}
                                    >
                                      {this.state.pswdVisible ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                className: 'forrmInput_textfield',
                              }}
                              style={{
                                width: '100%',
                              }}
                              className="input_focus_pass"
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const value = event.target.value.trim();
                                if (event.target.value.length === 0) {
                                  this.setState({
                                    pswderror: true,
                                    openToolTip: false,
                                    pswderrorText: 'Password is required.',
                                  });
                                } else if (
                                  !pswdspaceReg.test(event.target.value)
                                ) {
                                  this.setState({
                                    pswderror: true,
                                    openToolTip: false,
                                    pswderrorText:
                                      "Your password can't start or end with a blank space.",
                                  });
                                } else if (value.length > 0) {
                                  if (
                                    !passwordReg.test(value.replace(' ', '@'))
                                  ) {
                                    this.setState({
                                      pswderror: true,
                                      openToolTip: true,
                                    });
                                  } else if (
                                    passwordReg.test(value.replace(' ', '@'))
                                  ) {
                                    this.setState({
                                      pswderror: false,
                                      openToolTip: false,
                                      pswderrorText: '',
                                    });
                                  } else {
                                    this.setState({
                                      pswderror: false,
                                      openToolTip: false,
                                      pswderrorText: '',
                                    });
                                  }
                                }
                                if (this.state.confirmPassword.length > 0) {
                                  if (
                                    event.target.value ===
                                    this.state.confirmPassword
                                  ) {
                                    this.setState({
                                      cpswderror: false,
                                      openToolTip: false,
                                      cpswderrorText: '',
                                    });
                                  }
                                }

                                this.setState({ password: event.target.value });
                              }}
                              onBlur={() => {
                                if (
                                  this.state.password === null ||
                                  this.state.password.length === 0
                                ) {
                                  this.setState({
                                    pswderror: true,
                                    openToolTip: false,
                                    pswderrorText: 'Password is required.',
                                  });
                                } else if (
                                  !pswdspaceReg.test(this.state.password)
                                ) {
                                  this.setState({
                                    pswderror: true,
                                    pswderrorText:
                                      "Your password can't start or end with a blank space.",
                                  });
                                } else if (
                                  !passwordReg.test(
                                    this.state.password.replace(' ', '@')
                                  )
                                ) {
                                  this.setState({
                                    pswderror: true,
                                    openToolTip: true,
                                  });
                                } else {
                                  this.setState({
                                    pswderror: false,
                                    pswderrorText: '',
                                  });
                                }
                                if (this.state.confirmPassword.length > 0) {
                                  if (
                                    this.state.password !=
                                    this.state.confirmPassword
                                  ) {
                                    this.setState({
                                      cpswderror: true,
                                      openToolTip: false,
                                      cpswderrorText:
                                        'Password and Confirm password must match.',
                                    });
                                  }
                                }
                              }}
                            />
                          </div>
                          {this.state.pswderror && (
                            <FormHelperText
                              style={{ color: 'red', margin: '3px 14px 0' }}
                            >
                              {this.state.pswderrorText}
                            </FormHelperText>
                          )}

                          {this.state.openToolTip &&
                            <Box
                              style={{
                                padding: '10px',
                                top: "110%",
                                boxShadow: '0 0 10px 0 rgb(30 28 46 / 8%)',
                                background: "white", color: "black", width: '96%', position: 'absolute', borderRadius: '8.8px', zIndex: 1,
                              }}>
                              <Typography style={{ fontSize: 18, textAlign: 'start', fontFamily: "Open sans", margin: '20px 0px' }}>
                                Password must:
                              </Typography>
                              <ul style={{ textAlign: 'start', fontFamily: "Open sans" }}>
                                <li>Have at least 8 characters.</li>
                                <li>Have at least 1 letter(a,b,c...).</li>
                                <li>Have at least 1 number(1,2,3...).</li>
                                <li>Have at least 1 special character(@,#,$...).</li>
                                <li>Include both uppercase and lowercase characters.</li>
                              </ul>
                            </Box>
                          }
                        </div>
                      </div>
                      <div style={{ marginBottom: '35px' }}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'start',
                          }}
                        >
                          {/* <div
                            style={{
                              // width: "20%",
                              textAlign: 'center',
                              padding: '25px 15px',
                              borderRadius: '8.8px 0px 0px 8.8px',
                              backgroundColor: '#f1f1f1',
                              // opacity: 0.5,
                              border: !this.state.cpswderror
                                ? '1px solid #cfcfcf'
                                : '1px solid red',
                            }}
                            className="input_pass_icon"
                          >
                            <LockOpenOutlinedIcon
                              style={{ fontSize: '20px', opacity: 0.4 }}
                            />
                          </div> */}
                          <TextField
                            variant="outlined"
                            type={this.state.cpswdVisible ? 'text' : 'password'}
                            placeholder="Confirm Password"
                            style={{
                              // width: '98%',
                              // borderRadius: '0px 8.8px 8.8px 0px',
                              // height: '72px',
                              // textAlign: 'start',
                              // color: '#03014c',
                              // // opacity: 0.5,
                              // backgroundColor: '#f1f1f1',
                              // fontSize: '15.8px',
                              // fontFamily: 'Open sans',
                              // border: !this.state.cpswderror
                              //   ? '1px solid #cfcfcf'
                              //   : '1px solid red',
                              width: '100%',
                            }}
                            error={this.state.cpswderror}
                            className="input_focus_pass"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginRight: '0' }}
                                >
                                  <LockOpenOutlinedIcon
                                    fontSize="small"
                                    className="input_start_adornment_icon"
                                  />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.toggleConfirmPasswordVisible}
                                  >
                                    {this.state.cpswdVisible ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              // style: {
                              //   fontSize: "15.8px",
                              //   color: "#03014c",
                              //   fontFamily: "OpenSans",
                              //   opacity: 0.5,
                              //   paddingRight: "27px",
                              //   // border: "1px solid #cfcfcf",
                              //   backgroundColor: "#f1f1f1",
                              //   paddingLeft: "0px",
                              // },
                              className: 'forrmInput_textfield',
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (event.target.value.length === 0) {
                                this.setState({
                                  cpswderror: true,
                                  cpswderrorText:
                                    'Confirm Password is required.',
                                });
                              } else if (
                                !pswdspaceReg.test(event.target.value)
                              ) {
                                this.setState({
                                  cpswderror: true,
                                  cpswderrorText:
                                    "Your password can't start or end with a blank space.",
                                });
                              } else if (event.target.value.trim().length > 0) {
                                if (this.state.password != event.target.value) {
                                  this.setState({
                                    cpswderror: true,
                                    cpswderrorText:
                                      'Password and Confirm password must match. ',
                                  });
                                } else {
                                  this.setState({
                                    cpswderror: false,
                                    cpswderrorText: '',
                                  });
                                }
                              }
                              this.setState({
                                confirmPassword: event.target.value,
                              });
                            }}
                            onBlur={() => {
                              if (
                                this.state.confirmPassword === null ||
                                this.state.confirmPassword.length === 0
                              ) {
                                this.setState({
                                  cpswderror: true,
                                  cpswderrorText:
                                    'Confirm Password is required.',
                                });
                              } else if (
                                !pswdspaceReg.test(this.state.confirmPassword)
                              ) {
                                this.setState({
                                  pswderror: true,
                                  pswderrorText:
                                    "Your password can't start or end with a blank space.",
                                });
                              } else if (
                                this.state.password !=
                                this.state.confirmPassword
                              ) {
                                this.setState({
                                  cpswderror: true,
                                  cpswderrorText:
                                    'Password and Confirm password must match. ',
                                });
                              } else {
                                this.setState({
                                  cpswderror: false,
                                  cpswderrorText: '',
                                });
                              }
                            }}
                          />
                        </div>

                        {this.state.cpswderror && (
                          <FormHelperText
                            style={{ color: 'red', margin: '3px 14px 0' }}
                          >
                            {this.state.cpswderrorText}
                          </FormHelperText>
                        )}
                      </div>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      // style={textlabel_loginText}
                      className="textlabel_loginText xs-mb-25"
                    // onClick={() => { this.handleSubmit() }}
                    >
                      Send
                    </Button>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
