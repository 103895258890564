// Customizable Area Start
import { ITimeTableEventsProps } from "../TeacherTimeTableController.web";
import React, { useState } from "react";
import { image_info, image_lunch_time, subjectIcon, video_camera_live, information, clock, blackBoard } from "../assets";
import { I_Support, } from "../../assets";
import { Typography, Button, Grid } from "@material-ui/core";
import moment from "moment";
import './TimeTableEventWrapper.web.css';
import EventToolTip from "./EventToolTip.web";
// Customizable Area End

// Customizable Area Start
interface IDayEvent {
  event: ITimeTableEventsProps;
  isTeacher: boolean;
  joinMeeting: () => void;
  getTimeTableUpdatedData?: any;
}
// Customizable Area End


// Customizable Area Start
const DayEvent: React.FC<IDayEvent> = ({
  event,
  isTeacher,
  joinMeeting,
  getTimeTableUpdatedData
}: IDayEvent) => {
  const {
    title,
    subject,
  } = event as ITimeTableEventsProps;
  const duration = moment.duration(moment(event.end).diff(moment(event.start)));
  const hours = duration.asHours();
  const lectureEndTime = moment(event.end).format("DD MMM YYYY HH:mma");
  const today = moment();
  const name = moment(event.start).diff(moment(new Date()), 'minutes') >= 10
  const byname = moment(lectureEndTime, "DD MMM YYYY HH:mma").isBefore(today)
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleClose = (save: boolean) => {
    setAnchorEl(null);
  };
  const savehandleClose = () => {
    setAnchorEl(null);
    getTimeTableUpdatedData()

  };
  const handleModalClick = (event: any) => {
    setAnchorEl(event.currentTarget);

  };

  const defaultCss: any = {
    border: 'linear-gradient(to top, #af38cb, #471c51)',
    background: "linear-gradient(to top, #af38cb, #471c51),linear-gradient(to top, #af38cb, #471c51)"
  }

  const ongoing: any = {
    border: '#3dbb3d',
    background: "#3dbb3d",
  }
  const ended: any = {
    border: '#999999',
    background: "#999999",
    color: '#999999'
  }
  return (
    <>
      {event.type === "lunchTime" ? (
        <div
          className="TimeTableEventWrapperLunchTimeMainDiv day-event"
        >
          <img src={image_lunch_time} className='TimeTableEventWrapperLunchTimeImg' />
          <div
            className="TimeTableEventWrapperLunchTimeTitleTxt"
          >
            {title}
          </div>
        </div>
      ) : (
        <Grid
          container
          className="TimeTableEventWrapperMainSubDiv day-event"
        >
          <Grid
            item
            xs={1}
            className='TimeTableEventWrapperSubjectImageDiv'
          >
            <span
              className="TimeTableEventWrapperSubjectImageSubDiv"
            >
              {event.isEvent ?
                <img
                  style={{
                    height: hours >= 1 ? "40px" : "30px",
                    width: hours >= 1 ? "40px" : "30px",
                    alignSelf: "center",
                  }}
                  src={I_Support}
                /> :
                <img
                  style={{
                    height: hours >= 1 ? "40px" : "30px",
                    width: hours >= 1 ? "40px" : "30px",
                    alignSelf: "center",
                  }}
                  src={
                    event.subjectImage == undefined ? subjectIcon : event.subjectImage
                  }
                />
              }

            </span>
          </Grid>
          <Grid item xs={3} className='TimeTableEventWrapperFlexDiv'>
            <Grid
              item
              xs={6}
              className='TimeTableEventWrapperFlexSubDiv'
            >
              <div
                className="TimeTableEventWrapperClassTitle"
              >
                {event.isEvent ? "" : subject}
              </div>
              <div
                className="TimeTableEventWrapperClassTxt"
              >
                {/* {event.class} */}
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              className='TimeTableEventWrapperEventDiv'
            >
              {
                <>

                  <img
                    className="TimeTableEventWrapperEventImg"
                    src={event.is_online ? video_camera_live : blackBoard}
                  />
                  {hours >= 1 && <div
                    style={{
                      color: isTeacher ? "#471c51" : "#471c51",
                    }}
                    className='TimeTableEventWrapperEventTxt'
                  >
                    {event.is_online ? "Online Class" : "Offline Class"}
                  </div>}

                </>

              }
            </Grid>
          </Grid>

          <Grid
            item
            xs={3}
            className='TimeTableEventWrapperEventTitleDiv'
          >
            <div
              style={{
                color: title === "Live Class" ? event.borderColor : "#3f526d",
              }}
              className='TimeTableEventWrapperEventTitleTxt'
            >
              {event.isEvent ? event.title : title}

            </div>
            <div
              style={{
                color: title === "Live Class" ? event.borderColor : "#3f526d",
              }}
              className='TimeTableEventWrapperEventTitleTxt'
            >
              {event.isEvent ? "" : ''}
            </div>
          </Grid>
          <Grid item xs={1} className='TimeTableEventWrapperInfoImgDiv'>
            <img
              className="TimeTableEventWrapperInfoImg"
              src={isTeacher ? information : image_info}
              onClick={(event: any) => handleModalClick(event)}
            />
          </Grid>

          <Grid item xs={4}
            className='TimeTableEventWrapperDurationDiv'>
            <Typography
              component="span"
              className="TimeTableEventWrapperDurationTxt"
            >
              <img
                className="TimeTableEventWrapperDurationImg"
                src={clock}
              />
              <span
                className="TimeTableEventWrapperDurationTitleTxt"
              >{`Duration: ${hours.toFixed(2)} hour`}</span>
            </Typography>
            <div>
              {event?.is_online &&

                <Button
                  className={isTeacher ?
                    // "btn_joinnow" 
                    " view_joinnow"
                    :
                    "view_joinnow"

                  }
                  style={{
                    fontFamily: "Open sans",
                    height: hours >= 1 ? 48 : 36,
                    background:
                      event.status == 'ongoing' ? ongoing.background :
                        event.status == "ended" ? ended.background :
                          defaultCss.background,

                    border:
                      event.status == 'ongoing' ? ongoing.border :
                        event.status == "ended" ? ended.border :
                          defaultCss.border,

                    opacity:
                      event?.status &&
                        (isTeacher ?
                          event?.status && event.status === 'upcoming' && (!byname && !name) ||
                          event?.status && event.status === 'ongoing'
                          : event?.status && event.status === 'ongoing') ? 1 : 0.4
                  }}
                  onClick={joinMeeting}
                  disabled={
                    event?.status &&
                      (isTeacher ?
                        event?.status && event.status === 'upcoming' && (!byname && !name) ||
                        event?.status && event.status === 'ongoing'
                        : event?.status && event.status === 'ongoing') ? false : true
                  }

                >
                  <Typography className="label_joinnow">

                    {byname ? (event.status === 'ongoing' ? 'Ongoing' :
                      event.status === "upcoming" ? isTeacher ? 'Start Class' :
                        isTeacher ? 'Start Class' : "Join Now" : 'End Class')

                      : (event.status && event.status === 'ongoing' ? 'Ongoing'
                        : event.status && event.status === 'upcoming' &&
                          (!byname && !name) &&
                          isTeacher ? 'Start Class' : isTeacher ? 'Start Class' : "Join Now")}
                  </Typography>

                </Button>
              }
            </div>
          </Grid>
          <EventToolTip
            event={event}
            anchorEl={anchorEl}
            handleClose={handleClose}
            isTeacher={isTeacher}
            savehandleClose={savehandleClose}
            joinMeeting={joinMeeting}
          />
        </Grid>
      )}
    </>
  );
};
// Customizable Area End

// Customizable Area Start
export default DayEvent;
// Customizable Area End
