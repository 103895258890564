// Customizable Area Start
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { TextField, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import StudentProfileDetailsController, { Props } from './StudentProfileDetailsController.web';
import Spinner from '../../../shared/SideBar/src/Spinner';
import { schoolLogo } from '../assets';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start

function createData(title: string, term: number, term2: number, term3: number) {
    return { title, term, term2, term3 };
}

const rows = [
    createData('Total Attendance of the learner', 20, 10, 5),
    createData('Total Number of working days', 20, 10, 4.0)
];
// Customizable Area End

export class StudentProfileDetails extends StudentProfileDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { schoolData, isLoader, schoolLogoUrl, termListData, data, profileDetails, secondAttendanceRow, thirdAttendanceRow } = this.state
        return (
            <>
                {isLoader && <Spinner spinnerModal={isLoader} />}
                <div className='profileContent teacherProfileContent'>
                <div className='topHeader mb10'>
                    <div className='schoolLogo'>
                        <img src={schoolLogoUrl ? schoolLogoUrl : schoolLogo} />
                    </div>
                    <div className='schoolTitle'>
                        <h3 className={this.props.classes.heading_font}>
                        {schoolData?.attributes?.name}</h3>
                    </div>
                    <span className="commonBtnPrimary">Report Card - {`${profileDetails?.attributes?.school_class?.data?.attributes?.class_name || ''}`}</span>
                </div>

                    <div className={`${this.props.classes.heading_font} student-profile-header`}>
                        Student Profile
                    </div>

                    <Grid container alignItems="flex-start" spacing={4}>
                        <Grid className="studentProfile" item xs={12} lg={2}>
                            <div className="student-profile-photo-div">
                                <Avatar src={profileDetails?.attributes?.avatar ? profileDetails?.attributes?.avatar : ""} className="student-profile-photo" />
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={10}>
                            <Box className="profileForm textLeft">
                                <form noValidate autoComplete="off">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Name</label>
                                            <TextField
                                                fullWidth
                                                placeholder='Name'
                                                variant="outlined"
                                                value={`${profileDetails?.attributes?.first_name} ${profileDetails?.attributes?.last_name}`}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={4} sm={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Class</label>
                                            <TextField
                                                fullWidth
                                                placeholder='Class Name'
                                                variant="outlined"
                                                value={`${profileDetails?.attributes?.school_class?.data?.attributes?.class_name}`}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4} sm={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Section</label>
                                            <TextField
                                                fullWidth
                                                placeholder='Class Name'
                                                variant="outlined"
                                                value={`${profileDetails?.attributes?.school_class?.data?.attributes?.divisions?.data?.attributes?.name}`}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4} sm={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>DOB</label>
                                            <div className="datePicker">
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        format="MM/dd/yyyy"
                                                        id="date-picker-inline"
                                                        value={new Date(profileDetails?.attributes?.date_of_birth)}
                                                        onChange={() => { }}
                                                        disabled
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        inputProps={{
                                                            className: `${this.props.classes.heading_font} detail-field-style`
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                       
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Class Teacher's Name</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Class Teacher's Name"
                                                variant="outlined"
                                                value={this.state.teacherName}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Residential Address</label>
                                            <TextField
                                                fullWidth
                                                placeholder='Residential Address'
                                                variant="outlined"
                                                value={profileDetails?.attributes?.residential_address}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Mother's Name</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Mother's Name"
                                                variant="outlined"
                                                value={profileDetails?.attributes?.mother_name}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Father's / Guardian's Name</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Father's / Guardian's Name"
                                                variant="outlined"
                                                value={profileDetails?.attributes?.father_name}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Email ID</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Email ID"
                                                variant="outlined"
                                                value={profileDetails?.attributes?.email}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <label className={`${this.props.classes.heading_font} detail-field-title`}>Mobile No.</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Mobile No."
                                                variant="outlined"
                                                value={profileDetails?.attributes?.phone_number}
                                                disabled
                                                inputProps={{
                                                    className: `${this.props.classes.heading_font} detail-field-style`
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Grid>
                    </Grid>

                    <TableContainer component={Paper} className="ReportAttendanceTableContainer">
                        <Table className="ReportAttendanceTable" aria-label="customized table">
                            <TableHead className={this.props.classes.ReportCardDetails_Feedback_background_color}>
                                <TableRow>
                                    <TableCell className={`${this.props.classes.ReportCardDetails_Feedback_tableRowCell} attendance-title`}>Attendance</TableCell>
                                    {termListData?.map((data: any, termIndex: number) => (
                                        <TableCell align="center" key={termIndex} className={`${this.props.classes.ReportCardDetails_Feedback_tableRowCell} attendance-title`}>{data}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row: any, index: number) => (
                                    <TableRow className='tableRow' key={index}>
                                        <TableCell scope="row" className={`${this.props.classes.heading_font} attendance-details`}>
                                            {row.title}
                                        </TableCell>
                                        {index === 0 && secondAttendanceRow?.map((data: any, secondIndex: number) => (
                                            <TableCell align="center" key={secondIndex}> {data}</TableCell>
                                        ))}
                                        {index === 1 && thirdAttendanceRow?.map((data: any, thirdIndex: number) => (
                                            <TableCell align="center" key={thirdIndex}> {data}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>                   
                </div>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(StudentProfileDetails as React.ComponentType<any>);
// Customizable Area End