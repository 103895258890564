// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HOCAuth from '../../../shared/Header/src/HOCAuth.web';
import { HISTORY } from '../../../../components/src/comman';
import {
  List,
  ListItem,
  FormControl,
  InputLabel,
  TextField,
  Box,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
} from '@material-ui/core';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);
// Customizable Area End

// Customizable Area Start
function EvaluationFourFive(props: any) {
  const classes = useStyles();
  const handleBack = () => {
    HISTORY.goBack();
  };
  return (
    <>
      <div className="profileTitle">
        <Button className="backBtn" onClick={() => handleBack()}>
          <KeyboardBackspaceOutlinedIcon /> Back
        </Button>
        <h2 className={props.classes.heading_font}>
          Part 2 - Assessment of Learning{' '}
        </h2>
        <Button className="editBtn" onClick={() => handleBack()}>
          Next <KeyboardBackspaceOutlinedIcon />
        </Button>
      </div>
      <div className="tableContent">
        <div className={classes.root}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={props.classes.subHeading_font}>
                Evaluation 1
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table stickyHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className={`${props.classes.bodyText_font} ${
                          props.classes
                            .evaluationFourFive_classAndSection_tableTopCell
                        } table_head_cell_text`}
                        style={{ borderRadius: '0px' }}
                      >
                        Specific Participation
                      </TableCell>
                      <TableCell
                        className={
                          props.classes
                            .evaluationFourFive_classAndSection_border_Cell
                        }
                        style={{ borderRadius: '0px' }}
                      />
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${props.classes.bodyText_font} ${
                          props.classes
                            .evaluationFourFive_classAndSection_tableTopCell
                        } table_head_cell_text`}
                        style={{ borderRadius: '0px' }}
                      >
                        General Remarks
                      </TableCell>
                      <TableCell
                        className={
                          props.classes
                            .evaluationFourFive_classAndSection_border_Cell
                        }
                        style={{ borderRadius: '0px' }}
                      />
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${props.classes.bodyText_font} ${
                          props.classes
                            .evaluationFourFive_classAndSection_table_cell
                        }`}
                        style={{ borderRadius: '0px' }}
                      >
                        Attendence
                      </TableCell>
                      <TableCell
                        className={
                          props.classes
                            .evaluationFourFive_classAndSection_border_Cell
                        }
                        style={{ borderRadius: '0px' }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} lg={6}>
                            <Typography align="left">
                              {' '}
                              No. of Days Present <TextField />
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <Typography align="right">
                              Total No. of Working Days <TextField />
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={props.classes.bodyText_font}
                        style={{
                          borderRadius: '0px',
                          border: '1px solid #D9D9D9',
                          borderRight: '0px',
                        }}
                      >
                        Class Teachers
                      </TableCell>
                      <TableCell
                        style={{
                          borderRadius: '0px',
                          borderRight: '1px solid #D9D9D9',
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} lg={6}>
                            <Typography
                              align="left"
                              style={{ paddingLeft: '100px' }}
                            >
                              {' '}
                              Principal / ACo
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <Typography style={{ paddingLeft: '250px' }}>
                              {' '}
                              Parent
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={props.classes.subHeading_font}>
                Evaluation 2
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table stickyHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className={`${props.classes.bodyText_font} ${
                          props.classes
                            .evaluationFourFive_classAndSection_tableTopCell
                        } table_head_cell_text`}
                        style={{ borderRadius: '0px' }}
                      >
                        Specific Participation
                      </TableCell>
                      <TableCell
                        className={
                          props.classes
                            .evaluationFourFive_classAndSection_border_Cell
                        }
                        style={{ borderRadius: '0px' }}
                      />
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${props.classes.bodyText_font} ${
                          props.classes
                            .evaluationFourFive_classAndSection_tableTopCell
                        } table_head_cell_text`}
                        style={{ borderRadius: '0px' }}
                      >
                        General Remarks
                      </TableCell>
                      <TableCell
                        className={
                          props.classes
                            .evaluationFourFive_classAndSection_border_Cell
                        }
                        style={{ borderRadius: '0px' }}
                      />
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${props.classes.bodyText_font} ${
                          props.classes
                            .evaluationFourFive_classAndSection_table_cell
                        }`}
                        style={{ borderRadius: '0px' }}
                      >
                        Attendence
                      </TableCell>
                      <TableCell
                        className={
                          props.classes
                            .evaluationFourFive_classAndSection_border_Cell
                        }
                        style={{ borderRadius: '0px' }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} lg={6}>
                            <Typography align="left">
                              {' '}
                              No. of Days Present <TextField />
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <Typography align="right">
                              Total No. of Working Days <TextField />
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={props.classes.bodyText_font}
                        style={{
                          borderRadius: '0px',
                          borderRight: '0px',
                          border: '1px solid #D9D9D9',
                        }}
                      >
                        Class Teachers
                      </TableCell>
                      <TableCell
                        style={{
                          borderRadius: '0px',
                          borderRight: '1px solid #D9D9D9',
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} lg={6}>
                            <Typography
                              align="left"
                              style={{ paddingLeft: '100px' }}
                            >
                              {' '}
                              Principal / ACo
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <Typography style={{ paddingLeft: '250px' }}>
                              {' '}
                              Parent
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          {/* table 3 start Evalutaion 3*/}
          <Box style={{ marginTop: '30px' }}>
            <Box
              style={{
                textAlign: 'center',
                paddingTop: '20px',
                paddingBottom: '30px',
              }}
            >
              <Typography>
                Congratulations! Promoted to Class <TextField />
              </Typography>
            </Box>
            <Box style={{ textAlign: 'center', paddingBottom: '30px' }}>
              <Typography>
                {' '}
                New Session begins on <TextField />
              </Typography>
            </Box>
            <Box style={{ paddingBottom: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    align="left"
                    className={props.classes.bodyText_font}
                    style={{ paddingLeft: '20px' }}
                  >
                    {' '}
                    Class Teachers{' '}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  Principal / ACo
                </Grid>
                <Grid item xs={4}>
                  Parent
                </Grid>
              </Grid>
            </Box>
          </Box>
          <TableContainer>
            <Table stickyHeader>
              <TableBody>
                <TableRow>
                  <TableCell
                    className={
                      props.classes
                        .evaluationFourFive_classAndSection_table_cell
                    }
                    style={{ borderRadius: '0px' }}
                  >
                    A+
                  </TableCell>
                  <TableCell
                    className={
                      props.classes
                        .evaluationFourFive_classAndSection_table_cell
                    }
                    style={{ borderRadius: '0px' }}
                  >
                    Outstanding
                  </TableCell>
                  <TableCell style={{ borderRadius: '0px', border: '0px' }}>
                    1)The Objective of the achievement record is to facilate
                    holistic learning in the school in a street free
                    environment.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={
                      props.classes
                        .evaluationFourFive_classAndSection_table_cell
                    }
                    style={{ borderRadius: '0px' }}
                  >
                    A
                  </TableCell>
                  <TableCell
                    className={
                      props.classes
                        .evaluationFourFive_classAndSection_table_cell
                    }
                    style={{ borderRadius: '0px' }}
                  >
                    Excellent
                  </TableCell>
                  <TableCell style={{ borderRadius: '0px', border: '0px' }}>
                    2)The Focus is on identifying the talents of the learner and
                    to empower him/her positive inputs.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={
                      props.classes
                        .evaluationFourFive_classAndSection_table_cell
                    }
                    style={{ borderRadius: '0px' }}
                  >
                    B
                  </TableCell>
                  <TableCell
                    className={
                      props.classes
                        .evaluationFourFive_classAndSection_table_cell
                    }
                    style={{ borderRadius: '0px' }}
                  >
                    Very Good
                  </TableCell>
                  <TableCell style={{ borderRadius: '0px', border: '0px' }}>
                    3)The boards recommendeds a five points scale for indicating
                    the achievement in the following order.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={
                      props.classes
                        .evaluationFourFive_classAndSection_table_cell
                    }
                    style={{ borderRadius: '0px' }}
                  >
                    C
                  </TableCell>
                  <TableCell
                    className={
                      props.classes
                        .evaluationFourFive_classAndSection_table_cell
                    }
                    style={{ borderRadius: '0px' }}
                  >
                    Good
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={
                      props.classes
                        .evaluationFourFive_classAndSection_table_cell
                    }
                    style={{ borderRadius: '0px' }}
                  >
                    D
                  </TableCell>
                  <TableCell
                    className={
                      props.classes
                        .evaluationFourFive_classAndSection_table_cell
                    }
                    style={{ borderRadius: '0px' }}
                  >
                    Average
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(EvaluationFourFive as React.ComponentType<any>, 'AdminAccount')
);
// Customizable Area End
