// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  error: boolean;
  errorText: string;
  message: '';
  data: any;
  token: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountSucessFullController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sucessfullApiCallId: any;
  emailActivationApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      error: false,
      errorText: "",
      data: "",
      message: '',
      token: ''
    };
    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get('token')
    this.setState({ token }, () => {

      this.emailActivation();
    });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.emailActivationApiCallId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ message: responseJson.meta.message })

          } else {
            const errors: any[] = responseJson.errors[0];

            let allerrors = '';
            const newLocal = JSON.stringify(errors);
            JSON.parse(newLocal, (key, value) => {
              if (value.length > 0) {
                if (allerrors.length <= 0) {
                  allerrors = value;
                } else {
                  allerrors = `${allerrors}{\n}${value}`;
                }
              }
            });
            this.setState({ error: true, errorText: allerrors })
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  backtologinhandle = () => {
    this.props.navigation.navigate('EmailAccountLoginBlock');
  }

  emailActivation() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emailActivationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailActivationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
