// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  CircularProgress, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import AlertModal from "../../../alert/src/AlertModal.web";
import SelectImageModal from "../AddQuestion/SelectImageModal.web";
import TextEditor from "../AddQuestion/TextEditor/TextEditor.web";
import './common.css';
import QuestionSolutionController from "./QuestionSolutionController.web";
export const configJSON = require("../config");
// Customizable Area End

interface Props {
  // Customizable Area Start
  getPreSignedValue: any;
  hintAnswer: any;
  hintAnswerOnchange: any;
  addHintOnClick: any;
  removeHintOnClick: any;
  explainationOnchange: any;
  explainationOnKeyPress?: any;
  explaination: any;
  attachmentExplanationUrl?: any;
  removeSolutionImage: any;
  classes: any;
  // Customizable Area End
}
// Customizable Area Start
const forrmInput_textfield = {
  width: "100%",
  borderRadius: "10px",
  margin: "0px 0px 20px 0px",
  backgroundColor: "#f9f8fd",
} as const;
const forrmInput_hintNumber = {
  width: "100%",
  borderRadius: "10px",
  backgroundColor: "#f9f8fd",
} as const;
// Customizable Area End
export class QuestionSolution extends QuestionSolutionController {
  // Customizable Area Start
  render() {
    return (
      <Box
        className={this.props.classes.container}
      >
        <Typography
          className={`${this.props.classes.subHeading_font} opensans custom-font-size-solution`}
        >
          Solution
        </Typography>
        <Box>
          <Typography
            className={`${this.props.classes.subHeading_font} opensans custom-font-size-explanation`}
          >
            Explanation
          </Typography>
          <div ref={this.explanationEditorRef}>
            <TextEditor
              myref={this.explanationEditorRef}
              handleChange={(value: any) =>
                this.handleOnChangeExplaination(value)
              }
              editorData={this.props.explaination}
            />
          </div>

          <input
            ref={this.inputFile}
            accept="image/png, image/jpg, image/jpeg,"
            className='display-none'
            type="file"
            onChange={(e: any) => {
              let totalSize: number = 0;
              let isAllImageTypeFiles = false;
              var files = e.target.files;
              const keys = Object.keys(files);
              keys?.map(async (key: any) => {
                const file = files[key];
                totalSize = totalSize + file.size;
                let fileExtension = file.name.split(".").pop();
                if (
                  fileExtension === "png" ||
                  fileExtension === "jpg" ||
                  fileExtension === "jpeg"
                ) {
                  isAllImageTypeFiles = true;
                } else {
                  isAllImageTypeFiles = false;
                }
              });
              if (isAllImageTypeFiles) {
                if (e.target.files.length <= 1 && totalSize < 524288000) {
                  this.handleFileOnChange(e, null, "explantation");
                } else {
                  if (e.target.files.length > 1) {
                    this.setState(
                      { alertModalMessage: "You can upload maximum 1 files" },
                      () => {
                        this.setState({ openAlertModal: true });
                      }
                    );
                  } else {
                    this.setState(
                      { alertModalMessage: "Maximum files size: 500MB" },
                      () => {
                        this.setState({ openAlertModal: true });
                      }
                    );
                  }
                }
              } else {
                this.setState(
                  { alertModalMessage: "only image file can be upload" },
                  () => {
                    this.setState({ openAlertModal: true });
                  }
                );
              }
            }}
          />
          <Button
            onClick={() => {
              // setOpenImageModal(true)
              this.inputFile?.current?.click();
            }}
            className={`${this.props.classes.outline_button_no_hover} attachment_button`}
          >
            {this.state.showLoaderForSolutionImageUpload &&
              this.state.typeofAttachment != "hints" ? (
              <>
                <CircularProgress
                  size={16}
                  className={this.props.classes.loader}
                />
                Uploading...
              </>
            ) : (
              <>
                {" "}
                {this.state.questionSolutionImageBlobUrl ? (
                  <img
                    src={this.state.questionSolutionImageBlobUrl}
                    className={`${this.props.classes.imageBlobUrl} attachment-image`}
                  />
                ) : (
                  <AddIcon className={this.props.classes.loader} />
                )}
                Attachment
              </>
            )}
          </Button>
          {this.state.questionSolutionImageBlobUrl ? (
            <Button
              className="clear-button"
              title={"Remove explaination image"}
              onClick={() => {
                this.removeSelectedSolutionImage("explanation");
              }}
            >
              <ClearIcon
                className={this.props.classes.clear_icon}
              />
            </Button>
          ) : null}
        </Box>
        <Box marginTop={'35px'}>
          <Typography
            className={`${this.props.classes.subHeading_font} custom-font-size-hints opensans`}
          >
            Hints
          </Typography>
          {this.props?.hintAnswer?.map((data: any, index: number) => (
            <div key={index} ref={this.hintEditorRef}>
              <TextEditor
                myref={this.hintEditorRef}
                handleChange={(event: any) =>
                  this.props.hintAnswerOnchange(event, data.hintId)
                }
                editorData={data?.hint}
              />
            </div>
          ))}
          <input
            ref={this.HintInputFile}
            accept="image/png, image/jpg, image/jpeg,"
            className="display-none"
            type="file"
            onChange={(e: any) => {
              let totalSize: number = 0;
              let isAllImageTypeFiles = false;
              var files = e.target.files;
              const keys = Object.keys(files);
              keys?.map(async (key: any) => {
                const file = files[key];
                totalSize = totalSize + file.size;
                let fileExtension = file.name.split(".").pop();
                if (
                  fileExtension === "png" ||
                  fileExtension === "jpg" ||
                  fileExtension === "jpeg"
                ) {
                  isAllImageTypeFiles = true;
                } else {
                  isAllImageTypeFiles = false;
                }
              });
              if (isAllImageTypeFiles) {
                if (e.target.files.length <= 1 && totalSize < 524288000) {
                  this.handleFileOnChange(e, null, "hints");
                } else {
                  if (e.target.files.length > 1) {
                    this.setState(
                      { alertModalMessage: "You can upload maximum 1 files" },
                      () => {
                        this.setState({ openAlertModal: true });
                      }
                    );
                  } else {
                    this.setState(
                      { alertModalMessage: "Maximum files size: 500MB" },
                      () => {
                        this.setState({ openAlertModal: true });
                      }
                    );
                  }
                }
              } else {
                this.setState(
                  { alertModalMessage: "only image file can be upload" },
                  () => {
                    this.setState({ openAlertModal: true });
                  }
                );
              }
            }}
          />
          <Button
            onClick={() => {
              // setOpenImageModal(true)
              this.HintInputFile?.current?.click();
            }}
            className={`${this.props.classes.outline_button_no_hover} attachment_button`}
          >
            {this.state.showLoaderForSolutionImageUpload &&
              this.state.typeofAttachment === "hints" ? (
              <>
                <CircularProgress
                  size={16}
                  className={this.props.classes.loader}
                />
                Uploading...
              </>
            ) : (
              <>
                {" "}
                {this.state.questionSolutionHintsImageBlobUrl ? (
                  <img
                    src={this.state.questionSolutionHintsImageBlobUrl}
                    className={`${this.props.classes.imageBlobUrl} attachment-image`}
                  />
                ) : (
                  <AddIcon className={this.props.classes.loader} />
                )}
                Attachment
              </>
            )}
          </Button>
          {this.state.questionSolutionHintsImageBlobUrl ? (
            <Button
              title={"Remove explaination image"}
              onClick={() => {
                this.removeSelectedSolutionImage("hints");
              }}
              className={this.props.classes.outline_button_no_hover}
            >
              <ClearIcon
                className={this.props.classes.clear_icon}
              />
            </Button>
          ) : null}
        </Box>
        <SelectImageModal
          openSelectImageModal={this.state.openImageModal}
          onCloseModal={() => this.setState({ openImageModal: false })}
          onClickChooseFromGallery={() => console.log()}
          onClickUploadFromLocal={() => this.inputFile?.current?.click()}
        />

        <AlertModal
          alertModal={this.state.openAlertModal}
          isTeacher={true}
          onCloseModal={() => {
            this.setState({ openAlertModal: false });
          }}
          alertTitle="Alert!"
          alertMessage={this.state.alertModalMessage}
          buttonText="OK"
        />
      </Box>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(QuestionSolution);
// Customizable Area End
