// Customizable Area Start
import React from 'react';
import { IconButton } from '@material-ui/core';
import { libraryPlayIcon } from "../assets";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export interface Props {
    navigation?: any;
    id?: string;
    location?: any;
    // Customizable Area Start
    data: any;
    handleShowAssessments: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export class LibraryPlayIcon extends React.Component<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
        };

        this.handleIconClick = this.handleIconClick.bind(this)
        // Customizable Area End
    }

    // Customizable Area Start
    handleIconClick() {
        this.props.handleShowAssessments(this.props.data)
    }
    // Customizable Area End

    // Customizable Area Start
    render() {
        return <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            className="IconButton"
            onClick={this.handleIconClick}
        >
            <img src={libraryPlayIcon}
                className="Icon"
            />
        </IconButton>
    }
    // Customizable Area End
}

// Customizable Area Start
export default LibraryPlayIcon;
// Customizable Area End

