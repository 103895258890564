// Customizable Area Start
import React from "react"
import {
    Box,
    Button,
    withStyles,
    Typography,
    Modal,
    Backdrop,
    Grid,
    Paper,
    IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SubmitPaperModalController, { Props } from "./SubmitPaperModalController.web";
import { imgExamIcon, modalImgOrangeBackground } from "../assets";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";

import './SubmitPaperModal.web.css'
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class SubmitPaperModal extends SubmitPaperModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { classes } = this.props
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className='submitPaperModal_mainModal'
                    open={this.props.openModal}
                    onClose={(_, reason) => {
                        reason !== 'backdropClick' ? this.props?.handleOnClose(false) : null;
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Box className='submitPaperModal_mainBox'>
                        <Grid container spacing={0}>
                            {/* Close Btn */}
                            <Grid item xs={12} className='submitPaperModal_closeIconDiv'>
                                <IconButton onClick={() => this.props.handleOnClose(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            {/* Close Btn END*/}

                            {/* Img Icon */}
                            <Grid item xs={12} className='submitPaperModal_imgIconDiv'>
                                <img src={modalImgOrangeBackground} className='submitPaperModal_modalImg' />
                                <img src={imgExamIcon} className='submitPaperModal_examIcon' />
                            </Grid>
                            {/* Img Icon END */}

                            {/* message String */}
                            <Grid item xs={12} className='submitPaperModal_msgDiv'>
                                <Typography
                                    component={"h2"}
                                    className='submitPaperModal_msgTitle'
                                >
                                    {this.props.messageString}
                                </Typography>
                            </Grid>
                            {/* message String END */}


                            {/* message sub String */}
                            <Grid item xs={12} className='submitPaperModal_questionDiv'>
                                <Typography
                                    component={"h3"}
                                    className='submitPaperModal_questionTitle'>
                                    Are you sure you want to submit the question paper
                                </Typography>
                            </Grid>
                            {/* message sub String END*/}


                            {/* Submit Button */}
                            <Grid item xs={12} className='submitPaperModal_btnDiv'>
                                <Box
                                    className="submitPaperModal_subBtnDiv"
                                >
                                    <Button className={`submitPaperModal_ReUploadBtn submitPaperModal_subBtnTxt ${classes.button_color}`} onClick={() => {
                                        this.props.handleOnSubmit()
                                    }}>Submit
                                    </Button>

                                </Box>
                            </Grid>
                            {/* Submit Button END */}

                        </Grid>
                    </Box>

                </Modal>
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(SubmitPaperModal);
// Customizable Area End