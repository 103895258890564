// Customizable Area Start
import React, { useRef, useCallback, useState, useEffect } from "react";
import Webcam from "react-webcam";
import { Box, Button, Typography, Divider } from "@material-ui/core";
import {
  backArrowIcon,
  video_recording_stop_btn_icon,
  video_recording_btn_icon,
} from "./assets";
import { Close } from "@material-ui/icons";
import { convertBlobToFile, getFileSrc } from "./utils/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
interface IWebcam {
  onFileSave: (mediaBlobUrl: string, mediaBlob: any) => void;
  onClose?: () => void;
  onBack?: () => void;
  loadingSpinner?: boolean;
  setPreviewMode: (value: boolean) => void;
  MaxVideoRecordingTime: number;
}

const spinnerBar = {
  color: "rgba(255, 255, 255, 1)",
  height: "30px",
  width: "30px",
  margin: "auto",
  position: "absolute",
  top: "4px",
  right: "33%",
} as const;

const loader = {
  color: "blue",
  height: "30px",
  width: "30px",
  margin: "auto",
} as const;

const timerValue = (val: number) => {
  let valString = val + "";
  if (valString.length < 2) {
    return "0" + valString;
  } else {
    return valString;
  }
};
declare var MediaRecorder: any;
// Customizable Area End

export const VideoWebCam: React.FC<IWebcam> = ({
  // Customizable Area Start
  onFileSave,
  onClose,
  onBack,
  loadingSpinner,
  setPreviewMode,
  MaxVideoRecordingTime,
  // Customizable Area End
}) => {
  // Customizable Area Start
  const webcamRef: React.MutableRefObject<any> = useRef();
  const mediaRecorderRef: React.MutableRefObject<any> = useRef();
  const [capturing, setCapturing] = useState(false);
  const [canRecordVideoViaWebcam, setCanRecordVideoViaWebcam] = useState(false);
  const [isMaxRecordingTimeUp, setIsMaxRecordingTimeUp] = useState(false);
  const [preview, setPreview] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [recorderTime, setRecorderTime] = useState("00:00");
  const [isCameraPermissionDenied, setIsCameraPermissionDenied] = useState(
    false
  );
  let totalSeconds: number = 0;
  const momentDurationFormatSetup = require("moment-duration-format");
  momentDurationFormatSetup(moment);
  const timerForRecorder = useCallback(() => {
    ++totalSeconds;
    const duration: any = moment.duration(totalSeconds, "seconds");
    const formatted = duration.format("mm:ss", { trim: false });
    if (totalSeconds > MaxVideoRecordingTime) setIsMaxRecordingTimeUp(true);
    setRecorderTime(formatted);
  }, []);

  useEffect(() => {
    let timer: any;
    if (!isMaxRecordingTimeUp && capturing) {
      timer = setInterval(() => {
        timerForRecorder();
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isMaxRecordingTimeUp, capturing]);

  useEffect(() => {
    if (isMaxRecordingTimeUp) handleStopCaptureClick();
  }, [isMaxRecordingTimeUp]);

  const handleResetTimer = () => {
    setRecorderTime("00:00");
    totalSeconds = 0;
  };

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data));
    }
  }, []);

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    setIsMaxRecordingTimeUp(false);
    let options;
    if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
      options = {
        mimeType: "video/webm; codecs=vp9",
      };
    }
    mediaRecorderRef.current = new MediaRecorder(
      webcamRef.current.stream,
      options
    );
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setPreview(true);
    setPreviewMode(true);
    setCanRecordVideoViaWebcam(false);
    handleResetTimer();
  }, [mediaRecorderRef, setCapturing]);

  const getVideoSrcToLoadPreview = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4",
      });

      const videoFile: any = convertBlobToFile(blob, `${Math.random()}.mp4`);
      const videoSrc: string = getFileSrc(videoFile);
      return videoSrc;
    }
    return "";
  }, [recordedChunks]);

  const handleSave = useCallback(() => {
    if (recordedChunks.length) {
      setCapturing(false);
      const videoFile: any = convertBlobToFile(
        recordedChunks[0],
        `self_video.webm`
      );
      const videoSrc: string = getFileSrc(videoFile);
      onFileSave(videoSrc, videoFile);
      setRecordedChunks([]);
    }
  }, [recordedChunks, onFileSave]);

  const handleCancel = useCallback(() => {
    setCapturing(false);
    setPreview(false);
    setPreviewMode(false);
    handleResetTimer();
    setRecordedChunks([]);
  }, []);

  const handleRecordButtonClick = () => {
    setCanRecordVideoViaWebcam(true);
  };

  const handleError = useCallback(
    (e) => {
      setIsCameraPermissionDenied(true);
      handleCancel();
    },
    [handleCancel]
  );

  return (
    <Box className="video_web_cam_modal">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="10%"
      >
        <Box display="flex">
          <Button
            disableRipple={true}
            className="PreviewVideoBackBtn"
            onClick={onBack}
          >
            <Typography className="VideoWebCamBack">
              <img src={backArrowIcon} />
            </Typography>
            <Typography className="back_text">Back</Typography>
          </Button>
        </Box>
        <Typography className="create_video_text_webcam">
          {preview ? "Preview Video" : "Create Video"}
        </Typography>

        <Typography>
          <Close onClick={onClose} className="VideoWebcamClose" />
        </Typography>
      </Box>
      <Divider className="upload_video_divider" />
      {!canRecordVideoViaWebcam && !isCameraPermissionDenied && !preview && (
        <div className="CapturePhotoLoader">
          <CircularProgress style={loader} />
        </div>
      )}
      {isCameraPermissionDenied && !preview && (
        <div className="CapturePhotoNotAllowedText">
          Please allow 21KSchoolPhase1 to access camera and microphone in order
          to start recording video!
        </div>
      )}
      <div>
        {!isCameraPermissionDenied && (
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              className="VideoAllowedDiv"
            >
              {!preview ? (
                <Webcam
                  audioConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                    autoGainControl: false,
                  }}
                  ref={webcamRef}
                  onUserMediaError={handleError}
                  onUserMedia={handleRecordButtonClick}
                  className="video_webcam_style"
                />
              ) : (
                <video
                  controls
                  className="video_webcam_style"
                  src={getVideoSrcToLoadPreview()}
                />
              )}
            </Box>
          </Box>
        )}
        {!isCameraPermissionDenied && (
          <div className="AllowedVideoPreviewDiv">
            {preview ? (
              <>
                <Button
                  onClick={handleSave}
                  className="VideoSubmitBtn"
                >
                  <span className="VideoSubmitBtnText">
                    {loadingSpinner ? (
                      <CircularProgress style={spinnerBar} />
                    ) : (
                      <>Submit</>
                    )}
                  </span>
                </Button>
                <Button
                  onClick={handleCancel}
                  className="PhotoCancelBtn"
                  style={{
                    opacity: loadingSpinner ? 0.5 : 1,
                  }}
                  disabled={loadingSpinner}
                >
                  <Typography className="PhotoCancelBtnText">
                    Cancel
                  </Typography>
                </Button>
              </>
            ) : (
              <div className="record_video_bottom_section">
                <label id="recorderVideoPreview"></label>
                {!preview && !isCameraPermissionDenied && (
                  <span id="recorderVideoPreview">{recorderTime}</span>
                )}
                {!isCameraPermissionDenied && (
                  <Button
                    onClick={
                      capturing
                        ? handleStopCaptureClick
                        : handleStartCaptureClick
                    }
                    style={{
                      opacity: canRecordVideoViaWebcam ? 1 : 0.6,                    
                    }}
                    className="RecordVideoBtn"
                    disabled={!canRecordVideoViaWebcam}
                  >
                    <img
                      className="video_recording_icon"
                      src={
                        capturing
                          ? video_recording_stop_btn_icon
                          : video_recording_btn_icon
                      }
                    ></img>
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Box>
  );
  // Customizable Area End
};

// Customizable Area Start
export default VideoWebCam;
// Customizable Area End
