// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import {
  file_uploaded_check_icon,
  file_uploaded_pic,
  openIcon,
  cancelIcon,
  fileIcon,
} from "./assets";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { toBase64 } from "./utils/common";
import axios from "axios";
export const configJSON = require("./config");
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface Props {
  // Customizable Area Start
  fileName: string;
  fileSize: number;
  deleteClick: any;
  previewClick: any;
  onChangeFileName: any;
  updateProgress: any;
  file: any;
  getBulkPhotoPreSignedId: any;
  progress: number;
  // Customizable Area End
}

export const TeacherBulkDocumentBox = ({
  // Customizable Area Start
  fileName,
  fileSize,
  deleteClick,
  previewClick,
  onChangeFileName,
  updateProgress,
  file,
  getBulkPhotoPreSignedId,
  progress
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  const [percentage, setPercentage] = useState<number>(progress);
  let clientSidePercentage = 0; // 25% weightage
  let presignedPercentage = 0; // 25% weightage
  let awsPercentage = 0; // 50% weightage
  let signedId: string | null = null;
  let fileData: any = null;

  useEffect(() => {
    calcTotalPercentage();
  }, [progress]);
  useEffect(() => {
    uploadFile();
  }, []);

  const uploadFile = async () => {
    fileData = await toBase64(file);
    const fileDetails = {
      fileName: file.name,
      fileSize: file.size,
      content_type: file.type,
      fileData: fileData
    };
    getBulkPhotoPreSignedId(fileDetails);
  };

  const getPreSignedId = async (fileUploadDetail: any) => {
    const header:any = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody= {
      filename: fileUploadDetail.fileName,
      byte_size: fileUploadDetail.fileSize,
      checksum: fileUploadDetail.checksum,
      content_type: fileUploadDetail.content_type,
    };
    await axios({
      method: "post",
      url: configJSON.userManagementURL + configJSON.getPreSignedId,
      data: httpBody,
      headers: header,
      onUploadProgress: (progressEvent) => {
        presignedPercentage = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        calcTotalPercentage();
      },
    })
      .then((s3Response: any) => {
        
      })
      .catch((s3error: any) => {
        updateProgress(percentage, null);
        toast.error("Something went wrong.");
      });
  };

  const saveImageUsingURL = async (preSignedResponse: any) => {
    signedId = preSignedResponse?.signed_id;
    const header:any = {
      "Content-Type": preSignedResponse?.headers?.["Content-Type"],
      "Content-MD5": preSignedResponse?.headers?.["Content-MD5"],
      token: localStorage.getItem("token"),
    };

    const httpBody = file;

    try {
      await axios({
        method: "put",
        url: preSignedResponse.url,
        data: httpBody,
        headers: header,
        onUploadProgress: (progressEvent) => {
          awsPercentage = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          calcTotalPercentage();
        },
      })
        .then((s3Response) => {

        })
        .catch((s3error) => {
          updateProgress(percentage, null);
          toast.error("Something went wrong.");
        });
    } catch (e) {
      updateProgress(percentage, null);
      toast.error("Something went wrong.");
    }
  };

  const calcTotalPercentage = () => {
    setPercentage(progress);
    if (progress >= 100) {
      updateProgress(progress, signedId, fileData);
    }
  };

  return (
    <Grid item xs={12}>
      <div className="BulkDocumentUploadDiv">
        {percentage >= 100 ? (
          <Grid container className="BulkDocumentContainer">
            <Grid item xs={2} className="BulkDocumentDiv">
              <Box className="DocumentUploadedIconBox">
                <img
                  src={file_uploaded_pic}
                />
                <img
                  src={file_uploaded_check_icon}
                  className="DocumentUploadedCheckIcon"
                />
              </Box>
            </Grid>
            <Grid item xs={10} className="DocumentInputDiv">
              <Grid container direction="row">
                <Grid
                  item
                  xs={10}
                  className="DocumentInputItem"
                >
                  <TextField
                    variant={"outlined"}
                    size={"medium"}
                    className="DocumentInput"
                    disabled={true}
                    value={fileName.slice(0, fileName?.lastIndexOf("."))}
                    onChange={(e) => {
                      onChangeFileName(e.target.value);
                    }}
                  />
                  <Typography className="DocumentSizeText">
                    {" "}
                    Size:{" "}
                    {fileSize > 1048576
                      ? `${(fileSize / 1048576).toFixed(2)} MB`
                      : `${(fileSize / 1024).toFixed(2)} KB`}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <div className="UploadedDocActions">
                    <img
                      src={openIcon}
                      className="UploadedDocOpenImg"
                      title="Preview"
                      onClick={previewClick}
                    />
                    <img
                      src={cancelIcon}
                      className="UploadedDocDeleteImg"
                      title="Delete"
                      onClick={deleteClick}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={2} className="BulkDocumentDiv">
              <Box className="BulkUploadingBox">
                <CircularProgressbarWithChildren
                  value={percentage ? percentage : 0}
                  strokeWidth={2}
                  styles={{
                    path: {
                      stroke: "#3f526d",
                      strokeLinecap: "round",
                    },
                  }}
                >
                  <img
                    src={fileIcon}
                    className="UploadingFileIcon"
                  />
                </CircularProgressbarWithChildren>
              </Box>
            </Grid>
            <Grid
              item
              xs={10}
              className="DocumentInputItem"
            >
              <Typography className="BulkUploadingFileText">
                Uploading File...
              </Typography>
              <Typography className="ThisMayTakeWhileText">
                {" "}
                This may take a while{" "}
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>
    </Grid>
  );
  // Customizable Area End
};
// Customizable Area Start
export default TeacherBulkDocumentBox;
// Customizable Area End