// Customizable Area Start
import React from "react";
import Grid from "@material-ui/core/Grid";
import { Button, Dialog, Typography, TextField, Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import { invalidCharsForNumberType } from "./CreateCustomReportCardController.web";
import './CreateCustomReportCard.web.css';
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const form_input_text_field = {
  width: "100%",
  borderRadius: "6px",
  textAlign: "start",
} as const;
// Customizable Area End

interface Props {
  // Customizable Area Start
  addEditGradeDialog: boolean;
  handleAddEditGradeDialogClose: any;
  gradeName: string;
  gradeNameError: any;
  gradeNameErrorMsg: string;
  gradeLabel: string;
  gradeLabelError: boolean;
  gradeLabelErrorMsg: string;
  score: string;
  scoreError: boolean;
  scoreErrorMsg: string;
  minMarks: string;
  minMarksError: boolean;
  minMarksErrorMsg: string;
  maxMarks: string;
  maxMarksError: boolean;
  maxMarksErrorMsg: string;
  onChangeHandle: any;
  onBlurHandle: any;
  addEditGradeClick: any;
  classes: any;
  // Customizable Area End
}

// Customizable Area Start
export const AddEditGrade = ({
  addEditGradeDialog,
  handleAddEditGradeDialogClose,
  gradeName,
  gradeNameError,
  gradeNameErrorMsg,
  gradeLabel,
  gradeLabelError,
  gradeLabelErrorMsg,
  score,
  scoreError,
  scoreErrorMsg,
  minMarks,
  minMarksError,
  minMarksErrorMsg,
  maxMarks,
  maxMarksError,
  maxMarksErrorMsg,
  onChangeHandle,
  onBlurHandle,
  addEditGradeClick,
  classes,
}: Props) => {
  const onKeyPressMarks = (e: any) => {
    if (invalidCharsForNumberType.includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
    }
  } 
  return (
    <Dialog
      PaperProps={{
        className: "dynamic_popup_width CreateReportCardModal",
      }}
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
      open={addEditGradeDialog}
      onClose={() => handleAddEditGradeDialogClose()}
      closeAfterTransition
      fullWidth={true}
      disableAutoFocus={true}
      className="CreateReportCardModal"
      id="add-edit-grade-modal"
      data-test-id="add-edit-grade-modal"
    >
      <div className="add-grade-modal">
        <Grid container>
          <Grid
            item
            xs={12}
            className="ReportGradeHead"
          >
            <Typography className={`${classes.title_font} grade-dialog-title`}>
              Add Details
            </Typography>
            <CloseIcon
              className="ReportGradeCloseIcon"
              onClick={() => handleAddEditGradeDialogClose()}
              data-test-id="add-edit-grade-close-icon"
            />
          </Grid>
        </Grid>

        <Grid container className="ReportGradeContainer">
          <Grid
            item
            xs={12}
            className="ReportGradeNameField"
          >
            <div>
              <div className={`${classes.subTitle_font} grade-detail-title`}>
                Name
              </div>
              <TextField
                id="standard-basic"
                placeholder="Eg. Grade, Point Scale, Marks "
                style={form_input_text_field}
                variant="outlined"
                value={gradeName}
                onChange={(e: any) => {
                  onChangeHandle(e, "gradeName");
                }}
                onBlur={(e: any) => {
                  onBlurHandle(e, "gradeName");
                }}
                error={gradeNameError}
                helperText={gradeNameErrorMsg}
                inputProps={{ className: `grade-text-field` }}
                data-test-id="grade-name-text-field"
              />
            </div>
            <Divider className="add-grade-divider"/>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className="ReportGradeLeftField"
          >
            <div className="grade-scale-textfields">
              <div className={`${classes.subTitle_font} grade-detail-title`}>
                Label
              </div>
              <TextField
                id="standard-basic"
                placeholder="Eg. Excellent P1, P9"
                style={form_input_text_field}
                variant="outlined"
                value={gradeLabel}
                onChange={(e: any) => {
                  onChangeHandle(e, "gradeLabel");
                }}
                onBlur={(e: any) => {
                  onBlurHandle(e, "gradeLabel");
                }}
                error={gradeLabelError}
                helperText={gradeLabelErrorMsg}
                inputProps={{ className: `grade-text-field` }}
                data-test-id="grade-label-text-field"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className="ReportGradeRightField"
          >
            <div className="grade-scale-textfields">
              <div className={`${classes.subTitle_font} grade-detail-title`}>
                Score
              </div>
              <TextField
                id="standard-basic"
                placeholder="Eg. A+, A, D, E, 1, 2"
                style={form_input_text_field}
                variant="outlined"
                value={score}
                onChange={(e: any) => {
                  onChangeHandle(e, "score");
                }}
                onBlur={(e: any) => {
                  onBlurHandle(e, "score");
                }}
                error={scoreError}
                helperText={scoreErrorMsg}
                inputProps={{ className: `grade-text-field` }}
                data-test-id="grade-score-text-field"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className="ReportGradeLeftField"
          >
            <div className="grade-scale-textfields">
              <div className={`${classes.subTitle_font} grade-detail-title`}>
                Min Marks Value
              </div>
              <TextField
                id="standard-basic"
                type="number"
                placeholder="Out of 100"
                style={form_input_text_field}
                variant="outlined"
                value={minMarks}
                onChange={(e: any) => {
                  onChangeHandle(e, "minMarks");
                }}
                onBlur={(e: any) => {
                  onBlurHandle(e, "minMarks");
                }}
                onKeyPress={(e: any) => onKeyPressMarks(e)}
                error={minMarksError}
                helperText={minMarksErrorMsg}
                inputProps={{ className: `grade-text-field` }}
                data-test-id="grade-min-marks-text-field"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className="ReportGradeRightField"
          >
            <div className="grade-scale-textfields">
              <div className={`${classes.subTitle_font} grade-detail-title`}>
                Max Marks Value
              </div>
              <TextField
                id="standard-basic"
                type="number"
                placeholder="Out of 100"
                style={form_input_text_field}
                variant="outlined"
                value={maxMarks}
                onChange={(e: any) => {
                  onChangeHandle(e, "maxMarks");
                }}
                onBlur={(e: any) => {
                  onBlurHandle(e, "maxMarks");
                }}
                onKeyPress={(e: any) => onKeyPressMarks(e)}
                error={maxMarksError}
                helperText={maxMarksErrorMsg}
                inputProps={{ className: `grade-text-field` }}
                data-test-id="grade-max-marks-text-field"
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div className="grade-bottom-btn-div">
            <Button
              className={`grade-create-btn ${classes.button_color}`}
              onClick={(e: any) => {
                addEditGradeClick();
              }}
              data-test-id="create-grade-btn"
            >
              Create
            </Button>
          </div>
        </Grid>
      </div>
    </Dialog>
  );
};
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(AddEditGrade);
// Customizable Area End