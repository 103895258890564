// Customizable Area Start
import React from "react";
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  TextField,
  FormHelperText,
  InputAdornment,
  Box,
} from "@material-ui/core";
import MatchTypeQuestionController, {
  Props,
} from "./MatchTypeQuestionController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import {
  imageDragSVG,
  imageMarksMinusSVG,
  imageMarksPlusSVG,
  imgBackArrowSVG,
  imgPlusIconSVG,
  matchGroupSVG,
  optionImageSVG,
  imageErrorSVG,
} from "../assets";
import QuestionMetaData from "../Components/QuestionMetaData.web";
import "../TeacherAssessment.web.css";
import QuestionSolution from "../Components/QuestionSolution.web";
import { invalidCharsForNumberType } from "../../../profiles/src/utils/common";
import { FileDrop } from "react-file-drop";
import AlertModal from "../../../alert/src/AlertModal.web";
import SelectImageModal from "./SelectImageModal.web";
import { HISTORY } from "../../../../components/src/comman";
import ChooseFromGalleryModal from "./ChooseFromGalleryModal.web";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";
import { ImagePreivewModal } from "../Components/ImagePreivewModal.web";
import TextEditor from "./TextEditor/TextEditor.web";
import { withStyles } from "@material-ui/core/styles";
import QuestionSidebarCollapse from "./QuestionSidebarCollapse.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import QuestionPreviewModal from "../Components/QuestionPreviewModal.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import './AddQuestion.web.css';
// Customizable Area End

// Customizable Area Start
const form_input_hint_number = {
  width: "97%",
  borderRadius: "10px",
  // backgroundColor: "#f9f8fd",
} as const;
// Customizable Area End

export class MatchTypeQuestion extends MatchTypeQuestionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const {
      marks,
      negativeMarks,
      selectedCategoryQuestionData,
      sectiondata,
      questionList,
      possibleResponseList,
    } = this.state;

    const drop = (e: any) => {
      e.preventDefault();
      const tempItemArray: any = [...this.state.questionList];
      const div_id = e.dataTransfer.getData("div_id");
      let dropDestinationIndex = Array.from(e.target.children).findIndex(
        (child: any) => child.getBoundingClientRect().bottom > e.clientY
      );
      let dropItemIndex = Array.from(e.target.children).findIndex(
        (child: any) => child.id === div_id
      );
      if (dropDestinationIndex === -1) return;
      else {
        const [reorderedItem] = tempItemArray.splice(dropItemIndex, 1);
        // Add dropped item
        tempItemArray.splice(dropDestinationIndex, 0, reorderedItem);
        // Update State
      }

      this.setState({ questionList: tempItemArray });
    };

    const dragOver1 = (e: any) => {
      e.preventDefault();
    };

    const dragStart = (e: any) => {
      const target = e.target;
      e.dataTransfer.setData("div_id", target.id);
    };

    const dragOver = (e: any) => {
      e.stopPropagation();
    };

    const dropResponse = (e: any) => {
      e.preventDefault();
      const tempItemArray: any = [...this.state.possibleResponseList];
      const div_id = e.dataTransfer.getData("div_id");
      let dropDestinationIndex = Array.from(e.target.children).findIndex(
        (child: any) => child.getBoundingClientRect().bottom > e.clientY
      );
      let dropItemIndex = Array.from(e.target.children).findIndex(
        (child: any) => child.id === div_id
      );
      if (dropDestinationIndex === -1) return;
      else {
        const [reorderedItem] = tempItemArray.splice(dropItemIndex, 1);
        // Add dropped item
        tempItemArray.splice(dropDestinationIndex, 0, reorderedItem);
        // Update State
      }

      this.setState({ possibleResponseList: tempItemArray });
    };

    const dragOver1Response = (e: any) => {
      e.preventDefault();
    };

    const dragStartResponse = (e: any) => {
      const target = e.target;
      e.dataTransfer.setData("div_id", target.id);
    };

    const dragOverResponse = (e: any) => {
      e.stopPropagation();
    };

    const touchDropEndInternalList = (e: any) => {
      e.preventDefault();
      const tempItemArray: any = [...this.state.questionList];
      const target = e.currentTarget;
      const div_id = target.id;

      let x = e.changedTouches[0].pageX - window.pageXOffset;
      let y = e.changedTouches[0].pageY - window.pageYOffset;
      let releaseTarget: any = document.elementFromPoint(x, y);

      let dropDestinationIndex = Array.from(releaseTarget?.children).findIndex(
        (child: any) => child.getBoundingClientRect()?.bottom > e?.changedTouches?.[0]?.clientY
      );
      let dropItemIndex = Array.from(releaseTarget?.children).findIndex(
        (child: any) => child.id === div_id
      );
      if (dropDestinationIndex === -1) return;
      else {
        const [reorderedItem] = tempItemArray.splice(dropItemIndex, 1);
        tempItemArray.splice(dropDestinationIndex, 0, reorderedItem);
      }
      this.setState({ questionList: tempItemArray });
    };

    const touchDropEndInternalResponse = (e: any) => {
      e.preventDefault();
      const tempItemArray: any = [...this.state.possibleResponseList];
      const target = e.currentTarget;
      const div_id = target.id;

      let x = e.changedTouches[0].pageX - window.pageXOffset;
      let y = e.changedTouches[0].pageY - window.pageYOffset;
      let releaseTarget: any = document.elementFromPoint(x, y);

      let dropDestinationIndex = Array.from(releaseTarget?.children).findIndex(
        (child: any) => child.getBoundingClientRect()?.bottom > e?.changedTouches?.[0]?.clientY
      );
      let dropItemIndex = Array.from(releaseTarget?.children).findIndex(
        (child: any) => child.id === div_id
      );
      if (dropDestinationIndex === -1) return;
      else {
        const [reorderedItem] = tempItemArray.splice(dropItemIndex, 1);
        tempItemArray.splice(dropDestinationIndex, 0, reorderedItem);
      }
      this.setState({ possibleResponseList: tempItemArray });
    };

    const dragTouchStart = (e: any) => {
      e.preventDefault();
    };

    const dragTouchMove = (e: any) => {
      e.stopPropagation();
    };

    const touchDropEnd = (e: any) => {
      e.preventDefault();
      const target = e.currentTarget;
      const div_id = target.id;
      const answerId = div_id.replace("answer-", "");

      if (!this.checkIfOptionAlreadyMatched(answerId)) {
        let x = e.changedTouches[0].pageX - window.pageXOffset;
        let y = e.changedTouches[0].pageY - window.pageYOffset;
        let releaseTarget: any = document.elementFromPoint(x, y);
        let releaseTargetId: any = releaseTarget.id;
        const questionUniqId = releaseTargetId.replace("answer-", "");
        const block = document.getElementById(div_id);
        let myimg = null;
        let mysrc = null;
        if (block) {
          myimg = block.getElementsByTagName("img")[0];
        }
        if (myimg) {
          mysrc = myimg.src;
        }

        let tempQuestionList = this.state.questionList;
        const index = this.state.questionList.findIndex(
          (res: any) => res.questionId === questionUniqId
        );

        if (index !== -1) {
          const data = tempQuestionList[index];
          tempQuestionList.map((item: any) => {
            if (item.questionId === questionUniqId) {
              item.correctAnswerId = "";
              item.answer = "";
              item.answerSrc = "";
            }
            if (item.questionId === div_id.replace("answer-", "")) {
              item.correctAnswerId = "";
              item.answer = "";
              item.answerSrc = "";
            }
            return item;
          });

          tempQuestionList[index] = {
            ...data,
            answer: block?.innerText ? block?.innerText : "",
            answerSrc: mysrc ? mysrc : "",
            correctAnswerId: div_id.replace("answer-", ""),
          };
          this.setState(
            {
              questionList: tempQuestionList,
              internallyDraggedAnswer: false,
            }
          );
        }
      }
    };

    const touchDropEndInternal = (e: any) => {
      e.preventDefault();
      const target = e.currentTarget;
      const div_id = target.id;
      const isInternallyDragged = this.state.questionList.find(
        (res: any) => res.questionId === div_id.replace("answer-", "")
      );
      const internallyDraggedAnswerId = isInternallyDragged?.correctAnswerId;

      let x = e.changedTouches[0].pageX - window.pageXOffset;
      let y = e.changedTouches[0].pageY - window.pageYOffset;
      let releaseTarget: any = document.elementFromPoint(x, y);
      let releaseTargetId: any = releaseTarget.id;
      const questionUniqId = releaseTargetId.replace("answer-", "");
      const block = document.getElementById(div_id);
      let myimg = null;
      let mysrc = null;
      if (block) {
        myimg = block.getElementsByTagName("img")[0];
      }
      if (myimg) {
        mysrc = myimg.src;
      }

      let tempQuestionList = this.state.questionList;
      const index = this.state.questionList.findIndex(
        (res: any) => res.questionId === questionUniqId
      );

      if (index !== -1) {
        const data = tempQuestionList[index];
        tempQuestionList.map((item: any) => {
          if (item.questionId === questionUniqId) {
            item.correctAnswerId = "";
            item.answer = "";
            item.answerSrc = "";
          }
          if (
            isInternallyDragged &&
            item.questionId === div_id.replace("answer-", "")
          ) {
            item.correctAnswerId = "";
            item.answer = "";
            item.answerSrc = "";
          }
          return item;
        });

        if (isInternallyDragged) {
          tempQuestionList[index] = {
            ...data,
            answer: block?.innerText ? block?.innerText : "",
            answerSrc: mysrc ? mysrc : "",
            correctAnswerId: internallyDraggedAnswerId,
          };
        } else {
          tempQuestionList[index] = {
            ...data,
            answer: block?.innerText ? block?.innerText : "",
            answerSrc: mysrc ? mysrc : "",
            correctAnswerId: div_id.replace("answer-", ""),
          };
        }
        this.setState(
          {
            questionList: tempQuestionList,
            internallyDraggedAnswer: false,
          }
        );
      }
    };

    const dropAnswer = (e: any, questionId: any) => {
      e.preventDefault();
      const targetId = e.currentTarget.id;
      const div_id = e.dataTransfer.getData("div_id");
      const block = document.getElementById(div_id);
      let myimg = null;
      let mysrc = null;
      if (block) {
        myimg = block.getElementsByTagName("img")[0];
      }
      if (myimg) {
        mysrc = myimg.src;
      }

      let tempQuestionList = this.state.questionList;
      const index = this.state.questionList.findIndex(
        (res: any) => res.questionId === questionId
      );
      const isInternallyDragged = this.state.questionList.find(
        (res: any) => res.questionId === div_id.replace("answer-", "")
      );
      const internallyDraggedAnswerId = isInternallyDragged?.correctAnswerId;
      const data = tempQuestionList[index];
      tempQuestionList.map((item: any) => {
        if (item.questionId === questionId) {
          item.correctAnswerId = "";
          item.answer = "";
          item.answerSrc = "";
        }
        if (
          isInternallyDragged &&
          item.questionId === div_id.replace("answer-", "")
        ) {
          item.correctAnswerId = "";
          item.answer = "";
          item.answerSrc = "";
        }
        return item;
      });
      if (isInternallyDragged) {
        tempQuestionList[index] = {
          ...data,
          correctAnswerId: internallyDraggedAnswerId,
          answer: block?.innerText ? block?.innerText : "",
          answerSrc: mysrc ? mysrc : "",
        };
      } else {
        tempQuestionList[index] = {
          ...data,
          correctAnswerId: div_id.replace("answer-", ""),
          answer: block?.innerText ? block?.innerText : "",
          answerSrc: mysrc ? mysrc : "",
        };
      }
      this.setState(
        {
          questionList: tempQuestionList,
          internallyDraggedAnswer: false,
        }
      );
    };

    const dragOver1Answer = (e: any) => {
      e.preventDefault();
    };

    const dragStartAnswer = (e: any) => {
      const target = e.target;
      e.dataTransfer.setData("div_id", target.id);
      this.setState({ internallyDraggedAnswer: true });
    };

    const dragOverAnswer = (e: any) => {
      e.stopPropagation();
    };

    return (
      <>
        <div>
          {this.state.showLoader && (
            <Spinner spinnerModal={this.state.showLoader} />
          )}
          <Grid
            container
            className="marks_section"
          >
            <Grid
              item
              xs={12}
              sm={10}
              className="question_flex_column"
            >
              <Typography className={`back_with_arrow ${this.props.classes.bodyText_font}`}>
                <span onClick={HISTORY.goBack}>
                  <img
                    src={imgBackArrowSVG}
                    className="question_back_arrow"
                  ></img>
                  Back
                </span>

                <span
                  className={`${this.props.classes.bodyText_font} textlabel_breadcrum`}
                >
                  <span className="text-style-light">
                    {this.state.editButton
                      ? "Edit question "
                      : "Create new question "}
                  </span>
                  (
                  {selectedCategoryQuestionData?.question_category_id === 1 ? (
                    <>Objective</>
                  ) : (
                    <>Subjective</>
                  )}{" "}
                  - {selectedCategoryQuestionData?.name})
                </span>
              </Typography>

              <div className="marks_wrap_div">
                <div className="marks_div">
                  <Typography
                    className={`question_marks_text ${this.props.classes.subHeading_font}`}
                  >
                    Marks
                  </Typography>
                  <div>
                    <div
                      className={this.state.marksError ? "view_marksbox red_border" : "view_marksbox none_border"}
                    >
                      <img
                        src={imageMarksPlusSVG}
                        className="image_marks"
                        onClick={this.plusMarksClick}
                      ></img>

                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={(event) => {
                          const value: number = parseFloat(
                            parseFloat(event.target.value)
                              .toFixed(3)
                              .slice(0, -1)
                          );
                          if (value > 0 || marks > 0) {
                            this.setState({ marksError: false });
                          } else {
                            this.setState({
                              marksError: true,
                              marks: 0,
                              marksErrorText: "Please enter marks.",
                            });
                          }
                          if (
                            value <
                            this.state.sectiondata?.attributes?.remaining_marks
                          ) {
                            this.setState({ marks: value, marksError: false });
                          } else if (
                            value ==
                            this.state.sectiondata?.attributes
                              ?.remaining_marks -
                            1
                          ) {
                            this.setState({ marks: value, marksError: false });
                          }
                          if (value >= this.state.negativeMarks) {
                            this.setState({
                              marks: value,
                              negmarksError: false,
                            });
                          } else {
                            this.setState({
                              marks: value,
                              negmarksError: true,
                            });
                          }

                          if (this.state.editButton) {
                            if (
                              value >
                              this.state.sectiondata?.remaining_marks +
                              HISTORY.location.state?.questionData?.attributes
                                ?.marks
                            ) {
                              this.setState({
                                marks: value,
                                marksError: true,
                                marksErrorText:
                                  "Marks not greater than section marks.",
                              });
                            } else {
                              this.setState({
                                marks: value,
                              });
                            }
                          } else {
                            if (
                              value >
                              this.state.sectiondata?.attributes
                                ?.remaining_marks
                            ) {
                              this.setState({
                                marks: value,
                                marksError: true,
                                marksErrorText:
                                  "Marks not greater than section marks.",
                              });
                            } else {
                              this.setState({
                                marks: value,
                              });
                            }
                          }
                        }}
                        onKeyPress={(event) => {
                          if (invalidCharsForNumberType.includes(event.key)) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        value={marks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.marksError && (
                      <FormHelperText className="red_text">
                        {this.state.marksErrorText}
                      </FormHelperText>
                    )}
                  </div>
                </div>

                {this.state.assessmentHasNegativeMarking && (
                  <div className="marks_div">
                    <Typography
                      className={`question_marks_text ${this.props.classes.subHeading_font}`}
                    >
                      Negative Marks
                    </Typography>
                    <div
                      className={this.state.negmarksError ? "view_marksbox red_border" : "view_marksbox none_border"}
                    >
                      <img
                        src={imageMarksMinusSVG}
                        className="image_marks"
                        onClick={this.minusMarksClick}
                      ></img>

                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={(event) => {
                          const value: number = parseFloat(
                            parseFloat(event.target.value)
                              .toFixed(3)
                              .slice(0, -1)
                          );
                          if (value > this.state.marks) {
                            this.setState({ negmarksError: true });
                          } else {
                            this.setState({ negmarksError: false });
                          }
                          this.setState({ negativeMarks: value });
                        }}
                        onKeyPress={(event) => {
                          if (invalidCharsForNumberType.includes(event.key)) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        value={negativeMarks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.negmarksError && (
                      <FormHelperText className="red_text">
                        Negative marking not greater then marks.
                      </FormHelperText>
                    )}
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={2} className="remaining_section_marks">
              <Typography
                className={`remaining_marks ${this.props.classes.subHeading_font}`}
              >
                Remaining Section Marks
              </Typography>

              <div className="circular_div">
                <CircularProgress
                  variant="static"
                  className="grey_circular"
                  size={92}
                  thickness={2}
                  value={100}
                />
                <CircularProgress
                  variant="static"
                  disableShrink
                  className="colored_circular"
                  size={92}
                  value={this.state.remainSectionMarkPr}
                  thickness={2}
                />
                <Typography className={`circular_marks_text ${this.props.classes.bodyText_font}`}>
                  {(
                    sectiondata?.section_marks - sectiondata?.remaining_marks
                  ).toFixed(2)}
                  /{sectiondata?.section_marks}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Typography
            className={`question_text ${this.props.classes.subHeading_font}`}
          >
            Question
          </Typography>
          <div ref={this.questionEditorRef}>
            <TextEditor
              myref={this.questionEditorRef}
              handleChange={(value: any) => {
                this.setState({ question: value }, () => {
                  if (
                    this.state.question === null ||
                    this.state.question === ""
                  ) {
                    this.setState({ questionError: true });
                  } else {
                    this.setState({ questionError: false });
                  }
                });
              }}
              editorData={
                HISTORY.location.state?.questionData?.attributes?.title
                  ? HISTORY.location.state?.questionData?.attributes?.title
                  : ""
              }
              isEdit={this.state.editButton}
            />
          </div>

          {this.state.questionError && (
            <FormHelperText className="question_error">
              Please enter question.
            </FormHelperText>
          )}
          <FileDrop
            onDrop={(files: any, event: any) => {
              window.addEventListener(
                "drop",
                function (e) {
                  e.preventDefault();
                },
                false
              );

              let totalSize: number = 0;
              const keys = Object.keys(files);
              keys?.map(async (key: any) => {
                const file = files[key];
                totalSize = totalSize + file.size;
              });
              if (files.length <= 1 && totalSize < 524288000) {
                this.handleFileOnChange(event, files);
              } else {
                if (files.length > 1) {
                  this.setState({
                    alertMessage: "You can upload maximum 1 files",
                    alertModal: true,
                  });
                } else {
                  this.setState({
                    alertMessage: "Maximum files size: 500MB",
                    alertModal: true,
                  });
                }
              }
            }}
          >
            <div className="question_main_image_div">
              {this.state.showLoaderForImageUpload ? (
                <div className="image_uploading_div">
                  <CircularProgress />
                  <Typography
                    className={`image_uploading_text ${this.props.classes.bodyText_font}`}
                  >
                    Uploading...
                  </Typography>
                </div>
              ) : (
                <>
                  {this.state.selectedImageBlobUrl ? (
                    <>
                      <img
                        src={this.state.selectedImageBlobUrl}
                        className="uploaded_question_image"
                        onError={() => {
                          this.setState({
                            selectedImageBlobUrl: imageErrorSVG,
                          });
                        }}
                      ></img>
                      <Button
                        disableRipple={true}
                        variant="outlined"
                        className={`remove_question_image ${this.props.classes.subHeading_font}`}
                        onClick={(e: any) =>
                          this.removeSelectedImage(
                            e,
                            this.state.mainGalleryImageId
                          )
                        }
                      >
                        Remove
                      </Button>
                    </>
                  ) : (
                    <>
                      <img
                        src={imgPlusIconSVG}
                        className="img_plus_icon"
                        onClick={() =>
                          this.setState({
                            openSelectImageModal: true,
                            optionImageSelection: false,
                          })
                        }
                      />
                      <input
                        ref={this.inputOpenFileRef}
                        accept="application/pdf, image/png, image/jpg, image/jpeg,"
                        className="hidden_input"
                        type="file"
                        onChange={(e: any) => {
                          let totalSize: number = 0;
                          var files = e.target.files;
                          const keys = Object.keys(files);
                          keys?.map(async (key: any) => {
                            const file = files[key];
                            totalSize = totalSize + file.size;
                          });
                          if (
                            e.target.files.length <= 1 &&
                            totalSize < 524288000
                          ) {
                            this.handleFileOnChange(e, null);
                          } else {
                            if (e.target.files.length > 1) {
                              this.setState({
                                alertMessage: "You can upload maximum 1 files",
                                alertModal: true,
                              });
                            } else {
                              this.setState({
                                alertMessage: "Maximum files size: 500MB",
                                alertModal: true,
                              });
                            }
                          }
                        }}
                      />

                      <Typography className={`upload_image_text ${this.props.classes.bodyText_font}`}>
                        Drag and drop file here or browse them from your
                        computer.
                      </Typography>
                    </>
                  )}
                </>
              )}
            </div>
          </FileDrop>

          <div className="match_type_div">
            <Typography className={`match_list_text ${this.props.classes.subHeading_font}`}>
              List
            </Typography>
            <div
              onDrop={drop}
              onDragOver={dragOver1}
              className="match_questions_list"
            >
              {questionList
                ?.filter((item: any) => !item._destroy)
                ?.map((data: any, index: number) => (
                  <div
                    className="match_question_div"
                    draggable
                    id={`question-${data.questionId}`}
                    onDragStart={dragStart}
                    onDragOver={dragOver}
                  >
                    {data.isImageAvailble ? (
                      <div className="match_img_div">
                        <img
                          id={`question-${data.questionId}`}
                          src={imageDragSVG}
                          className="match_drag_icon_2"
                          onTouchStart={dragTouchStart}
                          onTouchMove={dragTouchMove}
                          onTouchEnd={(e: any) => touchDropEndInternalList(e)}
                        />
                        <img
                          id={`question-${data.questionId}`}
                          src={data.src}
                          className="match_question_img"
                          onClick={() => {
                            this.setState({
                              previewImageModalSrc: data.src,
                              previewImageModal: true,
                            });
                          }}
                        />

                        <Typography className={`match_img_name ${this.props.classes.subHeading_font}`}>
                          {data.name}
                        </Typography>
                        <ClearIcon
                          className={`match_img_clear ${this.props.classes.icon_button_color}`}
                          onClick={() => {
                            let tempResponseList = this.state.questionList;
                            const findData = this.state.questionList[index];
                            tempResponseList[index] = {
                              ...findData,
                              src: "",
                              name: "",
                              isImageAvailble: false,
                            };
                            this.setState({
                              questionList: tempResponseList,
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <TextField
                        id="standard-basic"
                        placeholder={`Item #${index + 1}`}
                        style={form_input_hint_number}
                        variant="outlined"
                        defaultValue={data?.question}
                        value={data?.question}
                        inputProps={{
                          style: {
                            fontFamily: "Open sans",
                            fontSize: 15.8,
                            color: "#03014c",
                          },
                        }}
                        onChange={() =>
                          this.questionOnchange(event, data.questionId)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                id={`question-${data.questionId}`}
                                src={imageDragSVG}
                                className="match_drag_icon"
                                onClick={() => { }}
                                onTouchStart={dragTouchStart}
                                onTouchMove={dragTouchMove}
                                onTouchEnd={(e: any) => touchDropEndInternalList(e)}
                              />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <img
                                src={optionImageSVG}
                                className="upload_option_img"
                                onClick={() => {
                                  this.setState({
                                    openSelectImageModal: true,
                                    selectedOptionNumber: data.questionId,
                                    optionImageSelection: true,
                                    isUploadImageQuestion: true,
                                  });
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    <CloseIcon
                      className="remove_match_icon"
                      onClick={() =>
                        this.removeQuestionOnClick(data.questionId)
                      }
                    />
                  </div>
                ))}
            </div>
            <Button
              onClick={this.addQuestionOnClick}
              className={`add_new_match_btn ${this.props.classes.outline_button_no_hover}`}
            >
              <AddIcon className="add_new_match_icon" />
              Add New
            </Button>
          </div>

          <div className="match_type_div">
            <Typography className={`match_list_text ${this.props.classes.subHeading_font}`}>
              Possible Responses
            </Typography>
            <div
              onDrop={dropResponse}
              onDragOver={dragOver1Response}
              className="match_responses_list"
            >
              {possibleResponseList
                ?.filter((item: any) => !item._destroy)
                ?.map((data: any, index: number) => (
                  <div
                    className="match_question_div"
                    draggable
                    id={`response-${data.responseId}`}
                    onDragStart={dragStartResponse}
                    onDragOver={dragOverResponse}
                  >
                    {data.isImageAvailble ? (
                      <div className="match_img_div">
                        <img
                          id={`response-${data.responseId}`}
                          src={imageDragSVG}
                          className="match_drag_icon_2"
                          onTouchStart={dragTouchStart}
                          onTouchMove={dragTouchMove}
                          onTouchEnd={(e: any) => touchDropEndInternalResponse(e)}
                        />
                        <img
                          id={`response-${data.responseId}`}
                          src={data.src}
                          className="match_question_img"
                          onClick={() => {
                            this.setState({
                              previewImageModalSrc: data.src,
                              previewImageModal: true,
                            });
                          }}
                        />

                        <Typography className={`match_img_name ${this.props.classes.subHeading_font}`}>
                          {data.name}
                        </Typography>
                        <ClearIcon
                          className={`match_img_clear ${this.props.classes.icon_button_color}`}
                          onClick={() => {
                            let tempResponseList = this.state
                              .possibleResponseList;
                            const findData = this.state.possibleResponseList[
                              index
                            ];
                            tempResponseList[index] = {
                              ...findData,
                              src: "",
                              name: "",
                              isImageAvailble: false,
                            };
                            this.setState({
                              possibleResponseList: tempResponseList,
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <TextField
                        id="standard-basic"
                        placeholder={`Choice ${String.fromCharCode(
                          65 + index
                        )}`}
                        style={form_input_hint_number}
                        variant="outlined"
                        value={data?.response}
                        inputProps={{
                          style: {
                            fontFamily: "Open sans",
                            fontSize: 15.8,
                            color: "#03014c",
                          },
                        }}
                        onChange={(event: any) =>
                          this.possibleResponseOnchange(event, data.responseId)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                id={`response-${data.responseId}`}
                                src={imageDragSVG}
                                className="match_drag_icon"
                                onClick={() => { }}
                                onTouchStart={dragTouchStart}
                                onTouchMove={dragTouchMove}
                                onTouchEnd={(e: any) => touchDropEndInternalResponse(e)}
                              />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <img
                                src={optionImageSVG}
                                className="upload_option_img"
                                onClick={() => {
                                  this.setState({
                                    openSelectImageModal: true,
                                    selectedOptionNumber: data.responseId,
                                    optionImageSelection: true,
                                    isUploadImageQuestion: false,
                                  });
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    <CloseIcon
                      className="remove_match_icon"
                      onClick={() =>
                        this.removePossibleResponseOnClick(data.responseId)
                      }
                    />
                  </div>
                ))}
            </div>
            <Button
              onClick={this.addPossibleResponseOnClick}
              className={`add_new_match_btn ${this.props.classes.outline_button_no_hover}`}
            >
              <AddIcon className="add_new_match_icon" />
              Add New
            </Button>
          </div>

          <input
            ref={this.inputOpenFileQuesAnsRef}
            accept="application/pdf, image/png, image/jpg, image/jpeg,"
            className="hidden_input"
            type="file"
            onChange={(e: any) => {
              let totalSize: number = 0;
              var files = e.target.files;
              const keys = Object.keys(files);
              keys?.map(async (key: any) => {
                const file = files[key];
                totalSize = totalSize + file.size;
              });
              if (e.target.files.length <= 1 && totalSize < 524288000) {
                if (this.state.isUploadImageQuestion) {
                  this.handleOptionFileChange(
                    e,
                    null,
                    this.state.selectedOptionNumber
                  );
                } else {
                  this.handleOptionFileChangeResponse(
                    e,
                    null,
                    this.state.selectedOptionNumber
                  );
                }
              } else {
                if (e.target.files.length > 1) {
                  this.setState({
                    alertMessage: "You can upload maximum 1 files",
                    alertModal: true,
                  });
                } else {
                  this.setState({
                    alertMessage: "Maximum files size: 500MB",
                    alertModal: true,
                  });
                }
              }
            }}
          />

          <div className="match_type_div">
            <Typography className={`match_list_text ${this.props.classes.subHeading_font}`}>
              Set Correct Answer(s)
            </Typography>
            <div className="question_flex_column">
              {questionList
                ?.filter((item: any) => !item._destroy)
                ?.map((data: any, index: number) => (
                  <>
                    {(data?.question || data?.src) && (
                      <Grid container className="set_answers_container">
                        <Grid item xs={5}>
                          <div className="set_answers_div">
                            {!data?.src ? (
                              <Typography className={`match_pair_question ${this.props.classes.subHeading_font}`}>
                                {data?.question}
                              </Typography>
                            ) : (
                              <>
                                <img
                                  src={data.src}
                                  className="match_question_img"
                                  onClick={() => {
                                    this.setState({
                                      previewImageModalSrc: data.src,
                                      previewImageModal: true,
                                    });
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className="match_pair_img"
                        >
                          <img src={matchGroupSVG} className="match_grp_img" />
                        </Grid>
                        <Grid item xs={5}>
                          <div
                            className="match_pair_answer_div"
                            onDrop={(e: any) => dropAnswer(e, data?.questionId)}
                            draggable
                            id={`answer-${data.questionId}`}
                            onDragStart={dragStartAnswer}
                            onDragOver={dragOver1Answer}
                            onTouchStart={dragTouchStart}
                            onTouchMove={dragTouchMove}
                            onTouchEnd={(e: any) => touchDropEndInternal(e)}
                          >
                            {!data?.answerSrc ? (
                              <Typography
                                id={`answer-${data.questionId}`}
                                className={`match_pair_question ${this.props.classes.subHeading_font}`}
                              >
                                {data?.answer}
                              </Typography>
                            ) : (
                              <>
                                <img
                                  id={`answer-${data.questionId}`}
                                  src={data.answerSrc}
                                  className="match_question_img"
                                  onClick={() => {
                                    this.setState({
                                      previewImageModalSrc: data.answerSrc,
                                      previewImageModal: true,
                                    });
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    )}
                  </>
                ))}
            </div>
          </div>

          <div className="drag_drop_div">
            <Typography className={`drag_and_drop_text ${this.props.classes.subHeading_font}`}>
              Drag & Drop the Answer
            </Typography>
            <div className="possible_response_div">
              {possibleResponseList
                ?.filter((item: any) => !item._destroy)
                ?.map((data: any, index: number) => (
                  <>
                    {(data?.response || data?.isImageAvailble) && (
                      <div className="possible_response_item"
                        style={{
                          touchAction: !this.checkIfOptionAlreadyMatched(
                            data.responseId
                          )
                            ? "none"
                            : "",
                          opacity: this.checkIfOptionAlreadyMatched(
                            data.responseId
                          )
                            ? 0.5
                            : 1,
                        }}
                        draggable={
                          !this.checkIfOptionAlreadyMatched(data.responseId)
                        }
                        id={`answer-${data.responseId}`}
                        onDragStart={dragStartResponse}
                        onDragOver={dragOverResponse}
                        onTouchStart={dragTouchStart}
                        onTouchMove={dragTouchMove}
                        onTouchEnd={(e: any) => touchDropEnd(e)}
                      >
                        <>
                          {data?.response ? (
                            <Typography className={`drag_and_drop_text ${this.props.classes.subHeading_font}`}>
                              {data?.response}
                            </Typography>
                          ) : (
                            <>
                              <img
                                id={`answer-${data.responseId}`}
                                src={data.src}
                                className="match_question_img"
                                onClick={() => {
                                  this.setState({
                                    previewImageModalSrc: data.src,
                                    previewImageModal: true,
                                  });
                                }}
                              />
                            </>
                          )}
                        </>
                      </div>
                    )}
                  </>
                ))}
            </div>
          </div>

          <QuestionSolution
            hintAnswer={this.state.hintAnswer}
            hintAnswerOnchange={(value: any, id: any) => {
              let tempData = this.state.hintAnswer;
              const index = this.state.hintAnswer.findIndex(
                (res: any) => res.hintId === id
              );
              tempData[index].hint = value.trim();
              this.setState({
                hintAnswer: tempData,
              });
            }}
            explaination={this.getExplanation()}
            getPreSignedValue={(
              preSignedId: string,
              typeofAttachment: string,
              blobUrl: string
            ) => {
              if (typeofAttachment === "hints") {
                let tempData = this.state.hintAnswer;
                tempData[0].files = [preSignedId];
                tempData[0].src = blobUrl;
                this.setState({
                  hintAnswer: tempData,
                  hintId: preSignedId,
                });
              } else {
                this.setState({ solutionImageSignedId: preSignedId });
              }
            }}
            explainationOnchange={(value: any) => {
              this.setState({
                explaination: value,
              });
            }}
            attachmentExplanationUrl={this.state.attachmentExplanationUrl}
            hintsExplanationUrl={this.state.hintsExplanationUrl}
            removeSolutionImage={(attachmentType: string) => {
              if (attachmentType === "hints") {
                this.setState({
                  hintsExplanationUrl: "",
                  hintId: null,
                });
              } else {
                this.setState({
                  attachmentExplanationUrl: "",
                  solutionImageSignedId: null,
                });
              }
            }}
          />
          <QuestionMetaData
            selectedSubject={this.state.selectedSubject}
            onChangeSubject={(e: any) => {
              this.setState({ selectedSubject: e.target.value });
            }}
            chapterListData={this.state.chapterListData}
            selectedChapter={this.state.selectedChapter}
            onChangeChapter={(e: any) => {
              this.setState(
                {
                  selectedChapter: e.target.value,
                  metaDataErrors: {
                    ...this.state.metaDataErrors,
                    chapter: false,
                  },
                },
                () => this.get_unit_list(this.state.selectedChapter, true)
              );
            }}
            unitListData={this.state.unitListData}
            selectedUnit={this.state.selectedUnit}
            onChangeUnit={(e: any) => {
              this.setState({
                selectedUnit: e.target.value,
                metaDataErrors: { ...this.state.metaDataErrors, unit: false },
              });
            }}
            selectedSkill={this.state.selectedSkill}
            onChangeSkill={(e: any) => {
              this.setState({
                selectedSkill: e.target.value,
                metaDataErrors: { ...this.state.metaDataErrors, skill: false },
              });
            }}
            selectedProficiency={this.state.selectedProficiency}
            onChangeProficiency={(e: any) => {
              this.setState({
                selectedProficiency: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  proficiency: false,
                },
              });
            }}
            selectedDifficultyLevel={this.state.selectedDifficultyLevel}
            onChangeDifficultyLevel={(e: any) => {
              this.setState({
                selectedDifficultyLevel: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  difficultyLevel: false,
                },
              });
            }}
            keyword={this.state.keyword}
            keywordsList={this.state.keywordsList}
            onChangeKeywords={this.onChangeKeywords}
            onBlurKeywords={this.onBlurKeywords}
            onKeyDownKeywords={this.onKeyDownKeywords}
            handleDeleteKeyword={this.handleDeleteKeyword}
            metaDataErrors={this.state.metaDataErrors}
            selectedBloomTaxonomy={this.state.selectedBloomTaxonomy}
            onChangeBloomTaxonomy={(e: any) => {
              this.setState({
                selectedBloomTaxonomy: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  bloomTaxonomy: false,
                },
              });
            }}
          />

          <div className="question_action_items">
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.background_button_no_hover}`}
              onClick={() => {
                this.setState({ prevQuestionModal: true });
              }}
            >
              <Box className="preview_btn_box">
                <VisibilityIcon className="eye_preview" />
                <Typography> Preview</Typography>
              </Box>
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.button_color}`}
              onClick={this.saveQuestion}
            >
              {this.state.editButton ? "Update" : "Save"}
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_cancel_btn ${this.props.classes.outline_button_no_hover}`}
              onClick={()=>HISTORY.push("/TeacherAssessmentCreateSection")}
            >
              Cancel
            </Button>
          </div>

          <QuestionPreviewModal
            questionType={"match"}
            selectedImageBlobUrl={this.state.selectedImageBlobUrl}
            questionPreviewModal={this.state.prevQuestionModal}
            closeModalFun={() => {
              this.setState({ prevQuestionModal: false });
            }}
            question={this.state.question}
            questionHint={this.state.hintAnswer}
            matchQuestionList={questionList}
          />

          <AlertModal
            alertModal={this.state.alertModal}
            isTeacher={true}
            onCloseModal={() => {
              this.setState({ alertModal: false });
            }}
            alertTitle="Alert!"
            alertMessage={this.state.alertMessage}
            buttonText="OK"
          />

          <SelectImageModal
            openSelectImageModal={this.state.openSelectImageModal}
            onCloseModal={() => this.setState({ openSelectImageModal: false })}
            onClickChooseFromGallery={() => {
              this.setState({
                openChooseFromGalleryModal: true,
                openSelectImageModal: false,
              });
            }}
            onClickUploadFromLocal={
              this.state.optionImageSelection
                ? this.showOpenFileQuesAnsDlg
                : this.showOpenFileDlg
            }
          />

          <ChooseFromGalleryModal
            openChooseFromGalleryModal={this.state.openChooseFromGalleryModal}
            onCloseModal={() =>
              this.setState({ openChooseFromGalleryModal: false })
            }
            onClickImage={(id: any, url: any) => {
              if (this.state.optionImageSelection) {
                if (this.state.isUploadImageQuestion) {
                  let TempResponseData = this.state.questionList;
                  const findIndex = this.state.questionList.findIndex(
                    (res: any) =>
                      res.questionId === this.state.selectedOptionNumber
                  );
                  const findData = this.state.questionList.find(
                    (res: any) =>
                      res.questionId === this.state.selectedOptionNumber
                  );
                  TempResponseData[findIndex] = {
                    ...findData,
                    src: url,
                    upload_id: id,
                    name: "Gallery Image",
                    isImageAvailble: true,
                    question: "",
                  };
                  this.setState({
                    selectedGalleryImageId: id,
                    questionList: TempResponseData,
                  });
                } else {
                  let TempResponseData = this.state.possibleResponseList;
                  const findIndex = this.state.possibleResponseList.findIndex(
                    (res: any) =>
                      res.responseId === this.state.selectedOptionNumber
                  );
                  const findData = this.state.possibleResponseList.find(
                    (res: any) =>
                      res.responseId === this.state.selectedOptionNumber
                  );
                  TempResponseData[findIndex] = {
                    ...findData,
                    src: url,
                    upload_id: id,
                    name: "Gallery Image",
                    isImageAvailble: true,
                    response: "",
                  };
                  this.setState({
                    selectedGalleryImageId: id,
                    possibleResponseList: TempResponseData,
                  });
                }
              } else {
                this.setState({
                  mainGalleryImageId: id,
                  selectedGalleryImageId: id,
                  selectedGalleryImageUrl: url,
                });
              }
            }}
            onClickChoose={() => this.onClickGalleryImageChoose()}
            selectedGalleryImageId={this.state.selectedGalleryImageId}
            onTabChangeClearId={() =>
              this.setState({ selectedGalleryImageId: 0 })
            }
          />
        </div>

        <ImagePreivewModal
          previewImageModal={this.state.previewImageModal}
          modalhandleClose={() =>
            this.setState({
              previewImageModal: false,
            })
          }
          previewImage={this.state.previewImageModalSrc}
        />
        {this.state.editButton && (
          <QuestionSidebarCollapse
            editedQuestionData={HISTORY.location.state?.questionData}
          />
        )}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(
  MatchTypeQuestion as React.ComponentType<any>
);

// Customizable Area End
