// Customizable Area Start
import React from "react";
import AssessmentTabController from "./AssessmentTabController.web";
import {
    Box,
    Grid,
    NativeSelect,
    Typography,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { ClassSelect } from "./AssessmentTab.style.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import './AssessmentTab.web.css';
import LibraryPlayIcon from "./LibraryPlayIcon.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class AssessmentTab extends AssessmentTabController {
    // Customizable Area Start
    render() {
        const { teacherSubjects, showLoader, gradesList } = this.state;
        const { classes } = this.props;
        return (
            <>
                <Spinner spinnerModal={showLoader} />
                <Grid className="SubjectAssessmentMainGrid">
                    <Grid item xs={3} className={`ClassBox`}>
                        <Box>
                            <Typography
                                className={`ClassDropDownTitle ${classes.subHeading_font}`}
                            >
                                Class
                            </Typography>

                            <NativeSelect
                                id="select"
                                value={this.state.grade_id}
                                input={<ClassSelect />}
                                IconComponent={ArrowDropDown}
                                onChange={this.handleClassChange}
                            >
                                <option value="" disabled>Select Class</option>
                                {
                                    gradesList.length != 0 && gradesList.map((grade: any) => (
                                        <option key={grade?.id} value={grade?.id} >{grade?.attributes?.name}</option>
                                    ))
                                }
                            </NativeSelect>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={`ListBox`}>
                        {
                            teacherSubjects?.length === 0
                                ?
                                <>
                                    <Typography
                                        className={`NoRecordText ${classes.bodyText_font}`}
                                    >
                                        No record(s) found.
                                    </Typography>
                                </>
                                :
                                teacherSubjects?.map((data: any) => (
                                    <React.Fragment key={data.id}>
                                        <div className={`ListItem`}>
                                            <Grid
                                                container
                                                className="SubjectAssessments"
                                            >
                                                <Grid item xs={12} sm={6} md={7} lg={8}>
                                                    <div
                                                        className={`SubjectName ${classes.subHeading_font}`}
                                                    >
                                                        {data?.subject_name}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={10} sm={4} md={3} lg={3} className="TotalAssessmentGrid">
                                                    <div
                                                        className={`TotalAssessments ${classes.bodyText_font}`}
                                                    >
                                                        Total Assessments: {data?.total_assessments}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={1}>
                                                    <div
                                                        className="ImageDiv"
                                                    >
                                                        <LibraryPlayIcon
                                                            data={data}
                                                            handleShowAssessments={this.handleShowAssessments}
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </React.Fragment>
                                ))}
                    </Grid>
                </Grid>
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(AssessmentTab);
// Customizable Area End
