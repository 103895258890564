//@ts-nocheck
// Customizable Area Start
import React, { useState, useRef, useEffect } from 'react'
import "./AudioList.web.css";
import {
    playButtonSVG,
    pauseButtonSVG,
    downloadBtnSVG,
    blueDeleteSVG,
} from "../assets";
import {
    Grid, IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { downloadFile } from "../../../AssessmentTest/src/utils/common";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
function AudioList(props: any) {
    // state
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    // references
    const audioPlayer = useRef();   // reference our audio component
    const progressBar = useRef();   // reference our progress bar
    const animationRef = useRef();  // reference the animation

    const calculateTime = (secs) => {
        if (secs === Infinity) {
            return 'NA'
        }
        const minutes = Math.floor(secs / 60);
        const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${returnedMinutes}:${returnedSeconds}`;
    }

    const togglePlayPause = () => {
        const prevValue = isPlaying;
        setIsPlaying(!prevValue);
        if (!prevValue) {
            audioPlayer.current.play();
            animationRef.current = requestAnimationFrame(whilePlaying)
        } else {
            audioPlayer.current.pause();
            cancelAnimationFrame(animationRef.current);
        }
    }

    useEffect(() => {
        var checkStop = setTimeout(() => {
            if (audioPlayer.current.ended && isPlaying) {
                setDuration(currentTime);
                progressBar.current.max = currentTime;
                props.setAudioDuration(currentTime);
                setIsPlaying(false);
                setCurrentTime(0);
                audioPlayer.current.pause();
                cancelAnimationFrame(animationRef.current);
                progressBar.current.value = 0;
                changePlayerCurrentTime()
            }
        }, 2000);
        return () => {
            clearInterval(checkStop);
        }
    }, [currentTime])


    const whilePlaying = () => {
        progressBar.current.value = audioPlayer.current.currentTime;
        changePlayerCurrentTime();
        animationRef.current = requestAnimationFrame(whilePlaying);
    }

    const changeRange = () => {
        audioPlayer.current.currentTime = progressBar.current.value;
        changePlayerCurrentTime();
    }

    const changePlayerCurrentTime = () => {
        progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / duration * 100}%`)
        setCurrentTime(progressBar.current.value);
    }
    const onLoadedMetadata = () => {
        if (audioPlayer.current.duration === Infinity) {
            if (audioPlayer.current.duration === Infinity) {
                audioPlayer.current.currentTime = 1e101
                audioPlayer.current.addEventListener('timeupdate', getDuration)
            }

            function getDuration() {
                audioPlayer.current.currentTime = 0
                audioPlayer.current.removeEventListener('timeupdate', getDuration)
                let seconds = Math.floor(audioPlayer.current.duration);
                if (seconds > 0 && props.showActions && !props.audio.duration) {
                    props.setAudioDuration(seconds)
                }
                if (props.audio.duration) {
                    seconds = props.audio.duration;
                }
                setDuration(seconds);
                progressBar.current.max = seconds;
            }
        } else {
            let seconds = Math.floor(audioPlayer.current.duration);
            if (seconds > 0 && props.showActions && !props.audio.duration) {
                props.setAudioDuration(seconds)
            }
            if (props.audio.duration) {
                seconds = props.audio.duration;
            }
            setDuration(seconds);
            progressBar.current.max = seconds;
        }
    }
    return (
        <Grid container spacing={2} className="audioContainer">
            <Grid item xs={3} sm={2}>
                <div className={`audioNameDiv ${props.classes.subHeading_font}`}>
                    <span className="audioName">{props.audio.file_name}</span>
                </div>
                {props.isStudent
                    ?
                    <>
                        <div className={`audioDuration ${props.classes.subHeading_font}`}>
                            {props.audio.duration_time}
                        </div>
                    </>
                    :
                    <>
                        {
                            props.audio.duration ? (
                                <div className={`audioDuration ${props.classes.subHeading_font}`}>
                                    {props.audio.duration && calculateTime(isNaN(props.audio.duration) ? Infinity : props.audio.duration)}
                                </div>
                            ) : (
                                <div className={`audioDuration ${props.classes.subHeading_font}`}>
                                    {duration && calculateTime(isNaN(duration) ? Infinity : duration)}
                                </div>
                            )
                        }
                    </>
                }

            </Grid>
            <Grid item xs={6} sm={8} className="audioPlayerGrid">
                <div className="audioPlayer">
                    <audio ref={audioPlayer} src={props.audio.file_url} preload="metadata" onLoadedMetadata={onLoadedMetadata}></audio>

                    <div className="audioRange">
                        <input type="range" defaultValue={0} ref={progressBar} onChange={changeRange} className={`progressBar ${props.classes.progressBar}`} />
                    </div>
                </div>
                <div className="durationDiv">
                    {props.isStudent
                        ?
                        <>
                            <div className={`audioDuration ${props.classes.subHeading_font}`}>
                                {props.audio.duration_time}
                            </div>
                        </>
                        :
                        <>
                            {
                                props.audio.duration ? (
                                    <div className="duration">{props.audio.duration && calculateTime(isNaN(props.audio.duration) ? Infinity : props.audio.duration)}</div>
                                ) : (
                                    <div className="duration">{duration && calculateTime(isNaN(duration) ? Infinity : duration)}</div>
                                )
                            }
                        </>
                    }

                    <div className="currentTime">{calculateTime(currentTime)}</div>
                </div>
            </Grid>
            <Grid item xs={3} sm={2} className="audioActions">
                <IconButton onClick={togglePlayPause} >
                    {isPlaying ? <img src={pauseButtonSVG} width={18} height={18} /> : <img src={playButtonSVG} width={18} height={18} />}
                </IconButton>
                {props.showActions &&
                    <>
                        <IconButton onClick={() => { downloadFile(props.audio.file, props.audio.file_name) }} >
                            <img src={downloadBtnSVG} width={18} height={18} />
                        </IconButton>
                        {!props.fromEvaluation &&
                            <IconButton onClick={props.removeAudio} >
                                <img src={blueDeleteSVG} width={18} height={18} />
                            </IconButton>
                        }
                    </>
                }

            </Grid>
        </Grid>
    );
}
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(AudioList);
// Customizable Area End