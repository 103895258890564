// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { HISTORY } from "../../../components/src/comman";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  assessmentList: any;
  assessmentListMeta: any;
  queryTerm: any;
  page: number;
  rowsPerPage: number;
  termAsc: boolean;
  classAsc: boolean;
  divisionAsc: boolean;
  statusAsc: boolean;
  resultAsc: boolean;
  anchorEl: any;
  classList: any;
  divisionList: any;
  filterClassId: any;
  filterDivisionId: any;
  filterStatus: any;
  showLoader: boolean;
  subjectList: any;
  stateAssessment: any;
  selectedMonth: any;
  selectedType: any;
  selectedClassId: any;
  selectedTerm: any;
  selectedSubject: any;
  termsList: any;
  gradesList: any;
  yearMonthsList: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherAssessmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAssessmentList: string = "";
  apiGetClassList: string = "";
  apiGetDivisionList: string = "";
  apiGetSubjectList: string = "";
  apiGetTermsList: string = "";
  apiStateAssessmentList: string = "";
  apiGetGradesList: string = "";
  apiGetAssessmentData: string = "";
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      assessmentList: [],
      assessmentListMeta: {},
      queryTerm: "",
      page: 0,
      rowsPerPage: 10,
      termAsc: true,
      classAsc: true,
      divisionAsc: true,
      statusAsc: true,
      resultAsc: true,
      anchorEl: null,
      classList: [],
      divisionList: [],
      filterClassId: "",
      filterDivisionId: "",
      filterStatus: "",
      showLoader: false,
      subjectList: [],
      stateAssessment: null,
      selectedMonth: "",
      selectedType: "",
      selectedClassId: "",
      selectedTerm: "",
      selectedSubject: "",
      termsList: [],
      gradesList: [],
      yearMonthsList: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetAssessmentList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              assessmentList: responseJson?.data,
              assessmentListMeta: responseJson?.meta,
              showLoader: false,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiGetClassList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ classList: responseJson.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiGetDivisionList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ divisionList: responseJson.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiGetSubjectList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ subjectList: responseJson.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiGetTermsList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ termsList: responseJson?.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiStateAssessmentList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ stateAssessment: responseJson });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiGetGradesList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ gradesList: responseJson.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiGetAssessmentData) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            //  creating editable data
            this.configureAssessmentStorageAndEdit(responseJson.data);
            //  creating editable data END
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({
          showLoader: false,
        });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const monthsArray: any = [];
    for (let i = 0; i < 12; i++) {
      const monthItem = {
        value: moment()
          .subtract(i, "month")
          .startOf("month")
          .format("MMMM-YYYY"),
        label: moment()
          .subtract(i, "month")
          .startOf("month")
          .format("MMMM-YYYY"),
      };
      monthsArray.push(monthItem);
    }
    this.setState({ yearMonthsList: monthsArray.reverse() });
    this.getStateAssessment();
    this.getAssessmentList();
    this.getGradesList();

    // removing assessment Data
    localStorage.removeItem("assessmentData");
    localStorage.removeItem("came_from_assessment_list");
    localStorage.removeItem("assessment_library_setting");
    // removing assessment Data END
  }

  getEmptyState = () => ({});

  assessmentModuleUrl = () => {
    HISTORY.push("/TeacherAssessmentModule");
  };

  handleBack = () => {
    HISTORY.goBack();
  };

  getStatusTextColor = (status: string) => {
    switch (status) {
      case "Scheduled":
        return "#06b0b6";
      case "Ongoing":
        return "#e69416";
      case "Completed":
        return "#68b21a";
      case "Save as draft":
        return "#3a60d7";
      default:
        return "#68b21a";
    }
  };

  onChangeSearchTerm = (event: any) => {
    this.setState({ queryTerm: event.target.value });
  };

  termArrowClick = () => {
    const sortedData = _.orderBy(
      this.state.assessmentList,
      "attributes.terms",
      this.state.termAsc ? "desc" : "asc"
    );
    this.setState({ assessmentList: sortedData, termAsc: !this.state.termAsc });
  };

  classArrowClick = () => {
    const sortedData = _.orderBy(
      this.state.assessmentList,
      "attributes.class_name.attributes.class_name",
      this.state.classAsc ? "desc" : "asc"
    );
    this.setState({
      assessmentList: sortedData,
      classAsc: !this.state.classAsc,
    });
  };

  divisionArrowClick = () => {
    const sortedData = _.orderBy(
      this.state.assessmentList,
      "attributes.class_name.attributes.divisions.data.attributes.name",
      this.state.divisionAsc ? "desc" : "asc"
    );
    this.setState({
      assessmentList: sortedData,
      divisionAsc: !this.state.divisionAsc,
    });
  };

  statusArrowClick = () => {
    const sortedData = _.orderBy(
      this.state.assessmentList,
      "attributes.status",
      this.state.statusAsc ? "desc" : "asc"
    );
    this.setState({
      assessmentList: sortedData,
      statusAsc: !this.state.statusAsc,
    });
  };

  resultArrowClick = () => {
    const sortedData = _.orderBy(
      this.state.assessmentList,
      "attributes.result",
      this.state.resultAsc ? "desc" : "asc"
    );
    this.setState({
      assessmentList: sortedData,
      resultAsc: !this.state.resultAsc,
    });
  };

  onMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  getStateAssessment = () => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiStateAssessmentList = requestMessage.messageId;

    let apiEndPoint =
      configJSON.getStateAssessmentEndPoint +
      `?school_id=${school_Data?.school_id}`;

    this.setState({ showLoader: true });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAssessmentList = () => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAssessmentList = requestMessage.messageId;

    let apiEndPoint =
      configJSON.getAssessmentsListEndPoint +
      `?page=${this.state.page + 1}&per=${this.state.rowsPerPage}`;

    if (this.state.selectedMonth) {
      apiEndPoint = apiEndPoint + `&month=${this.state.selectedMonth}`;
    }

    if (this.state.selectedType) {
      apiEndPoint = apiEndPoint + `&exam_type=${this.state.selectedType}`;
    }

    if (this.state.selectedClassId) {
      apiEndPoint =
        apiEndPoint + `&grade_id=${Number(this.state.selectedClassId)}`;
    }

    if (this.state.selectedTerm) {
      apiEndPoint = apiEndPoint + `&term_id=${this.state.selectedTerm}`;
    }

    if (this.state.selectedSubject) {
      apiEndPoint =
        apiEndPoint + `&subject_id=${Number(this.state.selectedSubject)}`;
    }
    this.setState({ showLoader: true });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getClassList = () => {
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetClassList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClassesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDivisionsList = (gradeId: string) => {
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDivisionList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDivisionsEndPoint + `?grade_id=${gradeId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubjectsList = (classGradeId: string) => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSubjectList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectByGradeEndPoint + `?grade_id=${classGradeId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  gettermList = (classId: string) => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetTermsList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termListEndPoint +
        `?school_id=${school_Data?.school_id}&grade_id=${classId}&per=100`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getGradesList = () => {
    const user_data = localStorage.getItem("user_data");
    const schoolData = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: schoolData?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetGradesList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGradeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMonthChange = (event: any) => {
    const value = event.target.value;
    this.setState({ selectedMonth: value, page: 0 }, () =>
      this.getAssessmentList()
    );
  };

  handleTypeChange = (event: any) => {
    const value = event.target.value;
    this.setState({ selectedType: value, page: 0 }, () =>
      this.getAssessmentList()
    );
  };

  handleClassChange = (event: any) => {
    const value = event.target.value;
    const gradeData = this.state.gradesList.find(
      (gradeItem: any) => gradeItem.id == value
    );

    this.setState({ selectedClassId: event.target.value, page: 0 }, () => {
      this.getAssessmentList();
      this.setState({ selectedSubject: "" });
      if (this.state.selectedClassId !== 0) {
        this.getSubjectsList(gradeData?.id);
        this.gettermList(gradeData?.id);
      }
    });
  };

  handleTermChange = (event: any) => {
    const value = event.target.value;
    this.setState({ selectedTerm: value, page: 0 }, () =>
      this.getAssessmentList()
    );
  };

  handleSubjectChange = (event: any) => {
    const value = event.target.value;
    this.setState({ selectedSubject: value, page: 0 }, () =>
      this.getAssessmentList()
    );
  };

  openAssessmentEvaluation = (assessmentId: number) => {
    HISTORY.push({
      pathname: "/AssessmentEvaluation",
      state: { assessmentId: assessmentId },
    });
  };

  handleEditAssessment = (id: any) => {
    this.setState({
      showLoader: true,
    });
    const user_data = localStorage.getItem("user_data");
    const schoolData = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: schoolData?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAssessmentData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAssessmentsEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  configureAssessmentStorageAndEdit = (data: any) => {
    let assessmentData: any = {
      assessment_id: data.id,
      assessment_category_id: data.attributes.assessment_category_id,
      assessment_name: data.attributes.assessment_name,
      grade_id: data.attributes.grade_id,
      grade: data.attributes.grade,
      subject_id: data.attributes.subject_id,
      subject: data.attributes.subject_name,
      exam_type: data.attributes.exam_type,
      maximum_marks: data.attributes.maximum_marks,
      term_id: data.attributes.term.id,
      term: [
        {
          id: data.attributes.term.id,
          type: "term",
          attributes: {
            grade_id: data.attributes.term.grade_id,
            school_id: data.attributes.term.school_id,
            account_id: data.attributes.term.account_id,
            title: data.attributes.term.title,
            session_start: data.attributes.term.session_start,
            session_end: data.attributes.term.session_end,
          },
        },
      ],
      negative_marking: data.attributes.negative_marking,
      time_limit: data.attributes.time_limit,
      difficulty_level: data.attributes.difficulty_level,
      assessment_theme: data.attributes.assessment_theme,
    };

    // Guidelines
    const guidelines = data.attributes.assessment_guide_lines.reduce(
      (result: any, guideline: any) => {
        result.push({
          id: uuidv4(),
          description: guideline.description,
          error: false,
          guideline_id: guideline.id,
        });
        return result;
      },
      []
    );
    // Guidelines END

    // section data
    const sections = data.attributes.assessment_sections.data.reduce(
      (result: any, section: any) => {
        result.push({
          id: uuidv4(),
          section_id: section.id,
          name: section.attributes.name,
          section_marks: section.attributes.section_marks,
          remaining_marks: section.attributes.remaining_marks,
          total_questions: section.attributes.total_questions,
          time_limit: section.attributes.time_limit,
          question_data: section.attributes.questions.data.reduce(
            (questionOutput: any, question: any) => {
              questionOutput.push({
                id: question.id,
                title: question.attributes.title,
                type: question.attributes.type.name,
                marks: question.attributes.marks,
                negative_marks: question.attributes.negative_marks,
              });
              return questionOutput;
            },
            []
          ),
        });
        return result;
      },
      []
    );
    console.log("HERERRER EGKHJKJKB");
    // section data  END
    assessmentData.assessment_guidelines = guidelines;
    assessmentData.assessment_sections_attributes = sections;
    localStorage.setItem("assessmentData", JSON.stringify(assessmentData));

    HISTORY.push({
      pathname: "/TeacherAssessmentCreateDetail",
    });
  };
  // Customizable Area End
}
