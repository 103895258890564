// Customizable Area Start
import { runEngine } from '../../../../framework/src/RunEngine';
import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../../framework/src/Messages/MessageEnum';
import SurveysController from '../../../Surveys/src/SurveysController';
export const configJSON = require('../config.js');
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  classes: any;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabValue: number;
  showLoader: boolean;
  performancePerStudent: any[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MultipleAssessmentReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPerformanceByStudentsId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area Start

    this.state = {
      tabValue: 0,
      showLoader: false,
      performancePerStudent: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getPerformanceByStudentsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              performancePerStudent: responseJson.data,
              showLoader: false,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getPerformancePerStudent('3056');
  }

  getPerformancePerStudent = (assessment_id: string) => {
    this.setState({ showLoader: true });

    // @ts-ignore
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const { school_id } = user_data;

    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('token'),
      school: school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPerformanceByStudentsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPerformanceByStudents}${assessment_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChangeTabs(num: number) {
    this.setState({ tabValue: num });
  }

  // Customizable Area End
}
