// Customizable Area Start
import React from 'react';
import { Grid } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import './EmailAccountRegistration.web.css';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
  img21kSchholLogo,
  student_at,
  teacher_at,
  parent_at,
  student_background,
  teacher_background,
  parent_background,
} from './assets';
import './EmailAccountRegistration.web.css';
import EmailAccountRegistrationController, {
  Props,
} from './EmailAccountRegistrationController.web';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start


// Customizable Area End

export class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const WhiteRadio = withStyles({
      root: {
        color: 'white',
        '&$checked': {
          color: 'white',
        },
      },
      checked: {},
    })((props: RadioProps) => <Radio color="default" {...props} />);

    return (
      <Grid container>
        <Grid className="Header accountRegistration_div" item xs={12}>
          <img src={img21kSchholLogo} alt="Logo Image" className={`logoImage`} />
          <HelpIcon className={`accountRegistration_helpIcon`} />
        </Grid>
        <Grid item xs={12}>
          <form
            onSubmit={() => {
              this.onSubmit();
            }}
          >
            <Grid container className={`accountRegistration_footer`}>
              <Grid
                item
                xs={1}
                className="FlexCenter"
              >
                {' '}
                <IconButton
                  aria-label="delete"
                  className="arrowStyleButtonLeft"
                  color="primary"
                  onClick={() => {
                    this.onBackArrow();
                  }}
                >
                  <ArrowBackRoundedIcon className="WhiteText" />
                </IconButton>
              </Grid>

              <Grid item xs={10} className={`typesofAccount_div`}>
                <div className={`chooseAccount typesofAccount_textLabel ${this.props.classes.theme_font}`}>
                  Choose an account
                </div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.onAccountClick(event)
                    }
                  >
                    <div className="Emai-Row studentAccBackgroundImg" style={{ background: `url(${student_background})` }}>
                      <div className="Avtar-Img avtar_div">
                        <img src={student_at} alt="Avatar" className={`avatar`} />
                      </div>
                      <div className="blockText">
                        <span className='EmailAccount_MarginB10'>
                          Student Account
                        </span>
                        <br />
                        <div className="informationTextLabel">
                          simply dummy text of the printing and typesetting
                          industry.
                        </div>
                      </div>
                      <div className="Radio-Button radioDiv">
                        <WhiteRadio
                          value="student_account"
                          name="radio-button-demo"
                          inputProps={{ 'aria-label': 'A' }}
                          checkedIcon={<CheckCircleOutlineIcon />}
                        />
                      </div>
                    </div>
                    <div className="Emai-Row teacherAccBackgroundImg" style={{ background: `url(${teacher_background})` }}>
                      <div className="Avtar-Img avtar_div">
                        <img src={teacher_at} alt="Avatar" className={`avatar`} />
                      </div>
                      <div className="blockText">
                        <span className="EmailAccount_MarginB10">
                          Teacher Account
                        </span>
                        <br />
                        <div className="informationTextLabel">
                          simply dummy text of the printing and typesetting
                          industry.
                        </div>
                      </div>
                      <div className="Radio-Button radioDiv">
                        <WhiteRadio
                          value="teacher_account"
                          name="radio-button-demo"
                          inputProps={{ 'aria-label': 'A' }}
                          checkedIcon={<CheckCircleOutlineIcon />}
                        />
                      </div>
                    </div>

                    <div className="Emai-Row parentAccBackgroundImg" style={{ background: `url(${parent_background})` }}>
                      <div className="Avtar-Img avtar_div">
                        <img src={parent_at} alt="Avatar" className={`avatar`} />
                      </div>
                      <div className="blockText">
                        <span className='EmailAccount_MarginB10'>Parent Account</span>
                        <br />
                        <div className="informationTextLabel">
                          simply dummy text of the printing and typesetting
                          industry.
                        </div>
                      </div>
                      <div className="Radio-Button radioDiv">
                        <WhiteRadio
                          value="parent_account"
                          name="radio-button-demo"
                          inputProps={{ 'aria-label': 'A' }}
                          checkedIcon={<CheckCircleOutlineIcon />}
                        />
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(EmailAccountRegistration);
// Customizable Area End