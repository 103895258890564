// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const ClassSection: any = (props: any) => {
    return (
        <SvgIcon {...props}>
           <path d="M5.334 6.095a.666.666 0 1 1 0 1.333H2a.667.667 0 0 0-.666.667V25.43c0 .368.298.667.666.667l11.714-.001v-.952a.761.761 0 1 1 1.524 0v.761h1.524v-.761a.761.761 0 1 1 1.524 0l-.001.952H30a.667.667 0 0 0 .666-.666V8.095A.667.667 0 0 0 30 7.428h-3.334a.666.666 0 1 1 0-1.333H30c1.103 0 2 .897 2 2V25.43c0 1.103-.897 2-2 2l-11.715-.001v3.048h3.887c.379 0 .685.341.685.762s-.306.762-.685.762H9.828c-.379 0-.685-.341-.685-.762 0-.42.306-.762.685-.762h3.886v-3.048H2c-1.103 0-2-.896-2-2V8.096c0-1.103.897-2 2-2h3.334zm16.65-5.017c.546.367.873 1 .873 1.695v10.479a1.38 1.38 0 0 1-2.063 1.195c-1.71-.962-3.385-.752-5.123.64a.696.696 0 0 1-.866 0c-1.736-1.39-3.412-1.602-5.123-.64a1.375 1.375 0 0 1-1.385-.01 1.36 1.36 0 0 1-.678-1.185V2.773c0-.694.327-1.328.874-1.695 2.212-1.48 4.533-1.434 6.745.126 2.21-1.56 4.532-1.607 6.745-.126zM9.262 2.231c-.16.106-.259.314-.259.542v10.479c.927-.526 1.858-.79 2.787-.79 1.154 0 2.306.406 3.447 1.216l.225-.155a.616.616 0 0 1-.225-.467V2.763a.693.693 0 0 1-.433-.15c-1.873-1.5-3.685-1.624-5.542-.382zm7.605-.393-.167.086c.04.08.06.167.06.26v10.655c1.564-.614 3.141-.482 4.711.4V2.772c0-.228-.1-.436-.26-.542-1.457-.977-2.888-1.11-4.344-.393z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default ClassSection;
// Customizable Area End