// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
import { accountTypes } from "../utils/constants";
export const configJSON = require("./config.js");
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  userId: any;
  data: any;
  currentSubject: any;
  currentClass: any;
  pagination_page: number;
  assessmentList: Array<{}>;
  totalPages: number;
  showLoader: boolean;
  timeLineMeta: any;
  isFirstTime: boolean;
  isDeleteModalOpen: boolean;
  deleteAssessmentId: number;
  selectedTeacherId: string,
  teacherList: Array<{}>;
  selectedDifficultyType: string;
  selectedAssessmentStatus: string;
  searchText: string;
  isSummaryModalOpen: boolean;
  assessmentSummary: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LibraryAssessmentListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAssessmentList: string = "";
  apiGetAssessmentData: string = "";
  apiDeleteAssessment: string = "";
  apiGetAssessment: string = "";
  getTeacherListId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      userId: "",
      data: "",
      pagination_page: 0,
      totalPages: 0,
      showLoader: false,
      currentSubject: null,
      currentClass: null,
      assessmentList: [],
      timeLineMeta: [],
      isFirstTime: true,
      isDeleteModalOpen: false,
      deleteAssessmentId: -1,
      selectedTeacherId: "",
      teacherList: [],
      selectedDifficultyType: "",
      selectedAssessmentStatus: "",
      searchText: "",
      isSummaryModalOpen: false,
      assessmentSummary: {},
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.userdata = this.userdata.bind(this);
    this.getAssessmentList = this.getAssessmentList.bind(this);
    this.handleEditAssessment = this.handleEditAssessment.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.handleDeleteAssessment = this.handleDeleteAssessment.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.filterTeacherOnChange = this.filterTeacherOnChange.bind(this);
    this.filterDifficultyTypeOnChange = this.filterDifficultyTypeOnChange.bind(this);
    this.filterByStatus = this.filterByStatus.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.filterStatus = this.filterStatus.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onSearchBtnClick = this.onSearchBtnClick.bind(this);
    this.onEnterKeyPress = this.onEnterKeyPress.bind(this);
    this.handleViewAssessment = this.handleViewAssessment.bind(this);
    this.closeSummaryModal = this.closeSummaryModal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  openDeleteModal(id: number) {
    this.setState({
      deleteAssessmentId: id
    }, () => {
      this.setState({
        isDeleteModalOpen: true
      })
    })
  }

  onSearchBtnClick() {
    this.setState({
      pagination_page: 0,
      assessmentList: []
    }, () => {
      this.getAssessmentList()
    })
  }

  onChangeSearch(event?: any) {
    const value = event.target?.value;
    this.setState({
      searchText: value
    });
  }

  onEnterKeyPress(event: any) {
    if (event.key === 'Enter') {
      this.onSearchBtnClick()
    }
  }

  closeSummaryModal() {
    this.setState({
      isSummaryModalOpen: false,
      assessmentSummary: {}
    })
  }

  closeDeleteModal() {
    this.setState({
      isDeleteModalOpen: false
    })
  }
  filterDifficultyTypeOnChange(event: any) {
    this.setState({ selectedDifficultyType: event.target.value });
  }
  filterByStatus(event: any) {
    this.setState({ selectedAssessmentStatus: event.target.value });
  }
  clearAll() {
    if (!this.filterStatus()) {
      this.setState({
        selectedTeacherId: "",
        selectedDifficultyType: "",
        selectedAssessmentStatus: "",
        assessmentList: []
      }, () => this.refreshData());
    }
  }

  refreshData = () => {
    this.setState({
      userId: "",
      data: "",
      pagination_page: 0,
      totalPages: 0,
      showLoader: false,
      currentSubject: null,
      currentClass: null,
      assessmentList: [],
      timeLineMeta: [],
      isFirstTime: true,
      isDeleteModalOpen: false,
      deleteAssessmentId: -1,
    }, () => {
      this.userdata();
      const userData = localStorage.getItem("user_data");
      const parsedUserData = JSON.parse(userData || "{}");
      const isTeacherAccount = parsedUserData?.type === accountTypes.TEACHER;
      if (HISTORY.location.state && isTeacherAccount) {
        const subjectId = HISTORY.location.state.subject?.id
          ? HISTORY.location.state.subject?.id
          : HISTORY.location.state.subject?.subject_id;

        const schoolClassId = HISTORY.location.state.schoolClassId
          ? HISTORY.location.state.schoolClassId
          : HISTORY.location.state.currentClass?.id;
        this.setState({
          currentSubject: HISTORY.location.state.subject,
          currentClass: {
            ...HISTORY.location.state.currentClass,
            school_class_id: schoolClassId,
          },
        });
        this.getAssessmentList(subjectId, schoolClassId);
      } else {
        HISTORY.push("/AssessmentQuestionBankLibrary");
      }
    })
  }
  async componentDidMount() {
    super.componentDidMount();
    this.userdata();
    const userData = localStorage.getItem("user_data");
    const parsedUserData = JSON.parse(userData || "{}");
    const isTeacherAccount = parsedUserData?.type === accountTypes.TEACHER;
    if (HISTORY.location.state && isTeacherAccount) {
      const subjectId = HISTORY.location.state.subject?.id
        ? HISTORY.location.state.subject?.id
        : HISTORY.location.state.subject?.subject_id;

      const schoolClassId = HISTORY.location.state.schoolClassId
        ? HISTORY.location.state.schoolClassId
        : HISTORY.location.state.currentClass?.id;
      this.setState({
        currentSubject: HISTORY.location.state.subject,
        currentClass: {
          ...HISTORY.location.state.currentClass,
          school_class_id: schoolClassId,
        },
      });
      this.getAssessmentList(subjectId, schoolClassId);
      this.getTeacherList(subjectId, schoolClassId);
    } else {
      HISTORY.push("/AssessmentQuestionBankLibrary");
    }
  }

  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const id = localStorage.getItem("id");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data, userId: id });
  };

  handleLoadMore = () => {
    this.getAssessmentList();
  };

  handleBackClick = () => {
    this.props.navigation.navigate("AssessmentQuestionBankLibrary");
  };

  filterTeacherOnChange = (event: any) => {
    this.setState({ selectedTeacherId: event.target.value });
  };

  filterStatus() {
    const { selectedTeacherId, selectedAssessmentStatus, selectedDifficultyType } = this.state;
    return (!selectedTeacherId && !selectedAssessmentStatus && !selectedDifficultyType)
  }
  applyFilter() {
    this.setState({
      pagination_page: 0,
      assessmentList: [],
    }, () => {
      this.getAssessmentList()
    })
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson != null) {
        if (!responseJson.errors) {
          if (apiRequestCallId === this.apiGetAssessmentList) {
            let listOfAssessments = [...this.state.assessmentList];
            let paginationPage = 0;
            if (responseJson?.data?.length) {
              listOfAssessments = [...listOfAssessments, ...responseJson.data];
            }

            if (responseJson?.meta?.pagination?.next_page) {
              paginationPage = responseJson.meta.pagination.next_page;
            }
            this.setState({
              assessmentList: listOfAssessments,
              timeLineMeta: responseJson?.meta?.pagination,
              pagination_page: paginationPage,
              totalPages: responseJson?.meta?.pagination?.total_pages,
              showLoader: false,
              isFirstTime: false,
            });
          } else if (apiRequestCallId === this.apiGetAssessmentData) {
            //  creating editable data
            this.configureAssessmentStorageAndEdit(responseJson.data);
            //  creating editable data END
          } else if (apiRequestCallId === this.apiDeleteAssessment) {
            this.setState({
              deleteAssessmentId: -1,
              isDeleteModalOpen: false
            });
            this.refreshData();
            toast("Assessment Deleted Succuessfully");
          } else if (apiRequestCallId === this.apiGetAssessment) {
            this.setState({
              assessmentSummary: responseJson.data,
              isSummaryModalOpen: true
            });
          } else if (apiRequestCallId === this.getTeacherListId) {
            this.setState(
              { teacherList: responseJson?.accounts_data }
            );
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
      this.parseApiCatchErrorResponse(errorReponse);
      this.setState({ showLoader: false });
    }
  }

  getAssessmentList = (subjectId?: number, classId?: number, page?: number) => {
    this.setState({ showLoader: true });
    const pageNo = this.state.pagination_page ? this.state.pagination_page : 1;
    let subject = this.state.currentSubject?.id;
    if (subjectId) {
      subject = subjectId;
    }
    const classid = classId ? classId : this.state.currentClass?.id;

    const { selectedTeacherId, selectedDifficultyType, selectedAssessmentStatus, searchText } = this.state;
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAssessmentList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAssessmentListEndPoint +
      `?subject_id=${subject}&grade_id=${classid}&page=${pageNo}&per=30&teacher_id=${selectedTeacherId}&difficulty_level=${selectedDifficultyType.toLowerCase()}&assessment_status=${selectedAssessmentStatus}&search=${searchText}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleEditAssessment(id: number) {
    this.setState({
      showLoader: true,
    });
    const user_data = localStorage.getItem("user_data");
    const schoolData = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: schoolData?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAssessmentData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAssessmentsEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeleteAssessment(id: number) {
    this.setState({
      showLoader: true,
    });
    const user_data = localStorage.getItem("user_data");
    const schoolData = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: schoolData?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteAssessment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAssessmentsEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleViewAssessment(id: number) {
    this.setState({
      showLoader: true,
    });
    const user_data = localStorage.getItem("user_data");
    const schoolData = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: schoolData?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAssessment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAssessmentsEndPoint + `/${id}/get_assessment_summary`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTeacherList = (subjectId?: number, classId?: number) => {
    const user_data = localStorage.getItem("user_data");
    const userData = JSON.parse(user_data || "{}");

    const subjectid = subjectId
      ? subjectId
      : this.state.currentSubject?.id
        ? this.state.currentSubject?.id
        : this.state.currentSubject?.subject_id;
    const classid = classId ? classId : this.state.currentClass?.id;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: userData.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTeacherListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeachersListEndPoint + `?subject_id=${subjectid}&grade_id=${classid}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  configureAssessmentStorageAndEdit = (data: any) => {
    let assessmentData: any = {
      assessment_id: data.id,
      assessment_category_id: data.attributes.assessment_category_id,
      assessment_name: data.attributes.assessment_name,
      grade_id: data.attributes.grade_id,
      grade: data.attributes.grade,
      subject_id: data.attributes.subject_id,
      subject: data.attributes.subject_name,
      exam_type: data.attributes.exam_type,
      maximum_marks: data.attributes.maximum_marks,
      term_id: data.attributes.term.id,
      term: [
        {
          id: data.attributes.term.id,
          type: "term",
          attributes: {
            grade_id: data.attributes.term.grade_id,
            school_id: data.attributes.term.school_id,
            account_id: data.attributes.term.account_id,
            title: data.attributes.term.title,
            session_start: data.attributes.term.session_start,
            session_end: data.attributes.term.session_end,
          },
        },
      ],
      negative_marking: data.attributes.negative_marking,
      time_limit: data.attributes.time_limit,
      difficulty_level: data.attributes.difficulty_level,
      assessment_theme: data.attributes.assessment_theme,
    };

    // Guidelines
    const guidelines = data.attributes.assessment_guide_lines.reduce(
      (result: any, guideline: any) => {
        result.push({
          id: uuidv4(),
          description: guideline.description,
          error: false,
          guideline_id: guideline.id,
        });
        return result;
      },
      []
    );
    // Guidelines END

    // section data
    const sections = data.attributes.assessment_sections.data.reduce(
      (result: any, section: any) => {
        result.push({
          id: uuidv4(),
          section_id: section.id,
          name: section.attributes.name,
          section_marks: section.attributes.section_marks,
          remaining_marks: section.attributes.remaining_marks,
          total_questions: section.attributes.total_questions,
          time_limit: section.attributes.time_limit,
          question_data: section.attributes.questions.data.reduce(
            (questionOutput: any, question: any) => {
              questionOutput.push({
                id: question.id,
                title: question?.attributes?.title,
                type: question?.attributes?.type?.name,
                marks: question?.attributes?.marks,
                negative_marks: question?.attributes?.negative_marks,
              });
              return questionOutput;
            },
            []
          ),
        });
        return result;
      },
      []
    );
    // section data  END
    assessmentData.assessment_guidelines = guidelines;
    assessmentData.assessment_sections_attributes = sections;
    localStorage.setItem("assessmentData", JSON.stringify(assessmentData));

    HISTORY.push({
      pathname: "/TeacherAssessmentCreateDetail",
    });
  };
  // Customizable Area End
}
