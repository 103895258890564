// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: any;
  listView: any;
  handleGrid: any;
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  resourceData: any;
  data: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  studentResourcetList: any;
  teacherAttendanceList: any;
  studentResourceFolders: any;
  classesList: any;
  subjectList: any;
  categoryList: any;
  teachersList: any;
  studentList: any;
  anchorEl: any;
  openModal: boolean;
  selectedItem: string;
  selectedId: string;
  selectedIds: any;
  selectAll: boolean;
  selectedClass: any;
  selectedSubject: any;
  selectedStudens: any;
  selectedTeacher: any;
  selectedListType: string;
  modalType: string;
  selectedFiles: any,
  currentFile: any,
  progress: number,
  message: string,
  isError: boolean,
  fileInfos: any,
  categoriesName: string,
  createCategory: boolean,
  uploadResource: any,
  displayList: string,
  uploadPercentage: number,
  studentChecked: boolean,
  teacherChecked: boolean,
  parentChecked: boolean,
  shared_with: any,
  folderName: string,
  createSelectedAll: boolean,
  createSelected: any,
  fileSelectedAll: boolean,
  fileSelected: any,
  queryTerm: string,
  viewFolders: any,
  viewResource: any,
  movetoFolderId: any,
  openDialog: boolean,
  fileToBeAdded: any,
  studentAttendanceList: any,
  date: any;
  studentAttendanceReport: any;
  selectedMonth: any;
  selectedYear: any;
  studentReportYear: any;
  loadingSpinner: boolean;

  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudentAttendanceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apigetStudentResourceId: string = "";
  apiCreateStudentResourceId: string = "";
  apiShowStudentResourceId: string = "";
  apiDeleteStudentResourceId: string = "";
  apiShareStudentResourceId: string = "";
  apiGetUserStudentResourceId: string = "";
  apiUploadResourceId: string = "";
  apiViewUploadResourceId: string = "";
  apiRenameUploadResourceId: string = "";
  apiMakeaCopy: string = "";
  apiMoveTo: string = "";
  apigetStudentAttendanceId: any;
  apigetStudentAttendanceReport: any;
  // for folders
  apigetStudentResourceFolderId: string = "";
  apiCreateStudentResourceFolderId: string = "";
  apiDeleteStudentResourceFolderId: string = "";
  apiShowStudentResourceFolderId: string = "";
  apiGetUsersStudentResourceFolderId: string = "";
  apiSearchStudentResourceFolderId: string = "";
  apiShareStudentResourceFolderId: string = "";
  apigetCategoryList: string = '';
  apicreateCategory: string = ''
  apicreateFolder: string = ''
  apiViewFolderResourceId: string = ""
  apiRenameFolderResourceId: string = ""
  // for student and teacher list
  apiGetClassesId: string = "";
  apiGetClassStudentId: string = "";
  apiGetClassSubjectId: string = "";
  apiGetClassTeacherId: string = "";
  apiGetStudentsByClassAndSubjectId: string = "";
  // Customizable Area End



  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleModalClick = this.handleModalClick.bind(this)



    // this.selectFile = this.selectFile.bind(this);
    // this.upload = this.upload.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      resourceData: [],
      data: '',
      errorMsg: "",
      anchorEl: null,
      token: "",
      loading: false,
      studentResourcetList: {},
      teacherAttendanceList: {},
      studentAttendanceList: [],
      studentAttendanceReport: [],
      studentResourceFolders: {},
      classesList: {},
      subjectList: [],
      teachersList: {},
      studentList: {},
      openModal: false,
      selectedItem: "",
      selectedId: "",
      selectedIds: [],
      categoryList: [],
      selectAll: false,
      selectedClass: null,
      selectedSubject: null,
      selectedStudens: null,
      selectedTeacher: null,
      selectedListType: "",
      modalType: "",
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 10,
      message: "",
      isError: false,
      fileInfos: [],
      categoriesName: '',
      createCategory: false,
      displayList: 'student',
      uploadResource: [
        {
          id: 1
        }
      ],
      uploadPercentage: 0,
      studentChecked: false,
      teacherChecked: false,
      parentChecked: false,
      shared_with: [],
      folderName: "",
      createSelectedAll: false,
      createSelected: [],
      fileSelectedAll: false,
      fileSelected: [],
      queryTerm: "",
      viewFolders: [],
      viewResource: [],
      movetoFolderId: undefined,
      openDialog: false,
      fileToBeAdded: [],
      date: moment().format('DD MMMM, YYYY'),
      selectedMonth: "",
      selectedYear: "",
      studentReportYear: [],
      loadingSpinner: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    this.handleSelectAll = this.handleSelectAll.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.userdata = this.userdata.bind(this);
    this.handleSelectClass = this.handleSelectClass.bind(this)
    this.handleSelectSubject = this.handleSelectSubject.bind(this)
    this.handleSelectCategories = this.handleSelectCategories.bind(this)
    this.handleCreateCategory = this.handleCreateCategory.bind(this)
    this.handleCancelCategory = this.handleCancelCategory.bind(this)
  }

  // Customizable Area Start  
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.userdata();
    this.getStudentAttendance();
    this.getStudentAttendanceReport();


    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  }

  disableFutureDt = (current: any) => {
    const today = moment();
      return current.isBefore(today);
  };

  calendarChangeHandler = (date: any) => {
    this.setState({
      date: moment(date).format("DD MMMM, YYYY"),

    }, () => {
      this.getStudentAttendance(); this.getStudentAttendanceReport();
    })
  }

  monthdropdownChangeHandler = (e: any) => {
    this.setState({ selectedMonth: e.target.value },
      () => this.getStudentAttendanceReport())
  }

  handleTodayBtnClick = () => {
    this.setState({
      date: moment().format('DD MMMM, YYYY')
    },
      () => {
        this.getStudentAttendance(); this.getStudentAttendanceReport();
      })
  }

  isSelected = (id: any) => this.state.createSelected.indexOf(id) !== -1;
  isFileSelected = (id: any) => this.state.fileSelected.indexOf(id) !== -1;
  handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      this.setState({ createSelected: this.state.studentList && this.state.studentList.map((n: any) => n.id) });
      return;
    }
    this.setState({ createSelected: [] });
  };

  handleSelectAllPresent = (event: any) => {

    this.setState({
      createSelected: this.state.teacherAttendanceList && this.state.teacherAttendanceList.data.map((n: any) => {
        if (n.attendence.attributes.status === "Present")
          return n.attendence.id
      }
      )
    });


  };
  handleSelectAllAbsent = (event: any) => {

    this.setState({
      createSelected: this.state.teacherAttendanceList && this.state.teacherAttendanceList.data.map((n: any) => {
        if (n.attendence.attributes.status === "Absent")
          return n.attendence.id
      }
      )
    });


  };
  handleSelectAllLeave = (event: any) => {

    this.setState({
      createSelected: this.state.teacherAttendanceList && this.state.teacherAttendanceList.data.map((n: any) => {
        if (n.attendence.attributes.status === "Leave")
          return n.attendence.id
      }
      )
    });


  };

  handleCheckboxClick = (event: any, id: any) => {
    event.stopPropagation();
    const { createSelected } = this.state;
    const selectedIndex = createSelected.indexOf(id);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(createSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(createSelected.slice(1));
    } else if (selectedIndex === createSelected.length - 1) {
      newSelected = newSelected.concat(createSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        createSelected.slice(0, selectedIndex),
        createSelected.slice(selectedIndex + 1)
      );
    }

    this.setState({ createSelected: newSelected });
  };
  handleCheckboxClickFile = (event: any, id: any) => {
    event.stopPropagation();
    const { fileSelected } = this.state;
    const selectedIndex = fileSelected.indexOf(id);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(fileSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(fileSelected.slice(1));
    } else if (selectedIndex === fileSelected.length - 1) {
      newSelected = newSelected.concat(fileSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        fileSelected.slice(0, selectedIndex),
        fileSelected.slice(selectedIndex + 1)
      );
    }

    this.setState({ createSelected: newSelected });
  };
  handleCloseResourceModal = () => {
    this.setState({
      openModal: false,
      categoriesName: '',
      selectedSubject: null,
      selectedClass: null,
      shared_with: [],
      selectedFiles: undefined,
      uploadPercentage: 0,
      studentChecked: false,
      teacherChecked: false,
      parentChecked: false,
      uploadResource: [{}]

    })
  }
  handleCheckBoxChange = (e: any, type: any) => {
    const {
      target: { checked },
    } = e;
    if (type === 'student') {
      this.setState({ studentChecked: !this.state.studentChecked, shared_with: [...this.state.shared_with, checked && 'student'] });
    } else if (type === 'teacher') {
      this.setState({ teacherChecked: !this.state.teacherChecked, shared_with: [...this.state.shared_with, checked && 'teacher'] });
    } else {
      this.setState({ parentChecked: !this.state.parentChecked, shared_with: [...this.state.shared_with, checked && 'parent'] });
    }
  };
  handleFileUpload = (e: any) => {
    this.setState({
      selectedFiles: e.target.files,
    })
    let data = new FormData();
    if (this.state.selectedFiles && this.state.selectedFiles.length > 0)
      data.append('file', this.state.selectedFiles && this.state.selectedFiles[0])
    const options = {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total)

        if (percent < 100) {
          this.setState({ uploadPercentage: percent })
        }
      }
    }
    this.setState({ uploadPercentage: 100 }, () => {

    })

  }

  handleModalClick = (type: any) => {
    this.setState({
      openModal: !this.state.openModal,
      modalType: type,
      shared_with: [],
      studentChecked: false,
      teacherChecked: false,
      parentChecked: false,
      selectedFiles: undefined,
      categoriesName: '',
      selectedSubject: null,
      selectedClass: null,
    })
  }
  handleCreateCategory = () => {
    this.setState({ createCategory: true })
  }
  handleCancelCategory = () => {
    this.setState({ createCategory: false })
  }
  handleSelectCategories = (event: any) => {
    const {
      target: { value },
    } = event;
    this.setState({
      categoriesName: value
    })
  }
  handleSelectClass = (event: any) => {
    const {
      target: { value },
    } = event;
    this.setState({ selectedClass: parseInt(value) })
    this.getStudentSubject(parseInt(value))
    this.getTeacherByClassId(parseInt(value))
  }
  handleSelectSubject = (event: any) => {
    const {
      target: { value },
    } = event;
    this.setState({ selectedSubject: value })
    this.getStudentByClassIdAndSubjectId(value)

  }
  handleClick = (event: any, type: any, id: string) => {
    event.stopPropagation()
    this.setState({
      anchorEl: event.currentTarget,
      selectedItem: type,
      selectedId: id
    });


  };

  handleSelectAll = () => {
    this.setState({ selectAll: !this.state.selectAll })
  }
  handleSelect = (id: any) => {
    this.setState({ selectedIds: this.state.selectedIds.concat(id) })
  }
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  userdata = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}')
    this.setState({ data: data });
  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }
  // view details
  viewStudentResourceFolders = (id: any) => {
    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem('token'),

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiViewFolderResourceId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentResourceFolder + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getStudentAttendance = () => {
    this.setState({ loadingSpinner: true })
    const studentId = localStorage.getItem("id")
    const date = this.state.date === "" ? moment().format("DD-MM-YYYY") : moment(this.state.date, 'DD MMMM, YYYY').format('DD-MM-YYYY')
    const header = {
      "Content-Type": configJSON.resourceContentType,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentAttendanceId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentAttendance + `?student_id=${studentId}&date=${date}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStudentAttendanceReport = () => {
    this.setState({ loadingSpinner: true })
    const studentId = localStorage.getItem("id")
    const month = this.state.selectedMonth === "" ? moment().format("MMM") : this.state.selectedMonth
    const year = this.state.selectedYear === "" || this.state.selectedYear === "month" ? moment().format("YYYY") : this.state.selectedYear
    const header = {
      "Content-Type": configJSON.resourceContentType,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentAttendanceReport = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentAttendanceReport + `?student_id=${studentId}&month=${month}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resourceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStudentClasses = () => {
    const header = {
      "Content-Type": configJSON.resourceContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetClassesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClasses
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStudentSubject = (value: any) => {
    const header = {
      "Content-Type": configJSON.resourceContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetClassSubjectId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectsByClassId + `?class_id=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStudentByClassIdAndSubjectId = (value: any) => {
    const header = {
      "Content-Type": configJSON.resourceContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetStudentsByClassAndSubjectId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentsByClassIdAndSubjectId + `?class_id=${this.state.selectedClass}&subject_id=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getTeacherByClassId = (value: any) => {
    const header = {
      "Content-Type": configJSON.resourceContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetClassTeacherId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeachersByClassId + `?class_id=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End



  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      console.log(apiRequestCallId, "apiRequestCallId")
      console.log(responseJson, "responseJson")
      console.log(errorReponse, "errorReponse")
      if (apiRequestCallId === this.apigetStudentResourceFolderId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ studentResourceFolders: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetStudentResourceId) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            this.setState({ studentResourcetList: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetStudentAttendanceId) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            this.setState({ studentAttendanceList: responseJson, loadingSpinner: false })

          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })

      }
      else if (apiRequestCallId === this.apigetStudentAttendanceReport) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            console.log(responseJson)

            this.setState({
              studentAttendanceReport: responseJson,
              loadingSpinner: false
            })


          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }
        this.setState({ loadingSpinner: false })
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apicreateFolder) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            // toast.success(responseJson.meta.message)
            this.setState({
              openModal: false,
              selectedSubject: null,
              selectedClass: null,
              shared_with: [],
              selectedFiles: undefined,
              uploadPercentage: 0,
              studentChecked: false,
              teacherChecked: false,
              parentChecked: false
            })


          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiUploadResourceId) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            toast.success("Uploaded Successfully")


            this.setState({
              openModal: false,
              categoriesName: '',
              selectedSubject: null,
              selectedClass: null,
              shared_with: [],
              selectedFiles: undefined,
              uploadPercentage: 0,
              studentChecked: false,
              teacherChecked: false,
              parentChecked: false

            })


          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }

      else if (apiRequestCallId === this.apiGetClassTeacherId) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            this.setState({ teachersList: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiGetStudentsByClassAndSubjectId) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            this.setState({ studentList: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiGetClassesId) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            this.setState({ classesList: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiGetClassSubjectId) {

        if (responseJson != null) {

          if (!responseJson.errors) {
            this.setState({ subjectList: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson)
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }






    }
    // Customizable Area End

  }
  
// Customizable Area Start
// Customizable Area End

}
