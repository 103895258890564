// Customizable Area Start
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { HISTORY } from '../../../../components/src/comman';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import AssessmentAnalysis from './AssessmentAnalysis.web';
import AssessmentStudents from './AssessmentStudents.web';
import AssessmentSummaryViewController from './AssessmentSummaryReportController.web';
import AssessmentSummary from './AssessmentSummaryReports.web';
import AssessmentStandards from './AsssessmentStandardsReport.web';
import { downloadPDF } from '../utils/common';
//@ts-ignore
import FilterIcon from '../assets/filter-outline.svg';
//@ts-ignore
import DownloadIcon from '../assets/download.svg';
import './styles.css';
// Customizable Area End

// Customizable Area Start
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
const noData = require('../assets/no-data.png').default;
const configJSON = require('../config.js');

// Customizable Area End

export class AssessmentSummaryReport extends AssessmentSummaryViewController {
  // Customizable Area Start
  render() {
    const performance_band = this.state.assessmentSummaryReport
      ?.performance_band;

    const doughnutData = this.state.performancePerStudent.performance_wise_data
      ?.performance_band;
    const studentsBarGraph = {
      data: {
        labels: [
          'Good',
          'Low Pass',
          'Excellent',
          'Satisfactory',
          'Unsatisfactory',
        ],
        datasets: [
          {
            label: '# of Votes',
            data:
              doughnutData !== undefined && doughnutData !== null
                ? Object.values(doughnutData).map((itm: any) => itm)
                : [],
            backgroundColor: [
              '#4fdbb6',
              '#ffcc70',
              '#26c165',
              '#5887a3',
              '#f24a3d',
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 5,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value: any) {
                return value + '%';
              },
            },
            backdropColor: '#4e5873',
            grid: {
              display: false,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: true,
            color: 'black',
            formatter: Math.round,
            anchor: 'end',
            offset: -15,
            align: 'start',
          },
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            enabled: true,
          },
        },
      },
    };
    const studentsDonughtData = {
      data: {
        labels:
          doughnutData !== undefined && doughnutData !== null
            ? Object.keys(doughnutData).map((itm: any) =>
                itm
                  ?.toLowerCase()
                  .replace(/_/g, ' ')
                  .split(' ')
                  .map(
                    (val: string) => val.charAt(0).toUpperCase() + val.slice(1)
                  )
                  .join(' ')
              )
            : [],
        datasets: [
          {
            data:
              doughnutData !== undefined && doughnutData !== null
                ? Object.values(doughnutData).map((itm: any) => itm)
                : [],
            backgroundColor: [
              '#4fdbb6',
              '#ffcc70',
              '#26c165',
              '#5887a3',
              '#f24a3d',
            ],
            tension: 0.4,
            borderSkipped: 'bottom',
            datalabels: {
              color: '#fff',
              font: {
                size: 24,
              },
              formatter: function(value: any, context: any) {
                if (value === 0) return '';
                return value + '%';
              },
            },
          },
        ],
      },
      options: {
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            render: function(value: any, context: any) {
              return value + '%';
            },
            fontColor: 'white',
          },
          legend: {
            display: false,
          },
          title: {
            display: false,
            text: configJSON.ASSESSMENT_SUMMARY.STUDENTS_PERFORMANCE_BANDS,
            position: 'top' as const,
          },
          tooltip: {
            enabled: true,
          },
        },
      },
    };
    const downloadableFileName = () => {
      switch (this.state.tabValue) {
        case 0:
          return 'Assessment Summary Report';
        case 1:
          return 'Assessment Analysis Report';
        case 2:
          return 'Assessment Performance Report';
        default:
          break;
      }
      return '';
    };
    const data = {
      data: {
        labels:
          performance_band !== undefined
            ? Object?.keys(performance_band)?.map((itm) =>
                itm
                  ?.toLowerCase()
                  .replace(/_/g, ' ')
                  .split(' ')
                  .map(
                    (val: string) => val.charAt(0).toUpperCase() + val.slice(1)
                  )
                  .join(' ')
              )
            : [],
        datasets: [
          {
            label: '# of Votes',
            data:
              performance_band !== undefined
                ? Object?.values(performance_band)
                : [],
            backgroundColor: [
              '#4fdbb6',
              '#ffcc70',
              '#26c165',
              '#5887a3',
              '#f24a3d',
            ],
            borderColor: [
              '#4fdbb6',
              '#ffcc70',
              '#26c165',
              '#5887a3',
              '#f24a3d',
            ],
            borderWidth: 1,
            datalabels: {
              color: '#fff',
              font: {
                size: 24,
              },
              formatter: function(value: any, context: any) {
                if (value === 0) return '';
                return value + '%';
              },
            },
          },
        ],
      },
      options: {
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value: any) {
                return value + '%';
              },
            },
            backdropColor: '#4e5873',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            enabled: true,
          },
        },
      },
    };
    const renderTable = () => {
      if (!this.state.submittedFilters) {
        return (
          <Box className={`assessmentSummaryReport_AssessmentTest_main_table`}>
            <img
              src={noData}
              className={`${this.props.classes.assessmentSummaryReport_AssessmentTest_no_data_image} no_data_img`}
              alt="no data"
            />

            <p
              className={`${this.props.classes.assessmentSummaryReport_AssessmentTest_no_data_text_title} no_data_title`}
            >
              {configJSON.ASSESSMENT_REPORTS.NO_DATA_FOUND}
            </p>

            <p
              className={
                this.props.classes
                  .assessmentSummaryReport_AssessmentTest_no_data_text
              }
            >
              {configJSON.ASSESSMENT_REPORTS.SELECT_ASSESSMENT}
            </p>
          </Box>
        );
      } else {
        switch (this.state.tabValue) {
          case 0:
            return (
              <AssessmentSummary
                tableData={
                  Array.isArray(
                    this.state.assessmentSummaryReport
                      ?.assessment_report_summaries
                  )
                    ? this.state.assessmentSummaryReport
                        ?.assessment_report_summaries
                    : [
                        this.state.assessmentSummaryReport
                          ?.assessment_report_summaries,
                      ]
                }
                classes={this.props.classes}
                data={data}
                assessmentData={this.state.assessmentQuestionAnalysis}
                id={'downloadable'}
              />
            );
          case 1:
            return (
              <AssessmentAnalysis
                classes={this.props.classes}
                sections={this.state.sections}
                assessmentData={this.state.assessmentQuestionAnalysis}
                activeSection={this.state.activeSection}
                toggleActiveSection={this.toggleActiveSection}
                id={'downloadable'}
              />
            );
          // case 2:
          //   return (
          //     <AssessmentFrequency
          //       data={frequencyData}
          //       classes={this.props.classes}
          //     />
          //   );
          case 2:
            return (
              <AssessmentStudents
                donughtData={studentsDonughtData}
                barData={studentsBarGraph}
                classes={this.props.classes}
                tableData={this.state?.performancePerStudent}
                assessmentData={this.state.assessmentQuestionAnalysis}
                id={'downloadable'}
              />
            );
          case 3:
            return (
              <AssessmentStandards
                barData={studentsBarGraph}
                classes={this.props.classes}
                assessmentData={this.state.assessmentQuestionAnalysis}
                id={'downloadable'}
              />
            );
          default:
            break;
        }
      }
    };

    return (
      <Box marginTop={3}>
        {/* Back button starts here */}
        <Box
          display="flex"
          alignItems="center"
          className={this.props.classes.icon_color}
        >
          <IconButton onClick={() => HISTORY.goBack()}>
            <ArrowBack />
          </IconButton>
          <Typography
            variant="h5"
            className={
              this.props.classes
                .assessmentSummaryReport_AssessmentTest_reports_font
            }
          >
            {configJSON.STATIC_STRINGS.REPORTS}
          </Typography>
        </Box>
        {/* Back button ends here */}

        {/* Tabs and icons start here */}
        <Box marginTop={3}>
          <Grid container>
            {/* Restructure grid values when adding the future buttons */}
            <Grid item xs={8} md={8} lg={8}>
              <Tabs
                value={this.state?.tabValue}
                onChange={(_, value) => this.handleChangeTabs(value)}
                TabIndicatorProps={{
                  style: {
                    background: this.props.classes.button_color,
                  },
                }}
              >
                <Tab
                  className={` ${this.props.classes.subHeading_font}`}
                  label="Summary"
                  id={`simple-tab-${this.state?.tabValue}`}
                />
                <Tab
                  className={` ${this.props.classes.subHeading_font}`}
                  label="Analysis"
                  id={`simple-tab-${this.state.tabValue}`}
                />
                {/* <Tab
                  className={` ${this.props.classes.subHeading_font}`}
                  label="Frequency"
                  id={`simple-tab-${this.state.tabValue}`}
                /> */}
                <Tab
                  className={` ${this.props.classes.subHeading_font}`}
                  label="Students"
                  id={`simple-tab-${this.state.tabValue}`}
                />
                {/* <Tab
                  className={` ${this.props.classes.subHeading_font}`}
                  label="Standards"
                  id={`simple-tab-${this.state.tabValue}`}
                /> */}
              </Tabs>
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <Popover
                anchorReference="anchorPosition"
                anchorPosition={{ top: 350, left: 700 }}
                PaperProps={{
                  style: {
                    width: '800px',
                    padding: '20px',
                    boxShadow: '0 8px 27px 0 rgba(224, 224, 224, 0.5)',
                  },
                }}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                open={this.state.isFiltersOpen}
                onClose={this.handleOpenFilters}
              >
                <Typography
                  variant="h6"
                  className={this.props.classes.subTitle_font}
                >
                  {configJSON.STATIC_STRINGS.FILTER_BY}
                </Typography>
                <Divider
                  variant="fullWidth"
                  className={
                    this.props.classes
                      .assessmentSummaryReport_AssessmentTest_divider
                  }
                />
                <Box marginTop={3} />
                <Grid
                  container
                  justify="space-around"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={12} md={3} lg={3}>
                    <Typography className={this.props.classes.subTitle_font}>
                      {configJSON.STATIC_STRINGS.YEAR}
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        variant="outlined"
                        onChange={this.filterChangeDate}
                        renderValue={() =>
                          this.state.selectedYear === ''
                            ? configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION
                            : this.state.selectedYear
                        }
                        displayEmpty
                        value={this.state.selectedYear}
                      >
                        <MenuItem disabled value="">
                          {configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION}
                        </MenuItem>
                        <MenuItem value={'2023-24'}>
                          {configJSON.STATIC_STRINGS['2023-24']}
                        </MenuItem>
                        <MenuItem value={'2022-23'}>
                          {configJSON.STATIC_STRINGS['2022-23']}
                        </MenuItem>
                        <MenuItem value={'2021-22'}>
                          {configJSON.STATIC_STRINGS['2021-22']}
                        </MenuItem>
                        <MenuItem value={'2020-21'}>
                          {configJSON.STATIC_STRINGS['2020-21']}
                        </MenuItem>
                        <MenuItem value={'2019-20'}>
                          {configJSON.STATIC_STRINGS['2019-20']}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <Typography className={this.props.classes.subTitle_font}>
                      {configJSON.STATIC_STRINGS.CLASS}
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        variant="outlined"
                        onChange={this.handleChangeClass}
                        name="selected_class"
                        renderValue={() =>
                          this.state.selected_class.class_name === ''
                            ? configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION
                            : this.state.selected_class.class_name
                        }
                        displayEmpty
                        value={this.state.selected_class.class_name}
                      >
                        <MenuItem disabled value="">
                          {configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION}
                        </MenuItem>
                        {this.state.schoolClasses.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item}>
                              {item.attributes.display_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <Typography className={this.props.classes.subTitle_font}>
                      {configJSON.STATIC_STRINGS.SUBJECT}
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        variant="outlined"
                        onChange={this.handleSelectSubject}
                        value={this.state.selected_subject.subject_name}
                        displayEmpty
                        renderValue={() =>
                          this.state.selected_subject.subject_name === ''
                            ? configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION
                            : this.state.selected_subject.subject_name
                        }
                      >
                        <MenuItem disabled value="">
                          {configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION}
                        </MenuItem>
                        {this.state.classSubjects.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item}>
                              {item.attributes.subject_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <Typography className={this.props.classes.subTitle_font}>
                      {configJSON.STATIC_STRINGS.TEST_TYPE}
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        variant="outlined"
                        onChange={this.handleChangeTestType}
                        value={this.state.selected_test_type}
                        displayEmpty
                        renderValue={() =>
                          this.state.selected_test_type === ''
                            ? configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION
                            : this.state.selected_test_type
                        }
                      >
                        <MenuItem disabled value="">
                          {configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION}
                        </MenuItem>
                        <MenuItem value={configJSON.STATIC_STRINGS.FORMATIVE}>
                          {configJSON.STATIC_STRINGS.FORMATIVE}
                        </MenuItem>
                        <MenuItem value={configJSON.STATIC_STRINGS.SUMMATIVE}>
                          {configJSON.STATIC_STRINGS.SUMMATIVE}
                        </MenuItem>
                        <MenuItem
                          value={configJSON.STATIC_STRINGS.PRACTICE_PAPER}
                        >
                          {configJSON.STATIC_STRINGS.PRACTICE_PAPER}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid xs={12}>
                    <Typography className={this.props.classes.subTitle_font}>
                      {configJSON.ASSESSMENT_SUMMARY.TEST}
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        variant="outlined"
                        onChange={this.handleSelectAssessment}
                        renderValue={() =>
                          this.state.selected_assessment.assessment_name === ''
                            ? configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION
                            : this.state.selected_assessment.assessment_name
                        }
                        displayEmpty
                        value={
                          this.state.selected_assessment.assessment_name === ''
                            ? configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION
                            : this.state.selected_assessment.assessment_name
                        }
                      >
                        <MenuItem disabled value="">
                          {configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION}
                        </MenuItem>
                        {this.state.filteredAssessments &&
                          Array.isArray(this.state.filteredAssessments) &&
                          this.state.filteredAssessments?.map((item, index) => {
                            return (
                              <MenuItem value={item} key={index}>
                                {item?.assessment_name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Box marginTop={3} display="flex">
                  <Button
                    onClick={this.handleOpenFilters}
                    className={`${this.props.classes.assessmentSummaryReport_AssessmentTest_cancel_button} cancel_btn`}
                  >
                    {configJSON.STATIC_STRINGS.CANCEL}
                  </Button>
                  <Button
                    onClick={this.submitFilters}
                    className={`${this.props.classes.assessmentSummaryReport_AssessmentTest_apply_button} apply_btn`}
                  >
                    {configJSON.STATIC_STRINGS.APPLY}
                  </Button>
                </Box>
              </Popover>
              <IconButton onClick={this.handleOpenFilters}>
                <img src={FilterIcon} style={{ width: '20px' }} />
                {/* <FilterList /> */}
              </IconButton>
            </Grid>
            {/* <Grid item xs={3} md={3} lg={1}> */}
            {/* {this.state.submittedFilters && (
                <IconButton>
                  <ShareOutlined />
                </IconButton>
              )} */}
            {/* </Grid> */}
            {/* <Grid item xs={3} md={3} lg={1}>
              {this.state.submittedFilters && (
                <IconButton>
                  <PrintOutlined />
                </IconButton>
              )}
            </Grid> */}
            <Grid item xs={2} md={2} lg={2}>
              {this.state.submittedFilters && (
                <IconButton>
                  <img
                    src={DownloadIcon}
                    style={{ width: '20px' }}
                    onClick={() =>
                      downloadPDF('downloadable', downloadableFileName())
                    }
                  />
                  {/* <CloudDownload onClick={this.downloadPDF} /> */}
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Box>
        {/* Tabs and icons ends here */}
        {renderTable()}
      </Box>
    );
  }
  // Customizable Area End
}
// Customizable Area Start

// @ts-ignore
export default withStyles(themeCreateStyle)(
  (AssessmentSummaryReport as unknown) as React.ComponentType<any>
);
// Customizable Area End
