import React, { createRef } from "react";
// Customizable Area Start
import {
    Button,
    Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AdminBadgesController, { Props } from "./AdminBadgesController.web";
import './AdminBadges.web.css';
import Spinner from "../../../shared/SideBar/src/Spinner";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";

// Customizable Area End
// Customizable Area Start
// Customizable Area End
export class AdminBadges extends AdminBadgesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
        // Customizable Area Start
        // Customizable Area End
    render() {
        // Customizable Area Start
        const { adminBadges } = this.state;
        const imgRef: any = createRef();

        return (
            <>
                {this.state.showSpinner && <Spinner spinnerModal={this.state.showSpinner} />}
                <Grid container>
                    <Grid item xs={12} className="badges-top-grid">
                        <span className={`${this.props.classes.heading_font} badges-text`}>Badges</span>
                        <Button className={`${this.props.classes.button_color} create-new-badge-btn`} onClick={this.moveToAssignBadges}>Create New</Button>
                    </Grid>
                </Grid>
                <div className="view-whitebox">
                    {Object.keys(adminBadges)?.length > 0 ?
                        <React.Fragment>
                            {Object.keys(adminBadges)?.map((badgeItem: any) => (
                                <div key={badgeItem?.id}>
                                    <span className={`${this.props.classes.heading_font} badges-text`}>{badgeItem}</span>
                                    <Grid container className="badges-list-view">
                                        {adminBadges?.[badgeItem]?.map((badge: any) => (
                                            <Grid item xs={12} sm={4} lg={3} xl={3} key={badge?.id} className="badge-box">
                                                <div className="view-badgecard">
                                                    <img src={badge.badge_attachment} className="badge-img" alt="badge"></img>
                                                    <span className={`${this.props.classes.title_font} badges-text ellipsis-text`}>{badge.name}</span>
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            ))}
                        </React.Fragment>
                        :
                        <span className={`${this.props.classes.heading_font} no-record-text`}>
                            No record(s) found.
                        </span>
                    }
                </div>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(AdminBadges);
// Customizable Area End