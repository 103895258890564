// Customizable Area Start
import React from "react";
import { Grid, Modal, Button, Typography } from "@material-ui/core";
import "./MeetingFeedbackOne.web.css";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import MeetingFeedbackController from "./MeetingFeedbackController.web";
import MeetingFeedbackTwo from "./MeetingFeedbackTwo.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
class MeetingFeedbackOne extends MeetingFeedbackController {
  // Customizable Area Start
  render() {
    // console.log("feedback res", this.state.feedbackResponse);

    const { openModal, onCloseModal } = this.props;

    return (
      <>
        <Modal
          open={openModal}
          onClose={onCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            style={{
              justifyContent: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "20px",
              width: "600px",
            }}
            className="modal-width-90"
          >
            <div className="popup-container">
              <div className="heading-container">
                <Typography
                  style={{
                    // fontFamily: "OpenSans",
                    // fontSize: "28px",
                    // fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                  variant="h6"
                >
                  How would you rate your today's experience ?
                </Typography>
              </div>
              <div className="reaction-container">
                {this.state.feedbackOptions.map(
                  (
                    item: { response: string; img: any; imgSelected: any },
                    index: number
                  ) => (
                    <div
                      key={index}
                      className="reaction"
                      onClick={() => {
                        this.setState({ feedbackResponse: item.response });
                        // console.log(item.response);
                      }}
                    >
                      <div className="reaction-img">
                        {this.state.feedbackResponse === item.response ? (
                          <img
                            src={item.imgSelected}
                            className="selectedImg"
                            alt={item.response}
                          />
                        ) : (
                          <img
                            src={item.img}
                            className="unselectedImg"
                            alt={item.response}
                          />
                        )}
                      </div>
                      <div className="reaction-text">
                        <Typography
                          style={{ textAlign: "center", fontWeight: "bold" }}
                          variant="h6"
                        >
                          {item.response}
                        </Typography>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="action-button-container">
                <Button
                  style={{
                    width: "120px",
                    height: "50px",
                    marginRight: "10px",
                    borderRadius: 6,
                    border: "1px solid #af38cb",
                    //   "1px solid #471c51",
                    background:
                      "linear-gradient(to top, #af38cb, #471c51 200%)",

                    textTransform: "capitalize",
                  }}
                  onClick={this.handleSubmit}
                >
                  <Typography style={{ color: "white" }}>SUBMIT</Typography>
                </Button>
                <Button
                  style={{
                    width: "120px",
                    height: "50px",
                    marginRight: "10px",
                    borderRadius: 6,
                    marginLeft: "50x",
                    border: "1px solid #471c51",
                    background: "#fff",
                    textTransform: "capitalize",
                  }}
                  onClick={onCloseModal}
                >
                  <Typography>NO</Typography>
                </Button>
              </div>
            </div>
          </Grid>
        </Modal>
        <MeetingFeedbackTwo
          openModal={this.state.openSecondFeedbackForm}
          onCloseModal={() => {
            this.setState({ openSecondFeedbackForm: false });
          }}
        />
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default MeetingFeedbackOne as React.ComponentType<any>;
// Customizable Area End
