// Customizable Area Start
import React from "react";
import ReactPaginate from "react-paginate";
import {
  Box,
  Button,
  Grid,
  InputBase,
  TextField,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  FormLabel

} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AdminDivisionController, { Props } from "./AdminDivisionController.web";
import moment from "moment";
import 'react-calendar/dist/Calendar.css';
import { deleteDivision, editDivision, filter } from "./assets";
import DeleteDivision from "./DeleteDivision.web";
import HOCAuth from "../../shared/Header/src/HOCAuth.web";
import './AdminDivision.web.css';
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const forrmInput_textfield = {
  width: "100%",
  borderRadius: "6px",
  textAlign: "start",
  color: "#3f526d",
  fontSize: "1.125rem",
  fontFamily: "Open sans",
} as const;
// Customizable Area End


export class AdminDivision extends AdminDivisionController {
  constructor(props: Props) {
    super(props);
  // Customizable Area Start
  // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const nameReg = /^[\s]+$/;

    return (
      <Box>
        <Box
          className={`admin_division_parent_container`}
        >
          <Box>
            <Typography
              className={`${this.props.classes.heading_font} admin_division_main_title`}
            >
              Division
            </Typography>
          </Box>
          <Box>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`${this.props.classes.button_color} admin_division_add_button`}
              onClick={() => { this.setState({ dialogOpen: true }) }}
            >
              Add Division
            </Button>


          </Box>
        </Box>

        <Box
          className={`admin_division_table_container`}
        >
          <Grid item xs={12} className={`mt_0`}>

            <TableContainer  className="division-table">
              <Table className={`admin_division_table`} aria-label="simple table">
                <TableHead className={`admin_division_border_b_none`}>
                  <TableRow>


                    <TableCell align="left" className={`${this.props.classes.subHeading_font} admin_division_table_cell admin_division_w_60_percent admin_division_border_left_radius`}>Division Name</TableCell>


                    <TableCell align="center" className={`${this.props.classes.subHeading_font} admin_division_w_20_percent admin_division_table_cell`} >Creation Date</TableCell>
                    <TableCell align="center" className={`${this.props.classes.subHeading_font} admin_division_w_20_percent admin_division_table_cell admin_division_border_right_radius`}>Action</TableCell>



                  </TableRow>
                </TableHead>


                {this.state.schoolDivision.map((_data: any, index: any) => {



                  return (
                    <>
                      <Box className={`h_10`}></Box>
                      <TableBody>
                        <TableRow
                          hover
                          role="checkbox"

                          tabIndex={-1}
                          key={_data.id}
                          className={`admin_division_table_row`}

                        >


                          <TableCell component="th" scope="row" align="left" className={`admin_division_table_column admin_division_border_left_radius admin_division_pl_25`}>
                            <Typography
                              className={`${this.props.classes.bodyText_font} admin_division_table_column_data`}
                            >
                              {_data.attributes.name}
                            </Typography>

                          </TableCell>
                          <TableCell component="th" scope="row" align="center" className={`admin_division_table_column admin_division_p_12`} >
                            <Typography
                              className={`${this.props.classes.bodyText_font} admin_division_table_column_data`}
                            >
                              {moment(_data.attributes.creation_date).format("DD MMM YYYY")}
                            </Typography>

                          </TableCell>
                          <TableCell component="th" scope="row" align="center" className={`admin_division_table_column admin_division_p_12 admin_division_border_right_radius admin_division_flex_evenly`}>
                            <div>
                              <img src={editDivision} onClick={() => this.setState({ dialogOpen: true, edit: true, viewId: _data.id, divisionName: _data.attributes.name })} className={`admin_division_cursor`} />
                            </div>
                            <div >
                              <img src={deleteDivision} onClick={() => this.setState({ deleteAlertModal: true, viewId: _data.id })} className={`admin_division_cursor`} />
                            </div>

                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </>
                  )
                })}
              </Table>
            </TableContainer>
            {this.state.schoolDivision.length === 0 || this.state.schoolDivision?.data?.length === 0 ? <div className={`className="admin_division_no_data"`}>
              No Data Found</div> : <></>}
            {this.state.totalPage > 1 && <Box className="admin_division_pagination_box">
              <ReactPaginate
                previousLabel={"←   Previous"}
                nextLabel={"Next   →	"}
                initialPage={this.state.currentPage}
                forcePage={this.state.currentPage}
                pageCount={this.state.totalPage}
                onPageChange={(e: any) => this.setState({ currentPage: e.selected }, () => {
                  this.get_School_Division()

                })}
                containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
            </Box>}


          </Grid>

        </Box>
        <Dialog
          PaperProps={{
            style: { borderRadius: 8 }
          }}
          aria-labelledby="customized-dialog-title"
          maxWidth="md"
          open={this.state.dialogOpen}
          onClose={() => this.setState({ dialogOpen: false, edit: false, viewId: '', divisionName: '', divisionNameError: false, divisionNameErrorMes: '' })}
          closeAfterTransition

          disableAutoFocus={true}
          className={`border_radius_8`}
        >
          <Grid className="admindivision-popup admin_division_popup_box" item xs={12} >
            <Grid item xs={12} className={`mb_27`}>
              <FormLabel
                className={`${this.props.classes.title_font} admin_division_add_edit_text`}
              >
                {this.state.edit ? "Edit Division" : "Add Division"}
              </FormLabel>
            </Grid>
            <Grid item xs={12} >
              <Grid item xs={12} className={`mb_14`}>
                <FormLabel
                  className={`${this.props.classes.subTitle_font} admin_division_add_edit_text`}

                >
                  Name
                </FormLabel>
              </Grid>
              <Grid item xs={12} >
                <TextField
                  id="standard-basic"
                  placeholder=""
                  style={forrmInput_textfield}
                  variant="outlined"
                  value={this.state.divisionName}
                  onKeyPress={(event: any) => {
                    if (event.target.value.length >= 30) {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value.trim();

                    if (value.length === 0) {
                      this.setState({
                        divisionNameError: true,
                        divisionNameErrorMes: " Division name is required.",
                      });
                    } else {
                      this.setState({
                        divisionNameError: false,
                        divisionNameErrorMes: "",

                      });
                    }
                    if (
                      value.length > 0
                    ) {
                      this.setState({ divisionName: event.target.value })
                    } else {
                      this.setState({ divisionName: value })
                    }



                  }}
                  onBlur={() => {
                    if (
                      this.state.divisionName === null ||
                      this.state.divisionName.length === 0
                    ) {
                      this.setState({
                        divisionNameError: true,
                        divisionNameErrorMes: " Division name is required.",
                      });
                    } else {
                      this.setState({
                        divisionNameError: false,
                        divisionNameErrorMes: "",
                      });
                    }
                  }}
                  error={this.state.divisionNameError}
                  helperText={this.state.divisionNameErrorMes}

                  inputProps={{ style: { fontFamily: "Open sans", fontSize: '1.125rem', color: "#3f526d", padding: '1rem 1.875rem' } }}
                  InputProps={{
                   
                  }}
                />
              </Grid>
            </Grid>


            <Grid item xs={12} className={`admin_division_update_btn_box mt_30`}>

              <Button
                style={{
                  padding: this.state.edit ? "13px 47px " : "13px 62px 13px 62px",
                }}

                onClick={() => {
                  if (this.state.edit) {
                    this.edit_School_Division()
                  }
                  else {
                    this.post_School_Division()
                  }
                }}
                className={`${this.props.classes.button_color} border_radius_8`}
              >
                <Typography
                  className={`admin_division_update_btn_text`}
                >
                  {this.state.edit ? "Update" : "Add"}
                </Typography>
              </Button>
              <Button
                className={`admin_division_cancel_btn ml_20`}
                onClick={() => {
                  this.setState({ dialogOpen: false, divisionName: '', divisionNameError: false, divisionNameErrorMes: '' }, () => { this.setState({ edit: false, viewId: '' }) })
                }}
              >
                <Typography
                  className={`admin_division_cancel_btn_text`}
                >
                  Cancel
                </Typography>
              </Button>
            </Grid>

          </Grid>
        </Dialog>
        {this.state.deleteAlertModal && <DeleteDivision deleteAlertModal={this.state.deleteAlertModal} closeModalFun={this.onDeleteCloseModal} id={this.state.viewId} />}
      </Box>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(AdminDivision as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End
