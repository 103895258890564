// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { List, ListItem,FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Grid ,Typography} from '@material-ui/core';
import { HISTORY } from "../../../../components/src/comman";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

function ReportCardTwoFourFive(props:any) {
  const classes = useStyles();
  const handleBack = () => {
    HISTORY.goBack();
  }
  return (
    <>
    <div className='profileTitle'>
    <Button className="backBtn" onClick={()=>handleBack()}><KeyboardBackspaceOutlinedIcon /> Back</Button>
    <h2 className={props.classes.heading_font}>Part 2 - Assessment of Learning </h2>
    {/* <Button className="editBtn commonBtnSecondary">Enable Edit <EditOutlinedIcon /> </Button> */}
    <Button style={{ position: "absolute",
  right: 0,
  marginRight: "10px"}} className={props.classes.button_color}>Enable Edit <EditOutlinedIcon /> </Button>
    </div>
    <div className="tableContent">
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={props.classes.subHeading_font}>A. Languages</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer
                style={{ width: "96%", marginLeft: "40px" }} >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                English
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 1
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 2
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <Typography style={{ fontWeight: "bold" }}>Reading Skills</Typography>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Pronounciation</ListItem>
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Accuracy</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Compreshension</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <Typography style={{ fontWeight: "bold" }}>  Writing Skills</Typography>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Creative Writting</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>HandWritting</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Grammar</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Spellings</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px"}}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Vocabulary</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <Typography style={{ fontWeight: "bold" }}>Speaking Skills</Typography>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Conversation</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px"}}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Recitation</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px"}}>
                                <Typography style={{ fontWeight: "bold" }}>Listening Skills</Typography>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Comprehension</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Extra Reading</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Activity Project</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            <TableContainer
                style={{ width: "96%", marginLeft: "40px" }} >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                        <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Tamil/Hindi
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 1
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 2
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <Typography style={{ fontWeight: "bold" }}>Reading Skills</Typography>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Pronounciation</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Accuracy</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Compreshension</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px"}}>
                                <Typography style={{ fontWeight: "bold" }}>  Writing Skills</Typography>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Creative Writting</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>HandWritting</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px"}}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Grammar</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Spellings</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Vocabulary</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <Typography style={{ fontWeight: "bold" }}>  Speaking Skills</Typography>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Conversation</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Recitation</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <Typography style={{ fontWeight: "bold" }}>Listening Skills</Typography>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Comprehension</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Extra Reading</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Activity Project</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
         <Typography className={props.classes.subHeading_font}>B. Mathematics</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Aspects
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 1
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 2
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px"}}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Concept</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px"}}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Activity</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Tables</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Mental Ability</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
        <Typography className={props.classes.subHeading_font}>C. Enviromental Science / Science</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Aspects
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 1
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 2
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 3
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Enviromental Sensitivity</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px"}}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Activity/Project</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Group Discussion</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Written Work</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
        <Typography className={props.classes.subHeading_font}>D. Computer Science</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Games
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 1
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 2
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Skills</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Aptitude</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={props.classes.subHeading_font}>E. Co-Curricular Activities</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Games
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 1
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 2
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Enthusiasm</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px"}}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Discipline</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Team Spirit</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Talent</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            <TableContainer
                style={{ width: "96%", marginLeft: "40px" }} >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Art/Craft
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 1
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 2
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Interest</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Creativity</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Skills</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            <TableContainer
                style={{ width: "96%", marginLeft: "40px" }} >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Music / Dance
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 1
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 2
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px"}}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Interest</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Rhythm / Steps</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Melody / Themes</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={props.classes.subHeading_font}>F. Personality Development</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Aspects
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 1
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 2
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Courteousness</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Confidence</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Caring of Belongings</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Neatness</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Regularity and Punctuality</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Initiatives</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Self-Control</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Respects for Other's Property</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Sharing and Caring</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
        <Typography className={props.classes.subHeading_font}>G. Health</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Aspects
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 1
                            </TableCell>
                            <TableCell align="center" className={props.classes.bodyText_font} style={{ borderRadius:"0px" }}>
                                Evaluation 2
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Height (Cms.)</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                             <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px" }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tabBodyCell} style={{ borderRadius:"0px" }}>
                                <ListItem className={props.classes.reportCardTwoFourFive_classAndSection_cellListItem}>Weight (kg.)</ListItem>
                            </TableCell>
                           <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} >
                            </TableCell>
                            <TableCell className={props.classes.reportCardTwoFourFive_classAndSection_tableCell_border} style={{ borderRadius:"0px"}}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>
    </div>
    </div>
    </>
  );
}
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(ReportCardTwoFourFive as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End
