// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: any;
  // Customizable Area Start
  classes: any;
  listView: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  listView: string;
  studentSubjects: any;
  searchQuery: string;
  type: string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudentSubjectsController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  apigetStudentSubjectsId: string = "";
  apigetSearchSubjectsId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      listView: 'list',
      studentSubjects: [],
      searchQuery: '',
      type: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.get_student_subjects = this.get_student_subjects.bind(this);
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if (HISTORY.location.state?.studentSubjects) {
      this.setState({ studentSubjects: HISTORY.location.state?.studentSubjects })
    }
    else {
      this.get_student_subjects();
    }
  }

  handleKeyPress = (event:any) => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (this.state.searchQuery !== "") {
          this.search_subjects(this.state.searchQuery)
      } else {
          this.get_student_subjects();
      }
    }
  }

  handleSearchBtnClick = () => {
    if (this.state.searchQuery !== "") {
      this.search_subjects(this.state.searchQuery)
    }else{
        this.get_student_subjects();
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apigetStudentSubjectsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ studentSubjects: responseJson.data })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetSearchSubjectsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ studentSubjects: responseJson.data })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }

    }
  }
  get_student_subjects = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentSubjectsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherSubjectsEndPoint + `?class_id=${data?.school_account?.data[0]?.attributes?.class?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  search_subjects = (value: string) => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetSearchSubjectsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectsEndPoint + `?class_id=${data?.school_account?.data[0]?.attributes?.class?.id}&subject_name=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
