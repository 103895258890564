// Customizable Area Start
import { Box, Button, Typography, IconButton } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { CloseOutlined } from '@material-ui/icons';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import './TeacherDashboard.web.css';
import ViewThoughtModalController, {
  Props,
} from './ViewThoughtModalController.web';
import moment from 'moment';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End
export class ViewThoughtModal extends ViewThoughtModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={this.props.mainReminderOpen}
          onClose={this.props.modalhandleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={this.props.mainReminderOpen} style={{ border: 'none' }}>
            <div className="thoughtpopup">
              <Box style={{ height: '100%' }}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: '0.3px solid #979797',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Typography
                      style={{
                        textTransform: 'none',
                        fontSize: 24,
                        fontFamily: 'Open Sans',
                        fontWeight: 'bold',
                        color: '#3f526d',
                        margin: '0 0px 26px 0px',
                      }}
                    >
                      Thought for the day
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton onClick={this.props.modalhandleClose}>
                      <CloseOutlined />
                    </IconButton>
                  </Box>
                </Box>
                <div
                  style={{
                    // fontSize: 16,
                    // fontWeight: 'bold',
                    marginTop: 30,
                  }}
                  className={this.props.classes.bodyText_font}
                  dangerouslySetInnerHTML={{
                    __html: this.props.mainReminder.attributes.thought_title,
                  }}
                />
              </Box>
            </div>
          </Fade>
        </Modal>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  ViewThoughtModal as React.ComponentType<any>
);
// Customizable Area End
