// Customizable Area Start
import React from "react";
import moment from "moment";
import {
  Box,
  Typography,
  WithStyles,
  withStyles,
  IconButton,
  Menu,
  MenuItem
  // MoreVertOutlined,
} from "@material-ui/core";
import { MoreVertOutlined } from "@material-ui/icons";
import { deleteIcon, editIcon } from "../assets";
import EventMenuBoxWeb from "./EventMenuBox.web";
// Customizable Area End

interface IProps {
  // Customizable Area Start
  postEvent?:any;
  close?:any
  classes?: any;
  eventDetail?: any;
  createEventDelete?: any;
  updateEvent?: any;
  updateStateEvent?: any;
  OpenPostEditEventModal?:any
  // Customizable Area End
}

// const UpcomingBox: React.FC<IProps> = ({ classes, eventDetail, createEventDelete, updateEvent, updateStateEvent }) => {
//   postEvent: boolean;
//   close: React.Dispatch<React.SetStateAction<boolean>>;
//   OpenPostEditEventModal: any;
// }

const UpcomingBox: React.FC<IProps> = ({
  classes,
  eventDetail,
  createEventDelete,
  updateEvent,
  postEvent,
  close,
  OpenPostEditEventModal,
  updateStateEvent
}) => {
  // Customizable Area Start
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [eventId, setEventId] = React.useState<any>(null);
  // Customizable Area End

  // Customizable Area Start
  const handleClick = (event: any) => {
    setEventId(eventDetail.id)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createEventDelete2 = (obj: any) => {
    createEventDelete(obj)
  }

  const updateStateEditEvent = (obj: any, id:any) => {
    updateEvent(obj,id)
  }
  // Customizable Area End

  return (
    <>
      <Box className={classes.thoughtContainer}>
        <Box
          style={{
            margin: "8px",
          }}
        >
          <Box style={{ display: "flex" }}>
            <Box style={{ width: "90%" }}>
              <Typography className={classes.contentFont}>
                {eventDetail?.attributes?.name}
              </Typography>
            </Box>
            <Box style={{ width: "10%", height: "15px", marginLeft: "20px" }}>
              <IconButton
                onClick={handleClick}
              >
                <MoreVertOutlined />
              </IconButton>
            </Box>
          </Box>

          <Box>
            <Typography className={classes.EventFont}> 
              <div
                  dangerouslySetInnerHTML={{
                    __html: eventDetail?.attributes?.description,
                  }}
                />
            </Typography>
          </Box>

          <Box style={{ marginTop: "20px" }}>
            <Box>
              <div>
                <Typography className={classes.DateTextFont}>
                  Event From: {moment(eventDetail?.attributes?.start_time).format(`MMM DD YYYY`)} at{" "}
                  {moment(eventDetail?.attributes?.start_time).format(`hh:mm A`)}
                </Typography>
              </div>
              <div>
                <Typography className={classes.DateTextFont}>
                  Event To: {moment(eventDetail?.attributes?.end_time).format(`MMM DD YYYY`)} at{" "}
                  {moment(eventDetail?.attributes?.end_time).format(`hh:mm A`)}
                </Typography>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
      <EventMenuBoxWeb
        eventDetail={eventDetail}
        event={eventDetail?.attributes}
        anchorEl={anchorEl}
        setAnchorEl={handleClose}
        eventId={eventDetail?.id}
        createEventDelete2={createEventDelete2}
        updateStateEditEvent={updateStateEditEvent}
        postEvent2={postEvent}
        close={close}
        OpenPostEditEventModal={OpenPostEditEventModal}
      // createThoughtDelete={createThoughtDelete1}
      />
      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div style={{width:'200px'}}>
        <MenuItem >
        <Box style={{display:'flex',width:'100%',alignItems:'center'}}>
          <Box style={{width:'20%'}}>
          <img src= {editIcon} 
          style={{
            height:15,
            width:15
          }}
          />
          </Box>
          <Box>
          <Typography 
          className={classes.MenuItemFont}
          >
          Edit
          </Typography>
          </Box>
        </Box>
        </MenuItem>
        <Box className={classes.Line} />
        <MenuItem >
        <Box style={{display:'flex',width:'100%',alignItems:'center'}}>
          <Box style={{width:'20%'}}>
          <img src= {deleteIcon} 
          style={{
            height:20,
            width:20,
            marginTop:4
          }}
          />
          </Box>
          <Box>
          <Typography 
          className={classes.MenuItemFont}
          >
          Remove
          </Typography>
          </Box>
        </Box>
        </MenuItem>
        </div>
      </Menu> */}

    </>
  );
};
export const themeCreatStyle = ((theme: any) => ({
  // Customizable Area Start
  DateTextFont: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: 1.71,
    letterSpacing: "0.1px",
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  EventFont: {
    width: "90%",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: 1.71,
    letterSpacing: "0.1px",
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  thoughtContainer: {
    borderRadius: "8px",
    padding: "10px",
    margin: "10px",
    backgroundColor: "#fafafb",
  },
  contentFont: {
    fontSize: "16px",
    fontWeight: 600,
    color: theme.props.subheadingColor,
    fontFamily: theme.props.subheadingFont
  },
  // Customizable Area End
}))


// Customizable Area Start
export default withStyles(themeCreatStyle)(UpcomingBox);
// Customizable Area End
