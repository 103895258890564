Object.defineProperty(exports, "__esModule", {
  value: true,
});

const urlConfig = require("../../../../framework/src/config");

// Customizable Area Start
exports.apiGetMethodType = "GET";
exports.classesListEndPoint = "/bx_block_scheduling/schools/get_school_classes"; //need to change
exports.subjectListEndPoint = "/bx_block_scheduling/school_classes";  //need to change
exports.assessmentListEndPoint = "/bx_block_assessmenttest/assessments/class_assessments_by_subject"; //need to change
exports.assessmentReportListEndPoint = "/account_block/student_assessment_details"; //need to change

exports.examinationURL = urlConfig.examinationUrl;
exports.dashboardURL = urlConfig.dashboardURL;
exports.instituteURL = urlConfig.instituteURL;
exports.userManagementURL = urlConfig.baseURL;
exports.attendanceURL = urlConfig.attendanceURL;
// Customizable Area End
