// Customizable Area Start
import React, { useEffect, useState } from "react";
import { decryptData } from "../utils/common";
import ZoomMeeting from "./ZoomMeeting.web";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area End

// Customizable Area Start
const ZoomMeetingHomePage = () => {
  const [meetingDetail, setMeetingDetail] = useState();
  useEffect(() => {
    const encryptedMeetingParameters = localStorage.getItem("meeting-detail");
    if (encryptedMeetingParameters) {
      let decryptedMeetingParameters = JSON.parse(encryptedMeetingParameters);
      const apiKey = decryptData(
        decryptedMeetingParameters.meetingDetails.api_key,
        "meeting-api-key"
      );
      const apiSecret = decryptData(
        decryptedMeetingParameters.meetingDetails.api_secret,
        "meeting-api-secret"
      );
      const meetingId = decryptData(
        decryptedMeetingParameters.meetingDetails.id,
        "meeting-id"
      );
      const meetingRole = decryptData(
        decryptedMeetingParameters.meetingDetails.role,
        "meeting-role"
      );
      decryptedMeetingParameters = {
        ...decryptedMeetingParameters,
        meetingDetails: {
          api_key: apiKey,
          api_secret: apiSecret,
          id: meetingId,
          role: meetingRole,
        },
      };
      setMeetingDetail(decryptedMeetingParameters);
    } else {
      const user_data = localStorage.getItem('user_data');
      const data = JSON.parse(user_data || '{}')
      const role = localStorage.getItem("role");
      if (data?.type === "TeacherAccount") HISTORY.push("/TeacherClassesAndSections");
      else HISTORY.push("/StudentClassesAndSections");
    }
  }, []);
  return (
    <>
      {meetingDetail ? <ZoomMeeting currentEvent={meetingDetail} /> : <div />}
    </>
  );
};
// Customizable Area End

// Customizable Area Start
export default ZoomMeetingHomePage;
// Customizable Area End
