// Customizable Area Start
import React from "react";
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  FormHelperText,
  Box,
} from "@material-ui/core";
import AudioTypeQuestionController, {
  Props,
} from "./AudioTypeQuestionController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import {
  imageMarksMinusSVG,
  imageMarksPlusSVG,
  imgBackArrowSVG,
  recordAudioSVG,
  uploadAudioSVG,
} from "../assets";
import QuestionMetaData from "../Components/QuestionMetaData.web";
import "../TeacherAssessment.web.css";
import QuestionSolution from "../Components/QuestionSolution.web";
import { invalidCharsForNumberType } from "../../../profiles/src/utils/common";
import AlertModal from "../../../alert/src/AlertModal.web";
import { HISTORY } from "../../../../components/src/comman";
import TextEditor from "./TextEditor/TextEditor.web";
import { withStyles } from "@material-ui/core/styles";
import QuestionSidebarCollapse from "./QuestionSidebarCollapse.web";
import QuestionPreviewModal from "../Components/QuestionPreviewModal.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AudioList from "./AudioList.web";
import SetAudioFileName from "./SetAudioFileName.web";
import WarningModal from "../WarningModal.web";
import WebNativeAudioRecorder from "./WebNativeAudioRecorder.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import './AddQuestion.web.css';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class AudioTypeQuestion extends AudioTypeQuestionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const {
      marks,
      negativeMarks,
      selectedCategoryQuestionData,
      sectiondata,
    } = this.state;

    return (
      <>
        <div>
          {this.state.showLoader && (
            <Spinner spinnerModal={this.state.showLoader} />
          )}
          <Grid
            container
            className="marks_section"
          >
            <Grid
              item
              xs={12}
              sm={10}
              className="question_flex_column"
            >
              <Typography className={`back_with_arrow ${this.props.classes.bodyText_font}`}>
                <span onClick={HISTORY.goBack}>
                  <img
                    src={imgBackArrowSVG}
                    className="question_back_arrow"
                  ></img>
                  Back
                </span>

                <span
                  className={`${this.props.classes.bodyText_font} textlabel_breadcrum`}
                >
                  <span className="text-style-light">
                    {this.state.editButton
                      ? "Edit question "
                      : "Create new question "}
                  </span>
                  (
                  {
                    selectedCategoryQuestionData?.question_category_id === 1 ? (
                      <>Objective</>
                    ) : (selectedCategoryQuestionData?.question_category_id === 2 ? (
                      <>Subjective</>
                    ) : (selectedCategoryQuestionData?.question_category_id === 3) ? <>Audio video</> : '')}{" "}
                  - {selectedCategoryQuestionData?.name})
                </span>
              </Typography>

              <div className="marks_wrap_div">
                <div className="marks_div">
                  <Typography
                    className={`question_marks_text ${this.props.classes.subHeading_font}`}
                  >
                    Marks
                  </Typography>
                  <div>
                    <div
                      className={this.state.marksError ? `view_marksbox red_border` : `view_marksbox none_border`}
                    >
                      <img
                        src={imageMarksPlusSVG}
                        className="image_marks"
                        onClick={this.plusMarksClick}
                      ></img>
                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={(event) => {
                          const value: number = parseFloat(
                            parseFloat(event.target.value)
                              .toFixed(3)
                              .slice(0, -1)
                          );
                          if (value > 0 || marks > 0) {
                            this.setState({ marksError: false });
                          } else {
                            this.setState({
                              marksError: true,
                              marks: 0,
                              marksErrorText: "Please enter marks.",
                            });
                          }
                          if (value < this.state.sectiondata?.remaining_marks) {
                            this.setState({ marks: value, marksError: false });
                          } else if (
                            value ==
                            this.state.sectiondata?.remaining_marks - 1
                          ) {
                            this.setState({ marks: value, marksError: false });
                          }
                          if (value >= this.state.negativeMarks) {
                            this.setState({
                              marks: value,
                              negmarksError: false,
                            });
                          } else {
                            this.setState({
                              marks: value,
                              negmarksError: true,
                            });
                          }

                          if (this.state.editButton) {
                            if (
                              value >
                              this.state.sectiondata?.remaining_marks +
                              HISTORY.location.state?.questionData?.attributes
                                ?.marks
                            ) {
                              this.setState({
                                marks: value,
                                marksError: true,
                                marksErrorText:
                                  "Marks not greater than section marks.",
                              });
                            } else {
                              this.setState({
                                marks: value,
                              });
                            }
                          } else {
                            if (
                              value > this.state.sectiondata?.remaining_marks
                            ) {
                              this.setState({
                                marks: value,
                                marksError: true,
                                marksErrorText:
                                  "Marks not greater than section marks.",
                              });
                            } else {
                              this.setState({
                                marks: value,
                              });
                            }
                          }
                        }}
                        onKeyPress={(event) => {
                          if (invalidCharsForNumberType.includes(event.key)) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        value={marks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.marksError && (
                      <FormHelperText className="red_text">
                        {this.state.marksErrorText}
                      </FormHelperText>
                    )}
                  </div>
                </div>

                {this.state.assessmentHasNegativeMarking && (
                  <div className="marks_div">
                    <Typography
                      className={`question_marks_text ${this.props.classes.subHeading_font}`}
                    >
                      Negative Marks
                    </Typography>
                    <div
                      className={this.state.negmarksError ? "view_marksbox red_border" : "view_marksbox none_border"}
                    >
                      <img
                        src={imageMarksMinusSVG}
                        className="image_marks"
                        onClick={this.minusMarksClick}
                      ></img>
                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={(event) => {
                          const value: number = parseFloat(
                            parseFloat(event.target.value)
                              .toFixed(3)
                              .slice(0, -1)
                          );
                          if (value > this.state.marks) {
                            this.setState({ negmarksError: true });
                          } else {
                            this.setState({ negmarksError: false });
                          }
                          this.setState({ negativeMarks: value });
                        }}
                        onKeyPress={(event) => {
                          if (invalidCharsForNumberType.includes(event.key)) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        value={negativeMarks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.negmarksError && (
                      <FormHelperText className="red_text">
                        Negative marking not greater then marks.
                      </FormHelperText>
                    )}
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={2} className="remaining_section_marks">
              <Typography className={`remaining_marks ${this.props.classes.subHeading_font}`}>
                Remaining Section Marks
              </Typography>

              <div className="circular_div">
                <CircularProgress
                  variant="static"
                  className="grey_circular"
                  size={92}
                  thickness={2}
                  value={100}
                />
                <CircularProgress
                  variant="static"
                  disableShrink
                  className="colored_circular"
                  size={92}
                  value={this.state.remainSectionMarkPr}
                  thickness={2}
                />
                <Typography
                  className={`circular_marks_text ${this.props.classes.bodyText_font}`}
                >
                  {(
                    sectiondata?.section_marks - sectiondata?.remaining_marks
                  ).toFixed(2)}
                  /{sectiondata?.section_marks}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Typography
            className={`question_text ${this.props.classes.subHeading_font}`}
          >
            Question
          </Typography>
          <div ref={this.questionEditorRef}>
            <TextEditor
              myref={this.questionEditorRef}
              handleChange={(value: any) => {
                this.setState({ question: value }, () => {
                  if (
                    this.state.question === null ||
                    this.state.question === ""
                  ) {
                    this.setState({ questionError: true });
                  } else {
                    this.setState({ questionError: false });
                  }
                });
              }}
              editorData={
                HISTORY.location.state?.questionData?.attributes?.title
                  ? HISTORY.location.state?.questionData?.attributes?.title
                  : ""
              }
              isEdit={this.state.editButton}
            />
          </div>

          {this.state.questionError && (
            <FormHelperText className="question_error">
              Please enter question.
            </FormHelperText>
          )}

          <Grid container>

            <div
              className={`record-audio-btn-div ${this.props.classes.subHeading_font}`}
              onClick={() => {
                this.setState({ fromUploadAudio: false });
                this.recordAudioClick();
              }}
            >
              <img src={recordAudioSVG} alt="record" height={30} width={30} />
              Record Audio
            </div>
            <div
              className={`record-audio-btn-div ${this.props.classes.subHeading_font}`}
              onClick={() => {
                this.setState({ fromUploadAudio: true });
                this.showOpenAudioFileDlg();
              }}
            >
              <img src={uploadAudioSVG} alt="upload" height={30} width={30} />
              Upload Audio
            </div>

            <input
              ref={this.inputOpenAudioFileRef}
              accept="audio/*"
              className="hidden_input"
              id="contained-button-file"
              type="file"
              onChange={(e: any) => this.handleAudioUpload(e)}
            />
          </Grid>

          {this.state.showAudioRecordingSection &&
            <div className="audio_recording_section">
              <Grid item className="audio_recording_grid" xs={12}>
                <WebNativeAudioRecorder
                  renderedStreamDuration={this.state.audioTimerSecond}
                  handleCountDown={(seconds: number) => {
                    this.setState({
                      audioTimerSecond: seconds
                    });
                  }}
                  handleReset={() => { }}
                  handleAudioStop={(result: any) => {
                    this.getRecoredAudioBlobUrl(result);
                  }}
                  mimeTypeToUseWhenRecording={""} />
                <SetAudioFileName
                  classes={this.props.classes}
                  isModalOpen={this.state.openSaveModal}
                  onCloseModal={() => this.setState({
                    openSaveModal: false
                  })}
                  onClickOk={(name: string) => this.handleModalOk(name)}
                  onClickCancel={this.handleModalCancel}
                />
              </Grid>
            </div>
          }

          {/* Audiolist */}

          {
            this.state.audioList.length > 0 && this.state.audioList.map((audio: any, index: number) => {
              return (
                <AudioList
                  key={audio.id}
                  audio={audio}
                  showActions={true}
                  isStudent={false}
                  removeAudio={() => {
                    this.setState({
                      deleteAudioIndex: index,
                      openRemoveAudioModal: true,
                    });
                  }}
                  setAudioDuration={(durationInSecond: number) =>
                    this.setAudioDuration(index, durationInSecond)
                  }
                />
              )
            })
          }
          <WarningModal
            title={"Delete Audio?"}
            subTitle={"Are you sure you want to remove this audio file."}
            paragraph={""}
            warningModal={this.state.openRemoveAudioModal}
            onCloseModal={() => this.setState({ openRemoveAudioModal: false, deleteAudioIndex: -1 })}
            warningClickOk={() => {
              this.removeAudio();
            }}
          />

          <QuestionSolution
            hintAnswer={this.state.hintAnswer}
            hintAnswerOnchange={(value: any, id: any) => {
              let tempData = this.state.hintAnswer;
              const index = this.state.hintAnswer.findIndex(
                (res: any) => res.hintId === id
              );
              tempData[index].hint = value.trim();
              this.setState({
                hintAnswer: tempData,
              });
            }}
            explaination={this.getExplanation()}
            getPreSignedValue={(
              preSignedId: string,
              typeofAttachment: string,
              blobUrl: string,
            ) => {
              if (typeofAttachment === "hints") {
                let tempData = this.state.hintAnswer;
                tempData[0].files = [preSignedId];
                tempData[0].src = blobUrl;
                this.setState({
                  hintAnswer: tempData,
                  hintId: preSignedId,

                });
              } else {
                this.setState({ solutionImageSignedId: preSignedId });
              }
            }}
            explainationOnchange={(value: any) => {
              this.setState({
                explaination: value,
              });
            }}
            attachmentExplanationUrl={this.state.attachmentExplanationUrl}
            hintsExplanationUrl={this.state.hintsExplanationUrl}
            removeSolutionImage={(attachmentType: string) => {
              if (attachmentType === "hints") {
                this.setState({
                  hintsExplanationUrl: "",
                  hintId: null,
                });
              } else {
                this.setState({
                  attachmentExplanationUrl: "",
                  solutionImageSignedId: null,
                });
              }
            }}
          />
          <QuestionMetaData
            selectedSubject={this.state.selectedSubject}
            onChangeSubject={(e: any) => {
              this.setState({ selectedSubject: e.target.value });
            }}
            chapterListData={this.state.chapterListData}
            selectedChapter={this.state.selectedChapter}
            onChangeChapter={(e: any) => {
              this.setState(
                {
                  selectedChapter: e.target.value,
                  metaDataErrors: {
                    ...this.state.metaDataErrors,
                    chapter: false,
                  },
                },
                () => this.get_unit_list(this.state.selectedChapter, true)
              );
            }}
            unitListData={this.state.unitListData}
            selectedUnit={this.state.selectedUnit}
            onChangeUnit={(e: any) => {
              this.setState({
                selectedUnit: e.target.value,
                metaDataErrors: { ...this.state.metaDataErrors, unit: false },
              });
            }}
            selectedSkill={this.state.selectedSkill}
            onChangeSkill={(e: any) => {
              this.setState({
                selectedSkill: e.target.value,
                metaDataErrors: { ...this.state.metaDataErrors, skill: false },
              });
            }}
            selectedProficiency={this.state.selectedProficiency}
            onChangeProficiency={(e: any) => {
              this.setState({
                selectedProficiency: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  proficiency: false,
                },
              });
            }}
            selectedDifficultyLevel={this.state.selectedDifficultyLevel}
            onChangeDifficultyLevel={(e: any) => {
              this.setState({
                selectedDifficultyLevel: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  difficultyLevel: false,
                },
              });
            }}
            keyword={this.state.keyword}
            keywordsList={this.state.keywordsList}
            onChangeKeywords={this.onChangeKeywords}
            onBlurKeywords={this.onBlurKeywords}
            onKeyDownKeywords={this.onKeyDownKeywords}
            handleDeleteKeyword={this.handleDeleteKeyword}
            metaDataErrors={this.state.metaDataErrors}
            selectedBloomTaxonomy={this.state.selectedBloomTaxonomy}
            onChangeBloomTaxonomy={(e: any) => {
              this.setState({
                selectedBloomTaxonomy: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  bloomTaxonomy: false,
                },
              });
            }}
          />

          <div className="question_action_items">
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.background_button_no_hover}`}
              onClick={() => {
                this.setState({ prevQuestionModal: true });
              }}
            >
              <Box className="preview_btn_box">
                <VisibilityIcon className="eye_preview" />
                <Typography> Preview</Typography>
              </Box>
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.button_color}`}
              onClick={this.saveQuestion}
            >
              {this.state.editButton ? "Update" : "Save"}
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_cancel_btn ${this.props.classes.outline_button_no_hover}`}
              onClick={()=>HISTORY.push("/TeacherAssessmentCreateSection")}
            >
              Cancel
            </Button>
          </div>
          <QuestionPreviewModal
            questionType={"audio"}
            questionPreviewModal={this.state.prevQuestionModal}
            closeModalFun={() => {
              this.setState({ prevQuestionModal: false });
            }}
            question={this.state.question}
            questionHint={this.state.hintAnswer}
            audioList={this.state.audioList}
          />

          <AlertModal
            alertModal={this.state.alertModal}
            isTeacher={true}
            onCloseModal={() => {
              this.setState({ alertModal: false });
            }}
            alertTitle="Alert!"
            alertMessage={this.state.alertMessage}
            buttonText="OK"
          />

        </div>
        {this.state.editButton && (
          <QuestionSidebarCollapse
            editedQuestionData={HISTORY.location.state?.questionData}
          />
        )}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

export default withStyles(themeCreateStyle)(
  AudioTypeQuestion as React.ComponentType<any>
);

// Customizable Area End
