// Customizable Area Start
import {
  InputAdornment, RadioGroup, Step, StepConnector, StepLabel, Stepper, TextField, Typography
} from "@material-ui/core";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import { StepIconProps } from "@material-ui/core/StepIcon";
import {
  createStyles, makeStyles,
  Theme, withStyles
} from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const ColorLibConnector = withStyles({
  alternativeLabel: {
    top: 15,
    left: "calc(-50% + 1px)",
    right: "calc(50% + 1px)",
  },
  active: {
    "& $line": {
      borderColor: "#3a60d7",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#3a60d7",
    },
  },
  line: {
    width: "auto",
    borderStyle: "solid",
    borderImageSlice: 1,
  },
})(StepConnector);

const useCustomStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ffffff",
    zIndex: 1,
    color: "#fff",
    height: "34px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Open sans",
  },
  active: {
    width: "34px",
    border: "1px solid #3a60d7",
    borderWidth: 3,
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  verticalLine: {
    width: 3,
    height: 16,
    borderRadius: "2px",
    backgroundColor: "#3a60d7",
  },
});

function CustomStepIcon(props: StepIconProps) {
  const classes = useCustomStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      <div className={active ? "" : classes.verticalLine} />
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflow: "auto",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

const form_input_text_field = {
  borderRadius: 6,
} as const;

export const StudentStepperWithText=(props: any) =>{
  const classes = useStyles();
  const CustomRadio = withStyles({
    root: {
      color: "#3f526d",
      "&$checked": {
        color: "#3a60d7",
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);
  return (
    <div className={classes.root}>
      <RadioGroup
        aria-label="position"
        name="position"
        value={`${props.answerIds[0]}`}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props?.onChangeStepperValue(event)
        }
        }
      >
        <Stepper
          alternativeLabel
          activeStep={props.activeStep}
          connector={<ColorLibConnector />}
        >
          {props.steps?.map((stepItem: any, index: number) => (
            <Step key={index}>
              <StepLabel
                className="rating-step-custom-color"
                StepIconComponent={CustomStepIcon}
              >
                {props?.questionType == 'graphic_rating_scale'
                  && (
                    <div className="emoji-icon">
                      <span>{stepItem?.attributes?.emoji}</span>
                    </div>
                  )}
                <div className="question-step-label">
                  <CustomRadio
                    value={stepItem?.id}
                    style={{ padding: "0px 10px 0px 0px" }}
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "A" }}
                  />

                  <TextField
                    id="standard-basic"
                    placeholder=""
                    style={form_input_text_field}
                    variant="outlined"
                    value={stepItem?.attributes?.answer}
                    disabled
                    inputProps={{
                      className: `rating-text-field ${props.classes.heading_font}`,
                    }}
                    InputProps={{
                      endAdornment: props?.questionType == 'percentage_rating_scale' && (
                        <InputAdornment position="end">
                          <Typography
                            className={props.classes.heading_font}
                          >
                            %
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </RadioGroup>
    </div>
  );
}
// Customizable Area End
// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(StudentStepperWithText);
// Customizable Area End
