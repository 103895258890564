// Customizable Area Start
import React from "react";
import { Box, Grid, Modal, Button, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const form_input_textfield = {
  width: "100%",
  borderRadius: "6px",
  border: "none",
  textAlign: "start",
} as const;
// Customizable Area End

interface Props {
  // Customizable Area Start
  openSaveVideoModal: boolean;
  onCloseModal: any;
  fileName: string;
  onChangeFileName: any;
  onBlurFileName: any;
  fileNameError: boolean;
  fileNameErrorMsg: string;
  saveVideoClick: any;
  classes: any;
  // Customizable Area End
}

export const SaveVideoModal = ({
  // Customizable Area Start
  openSaveVideoModal,
  onCloseModal,
  fileName,
  onChangeFileName,
  onBlurFileName,
  fileNameError,
  fileNameErrorMsg,
  saveVideoClick,
  classes,
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  return (
    <>
      <Modal
        open={openSaveVideoModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="save-video-modal-main-div">
          <Grid
            className={`flex-center save-video-title ${classes.heading_font}`}
          >
            Save Video
          </Grid>

          <TextField
            id="standard-basic"
            placeholder="File Name"
            style={form_input_textfield}
            value={fileName}
            onChange={onChangeFileName}
            onBlur={onBlurFileName}
            error={fileNameError}
            helperText={fileNameErrorMsg}
            className={`no-underline ${classes.input_theme_style}`}
            inputProps={{
              className: `${fileNameError ? "video-name-error-field" : "video-name-field"}`
            }}
          />

          <Grid className="justify-align-center">
            <Button
              className={`view-save-video-btn ${classes.button_color}`}
              onClick={saveVideoClick}
              disabled={!fileName}
            >
              Save
            </Button>
            <Button
              className={`view-save-video-btn ${classes.outline_button_no_hover}`}
              onClick={onCloseModal}
            >
              Cancel
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(
  SaveVideoModal as React.ComponentType<any>
);
// Customizable Area End
