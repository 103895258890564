// Customizable Area Start
import {
  InputBase,
  NativeSelect,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import HOCAuth from "../../../../../shared/Header/src/HOCAuth.web";
import WardResultController, { Props } from "./WardResultController.web";
import "./WardResult.web.css"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { themeCreateStyle } from "../../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme: any) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 8,
    position: 'relative',
    border: '1px solid #ced4da',
    backgroundColor: theme.palette.background.paper,
    padding: '10px 26px 10px 12px',
    width: '100%',
    minHeight:28,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: theme.props.textFont,
    color: theme.props.textColor,
    fontSize: 18,
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);
// Customizable Area End
export class WardResult extends WardResultController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {studentList}= this.state;
    return (
      <Grid className={`result-main-grid ${this.props.classes.wardResult_dashboard_main_grid}`} container direction="row">
        <Grid item xs={12}>
            <Typography
              className={`${this.props.classes.heading_font} result-title-text`}
            >
              Result
            </Typography>
        </Grid>
        {/* Options */}
        <Grid item xs={12}>
          <Grid container className={`result-option-grid`}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <NativeSelect 
                  className="result_dropdwon"
                  input={<BootstrapInput />}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <option value="">Select Exam</option>
                </NativeSelect>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} className={`result-flex-start result-option-item`}>
                <Typography className={`${this.props.classes.wardResult_dashboard_font_14} ${this.props.classes.bodyText_font} result-fw-600`}>
                  Total Students
                </Typography>
                <Typography className={`${this.props.classes.wardResult_dashboard_font_18} ${this.props.classes.subHeading_font} result-fw-600`}>
                  500
                </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} className={`result-flex-start result-option-item`}>
                <Typography className={`${this.props.classes.wardResult_dashboard_font_14} ${this.props.classes.bodyText_font} result-fw-600`}>
                  Subject
                </Typography>
                <Typography className={`${this.props.classes.wardResult_dashboard_font_18} ${this.props.classes.subHeading_font} result-fw-600`}>
                  Biology
                </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} className={`result-flex-start result-option-item`}>
                <Typography className={`${this.props.classes.wardResult_dashboard_font_14} ${this.props.classes.bodyText_font} result-fw-600`}>
                  Exam Date
                </Typography>
                <Typography className={`${this.props.classes.wardResult_dashboard_font_18} ${this.props.classes.subHeading_font} result-fw-600`}>
                  10/10/2021
                </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Options */}

        {/* Student result */}
        <Grid item xs={12} className={`result-student-marks`}>
          <Typography className={`${this.props.classes.wardResult_dashboard_font_18} ${this.props.classes.subHeading_font} result-fw-600`}>Student Name</Typography>
          <Typography className={`${this.props.classes.wardResult_dashboard_font_18} ${this.props.classes.subHeading_font} result-fw-600`}>Rank : 5</Typography>
          <Typography className={`${this.props.classes.wardResult_dashboard_font_18} ${this.props.classes.subHeading_font} result-fw-600`}>Percentage : 99%</Typography>
        </Grid>
        {/* Student result END */}

        {/* Student List */}
        <Grid item xs={12}>
          <TableContainer className={`result-table-container`}>
            <Table>
              <TableHead>
                <TableCell className={`result-table-header-cell result-table-cell ${this.props.classes.subHeading_font}`}>Student Name</TableCell>
                <TableCell className={`result-table-header-cell result-table-cell ${this.props.classes.subHeading_font}`} align={`right`}>Rank</TableCell>
                <TableCell className={`result-table-header-cell result-table-cell ${this.props.classes.subHeading_font}`} align={`right`}>Marks</TableCell>
              </TableHead>
              <TableBody>
                {studentList.length > 0 && studentList.map((student:any) => (
                  <TableRow key={student.id}>
                    <TableCell className={`result-table-cell ${this.props.classes.bodyText_font}`}>{student.name}</TableCell>
                    <TableCell className={`result-table-cell ${this.props.classes.bodyText_font}`} align={`right`}>{student.rank}</TableCell>
                    <TableCell className={`result-table-cell ${this.props.classes.bodyText_font}`} align={`right`}>{student.marks}</TableCell>
                  </TableRow>
                ))}
                {studentList.length === 0 && 
                <TableRow>
                  <TableCell className={`result-table-cell`} colSpan={3}>No Result Found</TableCell>
                </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* Student List END */}
      </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(WardResult, "TeacherAccount")
);
// Customizable Area End
