// Customizable Area Start
import React from "react";
import "./AlertBlock.web.css";
import {
    Box,
    Modal,
    Divider,
    Typography,
    Button,
    Grid,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
const titleDiv = {
    fontFamily: "Open sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: 'center',
    color: "#3f526d",
    marginBottom: '14px',
} as const;
// Customizable Area End
interface Props {
    // Customizable Area Start
    alertModal: boolean;
    isTeacher: boolean;
    onCloseModal: any;
    alertTitle?: string;
    alertMessage: string;
    buttonText?: string;
    // Customizable Area End
}

const AlertModal = ({
    alertModal,
    isTeacher,
    onCloseModal,
    alertTitle = "Alert!",
    alertMessage,
    buttonText = "OK",
}: Props) => {
    // Customizable Area Start
    return (
        <Modal
            open={alertModal}
            onClose={onCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="alert-common-modal"
            id="alert-common-modal-id"
        >
            <Box
                style={{
                    justifyContent: "center",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    borderRadius: "29px",
                    padding: "32px 18px",
                    width: "576px",
                }}
                className="alert_modal_xs"
            >
                <div style={titleDiv}>{alertTitle}</div>
                <Divider />

                <Typography
                    style={{
                        margin: "30px",
                        fontFamily: "Open sans",
                        fontSize: "20px",
                        fontWeight: 500,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: "#3f526d"
                    }}
                >
                    {alertMessage}
                </Typography>

                <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        data-test-id="OKButton"
                        style={{
                            background: isTeacher ? "linear-gradient(253deg, #3a60d7 111%, #253873 -30%)" : "linear-gradient(to top, #af38cb 101%, #471c51 33%)",
                            color: "#fff",
                            width: "22%",
                            height: 50,
                            textTransform: "capitalize",
                            fontSize: 18,
                        }}
                        onClick={onCloseModal}
                    >
                        {buttonText}
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
    // Customizable Area End
};
// Customizable Area Start
export default AlertModal;
// Customizable Area End
