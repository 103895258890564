Object.defineProperty(exports, "__esModule", {
    value: true,
});

const urlConfig = require("../../../../framework/src/config");
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.GET = "GET";
exports.POST = "POST";
exports.PUT = "PUT";
exports.DELETE = "DELETE";
exports.PATCH = "PATCH";
exports.dashboardURL = urlConfig.dashboardURL;
exports.instituteURL = urlConfig.instituteURL;
exports.userManagementURL = urlConfig.baseURL;
exports.attendanceURL = urlConfig.attendanceURL;
exports.examinationUrl = urlConfig.examinationUrl;
exports.getAssessmentEndPoint = "/bx_block_assessmenttest/assessments";
exports.getPreSignedUrl = "bx_block_bulk_uploading/uploads/s3_signed_url";
exports.getAssessmentSectionEndPoint  = "/bx_block_assessmenttest/questions/get_assessment_section_evaluation"
exports.getAssessmentSectionQuestionEndPoint = "/bx_block_assessmenttest/assessment_sections";
  // Customizable Area End
