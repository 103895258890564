// Customizable Area Start
import React from "react";
import Grid from '@material-ui/core/Grid';
import {
    Button,
    Dialog,
    Typography,
    TextField,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    TimePicker,
} from '@material-ui/pickers';
import './BatchAndPeriod.web.css'
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

interface Props {
    // Customizable Area Start
    addEditBatchDialog: boolean;
    handleAddEditBatchDialogClose: any;
    isEditBatch: boolean;
    batchName: string;
    batchNameError: any;
    batchNameErrorMsg: string;
    onChangeBatchName: any;
    onBlurBatchName: any;
    selectedStartTime: any;
    selectedStartTimeBatchLabel: string;
    selectedEndTime: any;
    selectedEndTimeBatchLabel: string;
    handleStartTimeChange: any;
    handleEndTimeChange: any;
    addEditBatchClick: any;
    selectedBatchEndTimeError: any;
    selectedBatchEndTimeErrorMsg: string;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
const AddEditBatch = ({
    addEditBatchDialog,
    handleAddEditBatchDialogClose,
    isEditBatch,
    batchName,
    batchNameError,
    batchNameErrorMsg,
    onChangeBatchName,
    onBlurBatchName,
    selectedStartTime,
    selectedStartTimeBatchLabel,
    selectedEndTime,
    selectedEndTimeBatchLabel,
    handleStartTimeChange,
    handleEndTimeChange,
    addEditBatchClick,
    selectedBatchEndTimeError,
    selectedBatchEndTimeErrorMsg,
    classes,
}: Props) => {
    return (
        <Dialog
            PaperProps={{
                className: "dynamic_popup_width ViewBatchModal"
            }}
            aria-labelledby="customized-dialog-title"
            maxWidth="md"
            open={addEditBatchDialog}
            onClose={() => handleAddEditBatchDialogClose()}
            closeAfterTransition
            fullWidth={true}
            disableAutoFocus={true}
            className="ViewBatchModal"
            id="admin-add-edit-batch-modal"
            data-test-id="admin-add-edit-batch-modal"
        >
            <div className="PeriodModalDiv">
                <Grid container >
                    <Grid item xs={12} className="ViewBatchHead">
                        <Typography className={`ViewBatchText ${classes.title_font}`}>
                            {isEditBatch ? 'Edit Batch' : 'Add Batch'}
                        </Typography>
                        <CloseIcon
                            className="ViewBatchClose"
                            data-test-id="add-edit-batch-close-btn"
                            onClick={() => handleAddEditBatchDialogClose()}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} className="AddEditFields">
                        <div>
                            <Typography
                                className={`AddEditTitle ${classes.subTitle_font}`}
                            >
                                Name of Batch
                            </Typography>
                            <TextField
                                id="standard-basic"
                                data-test-id="add-edit-batch-text-field"
                                placeholder="Example Batch 1, Morning Batch..."
                                className={`AddPeriodTextfieldStyle ${classes.subTitle_font}`}
                                variant="outlined"
                                value={batchName}
                                onChange={onChangeBatchName}
                                onBlur={onBlurBatchName}
                                error={batchNameError}
                                helperText={batchNameErrorMsg}
                                inputProps={{ className: `PeriodInputProps ${classes.subTitle_font}` }}
                                InputProps={{
                                    className: `PeriodNameInputProps ${classes.subTitle_font}`
                                }}
                            />
                        </div>

                        <Typography className={`BatchDurationText ${classes.subTitle_font}`}>
                            Batch Duration
                        </Typography>

                        <Grid container className="AddEditFieldSpace">
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    className={`AddEditTitle ${classes.subTitle_font}`}
                                >
                                    Start Time
                                </Typography>
                                <div className="AddEditFlexRow">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimePicker
                                            autoOk
                                            inputVariant="outlined"
                                            format="hh:mm"
                                            data-test-id="batch-popup-start-time"
                                            className="periodTimePicker"
                                            value={selectedStartTime}
                                            onChange={(e: any) => handleStartTimeChange(e)} />
                                    </MuiPickersUtilsProvider>
                                    <div className="timePeriodBox">
                                        <Typography className="timePeriodText">{selectedStartTimeBatchLabel}</Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    className={`padding-top-15 AddEditTitle ${classes.subTitle_font}`}
                                >
                                    End Time
                                </Typography>

                                <div className="AddEditFlexRow">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimePicker
                                            autoOk
                                            inputVariant="outlined"
                                            format="hh:mm"
                                            data-test-id="batch-popup-end-time"
                                            className="periodTimePicker"
                                            value={selectedEndTime}
                                            onChange={(e: any) => handleEndTimeChange(e)}
                                            error={selectedBatchEndTimeError}
                                            helperText={selectedBatchEndTimeErrorMsg} />
                                    </MuiPickersUtilsProvider>
                                    <div className="timePeriodBox">
                                        <Typography className="timePeriodText">{selectedEndTimeBatchLabel}</Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <div className="CreatePeriodDiv">
                    <Button
                    data-test-id="update-create-batch-btn"
                        onClick={(e: any) => {
                            addEditBatchClick()
                        }}
                        className={`NewBatchBtn ${classes.button_color}`}
                    >
                        <Typography className="AddNewBatchText">
                            {isEditBatch ? 'Update' : 'Create'}
                        </Typography>
                    </Button>
                </div>

            </div>
        </Dialog>
    );
};
// Customizable Area End
// Customizable Area Start
export default withStyles(themeCreateStyle)(AddEditBatch);
// Customizable Area End
