// Customizable Area Start
import React from 'react';
import {
  Box,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import HOCAuth from '../../../../shared/Header/src/HOCAuth.web';
import {
  birthday_bg, thoughts_bg, UPCOMING_CLASSES_NO_DATA
} from '../../assets';
import ReminderQuotesController, { Props } from "./ReminderQuotesController.web";
import ViewThoughtModalWeb from '../../ViewThoughtModal.web';
import Spinner from '../../../../shared/SideBar/src/Spinner';
import "./ReminderQuotes.web.css"
import { themeCreateStyle } from '../../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class ReminderQuotes extends ReminderQuotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      data,
      reminders,
      mainReminder,
      mainReminderOpen,
      loader
    } = this.state;
    const reminderEvents: any = reminders?.events;
    const reminderThoughts: any = reminders?.thoughts;
    const isReminderData: boolean = !!(reminderEvents?.length || reminderThoughts?.length);

    return (
      <>
       {loader && (
            <Spinner spinnerModal={loader} />
          )}
        <Grid style={{height:"fit-content"}} container direction="row">
            {isReminderData ? (
               <Grid item xs={12}>
              <Box
               className='reminder_block'
              >
                <Box className='parent_reminder-quote-outer'
                  style={{
                    display: 'flex',
                    marginTop: '23px',
                  }}
                >
                  {/* Events Reminder */}
                  {reminderEvents.map((reminderevent: any, index: number) => (
                    <Box
                      className={`reminder_event_block`}
                      style={{
                        backgroundImage: `url(${birthday_bg})`,
                      }}
                      key={index}
                    >
                      <Typography
                        style={{
                          fontSize: 17,
                          //fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "white",
                          textTransform: 'capitalize'
                        }}
                        className={this.props.classes.subHeading_font}
                      >
                        {reminderevent.attributes.name[0].toUpperCase()}{reminderevent.attributes.name.slice(1)}
                      </Typography>

                      <Typography
                        style={{
                          fontSize: 23,
                          fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "white",
                          width: 288,
                          marginTop: 23,
                        }}
                        className={this.props.classes.bodyText_font}
                      >
                        {reminderevent.attributes.user_name}
                      </Typography>

                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: "Open sans",
                          fontWeight: "bold",
                          color: "white",
                          marginTop: 10,
                        }}
                        className={this.props.classes.bodyText_font}
                      >
                        {moment(reminderevent.attributes.start_time).format('LL')}
                      </Typography>
                    </Box>
                  ))}
                  {/* Thoughts Reminder */}
                  {reminderThoughts.map((reminderthought: any, index: number) => {
                    return (
                      <Box className='parent_reminder-quote-outer reminder_thought_block'
                        style={{
                          backgroundImage: `url(${thoughts_bg})`,
                        }}
                        key={index}
                      >
                        <Typography
                          style={{
                            fontSize: 19,
                            fontFamily: "Open sans",
                            fontWeight: "bold",
                            color: "white",
                          }}
                          className={this.props.classes.subHeading_font}

                        >
                          Thought for the day
                        </Typography>

                        <Typography
                          style={{
                            fontFamily: "Open sans",
                            color: "white",
                            marginTop: 20,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            height: 93,
                            display: '-webkit-box',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            this.setState({ mainReminder: reminderthought, mainReminderOpen: true })
                          }}
                          className={this.props.classes.bodyText_font}
                          dangerouslySetInnerHTML={{ __html: reminderthought.attributes.thought_title }}
                        />
                      </Box>
                    )
                  })}
                </Box>
              </Box>
              </Grid>
            ) : 
            <Grid item xs={12}>
              <Grid container direction="row">
              <Grid item xs={12}
               className='no_reminder_img_block justify_center'
              >
                <img
                  className='img_object_fit'
                  src={UPCOMING_CLASSES_NO_DATA}
                  alt="No data found"
                />
              </Grid>
              <Grid item xs={12}
              className="justify_center"
              >
                <span
                className={`${this.props.classes.heading_font} no_content_text`}
                >
                  No Reminder & quotes Found!
                </span>
              </Grid>
                </Grid>
            </Grid>
            }
        </Grid>
        {mainReminder &&
          <ViewThoughtModalWeb
            classes={this.props.classes}
            mainReminder={mainReminder}
            mainReminderOpen={mainReminderOpen} modalhandleClose={this.modalhandleClose}
          />
        }
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(ReminderQuotes, 'TeacherAccount')
);
  // Customizable Area End
