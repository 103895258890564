// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { HISTORY } from "../../../components/src/comman";
import { v4 as uuidv4 } from "uuid";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  preGuidelineList: any;
  customGuidelineList: any;
  showLoader: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherAssessmentCreateGuidelineController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAdminGuidanceList: string = "";
  apiCreateGuidelineList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    let customGuidelineData = [];

    customGuidelineData.push({
      id: uuidv4(),
      description: "",
    });


    this.state = {
      preGuidelineList: [],
      customGuidelineList: customGuidelineData,
      showLoader: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetAdminGuidanceList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ preGuidelineList: responseJson.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
  // Customizable Area Start
    super.componentDidMount();

    // local guidelines
    const assessmentData = localStorage.getItem("assessmentData");
    if (assessmentData !== null) {
      const data = JSON.parse(assessmentData || "{}");
      this.setState({
        customGuidelineList: data?.assessment_guidelines ? data?.assessment_guidelines : []
      })
    } else {
      // redirect back to create assessment
      HISTORY.push({
        pathname: "/TeacherAssessmentCreateDetail"
      });
      // redirect back to create assessment END
    }
    // local guidelines END

    this.getPreGuidelineList();
    // Customizable Area End
  }

  // Customizable Area Start
  createDetailUrl = () => {
    HISTORY.push({
      pathname: "/TeacherAssessmentCreateTheme",
    });
  };

  handleBack = () => {
    // redirect back to create assessment
    HISTORY.push({
      pathname: "/TeacherAssessmentCreateDetail"
    });
    // redirect back to create assessment END
  };

  handleAddGuideline = () => {
    this.setState((preState) => ({
      ...preState,
      customGuidelineList: [
        ...this.state.customGuidelineList,
        {
          id: uuidv4(),
          description: "",

        },
      ],
    }));
  };

  handleUpdateGuideline = (index: number, e: any) => {
    const data = [...this.state.customGuidelineList];
    const name = "description";
    const value = e.target.value;
    data[index][name] = value;
    this.setState((preState) => ({
      ...preState,
      customGuidelineList: data,
    }));
  };

  handleDeleteGuideline = (guidelineId: string) => {
    let deletedGuidelines: any = [];
    const filterGuidelines = this.state.customGuidelineList.filter(
      (guideline: any) => {
        if (guideline.id !== guidelineId) {
          return guideline;
        } else {
          if (guideline?.guideline_id) {
            deletedGuidelines.push({
              id: guideline?.guideline_id,
              _destroy: true
            })
          }
        }
      }
    );
    // Edit mode
    if (deletedGuidelines.length > 0) {
      const assessmentData = JSON.parse(localStorage.getItem('assessmentData') || "{}");
      assessmentData.guideline_to_destroy = deletedGuidelines;
      localStorage.setItem("assessmentData", JSON.stringify(assessmentData));
    }
    // Edit mode END

    this.setState((preState) => ({
      ...preState,
      customGuidelineList: filterGuidelines,
    }));
  };

  onNext = async () => {
    if (this.validateForm()) {
      this.saveGuidelinesAndContine();
    }
  };

  validateForm = () => {
    let isValid = true;
    const { customGuidelineList } = this.state;

    let validatedCustomGuideline = customGuidelineList.map((data: any) => {
      if (data.description !== '') {
        data.error = false
      } else {
        data.error = true;
        isValid = false;
      }
      return data;
    })
    this.setState({
      customGuidelineList: validatedCustomGuideline
    });

    return isValid;
  };

  getPreGuidelineList = () => {
    const token = localStorage.getItem("token");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    let grade_id = HISTORY.location.state?.grade_id;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "school": data.school_id,
      token,
    };
    this.setState({ showLoader: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAdminGuidanceList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAdminGuidelinesEndPoint + `?grade_id=${grade_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  saveGuidelinesAndContine = () => {
    const assessmentData = localStorage.getItem("assessmentData");
    const data = JSON.parse(assessmentData || "{}");
    data.assessment_guidelines = this.state.customGuidelineList;
    localStorage.setItem("assessmentData", JSON.stringify(data))
    this.createDetailUrl();
  }

  // Customizable Area End
}
