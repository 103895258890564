// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reportCardList: any;
  reportCardListMeta: any;
  queryTerm: any;
  page: number;
  rowsPerPage: number;
  showLoader: boolean;
  isMenuOpen: boolean;
  menuAnchorEl: any;
  selectedReportCardId: any;
  openDeleteWarningModal: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AdminReportCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetReportCardList: string = "";
  apiDeleteReportCard: string = "";
  apiGetEditReportCardData: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      reportCardList: [],
      reportCardListMeta: [],
      queryTerm: "",
      page: 0,
      rowsPerPage: 10,
      showLoader: false,
      isMenuOpen: false,
      menuAnchorEl: '',
      selectedReportCardId: '',
      openDeleteWarningModal: false,
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  handleBack = () => {
    HISTORY.goBack();
  };
  createCustomCardClick = () => {
    HISTORY.push('/AddCustomReportCard');
  }

  createEvaluationBtnClick = () => {
    HISTORY.push('/CreateEvaluationDetails')
  }

  draftReportCardClick = () => {
    HISTORY.push('/DraftReportCard');
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getReportCards();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apiGetReportCardList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              reportCardList: responseJson?.data,
              reportCardListMeta: responseJson?.meta
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiDeleteReportCard) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ showLoader: false, isMenuOpen: false, menuAnchorEl: '', selectedReportCardId: '' }, () => this.getReportCards())
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetEditReportCardData) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ showLoader: false, isMenuOpen: false, menuAnchorEl: '', selectedReportCardId: '' },
              () => {
                HISTORY.push({
                  pathname: "/AddCustomReportCard",
                  state: {
                    reportCardData: responseJson.data,
                    isEdit: true,
                  },
                });
              })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }


  getReportCards = () => {
    this.setState({
      showLoader: true
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetReportCardList = requestMessage.messageId;

    let apiEndPoint = configJSON.apiGetReportCardEndPoing + `?report_status=published&page=${this.state.page + 1}&per=${this.state.rowsPerPage}`

    this.setState({ showLoader: true })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createTemplateCardClick = () => {
    HISTORY.push('/AdminReportCardTemplate');
  }
  getEditReportCardData = () => {
    const { selectedReportCardId } = this.state;
    if (selectedReportCardId === '') {
      return false
    }
    this.setState({
      showLoader: true
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetEditReportCardData = requestMessage.messageId;

    let apiEndPoint = configJSON.apiPublishReportCardEndPoint + `/${selectedReportCardId}`

    this.setState({ showLoader: true })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  deleteReportCard = () => {
    const { selectedReportCardId } = this.state;
    if (selectedReportCardId === '') {
      return false
    }
    this.setState({
      showLoader: true,
      openDeleteWarningModal: false
    })
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteReportCard = requestMessage.messageId;

    let apiEndPoint = configJSON.apiPublishReportCardEndPoint + `/${selectedReportCardId}`

    this.setState({ showLoader: true })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  // Customizable Area End
}
