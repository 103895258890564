// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import moment from 'moment';
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    addDialog: any;
    handleDialogClose: any;
    id?: any;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    data: any;
    pagination_page: number;
    timeLineMeta: any;
    batchData: any;
    showLoader: boolean;
    openAddEditBatch: boolean;
    batchName: any;
    batchNameError: boolean;
    batchNameErrorMsg: any;
    selectedStartTime: any;
    selectedEndTime: any;
    selectedStartTimeBatchLabel: string;
    selectedEndTimeBatchLabel: string;
    isEditBatch: boolean;
    selectedBatch: any;
    selectedBatchEndTimeError: boolean;
    selectedBatchEndTimeErrorMsg: string;
    openDeleteBatchModal: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start
const today = new Date();
// Customizable Area End

export default class ViewBatchController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getBatchListId: string = "";
    postBatchId: string = "";
    deleteBatchId: string = "";
    child: React.RefObject<any>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.child = React.createRef();
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            data: '',
            pagination_page: 0,
            timeLineMeta: [],
            batchData: [],
            showLoader: false,
            openAddEditBatch: false,
            batchName: "",
            batchNameError: false,
            batchNameErrorMsg: "",
            selectedStartTime: today,
            selectedEndTime: today,
            selectedStartTimeBatchLabel: moment(today).format("A"),
            selectedEndTimeBatchLabel: moment(today).format("A"),
            isEditBatch: false,
            selectedBatch: null,
            selectedBatchEndTimeError: false,
            selectedBatchEndTimeErrorMsg: "",
            openDeleteBatchModal: false,
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.userdata = this.userdata.bind(this);
        this.get_batch_list = this.get_batch_list.bind(this);
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.get_batch_list();
        // Customizable Area End
    }

    // Customizable Area Start
    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.getBatchListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        const formattedBatchData = responseJson?.data?.map((item: any) => {
                            item.attributes.start_time = moment(item.attributes.start_time).format("hh:mm A");
                            item.attributes.end_time = moment(item.attributes.end_time).format("hh:mm A");

                            return item;
                        })
                        this.setState({ batchData: formattedBatchData, timeLineMeta: responseJson.meta, showLoader: false })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorResponse);
            } else if (apiRequestCallId === this.postBatchId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ openAddEditBatch: false, showLoader: false })
                        this.get_batch_list()
                    } else {
                        const batchError = responseJson?.errors[0]?.batch;
                        const nameError = responseJson?.errors[0]?.name;
                        if (batchError === 'is already scheduled for this time durations') {
                            this.setState({
                                selectedBatchEndTimeError: true,
                                selectedBatchEndTimeErrorMsg: "Batch is already scheduled for this time durations.",
                                showLoader: false
                            });
                        } else if (nameError === 'has already been taken') {
                            this.setState({
                                batchNameError: true,
                                batchNameErrorMsg: "Batch name has already been taken.",
                                showLoader: false
                            });
                        } else {
                            this.setState({
                                selectedBatchEndTimeError: true,
                                selectedBatchEndTimeErrorMsg: "Something went wrong.",
                                showLoader: false
                            });
                        }
                    }
                }
                this.parseApiCatchErrorResponse(errorResponse);
            } else if (apiRequestCallId === this.deleteBatchId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ openDeleteBatchModal: false, showLoader: false, selectedBatch: null })
                        this.get_batch_list()
                    } else {
                        const key: any = Object.keys(responseJson.errors[0])
                        toast.error(`${responseJson.errors[0][key]}`)
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorResponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleOnChangeBatchName = (event:any) => {
        const value = event.target.value.trim();
        if (value.length === 0) {
            this.setState({
                batchNameError: true,
                batchNameErrorMsg: "Batch name is required.",
            });
        } else {
            this.setState({
                batchNameError: false,
                batchNameErrorMsg: "",
            });
        }

        // if (event.target.value !== "") {
        this.setState({ batchName: event.target.value });
        // }
    }

    handleOnBlurBatchName = () => {
        if (
            this.state.batchName === null ||
            this.state.batchName.length === 0
        ) {
            this.setState({
                batchNameError: true,
                batchNameErrorMsg: "Batch name is required.",
            });
        } else {
            this.setState({
                batchNameError: false,
                batchNameErrorMsg: "",
            });
        }
    }

    handleCloseDeleteBatchModal = () => {
        this.setState({ openDeleteBatchModal: false, selectedBatch: null });
    }

    get_batch_list = () => {
        this.setState({ showLoader: true });
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.dashboardContentType,

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBatchListId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.batchesEndPoint + `?per=1000&school_id=${school_Data.school_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboardApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleStartTimeChange = (date: any) => {
        this.setState({ selectedStartTime: date, selectedStartTimeBatchLabel: moment(date).format("A") })
        this.handleValidTime(date, this.state.selectedEndTime)
    };

    handleEndTimeChange = (date: any) => {
        this.setState({ selectedEndTime: date, selectedEndTimeBatchLabel: moment(date).format("A") })
        this.handleValidTime(this.state.selectedStartTime, date)
    };

    handleValidTime = (startTime: any, endTime: any) => {
        const startTimeValid = moment(startTime, "LT", true);
        const endTimeValid = moment(endTime, "LT", true);
        if (!startTimeValid.isBefore(endTimeValid)) {
            this.setState({
                selectedBatchEndTimeError: true,
                selectedBatchEndTimeErrorMsg: "End time should be greater than start time.",
            });
        } else {
            this.setState({
                selectedBatchEndTimeError: false,
                selectedBatchEndTimeErrorMsg: "",
            });
        }
    }

    handleGetStartAndEndTime = (time: string) => {
        const dateObj = new Date();
        const month = dateObj.getUTCMonth(); //index of month
        const day = dateObj.getDate();
        const year = dateObj.getUTCFullYear();
        const formattedTime = moment(time, ["h:mm A"]).format("HH:mm A");
        const formattedTimeHours = parseInt(formattedTime.split(":")[0]);
        const formattedTimeMins = parseInt(formattedTime.split(":")[1]);
        return new Date(year, month, day, formattedTimeHours, formattedTimeMins);
    };

    handleGetStartAndEndTimeLabel = (time: string) => {
        const date = this.handleGetStartAndEndTime(time)
        return moment(date).format("A")
    }

    isValidData = () => {
        let isValid = true;
        const value = this.state.batchName.trim();
        const startTimeValid = moment(this.state.selectedStartTime, "LT", true);
        const endTimeValid = moment(this.state.selectedEndTime, "LT", true);
        if (value.length === 0) {
            this.setState({
                batchNameError: true,
                batchNameErrorMsg: "Batch name is required.",
            });
            isValid = false;
        }
        if (!startTimeValid.isBefore(endTimeValid)) {
            this.setState({
                selectedBatchEndTimeError: true,
                selectedBatchEndTimeErrorMsg: "End time should be greater than start time.",
            });
            isValid = false;
        }
        return isValid;
    }

    add_edit_batch = () => {
        if (this.isValidData()) {
            this.setState({
                batchNameError: false,
                batchNameErrorMsg: "",
                selectedBatchEndTimeError: false,
                selectedBatchEndTimeErrorMsg: "",
            });
            this.setState({ showLoader: true });
            const user_data = localStorage.getItem('user_data');
            const school_Data = JSON.parse(user_data || '{}')
            const header = {
                "Content-Type": configJSON.dashboardContentType,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.postBatchId = requestMessage.messageId;

            const { batchName, selectedStartTime, selectedEndTime, selectedBatch, isEditBatch } = this.state

            const httpBody = {
                name: batchName.trim(),
                start_time: moment(selectedStartTime).format(),
                end_time: moment(selectedEndTime).format(),
                school_id: school_Data?.school_id,
            };

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                isEditBatch ? configJSON.batchesEndPoint + `/${selectedBatch.id}` : configJSON.batchesEndPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestbaseURLMessage),
                configJSON.instituteURL
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                isEditBatch ? configJSON.dashboardApiPutMethodType : configJSON.dashboardApiPostMethodType
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    handleAddEditBatchDialogClose = (closeModal: boolean) => {
        this.setState({
            openAddEditBatch: closeModal,
            selectedBatch: null,
            batchNameError: false,
            batchNameErrorMsg: "",
            selectedBatchEndTimeError: false,
            selectedBatchEndTimeErrorMsg: ""
        })
    }

    delete_batch = () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteBatchId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.batchesEndPoint + `/${this.state.selectedBatch?.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteSchoolMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}