// Customizable Area Start
import React from "react";
import {
    Grid,
    Modal,
    Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import './CreateCustomReportCard.web.css';
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const deleteDiv = {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: 'center',
    marginBottom: '14px',
} as const;
const deleteMes1 = {
    fontSize: "24px",
    fontWeight: 500,
    textAlign: 'center',
    margin: '31px 32px 6px 33px',
} as const;
const deleteMes2 = {
    fontSize: "14px",
    fontWeight: 500,
    textAlign: 'center',
    margin: '6px 53px 40px 54px',
} as const;
const cancelText = {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
    textAlign: 'center',
} as const;
const deleteText = {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
    textAlign: 'center'
} as const;
const cancelButton = {
    margin: '0 20px 0 0',
    padding: '9px 20px 12px 22px',
    borderRadius: '3px',
    textTransform: 'initial',
} as const;
const deleteButton = {
    margin: '0 0px 0 20px',
    padding: '9px 20px 12px 22px',
    borderRadius: '3px',
    textTransform: 'initial',
} as const;
// Customizable Area End

interface Props {
    // Customizable Area Start
    deleteModal: boolean;
    onCloseModal: any;
    deleteClick: any;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
const DeleteGradeModal = ({
    deleteModal,
    onCloseModal,
    deleteClick,
    classes,
}: Props) => {
    return (
        <>
            <Modal
                open={deleteModal}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid className="DeleteGradeModal">
                    <div style={deleteDiv} className={classes.heading_font}>Delete</div>
                    <div className="DeleteGradeModalDivider"></div>
                    <div style={deleteMes1} className={classes.heading_font}>Are you sure you want to delete the item?</div>
                    <div style={deleteMes2} className={classes.subHeading_font}>This item will be deleted immediately. You cannot undo the action.</div>
                    <Grid className="DeleteGradeActionDiv">
                        <Button variant="contained" style={cancelButton} className={classes.button_color} onClick={onCloseModal}><span style={cancelText}>Cancel</span></Button>
                        <Button variant="contained" style={deleteButton} className={classes.DeleteGradeModal_Feedback_normal_button_color} onClick={deleteClick}><span style={deleteText}>Delete</span></Button>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
};
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(DeleteGradeModal);
// Customizable Area End