// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End


export interface Props {
    // Customizable Area Start
    navigation: any;
    deleteAlertModal: boolean;
    closeModalFun: any;
    id: any
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    data: any;
    closeModal: boolean;
    sucessFullModal: Boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class DeleteAlertModalController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    schoolDeleteId: string = "";
    child: React.RefObject<any>;
    // Customizable Area End

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.child = React.createRef();
        console.log(this);
        console.log(this.child, "state")
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            data: '',
            closeModal: false,
            sucessFullModal: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.userdata = this.userdata.bind(this);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    modalhandleClose = () => {
        this.setState({ closeModal: true })
        console.log("modal closed", this.state.closeModal)
        this.props.closeModalFun(this.state.closeModal)
    }
    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaa", data);
        this.setState({ data: data });
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.schoolDeleteId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        this.setState({ sucessFullModal: true })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }


        }
    }

    delete_class = () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.schoolDeleteId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.schoolClassEndPoint + `/${this.props.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteSchoolMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
