// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import moment from "moment";
import { HISTORY } from "../../../../components/src/comman";

export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
    navigation: any;
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    data: any;
    recordingsList: any;
    selectedRecording: any;
    selectedDate: any;
    showSpinner: boolean;
    isFirstTimeRecording: boolean;
    changedDate: boolean;
    multipleRecordingList: any;
    selectedRecordingTitle: string;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class LiveClassRecordingsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetRecordingList: string = "";
    apiGetRecordingDetails: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            token: "",
            data: "",
            recordingsList: [],
            selectedRecording: "",
            selectedDate: new Date(),
            showSpinner: false,
            isFirstTimeRecording: false,
            changedDate: false,
            multipleRecordingList: [],
            selectedRecordingTitle: "Recording 1",
        };

        runEngine.attachBuildingBlock(
            this as IBlock,
            this.subScribedMessages
        );
        this.get_userdata = this.get_userdata.bind(this);
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        if (HISTORY.location.state?.recordingId) {
            this.setState({ isFirstTimeRecording: true, selectedDate: moment(HISTORY.location.state?.recordingDate) }, () => this.getRecordingList());
        } else {
            this.getRecordingList();
        }
        window.history.replaceState(HISTORY.location.pathname, "");
        // Customizable Area End
    }

    // Customizable Area Start
    get_userdata = () => {
        const user_data = localStorage.getItem("user_data");
        const data = JSON.parse(user_data || "{}");
        this.setState({ data: data });
    };

    onChangeRecording = (meeting: any) => {
        this.setState({ selectedRecording: meeting });
        this.getRecordingDetails(Number(meeting.id))
    };
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id
        ) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.apiGetRecordingList) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ recordingsList: responseJson?.data }, () => {
                            if (this.state.isFirstTimeRecording && responseJson?.data?.length > 0) {
                                const matchedRecordingData = responseJson?.data?.filter(
                                    (item: any) => item?.attributes?.recordings?.data.some((m: any) => Number(m.id) === Number(HISTORY.location.state?.recordingId))
                                );
                                if (matchedRecordingData.length > 0) {
                                    this.getRecordingDetails(Number(matchedRecordingData?.[0]?.id))
                                } else if (HISTORY.location.state?.meetingId) {
                                    this.getRecordingDetails(Number(HISTORY.location.state?.meetingId))
                                } else {
                                    this.setState({ selectedRecording: "", multipleRecordingList: [] })
                                }
                            } else {
                                if (responseJson?.data?.length > 0) {
                                    this.getRecordingDetails(Number(responseJson?.data?.[0]?.id))
                                } else if (HISTORY.location.state?.meetingId && !this.state.changedDate) {
                                    this.getRecordingDetails(Number(HISTORY.location.state?.meetingId))
                                } else {
                                    this.setState({ selectedRecording: "", multipleRecordingList: [] })
                                }
                            }
                        })

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showSpinner: false, isFirstTimeRecording: false });
                this.parseApiCatchErrorResponse(errorReponse);
            } else if (apiRequestCallId === this.apiGetRecordingDetails) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ selectedRecording: responseJson?.data?.[0], multipleRecordingList: responseJson?.data, selectedRecordingTitle: "Recording 1" })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showSpinner: false });
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getRecordingList = () => {
        this.setState({ showSpinner: true });
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const { selectedDate } = this.state;
        const parsedDate = moment(selectedDate).format("DD/MM/YYYY");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("token"),
            school: school_Data?.school_id
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetRecordingList = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getRecordingList + `?start_date=${parsedDate}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getRecordingDetails = (meetingId: number) => {
        this.setState({ showSpinner: true });
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const { selectedDate } = this.state;
        const parsedDate = moment(selectedDate).format("YYYY-MM-DD");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("token"),
            school: school_Data?.school_id
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetRecordingDetails = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getRecordingList + `/${meetingId}/recordings?selected_date=${parsedDate}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}
