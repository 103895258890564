// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    InputBase,
    Typography,
    Checkbox,
    Popover,
} from "@material-ui/core";

import {
    Search,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import DivisionSelectDivController, { Props } from "./DivisionSelectDivController.web";
import 'react-calendar/dist/Calendar.css';
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
import './CommanSelectDiv.web.css';
// Customizable Area End
// Customizable Area Start
// Customizable Area End

export class DivisionSelectDiv extends DivisionSelectDivController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    
    // Customizable Area Start
    render() {
        const { responseData, searchData } = this.state
        return (
            <>
                <Popover
                    id="1"
                    anchorEl={this.props.divisiondropDownAnchorEl}
                    open={Boolean(this.props.divisiondropDownAnchorEl)}
                    onClose={this.modalhandleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    className="common_select_div_popover"
                    PaperProps={{
                        style: {
                            width: '39.7%',
                            maxHeight: '70%',
                            padding: "20px 25px 30px 25px",
                            borderRadius: 8,
                            boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.2)",
                            backgroundColor: "#fff"
                        },
                    }}
                >
                    <Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            width="100%"
                            className={`${this.props.classes.border_Color} search_container`}
                        >
                            <Search
                                className={`${this.props.classes.icon_color} search`}
                            />
                            <input
                                placeholder="Search division"
                                className="search_input"
                                onChange={
                                    ((event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ queryTerm: event.target.value.trim() })

                                        const filtered = responseData?.filter((member: any) => {
                                            const memberName = ` ${member.name}`
                                            if (event.target.value === '') {
                                                return member;
                                            }
                                            else if (member.attributes && (member.attributes.name && member.attributes.name.toLowerCase().includes(event.target.value.trim().toLowerCase()))) {
                                                return member;
                                            }
                                        })

                                        this.setState({ searchData: filtered })

                                    })}
                            />
                        </Box>
                        <Box className="container">
                            {
                                (this.state.queryTerm === '' ? responseData : searchData).length === 0
                                    ?
                                    <>
                                        <Typography className="no_record_found">
                                            No record(s) found.
                                        </Typography>
                                    </>
                                    :
                                    (this.state.queryTerm === '' ? responseData : searchData).map((data: any, index: any) => (
                                        <Box
                                            className="data_list"
                                            onClick={() => {
                                                let tempresponseData = responseData
                                                if (this.state.queryTerm === '') {
                                                    tempresponseData[index].checked = !tempresponseData[index].checked
                                                    this.setState({ responseData: tempresponseData })
                                                }
                                                else {
                                                    const findIndex = responseData.findIndex((res: any) => res.id === data.id)
                                                    tempresponseData[findIndex].checked = !tempresponseData[findIndex].checked
                                                    this.setState({ responseData: tempresponseData })
                                                }

                                            }}
                                        >
                                            <Typography
                                                className={`${this.props.classes.bodyText_font} data_list_title`}>
                                                {data.attributes.name}
                                            </Typography>
                                            <Box
                                                onClick={(event: any) => {
                                                    event.stopPropagation();
                                                    let tempresponseData = responseData
                                                    if (this.state.queryTerm === '') {
                                                        tempresponseData[index].checked = !tempresponseData[index].checked
                                                        this.setState({ responseData: tempresponseData })
                                                    }
                                                    else {
                                                        const findIndex = responseData.findIndex((res: any) => res.id === data.id)
                                                        tempresponseData[findIndex].checked = !tempresponseData[findIndex].checked
                                                        this.setState({ responseData: tempresponseData })
                                                    }

                                                }}
                                            >
                                                <Checkbox
                                                    checked={data.checked ? data.checked : false}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    className={`${this.props.classes.icon_color} transparent_bg`}
                                                />
                                            </Box>
                                        </Box>
                                    ))}
                        </Box>
                        <Box
                           className="btn_container"
                        >
                            <Button
                                onClick={this.datasavemodalClose}
                                className={`${this.props.classes.button_color} save_btn`}
                            >
                                Save
                            </Button>
                            <Button
                                className={`${this.props.classes.divisionSelectDiv_classAndSection_button_color_disabled} cancel_btn`}
                                onClick={this.modalhandleClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Popover>
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(DivisionSelectDiv);

// Customizable Area End
