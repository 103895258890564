// Customizable Area Start
import { InputBase, withStyles } from "@material-ui/core";

export const ClassSelect = withStyles((theme: any) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
        "& .MuiNativeSelect-icon": {
            width: 42,
            height: 42,
            top: 5
        }
    },
    input: {
        borderRadius: 6,
        position: "relative",
        backgroundColor: "#f9f8fd",
        border: '1px solid #f9f8fd',
        padding: "12px",
        width: "390px",
        height: "30px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        fontSize: 22,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        [theme.breakpoints.between('sm', 'md')]: {
            width: "250px",
        },
        [theme.breakpoints.down('sm')]: {
            width: "180px",
        },
    },
}))(InputBase);

// Customizable Area End