// Customizable Area Start
import React from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { img21kSchholLogo1, imgloginScreenBackground } from './assets';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const loginImageDiv = {
  position: 'relative',
  height: '100%',
} as const;

const halfcircleImage = {
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(142, 123, 253, 1)',
  position: 'absolute',
  zIndex: 0,
} as const;

const logoImage = {
  width: 'auto',
  height: '68px',
  position: 'relative',
  margin: '20px',
  objectFit: 'cover',
} as const;

const timelineConnector = {
  minHeight: '111px',
  width: '4px',
  backgroundColor: '#fff',
};
const timelineDot = {
  color: '#fff',
  backgroundColor: '#fff',
  margin: '0px',
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    missingOppositeContent: {
      '&:before': {
        display: 'none',
      },
    },
    timelineContent: {
      color: '#fff',
      fontSize: '22px',
      fontFamily: 'Open sans',

      padding: '0px 34px 0px 16px',
      marginTop: '-10px',
    },
  })
);
// Customizable Area End

export function OnBoardingSideBar(props: any) {
  // Customizable Area Start
  const classes = useStyles();
  const goTODetailsPage = () => {
    if (props.activePage > 1) {
      window.location.href = '/OnBoardingSchoolDeatils';
    }
  };
  const goTOLogoAndPicture = () => {
    if (props.activePage > 2) {
      window.location.href = '/LogoAndPicture';
    }
  };
  const goTOSubject = () => {
    if (props.activePage > 4) {
      window.location.href = '/Subjects';
    }
  };
  const goTOclasses = () => {
    if (props.activePage > 3) {
      window.location.href = '/GradesAndLevels';
    }
  };
  const goTOtheme = () => {
    if (props.activePage > 5) {
      window.location.href = '/ThemesAndFonts';
    }
  };

  return (
    <Grid className={classes.root}>
      <Grid style={loginImageDiv}>
        <img
          src={imgloginScreenBackground}
          style={halfcircleImage}
          alt="Background Image"
        />

        <div>
          <img src={img21kSchholLogo1} alt="Logo Image" style={logoImage} />
        </div>
        <Grid
          item
          xs={12}
          style={{ padding: '10px 0px 0px 40px', marginTop: '32px' }}
        >
          <Timeline align="left">
            <TimelineItem className={classes.missingOppositeContent}>
              <TimelineSeparator>
                <TimelineDot style={timelineDot} />
                <TimelineConnector style={timelineConnector} />
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>
                {' '}
                <Typography
                  style={{
                    fontSize: '1.375rem',
                    cursor: props.activePage > 1 ? 'pointer' : '',
                  }}
                  onClick={() => goTODetailsPage()}
                >
                  {' '}
                  School Details{' '}
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem className={classes.missingOppositeContent}>
              <TimelineSeparator>
                <TimelineDot style={timelineDot} />
                <TimelineConnector style={timelineConnector} />
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>
                {' '}
                <Typography
                  style={{
                    fontSize: '1.375rem',
                    cursor: props.activePage > 2 ? 'pointer' : '',
                  }}
                  onClick={() => goTOLogoAndPicture()}
                >
                  Logo & Pictures{' '}
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem className={classes.missingOppositeContent}>
              <TimelineSeparator>
                <TimelineDot style={timelineDot} />
                <TimelineConnector style={timelineConnector} />
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>
                <Typography
                  style={{
                    fontSize: '1.375rem',
                    cursor: props.activePage > 3 ? 'pointer' : '',
                  }}
                  onClick={() => goTOclasses()}
                >
                  Grades & Levels{' '}
                </Typography>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem className={classes.missingOppositeContent}>
              <TimelineSeparator>
                <TimelineDot style={timelineDot} />
                <TimelineConnector style={timelineConnector} />
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>
                <Typography
                  style={{
                    fontSize: '1.375rem',
                    cursor: props.activePage > 4 ? 'pointer' : '',
                  }}
                  onClick={() => goTOSubject()}
                >
                  Subjects
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem className={classes.missingOppositeContent}>
              <TimelineDot style={timelineDot} />

              <TimelineContent className={classes.timelineContent}>
                <Typography
                  style={{
                    fontSize: '1.375rem',
                    cursor: props.activePage > 5 ? 'pointer' : '',
                  }}
                  onClick={() => goTOtheme()}
                >
                  Themes{' '}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Grid>
      </Grid>
    </Grid>
  );
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(OnBoardingSideBar);
// Customizable Area End