// Customizable Area Start
import React from "react";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import Grid from '@material-ui/core/Grid';
import {
    Checkbox,
    Button,
    NativeSelect,
    TextField,
    Dialog,
    Typography,
    Divider,
    FormLabel,
    FormHelperText,
    FormControlLabel
} from "@material-ui/core";

import { withStyles } from '@material-ui/core/styles';
import AutomaticClassPeriodController, { Props } from './AutomaticClassPeriodController.web';
import moment from "moment";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { addMoreSubject, crossButton } from "../assets";
import MesDialog from "./MesDialog.web";
import './AutomaticClassPeriod.web.css';
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
    },
    input: {
        borderRadius: "8.8px",
        position: "relative",
        color: "#3f526d",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        width: "95%",
        lineHeight: 1.2,
        backgroundColor: "#f6f6f6",
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
            padding: '18.5px 14px',

        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 12.5,
            padding: '14.5px 12px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: 15.5,
            padding: '17px 13.5px',
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 13.5,
            padding: '16px 13px',
        },
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: "8.8px",
            borderColor: "#cfcfcf",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase);

const styles = (theme: any) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
    },
    textFontFamily: {
        fontFamily: "Open sans",
    },
    textFiledCss: {
        fontFamily: "Open sans",
        color: "#3f526d",
        lineHeight: 1,

        [theme.breakpoints.up('md')]: {
            fontSize: 16,
            padding: '18.5px 14px',
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 12.5,
            padding: '14.5px 12px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize: 15.5,
            padding: '17px 13.5px',
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 13.5,
            padding: '16px 13px',
        },

    },
    forrmInput_textfield: {
        width: "100%",
        borderRadius: "6px",
        color: "#3f526d",

        fontFamily: "Open sans",
        [theme.breakpoints.up('md')]: {
            fontSize: 16,

        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 12.5,

        },
        [theme.breakpoints.only('md')]: {
            fontSize: 15.5,

        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 13.5,

        },

    },
    helperTextCss: {
        color: "#3f526d",
        fontWeight: 600,
        fontFamily: 'Open sans',
        [theme.breakpoints.up('md')]: {
            fontSize: 18,

        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 14.5,

        },
        [theme.breakpoints.only('md')]: {
            fontSize: 17.5,

        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 15.5,

        },
    },
    mobileView: {
        [theme.breakpoints.up('xs')]: {
            visibility: "hidden",
            width: 18,
            height: 18,

        },
        [theme.breakpoints.only('xs')]: {
            width: 15,
            height: 15,
            visibility: 'visible'

        },
    },
    laptopView: {
        [theme.breakpoints.only('xs')]: {
            visibility: "hidden",
            width: 15,
            height: 15,

        },
        [theme.breakpoints.between('sm', 'xl')]: {
            width: 18,
            height: 18,
            visibility: 'visible'

        },
    },
    forrmInput_online_field: {
        backgroundColor: "#f9f6f6",
        marginLeft: 0,
        width: "100%",
        marginBottom: 0,
       
        paddingLeft: 10,
       

       
        [theme.breakpoints.up('md')]: {
            height: 56,
            borderRadius: '8.8px',

        },
        [theme.breakpoints.only('xs')]: {
            height: 43.84,
            borderRadius: '6px',

        },
        [theme.breakpoints.only('md')]: {
            height: 52.41,
            borderRadius: '6px',

        },
        [theme.breakpoints.only('sm')]: {
            height: 48.03,
            borderRadius: '6px',
        },

    },
})

const forrmInput_textfield = {

    textAlign: "start",

} as const;
// Customizable Area End

export class AutomaticClassPeriod extends AutomaticClassPeriodController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    render() {
        const numReg = /^[0-9]*$/;
        const today = moment();
        const disableFutureDt = (current: any) => {
            return current.isBefore(today);
        };
        const { classes } = this.props
        return (
            <Dialog
                PaperProps={{
                    style: { borderRadius: 8 }
                }}
                aria-labelledby="customized-dialog-title"
                maxWidth="md"
                open={this.props.createPeriodDialog}
                onClose={() => this.props.handleDialogClose()}
                closeAfterTransition
                fullWidth={true}
                disableAutoFocus={true}
                className="AutomaticClassPeriodDialogBox"
            >
                {this.state.loadingSpinner && <Spinner spinnerModal={this.state.loadingSpinner} />}

                <Grid item xs={12} className="innerBox">
                    <Grid item xs={12} className="mb10">
                        <FormLabel
                            className={`${classes.textFontFamily} addDetailHeading`}
                        >
                            Add Details
                        </FormLabel>
                    </Grid>
                    <Grid container spacing={3}>

                        <Grid item sm={6} xs={12} >
                            <Grid item xs={12} className="mb10">
                                <FormLabel
                                    className={classes.helperTextCss}

                                >
                                    Batch
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl className={classes.forrmInput_textfield} style={forrmInput_textfield}>
                                    <NativeSelect
                                        id="select"
                                        value={this.state.batch}
                                        onChange={(
                                            event: React.ChangeEvent<{ value: unknown }>
                                        ) => {
                                            this.handleSelectBatchChange(event)
                                        }}
                                        input={<BootstrapInput />}
                                        onBlur={() => {
                                            this.handleOnBlurBatchChange()
                                        }}
                                    >
                                        <option value="" disabled>Select Batch</option>
                                        {
                                            this.state.batchList.length && this.state.batchList.map((batch: any) => (
                                                <option value={batch.id} >{batch.attributes?.name}</option>
                                            ))
                                        }
                                    </NativeSelect>
                                    {this.state.batcherror && (
                                        <FormHelperText
                                            className="errorText"
                                        >
                                            Please select batch.
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} >
                            <Grid item xs={12} className="mb10">
                                <FormLabel
                                    className={classes.helperTextCss}
                                >
                                    Class
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl className={classes.forrmInput_textfield} style={forrmInput_textfield}>
                                    <NativeSelect
                                        id="select"
                                        value={this.state.class}
                                        onChange={(
                                            event: React.ChangeEvent<{ value: unknown }>
                                        ) => {
                                            if (event.target.value !== "") {
                                                this.setState({ classerror: false });
                                            }
                                            this.setState({
                                                class: event.target.value,
                                                subjectDetails: [{
                                                    subject: '',
                                                    subjecterror: false,
                                                    lengthh: '',
                                                    lengtherror: false,
                                                    priority: '',
                                                    priorityerror: false,
                                                    perDay: '',
                                                    perDayerror: false
                                                }]
                                            });
                                            this.get_subject(event.target.value)
                                        }}
                                        input={<BootstrapInput />}
                                        onBlur={() => {
                                            if (
                                                this.state.class === "" ||
                                                this.state.class.length === 0
                                            ) {
                                                this.setState({ classerror: true });
                                            } else if (this.state.class.length != 0) {
                                                this.setState({ classerror: false });
                                            } else {
                                                this.setState({ classerror: false });
                                            }
                                        }}
                                    >
                                        <option value="" disabled>Select Class</option>
                                        {
                                            this.state.classList.length && this.state.classList.map((classItem: any) => (
                                                <option value={classItem.id} >{classItem.attributes?.display_name}</option>
                                            ))
                                        }
                                    </NativeSelect>
                                    {this.state.classerror && (
                                        <FormHelperText
                                            className="errorText"
                                        >
                                            Please select class.
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>


                        {this.state.subjectDetails.map((_data: any, index: any) => {
                            return (<>

                                <Grid item sm={4} xs={12} >
                                    <Grid item xs={12} className="flexBetween mb10">
                                        <FormLabel
                                            className={classes.helperTextCss}
                                        >
                                            Subject
                                        </FormLabel>
                                        {index !== 0 && (<img src={crossButton} className={classes.mobileView}
                                            onClick={() => {
                                                this.handleDeleteSubject(index)
                                            }} />)}
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormControl className={classes.forrmInput_textfield} style={forrmInput_textfield}>
                                            <NativeSelect
                                                id="select"
                                                value={_data.subject}
                                                onChange={(
                                                    event: React.ChangeEvent<{ value: unknown }>
                                                ) => {
                                                    this.handleSelectSubjectChange(event, index)
                                                }}

                                                input={<BootstrapInput />}
                                                onBlur={() => {
                                                    this.handleOnBlurSubjectChange(_data, index);

                                                }}
                                            >
                                                <option value="" disabled>Select Subject</option>
                                                {
                                                    this.state.subjectList.length && this.state.subjectList
                                                        .filter((_post: any) => {
                                                            if (this.state.selectedSubectId.indexOf(_post.id) === -1 &&
                                                                this.state.selectedSubectId.indexOf(parseInt(_post.id)) === -1 ||
                                                                _data.subject == _post.id
                                                            ) {
                                                                return _post
                                                            }
                                                        })
                                                        .map((subjectItem: any) => (
                                                            <option value={subjectItem.id} >{subjectItem.attributes?.subject_name}</option>
                                                        ))
                                                }

                                            </NativeSelect>
                                            {_data.subjecterror && (
                                                <FormHelperText
                                                    className="errorText"
                                                >
                                                    Please select subject.
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item sm={4} xs={12} >
                                    <Grid item xs={12} className="mb10">
                                        <FormLabel
                                            className={classes.helperTextCss}
                                        >
                                            Length
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            id="standard-basic"
                                            placeholder="Enter length"
                                            className={`${classes.forrmInput_textfield} textFieldStyles ${classes.textFontFamily}`} style={forrmInput_textfield}

                                            variant="outlined"
                                            value={_data.lengthh}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleSelectLengthhChange(event, index)
                                            }}
                                            onBlur={() => {
                                                this.handleOnBlurLengthhChange(_data, index)
                                            }}

                                            error={_data.lengtherror}
                                            helperText={_data.lengtherror && "Length is required."}
                                            inputProps={{ className: classes.textFiledCss }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item sm={4} xs={12} >
                                    <Grid item xs={12} className="mb10 flexBetween">
                                        <FormLabel
                                            className={classes.helperTextCss}
                                        >
                                            Priority
                                        </FormLabel>
                                        {index !== 0 && <img src={crossButton} className={classes.laptopView}
                                            onClick={() => {
                                                let classData = this.state.subjectDetails;
                                                classData.splice(index, 1)
                                                this.setState({ subjectDetails: classData })
                                            }} />}
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormControl className={classes.forrmInput_textfield} style={forrmInput_textfield}>
                                            <NativeSelect
                                                id="select"
                                                value={_data.priority}
                                                onChange={(
                                                    event: React.ChangeEvent<{ value: unknown }>
                                                ) => {
                                                    this.handleSelectPriorityChange(event, index)
                                                }}

                                                input={<BootstrapInput />}
                                                onBlur={() => {
                                                    this.handleOnBlurPriorityChange(_data, index)
                                                }}
                                            >
                                                <option
                                                    value=""

                                                    disabled
                                                >
                                                    Select Priority
                                                </option>
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((_priorityData: any) => {
                                                    return (
                                                        <option value={_priorityData}>
                                                            {_priorityData}
                                                        </option>
                                                    )
                                                })}

                                            </NativeSelect>
                                            {_data.priorityerror && (
                                                <FormHelperText
                                                    className="errorText"
                                                >
                                                    Please select priority.
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item sm={4} xs={12} >
                                    <Grid item xs={12} className="mb10">
                                        <FormLabel
                                            className={classes.helperTextCss}
                                        >
                                            Max per day
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormControl className={classes.forrmInput_textfield} style={forrmInput_textfield}>
                                            <NativeSelect
                                                id="select"
                                                value={_data.perDay}
                                                onChange={(
                                                    event: React.ChangeEvent<{ value: unknown }>
                                                ) => {
                                                    this.handleSelectPerDayChange(event, index)
                                                }}

                                                input={<BootstrapInput />}
                                                onBlur={() => {
                                                    this.handleOnBlurPerDayChange(_data, index)
                                                }}
                                            >
                                                <option
                                                    value=""

                                                    disabled
                                                >
                                                    Select Max Per Day
                                                </option>
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((_priorityData: any) => {
                                                    return (
                                                        <option value={_priorityData}>
                                                            {_priorityData}
                                                        </option>)
                                                })}
                                            </NativeSelect>
                                            {_data.perDayerror && (
                                                <FormHelperText
                                                    className="errorText"
                                                >
                                                    Please select max per day.
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item sm={4} xs={12} >
                                    <Grid item xs={12} className="mb10">
                                        <FormLabel
                                            className={classes.helperTextCss}
                                        >
                                           Period Name
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            id="standard-basic"
                                            placeholder="Enter name"
                                            className={`${classes.forrmInput_textfield} textFieldStyles ${classes.textFontFamily}`} style={forrmInput_textfield}

                                            variant="outlined"
                                            value={_data.name}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                const value = event.target.value.trim();
                                                let data = this.state.subjectDetails
                                                data[index].name = event.target.value
                                                this.setState({ subjectDetails: data })                                
                                            }}


                                            inputProps={{ className: classes.textFiledCss }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item sm={4} xs={12} >
                                    <Grid item xs={12} className="mb10">
                                        <FormLabel
                                            className={classes.helperTextCss}
                                        >
                                            Is Online
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormControlLabel
                                            className={classes.forrmInput_online_field}
                                            control={
                                                <Checkbox
                                                    checked={_data.isOnline}
                                                    onChange={(event: any) => {

                                                        let data = this.state.subjectDetails
                                                        data[index].isOnline = event.target.checked
                                                        this.setState({ subjectDetails: data })

                                                    }}
                                                    name="includingNagativeMark"
                                                    className="violetColor"
                                                />
                                            }
                                            label={<span className={classes.helperTextCss}>Yes</span>}
                                        />
                                    </Grid>
                                </Grid>


                                {index !== this.state.subjectDetails.length - 1 && <Divider className="dividerStyles" />}
                            </>)
                        })}
                    </Grid>


                    {this.state.subjectDetails.length < this.state.subjectList.length && <Grid item xs={12} className="btnContainer">
                        <Button className="addButton"
                            onClick={() => {
                                this.setState({
                                    subjectDetails: [...this.state.subjectDetails, {
                                        subject: '',
                                        subjecterror: false,
                                        lengthh: '',
                                        lengtherror: false,
                                        priority: '',
                                        priorityerror: false,
                                        perDay: '',
                                        perDayerror: false,
                                        name: '',
                                        isOnline: false
                                    }]
                                })
                            }}
                        >
                            <img src={addMoreSubject} className="AddButtonImg" />
                            <Typography
                                className={`${classes.textFontFamily} addButtonTypo`}
                            >Add New</Typography>
                        </Button>
                    </Grid>}
                    <Grid item xs={12} className="mt2rem">
                        <Button
                            className="createButton"
                            onClick={(e: any) =>
                                this.submitPeriod(e)
                            }
                        >
                            <Typography
                                className={`createButtonTypo ${classes.textFontFamily}`}
                            >
                                Create
                            </Typography>
                        </Button>

                        <Button
                            className="cancelButton"
                            onClick={() => {
                                this.props.handleDialogClose()
                            }}
                        >
                            <Typography
                                className={`canceButtonTypo ${classes.textFontFamily}`}
                            >
                                Cancel
                            </Typography>
                        </Button>
                    </Grid>

                </Grid>
                {this.state.timetableDialog && <MesDialog
                    title="Create TimeTable"
                    deleteModal={this.state.timetableDialog}
                    onCloseModal={() => {
                        this.setState({ timetableDialog: false });
                    }}
                    confirmMes="Are you sure you want to create a timetable?"
                    description="This item will delete all your previous data."
                    cancelButtonText="Cancel"
                    okButtonText="Confirm"
                    deleteClick={(event: any) => this.submitForm(event)
                    } />
                }
            </Dialog>
        )
    }
    // Customizable Area End
}


// Customizable Area Start
export default withStyles(styles)(AutomaticClassPeriod);
// Customizable Area End
