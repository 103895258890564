// Customizable Area Start
import React from "react";
import AlertModal from "../../../../alert/src/AlertModal.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import InputBase from "@material-ui/core/InputBase";
import "../AddStudent.web.css";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Grid from '@material-ui/core/Grid';
import ReactPaginate from "react-paginate";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FileDrop } from 'react-file-drop';
export const configJSON = require("./config.js");
import {
    Box,
    Checkbox,
    Button,
    Link,
    Menu,
    MenuItem,
    TextField,
    Dialog,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Divider,
    Popover
} from "@material-ui/core";
import {
    Search,
} from "@material-ui/icons";

import { withStyles } from '@material-ui/core/styles';
import TeacherEnrollController, { Props } from './TeacherEnrollController.web';

import moment from "moment";
import CloseIcon from '@material-ui/icons/Close';
import { adminDownload, adminFilter, cancelOption, fileuploaded, moreOption, send } from "../assets";
import AddTeacher from "./AddTeacher.web";
import DeleteTeacher from "./DeleteTeacher.web";
import SendTeacher from "./SendTeacher.web";
import CsvError from "../CsvError.web";
import "./TeacherEnroll.web.css";
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
export class TeacherEnroll extends TeacherEnrollController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { studentSubjects, searchResult } = this.state;
        return (
            <Box
                style={{
                    backgroundColor: "white",
                    borderRadius: 8,
                    margin: "19px 0 0",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    //boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                    //padding: "25px 19px 0px 20px",
                }}
            >
                {this.state.loadingSpinner && <Spinner spinnerModal={this.state.loadingSpinner} />}
                <Box
                    style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box

                        style={{
                            borderRadius: 8,
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            padding: '1% 1.2% 1% 1.7%'
                        }}
                        className={this.props.classes.border_Color}
                    >
                        <input
                            placeholder="Search for Teacher..."
                            value={this.state.searchQuery}
                            style={{
                                border: "none",
                                outline: "none",
                                color: "#484848",
                                opacity: 0.55,
                                fontSize: '1rem',
                                fontWeight: 600,
                                width: "100%",
                                paddingRight: '1%',
                                paddingLeft: '1%'
                            }}
                            onChange={((event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ searchQuery: event.target.value }, () => {

                                })
                            })}
                            onKeyPress={(event: any) => {
                                if (event.key === "Enter") {
                                    event.preventDefault()
                                    if (this.state.applyFilterStudent && this.state.searchQuery.trim().length !== 0) {
                                        this.setState({ currentPage: 0 }, () => this.get_filter_student())
                                    }
                                    else if (this.state.searchQuery.trim().length !== 0) {
                                        this.setState({ currentPage: 0 }, () => this.search_student())
                                    } else if (this.state.applyFilterStudent && this.state.searchQuery.trim().length === 0) {
                                        this.setState({ currentPage: 0 }, () => this.get_filter_student())
                                    } else { this.setState({ currentPage: 0 }, () => this.get_teacher()) }
                                }
                            }}
                        />
                        <Search style={{
                            marginRight: '1%',
                            cursor: 'pointer'
                        }}
                            className={this.props.classes.icon_color}
                            onClick={() => {
                                if (this.state.applyFilterStudent && this.state.searchQuery.trim().length !== 0) {
                                    this.setState({ currentPage: 0 }, () => this.get_filter_student())
                                }
                                else if (this.state.searchQuery.trim().length !== 0) {
                                    this.setState({ currentPage: 0 }, () => this.search_student())
                                } else if (this.state.applyFilterStudent && this.state.searchQuery.trim().length === 0) {
                                    this.setState({ currentPage: 0 }, () => this.get_filter_student())
                                } else { this.setState({ currentPage: 0 }, () => this.get_teacher()) }

                            }} />
                    </Box>

                </Box>

                <Grid container style={{ marginTop: '2%', padding: '2% 2% 3%', borderRadius: 8, boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)' }}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {this.state.createSelected.length !== 0 && <div style={{ boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)', padding: 10, borderRadius: 6, backgroundColor: "#fff", marginRight: '1%', cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => { this.setState({ sendAlertModal: true }) }}>
                                    <img src={send} style={{ width: 26, height: 24, objectFit: 'contain' }} />
                                </div>}
                                <div className="Enroll-icons" style={{ boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)', padding: 11, borderRadius: 6, backgroundColor: "#fff", marginRight: '1%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                >
                                    <Link onClick={(event: any) => event.stopPropagation()}
                                        href={`${configJSON.baseUrl}${configJSON.downloadEnrollStudent}?token=${localStorage.getItem("token")}`}
                                        // target="_blank"
                                        download >
                                        <img src={adminDownload} style={{ width: 18, height: 24, objectFit: 'contain' }} />
                                    </Link>
                                </div>
                                <div className="Enroll-icons" style={{ boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)', padding: '11px', borderRadius: 6, backgroundColor: "#fff", marginRight: '1%', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                    onClick={(event: any) => { this.setState({ anchorEl: event.currentTarget }) }}

                                >


                                    <img src={adminFilter} style={{ width: '22px', height: 24, objectFit: 'contain' }} />

                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '3%' }}>

                        <TableContainer >
                            <Table style={{ width: '100%' }} aria-label="simple table">
                                <TableHead style={{ borderBottom: 'none' }}>
                                    <TableRow>
                                        <TableCell align="center" style={{ lineHeight: 'normal', padding: '6px 12px', width: '3%', borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: "#ded9ff", borderBottom: 'none' }} >
                                            <Checkbox
                                                size="small"
                                                checked={this.state.selectAll}
                                                onClick={event =>
                                                    this.handleSelectAllClick(event)
                                                }
                                                className={this.props.classes.icon_color}
                                            /></TableCell>
                                        <TableCell align="center" className={this.props.classes.subHeading_font} style={{ fontSize: '1.25rem', fontWeight: 600, padding: '12px', lineHeight: 'normal', backgroundColor: "#ded9ff", borderBottom: 'none' }}>Teacher ID</TableCell>
                                        <TableCell align="left" className={this.props.classes.subHeading_font} style={{ width: '35%', fontSize: '1.25rem', fontWeight: 600, padding: '12px', lineHeight: 'normal', backgroundColor: "#ded9ff", borderBottom: 'none' }}>Teacher Name</TableCell>
                                        <TableCell align="center" className={this.props.classes.subHeading_font} style={{ fontSize: '1.25rem', fontWeight: 600, padding: '12px', lineHeight: 'normal', backgroundColor: "#ded9ff", borderBottom: 'none' }}>Status</TableCell>
                                        <TableCell align="center" className={this.props.classes.subHeading_font} style={{ fontSize: '1.25rem', fontWeight: 600, padding: '12px', lineHeight: 'normal', backgroundColor: "#ded9ff", borderBottom: 'none' }}>Class</TableCell>
                                        <TableCell align="center" className={this.props.classes.subHeading_font} style={{ fontSize: '1.25rem', fontWeight: 600, padding: '12px', lineHeight: 'normal', backgroundColor: "#ded9ff", borderBottom: 'none' }}>Division</TableCell>
                                        <TableCell align="center" className={this.props.classes.subHeading_font} style={{ fontSize: '1.25rem', fontWeight: 600, padding: '12px', lineHeight: 'normal', width: '3%', backgroundColor: "#ded9ff", borderTopRightRadius: 6, borderBottomRightRadius: 6, borderBottom: 'none' }}></TableCell>


                                    </TableRow>
                                </TableHead>


                                {this.state.studentData.map((_data: any, index: any) => {


                                    const isSelected = this.isSelected(_data.id);
                                    return (
                                        <>
                                            <Box style={{ height: '10px' }}></Box>
                                            <TableBody>
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={_data.id}
                                                    selected={isSelected}
                                                    style={{
                                                        background: this.state.selectAll ? "#dae6d8" : "#f9f8fd"
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row" style={{
                                                        padding: '6px 12px',
                                                        borderTopLeftRadius: 6, borderBottomLeftRadius: 6, borderBottom: 'none'
                                                    }} >
                                                        <Checkbox
                                                            onClick={event =>
                                                                this.handleCheckboxClick(event, _data.id)
                                                            }
                                                            checked={isSelected || this.state.selectAll}
                                                            size="small"
                                                            className={`selectCheckbox ${this.props.classes.icon_color}`}
                                                        />
                                                    </TableCell>

                                                    <TableCell component="th" scope="row" align="center" style={{
                                                        padding: '12px',
                                                        borderBottom: 'none'
                                                    }} >
                                                        <Typography
                                                            style={{
                                                                fontSize: '1.125rem',
                                                                fontWeight: 600,
                                                            }}
                                                            className={this.props.classes.bodyText_font}
                                                        >
                                                            {_data.id}
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="left" style={{ padding: 12, borderBottom: 'none' }} >
                                                        <Typography
                                                            style={{
                                                                fontWeight: 600,
                                                                lineHeight: 'normal',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            className={this.props.classes.bodyText_font}
                                                        >
                                                            {_data.attributes.first_name} {_data.attributes.last_name}
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="center" style={{ padding: 12, borderBottom: 'none' }} >
                                                        <Typography
                                                            style={{
                                                                fontWeight: 600,
                                                                lineHeight: 'normal',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            className={this.props.classes.bodyText_font}
                                                        >
                                                            {_data.attributes.account_status}
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="center" style={{ padding: 12, borderBottom: 'none' }} >
                                                        <Typography
                                                            style={{
                                                                fontWeight: 600,
                                                                lineHeight: 'normal'
                                                            }}
                                                            className={`fileNameText22 ${this.props.classes.bodyText_font}`}
                                                        >
                                                            {/* {_data.attributes?.school_account?.data[0]?.attributes?.class?.class_name} */}
                                                            {_data.attributes?.school_account?.data.map((_userdata: any) => {
                                                                return (
                                                                    _userdata.attributes?.class?.class_name
                                                                )

                                                            }).join(" & ")}
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="center" style={{ padding: 12, borderBottom: 'none' }} >
                                                        <Typography
                                                            style={{
                                                                fontSize: '1.125rem',
                                                                fontFamily: "Open Sans",
                                                                color: "#3f526d",
                                                                fontWeight: 600,
                                                                lineHeight: 'normal'
                                                            }}
                                                        >
                                                            {_data.attributes?.school_account?.data[0]?.attributes?.division?.name}
                                                        </Typography>

                                                    </TableCell>

                                                    <TableCell component="th" scope="row" align="left" style={{ padding: '12px 0', borderBottom: 'none', borderBottomRightRadius: 6, borderTopRightRadius: 6 }} >
                                                        <img src={moreOption} style={{ cursor: 'pointer' }} onClick={(event: any) => {
                                                            this.setState({ viewStudent: _data }, () => { }), this.setState({ anchorEl1: event.currentTarget }
                                                            )
                                                        }}
                                                        />
                                                    </TableCell>



                                                </TableRow>


                                            </TableBody>
                                        </>
                                    )
                                })}
                            </Table>
                        </TableContainer>
                        {this.state.studentData.length === 0 && <span style={{ fontSize: '1.125rem', color: '#3f526d', fontWeight: 500, display: 'flex', justifyContent: 'center', marginTop: 10 }}>No Data Found.</span>}
                        {this.state.totalPage > 1 && <Box style={{ textAlign: "end", marginTop: 25 }}>
                            <ReactPaginate
                                previousLabel={"←   Previous"}
                                nextLabel={"Next   →	"}
                                initialPage={this.state.currentPage}
                                forcePage={this.state.currentPage}
                                pageCount={this.state.totalPage}
                                onPageChange={(e: any) => this.setState({ currentPage: e.selected }, () => {
                                    this.setState({ createSelected: [], selectAll: false })
                                    if (this.state.applyFilterStudent && this.state.searchQuery.trim().length !== 0) {
                                        this.get_filter_student()
                                    }
                                    else if (this.state.searchQuery.trim().length !== 0) {
                                        this.search_student()
                                    }
                                    else if (this.state.applyFilterStudent) {
                                        this.get_filter_student()
                                    }
                                    else {

                                        this.get_teacher()
                                    }
                                })}
                                containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                                previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                                nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                                disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                                activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                            />
                        </Box>}
                    </Grid>
                </Grid>


                {/* Filter menu */}
                <Menu
                    // anchorOrigin={{
                    //     vertical: "bottom",
                    //     horizontal: "right",
                    // }}
                    // transformOrigin={{
                    //     vertical: "bottom",
                    //     horizontal: "right",
                    // }}
                    //aria-haspopup="true"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.onMenuClose}
                    style={{
                        marginTop: '8%',
                        marginLeft: '-4%',
                    }}
                //   MenuListProps={{
                //     "aria-labelledby": "basic-button",
                //   }}
                >
                    <Box style={{ width: 324, padding: '.625rem 1rem', }}>
                        <Typography
                            style={{
                                textTransform: "none",
                                fontSize: "1.25rem",
                                fontWeight: 600,
                            }}
                            className={this.props.classes.title_font}
                        >
                            Filters by
                        </Typography>
                        <Divider style={{ margin: '8px 0', border: 'solid 0.5px #3f526d' }} />

                        {/* <Grid container >

                            <Grid item xs={12} style={{ alignSelf: 'center' }} >
                                <Typography
                                    style={{
                                        textTransform: "none",
                                        fontSize: "1rem",
                                        fontFamily: "Open Sans",
                                        fontWeight: 600,
                                        color: "#3f526d",

                                    }}
                                >
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '0.625rem', cursor: "pointer" }} onClick={(event: any) => { console.log("run"), this.setState({ tempFilter: this.state.filterStatus, statusDropDownAnchorEl: event.currentTarget, }, () => this.setState({ selectStatusDropDownDiv: true, data_Type: 'status' })) }}>
                                <Box style={{
                                    textAlign: "start",
                                    color: "#3f526d",
                                    fontSize: "1rem",
                                    fontFamily: "Open sans",
                                    opacity: 0.75,
                                    border: "solid 0.3px rgb(112 123 139 / 50%",
                                    display: "flex",
                                    justifyContent: 'space-between',
                                    padding: "10px 10px 10px 15px",
                                    borderRadius: 4,
                                    backgroundColor: "#f9f8fd",
                                    alignItems: "center",
                                    textTransform: 'capitalize'
                                    // backgroundImage: "linear-gradient(to bottom, #fff, #fff), linear-gradient(to top, #af38cb 126%, #7f6cfc -23%)"
                                }}>
                                    {this.state.filterStatus.length != 0 ?
                                        <Box style={{ display: "flex", flexDirection: "row" }}>
                                            {
                                                this.state.filterStatus.map((data: any, index: number) => (
                                                    <Box>


                                                        <Typography
                                                            style={{
                                                                color: "#3f526d",
                                                                fontSize: "1rem",
                                                                fontFamily: "Open sans",
                                                            }}
                                                        >
                                                            {
                                                                index === this.state.filterStatus.length - 1
                                                                    ?
                                                                    <>
                                                                        {data}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {data},{" "}
                                                                    </>
                                                            }
                                                        </Typography>

                                                    </Box>
                                                ))}
                                        </Box>
                                        :
                                        <Typography style={{

                                            fontSize: "1rem",
                                            fontFamily: "Open sans",
                                            color: "rgb(63, 82, 109,0.32)"
                                        }}>
                                            Please choose
                                        </Typography>
                                    }
                                    <Box style={{ display: "flex" }}>
                                        <KeyboardArrowDownIcon />
                                    </Box>
                                </Box>
                            </Grid>
                            <Popover
                                id="1"
                                anchorEl={this.state.statusDropDownAnchorEl}
                                open={Boolean(this.state.statusDropDownAnchorEl)}
                                onClose={() => {
                                    this.setState({
                                        statusDropDownAnchorEl: null
                                    })
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                style={{
                                    height: "auto"
                                }}
                                PaperProps={{
                                    style: {
                                        width: '288px',
                                        maxHeight: '70%',
                                        padding: "20px 25px 30px 25px",
                                        borderRadius: 8,
                                        boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.2)",
                                        backgroundColor: "#fff"
                                    },
                                }}
                            >
                                <Box>
                                    <Box
                                        style={{
                                            padding: "0px 0px 5px 0px",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            borderBottom: "solid 0.5px rgba(63, 82, 109,0.34)"
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: 15,
                                                fontFamily: "Open sans",
                                                fontWeight: 600,
                                                color: "#3f526d",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",

                                            }}>
                                            Approved
                                        </Typography>
                                        <Checkbox
                                            checked={this.state.tempFilter.indexOf(
                                                "approved"
                                            ) > -1}
                                            size="small"
                                            style={{ color: "#af38cb", backgroundColor: 'transparent' }}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            onChange={(event: any) => {
                                                let temp = []
                                                temp = this.state.tempFilter
                                                console.log(temp)
                                                if (event.target.checked) {
                                                    temp = []
                                                    temp.push("approved")


                                                } else {
                                                    // const data = temp.filter((_data: any, index: any) => {
                                                    //     _data !== "approved"
                                                    // })
                                                    // console.log("remove", data)
                                                    // temp = data;
                                                    temp = []

                                                }
                                                this.setState({
                                                    tempFilter: temp
                                                }, () => {

                                                })
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            padding: "5px 0px 5px 0px",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            borderBottom: "solid 0.5px rgba(63, 82, 109,0.34)"
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: 15,
                                                fontFamily: "Open sans",
                                                fontWeight: 600,
                                                color: "#3f526d",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",

                                            }}>
                                            Pending
                                        </Typography>
                                        <Checkbox
                                            checked={this.state.tempFilter.indexOf(
                                                "pending"
                                            ) > -1}
                                            size="small"
                                            style={{ color: "#af38cb", backgroundColor: 'transparent' }}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            onChange={(event: any) => {
                                                let temp = []
                                                temp = this.state.tempFilter
                                                console.log(temp)
                                                if (event.target.checked) {
                                                    temp = []
                                                    temp.push("pending")


                                                } else {
                                                    // const data = temp.filter((_data: any, index: any) => {
                                                    //     _data !== "pending"
                                                    // })
                                                    // console.log("remove", data)
                                                    // temp = data;
                                                    temp = []

                                                }
                                                this.setState({
                                                    tempFilter: temp
                                                }, () => {

                                                })
                                            }}
                                        />
                                    </Box>


                                    <Box
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "start",
                                            marginTop: 30,
                                            alignItems: "center",
                                        }}
                                    >
                                        <Button
                                            style={{
                                                borderRadius: '6px',
                                                backgroundColor: "#7f6cfc",
                                                color: "#fff",
                                                width: "40%",
                                                height: 30,
                                                textTransform: "capitalize",
                                                fontSize: "1rem",
                                                marginRight: 12,
                                                fontFamily: 'Open sans'
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    filterStatus: this.state.tempFilter,
                                                    statusDropDownAnchorEl: null

                                                }, () => {
                                                    this.setState({
                                                        tempFilter: []
                                                    })
                                                })
                                            }}
                                        >
                                            OK
                                        </Button>
                                        <Button
                                            style={{
                                                borderRadius: 6,
                                                fontSize: '1rem',
                                                fontFamily: "Open sans",
                                                fontWeight: 600,
                                                color: "#3f526d",
                                                textTransform: "none",
                                                marginLeft: "5px",
                                                backgroundColor: '#fff',
                                                border: 'solid 0.5px #7f6cfc',

                                                width: '40%',
                                                height: 30
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    statusDropDownAnchorEl: null,
                                                    tempFilter: []
                                                })
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Box>
                            </Popover>
                        </Grid> */}
                        <Grid container >

                            <Grid item xs={12} style={{ alignSelf: 'center', }} >
                                <Typography
                                    style={{
                                        textTransform: "none",
                                        fontSize: "1rem",
                                        fontWeight: 600,

                                    }}
                                    className={this.props.classes.subTitle_font}
                                >
                                    Class
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '0.625rem', cursor: "pointer" }} onClick={(event: any) => { event.stopPropagation(); console.log("run"), this.setState({ tempClass: this.state.filterClassId, classDropDownAnchorEl: event.currentTarget, selectClassDropDownDiv: true, data_Type: 'class' }) }}>


                                <Box style={{
                                    textAlign: "start",
                                    fontSize: "1rem",
                                    border: "solid 0.3px rgb(112 123 139 / 50%",
                                    display: "flex",
                                    justifyContent: 'space-between',
                                    padding: "10px 10px 10px 15px",
                                    borderRadius: 4,
                                    backgroundColor: "#f9f8fd",
                                    alignItems: "center",
                                    textTransform: 'capitalize'
                                    // backgroundImage: "linear-gradient(to bottom, #fff, #fff), linear-gradient(to top, #af38cb 126%, #7f6cfc -23%)"
                                }}>
                                    {this.state.filterClassId.length != 0 ?
                                        <Box className={`fileNameText22 ${this.props.classes.bodyText_font}`} style={{ display: "flex", flexDirection: "row" }}>
                                            {
                                                this.state.filterClassId.map((data1: any, index: number) => {
                                                    return (
                                                        this.state.classList.filter((x: any) => x.id === data1)
                                                            .map((x: any) => x.attributes?.display_name)
                                                    )
                                                }
                                                ).join(", ")}
                                        </Box>
                                        :
                                        <Typography style={{

                                            fontSize: "1rem",
                                            fontFamily: "Open sans",
                                            color: "rgb(63, 82, 109,0.32)"
                                        }}>
                                            Select Class
                                        </Typography>
                                    }
                                    <Box style={{ display: "flex" }}>
                                        <KeyboardArrowDownIcon />
                                    </Box>
                                </Box>
                            </Grid>
                            <Popover
                                id="1"
                                anchorEl={this.state.classDropDownAnchorEl}
                                open={Boolean(this.state.classDropDownAnchorEl)}
                                onClose={() => {
                                    this.setState({
                                        classDropDownAnchorEl: null
                                    })
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                style={{
                                    height: "auto"
                                }}
                                PaperProps={{
                                    style: {
                                        width: '288px',
                                        maxHeight: '70%',
                                        padding: "20px 25px 30px 25px",
                                        borderRadius: 8,
                                        boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.2)",
                                        backgroundColor: "#fff"
                                    },
                                }}
                            >
                                <Box>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        width="100%"
                                        style={{
                                            borderRadius: 8,
                                            height: "40px",
                                            position: "inherit",
                                            top: "0%",
                                            backgroundColor: '#f9f8fd'
                                        }}
                                    >
                                        <Search style={{
                                            height: 20,
                                            width: 20,
                                            marginRight: 20,
                                            marginLeft: 15
                                        }}
                                            className={this.props.classes.icon_color}
                                        />
                                        <input
                                            placeholder="Search Class"

                                            style={{
                                                border: "none",
                                                outline: "none",
                                                color: "#3f526d",
                                                opacity: 0.75,
                                                fontSize: "1rem",
                                                width: "100%",
                                                backgroundColor: '#f9f8fd'
                                            }}
                                            onChange={
                                                ((event: React.ChangeEvent<HTMLInputElement>) => {
                                                    event.stopPropagation(),
                                                        this.setState({ searchClass: event.target.value.trim() }, () => { })

                                                })}
                                        />
                                    </Box>
                                    {this.state.classList.filter((post: any) => {

                                        if (this.state.searchClass === '') {
                                            return post;

                                        } else if (post.attributes && post.attributes.display_name && post.attributes.display_name.toLowerCase().includes(this.state.searchClass.toLowerCase())) {
                                            return post;

                                        }
                                    })
                                        .map((_dataClass: any, index: any) => {
                                            return (
                                                <Box
                                                    style={{
                                                        padding: "0px 0px 5px 0px",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        borderBottom: "solid 0.5px rgba(63, 82, 109,0.34)",
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        {

                                                            let temp1: any = [];
                                                            temp1 = this.state.tempClass;
                                                            let temp2: any = temp1;
                                                            // let length = temp1.length === 0 ? 0 : temp1.length -1
                                                            console.log(temp1)
                                                            if (this.state.tempClass.indexOf(
                                                                _dataClass.id
                                                            ) > -1 || this.state.tempClass.indexOf(
                                                                parseInt(_dataClass.id)
                                                            ) > -1) {

                                                                var array: any[] = this.state.tempClass.filter((item: any) => {
                                                                    if (item !== _dataClass.id && item !== parseInt(_dataClass.id)) {
                                                                        return item
                                                                    }
                                                                }
                                                                );


                                                                this.setState({ tempClass: array }, () => { })

                                                            }
                                                            else {
                                                                this.setState({ tempClass: [...this.state.tempClass, _dataClass.id] }, () => { })

                                                            }


                                                        }
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: 15,
                                                            fontWeight: 600,
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",

                                                        }}
                                                        className={this.props.classes.bodyText_font}
                                                    >
                                                        {_dataClass.attributes.display_name}
                                                    </Typography>
                                                    <Checkbox
                                                        checked={this.state.tempClass.indexOf(
                                                            _dataClass.id
                                                        ) > -1}
                                                        size="small"
                                                        style={{ backgroundColor: 'transparent' }}
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        className={this.props.classes.icon_color}
                                                    />
                                                </Box>)
                                        })}
                                    {
                                        this.state.classList.filter((post: any) => {

                                            if (this.state.searchClass === '') {
                                                return post;

                                            } else if (post.attributes && post.attributes.display_name && post.attributes.display_name.toLowerCase().includes(this.state.searchClass.toLowerCase())) {
                                                return post;

                                            }
                                        }).length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>  <Typography
                                            style={{
                                                fontSize: 15,
                                                fontFamily: "Open sans",
                                                fontWeight: 600,
                                                color: "#3f526d",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",

                                            }}>No Data Found.</Typography> </div> : ''
                                    }


                                    <Box
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "start",
                                            marginTop: 20,
                                            alignItems: "center",
                                        }}
                                    >
                                        <Button
                                            style={{
                                                borderRadius: '6px',
                                                width: "40%",
                                                height: 30,
                                                textTransform: "capitalize",
                                                fontSize: "1rem",
                                                marginRight: 12,
                                            }}
                                            className={this.props.classes.button_color}
                                            onClick={(event: any) => {
                                                event.stopPropagation();
                                                event.cancelBubble = true;
                                                console.log("run")
                                                this.setState({

                                                    filterClassId: this.state.tempClass,
                                                    classDropDownAnchorEl: null,
                                                    searchClass: ''


                                                }, () => {
                                                    this.setState({ tempClass: [] })
                                                })
                                            }}
                                        >
                                            OK
                                        </Button>
                                        <Button
                                            style={{
                                                borderRadius: 6,
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                                textTransform: "none",
                                                marginLeft: "5px",
                                                width: '40%',
                                                height: 30
                                            }}
                                            className={this.props.classes.button_color_disabled}
                                            onClick={() => {
                                                this.setState({
                                                    classDropDownAnchorEl: null,
                                                    tempClass: [],
                                                    searchClass: ''
                                                })
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Box>
                            </Popover>
                        </Grid>
                        <Grid container >
                            <Grid item xs={12} style={{ marginTop: '1.125rem' }} >
                                <Button
                                    style={{
                                        borderRadius: '6px',
                                        width: "40%",
                                        height: 30,
                                        textTransform: "capitalize",
                                        fontSize: "1rem",
                                        marginRight: 12,
                                    }}
                                    //type="submit"
                                    className={this.props.classes.button_color}
                                    onClick={() => this.setState({ currentPage: 0 }, () => this.get_filter_student())}
                                >
                                    Apply
                                </Button>

                                <Button

                                    style={{
                                        borderRadius: 6,
                                        fontSize: '1rem',
                                        fontWeight: 600,
                                        textTransform: "none",
                                        marginLeft: "5px",
                                        width: '40%',
                                        height: 30
                                    }}
                                    className={this.props.classes.button_color_disabled}
                                    onClick={() => { this.setState({ anchorEl: null }) }}
                                >
                                    Cancel
                                </Button>
                                {/* <Close style={{ color: "#3f526d", cursor: "pointer" }} /> */}
                            </Grid>

                        </Grid>

                    </Box>
                </Menu>
                {/* More options */}
                <Menu
                    id="basic-menu"
                    anchorEl={this.state.anchorEl1}
                    open={Boolean(this.state.anchorEl1)}
                    onClose={() => { this.setState({ anchorEl1: '' }) }}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    style={{ marginLeft: '-5%' }}
                >
                    <Box style={{ width: "auto", borderRadius: 15 }}>
                        <MenuItem

                            onClick={() => {
                                this.setState({ dialogOpen: true }, () => { this.setState({ anchorEl1: '' }), console.log(Object.keys(this.state.viewStudent)) })
                            }}
                        >
                            <Grid container style={{ justifyContent: 'center' }}>
                                <Grid item xs={12} style={{ flexBasis: 0 }} >
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d"
                                        }}

                                    >
                                        View
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem

                            onClick={() => this.setState({ editDialog: true, studentEdit: true }, () => this.setState({ anchorEl1: '' }))}
                        >
                            <Grid container style={{ justifyContent: 'center' }}>
                                <Grid item xs={12} style={{ flexBasis: 0 }} >
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d"
                                        }}

                                    >
                                        Edit
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem
                            onClick={() => { this.setState({ deleteAlertModal: true }, () => this.setState({ anchorEl1: '' })) }}
                        >
                            <Grid container style={{ justifyContent: 'center' }}>
                                <Grid item xs={12} style={{ flexBasis: 0 }}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d"
                                        }}
                                    >
                                        Delete
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>

                    </Box>
                </Menu>

                {/* View Dialog */}
                {Object.keys(this.state.viewStudent).length > 0 && <Dialog
                    PaperProps={{
                        style: { borderRadius: 8 }
                    }}
                    aria-labelledby="customized-dialog-title"
                    maxWidth="md"
                    open={this.state.dialogOpen}
                    onClose={() => this.setState({ dialogOpen: false })}
                    closeAfterTransition

                    disableAutoFocus={true}
                    style={{ borderRadius: 8, }}
                >
                    <Grid item xs={12} style={{
                        padding: '30px 30px 30px 62px',
                        borderRadius: 8,
                        boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
                        minWidth: '600px'
                    }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}> <img src={cancelOption} onClick={() => this.setState({ dialogOpen: false })} style={{ width: '28px', height: 28, cursor: 'pointer' }} /></Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}
                                    >
                                        Teacher Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,

                                        }}
                                        className={this.props.classes.bodyText_font}
                                    >
                                        {this.state.viewStudent.attributes.first_name} {this.state.viewStudent.attributes.last_name}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        Teacher ID
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,

                                        }}
                                        className={this.props.classes.bodyText_font}>
                                        {this.state.viewStudent.id}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        Gender
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,

                                        }}
                                        className={this.props.classes.bodyText_font}>
                                        {this.state.viewStudent.attributes.gender}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        Creation Date
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,

                                        }}
                                        className={this.props.classes.bodyText_font}>
                                        {moment(this.state.viewStudent.attributes.created_at).format("DD/MM/YYYY")}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        DOB
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                        }}
                                        className={this.props.classes.bodyText_font}>
                                        {this.state.viewStudent.attributes.date_of_birth !== null ? moment(this.state.viewStudent.attributes.date_of_birth).format("DD/MM/YYYY") : ''}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        Contact No.
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,

                                        }} className={this.props.classes.bodyText_font} >
                                        {this.state.viewStudent.attributes.full_phone_number}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        Email ID
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            wordBreak: 'break-all'

                                        }}
                                        className={this.props.classes.bodyText_font}>
                                        {this.state.viewStudent.attributes.email}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        Class
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            wordBreak: 'break-all',
                                        }}
                                        className={this.props.classes.bodyText_font}
                                    >

                                        {this.state.viewStudent.attributes?.school_account?.data.map((_userdata: any) => {
                                            return (
                                                _userdata.attributes?.class?.class_name
                                            )

                                        }).join(" & ")}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        Subject
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            opacity: 0.5
                                        }}
                                        className={this.props.classes.subTitle_font}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                        }}
                                        className={this.props.classes.bodyText_font}>
                                        {this.state.viewStudent.attributes?.teacher_classes_subjects?.teacher_class_subject_data.map((_userdata: any) => {
                                            return (
                                                _userdata.subject_name
                                            )

                                        }).join(", ")}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                            <Button
                                style={{
                                    padding: "13px 70px 13px 71px",
                                    borderRadius: 8,
                                }}
                                className={this.props.classes.button_color}
                                //  variant="contained"
                                onClick={() => this.setState({ dialogOpen: false, viewStudent: {} })}
                            >
                                <Typography
                                    style={{
                                        textTransform: "none",
                                        fontSize: '1.125rem',
                                        fontWeight: 600,
                                    }}

                                >
                                    Ok
                                </Typography>
                            </Button>
                        </Grid>

                    </Grid>
                </Dialog>}
                {/* {this.props.uploadStudent && <UploadStudent uploadClass={this.props.uploadStudent} closeModalFun={this.props.onCloseModal} />} */}

                {(this.state.studentEdit || this.props.addDialog) && <AddTeacher addDialog={this.state.studentEdit ? this.state.editDialog : this.props.addDialog} handleDialogClose={this.handleDialogClose} isEdit={this.state.studentEdit} id={this.state.studentEdit ? this.state.viewStudent.id : "null"} isPending={false} />}
                {Object.keys(this.state.viewStudent).length > 0 && this.state.deleteAlertModal && <DeleteTeacher deleteAlertModal={this.state.deleteAlertModal} closeModalFun={this.onDeleteCloseModal} id={this.state.viewStudent.id} />}
                {this.state.createSelected.length > 0 && this.state.sendAlertModal && <SendTeacher sendAlertModal={this.state.sendAlertModal} closeModalFun={this.onSendCloseModal} id={this.state.createSelected} />}
                {/* Upload Student */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    open={this.props.uploadStudent}
                    onClose={this.props.onCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableAutoFocus={true} >
                    <Fade in={this.props.uploadStudent} style={{ border: "none" }}>
                        <div className="uploadfile-popup" style={{
                            backgroundColor: "white",
                            border: "1px solid #979797",
                            outline: "none",
                            borderRadius: 8,
                            height: "auto",
                            boxShadow: "0 0 24px 4px rgba(0, 0, 0, 0.2)",
                        }}>
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                    }}
                                    className={this.props.classes.title_font}
                                >
                                    Upload File
                                </Typography>
                                <Box>
                                    <CloseIcon
                                        style={{
                                            width: 24,
                                            height: 24,
                                            color: "#3f526d",
                                            opacity: 0.12,
                                            cursor: "pointer",
                                        }}
                                        onClick={this.props.onCloseModal}
                                    />
                                </Box>
                            </Box>
                            {
                                this.state.bulk_file.length === 0 ?
                                    <>
                                        <FileDrop
                                            onDrop={(files: any, event: any) => {
                                                window.addEventListener("drop", function (e) {
                                                    e.preventDefault();
                                                }, false);
                                                const validFileType = [

                                                    "text/csv",
                                                    "text/x-csv",
                                                    "application/csv",
                                                    "application/x-csv"
                                                ];

                                                if (files[0]) {
                                                    if (files[0].size > 524288000) {
                                                        this.setState({ alertMessage: "Maximum files size: 500MB", alertModal: true })
                                                    }
                                                    else if (!validFileType.includes(files[0].type)) {
                                                        this.setState({
                                                            alertMessage:
                                                                "Please upload valid file format: .csv",
                                                            alertModal: true,
                                                        });

                                                    } else {
                                                        this.setState({ fileName: files[0].name, bulk_file: files[0] })
                                                    }
                                                }

                                            }}
                                        >
                                            <Box
                                                style={{
                                                    padding: "29px 0px 24px 0px",
                                                    borderRadius: 8,
                                                    backgroundColor: "#f9f8fd",
                                                    marginTop: 43,
                                                    alignItems: 'center',
                                                    justifyContent: "center",
                                                }}
                                                className={this.props.classes.teacherEnroll_classAndSection_dashed_Border}
                                            >
                                                <Box style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: 'center',
                                                    justifyContent: "center",
                                                }}
                                                >
                                                    <input
                                                        style={{ display: "none" }}
                                                        id="contained-button-file"
                                                        type="file"
                                                        accept="text/csv"
                                                        onChange={(event: React.ChangeEvent<any>) => {
                                                            if (event.target.files[0]) {
                                                                const validFileType = [

                                                                    "text/csv",
                                                                    "text/x-csv",
                                                                    "application/csv",
                                                                    "application/x-csv"
                                                                ];
                                                                var file = event.target.files[0];
                                                                console.log(file)
                                                                if (file.size > 524288000) {
                                                                    this.setState({ alertMessage: "Maximum files size: 500MB", alertModal: true })
                                                                }
                                                                else if (!validFileType.includes(file.type)) {
                                                                    this.setState({
                                                                        alertMessage:
                                                                            "Please upload valid file format: .csv",
                                                                        alertModal: true,
                                                                    });

                                                                } else {
                                                                    this.setState({ fileName: file.name })
                                                                    this.set_data(file)
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor="contained-button-file">
                                                        <Button style={{
                                                            boxShadow: "none",
                                                            textTransform: "none",
                                                            fontSize: 16,
                                                            padding: "11px 31px",
                                                        }}
                                                            className={this.props.classes.button_color}
                                                            component="span"
                                                        >
                                                            Add File
                                                        </Button>
                                                    </label>
                                                </Box>
                                                <Typography
                                                    style={{
                                                        margin: "21px 0 0",
                                                        fontSize: 16,
                                                        textAlign: "center"
                                                    }}
                                                    className={this.props.classes.bodyText_font}
                                                >
                                                    Drag and drop files here or browse them from your computer.
                                                </Typography>
                                            </Box>
                                        </FileDrop>
                                    </>
                                    :
                                    <>
                                        <FileDrop
                                            onDrop={(files: any, event: any) => {
                                                window.addEventListener("drop", function (e) {
                                                    e.preventDefault();
                                                }, false);
                                                const validFileType = [

                                                    "text/csv",
                                                    "text/x-csv",
                                                    "application/csv",
                                                    "application/x-csv"
                                                ];

                                                if (files[0]) {
                                                    if (files[0].size > 524288000) {
                                                        this.setState({ alertMessage: "Maximum files size: 500MB", alertModal: true })
                                                    }
                                                    else if (!validFileType.includes(files[0].type)) {
                                                        this.setState({
                                                            alertMessage:
                                                                "Please upload valid file format: .csv",
                                                            alertModal: true,
                                                        });

                                                    } else {
                                                        this.setState({ fileName: files[0].name, bulk_file: files[0] })
                                                    }
                                                }

                                            }}
                                        >
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    textAlign: "center",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "12px",
                                                    padding: "34px 216px 34px 201px",

                                                }}
                                                className={this.props.classes.border_Color}
                                            >
                                                <Box>
                                                    <img src={fileuploaded} style={{ width: 61, height: 63, margin: "0 0px 27px 0px" }} />
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        style={{
                                                            textTransform: "none",
                                                            fontSize: 20,
                                                            fontWeight: 500,
                                                        }}
                                                        className={this.props.classes.subTitle_font}
                                                    >
                                                        File Uploaded
                                                    </Typography>
                                                </Box>
                                                <Box style={{ textAlign: "start" }}>
                                                    <Typography
                                                        style={{
                                                            textTransform: "none",
                                                            fontSize: 16,
                                                            fontWeight: 600,
                                                            margin: "10px 24px 0 3px"
                                                        }}
                                                        className={this.props.classes.subTitle_font}
                                                    >
                                                        File Name
                                                    </Typography>

                                                    <TextField
                                                        id="standard-basic"
                                                        type="text"
                                                        value={this.state.fileName}
                                                        style={{
                                                            width: "100%",
                                                            margin: "5px 35px 16px 1px",
                                                            borderRadius: 8,
                                                            border: "1px solid #ebebeb"
                                                        }}
                                                        disabled={true}
                                                        variant="outlined"
                                                        inputProps={{
                                                            style: {
                                                                fontFamily: "Open sans",
                                                                fontSize: 15.8,
                                                                color: "#03014c",
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                                <Box>
                                                    <input
                                                        style={{ display: "none" }}
                                                        id="contained-button-file"

                                                        type="file"
                                                        accept="text/csv"
                                                        onChange={(event: React.ChangeEvent<any>) => {
                                                            if (event.target.files[0]) {
                                                                const validFileType = [

                                                                    "text/csv",
                                                                    "text/x-csv",
                                                                    "application/csv",
                                                                    "application/x-csv"
                                                                ];
                                                                var file = event.target.files[0];
                                                                if (file.size > 524288000) {
                                                                    this.setState({ alertMessage: "Maximum files size: 500MB", alertModal: true })
                                                                }
                                                                else if (!validFileType.includes(file.type)) {
                                                                    this.setState({
                                                                        alertMessage:
                                                                            "Please upload valid file format: .csv",
                                                                        alertModal: true,
                                                                    });

                                                                } else {
                                                                    this.setState({ fileName: file.name })
                                                                    this.set_data(file)
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor="contained-button-file">
                                                        <Button variant="contained" component="span"
                                                            style={{
                                                                margin: "0 20px 0 0",
                                                                borderRadius: 3,
                                                                fontSize: 14,
                                                                fontWeight: 600,
                                                                letterSpacing: "0.1px",
                                                                boxShadow: "none",
                                                                cursor: "pointer"
                                                            }}
                                                            className={this.props.classes.button_color}
                                                        >
                                                            Update
                                                        </Button>
                                                    </label>
                                                </Box>
                                            </Box>
                                        </FileDrop>
                                    </>
                            }

                            <Box style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 43
                            }}>
                                <Button
                                    disableRipple={true}
                                    variant="contained"
                                    style={{
                                        margin: "0 20px 0 0",
                                        padding: "5px 25px ",
                                        borderRadius: 8,
                                        fontSize: 16,
                                    }}
                                    className={this.props.classes.button_color}
                                    onClick={this.add_student_Class}
                                >
                                    Upload
                                </Button>

                                <Button
                                    disableRipple={true}
                                    variant="contained"
                                    style={{
                                        margin: "0 20px 0 0",
                                        padding: "5px 25px",
                                        borderRadius: 8,
                                        fontSize: 16,
                                        boxShadow: "none",
                                    }}
                                    className={this.props.classes.button_color_disabled}
                                    onClick={() => this.onClose()}

                                >
                                    Cancel
                                </Button>
                            </Box>
                        </div>
                    </Fade>

                </Modal>

                <AlertModal
                    alertModal={this.state.alertModal}
                    isTeacher={false}
                    onCloseModal={() => { this.setState({ alertModal: false }) }}
                    alertTitle="Alert!"
                    alertMessage={this.state.alertMessage}
                    buttonText="OK"
                />
                {this.state.csvFileDilogError &&
                    <CsvError
                        alertModal={this.state.csvFileDilogError}
                        teacherRestircition={false}
                        onCloseModal={() => { this.setState({ csvFileDilogError: false, csvFileError: [] }) }}

                        csvError={this.state.csvFileError}

                    />}
            </Box>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(TeacherEnroll);
// Customizable Area End