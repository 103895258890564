// Customizable Area Start
import React from "react";
import { Typography, NativeSelect, Grid, InputBase, Box, Button } from "@material-ui/core";
import Calendar from 'react-calendar';
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../../../shared/Header/src/HOCAuth.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {EVENTS_NO_DATA } from "../../assets";
import ParentCalendarController, {
  Props,
} from "./ParentCalendarController.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import "./ParentCalendar.web.css";
import ViewAllEventsModal from "../../ViewAllEventsModal";
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme: any) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    backgroundColor: theme.palette.background.paper,
    padding: '10px 26px 10px 12px',
    width: '100%',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: theme.props.textFont,
    color: theme.props.textColor,
    fontSize: 18,
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);
// Customizable Area End
export class ParentCalendar extends ParentCalendarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { schoolEvents, loader, openViewAllSchoolEventsModal } = this.state;

    return (
      <>
        {loader && <Spinner spinnerModal={loader} />}
        <Grid container>
          <Grid item xs={12}>
            <Calendar
              className="noborder full-width"
              value={this.state.calendarDate}
              onChange={(date: any) => {
                this.setState({ calendarDate: new Date(date) }, () => {
                  this.getSchoolEvents();
                });
              }}
              next2Label={null}
              prev2Label={null}
              calendarType="US"
            />
            <Grid item xs={12}
              className={`calendar-dropdown-grid`}
            >
              <NativeSelect
                id="select"
                input={<BootstrapInput />}
                IconComponent={KeyboardArrowDownIcon}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  if (event.target.value === 'Today') {
                    this.setState(
                      { dateoftoday: 'Today', calendarDate: new Date() },
                      () => {
                        this.getSchoolEvents();
                      }
                    );
                  } else if (event.target.value === 'Tomorrow') {
                    let tomorrow = new Date();
                    tomorrow.setDate(new Date().getDate() + 1);
                    this.setState(
                      { dateoftoday: 'Tomorrow', calendarDate: tomorrow },
                      () => {
                        this.getSchoolEvents();
                      }
                    );
                  }
                }}
              >
                <option value="Today">Today</option>
                <option value="Tomorrow">Tomorrow</option>
              </NativeSelect>
            </Grid>
          </Grid>
          <Grid item xs={12}
            className={`calendar-event-grid`}
          >
            {schoolEvents?.length === 0 && (
              <>
                <div
                  className={`calendar-no-event-img-box`}
                >
                  <img
                    className={`calendar-no-event-img`}
                    src={EVENTS_NO_DATA}
                    alt="No data found"
                  />
                </div>
                <div
                  className={`calendar-no-event-text-box`}
                >
                  <span
                    className={`${this.props.classes.bodyText_font} calendar-no-event-text`}
                  >
                    No Event Found!
                  </span>
                </div>
              </>
            )}
            {schoolEvents?.length > 0 &&
              schoolEvents.map((schoolevent: any, index: number) => (
                index < 3 && 
                <Box
                  key={index}
                  style={{
                    cursor: schoolEvents?.length <= 3 ? 'pointer' : 'default'
                  }}
                  className={`calendar-event-box`}
                  onClick={() => {
                    schoolEvents?.length <= 3 && this.setState({
                      openViewAllSchoolEventsModal: !openViewAllSchoolEventsModal,
                    })
                  }}
                >
                  <Typography
                    className={`calendar-mb-8 ${this.props.classes.parentCalendar_dashboard_font_18} ${this.props.classes.subHeading_font}`}
                  >
                    {schoolevent.attributes.name}
                  </Typography>
                  <Typography
                    className={`${this.props.classes.parentCalendar_dashboard_font_14} ${this.props.classes.bodyText_font}`}
                  >
                    {this.handleGetStartAndEndTime(
                      schoolevent?.attributes?.start_time
                    )}{" "}
                    to{" "}
                    {this.handleGetStartAndEndTime(
                      schoolevent?.attributes?.end_time
                    )}
                  </Typography>
                </Box>
              ))}
          </Grid>
          {schoolEvents?.length > 3 && (
            <Grid xs={12} className={`calendar-view-all-box`} >
              <Button
                className={`calendar-view-all-btn ${this.props.classes.outline_button}`}
                variant="outlined"
                onClick={() => {
                  this.setState({
                    openViewAllSchoolEventsModal: !openViewAllSchoolEventsModal,
                  });
                }}
              >
                <Typography
                  className={`${this.props.classes.parentCalendar_dashboard_font_14} ${this.props.classes.subHeading_font} calendar-view-all-btn-text`}
                >
                  View All
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
        <ViewAllEventsModal
          allEventsModal={openViewAllSchoolEventsModal}
          modalHandleClose={this.onCloseEventsModal}
          allEvents={schoolEvents}
        />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(ParentCalendar, "TeacherAccount")
);
// Customizable Area End
