// Customizable Area Start
import React from "react";
import "./StudentGradeBook.web.css";

import {
  Box,
  withStyles,
  Typography,
  Grid,
  NativeSelect,
  Tabs,
  Tab
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import { ArrowDropDown } from "@material-ui/icons";
import TeacherGradeBookController, {
  Props,
} from "./TeacherGradeBookController.web";
import AssessmentGradeBook from "./TeacherTabs/AssessmentGradeBook.web";
import TaskGradeBook from "./TeacherTabs/TaskGradebook.web";
import TaskEvaluation from './TeacherTabs/TaskEvaluation.web';
import SelectedTab from '../SelectedTab.web'
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import './TeacherGradeBook.web.css';
// Customizable Area End

export class TeacherGradeBook extends TeacherGradeBookController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const BootstrapInput = withStyles((theme: any) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 4,
        position: "relative",
        boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: theme.palette.background.paper,
        padding: "14px 26px 14px 12px",
        width: "100%",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        fontSize: 18,
        lineHeight: 1.2,
        "&:focus": {
          borderRadius: 4,
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);

    const { selectedClassId, classData, subjectData, selectedSubjectId } = this.state;
    return (
      <div className="gradeBookContent TeacherGradeBookMainDiv">
        <Grid container spacing={2} className='TeacherGradeBookAlignItemsDiv'>
          <Grid item className="item" xs={12} sm={6} md={3} lg={2}>
            {/* <Box className="item"> */}
            <Typography
              className="TeacherGradeBookTypographyTxt"
            >
              Classes
            </Typography>
          </Grid>
          <Grid item className="item yearBg" xs={12} sm={6} md={3} lg={2}>
            <Typography
              className="TeacherGradeBookTypographyTxt"
            >
              2022-23
            </Typography>
          </Grid>
          <Grid item className="item" xs={12} sm={6} md={3} lg={2}>
            <NativeSelect id="select"
              // value={this.state.school_class_id ? this.state.school_class_id : ""}
              value={selectedClassId}
              input={<BootstrapInput />}
              IconComponent={ArrowDropDown}
              className="TeacherGradeBookDropDownSelect date-input"
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                this.handleClassChange(event.target.value)
              }}
            >
              <option value="" disabled>Select Class</option>
              {
                classData.length && classData.map((item: any) => (
                  <option value={item.id} key={item.id}>{item?.display_name}</option>
                ))
              }
            </NativeSelect>
          </Grid>
          <Grid item className="item" xs={12} sm={6} md={3} lg={2}>

            <NativeSelect id="select"
              // value={this.state.school_class_id ? this.state.school_class_id : ""}
              value={selectedSubjectId}
              input={<BootstrapInput />}
              IconComponent={ArrowDropDown}
              className='TeacherGradeBookDropDownSelect'
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                this.handleSubjectChange(event.target.value)
              }}
            >
              <option value="" disabled>All Subjects</option>
              {
                subjectData.length && subjectData.map((item: any) => (
                  <option value={item.id} key={item.id}>{item.attributes?.subject_name}</option>
                ))
              }
            </NativeSelect>
          </Grid>
          <Box className="gradebook-outer">
          <Box className="TeacherGradeBookSelectTabDiv">
            <SelectedTab isTeacher={true} type={'Gradebook'} />
          </Box>
          <Box className="tabingSection">
            <Tabs
              value={this.state.tabValue}
              onChange={(event, value) =>
                this.handleTansChange.onChangeTab(value)
              }
              TabIndicatorProps={{
                style: {
                  background: "#af38cb",
                },
              }}
              className='TeacherGradeBookTabWidthDiv'
              variant="scrollable"
            >
              <Tab
                className={`teacher_tab ${this.props.classes.subHeading_font}`}
                label="Assessment"
                id={`simple-tab-${this.state.tabValue}`}
              />
              <Tab
                className={`teacher_tab ${this.props.classes.subHeading_font}`}
                label="Task"
                id={`simple-tab-${this.state.tabValue}`}
              />
              <Tab
                className={`teacher_tab ${this.props.classes.subHeading_font}`}
                label="Submission"
                id={`simple-tab-${this.state.tabValue}`}
              />
            </Tabs>
          </Box>
          <Box
            
            className='TeacherGradeBookTabWidthDiv'
          >
            {this.state.tabValue === 0 && (
              <>
                {
                  this.state.selectedClassId !== '' &&
                  <AssessmentGradeBook
                    school_class_id={this.state.selectedClassId}
                    subject_id={this.state.selectedSubjectId}
                  />
                }
              </>
            )}
          </Box>
          <Box
            
            className='TeacherGradeBookTabWidthDiv'>
            {this.state.tabValue === 1 && (
              <>{
                this.state.selectedClassId !== '' &&
                <TaskGradeBook
                  school_class_id={this.state.selectedClassId}
                  subject_id={this.state.selectedSubjectId}
                />
              }
              </>
            )}
          </Box>
          <Box
              className='TeacherGradeBookTabWidthDiv'>
            {this.state.tabValue === 2 && (
              <>
                <TaskEvaluation
                  school_class_id={this.state.selectedClassId}
                  subject_id={this.state.selectedSubjectId}
                  classes={undefined}
                >
                </TaskEvaluation>
              </>
            )}
          </Box>
          </Box>
        </Grid>
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(TeacherGradeBook);
// Customizable Area End