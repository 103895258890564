// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import axios from "axios";
import * as SparkMD5 from "spark-md5";
import convert from "xml-js";
const uuidv4 = require("uuid/v4");
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

// Customizable Area Start
export interface Props {
  submitModal: boolean;
  closeModalFun: any;
  getStudentResourceFolders: any;
  getStudentResource: any;
  classes: any;

  // onCloseModel:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  data: any;
  teacherupcomingClasses: any;
  teacherClasses: any;
  teacherNotes: any;
  totalClasses: string;

  teacherAssegments: string;
  teacherofStudents: string;
  createnotediv: boolean;

  selectedUpcomingSubject: string;
  calendarDate: string;
  liveClasses: any;
  teacherTimetable: any;
  liveClassDate: string;
  searchResult: boolean;
  getTeacherClasses: any;

  closeModal: boolean;
  class_id: any;
  subject_id: any;
  getTeacherSubjects: any;
  unitHours: any;
  uploadFile: any;
  fileName: string;
  unitName: string;
  startdate: string;
  unitErrorText: string;
  unitError: boolean;
  classerror: boolean;
  subjecterror: boolean;
  hourerror: boolean;
  startdateerror: boolean;
  startdateerrorText: string;
  enddateerror: boolean;
  enddateerrorText: string;
  enddate: string;
  img_upload: boolean;
  unitDescription: string;
  saveAsDraft: boolean;
  alertMessage: any;
  alertModal: boolean;
  fileUploadDetail: any;
  sihnIdData: any;
  showSpinner: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UploadResourceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apisubmitCourseworkApiId: string = "";
  postPresignID: string = '';
  apiPreSignedID: any = [];
  apiResourceBulkUploadId: any = [];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      teacherNotes: [],
      teacherTimetable: [],

      data: '',
      liveClassDate: '',
      selectedUpcomingSubject: '',
      totalClasses: '',
      teacherClasses: [],
      teacherupcomingClasses: [],
      liveClasses: [],
      teacherAssegments: '',
      teacherofStudents: '',
      createnotediv: false,
      calendarDate: '',
      searchResult: false,
      getTeacherClasses: [],

      closeModal: false,
      class_id: '',
      subject_id: '',
      getTeacherSubjects: [],
      unitHours: '',
      uploadFile: [],
      fileName: '',
      unitName: '',
      startdate: '',
      enddate: '',
      unitError: false,
      unitErrorText: '',
      classerror: false,
      subjecterror: false,
      hourerror: false,
      startdateerror: false,
      startdateerrorText: '',
      enddateerror: false,
      enddateerrorText: '',
      img_upload: false,
      unitDescription: '',
      saveAsDraft: false,
      alertMessage: '',
      alertModal: false,
      fileUploadDetail: null,
      sihnIdData: [],
      showSpinner: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.get_userdata = this.get_userdata.bind(this);
  }
  // Customizable Area Start


  async componentDidMount() {
    super.componentDidMount();
  }

  get_userdata = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}')
    this.setState({ data: data });
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.apiPreSignedID.indexOf(apiRequestCallId) != -1
          && apiRequestCallId:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const indexOfUploadDoc = this.apiPreSignedID.indexOf(apiRequestCallId)
                const imageData: any = this.state.uploadFile[indexOfUploadDoc];
                const updatedBulkDocument = [...this.state.uploadFile];
                const msg: Message = new Message(
                  getName(MessageEnum.UploadMediaMessage)
                );
                updatedBulkDocument[indexOfUploadDoc].messageId = msg.messageId;
                updatedBulkDocument[indexOfUploadDoc].progress = 50;
                this.setState({ uploadFile: updatedBulkDocument })
                const uploadFileData: any = {
                  responseJson: responseJson,
                  imageData: imageData.file,
                  messageId: msg.messageId
                };
                msg.addData(
                  getName(MessageEnum.UploadMediaDataMessage),
                  uploadFileData
                );

                this.apiResourceBulkUploadId[this.apiResourceBulkUploadId.length] = msg.messageId;
                runEngine.sendMessage(msg.id, msg)
                //this.saveImageUsingURL(data);
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.setState({ showSpinner: false })
          }
          break;
          case this.apiResourceBulkUploadId.indexOf(apiRequestCallId) === -1
          ? ""
          : apiRequestCallId:
          {
            if (responseJson != null) {
              if (!responseJson.errors) {
                const keyValue = JSON.parse(
                  convert.xml2json(responseJson, {
                    spaces: 1,
                    compact: true,
                    ignoreComment: true,
                    alwaysChildren: true,
                    ignoreDeclaration: true,
                  })
                );
                const { uploadFile } = this.state;
                const updatedDocIndex = this.state.uploadFile.findIndex((item: any) => item.messageId === apiRequestCallId)
                uploadFile[updatedDocIndex].signedInId = keyValue.PostResponse.Key._text
                this.setState({ uploadFile: uploadFile });
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          break;
        case this.apisubmitCourseworkApiId: {
          if (responseJson != null) {
            if (!responseJson.errors) {
              toast.success(responseJson?.data?.message);
              this.apiPreSignedID=[]
              this.props.closeModalFun(this.state.closeModal)
              this.props.getStudentResource()
              this.props.getStudentResourceFolders()
            } else {
              this.parseApiErrorResponse(responseJson);
              if (responseJson?.errors[0] == "Something Went Wrong.") {
                this.submitCourseWork();
              }
              else {
                toast.error(responseJson?.errors[0])
              }
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
          this.setState({ showSpinner: false });
        }
          break;
      }

    }
    // Customizable Area End
  }
  // Customizable Area Start
  modalhandleClose = () => {
    this.setState({ closeModal: true })
    this.props.closeModalFun(this.state.closeModal)
  }

  getPreSignedId = async (fileUploadDetail: any) => {
    this.setState({ showSpinner: true }, () => console.log("loading===>", this.state.showSpinner))
    const { uploadFile } = this.state;
    const header = {
      // "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    let formData = new FormData();
    formData.append('content_type', fileUploadDetail.content_type)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.SignIDUrl
    );

    this.apiPreSignedID[this.apiPreSignedID.length] = requestMessage.messageId;

    uploadFile.map((item: any) => {
      if (item.id === fileUploadDetail.id) {
        item.requestID = requestMessage.messageId;
      }
    });

    await this.setState({ uploadFile: uploadFile });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fileUploadS3
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiPostMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUpload = (event: any, dragAndDrop: boolean, fileFromDragAndDrop: any) => {
    let allowUpload: boolean = true;
    const validFileType = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "application/pdf",
      "application/msword",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ];
    let files: any;
    if (dragAndDrop) {
      files = fileFromDragAndDrop
    }
    else {
      files = event.target.files
    }
    let totalSize: number = 0
    const keys2 = Object.keys(files)
    keys2?.map(async (key: any) => {
      const file = files[key]
      totalSize = totalSize + file.size
    })
    keys2?.map((key: any) => {
      const file = files[key];
      if (!validFileType.includes(file?.type)) {
        allowUpload = false;
        this.setState({
          alertMessage:
            "Please upload valid file format: .png .jpg .jpeg .doc xls or .pdf",
          alertModal: true,
        });

      }
    });
    if ((files.length + this.state.uploadFile.length) <= 10 && totalSize < 524288000) {
      // let files = event.target.files;
    } else {
      allowUpload = false;
      if (files.length + this.state.uploadFile.length > 10) {
        this.setState({ alertMessage: "Please upload maximum 10 files.", alertModal: true })
      } else {
        this.setState({ alertMessage: "Maximum files size: 500MB", alertModal: true })
      }
    }
    if (allowUpload) {
      const arr: any = this.state.uploadFile
      const keys = Object.keys(files);
      keys?.map(async (key: any) => {
        let fileUpload: any = {}
        const file: any = files[key];
        fileUpload = {
          id: uuidv4(),
          fileName: file.name,
          name: file.name.split(".")[0],
          isExistingFile: false,
          fileSize: file.size,
          percentage: 0,
          file: file,
          content_type: file.type,
          localUrl: URL.createObjectURL(file)
        };
        arr.push(fileUpload)
        this.setState({ uploadFile: arr })
        let checksum = "";
        this.getPreSignedId(fileUpload);
      })
    }


  }

  computeChecksumMd5 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject("MD5 computation failed - error reading the file");
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  }
  deleteDoc = (index: any) => {
    this.apiPreSignedID.splice(index, 1)
    let afterDelArr = this.state.uploadFile.filter((row: any, _index: any) => { return (_index !== index) })
    this.setState({ uploadFile: afterDelArr })
  }

  saveImageUsingURL = async (preSignedResponse: any) => {
    const { uploadFile } = this.state;
    let ind: number = 0;
    
    uploadFile?.map((item: any, index: number) => {
      if (item.requestID === preSignedResponse?.apiRequestCallId) {
        ind = index;
      }
    });
    const uploadURL = preSignedResponse.url.replace(/\/+$/, "");
    const urlFields = preSignedResponse.url_fields;
    if (uploadURL != null && uploadURL !== undefined) {
      const fileKey = urlFields.key.replace("${filename}", uploadFile[ind].fileName);
      const formData = new FormData();
      formData.append("key", fileKey);
      formData.append(
        "success_action_status",
        urlFields.success_action_status
      );
      formData.append("Content-Type", urlFields["Content-Type"]);
      formData.append(
        "Content-Disposition",
        urlFields["Content-Disposition"]
      );
      formData.append("policy", urlFields.policy);
      formData.append("x-amz-credential", urlFields["x-amz-credential"]);
      formData.append("x-amz-algorithm", urlFields["x-amz-algorithm"]);
      formData.append("x-amz-date", urlFields["x-amz-date"]);
      formData.append("x-amz-signature", urlFields["x-amz-signature"]);
      formData.append("file", uploadFile[ind].file);
      const s3Response = await axios({
        method: "post",
        url: uploadURL,
        data: formData,
      });
      if (s3Response.status === 201) {
        const keyValue = JSON.parse(
          convert.xml2json(s3Response.data, {
            spaces: 1,
            compact: true,
            ignoreComment: true,
            alwaysChildren: true,
            ignoreDeclaration: true,
          })
        );
        uploadFile[ind].signedInId = keyValue.PostResponse.Key._text
        this.setState({ uploadFile: uploadFile });
      }
    }


  };

  submitCourseWork = async () => {
    const arr: any = [];
    await this.state.uploadFile.map(async (key: any, index: any) => {
      arr.push(this.state.uploadFile[index].signedInId)
    })
    this.setState({ showSpinner: true });

    const { sihnIdData } = this.state;
    const httpBody = {
      attachments: arr,
    }
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.resourceContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apisubmitCourseworkApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.attandanceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitCourseWorkEndPoint + `?school_id=${school_Data?.school_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resourceApiPostMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}