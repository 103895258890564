// Customizable Area Start
import React, { useState, useCallback } from "react";
import { ITimeTableEventsProps as TeacherTimeTableEvent } from "../TeacherTimeTableController.web";
import { ITimeTableEventsProps as StudentTimeTableEvent } from "../StudentTimeTableController.web";
import { Typography, Divider, Button, Menu, Avatar, IconButton } from "@material-ui/core";
import {
  image_clock,
  image_user,
  image_presentation,
  image_class,
  image_calendar,
  subjectIcon,
} from "../assets";
import { I_Support } from "../../assets";
import moment from "moment";
import CreateIcon from '@material-ui/icons/Create';
import "./EventToolTip.web.css";
import EditOnlineLecture from '../../EditOnlineLecture.web';
import { HISTORY } from "../../../../../components/src/comman";
// Customizable Area End
// Customizable Area Start
interface props {
  event: TeacherTimeTableEvent | StudentTimeTableEvent;
  anchorEl: any;
  handleClose: any;
  savehandleClose: any;
  isTeacher: boolean;
  joinMeeting?: () => void;
  updateEvents?: (bool: boolean) => any
}
// Customizable Area End

// Customizable Area Start
const EventToolTip: React.FC<props> = ({
  event,
  anchorEl,
  handleClose,
  isTeacher,
  savehandleClose,
  joinMeeting,
  updateEvents
}) => {
  const duration = moment.duration(moment(event.end).diff(moment(event.start)));
  const lectureEndTime = moment(event.end).format("DD MMM YYYY HH:mma");
  const today = moment();
  const name = moment(event.start).diff(moment(new Date()), 'minutes') >= 10

  const byname = moment(lectureEndTime, "DD MMM YYYY HH:mma").isBefore(today)
  const hours = duration.asHours();

  const differ = moment.duration(moment().diff(moment(event.start)));
  const days = differ.asDays();

  const openModal = Boolean(anchorEl);
  const [editModal, setEditModal] = useState(false)
  const user_data = localStorage.getItem("user_data");
  const data = JSON.parse(user_data || "{}");


  const defaultCss: any = {
    border: 'linear-gradient(to top, #af38cb, #471c51)',
    background: "linear-gradient(to top, #af38cb, #471c51),linear-gradient(to top, #af38cb, #471c51)"
  }

  const ongoing: any = {
    border: '#3dbb3d',
    background: "#3dbb3d",
  }
  const ended: any = {
    border: '#999999',
    background: "#999999",
    color: '#999999'
  }

  const handleOpenRecordingVideoLink = useCallback(() => {
    if (isTeacher) {
      const recording = event?.recordings?.data?.[event?.recordings?.data?.length - 1].attributes;
      window.open(recording.play_url, "_blank")
    } else {
      HISTORY.push({
        pathname: '/LiveClassRecordings',
        state: {
          recordingDate: event?.recordings?.data?.[event?.recordings?.data?.length - 1]?.attributes?.recording_end,
          recordingId: event?.recordings?.data?.[event?.recordings?.data?.length - 1]?.id,
          meetingId: event?.meetingId,
        }
      })
    }

  }, [])

  return (
    <Menu
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorEl={anchorEl}
      open={openModal}
      onClose={handleClose}
      getContentAnchorEl={null}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      className="timeTableTooltip"
    >
      <div className="Tooltip-outer">
        <div
          className="EventToolTipMainDiv"
        >
          <Typography
            className="EventToolTipEventTxt Tooltiptitle"
          >
            {event?.isEvent
              ? "Event"
              : event?.is_online
                ? "Online Class"
                : "Offline Class"}
          </Typography>
          <div className="EventToolTipRecordingBtnDiv">
            {(!isTeacher && event?.is_online) &&
              <Button
                className="EventToolTipRecordingBtn"
                onClick={handleOpenRecordingVideoLink}
              >
                Recording
              </Button>
            }

            {isTeacher && (
              <div>
                <IconButton
                  disabled={days > 0}
                  onClick={() => {
                    setEditModal(true);
                  }}
                  className='EventToolTipCreateIconBtn'
                >
                  <CreateIcon
                    style={{
                      color: `${days > 0 ? "#471c5136" : "rgb(71,28,81)"}`,
                    }}
                    className='EventToolTipCreateIcon'
                  />
                </IconButton>
                {editModal && (
                  <EditOnlineLecture
                    data={event}
                    updateEvents={updateEvents}
                    editModal={editModal}
                    savecloseModalFun={() => {
                      setEditModal(false);
                      savehandleClose();
                    }}
                    closeModalFun={() => {
                      setEditModal(false);
                      handleClose();
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="EventToolTipRecordingBtnDiv">
            <div
              className="EventToolTipSubjectImageDiv"
            >
              {event.isEvent ? (
                <img
                  className="EventToolTipSubjectImage"
                  src={I_Support}
                />
              ) : (
                <img
                  className="EventToolTipSubjectImage"
                  src={
                    event.subjectImage == undefined
                      ? subjectIcon
                      : event.subjectImage
                  }
                />
              )}
            </div>

            <Typography
              className="EventToolTipSubjectTitleTxt"
            >
              {event.subject}
            </Typography>
          </div>

          <div
            style={{
              backgroundColor: event.borderColor,
            }}
            className='EventToolTipEventStartMonthDiv'
          >
            <div
              className="EventToolTipEventStartMonthSubDiv"
            >
              <Typography
                className="EventToolTipEventStartMonthTxt"
              >
                {moment(event.start).format("DD")}{" "}
              </Typography>
              <Typography
                className="EventToolTipEventStartMonthTxt"
              >
                {moment(event.start).format("MMM")}{" "}
              </Typography>
            </div>
            <div
              className="EventToolTipPeriodNameDiv"
            >
              <Typography
                className="EventToolTipEventStartMonthTxt"
              >
                {event.isEvent ? event?.period_name : event.title}
              </Typography>
              <Typography
                className="EventToolTipEventTitleTxt"
              >
                {event?.isEvent
                  ? `${event?.title}`
                  : event?.is_online
                    ? "Online Class"
                    : "Offline Class"}
              </Typography>
            </div>
          </div>

          <div
            className="EventToolTipImageClockDiv"
          >
            <img
              className="EventToolTipImageClockImg"
              src={isTeacher ? image_clock : image_clock}
            />
            <Typography
              className="EventToolTipDurationTxt"
            >
              <span className="EventToolTipTxtWeight">Duration: </span>
              {hours.toFixed(2)} hour from{" "}
              {moment(event.start).format("HH:mm A")}-
              {moment(event.end).format("HH:mm A")}
            </Typography>
          </div>

          <div
            className="EventToolTipUserDiv"
          >
            <img
              className="EventToolTipImg"
              src={isTeacher ? image_user : image_user}
            />
            <span
              className="EventToolTipTeacherDiv"
            >
              <span className="EventToolTipTxtWeight">
                {" "}
                {isTeacher ? "Teacher" : "Teacher"}{" "}
              </span>
              <span
                className="EventToolTipTeacherAvtarDiv"
              >
                <Avatar
                  src={event?.teacherImage ? event.teacherImage : "/"}
                  className='EventToolTipTeacherAvtar'
                />
              </span>
              {event?.teacherName}
            </span>
          </div>
          {event?.unit && (
            <div
              className="EventToolTipTitleDiv"
            >
              <img
                className="EventToolTipImg"
                src={isTeacher ? image_presentation : image_presentation}
              />
              <Typography
                className="EventToolTipTitleTxt"
              >
                <span className="EventToolTipTxtWeight">Unit: </span>
                {/* {event.unit} */}
              </Typography>
            </div>
          )}
          <div
            className="EventToolTipTitleDiv"
          >
            <img
              className="EventToolTipImg"
              src={isTeacher ? image_class : image_class}
            />
            <Typography
              className="EventToolTipTitleTxt"
            >
              <span className="EventToolTipTxtWeight">Class: </span>
              {event.class}
            </Typography>
          </div>

          <div
            className="EventToolTipTitleDiv"
          >
            <img
              className="EventToolTipImg"
              src={isTeacher ? image_calendar : image_calendar}
            />
            <Typography
              className="EventToolTipTitleTxt"
            >
              <span className="EventToolTipTxtWeight">Start Date & Time: </span>
              {moment(event.start).format("dddd, MMM DD, HH:mm A")}
            </Typography>
          </div>

          <Divider />

          {true && (
            <div className="EventToolTipDescDiv">
              <Typography
                className="EventToolTipDescTitle"
              >
                Description
              </Typography>

              <Typography
                className="EventToolTipDescTxt"
              >
                {event.description}
              </Typography>
            </div>
          )}

          {event.is_online &&
            !(event.status == "ended") &&
            event.status !== null && (
              <Button
                className={isTeacher ? "btn_joinnow" : "btn_joinnow"}
                style={{
                  fontFamily: "Open sans",
                  background:
                    event.status == "ongoing"
                      ? ongoing.background
                      : event.status == "ended"
                        ? ended.background
                        : defaultCss.background,

                  border:
                    event.status == "ongoing"
                      ? ongoing.border
                      : event.status == "ended"
                        ? ended.border
                        : defaultCss.border,

                  opacity:
                    event?.status &&
                      (isTeacher
                        ? (event?.status &&
                          event.status === "upcoming" &&
                          !byname &&
                          !name) ||
                        (event?.status && event.status === "ongoing")
                        : event?.status && event.status === "ongoing")
                      ? 1
                      : 0.4,
                }}
                onClick={joinMeeting}
                disabled={
                  event?.status &&
                    (isTeacher
                      ? (event?.status &&
                        event.status === "upcoming" &&
                        !byname &&
                        !name) ||
                      (event?.status && event.status === "ongoing")
                      : event?.status && event.status === "ongoing")
                    ? false
                    : true
                }
              >
                <Typography
                  className={isTeacher ? "label_joinnow" : "textlabel_joinnow"}
                >
                  {byname
                    ? event.status === "ongoing"
                      ? "Ongoing"
                      : event.status === "upcoming"
                        ? isTeacher
                          ? "Start Class"
                          : isTeacher
                            ? "Start Class"
                            : "Join Now"
                        : "End Class"
                    : event.status && event.status === "ongoing"
                      ? "Ongoing"
                      : event.status &&
                        event.status === "upcoming" &&
                        !byname &&
                        !name &&
                        isTeacher
                        ? "Start Class"
                        : isTeacher
                          ? "Start Class"
                          : "Join Now"}
                </Typography>
              </Button>
            )}
        </div>
      </div>
    </Menu>
  );
};
// Customizable Area End

// Customizable Area Start
export default EventToolTip;
function getStudentPeriods() {
  throw new Error("Function not implemented.");
}
// Customizable Area End

