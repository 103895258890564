// Customizable Area Start

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Hidden } from '@material-ui/core';
import './EmailAccountRegistration.web.css';
import {
  img21kSchholLogo1,
  imgloginScreenBackground,
  manImage,
  bigCloud,
  smallCloud,
  successIcon,
  errorIcon,
} from './assets';

import EmailVerificationLinkController, {
  Props,
} from './EmailVerificationLinkController.web';
import './EmailVerificationLink.web.css';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
import { withStyles } from '@material-ui/core';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class EmailVerificationLink extends EmailVerificationLinkController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return (
      <div className="loginHeight FullVhHeight">
        <div className={`EmailVerificationParentBox`}>
          <Grid container className="loginHeight FullHeight">
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={6}
              spacing={0}
              className={`loginImageDiv`}
            >
              <img
                src={imgloginScreenBackground}
                className={`halfcircleImage`}
                alt="Background Image"
              />
              <img
                src={manImage}
                alt="Student Illustrator Image"
                className="student_illustrator_img image_studentillustratorImage"
              />
              <div>
                <img
                  className="SchholLogo logoImage"
                  src={img21kSchholLogo1}
                  alt="Logo Image"
                />
              </div>
              <div className={`learn_together_text textlabel_quoteText ${this.props.classes.theme_font}`}>
                <Hidden only={['lg', 'xl']}>Let’s learn Together</Hidden>
                <Hidden only={['xs', 'sm', 'md']}>
                  Let’s learn
                  <br />
                  Together
                </Hidden>
              </div>
              <img
                src={smallCloud}
                alt="Small Cloud Image"
                className="cloud_small_img image_cloudsmallImage"
              />
              <img
                src={bigCloud}
                alt="Big Cloud Image"
                className="cloud_big_img image_cloudbigImage"
              />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={6}>
              <Grid container className="main_right_div centerDiv">
                <Grid item xs={12} className="login_div_otp loginDiv">
                  <div>
                    <div
                      className="md-mb-0 ErrorBox"
                    >
                      {this.state.error ? (
                        <img
                          src={errorIcon}
                          className="error_logo_div errorLogoDiv"
                          alt="success"
                        />
                      ) : (
                        <img
                          src={successIcon}
                          alt="error"
                          className="success_logo_div sucessLogoDiv"
                        />
                      )}
                    </div>
                    {this.state.error ? (
                      <div
                        className={`welcome_text textlabel_welcomeText  ${this.props.classes.theme_font}`}
                      >
                        Link Expired
                      </div>
                    ) : (
                      <div
                        className={`welcome_text textlabel_welcomeText  ${this.props.classes.theme_font}`}
                      >
                        Congratulations!
                      </div>
                    )}
                    {this.state.error ? (
                      <div
                        className={`verification_link_text textlabel_followinstructionText ${this.props.classes.theme_font}`}
                      >
                        Your registration link has been expired.
                        <br />
                        Please try a new one.
                      </div>
                    ) : (
                      <div
                        className={`verification_link_text textlabel_followinstructionText ${this.props.classes.theme_font}`}
                      >
                        {this.state.message}
                      </div>
                    )}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="textlabel_loginText xs-mb-25"
                    onClick={this.backtologinhandle}
                  >
                    Back To Login
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(EmailVerificationLink);
// Customizable Area End
