// Customizable Area Start
import React from "react";
import {
    Box,
    Modal,
    Divider,
    Typography,
    Button
} from "@material-ui/core";
import { videoIcon, backArrowIcon } from "./assets";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface Props {
    // Customizable Area Start
    isTeacher: boolean;
    onCloseModal: any;
    uploadVideoPercentage: number | null;
    backArrowClick: any;
    // Customizable Area End
}

export const UploadVideoPercentageModal = ({
    // Customizable Area Start
    isTeacher,
    onCloseModal,
    uploadVideoPercentage,
    backArrowClick,
    // Customizable Area End
}: Props) => {
    // Customizable Area Start
    return (
        <>
            <Modal
                open={!!uploadVideoPercentage}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="upload_video_percentage_modal">
                    <Box display="flex" alignItems="center">
                        <Box display="flex" id="modal-modal-title">
                            <Button
                                disableRipple={true}
                                className="UploadVideoBackBtn"
                                onClick={backArrowClick}
                            >
                                <Typography className="UploadVideoBackArrow">
                                    <img src={backArrowIcon} />
                                </Typography>
                                <Typography className="back_text">Back</Typography>
                            </Button>
                        </Box>
                        <Typography
                            id="modal-modal-title"
                            className="create_video_text_upload_modal"
                        >
                            Create Video
                        </Typography>
                    </Box>
                    <Divider className="upload_video_divider" />

                    <Box className="UploadVideoProgressDiv">
                        <div className="UploadVideoProgress">
                            <CircularProgressbarWithChildren
                                value={uploadVideoPercentage ? uploadVideoPercentage : 0}
                                strokeWidth={2}
                                styles={{
                                    path: {
                                        stroke: "#3f526d",
                                        strokeLinecap: "round",
                                    },
                                }}
                            >
                                <img
                                    src={videoIcon}
                                    className="UploadingVideoIcon"
                                />
                            </CircularProgressbarWithChildren>
                        </div>
                        <Typography className="UploadingPerText">
                            {uploadVideoPercentage} % Uploading...
                        </Typography>
                        <Typography className="UploadingVideoTakeTimeText">
                            This may take a while.
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </>
    );
    // Customizable Area End
};
// Customizable Area Start
export default UploadVideoPercentageModal;
// Customizable Area End
