// Customizable Area Start
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import './StudentAttemptSummary.web.css';
import AssesmentSummaryViewController from './AssesmentSummaryViewController.web';
import StudentAttemptSummary from './StudentAttemptSummary.web';
export const configJSON = require('../config');
import { v4 } from 'uuid';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class AssesmentSummaryView extends AssesmentSummaryViewController {
  // Customizable Area Start
  render() {
    let questionNumber: number = 0;
    const jumpQuestionHandler = (jumpNumber: number) => {
      this.props.numberHandler(jumpNumber);
    };
    return (
      <>
        <div className="view_questionbox">
          <h4 className={`pad_ing ${this.props.classes.subHeading_font}`}>
            Attempt Summary
          </h4>
          {this.props.assesmentQuestionSummary.map((section: any) => {
            return (
              <>
                <div>
                  <div>
                    <h4
                      className={`pad_ing bg_grey ${this.props.classes.title_font}`}
                    >
                      {section.attributes.name}
                    </h4>
                  </div>
                  <div
                    className="pad_ing"
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                  >
                    {section.attributes.questions.data.map((value: any) => {
                      questionNumber = questionNumber + 1;
                      return (
                        <StudentAttemptSummary
                          key={v4()}
                          index={questionNumber}
                          questionNumber={this.props.questionNumber}
                          isMarked={
                            value?.attributes?.student_answers?.data?.length ===
                              0
                              ? false
                              : value?.attributes?.student_answers?.data?.[0]
                                ?.attributes.mark_for_review
                          }
                          isGreen={
                            value?.attributes?.student_answers?.data?.length ===
                              0
                              ? false
                              : true
                          }
                          jumpQuestionHandler={jumpQuestionHandler}
                          classes={this.props.classes}
                        />
                      );
                    })}
                  </div>
                </div>
              </>
            );
          })}
          <div className="Rectangle" />
          <div className="pad_ing">
            <div className={`margin_bottom ${this.props.classes.title_font}`}>
              <h4>Legend</h4>
            </div>
            <div className={'margin_bottom'}>
              <span className={'box grey'} />
              <p className={this.props.classes.bodyText_font}>
                Current question
              </p>
            </div>
            <div className={'margin_bottom'}>
              <span className={'box green'} />
              <p className={this.props.classes.bodyText_font}>Answered</p>
            </div>
            <div className={'margin_bottom'}>
              <span className={'box white'} />
              <p className={this.props.classes.bodyText_font}>Not Attempted</p>
            </div>
            <div className={'margin_bottom'}>
              <span className={'box yellow'} />
              <p className={this.props.classes.bodyText_font}>
                Marked for Review
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// @ts-ignores
export default withStyles(themeCreateStyle)(AssesmentSummaryView);
// Customizable Area End
