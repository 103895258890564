// Customizable Area Start
import React, { useEffect, useRef } from "react";
import {
  apiKey,
  meetingNumber,
  password,
  role,
  signature,
  userEmail,
  userName,
} from "./config";
import "./css/bootstrap.css";
import "@zoomus/websdk/dist/css/react-select.css";
import "./ZoomMeeting.web.css";
import { generateSignature } from "./helper/helper";
// import { getZoomLeaveUrl } from "../utils/common";
// Customizable Area End

// Customizable Area Start
interface IZoomMeetingProps {
  currentEvent?: any;
  isTeacher?: boolean;
}
// Customizable Area End

// Customizable Area Start
const ZoomMeeting: React.FC<IZoomMeetingProps> = ({
  currentEvent,
  isTeacher = false,
}) => {
  let ZoomMtg: React.MutableRefObject<any> = useRef();
  // loading zoom libraries before joining on component did mount
  useEffect(() => {
    async function initiateZoomClient() {
      const Zoom = require("@zoomus/websdk");
      ZoomMtg.current = Zoom.ZoomMtg;
      ZoomMtg.current.setZoomJSLib("https://source.zoom.us/2.2.0/lib", "/av");
      ZoomMtg.current.preLoadWasm();
      ZoomMtg.current.prepareJssdk();
      if (currentEvent) {
        showMeetingBlock();
        initiateMeeting(currentEvent);
        // localStorage.removeItem("meeting-detail");
      }
    }
    initiateZoomClient();
  }, [currentEvent]);

  const showMeetingBlock = () => {
    const zoomMeetingBlock = document.getElementById("zmmtg-root");
    const mainBody = document.getElementById("main-frame");
    if (zoomMeetingBlock) zoomMeetingBlock.style.display = "block";
    if (mainBody) mainBody.style.display = "none";
  };

   const getZoomLeaveUrl = (role?: any) => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}')
    //const role = localStorage.getItem("role");
    if (data?.type === "TeacherAccount") return `${window.location.origin}/TeacherClassesAndSections`;
    else return `${window.location.origin}/StudentClassesAndSections`
  }

  const initiateMeeting = (currentEvent: any) => {
    const { api_key, api_secret, id, role } = currentEvent.meetingDetails;
    const currentRole = isTeacher ? 1 : 0;
    const leaveUrl = getZoomLeaveUrl(currentRole);
    const signature = generateSignature(
      api_key,
      api_secret,
      parseInt(id),
      parseInt(role)
    );
    ZoomMtg.current.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      success: (success: any) => {
        console.log("Success", success);
        ZoomMtg.current.join({
          signature: signature,
          meetingNumber: id,
          userName: `${userName} (${userEmail})`,
          apiKey: api_key,
          userEmail: userEmail,
          passWord: "",
          success: (success: any) => {
            console.log(success);
          },
          error: (error: any) => {
            console.log(error);
          },
        });
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  };

  return <div />;
};
// Customizable Area End

// Customizable Area Start
export default ZoomMeeting;
// Customizable Area End
