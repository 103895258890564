// Customizable Area Start
import React from "react";
import {
    StyleSheet,
    Platform,
} from "react-native";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Chart } from "react-google-charts";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import Calendar from 'react-calendar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ReactPaginate from "react-paginate";
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    CircularProgress,
    IconButton,
    Link,
    Menu,
    MenuItem,
    NativeSelect,
    TextField,
    Dialog,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Divider,
    FormLabel,
    FormHelperText
} from "@material-ui/core";
import {
    Search,
    Replay,
    NotificationsNone,
    HeadsetMicOutlined,
    Title,
    TitleOutlined,
    KeyboardArrowLeft,
    EditOutlined,
    MoreVertOutlined,
    KeyboardArrowDown,
    ArrowDropDown,
    BorderBottom
} from "@material-ui/icons";

import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import AddStudentController, { Props } from './AddStudentController.web';
import "../AddStudent.web.css";

import moment from "moment";
import { adminDownload, adminFilter, moreOption } from "../assets";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
// Customizable Area Start
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;

const BootstrapInput1 = withStyles(() => ({
    input: {
        width: "100%",
        // borderRadius: "8.8px",
        textAlign: "start",
        color: "#3f526d",
        fontSize: "1.125rem",
        fontFamily: "Open sans",
        opacity: 0.9,
        border: "solid 0.3px rgba(63, 82, 109, 0.5)"

    },
    error: {
        border: "0.3px solid red !important",
        opacity: 0.9
    },

}))(InputBase);

const forrmInput_textfield = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
    color: "#3f526d",
    fontSize: "1.125rem",
    fontFamily: "Open sans",
} as const;
// Customizable Area End

export class AddStudent extends AddStudentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    render() {
        const name = /^[a-zA-Z ]*$/;
        const phoneReg = /^(?:[0-9]●?){10,15}[0-9]$/;
        const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const today = moment();
        const disableFutureDt = (current: any) => {
            return current.isBefore(today);
        };
        return (
            <Dialog
                PaperProps={{
                    style: { borderRadius: 8 }
                }}
                aria-labelledby="customized-dialog-title"
                maxWidth="md"
                open={this.props.addDialog}
                onClose={() => this.props.handleDialogClose()}
                closeAfterTransition
                fullWidth={true}
                disableAutoFocus={true}
                style={{ borderRadius: 8 }}
            >
                {this.state.loadingSpinner && <Spinner spinnerModal={this.state.loadingSpinner} />}

                <Grid item xs={12} style={{
                    padding: '2.5rem 6rem',
                    borderRadius: 8,
                    boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)'
                }}>
                    <Grid container spacing={3}>
                        <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                            <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <FormLabel
                                    style={{
                                        fontSize: '1.125rem',
                                        fontWeight: 600,
                                    }}
                                    className={this.props.classes.subTitle_font}

                                >
                                    First Name*
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    id="standard-basic"
                                    placeholder="Enter First Name"
                                    style={forrmInput_textfield}
                                    variant="outlined"
                                    value={this.state.firstName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = event.target.value.trim();
                                        if (value.length === 0) {
                                            this.setState({
                                                firstNameError: true,
                                                firstNameErrorMes: " First name is required.",
                                            });
                                        } else {
                                            this.setState({
                                                firstNameError: false,
                                                firstNameErrorMes: "",
                                            });
                                        }

                                        if (
                                            event.target.value === "" ||
                                            name.test(event.target.value)
                                        ) {
                                            this.setState({ firstName: event.target.value });
                                        }
                                    }}
                                    onBlur={() => {
                                        if (
                                            this.state.firstName === null ||
                                            this.state.firstName.length === 0
                                        ) {
                                            this.setState({
                                                firstNameError: true,
                                                firstNameErrorMes: " First name is required.",
                                            });
                                        } else {
                                            this.setState({
                                                firstNameError: false,
                                                firstNameErrorMes: "",
                                            });
                                        }
                                    }}
                                    error={this.state.firstNameError}
                                    helperText={this.state.firstNameErrorMes}

                                    inputProps={{ style: { fontFamily: "Open sans", fontSize: '1.125rem', color: "#3f526d", padding: '1rem 1.875rem' } }}
                                    InputProps={{
                                        // startAdornment: (
                                        //   <InputAdornment position="start" style={{ marginRight: "0" }}>
                                        //     <img src={schoolIcon} style={{ width: '1.465rem', height: '1.465rem', color: "#7c7ba0", padding: '1.15rem 1.87rem 1.15rem 1.56rem', marginRight: "0px" }} />
                                        //   </InputAdornment>
                                        // ),
                                        style: {
                                            fontSize: "1.125rem",
                                            color: '#3f526d',
                                            fontFamily: "OpenSans",
                                            opacity: 1,
                                            paddingLeft: 0,
                                            // border: "1px solid #cfcfcf",
                                            //backgroundColor: "#f1f1f1",

                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                            <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <FormLabel
                                    style={{
                                        fontSize: '1.125rem',
                                        fontWeight: 600,

                                    }}
                                    className={this.props.classes.subTitle_font}
                                >
                                    Last Name*
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    id="standard-basic"
                                    placeholder="Enter Last Name"
                                    style={forrmInput_textfield}
                                    variant="outlined"
                                    value={this.state.lastName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = event.target.value.trim();
                                        if (value.length === 0) {
                                            this.setState({
                                                lastNameError: true,
                                                lastNameErrorMes: " Last name is required.",
                                            });
                                        } else {
                                            this.setState({
                                                lastNameError: false,
                                                lastNameErrorMes: "",
                                            });
                                        }

                                        if (
                                            event.target.value === "" ||
                                            name.test(event.target.value)
                                        ) {
                                            this.setState({ lastName: event.target.value });
                                        }
                                    }}
                                    onBlur={() => {
                                        if (
                                            this.state.lastName === null ||
                                            this.state.lastName.length === 0
                                        ) {
                                            this.setState({
                                                lastNameError: true,
                                                lastNameErrorMes: " Last name is required.",
                                            });
                                        } else {
                                            this.setState({
                                                lastNameError: false,
                                                lastNameErrorMes: "",
                                            });
                                        }
                                    }}
                                    error={this.state.lastNameError}
                                    helperText={this.state.lastNameErrorMes}

                                    inputProps={{ style: { fontFamily: "Open sans", fontSize: '1.125rem', color: "#3f526d", padding: '1rem 1.875rem' } }}
                                    InputProps={{
                                        // startAdornment: (
                                        //   <InputAdornment position="start" style={{ marginRight: "0" }}>
                                        //     <img src={schoolIcon} style={{ width: '1.465rem', height: '1.465rem', color: "#7c7ba0", padding: '1.15rem 1.87rem 1.15rem 1.56rem', marginRight: "0px" }} />
                                        //   </InputAdornment>
                                        // ),
                                        style: {
                                            fontSize: "1.125rem",
                                            color: '#3f526d',
                                            fontFamily: "OpenSans",
                                            opacity: 1,
                                            paddingLeft: 0,
                                            // border: "1px solid #cfcfcf",
                                            //backgroundColor: "#f1f1f1",

                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                            <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <FormLabel
                                    style={{
                                        fontSize: '1.125rem',
                                        fontWeight: 600,

                                    }}
                                    className={this.props.classes.subTitle_font}
                                >
                                    DOB*
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} >
                                <Datetime
                                    dateFormat={"DD/MM/YYYY"}
                                    timeFormat={false}
                                    strictParsing={true}
                                    input={true}
                                    value={this.state.DOB === "" ? "DD/MM/YYYY" : moment(this.state.DOB, "DD/MM/YYYY").format("DD/MM/YYYY")}
                                    className={
                                        this.state.DOBError
                                            ? "input_date_css_error_format1"
                                            : "input_date_cal_css_format1"
                                    }
                                    inputProps={{
                                        placeholder: "Date of Birth1",
                                        className: "Date_of_birth_calendar_Icon1",
                                        readOnly: true,
                                    }}
                                    isValidDate={disableFutureDt}

                                    onChange={(date) => {
                                        if (moment(date).format("DD/MM/YYYY").length === 0) {
                                            this.setState({
                                                DOBError: true,
                                                DOBErrorMes: " Please select date of birth.",
                                            });
                                        }
                                        this.setState({
                                            DOB: moment(date).format("DD/MM/YYYY"),
                                            DOBError: false,
                                            DOBErrorMes: '',
                                        });
                                    }}
                                />
                                <FormHelperText
                                    style={{ color: "red", margin: "3px 14px 0" }}
                                >
                                    {this.state.DOBErrorMes}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                            <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <FormLabel
                                    style={{
                                        fontSize: '1.125rem',
                                        fontWeight: 600,

                                    }}
                                    className={this.props.classes.subTitle_font}
                                >
                                    Gender*
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} >
                                <NativeSelect
                                    id="select"
                                    placeholder="Select Language*"
                                    style={forrmInput_textfield}
                                    variant="outlined"
                                    IconComponent={ExpandMoreIcon}
                                    value={this.state.gender}
                                    error={this.state.genderError}
                                    onChange={(
                                        event: any
                                    ) => {
                                        if (event.target.value !== "") {
                                            this.setState({ genderError: false });
                                        }
                                        this.setState({ gender: event.target.value });
                                    }}
                                    onBlur={() => {
                                        if (
                                            this.state.gender === "" ||
                                            this.state.gender.length === 0
                                        ) {
                                            this.setState({ genderError: true });
                                        } else if (this.state.gender.length !== 0) {
                                            this.setState({ genderError: false });
                                        } else {
                                            this.setState({ genderError: false });
                                        }
                                    }}


                                    inputProps={{ style: { fontFamily: "Open sans", fontSize: '1.125rem', color: "#3f526d", padding: '1rem 1.875rem' } }}



                                    input={<BootstrapInput1 />}
                                >
                                    <option value="" disabled>Select Gender</option>

                                    <option value="Male" >Male</option>
                                    <option value="Female" >Female</option>
                                    <option value="Other" >Other</option>



                                </NativeSelect>
                                {this.state.genderError && (
                                    <FormHelperText
                                        style={{ color: "red", margin: "3px 14px 0" }}
                                    >
                                        Please select gender.
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                            <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <FormLabel
                                    style={{
                                        fontSize: '1.125rem',
                                        fontWeight: 600,

                                    }}
                                    className={this.props.classes.subTitle_font}
                                >
                                    Phone No*
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} >
                                <ReactPhoneInput
                                    country={"in"}
                                    placeholder="Phone Number"
                                    value={this.state.phoneNo}
                                    alwaysDefaultMask={false}
                                    autoFormat={false}
                                    disabled={this.props.isEdit}
                                    disableCountryGuess={true}
                                    inputStyle={{
                                        color: '#3f526d',
                                        width: "100%",
                                        height: "56px",
                                        fontFamily: "Open sans",
                                        fontSize: "15.8px",
                                        borderRadius: "5px",
                                        border: this.state.phoneNoError
                                            ? "1px solid red"
                                            : "1px solid lightgray",
                                        borderLeft: this.state.phoneNoError
                                            ? "0px"
                                            : "1px solid lightgray",

                                    }}
                                    buttonStyle={{
                                        backgroundColor: '#fff',
                                        borderLeft: this.state.phoneNoError
                                            ? "1px solid red"
                                            : "1px solid lightgray",
                                        borderTop: this.state.phoneNoError
                                            ? "1px solid red"
                                            : "1px solid lightgray",
                                        borderBottom: this.state.phoneNoError
                                            ? "1px solid red"
                                            : "1px solid lightgray",
                                        borderRight: '0px'
                                    }}
                                    dropdownStyle={{ width: "300px" }}
                                    searchStyle={{ margin: "0", width: "97%", height: "30px" }}
                                    searchClass="search-class"
                                    disableSearchIcon
                                    enableSearch={true}

                                    countryCodeEditable={false}
                                    onChange={(value, country: any) => {
                                        console.log(value);
                                        if (this.state.dialCode !== country.countryCode) {
                                            this.setState({ phoneNo: country.dialCode, dialCode: country.countryCode });
                                        } else {

                                            this.setState({ phoneNo: value, dialCode: country.countryCode });
                                        }
                                        if (value.length === 0) {
                                            this.setState({
                                                phoneNoError: true,
                                                phoneNoErrorMes: "Phone number is required.",
                                            });
                                        } else if (phoneReg.test(value)) {
                                            console.log("value", value);
                                            this.setState({
                                                phoneNoError: false,
                                                phoneNoErrorMes: "",
                                            });
                                        } else if (!phoneReg.test(value)) {
                                            this.setState({
                                                phoneNoError: true,
                                                phoneNoErrorMes: "Enter a valid phone number.",
                                            });
                                        } else {
                                            this.setState({
                                                phoneNoError: false,
                                                phoneNoErrorMes: "",
                                            });
                                        }
                                    }}
                                    onBlur={(value: any) => {
                                        if (
                                            this.state.phoneNo === null ||
                                            this.state.phoneNo.length === 0
                                        ) {
                                            this.setState({
                                                phoneNoError: true,
                                                phoneNoErrorMes: "Phone number is required.",
                                            });
                                        } else if (!phoneReg.test(this.state.phoneNo)) {
                                            this.setState({
                                                phoneNoError: true,
                                                phoneNoErrorMes: "Enter a valid phone number.",
                                            });
                                        } else if (phoneReg.test(this.state.phoneNo)) {
                                            this.setState({
                                                phoneNoError: false,
                                                phoneNoErrorMes: "",
                                            });
                                        } else {
                                            this.setState({
                                                phoneNoError: false,
                                                phoneNoErrorMes: "",
                                            });
                                        }
                                    }}
                                />
                                {this.state.phoneNoError && (
                                    <FormHelperText
                                        style={{ color: "red", margin: "3px 14px 0" }}
                                    >
                                        {this.state.phoneNoErrorMes}
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                            <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <FormLabel
                                    style={{
                                        fontSize: '1.125rem',
                                        fontWeight: 600,

                                    }}
                                    className={this.props.classes.subTitle_font}
                                >
                                    Email ID*
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    id="standard-basic"
                                    placeholder="Email ID"
                                    style={forrmInput_textfield}
                                    disabled={this.props.isEdit}
                                    variant="outlined"
                                    value={this.state.emailId}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = event.target.value.trim();
                                        console.log(value);
                                        if (value.length === 0) {
                                            this.setState({
                                                emailIdError: true,
                                                emailIdErrorMes: "Email is required.",
                                            });
                                        } else if (!emailReg.test(value)) {
                                            this.setState({
                                                emailIdError: true,
                                                emailIdErrorMes: "Enter a valid Email.",
                                            });
                                        } else if (emailReg.test(value)) {
                                            this.setState({
                                                emailIdError: false,
                                                emailIdErrorMes: "",
                                            });
                                        }
                                        this.setState({ emailId: event.target.value.trim() });
                                    }}
                                    onBlur={() => {
                                        if (
                                            this.state.emailId === null ||
                                            this.state.emailId.length === 0
                                        ) {
                                            this.setState({
                                                emailIdError: true,
                                                emailIdErrorMes: "Email is required.",
                                            });
                                        } else if (!emailReg.test(this.state.emailId)) {
                                            this.setState({
                                                emailIdError: true,
                                                emailIdErrorMes: "Enter a valid Email.",
                                            });
                                        } else if (emailReg.test(this.state.emailId)) {
                                            this.setState({
                                                emailIdError: false,
                                                emailIdErrorMes: "",
                                            });
                                        } else {
                                            this.setState({
                                                emailIdError: false,
                                                emailIdErrorMes: "",
                                            });
                                        }
                                    }}
                                    error={this.state.emailIdError}
                                    helperText={this.state.emailIdErrorMes}
                                    inputProps={{ style: { fontFamily: "Open sans", fontSize: '1.125rem', color: "#3f526d", padding: '1rem 1.875rem', cursor: this.props.isEdit ? "not-allowed" : '' } }}
                                    InputProps={{
                                        // startAdornment: (
                                        //   <InputAdornment position="start" style={{ marginRight: "0" }}>
                                        //     <img src={schoolIcon} style={{ width: '1.465rem', height: '1.465rem', color: "#7c7ba0", padding: '1.15rem 1.87rem 1.15rem 1.56rem', marginRight: "0px" }} />
                                        //   </InputAdornment>
                                        // ),
                                        style: {
                                            fontSize: "1.125rem",
                                            color: '#3f526d',
                                            fontFamily: "OpenSans",
                                            opacity: 1,
                                            paddingLeft: 0,
                                            cursor: this.props.isEdit ? "not-allowed" : ''
                                            // border: "1px solid #cfcfcf",
                                            //backgroundColor: "#f1f1f1",

                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>

                        <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                            <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <FormLabel
                                    style={{
                                        fontSize: '1.125rem',
                                        fontWeight: 600,

                                    }}
                                    className={this.props.classes.subTitle_font}
                                >
                                    Class Room*
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} >
                                <NativeSelect
                                    id="select"
                                    placeholder="Select Language*"
                                    style={forrmInput_textfield}
                                    variant="outlined"
                                    IconComponent={ExpandMoreIcon}
                                    value={this.state.classs}
                                    error={this.state.classsError}
                                    onChange={(
                                        event: any
                                    ) => {
                                        if (event.target.value !== "") {
                                            this.setState({ classsError: false });
                                        }
                                        this.setState({ classs: event.target.value }, () => {
                                            this.setState({ division: '' }),
                                                this.get_division(this.state.classs)
                                        });
                                    }}
                                    onBlur={() => {
                                        if (
                                            this.state.classs === "" ||
                                            this.state.classs.length === 0
                                        ) {
                                            this.setState({ classsError: true });
                                        } else if (this.state.classs.length !== 0) {
                                            this.setState({ classsError: false });
                                        } else {
                                            this.setState({ classsError: false });
                                        }
                                    }}


                                    inputProps={{ style: { fontFamily: "Open sans", fontSize: '1.125rem', color: "#3f526d", padding: '1rem 1.875rem' } }}



                                    input={<BootstrapInput1 />}
                                >
                                    <option value="" disabled>Select Class</option>
                                    {this.state.classList.map((item: any) => {
                                        return (
                                            <>

                                                <option value={item.id}>{item.attributes.school_grade_name}</option>
                                            </>
                                        )
                                    })}




                                </NativeSelect>
                                {this.state.classsError && (
                                    <FormHelperText
                                        style={{ color: "red", margin: "3px 14px 0" }}
                                    >
                                        Please select class room.
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item style={{ marginTop: "2%" }} sm={6} xs={12} >
                            <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <FormLabel
                                    style={{
                                        fontSize: '1.125rem',
                                        fontWeight: 600,

                                    }}
                                    className={this.props.classes.subTitle_font}
                                >
                                    Division*
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} >
                                <NativeSelect
                                    id="select"
                                    placeholder="Select Language*"
                                    style={forrmInput_textfield}
                                    variant="outlined"
                                    IconComponent={ExpandMoreIcon}
                                    value={this.state.division}
                                    error={this.state.divisionError}
                                    onChange={(
                                        event: any
                                    ) => {
                                        if (event.target.value !== "") {
                                            this.setState({ divisionError: false });
                                        }
                                        this.setState({ division: event.target.value });
                                    }}
                                    onBlur={() => {
                                        if (
                                            this.state.division === "" ||
                                            this.state.division.length === 0
                                        ) {
                                            this.setState({ divisionError: true });
                                        } else if (this.state.division.length !== 0) {
                                            this.setState({ divisionError: false });
                                        } else {
                                            this.setState({ divisionError: false });
                                        }
                                    }}


                                    inputProps={{ style: { fontFamily: "Open sans", fontSize: '1.125rem', color: "#3f526d", padding: '1rem 1.875rem' } }}



                                    input={<BootstrapInput1 />}
                                >
                                    <option value="" disabled>Select Division</option>
                                    {this.state.divisionList.map((item: any) => {
                                        return (
                                            <>

                                                <option value={item.id}>{item.attributes.name}</option>
                                            </>
                                        )
                                    })}



                                </NativeSelect>
                                {this.state.divisionError && (
                                    <FormHelperText
                                        style={{ color: "red", margin: "3px 14px 0" }}
                                    >
                                        Please select division.
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '2rem' }}>
                        <Button
                            style={{

                                padding: "8px 40.5px 8px 41.5px",
                                borderRadius: 8,
                            }}

                            //  variant="contained"
                            onClick={(e: any) => {
                                this.onAddStudent(e)
                            }}
                            className={this.props.classes.button_color}
                        >
                            <Typography
                                style={{
                                    textTransform: "none",
                                    fontSize: '1.125rem',
                                    fontWeight: 600,
                                }}
                            >
                                {this.props.isEdit ? "Update" : "Create User"}
                            </Typography>
                        </Button>
                        {this.props.isEdit && !this.props.isPending && <Button
                            style={{

                                padding: "8px 20.5px 8px 21.5px",
                                borderRadius: 8,
                                marginLeft: 20
                            }}
                            className={this.props.classes.button_color_disabled}

                            //  variant="contained"
                            onClick={() => {
                                this.reset_student_password();
                            }}
                        >
                            <Typography
                                style={{
                                    textTransform: "none",
                                    fontSize: '1.125rem',
                                    fontWeight: 600,

                                }}
                            >
                                Reset Password
                            </Typography>
                        </Button>}
                        <Button
                            style={{
                                padding: '11px 24px 11px 25px',
                                borderRadius: 6,
                                marginLeft: 20
                            }}

                            className={this.props.classes.addStudentAndTeacher_classAndSection_normal_button_color}
                            //  variant="contained"
                            onClick={() => {
                                this.props.handleDialogClose()
                            }}
                        >
                            <Typography
                                style={{
                                    textTransform: "none",
                                    fontSize: '1.125rem',
                                    fontWeight: 600,
                                }}
                            >
                                Cancel
                            </Typography>
                        </Button>
                    </Grid>

                </Grid>
            </Dialog>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(AddStudent);
// Customizable Area End