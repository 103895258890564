// Customizable Area Start
import React from "react";
import "../../StudentGradeBook.web.css";
import "./Gradebook.web.css";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Checkbox,
    Select,
    MenuItem,
    ListItemText,
    InputBase,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import TaskController, {
    Props,
} from "./TaskController.web";
import _ from 'lodash'
import Datetime from "react-datetime";
import moment from 'moment';
import Spinner from "../../../../../shared/SideBar/src/Spinner";
import { withStyles } from "@material-ui/core/styles";
import ReactPaginate from "react-paginate";
import { themeCreateStyle } from "../../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme: any) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
    },
    input: {
        borderRadius: 8,
        position: "relative",
        backgroundColor: "#fff",
        boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
        border: "1px solid #f9f8fd",
        padding: "16px 26px 0px 12px",
        height: "36px",
        width: "200px",
        fontWeight: 600,
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        fontSize: 18,
        lineHeight: "22px",
        "&:focus": {
            borderRadius: 8,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps: any = {
    PaperProps: {
        style: {
            borderRadius: "6px",
            boxShadow: "0 2px 4px 0 rgba(134, 134, 134, 0.5)",
            backgroundColor: "#ffffff",
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        }
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    getContentAnchorEl: null
};
// Customizable Area End

export class Task extends TaskController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { classes } = this.props;
        const { isAllTaskSelected, isAllSubjectSelected, selectedTasks, selectedSubjects, taskList, subjectList } = this.state
        return (
            <div>
                {this.state.showLoader && (
                    <Spinner spinnerModal={this.state.showLoader} />
                )}
                <Grid container style={{ paddingBottom: "20px" }} spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <div className={classes.assessment_classAndSection_searchBoxStyle}>
                            <input
                                placeholder="Search..."
                                onChange={event => { this.setState({ queryTerm: event.target.value }) }}
                                value={this.state.queryTerm}
                                className={`${classes.bodyText_font} ${classes.assessment_classAndSection_searchInputStyle}`}
                                onKeyDown={(e: any) => {
                                    if (e?.key === 'Enter') {
                                        this.setState({ page: 0 }, () => this.getGradebookList())
                                    }
                                }}
                            />
                            <Search
                                className={classes.assessment_classAndSection_searchIconStyle}
                                onClick={() => this.setState({ page: 0 }, () => this.getGradebookList())}
                            />

                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} className={`${classes.GradebookStudentdateGridStyle} date-grid`}>
                        <Datetime
                            dateFormat={"DD MMM, YYYY"}
                            timeFormat={false}
                            strictParsing={true}
                            input={true}
                            value={moment(this.state.startDate, "DD-MM-YYYY").format(
                                "DD MMM, YYYY"
                            )}
                            initialValue={moment().format("DD MMM, YYYY")}
                            className="date-input"
                            inputProps={{
                                placeholder: "Start date",
                                className: `${classes.bodyText_font} Date_of_birth_calendar_Icon_register`,
                                readOnly: true,
                            }}
                            closeOnSelect
                            onChange={(date) => {
                                if (this.validateDates(date, this.state.endDate)) {
                                    this.setState({ startDate: date, page: 0 }, () => {
                                        this.getGradebookList()
                                    });
                                }
                            }}
                        />

                        <div className={`${classes.bodyText_font} ${classes.GradebookStudenttoTextStyle} to-text`}>To</div>

                        <Datetime
                            dateFormat={"DD MMM, YYYY"}
                            timeFormat={false}
                            strictParsing={true}
                            input={true}
                            value={moment(this.state.endDate, "DD-MM-YYYY").format(
                                "DD MMM, YYYY"
                            )}
                            initialValue={moment().format("DD MMM, YYYY")}
                            className="date-input"
                            inputProps={{
                                placeholder: "Start date",
                                className: `${classes.bodyText_font} Date_of_birth_calendar_Icon_register`,
                                readOnly: true,
                            }}
                            closeOnSelect
                            onChange={(date) => {
                                if (this.validateDates(this.state.startDate, date)) {
                                    this.setState({ endDate: date, page: 0 }, () => {
                                        this.getGradebookList()
                                    });
                                }
                            }}
                        />

                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={4} className={`${classes.GradebookStudentdropdownStyle} dropdown-select dropdown-select-task`}>
                        <Select
                            multiple
                            value={this.state.selectedSubjects}
                            onChange={this.handleChange}
                            onClose={() => {
                                if (this.state.selectChanged) {
                                    this.getGradebookList()
                                    this.setState({
                                        selectChanged: false
                                    })
                                }
                            }}
                            input={<BootstrapInput />}
                            MenuProps={MenuProps}
                            displayEmpty={true}
                            renderValue={(selected) => this.getSelectedSubjectNames(selected)}
                        >
                            <MenuItem
                                value="-1"
                                classes={{
                                    root: isAllSubjectSelected ? classes.GradebookStudentselectedAll : ""
                                }}
                            >
                                <Checkbox
                                    checked={isAllSubjectSelected}
                                />
                                <ListItemText primary={"All"} />
                            </MenuItem>
                            {
                                subjectList.map((subject: any) => {
                                    return (
                                        <MenuItem key={subject.id} value={subject.attributes.subject_name}>
                                            <Checkbox checked={this.state.selectedSubjects.indexOf(subject.attributes.subject_name) > -1} />
                                            <ListItemText primary={subject.attributes.subject_name} />
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={4} className={`${classes.GradebookStudentdropdownStyle} dropdown-select dropdown-select-task`}>
                        <Select
                            multiple
                            value={this.state.selectedTasks}
                            onChange={this.handleTaskChange}
                            onClose={() => {
                                if (this.state.selectChanged) {
                                    this.getGradebookList()
                                    this.setState({
                                        selectChanged: false
                                    })
                                }
                            }}
                            input={<BootstrapInput />}
                            MenuProps={MenuProps}
                            displayEmpty={true}
                            renderValue={(selected) => this.getSelectedTaskTypes(selected)}
                        >
                            <MenuItem
                                value="-1"
                                classes={{
                                    root: isAllTaskSelected ? classes.GradebookStudentselectedAll : ""
                                }}
                            >
                                <Checkbox
                                    checked={isAllTaskSelected}
                                />
                                <ListItemText primary={"All"} />
                            </MenuItem>
                            {
                                taskList.map((task: any) => {
                                    return (
                                        <MenuItem key={task.id} value={task.attributes.name}>
                                            <Checkbox checked={this.state.selectedTasks.indexOf(task.attributes.name) > -1} />
                                            <ListItemText primary={task.attributes.name} />
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </Grid>
                </Grid>
                <TableContainer className={`${classes.GradebookStudenttableContainerStyle}`}>
                    <Table>
                        <TableHead className={`${classes.GradebookStudenttableHeaderStyle}`}>
                            <TableRow>
                                <TableCell className={`${classes.subHeading_font} ${classes.GradebookStudenttableHeadingCell}`}>Task Name</TableCell>
                                <TableCell className={`${classes.subHeading_font} ${classes.GradebookStudenttableHeadingCell}`}>Subject</TableCell>
                                <TableCell className={`${classes.subHeading_font} ${classes.GradebookStudenttableHeadingCell}`}>Task Type</TableCell>
                                <TableCell className={`${classes.subHeading_font} ${classes.GradebookStudenttableHeadingCell}`}>Submit Date</TableCell>
                                <TableCell className={`${classes.subHeading_font} ${classes.GradebookStudenttableHeadingCell}`}>Grade</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody className={`${classes.GradebookStudenttableBodyStyle}`}>
                            {
                                this.state.gradebookData?.length === 0
                                    ?
                                    <TableRow>
                                        <TableCell className={`${classes.bodyText_font} ${classes.GradebookStudenttableBodyCell}`} colSpan={4} align="center" > No record(s) found. </TableCell>
                                    </TableRow>
                                    :
                                    this.state.gradebookData.map((item: any) => {
                                        return (
                                            <React.Fragment key={item.id}>
                                                <TableRow>
                                                    <TableCell colSpan={4} className={`${classes.GradebookStudentemptyTableBodyRow}`}></TableCell>
                                                </TableRow>
                                                <TableRow className={`${classes.GradebookStudenttableBodyRowStyle}`} >
                                                    <TableCell className={`${classes.bodyText_font} ${classes.GradebookStudenttableBodyCell}`}>{item.name}</TableCell>
                                                    <TableCell className={`${classes.bodyText_font} ${classes.GradebookStudenttableBodyCell}`}>{item.subject_name}</TableCell>
                                                    <TableCell className={`${classes.bodyText_font} ${classes.GradebookStudenttableBodyCell}`}>{item.task_type}</TableCell>
                                                    <TableCell className={`${classes.bodyText_font} ${classes.GradebookStudenttableBodyCell}`}>{moment(item.submit_date).format('DD/MM/YYYY')}</TableCell>
                                                    <TableCell style={{ color: item.grade_color_code }} className={`${classes.bodyText_font} ${classes.GradebookStudenttableBodyCell}`}>{item.grade ? item.grade : (item.percentage ? item.percentage : '-')}</TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        )
                                    })
                            }
                        </TableBody>

                    </Table>
                </TableContainer>
                {this.state.gradebookDataMeta?.pagination?.total_count > this.state.rowsPerPage && (
                    <ReactPaginate
                        previousLabel={"←   Previous"}
                        nextLabel={"Next   →	"}
                        breakLabel="..."
                        initialPage={this.state.page}
                        pageCount={
                            this.state.gradebookDataMeta?.pagination?.total_pages
                        }
                        onPageChange={(e: any) => {
                            if (e.selected !== this.state.page) {
                                this.setState({ page: e.selected }, () =>
                                    this.getGradebookList()
                                );
                            }
                        }}
                        containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                        previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                        nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                        disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                        activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                    />
                )}
            </div>
        );
    }
    // Customizable Area End
}


export const themeCreatStyle = ((theme: any) => ({
    // Customizable Area Start
    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: theme.props.buttonColor,
        }
    },
    image_Border_Color: {
        border: `3px solid ${theme.props.buttonColor}`
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    },

    GradebookStudenttableHeadingCell: {
        padding: "17px 5px 17px 20px", fontSize: 20, fontWeight: 600, borderBottom: 0
    },
    GradebookStudenttableBodyCell: {
        padding: "17px 5px 17px 20px", border: 0, fontSize: 18, fontWeight: 600
    },
    tableHeaderStyle: {
        backgroundColor: "#f9f8fd"
    },
    tableContainerStyle: {
        borderRadius: 6,
        border: 0,
    },
    tableBodyStyle: {
        marginTop: 10
    },
    tableBodyRowStyle: {
        backgroundColor: "#ffffff",
        borderRadius: "15%"
    },
    emptyTableBodyRow: {
        padding: "4px 0px 4px 0px",
        border: 0
    },
    mainHeading: {
        margin: "1rem 0",
        fontSize: "1.25rem",
        fontWeight: 600,
    },
    mainBox: {
        padding: "22px 20px 26px",
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 8,
        boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
    },
    filterBox: {
        display: "flex",
        marginBottom: 20,
    },
    filterSelect: {
        width: 180,
        marginBottom: 20,
        marginRight: 20,
    },
    searchInputStyle: {
        border: "none",
        outline: "none",
        marginLeft: 10,
        opacity: 1,
        fontSize: 18,
        width: "100%",
        paddingRight: 10,
        fontWeight: 600,
    },
    searchBoxStyle: {
        display: "flex",
        alignItems: "center",
        padding: "10px",
        height: "51px",
        borderRadius: 6,
        border: "solid 1px #c6c6c6"
    },
    searchIconStyle: {
        height: 30,
        width: 30,
        cursor: "pointer",
        marginRight: 5,
    },
    GradebookStudentdropdownStyle: {
        display: "flex",
        alignItems: "center",
    },
    toTextStyle: {
        marginLeft: 12,
        marginRight: 12,
    },
    dateGridStyle: {
        maxWidth: "100%"
    },
    GradebookStudentselectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    },
    indeterminateColor: {
        color: "#f50057"
    },
    // Customizable Area End
}))

// Customizable Area Start
export default withStyles(themeCreateStyle)(
    Task as React.ComponentType<any>
);
// Customizable Area End
