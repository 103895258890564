  // Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";

export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  teacherData: any;
  showLoader: boolean;
  tabValue: number;
  grade_id: string;
  selectedClass: any;
  teacherSubjects: Array<any>;
  selectedSubject: any;
  gradesList: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AssessmentLibraryContoller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apigetTeacherSubjectsId: string = "";
  apiGetGradesId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      teacherData: '',
      showLoader: false,
      tabValue: 0,
      grade_id: '',
      teacherSubjects: [],
      selectedClass: null,
      selectedSubject: null,
      gradesList: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.userdata = this.userdata.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.get_grades_list();
    this.userdata();
  }

  userdata = () => {
    const user_data = localStorage.getItem('user_data');
    const teacherData = JSON.parse(user_data || '{}')
    this.setState({ teacherData: teacherData });
  }

  handleClassChange = (event: any) => {
    const classSelected = this.state.gradesList?.find((item: any) => parseInt(item?.id) === parseInt(event.target.value))
    this.setState({ grade_id: event.target.value, selectedClass: classSelected, showLoader: true });
    this.get_teacher_class_subjects(event.target.value);
  }

  handleShowQuestionBankList = (event: any, subject: any) => {
    this.setState({ selectedSubject: subject });
    HISTORY.push({
      pathname: "/QuestionBank",
      state: { subject, currentClass: this.state.selectedClass },
    });
    localStorage.removeItem("preview_data")
  };

  get_teacher_class_subjects = (value: any) => {
    const { teacherData } = this.state
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherSubjectsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGradeEndPoint + `/${parseInt(value)}/subject_questions?school_id=${teacherData.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTansChange = {
    onChangeTab: (num: number) => {
      this.setState({ tabValue: num });
    },
  };

  get_grades_list = () => {
    const user_data = localStorage.getItem('user_data');
    const schoolData = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token'),
      school: schoolData?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetGradesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeachersGradeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apigetTeacherSubjectsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              teacherSubjects: responseJson.data?.questions ? responseJson.data?.questions : [],
              showLoader: false
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
          this.setState({ showLoader: false })
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiGetGradesId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ gradesList: responseJson.data, showLoader: false})
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  // Customizable Area End
}
