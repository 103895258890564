// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { List, ListItem,FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Grid ,Typography} from '@material-ui/core';
import { HISTORY } from "../../../../components/src/comman";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
  tabCell: {
    border: "1px solid #D9D9D9",
    borderRadius:"0px"
  },
  tabCellHead:{
    borderRadius:"0px",
    fontWeight: 600, 
    backgroundColor: "#ded9ff", 
    borderTopLeftRadius: "5px"
  },
  tabCellRow: {
    borderRadius:"0px",
    border: "1px solid #D9D9D9",
    backgroundColor: "#F2F2F2" 
  },
 
  tabBodyBox:{
    margin: '-16px', paddingTop: "29px"
  },
  boxFirstGrid: {
    borderLeft: "0px",
    borderBottom: "0px",
    borderTop: "1px solid #D9D9D9", 
    backgroundColor: "#F2F2F2", 
    textAlign: "center", 
    padding: "18px"
  },
  boxGrid: {
    backgroundColor: "#F2F2F2",
    textAlign: "center",
    padding: "18px",
    borderLeft: "1px solid #D9D9D9", 
    borderBottom: "0px",
    borderTop: "1px solid #D9D9D9"

  },
  bodyGridFirstBox: {
    borderLeft: "0px",
    borderBottom: "0px",
    textAlign: "center",
    padding: "27px" 
  },
  bodyBoxGrid: {
    borderLeft: "1px solid #D9D9D9",
    borderBottom: "0px",
    textAlign: "center", 
    padding: "27px" 
  },
  tabCellBoxCommon: {
    margin: '-16px'
  }
}),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    }
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

function createData(title: string, term: number, term2: number, term3: number) {
  return { title, term, term2, term3 };
}

const rows = [
  createData('Total Attendence of the learner', 20, 10, 5),
  createData('Total Number of working days', 20, 10, 4.0)
];


function ReportCardElevenTwelve(props:any) {
  const classes = useStyles();
  const [selectclass, setSelectclass] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date('2014-08-18T21:11:54'),
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectclass(event.target.value as string);
  };
  
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };


  const handleBack = () => {
    HISTORY.goBack();
  }

  return (
    <div>
      <div className='profileTitle'>
      <Button className="backBtn" onClick={()=>handleBack()}><KeyboardBackspaceOutlinedIcon /> Back</Button>
      <h2 className={props.classes.heading_font}>Part 1 - Assessment of Learning </h2>
      {/* <Button className="editBtn commonBtnSecondary">Enable Edit <EditOutlinedIcon /> </Button> */}
      <Button className={props.classes.button_color} style={{position: "absolute",right: 0,marginRight: "10px"}}>Enable Edit <EditOutlinedIcon /> </Button>
      </div>
      <div className="tableContent">
      <Typography className={props.classes.subHeading_font} align="left" style={{ paddingLeft: "40px"}}>5 Point Scale</Typography>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} align="center" className={props.classes.bodyText_font}>
                                First Term
                            </TableCell>
                            <TableCell colSpan={3} align="center" className={props.classes.bodyText_font}>
                                Second Term
                            </TableCell>
                            <TableCell colSpan={4} align="center"  className={props.classes.bodyText_font}>
                                Third Term
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.tabCellRow} style={{ borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCellRow} >
                                <Typography align="center">Examination</Typography>
                                <Box className={classes.tabBodyBox}>
                                    <Grid container >
                                        <Grid item xs={4} className={classes.boxFirstGrid}>
                                        Test 1<br />50
                                        </Grid>
                                        <Grid item xs={4} className={classes.boxGrid}>
                                        100
                                        </Grid>
                                        <Grid item xs={4} className={classes.boxGrid}>
                                        Class <br />Avg
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>

                            <TableCell className={classes.tabCellRow}>
                                <Typography align="center">Examination</Typography>
                                <Box  className={classes.tabBodyBox}>
                                    <Grid container >
                                        <Grid item xs={4}  style={{ borderLeft: "0px", borderBottom: "0px", borderTop: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center", padding: "18px"}}>
                                        Test 2<br />50
                                        </Grid>
                                        <Grid item xs={4} className={classes.boxGrid}>
                                        100
                                        </Grid>
                                        <Grid item xs={4} className={classes.boxGrid}>
                                        Class<br /> Avg
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCellRow}>
                                <Typography align="center">Examination</Typography>
                                <Box  className={classes.tabBodyBox}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.boxFirstGrid}>
                                        Test 3<br />50
                                        </Grid>
                                        <Grid item xs={4} className={classes.boxGrid}>
                                        100
                                        </Grid>
                                        <Grid item xs={4} className={classes.boxGrid}>
                                        Class <br />Avg
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell align="center" className={classes.tabCellRow} style={{ borderRadius:"0px"}}>
                                <Typography>
                                    Internal <br/>
                                  Assess.<br/>
                                   50</Typography>
                            </TableCell>
                            <TableCell className={classes.tabCellRow} style={{borderRadius:"0px" }} >
                                <Typography align="center">Promotion</Typography>
                                <Box  className={classes.tabBodyBox}>
                                    <Grid container >
                                        <Grid item xs={6} className={classes.boxFirstGrid}>
                                        Total<br />500
                                        </Grid>
                                        <Grid item xs={6} className={classes.boxGrid}>
                                        Grade
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.tabCellRow} style={{ borderRadius:"0px"}}>
                                <Typography> English</Typography>
                            </TableCell>
                            <TableCell className={classes.tabCell}>
                                <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4} className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4} className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4} className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                           <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                           <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                             <TableCell  className={classes.tabCell}style={{ borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Mathematics
                            </TableCell>
                           <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                           <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                           <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                             <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Physical Theory/Bus.St/History
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Physics Prac
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Chemistry Theory/Acc/Geo
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Chemistry Practical
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Chemistry Total
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Biology Theory/Economics
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Biology Practical
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Biology Total
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Inf.Practices Theory/C.S
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Inf.Practices/CS Practical
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Inf.Practices/CS Total
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Attendence
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                SUPW
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                Health & Physical Education
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  className={classes.tabCellRow} style={{ borderRadius:"0px" }}>
                                General Studies
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                          <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={4}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                        <Grid item xs={4}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell className={classes.tabCell} style={{borderRadius:"0px"}}>
                               <Box className={classes.tabCellBoxCommon}>
                                    <Grid container >
                                        <Grid item xs={6}  className={classes.bodyGridFirstBox}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.bodyBoxGrid}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
      </div>
    </div>
  )
}
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(ReportCardElevenTwelve as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End