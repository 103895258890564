// Customizable Area Start
import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { check } from "./assets";
import "./TeacherProfile.web.css";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface Props {
  // Customizable Area Start
  imgSource: () => any;
  name: string;
  fileName: string;
  isReviewed: any;
  imgWidth: number;
  imgHeight: number;
  isUploaded: boolean;
  uploadDocument: () => any;
  reviewDocument: () => any;
  // Customizable Area End
}

export const StudentsDocumentBox = ({
  // Customizable Area Start
  imgSource,
  name,
  fileName,
  isReviewed,
  imgWidth,
  imgHeight,
  isUploaded,
  uploadDocument,
  reviewDocument,
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  return (
    <Grid item xs={12} sm={6} md={6} lg={4}>
      <div
        className="document_box"
        style={{
          backgroundColor: isReviewed == "approved" ? "#f2f6fc" : "#fff",
        }}
      >
        <img
          src={imgSource()}
          className="ProfileDocumentImg"
          style={{
            width: imgWidth,
            height: imgHeight,
          }}
        />
        <div
          id="fileNameTextDoc"
          className="StudentProfileDocumentName"
        >
          {fileName === "" || fileName === null ? name : fileName}
        </div>
        {!isUploaded ? (
          <Button
            variant="text"
            className="StudentDocumentUploadBtn"
            onClick={uploadDocument}
          >
            Upload
          </Button>
        ) : isReviewed === "approved" ? (
          <Button
            variant="text"
            className="ViewDocumentBtn"
            onClick={reviewDocument}
          >
            View Document
          </Button>
        ) : isReviewed === "rejected" ? (
          <Button
            variant="outlined"
            className="DocumentRejectedBtn"
            onClick={reviewDocument}
          >
            Rejected
          </Button>
        ) : (
          <Button
            variant="outlined"
            className="DocUnderReviewBtn"
            onClick={reviewDocument}
          >
            Under Review
          </Button>
        )}
        {isReviewed === "approved" && (
          <img
            src={check}
            className="ApprovedCheckImg"
          />
        )}
      </div>
    </Grid>
  );
  // Customizable Area End
};
// Customizable Area Start
export default StudentsDocumentBox;
// Customizable Area End