// Customizable Area Start
import React from "react";
import {
  Grid,
  Modal,
  Typography,
  Button,
  Fade,
  Box,
  Divider,
  TextField,
} from "@material-ui/core";
import QuestionPreviewModalController from "./QuestionPreviewModalController.web";
import Backdrop from "@material-ui/core/Backdrop";
import "../TeacherAssessment.web.css";
import { withStyles } from "@material-ui/core/styles";
import { imageCheckmark, matchGroup } from "../assets";
import AudioList from "../AddQuestion/AudioList.web";
import StepperWithText from "../AddQuestion/StepperWithText.web";
import { videoThumbnail } from "./../assets";
import LineChart from "../AddQuestion//LineChart.web"
import BarChart from "../AddQuestion/BarChart.web";
import PlotlyGraph from "../AddQuestion/PlotlyGraph.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class QuestionPreviewModal extends QuestionPreviewModalController {
  // Customizable Area Start
  render() {
    const questionType = () => {
      switch (this.props.questionType) {
        case "fill in blank":
          return (
            <>
              {this.props.fillBlankType === "text_type" ? (
                <Box>
                  <Typography>{this.props.textAnswer}</Typography>
                </Box>
              ) : (
                <>
                  <Grid container spacing={2} className={this.props.classes.container_margin_left}>
                    {this.props.optionAnswer.map((data: any, index: number) => {
                      const title = String.fromCharCode(65 + index);
                      return (
                        <Grid
                          item
                          xs={11} sm={5}
                          alignItems='center'
                          className={this.props.classes.grid_margin}
                        >
                          {optionThemeStyles(data, title)}
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              )}
            </>
          );
        case "one word":
          return (
            <Grid container >
              <Grid item xs={12}>
                <Typography >
                  {this.props.textAnswer}
                </Typography>
              </Grid>
            </Grid>
          );
        case "rating scale":
          return (
            <StepperWithText
              activeStep={this.props.activeStepperStep}
              steps={this.props.stepItems}
              onChangeTextValue={(e: any) => {
                e.preventDefault();
              }}
              onRemoveStep={(e: any) => {
                e.preventDefault();
              }}
              isAnswerSection={true}
              onChangeStepperValue={(e: any) => {
                e.preventDefault();
              }}
              selectedStepperRadioValue={this.props.stepperSelectedRadioValue}
              disableRadioButton
              isEmojiType
            />
          );
        case "video":
          return (
            <div>
              {this.props.videosList?.map((videoItem: any, index: number) => (
                <React.Fragment key={videoItem?.id}>
                  <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid
                      item
                      xs={11} sm={4}
                      className={this.props.classes.grid_relative}
                    >
                      <img
                        src={videoThumbnail}
                        className="video-thumbnail-img"
                        alt="thumbnail"
                        onClick={() => this.props.selectVideo(videoItem)}
                      />
                      <video
                        id={`video-${index}`}
                        className="video-thumbnail-img"
                        src={videoItem.videoSrc}
                        controls
                        hidden
                        onLoadedData={(e: any) => {
                          if (this.props.fromUploadVideo) {
                            const videoDetails: any = document.getElementById(
                              `video-${index}`
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={11} sm={5}>
                      <Box
                        display='flex'
                        flexDirection={'column'}
                        justifyContent='center'
                      >
                        <div
                          className={`video-recording-text ${this.props.classes.subHeading_font}`}
                          style={{
                            wordBreak: 'break-all'
                          }}
                        >
                          {videoItem?.name}
                        </div>
                        <div
                          className={`video-recording-duration ${this.props.classes.subHeading_font}`}
                        >
                          {videoItem?.duration}
                        </div>
                      </Box>
                    </Grid>
                    {/* <Grid
                    item
                    xs={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                    onClick={() => this.props.removeSelectedVideo(videoItem?.id)}
                  >
                    <img
                      src={closeIconBlue}
                      className="remove-video-icon"
                    ></img>
                  </Grid> */}
                  </Grid>
                  <Divider />
                </React.Fragment>
              ))}
            </div>
          );
        case "audio":
          return (
            this.props.audioList.length > 0 &&
            this.props.audioList.map((audio: any, index: number) => {
              return <AudioList key={audio.id} audio={audio} />;
            })
          );
        case "graph_plotting_line_chart":
          return (
            <LineChart
              classes={this.props.classes}
              graph_axis_settings_attributes={this.props.graph_axis_settings_attributes}
              graph_plotting_categories_attributes={this.props.graph_plotting_categories_attributes}
              graph_properties={this.props.graph_properties}
            // isPreview={true}
            />
          );
        case "match":
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {this.props.matchQuestionList
                ?.filter((item: any) => !item._destroy)
                ?.map((data: any, index: number) => (
                  <React.Fragment key={index}>
                    {(data?.question || data?.src) && (
                      <Grid container style={{ marginBottom: 20 }}>
                        <Grid item xs={5}>
                          <div
                            style={{
                              border: "1px solid #3f526d",
                              padding: "10px 20px",
                              borderRadius: 6,
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {!data?.src ? (
                              <Typography
                                className={this.props.classes.heading_font}
                                style={{
                                  fontSize: "20px",
                                  fontWeight: 600,
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {data?.question}
                              </Typography>
                            ) : (
                              <>
                                <img
                                  src={data.src}
                                  style={{
                                    height: "50px",
                                    width: "60px",
                                    objectFit: "contain",
                                    marginRight: "20px",
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={matchGroup}
                            className="match_grp_img_preview"
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <div
                            style={{
                              border: "1px dashed #3f526d",
                              padding: "10px 20px",
                              borderRadius: 6,
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                            id={`answer-${data.correctAnswerId}`}
                          >
                            {!data?.answerSrc ? (
                              <Typography
                                id={`answer-${data.correctAnswerId}`}
                                className={this.props.classes.heading_font}
                                style={{
                                  fontSize: "20px",
                                  fontWeight: 600,
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {data?.answer}
                              </Typography>
                            ) : (
                              <>
                                <img
                                  id={`answer-${data.correctAnswerId}`}
                                  src={data.answerSrc}
                                  style={{
                                    height: "50px",
                                    width: "60px",
                                    objectFit: "contain",
                                    marginRight: "20px",
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
            </div>
          );
        case "sorting list":
          return (
            <div>
              {this.props.sortingResponseList
                ?.filter((item: any) => !item._destroy)
                ?.map((data: any, index: number) => (
                  <div
                    style={{
                      display: "flex",
                      margin: "0px 0px 20px 0px",
                      justifyContent: "center",
                    }}
                    id={`response-${data.questionId}`}
                    key={index}
                  >
                    <TextField
                      id="standard-basic"
                      placeholder={`Choice ${String.fromCharCode(65 + index)}`}
                      variant="outlined"
                      disabled
                      value={data?.question}
                      inputProps={{
                        className: `${this.props.classes.heading_font}`,
                      }}
                    />
                  </div>
                ))}
            </div>
          );
        case "bar_graph_plotting":
          return (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BarChart
                  classes={this.props.classes}
                  graph_axis_settings_attributes={this.props.graphDetails.axis}
                  graph_plotting_categories_attributes={this.props.graphDetails.categories.map((data: any) => ({
                    label: data.label,
                    //correct_answer_value: data.initial_value,
                    correct_answer_value: data.correct_answer_value,
                    bar_color: data.bar_color
                  }))}
                  graph_properties={this.props.graphDetails.setting}
                />
              </Grid>
            </Grid>
          );
        case "2x2_grid":
          return (
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginTop: '-20px' }}>
                <PlotlyGraph
                  classes={this.props.classes}
                  setting={this.props.graph_axis_settings_attributes}
                  graph_lines={this.props.graph_lines_attributes}
                />
              </Grid>
            </Grid>
          );
        case "flash_card":
          return (
            <Grid container spacing={2}>
              {
                this.props.flash_cards_list.map((card: any, index: number) => (
                  <Grid key={card.uniq_key} item xs={10} md={5}
                    style={{
                      position: "relative",
                      height: '520px',
                      margin: '0 40px 20px 25px',
                      padding: '16px 16px 18px',
                      borderRadius: '20px',
                      boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
                      border: 'solid 1px #e1e1e1',
                      backgroundColor: '#fff',
                    }}>

                    {
                      card.isImageAvailble ?
                        <div style={{
                          height: '267px',
                          margin: '0 0 20px',
                          borderRadius: '20px',
                          backgroundColor: '#ebeef8',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          textAlign: 'center',
                        }}>
                          <img src={card.flash_card_image} style={{ width: "100%", height: "100%", objectFit: "contain" }} />

                        </div>
                        :

                        <div style={{
                          height: '267px',
                          margin: '0 0 20px',
                          padding: '56px 40px 16px',
                          borderRadius: '20px',
                          backgroundColor: '#ebeef8',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          textAlign: 'center',
                        }}>

                          <Typography className={`question_text ${this.props.classes.subHeading_font}`}> No Image Found</Typography>
                        </div>
                    }

                    <div className="graph_plotting">
                      <div
                        style={{
                          width: '100%',
                          height: '52px',
                          borderRadius: '6px',
                          boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
                          backgroundColor: '#fff',
                          padding: "11px",
                          textAlign: 'center'
                        }}
                        className={this.props.classes.bodyText_font}
                      >
                        {
                          card.flash_card_hint
                        }
                      </div>
                    </div>

                    <div style={{ margin: "20px 0" }}>
                      <div
                        style={{
                          width: '100%',
                          height: '52px',
                          borderRadius: '6px',
                          boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
                          backgroundColor: '#fff',
                          padding: "11px"
                        }}
                        className={this.props.classes.bodyText_font}
                      >
                        {card.flash_card_answer}
                      </div>
                    </div>

                    <span style={{
                      opacity: '0.54',
                      fontSize: '18px',
                      fontWeight: 600,
                      color: '#3f526d',
                      width: '100%',
                      display: 'inline-block',
                      textAlign: 'center',
                      marginTop: "28px"
                    }}>
                      1/1
                    </span>
                  </Grid>
                ))

              }
            </Grid>
          );
      }
    };
    const labelQuestionType = () => {
      return (
        <>
          {this.props.selectedImageBlobUrl && (<div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 40px",
              borderRadius: 8,
              boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              minHeight: 300,
              justifyContent: "space-evenly",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <img
                src={this.props.selectedImageBlobUrl}
                className="label_question_type_image"
              ></img>
              {this.props.boxCoordinates?.length > 0 &&
                this.props.boxCoordinates
                  .filter((element: any) => !element._destroy)
                  .map((item: any, index: number) => (
                    <div
                      key={item?.uniqId || index}
                      style={{
                        position: "absolute",
                        width: "130px",
                        height: "40px",
                        backgroundColor: "#ffffff",
                        borderRadius: "8px",
                        top: `${item.top}%`,
                        left: `${item.left}%`,
                        fontSize: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "5px",
                      }}
                      className={this.props.classes.bodyText_font}
                      id={`answer-${item.correctAnswerId}`}
                    >
                      <span
                        className={this.props.classes.bodyText_font}
                        style={{
                          display: "block",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {item.answer}
                      </span>
                    </div>
                  ))}
            </div>
          </div>
          )}
        </>
      )
    }
    const optionQuestionType = () => {
      return (
        <Grid container spacing={2} style={{ marginLeft: '0px' }}>
          {this.props.optionAnswer.map((data: any, index: number) => {
            const title = String.fromCharCode(65 + index);
            return (
              <Grid
                item
                xs={11} sm={5}
                style={{ alignItems: "center", marginBottom: "16px" }}
              >
                {optionThemeStyles(data, title)}
              </Grid>
            );
          })}
        </Grid>
      );
    }
    const optionThemeStyles = (data: any, title: string) => {
      const assessmentData = localStorage.getItem("assessmentData");
      let themeName;
      if (assessmentData !== null) {
        const data = JSON.parse(assessmentData || "{}");
        if (data.assessment_theme !== null) {
          themeName = data.assessment_theme.name;
        } else {
          themeName = "Default Test";
        }
      } else {
        themeName = "Default Test";
      }
      switch (themeName) {
        case "Default Test":
          return (
            <Grid
              container
              spacing={1}
              style={{
                padding: "13px 20px",
                borderRadius: "8px",
                boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                border: `solid 1px #fff`,
                backgroundColor: "#fff",
                alignItems: "center",
              }}
            >
              <Grid item xs={2}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "8px",
                    alignItems: "center",
                    width: 44,
                    height: 44,
                    backgroundColor: `${data.color}`,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Open sans",
                      fontSize: "24px",

                      fontWeight: 600,

                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
              {data.files != undefined && data.files.length === 0 ? (
                <>
                  {data.src != undefined ? (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#3f526d",
                        paddingLeft: 10,
                        display: "flex",
                      }}
                    >
                      <img
                        src={data.src}
                        style={{
                          maxHeight: "60px",
                          width: "20%",
                          cursor: "pointer",
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#3f526d",
                        paddingLeft: 10,
                        wordBreak: "break-all",
                      }}
                    >
                      {data?.answer}
                    </Grid>
                  )}
                </>
              ) : (
                <Grid
                  item
                  xs={8}
                  style={{
                    fontFamily: "Open sans",
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#3f526d",
                    paddingLeft: 31,
                    display: "flex",
                  }}
                >
                  <img
                    src={data.src}
                    style={{
                      maxHeight: "60px",
                      width: "20%",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={2}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                {data.right_answer != undefined && data.right_answer && (
                  <Box
                    style={{
                      width: 25,
                      height: 25,
                      background: "green",
                      borderRadius: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={imageCheckmark}
                      style={{
                        width: 16,
                        height: 16,
                        objectFit: "contain",
                      }}
                    ></img>
                  </Box>
                )}
              </Grid>
            </Grid>
          );
        case "Rounded Rectangle Layout":
          return (
            <Grid
              container
              spacing={1}
              style={{
                padding: "13px 20px",
                borderRadius: "8px",
                boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                border: `solid 1px #fff`,
                backgroundColor: "#fff",
                alignItems: "center"
              }}
            >

              <Grid item xs={2}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "8px",
                    alignItems: "center",
                    width: 44,
                    height: 44,
                    backgroundColor: `${data.color}`,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Open sans",
                      fontSize: "24px",

                      fontWeight: 600,

                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
              {data.files != undefined && data.files.length === 0 ? (
                <>
                  {data.src != undefined ? (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#3f526d",
                        paddingLeft: 31,
                        display: "flex",
                      }}
                    >
                      <img
                        src={data.src}
                        style={{
                          maxHeight: "60px",
                          width: "20%",
                          cursor: "pointer",
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#3f526d",
                        paddingLeft: 31,
                        wordBreak: "break-all",
                      }}
                    >
                      {data?.answer}
                    </Grid>
                  )}
                </>
              ) : (
                <Grid
                  item
                  xs={8}
                  style={{
                    fontFamily: "Open sans",
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#3f526d",
                    paddingLeft: 31,
                    display: "flex",
                  }}
                >
                  <img
                    src={data.src}
                    style={{
                      maxHeight: "60px",
                      width: "20%",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={2}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                {data.right_answer != undefined && data.right_answer && (
                  <Box
                    style={{
                      width: 25,
                      height: 25,
                      background: "green",
                      borderRadius: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={imageCheckmark}
                      style={{
                        width: 16,
                        height: 16,
                        objectFit: "contain",
                      }}
                    ></img>
                  </Box>
                )}
              </Grid>
            </Grid>
          );
        case "Outline Rounded Rectangle Layout":
          return (
            <Grid
              container
              spacing={1}
              style={{
                padding: "13px 40px 13px 12px",
                borderRadius: "8px",
                boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                border: `solid 1px #fff`,
                backgroundColor: "#fff",
                alignItems: "center",
              }}
            >
              <Grid item xs={2}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "8px",
                    border: `solid 1px ${data.color}`,
                    backgroundColor: `#fff`,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Open sans",
                      fontSize: "24px",
                      padding: "5px",
                      fontWeight: 600,
                      color: `${data.color}`,
                      textAlign: "center",
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
              {data.files != undefined && data.files.length === 0 ? (
                <>
                  {data.src != undefined ? (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#3f526d",
                        paddingLeft: 31,
                        display: "flex",
                      }}
                    >
                      <img
                        src={data.src}
                        style={{
                          maxHeight: "60px",
                          width: "20%",
                          cursor: "pointer",
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#3f526d",
                        paddingLeft: 31,
                        wordBreak: "break-all",
                      }}
                    >
                      {data?.answer}
                    </Grid>
                  )}
                </>
              ) : (
                <Grid
                  item
                  xs={8}
                  style={{
                    fontFamily: "Open sans",
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#3f526d",
                    paddingLeft: 31,
                    display: "flex",
                  }}
                >
                  <img
                    src={data.src}
                    style={{
                      maxHeight: "60px",
                      width: "20%",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={2}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                {data.right_answer != undefined && data.right_answer && (
                  <Box
                    style={{
                      width: 25,
                      height: 25,
                      background: "green",
                      borderRadius: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={imageCheckmark}
                      style={{
                        width: 16,
                        height: 16,
                        objectFit: "contain",
                      }}
                    ></img>
                  </Box>
                )}
              </Grid>
            </Grid>
          );
        case "Circle Layout":
          return (
            <Grid
              container
              spacing={1}
              style={{
                padding: "13px 40px 13px 12px",
                borderRadius: "8px",
                boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                alignItems: "center",
              }}
            >
              <Grid item xs={2}>
                <Box
                  style={{
                    display: "inline-block",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: `${data.color}`,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Open sans",
                      fontSize: "24px",
                      padding: "5px 14px",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
              {data.files != undefined && data.files.length === 0 ? (
                <>
                  {data.src != undefined ? (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#3f526d",
                        paddingLeft: 31,
                        display: "flex",
                      }}
                    >
                      <img
                        src={data.src}
                        style={{
                          maxHeight: "60px",
                          width: "20%",
                          cursor: "pointer",
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#3f526d",
                        paddingLeft: 31,
                        wordBreak: "break-all",
                      }}
                    >
                      {data?.answer}
                    </Grid>
                  )}
                </>
              ) : (
                <Grid
                  item
                  xs={8}
                  style={{
                    fontFamily: "Open sans",
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#3f526d",
                    paddingLeft: 31,
                    display: "flex",
                  }}
                >
                  <img
                    src={data.src}
                    style={{
                      maxHeight: "60px",
                      width: "20%",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={2}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                {data.right_answer != undefined && data.right_answer && (
                  <Box
                    style={{
                      width: 25,
                      height: 25,
                      background: "green",
                      borderRadius: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={imageCheckmark}
                      style={{
                        width: 16,
                        height: 16,
                        objectFit: "contain",
                      }}
                    ></img>
                  </Box>
                )}
              </Grid>
            </Grid>
          );
        case "Outline Circle Layout":
          return (
            <Grid
              container
              spacing={1}
              style={{
                padding: "13px 40px 13px 12px",
                borderRadius: "8px",
                boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                alignItems: "center",
              }}
            >
              <Grid item xs={2}>
                <Box
                  style={{
                    display: "inline-block",
                    justifyContent: "center",
                    borderRadius: "50%",
                    border: `1px solid ${data.color}`,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Open sans",
                      fontSize: "24px",
                      padding: "5px 14px",
                      fontWeight: 600,
                      color: `${data.color}`,
                      textAlign: "center",
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
              {data.files != undefined && data.files.length === 0 ? (
                <>
                  {data.src != undefined ? (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#3f526d",
                        paddingLeft: 31,
                        display: "flex",
                      }}
                    >
                      <img
                        src={data.src}
                        style={{
                          maxHeight: "60px",
                          width: "20%",
                          cursor: "pointer",
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#3f526d",
                        paddingLeft: 31,
                        wordBreak: "break-all",
                      }}
                    >
                      {data?.answer}
                    </Grid>
                  )}
                </>
              ) : (
                <Grid
                  item
                  xs={8}
                  style={{
                    fontFamily: "Open sans",
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#3f526d",
                    paddingLeft: 31,
                    display: "flex",
                  }}
                >
                  <img
                    src={data.src}
                    style={{
                      maxHeight: "60px",
                      width: "20%",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={2}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                {data.right_answer != undefined && data.right_answer && (
                  <Box
                    style={{
                      width: 25,
                      height: 25,
                      background: "green",
                      borderRadius: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={imageCheckmark}
                      style={{
                        width: 16,
                        height: 16,
                        objectFit: "contain",
                      }}
                    ></img>
                  </Box>
                )}
              </Grid>
            </Grid>
          );
        case "Rectangle Block Layout":
          return (
            <Grid
              container
              spacing={1}
              style={{
                padding: "13px 40px 13px 12px",
                borderRadius: "8px",
                boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                border: `solid 1px ${data.color}`,
                backgroundColor: `${data.color}`,
                alignItems: "center",
              }}
            >
              <Grid item xs={2}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "8px",
                    backgroundColor: `${data.color}`,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Open sans",
                      fontSize: "24px",
                      padding: "5px 11px 6px",
                      fontWeight: 600,
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
              {data.files != undefined && data.files.length === 0 ? (
                <>
                  {data.src != undefined ? (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#fff",
                        paddingLeft: 31,
                        display: "flex",
                      }}
                    >
                      <img
                        src={data.src}
                        style={{
                          maxHeight: "60px",
                          width: "20%",
                          cursor: "pointer",
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#fff",
                        paddingLeft: 31,
                        wordBreak: "break-all",
                      }}
                    >
                      {data?.answer}
                    </Grid>
                  )}
                </>
              ) : (
                <Grid
                  item
                  xs={8}
                  style={{
                    fontFamily: "Open sans",
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#fff",
                    paddingLeft: 31,
                    display: "flex",
                  }}
                >
                  <img
                    src={data.src}
                    style={{
                      maxHeight: "60px",
                      width: "20%",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={2}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                {data.right_answer != undefined && data.right_answer && (
                  <Box
                    style={{
                      width: 25,
                      height: 25,
                      background: "green",
                      borderRadius: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={imageCheckmark}
                      style={{
                        width: 16,
                        height: 16,
                        objectFit: "contain",
                      }}
                    ></img>
                  </Box>
                )}
              </Grid>
            </Grid>
          );
        case "Outline Rectangle Block Layout":
          return (
            <Grid
              container
              spacing={1}
              style={{
                padding: "13px 40px 13px 12px",
                borderRadius: "8px",
                boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                border: `solid 1px ${data.color}`,
                backgroundColor: "#fff",
                alignItems: "center",
              }}
            >
              <Grid item xs={2}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Open sans",
                      fontSize: "24px",
                      padding: "5px 11px 6px",
                      fontWeight: 600,
                      color: `${data.color}`,
                      textAlign: "center",
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
              {data.files != undefined && data.files.length === 0 ? (
                <>
                  {data.src != undefined ? (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#3f526d",
                        paddingLeft: 31,
                        display: "flex",
                      }}
                    >
                      <img
                        src={data.src}
                        style={{
                          maxHeight: "60px",
                          width: "20%",
                          cursor: "pointer",
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={8}
                      style={{
                        fontFamily: "Open sans",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#3f526d",
                        paddingLeft: 31,
                        wordBreak: "break-all",
                      }}
                    >
                      {data?.answer}
                    </Grid>
                  )}
                </>
              ) : (
                <Grid
                  item
                  xs={8}
                  style={{
                    fontFamily: "Open sans",
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#3f526d",
                    paddingLeft: 31,
                    display: "flex",
                  }}
                >
                  <img
                    src={data.src}
                    style={{
                      maxHeight: "60px",
                      width: "20%",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={2}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                {data.right_answer != undefined && data.right_answer && (
                  <Box
                    style={{
                      width: 25,
                      height: 25,
                      background: "green",
                      borderRadius: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={imageCheckmark}
                      style={{
                        width: 16,
                        height: 16,
                        objectFit: "contain",
                      }}
                    ></img>
                  </Box>
                )}
              </Grid>
            </Grid>
          );
      }
    };
    let optionTypeQuestionList: any = ['mcq', 'pictionary', 'true false']
    let labelImageQuestionList: any = ['label_image_with_text', 'label_image_with_drag_and_drop']
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={this.props.questionPreviewModal}
          onClose={this.modalhandleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={this.props.questionPreviewModal} style={{ border: "none" }}>
            <div className="Addtask-popup preview-modal">
              <Box style={{ height: "100%" }}>
                <Typography
                  className={this.props.classes.title_font}
                  style={{
                    fontSize: 24,
                    fontWeight: 600,
                  }}
                >
                  Preview
                </Typography>
                <Box
                  style={{
                    overflowY: "scroll",
                    height: "calc(100% - 80px)",
                    overflowX: "hidden",
                  }}
                >
                  <Box
                    className="quetext"
                    style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}
                  >
                    Que.
                    <span
                      style={{
                        marginLeft: "1%",
                        marginBottom: "0",
                        fontWeight: 600,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: this.props.question,
                      }}
                      className={this.props.classes.subHeading_font}
                    ></span>
                  </Box>
                  {this.props.selectedImageBlobUrl != undefined &&
                    this.props.selectedImageBlobUrl != "" && (
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "auto",
                          width: "30%",
                        }}
                      >
                        {(this.props.questionType !==
                          "label_image_with_drag_and_drop" && this.props.questionType !==
                          "label_image_with_text") && (
                            <img
                              src={this.props.selectedImageBlobUrl}
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "contain",
                                marginBottom: "15px",
                              }}
                            ></img>
                          )}
                      </Box>
                    )}
                  <Box>{optionTypeQuestionList.includes((this.props.questionType)) ? <>{optionQuestionType()}</> : labelImageQuestionList.includes((this.props.questionType)) ? <>{labelQuestionType()}</> : <>{questionType()}</>}</Box>
                  {this.props.questionHint.length !== 0 &&
                    (this.props.questionHint[0]?.hint ||
                      this.props.questionHint[0]?.src) && (
                      <Box
                        style={{
                          marginTop: 20,
                          padding: "18px 0px 18px 30px",
                          borderRadius: 10,
                          backgroundColor: "#f9f8fd",
                        }}
                      >
                        {this.props.questionHint[0].src != undefined &&
                          this.props.questionHint[0].src != "" && (
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "auto",
                                width: "30%",
                              }}
                            >
                              <img
                                src={this.props.questionHint[0].src}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                  marginBottom: "15px",
                                }}
                              ></img>
                            </Box>
                          )}
                        <Box>
                          <span
                            style={{
                              marginLeft: "1%",
                              fontSize: 16,
                              fontWeight: 600,
                            }}
                            dangerouslySetInnerHTML={{
                              __html: this.props.questionHint[0].hint,
                            }}
                            className={this.props.classes.subHeading_font}
                          ></span>
                        </Box>
                      </Box>
                    )}
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={this.modalhandleClose}
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      letterSpacing: "0.1px",
                      padding: "13px 57px 13px 58px",
                      borderRadius: 8,
                      boxShadow: "none",
                    }}
                    className={this.props.classes.button_color}
                  >
                    ok
                  </Button>
                </Box>
              </Box>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start

export default withStyles(themeCreateStyle)(
  QuestionPreviewModal as React.ComponentType<any>
);
// Customizable Area End