Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  const urlConfig = require("../../../../framework/src/config");
  
  // Customizable Area Start
  exports.dashboardContentType = "application/json";
  exports.formDataContentType = "multipart/form-data";
  exports.dashboardApiMethodType = "GET";
  exports.dashboardApiPostMethodType = "POST";
  exports.dashboardApiPutMethodType = "PUT";
  exports.deleteSchoolMethodType = "DELETE";
  exports.dashboardURL = urlConfig.dashboardURL;
  exports.instituteURL = urlConfig.instituteURL;
  exports.userManagementURL = urlConfig.baseURL;
  exports.attendanceURL = urlConfig.attendanceURL;

  exports.periodEndPoint = "/bx_block_scheduling/school_periods";
  exports.batchesEndPoint = "/bx_block_organisationhierarchy/batches";
  
  // Customizable Area End