// Customizable Area Start
import React from "react";
import { Typography, Avatar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../../../shared/Header/src/HOCAuth.web";
import { NOTIFICATIONS_NO_DATA, NOTIFICATION_DASHBOARD_ICON } from "../../assets";
import ParentNotificationController, {
  Props,
} from "./ParentNotificationController.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import "./ParentNotification.web.css";
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class ParentNotification extends ParentNotificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { loader, notifications} = this.state;

    return (
      <>
        {loader && <Spinner spinnerModal={loader} />}
        <Grid className="parent_notification_box" container>
          <Grid item xs={12} className="center_align">
            <Avatar src={NOTIFICATION_DASHBOARD_ICON} className="notification_msg_icon" />
            <Typography
              className={`${this.props.classes.parentNotification_dashboard_text_font_family} notification_text`}
            >
              Notification
            </Typography>
          </Grid>
          {notifications?.length === 0 && (
            <>
              <Grid item xs={12} className="no_notification_img_block">
                <span className="no_notification_img_block_span">
                  <img src={NOTIFICATIONS_NO_DATA} alt="No data found" />
                </span>
              </Grid>
              <Grid item xs={12} className="no_notification_text_block">
                <span
                  className={`${
                    this.props.classes.heading_font
                  } no_notification_text`}
                >
                  No Notification Yet!
                </span>
              </Grid>
            </>
          )}

          {notifications?.length > 0 &&
            notifications?.map((notification: any,index:number)=>(
              <Grid key={notification.id} item xs={12}>
                <Typography className={`${this.props.classes.parentNotification_dashboard_notification_title} notification_view_all_text`}>
                  {notification.title}
                </Typography>
              </Grid>
            ))
          }
          {notifications?.length > 0 && (
            <Grid item xs={12}>
              <Typography className={`view_all_text ${this.props.classes.parentNotification_dashboard_notification_view_all} notification_view_all_text`}>
                VIEW ALL
              </Typography>
            </Grid>
          )}
        </Grid>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(ParentNotification, "TeacherAccount")
);
// Customizable Area End
