// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

import moment from "moment";
import { string } from "yup";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    popoverClose: any;
    filterAnchorEl: any;
    navigation: any;
    applyFilter: any;
    className: string;
    grade_id: any;
    subjectData: any;
    divisionData: any;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    closeModal: boolean;
    data: any;
    dropDownAnchorEl: any;
    startDate: any;
    endDate: any;
    start_type: boolean;
    gradeData: any;
    grade_id: any;
    divisiondropDownAnchorEl: any;
    subjectData: any;
    divisionData: any;
    className: string;
    subject_data: any;
    division_data: any;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class FilterScreenController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apigetStudentgradeId: string = "";
    apigetStudentClasslId: string = "";
    child: React.RefObject<any>;
    // Customizable Area End

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.child = React.createRef();
        console.log(this);
        console.log(this.child, "state")
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            closeModal: false,
            data: [],
            dropDownAnchorEl: '',
            startDate: '',
            endDate: '',
            start_type: false,
            gradeData: [],
            grade_id: '',
            divisiondropDownAnchorEl: null,
            subjectData: [],
            divisionData: [],
            className: '',
            subject_data: [],
            division_data: [],
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.userdata = this.userdata.bind(this);
        this.get_student_grade = this.get_student_grade.bind(this);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.get_student_grade();
        this.setState({
            className: this.props.className,
            grade_id: this.props.grade_id,
            division_data: this.props.divisionData,
            subjectData: this.props.subjectData,
            divisionData: this.props.divisionData,
        })
        this.props.subjectData.map((data: any) => {
            if (data.checked) {
                this.state.subject_data.push(data)
            }
        })
    }
    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaa", data);
        this.setState({ data: data });
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apigetStudentgradeId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        this.setState({ gradeData: responseJson.data })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }

        }
    }
    removeSubject = (event: any, id: any) => {
        event.stopPropagation();
        const { subject_data, subjectData } = this.state
        let tempresponseData = subjectData
        const find_data = subject_data.findIndex((res: any) => res.id === id)
        const subject_index = subjectData.findIndex((res: any) => res.id === id)
        tempresponseData[subject_index].checked = !tempresponseData[subject_index].checked
        this.setState({ subjectData: tempresponseData })
        if (find_data != -1) {
            subject_data.splice(subject_data[find_data], 1)
        }
    }
    removeDivision = (event: any, id: any) => {
        event.stopPropagation();
        const { division_data, divisionData } = this.state
        let tempresponseData = divisionData
        const find_data = divisionData.findIndex((res: any) => res.id === id)
        const subject_index = divisionData.findIndex((res: any) => res.id === id)
        tempresponseData[subject_index].checked = !tempresponseData[subject_index].checked
        this.setState({ divisionData: tempresponseData })
        if (find_data != -1) {
            division_data.splice(division_data[find_data], 1)
        }
    }

    dataSubjectSave = (datas: any) => {
        this.setState({ subjectData: datas, dropDownAnchorEl: null })
        const { subject_data } = this.state
        datas.map((data: any) => {
            if (data?.checked) {
                const find_data = subject_data.find((res: any) => res.id === data.id)
                if (find_data === undefined || find_data === null) {
                    subject_data.push(data)
                }
            }
            else {
                const find_data = subject_data.findIndex((res: any) => res.id === data.id)
                if (find_data != -1) {
                    subject_data.splice(subject_data[find_data], 1)
                }
            }
        })
    }
    savedivisionModal = (datas: any) => {
        console.log(datas)
        const { division_data, divisionData } = this.state
        datas.map((data: any) => {
            if (data?.checked) {
                const find_data = division_data.find((res: any) => res.id === data.id)
                if (find_data === undefined || find_data === null) {
                    division_data.push(data)
                }
                console.log(division_data)
            }
            else {
                const find_data = division_data.findIndex((res: any) => res.id === data.id)
                if (find_data != -1) {
                    division_data.splice(division_data[find_data], 1)
                }
                console.log(division_data)
            }
        })
        this.setState({ divisionData: datas, divisiondropDownAnchorEl: null })
    }
    applyfilter = (event: React.SyntheticEvent) => {
        event.preventDefault();
        this.setState({ closeModal: true })
        console.log(this.state.divisionData)
        this.props.applyFilter(this.state.grade_id, this.state.className, this.state.divisionData, this.state.subjectData)
        this.props.popoverClose(this.state.closeModal)
    }
    get_student_grade = () => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}');
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            school: school_Data?.school_id

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetStudentgradeId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.gradeEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    modalhandleClose = () => {
        this.setState({ closeModal: true })
        console.log("modal closed", this.state.closeModal)
        this.props.popoverClose(this.state.closeModal)
    }
    // Customizable Area End
}
