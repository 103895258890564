// Customizable Area Start
import React from "react";
import {
    Grid,
    Modal,
    Button,
} from "@material-ui/core";
import { warningIcon } from "../../assets";
import './CreateCustomReportCard.web.css';
// Customizable Area End

// Customizable Area Start
const deleteDiv = {
    fontFamily: "Open sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: 'center',
    color: "#3f526d",
    marginBottom: '14px',
} as const;
const deleteMes1 = {
    fontFamily: "Open sans",
    fontSize: "24px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: 'center',
    color: "#3f526d",
    margin: '31px 32px 6px 33px',
} as const;
const deleteMes2 = {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: 'center',
    color: "#959393",
    margin: '6px 53px 40px 54px',
} as const;
const cancelText = {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: 'center',
    color: "#fff",
} as const;
const deleteText = {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: 'center'
} as const;
const cancelButton = {
    margin: '0 20px 0 0',
    padding: '9px 20px 12px 22px',
    borderRadius: '3px',
    background: '#3f526d',
    color: 'white',
    textTransform: 'initial',
} as const;
const deleteButton = {
    margin: '0 0px 0 20px',
    padding: '9px 20px 12px 22px',
    borderRadius: '3px',
    backgroundColor: '#f6f6f6',
    textTransform: 'initial',
} as const;
// Customizable Area End

interface Props {
    // Customizable Area Start
    title: string;
    subTitle: string;
    paragraph: string;
    warningModal: boolean;
    onCloseModal: any;
    warningClickOk: any;
    showIcon?: boolean
    // Customizable Area End
}

// Customizable Area Start
const WarningModal = ({
    title,
    subTitle,
    paragraph,
    warningModal,
    onCloseModal,
    warningClickOk,
    showIcon
}: Props) => {
    return (
        <>
            <Modal
                open={warningModal}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid className="DeleteGradeModal">
                    {
                        showIcon &&
                        <Grid className="flex-center">
                            <img src={warningIcon} alt="warning" className="alert-img"/>
                        </Grid>
                    }
                    <div style={deleteDiv}>{title}</div>
                    <div className="DeleteGradeModalDivider"></div>
                    <div style={deleteMes1}>{subTitle}</div>
                    <div style={deleteMes2}>{paragraph}</div>
                    <Grid className="DeleteGradeActionDiv">
                        <Button variant="contained" style={cancelButton} onClick={onCloseModal}><span style={cancelText}>Cancel</span></Button>
                        <Button variant="contained" style={deleteButton} onClick={warningClickOk}><span style={deleteText}>Ok</span></Button>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
};
// Customizable Area End
export default WarningModal;
