// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    studentList: any;
    studentListMeta: any;
    queryTerm: any;
    page: number;
    rowsPerPage: number;
    showLoader: boolean;
    selectedStatus: any;
    isListSelected: boolean;
    publishBtnEnable: boolean;
    assessmentId: any;
    assessmentMetaData: any;
    allStudentList: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AssessmentEvaluationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetStudentList: string = "";
    apiPublishAssessment: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            studentList: [],
            studentListMeta: {},
            queryTerm: "",
            page: 0,
            rowsPerPage: 10,
            showLoader: false,
            selectedStatus: "",
            isListSelected: false,
            publishBtnEnable: false,
            assessmentId: "",
            assessmentMetaData: "",
            allStudentList: [],
        }

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apiGetStudentList) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        const { queryTerm, selectedStatus } = this.state;
                        if (!queryTerm && !selectedStatus) {
                            this.setState({ allStudentList: responseJson?.data }, () => this.checkPublishBtnEnable());
                        } else {
                            this.checkPublishBtnEnable();
                        }
                        this.setState({ studentList: responseJson?.data, studentListMeta: responseJson?.meta });
                        if (!this.state.assessmentMetaData) {
                            this.setState({ assessmentMetaData: responseJson?.assessment_meta_data });
                        }
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showLoader: false })
                this.parseApiCatchErrorResponse(errorReponse);
            } else if (apiRequestCallId === this.apiPublishAssessment) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        toast.success('Assessment published successfully.')
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.setState({ showLoader: false })
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        if (HISTORY.location.state?.assessmentId) {
            this.setState({ assessmentId: HISTORY.location.state?.assessmentId }, () => this.getStudentList())
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleBack = () => {
        HISTORY.goBack();
    };

    onChangeSearchTerm = (event: any) => {
        this.setState({ queryTerm: event.target.value })
    }

    navigateToDetails = (studentData: any) => {
        if (studentData?.attributes?.student && 
            studentData?.attributes?.submission_status &&
            studentData?.attributes?.evaluation_status !== "not_taken") {
            HISTORY.push({
                pathname: "/AssessmentEvaluationDetails",
                state: {
                    assessmentId: this.state.assessmentId,
                    studentDetails: {
                        id: studentData?.attributes?.student?.data?.[0]?.id,
                        name: `${studentData?.attributes?.student?.data?.[0]?.attributes?.first_name} ${studentData?.attributes?.student?.data?.[0]?.attributes?.last_name}`,
                        profile: studentData?.attributes?.student?.data?.[0]?.attributes?.avatar,
                        submitionDetails: studentData?.attributes?.submission_date ? `${moment(studentData?.attributes?.submission_date).format("DD MMM YYYY")} (${studentData?.attributes?.submitted_time})` : "-",
                    }
                },
            });
        }
    }

    handleStatusChange = (event: any) => {
        this.setState({ selectedStatus: event.target.value }, () => this.getStudentList())
    }

    getEvaluationStatus = (status: string) => {
        switch (status) {
            case "evaluation_pending":
                return "Pending"
            case "evaluation_completed":
                return "Completed"
            case "not_taken":
                return "Not Taken"
            default:
                return "-";
        }
    }

    getStudentList = () => {
        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token,
            school: school_Data?.school_id,
        };
        const { assessmentId, queryTerm, selectedStatus, page, rowsPerPage } = this.state;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetStudentList = requestMessage.messageId;

        let apiEndPoint = configJSON.getAssessmentEndPoint + `/${assessmentId}/get_evalution_student_list?page=${page + 1}&per=${rowsPerPage}`

        if (queryTerm) {
            apiEndPoint = apiEndPoint + `&search=${queryTerm}`;
        }
        if (selectedStatus) {
            apiEndPoint = apiEndPoint + `&evaluation_status=${selectedStatus}`;
        }

        this.setState({ showLoader: true })

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    publishAssessment = () => {
        const user_data = localStorage.getItem("user_data");
        const school_Data = JSON.parse(user_data || "{}");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("token"),
            school: school_Data?.school_id,
        };

        this.setState({ showLoader: true });
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiPublishAssessment = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAssessmentEndPoint + `/${this.state.assessmentId}/publish_evaluted_result`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PUT
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    checkPublishBtnEnable = () => {
        const { allStudentList  } = this.state;
        if (allStudentList?.length > 0) {
            const completedEvaluation = allStudentList?.filter((item: any) => item.attributes?.evaluation_status === "evaluation_completed" || item.attributes?.evaluation_status === "not_taken");
            if (completedEvaluation?.length === allStudentList?.length) {
                this.setState({ publishBtnEnable: true })
            } else {
                this.setState({ publishBtnEnable: false })
            }
        } else {
            this.setState({ publishBtnEnable: false })
        }
    }
    // Customizable Area End
}
