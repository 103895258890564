
// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { nounCheckSVG, nounCloseSVG } from "../assets";
import PlotlyGraph from "../AddQuestion/PlotlyGraph.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
// Customizable Area Start
// Customizable Area End
interface Props {
    // Customizable Area Start
    questionData: any;
    isTeacher: boolean;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
export const TwoByTwoGridQuestionType = ({
    questionData,
    isTeacher,
    classes,
}: Props) => {
    const [graphAxisSettingsAttributes, setGraphAxisSettingsAttributes] = useState<any>(
        {
            x_id: 0,
            x_axis_label: '',
            x_minimum_value: 0,
            x_maximum_value: 10,
            y_axis_label: '',
            y_id: 0,
            y_minimum_value: 0,
            y_maximum_value: 10,
            common_step_size: 1,
        }
    );
    const [graphLinesAttributes, setGraphLinesAttributes] = useState<any>([]);   
    const [studentGraphLinesAttributes, setStudentGraphLinesAttributes] = useState<any>([]);

    useEffect(() => {
        const graphSetting: any = {
            x_id: 0,
            x_axis_label: '',
            x_minimum_value: 0,
            x_maximum_value: 10,
            y_id: 0,
            y_axis_label: '',
            y_minimum_value: 0,
            y_maximum_value: 10,
            common_step_size: 1,
        };

        questionData?.line_graph_settings?.map((setting: any) => {
            if (setting.axis_label === 'x_axis') {
                graphSetting.x_id = setting.id;
                graphSetting.x_axis_label = setting.label;
                graphSetting.x_minimum_value = setting.minimum_value;
                graphSetting.x_maximum_value = setting.maximum_value;
            } else if (setting.axis_label === 'y_axis') {
                graphSetting.y_id = setting.id;
                graphSetting.y_axis_label = setting.label;
                graphSetting.y_minimum_value = setting.minimum_value;
                graphSetting.y_maximum_value = setting.maximum_value;
            }
            graphSetting.common_step_size = setting.step_size;
        });

        const linesAttributes = questionData?.answers?.map((item: any, index: number) => {
            return {
                id: item?.id,
                x1: item?.line_graph_answer?.[0]?.x_axis,
                y1: item?.line_graph_answer?.[0]?.y_axis,
                x2: item?.line_graph_answer?.[1]?.x_axis,
                y2: item?.line_graph_answer?.[1]?.y_axis,
                student_x1: questionData?.selected_options?.data?.attributes?.line_graph_answer?.[index]?.x1,
                student_x2: questionData?.selected_options?.data?.attributes?.line_graph_answer?.[index]?.x2,
                student_y1: questionData?.selected_options?.data?.attributes?.line_graph_answer?.[index]?.y1,
                student_y2: questionData?.selected_options?.data?.attributes?.line_graph_answer?.[index]?.y2,
                error: {
                    x1: false,
                    y1: false,
                    x2: false,
                    y2: false,
                }
            }
        })

        const studentLinesAttributes = questionData?.answers?.map((item: any, index: number) => {
            return {
                id: item?.id,
                x1: questionData?.selected_options?.data?.attributes?.line_graph_answer?.[index]?.x1,
                x2: questionData?.selected_options?.data?.attributes?.line_graph_answer?.[index]?.x2,
                y1: questionData?.selected_options?.data?.attributes?.line_graph_answer?.[index]?.y1,
                y2: questionData?.selected_options?.data?.attributes?.line_graph_answer?.[index]?.y2,
                error: {
                    x1: false,
                    y1: false,
                    x2: false,
                    y2: false,
                }
            }
        })

        setGraphAxisSettingsAttributes(graphSetting);
        setGraphLinesAttributes(linesAttributes);
        setStudentGraphLinesAttributes(studentLinesAttributes);
    }, [])

    const checkForCorrectAnswer = (teacherAnswer: any, studentAnswer: any) => {
        if (teacherAnswer === studentAnswer) {
            return <img src={nounCheckSVG} alt="check" className="right-check-icon absolute-icon" />
        } else {
            return <img src={nounCloseSVG} alt="close" className="close-red-icon absolute-icon" />
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} className="justify-center">
                <div className="view-two-by-two-box">
                    <PlotlyGraph
                        classes={classes}
                        setting={graphAxisSettingsAttributes}
                        graph_lines={studentGraphLinesAttributes}
                        is_custom={true}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    {graphLinesAttributes.map((line: any, index: number) => {
                        return (
                            <Table key={index}>
                                <TableHead>
                                    <TableRow >
                                        <TableCell className="two-by-two-head-cell"></TableCell>
                                        <TableCell className="two-by-two-head-cell">
                                            <span className={`two-by-two-text ${classes.subHeading_font}`}>
                                                Start (x1,
                                            </span>
                                        </TableCell>
                                        <TableCell className="two-by-two-head-cell">
                                            <span className={`two-by-two-text ${classes.subHeading_font}`}>
                                                y1)
                                            </span>
                                        </TableCell>
                                        <TableCell className="two-by-two-head-cell">
                                            <span className={`two-by-two-text ${classes.subHeading_font}`}>
                                                End (x2,
                                            </span>
                                        </TableCell>
                                        <TableCell className="two-by-two-head-cell">
                                            <span className={`two-by-two-text ${classes.subHeading_font}`}>
                                                y2)
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={line.id} >
                                        <TableCell className="two-by-two-answer-cell">
                                            <span className={`two-by-two-text ${classes.subHeading_font}`}>
                                                Line-{index + 1}
                                            </span>
                                        </TableCell>
                                        <TableCell className="two-by-two-answer-cell">
                                            <div className={line.student_x1 === line.x1 ? `two-by-two-answer-green ${classes.subHeading_font}` : `two-by-two-answer-red ${classes.subHeading_font}`}>
                                                {line.student_x1}
                                                {checkForCorrectAnswer(line.x1, line.student_x1)}
                                            </div>
                                        </TableCell>
                                        <TableCell className="two-by-two-answer-cell">
                                            <div className={line.student_y1 === line.y1 ? `two-by-two-answer-green ${classes.subHeading_font}` : `two-by-two-answer-red ${classes.subHeading_font}`}>
                                                {line.student_y1}
                                                {checkForCorrectAnswer(line.y1, line.student_y1)}
                                            </div>
                                        </TableCell>
                                        <TableCell className="two-by-two-answer-cell">
                                            <div className={line.student_x2 === line.x2 ? `two-by-two-answer-green ${classes.subHeading_font}` : `two-by-two-answer-red ${classes.subHeading_font}`}>
                                                {line.student_x2}
                                                {checkForCorrectAnswer(line.x2, line.student_x2)}
                                            </div>
                                        </TableCell>
                                        <TableCell className="two-by-two-answer-cell">
                                            <div className={line.student_y2 === line.y2 ? `two-by-two-answer-green ${classes.subHeading_font}` : `two-by-two-answer-red ${classes.subHeading_font}`}>
                                                {line.student_y2}
                                                {checkForCorrectAnswer(line.y2, line.student_y2)}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        )
                    }
                    )}
                </TableContainer>
            </Grid>

            <div className="correct_answer_section">
                <Grid container>
                    <Grid item xs={12} sm={6} md={8} className="correct_answer_left">
                        {!questionData?.selected_options?.data &&
                            <Typography className={`not_attemped_text ${classes.bodyText_font}`}>Not attempted</Typography>
                        }
                        <Typography className={`correct_answer_text ${classes.heading_font}`}>Correct Answer</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className="score_section">
                        <Typography className={`correct_answer_text ${classes.heading_font}`}>Score</Typography>
                        <div className="correct_answer_rectangle">
                            <Typography className="score_text">
                                {questionData?.selected_options?.data?.attributes?.score ? questionData?.selected_options?.data?.attributes?.score : 0}
                            </Typography>
                        </div>
                        <Typography className="score_text"> / {questionData?.marks}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="sorting-correct-block">
                            <div className="view-two-by-two-box-teacher">
                                <PlotlyGraph
                                    classes={classes}
                                    setting={graphAxisSettingsAttributes}
                                    graph_lines={graphLinesAttributes}
                                    is_custom={true}
                                />
                            </div>
                            <TableContainer>
                                {graphLinesAttributes.map((line: any, index: number) => {
                                    return (
                                        <Table key={index}>
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell className="two-by-two-head-cell"></TableCell>
                                                    <TableCell className="two-by-two-head-cell">
                                                        <span className={`two-by-two-text ${classes.subHeading_font}`}>
                                                            Start (x1,
                                                        </span>
                                                    </TableCell>
                                                    <TableCell className="two-by-two-head-cell">
                                                        <span className={`two-by-two-text ${classes.subHeading_font}`}>
                                                            y1)
                                                        </span>
                                                    </TableCell>
                                                    <TableCell className="two-by-two-head-cell">
                                                        <span className={`two-by-two-text ${classes.subHeading_font}`}>
                                                            End (x2,
                                                        </span>
                                                    </TableCell>
                                                    <TableCell className="two-by-two-head-cell">
                                                        <span className={`two-by-two-text ${classes.subHeading_font}`}>
                                                            y2)
                                                        </span>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow key={line.id} >
                                                    <TableCell className="two-by-two-answer-cell">
                                                        <span className={`two-by-two-text ${classes.subHeading_font}`}>
                                                            Line-{index + 1}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell className="two-by-two-answer-cell">
                                                        <div className={`two-by-two-answer ${classes.subHeading_font}`}>
                                                            {line.x1}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className="two-by-two-answer-cell">
                                                        <div className={`two-by-two-answer ${classes.subHeading_font}`}>
                                                            {line.y1}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className="two-by-two-answer-cell">
                                                        <div className={`two-by-two-answer ${classes.subHeading_font}`}>
                                                            {line.x2}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className="two-by-two-answer-cell">
                                                        <div className={`two-by-two-answer ${classes.subHeading_font}`}>
                                                            {line.y2}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    )
                                }
                                )}
                            </TableContainer>
                        </div>
                    </Grid>
                    {(!isTeacher && questionData?.selected_options?.data?.attributes?.comments) &&
                        <Grid item xs={12}>
                            <Typography className={`title_teachercomment ${classes.theme_font}`}>Teacher Comment:
                                <span className={`textlabel_teachercomment ${classes.heading_font}`} dangerouslySetInnerHTML={{
                                    __html: questionData?.selected_options?.data?.attributes?.comments,
                                }}></span></Typography>
                        </Grid>
                    }
                </Grid>
            </div>
        </Grid>
    );
};
// Customizable Area End

// Customizable Area Start

export default withStyles(themeCreateStyle)(TwoByTwoGridQuestionType);
// Customizable Area End