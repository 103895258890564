// Customizable Area Start
import React from "react";
import moment from "moment";
import {
  Box,
  Typography,
  WithStyles,
  withStyles,
  IconButton,
  // MoreVertOutlined,
} from "@material-ui/core";
import { MoreVertOutlined } from "@material-ui/icons";
// Customizable Area End

interface IProps {
  // Customizable Area Start
  classes?: any;
  birthday?: any;
  // Customizable Area End
}

const Birthday: React.FC<IProps> = ({ classes, birthday }) => {
  // Customizable Area Start
  return (
    <>
      <Box className={classes.thoughtContainer}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 5px",
          }}
        >
          <div>
            <Typography className={classes.contentFont} style={{ whiteSpace: 'nowrap', textAlign:"left" }}>
              {birthday?.attributes?.user_name}
            </Typography>
          </div>
          <div>
            <Typography
              className={classes.contentFont}
            >
              {moment(birthday?.attributes?.start_time).format("MMM Do")}
            </Typography>
          </div>
          <div>
            <Typography className={classes.contentFont}>
              {birthday?.attributes?.name}
            </Typography>
          </div>
        </Box>
      </Box>
    </>
  );
  // Customizable Area End
};
export const themeCreatStyle = ((theme: any) => ({
  // Customizable Area Start
  thoughtContainer: {
    borderRadius: "8px",
    padding: "10px",
    margin: "10px",
    backgroundColor: "#fafafb",
  },
  contentFont: {
    width: "186px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "16px",
    fontWeight: 600,
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  // Customizable Area end
}))

// Customizable Area Start
export default withStyles(themeCreatStyle)(Birthday);
// Customizable Area End
