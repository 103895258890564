// Customizable Area Start
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import { check } from './assets';
import './TeacherProfile.web.css';
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End
interface Props {
  // Customizable Area Start
  imgSource: () => any;
  name: string;
  fileName: string;
  isReviewed: any;
  imgWidth: number;
  imgHeight: number;
  isUploaded: boolean;
  uploadDocument: () => any;
  reviewDocument: () => any;
  // Customizable Area End
}

export const TeacherDocumentBox = ({
  // Customizable Area Start
  imgSource,
  name,
  fileName,
  isReviewed,
  imgWidth,
  imgHeight,
  isUploaded,
  uploadDocument,
  reviewDocument,
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  return (
    <Grid item xs={12} sm={6} md={6} lg={4}>
      <div className="ProfileDocumentMainBox"
        style={{
          backgroundColor: isReviewed === 'approved' ? '#f2f6fc' : '#fff',
        }}
      >
        <img
          src={imgSource()}
          style={{
            width: imgWidth,
            height: imgHeight,
          }}
          className="ProfileDocumentImg"
        />
        <div
          id="fileNameTextDoc"
          className="ProfileDocumentName"
        >
          {fileName === '' || fileName === null ? name : fileName}
        </div>
        {!isUploaded ? (
          <Button
            variant="outlined"
            className="DocumentUploadBtn"
            onClick={uploadDocument}
          >
            Upload
          </Button>
        ) : isReviewed === 'approved' ? (
          <Button
            variant="text"
            className="ViewDocumentBtn"
            onClick={reviewDocument}
          >
            View Document
          </Button>
        ) : isReviewed === 'rejected' ? (
          <Button
            variant="outlined"
            className="DocumentRejectedBtn"
            onClick={reviewDocument}
          >
            Rejected
          </Button>
        ) : (
          <Button
            variant="outlined"
            className="DocUnderReviewBtn"
            onClick={reviewDocument}
          >
            Under Review
          </Button>
        )}
        {isReviewed == 'approved' && (
          <img
            src={check}
            className="ApprovedCheckImg"
          />
        )}
      </div>
    </Grid>
  );
  // Customizable Area End
};

// Customizable Area Start
export default withStyles(themeCreateStyle)(
  TeacherDocumentBox as React.ComponentType<any>
);

// Customizable Area End