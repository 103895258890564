// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { List, ListItem,FormControl, InputLabel, TextField, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Grid ,Typography} from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

export default function ReportCardTwoFourFiveEdit() {
  const classes = useStyles();

  return (
    <>
    <div className='profileTitle'>
    <Button className="backBtn"><KeyboardBackspaceOutlinedIcon /> Back</Button>
    <h2>Part 2 - Assessment of Learning </h2>
    <Button className="editBtn commonBtnSecondary">Enable Edit <EditOutlinedIcon /> </Button>
    </div>
    <div className="tableContent">
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>A. English</Typography>
        </AccordionSummary>
        <AccordionDetails style={{display:"inline"}}>
        <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff", borderTopLeftRadius: "5px",borderRadius:"0px" }}>
                                English<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff" }}>
                                Evaluation 1<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                                Evaluation 2<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                            <AddOutlinedIcon  />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <Typography style={{ fontWeight: "bold" }}>Reading Skills</Typography>
                                <ListItem style={{ display: 'list-item' }}>Pronounciation</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Accuracy</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Compreshension</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <Typography style={{ fontWeight: "bold" }}>  Writing Skills</Typography>
                                <ListItem style={{ display: 'list-item' }}>Creative Writting</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>HandWritting</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Grammar</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Spellings</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                <ListItem style={{ display: 'list-item' }}>Vocabulary</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <Typography style={{ fontWeight: "bold" }}>Speaking Skills</Typography>
                                <ListItem style={{ display: 'list-item' }}>Conversation</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                <ListItem style={{ display: 'list-item' }}>Recitation</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                <Typography style={{ fontWeight: "bold" }}>Listening Skills</Typography>
                                <ListItem style={{ display: 'list-item' }}>Comprehension</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Extra Reading</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Activity Project</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                         <TableRow>
                        <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff" ,borderRadius:"0px"}}>
                                Tamil/Hindi<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff" }}>
                                Evaluation 1<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                                Evaluation 2<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                            <AddOutlinedIcon  />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <Typography style={{ fontWeight: "bold" }}>Reading Skills</Typography>
                                <ListItem style={{ display: 'list-item' }}>Pronounciation</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Accuracy</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Compreshension</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                <Typography style={{ fontWeight: "bold" }}>  Writing Skills</Typography>
                                <ListItem style={{ display: 'list-item' }}>Creative Writting</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>HandWritting</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                <ListItem style={{ display: 'list-item' }}>Grammar</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Spellings</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Vocabulary</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <Typography style={{ fontWeight: "bold" }}>  Speaking Skills</Typography>
                                <ListItem style={{ display: 'list-item' }}>Conversation</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Recitation</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <Typography style={{ fontWeight: "bold" }}>Listening Skills</Typography>
                                <ListItem style={{ display: 'list-item' }}>Comprehension</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Extra Reading</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Activity Project</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                         <TableRow>
                        <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px"}}>
                                Aspects<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff" }}>
                                Evaluation 1<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                                Evaluation 2<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                            <AddOutlinedIcon  />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                <ListItem style={{ display: 'list-item' }}>Concept</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                <ListItem style={{ display: 'list-item' }}>Activity</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Tables</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Mental Ability</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                         <TableRow>
                        <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px"}}>
                                Aspects<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff" }}>
                                Evaluation 1<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff" }}>
                                Evaluation 2<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff", borderRadius: "0px" }}>
                                Evaluation 3<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                            <AddOutlinedIcon  />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Enviromental Sensitivity</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                <ListItem style={{ display: 'list-item' }}>Activity/Project</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Group Discussion</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Written Work</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                             <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                         <TableRow>
                        <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px"}}>
                                Games<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff" }}>
                                Evaluation 1<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                                Evaluation 2<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                            <AddOutlinedIcon  />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Skills</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Aptitude</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                         <TableRow>
                        <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px"}}>
                                Games<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff" }}>
                                Evaluation 1<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff" ,borderRadius:"0px"}}>
                                Evaluation 2<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                            <AddOutlinedIcon  />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Enthusiasm</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                <ListItem style={{ display: 'list-item' }}>Discipline</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Team Spirit</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Talent</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                         <TableRow>
                        <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                                Art/Craft<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff" }}>
                                Evaluation 1<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                                Evaluation 2<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                            <AddOutlinedIcon  />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Interest</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Creativity</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Skills</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                         <TableRow>
                        <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                                Music / Dance<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff" }}>
                                Evaluation 1<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                                Evaluation 2<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                            <AddOutlinedIcon  />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2" ,borderRadius:"0px"}}>
                                <ListItem style={{ display: 'list-item' }}>Interest</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Rhythm / Steps</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Melody / Themes</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                         <TableRow>
                        <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px"}}>
                                Aspects<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff" }}>
                                Evaluation 1<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                                Evaluation 2<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                            <AddOutlinedIcon  />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Courteousness</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Confidence</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Caring of Belongings</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Neatness</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Regularity and Punctuality</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Initiatives</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Self-Control</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Respects for Other's Property</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Sharing and Caring</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                                Aspects<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff" }}>
                                Evaluation 1<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                                Evaluation 2<EditOutlinedIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 600, backgroundColor: "#ded9ff",borderRadius:"0px" }}>
                            <AddOutlinedIcon  />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Height (Cms.)</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9",borderRadius:"0px" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2",borderRadius:"0px" }}>
                                <ListItem style={{ display: 'list-item' }}>Weight (kg.)</ListItem>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" }}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9" ,borderRadius:"0px"}}>
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell style={{ border: "1px solid #D9D9D9", backgroundColor: "#F2F2F2", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                            <TableCell style={{ border: "1px solid #D9D9D9", textAlign: "center",borderRadius:"0px" }}>
                                <AddOutlinedIcon style={{ color: "#D9D9D9" }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
        </AccordionDetails>
      </Accordion>


  
    </div>
    </div>
    </>
  );
}
// Customizable Area End
