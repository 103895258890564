// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
export const numReg = /^\d+$/;
// Customizable Area End


export interface Props {
    // Customizable Area Start
    createPeriodDialog: any;
    handleDialogClose: any;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    loading: boolean;
    dialogOpen: any;
    loadingSpinner: boolean;
    batch: any;
    batcherror: boolean;
    subjectDetails: any;
    class: any;
    classerror: boolean;
    classList: any;
    subjectList: any;
    batchList: any;
    isEdit: boolean;
    selectedSubectId: any;
    timetableDialog: boolean;
    schoolZoomPaidAccount:boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AutomaticClassPeriodController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apigetStudentClasslId: string = "";
    apigetClasslId: any;
    apigetSubjectById: any;
    getBatchListId: any;
    getClassesListId: any;
    addPeriodApiId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {

            loading: false,
            dialogOpen: false,
            loadingSpinner: false,
            batch: '',
            batcherror: false,
            class: '',
            classerror: false,
            classList: [],
            subjectList: [],
            batchList: [],
            isEdit: false,
            subjectDetails: [{
                subject: '',
                subjecterror: false,
                lengthh: '',
                lengtherror: false,
                priority: '',
                priorityerror: false,
                perDay: '',
                perDayerror: false,
                name:"",
                isOnline:false
            }],
            selectedSubectId: [],
            timetableDialog: false,
            schoolZoomPaidAccount:false,


        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const school_data = localStorage.getItem("user_data");
        const schoolData = JSON.parse(school_data || "{}");
        this.setState({schoolZoomPaidAccount:schoolData.meeting_paid_account})
        this.get_classes_list()
        this.get_batch_list()
    }

    submitPeriod = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const school_data = localStorage.getItem("user_data");
        const schoolData = JSON.parse(school_data || "{}");
        const adminId = localStorage.getItem("id")
        let allow: boolean = true
        let subjectData1 = this.state.subjectDetails

        subjectData1.map((_data: any) => {
            if (_data.subject === "" || _data.lengthh === "" || _data.priority === "" || _data.perDay === "") {
                if (_data.subject === "") {
                    _data.subjecterror = true
                }
                if (_data.lengthh === "") {
                    _data.lengtherror = true
                } if (_data.priority === "") {
                    _data.priorityerror = true
                } if (_data.perDay === "") {
                    _data.perDayerror = true
                }
                allow = false
            }
        })
        this.setState({ subjectDetails: subjectData1 })
        if (this.state.batch === "" || this.state.class == "") {
            if (this.state.batch === "") {
                this.setState({ batcherror: true })
            } if (this.state.class === "") {
                this.setState({ classerror: true })
            }
            allow = false
            return false;
        }
        console.log(allow)
        if (allow) {
            this.setState({ timetableDialog: true })
        }
    }
    submitForm = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const school_data = localStorage.getItem("user_data");
        const schoolData = JSON.parse(school_data || "{}");
        const adminId = localStorage.getItem("id")
        this.setState({ loadingSpinner: true, timetableDialog: false })
       
        let example ={}
        let subjectDataArr :any =[]
        this.state.subjectDetails.map((_data: any) => {
            let subjectName: any = this.state.subjectList.filter((_subject: any) => _subject.id == _data.subject)

            let key : string = subjectName[0]?.attributes?.subject_name
            
            subjectDataArr.push({
                [key]: {
                    length: parseInt(_data.lengthh),
                    priority: parseInt(_data.priority),
                    max_per_day: parseInt(_data.perDay),
                    name:_data.name ?_data.name :"" ,
                    is_online:_data.isOnline?_data.isOnline:false
                    
                },
            })
        })
      
        const data = {
            "school_id": parseInt(schoolData.school_id),
            "class_id": parseInt(this.state.class),
            "batch_id": parseInt(this.state.batch),
            // "subjects": [example],
            "subjects": subjectDataArr,

           

        }


        const header = {
            "Content-Type": configJSON.dashboardContentType,
            token: localStorage.getItem("token")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addPeriodApiId = requestMessage.messageId;

        const httpBody = data;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addPeriodEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboardApiPostMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );

        console.log("REQUEST: ", requestMessage);

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    get_classes_list = () => {
        this.setState({ loadingSpinner: true });
        const user_data = localStorage.getItem("user_data");
        const school_Data = JSON.parse(user_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboardContentType,
            token: localStorage.getItem("token"),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getClassesListId = requestMessage.messageId;
        let apiEndPoint =
            configJSON.classesListEndPoint + `?page=1&per=1000&school_id=${school_Data.school_id}`;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboardApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    get_batch_list = () => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.dashboardContentType,
            token: localStorage.getItem("token"),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBatchListId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.batchesEndPoint + `?school_id=${school_Data.school_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboardApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    get_subject = (id: any) => {
        const header = {
            "Content-Type": configJSON.dashboardContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetSubjectById = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getSubjectEndPoint + `?class_id=${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboardApiMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({ loadingSpinner: true })
    }

    handleSelectBatchChange = (event:any) => {
        if (event.target.value !== "") {
            this.setState({ batcherror: false });
        }
        this.setState({
            batch: event.target.value,
        });
    }
    handleOnBlurBatchChange = () => {
        if (this.state.batch === "" ||this.state.batch.length === 0) {
            this.setState({ batcherror: true });
        } else if (this.state.batch.length != "") {
            this.setState({ batcherror: false });
        } else {
            this.setState({ batcherror: false });
        }
    }
    handleDeleteSubject = (index:number) => {
        let classData = this.state.subjectDetails;
        classData.splice(index, 1);
        this.setState({ subjectDetails: classData})
    }
    handleSelectSubjectChange = (e:any, index:number) => {
        let data = this.state.subjectDetails
        if (e.target.value != "") {
            data[index].subjecterror = false
        } else {
            data[index].subjecterror = true
        }

        data[index].subject = e.target.value
        this.setState({ subjectDetails: data });
    }
    handleOnBlurSubjectChange = (_data:any, index:number) => {
        let data = this.state.subjectDetails
        if (_data.subject === "" ||_data.subject.length === 0) {
            data[index].subjecterror = true
        } else if (_data.subject != "") {
            data[index].subjecterror = false
        } else {
            data[index].subjecterror = false
        }
        this.setState({ subjectDetails: data }, () => {
            let subjectData: any = []
            this.state.subjectDetails.map((_data: any) => {
                subjectData.push(_data.subject)
            })
            this.setState({ selectedSubectId: subjectData })
        });
    }
    handleSelectLengthhChange = (event:any, index:number) => {
        const value = event.target.value.trim();
        if (numReg.test(value)) {
            let data = this.state.subjectDetails
            data[index].lengthh = value
            if (value === "" || value.length === 0) {
                data[index].lengtherror = true
            } else {
                data[index].lengtherror = false
            }
            this.setState({ subjectDetails: data });
        }    
    }
    handleOnBlurLengthhChange = (_data:any, index:number) => {
        let data = this.state.subjectDetails
        if (
            _data.lengthh === "" ||
            _data.lengthh.length === 0
        ) {
            data[index].lengtherror = true
        } else if (_data.lengthh.length != "") {
            data[index].lengtherror = false
        } else {
            data[index].lengtherror = false
        }
        this.setState({ subjectDetails: data });
    }
    handleSelectPriorityChange = (event:any, index:number) => {
        let data = this.state.subjectDetails
        if (event.target.value != "") {
            data[index].priorityerror = false
        } else {
            data[index].priorityerror = true
        }

        data[index].priority = event.target.value
        this.setState({ subjectDetails: data });
    }
    handleOnBlurPriorityChange = (_data:any, index:number) => {
        let data = this.state.subjectDetails
        if (
            _data.priority === "" ||
            _data.priority.length === 0
        ) {
            data[index].priorityerror = true
        } else if (_data.priorityerror.length != "") {
            data[index].priorityerror = false
        } else {
            data[index].priorityerror = false
        }
        this.setState({ subjectDetails: data });
    }
    handleSelectPerDayChange = (event:any, index:number) => {
        let data = this.state.subjectDetails
        if (event.target.value != "") {
            data[index].perDayerror = false
        } else {
            data[index].perDayerror = true
        }

        data[index].perDay = event.target.value
        this.setState({ subjectDetails: data });
    }
    handleOnBlurPerDayChange = (_data:any, index:number) => {
        let data = this.state.subjectDetails
        if (
            _data.perDay === "" ||
            _data.perDay.length === 0
        ) {
            data[index].perDayerror = true
        } else if (_data.perDayerror.length != "") {
            data[index].perDayerror = false
        } else {
            data[index].perDayerror = false
        }
        this.setState({ subjectDetails: data });
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if (apiRequestCallId === this.apigetClasslId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {

                        console.log(responseJson)

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
            else if (apiRequestCallId === this.getClassesListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ classList: responseJson?.data, loadingSpinner: false })

                    } else {
                        this.parseApiErrorResponse(responseJson);
                        this.setState({ loadingSpinner: false })
                    }
                } else {
                    this.setState({ loadingSpinner: false })
                }

            }
            else if (apiRequestCallId === this.getBatchListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ batchList: responseJson?.data, loadingSpinner: false })

                    } else {
                        this.parseApiErrorResponse(responseJson);
                        this.setState({ loadingSpinner: false })
                    }
                } else {
                    this.setState({ loadingSpinner: false })
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apigetSubjectById) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ subjectList: responseJson?.data, loadingSpinner: false })

                    } else {
                        this.parseApiErrorResponse(responseJson);
                        this.setState({ loadingSpinner: false })
                    }
                } else {
                    this.setState({ loadingSpinner: false })
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.addPeriodApiId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ loadingSpinner: false })
                        console.log(responseJson)
                        let key: any = Object.keys(responseJson)[0]
                        if (key === "message") {
                            toast.error(responseJson.message)
                        } else {
                            toast.success(responseJson.success_message)
                            this.props.handleDialogClose()
                        }
                       

                    } else {

                        this.parseApiErrorResponse(responseJson);
                        this.setState({ loadingSpinner: false })
                    }
                } else {
                    this.setState({ loadingSpinner: false })
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
}
