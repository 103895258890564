import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";

//Assembler generated adapters start
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to 21KSchoolPhase1!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'Dashboard'}  onPress={() => navigation.navigate("Dashboard")} />
<CustomTextItem content={'EmailAccountRegistration'}  onPress={() => navigation.navigate("EmailAccountRegistration")} />
<CustomTextItem content={'CountryCodeSelector'}  onPress={() => navigation.navigate("CountryCodeSelector")} />
<CustomTextItem content={'SocialMediaAccountRegistrationScreen'}  onPress={() => navigation.navigate("SocialMediaAccountRegistrationScreen")} />
<CustomTextItem content={'social-media-account'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'PhoneNumberInput'}  onPress={() => navigation.navigate("PhoneNumberInput")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'Ordermanagement'}  onPress={() => navigation.navigate("Ordermanagement")} />
<CustomTextItem content={'EmailAccountLoginBlock'}  onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
<CustomTextItem content={'ForgotPassword'}  onPress={() => navigation.navigate("ForgotPassword")} />
<CustomTextItem content={'Categoriessubcategories'}  onPress={() => navigation.navigate("Categoriessubcategories")} />
<CustomTextItem content={'UserProfileBasicBlock'}  onPress={() => navigation.navigate("UserProfileBasicBlock")} />
<CustomTextItem content={'Filteritems'}  onPress={() => navigation.navigate("Filteritems")} />
<CustomTextItem content={'Chat'}  onPress={() => navigation.navigate("Chat")} />
<CustomTextItem content={'CustomisedOrderStatus'}  onPress={() => navigation.navigate("CustomisedOrderStatus")} />
<CustomTextItem content={'GroupVideoCall'}  onPress={() => navigation.navigate("GroupVideoCall")} />
<CustomTextItem content={'InventoryManagement'}  onPress={() => navigation.navigate("InventoryManagement")} />
<CustomTextItem content={'PushNotifications'}  onPress={() => navigation.navigate("PushNotifications")} />
<CustomTextItem content={'QuestionBank'}  onPress={() => navigation.navigate("QuestionBank")} />
<CustomTextItem content={'Videos'}  onPress={() => navigation.navigate("Videos")} />
<CustomTextItem content={'ScreenSharing'}  onPress={() => navigation.navigate("ScreenSharing")} />
<CustomTextItem content={'GraphicalCharts'}  onPress={() => navigation.navigate("GraphicalCharts")} />
<CustomTextItem content={'OrderDetailView'}  onPress={() => navigation.navigate("OrderDetailView")} />
<CustomTextItem content={'ItemAvailability'}  onPress={() => navigation.navigate("ItemAvailability")} />
<CustomTextItem content={'SingleSignonsso'}  onPress={() => navigation.navigate("SingleSignonsso")} />
<CustomTextItem content={'GoogleCalendarSync'}  onPress={() => navigation.navigate("GoogleCalendarSync")} />
<CustomTextItem content={'ShoppingCart'}  onPress={() => navigation.navigate("ShoppingCart")} />
<CustomTextItem content={'AssessmentTest'}  onPress={() => navigation.navigate("AssessmentTest")} />
<CustomTextItem content={'RolesPermissions'}  onPress={() => navigation.navigate("RolesPermissions")} />
<CustomTextItem content={'PeopleManagement'}  onPress={() => navigation.navigate("PeopleManagement")} />
<CustomTextItem content={'LiveChatSummary'}  onPress={() => navigation.navigate("LiveChatSummary")} />
<CustomTextItem content={'SummaryCard'}  onPress={() => navigation.navigate("SummaryCard")} />
<CustomTextItem content={'LiveStreaming'}  onPress={() => navigation.navigate("LiveStreaming")} />
<CustomTextItem content={'AddressManagement'}  onPress={() => navigation.navigate("AddressManagement")} />
<CustomTextItem content={'BiometricIntegrationForAttendence'}  onPress={() => navigation.navigate("BiometricIntegrationForAttendence")} />
<CustomTextItem content={'EmotionAnalysisIntegration'}  onPress={() => navigation.navigate("EmotionAnalysisIntegration")} />
<CustomTextItem content={'WhiteBoardIntegration'}  onPress={() => navigation.navigate("WhiteBoardIntegration")} />
<CustomTextItem content={'BiometricAuthorization'}  onPress={() => navigation.navigate("BiometricAuthorization")} />
<CustomTextItem content={'RecordingOfTestTaker'}  onPress={() => navigation.navigate("RecordingOfTestTaker")} />
<CustomTextItem content={'SurroundingMonitoringmultiplePersonPresenceByImageProcessing'}  onPress={() => navigation.navigate("SurroundingMonitoringmultiplePersonPresenceByImageProcessing")} />
<CustomTextItem content={'SoundProcessing'}  onPress={() => navigation.navigate("SoundProcessing")} />
<CustomTextItem content={'ImageCapture'}  onPress={() => navigation.navigate("ImageCapture")} />
<CustomTextItem content={'EmailIntegration'}  onPress={() => navigation.navigate("EmailIntegration")} />
<CustomTextItem content={'AdvancedSearch'}  onPress={() => navigation.navigate("AdvancedSearch")} />
<CustomTextItem content={'Analytics'}  onPress={() => navigation.navigate("Analytics")} />
<CustomTextItem content={'Scheduling'}  onPress={() => navigation.navigate("Scheduling")} />
<CustomTextItem content={'Notifications'}  onPress={() => navigation.navigate("Notifications")} />
<CustomTextItem content={'BulkUploading'}  onPress={() => navigation.navigate("BulkUploading")} />
<CustomTextItem content={'TaskAllocator'}  onPress={() => navigation.navigate("TaskAllocator")} />
<CustomTextItem content={'TaskList'}  onPress={() => navigation.navigate("TaskList")} />
<CustomTextItem content={'Calendar'}  onPress={() => navigation.navigate("Calendar")} />
<CustomTextItem content={'CallRecording'}  onPress={() => navigation.navigate("CallRecording")} />
<CustomTextItem content={'CameraAccess'}  onPress={() => navigation.navigate("CameraAccess")} />
<CustomTextItem content={'VideoBackgrounds'}  onPress={() => navigation.navigate("VideoBackgrounds")} />
<CustomTextItem content={'VideoEmbeddingYoutube'}  onPress={() => navigation.navigate("VideoEmbeddingYoutube")} />
<CustomTextItem content={'VideoLibrary'}  onPress={() => navigation.navigate("VideoLibrary")} />
<CustomTextItem content={'CustomForm'}  onPress={() => navigation.navigate("CustomForm")} />
<CustomTextItem content={'DocumentDistribution'}  onPress={() => navigation.navigate("DocumentDistribution")} />
<CustomTextItem content={'DocumentOpener'}  onPress={() => navigation.navigate("DocumentOpener")} />
<CustomTextItem content={'EmailNotifications'}  onPress={() => navigation.navigate("EmailNotifications")} />
<CustomTextItem content={'PortfolioManagement'}  onPress={() => navigation.navigate("PortfolioManagement")} />
<CustomTextItem content={'TimesheetManagement'}  onPress={() => navigation.navigate("TimesheetManagement")} />
<CustomTextItem content={'ProductRecommendationEngine'}  onPress={() => navigation.navigate("ProductRecommendationEngine")} />
<CustomTextItem content={'FileAttachment'}  onPress={() => navigation.navigate("FileAttachment")} />
<CustomTextItem content={'CarouselDisplay0'}  onPress={() => navigation.navigate("CarouselDisplay0")} />
<CustomTextItem content={'TabletSupport'}  onPress={() => navigation.navigate("TabletSupport")} />
<CustomTextItem content={'PerformanceTracker'}  onPress={() => navigation.navigate("PerformanceTracker")} />
<CustomTextItem content={'AccountScoreranking'}  onPress={() => navigation.navigate("AccountScoreranking")} />
<CustomTextItem content={'OfflineBrowsing'}  onPress={() => navigation.navigate("OfflineBrowsing")} />
<CustomTextItem content={'PrivacySettings'}  onPress={() => navigation.navigate("PrivacySettings")} />
<CustomTextItem content={'HelpCentre'}  onPress={() => navigation.navigate("HelpCentre")} />
<CustomTextItem content={'DynamicContent'}  onPress={() => navigation.navigate("DynamicContent")} />
<CustomTextItem content={'PledgeTracking'}  onPress={() => navigation.navigate("PledgeTracking")} />
<CustomTextItem content={'CertificationTracking'}  onPress={() => navigation.navigate("CertificationTracking")} />
<CustomTextItem content={'Gamification'}  onPress={() => navigation.navigate("Gamification")} />
<CustomTextItem content={'GoalManagement'}  onPress={() => navigation.navigate("GoalManagement")} />
<CustomTextItem content={'LogoDesign'}  onPress={() => navigation.navigate("LogoDesign")} />
<CustomTextItem content={'Gallery'}  onPress={() => navigation.navigate("Gallery")} />
<CustomTextItem content={'MatchAlgorithm'}  onPress={() => navigation.navigate("MatchAlgorithm")} />
<CustomTextItem content={'Leaderboard'}  onPress={() => navigation.navigate("Leaderboard")} />
<CustomTextItem content={'Surveys'}  onPress={() => navigation.navigate("Surveys")} />
<CustomTextItem content={'Annotations'}  onPress={() => navigation.navigate("Annotations")} />
<CustomTextItem content={'Print'}  onPress={() => navigation.navigate("Print")} />
<CustomTextItem content={'DataImportexportcsv'}  onPress={() => navigation.navigate("DataImportexportcsv")} />
<CustomTextItem content={'Turni1n'}  onPress={() => navigation.navigate("Turni1n")} />
<CustomTextItem content={'Unicheck'}  onPress={() => navigation.navigate("Unicheck")} />
<CustomTextItem content={'ReviewAndApproval'}  onPress={() => navigation.navigate("ReviewAndApproval")} />
<CustomTextItem content={'Feedback'}  onPress={() => navigation.navigate("Feedback")} />
<CustomTextItem content={'UserAvatar'}  onPress={() => navigation.navigate("UserAvatar")} />
<CustomTextItem content={'DragDropInterface'}  onPress={() => navigation.navigate("DragDropInterface")} />
<CustomTextItem content={'AnimationsAndTransition'}  onPress={() => navigation.navigate("AnimationsAndTransition")} />
<CustomTextItem content={'Subtitles'}  onPress={() => navigation.navigate("Subtitles")} />
<CustomTextItem content={'VideoAutoplay'}  onPress={() => navigation.navigate("VideoAutoplay")} />
<CustomTextItem content={'VideoCall'}  onPress={() => navigation.navigate("VideoCall")} />
<CustomTextItem content={'VideoEditingTools'}  onPress={() => navigation.navigate("VideoEditingTools")} />
<CustomTextItem content={'VideoManagement'}  onPress={() => navigation.navigate("VideoManagement")} />
<CustomTextItem content={'VideoTrimming'}  onPress={() => navigation.navigate("VideoTrimming")} />
<CustomTextItem content={'Translation'}  onPress={() => navigation.navigate("Translation")} />
<CustomTextItem content={'WhiteboardCollaboration'}  onPress={() => navigation.navigate("WhiteboardCollaboration")} />
<CustomTextItem content={'BlockUsers'}  onPress={() => navigation.navigate("BlockUsers")} />
<CustomTextItem content={'MuteunmuteChat'}  onPress={() => navigation.navigate("MuteunmuteChat")} />
<CustomTextItem content={'DisplaySettings'}  onPress={() => navigation.navigate("DisplaySettings")} />
<CustomTextItem content={'Themes'}  onPress={() => navigation.navigate("Themes")} />
<CustomTextItem content={'CustomHeader'}  onPress={() => navigation.navigate("CustomHeader")} />
<CustomTextItem content={'Chatbot'}  onPress={() => navigation.navigate("Chatbot")} />
<CustomTextItem content={'CustomizableTerminology'}  onPress={() => navigation.navigate("CustomizableTerminology")} />
<CustomTextItem content={'ElasticSearch'}  onPress={() => navigation.navigate("ElasticSearch")} />
<CustomTextItem content={'LanguageOptions'}  onPress={() => navigation.navigate("LanguageOptions")} />
<CustomTextItem content={'CustomizablePortalIntegration'}  onPress={() => navigation.navigate("CustomizablePortalIntegration")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;