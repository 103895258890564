// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
import { toast } from "react-toastify";
import { HISTORY } from "../../../components/src/comman";
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  themeDetails: any;
  selectedTheme: any;
  customThemeDetails: any;
  loadingSpinner: boolean;
  alertMessage: any;
  alertModal: boolean;
  school_id: string;
  selected_theme_id: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ThemesAndFontsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  applyThemeApiId: any;
  getThemeApiId: any;
  // Customizable Area End


  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      themeDetails: [],
      loadingSpinner: false,
      customThemeDetails: [],
      selectedTheme: {},
      alertMessage: "Theme is required.",
      alertModal: false,
      school_id: '',
      selected_theme_id: ''
    };
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  async componentDidMount() {
    // Customizable Area Start
    this.getTheme();
    // Customizable Area End
  }

  // Customizable Area Start
  onApplyTheme = () => {
    const id = localStorage.getItem("schoolId")
    const themeId = localStorage.getItem("customThemeID")
    if (Object.keys(this.state.selectedTheme).length === 0) {
      this.setState({ alertModal: true })
      return false
    }
    else {
      const data = {
        "theme_type": this.state.selectedTheme.attributes.theme_type,
        "school_id": id,
        "theme_id": this.state.selectedTheme.id != undefined ? this.state.selectedTheme.id : themeId,
      }

      this.setState({ loadingSpinner: true })
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.applyThemeApiId = requestMessage.messageId;

      const httpBody = data;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.applyThemeEndPoint + `/theme_apply`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeEditDetail
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.instituteURL
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  }
  getTheme = () => {
    this.setState({ loadingSpinner: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getThemeApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getThemeEndPoint + `?school_id=${localStorage.getItem("schoolId")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  onCustomClick = (event: any, index: any) => {
    event.stopPropagation()

    localStorage.setItem("customThemeID", index.id)
    this.props.navigation.navigate(`CustomThemes`)
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getThemeApiId) {
        this.setState({ loadingSpinner: false })
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              themeDetails: responseJson.default_theme.data,
              customThemeDetails: responseJson.custom_theme.data,
              loadingSpinner: false
            })
            const selectedThemeIndex = responseJson.default_theme.data.findIndex((res: any) => res.attributes.primary_color === '#253873ff')
            this.setState({
              selectedTheme: responseJson.default_theme.data[selectedThemeIndex]
            })
            localStorage.removeItem("school_data")
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.applyThemeApiId) {
        if (responseJson != null) {
          this.setState({ loadingSpinner: false })
          if (!responseJson.errors) {
            toast.success("Theme applied successfully.")
            HISTORY.push('/OnBoardingSchoolDeatils')

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }



    }
  }
  // Customizable Area End
}
// Customizable Area Start
function id(id: any) {
  throw new Error("Function not implemented.");
}
// Customizable Area End

