// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const UserAccountsIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M32 16c0-8.83-7.17-16-16-16S0 7.17 0 16s7.17 16 16 16 16-7.17 16-16zM16 29.61C8.506 29.61 2.39 23.494 2.39 16S8.506 2.39 16 2.39 29.61 8.506 29.61 16 23.494 29.61 16 29.61zm0-14.906a4.425 4.425 0 0 0 4.415-4.415A4.4 4.4 0 0 0 16 5.874a4.425 4.425 0 0 0-4.415 4.415A4.425 4.425 0 0 0 16 14.704zm0-6.44c1.094 0 2.025.89 2.025 2.025A2.031 2.031 0 0 1 16 12.314a2.006 2.006 0 0 1-2.025-2.025c0-1.134.931-2.026 2.025-2.026zm5.914 16.121c-.77-6.684-4.658-6.684-5.914-6.684-1.256 0-5.144 0-5.914 6.684l-2.39-.284c.648-5.67 3.605-8.83 8.304-8.83s7.656 3.16 8.304 8.83l-2.39.284z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default UserAccountsIcon;
// Customizable Area End