// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  subjectsDetails: any;
  loadingSpinner: boolean;
  boardName: any;
  alertModal: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SubjectsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postLoginApiId: any;
  postPresignID: any;
  emailReg: RegExp;
  submitSubjectApiId: any;
  getSchoolApiId: any;
  getSubjectApiId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      subjectsDetails: [],
      loadingSpinner: false,
      boardName: '',
      alertModal: false
    };
    this.emailReg = new RegExp("");
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  async componentDidMount() {
    // Customizable Area Start
    this.getSubject()
    this.getSchoolDetails()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getSchoolApiId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ boardName: responseJson.data.attributes.board?.name, loadingSpinner: false })

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.submitSubjectApiId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ loadingSpinner: false })
            this.props.navigation.navigate("ThemesAndFonts")
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.getSubjectApiId) {
        this.setState({ loadingSpinner: false })
        if (responseJson != null) {
          if (!responseJson.errors) {
            const arr: any = [];
            responseJson.data.map((_data: any) => {
              let data: any = {
                id: _data.id,
                selectedSubject: false,
                subjectName: _data.attributes.subject_name,
                error: false,
                type: "admin"

              }
              arr.push(data)
            })
            this.setState({ subjectsDetails: arr, loadingSpinner: false })

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }

    }
    // Customizable Area End
  }
  // Customizable Area Start
  getSchoolDetails = () => {
    this.setState({ loadingSpinner: true })
    const id = localStorage.getItem("schoolId")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSchoolApiId = requestMessage.messageId;

    const httpBody = {
    };


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.schoolDetailsEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getSubject = () => {
    this.setState({ loadingSpinner: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubjectApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goNext = (event: any) => {
    event.preventDefault();
    let apicall: boolean = true;
    let count: number = 0
    const arr: any = this.state.subjectsDetails
    arr.map((_data: any, index: any) => {
      if (_data.selectedSubject && _data.subjectName.trim().length === 0) {
        _data.error = true;
        apicall = false

      }
      if (_data.selectedSubject) {
        count++

      }
    })
    this.setState({ subjectsDetails: arr })
    if (count === 0) {

      this.setState({ alertModal: true })
    }
    if (apicall && count !== 0) {
      const subjectdata: any = []
      const newSubjectdata: any = []
      arr.map((_data: any, index: any) => {
        if (_data.selectedSubject && _data.type === "admin") {
          subjectdata.push(_data.id)
        }
        if (_data.selectedSubject && _data.type === "new") {
          newSubjectdata.push(
            _data.subjectName,

          )
        }
      })

      const data = {
        "school_id": localStorage.getItem("schoolId"),
        "subjects": subjectdata,
        "new_subjects": newSubjectdata
      }


      this.setState({ loadingSpinner: true })
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.submitSubjectApiId = requestMessage.messageId;

      const httpBody = data;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postSubjectEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestbaseURLMessage),
        configJSON.instituteURL
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  // Customizable Area End

}
