// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";

import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    addDialog: any;
    handleDialogClose: any;
    isEdit: any;
    id: any;
    isPending: boolean;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    loading: boolean;
    dialogOpen: any;
    firstName: any;
    firstNameError: boolean;
    firstNameErrorMes: any;
    lastName: any;
    lastNameError: boolean;
    lastNameErrorMes: any;
    DOB: any;
    DOBError: boolean;
    DOBErrorMes: any;
    gender: any;
    genderError: boolean;
    genderErrorMes: any;
    phoneNo: any;
    phoneNoError: boolean;
    phoneNoErrorMes: any;
    emailId: any;
    emailIdError: boolean;
    emailIdErrorMes: any;
    classs: any;
    classsError: boolean;
    classsErrorMes: any;
    subject: any;
    subjectError: boolean;
    subjectErrorMes: any;
    dialCode: any;
    classList: any;
    divisionList: any;
    selectSubjectDropDownDiv: boolean;
    selectClassDropDownDiv: boolean;
    subjectDropDownAnchorEl: any;
    classDropDownAnchorEl: any;
    genderClassDropDownDiv: boolean;
    genderDropDownAnchorEl: any;
    tempGender: any;
    tempSubject: any;
    tempClass: any;
    searchClass: any;
    searchSubject: any;
    subjectList: any;
    classesAndSubject: any;
    selectedIndexId: any;
    subjectWithClassId: any;
    selectedClassId: any;
    selectedSubjectList: any;
    deletedclassesAndSubject: any;
    loadingSpinner: boolean;
    finalSelectedClass: any;
    teacherRestircition: boolean;
    teacherRestircitionError: any;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AddTeacherController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    apigetStudentId: string = "";
    apigetSearchSubjectsId: string = "";
    apigetSubjectRemoveId: string = "";
    apigetTeacherClassesId: string = "";
    apigetStudentClasslId: string = "";
    apigetClasslId: any;
    apigetClassDivisionlId: any = [];
    addStudentApiId: any;
    editStudentApiId: any;
    apiresetPasswordId: any;
    apigetrestritionTeacher: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {

            loading: false,
            dialogOpen: false,
            firstName: "",
            firstNameError: false,
            firstNameErrorMes: "",
            lastName: "",
            lastNameError: false,
            lastNameErrorMes: "",
            DOB: '',
            DOBError: false,
            DOBErrorMes: "",
            gender: "",
            genderError: false,
            genderErrorMes: "",
            phoneNo: "",
            phoneNoError: false,
            phoneNoErrorMes: "",
            emailId: "",
            emailIdError: false,
            emailIdErrorMes: "",
            classs: [],
            classsError: false,
            classsErrorMes: "",
            subject: [],
            subjectError: false,
            subjectErrorMes: "",
            dialCode: 'in',
            classList: [],
            divisionList: [],
            selectSubjectDropDownDiv: false,
            selectClassDropDownDiv: false,
            subjectDropDownAnchorEl: null,
            classDropDownAnchorEl: null,
            tempSubject: [],
            tempClass: '',
            searchClass: '',
            searchSubject: '',
            subjectList: [],
            classesAndSubject: [{ class: '', subject_data: [], classError: false, subjectError: false }],
            selectedIndexId: '',
            genderClassDropDownDiv: false,
            genderDropDownAnchorEl: null,
            tempGender: '',
            subjectWithClassId: [],
            selectedClassId: '',
            selectedSubjectList: [],
            deletedclassesAndSubject: [],
            loadingSpinner: false,
            finalSelectedClass: [],
            teacherRestircition: false,
            teacherRestircitionError: []
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    }


    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        await this.get_classes();
        // Customizable Area End
    }
    // Customizable Area Start
    get_classes = () => {
        this.setState({ loadingSpinner: true })
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.enrollContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetClasslId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllClassesEndpoint + `&school_id=${data.school_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.enrollApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    get_subject = (id: any) => {
        const gradeIndex = this.state.classList.findIndex((x: any) => x.id === this.state.classs);
        let gradeId: any
        if (id === null) {
            gradeId = this.state.classList[gradeIndex].attributes.grade.data.id
        }
        else {
            gradeId = id
        }

        const header = {
            "Content-Type": configJSON.enrollContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetClassDivisionlId[this.apigetClassDivisionlId.length] = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getSubjectEndPoint + `?class_id=${gradeId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.enrollApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    get_restriction = async (e: any) => {
        e.preventDefault();
        let allow: boolean = true;
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        allow = this.validation()
        if (allow) {
            this.setState({ loadingSpinner: true })
            let arr: any[] = []
            let arrSubject: any[] = []
            this.state.classesAndSubject.map((_data: any) => {
                arr.push(_data.class)
                arrSubject.push({ class: _data.class, subject_data: _data.subject_data })

            })
            let fulldata = JSON.stringify(arrSubject)

            const header = {
                "Content-Type": configJSON.enrollContentType,
                token: localStorage.getItem('token'),
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.apigetrestritionTeacher = requestMessage.messageId;
            let apiEndPoint = configJSON.restrictionEndPoint + `?school_id=${data.school_id}`

            apiEndPoint = apiEndPoint + `&subjects=${JSON.stringify(arrSubject)}`
            if (this.props.isEdit) {
                apiEndPoint = apiEndPoint + `&account_id=${this.props.id}`
            }
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                decodeURIComponent(apiEndPoint)

            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestbaseURLMessage),
                configJSON.instituteURL
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            // const httpBody = bodydata;

            // requestMessage.addData(
            //     getName(MessageEnum.RestAPIRequestBodyMessage),
            //     JSON.stringify(httpBody)
            // );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.enrollApiMethodType
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);


        }

    }

    validation = () => {
        let allow: boolean = true;
        const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let classAndSubjectData: any[] = []
        classAndSubjectData = this.state.classesAndSubject
        classAndSubjectData.map((_data: any, index: number) => {
            if (index === 0) {
                if (_data.class === "" || _data.class.lenght === 0) {
                    _data.classError = true
                    allow = false


                }
                if (_data.subject_data.length === 0) {
                    _data.subjectError = true
                    allow = false
                }


            } else {
                if (_data.class !== "" || _data.class.length !== 0) {
                    if (_data.subject_data.length === 0) {
                        _data.subjectError = true
                        allow = false
                    } else {
                        _data.subjectError = false
                    }
                }
            }

        })
        this.setState({ classesAndSubject: classAndSubjectData })

        const { firstName, lastName, DOB, classs, subject, gender, phoneNo, emailId } = this.state;
        if (firstName.length === 0 || emailId.length === 0 || lastName.length === 0 || DOB.length === 0 || gender.length === 0 || phoneNo.length === 0) {
            if (
                this.state.firstName === "" || this.state.firstName.length === 0) {
                this.setState({ firstNameError: true, firstNameErrorMes: 'First name is required.' });
            }
            if (this.state.lastName === '' || this.state.lastName.length === 0) {
                this.setState({ lastNameError: true, lastNameErrorMes: 'Last name is required.' });
            }

            if (this.state.gender === '' || this.state.gender.length === 0) {
                this.setState({ genderError: true, genderErrorMes: 'Please select gender.' });
            }
            if (this.state.DOB === '' || this.state.DOB.length === 0) {
                this.setState({ DOBError: true, DOBErrorMes: 'DOB is required.' });
            }
            // if (this.state.subject === '' || this.state.subject.length === 0) {
            //     this.setState({ subjectError: true, subjectErrorMes: 'Please select subject.' });
            // }
            // if (this.state.classs === '' || this.state.classs.length === 0) {
            //     this.setState({ classsError: true, classsErrorMes: 'Please select class.' });
            // }

            if (this.state.phoneNo === '' || this.state.phoneNo.length === 0) {
                this.setState({ phoneNoError: true, phoneNoErrorMes: 'Phone number is required.' });
            } else if (phoneNo.length < 7) {
                this.setState({ phoneNoError: true, phoneNoErrorMes: ' Enter a valid phone number.' });
            }

            if (this.state.emailId === '' || this.state.emailId.length === 0) {
                this.setState({ emailIdError: true, emailIdErrorMes: 'Email is required.' });
            } else if (!emailReg.test(emailId)) {
                this.setState({ emailIdError: true, emailIdErrorMes: ' Enter a valid email.' });
            }


            allow = false

        }
        return allow;

    }

    onAddStudent = () => {
        const school_data = localStorage.getItem("user_data");
        const schoolData = JSON.parse(school_data || "{}");
        const adminId = localStorage.getItem("id")


        const { firstName, lastName, DOB, classs, subject, gender, phoneNo, emailId } = this.state;


        this.setState({ loadingSpinner: true })
        if (this.props.isEdit) {
            let arr: any[] = []
            let arrSubject: any[] = []
            this.state.classesAndSubject.map((_data: any) => {
                arr.push(_data.class)
                arrSubject.push({ class: _data.class, subject_data: _data.subject_data })
            })
            // let subjectsArr :any[] = this.state.classesAndSubject
            // this.state.deletedclassesAndSubject.map((_data:any)=>{
            //     if(_data.class_id !== "" ){
            //         subjectsArr.push(_data)
            //     }
            // })

            const data = {
                data: {
                    type: "teacher_account",
                    attributes:
                    {
                        school_id: schoolData.school_id,
                        // approved_by: adminId,
                        first_name: firstName,
                        last_name: lastName,
                        gender: gender,
                        // full_phone_number: phoneNo,
                        // email: emailId,
                        date_of_birth: DOB,
                        class_ids: arr,
                        subjects: arrSubject
                    }
                }
            }

            this.setState({ loading: true })
            const header = {
                "Content-Type": configJSON.enrollContentType,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.editStudentApiId = requestMessage.messageId;

            const httpBody = data;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.editStudentEndPoint + `/${this.props.id}/admin_update_account`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.enrollApiPutMethodType
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestbaseURLMessage),
                configJSON.baseUrl
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
        else {
            let arr: any[] = []
            let arrSubject: any[] = []
            let subjectArr: any = []
            let displayNamesArr: any = []

            this.state.classesAndSubject.map((_data: any) => {
                arr.push(_data.class)
                arrSubject.push({ class: _data.class, subject_data: _data.subject_data })
            })

            this.state.finalSelectedClass.map((_index: any) => {
                return (
                    this.state.classList.filter((x: any) => x.id === _index)
                        .map((x: any) => displayNamesArr.push(x.attributes?.display_name))
                )
            })

            this.state.classesAndSubject.map((_classAndSubject: any) => {
                _classAndSubject.subject_data.map((data1: any, index: number) => {

                    this.state.subjectWithClassId.map((_class: any) => {
                        if (_class.class == _classAndSubject.class) {
                            return (_class.subjectList.filter((x: any) => x.id === data1)
                                .map((x: any) => {
                                    return (subjectArr.push(x.attributes?.subject_name))
                                }))}
                    })
                })
            })

            const desData: any = subjectArr.filter((x: any, i: any, a: any) => a.indexOf(x) == i)

            const data = {
                data: {
                    type: "teacher_account",
                    attributes:
                    {
                        approved_by: adminId,
                        school_id: schoolData.school_id,
                        first_name: firstName,
                        last_name: lastName,
                        gender: gender,
                        full_phone_number: phoneNo,
                        email: emailId,
                        date_of_birth: moment(DOB, "DD/MM/YYYY").format("YYYY-MM-DD"),
                        class_ids: arr,
                        //division_id: division
                        subjects: arrSubject,
                        display_names: displayNamesArr,
                        subject_names: desData,
                        role_name:"teacher"
                    }
                }
            }

            this.setState({ loading: true })
            const header = {
                "Content-Type": configJSON.enrollContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.addStudentApiId = requestMessage.messageId;

            const httpBody = data;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.addTeacherEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.enrollApiPostMethodType
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestbaseURLMessage),
                configJSON.baseUrl
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }

    }
    // Customizable Area End


    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            switch (apiRequestCallId) {
                // Customizable Area Start
                case this.apigetClassDivisionlId.indexOf(apiRequestCallId) === -1 ? ""
                    : apiRequestCallId: {

                        if (responseJson != null) {
                            if (!responseJson.errors) {
                                let index = this.state.subjectWithClassId
                                // this.setState({subjectWithClassId : })

                                index.map((_data: any) => {
                                    if (_data.class == responseJson.class_id || parseInt(_data.class) == parseInt(responseJson.class_id)) {
                                        _data.subjectList = responseJson.data
                                    }

                                })
                                this.setState((prevState: any) => ({
                                    subjectWithClassId: index
                                }))
                                this.setState({ subjectList: responseJson.data }, () => {
                                    let data = this.state.subjectWithClassId.filter((_data1: any) => _data1.class == this.state.classesAndSubject[this.state.selectedIndexId]?.class)
                                    this.setState({ selectedSubjectList: this.state.subjectWithClassId.filter((_data1: any) => _data1.class == this.state.classesAndSubject[this.state.selectedIndexId]?.class) })
                                })


                            } else {
                                this.parseApiErrorResponse(responseJson);
                            }
                        }
                        this.parseApiCatchErrorResponse(errorReponse);
                    } break;
                // Customizable Area End
            }
            // Customizable Area Start
            if (apiRequestCallId === this.apigetClasslId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        let arr: { class: any; subjectList: never[]; }[] = [];
                        responseJson.data.map((_data: any) => {
                            arr.push({ class: _data.id, subjectList: [] })
                        })
                        this.setState({ classList: responseJson.data, subjectWithClassId: arr }, () => {
                            if (this.props.isEdit) {
                                this.get_student()
                            }
                        })

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
            if (apiRequestCallId === this.apigetrestritionTeacher) {

                if (responseJson != null) {
                    if (!responseJson.errors) {

                        if (responseJson.success_data) {
                            this.onAddStudent()
                        } else {
                            this.setState({
                                teacherRestircitionError: responseJson?.reject_data,

                            }, () => {

                                this.setState({ teacherRestircition: true })
                            })
                        }

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }

            else if (apiRequestCallId === this.apigetStudentClasslId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {

                        if (responseJson.data != null) {
                            let classes_data: any = []
                            let classes_subjectList: any = []
                            let arr: any = []
                            responseJson?.data?.attributes?.school_account?.data?.map((_data: any) => {
                                classes_data.push({ class: `${_data.attributes?.class?.id}`, subject_data: [] })

                                //     classes_subjectList.push({ class:`${_data.attributes?.class?.id}` , subjectList: [] })
                                //    this.get_subject(_data.attributes?.class?.id)

                            })
                            responseJson?.data?.attributes?.school_account?.data?.map((_data: any) => {

                                classes_subjectList.push({ class: `${_data.attributes?.class?.id}`, subjectList: [], classError: false, subjectError: false })
                                arr.push(_data.attributes?.class?.id)
                                this.get_subject(_data.attributes?.class?.id)

                            })

                            responseJson?.data?.attributes?.teacher_classes_subjects?.teacher_class_subject_data.map((_subject: any) => {
                                classes_data.map((_class: any) => {
                                    if (_class.class == _subject.class_id || parseInt(_class.class) == parseInt(_subject.class_id)) {
                                        _class.subject_data.push(`${_subject.id}`)
                                    }
                                })
                            })
                            if (classes_data.length === 0) {
                                classes_data.push({ class: '', subject_data: [] })
                            }
                            this.setState({
                                firstName: responseJson?.data.attributes.first_name,
                                lastName: responseJson?.data.attributes.last_name,
                                DOB: moment(responseJson?.data.attributes.date_of_birth).format("DD/MM/YYYY"),
                                gender: responseJson?.data.attributes.gender,
                                phoneNo: responseJson?.data.attributes.full_phone_number,
                                emailId: responseJson?.data.attributes.email,
                                classs: responseJson?.data.attributes.school_account.data === null ? "" : responseJson?.data.attributes?.school_account?.data[0]?.attributes?.class?.id,
                                classesAndSubject: classes_data,
                                subjectWithClassId: classes_subjectList,
                                finalSelectedClass: arr
                                //  division: responseJson?.data.attributes.school_account.data === null ? "" : responseJson?.data.attributes?.school_account?.data[0]?.attributes?.division === null ? "" : responseJson?.data.attributes?.school_account?.data[0]?.attributes?.division.id

                            })
                            // if (responseJson?.data.attributes.school_account.data !== null) {
                            //     if (responseJson?.data.attributes?.school_account?.data[0]?.attributes?.division !== null) {
                            //         this.get_subject(responseJson?.data.attributes?.school_account?.data[0]?.attributes?.class?.grade_id)
                            //     }
                            // }
                            // this.get_student_subjects(responseJson?.data[0]?.id)
                        }

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                    this.setState({ loadingSpinner: false })
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
            else if (apiRequestCallId === this.addStudentApiId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (responseJson.data != null) {
                            toast.success("Teacher added successfully.")
                            this.props.handleDialogClose()
                            this.setState({ loadingSpinner: false })
                            // this.get_student_subjects(responseJson?.data[0]?.id)
                        }

                    } else {

                        toast.error(responseJson.errors[0].message)
                        if (responseJson.errors[0]?.full_phone_number) {
                            toast.error(responseJson.errors[0].full_phone_number)
                        }
                        this.parseApiErrorResponse(responseJson);
                    }
                }

                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
            else if (apiRequestCallId === this.editStudentApiId) {

                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (responseJson.data != null) {
                            toast.success("Teacher updated successfully.")
                            this.props.handleDialogClose()
                            this.setState({ loadingSpinner: false })
                            // this.get_student_subjects(responseJson?.data[0]?.id)
                        }

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
            else if (apiRequestCallId === this.apiresetPasswordId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (responseJson != null) {
                            toast.success(responseJson.message)

                            // this.get_student_subjects(responseJson?.data[0]?.id)
                        }

                    } else {
                        toast.error(responseJson.message)
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
            // Customizable Area End
        }
    }
    // Customizable Area Start
    get_student = () => {
        this.setState({ loadingSpinner: true })
        const header = {
            "Content-Type": configJSON.enrollContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetStudentClasslId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAccountDetailsById + `${this.props.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.baseURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.enrollApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    reset_student_password = () => {
        let formData = new FormData();
        formData.append("account_id", this.props.id);
        const header = {
            // "Content-Type": configJSON.formDataContentType,
            token: localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiresetPasswordId = requestMessage.messageId;
        // const httpBody = {
        //     formData,
        // }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.resetPasswordEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.baseUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.enrollApiPutMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
