// Customizable Area Start
import React from "react";
import { Box, Grid, Modal, Typography, Tooltip } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { badgeStar } from "./assets";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface Props {
  // Customizable Area Start
  openModal: boolean;
  onCloseModal: any;
  badgesDetails: any;
  // Customizable Area End
}

export const BadgesModal = ({ openModal, onCloseModal, badgesDetails }: Props) => {
  // Customizable Area Start
  return (
    <Modal
      open={openModal}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="BadgesModal badges_width">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <img src={badgeStar} className="badge_img_modal" />
          <Box display="flex" justifyContent="space-between">
            <Typography className="my_badges_text">My Badges</Typography>
            <Typography
              id="modal-modal-title"
              component="h2"
              className="BadgeModalClose"
            >
              <Close onClick={onCloseModal} className="BadgeModalCloseImg" />
            </Typography>
          </Box>

          <Grid
            container
            justify="space-around"
            spacing={2}
            alignItems="center"
            className="BadgesGrid"
          >
            {badgesDetails?.map((item: any) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={4}
                className="BadgesItem"
              >
                <img
                  src={item.attributes.badge_attachment}
                  className="badge_attachment_img"
                />
                <Tooltip title={
                  <>
                    <h5>Name: {item.attributes?.name}</h5>
                    {item.attributes?.description && <h5>Description: {item.attributes?.description}</h5>}
                  </>
                }>
                  <Typography className="badge_name">
                    {item.attributes.name}
                  </Typography>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
  // Customizable Area End
};
// Customizable Area Start
export default BadgesModal;
// Customizable Area End
