// Customizable Area Start
import React from "react"
import {
    Box,
    Button,
    withStyles,
    Typography,
    Modal,
    Backdrop,
    Grid
} from "@material-ui/core";
import PaperSubmittedModalController, { Props } from "./PaperSubmittedModalController.web";
import { imgThumbUp, modalImgOrangeBackground } from "../assets";
import './PaperSubmittedModal.web.css';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
export class PaperSubmittedModal extends PaperSubmittedModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { classes } = this.props
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="paperSubmittedModal_mainModal"
                    open={this.props.openModal}
                    onClose={(_, reason) => {
                        reason !== 'backdropClick' ? this.props?.handleOnClose(false) : null;
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Box className="paperSubmittedModal_subDiv">
                        <Grid container spacing={0}>
                            {/* Kudos Title */}
                            <Grid item xs={12} className='paperSubmittedModal_congratsDiv'>
                                <Typography
                                    component={"h1"} className={`paperSubmittedModal_congratsTxt ${classes.title_font}`}>
                                    Congratulations
                                </Typography>
                            </Grid>
                            {/* Kudos Title END */}

                            {/* Img Icon */}
                            <Grid item xs={12} className='paperSubmittedModal_thumbImgDiv'>
                                <img src={modalImgOrangeBackground} className='paperSubmittedModal_thumbBackgroundImg' />
                                <img src={imgThumbUp} className='paperSubmittedModal_thumbMainImg' />
                            </Grid>
                            {/* Img Icon END */}

                            {/* message String */}
                            <Grid item xs={12} className='paperSubmittedModal_msgDiv'>
                                <Typography
                                    component={"h2"} className={`paperSubmittedModal_msgTxt ${classes.subTitle_font}`}>
                                    Your exam paper has been submitted successfully
                                </Typography>
                            </Grid>
                            {/* message String END */}


                            {/* Okay Button */}
                            <Grid item xs={12} className='paperSubmittedModal_btnDiv'>
                                <Box className='paperSubmittedModal_subBtnDiv'>
                                    <Button className={`paperSubmittedModal_btnTxt paperSubmittedModal_okayBtn ${classes.button_color}`}
                                        onClick={() => {
                                            window.location.href = '/StudentAssessment'; //need to change the route
                                        }}>
                                        Okay
                                    </Button>
                                </Box>
                            </Grid>
                            {/* Okay Button END */}

                        </Grid>
                    </Box>
                </Modal>
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(PaperSubmittedModal);
// Customizable Area End