// Customizable Area Start
import React from "react";
import {
  Button,
  Grid,
  Avatar,
  Typography,
  NativeSelect,
  Checkbox,
  InputBase,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./QuestionBank.web.css";
import QuestionBankController, { Props } from "./QuestionBankController.web";
import { imgEyeIconSvg } from "../assets";
import PreviousSelectedQuestion from "./PreviousSelectedQuestion.web";
import QuestionPreview from "./QuestionPreview.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import QuestionBankAlertModalPopup from "./QuestionBankAlertModalPopup.web";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import { KeyboardBackspaceOutlined, ArrowDropDown } from '@material-ui/icons';
import { difficultyLevelList } from '../utils/constants';
import { FilterSelect, FilterSelectDisabled } from "./LibraryAssessmentList.style.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class QuestionBankList extends QuestionBankController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const {
      currentSubject,
      currentClass,
      questionData,
      userId,
      teacherList,
      questionsTypeList,
      chapterList,
      selectedTeacherId,
      selectedChapterId,
      selectedQuestionTypeId,
      selectedDifficultyType
    } = this.state;

    const {classes} = this.props;
    return (
      <>
        {this.state.showLoader && (
          <Spinner spinnerModal={this.state.showLoader} />
        )}
        <div className="location">
          <div className="go-back">
            <Typography
              className={`BackText  ${classes.bodyText_font}`}
              data-test-id="question-bank-list-back"
              onClick={() => {
                this.handleBackClick();
              }}
            >
              <KeyboardBackspaceOutlined className={`GoBackIcon ${classes.icon_color}`} />
              Assessment Question Bank
            </Typography>
          </div>
        </div>
        <div className="CreateAssessmentDiv">
          <Button
            variant="contained"
            data-test-id="list-create-assessment-btn"
            onClick={() => {
              this.handleCreateAssessment();
            }}
            style={{
              opacity: this.state.selectedQuestionList?.length > 0 ? 1 : 0.6,
            }}
            className={`CreateAssessmentBtn ${classes.button_color}`}
          >
            Create Assessment
          </Button>
        </div>
        <Grid container direction="row" className="QuestionBankMainBlock">
          <Grid className="topRow" item xs={12}>
            <Typography
              className={`SubjectTextColor ${classes.bodyText_font}`}
              component="span"
            >{`${currentClass?.attributes?.name} > ${currentSubject?.subject_name}`}</Typography>
            {this.state.selectedQuestionList?.length > 0 && (
              <Button
                variant="outlined"
                data-test-id="question-preview-btn"
                onClick={() => this.get_selected_questions_metadata()}
                className={`PreviewBtn ${classes.outline_button_no_hover} ${classes.theme_font}`}
              >
                Preview
              </Button>
            )}
          </Grid>

            <Accordion elevation={0} className="AccordionStylesQuestionBank">
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid className="justifyStart" item xs={6}>
                      <span className="FilterBox">
                        <ArrowDropDown fontSize="large"  className="DropDownIcon" />
                        <span className={`filter ${this.props.classes.theme_font}`}>Filters</span>
                      </span>
                    </Grid>
                    <Grid className="justifyEnd" item xs={6} onClick={this.clearAll}>
                      <span className={`clearFilter ${this.props.classes.theme_font} ${this.props.classes.subTitle_font}`}>Clear all</span>
                    </Grid>
                  </Grid>
                <hr className="horizontalLine" />
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid className="marginTop" item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={12} sm={6} md={4}>
                      <Grid container direction="row">
                        <Grid className="fieldGrid" item xs={12}>
                          <Typography component="span"
                            className={`fieldLabels ${this.props.classes.theme_font}`}>
                            Uploaded by
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <NativeSelect
                            className="nativeSelect"
                            id="select"
                            value={this.state.selectedTeacherId}
                            input={this.state.selectedTeacherId == "" ? <FilterSelectDisabled /> :<FilterSelect />}
                            IconComponent={ArrowDropDown}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.filterTeacherOnChange(event)}
                          >
                            <option value="" disabled>Upload by</option>
                            {
                              teacherList.length > 0 && teacherList.map((item: any) => (
                                <option key={item.id} value={item.id} >
                                  {
                                    item.id === parseInt(userId) ? "Upload by me" : `${item?.first_name} ${item?.last_name !== null ? item.last_name : ""}`
                                  }
                                </option>
                              ))
                            }
                          </NativeSelect>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Grid container direction="row">
                        <Grid className="fieldGrid" item xs={12}>
                          <Typography component="span"
                            className={`fieldLabels ${this.props.classes.theme_font}`}>
                            Filter by Chapter
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <NativeSelect
                            className="nativeSelect"
                            id="select"
                            value={this.state.selectedChapterId}
                            input={this.state.selectedChapterId == "" ? <FilterSelectDisabled /> :<FilterSelect />}
                            IconComponent={ArrowDropDown}
                          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.filterChapterOnChange(event)}
                          >
                            <option value="" disabled>Select Chapter</option>
                            {
                              chapterList.length > 0 && chapterList.map((item: any) => (
                                <option key={item.id} value={item.id} >{item.attributes?.name}</option>
                              ))
                            }
                          </NativeSelect>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Grid container direction="row">
                        <Grid className="fieldGrid" item xs={12}>
                          <Typography component="span"
                            className={`fieldLabels ${this.props.classes.theme_font}`}>
                            Filter by question type
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <NativeSelect
                            className="nativeSelect"
                            id="select"
                            value={this.state.selectedQuestionTypeId}
                            input={this.state.selectedQuestionTypeId == "" ? <FilterSelectDisabled /> :<FilterSelect />}
                            IconComponent={ArrowDropDown}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.filterQuestionTypeOnChange(event)}
                          >
                            <option value="" disabled>Select Type</option>
                            {
                              questionsTypeList.length > 0 && questionsTypeList.map((item:any) => (
                                item?.attributes?.question_sub_categories.map((categroy:any)=>{
                                  return <option key={categroy.id} value={categroy.id} >
                                    {categroy?.name.charAt(0).toUpperCase()}{categroy?.name.replaceAll("_", " ").slice(1)}
                                    </option>
                                })
                              ))
                            }
                          </NativeSelect>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Grid container direction="row">
                        <Grid className="fieldGrid" item xs={12}>
                          <Typography component="span"
                            className={`fieldLabels ${this.props.classes.theme_font}`}>
                            Filter by difficulty level
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <NativeSelect
                            className="nativeSelect"
                            id="select"
                            value={this.state.selectedDifficultyType}
                            input={this.state.selectedDifficultyType == "" ? <FilterSelectDisabled /> :<FilterSelect />}
                            IconComponent={ArrowDropDown}
                          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.filterDifficultyTypeOnChange(event)}
                          >
                            <option value="" disabled>Select Difficulty</option>
                            {difficultyLevelList?.map((item: any) => (
                                <option value={item.value} key={item.value}>
                                {item.label}
                                </option>
                              ))}
                          </NativeSelect>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className="alignEnd marginTop" item xs={12} sm={6} md={4}>
                      <Button
                      disabled={ !selectedTeacherId && 
                      !selectedChapterId && 
                      !selectedQuestionTypeId &&
                      !selectedDifficultyType } 
                      className={`btn_disabled ${this.props.classes.button_color} ${this.props.classes.theme_font} filterBtn`} onClick={this.applyFilter}>
                        Apply Filter
                      </Button>
                    </Grid>
                  </Grid>
                  <hr className="horizontalLine2" />
                </Grid>
              </AccordionDetails>
            </Accordion>


          <Grid className="marginTop" item xs={12}>
            <Grid container direction="row">
              
              <Grid item xs={12}>
                {questionData.length > 0 ? (
                  questionData.map((question: any, index: any) => (
                    <Grid
                      className="questionContainer assessment-library-outer"
                      container
                      direction="row"
                      key={question.id}
                    >
                      <Grid className="QuestionRow" item xs={12}>
                        <Grid container direction="row">
                          <Grid className="justifyStart" item xs={10} sm={8}>
                            <label
                              htmlFor={"checkbox_" + index + ""}
                              className="checkboxSpan"
                            >
                              <Checkbox
                                id={"checkbox_" + index}
                                data-test-id={"question_checkbox_" + index}
                                onChange={() =>
                                  this.handleToggleQuestionSelect(question)
                                }
                                checked={
                                  this.state.selectedQuestionList?.indexOf(
                                    question
                                  ) !== -1
                                }
                                className={`${this.props.classes.QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_checkBoxChecked} QuestionCheckBox`}
                                inputProps={{
                                  "aria-labelledby":
                                    "checkbox-list-secondary-label-question",
                                }}
                              />
                            </label>
                            <span className={`QuestionText ${classes.subHeading_font}`}>{`Q${index +
                              1})`}</span>
                            <span
                              style={{ marginLeft: "10px" }}
                              dangerouslySetInnerHTML={{
                                __html: question?.title,
                              }}
                              className={`QuestionText ${classes.subHeading_font}`}
                            ></span>
                          </Grid>
                          <Grid className="justifyEnd" item xs={2} sm={4}>
                            <span

                              data-test-id="get-question-answer-span"
                              className="PreviewQuestion"
                              onClick={() => {
                                this.setState(
                                  {
                                    selectedQuestion: question,
                                  },
                                  () => {
                                    this.get_selected_question_answer(
                                      question.id
                                    );
                                  }
                                );
                              }}
                            >
                              {" "}
                              <img
                                className="icons"
                                src={imgEyeIconSvg}
                                alt=""
                              />{" "}
                            </span>
                            {parseInt(userId) === question?.account_id ? (
                              <></>
                            ) : (
                              <span className="teacherInfo">
                                <span className="avatarSpan">
                                  {question?.account?.[0]?.attributes?.avatar ? (
                                    <img
                                      className="avatar"
                                      src={
                                        question.account?.[0]?.attributes?.avatar
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <Avatar src="" className="avatar" />
                                  )}
                                </span>
                                <span className={`TeacherName ${classes.theme_font} ${classes.bodyText_font}`}>{`${question?.account?.[0]?.attributes?.first_name}`}</span>
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <div className={`no-items ${classes.bodyText_font}`}>
                    <span>No record(s) found</span>
                  </div>
                )}
                {this.state.timeLineMeta && this.state.pagination_page > 0 && (
                  <div className="loadMoreBtnDiv">
                    <Button
                      variant="outlined"
                      className={`loadMoreBtn ${classes.outline_button}`}
                      onClick={this.handleLoadMore}
                    >
                      Load More...
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <PreviousSelectedQuestion
          openModal={this.state.PreviousSelectedQuestion}
          questionsList={this.state.selectedQuestionMetadata}
          handleOnClose={this.handleOnClosePreviousSelectedQuestion}
        />
        <QuestionPreview
          openModal={this.state.PreviousQuestion}
          selectedQuestion={this.state.selectedQuestion?.title}
          selectedQuestionAnswers={this.state.selectedQuestionAnswer}
          handleOnClose={this.handleOnClosePreviousQuestion}
        />
        <QuestionBankAlertModalPopup
          alertModal={this.state.openDataLossAlertPopup}
          onCloseModal={this.handleCloseDataLossAlertPopup}
          onConfirmGoBack={this.handleCloseDataLossAlertPopupAndRedirect}
          alertMessage={`You have selected few questions from the ${this.state.currentSubject?.subject_name}. Going back now would result in loss of the current data. Are you sure you want to go back ?`}
        />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(QuestionBankList);
// Customizable Area End
