import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showLoader: boolean;
  addedRowItems: any;
  addedColumnItems: any;
  anchorEl: any;
  columnName: string;
  markingTypeList: any;
  typeOfMarking: any;
  anchorElRowName: any;
  subjectName: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CreateCoScholasticAreaController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostReportCardList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      showLoader: false,
      addedRowItems: [],
      addedColumnItems: [],
      anchorEl: null,
      columnName: "",
      markingTypeList: [],
      typeOfMarking: "",
      anchorElRowName: null,
      subjectName: "",
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  // Customizable Area Start

  handleBack = () => {
    HISTORY.goBack();
  };

  addTableRow = () => {
    const { addedRowItems } = this.state;
    let item: any = null
    if (addedRowItems?.length > 0) {
      item = {
        name: `Subject ${addedRowItems?.length + 1}`,
      };
    } else {
      item = {
        name: `Subject 1`,
      };
    }

    this.setState({
      addedRowItems: [...this.state.addedRowItems, item]
    });
  }

  addTableColumn = (event: any) => {
    const { addedColumnItems } = this.state;
    let item: any = null
    if (addedColumnItems?.length > 0) {
      item = {
        name: `Column ${addedColumnItems?.length + 1}`,
      };
    } else {
      item = {
        name: `Column 1`,
      };
    }

    this.setState({
      // addedColumnItems: [...this.state.addedColumnItems, item],
      anchorEl: event.currentTarget
    });

  }

  onChangeColumnName = (event: any) => {
    this.setState({ columnName: event.target.value })
  }

  onChangeTypeOfMarking = (event: any) => {
    this.setState({ typeOfMarking: event.target.value })
  }

  openEditRowNamePopover = (event: any) => {
    this.setState({ anchorElRowName: event.currentTarget});
  }

  onChangeSubjectName = (event: any) => {
    this.setState({ subjectName: event.target.value })
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apiPostReportCardList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ showLoader: false });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }


  // Customizable Area End
}
