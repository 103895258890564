// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  openModal: boolean;
  onCloseModal: any;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  feedbackData: any;
  pagination_page: number;
  pageCount: number;
  loading: boolean;
  classList: any;
  selectedClass: any,
  selectedSubject: any,
  assessmentList: any,
  selectedAssessment: any,
  openPopup: boolean,
  status: string,
  assessmentReportList:any,
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminAssessmentReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // getPeriodListId: string = "";
  getClassesListId: string = "";
  getSubjectListId: string = "";
  getAssessmentListId: string = "";
  getAssessmentReportId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      feedbackData: [],
      pagination_page: 0,
      pageCount: 1,
      loading: false,
      classList: [],
      selectedClass: "",
      
      selectedSubject: "",
      assessmentList: [],
      selectedAssessment: "",
      openPopup: false,
      status: "skipped",
      assessmentReportList:[]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  
  // Customizable Area Start
  handlePopup = () => {
    this.setState({ openPopup: !this.state.openPopup })
  }

  handlePagination = (e:any) => {
    if (e.selected !== this.state.pagination_page) {
      this.setState({ pagination_page: e.selected });
    }
  }

  handleCloseBadResponsePopup = () => {
    this.setState({ openPopup: false })
  }

  async componentDidMount() {
    super.componentDidMount()
    this.get_classes_list();
    
    
  }

  get_classes_list = () => {
    console.log("calling get class list");
    
    this.setState({ loading: true });
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClassesListId = requestMessage.messageId;
    let apiEndPoint =
      configJSON.classesListEndPoint + `?school_id=${school_Data.school_id}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType

    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_assessment_list = () => {
    this.setState({ loading: true });
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: school_Data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssessmentListId = requestMessage.messageId;
    let apiEndPoint =
      configJSON.assessmentListEndPoint + `?school_class_id=${this.state.selectedClass}&subject_id=${this.state.selectedSubject}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  get_assessment_report_list = () => {
    this.setState({ loading: true });
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssessmentReportId = requestMessage.messageId;
    let apiEndPoint =
      configJSON.assessmentReportListEndPoint + `?assessment_id=${this.state.selectedAssessment}&school_class_id=${this.state.selectedClass}&school_id=${school_Data.school_id}&status=${this.state.status}&per=10&page=1`;
    if (this.state.status !== "") {
      apiEndPoint = apiEndPoint + `&status=${this.state.status}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType

    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

       if (apiRequestCallId === this.getClassesListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ classList: responseJson?.data,  loading: false }, () => {
              // this.get_subject_list();
            })
            
            
            // this.handleSetClass(responseJson?.data[0])
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loading: false });
       } // else if (apiRequestCallId === this.getSubjectListId) {
      //   if (responseJson != null) {
      //     if (!responseJson.errors) {
      //       this.setState({
      //         subjectList: responseJson?.data,
      //         selectedSubject:"",
      //         loading: false
      //       })
      //       // this.setState({ subjectList: responseJson?.data, selectedSubject: responseJson.data[0].id, loading: false }, () => {
      //       //   this.get_assessment_list();
      //       // })
      //       // this.handleSetClass(responseJson?.data[0])
      //     } else {
      //       this.parseApiErrorResponse(responseJson);
      //     }
      //   }
      //   this.parseApiCatchErrorResponse(errorReponse);
      //   this.setState({ loading: false });
     // }
    else if (apiRequestCallId === this.getAssessmentListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ assessmentList: responseJson?.data, loading: false })
            // this.handleSetClass(responseJson?.data[0])
            console.log("data",responseJson);
            console.log(this.state.selectedClass);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.getAssessmentReportId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ assessmentReportList: responseJson?.data, loading: false })
            // this.handleSetClass(responseJson?.data[0])
            console.log("assessment report", responseJson.data);
            
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loading: false });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
