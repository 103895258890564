// Customizable Area Start
import React from "react";
import "./AdminReportCardTemplate.web.css";
import Button from "@material-ui/core/Button";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import {
  FormControl,
  InputLabel,
  TextField,
  Box,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    table: {
      minWidth: 700,
    },
  })
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

function createData(title: string, grade: string, achievement: string, edit: any) {
  return { title, grade, achievement, edit };
}

const rows = [
  createData("90% and above", "A+", "Outstanding", "edit"),
  createData("75% to 89%", "A", "Excellent", "edit"),
  createData("56% and 74%", "B", "Very Good", "edit"),
  createData("35% to 55%", "C", "Good", "edit"),
  createData("34% to below", "D", "Scope for improvement", "edit")
];
// Customizable Area End

export default function GradingSystem() {
  // Customizable Area Start
  const classes = useStyles();
  return (
    <div>
      <div className="profileTitle">
        <Button variant="text"><KeyboardBackspaceOutlinedIcon /> Back</Button>
        <h2>Grading System-Part 1</h2>
        <Button className="editBtn commonBtnSecondary">Enable Edit <EditOutlinedIcon /> </Button>
      </div>
      <p>
        Grading scale for scholastic areas: Grades are awarded on a 5 points grading scale as follows.
      </p>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Marks Range</StyledTableCell>
                <StyledTableCell align="right">Grade</StyledTableCell>
                <StyledTableCell align="right">Grade Acheivement</StyledTableCell>
                <StyledTableCell align="right">Edit</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.title}>
                  <StyledTableCell component="th" scope="row">
                    {row.title}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.grade}</StyledTableCell>
                  <StyledTableCell align="right">{row.achievement}</StyledTableCell>
                  <StyledTableCell align="right">{row.edit}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </div>
  );
  // Customizable Area End
}
