// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Grid,
    Typography,
    MenuItem,
    Select,
    Checkbox,
    ListItemText,
    TextField,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    IconButton,
    Divider,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import { withStyles } from "@material-ui/core/styles";
import AddCustomReportCardController, { Props } from "./AddCustomReportCardController.web";
import 'react-calendar/dist/Calendar.css';
import Spinner from "../../../../shared/SideBar/src/Spinner";
import HOCAuth from "../../../../shared/Header/src/HOCAuth.web";
import ClearIcon from '@material-ui/icons/Clear';
import { HISTORY } from "../../../../../components/src/comman";
import WarningModal from "./WarningModal.web";
import './CreateCustomReportCard.web.css';
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const MenuProps: any = {
    PaperProps: {
        style: {
            borderRadius: "6px",
            boxShadow: "0 2px 4px 0 rgba(134, 134, 134, 0.5)",
            backgroundColor: "#ffffff"
        }
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    getContentAnchorEl: null
};

const styles = {
    error: {
        border: "solid 0.5px #ff0000",
    },
    opacityLight: {
        opacity: 0.4
    },
    opacityFull: {
        opacity: 1
    }
}

const form_input_text_field = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
} as const;
// Customizable Area End

export class AddCustomReportCard extends AddCustomReportCardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
        // Customizable Area Start
        // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { showLoader, isEdit, isAllGradeSelected, isAllGradeDisabled, gradeList, selectedGrades, selectedAssessmentCategories, assessmentCategoryList, taskCategoryList, selectedTaskCategories, reportCardTitle, gradeScaleList, gradeScaleDetail, selectedGradeScale, selectedTopics, isAllTopicSelected, coScholasticModule } = this.state;
        return (
            <Box>
                {showLoader && <Spinner spinnerModal={showLoader} />}

                <Grid container spacing={2}>
                    {/* Back Button */}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classes.AddCustomReportCard_Feedback_backScreen}
                            onClick={() => { this.onBackClick() }}
                            data-test-id="custom-report-back-icon">
                            <KeyboardBackspaceIcon className={this.props.classes.AddCustomReportCard_Feedback_icon_button_color} />
                            <Typography
                                className={`${classes.AddCustomReportCard_Feedback_backIconStyle} ${this.props.classes.bodyText_font}`}
                            >Back</Typography>
                        </Box>
                    </Grid>
                    {/* Back Button END */}

                    {/* Title */}
                    <Grid item xs={12} sm={6} md={6} lg={6} className={classes.AddCustomReportCard_Feedback_titleGrid}>
                        <Typography
                            className={`${classes.AddCustomReportCard_Feedback_titleText} ${classes.heading_font}`}
                        >
                            {isEdit ? 'Edit custom report card' : 'Create custom report card'}
                        </Typography>
                    </Grid>
                    {/* Title END */}

                </Grid>

                <Grid container className={classes.AddCustomReportCard_Feedback_contentGrid} spacing={2}>

                    {/* Report card Title */}
                    <Grid item xs={12} sm={6} md={9} lg={9} className="CustomReportCardTitle">
                        <div className="pr-40 mb-16" >
                            <div className="FlexRowSpaceBetween">
                                <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                    Report card title
                                </div>
                            </div>
                            <TextField
                                placeholder="Report Title"
                                style={form_input_text_field}
                                variant="outlined"
                                value={reportCardTitle}
                                onChange={(e: any) => this.handleReportCardTitle(e.target.value)}
                                inputProps={{ className: `term-text-field` }}
                                data-test-id="report-card-title-field"
                            />
                        </div>
                    </Grid>
                    {/* Report card Title END */}

                    {/* Grade List */}
                    <Grid item xs={12} sm={6} md={3} lg={3} className="CustomReportCardSelect">
                        <div className="module-text-field mb-16">
                            <div className="FlexRowSpaceBetween">
                                <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                    Select Class
                                </div>
                            </div>
                            <Select
                                className={`${classes.AddCustomReportCard_Feedback_selectDropDown} ${classes.bodyText_font} ${selectedGrades.length === 0 ? classes.AddCustomReportCard_Feedback_lowOpacityMultiSelect : ''} multi-dropdown-report`}
                                value={selectedGrades}
                                multiple
                                disabled={isEdit}
                                IconComponent={ArrowDropDownRoundedIcon}
                                onChange={this.handleClassChange}
                                variant="outlined"
                                inputProps={{
                                    className: "CustomReportCardSelectInput"
                                }}
                                displayEmpty
                                MenuProps={MenuProps}
                                renderValue={(selected) => this.getSelectedClassNames(selected)}
                            >
                                <MenuItem
                                    value="-1"
                                    classes={{
                                        root: isAllGradeSelected ? classes.AddCustomReportCard_Feedback_selectedAll : ""
                                    }}
                                    disabled={isAllGradeDisabled}
                                >
                                    <Checkbox
                                        className={classes.AddCustomReportCard_Feedback_checkboxChecked}
                                        checked={isAllGradeSelected}
                                    />
                                    <ListItemText primary={"All"} />
                                </MenuItem>
                                {
                                    gradeList.map((data: any) => {
                                        const checked = selectedGrades.indexOf(Number(data.id));
                                        return (
                                            <MenuItem
                                                key={data.id}
                                                value={Number(data.id)}
                                                disabled={this.state.bindedGradeIds.length > 0 && this.state.bindedGradeIds.indexOf(Number(data.id)) > -1}
                                            >
                                                <Checkbox
                                                    className={classes.AddCustomReportCard_Feedback_checkboxChecked}
                                                    checked={checked > -1}
                                                />
                                                <ListItemText primary={data.attributes.name} />
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>

                        </div>
                    </Grid>
                    {/* Grade List END */}

                    {/* Module Detail */}
                    <Grid item xs={12} sm={12} md={12} lg={12} className="ReportCardMargin">
                        <Typography
                            className={`${classes.AddCustomReportCard_Feedback_subheadingText} ${classes.subHeading_font}`}
                        >
                            Module details
                        </Typography>
                    </Grid>

                    {/* Co-Scholatic Title */}
                    <Grid item xs={12} sm={6} md={4} lg={4} className="CustomReportCardTitle">
                        <div className="pr-40 mb-16" >
                            <div className="FlexRowSpaceBetween">
                                <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                    Module Type
                                </div>
                            </div>
                            <TextField
                                placeholder="Report Title"
                                style={{ ...form_input_text_field, ...styles.opacityLight }}
                                variant="outlined"
                                disabled
                                value={coScholasticModule?.attributes?.name}
                                inputProps={{ className: `term-text-field` }}
                            />
                        </div>
                    </Grid>
                    {/* Co-Scholatic Title END */}

                    {/* Co-Scholastic Topics */}
                    <Grid item xs={12} sm={6} md={8} lg={8} className="CustomReportCardSelect">
                        <div className="module-text-field mb-16">
                            <div className="FlexRowSpaceBetween">
                                <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                    Select Topics
                                </div>
                            </div>
                            <Select
                                className={`${classes.AddCustomReportCard_Feedback_selectDropDown} ${classes.bodyText_font}  ${selectedTopics.length === 0 ? classes.AddCustomReportCard_Feedback_lowOpacityMultiSelect : ''} multi-dropdown-report`}
                                value={selectedTopics}
                                multiple
                                IconComponent={ArrowDropDownRoundedIcon}
                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleCoScholastic(event.target.value as string[])}
                                variant="outlined"
                                inputProps={{
                                    id: "select-multiple-native",
                                    className: "CustomReportCardSelectInput"
                                }}
                                displayEmpty
                                MenuProps={MenuProps}
                                renderValue={(selected) => this.getSelectedTopicNames(selected)}
                                data-test-id="select-topic-field"
                            >
                                <MenuItem
                                    value="-1"
                                    classes={{
                                        root: isAllTopicSelected ? classes.AddCustomReportCard_Feedback_selectedAll : ""
                                    }}
                                >
                                    <Checkbox
                                        className={classes.AddCustomReportCard_Feedback_checkboxChecked}
                                        checked={isAllTopicSelected}
                                    />
                                    <ListItemText primary={"All"} />
                                </MenuItem>
                                {
                                    coScholasticModule?.attributes?.topics?.map((data: any) => {
                                        const checked = selectedTopics.indexOf(Number(data.id));
                                        return (
                                            <MenuItem key={data.id} value={data.id}>
                                                <Checkbox
                                                    className={classes.AddCustomReportCard_Feedback_checkboxChecked}
                                                    checked={checked > -1}
                                                />
                                                <ListItemText primary={data?.name} />
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>

                        </div>
                    </Grid>
                    {/* Co-Scholastic Topics END */}
                    {/* Module Detail END */}

                    {/* Category Details */}
                    <Grid item xs={12} sm={12} md={12} lg={12} className="ReportCardMargin20">
                        <Typography
                            className={`${classes.AddCustomReportCard_Feedback_subheadingText} ${classes.subHeading_font}`}
                        >
                            Category details
                        </Typography>
                    </Grid>
                    {/* Assessment */}

                    <Grid item xs={12} sm={12} md={12} lg={12} className="ReportCardMargin">
                        <Typography
                            className={`FillAssessmentText ${classes.subTitle_font}`}
                        >
                            Fill Assessment details
                        </Typography>
                    </Grid>

                    {
                        selectedAssessmentCategories.map((data: any, index: number) => {
                            return <Grid container key={data.id} spacing={2} className="ReportAssessmentGrid">
                                <Grid item xs={10} sm={10} md={10} lg={8} className="ReportCardDisplayFlex">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6} lg={6} className="CustomReportCardSelect">
                                            <div className="create-report-card-textfield-div">
                                                <div className="FlexRowSpaceBetween">
                                                    <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                                        Assessment Name
                                                    </div>
                                                </div>
                                                <Select
                                                    className={`${classes.AddCustomReportCard_Feedback_selectDropDown} ${classes.bodyText_font} ${data.selectedAssessment === "" ? classes.AddCustomReportCard_Feedback_lowOpacitySelect : ''} multi-dropdown-report`}
                                                    value={Number(data.selectedAssessment)}
                                                    IconComponent={ArrowDropDownRoundedIcon}
                                                    onChange={(e: any) => this.handleAssessmentNameChange(e.target.value, index)}
                                                    variant="outlined"
                                                    inputProps={{
                                                        className: "CustomReportCardSelectInput"
                                                    }}
                                                    displayEmpty
                                                    MenuProps={{ getContentAnchorEl: null }}
                                                    data-test-id="assessment-category-text-field"
                                                >

                                                    <MenuItem
                                                        disabled
                                                        value={Number("")}
                                                    >
                                                        <ListItemText primary={"Select Assessment"} />
                                                    </MenuItem>
                                                    {assessmentCategoryList.map((item: any) => (
                                                        <MenuItem key={item.id} value={Number(item.id)}
                                                            disabled={selectedAssessmentCategories.filter((data: any) => data.selectedAssessment == item.id).length > 0}
                                                        >
                                                            <ListItemText primary={item.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} className="CustomReportCardTitle">
                                            <div className="create-report-card-textfield-div" >
                                                <div className="FlexRowSpaceBetween">
                                                    <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                                        Enter Weightage (%)
                                                    </div>
                                                </div>
                                                <TextField
                                                    placeholder="Eg- English, Computer Science, Health..."
                                                    style={form_input_text_field}
                                                    variant="outlined"
                                                    type="number"
                                                    value={data.weightage}
                                                    onChange={(e: any) => this.handleAssessmentWeightageChange(e.target.value, index)}
                                                    inputProps={{ className: `term-text-field` }}
                                                    data-test-id="assessment-weightage-text-field"
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {selectedAssessmentCategories.length > 1 &&
                                    <Grid item xs={2} sm={2} md={2} lg={4} className="ModulesCoScholasticClear">
                                        <IconButton
                                            className="ModulesCoScholasticClearIcon"
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            data-test-id={`delete-assessment-icon-${index}`}
                                            onClick={() => this.deleteAssessment(index)}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                        })
                    }

                    <Grid item xs={12} sm={12} md={12} lg={12} className="ReportAddAssessmentDiv">
                        <Button
                            className={`${this.props.classes.AddCustomReportCard_Feedback_btn_border} add-assessment-task-btn ReportAddAssessmentBtn`}
                            onClick={this.handleAddAssessment}
                        >
                            + Add Assessment
                        </Button>
                    </Grid>
                    {/* Assessment END */}

                    <Grid item xs={12} sm={12} md={12} lg={12} className="ReportCardMargin">
                        <Divider />
                    </Grid>
                    {/* Task */}

                    <Grid item xs={12} sm={12} md={12} lg={12} className="ReportCardMargin">
                        <Typography
                            className={`${classes.subTitle_font} FillAssessmentText`}
                        >
                            Fill Task details
                        </Typography>
                    </Grid>

                    {
                        selectedTaskCategories.map((data: any, index: number) => {
                            return <Grid container key={data.id} spacing={2} className="ReportAssessmentGrid">
                                <Grid item xs={10} sm={10} md={10} lg={8} className="ReportCardDisplayFlex">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6} lg={6} className="CustomReportCardSelect">
                                            <div className="create-report-card-textfield-div">
                                                <div className="FlexRowSpaceBetween">
                                                    <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                                        Task Name
                                                    </div>
                                                </div>
                                                <Select
                                                    className={`${classes.AddCustomReportCard_Feedback_selectDropDown} ${classes.bodyText_font} ${data.selectedTask === "" ? classes.AddCustomReportCard_Feedback_lowOpacitySelect : ''} multi-dropdown-report`}
                                                    value={Number(data.selectedTask)}
                                                    IconComponent={ArrowDropDownRoundedIcon}
                                                    onChange={(e: any) => this.handleTaskNameChange(e.target.value, index)}
                                                    variant="outlined"
                                                    inputProps={{
                                                        id: "select-multiple-native",
                                                        className: "CustomReportCardSelectInput"
                                                    }}
                                                    displayEmpty
                                                    MenuProps={{ getContentAnchorEl: null }}
                                                    data-test-id="task-name-select"
                                                >
                                                    <MenuItem
                                                        disabled
                                                        value={Number("")}
                                                    >
                                                        <ListItemText primary={"Select Task"} />
                                                    </MenuItem>
                                                    {taskCategoryList.map((item: any) => (
                                                        <MenuItem key={item.id} value={Number(item.id)}
                                                            disabled={selectedTaskCategories.filter((data: any) => Number(data.selectedTask) == Number(item.id)).length > 0}
                                                        >
                                                            <ListItemText primary={item.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} className="CustomReportCardTitle">
                                            <div className="create-report-card-textfield-div" >
                                                <div className="FlexRowSpaceBetween">
                                                    <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                                        Enter Weightage (%)
                                                    </div>
                                                </div>
                                                <TextField
                                                    placeholder="Eg- English, Computer Science, Health..."
                                                    style={form_input_text_field}
                                                    variant="outlined"
                                                    type="number"
                                                    value={data.weightage}
                                                    onChange={(e: any) => this.handleTaskWeightageChange(e.target.value, index)}
                                                    inputProps={{ className: `term-text-field` }}
                                                    data-test-id="task-weightage-text-field"
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {selectedTaskCategories.length > 1 &&
                                    <Grid item xs={2} sm={2} md={2} lg={4} className="ModulesCoScholasticClear">
                                        <IconButton
                                            className="ModulesCoScholasticClearIcon"
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            data-test-id={`delete-task-icon-${index}`}
                                            onClick={() => this.deleteTask(index)}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                        })
                    }


                    <Grid item xs={12} sm={12} md={12} lg={12} className="ReportAddAssessmentDiv">
                        <Button
                            className={`${this.props.classes.AddCustomReportCard_Feedback_btn_border} add-assessment-task-btn ReportAddAssessmentBtn`}
                            onClick={this.handleAddTask}
                        >
                            + Add Task
                        </Button>
                    </Grid>
                    {/* Task END */}
                    {/* Category Details END */}



                    {/* Grade Scale Detail */}
                    <Grid item xs={12} sm={12} md={12} lg={12} className="ReportCardMargin20">
                        <Typography
                            className={`${classes.AddCustomReportCard_Feedback_subheadingText} ${classes.subHeading_font}`}
                        >
                            Grade details
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4} className="ReportCardSelectGradeScale">
                        <div className="pr-40 mb-16" >
                            <div className="FlexRowSpaceBetween">
                                <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                    Select Grade Scale
                                </div>
                            </div>
                            <Select
                                value={selectedGradeScale}
                                onChange={this.handleGradeChange}
                                displayEmpty
                                variant="outlined"
                                inputProps={{
                                    id: "select-multiple-native",
                                    className: "CustomReportCardSelectInput"
                                }}
                                MenuProps={{ getContentAnchorEl: null }}
                                className={`${classes.AddCustomReportCard_Feedback_selectDropDown} ${classes.bodyText_font} ${selectedGradeScale === "" ? classes.AddCustomReportCard_Feedback_lowOpacitySelect : ''} multi-dropdown-report`}
                            >
                                <MenuItem
                                    disabled
                                    value=""
                                >
                                    <ListItemText primary={"Eg. 5 Scale, B Scale"} />
                                </MenuItem>
                                {
                                    gradeScaleList.map((data: any) => {
                                        return (
                                            <MenuItem key={data.id} value={data.id}>
                                                <ListItemText primary={data.attributes.name} />
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </Grid>
                    {/* Grade Scale Detail END */}

                    {/* Grade Scale List */}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {
                            gradeScaleDetail?.length > 0 &&
                            <TableContainer className="CustomReportGradeTableContainer">
                                <Table className="CustomReportGradeTable">
                                    <TableHead className="CustomReportGradeTableHead">
                                        <TableRow>
                                            <TableCell className={`CustomReportGradeTableHeadCell ${this.props.classes.subTitle_font} border-radius-0`} >Label</TableCell>
                                            <TableCell className={`CustomReportGradeTableHeadCell ${this.props.classes.subTitle_font}`}>Score</TableCell>
                                            <TableCell className={`CustomReportGradeTableHeadCell ${this.props.classes.subTitle_font}`}>Max Marks</TableCell>
                                            <TableCell className={`CustomReportGradeTableHeadCell ${this.props.classes.subTitle_font} border-radius-0`}>Min Marks</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="CustomReportGradeTableBody">
                                        {gradeScaleDetail?.map((data: any, index: number) => (
                                            <TableRow key={data.id}>
                                                <TableCell className={`CustomReportGradeTableDataCell ${this.props.classes.subTitle_font} border-radius-0`}>{data.attributes.name}</TableCell>
                                                <TableCell className={`CustomReportGradeTableScoreCell ${this.props.classes.subTitle_font}`}>{data.attributes.score}</TableCell>
                                                <TableCell className={`CustomReportGradeTableDataCell ${this.props.classes.subTitle_font}`}>{data.attributes?.max_marks}</TableCell>
                                                <TableCell className={`CustomReportGradeTableDataCell ${this.props.classes.subTitle_font} border-radius-0`}>{data.attributes?.min_marks}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </Grid>
                    {/* Grade Scale List END */}

                    {/* Submit Btn */}
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.AddCustomReportCard_Feedback_classGrid}>
                        {
                            this.state.isEdit
                                ? (
                                    <>
                                        <Button
                                            onClick={() => this.handleUpdateOrPublishReportCard(false)}
                                            className={`${classes.button_color} ${classes.AddCustomReportCard_Feedback_nextButton} CustomReportBtn`}
                                            data-test-id="edit-save-btn"
                                        >Save</Button>
                                        {
                                            HISTORY.location.state?.reportCardData.attributes.status == 'as_draft' &&
                                            <Button
                                                onClick={() => this.handleUpdateOrPublishReportCard(true)}
                                                className={`${classes.button_color} ${classes.AddCustomReportCard_Feedback_nextButton} CustomReportBtn`}
                                                data-test-id="edit-publish-btn"
                                            >Publish</Button>
                                        }
                                    </>
                                )
                                : (
                                    <>
                                        <Button
                                            onClick={this.handleSaveAsDraft}
                                            className={`${classes.button_color} ${classes.AddCustomReportCard_Feedback_nextButton} CustomReportBtn`}
                                        >Save as draft</Button>

                                        <Button
                                            onClick={this.onStartToCreate}
                                            className={`${classes.button_color} ${classes.AddCustomReportCard_Feedback_nextButton} CustomReportBtn`}
                                        >Publish</Button>
                                    </>
                                )
                        }

                    </Grid>
                    {/* Submit Btn END */}
                </Grid>
                <WarningModal
                    title={this.state.warningTitle}
                    subTitle={this.state.warningSubTitle}
                    paragraph={""}
                    showIcon={true}
                    warningModal={this.state.openWarningModal}
                    onCloseModal={this.onCloseWarningModal}
                    warningClickOk={this.handleWarningOk}
                />
            </Box>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(AddCustomReportCard as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End