Object.defineProperty(exports, "__esModule", {
    value: true
});

// Customizable Area Start
const urlConfig = require("../../../../framework/src/config");
exports.postAPiMethod = "POST";
exports.getAPiMethod = "GET";
exports.putAPiMethod = "PUT";
exports.deleteAPIMethod = "DELETE";
exports.dashboarContentType = "application/json";
exports.getSchoolEventEndPoint = "account_block/general_events/get_school_events"
exports.getSchoolBirthdayAndAnniverseriesEndPoint = "account_block/general_events/get_birthday_anniversary_events"


exports.instituteURL = urlConfig.instituteURL
exports.getThoughtListEndPoint = "/account_block/thoughts?";
exports.setThoughtToInactiveEndpoint = "/account_block/thoughts/";
exports.eventsEndPoint = "/account_block/general_events"
// Customizable Area End