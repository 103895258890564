// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { HISTORY } from "../../../components/src/comman";

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  themeList: any;
  selectedTheme_id: any;
  showLoader: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherAssessmentCreateThemeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetThemeList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      themeList: [],
      selectedTheme_id: '',
      showLoader: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetThemeList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ themeList: responseJson.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getThemeList();
    // local theme
    const assessmentData = localStorage.getItem("assessmentData");
    
    if (assessmentData !== null) {
      const data = JSON.parse(assessmentData || "{}");
      this.setState({
        selectedTheme_id: data?.assessment_theme ? data?.assessment_theme.id : ''
      })

      data?.assessment_sections_attributes?.map((section:any, index:number)=>{
        let uniqQuestions = section?.question_data?.filter((v:any,i:number,a:any)=>a.findIndex((v2:any)=>(v2.id===v.id))===i)
        section.question_data = uniqQuestions;
      })
      localStorage.setItem("assessmentData", JSON.stringify(data))

    } else {
      // redirect back to create assessment
      HISTORY.push({
        pathname: "/TeacherAssessmentCreateDetail"
      });
      // redirect back to create assessment END
    }
    // local theme END
  }

  createSectionUrl = () => {
    HISTORY.push(
      {
        pathname: '/TeacherAssessmentCreateSection',
        state: {
          selectedTheme_id: this.state.selectedTheme_id,
        }
      }
    )
  };

  handleBack = () => {
    // redirect back to create assessment guidelines
    HISTORY.push({
      pathname: "/TeacherAssessmentCreateGuideline"
    });
    // redirect back to create assessment guidelines END
  };

  getThemeList = () => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id,
    };
    this.setState({ showLoader: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetThemeList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getThemesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveThemeAndContine = (theme: any) => {
    const assessmentData = localStorage.getItem("assessmentData");
    const data = JSON.parse(assessmentData || "{}");
    data.assessment_theme = {
      id:theme.id,
      name:theme.attributes?.name,
      image:theme.attributes?.image
    };
    localStorage.setItem("assessmentData", JSON.stringify(data))
    this.createSectionUrl();
  }

  // Customizable Area End
}
