// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { nounCheckSVG, nounCloseSVG, zoomOutIconSVG } from "../assets";
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

interface Props {
  // Customizable Area Start
  questionData: any;
  setPreviewImageSrc?: any;
  isTeacher: boolean;
  classes?: any;
  // Customizable Area End
}

// Customizable Area Start
export const ObjectiveQuestionType = ({
  questionData,
  setPreviewImageSrc,
  isTeacher,
  classes
}: Props) => {
  const [correctAnswer, setCorrectAnswer] = useState<string[]>([]);
  useEffect(() => {
    let correctAnswerArray: any = [];
    questionData?.answers?.map((data: any, index: number) => {
      const title = String.fromCharCode(65 + (index))
      if (data.is_right) {
        correctAnswerArray.push(title)
      }
    })
    setCorrectAnswer(correctAnswerArray)
  }, [questionData])

  const getObjectiveClass = (data: any) => {
    if (questionData?.selected_options?.data) {
      if (data?.is_right) {     
        return "correct_answer_div obj_answer_common"
      } else {
        if (data?.is_right !== data?.attempedAnswer) {
          return "wrong_answer_div obj_answer_common"
        } else {
          return "answer_div obj_answer_common"
        }       
      }
    }
    else {
      return "";
    }
  }

  return (
    <Grid container spacing={2}>
      {(questionData?.question_type === 'pictionary' && questionData?.attachements?.data?.[0]?.attributes?.url) &&
        <Grid item xs={12}>
          <div className="question_image_section">
            <img
              src={questionData?.attachements?.data?.[0]?.attributes?.url}
              alt="image"
              className="objective_attachment"></img>
            <div className="zoom_icon_div" onClick={() => setPreviewImageSrc(questionData?.attachements?.data?.[0]?.attributes?.url)}>
              <img src={zoomOutIconSVG} alt="zoom" className="zoom_icon"></img>
            </div>
          </div>
        </Grid>
      }
      {questionData?.answers?.length > 0 && questionData?.answers?.map((data: any, index: number) => {
        const title = String.fromCharCode(65 + (index))
        if (questionData?.multiple_response) {
          const findedId = questionData?.selected_options?.data?.attributes?.answer_ids?.find((e: any) => Number(e) === Number(data.id))
          data.attempedAnswer = !!findedId
        } else {
          data.attempedAnswer = Boolean(data?.id === questionData?.selected_options?.data?.attributes?.answer_ids?.[0])
        }

        return (
          <Grid item xs={12} sm={6} key={data?.id} className="xs_objective_spacing">
            <div className={getObjectiveClass(data)}>
              <div className="obj_answer_div">
                <div className={data?.attempedAnswer ? "attemped_answer_rectangle" : "mcq_option_rectangle"}>
                  <Typography className={data?.attempedAnswer ? `attemped_answer_title ${classes.theme_font}` : `mcq_option_title ${classes.theme_font}`}>{title}</Typography>
                </div>
                {!data?.attachments?.data?.[0]?.attributes?.url ?
                  <Typography className={`mcq_option_text ${classes.heading_font}`}>{data?.answer}</Typography> :
                  <img src={data?.attachments?.data?.[0]?.attributes?.url} alt="img" className="option_img_data" onClick={() => setPreviewImageSrc(data?.attachments?.data?.[0]?.attributes?.url)}></img>
                }
              </div>
              {questionData?.selected_options?.data &&
                <>
                  {data?.is_right &&
                    <img src={nounCheckSVG} alt="check" />
                  }
                  {(!data?.is_right && data?.is_right !== data?.attempedAnswer) &&
                    <img src={nounCloseSVG} alt="close" />
                  }
                </>
              }
            </div>
          </Grid>
        )
      })}
      <div className="correct_answer_section">
        <Grid container>
          <Grid item xs={12} sm={6} className="correct_answer_left">
            <Typography className={`correct_answer_text ${classes.heading_font}`}>Correct Answer</Typography>
            <div className="correct_answer_rectangle">
              {correctAnswer?.length > 0 && <Typography className="score_text">{correctAnswer.join(",")}</Typography>}
            </div>
            {!questionData?.selected_options?.data &&
              <Typography className={`not_attemped_text ${classes.bodyText_font}`}>Not attempted</Typography>
            }
          </Grid>
          <Grid item xs={12} sm={6} className="score_section">
            <Typography className={`correct_answer_text ${classes.heading_font}`}>Score</Typography>
            <div className="correct_answer_rectangle">
              <Typography className="score_text">
                {questionData?.selected_options?.data?.attributes?.score ? questionData?.selected_options?.data?.attributes?.score : 0}
              </Typography>
            </div>
            <Typography className="score_text"> / {questionData?.marks}</Typography>
          </Grid>
          {(!isTeacher && questionData?.selected_options?.data?.attributes?.comments) &&
            <Grid item xs={12}>
              <Typography className={`title_teachercomment ${classes.theme_font}`}>Teacher Comment:
                <span className={`textlabel_teachercomment ${classes.heading_font}`} dangerouslySetInnerHTML={{
                  __html: questionData?.selected_options?.data?.attributes?.comments,
                }}></span></Typography>

            </Grid>
          }

        </Grid>
      </div>
    </Grid>
  );
};
// Customizable Area End



// Customizable Area Start

export default withStyles(themeCreateStyle)(ObjectiveQuestionType);
// Customizable Area End
