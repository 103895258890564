// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const DocumentIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g id="Teacher-Assesment-" fill={`${props.fillColor}`} fill-rule="evenodd" {...props}>
                <g id="icons" transform="translate(-574.000000, -258.000000)" fill={`${props.fillColor}`} fill-rule="nonzero" {...props}>
                    <g id="document-(1)" {...props} transform="translate(574.000000, 258.000000)">
                        <path d="M22.8511275,8.00631608 C22.8448629,7.64870614 22.6714992,7.31466391 22.3826794,7.10369654 C22.314126,7.02371759 22.2569982,6.94373865 22.1770193,6.8637597 L15.9957893,0.682529699 C15.9211729,0.607956917 15.8409598,0.53920285 15.7558524,0.476869551 C15.5448851,0.188049743 15.2108428,0.0146860502 14.8532329,0.00842143622 C14.8000476,0.00307610957 14.7464603,0.00307610957 14.693275,0.00842143622 C14.5907515,-0.00280714541 14.4873083,-0.00280714541 14.3847848,0.00842143622 L2.28511275,0.00842143622 C1.02307983,0.00842143622 0,1.03150126 0,2.29353419 L0,29.7148872 C0,30.9769202 1.02307983,32 2.28511275,32 L20.5660148,32 C21.8280477,32 22.8511275,30.9769202 22.8511275,29.7148872 L22.8511275,8.47476419 C22.8623561,8.37224065 22.8623561,8.26879752 22.8511275,8.16627397 C22.8564729,8.11308864 22.8564729,8.05950141 22.8511275,8.00631608 Z M18.9550103,6.8637597 L15.9957893,6.8637597 L15.9957893,3.90453868 L18.9550103,6.8637597 Z M2.28511275,29.7148872 L2.28511275,2.29353419 L13.7106765,2.29353419 L13.7106765,6.8637597 C13.7106765,8.12579263 14.7337564,9.14887245 15.9957893,9.14887245 L20.5660148,9.14887245 L20.5660148,29.7148872 L2.28511275,29.7148872 Z" id="Shape"></path>
                        <path d="M16.2357261,14.0504393 C16.0211922,13.8341389 15.7291595,13.7124731 15.4245111,13.7124731 C15.1198627,13.7124731 14.82783,13.8341389 14.6132961,14.0504393 L9.71172921,18.9634317 L8.23783148,17.4781084 C7.94801226,17.1882892 7.52559156,17.0751019 7.12969114,17.1811831 C6.73379073,17.2872643 6.42455731,17.5964978 6.31847612,17.9923982 C6.21239492,18.3882986 6.32558221,18.8107193 6.61540142,19.1005385 L8.90051418,21.3856513 C9.11504811,21.6019516 9.40708079,21.7236175 9.71172921,21.7236175 C10.0163776,21.7236175 10.3084103,21.6019516 10.5229442,21.3856513 L16.2357261,15.6728694 C16.4520265,15.4583354 16.5736924,15.1663028 16.5736924,14.8616543 C16.5736924,14.5570059 16.4520265,14.2649732 16.2357261,14.0504393 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default DocumentIcon;
// Customizable Area End