// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showLoader: boolean;
  termDetailsList: any;
  gradesList: any;
  openTermAlertModal: boolean;
  selectedTermValues: any;
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}
export default class TermDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetGradesList: string = "";
  apiGetTermsList: string = "";
  apiSaveTermDetails: string = "";
  apiEditTermDetails: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      showLoader: false,
      termDetailsList: [],
      gradesList: [],
      openTermAlertModal: false,
      selectedTermValues: {}
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getGradeList();
    this.getTermsList();
    // Customizable Area End
  }

  onChangeTermName = (e: any, index: any) => {
    const updatedTerms = this.state.termDetailsList?.map((item: any, ind: number) => {

      if (ind === index) {
        item.termName = e.target.value;
        if (!e.target.value) {
          item.isError = true;
        } else {
          item.isError = false;
          item.isEdit = true;
        }
      }
      return item
    })
    this.setState({ termDetailsList: updatedTerms })
  }

  onChangeGrade = (e: any, index: any, gradeItem: any, termAssociated: boolean) => {
    if (termAssociated) {
      this.setState({
        openTermAlertModal: true,
        selectedTermValues: {
          event: e, id: index, gradeItem: gradeItem
        }
      })
    } else {
      const updatedTerms = this.state.termDetailsList?.map((item: any, ind: number) => {
        if (ind === index) {
          item.gradesList = e.target.value;
          item.gradeName = gradeItem;
          item.isEdit = true;
        }
        return item
      })
      this.setState({ termDetailsList: updatedTerms })
    }
  }

  onChangeSessionStartDate = (date: any, index: any) => {
    const updatedTerms = this.state.termDetailsList?.map((item: any, ind: number) => {
      if (ind === index) {
        item.sessionStart = date
        item.isEdit = true;
      }
      return item
    })
    this.setState({ termDetailsList: updatedTerms })
  }

  onChangeSessionEndDate = (date: any, index: any) => {
    const updatedTerms = this.state.termDetailsList?.map((item: any, ind: number) => {
      if (ind === index) {
        item.sessionEnd = date
        item.isEdit = true;
        if (moment(date, "DD-MM-YYYY").isBefore(moment(item.sessionStart, "DD-MM-YYYY"))) {
          toast.error("Start Date can't be greater than End Date.")
        }
      }
      return item
    })
    this.setState({ termDetailsList: updatedTerms })
  }

  enableEditChanges = () => {
    const { selectedTermValues } = this.state;
    const updatedTerms = this.state.termDetailsList?.map((item: any, index: number) => {
      if (index === selectedTermValues?.id) {
        item.gradesList = selectedTermValues?.event.target.value;
        item.gradeName = selectedTermValues?.gradeItem;
        item.sessionStart = selectedTermValues?.sessionStart;
        item.sessionEnd = selectedTermValues?.sessionEnd;
        item.isEdit = true;
      }
      return item
    })
    this.setState({ termDetailsList: updatedTerms, openTermAlertModal: false })
  }

  addTermClick = () => {
    const { termDetailsList } = this.state;
    const item = {
      termName: "",
      gradesList: [],
      gradeName: [],
      sessionStart: moment(),
      sessionEnd: moment(),
      addedLocally: true,
    }
    if (termDetailsList?.length === 0) {
      this.setState({ termDetailsList: [...termDetailsList, item] })
    } else {
      const lastElement = termDetailsList[termDetailsList?.length - 1];
      if (!lastElement.termName) {
        toast.error('Please add term name.')
      } else if (lastElement?.gradesList?.length === 0) {
        toast.error('Please select grades.')
      } else {
        this.setState({ termDetailsList: [...termDetailsList, item] })
      }
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Received', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.apiGetGradesList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const updatedGrades = responseJson.data?.map((grade: any) => {
              grade.id = Number(grade.id)
              return grade;
            })
            this.setState({ gradesList: updatedGrades, showLoader: false })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetTermsList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setFormattedTermsData(responseJson?.data);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiSaveTermDetails) {
        if (responseJson != null) {
          if (responseJson.data) {
            toast.success("Term added successfully.")
            this.getTermsList();
          } else {
            const key: any = Object.keys(responseJson?.errors?.[0])
            toast.error(`${responseJson?.errors?.[0][key]}`)
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiEditTermDetails) {
        if (responseJson != null) {
          if (responseJson.data) {
            toast.success("Term edited successfully.")
            this.getTermsList();
          } else {
            const key: any = Object.keys(responseJson?.errors?.[0])
            toast.error(`${key} ${responseJson?.errors?.[0][key]}`)
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }

  setFormattedTermsData = (termsListFromApi: any) => {
    let formattedTerms: any = [];

    termsListFromApi?.map((term: any) => {
      const gradeNames = this.state.gradesList?.filter((grade: any) => term?.attributes?.grade_id.some((val: any) => parseInt(val) === parseInt(grade?.id)));
      const item = {
        id: term?.id,
        termName: term?.attributes?.title.trim(),
        gradesList: term?.attributes?.grade_id,
        gradeName: gradeNames,
        sessionStart: moment(term?.attributes?.session_start, "DD-MM-YYYY"),
        sessionEnd: moment(term?.attributes?.session_end, "DD-MM-YYYY"),
        addedLocally: false,
        isEdit: false,
        isError: false,
        termAssociated: term?.attributes?.term_assosicated
      }
      formattedTerms = [...formattedTerms, item]
    })

    this.setState({ termDetailsList: formattedTerms, showLoader: false })
  }

  getGradeList = () => {
    const user_data = localStorage.getItem('user_data');
    const schoolData = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: schoolData?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetGradesList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGradeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTermsList = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    this.setState({ showLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetTermsList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkForAddedTerms = () => {
    const { termDetailsList } = this.state;
    const isAnyNewData = termDetailsList?.filter((term: any) => term?.addedLocally || term?.isEdit)
    const isAnyError = termDetailsList?.filter((term: any) => term?.isError)
    const flag = !!isAnyError?.length || !isAnyNewData?.length
    return flag
  }

  saveTermDetails = () => {
    if (this.validateTerms()) {
      const { termDetailsList } = this.state;
      const isAnyLocalData = termDetailsList?.filter((term: any) => term?.addedLocally)
      if (isAnyLocalData?.length) {
        this.saveTermDetailsApi()
      }
      const isAnyEditedData = termDetailsList?.filter((term: any) => term?.isEdit && term?.id)
      if (isAnyEditedData?.length) {
        this.editTermDetailsApi()
      }
    }
  }

  validateTerms = () => {
    const { termDetailsList } = this.state;
    let isValid = true;
    const lastElement = termDetailsList[termDetailsList?.length - 1];
    if (!lastElement.termName) {
      toast.error('Please add term name.')
      isValid = false;
    } else if (lastElement?.gradesList?.length === 0) {
      toast.error('Please select grades.')
      isValid = false;
    } else {
      isValid = true;
    }

    const invalidData = termDetailsList?.filter((term: any) => {
      if (moment(term.sessionEnd, "DD-MM-YYYY").isBefore(moment(term.sessionStart, "DD-MM-YYYY"))) {
        return term;
      }
    })

    if (invalidData?.length > 0) {
      isValid = false;
      toast.error("Please check Start Date can't be greater than End Date.")
    } else {
      isValid = true;
    }
    return isValid;
  }

  saveTermDetailsApi = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id
    };

    const { termDetailsList } = this.state;

    let finalItems: any = [];
    termDetailsList?.map((item: any) => {
      if (item?.addedLocally) {
        const termData = {
          title: item.termName.trim(),
          grade_id: item.gradesList,
          school_id: school_Data?.school_id,
          session_start: moment(item?.sessionStart).format("DD-MM-YYYY"),
          session_end: moment(item?.sessionEnd).format("DD-MM-YYYY"),
        }

        finalItems = [...finalItems, termData]
      }
    })
    const httpBody: any = finalItems;
    this.setState({ showLoader: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );

    this.apiSaveTermDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termDetailsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editTermDetailsApi = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id
    };

    const { termDetailsList } = this.state;

    let finalItems: any = [];
    termDetailsList?.map((item: any) => {
      if (item?.isEdit && item.id) {
        const termData = {
          id: item.id,
          title: item.termName.trim(),
          grade_id: item.gradesList,
          school_id: school_Data?.school_id,
          session_start: moment(item?.sessionStart).format("DD-MM-YYYY"),
          session_end: moment(item?.sessionEnd).format("DD-MM-YYYY"),
        }

        finalItems = [...finalItems, termData]
      }
    })

    const httpBody: any = finalItems;
    this.setState({ showLoader: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );

    this.apiEditTermDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editTermsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onCloseTermAlertModal = () => {
    this.setState({ openTermAlertModal: false })
  }

  onChangeTermClass = (e: any, index: number, termItem: any) => {
    const { gradesList } = this.state;
    const gradeItem = gradesList.filter((grade: any) => e.target.value.some((val: any) => parseInt(val) === parseInt(grade.id)));
    this.onChangeGrade(e, index, gradeItem, termItem?.termAssociated)
  }
  // Customizable Area End

}