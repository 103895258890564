// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import _ from "lodash";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
// Customizable Area Start
  class_id?: any;
  subject_id: any;
  createUnitChange: boolean;
  taskScreen: any;
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showSpinner: boolean;
  teacher_id: number;
  teacherSubjects: any;
  viewAllTask: boolean;
  categoryModal: boolean;
  unitModal: boolean;
  teacherTasks: any;
  query: string;
  dateofbirth: any;
  todayBtn: boolean;
  anchorEl: any;
  editModal: boolean;
  deleteModal: boolean;
  shareModal: boolean;
  isOpen: boolean;
  openShareModal: boolean;
  userTypeIsTeacher: boolean;
  classTeacherList: any;
  classStudentList: any;
  sharewithstudent: any;
  sharewithteacher: any;
  prevIds: any;
  isShareChanges: boolean;
  teacherId: number;
  deleteCategoryBoolean: boolean;
  viewModal: boolean;
  task_page: number;
  timeLineMeta: any;
  createrId: number;
  studentIndexNo: number
  task_id: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TaskUnitController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  apigetTeacherSubjectsId: string = "";
  apigetTeacherTaskId: string = "";
  getTeacherListId: string = '';
  getStudentListId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      teacherId: 0,
      showSpinner: false,
      teacher_id: 111,
      teacherSubjects: [],
      unitModal: false,
      teacherTasks: [],
      query: '',
      dateofbirth: '',
      todayBtn: false,
      anchorEl: '',
      editModal: false,
      categoryModal: false,
      viewAllTask: false,
      deleteModal: false,
      shareModal: false,
      isOpen: false,
      openShareModal: false,
      userTypeIsTeacher: false,
      classTeacherList: [],
      classStudentList: [],
      sharewithstudent: [],
      sharewithteacher: [],
      prevIds: [],
      isShareChanges: false,
      deleteCategoryBoolean: false,
      viewModal: false,
      timeLineMeta: [],
      task_page: 0,
      studentIndexNo: 0,
      createrId: 0,
      task_id: '',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.get_userdata = this.get_userdata.bind(this);
    this.get_default_task = this.get_default_task.bind(this);
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.get_default_task();
    this.get_userdata();
  }
  viewAlltaskScreen = () => {
    this.props.taskScreen(!this.state.viewAllTask)
  }
  get_userdata = () => {
    const id: any = localStorage.getItem('id')
    this.setState({
      teacherId: parseInt(id)
    });
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apigetTeacherSubjectsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ teacherSubjects: responseJson })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetTeacherTaskId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            console.log("Abdhsadjasgdjsg")
            this.setState({
              teacherTasks: responseJson.tasks.data,
              timeLineMeta: responseJson.meta,
              showSpinner: false
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ showSpinner: false })
      }
      else if (apiRequestCallId === this.getTeacherListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ classTeacherList: responseJson?.teachers })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showSpinner: false })
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.getStudentListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            let objectKeys = Object.keys(responseJson.data).map((item: any) => item);
            let tempStudentList: any = {};
            objectKeys.map((element: any) => {
              let classStudents = responseJson.data[element].map((element: any) => {
                return {
                  student_id: element.account_id,
                  first_name: element?.account?.data?.attributes?.first_name,
                  last_name: element?.account?.data?.attributes?.last_name,
                  gender: element?.account?.data?.attributes?.gender,
                  avatar: element?.account?.data?.attributes?.avatar,
                  email: element?.account?.data?.attributes?.email
                }
              });
              tempStudentList[element] = classStudents;
            })
            this.setState({ classStudentList: tempStudentList })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showSpinner: false })
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  onCloseModal = (closeModal: boolean) => {
    this.setState({ unitModal: closeModal, editModal: closeModal, deleteModal: closeModal, anchorEl: '', isOpen: false, shareModal: closeModal, viewModal: closeModal })
  }
  onsaveCloseModal = (closeModal: boolean) => {
    this.setState({ unitModal: closeModal, editModal: closeModal, deleteModal: closeModal, anchorEl: '', isOpen: false, shareModal: closeModal, task_page: 0 })
    this.get_default_task();
  }
  deleteCategoryHandler = (CategoryBoolean: boolean) => {
    this.setState({
      deleteCategoryBoolean: CategoryBoolean,
    })
  }
  get_default_task = () => {
    if (this.state.query === '') {
      this.setState({ showSpinner: true })
    }
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherTaskId = requestMessage.messageId;
    let taskUrl = configJSON.getTaskEndPoint
    if (this.state.query != '') {
      taskUrl = taskUrl + `?name=${this.state.query}`
    }
    if (this.state.dateofbirth !== '') {
      taskUrl = taskUrl + `?created_at=${this.state.dateofbirth}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      taskUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  get_task = (page?: any) => {
    this.setState({ showSpinner: true })
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherTaskId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskEndPoint + `?page=${page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  onCateogryCloseModal = (closeModal: boolean) => {
    this.setState({
      categoryModal: !this.state.categoryModal,
    });
    if (this.state.deleteCategoryBoolean) {
      this.get_default_task();
    }
  };
  get_class_teacher = (id: any) => {
    this.setState({ showSpinner: true })
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": data.school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTeacherListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.classTeachersEndPoint + `?grade_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  get_class_student = (id: any) => {
    this.setState({ showSpinner: true })
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStudentListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.gradeStudentEndPoint + `?grade_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  selectUserModal = (openShareModal: boolean, userTypeIsTeacher: boolean) => {
    this.setState({
      openShareModal: openShareModal,
      userTypeIsTeacher: userTypeIsTeacher
    })
  }
  handleCloseSharePopup = () => {
    this.setState({ openShareModal: false })
  }
  get_props_data = (task: any) => {
    let uniqueStudentIds: any = []
    let uniqueTeacherIds: any = []
    let uniqueStudent: any = _.uniqBy(task?.attributes?.student_members, "account_id")
    uniqueStudent.map((_data: any) => uniqueStudentIds.push(_data.account_id))
    let uniqueTeacher: any = _.uniqBy(task?.attributes?.teacher_members, "account_id")
    uniqueTeacher.map((_data: any) => uniqueTeacherIds.push(_data.account_id))
    this.setState({
      sharewithstudent: uniqueStudentIds,
      sharewithteacher: uniqueTeacherIds,
      prevIds: [...task?.attributes?.teacher_members, ...task?.attributes?.student_members]
    })
    this.get_class_teacher(task.attributes.grade_id);
    this.get_class_student(task.attributes.grade_id);
    this.setState({ showSpinner: false })
  }
  saveSelectedUsersId = (list: any) => {
    if (this.state.userTypeIsTeacher) {
      this.setState({ sharewithteacher: list })
    } else {
      this.setState({ sharewithstudent: list })
    }
    this.setState({ openShareModal: false, userTypeIsTeacher: false, isShareChanges: true })
  }
  // Customizable Area End
}
