// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    navigation: any;
    divisiondropDownAnchorEl: any;
    closeModalFun: any;
    subjectData: any;
    division_Data: any;
    datasaveDivisionModalFun: any;
    editData: boolean;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    data: any;
    closeModal: boolean;
    sucessFullModal: Boolean;
    class_id: any;
    responseData: any;
    optional_data: any;
    queryTerm: string;
    searchData: any;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class DivisionSelectDivController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    apigetStudentDivisionId: string = "";
    apigetStudentClasslId: string = "";
    child: React.RefObject<any>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.child = React.createRef();
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            data: '',
            closeModal: false,
            sucessFullModal: false,
            class_id: [],
            responseData: [],
            optional_data: [],
            queryTerm: '',
            searchData: [],
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.userdata = this.userdata.bind(this);
        this.get_student_divison = this.get_student_divison.bind(this);
    }
    
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.get_student_divison();
    }

    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.closeModalFun()
    }
    datasavemodalClose = () => {
        this.setState({ closeModal: true })
        this.props.datasaveDivisionModalFun(this.state.responseData)
    }
    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apigetStudentDivisionId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (this.props.editData) {
                            this.props.division_Data.map((data: any) => {
                                const tempData = responseJson.data
                                const dataIndex = tempData.findIndex((res: any) => parseInt(res.id) === parseInt(data.id))
                                const tempDataIndex = responseJson.data[dataIndex]
                                if (dataIndex === -1) {
                                    const finalData = { ...tempDataIndex, checked: true }
                                    tempData[dataIndex] = finalData
                                }
                                else {
                                    const finalData = { ...tempDataIndex, checked: !tempDataIndex.checked }
                                    tempData[dataIndex] = finalData
                                }
                                this.setState({ responseData: tempData })
                            })
                        }
                        else {
                            this.setState({ responseData: responseJson.data })
                        }
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }

        }
    }
    get_student_divison = () => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetStudentDivisionId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDivisionEndPoint + `?school_id=${school_Data.school_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End

}
