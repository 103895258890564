
// Customizable Area Start
import {
    Box,
    Button,
    MenuItem,
    Select,
    TableContainer,
    TableCell,
    TableRow,
    Table,
    Divider,
    Grid,
    Popover,
    TextField,
    Typography,
    Tooltip,
    FormHelperText,
    TableBody
} from "@material-ui/core";
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useTheme, withStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import AudioList from '../AddQuestion/AudioList.web';
import BarChart from "../AddQuestion/BarChart.web";
import PreviewVideoModal from "../AddQuestion/PreviewVideoModal.web";
import { hintImageIcon, videoThumbnail, zoomoutIcon, imageDragicon, imageClose, drawIcon } from '../assets';
import { ImagePreivewModal } from "./ImagePreivewModal.web";
import StudentStepperWithText from './StudentStepperWithText.web';
import DehazeIcon from '@material-ui/icons/Dehaze';
import { Line } from 'react-chartjs-2';
import { convertDataURLtoFile } from "../../../ClassesAndSections/src/utils/common";
import * as markerjs2 from "markerjs2";
import { TriangleMarker } from "../../../ClassesAndSections/src/TaskUnit/TriangleMarker.web";
import Slider from "react-slick";
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PlotlyGraph from "../AddQuestion/PlotlyGraph.web";
export const configJSON = require("../config");
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './QuestionStudentView.web.css';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";

// Customizable Area End

// Customizable Area Start
// Customizable Area End

export const QuestionStudentView = (props: any) => {

    // Customizable Area Start
    const dragItem = useRef<any>();
    const dragOverItem = useRef<any>();
    const editorImageRef = useRef<any>();
    const [multipleResponse, setMultipleResponse] = useState(false);
    const [previewImageModal, setpreviewImageModal] = useState(false);
    const [openPreviewVideoModal, setOpenPreviewVideoModal] = useState(false);
    const [hintMenu, setHintMenu] = useState(null);
    const [previewImageSrc, setpreviewImageSrc] = useState('');
    const [answerDetails, setAnswerDetails] = useState('');
    const [answerIds, setAnswerIds] = useState<any>([])
    const [activeStep, setActiveStep] = useState<any>(0)
    const [matchlistItems, setmatchListitems] = useState<any>([]);
    const [selectedVideoSrc, setSelectedVideoSrc] = useState<any>([]);
    const [possibleResponseList, setPossibleResponseList] = useState<any>([]);
    const [graphAxisSettings, setGraphAxisSettings] = useState<any>({});
    const [sortListAnswers, setSortListAnswers] = useState<any>([])
    const [imageDragAnswers, setImageDragAnswers] = useState<any>([])
    const [markerImageUrl, setMarkerImageUrl] = useState('');
    const [markerImageFile, setMarkerImageFile] = useState<any>([]);
    const [imageUploadID, setImageUploadID] = useState<any>('')
    const [hotspotAnswerURL, setHotspotAnswerURL] = useState('');
    const [flashCardQuestionArr, setFlashCardQuestionArr] = useState<any>([]);
    const [graphProperties, setGraphProperties] = useState<any>({
        "show_grid": true,
        "show_multicolor_bar": true,
        "display_position_on_hover": true
    });
    const [graphPlottingCategories, setGraphPlottingCategories] = useState<any>([]);
    const [graph_axis_settings_attributes, set_graph_axis_settings_attributes] = useState({
        x_axis_label: '',
        x_minimum_value: 0,
        x_maximum_value: 10,
        y_axis_label: '',
        y_minimum_value: 0,
        y_maximum_value: 10,
        common_step_size: 1,
    })
    const [graph_lines_attributes, set_graph_lines_attributes] = useState([{
        x1: '',
        y1: '',
        x2: '',
        y2: '',
        error: {
            x1: false,
            y1: false,
            x2: false,
            y2: false
        }
    }])
    const [labels, setLabels] = useState<string[]>([]);
    const [labelValues, setLabelValues] = useState<any>([]);
    const theme = useTheme();
    const [activeFlashCardStep, setActiveFlashCardStep] = useState<any>(1);

    const handleNext = () => {
        setActiveFlashCardStep((prevActiveStep: any) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveFlashCardStep((prevActiveStep: any) => prevActiveStep - 1);
    };
    const onChangeStepperValue = (e: any) => {
        let temp = answerIds
        let findIndex = props?.questionData?.attributes?.answers?.data.findIndex((res: any) => parseInt(res?.id) === parseInt(e.target.value))
        setActiveStep(findIndex === -1 ? 0 : findIndex)
        temp[0] = e.target.value
        setAnswerIds(temp)
        props.questionAnswer(answerIds, props.sectionId, props.questionId)
    }
    const generateColor = () => {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;

    };
    useEffect(() => {
        let tempGraphAxisSettings: any = {}
        let tempGraphPlottingCategories: any = []
        setActiveFlashCardStep(1)
        if (props.questionData != undefined) {
            if (props.questionData.attributes?.student_answers?.data.length === 0) {
                setAnswerDetails('')
                setAnswerIds([])
                setActiveStep(0)
                setHotspotAnswerURL('')
                setImageUploadID('')
            }
            else {
                setAnswerDetails(props.questionData?.attributes?.student_answers?.data?.[0]?.attributes?.answer_details)
                setAnswerIds(props.questionData?.attributes?.student_answers?.data?.[0]?.attributes?.answer_ids)
                setMultipleResponse(props.questionData?.attributes?.student_answers?.data?.[0]?.attributes?.mark_for_review)
                let findIndex = props?.questionData?.attributes?.answers?.data.findIndex((res: any) => parseInt(res?.id) === parseInt(props.questionData?.attributes?.student_answers?.data?.[0]?.attributes?.answer_ids[0]))
                setActiveStep(findIndex === -1 ? 0 : findIndex)
                setHotspotAnswerURL(props.questionData?.attributes?.student_answers?.data?.[0]?.attributes?.attachments?.data[props.questionData?.attributes?.student_answers?.data?.[0]?.attributes?.attachments?.data.length - 1]?.attributes?.url)
                setImageUploadID(props.questionData?.attributes?.student_answers?.data?.[0]?.attributes?.attachments?.data[props.questionData?.attributes?.student_answers?.data?.[0]?.attributes?.attachments?.data.length - 1]?.id)
            }
            if (props.questionData?.attributes?.type?.name === 'flash_card') {
                let tempFlashCardData: any = []
                if (props.questionData.attributes?.student_answers?.data.length === 0) {
                    props.questionData?.attributes?.answers.data.map((data: any) => {
                        tempFlashCardData.push({
                            answer: '',
                            ...data
                        })
                    })
                }
                else {
                    props.questionData?.attributes?.answers.data.map((data: any) => {
                        const findIndex = props.questionData.attributes?.student_answers?.data[0].attributes.flash_card_answer.findIndex((res: any) => res.ans_id === data.id)
                        tempFlashCardData.push({
                            answer: props.questionData.attributes?.student_answers?.data[0].attributes.flash_card_answer[findIndex].answer,
                            ...data
                        })
                    })
                }
                setFlashCardQuestionArr(tempFlashCardData)
            }
            if (props.questionData?.attributes?.type?.name === 'line_chart' || props.questionData?.attributes?.type?.name === 'graph_plotting') {
                let chartLabels: string[] = [];
                let chartLabelValues: any = [];
                props.questionData?.attributes?.graph_plotting_setting.data.attributes.axis_setting.data.map((_data: any) => {
                    tempGraphAxisSettings.minimum_value = _data.attributes.minimum_value != null ? _data.attributes.minimum_value : tempGraphAxisSettings.minimum_value
                    tempGraphAxisSettings.maximum_value = _data.attributes.maximum_value != null ? _data.attributes.maximum_value : tempGraphAxisSettings.maximum_value
                    tempGraphAxisSettings.step_size = _data.attributes.step_size != null ? _data.attributes.step_size : tempGraphAxisSettings.step_size
                    tempGraphAxisSettings.x_axis_label = _data.attributes.axis_type === "x_axis" ? _data.attributes.axis_label : tempGraphAxisSettings.x_axis_label
                    tempGraphAxisSettings.y_axis_label = _data.attributes.axis_type != "x_axis" ? _data.attributes.axis_label : tempGraphAxisSettings.y_axis_label
                })
                props.questionData?.attributes?.graph_plotting_setting.data.attributes.graph_categories.data.map((_data: any) => {
                    let correct_answer_value = 0

                    if (props.questionData?.attributes?.student_answers.data.length != 0) {
                        if (props.questionData?.attributes?.student_answers.data[0].attributes.student_chart_answers.length != 0) {
                            const chartFindData = props.questionData?.attributes?.student_answers.data[0].attributes.student_chart_answers.find((res: any) => res.uniq_key === _data.attributes.uniq_key)
                            correct_answer_value = chartFindData.student_answer
                        }
                        else {
                            correct_answer_value = _data.attributes.initial_value
                        }
                    }
                    else {
                        correct_answer_value = _data.attributes.initial_value
                    }
                    tempGraphPlottingCategories.push(
                        {
                            uniq_key: _data.attributes.uniq_key,
                            label: _data.attributes.label,
                            initial_value: _data.attributes.initial_value,
                            correct_answer_value: correct_answer_value,
                            bar_color: generateColor(),
                            fraction_format_type: "fraction"
                        }
                    )
                    chartLabels.push(_data.attributes.label);
                    chartLabelValues.push(Number(correct_answer_value));
                })
                setLabels(chartLabels);
                setLabelValues(chartLabelValues);
                setGraphPlottingCategories(tempGraphPlottingCategories)
                setGraphAxisSettings(tempGraphAxisSettings)
            }
            if (props.questionData?.attributes?.type?.name === 'label_image_with_dropdown' || props.questionData?.attributes?.type?.name === 'label_image_with_text' || props.questionData?.attributes?.type?.name === 'label_image_with_drag_and_drop') {
                setMarkerImageUrl(props.questionData?.attributes?.attachments[0].attributes?.url)
                let tempArray: any = []
                props.questionData?.attributes?.box_coordinates.map((data: any) => {
                    data.student_answer = {}
                    data.right_answer = ''
                    tempArray.push(data)
                })
                if (props.questionData.attributes?.student_answers?.data.length != 0) {
                    if (props.questionData?.attributes?.type?.name === 'label_image_with_text') {
                        props.questionData.attributes?.student_answers?.data[0]?.attributes?.student_label_questions_answer.map((_data: any) => {
                            const findIndex = tempArray.findIndex((res: any) => res.box_uniq_key === _data.box_uniq)
                            const data = tempArray[findIndex];
                            tempArray[findIndex] = {
                                ...data,
                                right_answer: _data.text_answer,
                                student_answer: _data,
                            }
                        })
                    }
                    else if (props.questionData?.attributes?.type?.name === 'label_image_with_drag_and_drop') {
                        props.questionData.attributes?.student_answers?.data[0]?.attributes?.student_label_questions_answer.map((_data: any) => {
                            const findIndex = tempArray.findIndex((res: any) => res.box_uniq_key === _data.box_uniq)
                            const findAnswer = props.questionData?.attributes?.answers?.data?.findIndex((res: any) => res.attributes.ans_uniq_id === _data.answer_uniq_id)
                            const data = tempArray[findIndex];
                            tempArray[findIndex] = {
                                ...data,
                                right_answer: props.questionData?.attributes?.answers?.data[findAnswer]?.attributes?.answer,
                                student_answer: _data,
                            }
                        })
                    }
                    else if (props.questionData?.attributes?.type?.name === 'label_image_with_dropdown') {
                        props.questionData.attributes?.student_answers?.data[0]?.attributes?.student_label_questions_answer.map((_data: any) => {
                            const findIndex = tempArray.findIndex((res: any) => res.box_uniq_key === _data.box_uniq)
                            const findAnswer = props.questionData?.attributes?.answers?.data?.findIndex((res: any) => res.attributes.ans_uniq_id === _data.answer_uniq_id)
                            const data = tempArray[findIndex];
                            tempArray[findIndex] = {
                                ...data,
                                right_answer: _data.answer_uniq_id,
                                student_answer: _data,
                            }
                        })
                    }

                }
                setImageDragAnswers(tempArray)

            }
            if (props.questionData?.attributes?.type?.name === '2x2_grid') {
                let tempLineGraphAxisSettings: any = {}
                let tempLineGraphAttr: any = []
                props.questionData?.attributes?.line_graph_settings.map((_data: any) => {
                    if (_data.axis_label === "y_axis") {
                        tempLineGraphAxisSettings.y_axis_label = _data.label
                        tempLineGraphAxisSettings.y_maximum_value = _data.maximum_value
                        tempLineGraphAxisSettings.y_minimum_value = _data.minimum_value
                        tempLineGraphAxisSettings.common_step_size = _data.step_size
                    }
                    else {
                        tempLineGraphAxisSettings.x_axis_label = _data.label
                        tempLineGraphAxisSettings.x_maximum_value = _data.maximum_value
                        tempLineGraphAxisSettings.x_minimum_value = _data.minimum_value
                        tempLineGraphAxisSettings.common_step_size = _data.step_size
                    }
                })
                if (props.questionData.attributes?.student_answers?.data.length != 0) {
                    props.questionData.attributes?.student_answers?.data[0]?.attributes?.line_graph_answer.map((data: any) => {
                        tempLineGraphAttr.push({
                            x1: data.x1,
                            y1: data.y1,
                            x2: data.x2,
                            y2: data.y2,
                            error: {
                                x1: false,
                                y1: false,
                                x2: false,
                                y2: false
                            }

                        })
                    })
                }
                else {
                    props.questionData.attributes?.answers?.data.map((data: any) => {
                        tempLineGraphAttr.push({
                            x1: '',
                            y1: '',
                            x2: '',
                            y2: '',
                            error: {
                                x1: false,
                                y1: false,
                                x2: false,
                                y2: false
                            }

                        })
                    })
                }
                set_graph_lines_attributes(tempLineGraphAttr)
                set_graph_axis_settings_attributes(tempLineGraphAxisSettings)
            }
            if (props.questionData?.attributes?.type?.name === 'sort_list') {

                if (props.questionData.attributes?.answers?.data.length != 0) {
                    let temp: any = []
                    if (props.questionData.attributes?.student_answers?.data.length != 0) {
                        props.questionData.attributes?.student_answers?.data[0]?.attributes?.sorting_answers.map((data: any) => {
                            temp.push({
                                answerValue: data,
                                tabColor: generateColor()
                            })
                        })
                    }
                    else {
                        props.questionData.attributes?.answers?.data[0]?.attributes?.sorting_answers.map((data: any) => {
                            temp.push({
                                answerValue: data,
                                tabColor: generateColor()
                            })
                        })
                    }
                    setSortListAnswers(temp)
                }
            }
            if (props.questionData?.attributes?.attributes?.match_list_items?.data?.length != 0) {
                let temp: any = []
                if (props.questionData.attributes?.student_answers?.data?.length != 0) {
                    props.questionData?.attributes?.student_answers?.data?.[0]?.attributes?.match_list_answer?.map((res: any) => {
                        const tempData = props.questionData?.attributes?.match_list_items?.data?.filter((res: any) => res.attributes.match_type === 'match_list')
                        const index = tempData.findIndex((data: any) => data.attributes.uniq_id === res.match_list_id)
                        const FindData = tempData[index]
                        const mainData = {
                            ...FindData,
                            correctAnswerId: res.match_response_id,
                            color: (index + 1) % 2 === 0 ? '#f22095' : (index + 1) % 3 === 0 ? '#38aa57' : '#f38925'
                        }
                        temp.push(mainData)
                    })
                    setmatchListitems(temp)
                }

                else {
                    props.questionData?.attributes?.match_list_items.data.filter((res: any) => res.attributes.match_type === 'match_list').map((res: any, index: number) => {
                        const mainData = {
                            ...res,
                            color: (index + 1) % 2 === 0 ? '#f22095' : (index + 1) % 3 === 0 ? '#38aa57' : '#f38925'

                        }
                        temp.push(mainData)
                    })
                    setmatchListitems(temp)

                }
                setPossibleResponseList(props.questionData?.attributes?.match_list_items.data.filter((res: any) => res.attributes.match_type === 'match_response'))
            }

        }
    }, [props.questionData, props.questionData?.attributes?.match_list_items])
    useEffect(() => {
        let chartLabels: string[] = [];
        let chartLabelValues: any = [];
        graphPlottingCategories.map((data: any) => {
            chartLabels.push(data.label)
            chartLabelValues.push(Number(data.correct_answer_value))
        })
        setLabels(chartLabels);
        setLabelValues(chartLabelValues);
    }, [graphPlottingCategories])
    const handleUpdateCategories = (index: number, e: any) => {
        if (e.target.value > Number(graphAxisSettings.maximum_value)) {
            toast.error(`Initial value is greater than ${graphAxisSettings.maximum_value}`);
        } else if (e.target.value < Number(graphAxisSettings.minimum_value)) {
            toast.error(`Initial value is less than ${graphAxisSettings.minimum_value}`);
        }
        let chart_answers: any = [];
        const data = [...graphPlottingCategories];
        const name = e.target.name;
        const value = e.target.value;
        data[index][name] = value;
        setGraphPlottingCategories(data)
        data != null &&
            data.map((_data: any) => {
                chart_answers.push({
                    uniq_key: _data.uniq_key,
                    student_answer: _data.correct_answer_value,
                });
            });
        props.graphPlottingQuestionAnswer(chart_answers, props.sectionId, props.questionId)
    };
    const optionThemeStyles = (data: any, title: string) => {
        switch (props.optionThemeId) {
            case 'Default Test': return (
                <Grid container spacing={1}
                    className='QuestionStudentViewOptionLayoutBorderMainGrid'
                >
                    <Grid item xs={1}>
                        <Box style={{
                            backgroundColor: `${data.color}`,
                        }}
                            className='QuestionStudentViewOptionLayoutDTTitleDiv'
                        >
                            <Typography style={{
                                color: '#fff'
                            }}
                                className="QuestionStudentViewOptionLayoutTitleTxt">
                                {title}
                            </Typography>
                        </Box>
                    </Grid>
                    {data.attributes?.attachments?.data?.length === 0 ?
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutAnswerTxt'>
                            {data?.attributes?.answer}
                        </Grid>
                        :
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutImageDiv'>
                            <img src={data.attributes?.attachments?.data?.[0]?.attributes?.url}
                                className='QuestionStudentViewOptionLayoutImage'
                                onClick={() => {
                                    setpreviewImageModal(true)
                                    setpreviewImageSrc(data.attributes?.attachments?.data?.[0]?.attributes?.url)

                                }}
                            />
                        </Grid>
                    }
                    <Grid item xs={1} className='QuestionStudentViewOptionLayoutRadioDiv'>
                        {
                            props?.questionData?.attributes?.multiple_response
                                ?
                                <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    onChange={() => {
                                        const findIndex = answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id))
                                        const tempIds = answerIds
                                        if (findIndex === -1) {
                                            tempIds.push(data.id)
                                        }
                                        else {
                                            tempIds.splice(findIndex, 1)
                                        }
                                        setAnswerIds(tempIds)
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)

                                    }}
                                >
                                    <WhiteRadio
                                        checked={answerIds?.length === 0 ? false : answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id)) != -1 ? true : false}
                                        value={answerIds.find((res: any) => parseInt(res) === parseInt(data.id))}
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                        checkedIcon={<CheckCircleOutlineIcon />}
                                        size="medium"
                                        onClick={() => {
                                            const filterData = answerIds.filter((res: any) => parseInt(res) != parseInt(data.id))
                                            setAnswerIds(filterData)
                                        }}
                                    />
                                </RadioGroup>
                                :
                                <WhiteRadio
                                    checked={parseInt(answerIds[0]) === parseInt(data.id)}
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'A' }}
                                    checkedIcon={<CheckCircleOutlineIcon />}
                                    size="medium"
                                    onClick={(event: any) => {
                                        answerIds[0] = data.id
                                        setAnswerIds([data.id])
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)
                                    }}
                                />
                        }

                    </Grid>
                </Grid>
            );
            case 'Rounded Rectangle Layout': return (
                <Grid container spacing={1}
                    className='QuestionStudentViewOptionLayoutBorderMainGrid'
                >
                    <Grid item xs={1}>
                        <Box style={{
                            backgroundColor: `${data.color}`,
                        }}
                            className='QuestionStudentViewOptionLayoutRRLTitleDiv'
                        >
                            <Typography
                                style={{
                                    color: "#fff",
                                }}
                                className="QuestionStudentViewOptionLayoutTitleTxt"
                            >
                                {title}
                            </Typography>
                        </Box>
                    </Grid>
                    {data.attributes?.attachments?.data?.length === 0 ?
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutAnswerTxt'>
                            {data?.attributes?.answer}
                        </Grid>
                        :
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutImageDiv'>
                            <img src={data.attributes?.attachments?.data?.[0]?.attributes.url}
                                className='QuestionStudentViewOptionLayoutImage'
                                onClick={() => {
                                    setpreviewImageModal(true)
                                    setpreviewImageSrc(data.attributes?.attachments?.data?.[0]?.attributes.url)

                                }}
                            />
                        </Grid>
                    }
                    <Grid item xs={1} className='QuestionStudentViewOptionLayoutRadioDiv'>
                        {
                            props?.questionData?.attributes?.multiple_response
                                ?
                                <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    onChange={() => {
                                        const findIndex = answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id))
                                        const tempIds = answerIds
                                        if (findIndex === -1) {
                                            tempIds.push(data.id)
                                        }
                                        else {
                                            tempIds.splice(findIndex, 1)
                                        }
                                        setAnswerIds(tempIds)
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)

                                    }}
                                >
                                    <WhiteRadio
                                        checked={answerIds?.length === 0 ? false : answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id)) != -1 ? true : false}
                                        value={answerIds.find((res: any) => parseInt(res) === parseInt(data.id))}
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                        checkedIcon={<CheckCircleOutlineIcon />}
                                        size="medium"
                                        onClick={() => {
                                            const filterData = answerIds.filter((res: any) => parseInt(res) != parseInt(data.id))
                                            setAnswerIds(filterData)
                                        }}
                                    />
                                </RadioGroup>
                                :
                                <WhiteRadio
                                    checked={parseInt(answerIds[0]) === parseInt(data.id)}
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'A' }}
                                    checkedIcon={<CheckCircleOutlineIcon />}
                                    size="medium"
                                    onClick={(event: any) => {
                                        answerIds[0] = data.id
                                        setAnswerIds([data.id])
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)
                                    }}
                                />
                        }

                    </Grid>
                </Grid>
            );
            case 'Outline Rounded Rectangle Layout': return (
                <Grid container spacing={1}
                    className='QuestionStudentViewOptionLayoutBorderMainGrid'
                >
                    <Grid item xs={1}>
                        <Box style={{
                            border: `solid 1px ${data.color}`,
                        }}
                            className='QuestionStudentViewOptionLayoutORRLTitleDiv'
                        >
                            <Typography
                                style={{
                                    color: `${data.color}`,
                                }}
                                className="QuestionStudentViewOptionLayoutTitleTxt">
                                {title}
                            </Typography>
                        </Box>
                    </Grid>
                    {data.attributes?.attachments?.data?.length === 0 ?
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutAnswerTxt'>
                            {data?.attributes?.answer}
                        </Grid>
                        :
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutImageDiv'>
                            <img src={data.attributes?.attachments?.data?.[0]?.attributes?.url}
                                className='QuestionStudentViewOptionLayoutImage'
                                onClick={() => {
                                    setpreviewImageModal(true)
                                    setpreviewImageSrc(data.attributes?.attachments?.data?.[0]?.attributes?.url)

                                }}
                            />
                        </Grid>
                    }
                    <Grid item xs={1} className='QuestionStudentViewOptionLayoutRadioDiv'>
                        {
                            props?.questionData?.attributes?.multiple_response
                                ?
                                <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    onChange={() => {
                                        const findIndex = answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id))
                                        const tempIds = answerIds
                                        if (findIndex === -1) {
                                            tempIds.push(data.id)
                                        }
                                        else {
                                            tempIds.splice(findIndex, 1)
                                        }
                                        setAnswerIds(tempIds)
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)

                                    }}
                                >
                                    <WhiteRadio
                                        checked={answerIds?.length === 0 ? false : answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id)) != -1 ? true : false}
                                        value={answerIds.find((res: any) => parseInt(res) === parseInt(data.id))}
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                        checkedIcon={<CheckCircleOutlineIcon />}
                                        size="medium"
                                        onClick={() => {
                                            const filterData = answerIds.filter((res: any) => parseInt(res) != parseInt(data.id))
                                            setAnswerIds(filterData)
                                        }}
                                    />
                                </RadioGroup>
                                :
                                <WhiteRadio
                                    checked={parseInt(answerIds[0]) === parseInt(data.id)}
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'A' }}
                                    checkedIcon={<CheckCircleOutlineIcon />}
                                    size="medium"
                                    onClick={(event: any) => {
                                        answerIds[0] = data.id
                                        setAnswerIds([data.id])
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)
                                    }}
                                />
                        }

                    </Grid>
                </Grid>
            );
            case 'Circle Layout': return (
                <Grid container spacing={1}
                    className='QuestionStudentViewOptionLayoutCircleMainGrid'
                >
                    <Grid item xs={1}>
                        <Box style={{
                            backgroundColor: `${data.color}`,
                        }}
                            className='QuestionStudentViewOptionLayoutCLTitleDiv'
                        >
                            <Typography
                                style={{
                                    color: "#fff",
                                }}
                                className="QuestionStudentViewOptionLayoutTitleTxt">
                                {title}
                            </Typography>
                        </Box>
                    </Grid>
                    {data.attributes?.attachments?.data?.length === 0 ?
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutAnswerTxt'>
                            {data?.attributes?.answer}
                        </Grid>
                        :
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutImageDiv'>
                            <img src={data.attributes?.attachments?.data?.[0]?.attributes.url}
                                className='QuestionStudentViewOptionLayoutImage'
                                onClick={() => {
                                    setpreviewImageModal(true)
                                    setpreviewImageSrc(data.attributes?.attachments?.data?.[0]?.attributes.url)
                                }}
                            />
                        </Grid>
                    }
                    <Grid item xs={1} className='QuestionStudentViewOptionLayoutRadioDiv'>
                        {
                            props?.questionData?.attributes?.multiple_response
                                ?
                                <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    onChange={() => {
                                        const findIndex = answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id))
                                        const tempIds = answerIds
                                        if (findIndex === -1) {
                                            tempIds.push(data.id)
                                        }
                                        else {
                                            tempIds.splice(findIndex, 1)
                                        }
                                        setAnswerIds(tempIds)
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)
                                    }}
                                >
                                    <WhiteRadio
                                        checked={answerIds?.length === 0 ? false : answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id)) != -1 ? true : false}
                                        value={answerIds.find((res: any) => parseInt(res) === parseInt(data.id))}
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                        checkedIcon={<CheckCircleOutlineIcon />}
                                        size="medium"
                                        onClick={() => {
                                            const filterData = answerIds.filter((res: any) => parseInt(res) != parseInt(data.id))
                                            setAnswerIds(filterData)
                                        }}
                                    />
                                </RadioGroup>
                                :
                                <WhiteRadio
                                    checked={parseInt(answerIds[0]) === parseInt(data.id)}
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'A' }}
                                    checkedIcon={<CheckCircleOutlineIcon />}
                                    size="medium"
                                    onClick={(event: any) => {
                                        answerIds[0] = data.id
                                        setAnswerIds([data.id])
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)
                                    }}
                                />
                        }

                    </Grid>
                </Grid>
            );
            case 'Outline Circle Layout': return (
                <Grid container spacing={1}
                    className='QuestionStudentViewOptionLayoutCircleMainGrid'
                >
                    <Grid item xs={1}>
                        <Box style={{
                            border: `1px solid ${data.color}`,
                        }}
                            className='QuestionStudentViewOptionLayoutOCLTitleDiv'
                        >
                            <Typography
                                style={{
                                    color: `${data.color}`,
                                }}
                                className="QuestionStudentViewOptionLayoutTitleTxt">
                                {title}
                            </Typography>
                        </Box>
                    </Grid>
                    {data.attributes?.attachments?.data?.length === 0 ?
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutAnswerTxt'>

                            {data?.attributes?.answer}
                        </Grid>
                        :
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutImageDiv'>
                            <img src={data.attributes?.attachments?.data?.[0]?.attributes?.url}
                                className='QuestionStudentViewOptionLayoutImage'
                                onClick={() => {
                                    setpreviewImageModal(true)
                                    setpreviewImageSrc(data.attributes?.attachments?.data?.[0]?.attributes?.url)
                                }}
                            />
                        </Grid>
                    }
                    <Grid item xs={1} className='QuestionStudentViewOptionLayoutRadioDiv'>
                        {
                            props?.questionData?.attributes?.multiple_response
                                ?
                                <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    onChange={() => {
                                        const findIndex = answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id))
                                        const tempIds = answerIds
                                        if (findIndex === -1) {
                                            tempIds.push(data.id)
                                        }
                                        else {
                                            tempIds.splice(findIndex, 1)
                                        }
                                        setAnswerIds(tempIds)
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)
                                    }}
                                >
                                    <WhiteRadio
                                        checked={answerIds?.length === 0 ? false : answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id)) != -1 ? true : false}
                                        value={answerIds.find((res: any) => parseInt(res) === parseInt(data.id))}
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                        checkedIcon={<CheckCircleOutlineIcon />}
                                        size="medium"
                                        onClick={() => {
                                            const filterData = answerIds.filter((res: any) => parseInt(res) != parseInt(data.id))
                                            setAnswerIds(filterData)
                                        }}
                                    />
                                </RadioGroup>
                                :
                                <WhiteRadio
                                    checked={parseInt(answerIds[0]) === parseInt(data.id)}
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'A' }}
                                    checkedIcon={<CheckCircleOutlineIcon />}
                                    size="medium"
                                    onClick={(event: any) => {
                                        answerIds[0] = data.id
                                        setAnswerIds([data.id])
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)
                                    }}
                                />
                        }

                    </Grid>
                </Grid>
            );
            case 'Rectangle Block Layout': return (
                <Grid container spacing={1}
                    style={{
                        border: `solid 1px ${data.color}`,
                        backgroundColor: `${data.color}`,
                    }}
                    className='QuestionStudentViewOptionLayoutBlockMainGrid'
                >
                    <Grid item xs={1}>
                        <Box style={{
                            backgroundColor: `${data.color}`,
                        }}
                            className='QuestionStudentViewOptionLayoutRBLTitleDiv'
                        >
                            <Typography
                                style={{
                                    color: "#fff",
                                }}
                                className="QuestionStudentViewOptionLayoutTitleTxt">
                                {title}
                            </Typography>
                        </Box>
                    </Grid>
                    {data.attributes?.attachments?.data?.length === 0 ?
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutAnswerTxt'>
                            {data?.attributes?.answer}
                        </Grid>
                        :
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutImageDiv'>
                            <img src={data.attributes?.attachments?.data?.[0]?.attributes?.url}
                                className='QuestionStudentViewOptionLayoutImage'
                                onClick={() => {
                                    setpreviewImageModal(true)
                                    setpreviewImageSrc(data.attributes?.attachments?.data?.[0]?.attributes.url)
                                }}
                            />
                        </Grid>
                    }
                    <Grid item xs={1} className='QuestionStudentViewOptionLayoutRadioDiv'>
                        {
                            props?.questionData?.attributes?.multiple_response
                                ?
                                <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    onChange={() => {
                                        const findIndex = answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id))
                                        const tempIds = answerIds
                                        if (findIndex === -1) {
                                            tempIds.push(data.id)
                                        }
                                        else {
                                            tempIds.splice(findIndex, 1)
                                        }
                                        setAnswerIds(tempIds)
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)
                                    }}
                                >
                                    <WhiteRadio
                                        checked={answerIds?.length === 0 ? false : answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id)) != -1 ? true : false}
                                        value={answerIds.find((res: any) => parseInt(res) === parseInt(data.id))}
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                        checkedIcon={<CheckCircleOutlineIcon />}
                                        size="medium"
                                        onClick={() => {
                                            const filterData = answerIds.filter((res: any) => parseInt(res) != parseInt(data.id))
                                            setAnswerIds(filterData)
                                        }}
                                    />
                                </RadioGroup>
                                :
                                <WhiteRadio
                                    checked={parseInt(answerIds[0]) === parseInt(data.id)}
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'A' }}
                                    checkedIcon={<CheckCircleOutlineIcon />}
                                    size="medium"
                                    onClick={(event: any) => {
                                        answerIds[0] = data.id
                                        setAnswerIds([data.id])
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)
                                    }}
                                />
                        }

                    </Grid>
                </Grid>
            );
            case 'Outline Rectangle Block Layout': return (
                <Grid container spacing={1}
                    style={{
                        border: `solid 1px ${data.color}`,
                        backgroundColor: "#fff",
                    }}
                    className='QuestionStudentViewOptionLayoutBlockMainGrid'
                >
                    <Grid item xs={1}>
                        <Box className="QuestionStudentViewOptionLayoutORBLTitleDiv ">
                            <Typography
                                style={{
                                    color: `${data.color}`,
                                }}
                                className="QuestionStudentViewOptionLayoutTitleTxt">
                                {title}
                            </Typography>
                        </Box>
                    </Grid>
                    {data.attributes?.attachments?.data?.length === 0 ?
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutAnswerTxt'>
                            {data?.attributes?.answer}
                        </Grid>
                        :
                        <Grid item xs={10}
                            className='QuestionStudentViewOptionLayoutImageDiv'>
                            <img src={data.attributes?.attachments?.data?.[0]?.attributes?.url}
                                className='QuestionStudentViewOptionLayoutImage'
                                onClick={() => {
                                    setpreviewImageModal(true)
                                    setpreviewImageSrc(data.attributes?.attachments?.data?.[0]?.attributes?.url)
                                }}
                            />
                        </Grid>
                    }
                    <Grid item xs={1} className='QuestionStudentViewOptionLayoutRadioDiv'>
                        {
                            props?.questionData?.attributes?.multiple_response
                                ?
                                <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    onChange={() => {
                                        const findIndex = answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id))
                                        const tempIds = answerIds
                                        if (findIndex === -1) {
                                            tempIds.push(data.id)
                                        }
                                        else {
                                            tempIds.splice(findIndex, 1)
                                        }
                                        setAnswerIds(tempIds)
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)

                                    }}
                                >
                                    <WhiteRadio
                                        checked={answerIds?.length === 0 ? false : answerIds.findIndex((res: any) => parseInt(res) === parseInt(data.id)) != -1 ? true : false}
                                        value={answerIds.find((res: any) => parseInt(res) === parseInt(data.id))}
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                        checkedIcon={<CheckCircleOutlineIcon />}
                                        size="medium"
                                        onClick={() => {
                                            const filterData = answerIds.filter((res: any) => parseInt(res) != parseInt(data.id))
                                            setAnswerIds(filterData)
                                        }}
                                    />
                                </RadioGroup>
                                :
                                <WhiteRadio
                                    checked={parseInt(answerIds[0]) === parseInt(data.id)}
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'A' }}
                                    checkedIcon={<CheckCircleOutlineIcon />}
                                    size="medium"
                                    onClick={(event: any) => {
                                        answerIds[0] = data.id
                                        setAnswerIds([data.id])
                                        props.questionAnswer(answerIds, props.sectionId, props.questionId)
                                    }}
                                />
                        }

                    </Grid>
                </Grid>
            );
        }
    }
    const checkIfOptionAlreadyMatched = (responseId: any) => {
        let isMatched = false;
        const findMatchedId = matchlistItems.find((question: any) => (question.correctAnswerId === responseId))
        if (findMatchedId) {
            isMatched = true;
        }
        return isMatched
    }
    const WhiteRadio = withStyles({
        root: {
            color: "#3f526d",
            padding: "0px",
            '&$checked': {
                color: "white",
                backgroundColor: "#3f526d",
                padding: "2px"
            },
        },
        checked: {},
    })((props: RadioProps) => <Radio color="default" {...props} />);
    const dragOver1Answer = (e: any) => {
        e.preventDefault();
    };
    const dragStartAnswer = (e: any) => {
        const target = e.target;
        e.dataTransfer.setData("div_id", target.id);
    };
    const dragStartSortListAnswer = (e: any, position: any) => {
        dragItem.current = position;
    }
    const dragEnterSortListAnswer = (e: any, position: any) => {
        dragOverItem.current = position;
    }
    const dragDropSortListAnswer = () => {
        const copyListItems = [...sortListAnswers];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setSortListAnswers(copyListItems)
        let finalAnswer: any = []
        copyListItems.map((data: any) => {
            finalAnswer.push(data.answerValue)
        })
        props.studentSortListAnswer(finalAnswer, props.sectionId, props.questionId)
    }
    const dragOverAnswer = (e: any) => {
        e.stopPropagation();
    };
    const dropAnswer = useCallback((e: any, questionId: any) => {
        e.preventDefault()
        const div_id = e.dataTransfer.getData("div_id");
        const block = document.getElementById(div_id);
        let myimg = null;
        let mysrc = null;
        if (block) {
            myimg = block.getElementsByTagName('img')[0];
        }
        if (myimg) {
            mysrc = myimg.src;
        }
        if (props.questionData?.attributes?.type?.name === 'label_image_with_text' || props.questionData?.attributes?.type?.name === 'label_image_with_drag_and_drop') {
            let tempQuestionList: any = [...imageDragAnswers]
            const findIndex = tempQuestionList.findIndex((res: any) => res.box_uniq_key === questionId)
            const findAnswer = props.questionData?.attributes?.answers?.data?.findIndex((res: any) => res.attributes.ans_uniq_id === div_id.replace("answer-", ""))
            const data = tempQuestionList[findIndex];
            tempQuestionList[findIndex] = {
                ...data,
                right_answer: props.questionData?.attributes?.answers?.data[findAnswer]?.attributes?.answer,
                student_answer: {
                    box_uniq: questionId,
                    answer_uniq_id: div_id.replace("answer-", ""),
                },
            }
            setImageDragAnswers(tempQuestionList)
            let dataArray: any = []
            tempQuestionList.map((_data: any) => {
                if (Object.keys(_data.student_answer).length != 0) {
                    dataArray.push(_data.student_answer)
                }
            })
            props.handleLabelDropChange(dataArray, props.sectionId, props.questionId)
        }
        else if (props.questionData?.attributes?.type?.name === 'match') {
            let tempQuestionList = matchlistItems
            const index = matchlistItems.findIndex((res: any) => res.attributes.uniq_id === questionId)
            const data = tempQuestionList[index]
            tempQuestionList?.map((item: any) => {
                if (item.correctAnswerId === div_id.replace("answer-", "")) {
                    item.correctAnswerId = "";
                }
                return item;
            })
            tempQuestionList[index] = {
                ...data,
                correctAnswerId: div_id.replace("answer-", ""),

            }
            let dataTable: any = []
            tempQuestionList?.map((data: any) => {
                dataTable.push(data)
            })
            setmatchListitems(dataTable)
            props.handleMatchChange(dataTable, props.sectionId, props.questionId)
        }

    }, [imageDragAnswers, matchlistItems]);
    const handleOnChangeExplaination = (value: any) => {
        setAnswerDetails(value)
        props.answerDetails(value, props.sectionId, props.questionId)
    };
    const selectVideo = (video: any) => {
        setSelectedVideoSrc(video)
        setOpenPreviewVideoModal(true)
    };
    const handleRemoveOptionFromInputBlock = useCallback((id: any) => {
        let tempQuestionList: any = [...imageDragAnswers]
        const findIndex = tempQuestionList.findIndex((res: any) => res.box_uniq_key === id)
        if (findIndex != -1) {
            tempQuestionList[findIndex].right_answer = ''
            tempQuestionList[findIndex].student_answer = {}
        }
        let dataArray: any = []
        tempQuestionList.map((_data: any) => {
            if (Object.keys(_data.student_answer).length != 0) {
                dataArray.push(_data.student_answer)
            }
        })
        props.handleLabelDropChange(dataArray, props.sectionId, props.questionId)
        setImageDragAnswers(tempQuestionList)
    }, [imageDragAnswers]);
    const handletextAnswer = useCallback((e: any, id: any) => {
        let tempQuestionList: any = [...imageDragAnswers]
        const findIndex = tempQuestionList.findIndex((res: any) => res.box_uniq_key === id)
        const data = tempQuestionList[findIndex]
        tempQuestionList[findIndex] = {
            ...data,
            right_answer: e.target.value,
            student_answer: {
                box_uniq: id,
                text_answer: e.target.value,
            },
        }
        setImageDragAnswers(tempQuestionList)
        let dataArray: any = []
        tempQuestionList.map((_data: any) => {
            if (Object.keys(_data.student_answer).length != 0) {
                dataArray.push(_data.student_answer)
            }
        })
        props.handleLabelDropChange(dataArray, props.sectionId, props.questionId)
    }, [imageDragAnswers]);
    const setSelectedAnswer = useCallback((e: any, id: any) => {
        let tempQuestionList: any = [...imageDragAnswers]
        const findIndex = tempQuestionList.findIndex((res: any) => res.box_uniq_key === id)
        const data = tempQuestionList[findIndex]
        tempQuestionList[findIndex] = {
            ...data,
            right_answer: e.target.value,
            student_answer: {
                box_uniq: id,
                answer_uniq_id: e.target.value,
            },
        }
        setImageDragAnswers(tempQuestionList)
        let dataArray: any = []
        tempQuestionList.map((_data: any) => {
            if (Object.keys(_data.student_answer).length != 0) {
                dataArray.push(_data.student_answer)
            }
        })
        props.handleLabelDropChange(dataArray, props.sectionId, props.questionId)
    }, [imageDragAnswers]);
    const showMarkerArea = (imageRef: any, id: any) => {
        let targetImageId: any = document.getElementById(`annotate_${id}`);
        const markerArea = new markerjs2.MarkerArea(targetImageId);
        markerArea.settings.displayMode = 'popup';
        markerArea.availableMarkerTypes = [
            TriangleMarker,
            ...markerArea.BASIC_MARKER_TYPES
        ];
        markerArea.uiStyleSettings.redoButtonVisible = true;
        markerArea.uiStyleSettings.zoomButtonVisible = true;
        markerArea.uiStyleSettings.zoomOutButtonVisible = true;
        markerArea.addEventListener("render", (event: any) => {
            targetImageId.src = event.dataUrl;
            setMarkerImageUrl(event.dataUrl)
            const tempfileData = props.questionData?.attributes?.attachments
            const findIndex = tempfileData.findIndex((data: any) => data.id === id)
            const data = tempfileData[findIndex]
            const obj = data
            obj.annotateImage = event.dataUrl
            const newS = obj
            tempfileData[findIndex] = newS
            const imageDataFileUrl = convertDataURLtoFile(event.dataUrl, tempfileData[findIndex].attributes.file_name)

            setMarkerImageFile(tempfileData)
            const fileUpload = {
                fileName: imageDataFileUrl.name,
                name: imageDataFileUrl.name.split('.')[0],
                fileSize: imageDataFileUrl.size,
                file: imageDataFileUrl,
                content_type: imageDataFileUrl.type
            };
            props.getPreSignedIdData(fileUpload, imageDataFileUrl, imageUploadID, props.sectionId, props.questionId)
        });
        markerArea.show();
    }
    const flashCardOnChange = useCallback((event: any, id: any) => {
        let temp = flashCardQuestionArr
        let index = temp.findIndex((_res: any) => _res.id === id)
        let data = temp[index]
        data.answer = event.target.value
        temp[index] = data
        let finalArray: any = []
        temp.map((_res: any) => {
            finalArray.push({
                ans_id: _res.id,
                answer: _res.answer
            })
        })
        props.flashCardHandleChange(finalArray, props.sectionId, props.questionId)
        setFlashCardQuestionArr(temp)
    }, [flashCardQuestionArr]);
    const questionType = () => {
        switch (props.questionData?.attributes?.type?.name) {
            case 'mcq': return (
                props?.questionData?.attributes?.answers?.data?.map((data: any, index: number) => {
                    const title = String.fromCharCode(65 + (index))
                    const color = generateColor()
                    data.color = (index + 1) % 2 === 0 ? '#f22095' : (index + 1) % 3 === 0 ? '#38aa57' : '#f38925'
                    return (
                        <Grid item xs={12} className='QuestionStudentViewOptionQuestionDiv'>
                            {optionThemeStyles(data, title,)}
                        </Grid>
                    )
                })
            );
            case 'long answer': return (
                <Box>
                    <TextField
                        id="standard-basic"
                        type="text"
                        className="QuestionStudentViewTextFieldQuestion"
                        multiline
                        rows={24}
                        placeholder="Write your answer here..."
                        variant="outlined"
                        inputProps={{
                            className: props.classes.textField_input_theme_style
                        }}
                        value={answerDetails}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value
                            setAnswerDetails(value)
                            props.answerDetails(value, props.sectionId, props.questionId)

                        }}
                    />
                </Box>
            );
            case 'short answer': return (
                <Box>
                    <TextField
                        id="standard-basic"
                        type="text"
                        className="QuestionStudentViewTextFieldQuestion"
                        multiline
                        rows={24}
                        placeholder="Write your answer here..."
                        variant="outlined"
                        inputProps={{
                            className: props.classes.textField_input_theme_style
                        }}
                        value={answerDetails}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value
                            setAnswerDetails(value)
                            props.answerDetails(value, props.sectionId, props.questionId)
                        }}
                    />
                </Box>
            );
            case 'one word': return (
                <Box>
                    <TextField
                        id="standard-basic"
                        type="text"
                        className="QuestionStudentViewTextFieldQuestion"
                        placeholder="Write your answer here..."
                        variant="outlined"
                        inputProps={{
                            className: props.classes.QuestionStudentView_AssessmentTest_fillInBlank_Question
                        }}
                        value={answerDetails}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value.trim()
                            setAnswerDetails(value)
                            props.answerDetails(value, props.sectionId, props.questionId)
                        }}
                    />
                </Box>
            );
            case 'pictionary': return (
                <Box>
                    {props?.questionData?.attributes?.answers?.data?.map((data: any, index: number) => {
                        const title = String.fromCharCode(65 + (index))
                        const color = generateColor()
                        data.color = (index + 1) % 2 === 0 ? '#f22095' : (index + 1) % 3 === 0 ? '#38aa57' : '#f38925'
                        return (
                            <Grid item xs={12} className='QuestionStudentViewOptionQuestionDiv'>
                                {optionThemeStyles(data, title,)}
                            </Grid>
                        )
                    })}
                </Box>
            );
            case 'fill in blank': return (
                <>
                    {props.questionData?.attributes?.text_input
                        ?
                        <Box>
                            <TextField
                                id="standard-basic"
                                type="text"
                                className="QuestionStudentViewTextFieldQuestion"
                                placeholder="Write your answer here..."
                                variant="outlined"
                                inputProps={{
                                    className: props.classes.QuestionStudentView_AssessmentTest_fillInBlank_Question
                                }}
                                value={answerDetails}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = event.target.value
                                    setAnswerDetails(value)
                                    props.answerDetails(value, props.sectionId, props.questionId)
                                }}
                            />
                        </Box>
                        :
                        <>
                            {props?.questionData?.attributes?.answers?.data?.map((data: any, index: number) => {
                                const title = String.fromCharCode(65 + (index))
                                const color = generateColor()
                                data.color = (index + 1) % 2 === 0 ? '#f22095' : (index + 1) % 3 === 0 ? '#38aa57' : '#f38925'
                                return (
                                    <Grid item xs={12} className='QuestionStudentViewOptionQuestionDiv'>
                                        {optionThemeStyles(data, title,)}
                                    </Grid>
                                )
                            })}
                        </>
                    }
                </>
            );
            case 'true false': return (
                props?.questionData?.attributes?.answers?.data?.map((data: any, index: number) => {
                    const title = String.fromCharCode(65 + (index))
                    const color = generateColor()
                    data.color = (index + 1) % 2 === 0 ? '#f22095' : (index + 1) % 3 === 0 ? '#38aa57' : '#f38925'
                    return (
                        <Grid item xs={12} className='QuestionStudentViewOptionQuestionDiv'>
                            {optionThemeStyles(data, title)}
                        </Grid>
                    )
                })
            );
            case 'match': return (
                <>
                    <div className="QuestionStudentViewMatchQuestionMainGrid">
                        <div className="QuestionStudentViewMatchQuestionSubMainGrid">
                            <Typography
                                className="QuestionStudentViewAnswerTitleTxt">
                                Set Correct Answer
                            </Typography>
                            <div className="QuestionStudentViewMatchQuestionMainDiv">
                                {matchlistItems?.map((matchItem: any) => {
                                    const findText = possibleResponseList.find((res: any) => res.attributes.uniq_id === matchItem.correctAnswerId)

                                    return (
                                        <>
                                            <Grid container spacing={3} className='QuestionStudentViewMatchQuestionMainGridContainer'>

                                                {
                                                    matchItem.attributes?.attachments?.data?.length != 0
                                                        ?
                                                        <>
                                                            <Grid item xs={5}>
                                                                <div className="QuestionStudentViewMatchQuestionAttachmentDiv">
                                                                    <img
                                                                        src={matchItem.attributes?.attachments?.data?.[0]?.attributes?.url}
                                                                        className='QuestionStudentViewMatchQuestionAttachmentImg'
                                                                        onClick={() => {
                                                                            setpreviewImageModal(true)
                                                                            setpreviewImageSrc(matchItem.attributes?.attachments?.data?.[0]?.attributes?.url)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        </>
                                                        :
                                                        <Grid item xs={5}>
                                                            <div className="QuestionStudentViewMatchQuestionAttachmentDiv">
                                                                <Typography
                                                                    className="QuestionStudentViewMatchQuestionAttachmentTxt">
                                                                    {matchItem.attributes.list_name}
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                }

                                                <Grid item xs={2} className='QuestionStudentViewMatchQuestionLineDiv'>
                                                    <Box style={{
                                                        backgroundColor: matchItem.color,
                                                    }}
                                                        className='QuestionStudentViewMatchQuestionCircle'
                                                    >
                                                    </Box>
                                                    <Box style={{
                                                        backgroundColor: matchItem.color,
                                                    }}
                                                        className='QuestionStudentViewMatchQuestionLine'
                                                    >
                                                    </Box>
                                                    <Box style={{
                                                        backgroundColor: matchItem.color,
                                                    }}
                                                        className='QuestionStudentViewMatchQuestionCircle'
                                                    >
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={5}>
                                                    {matchItem?.correctAnswerId && matchItem?.correctAnswerId != ''
                                                        ?
                                                        <Typography
                                                            style={{
                                                                border: matchItem?.correctAnswerId && matchItem?.correctAnswerId != '' ? `solid 1px ${matchItem.color}` : `dashed 1px ${matchItem.color}`,
                                                            }}
                                                            className='QuestionStudentViewMatchQuestionCorrectAnswerDiv'
                                                            onDrop={(e: any) => dropAnswer(e, matchItem.attributes.uniq_id)}
                                                            draggable
                                                            id={`answer-${matchItem.correctAnswerId}`}
                                                            onDragStart={dragStartAnswer}
                                                            onDragOver={dragOver1Answer}
                                                        >
                                                            {findText.attributes?.attachments?.data?.length != 0
                                                                ?
                                                                <>
                                                                    <img
                                                                        src={findText?.attributes?.attachments?.data?.[0]?.attributes?.url}
                                                                        className='QuestionStudentViewMatchQuestionCorrectNoAnswerImg'
                                                                        onClick={() => {
                                                                            setpreviewImageModal(true)
                                                                            setpreviewImageSrc(findText?.attributes?.attachments?.data?.[0]?.attributes?.url)
                                                                        }}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        findText?.attributes?.list_name
                                                                    }
                                                                </>
                                                            }
                                                        </Typography>
                                                        :
                                                        <Box style={{
                                                            border: matchItem?.correctAnswerId && matchItem?.correctAnswerId != '' ? 'solid 1px #f68d8d' : 'dashed 1px #f68d8d',
                                                        }}
                                                            className='QuestionStudentViewMatchQuestionCorrectNoAnswerDiv'
                                                            onDrop={(e: any) => dropAnswer(e, matchItem.attributes.uniq_id)}
                                                            draggable
                                                            id={`answer-${matchItem.attributes.uniq_id}`}
                                                            onDragStart={dragStartAnswer}
                                                            onDragOver={dragOver1Answer}
                                                        >

                                                        </Box>
                                                    }
                                                </Grid>

                                            </Grid>
                                        </>

                                    )
                                }
                                )}
                                <div>
                                    <Typography
                                        className="QuestionStudentViewMatchQuestionCorrectDragTxt">
                                        Drag & Drop the Answer
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {possibleResponseList?.map((matchItem: any) => (
                                            <Grid
                                                item
                                                xs={2}
                                            >
                                                <Box
                                                    draggable={!checkIfOptionAlreadyMatched(matchItem.attributes.uniq_id)}
                                                    id={`answer-${matchItem.attributes.uniq_id}`}
                                                    onDragStart={dragStartAnswer}
                                                    onDragOver={dragOverAnswer}
                                                    style={{
                                                        opacity: checkIfOptionAlreadyMatched(matchItem.attributes.uniq_id) ? 0.5 : 1,
                                                    }}
                                                    className='QuestionStudentViewMatchQuestionPossibleResponseDiv'
                                                >
                                                    {
                                                        matchItem?.attributes?.attachments?.data?.length != 0
                                                            ?
                                                            <>
                                                                <img
                                                                    id={`answer-${matchItem.attributes.uniq_id}`}
                                                                    src={matchItem?.attributes?.attachments?.data?.[0]?.attributes?.url}
                                                                    className='QuestionStudentViewMatchQuestionPossibleResponseImg'
                                                                    onClick={() => {
                                                                        setpreviewImageModal(true)
                                                                        setpreviewImageSrc(matchItem?.attributes?.attachments?.data?.[0]?.attributes?.url)
                                                                    }}
                                                                />
                                                            </>
                                                            :
                                                            <Typography
                                                                className="QuestionStudentViewMatchQuestionPossibleResponseTxt">
                                                                {matchItem.attributes.list_name}
                                                            </Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
            case 'text_rating_scale': return (
                <>
                    <StudentStepperWithText
                        steps={props?.questionData?.attributes?.answers?.data}
                        isAnswerSection={true}
                        onChangeStepperValue={onChangeStepperValue}
                        activeStep={activeStep}
                        answerIds={answerIds}
                        questionType={props.questionData?.attributes?.type?.name}
                    />
                </>
            )
            case 'numeric_rating_scale': return (
                <>
                    <StudentStepperWithText
                        steps={props?.questionData?.attributes?.answers?.data}
                        isAnswerSection={true}
                        activeStep={activeStep}
                        onChangeStepperValue={onChangeStepperValue}
                        answerIds={answerIds}
                        questionType={props.questionData?.attributes?.type?.name}
                    />
                </>
            )
            case 'percentage_rating_scale': return (
                <>
                    <StudentStepperWithText
                        steps={props?.questionData?.attributes?.answers?.data}
                        isAnswerSection={true}
                        activeStep={activeStep}
                        onChangeStepperValue={onChangeStepperValue}
                        answerIds={answerIds}
                        questionType={props.questionData?.attributes?.type?.name}
                    />
                </>
            )
            case 'graphic_rating_scale': return (
                <>
                    <StudentStepperWithText
                        steps={props?.questionData?.attributes?.answers?.data}
                        isAnswerSection={true}
                        onChangeStepperValue={onChangeStepperValue}
                        activeStep={activeStep}
                        answerIds={answerIds}
                        questionType={props.questionData?.attributes?.type?.name}
                    />
                </>
            )
            case 'audio': return (
                <>
                    <Box className="QuestionStudentViewAudioVideoTypeQuestionMainDiv">
                        {props.questionData.attributes.attachments?.map((audioItem: any, index: number) => {
                            var hms = audioItem.attributes.duration;
                            var a = hms.split(':');
                            var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
                            let audioData = {
                                duration: seconds,
                                duration_time: audioItem.attributes.duration,
                                file_url: audioItem.attributes.url,
                                file_name: audioItem.attributes.file_name
                            }
                            return (
                                <Box className="QuestionStudentViewAudioTypeQuestionListDiv">
                                    <AudioList
                                        key={audioItem.id}
                                        audio={audioData}
                                        showActions={false}
                                        isStudent={true}
                                    />
                                </Box>
                            )
                        }
                        )}
                    </Box>
                    <Box className="QuestionStudentViewAudioVideoTypeQuestionAnsweerTxtDiv"
                    >
                        <Typography className={`${props.classes.subHeading_font} writeAnswerTextTypography`}>
                            Write Answer
                        </Typography>
                    </Box>
                    {/* <div ref={audioEditorRef}>
                        <TextEditor
                            myref={audioEditorRef}
                            handleChange={(value: any) => {
                                handleOnChangeExplaination(value)
                            }}
                            editorData={props.questionData.attributes.student_answers.data.length != 0 ? props.questionData.attributes.student_answers?.data[0].attributes.answer_details : ''}
                        />
                    </div> */}
                    <Box>
                        <TextField
                            id="standard-basic"
                            type="text"
                            className="QuestionStudentViewTextFieldQuestion"
                            multiline
                            rows={24}
                            placeholder="Write your answer here..."
                            variant="outlined"
                            inputProps={{
                                className: props.classes.textField_input_theme_style
                            }}
                            value={answerDetails}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value
                                setAnswerDetails(value)
                                props.answerDetails(value, props.sectionId, props.questionId)

                            }}
                        />
                    </Box>
                </>
            )
            case 'video': return (
                <>
                    <Box className="QuestionStudentViewAudioVideoTypeQuestionMainDiv">
                        {props.questionData.attributes.attachments?.map((videoItem: any, index: number) => (
                            <React.Fragment key={videoItem?.id}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        className='QuestionStudentViewVideoTypeQuestionListDiv'
                                    >
                                        <img
                                            src={videoThumbnail}
                                            className="video-thumbnail-img"
                                            alt="thumbnail"
                                            onClick={() => selectVideo(videoItem.attributes.url)}
                                        ></img>
                                        <video
                                            id={`video-${index}`}
                                            className="video-thumbnail-img"
                                            src={videoItem.attributes.url}
                                            controls
                                            hidden
                                        ></video>
                                        <div
                                            className="QuestionStudentViewVideoTypeQuestionListSubDiv"
                                        >
                                            <div
                                                className={`video-recording-text ${props.classes.subHeading_font}`}
                                            >
                                                {videoItem?.attributes.file_name}
                                            </div>
                                            <div
                                                className={`video-recording-duration ${props.classes.subHeading_font}`}
                                            >
                                                {videoItem?.attributes.duration}
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </Box>

                    <Box className="QuestionStudentViewAudioVideoTypeQuestionAnsweerTxtDiv"
                    >
                        <Typography className={`${props.classes.subHeading_font} writeAnswerTextTypography`}>
                            Write Answer
                        </Typography>
                    </Box>
                    {/* <div ref={audioEditorRef}>
                        <TextEditor
                            myref={audioEditorRef}
                            handleChange={(value: any) => {
                                handleOnChangeExplaination(value)
                            }}
                            editorData={props.questionData.attributes.student_answers.data.length != 0 ? props.questionData.attributes.student_answers?.data[0].attributes.answer_details : ''}
                        />
                    </div> */}
                    <Box>
                        <TextField
                            id="standard-basic"
                            type="text"
                            className="QuestionStudentViewTextFieldQuestion"
                            multiline
                            rows={24}
                            placeholder="Write your answer here..."
                            variant="outlined"
                            inputProps={{
                                className: props.classes.textField_input_theme_style
                            }}
                            value={answerDetails}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value
                                setAnswerDetails(value)
                                props.answerDetails(value, props.sectionId, props.questionId)

                            }}
                        />
                    </Box>
                </>
            )
            case 'graph_plotting': return (
                <>
                    <Box className="QuestionStudentViewChartQuestionMainDiv">
                        <Box className="QuestionStudentViewChartQuestionSubDiv">
                            <BarChart classes={props.classes} graph_axis_settings_attributes={graphAxisSettings} graph_plotting_categories_attributes={graphPlottingCategories} graph_properties={graphProperties} />
                        </Box>
                        <Box
                            className="QuestionStudentViewChartQuestionAnswerTitleTxt"
                        >
                            <Typography className={`${props.classes.subHeading_font} writeAnswerTextTypography`}>
                                Write Answer
                            </Typography>
                        </Box>
                        <Box className="QuestionStudentViewChartQuestionSubDiv">
                            <Grid container spacing={5}>
                                <TableContainer>
                                    <Table>
                                        <TableRow>
                                            {graphPlottingCategories.map((category: any, index: number) => (
                                                <TableCell key={index} className='QuestionStudentViewChartQuestionMainTableCell'>
                                                    <Box className="QuestionStudentViewChartQuestionMainTableCellDiv">
                                                        <Typography
                                                            className={`QuestionStudentViewChartQuestionMainTableCellTxt ${props.classes.subHeading_font}`}
                                                        >
                                                            {category.label ? category.label : `Bar-${index + 1} `}
                                                        </Typography>

                                                        <TextField
                                                            variant="standard"
                                                            name="correct_answer_value"
                                                            type="number"
                                                            value={category.correct_answer_value}
                                                            placeholder="80"
                                                            className={`QuestionStudentViewChartQuestionTextField ${props.classes.bodyText_font}`}
                                                            onChange={(e: any) => {
                                                                handleUpdateCategories(index, e)
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                            }}
                                                        />
                                                    </Box>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </Table>
                                </TableContainer>

                            </Grid>
                        </Box>
                    </Box>
                </>
            )
            case 'sort_list': return (
                <>
                    <div>
                        <div className="QuestionStudentViewSortingQuestionMainDiv">
                            <Typography
                                className={`QuestionStudentViewSortingQuestionAnswerTxt ${props.classes.subHeading_font}`}
                            >
                                Resequence the correct answer
                            </Typography>
                        </div>
                        <Grid container className="QuestionStudentViewSortingQuestionMainDiv">
                            <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
                                <div>
                                    {sortListAnswers.map((_data: any, index: any) => (
                                        <div className="QuestionStudentViewSortingQuestionSubDiv">
                                            <div
                                                style={{
                                                    border: `solid 1px ${_data.tabColor}`,
                                                }}
                                                className='QuestionStudentViewSortingQuestionDragDiv'
                                                draggable
                                                id={`answer-${_data.answerValue}`}
                                                onDragStart={(e: any) => dragStartSortListAnswer(e, index)}
                                                onDragEnter={(e) => dragEnterSortListAnswer(e, index)}
                                                onDragEnd={dragDropSortListAnswer}
                                            >
                                                <DehazeIcon
                                                    style={{
                                                        color: `${_data.tabColor}`,
                                                    }}
                                                    className='QuestionStudentViewSortingQuestionDahzeIcon'
                                                />
                                                {_data.answerValue}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </>
            )
            case 'label_image_with_drag_and_drop': return (
                <>
                    <div>
                        <div className="QuestionStudentViewLabelTypeQuestionMainDiv">
                            <Typography
                                className={`QuestionStudentViewLabelTypeQuestionAnswetTitleTxt ${props.classes.subHeading_font}`}
                            >
                                Set correct answer
                            </Typography>
                        </div>
                        <div>
                            {props.questionData?.attributes?.attachments?.length !== 0 &&
                                props.questionData?.attributes?.attachments[0].attributes?.url &&
                                props.questionData?.attributes?.attachments[0].attributes?.url !== "" &&
                                <>
                                    <div
                                        className="QuestionStudentViewLabelTypeQuestionAttachmentDiv"
                                    >
                                        <div
                                            className="QuestionStudentViewLabelTypeQuestionAttachmentSubDiv"
                                        >
                                            <img
                                                src={props.questionData?.attributes?.attachments[0].attributes.url}
                                                className='QuestionStudentViewLabelTypeQuestionAttachmentImg'
                                            ></img>
                                            {imageDragAnswers.length > 0 &&
                                                imageDragAnswers.map((item: any, index: number) => (
                                                    <>
                                                        <div
                                                            key={index}
                                                            style={{
                                                                top: `${item.margin_top}%`,
                                                                left: `${item.margin_left}%`,
                                                            }}
                                                            className={`${props.classes.bodyText_font} label_image_block QuestionStudentViewLabelTypeQuestionAnswerMainDiv`}
                                                            onDrop={(e: any) => dropAnswer(e, item?.box_uniq_key)}
                                                            id={`questionBlockDiv-${item.box_uniq_key}`}
                                                            onDragStart={dragStartAnswer}
                                                            onDragOver={dragOver1Answer}
                                                        >
                                                            <Tooltip title={item.right_answer} placement="top-start">
                                                                <span
                                                                    id={`questionBlock-${item.box_uniq_key}`}
                                                                    className={`QuestionStudentViewLabelTypeQuestionTooltipSpanDiv ${props.classes.bodyText_font}`}
                                                                >
                                                                    {item.right_answer}
                                                                </span>
                                                            </Tooltip>
                                                            {item.right_answer != '' && (
                                                                <span>
                                                                    <img
                                                                        src={imageClose}
                                                                        className="hotspot_question_delete_icon"
                                                                        onClick={() => {
                                                                            handleRemoveOptionFromInputBlock(
                                                                                item.box_uniq_key
                                                                            );
                                                                        }}
                                                                    ></img>
                                                                </span>
                                                            )}
                                                        </div>
                                                    </>

                                                ))}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div>
                            <Typography
                                className={`QuestionStudentViewLabelTypeQuestionAnswerTitleDiv ${props.classes.subHeading_font}`}
                            >
                                Drag & Drop the Answer
                            </Typography>
                            <div
                                className="QuestionStudentViewLabelTypeQuestionPossibleDragAnswerDiv"
                            >
                                {props.questionData?.attributes?.answers?.data?.filter((item: any) => item?.attributes.answer != null)
                                    .map((data: any, index: number) => {
                                        const findIndex = imageDragAnswers.findIndex((res: any) => res.right_answer === data?.attributes.answer)
                                        return (
                                            <>
                                                <div
                                                    key={data.responseId || index}
                                                    style={{
                                                        opacity: findIndex != -1
                                                            ? 0.5
                                                            : 1,
                                                    }}
                                                    className='QuestionStudentViewLabelTypeQuestionPossibleDragAnswerSubDiv'
                                                    draggable={findIndex != -1 ? false : true}
                                                    id={`answer-${data.attributes.ans_uniq_id}`}
                                                    onDragStart={dragStartAnswer}
                                                    onDragOver={dragOver1Answer}
                                                >
                                                    <Typography
                                                        className="QuestionStudentViewLabelTypeQuestionDragAnswerSubDivTxt"
                                                    >
                                                        {data?.attributes.answer}
                                                    </Typography>
                                                </div>
                                            </>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </>
            )
            case 'label_image_with_text': return (
                <>
                    <>
                        <div>
                            <div className="QuestionStudentViewLabelTypeQuestionMainDiv">
                                <Typography
                                    className={`QuestionStudentViewLabelTypeQuestionAnswetTitleTxt ${props.classes.subHeading_font}`}
                                >
                                    Set correct answer
                                </Typography>
                            </div>
                            <div>
                                {props.questionData?.attributes?.attachments?.length !== 0 &&
                                    props.questionData?.attributes?.attachments[0].attributes?.url &&
                                    props.questionData?.attributes?.attachments[0].attributes?.url !== "" &&
                                    <>
                                        <div
                                            className="QuestionStudentViewLabelTypeQuestionAttachmentDiv"
                                        >
                                            <div
                                                className="QuestionStudentViewLabelTypeQuestionAttachmentSubDiv"
                                            >
                                                <img
                                                    src={props.questionData?.attributes?.attachments[0].attributes.url}
                                                    className='QuestionStudentViewLabelTypeQuestionAttachmentImg'
                                                ></img>

                                                {imageDragAnswers.length > 0 &&
                                                    imageDragAnswers.map((item: any, index: number) => (
                                                        <>
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    top: `${item.margin_top}%`,
                                                                    left: `${item.margin_left}%`,
                                                                }}
                                                                className={`${props.classes.bodyText_font} label_image_block QuestionStudentViewLabelTypeQuestionAnswerMainDiv`}
                                                                id={`questionBlockDiv-${item.box_uniq_key}`}

                                                            >
                                                                <TextField
                                                                    value={item.right_answer}
                                                                    className={props.classes.input_theme_style}
                                                                    variant="standard"
                                                                    onChange={(e: any) => {
                                                                        handletextAnswer(e, item.box_uniq_key)
                                                                    }}
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                    }}
                                                                />
                                                            </div>
                                                        </>

                                                    ))}
                                            </div>
                                        </div>
                                    </>}
                            </div>
                        </div>
                    </>
                </>
            )
            case 'label_image_with_dropdown': return (
                <>
                    <>
                        <div>
                            <div className="QuestionStudentViewLabelTypeQuestionMainDiv">
                                <Typography
                                    className={`QuestionStudentViewLabelTypeQuestionAnswetTitleTxt ${props.classes.subHeading_font}`}
                                >
                                    Set correct answer
                                </Typography>
                            </div>
                            <div>
                                {props.questionData?.attributes?.attachments?.length !== 0 &&
                                    props.questionData?.attributes?.attachments[0].attributes?.url &&
                                    props.questionData?.attributes?.attachments[0].attributes?.url !== "" &&
                                    <>
                                        <div
                                            className="QuestionStudentViewLabelTypeQuestionAttachmentDiv"
                                        >
                                            <div
                                                className="QuestionStudentViewLabelTypeQuestionAttachmentSubDiv"
                                            >
                                                <img
                                                    src={props.questionData?.attributes?.attachments[0].attributes.url}
                                                    className='QuestionStudentViewLabelTypeQuestionAttachmentImg'
                                                ></img>

                                                {imageDragAnswers.length > 0 &&
                                                    imageDragAnswers.map((item: any, index: number) => (
                                                        <>
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    top: `${item.margin_top}%`,
                                                                    left: `${item.margin_left}%`,
                                                                }}
                                                                className={`${props.classes.bodyText_font} label_image_block QuestionStudentViewLabelTypeQuestionAnswerMainDiv`}
                                                                id={`questionBlockDiv-${item.box_uniq_key}`}

                                                            >
                                                                <Select
                                                                    value={item.right_answer}
                                                                    className={`QuestionStudentViewLabelTypeQuestionAnswerDropDown ${props.classes.input_theme_style}`}
                                                                    disableUnderline
                                                                    onChange={(e) => setSelectedAnswer(e, item.box_uniq_key)}
                                                                >
                                                                    {
                                                                        item?.answers?.map((options: any, idx: number) => (
                                                                            <MenuItem key={idx} value={options.ans_uniq_id}>{options.answer}</MenuItem>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </div>
                                                        </>

                                                    ))}
                                            </div>
                                        </div>
                                    </>}
                            </div>
                        </div>
                    </>
                </>
            )
            case 'line_chart': return (
                <>
                    <Box className="QuestionStudentViewChartQuestionMainDiv">
                        <Box className="QuestionStudentViewChartQuestionSubDiv">
                            <Line
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        title: {
                                            display: false,
                                        },
                                        tooltip: {
                                            enabled: true
                                        }
                                    },
                                    scales: {
                                        y: {
                                            title: {
                                                display: true,
                                                text: graphAxisSettings.y_axis_label,
                                                color: '#3f526d',
                                                font: {
                                                    size: 20,
                                                    weight: '600',
                                                    family: 'OpenSans'
                                                }
                                            },
                                            max: parseInt(graphAxisSettings.maximum_value !== '' ? graphAxisSettings.maximum_value : 10),
                                            min: parseInt(graphAxisSettings.minimum_value !== '' ? graphAxisSettings.minimum_value : 0),
                                            ticks: {
                                                stepSize: parseInt(graphAxisSettings.step_size !== '' ? graphAxisSettings.step_size : 1)
                                            },
                                            grid: {
                                                display: graphAxisSettings?.show_grid
                                            }
                                        },
                                        x: {
                                            title: {
                                                display: true,
                                                text: graphAxisSettings.x_axis_label,
                                                color: '#3f526d',
                                                font: {
                                                    size: 20,
                                                    weight: '600',
                                                    family: 'OpenSans'
                                                }
                                            },
                                            grid: {
                                                display: false
                                            }
                                        }
                                    }
                                }}
                                data={{
                                    labels,
                                    datasets: [
                                        {
                                            data: labelValues,
                                            borderColor: '#c1c1c1',
                                            pointBorderWidth: 6,
                                            backgroundColor: '#c1c1c1'
                                        }
                                    ],
                                }}
                            />
                        </Box>
                        <Box className="QuestionStudentViewChartQuestionAnswerTitleTxt"
                        >
                            <Typography className={`${props.classes.subHeading_font} writeAnswerTextTypography`}>
                                Write Answer
                            </Typography>
                        </Box>
                        <Box className="QuestionStudentViewChartQuestionSubDiv">
                            <Grid container spacing={5}>
                                <TableContainer>
                                    <Table>
                                        <TableRow>
                                            {graphPlottingCategories.map((category: any, index: number) => (
                                                <TableCell key={index} className='QuestionStudentViewChartQuestionMainTableCell'>
                                                    <Box className="QuestionStudentViewChartQuestionMainTableCellDiv">
                                                        <Typography
                                                            className={`QuestionStudentViewChartQuestionMainTableCellTxt ${props.classes.subHeading_font}`}
                                                        >
                                                            {category.label ? category.label : `Bar-${index + 1} `}
                                                        </Typography>

                                                        <TextField
                                                            variant="standard"
                                                            name="correct_answer_value"
                                                            type="number"
                                                            value={category.correct_answer_value}
                                                            placeholder="80"
                                                            className={`QuestionStudentViewChartQuestionTextField ${props.classes.bodyText_font}`}
                                                            onChange={(e: any) => {
                                                                handleUpdateCategories(index, e)
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                            }}
                                                        />
                                                    </Box>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </Table>
                                </TableContainer>

                            </Grid>
                        </Box>
                    </Box>
                </>

            )
            case 'hotspot': return (
                <>
                    <div>
                        <div className="QuestionStudentViewLabelTypeQuestionMainDiv">
                            <Typography
                                className={`QuestionStudentViewLabelTypeQuestionAnswetTitleTxt ${props.classes.subHeading_font}`}
                            >
                                Set Correct Answer(s) - Mark Area
                            </Typography>
                        </div>
                        <div
                            className="QuestionStudentViewHotspotTypeQuestionMainDiv">
                            {props.questionData?.attributes?.attachments?.length !== 0 &&
                                props.questionData?.attributes?.attachments[0].attributes?.url &&
                                props.questionData?.attributes?.attachments[0].attributes?.url !== "" &&
                                <>
                                    {props.questionData?.attributes?.attachments.map((data: any, index: any) => (
                                        <Box className="QuestionStudentViewHotspotTypeQuestionAttachmentDiv">
                                            <Button onClick={() => {
                                                showMarkerArea(editorImageRef, data?.id)
                                            }}
                                                className={`QuestionStudentViewHotspotTypeQuestionDrawBtn ${props.classes.button_color}`}
                                            >
                                                <img src={drawIcon} className='QuestionStudentViewHotspotTypeQuestionDrawIcon' />
                                                Draw
                                            </Button>
                                            <Box className="QuestionStudentViewHotspotTypeQuestionImgDiv">
                                                <img
                                                    ref={editorImageRef}
                                                    id={`annotate_${data?.id}`}
                                                    src={`${data.annotateImage ? data.annotateImage : hotspotAnswerURL != '' ? hotspotAnswerURL : data.attributes.url}`}
                                                    alt="answer-img"
                                                    crossOrigin="anonymous"
                                                    className="QuestionStudentViewHotspotTypeQuestionImg"
                                                />
                                            </Box>
                                        </Box>
                                    ))}
                                </>
                            }
                        </div>
                    </div>
                </>
            )
            case 'flash_card': return (
                <>
                    <Box className="QuestionStudentViewFlashCardTypeQuestionMainDiv">
                        <Grid
                            container
                            spacing={2}
                        >
                            {flashCardQuestionArr.map((_flashCardData: any, index: any) => (
                                <>
                                    {index === 0 && activeFlashCardStep == 1 &&
                                        <Grid item xs={12} sm={4} md={4} lg={4} className={activeFlashCardStep === index + 1 ? `activeFlashCard_div` : `inActiveFlashCard_div`}>
                                        </Grid>
                                    }
                                    {index === activeFlashCardStep &&
                                        <Grid item xs={12} sm={4} md={4} lg={4} className={activeFlashCardStep === index + 1 ? `activeFlashCard_div` : `inActiveFlashCard_div`}>
                                            {flashCardStudentAnswer(_flashCardData, index)}
                                        </Grid>
                                    }
                                    {index + 1 === activeFlashCardStep &&
                                        <Grid item xs={12} sm={4} md={4} lg={4} className={activeFlashCardStep === index + 1 ? `activeFlashCard_Center_div` : `inActiveFlashCard_Center_div`}>
                                            {flashCardStudentAnswer(_flashCardData, index)}
                                        </Grid>
                                    }
                                    {index + 2 === activeFlashCardStep &&
                                        <Grid item xs={12} sm={4} md={4} lg={4} className={activeFlashCardStep === index + 1 ? `activeFlashCard_div` : `inActiveFlashCard_div`}>
                                            {flashCardStudentAnswer(_flashCardData, index)}
                                        </Grid>
                                    }
                                </>
                            ))}
                        </Grid>
                    </Box>

                    <MobileStepper
                        variant="progress"
                        steps={flashCardQuestionArr.length + 1}
                        position="static"
                        activeStep={activeFlashCardStep}
                        nextButton={
                            <Button
                                size="small"
                                disableRipple={true}
                                variant="contained"
                                onClick={handleNext}
                                disabled={activeFlashCardStep === flashCardQuestionArr.length}
                                className={activeFlashCardStep === flashCardQuestionArr.length ? props.classes.questionStudenView_AssessmentTest_inActiveFlashCardbtn : props.classes.questionStudenView_AssessmentTest_activeFlashCardbtn}
                            >
                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                        }
                        backButton={
                            <Button
                                size="small"
                                disableRipple={true}
                                variant="contained"
                                onClick={handleBack}
                                disabled={activeFlashCardStep === 1}
                                className={activeFlashCardStep === 1 ? props.classes.questionStudenView_AssessmentTest_inActiveFlashCardbtn : props.classes.questionStudenView_AssessmentTest_activeFlashCardbtn}
                            >
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            </Button>
                        }
                    />
                </>
            )
            case '2x2_grid': return (
                <>
                    {/* Correct Answers */}
                    <Box className='_2x2_lineGraphDiv'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <PlotlyGraph
                                    classes={props.classes}
                                    setting={graph_axis_settings_attributes}
                                    graph_lines={graph_lines_attributes}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography className={`${props.classes.subHeading_font} writeAnswerTextTypography`}>
                        Write Answer
                    </Typography>
                    <Box className='_2x2_graphLinesAttrDiv'>
                        <TableBody>
                            <TableRow >
                                <TableCell className='_2x2_graphLinesAttrDiv_tableCell'></TableCell>
                                <TableCell className={`_2x2_graphLinesAttrDiv_tableCell ${props.classes.subHeading_font}`}>
                                    Start (x1,
                                </TableCell>
                                <TableCell className={`_2x2_graphLinesAttrDiv_tableCell ${props.classes.subHeading_font}`}>
                                    y1)
                                </TableCell>
                                <TableCell className={`_2x2_graphLinesAttrDiv_tableCell ${props.classes.subHeading_font}`}>
                                    End (x2,
                                </TableCell>
                                <TableCell className={`_2x2_graphLinesAttrDiv_tableCell ${props.classes.subHeading_font}`}>
                                    y2)
                                </TableCell>
                            </TableRow>
                            {graph_lines_attributes.map((line: any, index: number) => {
                                return (
                                    <>
                                        <TableRow key={line.uniq_key}>
                                            <TableCell className={`_2x2_graphLinesAttrDiv_tableCell ${props.classes.subHeading_font}`}>
                                                Line-{index + 1}
                                            </TableCell>
                                            <TableCell className='_2x2_graphLinesAttrDiv_tableCell'>
                                                <div className='_2x2_graphLinesAttrDiv_tableCell_Div'>
                                                    <TextField
                                                        style={{
                                                            border: line.error.x1
                                                                ? "1px solid red"
                                                                : "none",
                                                        }}
                                                        className={`_2x2_graphLinesAttrDiv_tableCell_Textfield ${props.classes.bodyText_font}`}
                                                        variant="standard"
                                                        type="number"
                                                        name="x1"
                                                        placeholder='0'
                                                        value={line.x1}
                                                        onChange={(e: any) => handleUpdateLinesData(index, e)}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                        }}
                                                    />
                                                    {line.error.x1 &&
                                                        <FormHelperText className='_2x2_graphLinesAttrDiv_tableCell_Error'>
                                                            Value should be in between {`(${graph_axis_settings_attributes.x_minimum_value}, ${graph_axis_settings_attributes.x_maximum_value})`}
                                                        </FormHelperText>
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell className='_2x2_graphLinesAttrDiv_tableCell'>
                                                <div className='_2x2_graphLinesAttrDiv_tableCell_Div'>
                                                    <TextField
                                                        style={{
                                                            border: line.error.y1
                                                                ? "1px solid red"
                                                                : "none",
                                                        }}
                                                        variant="standard"
                                                        type="number"
                                                        name="y1"
                                                        value={line.y1}
                                                        placeholder='0'
                                                        className={`_2x2_graphLinesAttrDiv_tableCell_Textfield ${props.classes.bodyText_font}`}
                                                        onChange={(e: any) => handleUpdateLinesData(index, e)}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                        }}
                                                    />
                                                    {line.error.y1 &&
                                                        <FormHelperText className='_2x2_graphLinesAttrDiv_tableCell_Error'>
                                                            Value should be in between {`(${graph_axis_settings_attributes.y_minimum_value}, ${graph_axis_settings_attributes.y_maximum_value})`}
                                                        </FormHelperText>
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell className='_2x2_graphLinesAttrDiv_tableCell'>
                                                <div className='_2x2_graphLinesAttrDiv_tableCell_Div'>
                                                    <TextField
                                                        style={{
                                                            border: line.error.x2
                                                                ? "1px solid red"
                                                                : "none",
                                                        }}
                                                        variant="standard"
                                                        type="number"
                                                        name="x2"
                                                        value={line.x2}
                                                        placeholder='0'
                                                        className={`_2x2_graphLinesAttrDiv_tableCell_Textfield ${props.classes.bodyText_font}`}
                                                        onChange={(e: any) => handleUpdateLinesData(index, e)}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                        }}
                                                    />
                                                    {line.error.x2 &&
                                                        <FormHelperText className='_2x2_graphLinesAttrDiv_tableCell_Error'>
                                                            Value should be in between {`(${graph_axis_settings_attributes.x_minimum_value}, ${graph_axis_settings_attributes.x_maximum_value})`}
                                                        </FormHelperText>
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell className='_2x2_graphLinesAttrDiv_tableCell'>
                                                <div className='_2x2_graphLinesAttrDiv_tableCell_Div'>
                                                    <TextField
                                                        style={{
                                                            border: line.error.y2
                                                                ? "1px solid red"
                                                                : "none",
                                                        }}
                                                        variant="standard"
                                                        type="number"
                                                        name="y2"
                                                        value={line.y2}
                                                        placeholder='0'
                                                        className={`_2x2_graphLinesAttrDiv_tableCell_Textfield ${props.classes.bodyText_font}`}
                                                        onChange={(e: any) => handleUpdateLinesData(index, e)}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                        }}
                                                    />
                                                    {line.error.y2 &&
                                                        <FormHelperText className='_2x2_graphLinesAttrDiv_tableCell_Error'>
                                                            Value should be in between {`(${graph_axis_settings_attributes.y_minimum_value}, ${graph_axis_settings_attributes.y_maximum_value})`}
                                                        </FormHelperText>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )
                            }
                            )}
                        </TableBody>
                    </Box>
                    {/* Correct Answers END */}
                </>
            )
        }
    }
    const handleUpdateLinesData = (index: number, e: any) => {
        let data: any = [...graph_lines_attributes];
        const name = e.target.name;
        const value = e.target.value;
        data[index][name] = value;
        data = checkForLineError(data, index).data;
        let tempPropsData: any = []
        data.map((_data: any) => {
            tempPropsData.push({
                x1: _data.x1 != '' ? _data.x1 : 'nil',
                y1: _data.y1 != '' ? _data.y1 : 'nil',
                x2: _data.x2 != '' ? _data.x2 : 'nil',
                y2: _data.y2 != '' ? _data.y2 : 'nil',
            })
        })
        props.handleLineGraphAttrChange(tempPropsData, props.sectionId, props.questionId)
        set_graph_lines_attributes(data)
    };
    const checkForLineError = (lineData: any, index: number) => {
        const x1 = Number(lineData[index].x1);
        const y1 = Number(lineData[index].y1);
        const x2 = Number(lineData[index].x2);
        const y2 = Number(lineData[index].y2);
        const config = graph_axis_settings_attributes;
        const xmin = Number(config.x_minimum_value);
        const xmax = Number(config.x_maximum_value);
        const ymin = Number(config.y_minimum_value);
        const ymax = Number(config.y_maximum_value);

        let error = false;
        if (x1 > xmax || x1 < xmin) {
            lineData[index].error.x1 = true;
            error = true;
        } else {
            lineData[index].error.x1 = false;
        }

        if (x2 > xmax || x2 < xmin) {
            lineData[index].error.x2 = true;
            error = true;
        } else {
            lineData[index].error.x2 = false;
        }

        if (y1 > ymax || y1 < ymin) {
            lineData[index].error.y1 = true;
            error = true;
        } else {
            lineData[index].error.y1 = false;
        }

        if (y2 > ymax || y2 < ymin) {
            lineData[index].error.y2 = true;
            error = true;
        } else {
            lineData[index].error.y2 = false;
        }

        return { data: lineData, error: error };
    }
    const flashCardStudentAnswer = (flash_card_data: any, cardNumber: number) => {
        return (
            <>
                <Box className='flashCardQuestion_div'>
                    <img className='flashCardQuestion_img_div' src={flash_card_data?.attributes?.attachments?.data[0]?.attributes?.url} />
                    <Box className='flashCardQuestion_hint_div'>
                        {flash_card_data.attributes.hints}
                    </Box>
                    <Box className='flashCardQuestion_textField_div'>
                        <TextField
                            id="standard-basic"
                            placeholder="Write Answer"
                            disabled={cardNumber + 1 === activeFlashCardStep ? false : true}
                            value={flash_card_data.answer}
                            className='QuestionStudentViewFlashCardTypeQuestionTextField no-underline'
                            autoComplete="off"
                            autoFocus={false}
                            inputProps={{
                                className: props.classes.QuestionStudentView_AssessmentTest_FlashCard_Question
                            }}
                            onChange={(event: any) => flashCardOnChange(event, flash_card_data.id)}
                        />
                    </Box>
                    <Box className='flashCardQuestion_option_number_div'>
                        {cardNumber + 1}/{flashCardQuestionArr.length}
                    </Box>
                </Box>
            </>
        )
    }
    return (
        <Box>
            <Box className='QuestionStudentViewMainDiv'>
                <Grid container>
                    <Grid item xs={12} sm={8} md={10} lg={10} xl={10}>
                        <Box>
                            <div className="QuestionStudentViewQuestionDiv">
                                <span className={`QuestionStudentViewQuestionTxt ${props.classes.subHeading_font}`}>Q{props.questionNumber}.</span>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: props.questionData?.attributes?.title,
                                    }}
                                    className={`QuestionStudentViewQuestionTxtMr QuestionStudentViewQuestionTxt ${props.classes.subHeading_font}`}
                                ></span>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
                        <Box className="QuestionStudentViewHintDiv">
                            <img
                                src={hintImageIcon}
                                aria-controls="fade-menu"
                                aria-haspopup="true"
                                className="QuestionStudentViewHintImg"
                                onClick={(event: any) => {
                                    setHintMenu(event.currentTarget)
                                }} />
                            <Popover
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                }}
                                anchorEl={hintMenu}
                                open={Boolean(hintMenu)}
                                onClose={() => { setHintMenu(null) }}

                            >
                                <Box
                                    className="QuestionStudentViewPopoverHintDiv"
                                >
                                    <Typography

                                        className={`QuestionStudentViewPopoverHintTitle ${props.classes.title_font}`}
                                    >
                                        Hint
                                    </Typography>
                                    <Box
                                        className="QuestionStudentViewHintTxtDiv"
                                    >

                                        {props.questionData?.attributes?.hints?.data?.map((hint: any) => (
                                            <>
                                                <Box>
                                                    <img src={hint?.attributes?.attachments?.data?.[0]?.attributes?.url} className='QuestionStudentViewHintTxtImg' />
                                                </Box>
                                                <Typography
                                                    className="QuestionStudentViewHintTxt"
                                                >
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: hint?.attributes?.hint,
                                                        }}
                                                        className={props.classes.bodyText_font}

                                                    />
                                                </Typography>
                                            </>
                                        ))}
                                    </Box>
                                </Box>
                            </Popover>
                            <Typography
                                className={`QuestionStudentViewMarksTxt ${props.classes.subHeading_font}`}
                            >
                                {props.questionData?.attributes?.marks} Marks
                            </Typography>
                        </Box>

                    </Grid>
                </Grid>
            </Box>
            {props.questionData?.attributes?.type?.name != 'hotspot' && props.questionData?.attributes?.type?.name != 'label_image_with_dropdown' && props.questionData?.attributes?.type?.name != 'label_image_with_text' && props.questionData?.attributes?.type?.name != 'label_image_with_drag_and_drop' && props.questionData?.attributes?.type?.name != 'audio' && props.questionData?.attributes?.type?.name != 'video' && <>
                {props.questionData?.attributes?.attachments?.length !== 0 &&
                    props.questionData?.attributes?.attachments[0].attributes?.url &&
                    props.questionData?.attributes?.attachments[0].attributes?.url !== "" &&

                    <Box style={{
                        display: "flex",
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        padding: "18px 20px",
                        borderRadius: 8,
                        boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
                        backgroundColor: 'var(--calcite)',
                        maxHeight: 520,
                    }}>
                        <Box style={{
                            width: '95%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <img
                                src={props.questionData?.attributes?.attachments[0].attributes.url}
                                style={{ width: '100%', height: 250, objectFit: 'contain' }}
                            />
                        </Box>
                        <Box
                            style={{
                                width: '5%',
                                padding: 5,
                                borderRadius: 4,
                                backgroundColor: '#3f526d',
                                textAlign: 'center',
                            }}
                        >
                            <img src={zoomoutIcon} style={{ width: 22, height: 22, cursor: 'pointer' }}
                                onClick={() => {
                                    setpreviewImageSrc(props.questionData?.attributes?.attachments[0].attributes.url)
                                    setpreviewImageModal(true)
                                }} />
                            {previewImageModal
                                &&
                                <ImagePreivewModal
                                    previewImageModal={previewImageModal}
                                    modalhandleClose={() => setpreviewImageModal(false)}
                                    previewImage={props.questionData?.attributes?.attachments[0].attributes.url}
                                />
                            }
                        </Box>
                    </Box>
                }
            </>
            }

            <Box>
                {
                    props.questionData?.attributes?.type?.name === '2x2_grid' || props.questionData?.attributes?.type?.name === 'hotspot' || props.questionData?.attributes?.type?.name === 'label_image_with_dropdown' || props.questionData?.attributes?.type?.name === 'label_image_with_text' || props.questionData?.attributes?.type?.name === 'label_image_with_drag_and_drop' || props.questionData?.attributes?.type?.name === 'match' || props.questionData?.attributes?.type?.name === 'sort_list' || props.questionData?.attributes?.type?.name === 'audio' || props.questionData?.attributes?.type?.name === 'video' || props.questionData?.attributes?.type?.name === 'graph_plotting' || props.questionData?.attributes?.type?.name === 'graph_plotting' ?
                        <></>
                        :
                        props.questionData?.attributes?.type?.question_category_id != 1 || props.questionData?.attributes?.type?.name === 'one word' || (props.questionData?.attributes?.type?.name === 'fill in blank' && props.questionData?.attributes?.text_input) ?
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                            >
                                <Typography className={`${props.classes.subHeading_font} writeAnswerTextTypography`}>
                                    Write Answer
                                </Typography>
                                {props.questionData?.attributes?.type?.name != 'one word' && props.questionData?.attributes?.word_limit != null &&
                                    <Typography
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 600,
                                            marginRight: 20,
                                            marginTop: 20
                                        }}
                                        className={props.classes.subHeading_font}
                                    >
                                        Word Limit {props.questionData?.attributes?.word_limit}
                                    </Typography>
                                }
                            </Box>
                            :
                            <Typography
                                style={{
                                    fontSize: 18,
                                    fontWeight: 600,
                                    marginRight: 20,
                                    marginTop: 20
                                }}
                                className={props.classes.subHeading_font}
                            >
                                Select Answer
                            </Typography>
                }
            </Box>
            <Box style={{
                margin: '20px 0px',
            }}>
                {questionType()}
            </Box>
            <PreviewVideoModal
                onCloseModal={() => { setOpenPreviewVideoModal(false) }}
                openPreviewVideoModal={openPreviewVideoModal}
                videoSrc={selectedVideoSrc}
            />
        </Box >
    );
    // Customizable Area End
};
// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(QuestionStudentView);
// Customizable Area End