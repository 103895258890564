// Customizable Area Start
import React, { useState, useCallback } from "react";
import { toast } from "react-toastify";
import { Modal } from "@material-ui/core";
import VideoWebCam from "./videoWebCam.web";
// Customizable Area End

// Customizable Area Start
interface IVideoRecordWebcamModal {
  handleClose: () => void;
  handleOnBack?: () => void;
  handleSave: (mediaUrl: any, mediaBlob: any) => void;
  //handleCancel?: () => void;
  createVideo?: any;
  loadingSpinner?: boolean;
  MaxVideoRecordingTime: number;
}
// Customizable Area End

export const VideoRecordViaWebcamModal: React.FC<IVideoRecordWebcamModal> = ({
  // Customizable Area Start
  handleClose,
  handleOnBack,
  handleSave,
  createVideo,
  loadingSpinner,
  MaxVideoRecordingTime
  // Customizable Area End
}) => {
  // Customizable Area Start
  const [isPreviewMode, setIsPreviewMode] = useState(false);

  const handleAlertOnModalClose = useCallback(
    (e: any) => {
      e.preventDefault();
      if (isPreviewMode) {
        toast.warn("The video recorded will be lost upon closing the popup.");
      } else {
        handleClose();
      }
    },
    [isPreviewMode]
  );

  const handleSetPreviewMode = (value: boolean) => {
    setIsPreviewMode(value);
  };

  return (
    <Modal
      open={createVideo.isCreateUploadVideo === "Create"}
      onClose={(e) => handleAlertOnModalClose(e)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <VideoWebCam
        onFileSave={handleSave}
        onClose={() => {
          handleClose(), setIsPreviewMode(false);
        }}
        onBack={handleOnBack}
        loadingSpinner={loadingSpinner}
        setPreviewMode={handleSetPreviewMode}
        MaxVideoRecordingTime = {MaxVideoRecordingTime}
      />
    </Modal>
  );
  // Customizable Area End
};

// Customizable Area Start
export default VideoRecordViaWebcamModal;
// Customizable Area End
