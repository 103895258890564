// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const ThemeIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M24.786 0A3.214 3.214 0 0 1 28 3.214v19.572A3.214 3.214 0 0 1 24.786 26H3.214A3.214 3.214 0 0 1 0 22.786V3.214A3.214 3.214 0 0 1 3.214 0zM26 8H2v14.786C2 23.456 2.543 24 3.214 24h21.572c.67 0 1.214-.543 1.214-1.214V8zm-8 12a1 1 0 0 1 0 2h-8a1 1 0 0 1 0-2zm0-5a1 1 0 0 1 0 2h-8a1 1 0 0 1 0-2zm6.786-13H3.214C2.544 2 2 2.543 2 3.214V6h24V3.214C26 2.544 25.457 2 24.786 2zM7 3a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default ThemeIcon;
// Customizable Area End