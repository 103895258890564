// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
// Customizable Area End
// Customizable Area Start
const uuid = require("uuid/v4");

export const configJSON = require("../config");
// Customizable Area End
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showLoader: boolean;
  modulesList: any;
  moduleAttributes: any;
  alreadySelectedModule: any;
  scholasticModule: any;
  scholasticModuleNameError: boolean;
  coScholasticModule: any;
  coScholasticModuleNameError: boolean;
  coScholasticEnabled: boolean;
  attributeList: any;
  openAddAttributeModal: boolean;
  openManageAttributeModal: boolean;
  deletedCoScholasticTopics: any;
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}
export default class ModulesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetAttributeList: string = "";
  apiCreateAttribute: string = "";
  apiDeleteAttribute: string = "";
  apiEditAttribute: string = "";
  apiSaveModule: string = "";
  apiGetModuleList: string = "";
  apiUpdateModule: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      showLoader: false,
      modulesList: [
        { id: 1, module: "Scholastic Area" },
        { id: 2, module: "Co-Scholastic Area" },
      ],
      moduleAttributes: [],
      alreadySelectedModule: [],
      scholasticModule: {
        id: -1,
        name: ''
      },
      scholasticModuleNameError: false,
      coScholasticModule: {
        id: '-1',
        name: '',
        report_attributes: []
      },
      coScholasticModuleNameError: false,
      coScholasticEnabled: false,
      attributeList: [{
        id: 1,
        attributes: {
          name: "Attr 1",
        },
      },
      { id: 2, attributes: { name: "Attr 2" } },
      { id: 3, attributes: { name: "Attr 3" } },
      { id: 4, attributes: { name: "Attr 4" } },
      { id: 5, attributes: { name: "Attr 5" } },],
      openAddAttributeModal: false,
      openManageAttributeModal: false,
      deletedCoScholasticTopics: []

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getAttributeList();
    this.getModules();
    // Customizable Area End
  }

  onChangeScholasticName = (value: any) => {
    const { scholasticModule } = this.state;
    this.setState({
      scholasticModule: {
        ...scholasticModule,
        name: value,
        isEdited: true
      }
    })
  }

  onChangeCoScholasticName = (value: any) => {
    const { coScholasticModule } = this.state;
    this.setState({
      coScholasticModule: {
        ...coScholasticModule,
        name: value,
        isEdited: true
      }
    })
  }
  onChangeCoScholaticTopic = (event: any, index: number) => {
    const updatedData = this.state.coScholasticModule;
    updatedData.isEdited = true;
    updatedData.report_attributes[index].name = event.target.value;
    this.setState({
      coScholasticModule: updatedData
    })
  }

  onAttributeChange = (event: any, index: number) => {
    const coScholasticData = this.state.coScholasticModule;
    let newData = event.target.value.map((item: any) => Number(item))
    coScholasticData.isEdited = true;
    coScholasticData.report_attributes[index].report_attributes = newData;

    this.setState({
      coScholasticModule: coScholasticData
    })

  }

  getSelectedAttributes = (selected: any) => {

    if (selected.length > 0) {
      return this.state.attributeList.reduce((result: string, item: any) => {
        if (selected.includes(Number(item.id))) {
          result = result + (result !== "" ? ", " : "") + item.attributes.name
        }
        return result
      }, "")
    }

    return "Select Attributes"
  }

  addNewTopic = () => {
    const { coScholasticModule } = this.state;
    let error = false;
    coScholasticModule.report_attributes.map((data: any) => {
      if (data.name == '') {
        toast("Topic name cannot be empty");
        error = true;
      }
      if (data.report_attributes.length === 0) {
        toast("Please select atleast one attribute")
        error = true;
      }
    })
    if (error) {
      return;
    }
    coScholasticModule.report_attributes.push({
      id: -1,
      name: '',
      report_attributes: []
    })
    this.setState({
      coScholasticModule: coScholasticModule
    })
  }



  deleteCoScholasticTopic = (index: number) => {
    const { coScholasticModule, deletedCoScholasticTopics } = this.state;
    const deletedTopic = coScholasticModule.report_attributes[index];
    deletedCoScholasticTopics.push(deletedTopic);
    coScholasticModule.report_attributes.splice(index, 1);
    this.setState({
      coScholasticModule: coScholasticModule,
      deletedCoScholasticTopics: deletedCoScholasticTopics
    })
  }

  addAnotherModule = () => {
    this.setState({
      coScholasticModule: {
        id: '-1',
        name: '',
        report_attributes: []
      },
      coScholasticEnabled: true
    })
  }

  openAttributeModal = () => {
    this.setState({
      openAddAttributeModal: true
    })
  }

  onClickEditAttributes = () => {
    this.setState({ openManageAttributeModal: true })
  }

  closeAttributeModal = () => {
    this.setState({
      openAddAttributeModal: false
    })
  }

  manageMuduleData = (apiData: any) => {
    // Scholastic module
    let scholasticData = apiData.filter((data: any) => data.attributes.type == "scholastic");
    if (scholasticData.length > 0) {
      this.setState({
        scholasticModule: {
          id: scholasticData[0].id,
          name: scholasticData[0].attributes.name,
          isEdited: false
        },
      })
    } else {
      this.setState({
        scholasticModule: {
          id: -1,
          name: ''
        },
      })
    }
    // Scholastic module END

    // Co-Scholastic Module
    let coScholasticData = apiData.filter((data: any) => data.attributes.type == "co_scholastic");
    if (coScholasticData.length > 0) {
      const data: any = {
        id: coScholasticData[0].id,
        name: coScholasticData[0].attributes.name,
        isEdited: false,
        report_attributes: coScholasticData[0].attributes?.topics?.map((item: any) => ({
          id: item.id,
          name: item.name,
          report_attributes: item.report_attributes.map((i: any) => Number(i))
        }))
      };
      this.setState({
        coScholasticModule: data,
        coScholasticEnabled: true
      })
    } else {
      this.setState({
        coScholasticModule: {
          id: '-1',
          name: '',
          report_attributes: []
        },
        coScholasticEnabled: false
      })
    }
    // Co-Scholastic Module END
  }

  manageAttributeList = (apiData: any) => {
    this.setState({
      attributeList: apiData.map((data: any) => ({
        ...data,
        id: Number(data.id)
      })),
      showLoader: false,
      openAddAttributeModal: false
    })
  }

  manageModuleUpdate = () => {
    const {
      scholasticModule,
      coScholasticEnabled,
      coScholasticModule,
      deletedCoScholasticTopics
    } = this.state;

    const createPayload: any = {
      report_modules: []
    }
    const updatePayload: any = {
      report_modules: []
    }
    // Scholastic
    if (Number(scholasticModule.id) === -1) {
      createPayload.report_modules.push({
        type: "scholastic",
        name: scholasticModule.name,
      })
    } else {
      if (scholasticModule.isEdited) {
        updatePayload.report_modules.push({
          id: scholasticModule.id,
          type: "scholastic",
          name: scholasticModule.name,
        })
      }
    }
    // Scholastic END

    // co-scholastic module attributes
    if (coScholasticEnabled) {
      const topicAttributes = coScholasticModule.report_attributes.map((data: any) => {
        if (Number(data?.id) === -1) {
          return {
            name: data.name,
            report_attributes: data.report_attributes
          }
        }
        return {
          id: data?.id,
          name: data.name,
          report_attributes: data.report_attributes
        }
      })
      if (Number(coScholasticModule.id) === -1) {
        createPayload.report_modules.push({
          type: "co_scholastic",
          name: coScholasticModule.name,
          report_topics_attributes: topicAttributes
        })
      } else {
        // deleted attributes
        if (deletedCoScholasticTopics.length > 0) {
          topicAttributes.push(...deletedCoScholasticTopics.reduce((result: any, data: any) => {
            if (Number(data.id) !== -1) {
              result.push({
                id: data.id,
                _destroy: true
              })
            }
            return result
          }, []))
        }
        // deleted attributes END

        if (coScholasticModule.isEdited) {
          updatePayload.report_modules.push({
            id: coScholasticModule.id,
            type: "co_scholastic",
            name: coScholasticModule.name,
            report_topics_attributes: topicAttributes
          })
        }
      }
    }
    // co-scholastic module attributes END
    if (createPayload.report_modules.length > 0) {
      this.saveModules(createPayload);
    }

    if (updatePayload.report_modules.length > 0) {
      this.updateModules(updatePayload)
    }
  }


  validateData = () => {
    let error = false;

    const { scholasticModule, coScholasticEnabled, coScholasticModule } = this.state;
    if (scholasticModule.name.trim() === '') {
      toast("Module name cannot be empty");
      error = true;
    }

    if (coScholasticEnabled) {
      if (coScholasticModule.name.trim() === '') {
        toast("Module name cannot be empty");
        error = true;
      }

      if (coScholasticModule.report_attributes.length > 0) {
        coScholasticModule.report_attributes.map((data: any) => {
          if (data.name == '') {
            toast("Topic name cannot be empty");
            error = true;
          }
          if (data.report_attributes.length === 0) {
            toast("Please select atleast one attribute")
            error = true;
          }
        })
      } else {
        toast("Please add atleast one topic");
        error = true;
      }

    }
    return !error;
  }

  onClickSaveModule = () => {
    if (this.validateData()) {
        this.manageModuleUpdate()
    }
  }

  handleOnCloseManageAttributeModal = () => {
    this.setState({ openManageAttributeModal: false })
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Received', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.apiGetAttributeList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.manageAttributeList(responseJson.data)
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiCreateAttribute) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.getAttributeList()
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
      else if (apiRequestCallId === this.apiGetModuleList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.manageMuduleData(responseJson.data)
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
      else if (apiRequestCallId === this.apiDeleteAttribute) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.getAttributeList()
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
      else if (apiRequestCallId === this.apiEditAttribute) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.getAttributeList()
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
      else if (apiRequestCallId === this.apiSaveModule) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.getModules()
            this.getAttributeList()
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
      else if (apiRequestCallId === this.apiUpdateModule) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            toast("Modules updated successfully")
            this.setState({
              showLoader: false
            })
            this.getModules()
            this.getAttributeList()
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }

  getModules = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    this.setState({ showLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetModuleList = requestMessage.messageId;
    let apiEndPoint = configJSON.moduleEndPoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getAttributeList = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    this.setState({ showLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAttributeList = requestMessage.messageId;
    let apiEndPoint = configJSON.moduleAttributeEndPoints

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  addNewAttribute = (attribute: string) => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    this.setState({ showLoader: true, openAddAttributeModal: false })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: school_Data?.school_id,
    };

    const httpBody: any = {
      report_attribute: {
        name: attribute
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreateAttribute = requestMessage.messageId;
    let apiEndPoint = configJSON.moduleAttributeEndPoints //same for create

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  deleteAttribute = (id: number) => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    this.setState({ showLoader: true, openAddAttributeModal: false })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: school_Data?.school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteAttribute = requestMessage.messageId;
    let apiEndPoint = configJSON.moduleAttributeEndPoints + `/${id}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleEditAttribute = (id: number, value: string) => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    this.setState({ showLoader: true, openAddAttributeModal: false })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: school_Data?.school_id,
    };
    const httpBody: any = {
      report_attribute: {
        name: value
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEditAttribute = requestMessage.messageId;
    let apiEndPoint = configJSON.moduleAttributeEndPoints + `/${id}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveModules = (payload: any) => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    this.setState({ showLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: school_Data?.school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSaveModule = requestMessage.messageId;
    let apiEndPoint = configJSON.moduleEndPoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateModules = (payload: any) => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    this.setState({ showLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: school_Data?.school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateModule = requestMessage.messageId;
    let apiEndPoint = configJSON.updateModuleEndPoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
