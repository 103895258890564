// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import { checkFieldLengthForNumber } from "../../../profiles/src/utils/common";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
interface Props {
    // Customizable Area Start
    questionData: any;
    isTeacher: boolean;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
const form_input_text_field = {
    width: "100%",
    borderRadius: "6px",
    fontSize: "20px",
    fontWeight: 600,
} as const;
// Customizable Area End

// Customizable Area Start
export const HotSpotQuestionType = ({
    questionData,
    isTeacher,
    classes,
}: Props) => {
    const [selectedImageBlobUrl, setSelectedImageBlobUrl] = useState<string>("");
    const [attemptedImageBlobUrl, setAttemptedImageBlobUrl] = useState<string>("");
    const [marks, setMarks] = useState<any>(questionData?.selected_options?.data?.attributes?.score)

    useEffect(() => {
        const imageUrl = questionData?.answers?.[0].attachments?.data?.[0]?.attributes?.url
        setSelectedImageBlobUrl(imageUrl)
        const studentUrl = questionData?.selected_options?.data?.attributes?.attachments?.data?.[0]?.attributes?.url
        setAttemptedImageBlobUrl(studentUrl)
        if (!questionData?.selected_options?.data) {
            setMarks(0);
        }
    }, []);

    return (
        <>
            <div className="evaluation-blob-img-block">
                <img
                    src={attemptedImageBlobUrl}
                    className="evaluation-blob-img"
                ></img>
            </div>
            <Grid container>
                <div className="correct_answer_section">
                    <Grid container>
                        <Grid item xs={12} sm={6} className="correct_answer_left">
                            {!questionData?.selected_options?.data &&
                                <Typography className={`not_attemped_text ${classes.bodyText_font}`}>Not attempted</Typography>
                            }
                            <Typography className={`correct_answer_text ${classes.heading_font}`}>Correct Answer</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className="score_section">
                            <Typography className={`correct_answer_text ${classes.heading_font}`}>Score</Typography>
                            <div className="correct_answer_rectangle">
                                {isTeacher ?
                                    <TextField
                                        placeholder=""
                                        type="number"
                                        value={marks}
                                        style={form_input_text_field}
                                        className={classes.input_theme_style}
                                        inputProps={{ className: "score_text" }}
                                        disabled={!questionData?.selected_options?.data}
                                        onChange={(e: any) => {
                                            if (Number(e.target.value) > Number(questionData?.marks)) {
                                                toast.error('Obtained marks should not greater than total marks')
                                            }
                                            setMarks(e.target.value)
                                            questionData.selected_options.data.attributes.score = e.target.value
                                        }}
                                        onKeyPress={(event: any) => checkFieldLengthForNumber(event, 3)}
                                    />
                                    :
                                    <Typography className={`score_text ${classes.bodyText_font}`}>
                                        {questionData?.selected_options?.data?.attributes?.score ? questionData?.selected_options?.data?.attributes?.score : 0}
                                    </Typography>
                                }
                            </div>
                            <Typography className={`score_text ${classes.bodyText_font}`}> / {questionData?.marks}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="sorting-correct-block">
                                {selectedImageBlobUrl && (
                                    <div className="evaluation-blob-img-block">
                                        <img
                                            src={selectedImageBlobUrl}
                                            className="evaluation-blob-img"
                                        ></img>
                                    </div>
                                )}
                            </div>
                        </Grid>
                        {(!isTeacher && questionData?.selected_options?.data?.attributes?.comments) &&
                            <Grid item xs={12}>
                                <Typography className={`title_teachercomment ${classes.bodyText_font}`}>Teacher Comment:
                                    <span className={`textlabel_teachercomment ${classes.heading_font}`} dangerouslySetInnerHTML={{
                                        __html: questionData?.selected_options?.data?.attributes?.comments,
                                    }}></span></Typography>
                            </Grid>
                        }
                    </Grid>
                </div>
            </Grid>
        </>
    );
};
// Customizable Area End
// Customizable Area Start

export default withStyles(themeCreateStyle)(HotSpotQuestionType);
// Customizable Area End