// Customizable Area Start
import React from "react";
import {
    Grid,
    Modal,
    Button,
} from "@material-ui/core";
import './MesDialog.web.css';
import {themeCreateStyle} from '../../../../components/src/CustomThemeStyles.web';
import { withStyles } from '@material-ui/core/styles';
// Customizable Area End

// Customizable Area Start

// Customizable Area End

interface Props {
    // Customizable Area Start
    title: string;
    deleteModal: boolean;
    onCloseModal: any;
    deleteClick: any;
    confirmMes:any;
    description:any;
    cancelButtonText:any;
    okButtonText:any;
    classes:any;
    // Customizable Area End
}

export const MesDialog = ({
    // Customizable Area Start
    title,
    deleteModal,
    onCloseModal,
    deleteClick,
    confirmMes,
    description,
    cancelButtonText,
    okButtonText,
    classes
    // Customizable Area End
}: Props) => {
    // Customizable Area Start
    return (
        <>
            <Modal
                open={deleteModal}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid
                    className="modal-width-90 MesDialogContainer"
                >
                    <div className={`${classes.theme_font} titleDiv`}>{title}</div>
                    <div className="emptyDiv"></div>
                    <div className={`${classes.theme_font} confirmationBox`}>{confirmMes}</div>
                    <div className={`${classes.theme_font} descriptionTypo`}>{description}</div>
                    <Grid className="flexCenter">
                        <Button variant="contained" className="cancelBtn" onClick={onCloseModal}><span className={`${classes.theme_font} btnTypo whiteText`}>{cancelButtonText}</span></Button>
                        <Button variant="contained" className="dltBtn" onClick={deleteClick}><span className={`btnTypo ${classes.theme_font}`}>{okButtonText}</span></Button>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
    // Customizable Area End
};
// Customizable Area Start
export default withStyles(themeCreateStyle)(MesDialog);
// Customizable Area End
