// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoader: boolean;
    schoolData: any;
    selectClass: any;
    selectedDate: any;
    schoolLogoUrl: string;
    termListData: any;
    data: any;
    profileDetails: any;
    teacherName:string;
    secondAttendanceRow: any;
    thirdAttendanceRow: any,
    // Customizable Area End
}
interface SS { 
    // Customizable Area Start
    // Customizable Area End
}

export default class StudentProfileDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getProfileSchoolDetailsId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

        this.state = {
            isLoader: false,
            schoolData: {},
            selectClass: '',
            selectedDate: new Date('2014-08-18T21:11:54'),
            schoolLogoUrl: '',
            termListData: [],
            data: null,
            teacherName:'',
            profileDetails: {}, 
            secondAttendanceRow: [],
            thirdAttendanceRow: [],
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.get_student_profile_details = this.get_student_profile_details.bind(this);
        // Customizable Area End
    }
    async componentDidMount() {
        super.componentDidMount()

        // Customizable Area Start
        if(HISTORY.location.state?.studentData){
            const studentData = HISTORY.location.state?.studentData;

            const user_data = localStorage.getItem("user_data");
            const teacherData = JSON.parse(user_data || "{}");     
            this.setState({
                schoolData:{
                    id:teacherData.school_id,
                    attributes:{
                        name:teacherData.school.name
                    }
                },
                teacherName:teacherData.first_name +" "+ teacherData.last_name
            });


            this.get_student_profile_details(Number(studentData.data.id));
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.getProfileSchoolDetailsId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (responseJson.data != null) {
                            let row1: any = []; 
                            let row2: any = []; 
                            let row3: any = []; 
                            responseJson.data?.attributes?.terms?.map((termData: any) => {
                                row1.push(termData.title)
                                responseJson.data?.attributes?.attendance?.filter((aData: any) => {                                  
                                   if(termData.id === aData.term_id) {
                                    row2.push(aData.student_attendance);
                                    row3.push(aData.total_attendance);
                                    return;
                                   }
                                })
                            })
                    
                            const filterData = responseJson.data?.attributes?.school_logo?.data?.find((res: any) => res.name === 'print_logo')
                            this.setState({
                                profileDetails: responseJson.data,
                                termListData: row1,
                                secondAttendanceRow: row2,
                                thirdAttendanceRow: row3,
                                schoolLogoUrl: filterData?.logo_file?.data?.[0]?.attributes?.url,          
                            }, () => {
                                this.setState({  isLoader:false })
                            });                          
                        }
                    } else {
                        this.parseApiErrorResponse(responseJson);
                        this.setState({ isLoader: false });
                    }
                }
                this.parseApiCatchErrorResponse(errorResponse);
                this.setState({ isLoader: false });
            }
        }
        // Customizable Area End
    }

    get_student_profile_details = (student_id:number) => {
        this.setState({ isLoader: true });
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("token"),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProfileSchoolDetailsId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getStudentDataEndPoint + `?account_id=${student_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.userManagementUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}
