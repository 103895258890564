// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import moment from "moment";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  taskScreen: any;
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  submitModal: boolean;
  teacherTasks: any;
  query: string;
  todayBtn: boolean;
  task_id: any;
  viewAllTask: boolean;
  task_page: number;
  timeLineMeta: any;
  seletedTask: any;
  dialogOpen: boolean;
  showAllTask: boolean;
  showSpinner: boolean;
  arraySlicenumber: number;
  task_date: string;
  queryTerm: string;
  type: string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudentTaskUnitController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetStudentTaskId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      submitModal: false,
      teacherTasks: [],
      query: "",
      todayBtn: false,
      task_id: "",
      viewAllTask: false,
      task_page: 0,
      timeLineMeta: null,
      seletedTask: {},
      dialogOpen: true,
      showAllTask: false,
      showSpinner: false,
      arraySlicenumber: 3,
      task_date: '',
      queryTerm: '',
      type: '',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.get_task = this.get_task.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.get_task(this.state.task_page + 1);
  }
  onCouseWorkModal = (event: any, closeModal: boolean) => {
    this.setState({ submitModal: closeModal });
    this.get_task();
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apigetStudentTaskId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              teacherTasks: responseJson.tasks.data,
              timeLineMeta: responseJson.meta,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({
          showSpinner: false
        })
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  get_task = (page?: any, text?: any) => {
    this.setState({
      showSpinner: true
    })
    const task_page = typeof page === "number" ? page : this.state.task_page;
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentTaskId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentTaskEndPoint +
      `?page=${task_page}&per=6`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_search_task = (name?: any) => {
    this.setState({
      showSpinner: true
    })
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentTaskId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentTaskEndPoint +
      `?user_id=${localStorage.getItem("id")}&name=${name}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_cal_task = (date: any) => {
    this.setState({
      showSpinner: true
    })
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const selectedDate = moment(date).format("DD/MM/YYYY");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentTaskId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentTaskEndPoint +
      `?due_date=${selectedDate}&user_id=${localStorage.getItem("id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
