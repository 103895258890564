// Customizable Area Start
import React from "react";
import {
    Box,
    Avatar,
} from "@material-ui/core";
import './Member.web.css'
import {
    makeStyles,
    createStyles,
  } from "@material-ui/core/styles";
import './MembersBox.web.css';
// Customizable Area End

// Customizable Area Start  
  const useStyles = makeStyles((theme:any) =>
  createStyles({
    background_color: {
        backgroundColor: theme.palette.primary.main
      },
      
      heading_font: {
        color: `${theme.props.headingColor} !important`,
        fontFamily: theme.props.headingFont
      },
     
      bodyText_font: {
        color: `${theme.props.mainColor} !important`,
        fontFamily: theme.props.textFont
      },
      
      image_Border_Color: {
        border: `3px solid ${theme.props.buttonColor} `
      },
      icon_color: {
        color: `${theme.props.iconColor} !important`
      },
      subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
      }, 
  })
);
// Customizable Area End


interface Props {
    // Customizable Area Start
    id: number;
    name: string;
    imgSource: any;
    role: string;
    gender: string;
    dateOfBirth: string;
    handleCheckboxClick: (event: any, id: number) => void;
    isSelected: boolean;
    selectAll: boolean;
    // Customizable Area End
}

// Customizable Area Start
export const TeacherMembersBox = ({
    id,
    name,
    imgSource,
    role,
    gender,
    dateOfBirth,
    handleCheckboxClick,
    isSelected,
    selectAll,
}: Props) => {
    const classes = useStyles();
    const displayFocused = (id: number) => {
        const element = document.getElementById(`focus-id-${id}`)
        if (element) {
            element.style.boxShadow = "0 0 15px 0 rgba(0, 0, 0, 0.25)"
        }
    };

    const displayNormal = (id: number) => {
        const element = document.getElementById(`focus-id-${id}`)
        if (element) {
            element.style.boxShadow = "0 0 8px 0 rgba(0, 0, 0, 0.1)"
        }
    };
    return (
        <Box
        className="profileOuterBox memberBoxContainer"
            id={`focus-id-${id}`}
            onMouseEnter={() => displayFocused(id)}
            onMouseLeave={() => displayNormal(id)}
        >
            <div className="flexRow">
                <div>
                <div className={`image_box ${classes.image_Border_Color} imageBox`}>
                     
                    <Avatar
                        src={imgSource ? imgSource : "/"}
                        className="avatar"
                        style={{
                            backgroundColor: imgSource && "#fff",
                        }}
                    />
                    </div>
                    </div>
                    <div className="ml20">
                        <div>
                             <div className={`username title textLeft ${classes.subHeading_font}`} 
                             id='fileNameTextDoc font_18'
                >
                    {name}
                </div>
                        </div>
                    <div 
                    className={`username ${classes.bodyText_font} fw600 fs16`} 
                    >
                        {role}
                    </div>
                </div>
               
                 </div>
          

        </Box>
    );
};
// Customizable Area End

// Customizable Area Start
export default TeacherMembersBox;
// Customizable Area End