// Customizable Area Start
import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  TextField,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  RadioGroup,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { imageCloseGray } from "../assets";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import { invalidCharsForNumberType } from "../../../profiles/src/utils/common";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const ColorLibConnector = withStyles({
  alternativeLabel: {
    top: 15,
    left: "calc(-50% + 1px)",
    right: "calc(50% + 1px)",
  },
  active: {
    "& $line": {
      borderColor: "#3a60d7",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#3a60d7",
    },
  },
  line: {
    width: "auto",
    borderStyle: "solid",
    borderImageSlice: 1,
  },
})(StepConnector);

const useCustomStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ffffff",
    zIndex: 1,
    color: "#fff",
    height: "34px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Open sans",
  },
  active: {
    width: "34px",
    border: "1px solid #3a60d7",
    borderWidth: 3,
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  verticalLine: {
    width: 3,
    height: 16,
    borderRadius: "2px",
    backgroundColor: "#3a60d7",
  },
});
// Customizable Area End

function CustomStepIcon(props: StepIconProps) {
  // Customizable Area Start
  const classes = useCustomStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      <div className={active ? "" : classes.verticalLine} />
    </div>
  );
  // Customizable Area End
}

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflow: "auto",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

const form_input_text_field = {
  borderRadius: 6,
} as const;

function StepperWithText(props: any) {
  const classes = useStyles();
  const CustomRadio = withStyles({
    root: {
      color: "#3f526d",
      "&$checked": {
        color: "#3a60d7",
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);
  return (
    <div className={classes.root}>
      <RadioGroup
        aria-label="position"
        name="position"
        defaultValue={props.steps?.[0]?.uniqueId}
        value={props.selectedStepperRadioValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props?.onChangeStepperValue(event)
        }
      >
        <Stepper
          alternativeLabel
          activeStep={props.activeStep}
          connector={<ColorLibConnector />}
        >
          {props.steps
            ?.filter((item: any) => !item?._destroy)
            ?.map((stepItem: any, index: number) => (
              <Step key={stepItem?.uniqueId}>
                <StepLabel
                  className="rating-step-custom-color"
                  StepIconComponent={CustomStepIcon}
                >
                  {props?.isAnswerSection ? (
                    <>
                      {props?.isEmojiType && (
                        <div className="emoji-icon">
                          <span>{stepItem?.emoji}</span>
                        </div>
                      )}
                      <div className="question-step-label">
                        <CustomRadio
                          value={stepItem?.uniqueId}
                          className="rating_scale_radio"
                          name="radio-button-demo"
                          inputProps={{ "aria-label": "A" }}
                          disabled={props?.disableRadioButton}
                        />
                        <TextField
                          id="standard-basic"
                          placeholder=""
                          style={form_input_text_field}
                          variant="outlined"
                          value={stepItem?.textValue}
                          disabled
                          inputProps={{
                            className: `rating-text-field ${props.classes.heading_font}`,
                          }}
                          InputProps={{
                            endAdornment: props?.isPercentageType && (
                              <InputAdornment position="end">
                                <Typography
                                  className={props.classes.heading_font}
                                >
                                  %
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {props?.isEmojiType && (
                        <div className="emoji-icon">
                          <span>{stepItem?.emoji}</span>
                        </div>
                      )}
                      <div className="question-step-label">
                        <TextField
                          id="standard-basic"
                          type={props.textFieldType}
                          placeholder=""
                          style={form_input_text_field}
                          variant="outlined"
                          value={stepItem?.textValue}
                          onChange={(e: any) => {
                            props.onChangeTextValue(
                              stepItem?.uniqueId,
                              e.target.value
                            );
                          }}
                          inputProps={{
                            className: `rating-text-field ${props.classes.heading_font}`,
                          }}
                          onKeyPress={(event) => {
                            if (
                              props.textFieldType === "number" &&
                              invalidCharsForNumberType.includes(event.key)
                            ) {
                              event.preventDefault();
                              event.stopPropagation();
                            }
                          }}
                          InputProps={{
                            endAdornment: props?.isPercentageType && (
                              <InputAdornment position="end">
                                <Typography
                                  className={props.classes.heading_font}
                                >
                                  %
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <img
                          src={imageCloseGray}
                          className="stepper-img-close"
                          onClick={() => {
                            props.onRemoveStep(stepItem);
                          }}
                        ></img>
                      </div>
                    </>
                  )}
                </StepLabel>
              </Step>
            ))}
        </Stepper>
      </RadioGroup>
    </div>
  );
}
// Customizable Area End

// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(StepperWithText);
// Customizable Area End
