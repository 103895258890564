// Customizable Area Start
import React from "react";
import {
    Grid,
    Modal,
    Typography,
    Tab,
    Tabs,
} from "@material-ui/core";
import { imageCheckSVG, imageCheckmark, imgBackArrowSVG } from '../assets';
import ChooseFromGalleryModalController from "./ChooseFromGalleryModalController.web";
import ReactPaginate from "react-paginate";
import Spinner from "../../../shared/SideBar/src/Spinner";
import '../TeacherAssessment.web.css'
import { withStyles } from '@material-ui/core/styles';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import './AddQuestion.web.css';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class ChooseFromGalleryModal extends ChooseFromGalleryModalController {

    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    render() {

        return (
            <>
                {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
                <Modal
                    open={this.props.openChooseFromGalleryModal}
                    onClose={this.onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Grid
                        className="modal-width-dynamic select-image-modal gallery-modal"
                    >
                        <Typography
                            className={`choose_back_text ${this.props.classes.bodyText_font}`}
                            onClick={this.onBackClick}
                        >
                            <img src={imgBackArrowSVG} className="choose_back_img"></img>
                            Back
                        </Typography>

                        <Grid container justify='space-between' alignItems="center">
                            <Grid container item xs={10} sm={10} md={11} lg={11} justify='space-between' alignItems="center"
                                className="choose_tabs_div">
                                <Tabs
                                    value={this.state.selectedTabValue}
                                    onChange={(event, value) =>
                                        this.onChangeTab(value)
                                    }
                                    TabIndicatorProps={{
                                        className: "choose_from_gallery_tab_color"
                                    }}
                                    className="choose_tabs"
                                >
                                    <Tab
                                        className={`choose_from_gallery_tab ${this.props.classes.title_font}`}
                                        label="Picture’s Gallery"
                                        id={`simple-tab-${this.state.selectedTabValue}`}
                                    />
                                    <Tab
                                        className={`choose_from_gallery_tab ${this.props.classes.title_font}`}
                                        label="Uploaded by Me"
                                        id={`simple-tab-${this.state.selectedTabValue}`}
                                    />

                                </Tabs>
                            </Grid>

                            <Grid item xs={2} sm={2} md={1} lg={1} className="choose_right_image_div">
                                <div className={this.props.selectedGalleryImageId ?
                                    `choose_right_image question_opacity_1 image_right_spacing ${this.props.classes.button_color}` :
                                    `choose_right_image question_opacity_light image_right_spacing ${this.props.classes.button_color}`}
                                    onClick={this.onChoose}>
                                    <img src={imageCheckSVG}></img>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}
                            className="selected_text_grid padding-10">
                            {this.props.selectedGalleryImageId ?
                                <Grid item xs={12}>
                                    <Typography
                                        className={`selected_text ${this.props.classes.heading_font}`}
                                    >
                                        1 Selected
                                    </Typography>
                                </Grid>
                                : <></>
                            }
                            {this.state.galleryImagelist && this.state.galleryImagelist.length > 0 ?
                                <>
                                    {this.state.galleryImagelist?.map((imageItem: any) =>
                                        <Grid item xs={12} sm={6} md={4} key={imageItem.id} className="position_relative">
                                            <img
                                                src={imageItem?.attributes?.url}
                                                className="galley_image"
                                                onClick={() => {
                                                    this.props.onClickImage(imageItem.id, imageItem?.attributes?.url)
                                                }}></img>

                                            <div
                                                className={this.props.selectedGalleryImageId === imageItem.id ? "layer flex_check_img" : "layer hidden_check_img"}
                                                ref="layer"
                                                id={`layer-id-${imageItem.id}`}>
                                                <img src={imageCheckmark} className="gallery_check_img"></img>
                                            </div>
                                        </Grid>
                                    )}
                                    <div className="choose_from_gallery">
                                        <ReactPaginate
                                            previousLabel={<>←{" "}<span className="pagination_margin xs_display"
                                            >Previous</span></>}
                                            nextLabel={<><span className="pagination_margin xs_display"
                                            >Next</span>{" "}→</>}
                                            breakLabel="..."
                                            initialPage={this.state.page}
                                            pageCount={this.state.galleryImagelistMeta?.pagination?.total_pages}
                                            onPageChange={this.onPageChange}
                                            containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                                            previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                                            nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                                            disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                                            activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                                            pageRangeDisplayed={2}
                                            marginPagesDisplayed={2}
                                        />
                                    </div>
                                </>
                                :
                                <>
                                    <Typography
                                        className={`no_record_text ${this.props.classes.bodyText_font}`}
                                    >
                                        No record(s) found.
                                    </Typography>
                                </>
                            }

                        </Grid>
                    </Grid>
                </Modal>
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start

export default withStyles(themeCreateStyle)(ChooseFromGalleryModal as React.ComponentType<any>);
  // Customizable Area End
