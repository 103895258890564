// Customizable Area Start
import React from "react";
import {
  Typography,
  Box,
  Grid,
  withStyles,
  IconButton,
} from "@material-ui/core";
import TeacherReportsController from "./TeacherReportsDashboardController.web";
import { Settings } from "@material-ui/icons";
import { HISTORY } from "../../../../components/src/comman";
import "./common.css";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

export class TeacherReportsDashboard extends TeacherReportsController {
  // Customizable Area Start

  render() {
    return (
      <Box marginTop={3}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" className={this.props.classes.title_font}>
            Reports
          </Typography>

          <IconButton className="icon_button">
            <Settings color="primary" className="setting_box" />
          </IconButton>
        </Box>

        <Box marginBottom={3}>
          <Typography
            className={`${this.props.classes.titleFont} teacher_reports_dashboard_title`}

          >
            Single Assessment Report
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {this.state?.singleAssessmentReport?.map((item, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                key={item?.id}
                className="pointer_cursor"
                onClick={() => {
                  HISTORY.push({
                    pathname: "/AssessmentSummaryReport",
                    state: {
                      tabValue: item?.tabIndex,
                    },
                  });
                }}
              >
                <Box className="card">
                  <h2 className="card_title">{item?.name}</h2>
                  <p className="card_sub_title">{item?.description}</p>

                  <img src={item?.image} className="card_in_image" />
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <hr />
        <Box marginTop={3} marginBottom={3}>
          <Typography
            className={`${this.props.classes.titleFont}teacher_reports_dashboard_title`}


          >
            Multiple Assessment Report
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {this?.state?.multipleAssessmentReport?.map((item, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                spacing={3}
                key={item?.id}
                className="pointer_cursor"
              >
                <Box className="card">
                  <h2 className="card_title">{item?.name}</h2>
                  <p className="card_sub_title">{item?.description}</p>

                  <img src={item?.image} className="card_in_image" />
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <hr />

        <Box marginTop={3} marginBottom={3}>
          <Typography
            className={`${this.props.classes.titleFont} teacher_reports_dashboard_title`}

          >
            Standards Mastery Report
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {this?.state?.standardsMasteryReport?.map((item, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                spacing={3}
                key={item?.id}
                className="pointer_cursor"
              >
                <Box className="card">
                  <h2 className="card_title">{item?.name}</h2>
                  <p className="card_sub_title">{item?.description}</p>
                  <img src={item?.image} className="card_in_image" />
                </Box>
              </Grid>
            );
          })}
        </Grid>

        <hr />

        <Box marginTop={3} marginBottom={3}>
          <Typography
            className={`${this.props.classes.titleFont} teacher_reports_dashboard_title`}

          >
            Student Profile Report
          </Typography>
        </Box>
        <Grid
          container
          spacing={3}
          className='margin_bottom_20'
        >
          {this?.state?.studentProfileReport?.map((item, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                spacing={3}
                key={item?.id}
                className="pointer_cursor"
              >
                <Box className="card">
                  <h2 className="card_title">{item?.name}</h2>
                  <p className="card_sub_title">{item?.description}</p>

                  <img src={item?.image} className="card_in_image" />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(TeacherReportsDashboard);
// Customizable Area End
