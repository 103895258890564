// Customizable Area Start
import React from 'react';
import { Box, Button, Typography, Grid } from '@material-ui/core';
import {
  imgOnlineLearning,
  imgQuestionAndAnswer,
  imgOnlineTestTime,
} from './assets';
import TeacherAssessmentModuleController, {
  Props,
} from './TeacherAssessmentModuleController.web';
import HOCAuth from '../../shared/Header/src/HOCAuth.web';
import { withStyles } from '@material-ui/core/styles';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const styles = {
  assessmentCard: {
    borderRadius: 17,
    boxShadow: '0 11px 32px 1px rgba(255, 143, 100, 0.74)',
    backgroundImage: 'radial-gradient(circle at 50% 3%, #ff8d71, #ff9153 96%)',
    paddingLeft: 49,
    paddingTop: 100,
    paddingRight: 49,
    paddingBottom: 49,
    textAlign: 'center',
    position: 'relative',
    minHeight: '344px',
    minWidth: '268px',
  },
  libraryCard: {
    borderRadius: 17,
    boxShadow: '0 11px 32px 1px #54a3ff',
    backgroundImage: 'radial-gradient(circle at 50% 3%, #71f9ff, #53a0ff 96%)',
    paddingLeft: 49,
    paddingTop: 100,
    paddingRight: 49,
    paddingBottom: 49,
    textAlign: 'center',
    position: 'relative',
    minHeight: '344px',
    minWidth: '268px',
  },
  newTestCard: {
    borderRadius: 17,
    boxShadow: '0 11px 32px 1px #ff5354',
    backgroundImage: 'radial-gradient(circle at 50% 3%, #db71ff, #ff5353 96%)',
    paddingLeft: 49,
    paddingTop: 100,
    paddingRight: 49,
    paddingBottom: 49,
    textAlign: 'center',
    position: 'relative',
    minHeight: '344px',
    minWidth: '268px',
  },
  cards: {
    justifyContent: 'space-evenly',
    marginTop: 80,
    marginBottom: 80,
    display: 'flex',
    alignItems: 'center',
  },
  cardText: {
    fontSize: 30,
    color: '#FFF',
    fontWeight: 'bold',
    minHeight: '115px',
    display: 'flex',
    justifyContent: 'center',
    placeItems: 'center',
  },
  cardImage: {
    height: 180,
    width: 180,
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },
  assessmentCardButton: {
    color: '#fe8d68',
    backgroundColor: '#FFF',
    fontWeight: 600,
    fontSize: 28,
    width: '100%',
    borderRadius: 11,
    border: 'solid 4px #fff',
    textTransform: 'capitalize',
    padding: '0px 0',

    '&:hover': {
      color: '#fe8d68',
      backgroundColor: '#FFF',
    },
  },
  libraryCardButton: {
    color: '#5dc0ff',
    backgroundColor: '#FFF',
    fontWeight: 600,
    fontSize: 28,
    width: '100%',
    borderRadius: 11,
    border: 'solid 4px #fff',
    textTransform: 'capitalize',
    padding: '0px 0',
    '&:hover': {
      color: '#5dc0ff',
      backgroundColor: '#FFF',
    },
  },
  newTestCardButton: {
    cursor: 'pointer',
    color: '#f45c87',
    backgroundColor: '#FFF',
    fontWeight: 600,
    fontSize: 28,
    width: '100%',
    borderRadius: 11,
    border: 'solid 4px #fff',
    textTransform: 'capitalize',
    padding: '0px 0',
    '&:hover': {
      color: '#f45c87',
      backgroundColor: '#FFF',
    },
  },
} as const;
// Customizable Area End

export class TeacherAssessmentModule extends TeacherAssessmentModuleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    return (
      <Box>
        <Box p={3}>
          <Typography
            className={`assessment_module_text ${this.props.classes.heading_font
              }`}
          >
            Assessment Module
          </Typography>
        </Box>
        <Grid
          container
          spacing={8}
          style={styles.cards}
          className="module_cards"
        >
          <Grid item xs={12} sm={4} className="centered_card">
            <div className="assessment_card">
              <img src={imgQuestionAndAnswer} className="card_image shadow" />
              <Typography
                className={`card_text assesment_static_color_txt`}
              >
                Create new <br /> Assessment
              </Typography>
              <Button
                id="create-assessment-button"
                style={styles.assessmentCardButton}
                onClick={this.createGuidelineUrl}
                className="sm_font_22"
              >
                Create
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} className="centered_card">
            <div className="library_card">
              <img src={imgOnlineTestTime} className="card_image" />
              <Typography
                className={`card_text assesment_static_color_txt`}
              >
                Library
              </Typography>
              <Button
                id="library-button"
                style={styles.libraryCardButton}
                onClick={this.libraryUrl}
                className="sm_font_22"
              >
                Browse
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} className="centered_card">
            <div className="new_test_card">
              <img src={imgOnlineLearning} className="card_image" />
              <Typography
                className={`card_text assesment_static_color_txt`}
              >
                Upload
              </Typography>
              <Button
                id="upload-button"
                style={styles.newTestCardButton}
                onClick={this.newTestUrl}
                className="sm_font_22"
              >
                Upload
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(TeacherAssessmentModule as React.ComponentType<any>, 'TeacherAccount')
);
// Customizable Area End
