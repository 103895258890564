// Customizable Area Start
import React from "react";
import {
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton
} from "@material-ui/core";
import ReactPaginate from "react-paginate";
import DraftReportCardController, { Props } from "./DraftReportCardController.web";
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../../../shared/Header/src/HOCAuth.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import { editPurpleIcon, imgBackArrow, deleteOutlineIcon } from "../../assets";
import moment from "moment";
import WarningModal from "../../../../AssessmentTest/src/WarningModal.web";
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";

// Customizable Area End
// Customizable Area Start
// Customizable Area End
export class DraftReportCard extends DraftReportCardController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      showLoader,
      reportCardList
    } = this.state;

    return (
      <>
        {this.state.showLoader && (
          <Spinner spinnerModal={this.state.showLoader} />
        )}

        <Typography
          style={{
            fontFamily: "Open sans",
            fontSize: 20,
            fontWeight: 600,
            color: "#3f526d",
            cursor: "pointer",
            marginTop: 20,
          }}
          onClick={this.handleBack}
        >
          <img
            src={imgBackArrow}
            style={{
              height: 14,
              width: 18,
              objectFit: "contain",
              marginRight: 10,
            }}
          />
          Back
        </Typography>

        <div
          className={`draft-screen-text ${this.props.classes.heading_font
            }`}
        >
          Report Card Drafts
        </div>

        <div>
          <React.Fragment>
            <TableContainer style={{ borderRadius: 6, border: 0 }}>
              <Table style={{ width: "100%" }}>
                <TableHead style={{ backgroundColor: "#ded9ff" }}>
                  <TableRow>
                    <TableCell className={`${this.props.classes.subTitle_font} border-radius-0`} style={{ padding: "17px 20px", fontSize: 20, fontWeight: "bold" }}>Name</TableCell>
                    <TableCell className={this.props.classes.subTitle_font} style={{ padding: "17px 20px", fontSize: 20, fontWeight: "bold" }}>Creation Date</TableCell>
                    <TableCell className={this.props.classes.subTitle_font} style={{ padding: "17px 20px", fontSize: 20, fontWeight: "bold" }}>Class</TableCell>
                    <TableCell className={`${this.props.classes.subTitle_font} border-radius-0`} style={{ padding: "17px 20px", fontSize: 20, fontWeight: "bold" }}>
                      <div style={{ display: "flex", justifyContent: "center" }}>Action</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ marginTop: 10, backgroundColor: "#f9f8fd" }}>
                  {
                    reportCardList?.length === 0 || reportCardList === undefined ?
                      <TableRow>
                        <TableCell
                          style={{ padding: "17px 0px 16px 20px", color: "#3f526d", fontFamily: 'Open sans', fontSize: 18 }}
                          colSpan={7}
                          align="center">
                          No record(s) found.
                        </TableCell>
                      </TableRow>
                      :
                      <>
                        {reportCardList?.map((data: any, ind: number) => (
                          <React.Fragment key={ind}>
                            <TableRow>
                              <TableCell className={`${this.props.classes.subTitle_font} border-radius-0`} style={{ padding: "17px 20px", border: "1px solid lightgray", fontSize: 18, fontWeight: 600 }}>
                                {data.attributes.title ? data?.attributes?.title : '-'}
                              </TableCell>
                              <TableCell className={this.props.classes.subTitle_font} style={{ padding: "17px 20px", border: "1px solid lightgray", fontSize: 18, fontWeight: 600, textTransform: "capitalize" }}>
                                {moment(data?.attributes?.created_date, "YYYY-MM-DD").format("DD / MM / YYYY")}
                              </TableCell>
                              <TableCell className={this.props.classes.subTitle_font} style={{ padding: "17px 20px", border: "1px solid lightgray", fontSize: 18, fontWeight: 600 }}>
                                {
                                  (data?.attributes?.grades === null || data?.attributes?.grades?.length === 0) && '-'
                                }
                                {data?.attributes?.grades?.[0]?.name}
                              </TableCell>
                              <TableCell className={`${this.props.classes.subTitle_font} border-radius-0`} style={{ padding: "17px 20px", border: "1px solid lightgray", fontSize: 18, fontWeight: 600 }}>
                                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                  <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    style={{
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                    }}
                                    className="padding-7"
                                    onClick={() => this.onClickDeleteIcon(data)}
                                    data-test-id={`draft-report-delete-icon-${ind}`}
                                  >
                                    <img
                                      src={deleteOutlineIcon}
                                      style={{
                                        height: 28,
                                        width: 28,
                                        objectFit: "contain",
                                      }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    style={{
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                    }}
                                    className="padding-7"
                                    onClick={() => this.onClickEditIcon(data)}
                                    data-test-id={`draft-report-edit-icon-${ind}`}
                                  >
                                    <img
                                      src={editPurpleIcon}
                                      style={{
                                        height: 24,
                                        width: 24,
                                        objectFit: "contain",
                                      }}
                                    />
                                  </IconButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}
                      </>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>

        </div>

        {/* pagination code goes here */}
        {
          reportCardList?.length === 0 || reportCardList === undefined
            ? null :
            <div style={{ overflowX: "auto" }} className="choose_from_gallery">
              <ReactPaginate
                previousLabel={<>←   <span className="xs_display">Previous</span></>}
                nextLabel={<><span className="xs_display">Next</span>   →</>}
                breakLabel="..."
                initialPage={this.state.page}
                pageCount={this.state.reportCardListMeta?.pagination?.total_pages}
                onPageChange={(e: any) => this.onPageChange(e)}
                containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
              />
            </div>
        }
        <WarningModal
          title={"Delete Report Card?"}
          subTitle={"Are you sure you want to delete!"}
          paragraph={""}
          warningModal={this.state.openDeleteWarningModal}
          onCloseModal={this.onCloseWarningModal}
          warningClickOk={this.deleteReportCard}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(DraftReportCard as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End