// Customizable Area Start
import React from "react";
import { Box, Tabs, Button, Typography } from "@material-ui/core";
import UserDocumentController, { Props } from "./UserDocumentController.web";
import { withStyles } from "@material-ui/core";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
// Customizable Area Start
const tabsList = [
  {
    key: 1,
    value: "teachers",
    label: "Teachers",
  },
  {
    key: 2,
    value: "students",
    label: "Students",
  },
];
// Customizable Area End

export class UserDocument extends UserDocumentController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      selectedIndex,
    } = this.state;
    // Customizable Area End
    // Customizable Area Start
    return (
      <Box width="100%">
        <Box
          display="flex"
          width="100%"
          pb="20px"
          marginTop="2%"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="row"
            width="40%"
            alignItems="center"
          >
            <Box display="flex" justifyContent="flex-start">
              <Typography
                className={`UserDocumentText ${this.props.classes.title_font}`}
              >
                User Document
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box width="100%">
          <Box display="flex" flexDirection="column" pr="10px">
            <Tabs
              value={"resources"}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabsList.map((item, i) => (
                <Button
                  className={item.key === selectedIndex ? `UserDocumentBtnTab ${this.props.classes.button_color}` : `UserDocumentBtnTab ${this.props.classes.button_color_disabled}`}
                  key={item.key}
                  onClick={() => {
                    this.handleSelectTab(item.value, item.key);
                  }}
                >
                  <Typography className="UserDocumentTabText">
                    {item.label}
                  </Typography>
                </Button>
              ))}
            </Tabs>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" className="UserDocFullWidth" >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {this.screen()}
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default HOCAuth(
  withStyles(themeCreateStyle)(UserDocument as React.ComponentType<any>),
  "AdminAccount"
);
// Customizable Area End

// Customizable Area End
