// Customizable Area Start
import React from 'react';
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
// Customizable Area End


// Customizable Area Start
const useStylesCircularProgress = makeStyles((theme: any) => ({
    root: {
        position: "relative"
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    present: {
        color: "#4caf50",
        position: "absolute",
        left: 0
    },
    absent: {
        color: "#ff5722",
        position: "absolute",
        left: 0
    },
    circle: {
        strokeLinecap: "round"
    },
    percentageText: {
        display: 'flex',
        fontSize: 46,
        fontWeight: 600,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`,
    },
    percentSign: {
        fontSize: 18,
        marginTop: 14,
        opacity: 0.6
    }
}));
// Customizable Area End

// Customizable Area Start
export const CustomCircularProgress = (props: any) => {

    const classes = useStylesCircularProgress();
    return (
        <div className={classes.root}>
            <CircularProgress
                variant="static"
                style={{ color: "#eceff1" }}
                size={162}
                thickness={2.5}
                value={100}
            />
            <CircularProgress
                variant="static"
                disableShrink
                className={classes.absent}
                classes={{
                    circle: classes.circle
                }}
                size={162}
                value={props.absent}
                thickness={2.5}
            />
            <CircularProgress
                variant="static"
                disableShrink
                className={classes.present}
                classes={{
                    circle: classes.circle
                }}
                size={162}
                value={props.present}
                thickness={2.5}
            />
            <div>
                <Typography
                    className={`${classes.percentageText} ${props.classes.bodyText_font}`}
                >
                    {props.circular_percentage}<span className={classes.percentSign}>%</span>
                </Typography>
            </div>

        </div>
    );
}
// Customizable Area End


// Customizable Area Start
export default CustomCircularProgress;
// Customizable Area End