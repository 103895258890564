// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  IconButton
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import HOCAuth from '../../../shared/Header/src/HOCAuth.web';
import './ParentNewDashboard.css';
import ParentNewDashboardController, { Props } from './ParentNewDashboardController.web';
import ReminderQuotes from "./ReminderQuotes/ReminderQuotes.web";
import ParentNotificationWeb from './ParentNotification/ParentNotification.web';
import ParentCalendarWeb from './ParentCalendar/ParentCalendar.web';
import ParentNotesWeb from './ParentNotes/ParentNotes.web';
import WardTasks from './WardDetails/WardTasks/WardTasks.web';
import WardSubjects from './WardDetails/WardSubjects/WardSubjects.web';
import WardResources from './WardDetails/WardResources/WardResources.web';
import WardResultWeb from './WardDetails/WardResult/WardResult.web';
import WardAttendanceWeb from './WardDetails/WardAttendance/WardAttendance.web';
import WardDetailsWeb from './WardDetails/WardDetails.web';
import ParentLeaveWeb from './ParentLeave/ParentLeave.web';
import ParentFees from './ParentFees/ParentFees.web';
import ParentAnnouncement from './ParentAnnouncement/ParentAnnouncement.web';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { CREATE_ICON, MESSAGE_ICON } from "../assets";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
function TabPanel(props:any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt="5px" pl="5px" >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// Customizable Area End
export class ParentNewDashboard extends ParentNewDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      data,
      wardsLinkedToParent,
      childActivityTabs,
      tabValue,
      selectedWard
    } = this.state;
   console.clear()
   console.log("selcted ward",selectedWard)
    return (
      <Box
        className="fluid-flex"
        style={{ display: 'flex', width: '100%', marginTop: '25px', paddingBottom: '20px' }}
      >
        <Box
          className="leftsidebar-col"
        >
          {/* Parent Profile Pic and Name */}
          <Grid className='parent_grid_box_shadow'
            container
            alignItems="center"
          >
            <Grid item xs={4} sm={2} className={`parent-avatar-grid`}>
              {data?.avatar ? (
                <img
                  src={data.avatar}
                  className={this.props.classes.image_Border_Color}
                  style={{
                    cursor: 'pointer',
                    borderRadius: '50%',
                    height: 100,
                    width: 100,
                    padding: 3,
                  }}
                />
              ) : (
                <Avatar
                  src={'/'}
                  className={`${this.props.classes.image_Border_Color} profile_img`}
                />
              )}
                <Typography
                  className={`${this.props.classes.parentNewDashboard_dashboard_font_16} ${this.props.classes.subHeading_font} mt-10`}
                >
                  {data?.type === 'TeacherAccount' ? (
                    <>Parent</>
                  ) : (
                    <>Student</>
                  )}
                </Typography>
            </Grid>
            <Grid item xs={8}  sm={10} >
              <Box
                className="parent_info_block"
                display="flex"
                flexDirection="column"
              >
                <Typography
                >
                  <span
                    className={`${this.props.classes.parentNewDashboard_dashboard_heading_font} hello_text`}
                  >
                    Hello,{' '}
                  </span>
                  <span className={this.props.classes.parentNewDashboard_dashboard_heading_font}>
                    {data?.first_name} {data?.last_name}
                  </span>
                </Typography>
                <Typography
                  className={`${this.props.classes.parentNewDashboard_dashboard_font_19} ${this.props.classes.subHeading_font} mt-11`}
                >
                 Have a great day. 
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {/* Child Activity */}
          <Grid
            className="parent_grid_box_shadow parent_grid_box"
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
             className="flex_direction"
            >
             <div className="flex-justify-bw">
                <div className={`${this.props.classes.parentNewDashboard_dashboard_heading_font} bold_heading`}>Child Activity</div>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                >
                  <MoreVertIcon />
                </IconButton>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Box mt="5px" mb="20px" className='ward_name_tab'>
              {wardsLinkedToParent?.length >0 &&
                wardsLinkedToParent.map((ward:any,index:number)=>
                <div onClick={(e:any)=>this.handleSelectWard(e,ward.id)} key={index} className={(parseInt(selectedWard?.id) === parseInt(ward.id)) ? `${this.props.classes.background_color} ${this.props.classes.bodyText_font} ward_name` :`${this.props.classes.bodyText_font} ${this.props.classes.parentNewDashboard_dashboard_border_Color} ward_name ward_name_inactive`}>
                    <span>{`${ward.first_name} ${ward.last_name}`}</span>
                </div>
                )
              }
              </Box>
            </Grid>
            <Grid item xs={12}
            className={`parent_detail_grid`}
            >
                <WardDetailsWeb />
            </Grid>

            <Grid
              item
              xs={12}
              className="tabs_details"
            >
              <Tabs
                value={tabValue}
                onChange={(event:any, value:any) =>
                  this.handleTabChange.onChangeTab(value)
                }
                TabIndicatorProps={{
                 className:`${this.props.classes.button_color}`,
                 style: { margin: '0 0 14px 0' }
                }}
                className="parent_tab_indicator"
                variant="scrollable"
              >
                {childActivityTabs.map((tab:any,index:any)=>
                  <Tab
                  key={`tab-${index}`}
                  className={`parent_tab ${this.props.classes.subHeading_font}`}
                  label={tab.tabName}
                  id={`simple-tab-${index}`}
                />
                )}
              </Tabs>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    {/* Today's task tab */}
                      <TabPanel value={tabValue} index={0}>
                        <WardTasks />
                      </TabPanel>
                      {/* Subjects tab */}
                      <TabPanel value={tabValue} index={1}>
                      <WardSubjects />
                      </TabPanel>
                      {/* Resources tab */}
                      <TabPanel value={tabValue} index={2}>
                        <WardResources />
                      </TabPanel>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Attendance */}
            <Grid item xs={12}
              className={`parent_result_grid`}
            >
                <WardAttendanceWeb />
            </Grid>
            {/* Attendance END */}

            {/* Result */}
            <Grid item xs={12}
              className={`parent_result_grid`}
            >
                <WardResultWeb />
            </Grid>
            {/* Result END */}
           
          </Grid>
          {/* Fees */}
          <Grid
            className="parent_grid_box_shadow parent_grid_box"
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
              className="center_justify flex_direction"
            >
              <Typography
                className={`${this.props.classes.parentNewDashboard_dashboard_heading_font} bold_heading`}
                component="span"
              >
                Fees
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ParentFees />
            </Grid>
          </Grid>
          {/* Reminder and Quotes */}
          <Grid
            container
            className="parent_grid_box_shadow parent_grid_box"
          >
            <Grid item xs={12}>
            <Typography
              className={`${this.props.classes.parentNewDashboard_dashboard_heading_font} bold_heading`}
              component="span"
            >
              Reminder & Quote
            </Typography>
            </Grid>
          <Grid item xs={12}>
          <ReminderQuotes />
          </Grid>
          </Grid>
        </Box>
        <Box
          className="parent_right_sidebar"
        >
          {/* Notification Block */}
          <Box className={`parent-flex-end`}>
            <Button  
              className={`${this.props.classes.button_color} parent-edit-widget-btn`}
              onClick={() => {
                // TODO
              }}
            >
              Edit Widget
            </Button>
          </Box>
          <Box className={`parent_grid_box`}>
            <ParentAnnouncement />
          </Box>
          <Box className={`parent_grid_box`}>
            <ParentNotificationWeb />
          </Box>
          {/* Parent Calendar */}
          <Grid container className='cal-pd parent_grid_box_shadow parent_grid_box'
          >
            <Grid item xs={12} className="flex-justify-bw">
              <Typography 
              style={{
                fontWeight: 600,
                marginBottom: 15,
              }}
              className={this.props.classes.parentNewDashboard_dashboard_heading_font}
              >
                Upcoming Events
              </Typography>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
              >
                <MoreVertIcon />
              </IconButton>
            </Grid>

          <ParentCalendarWeb />
          </Grid>
          {/* Parent Leave */}
          <Grid container className='cal-pd parent_grid_box_shadow parent_grid_box'
          >
            <ParentLeaveWeb />
          </Grid>
          {/* Parent Leave End */}
          {/* Parent Notes */}
          <Grid container className='cal-pd parent_grid_box_shadow parent_grid_box'
          >
            <Grid item xs={12} className="flex-justify-bw">
              <Typography
                className={`${this.props.classes.parentNewDashboard_dashboard_heading_font}`}
              >
                Notes
              </Typography>
              <Box className="center_align">
                <img className="parent_note_widget_create_icon" src={CREATE_ICON} alt="add notes" />
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Grid>
          
            <Grid xs={12}>
              <ParentNotesWeb />
            </Grid>
          </Grid>

          {/* Parent Sms Start */}
          <Box className='fixed_sms_icon'>
            <img src={MESSAGE_ICON} alt="Send message" className="parent_sms_icon"/>
          </Box>
          {/* Parent Sms End */}
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(ParentNewDashboard, 'TeacherAccount')
);
// Customizable Area End
