// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { toast } from "react-toastify";

export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    openModal: boolean;
    closeModalFun: any;
    heading: any;
    description: any;
    notes_id: any;
    classes: any;
    role?:any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    data: any;
    closeModal: boolean;
    noteHeader: string;
    noteDescription: string;
    deleteModal: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EditNoteController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apideleteNotesId: string = "";
    apiupdateNotesId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            data: [],
            closeModal: false,
            noteHeader: '',
            noteDescription: '',
            deleteModal: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.get_userdata = this.get_userdata.bind(this);
        // Customizable Area End
    }


    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    get_userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }
    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.closeModalFun(this.state.closeModal)
    }
    closeModal = (closeModal: boolean) => {
        this.setState({ deleteModal: closeModal })
    }
    saveCloseModal = (closeModal: boolean) => {
        this.setState({ deleteModal: closeModal })
        this.delete_notes()
    }
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apideleteNotesId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.props.closeModalFun(!this.state.closeModal)
                        toast.success("Notes has been deleted successfully")

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiupdateNotesId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.props.closeModalFun(!this.state.closeModal)
                        toast.success("Notes has been edited successfully")

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    delete_notes = () => {
        const school_data = localStorage.getItem("user_data");
        const userdata = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            school: userdata.school_id,

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apideleteNotesId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.CRUDNotesEndPoint + `/${this.props.notes_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.InstituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarDELETEMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    update_notes = () => {
        const { noteHeader, noteDescription } = this.state;
        const school_data = localStorage.getItem("user_data");
        const userdata = JSON.parse(school_data || "{}");
        const data = {
            title: noteHeader ? noteHeader : this.props.heading,
            description: noteDescription ? noteDescription : this.props.description,
        }

        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            school: userdata.school_id,

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiupdateNotesId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.CRUDNotesEndPoint + `/${this.props.notes_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.InstituteURL
        );
        const httpBody = data;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarPUTMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
