Object.defineProperty(exports, "__esModule", {
  value: true,
});

const urlConfig = require("../../../../framework/src/config");

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleAPiPUTMethod = "POST";
exports.termListEndPoint = "/bx_block_assessmenttest/terms/term_list";
exports.categoryListEndPoint = "bx_block_assessmenttest/report_category_types/list";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AssessmentTest";
exports.labelBodyText = "AssessmentTest Body";
exports.getQuestionThemeEndPoint = "bx_block_assessmenttest/assessment_themes";
exports.btnExampleTitle = "CLICK ME";

exports.apiPutMethodType = "PUT";
exports.apiDeleteMethodType = "DELETE";
exports.examinationUrl = urlConfig.examinationUrl;
exports.dashboardURL = urlConfig.dashboardURL;
exports.instituteURL = urlConfig.instituteURL;
exports.userManagementURL = urlConfig.baseURL;
exports.attendanceURL = urlConfig.attendanceURL;
// exports.getSubjectsEndPoint = "/bx_block_scheduling/subjects/class_section_subjects";
// exports.questionEndPoint = ""
// exports.apiAssessmentUpload = "/bx_block_assessmenttest/questions/import_questions"
exports.apiAssessmentSummary = "/bx_block_assessmenttest/assessments";
exports.apiAssessmentPublish =
  "/bx_block_assessmenttest/assessments/publish_assessment";
exports.apiBuildAssessmentEndPoint =
  "bx_block_assessmenttest/assessments/build_assessment";
exports.apiUpdateAssessmentEndPoint = "bx_block_assessmenttest/assessments";
exports.getSubjectsEndPoint = "/bx_block_scheduling/school_classes/";
exports.questionEndPoint =
  "bx_block_assessmenttest/questions/list_subject_questions";
exports.questionLibraryEndPoint =
  "bx_block_assessmenttest/questions/get_question_bank_questions";
exports.getTeachersListEndPoint =
  "bx_block_scheduling/grades/get_account_class_subject_ids";
exports.getQuestionsTypeListEndPoint =
  '/bx_block_assessmenttest/question_categories';
exports.chapterEndPoint = '/bx_block_scheduling/chapters';
exports.questionMetadataEndPoint =
  "bx_block_assessmenttest/questions/get_questions_preview";
exports.questionAnswerEndPoint = "bx_block_assessmenttest/questions/";
exports.apiAssessmentUpload =
  "/bx_block_assessmenttest/questions/import_questions";
exports.getDivisionsEndPoint =
  "/bx_block_scheduling/school_classes/student_by_divisions";
exports.getGradeEndPoint = "/bx_block_scheduling/grades";
exports.getTeachersGradeEndPoint = '/bx_block_dashboard8/dashboard/get_teacher_grades';
exports.getAssessmentsEndPoint = "bx_block_assessmenttest/assessments";
exports.createAssessmentsEndPoint = 'bx_block_assessmenttest/assessments/create_assessment';
exports.updateAssessmentsEndPoint = 'bx_block_assessmenttest/assessments';
exports.getSubjectsWithAssessmentCount = 'bx_block_assessmenttest/assessments/get_subject_assessments_count';
exports.getAssessmentListEndPoint = 'bx_block_assessmenttest/assessments/get_assessment_library';
exports.POST = "POST";
exports.PUT = "PUT";
exports.GET = "GET";
exports.DELETE = "DELETE";
// Customizable Area End
