// Customizable Area Start
import React from 'react';
import './AdminReportCardTemplate.web.css';
import ReactPaginate from "react-paginate";
import { withStyles } from '@material-ui/core/styles';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import StudentPaginationController, { Props } from './StudentPaginationController.web';
import { Box } from '@material-ui/core';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class StudentPagination extends StudentPaginationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return (
      <Box mt={2}>
        <ReactPaginate          
            previousLabel={"←   Previous"}
            nextLabel={"Next   →	"}
            breakLabel="..."
            initialPage={this.props.intialPage}
            pageCount={ 4 }
            onPageChange={(e: any) => {
                this.onTabchangeClick (e.selected)
            }}
            containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
            previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
            nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
            disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
            activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
            pageRangeDisplayed={2}
            marginPagesDisplayed={10}
          />
      </Box>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(StudentPagination as React.ComponentType<any>);
// Customizable Area End