// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: any;
  otperror: boolean;
  otperrorText: string;
  loadingSpinner: boolean;

  otp_code: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  data: any;
  isOtpCorrect: boolean;
  // Customizable Area End
}

export interface SS {
  id: any;
}

export default class AccountPhoneVerifyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createOtpApiCallId: any;
  resendOtpApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      otperror: false,
      otperrorText: "",
      otp: null,
      loadingSpinner: false,
      otp_code: '',
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      data: '',
      isOtpCorrect: false
    };
    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.createOtpApiCallId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            if (responseJson.data.attributes.phone_activated && responseJson.data.attributes.email_activated) {
              this.props.navigation.navigate("AccountSucessFull");
              localStorage.setItem("token", responseJson.meta.token);
              sessionStorage.setItem("otp-token", responseJson.meta.token);
            }
            else if (responseJson.data.attributes.phone_activated) {
              this.setState({ isOtpCorrect: true }, () => {
                setTimeout(() => {
                  this.props.navigation.navigate("AccountVerify");
                }, 500);
              });
            }
            localStorage.setItem("id", responseJson.data.id);

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false });
      } else if (apiRequestCallId === this.resendOtpApiCallId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            toast.success(responseJson.meta.message)
            this.setState({ otp: null })
            localStorage.setItem("token", responseJson.meta.token);

            sessionStorage.setItem("sms-token", responseJson.meta.sms_token);

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ loadingSpinner: false });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  callOtpApi = () => {
    const reg = /^\d+$/;
    const { otp1, otp2, otp3, otp4, otp_code } = this.state

    if (otp_code.concat(otp1, otp2, otp3, otp4) === null || otp_code.concat(otp1, otp2, otp3, otp4).length === 0) {
      this.setState({ otperror: true, otperrorText: "OTP is required." });
      return false;
    }
    if (otp_code.concat(otp1, otp2, otp3, otp4).length != 4) {
      this.setState({ otperror: true, otperrorText: "Enter a valid OTP." });
      return false;
    }
    if (!reg.test(otp_code.concat(otp1, otp2, otp3, otp4))) {
      this.setState({ otperror: true, otperrorText: "Enter a valid OTP." });
      return false;
    }
    const header = {
      "Content-Type": "application/json",
      token: sessionStorage.getItem("sms-token"),
    };
    const data = {
      pin: otp_code.concat(otp1, otp2, otp3, otp4),
    };
    var bodyFormData = new FormData();
    bodyFormData.append("pin", otp_code.concat(otp1, otp2, otp3, otp4));

    const httpBody = {
      data: data,
    };
    this.setState({ loadingSpinner: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherPhoneOtpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  resendOtpClick = () => {
    const { otp1, otp2, otp3, otp4 } = this.state
    this.setState({ loadingSpinner: true, otp1: " ", otp2: " ", otp3: " ", otp4: " " })
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: sessionStorage.getItem("otp-token"),
    };
    const data = {};

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherPhoneResendOtpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onteacherOtpSubmit = (event: any) => {
    event.preventDefault()
    if (this.state.data.phone_activated) {
      this.props.navigation.navigate("AccountVerify");
    } else {
      this.callOtpApi();
    }

  };

  onBackArrowOtp = () => {
    const user_data = localStorage.getItem("user_data");
    const data = localStorage.getItem("account_type") || {};
    if (data === "ParentAccount") {
      this.props.navigation.navigate("ParentAccountRegistration");
    }
    if (data === "StudentAccount") {
      this.props.navigation.navigate("StudentAccountRegistration");
    }
    if (data === "AdminAccount") {
      this.props.navigation.navigate("AdminAccountRegistration");
    }
    if (data === "TeacherAccount") {
      this.props.navigation.navigate("TeacherAccountRegistration");
    }
  };
  // Customizable Area End
}