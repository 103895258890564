Object.defineProperty(exports, '__esModule', {
  value: true,
});

const urlConfig = require('../../../framework/src/config');
// Customizable Area Start
exports.validationApiContentType = 'application/json';
exports.validationApiMethodType = 'GET';
exports.exampleAPiEndPoint = 'EXAMPLE_URL';
exports.exampleAPiMethod = 'POST';
exports.examplePUTAPiMethod = 'PUT';

exports.exampleApiContentType = 'application/json';
exports.textInputPlaceHolder = 'Enter Text';
exports.labelTitleText = 'AssessmentTest';
exports.labelBodyText = 'AssessmentTest Body';

exports.GET = 'GET';
exports.POST = 'POST';
exports.PUT = 'PUT';
exports.DELETE = 'DELETE';

exports.baseUrl = urlConfig.instituteURL;
exports.baseUrlAssessment = urlConfig.examinationUrl;
exports.termListEndPoint = '/bx_block_assessmenttest/terms/term_list';
exports.categoryListEndPoint =
  'bx_block_assessmenttest/report_category_types/list';
exports.getAdminGuidelinesEndPoint =
  'bx_block_assessmenttest/assessment_guidelines';
exports.getThemesEndPoint = 'bx_block_assessmenttest/assessment_themes';
exports.getAssessmentsEndPoint = 'bx_block_assessmenttest/assessments';
exports.createAssessmentsEndPoint =
  'bx_block_assessmenttest/assessments/create_assessment';
exports.updateAssessmentsEndPoint = 'bx_block_assessmenttest/assessments';
exports.getStateAssessmentEndPoint =
  'bx_block_assessmenttest/assessments/state_assessment';
exports.getTeacherAssessmentTermsEndPoint =
  '/bx_block_assessmenttest/assessments/get_teacher_assessment_terms';
exports.getClassesEndPoint =
  'account_block/school_accounts/get_teacher_classes';
exports.getDivisionsEndPoint =
  'account_block/school_accounts/get_teacher_divisions';
exports.getThemeEndPoint =
  '/bx_block_assessmenttest/assessment_themes/select_assessment_theme';
exports.getSubjectEndPoint = 'account_block/school_accounts/get_class_subjects';
exports.getSubjectByGradeEndPoint =
  '/bx_block_scheduling/subjects/get_teacher_subject_on_grade';
exports.getSchoolSubjectEndPoint =
  'bx_block_scheduling/subjects/account_subjects';
exports.getGradesEndPoint = 'bx_block_scheduling/grades';
exports.getAssessmentsListEndPoint =
  '/bx_block_assessmenttest/assessments/teacher_assessments';
exports.apiAssessmentSummary = '/bx_block_assessmenttest/assessments';
exports.examinationUrl = urlConfig.examinationUrl;
exports.getQuestionThemeEndPoint = 'bx_block_assessmenttest/assessment_themes';
exports.btnExampleTitle = 'CLICK ME';
exports.studentQuestionEndPoint = '/bx_block_assessmenttest/assessments/';
exports.submitQuestionAnswerEndPoint =
  '/bx_block_assessmenttest/assessments/submit_answer';
exports.getQuestionsList = 'bx_block_assessmenttest/question_categories';
exports.getQuestionsListv2 = 'bx_block_assessmenttest/question_categories/list';
exports.searchQuestionList =
  'bx_block_assessmenttest/question_categories/list?q=';
exports.getSchoolClassesList =
  'bx_block_scheduling/school_classes?page=1&per=100&school_id=';
exports.fileUploadS3 = 'bx_block_bulk_uploading/uploads/s3_signed_url';
exports.SignIDUrl = urlConfig.baseURL;
exports.getClassSubjectsList =
  'bx_block_scheduling/subjects/class_section_subjects?class_id=';
exports.getPerformanceByStudents =
  'bx_block_assessmenttest/report_graphs/get_performance_by_students?assessment_id=';
exports.getAssessmentSummaryReport =
  'bx_block_assessmenttest/report_graphs/get_assessment_summary_report';
exports.assessmentviewsummary = 'bx_block_assessmenttest/assessments';
//exports.assessmentviewsummary = '/bx_block_assessmenttest/assessments'
exports.apiPutMethodType = 'PUT';
exports.apiDeleteMethodType = 'DELETE';
exports.dashboardURL = urlConfig.dashboardURL;
exports.instituteURL = urlConfig.instituteURL;
exports.userManagementURL = urlConfig.baseURL;
exports.attendanceURL = urlConfig.attendanceURL;
exports.resultUrl = urlConfig.resultUrl;
exports.getSubjectsEndPoint =
  '/bx_block_scheduling/subjects/class_section_subjects';
exports.putThemeEndPoint =
  'bx_block_assessmenttest/assessment_themes/select_assessment_theme';
exports.deletequestionEndPoint = 'bx_block_assessmenttest/questions';
exports.chapterEndPoint = '/bx_block_scheduling/chapters';
exports.topicEndPoint = '/bx_block_scheduling/topics';
exports.saveQuestionEndPoint = '/bx_block_assessmenttest/questions';
exports.getPreSignedId = '/bx_block_bulk_uploading/uploads/pre_signed_url';
exports.getPreSignedUrl = 'bx_block_bulk_uploading/uploads/s3_signed_url';
exports.galleryImagesEndPoint = '/bx_block_bulk_uploading/uploads';
exports.getQuestionsTypeListEndPoint =
  '/bx_block_assessmenttest/question_categories';
exports.apiMoveQuestionsToSection =
  '/bx_block_assessmenttest/assessments/move_section_wise_questions';
exports.deleteAssessmentCSV =
  '/bx_block_assessmenttest/questions/delete_csv_file';
exports.assessment_filter =
  'bx_block_assessmenttest/report_graphs/get_filtered_assessments';
exports.presignedUrl = 'bx_block_bulk_uploading/uploads/pre_signed_url';
exports.removeQuestionFileUrl =
  'bx_block_assessmenttest/questions/remove_file_from_questions';
exports.getStudentAssessmentListEndPoint =
  '/bx_block_assessmenttest/assessments/student_assessments';
exports.getStudentAssessmentTermsEndPoint =
  '/bx_block_assessmenttest/assessments/get_student_assessment_terms';

exports.getStudentAssessmentListEndPoint =
  '/bx_block_assessmenttest/assessments/student_assessments';
exports.getStudentAssessmentTermsEndPoint =
  '/bx_block_assessmenttest/terms/term_list';

exports.studentSubmitPaperApiEndPoint =
  '/bx_block_assessmenttest/assessments/submit_exam';
exports.deleteSectionQuestionEndPoint =
  'bx_block_assessmenttest/assessment_sections/delete_section_question';
exports.startAssessmentTest = 'bx_block_assessmenttest/assessments/';
exports.createQueriesEndPoint =
  '/bx_block_assessmenttest/questions/create_queries';
exports.getGradeEndPoint = '/bx_block_scheduling/grades';
exports.getErrorCSVEndPoint =
  '/bx_block_assessmenttest/questions/list_question_error_csv';
exports.removeErrorCSVEndPoint =
  '/bx_block_assessmenttest/questions/remove_question_error_csv';
exports.removeQuestionAttachments =
  '/bx_block_assessmenttest/questions/remove_file_from_questions';

exports.getAssessmentQuestionAnalysis =
  'bx_block_assessmenttest/report_graphs/get_assessment_question_analysis';

exports.hotspotTypeQuestionToastMsg = {
  DRAW_ON_IMAGE: 'Please Draw on question image...',
  ADD_ANSWER: 'Please add answers to options...',
  EDIT_SUCCESS_MSG: 'Question updated successfully...',
  CREATE_SUCCESS_MSG: 'Question created successfully...',
  ERROR_MSG: 'Something went wrong...',
};

exports.STATIC_STRINGS = {
  REPORTS: 'Reports',
  FILTER_BY: 'Filter by',
  YEAR: 'Year',
  CLASS: 'Class',
  SUBJECT: 'Subject',
  TEST_TYPE: 'Test Type',
  FORMATIVE: 'Formative',
  SUMMATIVE: 'Summative',
  PRACTICE_PAPER: 'Practice Paper',
  CANCEL: 'Cancel',
  APPLY: 'Apply',
  '2023-24': '2023-24',
  '2022-23': '2022-23',
  '2021-22': '2021-22',
  '2020-21': '2020-21',
  '2019-20': '2019-20',
};
exports.common_question_type_strings = {
  add_answe_options_error: 'Please add answers to options.',
  label_on_question_image_error: 'Please add labels on question image.',
  marks_error: 'Marks not greater than section marks.',
  marks_enter_error: 'Please enter marks.',
};
exports.two_by_two_strings = {
  x_axis_min_max_error:
    'X-Axis minimum value should be less than maximum value',
  y_axis_min_max_error:
    'Y-Axis minimum value should be less than maximum value',
  x_axis_label_required: 'X-Axis label is required.',
  y_axis_label_required: 'Y-Axis label is required.',
  x_min_required: 'X-Axis Minimun value is required.',
  x_maximum_value: 'X-Axis Maximum value is required.',
  y_min_required: 'Y-Axis Minimun value required.',
  y_maximum_value: 'Y-Axis Maximum value is is required.',
  step_size_required: 'Step size is required.',
  correct_answer_required: 'Please add atleast one line to correct answer.',
  x1_required: 'Line x1 value is required',
  y1_required: 'Line y1 value is required',
  x2_required: 'Line x2 value is required',
  y2_required: 'Line y2 value is required',
  step_size_max: 'Step size should be less than',
};
exports.flash_card_strings = {
  hint_required: 'Please add flash card hint',
  answer_required: 'Please add flash card answer',
  image_required: 'Please upload image',
  flash_card_required: 'Please add atleast one flash card',
};
exports.ASSESSMENT_ANALYSIS = {
  OVERALL_STATISTICS: 'Overall Statistics of',
  DETAILED_PERFORMANCE: 'Detailed Performance Analysis',
  QUESTION: 'Question',
  CLASS: 'Class',
  MARK: 'Mark',
  DISTRICT: 'AVG. SCORE %',
  ALL_CLASSES: 'All Classes (SCORE %)',
};
exports.ASSESSMENT_SUMMARY = {
  OVERALL_STATISTICS: 'Overall Statistics of',
  ANALYSIS_TITLE: 'Question Performance Analysis of',
  TOTAL_ASSIGNED: 'Total Assigned',
  STUDENTS_SUBMITTED: 'Students Submitted & Graded',
  STUDENTS_NOT_SUBMITTED: 'Students Not Submitted & Not Graded',
  AVERAGE_SCORE: 'Average Score',
  AVERAGE_SCORE_PERCENTAGE: 'Average Score Percentage',
  STUDENTS_PERFORMANCE_BANDS: 'Students in Performance Bands (%)',
  EXCELLENT: 'Excellent',
  GOOD: 'Good',
  SATISFACTORY: 'Satisfactory',
  UNSATISFACTORY: 'Unsatisfactory',
  LOW_PASS: 'Low Pass',
  CLASS_NAME: 'Class Name',
  ASSIGNED_ON: 'Assigned on',
  DUE_ON: 'Due on',
  // TOTAL_ASSIGNED: 'Total Assigned',
  TOTAL: 'Total Assigned',
  STUDENTS_NOT_STARTED: 'Students not started, In progress & absent',
  AVERAGE_STUDENTS_SCORE: 'Avg. student score (%)',
  MIN_SCORE: 'Min. Score',
  MAX_SCORE: 'Max. Score',
  SELECT_AN_OPTION: 'Select an option',
  TEST: 'Test',
  AVG_SCORE: 'Avg. Score',
  STD_DEV: 'Std. Dev.(p) of Score',
  VARIANCE: 'Variance (p) of Score',
};
exports.ASSESSMENT_STUDENTS = {
  STUDENTS_PERFORMANCE_BANDS: 'Students in Performance Bands (%)',
  ANALYZE_BY_SCORE: 'Analyze By Score (%)',
  STUDENTS_PERFORMANCE: 'Students Performance',
  EXCELLENT: 'Performance Band - Excellence',
  TEN: 'Ten',
  TWENTY: 'Twenty',
  THIRTY: 'Thirty',
  PERFORMANCE_BAND: 'Performance Band',
  STUDENT: 'Student',
  ASSESSMENT_DATE: 'Assessment Date',
  ASSESSMENT_SCORE: 'Assessment Score',
  CLASS_AVERAGE: 'Class (AVG score %)',
  STUDENT_SCORE: 'Student (Score %)',
  PERFORMANCE_BY_STANDARDS: 'Performance by Standards',
  STANDARD_PERFORMANCE:
    'Standard Performance Analysis by Students for Assessment',
  STUDENTS: 'Students',
  AVERAGE_STUDENT_PERFORMANCE: 'Average Student Performance',
  TEST_NAME_HERE: 'Test Name Here 100%-0%',
  QUESTION: 'Question',
  QUESTION_TYPE: 'Question Type',
  TOTAL_MARKS: 'Total Marks',
  ASSIGNED: 'Assigned',
  SUBMITTED: 'Submitted',
  ABSENT: 'Absent',
  OBTAINED_MARKS: 'Obtained Marks',
  AVERAGE_STUDENT_SCORE: 'Avg. Student (Score%)',
  QUESTION_TYPE_PERFORMANCE: 'Question Type Performance for Assessment',
};
exports.ASSESSMENT_REPORTS = {
  NO_DATA_FOUND: 'No data found',
  SELECT_ASSESSMENT:
    'Please select the details from filters screen for summary report.',
};

// Customizable Area End
