import React from "react";
// Customizable Area Start
import {
    StyleSheet,
    Platform,
} from "react-native";
import { Chart } from "react-google-charts";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import Calendar from 'react-calendar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Header from "../../shared/Header/src/Header";
import SideBar from "../../shared/SideBar/src/SideBar";
import Picker from "emoji-picker-react";
import "./StudentPortfolio.web.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import AlertModal from "../../alert/src/AlertModal.web";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Datetime from "react-datetime";
import DateRangeIcon from '@material-ui/icons/DateRange';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    CircularProgress,
    IconButton,
    Link,
    TextField,
    NativeSelect,
    Typography,
    Menu,
    MenuItem,
    Popover
} from "@material-ui/core";
import Select from 'react-dropdown-select';
import {
    Search,
    Replay,
    NotificationsNone,
    HeadsetMicOutlined,
    Title,
    TitleOutlined,
    KeyboardArrowLeft,
    EditOutlined,
    MoreVertOutlined,
    KeyboardArrowDown,
    ArrowDropDown,
    BorderTop,
} from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import PublicIcon from '@material-ui/icons/Public';
import {
    chat,
    folder,
    goal,
    heart,
    share,
    image,
    school,
    download,
    pdf,
    like,
    filter
} from './assets';
import CommentModal from './CommentModal.web';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import ViewPostController, { Props } from "./ViewPostController.web";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import LockIcon from '@material-ui/icons/Lock';
import ReactPaginate from "react-paginate";
import { array } from "yup";
import StudentAttendanceWeb from "../../ClassesAndSections/src/Attendance/StudentAttendance.web";
import ShareScreen from "./ShareScreen.web";
import FilterScreen from './FilterScreen.web';
import DeleteModal from "./DeleteModal.web";
// Customizable Area End
// Customizable Area Start
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
// Customizable Area End
export default class ViewPost extends ViewPostController {
    modalhandleClose: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    render() {
        const deleteMes1 = {
            fontFamily: "Open sans",
            fontSize: "1.5rem",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#3f526d",
            margin: '31px 32px 6px 33px',

        } as const;

        const deleteDiv = {
            fontFamily: "Open sans",
            fontSize: "1.5rem",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#3f526d",
            marginBottom: '14px',

        } as const;
        const deleteMes2 = {
            fontFamily: "Open sans",
            fontSize: "0.875rem",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#959393",
            margin: '6px 53px 40px 54px',

        } as const;
        const cancelButton = {
            margin: '0 20px 0 0',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',
            backgroundImage: 'linear-gradient(to top, #af38cb 101%, #471c51 33%)',
            color: 'white'

        } as const;
        const deleteButton = {
            margin: '0 0px 0 20px',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',
            backgroundColor: '#f6f6f6',
        } as const;
        const cancelText = {
            fontFamily: "Open sans",
            fontSize: "0.875rem",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center',
            color: "#fff",

        } as const;
        const deleteText = {
            fontFamily: "Open sans",
            fontSize: "0.875rem",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center'

        } as const;
        const BootstrapInput = withStyles((theme) => ({
            root: {
                "label + &": {
                    marginTop: theme.spacing(1),
                },
            },
            input: {
                borderRadius: 8,
                position: "relative",
                border: "solid 1px #ebebeb",
                backgroundColor: "#fff",
                padding: "13px 22px",
                height: 30,
                transition: theme.transitions.create(["border-color", "box-shadow"]),
                // Use the system font instead of the default Roboto font.
                fontFamily: 'Open sans',
                color: "#000",
                fontWeight: 600,
                fontSize: "1.125rem",
                "&:focus": {
                    borderRadius: 4,
                    borderColor: "#80bdff",
                    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
                },
            },
        }))(InputBase);
        const { imageArray, post_description, timeline, fileUploadDetail, bulkFileUploaded, pendingStudentGoals, completedStudentGoals, isgoalCompleted, goal_id, studentGoals, fileArray, inputStr, showPicker } = this.state
        const onEmojiClick = (event: any, emojiObject: any) => {
            this.setState({ inputStr: this.state.inputStr + emojiObject.emoji, showPicker: false })
        };
        console.log(timeline)
        return (
            <Box>
                <Box
                    style={{
                        marginTop: 20,
                        padding: "20px 19px 50px 20px",
                        borderRadius: 8,
                        boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#fff",
                    }}
                >
                    <AlertModal
                        alertModal={this.state.alertModal}
                        isTeacher={false}
                        onCloseModal={() => { this.setState({ alertModal: false }) }}
                        alertTitle="Alert!"
                        alertMessage={this.state.alertMessage}
                        buttonText="OK"
                    />
                    <Box style={{
                        margin: "27px 0px 18px 0px",

                    }}>
                        <Box>
                            <Grid container style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "51px 0px 30px 0px",
                            }}>
                                <Grid item xs={1}>
                                    <Box style={{
                                        backgroundColor: "#fff",
                                        textAlign: "center"
                                    }}>
                                        <img src={timeline.attributes?.added_by.data.attributes.avatar}
                                            style={{
                                                width: 38,
                                                height: 38,
                                                padding: 4,
                                                borderRadius: "50%",
                                                border: "solid 0.5px #3f526d",
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={11} style={{ display: "flex", flexDirection: "row", borderBottom: "solid 0.5px #3f526d" }}>
                                    <Grid item xs={9}>
                                        <Box style={{
                                            backgroundColor: "#fff",
                                            color: "#3f526d",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open sans",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}>
                                            <Typography style={{
                                                fontSize: "1.25rem",
                                                fontFamily: 'Open sans',
                                                color: "#3f526d",
                                                marginBottom: 30,
                                            }}>
                                                {
                                                    timeline.type === 'goals'
                                                        ?
                                                        <>
                                                            Goal added by {timeline.attributes?.added_by.data.attributes.first_name} {timeline.attributes?.added_by.data.attributes.last_name}
                                                        </>
                                                        :
                                                        <Typography style={{
                                                            fontSize: "1.25rem",
                                                            fontFamily: 'Open sans',
                                                            color: "#3f526d",
                                                            textTransform: "capitalize"
                                                        }}>{timeline.attributes?.added_by.data.attributes.first_name} {timeline.attributes?.added_by.data.attributes.last_name} posted on {moment(timeline.attributes?.created_at).format("DD MMM YYYY, HH:hh A")}</Typography>
                                                }
                                            </Typography>
                                            <Grid container>
                                                {timeline.attributes?.file.data.map((file: any, index: number) => (
                                                    file.attributes?.content_type === 'application/pdf'
                                                        ?
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Box style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    marginBottom: '40px'
                                                                }}>
                                                                    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0px 20px 0px 0", }}>
                                                                        <img src={pdf} style={{
                                                                            height: 55,
                                                                        }} />
                                                                        <Box style={{ marginLeft: 18, display: "flex", flexDirection: "column", }}>
                                                                            <Typography style={{
                                                                                fontSize: "1.25rem",
                                                                                color: " #3f526d",
                                                                                fontFamily: "Open sans",
                                                                                fontWeight: 600,
                                                                            }}>
                                                                                {file.attributes?.file_name}
                                                                            </Typography>
                                                                            <Typography style={{
                                                                                fontSize: "1rem",
                                                                                color: " #3f526d",
                                                                                fontFamily: "Open sans",
                                                                                fontWeight: 600,
                                                                                marginTop: 11
                                                                            }}>
                                                                                {file.attributes?.file_size > 1048576 ? `${(file.attributes?.file_size / 1048576).toFixed(2)} MB` : `${(file.attributes?.file_size / 1024).toFixed(2)} KB`}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        </>
                                                        :
                                                        index === 2 ?
                                                            <Grid item xs={4} style={{ position: "relative", width: "100%" }}>
                                                                {
                                                                    file.attributes?.content_type === 'video/mp4'
                                                                        ?
                                                                        <video controls style={{ display: "block", width: "95%", height: 200, objectFit: "cover" }} src={file.attributes?.url} >
                                                                            The “video” tag is not supported by your browser. Click [here] to download the video file.
                                                                        </video>
                                                                        :
                                                                        <img src={file.attributes?.url} style={{ display: "block", width: "95%", height: 200, objectFit: "cover" }} />

                                                                }
                                                                <Typography style={{
                                                                    position: "absolute",
                                                                    top: "45%",
                                                                    right: "45%",
                                                                    justifyContent: "center",
                                                                    fontSize: "1.625rem",
                                                                    color: "#fff",
                                                                    fontFamily: "Open sans"
                                                                }}>
                                                                    {timeline.attributes?.file.data.length > 3 && <>+{parseInt(timeline.attributes?.file.data.length) - 3}</>}
                                                                </Typography>
                                                            </Grid>
                                                            :
                                                            (
                                                                index < 3 &&
                                                                <>
                                                                    <Grid item xs={4} style={{ position: "relative", width: "100%" }}>
                                                                        {
                                                                            file.attributes?.content_type === 'video/mp4'
                                                                                ?
                                                                                <video controls style={{ display: "block", width: "95%", height: 200, objectFit: "cover" }} src={file.attributes?.url} >
                                                                                    The “video” tag is not supported by your browser. Click [here] to download the video file.
                                                                                </video>
                                                                                :
                                                                                <img src={file.attributes?.url} style={{ display: "block", width: "95%", height: 200, objectFit: "cover" }} />

                                                                        }
                                                                    </Grid>
                                                                </>
                                                            )

                                                ))}
                                            </Grid>
                                            <Typography style={{
                                                margin: "25px 0px 30px 12px",
                                                fontFamily: "Open sans",
                                                fontSize: "1.125rem",
                                                fontWeight: 600,
                                                color: "#3f526d",
                                                textTransform: "capitalize"
                                            }}>
                                                {timeline.attributes?.description}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box style={{
                                            backgroundColor: "#fff",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "end"
                                        }}>
                                            <Box style={{
                                                margin: "0 10px 0 0px",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                border: "solid 0.15px #471c51",
                                                borderRadius: 6,
                                                backgroundColor: "#f9f8fd",
                                                padding: "8px 8px 10px 10px"
                                            }}
                                            >
                                                <Box>
                                                    <img
                                                        id="2"
                                                        src={share} style={{
                                                            margin: "3px 4px 1px 4px",
                                                            width: 18,
                                                            height: 18,
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={(event: any) => { this.setState({ shareScreenAnchorEl: event?.currentTarget, goal_id: timeline.id }) }}
                                                    />
                                                    {this.state.shareScreenAnchorEl && goal_id === timeline.id && <ShareScreen popoverClose={this.onShareClose} shareScreenAnchorEl={this.state.shareScreenAnchorEl} navigation={undefined} id={timeline.id} type={timeline.type} />}
                                                </Box>
                                            </Box>
                                            <Box style={{
                                                margin: "0 10px 0 0px",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                border: "solid 0.15px #471c51",
                                                borderRadius: 6,
                                                backgroundColor: "#f9f8fd",
                                                padding: "8px 8px 10px 10px"
                                            }}
                                            >

                                                <img src={timeline.attributes?.like_by_me ? like : heart} style={{
                                                    margin: "0px 4px 0px 0",
                                                    width: 18,
                                                    height: 18,
                                                    cursor: "pointer"
                                                }}
                                                    onClick={() => {
                                                        this.post_like(timeline.type, timeline.id, !timeline.attributes?.like_by_me)
                                                    }}
                                                />
                                                <Typography style={{
                                                    color: "#3f526d",
                                                    fontFamily: "Open sans",
                                                    margin: "0 0 0 4px",
                                                    fontSize: "1rem",
                                                }}>
                                                    {timeline.attributes?.likes}
                                                </Typography>
                                            </Box>
                                            <Box style={{
                                                margin: "0 10px 0 0px",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                border: "solid 0.15px #471c51",
                                                borderRadius: 6,
                                                backgroundColor: "#f9f8fd",
                                                padding: "8px 8px 10px 10px"
                                            }}
                                            >
                                                <img
                                                    src={chat}
                                                    style={{
                                                        margin: "3px 4px 1px 0",
                                                        width: 18,
                                                        height: 18,
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => { this.setState({ openComment: !this.state.openComment, goal_id: timeline.id }) }}
                                                />
                                                {this.state.openComment && goal_id === timeline.id && <CommentModal id={timeline.id} type={timeline.type} closeModalFun={this.onClose} openComment={this.state.openComment} />}
                                                <Typography style={{
                                                    color: "#3f526d",
                                                    fontFamily: "Open sans",
                                                    margin: "0 0 0 4px",
                                                    fontSize: "1rem",
                                                }}>
                                                    {timeline.attributes?.comments.data.length}
                                                </Typography>
                                            </Box>
                                            {/* <Box style={{
                                                margin: "0 10px 0 0px",
                                            }}>
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    style={{ padding: 0 }}
                                                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                                                        this.setState({ anchorEl: event.currentTarget, goal_id: timeline.id })
                                                    }}
                                                >
                                                    <MoreVertOutlined
                                                        style={{
                                                            height: 24,
                                                            width: 24,
                                                            opacity: 0.24,
                                                            color: "#3f526d"
                                                        }}

                                                    />
                                                </IconButton> */}
                                            {/* <Menu
                                                    id="basic-menu"
                                                    anchorEl={this.state.anchorEl}
                                                    open={Boolean(this.state.anchorEl)}
                                                    onClose={() => { this.setState({ anchorEl: '' }) }}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                >
                                                    <Box style={{ width: 203 }}>
                                                        <MenuItem
                                                            onClick={() => {
                                                                this.goToEditGoals()
                                                                console.clear(
                                                                )
                                                            }}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} style={{ flexBasis: 0 }} >
                                                                    <Typography
                                                                        style={{
                                                                            textTransform: "none",
                                                                            fontSize: "16px",
                                                                            fontFamily: "Open Sans",
                                                                            fontWeight: 600,
                                                                            color: "#3f526d"
                                                                        }}

                                                                    >
                                                                        Edit
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </MenuItem>
                                                        <MenuItem onClick={() => { this.setState({ deleteGoal: !this.state.deleteGoal }) }}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} style={{ flexBasis: 0 }}>
                                                                    <Typography
                                                                        style={{
                                                                            textTransform: "none",
                                                                            fontSize: "16px",
                                                                            fontFamily: "Open Sans",
                                                                            fontWeight: 600,
                                                                            color: "#3f526d"
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </MenuItem>
                                                        {/* {this.state.deleteGoal && goal_id === timeline.id &&
                                                        <>
                                                            <DeleteModal deleteModal={this.state.deleteGoal} type={timeline.type} id={timeline.id} closeModalFun={this.onClose} navigation={undefined} />
                                                        </>
                                                    } */}
                                            {/* </Box> */}
                                            {/* </Menu> */}
                                            {/* </Box> */}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }
    // Customizable Area End
}


// Customizable Area End
