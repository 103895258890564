// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: any;
  otperror: boolean;
  otperrorText: string;
  loadingSpinner: boolean;
  email: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountVerifyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailVerifyApiCallId: any;
  resendOtpApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      otperror: false,
      otperrorText: "",
      otp: null,
      loadingSpinner: false,
      email: "",
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.emailVerifyApiCallId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            toast.success(responseJson.meta.message)
            const activated = responseJson.data && responseJson.data.attributes && responseJson.data.attributes.activated
            const email_activated = responseJson.data && responseJson.data.attributes && responseJson.data.attributes.email_activated
            if (activated && email_activated) {
              this.props.navigation.navigate("AccountSucessFull");
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false });
      } else if (apiRequestCallId === this.resendOtpApiCallId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            toast.success(responseJson.meta.message)
            localStorage.setItem("token", responseJson.meta.token);
            sessionStorage.setItem("otp-token", responseJson.meta.token);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ loadingSpinner: false });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  resendOtpClick = () => {
    this.setState({ loadingSpinner: true });
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: sessionStorage.getItem("otp-token"),
    };
    const data = {};

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ResendEmailVerificationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onAccountEmailVerify() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: sessionStorage.getItem("otp-token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emailVerifyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherEmailVerficationEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onBackArrowAccountEmail = () => {
    this.props.navigation.navigate("AccountPhoneVerify");
  };
  // Customizable Area End
}
