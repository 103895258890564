// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import "../StudentGradeBook.web.css";
import {
  Box,
  withStyles,
  Typography,
  Grid,
  NativeSelect,
  Tabs,
  Tab
} from "@material-ui/core";
import GradeBookController, {
  Props,
} from "./GradeBookController.web";
import Assessment from "./Tabs/Assessment.web";
import Task from "./Tabs/Task.web";
import SelectedTab from '../../SelectedTab.web';
import './GradeBook.web.css';
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

export class GradeBook extends GradeBookController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    return (
      <>
        <Box className="GradeBookMT20">
          <SelectedTab isTeacher={false} type={'Gradebook'} />
        </Box>
        <div className="gradeBookContent GradeBookP20">
          <Grid container spacing={2} className='GradeBookTabMainDiv'>
            <Box className="tabingSection GradeBookLP20">
              <Tabs
                value={this.state.tabValue}
                onChange={(event, value) =>
                  this.handleTansChange.onChangeTab(value)
                }
                TabIndicatorProps={{
                  style: {
                    background: "#af38cb",
                  },
                }}
                className='GradeBookW100'
                variant="scrollable"
              >
                <Tab
                  className={`teacher_tab ${this.props.classes.subHeading_font}`}
                  label="Assessment"
                  id={`simple-tab-${this.state.tabValue}`}
                />
                <Tab
                  className={`teacher_tab ${this.props.classes.subHeading_font}`}
                  label="Task"
                  id={`simple-tab-${this.state.tabValue}`}
                />
              </Tabs>
            </Box>
            <Box
              className='GradeBookW100'
            >
              {this.state.tabValue === 0 && (
                <>
                  {
                    this.state.selectedClassId !== '' &&
                    <Assessment
                    />
                  }
                </>
              )}
            </Box>
            <Box
              className='GradeBookW100'>
              {this.state.tabValue === 1 && (
                <>{
                  this.state.selectedClassId !== '' &&
                  <Task
                  />
                }
                </>
              )}
            </Box>
          </Grid>
        </div>
      </>
    );
  }
  // Customizable Area End
}


// Customizable Area Start
export default withStyles(themeCreateStyle)(GradeBook);
// Customizable Area End