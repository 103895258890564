import React from "react";
// Customizable Area Start
import {
    StyleSheet,
    Platform,
} from "react-native";
import { Chart } from "react-google-charts";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import Calendar from 'react-calendar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Header from "../../shared/Header/src/Header";
import SideBar from "../../shared/SideBar/src/SideBar";
import Picker from "emoji-picker-react";
import "./StudentPortfolio.web.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import AlertModal from "../../alert/src/AlertModal.web";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import validator from 'validator';
import Datetime from "react-datetime";
import DateRangeIcon from '@material-ui/icons/DateRange';
import Pagination from '@material-ui/lab/Pagination';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Select, { StylesConfig } from 'react-select';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    CircularProgress,
    IconButton,
    Link,
    TextField,
    NativeSelect,
    Typography,
    Menu,
    MenuItem,
    Popover
} from "@material-ui/core";
import {
    Search,
    Replay,
    NotificationsNone,
    HeadsetMicOutlined,
    Title,
    TitleOutlined,
    KeyboardArrowLeft,
    EditOutlined,
    MoreVertOutlined,
    KeyboardArrowDown,
    ArrowDropDown,
    BorderTop,
} from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import PublicIcon from '@material-ui/icons/Public';
import {
    chat,
    folder,
    goal,
    heart,
    share,
    image,
    school,
    download,
    pdf,
    like,
    filter,
    doc,
    xls
} from './assets';
import CommentModal from './CommentModal.web';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import StudentProtfolioController, { Props } from "./StudentProtfolioController.web";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import LockIcon from '@material-ui/icons/Lock';
import ReactPaginate from "react-paginate";
import { array } from "yup";
import StudentAttendanceWeb from "../../ClassesAndSections/src/Attendance/StudentAttendance.web";
import ShareScreen from "./ShareScreen.web";
import FilterScreen from './FilterScreen.web';
import DeleteModal from "./DeleteModal.web";
import AddMemberController from "../../ClassesAndSections/src/Members/AddMemberController.web";
import HOCAuth from "../../shared/Header/src/HOCAuth.web";
// Customizable Area End

// Customizable Area Start
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
// Customizable Area End

// Customizable Area Start
class StudentPortfolio extends StudentProtfolioController {
    modalhandleClose: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const deleteMes1 = {
            fontFamily: "Open sans",
            fontSize: "1.5rem",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#3f526d",
            margin: '31px 32px 6px 33px',

        } as const;

        const deleteDiv = {
            fontFamily: "Open sans",
            fontSize: "1.5rem",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#3f526d",
            marginBottom: '14px',

        } as const;
        const deleteMes2 = {
            fontFamily: "Open sans",
            fontSize: "0.875rem",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#959393",
            margin: '6px 53px 40px 54px',

        } as const;
        const cancelButton = {
            margin: '0 20px 0 0',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',
            backgroundImage: 'linear-gradient(to top, #af38cb 101%, #471c51 33%)',
            color: 'white'

        } as const;
        const deleteButton = {
            margin: '0 0px 0 20px',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',
            backgroundColor: '#f6f6f6',
        } as const;
        const cancelText = {
            fontFamily: "Open sans",
            fontSize: "0.875rem",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center',
            color: "#fff",

        } as const;
        const deleteText = {
            fontFamily: "Open sans",
            fontSize: "0.875rem",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center'

        } as const;
        const BootstrapInput = withStyles((theme) => ({
            root: {
                "label + &": {
                    marginTop: theme.spacing(1),
                },
            },
            input: {
                borderRadius: 8,
                position: "relative",
                border: "solid 1px #ebebeb",
                backgroundColor: "#fff",
                padding: "13px 22px",
                height: 30,
                transition: theme.transitions.create(["border-color", "box-shadow"]),
                // Use the system font instead of the default Roboto font.
                fontFamily: 'Open sans',
                color: "#000",
                fontWeight: 600,
                fontSize: "1.125rem",
                "&:focus": {
                    borderRadius: 4,
                    borderColor: "#80bdff",
                    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
                },
            },
        }))(InputBase);
        const { imageArray, post_description, userData, dropDownValue, fileUploadDetail, searchPost, timelineData, bulkFileUploaded, pendingStudentGoals, edit_type, completedStudentGoals, isgoalCompleted, goal_id, studentGoals, fileArray, inputStr, showPicker } = this.state
        const onEmojiClick = (event: any, emojiObject: any) => {
            this.setState({ inputStr: this.state.inputStr + emojiObject.emoji, showPicker: false })
        };

        const colourStyles = {
            control: (styles: any) => ({
                ...styles,
                cursor: 'pointer',
                border: "solid 0.3px #471c51"
            }),
            option: (styles: any) => {
                return {
                    ...styles,
                    backgroundColor: 'white',
                    color: '#FFF',
                    cursor: 'pointer',
                    ':hover': {
                        ...styles[':hover'],
                        backgroundColor: '#f9f8fd',
                        color: "#3f526d",
                    }
                }
            }
        }

        const options = [
            {
                value: 'public_post', label:
                    <Box style={{
                        padding: "12px 0px 12px 0px",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row"
                    }}>
                        <PublicIcon style={{ marginRight: 15, color: "black" }} />
                        <Box>
                            <Typography style={{
                                fontSize: "1rem",
                                fontWeight: 600,
                                color: "#3f526d",
                                marginBottom: 2,
                                fontFamily: "Open sans"
                            }}>Public</Typography>
                        </Box>
                    </Box>
            },
            {
                value: 'private_post', label:
                    <Box style={{
                        padding: "12px 0px 12px 0px",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row"
                    }}>
                        <LockIcon style={{ marginRight: 15, color: "black" }} />
                        <Box>
                            <Typography style={{
                                fontSize: "1rem",
                                fontWeight: 600,
                                color: "#3f526d",
                                marginBottom: 2
                            }}>
                                Private
                            </Typography>
                        </Box>
                    </Box>
            },
        ];
        return (


            <Box>
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Box>
                        <Typography
                            style={{
                                margin: "21px 0px 41px 0",
                                fontSize: "1.25rem",
                                color: "#3f526d",
                                fontWeight: 600,
                            }}
                        >
                            Portfolio
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            margin: "19px 0 20px",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: "1.125rem",
                                fontWeight: 600,
                                padding: "13px 20px",
                                color: "#fff",
                                borderRadius: "8px 0px 0px 8px",

                                cursor: 'pointer'
                            }}
                            className={this.props.classes.button_color}
                            onClick={() => this.goToTimeline()}

                        >
                            Goals & Timeline
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "1.125rem",
                                color: "#3f526d",
                                fontWeight: 600,
                                padding: "13px 20px",
                                backgroundColor: "#fff",
                                borderRadius: "0px 8px 8px 0px",
                                cursor: "pointer"
                            }}
                            className={this.props.classes.border_color}
                            onClick={() => this.goToFiles()}
                        >
                            Files
                        </Typography>
                    </Box>
                </Box>
                <Box
                    style={{
                        marginTop: 20,
                        padding: "20px 19px 50px 20px",
                        borderRadius: 8,
                        boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#fff",
                    }}
                >
                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                        <Box
                            style={{
                                padding: "0px 10px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                border: "solid 0.5px #471c51",
                                // padding: "0px 10px 0px 0px",
                                margin: "0 29px 20px 0px",
                                borderRadius: 8,
                                alignItems: "center",
                                cursor: 'pointer',
                                height: 55,
                            }}>
                            <img src={folder} style={{ width: 36, height: 36, margin: "0 10px 0 0" }} />
                            <Typography
                                style={{
                                    fontSize: "1.25rem",
                                    color: "#3f526d",
                                    fontWeight: 600,
                                    backgroundColor: "#fff",
                                    borderRadius: "0px 8px 8px 0px",
                                    cursor: 'pointer'
                                }}
                                onClick={() => this.goToResource()}
                            >
                                Add Resource
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                padding: "0px 10px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                border: "solid 0.5px #471c51",
                                borderRadius: 8,
                                alignItems: "center",
                                cursor: 'pointer',
                                height: 55,
                            }}
                            onClick={() => this.goToGoals()}
                        >
                            <img src={goal} style={{ width: 36, height: 36, margin: "0 10px 0 0" }} />
                            <Typography
                                style={{
                                    fontSize: "1.25rem",
                                    color: "#3f526d",
                                    fontWeight: 600,
                                    backgroundColor: "#fff",
                                    padding: "0px 10px 0px 10px",
                                    borderRadius: "0px 8px 8px 0px",
                                    cursor: 'pointer'
                                }}
                                onClick={() => this.goToGoals()}
                            >
                                New Goal
                            </Typography>
                        </Box>
                    </Box>
                    <Box style={{
                        margin: "0px 0px 17px 0px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Typography
                            style={{
                                fontSize: "1.25rem",
                                fontWeight: 600,
                                backgroundColor: "#fff",
                            }}
                            className={this.props.classes.title_font}
                        >
                            Goals
                        </Typography>

                        <Button
                            disableRipple={true}
                            variant="contained"
                            style={{
                                textTransform: "capitalize",
                                fontSize: "1.25rem",
                                fontWeight: 600,
                                padding: "5px 15px 5px",
                                borderRadius: 6,
                                boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.1)",
                                backgroundColor: "#fff",
                                color: "#3f526d"
                            }}
                            id="1"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => { this.setState({ filterAnchorEl: event.currentTarget }) }}
                        >
                            <img src={filter} style={{
                                width: 26,
                                height: 26,
                                margin: "0 14px 0px 0"
                            }} />
                            Filter
                        </Button>
                        {this.state.filterAnchorEl && <FilterScreen popoverClose={this.onFilterClose} applyFilter={this.get_filter_student_goals} start_date={this.state.start_date} end_date={this.state.end_date} filterAnchorEl={this.state.filterAnchorEl} navigation={undefined} />}
                    </Box>

                    {pendingStudentGoals.map((goal: any, index: number) => (
                        index < 5 && (
                            <Box style={{
                                padding: "10px 15px 10px 20px",
                                borderRadius: 6,
                                border: "0.15px solid #471c51",
                                backgroundColor: "#f9f8fd",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0px 20px 0px 0", }}>
                                    <Checkbox
                                        style={{
                                            color: "#3f526d",
                                            backgroundColor: 'transparent',
                                            opacity: goal_id === goal.id && isgoalCompleted && goal.attributes.is_completed ? 1 : 0.08,
                                        }}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        onClick={event => {
                                            this.completed_goals(true, goal.id)
                                            this.setState({ goal_id: goal.id, isgoalCompleted: !this.state.isgoalCompleted })
                                        }
                                        }
                                        checked={goal_id === goal.id && goal.attributes.is_completed && this.state.isgoalCompleted}
                                    />
                                    <Typography style={{
                                        fontSize: "1rem",
                                        color: "#202020",
                                        fontFamily: "Open sans",
                                        margin: "0px 20px",
                                        textTransform: "capitalize"
                                    }}>
                                        {goal.attributes.title}
                                    </Typography>
                                    <Typography style={{
                                        fontSize: "0.75rem",
                                        fontFamily: "Open sans",
                                        fontWeight: 600,
                                        borderRadius: 4,
                                        padding: "5px 9px 4px",
                                        color: "#ffffff"
                                    }}
                                        className={this.props.classes.background_color}
                                    >
                                        Due Date: {moment(goal.attributes.end_date).format("MMMM DD,YYYY")}
                                    </Typography>
                                </Box>

                                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    {/* <Box style={{
                                    margin: "0 10px 0 0px",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    border: "solid 0.15px #471c51",
                                    borderRadius: 6,
                                    backgroundColor: "#f9f8fd",
                                    padding: "8px 8px 10px 10px"
                                }}
                                >
                                    <Box>
                                        <img src={share} style={{
                                            margin: "3px 4px 1px 4px",
                                            width: 18,
                                            height: 18,
                                        }} />
                                    </Box>
                                </Box> */}
                                    <Box style={{
                                        margin: "0 10px 0 0px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        border: "solid 0.15px #471c51",
                                        borderRadius: 6,
                                        backgroundColor: "#f9f8fd",
                                        padding: "8px 8px 10px 10px"
                                    }}
                                    >
                                        <Box>
                                            <img
                                                src={goal.attributes.like_by_me ? like : heart}
                                                style={{
                                                    margin: "3px 4px 1px 0",
                                                    width: 18,
                                                    height: 18,
                                                    cursor: "pointer"
                                                }} />
                                        </Box>
                                        <Typography style={{
                                            color: "#3f526d",
                                            fontFamily: "Open sans",
                                            margin: "0 0 0 4px",
                                            fontSize: "1rem",
                                        }}>
                                            {goal.attributes.likes}
                                        </Typography>
                                    </Box>
                                    <Box style={{
                                        margin: "0 10px 0 0px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        border: "solid 0.15px #471c51",
                                        borderRadius: 6,
                                        backgroundColor: "#f9f8fd",
                                        padding: "8px 8px 10px 10px"
                                    }}
                                    >
                                        <Box>
                                            <img src={chat}
                                                style={{
                                                    margin: "3px 4px 1px 0",
                                                    width: 18,
                                                    height: 18,
                                                    cursor: "pointer"
                                                }} />

                                        </Box>

                                        <Typography style={{
                                            color: "#3f526d",
                                            fontFamily: "Open sans",
                                            margin: "0 0 0 4px",
                                            fontSize: "1rem",
                                        }}>
                                            {goal.attributes.comments.data.length}
                                        </Typography>
                                    </Box>
                                    <Box style={{
                                        margin: "0 10px 0 0px",
                                    }}>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            style={{ padding: 0 }}
                                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                                this.setState({ anchorEl: event.currentTarget, goal_id: goal.id })
                                            }}
                                        >
                                            <MoreVertOutlined
                                                style={{
                                                    height: 24,
                                                    width: 24,
                                                    opacity: 0.24,
                                                    color: "#3f526d"
                                                }}

                                            />
                                        </IconButton>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={this.state.anchorEl}
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={() => { this.setState({ anchorEl: '' }) }}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <Box style={{ width: 203 }}>
                                                <MenuItem onClick={() => {
                                                    this.goToEditGoals()
                                                }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} style={{ flexBasis: 0 }} >
                                                            <Typography
                                                                style={{
                                                                    textTransform: "none",
                                                                    fontSize: "1rem",
                                                                    fontFamily: "Open Sans",
                                                                    fontWeight: 600,
                                                                    color: "#3f526d"
                                                                }}

                                                            >
                                                                Edit
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </MenuItem>
                                                <MenuItem onClick={() => { this.setState({ deleteGoal: !this.state.deleteGoal }) }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} style={{ flexBasis: 0 }}>
                                                            <Typography
                                                                style={{
                                                                    textTransform: "none",
                                                                    fontSize: "1rem",
                                                                    fontFamily: "Open Sans",
                                                                    fontWeight: 600,
                                                                    color: "#3f526d"
                                                                }}
                                                            >
                                                                Delete
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </MenuItem>
                                                {this.state.deleteGoal && goal_id === goal.id &&
                                                    <>
                                                        <DeleteModal deleteModal={this.state.deleteGoal} id={goal.id} closeModalFun={this.onClose} navigation={undefined} type={goal.type} />
                                                    </>
                                                }
                                            </Box>
                                        </Menu>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    )
                    )}
                    <AlertModal
                        alertModal={this.state.alertModal}
                        isTeacher={false}
                        onCloseModal={() => { this.setState({ alertModal: false }) }}
                        alertTitle="Alert!"
                        alertMessage={this.state.alertMessage}
                        buttonText="OK"
                    />
                    <Box style={{
                        padding: "13px 15px 10px 20px",
                        borderRadius: 6,
                        border: "0.15px solid #471c51",
                        backgroundColor: "#f9f8fd",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <Typography style={{
                            fontSize: "0.875rem",
                            color: "#af38cb",
                            fontFamily: "Open sans",

                        }}>
                            <Link style={{ cursor: "pointer" }} onClick={() => { this.setState({ showCompletedGoals: !this.state.showCompletedGoals }) }}>
                                {this.state.showCompletedGoals ? <>Hide</> : <>Show</>} Completed Goals
                            </Link>
                        </Typography>
                    </Box>
                    {this.state.showCompletedGoals &&
                        <>
                            {completedStudentGoals.map((goal: any, index: number) => (
                                index < 5 && (
                                    <Box style={{
                                        padding: "10px 15px 10px 20px",
                                        borderRadius: 6,
                                        border: "0.15px solid #471c51",
                                        backgroundColor: "#f9f8fd",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}>
                                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0px 20px 0px 0", }}>
                                            <Checkbox
                                                style={{
                                                    color: "#3f526d",
                                                    opacity: 1,
                                                    backgroundColor: 'transparent',
                                                }}
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                onClick={event => {
                                                    this.completed_goals(false, goal.id)
                                                    this.setState({ goal_id: goal.id, isgoalCompleted: !this.state.isgoalCompleted })
                                                }
                                                }
                                                checked={goal.attributes.is_completed}
                                            />
                                            <Typography style={{
                                                fontSize: "1rem",
                                                color: "#202020",
                                                fontFamily: "Open sans",
                                                margin: "0px 20px",
                                            }}>
                                                {goal.attributes.title}
                                            </Typography>
                                            <Typography style={{
                                                fontSize: "0.75rem",
                                                fontFamily: "Open sans",
                                                fontWeight: 600,
                                                borderRadius: 4,
                                                backgroundColor: "green",
                                                padding: "5px 9px 4px",
                                                color: "#ffffff"
                                            }}>
                                                Completed
                                            </Typography>
                                        </Box>

                                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            {/* <Box style={{
                                            margin: "0 10px 0 0px",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            border: "solid 0.15px #471c51",
                                            borderRadius: 6,
                                            backgroundColor: "#f9f8fd",
                                            padding: "8px 8px 10px 10px"
                                        }}
                                        >
                                            <Box>
                                                <img src={share} style={{
                                                    margin: "3px 4px 1px 4px",
                                                    width: 18,
                                                    height: 18,
                                                }} />
                                            </Box>
                                        </Box> */}
                                            <Box style={{
                                                margin: "0 10px 0 0px",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                border: "solid 0.15px #471c51",
                                                borderRadius: 6,
                                                backgroundColor: "#f9f8fd",
                                                padding: "8px 8px 10px 10px"
                                            }}
                                            >
                                                <Box>
                                                    <img
                                                        src={goal.attributes.like_by_me ? like : heart}
                                                        style={{
                                                            margin: "3px 4px 1px 0",
                                                            width: 18,
                                                            height: 18,
                                                            cursor: "pointer",
                                                        }} />
                                                </Box>
                                                <Typography style={{
                                                    color: "#3f526d",
                                                    fontFamily: "Open sans",
                                                    margin: "0 0 0 4px",
                                                    fontSize: "1rem",
                                                }}>
                                                    {goal.attributes.likes}
                                                </Typography>
                                            </Box>
                                            <Box style={{
                                                margin: "0 10px 0 0px",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                border: "solid 0.15px #471c51",
                                                borderRadius: 6,
                                                backgroundColor: "#f9f8fd",
                                                padding: "8px 8px 10px 10px"
                                            }}
                                            >
                                                <Box>
                                                    <img src={chat} style={{
                                                        margin: "3px 4px 1px 0",
                                                        width: 18,
                                                        height: 18,
                                                    }} />
                                                </Box>
                                                <Typography style={{
                                                    color: "#3f526d",
                                                    fontFamily: "Open sans",
                                                    margin: "0 0 0 4px",
                                                    fontSize: "1rem",
                                                }}>
                                                    {goal.attributes.comments.data.length}
                                                </Typography>
                                            </Box>
                                            <Box style={{
                                                margin: "0 10px 0 0px",
                                            }}>
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    style={{ padding: 0 }}
                                                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                                                        this.setState({ anchorEl: event.currentTarget, goal_id: goal.id })
                                                    }}
                                                >
                                                    <MoreVertOutlined
                                                        style={{
                                                            height: 24,
                                                            width: 24,
                                                            opacity: 0.24,
                                                            color: "#3f526d"
                                                        }}

                                                    />
                                                </IconButton>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={this.state.anchorEl}
                                                    open={Boolean(this.state.anchorEl)}
                                                    onClose={() => { this.setState({ anchorEl: '' }) }}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                >
                                                    <Box style={{ width: 203 }}>
                                                        <MenuItem disabled>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} style={{ flexBasis: 0 }}>
                                                                    <Typography
                                                                        style={{
                                                                            textTransform: "none",
                                                                            fontSize: "1rem",
                                                                            fontFamily: "Open Sans",
                                                                            fontWeight: 600,
                                                                            color: "#3f526d"
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </MenuItem>
                                                        <MenuItem onClick={() => { this.setState({ deleteGoal: !this.state.deleteGoal }) }}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} style={{ flexBasis: 0 }}>
                                                                    <Typography
                                                                        style={{
                                                                            textTransform: "none",
                                                                            fontSize: "1rem",
                                                                            fontFamily: "Open Sans",
                                                                            fontWeight: 600,
                                                                            color: "#3f526d"
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </MenuItem>
                                                        {this.state.deleteGoal && goal_id === goal.id &&
                                                            <>
                                                                <DeleteModal deleteModal={this.state.deleteGoal} id={goal.id} closeModalFun={this.onClose} navigation={undefined} type={goal.type} />
                                                            </>
                                                        }
                                                    </Box>
                                                </Menu>
                                            </Box>
                                        </Box>

                                    </Box>
                                )
                            )
                            )}
                        </>
                    }
                    <Box style={{
                        margin: "27px 0px 18px 0px",

                    }}>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>

                            <Typography
                                style={{
                                    width: "25%",
                                    fontSize: "1.25rem",
                                    fontWeight: 600,
                                    backgroundColor: "#fff",
                                }}
                                className={this.props.classes.title_font}
                            >
                                Add Post
                            </Typography>
                            <Box>
                                <Select
                                    onChange={(values) => {
                                        if (values?.value === 'private_post') {
                                            this.setState({ post_type: values?.value, dropDownValue: true })
                                        }
                                        else {
                                            this.setState({ post_type: values?.value, dropDownValue: false })
                                        }
                                    }}
                                    options={[
                                        {
                                            value: 'public_post', label:
                                                <Box style={{
                                                    padding: "12px 0px 12px 0px",
                                                    alignItems: "center",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}>
                                                    <PublicIcon style={{ marginRight: 15, color: "black" }} />
                                                    <Box>
                                                        <Typography style={{
                                                            fontSize: "1rem",
                                                            fontWeight: 600,
                                                            color: "#3f526d",
                                                            marginBottom: 2,
                                                            fontFamily: "Open sans"
                                                        }}>Public</Typography>
                                                        {
                                                            !dropDownValue
                                                            &&
                                                            <Typography
                                                                style={{
                                                                    fontSize: "0.625rem",
                                                                    color: "#3f526d",
                                                                    opacity: 0.7,
                                                                    fontFamily: "Open sans"
                                                                }}
                                                            >Anyone can see</Typography>
                                                        }
                                                    </Box>
                                                </Box>
                                        },
                                        {
                                            value: 'private_post', label:
                                                <Box style={{
                                                    padding: "12px 0px 12px 0px",
                                                    alignItems: "center",
                                                    display: "flex",
                                                    flexDirection: "row"
                                                }}>
                                                    <LockIcon style={{ marginRight: 15, color: "black" }} />
                                                    <Box>
                                                        <Typography style={{
                                                            fontSize: "1rem",
                                                            fontWeight: 600,
                                                            color: "#3f526d",
                                                            marginBottom: 2
                                                        }}>
                                                            Private
                                                        </Typography>
                                                        {
                                                            dropDownValue
                                                            &&
                                                            <Typography
                                                                style={{
                                                                    fontSize: "0.625rem",
                                                                    color: "#3f526d",
                                                                    opacity: 0.7
                                                                }}
                                                            >
                                                                Teacher can see
                                                            </Typography>
                                                        }
                                                    </Box>
                                                </Box>
                                        },
                                    ]}
                                    defaultValue={options[0]}
                                    isSearchable={false}
                                    styles={colourStyles}
                                // color={'#f9f8fd'}
                                // style={{
                                //     fontSize: "1rem",
                                //     fontWeight: 600,
                                //     color: "#3f526d",
                                //     fontFamily: "Open sans"
                                // }}
                                />
                            </Box>
                        </Box>
                        <Box
                            style={{
                                margin: "18px 1px 29px 0px",
                                padding: "30px 32px 15px 30px",
                                borderRadius: 6,
                                border: "solid 0.5px #471c51",
                                position: "relative",
                            }}>
                            <Grid container style={{ marginBottom: 30, alignItems: "center" }}>
                                <Grid item xs={1} >
                                    <Avatar src={this.state.data.avatar} style={{ width: 55, height: 55 }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField
                                        id="standard-basic"
                                        type="text"
                                        style={{
                                            width: "100%",
                                            backgroundColor: "#fff",
                                            margin: "0px 35px 0px 1px",
                                        }}
                                        multiline
                                        placeholder="Write something here..."
                                        inputProps={{
                                            style: {
                                                fontFamily: "Open sans",
                                                fontSize: "0.9875rem",
                                                color: "#03014c",
                                                opacity: 0.5,
                                                position: "relative"
                                            },
                                        }}
                                        value={this.state.post_description}
                                        InputProps={{ disableUnderline: true }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = event.target.value;
                                            if (event.target.value.search("@") != -1) {
                                                const value = event.target.value.substring(event.target.value.indexOf('@') + 1)
                                                this.get_user_search(value)
                                                this.setState({ userAnchorEl: !this.state.userAnchorEl })
                                            }
                                            this.setState({ post_description: value })

                                        }}
                                    />
                                    {this.state.userAnchorEl &&
                                        <Box style={{
                                            padding: "15px 32px 15px 30px",
                                            borderRadius: 6,
                                            border: "solid 0.5px #471c51",
                                            width: "25%",
                                            backgroundColor: "lighblue",
                                            position: "absolute",
                                        }}>
                                            {userData.map((data: any) => (
                                                <Box style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    marginBottom: 15,
                                                    justifyContent: "space-between",
                                                    borderBottom: "1px solid #471c51",
                                                }}>
                                                    <Avatar src={data.attributes.avatar ? data.attributes.avatar : "/"} style={{ width: "30px", height: "30px", marginRight: "20px", marginBottom: 10 }} />
                                                    <Typography
                                                        style={{
                                                            textTransform: "capitalize",
                                                            fontSize: "1.125rem",
                                                            fontFamily: "Open Sans",
                                                            fontWeight: 600,
                                                            color: "#3f526d",
                                                            marginBottom: 10
                                                        }}
                                                    >
                                                        {data.attributes.first_name} {data.attributes.last_name}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    }

                                </Grid>
                            </Grid>
                            <Grid container>
                                {imageArray && imageArray.map((arrImage: any, index: any) =>

                                (
                                    <>
                                        {
                                            this.state.prePostPreview
                                                ?
                                                <>
                                                    <Grid item xs={4} style={{ position: "relative", width: "100%" }}>
                                                        {
                                                            arrImage.type === 'video/mp4'
                                                                ?
                                                                <Box style={{ position: "relative", marginBottom: 30 }}>
                                                                    <video controls style={{ display: "block", width: "95%", height: 200, objectFit: "cover" }} src={arrImage.src} />
                                                                    <Box
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: 0,
                                                                            bottom: 0,
                                                                            left: 0,
                                                                            right: 0,
                                                                            height: "100%",
                                                                            width: "95%",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                        }}>
                                                                        <CloseIcon style={{
                                                                            position: "absolute",
                                                                            top: "5%",
                                                                            right: "10%",
                                                                            fontSize: "1.875rem",
                                                                            backgroundColor: "transparant",
                                                                            color: "#fff",
                                                                            cursor: "pointer",
                                                                        }}
                                                                            onClick={() => {
                                                                                const index = imageArray.indexOf(arrImage);

                                                                                if (index > -1) {
                                                                                    imageArray.splice(index, 1);
                                                                                    bulkFileUploaded.splice(index, 1);
                                                                                    if (imageArray.length === 3) {
                                                                                        this.setState({ prePostPreview: false })
                                                                                    }
                                                                                }
                                                                                this.setState({ imageArray: imageArray, bulkFileUploaded: bulkFileUploaded })
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                                :
                                                                <Box style={{ position: "relative" }}>
                                                                    <img src={arrImage.src} style={{ display: "block", width: "95%", height: 200, objectFit: "cover", marginBottom: 30 }} />
                                                                    <Box style={{
                                                                        position: "absolute",
                                                                        top: 0,
                                                                        bottom: 0,
                                                                        left: 0,
                                                                        right: 0,
                                                                        height: "100%",
                                                                        width: "95%",
                                                                        backgroundColor: "rgba(236, 236, 236,0.5)",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                    }}>
                                                                        <CloseIcon style={{
                                                                            position: "absolute",
                                                                            top: "5%",
                                                                            right: "10%",
                                                                            fontSize: "1.875rem",
                                                                            backgroundColor: "transparant",
                                                                            color: "#fff",
                                                                            cursor: "pointer",
                                                                        }}
                                                                            onClick={() => {
                                                                                const index = imageArray.indexOf(arrImage);

                                                                                if (index > -1) {
                                                                                    imageArray.splice(index, 1);
                                                                                    bulkFileUploaded.splice(index, 1);
                                                                                    if (imageArray.length === 3) {
                                                                                        this.setState({ prePostPreview: false })
                                                                                    }
                                                                                }
                                                                                this.setState({ imageArray: imageArray, bulkFileUploaded: bulkFileUploaded })
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                        }
                                                    </Grid>
                                                </>
                                                :
                                                <>
                                                    {
                                                        index === 2 ?
                                                            <Grid
                                                                item xs={4}
                                                                style={{ position: "relative", width: "100%", cursor: "pointer" }}

                                                            >
                                                                {
                                                                    arrImage.type === 'video/mp4'
                                                                        ?
                                                                        <Box style={{ position: "relative", marginBottom: 30 }}>
                                                                            <video controls style={{ display: "block", width: "95%", height: 200, objectFit: "cover" }} src={arrImage.src} />
                                                                            <Box
                                                                                style={{
                                                                                    position: "absolute",
                                                                                    top: 0,
                                                                                    bottom: 0,
                                                                                    left: 0,
                                                                                    right: 0,
                                                                                    height: "100%",
                                                                                    width: "95%",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                }}>
                                                                                <CloseIcon style={{
                                                                                    position: "absolute",
                                                                                    top: "5%",
                                                                                    right: "10%",
                                                                                    fontSize: "1.875rem",
                                                                                    backgroundColor: "transparant",
                                                                                    color: "#fff",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                    onClick={() => {
                                                                                        const index = imageArray.indexOf(arrImage);

                                                                                        if (index > -1) {
                                                                                            imageArray.splice(index, 1);
                                                                                            bulkFileUploaded.splice(index, 1);
                                                                                            if (imageArray.length === 3) {
                                                                                                this.setState({ prePostPreview: false })
                                                                                            }
                                                                                        }
                                                                                        this.setState({ imageArray: imageArray, bulkFileUploaded: bulkFileUploaded })
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        :
                                                                        <Box style={{ position: "relative" }}>
                                                                            <img src={arrImage.src} style={{ display: "block", width: "95%", height: 200, objectFit: "cover", marginBottom: 30 }} />
                                                                            <Box style={{
                                                                                position: "absolute",
                                                                                top: 0,
                                                                                bottom: 0,
                                                                                left: 0,
                                                                                right: 0,
                                                                                height: "100%",
                                                                                width: "95%",
                                                                                backgroundColor: "rgba(236, 236, 236,0.5)",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                            }}>
                                                                                <CloseIcon style={{
                                                                                    position: "absolute",
                                                                                    top: "5%",
                                                                                    right: "10%",
                                                                                    fontSize: "1.875rem",
                                                                                    backgroundColor: "transparant",
                                                                                    color: "#fff",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                    onClick={() => {
                                                                                        const index = imageArray.indexOf(arrImage);
                                                                                        if (index > -1) {
                                                                                            imageArray.splice(index, 1);
                                                                                            bulkFileUploaded.splice(index, 1);
                                                                                            if (imageArray.length === 3) {
                                                                                                this.setState({ prePostPreview: false })
                                                                                            }
                                                                                        }
                                                                                        this.setState({ imageArray: imageArray, bulkFileUploaded: bulkFileUploaded })
                                                                                    }}
                                                                                />
                                                                                <Typography style={{
                                                                                    position: "absolute",
                                                                                    top: "45%",
                                                                                    right: "45%",
                                                                                    justifyContent: "center",
                                                                                    fontSize: "1.625rem",
                                                                                    color: "#fff",
                                                                                    fontFamily: "Open sans",
                                                                                    cursor: "pointer"
                                                                                }}
                                                                                    onClick={() => {
                                                                                        this.setState({ prePostPreview: !this.state.prePostPreview })
                                                                                    }}
                                                                                >
                                                                                    {imageArray.length > 3 && <>+{parseInt(imageArray.length) - 3}</>}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                }

                                                            </Grid>
                                                            :
                                                            (
                                                                index < 3 &&
                                                                <>
                                                                    <Grid item xs={4} style={{ position: "relative", width: "100%" }}>
                                                                        {
                                                                            arrImage.type === 'video/mp4'
                                                                                ?
                                                                                <Box style={{ position: "relative", marginBottom: 30 }}>
                                                                                    <video controls style={{ display: "block", width: "95%", height: 200, objectFit: "cover" }} src={arrImage.src} />
                                                                                    <Box
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: 0,
                                                                                            bottom: 0,
                                                                                            left: 0,
                                                                                            right: 0,
                                                                                            height: "100%",
                                                                                            width: "95%",
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            alignItems: "center",
                                                                                        }}>
                                                                                        <CloseIcon style={{
                                                                                            position: "absolute",
                                                                                            top: "5%",
                                                                                            right: "10%",
                                                                                            fontSize: "1.875rem",
                                                                                            backgroundColor: "transparant",
                                                                                            color: "#fff",
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                            onClick={() => {
                                                                                                const index = imageArray.indexOf(arrImage);

                                                                                                if (index > -1) {
                                                                                                    imageArray.splice(index, 1);
                                                                                                    bulkFileUploaded.splice(index, 1);
                                                                                                    if (imageArray.length === 3) {
                                                                                                        this.setState({ prePostPreview: false })
                                                                                                    }
                                                                                                }
                                                                                                this.setState({ imageArray: imageArray, bulkFileUploaded: bulkFileUploaded })
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                </Box>
                                                                                :
                                                                                <Box style={{ position: "relative" }}>
                                                                                    <img src={arrImage.src} style={{ display: "block", width: "95%", height: 200, objectFit: "cover", marginBottom: 30 }} />
                                                                                    <Box style={{
                                                                                        position: "absolute",
                                                                                        top: 0,
                                                                                        bottom: 0,
                                                                                        left: 0,
                                                                                        right: 0,
                                                                                        height: "100%",
                                                                                        width: "95%",
                                                                                        backgroundColor: "rgba(236, 236, 236,0.5)",
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                    }}>
                                                                                        <CloseIcon style={{
                                                                                            position: "absolute",
                                                                                            top: "5%",
                                                                                            right: "10%",
                                                                                            fontSize: "1.875rem",
                                                                                            backgroundColor: "transparant",
                                                                                            color: "#fff",
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                            onClick={() => {
                                                                                                const index = imageArray.indexOf(arrImage);
                                                                                                if (index > -1) {
                                                                                                    imageArray.splice(index, 1);
                                                                                                    bulkFileUploaded.splice(index, 1);
                                                                                                    if (imageArray.length === 3) {
                                                                                                        this.setState({ prePostPreview: false })
                                                                                                    }
                                                                                                }
                                                                                                this.setState({ imageArray: imageArray, bulkFileUploaded: bulkFileUploaded })
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                </Box>

                                                                        }
                                                                    </Grid>
                                                                </>

                                                            )
                                                    }
                                                </>
                                        }

                                    </>
                                )

                                )}

                            </Grid>
                            {
                                this.state.prePostPreview
                                &&
                                <>
                                    <Typography style={{
                                        fontSize: "0.875rem",
                                        color: "#af38cb",
                                        fontFamily: "Open sans",
                                        cursor: "pointer",
                                        marginBottom: 10
                                    }}
                                        onClick={() => { this.setState({ prePostPreview: !this.state.prePostPreview }) }}
                                    >
                                        Show less
                                    </Typography>
                                </>
                            }
                            <Grid container>
                                {fileArray && fileArray.map((arrImage: any, index: number) => (
                                    <Grid item xs={12}>
                                        <Box style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginTop: 30,
                                            marginBottom: '30px'
                                        }}>
                                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0px 20px 0px 0", }}>
                                                <img src={arrImage.type === "application/pdf" ? pdf : arrImage.type === "application/msword" || arrImage.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? doc : xls} style={{
                                                    height: 55,
                                                }} />
                                                <Box style={{ marginLeft: 18, display: "flex", flexDirection: "column", }}>
                                                    <Typography style={{
                                                        fontSize: "1.25rem",
                                                        color: " #3f526d",
                                                        fontFamily: "Open sans",
                                                        fontWeight: 600,
                                                    }}>
                                                        {arrImage.name}
                                                    </Typography>
                                                    <Typography style={{
                                                        fontSize: "1rem",
                                                        color: " #3f526d",
                                                        fontFamily: "Open sans",
                                                        fontWeight: 600,
                                                        marginTop: 11
                                                    }}>
                                                        {arrImage.size > 1048576 ? `${(arrImage.size / 1048576).toFixed(2)} MB` : `${(arrImage.size / 1024).toFixed(2)} KB`}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <CloseIcon style={{
                                                    opacity: 0.08,
                                                    color: "#3f526d",
                                                    backgroundColor: "#fff",
                                                    fontSize: 50,
                                                    cursor: "pointer"
                                                }}
                                                    onClick={() => {
                                                        const index = fileArray.indexOf(arrImage);
                                                        if (index > -1) {
                                                            fileArray.splice(index, 1);
                                                            bulkFileUploaded.splice(index, 1);
                                                        }
                                                        this.setState({ fileArray: fileArray, bulkFileUploaded: bulkFileUploaded })
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                            <Box style={{
                                margin: "0px 0 0px 3px",
                                opacity: 0.6,
                                border: "solid 0.5px #3f526d",
                            }}>
                            </Box>
                            <Box style={{
                                margin: "18px 0px 2px 0px",
                            }}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography style={{
                                            fontFamily: "Open sans",
                                            fontSize: "1rem",
                                            fontWeight: 600,
                                        }}>
                                            Add to your Post
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",

                                    }}>
                                        <input
                                            accept="image/png, image/jpg, image/jpeg, video/mp4"
                                            style={{ display: "none" }}
                                            id="contained-button-file"
                                            multiple
                                            type="file"
                                            onChange={(event: any) => {
                                                if (event.target.files.length + imageArray.length + fileArray.length > 10) {
                                                    this.setState({ alertMessage: "Please upload maximum 10 files.", alertModal: true })
                                                }
                                                else {
                                                    this.imageHandler(event)
                                                }
                                            }}
                                        />
                                        <label htmlFor="contained-button-file" style={{ display: "flex", flexDirection: "row" }}>
                                            <img src={image} style={{ width: 26, height: 26, cursor: "pointer" }} />
                                            <Typography style={{
                                                margin: "0px 24px 0px 18px",
                                                fontFamily: "Open sans",
                                                fontSize: "1rem",
                                                fontWeight: 600,
                                                color: "#3f526d",
                                                cursor: "pointer"

                                            }}>
                                                Photo/Video
                                            </Typography>
                                        </label>

                                    </Grid>
                                    <Grid item xs={2} style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                    }}>
                                        <input
                                            accept=" application/pdf , application/msword ,application/vnd.ms-excel , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , application/vnd.openxmlformats-officedocument.wordprocessingml.document , text/plain,"
                                            style={{ display: "none" }}
                                            id="contained-button2-file"
                                            multiple
                                            type="file"
                                            onChange={(event: any) => {
                                                if (event.target.files.length + imageArray.length + fileArray.length > 10) {
                                                    this.setState({ alertMessage: "Please upload maximum 10 files.", alertModal: true })
                                                }
                                                else {
                                                    this.fileHandler(event)
                                                }
                                            }}

                                        />
                                        <label htmlFor="contained-button2-file" style={{ display: "flex", flexDirection: "row" }}>
                                            <img src={folder} style={{ width: 26, height: 26, cursor: "pointer" }} />
                                            <Typography style={{
                                                margin: "0px 24px 0px 18px",
                                                fontFamily: "Open sans",
                                                fontSize: "1rem",
                                                fontWeight: 600,
                                                color: "#3f526d",
                                                cursor: "pointer"
                                            }}>
                                                File
                                            </Typography>
                                        </label>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <Button
                                disableRipple={true}
                                style={{
                                    padding: "13px 0px 13px",
                                    margin: "29px 1px 40px 0px",
                                    borderRadius: 6,
                                    opacity: 1,
                                    width: "35%",
                                    fontSize: "1.25rem",
                                    fontWeight: 600,
                                    textTransform: "none",
                                }}
                                className={(imageArray.length > 0 || fileArray.length > 0 || post_description.length) ? this.props.classes.button_color : this.props.classes.button_disabled_color}
                                onClick={this.post_Submit}
                                disabled={(imageArray.length > 0 || fileArray.length > 0 || post_description.length > 0) ? false : true}
                            >
                                Post
                            </Button>
                        </Box>
                        <Typography
                            style={{
                                fontSize: "1.25rem",
                                fontWeight: 600,
                                backgroundColor: "#fff",
                            }}
                            className={this.props.classes.title_font}
                        >
                            Timeline
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            style={{
                                padding: "12px 20px 11px",
                                margin: "18px 10px 25px 0px",
                                border: `1px solid #9aa3b2`,
                                borderRadius: 6
                            }}
                        >
                            <input
                                placeholder="Search timeline..."
                                style={{
                                    border: "none",
                                    outline: "none",
                                    color: "#484848",
                                    opacity: 0.45,
                                    fontSize: "1rem",
                                    fontWeight: 600,
                                    width: "100%",
                                    paddingRight: 10
                                }}
                                onChange={((event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.get_search_posts(event.target.value.trim())
                                }
                                )}
                            />
                            <Search style={{
                                height: 25,
                                width: 25,
                            }}
                                className={this.props.classes.icon_color} />
                        </Box>



                        <Box>
                            {timelineData.map((timeline: any, index: number) => {
                                var event = moment.utc(timeline.attributes.created_at)
                                var date = event.local().format('DD MMM YYYY, hh:mm A');
                                return (
                                    <>
                                        <Grid container style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            margin: "51px 0px 30px 0px",
                                        }}>
                                            <Grid item xs={1}>
                                                <Box style={{
                                                    backgroundColor: "#fff",
                                                    textAlign: "center"
                                                }}>
                                                    <Avatar
                                                        src={timeline.attributes.added_by.data.attributes.avatar}
                                                        style={{
                                                            width: 38,
                                                            height: 38,
                                                            padding: 4,
                                                            borderRadius: "50%",
                                                        }}
                                                        className={this.props.classes.avtar_border_color}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={11} style={{ display: "flex", flexDirection: "row" }}>
                                                <Grid item xs={9}>
                                                    <Typography style={{
                                                        fontSize: "1.25rem",
                                                        fontFamily: 'Open sans',
                                                        color: "#3f526d",
                                                    }}>
                                                        {
                                                            timeline.type === 'goals'
                                                                ?
                                                                <>
                                                                    <span style={{
                                                                        fontSize: "1.25rem",
                                                                        fontFamily: 'Open sans',
                                                                        color: "#3f526d",
                                                                        fontWeight: 600
                                                                    }}>Goal</span> added by {timeline.attributes.added_by.data.attributes.first_name} {timeline.attributes.added_by.data.attributes.last_name}
                                                                </>
                                                                :
                                                                <Typography style={{
                                                                    fontSize: "1.25rem",
                                                                    fontFamily: 'Open sans',
                                                                    color: "#3f526d",
                                                                }}>
                                                                    <span style={{
                                                                        fontSize: "1.25rem",
                                                                        fontFamily: 'Open sans',
                                                                        color: "#3f526d",
                                                                        textTransform: "capitalize"
                                                                    }}>{timeline.attributes.added_by.data.attributes.first_name}</span> {timeline.attributes.added_by.data.attributes.last_name} added a <span style={{
                                                                        fontSize: "1.25rem",
                                                                        fontFamily: 'Open sans',
                                                                        color: "#3f526d",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        post
                                                                    </span> on {date}
                                                                </Typography>
                                                        }
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Box style={{
                                                        backgroundColor: "#fff",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "end"
                                                    }}>
                                                        <Box style={{
                                                            margin: "0 10px 0 0px",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            border: "solid 0.15px #471c51",
                                                            borderRadius: 6,
                                                            backgroundColor: "#f9f8fd",
                                                            padding: "8px 8px 10px 10px"
                                                        }}
                                                        >
                                                            <Box>
                                                                <img
                                                                    id="2"
                                                                    src={share} style={{
                                                                        margin: "3px 4px 1px 4px",
                                                                        width: 18,
                                                                        height: 18,
                                                                        cursor: "pointer"
                                                                    }}
                                                                    onClick={(event: any) => { this.setState({ shareScreenAnchorEl: event?.currentTarget, goal_id: timeline.id }) }} />
                                                                {this.state.shareScreenAnchorEl && goal_id === timeline.id && <ShareScreen popoverClose={this.onShareClose} id={timeline.id} shareScreenAnchorEl={this.state.shareScreenAnchorEl} navigation={undefined} type={timeline.type} />}
                                                            </Box>
                                                        </Box>
                                                        <Box style={{
                                                            margin: "0 10px 0 0px",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            border: "solid 0.15px #471c51",
                                                            borderRadius: 6,
                                                            backgroundColor: "#f9f8fd",
                                                            padding: "8px 8px 10px 10px"
                                                        }}
                                                        >
                                                            <Box>
                                                                <img src={timeline.attributes.like_by_me || this.state.isLiked ? like : heart} style={{
                                                                    margin: "3px 4px 1px 0",
                                                                    width: 18,
                                                                    height: 18,
                                                                    cursor: "pointer"
                                                                }}
                                                                    onClick={() => {
                                                                        let temptimelineData = timelineData
                                                                        temptimelineData[index].attributes.like_by_me = !temptimelineData[index].attributes.like_by_me
                                                                        this.setState({ timelineData: temptimelineData })
                                                                        this.post_like(timeline.type, timeline.id, temptimelineData[index].attributes.like_by_me)
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Typography style={{
                                                                color: "#3f526d",
                                                                fontFamily: "Open sans",
                                                                margin: "0 0 0 4px",
                                                                fontSize: "1rem",
                                                            }}>
                                                                {timeline.attributes.likes}
                                                            </Typography>
                                                        </Box>
                                                        <Box style={{
                                                            margin: "0 10px 0 0px",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            border: "solid 0.15px #471c51",
                                                            borderRadius: 6,
                                                            backgroundColor: "#f9f8fd",
                                                            padding: "8px 8px 10px 10px"
                                                        }}
                                                        >
                                                            <Box>
                                                                <img
                                                                    src={chat}
                                                                    style={{
                                                                        margin: "3px 4px 1px 0",
                                                                        width: 18,
                                                                        height: 18,
                                                                        cursor: "pointer"
                                                                    }}
                                                                    onClick={() => { this.setState({ openComment: !this.state.openComment, goal_id: timeline.id }) }}
                                                                />
                                                            </Box>
                                                            {this.state.openComment && goal_id === timeline.id && <CommentModal id={timeline.id} type={timeline.type} closeModalFun={this.onClose} openComment={this.state.openComment} />}
                                                            <Typography style={{
                                                                color: "#3f526d",
                                                                fontFamily: "Open sans",
                                                                margin: "0 0 0 4px",
                                                                fontSize: "1rem",
                                                            }}>
                                                                {timeline.attributes.comments.data.length}
                                                            </Typography>
                                                        </Box>
                                                        <Box style={{
                                                            margin: "0 10px 0 0px",
                                                        }}>
                                                            <IconButton
                                                                aria-label="more"
                                                                aria-controls="long-menu"
                                                                aria-haspopup="true"
                                                                style={{ padding: 0 }}
                                                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                                                    this.setState({
                                                                        anchorEl: event.currentTarget,
                                                                        goal_id: timeline.id, edit_type: timeline.type
                                                                    })
                                                                }}
                                                            >
                                                                <MoreVertOutlined
                                                                    style={{
                                                                        height: 24,
                                                                        width: 24,
                                                                        opacity: 0.24,
                                                                        color: "#3f526d"
                                                                    }}

                                                                />
                                                            </IconButton>
                                                            <Menu
                                                                id="basic-menu"
                                                                anchorEl={this.state.anchorEl}
                                                                open={Boolean(this.state.anchorEl)}
                                                                onClose={() => { this.setState({ anchorEl: '' }) }}
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'basic-button',
                                                                }}
                                                            >
                                                                <Box style={{ width: 203 }}>
                                                                    <MenuItem
                                                                        disabled={edit_type === 'post' && true}
                                                                        onClick={() => {
                                                                            this.goToEditGoals()
                                                                        }
                                                                        }>
                                                                        <Grid container>
                                                                            <Grid item xs={12} style={{ flexBasis: 0 }} >
                                                                                <Typography
                                                                                    style={{
                                                                                        textTransform: "none",
                                                                                        fontSize: "1rem",
                                                                                        fontFamily: "Open Sans",
                                                                                        fontWeight: 600,
                                                                                        color: "#3f526d"
                                                                                    }}

                                                                                >
                                                                                    Edit
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </MenuItem>
                                                                    <MenuItem onClick={() => { this.setState({ deleteGoal: !this.state.deleteGoal }) }}>
                                                                        <Grid container spacing={1}>
                                                                            <Grid item xs={12} style={{ flexBasis: 0 }}>
                                                                                <Typography
                                                                                    style={{
                                                                                        textTransform: "none",
                                                                                        fontSize: "1rem",
                                                                                        fontFamily: "Open Sans",
                                                                                        fontWeight: 600,
                                                                                        color: "#3f526d"
                                                                                    }}
                                                                                >
                                                                                    Delete
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </MenuItem>
                                                                    {this.state.deleteGoal && goal_id === timeline.id &&
                                                                        <>
                                                                            <DeleteModal deleteModal={this.state.deleteGoal} type={timeline.type} id={timeline.id} closeModalFun={this.onClose} navigation={undefined} />
                                                                        </>
                                                                    }
                                                                </Box>
                                                            </Menu>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={11} style={{ display: "flex", flexDirection: "row", borderBottom: "solid 0.5px #3f526d" }}>
                                                <Box style={{
                                                    backgroundColor: "#fff",
                                                    color: "#3f526d",
                                                    fontSize: "1.25rem",
                                                    fontFamily: "Open sans",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginTop: 15,
                                                    width: "100%",
                                                }}>
                                                    <Grid container>
                                                        {timeline.attributes.file.data.map((file: any, index: number) => (
                                                            file.attributes.content_type === 'application/pdf' || file.attributes.content_type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.attributes.content_type === "application/vnd.ms-excel" || file.attributes.content_type === "application/msword" || file.attributes.content_type === "application/x-ole-storage" || file.attributes.content_type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                                ?
                                                                <>
                                                                    <Box style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "row",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        marginTop: '30px',
                                                                        marginBottom: '30px',
                                                                        border: "1px solid #af38cb",
                                                                        borderRadius: 8,
                                                                        padding: "13px 20px"
                                                                    }}>
                                                                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0px 20px 0px 0px", }}>
                                                                            <img src={file.attributes.content_type === "application/pdf" ? pdf : file.attributes.content_type === "application/msword" || file.attributes.content_type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.attributes.content_type === "application/x-ole-storage" ? doc : xls} style={{
                                                                                height: 55,
                                                                            }} />
                                                                            <Box style={{ marginLeft: 18, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                <Typography style={{
                                                                                    fontSize: "1.25rem",
                                                                                    color: " #3f526d",
                                                                                    fontFamily: "Open sans",
                                                                                    fontWeight: 600,
                                                                                }}>
                                                                                    {file.attributes.file_name}
                                                                                </Typography>
                                                                                <Typography style={{
                                                                                    fontSize: "1rem",
                                                                                    color: " #3f526d",
                                                                                    fontFamily: "Open sans",
                                                                                    fontWeight: 600,
                                                                                    marginTop: 11
                                                                                }}>
                                                                                    {file.attributes.file_size > 1048576 ? `${(file.attributes.file_size / 1048576).toFixed(2)} MB` : `${(file.attributes.file_size / 1024).toFixed(2)} KB`}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box style={{
                                                                            margin: "0 10px 0 0px",
                                                                        }}>
                                                                            <IconButton
                                                                                aria-label="more"
                                                                                aria-controls="long-menu"
                                                                                aria-haspopup="true"
                                                                                style={{ padding: 0 }}
                                                                            // onClick={() => this.saveFile(_file.attributes.url, _file.attributes.file_name)}
                                                                            >
                                                                                <a download href={file.attributes.url}>
                                                                                    <img src={download} style={{
                                                                                        margin: "0px 0px 6px 0",
                                                                                        width: 26,
                                                                                        height: 26,
                                                                                    }} />
                                                                                </a>
                                                                            </IconButton>
                                                                        </Box>
                                                                    </Box>
                                                                </>
                                                                :
                                                                this.state.previewImage && this.state.id === timeline.attributes.id ?
                                                                    <>
                                                                        <Grid item xs={3} style={{ position: "relative", width: "100%" }}>
                                                                            {
                                                                                file.attributes.content_type === 'video/mp4'
                                                                                    ?
                                                                                    <video controls style={{ display: "block", width: "95%", height: 200, objectFit: "cover" }} src={file.attributes.url} >
                                                                                        The “video” tag is not supported by your browser. Click [here] to download the video file.
                                                                                    </video>
                                                                                    :

                                                                                    <a download href={file.attributes.url}>
                                                                                        <img src={file.attributes.url} style={{ display: "block", width: "95%", marginTop: 10, height: 200, objectFit: "cover" }} />
                                                                                    </a>
                                                                            }
                                                                        </Grid>
                                                                    </>
                                                                    :
                                                                    index === 3 ?
                                                                        <Grid
                                                                            item xs={3}
                                                                            style={{ position: "relative", width: "100%", cursor: "pointer" }}
                                                                            onClick={() => this.setState({ previewImage: !this.state.previewImage, id: timeline.attributes.id })}>
                                                                            {
                                                                                file.attributes.content_type === 'video/mp4'
                                                                                    ?
                                                                                    <video controls style={{ display: "block", width: "95%", height: 200, marginBottom: 30, objectFit: "cover" }} src={file.attributes.url} >
                                                                                        The “video” tag is not supported by your browser. Click [here] to download the video file.
                                                                                    </video>
                                                                                    :
                                                                                    <Box style={{ position: "relative" }}>
                                                                                        <a download href={file.attributes.url}>
                                                                                            <img src={file.attributes.url} style={{ display: "block", width: "95%", marginBottom: 30, height: 200, objectFit: "cover" }} />
                                                                                        </a>
                                                                                        <Box style={{
                                                                                            position: "absolute",
                                                                                            top: 0,
                                                                                            bottom: 0,
                                                                                            left: 0,
                                                                                            right: 0,
                                                                                            height: "100%",
                                                                                            width: "100%",
                                                                                            backgroundColor: "rgba(236, 236, 236,0.5)",
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            alignItems: "center",
                                                                                        }}>
                                                                                            <Typography
                                                                                                style={{
                                                                                                    fontSize: "1.625rem",
                                                                                                    color: "#fff",
                                                                                                    fontFamily: "Open sans",
                                                                                                    opacity: 1,
                                                                                                    justifyContent: "center",
                                                                                                }}>
                                                                                                {timeline.attributes.file.data.length > 3 && <>+{parseInt(timeline.attributes.file.data.length) - 3}</>}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                            }

                                                                        </Grid>
                                                                        :
                                                                        (
                                                                            index < 4 &&
                                                                            <>
                                                                                <Grid item xs={3} style={{ position: "relative", width: "100%" }}>
                                                                                    {
                                                                                        file.attributes.content_type === 'video/mp4'
                                                                                            ?
                                                                                            <video controls style={{ display: "block", width: "95%", height: 200, objectFit: "cover" }} src={file.attributes.url} >
                                                                                                The “video” tag is not supported by your browser. Click [here] to download the video file.
                                                                                            </video>
                                                                                            :
                                                                                            <a download href={file.attributes.url}>
                                                                                                <img src={file.attributes.url} style={{ display: "block", width: "95%", height: 200, objectFit: "cover" }} />
                                                                                            </a>
                                                                                    }
                                                                                </Grid>
                                                                            </>
                                                                        )

                                                        ))}
                                                    </Grid>
                                                    {
                                                        this.state.previewImage && this.state.id === timeline.attributes.id
                                                        &&
                                                        <>
                                                            <Typography style={{
                                                                fontSize: "0.875rem",
                                                                color: "#af38cb",
                                                                fontFamily: "Open sans",
                                                                cursor: "pointer",
                                                                marginTop: 10
                                                            }}
                                                                onClick={() => { this.setState({ previewImage: !this.state.previewImage }) }}
                                                            >
                                                                Show less
                                                            </Typography>
                                                        </>
                                                    }
                                                    {
                                                        timeline.attributes.title &&
                                                        <Typography style={{
                                                            margin: "25px 0px 18px 12px",
                                                            fontFamily: "Open sans",
                                                            fontSize: "1.125rem",
                                                            fontWeight: 600,
                                                            color: "#3f526d",
                                                            textTransform: "capitalize"
                                                        }}>
                                                            {timeline.attributes.title}
                                                        </Typography>
                                                    }
                                                    {validator.isURL(timeline.attributes.description)
                                                        ?
                                                        <>
                                                            <Link
                                                                target="_blank"
                                                                href={timeline.attributes.description}
                                                                style={{
                                                                    margin: timeline.attributes.title ? "0px 0px 30px 12px" : "25px 0px 30px 12px",
                                                                    fontFamily: "Open sans",
                                                                    fontSize: "1.125rem",
                                                                    fontWeight: 600,
                                                                    cursor: "pointer",
                                                                    color: "#af38cb",
                                                                }}>
                                                                {timeline.attributes.description}
                                                            </Link>
                                                        </>
                                                        :
                                                        <>
                                                            <Typography style={{
                                                                margin: timeline.attributes.title ? "0px 0px 30px 12px" : "25px 0px 30px 12px",
                                                                fontFamily: "Open sans",
                                                                fontSize: "1.125rem",
                                                                fontWeight: 600,
                                                                color: "#3f526d",
                                                                textTransform: "capitalize"
                                                            }}>
                                                                {timeline.attributes.description}
                                                            </Typography>
                                                        </>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            })
                            }

                            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>

                                <ReactPaginate
                                    previousLabel={"←   Previous"}
                                    nextLabel={"Next   →	"}
                                    initialPage={this.state.post_page}
                                    pageCount={this.state.timeLineMeta?.pagination?.total_pages}
                                    onPageChange={(e: any) => {
                                        if (e.selected !== this.state.post_page) {
                                            this.setState({ post_page: e.selected })
                                            this.get_timeline_posts(e.selected + 1)
                                        }
                                    }}
                                    containerClassName={"paginationBttns"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                    pageRangeDisplayed={4}
                                    marginPagesDisplayed={0}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box >
        )
    }
    // Customizable Area End
}
const ThemeCreateStyle = ((theme: any) => ({

    // Customizable Area Start
    background_color: {
        backgroundColor: theme.props.backgroundColor
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    icon_color: {
        color: theme.props.iconColor
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        border: `1px solid ${theme.props.buttonColor}`,
        color: "#fff"
    },
    button_disabled_color: {
        backgroundColor: "rgba(109, 114, 120, 0.1)",
        border: `1px solid rgba(109, 114, 120, 0.1)`,
        color: "#471c51"
    },
    border_color: {
        border: `1px solid ${theme.props.backgroundColor}`
    },
    avtar_border_color: {
        border: `1px solid ${theme.props.backgroundColor}`
    }
    // Customizable Area End
}))
// Customizable Area Start
export default HOCAuth(withStyles(ThemeCreateStyle)(
    StudentPortfolio as React.ComponentType<any>
), "StudentAccount")
// Customizable Area End
