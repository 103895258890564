// Customizable Area Start
import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "./TeacherDashboard.web.css";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

interface IProps {
  // Customizable Area Start
  allEventsModal: boolean;
  modalHandleClose: () => void;
  classes: any;
  allEvents: any[];
  // Customizable Area End
}
const ViewAllNotesModal: React.FC<IProps> = (props) => {
  // Customizable Area Start
  const { allEventsModal, modalHandleClose, classes, allEvents } = props;
  const   handleGetStartAndEndTime = (startDate: any) => {
    return moment(startDate).format('h:mm A');
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={allEventsModal}
        onClose={modalHandleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableAutoFocus={true}
      >
        <Fade in={allEventsModal} style={{ border: "none" }}>
          <div className="notepopup">
            <Box style={{ height: "100%" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "0.3px solid #979797",
                  justifyContent: "space-between",
                  height: "45px",
                }}
              >
                <Box>
                  <Typography
                    style={{
                      textTransform: "none",
                      fontSize: 24,
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      color: "#3f526d",
                      margin: "0 0px 26px 0px",
                    }}
                    className={classes.title_font}
                  >
                    Events
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  height: "78%",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  marginTop: "7px",
                }}
              >
                <Box
                  style={{
                    padding: "20px 17px 2px 18px",
                    backgroundColor: "rgba(124, 106, 247, 0.27)",
                    borderLeft: "3px solid #7c6af7",
                  }}
                >
                  {allEvents?.map((schoolevent: any, index: any) => (
                    <>
                      <Box
                        style={{
                          padding: "12px 64px 12px 13px",
                          borderRadius: 6,
                          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.14)",
                          backgroundColor: "#fff",
                          marginBottom: 7,
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 24,
                            color: "#3f526d",
                            marginBottom: 8,
                          }}
                          className={classes.subHeading_font}
                        >
                          {schoolevent.attributes.name}
                        </Typography>
                        <Typography
                      style={{
                        fontSize: 18,
                        marginTop: 20,
                      }}
                      className={classes.bodyText_font}
                    >
                        <div
                  dangerouslySetInnerHTML={{
                    __html: schoolevent?.attributes?.description,
                  }}
                />
                    </Typography>
                        <Typography
                          style={{
                            fontSize: 16,
                            marginTop:'20px'
                          }}
                          className={classes.bodyText_font}
                        >
                          {handleGetStartAndEndTime(
                            schoolevent?.attributes?.start_time
                          )}{" "}
                          to{" "}
                          {handleGetStartAndEndTime(
                            schoolevent?.attributes?.end_time
                          )}
                        </Typography>
                      </Box>
                    </>
                  ))}
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  borderRadius: "12px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={modalHandleClose}
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: 3,
                    color: "black",
                    fontSize: 14,
                    fontWeight: 600,
                    letterSpacing: "0.1px",
                    boxShadow: "none",
                    padding: "13px 33px 13px 35px",
                    marginRight: "10px",
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  ViewAllNotesModal as React.ComponentType<any>
);
// Customizable Area End
