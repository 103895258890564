// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import AssessmentSummaryController, { Props } from "./AssessmentSummaryController.web";
import { difficultyCopyIcon, difficultyIcon, imgBackArrow, sectionIcon } from "../assets";
import { withStyles } from "@material-ui/styles";
import ScheduleModalWeb from "./ScheduleModal.web";
import PublishedScheduledModalWeb from "./PublishedScheduledModal.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import AlertModal from "../../../alert/src/AlertModal.web";
import AddIcon from "@material-ui/icons/Add";
import AddGroupModal from "./AddGroupModal";
import "./AssessmentSummary.web.css";
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web'
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class AssessmentSummary extends AssessmentSummaryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start
  render() {
    const { classes } = this.props
    const { assessmentSummary, divisionList } = this.state;
    const LightTooltip = withStyles((theme) => ({
      arrow: {
        color: '#f9f8fd',
      },
      tooltip: {
        backgroundColor: '#f9f8fd',
        color: theme.props.textColor,
        boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.1)',
        fontSize: 10,
        padding: '11px 13.5px 18.7px',
        fontFamily: theme.props.textFont
      },
    }))(Tooltip);

    
    const divisionInputClass:any = this.state.error.divisionId ? `${classes.AssessmentSummary_AssessmentQuestionBankLibrary_InputText} Error` : classes.AssessmentSummary_AssessmentQuestionBankLibrary_InputText;
    const groupButtonClass:any =  this.state.error.studentList ? `${classes.AssessmentSummary_AssessmentQuestionBankLibrary_GroupButton} Error` : classes.AssessmentSummary_AssessmentQuestionBankLibrary_GroupButton;
    return (
      <>
        {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
        <div
          className="BackButtonDiv"
        >
          <img src={imgBackArrow} 
            className="BackIcon"
            data-test-id="summary-back-icon"
            onClick={() => { this.props.navigation.goBack() }}
          />
          <Typography
            className={`BackText ${this.props.classes.bodyText_font}`}
          >Back</Typography>
        </div>
        <div className="ContentDiv">
          <Typography
            className={`SummaryText ${this.props.classes.heading_font}`}
          >
            Assessment Summary
          </Typography>

          <Box marginTop='10px'>
            <Grid container spacing={2}>
              {/* Assessment Name */}
              <Grid item xs={12}>
                <Typography 
                  className={`MV10 ${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}
                >
                  Assessment Title
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder=""
                  value={assessmentSummary?.assessment_name}
                  disabled={true}
                  defaultValue=""
                  className={`${classes.AssessmentSummary_AssessmentQuestionBankLibrary_TextFieldNum} ${classes.assesmentSummary_AssessmentTest_mui_input_style}`}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                />
              </Grid>
              {/* Assessment Name END */}

              <Grid item xs={12} className="MV7">
                <Grid container spacing={4}>
                  {/* Subject */}
                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <Typography
                      className={`MV10 ${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}
                    >
                      Subject
                    </Typography>

                    <TextField
                      variant="outlined"
                      placeholder=""
                      type="text"
                      defaultValue=""
                      value={assessmentSummary?.subject?.[0]?.attributes?.subject_name}
                      disabled={true}
                      className={`${classes.AssessmentSummary_AssessmentQuestionBankLibrary_TextFieldNum} ${classes.assesmentSummary_AssessmentTest_mui_input_style}`}

                      inputProps={{ style: { textTransform: "capitalize" } }}
                    />
                  </Grid>
                  {/* Subject END */}


                  {/* Exam Type */}
                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <Typography 
                      className={`MV10 ${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}
                    >
                      Exam Type
                    </Typography>

                    <TextField
                      variant="outlined"
                      placeholder=""
                      type="text"
                      defaultValue=""
                      value={assessmentSummary?.exam_type}
                      disabled={true}
                      className={`${classes.AssessmentSummary_AssessmentQuestionBankLibrary_TextFieldNum} ${classes.assesmentSummary_AssessmentTest_mui_input_style}`}

                      inputProps={{ style: { textTransform: "capitalize" } }}
                    />
                  </Grid>
                  {/* Exam Type END */}

                </Grid>
              </Grid>

              {/* Radio buttons */}
              <Grid item xs={12} >
                <RadioGroup
                  aria-label="publish"
                  name="publish"
                  defaultValue="class_division"
                  value={this.state.publishRadioValue}
                  data-test-id="publish-radio-grp"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({
                    publishRadioValue: event.target.value
                  })}
                >
                  <Grid container spacing={4}>

                    {/* Class */}
                    <Grid item xs={4} sm={4} md={3} lg={2}>
                      <FormControlLabel
                        value="class"
                        control={<Radio color="primary"
                        // checkedIcon={<CheckCircleOutlineIcon />}
                        />}
                        label="Class"
                        className={classes.bodyText_font}
                        labelPlacement="end"
                      />
                    </Grid>
                    {/* Class END */}

                    {/* Class & Division */}
                    <Grid item xs={4} sm={4} md={3} lg={2}>
                      <FormControlLabel
                        value="class_division"
                        control={<Radio color="primary"
                        // checkedIcon={<CheckCircleOutlineIcon />}
                        />}
                        label="Class &amp; Division"
                        className={classes.bodyText_font}
                        labelPlacement="end"
                      />
                    </Grid>
                    {/* Class & Division END */}

                    {/* Group */}
                    <Grid item xs={4} sm={4} md={3} lg={2}>
                      <FormControlLabel
                        value="group"
                        control={<Radio color="primary"
                        // checkedIcon={<CheckCircleOutlineIcon />}
                        />}
                        label="Group"
                        className={classes.bodyText_font}
                        labelPlacement="end"

                      />
                    </Grid>
                    {/* Group END */}
                  </Grid>
                </RadioGroup>
              </Grid>
              {/* Radio buttons END */}

              {/* Radio Button View Elements */}
              <Grid item xs={12} >

                {/* Class */}
                {this.state.publishRadioValue === 'class' ?
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                      <Typography 
                        className={`MV4 ${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}
                      >
                        Class
                      </Typography>

                      <TextField
                        variant="outlined"
                        placeholder=""
                        type="text"
                        defaultValue=""
                        value={assessmentSummary?.grade?.[0]?.attributes?.name}
                        disabled={true}
                        className={`${classes.AssessmentSummary_AssessmentQuestionBankLibrary_TextFieldNum} ${classes.assesmentSummary_AssessmentTest_mui_input_style}`}

                        inputProps={{ style: { textTransform: "capitalize" } }}
                      />
                    </Grid>
                  </Grid>
                  : null
                }
                {/* Class END */}

                {/* Class & Division */}
                {this.state.publishRadioValue === 'class_division' ?
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                      <Typography 
                        className={`MV4 ${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}
                      >
                        Division
                      </Typography>
                      <select
                        value={this.state.divisionId ? this.state.divisionId : ""}
                        name="divisionId"
                        onChange={this.handleDivisionChange}
                        data-test-id="division-select"
                        className={`${divisionInputClass} ${this.props.classes.bodyText_font}`}
                      >
                        <option value="" disabled>
                          Select Division
                        </option>
                        {Object.keys(divisionList).length != 0 &&
                          Object.keys(divisionList).map((item: any) => (
                            <option key={item} value={divisionList[item][0]?.school_class_id}>
                              {item}
                            </option>
                          ))}
                      </select>
                      {this.state.error.divisionId && (
                        <FormHelperText className="ColorRed">
                          Please select division
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  : null
                }
                {/* Class & Division END */}

                {/* Group */}
                {this.state.publishRadioValue === 'group' ?
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                      <Typography 
                        className={`MV4 ${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}
                      >
                        Group
                      </Typography>
                      <Button
                        className={`${groupButtonClass} ${this.props.classes.bodyText_font}`}
                        data-test-id="radio-value-group-btn"
                        onClick={() => {
                          this.setState({
                            addGroupModal: true
                          })
                        }}
                      >
                        <Typography className={classes.AssessmentSummary_AssessmentQuestionBankLibrary_CommonText}>
                          {(this.state.studentIdList.length !== 0 && !this.state.addGroupModal) ? (`${this.state.studentIdList.length} Selected`) : "Add Group"}
                        </Typography>
                        <AddIcon className="AddIcon" />
                      </Button>
                      {this.state.error.studentList && (
                        <FormHelperText className="ColorRed">
                          Please select students
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  : null
                }
                {/* Group END */}
              </Grid>
              {/* Radio Button View Elements END */}

              <Grid item xs={12} className="MT7">
                <Grid container spacing={3}>
                  {/* Max marks */}
                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <Typography 
                      className={`MV10 ${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}
                    >
                      Max marks
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder=""
                      type="text"
                      defaultValue=""
                      value={assessmentSummary?.maximum_marks}
                      disabled={true}
                      className={`${classes.AssessmentSummary_AssessmentQuestionBankLibrary_TextFieldNum} ${classes.assesmentSummary_AssessmentTest_mui_input_style}`}

                      inputProps={{ style: { textTransform: "capitalize" } }}
                    />
                  </Grid>
                  {/* Max marks END */}

                  {/* Terms */}
                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <Typography 
                      className={`MV10 ${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}
                    >
                      Terms
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder=""
                      type="text"
                      defaultValue=""
                      value={assessmentSummary?.term?.[0]?.attributes?.title}
                      disabled={true}
                      className={`${classes.AssessmentSummary_AssessmentQuestionBankLibrary_TextFieldNum} ${classes.assesmentSummary_AssessmentTest_mui_input_style}`}

                      inputProps={{ style: { textTransform: "capitalize" } }}
                    />
                  </Grid>
                  {/* Terms END */}

                  {/* Include Negative Marking */}
                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <Typography 
                      className={`MV10 ${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}
                    >
                      Include Negative Marking?
                    </Typography>
                    <Box
                      className={classes.AssessmentSummary_AssessmentQuestionBankLibrary_BoxStyle}
                    >
                      <Checkbox
                        disabled={true}
                        checked={assessmentSummary?.negative_marking ? true : false}
                      />
                      <Typography
                        className={`YesText ${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}>Yes</Typography>
                    </Box>
                  </Grid>
                  {/* Include Negative Marking END */}

                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  className={`QuestionDescriptionText ${classes.heading_font}`}
                >
                  Question paper Description
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <Box className="FieldTitleBox">
                      <img src={difficultyIcon} className="Size22" />
                      <Typography
                        className={`MS20 ${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}>
                        Difficulty Level
                      </Typography>
                    </Box>
                    <TextField
                      variant="outlined"
                      placeholder=""
                      type="text"
                      defaultValue=""
                      value={assessmentSummary?.difficulty_level}
                      disabled={true}
                      className={`${classes.AssessmentSummary_AssessmentQuestionBankLibrary_TextFieldDiffLevel} ${classes.assesmentSummary_AssessmentTest_mui_input_style}`}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <Box className="FieldTitleBox">
                      <img src={difficultyCopyIcon} className="Size22" />
                      <Typography
                        className={`MS20 ${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}>
                        Total Duration
                      </Typography>
                    </Box>
                    <TextField
                      variant="outlined"
                      placeholder=""
                      defaultValue=""
                      value={assessmentSummary?.time_limit}
                      disabled={true}
                      className={`${classes.AssessmentSummary_AssessmentQuestionBankLibrary_TextFieldDiffLevel} ${classes.assesmentSummary_AssessmentTest_mui_input_style}`}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                    />

                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={8} md={8} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box className="FieldTitleBox">
                      <img src={sectionIcon} className="Size22" />
                      <Typography
                        className={`MS20 ${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}>
                        Section
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="FieldTitleBox">
                      <Typography
                        className={`${classes.AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle} ${classes.subHeading_font}`}>
                        Total Marks
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {assessmentSummary?.assessment_sections_attributes?.map((sectionData: any) => (
                <Grid key={sectionData?.id} item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={4} md={4} lg={3}>
                      <TextField
                        variant="outlined"
                        placeholder=""
                        defaultValue=""
                        value={sectionData?.name}
                        disabled={true}
                        className={`${classes.AssessmentSummary_AssessmentQuestionBankLibrary_TextFieldDiffLevel} ${classes.assesmentSummary_AssessmentTest_mui_input_style}`}
                        inputProps={{ style: { textTransform: "capitalize" } }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={3}>
                      <TextField
                        variant="outlined"
                        placeholder=""
                        defaultValue=""
                        value={sectionData?.section_marks}
                        disabled={true}
                        className={`${classes.AssessmentSummary_AssessmentQuestionBankLibrary_TextFieldDiffLevel} ${classes.assesmentSummary_AssessmentTest_mui_input_style}`}
                        inputProps={{ style: { textTransform: "capitalize" } }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}


            </Grid>
          </Box>

          <Box className="ActionButtonBox">

            <LightTooltip title="On clicking Publish now the students
                  will be able to start the exam after 15 minutes." arrow>
              <Button className={`ActionButton MR20 ${classes.button_color}`}
                data-test-id="publish-now-btn"
                onClick={this.onClickPublishNowBtn}
              >
                Publish Now
              </Button>
            </LightTooltip>
            <Button className={`ActionButton ${classes.outline_button_no_hover}`}
              data-test-id="schedule-later-btn"
              onClick={this.onClickScheduleLaterBtn}
            >
              Schedule Later
            </Button>

          </Box>
        </div>
        <ScheduleModalWeb
          openModal={this.state.openScheduleModal}
          handleOnClose={this.handleOnCloseScheduleModal}
          handleOnSchedule={this.handleOnSchedule}
          currentTime={new Date()}
        />
        <PublishedScheduledModalWeb
          openModal={this.state.openPublishScheduleModal}
          handleOnClose={this.handleOnClose}
          messageString={this.state.successMessage}
        />
        <AlertModal
          alertModal={this.state.alertModal}
          isTeacher={true}
          onCloseModal={this.onCloseAlertModal}
          alertTitle="Alert!"
          alertMessage={this.state.alertMessage}
          buttonText="OK"
        />

        <AddGroupModal
          openModal={this.state.addGroupModal}
          handleOnClose={this.handleOnCloseAddGroup}
          studentList={this.state.studentList}
          messageString={this.state.successMessage}
          handleOnSchedule={undefined}
          updateList={this.handleStudentToggle}
          checkedStudents={this.state.studentIdList}
          handleSelectAll={this.handleSelectAll}
          handleDeSelectAll={this.handleDeSelectAll}
        />

      </>
    )
  }
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area Start

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(AssessmentSummary)
// Customizable Area End
