// Customizable Area Start
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import GradingDetailsController, { Props } from './GradingDetailsController.web';
import Spinner from '../../../shared/SideBar/src/Spinner';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class GradingDetails extends GradingDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start

    const { reportGradeScaleData, isLoader } = this.state

    return (
      <>
        {isLoader && <Spinner spinnerModal={isLoader} />}
        <div>
          <p className={`${this.props.classes.heading_font} grading-detail-text`}>
             Grading scale for this report card.
          </p>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {
              reportGradeScaleData?.attributes?.report_grade_scales?.data?.length > 0 &&
              <TableContainer className="GradingTableContainer">
                <Table className="GradingTable">
                  <TableHead>
                    <TableRow>
                      <TableCell className={`GradingHeadCell ${this.props.classes.ReportCardDetails_Feedback_background_color} border-radius-0`}>Label</TableCell>
                      <TableCell className={`GradingHeadCell ${this.props.classes.ReportCardDetails_Feedback_background_color}`}>Score</TableCell>
                      <TableCell className={`GradingHeadCell ${this.props.classes.ReportCardDetails_Feedback_background_color}`}>Max Marks</TableCell>
                      <TableCell className={`GradingHeadCell ${this.props.classes.ReportCardDetails_Feedback_background_color} border-radius-0`}>Min Marks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="GradingTableBody">
                    {reportGradeScaleData?.attributes?.report_grade_scales?.data?.map((data: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell className={`GradingBodyCell ${this.props.classes.heading_font} border-radius-0`}>{data.attributes.name}</TableCell>
                        <TableCell className={`GradingBodyCell ${this.props.classes.heading_font}`}>{data.attributes.score}</TableCell>
                        <TableCell className={`GradingBodyCell ${this.props.classes.heading_font}`}>{data.attributes.max_marks}</TableCell>
                        <TableCell className={`GradingBodyCell ${this.props.classes.heading_font} border-radius-0`}>{data.attributes.min_marks}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Grid>
        </div>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area // Customizable Area Start
export default withStyles(themeCreateStyle)(GradingDetails as React.ComponentType<any>);
// Customizable Area End