// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import moment from 'moment';
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    addEditPeriodDialog: boolean;
    handleAddEditPeriodDialogClose: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    data: any;
    pagination_page: number;
    periodData: any;
    timeLineMeta: any;
    showLoader: boolean;
    viewBatch: boolean;
    selected_batch_id: number;
    openAddEditPeriod: boolean;
    periodName: any;
    periodNameError: boolean;
    periodNameErrorMsg: any;
    selectedBatch: string;
    selectedBatchError: boolean;
    batchData: any;
    selectedStartTime: any;
    selectedEndTime: any;
    selectedStartTimePeriodLabel: string;
    selectedEndTimePeriodLabel: string;
    isEditPeriod: boolean;
    selectedPeriodForEdit: any;
    selectedPeriodEndTimeError: any;
    selectedPeriodEndTimeErrorMsg: string;
    openDeletePeriodModal: boolean;
    editPeriodEnable: string;
    selectedPeriodType: any;
    sortByDate:'asc'|'desc'
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

// Customizable Area Start
const today = new Date();
// Customizable Area End

export default class AdminBatchAndPeriodController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getPeriodListId: string = "";
    getBatchListId: string = "";
    postPeriodId: string = "";
    deletePeriodId: string = "";
    child: React.RefObject<any>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.child = React.createRef();

        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            data: '',
            pagination_page: 0,
            periodData: [],
            timeLineMeta: [],
            showLoader: false,
            viewBatch: false,
            selected_batch_id: 0,
            openAddEditPeriod: false,
            periodName: "",
            periodNameError: false,
            periodNameErrorMsg: "",
            selectedBatch: "",
            selectedBatchError: false,
            batchData: [],
            selectedStartTime: today,
            selectedEndTime: today,
            selectedStartTimePeriodLabel: moment(today).format("A"),
            selectedEndTimePeriodLabel: moment(today).format("A"),
            isEditPeriod: false,
            selectedPeriodForEdit: null,
            selectedPeriodEndTimeError: false,
            selectedPeriodEndTimeErrorMsg: "",
            openDeletePeriodModal: false,
            editPeriodEnable: 'enable',
            selectedPeriodType: 'Lecture',
            sortByDate:'asc'
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.userdata = this.userdata.bind(this);
        this.get_period_list = this.get_period_list.bind(this);
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.get_period_list(this.state.pagination_page + 1);
        this.get_batch_list();
        // Customizable Area End
    }

    // Customizable Area Start
    onCloseViewBatchModal = (closeModal: boolean) => {
        this.setState({ viewBatch: closeModal })
        this.get_period_list(this.state.pagination_page + 1);
    }

    handleAddEditPeriodDialogClose = (closeModal: boolean) => {
        this.setState({
            openAddEditPeriod: closeModal,
            selectedBatch: "",
            periodNameError: false,
            periodNameErrorMsg: "",
            selectedPeriodEndTimeError: false,
            selectedPeriodEndTimeErrorMsg: "",
            selectedBatchError: false,
        })
    }

    handleChangeBatch=(event:any)=>{
         this.setState({ selected_batch_id: Number(event.target.value),pagination_page:0 });
         this.get_period_list(1,Number(event.target.value));
    }

    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }
    handleSortByCreationDate = () => {
        const { sortByDate } = this.state;
        if (sortByDate === 'asc') {
            const sortedPeriods=this.state.periodData.sort((a:any,b:any)=>new Date(b.attributes.created_at).getTime()-new Date(a.attributes.created_at).getTime())
            this.setState({ sortByDate: 'desc',periodData:sortedPeriods });
        } else {
            const sortedPeriods=this.state.periodData.sort((a:any,b:any)=>new Date(a.attributes.created_at).getTime()-new Date(b.attributes.created_at).getTime())
            this.setState({ sortByDate: 'asc',periodData:sortedPeriods });
        }
    }

    handleOnChangePeriodName =(e : any) => {
        const value = e.target.value.trim();
        if (value.length === 0) {
          this.setState({
            periodNameError: true,
            periodNameErrorMsg: "Period name is required.",
          });
        } else {
          this.setState({
            periodNameError: false,
            periodNameErrorMsg: "",
          });
        }

        this.setState({ periodName: e.target.value });
    }

    handleOnBlurPeriodName = () => {
        if (
            this.state.periodName === null ||
            this.state.periodName.length === 0
          ) {
            this.setState({
              periodNameError: true,
              periodNameErrorMsg: "Period name is required.",
            });
          } else {
            this.setState({
              periodNameError: false,
              periodNameErrorMsg: "",
            });
          }
    }

    handleOnChangeBatch = (e : any) => {
        if (e.target.value !== "") {
            this.setState({ selectedBatchError: false })
          }
          this.setState({ selectedBatch: e.target.value })
    }

    handleOnBlurBatch =() => {
        if (
            this.state.selectedBatch === "" ||
            this.state.selectedBatch?.length === 0
          ) {
            this.setState({ selectedBatchError: true })
          } else if (this.state.selectedBatch?.length !== 0) {
            this.setState({ selectedBatchError: false })
          } else {
            this.setState({ selectedBatchError: false })
          }
    }

    handleOnChangePeriodType =(e : any) => {
        this.setState({ selectedPeriodType: e.target.value })
    }

    handleOnCLoseDeleteModal = () => {
        this.setState({
            openDeletePeriodModal: false,
            selectedPeriodForEdit: null,
          });
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.getPeriodListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        const formattedPeriodData = responseJson?.data?.map((item: any) => {
                            item.attributes.start_time = moment(item.attributes.start_time).format("hh:mm A");
                            item.attributes.end_time = moment(item.attributes.end_time).format("hh:mm A");

                            return item;
                        })
                        this.setState({ periodData: formattedPeriodData, timeLineMeta: responseJson?.meta, showLoader: false })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorResponse);
            } else if (apiRequestCallId === this.getBatchListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ batchData: responseJson?.data })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorResponse);
            } else if (apiRequestCallId === this.postPeriodId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ openAddEditPeriod: false, showLoader: false })
                        this.get_period_list(this.state.pagination_page + 1)
                    } else {
                        responseJson?.errors?.map((item: any) => {
                            const periodError = item?.period;
                            const nameError = item?.name;
                            const startAndEndTimeError = item?.start_time_and_end_time;

                            if (periodError === 'is already scheduled for this time durations') {
                                this.setState({
                                    selectedPeriodEndTimeError: true,
                                    selectedPeriodEndTimeErrorMsg: "Period is already scheduled for this time durations.",
                                    showLoader: false
                                });
                            } else if (nameError === 'has already been taken') {
                                this.setState({
                                    periodNameError: true,
                                    periodNameErrorMsg: "Period name has already been taken.",
                                    showLoader: false
                                });
                            } else if (startAndEndTimeError) {
                                this.setState({
                                    selectedPeriodEndTimeError: true,
                                    selectedPeriodEndTimeErrorMsg: startAndEndTimeError,
                                    showLoader: false
                                });
                            } else {
                                this.setState({
                                    selectedPeriodEndTimeError: true,
                                    selectedPeriodEndTimeErrorMsg: "Something went wrong.",
                                    showLoader: false
                                });
                            }
                          })
                    }
                }
                this.parseApiCatchErrorResponse(errorResponse);
            } else if (apiRequestCallId === this.deletePeriodId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ openDeletePeriodModal: false, showLoader: false, selectedPeriodForEdit: null })
                        this.get_period_list(this.state.pagination_page + 1)
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorResponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    get_period_list = (page: any,selectedBatchId?:any) => {
        this.setState({ showLoader: true });
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.dashboardContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getPeriodListId = requestMessage.messageId;
        let apiEndPoint=configJSON.periodEndPoint + `?page=${page}&school_id=${school_Data.school_id}`;
        if(selectedBatchId) apiEndPoint=apiEndPoint+`&batch_id=${selectedBatchId}`;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboardApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    get_batch_list = () => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.dashboardContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBatchListId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.batchesEndPoint + `?per=1000&school_id=${school_Data.school_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboardApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleStartTimeChange = (date: any) => {
        this.setState({ selectedStartTime: date, selectedStartTimePeriodLabel: moment(date).format("A") })
        this.handleValidTime(date, this.state.selectedEndTime)
    };

    handleEndTimeChange = (date: any) => {
        this.setState({ selectedEndTime: date, selectedEndTimePeriodLabel: moment(date).format("A") })
        this.handleValidTime(this.state.selectedStartTime, date)
    };

    handleValidTime = (startTime: any, endTime: any) => {
        const minutesDiff = moment(endTime).diff(moment(startTime), 'minutes');
        const startTimeValid = moment(startTime, "LT", true);
        const endTimeValid = moment(endTime, "LT", true);
        if (!startTimeValid.isBefore(endTimeValid)) {
            this.setState({
                selectedPeriodEndTimeError: true,
                selectedPeriodEndTimeErrorMsg: "End time should be greater than start time.",
            });
        } else if(minutesDiff < 5){
            this.setState({
                selectedPeriodEndTimeError: true,
                selectedPeriodEndTimeErrorMsg: " The school period must be greater or equal to 5 minutes.",
            });
        } else {
            this.setState({
                selectedPeriodEndTimeError: false,
                selectedPeriodEndTimeErrorMsg: "",
            });
        }
    }

    onAddPeriodClick = () => {
        this.get_batch_list();
        this.setState({
            openAddEditPeriod: true, isEditPeriod: false, selectedPeriodForEdit: null, periodName: "", selectedPeriodType: "Lecture",
            selectedStartTime: new Date(), selectedStartTimePeriodLabel: moment(new Date()).format("A"),
            selectedEndTime: new Date(), selectedEndTimePeriodLabel: moment(new Date()).format("A")
        })
    }

    onEditPeriodClick = (data: any) => {
        this.setState({
            isEditPeriod: true,
            openAddEditPeriod: true,
            selectedPeriodForEdit: data,
            selectedBatch: data.attributes.batch.data?.id ? data.attributes.batch.data?.id : "",
            periodName: data.attributes.name,
            selectedPeriodType: data.attributes.period_type,
            selectedStartTime: this.handleGetStartAndEndTime(data.attributes?.start_time),
            selectedStartTimePeriodLabel: this.handleGetStartAndEndTimeLabel(data.attributes?.start_time),
            selectedEndTime: this.handleGetStartAndEndTime(data.attributes?.end_time),
            selectedEndTimePeriodLabel: this.handleGetStartAndEndTimeLabel(data.attributes?.end_time)
        })
    }

    isValidData = () => {
        let isValid = true;
        const value = this.state.periodName.trim();
        const startTimeValid = moment(this.state.selectedStartTime, "LT", true);
        const endTimeValid = moment(this.state.selectedEndTime, "LT", true);
        const minutesDiff = moment(this.state.selectedEndTime).diff(moment(this.state.selectedStartTime), 'minutes');
        if (value.length === 0) {
            this.setState({
                periodNameError: true,
                periodNameErrorMsg: "Period name is required.",
            });
            isValid = false;
        }
        if (this.state.selectedBatch === "" || this.state.selectedBatch?.length === 0) {
            this.setState({ selectedBatchError: true });
            isValid = false;
        }
        if (minutesDiff < 5) {
            this.setState({
                selectedPeriodEndTimeError: true,
                selectedPeriodEndTimeErrorMsg: " The school period must be greater or equal to 5 minutes.",
            });
            isValid = false;
        }
        if (!startTimeValid.isBefore(endTimeValid)) {
            this.setState({
                selectedPeriodEndTimeError: true,
                selectedPeriodEndTimeErrorMsg: "End time should be greater than start time.",
            });
            isValid = false;
        }
        return isValid;
    }

    add_edit_period = () => {
        if (this.isValidData()) {
            this.setState({
                periodNameError: false,
                periodNameErrorMsg: "",
                selectedPeriodEndTimeError: false,
                selectedPeriodEndTimeErrorMsg: "",
            });

            this.setState({ showLoader: true });
            const user_data = localStorage.getItem('user_data');
            const school_Data = JSON.parse(user_data || '{}')
            const header = {
                "Content-Type": configJSON.dashboardContentType,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.postPeriodId = requestMessage.messageId;

            const { periodName, selectedStartTime, selectedEndTime, selectedBatch, isEditPeriod, selectedPeriodForEdit, editPeriodEnable } = this.state

            const httpBody = {
                name: periodName.trim(),
                period_type: this.state.selectedPeriodType,
                start_time: moment(selectedStartTime).format(),
                end_time: moment(selectedEndTime).format(),
                school_id: school_Data?.school_id,
                batch_id: Number(selectedBatch),
                week_day: moment(selectedStartTime).format("dddd"),
            };

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                isEditPeriod ? configJSON.periodEndPoint + `/${selectedPeriodForEdit?.id}` : configJSON.periodEndPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestbaseURLMessage),
                configJSON.instituteURL
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                isEditPeriod ? configJSON.dashboardApiPutMethodType : configJSON.dashboardApiPostMethodType
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    handleGetStartAndEndTime = (time: string) => {
        const dateObj = new Date();
        const month = dateObj.getUTCMonth(); //index of month
        const day = dateObj.getDate();
        const year = dateObj.getUTCFullYear();
        const formattedTime = moment(time, ["h:mm A"]).format("HH:mm A");
        const formattedTimeHours = parseInt(formattedTime.split(":")[0]);
        const formattedTimeMins = parseInt(formattedTime.split(":")[1]);
        return new Date(year, month, day, formattedTimeHours, formattedTimeMins);
    };

    handleGetStartAndEndTimeLabel = (time: string) => {
        const date = this.handleGetStartAndEndTime(time)
        return moment(date).format("A")
    }

    delete_period = () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deletePeriodId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.periodEndPoint + `/${this.state.selectedPeriodForEdit?.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteSchoolMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleChangeEditPeriod = (event: any) => {
        this.setState({ editPeriodEnable: event.target.value })
    }
    // Customizable Area End
}