// Customizable Area Start
import { runEngine } from '../../../../framework/src/RunEngine';
import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { HISTORY } from '../../../../components/src/comman';

import React from 'react';
// Customizable Area End

// Customizable Area Start
export const configJSON = require('../config.js');
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  classes: any;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabValue: number;
  showLoader: boolean;
  schoolClasses: any[];
  classSubjects: any[];
  isFiltersOpen: boolean;
  selected_class: any;
  selected_subject: {
    id: string;
    subject_name: string;
  };
  sections: any[];
  activeSection: any;
  assessmentSummaryReport: any;
  selectedYear: string;
  selected_test_type: string;
  performancePerStudent: any;
  assessmentQuestionAnalysis: any;
  grade_id: string;
  filteredAssessments: any[];
  selected_assessment: {
    id: string;
    assessment_name: string;
  };
  submittedFilters: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AssessmentSummaryViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSchoolClassesId: string = '';
  getClassSubjectsId: string = '';
  getAssessmentSummaryReportId: string = '';
  filterAssessmentsMessageId: string = '';
  getAssessmentQuestionAnalysisId: string = '';
  getPerformanceByStudentsId: string = '';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      tabValue: 0,
      submittedFilters: false,
      showLoader: false,
      schoolClasses: [],
      isFiltersOpen: false,
      selectedYear: configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION,
      selected_class: {
        id: '',
        class_name: configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION,
      },
      filteredAssessments: [],
      sections: [],
      activeSection: null,
      classSubjects: [],
      selected_assessment: {
        id: '',
        assessment_name: configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION,
      },
      selected_subject: {
        id: '',
        subject_name: configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION,
      },
      grade_id: '',
      assessmentSummaryReport: {},
      selected_test_type: configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION,
      performancePerStudent: {},
      assessmentQuestionAnalysis: {},
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  handleTabValueChange = (index: number) => {
    this.setState({ tabValue: index });
  };

  filterChangeDate = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    this.setState({
      selectedYear: e.target.value as string,
      selected_assessment: {
        id: '',
        assessment_name: configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION,
      },
    });
    if (
      this.state.selected_class.id !== '' &&
      this.state.selected_test_type !==
        configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION
    ) {
      this.filterAssessments(e.target.value as string);
    }
  };

  handleSelectSubject = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    this.setState({
      selected_subject: {
        //@ts-ignore
        id: e.target.value.id,
        //@ts-ignore
        subject_name: e.target.value.attributes.subject_name,
      },
      selected_test_type: '',
      selected_assessment: {
        id: '',
        assessment_name: '',
      },
    });
    if (
      this.state.selectedYear !==
        configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION &&
      this.state.selected_class.id !== '' &&
      this.state.selected_test_type !==
        configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION
    ) {
      this.filterAssessments();
    }
  };

  handleChangeTestType = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    this.setState({
      selected_test_type: e.target.value as string,
      selected_assessment: {
        id: '',
        assessment_name: '',
      },
    });
    if (
      this.state.selectedYear !==
        configJSON.ASSESSMENT_SUMMARY.SELECT_AN_OPTION &&
      this.state.selected_class.id !== '' &&
      this.state.selected_subject.id !== ''
    ) {
      this.filterAssessments(this.state.selectedYear, e.target.value as string);
    }
  };
  handleChangeClass = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    this.setState({
      //@ts-ignore
      grade_id: event.target.value.attributes.grade.id,
      selected_class: {
        //@ts-ignore
        id: event.target.value.id,
        //@ts-ignore
        class_name: event.target.value.attributes.display_name,
      },
      selected_subject: {
        id: '',
        subject_name: '',
      },
      selected_test_type: '',
      selected_assessment: {
        id: '',
        assessment_name: '',
      },
    });
    //@ts-ignore
    this.getClassSubjects(event.target.value.id);
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getSchoolClassesId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              schoolClasses: responseJson.data,
              showLoader: false,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.getClassSubjectsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              classSubjects: responseJson.data,
              showLoader: false,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }

      if (apiRequestCallId === this.getAssessmentSummaryReportId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              showLoader: false,
              assessmentSummaryReport: responseJson.data,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.filterAssessmentsMessageId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              filteredAssessments: responseJson.data,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.getAssessmentQuestionAnalysisId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              showLoader: false,
              assessmentQuestionAnalysis: responseJson.data,
              sections: responseJson.data?.section_questions
                ? responseJson.data?.section_questions
                : [],
              activeSection: responseJson?.data?.section_questions
                ? responseJson.data?.section_questions[0]
                : [],
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.getPerformanceByStudentsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              performancePerStudent: responseJson.data ? responseJson.data : [],
              showLoader: false,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    this.getSchoolClasses();
    if (HISTORY.location.state) {
      this.handleTabValueChange(HISTORY.location.state.tabValue);
    }
  }
  submitFilters = () => {
    this.setState({
      isFiltersOpen: false,
      submittedFilters: true,
    });

    this.getPerformancePerStudent();
    this.getAssessmentSummaryReport();
    this.getAssessmentQuestionAnalysis();
  };

  toggleActiveSection = (sectionIndex: number) => {
    this.setState({
      activeSection: this.state.sections[sectionIndex],
    });
  };
  handleSelectAssessment = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    this.setState({
      selected_assessment: {
        //@ts-ignore
        id: event.target.value.id as string,
        //@ts-ignore
        assessment_name: event.target.value.assessment_name as string,
      },
    });
  };
  handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    //@ts-ignore
    this.setState({ [event.target.name]: event.target.value });
    //@ts-ignore
    this.getClassSubjects(event.target.value.id);
  };
  handleOpenFilters = () => {
    this.setState({ isFiltersOpen: !this.state.isFiltersOpen });
  };
  handleSubmitFilters = () => {
    // this.filterAssessments();
    this.setState({ isFiltersOpen: false });
  };
  getPerformancePerStudent = () => {
    this.setState({ showLoader: true });

    // @ts-ignore
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const { school_id } = user_data;

    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('token'),
      school: school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPerformanceByStudentsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      //@ts-ignore
      `${configJSON.getPerformanceByStudents}${this.state.selected_assessment.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getClassSubjects = (classId: string) => {
    this.setState({ showLoader: true });

    const header = {
      'Content-Type': configJSON.dashboardContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClassSubjectsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClassSubjectsList}${classId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAssessmentSummaryReport = () => {
    this.setState({ showLoader: true });
    const token = localStorage.getItem('token');
    //@ts-ignore
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const { school_id } = user_data;

    const header = {
      'Content-Type': 'application/json',
      token,
      school: school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAssessmentSummaryReport}?assessment_id=${this.state.selected_assessment.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    this.getAssessmentSummaryReportId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  filterAssessments = (
    selected_year: string = this.state.selectedYear,
    test_type: string = this.state.selected_test_type
  ) => {
    this.setState({ showLoader: true });
    const token = localStorage.getItem('token');
    //@ts-ignore
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const { school_id } = user_data;

    const header = {
      'Content-Type': 'application/json',
      token,
      school: school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.filterAssessmentsMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${
        configJSON.assessment_filter
      }?test_type=${test_type.toLowerCase().replace(/ /g, '_')}&subject_id=${
        this.state.selected_subject.id
      }&session_start=${selected_year.split('-')[0]}&class_id=${
        this.state.selected_class.id
      }&grade_id=${this.state.grade_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSchoolClasses = () => {
    this.setState({ showLoader: true });
    //@ts-ignore
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const header = {
      'Content-Type': configJSON.dashboardContentType,
      school: user_data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSchoolClassesId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSchoolClassesList}${user_data?.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAssessmentQuestionAnalysis = () => {
    this.setState({ showLoader: true });
    const token = localStorage.getItem('token');
    //@ts-ignore
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const { school_id } = user_data;

    const header = {
      'Content-Type': 'application/json',
      token,
      school: school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAssessmentQuestionAnalysis}?assessment_id=${this.state.selected_assessment.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    this.getAssessmentQuestionAnalysisId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleChangeTabs(num: number) {
    this.setState({ tabValue: num });
  }

  // Customizable Area End
}
