// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { computeChecksumMd5 } from "../utils/common";
import { createRef, RefObject } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import convert from "xml-js";
import { HISTORY } from "../../../../components/src/comman";
import { v4 as uuidv4 } from "uuid";

export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  data: any;
  showLoader: boolean;
  marks: any;
  negativeMarks: any;
  wordLimit: any;
  selectedSubject: any;
  selectedChapter: any;
  selectedUnit: any;
  selectedSkill: any;
  selectedProficiency: any;
  selectedDifficultyLevel: any;
  keywordsList: any;
  keyword: string;
  questionOptionList: any;
  charNumber: number;
  chapterListData: any;
  unitListData: any;
  showLoaderForImageUpload: boolean;
  openSelectImageModal: boolean;
  alertMessage: string;
  alertModal: boolean;
  selectedImageFile: any;
  selectedImageBlobUrl: string;
  selectedImageSignedId: any;
  question: string;
  selectedOptionNumber: number;
  optionImageSelection: boolean;
  temp_answers_attributes: any;
  solutionImageSignedId: any;
  multipleResponse: boolean;
  hintAnswer: any;
  hintsNumberr: number;
  answerSelected: boolean;
  explaination: any;
  previewImageModal: boolean;
  openChooseFromGalleryModal: boolean;
  selectedCategoryQuestionData: any;
  assessmentSectionId: any;
  remainSectionMarkPr: number;
  selectedGalleryImageId: number;
  selectedGalleryImageUrl: string;
  marksError: boolean;
  questionError: boolean;
  metaDataErrors: any;
  sectiondata: any;
  isEdit: boolean;
  negmarksError: boolean;
  marksErrorText: string;
  questionId: any;
  explanationId: string;
  hintsExplanationUrl: string;
  attachmentExplanationUrl: string;
  assessmentHasNegativeMarking: boolean;
  mainGalleryImageId: any;
  explainationAttachmentUploadId: string;
  selectedBloomTaxonomy: any;
  hintId: any;
  hintsAttachmentUploadId: string;
  previewImage: string;
  prevQuestionModal: boolean;
  graph_axis_settings_attributes: any;
  graph_plotting_categories_attributes: any;
  graph_properties: any;
  correct_answer_id: number;
  main_graph_object_id: number;
  deletedGraphCategories: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class GraphPlottingTypeQuestionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuestionListId: string = "";
  deleteQuestionId: string = "";
  getTopicListId: string = "";
  getChapterListId: string = "";
  apiPhotoPreSignedID: string = "";
  apiUploadID: string = "";
  apiSaveQuesionId: string = "";
  apiDeleteSolutionAttachment: string = "";
  readonly inputOpenFileRef: RefObject<HTMLInputElement>;
  readonly inputFirstOpenFileRef: RefObject<HTMLInputElement>;
  questionEditorRef: React.Ref<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      data: "",
      showLoader: false,
      marks: 0,
      negativeMarks: 0,
      wordLimit: "",
      selectedSubject: "",
      selectedChapter: "",
      selectedUnit: "",
      selectedSkill: "",
      hintId: null,
      hintsAttachmentUploadId: "",
      selectedProficiency: "",
      selectedDifficultyLevel: "",
      keywordsList: [],
      keyword: "",
      charNumber: 0,
      hintsNumberr: 0,
      questionOptionList: [],
      answerSelected: false,
      hintAnswer: [],
      chapterListData: [],
      unitListData: [],
      showLoaderForImageUpload: false,
      openSelectImageModal: false,
      alertMessage: "",
      alertModal: false,
      selectedImageFile: null,
      selectedImageBlobUrl: "",
      selectedImageSignedId: null,
      question: "",
      selectedOptionNumber: 0,
      optionImageSelection: false,
      temp_answers_attributes: [],
      multipleResponse: false,
      solutionImageSignedId: null,
      explaination: "",
      previewImageModal: false,
      openChooseFromGalleryModal: false,
      selectedCategoryQuestionData: null,
      assessmentSectionId: 0,
      negmarksError: false,
      marksErrorText: "",
      hintsExplanationUrl: "",
      selectedGalleryImageId: 0,
      selectedGalleryImageUrl: "",
      marksError: false,
      questionError: false,
      isEdit: false,
      metaDataErrors: {
        chapter: false,
        unit: false,
        skill: false,
        proficiency: false,
        keywords: false,
        difficultyLevel: false,
        bloomTaxonomy: false,
      },
      remainSectionMarkPr: 0,
      explanationId: "",
      sectiondata: {},
      attachmentExplanationUrl: "",
      mainGalleryImageId: 0,
      questionId: "",
      assessmentHasNegativeMarking: JSON.parse(
        localStorage.getItem("assessmentData") || "{}"
      )?.negative_marking,
      explainationAttachmentUploadId: "",
      selectedBloomTaxonomy: "",
      previewImage: "",
      prevQuestionModal: false,
      graph_axis_settings_attributes: {
        x_axis_label: '',
        y_axis_label: '',
        minimum_value: 0,
        maximum_value: 100,
        step_size: 10,
      },
      graph_plotting_categories_attributes: [],
      graph_properties: {
        show_grid: true,
        show_multicolor_bar: true,
        display_position_on_hover: true,
      },
      correct_answer_id: -1,
      main_graph_object_id: -1,
      deletedGraphCategories: [],
    };
    this.userdata = this.userdata.bind(this);
    this.get_chapter_list = this.get_chapter_list.bind(this);
    this.inputOpenFileRef = createRef();
    this.inputFirstOpenFileRef = createRef();
    this.questionEditorRef = createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.get_chapter_list();
    const { questionData, sectiondata, sectionId } = HISTORY.location.state;
    if (HISTORY.location.state?.isEdit) {

      let index = questionData?.attributes?.solutions?.data.length - 1;
      let ImageIndex = questionData?.attributes?.attachments?.data?.length - 1;
      this.setState({
        isEdit: HISTORY.location.state?.isEdit,
        marks: questionData?.attributes?.marks,
        negativeMarks: questionData?.attributes?.negative_marks,
        question: questionData?.attributes?.title,
        wordLimit: questionData?.attributes
          ?.word_limit,
        explaination: questionData?.attributes?.solutions?.data[index]?.attributes?.explaination,
        explanationId: questionData?.attributes?.solutions?.data[index]?.id,
        selectedCategoryQuestionData: questionData?.attributes?.type,
        selectedChapter: questionData?.attributes?.metadata?.data?.attributes?.chapter_id,
        selectedUnit: questionData?.attributes?.metadata?.data?.attributes?.unit_id,
        selectedBloomTaxonomy: questionData?.attributes?.metadata?.data?.attributes?.bloom_taxonomy,
        selectedProficiency: questionData?.attributes?.metadata?.data?.attributes?.proficiency,
        selectedSkill: questionData?.attributes?.metadata?.data?.attributes?.skill,
        selectedDifficultyLevel: questionData?.attributes?.metadata?.data?.attributes?.difficulty_level,
        keywordsList: questionData?.attributes?.metadata?.data?.attributes?.keywords,
        questionId: questionData?.id,
        remainSectionMarkPr: ((sectiondata?.section_marks - sectiondata?.remaining_marks) * 100) /
          sectiondata?.section_marks,
        sectiondata: sectiondata,
        selectedImageBlobUrl: questionData?.attributes?.attachments?.data?.[ImageIndex]?.attributes?.url,
        attachmentExplanationUrl: questionData?.attributes
          ?.solutions?.data?.[0]?.attributes?.attachments?.data?.[0]
          ?.attributes?.url,
        explainationAttachmentUploadId: questionData?.attributes
          ?.solutions?.data?.[0]?.attributes?.attachments?.data?.[0]?.id,
        multipleResponse: questionData?.attributes?.multiple_response,
        hintsAttachmentUploadId: questionData?.attributes?.hints
          ?.data?.[0]?.attributes?.attachments?.data?.[0]?.id,
        assessmentSectionId: sectionId,
        hintsExplanationUrl: questionData?.attributes?.hints?.data?.[0]?.attributes?.attachments?.data?.[0]?.attributes?.url,
      });

      // graph
      const graph_props: any = {
        show_grid: questionData?.attributes.graph_plotting_setting.data.attributes.show_grid,
        show_multicolor_bar: questionData?.attributes.graph_plotting_setting.data.attributes.multicolor_bar,
        display_position_on_hover: questionData?.attributes.graph_plotting_setting.data.attributes.display_position,
      }

      let graph_axis_config: any = {};
      questionData?.attributes.graph_plotting_setting.data.attributes.axis_setting.data.map(
        (data: any) => {
          if (data.attributes.axis_type == 'x_axis') {
            graph_axis_config.x_axis_label = data.attributes.axis_label;
            graph_axis_config.x_axis_id = data.id;
          } else if (data.attributes.axis_type == 'y_axis') {
            graph_axis_config.y_axis_id = data.id;
            graph_axis_config.y_axis_label = data.attributes.axis_label;
            graph_axis_config.minimum_value = data.attributes.minimum_value;
            graph_axis_config.maximum_value = data.attributes.maximum_value;
            graph_axis_config.step_size = data.attributes.step_size;
          }
        }
      );

      let graph_category_config: any = [];
      questionData?.attributes.graph_plotting_setting.data.attributes.graph_categories.data.map(
        (data: any) => {
          graph_category_config.push({
            category_id: data.id,
            uniq_key: data.attributes?.uniq_key,
            label: data.attributes?.label,
            initial_value: Number(data.attributes?.initial_value),
            correct_answer_value: Number(questionData?.attributes.answers.data[0].attributes.correct_chart_answers.filter((item: any) => item.uniq_key == data.attributes?.uniq_key)?.[0]?.right_answer),
            bar_color: this.generateColor(),
            fraction_format_type: "fraction",
            error: {
              initial_value_error: false,
              correct_answer_value_error: false,
            }
          })
        }
      )
      this.setState({
        graph_properties: graph_props,
        graph_axis_settings_attributes: graph_axis_config,
        graph_plotting_categories_attributes: graph_category_config,
        correct_answer_id: questionData?.attributes.answers.data[0].id,
        main_graph_object_id: questionData?.attributes.graph_plotting_setting.data.id
      })

      // graph END


      if (questionData?.attributes?.hints.data.length != 0) {
        questionData?.attributes?.hints.data.map((data: any, index: number) => {
          this.state.hintAnswer.push({
            id: data.id,
            hintId: index + 1,
            hint: data.attributes.hint,
            src:
              data.attributes.attachments.data.length != 0
                ? data.attributes.attachments.data[0].attributes.url
                : "",
          });
        });
      }
      else {
        this.setState({
          hintAnswer: [
            {
              hintId: 0,
              hint: "",
            },
          ]
        })
      }

      questionData?.attributes?.answers?.data?.map(
        (data: any, index: number) => {
          const randomColor = this.generateColor();
          if (data?.attributes?.right_answer) {
            this.setState({
              answerSelected: true,
            });
          }
          this.state.questionOptionList.push({
            id: data?.id,
            color: randomColor,
            charNumber: index + 1,
            right_answer: data?.attributes?.right_answer,
            files: [],
            oldUploadId: data?.attributes?.attachments?.data[0]?.id,
            name: data?.attributes?.attachments?.data[0]?.attributes?.file_name,
            src: data?.attributes?.attachments?.data[0]?.attributes?.url,
            isImageAvailble:
              data?.attributes?.attachments?.data?.length === 0 ? false : true,
            answer: data?.attributes?.answer,
          });
        }
      );

      this.setState({
        charNumber: questionData?.attributes?.answers?.data?.length,
      });
    } else {
      const randomColor = this.generateColor();
      this.setState({
        hintAnswer: [
          {
            hintId: 0,
            hint: "",
          },
        ],
        charNumber: 4,
        questionOptionList: [
          {
            charNumber: 1,
            color: "#C0392B",
          },
          {
            charNumber: 2,
            color: "#229954",
          },
          {
            charNumber: 3,
            color: "#2471A3",
          },
          {
            charNumber: 4,
            color: "#1F51FF",
          },
        ],
        sectiondata: sectiondata,
        selectedCategoryQuestionData: questionData,
        assessmentSectionId: sectionId,
        remainSectionMarkPr: ((sectiondata?.section_marks - sectiondata?.remaining_marks) * 100) /
          sectiondata?.section_marks,
      });
    }
  }
  generateColor = () => {
    // Dark colors
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
    // Dark colors END
  };

  getExplanation = () => {
    if (HISTORY?.location?.state) {
      const { questionData } = HISTORY.location.state;
      const index = questionData?.attributes?.solutions?.data.length - 1;
      if (questionData?.attributes?.solutions?.data[index]?.attributes?.explaination)
        return questionData?.attributes?.solutions?.data[index]?.attributes?.explaination;

      return "";
    } else {
      HISTORY.push("/TeacherAssessmentCreateSection");
    }
  };

  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
  };

  handleInputChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      graph_axis_settings_attributes: { ...this.state.graph_axis_settings_attributes, [name]: value },
    });
  };

  handleAddPoint = () => {
    this.setState((preState) => ({
      ...preState,
      graph_plotting_categories_attributes: [
        ...this.state.graph_plotting_categories_attributes,
        {
          uniq_key: uuidv4(),
          label: "",
          fraction_format_type: "fraction",
          initial_value: this.state.graph_axis_settings_attributes.minimum_value,
          correct_answer_value: this.state.graph_axis_settings_attributes.minimum_value,
          bar_color: this.generateColor(),
          error: {
            initial_value_error: false,
            correct_answer_value_error: false,
          }
        },
      ],
    }));
  };

  handleUpdateCategories = (index: number, e: any) => {
    const data = [...this.state.graph_plotting_categories_attributes];
    const name = e.target.name;
    const value = e.target.value;
    data[index][name] = value;

    if (data[index].initial_value > Number(this.state.graph_axis_settings_attributes.maximum_value)) {
      data[index].error.initial_value_error = true;
    } else if (data[index].initial_value < Number(this.state.graph_axis_settings_attributes.minimum_value)) {
      data[index].error.initial_value_error = true;
    } else {
      data[index].error.initial_value_error = false;
    }

    if (data[index].correct_answer_value > Number(this.state.graph_axis_settings_attributes.maximum_value)) {
      data[index].error.correct_answer_value_error = true;
    } else if (data[index].correct_answer_value < Number(this.state.graph_axis_settings_attributes.minimum_value)) {
      data[index].error.correct_answer_value_error = true;
    } else {
      data[index].error.correct_answer_value_error = false;
    }

    this.setState((preState) => ({
      ...preState,
      graph_plotting_categories_attributes: data,
    }));
  };

  handleDeleteCategorySection = (categoryId: string, index: number) => {

    const filterSections = this.state.graph_plotting_categories_attributes.filter(
      (category: any) => {
        if (category.uniq_key !== categoryId) {
          return category;
        }
      }
    );

    const { deletedGraphCategories } = this.state;
    deletedGraphCategories.push({
      id: this.state.graph_plotting_categories_attributes[index].category_id,
      _destroy: true,
    })
    this.setState((preState) => ({
      ...preState,
      graph_plotting_categories_attributes: filterSections,
      deletedGraphCategories: deletedGraphCategories
    }));
  };

  plusMarksClick = () => {
    if (this.state.marks + 1 > 0) {
      this.setState({ marksError: false });
    } else {
      this.setState({
        marksError: true,
        marksErrorText: "Please enter marks.",
      });
    }

    if (
      this.state.marks < this.state.sectiondata?.remaining_marks
    ) {
      this.setState({ marks: this.state.marks + 1, marksError: false });
    } else if (
      this.state.marks ===
      this.state.sectiondata?.remaining_marks - 1
    ) {
      this.setState({ marks: this.state.marks + 1, marksError: false });
    }
    if (
      this.state.marks + 1 > this.state.negativeMarks ||
      this.state.marks + 1 == this.state.negativeMarks
    ) {
      this.setState({ marks: this.state.marks + 1, negmarksError: false });
    }
    if (this.state.isEdit) {
      if (
        this.state.marks + 1 >
        this.state.sectiondata?.remaining_marks +
        HISTORY.location.state?.questionData?.attributes?.marks
      ) {
        this.setState({
          marks: this.state.marks + 1,
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
      }
    } else {
      if (
        this.state.marks + 1 >
        this.state.sectiondata?.remaining_marks
      ) {
        this.setState({
          marks: this.state.marks + 1,
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
      }
    }

    this.setState({
      marks: this.state.marks + 1,
    });
  };

  minusMarksClick = () => {
    if (this.state.negativeMarks + 1 > this.state.marks) {
      this.setState({ negmarksError: true });
    }
    this.setState({ negativeMarks: this.state.negativeMarks + 1 });
  };

  onChangeKeywords = (e: any) => {
    this.setState({ keyword: e.target.value });
  };

  onBlurKeywords = (e: any) => {
    this.setState({ keyword: e.target.value });
    if (e.target.value !== "") {
      let { keywordsList } = this.state;
      keywordsList = [...keywordsList, e.target.value];
      this.setState({ keywordsList: keywordsList, keyword: "" });
      if (keywordsList.length > 0) {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: false },
        });
      } else {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: true },
        });
      }
    }
  };

  showOpenFileDlg = () => {
    this.inputOpenFileRef?.current?.click();
  };
  showFirstOpenFileDlg = () => {
    this.inputFirstOpenFileRef?.current?.click();
  };
  onKeyDownKeywords = (e: any) => {
    this.setState({ keyword: e.target.value });
    if (e?.key === "Enter" && e.target.value !== "") {
      let { keywordsList } = this.state;
      keywordsList = [...keywordsList, e.target.value];
      this.setState({ keywordsList: keywordsList, keyword: "" });
      if (keywordsList.length > 0) {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: false },
        });
      } else {
        this.setState({
          metaDataErrors: { ...this.state.metaDataErrors, keywords: true },
        });
      }
    }
  };

  handleDeleteKeyword = (item: any) => {
    let { keywordsList } = this.state;
    keywordsList = [...keywordsList.filter((x: any) => x !== item)];
    this.setState({ keywordsList: keywordsList, keyword: "" });
    if (keywordsList.length > 0) {
      this.setState({
        metaDataErrors: { ...this.state.metaDataErrors, keywords: false },
      });
    } else {
      this.setState({
        metaDataErrors: { ...this.state.metaDataErrors, keywords: true },
      });
    }
  };

  removeSelectedImage = () => {
    this.setState({
      selectedImageBlobUrl: "",
      selectedImageFile: null,
      selectedImageSignedId: null,
      selectedGalleryImageId: 0,
      selectedGalleryImageUrl: "",
      mainGalleryImageId: 0,
    });
  };

  onClickGalleryImageChoose = () => {
    this.setState({
      openChooseFromGalleryModal: false,
      selectedImageBlobUrl: this.state.selectedGalleryImageUrl,
      selectedImageFile: null,
      selectedImageSignedId: null,
    });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getChapterListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ chapterListData: responseJson?.data }, () =>
              this.get_unit_list(this.state.selectedChapter)
            );
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getTopicListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            // 
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiSaveQuesionId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            if (this.state.isEdit) {
              toast.success("Question updated successfully.");
            } else {
              toast.success("Question created successfully.");
            }
            // Update question to section list
            this.updateSectionQuestion(responseJson.data)
            // Update question to section list END
            this.setState({ showLoader: false });
            HISTORY.push("/TeacherAssessmentCreateSection");
          } else {
            this.parseApiErrorResponse(responseJson);
            this.setState({ showLoader: false });
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiPhotoPreSignedID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const findData = this.state.questionOptionList.find(
              (item: any) => item.messageId === this.apiPhotoPreSignedID
            );
            const imageData = findData
              ? findData?.file
              : this.state.selectedImageFile;
            const msg: Message = new Message(
              getName(MessageEnum.UploadMediaMessage)
            );
            const uploadFileData: any = {
              responseJson: responseJson,
              imageData,
              messageId: msg.messageId,
            };
            msg.addData(
              getName(MessageEnum.UploadMediaDataMessage),
              uploadFileData
            );
            this.apiUploadID = msg.messageId;
            runEngine.sendMessage(msg.id, msg);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apiUploadID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const keyValue = JSON.parse(
              convert.xml2json(responseJson, {
                spaces: 1,
                compact: true,
                ignoreComment: true,
                alwaysChildren: true,
                ignoreDeclaration: true,
              })
            );
            if (this.state.optionImageSelection) {
              let tempResponseList = this.state.questionOptionList;
              const index = this.state.questionOptionList.findIndex(
                (res: any) => res.charNumber === this.state.selectedOptionNumber
              );
              const data = tempResponseList[index];
              tempResponseList[index] = {
                ...data,
                files: [keyValue.PostResponse.Key._text],
                answer: "",
              };
              this.setState({ questionOptionList: tempResponseList });
            } else {
              this.setState({
                selectedImageSignedId: keyValue.PostResponse.Key._text,
              });
            }
            this.setState({
              showLoaderForImageUpload: false,
              selectedGalleryImageId: 0,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  updateSectionQuestion = (questionData: any) => {
    const { sectionId } = HISTORY.location.state;
    const assessmentData = JSON.parse(localStorage.getItem("assessmentData") || "{}");

    const question = {
      id: questionData.id,
      title: questionData?.attributes?.title,
      type: questionData?.attributes?.type?.name,
      marks: questionData?.attributes?.marks,
      negative_marks: questionData?.attributes?.negative_marks,
    };
    assessmentData.assessment_sections_attributes.map((sectionData: any, index: number) => {
      if (sectionData.id === sectionId) {
        // setting question data
        if (sectionData.question_data?.length > 0) {
          const qIndex = sectionData.question_data.findIndex((data: any) => Number(data.id) === Number(question.id));
          if (qIndex != -1) {
            sectionData.question_data[qIndex] = question;
          } else {
            sectionData.question_data.push(question);
          }
        } else {
          sectionData.question_data = [question];
        }
        // setting question data END

        // setting section marks
        const totalQuestionMarks = sectionData.question_data.reduce((sum: any, current: any) => (sum + current.marks), 0);
        sectionData.remaining_marks = sectionData.section_marks - totalQuestionMarks;
        // setting section marks END
        return;
      }
    })
    localStorage.setItem("assessmentData", JSON.stringify(assessmentData))
  }

  handleFileOnChange = async (
    event: React.ChangeEvent<any>,
    filesFromDragAndDrop: any
  ) => {
    let files: any;
    if (filesFromDragAndDrop !== null) {
      files = filesFromDragAndDrop;
    } else {
      files = event.target.files;
    }

    const file = files[0];
    var blobURL = URL.createObjectURL(file);
    this.setState({
      openSelectImageModal: false,
      selectedImageBlobUrl: blobURL,
      selectedImageFile: file,
      showLoaderForImageUpload: true,
    });
    await computeChecksumMd5(file).then((md5: any) => {
      const fileDetails = {
        fileName: file.name,
        fileSize: file.size,
        checksum: md5,
        content_type: file.type,
      };
      this.getPhotoPreSignedId(fileDetails);
    });
  };

  handleOptionFileChange = async (
    event: React.ChangeEvent<any>,
    filesFromDragAndDrop: any,
    dataID: any
  ) => {
    let files: any;
    if (filesFromDragAndDrop !== null) {
      files = filesFromDragAndDrop;
    } else {
      files = event.target.files;
    }

    const file = files[0];
    var blobURL = URL.createObjectURL(file);
    let tempResponseList = this.state.questionOptionList;
    const index = this.state.questionOptionList.findIndex(
      (res: any) => res.charNumber === dataID
    );
    const findData = this.state.questionOptionList[index];
    tempResponseList[index] = {
      ...findData,
      src: blobURL,
      name: file.name,
      isImageAvailble: true,
    };
    if (this.state.optionImageSelection) {
      this.setState({
        openSelectImageModal: false,
        questionOptionList: tempResponseList,
        selectedImageFile: file,
      });
    } else {
      this.setState({
        openSelectImageModal: false,
        selectedImageBlobUrl: blobURL,
        selectedImageFile: file,
        showLoaderForImageUpload: true,
      });
    }

    await computeChecksumMd5(file).then((md5: any) => {
      const fileDetails = {
        fileName: file.name,
        fileSize: file.size,
        checksum: md5,
        content_type: file.type,
      };
      this.getPhotoPreSignedId(fileDetails);
    });
  };

  getPhotoPreSignedId = (photoDetail: any) => {
    const header = {
      token: localStorage.getItem("token"),
    };

    let formData = new FormData();
    formData.append("content_type", photoDetail.content_type);

    const httpBody = {
      filename: photoDetail.fileName,
      byte_size: photoDetail.fileSize,
      checksum: photoDetail.checksum,
      content_type: photoDetail.content_type,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );

    this.apiPhotoPreSignedID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPreSignedUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  savePhotoUsingURL = async (preSignedResponse: any) => {
    const uploadURL = preSignedResponse.url.replace(/\/+$/, "");
    const urlFields = preSignedResponse.url_fields;
    if (uploadURL != null && uploadURL !== undefined) {
      const fileKey = urlFields.key.replace(
        "${filename}",
        this.state.selectedImageFile.name
      );

      const formData = new FormData();
      formData.append("key", fileKey);
      formData.append("success_action_status", urlFields.success_action_status);
      formData.append("Content-Type", urlFields["Content-Type"]);
      formData.append("Content-Disposition", urlFields["Content-Disposition"]);
      formData.append("policy", urlFields.policy);
      formData.append("x-amz-credential", urlFields["x-amz-credential"]);
      formData.append("x-amz-algorithm", urlFields["x-amz-algorithm"]);
      formData.append("x-amz-date", urlFields["x-amz-date"]);
      formData.append("x-amz-signature", urlFields["x-amz-signature"]);
      formData.append("file", this.state.selectedImageFile);
      try {
        const s3Response = await axios({
          method: "post",
          url: uploadURL,
          data: formData,
        });
        if (s3Response.status === 201) {
          const keyValue = JSON.parse(
            convert.xml2json(s3Response.data, {
              spaces: 1,
              compact: true,
              ignoreComment: true,
              alwaysChildren: true,
              ignoreDeclaration: true,
            })
          );
          if (this.state.optionImageSelection) {
            let tempResponseList = this.state.questionOptionList;
            const index = this.state.questionOptionList.findIndex(
              (res: any) => res.charNumber === this.state.selectedOptionNumber
            );
            const data = tempResponseList[index];
            tempResponseList[index] = {
              ...data,
              files: [keyValue.PostResponse.Key._text],
              answer: "",
            };
            this.setState({ questionOptionList: tempResponseList });
          } else {
            this.setState({
              selectedImageSignedId: keyValue.PostResponse.Key._text,
            });
          }
          this.setState({
            showLoaderForImageUpload: false,
            selectedGalleryImageId: 0,
          });
        }
      } catch (e) {
        toast.error("Something went wrong.");
      }
    }
  };

  get_chapter_list = () => {
    const assessment_data = localStorage.getItem("assessmentData");
    const assessmentData = JSON.parse(assessment_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getChapterListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chapterEndPoint + `?subject_id=${assessmentData?.subject_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_topic_list = (id?: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTopicListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topicEndPoint + `?chapter_id=${Number(id)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_unit_list = (id?: any, resetUnit?: boolean) => {
    const { chapterListData } = this.state;
    const chapterUnits = chapterListData?.find(
      (chapter: any) => Number(chapter.id) === Number(id)
    );
    this.setState({ unitListData: chapterUnits?.attributes?.units?.data });

    if (resetUnit) {
      this.setState({ selectedUnit: "" });
    }
  };

  getEmptyState = () => ({
    marksError: false,
    questionError: false,
    metaDataErrors: {
      chapter: false,
      unit: false,
      skill: false,
      proficiency: false,
      keywords: false,
      difficultyLevel: false,
      bloomTaxonomy: false,
    },
  });

  validFormData = () => {
    let isValid = true;

    const {
      question,
      marks,
      selectedChapter,
      selectedUnit,
      selectedSkill,
      selectedProficiency,
      selectedDifficultyLevel,
      keywordsList,
      selectedBloomTaxonomy,
      graph_axis_settings_attributes,
      graph_plotting_categories_attributes,
    } = this.state;

    const errors = this.getEmptyState().metaDataErrors;
    let questionErr = this.getEmptyState().questionError;
    let marksErr = this.getEmptyState().marksError;

    if (marks === null || Number(marks) === 0) {
      this.setState({
        marksError: true,
        marksErrorText: "Please enter marks.",
      });
      isValid = false;
    }
    if (this.state.isEdit) {
      if (
        this.state.marks >
        this.state.sectiondata?.remaining_marks +
        HISTORY.location.state?.questionData?.attributes?.marks
      ) {
        this.setState({
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
        isValid = false;
      }
    } else {
      if (
        this.state.marks > this.state.sectiondata?.remaining_marks
      ) {
        this.setState({
          marksError: true,
          marksErrorText: "Marks not greater than section marks.",
        });
        isValid = false;
      }
    }

    if (this.state.negativeMarks > this.state.marks) {
      this.setState({ negmarksError: true });
      isValid = false;
    }
    if (question === null || question === "") {
      questionErr = true;
      isValid = false;
    }

    if (selectedChapter === null || selectedChapter === "") {
      errors.chapter = true;
      isValid = false;
    }

    if (selectedUnit === null || selectedUnit === "") {
      errors.unit = true;
      isValid = false;
    }

    if (selectedSkill === null || selectedSkill === "") {
      errors.skill = true;
      isValid = false;
    }

    if (selectedProficiency === null || selectedProficiency === "") {
      errors.proficiency = true;
      isValid = false;
    }

    if (selectedDifficultyLevel === null || selectedDifficultyLevel === "") {
      errors.difficultyLevel = true;
      isValid = false;
    }

    if (keywordsList.length === 0) {
      errors.keywords = true;
      isValid = false;
    }

    if (selectedBloomTaxonomy === null || selectedBloomTaxonomy === "") {
      errors.bloomTaxonomy = true;
      isValid = false;
    }


    if (graph_axis_settings_attributes.x_axis_label === '') {
      toast('Please add label for X Axis')
      isValid = false;
    }
    if (graph_axis_settings_attributes.y_axis_label === '') {
      toast('Please add label for X Axis')
      isValid = false;
    }
    if (Number(graph_axis_settings_attributes.minimum_value) > Number(graph_axis_settings_attributes.maximum_value)) {
      toast('Minumum value cannot be greater than maximum value')
      isValid = false;
    }

    if (Number(graph_axis_settings_attributes.step_size) > Number(graph_axis_settings_attributes.maximum_value) || Number(graph_axis_settings_attributes.step_size) < 0) {
      toast('Please Enter valid step size')
      isValid = false;
    }

    if (graph_plotting_categories_attributes.length == 0) {
      toast('Please add atleast one category to graph');
      isValid = false;
    }

    {
      graph_plotting_categories_attributes.length > 0 && graph_plotting_categories_attributes.map((item: any) => {
        if (item.label.trim() === '') {
          toast('Category label cannot be empty');
          isValid = false;
        }
        if (item.initial_value == '') {
          toast('Category initial value cannot be empty');
          isValid = false;
        }
        if (item.correct_answer_value == '') {
          toast('Answer value cannot be empty');
          isValid = false;
        }

        // Initial Value validation start
        if (item.initial_value > Number(this.state.graph_axis_settings_attributes.maximum_value)) {
          toast(`Initial value is greater than ${this.state.graph_axis_settings_attributes.maximum_value}`);
          isValid = false;
          item.error.initial_value_error = true;
        } else if (item.initial_value < Number(this.state.graph_axis_settings_attributes.minimum_value)) {
          toast(`Initial value is less than ${this.state.graph_axis_settings_attributes.minimum_value}`);
          isValid = false;
          item.error.initial_value_error = true;
        } else {
          item.error.initial_value_error = false;
        }
        // Initial Value validation end

        // Correct Answer value validation start
        if (item.correct_answer_value > Number(this.state.graph_axis_settings_attributes.maximum_value)) {
          toast(`Correct Ans value is greater than ${this.state.graph_axis_settings_attributes.maximum_value}`);
          isValid = false;
          item.error.correct_answer_value_error = true;
        } else if (item.correct_answer_value < Number(this.state.graph_axis_settings_attributes.minimum_value)) {
          toast(`Correct Ans value is less than ${this.state.graph_axis_settings_attributes.minimum_value}`);
          isValid = false;
          item.error.correct_answer_value_error = true;
        } else {
          item.error.correct_answer_value_error = false;
        }
        // Correct Answer value validation end
      })
    }

    this.setState({ metaDataErrors: errors, questionError: questionErr }); // removed marksError: marksErr

    return isValid;
  };

  saveQuestion = () => {
    if (this.validFormData()) {
      this.saveQuestionApi();
    }
  };

  saveQuestionApi = () => {
    let final_hint_answer: any = [];
    let final_option_list: any = [];

    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id,
    };

    const {
      isEdit,
      marks,
      negativeMarks,
      multipleResponse,
      hintId,
      mainGalleryImageId,
      explanationId,
      questionId,
      wordLimit,
      questionOptionList,
      hintAnswer,
      question,
      selectedChapter,
      selectedUnit,
      selectedSkill,
      selectedProficiency,
      selectedDifficultyLevel,
      keywordsList,
      selectedImageSignedId,
      explaination,
      solutionImageSignedId,
      selectedCategoryQuestionData,
      assessmentSectionId,
      selectedGalleryImageId,
      selectedBloomTaxonomy,
      graph_axis_settings_attributes,
      graph_plotting_categories_attributes,
      graph_properties,
      correct_answer_id,
      main_graph_object_id,
      deletedGraphCategories
    } = this.state;
    hintAnswer?.map((data: any) => {
      final_hint_answer.push({
        id: data.id,
        files: data.files ? data.files : [],
        hint: data.hint,
      });
    });

    let graphCategories: any = [];
    let graphAnswer: any = [];
    graph_plotting_categories_attributes.forEach((data: any) => {
      if (isEdit) {
        graphCategories.push({
          id: data.category_id,
          uniq_key: data.uniq_key,
          label: data.label,
          initial_value: data.initial_value,
        });
      } else {
        graphCategories.push({
          uniq_key: data.uniq_key,
          label: data.label,
          initial_value: data.initial_value,
        });
      }
      graphAnswer.push({
        'uniq_key': data.uniq_key,
        'right_answer': data.correct_answer_value
      })
    });

    if (deletedGraphCategories.length > 0 && isEdit) {
      graphCategories.push(...deletedGraphCategories);
    }

    let graph_setting: any = {
      show_grid: graph_properties.show_grid,
      multicolor_bar: graph_properties.show_multicolor_bar,
      display_position: graph_properties.display_position_on_hover,
      graph_axis_settings_attributes: [
        {
          axis_label: graph_axis_settings_attributes.x_axis_label,
          axis_type: "x_axis"
        },
        {
          axis_label: graph_axis_settings_attributes.y_axis_label,
          minimum_value: graph_axis_settings_attributes.minimum_value,
          maximum_value: graph_axis_settings_attributes.maximum_value,
          step_size: graph_axis_settings_attributes.step_size,
          axis_type: "y_axis"
        }
      ],
      graph_plotting_categories_attributes: graphCategories
    }
    if (isEdit) {
      graph_setting.id = main_graph_object_id;
      graph_setting.graph_axis_settings_attributes[0].id = graph_axis_settings_attributes.x_axis_id;
      graph_setting.graph_axis_settings_attributes[1].id = graph_axis_settings_attributes.y_axis_id;

    }

    if (isEdit) {
      final_option_list.push({
        id: correct_answer_id,
        right_answer: true,
        correct_chart_answers: graphAnswer
      })
    } else {
      final_option_list.push({
        right_answer: true,
        correct_chart_answers: graphAnswer
      })
    }


    if (graphAnswer.length === 0) {
      this.setState({
        alertMessage: "Answer can't blank",
        alertModal: true,
      });
      return false;
    }

    const assessmentData = JSON.parse(localStorage.getItem("assessmentData") || "{}");
    const httpBody: any = {
      subject_id: assessmentData.subject_id,
      grade_id: assessmentData.grade_id,
      marks: marks,
      word_limit: Number(wordLimit),
      title: question,
      question_sub_category_id: selectedCategoryQuestionData.id,
      negative_marks: negativeMarks,
      is_negative_marking: negativeMarks > 0 ? true : false,
      assessment_section_id: assessmentSectionId,
      multiple_response: multipleResponse,
      solutions_attributes: [
        {
          id: explanationId,
          explaination: explaination,
        },
      ],
      hints_attributes: final_hint_answer,
      answers_attributes: final_option_list,
      metadata_attributes: {
        chapter_id: selectedChapter,
        unit_id: selectedUnit,
        skill: selectedSkill,
        proficiency: selectedProficiency,
        difficulty_level: selectedDifficultyLevel,
        bloom_taxonomy: selectedBloomTaxonomy,
        keywords: keywordsList,
      },
      graph_plotting_setting_attributes: graph_setting
    };
    if (
      this.state.isEdit &&
      HISTORY.location.state?.questionData?.attributes?.solutions?.data?.length != 0
    ) {
      // remove attachment when no new image selected
      if (
        this.state.explainationAttachmentUploadId &&
        solutionImageSignedId === null &&
        this.state.attachmentExplanationUrl === ""
      ) {
        this.removeExplainationAttachment(
          this.state.explainationAttachmentUploadId
        );
      } else {
        // insert new image if initialy no image uploaded
        if (
          (this.state.explainationAttachmentUploadId === undefined ||
            this.state.explainationAttachmentUploadId === "") &&
          solutionImageSignedId !== null
        ) {
          httpBody.solutions_attributes[0].files =
            solutionImageSignedId === "" || solutionImageSignedId === null
              ? []
              : [solutionImageSignedId];
        }
        // Update Image
        else {
          httpBody.solutions_attributes[0].files = [solutionImageSignedId];
          httpBody.solutions_attributes[0].old_upload_id =
            HISTORY.location.state?.questionData?.attributes?.solutions?.data?.[0]?.attributes?.attachments?.data[0]?.id;
        }
      }
    } else {
      httpBody.solutions_attributes[0].files =
        solutionImageSignedId === "" || solutionImageSignedId === null
          ? []
          : [solutionImageSignedId];
    }
    if (
      this.state.isEdit &&
      HISTORY.location.state?.questionData?.attributes?.hints
        ?.data?.length != 0
    ) {
      // remove attachment when no new image selected
      if (
        this.state.hintsAttachmentUploadId &&
        hintId === null &&
        this.state.hintsExplanationUrl === ""
      ) {
        this.removeExplainationAttachment(this.state.hintsAttachmentUploadId);
      } else {
        // insert new image if initialy no image uploaded
        if (
          (this.state.hintsAttachmentUploadId === undefined ||
            this.state.hintsAttachmentUploadId === "") &&
          hintId !== null
        ) {
          httpBody.hints_attributes[0].files =
            hintId === "" || hintId === null ? [] : [hintId];
        }
        // Update Image
        else if (hintId && this.state.hintsAttachmentUploadId) {
          httpBody.hints_attributes[0].files = [hintId];
          httpBody.hints_attributes[0].old_upload_id = this.state.hintsAttachmentUploadId
        }
      }
    } else {
      httpBody.hints_attributes[0].files =
        hintId === "" || hintId === null ? [] : [hintId];
    }
    if (mainGalleryImageId) {
      if (HISTORY.location.state?.questionData?.attributes?.attachments?.data?.length > 0) {
        const ImageIndex = HISTORY.location.state?.questionData?.attributes?.attachments?.data?.length - 1;
        httpBody.old_upload_id = HISTORY.location.state?.questionData?.attributes?.attachments?.data?.[ImageIndex]?.id;
      }
      httpBody.upload_id = mainGalleryImageId;
    } else {
      const ImageIndex =
        HISTORY.location.state?.questionData?.attributes?.attachments?.data
          ?.length - 1;
      if (
        this.state.isEdit && selectedImageSignedId &&
        HISTORY.location.state?.questionData?.attributes?.attachments?.data
          ?.length != 0
      ) {
        httpBody.files = [selectedImageSignedId];
        httpBody.old_upload_id =
          HISTORY.location.state?.questionData?.attributes?.attachments?.data?.[
            ImageIndex
          ]?.id;
      } else if (selectedImageSignedId) {
        httpBody.files =
          selectedImageSignedId === "" || selectedImageSignedId === null
            ? []
            : [selectedImageSignedId];
      }
    }
    if ((final_hint_answer[0].files && final_hint_answer[0].files == 0) && final_hint_answer[0].hint == '') {
      delete httpBody.hints_attributes
    }
    this.setState({ showLoader: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSaveQuesionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      isEdit
        ? configJSON.saveQuestionEndPoint + `/${questionId}`
        : configJSON.saveQuestionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      isEdit ? configJSON.examplePUTAPiMethod : configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeExplainationAttachment = (id: any) => {
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteSolutionAttachment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.galleryImagesEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
