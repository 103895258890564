// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    data: any;
    uploadClass: boolean;
    addClass: boolean;
    currentPage: any;
    nextPage: any;
    totalPage: any;
    totalCount: any;
    schoolDivision: any;
    timeLineMeta: any;
    anchorEl: any;
    deleteAlertModal: boolean;
    school_class_id: any;
    dialogOpen: boolean;
    divisionName: any;
    divisionNameError: boolean;
    divisionNameErrorMes: any;
    edit: boolean;
    per_page: number;
    viewId: any;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AdminDivisionController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    getSchoolListId: string = "";
    postSchoolListId:any;
    updateSchoolListId:any;
    child: React.RefObject<any>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.child = React.createRef();
        console.log(this);
        console.log(this.child, "state")
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            data: '',
            uploadClass: false,
            addClass: false,
            currentPage: 0,
            nextPage: null,
            totalPage: 1,
            totalCount: 1,
            schoolDivision: [],
            timeLineMeta: [],
            anchorEl: null,
            deleteAlertModal: false,
            school_class_id: [],
            dialogOpen: false,
            divisionName: '',
            divisionNameError: false,
            divisionNameErrorMes: '',
            edit: false,
            per_page: 10,
            viewId: ''
        };
        this.userdata = this.userdata.bind(this);
        this.get_School_Division = this.get_School_Division.bind(this);
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.get_School_Division();
    }

    onCloseModal = (closeModal: boolean) => {
        this.setState({ uploadClass: closeModal })
    }
    onAddCloseModal = (closeModal: boolean) => {
        this.setState({ addClass: closeModal, anchorEl: null })
    }
    onDeleteCloseModal = (closeModal: boolean) => {
        this.setState({ deleteAlertModal: closeModal, viewId: '', currentPage: 0 }, () => this.get_School_Division())

    }
    userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaa", data);
        this.setState({ data: data });
    }

    scrollTop =() =>{
        const el = document.querySelector("main");
        el && el.scrollTo(0, 0);
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.getSchoolListId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        this.scrollTop()
                        this.setState({ schoolDivision: responseJson.data, totalPage: Math.ceil(responseJson.total_count / this.state.per_page) })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }
            if (apiRequestCallId === this.postSchoolListId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        toast.success("Division added successfully.")
                        this.setState({ currentPage:0 , divisionName:'', divisionNameError:false, divisionNameErrorMes:''},()=>{
                            this.get_School_Division()
                            
                        })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                        this.setState({ divisionName:'', divisionNameError:false, divisionNameErrorMes:''})

                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }
            if (apiRequestCallId === this.updateSchoolListId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        toast.success("Division updated successfully.")
                        this.setState({ currentPage:0,divisionName:'', divisionNameError:false, divisionNameErrorMes:''},()=>{
                            this.get_School_Division()
                        })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }


        }
        // Customizable Area End
    }
    // Customizable Area Start
    get_School_Division = () => {
        const school_data = localStorage.getItem("user_data");
      const schoolData = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSchoolListId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDivisionEndPoint + `?page=${parseInt(this.state.currentPage) + 1}&school_id=${schoolData.school_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    post_School_Division = () => {
        const school_data = localStorage.getItem("user_data");
      const schoolData = JSON.parse(school_data || "{}");
        if (
            this.state.divisionName === "" || this.state.divisionName.length === 0) {
            this.setState({ divisionNameError: true, divisionNameErrorMes: 'Division name is required.' });
        }else{
        const data = {
            data: {
                attributes: {
                    school_id: schoolData.school_id,
                    name: this.state.divisionName

                }
            }
        }
        const header = {
            "Content-Type": configJSON.dashboarContentType,

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postSchoolListId = requestMessage.messageId;
        const httpBody = data;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDivisionEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.enrollApiPostMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({dialogOpen: false , edit:false ,viewId:''})
        }
    }
    edit_School_Division = () => {
        const teacherId = localStorage.getItem("id")
        if (
            this.state.divisionName === "" || this.state.divisionName.length === 0) {
            this.setState({ divisionNameError: true, divisionNameErrorMes: 'Division name is required.' });
        }else{
        const data = {
            data: {
                attributes: {
                    
                    name: this.state.divisionName

                }
            }
        }
        const header = {
            "Content-Type": configJSON.dashboarContentType,

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateSchoolListId = requestMessage.messageId;
        const httpBody = data;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDivisionEndPoint + `/${this.state.viewId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.enrollApiPutMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({dialogOpen: false , edit:false ,viewId:'', divisionName:''})
        }
    }
    // Customizable Area End
    

}
