// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  IconButton,
  Menu,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import TeacherAssessmentCreateSectionController, {
  Props,
} from "./TeacherAssessmentCreateSectionController.web";
import Spinner from "../../shared/SideBar/src/Spinner";
import DeleteModal from "./DeleteModal.web";
import {
  imgBackArrow,
  imgEdit,
  imgCopy,
  imgDelete,
  RightEditIcon,
} from "./assets";
import SelectQuestionTypeModal from "./SelectQuestionType.web";
import SectionMenu from "./SectionMenu.web";
import AlertModal from "../../alert/src/AlertModal.web";
import { withStyles } from "@material-ui/core/styles";
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = {
  saveDraftButton: {
    borderRadius: 8,
    border: "solid 1px #3a60d7",
    backgroundColor: "#fff",
    color: "#3f526d",
    padding: "15px 30px",
  },
  nextButton: {
    borderRadius: 8,
    backgroundColor: "#3a60d7",
    padding: "15px 40px",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#3a60d7",
    },
  },
  backScreen: {
    cursor: "pointer",
  },
} as const;
// Customizable Area End

export class TeacherAssessmentCreateSection extends TeacherAssessmentCreateSectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { assessmentDetail, sectionList, selectedDataforMove } = this.state;

    return (
      <>

        <div>
          {this.state.showLoader && (
            <Spinner spinnerModal={this.state.showLoader} />
          )}
          <Grid container className="CreateSectionHeaderDiv">
            <Grid item xs={12} sm={6}>
              <Typography
                className={`CreateSectionBackText ${this.props.classes.bodyText_font}`}
                onClick={this.handleBack}
              >
                <img
                  src={imgBackArrow}
                  className="CreateSectionBackArrow"
                ></img>
                Back
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className="flex_end_portion">
              <Button
                disableRipple={true}
                variant="outlined"
                className={`save_as_draft_btn ${this.props.classes.teacherAssessmentCreateSection_AssessmentTest_outline_button_color}`}
                onClick={this.updateAssessmentSaveAsDraft}
              >
                Save as draft
              </Button>
              <Button
                disableRipple={true}
                variant="outlined"
                className={`next_btn ${this.props.classes.button_color}`}
                onClick={() => {
                  this.handleNext();
                }}
              >
                Next
              </Button>
            </Grid>
          </Grid>
          <Grid container className="CreateSectionHeaderDiv">
            <Grid item xs={12} sm={6}>
              <Typography
                className={`CreateSectionAssessmentName ${this.props.classes.subHeading_font}`}
              >
                {assessmentDetail?.assessment_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className="flex_end_portion">
              <Typography
                className={`CreateSectionTotalTime ${this.props.classes.subHeading_font}`}
              >
                Total Time : {assessmentDetail?.time_limit} Hours
              </Typography>
            </Grid>
          </Grid>

          {assessmentDetail?.assessment_sections_attributes?.map(
            (sectionDetails: any, index: number) => {
              const sectionData = sectionDetails;
              const sectionQuestionsData = sectionDetails?.question_data
              return (
                <div
                  key={index}
                  className="SectionMainDiv"
                >
                  <div className="questions-outer">
                    <Grid container className="SectionDetailDiv">
                      <Grid item xs={12} sm={6}>
                        <Typography
                          className={`SectionName ${this.props.classes.heading_font}`}
                        >
                          {sectionData.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} className="flex_end_portion">
                        <Button
                          disableRipple={true}
                          variant="outlined"
                          className={`total_que_btn ${this.props.classes.teacherAssessmentCreateSection_AssessmentTest_outline_button_no_hover}`}
                        >
                          Total Questions:{" "}
                          {sectionData?.total_questions}
                        </Button>

                        <Button
                          disableRipple={true}
                          variant="outlined"
                          className={`total_marks_btn ${this.props.classes.teacherAssessmentCreateSection_AssessmentTest_outline_button_no_hover}`}
                        >
                          Total Marks: {sectionData?.section_marks}
                        </Button>
                      </Grid>
                    </Grid>

                    <TableContainer className="SectionTableContainer">
                      <Table className="separte-border-collapse">
                        <TableHead className={this.props.classes.main_Primary_Color}>
                          <TableRow>
                            <TableCell className={`SectionDetailQuestion ${this.props.classes.subHeading_font}`}>Questions</TableCell>
                            <TableCell className={`SectionDetails ${this.props.classes.subHeading_font}`}>Type</TableCell>
                            <TableCell className={`SectionDetails ${this.props.classes.subHeading_font}`}>Marks</TableCell>
                            {assessmentDetail?.negative_marking && (
                              <TableCell className={`SectionDetails ${this.props.classes.subHeading_font}`}>Neg. Marks</TableCell>
                            )}
                            <TableCell className="SectionDetails"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="SectionTableBody">
                          {
                            sectionQuestionsData?.length === 0 || sectionQuestionsData === undefined
                              ?
                              <TableRow>
                                <TableCell
                                  colSpan={7}
                                  align="center"
                                  className={`NoQuestionFound ${this.props.classes.bodyText_font}`}
                                >
                                  No record(s) found.
                                </TableCell>
                              </TableRow>
                              :
                              <>
                                {sectionQuestionsData?.map((data: any, index: number) => (
                                  <React.Fragment key={index}>
                                    <TableRow>
                                      <TableCell colSpan={7} className="SectionDataBlankCell"></TableCell>
                                    </TableRow>
                                    <TableRow key={data.id} className={selectedDataforMove.find(
                                      (item: any) => item.id == data.id
                                    )?.checked ? "selectedRow" : "unSelectedRow"}
                                      onContextMenu={(e: any) => {
                                        if (
                                          selectedDataforMove.find(
                                            (item: any) => item.id == data.id
                                          )?.checked
                                        ) {
                                          this.setState({
                                            SectionNameText: sectionData.id,
                                          });

                                          this.handleContextMenu(e);
                                        }
                                      }} onClick={() => {
                                        const datacheck = selectedDataforMove.find(
                                          (item: any) => data.id === item.id
                                        );
                                        if (datacheck) {
                                          let check = selectedDataforMove.findIndex(
                                            (item: any) => item.id === data.id
                                          );
                                          let itemcheck = selectedDataforMove.find(
                                            (item: any) => item.id === data.id
                                          );
                                          const obj = [...selectedDataforMove];
                                          obj[check] = {
                                            ...itemcheck,
                                            checked: !itemcheck.checked,
                                          };
                                          this.setState({
                                            selectedDataforMove: obj,
                                          });
                                        } else {
                                          const temp = {
                                            ...data,
                                            checked: !data.checked,
                                          };
                                          temp.section_id = sectionDetails.id;

                                          this.setState({
                                            selectedDataforMove: [
                                              ...selectedDataforMove,
                                              temp,
                                            ],
                                          });
                                        }
                                      }}>
                                      <TableCell className={`SectionQuestionData ${this.props.classes.bodyText_font}`}>
                                        <div className={`SectionQuestionDataDiv ${this.props.classes.bodyText_font}`}>
                                          <Typography className="QuestionText">
                                            Q{index + 1}.</Typography>
                                          <div className="pb0 QuestionWordBreak"
                                            dangerouslySetInnerHTML={{
                                              __html: data?.title,
                                            }}
                                          />
                                        </div>
                                      </TableCell>
                                      <TableCell className={`SectionQuestionType ${this.props.classes.bodyText_font}`}>
                                        {data?.type?.replaceAll("_", " ")}
                                      </TableCell>
                                      <TableCell className={`SectionQuestionMarks ${this.props.classes.bodyText_font}`}>
                                        {data?.marks <= 0
                                          ? data?.marks
                                          : `+${data?.marks}`}
                                      </TableCell>
                                      {assessmentDetail?.negative_marking && (
                                        <TableCell className={`SectionQuestionNegMarks ${this.props.classes.bodyText_font}`}>
                                          {data.negative_marks <= 0
                                            ? data.negative_marks
                                            : `-${data.negative_marks}`}
                                        </TableCell>)}
                                      <TableCell className={`SectionQuestionMarks ${this.props.classes.bodyText_font}`}>
                                        <div className="SectionQuestionActionDiv">
                                          <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            className="padding-7 QuestionActionsIconBtn"
                                            disabled={
                                              sectionData?.remaining_marks === 0 ||
                                              data?.marks >
                                              sectionData?.remaining_marks ||
                                              sectionData?.total_questions ===
                                              sectionQuestionsData?.length
                                            }
                                            onClick={async (event: any) => {

                                              await this.setState({
                                                selectedQuestion: data,
                                                selectedQuestionSection: sectionData
                                              });
                                              this.copyQuestion();
                                            }}
                                          >
                                            <img
                                              src={imgCopy}
                                              className="QuestionEditCopyImg"
                                            />
                                          </IconButton>

                                          <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            className="padding-7 QuestionActionsIconBtn"
                                            onClick={(event: any) => {
                                              this.setState({
                                                editQuestionData: {
                                                  questionData: data,
                                                  sectiondata: sectionData,
                                                }
                                              }, () => this.getQuestionDetails(data?.id))
                                            }}
                                          >
                                            <img
                                              src={imgEdit}
                                              className="QuestionEditCopyImg"
                                            />
                                          </IconButton>

                                          <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            className="padding-7 QuestionActionsIconBtn"
                                            onClick={() =>
                                              this.setState({
                                                openDeleteQuestionModal: true,
                                                selectedQuestion: data,
                                                selectedQuestionSection: sectionData,
                                              })
                                            }
                                          >
                                            <img
                                              id={`question-id-${data.id}`}
                                              src={imgDelete}
                                              className="QuestionDeleteImg"
                                              onMouseEnter={() => {
                                                const layerElement = document.getElementById(
                                                  `question-id-${data.id}`
                                                );
                                                if (layerElement) {
                                                  layerElement.style.opacity = "1";
                                                }
                                              }}
                                              onMouseLeave={() => {
                                                const layerElement = document.getElementById(
                                                  `question-id-${data.id}`
                                                );
                                                if (layerElement) {
                                                  layerElement.style.opacity = "0.3";
                                                }
                                              }}
                                            />
                                          </IconButton>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                ))
                                }
                              </>
                          }
                        </TableBody >
                      </Table >
                    </TableContainer >
                  </div >

                  <div
                    className={`SectionBtnDiv ${this.props.classes.teacherAssessmentCreateSection_AssessmentTest_outline_button_disabled}`}
                  >
                    <Button
                      disableRipple={true}
                      variant="outlined"
                      className={`import_from_library_btn ${this.props.classes.teacherAssessmentCreateSection_AssessmentTest_outline_button_color}`}
                      style={{
                        opacity:
                          sectionData?.remaining_marks === 0 ||
                            sectionData?.total_questions ===
                            sectionQuestionsData?.length
                            ? 0.5
                            : 1,
                      }}
                      disabled={
                        sectionData?.remaining_marks === 0 ||
                        sectionData?.total_questions ===
                        sectionQuestionsData?.length
                      }
                      onClick={() => {
                        this.setState({
                          sectiondata: sectionData,
                          selectedSectionId: sectionData?.id,
                        }, () => {
                          const assessmentData = localStorage.getItem("assessmentData");
                          const data = JSON.parse(assessmentData || "{}");
                          this.handleGoToLibrary(data);
                        })
                      }}
                    >
                      Import from Library
                    </Button>

                    <Button
                      disableRipple={true}
                      variant="outlined"
                      className={sectionData?.remaining_marks === 0 ||
                        sectionData?.total_questions ===
                        sectionQuestionsData?.length
                        ? `create_question_btn ${this.props.classes.teacherAssessmentCreateSection_AssessmentTest_outline_button_color}` : `create_question_btn ${this.props.classes.button_color}`}

                      style={{
                        opacity:
                          sectionData?.remaining_marks === 0 ||
                            sectionData?.total_questions ===
                            sectionQuestionsData?.length
                            ? 0.5
                            : 1,
                      }}
                      disabled={
                        sectionData?.remaining_marks === 0 ||
                        sectionData?.total_questions ===
                        sectionQuestionsData?.length
                      }
                      onClick={() =>
                        this.setState({
                          sectiondata: sectionData,
                          selectedSectionId: sectionData?.id,
                          openSelectQuestionTypeModal: true,
                        })
                      }
                    >
                      Create Question
                    </Button>
                  </div>
                </div >
              )
            }
          )}
        </div >
        <Menu
          open={this.state.contextMenu !== null}
          onClose={this.handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            this.state.contextMenu !== null
              ? {
                top: this.state.contextMenu.mouseY,
                left: this.state.contextMenu.mouseX,
              }
              : undefined
          }
        >
          <Box className="MoveQuestionDiv">
            <Box
              display="flex"
              alignItems="center"
              className="MoveQuestionCursor"
              onClick={this.handleSectionMenu}
            >
              <Typography className={`MoveQuestionsText ${this.props.classes.bodyText_font}`}>
                Move Questions To
              </Typography>
              <img
                src={RightEditIcon}
                className="MoveRightArrow"
              />
            </Box>
          </Box>
        </Menu>

        <SectionMenu
          sectionList={
            assessmentDetail?.assessment_sections_attributes
              ? assessmentDetail?.assessment_sections_attributes
              : []
          }
          sendTargetSection={(targetSectionId: any, currentSectionId: any) => {
            this.handleGetTargetSectionToAddQuestion(targetSectionId, currentSectionId);
          }}
          disabledItem={this.state.SectionNameText}
          selectedQuestions={this.state.selectedDataforMove}
          anchorEl={this.state.openSectionMenu}
          setAnchorEl={this.handleSectionMenuClose}
          classes={this.props.classes}
        />

        <DeleteModal
          title="Delete Question"
          deleteModal={this.state.openDeleteQuestionModal}
          onCloseModal={() => {
            this.setState({
              openDeleteQuestionModal: false,
              selectedQuestion: null,
              selectedQuestionSection: null,
            });
          }}
          deleteClick={() => this.deleteQuestionFromStorage()}
        />

        <SelectQuestionTypeModal
          openSelectQuestionTypeModal={this.state.openSelectQuestionTypeModal}
          onCloseModal={() => {
            this.setState({ openSelectQuestionTypeModal: false });
          }}
          onSelectQuestionType={this.onSelectQuestionType}
          questionsTypeList={this.state.questionsTypeList}
          theme={this.props.classes}
        />
        <AlertModal
          alertModal={this.state.alertModal}
          isTeacher={true}
          onCloseModal={() => {
            this.setState({ alertModal: false });
          }}
          alertTitle="Alert!"
          alertMessage={this.state.alertMessage}
          buttonText="OK"
        />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(TeacherAssessmentCreateSection as React.ComponentType<any>);
// Customizable Area End
