//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
// Customizable Area Start
import React from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

import {
  img21kSchholLogo,
  imgloginScreenBackground,
  manImage,
  bigCloud,
  smallCloud,
} from "./assets";
import NewPasswordAdminController, { Props } from "./NewPasswordAdminController.web";
import NewPasswordController from "./NewPasswordController.web";
// Customizable Area End
// Customizable Area Start
 const root={
      flexGrow: 1,
      overflowX:"hidden",
    }as const;
    const loginImageDiv={
      position: "relative",
      height:"100vh",
    }as const;
    const halfcircleImage={
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(142, 123, 253, 1)",
      position: "absolute",
      zIndex: 0,
    }as const;
    const logoImage={
      width: "218px",
      height: "68px",
      position:"relative",
      margin:"20px"
    }as const;
    const textlabel_quoteText={
      color: "rgba(255, 255, 255, 1)",
      fontWeight: "bold",
      position: "relative",
      fontFamily: "Open sans",
      fontSize: "4.5rem",
      margin:'30px 25px'
    }as const;
    const image_studentillustratorImage={
      width: "100%",
      height: "auto",
      bottom:'0%',
      position:"absolute",
      transform: "translateX(33%)",
    }as const;
    const image_cloudsmallImage={
      width: "65px",
      height: "51px",
      position:"absolute",
      top:"61%",
      left:"30%",
    }as const;
    const image_cloudbigImage={
      width: "151px",
      height: "117px",
      bottom: "10%",
      left: "8%",
      position:'absolute',
    }as const;
    const attrbuted_textlabel_div={
      textAlign: "right",
      fontFamily: "Open sans",
      fontSize: "16px",
      marginTop: "10px",
    }as const;
    const attrbuted_textlabel_signinText={
      color: "rgba(3, 1, 76, 1)",
    }as const;
    const attrbuted_textlabel_signinText2={
      color: "rgba(0, 0, 0, 1)",
      fontWeight: "bold",
      marginLeft: "10px",
      marginRight: "5px",
    }as const;
    const loginDiv={
      justifyContent: "center",
      margin: "auto",
      width: "60%",
      paddingLeft: "30px",
      marginTop: "60px",
    }as const;
   const textlabel_welcomeText={
      fontSize: "35.1px",
      fontWeight:"bold",
      marginTop:"10px",
      fontFamily: "Open sans",
    }as const;
    const textlabel_followinstructionText={
      color: "#03014c",
      fontSize: "12.5px",
      fontFamily: "Open sans",
    }as const;
    const textlabel_loginText={
      fontWeight: "bold",
      color: "rgba(255, 255, 255, 1)",
      marginTop: "20px",
      width: "100%",
      backgroundColor: "rgba(142, 123, 253, 1)",
      padding: "10px",
    }as const;
// Customizable Area End

export default class SucessfullPasswordAdminChange extends NewPasswordAdminController {
  
  // Customizable Area Start
  // Customizable Area End

 constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return (
     <div style={root}>
      <Grid container spacing={0}>
        <Grid xs={12} sm={6} spacing={0} style={loginImageDiv}>
          <img
            src={imgloginScreenBackground}
            style={halfcircleImage}
            alt="Background Image"
          />
          <img
            src={manImage}
            alt="Student Illustrator Image"
            style={image_studentillustratorImage}
          />
          <div>
          <img
              src={img21kSchholLogo}
              alt="Logo Image"
              style={logoImage}
          />
          </div>
          <div style={textlabel_quoteText}>
              Let’s learn
              <br />
              Together
          </div>
          <img
              src={smallCloud}
              alt="Small Cloud Image"
              style={image_cloudsmallImage}
          />
          <img
              src={bigCloud}
              alt="Big Cloud Image"
              style={image_cloudbigImage}
            />
        </Grid>
        <Grid xs={12} sm={6} spacing={0}>
          <div>
            <div style={attrbuted_textlabel_div}>
              <span style={attrbuted_textlabel_signinText}>
                Remember Password ?
              </span>
              <Link href="/EmailAccountLoginBlock" style={attrbuted_textlabel_signinText2}>
                Login
              </Link>
            </div>
            <div style={loginDiv}>
              <div>
                <div style={textlabel_welcomeText}>Congrats! Password Changed</div>
                <span style={textlabel_followinstructionText}>
                  Your password has been changed successfully
                </span>
              </div>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={textlabel_loginText}
                onClick={this.backtologinhandle}
              >
                Go Back To Login
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End