// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  navigation?: any;
  dropDownAnchorEl?: any;
  closeModalFun?: any;
  data_Type: string;
  subjectData?: any;
  datasaveModalFun?: any;
  optionalSubjectData?: any;
  editData?: boolean;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  data: any;
  closeModal: boolean;
  sucessFullModal: Boolean;
  class_id: any;
  subjectData: any;
  optionalData: any;
  dataType: string;
  queryTerm: string;
  searchData: any;
  tempData: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CommanSelectDivController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetStudentSubjectsId: string = "";
  apigetStudentClasslId: string = "";
  child: React.RefObject<any>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.child = React.createRef();
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      data: "",
      closeModal: false,
      sucessFullModal: false,
      class_id: [],
      subjectData: [],
      optionalData: [],
      dataType: "",
      queryTerm: "",
      searchData: [],
      tempData: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.userdata = this.userdata.bind(this);
    this.get_student_subjects = this.get_student_subjects.bind(this);
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.get_props_data();
  }

  get_props_data = () => {
    this.setState({ dataType: this.props.data_Type });
    this.get_student_subjects();
  };

  modalhandleClose = () => {
    this.setState({ closeModal: true });
    this.props.closeModalFun();
  };
  datasavemodalClose = () => {
    this.setState({ closeModal: true });
    if (this.state.dataType === "subject") {
      this.props.datasaveModalFun(this.state.subjectData);
    } else {
      this.props.datasaveModalFun(this.state.optionalData);
    }
  };
  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
  };
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apigetStudentSubjectsId) {
        console.log("ID Match");
        if (responseJson != null) {
          console.log("JSON NOt NUll");
          if (!responseJson.errors) {
            if (this.props.data_Type === "subject") {
              if (this.props.editData) {
                this.props.subjectData.map((data: any) => {
                  const data2 = responseJson.data.findIndex(
                    (res: any) => res.id === parseInt(data.id)
                  );
                  responseJson.data[data2].checked = !responseJson.data[data2]
                    .checked;
                });
                this.setState({ subjectData: responseJson.data });
              } else {
                this.setState({ subjectData: responseJson.data });
              }
            } else {
              if (this.props.editData) {
                this.props.subjectData.map((data: any) => {
                  const data2 = responseJson.data.findIndex(
                    (res: any) => res.id === parseInt(data.id)
                  );
                  responseJson.data[data2].checked = !responseJson.data[data2]
                    .checked;
                });
                this.setState({ optionalData: responseJson.data });
              } else {
                this.setState({ optionalData: responseJson.data });
              }
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        console.log("responseJson nulll");
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  get_student_subjects = () => {
    const user_data = localStorage.getItem("user_data");
    const school_Data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetStudentSubjectsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectsEndPoint + `?id=${school_Data.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    console.log("REQUEST: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
