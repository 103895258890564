// Customizable Area Start
import React from "react";
import './CustomHeader.web.css';
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
const CustomHeader: React.FC<any> = (props: any) => {

  return <div className='CustomHeaderDiv'>{props.label}</div>;
};
// Customizable Area End

// Customizable Area Start
export default CustomHeader;
// Customizable Area End
