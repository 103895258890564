// Customizable Area Start
import React from "react";
import "./QuestionSidebarCollapse.web.css";
import { ArrowBackIosSharp } from "@material-ui/icons";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from 'axios';
const configJSON = require("../config.js");
import { HISTORY } from "../../../../components/src/comman";
import Spinner from "../../../shared/SideBar/src/Spinner";
// Customizable Area End

function CommonAllQuestion(props: any) {
    // Customizable Area Start
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
    const [showLoader, setShowLoader] = React.useState(false)
    const assessmentData = localStorage.getItem("assessmentData");
    const data = JSON.parse(assessmentData || "{}");

    let toogleDrawerStatus = () => {
        setIsDrawerOpen(true);
    }

    let closeDrawer = () => {
        setIsDrawerOpen(false);
    }

    let edited_question_id = props.editedQuestionData?.id;
    const sectionData = data?.assessment_sections_attributes?.map((section: any) => {
        let data: any = {
            name: section.name,
            section_id: section.id,
            question_data: []
        }
        section.question_data?.map((question: any, index: any) => {
            data.question_data.push({
                questionnumber: index + 1,
                type: question.type,
                question_id: question.id,
                active: edited_question_id == question.id ? true : false
            })
        })
        return data;
    })

    const editQuestion = async (id: any, type: any, section_id:any) => {
        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem("user_data");
        const school_Data = JSON.parse(user_data || "{}");
        setShowLoader(true);

        let edit_question_sec:any;
        const selectedSection = data?.assessment_sections_attributes?.map((section: any) => {
            if(section_id == section.id){
                edit_question_sec = section
            }
            return edit_question_sec;
        })

        try {
            const config:any = {
                baseURL: configJSON.baseUrlAssessment,
                headers: {
                    "Content-Type": configJSON.validationApiContentType,
                    token,
                    school: school_Data.school_id,
                }
            };

            const response = await axios.get(
                `${configJSON.saveQuestionEndPoint}/${id}/get_question`,
                config
            );
            setShowLoader(false);

            let questionDetails = response?.data?.data[0];
            const target = type.toLowerCase();
            console.log({target});

            switch (target) {
                case "long answer":
                    if(window.location.pathname == '/LongAnswerTypeQuestion'){
                        HISTORY.push({
                            pathname: "/LongAnswerTypeQuestion",
                            state: {
                                questionData: questionDetails,
                                sectiondata: edit_question_sec,
                                sectionId: edit_question_sec.id,
                                isEdit: true,
                            }
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                        pathname: "/LongAnswerTypeQuestion",
                        state: {
                            questionData: questionDetails,
                            sectiondata: edit_question_sec,
                            sectionId: edit_question_sec.id,
                            isEdit: true,
                        }
                        });
                    }
                break;
                case "mcq":
                    if(window.location.pathname == '/McqAnswerTypeQuestion'){
                        HISTORY.push({
                            pathname: "/McqAnswerTypeQuestion",
                            state: {
                                questionData: questionDetails,
                                sectiondata: edit_question_sec,
                                sectionId: edit_question_sec.id,
                                isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/McqAnswerTypeQuestion",
                            state: {
                                questionData: questionDetails,
                                sectiondata: edit_question_sec,
                                sectionId: edit_question_sec.id,
                                isEdit: true,
                            },
                        });
                    }
                break;
                case "short answer":
                    if(window.location.pathname == '/ShortAnswerTypeQuestion'){
                        HISTORY.push({
                            pathname: "/ShortAnswerTypeQuestion",
                            state: {
                                questionData: questionDetails,
                                sectiondata: edit_question_sec,
                                sectionId: edit_question_sec.id,
                                isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/ShortAnswerTypeQuestion",
                            state: {
                                questionData: questionDetails,
                                sectiondata: edit_question_sec,
                                sectionId: edit_question_sec.id,
                                isEdit: true,
                            },
                        });
                    }
                break;
                case "true false":
                    if(window.location.pathname == '/TrueFalseTypeQuestion'){
                        HISTORY.push({
                            pathname: "/TrueFalseTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/TrueFalseTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "one word":
                    if(window.location.pathname == '/OneWordTypeQuestion'){
                        HISTORY.push({
                            pathname: "/OneWordTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/OneWordTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "fill in blank":
                    if(window.location.pathname == '/FillInTheBlanksTypeQuestion'){
                        HISTORY.push({
                            pathname: "/FillInTheBlanksTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/FillInTheBlanksTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "pictionary":
                    if(window.location.pathname == '/PictionaryTypeQuestion'){
                        HISTORY.push({
                            pathname: "/PictionaryTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/PictionaryTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "match":
                    if(window.location.pathname == '/MatchTypeQuestion'){
                        HISTORY.push({
                            pathname: "/MatchTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/MatchTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "text_rating_scale":
                    if(window.location.pathname == '/TextRatingTypeQuestion'){
                        HISTORY.push({
                            pathname: "/TextRatingTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/TextRatingTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "hotspot":
                    if(window.location.pathname == '/HotspotTypeQuestion'){
                        HISTORY.push({
                            pathname: "/HotspotTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/HotspotTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "audio":
                    if(window.location.pathname == '/AudioTypeQuestion'){
                        HISTORY.push({
                            pathname: "/AudioTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/AudioTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "video":
                    if(window.location.pathname == '/VideoTypeQuestion'){
                        HISTORY.push({
                            pathname: "/VideoTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/VideoTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "line_chart":
                    if(window.location.pathname == '/GraphPlottingLineChart'){
                        HISTORY.push({
                            pathname: "/GraphPlottingLineChart",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/GraphPlottingLineChart",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "graph_plotting":
                    if(window.location.pathname == '/GraphPlottingTypeQuestion'){
                        HISTORY.push({
                            pathname: "/GraphPlottingTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/GraphPlottingTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "sort_list":
                    if(window.location.pathname == '/SortingTypeQuestion'){
                        HISTORY.push({
                            pathname: "/SortingTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/SortingTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "label_image_with_drag_and_drop":
                    if(window.location.pathname == '/LabelImagewithDragAndDrop'){
                        HISTORY.push({
                            pathname: "/LabelImagewithDragAndDrop",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/LabelImagewithDragAndDrop",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "label_image_with_text":
                    if(window.location.pathname == '/LabelImagewithTextInput'){
                        HISTORY.push({
                            pathname: "/LabelImagewithTextInput",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/LabelImagewithTextInput",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "label_image_with_dropdown":
                    if(window.location.pathname == '/LabelImagewithDropDown'){
                        HISTORY.push({
                            pathname: "/LabelImagewithDropDown",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/LabelImagewithDropDown",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "2x2_grid":
                    if(window.location.pathname == '/TwoByTwoGridTypeQuestion'){
                        HISTORY.push({
                            pathname: "/TwoByTwoGridTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/TwoByTwoGridTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "percentage_rating_scale":
                    if(window.location.pathname == '/PercentageRatingTypeQuestion'){
                        HISTORY.push({
                            pathname: "/PercentageRatingTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/PercentageRatingTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "flash_card":
                    if(window.location.pathname == '/FlashCardTypeQuestion'){
                        HISTORY.push({
                            pathname: "/FlashCardTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/FlashCardTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "graphic_rating_scale":
                    if(window.location.pathname == '/EmojiRatingTypeQuestion'){
                        HISTORY.push({
                            pathname: "/EmojiRatingTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/EmojiRatingTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                case "numeric_rating_scale":
                    if(window.location.pathname == '/NumericRatingTypeQuestion'){
                        HISTORY.push({
                            pathname: "/NumericRatingTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                        location.reload();
                    }else{
                        HISTORY.push({
                            pathname: "/NumericRatingTypeQuestion",
                            state: {
                              questionData: questionDetails,
                              sectiondata: edit_question_sec,
                              sectionId: edit_question_sec.id,
                              isEdit: true,
                            },
                        });
                    }
                break;
                default:
                break;
            }
        } catch (error) {
          if (error instanceof Error) {
            let message = "An error occured"
            return error.message;
          }
        }
        setShowLoader(false);
        setIsDrawerOpen(false);
    };

    return (
        <>
            {showLoader && (
                <Spinner spinnerModal={showLoader} />
            )}
            <div className="questionListsidebar">
                <div className="hiddenPanel">
                    <div
                        onClick={()=>toogleDrawerStatus()}
                        className={`leftArrow ${props.classes.background_color}`}
                    >
                        <ArrowBackIosSharp />
                    </div>
                </div>

                <Drawer variant="temporary" className="popupToggle" anchor="right" open={isDrawerOpen} onClose={()=>closeDrawer()}>
                    <div className="summary-sidebar">
                        <h5 className="all_questions">All Questions</h5>
                        <div role="presentation" onClick={()=>closeDrawer()} onKeyDown={()=>closeDrawer()}>
                            <div onClick={()=>closeDrawer()} className={`arrowBg ${props.classes.background_color}`}>
                                <ArrowBackIosSharp />
                            </div>
                        </div>
                        {sectionData?.map((section: any, index: any) => {
                            return (
                                <div key={index} className="section-list">
                                    <p className="section_name">{section.name}</p>
                                    <ul>
                                        {section.question_data?.map((question: any, idx: any) => {
                                            return (
                                                <li key={idx}>
                                                    <span onClick={(() => editQuestion(question.question_id, question.type, section.section_id))}
                                                        className={`${question?.active &&
                                                            `activeNumber
                                    ${props.classes.background_color}`} section_number`}
                                                    >
                                                        {question.questionnumber}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </Drawer>

            </div>
        </>
    );
    // Customizable Area End
}

export const themeCreatStyle = (theme: any) => ({
    // Customizable Area Start
    background_color: {
        backgroundColor: theme.palette.primary.main,
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont,
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont,
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont,
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont,
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont,
    },
    input_theme_style: {
        "& input": {
            color: theme.props.textColor,
            fontFamily: theme.props.textFont,
        },
    },
    icon_button_color: {
        color: theme.props.buttonColor,
    },

    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
        "&:hover": {
            backgroundColor: theme.props.buttonColor,
            color: "white",
        },
    },

    outline_button_no_hover: {
        backgroundColor: "white",
        color: theme.props.buttonColor,
        border: `1px solid ${theme.props.buttonColor}`,
    },
    // Customizable Area End
});

// Customizable Area Start
export default withStyles(themeCreatStyle)(CommonAllQuestion);
// Customizable Area End
