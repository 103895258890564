// Customizable Area Start
import React from "react";
import { Typography, Box, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../../../shared/Header/src/HOCAuth.web";
import { NOTES_NO_DATA } from "../../assets";
import ParentNotesController, {
  Props,
} from "./ParentNotesController.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import "./ParentNotes.web.css";
import moment from "moment";
import EditNote from '../../EditNote.web';
import ViewAllNotesModal from '../../ViewAllNotesModal.web';
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class ParentNotes extends ParentNotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { parentNotes, loader } = this.state;
    return (
      <>
        {loader && <Spinner spinnerModal={loader} />}
        <Grid className="notes-parent-box" container>
          <Grid item xs={12}>
            {parentNotes?.length > 0 ? (
              parentNotes?.map(
                (parentNote: any, index: any) =>
                {
                  let colorStyle:any = {
                    backgroundColor: '#ffeeca'
                  }
                  if(index === 1){
                    colorStyle.backgroundColor = '#caf2ff';
                  }else if(index === 2){
                    colorStyle.backgroundColor = '#e6e2ff';
                  }

                  return index < 3 && 
                    <React.Fragment key={parentNote.id}>
                      <Box
                        className={`notes-note-item-box`}
                        style={colorStyle}
                        onClick={() => {
                          this.setState({
                            notes_id: parentNote.id,
                            title:parentNote.attributes.title,
                            description:parentNote.attributes.description
                          }, () => {
                            this.setState({
                              editNoteModal:true
                            })
                          });
                        }}
                      >
                        <Box
                          className={`notes-flex-space-between`}
                        >
                          <Typography
                            className={`${this.props.classes.subHeading_font} notes-text-capitalize`}
                          >
                            {parentNote.attributes.title}
                          </Typography>
                          <Typography
                            className={`${this.props.classes.parentNotes_dashboard_font_12} ${this.props.classes.subTitle_font}`}
                          >
                            {moment(parentNote.attributes.updated_at).format(
                              'DD MMM,YYYY'
                            )}
                          </Typography>
                        </Box>

                        <Typography
                          className={`${this.props.classes.parentNotes_dashboard_font_14} ${this.props.classes.bodyText_font} notes-text-capitalize margin-top-14`}
                        >
                          {parentNote.attributes.description}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  }
              )
            ) : (
              <>
                <div
                  className={`notes-empty-img-div`}
                >
                  <img
                    className={`notes-empty-img`}
                    src={NOTES_NO_DATA}
                    alt="No data found"
                  />
                </div>
                <div
                  className={`notes-empty-text-div`}
                  style={{
                  }}
                >
                  <span
                    className={`notes-empty-text ${this.props.classes.subHeading_font}`}
                  >
                    No Notes!
                  </span>
                </div>
              </>
            )}
          </Grid>
          {parentNotes?.length > 3 && (
            <Grid xs={12} className={`notes-flex-center margin-top-14`} >
              <Button
                className={`${this.props.classes.outline_button} notes-view-all-btn`}
                variant="outlined"
                onClick={() => {
                  this.setState({ allNotesModal: !this.state.allNotesModal });
                }}
              >
                <Typography
                  className={`${this.props.classes.parentNotes_dashboard_font_14} ${this.props.classes.subHeading_font} text-transform-none`}
                >
                  View All
                </Typography>
              </Button>
            </Grid>
          )}
          {this.state.allNotesModal && (
            <>
              <ViewAllNotesModal
                allNotesModal={this.state.allNotesModal}
                closeModalFun={this.onCloseNotesModal}
                role="parent"
              />
            </>
          )}
          {this.state.editNoteModal && (
            <EditNote
              notes_id={this.state.notes_id}
              heading={this.state.title}
              description={this.state.description}
              openModal={this.state.editNoteModal}
              closeModalFun={this.onCloseNotesModal}
              role="parent"
            />
          )}
        </Grid>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(ParentNotes, "TeacherAccount")
);
// Customizable Area End
