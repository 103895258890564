// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoader: boolean;
    schoolData: any;
    selectclass: any;
    selectedDate: any;
    schoolLogoUrl: string;
    termListData: any;
    classTitle: string;
    data: any;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    // Customizable Area End
 }

export default class ProfileDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getProfileSchoolDetailsId: string = "";
    getSchoolTermListId: string = '';
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

        this.state = {
            isLoader: false,
            schoolData: null,
            selectclass: '',
            selectedDate: new Date(),
            schoolLogoUrl: '',
            termListData: [],
            classTitle: '',
            data: null,

        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.get_student_profile_Deatils = this.get_student_profile_Deatils.bind(this);
        this.get_School_Term_Data = this.get_School_Term_Data.bind(this);
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount()
        this.get_student_profile_Deatils();
        if (HISTORY.location.state?.selectedClassData) {
            this.get_School_Term_Data(
                HISTORY.location.state?.
                selectedClassData.attributes.grade[0].id);
            this.setState({
                classTitle: HISTORY.location.state?.selectedClassData.attributes.title,
                data: HISTORY.location.state?.selectedClassData
            })
        }
    }
    // Customizable Area End
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.getProfileSchoolDetailsId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (responseJson.data != null) {
                            const filterData = responseJson.data.attributes.logos.find((res: any) => res.name === 'print_logo')
                            this.setState({
                                schoolLogoUrl: filterData?.logo_file?.data[0]?.attributes?.url,
                                schoolData: responseJson.data,
                                isLoader: false
                            })
                        }
                    } else {
                        this.parseApiErrorResponse(responseJson);
                        this.setState({ isLoader: false });
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ isLoader: false });
            }
            else if (apiRequestCallId === this.getSchoolTermListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        if (responseJson.terms != null) {
                            this.setState({
                                termListData: responseJson.terms,
                            })
                        }
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    get_student_profile_Deatils = () => {
        this.setState({ isLoader: true });
        let report_card_id = ''
        const user_data = localStorage.getItem("user_data");
        const school_Data = JSON.parse(user_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("token"),
            school: school_Data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProfileSchoolDetailsId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getSchoolDataEndPoint + `${school_Data.school_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    get_School_Term_Data = (grade_id: any) => {
        this.setState({ isLoader: true });
        const user_data = localStorage.getItem("user_data");
        const school_Data = JSON.parse(user_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("token"),
            school: school_Data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSchoolTermListId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTermListEndPoint + `?school_id=${school_Data.school_id}&grade_id=${grade_id}&per=100`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}
