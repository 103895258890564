// Customizable Area Start
import React from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  Menu,
  MenuItem,
  Divider,
} from "@material-ui/core";
import {
  addPhotoIcon,
  lockImage,
  linkedinIcon,
  badgeStar,
  pcTeacher,
  mobileTeacher,
  delete_outline_black,
  file_upload_black,
  photo_camera_black,
  unLockImage,
} from "./assets";
import PersonalInfo from "./TeacherPersonalInfo.web";
import EducationalInfo from "./TeacherEducationalInfo.web";
import TeacherNotification from "./TeacherNotifications.web";
import TeacherProfileController, {
  Props,
} from "./TeacherProfileController.web";
import moment from "moment";
import TeacherDocument from "./TeacherDocument.web";
import { withStyles } from "@material-ui/core/styles";
import DeleteProfilePictureModal from "./DeleteProfilePictureModal.web";
import TakeProfilePictureModal from "./TakeProfilePictureModal.web";
import BadgesModal from "./BadgesModal.web";
import Spinner from "../../shared/SideBar/src/Spinner";
import AlertModal from "../../alert/src/AlertModal.web";
import { HISTORY } from "../../../components/src/comman";
import "./TeacherProfile.web.css";
import HOCAuth from "../../shared/Header/src/HOCAuth.web";
import ShareIcon from '@material-ui/icons/Share';
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const menuIcon = {
  width: "24px",
  height: "24px",
  objectFit: "contain",
} as const;
// Customizable Area End
export class TeacherProfile extends TeacherProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // TODO: Need to use react route, as currently reloads full page
  viewRecentActivities = () => {
    HISTORY.push(`\RecentActivity`);
  };

  render() {
    const {
      teacherPersonalDetails,
      badgesDetails,
      recentActivities,
      teacherDocuments,
      classes,
      data,
      isLockProfile,
    } = this.state;
    const open = Boolean(this.state.addPhotoAnchorEl);
    const batchYear = classes.find(
      (item: any) =>
        item.attributes.class_name ===
        teacherPersonalDetails?.attributes?.admission_class
    )?.attributes.end_date;
    return (
      <>
        {this.state.showSpinner && (
          <Spinner spinnerModal={this.state.showSpinner} />
        )}
        <Grid container className="profile_main_div" spacing={4}>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Typography className={`${this.props.classes.pageTitle} ${this.props.classes.title_font}`}>
              My Profile
            </Typography>

            <Grid className="profile_detail_div">
              <Box className="ProfileImgDiv">
                {data?.avatar ? (
                  <img src={data?.avatar} className="profile_image" />
                ) : (
                  <Avatar src={"/"} className="profile_avatar" />
                )}
                <img
                  className="add_photo_icon"
                  onClick={(event) => this.handleAddPhotoModalClick(event)}
                  src={addPhotoIcon}
                />
              </Box>

              <Menu
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                anchorEl={this.state.addPhotoAnchorEl}
                open={open}
                onClose={this.handleAddPhotoModalClose}
                getContentAnchorEl={null}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <Box className="ProfilePhotoBox">
                  <MenuItem
                    className="MenuItemProfilePhoto"
                    onClick={this.showOpenPhotoDlg}
                  >
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={4}
                        className="MenuItemProfileImg"
                      >
                        <Avatar
                          src={file_upload_black}
                          variant="square"
                          style={menuIcon}
                        />
                      </Grid>
                      <Grid item xs={8} className="MenuItemProfileGrid">
                        <input
                          ref={this.inputOpenPhotoRef}
                          className="ProfileHiddenInput"
                          type="file"
                          accept="image/png, image/jpg, image/jpeg,"
                          onChange={(e) => this.handlePhotoUpload(e)}
                        />
                        <Typography
                          className={`MenuItemProfileText ${this.props.classes.bodyText_font}`}
                        >
                          Upload photo
                        </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    className="MenuItemProfilePhoto"
                    onClick={() => {
                      this.setState({
                        takePictureModal: true,
                        addPhotoAnchorEl: null,
                      });
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={4}
                        className="MenuItemProfileImg"
                      >
                        <Avatar
                          src={photo_camera_black}
                          variant="square"
                          style={menuIcon}
                        />
                      </Grid>
                      <Grid item xs={8} className="MenuItemProfileGrid">
                        <Typography
                          className={`MenuItemProfileText ${this.props.classes.bodyText_font}`}
                        >
                          Take photo
                        </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    className="MenuItemProfilePhoto"
                    onClick={() => {
                      this.setState({
                        deletePhotoModal: true,
                        addPhotoAnchorEl: null,
                      });
                    }}
                    disabled={!this.state.data.avatar_id}
                  >
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={4}
                        className="MenuItemProfileImg"
                      >
                        <Avatar
                          src={delete_outline_black}
                          variant="square"
                          style={menuIcon}
                        />
                      </Grid>
                      <Grid item xs={8} className="MenuItemProfileGrid">
                        <Typography
                          className={`MenuItemProfileText ${this.props.classes.bodyText_font}`}
                        >
                          Remove photo
                        </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                </Box>
              </Menu>

              <Typography className={`personal_details_name ${this.props.classes.heading_font}`} id="teacherName">
                {teacherPersonalDetails?.attributes?.name}
              </Typography>
              <Box className="other_details_block">
                <Typography
                  component="span"
                  className={`TeacherClassText ${this.props.classes.SubHeading_font}`}
                >
                  Teacher | Class of
                  <Typography
                    component="span"
                    id="teacherAdmissionClass"
                    className={`BatchYearText ${this.props.classes.SubHeading_font}`}
                  >
                    {moment(batchYear ? batchYear : undefined)?.format("YYYY")}{" "}
                  </Typography>
                </Typography>

                {teacherPersonalDetails?.attributes?.preferred_classes?.length >
                  0 && (
                  <Typography className={`class_list ${this.props.classes.bodyText_font}`}>
                    (
                    {teacherPersonalDetails?.attributes?.preferred_classes.join(
                      ", "
                    )}
                    )
                  </Typography>
                )}
                <Typography className={`subject_list ${this.props.classes.bodyText_font}`}>
                  {teacherPersonalDetails?.attributes?.preferred_subjects.join(
                    " | "
                  )}
                </Typography>
              </Box>
              <Box
                className="locked_div"
                onClick={() => {
                  this.setState({ isLockProfile: !isLockProfile });
                }}
                onMouseEnter={() => {
                  this.setState({ hovered: !this.state.hovered });
                }}
                onMouseLeave={() => {
                  this.setState({ hovered: !this.state.hovered });
                }}
              >
                <img
                  src={isLockProfile ? lockImage : unLockImage}
                  className={isLockProfile ? "LockedImg" : "UnlockedImg"}
                />
                <Typography
                  className={`LockedProfileText ${this.props.classes.bodyText_font}`}
                >
                  You’ve {isLockProfile ? "locked" : "unlocked"} your profile
                </Typography>
              </Box>
              <Box className="ProfileLinkedinDiv">
                <img
                  src={linkedinIcon}
                  className="ProfileLinkedinImg"
                  onClick={() => {
                    const text = encodeURIComponent(
                      "Follow JavaScript Jeep form Amazing JavaScript Tutorial"
                    );
                    const url =
                      "https://b21k-mvp-91002-web.b91002.dev.us-east-1.aws.svc.builder.cafe/TeacherProfile";
                    let Shareurl = `https://www.linkedin.com/shareArticle?url=${url}`;
                    window.open(Shareurl, "_blank");
                  }}
                />
              </Box>
            </Grid>
            <Box>
              <Box className="ProfileBadgesBox">
                  <Grid container spacing={4} className="ProfileBadgesHead">
                    <Grid item xs={10} sm={10} md={10} lg={10} xl={11} className="ProfileBadgesHead">
                      <img src={badgeStar} className="badge_img" />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={1}
                    className="BadgesShare"
                    onClick={()=>HISTORY.push("/Badges")}
                    >
                      <ShareIcon  />
                    </Grid>
                  </Grid>

                <Typography className="ProfileMyBadgesText">
                  My Badges
                </Typography>
                <Grid
                  container
                  justify="space-around"
                  spacing={2}
                  alignItems="center"
                  className="badge_scroll_view"
                >
                  {badgesDetails?.length > 0 ? (
                    <>
                      {badgesDetails?.slice(0, 3).map((item: any) => (
                        <Grid
                          item
                          md={4}
                          className="BadgeDetailsDiv"
                        >
                          <img
                            src={item.attributes.badge_attachment}
                            className="BadgeDetailsImg"
                          />
                          <Typography
                            className={`BadgeDetailName ${this.props.classes.bodyText_font}`}
                          >
                            {item.attributes.name}
                          </Typography>
                        </Grid>
                      ))}
                    </>
                  ) : (
                      <Typography
                      className={`BadgeNoRecordText ${this.props.classes.bodyText_font}`}
                    >
                      No record(s) found.
                    </Typography>
                  )}
                </Grid>
                {badgesDetails?.length > 3 && (
                  <>
                    <Divider className="BadgesDetailDivider" />
                    <Button
                      disableRipple={true}
                      className="BadgeViewAllBtn"
                      onClick={this.handleModalClick}
                    >
                      <Typography
                        className={`BadgeViewAllBtnText ${this.props.classes.bodyText_font}`}
                      >
                        View All ({badgesDetails?.length})
                      </Typography>
                    </Button>
                  </>
                )}
              </Box>
            </Box>
            <Box>
              <Box className="RecentLogActivityMainBox">
                <Box className="RecentLogActivityHead">
                  <Typography className={`recent_log_activity ${this.props.classes.heading_font}`}>
                    Recent Log Activity
                  </Typography>
                  {recentActivities?.length > 0 && (
                    <Button
                      disableRipple={true}
                      className="ViewRecentActivitiesBtn"
                      onClick={this.viewRecentActivities}
                    >
                      <Typography
                        className={`ViewAllRecentActivityText ${this.props.classes.bodyText_font}`}
                      >
                        View All
                      </Typography>
                    </Button>
                  )}
                </Box>
                {recentActivities?.length ? (
                  <>
                    {recentActivities?.map((item: any) => (
                      <Box className="RecentActivitiesBox">
                        <img
                          src={
                            item.attributes.operating_system === "window" ||
                            item.attributes.operating_system === "Windows"
                              ? pcTeacher
                              : mobileTeacher
                          }
                          className="ActivityIcon"
                        />
                        <Box className="ActivityDetailsDiv">
                          <Typography
                            className={`ActivityOSName ${this.props.classes.bodyText_font}`}
                          >
                            {item.attributes.operating_system}
                          </Typography>
                          <Typography
                            className={`ActivityDate ${this.props.classes.bodyText_font}`}
                          >
                            {moment(
                              item.attributes.date,
                              "DD-MM-YYYY HH:mm:ss"
                            ).format("DD-MM-YYYY ")}
                            {item.attributes.date.split(" ")[1]}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </>
                ) : (
                  <Box className="RecentActivityNoRecord">
                    <Typography
                      component="span"
                      className={`RecentActivityNoRecordText ${this.props.classes.bodyText_font}`}
                    >
                      No record(s) found.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9}>
            <Box 
              className="ProfileTabsBox"            
              display="flex"
              flexDirection="row"
              height="100%"
            >
              <Tabs
                value={this.state.tabValue}
                onChange={(event, value) =>
                  this.handleTansChange.onChangeTab(value)
                }
                TabIndicatorProps={{
                  className: "TeacherProfileTabIndicator"
                }}
                className="ProfileTabs"
                variant="scrollable"
              >
                <Tab
                  className={`teacher_tab ${this.props.classes.subHeading_font}`}
                  label="Personal Info"
                  id={`simple-tab-${this.state.tabValue}`}
                />
                <Tab
                  className={`teacher_tab ${this.props.classes.subHeading_font}`}
                  label="Educational Info"
                  id={`simple-tab-${this.state.tabValue}`}
                />
                <Tab
                  className={`teacher_tab ${this.props.classes.subHeading_font}`}
                  label="Documents"
                  id={`simple-tab-${this.state.tabValue}`}
                />
                <Tab
                  className={`teacher_tab ${this.props.classes.subHeading_font}`}
                  label="Notifications"
                  id={`simple-tab-${this.state.tabValue}`}
                />
              </Tabs>
            </Box>
            <Box>
              {this.state.tabValue === 0 && (
                <div className="ProfilePersonalInfoDiv">
                  <PersonalInfo />
                </div>
              )}
            </Box>
            <Box>
              {this.state.tabValue === 1 && (
                <div className="ProfilePersonalInfoDiv">
                  <EducationalInfo />
                </div>
              )}
            </Box>
            <Box>
              {this.state.tabValue === 2 && (
                <div className="ProfileTeacherDocumentDiv">
                  <TeacherDocument />
                </div>
              )}
            </Box>
            <Box>
              {this.state.tabValue === 3 && (
                <div className="ProfileTeacherNotificationDiv">
                  <TeacherNotification />
                </div>
              )}
            </Box>
          </Grid>
        </Grid>

        <BadgesModal
          openModal={this.state.openBadgeModal}
          onCloseModal={this.handleModalClick}
          badgesDetails={badgesDetails}
        />

        <DeleteProfilePictureModal
          isTeacher={true}
          deletePhotoModal={this.state.deletePhotoModal}
          onCloseModal={() => {
            this.setState({ deletePhotoModal: false });
          }}
          deleteProfilePhotoClick={this.deleteProfilePhoto}
        />

        <TakeProfilePictureModal
          isTeacher={true}
          takePictureModal={this.state.takePictureModal}
          onCloseModal={() => {
            this.setState({ takePictureModal: false });
          }}
          handleSave={this.handleWebcamPhotoUpload}
          loadingSpinner={this.state.loadingSpinner}
        />
        <AlertModal
          alertModal={this.state.alertModal}
          isTeacher={true}
          onCloseModal={() => {
            this.setState({ alertModal: false });
          }}
          alertTitle="Alert!"
          alertMessage={this.state.alertMessage}
          buttonText="OK"
        />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export const teacherProfileStyles = {
  pageWrapper: {
    backgroundColor: "#a439cb",
    display: "flex",
  },
  mainContentWrapper: {
    backgroundColor: "white",
    width: "90%",
  },
  headerWrapper: {
    display: "flex",
    width: "100%",
    backgroundColor: "#ffffff",
    borderTopLeftRadius: 40,
  },
  bodyWrapper: {
    backgroundColor: "#ffffff",
    display: "flex",
    paddingVertical: 50,
    paddingHorizontal: 35,
    width: "100%",
    marginTop: "2%",
  },
  leftSideWrapper: {
    width: "40%",
    padding: "0px 20px",
    paddingTop: 17,
  },
  rightSideWrapper: {
    maxWidth: "calc(100% - 374px)",
    flexBasis: "calc(100% - 374px)",
    flexGrow: 0,
    flexShrink: 0,
  },
  pageTitle: {
    fontSize: 28,
    fontWeight: 600,
    letterSpacing: 0.12,
  },
  parentBlock: {
    borderRadius: 8,
    boxShadow: "0 8px 27px 0 rgba(242, 246, 252, 0.5)",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#f9d8ff",
    backgroundColor: "#fef8ff",
    display: "flex",
    marginTop: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    width: "100%",
  },
  parentInfo: {
    marginLeft: 10,
  },
  parentInfoTitle: {
    fontSize: 16,
    color: "#141821",
  },
  parentInfoSubtitle: {
    fontSize: 12,
    fontWeight: 600,
    color: "#959393",
  },
};
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)( HOCAuth(
  withStyles(teacherProfileStyles)(TeacherProfile as React.ComponentType<any>),
  "TeacherAccount"
));
// Customizable Area End
