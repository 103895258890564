// Customizable Area Start
import React, { useState } from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { information, information_1 } from "../assets";
import EventToolTip from "./EventToolTip.web";
import './MonthEvent.web.css';
import { ITimeTableEventsProps as StudentTableEvent } from "../StudentTimeTableController.web";
import { ITimeTableEventsProps as TeacherTableEvent } from "../TeacherTimeTableController.web";
// Customizable Area End

// Customizable Area Start
interface IMonthEvent {
  event: StudentTableEvent | TeacherTableEvent;
  isTeacher: boolean;
  joinMeeting: () => void;
  getTimeTableUpdatedData?: any
}
// Customizable Area End

// Customizable Area Start
const MonthEvent: React.FC<IMonthEvent> = ({
  event,
  isTeacher,
  joinMeeting,
  getTimeTableUpdatedData
}: IMonthEvent) => {
  const { title, start, end, image } = event as StudentTableEvent | TeacherTableEvent;

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleClose = (save: boolean) => {
    setAnchorEl(null);
  };
  const savehandleClose = () => {
    setAnchorEl(null);
    getTimeTableUpdatedData()

  };
  const handleModalClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="month-event">
      <div
       className="MonthEventMainDiv"
      >
        <div
          className="MonthEventSubDiv"
        >
          {title}
        </div>
      </div>
      {event.type !== "lunchTime" && (
        <div
          style={{
            width: isTeacher ? "95%" : "",
          }}
          className='MonthEventLunchTimeDiv'
        >
          <Typography
            style={{
              color: isTeacher ? "#000" : "#3f526d",
            }}
            className='MonthEventLunchTimeTxt'
          >
            {moment(start).format(isTeacher ? "HH:mm A" : "HH:mm A")}-
            {moment(end).format(isTeacher ? "HH:mm A" : "HH:mm A")}
          </Typography>
          <img
           className="MonthEventLunchTimeImg"
            src={isTeacher ? information_1 : information}
            onClick={(event: any) => handleModalClick(event)}
          />
        </div>
      )}

      <EventToolTip
        event={event}
        anchorEl={anchorEl}
        handleClose={handleClose}
        savehandleClose={savehandleClose}
        isTeacher={isTeacher}
        joinMeeting={joinMeeting}
      />
    </div>
  );
};
// Customizable Area End

// Customizable Area Start
export default MonthEvent;
// Customizable Area End
