// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import TwoByTwoGridTypeQuestionController, {
  Props,
} from "./TwoByTwoGridTypeQuestionController.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import {
  imageMarksMinusSVG,
  imageMarksPlusSVG,
  imgBackArrowSVG,
} from "../assets";
import { withStyles } from "@material-ui/core/styles";
import QuestionMetaData from "../Components/QuestionMetaData.web";
import "../TeacherAssessment.web.css";
import QuestionSolution from "../Components/QuestionSolution.web";
import AlertModal from "../../../alert/src/AlertModal.web";
import { HISTORY } from "../../../../components/src/comman";
import { invalidCharsForNumberType } from "../../../profiles/src/utils/common";
import TextEditor from "./TextEditor/TextEditor.web";
import QuestionSidebarCollapse from "./QuestionSidebarCollapse.web";
import QuestionPreviewModal from "../Components/QuestionPreviewModal.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddIcon from "@material-ui/icons/Add";
import PlotlyGraph from "./PlotlyGraph.web";
import { imgDeleteGraySVG } from "../assets";
import './AddQuestion.web.css';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const input_box = {
  borderRadius: '6px',
  boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
  backgroundColor: '#ffffff',
  width: '180px',
  height: '52px',
  textAlign: "center",
  justifyContent: "center",
  fontSize: 16,
  fontWeight: 600,
  padding: '0 10px'
} as const;
// Customizable Area End

export class TwoByTwoGridTypeQuestion extends TwoByTwoGridTypeQuestionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const {
      marks,
      negativeMarks,
      sectiondata,
      selectedCategoryQuestionData,
      graph_lines_attributes,
      graph_axis_settings_attributes
    } = this.state;

    return (
      <>
        <div>
          {this.state.showLoader && (
            <Spinner spinnerModal={this.state.showLoader} />
          )}
          <Grid
            container
            className="marks_section"
          >
            <Grid
              item
              xs={12}
              sm={10}
              className="question_flex_column"
            >
              <Typography
                className={`back_with_arrow ${this.props.classes.bodyText_font}`}
              >
                <span onClick={HISTORY.goBack}>
                  <img
                    src={imgBackArrowSVG}
                    className="question_back_arrow"
                  ></img>
                  Back
                </span>

                <span
                  className={`${this.props.classes.bodyText_font} textlabel_breadcrum`}
                >
                  <span className={`text-style-light`}>
                    {this.state.isEdit
                      ? "Edit question "
                      : "Create new question "}{" "}
                  </span>
                  (
                  {selectedCategoryQuestionData?.question_category_id === 1 ? (
                    <>Objective</>
                  ) : (
                    <>Subjective</>
                  )}{" "}
                  - {selectedCategoryQuestionData?.name?.replaceAll("_", " ")})
                </span>
              </Typography>

              <div className="marks_wrap_div">
                <div className="marks_div">
                  <Typography
                    className={`question_marks_text ${this.props.classes.subHeading_font}`}
                  >
                    Marks
                  </Typography>
                  <div>
                    <div
                      className={this.state.marksError ? `view_marksbox red_border` : `view_marksbox none_border`}
                    >
                      <img
                        src={imageMarksPlusSVG}
                        className="image_marks"
                        onClick={this.plusMarksClick}
                      ></img>

                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={(event) => {
                          const value: number = parseFloat(
                            parseFloat(event.target.value)
                              .toFixed(3)
                              .slice(0, -1)
                          );
                          if (value > 0 || marks > 0) {
                            this.setState({ marksError: false });
                          } else {
                            this.setState({
                              marksError: true,
                              marks: 0,
                              marksErrorText: "Please enter marks.",
                            });
                          }
                          if (value < this.state.sectiondata?.remaining_marks) {
                            this.setState({ marks: value, marksError: false });
                          } else if (
                            value ==
                            this.state.sectiondata?.remaining_marks - 1
                          ) {
                            this.setState({ marks: value, marksError: false });
                          }
                          if (value >= this.state.negativeMarks) {
                            this.setState({
                              marks: value,
                              negmarksError: false,
                            });
                          } else {
                            this.setState({
                              marks: value,
                              negmarksError: true,
                            });
                          }
                          if (this.state.isEdit) {
                            if (
                              value >
                              this.state.sectiondata?.remaining_marks +
                              HISTORY.location.state?.questionData?.attributes
                                ?.marks
                            ) {
                              this.setState({
                                marks: value,
                                marksError: true,
                                marksErrorText:
                                  "Marks not greater than section marks.",
                              });
                            } else {
                              this.setState({
                                marks: value,
                              });
                            }
                          } else {
                            if (
                              value > this.state.sectiondata?.remaining_marks
                            ) {
                              this.setState({
                                marks: value,
                                marksError: true,
                                marksErrorText:
                                  "Marks not greater than section marks.",
                              });
                            } else {
                              this.setState({
                                marks: value,
                              });
                            }
                          }
                        }}
                        onKeyPress={(event) => {
                          if (invalidCharsForNumberType.includes(event.key)) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        value={marks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.marksError && (
                      <FormHelperText className="red_text">
                        {this.state.marksErrorText}
                      </FormHelperText>
                    )}
                  </div>
                </div>

                {this.state.assessmentHasNegativeMarking && (
                  <div className="marks_div">
                    <Typography
                      className={`question_marks_text ${this.props.classes.subHeading_font}`}
                    >
                      Negative Marks
                    </Typography>
                    <div
                      className={this.state.negmarksError ? "view_marksbox red_border" : "view_marksbox none_border"}
                    >
                      <img
                        src={imageMarksMinusSVG}
                        className="image_marks"
                        onClick={this.minusMarksClick}
                      ></img>

                      <input
                        placeholder=""
                        type="number"
                        step="0.25"
                        min="0.00"
                        onChange={(event) => {
                          const value: number = parseFloat(
                            parseFloat(event.target.value)
                              .toFixed(3)
                              .slice(0, -1)
                          );
                          if (value > this.state.marks) {
                            this.setState({ negmarksError: true });
                          } else {
                            this.setState({ negmarksError: false });
                          }
                          this.setState({ negativeMarks: value });
                        }}
                        onKeyPress={(event) => {
                          if (invalidCharsForNumberType.includes(event.key)) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        value={negativeMarks}
                        className={`question_marks_input ${this.props.classes.bodyText_font}`}
                      />
                    </div>
                    {this.state.negmarksError && (
                      <FormHelperText className="red_text">
                        Negative marking not greater then marks.
                      </FormHelperText>
                    )}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={2} className="remaining_section_marks">
              <Typography
                className={`remaining_marks ${this.props.classes.subHeading_font}`}
              >
                Remaining Section Marks
              </Typography>

              <div className="circular_div">
                <CircularProgress
                  variant="static"
                  className="grey_circular"
                  size={92}
                  thickness={2}
                  value={100}
                />
                <CircularProgress
                  variant="static"
                  disableShrink
                  className="colored_circular"
                  size={92}
                  value={this.state.remainSectionMarkPr}
                  thickness={2}
                />
                <Typography
                  className={`circular_marks_text ${this.props.classes.bodyText_font}`}
                >
                  {(
                    sectiondata?.section_marks - sectiondata?.remaining_marks
                  ).toFixed(2)}
                  /{sectiondata?.section_marks}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Typography
            className={`question_text ${this.props.classes.subHeading_font}`}
          >
            Question
          </Typography>
          <div ref={this.questionEditorRef}>
            <TextEditor
              myref={this.questionEditorRef}
              handleChange={(value: any) =>
                this.setState({ question: value }, () => {
                  if (
                    this.state.question === null ||
                    this.state.question === ""
                  ) {
                    this.setState({ questionError: true });
                  } else {
                    this.setState({ questionError: false });
                  }
                })
              }
              editorData={
                HISTORY.location.state?.questionData?.attributes?.title
                  ? HISTORY.location.state?.questionData?.attributes?.title
                  : ""
              }
              isEdit={this.state.isEdit}
            />
          </div>
          {this.state.questionError && (
            <FormHelperText className="question_error">
              Please enter question.
            </FormHelperText>
          )}

          {/* Chart Axis Section start */}
          <div className="graph_plotting">
            <Typography className={`question_text ${this.props.classes.subHeading_font}`}>Chart</Typography>

            <TableContainer className="chart_table_container">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={`first_blank_cell ${this.props.classes.subHeading_font}`}></TableCell>
                    <TableCell
                      className={`chart_header ${this.props.classes.subHeading_font}`}
                    >Label</TableCell>
                    <TableCell
                      className={`chart_header ${this.props.classes.subHeading_font}`}
                    >Minimum Value</TableCell>
                    <TableCell
                      className={`chart_header ${this.props.classes.subHeading_font}`}
                    >Maximum Value</TableCell>
                    <TableCell
                      className={`chart_header ${this.props.classes.subHeading_font}`}
                    >Step size</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={`axis_header ${this.props.classes.subHeading_font}`}
                    >X- Axis</TableCell>
                    <TableCell className="table_cell_value">
                      <TextField
                        style={input_box}
                        variant="standard"
                        name="x_axis_label"
                        onChange={this.handleInputChange}
                        value={graph_axis_settings_attributes.x_axis_label}
                        placeholder="X-axis"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className={this.props.classes.bodyText_font}
                      />
                    </TableCell>
                    <TableCell className="table_cell_value">
                      <TextField
                        style={input_box}
                        name="x_minimum_value"
                        type="number"
                        onChange={this.handleInputChange}
                        value={graph_axis_settings_attributes.x_minimum_value}
                        placeholder="10"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className={this.props.classes.bodyText_font}
                      />
                    </TableCell>
                    <TableCell className="table_cell_value">
                      <TextField
                        style={input_box}
                        name="x_maximum_value"
                        type="number"
                        onChange={this.handleInputChange}
                        value={graph_axis_settings_attributes.x_maximum_value}
                        placeholder="100"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className={this.props.classes.bodyText_font}
                      />
                    </TableCell>
                    <TableCell rowSpan={2} className="table_cell_value">
                      <TextField
                        style={input_box}
                        name="common_step_size"
                        type="number"
                        onChange={this.handleInputChange}
                        value={graph_axis_settings_attributes.common_step_size}
                        placeholder="10"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className={this.props.classes.bodyText_font}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className={`axis_header ${this.props.classes.subHeading_font}`}
                    >Y- Axis</TableCell>
                    <TableCell className="table_cell_value">
                      <TextField
                        style={input_box}
                        name="y_axis_label"
                        onChange={this.handleInputChange}
                        value={graph_axis_settings_attributes.y_axis_label}
                        variant="standard"
                        placeholder="Y-axis"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className={this.props.classes.bodyText_font}
                      />
                    </TableCell>
                    <TableCell className="table_cell_value">
                      <TextField
                        style={input_box}
                        name="y_minimum_value"
                        type="number"
                        onChange={this.handleInputChange}
                        value={graph_axis_settings_attributes.y_minimum_value}
                        placeholder="10"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className={this.props.classes.bodyText_font}
                      />
                    </TableCell>
                    <TableCell className="table_cell_value">
                      <TextField
                        style={input_box}
                        name="y_maximum_value"
                        type="number"
                        onChange={this.handleInputChange}
                        value={graph_axis_settings_attributes.y_maximum_value}
                        placeholder="100"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className={this.props.classes.bodyText_font}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* Chart Section end */}

          {/* Correct Answers */}
          <div>
            <Typography className={`question_text ${this.props.classes.subHeading_font}`}>Set Correct Answer(s)</Typography>
            <Grid container spacing={2} className="plot_graph_div">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <PlotlyGraph
                  classes={this.props.classes}
                  setting={graph_axis_settings_attributes}
                  graph_lines={graph_lines_attributes}
                />
              </Grid>
            </Grid>
          </div>
          {/* Correct Answers END */}

          {/* Enter Correct answer */}
          <div className="graph_plotting">
            <Grid container spacing={5} className="graph_correct_answer_div">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  className={`enter_correct_ans_text ${this.props.classes.subHeading_font}`}
                >
                  Enter Correct Answer
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TableContainer className="chart_table_container">
                  <Table>
                    {graph_lines_attributes.length === 0 ? (
                      <TableRow>
                        <TableCell className="table_cell_value" colSpan={4}>
                          <Typography
                            className={`graph_no_lines_text ${this.props.classes.bodyText_font}`}
                          >
                            No Lines Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        <TableBody>
                          <TableRow >
                            <TableCell className="table_cell_value"></TableCell>
                            <TableCell className="table_cell_value">
                              <Typography
                                className={`graph_start_end_text ${this.props.classes.subHeading_font}`}
                              >
                                Start (x1,
                              </Typography>
                            </TableCell>
                            <TableCell className="table_cell_value">
                              <Typography
                                className={`graph_start_end_text ${this.props.classes.subHeading_font}`}
                              >
                                y1)
                              </Typography>
                            </TableCell>
                            <TableCell className="table_cell_value">
                              <Typography
                                className={`graph_start_end_text ${this.props.classes.subHeading_font}`}
                              >
                                End (x2,
                              </Typography>

                            </TableCell>
                            <TableCell className="table_cell_value">
                              <Typography
                                className={`graph_start_end_text ${this.props.classes.subHeading_font}`}
                              >
                                y2)
                              </Typography>
                            </TableCell>
                          </TableRow>

                          {graph_lines_attributes.map((line: any, index: number) => {
                            return (
                              < TableRow key={line.uniq_key} >
                                <TableCell className="line_num_table_cell">
                                  <Typography
                                    className={`graph_no_lines_text ${this.props.classes.subHeading_font}`}
                                  >
                                    Line-{index + 1}
                                  </Typography>
                                </TableCell>

                                <TableCell className="table_cell_value">
                                  <div className="graph_axis_value_div">
                                    <TextField
                                      style={{
                                        ...input_box,
                                        border: line.error.x1
                                          ? "1px solid red"
                                          : "none",
                                      }}
                                      variant="standard"
                                      type="number"
                                      name="x1"
                                      value={line.x1}
                                      placeholder='0'
                                      className={this.props.classes.bodyText_font}
                                      onChange={(e: any) => this.handleUpdateLinesData(index, e)}

                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                    />
                                    <FormHelperText className={line.error.x1 ? "visible_graph_error" : "hidden_graph_error"}>
                                      Value should be in between {`(${graph_axis_settings_attributes.x_minimum_value}, ${graph_axis_settings_attributes.x_maximum_value})`}
                                    </FormHelperText>
                                  </div>
                                </TableCell>
                                <TableCell className="table_cell_value">
                                  <div className="graph_axis_value_div">
                                    <TextField
                                      style={{
                                        ...input_box,
                                        border: line.error.y1
                                          ? "1px solid red"
                                          : "none",
                                      }}
                                      variant="standard"
                                      type="number"
                                      name="y1"
                                      value={line.y1}
                                      placeholder='0'
                                      className={this.props.classes.bodyText_font}
                                      onChange={(e: any) => this.handleUpdateLinesData(index, e)}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                    />
                                    <FormHelperText className={line.error.y1 ? "visible_graph_error" : "hidden_graph_error"}>
                                      Value should be in between {`(${graph_axis_settings_attributes.y_minimum_value}, ${graph_axis_settings_attributes.y_maximum_value})`}
                                    </FormHelperText>
                                  </div>
                                </TableCell>
                                <TableCell className="table_cell_value">
                                  <div className="graph_axis_value_div">
                                    <TextField
                                      style={{
                                        ...input_box,
                                        border: line.error.x2
                                          ? "1px solid red"
                                          : "none",
                                      }}
                                      variant="standard"
                                      type="number"
                                      name="x2"
                                      value={line.x2}
                                      placeholder='0'
                                      className={this.props.classes.bodyText_font}
                                      onChange={(e: any) => this.handleUpdateLinesData(index, e)}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                    />
                                    <FormHelperText className={line.error.x2 ? "visible_graph_error" : "hidden_graph_error"} >
                                      Value should be in between {`(${graph_axis_settings_attributes.x_minimum_value}, ${graph_axis_settings_attributes.x_maximum_value})`}
                                    </FormHelperText>
                                  </div>
                                </TableCell>
                                <TableCell className="table_cell_value">
                                  <div className="graph_axis_value_div">
                                    <TextField
                                      style={{
                                        ...input_box,
                                        border: line.error.y2
                                          ? "1px solid red"
                                          : "none",
                                      }}
                                      variant="standard"
                                      type="number"
                                      name="y2"
                                      value={line.y2}
                                      placeholder='0'
                                      className={this.props.classes.bodyText_font}
                                      onChange={(e: any) => this.handleUpdateLinesData(index, e)}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                    />
                                    <FormHelperText className={line.error.y2 ? "visible_graph_error" : "hidden_graph_error"}>
                                      Value should be in between {`(${graph_axis_settings_attributes.y_minimum_value}, ${graph_axis_settings_attributes.y_maximum_value})`}
                                    </FormHelperText>
                                  </div>
                                </TableCell>
                                <TableCell className="delete_table_cell">
                                  <Box
                                    className="delete_cell_box"
                                    onClick={() => {
                                      this.handleDeleteCategorySection(index)
                                    }}
                                  >
                                    <img src={imgDeleteGraySVG} />
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )
                          }
                          )}
                        </TableBody>
                      </>)}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
          {/* Enter Correct answer */}

          {/* Add new Point Btn */}
          <Box className="add_graph_line_div">
            <Button
              onClick={this.handleAddNewLine}
              className={`add_new_match_btn ${this.props.classes.outline_button_no_hover}`}
            >
              <AddIcon className="add_new_match_icon" />
              Add Line
            </Button>
          </Box>

          <QuestionSolution
            hintAnswer={this.state.hintAnswer}
            hintAnswerOnchange={(value: any, id: any) => {
              let tempData = this.state.hintAnswer;
              const index = this.state.hintAnswer.findIndex(
                (res: any) => res.hintId === id
              );
              tempData[index].hint = value.trim();
              this.setState({
                hintAnswer: tempData,
              });
            }}
            explaination={this.getExplanation()}
            getPreSignedValue={(
              preSignedId: string,
              typeofAttachment: string,
              blobUrl: string
            ) => {
              if (typeofAttachment === "hints") {
                let tempData = this.state.hintAnswer;
                tempData[0].files = [preSignedId];
                tempData[0].src = blobUrl;
                this.setState({
                  hintAnswer: tempData,
                  hintId: preSignedId,
                });
              } else {
                this.setState({ solutionImageSignedId: preSignedId });
              }
            }}
            explainationOnchange={(value: any) => {
              this.setState({
                explaination: value,
              });
            }}
            attachmentExplanationUrl={this.state.attachmentExplanationUrl}
            hintsExplanationUrl={this.state.hintsExplanationUrl}
            removeSolutionImage={(attachmentType: string) => {
              if (attachmentType === "hints") {
                this.setState({
                  hintsExplanationUrl: "",
                  hintId: null,
                });
              } else {
                this.setState({
                  attachmentExplanationUrl: "",
                  solutionImageSignedId: null,
                });
              }
            }}
          />
          <QuestionMetaData
            selectedSubject={this.state.selectedSubject}
            onChangeSubject={(e: any) => {
              this.setState({ selectedSubject: e.target.value });
            }}
            chapterListData={this.state.chapterListData}
            selectedChapter={this.state.selectedChapter}
            onChangeChapter={(e: any) => {
              this.setState(
                {
                  selectedChapter: e.target.value,
                  metaDataErrors: {
                    ...this.state.metaDataErrors,
                    chapter: false,
                  },
                },
                () => this.get_unit_list(this.state.selectedChapter, true)
              );
            }}
            unitListData={this.state.unitListData}
            selectedUnit={this.state.selectedUnit}
            onChangeUnit={(e: any) => {
              this.setState({
                selectedUnit: e.target.value,
                metaDataErrors: { ...this.state.metaDataErrors, unit: false },
              });
            }}
            selectedSkill={this.state.selectedSkill}
            onChangeSkill={(e: any) => {
              this.setState({
                selectedSkill: e.target.value,
                metaDataErrors: { ...this.state.metaDataErrors, skill: false },
              });
            }}
            selectedProficiency={this.state.selectedProficiency}
            onChangeProficiency={(e: any) => {
              this.setState({
                selectedProficiency: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  proficiency: false,
                },
              });
            }}
            selectedDifficultyLevel={this.state.selectedDifficultyLevel}
            onChangeDifficultyLevel={(e: any) => {
              this.setState({
                selectedDifficultyLevel: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  difficultyLevel: false,
                },
              });
            }}
            keyword={this.state.keyword}
            keywordsList={this.state.keywordsList}
            onChangeKeywords={this.onChangeKeywords}
            onBlurKeywords={this.onBlurKeywords}
            onKeyDownKeywords={this.onKeyDownKeywords}
            handleDeleteKeyword={this.handleDeleteKeyword}
            metaDataErrors={this.state.metaDataErrors}
            selectedBloomTaxonomy={this.state.selectedBloomTaxonomy}
            onChangeBloomTaxonomy={(e: any) => {
              this.setState({
                selectedBloomTaxonomy: e.target.value,
                metaDataErrors: {
                  ...this.state.metaDataErrors,
                  bloomTaxonomy: false,
                },
              });
            }}
          />

          <div className="question_action_items">
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.background_button_no_hover}`}
              onClick={() => {
                this.setState({ prevQuestionModal: true });
              }}
            >
              <Box className="preview_btn_box">
                <VisibilityIcon className="eye_preview" />
                <Typography> Preview</Typography>
              </Box>
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_save_btn ${this.props.classes.button_color}`}
              onClick={this.saveQuestion}
            >
              {this.state.isEdit ? "Update" : "Save"}
            </Button>
            <Button
              disableRipple={true}
              variant="outlined"
              className={`question_cancel_btn ${this.props.classes.outline_button_no_hover}`}
              onClick={()=>HISTORY.push("/TeacherAssessmentCreateSection")}
            >
              Cancel
            </Button>
          </div>

          <AlertModal
            alertModal={this.state.alertModal}
            isTeacher={true}
            onCloseModal={() => {
              this.setState({ alertModal: false });
            }}
            alertTitle="Alert!"
            alertMessage={this.state.alertMessage}
            buttonText="OK"
          />

          <QuestionPreviewModal
            questionType={"2x2_grid"}
            selectedImageBlobUrl={this.state.selectedImageBlobUrl}
            questionPreviewModal={this.state.prevQuestionModal}
            closeModalFun={() => {
              this.setState({ prevQuestionModal: false });
            }}
            optionAnswer={[]}
            question={this.state.question}
            questionHint={this.state.hintAnswer}
            graph_axis_settings_attributes={this.state.graph_axis_settings_attributes}
            graph_lines_attributes={this.state.graph_lines_attributes}
          />
        </div>
        {
          this.state.isEdit && (
            <QuestionSidebarCollapse
              editedQuestionData={HISTORY.location.state?.questionData}
            />
          )
        }
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(
  TwoByTwoGridTypeQuestion as React.ComponentType<any>
);

// Customizable Area End
