// Customizable Area Start
import React from "react"
import {
    Button,
    withStyles,
    Grid,
    TextField,
    Select,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    IconButton,
    Typography
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import ModulesController, { Props } from "./ModulesController.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import AddAttributesModal from "./AddAttributesModal.web";
import ManageAttributeModal from "./ManageAttributeModal.web";
import './CreateCustomReportCard.web.css';
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = {
    headingStyle: {
        margin: "72px 0px 48px 0"
    },

}
const form_input_text_field = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
} as const;

const form_input_text_field_error = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
    border: "1px solid red"
} as const;
// Customizable Area End

export class Modules extends ModulesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { scholasticModule, coScholasticModule } = this.state;
        return (
            <>
                {this.state.showLoader && (
                    <Spinner spinnerModal={this.state.showLoader} />
                )}

                <Grid container spacing={2} className="ModuleBorderBottom">
                    {/* heading */}
                    <Grid item xs={12} sm={12} md={12} lg={12} style={styles.headingStyle}>
                        <Typography
                            className={`${classes.Modules_Feedback_subheadingText} ${classes.heading_font}`}
                        >
                            Enter Modules Details
                        </Typography>
                    </Grid>
                    {/* heading END */}

                    {/* Scholastic */}
                    <Grid item xs={12} sm={6} md={6} lg={4} className="ModulesFlexColumn">
                        <div className="term-textfield-div">
                            <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                Enter Module Name
                            </div>
                            <TextField
                                id="standard-basic"
                                placeholder="Scholastic Area"
                                style={this.state.scholasticModuleNameError ? form_input_text_field_error : form_input_text_field}
                                variant="outlined"
                                value={scholasticModule.name}
                                onChange={(e: any) => this.onChangeScholasticName(e.target.value)}
                                inputProps={{ className: `term-text-field` }}
                                data-test-id="scholastic-module-name-field"
                            />
                        </div>
                    </Grid>
                    {/* Scholastic END */}
                </Grid>

                {/* Co-Scholastic */}
                {this.state.coScholasticEnabled &&
                    <>
                        <Grid container spacing={2} className="ModulesCoScholasticDiv">
                            <Grid item xs={12} sm={6} md={6} lg={4} className="ModulesFlexColumn">
                                <div className="term-textfield-div">
                                    <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                        Enter Module Name
                                    </div>
                                    <TextField
                                        placeholder="Co-Scholastic Area"
                                        style={this.state.coScholasticModuleNameError ? form_input_text_field_error : form_input_text_field}
                                        variant="outlined"
                                        value={coScholasticModule.name}
                                        onChange={(e: any) => this.onChangeCoScholasticName(e.target.value)}
                                        inputProps={{ className: `term-text-field` }}
                                        data-test-id="co-scholastic-module-name-field"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        {
                            coScholasticModule.report_attributes.map((data: any, index: number) => (
                                <Grid container key={index} spacing={2} className="ModulesCoScholasticReport">
                                    <Grid item xs={10} sm={10} md={10} lg={10}
                                        className="ModulesFlex">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} md={6} lg={6} className="ModulesFlexColumn">
                                                <div className="module-text-field pr-40 mb-16" >
                                                    <div className="ModulesCoScholasticField">
                                                        <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                                            Enter Topic Name
                                                        </div>
                                                    </div>
                                                    <TextField
                                                        placeholder="Eg- English, Computer Science, Health..."
                                                        style={form_input_text_field}
                                                        variant="outlined"
                                                        value={data.name}
                                                        onChange={(e: any) => this.onChangeCoScholaticTopic(e, index)}
                                                        inputProps={{ className: `term-text-field` }}
                                                        data-test-id={`co-scholastic-topic-${index}`}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6} className="ModulesSelectAttributes">
                                                <div className="module-text-field pr-40 mb-16">
                                                    <div className="ModulesCoScholasticField">
                                                        <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                                            Select Attributes
                                                        </div>
                                                    </div>
                                                    <Select
                                                        className={`${classes.Modules_Feedback_selectDropDown} ${classes.bodyText_font} multi-dropdown-report`}
                                                        value={data.report_attributes}
                                                        multiple
                                                        IconComponent={ArrowDropDownRoundedIcon}
                                                        onChange={(e: any) => this.onAttributeChange(e, index)}
                                                        variant="outlined"
                                                        inputProps={{
                                                            id: "select-multiple-native",
                                                            className: "CustomReportCardSelectInput"
                                                        }}
                                                        displayEmpty
                                                        MenuProps={{ getContentAnchorEl: null }}
                                                        renderValue={(selected) => this.getSelectedAttributes(selected)}
                                                        data-test-id={`co-scholastic-attribute-${index}`}
                                                    >
                                                        {this.state.attributeList.map((item: any) => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                <ListItemText primary={item.attributes.name} />
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        checked={data.report_attributes.indexOf(Number(item.id)) > -1}
                                                                        className={`ModuleSelectCheckBox ${classes.Modules_Feedback_term_checkbox}`}
                                                                    />
                                                                </ListItemIcon>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} lg={2} className="ModulesCoScholasticClear">
                                        <IconButton
                                            className="ModulesCoScholasticClearIcon"
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={() => this.deleteCoScholasticTopic(index)}
                                            data-test-id={`co-scholastic-delete-${index}`}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>)
                            )
                        }
                    </>
                }
                {/* Co-Scholastic END */}


                {/* Create co-scholastic button */}
                <Grid container spacing={2}>
                    <Grid item xs={12} className="ModulesBtnDiv">
                        {this.state.coScholasticEnabled ?
                            <>
                                <Button
                                    className={`${this.props.classes.GradingScale_Feedback_btn_border} add-grade-btn ModulesBtnAdd`}
                                    onClick={this.addNewTopic}
                                >
                                    + Add New Topic
                                </Button>

                                <Button
                                    className={`${this.props.classes.GradingScale_Feedback_btn_border} add-grade-btn ModulesBtnAdd`}
                                    onClick={this.openAttributeModal}
                                >
                                    + Add Attribute
                                </Button>

                                <Button
                                    className={`${this.props.classes.GradingScale_Feedback_btn_border} add-grade-btn ModulesBtnEdit`}
                                    onClick={this.onClickEditAttributes}
                                >
                                    Edit Attributes
                                </Button>
                            </>
                            :
                            <Button
                                className={`${this.props.classes.GradingScale_Feedback_btn_border} add-grade-btn ModulesBtnEdit`}
                                onClick={this.addAnotherModule}
                                disabled={this.state.coScholasticEnabled}
                            >
                                + Create Module
                            </Button>
                        }
                    </Grid>
                </Grid>
                {/* Create co-scholastic button END */}

                <div className="grade-next-btn-div">
                    <Button
                        className={`${this.props.classes.button_color} done-btn`}
                        onClick={this.onClickSaveModule}
                    >
                        Save
                    </Button>
                </div>
                <AddAttributesModal
                    openModal={this.state.openAddAttributeModal}
                    handleOnClose={this.closeAttributeModal}
                    handleAddAttribute={this.addNewAttribute}
                    classes={this.props.classes}
                    attributeList={this.state.attributeList}
                />
                <ManageAttributeModal
                    openModal={this.state.openManageAttributeModal}
                    handleOnClose={this.handleOnCloseManageAttributeModal}
                    handleEditAttribute={this.handleEditAttribute}
                    handleDeleteAttribute={this.deleteAttribute}
                    classes={this.props.classes}
                    attributeList={this.state.attributeList}
                />
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

// Customizable Area End

// Customizable Area Start

export default withStyles(themeCreateStyle)(Modules as React.ComponentType<any>);
// Customizable Area End
