// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { toast } from "react-toastify";

export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    createNoteModal: boolean;
    closeModalFun: any;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    closeModal: boolean;
    data: any;
    teacherNotes: any;
    noteHeader: string;
    noteDescription: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CreateNotesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apigetTeacherClassesId: string = "";
    apigetTeacherSubjectsId: string = "";
    apigetTeacherAssignmnetId: string = "";
    apigetTeacherofStudentId: string = "";
    apigetTeacherNotesId: string = "";
    apicreateTeacherNotesId: string = "";
    apigetupcomingClassesId: string = "";
    apigettimeTableId: string = "";
    apigetliveClassesId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            closeModal: false,
            data: [],
            teacherNotes: [],
            noteDescription: '',
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.get_userdata = this.get_userdata.bind(this);
        // Customizable Area End
    }


    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    get_userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }
    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.closeModalFun(this.state.closeModal)
    }
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apicreateTeacherNotesId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.props.closeModalFun()
                        // this.props.closeModalFun(!this.state.closeModal)
                        toast.success("Notes has been added successfully")

                    } else {
                        this.parseApiErrorResponse(responseJson);
                        this.setState({ loadingSpinner: false })
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    addNote = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { noteHeader, noteDescription } = this.state;
        const school_data = localStorage.getItem("user_data");
        const userdata = JSON.parse(school_data || "{}");
        const data = {
            title: noteHeader,
            description: noteDescription,
        }
        this.setState({ loadingSpinner: true })
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            school: userdata.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apicreateTeacherNotesId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.teacherCreateNotes
        );
        const httpBody = data;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.InstituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarPOSTMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
