// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const CalendarIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M25.489 0c.38 0 .689.298.689.667l-.001 1.999h3.445c1.9 0 3.445 1.496 3.445 3.334v22.667c0 1.837-1.545 3.333-3.445 3.333H3.445C1.545 32 0 30.504 0 28.667V6c0-1.838 1.544-3.333 3.445-3.333l3.443-.001v-2c0-.368.31-.666.69-.666.38 0 .689.298.689.667l-.001 1.999h16.533l.001-2c0-.368.308-.666.689-.666zm6.199 11.999H1.377v16.668c0 1.103.928 2 2.068 2h26.177c1.14 0 2.067-.897 2.067-2l-.001-16.668zM6.888 4H3.445c-1.14 0-2.067.897-2.067 2l-.001 4.666h30.311V6c0-1.103-.926-2-2.066-2h-3.445v2a.678.678 0 0 1-.688.667A.677.677 0 0 1 24.8 6l-.001-2H8.266v2a.677.677 0 0 1-.688.667.678.678 0 0 1-.69-.667V4z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default CalendarIcon;
// Customizable Area End