// Customizable Area Start
import React from "react";
import AlertModal from "../../../alert/src/AlertModal.web";
import { FileDrop } from "react-file-drop";
import "./StudentResources.web.css";
import {
  Box,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import {
  close_icon,
  doc,
  fileUploadImage,
  gif_icon,
  jpg,
  pdf,
  png_icon,
  xls,
} from "./assets";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { withStyles } from "@material-ui/core/styles";
import UploadResourcesController, {
  Props,
} from "./UploadResourcesController.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

export class UploadResource extends UploadResourcesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return (
      <>
        {this.state.showSpinner && (
          <Spinner spinnerModal={this.state.showSpinner} />
        )}
        <Modal
          open={this.props.submitModal}
          onClose={this.modalhandleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={this.props.submitModal}>
            <div className="uploadpopup">
              <Box className="commonPopup">
                <Box className={`titleMain ${this.props.classes.title_font}`}>Upload Files</Box>
                <form onSubmit={this.submitCourseWork}>
                  <Box className="uploadItems">
                    {this.state.uploadFile.length !== 0 ? (
                      <>
                        <Box>
                          <Box className="outerfileList">
                            {this.state.uploadFile.map(
                              (row: any, index: any) => {
                                return (
                                  <Box className="filelists">
                                    {row.content_type === "image/jpeg" ? (
                                      <img src={jpg} className="fileIcon" />
                                    ) : row.content_type === "image/png" ? (
                                      <img
                                        src={png_icon}
                                        className="fileIcon"
                                      />
                                    ) : row.content_type === "image/gif" ? (
                                      <img
                                        src={gif_icon}
                                        className="fileIcon"
                                      />
                                    ) : row.content_type ===
                                      "application/pdf" ? (
                                      <img src={pdf} className="fileIcon" />
                                    ) : row.content_type ===
                                      "application/msword" ||
                                      row.content_type ===
                                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                      <img src={doc} className="fileIcon" />
                                    ) : row.content_type ===
                                      "application/vnd.ms-excel" ||
                                      row.content_type ===
                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                                      <img src={xls} className="fileIcon" />
                                    ) : (
                                      ""
                                    )}
                                    <Typography className="fileName">
                                      {row.fileName}
                                      <span>
                                        {row.fileSize > 1048576
                                          ? `${(row.fileSize / 1048576).toFixed(
                                            2
                                          )} MB`
                                          : `${(row.fileSize / 1024).toFixed(
                                            2
                                          )} KB`}
                                      </span>
                                    </Typography>
                                    <IconButton
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      onClick={() => {
                                        this.deleteDoc(index);
                                      }}
                                    >
                                      <img
                                        src={close_icon}
                                        className="cross_icon"
                                      />
                                    </IconButton>
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <FileDrop
                        onDrop={(files: any, event: any) => {
                          this.handleUpload(event, true, files);
                        }}
                      >
                        <Box className="uploadpopup-content">
                          <Box className="dragFile">
                            <Box className="uploadFiles">
                              <img
                                src={fileUploadImage}
                                alt="Upload"
                                title="Upload"
                              />
                            </Box>
                            Drag and drop files here or
                            <input className="nodisplay"
                              id="contained-button-file2"
                              multiple
                              type="file"
                              accept="image/png, image/jpg, image/jpeg, image/gif ,application/pdf , application/msword ,application/vnd.ms-excel , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              onChange={(event: React.ChangeEvent<any>) => {
                                this.handleUpload(event, false, null);
                              }}
                            />
                            <label htmlFor="contained-button-file2">
                              <Button className="browseBtn" component="span">browse.</Button>
                            </label>
                            them from your computer Supported images formats:
                            .png .jpg .pdf .doc .xls or .gif. Up to 10 files can
                            be uploaded at once, should not exceed 500MB
                          </Box>
                        </Box>
                      </FileDrop>
                    )}
                  </Box>

                  <Box className="centerButton bottomButton">
                    <Button
                      className={`purpleButton ${this.props.classes.button_color}`}
                      onClick={this.submitCourseWork}
                      disabled={
                        this.state.uploadFile.length === 0 ? true : false
                      }
                    >
                      Upload
                    </Button>
                    <Button
                      className="greyButton"
                      onClick={this.modalhandleClose}
                    >
                      Cancel
                    </Button>
                  </Box>
                </form>
              </Box>
              <AlertModal
                alertModal={this.state.alertModal}
                isTeacher={false}
                onCloseModal={() => {
                  this.setState({ alertModal: false });
                }}
                alertTitle="Alert!"
                alertMessage={this.state.alertMessage}
                buttonText="OK"
              />
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
    // Customizable Area End
}
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(UploadResource);
// Customizable Area End