import React from "react";
// Customizable Area Start
import {
    StyleSheet,
    Platform,
} from "react-native";
import { Chart } from "react-google-charts";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import Calendar from 'react-calendar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Datetime from "react-datetime";
import FormHelperText from "@material-ui/core/FormHelperText";
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import { period } from './assets'
// Customizable Area End
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    CircularProgress,
    IconButton,
    Link,
    MenuItem,
    Select,
    NativeSelect,
    TextField,
    Typography
} from "@material-ui/core";
import {
    Search,
    Replay,
    NotificationsNone,
    HeadsetMicOutlined,
    Title,
    TitleOutlined,
    KeyboardArrowLeft,
    EditOutlined,
    MoreVertOutlined,
    KeyboardArrowDown,
    DesktopAccessDisabledOutlined,
    ArrowDropDown
} from "@material-ui/icons";

import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import LogoutController, { Props } from "./LogoutController";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import "./Sidebar.css";
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area Start
class Logout extends LogoutController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        const deleteMes1 = {
            fontSize: "24px",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            margin: '31px 32px 6px 33px',

        } as const;

        const deleteDiv = {
            fontSize: "24px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            marginBottom: '14px',

        } as const;
        const deleteMes2 = {
            fontFamily: "Open sans",
            fontSize: "14px",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#959393",
            margin: '6px 53px 40px 54px',

        } as const;
        const cancelButton = {
            margin: '0 20px 0 0',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',

        } as const;
        const deleteButton = {
            margin: '0 0px 0 20px',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',
        } as const;
        const cancelText = {
            fontSize: "14px",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center',

        } as const;
        const deleteText = {
            fontSize: "14px",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center'

        } as const;
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    open={this.props.logoutModal}
                    onClose={this.modalhandleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableAutoFocus={true}
                >
                    <Fade in={this.props.logoutModal} style={{ border: "none" }}>
                        <Box className="logout-modal"
                            style={{
                                justifyContent: "center",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'white',

                                borderRadius: "29px",
                                padding: "32px 18px",
                                // width: 800,
                                

                            }}
                        >
                            <div style={deleteDiv} className={this.props.classes.title_font}>Log out</div>
                            <div style={{
                                height: "1px",
                                margin: "14px 0 31px 0",
                            }}
                                className={this.props.classes.logout_solid_border}
                            >

                            </div>
                            <div style={deleteMes1} className={this.props.classes.subTitle_font}>Are you sure you want to log out?</div>
                            <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
                                <Button variant="contained" style={cancelButton} className={this.props.classes.button_color_disabled} onClick={this.modalhandleClose}><span style={cancelText}>Cancel</span></Button>
                                <Button variant="contained" style={deleteButton} className={this.props.classes.button_color} onClick={this.logoutHandleClick}><span style={deleteText}>Yes</span></Button>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </>
        )
    }
}

export default withStyles(themeCreateStyle)(Logout);
