// Customizable Area Start
import React from "react";
import {
    makeStyles,
    Theme,
    createStyles,
    withStyles,
} from "@material-ui/core/styles";
import {
    TextField,
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    InputAdornment,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { nounCheckSVG, nounCloseSVG } from "../assets";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const ColorLibConnector = withStyles({
    alternativeLabel: {
        top: 15.5,
        left: "calc(-50% + 1px)",
        right: "calc(50% + 1px)",
    },
    active: {
        "& $line": {
            borderColor: "#3a60d7",
        },
    },
    completed: {
        "& $line": {
            borderColor: "#3a60d7",
        },
    },
    line: {
        width: "auto",
        borderStyle: "solid",
        borderImageSlice: 1,
    },
})(StepConnector);

const useCustomStepIconStyles = makeStyles({
    root: {
        backgroundColor: "#ffffff",
        zIndex: 1,
        color: "#fff",
        height: "34px",
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "22px",
        fontWeight: 600,
        fontFamily: "Open sans",
    },
    active: {
        width: "34px",
        border: "1px solid #3a60d7",
        borderWidth: 3,
    },
    completed: {
        color: "#784af4",
        zIndex: 1,
        fontSize: 18,
    },
    verticalLine: {
        width: 3,
        height: 16,
        borderRadius: "2px",
        backgroundColor: "#3a60d7",
    },
});

function CustomStepIcon(props: StepIconProps) {
    const classes = useCustomStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            <div className={active ? "" : classes.verticalLine} />
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            overflow: "auto",
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    })
);
// Customizable Area End

function EvaluationStepper(props: any) {
    // Customizable Area Start
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Stepper
                alternativeLabel
                activeStep={props.activeStep}
                connector={<ColorLibConnector />}
            >
                {props.steps
                    ?.filter((item: any) => !item?._destroy)
                    ?.map((stepItem: any, index: number) => (
                        <Step key={stepItem?.uniqueId}>
                            <StepLabel
                                className="rating-step-custom-color"
                                StepIconComponent={CustomStepIcon}
                            >
                                <>
                                    {props?.isEmojiType && (
                                        <div className="emoji-icon">
                                            <span>{stepItem?.emoji}</span>
                                        </div>
                                    )}
                                    <div className="question-step-label">
                                        <TextField
                                            id="standard-basic"
                                            type={props.textFieldType}
                                            placeholder=""
                                            style={{
                                                borderRadius: 6,
                                                backgroundColor: stepItem.id === stepItem.attmptedId ? stepItem.right_answer ? "#def4df" : "#f7dee5" : "",
                                                paddingRight: stepItem.id === stepItem.attmptedId && props?.isPercentageType && 14
                                            }}
                                            className={stepItem.id === stepItem.attmptedId ? "no-underline" : ""}
                                            variant={stepItem.id === stepItem.attmptedId ? "standard" : "outlined"}
                                            value={stepItem?.textValue}
                                            disabled
                                            inputProps={{
                                                className: `rating-text-field ${props.classes.heading_font}`,
                                            }}
                                            InputProps={{
                                                endAdornment: props?.isPercentageType && (
                                                    <InputAdornment position="end">
                                                        <Typography
                                                            className={props.classes.heading_font}
                                                        >
                                                            %
                                                        </Typography>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                        {stepItem.id === stepItem.attmptedId &&
                                            <>
                                                {stepItem.right_answer ?
                                                    <img src={nounCheckSVG} alt="check" className="right-check-icon" />
                                                    :
                                                    <img src={nounCloseSVG} alt="close" className="close-red-icon" />}
                                            </>
                                        }

                                    </div>
                                </>
                            </StepLabel>
                        </Step>
                    ))}
            </Stepper>
        </div>
    );
    // Customizable Area End
}

// Customizable Area Start

export default withStyles(themeCreateStyle)(EvaluationStepper);
// Customizable Area End
