// Customizable Area Start
import React, { useEffect, useState } from "react"
import {
  Modal,
  Box,
  Typography,
  Grid,
  withStyles,
  TextField,
  Button,
  FormHelperText
} from "@material-ui/core"
import {
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import Datetime from "react-datetime";
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import { calendar } from "../assets";
import moment from "moment"
import { toast } from "react-toastify";
import Editor from "./Component/Editor.web";
import "./Component/EditorStyle.css"
import axios from 'axios'
import Spinner from "../../../shared/SideBar/src/Spinner";
import getLength from "react-quill";
const configJSON = require("./config.js")
// import { checkForRequiredField } from "./PostEventModalController.web";
// Customizable Area End

interface Props {
  // Customizable Area Start
  thought?: any
  open: boolean;
  classes?: any
  close: React.Dispatch<React.SetStateAction<boolean>>;
  createThought?: any;
  handlePostThoughtSave?: any;
  thoughtId?: any;
  appenedUpdateThought?: any;
  updateStatEditThough?: any;
  updateStateEditThoughBoxMenu?: any;
  postThought?: any;
  // Customizable Area End
}
interface IForm {
  // Customizable Area Start
  //  EventName:String,
  StartDate: any,
  StartTime: any,
  EndDate: any,
  EndTime: any,
  [key: string]: any
  // Customizable Area End
}

interface IErrorMessage {
  // Customizable Area Start
  error: boolean,
  errorText: string
  // Customizable Area End
}

interface IError {
  // Customizable Area Start
  // EventNameError:IErrorMessage,
  StartDateError: IErrorMessage,
  StartTimeError: IErrorMessage,
  EndDateError: IErrorMessage,
  EndTimeError: IErrorMessage,
  [key: string]: any
  // Customizable Area End
}

const PostThoughtModal: React.FC<Props> = ({
  thought,
  open,
  close,
  classes,
  handlePostThoughtSave,
  createThought,
  thoughtId,
  appenedUpdateThought,
  updateStatEditThough,
  updateStateEditThoughBoxMenu,
  postThought,
}) => {
  // Customizable Area Start
  const defaultValue: IForm = {
    // EventName:'',
    StartDate: null, StartTime: null, EndDate: null, EndTime: null
  }
  const defaultError: IError = {
    EndDateError: { error: false, errorText: '' },
    EndTimeError: { error: false, errorText: '' },
    StartDateError: { error: false, errorText: '' },
    // EventNameError:{error:false,errorText:''},
    StartTimeError: { error: false, errorText: '' },
  }

  const [form, setForm] = useState<IForm>(defaultValue)
  const [error, setError] = useState<IError>(defaultError)
  const [editorText, setEditorText] = useState('');
  const [readOnly, setReadOnly] = useState(false)
  // spinner
  const [spinner, setSpinner] = useState(false);
  // Customizable Area End

  // Customizable Area Start
  const reset = () => {
    setError(defaultError)
    setForm(defaultValue)
    setEditorText("")
    setReadOnly(false)
  }
  // Customizable Area End

  // Customizable Area Start

  useEffect(() => {

    if (thought) {
      setForm({
        EndDate: moment(thought.valid_to).format('DD-MM-YYYY'),
        StartDate: moment(thought.valid_from).format('DD-MM-YYYY'),
        EndTime: thought.valid_to,
        StartTime: thought.valid_from
      })
      setEditorText(thought.thought_title)
    }

  }, [thought])

  // useEffect(()=>{
  //   handleTimeChange()
  // },[form.EndDate])

  // Customizable Area End

  // Customizable Area Start
  const handleText = (value: any) => {
    setEditorText(value)
    // const t1 = value.replace('<em>','')
    // const t2 = t1.replace('</em>','')
    // const t3 = t2.replace('<strong>','')
    // const t4 = t3.replace('</strong>','')
    // const length = t4.trim().length;
    // if (length <= 256) {
    //   setEditorText(value)
    //   setReadOnly(false)
    // }
    // else {
    //   setReadOnly(true)
    // }
  }

  const checkForRequiredField = (newVal: any, prop: any) => {
    const props = prop.charAt(0).toUpperCase() + prop.slice(1);
    const value = typeof newVal === "string" ? newVal?.trim() : newVal;
    if (
      value === "" ||
      value === null ||
      value?.length === 0 ||
      value === undefined
    ) {
      return true
    } else {
      return false
    }
  };

  const checkField = (props: any) => {
    setError({
      ...error,
      //  EventNameError: props.includes("EventName")?{error:true,errorText:"Event Name is required"}:
      //                  error.EventNameError.error?error.EventNameError:defaultError.EventNameError,
      StartTimeError: props.includes("StartTime") ? { error: true, errorText: "Start Time is required" } :
        error.StartTimeError.error ? error.StartTimeError : defaultError.StartTimeError,
      StartDateError: props.includes("StartDate") ? { error: true, errorText: "Start Date is required" } :
        error.StartDateError.error ? error.StartDateError : defaultError.StartDateError,
      EndDateError: props.includes("EndDate") ? { error: true, errorText: "End Date is required" } :
        error.EndDateError.error ? error.EndDateError : defaultError.EndDateError,
      EndTimeError: props.includes("EndTime") ? { error: true, errorText: "End Time is required" } :
        error.EndTimeError.error ? error.EndTimeError : defaultError.EndTimeError
    })

  }

  const checkValid = () => {
    var valid = true
    var arr: any = [];
    Object.keys(form).map((key: keyof IForm) => {
      if (checkForRequiredField(form[key], key)) {
        arr.push(key)
        valid = false
      }
    })
    checkField(arr)

    return valid
  }

  const checkvalidFieldError = () => {
    var valid = true
    Object.keys(error).map((key) => {

    })

    return valid
  }

  const handleCreateThought = async () => {

    const intialTime = moment(form.StartTime).format('H:mm')
    const startTimeSplit = intialTime.split(':');
    let startTime = moment(form.StartDate, "DD-MM-YYYY").add(startTimeSplit[0], 'hours').add(startTimeSplit[1], 'minutes')
    const validFromTime = moment(startTime).format()

    const validToTime = moment(form.EndTime).format('H:mm')
    const endTimeSplit = validToTime.split(':');
    let endTime = moment(form.EndDate, "DD-MM-YYYY")
      .add(endTimeSplit[0], 'hours')
      .add(endTimeSplit[1], 'minutes')
    const validTo_Time = moment(endTime).format()

    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");

    const httpBody = {
      school_id: data.school_id,
      thought_title: editorText,
      valid_from: validFromTime,
      valid_to: validTo_Time
    }

    postThought(httpBody);
    close(false);
    setEditorText("");
    setForm(defaultValue);
    setReadOnly(false)

  }

  const handleUpdateThought = async () => {

    const intialTime = moment(form.StartTime).format('H:mm')
    const startTimeSplit = intialTime.split(':');
    let startTime: any = moment(form.StartDate, "DD-MM-YYYY").add(startTimeSplit[0], 'hours').add(startTimeSplit[1], 'minutes')
    const validFromTime = moment(startTime).format()

    const validToTime = moment(form.EndTime).format('H:mm')
    const endTimeSplit = validToTime.split(':');
    let endTime = moment(form.EndDate, "DD-MM-YYYY")
      .add(endTimeSplit[0], 'hours')
      .add(endTimeSplit[1], 'minutes')
    const validTo_Time = moment(endTime).format()

    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {
      school_id: data.school_id,
      thought_title: editorText,
      valid_from: validFromTime,
      valid_to: validTo_Time
    }

    updateStateEditThoughBoxMenu(httpBody, thoughtId)
    close(false)
    setReadOnly(false)

  }




  const submitForm = () => {
    const valid = checkValid()
    if (valid) {
      const fields = checkvalidFieldError()
      if (fields) {
        if (error.EndDateError.error == false && error.EndTimeError.error == false && error.StartDateError.error == false && error.StartTimeError.error == false && readOnly == false) {
          if (thought) {
            handleUpdateThought()
          } else
            handleCreateThought()
        }
        else {
        }
      }
    }

  }




  const disablePastDt = (current: any) => {
    const today = thought ? moment(thought.valid_to).add(-1, 'days') : moment().add(-1, 'days');
    return current.isAfter(today);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleTimeChange = (date: any, prop: string) => {
    let today = new Date();
    let text = today.toLocaleDateString();
    let regex = /\//g;
    let result = text.replace(regex, "-");
    if (prop !== "EndTime") {
      const startTimeValid = moment(date).isSameOrAfter()
      if (thought ? true : startTimeValid) {
        const first = moment.utc(date, "hh:mm")
        const EndDateValid = moment.utc(form.EndDate, "hh:mm").isAfter(first)

        if (!EndDateValid) {

          const dateError = { error: true, errorText: "End Time can't be before start Time" }
          setForm({
            ...form, [prop]: date
          })
          setError({
            ...error,
            StartTimeError: defaultError.StartTimeError,
            EndTimeError: dateError
          })
        } else {
          setForm({
            ...form, [prop]: date
          })
          setError({
            ...error,
            StartTimeError: defaultError.StartTimeError,
            EndTimeError: defaultError.EndTimeError,
          })
        }

      } else {

        setForm({
          ...form, [prop]: date
        })
        setError({
          ...error,
          StartTimeError: { error: result == form.StartDate ? true : false, errorText: result == form.StartDate ? "Start Time should be greater than current Time" : "", }
        })
      }
    } else {
      const EndtimeValid = moment(date).isAfter(form.StartTime)
      if (EndtimeValid) {
        setForm({
          ...form, [prop]: date
        })
        setError({
          ...error,
          EndTimeError: { error: false, errorText: "", }
        })
      } else {
        setForm({
          ...form, [prop]: date
        })
        setError({
          ...error,
          EndTimeError: { error: form.StartDate == form.EndDate ? true : false, errorText: form.StartDate == form.EndDate ? "End Time should be greater than start Time" : "", }
        })
      }
    }
  }
  // Customizable Area End

  return (
    <>
      <Spinner
        spinnerModal={spinner}
      />
      <Modal className="postThought"
        open={open}
        onClose={() => {
          close(false)
          if (!thought) {
            reset()
          }
          setReadOnly(false)
        }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflowY: "scroll"

        }}
      >

        <Box
          style={{
            padding: "28px 24px",
            borderRadius: "8px",
            boxShadow: "0 0 24px 4px rgba(0, 0, 0, 0.2)",
            background: "#fff",
            width: 680,
            height:'90%',
            overflow:'hidden auto'
          }}
        >
          <Box style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: '20px'
          }}>
            <div>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                }}
                className={classes.title_font}
              >Post New Thought</Typography>
            </div>

            <CloseIcon
              style={{
                cursor: "pointer",
                fontSize: 38,
                color: "lightGray"
              }}
              onClick={() => {

                if (!thought) {
                  reset()
                }
                close(false)
                setReadOnly(false)
              }}
            />
          </Box>

          <Grid container spacing={2}>


            {/* <Grid item xs={12} className={classes.padding}>
                      <Box className={classes.TextMargin}> 
                        <Typography className={classes.Text}>Event Name</Typography>
                      </Box>
                      <Box className={classes.inputMargin}>
                      <TextField
                          className={classes.textField}
                          variant={"outlined"}
                          placeholder="Write Event Name"
                          onBlur={()=>{
                            if(form.EventName===''){
                              setError({
                                ...error,EventNameError:{error:true,errorText:"Event Name is Required"}
                              })
                            }else{
                              setError({
                                ...error,EventNameError:{error:false,errorText:""} })
                            }
                          }}
                          onChange={(e:any)=>{
                            handleChange(e)
                            if(e.target.value!==''){
                              setError({
                                ...error,EventNameError:{error:false,errorText:""} })
                            }else{
                              setError({
                                ...error,EventNameError:{error:true,errorText:"Event Name is Required"}
                              })
                            }
                          }}
                          name="EventName"
                          error={error.EventNameError.error}
                          helperText={error.EventNameError.errorText}
                          value={form.EventName}
                       />
                     </Box>   
                  </Grid> */}

            <Grid item xs={12} className={classes.padding}>
              <Box
                // marginTop="px" 
                position="relative"
                height="138px"
                width="100%"
                border = { readOnly ? "2.3px solid #f3110d" : "solid 0.3px rgba(63, 82, 109, 0.5)" }
                borderRadius="8px"
              //  padding="10px 20px"
              >


                <Editor
                  editorText={editorText}
                  handleText={handleText}
                />
              </Box>
              {/* {readOnly && <div>
                <p style={{ color: "#d11414bd", fontSize:"13px", margin: "10px" }}>
                Maximum 256 characters allowed      
                </p>
              </div>
              } */}
            </Grid>

            <Grid item xs={12} className={classes.padding}>
              <Box className={classes.TextMargin}>
                <Typography className={classes.DateTimeText}
                  style={{ textTransform: 'none' }}
                >Valid From</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Box className={classes.TextMargin}>
                    <Typography className={classes.Text}>Date</Typography>
                  </Box>
                  <Box className={classes.inputMargin}>
                    <Datetime
                      dateFormat={"DD-MM-YYYY"}
                      timeFormat={false}
                      strictParsing={true}
                      input={true}
                      isValidDate={disablePastDt}
                      className={
                        error.StartDateError.error
                          ? "input_date_of_birth_css_error_format postModal"
                          : "input_date_of_birth_css_format postModal"
                      }
                      inputProps={{
                        placeholder: "dd-mm-yyyy",
                        className:
                          classes.DateTime,
                        // "Teacher_Profile Date_Icon",
                        readOnly: true,
                      }}
                      value={form.StartDate}
                      onClose={() => {
                        if (form.StartDate === null) {
                          const dateError = { error: true, errorText: "Date is required.", }
                          setError({
                            ...error, StartDateError: dateError,
                          });
                        } else {
                          if (!error.StartDateError.error)
                            setError({
                              ...error, StartDateError: defaultError.StartDateError
                            })
                        }
                      }}
                      closeOnClickOutside
                      closeOnSelect
                      onChange={(value: any) => {
                        const first = moment.utc(value, "DD-MM-YYYY")
                        const EndDateValid = moment.utc(form.EndDate, "DD-MM-YYYY").isAfter(first)

                        if (!EndDateValid) {


                          const dateError = { error: false, errorText: "End date can't be before start date" }
                          setError({
                            ...error,
                            EndDateError: dateError,
                            StartDateError: defaultError.StartDateError
                          });
                        } else {
                          setError({
                            ...error,
                            EndDateError: defaultError.EndDateError,
                            StartDateError: defaultError.StartDateError
                          });
                        }
                        setForm({
                          ...form,
                          StartDate: moment(value).format('DD-MM-YYYY')
                        })
                      }}
                    />
                    <FormHelperText
                      style={{ color: "red", margin: "3px 14px 0" }}
                    >
                      {error.StartDateError.error && error.StartDateError.errorText}
                    </FormHelperText>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box className={classes.TextMargin}>
                    <Typography className={classes.Text}>Time</Typography>
                  </Box>
                  <Box className={classes.inputMargin}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        autoOk
                        inputVariant="outlined"
                        // format="hh:mm"
                        placeholder="hh:mm"
                        //  onBlur={()=>{
                        //    if(form.StartTime===null){
                        //      setError({
                        //        ...error,
                        //       StartTimeError:{error:true,errorText:"Start Time is Required"} 
                        //      })
                        //    }else{
                        //      if(!error.StartTimeError.error){
                        //     setError({
                        //       ...error,
                        //      StartTimeError:{error:false,errorText:""} 
                        //     })
                        //      }
                        //    }
                        //  }}
                        className={classes.textField}
                        value={form.StartTime}
                        onChange={(e: any) => handleTimeChange(e, "StartTime")}
                        error={error.StartTimeError.error}
                        helperText={error.StartTimeError.errorText}
                      />
                    </MuiPickersUtilsProvider>

                  </Box>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12} className={classes.padding}>

              <Box className={classes.TextMargin}>
                <Typography className={classes.DateTimeText}
                  style={{ textTransform: 'none' }}
                >Valid To</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid className="datepicker-outer" item sm={6} xs={12}>
                  <Box className={classes.TextMargin}>
                    <Typography className={classes.Text}>Date</Typography>
                  </Box>
                  <Box className={classes.inputMargin}>
                    <Datetime
                      dateFormat={"DD-MM-YYYY"}
                      timeFormat={false}
                      strictParsing={true}
                      // onBlur={this.handleOnBlur('Name')}
                      input={true}
                      // isValidDate={disablePastDt}
                      className={
                        error.EndDateError.error
                          ? "input_date_of_birth_css_error_format postModal"
                          : "input_date_of_birth_css_format postModal"
                      }
                      inputProps={{
                        placeholder: "dd-mm-yyyy",
                        className:
                          classes.DateTime,
                        // "Teacher_Profile Date_Icon",
                        readOnly: true,
                      }}
                      value={form.EndDate}
                      onClose={() => {
                        if (form.EndDate === null) {
                          const dateError = { error: true, errorText: "Date is required.", }
                          setError({
                            ...error, EndDateError: dateError,
                          });
                        } else {
                          if (!error.EndDateError.error)
                            setError({
                              ...error, EndDateError: defaultError.EndDateError
                            })
                        }
                      }}
                      closeOnClickOutside
                      closeOnSelect
                      isValidDate={disablePastDt}
                      onChange={(value: any) => {
                        const first = moment.utc(value, "DD-MM-YYYY")

                        const EndDateValid = moment.utc(form.StartDate, "DD-MM-YYYY").isAfter(first)
                        if (EndDateValid) {
                          const dateError = { error: false, errorText: "End date can't be before start date" }
                          setError({
                            ...error,
                            EndDateError: dateError,
                          })
                        } else {
                          setError({
                            ...error, EndDateError: defaultError.EndDateError
                          })
                        }
                        setForm({
                          ...form,
                          EndDate: moment(value).format('DD-MM-YYYY')
                        });

                      }}
                    />
                    <FormHelperText
                      style={{ color: "red", margin: "3px 14px 0" }}
                    >
                      {error.EndDateError.error && error.EndDateError.errorText}
                    </FormHelperText>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box className={classes.TextMargin}>
                    <Typography className={classes.Text}>Time</Typography>
                  </Box>
                  <Box className={classes.inputMargin}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>

                      <TimePicker
                        autoOk
                        inputVariant="outlined"
                        // format="hh:mm"
                        placeholder="hh:mm"
                        className={classes.textField}
                        value={form.EndTime}
                        //  onBlur={()=>{
                        //    debugger
                        //   if(form.EndTime===null){
                        //     setError({
                        //       ...error,
                        //      EndTimeError:{error:true,errorText:"End Time is Required"} 
                        //     })
                        //   }else{
                        //     setError({
                        //       ...error,
                        //      EndTimeError:{error:false,errorText:""} 
                        //     })
                        //   }
                        // }}
                        onChange={(e: any) => handleTimeChange(e, "EndTime")}
                        error={error.EndTimeError.error}
                        helperText={error.EndTimeError.errorText}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                </Grid>
              </Grid>

            </Grid>

            <Grid item xs={12} className={classes.padding}>
              <Box style={{ marginTop: '18px' }}>
                <Button
                  onClick={submitForm}
                  className={classes.button_color}
                  style={{
                    padding: "10px 42px",
                    height: 50,
                    fontSize: 18,
                    textTransform: "none",
                  }}
                >
                  Post
                </Button>
              </Box>

            </Grid>

          </Grid>

        </Box>
      </Modal>
    </>
  )
}
export const themeCreatStyle = ((theme: any) => ({
  // Customizable Area Start
  background_color: {
    backgroundColor: theme.palette.primary.main
  },
  title_font: {
    color: theme.props.titleColor,
    fontFamily: theme.props.titleFont
  },
  subTitle_font: {
    color: theme.props.subtitleColor,
    fontFamily: theme.props.subtitleFont
  }, padding: {
    padding: '6px 8px'
  },
  TextMargin: {
    marginTop: 8
  },
  inputMargin: {
    marginTop: "1.8%",
  },
  textField: {
    width: "100%",
    border: "solid 0px rgba(63, 82, 109, 0.5)",
    // backgroundColor: "#f9f8fd",

    "& .MuiOutlinedInput-input": {
      padding: "14px",
      height: "50px",
      boxSizing: "border-box",
      borderRadius: "8px",
      border: "solid 1px #ebebeb",
      backgroundColor: "#f9f8fd",
      // background: '#fff',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ebebeb",
        borderRadius: "8px",
        border: "solid 1px #ebebeb",
        // background: '#fff',
      },
    },
  },


  DateTime: {
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${calendar})`,
    backgroundSize: '24px 24px',
    backgroundPosition: 'top 15px right 16px',
    border: "solid 0px rgba(63, 82, 109, 0.5)",
    backgroundColor: "#f9f8fd",
  },
  Text: {
    fontSize: "14px",
    fontWeight: 600,
    color: theme.props.subtitleColor,
    fontFamily: theme.props.subtitleFont
  },
  DateTimeText: {
    fontSize: "16px",
    fontWeight: 600,
    color: theme.props.subtitleColor,
    fontFamily: theme.props.subtitleFont
  },
  heading_font: {
    color: theme.props.headingColor,
    fontFamily: theme.props.headingFont
  },
  subHeading_font: {
    color: theme.props.subheadingColor,
    fontFamily: theme.props.subheadingFont
  },
  bodyText_font: {
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  checkBox_color: {
    color: theme.props.buttonColor
  },
  button_color: {
    backgroundColor: theme.props.buttonColor,
    color: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    '&:hover': {
      background: theme.props.buttonColor,
    }
  },
  border_Color: {
    border: `1px solid ${theme.props.buttonColor}`
  },
  icon_color: {
    color: theme.props.iconColor
  },
  file_border_Color: {
    border: `dashed 0.5px ${theme.props.buttonColor}`,
  },
  button_color_disabled: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    '&:hover': {
      background: "#fff",
    }
  },
  normal_button_color: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
  },
  dashed_Border: {
    border: `0.3px dashed ${theme.props.buttonColor}`,
  },
  // Customizable Area End
}))

// Customizable Area Start
export default withStyles(themeCreatStyle)(PostThoughtModal);
// Customizable Area End