import React from "react";
// Customizable Area Start
import {
    StyleSheet,
    Platform,
} from "react-native";
import Picker from "emoji-picker-react";
import Grid from '@material-ui/core/Grid';
import Datetime from "react-datetime";
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    IconButton,
    Link,
    MenuItem,
    Select,
    NativeSelect,
    TextField,
    Typography,
    Popover
} from "@material-ui/core";
import {
    Search,
    Replay,
    NotificationsNone,
    HeadsetMicOutlined,
    Title,
    TitleOutlined,
    KeyboardArrowLeft,
    EditOutlined,
    MoreVertOutlined,
    KeyboardArrowDown,
    DesktopAccessDisabledOutlined,
    ArrowDropDown
} from "@material-ui/icons";
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import FilterScreenController, { Props } from "./FilterScreenController.web";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FileDrop } from 'react-file-drop';
import { grid } from "../../ClassesAndSections/src/Attendance/assets";
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    EmailShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    EmailIcon,
    WhatsappIcon,
} from "react-share";
import DateCalendarScreen from './DateCalendarScreen.web';
import {
    calendar
} from './assets';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End
// Customizable Area Start
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
// Customizable Area End
class FilterScreen extends FilterScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    render() {
        return (
            <>
                <Popover
                    id="1"
                    anchorEl={this.props.filterAnchorEl}
                    open={Boolean(this.props.filterAnchorEl)}
                    onClose={this.props.popoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{
                        marginTop: '5px',
                        height: 'auto'
                    }}
                >
                    <Box style={{
                        width: "auto",
                        padding: "10px 20px 30px"
                    }}>
                        <Typography
                            style={{
                                textTransform: "none",
                                fontSize: "1.25rem",
                                fontWeight: 600,
                                marginBottom: 8,
                            }}
                            className={this.props.classes.title_font}
                        >
                            Filters
                        </Typography>
                        <Box
                            style={{
                                borderBottom: "solid 0.5px #3f526d",
                                marginBottom: 10,
                            }}
                        >
                        </Box>
                        <Box>
                            <Typography
                                style={{
                                    textTransform: "none",
                                    fontSize: "1rem",
                                    fontFamily: "Open Sans",
                                    fontWeight: 600,
                                    color: "#3f526d",
                                    marginBottom: 10
                                }}
                            >
                                Start Date
                            </Typography>
                            <Box style={{
                                width: "100%",
                                padding: "9px 20px",
                                borderRadius: 4,
                                border: "solid 0.3px rgba(63, 82, 109, 0.5)",
                                backgroundColor: "#f9f8fd",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}>
                                <Typography style={{
                                    fontFamily: "Open sans",
                                    fontSize: "1rem",
                                    fontWeight: 600,
                                    opacity: 0.32
                                }}>
                                    {this.state.startDate ? this.state.startDate : <>Start Date</>}
                                </Typography>
                                <img
                                    src={calendar}
                                    style={{
                                        width: 24,
                                        height: 24,
                                        cursor: "pointer",
                                    }}
                                    onClick={(event: any) => this.setState({ start_type: !this.state.start_type, datecalendarAnchorEl: event.currentTarget })}
                                />

                            </Box>
                            {this.state.datecalendarAnchorEl && <DateCalendarScreen popoverClose={undefined} type='start' datecalendarAnchorEl={this.state.datecalendarAnchorEl} navigation={undefined} />}

                        </Box>

                        <Box>
                            <Typography
                                style={{
                                    textTransform: "none",
                                    fontSize: "1rem",
                                    fontFamily: "Open Sans",
                                    fontWeight: 600,
                                    color: "#3f526d",
                                    marginBottom: 10,
                                    marginTop: 18,
                                }}
                            >
                                End Date
                            </Typography>
                            <Box style={{
                                width: "100%",
                                padding: "9px 20px",
                                borderRadius: 4,
                                border: "solid 0.3px rgba(63, 82, 109, 0.5)",
                                backgroundColor: "#f9f8fd",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}>
                                <Typography style={{
                                    fontFamily: "Open sans",
                                    fontSize: "1rem",
                                    fontWeight: 600,
                                    opacity: 0.32
                                }}>
                                    {this.state.endDate ? this.state.endDate : <> End Date </>}
                                </Typography>
                                <img
                                    src={calendar}
                                    style={{
                                        width: 24,
                                        height: 24,
                                        cursor: "pointer",
                                    }}
                                    onClick={(event: any) => this.setState({ datecalendarAnchorEl: event.currentTarget })}
                                />

                            </Box>
                            {this.state.datecalendarAnchorEl && <DateCalendarScreen popoverClose={this.onClose} type='end' datecalendarAnchorEl={this.state.datecalendarAnchorEl} navigation={undefined} />}
                        </Box>
                        <Grid container style={{ marginTop: 30 }}>
                            <Grid item xs={12}>
                                <Button
                                    disableRipple={true}
                                    style={{
                                        borderRadius: '5px',
                                        color: "#fff",
                                        textTransform: "capitalize",
                                        fontSize: "1rem",
                                        marginRight: 12,
                                        padding: "9px 40px"
                                    }}
                                    className={this.props.classes.button_color}
                                    onClick={this.applyfilter}
                                >
                                    Apply
                                </Button>
                                <Button
                                    disableRipple={true}
                                    variant="contained"
                                    style={{
                                        borderRadius: 6,
                                        fontSize: "1rem",
                                        fontFamily: "Open sans",
                                        fontWeight: 600,
                                        color: "#3f526d",
                                        textTransform: "none",
                                        border: "solid 0.5px #471c51",
                                        backgroundColor: "#fff",
                                        padding: "9px 40px",
                                        boxShadow: "none"
                                    }}
                                    onClick={this.props.popoverClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Popover>
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
    FilterScreen as React.ComponentType<any>
)
  // Customizable Area End