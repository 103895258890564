// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import axios from 'axios';
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End


export interface Props {
    // Customizable Area Start
    closeModalFun: any;
    logoutCloseModalFun: any;
    navigation: any;
    logoutModal: boolean;
    classes: any
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    closeModal: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class LogoutModalController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            closeModal: false,
        };
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }
    logoutHandleClick = () => {
        if (localStorage.getItem('token') && localStorage.getItem('user_data') && localStorage.getItem('device_id')) {
            const user_data = localStorage.getItem('user_data');
            const data = JSON.parse(user_data || '{}');
            this.deleteFirebaseToken(localStorage.getItem('token'), localStorage.getItem('device_id'), data.school_id)
        }
        localStorage.removeItem('token');
        localStorage.removeItem('selectedMenuId');
        localStorage.removeItem('urlData');
        localStorage.removeItem('account_type');
        localStorage.removeItem('customThemeID');
        localStorage.removeItem('id');
        localStorage.removeItem('epr_ru');
        localStorage.removeItem('MenuOpen');
        localStorage.removeItem('editGoalId');
        localStorage.removeItem('user_login');
        localStorage.removeItem('fireBaseToken');
        localStorage.removeItem("expireTokenTime")
        localStorage.removeItem('device_id')

        this.props.logoutCloseModalFun(this.state.closeModal)

    }
    modalhandleClose = () => {
        this.setState({ closeModal: true })
        console.log("modal closed", this.state.closeModal)
        this.props.closeModalFun(this.state.closeModal)
    }
    deleteFirebaseToken = (token: any, deviceId: any, schoolId: any) => {
        const header = {
            'Content-Type': configJSON.validationApiContentType,
            token: token,
            school: schoolId

        };
        axios({
            method: 'delete',
            url:
                configJSON.userManagment + `/account_block/devices/${deviceId}`,
            headers: header,
        })
            .then((response: any) => {
                console.log(response)

            })
            .catch((s3error: any) => {
                console.log(s3error);
            });
    };
    // Customizable Area End
}


