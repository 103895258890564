// Customizable Area Start
import React from 'react';
import 'react-phone-input-2/lib/style.css';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import AlertModal from '../../alert/src/AlertModal.web';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import 'react-datetime/css/react-datetime.css';
import './TeacherAccountRegistration.css';
import { demoSchoolIcon, topNavigation, upload } from './assets';
import LogoAndPictureController, {
  Props,
} from './LogoAndPictureController.web';
import OnBoardingSideBar from './OnBoardingSideBar.web';
import Spinner from '../../shared/SideBar/src/Spinner';
import HOCAuth from '../../shared/Header/src/HOCAuth.web';
import "./LogoAndPicture.web.css";
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
import { withStyles } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class LogoAndPicture extends LogoAndPictureController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    return (
      <>
        <Grid container>
          {this.state.loadingSpinner && (
            <Spinner spinnerModal={this.state.loadingSpinner} />
          )}

          <Grid item xs={12} sm={3} md={3} lg={3}>
            <OnBoardingSideBar activePage={2} />
          </Grid>
          <Grid
            item
            sm={9}
            md={9}
            lg={9}
            xs={12}
            className="LogoAndPictureParentBox"
          >
            <Grid item xs={12}>
              <div className={`textlabel_welcomeText ${this.props.classes.theme_font}`}>Welcome to 21K School</div>
            </Grid>

            <Grid
              container
              className="InnerContainer"
            >
              <span className={`textlabel_logo ${this.props.classes.theme_font}`}>Logo and printables</span>

              <Grid container spacing={4}>
                <Grid
                  item
                  sm={12}
                  md={3}
                  lg={3}
                  xs={12}
                  className="Mt5"
                >
                  <div
                    className="ImgDiv"
                  >
                    <img
                      alt="img"
                      src={
                        this.state.schoolLogo.logo &&
                        this.state.schoolLogo.logo.localUrl
                          ? this.state.schoolLogo.logo.localUrl
                          : demoSchoolIcon
                      }
                      className="ImgStyling"
                    />
                  </div>
                  {this.state.schoolLogoError && (
                    <FormHelperText
                      className="LogoErrorText"
                    >
                      School logo is required.
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item sm={12} md={9} lg={9} xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <span className={`textlabel_school ${this.props.classes.theme_font}`}>School Logo</span>
                    </Grid>
                    <Grid item xs={12} className="Mt14">
                      <div className={`textlabel_school_des ${this.props.classes.theme_font}`}>
                        School logo will appear on the login and dashboard
                        pages. The logo should be transparent and width should
                        be less than 200 pixels. The supported file formats are:
                        jpg, jpeg, gif, png.
                      </div>
                    </Grid>
                    <Grid item xs={12} className="px6">
                      <Grid container spacing={4} className="Mt1">
                        <Grid item sm={6} md={4} lg={4} xs={12}>
                          <input
                            className="Hidden"
                            id="schoolLogo1"
                            name="schoolLogo1"
                            type="file"
                            accept="image/png, image/jpg, image/jpeg, image/gif )"
                            onChange={(event: React.ChangeEvent<any>) => {
                              this.handleUpload(event, 'schoolLogo');
                            }}
                          />
                          <label
                            htmlFor="schoolLogo1"
                            className="W100"
                          >
                            <Button
                              className={`${this.props.classes.theme_font} UpdateLogoBtn`}
                              component="span"
                            >
                              {this.state.schoolLogo.logo &&
                              this.state.schoolLogo.logo.localUrl
                                ? 'Update Logo'
                                : 'Upload Logo'}
                            </Button>
                          </label>
                        </Grid>
                        <Grid item sm={6} md={4} lg={4} xs={12}>
                          {' '}
                          <Button
                            className={`${this.props.classes.theme_font} DeleteLogoBtn`}
                            onClick={() => {
                              let data: any = this.state.schoolLogo;
                              data.logo = {};
                              this.setState({ schoolLogo: data });
                            }}
                          >
                            Delete Logo
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={4} className="Mt20">
                <Grid
                  item
                  sm={12}
                  md={3}
                  lg={3}
                  xs={12}
                  className="Mt5"
                >
                  <div
                    style={{
                      padding:
                        this.state.topNavigation.logoType === 'Square Format'
                          ? '28px 39.1px 27.7px 40px'
                          : '28px 0',
                    }}
                    className="TopNavLogoDiv"
                  >
                    <img
                      alt="img"
                      src={
                        this.state.topNavigation.logo &&
                        this.state.topNavigation.logo.localUrl
                          ? this.state.topNavigation.logo.localUrl
                          : topNavigation
                      }
                      style={{
                        width:
                          this.state.topNavigation.logoType === 'Square Format'
                            ? '7.0625rem'
                            : '100%',
                        height:
                          this.state.topNavigation.logoType === 'Square Format'
                            ? '7.0625rem'
                            : '5.0625rem',
                      }}
                      className="ObjectFitContain"
                    />
                  </div>
                  {this.state.topNavigationLogoError && (
                    <FormHelperText
                      className="LogoErrorText"
                    >
                      Top Navigation logo is required.
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item sm={12} md={9} lg={9} xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <span className={`textlabel_school ${this.props.classes.theme_font}`}>Top Navigation</span>
                    </Grid>
                    <Grid item xs={12} className="Mt14">
                      <div className={`textlabel_school_des ${this.props.classes.theme_font}`}>
                        School logo will appear on the login and dashboard
                        pages. The logo should be transparent and width should
                        be less than 200 pixels. The supported file formats are:
                        jpg, jpeg, gif, png.
                      </div>
                    </Grid>
                    <Grid item xs={12} className="px6">
                      <Grid container spacing={4} className="Mt1">
                        <Grid item sm={6} md={4} lg={4} xs={12}>
                          <input
                            className='Hidden'
                            id="contained-button-file"
                            name="schoolLogo2"
                            type="file"
                            accept="image/png, image/jpg, image/jpeg, image/gif )"
                            onChange={(event: React.ChangeEvent<any>) => {
                              this.handleUpload(event, 'topNavigation');
                            }}
                          />
                          <label
                            htmlFor="contained-button-file"
                            className="W100"
                          >
                            <Button
                              className={`UpdateLogoBtn ${this.props.classes.theme_font}`}
                              component="span"
                            >
                              {this.state.topNavigation.logo &&
                              this.state.topNavigation.logo.localUrl
                                ? 'Update Logo'
                                : 'Upload Logo'}
                            </Button>
                          </label>
                        </Grid>
                        <Grid item sm={6} md={4} lg={4} xs={12}>
                          {' '}
                          <Button
                            className={`${this.props.classes.theme_font} DeleteLogoBtn`}
                            onClick={() => {
                              let data: any = this.state.topNavigation;
                              data.logo = {};
                              this.setState({ topNavigation: data });
                            }}
                          >
                            Delete Logo
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="LogoTypeDiv"
                    >
                      <Grid
                        container
                        spacing={0}
                        className="AlignCenter"
                      >
                        <Grid item sm={12} md={4} lg={4} xs={12}>
                          <span className={`text_logo_type_heading ${this.props.classes.theme_font}`}>Logo Type</span>
                        </Grid>
                        <Grid item sm={6} md={4} lg={4} xs={6}>
                          <Grid container>
                            <Grid
                              item
                              xs={2}
                              className="FlexCenterCenter"
                              onClick={() => {
                                const data: any = this.state.topNavigation;
                                data.logoType = 'Square Format';
                                this.setState({ topNavigation: data });
                              }}
                            >
                              <div
                                className={`${this.state.topNavigation.logoType === 'Square Format' ? "checkbox_button_checked" : "checkbox_button"}`}
                              />
                            </Grid>
                            <Grid item xs={10}>
                              <span className={`text_logo_type ${this.props.classes.theme_font}s`}>Square Format</span>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm={6} md={4} lg={4} xs={6}>
                          <Grid container>
                            <Grid
                              item
                              xs={2}
                              className="FlexCenterCenter"
                              onClick={() => {
                                const data: any = this.state.topNavigation;
                                data.logoType = 'Wide Format';
                                this.setState({ topNavigation: data });
                              }}
                            >
                              <div
                                className={`${this.state.topNavigation.logoType === 'Wide Format' ? "checkbox_button_checked" : "checkbox_button"}`}
                              />
                            </Grid>
                            <Grid item xs={10}>
                              <span className={`text_logo_type ${this.props.classes.theme_font}`}>Wide Format</span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={4} className="Mt20">
                <Grid
                  item
                  sm={12}
                  md={3}
                  lg={3}
                  xs={12}
                  className="Mt5"
                >
                  <div
                    style={{
                      padding:
                        this.state.printLogo.logoAppearance ===
                        'Full size (100% width)'
                          ? '0'
                          : '28px 39.1px 27.7px 40px',
                    }}
                    className="TopNavLogoDiv"
                  >
                    <img
                      alt="img"
                      src={
                        this.state.printLogo.logo &&
                        this.state.printLogo.logo.localUrl
                          ? this.state.printLogo.logo.localUrl
                          : topNavigation
                      }
                      style={{
                        width:
                          this.state.printLogo.logoAppearance ===
                          'Full size (100% width)'
                            ? '11rem'
                            : '7.1875rem',
                        height:
                          this.state.printLogo.logoAppearance ===
                          'Full size (100% width)'
                            ? '10.605rem'
                            : '7.0625rem',
                      }}
                      className="ObjectFitContain"
                    />
                  </div>
                  {this.state.printLogoError && (
                    <FormHelperText
                      className="LogoErrorText"
                    >
                      Print logo is required.
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item sm={12} md={9} lg={9} xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <span className={`textlabel_school ${this.props.classes.theme_font}`}>Print Logo</span>
                    </Grid>
                    <Grid item xs={12} className="Mt14">
                      <div className={`textlabel_school_des ${this.props.classes.theme_font}`}>
                        School logo will appear on the login and dashboard
                        pages. The logo should be transparent and width should
                        be less than 200 pixels. The supported file formats are:
                        jpg, jpeg, gif, png.
                      </div>
                    </Grid>
                    <Grid item xs={12} className="px6">
                      <Grid container spacing={4} className="Mt1">
                        <Grid item sm={6} md={4} lg={4} xs={12}>
                          <input
                            className='Hidden'
                            id="contained-button-printLogo"
                            name="printLogo"
                            type="file"
                            accept="image/png, image/jpg, image/jpeg, image/gif )"
                            onChange={(event: React.ChangeEvent<any>) => {
                              this.handleUpload(event, 'printLogo');
                            }}
                          />
                          <label
                            htmlFor="contained-button-printLogo"
                            className="W100"
                          >
                            <Button
                              fullWidth
                              className={`UpdateLogoBtn ${this.props.classes.theme_font}`}
                              component="span"
                            >
                              {this.state.printLogo.logo &&
                              this.state.printLogo.logo.localUrl
                                ? 'Update Logo'
                                : 'Upload Logo'}
                            </Button>
                          </label>
                        </Grid>
                        <Grid item sm={6} md={4} lg={4} xs={12}>
                          {' '}
                          <Button
                            className={`DeleteLogoBtn ${this.props.classes.theme_font}`}
                            onClick={() => {
                              let data: any = this.state.printLogo;
                              data.logo = {};
                              this.setState({ printLogo: data });
                            }}
                          >
                            Delete Logo
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="LogoTypeDiv"
                    >
                      <Grid
                        container
                        spacing={0}
                        className="AlignCenter"
                      >
                        <Grid item sm={12} md={4} lg={4} xs={12}>
                          <span className={`text_logo_type_heading ${this.props.classes.theme_font}`}>
                            Logo Appearance
                          </span>
                        </Grid>

                        <Grid item sm={6} md={4} lg={4} xs={12}>
                          <Grid container>
                            <Grid
                              item
                              xs={2}
                              className="FlexCenterCenter"
                              onClick={() => {
                                const data: any = this.state.printLogo;
                                data.logoAppearance = 'Normal (30% width)';
                                this.setState({ printLogo: data });
                              }}
                            >
                              <div
                                className={`${this.state.printLogo.logoAppearance === 'Normal (30% width)' ? "checkbox_button_checked": "checkbox_button"}`}
                              />
                            </Grid>
                            <Grid item xs={10}>
                              <span className={`text_logo_type ${this.props.classes.theme_font}`}>
                                Normal (30% width)
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm={6} md={4} lg={4} xs={12}>
                          <Grid container>
                            <Grid
                              item
                              xs={2}
                              className="FlexCenterCenter"
                              onClick={() => {
                                const data: any = this.state.printLogo;
                                data.logoAppearance = 'Full size (100% width)';
                                this.setState({ printLogo: data });
                              }}
                            >
                              <div
                                className={`${this.state.printLogo.logoAppearance === 'Full size (100% width)' ? "checkbox_button_checked" : "checkbox_button"}`}
                              />
                            </Grid>
                            <Grid item xs={10}>
                              <span className={`text_logo_type ${this.props.classes.theme_font}`}>
                                Full size (100% width)
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className="UploaMaindDiv"
            >
              <span className={`textlabel_picture ${this.props.classes.theme_font}`}>Pictures</span>
              <Grid container spacing={5}>
                <Grid item sm={4} md={2} lg={2} xs={4}>
                  <div
                    className='UploadInnerBox'
                  >
                    <input
                      className="Hidden"
                      id="contained-button-file1"
                      multiple
                      type="file"
                      accept="(image/png, image/jpg, image/jpeg, image/gif )"
                      onChange={(event: React.ChangeEvent<any>) => {
                        this.handleUpload(event, '');
                      }}
                    />
                    <label htmlFor="contained-button-file1">
                      <img
                        alt="img"
                        src={upload}
                        className="UploadImg"
                      />
                    </label>
                  </div>
                </Grid>
                <Grid item sm={12} md={10} lg={10} xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={`textlabel_school_des ${this.props.classes.theme_font}`}>
                        Upload Images of your facilty here the supported file
                        formats are: jpg, jpeg, gif, png.
                      </div>
                    </Grid>
                    <Grid item xs={12} className="LogoPicturePadBox">
                      <Grid container spacing={4} className="Mt1">
                        {this.state.uploadFile
                          .slice(0, 6)
                          .map((_data: any, index: any) => {
                            return (
                              <Grid
                                item
                                sm={4}
                                md={2}
                                lg={2}
                                xs={4}
                                className="FlexCenterCenter"
                              >
                                <div
                                  className="UploadFile"
                                >
                                  <img
                                    src={_data.localUrl}
                                    alt="img"
                                    className="UploadFileImg"
                                  />
                                </div>
                                {this.state.uploadFile.length > 6 &&
                                  index == 5 && (
                                    <span
                                      className='UploadFileLength'
                                    >
                                      +{this.state.uploadFile.length - 6}
                                    </span>
                                  )}
                              </Grid>
                            );
                          })}
                        {this.state.uploadFile.length < 6 &&
                          _.times(6 - this.state.uploadFile.length, () => {
                            return (
                              <Grid item sm={4} md={2} lg={2} xs={4}>
                                <div
                                  className='EmptyDiv'
                                />
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className="NextBtnContainer"
            >
              <Button
                className="NextButton"
                onClick={(event: any) => this.goNext(event)}
              >
                Next
              </Button>
            </Grid>
          </Grid>
          <AlertModal
            alertModal={this.state.alertModal}
            isTeacher={false}
            onCloseModal={() => {
              this.setState({ alertModal: false });
            }}
            alertTitle="Alert!"
            alertMessage={this.state.alertMessage}
            buttonText="OK"
          />
        </Grid>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(LogoAndPicture, 'SuperAdminAccount'));
// Customizable Area End
