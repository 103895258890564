// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showLoader: boolean;
  selectedTab: any;
  selectedIndex: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
export const invalidCharsForNumberType = ["-", "e", "+", "E"];
// Customizable Area End
export default class CreateCustomReportCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      showLoader: false,
      selectedTab: "termsDetails",
      selectedIndex: 0,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  
  handleSelectTab = (tab: string, key: number) => {
    this.setState({ selectedTab: tab, selectedIndex: key });
  };

  // Customizable Area End
}
