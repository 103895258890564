// Customizable Area Start
import React from "react";
import { Grid, Modal, Button, Typography, TextField } from "@material-ui/core";
import MeetingFeedbackController from "./MeetingFeedbackController.web";
import { amazing, audio } from "../../assets";
import "./MeetingFeedbackTwo.web.css";
import MeetingFeedbackSuccessPopup from "./MeetingFeedbackSuccessPopup.web";
import AlertModal from "../../../../alert/src/AlertModal.web";
// import MeetingFeedbackSuccessPopup from "./"
// Customizable Area End

// Customizable Area Start
// Customizable Area End
class MeetingFeedbackTwo extends MeetingFeedbackController {
  // Customizable Area Start
  render() {
    // console.log("feedback res", this.state.feedbackResponse);

    const { openModal, onCloseModal } = this.props;

    return (
      <>
        <Modal
          open={openModal}
          onClose={onCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            style={{
              justifyContent: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "30px 30px",
              width: "500px",
            }}
            className="modal-width-90"
          >
            <div className="feedback-form-container">
              <div className="feedback-form-heading">
                <Typography
                  className="font-family-feedback"
                  variant="h5"
                  style={{
                    fontSize: "18px",
                    letterSpacing: "1.78px",
                    color: "#3f526d",
                    fontWeight: "bold",
                  }}
                >
                  Feedback Form
                </Typography>
                <Typography
                  className="font-family-feedback"
                  variant="h6"
                  style={{
                    fontSize: "14px",
                    letterSpacing: "1.34px",
                    color: "#3f526d",
                  }}
                >
                  Please help us know further what didn't went well:
                </Typography>
                <hr style={{ border: "1px solid #d3defa", margin: "15px 0" }} />
              </div>
              {this.state.secondFeedbackQuestions.map(
                (
                  item: {
                    img: any;
                    question: string;
                    chooseActive: null | string;
                  },
                  index: number
                ) => (
                  <div key={index} className="feedback-questions-container">
                    <div className="feedback-questions">
                      <div className="questions">
                        <img
                          className="question-icons"
                          height={30}
                          width={30}
                          src={item?.img}
                          alt="na"
                        />
                        <Typography
                          className="font-family-feedback"
                          variant="h4"
                          style={{
                            fontSize: "13px",
                            color: "#3f526d",
                            letterSpacing: "1.50px",
                            fontWeight: 500,
                          }}
                        >
                          {item?.question}
                        </Typography>
                      </div>
                      <div className="answer-button">
                        <Button
                          onClick={() => this.handleSelectAnswer(index, "YES")}
                          className={`${item?.chooseActive !== null &&
                            item?.chooseActive === "YES"
                            ? "active-btn"
                            : "inactive-btn"
                            } btn`}
                        >
                          YES
                        </Button>
                        <Button
                          onClick={() => this.handleSelectAnswer(index, "NO")}
                          className={`${item?.chooseActive !== null &&
                            item?.chooseActive === "NO"
                            ? "active-btn"
                            : "inactive-btn"
                            } btn`}
                        >
                          NO
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              )}
              <div className="feedback-details-container">
                <hr
                  style={{ border: " 1px solid #d3defa", margin: "0 0 15px 0" }}
                />
                <Typography
                  className="font-family-feedback"
                  variant="h5"
                  style={{
                    color: "#3f526d",
                    fontSize: "14px",
                    letterSpacing: "1.38px",
                  }}
                >
                  Any other feedback that you would like to share?
                </Typography>
                <Typography
                  className="font-family-feedback"
                  variant="h5"
                  style={{
                    color: "#3f526d",
                    fontSize: "14px",
                    letterSpacing: "1.38px",
                    marginBottom: "10px",
                  }}
                >
                  Please explain in detail .
                </Typography>
                <TextField
                  // className="font-family-feedback"
                  placeholder=" Write your comment here...."
                  // id="outlined-basic"
                  InputProps={{ disableUnderline: true }}
                  style={{
                    width: "100%",
                    height: "50px",
                    border: "1px solid #ebebeb",
                    borderRadius: "5px",
                    marginBottom: "10px",
                    outline: "none !important",
                    outlineColor: "transparent !important",
                    overflowY: "scroll",
                  }}
                  // variant="outlined"
                  multiline
                  onChange={(e) => {
                    this.setState({ formInput: e.target.value });
                  }}
                  type="text"
                  className="details-input font-family-feedback"
                />
              </div>
              <div className="submit-button-container">
                <Button
                  className="form-btn"
                  style={{
                    width: "80px",
                    height: "40px",
                    marginRight: "10px",
                    borderRadius: "6",
                    border: "1px solid #af38cb",
                    background:
                      "linear-gradient(to top, #af38cb, #471c51 200%)",
                    textTransform: "capitalize",
                  }}
                  onClick={this.handleSecondSubmit}
                >
                  <Typography style={{ color: "white" }}>Submit</Typography>
                </Button>
                <Button
                  className="form-btn"
                  style={{
                    width: "80px",
                    height: "40px",
                    marginRight: "10px",
                    borderRadius: "4px",
                    marginLeft: "50x",
                    border: "1px solid #471c51",
                    background: "#fff",
                    textTransform: "capitalize",
                  }}
                  onClick={onCloseModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Grid>
        </Modal>
        <AlertModal
          alertModal={this.state.feedBackError}
          onCloseModal={() => { this.setState({ feedBackError: false }) }}
          alertTitle="Alert!"
          alertMessage={"Feedback form is empty."}
          buttonText="OK"
          isTeacher={false}
        />
        <MeetingFeedbackSuccessPopup
          openModal={this.state.openSuccessPopup}
          onCloseModal={this.handleSuccessToggle}
        />
        {/* {this.state.openSuccessPopup && console.log("popup")} */}
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default MeetingFeedbackTwo;
// Customizable Area End
