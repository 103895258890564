// Customizable Area Start
import React from "react";
import {
    StyleSheet,
    Platform,
} from "react-native";
import { Chart } from "react-google-charts";
import AlertModal from "../../../../alert/src/AlertModal.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import Calendar from 'react-calendar';
import "../AddStudent.web.css";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ReactPaginate from "react-paginate";
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FileDrop } from 'react-file-drop';
export const configJSON = require("./config.js");
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    CircularProgress,
    IconButton,
    Link,
    Menu,
    MenuItem,
    NativeSelect,
    TextField,
    Dialog,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Divider,
    Popover,
    DialogTitle
} from "@material-ui/core";
import {
    Search,
    Replay,
    NotificationsNone,
    HeadsetMicOutlined,
    Title,
    TitleOutlined,
    KeyboardArrowLeft,
    EditOutlined,
    MoreVertOutlined,
    KeyboardArrowDown,
    ArrowDropDown,
    BorderBottom
} from "@material-ui/icons";

import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import PendingAccountController, { Props } from './PendingAccountController.web';

import moment from "moment";
import CloseIcon from '@material-ui/icons/Close';
import { adminDownload, adminFilter, approve, cancelOption, driveEdit, editAction, editImg, fileuploaded, moreOption, reject, send } from "../assets";

import { AnySrvRecord } from "dns";
import { filter } from "../../assets";
import { xls } from "../../Attendance/assets";
import AddTeacher from "../Teacher/AddTeacher.web";
import AddStudent from "../Student/AddStudent.web";
import CsvError from "../CsvError.web";
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
const titleDiv = {
    fontFamily: "Open sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: 'center',
    color: "#3f526d",
    marginBottom: '14px',
    textTransform: 'capitalize'
} as const;

const BootstrapInput1 = withStyles(() => ({
    input: {
        width: "100%",
        // borderRadius: "8.8px",
        textAlign: "start",
        color: "#3f526d",
        fontSize: "1rem",
        fontFamily: "Open sans",
        opacity: 0.32,
        border: "solid 0.3px rgba(63, 82, 109, 0.5)"

    },
}))(InputBase);

const forrmInput_textfield = {
    width: "100%",
    borderRadius: "4px",
    textAlign: "start",
    color: "#3f526d",
    fontSize: "1rem",
    fontFamily: "Open sans",
} as const;

// Customizable Area End
export class PendingAccount extends PendingAccountController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { studentSubjects, searchResult } = this.state;
        return (
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                style={{
                    backgroundColor: "white",
                    borderRadius: 8,
                    margin: "19px 0 0",
                    //boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                    //padding: "25px 19px 0px 20px",
                }}
            >
                {this.state.loadingSpinner && <Spinner spinnerModal={this.state.loadingSpinner} />}
                <Box
                    display="flex"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        alignItems="center"

                        width="85%"
                        style={{
                            borderRadius: 8,
                            padding: '1% 1.2% 1% 1.7%'
                        }}
                        className={this.props.classes.border_Color}
                    >
                        <input
                            placeholder="Search for principal, teacher, student, parents…"
                            value={this.state.searchQuery}
                            style={{
                                border: "none",
                                outline: "none",
                                color: "#484848",
                                opacity: 0.55,
                                fontSize: '1rem',
                                fontWeight: 600,
                                width: "100%",
                                paddingRight: '1%',
                                paddingLeft: '1%'
                            }}
                            onChange={((event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ searchQuery: event.target.value }, () => {
                                })
                            })}
                            onKeyPress={(event: any) => {
                                if (event.key === "Enter") {
                                    event.preventDefault()
                                    if (this.state.applyFilterStudent && this.state.searchQuery.trim().length !== 0) {
                                        this.setState({ currentPage: 0 }, () => this.get_filter_student())
                                    }
                                    else if (this.state.searchQuery.trim().length !== 0) {
                                        this.setState({ currentPage: 0 }, () => this.search_student())
                                    } else if (this.state.applyFilterStudent && this.state.searchQuery.trim().length === 0) {
                                        this.setState({ currentPage: 0 }, () => this.get_filter_student())
                                    } else { this.setState({ currentPage: 0 }, () => this.get_pending_account()) }

                                }
                            }}
                        />
                        <Search style={{
                            marginRight: '1%',
                            cursor: 'pointer'
                        }}
                            className={this.props.classes.icon_color}
                            onClick={() => {
                                if (this.state.applyFilterStudent && this.state.searchQuery.trim().length !== 0) {
                                    this.setState({ currentPage: 0 }, () => this.get_filter_student())
                                }
                                else if (this.state.searchQuery.trim().length !== 0) {
                                    this.setState({ currentPage: 0 }, () => this.search_student())
                                } else if (this.state.applyFilterStudent && this.state.searchQuery.trim().length === 0) {
                                    this.setState({ currentPage: 0 }, () => this.get_filter_student())
                                } else { this.setState({ currentPage: 0 }, () => this.get_pending_account()) }

                            }} />
                    </Box>
                    <Box className="filterright"
                        style={{
                            width: '15%',
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                            alignItems: "center",

                        }}
                    >
                        <Button
                            disableRipple={true}
                            variant="contained"
                            fullWidth
                            style={{
                                marginLeft: '20px',

                                textTransform: "capitalize",
                                fontSize: "1.25rem",
                                fontWeight: 600,
                                padding: "7px 15px 7px",
                                borderRadius: 6,
                                boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.1)",
                                backgroundColor: "#fff",
                                color: "#3f526d"
                            }}
                            id="1"
                            onClick={(event: any) => { this.setState({ anchorEl: event.currentTarget }) }}
                        >
                            <img src={filter} style={{
                                width: 26,
                                height: 26,
                                margin: "0 18px 0px 0"
                            }} />
                            Filter
                        </Button>
                    </Box>

                </Box>

                <Grid container style={{ marginTop: '2%', padding: '2% 2% 3%', borderRadius: 8, boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)' }}>

                    <Grid item xs={12} style={{ marginTop: '3%' }}>

                        <TableContainer >
                            <Table style={{ width: '100%' }} aria-label="simple table">
                                <TableHead style={{ borderBottom: 'none' }}>
                                    <TableRow>
                                        <TableCell align="left" className={this.props.classes.subHeading_font} style={{ width: '35%', fontSize: '1.25rem', fontWeight: 600, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, padding: '12px 12px 12px 40px', lineHeight: 'normal', backgroundColor: "#ded9ff", borderBottom: 'none' }}>User Name</TableCell>
                                        <TableCell align="left" className={this.props.classes.subHeading_font} style={{ fontSize: '1.25rem', fontWeight: 600, padding: '12px', lineHeight: 'normal', backgroundColor: "#ded9ff", borderBottom: 'none' }}>Profile Type</TableCell>
                                        <TableCell align="left" className={this.props.classes.subHeading_font} style={{ fontSize: '1.25rem', fontWeight: 600, padding: '12px', lineHeight: 'normal', backgroundColor: "#ded9ff", borderBottom: 'none' }}>Status</TableCell>
                                        <TableCell align="center" className={this.props.classes.subHeading_font} style={{ fontSize: '1.25rem', fontWeight: 600, padding: '12px 40px 12px 12px ', lineHeight: 'normal', backgroundColor: "#ded9ff", borderTopRightRadius: 6, borderBottomRightRadius: 6, borderBottom: 'none' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                {this.state.accountData.map((_data: any, index: any) => {
                                    const isSelected = this.isSelected(_data.id);
                                    return (
                                        <>
                                            <Box style={{ height: '10px' }}></Box>
                                            <TableBody>
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={_data.id}
                                                    selected={isSelected}
                                                    style={{
                                                        background: this.state.selectAll ? "#dae6d8" : "#f9f8fd"
                                                    }}
                                                >


                                                    <TableCell component="th" scope="row" align="left" style={{
                                                        padding: '12px 12px 12px 40px',
                                                        borderTopLeftRadius: 6, borderBottomLeftRadius: 6,
                                                        borderBottom: 'none',
                                                        cursor: _data.attributes.account_status === "pending" ? 'pointer' : '',
                                                        display: 'flex'
                                                    }}
                                                        onClick={(event: any) => {
                                                            this.setState({ viewStudent: _data }, () => {
                                                                if (_data.attributes.account_status === "pending") {
                                                                    this.setState({ dialogOpen: true }
                                                                    )
                                                                }
                                                            })
                                                        }}>
                                                        <Typography
                                                            style={{
                                                                fontSize: '1.125rem',
                                                                fontWeight: 600,
                                                            }}
                                                            className={this.props.classes.bodyText_font}
                                                        >
                                                            {_data.attributes.first_name} {_data.attributes.last_name}
                                                        </Typography>
                                                        <img src={driveEdit} style={{ marginLeft: 16, objectFit: 'contain', cursor: 'pointer' }}
                                                            onClick={(event: any) => {
                                                                event.stopPropagation()
                                                                this.setState({ editID: _data.id, editType: _data.attributes.type }, () => {
                                                                    this.setState({ editDialog: true })
                                                                })
                                                            }} />

                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="left" style={{ padding: 12, borderBottom: 'none' }} >
                                                        <Typography
                                                            style={{
                                                                fontSize: '1.125rem',
                                                                fontWeight: 600,
                                                                lineHeight: 'normal',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            className={this.props.classes.bodyText_font}
                                                        >
                                                            {_data.attributes.type == "StudentAccount" ? "Student" : _data.attributes.type == "TeacherAccount" ? "Teacher" : _data.attributes.type == "ParentAccount" ? "Parent" : _data.attributes.type == "PrincipalAccount" ? "Principal" : ''}

                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="left" style={{ padding: 12, borderBottom: 'none' }} >
                                                        <Typography
                                                            style={{
                                                                fontSize: '1.125rem',
                                                                fontWeight: 600,
                                                                lineHeight: 'normal',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            className={this.props.classes.bodyText_font}
                                                        >
                                                            {_data.attributes.account_status}
                                                        </Typography>

                                                    </TableCell>



                                                    <TableCell component="th" scope="row" align="center" style={{ padding: '12px 40px 12px 12px', borderBottom: 'none', borderBottomRightRadius: 6, borderTopRightRadius: 6 }} >
                                                        {_data.attributes.account_status === "pending" ? <Box style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-around'
                                                        }}>
                                                            <img src={reject} style={{ cursor: 'pointer' }}
                                                                onClick={() => this.setState({ actionId: _data.id, actionType: "reject", schoolName: _data.attributes?.school_account?.data[0]?.attributes?.school?.name, viewStudent: _data }, () => {
                                                                    this.setState({ actionModal: true })
                                                                })
                                                                    // this.reject_user(_data.id, _data.attributes?.school_account?.data[0]?.attributes?.school?.name)
                                                                }
                                                            />
                                                            <img src={approve} style={{ cursor: 'pointer' }}
                                                                onClick={() => this.setState({ actionId: _data.id, actionType: "approve", schoolName: _data.attributes?.school_account?.data[0]?.attributes?.school?.name, viewStudent: _data }, () => {
                                                                    this.setState({ actionModal: true })
                                                                })
                                                                    //this.approve_user(_data.id, _data.attributes?.school_account?.data[0]?.attributes?.school?.name)
                                                                }

                                                            />
                                                        </Box> : <Box style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-around'
                                                        }}> <span style={{ width: '32px' }}></span>
                                                            <img src={editImg} style={{ cursor: 'pointer' }}
                                                                onClick={(event: any) => {
                                                                    this.setState({ viewStudent: _data }, () => {
                                                                        if (_data.attributes.account_status === "rejected") {
                                                                            this.setState({ rejectAlert: true }
                                                                            )
                                                                        }
                                                                    })
                                                                }}
                                                            /></Box>}

                                                    </TableCell>



                                                </TableRow>


                                            </TableBody>
                                        </>
                                    )
                                })}
                            </Table>
                        </TableContainer>
                        {this.state.accountData.length === 0 && <span style={{ fontSize: '1.125rem', color: '#3f526d', fontWeight: 500, display: 'flex', justifyContent: 'center', marginTop: 10 }}>No Data Found.</span>}
                        {this.state.totalPage > 1 && <Box style={{ textAlign: "end", marginTop: 25 }}>
                            <ReactPaginate
                                previousLabel={"←   Previous"}
                                nextLabel={"Next   →	"}
                                initialPage={this.state.currentPage}
                                forcePage={this.state.currentPage}
                                pageCount={this.state.totalPage}
                                onPageChange={(e: any) => this.setState({ currentPage: e.selected }, () => {
                                    this.setState({ createSelected: [], selectAll: false })
                                    if (this.state.applyFilterStudent && this.state.searchQuery.trim().length !== 0) {
                                        this.get_filter_student()
                                    }
                                    else if (this.state.searchQuery.trim().length !== 0) {
                                        this.search_student()
                                    }
                                    else if (this.state.applyFilterStudent) {
                                        this.get_filter_student()
                                    }
                                    else {

                                        this.get_pending_account()
                                    }
                                })}
                                containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                                previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                                nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                                disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                                activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                            />
                        </Box>}
                    </Grid>
                </Grid>


                {/* Filter menu */}
                <Menu
                    // anchorOrigin={{
                    //     vertical: "bottom",
                    //     horizontal: "right",
                    // }}
                    // transformOrigin={{
                    //     vertical: "bottom",
                    //     horizontal: "right",
                    // }}
                    //aria-haspopup="true"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.onMenuClose}
                    style={{
                        marginTop: '8%',
                        marginLeft: '-4%',
                    }}
                //   MenuListProps={{
                //     "aria-labelledby": "basic-button",
                //   }}
                >
                    <Box style={{ width: 324, padding: '.625rem 1rem', }}>
                        <Typography
                            style={{
                                textTransform: "none",
                                fontSize: "1.25rem",
                                fontWeight: 600,
                            }}
                            className={this.props.classes.title_font}
                        >
                            Filters
                        </Typography>
                        <Divider style={{ margin: '8px 0', border: 'solid 0.5px #3f526d' }} />


                        <Grid container >

                            <Grid item xs={12} style={{ alignSelf: 'center', }} >
                                <Typography
                                    style={{
                                        textTransform: "none",
                                        fontSize: "1rem",
                                        fontWeight: 600,

                                    }}
                                    className={this.props.classes.subTitle_font}
                                >
                                    Profile type
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '0.625rem', cursor: "pointer" }} onClick={(event: any) => { event.stopPropagation(); console.log("run"), this.setState({ tempClass: this.state.filterUserType, classDropDownAnchorEl: event.currentTarget, selectClassDropDownDiv: true, data_Type: 'class' }) }}>


                                <Box style={{
                                    textAlign: "start",
                                    fontSize: "1rem",
                                    //opacity: 0.75,
                                    border: "solid 0.3px rgb(112 123 139 / 50%",
                                    display: "flex",
                                    justifyContent: 'space-between',
                                    padding: "10px 10px 10px 15px",
                                    borderRadius: 4,
                                    backgroundColor: "#f9f8fd",
                                    alignItems: "center",
                                    textTransform: 'capitalize'
                                    // backgroundImage: "linear-gradient(to bottom, #fff, #fff), linear-gradient(to top, #af38cb 126%, #7f6cfc -23%)"
                                }}>
                                    {this.state.filterUserType.length != 0 ?
                                        <Box className={`fileNameText22 ${this.props.classes.bodyText_font}`} style={{ display: "flex", flexDirection: "row" }}>
                                            {
                                                this.state.filterUserType.map((data1: any, index: number) => {
                                                    return (
                                                        // <Box>


                                                        //     <Typography

                                                        //         style={{
                                                        //             color: "#3f526d",
                                                        //             fontSize: "1rem",
                                                        //             fontFamily: "Open sans",
                                                        //         }}
                                                        //     >
                                                        //         {
                                                        // this.state.filterUserType.length === 1
                                                        //     ?
                                                        //     <>
                                                        //         {
                                                        this.state.accountType.filter((x: any) => x === data1)
                                                            .map((x: any) => x)
                                                        //     }
                                                        // </>
                                                        // :
                                                        // <>
                                                        //     {
                                                        //         this.state.classList.filter((x: any) => x.id === data1)
                                                        //             .map((x: any) => x.attributes?.display_name)
                                                        //     },{" "}
                                                        // </>
                                                        //         }
                                                        //     </Typography>

                                                        // </Box>
                                                    )
                                                }
                                                ).join(", ")}
                                        </Box>
                                        :
                                        <Typography style={{

                                            fontSize: "1rem",
                                            fontFamily: "Open sans",
                                            color: "rgb(63, 82, 109,0.32)"
                                        }}>
                                            Select type
                                        </Typography>
                                    }
                                    <Box style={{ display: "flex" }}>
                                        <KeyboardArrowDownIcon />
                                    </Box>
                                </Box>



                            </Grid>
                            <Popover
                                id="1"
                                anchorEl={this.state.classDropDownAnchorEl}
                                open={Boolean(this.state.classDropDownAnchorEl)}
                                onClose={() => {
                                    this.setState({
                                        classDropDownAnchorEl: null
                                    })
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                style={{
                                    height: "auto"
                                }}
                                PaperProps={{
                                    style: {
                                        width: '288px',
                                        maxHeight: '70%',
                                        padding: "20px 25px 30px 25px",
                                        borderRadius: 8,
                                        boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.2)",
                                        backgroundColor: "#fff"
                                    },
                                }}
                            >
                                <Box>

                                    {this.state.accountType
                                        .map((_dataClass: any, index: any) => {
                                            return (
                                                <Box
                                                    style={{
                                                        padding: "0px 0px 5px 0px",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        borderBottom: index < this.state.accountType.length - 1 ? "solid 0.5px rgba(63, 82, 109,0.34)" : 'none',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        {

                                                            let temp1: any = [];
                                                            temp1 = this.state.tempClass;
                                                            let temp2: any = temp1;
                                                            // let length = temp1.length === 0 ? 0 : temp1.length -1
                                                            console.log(temp1)
                                                            if (this.state.tempClass.indexOf(
                                                                _dataClass
                                                            ) > -1) {

                                                                var array: any[] = this.state.tempClass.filter((item: any) =>
                                                                    item !== _dataClass)


                                                                this.setState({ tempClass: array }, () => { })

                                                            }
                                                            else {
                                                                this.setState({ tempClass: [...this.state.tempClass, _dataClass] }, () => { })

                                                            }


                                                        }
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: 15,
                                                            fontWeight: 600,
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textTransform: 'capitalize'

                                                        }}
                                                        className={this.props.classes.bodyText_font}>
                                                        {_dataClass}
                                                    </Typography>
                                                    <Checkbox
                                                        checked={this.state.tempClass.indexOf(
                                                            _dataClass
                                                        ) > -1}
                                                        size="small"
                                                        style={{ backgroundColor: 'transparent' }}
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        className={this.props.classes.icon_color}
                                                    />
                                                </Box>)
                                        })}



                                    <Box
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "start",
                                            marginTop: 20,
                                            alignItems: "center",
                                        }}
                                    >
                                        <Button
                                            style={{
                                                borderRadius: '6px',
                                                width: "40%",
                                                height: 30,
                                                textTransform: "capitalize",
                                                fontSize: "1rem",
                                                marginRight: 12,
                                            }}
                                            className={this.props.classes.button_color}
                                            onClick={(event: any) => {
                                                event.stopPropagation();
                                                event.cancelBubble = true;
                                                console.log("run")
                                                this.setState({

                                                    filterUserType: this.state.tempClass,
                                                    classDropDownAnchorEl: null,
                                                    searchClass: ''


                                                }, () => {
                                                    this.setState({ tempClass: [] })
                                                })
                                            }}
                                        >
                                            OK
                                        </Button>
                                        <Button
                                            style={{
                                                borderRadius: 6,
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                                textTransform: "none",
                                                marginLeft: "5px",
                                                width: '40%',
                                                height: 30
                                            }}
                                            className={this.props.classes.button_color_disabled}
                                            onClick={() => {
                                                this.setState({
                                                    classDropDownAnchorEl: null,
                                                    tempClass: [],
                                                    searchClass: ''
                                                })
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Box>
                            </Popover>
                        </Grid>
                        <Grid container >

                            <Grid item xs={12} style={{ alignSelf: 'center', marginTop: '1rem' }} >
                                <Typography
                                    style={{
                                        textTransform: "none",
                                        fontSize: "1rem",
                                        fontWeight: 600,

                                    }}
                                    className={this.props.classes.subTitle_font}
                                >
                                    Status type
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '0.625rem', cursor: "pointer" }} onClick={(event: any) => { console.log("run"), this.setState({ tempFilter: this.state.filterStatus, statusDropDownAnchorEl: event.currentTarget, }, () => this.setState({ selectStatusDropDownDiv: true, data_Type: 'status' })) }}>
                                <Box style={{
                                    textAlign: "start",
                                    color: "#3f526d",
                                    fontSize: "1rem",
                                    fontFamily: "Open sans",
                                    //opacity: 0.75,
                                    border: "solid 0.3px rgb(112 123 139 / 50%",
                                    display: "flex",
                                    justifyContent: 'space-between',
                                    padding: "10px 10px 10px 15px",
                                    borderRadius: 4,
                                    backgroundColor: "#f9f8fd",
                                    alignItems: "center",
                                    textTransform: 'capitalize'
                                    // backgroundImage: "linear-gradient(to bottom, #fff, #fff), linear-gradient(to top, #af38cb 126%, #7f6cfc -23%)"
                                }}>
                                    {this.state.filterStatus.length != 0 ?
                                        <Box style={{ display: "flex", flexDirection: "row" }}>
                                            {
                                                this.state.filterStatus.map((data: any, index: number) => (
                                                    <Box>


                                                        <Typography
                                                            style={{
                                                                fontSize: "1rem",
                                                            }}
                                                            className={this.props.classes.bodyText_font}
                                                        >
                                                            {
                                                                index === this.state.filterStatus.length - 1
                                                                    ?
                                                                    <>
                                                                        {data}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {data},{" "}
                                                                    </>
                                                            }
                                                        </Typography>

                                                    </Box>
                                                ))}
                                        </Box>
                                        :
                                        <Typography style={{

                                            fontSize: "1rem",
                                            fontFamily: "Open sans",
                                            color: "rgb(63, 82, 109,0.32)"
                                        }}>
                                            Select type
                                        </Typography>
                                    }
                                    <Box style={{ display: "flex" }}>
                                        <KeyboardArrowDownIcon />
                                    </Box>
                                </Box>
                            </Grid>
                            <Popover
                                id="1"
                                anchorEl={this.state.statusDropDownAnchorEl}
                                open={Boolean(this.state.statusDropDownAnchorEl)}
                                onClose={() => {
                                    this.setState({
                                        statusDropDownAnchorEl: null
                                    })
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                style={{
                                    height: "auto"
                                }}
                                PaperProps={{
                                    style: {
                                        width: '288px',
                                        maxHeight: '70%',
                                        padding: "20px 25px 30px 25px",
                                        borderRadius: 8,
                                        boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.2)",
                                        backgroundColor: "#fff"
                                    },
                                }}
                            >
                                <Box >
                                    <Box
                                        style={{
                                            padding: "0px 0px 5px 0px",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            borderBottom: "solid 0.5px rgba(63, 82, 109,0.34)",
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            let temp = []
                                            temp = this.state.tempFilter
                                            if (this.state.tempFilter.indexOf(
                                                "rejected"
                                            ) > -1) {
                                                temp = []
                                            } else {
                                                temp = []
                                                temp.push("rejected")
                                            }
                                            this.setState({
                                                tempFilter: temp
                                            }, () => {

                                            })
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: 15,
                                                fontWeight: 600,
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",

                                            }}
                                            className={this.props.classes.bodyText_font}>
                                            Rejected
                                        </Typography>
                                        <Checkbox
                                            checked={this.state.tempFilter.indexOf(
                                                "rejected"
                                            ) > -1}
                                            size="small"
                                            style={{ backgroundColor: 'transparent' }}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            className={this.props.classes.icon_color}
                                        // onChange={(event: any) => {
                                        //     let temp = []
                                        //     temp = this.state.tempFilter
                                        //     console.log(temp)
                                        //     if (event.target.checked) {
                                        //         temp = []
                                        //         temp.push("rejected")


                                        //     } else {
                                        //         // const data = temp.filter((_data: any, index: any) => {
                                        //         //     _data !== "approved"
                                        //         // })
                                        //         // console.log("remove", data)
                                        //         // temp = data;
                                        //         temp = []

                                        //     }
                                        //     this.setState({
                                        //         tempFilter: temp
                                        //     }, () => {

                                        //     })
                                        // }}
                                        />
                                    </Box>
                                    <Box
                                        style={{

                                            padding: "5px 0px 5px 0px",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            // borderBottom: "solid 0.5px rgba(63, 82, 109,0.34)",
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            let temp = []
                                            temp = this.state.tempFilter
                                            if (this.state.tempFilter.indexOf(
                                                "pending"
                                            ) > -1) {
                                                temp = []
                                            } else {
                                                temp = []
                                                temp.push("pending")
                                            }
                                            this.setState({
                                                tempFilter: temp
                                            }, () => {

                                            })
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: 15,
                                                fontWeight: 600,
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",

                                            }}
                                            className={this.props.classes.bodyText_font}>
                                            Pending
                                        </Typography>
                                        <Checkbox
                                            checked={this.state.tempFilter.indexOf(
                                                "pending"
                                            ) > -1}
                                            size="small"
                                            style={{ backgroundColor: 'transparent' }}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            className={this.props.classes.icon_color}
                                        />
                                    </Box>


                                    <Box
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "start",
                                            marginTop: 30,
                                            alignItems: "center",
                                        }}
                                    >
                                        <Button
                                            style={{
                                                borderRadius: '6px',
                                                width: "40%",
                                                height: 30,
                                                textTransform: "capitalize",
                                                fontSize: "1rem",
                                                marginRight: 12,
                                            }}
                                            className={this.props.classes.button_color}
                                            onClick={() => {
                                                this.setState({
                                                    filterStatus: this.state.tempFilter,
                                                    statusDropDownAnchorEl: null

                                                }, () => {
                                                    this.setState({
                                                        tempFilter: []
                                                    })
                                                })
                                            }}
                                        >
                                            OK
                                        </Button>
                                        <Button
                                            style={{
                                                borderRadius: 6,
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                                textTransform: "none",
                                                marginLeft: "5px",
                                                width: '40%',
                                                height: 30
                                            }}
                                            className={this.props.classes.button_color_disabled}

                                            onClick={() => {
                                                this.setState({
                                                    statusDropDownAnchorEl: null,
                                                    tempFilter: []
                                                })
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Box>
                            </Popover>
                        </Grid>
                        <Grid container >
                            <Grid item xs={12} style={{ marginTop: '1.125rem' }} >
                                <Button
                                    style={{
                                        borderRadius: '6px',
                                        width: "40%",
                                        height: 30,
                                        textTransform: "capitalize",
                                        fontSize: "1rem",
                                        marginRight: 12,
                                    }}
                                    className={this.props.classes.button_color}
                                    //type="submit"
                                    onClick={() => this.setState({ currentPage: 0 }, () => this.get_filter_student())}

                                >
                                    Apply
                                </Button>

                                <Button
                                    style={{
                                        borderRadius: 6,
                                        fontSize: '1rem',
                                        fontWeight: 600,
                                        textTransform: "none",
                                        marginLeft: "5px",
                                        width: '40%',
                                        height: 30
                                    }}
                                    className={this.props.classes.button_color_disabled}
                                    onClick={() => { this.setState({ anchorEl: null }) }}
                                >
                                    Cancel
                                </Button>
                                {/* <Close style={{ color: "#3f526d", cursor: "pointer" }} /> */}
                            </Grid>

                        </Grid>

                    </Box>
                </Menu>
                {/* More options */}


                {/* View Dialog */}
                {Object.keys(this.state.viewStudent).length > 0 && <Dialog
                    PaperProps={{
                        style: { borderRadius: 8 }
                    }}
                    aria-labelledby="customized-dialog-title"
                    maxWidth="md"
                    open={this.state.dialogOpen}
                    onClose={() => this.setState({ dialogOpen: false })}
                    closeAfterTransition

                    disableAutoFocus={true}
                    style={{ borderRadius: 8, }}
                >

                    <Grid item xs={12} style={{
                        padding: '30px 30px 30px 50px',
                        borderRadius: 8,
                        boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
                        minWidth: '600px'
                    }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}> <img src={cancelOption} onClick={() => this.setState({ dialogOpen: false })} style={{ width: '28px', height: 28, cursor: 'pointer' }} /></Grid>
                        {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', margin: '-25px 0 20px' }}><Avatar style={{ width: '100px', height: 100, cursor: 'pointer' }} src={this.state.viewStudent.attributes.avatar === null ? "" : this.state.viewStudent.attributes.avatar} /></Grid> */}
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",

                                        }}>
                                        {this.state.viewStudent.attributes.first_name} {this.state.viewStudent.attributes.last_name}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        Role
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",

                                        }}>
                                        {this.state.viewStudent.attributes.type == "StudentAccount" ? "Student" : this.state.viewStudent.attributes.type == "TeacherAccount" ? "Teacher" : this.state.viewStudent.attributes.type == "ParentAccount" ? "Parent" : this.state.viewStudent.attributes.type == "PrincipalAccount" ? "Principal" : ''}

                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        Gender
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",

                                        }}>
                                        {this.state.viewStudent.attributes.gender}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        Creation Date
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",

                                        }}>
                                        {moment(this.state.viewStudent.attributes.created_at).format("DD/MM/YYYY")}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        DOB
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",

                                        }}>
                                        {this.state.viewStudent.attributes.date_of_birth !== null ? moment(this.state.viewStudent.attributes.date_of_birth).format("DD/MM/YYYY") : ''}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        Contact No.
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",

                                        }}>
                                        {this.state.viewStudent.attributes.full_phone_number}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        Email ID
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            opacity: 0.5
                                        }}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            textTransform: "none",
                                            fontSize: "1.25rem",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            color: "#3f526d",
                                            wordBreak: 'break-all'

                                        }}>
                                        {this.state.viewStudent.attributes.email}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>

                            <img src={editAction} style={{ objectFit: 'contain', cursor: 'pointer', marginRight: "20px", }}
                                onClick={(event: any) => {
                                    event.stopPropagation()
                                    this.setState({ editID: this.state.viewStudent.id, editType: this.state.viewStudent.attributes.type }, () => {
                                        this.setState({ editDialog: true })
                                    })
                                }} />

                            {this.state.viewStudent.attributes.account_status === "pending" && <Button

                                style={{
                                    borderRadius: 8,
                                    fontSize: '18px',
                                    fontFamily: "Open sans",
                                    fontWeight: 600,
                                    color: "#b2bac6",
                                    textTransform: "none",

                                    marginRight: "20px",
                                    backgroundColor: '#fff',
                                    border: 'solid 1px #b2bac6',

                                    width: '30%',
                                    lineHeight: 'normal'
                                    // height: 30
                                }}
                                onClick={() => this.setState({ actionId: this.state.viewStudent.id, actionType: "reject", schoolName: this.state.viewStudent.attributes?.school_account?.data[0]?.attributes?.school?.name }, () => {
                                    this.setState({ actionModal: true })
                                })
                                    //this.approve_user(_data.id, _data.attributes?.school_account?.data[0]?.attributes?.school?.name)
                                }

                            >
                                Reject
                            </Button>}
                            <Button
                                style={{
                                    borderRadius: '8px',
                                    backgroundColor: "#0c5c10",
                                    color: "#fff",
                                    width: "30%",
                                    // height: 30,
                                    textTransform: "capitalize",
                                    fontSize: "1rem",
                                    marginRight: 12,
                                    fontFamily: 'Open sans',
                                    lineHeight: 'normal'
                                }}
                                //type="submit"
                                onClick={() => this.setState({ actionId: this.state.viewStudent.id, actionType: "approve", schoolName: this.state.viewStudent.attributes?.school_account?.data[0]?.attributes?.school?.name }, () => {
                                    this.setState({ actionModal: true })
                                })
                                    //this.approve_user(_data.id, _data.attributes?.school_account?.data[0]?.attributes?.school?.name)
                                }

                            >
                                Approve
                            </Button>
                        </Grid>

                    </Grid>
                </Dialog>}


                <AlertModal
                    alertModal={this.state.alertModal}
                    isTeacher={false}
                    onCloseModal={() => { this.setState({ alertModal: false }) }}
                    alertTitle="Alert!"
                    alertMessage={this.state.alertMessage}
                    buttonText="OK"
                />
                <Modal
                    open={this.state.rejectAlert}
                    //  onClose={onCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        style={{
                            justifyContent: "center",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'white',
                            borderRadius: "29px",
                            padding: "32px 18px",
                            width: "576px",
                        }}
                    >
                        <div style={titleDiv}>Edit Action</div>
                        <Divider />

                        <Typography
                            style={{
                                margin: "30px",
                                fontFamily: "Open sans",
                                fontSize: "20px",
                                fontWeight: 500,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "center",
                                color: "#3f526d"
                            }}
                        >
                            Are you sure you want to edit action taken?
                        </Typography>

                        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                style={{

                                    color: "#3f526d",
                                    width: "22%",

                                    textTransform: "capitalize",
                                    fontSize: 18,
                                    marginRight: '25px',
                                    background: '#f9f8fd'
                                }}
                                onClick={() => {
                                    this.setState({ viewStudent: {} }, () => {
                                        this.setState({ rejectAlert: false })
                                    })
                                }}
                            >
                                No
                            </Button>
                            <Button
                                style={{
                                    color: "#fff",
                                    width: "22%",

                                    textTransform: "capitalize",
                                    fontSize: 18,
                                    backgroundColor: "#7f6cfc",
                                }}
                                onClick={() => this.setState({ dialogOpen: true }, () => this.setState({ rejectAlert: false }))}
                            >
                                Yes
                            </Button>
                        </Grid>
                    </Box>
                </Modal>
                <Modal
                    open={this.state.actionModal}
                    //  onClose={onCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        style={{
                            justifyContent: "center",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'white',
                            borderRadius: "29px",
                            padding: "32px 18px",
                            width: "576px",
                        }}
                    >
                        <div style={titleDiv}>{`${this.state.actionType} Action`}</div>
                        <Divider />

                        <Typography
                            style={{
                                margin: "30px",
                                fontFamily: "Open sans",
                                fontSize: "20px",
                                fontWeight: 500,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "center",
                                color: "#3f526d"
                            }}
                        >
                            {`Are you sure you want to ${this.state.actionType}?`}
                        </Typography>

                        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                style={{

                                    color: "#3f526d",
                                    width: "22%",

                                    textTransform: "capitalize",
                                    fontSize: 18,
                                    marginRight: '25px',
                                    background: '#f9f8fd'
                                }}
                                onClick={() => {
                                    this.setState({
                                        actionId: null,
                                        actionType: '', actionModal: false, schoolName: ''
                                    }, () => {

                                    })
                                }}
                            >
                                No
                            </Button>
                            <Button
                                style={{
                                    color: "#fff",
                                    width: "22%",

                                    textTransform: "capitalize",
                                    fontSize: 18,
                                    backgroundColor: "#7f6cfc",
                                }}
                                onClick={(event: any) => {
                                    if (this.state.actionType === 'reject') {
                                        this.reject_user(this.state.actionId, this.state.schoolName)
                                    } else if (this.state.actionType === 'approve') {
                                        console.log('+++++', this.state.viewStudent)
                                        if (this.state.viewStudent?.attributes?.type == "TeacherAccount") {
                                            this.get_restriction(event)
                                        } else {

                                            this.approve_user(this.state.actionId, this.state.schoolName)
                                        }
                                    }
                                }}
                            >
                                Yes
                            </Button>
                        </Grid>
                    </Box>
                </Modal>


                {this.state.editID !== null && this.state.editType === "TeacherAccount" && this.state.editDialog && <AddTeacher addDialog={this.state.editDialog} handleDialogClose={this.handleDialogClose} isEdit={true} id={this.state.editID} isPending={true} />}
                {this.state.editID !== null && this.state.editType === "StudentAccount" && this.state.editDialog && <AddStudent addDialog={this.state.editDialog} handleDialogClose={this.handleDialogClose} isEdit={true} id={this.state.editID} isPending={true} />}
                {this.state.teacherRestircition && <CsvError csvError={this.state.teacherRestircitionError} alertModal={this.state.teacherRestircition} teacherRestircition={this.state.teacherRestircition} onCloseModal={() => this.setState({ teacherRestircition: false, teacherRestircitionError: [], viewStudent: {}, actionModal: false })} />}

            </Box>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(PendingAccount);
 // Customizable Area End
