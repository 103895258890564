// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
// import { BrowserRouter as Router } from 'react-router-dom';
import { Router } from 'react-router';
import App from './App';
import './App.css';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { HISTORY } from '../../components/src/comman';

ReactDOM.render(
  <Router history={HISTORY}>
      <App />
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
