// Customizable Area Start
import React from "react";
// Customizable Area End

// Customizable Area Start
const CustomTimeGutterHeader = (props: any) => {
  return <span>Time</span>;
};
// Customizable Area End

// Customizable Area Start
export default CustomTimeGutterHeader;
// Customizable Area End
