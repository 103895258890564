// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
import moment from "moment";
import * as SparkMD5 from "spark-md5";
import axios from "axios";
import { computeChecksumMd5 } from "../../profiles/src/utils/common";
import convert from "xml-js";
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation: any;
  route: any;
  id: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstname: string;
  lastname: string;
  email: string;
  phonenumber: string;
  gender: string;
  dateofbirth: string;
  password: string;
  confirmPassword: string;
  imagemapcallId: any;
  avtar: any;
  clickavtar: any;
  avtarclick: boolean;
  textdatetype: string;
  fnameerror: boolean;
  fnameerrorText: string;
  lnameerror: boolean;
  lnameerrorText: string;
  emailerror: boolean;
  emailerrorText: string;
  phoneerrorText: string;
  phoneerror: boolean;
  pswderror: boolean;
  pswderrorText: string;
  cpswderror: boolean;
  cpswderrorText: string;
  loadingSpinner: boolean;
  gendererror: boolean;
  dateerror: boolean;
  uploadavtar: any;
  dateerrorText: string;
  calendarOpen: boolean;
  openToolTip: boolean;
  error: string;
  fileUploadDetail: any;
  sihnIdData: any;
  dialCode: any;
  schoolData: any;
  classList: any;
  schoolerror: boolean;
  classerror: boolean;
  class: any;
  school: any;
  classesAndSubject: any;
  selectedIndexId: any;
  subjectWithClassId: any;
  selectedClassId: any;
  finalSelectedClass: any;
  subjectError: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postLoginApiId: any;
  postPresignID: any;
  apiUploadBlockId: any;
  emailReg: RegExp;
  apigetSchoolId: any;
  apigetClassId: any;
  apigetClassDivisionlId: any = [];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.state = {
      imagemapcallId: null,
      calendarOpen: false,
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      gender: "",
      dateofbirth: "",
      avtar: configJSON.defalut_avtar,
      password: "",
      confirmPassword: "",
      textdatetype: "",
      clickavtar: [],
      error: "",
      uploadavtar: [],
      avtarclick: false,
      fnameerror: false,
      fnameerrorText: "",
      lnameerror: false,
      lnameerrorText: "",
      emailerror: false,
      emailerrorText: "",
      phoneerror: false,
      phoneerrorText: "",
      pswderror: false,
      pswderrorText: "",
      cpswderror: false,
      cpswderrorText: "",
      loadingSpinner: false,
      gendererror: false,
      dateerror: false,
      dateerrorText: "",
      fileUploadDetail: [],
      sihnIdData: [],
      dialCode: "af",
      schoolData: [],
      classList: [],
      schoolerror: false,
      classerror: false,
      school: "",
      class: [],
      classesAndSubject: [{ class: '', subject_data: [] }],
      selectedIndexId: '',
      subjectWithClassId: [],
      selectedClassId: '',
      finalSelectedClass: [],
      subjectError: false,
      openToolTip: false,
    };
    this.emailReg = new RegExp("");
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      this.onteacherSubmit(event)
    }
  }


  async componentDidMount() {
    window.addEventListener('keydown', this.handleEnter)
    this.get_schools()
    if (localStorage.getItem("user_data") !== null) {
      const user_data = localStorage.getItem("user_data");
      const data = JSON.parse(user_data || "{}");
      this.setState({
        firstname: data.first_name,
        lastname: data.last_name,
        email: data.email,
        phonenumber: data.full_phone_number,
        gender: data.gender === null ? "" : data.gender,
        dateofbirth: data.date_of_birth == "" || data.date_of_birth === null ? "" : moment(data.date_of_birth).format("DD-MM-YYYY"),
        avtar: data.avtar
      });
    }
  }
  async componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEnter)
  }
  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      switch (apiRequestCallId) {
        case this.apigetClassDivisionlId.indexOf(apiRequestCallId) === -1 ? ""
          : apiRequestCallId: {

            if (responseJson != null) {
              if (!responseJson.errors) {
                let index = this.state.subjectWithClassId

                index.map((_data: any) => {
                  if (_data.class == responseJson.class_id || parseInt(_data.class) == parseInt(responseJson.class_id)) {
                    _data.subjectList = responseJson.data
                  }

                })
                this.setState((prevState: any) => ({
                  subjectWithClassId: index,
                  loadingSpinner: false
                }))
              } else {
                this.parseApiErrorResponse(responseJson);
              }
            }
            this.parseApiCatchErrorResponse(errorReponse);
          } break;
      }

      if (apiRequestCallId === this.postLoginApiId) {
        if (responseJson != null) {
          localStorage.setItem("account_type", "TeacherAccount");
          if (!responseJson.errors) {
            localStorage.setItem(
              "user_data",
              JSON.stringify(responseJson?.data?.attributes)
            );
            localStorage.setItem("token", responseJson.meta.token);
            sessionStorage.setItem("otp-token", responseJson.meta.token);
            localStorage.setItem("class-ids", responseJson?.data?.attributes?.class_id_data)
            localStorage.setItem("school-ids", responseJson?.data?.attributes?.school_id)
            sessionStorage.setItem(
              "sms-token",
              responseJson.meta.sms_token
            );
            this.props.navigation.navigate("AccountPhoneVerify");
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false });
      }
      else if (apiRequestCallId === this.apigetClassId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            let arr: { class: any; subjectList: never[]; }[] = [];
            responseJson.data.map((_data: any) => {
              arr.push({ class: _data.id, subjectList: [] })
            })
            this.setState({ classList: responseJson.data, subjectWithClassId: arr })
          }
          else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
      else if (apiRequestCallId === this.apigetSchoolId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ schoolData: responseJson.data })
          }
          else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false })
      }
      else if (apiRequestCallId === this.postPresignID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const imageData = this.state.fileUploadDetail.file
            const msg: Message = new Message(
              getName(MessageEnum.UploadMediaMessage)
            );
            const uploadFileData: any = {
              responseJson: responseJson,
              imageData,
              messageId: msg.messageId
            };
            msg.addData(
              getName(MessageEnum.UploadMediaDataMessage),
              uploadFileData
            );
            this.apiUploadBlockId = msg.messageId;
            runEngine.sendMessage(msg.id, msg)
          }
          else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false });
      } else if (apiRequestCallId === this.apiUploadBlockId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const keyValue = JSON.parse(
              convert.xml2json(responseJson, {
                spaces: 1,
                compact: true,
                ignoreComment: true,
                alwaysChildren: true,
                ignoreDeclaration: true,
              })
            );
            this.setState({ sihnIdData: keyValue.PostResponse.Key._text })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  get_schools = () => {
    this.setState({ loadingSpinner: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetSchoolId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  get_class = (id: any) => {
    this.setState({ loadingSpinner: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetClassId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClassEndPoint + `?page=1&per=100&school_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  get_subject = (id: any) => {



    this.setState({ loadingSpinner: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetClassDivisionlId[this.apigetClassDivisionlId.length] = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectEndPoint + `?class_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  computeChecksumMd5 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152;
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0;

      fileReader.onerror = function (): void {
        reject('MD5 computation failed - error reading the file');
      };
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result);
        cursor += chunkSize;
        if (cursor < file.size) {
          processChunk(cursor);
        } else {
          resolve(btoa(spark.end(true)));
        }
      };

      processChunk(0);
    });
  }

  avtarUpload = async (file: any) => {
    let fileUpload: any = {};
    let checksum = "";
    fileUpload = {
      fileName: file.name,
      name: file.name.split('.')[0],
      fileSize: file.size,
      file: file,
      content_type: file.type
    };
    await computeChecksumMd5(file).then(
      (md5: any) => {
        checksum = md5
        fileUpload.checksum = checksum;
        this.setState({ fileUploadDetail: fileUpload })
        this.getPreSignedId()
      }
    );
  }

  handleUpload = (event: any) => {
    let file: any = []
    let fileUpload: any = {};
    let checksum = "";
    file = event.target.files[0];
    fileUpload = {
      fileName: file.name,
      name: file.name.split('.')[0],
      fileSize: file.size,
      file: file,
      content_type: file.type
    };
    this.computeChecksumMd5(file).then(
      (md5: any) => {
        checksum = md5
        fileUpload.checksum = checksum;
        this.setState({ fileUploadDetail: fileUpload })
        this.getPreSignedId()
      }
    );
  }

  getPreSignedId = () => {
    const { fileUploadDetail } = this.state;
    const header = {
      token: localStorage.getItem("token")
    };
    let formData = new FormData();
    formData.append('content_type', fileUploadDetail.content_type)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postPresignID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fileUploadS3
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.SignIDUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  datadefaultImageUsingURL = async () => {
    const file = this.dataURLtoFile(configJSON.defalut_avtar, 'defalut_avtar')
    this.avtarUpload(file)
  }
  saveImageUsingURL = async (preSignedResponse: any) => {

    let ind: number = 0;
    const uploadURL = preSignedResponse.url.replace(/\/+$/, "");
    const urlFields = preSignedResponse.url_fields;
    if (uploadURL != null && uploadURL !== undefined) {
      const fileKey = urlFields.key.replace("${filename}", this.state.fileUploadDetail.fileName);
      const formData = new FormData();
      formData.append("key", fileKey);
      formData.append(
        "success_action_status",
        urlFields.success_action_status
      );
      formData.append("Content-Type", urlFields["Content-Type"]);
      formData.append(
        "Content-Disposition",
        urlFields["Content-Disposition"]
      );
      formData.append("policy", urlFields.policy);
      formData.append("x-amz-credential", urlFields["x-amz-credential"]);
      formData.append("x-amz-algorithm", urlFields["x-amz-algorithm"]);
      formData.append("x-amz-date", urlFields["x-amz-date"]);
      formData.append("x-amz-signature", urlFields["x-amz-signature"]);
      formData.append("file", this.state.fileUploadDetail.file);
      const s3Response = await axios({
        method: "post",
        url: uploadURL,
        data: formData,
      });
      if (s3Response.status === 201) {
        const keyValue = JSON.parse(
          convert.xml2json(s3Response.data, {
            spaces: 1,
            compact: true,
            ignoreComment: true,
            alwaysChildren: true,
            ignoreDeclaration: true,
          })
        );
        this.setState({ sihnIdData: keyValue.PostResponse.Key._text })
      }
    }

  }
  dataURLtoFile = (base64: any, fileName: string) => {
    var arr = base64.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  }

  onteacherSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const pswdspaceReg = /^\S$|^\S[\s\S]*\S$/;
    const { firstname, lastname, phonenumber, email, dateofbirth, gender, password, avtar, confirmPassword, school, } = this.state;
    this.emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const arr: any = []
    const subjectArr: any = []
    this.state.finalSelectedClass.map((_index: any) => {
      return (
        this.state.classList.filter((x: any) => x.id === _index)
          .map((x: any) => arr.push(x.attributes?.display_name))
      )
    })

    this.state.classesAndSubject.map((_classAndSubject: any) => {
      _classAndSubject.subject_data.map((data1: any, index: number) => {

        this.state.subjectWithClassId.map((_class: any) => {
          if (_class.class == _classAndSubject.class) {
            return (_class.subjectList.filter((x: any) => x.id === data1)
              .map((x: any) => {
                return (subjectArr.push(x.attributes?.subject_name))
              }))
          }
        })
      })
    })

    const desData: any = subjectArr.filter((x: any, i: any, a: any) => a.indexOf(x) == i)
    if (firstname.length === 0 || lastname.length === 0 || gender?.length === 0 || desData.length === 0 || email.length === 0 || password.length === 0 || confirmPassword.length === 0 || school.length === 0 || this.state.classesAndSubject[0].class.length === 0) {
      if (
        this.state.firstname === null ||
        this.state.firstname.length === 0
      ) {
        this.setState({ fnameerror: true, fnameerrorText: 'Firstname is required.' });

      }
      if (this.state.school === null || this.state.school.length === 0) {
        this.setState({ schoolerror: true });
      }
      if (this.state.classesAndSubject[0].class.length === 0 || this.state.classesAndSubject[0].class == "") {
        this.setState({ classerror: true });
      }
      if (this.state.lastname === null || this.state.lastname.length === 0) {
        this.setState({
          lnameerror: true,
          lnameerrorText: "Lastname is required.",
        });
      }
      if (this.state.email === null || this.state.email.length === 0) {
        this.setState({
          emailerror: true,
          emailerrorText: "Email is required.",
        });
      }
      if (
        this.state.phonenumber === null ||
        this.state.phonenumber.length === 0
      ) {
        this.setState({
          phoneerror: true,
          phoneerrorText: "Phone number is required.",
        });
      }
      if (this.state.gender === null || this.state.gender?.length === 0) {
        this.setState({ gendererror: true });
      }

      if (
        this.state.dateofbirth === null ||
        this.state.dateofbirth.length === 0
      ) {
        this.setState({
          dateerror: true,
          dateerrorText: "Please select date of birth.",
        });
      }
      if (!pswdspaceReg.test(this.state.password)) {
        this.setState({
          pswderror: true,
          pswderrorText: "Your password can't start or end with a blank space.",
        });
      }
      if (this.state.password === null || this.state.password.length === 0) {
        this.setState({
          pswderror: true,
          pswderrorText: "Password is required.",
        });
      }
      if (!pswdspaceReg.test(this.state.confirmPassword)) {
        this.setState({
          cpswderror: true,
          cpswderrorText: "Your password can't start or end with a blank space.",
        });
      }
      if (this.state.password != this.state.confirmPassword) {
        this.setState({ cpswderror: true, cpswderrorText: 'Password and Confirm password must match. ' })

      }
      if (this.state.confirmPassword === null || this.state.confirmPassword.length === 0) {
        this.setState({ cpswderror: true, cpswderrorText: 'Confirm Password is required.' });

      }
      if (desData.length === 0) {
        this.setState({ subjectError: true })
      }
      return false;
    }


    const data = {
      data: {
        type: "teacher_account",
        attributes: {
          first_name: firstname,
          last_name: lastname,
          email: email,
          full_phone_number: phonenumber,
          date_of_birth: dateofbirth,
          gender: gender,
          avatar: this.state.sihnIdData,
          password: password.trim(),
          school_id: parseInt(school),
          class_ids: this.state.finalSelectedClass,
          subjects: this.state.classesAndSubject,
          display_names: arr,
          subject_names: desData,
          role_name:"teacher"
        }
      }
    }
    this.setState({ loadingSpinner: true })
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    this.postLoginApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsparentAccountEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End

}
