// Customizable Area Start
import { BlockComponent } from '../../../framework/src/BlockComponent';

export const configJSON = require('./config.js');
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  mainReminder: any;
  mainReminderOpen: boolean;
  modalhandleClose: () => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ViewAllNotesModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
