// Customizable Area Start
import { HISTORY } from "../../../../components/src/comman";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import moment from "moment";
import convert from "xml-js";
export const configJSON = require("../config.js");
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    openChooseFromGalleryModal?: boolean;
    onCloseModal?: any;
    onClickImage?: any;
    onClickChoose?: any;
    selectedGalleryImageId?: any;
    onTabChangeClearId?: any;
    index?: any;
    ischecked?: boolean;
    totalQuestions?: any;
    isGreen?: boolean;
    assesmentQuestionSummary: any;
    questionNumber?: any;
    classes?: any;
    markForReview?: boolean;
    onSubmit?: any
    studentExamData?: any;
    numberHandler: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    data: any;
    markedForReview: boolean;
    assesmentId: number;
    studentExamData: any;
    sectionNumber: number;
    questionNumber: number;
    showLoader: boolean;
    openSubmitModal: boolean;
    openSubmittedPaperModal: boolean;
    totalQuestions: any;
    nextButton: boolean;
    previousButton: boolean;
    questions: string[];
    isSubmitQuestion: boolean;
    openModal: boolean;
    preSignedID: Array<any>;
    preSignedBlobUrl: Array<any>;
    answerAttachFileName: string;

    studentAnswer: string;
    markForReview: boolean;
    answerids: any;
    numberOfQuestion: number;
    totalNumberofQuestion: number;
    TempsectionId: any;
    TempquestionId: any;
    firstQuestion: number | null;
    firstQuestion2: number | null;
    firstQuestion3: number | null;
    match_list_items: any;
    assesmentQuestionSummary: any;
    startTime: any;
    answerAttachFiles: Array<any>;
    mainId: string;
    graphPlottingAnswer: any;
    sortListAnswer: any;
    handleDropLabelAnswer: any;
    profilePhotoFile: any;
    annotationImageId: any;
    imageUploadID: string;
    flashCardAnsArray: any;
    lineGraphAnswer: any;
    time: any;
    isClicked: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class StudentAssesmentAnswerController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getStudentQuestionListId: string = "";
    apiSubmitPaper: string = "";
    submitQuestionAnswerId: string = "";
    apiPostPresignID: string = '';
    apiUploadBlockId: string = '';
    timerID: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            data: "",
            showLoader: false,
            markedForReview: false,
            assesmentId: 0,
            studentExamData: {} || null || undefined,
            sectionNumber: 0,
            totalQuestions: null,
            questionNumber: 0,
            nextButton: false,
            previousButton: false,
            questions: [],
            isSubmitQuestion: false,
            openSubmitModal: false,
            openSubmittedPaperModal: false,
            openModal: false,
            preSignedID: [],
            preSignedBlobUrl: [],
            answerAttachFileName: "",
            answerAttachFiles: [],
            studentAnswer: "",
            markForReview: false,
            answerids: [],
            numberOfQuestion: 1,
            totalNumberofQuestion: 0,
            TempsectionId: "",
            TempquestionId: "",
            firstQuestion: null,
            firstQuestion2: null,
            firstQuestion3: null,
            match_list_items: null,
            assesmentQuestionSummary: [],
            startTime: new Date(),
            mainId: '',
            graphPlottingAnswer: [],
            sortListAnswer: [],
            handleDropLabelAnswer: [],
            profilePhotoFile: '',
            annotationImageId: '',
            imageUploadID: '',
            flashCardAnsArray: [],
            lineGraphAnswer: [],
            time: null,
            isClicked: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.userdata = this.userdata.bind(this);
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.userdata();
        this.get_Question_List();
        this.timerID = setInterval(
            () =>
                this.setState({
                    time: moment()
                }
                ),
            1000
        );
    }
    async componentWillUnmount() {
        clearInterval(this.timerID);
    }

    handleOnClose = (index: number) => {
        this.setState({
            openModal: false,
            preSignedID: this.state.preSignedID.filter(
                (item: any, imgIndex: number) => imgIndex !== index
            ),
            preSignedBlobUrl: this.state.preSignedBlobUrl.filter(
                (item: any, imgIndex: number) => imgIndex !== index
            ),
            answerAttachFileName: "",
            answerAttachFiles: this.state.answerAttachFiles.filter(
                (item: any, imgIndex: number) => imgIndex !== index
            ),
        });
    };

    handlePresignIDClose = (id: any, qId: any, sId: any) => {
        this.setState({
            openModal: false,
            preSignedID: [...this.state.preSignedID, ...id],
            TempsectionId: sId,
            TempquestionId: qId,
        });
    };

    handlePresignBlobUrl = (urlArr: any) => {
        this.setState({
            preSignedBlobUrl: [...this.state.preSignedBlobUrl, ...urlArr],
        });
    };

    handleAnswerAttachFileName = (namesArr: any) => {
        this.setState({
            //answerAttachFileName: name,
            answerAttachFiles: [...this.state.answerAttachFiles, ...namesArr],
        });
    };

    userdata = () => {
        const user_data = localStorage.getItem("user_data");
        const data = JSON.parse(user_data || "{}");
        this.setState({ data: data });
    };
    jumpQuestionNumberHandler = (questionNumber: any) => {
        const { studentExamData } = this.state
        let questionLength = 0
        studentExamData?.attributes?.assessment_sections?.data.map((data: any, index: number) => {
            data.attributes?.questions.data.map((queData: any, i: number) => {
                questionLength += 1
                if (questionLength === questionNumber) {
                    this.setState({
                        sectionNumber: index,
                        questionNumber: i,
                        numberOfQuestion: questionNumber,
                        answerAttachFiles: [],
                        markForReview: queData.attributes.student_answers.data.length != 0 ? queData.attributes.student_answers.data[0].attributes.mark_for_review : false,

                    })
                }
            })
        })

    }
    review = () => {
        const user_data = localStorage.getItem("user_data");
        const data = JSON.parse(user_data || "{}");
        this.setState({});
    };
    handleMatchChange = (matchListItems: any, qId: any, sId: any) => {
        this.setState({
            match_list_items: matchListItems,
            TempsectionId: sId,
            TempquestionId: qId,
        });
    };
    studentSortListAnswer = (sortLists: any, qId: any, sId: any) => {
        this.setState({
            sortListAnswer: sortLists,
            TempsectionId: sId,
            TempquestionId: qId,
        });
    }
    getPreSignedIdData = (fileUpload: any, imageDataFileUrl: any, id: any, qId: any, sId: any) => {
        this.setState({
            imageUploadID: id,
            TempsectionId: sId,
            TempquestionId: qId,
        });
        this.getPreSignedId(fileUpload, imageDataFileUrl, id)
    }
    handleResetAnswerState = (tempStudentExamData?: any) => {
        const { studentExamData, TempsectionId, lineGraphAnswer, TempquestionId, flashCardAnsArray, imageUploadID, sortListAnswer, handleDropLabelAnswer, graphPlottingAnswer, answerids, studentAnswer, match_list_items, markForReview, preSignedID } = this.state
        this.setState({
            studentExamData: tempStudentExamData != undefined ? tempStudentExamData : studentExamData,
            answerids: [],
            studentAnswer: "",
            preSignedID: [],
            preSignedBlobUrl: [],
            answerAttachFileName: "",
            answerAttachFiles: [],
            match_list_items: null,
            graphPlottingAnswer: [],
            sortListAnswer: [],
            handleDropLabelAnswer: [],
            imageUploadID: '',
            flashCardAnsArray: [],
            lineGraphAnswer: []
        });
    }
    // Customizable Area End
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.getStudentQuestionListId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ totalNumberofQuestion: 0 });
                        var totalQuestions = 0;
                        responseJson?.data?.attributes?.assessment_sections?.data.map(
                            (e: any, index: number) => {
                                totalQuestions = e.attributes.questions.data.length;
                                this.setState({
                                    totalNumberofQuestion:
                                        e.attributes.questions.data.length +
                                        this.state.totalNumberofQuestion,
                                });
                            }
                        );
                        this.setState({
                            studentExamData: responseJson.data,
                            showLoader: false,
                            assesmentQuestionSummary:
                                responseJson.data.attributes.assessment_sections.data,
                            markForReview:
                                responseJson.data?.attributes?.assessment_sections?.data[
                                    this.state.sectionNumber
                                ]?.attributes?.questions.data[this.state.questionNumber]
                                    ?.attributes?.student_answers?.data.length != 0
                                    ? responseJson.data?.attributes?.assessment_sections?.data[
                                        this.state.sectionNumber
                                    ]?.attributes?.questions.data[this.state.questionNumber]
                                        ?.attributes?.student_answers?.data[0]?.attributes
                                        .mark_for_review
                                    : false,
                            startTime: new Date(),
                        });
                        var mfr: number | string | string[] = String(
                            localStorage.getItem("mfr")
                        );
                        mfr = mfr.split(",");
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            } else if (apiRequestCallId === this.submitQuestionAnswerId) {
                const { studentExamData, TempsectionId, lineGraphAnswer, TempquestionId, flashCardAnsArray, imageUploadID, sortListAnswer, handleDropLabelAnswer, graphPlottingAnswer, answerids, studentAnswer, match_list_items, markForReview, preSignedID } = this.state
                const tempData = studentExamData
                const endTime: any = new Date();
                if (tempData?.attributes?.assessment_sections?.data[TempsectionId]?.attributes?.questions.data[TempquestionId].attributes?.student_answers?.data.length === 0) {
                    let questionData = tempData?.attributes?.assessment_sections?.data[TempsectionId]?.attributes?.questions.data[TempquestionId].attributes?.student_answers?.data
                    questionData.push({
                        attributes: {
                            answer_ids: answerids,
                            answer_details: studentAnswer,
                            match_list_answer: match_list_items,
                            errorSubmitAnswer: responseJson == null || responseJson.errors ? true : false,
                            timeDuration: Math.round(
                                (endTime - this.state.startTime) / 1000
                            ),
                            mark_for_review: responseJson.data.mark_for_review,
                            preSignedID: preSignedID,
                            student_chart_answers: graphPlottingAnswer,
                            sorting_answers: sortListAnswer,
                            student_label_questions_answer: handleDropLabelAnswer,
                            old_upload_id: imageUploadID,
                            flash_card_answer: flashCardAnsArray,
                            line_graph_answer: lineGraphAnswer
                        }
                    })
                }
                else {
                    let questionData = tempData?.attributes?.assessment_sections?.data[TempsectionId]?.attributes?.questions.data[TempquestionId].attributes?.student_answers?.data[0].attributes
                    if (questionData) {
                        questionData.answer_ids = answerids
                        questionData.answer_details = studentAnswer
                        questionData.match_list_answer = match_list_items

                        questionData.errorSubmitAnswer = responseJson == null || responseJson.errors ? true : false
                        questionData.timeDuration = Math.round(
                            (endTime - this.state.startTime) / 1000
                        )
                        questionData.mark_for_review = responseJson.data.mark_for_review
                        questionData.preSignedID = preSignedID
                        questionData.student_chart_answers = graphPlottingAnswer
                        questionData.sorting_answers = sortListAnswer
                        questionData.student_label_questions_answer = handleDropLabelAnswer
                        questionData.old_upload_id = imageUploadID
                        questionData.flash_card_answer = flashCardAnsArray
                        questionData.line_graph_answer = lineGraphAnswer
                    }
                }
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.handleResetAnswerState(tempData)
                    } else {
                        this.handleResetAnswerState(tempData)
                        this.parseApiErrorResponse(responseJson);
                    }
                } else {
                    this.handleResetAnswerState(tempData)
                    this.parseApiCatchErrorResponse(errorReponse);
                }
            } else if (apiRequestCallId === this.apiSubmitPaper) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({
                            openSubmittedPaperModal: true,
                        });
                    } else {
                        if (this.state.openSubmitModal) {
                            this.setState({
                                openSubmitModal: false,
                            });
                        }
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({
                    showLoader: false,
                });
            }
            else if (apiRequestCallId === this.apiPostPresignID) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        // this.setState({
                        //     isLoading: true,
                        // })
                        const imageData = this.state.profilePhotoFile;
                        const msg: Message = new Message(
                            getName(MessageEnum.UploadMediaMessage)
                        );
                        const uploadFileData: any = {
                            responseJson: responseJson,
                            imageData,
                            messageId: msg.messageId,
                        };
                        msg.addData(
                            getName(MessageEnum.UploadMediaDataMessage),
                            uploadFileData
                        );
                        this.apiUploadBlockId = msg.messageId;
                        runEngine.sendMessage(msg.id, msg);
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apiUploadBlockId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        const keyValue = JSON.parse(
                            convert.xml2json(responseJson, {
                                spaces: 1,
                                compact: true,
                                ignoreComment: true,
                                alwaysChildren: true,
                                ignoreDeclaration: true,
                            })
                        );
                        this.setState({ preSignedID: [keyValue.PostResponse.Key._text] })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    generateColor = () => {
        const randomColor = Math.floor(Math.random() * 16777215)
            .toString(16)
            .padStart(6, "0");
        return `#${randomColor}`;
    };
    get_Question_List = () => {
        const user_data = localStorage.getItem("user_data");
        const school_Data = JSON.parse(user_data || "{}");
        const id = HISTORY.location.state?.id;
        this.setState({
            showLoader: true,
            mainId: HISTORY.location.state?.id
        });
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("token"),
            school: school_Data?.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getStudentQuestionListId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.studentQuestionEndPoint + `/${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleCheckbox = (totalQuestions: any) => {
        this.setState({ markForReview: !this.state.markForReview });
    };

    submit_Question = (isNext: boolean) => {
        const {
            studentExamData,
            answerids,
            studentAnswer,
            match_list_items,
            preSignedID,
            sectionNumber,
            questionNumber,
            numberOfQuestion,
            graphPlottingAnswer,
            sortListAnswer,
            handleDropLabelAnswer,
            flashCardAnsArray,
            lineGraphAnswer
        } = this.state;
        this.setState({
            isSubmitQuestion: true,
            TempsectionId: sectionNumber,
            TempquestionId: questionNumber,
        });
        if (isNext) {
            if (
                questionNumber + 1 ===
                studentExamData?.attributes?.assessment_sections?.data[sectionNumber]
                    ?.attributes?.questions.data.length &&
                sectionNumber + 1 !=
                studentExamData?.attributes?.assessment_sections?.data.length
            ) {
                this.setState({
                    sectionNumber: sectionNumber + 1,
                    questionNumber: 0,
                    numberOfQuestion: numberOfQuestion + 1,
                    markForReview:
                        studentExamData?.attributes?.assessment_sections?.data[
                            sectionNumber + 1
                        ]?.attributes?.questions.data[0]?.attributes?.student_answers
                            ?.data[0]?.attributes.mark_for_review != undefined ?
                            studentExamData?.attributes?.assessment_sections?.data[
                                sectionNumber + 1
                            ]?.attributes?.questions.data[0]?.attributes?.student_answers
                                ?.data[0]?.attributes.mark_for_review : false,
                });
            } else if (
                questionNumber + 1 ===
                studentExamData?.attributes?.assessment_sections?.data[sectionNumber]
                    ?.attributes?.questions.data.length &&
                sectionNumber + 1 ===
                studentExamData?.attributes?.assessment_sections?.data.length
            ) {
                this.setState({
                    nextButton: true,
                    questionNumber: questionNumber + 1,
                    numberOfQuestion: numberOfQuestion + 1,
                    markForReview:
                        studentExamData?.attributes?.assessment_sections?.data[
                            sectionNumber
                        ]?.attributes?.questions.data[questionNumber + 1]?.attributes
                            ?.student_answers?.data[0]?.attributes.mark_for_review != undefined ?
                            studentExamData?.attributes?.assessment_sections?.data[
                                sectionNumber
                            ]?.attributes?.questions.data[questionNumber + 1]?.attributes
                                ?.student_answers?.data[0]?.attributes.mark_for_review : false,
                });
            } else {
                this.setState({
                    questionNumber: questionNumber + 1,
                    numberOfQuestion: numberOfQuestion + 1,
                    markForReview:
                        studentExamData?.attributes?.assessment_sections?.data[
                            sectionNumber
                        ]?.attributes?.questions.data[questionNumber + 1]?.attributes
                            ?.student_answers?.data[0]?.attributes.mark_for_review != undefined ?
                            studentExamData?.attributes?.assessment_sections?.data[
                                sectionNumber
                            ]?.attributes?.questions.data[questionNumber + 1]?.attributes
                                ?.student_answers?.data[0]?.attributes.mark_for_review : false,
                });
            }
        } else {
            if (questionNumber - 1 === 0 && sectionNumber != 0) {
                this.setState({
                    questionNumber: questionNumber - 1,
                    numberOfQuestion: numberOfQuestion - 1,
                    markForReview:
                        studentExamData?.attributes?.assessment_sections?.data[
                            sectionNumber
                        ]?.attributes?.questions.data[questionNumber - 1]?.attributes
                            ?.student_answers?.data[0]?.attributes.mark_for_review != undefined ?
                            studentExamData?.attributes?.assessment_sections?.data[
                                sectionNumber
                            ]?.attributes?.questions.data[questionNumber - 1]?.attributes
                                ?.student_answers?.data[0]?.attributes.mark_for_review : false,
                });
            } else if (questionNumber === 0 && sectionNumber != 0) {
                this.setState({
                    sectionNumber: sectionNumber - 1,
                    questionNumber:
                        studentExamData?.attributes?.assessment_sections?.data[
                            sectionNumber - 1
                        ]?.attributes?.questions.data.length - 1,
                    numberOfQuestion: numberOfQuestion - 1,
                    markForReview:
                        studentExamData?.attributes?.assessment_sections?.data[
                            sectionNumber - 1
                        ]?.attributes?.questions.data[
                            studentExamData?.attributes?.assessment_sections?.data[
                                sectionNumber - 1
                            ]?.attributes?.questions.data.length - 1
                        ]?.attributes?.student_answers?.data[0]?.attributes.mark_for_review != undefined ?
                            studentExamData?.attributes?.assessment_sections?.data[
                                sectionNumber - 1
                            ]?.attributes?.questions.data[
                                studentExamData?.attributes?.assessment_sections?.data[
                                    sectionNumber - 1
                                ]?.attributes?.questions.data.length - 1
                            ]?.attributes?.student_answers?.data[0]?.attributes.mark_for_review : false,
                });
            } else if (questionNumber != 0 && sectionNumber === 0) {
                this.setState({
                    questionNumber: questionNumber - 1,
                    numberOfQuestion: numberOfQuestion - 1,
                    markForReview:
                        studentExamData?.attributes?.assessment_sections?.data[
                            sectionNumber
                        ]?.attributes?.questions.data[questionNumber - 1]?.attributes
                            ?.student_answers?.data[0]?.attributes.mark_for_review != undefined ?
                            studentExamData?.attributes?.assessment_sections?.data[
                                sectionNumber
                            ]?.attributes?.questions.data[questionNumber - 1]?.attributes
                                ?.student_answers?.data[0]?.attributes.mark_for_review : false,
                });
            }
        }

        if (
            answerids.length != 0 ||
            studentAnswer != "" ||
            preSignedID.length != 0 ||
            match_list_items != null ||
            graphPlottingAnswer.length != 0 ||
            sortListAnswer.length != 0 ||
            handleDropLabelAnswer.length != 0 ||
            flashCardAnsArray.length != 0 ||
            lineGraphAnswer.length != 0
        ) {
            this.submit_Question_answer();
        } else {
            this.setState({
                startTime: new Date(),
            });
        }
    };
    studentAnswerDetails = (answer: string, qId: any, sId: any) => {
        this.setState({
            studentAnswer: answer,
            TempsectionId: sId,
            TempquestionId: qId,
        });
    };
    questionRatingAnswer = (data: any, qId: any, sId: any) => {
        this.setState({
            answerids: data,
            TempsectionId: sId,
            TempquestionId: qId,
        });
    };
    graphPlottingQuestionAnswer = (data: any, qId: any, sId: any) => {
        this.setState({
            graphPlottingAnswer: data,
            TempsectionId: sId,
            TempquestionId: qId,
        });
    };
    handleLineGraphAttrChange = (data: any, qId: any, sId: any) => {
        this.setState({
            lineGraphAnswer: data,
            TempsectionId: sId,
            TempquestionId: qId,
        });
    };
    studentOptionAnswer = (data: any, qId: any, sId: any) => {
        this.setState({
            answerids: data,
            TempsectionId: sId,
            TempquestionId: qId,
        });
    };
    flashCardHandleChange = (data: any, qId: any, sId: any) => {
        this.setState({
            flashCardAnsArray: data,
            TempsectionId: sId,
            TempquestionId: qId,
        });
    };
    handleLabelDropChange = (data: any, qId: any, sId: any) => {
        this.setState({
            handleDropLabelAnswer: data,
            TempsectionId: sId,
            TempquestionId: qId,
        });
    };

    submit_Question_answer = () => {
        const {
            studentExamData,
            sectionNumber,
            match_list_items,
            TempsectionId,
            TempquestionId,
            preSignedID,
            answerids,
            questionNumber,
            graphPlottingAnswer,
            studentAnswer,
            markForReview,
            sortListAnswer,
            handleDropLabelAnswer,
            imageUploadID,
            flashCardAnsArray,
            lineGraphAnswer
        } = this.state;
        const user_data = localStorage.getItem("user_data");
        const school_Data = JSON.parse(user_data || "{}");
        const id = HISTORY.location.state?.id;
        let match_items: any = [];
        match_list_items != null &&
            match_list_items.map((data: any) => {
                match_items.push({
                    match_list_id: data.attributes.uniq_id,
                    match_response_id: data.correctAnswerId,
                });
            });
        // Time calculation
        const endTime: any = new Date();
        const timeSpentInSeconds = Math.round(
            (endTime - this.state.startTime) / 1000
        );

        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: localStorage.getItem("token"),
            school: school_Data?.school_id,
        };
        const httpBody = {
            question_id: TempsectionId,
            assessment_section_id: TempquestionId,
            answer_details: studentAnswer,
            match_list_answer: match_items,
            answer_ids: answerids,
            mark_for_review: markForReview,
            files: preSignedID.length != 0 ? preSignedID : [],
            time_duration: timeSpentInSeconds,
            student_chart_answers: graphPlottingAnswer,
            sorting_answers: sortListAnswer,
            student_label_questions_answer: handleDropLabelAnswer,
            old_upload_id: parseInt(imageUploadID),
            flash_card_answer: flashCardAnsArray,
            line_graph_answer: lineGraphAnswer
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.submitQuestionAnswerId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.submitQuestionAnswerEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    onClickSubmitPaper = (studentAnswers?: any) => {
        this.setState({
            showLoader: true,
            isClicked: true,
        });

        const user_data = localStorage.getItem("user_data");
        const school_Data = JSON.parse(user_data || "{}");
        const id = HISTORY.location.state?.id;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("token"),
            school: school_Data?.school_id,
        };
        const startTime = this.state.studentExamData?.attributes
            ?.assessment_start_time;
        const timeSpent = Math.round(
            this.getTimeSpentInMinute(startTime, new Date())
        );
        const httpBody = {
            assessment_id: this.state.mainId,
            time_duration: timeSpent,
            student_answers: studentAnswers,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiSubmitPaper = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.studentSubmitPaperApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.examinationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiPutMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getTimeSpentInMinute = (start_time: any, end_time?: any) => {
        if (end_time) {
            let duration = moment.duration(moment(end_time).diff(moment(start_time)));
            return duration.asMinutes();
        } else {
            let duration = moment.duration(moment().diff(moment(start_time)));
            return duration.asMinutes();
        }
    };

    getPreSignedId = (photoDetail: any, imageDataFile: any, id: any) => {
        this.setState({
            profilePhotoFile: imageDataFile,
            annotationImageId: id,
        })
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            token: localStorage.getItem("token"),
            school: data.school_id,
        };
        let formData = new FormData();
        formData.append("content_type", photoDetail.content_type);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiPostPresignID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fileUploadS3
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.SignIDUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}
