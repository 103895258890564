// Customizable Area Start
import React from "react";
import {
    Box,
    Button, Checkbox, Grid, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import {
    arrowLeftIcon,
    arrowLeftWhite, arrowRightIcon, arrowRightWhite, attachFilePinIcon
} from "../assets";
import StudentUploadAssessmentWeb from "../AttachmentPopUp/StudentUploadAssessment.web";
import AssesmentSummaryView from "../Components/AssesmentSummaryView.web";
import QuestionStudentView from "../Components/QuestionStudentView.web";
import PaperSubmittedModal from "../StudentAssessmentTest/PaperSubmittedModal.web";
import SubmitPaperModal from "../StudentAssessmentTest/SubmitPaperModal.web";
import TestHeader from "../StudentAssessmentTest/TestHeader.web";
import StudentAssesmentAnswerController from "./StudentAssesmentAnswerController.web";
import './StudentAssessmentAnswer.web.css';
import moment from "moment";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class StudentAssesmentAnswer extends StudentAssesmentAnswerController {

    // Customizable Area Start
    render() {
        const {
            studentExamData,
            questionNumber,
            sectionNumber,
            numberOfQuestion,
            totalNumberofQuestion,
        } = this.state;
        var totalQuestions =
            studentExamData?.attributes?.assessment_sections?.data?.[sectionNumber]
                ?.attributes?.total_questions;
        let filteredResult: any = ['mcq', '2x2_grid', 'flash_card', 'hotspot', 'long answer', 'line_chart', 'label_image_with_dropdown', 'short answer', 'one word', 'pictionary', 'fill in blank', 'true false', 'match', 'text_rating_scale', 'numeric_rating_scale', 'percentage_rating_scale', 'label_image_with_text', 'graphic_rating_scale', 'audio', 'video', 'sort_list', 'graph_plotting', 'label_image_with_drag_and_drop']
        let objectiveQuestionType: any = ['long answer', 'short answer',]

        let time1 = moment(this.state.time).format()
        if (this.state.studentExamData) {
            let endTime = moment(this.state.studentExamData?.attributes?.end_time).format()
            let duration = moment.duration(moment(endTime).diff(time1))
            let second = duration.asSeconds()
            let reaming = Math.ceil(second)
            if (reaming < 0) {
                if (!this.state.isClicked) {
                    this.onClickSubmitPaper()
                }
            }
        }
        return (
            <>
                {this.state.showLoader && (
                    <Spinner spinnerModal={this.state.showLoader} />
                )}
                <Box className="studentAssessmentAnswer_Header">
                    <TestHeader
                        data={this.state.studentExamData}
                        onSubmit={this.onClickSubmitPaper}
                    />
                </Box>

                <Grid container className="studentAssessmentAnswer_MainDiv">
                    <Grid item xs={12} sm={8} md={8} lg={9} xl={9}>
                        <Box className="studentAssessmentAnswer_QuestionBox">
                            {
                                !filteredResult.includes((studentExamData?.attributes?.assessment_sections?.data?.[sectionNumber]?.attributes?.questions.data?.[questionNumber]?.attributes?.type.name))
                                    ?
                                    <Typography
                                        className={`studentAssessmentAnswer_SkipQuestionBox ${this.props.classes.bodyText_font}`}>
                                        You can skip this question.
                                    </Typography>
                                    :
                                    <QuestionStudentView
                                        totalQuestion={
                                            studentExamData?.attributes?.assessment_sections?.data[
                                                sectionNumber
                                            ]?.attributes?.total_questions
                                        }
                                        questionData={
                                            studentExamData?.attributes?.assessment_sections?.data[
                                                sectionNumber
                                            ]?.attributes?.questions.data[questionNumber]
                                        }
                                        optionThemeId={
                                            studentExamData?.attributes?.assessment_theme != null
                                                ? studentExamData?.attributes?.assessment_theme?.name
                                                : "Default Test"
                                        }
                                        getPreSignedIdData={this.getPreSignedIdData}
                                        handleLabelDropChange={this.handleLabelDropChange}
                                        answerDetails={this.studentAnswerDetails}
                                        sectionId={
                                            studentExamData?.attributes?.assessment_sections?.data[
                                                sectionNumber
                                            ]?.id
                                        }
                                        handleLineGraphAttrChange={this.handleLineGraphAttrChange}
                                        studentSortListAnswer={this.studentSortListAnswer}
                                        questionAnswer={this.studentOptionAnswer}
                                        flashCardHandleChange={this.flashCardHandleChange}
                                        graphPlottingQuestionAnswer={this.graphPlottingQuestionAnswer}
                                        questionRatingAnswer={this.questionRatingAnswer}
                                        questionId={
                                            studentExamData?.attributes?.assessment_sections?.data[
                                                sectionNumber
                                            ]?.attributes?.questions?.data[questionNumber]?.id
                                        }
                                        questionNumber={numberOfQuestion}
                                        handleMatchChange={this.handleMatchChange}
                                    />
                            }
                        </Box>
                        <Grid item xs={12}>
                            <Box className="studentAssessmentAnswer_QuestionBox">
                                <Grid
                                    container
                                    className={objectiveQuestionType.includes((studentExamData?.attributes?.assessment_sections?.data?.[sectionNumber]?.attributes?.questions.data?.[questionNumber]?.attributes?.type.name)) ? "studentAssessmentAnswer_AttachFileBtn" : "studentAssessmentAnswer_NotAttachFileBtn"}
                                >
                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        {
                                            objectiveQuestionType.includes((studentExamData?.attributes?.assessment_sections?.data?.[sectionNumber]?.attributes?.questions.data?.[questionNumber]?.attributes?.type.name))
                                                ? (
                                                    <>
                                                        <Box className="studentAssessmentAnswer_AttachBtnDiv">
                                                            <Button
                                                                className={`studentAssessmentAnswer_AttachBtn ${this.props.classes.studentAssessmentAnswer_AssessmentTest_attachFile_Color}`}
                                                                // disabled={
                                                                //   this.state.preSignedBlobUrl !== "" ? true : false
                                                                // }
                                                                onClick={() => this.setState({ openModal: true })}
                                                            >
                                                                <Typography
                                                                    className={`studentAssessmentAnswer_AttachBtnText ${this.props.classes.bodyText_font}`}
                                                                >
                                                                    Attach file
                                                                </Typography>
                                                                <img
                                                                    // src={
                                                                    //   this.state.preSignedBlobUrl !== ""
                                                                    //     ? this.state.preSignedBlobUrl
                                                                    //     : studentExamData?.attributes
                                                                    //         ?.assessment_sections?.data[sectionNumber]
                                                                    //         ?.attributes?.questions?.data[
                                                                    //         questionNumber
                                                                    //       ]?.attributes?.student_answers.data
                                                                    //         .length != 0
                                                                    //     ? studentExamData?.attributes
                                                                    //         ?.assessment_sections?.data[sectionNumber]
                                                                    //         ?.attributes?.questions?.data[
                                                                    //         questionNumber
                                                                    //       ]?.attributes?.student_answers?.data[0]
                                                                    //         ?.attributes?.attachments?.data.length !=
                                                                    //       0
                                                                    //       ? studentExamData?.attributes
                                                                    //           ?.assessment_sections?.data[
                                                                    //           sectionNumber
                                                                    //         ]?.attributes?.questions?.data[
                                                                    //           questionNumber
                                                                    //         ]?.attributes?.student_answers?.data[0]
                                                                    //           ?.attributes?.attachments?.data[0]
                                                                    //           ?.attributes?.url
                                                                    //       : attachFilePinIcon
                                                                    //     : attachFilePinIcon
                                                                    // }
                                                                    src={attachFilePinIcon}
                                                                    alt="AttachFile"
                                                                    className="studentAssessmentAnswer_AttachBtnImg"
                                                                />
                                                            </Button>
                                                            {this.state.answerAttachFiles?.length !== 0 ? (
                                                                <div
                                                                    className="studentAssessmentAnswer_AnswerDiv">
                                                                    {this.state.answerAttachFiles?.map(
                                                                        (name: any, index: number) => (
                                                                            <div key={index}>
                                                                                <Box
                                                                                    className="studentAssessmentAnswer_AnswerFileDiv"
                                                                                >
                                                                                    <Typography
                                                                                        className={`studentAssessmentAnswer_AttachFileName ${this.props.classes.bodyText_font}`}
                                                                                    >
                                                                                        {name}
                                                                                    </Typography>
                                                                                    <Button
                                                                                        className="studentAssessmentAnswer_AnswerCloseBtn"
                                                                                        title={"Remove image"}
                                                                                        onClick={() => {
                                                                                            this.handleOnClose(index);
                                                                                        }}
                                                                                    >
                                                                                        <ClearIcon
                                                                                            className={`studentAssessmentAnswer_AttachFileCloseIcon ${this.props.classes.icon_color}`}
                                                                                        />
                                                                                    </Button>
                                                                                </Box>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {studentExamData?.attributes?.assessment_sections
                                                                        ?.data[sectionNumber]?.attributes?.questions
                                                                        ?.data[questionNumber]?.attributes
                                                                        ?.student_answers?.data?.length != 0 ? (
                                                                        <>
                                                                            {studentExamData?.attributes?.assessment_sections?.data[
                                                                                sectionNumber
                                                                            ]?.attributes?.questions?.data[
                                                                                questionNumber
                                                                            ]?.attributes?.student_answers?.data?.[0].attributes?.attachments?.data?.map(
                                                                                (item: any, index: number) => (
                                                                                    <Box
                                                                                        key={index}
                                                                                        className="studentAssessmentAnswer_AnswerFileNameDiv"
                                                                                    >
                                                                                        <Typography className={`studentAssessmentAnswer_AttachFileName ${this.props.classes.bodyText_font}`}>
                                                                                            {item?.attributes?.file_name}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                )
                                                                            )}
                                                                        </>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Box>
                                                    </>
                                                ) : null}
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <Box className="studentAssessmentAnswer_BottomDiv">
                                            <Checkbox
                                                inputProps={{ "aria-label": "secondary checkbox" }}
                                                checked={
                                                    this.state.markForReview != undefined
                                                        ? this.state.markForReview
                                                        : false
                                                }
                                                onChange={() => {
                                                    this.handleCheckbox(totalQuestions);
                                                }}
                                                className={`studentAssessmentAnswer_markForRevCheckBox ${this.props.classes.icon_color}`}
                                            />
                                            <Typography className={`studentAssessmentAnswer_markForRevTxt ${this.props.classes.bodyText_font}`}
                                            >
                                                Mark for Review
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="studentAssessmentAnswer_QuestionBox">
                                <Grid
                                    container
                                    spacing={2}
                                    className="studentAssessmentAnswer_SubmitExamBtn"
                                >
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Box>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    const {
                                                        answerids,
                                                        studentAnswer,
                                                        match_list_items,
                                                        preSignedID,
                                                        graphPlottingAnswer,
                                                        sortListAnswer,
                                                        handleDropLabelAnswer,
                                                        flashCardAnsArray,
                                                        lineGraphAnswer,
                                                    } = this.state;
                                                    if (
                                                        answerids.length != 0 ||
                                                        studentAnswer != "" ||
                                                        preSignedID.length != 0 ||
                                                        match_list_items != null ||
                                                        graphPlottingAnswer.length != 0 ||
                                                        sortListAnswer.length != 0 ||
                                                        handleDropLabelAnswer.length != 0 ||
                                                        flashCardAnsArray.length != 0 ||
                                                        lineGraphAnswer.length != 0
                                                    ) {
                                                        this.submit_Question_answer();
                                                    }
                                                    this.setState({
                                                        openSubmitModal: true,
                                                    });
                                                }}
                                                className={`studentAssessmentAnswer_submitBtn ${this.props.classes.studentAssessmentAnswer_AssessmentTest_attachFile_Color}`}
                                            >
                                                Submit Exam
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Box
                                            className="studentAssessmentAnswer_paginationDiv"
                                        >
                                            <Box>
                                                <Button
                                                    className={
                                                        `studentAssessmentAnswer_PrevBtn ${this.state.numberOfQuestion - 1 === 0
                                                            ? this.props.classes.button_color_disabled
                                                            : this.props.classes.button_color}`
                                                    }
                                                    disabled={
                                                        this.state.numberOfQuestion - 1 === 0 ? true : false
                                                    }
                                                    onClick={() => {
                                                        this.submit_Question(false);
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            this.state.numberOfQuestion - 1 === 0
                                                                ? arrowLeftIcon
                                                                : arrowLeftWhite
                                                        }
                                                    />
                                                </Button>
                                            </Box>
                                            <Box>
                                                <Button
                                                    className={`
                                                    studentAssessmentAnswer_NextBtn ${this.state.numberOfQuestion ===
                                                            totalNumberofQuestion
                                                            ? this.props.classes.button_color_disabled
                                                            : this.props.classes.button_color}`
                                                    }
                                                    onClick={() => {
                                                        this.submit_Question(true);
                                                    }}
                                                    disabled={
                                                        this.state.numberOfQuestion ===
                                                            totalNumberofQuestion
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            this.state.numberOfQuestion ===
                                                                totalNumberofQuestion
                                                                ? arrowRightWhite
                                                                : arrowRightIcon
                                                        }
                                                    />
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                        <Box>
                            <AssesmentSummaryView
                                assesmentQuestionSummary={this.state.assesmentQuestionSummary}
                                questionNumber={numberOfQuestion}
                                markForReview={this.state.markForReview}
                                numberHandler={this.jumpQuestionNumberHandler}
                            />
                        </Box>
                    </Grid>
                </Grid>
                {/* Submit Modal */}
                <SubmitPaperModal
                    openModal={this.state.openSubmitModal}
                    handleOnClose={() => {
                        if (this.state.numberOfQuestion === totalNumberofQuestion) {
                            this.get_Question_List();
                        }
                        this.setState({
                            openSubmitModal: false,
                        });
                    }}
                    handleOnSubmit={() => {
                        let studentAnswers: any = []
                        const { studentExamData } = this.state
                        studentExamData?.attributes?.assessment_sections?.data?.map((data: any, index: number) => {
                            data.attributes?.questions?.data?.map((queData: any, i: number) => {
                                if (queData.attributes?.student_answers?.data?.length != 0 && queData.attributes?.student_answers?.data?.[0]?.attributes?.errorSubmitAnswer) {
                                    studentAnswers.push({
                                        question_id: queData.id,
                                        assessment_section_id: data.id,
                                        answer_details: queData?.attributes?.student_answers?.data?.[0]?.attributes?.answer_details,
                                        match_list_answer: queData?.attributes?.student_answers?.data?.[0]?.attributes?.match_list_answer,
                                        answer_ids: queData?.attributes?.student_answers?.data?.[0]?.attributes?.answer_ids,
                                        mark_for_review: queData?.attributes?.student_answers?.data?.[0]?.attributes?.markForReview,
                                        files: queData?.attributes?.student_answers?.data?.[0]?.attributes?.preSignedID?.length != 0 ? queData.attributes?.student_answers?.data?.[0]?.attributes?.preSignedID : [],
                                        time_duration: queData?.attributes?.student_answers?.data?.[0]?.attributes?.timeDuration,
                                    })
                                }

                            })
                        })
                        this.onClickSubmitPaper(studentAnswers);
                        this.setState({
                            openSubmitModal: false,
                        })
                    }}
                />
                {/* Submit Modal END */}
                {/* Submitted Modal */}
                <PaperSubmittedModal
                    openModal={this.state.openSubmittedPaperModal}
                    handleOnClose={() => {
                        this.setState({
                            openSubmittedPaperModal: false,
                        });
                    }}
                />
                {/* Submitted Modal END */}
                {this.state.openModal && (
                    <StudentUploadAssessmentWeb
                        openModal={this.state.openModal}
                        handleOnClose={this.handleOnClose}
                        handlePresignIDClose={this.handlePresignIDClose}
                        handlePresignBlobUrl={this.handlePresignBlobUrl}
                        handleAnswerAttachFileName={this.handleAnswerAttachFileName}
                        questionNumber={numberOfQuestion}
                        sectionId={
                            studentExamData?.attributes?.assessment_sections?.data[
                                sectionNumber
                            ]?.id
                        }
                        questionId={
                            studentExamData?.attributes?.assessment_sections?.data[
                                sectionNumber
                            ]?.attributes?.questions?.data[questionNumber]?.id
                        }
                    />
                )}
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start

export default withStyles(themeCreateStyle)(StudentAssesmentAnswer);
// Customizable Area End
