// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
// import StudentEnroll from "./Enroll/Student/StudentEnroll.web";
// import TeacherEnroll from "./Enroll/Teacher/TeacherEnroll.web";
// import PendingAccount from "./Enroll/Pending/PendingAccount.web";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  data: any;
  studentSubjectList: any;
  goals: any;
  progress: any;
  upcomingClasses: any;
  selectedUpcomingSubject: string;
  notes: any;
  learningCurve: any;
  assessment: any;
  selectedTab: string;
  selectedIndex: number;
  listView: string;
  membersListView: string;
  dialogOpen: boolean;
  uploadStudent: boolean;
  dialogOpenTeacher: boolean;
  uploadTeacher: boolean;
  CreateOrEdit: string | null;
  removeModal: boolean;
  term: {
    title: string,
    field_id: number | null;
  };
  terms: any[];
  board: string;
  grade: string | null;
  guidelines: any[];
  newGuideline: string;
  deleteData: any;
  pagination_page: number;
  total_pages: number;
  par_page: number;
  gradeList: any[];
  boardList: any[];
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AdminEnrollUserAccountController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    console.log(this);
    this.receive = this.receive.bind(this);
    this.handleSelectTab = this.handleSelectTab.bind(this)

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      data: '',
      selectedTab: 'terms',
      selectedIndex: 1,
      listView: 'list',
      loading: false,
      studentSubjectList: {},
      goals: {},
      progress: {},
      upcomingClasses: [],
      selectedUpcomingSubject: '',
      notes: {},
      learningCurve: {},
      assessment: {},
      membersListView: 'list',
      dialogOpen: false,
      uploadStudent: false,
      dialogOpenTeacher: false,
      uploadTeacher: false,
      CreateOrEdit: null,
      grade: "",
      board: "",
      removeModal: false,
      pagination_page: 0,
      total_pages: 0,
      par_page: 10,
      newGuideline: "",
      deleteData: "",
      term: { title: "", field_id: null },
      terms: [],
      gradeList: [],
      boardList: [],
      guidelines: [{ text: "loream sfjk sdfkj sfklj sf", field_id: 1 }, { text: "asdsdf", field_id: 2 }, { text: "fsf", field_id: 3 }, { text: "asdsdf", field_id: 5 }]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getGrades();

    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {

      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apigetGradeListCallId: string = "";
  apigetBoardListCallId: string = "";
  apigetGuidelinesCallId: string = "";
  apiCreateGuidelinesCallId: string = "";
  apiGetTermCallId: string = "";
  apiCreateTermCallId: string = "";
  apiEditTermCallId: string = "";
  apiDeleteTermID: string = "";
  apiDeleteGuidelineID: string = "";
  // Customizable Area End

  // Customizable Area Start
  getBoards = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apigetBoardListCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getBoardEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestbaseURLMessage), configJSON.instituteURL);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getGuidelines = () => {
    this.setState({ loading: true });
    const school_data = localStorage.getItem("user_data");
    const schoolData = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.enrollContentType,
      token: localStorage.getItem("token"),
      school: schoolData.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetGuidelinesCallId = requestMessage.messageId;
    let urlEndPoint = configJSON.getGuidelinesEndPoint;
    if (this.state.grade) {
      urlEndPoint = urlEndPoint + `?grade_id=${this.state.grade}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.GuidelineURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.enrollApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getGrades = () => {
    this.setState({ loading: true });
    const school_data = localStorage.getItem("user_data");
    const schoolData = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.enrollContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetGradeListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGradeEndPoint + `?school_id=${schoolData.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.enrollApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createTerm = () => {
    this.setState({ loading: true });

    const user_data = localStorage.getItem("user_data");

    const data = JSON.parse(user_data || "{}");
    const schoolData = JSON.parse(user_data || "{}");

    const header = {
      "Content-Type": configJSON.termContentType,
      token: localStorage.getItem("token"),
      "Access-Control-Allow-Origin": "*",
      school: schoolData.school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateTermCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createTermApiNew
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.GuidelineURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.termAPiMethod
    );

    const attrs = {
      grade_id: this.state.grade && [parseInt(this.state.grade)],
      school_id: data.school_id,
      session_start: Number(moment().format("YYYY")),
      session_end: Number(moment().format("YYYY")) + 1,
      title: this.state.term.title
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createGuidelines = () => {
    this.setState({ loading: true });

    const user_data = localStorage.getItem("user_data");

    const data = JSON.parse(user_data || "{}");

    const schoolData = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.termContentType,
      token: localStorage.getItem("token"),
      school: schoolData.school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateGuidelinesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createGuidelinesApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.GuidelineURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.termAPiMethod
    );

    const attrs = {
      description: this.state.newGuideline,
      is_admin_added: (localStorage.getItem("token") ? true : false),
      grade_id: this.state.grade
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getTerm = (page?: number) => {
    this.setState({ loading: true });

    const user_data = localStorage.getItem("user_data");

    const data = JSON.parse(user_data || "{}");

    const header = {
      "Content-Type": configJSON.termContentType,
      token: localStorage.getItem("token"),
      "Access-Control-Allow-Origin": "*",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTermCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTermApi}?school_id=${data.school_id}&grade_id=${this.state.grade}&per=${this.state.par_page}&page=${page ? page : this.state.pagination_page + 1}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.GuidelineURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTermMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editTerm = () => {
    this.setState({ loading: true });

    const user_data = localStorage.getItem("user_data");

    const data = JSON.parse(user_data || "{}");

    const header = {
      "Content-Type": configJSON.termContentType,
      token: localStorage.getItem("token"),
      "Access-Control-Allow-Origin": "*",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEditTermCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createTermApi}/${this.state.term?.field_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.GuidelineURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    const attrs = {
      school_id: data.school_id,
      grade_id: [this.state.grade],
      title: this.state.term?.title,
      session_start: Number(moment().format("YYYY")),
      session_end: Number(moment().format("YYYY")) + 1
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteTerm = () => {
    this.setState({ loading: true });

    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.gradeContentType,
      token: localStorage.getItem("token"),
      "Access-Control-Allow-Origin": "*",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteTermID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createTermApi}/${this.state.deleteData.field_id}?school_id=${data.school_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.GuidelineURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteTerm
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ school_id: data.school_id })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteGradeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteGuidelines = () => {
    this.setState({ loading: true });

    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.gradeContentType,
      token: localStorage.getItem("token"),
      "Access-Control-Allow-Origin": "*",
      school: data.school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteGuidelineID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getGuidelinesEndPoint}/${this.state.deleteData.field_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.GuidelineURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteTerm
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ school_id: data.school_id })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteGradeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteGuideline = () => {
    console.log(this.state.deleteData, "guideline");

  }

  changeClassDropdown = (event: any) => {
    this.setState({ grade: event.target.value, pagination_page: 0, total_pages: 0 },
      () => {
        if (this.state.grade !== "") {
          if (this.state.selectedTab === "terms") {
            this.getTerm();
          } else {
            this.getGuidelines();
          }
        }
      });
  }

  handleSelectTab = (tab: string, key: number) => {
    this.setState({
      selectedTab: tab,
      selectedIndex: key,
      grade: "",
      terms: [],
      guidelines: [],
    }, () => {
      if (tab === "assessment") {
        this.getGuidelines();
        // if(this.state.boardList.length === 0)   //Need to implement again when board logic is clear
        //   this.getBoards();
      }
    })
  }
  handleDialogClose = () => {
    this.setState({ dialogOpen: false })
  }

  onCloseModal = () => {
    this.setState({ uploadStudent: false })
  }

  handleTeacherDialogClose = () => {
    this.setState({ dialogOpenTeacher: false })
  }

  onTeacherCloseModal = () => {
    this.setState({ uploadTeacher: false })
  }


  screen = () => {
    // switch(this.state.selectedTab) {
    //   case "principals":  return <p>Principal</p>;
    //   case "teachers":   return <TeacherEnroll addDialog={this.state.dialogOpenTeacher} handleDialogClose={this.handleTeacherDialogClose} onCloseModal={this.onTeacherCloseModal} uploadStudent={this.state.uploadTeacher}/>;
    //   case "supervisors":   return <p>supervisors</p>;
    //   case "students":   return <StudentEnroll addDialog={this.state.dialogOpen} handleDialogClose={this.handleDialogClose} onCloseModal={this.onCloseModal} uploadStudent={this.state.uploadStudent}/>;
    //   case "pending":   return <PendingAccount />

    //   default:      return <h1>No project match</h1>
    // }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apigetBoardListCallId) {
        this.setState({ loading: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            const arr: any[] = [];
            responseJson.data.map((_data: any) => {
              arr.push({
                id: _data.id,
                name: _data.name
              });
            });
            this.setState({ boardList: arr });
          }
        }
      }
      if (apiRequestCallId === this.apigetGradeListCallId) {
        this.setState({ loading: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ gradeList: responseJson.data });
          }
        }
      }
      if (apiRequestCallId === this.apiGetTermCallId) {
        this.setState({ loading: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ terms: responseJson.data, total_pages: responseJson?.meta?.pagination?.total_pages });
          }
        }
      }
      if (apiRequestCallId === this.apiCreateTermCallId) {
        this.setState({ loading: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.getTerm();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
      if (apiRequestCallId === this.apiEditTermCallId) {
        this.setState({ loading: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.getTerm();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
      if (apiRequestCallId == this.apiDeleteTermID) {
        this.setState({ loading: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.getTerm();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
      if (apiRequestCallId == this.apigetGuidelinesCallId) {
        this.setState({ loading: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ guidelines: responseJson.data }, () => { console.log(this.state.guidelines) });

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
      if (apiRequestCallId == this.apiCreateGuidelinesCallId) {
        this.setState({ loading: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.getGuidelines();
            this.setState({ newGuideline: '' })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
      if (apiRequestCallId == this.apiDeleteGuidelineID) {
        this.setState({ loading: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.getGuidelines();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
    }
  }
  // Customizable Area End
  // }

  // Customizable Area End

}
