// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config.js");
// Customizable Area End
export interface Props {
    // Customizable Area Start
    allNotesModal: boolean;
    closeModalFun: any;
    classes: any;
    role?:any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    closeModal: boolean;
    data: any;
    teacherNotes: any;
    createNoteModal: boolean;
    editNoteModal: boolean;
    notes_id: any;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ViewAllNotesModalController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apigetTeacherClassesId: string = "";
    apigetTeacherSubjectsId: string = "";
    apigetTeacherAssignmnetId: string = "";
    apigetTeacherofStudentId: string = "";
    apigetTeacherNotesId: string = "";
    apicreateTeacherNotesId: string = "";
    apigetupcomingClassesId: string = "";
    apigettimeTableId: string = "";
    apigetliveClassesId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            closeModal: false,
            data: [],
            teacherNotes: [],
            createNoteModal: false,
            editNoteModal: false,
            notes_id: [],
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.get_userdata = this.get_userdata.bind(this);
        this.get_teacher_notes = this.get_teacher_notes.bind(this);
        // Customizable Area End
    }


    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.get_teacher_notes();
        // Customizable Area End
    }

    // Customizable Area Start
    get_userdata = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ data: data });
    }
    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.closeModalFun(this.state.closeModal)
    }
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apigetTeacherNotesId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ teacherNotes: responseJson.data })
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    onCloseModal = (closeModal: boolean) => {
        this.setState({ createNoteModal: closeModal })
        this.props.closeModalFun(this.state.closeModal)
    }

    onEditCloseModal = (closeModal: boolean) => {
        this.setState({ editNoteModal: closeModal })
        this.props.closeModalFun(this.state.closeModal)
    }
    get_teacher_notes = () => {
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("token"),
            school: data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetTeacherNotesId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.teacherCreateNotes
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.InstituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
