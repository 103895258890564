// Customizable Area Start
import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { HISTORY } from "../../../../components/src/comman";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import Spinner from "../../../shared/SideBar/src/Spinner";
import { imgBackArrow } from "../assets";
import './StudentAssessmentGuideline.web.css';
import StudentAssessmentGuidelineController from "./StudentAssessmentGuidelineController.web";
// Customizable Area End

// Customizable Area Start
const styles = {
  startBtn: {
    backgroundColor: "rgb(164, 57, 203)",
    color: "#fff",
    textTransform: "capitalize",
    padding: "8px 20px",
    fontSize: "16px"
  },
  guidelineSection: {
    borderRadius: "5px",
    "-webkit-box-shadow": "0 0 10px 1px rgba(0, 0, 0, 0.2)",
    boxShadow: "0 0 10px 1px rgba(0, 0, 0, 0.2)",
    background: "#fff",
    padding: " 15px",
  },
  guideList: {},
  guideLineBox: {
    display: "flex",
    alignItems: "baseline",
    padding: 15,
  },
} as const;
// Customizable Area End

export class StudentAssessmentGuideline extends StudentAssessmentGuidelineController {

  // Customizable Area Start
  render() {
    const { adminGuidelineList } = this.state;

    return (
      <>
        {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
        <Typography className={`studentAssesmentGuideline_backArrowDiv ${this.props.classes.heading_font}`}>
          <img src={imgBackArrow} className='studentAssesmentGuideline_backArrowImg' onClick={() => { this.handleBack() }} />
          {HISTORY.location.state?.examTitle}
        </Typography>
        <Box style={styles.guidelineSection}>
          <Typography className={`studentAssesmentGuideline_guidelineTxt ${this.props.classes.heading_font}`}>
            Test Guidelines
          </Typography>

          <Box style={styles.guideList} mb={2}>
            {adminGuidelineList && adminGuidelineList.length > 0 ?
              <>
                {adminGuidelineList?.map((guideline: any, index: number) => (
                  <Box style={styles.guideLineBox} my={1}>
                    <div
                      className={this.props.classes.bodyText_font} style={{ marginRight: 15, fontSize: 16, fontWeight: 600, }}>{index + 1}</div>
                    <div
                      className={this.props.classes.bodyText_font} style={{ fontSize: 16, fontWeight: 600, }}>{guideline?.attributes?.description}</div>
                  </Box>
                ))}
              </> :
              <>
                <Typography className="studentAssesmentGuideline_noRecordTxt">
                  No record(s) found.
                </Typography>
              </>
            }

          </Box>

          <Box className="studentAssesmentGuideline_btnDiv">
            <Button
              onClick={this.agreeStartClick}
              disabled={!this.state.checkStartBtn}
              className={`${!this.state.checkStartBtn ? 'studentAssesmentGuideline_disabledBtn' : 'studentAssesmentGuideline_activeBtn'} ${this.props.classes.button_color}`}
            >
              Agree & Start Test
            </Button>
          </Box>

        </Box>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

export default withStyles(themeCreateStyle)(HOCAuth(StudentAssessmentGuideline, "StudentAccount"));
// Customizable Area End