// Customizable Area Start
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import HOCAuth from '../../../../shared/Header/src/HOCAuth.web';
import WardDetailsController, { Props } from './WardDetailsController.web';
import {
  edit_note_img,
} from "../../assets";
import { Typography } from '@material-ui/core';
import { themeCreateStyle } from '../../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class WardDetails extends WardDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      data,
    } = this.state;

    return (
      <Grid className={`detail-main-grid ${this.props.classes.wardDetails_dashboard_main_grid}`} container direction="row">
        <Grid item xs={4} className={`detail-flex-row`}>
          {data?.school_logo?.img ?
            <img src='data?.school_logo?.img' className={'detail-img-school-logo'} />
            :
            <img src={edit_note_img} className={'detail-img-school-logo'} />
          }
          <Typography className={`${this.props.classes.wardDetails_dashboard_font_14} ${this.props.classes.subHeading_font} detail-text detail-fw-600`}>
            School Name
          </Typography>
        </Grid>
        <Grid item xs={4} className={'detail-flex-center'}>
          <Typography className={`${this.props.classes.wardDetails_dashboard_font_12} ${this.props.classes.subHeading_font} detail-text`}>
            City
          </Typography>
        </Grid>
        <Grid item xs={4} className={'detail-class-name-text'}>
          <Typography className={`${this.props.classes.wardDetails_dashboard_font_12} ${this.props.classes.subHeading_font} detail-text detail-fw-600`}>
            Class Name
          </Typography>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(WardDetails, 'TeacherAccount')
);
  // Customizable Area End
