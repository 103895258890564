// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  Modal,
  Typography,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { FileDrop } from 'react-file-drop';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
import AlertModal from '../../../alert/src/AlertModal.web';
import { imageReUploadIcon, imgDeleteGray, Rename } from '../assets';
import '../StudentAssessmentTest/StudentAssessment.web.css';
import { generateKey } from '../utils/common';
import StudentUploadAssessmentController, {
  Props,
} from './StudentUploadAssessmentController.web';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class StudentUploadAssessment extends StudentUploadAssessmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { classes } = this.props;
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={this.props.openModal}
          onClose={() => {
            this.props?.handleOnClose();
          }}
          closeAfterTransition
        >
          <Box
            style={{
              background: '#fff',
              borderRadius: 8,
              width: '62%',
              maxHeight: '80%',
            }}
            className="modal_mainLayout_Box"
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography
                className={`${classes.studentUploadAssesment_AssessmentTest_AttachFile} attach_file_text`}
              >
                Attach File
              </Typography>
              <IconButton
                style={{ height: 24 }}
                onClick={() => this.props.handleOnClose()}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              style={{ position: 'relative' }}
              className={classes.studentUploadAssesment_AssessmentTest_content}
            >
              <Typography
                style={{ textAlign: 'center' }}
                className={`${classes.studentUploadAssesment_AssessmentTest_QuestionNoText} attach_file_text`}
              >
                Q{this.props.questionNumber},Answer:
              </Typography>
              {this.state.bulkFileUploaded.length > 0 && (
                <Hidden only={['xs']}>
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      padding: '5px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      this.inputOpenFileRef?.current?.click();
                    }}
                    className={this.props.classes.image_Border_Color}
                  >
                    <IconButton
                      style={{ height: 24, width: 24, marginRight: 12 }}
                    >
                      <img
                        src={imageReUploadIcon}
                        style={{ height: 18, width: 18 }}
                      />
                    </IconButton>
                    <Typography
                      className={this.props.classes.heading_font}
                      component="span"
                    >
                      Add More
                    </Typography>
                  </div>
                </Hidden>
              )}
              {this.state.bulkFileUploaded.length > 0 && (
                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                  <IconButton
                    style={{
                      height: 24,
                      width: 24,
                      marginRight: 12,
                      position: 'absolute',
                      right: 0,
                      top: 0,
                    }}
                  >
                    <img
                      src={imageReUploadIcon}
                      onClick={() => {
                        this.inputOpenFileRef?.current?.click();
                      }}
                      style={{ height: 18, width: 18 }}
                    />
                  </IconButton>
                </Hidden>
              )}
            </Box>
            <Box className="modal_fileUpload_Content">
              <FileDrop
                onDrop={(files: any, event: any) => {
                  window.addEventListener(
                    'drop',
                    function (e) {
                      e.preventDefault();
                    },
                    false
                  );
                  // console.log(event.target.files)
                  let totalSize = 0;
                  let isAllImageTypeFiles = false;
                  //var files = event.target.files;
                  const keys = Object.keys(files);
                  keys?.map(async (key: any) => {
                    const file = files[key];
                    totalSize = totalSize + file.size;
                    let fileExtension = file.name.split('.').pop();
                    if (
                      fileExtension.includes('png') ||
                      fileExtension.includes('jpg') ||
                      fileExtension.includes('jpeg')
                    ) {
                      isAllImageTypeFiles = true;
                    } else {
                      isAllImageTypeFiles = false;
                    }
                  });
                  this.state.bulkFileUploaded.map((file: any) => {
                    totalSize += file.fileSize;
                  });
                  if (isAllImageTypeFiles) {
                    // console.log(filesObj);
                    if (totalSize <= 51200000) {
                      let uploadedFiles = [...this.state.bulkFileUploaded];
                      keys?.map(async (key: any) => {
                        const file = files[key];
                        let data = {
                          id: generateKey(file.name),
                          fileName: file.name,
                          name: file?.name?.split('.')[0],
                          isExistingFile: false,
                          filesize: file.size,
                          file: file,
                          content_type: file.type,
                        };
                        uploadedFiles.push(data);
                      });
                      console.log(uploadedFiles);
                      this.setState({
                        bulkFileUploaded: [...uploadedFiles],
                      });
                    } else {
                      this.setState({
                        alertMessage:
                          'uploaded file size should be less than 1MB',
                        alertModal: true,
                      });
                    }
                  } else {
                    this.setState({
                      alertMessage: 'only image file can be upload',
                      alertModal: true,
                    });
                  }
                }}
              >
                {!(
                  this.state.bulkFileUploaded &&
                  this.state.bulkFileUploaded.length > 0
                ) ? (
                  <Box
                    style={{
                      borderWidth: 3,
                      borderStyle: 'dashed',
                      borderColor: '#cadaf1',
                      borderRadius: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      // margin: "30px 20px 20px 20px",
                    }}
                    className="modal_ContentLayoutView"
                  >
                    <Box>
                      <img
                        src={Rename}
                        style={{ height: 70, cursor: 'pointer' }}
                        onClick={() => {
                          this.inputOpenFileRef?.current?.click();
                        }}
                      />

                      <Box className="file_Upload_text">
                        <Typography
                          className={`${classes.studentUploadAssesment_AssessmentTest_uploadMaterialContent} attach_file_text`}
                          style={{ textAlign: 'center' }}
                        >
                          Drag and Drop Files here or browse them from your
                          computer. Supported image formats: .png or .jpg ,
                          total size of files should not exceed 50 MB
                        </Typography>
                        <input
                          ref={this.inputOpenFileRef}
                          accept="image/png, image/jpg, image/jpeg,"
                          style={{ display: 'none' }}
                          type="file"
                          multiple
                          onChange={(e: any) => {
                            let totalSize = 0;
                            let isAllImageTypeFiles = false;
                            var files = e.target.files;
                            const keys = Object.keys(files);
                            keys?.map(async (key: any) => {
                              const file = files[key];
                              totalSize = totalSize + file.size;
                              let fileExtension = file.name.split('.').pop();
                              if (
                                fileExtension === 'png' ||
                                fileExtension === 'jpg' ||
                                fileExtension === 'jpeg'
                              ) {
                                isAllImageTypeFiles = true;
                              } else {
                                isAllImageTypeFiles = false;
                              }
                            });
                            this.state.bulkFileUploaded.map((file: any) => {
                              totalSize += file.fileSize;
                            });
                            if (isAllImageTypeFiles) {
                              if (totalSize <= 51200000) {
                                let uploadedFiles = [
                                  ...this.state.bulkFileUploaded,
                                ];
                                keys?.map(async (key: any) => {
                                  const file = files[key];
                                  let data = {
                                    id: generateKey(file.name),
                                    fileName: file.name,
                                    name: file?.name?.split('.')[0],
                                    isExistingFile: false,
                                    fileSize: file.size,
                                    file: file,
                                    content_type: file.type,
                                  };
                                  uploadedFiles.push(data);
                                });
                                console.log(uploadedFiles);
                                this.setState({
                                  bulkFileUploaded: [...uploadedFiles],
                                });
                              } else {
                                this.setState({
                                  alertMessage:
                                    'uploaded files size should be less than 50MB',
                                  alertModal: true,
                                });
                              }
                            } else {
                              this.setState({
                                alertMessage: 'only image files can be upload',
                                alertModal: true,
                              });
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    style={{
                      borderWidth: 3,
                      borderStyle: 'dashed',
                      borderColor: '#cadaf1',
                      borderRadius: 8,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      position: 'relative',
                      textAlign: 'center',
                    }}
                    className="modal_ContentLayoutView"
                  >
                    <input
                      ref={this.inputOpenFileRef}
                      accept="image/png, image/jpg, image/jpeg,"
                      style={{ display: 'none' }}
                      type="file"
                      multiple
                      onChange={(e: any) => {
                        let totalSize = 0;
                        let isAllImageTypeFiles = false;
                        var files = e.target.files;
                        const keys = Object.keys(files);
                        keys?.map(async (key: any) => {
                          const file = files[key];
                          totalSize = totalSize + file.size;
                          let fileExtension = file.name.split('.').pop();
                          if (
                            fileExtension === 'png' ||
                            fileExtension === 'jpg' ||
                            fileExtension === 'jpeg'
                          ) {
                            isAllImageTypeFiles = true;
                          } else {
                            isAllImageTypeFiles = false;
                          }
                        });
                        this.state.bulkFileUploaded.map((file: any) => {
                          totalSize += file.fileSize;
                        });
                        if (isAllImageTypeFiles) {
                          if (totalSize <= 51200000) {
                            let uploadedFiles = [
                              ...this.state.bulkFileUploaded,
                            ];
                            keys?.map(async (key: any) => {
                              const file = files[key];
                              let data = {
                                id: generateKey(file.name),
                                fileName: file.name,
                                name: file?.name?.split('.')[0],
                                isExistingFile: false,
                                fileSize: file.size,
                                file: file,
                                content_type: file.type,
                              };
                              uploadedFiles.push(data);
                            });
                            console.log(uploadedFiles);
                            this.setState({
                              bulkFileUploaded: [...uploadedFiles],
                            });
                          } else {
                            this.setState({
                              alertMessage:
                                'uploaded file size should be less than 1MB',
                              alertModal: true,
                            });
                          }
                        } else {
                          this.setState({
                            alertMessage: 'only image file can be upload',
                            alertModal: true,
                          });
                        }
                      }}
                    />
                    <Box
                      style={{
                        width: '100%',
                        maxHeight: '240px',
                        overflow: 'auto',
                      }}
                    >
                      <Grid
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        container
                        direction="row"
                      >
                        {this.state.bulkFileUploaded.map(
                          (imgFile: any, index: number) => (
                            <React.Fragment key={index}>
                              <Grid item xs={10}>
                                <img
                                  src={window.URL.createObjectURL(imgFile.file)}
                                  style={{ maxHeight: 240, maxWidth: '100%' }}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <IconButton style={{ height: 24, width: 24 }}>
                                  <img
                                    src={imgDeleteGray}
                                    onClick={() => {
                                      const updatedBulkUploaded: any = this.state.bulkFileUploaded.filter(
                                        (item: any, imgIndex: number) =>
                                          imgIndex !== index
                                      );
                                      this.setState({
                                        bulkFileUploaded: [
                                          ...updatedBulkUploaded,
                                        ],
                                      });
                                    }}
                                    style={{ height: 18, width: 18 }}
                                  />
                                </IconButton>
                              </Grid>
                            </React.Fragment>
                          )
                        )}
                      </Grid>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        position: 'absolute',
                        top: 20,
                        right: 20,
                      }}
                    >
                      {/* <IconButton
                        style={{ height: 24, width: 24, marginRight: 12 }}
                      >
                        <img
                          src={imageReUploadIcon}
                          onClick={() => {
                            this.inputOpenFileRef?.current?.click();
                          }}
                          style={{ height: 18, width: 18 }}
                        />
                      </IconButton> */}
                      {/* <IconButton style={{ height: 24, width: 24 }}>
                        <img
                          src={imgDeleteGray}
                          onClick={() => {
                            this.setState({
                              bulkFileUploaded: [],
                            });
                          }}
                          style={{ height: 18, width: 18 }}
                        />
                      </IconButton> */}
                    </Box>
                  </Box>
                )}
              </FileDrop>

              <Box
                className={
                  classes.studentUploadAssesment_AssessmentTest_ButtonBox
                }
                style={{ textAlign: 'center' }}
              >
                <Button
                  className={`${classes.studentUploadAssesment_AssessmentTest_DoneBtn} ${classes.button_color} file_text_500`}
                  style={{
                    opacity:
                      this.state.bulkFileUploaded &&
                        this.state.bulkFileUploaded.length > 0
                        ? 1
                        : 0.6,
                  }}
                  disabled={
                    this.state.bulkFileUploaded &&
                      this.state.bulkFileUploaded.length > 0
                      ? false
                      : true
                  }
                  onClick={this.uploadFile}
                >
                  <Typography
                    style={{ textTransform: 'none', color: '#fff' }}
                    className={`${classes.studentUploadAssesment_AssessmentTest_BottomBtnTextFont} file_text_500`}
                  >
                    {' '}
                    Done
                  </Typography>
                </Button>
                <Button
                  className=
                  {`${classes.studentUploadAssesment_AssessmentTest_UploadBtn} ${classes.outline_button}`}
                  onClick={() => this.props.handleOnClose()}
                >
                  <Typography
                    style={{ textTransform: 'none' }}
                    className={`${classes.studentUploadAssesment_AssessmentTest_BottomBtnTextFont}`}
                  >
                    cancel
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        {this.state.alertModal && (
          <AlertModal
            alertModal={this.state.alertModal}
            isTeacher={false}
            onCloseModal={() => {
              this.setState({ alertModal: false });
            }}
            alertTitle="Alert!"
            alertMessage={this.state.alertMessage}
            buttonText="OK"
          />
        )}
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
// @ts-ignore
export default withStyles(themeCreateStyle)(StudentUploadAssessment);
// Customizable Area End
