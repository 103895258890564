// Customizable Area Start
import React from "react";
import {
    StyleSheet,
    Platform,
} from "react-native";
import Picker from "emoji-picker-react";
import Grid from '@material-ui/core/Grid';
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    IconButton,
    Link,
    MenuItem,
    Select,
    NativeSelect,
    TextField,
    Typography,

} from "@material-ui/core";
// import {
//     Search,
//     Replay,
//     NotificationsNone,
//     HeadsetMicOutlined,
//     Title,
//     TitleOutlined,
//     KeyboardArrowLeft,
//     EditOutlined,
//     MoreVertOutlined,
//     KeyboardArrowDown,
//     DesktopAccessDisabledOutlined,
//     ArrowDropDown
// } from "@material-ui/icons";
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import ThoughtInActiveModalController, { Props } from "./ThoughtInactiveController.web";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import { truncateSync } from "fs";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FileDrop } from 'react-file-drop';
// Customizable Area End

// Customizable Area Start
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
// Customizable Area End

class InActiveModal extends ThoughtInActiveModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const deleteMes1 = {
            fontSize: "1.5rem",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            margin: '31px 32px 6px 33px',

        } as const;

        const deleteDiv = {
            fontSize: "1.5rem",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            marginBottom: '14px',

        } as const;
        const deleteMes2 = {
            fontFamily: "Open sans",
            fontSize: "0.875rem",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#959393",
            margin: '6px 53px 40px 54px',

        } as const;
        const cancelButton = {
            margin: '0 20px 0 0',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',

        } as const;
        const deleteButton = {
            margin: '0 0px 0 20px',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',
        } as const;
        const cancelText = {
            fontSize: "0.875rem",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center',

        } as const;
        const deleteText = {
            fontSize: "0.875rem",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center'

        } as const;

        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    open={this.props.InActiveModal}
                    onClose={this.modalhandleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableAutoFocus={true}
                >
                    <Fade in={this.props.InActiveModal} style={{ border: "none" }}>
                        <Box
                            style={{
                                justifyContent: "center",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'white',

                                borderRadius: "29px",
                                padding: "32px 18px",
                                // width: 800,
                                width: "650px",

                            }}
                        >
                            <div style={deleteDiv} className={this.props.classes.title_font}>Inactive Thought</div>
                            <div style={{
                                height: "1px",
                                margin: "14px 0 31px 0",
                            }}
                                className={this.props.classes.border_Color}>
                            </div>
                            <div style={deleteMes1} className={this.props.classes.subTitle_font}>Are you sure you want to inactive the thought?</div>
                            <div style={{
                                //  width: "389px",
                                //  margin: 6px 123px 44px 115px;
                                fontFamily: "Open Sans",
                                fontSize: "14px",
                                fontWeight: 500,
                                textAlign: "center",
                                color: " #959393"
                            }} className={this.props.classes.bodyText_font}>This item will be remove from all dashboards immediately</div>
                            <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
                                <Button variant="contained" style={cancelButton}
                                    onClick={this.modalhandleClose}
                                    className={this.props.classes.button_color_disabled}
                                >
                                    <span style={cancelText}>Cancel</span></Button>
                                <Button variant="contained" style={deleteButton}
                                    className={this.props.classes.button_color}
                                    onClick={this.props.handleInactiveSave}
                                ><span style={deleteText}>Confirm</span></Button>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </>
        )
    }
    // Customizable Area End
}
export const themeCreatStyle = ((theme: any) => ({
    // Customizable Area Start
    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    checkBox_color: {
        color: theme.props.buttonColor
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: theme.props.buttonColor,
        }
    },
    border_Color: {
        border: `0.3px solid ${theme.props.buttonColor}`
    },
    icon_color: {
        color: theme.props.iconColor
    },
    file_border_Color: {
        border: `dashed 0.5px ${theme.props.buttonColor}`,
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    },
    normal_button_color: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
    },
    dashed_Border: {
        border: `0.3px dashed ${theme.props.buttonColor}`,
    },
    // Customizable Area End
}))

// Customizable Area Start
export default withStyles(themeCreatStyle)(InActiveModal);
// Customizable Area End
