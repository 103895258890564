// Customizable Area Start
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import MessageEnum, {
  getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';
export const configJSON = require('../config.js');
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  openChooseFromGalleryModal: boolean;
  onCloseModal: any;
  onClickImage: any;
  onClickChoose: any;
  selectedGalleryImageId: number;
  onTabChangeClearId: any;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  questions: any[];
  showLoader: boolean;
  selectedQuestions: string;
  types: any[];
  selectedType: string;
  searchText: string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class QuestionsLayoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuestionsListId: string = '';
  searchQuestionListId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      questions: [],
      showLoader: false,
      selectedQuestions: '',
      types: [],
      selectedType: '',
      searchText: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getQuestionsList();
  }
  async selectQuestionCategory(selectedQuestion: string, category: string) {
    const basicCategory = this.state.questions.find(
      (item) => item.name === category
    );
    if (!basicCategory) {
      return;
    }
    const subCategory = basicCategory.sub_categories.find(
      (item: any) => item.name === selectedQuestion
    );
    this.setState({
      selectedQuestions: selectedQuestion,
      types: subCategory.types,
    });
  }
  async componentWillUnmount() {
    this.setState({ types: [] });
  }
  setSelectedType = (type: string) => {
    this.setState({ selectedType: type });
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getQuestionsListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              questions: [
                {
                  id: responseJson[0].id,
                  name: responseJson[0].name,
                  sub_categories: responseJson[0].sub_categories,
                },
                {
                  id: responseJson[1].id,
                  name: responseJson[1].name,
                  sub_categories: responseJson[1].sub_categories,
                },
                {
                  id: responseJson[2].id,
                  name: responseJson[2].name,
                  sub_categories: responseJson[2].sub_categories,
                },
              ],
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.getQuestionsListId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            //
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getQuestionsList = () => {
    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionsListId = requestMessage.messageId;

    let endPoint = configJSON.getQuestionsListv2;

    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  searchQuestionList = () => {
    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchQuestionListId = requestMessage.messageId;

    let endPoint = `${configJSON.searchQuestionList}${this.state.searchText}`;

    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.examinationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
