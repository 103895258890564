// Customizable Area Start
import React from "react";
import ReactPaginate from "react-paginate";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  Menu,
  MenuItem
} from "@material-ui/core";

import {
  Search,
  MoreVertOutlined,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import AdminClassesAndSectionsController, { Props } from "./AdminClassesAndSectionsController.web";
import 'react-calendar/dist/Calendar.css';
import { filter } from "./assets";
import UploadClass from "./UploadClass.web";
import AddClass from "./AddClass.web";
import DeleteAlertModal from "./DeleteAlertModal.web";
import FilterScreen from "./FilterScreen.web";
import EditClass from "./EditClass.web";
import Spinner from "../../shared/SideBar/src/Spinner";
import HOCAuth from "../../shared/Header/src/HOCAuth.web";
import './AddClass.web.css';
import './AdminClassesAndSections.web.css'
import { themeCreateStyle } from "../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class AdminClassesAndSections extends AdminClassesAndSectionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { schoolData } = this.state
    return (
      <Box>
        {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
        <Box className={`admin_classes_parent_container`}>
          <Box>
            <Typography
              className={`${this.props.classes.heading_font} admin_classes_title`}
            >
              Class
            </Typography>
          </Box>
          <Box>
            <Button
              disableRipple={true}
              variant="outlined"
              onClick={() => { this.setState({ uploadClass: !this.state.uploadClass }) }}
              className={`${this.props.classes.button_color_disabled} admin_classes_upload_button`}
            >
              Upload Class
            </Button>
            {this.state.uploadClass && <UploadClass uploadClass={this.state.uploadClass} closeModalFun={this.onCloseModal} navigation={undefined} />}
            <Button
              disableRipple={true}
              variant="outlined"
              onClick={() => { this.setState({ addClass: !this.state.addClass }) }}
              className={`${this.props.classes.button_color} admin_classes_add_button`}
            >
              Add Class
            </Button>
            {this.state.addClass && <AddClass addClass={this.state.addClass} closeModalFun={this.onAddCloseModal} navigation={undefined} />}

          </Box>
        </Box>
        <Box className={`${this.props.classes.border_Color} admin_classes_search_box`}>
          <input
            placeholder="Search subject, class, name, division…"
            className="admin_classes_search_textfield"
            onChange={
              ((event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  this.get_School_Lists(this.state.pagination_page + 1)
                }
                else {
                  this.search_data(event.target.value.trim())
                }

              })}
          />
          <Search
            className={`${this.props.classes.icon_color} admin_classes_search_icon`}
          />

        </Box>
        <Box className="admin_classes_container_box">
          <Box className="admin_classes_filter_box">
            <Button
              disableRipple={true}
              variant="contained"
              className="admin_classes_filter_button"
              id="1"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => { this.setState({ filterAnchorEl: event.currentTarget }) }}
            >
              <img src={filter} className="admin_classes_filter_button_img" />
              Filter
            </Button>
          </Box>
          {
            this.state.filterAnchorEl
            &&
            <FilterScreen
              filterAnchorEl={this.state.filterAnchorEl}
              navigation={undefined}
              popoverClose={() => { this.setState({ filterAnchorEl: null }) }}
              applyFilter={this.applyFilter}
              className={this.state.className}
              grade_id={this.state.grade_id}
              subjectData={this.state.subjectData}
              divisionData={this.state.divisionData}
            />}
          <Box className="division-table1">
            <Grid className="row-scroll admin_classes_listing_box_head" container>
              <Grid item xs={6}>
                <Box
                  className={`${this.props.classes.subHeading_font} admin_classes_listing_head_text`}
                >
                  Subjects
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={`${this.props.classes.subHeading_font} admin_classes_listing_head_text_center`}>
                  Name
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={`${this.props.classes.subHeading_font} admin_classes_listing_head_text_center`}>
                  Grade
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={`${this.props.classes.subHeading_font} admin_classes_listing_head_text_center`}>
                  Division
                </Box>
              </Grid>
            </Grid>
            {
              schoolData.length === 0
                ?
                <>
                  <Typography className="admin_classes_no_record_text">
                    No record(s) found.
                  </Typography>
                </>
                :
                schoolData.map((data: any) => (
                  <>
                    <Box className={`row-scroll ${this.props.classes.adminClassesAndSections_classAndSection_mt_10}`}>
                      <Grid
                        container
                        className="admin_classes_listing_box_body"
                      >
                        <Grid item xs={6}>
                          <Box className={`${this.props.classes.bodyText_font} admin_classes_listing_box_body_text`}>
                            {data?.attributes?.subjects?.map((subject: any, index: number) => (
                              <>
                                {index === data.attributes.subjects.length - 1 ? <>{subject.subject_name}</> : <>{subject.subject_name},{" "}</>}
                              </>
                            ))}
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box className={`${this.props.classes.bodyText_font} admin_classes_listing_box_body_text_center`}>
                            {data.attributes.display_name}
                          </Box>
                        </Grid>

                        <Grid item xs={2}>
                          <Box className={`${this.props.classes.bodyText_font} admin_classes_listing_box_body_text_center`}>
                            {data.attributes?.grade?.name}
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box className={`${this.props.classes.bodyText_font} admin_classes_listing_box_body_text_end`} >
                            {data.attributes?.divisions?.name}
                          </Box>
                        </Grid>

                        <Grid item xs={1}>
                          <Box className="admin_classes_more_icon_box">
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              className={this.props.classes.adminClassesAndSections_classAndSection_p_0}
                            >
                              <MoreVertOutlined
                                className="admin_classes_more_icon"
                                onClick={(event: any) => {
                                  this.setState({ anchorEl: event.currentTarget, school_class_id: data.id })
                                }}

                              />
                            </IconButton>
                            <Menu
                              id="basic-menu"
                              anchorEl={this.state.anchorEl}
                              open={Boolean(this.state.anchorEl)}
                              onClose={() => { this.setState({ anchorEl: '' }) }}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              <Box className="admin_classes_menu_box">
                                <MenuItem onClick={() => { this.setState({ EditClass: !this.state.EditClass }) }}>
                                  <Typography className={`${this.props.classes.bodyText_font} admin_classes_menu_options`}>
                                    Edit
                                  </Typography>
                                </MenuItem>
                                {
                                  this.state.EditClass && this.state.school_class_id === data.id &&
                                  <EditClass
                                    EditClassModal={this.state.EditClass}
                                    closeModalFun={() => {
                                      this.setState({ EditClass: false, anchorEl: null });
                                      this.get_School_Lists(this.state.pagination_page + 1);
                                    }}
                                    editData={data} navigation={undefined} />
                                }
                                <MenuItem onClick={() => { this.setState({ deleteAlertModal: !this.state.deleteAlertModal }) }}>
                                  <Typography className={`${this.props.classes.bodyText_font} admin_classes_menu_options`}>
                                    Delete
                                  </Typography>
                                </MenuItem>
                              </Box>
                            </Menu>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ))}
            {this.state.deleteAlertModal && <DeleteAlertModal deleteAlertModal={this.state.deleteAlertModal} closeModalFun={this.onDeleteCloseModal} id={this.state.school_class_id} />}
            <Box className="admin_classes_paginate_box">
              <ReactPaginate
                previousLabel={"←   Previous"}
                nextLabel={"Next   →	"}
                breakLabel="..."
                initialPage={this.state.pagination_page}
                pageCount={this.state.timeLineMeta?.pagination?.total_pages}
                onPageChange={(e: any) => {
                  if (e.selected !== this.state.pagination_page) {
                    this.setState({ pagination_page: e.selected })
                    this.get_School_Lists(e.selected + 1)
                  }
                }}
                containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                pageRangeDisplayed={this.state.timeLineMeta?.pagination?.total_pages}
                marginPagesDisplayed={0}
              />
            </Box>
          </Box>
        </Box>

      </Box>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(AdminClassesAndSections as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End
