// Customizable Area Start
import React from 'react';
// import './AdminReportCardTemplate.web.css';
import StudentPagination from './StudentPagination.web'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import StudentScholasticAreasController, { Props } from './StudentScholasticAreasController.web';
import Spinner from '../../../shared/SideBar/src/Spinner';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class StudentScholasticAreas extends StudentScholasticAreasController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { isLoader, scholasticListData, scholasticDynamicName } = this.state

        return (
            <>
                {isLoader && <Spinner spinnerModal={isLoader} />}
                <div>
                <div className='profileTitle'>
                    <h2 className={this.props.classes.bodyText_font}>Report Card - {scholasticDynamicName}</h2>
                </div>
                    {scholasticListData?.length != 0 && scholasticListData?.attributes?.terms?.map((termData: any) => (
                        <TableContainer key={termData.id} className="tableContent">
                            <Table>
                                <TableHead className={this.props.classes.studentReportCard_classAndSection_background_color}>
                                    <TableRow>
                                        <TableCell className={`${this.props.classes.studentReportCard_classAndSection_tableRowCell}`} align="center" style={{ borderRadius: "0px" }}>
                                            <span className={`assessment-name-header`}>{this.props.moduleName}</span>
                                        </TableCell>
                                        <TableCell colSpan={termData.colSpan} className={`${this.props.classes.studentReportCard_classAndSection_tableRowCell}`} align="center" style={{ borderRadius: "0px" }}>
                                            <span className={`assessment-name-header`}>{termData?.title}</span>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        {scholasticListData?.attributes?.report_categories?.map((categoryData: any, categoryIndex: number) => (
                                            <React.Fragment key={categoryIndex}>
                                                {
                                                    categoryData?.categories?.map((data: any, tempIndex: number) => (
                                                        <React.Fragment key={tempIndex}>
                                                            {categoryData.type === "assessment" ?
                                                                <>
                                                                    {data?.sub_category?.data?.length > 0 && data?.sub_category?.data?.filter((subData: any) => {
                                                                        if (subData.attributes.term_id === termData.id) {
                                                                            return subData;
                                                                        }
                                                                    }).length > 0 && <TableCell align='center' style={{ borderLeft: "1px solid lightgray", borderTop: "1px solid lightgray" }} colSpan={data?.sub_category?.data?.filter((subData: any) => {
                                                                        if (subData.attributes.term_id === termData.id) {
                                                                            return subData;
                                                                        }
                                                                    }).length}>
                                                                            <span className={`${this.props.classes.heading_font} category-name-header`}>{data.name}</span>
                                                                        </TableCell>}
                                                                </> :
                                                                <>
                                                                    {data?.sub_category?.length > 0 && data?.sub_category?.filter((subData: any) => {
                                                                        if (subData.term_id === termData.id) {
                                                                            return subData;
                                                                        }
                                                                    }).length > 0 && <TableCell align='center' style={{ borderLeft: "1px solid lightgray", borderTop: "1px solid lightgray" }} colSpan={data?.sub_category?.filter((subData: any) => {
                                                                        if (subData.term_id === termData.id) {
                                                                            return subData;
                                                                        }
                                                                    }).length}>
                                                                            <span className={`${this.props.classes.heading_font} category-name-header`}>{data.name}</span>
                                                                        </TableCell>}
                                                                </>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                            </React.Fragment>
                                        ))}
                                    </TableRow>

                                    <TableRow>
                                        <TableCell></TableCell>
                                        {scholasticListData?.attributes?.report_categories?.map((categoryData: any, categoryIndex: number) => (
                                            <React.Fragment key={categoryIndex}>
                                                {
                                                    categoryData?.categories?.map((data: any) => (
                                                        <React.Fragment key={data.id}>
                                                            {categoryData.type === "assessment" ?
                                                                <>
                                                                    {data?.sub_category?.data?.length > 0 && data?.sub_category?.data?.filter((subData: any) => {
                                                                        if (subData.attributes.term_id === termData.id) {
                                                                            return subData;
                                                                        }
                                                                    }).map((finalSubData: any) => {
                                                                        return <TableCell align='center' style={{ borderLeft: "1px solid lightgray", padding: 10 }} key={finalSubData.id}>
                                                                            <span className={`${this.props.classes.heading_font} category-name-header`}>
                                                                                {finalSubData.attributes.name}
                                                                                <br />({finalSubData?.attributes?.total_marks ? finalSubData?.attributes?.total_marks : "-"})
                                                                            </span>
                                                                        </TableCell>
                                                                    })}
                                                                </> :
                                                                <>
                                                                    {data?.sub_category?.length > 0 && data?.sub_category?.filter((subData: any) => {
                                                                        if (subData.term_id === termData.id) {
                                                                            return subData;
                                                                        }
                                                                    }).map((finalSubData: any) => {
                                                                        return <TableCell align='center' style={{ borderLeft: "1px solid lightgray", padding: 10 }} key={finalSubData.id}>
                                                                            <span className={`${this.props.classes.heading_font} category-name-header`}>
                                                                                {finalSubData.name}
                                                                                <br />({finalSubData?.total_marks ? finalSubData?.total_marks : "-"})
                                                                            </span>
                                                                        </TableCell>
                                                                    })}
                                                                </>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                            </React.Fragment>
                                        ))}
                                    </TableRow>

                                    {scholasticListData?.attributes?.class_subjects?.map((subjectData: any) => (
                                        <TableRow key={subjectData.id}>
                                            <TableCell className={this.props.classes.studentReportCard_classAndSection_commonCss} style={{ borderRadius: "0px" }}>
                                                <span className={`${this.props.classes.heading_font} category-name-header`}>{subjectData?.subject_name}</span>
                                            </TableCell>
                                            {scholasticListData?.attributes?.report_categories?.map((categoryData: any, categoryIndex: number) => (
                                                <React.Fragment key={categoryIndex}>
                                                    {
                                                        categoryData?.categories?.map((data: any) => (
                                                            <React.Fragment key={data.id}>
                                                                {categoryData.type === "assessment" ?
                                                                    <>
                                                                        {data?.sub_category?.data?.length > 0 && data?.sub_category?.data?.filter((subData: any) => {
                                                                            if (subData.attributes.term_id === termData.id) {
                                                                                return subData;
                                                                            }
                                                                        }).map((finalSubData: any) => {
                                                                            return <TableCell align='center' style={{ borderRight: "1px solid lightgray", padding: 10 }} key={finalSubData.id}>

                                                                                {Number(finalSubData.attributes.subject_id) === Number(subjectData.id) ?
                                                                                    <span>{finalSubData?.attributes?.marks ? finalSubData?.attributes?.marks : ""}</span>
                                                                                    :
                                                                                    <span>-</span>
                                                                                }
                                                                            </TableCell>
                                                                        })}
                                                                    </> :
                                                                    <>
                                                                        {data?.sub_category?.length > 0 && data?.sub_category?.filter((subData: any) => {
                                                                            if (subData.term_id === termData.id) {
                                                                                return subData;
                                                                            }
                                                                        }).map((finalSubData: any) => {
                                                                            return <TableCell align='center' style={{ borderRight: "1px solid lightgray", padding: 10 }} key={finalSubData.id}>
                                                                                {Number(finalSubData.subject_id) === Number(subjectData.id) ?
                                                                                    <span>{finalSubData?.marks ? finalSubData?.marks : ""}</span>
                                                                                    :
                                                                                    <span>-</span>
                                                                                }
                                                                            </TableCell>
                                                                        })}
                                                                    </>
                                                                }
                                                            </React.Fragment>
                                                        ))}
                                                </React.Fragment>
                                            ))}
                                        </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    ))}
                </div>
                <StudentPagination intialPage={1}/>
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
    StudentScholasticAreas as React.ComponentType<any>
);
// Customizable Area End
