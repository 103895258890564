export const parentAvatar = require("../assets/parent-avatar.png");
export const createVideoIcon = require("../assets/create-video-icon.png");
export const facebookIcon = require("../assets/image-facebook.png");
export const twitterIcon = require("../assets/image-twitter.png");
export const instagramIcon = require("../assets/image-insagram.png");
export const linkedinIcon = require("../assets/image-linkedin.png");
export const lockImage = require("../assets/lock-image.png");
export const book = require("../assets/book.png");
export const studentAvatar = require("../assets/student-avatar.png");
export const addPhotoIcon = require("../assets/add-photo-icon.png");
export const badgeStar = require("../assets/image-badgestar.png").default;
export const topperinscience = require("../assets/image-topperinscience.png");
export const madel = require("../assets/image-madel.png");
export const trophy = require("../assets/image-trophy.png").default;
export const pc = require("../assets/image-pc.png");
export const phone = require("../assets/image-phone.png");
export const locationPin = require("../assets/locationPin.png").default;
export const birthCertification = require("../assets/birth-certificate.png")
  .default;
export const camera = require("../assets/group-6@3x.png").default;
export const aadhaar = require("../assets/group-11@3x.png").default;
export const certificate1 = require("../assets/group-13@3x.png").default;
export const certificate2 = require("../assets/group-25@3x.png").default;
export const check = require("../assets/check@3x.png");
export const uploadFile = require("../assets/image-upload@3x.png");
export const whatsapp = require("../assets/image-whatsapp@3x.png");
export const mobile = require("../assets/image-mobilephone@3x.png");
export const email = require("../assets/image-email@3x.png");
export const web = require("../assets/image-web@3x.png");
export const ringing = require("../assets/ringing@3x.png");
export const add1 = require("../assets/add-1@3x.png");
export const history = require("../assets/history@3x.png");
export const history1 = require("../assets/history@3x copy.png");
export const min45 = require("../assets/45-minutes@3x.png");
export const starred = require("../assets/starred@3x.png");
export const pieChart = require("../assets/pie-chart@3x.png");
export const online = require("../assets/online-business@3x.png");
export const shareImg = require("../assets/share-black-24-dp.png");
export const student_default_profile_pic = require("../assets/student-default-profile-pic.png");
export const calendarImg = require("../assets/image-calendar_2021-10-20/image-calendar.png");
export const calendarIcon = require("../assets/dateCalendar.png");
export const arrowDownImg = require("../assets/image-downfilled_2021-10-20/image-downfilled.png");
export const percentageImg = require("../assets/image-percentage_2021-10-21/image-percentage.png");
export const refreshImg = require("../assets/image-refresh_2021-10-19/image-refresh.png");
export const bellImg = require("../assets/image-bell_2021-10-19/image-bell.png");
export const supportImg = require("../assets/image-support_2021-10-19/image-support.png");
export const fontImg = require("../assets/image-font_2021-10-19/image-font.png");
export const englishImg = require("../assets/image-englishuk_2021-10-19/image-englishuk.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backArrowIcon = require("../assets/back-arrow-icon.png");
export const emailTeacher = require("../assets/image-email.png");
export const ringingTeacher = require("../assets/image-pushnotif.png");
export const events = require("../assets/add-1.png");
export const lockIcon = require("../assets/image-lockicon.png");
export const pcTeacher = require("../assets/pc.png");
export const file = require("../assets/history-copy.png");
export const message = require("../assets/history-copy-2.png");
export const taskAndResources = require("../assets/45-minutes-taskandresources.png");
export const portfolio = require("../assets/portfolio.png");
export const attendance = require("../assets/image-attendance.png");
export const files = require("../assets/image-files.png");
export const uploadImg = require("../assets/image-upload.png");
export const tickMark = require("../assets/image-tickmark.png");
export const file_uploaded_pic = require("../assets/file-uploaded.png");
export const file_uploaded_check_icon = require("../assets/file-uploaded-check.png");
export const mobileTeacher = require("../assets/group-2.png");
export const other_options_icon = require("../assets/other-options-icon.png");
export const delete_outline_black = require("../assets/delete-outline-black.png");
export const restart_alt_black = require("../assets/restart-alt-black.png");
export const robotWebcam = require("../assets/robot-webcam.png");
export const checkSuccess = require("../assets/check.png");
export const oval = require("../assets/oval.png").default;
export const create_video_bg = require("../assets/create-video-bg.png").default;
export const video_recording_btn_icon = require("../assets/video-recording-btn-icon.png");
export const video_recording_stop_btn_icon = require("../assets/video-recording-stop-btn-icon.png");
export const videoIcon = require("../assets/group-26.png");
export const fileIcon = require("../assets/file.png");
export const pngIcon = require("../assets/png-icon.png");
export const jpgIcon = require("../assets/jpg-icon.png");
export const pdfIcon = require("../assets/pdf-icon.png");
export const docIcon = require("../assets/doc-icon.png");
export const uploadSvg = require("../assets/upload.svg");
export const openIcon = require("../assets/open-in-new-black-24-dp.png");
export const cancelIcon = require("../assets/cancel-black-24-dp.png");
export const file_upload_black = require("../assets/file-upload-black-24-dp.png");
export const photo_camera_black = require("../assets/photo-camera-black-24-dp.png");
export const camera_icon = require("../assets/camera-icon.png");
export const addPhotoSvg = require("../assets/group-4.svg");
export const tickMark2 = require("../assets/image-tickmark-2.png");
export const addBox = require("../assets/add-box-black-24-dp.png");
export const onlineBusiness = require("../assets/online-business.png");
export const unLockImage = require("../assets/unlock-image.jfif");
export const approveIcon = require("../assets/approveIcon.png")
export const rejectIcon = require("../assets/rejectIcon.png")
export const zoomIcon = require("../assets/zoomIcon.png")
export const resetIcon = require("../assets/resetIcon.png")
export const moreIcon = require("../assets/moreIcon.png")
export const closeIcon = require("../assets/close@3x.png")
// Customizable Area Start
// Customizable Area End