// Customizable Area Start
import React from 'react';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { withStyles } from '@material-ui/core/styles';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { ListItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { HISTORY } from "../../../../components/src/comman";
import AssessmentLearning2Controller, { Props } from './AssessmentLearning2Controller.web';
import './AdminReportCardTemplate.web.css';
import Spinner from '../../../shared/SideBar/src/Spinner';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End

class AssessmentLearning2 extends AssessmentLearning2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const handleBack = () => {
            HISTORY.goBack();
        }

        const { coScholasticListData, isLoader, data } = this.state
        const handleClick = () => {
            HISTORY.push({
                pathname: "/GradingSystem",
                state: {
                    selectedClassData: data,
                }
            });
        };
        // Customizable Area End
        return (
        // Customizable Area Start
            <>
                {isLoader && <Spinner spinnerModal={isLoader} />}
                <div className='profileTitle'>
                <Button className={`backBtn ${this.props.classes.bodyText_font}`}  onClick={() => handleBack()}><KeyboardBackspaceOutlinedIcon /> Back</Button>
                    <h2 className={this.props.classes.heading_font}>{coScholasticListData?.attributes?.name}</h2>
                <Button className={`editBtn ${this.props.classes.bodyText_font}`} onClick={() => handleClick()}>Next <KeyboardBackspaceOutlinedIcon /></Button>
                </div>
                <div>
                    <div style={{
                        width: "100%",
                    }}
                    >
                        {coScholasticListData.length != 0 && coScholasticListData.attributes.report_topics.map((topicData: any) => (
                            <>
                                <Accordion className='accordianPart'>
                                    <AccordionSummary className='accordianPartsummary'
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography className={this.props.classes.subHeading_font}>{topicData.topic_name}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className='tableContentarea'>
                                        <TableContainer>
                                            <Table className='tableRow'>
                                                <TableHead className={this.props.classes.background_color}>
                                                    <TableRow>
                                                        <TableCell className={`${this.props.classes.assessmentLearning_classAndSection_tableRowCell}`} align="center">
                                                            {topicData.topic_name}
                                                        </TableCell>
                                                        {coScholasticListData.attributes.terms.map((termData: any) => (
                                                            <TableCell className={`${this.props.classes.assessmentLearning_classAndSection_tableRowCell}`} align="center">
                                                                {termData.title}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {topicData.report_attributes.map((data: any) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell className={this.props.classes.tableRow} style={{ borderRadius: "0px" }}>
                                                                    {data.name}
                                                                </TableCell>
                                                                {coScholasticListData.attributes.terms.map((termData: any, index: any) => (
                                                                    <TableCell className={this.props.classes.border_Cell} style={{ borderRadius: coScholasticListData.attributes.terms.length === index + 1 ? "0px" : '10px 0px 0px 0px' }}>
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer >
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        ))}
                    </div>
                </div>
            </>
        // Customizable Area End
        );
    }
}
export default withStyles(themeCreateStyle)(
    // Customizable Area Start
    HOCAuth(AssessmentLearning2 as React.ComponentType<any>, "AdminAccount")
    // Customizable Area End
);
