// Customizable Area Start
import { IconButton, Typography } from "@material-ui/core";
import React from "react";
import { Avatar } from "@material-ui/core";
import './Sidebar.css'
import {
  menu,
  calendar,
  ic_computer_active,
  chat,
  social_media,
  reports,
  portfolio,
  library,
  mail,
  admin_class,
  active_class,
  division,
  active_division,
  badged,
  active_badged,
  period_2,
  active_period_2,
  period,
  active_period,
  gradebook,
  active_gradebook,
  grade,
  active_grade,
  reminder,
  active_reminder,
  report_card,
  active_report_card,
  theme,
  active_theme,
  user,
  active_user,
  user2,
  active_user2,
  image_assessment_active,
  image_assessment_white,
  ic_doc,
  feedback,
  ToasterFullLogo,
  PoweredByFullLogo,
  PoweredByMainLogo
} from "./assets";
import SideBarController, { Props } from "./SideBarController";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Assessment, ChevronLeftRounded, ChevronRightRounded } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { ic_assignee } from "../../../ClassesAndSections/src/Resources/assets";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import HomeIcon from "./SvgIcons/HomeIcon.web";
import LibraryIcon from "./SvgIcons/LibraryIcon.web";
import ClassSectionIcon from "./SvgIcons/ClassSectionIcon.web";
import CalendarIcon from "./SvgIcons/CalendarIcon.web";
import ReportIcon from "./SvgIcons/ReportIcon.web";
import MailIcon from "./SvgIcons/MailIcon.web";
import PortfolioIcon from "./SvgIcons/PortfolioIcon.web";
import SocialMediaIcon from './SvgIcons/SocialMediaIcon.web';
import BadgesIcon from './SvgIcons/BadgesIcon.web';
import ReportCardIcon from './SvgIcons/ReportCardIcon.web';
import AssessmentIcon from './SvgIcons/AssessmentIcon.web';
import ChatIcon from "./SvgIcons/ChatIcon.web"
import FeedbackIcon from "./SvgIcons/FeedbackIcon.web"
import ReminderQuotesIcon from './SvgIcons/ReminderQuotesIcon.web';
import SchoolPeriodIcon from './SvgIcons/SchoolPeriodIcon.web';
import ClassPeriodIcon from './SvgIcons/ClassPeriodIcon.web';
import DivisionIcon from './SvgIcons/DivisionIcon.web';
import CurriculumIcon from './SvgIcons/CurriculumIcon.web';
import AdminClassIcon from './SvgIcons/AdminClassIcon.web';
import GradebookIcon from './SvgIcons/GradebookIcon.web';
import GradeIcon from './SvgIcons/GradeIcon.web';
import ThemeIcon from './SvgIcons/ThemeIcon.web';
import UserAccountsIcon from './SvgIcons/UserAccountsIcon.web';
import TermIcon from "./SvgIcons/TermIcon.web";
import DocumentIcon from "./SvgIcons/DocumentIcon.web";

// Customizable Area End
export class SideBarMenuList extends SideBarController {
  constructor(props: Props) {
    super(props);
// Customizable Area Start
// Customizable Area End
  }

  // Customizable Area Start
  user_data = localStorage.getItem("user_data");
  data = JSON.parse(this.user_data || "{}");

  adminavtarIcon = {
    width: "27px",
    height: "20px",
    margin: "0px 6px",
    objectFit: "contain",
  } as const;

  avtarIcon = {
    width: "27px",
    height: "26px",
    margin: "0px 6px",
    objectFit: "contain",
    color: "black"
  } as const;

  profile_url = () => {
    switch (this.data.type) {
      case "TeacherAccount":
        return "/TeacherDashboard";
      case "StudentAccount":
        return "/StudentDashboard";
      default:
        return "/StudentDashboard";
    }
  };
  classesAndSection_url = () => {
    switch (this.data.type) {
      case "TeacherAccount":
        return "/TeacherClassesAndSections";
      case "StudentAccount":
        return "/StudentClassesAndSections";
      default:
        return "/StudentClassesAndSections";
    }
  };
  reportCards = () => {
    switch (this.data.type) {
      case "TeacherAccount":
        return "/TeacherReportsDashboard";
      case "StudentAccount":
        return "#";
      default:
        return "#";
    }
  };
  assessment_url = () => {
    switch (this.data.type) {
      case 'TeacherAccount':
        return '/TeacherAssessment';
      case 'StudentAccount':
        return '/StudentAssessment';
      default:
        return '#';
    }
  };
  protfolio_url = () => {
    switch (this.data.type) {
      // case "TeacherAccount": return "/StudentProtfolio";
      case "StudentAccount":
        return "/StudentProtfolio";
      default:
        return "/StudentProtfolio";
    }
  };

  report_url = () => {
    switch (this.data.type) {
      case "TeacherAccount":
        return "/TeacherReportCard";
      case "StudentAccount":
        return "/StudentReportCard";
      default:
        return "#";
    }
  }
// Customizable Area End
  // studentAssessment_url = () => {
  //   switch (this.data.type) {
  //     case "StudentAccount":
  //       return "/StudentAssessment";
  //     default:
  //       return "/StudentAssessment";
  //   }
  // };
  // calendar_url = () => {
  //   switch (this.data.type) {
  //     // case "TeacherAccount": return "/StudentProtfolio";
  //     case "StudentAccount":
  //       return "/AdminPeriodCreation";
  //     default:
  //       return "/AdminPeriodCreation";
  //   }
  // };
// Customizable Area Start
  menuList = [
    {
      id: '1',
      href: this.profile_url(),
      img: () => {
        return (<HomeIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "1" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "1" ? "#ffb84d" : "#ffffff"} />)
      },
      text: 'Home',
    },
    {
      id: '2',
      href: this.classesAndSection_url(),
      img:  () => {
        return (<ClassSectionIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "2" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "2" ? "#ffb84d" : "#ffffff"} />)
      },// this.state.selected === "2" ? ic_computer_active : ic_computer
      text: 'Class',
    },
    {
      id: '3',
      href: this.assessment_url(),
      img: () => {
        return (<AssessmentIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "3" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "3" ? "#ffb84d" : "#ffffff"} />)
      },
      text: 'Assessment',
    },
    {
      id: '4',
      href: '#',
      img: () => {
        return (<CalendarIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.55" stroke={this.state.selectedMenuId === "4" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "4" ? "#ffb84d" : "#ffffff"} />)
      },
      text: 'Calendar',
    },
    {
      id: '5',
      href: '#',
      img: () => {
        return (<MailIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "5" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "5" ? "#ffb84d" : "#ffffff"} />)
      },
      text: 'Mail',
    },
    {
      id: '6',
      href: '#',
      img: () => {
        return (<ChatIcon width="32" height="26" viewBox="0 0 32 26" stroke-width="0.055" stroke={this.state.selectedMenuId === "6" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "6" ? "#ffb84d" : "#ffffff"} />)
      },
      text: 'Chat',
    },
    {
      id: '7',
      href: '#',
      img: () => {
        return (<LibraryIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "7" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "7" ? "#ffb84d" : "#ffffff"} />)
      },
      text: 'Library',
    },
    // {
    //   id: '8',
    //   href: this.protfolio_url(),
    //   img: portfolio,
    //   text: 'Portfolio',
    // },
    {
      id: '9',
      href: this.reportCards(),
      img: () => {
        return (<ReportIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.55" stroke={this.state.selectedMenuId === "9" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "9" ? "#ffb84d" : "#ffffff"} />)
      },
      text: 'Reports',
    },
    {
      id: '10',
      href: '#',
      img: () => {
        return (<SocialMediaIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.55" stroke={this.state.selectedMenuId === "10" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "10" ? "#ffb84d" : "#ffffff"} />)
      },
      text: 'Social Media',
    },
    {
      id: "11",
      href: "/Badges",
      img: () => {
        return (<BadgesIcon width="32" height="29" viewBox="0 0 32 29" stroke-width="0.055" stroke={this.state.selectedMenuId === "11" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "11" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Badge",
    },
    {
      id: "12",
      href: this.report_url(),
      img: () => {
        return (<ReportCardIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "12" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "12" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Report Card",
    }
  ];
// Customizable Area End
// Customizable Area Start
  adminMenuList = [
    {
      id: "1",
      href: "#",
      img: () => {
        return (<HomeIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "1" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "1" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Home",
    },
    {
      id: "2",
      href: "/admindivision",
      img: () => {
        return (<DivisionIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "2" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "2" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Division",
    },
    {
      id: "3",
      href: "/AdminClassesAndSections",
      img: () => {
        return (<AdminClassIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "3" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "3" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Class",
    },
    {
      id: "4",
      href: "/AdminEnrollUserAccount",
      img: () => {
        return (<UserAccountsIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "4" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "4" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "User",
    },
    {
      id: '5',
      href: "/AdminTerm",
      img: () => {
        return (<TermIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.55" stroke={this.state.selectedMenuId === "5" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "5" ? "#ffb84d" : "#ffffff"} />)
      },
      text: 'Term',
    },
    {
      id: "6",
      href: "/AdminClassPeriod",
      img: () => {
        return (<ClassPeriodIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "6" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "6" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Class Period",
    },
    {
      id: "7",
      href: "/AdminBatchAndPeriods",
      img: () => {
        return (<SchoolPeriodIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "7" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "7" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "School Period",
    },
    // {
    //   id: "8",
    //   href: "#",
    //   img: () => {
      //   return (<HomeIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "1" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "3" ? "#ffb84d" : "#ffffff"} />)
      // },
    //   text: "Portfolio",
    // },
    {
      id: "9",
      href: "/AdminReminder",
      img: () => {
        return (<ReminderQuotesIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "9" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "9" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Reminder",
    },
    {
      id: "10",
      href: "#",
      img: () => {
        return (<GradebookIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "10" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "10" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Grade Book",
    },
    {
      id: "11",
      href: "/AdminGradeView",
      img: () => {
        return (<GradeIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "11" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "11" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Grade",
    },
    {
      id: "12",
      href: "/Badges",
      img: () => {
        return (<BadgesIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "12" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "12" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Badge",
    },
    {
      id: "13",
      href: "/AdminReportCard",
      img: () => {
        return (<ReportCardIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "13" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "13" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Report Card",
    },
    {
      id: "14",
      href: "#",
      img: () => {
        return (<ThemeIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "14" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "14" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Theme",
    },
    {
      id: "15",
      href: "/UserDocument",
      img: () => {
        return (<DocumentIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.055" stroke={this.state.selectedMenuId === "15" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "15" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Document",
    },
    {
      id: "16",
      href: "/AdminFeedback",
      img: () => {
        return (<FeedbackIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.55" stroke={this.state.selectedMenuId === "16" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "16" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Feedback",
    },
    {
      id: "17",
      href: "/AdminAssessmentReport",
      img: () => {
        return (<ReportIcon width="27px" height="20px" viewBox="0 0 32 32" stroke-width="0.55" stroke={this.state.selectedMenuId === "17" ? "#ffb84d" : "#ffffff"} fillColor={this.state.selectedMenuId === "17" ? "#ffb84d" : "#ffffff"} />)
      },
      text: "Assessment Report",
    },
  ];
// Customizable Area End

// Customizable Area Start
  render() {
    const { selectedMenuId } = this.state;
    const { open, drawerWidth, drawerCloseWidth, hide } = this.props;

    return (
      <>

        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            whiteSpace: "nowrap",
            boxSizing: "border-box",
            // overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            height: "100vh",
            flexGrow: 0,
            flexBasis: open ? drawerWidth : drawerCloseWidth,
            width: open ? drawerWidth : drawerCloseWidth,
            transition: "all 0.4s ease-out",
          }}
          className={open ? `sidebarNav open-sidebar ${!hide ? this.props.classes.sidebar_menu_list_background_color : this.props.classes.sidebar_menu_list_backgroundOpen_sideBar_color}` : `sidebarNav ${hide ? this.props.classes.sidebar_menu_list_background_color : this.props.classes.sidebar_menu_list_backgroundOpen_sideBar_color}`}
          onMouseEnter={() => {
            this.props.handleDrawer(true);
          }}
          onMouseLeave={() => {
            this.props.handleDrawer(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: '10%'
            }}
          >
            <div style={{ marginTop: "3vh", maxHeight: "62px" }}>
              {!open ? (
                <img
                  src={this.data.school_logo?.data[0]?.logo_file?.data[0]?.attributes?.url != undefined || this.data.school_logo?.data[0]?.logo_file?.data[0]?.attributes?.url != null ? this.data.school_logo.data[0].logo_file.data[0].attributes.url : PoweredByMainLogo}
                  style={{
                    height: "30px",
                    width: "50px",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <img
                  src={this.data?.school_logo?.data[1]?.logo_file?.data[0]?.attributes?.url != undefined || this.data?.school_logo?.data[1]?.logo_file?.data[0]?.attributes?.url != null ? this.data.school_logo.data[1].logo_file.data[0].attributes.url : PoweredByFullLogo}
                  style={{
                    height: "50px",
                    width: "120px",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
          </div>
          <List
            className="sidebarHidden"
            style={{
              overflow: "hidden",
              height: '80%'
            }}
          >
            {
              this.state.schoolAdmin
                ?
                this.adminMenuList.map((item, index) => {
                  return (
                    <ListItem
                      key={index + 1}
                      onClick={() => {
                        if(item.href !== "#"){
                        this.handleClickMenuItem(item);
                        this.setState({selectedMenuId : item.id})
                      }
                      }}
                      style={{
                        color: selectedMenuId === item.id ? "#ffb84d" : "white",
                        cursor: "pointer",
                        paddingTop: 1,
                        whiteSpace: index === 15 ? "pre-wrap" :"nowrap"
                      }}
                    >
                      <ListItemIcon className="SidebarListIcon">
                        {/* <img
                          src={item.img}
                          style={this.adminavtarIcon}
                        // className={
                        //   selectedMenuId === item.id &&
                        //   this.props.classes.selectedMenuIcon
                        // }
                        /> */}
                        {item.img()}
                      </ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItem>
                  )
                })
                :
                this.menuList.map((item, index) => (

                    <ListItem
                      key={index + 1}
                      onClick={(e:any) => {
                        e.preventDefault()
                        if(item.href !== "#"){
                        this.handleClickMenuItem(item);
                        this.setState({selectedMenuId : item.id})
                       }
                      }}
                      style={{
                        color: selectedMenuId === item.id ? "#ffb84d" : "white",
                        cursor: "pointer",
                        display: ((Number(item.id) === 11) && this.data?.type === "StudentAccount") ? "none" : "flex"
                      }}
                    >
                      <ListItemIcon className="SidebarListIcon">
                        {/* <img
                          src={item.img}
                          style={this.avtarIcon}
                        // className={
                        //   selectedMenuId === item.id &&
                        //   this.props.classes.selectedMenuIcon
                        // }
                        /> */}
                         {item.img()}
                      </ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItem>

                ))}
          </List>
          {this.data?.school_logo?.data[1]?.logo_file?.data[0]?.attributes?.url != undefined &&
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                // position: 'absolute',
                bottom: '0%'
              }}
            >
              <div style={{ maxHeight: "62px" }}>
                <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', textAlign: 'start' }}>
                  <Typography style={{
                    fontFamily: 'Open sans',
                    fontSize: 10,
                    opacity: 0.6,
                    color: '#ffffff'
                  }}>
                    Powered by
                  </Typography>
                  {!open ? (
                    <>

                      <img
                        src={PoweredByMainLogo}
                        style={{
                          height: "30px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                      />
                    </>
                  ) : (
                    <img
                      src={PoweredByFullLogo}
                      style={{
                        height: "50px",
                        width: "100px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </span>
              </div>
            </div>
          }
        </div>
      </>
    );
  }
// Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(SideBarMenuList);
// Customizable Area End