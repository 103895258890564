// Customizable Area Start
export const themeCreateStyle = (theme: any) => ({
  background_color: {
    backgroundColor: theme?.palette?.primary?.main,
  },
  main_Primary_Color: {
    backgroundColor: theme.props.mainColor,
  },
  title_font: {
    color: theme?.props?.titleColor,
    fontFamily: theme?.props?.titleFont,
  },
  subTitle_font: {
    color: theme?.props?.subtitleColor,
    fontFamily: theme?.props?.subtitleFont,
  },
  heading_font: {
    color: theme?.props?.headingColor,
    fontFamily: theme?.props?.headingFont,
  },
  subHeading_font: {
    color: theme?.props?.subheadingColor,
    fontFamily: theme?.props?.subheadingFont,
  },
  bodyText_font: {
    color: theme?.props?.textColor,
    fontFamily: theme?.props?.textFont,
  },
  button_color: {
    backgroundColor: theme?.props?.buttonColor,
    color: 'white',
    border: `1px solid ${theme?.props?.buttonColor}`,
    '&:hover': {
      background: theme?.props?.buttonColor,
    },
  },
  image_Border_Color: {
    border: `3px solid ${theme?.props?.buttonColor}`,
  },
  icon_color: {
    color: theme?.props?.iconColor,
  },
  button_color_disabled: {
    color: theme?.props?.buttonColor,
    backgroundColor: 'white',
    border: `1px solid ${theme?.props?.buttonColor}`,
    '&:hover': {
      background: '#fff',
    },
  },
  play_btn_color: {
    color: theme.props.buttonColor,
  },
  outline_button: {
    outline: 'none',
    border: `1px solid ${theme?.props?.buttonColor}`,
    color: theme?.props?.buttonColor,
    fontFamily: theme?.props?.subtitleFont,
    '&:hover': {
      background: theme.props.buttonColor,
      color: theme.props.textColor,
    },
  },
  border_Color: {
    border: `1px solid ${theme?.props?.buttonColor}`,
  },
  outline_button_no_hover: {
    backgroundColor: 'white',
    color: theme?.props?.buttonColor,
    border: `1px solid ${theme?.props?.buttonColor}`,
  },
  theme_font: {
    fontFamily: theme?.props?.titleFont,
  },
  input_theme_style: {
    '& input': {
      color: theme?.props?.textColor,
      fontFamily: theme?.props?.textFont,
    },
  },
  background_button_no_hover: {
    backgroundColor: '#f8f8f8',
    color: 'black',
    border: `none`,
  },
  textField_input_theme_style: {
    fontFamily: 'Open sans',
    fontSize: 15.8,
    color: '#03014c',
  },
  //Customizable Area ParentNewDashboard.web.tsx Start
  parentNewDashboard_dashboard_border_Color: {
    border: `1px solid ${theme?.props?.buttonColor}`,
  },
  parentNewDashboard_dashboard_font_16: {
    fontSize: 16,
  },
  parentNewDashboard_dashboard_font_19: {
    fontSize: 19,
  },
  parentNewDashboard_dashboard_heading_font: {
    color: theme?.props?.headingColor,
    fontFamily: theme?.props?.headingFont,
    fontSize: '22px !important',
  },
  //Customizable Area ParentNewDashboard.web.tsx End

  //Customizable Area ParentAnnouncement.web.tsx Start
  parentAnnouncement_dashboard_announcement_title: {
    minHeight: '44px',
    margin: '16px 0 0',
    fontSize: '16px',
    color: '#ffffff',
    fontFamily: 'Open sans',
  },
  parentAnnouncement_dashboard_announcement_view_all: {
    width: '58px',
    height: '18px',
    margin: '23px 287px 0 0',
    fontSize: '13px',
    color: '#fff',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  parentAnnouncement_dashboard_text_font_family: {
    fontFamily: theme?.props?.subtitleFont,
  },
  //Customizable Area ParentAnnouncement.web.tsx End

  //Customizable Area ParentCalendar.web.tsx Start
  parentCalendar_dashboard_font_18: {
    fontSize: 18,
  },
  parentCalendar_dashboard_font_14: {
    fontSize: 14,
  },
  //Customizable Area ParentCalendar.web.tsx End

  //Customizable Area ParentCalendar.web.tsx Start

  parentFees_dashboard_font_14: {
    fontSize: 14,
  },
  parentFees_dashboard_font_20: {
    fontSize: 20,
  },
  parentFees_dashboard_margin_tb: {
    margin: '20px 0',
  },
  parentFees_dashboard_margin_top: {
    marginTop: '10px',
  },
  //Customizable Area ParentCalendar.web.tsx End

  //Customizable Area ParentLeave.web.tsx Start
  parentFees_dashboard_font_22: {
    fontSize: 22,
  },
  //Customizable Area ParentLeave.web.tsx End

  //Customizable Area ParentNotes.web.tsx Start
  parentNotes_dashboard_font_12: {
    fontSize: 12,
  },
  parentNotes_dashboard_font_14: {
    fontSize: 14,
  },
  //Customizable Area ParentNotes.web.tsx End

  //Customizable Area ParentNotification.web.tsx Start
  parentNotification_dashboard_text_font_family: {
    fontFamily: theme?.props?.subtitleFont,
  },
  parentNotification_dashboard_notification_title: {
    minHeight: '44px',
    fontFamily: theme?.props?.textFont,
    margin: '16px 0 0',
    fontSize: '16px',
    color: '#ffffff',
  },
  parentNotification_dashboard_notification_view_all: {
    width: '58px',
    height: '18px',
    margin: '23px 287px 0 0',
    fontSize: '13px',
    color: '#fff',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  //Customizable Area ParentNotification.web.tsx End

  //Customizable Area WardDetails.web.tsx Start
  wardDetails_dashboard_font_12: {
    fontSize: 12,
  },
  wardDetails_dashboard_font_14: {
    fontSize: 14,
  },
  wardDetails_dashboard_main_grid: {
    border: `1px solid #ccc`,
  },
  //Customizable Area WardDetails.web.tsx End

  //Customizable Area WardAttendance.web.tsx Start
  wardAttendance_dashboard_font_18: {
    fontSize: 18,
  },
  wardAttendance_dashboard_font_14: {
    fontSize: 14,
  },
  wardAttendance_dashboard_main_grid: {
    border: `1px solid #ccc`,
  },
  //Customizable Area WardAttendance.web.tsx End

  //Customizable Area WardResult.web.tsx Start
  wardResult_dashboard_font_18: {
    fontSize: 18,
  },
  wardResult_dashboard_font_14: {
    fontSize: 14,
  },
  wardResult_dashboard_main_grid: {
    border: `1px solid #ccc`,
  },
  //Customizable Area WardResult.web.tsx End

  //Customizable Area UploadClass.web.tsx Start
  uploadClass_classAndSection_border_Color: {
    border: `0.5px solid ${theme?.props?.buttonColor}`,
  },
  uploadClass_classAndSection_file_border_Color: {
    border: `1px solid ${theme?.props?.buttonColor}`,
  },
  //Customizable Area UploadClass.web.tsx End

  //Customizable Area TeacherClassAndSections.web.tsx Start
  teacherClassAndSections_classAndSection_button_color_disabled: {
    color: theme?.props?.buttonColor,
  },
  //Customizable Area TeacherClassAndSections.web.tsx End

  //Customizable Area StudentClassAndSections.web.tsx Start
  studentClassAndSections_classAndSection_button_color_disabled: {
    color: theme?.props?.buttonColor,
  },
  //Customizable Area StudentClassAndSections.web.tsx End

  //Customizable Area FilterSubject.web.tsx,FilterScreen.web.tsx,FilterDivision.web.tsx Start
  filter_classAndSection_border_Color: {
    border: `0.3px solid ${theme?.props?.buttonColor}`,
  },
  //Customizable Area FilterSubject.web.tsx,FilterScreen.web.tsx,FilterDivision.web.tsx End

  //Customizable Area DivisionSelectDiv.web.tsx Start
  divisionSelectDiv_classAndSection_button_color_disabled: {
    color: theme?.props?.buttonColor,
    backgroundColor: 'white',
  },
  //Customizable Area DivisionSelectDiv.web.tsx End

  //Customizable Area CommanSelectDiv.web.tsx Start
  commanSelectDiv_classAndSection_button_color_disabled: {
    color: theme?.props?.buttonColor,
    backgroundColor: 'white',
  },
  //Customizable Area CommanSelectDiv.web.tsx End

  //Customizable Area AdminClassesAndSections.web.tsx Start
  adminClassesAndSections_classAndSection_mt_10: {
    marginTop: '10px',
  },
  adminClassesAndSections_classAndSection_p_0: {
    padding: 0,
  },
  //Customizable Area AdminClassesAndSections.web.tsx End

  //Customizable Area AddClass.web.tsx Start
  addClass_classAndSection_border_Color: {
    border: `0.5px solid ${theme?.props?.buttonColor}`,
  },
  addClass_classAndSection_error_Border_Color: {
    border: '0.5px solid red',
  },
  //Customizable Area AddClass.web.tsx End

  //Customizable Area AssessmentLearning.web.tsx,AssessmentLearning2.web.tsx Start
  assessmentLearning_classAndSection_tableHeadCell: {
    // fontWeight: 600,
    backgroundColor: '#ded9ff',
  },
  assessmentLearning_classAndSection_commonCss: {
    borderRight: '1px solid #D9D9D9',
    borderBottom: '1px solid #D9D9D9',
  },
  assessmentLearning_classAndSection_tableRowCell: {
    color: 'white',
  },
  //Customizable Area AssessmentLearning.web.tsx,AssessmentLearning2.web.tsx End

  //Customizable Area EvaluationFourFive.web.tsx Start
  evaluationFourFive_classAndSection_table_cell: {
    border: '1px solid #D9D9D9',
    backgroundColor: '#F2F2F2',
  },
  evaluationFourFive_classAndSection_border_Cell: {
    border: '1px solid #D9D9D9',
  },
  evaluationFourFive_classAndSection_tableTopCell: {
    border: '1px solid #D9D9D9',
    paddingBottom: '100px',
    backgroundColor: '#F2F2F2',
  },
  //Customizable Area EvaluationFourFive.web.tsx End

  //Customizable Area ReportCardEvaluation.web.tsx Start
  reportCardEvaluation_classAndSection_tabCell: {
    border: '1px solid #D9D9D9',
    backgroundColor: '#F2F2F2',
  },
  reportCardEvaluation_classAndSection_border_Cell: {
    border: '1px solid #D9D9D9',
  },
  reportCardEvaluation_classAndSection_table_cell: {
    border: '1px solid #D9D9D9',
    paddingTop: '125px',
  },
  reportCardEvaluation_classAndSection_border_right: {
    borderRight: '1px solid #D9D9D9',
  },
  reportCardEvaluation_classAndSection_tabRowCell: {
    border: '1px solid #D9D9D9',
    paddingBottom: '100px',
    backgroundColor: '#F2F2F2',
  },
  //Customizable Area ReportCardEvaluation.web.tsx End

  //Customizable Area ReportCardGradingSystem.web.tsx Start
  reportCardGradingSystem_classAndSection_tabBodyCel: {
    borderRadius: '0px',
    border: '1px solid #D9D9D9',
    backgroundColor: '#F2F2F2',
  },
  //Customizable Area ReportCardGradingSystem.web.tsx End

  //Customizable Area ReportCardOneSixTen.web.tsx Start
  reportCardOneSixTen_classAndSection_tabBodyCel: {
    borderRadius: '0px',
    border: '1px solid #D9D9D9',
    backgroundColor: '#F2F2F2',
  },
  reportCardOneSixTen_classAndSection_tabl_Cell: {
    border: '1px solid #D9D9D9',
    backgroundColor: '#D9D9D9',
  },
  reportCardOneSixTen_classAndSection_cell_border: {
    border: '1px solid #D9D9D9',
  },
  reportCardOneSixTen_classAndSection_box_all: {
    border: '1px solid #D9D9D9',
    marginTop: '30px',
    marginLeft: '40px',
    marginBottom: '30px',
    width: '96%',
  },
  reportCardOneSixTen_classAndSection_grid_typography: {
    paddingTop: '90px',
  },
  reportCardOneSixTen_classAndSection_typography_padding: {
    paddingTop: '30px',
  },
  //Customizable Area ReportCardOneSixTen.web.tsx End

  //Customizable Area ReportCardPartOneFourFive.web.tsx Start
  reportCardOneSpartOneFourFive_classAndSection_tabRowCell: {
    border: '1px solid #D9D9D9',
    backgroundColor: '#F2F2F2',
    borderRadius: '0px',
  },
  reportCardOneSpartOneFourFive_classAndSection_tableHeadCell: {
    backgroundColor: '#ded9ff',
  },
  reportCardOneSpartOneFourFive_classAndSection_border_cell: {
    border: '1px solid #D9D9D9',
  },
  reportCardOneSpartOneFourFive_classAndSection_box_grid: {
    borderLeft: '1px solid #D9D9D9',
    borderTop: '1px solid #D9D9D9',
    backgroundColor: '#F2F2F2',
    padding: '18px',
  },
  reportCardOneSpartOneFourFive_classAndSection_tabGrid: {
    borderLeft: '1px solid #D9D9D9',
    padding: '36px',
  },
  reportCardOneSpartOneFourFive_classAndSection_tableBodyCell: {
    border: '1px solid #D9D9D9',
    backgroundColor: '#F2F2F2',
  },
  reportCardOneSpartOneFourFive_classAndSection_tabBoxGrid: {
    borderLeft: '1px solid #D9D9D9',
    borderTop: '1px solid #D9D9D9',
    backgroundColor: '#F2F2F2',
    padding: '20px',
  },
  //Customizable Area ReportCardPartOneFourFive.web.tsx End

  //Customizable Area ReportCardTwoCoScholastic.web.tsx Start
  reportCardTwoCoScholastic_classAndSection_tabBodyCel: {
    borderRadius: '0px',
    border: '1px solid #D9D9D9',
    backgroundColor: '#F2F2F2',
  },
  reportCardTwoCoScholastic_classAndSection_tabHeadCel: {
    backgroundColor: '#ded9ff',
  },
  //Customizable Area ReportCardTwoCoScholastic.web.tsx End

  //Customizable Area ReportCardTwoFourFive.web.tsx Start
  reportCardTwoFourFive_classAndSection_tabBodyCell: {
    border: '1px solid #D9D9D9',
    backgroundColor: '#F2F2F2',
  },
  reportCardTwoFourFive_classAndSection_tableCell_border: {
    border: '1px solid #D9D9D9',
  },
  reportCardTwoFourFive_classAndSection_cellListItem: {
    display: 'list-item',
  },
  //Customizable Area ReportCardTwoCoScholastic.web.tsx End

  //Customizable Area AddStudent.web.tsx,AddTeacher.web.tsx  Start
  addStudentAndTeacher_classAndSection_normal_button_color: {
    color: theme?.props?.buttonColor,
    backgroundColor: 'white',
  },
  //Customizable Area AddStudent.web.tsx,AddTeacher.web.tsx  End

  //Customizable Area StudentEnroll.web.tsx Start
  studentEnroll_classAndSection_dashed_Border: {
    border: `0.3px dashed ${theme?.props?.buttonColor}`,
  },
  //Customizable Area StudentEnroll.web.tsx End

  //Customizable Area TeacherEnroll.web.tsx Start
  teacherEnroll_classAndSection_dashed_Border: {
    border: `0.5px dashed ${theme?.props?.buttonColor}`,
  },
  //Customizable Area StudentEnroll.web.tsx End

  //Customizable Area Assessment.web.tsx Start
  assessment_classAndSection_tableHeadingCell: {
    padding: '17px 5px 17px 20px',
    fontSize: 20,
    borderBottom: 0,
  },
  assessment_classAndSection_tableBodyCell: {
    padding: '17px 5px 17px 20px',
    border: 0,
    fontSize: 18,
  },
  assessment_classAndSection_tableHeaderStyle: {
    backgroundColor: '#f9f8fd',
  },
  assessment_classAndSection_tableContainerStyle: {
    borderRadius: 6,
    border: 0,
  },
  assessment_classAndSection_tableBodyStyle: {
    marginTop: 10,
  },
  assessment_classAndSection_tableBodyRowStyle: {
    backgroundColor: '#ffffff',
    borderRadius: '15%',
  },
  assessment_classAndSection_emptyTableBodyRow: {
    padding: '4px 0px 4px 0px',
    border: 0,
  },
  assessment_classAndSection_searchInputStyle: {
    border: 'none',
    outline: 'none',
    marginLeft: 10,
    opacity: 1,
    fontSize: 18,
    width: '100%',
    paddingRight: 10,
    // fontWeight: 600,
  },
  assessment_classAndSection_searchBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    height: '51px',
    borderRadius: 6,
    border: 'solid 1px #c6c6c6',
  },
  assessment_classAndSection_searchIconStyle: {
    height: 30,
    width: 30,
    cursor: 'pointer',
    marginRight: 5,
  },
  assessment_classAndSection_toTextStyle: {
    marginLeft: 12,
    marginRight: 12,
  },
  assessment_classAndSection_dateGridStyle: {
    maxWidth: '100%',
  },
  assessment_classAndSection_dropdownGridStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  assessment_classAndSection_pb20: {
    paddingBottom: '20px',
  },
  //Customizable Area Assessment.web.tsx End

  //Customizable Area StudentMembers.web.tsx Start
  studentMembers_classAndSection_text_font: {
    color: theme?.props?.textColor,
    fontFamily: theme?.props?.textFont,
  },
  //Customizable Area StudentMembers.web.tsx End

  //Customizable Area StudentSubjects.web.tsx,TeacherSubjects.web.tsx Start
  subjects_classAndSection_normal_button_color: {
    normal_button_color: {
      backgroundColor: 'lightgray',
      color: 'white',
      '&:hover': {
        background: theme?.props?.buttonColor,
      },
    },
  },
  subjects_classAndSection_active_button_color: {
    backgroundColor: theme?.props?.buttonColor,
    color: 'white',
    '&:hover': {
      background: theme?.props?.buttonColor,
    },
  },
  TeacherSubjectSelectRoot: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },

  TeacherSubjectSelectInput: {
    borderRadius: "8px",
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.background.paper,
    padding: "13px 25px",
    width: "140px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: theme.props.textColor,
    fontFamily: theme.props.textFont,
    fontSize: 18,
    lineHeight: 1.2,
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  TeacherSubjectProgressBarRoot: {
    height: 8,
    borderRadius: 3.5,
  },
  TeacherSubjectProgressBarColorPrimary: {
    backgroundColor: "eef0f8",
  },
  TeacherSubjectProgressBarLinearBar: {
    borderRadius: 3.5,
    backgroundColor: theme.palette.primary.main,
  },
  //Customizable Area SStudentSubjects.web.tsx,TeacherSubjects.web.tsx End

  //Customizable Area CreateTask.web.tsx Start
  createTask_classAndSection_dashed_border: {
    border: `1px dashed ${theme?.props?.buttonColor}`,
  },
  createTask_classAndSection_browse_button_color: {
    color: theme?.props?.buttonColor,
    fontFamily: theme?.props?.textFont,
  },
  //Customizable Area CreateTask.web.tsx End

  //Customizable Area DeleteTask.web.tsx Start
  deleteTask_classAndSection_solid_border: {
    border: `0.3px solid ${theme?.props?.buttonColor}`,
  },
  //Customizable Area DeleteTask.web.tsx End

  //Customizable Area StudentTaskUpload.web.tsx Start
  studentTaskUpload_classAndSection_solid_border: {
    border: `0.3px solid ${theme?.props?.buttonColor}`,
  },
  //Customizable Area StudentTaskUpload.web.tsx End

  //Customizable Area TaskUnit.web.tsx Start
  taskUnit_classAndSection_manageBtn_Color: {
    border: `0.3px solid ${theme?.props?.buttonColor}`,
  },
  //Customizable Area TaskUnit.web.tsx End

  //Customizable Area StudentReportCard (Full_Folder) Start
  studentReportCard_classAndSection_tableRowCell: {
    color: 'white',
  },
  studentReportCard_classAndSection_commonCss: {
    border: '1px solid #D9D9D9',
    backgroundColor: '#F2F2F2',
  },
  studentReportCard_classAndSection_background_color: {
    backgroundColor: theme?.props?.buttonColor,
    color: 'white',
    fontFamily: theme?.props?.headingFont,
  },
  //Customizable Area StudentReportCard (Full_Folder) End

  //Customizable Area StudentTimeTable.web.tsx,TeacherTimeTable.web.tsx Start
  timeTable_classAndSection_active_button_color: {
    backgroundColor: theme?.props?.buttonColor,
    color: 'white',
    '&:hover': {
      background: theme?.props?.buttonColor,
    },
  },
  timeTable_classAndSection_active_border_color: {
    border: `1px solid ${theme?.props?.buttonColor}`,
    color: theme?.props?.buttonColor,
    '&:hover': {
      background: theme?.props?.buttonColor,
      color: 'white',
    },
  },
  //Customizable Area StudentTimeTable.web.tsx,TeacherTimeTable.web.tsx End

  //Customizable Area StudentResources.web.tsx,TeacherResources.web.tsx Start
  resources_classAndSection_active_button_color: {
    backgroundColor: theme?.props?.buttonColor,
    color: 'white',
    '&:hover': {
      background: theme?.props?.buttonColor,
    },
  },
  resources_classAndSection_normal_button_color: {
    backgroundColor: 'lightgray',
    color: 'white',
    '&:hover': {
      background: theme?.props?.buttonColor,
    },
  },
  resources_classAndSection_listIcon: {
    [theme?.breakpoints?.only('xs')]: {
      width: 18,
      height: 19,
    },
    [theme?.breakpoints?.only('md')]: {
      width: 20,
      height: 22,
    },
    [theme?.breakpoints?.only('sm')]: {
      width: 19,
      height: 20,
    },
    [theme?.breakpoints?.up('md')]: {
      width: 22,
      height: 24,
    },
  },
  //Customizable Area StudentResources.web.tsx,TeacherResources.web.tsx End

  //Customizable Area TeacherAssessmentCreateTheme.web.tsx Start
  teacherAssessmentCreateTheme_AssessmentTest_outline_button_color: {
    backgroundColor: 'white',
    color: theme?.props?.buttonColor,
    border: `1px solid ${theme?.props?.buttonColor}`,
    '&:hover': {
      backgroundColor: theme?.props?.buttonColor,
      color: 'white',
    },
  },
  //Customizable Area TeacherAssessmentCreateTheme.web.tsx End

  //Customizable Area TeacherAssessmentCreateSection.web.tsx Start
  teacherAssessmentCreateSection_AssessmentTest_outline_button_color: {
    backgroundColor: 'white',
    color: theme?.props?.buttonColor,
    border: `1px solid ${theme?.props?.buttonColor}`,
    '&:hover': {
      backgroundColor: theme?.props?.buttonColor,
      color: 'white',
    },
  },
  teacherAssessmentCreateSection_AssessmentTest_outline_button_no_hover: {
    backgroundColor: 'white',
    color: theme?.props?.buttonColor,
    border: `1px solid ${theme?.props?.buttonColor}`,
  },

  teacherAssessmentCreateSection_AssessmentTest_outline_button_disabled: {
    '& .Mui-disabled': {
      color: `${theme?.props?.buttonColor}`,
    },
  },
  //Customizable Area TeacherAssessmentCreateSection.web.tsx End

  //Customizable Area TeacherAssessmentCreateDetail.web.tsx Start
  teacherAssessmentCreateDetail_AssessmentTest_subHeading_font_imp: {
    color: `${theme?.props?.subheadingColor} !important`,
    fontFamily: theme?.props?.subheadingFont,
  },
  teacherAssessmentCreateDetail_AssessmentTest_time_duration_input: {
    width: '100%',
    '& .MuiInputBase-input': {
      color: theme?.props?.textColor,
      fontFamily: theme?.props?.textFont,
    },
  },
  //Customizable Area TeacherAssessmentCreateDetail.web.tsx End

  //Customizable Area AssessmentSummaryReport.web.tsx Start
  assessmentSummaryReport_AssessmentTest_reports_font: {
    color: theme?.props?.titleColor,
    fontFamily: theme?.props?.titleFont,
    marginLeft: '10px',
  },
  assessmentSummaryReport_AssessmentTest_no_data_text_title: {
    height: '33px',
    marginTop: '44px',
    fontSize: '24px',
    color: '#3f526d',
  },
  assessmentSummaryReport_AssessmentTest_no_data_text: {
    marginTop: '16px',
    opacity: '0.5',
    fontSize: '24px',
    color: '#3f526d',
  },
  assessmentSummaryReport_AssessmentTest_divider: {
    marginTop: '5px',
  },
  assessmentSummaryReport_AssessmentTest_cancel_button: {
    borderColor: '#7f6cfc',
    padding: '12px 49px 12px 50px',
    borderRadius: 8,
    fontSize: 18,
    color: theme?.props?.subtitleColor,
    fontFamily: theme?.props?.subtitleFont,
  },
  assessmentSummaryReport_AssessmentTest_apply_button: {
    backgroundColor: '#7f6cfc',
    padding: '12px 49px 12px 50px',
    borderRadius: 8,
    color: '#fff',
    fontSize: 18,
    fontFamily: 'Open sans',
    marginLeft: '20px',
    '&:hover': {
      backgroundColor: theme?.props?.subtitleColor,
    },
  },
  assessmentSummaryReport_AssessmentTest_no_data_image: {
    width: '601.5px',
    height: '443px',
    margin: '99px 44px',
  },
  //Customizable Area AssessmentSummaryReport.web.tsx End

  //Customizable Area StudentAssessmentSummary.web.tsx Start

  studentAssessmentSummary_AssessmentTest_BoxStyle: {
    width: '100%',
    height: '50px',
    padding: '0px 15px',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    background: '#f9f8fd',
  },
  studentAssessmentSummary_AssessmentTest_AssessmentTitle: {
    fontSize: 16,
    color: theme?.props?.textColor,
    fontFamily: theme?.props?.textFont,
    fontWeight: 600,
  },
  studentAssessmentSummary_AssessmentTest_textFieldNum: {
    width: '100%',
    height: '50px',
    borderRadius: '6px',
    fontSize: 15,
    opacity: 1,
    color: theme?.props?.textColor,
    fontFamily: theme?.props?.textFont,
    backgroundColor: '#fff',
    '& .MuiOutlinedInput-input': {
      padding: '16px 14px',
    },
  },
  //Customizable Area StudentAssessmentSummary.web.tsx End

  //Customizable Area StudentAssessmentAnswer.web.tsx Start
  studentAssessmentAnswer_AssessmentTest_attachFile_Color: {
    border: `0.5px solid ${theme?.props?.buttonColor}`,
    fontFamily: `${theme?.props?.textFont}`
  },
  //Customizable Area StudentAssessmentAnswer.web.tsx End

  //Customizable Area QuestionMetaData.web.tsx Start
  questionMetaData_AssessmentTest_input_theme_style: {
    '& input': {
      color: theme?.props?.textColor,
      fontFamily: theme?.props?.textFont,
    },
  },
  questionMetaData_AssessmentTest_Tags_style: {
    color: 'white',
    fontFamily: theme.props.textFont
  },
  //Customizable Area QuestionMetaData.web.tsx End

  //Customizable Area QuestionStudentView.web.tsx Start
  questionStudenView_AssessmentTest_inActiveFlashCardbtn: {
    border: `1px solid ${theme?.props?.buttonColor}`,
    color: theme?.palette?.primary?.main,
    backgroundColor: '#ffffff',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  questionStudenView_AssessmentTest_activeFlashCardbtn: {
    backgroundColor: theme?.palette?.primary?.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  //Customizable Area QuestionStudentView.web.tsx End

  //Customizable Area StudentUploadAssesment.web.tsx Start
  studentUploadAssesment_AssessmentTest_inActiveFlashCardbtn: {
    border: `1px solid ${theme?.props?.buttonColor}`,
    color: theme?.palette?.primary?.main,
    backgroundColor: '#ffffff',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  studentUploadAssesment_AssessmentTest_AttachFile: {
    fontSize: 22,
    fontFamily: 'Open Sans',
    color: '#3f526d',
  },

  studentUploadAssesment_AssessmentTest_content: {
    marginTop: '10px',
  },

  studentUploadAssesment_AssessmentTest_QuestionNoText: {
    fontSize: 22,
    fontFamily: 'Open Sans',
    color: '#3f526d',
    padding: '0 10px 20px',
  },

  studentUploadAssesment_AssessmentTest_uploadMaterialContent: {
    fontSize: 14,
    fontFamily: 'Open Sans',
    color: '#253873',
    opacity: 0.8,
  },
  studentUploadAssesment_AssessmentTest_ButtonBox: {
    margin: '20px 0px 0px',
  },
  studentUploadAssesment_AssessmentTest_DoneBtn: {
    padding: '8px 28px',
    height: 48,
    fontSize: 18,
    marginRight: 20,
    marginTop: 10,
  },
  studentUploadAssesment_AssessmentTest_UploadBtn: {
    padding: '9px 28px',
    marginTop: 10,
  },
  studentUploadAssesment_AssessmentTest_BottomBtnTextFont: {
    fontFamily: 'Open Sans',
    fontSize: 18,
  },
  //Customizable Area StudentUploadAssesment.web.tsx End

  //Customizable Area AssesmentUpload.web.tsx,ErrorUploadAssesmentModal.web.tsx Start
  assesmentUpload_AssessmentTest_svg_icon_stroke: {
    stroke: theme?.props?.buttonColor,
  },
  assesmentUpload_AssessmentTest_outline_button_text: {
    color: theme?.props?.buttonColor,
    fontFamily: theme?.props?.subtitleFont,
  },
  assesmentUpload_AssessmentTest_downloadCsvBtn: {
    border: `1px solid ${theme?.props?.buttonColor}`,
    borderRadius: 4,
    padding: '6px 5px 0px 5px',
    marginLeft: 20,
  },
  assesmentUpload_AssessmentTest_mt4: {
    marginTop: '4%',
  },
  assesmentUpload_AssessmentTest_mt6: {
    marginTop: '6%',
  },
  assesmentUpload_AssessmentTest_dFlex: {
    display: 'flex',
  },
  assesmentUpload_AssessmentTest_font36: {
    fontSize: 36,
  },
  assesmentUpload_AssessmentTest_area64: {
    height: '64px',
    width: '64px',
  },
  assesmentUpload_AssessmentTest_flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  //Customizable Area AssesmentUpload.web.tsx,ErrorUploadAssesmentModal.web.tsx End

  //Customizable Area AssesmentSummary.web.tsx Start
  assesmentSummary_AssessmentTest_mui_input_style: {
    '& .MuiInputBase-input': {
      color: theme?.props?.textColor,
      fontFamily: theme?.props?.textFont,
      // border: `1px solid ${theme.props.textColor} !important`
    },
  },
  //Customizable Area AssesmentSummary.web.tsx End

  //Customizable Area AudioList.web.tsx Start
  audioList_AssessmentTest_progressBar: {
    '&::before': {
      content: '" "',
      height: '5px',
      width: 'var(--seek-before-width)',
      backgroundColor: theme?.props?.mainColor,
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: '2',
      cursor: 'pointer',
    },
  },
  //Customizable Area AudioList.web.tsx End
  //Customizable Area Logout.tsx Start
  logout_solid_border: {
    border: `0.3px solid ${theme?.props?.buttonColor}`,
  },
  //Customizable Area Logout.tsx End

  //Customizable Area SideBarMenuList.web.tsx Start
  sidebar_menu_list_background_color: {
    backgroundColor: theme?.props?.mainColor,
  },
  sidebar_menu_list_backgroundOpen_sideBar_color: {
    backgroundColor: theme?.props?.mainColor,
  },
  PreviousQuestion_AssessmentQuestionBankLibrary_question_text: {
    fontSize: 16,
  },
  PreviousQuestion_AssessmentQuestionBankLibrary_answer_type_text: {
    fontSize: 16,
  },
  TeacherClassesAndSections_ClassesAndSections_schedule_title: {
    fontSize: 24,
    color: '#000',
    fontFamily: 'Open Sans',
  },
  AssessmentStandardsReport_TeacherReports_graph_height: {
    width: '400px !important',
    height: '400px !important',
  },
  AssessmentAnalysis_TeacherReports_full_width_graph_height: {
    width: '100% !important',
    height: '400px !important',
  },
  //Customizable Area TeacherClassessAndSections.web.tsx End
  //Customizable Area TaskMarkSubmission.web Start
  TaskMarkSubmission_ClassAndSections_button_color_disabled: {
    color: 'white',
    backgroundColor: theme?.props?.buttonColor,
    opacity: 0.25,
    border: `1px solid ${theme?.props?.buttonColor}`,
    '&:hover': {
      background: '#fff',
    },
  },
  //Customizable Area for AdminAssessmentReport Start
  AdminAssessmentReportTableHeadingCell: {
    padding: "17px 5px 17px 20px", fontSize: 20, fontWeight: 600, borderBottom: 0
  },
  AdminAssessmentReportTableBodyCell: {
    padding: "17px 5px 17px 20px", border: 0, fontSize: 18, fontWeight: 600
  },
  AdminAssessmentReportTableHeaderStyle: {
    backgroundColor: "#ded9ff"
  },
  AdminAssessmentReportTableContainerStyle: {
    borderRadius: 6,
    border: 0,
  },
  AdminAssessmentReportTableBodyStyle: {
    marginTop: 10
  },
  AdminAssessmentReportTableBodyRowStyle: {
    backgroundColor: "rgb(249, 248, 253)",
    borderRadius: "15%"
  },
  AdminAssessmentReportEmptyTableBodyRow: {
    padding: "4px 0px 4px 0px",
    border: 0
  },
  AdminAssessmentReportMainHeading: {
    margin: "1rem 0",
    fontSize: "1.25rem",
    fontWeight: 600,
  },
  AdminAssessmentReportMainBox: {
    padding: "22px 20px 26px",
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 8,
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
  },
  AdminAssessmentReportFilterBox: {
    display: "flex",
    marginBottom: 20,
  },
  AdminAssessmentReportFilterSelect: {
    width: 180,
    marginBottom: 20,
    marginRight: 20,
  },
  //Customizable Area for AdminAssessmentReport End

  //Customizable Area TaskMarkSubmission.web End
  //Customizable Area FileName Start
  Pagination_PaginationBttns: {
    width: '100%',
    height: 'auto',
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'flex-end',
    "& a": {
      padding: '13px 20px',
      margin: '5px',
      borderRadius: '8px',
      border: `1px solid ${theme.props.buttonColor}`,
      color: `${theme.props.buttonColor}`,
      fontFamily: `${theme.props.textFont}`,
      cursor: 'pointer',
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    "& a: hover": {
      color: '#fff',
      borderRadius: '6px',
      backgroundColor: `${theme.props.buttonColor}`,
    }
  },
  Pagination_PaginationActive: {
    "& a": {
      color: '#fff',
      backgroundColor: `${theme.props.buttonColor}`,
      borderRadius: '6px'
    }
  },
  Pagination_PaginationDisabled: {
    "& a": {
      fontSize: '14px !important',
      fontFamily: `${theme.props.textFont}`,
      fontWeight: 600,
      opacity: 0.4,
      borderRadius: '8px',
      padding: '13px 5px 13px 5px !important',
      alignItems: 'center',
      cursor: 'not-allowed !important'
    }
  },
  Pagination_PreviousBttn: {
    padding: '13px 10px 13px 10px !important'

  },
  Pagination_NextBttn: {
    color: '#ffffff !important',
    borderRadius: '6px !important',
    backgroundColor: `${theme.props.buttonColor} !important`,
    padding: '13px 10px 13px 10px !important'
  },
  //Customizable Area FileName End
  //Customizable Area SubmitPaperModal.web Start
  SubmitPaperModal_StudentAssessmentTest_ReUploadBtn: {
    marginLeft: 15,
    marginRight: 15,
    background: 'linear-gradient(to top, rgb(175, 56, 203), rgb(71, 28, 81))',
    color: '#fff',
    padding: '18px 48px 18px 48px',
    height: 48,
  },
  SubmitPaperModal_StudentAssessmentTest_BottomBtnTextFont: {
    fontSize: 16,
    fontFamily: 'Open Sans',
    fontWeight: 600,
  },
  //Customizable Area SubmitPaperModal.web End
  //Customizable Area AssessmentSection.web Start
  AssessmentSection_AssessmentTest_container: {
    margin: '20px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  AssessmentSection_AssessmentTest_go_back: {
    fontFamily: 'Open sans',
    fontSize: 20,
    fontWeight: 600,
    color: '#3f526d',
    cursor: 'pointer',
  },
  AssessmentSection_AssessmentTest_back_img: {
    height: 14,
    width: 18,
    marginRight: 10,
  },
  AssessmentSection_AssessmentTest_save_as_draft: {
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'solid 1px #3a60d7',
    padding: '6px 30px 6px 30px',
    margin: '0px 20px 0px 0px',
    color: '#3f526d',
    backgroundColor: '#fff',
    height: 50,
    fontSize: 16,
  },
  AssessmentSection_AssessmentTest_next: {
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'solid 0.5px #3a60d7',
    padding: '6px 30px 6px 30px',
    backgroundColor: '#3a60d7',
    color: '#ffffff',
    height: 50,
    fontSize: 16,
  },
  AssessmentSection_AssessmentTest_inner_container: {
    margin: '20px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  AssessmentSection_AssessmentTest_title: {
    fontFamily: 'Open sans',
    fontSize: 20,
    fontWeight: 600,
    color: '#3f526d',
  },
  AssessmentSection_AssessmentTest_list_container: {
    padding: '0 5px',
    marginTop: 20,
    marginBottom: 30,
  },
  AssessmentSection_AssessmentTest_inner_list_container: {
    padding: '22px 20px 26px',
    borderRadius: 8,
    boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
  },
  AssessmentSection_AssessmentTest_inner_name_container: {
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  AssessmentSection_AssessmentTest_section_name: {
    fontFamily: 'Open sans',
    fontSize: 24,
    fontWeight: 600,
    color: '#3f526d',
  },
  AssessmentSection_AssessmentTest_total_questions_button: {
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'solid 1px #3a60d7',
    padding: '6px 30px 6px 30px',
    margin: '0px 20px 0px 0px',
    color: '#3f526d',
    backgroundColor: '#fff',
    height: 50,
    fontSize: 16,
  },
  ScheduleModal_root_modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ScheduleModal_root_box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    background: '#fff',
    borderRadius: 8,
    padding: '40px 32px',
    width: '750px',
  },
  ScheduleModal_Close_button: {
    height: 48,
    width: 48,
    opacity: 0.2,
  },
  ScheduleModal_Schedule_grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    paddingTop: 0,
  },
  ScheduleModal_Image_grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ScheduleModal_Public_grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ScheduleModal_ContainerGrid: {
    marginBottom: 16,
    padding: '0 10%',
  },
  ScheduleModal_Date_container: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
  },
  ScheduleModal_Time_container: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
  },
  ScheduleModal_Time_title: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 20,
  },
  ScheduleModal_Date_error_container: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    paddingTop: 2,
  },
  ScheduleModal_Submit_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  //Customizable Area AssessmentSection.web End
  //Customizable Area ScheduleModal.web Start

  //Customizable Area ScheduleModal.web End

  //Customizable Area AssessmentLibrary Start
  AssessmentLibraryActiveTabBorderBottom: {
    backgroundColor: theme.props.iconColor
  },
  //Customizable Area AssessmentLibrary End

  //Customizable Area QuestionStudentView.web Start
  QuestionStudentView_AssessmentTest_fillInBlank_Question: {
    fontFamily: 'Open sans',
    fontSize: 15.8,
    color: '#03014c',
    boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
  },
  QuestionStudentView_AssessmentTest_FlashCard_Question: {
    padding: '6px 0px 7px 12px',
    fontSize: 15.8,
  },
  //Customizable Area QuestionStudentView.web End
  //Customizable Area FileName Start

  // Customizable Area QuestionBankAssessmentCreate.web.tsx Start
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_SettingContainer: {
    backgroundColor: '#3a60d7',
    padding: 10,
    color: '#FFF',
    paddingBottom: 5,
    width: 'fit-content',
    marginLeft: 'auto',
    cursor: 'pointer',
    marginTop: 10,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_assetmentDetailsWraper: {
    borderRadius: 6,
    '-webkit-box-shadow': '0 0 15px 0 rgba(0, 0, 0, 0.1)',
    boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
    background: '#fff',
    padding: '22px',
    margin: '20px 0px',
  },

  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_labelText: {
    color: '#3f526d',
    marginBottom: 15,
    marginTop: 20,
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Open sans',
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_error: {
    border: 'solid 0.5px #ff0000',
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_inputText: {
    padding: '14px 16px',
    borderRadius: 5,
    backgroundColor: '#f9f8fd',
    width: '100%',
    border: 'none',
    color: '#3f526d',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Open sans',
    height: 58,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_inputTextDifficultyLevel: {
    padding: '14px 16px',
    borderRadius: 5,
    backgroundColor: '#f9f8fd',
    width: '100%',
    border: 'none',
    color: '#3f526d',
    fontSize: 20,
    fontWeight: 600,
    fontFamily: 'Open sans',
    height: 58,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_disabledTexts: {
    opacity: 0.6,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_inputTextWithBorder: {
    padding: '14px 16px',
    borderRadius: 5,
    width: '100%',
    border: 'solid 0.5px #3f526d',
    marginBottom: 15,
    color: '#3f526d',
    fontSize: 20,
    fontWeight: 600,
    fontFamily: 'Open sans',
    height: 58,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_inputTextWithBorderRed: {
    padding: '14px 16px',
    borderRadius: 5,
    width: '100%',
    border: 'solid 0.5px red',
    marginBottom: 0,
    color: '#3f526d',
    fontSize: 20,
    fontWeight: 600,
    fontFamily: 'Open sans',
    height: 58,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_numberText: {
    padding: '14px 16px',
    borderRadius: 5,
    width: '100%',
    border: 'solid 0.5px #3f526d',
    color: '#3f526d',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Open sans',
    height: 58,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_selectSection: {
    display: 'flex',
    columnGap: 30,
    paddingBottom: 40,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_checkBoxStyle: {
    backgroundColor: '#f9f8fd',
    marginLeft: 0,
    width: '100%',
    marginBottom: 0,
    height: 58,
    paddingLeft: 10,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_checkBoxChecked: {
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.props.buttonColor,
    },
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_questionPaperSection: {
    display: 'flex',
    columnGap: 30,
    paddingBottom: 30,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_sectionHeading: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 30,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_sectionTitle: {
    padding: '10px 15px',
    backgroundColor: '#e5ecff',
    borderRadius: 5,
    color: '#3f526d',
    fontSize: 20,
    fontWeight: 600,
    height: 58,
    display: 'flex',
    alignItems: 'center',
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_sectionInputData: {
    padding: '10px 0px',
    borderRadius: 5,
    fontWeight: 600,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_sectionDeleteIcon: {
    cursor: 'pointer',
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_sectionDeleteIconVisibility: {
    display: 'none',
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_bottomSection: {
    marginTop: 15,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_plusIcon: {
    color: '#fff',
    borderRadius: 5,
    padding: '10px 0px',
    textDecoration: 'none',
    marginRight: 10,
    backgroundColor: '#3a60d7',
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_sectionNextButton: {
    color: '#fff',
    borderRadius: 5,
    textDecoration: 'none',
    padding: '10px 25px',
    backgroundColor: '#3a60d7',
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_backScreen: {
    cursor: 'pointer',
    marginTop: 20,
    marginBottom: 10,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_NewAssessment: {
    fontFamily: 'Open sans',
    fontSize: 20,
    fontWeight: 600,
    color: '#3f526d',
    marginLeft: 10,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_sectionTitleTypography: {
    color: '#3a60d7',
    fontSize: 24,
    fontWeight: 600,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_paddingBottom: {
    paddingBottom: 20,
  },

  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_red: {
    color: 'red',
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_overflowAuto: {
    overflow: 'auto',
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_opacityPointTwo: {
    opacity: 0.2,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_paddingTop15: {
    paddingTop: 15,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_titleWithIcon: {
    width: 22,
    height: 22,
    marginRight: 10,
  },
  QuestionBankAssessmentCreate_AssessmentQuestionBankLibrary_flexColumn: {
    display: 'flex',
  },

  // Customizable Area QuestionBankAssessmentCreate.web.tsx End


  //Customizable Area AssessmentSummary.web.tsx Start
  AssessmentSummary_AssessmentQuestionBankLibrary_CommonText: {
    fontSize: 16,
    textTransform: "none" as const,
    fontWeight: 600,
    fontFamily: theme.props.textFont
  },
  AssessmentSummary_AssessmentQuestionBankLibrary_BoxStyle: {
    width: '100%',
    height: '58px',
    padding: '0px 15px',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    background: "#f9f8fd"
  },
  AssessmentSummary_AssessmentQuestionBankLibrary_AssessmentTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  AssessmentSummary_AssessmentQuestionBankLibrary_TextFieldNum: {
    width: '100%',
    height: '58px',
    borderRadius: "6px",
    fontSize: 16,
    opacity: 0.6,
    border: 0,
    backgroundColor: "#f0f0f0",
    "& .MuiOutlinedInput-input": {
      padding: '16px 14px',
      height: '26px',
      fontSize: '16px',
      fontWeight: 600
    }
  },
  AssessmentSummary_AssessmentQuestionBankLibrary_TextFieldDiffLevel: {
    width: '100%',
    height: '58px',
    borderRadius: "6px",
    fontSize: 16,
    opacity: 0.6,
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-input": {
      padding: '16px 14px',
      height: '26px',
      fontSize: '20px',
      fontWeight: 600
    }
  },
  AssessmentSummary_AssessmentQuestionBankLibrary_InputText: {
    padding: "16px 14px",
    borderRadius: "6px",
    backgroundColor: "#f9f8fd",
    width: "100%",
    height: '58px',
    border: "none",
    fontSize: 16,
    fontWeight: 600,
  },
  AssessmentSummary_AssessmentQuestionBankLibrary_Error: {
    border: "solid 0.5px #ff0000",
  },
  AssessmentSummary_AssessmentQuestionBankLibrary_GroupButton: {
    borderRadius: '6px',
    padding: "16px 14px",
    backgroundColor: "#f9f8fd",
    width: "100%",
    height: '58px',
    border: "none",
    fontSize: 16,
    fontWeight: 600,
    textTransform: "none" as const,
    justifyContent: "space-between",
  },
  //Customizable Area AssessmentSummary.web.tsx End
  //Customizable Area AdminBatchAndPeriod.web.tsx Start
  AdminBatchAndPeriodClassesAndSectionBootstrapRoot: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },

  AdminBatchAndPeriodClassesAndSectionBootstrapInput: {
    borderRadius: 4,
    border: "solid 1px #ebebeb",
    backgroundColor: "#fff",
    padding: "10px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: theme.props.textColor,
    fontFamily: theme.props.textFont,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "21px",
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },

  AddEditPeriodClassesAndSectionBootstrapInput: {
    width: "100%",
    color: theme.props.textColor,
    fontSize: "1.125rem",
    fontFamily: theme.props.textFont,
    opacity: 0.9,
    border: "solid 0.3px rgba(63, 82, 109, 0.5)"
  },
  //Customizable Area AdminBatchAndPeriod.web.tsx End
  //Customizable Area AdminFeedback.web.tsx Start
  AdminFeedbackClassesAndSectionBootstrapRoot: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },

  AdminFeedbackClassesAndSectionBootstrapInput: {
    borderRadius: 4,
    border: "solid 1px #ebebeb",
    backgroundColor: "#fff",
    padding: "10px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: "#3f526d",
    fontFamily: theme.props.textFont,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "21px",
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  //Customizable Area AdminFeedback.web.tsx End
  //Customizable Area TeacherAttendance.web.tsx Start
  TeacherAttendanceClassesAndSectionBootstrapRoot: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  TeacherAttendanceClassesAndSectionBootstrapInput: {
    borderRadius: 4,
    border: "solid 1px #ebebeb",
    backgroundColor: "#fff",
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: theme.props.textColor,
    fontFamily: theme.props.textFont,
    fontSize: '18px',
    lineHeight: 1.2,
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  //Customizable Area TeacherAttendance.web.tsx End

  //Customizable Area OnBoardingSchoolDeatils.web.tsx Start
  OnBoardingSchooldeatils_EmailAccountAccountRegistration_Icon: {
    opacity: "0.7",
  },
  //Customizable Area OnBoardingSchoolDeatils.web.tsx End

  //Customizable Area TeacherAccountRegistration.web.tsx Start
  TeacherAccountRegistration_EmailAccountRegistration_selectDropDown: {
    width: "100%",
    height: "70px",
    borderRadius: "8.8px",
    padding: "18.5px 14px",
    backgroundColor: "#f6f6f6",
    "& .MuiSelect-root": {
      boxSizing: "border-box",
      boxShadow: "none",
    },
    borderWidth: 1,
    borderColor: "#ebebeb",
    boxShadow: "none",
    "& .MuiInputBase-input": {
      fontSize: 16,
      fontFamily: "Open sans",
      outline: "none",
      backgroundColor: "#f6f6f6",
      "&:focus": {
        borderRadius: "8.8px",
        borderColor: "#cfcfcf",
        boxShadow: "none",
        outline: "none",
        backgroundColor: "#f6f6f6",
      },
    },
    "&.Mui-error > .MuiInputBase-input": {
      borderColor: "red !important",
      backgroundColor: "#f6f6f6",
    },
  },
  TeacherAccountRegistration_EmailAccountRegistration_selectErrorDropDown: {
    width: "100%",
    height: "70px",
    borderRadius: "8.8px",
    padding: "18.5px 14px",
    backgroundColor: "#f6f6f6",
    "& .MuiSelect-root": {
      boxSizing: "border-box",
      boxShadow: "none",
    },
    border: '1px solid red',
    boxShadow: "none",
    "& .MuiInputBase-input": {
      fontSize: 16,
      fontFamily: "Open sans",
      outline: "none",
      backgroundColor: "#f6f6f6",
      "&:focus": {
        borderRadius: "8.8px",
        borderColor: "#cfcfcf",
        boxShadow: "none",
        outline: "none",
        backgroundColor: "#f6f6f6",
      },
    },
    "&.Mui-error > .MuiInputBase-input": {
      borderColor: "red !important",
      backgroundColor: "#f6f6f6",
    },
  },
  // Customizable Area TeacherReportCard Start 
  ReportCardDetails_Feedback_reportCardTabs: {
    "& .MuiTabs-indicator": {
        display: "none"
    }
  },
  ReportCardDetails_Feedback_background_color: {
    backgroundColor: theme.props.buttonColor,
    color: "white",
    fontFamily: theme.props.headingFont
  },
  ReportCardDetails_Feedback_tableRowCell: {
    color: "white",
  },
  ScholasticAreas_Feedback_commonCss: {
    borderRight: "1px solid #D9D9D9",
    borderBottom: "1px solid #D9D9D9",
  },
  // Customizable Area TeacherReportCard End  

   // Customizable Area AdminBadges Start
   CreateBadges_Feedback_selectDropDown: {
    width: "100%",
    "& .MuiSelect-root": {
        height: "50px",
        padding: "14px",
        boxSizing: "border-box",
        display: "flex",
        placeItems: "center",
    },
    "& .MuiInputBase-input": {
        backgroundColor: "none",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#ebebeb",
        paddingLeft: 14,
        borderRadius: 8,
    },
    "&.Mui-error > .MuiInputBase-input": {
        borderColor: "red !important",
    },
  },
  CreateBadges_Feedback_textField: {
    "& .MuiOutlinedInput-input": {
        padding: "14px",
        height: "50px",
        boxSizing: "border-box",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#ebebeb",
        },
    },
  },
  AssignBadgesModal_Feedback_button_color: {
    backgroundColor: theme.props.buttonColor,
    color: "white",
    "&:hover": {
      backgroundColor: theme.props.buttonColor,
      color: "white",
    }
  },
  // Customizable Area AdminBadges End

  // Customizable Area CustomReportCard Start
  DeleteGradeModal_Feedback_normal_button_color: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
  },
  TermDetails_Feedback_selectDropDown: {
    width: "100%",
    "& .MuiSelect-root": {
        padding: "14px",
        boxSizing: "border-box",
        display: "flex",
        placeItems: "center",
    },
    "& .MuiInputBase-input": {
        backgroundColor: "none",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#ebebeb",
        paddingLeft: 14,
        borderRadius: 8,
        height: 52,
    },
    "&.Mui-error > .MuiInputBase-input": {
        borderColor: "red !important",
    },
  },
  TermDetails_Feedback_checkbox_color: theme.props.buttonColor,
  TermDetails_Feedback_term_checkbox: {
      "&.MuiCheckbox-colorSecondary.Mui-checked": {
          color: theme.props.buttonColor,
      },
  },
  GradingScale_Feedback_btn_border: {
    border: `1px solid ${theme.props.buttonColor}`,
    color: theme.props.buttonColor,
  },
  Modules_Feedback_subheadingText: {
    fontSize: 26,
    fontWeight: 500
  },
  Modules_Feedback_term_checkbox: {
      "&.MuiCheckbox-colorSecondary.Mui-checked": {
          color: theme.props.buttonColor,
      },
  },
  Modules_Feedback_selectDropDown: {
    width: "100%",
    "& .MuiSelect-root": {
        padding: "14px",
        boxSizing: "border-box",
        display: "flex",
        placeItems: "center",
    },
    "& .MuiInputBase-input": {
        backgroundColor: "none",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#ebebeb",
        paddingLeft: 14,
        borderRadius: 8,
        height: 52,
    },
    "&.Mui-error > .MuiInputBase-input": {
        borderColor: "red !important",
    },
  },
  AddCustomReportCard_Feedback_backScreen: {
    cursor: "pointer",
    marginTop: 20,
    marginBottom: 10,
  },
  AddCustomReportCard_Feedback_icon_button_color: {
    color: theme.props.buttonColor
  },
  AddCustomReportCard_Feedback_backIconStyle: {
    fontSize: 20,
    fontWeight: 600,
    marginLeft: 10,
  },
  AddCustomReportCard_Feedback_titleGrid: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center'
  },
  AddCustomReportCard_Feedback_titleText: {
      fontSize: 24,
      fontWeight: 600
  },
  AddCustomReportCard_Feedback_contentGrid: {
    padding: "22px 20px 26px",
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 8,
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
  },
  AddCustomReportCard_Feedback_selectDropDown: {
    width: "100%",
    "& .MuiSelect-root": {
        padding: "0 14px",
        boxSizing: "border-box",
        display: "flex",
        placeItems: "center",
    },
    "& .MuiInputBase-input": {
        backgroundColor: "none",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#ebebeb",
        paddingLeft: 14,
        borderRadius: 8,
        height: 50,
        textOverflow: 'ellipsis'
    },
    "&.Mui-error > .MuiInputBase-input": {
        borderColor: "red !important",
    },
  },
  AddCustomReportCard_Feedback_lowOpacitySelect: {
    "& .MuiTypography-displayBlock": {
        opacity: 0.4
    }
  },
  AddCustomReportCard_Feedback_lowOpacityMultiSelect: {
    "& .MuiOutlinedInput-input": {
        opacity: 0.4
    }
  },
  AddCustomReportCard_Feedback_selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },
  AddCustomReportCard_Feedback_checkboxChecked: {
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.props.buttonColor,
    },
  },
  AddCustomReportCard_Feedback_subheadingText: {
    fontSize: 26,
    fontWeight: 500
  },
  AddCustomReportCard_Feedback_classGrid: {
    display: 'flex',
    justifyContent: 'end',
    marginTop:'10px'
  },
  AddCustomReportCard_Feedback_nextButton: {
    cursor: "pointer",
    borderRadius: "6px",
    padding: "11px 20px 11px 21px",
    margin: "0px 10px 0px 0px",
    fontSize:'20px',
    height: '47px',
  },
  AddCustomReportCard_Feedback_btn_border: {
    border: `1px solid ${theme.props.buttonColor}`,
    color: theme.props.buttonColor,
  },
  // Customizable Area CustomReportCard End
  
  //Customizable Area TeacherAccountRegistration.web.tsx End
  //Customizable Area Gradebook>Student>Task.web.tsx Start
  GradebookStudenttableHeadingCell: {
    padding: "17px 5px 17px 20px", fontSize: 20, fontWeight: 600, borderBottom: 0
  },
  GradebookStudenttableBodyCell: {
    padding: "17px 5px 17px 20px", border: 0, fontSize: 18, fontWeight: 600
  },
  GradebookStudenttableHeaderStyle: {
    backgroundColor: "#f9f8fd"
  },
  GradebookStudenttableContainerStyle: {
    borderRadius: 6,
    border: 0,
  },
  GradebookStudenttableBodyStyle: {
    marginTop: 10
  },
  GradebookStudenttableBodyRowStyle: {
    backgroundColor: "#ffffff",
    borderRadius: "15%"
  },
  GradebookStudentemptyTableBodyRow: {
    padding: "4px 0px 4px 0px",
    border: 0
  },
  GradebookStudentmainHeading: {
    margin: "1rem 0",
    fontSize: "1.25rem",
    fontWeight: 600,
  },
  GradebookStudentmainBox: {
    padding: "22px 20px 26px",
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 8,
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
  },
  GradebookStudentfilterBox: {
    display: "flex",
    marginBottom: 20,
  },
  GradebookStudentfilterSelect: {
    width: 180,
    marginBottom: 20,
    marginRight: 20,
  },
  GradebookStudentsearchInputStyle: {
    border: "none",
    outline: "none",
    marginLeft: 10,
    opacity: 1,
    fontSize: 18,
    width: "100%",
    paddingRight: 10,
    fontWeight: 600,
  },
  GradebookStudentsearchBoxStyle: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    height: "51px",
    borderRadius: 6,
    border: "solid 1px #c6c6c6"
  },
  GradebookStudentsearchIconStyle: {
    height: 30,
    width: 30,
    cursor: "pointer",
    marginRight: 5,
  },
  GradebookStudentdropdownStyle: {
    display: "flex",
    alignItems: "center",
  },
  GradebookStudenttoTextStyle: {
    marginLeft: 12,
    marginRight: 12,
  },
  GradebookStudentdateGridStyle: {
    maxWidth: "100%"
  },
  GradebookStudentselectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },
  GradebookStudentindeterminateColor: {
    color: "#f50057"
  },
  //Customizable Area Gradebook>Student>Task.web.tsx End
  //If you have to add customize style then strictly follow this naming convention ===>(fileName_blockName_cssName)

  //Customizable Area FileName End
});
// Customizable Area End