// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { matchGroupSVG, nounCheckSVG, nounCloseSVG } from "../assets";
import { ImagePreivewModal } from "../Components/ImagePreivewModal.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
interface Props {
  // Customizable Area Start
  questionData: any;
  isTeacher: boolean;
  classes: any;
  // Customizable Area End
}

// Customizable Area Start
export const MatchQuestionType = ({
  questionData,
  isTeacher,
  classes,
}: Props) => {
  const [questionList, setQuestionList] = useState<any[]>([]);
  const [previewImageModal, setPreviewImageModal] = useState<boolean>(false)
  const [previewImageModalSrc, setPreviewImageModalSrc] = useState<string>("")

  useEffect(() => {
    const questionAnswerPair = questionData?.answers;
    const matchListItems = questionData?.match_list_items;
    const attemptedItems = questionData?.selected_options?.data?.attributes?.match_list_answer;
    let tempQuesList: any = [];
    let tempResponseList: any = [];
    questionAnswerPair?.map((item: any) => {
      const matchedPair = item?.match_list_answer;
      matchedPair?.map((data: any) => {
        tempQuesList.push({
          questionId: data.match_list_id,
          correctAnswerId: data.match_response_id,
        });
      });
    });

    matchListItems?.map((item: any) => {
      const index = tempQuesList.findIndex(
        (res: any) => res.questionId === item.uniq_id
      );
      if (index !== -1) {
        const data = tempQuesList[index];
        const attemptedItem = attemptedItems?.find((a: any) => a.match_list_id === data.questionId)
        tempQuesList[index] = {
          ...data,
          id: item.id,
          _destroy: false,
          question: item.list_name,
          src: item?.attachments?.data?.[0]?.attributes?.url
            ? item?.attachments?.data?.[0]?.attributes?.url
            : "",
          isImageAvailble: item?.attachments?.data?.[0]?.attributes
            ?.url
            ? true
            : false,
          name: item?.attachments?.data?.[0]?.attributes?.file_name
            ? item?.attachments?.data?.[0]?.attributes?.file_name
            : "",
          upload_id: item?.attachments?.data?.[0]?.id,
          attemptedAnswerId: attemptedItem?.match_response_id,
        };
      }
      const indexAns = tempQuesList.findIndex(
        (res: any) => res.correctAnswerId === item.uniq_id
      );
      if (indexAns !== -1) {
        const data = tempQuesList[indexAns];
        tempQuesList[indexAns] = {
          ...data,
          answer: item.list_name,
          answerSrc: item?.attachments?.data?.[0]?.attributes?.url
            ? item?.attachments?.data?.[0]?.attributes?.url
            : "",
        };
      }
      if (item.match_type === "match_response") {
        tempResponseList?.push({
          id: item.id,
          _destroy: false,
          responseId: item.uniq_id,
          response: item.list_name,
          src: item.attachments?.data?.[0]?.attributes?.url
            ? item.attachments?.data?.[0]?.attributes?.url
            : "",
          isImageAvailble: item?.attachments?.data?.[0]?.attributes
            ?.url
            ? true
            : false,
          name: item?.attachments?.data?.[0]?.attributes?.file_name
            ? item?.attachments?.data?.[0]?.attributes?.file_name
            : "",
          upload_id: item?.attachments?.data?.[0]?.id,
        });
      }
    });
    tempQuesList?.map((tempQue: any) => {
      tempResponseList.map((val: any) => {
        if (val.responseId === tempQue.attemptedAnswerId) {
          tempQue.attemptedAnswer = val.response
          tempQue.attemptedAnswerSrc = val?.src
        }
        return val;
      })
      return tempQue;
    });

    setQuestionList(tempQuesList);
  }, [])

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className="match-main-div">
          {questionList
            ?.filter((item: any) => !item._destroy)
            ?.map((data: any, index: number) => (
              <React.Fragment key={index}>
                {(data?.question || data?.src) && (
                  <Grid container className="match-item-container">
                    <Grid item xs={5}>
                      <div className="match-question">
                        {!data?.src ? (
                          <Typography className={`${classes.heading_font} match-question-text`}>
                            {data?.question}
                          </Typography>
                        ) : (
                          <>
                            <img
                              src={data.src}
                              className="match-answer-image"
                              onClick={() => {
                                setPreviewImageModalSrc(data.src);
                                setPreviewImageModal(true);
                              }}
                            />
                          </>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      className="match-img-div"
                    >
                      <img src={matchGroupSVG} className="match_grp_img" />
                    </Grid>
                    <Grid item xs={5}>
                      <div
                        className={data.attemptedAnswerId === data.correctAnswerId ? "match-correct-answer" : "match-wrong-answer"}
                        id={`answer-${data.questionId}`}
                      >
                        {!data?.attemptedAnswerSrc ? (
                          <>
                            <Typography className={`${classes.heading_font} match-question-text`}
                              id={`answer-${data.questionId}`}>
                              {data?.attemptedAnswer}
                            </Typography>
                            {data.attemptedAnswerId === data.correctAnswerId ?
                              <img src={nounCheckSVG} alt="check" className="right-check-icon absolute-icon" />
                              :
                              <img src={nounCloseSVG} alt="close" className="close-red-icon absolute-icon" />}
                          </>

                        ) : (
                          <>
                            <img
                              id={`answer-${data.questionId}`}
                              src={data.attemptedAnswerSrc}
                              className="match-answer-image"
                              onClick={() => {
                                setPreviewImageModalSrc(data.attemptedAnswerSrc);
                                setPreviewImageModal(true);
                              }}
                            />
                            {data.attemptedAnswerId === data.correctAnswerId ?
                              <img src={nounCheckSVG} alt="check" className="right-check-icon absolute-icon" />
                              :
                              <img src={nounCloseSVG} alt="close" className="close-red-icon absolute-icon" />}
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            ))}
        </div>
      </Grid>
      <div className="correct_answer_section">
        <Grid container>
          <Grid item xs={12} sm={6} className="correct_answer_left">
            {!questionData?.selected_options?.data &&
              <Typography className={`not_attemped_text ${classes.bodyText_font}`}>Not attempted</Typography>
            }
            <Typography className={`correct_answer_text ${classes.heading_font}`}>Correct Answer</Typography>
          </Grid>
          <Grid item xs={12} sm={6} className="score_section">
            <Typography className={`correct_answer_text ${classes.heading_font}`}>Score</Typography>
            <div className="correct_answer_rectangle">
              <Typography className="score_text">
                {questionData?.selected_options?.data?.attributes?.score ? questionData?.selected_options?.data?.attributes?.score : 0}
              </Typography>
            </div>
            <Typography className="score_text"> / {questionData?.marks}</Typography>
          </Grid>
          <Grid item xs={12}>
            <div className="sorting-correct-block">
              <div className="match-main-div">
                {questionList
                  ?.filter((item: any) => !item._destroy)
                  ?.map((data: any, index: number) => (
                    <React.Fragment key={index}>
                      {(data?.question || data?.src) && (
                        <Grid container className="match-item-container">
                          <Grid item xs={5}>
                            <div className="match-question">
                              {!data?.src ? (
                                <Typography className={`${classes.heading_font} match-question-text`}>
                                  {data?.question}
                                </Typography>
                              ) : (
                                <>
                                  <img
                                    src={data.src}
                                    className="match-answer-image"
                                    onClick={() => {
                                      setPreviewImageModalSrc(data.src);
                                      setPreviewImageModal(true);
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            className="match-img-div"
                          >
                            <img src={matchGroupSVG} className="match_grp_img" />
                          </Grid>
                          <Grid item xs={5}>
                            <div className="match-teacher-answer"
                              id={`answer-${data.questionId}`}
                            >
                              {!data?.answerSrc ? (
                                <Typography className={`${classes.heading_font} match-question-text`}
                                  id={`answer-${data.questionId}`}>
                                  {data?.answer}
                                </Typography>
                              ) : (
                                <>
                                  <img
                                    id={`answer-${data.questionId}`}
                                    src={data.answerSrc}
                                    className="match-answer-image"
                                    onClick={() => {
                                      setPreviewImageModalSrc(data.answerSrc);
                                      setPreviewImageModal(true);
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </Grid>
          {(!isTeacher && questionData?.selected_options?.data?.attributes?.comments) &&
            <Grid item xs={12}>
              <Typography className={`title_teachercomment ${classes.bodyText_font}`}>Teacher Comment:
                <span className={`textlabel_teachercomment ${classes.heading_font}`} dangerouslySetInnerHTML={{
                  __html: questionData?.selected_options?.data?.attributes?.comments,
                }}></span></Typography>
            </Grid>
          }
        </Grid>
      </div>

      <ImagePreivewModal
        previewImageModal={previewImageModal}
        modalhandleClose={() => setPreviewImageModal(false)}
        previewImage={previewImageModalSrc}
      />
    </Grid>
  );
};
// Customizable Area End

// Customizable Area Start

export default withStyles(themeCreateStyle)(MatchQuestionType);
// Customizable Area End
