// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const BadgesIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M16 14.77a4.516 4.516 0 0 1 4.513 4.483v8.235a1.232 1.232 0 0 1-1.781 1.1L16 27.224l-2.733 1.366a1.229 1.229 0 0 1-1.78-1.101v-8.206A4.515 4.515 0 0 1 16 14.77zm2.051 8.533a4.488 4.488 0 0 1-2.051.492 4.488 4.488 0 0 1-2.052-.492v2.192l1.502-.75a1.234 1.234 0 0 1 1.1 0l1.501.75v-2.192zM30.769 0C31.45 0 32 .551 32 1.23v22.975c0 .68-.551 1.231-1.23 1.231h-6.565a1.231 1.231 0 0 1 0-2.461h5.333V2.462H2.462v20.513h5.333a1.231 1.231 0 0 1 0 2.461H1.23c-.68 0-1.231-.551-1.231-1.23V1.23C0 .55.551 0 1.23 0h29.54zM16 17.231a2.052 2.052 0 1 0 .002 4.104A2.052 2.052 0 0 0 16 17.231zm3.283-7.384a1.231 1.231 0 0 1 0 2.461h-6.564a1.231 1.231 0 0 1 0-2.461zm3.283-4.923a1.231 1.231 0 0 1 0 2.461H9.436a1.231 1.231 0 0 1 0-2.461z" fill={`${props.fillColor}`} fill-rule="nonzero"/>
        </SvgIcon>
    );
}

export default BadgesIcon;
// Customizable Area End