// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";;
import * as SparkMD5 from "spark-md5";
import { toast } from "react-toastify";
import axios from "axios";
// import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

export const configJSON = require("./config");

import moment from "moment";
import { boolean } from "yup";
import { FieldArray } from "formik";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: any;
    type?: any;
    RemoveModal: boolean;
    closeModalFun: any;
    thoughtId?: any;
    createThought?: any
    classes: any;
    createThoughtDelete1?:any ;
    eventId?: any;
    createEventDelete1?:any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    closeModal: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}


export default class RemoveModalController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apideleteGoalId: string = '';
    apideleteEventId: string = '';
    // Customizable Area End


    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            closeModal: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }
    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.closeModalFun(!this.state.closeModal)
    }
    deletePost = () => {
        const { type, thoughtId, eventId } = this.props
        if(type === 'deleteEvent'){
            const school_data = localStorage.getItem("user_data");
            const data = JSON.parse(school_data || "{}");
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                "token": localStorage.getItem('token'),
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.apideleteEventId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.eventsEndPoint + `/${eventId}?school_id=${data.school_id}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestbaseURLMessage),
                configJSON.instituteURL
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.deleteAPIMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }else{
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('token'),
            "school": data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apideleteGoalId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            // type === 'post' ?
            configJSON.setThoughtToInactiveEndpoint + `${thoughtId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteAPIMethod
        );
        //console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }


    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));


            if (apiRequestCallId === this.apideleteGoalId) {
                //console.log("ID Match")
                if (responseJson != null) {
                    //console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        //console.log(responseJson)
                        toast.success("Thought deleted successfully.")
                        this.props.closeModalFun(!this.state.closeModal)
                        // this.props.createThought()
                        this.props.createThoughtDelete1(responseJson)
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                //console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }
            if (apiRequestCallId === this.apideleteEventId) {
                //console.log("ID Match")
                if (responseJson != null) {
                    //console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        //console.log(responseJson)
                        toast.success("Deleted successfully.")
                        this.props.closeModalFun(!this.state.closeModal)
                        // this.props.createThought()
                        this.props.createEventDelete1(responseJson)
                    }
                    else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                //console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
    }
    // Customizable Area End
}
