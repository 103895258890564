// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TeacherViewModalController, { Props } from "./TeacherViewModalController.web";
import "./TeacherViewModal.web.css";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Grid from '@material-ui/core/Grid';
import moment from "moment";
import { Avatar, Box, Link, Typography, Divider } from "@material-ui/core";
import { file_download, docICon, xlsIcon} from './assets';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class TeacherViewModal extends TeacherViewModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    
    // Customizable Area Start
    render() {
        const submissionCount = this.props.task.attributes.task_members.data.filter((res: any) => res.attributes.status === 'submitted')
        return (
            <>
                <Modal className="teacherViewModal"
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.props.viewModal}
                    onClose={this.modalhandleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableAutoFocus={true}
                >
                    <Fade in={this.props.viewModal} style={{ border: "none" }}>
                        <div className="addTaskPopup">
                            <Grid className="taskDetailPopup" item xs={12}>
                                <Grid className="taskTitle" item xs={12}>
                                    Task Details
                                </Grid>
                                <Divider className="dividerSection" />
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item className="teacherTaskAttributsGrid" xs={12}>
                                            <Typography className="subjTitle">
                                                {this.props.task?.attributes.name}
                                            </Typography>
                                            <Typography className="dayList">
                                                {moment(this.props.task?.attributes.due_date).format("dddd")} at {moment(this.props.task?.attributes.due_date).format("hh:mm A")}
                                            </Typography>
                                        </Grid>
                                        <Grid className="subjectCenter" item xs={12}>
                                            <Typography className="subjectName">
                                                Subject Name:
                                            </Typography>
                                            <Typography className="subjectNameAttributs">
                                                {this.props.task?.attributes.subject.data.attributes.subject_name}
                                            </Typography>
                                        </Grid>
                                        {this.props.task?.attributes.description != '' &&
                                            <>
                                                <Grid className="mt15" item xs={12}>
                                                    <Typography className="taskDetails">
                                                        Task Details:
                                                    </Typography>
                                                </Grid>
                                                <Grid className="descriptionGrid" item xs={12}>
                                                    <Typography className="descriptionText">
                                                        {this.props.task?.attributes.description}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }
                                        <Grid item xs={12} className="mt25">
                                            <Grid className="gradeMainGrid" container>
                                                <Grid item xs={6} className="gradeGrid" >
                                                    <Typography className="subjectName">
                                                        Grade:
                                                    </Typography>
                                                    <Typography className="subjectNameAttributs">
                                                        {this.props.task?.attributes.grade.data.attributes.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} className="gradeGrid">
                                                    <Typography className="subjectName">
                                                        Assignee:
                                                    </Typography>
                                                    {this.props.task?.attributes?.creator?.attributes?.avatar ? <Avatar className="createAvtar" src={this.props.task?.attributes?.creator?.attributes?.avatar} /> : this.props.task.attributes.creator?.attributes?.first_name ? <Avatar className="createAvtar" /> : ''}
                                                    <Typography className="ml12" component={'span'}>
                                                        <Typography className="flName" component={'p'}>
                                                            {this.props.task.attributes.creator?.attributes?.first_name} {this.props.task.attributes.creator?.attributes?.last_name}
                                                        </Typography>
                                                        <Typography className="emailAttributes">
                                                            {this.props.task.attributes.creator?.attributes?.email}
                                                        </Typography>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid className="mt25" item xs={12}>
                                            <Grid container className="centerFText">
                                                <Grid item xs={6} className="centerFText">
                                                    <Typography className="subjectName">
                                                        Submission :
                                                    </Typography>
                                                    <Box className="pendingBtn">
                                                        <Typography className="memberLength">
                                                            {submissionCount.length}/{this.props.task.attributes.student_members.length}
                                                        </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={6} className="gradeGrid">
                                                    <Typography className="taskDetails">
                                                        Due Date:
                                                    </Typography>
                                                    <Typography className="subjectNameAttributs">
                                                        {moment(this.props.task?.attributes.due_date).format("DD MMM YYYY")}
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {this.props.task?.attributes.file.length != 0 &&
                                            <Grid className="fileGrid" item xs={12}>
                                                <Typography className="fileText">
                                                    File:
                                                </Typography>
                                                <Box>
                                                    {this.props.task?.attributes.file.map((fileData: any) => (
                                                        <Box className="gradeGrid">
                                                            {
                                                                (fileData?.attributes?.content_type === "image/jpeg" || fileData?.attributes?.content_type === "image/png" || fileData?.attributes?.content_type === "image/gif")
                                                                    ?
                                                                    <img className="urlImg" src={fileData?.attributes?.url} />
                                                                    :
                                                                    (
                                                                        (
                                                                            fileData?.attributes?.content_type === "application/msword" ||
                                                                            fileData?.attributes?.content_type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                        )
                                                                            ?
                                                                            (<img className="urlImg" src={docICon} />)
                                                                            :
                                                                            (
                                                                                (
                                                                                    fileData?.attributes?.content_type === "application/vnd.ms-excel" ||
                                                                                    fileData?.attributes?.content_type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                                                )
                                                                                    ?
                                                                                    <img src={xlsIcon} className="urlImg" />
                                                                                    : ''
                                                                            )
                                                                    )
                                                            }
                                                            <Typography className="fileDataName">
                                                                {fileData?.attributes?.file_name}
                                                                <br />
                                                                <span className="fileSize">
                                                                    {
                                                                        fileData?.attributes?.file_size > 1048576
                                                                            ?
                                                                            `${(fileData?.attributes?.file_size / 1048576).toFixed(2)} MB`
                                                                            :
                                                                            `${(fileData?.attributes?.file_size / 1024).toFixed(2)} KB`
                                                                    }
                                                                    {" "}
                                                                    {
                                                                        (
                                                                            fileData?.attributes?.content_type === "image/jpeg" || fileData?.attributes?.content_type === "image/png" || fileData?.attributes?.content_type === "image/gif"
                                                                        )
                                                                            ?
                                                                            "Image"
                                                                            : (
                                                                                (
                                                                                    fileData?.attributes?.content_type === "application/msword" ||
                                                                                    fileData?.attributes?.content_type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                )
                                                                                    ?
                                                                                    "Word Document"
                                                                                    :
                                                                                    (
                                                                                        (
                                                                                            fileData?.attributes?.content_type === "application/vnd.ms-excel" ||
                                                                                            fileData?.attributes?.content_type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                                                        )
                                                                                            ?
                                                                                            "Excel Document"
                                                                                            :
                                                                                            ''
                                                                                    )
                                                                            )
                                                                    }
                                                                </span>
                                                            </Typography>
                                                            <Link className="link" onClick={(event: any) => event.stopPropagation()} href={fileData?.attributes?.url} target="_blank" download={fileData?.attributes?.file_name} >
                                                                <img src={file_download} className="fileIcon" />
                                                            </Link>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Fade>
                </Modal>
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(TeacherViewModal);
// Customizable Area End