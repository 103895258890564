// Customizable Area Start
import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import './common.css';
// Customizable Area End
interface Props {
  // Customizable Area Start
  previewImageModal: boolean;
  modalhandleClose: any;
  // Customizable Area End
}
// Customizable Area Start
export const ImagePreivewModal = (props: any) => {
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={props.previewImageModal}
        onClose={props.modalhandleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableAutoFocus={true}
      >
        <Fade in={props.previewImageModal} style={{ border: 'none' }}>
          <div className="fade_parent">
            <div className="flex_end">
              <CancelRoundedIcon
                className="cancel_rounded_icon"
                onClick={props.modalhandleClose}
              />
            </div>
            <img src={props.previewImage} className="fade_image" />
          </div>
        </Fade>
      </Modal>
    </>
  );
};
// Customizable Area End
