// Customizable Area Start
import React from "react"
import {
    Box,
    Button,
    withStyles,
    Typography,
    Modal,
    Backdrop,
    Grid,
    IconButton,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddGroupModalController, { Props } from "./AddGroupModalController.web";
import './AddGroupModal.web.css';
import { Search } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { themeCreateStyle } from '../../../../components/src/CustomThemeStyles.web';
// Customizable Area End
export class AddGroupModal extends AddGroupModalController {
    // Customizable Area Start

    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { classes } = this.props
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.props.openModal}
                    onClose={() => {
                        this.props?.handleOnClose(false)
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    data-test-id="add-group-modal"
                    className="MainModal"
                    id="add-group-modal"
                >
                    <Grid container spacing={0} className={"MainGrid"}>
                        {/* Close Btn */}
                        <div className="CloseIconDiv">
                            <IconButton onClick={() => this.props.handleOnClose(false)} className="closeIcon" data-test-id="group-close-icon">
                                <CloseIcon />
                            </IconButton>
                        </div>
                        {/* Close Btn END */}
                        <Grid item xs={12}
                            className={"ParentGrid"}
                        >
                            <Grid container spacing={4} className="MV4">
                                {/* Search Box */}
                                <Grid item xs={12} 
                                    className="SearchGrid"
                                >
                                    <Typography
                                        component={"h2"}
                                        className={`TitleText ${classes.title_font}`}
                                        >
                                        Add Students
                                    </Typography>
                                </Grid>
                                {/* Search Box END */}

                                {/* Search Box */}
                                <Grid xs={12}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        className={`BoxStyle`}
                                    >

                                        <Search
                                            className={`${classes.icon_color} SearchIcon`}
                                        />
                                        <input
                                            placeholder="Search for a participants"
                                            className={`SearchInput ${classes.bodyText_font}`}
                                            value={this.state.searchText}
                                            data-test-id="group-search-field"
                                            onChange={((event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.filterStudents(event);
                                            })}
                                        />
                                    </Box>
                                </Grid>
                                {/* Search Box END */}
                            </Grid>

                            {/* Student List by division */}
                            <Grid container spacing={4} 
                                className="ListContainer"    
                            >
                                {
                                    this.state.searchText === '' ?
                                        Object.keys(this.props.studentList).map((item: any) => {
                                            return (
                                                <>
                                                    {/* Class Division title */}
                                                    <Grid xs={12}
                                                        className="MV8"
                                                    >
                                                        <Accordion className={`AccordianHeader`}>
                                                            <AccordionSummary
                                                                id={"accordian_" + item}
                                                                className={`SectionHeader RowReverse`}
                                                                expandIcon={<ExpandMoreIcon className={classes.subTitle_font}/>}
                                                            >
                                                                <Typography component={"h4"} className={classes.subTitle_font}>
                                                                    {item}
                                                                </Typography>
                                                                <FormControlLabel
                                                                    aria-label="Select All"
                                                                    data-test-id="form-control-group"
                                                                    onClick={(event) => event.stopPropagation()}
                                                                    onFocus={(event) => event.stopPropagation()}
                                                                    control={
                                                                        <Checkbox
                                                                            checked={
                                                                                this.allChecked(this.props.studentList[item].map((item: any) => item.student_id)) ? true : false
                                                                            }
                                                                            data-test-id="select-all-student-checkbox"
                                                                            onChange={(event: any) => this.handleSelectAll(this.props.studentList[item])} />
                                                                    }
                                                                    label={"Select All"}
                                                                    labelPlacement='start'
                                                                    className={`SelectAll ${classes.subTitle_font}`}

                                                                />
                                                            </AccordionSummary>

                                                            {/* Class Division title END */}
                                                            <AccordionDetails
                                                                className="AccordionDetail"
                                                            >
                                                                {
                                                                    this.props.studentList[item].map((student: any) => {
                                                                        return (
                                                                            <Grid key={student.student_id} item xs={12}>
                                                                                {/* Student List */}
                                                                                <Grid container spacing={4} className={`ListGrid`}>
                                                                                    <Grid item xs={2}>
                                                                                        <img
                                                                                            src={student?.avatar}
                                                                                            className="PersonImage"
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={8}>
                                                                                        <Typography component={'h3'}>
                                                                                            {student?.first_name + ' ' + student?.last_name}
                                                                                        </Typography>
                                                                                        <Typography component={'p'}>
                                                                                            {student?.email}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid className="justifyStart" item xs={2}>
                                                                                        <label
                                                                                            htmlFor={"checkbox_" + student.student_id}
                                                                                            className="checkboxSpan"
                                                                                        >
                                                                                            <Checkbox
                                                                                                id={"checkbox_" + student.student_id}
                                                                                                data-test-id={"student_checkbox_" + student.student_id}
                                                                                                onChange={() =>
                                                                                                    this.props.updateList(student.student_id)
                                                                                                }
                                                                                                checked={this.props.checkedStudents.indexOf(student.student_id) === -1 ? false : true}
                                                                                                className="checkbox ML0 PL0"
                                                                                                inputProps={{
                                                                                                    "aria-labelledby":
                                                                                                        "checkbox-list-secondary-label-question",
                                                                                                }}
                                                                                            />
                                                                                        </label>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                {/* Student List END */}
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Grid>
                                                </>
                                            )
                                        }) :
                                        Object.keys(this.state.tempStudentList).map((item: any) => {
                                            return (
                                                <>
                                                    <Grid xs={12}
                                                        className={`MV8`}
                                                    >
                                                        {/* Class Division title */}
                                                        <Box
                                                            display="flex"
                                                            alignItems="center"
                                                            className={`SectionHeader MinHeight64`}
                                                        >
                                                            <Typography component={"h4"} 
                                                            className={`MV20 ${classes.subTitle_font}`}>
                                                                {item}
                                                            </Typography>
                                                        </Box>
                                                        {/* Class Division title END */}
                                                        <Box
                                                            className="SearchListBox"
                                                        >
                                                            {
                                                                this.state.tempStudentList[item].map((student: any) => {
                                                                    return (
                                                                        <Grid key={student.student_id} item xs={12}>
                                                                            {/* Student List */}
                                                                            <Grid container spacing={4} className="ListGridContainer">
                                                                                <Grid item xs={2}>
                                                                                    <img
                                                                                        src={student?.avatar}
                                                                                        className="PersonImage"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <Typography component={'h3'} >
                                                                                        {student?.first_name + ' ' + student?.last_name}
                                                                                    </Typography>
                                                                                    <Typography component={'p'} >
                                                                                        {student?.email}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid className="justifyStart" item xs={2}>
                                                                                    <label
                                                                                        htmlFor={"checkbox_" + student.student_id}
                                                                                        className="checkboxSpan"
                                                                                    >
                                                                                        <Checkbox
                                                                                            id={"checkbox_" + student.student_id}
                                                                                            data-test-id={"checkbox_" + student.student_id}
                                                                                            onChange={() =>
                                                                                                this.props.updateList(student.student_id)
                                                                                            }
                                                                                            checked={this.props.checkedStudents.indexOf(student.student_id) === -1 ? false : true}
                                                                                            className="checkbox ML0"
                                                                                            inputProps={{
                                                                                                "aria-labelledby":
                                                                                                    "checkbox-list-secondary-label-question",
                                                                                            }}
                                                                                        />
                                                                                    </label>
                                                                                </Grid>
                                                                            </Grid>
                                                                            {/* Student List END */}
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                        </Box>
                                                    </Grid>

                                                </>
                                            )
                                        })
                                }
                            </Grid>
                            {/* Student List by division END */}

                            {/* Done Cancel */}
                            <Grid container spacing={4} className="MV4">
                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Button data-test-id="group-done-btn" className={`ActionButton ${classes.button_color}`} onClick={() => this.props?.handleOnClose(false)}>
                                            Done
                                        </Button>

                                        <Button data-test-id="group-cancel-btn" className={`ActionButton`} onClick={() => this.props?.handleOnClose(false)}>
                                            Cancel
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* Done Cancel END */}
                        </Grid>

                    </Grid>

                </Modal>
            </>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(AddGroupModal);
// Customizable Area End