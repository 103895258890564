// Customizable Area Start
import React from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
    Box,
    Button,
    Typography,
} from "@material-ui/core";
import DeleteDivisionController, { Props } from "./DeleteDivisionController.web";
import {
    deleteAlert,
    SuccessCheckMark,
} from "./assets";
import './CommonDeleteModal.web.css';
// Customizable Area End

export class DeleteDivision extends DeleteDivisionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {

        return (
            <>
                {this.state.sucessFullModal ?
                    <>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className="delete_division_modal_container"
                            open={this.props.deleteAlertModal}
                            onClose={this.modalhandleClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                            disableAutoFocus={true}
                        >
                            <Fade in={this.props.deleteAlertModal} style={{ border: "none" }}>
                                <div className="delete_division_modal delete_division_success_modal_padding">
                                    <img
                                        src={SuccessCheckMark}
                                        className="delete_division_success_img"
                                    />
                                    <Typography className="delete_division_delete_text_head mt_30">
                                        Deleted
                                    </Typography>

                                    <Typography className="delete_division_delete_text_para mt_24">
                                        The Division has been deleted successfully.
                                    </Typography>
                                    <Button className="delete_division_delete_btn_ok dlt_division_modal_ok_btn_p mt_40" onClick={this.modalhandleClose}>
                                        ok
                                    </Button>
                                </div>
                            </Fade>
                        </Modal>
                    </>
                    :
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className="delete_division_modal_container"
                        open={this.props.deleteAlertModal}
                        onClose={this.modalhandleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        disableAutoFocus={true}
                    >
                        <Fade in={this.props.deleteAlertModal} style={{ border: "none" }}>
                            <div className="delete_division_modal delete_division_alert_modal_padding">
                                <img
                                    src={deleteAlert}
                                />
                                <Typography className="delete_division_delete_text_head mt_30">
                                    Are you sure?
                                </Typography>

                                <Typography className="delete_division_delete_text_para mt_24">
                                    Do you want to delete this “Division” ?
                                </Typography>
                                <Box className="delete_division_action_container mt_40">
                                    <Button className="delete_division_delete_button dlt_modal_divisions_delete_btn_spacing" onClick={this.delete_class}>
                                        Delete
                                    </Button>
                                    <Button className="delete_division_cancel_button" onClick={this.modalhandleClose}>
                                        Cancel
                                    </Button>
                                </Box>
                            </div>
                        </Fade>
                    </Modal>
                }
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export default DeleteDivision as React.ComponentType<any>

// Customizable Area End
