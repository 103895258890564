// Customizable Area Start
import React from 'react';
import {
    Grid,
    Modal,
    Button,
    withStyles,
} from "@material-ui/core";
import './DeleteModal.web.css';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export interface Props {
    navigation?: any;
    id?: string;
    location?: any;
    // Customizable Area Start
    classes?:any;
    title: string;
    subTitle: string;
    paragraph: string;
    deleteModal: boolean;
    onCloseModal: any;
    onModalOk: any;
    assessmentId:number;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export class DeleteModal extends React.Component<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
        };
        this.handleOkClick = this.handleOkClick.bind(this);
        // Customizable Area End
    }

    // Customizable Area Start
    handleOkClick(){
        this.props.onModalOk(this.props.assessmentId);
    }
    // Customizable Area End

    // Customizable Area Start
    render() {
        return <>
            <Modal
                open={this.props.deleteModal}
                onClose={this.props.onCloseModal}
            >
                <Grid
                    className="DeleteModal"
                >
                    <div className={`DeleteTitle ${this.props.classes.title_font}`}>{this.props.title}</div>
                    <div className="DeleteModalDivider"></div>
                    <div className={`DeleteSubTitle ${this.props.classes.subTitle_font}`}>{this.props.subTitle}</div>
                    <div className={`DeletePara ${this.props.classes.bodyText_font}`}>{this.props.paragraph}</div>
                    <Grid className="DeleteModalActionDiv">
                        <Button variant="contained" className={`CancelButton ${this.props.classes.button_color}`} onClick={this.props.onCloseModal}><span className={`ButtonText`}>Cancel</span></Button>
                        <Button variant="contained" className={`DeleteButton ${this.props.classes.button_color}`} onClick={this.handleOkClick} data-test-id="DeleteOkButton"><span className={`ButtonText`}>Ok</span></Button>
                    </Grid>
                </Grid>
            </Modal>
        </>
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(DeleteModal);
// Customizable Area End