exports.apiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.putApiMethodType = "PUT";
const urlConfig = require("../../../../framework/src/config");

// Customizable Area Start
exports.studentPeriodEndPoint = "/bx_block_scheduling/school_periods/school_period_by_day";

exports.dashboardURL = urlConfig.dashboardURL;
exports.instituteURL = urlConfig.instituteURL;
exports.attendanceURL = urlConfig.attendanceURL;
exports.getEventByMember = "/bx_block_time_table/events?member_id=";
exports.getEventByCreated = "/bx_block_time_table/events?created_by=";
exports.getEventByClass = "/bx_block_time_table/events?class_id=";
exports.teacherLiveClassesMakeHostEndPoint =
  "/bx_block_scheduling/meetings/make_meeting_host";
  // Customizable Area End
