// Customizable Area Start
import React from "react"
import {
    Box,
    Button,
    withStyles,
    Typography,
    Modal,
    Backdrop,
    Grid
} from "@material-ui/core";
import PublishedScheduledModalController, { Props } from "./PublishedScheduledModalController.web";
import { imgTwoHand, modalImgOrangeBackground } from "../assets";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
import './PublishedScheduledModal.web.css';
// Customizable Area End

export class PublishedScheduledModal extends PublishedScheduledModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { classes } = this.props
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="MainModal published-assessment-modal-class"    
                    open={this.props.openModal}
                    onClose={(_, reason) => {
                        reason !== 'backdropClick' ? this.props?.handleOnClose(false) : null;
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    id="published-assessment-modal-id"
                    data-test-id="published-assessment-modal"
                >
                    <Box
                        className="MainBox"
                    >
                        <Grid container spacing={0}>

                            {/* Kudos Title */}
                            <Grid item xs={12}
                                className="TitleBox"
                            >
                                <Typography className={`TitleText ${classes.title_font}`}>
                                    Kudos
                                </Typography>
                            </Grid>
                            {/* Kudos Title END */}

                            {/* Img Icon */}
                            <Grid item xs={12}
                                className="TitleBox"
                            >
                                <img src={modalImgOrangeBackground} className="img_orange_bg" />
                                <img src={imgTwoHand} className="img_two_hand" />
                            </Grid>
                            {/* Img Icon END */}

                            {/* message String */}
                            <Grid item xs={12}
                                className="MessageGrid"
                            >
                                <Typography
                                    component={"h2"}
                                    className={`MessageText ${classes.subTitle_font}`}
                                >
                                    {this.props.messageString}
                                </Typography>
                            </Grid>
                            {/* message String END */}


                            {/* message sub String */}
                            <Grid item xs={12}
                                className="MessageGrid"
                            >
                                <Typography
                                    component={"h3"}
                                    className={`SubTitleText ${classes.bodyText_font}`}
                                >
                                    Your assessment has been {this.props.messageString} Successfully
                                </Typography>
                            </Grid>
                            {/* message sub String END*/}


                            {/* go to Dashboard Button */}
                            <Grid item xs={12}
                                className="TitleBox"
                            >
                                <Button className={`ActionButton ${classes.button_color}`} onClick={() => {
                                    window.location.href = '/TeacherAssessment';
                                }}>
                                    Go to Dashboard
                                </Button>
                            </Grid>
                            {/* go to Dashboard Button END */}

                        </Grid>
                    </Box>

                </Modal>
            </>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(PublishedScheduledModal);
// Customizable Area End
