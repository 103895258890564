exports.apiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.putApiMethodType = "PUT";
exports.postApiMethodType = "POST";

const urlConfig = require("../../../../../framework/src/config");
// Customizable Area Start

exports.dashboardURL = urlConfig.dashboardURL;
exports.instituteURL = urlConfig.instituteURL;
exports.attendanceURL = urlConfig.attendanceURL;
exports.submitFeedbackApiEndpoint = "account_block/reviews";
exports.getQuestion = "account_block/feedback_questions";
// Customizable Area End
