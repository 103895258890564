// Customizable Area Start
import React from "react";
import "./AdminReportCardTemplate.web.css";
import Button from "@material-ui/core/Button";
import { report_icon } from "../assets";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import { HISTORY } from "../../../../components/src/comman";
import { withStyles } from "@material-ui/core/styles";
import AdminReportCardTemplateController, { Props } from './AdminReportCardTemplateController.web';
import Spinner from "../../../shared/SideBar/src/Spinner";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

class AdminReportCardTemplate extends AdminReportCardTemplateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const handleClick = (data: any) => {
      HISTORY.push({
        pathname: "/ProfileDetails",
        state: {
          selectedClassData: data,
        }
      });
    };
    const { templateClassData, isLoader } = this.state
    return (
      <>
        {isLoader && <Spinner spinnerModal={isLoader} />}
        <h2>Choose Class</h2>
        <Grid component="ul" container spacing={4} className="classSection">
          {templateClassData.map((adminreport: any, index: any) => {
            return (
              <Grid component="li" item xs={12} lg={4} sm={6} key={index}>
                <Box className="paper">
                  <div className="reportIcon">
                    <img src={report_icon} />
                  </div>
                  <h4>{adminreport.attributes.title}</h4>
                  <p>{adminreport.attributes.grade[0].name}</p>
                  <Button
                    variant="contained"
                    className={this.props.classes.button_color}
                    onClick={() => handleClick(adminreport)}
                    style={{ textTransform: 'capitalize' }}
                  >
                    Preview
                  </Button>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(AdminReportCardTemplate as React.ComponentType<any>, "AdminAccount")
);
// Customizable Area End
