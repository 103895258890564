// Customizable Area Start
import React, { useRef, useState } from "react"
import {
  Modal,
  Backdrop,
  Box,
  Grid,
  Button,
  Typography,
} from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { FileDrop } from "react-file-drop";
import axios from "axios"
import { toast } from "react-toastify";
import { fileuploaded, fileuploadedFail, csvIcon } from "../assets";
import './UploadModal.web.css';
export const configJSON = require("./config.js");
import {themeCreateStyle} from '../../../../components/src/CustomThemeStyles.web';
import { withStyles } from '@material-ui/core/styles';
// Customizable Area End

interface IProps {
  // Customizable Area Start
  open: boolean,
  close: React.Dispatch<React.SetStateAction<boolean>>,
  classes: any
  // Customizable Area End
}
export const UploadModal: React.FC<IProps> = ({
  // Customizable Area Start
  open,
  close,
  classes
  // Customizable Area End
}) => {
  // Customizable Area Start
  const inputRef = useRef<any>()
  const [files, setFiles] = useState<any>([])
  const [state, setState] = useState<number>(0)
  const [error, setError] = useState<string[]>([])
  const [uploading, setUploading] = useState<boolean>(false)
  const Reset = () => {
    setFiles([])
    setState(0)
  }

  const onUpdateClick = async () => {
    if(uploading){

    }else{
    const result = await uploadFileApi(files)
    if (result) {
      setUploading(false)
      if (result.status === 200) {
        toast.success("SUCCESS: file is submitted ")
        Reset()
        close(false)
      } else {
        setState(1)
        if (result.status === 422) {
          const err = result.error.toString().split(',')
          setError(err)
        }
        toast.warning("ERROR: Somwthing went wrong ")
      }
    } else {
      setUploading(false)
      toast.warning("ERROR: Somwthing went wrong ")
    }}
  }

  const uploadFileApi = async (bulk_file: any) => {
    setUploading(true)
    var FormData = require('form-data');
    var bodyFormData = new FormData();
    bodyFormData.append("file", bulk_file[0])

    const header:any = {
      "Content-Type": configJSON.formDataContentType,
      token: localStorage.getItem('token'),
    };

    return await axios({
      method: "post",
      url: configJSON.instituteURL + configJSON.FileUploadUrlEndPoint,
      data: bodyFormData,
      headers: header,
    }).then((response: any) => {
      return response.data
    }).catch((error) => {
      return error.response.data
    })

  }

  const download = (data: any) => {
    const blob = new Blob([data], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a');
    a.setAttribute('hidden', '')
    a.setAttribute('href', url)
    a.setAttribute('download', 'Admin_class_period_fromat.csv')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }



  const objectToCSV = () => {
    const csvRows = [];
    const headers = [
      `${"school_email"}`,
      `${"grade_name"}`,
      `${"division_name"}`,
      `${"school_period_name"}`,
      `${"subject_name"}`,
      `${"batch_name"}`,
      `${"teacher_email"}`,
      `${"start_date"}`,
      `${"name"}`,
      `${"description"}`,
      `${"is_online"}`,
      `${"week_day"}`,
    ]
    csvRows.push(headers.join(','))
    const data = csvRows.join('\n')
    download(data)
  }


  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="UploadModalContainer"
        open={open}
        onClose={() => {
          Reset()
          close(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          className="innerBox"
        >
          <Grid container>
            <Grid item xs={11}>
              <Typography
                className={`${classes.theme_font} titleText`}
              >
                Upload File
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <CloseIcon
                className="closeIcon"
                onClick={() => {
                  Reset()
                  close(false)
                }}
              />
            </Grid>
            <Grid item xs={12}>

              {files.length === 0 && state === 0 &&

                <>
                  <Box
                    className="btnContainer"
                  >
                    <Button
                      className="downlaodExcelBtn"
                      onClick={() => {
                        objectToCSV()
                      }}>
                      <img src={csvIcon} />
                      <Typography
                        className="downlaodExcelBtnTypo"
                      >
                        Download excel file
                      </Typography>
                    </Button>
                    <Typography
                      className="paragraphText"
                    >
                      Download & edit the file to enter data for periods. Please do not change column name
                    </Typography>
                  </Box>


                  <FileDrop
                    onDrop={(files: any, event: any) => {
                      window.addEventListener(
                        "drop",
                        function (e) {
                          e.preventDefault();
                        },
                        false
                      );

                      const bulkFileUploaded = [files[0]];
                      setFiles(bulkFileUploaded)
                    }}
                  >
                    <Box
                      className="uploadFileContainer"
                    >
                      <Button
                        className="uploadFileBtn w20"
                        onClick={() => {
                          inputRef?.current?.click()
                        }}
                      >
                        <Typography>
                          Upload File
                        </Typography>
                      </Button>
                      <input
                        ref={inputRef}
                        accept=".csv"
                        className="dNone"
                        type="file"
                        onChange={(e: any) => {
                          let totalSize: number = 0;
                          var files = e.target.files[0];
                          
                          const bulkFileUploaded = [files];
                          setFiles(bulkFileUploaded)
                        }}
                      />
                      <Typography
                        className="paragraphText"
                      >
                        Drag and drop files here or browse them from your computer
                      </Typography>
                    </Box>
                  </FileDrop>
                </>

              }
            </Grid>



            {files.length > 0 && state === 0 &&
              <>{
                files.map((item: any) => {
                  return (
                    <>
                      <Box
                        className="uploadFileContainer"
                      >
                        <Box>
                          <img src={fileuploaded} className="img" />
                        </Box>
                        <Box>
                          <Typography
                            className={`itemText ${classes.theme_font}`}
                          >
                            {item.name}
                          </Typography>
                        </Box>
                        <Box className="alignStart">
                          <Typography
                            className={`fileUploadText ${classes.theme_font}`}
                          >
                            File Uploaded
                          </Typography>

                        </Box>
                      </Box>
                    </>
                  )
                })
              }
              </>
            }
            {
              state === 1 && files.map((item: any) => {
                return (
                  <>
                    <Box
                      className="filesMainBox"
                    >
                      <Box>
                        <img src={fileuploadedFail} className="img" />
                      </Box>
                      <Box>
                        <Typography
                          className={`itemText ${classes.theme_font}`}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <Box className="alignStart">
                        <Typography
                          className={`fileUploadText ${classes.theme_font}`}
                        >
                          Error importing - Please make changes and reupload
                        </Typography>

                      </Box>
                      <Box className="mt20">
                        {error.length > 0 && error.map((item: string, index: number) => {
                          return (
                            <div
                              key={`${item} ${index}`}
                              className="filesBox"
                            >
                              <Typography

                                className={`filesText ${classes.theme_font}`}
                              >
                                {item}
                              </Typography>
                            </div>
                          )
                        })}

                      </Box>
                    </Box>
                  </>
                )
              })
            }
          </Grid>
          <Grid>
            {files.length > 0 && state == 0 && (
              <>
                <Box className="uploadBtnContainer">
                  <Box className="flexCenter w28">
                    <Box>
                      <Button
                        className="w100 uploadFileBtn pad"
                        onClick={onUpdateClick}
                      >
                        Upload
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        className="cancelBtn"
                        onClick={() => {
                          Reset()
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Box>

              </>
            )}
            {state === 1 && (
              <Box className="reUploadBtnBox">
                <Box className="w22">
                  <Box>
                    <Button
                      className="uploadFileBtn pad w100"
                      onClick={() => {
                        Reset()
                      }}
                    >
                      Re-Upload
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Grid>

        </Box>
      </Modal>
    </>
  )
  // Customizable Area End
}

  // Customizable Area Start
export default withStyles(themeCreateStyle)(UploadModal)
  // Customizable Area End