// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";

import moment from "moment";
import { HISTORY } from "../../../../../components/src/comman";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes: any;
    school_class_id: string;
    subject_id: string;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    data: any;
    taskEvaluationData: any;
    start_date: any;
    end_date: any;
    school_class_id: string;
    subject_id: string;
    searchText: string;
    totalPages: number;
    currentPage: number;
    studentTaskId: string;
    isLoading: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class TaskEvaluationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apigetTeacherTaskId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            data: [],
            taskEvaluationData: [],
            start_date: moment().format('DD/MM/YYYY'),
            end_date: moment().format('DD/MM/YYYY'),
            school_class_id: '',
            subject_id: '',
            searchText: '',
            totalPages: 0,
            currentPage: 0,
            studentTaskId: '',
            isLoading: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.get_student_task_submission(this.state.start_date, this.state.end_date, this.state.school_class_id, this.state.subject_id, this.state.searchText, this.state.currentPage)
    }
    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps?.school_class_id !== this.props?.school_class_id || prevProps?.subject_id !== this.props?.subject_id) {
            this.setState({
                school_class_id: this.props?.school_class_id,
                subject_id: this.props?.subject_id,
            })
            this.get_student_task_submission(this.state.start_date, this.state.end_date, this.props?.school_class_id, this.props?.subject_id, this.state.searchText, this.state.currentPage)
        }
    }
    // Customizable Area End
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.apigetTeacherTaskId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({
                            taskEvaluationData: responseJson.data,
                            totalPages: responseJson.meta.pagination.total_pages,
                            isLoading: false,
                        })

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    goToTaskMarkSubmission = (taskData: any) => {
        HISTORY.push({
            pathname: "/TaskMarkSubmission",
            state: {
                studentFileData: taskData,
            },
        });
    }
    get_student_task_submission = (start_date?: any, end_date?: any, school_class_id?: any, subject_id?: any, searchText?: any, currentPage?: any) => {
        this.setState({
            isLoading: true,
        })
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem('token'),
            "school": data.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetTeacherTaskId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getStudetTaskSubmissionEndPoint + `?start_date=${start_date}&end_date=${end_date}&subject_id=${subject_id}&school_class_id=${school_class_id}&search=${searchText}&page=${currentPage}&per=10`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
