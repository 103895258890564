// Customizable Area Start
import React from "react";
import Grid from '@material-ui/core/Grid';
import Spinner from "../../../shared/SideBar/src/Spinner";
import { Box, Button,} from "@material-ui/core";
import { withStyles, } from '@material-ui/core/styles';
import DeleteTaskController, { Props } from "./DeleteTaskController.web";
import './DeleteTask.web.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
export class DeleteTask extends DeleteTaskController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        return (
            <>
                <Modal className="deleteTaskModal"
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.props.deleteModal}
                    onClose={this.modalhandleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableAutoFocus={true}
                    >
                    <Fade in={this.props.deleteModal} style={{ border: "none" }}>
                        <Box className="deleteModalBox">
                            <div>
                                {this.state.showSpinner && <Spinner spinnerModal={this.state.showSpinner} />}
                                <div className={`deleteDiv ${this.props.classes.title_font}`}>Delete Task</div>
                                <div className={`borderNew ${this.props.classes.deleteTask_classAndSection_solid_border}`}></div>
                                <div className={`deleteMes1 ${this.props.classes.subTitle_font}`}>Are you sure you want to delete the item?</div>
                                <div className={`deleteMes2 ${this.props.classes.bodyText_font}`}>This item will be delete immediately. You cannot undo the action.</div>
                                <Grid className="deleteGrid">
                                    <Button variant="contained" className={`cancelButton ${this.props.classes.button_color_disabled}`} onClick={this.modalhandleClose}><span className="cancelText">Cancel</span></Button>
                                    <Button variant="contained" className={`deleteButton ${this.props.classes.button_color}`} onClick={this.deleteTask}><span className="deleteText">Delete</span></Button>
                                </Grid>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(DeleteTask);
// Customizable Area End