// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    createEventModal: boolean;
    savecloseModalFun: any;
    closeModalFun: any;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    closeModal: boolean;
    userData: any;
    ClassData: any;
    classerror: boolean;
    class_id: string;
    periodDateError: boolean;
    periodDate: string;
    periodDateErrorText: string;
    titleError: boolean;
    title: string;
    titleErrorText: string;
    subject_id: string;
    subjectError: boolean;
    getTeacherSubjects: any;
    description: string;
    periodTime: string;
    meetLink: string;
    getTeacherPeriod: any;
    period_id: string;
    periodError: boolean;
    isMeetLink: boolean;
    batch_id: string;
    showSpinner: boolean;
    alertModal: boolean;
    alertMessage: string;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CreateOnlineLectureController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    createPeriodId: string = "";
    apigetTeacherSubjectsId: string = "";
    apigetTeacherFreePeriodId: string = '';
    child: React.RefObject<any>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            closeModal: false,
            userData: [],
            ClassData: [],
            classerror: false,
            class_id: '',
            periodDateError: false,
            periodDate: '',
            periodDateErrorText: '',
            titleError: false,
            title: '',
            titleErrorText: '',
            alertModal: false,
            subject_id: '',
            subjectError: false,
            getTeacherSubjects: [],
            alertMessage: '',
            description: '',
            periodTime: '',
            meetLink: '',
            getTeacherPeriod: [],
            period_id: '',
            periodError: false,
            isMeetLink: false,
            batch_id: '',
            showSpinner: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.set_data();
        // Customizable Area End
    }
    // Customizable Area Start
    set_data = () => {
        const user_data = localStorage.getItem('user_data');
        const data = JSON.parse(user_data || '{}')
        this.setState({ userData: data, ClassData: data.school_account.data });
    }
    modalhandleClose = () => {
        this.setState({ closeModal: true })
        console.log("modal closed", this.state.closeModal)
        this.props.closeModalFun(this.state.closeModal)
    }


    get_period = (date: any, class_id: any) => {
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetTeacherFreePeriodId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getFreePeriodEndPoint + `?school_id=${school_Data.school_id}&date=${date}&school_class_id=${class_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    get_teacher_subjects = (id: any) => {
        const user_data = localStorage.getItem("user_data");
        const school_Data = JSON.parse(user_data || "{}");
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
            school: school_Data?.school_id,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetTeacherSubjectsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTeacherClassSubjectsEndPoint + `?school_class_id=${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    capitalizeFirstLetter = (string:any) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    addClass = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const user_data = localStorage.getItem('user_data');
        const school_Data = JSON.parse(user_data || '{}')
        const { title, period_id, subject_id, class_id, periodDate, isMeetLink, meetLink, description, batch_id } = this.state
        if (title.length === 0 || period_id.length === 0 || class_id.length === 0 || subject_id.length === 0) {
            if (
                title === null ||
                title.length === 0
            ) {
                this.setState({
                    titleError: true,
                    titleErrorText: "Tittle is required.",
                });
            }

            if (
                this.state.period_id === null ||
                this.state.period_id.length === 0
            ) {
                this.setState({
                    periodError: true,
                });
            }
            if (
                this.state.periodDate === null ||
                this.state.periodDate.length === 0
            ) {
                this.setState({ periodDateError: true, periodDateErrorText: " Please select date." });
            }
            if (this.state.class_id === null || this.state.class_id.length === 0) {
                this.setState({
                    classerror: true,
                });
            }
            if (this.state.subject_id === null || this.state.subject_id.length === 0) {
                this.setState({
                    subjectError: true,
                });
            }
            return false;
        }
        const data = {
            school_class_id: class_id != null && class_id,
            subject_id: subject_id != null && subject_id,
            batch_id: batch_id != null ? batch_id : '',
            week_day_school_period_id: period_id != null && period_id,
            start_date: periodDate != null && periodDate,
            school_id: school_Data.school_id,
            name: title != null && title,
            is_online: isMeetLink,
            online_class_link: meetLink,
            description: description,
        }
        this.setState({
            showSpinner: true
        })
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };
        const httpBody = data;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createPeriodId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createPeriodEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiPostMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            // Customizable Area Start
            if (apiRequestCallId === this.createPeriodId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        this.setState({
                            showSpinner: false
                        })
                        toast.success("Lecture has been Added successfully")
                        this.props.savecloseModalFun(true)
                    }
                    else {
                        let allerrors = '';
                        const newLocal = JSON.stringify(responseJson.errors[0]);
                        JSON.parse(newLocal, (key, value) => {
                            if (value.length > 0) {
                                if (allerrors.length <= 0) {
                                    allerrors = this.capitalizeFirstLetter(key) + ' ' + value;
                                }
                            }
                        });
                        this.setState({
                            showSpinner: false,
                            alertMessage: allerrors,
                            alertModal: true,
                        })
                        this.parseApiCatchErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apigetTeacherSubjectsId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        this.setState({ getTeacherSubjects: responseJson.data })

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apigetTeacherFreePeriodId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        const today = moment().format("DD/MM/YYYY")
                        if (moment(today).isSame(this.state.periodDate)) {
                            let tempdata: any = []
                            const today = moment()
                            var endTime = moment(today, 'hh:mm A');
                            console.log(endTime)
                            responseJson.data.map((res: any) => {
                                var beginningTime = moment(res.attributes.start_time, 'hh:mm A');
                                if (endTime.isBefore(beginningTime)) {
                                    tempdata.push(res)
                                    console.log(tempdata)
                                }
                            })
                            this.setState({ getTeacherPeriod: tempdata })
                        }
                        else {
                            this.setState({ getTeacherPeriod: responseJson.data })
                        }
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
            }
            // Customizable Area End
        }
    }
    // Customizable Area Start
    // Customizable Area End
}
