// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showLoader: boolean;
  addEditGradeDialog: boolean;
  isEditGrade: boolean;
  isEditGradeScale: boolean;
  gradeName: string;
  gradeNameError: boolean;
  gradeNameErrorMsg: string;
  gradeLabel: string;
  gradeLabelError: boolean;
  gradeLabelErrorMsg: string;
  score: string;
  scoreError: boolean;
  scoreErrorMsg: string;
  minMarks: string;
  minMarksError: boolean;
  minMarksErrorMsg: string;
  maxMarks: string;
  maxMarksError: boolean;
  maxMarksErrorMsg: string;
  gradingList: any;
  selectedGradeIndex: any;
  addEditGradeScaleDialog: boolean;
  selectedGradeId: any;
  selectedGradeScaleId: any;
  deleteModal: boolean;
  isDeleteAllGradeData: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
export const invalidCharsForNumberType = ["-", "e", "+", "E"];
// Customizable Area End
export default class GradingScaleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetGradingList: string = "";
  apiSaveGradeDetails: string = "";
  apiDeleteGrade: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      showLoader: false,
      addEditGradeDialog: false,
      isEditGrade: false,
      isEditGradeScale: false,
      gradeName: "",
      gradeNameError: false,
      gradeNameErrorMsg: "",
      gradeLabel: "",
      gradeLabelError: false,
      gradeLabelErrorMsg: "",
      score: "",
      scoreError: false,
      scoreErrorMsg: "",
      minMarks: "",
      minMarksError: false,
      minMarksErrorMsg: "",
      maxMarks: "",
      maxMarksError: false,
      maxMarksErrorMsg: "",
      gradingList: [],
      selectedGradeIndex: 0,
      addEditGradeScaleDialog: false,
      selectedGradeId: 0,
      selectedGradeScaleId: 0,
      deleteModal: false,
      isDeleteAllGradeData: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
// Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetGradingList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ gradingList: responseJson?.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiSaveGradeDetails) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ addEditGradeDialog: false, addEditGradeScaleDialog: false })
            if (this.state.deleteModal) {
              this.setState({ deleteModal: false });
            }
            if (this.state.isEditGrade) {
              toast.success("Grading Details updated Successfully.")
            } else {
              toast.success("Grading Details added Successfully.")
            }
            this.getGradingList()
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiDeleteGrade) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ deleteModal: false })
            toast.success("Grading Details deleted Successfully.")
            this.getGradingList()
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getGradingList();
    // Customizable Area End
  }

  openAddEditGradeDialog = () => {
    this.setState({
      addEditGradeDialog: true,
      gradeName: "",
      gradeLabel: "",
      score: "",
      minMarks: "",
      maxMarks: "",
      gradeNameError: false,
      gradeNameErrorMsg: "",
      gradeLabelError: false,
      gradeLabelErrorMsg: "",
      scoreError: false,
      scoreErrorMsg: "",
      minMarksError: false,
      minMarksErrorMsg: "",
      maxMarksError: false,
      maxMarksErrorMsg: "",
      isEditGrade: false,
      isEditGradeScale: false,
      selectedGradeIndex: 0,
      selectedGradeId: 0,
      selectedGradeScaleId: 0,
    });
  };

  handleAddEditGradeDialogClose = () => {
    this.setState({ addEditGradeDialog: false });
  };

  openAddEditGradeScaleDialog = (gradeId: number, gradeName: string) => {
    this.setState({
      addEditGradeScaleDialog: true,
      isEditGrade: true,
      selectedGradeId: gradeId,
      gradeName: gradeName,
      gradeLabel: "",
      score: "",
      minMarks: "",
      maxMarks: "",
      gradeNameError: false,
      gradeNameErrorMsg: "",
      gradeLabelError: false,
      gradeLabelErrorMsg: "",
      scoreError: false,
      scoreErrorMsg: "",
      minMarksError: false,
      minMarksErrorMsg: "",
      maxMarksError: false,
      maxMarksErrorMsg: "",
      isEditGradeScale: false,
      selectedGradeIndex: 0,
    });
  };

  handleAddEditGradeScaleDialogClose = () => {
    this.setState({ addEditGradeScaleDialog: false });
  };

  onChangeHandle = (e: any, name: string) => {
    const value = e.target.value.trim();

    switch (name) {
      case "gradeName":
        if (value.length === 0) {
          this.setState({
            gradeNameError: true,
            gradeNameErrorMsg: "Grade name is required.",
          });
        } else {
          this.setState({
            gradeNameError: false,
            gradeNameErrorMsg: "",
          });
        }
        this.setState({ gradeName: e.target.value });
        break;
      case "gradeLabel":
        if (value.length === 0) {
          this.setState({
            gradeLabelError: true,
            gradeLabelErrorMsg: "Label is required.",
          });
        } else {
          this.setState({
            gradeLabelError: false,
            gradeLabelErrorMsg: "",
          });
        }
        this.setState({ gradeLabel: e.target.value });
        break;
      case "score":
        if (value.length === 0) {
          this.setState({
            scoreError: true,
            scoreErrorMsg: "Score is required.",
          });
        } else {
          this.setState({
            scoreError: false,
            scoreErrorMsg: "",
          });
        }
        this.setState({ score: e.target.value });
        break;
      case "minMarks":
        if (value.length === 0) {
          this.setState({
            minMarksError: true,
            minMarksErrorMsg: "Minimum marks is required.",
          });
        } else if (value > 100) {
          this.setState({
            minMarksError: true,
            minMarksErrorMsg: "Minimum marks should be less than 100.",
          });
        } else {
          this.setState({
            minMarksError: false,
            minMarksErrorMsg: "",
          });
        }
        this.setState({ minMarks: e.target.value });
        break;
      case "maxMarks":
        if (value.length === 0) {
          this.setState({
            maxMarksError: true,
            maxMarksErrorMsg: "Maximum marks is required.",
          });
        } else if (value > 100) {
          this.setState({
            maxMarksError: true,
            maxMarksErrorMsg: "Maximum marks should be less than 100.",
          });
        } else {
          this.setState({
            maxMarksError: false,
            maxMarksErrorMsg: "",
          });
        }
        this.setState({ maxMarks: e.target.value });
        break;
      default:
    }
  };

  onBlurHandle = (e: any, name: string) => {
    const {
      gradeName,
      gradeLabel,
      score,
      minMarks,
      maxMarks,
    } = this.state;
    switch (name) {
      case "gradeName":
        if (gradeName === null || gradeName.length === 0) {
          this.setState({
            gradeNameError: true,
            gradeNameErrorMsg: "Grade name is required.",
          });
        } else {
          this.setState({
            gradeNameError: false,
            gradeNameErrorMsg: "",
          });
        }
        break;
      case "gradeLabel":
        if (gradeLabel === null || gradeLabel.length === 0) {
          this.setState({
            gradeLabelError: true,
            gradeLabelErrorMsg: "Label is required.",
          });
        } else {
          this.setState({
            gradeLabelError: false,
            gradeLabelErrorMsg: "",
          });
        }
        break;
      case "score":
        if (score === null || score.length === 0) {
          this.setState({
            scoreError: true,
            scoreErrorMsg: "Score is required.",
          });
        } else {
          this.setState({
            scoreError: false,
            scoreErrorMsg: "",
          });
        }
        break;
      case "minMarks":
        if (minMarks === null || minMarks.length === 0) {
          this.setState({
            minMarksError: true,
            minMarksErrorMsg: "Minimum marks is required.",
          });
        } else {
          this.setState({
            minMarksError: false,
            minMarksErrorMsg: "",
          });
        }
        break;
      case "maxMarks":
        if (maxMarks === null || maxMarks.length === 0) {
          this.setState({
            maxMarksError: true,
            maxMarksErrorMsg: "Maximum marks is required.",
          });
        } else {
          this.setState({
            maxMarksError: false,
            maxMarksErrorMsg: "",
          });
        }
        break;
      default:
    }
  };

  editGradeScaleClick = (gradeAllData: any, gradeScaleData: any, index: number) => {
    this.setState({
      selectedGradeId: gradeAllData?.id,
      gradeName: gradeAllData?.attributes?.name,
      selectedGradeScaleId: gradeScaleData?.id,
      gradeLabel: gradeScaleData?.attributes?.name,
      score: gradeScaleData?.attributes?.score,
      minMarks: gradeScaleData?.attributes?.min_marks,
      maxMarks: gradeScaleData?.attributes?.max_marks,
      addEditGradeScaleDialog: true,
      isEditGrade: true,
      isEditGradeScale: true,
      selectedGradeIndex: index,
      gradeNameError: false,
      gradeNameErrorMsg: "",
      gradeLabelError: false,
      gradeLabelErrorMsg: "",
      scoreError: false,
      scoreErrorMsg: "",
      minMarksError: false,
      minMarksErrorMsg: "",
      maxMarksError: false,
      maxMarksErrorMsg: "",
    })
  }

  validateGradeForm = () => {
    const { gradeName, gradeLabel, score, minMarks, maxMarks } = this.state;
    let isValidGradeName = true;
    let isValidGradeLabel = true;
    let isValidScore = true;
    let isValidMinMarks = true;
    let isValidMaxMarks = true;

    if (gradeName.length === 0) {
      this.setState({
        gradeNameError: true,
        gradeNameErrorMsg: "Grade name is required.",
      });
      isValidGradeName = false;
    } else {
      this.setState({
        gradeNameError: false,
        gradeNameErrorMsg: "",
      });
      isValidGradeName = true;
    }

    if (gradeLabel?.length === 0) {
      this.setState({
        gradeLabelError: true,
        gradeLabelErrorMsg: "Label is required.",
      });
      isValidGradeLabel = false;
    } else {
      this.setState({
        gradeLabelError: false,
        gradeLabelErrorMsg: "",
      });
      isValidGradeLabel = true;
    }

    if (score?.length === 0) {
      this.setState({
        scoreError: true,
        scoreErrorMsg: "Score is required.",
      });
      isValidScore = false;
    } else {
      this.setState({
        scoreError: false,
        scoreErrorMsg: "",
      });
      isValidScore = true;
    }

    if (minMarks?.length === 0) {
      this.setState({
        minMarksError: true,
        minMarksErrorMsg: "Minimum marks is required.",
      });
      isValidMinMarks = false;
    } else if (Number(minMarks) > 100) {
      this.setState({
        minMarksError: true,
        minMarksErrorMsg: "Minimum marks should be less than 100.",
      });
      isValidMinMarks = false;
    } else {
      this.setState({
        minMarksError: false,
        minMarksErrorMsg: "",
      });
      isValidMinMarks = true;
    }

    if (maxMarks?.length === 0) {
      this.setState({
        maxMarksError: true,
        maxMarksErrorMsg: "Maximum marks is required.",
      });
      isValidMaxMarks = false;
    } else if (Number(maxMarks) > 100) {
      this.setState({
        maxMarksError: true,
        maxMarksErrorMsg: "Maximum marks should be less than 100.",
      });
      isValidMaxMarks = false;
    } else {
      this.setState({
        maxMarksError: false,
        maxMarksErrorMsg: "",
      });
      isValidMaxMarks = true;
    }

    const isValid = isValidGradeName && isValidGradeLabel && isValidScore && isValidMinMarks && isValidMaxMarks
    return isValid;
  }

  addEditGradeClick = () => {
    if (this.validateGradeForm()) {
      this.saveGradeDetails();
    }
  }

  saveGradeClick = () => {
    const { gradeName, gradeLabel, score, minMarks, maxMarks, gradingList, isEditGrade, selectedGradeIndex } = this.state;
    if (isEditGrade) {
      const mappedGrades = gradingList?.map((item: any, index: number) => {
        if (index === selectedGradeIndex) {
          const data: any = {
            attributes: {
              name: gradeName,
              grade_scale: gradeLabel,
              min_marks: minMarks,
              max_marks: maxMarks,
              point_scale: score,
            }
          }
          if (item?.id) {
            data.id = item.id
          }
          return data
        }
        return item;
      })
      this.setState({ gradingList: mappedGrades, addEditGradeDialog: false })
    } else {
      const item = {
        attributes: {
          name: gradeName,
          grade_scale: gradeLabel,
          min_marks: minMarks,
          max_marks: maxMarks,
          point_scale: score,
        }
      }
      this.setState({ gradingList: [...gradingList, item], addEditGradeDialog: false })
    }
  }

  getGradingList = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    this.setState({ showLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetGradingList = requestMessage.messageId;
    let apiEndPoint = configJSON.getGradingListEndPoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveGradeDetails = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id,
    };

    const { isEditGrade, isEditGradeScale, gradeName, gradeLabel, score, minMarks, maxMarks, selectedGradeId, selectedGradeScaleId } = this.state;

    let finalItems: any = [];

    let gradeData: any = {
      name: gradeLabel.trim(),
      score: score,
      min_marks: Number(minMarks),
      max_marks: Number(maxMarks),
    }
    if (isEditGradeScale) {
      gradeData.id = selectedGradeScaleId
    }
    finalItems = [gradeData]

    const httpBody: any = {
      report_grade: {
        name: gradeName.trim(),
        report_grade_scales_attributes: finalItems
      }
    }

    this.setState({ showLoader: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );

    this.apiSaveGradeDetails = requestMessage.messageId;

    let apiEndPoint = configJSON.saveGradeDetailsEndPoint
    if (isEditGrade) {
      apiEndPoint = apiEndPoint + `/${selectedGradeId}`
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      isEditGrade ? configJSON.PUT : configJSON.POST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openDeleteModal = (isDeleteAllGradeData: boolean, gradeId: number, gradeScaleId?: number) => {
    this.setState({ deleteModal: true, isDeleteAllGradeData: isDeleteAllGradeData, selectedGradeId: gradeId })

    if (!isDeleteAllGradeData) {
      this.setState({ selectedGradeScaleId: gradeScaleId })
    }
  }

  onCloseDeleteModal = () => {
    this.setState({ deleteModal: false, isDeleteAllGradeData: false, selectedGradeId: 0, selectedGradeScaleId: 0 })
  }

  deleteAllGradeData = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteGrade = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveGradeDetailsEndPoint + `/${this.state.selectedGradeId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteGradeScaleData = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      school: school_Data?.school_id,
    };

    const { selectedGradeId, selectedGradeScaleId } = this.state;

    const httpBody: any = {
      report_grade: {
        report_grade_scales_attributes: [
          {
            id: selectedGradeScaleId,
            _destroy: true,
          }
        ]
      }
    }

    this.setState({ showLoader: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );

    this.apiSaveGradeDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveGradeDetailsEndPoint + `/${selectedGradeId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
