// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: any;
  otperror: boolean;
  otperrorText: string;
  loadingSpinner: boolean;
  data: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountSucessFullController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sucessfullApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      otperror: false,
      otperrorText: "",
      otp: null,
      loadingSpinner: false,
      data: "",
    };
    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
    // Customizable Area End
  }

  // Customizable Area Start
  backtologinhandle = () => {
    this.props.navigation.navigate('EmailAccountLoginBlock');
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.sucessfullApiCallId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            localStorage.setItem("token", responseJson.meta.token);
            if (responseJson.data.attributes.type === "ParentAccount") {
              this.props.navigation.navigate("ParentDashboard");
            }
            if (responseJson.data.attributes.type === "StudentAccount") {
              this.props.navigation.navigate("StudentDashboard");
            }
            if (responseJson.data.attributes.type === "AdminAccount") {
              this.props.navigation.navigate("AdminDashboard");
            }
            if (responseJson.data.attributes.type === "TeacherAccount") {
              this.props.navigation.navigate("TeacherDashboard");
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loadingSpinner: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  onBackArrowAccountSucessFull = () => {
    this.props.navigation.navigate("AccountVerify");
  };
  onAccessAccountSucessFull() {
    if (this.state.data.type === "ParentAccount") {
      localStorage.removeItem('account_type')
      this.props.navigation.navigate("ParentDashboard");
    }
    if (this.state.data.type === "StudentAccount") {
      localStorage.removeItem('account_type')
      this.props.navigation.navigate("StudentDashboard");
    }
    if (this.state.data.type === "AdminAccount") {
      localStorage.removeItem('account_type')
      this.props.navigation.navigate("AdminDashboard");
    }
    if (this.state.data.type === "TeacherAccount") {
      localStorage.removeItem('account_type')
      this.props.navigation.navigate("TeacherDashboard");
    }
  }
  // Customizable Area End
}
