// Customizable Area Start
import { Divider, Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { clock, newSchoolIcon } from "../assets";
import { withStyles } from "@material-ui/styles";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import './TestHeader.web.css';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
interface SAS {
    data: any;
    onSubmit: any;
    classes: any;

}
function MyTimer({ expiryTimestamp }: any) {

    const { seconds, minutes, hours, days } = useTimer({
        expiryTimestamp,
        onExpire: () => location.reload()
    });
    return (

        <span style={{
            textTransform: "none",
            fontSize: 18,
            fontFamily: "Open Sans",
            fontWeight: 600,
            color: hours == 0 && minutes < 5 ? 'red' : "#3f526d",
        }}>{` ${hours < 10 ? ` 0${hours}` : hours} : ${minutes < 10 ? `0${minutes}` : minutes} : ${seconds < 10 ? `0${seconds}` : seconds} `}</span>

    );
}

export const TestHeader = (props: { data: any, onSubmit: any, classes: any }) => {
    const time = new Date();
    var time1 = moment().format("DD MMM YYYY HH: mm")


    useEffect(() => {
        if (props.data?.attributes?.end_time !== undefined) {
            let duration = moment.duration(moment(props.data?.attributes?.end_time).diff(time1))
            let second = duration.asSeconds()
            let reaming = Math.ceil(second)
            if (reaming >= 0) {

            }
            else {
                onTimeOut()
            }
        }
    }, [props.data?.attributes?.end_time])
    const onTimeOut = () => {
        props.onSubmit()
    }
    const endDate = moment(props.data?.attributes?.end_time).format("DD MMM YYYY HH: mm")
    const testime = new Date(endDate);
    return (

        <Grid xs={12} item >
            <Grid container className='testHeader_mainContainer'>
                <Grid item xs={12}>
                    <Grid container className='testHeader_mainContainer_SubDiv'>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}
                            className='testHeader_examTitle_Div'>
                            <img src={newSchoolIcon} className='testHeader_examTitle_img' />
                            <span
                                className={`testHeader_examTitle_titleName ${props.classes.heading_font}`}
                            >Exam - {props.data?.attributes?.subject_name?.attributes?.subject_name} [{props.data?.attributes?.term?.title}]</span>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}
                            className='testHeader_exam_Div'>
                            <img src={clock} className='testHeader_examClock_img' />
                            <span
                                className={`testHeader_examClock_title ${props.classes.heading_font}`}
                            >Time Left</span>
                            {props.data?.attributes?.end_time !== undefined
                                &&
                                moment(endDate).isAfter(time1)
                                ?
                                <MyTimer expiryTimestamp={testime} />
                                :
                                <span
                                    className={`testHeader_examClock_subTitle ${props.classes.heading_font}`}
                                >-- : -- : --
                                </span>
                            }
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}
                            className='testHeader_exam_Div'
                        >

                            <span
                                className={`testHeader_examClock_subTitle ${props.classes.heading_font}`}
                            >{`Total marks : ${props.data?.attributes?.maximum_marks === undefined ? 0 : props.data?.attributes?.maximum_marks}`}</span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider className='testHeader_divider' />
                </Grid>
            </Grid>

        </Grid>
    );
}
export default withStyles(themeCreateStyle)(TestHeader);
// Customizable Area End