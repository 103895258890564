// Customizable Area Start
import React from "react";
import {
  Box,
  Modal,
  Divider,
  Typography,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
} from "@material-ui/core";
import {
  restart_alt_black,
  delete_outline_black,
  other_options_icon,
} from "./assets";
import { Close } from "@material-ui/icons";
// Customizable Area End

// Customizable Area Start
const menuIcon = {
  width: "20px",
  height: "20px",
  objectFit: "contain",
} as const;
const previewVideoDiv = {
  fontFamily: "Open sans",
  fontSize: "24px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "center",
  color: "#3f526d",
  marginBottom: "14px",
} as const;
// Customizable Area End

interface Props {
  // Customizable Area Start
  isTeacher: boolean;
  onCloseModal: any;
  openPreviewVideoModal: boolean;
  videoAnchorEl: any;
  openMenu: any;
  handleVideoModalClose: any;
  reUploadClick: any;
  deleteClick: any;
  closeClick: any;
  otherOptionsClick: any;
  videoSrc: any;
  isIdAvailable: boolean;
  // Customizable Area End
}

export const PreviewVideoModal = ({
  // Customizable Area Start
  isTeacher,
  onCloseModal,
  openPreviewVideoModal,
  videoAnchorEl,
  openMenu,
  handleVideoModalClose,
  reUploadClick,
  deleteClick,
  closeClick,
  otherOptionsClick,
  videoSrc,
  isIdAvailable,
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  return (
    <>
      <Modal
        open={openPreviewVideoModal}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="preview_video_modal"
        >
          <Menu
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={videoAnchorEl}
            open={openMenu}
            onClose={handleVideoModalClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Box className="PreviewVideoOptionBox">
              <MenuItem
                className="PreviewVideoOptionItem"
                onClick={reUploadClick}
              >
                <Grid container spacing={1}>
                  <Grid item xs={4} className="PreviewVideoFlexBasis">
                    <Avatar
                      src={restart_alt_black}
                      variant="square"
                      style={menuIcon}
                    />
                  </Grid>
                  <Grid item xs={8} className="PreviewVideoFlexBasis">
                    <Typography className="PreviewVideoActionName">
                      Re Upload
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
              <MenuItem onClick={deleteClick}>
                <Grid container spacing={1}>
                  <Grid item xs={4} className="PreviewVideoFlexBasis">
                    <Avatar
                      src={delete_outline_black}
                      variant="square"
                      style={menuIcon}
                    />
                  </Grid>
                  <Grid item xs={8} className="PreviewVideoFlexBasis">
                    <Typography className="PreviewVideoActionName">
                      Delete
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
              <MenuItem onClick={closeClick}>
                <Grid container spacing={1}>
                  <Grid item xs={4} className="PreviewVideoFlexBasis">
                    <Close className="PreviewVideoClose" />
                  </Grid>
                  <Grid item xs={8} className="PreviewVideoFlexBasis">
                    <Typography className="PreviewVideoActionName">
                      Close
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            </Box>
          </Menu>

          <IconButton
            className="PreviewVideoOptionIcon"
            onClick={otherOptionsClick}
          >
            <img
              src={other_options_icon}
              className="PreviewVideoOptions"          
            />
          </IconButton>
          <Grid className="PreviewVideoGrid">
            <div style={previewVideoDiv} className="preview_video_text">Preview Video</div>
          </Grid>

          <Divider className="PreviewVideoModalDivider" />

          <video height="85%" width="100%" src={videoSrc} controls />
        </Box>
      </Modal>
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
export default PreviewVideoModal;
// Customizable Area End
