// Customizable Area Start
import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import { encryptData } from "../../../../../ClassesAndSections/src/utils/common";

import moment from "moment";

export const configJSON = require("../../../config.js");
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  data: any;
  wardSubjects: Array<any>;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class WardSubjectsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetWardSubjects: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      data: "",
      wardSubjects: [
        {
          id: 1,
          ward_id: 1,
          subject_name: "Mathematics",
          subject_icon: "",
          task_name:
            "Mathematic quiz 1 Mathematic quiz 1 Mathematic quiz 1 Mathematic quiz 1 Mathematic quiz 1 Mathematic quiz 1 Mathematic quiz 1",
          marks: 50,
          teacher: {
            name: "Teacher 1",
            profile_pic: "",
          },
        },
        {
          id: 2,
          ward_id: 1,
          subject_name: "Chemistry",
          subject_icon: "",
          task_name: "Chemistry quiz 1",
          marks: 50,
          teacher: {
            name: "Teacher 2",
            profile_pic: "",
          },
        },
        {
          id: 3,
          ward_id: 2,
          subject_name: "History",
          subject_icon: "",
          task_name: "History quiz 1",
          marks: 50,
          teacher: {
            name: "Teacher 1",
            profile_pic: "",
          },
        },
        {
          id: 4,
          ward_id: 1,
          subject_name: "Biology",
          subject_icon: "",
          task_name: "Biology quiz 1",
          marks: 50,
          teacher: {
            name: "Teacher 1",
            profile_pic: "",
          },
        },
        {
          id: 5,
          ward_id: 2,
          subject_name: "Geography",
          subject_icon: "",
          task_name: "Geography quiz 1",
          marks: 50,
          teacher: {
            name: "Teacher 2",
            profile_pic: "",
          },
        },
      ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.get_userdata = this.get_userdata.bind(this);

    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const zoomMeetingBlock = document.getElementById("zmmtg-root");
    this.get_userdata();
    if (zoomMeetingBlock) zoomMeetingBlock.style.display = "none";
    // Customizable Area End
  }

  // Customizable Area Start
  get_userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    this.setState({ data: data });
  };

  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetWardSubjects) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              // totalClasses: responseJson.total_count,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
}
