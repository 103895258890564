import React from "react";
// Customizable Area Start
import {
    StyleSheet,
    Platform,
} from "react-native";
import { Chart } from "react-google-charts";
import InputBase from "@material-ui/core/InputBase";

import { Grid, Modal, Backdrop, Fade } from '@material-ui/core';
import Header from "../../shared/Header/src/Header";
import SideBar from "../../shared/SideBar/src/SideBar";
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    CircularProgress,
    IconButton,
    Link,
    Select,
    TextField,
    NativeSelect,
    Typography
} from "@material-ui/core";
import {
    Search,
    Replay,
    NotificationsNone,
    HeadsetMicOutlined,
    Title,
    TitleOutlined,
    KeyboardArrowLeft,
    EditOutlined,
    MoreVertOutlined,
    KeyboardArrowDown,
    ArrowDropDown,
    BorderTop,
} from "@material-ui/icons";
import PublicIcon from '@material-ui/icons/Public';
import {
    chat,
    deleteIcon,
    doc,
    download,
    folder,
    goal,
    heart,
    pdf,
    share,
    xls,
} from './assets';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import StudentProtfolioController, { Props } from "./StudentProtfolioController.web";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import Datetime from "react-datetime";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import HOCAuth from "../../shared/Header/src/HOCAuth.web";
// Customizable Area End

// Customizable Area Start
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
// Customizable Area End
class StudentFiles extends StudentProtfolioController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End


    // Customizable Area Start
    render() {
        const deleteMes1 = {
            fontFamily: "Open sans",
            fontSize: "24px",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#3f526d",
            margin: '31px 32px 6px 33px',

        } as const;

        const deleteDiv = {
            fontFamily: "Open sans",
            fontSize: "24px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#3f526d",
            marginBottom: '14px',

        } as const;
        const deleteMes2 = {
            fontFamily: "Open sans",
            fontSize: "14px",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: 'center',
            color: "#959393",
            margin: '6px 53px 40px 54px',

        } as const;
        const cancelButton = {
            margin: '0 20px 0 0',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',
            backgroundImage: 'linear-gradient(to top, #af38cb 101%, #471c51 33%)',
            color: 'white'

        } as const;
        const deleteButton = {
            margin: '0 0px 0 20px',
            padding: '9px 20px 12px 22px',
            borderRadius: '3px',
            backgroundColor: '#f6f6f6',
        } as const;
        const cancelText = {
            fontFamily: "Open sans",
            fontSize: "14px",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center',
            color: "#fff",

        } as const;
        const deleteText = {
            fontFamily: "Open sans",
            fontSize: "14px",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.1px",
            textAlign: 'center'

        } as const;
        const BootstrapInput = withStyles((theme) => ({
            root: {
                "label + &": {
                    marginTop: theme.spacing(1),
                },
            },
            input: {
                borderRadius: 8,
                position: "relative",
                border: "solid 1px #ebebeb",
                backgroundColor: "#fff",
                padding: "13px 22px",
                height: 30,
                transition: theme.transitions.create(["border-color", "box-shadow"]),
                // Use the system font instead of the default Roboto font.
                fontFamily: 'Open sans',
                color: "#000",
                fontWeight: 600,
                fontSize: 18,
                "&:focus": {
                    borderRadius: 4,
                    borderColor: "#80bdff",
                    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
                },
            },
        }))(InputBase);
        return (


            <>

                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Box>
                        <Typography
                            style={{
                                margin: "21px 0px 41px 0",
                                fontSize: 20,
                                color: "#3f526d",
                                fontWeight: 600,
                            }}
                        >
                            Portfolio
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            margin: "19px 0 20px",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: 18,
                                fontWeight: 600,
                                padding: "13px 20px",
                                color: "#3f526d",
                                backgroundColor: "#fff",
                                borderRadius: "8px 0px 0px 8px",
                                cursor: 'pointer'
                            }}
                            className={this.props.classes.border_color}
                            onClick={this.goToTimeline}
                        >
                            Goals & Timeline
                        </Typography>
                        <Typography
                            style={{
                                fontSize: 18,
                                color: "#fff",
                                fontWeight: 600,
                                padding: "13px 20px",
                                borderRadius: "0px 8px 8px 0px",
                                cursor: "pointer"
                            }}
                            className={this.props.classes.button_color}
                            onClick={this.goToFiles}
                        >
                            Files
                        </Typography>
                    </Box>
                </Box>
                <Box
                    style={{
                        marginTop: 20,
                        marginBottom: 25,
                        padding: "20px 19px 50px 20px",
                        borderRadius: 8,
                        boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#fff",
                    }}
                >
                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                        <Box
                            style={{
                                width: "16%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                border: "solid 0.5px #471c51",
                                // padding: "0px 10px 0px 0px",
                                margin: "0 29px 0px 0px",
                                borderRadius: 8,
                                alignItems: "center",
                                cursor: 'pointer',
                                height: 55,
                            }}>
                            <img src={folder} style={{ width: 36, height: 36, margin: "0 10px 0 0" }} />
                            <Typography
                                style={{
                                    fontSize: 20,
                                    color: "#3f526d",
                                    fontWeight: 600,
                                    backgroundColor: "#fff",
                                    borderRadius: "0px 8px 8px 0px",
                                    cursor: 'pointer'
                                }}
                                onClick={() => this.goToResource()}
                            >
                                Add Resource
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "16%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                border: "solid 0.5px #471c51",
                                borderRadius: 8,
                                alignItems: "center",
                                cursor: 'pointer',
                                height: 55,
                            }}
                            onClick={() => this.goToGoals()}
                        >
                            <img src={goal} style={{ width: 36, height: 36, margin: "0 10px 0 0" }} />
                            <Typography
                                style={{
                                    fontSize: 20,
                                    color: "#3f526d",
                                    fontWeight: 600,
                                    backgroundColor: "#fff",
                                    padding: "0px 10px 0px 10px",
                                    borderRadius: "0px 8px 8px 0px",
                                    cursor: 'pointer'
                                }}
                                onClick={() => this.goToGoals()}
                            >
                                New Goal
                            </Typography>
                        </Box>
                    </Box>
                    <Box style={{
                        margin: "27px 0px 26px 0px",

                    }}>
                        <Typography
                            style={{
                                fontSize: 20,
                                color: "#3f526d",
                                fontWeight: 600,
                                backgroundColor: "#fff",
                            }}
                        >
                            Files
                        </Typography>
                    </Box>
                    {this.state.files && this.state.files?.map((_data: any) => {
                        return (

                            _data.attributes.file.data?.map((_file: any) => {
                                return (
                                    <>

                                        <Box style={{
                                            padding: "15px 20px",
                                            borderRadius: 6,
                                            border: "0.3px solid rgb(149 115 157)",

                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            margin: '15px 0'
                                        }}>
                                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0px 20px 0px 0", }}>
                                                {_file.attributes.content_type === "image/jpeg" || _file.attributes.content_type === "image/png" || _file.attributes.content_type === "image/gif" ? <img src={_file.attributes.url} style={{ height: 55, width: '60px' }} /> : _file.attributes.content_type === "application/pdf" ?
                                                    <img src={pdf} style={{ height: 55, }} /> : _file.attributes.content_type === "application/x-ole-storage" || _file.attributes.content_type === "application/msword" || _file.attributes.content_type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? <img src={doc} style={{ height: 55, }} /> : _file.attributes.content_type === "application/vnd.ms-excel" || _file.attributes.content_type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? <img src={xls} style={{ height: 55, }} /> : ''}

                                                <Box style={{ display: "flex", flexDirection: "column", }}>
                                                    <Typography style={{
                                                        fontSize: 20,
                                                        color: "#3f526d",
                                                        fontFamily: "Open sans",
                                                        margin: "0px 20px",
                                                        fontWeight: 600,
                                                        textTransform: 'capitalize'
                                                    }}>
                                                        {_data.attributes.title}
                                                    </Typography>
                                                    <div style={{ display: "flex", flexDirection: "row", margin: "0px 20px 0px 0", }}>
                                                        <Typography style={{
                                                            fontSize: 16,
                                                            color: "#3f526d",
                                                            fontFamily: "Open sans",
                                                            margin: "0px 5px 0px 20px",
                                                            fontWeight: 600
                                                        }}>
                                                            {_file.attributes.file_size > 1048576 ? `${(_file.attributes.file_size / 1048576).toFixed(2)} MB` : `${(_file.attributes.file_size / 1024).toFixed(2)} KB`}
                                                        </Typography>
                                                        <Typography style={{
                                                            fontSize: 16,
                                                            color: "#3f526d",
                                                            fontFamily: "Open sans",
                                                            margin: "0px 5px",
                                                            fontWeight: 'normal'
                                                        }}>
                                                            Uploaded by
                                                            <span style={{ textTransform: 'capitalize' }}>
                                                                {` ${_data.attributes.added_by.data.attributes.first_name} ${_data.attributes.added_by.data.attributes.last_name} `}
                                                            </span>
                                                            {moment.utc(_file.attributes.created_at).local().startOf('seconds').fromNow()}
                                                        </Typography>

                                                    </div>
                                                </Box>
                                            </Box>

                                            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                                                <Box style={{
                                                    margin: "0 10px 0 0px",
                                                }}>
                                                    <IconButton
                                                        aria-label="more"
                                                        aria-controls="long-menu"
                                                        aria-haspopup="true"
                                                        style={{ padding: 0 }}
                                                        onClick={() => this.saveFile(_file.attributes.url, _file.attributes.file_name)}

                                                    >
                                                        {/* <a download={_file.attributes.file_name} href={_file.attributes.url}>  */}
                                                        <img src={download} style={{
                                                            margin: "0px 4px 5px 0",
                                                            width: 22,
                                                            height: 20,
                                                        }} />
                                                        {/* </a>  */}
                                                    </IconButton>
                                                </Box>
                                                <Box style={{
                                                    margin: "0 10px 0 0px",
                                                }}>
                                                    <IconButton
                                                        aria-label="more"
                                                        aria-controls="long-menu"
                                                        aria-haspopup="true"
                                                        style={{ padding: 0 }}
                                                        onClick={() => { console.log(_data), this.setState({ resourceId: _file.id }, () => { this.setState({ deleteResource: true }) }) }}

                                                    >
                                                        {/* <a download={_file.attributes.file_name} href={_file.attributes.url}>  */}
                                                        <img src={deleteIcon} style={{
                                                            margin: "5px 4px 0px 0px",
                                                            width: 22,
                                                            height: 20,
                                                        }} />
                                                        {/* </a>  */}
                                                    </IconButton>
                                                </Box>
                                            </Box>


                                        </Box>
                                    </>)
                            })
                        )
                    })}



                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        open={this.state.deleteResource}
                        onClose={this.onCloseResource}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        disableAutoFocus={true}
                    >
                        <Fade in={this.state.deleteResource} style={{ border: "none" }}>
                            <Box
                                style={{
                                    justifyContent: "center",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: 'white',

                                    borderRadius: "29px",
                                    padding: "32px 18px",
                                    // width: 800,
                                    width: "576px",

                                }}
                            >
                                <div style={deleteDiv}>Delete</div>
                                <div style={{
                                    height: "1px",
                                    margin: "14px 0 31px 0",
                                    border: "solid 0.3px #979797"
                                }}>

                                </div>
                                <div style={deleteMes1}>Are you sure you want to Delete?</div>
                                <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
                                    <Button variant="contained" style={cancelButton} onClick={this.onCloseResource}><span style={cancelText}>Cancel</span></Button>
                                    <Button variant="contained" style={deleteButton} onClick={this.deleteResource}><span style={deleteText}>Yes</span></Button>
                                </Grid>
                            </Box>
                        </Fade>
                    </Modal>



                </Box>
            </>

        )
    }

    // Customizable Area End
}
const ThemeCreateStyle = ((theme: any) => ({
    // Customizable Area Start
    background_color: {
        backgroundColor: theme.props.backgroundColor
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    icon_color: {
        color: theme.props.iconColor
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        border: `1px solid ${theme.props.buttonColor}`,
    },
    border_color: {
        border: `1px solid ${theme.props.backgroundColor}`
    }
    // Customizable Area End
}))

// Customizable Area Start
export default HOCAuth(withStyles(ThemeCreateStyle)(
    StudentFiles as React.ComponentType<any>
), "StudentAccount")
// Customizable Area End
