// Customizable Area Start
export const impProgressTrophy = require("../../assets/object-2-click-to-open.png");
export const impBiologyIcon = require("../../assets/bitmap.png");
export const impChemistryIcon = require("../../assets/chemistry_icon.png");
export const impMathIcon = require("../../assets/maths_icon.png");
export const impEnglishIcon = require("../../assets/english_icon.png");
export const impNewGoalIcon = require("../../assets/my_goal_icon.png");
export const impMyGoalIcon = require("../../assets/new_goal_icon.png");
export const imgProfilePicOutline = require("../../assets/profile_pic_outline.png");
export const impOrangeMeter = require("../../assets/orange_meter.png");
export const imgRedPin = require("../../assets/red_pin.png");
export const impBlackBoardIcon = require("../../assets/blackboard_icon.png");
export const imgZoomIcon = require("../../assets/zoom_icon.png");
export const impBlackBoardDisabledIcon = require("../../assets/blackboard_icon_disabled.png");
export const imgZoomDisabledIcon = require("../../assets/zoom_icon_disabled.png");
export const imgMenuIcon = require("../../assets/menu_icon.png");
export const todo = require("../../assets/to-do-list.png")
export const imgMenu1Icon = require("../../assets/menu_1_icon.png");
export const chatIcon = require("../../assets/group-19.png");
export const ic_calendar = require("../../assets/019-calendar.png");
export const ic_computer = require("../../assets/030-computer.png");
export const ic_pie_chart = require("../../assets/032-pie-chart.png");
export const ic_suitcase = require("../../assets/051-suitcase.png");
export const ic_support = require("../../assets/063-support.png");
export const ic_book = require("../../assets/067-download.png");
export const biology = require("../../assets/biology.png");
export const bitmap = require("../../assets/bitmap.png")
export const bitmap1 = require("../../assets/bitmap1.png")
export const bitmap2 = require("../../assets/bitmap2.png")
export const bitmap3 = require("../../assets/bitmap3.png")
export const mathematics = require("../../assets/mathematics.png")
export const ic_messages = require("../../assets/messages.png")
export const ic_userIcon = require("../../assets/userIcon.png")
export const ic_edit = require("../../assets/group-29.png")
export const ic_back = require("../../assets/group-39.png")
export const ic_homework = require("../../assets/homework-4.png")
export const ic_presentation = require("../../assets/presentation.png")
export const ic_zoom = require("../../assets/zoom-icon.png")
export const ic_clock = require("../../assets/clock-1.png")
export const ic_graduate = require("../../assets/graduate-1.png")
export const ic_oval = require("../../assets/oval.png")
export const logo = require("../../assets/fill-158.png")
export const ic_message = require("../../assets/group-2.png")
export const book = require("../../assets/group-20.png")
export const knowledge = require("../../assets/knowledge.png")
export const img_bell = require("../../assets/image-bell.png")
export const img_englishFlag = require("../../assets/image-englishuk.png")
export const img_font = require("../../assets/image-font.png")
export const img_support = require("../../assets/image-support.png")
export const img_undo = require("../../assets/image-undo.png")
export const menu = require("../../assets/menu.png")
export const note = require("../../assets/note.png")
export const ic_dustbin = require("../../assets/groupdusbin.png")
export const ic_share = require("../../assets/groupshare.png")
export const list = require("../../assets/group-23.png")
export const grid = require("../../assets/group-22.png")
export const image_subject_dustbin = require("../../assets/image-delete-dustbin.png")
export const image_subject_edit = require("../../assets/image-edit-subjects.png")
export const image_biology = require("../../assets/image-biology.png")
export const image_profile = require("../../assets/image-profile.png")
export const ic_pluse = require("../../assets/image-add.png")
export const image_clock = require("../../assets/image-clock.png")
export const disabled_list = require("../../assets/disabledlist.png")
export const active_grid = require("../../assets/activegrid.png")
export const fileupload = require("../../assets/fileupload.png")
export const fileuploaded = require("../../assets/Groupfile done.png")
export const ic_assignee = require("../../assets/assignee.png")
export const ic_minus = require("../../assets/minus.png")
export const image_upload = require("../../assets/image-upload.png")
export const reName = require("../../assets/image-rename@3x.png")
export const move = require("../../assets/image-folder-delete@3x.png")
export const image_file = require("../../assets/image-file.png")
export const file_download = require("../../assets/file_download.png")
export const docICon = require("../../assets/image-docfile.png")
export const xlsIcon = require("../../assets/image-xlsfile.png")
export const fileUploadImage = require("../../assets/fileUploadImage.png")
export const catogarydelete = require("../../assets/catogarydelete.png")
export const shareTaskIcon = require("../../assets/image-share@3x.png")
export const pdf = require("../../assets/image-pdffile@3x.png")
export const jpg = require("../../assets/jpg-icon.png")
export const xls = require("../../assets/image-xlsfile@3x.png")
export const doc = require("../../assets/image-docfile@3x.png")
export const png = require("../../assets/png-icon.png");
export const SUBJECTS_NO_DATA = require("../../assets/learning-curve-no-data.png");
export const cancelIcon = require("../../assets/cancel-black-24-dp.png")
export const previewImageIcon = require("../../assets/open-in-new-black-24-dp.png")
export const viewDetails = require("../../assets/image-viewdetails@3x.png")
// Customizable Area End