// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  postEvent: boolean;
  editPostEvent: boolean;
  spinner: boolean;
  thoughts: any;
  postThought: boolean;
  schoolEvents: any;
  birthdayAndAnniverseries: any;
  postThoughtApiRes: any;
  updateThoughtApiRes: any;
  updateEventApiRes: any;
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}
export default class AdminReminderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getThoughtlist: string = "";
  apigetSchholEventsId: string = "";
  apigetBirthdayAndAnniverseriesId: string = "";
  apisetThoughtsToInactiveId: string = "";
  apicreateEventsId: string = "";
  apiupdateThoughtsId: string = "";
  apiUpdateEvents: string = "";
  postThoughtApi: string = "";
  editThoughtApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      postEvent: false,
      editPostEvent: false,
      spinner: false,
      thoughts: [],
      postThought: false,
      schoolEvents: [],
      birthdayAndAnniverseries: [],
      postThoughtApiRes: "",
      updateThoughtApiRes: "",
      updateEventApiRes:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.getThoughts = this.getThoughts.bind(this);
    this.get_school_events = this.get_school_events.bind(this)
    this.get_birthdayandanniverseries_events = this.get_birthdayandanniverseries_events.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    this.getThoughts();
    this.get_school_events();
    this.get_birthdayandanniverseries_events()
  }

  OpenPostEventModal = () => {
    this.setState({
      postEvent: true,
    });
  };

  closePostEventModal = () => {
    this.setState({
      postEvent: false,
    });
  };

  OpenPostEditEventModal = () => {
    this.setState({
      editPostEvent: true,
    });
  };

  closePostEditEventModal = () => {
    this.setState({
      editPostEvent: false,
    });
  };

  OpenPostThoughtModal = () => {
    this.setState({
      postThought: true,
    });
  };

  closePostThoughtModal = () => {
    this.setState({
      postThought: false,
    });
  };
  appenedUpdateThought = (obj: any) => {
    const arr = this.state.thoughts
    this.setState({
      thoughts: [...arr, obj]
    })
  }
  createThoughtDelete = (obj: any) => {
    const arr = this.state.thoughts
    const remainingArr = arr.filter((data: any) => data.id != obj.id);
    this.setState({
      thoughts: remainingArr
    })
  }

  createEventDelete = (obj: any) => {
    const arr = this.state.schoolEvents
    const remainingArr = arr.filter((data: any) => data.id != obj.id);
    this.setState({
      schoolEvents: remainingArr
    })
  }

  // updateStatEditThough = (array: any) => {
  //   const arr = this.state.thoughts
  //   let index = arr.findIndex((item: any) => item.id === array.id);
  //   arr[index] = array
  //   this.setState({
  //     thoughts: arr
  //   })
  // }

  postThought = (body: any) => {

    this.setState({
      spinner: true
    });
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postThoughtApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getThoughtListEndPoint + `school_id=${data.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const httpBody = body;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateStatEditThough = (body: any, thoughtId: any) => {
    this.setState({
      spinner: true,
    });
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editThoughtApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setThoughtToInactiveEndpoint + `/${thoughtId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const httpBody = body;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  


  updateStatEvent = (array: any) => {
    const arr = this.state.schoolEvents
    let index = arr.findIndex((item: any) => item.id === array.id);
    arr[index] = array
    this.setState({
      schoolEvents: arr
    })
  }

  getThoughts = () => {

    this.setState({
      spinner: true,
    });
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getThoughtlist = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getThoughtListEndPoint + `school_id=${data.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    //console.log("REQUEST: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_school_events = () => {
    const token = localStorage.getItem("token");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token,
      "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetSchholEventsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolEventEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    //console.log("REQUEST: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_birthdayandanniverseries_events = () => {
    const token = localStorage.getItem("token");
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token,
      // "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetBirthdayAndAnniverseriesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolBirthdayAndAnniverseriesEndPoint +
      `?school_id=${data.school_id}
        )}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    //console.log("REQUEST: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  setThoughtToInactive = (id: any) => {
    this.setState({
      spinner: true,
    });
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apisetThoughtsToInactiveId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setThoughtToInactiveEndpoint + `${id}/move_to_inactive?school_id=${data.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );
    //console.log("REQUEST: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  createEvent = (formdata: any, editorText: any) => {
    const intialTime = moment(formdata.StartTime).format('H:mm')
    const startTimeSplit = intialTime.split(':');
    let startTime = moment(formdata.StartDate, "DD-MM-YYYY").add(startTimeSplit[0], 'hours').add(startTimeSplit[1], 'minutes')
    const validFromTime = moment(startTime).format()

    const validToTime = moment(formdata.EndTime).format('H:mm')
    const endTimeSplit = validToTime.split(':');
    let endTime = moment(formdata.EndDate, "DD-MM-YYYY")
      .add(endTimeSplit[0], 'hours')
      .add(endTimeSplit[1], 'minutes')
    const validTo_Time = moment(endTime).format()

    this.setState({
      spinner: true,
    });
    const school_data = localStorage.getItem("user_data");
    const schoolData = JSON.parse(school_data || "{}");

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apicreateEventsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.eventsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //const httpBody = formdata;
    const httpBody = {
      school_id: schoolData.school_id,
      name: formdata.EventName,
      description: editorText,
      start_time: validFromTime,
      end_time: validTo_Time
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    //console.log("REQUEST: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // updateEvent = (eventId: any, form:any, editorText:any) => {
  //   this.setState({
  //     spinner: true,
  //   });
  //   const school_data = localStorage.getItem("user_data");
  //   const schoolData = JSON.parse(school_data || "{}");

  //   const header = {
  //     "Content-Type": configJSON.dashboarContentType,
  //     token: localStorage.getItem("token"),
  //   };
  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );
  //   this.apiUpdateEvents = requestMessage.messageId;
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     configJSON.eventsEndPoint+`/${eventId}`,
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestbaseURLMessage),
  //     configJSON.instituteURL
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );
  //   const httpBody = {
  //       school_id: schoolData.school_id,
  //       name: form.EventName,
  //       description:editorText,
  //       start_time: form.StartDate,
  //       end_time: form.EndDate
  //   }
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     JSON.stringify(httpBody)
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.putAPiMethod
  //   );
  //   //console.log("REQUEST: ", requestMessage);
  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  // }

  updateEvent = (body:any, id:any) => {
    this.setState({
      spinner: true,
    });
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateEvents = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.eventsEndPoint+`/${id}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const httpBody = body;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  updateThought = (id: any) => {

    this.setState({
      spinner: true,
    });
    const school_data = localStorage.getItem("user_data");
    const schoolData = JSON.parse(school_data || "{}");
    // const data = JSON.parse(user_data || "{}");
    const data = {
      "school_id": parseInt(schoolData.school_id),
      // "thought_title": parseInt(this.state.class),
      // "valid_from": parseInt(this.state.batch),
      // "subjects": [example],
      // "valid_to": subjectDataArr,
    }
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiupdateThoughtsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setThoughtToInactiveEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );
    //console.log("REQUEST: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteThought = (formdata: any) => {
    this.setState({
      spinner: true,
    });
    const school_data = localStorage.getItem("user_data");
    const schoolData = JSON.parse(school_data || "{}");
    // const data = JSON.parse(user_data || "{}");
    const data = {
      "school_id": parseInt(schoolData.school_id),
      // "thought_title": parseInt(this.state.class),
      // "valid_from": parseInt(this.state.batch),
      // "subjects": [example],
      // "valid_to": subjectDataArr,
    }
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiupdateThoughtsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setThoughtToInactiveEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );
    //console.log("REQUEST: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // //console.log(apiRequestCallId, "apiRequestCallId");
      // //console.log(responseJson, "responseJson");
      // //console.log(errorReponse, "errorReponse");

      if (apiRequestCallId === this.getThoughtlist) {
        //console.log("ID Match");
        if (responseJson != null) {
          //console.log("JSON NOt NUll");
          if (!responseJson.errors) {
            this.setState({
              thoughts: responseJson.data,
            });
            //console.log(responseJson);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        //console.log("responseJson nulll");
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({
          spinner: false,
        });
      } else if (apiRequestCallId === this.apigetSchholEventsId) {
    
        if (responseJson != null) {
          
          if (!responseJson.errors) {
            this.setState({
              // response_data: responseJson.data,
              schoolEvents: responseJson.data,
            });
            //console.log(responseJson.data);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigetBirthdayAndAnniverseriesId) {
       
        if (responseJson != null) {
         
          if (!responseJson.errors) {
            this.setState({
              
              birthdayAndAnniverseries: responseJson.data,
            });
           
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }

      else if (apiRequestCallId === this.apisetThoughtsToInactiveId) {
        
        if (responseJson != null) {
          
          if (!responseJson.errors) {
            toast.success("Status changed to Inactive");
            this.getThoughts();
            
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
       
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({
          spinner: false,
        });
      }

      else if (apiRequestCallId === this.apicreateEventsId) {
      
        if (responseJson != null) {
          
          if (!responseJson.errors) {
            toast.success("Event Created Successfully");
            //this.get_school_events();
            this.setState({
              schoolEvents: [...this.state.schoolEvents, responseJson.data]
            });
            this.closePostEventModal();
            //console.log(responseJson.data);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({
          spinner: false,
        });
      }

      else if (apiRequestCallId === this.postThoughtApi) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            console.log("responseJson.....", responseJson)
            toast.success("Thoughts Created Successfully");
            this.setState({ postThoughtApiRes: responseJson }, () => {
              this.setState({
                thoughts: [...this.state.thoughts, responseJson?.data]
              })
            })

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({
          spinner: false,
        });
      }

      else if (apiRequestCallId === this.apiUpdateEvents) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            toast.success("Event Updated Successfully");
              this.setState({ updateEventApiRes: responseJson }, () => {
                const arr = this.state.schoolEvents
                let index = arr.findIndex((item: any) => item.id === responseJson?.data?.id);
                arr[index] = responseJson?.data
                this.setState({
                  schoolEvents: arr
                })
              })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({
          spinner: false,
        });
      }

      else if (apiRequestCallId === this.editThoughtApi) {
        if (responseJson != null) {
          if (!responseJson.errors) {

            toast.success("Thought updated Successfully");
            this.setState({ updateThoughtApiRes: responseJson }, () => {
              const arr = this.state.thoughts
              let index = arr.findIndex((item: any) => item.id === responseJson?.data?.id);
              arr[index] = responseJson?.data
              this.setState({
                thoughts: arr
              })
            })

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({
          spinner: false,
        });
      }
    }
  }
  // Customizable Area End
}
