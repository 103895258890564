import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

interface IFileProps {
  // Customizable Area Start
  type: any;
  size?: any;
  lastModifiedDate?: any;
  // Customizable Area End
}

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  enableField: boolean;
  txtInputValue: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class BulkUploadingController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.UploadMediaBlockMessage)];

    this.state = {
      enableField: false,
      txtInputValue: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.UploadMediaBlockMessage)) {
      const data = message.getData(getName(MessageEnum.UploadMediaDataMessage));
      await this.uploadImagesToS3(
        data.responseJson,
        data.imageData,
        data.messageId
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start
  bulkUploadImageMessageId: any;

  uploadImagesToS3 = async (
    element: { url: string; url_fields: any },
    file: any,
    messageId: any
  ) => {
    const header = {
      token: localStorage.getItem("token"),
    };
    let formData = new FormData();
    const uploadURL = element.url.replace(/\/+$/, "");
    const urlFields = element.url_fields;
    if (uploadURL != null && uploadURL !== undefined) {
      const fileKey = urlFields.key.replace("${filename}", file.name);
      formData.append("key", fileKey);
      formData.append("success_action_status", urlFields.success_action_status);
      formData.append("Content-Type", urlFields["Content-Type"]);
      formData.append("Content-Disposition", urlFields["Content-Disposition"]);
      formData.append("policy", urlFields.policy);
      formData.append("x-amz-credential", urlFields["x-amz-credential"]);
      formData.append("x-amz-algorithm", urlFields["x-amz-algorithm"]);
      formData.append("x-amz-date", urlFields["x-amz-date"]);
      formData.append("x-amz-signature", urlFields["x-amz-signature"]);
      formData.append("file", file);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.messageId = messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      uploadURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  uploadImageAsync = async (
    responseJson: any,
    imageData: any,
    messageId: any
  ) => {
    let bulkUploadData: any[] = [];
    await new Promise((resolve, reject) => {
      this.uploadImagesToS3(responseJson, imageData, messageId);
    })
      .then((_res) => {
        bulkUploadData.push(_res);
      })
      .catch((_err) => {
        bulkUploadData.push(_err);
      });

    return bulkUploadData;
  };

  // Customizable Area End
}
