// Customizable Area Start
export const impProgressTrophy = require("../assets/object-2-click-to-open.png");
export const impBiologyIcon = require("../assets/bitmap.png");
export const impChemistryIcon = require("../assets/chemistry_icon.png");
export const impMathIcon = require("../assets/maths_icon.png");
export const impEnglishIcon = require("../assets/english_icon.png");
export const cancelIcon = require("../assets/cancel-black-24-dp.png");
export const uploadFile = require("../assets/upload.png");
export const File = require("../assets/file.png");
// export const ImageCalendar = require("../assets/image-support@3x.png");
export const impNewGoalIcon = require("../assets/my_goal_icon.png");
export const impMyGoalIcon = require("../assets/new_goal_icon.png");
export const imgProfilePicOutline = require("../assets/profile_pic_outline.png");
export const impOrangeMeter = require("../assets/orange_meter.png");
export const imgRedPin = require("../assets/red_pin.png");
export const impBlackBoardIcon = require("../assets/blackboard_icon.png");
export const imgZoomIcon = require("../assets/zoom_icon.png");
export const impBlackBoardDisabledIcon = require("../assets/blackboard_icon_disabled.png");
export const imgZoomDisabledIcon = require("../assets/zoom_icon_disabled.png");
export const imgMenuIcon = require("../assets/menu_icon.png");
export const imgMenu1Icon = require("../assets/menu_1_icon.png");
export const filter = require("../assets/filter.png");
export const ic_calendar = require("../assets/019-calendar.png");
export const ic_computer = require("../assets/active-class.png");
export const ic_pie_chart = require("../assets/032-pie-chart.png");
export const ic_suitcase = require("../assets/051-suitcase.png");
export const ic_support = require("../assets/063-support.png");
export const ic_book = require("../assets/067-download.png");
export const biology = require("../assets/biology.png");
export const bitmap = require("../assets/bitmap.png")
export const bitmap1 = require("../assets/bitmap1.png")
export const bitmap2 = require("../assets/bitmap2.png")
export const bitmap3 = require("../assets/bitmap3.png")
export const mathematics = require("../assets/mathematics.png")
export const ic_messages = require("../assets/messages.png")
export const ic_userIcon = require("../assets/userIcon.png")
export const ic_edit = require("../assets/group-29.png")
export const ic_back = require("../assets/group-39.png")
export const ic_homework = require("../assets/homework-4.png")
export const ic_presentation = require("../assets/presentation.png")
export const ic_zoom = require("../assets/zoom-icon.png")
export const ic_clock = require("../assets/clock-1.png")
export const ic_graduate = require("../assets/graduate-1.png")
export const ic_oval = require("../assets/oval.png")
export const logo = require("../assets/fill-158.png")
export const ic_message = require("../assets/group-2.png")
export const book = require("../assets/group-20.png")
export const knowledge = require("../assets/knowledge.png")
export const note = require("../assets/note.png")
export const menu = require("../assets/disabledhome.png")
export const list = require("../assets/group-23.png")
export const grid = require("../assets/group-22.png")
export const folder = require("../assets/image-folder@3x.png")
export const notebook = require("../assets/notebook-1.png")
export const exam = require("../assets/exam.png")
export const disabled_list = require("../assets/disabledlist.png")
export const active_grid = require("../assets/activegrid.png")
export const addUser = require("../assets/add-user.png")
export const purpleList = require("../assets/image-list-purple.png")
export const whiteGrid = require("../assets/image-grid-white.png")
export const purpleGrid = require("../assets/image-grid.png")
export const whiteList = require("../assets/image-list.png")
export const deleteAlert = require("../assets/deleteIcon.png")
export const SuccessCheckMark = require("../assets/succefullyIcon.png")
export const editDivision = require("../assets/divisionEdit.png")
export const deleteDivision = require("../assets/divisionDelete.png")

export const I_Support = require("../assets/image-support.png")
export const calendar = require("../assets/succefullyIcon.png")
export const fileuploaded = require("../assets/Groupfile done.png")
export const fileuploadedFail = require("../assets/group-5.png")
export const csvIcon = require("../assets/csvIcon.png")
export const cloud = require("../assets/cloud-computing.png")
export const calendar_black = require("../assets/calendar.png");
export const clock = require("../assets/clock.png")
export const autoGenerateIcon = require("../assets/autoGenerateIcon.png")
export const uploadIcon = require("../assets/uploadIcon.png")
export const addMoreSubject = require("../assets/addMoreSubject.png")
export const moreItem = require("../assets/moreItem.png")
export const crossButton = require("../assets/crossButton.png")
export const editiconfeedback = require("../assets/edit-icon-feedback.png")
export const deleteiconfeedback = require("../assets/delete-icon-feedback.png")

export const amazing = require('../assets/amazing.png')
export const amazingSelected = require('../assets/amazing_selected.png')
export const okay = require('../assets/okay.png')
export const okaySelected = require('../assets/okay_selected.png')
export const bad = require('../assets/bad.png')
export const badSelected = require('../assets/Bad_Selected.png')
export const audio = require('../assets/audio.png')
export const teacher = require('../assets/teacher.png')
export const vedio = require('../assets/vedio.png')
export const conectivity = require('../assets/conectivity.png')
export const checkMark = require("../assets/checkmark.png")
export const hint = require("../assets/hint_img.png")


export const gradeBook_Image = require('../assets/grade-book.png')
export const resources_Image = require('../assets/resources.png')
export const subjects_Image = require('../assets/subjects.png')
export const timeTable_Image = require("../assets/time-table.png")
export const member_Image = require("../assets/member.png");
export const subjectIcon = require("../assets/subject-default.png").default
export const bgGroupImg = require("../assets/bg-group-img.png").default
export const addIcon = require("../assets/add-icon.png")
export const imageExport = require("../assets/image-export.png")
export const pdf = require("../assets/image-pdffile@3x.png")
export const jpg = require("../assets/jpg-icon.png")
export const xls = require("../assets/image-xlsfile@3x.png")
export const doc = require("../assets/image-docfile@3x.png")
export const png = require("../assets/png-icon.png");
export const report_icon = require("../assets/page-perspective-matte.png");
export const profile_logo = require("../assets/profile-logo.jpg");
export const profileImg = require("../assets/student-profile.jpg");
// Customizable Area End