// Customizable Area Start
import React from 'react';
import 'react-phone-input-2/lib/style.css';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import 'react-datetime/css/react-datetime.css';
import './OnBoardingSchoolDetails.web.css';
import './TeacherAccountRegistration.css';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  affiliationIcon,
  boardIcon,
  dateIcon,
  location,
  schoolIcon,
} from './assets';
import OnBoardingSchoolDeatilsController, {
  Props,
} from './OnBoardingSchoolDeatilsController.web';
import OnBoardingSideBar from './OnBoardingSideBar.web';
import { NativeSelect, withStyles } from '@material-ui/core';
import Spinner from '../../shared/SideBar/src/Spinner';
import HOCAuth from '../../shared/Header/src/HOCAuth.web';
import { themeCreateStyle } from '../../../components/src/CustomThemeStyles.web';
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles(() => ({
  input: {
    width: '100%',
    textAlign: 'start',
    color: '#03014c',
    fontSize: '15.8px',
    fontFamily: 'Open sans',
  },
  error: {
    border: '1px solid red',
    borderColor: 'red !important',
  },
}))(InputBase);

const BootstrapInput1 = withStyles(() => ({
  input: {
    width: '100%',
    textAlign: 'start',
    color: '#03014c',
    fontSize: '1rem',
    fontFamily: 'Open sans',
    opacity: 0.7,
    border: '1px solid #cfcfcf',
  },
  error: {
    border: '1px solid red',
    opacity: 0.7,
  },
}))(InputBase);

const forrmInput_textfield = {
  width: '100%',
  borderRadius: '4.8px',
  textAlign: 'start',
  color: '#03014c',
  fontSize: '1rem',
  paddingLeft: 0,
} as const;

const textlabel = {
  fontSize: '1rem',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#525252',
} as const;

const textlabel_welcomeText = {
  fontSize: '2.1875rem',
  fontWeight: 'bold' as 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
} as const;
const textlabel_contact = {
  fontSize: '1.5625rem',
  fontWeight: 'bold' as 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  padding: '0 7px',
  position: 'relative',
  marginTop: '-66px',
  marginLeft: '50px',
  backgroundColor: 'white',
} as const;
const selectDiv = {
  borderRadius: '4.2px',
  textAlign: 'start',
  fontSize: '1rem',
  color: '#03014c',
  backgroundColor: '#f1f1f1',
  padding: 0,
  border: '1px solid #cfcfcf',
  opacity: '0.7',
} as const;
const selectErrorDiv = {
  borderRadius: '4.2px',
  textAlign: 'start',
  fontSize: '1rem',
  color: '#03014c',
  backgroundColor: '#f1f1f1',
  padding: 0,
  opacity: '0.7',
  border: '1px solid red',
} as const;
// Customizable Area End

export class OnBoardingSchoolDeatils extends OnBoardingSchoolDeatilsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const name = /^[a-zA-Z ]*$/;
    const affNo = /^[A-Za-z0-9_-]*$/;
    const phoneReg = /^[0-9]+$/;
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const { classes } = this.props;

    return (
      <>
        <Grid container>
          {this.state.loadingSpinner && (
            <Spinner spinnerModal={this.state.loadingSpinner} />
          )}
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <OnBoardingSideBar />
          </Grid>
          <Grid
            item
            sm={9}
            md={9}
            lg={9}
            xs={12}
            style={{
              padding: '2.875rem 1.8125rem',
            }}
          >
            <form onSubmit={this.onSchoolDetailsSubmit} autoComplete="off">
              <Grid
                container
                style={{
                  padding: '0px 1.25rem',
                }}
              >
                <Grid item xs={12}>
                  <div style={textlabel_welcomeText} className={`${this.props.classes.theme_font}`}>Welcome to 21K School</div>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
                  <TextField
                    id="standard-basic"
                    placeholder="School Name*"
                    style={forrmInput_textfield}
                    className={`${this.props.classes.theme_font}`}
                    variant="outlined"
                    value={this.state.schoolName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value.trim();
                      if (value.length === 0) {
                        this.setState({
                          schoolNameError: true,
                          schoolNameErrorMes: ' School name is required.',
                        });
                      } else {
                        this.setState({
                          schoolNameError: false,
                          schoolNameErrorMes: '',
                        });
                      }
                      this.setState({ schoolName: event.target.value });
                    }}
                    onBlur={() => {
                      if (
                        this.state.schoolName === null ||
                        this.state.schoolName.length === 0
                      ) {
                        this.setState({
                          schoolNameError: true,
                          schoolNameErrorMes: ' School name is required.',
                        });
                      } else {
                        this.setState({
                          schoolNameError: false,
                          schoolNameErrorMes: '',
                        });
                      }
                    }}
                    error={this.state.schoolNameError}
                    helperText={this.state.schoolNameErrorMes}
                    inputProps={{
                      style: {
                        fontFamily: 'Open sans',
                        fontSize: '1rem',
                        color: '#03014c',
                        padding: '1.15rem 2.1875rem 1.15rem 0',
                      },
                      className: 'onboarding_text_fields',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ marginRight: '0' }}
                        >
                          <img
                            src={schoolIcon}
                            style={{
                              width: '1.465rem',
                              height: '1.465rem',
                              color: '#7c7ba0',
                              padding: '1.15rem 1.87rem 1.15rem 1.56rem',
                              marginRight: '0px',
                            }}
                          />
                        </InputAdornment>
                      ),
                      style: {
                        fontSize: '1rem',
                        color: '#03014c',
                        fontFamily: 'OpenSans',
                        opacity: 0.7,
                        paddingLeft: 0,
                        backgroundColor: '#f1f1f1',
                      },
                    }}
                  />
                </Grid>
                <Grid container spacing={4} style={{ marginTop: '1rem' }}>
                  <Grid item md={6} xs={12} style={{ paddingTop: 0 }}>
                    <Grid
                      xs={12}
                      style={
                        !this.state.schoolBoardError
                          ? selectDiv
                          : selectErrorDiv
                      }
                      className={`${this.props.classes.theme_font}`}
                    >
                      <NativeSelect
                        id="select"
                        placeholder="Select Board*"
                        style={forrmInput_textfield}
                        className={`${this.props.classes.theme_font}`}
                        variant="outlined"
                        value={this.state.schoolBoard}
                        onChange={(event: any) => {
                          if (event.target.value !== '') {
                            this.setState({ schoolBoardError: false });
                          }
                          this.setState({ schoolBoard: event.target.value });
                        }}
                        onBlur={() => {
                          if (
                            this.state.schoolBoard === '' ||
                            this.state.schoolBoard.length === 0
                          ) {
                            this.setState({ schoolBoardError: true });
                          } else if (this.state.schoolBoard.length !== 0) {
                            this.setState({ schoolBoardError: false });
                          } else {
                            this.setState({ schoolBoardError: false });
                          }
                        }}
                        inputProps={{
                          style: {
                            fontFamily: 'Open sans',
                            fontSize: '1rem',
                            color: '#03014c',
                            padding: '1.15rem 2.1875rem 1.15rem 1.875rem',
                            borderRadius: '5.8px',
                          },
                        }}
                        startAdornment={
                          <InputAdornment
                            position="start"
                            style={{ marginRight: '0', paddingLeft: '0' }}
                          >
                            <img
                              src={boardIcon}
                              style={{
                                width: '1.465rem',
                                height: '1.465rem',
                                color: '#7c7ba0',
                                padding: '0px 0px 0px 1.5625rem',
                                marginRight: '0px',
                              }}
                            />
                          </InputAdornment>
                        }
                        IconComponent={ExpandMoreIcon}
                        input={<BootstrapInput />}
                      >
                        <option value="" disabled>
                          Select Board*
                        </option>
                        {this.state.boardList.map((_data: any) => {
                          return <option value={_data.id}>{_data.name}</option>;
                        })}
                      </NativeSelect>
                    </Grid>
                    {this.state.schoolBoardError && (
                      <FormHelperText
                        style={{ color: 'red', margin: '3px 14px 0' }}
                      >
                        Please select board.
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12} style={{ paddingTop: 0 }}>
                    <TextField
                      id="standard-basic"
                      placeholder="Affiliation Number"
                      style={forrmInput_textfield}
                      className={`${this.props.classes.theme_font}`}
                      variant="outlined"
                      value={this.state.schoolAffiliationNo}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value = event.target.value.trim();
                        if (value.length === 0) {
                          this.setState({
                            schoolAffiliationNoError: true,
                            schoolAffiliationNoErrorMes:
                              ' Affiliation number is required.',
                          });
                        } else {
                          this.setState({
                            schoolAffiliationNoError: false,
                            schoolAffiliationNoErrorMes: '',
                          });
                        }

                        if (
                          event.target.value === '' ||
                          affNo.test(event.target.value)
                        ) {
                          this.setState({
                            schoolAffiliationNo: event.target.value,
                          });
                        }
                      }}
                      inputProps={{
                        style: {
                          fontFamily: 'Open sans',
                          fontSize: '1rem',
                          color: '#03014c',
                          padding: '1.15rem 2.1875rem 1.15rem 0',
                        },
                        className: 'onboarding_text_fields',
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ marginRight: '0' }}
                          >
                            <img
                              src={affiliationIcon}
                              style={{
                                width: '1.465rem',
                                height: '1.465rem',
                                color: '#7c7ba0',
                                padding: '1.15rem 1.87rem 1.15rem 1.56rem',
                                marginRight: '0px',
                              }}
                            />
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: '1rem',
                          color: '#03014c',
                          fontFamily: 'OpenSans',
                          opacity: 0.7,
                          paddingLeft: 0,
                          backgroundColor: '#f1f1f1',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={4}
                  style={{ alignItems: 'center', marginTop: '1rem' }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 0, paddingBottom: 8 }}
                  >
                    <div style={textlabel} className={`${this.props.classes.theme_font}`}>Academic Year</div>
                  </Grid>
                  <Grid item md={6} xs={12} style={{ paddingTop: 0 }}>
                    <Grid
                      xs={12}
                      style={
                        !this.state.schoolStartDateError
                          ? selectDiv
                          : selectErrorDiv
                      }
                      className={`${this.props.classes.theme_font}`}
                    >
                      <NativeSelect
                        id="select"
                        placeholder="Start*"
                        style={forrmInput_textfield}
                        className={`${this.props.classes.theme_font}`}
                        variant="outlined"
                        IconComponent={ExpandMoreIcon}
                        value={this.state.schoolStartDate}
                        onChange={(event: any) => {
                          if (event.target.value !== '') {
                            this.setState({ schoolStartDateError: false });
                          }
                          this.setState({
                            schoolStartDate: event.target.value,
                          });
                        }}
                        onBlur={() => {
                          if (
                            this.state.schoolStartDate === '' ||
                            this.state.schoolStartDate.length === 0
                          ) {
                            this.setState({ schoolStartDateError: true });
                          } else if (this.state.schoolStartDate.length !== 0) {
                            this.setState({ schoolStartDateError: false });
                          } else {
                            this.setState({ schoolStartDateError: false });
                          }
                        }}
                        inputProps={{
                          style: {
                            fontFamily: 'Open sans',
                            fontSize: '1rem',
                            color: '#03014c',
                            padding: '1.15rem 2.1875rem 1.15rem 1.875rem',
                            borderRadius: '5.8px',
                          },
                        }}
                        startAdornment={
                          <InputAdornment
                            position="start"
                            style={{ marginRight: '0' }}
                          >
                            <img
                              src={dateIcon}
                              style={{
                                width: '1.465rem',
                                height: '1.465rem',
                                color: '#7c7ba0',
                                padding: '0px 0px 0px 1.5625rem',
                                marginRight: '0px',
                              }}
                            />
                          </InputAdornment>
                        }
                        input={<BootstrapInput />}
                      >
                        <option
                          value=""
                          style={{ justifyContent: 'center' }}
                          disabled
                        >
                          Start Year*
                        </option>
                        <option
                          value="january"
                          style={{ justifyContent: 'center' }}
                        >
                          January
                        </option>
                        <option
                          value="february"
                          style={{ justifyContent: 'center' }}
                        >
                          February
                        </option>
                        <option
                          value="march"
                          style={{ justifyContent: 'center' }}
                        >
                          March
                        </option>
                        <option
                          value="april"
                          style={{ justifyContent: 'center' }}
                        >
                          April
                        </option>
                        <option
                          value="may"
                          style={{ justifyContent: 'center' }}
                        >
                          May
                        </option>
                        <option
                          value="june"
                          style={{ justifyContent: 'center' }}
                        >
                          June
                        </option>
                        <option
                          value="july"
                          style={{ justifyContent: 'center' }}
                        >
                          July
                        </option>

                        <option
                          value="august"
                          style={{ justifyContent: 'center' }}
                        >
                          August
                        </option>
                        <option
                          value="september"
                          style={{ justifyContent: 'center' }}
                        >
                          September
                        </option>
                        <option
                          value="october"
                          style={{ justifyContent: 'center' }}
                        >
                          October
                        </option>
                        <option
                          value="november"
                          style={{ justifyContent: 'center' }}
                        >
                          November
                        </option>
                        <option
                          value="december"
                          style={{ justifyContent: 'center' }}
                        >
                          December
                        </option>
                      </NativeSelect>
                    </Grid>
                    {this.state.schoolStartDateError && (
                      <FormHelperText
                        style={{ color: 'red', margin: '3px 14px 0' }}
                      >
                        Please select start session.
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12} style={{ paddingTop: 0 }}>
                    <Grid
                      xs={12}
                      style={
                        !this.state.schoolEndDateError
                          ? selectDiv
                          : selectErrorDiv
                      }
                      className={`${this.props.classes.theme_font}`}
                    >
                      <NativeSelect
                        id="select"
                        placeholder="End*"
                        style={forrmInput_textfield}
                        className={`${this.props.classes.theme_font}`}
                        variant="outlined"
                        IconComponent={ExpandMoreIcon}
                        value={this.state.schoolEndDate}
                        onChange={(event: any) => {
                          if (event.target.value !== '') {
                            this.setState({ schoolEndDateError: false });
                          }
                          this.setState({ schoolEndDate: event.target.value });
                        }}
                        onBlur={() => {
                          if (
                            this.state.schoolEndDate === '' ||
                            this.state.schoolEndDate.length === 0
                          ) {
                            this.setState({ schoolEndDateError: true });
                          } else if (this.state.schoolEndDate.length !== 0) {
                            this.setState({ schoolEndDateError: false });
                          } else {
                            this.setState({ schoolEndDateError: false });
                          }
                        }}
                        inputProps={{
                          style: {
                            fontFamily: 'Open sans',
                            fontSize: '1rem',
                            color: '#03014c',
                            padding: '1.15rem 2.1875rem 1.15rem 1.875rem',
                            borderRadius: '5.8px',
                          },
                        }}
                        startAdornment={
                          <InputAdornment
                            position="start"
                            style={{ marginRight: '0' }}
                          >
                            <img
                              src={dateIcon}
                              style={{
                                width: '1.465rem',
                                height: '1.465rem',
                                color: '#7c7ba0',
                                padding: '0px 0px 0px 1.5625rem',
                                marginRight: '0px',
                              }}
                            />
                          </InputAdornment>
                        }
                        input={<BootstrapInput />}
                      >
                        <option
                          value=""
                          style={{ justifyContent: 'center' }}
                          disabled
                        >
                          End Year*
                        </option>
                        <option
                          value="january"
                          style={{ justifyContent: 'center' }}
                        >
                          January
                        </option>
                        <option
                          value="february"
                          style={{ justifyContent: 'center' }}
                        >
                          February
                        </option>
                        <option
                          value="march"
                          style={{ justifyContent: 'center' }}
                        >
                          March
                        </option>
                        <option
                          value="april"
                          style={{ justifyContent: 'center' }}
                        >
                          April
                        </option>
                        <option
                          value="may"
                          style={{ justifyContent: 'center' }}
                        >
                          May
                        </option>
                        <option
                          value="june"
                          style={{ justifyContent: 'center' }}
                        >
                          June
                        </option>
                        <option
                          value="july"
                          style={{ justifyContent: 'center' }}
                        >
                          July
                        </option>

                        <option
                          value="august"
                          style={{ justifyContent: 'center' }}
                        >
                          August
                        </option>
                        <option
                          value="september"
                          style={{ justifyContent: 'center' }}
                        >
                          September
                        </option>
                        <option
                          value="october"
                          style={{ justifyContent: 'center' }}
                        >
                          October
                        </option>
                        <option
                          value="november"
                          style={{ justifyContent: 'center' }}
                        >
                          November
                        </option>
                        <option
                          value="december"
                          style={{ justifyContent: 'center' }}
                        >
                          December
                        </option>
                      </NativeSelect>
                    </Grid>
                    {this.state.schoolEndDateError && (
                      <FormHelperText
                        style={{ color: 'red', margin: '3px 14px 0' }}
                      >
                        Please select end session.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={4}
                  style={{ alignItems: 'center', marginTop: '1rem' }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 0, paddingBottom: 8 }}
                  >
                    <div style={textlabel} className={`${this.props.classes.theme_font}`}>Medium</div>
                  </Grid>
                  <Grid item md={6} xs={12} style={{ paddingTop: 0 }}>
                    <NativeSelect
                      id="select"
                      placeholder="Select Language*"
                      style={forrmInput_textfield}
                      className={`${this.props.classes.theme_font}`}
                      variant="outlined"
                      IconComponent={ExpandMoreIcon}
                      value={this.state.schoolMedium}
                      error={this.state.schoolMediumError}
                      onChange={(event: any) => {
                        if (event.target.value !== '') {
                          this.setState({ schoolMediumError: false });
                        }
                        this.setState({ schoolMedium: event.target.value });
                      }}
                      onBlur={() => {
                        if (
                          this.state.schoolMedium === '' ||
                          this.state.schoolMedium.length === 0
                        ) {
                          this.setState({ schoolMediumError: true });
                        } else if (this.state.schoolMedium.length !== 0) {
                          this.setState({ schoolMediumError: false });
                        } else {
                          this.setState({ schoolMediumError: false });
                        }
                      }}
                      classes={{
                        icon: classes.OnBoardingSchooldeatils_EmailAccountAccountRegistration_Icon,
                      }}
                      inputProps={{
                        style: {
                          fontFamily: 'Open sans',
                          fontSize: '1rem',
                          color: '#03014c',
                          padding: '1.15rem 2.1875rem 1.15rem 1.875rem',
                          backgroundColor: '#f1f1f1',
                        },
                      }}
                      input={<BootstrapInput1 />}
                    >
                      <option value="" disabled>
                        Select Language*
                      </option>
                      {this.state.mediumList.map((_data: any) => {
                        return (
                          <option value={_data.value}>{_data.name}</option>
                        );
                      })}
                    </NativeSelect>
                    {this.state.schoolMediumError && (
                      <FormHelperText
                        style={{ color: 'red', margin: '3px 14px 0' }}
                      >
                        Please select medium.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  marginTop: '2.25rem',
                  border: 'solid 1px #979797',
                  borderRadius: '17px',
                  padding: '47px 27px 40.7px 20px',
                }}
              >
                <span style={textlabel_contact} className={`${this.props.classes.theme_font}`}>Contact Information</span>

                <Grid item xs={12}>
                  <TextField
                    id="standard-basic"
                    placeholder="Address*"
                    style={forrmInput_textfield}
                    className={`${this.props.classes.theme_font}`}
                    variant="outlined"
                    value={this.state.schoolAddress}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value.trim();
                      if (value.length === 0) {
                        this.setState({
                          schoolAddressError: true,
                          schoolAddressErrorMes: ' Address is required.',
                        });
                      } else {
                        this.setState({
                          schoolAddressError: false,
                          schoolAddressErrorMes: '',
                        });
                      }
                      this.setState({ schoolAddress: event.target.value });
                    }}
                    onBlur={() => {
                      if (
                        this.state.schoolAddress === null ||
                        this.state.schoolAddress.length === 0
                      ) {
                        this.setState({
                          schoolAddressError: true,
                          schoolAddressErrorMes: ' Address is required.',
                        });
                      } else {
                        this.setState({
                          schoolAddressError: false,
                          schoolAddressErrorMes: '',
                        });
                      }
                    }}
                    error={this.state.schoolAddressError}
                    helperText={this.state.schoolAddressErrorMes}
                    inputProps={{
                      style: {
                        fontFamily: 'Open sans',
                        fontSize: '1rem',
                        color: '#03014c',
                        padding: '1.15rem 2.1875rem 1.15rem 0',
                      },
                      className: 'onboarding_text_fields',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ marginRight: '0' }}
                        >
                          <img
                            src={location}
                            style={{
                              width: '1.465rem',
                              height: '1.465rem',
                              color: '#7c7ba0',
                              padding: '1.15rem 1.87rem 1.15rem 1.56rem',
                              marginRight: '0px',
                            }}
                          />
                        </InputAdornment>
                      ),
                      style: {
                        fontSize: '1rem',
                        color: '#03014c',
                        fontFamily: 'OpenSans',
                        opacity: 0.7,
                        paddingLeft: 0,
                        backgroundColor: '#f1f1f1',
                      },
                    }}
                  />
                </Grid>
                <Grid container spacing={4} style={{ marginTop: '1rem' }}>
                  <Grid item md={7} xs={12} style={{ paddingTop: 0 }}>
                    <TextField
                      id="standard-basic"
                      placeholder="City*"
                      style={forrmInput_textfield}
                      className={`${this.props.classes.theme_font}`}
                      variant="outlined"
                      value={this.state.schoolCity}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value = event.target.value.trim();
                        if (value.length === 0) {
                          this.setState({
                            schoolCityError: true,
                            schoolCityErrorMes: ' City is required.',
                          });
                        } else {
                          this.setState({
                            schoolCityError: false,
                            schoolCityErrorMes: '',
                          });
                        }
                        if (
                          event.target.value === '' ||
                          name.test(event.target.value)
                        ) {
                          this.setState({ schoolCity: event.target.value });
                        }
                      }}
                      onBlur={() => {
                        if (
                          this.state.schoolCity === null ||
                          this.state.schoolCity.length === 0
                        ) {
                          this.setState({
                            schoolCityError: true,
                            schoolCityErrorMes: ' City is required.',
                          });
                        } else {
                          this.setState({
                            schoolCityError: false,
                            schoolCityErrorMes: '',
                          });
                        }
                      }}
                      error={this.state.schoolCityError}
                      helperText={this.state.schoolCityErrorMes}
                      inputProps={{
                        style: {
                          fontFamily: 'Open sans',
                          fontSize: '1rem',
                          color: '#03014c',
                          padding: '1.15rem 2.1875rem 1.15rem 2.1875rem',
                        },
                        className: 'onboarding_text_fields',
                      }}
                      InputProps={{
                        style: {
                          fontSize: '1rem',
                          color: '#03014c',
                          fontFamily: 'OpenSans',
                          opacity: 0.7,
                          backgroundColor: '#f1f1f1',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={5} xs={12} style={{ paddingTop: 0 }}>
                    <NativeSelect
                      id="select"
                      placeholder="State/Province*"
                      style={forrmInput_textfield}
                      className={`${this.props.classes.theme_font}`}
                      variant="outlined"
                      IconComponent={ExpandMoreIcon}
                      value={this.state.schoolState}
                      onChange={(event: any) => {
                        if (event.target.value !== '') {
                          this.setState({ schoolStateError: false });
                        }
                        this.setState({ schoolState: event.target.value });
                      }}
                      onBlur={() => {
                        if (
                          this.state.schoolState === '' ||
                          this.state.schoolState.length === 0
                        ) {
                          this.setState({ schoolStateError: true });
                        } else if (this.state.schoolState.length !== 0) {
                          this.setState({ schoolStateError: false });
                        } else {
                          this.setState({ schoolStateError: false });
                        }
                      }}
                      inputProps={{
                        style: {
                          fontFamily: 'Open sans',
                          fontSize: '1rem',
                          color: '#03014c',
                          padding: '1.15rem 2.1875rem 1.15rem 1.875rem',
                          backgroundColor: '#f1f1f1',
                        },
                      }}
                      classes={{
                        icon: classes.OnBoardingSchooldeatils_EmailAccountAccountRegistration_Icon,
                      }}
                      error={this.state.schoolStateError}
                      input={<BootstrapInput1 />}
                    >
                      <option value="" disabled>
                        State/Province*
                      </option>
                      {this.state.stateList.map((item: any) => {
                        return (
                          <>
                            <option value={item.value}>{item.name}</option>
                          </>
                        );
                      })}
                    </NativeSelect>

                    {this.state.schoolStateError && (
                      <FormHelperText
                        style={{ color: 'red', margin: '3px 14px 0' }}
                      >
                        Please select state.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={4} style={{ marginTop: '1rem' }}>
                  <Grid item md={7} xs={12} style={{ paddingTop: 0 }}>
                    <NativeSelect
                      id="select"
                      placeholder="Country*"
                      style={forrmInput_textfield}
                      className={`${this.props.classes.theme_font}`}
                      variant="outlined"
                      IconComponent={ExpandMoreIcon}
                      value={this.state.schoolCountry}
                      onChange={(event: any) => {
                        if (event.target.value !== '') {
                          this.setState({ schoolCountryError: false });
                        }
                        this.setState(
                          { schoolCountry: event.target.value },
                          () => {
                            this.setState({ schoolState: '' }),
                              this.getCountryState();
                          }
                        );
                      }}
                      error={this.state.schoolCountryError}
                      onBlur={() => {
                        if (
                          this.state.schoolCountry === '' ||
                          this.state.schoolCountry.length === 0
                        ) {
                          this.setState({ schoolCountryError: true });
                        } else if (this.state.schoolCountry.length !== 0) {
                          this.setState({ schoolCountryError: false });
                        } else {
                          this.setState({ schoolCountryError: false });
                        }
                      }}
                      inputProps={{
                        style: {
                          fontFamily: 'Open sans',
                          fontSize: '1rem',
                          color: '#03014c',
                          padding: '1.15rem 2.1875rem 1.15rem 1.875rem',
                          backgroundColor: '#f1f1f1',
                        },
                      }}
                      classes={{
                        icon: classes.OnBoardingSchooldeatils_EmailAccountAccountRegistration_Icon,
                      }}
                      input={<BootstrapInput1 />}
                    >
                      <option value="" disabled>
                        Country*
                      </option>
                      {this.state.countryList.map((item: any) => {
                        return (
                          <>
                            <option value={item.value}>{item.name}</option>
                          </>
                        );
                      })}
                    </NativeSelect>
                    {this.state.schoolCountryError && (
                      <FormHelperText
                        style={{ color: 'red', margin: '3px 14px 0' }}
                      >
                        Please select country.
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={5} xs={12} style={{ paddingTop: 0 }}>
                    <TextField
                      id="standard-basic"
                      placeholder="Pincode*"
                      style={forrmInput_textfield}
                      className={`${this.props.classes.theme_font}`}
                      variant="outlined"
                      value={this.state.schoolPincode}
                      onKeyPress={(event: any) => {
                        if (event.target.value.length >= 10) {
                          event.preventDefault();
                          event.stopPropagation();
                        }
                      }}
                      onChange={(event: any) => {
                        const value = event.target.value;
                        if (value.length === 0) {
                          this.setState({
                            schoolPincodeError: true,
                            schoolPincodeErrorMes: ' Pincode is required.',
                          });
                        } else {
                          this.setState({
                            schoolPincodeError: false,
                            schoolPincodeErrorMes: '',
                          });
                        }
                        if (event.target.value === '' || phoneReg.test(value)) {
                          this.setState({
                            schoolPincode: event.target.value,
                            schoolPincodeError: false,
                            schoolPincodeErrorMes: '',
                          });
                        } else {
                          this.setState({
                            schoolPincodeError: true,
                            schoolPincodeErrorMes: 'Enter a valid Pincode.',
                          });
                        }
                      }}
                      onBlur={() => {
                        if (
                          this.state.schoolPincode === null ||
                          this.state.schoolPincode.length === 0
                        ) {
                          this.setState({
                            schoolPincodeError: true,
                            schoolPincodeErrorMes: ' Pincode is required.',
                          });
                        } else if (this.state.schoolPincode.length < 4) {
                          this.setState({
                            schoolPincodeError: true,
                            schoolPincodeErrorMes: ' Enter a valid Pincode.',
                          });
                        } else {
                          this.setState({
                            schoolPincodeError: false,
                            schoolPincodeErrorMes: '',
                          });
                        }
                      }}
                      error={this.state.schoolPincodeError}
                      helperText={this.state.schoolPincodeErrorMes}
                      inputProps={{
                        style: {
                          fontFamily: 'Open sans',
                          fontSize: '1rem',
                          color: '#03014c',
                          padding: '1.15rem 2.1875rem 1.15rem 2.1875rem',
                        },
                        className: 'onboarding_text_fields',
                      }}
                      InputProps={{
                        style: {
                          fontSize: '1rem',
                          color: '#03014c',
                          fontFamily: 'OpenSans',
                          opacity: 0.7,
                          backgroundColor: '#f1f1f1',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4} style={{ marginTop: '1rem' }}>
                  <Grid item md={7} xs={12} style={{ paddingTop: 0 }}>
                    <TextField
                      id="standard-basic"
                      placeholder="Phone*"
                      style={forrmInput_textfield}
                      className={`${this.props.classes.theme_font}`}
                      variant="outlined"
                      value={this.state.schoolPhoneNo}
                      onKeyPress={(event: any) => {
                        if (event.target.value.length >= 14) {
                          event.preventDefault();
                          event.stopPropagation();
                        }
                      }}
                      onChange={(event: any) => {
                        const value = event.target.value;
                        if (event.target.value === '' || phoneReg.test(value)) {
                          this.setState({
                            schoolPhoneNo: event.target.value,
                            schoolPhoneNoError: false,
                            schoolPhoneNoErrorMes: '',
                          });
                        } else {
                          this.setState({
                            schoolPhoneNoError: true,
                            schoolPhoneNoErrorMes:
                              'Enter a valid phone number.',
                          });
                        }
                      }}
                      onBlur={() => {
                        if (
                          this.state.schoolPhoneNo === null ||
                          this.state.schoolPhoneNo.length === 0
                        ) {
                          this.setState({
                            schoolPhoneNoError: true,
                            schoolPhoneNoErrorMes: ' Phone number is required.',
                          });
                        } else if (this.state.schoolPhoneNo.length < 7) {
                          this.setState({
                            schoolPhoneNoError: true,
                            schoolPhoneNoErrorMes:
                              ' Enter a valid phone number.',
                          });
                        } else {
                          this.setState({
                            schoolPhoneNoError: false,
                            schoolPhoneNoErrorMes: '',
                          });
                        }
                      }}
                      error={this.state.schoolPhoneNoError}
                      helperText={this.state.schoolPhoneNoErrorMes}
                      inputProps={{
                        style: {
                          fontFamily: 'Open sans',
                          fontSize: '1rem',
                          color: '#03014c',
                          padding: '1.15rem 2.1875rem 1.15rem 0',
                        },
                        className: 'onboarding_text_fields',
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ marginRight: '0' }}
                          >
                            <span
                              style={{
                                fontFamily: 'Open sans',
                                fontSize: '1rem',
                                color: '#03014c',
                                padding: '1.15rem .57rem 1.15rem 1.06rem',
                                marginRight: '0px',
                              }}
                            >
                              +{this.state.counrtyCode}
                            </span>
                          </InputAdornment>
                        ),

                        style: {
                          fontSize: '1rem',
                          color: '#03014c',
                          fontFamily: 'OpenSans',
                          opacity: 0.7,
                          backgroundColor: '#f1f1f1',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={5} xs={12} style={{ paddingTop: 0 }}>
                    <TextField
                      id="standard-basic"
                      placeholder="Landline "
                      style={forrmInput_textfield}
                      className={`${this.props.classes.theme_font}`}
                      variant="outlined"
                      value={this.state.schoolLandlineNo}
                      onKeyPress={(event: any) => {
                        if (event.target.value.length >= 14) {
                          event.preventDefault();
                          event.stopPropagation();
                        }
                      }}
                      onChange={(event: any) => {
                        const value = event.target.value;
                        if (value.length === 0) {
                          this.setState({
                            schoolLandlineNoError: true,
                            schoolLandlineNoErrorMes:
                              ' Landline number is required.',
                          });
                        } else {
                          this.setState({
                            schoolLandlineNoError: false,
                            schoolLandlineNoErrorMes: '',
                          });
                        }
                        if (event.target.value === '' || phoneReg.test(value)) {
                          this.setState({
                            schoolLandlineNo: event.target.value,
                            schoolLandlineNoError: false,
                            schoolLandlineNoErrorMes: '',
                          });
                        } else {
                          this.setState({
                            schoolLandlineNoError: true,
                            schoolLandlineNoErrorMes:
                              'Enter a valid landline number.',
                          });
                        }
                      }}
                      onBlur={() => {
                        if (
                          this.state.schoolLandlineNo === null ||
                          this.state.schoolLandlineNo.length === 0
                        ) {
                          this.setState({
                            schoolLandlineNoError: true,
                            schoolLandlineNoErrorMes:
                              ' Landline number is required.',
                          });
                        } else if (this.state.schoolLandlineNo.length < 7) {
                          this.setState({
                            schoolLandlineNoError: true,
                            schoolLandlineNoErrorMes:
                              ' Enter a valid landline number.',
                          });
                        } else {
                          this.setState({
                            schoolLandlineNoError: false,
                            schoolLandlineNoErrorMes: '',
                          });
                        }
                      }}
                      error={this.state.schoolLandlineNoError}
                      helperText={this.state.schoolLandlineNoErrorMes}
                      inputProps={{
                        style: {
                          fontFamily: 'Open sans',
                          fontSize: '1rem',
                          color: '#03014c',
                          padding: '1.15rem 2.1875rem 1.15rem 2.1875rem',
                        },
                        className: 'onboarding_text_fields',
                      }}
                      InputProps={{
                        style: {
                          fontSize: '1rem',
                          color: '#03014c',
                          fontFamily: 'OpenSans',
                          opacity: 0.7,
                          backgroundColor: '#f1f1f1',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4} style={{ marginTop: '1rem' }}>
                  <Grid item md={7} xs={12} style={{ paddingTop: 0 }}>
                    <TextField
                      id="standard-basic"
                      placeholder="Email*"
                      style={forrmInput_textfield}
                      className={`${this.props.classes.theme_font}`}
                      variant="outlined"
                      value={this.state.schoolEmail}
                      type="email"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value = event.target.value.trim();
                        if (value.length === 0) {
                          this.setState({
                            schoolEmailError: true,
                            schoolEmailErrorMes: 'Email is required.',
                          });
                        } else if (!emailReg.test(value)) {
                          this.setState({
                            schoolEmailError: true,
                            schoolEmailErrorMes: 'Enter a valid Email.',
                          });
                        } else if (emailReg.test(value)) {
                          this.setState({
                            schoolEmailError: false,
                            schoolEmailErrorMes: '',
                          });
                        }
                        this.setState({
                          schoolEmail: event.target.value.trim(),
                        });
                      }}
                      onBlur={() => {
                        if (
                          this.state.schoolEmail === null ||
                          this.state.schoolEmail.length === 0
                        ) {
                          this.setState({
                            schoolEmailError: true,
                            schoolEmailErrorMes: 'Email is required.',
                          });
                        } else if (!emailReg.test(this.state.schoolEmail)) {
                          this.setState({
                            schoolEmailError: true,
                            schoolEmailErrorMes: 'Enter a valid Email.',
                          });
                        } else if (emailReg.test(this.state.schoolEmail)) {
                          this.setState({
                            schoolEmailError: false,
                            schoolEmailErrorMes: '',
                          });
                        } else {
                          this.setState({
                            schoolEmailError: false,
                            schoolEmailErrorMes: '',
                          });
                        }
                      }}
                      error={this.state.schoolEmailError}
                      helperText={this.state.schoolEmailErrorMes}
                      inputProps={{
                        style: {
                          fontFamily: 'Open sans',
                          fontSize: '1rem',
                          color: '#03014c',
                          padding: '1.15rem 2.1875rem 1.15rem 2.1875rem',
                        },
                      }}
                      InputProps={{
                        style: {
                          fontSize: '1rem',
                          color: '#03014c',
                          fontFamily: 'OpenSans',
                          opacity: 0.7,
                          backgroundColor: '#f1f1f1',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  margin: '3.125rem 0px 10px 0px ',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  style={{
                    backgroundImage:
                      'linear-gradient(289deg, #aca2ff 119%, #776be8)',

                    borderRadius: '8px',
                    color: '#fff',
                    width: '20%',
                    padding: '.85rem',
                    textTransform: 'capitalize',
                    fontSize: '1rem',
                  }}
                  type="submit"
                >
                  Next
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(OnBoardingSchoolDeatils, 'SuperAdminAccount')
);
// Customizable Area End
