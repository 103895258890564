Object.defineProperty(exports, "__esModule", {
  value: true,
});

const urlConfig = require("../../../../framework/src/config");
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.GET = "GET";
exports.POST = "POST";
exports.PUT = "PUT";
exports.PATCH = "PATCH";
exports.DELETE = "DELETE";

exports.instituteURL = urlConfig.instituteURL;
exports.examinationUrl = urlConfig.examinationUrl;
exports.resultUrl = urlConfig.resultUrl;

exports.getDraftReportCardListEndPoint = "/bx_block_assessmenttest/report_cards/get_report_cards";
exports.getReportCardListEndPoint = "/";
exports.getGradeEndPoint = "/bx_block_scheduling/grades";
exports.termDetailsEndPoint = "/bx_block_assessmenttest/terms";
exports.termsListEndPoint = "/bx_block_assessmenttest/terms/get_user_terms";
exports.editTermsEndPoint = "/bx_block_assessmenttest/terms/update_terms";
exports.getGradingListEndPoint = "/bx_block_assessmenttest/report_grade";
exports.saveGradeDetailsEndPoint = "/bx_block_assessmenttest/report_grade";
exports.moduleAttributeEndPoints = "/bx_block_assessmenttest/report_attributes";
exports.moduleEndPoint = "/bx_block_assessmenttest/report_modules";
exports.updateModuleEndPoint = "/bx_block_assessmenttest/report_modules/bulk_updates";
exports.getGradesForCategoryEndPoint = "/bx_block_assessmenttest/report_grade/report_grades_for_select";
exports.categoryDetailsEndPoint = "/bx_block_assessmenttest/report_category_types";
exports.editCategoryDetailsEndPoint = "/bx_block_assessmenttest/report_category_types/updates";
exports.getCategoryListEndPoint="bx_block_assessmenttest/report_category_types/get_categories_by_types";
exports.apiPublishReportCardEndPoint="bx_block_assessmenttest/report_cards";
exports.apiGetReportCardEndPoing="bx_block_assessmenttest/report_cards/get_report_cards";
exports.apiGetReportCardBindedGrades="bx_block_assessmenttest/report_cards/get_report_grades";
  // Customizable Area End
