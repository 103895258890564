// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField, IconButton, Divider } from "@material-ui/core";
import { checkFieldLengthForNumber } from "../../../profiles/src/utils/common";
import { toast } from "react-toastify";
import { downloadBtnImg, galleryImg1, videoThumbnail } from "../assets";
import { s3UrltoDataURL, downloadFile } from "../utils/common";
import { withStyles } from "@material-ui/core/styles";
import PreviewVideoModal from "../AddQuestion/PreviewVideoModal.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
interface Props {
  // Customizable Area Start
  questionData: any;
  isTeacher: boolean;
  classes: any;
  // Customizable Area End
}

// Customizable Area Start
const form_input_text_field = {
  width: "100%",
  borderRadius: "6px",
  textAlign: "start",
  fontSize: "20px",
  fontWeight: 600,
} as const;
// Customizable Area End

// Customizable Area Start
export const VideoQuestionType = ({
  questionData,
  isTeacher,
  classes,
}: Props) => {
  const [videosList, setVideosList] = useState<any[]>([]);
  const [openPreviewVideoModal, setOpenPreviewVideoModal] = useState<boolean>(false);
  const [selectedVideoSrc, setSelectedVideoSrc] = useState<string>("");
  const [studentVideosList, setStudentVideosList] = useState<any[]>([]);
  const [marks, setMarks] = useState<any>(questionData?.selected_options?.data?.attributes?.score)

  useEffect(() => {
    let updatedVideosList: any = [];
    questionData?.attachements?.data?.map(
      (item: any) => {
        const videoItem = {
          id: item?.id,
          name: item?.attributes?.file_name,
          size: item?.attributes?.file_size,
          type: item?.attributes?.content_type,
          thumbnail: galleryImg1,
          videoSrc: item?.attributes?.url,
          moduleId: item?.attributes?.module_id,
          duration: item?.attributes?.duration
            ? item?.attributes?.duration
            : "00:00:00",
        };
        updatedVideosList = [...updatedVideosList, videoItem];
      }
    );
    setVideosList(updatedVideosList)

    let updatedStudentVideosList: any = [];
    questionData?.selected_options?.data?.attributes?.attachments?.data?.map(
      (item: any) => {
        const videoItem = {
          id: item?.id,
          name: item?.attributes?.file_name,
          size: item?.attributes?.file_size,
          type: item?.attributes?.content_type,
          thumbnail: galleryImg1,
          videoSrc: item?.attributes?.url,
          moduleId: item?.attributes?.module_id,
          duration: item?.attributes?.duration
            ? item?.attributes?.duration
            : "00:00:00",
        };
        updatedStudentVideosList = [...updatedStudentVideosList, videoItem];
      }
    );
    setStudentVideosList(updatedStudentVideosList)
    if (!questionData?.selected_options?.data) {
      setMarks(0);
    }
  }, []);

  const selectVideo = (video: any) => {
    setSelectedVideoSrc(video?.videoSrc);
    setOpenPreviewVideoModal(true);
  };

  return (
    <>
      <div className="evaluation_details_section">
        {videosList?.map((videoItem: any, index: number) => (
          <React.Fragment key={videoItem?.id}>
            <Grid container>
              <Grid
                item
                xs={11}
                className="video-list-grid"
              >
                <img
                  src={videoThumbnail}
                  className="video-thumbnail-img"
                  alt="thumbnail"
                  onClick={() => selectVideo(videoItem)}
                ></img>
                <div className="video-list-details">
                  <div className={`video-recording-text ${classes.subHeading_font}`}>
                    {videoItem?.name}
                  </div>
                  <div className={`video-recording-duration ${classes.subHeading_font}`}>
                    {videoItem?.duration}
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={1}
                className="video-download-div"
              >
                <IconButton onClick={async () => {
                  toast.info("Please wait file is downloading...")
                  const fileItem = await s3UrltoDataURL(videoItem.videoSrc, videoItem?.name)
                  downloadFile(fileItem, videoItem.name)
                  toast.success("File is downloaded successfully.")
                }} >
                  <img
                    src={downloadBtnImg}
                    className="remove-video-icon"
                  ></img>
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
          </React.Fragment>
        ))}
      </div>
      <Grid container>
        <Typography className={`ans_text ${classes.heading_font}`}>
          Ans :
        </Typography>
        <div className="long_answer_rectangle">
          <Typography className={`long_answer_text ${classes.subHeading_font}`}>{questionData?.selected_options?.data?.attributes?.answer_details}</Typography>
          {questionData?.selected_options?.data?.attributes?.attachments?.data?.length > 0 &&
            <div className="evaluation_details_section">
              {studentVideosList?.map((videoItem: any, index: number) => (
                <React.Fragment key={index}>
                  <Grid container>
                    <Grid
                      item
                      xs={11}
                      className="video-list-grid"
                    >
                      <img
                        src={videoThumbnail}
                        className="video-thumbnail-img"
                        alt="thumbnail"
                        onClick={() => selectVideo(videoItem)}
                      ></img>
                      <div className="video-list-details">
                        <div className={`video-recording-text ${classes.subHeading_font}`}>
                          {videoItem?.name}
                        </div>
                        <div className={`video-recording-duration ${classes.subHeading_font}`}>
                          {videoItem?.duration}
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className="video-download-div"
                    >
                      <IconButton onClick={async () => {
                        toast.info("Please wait file is downloading...")
                        const fileItem = await s3UrltoDataURL(videoItem.videoSrc, videoItem?.name)
                        downloadFile(fileItem, videoItem.name)
                        toast.success("File is downloaded successfully.")
                      }} >
                        <img
                          src={downloadBtnImg}
                          className="remove-video-icon"
                        ></img>
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                </React.Fragment>
              ))}
            </div>
          }
        </div>

        <div className="correct_answer_section">
          <Grid container>
            <Grid item xs={12} sm={6} className="correct_answer_left">
              {!questionData?.selected_options?.data &&
                <Typography className={`not_attemped_text ${classes.bodyText_font}`}>Not attempted</Typography>
              }
            </Grid>
            <Grid item xs={12} sm={6} className="score_section">
              <Typography className={`correct_answer_text ${classes.heading_font}`}>Score</Typography>
              <div className="correct_answer_rectangle">
                {isTeacher ?
                  <TextField
                    placeholder=""
                    type="number"
                    value={marks}
                    style={form_input_text_field}
                    className={classes.input_theme_style}
                    inputProps={{ className: "score_text" }}
                    disabled={!questionData?.selected_options?.data}
                    onChange={(e: any) => {
                      if (Number(e.target.value) > Number(questionData?.marks)) {
                        toast.error('Obtained marks should not greater than total marks')
                      }
                      setMarks(e.target.value)
                      questionData.selected_options.data.attributes.score = e.target.value
                    }}
                    onKeyPress={(event: any) => checkFieldLengthForNumber(event, 3)}
                  />
                  :
                  <Typography className={`score_text ${classes.bodyText_font}`}>
                    {questionData?.selected_options?.data?.attributes?.score ? questionData?.selected_options?.data?.attributes?.score : 0}
                  </Typography>
                }
              </div>
              <Typography className={`score_text ${classes.bodyText_font}`}> / {questionData?.marks}</Typography>
            </Grid>
            {(!isTeacher && questionData?.selected_options?.data?.attributes?.comments) &&
              <Grid item xs={12}>
                <Typography className={`title_teachercomment ${classes.theme_font}`}>Teacher Comment:
                  <span className={`textlabel_teachercomment ${classes.heading_font}`} dangerouslySetInnerHTML={{
                    __html: questionData?.selected_options?.data?.attributes?.comments,
                  }}></span></Typography>
              </Grid>
            }
          </Grid>
        </div>
      </Grid>

      <PreviewVideoModal
        onCloseModal={() => setOpenPreviewVideoModal(false)}
        openPreviewVideoModal={openPreviewVideoModal}
        videoSrc={selectedVideoSrc}
      />
    </>
  );
};
// Customizable Area End

// Customizable Area Start

export default withStyles(themeCreateStyle)(VideoQuestionType);
// Customizable Area End
