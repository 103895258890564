// Customizable Area Start
import React from "react";
import {
  Avatar,
  Box,
  Checkbox,
  Typography,
  Link,
  List,
  ListItemText,
  ListItem,
  ListItemAvatar,
} from "@material-ui/core";
import "./TeacherResources.web.css";
// Customizable Area End

// Customizable Area Start
interface OptionalMiddleName {
  studentsList: any;
  handelShare: any;
  handelQuery: any;
}
// Customizable Area End
// Customizable Area Start
export default function SharewithPeople({
  // Customizable Area Start
  studentsList,
  handelShare,
  handelQuery,
  // Customizable Area End

}: OptionalMiddleName) {
  // Customizable Area Start
  const [checked, setChecked] = React.useState<any[]>([]);
  const [isCheckAll, setIsCheckAll] = React.useState(false);
  const [userData,setUserData]=React.useState<any>(false);

  const handleToggle = (e:any,value: any) => {
    const newChecked = [...checked];
    const currentIndex = checked.indexOf(value);
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    if(newChecked?.length === studentsList?.length){
      setIsCheckAll(true)
    }else{
      setIsCheckAll(false)
    }

  };
  const studentFilterData = () => {
    const studentdata = localStorage.getItem("user_data");
    const userData = JSON.parse(studentdata || "{}");
     setUserData(userData)
    }

  React.useEffect(() => {
    handelShare(checked)
    studentFilterData();
  }, [checked])

  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll);
    setChecked(studentsList.map((li: any) => Number(li.id)));
    if (isCheckAll) {
      setIsCheckAll(false);
      setChecked([]);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  return (
    <>
      {
        studentsList && studentsList.length > 0 ?
          <List className="listPeopleitem">
            {
              studentsList && studentsList.filter((post: any) => {
                if (handelQuery === '') {
                  return post;
                } else if (post.attributes.first_name.toLowerCase().includes(handelQuery.toLowerCase())) {
                  return post;
                }
              }).map((value: any, index: number) => {
                const labelId = `checkbox-list-secondary-label-${value.id}`;
                return (
                  <>
                    {userData && userData.first_name !== value.attributes.first_name &&
                      <ListItem className="listPeople pt-10" key={value.id}>
                        <ListItemAvatar>
                          <Avatar
                            alt={value.attributes.avatar_id}
                            src={value.attributes.avatar}
                          />
                        </ListItemAvatar>
                        <ListItemText id={labelId}>
                          <h5>{`${value.attributes.first_name} ${value.attributes.last_name}`}</h5>
                          <Link className="listEmail" href="#">{value.attributes.email}</Link>
                        </ListItemText>
                        <Checkbox
                          inputProps={{ "aria-label": "controlled" }}
                          onChange={(e) => handleToggle(e, Number(value.id))}
                          checked={checked.indexOf(Number(value.id)) !== -1}
                        />
                      </ListItem>
                    }
                  </>
                );
              })}
          </List>
          :
          <Typography className="norecordText">
            No record(s) found.
          </Typography>
      }
      <Box className="selectAll" display="flex" justifyContent="center" alignItems="center">
        <a className="shareSelectall" role="button" onClick={(event: any) => { handleSelectAll(event) }}>
          Select All
        </a>
      </Box>
    </>
  );
  // Customizable Area End
}
// Customizable Area End