// Customizable Area Start
import React, { useCallback, useEffect, useRef, useState } from 'react'

import Plot from 'react-plotly.js';
import './PlotlyGraph.css';
// Customizable Area End
interface Props {
    // Customizable Area Start
    classes?: any;
    setting: any;
    graph_lines: any;
    is_custom?: boolean;
    // Customizable Area End
}

export const PlotlyGraph = ({
    // Customizable Area Start
    classes,
    setting,
    graph_lines,
    is_custom,
    // Customizable Area End
}: Props) => {
    // Customizable Area Start
    const [lineData, setLineData] = useState<any>([]);
    const [lineAnnotations, setLineAnnotations] = useState<any>([]);
    const [zeroLineAnnotations, setZeroLineAnnotations] = useState<any>([]);
    const [graphAxis, setGraphAxis] = useState<any>([]);

    const getZeroLineAnnotations = (x1:number, y1:number, x2:number, y2:number) => {
        let tempZeroLineAnnotations:any[] = [
            {
                showarrow: true,
                borderwidth: 0.1,
                arrowhead: 2,
                arrowsize: 1,
                axref: "x",
                ayref: "y",
                x: x1-((x1 > x2/2) ? x2*.01 :x2*.05),
                y: 0,
                ax: x2,
                ay: 0
            },
            {
                showarrow: true,
                borderwidth: 0.1,
                arrowhead: 2,
                arrowsize: 1,
                axref: "x",
                ayref: "y",
                x: 0,
                y: y1 - ((y1 > y2/2) ? y2*.02 :y2*.10),
                ax: 0,
                ay: y2
            },
        ];
        if(x2>x1 && x1 > 0){
            tempZeroLineAnnotations[0].showarrow = false;
        }
        if(y2>y1 && y1 > 0){
            tempZeroLineAnnotations[1].showarrow = false;
        }
        if(x2 > x1 && x2 >= 0){
            tempZeroLineAnnotations.push(
                {
                    showarrow: true,
                    borderwidth: 0.1,
                    arrowhead: 2,
                    arrowsize: 1,
                    axref: "x",
                    ayref: "y",
                    x: x2 === 0 ? x2 + 1 : x2,
                    y: 0,
                    ax: x2 === 0 ? x2 -2 : 0,
                    ay: 0
                },
            )
        }

        if(y2 > y1 && y2 >= 0){
            tempZeroLineAnnotations.push(
                {
                    showarrow: true,
                    borderwidth: 0.1,
                    arrowhead: 2,
                    arrowsize: 1,
                    axref: "x",
                    ayref: "y",
                    x: 0,
                    y: y2 === 0 ? y2+1 : y2,
                    ax: 0,
                    ay: y2 === 0 ? y2-2 : 0
                },
            )
        }
        return tempZeroLineAnnotations;
    }
    useEffect(() => {
        const x1 = Number(setting.x_minimum_value);
        const x2 = Number(setting.x_maximum_value);
        const y1 = Number(setting.y_minimum_value);
        const y2 = Number(setting.y_maximum_value);
        setGraphAxis({
            x1: x1,
            x2: x2,
            y1: y1,
            y2: y2,
        });
        setZeroLineAnnotations(getZeroLineAnnotations(x1,y1,x2,y2));
    }, [])

    useEffect(() => {
        const x1 = Number(setting.x_minimum_value);
        const x2 = Number(setting.x_maximum_value);
        const y1 = Number(setting.y_minimum_value);
        const y2 = Number(setting.y_maximum_value);
        setGraphAxis({
            x1: x1,
            x2: x2,
            y1: y1,
            y2: y2,
        });
        setZeroLineAnnotations(getZeroLineAnnotations(x1,y1,x2,y2));
    }, [setting])


    useEffect(() => {
        if (graph_lines.length > 0) {
            let data: any = [];
            let lineAnnotations: any = [];
            graph_lines.forEach((item: any) => {
                let x1 = Number(item.x1);
                let x2 = Number(item.x2);
                let y1 = Number(item.y1);
                let y2 = Number(item.y2);

                // graph lines

                let slop = (y2 - y1) / (x2 - x1);
                let chartMode = 'lines';
                let markers = {};
                if ((slop == 0 || slop == Infinity || isNaN(slop))) {
                    chartMode = 'markers';
                    markers = {
                        color: '#212121',
                        size: 4,
                        line: {
                            color: '#212121',
                            width: 1
                        },
                    }
                }
                data.push({
                    x: [x1, x2],
                    y: [y1, y2],
                    type: 'scatter',
                    mode: chartMode,
                    marker: markers
                });
                // graph lines END

                

                if (slop !== 0 || slop !== Infinity || !isNaN(slop)) {
                    lineAnnotations.push(
                        {
                            showarrow: true,
                            arrowhead: 2,
                            arrowsize: 1,
                            axref: "x",
                            ayref: "y",
                            x: x1,
                            y: y1,
                            ax: x2,
                            ay: y2
                        }
                    )
                    lineAnnotations.push(
                        {
                            showarrow: true,
                            arrowhead: 2,
                            arrowsize: 1,
                            axref: "x",
                            ayref: "y",
                            x: x2,
                            y: y2,
                            ax: x1,
                            ay: y1,
                        }
                    )
                }
                // arrow annotationsEND
            });
            setLineData(data);

            setLineAnnotations(lineAnnotations);
        }else{
            setLineData([]);
            setLineAnnotations([]);
        }

    }, [JSON.stringify(graph_lines)])
    
    return (
        <div className="plotly_graph_main_div">
            <Plot
                data={lineData}
                layout={{
                    xaxis: {
                        range: [graphAxis.x1, graphAxis.x2],
                        title: setting.x_axis_label,
                        ticks: 'outside',
                        ticklen: 20,
                        tickcolor: "#eeeeee",
                        dtick: Number(setting.common_step_size),
                        zeroline: false,
                    },
                    yaxis: {
                        range: [graphAxis.y1, graphAxis.y2],
                        title: setting.y_axis_label,
                        ticks: 'outside',
                        ticklen: 20,
                        tickcolor: "#eeeeee",
                        dtick: Number(setting.common_step_size),
                        zeroline: false,
                    },
                    showlegend: false,
                    annotations: [
                        ...zeroLineAnnotations,
                        ...lineAnnotations,
                    ]
                }}
                config={{
                    staticPlot: true
                }}
                className={is_custom ? "custom-graph" : ""}
            />


        </div >
    )
    // Customizable Area End
}

// Customizable Area Start
export default PlotlyGraph;
// Customizable Area End
