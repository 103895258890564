// Customizable Area Start
import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../../../shared/Header/src/HOCAuth.web";
import ParentLeaveController, {
  Props,
} from "./ParentLeaveController.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import "./ParentLeave.web.css";
import { Button, IconButton, Typography } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class ParentLeave extends ParentLeaveController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { loader } = this.state;
    return (
      <>
        {loader && <Spinner spinnerModal={loader} />}
        <Grid className="notes-parent-box" container>
          <Grid item xs={6} className={`leaves-flex-start`}>
            <Typography className={`${this.props.classes.parentFees_dashboard_font_22} ${this.props.classes.subHeading_font} leaves-text leaves-fw-600`}>
              Leaves
            </Typography>
          </Grid>
          <Grid item xs={6} className={'leaves-flex-end'}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
            >
              <MoreVertIcon />
            </IconButton>
          </Grid>
          <Grid xs={12} className={`leaves-display-flex`}>
              <Button
                fullWidth
                className={`leaves-apply-btn ${this.props.classes.button_color}`}
                variant="contained"
                onClick={() => {
                  // TODO
                }}
              >
                <Typography
                  className={`${this.props.classes.parentFees_dashboard_font_22} leaves-apply-btn-text leaves-fw-600`}
                >
                  Apply For Leave
                </Typography>
              </Button>
          </Grid>
        </Grid>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(ParentLeave, "TeacherAccount")
);
// Customizable Area End
