// Customizable Area Start
import React, { useState } from 'react'
import {
    Modal,
    Backdrop,
    Grid,
    IconButton,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import EditAttributesModal from './EditAttributesModal.web';
import WarningModal from './WarningModal.web';
import './CreateCustomReportCard.web.css';
// Customizable Area End

// Customizable Area Start
const form_input_text_field = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
} as const;

const form_input_text_field_error = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
    border: "1px solid red"
} as const;
// Customizable Area End
export default function ManageAttributeModal(props: any) {
    // Customizable Area Start
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [attributeId, setAttributeId] = useState<number>(-1);
    const [attributeValue, setAttributeValue] = useState<string>('');

    const [openWarningModal, setOpenWarningModal] = useState<boolean>(false);


    const handleClose = () => {
        props?.handleOnClose()
    }

    const handleOnEdit = (id: number, value: string) => {
        props.handleEditAttribute(id, value)
        closeEditModal()
    }

    const handleOpenEditModal = (id: number, value: string) => {
        setAttributeId(id);
        setAttributeValue(value);
        setOpenEditModal(true);
    }

    const closeEditModal = () => {
        setOpenEditModal(false);
    }

    const handleWarningModal = (id:number) => {
        setAttributeId(id);
        setOpenWarningModal(true);
    }

    const handleOnDelete = () => {
        setOpenWarningModal(false);
        props.handleDeleteAttribute(attributeId);
    }

    return (
        <>
            <Modal
                className="ManageAttributesModal"
                open={props.openModal}
                onClose={() => handleClose()}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                data-test-id="manage-attribute-modal"
            >
                <Grid container spacing={0} className="ManageAttributesModalGrid">
                    {/* Close Btn */}
                    <Grid item xs={12} className="ManageAttributesHeader">
                        <div className={`${props.classes.subTitle_font} grade-detail-title EditAttributesText`}>
                            Edit Attributes
                        </div>

                        <IconButton onClick={() => handleClose()} className="closeIcon EditAttributesClose" data-test-id="manage-attribute-close-icon">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    {/* Close Btn END */}

                    <Grid item xs={12} className="ManageAttributesTableGrid">
                        {
                            props.attributeList?.length > 0 &&
                            <TableContainer className="ManageAttributesTableContainer">
                                <Table className="ManageAttributesTable">
                                    <TableHead className="ManageAttributesTableHead">
                                        <TableRow>
                                            <TableCell className={`ManageAttributesLabel ${props.classes.subTitle_font} attribute-table-first-column border-bottom-0`}>Label</TableCell>
                                            <TableCell className={`ManageAttributesAction ${props.classes.subTitle_font} border-bottom-0`}>
                                                Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="ManageAttributesTableBody">
                                        {props.attributeList?.map((data: any, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell className={`ManageAttributesCellLabel ${props.classes.subTitle_font}`}>{data.attributes.name}</TableCell>
                                                <TableCell className={`ManageAttributesCellAction ${props.classes.subTitle_font}`}>
                                                    <IconButton
                                                        disabled={data.attributes.has_bound_topics}
                                                        onClick={() => handleWarningModal(Number(data.id))} 
                                                        className={data.attributes.has_bound_topics ? "coloredIcon ManageAttributesDisabledDeleteIcon" : "coloredIcon ManageAttributesActiveDeleteIcon" }
                                                        data-test-id={`delete-attribute-icon-${index}`}>
                                                        <DeleteOutlineIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleOpenEditModal(Number(data.id), data.attributes.name)} className="coloredIcon ManageAttributesActionEditIcon" data-test-id={`edit-attribute-icon-${index}`}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </Grid>
                </Grid>
            </Modal>
            <EditAttributesModal
                openModal={openEditModal}
                handleOnClose={closeEditModal}
                id={attributeId}
                value={attributeValue}
                handleEdit={handleOnEdit}
                classes={props.classes}
                attributeList={props.attributeList}
            />
            <WarningModal
                title={"Delete Attribute?"}
                subTitle={"Are you sure you want to delete!"}
                paragraph={""}
                warningModal={openWarningModal}
                onCloseModal={() => setOpenWarningModal(false)}
                warningClickOk={handleOnDelete}
            />
        </>
    )
    // Customizable Area End
}
