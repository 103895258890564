// Customizable Area Start
import React from "react";
import {
  Button,
  Typography,
  Tabs,
  Tab,
} from "@material-ui/core";
import CreateCustomReportCardController, { Props } from "./CreateCustomReportCardController.web";
import { withStyles } from "@material-ui/core/styles";
import HOCAuth from "../../../../shared/Header/src/HOCAuth.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import GradingScale from "./GradingScale.web";
import TermDetails from "./TermDetails.web";
import Modules from "./Modules.web";
import CategoryDetails from "./CategoryDetails.web";
import './CreateCustomReportCard.web.css';
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const tabsList = [
  {
    key: 0,
    value: "termsDetails",
    label: "Term Details",
  },
  {
    key: 1,
    value: "gradingScale",
    label: "Grading Scale",
  },
  {
    key: 2,
    value: "modules",
    label: "Modules",
  },
  {
    key: 3,
    value: "category",
    label: "Category",
  },
];
// Customizable Area End

export class CreateCustomReportCard extends CreateCustomReportCardController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { selectedIndex, selectedTab } = this.state;
    return (
      <>
        {this.state.showLoader && <Spinner spinnerModal={this.state.showLoader} />}
        <div>
          <Typography className={`create-report-card-text ${this.props.classes.heading_font}`}>Create Report Card</Typography>
          <div>
            <Tabs
              value={selectedIndex}
              textColor="secondary"
              aria-label="secondary tabs example"
              variant="scrollable"
              scrollButtons="auto"
              className={`${this.props.classes.ReportCardDetails_Feedback_reportCardTabs} report-card-tabs`}
            >
              {tabsList?.map((item, i) => (
                <Tab key={i} label={
                  <Button
                    key={item.key}
                    onClick={() => {
                      this.handleSelectTab(item.value, item.key);
                    }}
                    data-test-id={`create-custom-report-card-tab-${i}`}
                    className={item.key === selectedIndex ? `CreateReportCardTab ${this.props.classes.button_color}` : `CreateReportCardTab ${this.props.classes.button_color_disabled}` }
                  >
                    <Typography
                      className={item.key === selectedIndex ? `CreateReportCardSelectedLabel ${this.props.classes.heading_font}` : `CreateReportCardLabel ${this.props.classes.heading_font}`}
                    >
                      {item.label}
                    </Typography>
                  </Button>
                }>
                </Tab>
              ))}
            </Tabs>
          </div>

          {selectedTab === "termsDetails" &&
            <TermDetails />}

          {selectedTab === "gradingScale" &&
            <GradingScale />}

          {selectedTab === "modules" &&
            <Modules />}

          {selectedTab === "category" &&
            <CategoryDetails />}  

        </div>

      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(themeCreateStyle)(HOCAuth(CreateCustomReportCard as React.ComponentType<any>, "AdminAccount"));
// Customizable Area End