// Customizable Area Start
import moment from 'moment';
import { HISTORY } from "../../../../components/src/comman";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export interface Props {
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  adminGuidelineList: any;
  assessmentId: any;
  checkStartBtn: boolean;
  showLoader: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudentAssessmentGuidelineController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAdminGuidanceList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      adminGuidelineList: [],
      assessmentId: "",
      checkStartBtn: false,
      showLoader: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetAdminGuidanceList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ adminGuidelineList: responseJson.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getAdminGuidelineList();
    const check = this.checkAbleToStartTest()
    this.setState({ assessmentId: HISTORY.location.state?.assessmentId, checkStartBtn: check })
  }

  handleBack = () => {
    HISTORY.goBack();
  };

  agreeStartClick = () => {
    HISTORY.push({
      pathname: "/StudentAssessmentSummary",
      state: { assessmentId: this.state.assessmentId },
    });
  };

  checkAbleToStartTest = () => {
    let ableToStart: boolean = false;

    const timeLimit = HISTORY.location.state?.timeLimit;
    const publishDate = HISTORY.location.state?.publishDate;
    const timeSplit = timeLimit.split(':');
    const endTime = moment(publishDate).add(timeSplit[0], 'hours').add(timeSplit[1], 'minutes')
    const currtime = new Date();
    ableToStart = moment(currtime).isBetween(moment(publishDate), moment(endTime));
    return ableToStart;
  }

  getAdminGuidelineList = () => {
    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      school: school_Data?.school_id,
    };
    this.setState({ showLoader: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAdminGuidanceList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAdminGuidelinesEndPoint + `?assessment_id=${Number(HISTORY.location.state?.assessmentId)}&grade_id=${school_Data.school_account.data[0].attributes.grade_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseUrlAssessment
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
