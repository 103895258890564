// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  data: any;
  teacherClasses: any;
  teacherSubjects: any;
  teacherofStudents: string;
  teacherTimetable: any;
  subjectSearch: any;
  changeview: boolean;
  query: string;
  className: string;
  teacherClass: any;
  class_id: any;
  searchSubjects: any;
  type: string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherSubjectsController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  apigetTeacherSubjectsId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      teacherTimetable: [],
      data: '',
      teacherSubjects: [],
      searchSubjects: [],
      teacherClasses: [],
      teacherofStudents: '',
      changeview: false,
      subjectSearch: false,
      query: '',
      className: '',
      teacherClass: [],
      class_id: '',
      type: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.get_userdata = this.get_userdata.bind(this);
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.get_userdata()
    if (HISTORY.location.state?.type) {
      this.setState({ type: HISTORY.location.state?.type })
    }
  }

  get_userdata = () => {
    const user_data = localStorage.getItem('user_data');
    const data = JSON.parse(user_data || '{}')
    this.setState({ data: data, teacherClass: data?.school_account?.data, className: data?.school_account?.data[0]?.attributes?.class?.display_name, class_id: data.school_account?.data[0]?.attributes?.class?.id });
    this.get_teacher_subjects(data?.school_account?.data[0]?.attributes?.class?.id)
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apigetTeacherSubjectsId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              teacherSubjects: responseJson.data,
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  handleSelectTab = (type: string) => {
    if (type === 'Time Table') {
      HISTORY.push({
        pathname: "/TeacherTimeTable",
        state: {
          type: type,
        },
      });
    }
    else if (type === 'Gradebook') {
      HISTORY.push({
        pathname: "/TeacherGradeBook",
        state: {
          type: type,
        },
      });
    }
    else if (type === 'Resources') {
      HISTORY.push({
        pathname: "/TeacherResources",
        state: {
          type: type,
        },
      });
    }
    else if (type === 'Subjects') {
      HISTORY.push({
        pathname: "/TeacherSubject",
        state: {
          type: type,
        },
      });
    }
    else if (type === 'Task') {
      HISTORY.push({
        pathname: "/TeacherTaskUnit",
        state: {
          type: type,
        },
      });
    } else if (type === 'Members') {
      HISTORY.push({
        pathname: "/TeacherMembers",
        state: {
          type: type,
        },
      });
    }
    else {
      HISTORY.push({
        pathname: "/TeacherAttendance",
        state: {
          type: type,
        },
      });
    }
  }

  handleFilterSearchedSubject = (event:any) => {
    this.setState({ subjectSearch: true, query: event.target.value });
    const filtered = this.state.teacherSubjects.filter((member: any) => {
      if (event.target.value === "") {
        return member;
      } else if (
        member.attributes &&
        member.attributes.subject_name &&
          member.attributes.subject_name
            .toLowerCase()
            .includes(event.target.value.trim()?.toLowerCase())
      ) {
        return member;
      }
    });
    this.setState({ searchSubjects: filtered });
  }

  get_teacher_class_subjects = (value: any) => {
    const { teacherClass } = this.state
    const className = teacherClass?.find((res: any) => (res?.attributes?.class?.id === parseInt(value)))
    this.setState({ className: className?.attributes?.class?.class_name })
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherSubjectsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAssignTeacherSubjectEndPoint + `?class_id=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  get_teacher_subjects = (value?: any) => {
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherSubjectsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAssignTeacherSubjectEndPoint + `?class_id=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
