Object.defineProperty(exports, "__esModule", {
  value: true
});
const urlConfig = require("../../../framework/src/config");
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "bx_block_login/login";
exports.loginAPiOTPEndPoint = "bx_block_login/login/login_otp_confirmation";
exports.loginResendOTPEndPoint ="/bx_block_login/login/resend_otp";
exports.recentActivity = "bx_block_profile/recent_activities";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";
// Customizable Area Start
exports.errorEmailNotValid = "Email is required";
exports.errorPasswordNotValid = "Password not valid.";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.erroremailOTP ="Enter OTP"
exports.erroremailOTPInvalied = "Enter Valied OTP"

exports.userManagementURL = urlConfig.baseURL;
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
// Customizable Area End