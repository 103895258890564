import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as SparkMD5 from "spark-md5";
// Customizable Area Start
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import { boolean } from "yup";
import { FieldArray } from "formik";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: any;
  type?: any;
  RemoveModal: boolean;
  closeModalFun: any;
  callback?: any;
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  closeModal: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RemoveModalController extends BlockComponent<Props, S, SS> {
// Customizable Area Start
  deleteMessageID: string = '';
// Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      closeModal: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }
  modalhandleClose = () => {
    this.setState({ closeModal: true })
    this.props.closeModalFun(!this.state.closeModal)
  }

  deleteGrade = (id: string) => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    const header = {
      "Content-Type": configJSON.gradeContentType,
      token: localStorage.getItem("token"),
      "Access-Control-Allow-Origin": "*",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteMessageID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createGradeApi}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.BASEURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),

      `${configJSON.createGradeApi}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ school_id: data.school_id })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteGradeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));


      if (apiRequestCallId === this.deleteMessageID) {
        toast.success('Deleted successfully.')
        this.modalhandleClose();
        this.props.callback();
      }
    }
  }
  // Customizable Area End
}
