// Customizable Area Start
import React from "react";
import ReactPaginate from "react-paginate";
import {
  Avatar,
  Box,
  Checkbox,
  Button,
  Typography,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Select,
  Grid,
  IconButton,
  Fade,
  Backdrop,
} from "@material-ui/core";
import BorderAllIcon from "@material-ui/icons/BorderAll";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import {
  Search,
  ArrowDropDown,
  MoreVert,
  SearchOutlined
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import UploadResource from "./UploadResource.web";
import "../StudentClassesAndSection.web.css"
import StudentResourcesController, { Props } from "./StudentResourcesController.web";
import SelectedTab from '../SelectedTab.web';
import {
  cloud,
  pdf,
  xls,
  doc,
  share,
  getlink,
  jpg,
  moveto,
  reName,
  viewDetails,
  makeACopy,
  download,
  deleteIcon,
  folder,
  folderlocation,
  png_icon,
  gif_icon,
  closegrey_icon,
  close_icon,
  user_icon
} from "./assets"
import moment from "moment";
import './StudentResources.web.css';
import InputBase from "@material-ui/core/InputBase";
import Spinner from "../../../shared/SideBar/src/Spinner";
import AlertModal from "../../../alert/src/AlertModal.web";
import SharewithPeople from "./SharewithPeople.web";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    height: "33px",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: 'Open sans',
    width: "100%",
    marginBottom: "5px",
    marginTop: "5px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
// Customizable Area End


export class StudentResources extends StudentResourcesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  // Customizable Area Start
  render() {
    const {
      studentResourcetList,
      studentResourceFolders,
      selectAll,
      viewResource,
      viewFolders,
      selectedItem,
      movetoFolderId,
      selectedFolderDetails,
      shareStudentList,
      page,
      resourcesFoldersMeta,
      resourcesFilesMeta,
      activeFolder
    } = this.state;
    const open = Boolean(this.state.anchorEl)
    return (
      <Box flexDirection="column" width="100%" className="studentResourcepart">
        {this.state.showSpinner && (
          <Spinner spinnerModal={this.state.showSpinner} />
        )}
        <Grid container spacing={2} className="gridCenter">
          <Grid item xs={4}>
            <Box>
            <Typography className={`classesButtonText ${this.props.classes.heading_font}`}>
                Classes
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt="20px">
          <SelectedTab isTeacher={false} type={'Resources'} />
        </Box>
        <Box className="topHeaderpanel">
          <div className="outerPanel">
            <Grid item xs={12}>
            <Box className={`gridMain ${this.props.classes.border_Color}`}>
                 <input className="searchFiles"
                  placeholder="Search for file, folder, documents...."
                  value={this.state.queryTerm}
                  onChange={event => this.setState({ queryTerm: event.target.value, selectAll: false, createSelected: [], fileSelected: [] })}
                />
                <Search className={`rightMargin ${this.props.classes.icon_color}`} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className="select-all-outer">
                <Box display="flex" justifyContent="flex-start" alignItems="center">
                  <Checkbox
                    checked={selectAll}
                    onChange={(event: any) => this.handleSelectAll(event)}
                    className={this.props.classes.icon_color}
                  />
                  <Typography className={`selectAlltext ${this.props.classes.title_font}`}>
                    Select All
                  </Typography>
                </Box>
                <Box className="folder-box boxList cloudIconupload" onClick={() => this.handleModalClick("upload")}>
                  <img src={cloud} className={this.props.classes.resources_classAndSection_listIcon} />
                </Box>
                <Box className="folder-box boxList" display="flex" justifyContent="center" alignItems="center">
                  <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => { if (this.state.createSelected.length !== 0 || this.state.fileSelected.length !== 0) { this.setState({ deleteResource: true }) } }}>
                    <img src={deleteIcon} className={this.props.classes.resources_classAndSection_listIcon} />
                  </IconButton>
                </Box>
                <Box className="folder-box" display="flex" justifyContent="center" alignItems="center" >
                  <Button className="folder-block" onClick={() => this.handleModalClick("create")}>
                    <Box display="flex" justifyContent="flex-start" className="avtarIcon">
                      <Avatar src={folder} variant="rounded" className={this.props.classes.resources_classAndSection_listIcon} />
                    </Box>
                    <Typography className={`createFoldername ${this.props.classes.title_font}`}>
                      Create Folder
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </div>
        </Box>
        <Box>
          <Box className="folderBoxouter">
            <Box className="foldersNamebox">
              <Typography className={`folderTitle ${this.props.classes.title_font}`}>
                Folders
              </Typography>
            </Box>
          </Box>
          <Box className="sfolderLists whiteBg">
            <Grid item xs={12}>
              {studentResourceFolders.data?.length > 0 ?
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {studentResourceFolders && studentResourceFolders.data && studentResourceFolders.data
                      .filter((post: any) => {
                        if (this.state.queryTerm === '') {
                          return post;
                        } else if (post.attributes && post.attributes.folder_name && post.attributes.folder_name.toLowerCase().includes(this.state.queryTerm.toLowerCase())) {
                          return post;
                        }
                      }).map((item: any, i: any) => {
                        const isSelected = this.isSelected(item.id);
                        return (
                          <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                            <Button className={`openFolderbg ${selectAll ? 'selectBg' : (activeFolder === item.id) ? 'selectBg' : 'whiteBg'}`} fullWidth={true}
                              onClick={(e) => { this.viewResourceFoldersFiles(e, item) }}>
                              <Checkbox onClick={event => this.handleCheckboxClick(event, item.id)}
                                className={`selectCheckbox ${this.props.classes.icon_color}`}
                                checked={isSelected || selectAll}
                                size="small"
                              />
                              <Box display="flex" justifyContent="center" alignItems="center">
                                <img src={folder} className="listFoldericon" />
                              </Box>
                              <Typography className={`folderTitlename ${this.props.classes.title_font}`}>
                                {item.attributes.folder_name}
                              </Typography>
                              <Box className="openText" onClick={(e) => {
                                this.handleClick(e, 'folder', item.id);
                                this.openedBy('folder', item.id);
                              }}>
                                <MoreVert className="cursor" />
                              </Box>
                            </Button>
                          </Grid>
                        )
                      })}
                    <ReactPaginate
                      previousLabel={"← Previous"}
                      nextLabel={"Next →"}
                      breakLabel="..."
                      initialPage={page}
                      pageCount={resourcesFoldersMeta?.pagination?.total_pages}
                      onPageChange={(e: any) => {
                        if (e.selected !== this.state.page) {
                          this.setState({ page: e.selected }, () => this.getStudentResourceFolders())
                        }
                      }}
                      containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                      previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                      nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                      disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                      activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={10}
                    />
                  </Grid>
                </Grid>
                : <Typography className={`norecordText ${this.props.classes.title_font}`}>
                  No record(s) found. </Typography>
                }
            </Grid>
          </Box>
          <Box>
            <Box className="folderBoxouter">
              <Box className="foldersNamebox">
                <Typography className={`folderTitle ${this.props.classes.title_font}`}>
                  Files
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" pt="10px" pb="10px">
                <Box display="flex" justifyContent="flex-end">
                  <FormatListBulletedIcon
                    className={`ListIcon ${!this.state.changeview
                      ? this.props.classes.resources_classAndSection_active_button_color
                      : this.props.classes.resources_classAndSection_normal_button_color
                      }`}
                    onClick={() => {
                      this.setState({ changeview: false });
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <BorderAllIcon
                    className={`ListIcon ${this.state.changeview
                      ? this.props.classes.resources_classAndSection_active_button_color
                      : this.props.classes.resources_classAndSection_normal_button_color
                      }`}
                    onClick={() => {
                      this.setState({ changeview: true });
                    }}
                  />
                </Box>
              </Box>
            </Box>
            {studentResourcetList &&
              studentResourcetList.data &&
              studentResourcetList &&
              studentResourcetList.data.length !== 0 ? (
              !this.state.changeview ? (
                <Grid className="margin10" item xs={12}>
                  <Grid container spacing={2}>
                    {studentResourcetList &&
                      studentResourcetList.data &&
                      studentResourcetList.data
                        .filter((post: any) => {
                          if (this.state.queryTerm === "") {
                            return post;
                          } else if (
                            post.attributes &&
                            post.attributes.file_name &&
                            post.attributes.file_name
                              .toLowerCase()
                              .includes(this.state.queryTerm.toLowerCase())
                          ) {
                            return post;
                          }
                        })
                        .map((item: any, i: any) => {
                          const isSelectedFile = this.isFileSelected(item.id);
                          return (
                            <Grid className={`checkouter ${selectAll ? 'selectBg' : 'whiteBg'}`}
                              key={i} container justify="space-between" alignItems="center">
                              <Grid className="displayItem" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box
                                  onClick={(e) => {
                                    this.handleClick(e, "file", item.id);
                                    this.openedBy("file", item.id);
                                  }}
                                >
                                  <Checkbox
                                    onClick={(event) =>
                                      this.handleCheckboxClickFile(
                                        event,
                                        item.id
                                      )
                                    }
                                    onChange={() => this.handleSelect(item.id)}
                                    size="small"
                                    checked={isSelectedFile || selectAll}
                                    className={
                                      this.props.classes.icon_color
                                    }
                                  />
                                </Box>
                                <Box m="5px" display="flex" justifyContent="flex-start">
                                  {item.attributes.file_and_type.file.data[0]
                                    ?.attributes?.content_type ===
                                    "image/jpeg" ? (
                                    <img src={jpg} className="h-55" />
                                  ) : item.attributes.file_and_type.file.data[0]
                                    ?.attributes?.content_type ===
                                    "image/png" ? (
                                    <img className="h-55" 
                                      src={png_icon}/>
                                  ) : item.attributes.file_and_type.file.data[0]
                                    ?.attributes?.content_type ===
                                    "image/gif" ? (
                                    <img className="h-55"
                                      src={gif_icon}
                                    />
                                  ) : item.attributes.file_and_type.file.data[0]
                                    ?.attributes?.content_type ===
                                    "application/pdf" ? (
                                    <img src={pdf} className="h-55" />
                                  ) : item.attributes.file_and_type.file.data[0]
                                    ?.attributes?.content_type ===
                                    "application/msword" ||
                                    item.attributes.file_and_type.file.data[0]
                                      ?.attributes?.content_type ===
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                    <img src={doc} className="h-55" />
                                  ) : item.attributes.file_and_type.file.data[0]
                                    ?.attributes?.content_type ===
                                    "application/vnd.ms-excel" ||
                                    item.attributes.file_and_type.file.data[0]
                                      ?.attributes?.content_type ===
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                                    <img src={xls} className="h-55" />
                                  ) : (
                                    ""
                                  )}
                                </Box>
                                <p className={`fileNameText1 ${this.props.classes.bodyText_font}`}>
                                  {item.attributes.file_and_type.file.data[0]?.attributes?.file_name}
                                </p>
                              </Grid>

                              <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
                                <Typography className={`sizeText ${this.props.classes.bodyText_font}`}>
                                  {item.attributes.file_and_type.file.data[0]
                                    ?.attributes?.file_size > 1048576
                                    ? `${(
                                      item.attributes.file_and_type.file
                                        .data[0]?.attributes?.file_size /
                                      1048576
                                    ).toFixed(2)} MB`
                                    : `${(
                                      item.attributes.file_and_type.file
                                        .data[0]?.attributes?.file_size / 1024
                                    ).toFixed(2)} KB`}
                                </Typography>
                              </Grid>
                              <Grid className="flexbasis" xs={12} sm={12} md={3} lg={3} xl={3}>
                                <Box>
                                  <Typography className={`uploadText ${this.props.classes.bodyText_font}`}>
                                    Uploaded by{" "}
                                    {`${item.attributes &&
                                      item.attributes.owner.attributes
                                        .first_name
                                      }  ${" "} ${item.attributes &&
                                      item.attributes.owner.attributes.last_name
                                      }`}
                                  </Typography>
                                  <Typography className={`uploadText ${this.props.classes.bodyText_font}`}
                                  >
                                    {moment(item.attributes.updated_at).format(
                                      "DD MMM, yyyy"
                                    )}
                                  </Typography>
                                </Box>
                                <IconButton
                                  onClick={(e) => {
                                    this.handleClick(e, "file", item.id);
                                    this.openedBy("file", item.id);
                                  }}
                                >
                                  <MoreVert className="cursor"/>
                                </IconButton>
                              </Grid>
                            </Grid>
                          );
                        })}

                    {studentResourcetList &&
                      studentResourcetList.data?.length !== 0 ? (
                      <ReactPaginate
                        previousLabel={"← Previous"}
                        nextLabel={"Next →"}
                        breakLabel="..."
                        initialPage={page}
                        pageCount={resourcesFilesMeta?.pagination?.total_pages}
                        onPageChange={(e: any) => {
                          if (e.selected !== this.state.page) {
                            this.setState({ page: e.selected }, () =>
                              this.getStudentResource()
                            );
                          }
                        }}
                        containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                        previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                        nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                        disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                        activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={10}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {studentResourcetList &&
                      studentResourcetList.data &&
                      studentResourcetList.data
                        .filter((post: any) => {
                          if (this.state.queryTerm === "") {
                            return post;
                          } else if (
                            post.attributes &&
                            post.attributes.file_name &&
                            post.attributes.file_name
                              .toLowerCase()
                              .includes(this.state.queryTerm.toLowerCase())
                          ) {
                            return post;
                          }
                        })
                        .map((item: any, i: any) => {
                          const isSelectedFile = this.isFileSelected(item.id);
                          return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                              <Box className={`boxShadow ${selectAll ? 'selectBg' : 'whiteBg'}`}>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                  width="100%"
                                >
                                  <Box m="5px" display="flex" justifyContent="flex-start">
                                    {item.attributes.file_and_type.file.data[0]
                                      ?.attributes?.content_type ===
                                      "image/jpeg" ? (
                                      <img src={jpg} className="h-55" />
                                    ) : item.attributes.file_and_type.file
                                      .data[0]?.attributes?.content_type ===
                                      "image/png" ? (
                                      <img className="h-55"
                                        src={png_icon}
                                      />
                                    ) : item.attributes.file_and_type.file
                                      .data[0]?.attributes?.content_type ===
                                      "image/gif" ? (
                                      <img className="h-55"
                                        src={gif_icon}
                                      />
                                    ) : item.attributes.file_and_type.file
                                      .data[0]?.attributes?.content_type ===
                                      "application/pdf" ? (
                                      <img src={pdf} className="h-55" />
                                    ) : item.attributes.file_and_type.file
                                      .data[0]?.attributes?.content_type ===
                                      "application/msword" ||
                                      item.attributes.file_and_type.file.data[0]
                                        ?.attributes?.content_type ===
                                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                      <img src={doc} className="h-55" />
                                    ) : item.attributes.file_and_type.file
                                      .data[0]?.attributes?.content_type ===
                                      "application/vnd.ms-excel" ||
                                      item.attributes.file_and_type.file.data[0]
                                        ?.attributes?.content_type ===
                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                                      <img src={xls} className="h-55" />
                                    ) : (
                                      ""
                                    )}{" "}
                                  </Box>
                                  <Box className="outerBorder"
                                    onClick={(e) => {
                                      this.handleClick(e, "file", item.id);
                                      this.openedBy("file", item.id);
                                    }}
                                  >
                                    <MoreVert className="cursor"/>
                                  </Box>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                  width="100%"
                                  height="50%"
                                  mb="5%"
                                  m="5px"
                                >
                                  <p className={`fileNameText1 ${this.props.classes.bodyText_font}`}>
                                    {item.attributes.file_and_type.file.data[0]?.attributes?.file_name}
                                  </p>
                                  <Typography className={`sizeText ${this.props.classes.bodyText_font}`}>
                                    {item.attributes.file_and_type.file.data[0]
                                      ?.attributes?.file_size > 1048576
                                      ? `${(
                                        item.attributes.file_and_type.file
                                          .data[0]?.attributes?.file_size /
                                        1048576
                                      ).toFixed(2)} MB`
                                      : `${(
                                        item.attributes.file_and_type.file
                                          .data[0]?.attributes?.file_size /
                                        1024
                                      ).toFixed(2)} KB`}
                                  </Typography>
                                  <Typography className={`uploadText ${this.props.classes.bodyText_font}`}>
                                    Uploaded by{" "}
                                    {`${item.attributes &&
                                      item.attributes.owner.attributes
                                        .first_name
                                      }  ${" "} ${item.attributes &&
                                      item.attributes.owner.attributes.last_name
                                      }`}
                                  </Typography>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%"
                                  >
                                    <Typography
                                      className={`uploadText ${this.props.classes.bodyText_font}`}>
                                      {moment(
                                        item.attributes.updated_at
                                      ).format("DD MMM, yyyy")}
                                    </Typography>
                                    <Checkbox
                                      onClick={(event) =>
                                        this.handleCheckboxClickFile(
                                          event,
                                          item.id
                                        )
                                      }
                                      onChange={() =>
                                        this.handleSelect(item.id)
                                      }
                                      size="small"
                                      checked={isSelectedFile || selectAll}
                                      className={
                                        this.props.classes.icon_color
                                      }
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          );
                        })}

                    {studentResourcetList &&
                      studentResourcetList.data?.length !== 0 ? (
                      <ReactPaginate
                        previousLabel={"← Previous"}
                        nextLabel={"Next →"}
                        breakLabel="..."
                        initialPage={page}
                        pageCount={resourcesFilesMeta?.pagination?.total_pages}
                        onPageChange={(e: any) => {
                          if (e.selected !== this.state.page) {
                            this.setState({ page: e.selected }, () =>
                              this.getStudentResource()
                            );
                          }
                        }}
                        containerClassName={`${this.props.classes.Pagination_PaginationBttns}`}
                        previousLinkClassName={`${this.props.classes.Pagination_PreviousBttn}`}
                        nextLinkClassName={`${this.props.classes.Pagination_NextBttn}`}
                        disabledClassName={`${this.props.classes.Pagination_PaginationDisabled}`}
                        activeClassName={`${this.props.classes.Pagination_PaginationActive}`}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={10}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              )
            ) : (
              <Typography className={`norecordText ${this.props.classes.title_font}`}>
                No record(s) found.{" "}
              </Typography>
            )}
          </Box>
        </Box>
        <Modal
          className="deletePopup"
          open={this.state.deleteResource}
          onClose={this.onCloseResource}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={this.state.deleteResource}>
            <Box className="commonPopup">
              <Avatar
                onClick={this.onCloseResource}
                src={closegrey_icon}
                className="closeIcon"
              />
              <Box className={`titleMain textCenter ${this.props.classes.title_font}`} >
                Delete
              </Box>
              <Box className={`viewBody textCenter ${this.props.classes.bodyText_font}`}>
                Are you sure you want to Delete?
              </Box>
              <Box className="bottomButton">
                <Button className={`purpleButton ${this.props.classes.button_color}`}
                  onClick={this.state.selectedId !== "" ? this.deleteStudentResource : this.handledeleteSelected}
                >
                  Yes
                </Button>
                <Button className="greyButton" onClick={this.onCloseResource}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <Menu
          id="basic-menu"
          anchorEl={this.state.anchorEl}
          open={open}
          onClose={this.handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Box className="w-203">
            <MenuItem className="itemDisplay" onClick={() => {this.handleModalClick("share"); this.handleClose()}}>
              <Grid container spacing={1}>
                <Grid item>
                  <Avatar src={share} variant="square" className="menuIcon" />
                </Grid>
                <Grid item xs={8} className="flexBase">
                  <Typography className={`menuText ${this.props.classes.bodyText_font}`}>
                    Share
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem onClick={() => {this.handleModalClick("getLink"); this.handleClose()}} disabled={selectedItem === 'folder'}>
              <Grid container spacing={1}>
                <Grid item className="getlinkIcon">
                  <img src={getlink} className="menuIcon" />
                </Grid>
                <Grid item xs={8} className="flexBase">
                  <Typography className={`menuText ${this.props.classes.bodyText_font}`}>
                    Get link
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem disabled onClick={this.handleClose}>
              <Grid container spacing={1}>
                <Grid item className="getlinkIcon">
                  <img src={folderlocation} className="menuIcon" />
                </Grid>
                <Grid className="flexBase" item xs={8}>
                  <Typography className={`menuText ${this.props.classes.bodyText_font}`}>
                    Folder location
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem onClick={() => {this.handleModalClick("moveto"); this.handleClose()}} disabled={selectedItem === 'folder'}>
              <Grid container spacing={1}>
                <Grid item className="flexBase">
                  <Avatar src={moveto} variant="square" className="menuIcon" />
                </Grid>
                <Grid item xs={8} className="flexBase">
                  <Typography className={`menuText ${this.props.classes.bodyText_font}`}>
                    Move to
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem onClick={() => {
              this.handleModalClick("rename");
              this.setState({ folderName: viewFolders.data.attributes.folder_name });
              this.handleClose()
            }}>
              <Grid container spacing={1}>
                <Grid item className="flexBase">
                  <Avatar src={reName} variant="square" className="menuIcon" />
                </Grid>
                <Grid item xs={8} className="flexBase">
                  <Typography className={`menuText ${this.props.classes.bodyText_font}`}>
                    Rename
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem onClick={(e) => { this.handleviewDetailsClick(e, viewFolders); this.handleModalClick("viewdetails");  this.handleClose()}}>
              <Grid container spacing={1}>
                <Grid item className="flexBase">
                  <Avatar src={viewDetails} variant="square" className="menuIcon" />
                </Grid>
                <Grid item xs={8} className="flexBase">
                  <Typography className={`menuText ${this.props.classes.bodyText_font}`}>
                    View Details
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem onClick={()=>{this.makeACopyStudentResourceUploads; this.handleClose()}} disabled={selectedItem === 'folder'}>
              <Grid container spacing={1}>
                <Grid item className="flexBase">
                  <Avatar src={makeACopy} variant="square" className="menuIcon" />
                </Grid>
                <Grid item xs={8} className="flexBase">
                  <Typography className={`menuText ${this.props.classes.bodyText_font}`}>
                    Make a copy
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem onClick={()=>this.handleClose()} disabled={selectedItem === 'folder'}>
              <Grid container spacing={1}>
                <Grid item className="flexBase">
                  <Avatar src={download} variant="square" className="menuIcon" />
                </Grid>
                <Grid item xs={8} className="flexBase">
                  <Typography className={`menuText ${this.props.classes.bodyText_font}`}>
                    <a
                      href={viewResource && viewResource.data && viewResource.data.attributes && viewResource.data.attributes.file_and_type.file.data[0]?.attributes && viewResource.data.attributes.file_and_type.file.data[0]?.attributes.url}
                      download
                      onClick={(e) => this.download(e)}
                      className={`underlineNone ${this.props.classes.bodyText_font}`}>
                      Download
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem className="itemDisplay" onClick={this.handleClose}>
              <Grid container spacing={1}>
                <Grid item className="flexBase">
                  <Avatar className="closeImg" src={close_icon} variant="square" />
                </Grid>
                <Grid item xs={8} className="flexBase">
                  <Typography className={`menuText ${this.props.classes.bodyText_font}`}>
                    Close
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          </Box>
        </Menu>
        <Modal
          disableBackdropClick
          open={this.state.openModal && this.state.modalType === "create"}
          onClose={this.handleModalClick}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="commonPopup">
            <Avatar
              onClick={() => this.setState({
                openModal: false,
                viewFolders: [],
                viewResource: [],
                anchorEl: null
              })}
              src={closegrey_icon}
              className="closeIcon"
            />
            <Box className={`titleMain ${this.props.classes.title_font}`}>Folder Name</Box>
            <TextField className="forrmInput_textfield folderNameInput"
              id="standard-basic"
              placeholder="Enter Folder Name"
              variant="outlined"
              value={this.state.folderName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value.trim();
                if (value.length === 0) {
                  this.setState({
                    folderNameError: true,
                    folderNameErrorMes: " Folder name is required.",
                  });
                } else {
                  this.setState({
                    folderNameError: false,
                    folderNameErrorMes: "",
                  });
                }
                this.setState({ folderName: event.target.value });
              }}
              onBlur={() => {
                if (
                  this.state.folderName === null ||
                  this.state.folderName.length === 0
                ) {
                  this.setState({
                    folderNameError: true,
                    folderNameErrorMes: " Folder name is required.",
                  });
                } else {
                  this.setState({
                    folderNameError: false,
                    folderNameErrorMes: "",
                  });
                }
              }}
              error={this.state.folderNameError}
              helperText={this.state.folderNameErrorMes}
            />

            <Box className="bottomButton reverseFlex">
              <Button className={`purpleButton ${this.props.classes.button_color}`}
                onClick={this.handleCreateFolder}
              >
                Create
              </Button>
              <Button className="greyButton" onClick={() => this.setState({
                openModal: false,
                viewFolders: [],
                viewResource: []
              })}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
        {this.state.openModal && this.state.modalType === "upload" && <UploadResource submitModal={this.state.openModal} closeModalFun={this.handleCloseModal} getStudentResource={this.getStudentResource} getStudentResourceFolders={this.getStudentResourceFolders} />}
        <Modal
          disableBackdropClick
          open={this.state.openModal && this.state.modalType === "rename"}
          onClose={this.handleModalClick}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="commonPopup sharepeople">
            <Typography id="modal-modal-title" component="h2">
              <Avatar onClick={() => this.setState({
                openModal: false,
                viewFolders: [],
                viewResource: []
              })} src={closegrey_icon} className="closeIcon" />
              <div className={`titleMain ${this.props.classes.title_font}`}>
                <h4>
                  Rename
                </h4>
              </div>
            </Typography>
            <Box>
              <TextField className={`renameInput ${this.props.classes.border_Color}`}
                defaultValue={selectedItem === 'file' ? viewResource && viewResource.data && viewResource.data.attributes.file_name : viewFolders && viewFolders.data && viewFolders.data.attributes && viewFolders.data.attributes.folder_name}
                id="standard-basic"
                variant="outlined"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ folderName: event.target.value })}
              />
            </Box>
            <Grid className="bottomButton">
              <Grid item xs={12} sm={12}>
                <Button variant="contained" className={`purpleButton ${this.props.classes.resources_classAndSection_active_button_color}`}
                  onClick={selectedItem === 'file' ? this.renameStudentResourceUploads : this.renameStudentResourceFolders}
                > Ok
                </Button>
                <Button className="cancelButton"
                  onClick={() => this.setState({
                    openModal: false,
                    viewFolders: [],
                    viewResource: []
                  })}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>

        <Modal
          disableBackdropClick
          open={this.state.openModal && this.state.modalType === "viewdetails"}
          onClose={this.handleModalClick}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="commonPopup">
            <Typography id="modal-modal-title">
              <Avatar onClick={() => this.setState({
                openModal: false,
                viewFolders: [],
                viewResource: []
              })} src={closegrey_icon} className="closeIcon" />
              <div className={`titleMain ${this.props.classes.title_font}`}>
                {selectedFolderDetails &&
                  (selectedItem === 'folder' ?
                    <h4><img className="foldericon" src={folder} />{selectedFolderDetails.folder_name}</h4>
                    : <h4>{selectedFolderDetails?.file_and_type?.file?.data[0]?.attributes?.file_name}</h4>
                  )}
              </div>
            </Typography>
            <div className="viewBody">
              <h5 className={this.props.classes.title_font}>Who has access</h5>
              <ul>
                {selectedFolderDetails?.owner &&
                   <li><img src={selectedFolderDetails?.owner?.attributes?.avatar} /></li>
                }
                {
                selectedFolderDetails && selectedItem === 'folder' ? 
                selectedFolderDetails?.folder_shared_with_users?.map((item:any, index:number)=>{
                  if(index < 4){
                    if(item?.attributes?.avatar !== null){
                      return <li key={item.id}>
                        <img src={item?.attributes?.avatar} />
                      </li> 
                    } 
                  }
                  if(index > 4){
                    return <li> <Avatar className={this.props.classes.background_color}> {selectedFolderDetails?.folder_shared_with_users.length - 4}+ </Avatar> </li>
                  }
                })  
                : selectedFolderDetails?.file_shared_with_users?.map((item:any, index:number)=>{
                  if(index < 4){
                    if(item?.attributes?.avatar !== null){
                      return <li key={item.id}>
                        <img src={item?.attributes?.avatar} />
                      </li> 
                    }
                  }
                  if(index > 4){
                    return <li> <Avatar className={this.props.classes.background_color}> {selectedFolderDetails?.file_shared_with_users.length - 4}+ </Avatar> </li>
                  }
                }) 
                }
              </ul>
              <h5 className={this.props.classes.title_font}>System properties</h5>
              <ul className="listdata">
                <li>
                  <span>Type</span>
                  {
                    selectedItem === "folder" ? 
                    selectedFolderDetails?.type ? selectedFolderDetails.type : "Resource folder" 
                    :
                    selectedFolderDetails?.type ? selectedFolderDetails.type : "Resource file"
                  }
                </li>
                <li><span>Location</span>
                {
                  selectedFolderDetails && selectedFolderDetails?.location ? selectedFolderDetails.location : "No location found"
                }
                </li>
                <li><span>Modified</span>
                  {selectedFolderDetails && selectedFolderDetails.modified_at != null || selectedFolderDetails && selectedFolderDetails.modified_at != undefined ? selectedFolderDetails && selectedFolderDetails.modified_at : "No modified date found"}
                </li>
                <li><span>Created</span>
                  {selectedFolderDetails && selectedFolderDetails.created_at != null || selectedFolderDetails && selectedFolderDetails.created_at != undefined ? selectedFolderDetails && selectedFolderDetails.created_at : "No created date found"}
                </li>
                <li>
                  <span>Owner</span>
                  { selectedFolderDetails ? selectedFolderDetails?.owner?.attributes?.first_name + " " + selectedFolderDetails?.owner?.attributes?.last_name : "No owner found"}
                </li>
                <li>
                  <span>Opened</span>
                  {selectedFolderDetails && selectedFolderDetails.opened_at != null || selectedFolderDetails && selectedFolderDetails.opened_at != undefined ? selectedFolderDetails && selectedFolderDetails.opened_at : "No opened found"}
                </li>
              </ul>
            </div>
          </Box>
        </Modal>

        <Modal
          disableBackdropClick
          open={this.state.openModal && this.state.modalType === "share"}
          onClose={this.handleModalClick}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="commonPopup sharepeople">
            <Typography id="modal-modal-title">
              <Avatar onClick={() => this.setState({
                openModal: false,
                viewFolders: [],
                viewResource: []
              })} src={closegrey_icon} className="closeIcon" />
              <div className="titleMain">
                <h4 className={`foldericon ${this.props.classes.title_font}`}>
                  <img className="w34" src={user_icon} />
                  Share with people
                </h4>
              </div>
            </Typography>
            <div className="viewBody">
              <TextField className="textsearcField" fullWidth
                placeholder="search..."
                id="standard-bare"
                variant="outlined"
                onChange={event => this.setState({ query: event.target.value })}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchOutlined />
                    </IconButton>
                  ),
                }}
              />
              <SharewithPeople
                studentsList={shareStudentList}
                handelShare={this.handelShareWithPeople}
                handelQuery={this.state.query}
              />
              <Grid className="bottomButton">
                <Grid item xs={6} sm={6}>
                  <Button className={`purpleButton ${this.props.classes.button_color}`} onClick={this.shareWithUsers}>Send</Button>
                  <Button className="cancelButton greyButton"
                    onClick={() => this.setState({
                      openModal: false,
                      viewFolders: [],
                      viewResource: []
                    })}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <span>{`${this.state.shareWithList && this.state.shareWithList?.length}/${this.state.shareWithList && this.state.shareStudentList?.length && this.state.shareStudentList?.length}`} selected</span>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Modal>

        <Modal
          disableBackdropClick
          open={this.state.openModal && this.state.modalType === "getLink"}
          onClose={this.handleModalClick}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="commonPopup sharepeople">
            <Typography id="modal-modal-title" component="h2">
              <Avatar
                onClick={() =>
                  this.setState({
                    openModal: false,
                    viewFolders: [],
                    viewResource: [],
                  })
                }
                src={closegrey_icon}
                className="closeIcon"
              />
              <div className={`titleMain ${this.props.classes.title_font}`}>
                <h4>Get Link</h4>
              </div>
            </Typography>

            <TextField className={`w100 forrmInput_textfield ${this.props.classes.border_Color}`}
              defaultValue={
                viewResource &&
                viewResource.data &&
                viewResource.data.attributes.file_and_type.file.data[0]
                  ?.attributes?.url
              }
              id="standard-basic"
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ folderName: event.target.value })
              }
            />
            <Grid className="bottomButton">
              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  className={`purpleButton ${this.props.classes.button_color}`}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${viewResource &&
                      viewResource.data &&
                      viewResource.data.attributes.file_and_type.file.data[0]?.attributes?.url
                      }`
                    );
                  }}
                >
                  Copy Link
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>

        <Modal
          disableBackdropClick
          open={this.state.openModal && this.state.modalType === "moveto"}
          onClose={this.handleModalClick}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="commonPopup">
            <Typography id="modal-modal-title" component="h2">
              <Avatar
                onClick={() =>
                  this.setState({
                    openModal: false,
                    viewFolders: [],
                    viewResource: [],
                  })
                }
                src={closegrey_icon}
                className="closeIcon"
              />
              <div className={`titleMain ${this.props.classes.title_font}`}>
                <h4>Move To</h4>
              </div>
            </Typography>
            <Typography id="modal-modal-title" component="h4">
              Select Folder
            </Typography>
            <Box>
              <Select className="movetoselectbox move-select fullWidthselect"
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={movetoFolderId}
                onChange={(e) => this.setState({ movetoFolderId: e.target.value })}
                input={<BootstrapInput />}
                IconComponent={ArrowDropDown}
              >
                {studentResourceFolders &&
                  studentResourceFolders.data &&
                  studentResourceFolders.data.map((item: any) => {
                    return (
                      <MenuItem value={item.id}>{item.attributes.folder_name}</MenuItem>
                    );
                  })}
              </Select>
            </Box>
            <Grid className="bottomButton">
              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  className={`purpleButton ${this.props.classes.button_color}`}
                  onClick={this.moveToResourceUploads}
                  disabled={movetoFolderId === undefined}
                >
                  Ok
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
        <AlertModal
          alertModal={this.state.alertModal}
          isTeacher={false}
          onCloseModal={() => { this.setState({ alertModal: false }) }}
          alertTitle="Alert!"
          alertMessage={this.state.alertMessage}
          buttonText="OK"
        />
      </Box>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(StudentResources);
// Customizable Area End