// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import { encryptData } from "./utils/common";
import { HISTORY } from "../../../components/src/comman";
export const configJSON = require("./config.js");
import { toast } from "react-toastify";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any
  // Customizable Area End
}
// Customizable Area Start
export interface ILiveClassesProps {
  id: any;
  isAllDay?: boolean;
  start: Date;
  end: Date;
  title: string;
  type?: string;
  hexBgColor?: string;
  borderColor?: string;
  image?: any;
  subjectImage?: any;
  subject?: string;
  meetingDate?: any;
  meetingTitle?: string;
  teacherImage?: any;
  teacherName?: string;
  unit?: string;
  link?: string;
  class?: string;
  description?: string;
  status?: string;
  recordings?: any
}
// Customizable Area End
interface S {
  // Customizable Area Start
  date: any;
  loadingSpinner: boolean;
  calendarEvents: any;
  selectedLiveClass: any;
  numberOfSubject: number;
  studentSubjects: any;
  TeacherTasks: any;
  studentAttendanceList: any;
  timeLineMeta: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherClassesAndSectionsController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  apiaddStudentClassesId: string = "";
  apigetTeacherTaskId: string = '';
  apigetTeacherAttendanceId: string = '';
  apiGetLiveClasses: string = '';
  apiMakeTeacherLiveClassHost: string = '';
  child: React.RefObject<any>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.child = React.createRef();
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      date: moment(new Date()),
      loadingSpinner: false,
      calendarEvents: [],
      selectedLiveClass: null,
      studentSubjects: [],
      numberOfSubject: 0,
      TeacherTasks: [],
      studentAttendanceList: [],
      timeLineMeta: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getTeacherLiveClasses();
    this.get_task();
    this.getTeacherAttendance();
  }
  // Customizable Area End
  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apiaddStudentClassesId) {
        if (responseJson != null) {

          if (!responseJson.errors) {
            console.log("Response Json", responseJson)
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiGetLiveClasses) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.handleTimeTableData(responseJson?.data);
            this.setState({ loadingSpinner: false })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetTeacherTaskId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              TeacherTasks: responseJson.tasks.data,
              timeLineMeta: responseJson.meta,
            });
            this.setState({ loadingSpinner: false })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetTeacherAttendanceId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            if (responseJson.data != undefined) {
              this.setState({
                studentAttendanceList: responseJson.data,
              });
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiMakeTeacherLiveClassHost) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.handleOpenZoomMeeting(this.state.selectedLiveClass)
          } else {
            this.parseApiErrorResponse(responseJson);
            toast.error("Something went wrong!")
          }
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleGetStartAndEndTime = (startDate: any) => {
    const dateObj = new Date(startDate);
    const month = dateObj.getMonth(); //index of month
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const formattedTime = moment(startDate).format("HH:mm");
    const formattedTimeHours = parseInt(formattedTime.split(":")[0]);
    const formattedTimeMins = parseInt(formattedTime.split(":")[1]);
    return new Date(year, month, day, formattedTimeHours, formattedTimeMins);
  };
  handleTimeTableData = (periodInfo: Array<any>) => {
    if (periodInfo.length) {
      const periodInfoDetails = periodInfo.map((period) => {
        const info = {
          title:
            period.attributes.topic ||
            period.attributes.subject.data.attributes.subject,
          start: this.handleGetStartAndEndTime(
            moment(period.attributes.start_time).format()
          ),
          end: this.handleGetStartAndEndTime(
            moment(period.attributes.end_time).format()
          ),
          hexBgColor: "#b1e4b1",
          borderColor: "#3dbb3d",
          subjectImage:
            period.attributes.subject.data.attributes.image?.file_path ? period.attributes.subject.data.attributes.image?.file_path : "",
          subject: period.attributes.subject?.data?.attributes?.subject_name,
          meetingDetails: period.attributes.signature,
          meetingId: period?.id,
          meetingTitle: period.attributes.topic,
          teacherImage: period.attributes.teacher_name?.data?.attributes?.avatar,
          teacherName:
            `${period.attributes.teacher_name?.data?.attributes?.first_name
            } ${period.attributes.teacher_name?.data?.attributes?.last_name}`,
          link: period.attributes.join_url,
          class: period.attributes?.class_name,
          description: period.attributes.description,
          status: period.attributes.status,
          recordings: period.attributes.recordings
        };
        return info;
      });
      this.setState({ calendarEvents: periodInfoDetails, loadingSpinner: false });
    }
  };

  sectionBtnHandler = (type: string) => {
    if (type === 'Time Table') {
      HISTORY.push({
        pathname: "/TeacherTimeTable"
      });
    }
    else if (type === 'Gradebook') {
      HISTORY.push({
        pathname: "/TeacherGradeBook"
      });
    }
    else if (type === 'Resources') {
      HISTORY.push({
        pathname: "/TeacherResources"
      });
    }
    else if (type === 'Subjects') {
      HISTORY.push({
        pathname: "/TeacherSubject",
        state: {
          studentSubjects: this.state.studentSubjects,
        },
      });
    }
    else if (type === 'Task') {
      HISTORY.push({
        pathname: "/TeacherTaskUnit",
        state: {
          TeacherTasks: this.state.TeacherTasks,
          timeLineMeta: this.state.timeLineMeta
        },
      });
    } else if (type === 'Members') {
      HISTORY.push({
        pathname: "/TeacherMembers",
      });
    }
    else {
      HISTORY.push({
        pathname: "/TeacherAttendance",
        state: {
          studentAttendanceList: this.state.studentAttendanceList,
        },
      });
    }
  }
  handleDateNavigate = (count: any, type: any, navigationType: any) => {
    let futureDate: any = "";
    if (navigationType === "backward") {
      if (type === "months") {
        futureDate = moment(this.state.date).subtract(count, "M");
      } else {
        futureDate = moment(this.state.date).subtract(count, "Y");
      }
    } else {
      if (type === "months") {
        futureDate = moment(this.state.date).add(count, "M");
      } else {
        futureDate = moment(this.state.date).add(count, "Y");
      }
    }
    this.setState({ date: futureDate });
    this.addLunchEvent(futureDate);
  };
  addLunchEvent = (currentDate?: any) => {
    let updatedCalendarEvents: any = [];
    let { calendarEvents, date } = this.state;
    const selectedDate = currentDate ? currentDate : date;
    const currentMonth = moment(selectedDate).format("M");
    const currentYear = moment(selectedDate).format("YYYY");

    const oddMonths = [1, 3, 5, 7, 8, 10, 12]; // Month num with 31 days
    let monthDays = 30;
    if (oddMonths.includes(Number(currentMonth))) {
      monthDays = 31;
    } else if (Number(currentMonth) === 2) {
      const leapYear = moment(selectedDate).isLeapYear();
      if (leapYear) {
        monthDays = 29;
      } else {
        monthDays = 28;
      }
    }

    for (let date = 0; date < monthDays; date++) {
      const lunchEvent = {
        id: `${date}-${Number(currentMonth)}-${Number(currentYear)}`,
        title: "Lunch Time",
        start: new Date(
          Number(currentYear),
          Number(currentMonth) - 1,
          date,
          11,
          0,
          0,
          0
        ),
        end: new Date(
          Number(currentYear),
          Number(currentMonth) - 1,
          date,
          12,
          0,
          0,
          0
        ),
        type: "lunchTime",
        hexBgColor: "#f1eff9",
        borderColor: "#f1eff9",
      };
      if (lunchEvent.start.getDay() !== 0) {
        updatedCalendarEvents = [...updatedCalendarEvents, lunchEvent];
      }
    }
  }
  handleOpenZoomMeeting = (event: any) => {
    const userData = localStorage.getItem("user_data");
    const parsedUserData = JSON.parse(userData || "{}");
    if (parsedUserData.meeting_paid_account) {
      const { api_key, api_secret, id } = event.meetingDetails;
      const role = 1;
      const meetingParameters = {
        ...event,
        meetingDetails: {
          api_key: encryptData(api_key, "meeting-api-key"),
          api_secret: encryptData(
            api_secret,
            "meeting-api-secret"
          ),
          id: encryptData(
            id,
            "meeting-id"
          ),
          role: encryptData(
            role.toString(),
            "meeting-role"
          )
        }
      };
      localStorage.setItem("meeting-detail", JSON.stringify(meetingParameters));
      localStorage.setItem("role", "teacher");
      const meetingDetail = localStorage.getItem("meeting-detail");
      if (meetingDetail) {
        const currentUrl = window.location.origin;
        const redirectUrl = `${currentUrl}/Meeting`;
        window.open(redirectUrl, "_blank");
      }
    } else {
      window.open(event.link, "_blank");
    }
  }
  makeTeacherLiveClassHost = () => {
    const userData = localStorage.getItem("user_data");
    const parsedUserData = JSON.parse(userData || "{}");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const httpBody = {
      meeting_id: this.state.selectedLiveClass?.meetingDetails.id
    }


    this.apiMakeTeacherLiveClassHost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherLiveClassesMakeHostEndPoint +
      `?school_id=${parsedUserData.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.enrollApiPutMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  get_task = () => {

    this.setState({ loadingSpinner: true })
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
      "school": data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherTaskId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getTeacherAttendance = () => {
    this.setState({ loadingSpinner: true })
    const school_data = localStorage.getItem("user_data");
    const data = JSON.parse(school_data || "{}");
    const teacherId = localStorage.getItem("id")
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
      school: data.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetTeacherAttendanceId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherAttendanceByFilter + `?teacher_id=${teacherId}&date=${moment().format('DD/MM/YYYY')}&page=${1}&per_page=${4}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.attendanceURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getTeacherLiveClasses = (date?: any) => {
    this.setState({ loadingSpinner: true })
    const selectedDate = date
      ? moment(date).format("DD/MM/YYYY")
      : moment(this.state.date).format("DD/MM/YYYY");
    const userData = localStorage.getItem("user_data");
    const parsedUserData = JSON.parse(userData || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetLiveClasses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherLiveClassesEndPoint +
      `?start_date=${selectedDate}&school_id=${parsedUserData.school_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
