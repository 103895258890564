// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { HISTORY } from "../../../../../components/src/comman";
const uuid = require('uuid/v4');
// Customizable Area End
// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showLoader: boolean;
  reportCardId: any;
  gradeList: any;
  selectedGrades: any;
  isAllGradeSelected: boolean;
  reportCardTitle: string;
  gradeScaleList: any;
  selectedGradeScale: string;
  filteredTermList: any;
  categoryDetailsTabList: any;
  selectedCategoryTab: any;
  selectedCategoryIndex: number;
  assessmentList: any;
  selectedAssessment: any;
  isAllAssessmentSelected: boolean;
  taskList: any;
  selectedTask: any;
  isAllTaskSelected: boolean;
  coScholasticTopicList: any;
  selectedTopics: any;
  isAllTopicSelected: boolean;
  assessmentCategoryList: any;
  selectedAssessmentCategories: any;
  deletedAssessmentCategories: any;
  taskCategoryList: any;
  selectedTaskCategories: any;
  deletedTaskCategories: any;
  coScholasticModule: any;
  gradeScaleDetail: any;
  isEdit: boolean;
  openWarningModal: boolean;
  warningFor: string;
  warningTitle: string;
  warningSubTitle: string;
  valueToBeUpdated: any;
  valueToBeUpdatedAt: number;
  topicChangeWarning: boolean;
  publishStatus: string;
  bindedGradeIds: any;
  isAllGradeDisabled: boolean;
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}
export default class AddCustomReportCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetGradeList: string = "";
  apiGetGradeScaleList: string = "";
  apiGetModuleDetail: string = "";
  apiGetAssessmentCategoryDetail: string = "";
  apiGetTaskCategoryDetail: string = "";
  apiGetGradeScaleDetail: string = "";
  apiPublishReportCard: string = "";
  apiUpdateReportCard: string = "";
  apiGetBindedGradeList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      showLoader: false,
      gradeList: [],
      selectedGrades: [],
      isAllGradeSelected: false,
      reportCardTitle: '',
      gradeScaleList: [],
      selectedGradeScale: '',
      filteredTermList: [],
      categoryDetailsTabList: [
        {
          key: 0,
          value: "assessments",
          label: "Assessments",
        },
        {
          key: 1,
          value: "task",
          label: "Task",
        },
      ],
      selectedCategoryTab: 'assessments',
      selectedCategoryIndex: 0,
      assessmentList: [],
      selectedAssessment: [],
      isAllAssessmentSelected: false,
      taskList: [],
      selectedTask: [],
      isAllTaskSelected: false,
      coScholasticTopicList: [],
      selectedTopics: [],
      isAllTopicSelected: false,
      assessmentCategoryList: [],
      selectedAssessmentCategories: [{
        id: uuid(),
        selectedAssessment: "",
        weightage: 0
      }],
      deletedAssessmentCategories: [],
      taskCategoryList: [],
      selectedTaskCategories: [{
        id: uuid(),
        selectedTask: "",
        weightage: 0
      }],
      deletedTaskCategories: [],
      coScholasticModule: [],
      gradeScaleDetail: [],
      reportCardId: '',
      isEdit: false,
      openWarningModal: false,
      warningFor: '',
      warningTitle: '',
      warningSubTitle: '',
      valueToBeUpdated: -1,
      valueToBeUpdatedAt: -1,
      topicChangeWarning: false,
      publishStatus: 'draft',
      bindedGradeIds: [],
      isAllGradeDisabled: false,
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // History data
    if (HISTORY.location.state?.isEdit) {
      this.getGradeList();
      this.getGradingScaleList();
      this.getAssessmentCategoryDetails();
      this.getTaskCategoryDetails();

      const reportCardData = HISTORY.location.state?.reportCardData;
      let assessmentCats: any = [];
      let taskCats: any = [];
      reportCardData.attributes.report_card_categories.map((item: any) => {
        if (item.type === "assessment") {
          assessmentCats.push({
            id: uuid(),
            selectedAssessment: Number(item.report_category_id),
            showWarning: true,
            weightage: item.weightage,
          })
        } else {
          taskCats.push({
            id: uuid(),
            selectedTask: Number(item.report_category_id),
            showWarning: true,
            weightage: item.weightage
          })
        }
      });

      this.setState({
        isEdit: true,
        reportCardId: reportCardData.id,
        reportCardTitle: reportCardData.attributes.title,
        selectedGrades: reportCardData.attributes.grades.reduce((result: any, item: any) => {
          result.push(Number(item.id));
          return result;
        }, []),
        selectedTopics: reportCardData.attributes.report_topics.reduce((result: any, item: any) => {
          result.push(Number(item.id));
          return result;
        }, []),
        topicChangeWarning: true,
        selectedAssessmentCategories: assessmentCats,
        selectedTaskCategories: taskCats,
        selectedGradeScale: reportCardData.attributes.report_grade.id,
        publishStatus: reportCardData.attributes.status
      }, () => {
        this.getGradeScaleDetail(Number(reportCardData.attributes.report_grade.id));
        this.getModuleDetails();
      })
    } else {
      this.getBindedGradeList();
      this.getGradingScaleList();
      this.getModuleDetails();
      this.getAssessmentCategoryDetails();
      this.getTaskCategoryDetails();
    }
    // History data END
    // Customizable Area End
  }

  // Customizable Area Start
  onBackClick = () => {
    HISTORY.push({
      pathname: "/AdminReportCard"
    })
  }

  handleReportCardTitle = (value: string) => {
    this.setState({
      reportCardTitle: value
    })
  }

  handleClassChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let selectedList = event.target.value as number[];
    selectedList = selectedList.map((data: any) => Number(data));

    let gradeList = this.state.gradeList.reduce((result: any, data: any) => {
      result.push(Number(data.id));
      return result;
    }, []);
    if (Number(selectedList[selectedList.length - 1]) === -1) {
      const updatedList = this.state.selectedGrades.length === gradeList.length ? [] : gradeList;
      const isAllSelected = updatedList.length === gradeList.length;
      this.setState({
        selectedGrades: updatedList,
        isAllGradeSelected: isAllSelected
      })
      return;
    }
    const isAllSelected = selectedList.length === gradeList.length;

    this.setState({
      selectedGrades: selectedList,
      isAllGradeSelected: isAllSelected
    })
  }

  getSelectedClassNames = (selected: any) => {

    if (selected.length === 0) {
      return "Select Class"
    }
    return this.state.gradeList.reduce((result: string, grade: any) => {
      if (this.state.selectedGrades.includes(Number(grade.id))) {
        result = result + (result !== "" ? ", " : "") + grade.attributes.name
      }
      return result
    }, "")

  }

  handleCoScholastic = (selectedList: string[]) => {
    if (this.state.topicChangeWarning) {
      this.setState({
        warningFor: 'topics',
        warningTitle: 'Are you sure you want to change topics ?',
        warningSubTitle: 'Changing the topics will result in loss of previous data',
        valueToBeUpdated: selectedList
      }, () => this.setState({ openWarningModal: true }))
      return false;
    }

    this.setState({
      openWarningModal: false,
      warningFor: '',
      warningTitle: '',
      warningSubTitle: '',
      valueToBeUpdated: -1,
      valueToBeUpdatedAt: -1
    });

    let topicList = this.state.coScholasticModule.attributes?.topics?.reduce((result: any, data: any) => {
      result.push(Number(data.id));
      return result;
    }, []);
    if (selectedList[selectedList.length - 1] === '-1') {
      const updatedList = this.state.selectedTopics.length === topicList.length ? [] : topicList;
      const isAllSelected = updatedList.length === topicList.length;
      this.setState({
        selectedTopics: updatedList,
        isAllTopicSelected: isAllSelected
      })
      return;
    }
    const isAllSelected = selectedList.length === topicList.length;

    this.setState({
      selectedTopics: selectedList,
      isAllTopicSelected: isAllSelected
    })
  }

  getSelectedTopicNames = (selected: any) => {

    if (selected.length === 0) {
      return "Select Topics"
    }
    return this.state.coScholasticModule.attributes?.topics?.reduce((result: string, topic: any) => {
      if (this.state.selectedTopics.includes(Number(topic.id))) {
        result = result + (result !== "" ? ", " : "") + topic.name
      }
      return result
    }, "")

  }

  // Category -assessment
  handleAssessmentNameChange = (value: any, index: number) => {
    const selectedAssessmentCategories = this.state.selectedAssessmentCategories;
    if (selectedAssessmentCategories[index]?.showWarning) {
      this.setState({
        warningFor: 'assessment',
        warningTitle: 'Are you sure you want to change assessment category ?',
        warningSubTitle: 'Changing the category will result in loss of previous data',
        valueToBeUpdated: value,
        valueToBeUpdatedAt: index,
      }, () => this.setState({ openWarningModal: true }));
      return false;
    }
    selectedAssessmentCategories[index].selectedAssessment = value;
    this.setState({
      selectedAssessmentCategories: selectedAssessmentCategories,
      openWarningModal: false,
      warningFor: '',
      warningTitle: '',
      warningSubTitle: '',
      valueToBeUpdated: -1,
      valueToBeUpdatedAt: -1
    })
  }

  handleAssessmentWeightageChange = (value: any, index: number) => {
    const selectedAssessmentCategories = this.state.selectedAssessmentCategories;
    if (value < 0) {
      toast("Assessment Weightage should be greater than 0");
      value = 0;
    }
    const totalWeightage: number = selectedAssessmentCategories.reduce((sum: number, data: any, i: number) => {
      if (index !== i) {
        sum = sum + Number(data.weightage);
      }
      return sum;
    }, 0)
    if (value > (100 - totalWeightage)) {
      toast("Total Assessment Weightage should be less than or equal to 100");
      value = 100 - totalWeightage;
    }

    selectedAssessmentCategories[index].weightage = value;
    this.setState({
      selectedAssessmentCategories: selectedAssessmentCategories
    })
  }

  handleAddAssessment = () => {
    const selectedAssessmentCategories = this.state.selectedAssessmentCategories;
    let error = false;
    selectedAssessmentCategories.map((data: any) => {
      if (data.selectedAssessment == "") {
        toast("Please select assessment");
        error = true;
      }
      if (data.weightage === 0) {
        toast("Assessment Weightage should be greater than 0")
        error = true;
      }
    })
    if (error) {
      return;
    }
    selectedAssessmentCategories.push({
      id: uuid(),
      selectedAssessment: "",
      weightage: 0
    })
    this.setState({
      selectedAssessmentCategories: selectedAssessmentCategories
    })
  }

  deleteAssessment = (index: number) => {
    const { selectedAssessmentCategories, deletedAssessmentCategories } = this.state;
    const deletedAssessment = selectedAssessmentCategories[index];
    deletedAssessmentCategories.push(deletedAssessment);
    selectedAssessmentCategories.splice(index, 1);
    this.setState({
      selectedAssessmentCategories: selectedAssessmentCategories,
      deletedAssessmentCategories: deletedAssessmentCategories
    })
  }
  // Category -assessment END


  // Category -task
  handleTaskNameChange = (value: any, index: number) => {
    const selectedTaskCategories = this.state.selectedTaskCategories;
    if (selectedTaskCategories[index]?.showWarning) {
      this.setState({
        warningFor: 'task',
        warningTitle: 'Are you sure you want to change task category ?',
        warningSubTitle: 'Changing the category will result in loss of previous data',
        valueToBeUpdated: value,
        valueToBeUpdatedAt: index,
      }, () => this.setState({ openWarningModal: true }));
      return false;
    }
    selectedTaskCategories[index].selectedTask = Number(value);
    this.setState({
      selectedTaskCategories: selectedTaskCategories,
      openWarningModal: false,
      warningFor: '',
      warningTitle: '',
      warningSubTitle: '',
      valueToBeUpdated: -1,
      valueToBeUpdatedAt: -1
    })
  }

  handleTaskWeightageChange = (value: any, index: number) => {
    const selectedTaskCategories = this.state.selectedTaskCategories;
    if (value < 0) {
      toast("Task Weightage should be greater than 0");
      value = 0;
    }
    const totalWeightage: number = selectedTaskCategories.reduce((sum: number, data: any, i: number) => {
      if (index !== i) {
        sum = sum + Number(data.weightage);
      }
      return sum;
    }, 0)
    if (value > (100 - totalWeightage)) {
      toast("Total Task Weightage should be less than or equal to 100");
      value = 100 - totalWeightage;
    }

    selectedTaskCategories[index].weightage = value;
    this.setState({
      selectedTaskCategories: selectedTaskCategories
    })
  }

  handleAddTask = () => {
    const selectedTaskCategories = this.state.selectedTaskCategories;
    let error = false;
    selectedTaskCategories.map((data: any) => {
      if (data.selectedTask == "") {
        toast("Please select task");
        error = true;
      }
      if (data.weightage === 0) {
        toast("Task Weightage should be greater than 0")
        error = true;
      }
    })
    if (error) {
      return;
    }
    selectedTaskCategories.push({
      id: uuid(),
      selectedTask: "",
      weightage: 0
    })
    this.setState({
      selectedTaskCategories: selectedTaskCategories
    })
  }

  deleteTask = (index: number) => {
    const { selectedTaskCategories, deletedTaskCategories } = this.state;
    const deletedTask = selectedTaskCategories[index];
    deletedTaskCategories.push(deletedTask);
    selectedTaskCategories.splice(index, 1);
    this.setState({
      selectedTaskCategories: selectedTaskCategories,
      deletedTaskCategories: deletedTaskCategories
    })
  }
  // Category -task END


  handleSelectCategoryTab = (tab: string, key: number) => {
    this.setState({ selectedCategoryTab: tab, selectedCategoryIndex: key });
  };

  handleGradeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      selectedGradeScale: event.target.value as string
    }, () => {
      this.getGradeScaleDetail(Number(event.target.value))
    })
  }

  validate = () => {

    const {
      reportCardTitle,
      selectedGrades,
      selectedTopics,
      selectedAssessmentCategories,
      selectedTaskCategories,
      selectedGradeScale
    } = this.state;
    // Title
    if (reportCardTitle === "") {
      toast("Report card title can't be empty")
      return false;
    }
    // Title END

    // grade/class
    if (selectedGrades.length === 0) {
      toast("Please select atleast one class")
      return false;
    }
    // grade/class END

    // co-scholastic topic
    if (selectedTopics.length === 0) {
      toast("Please select atleast one topic")
      return false;
    }
    // co-scholastic topic END

    // Assessment Category
    let assessmentError = false;
    let assessmentWeightageSum: number = 0;
    selectedAssessmentCategories.map((data: any) => {
      assessmentWeightageSum = assessmentWeightageSum + Number(data.weightage);
      if (data.selectedAssessment == "") {
        toast("Please select task");
        assessmentError = true;
      }
      if (data.weightage === 0) {
        toast("Asssessment Weightage should be greater than 0");
        assessmentError = true;
      }
    })

    if (assessmentError) {
      return false;
    }

    if (assessmentWeightageSum > 100 || assessmentWeightageSum < 100) {
      toast("Total Assessment Weightage should be equal to 100");
      return false;
    }
    // Assessment Category END

    // Task Category
    let taskError = false;
    let taskWeightageSum: number = 0;
    selectedTaskCategories.map((data: any) => {
      taskWeightageSum = taskWeightageSum + Number(data.weightage);
      if (data.selectedTask == "") {
        toast("Please select task");
        taskError = true;
      }
      if (data.weightage === 0) {
        toast("Task Weightage should be greater than 0");
        taskError = true;
      }
    })

    if (taskError) {
      return false;
    }

    if (taskWeightageSum > 100 || taskWeightageSum < 100) {
      toast("Total Task Weightage should be equal to 100");
      return false;
    }
    // Task Category END

    // Grade Scale
    if (selectedGradeScale === '') {
      toast("Please select grade scale");
      return false;
    }
    // Grade Scale END


    return true;
  }

  onStartToCreate = () => {
    if (this.validate()) {
      this.createCustomReportCard("published")
    }
  }


  handleSaveAsDraft = () => {
    if (this.validate()) {
      this.createCustomReportCard("as_draft")
    }
  }

  handleUpdateOrPublishReportCard = (publish: boolean) => {
    if (this.validate()) {
      if (publish) {
        this.updateCustomReportCard('published')
      } else {
        this.updateCustomReportCard()
      }
    }
  }

  onCloseWarningModal = () => {
    this.setState({
      openWarningModal: false,
      warningFor: '',
      warningTitle: '',
      warningSubTitle: '',
      valueToBeUpdated: -1,
      valueToBeUpdatedAt: -1
    })
  }

  handleWarningOk = () => {
    const { warningFor, valueToBeUpdated, valueToBeUpdatedAt, selectedAssessmentCategories, selectedTaskCategories } = this.state;
    if ((warningFor !== '' && valueToBeUpdated !== -1) || valueToBeUpdatedAt !== -1) {
      switch (warningFor) {
        case 'task':
          selectedTaskCategories[valueToBeUpdatedAt].showWarning = false;
          this.setState({ selectedTaskCategories }, () => {
            this.handleTaskNameChange(valueToBeUpdated, valueToBeUpdatedAt)
          })
          break;
        case 'assessment':
          selectedAssessmentCategories[valueToBeUpdatedAt].showWarning = false;
          this.setState({ selectedAssessmentCategories }, () => {
            this.handleAssessmentNameChange(valueToBeUpdated, valueToBeUpdatedAt)
          })
          break;
        case 'topics':
          this.setState({ topicChangeWarning: false }, () => {
            this.handleCoScholastic(valueToBeUpdated)
          })
          break;
      }
    }

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Received', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.apiGetGradeList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const data: any = responseJson?.data;
            let disableAllCheck = false;
            if (this.state.bindedGradeIds.length > 0) {
              data.map((item: any) => {
                if (this.state.bindedGradeIds.indexOf(Number(item.id) > -1)) {
                  disableAllCheck = true
                }
              });
            }

            this.setState({
              gradeList: data,
              showLoader: false,
              isAllGradeDisabled: disableAllCheck
            })

          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetGradeScaleList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              gradeScaleList: responseJson?.data,
              showLoader: false
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetModuleDetail) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            const coScholaticModule = responseJson.data.filter((item: any) => item.attributes.type === "co_scholastic")?.[0];
            this.setState({
              coScholasticModule: coScholaticModule,
              showLoader: false
            });

            if (this.state.isEdit) {
              this.setState({
                isAllTopicSelected: (coScholaticModule.attributes.topics.length === this.state.selectedTopics.length)
              })
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetAssessmentCategoryDetail) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              assessmentCategoryList: responseJson.data,
              showLoader: false
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetTaskCategoryDetail) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              taskCategoryList: responseJson.data,
              showLoader: false
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetGradeScaleDetail) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              gradeScaleDetail: responseJson.data.attributes.report_grade_scales.data,
              showLoader: false
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiPublishReportCard) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            HISTORY.push({
              pathname: "/AdminReportCard"
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiUpdateReportCard) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            if (responseJson.data.attributes.status == 'as_draft') {
              HISTORY.push({
                pathname: "/DraftReportCard"
              })
            } else {
              HISTORY.push({
                pathname: "/AdminReportCard"
              })
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetBindedGradeList) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({
              bindedGradeIds: responseJson.data?.map((data: any) => data !== null && Number(data))
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.getGradeList();
        this.setState({ showLoader: false })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }

  getGradingScaleList = () => {
    const user_data = localStorage.getItem('user_data');
    const school_Data = JSON.parse(user_data || '{}')
    this.setState({ showLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: school_Data?.school_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetGradeScaleList = requestMessage.messageId;
    let apiEndPoint = configJSON.getGradingListEndPoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getBindedGradeList = () => {
    const user_data = localStorage.getItem('user_data');
    const schoolData = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: schoolData?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetBindedGradeList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiGetReportCardBindedGrades
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getGradeList = () => {
    const user_data = localStorage.getItem('user_data');
    const schoolData = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: schoolData?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetGradeList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGradeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.instituteURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getModuleDetails = () => {
    const user_data = localStorage.getItem('user_data');
    const schoolData = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: schoolData?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetModuleDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.moduleEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAssessmentCategoryDetails = () => {
    const user_data = localStorage.getItem('user_data');
    const schoolData = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: schoolData?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAssessmentCategoryDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryListEndPoint + "?category_type=assessment"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTaskCategoryDetails = () => {
    const user_data = localStorage.getItem('user_data');
    const schoolData = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: schoolData?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetTaskCategoryDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryListEndPoint + "?category_type=task"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getGradeScaleDetail = (grade_id: number) => {
    this.setState({
      showLoader: true
    })
    const user_data = localStorage.getItem('user_data');
    const schoolData = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: schoolData?.school_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetGradeScaleDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGradingListEndPoint + `/${grade_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateCustomReportCard = (publish_type?: string) => {
    this.setState({
      showLoader: true
    })
    const user_data = localStorage.getItem('user_data');
    const schoolData = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: schoolData?.school_id
    };

    const {
      reportCardId,
      reportCardTitle,
      selectedTopics,
      selectedAssessmentCategories,
      selectedTaskCategories,
      selectedGradeScale
    } = this.state;

    let report_categories = [];
    // assessment categories
    report_categories.push(...selectedAssessmentCategories.reduce((result: any, data: any) => {
      result.push({
        id: data.selectedAssessment,
        weightage: data.weightage
      });
      return result;
    }, []));
    // assessment categories END
    // task categories
    report_categories.push(...selectedTaskCategories.reduce((result: any, data: any) => {
      result.push({
        id: data.selectedTask,
        weightage: data.weightage
      });
      return result;
    }, []));
    // task categories END

    const httpBody: any = {
      title: reportCardTitle,
      report_grade_id: selectedGradeScale,
      report_module_topics_ids: selectedTopics,
      report_categories: report_categories
    }
    if (publish_type) {
      httpBody.status = publish_type
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateReportCard = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiPublishReportCardEndPoint + `/${reportCardId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  createCustomReportCard = (status: string) => {
    this.setState({
      showLoader: true
    })
    const user_data = localStorage.getItem('user_data');
    const schoolData = JSON.parse(user_data || '{}')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      school: schoolData?.school_id
    };

    const {
      reportCardTitle,
      selectedGrades,
      selectedTopics,
      selectedAssessmentCategories,
      selectedTaskCategories,
      selectedGradeScale
    } = this.state;

    let report_categories = [];
    // assessment categories
    report_categories.push(...selectedAssessmentCategories.reduce((result: any, data: any) => {
      result.push({
        id: data.selectedAssessment,
        weightage: data.weightage
      });
      return result;
    }, []));
    // assessment categories END
    // task categories
    report_categories.push(...selectedTaskCategories.reduce((result: any, data: any) => {
      result.push({
        id: data.selectedTask,
        weightage: data.weightage
      });
      return result;
    }, []));
    // task categories END

    const httpBody: any = {
      title: reportCardTitle,
      grade_id: selectedGrades,
      report_grade_id: selectedGradeScale,
      report_module_topics_ids: selectedTopics,
      status: status, //"published" "draft"
      report_categories: report_categories
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPublishReportCard = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiPublishReportCardEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.resultUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}