// Customizable Area Start
import React from "react"
import {
    Button,
    withStyles,
    Grid,
    TextField,
    Select,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Divider,
    Hidden
} from "@material-ui/core";
import TermDetailsController, { Props } from "./TermDetailsController.web";
import Spinner from "../../../../shared/SideBar/src/Spinner";
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import TermAlertModal from "./TermAlertModal.web";
import Datetime from "react-datetime";
import moment from "moment";
import './CreateCustomReportCard.web.css';
import { themeCreateStyle } from "../../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start
const form_input_text_field = {
    width: "100%",
    borderRadius: "6px",
    textAlign: "start",
} as const;
// Customizable Area End

export class TermDetails extends TermDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { termDetailsList, gradesList, openTermAlertModal } = this.state;
        return (
            <>
                {this.state.showLoader && (
                    <Spinner spinnerModal={this.state.showLoader} />
                )}

                <div className={`enter-grading-scale-text ${this.props.classes.heading_font}`}>
                    Enter Term Details
                </div>

                <div>
                    {termDetailsList?.map((termItem: any, index: number) => (
                        <React.Fragment key={termItem?.id}>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={3} lg={3} className="CreateReportCardFlexColumn">
                                    <div className="term-textfield-div">
                                        <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                            Enter term name
                                        </div>
                                        <TextField
                                            id="standard-basic"
                                            placeholder="Term name"
                                            style={form_input_text_field}
                                            variant="outlined"
                                            value={termItem?.termName}
                                            error={termItem?.isError}
                                            helperText={termItem?.isError ? "Term name is required" : ""}
                                            onChange={(e: any) => this.onChangeTermName(e, index)}
                                            inputProps={{ className: `term-text-field` }}
                                            data-test-id={`term-name-text-field-${index}`}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} className="CreateReportCardSelectClass">
                                    <div className="term-textfield-div">
                                        <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                            Select class
                                        </div>

                                        <Select
                                            className={`${this.props.classes.TermDetails_Feedback_selectDropDown} ${this.props.classes.bodyText_font} multi-dropdown-report`}
                                            value={termItem?.gradesList}
                                            onChange={(e: any) => this.onChangeTermClass(e, index, termItem)}
                                            variant="outlined"
                                            IconComponent={ArrowDropDownRoundedIcon}
                                            inputProps={{
                                                id: "select-multiple-native",
                                                style: {
                                                    borderColor: termItem?.gradesList
                                                        ? "1px solid red"
                                                        : "#ebebeb",
                                                    textTransform: "capitalize"
                                                },
                                            }}
                                            multiple
                                            displayEmpty
                                            MenuProps={{ getContentAnchorEl: null }}
                                            renderValue={() => {
                                                return termItem?.gradesList?.length === 0 ? "Select class" : termItem?.gradeName?.map((grade: any) => grade.attributes.name).join(", ");
                                            }}
                                            data-test-id={`term-class-select-${index}`}
                                        >
                                            <MenuItem disabled value="">Select Class</MenuItem>
                                            {gradesList.map((item: any) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    <ListItemText primary={item?.attributes?.name} />
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            className={this.props.classes.TermDetails_Feedback_term_checkbox}
                                                            checked={termItem?.gradesList?.indexOf(item.id) > -1}
                                                            style={{
                                                                color:
                                                                    termItem?.gradesList?.indexOf(item.id) > -1
                                                                        ? this.props.classes.TermDetails_Feedback_checkbox_color
                                                                        : "#3f526d",
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} className="CreateReportCardFlexColumn">
                                    <div className="term-textfield-div">
                                        <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                            Term start
                                        </div>
                                        <Datetime
                                            dateFormat={"DD-MM-YYYY"}
                                            timeFormat={false}
                                            strictParsing={true}
                                            input={true}
                                            value={moment(termItem?.sessionStart).format("DD-MM-YYYY")}
                                            className={"input_date_of_birth_css_format_admin"}
                                            inputProps={{
                                                placeholder: "DD-MM-YYYY",
                                                className: "Date_of_birth_calendar_Icon_register",
                                            }}
                                            closeOnClickOutside
                                            closeOnSelect
                                            onChange={(date) => this.onChangeSessionStartDate(date, index)}
                                            data-test-id={`term-start-date-time-${index}`}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} className="CreateReportCardFlexColumn">
                                    <div className="term-textfield-div">
                                        <div className={`${this.props.classes.subTitle_font} grade-detail-title`}>
                                            Term end
                                        </div>
                                        <Datetime
                                            dateFormat={"DD-MM-YYYY"}
                                            timeFormat={false}
                                            strictParsing={true}
                                            input={true}
                                            value={moment(termItem?.sessionEnd).format("DD-MM-YYYY")}
                                            className={"input_date_of_birth_css_format_admin"}
                                            inputProps={{
                                                placeholder: "dd-mm-yyyy",
                                                className: "Date_of_birth_calendar_Icon_register",
                                            }}
                                            closeOnClickOutside
                                            closeOnSelect
                                            onChange={(date) => this.onChangeSessionEndDate(date, index)}
                                            data-test-id={`term-end-date-time-${index}`}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <Divider className="term-divider" />
                            </Hidden>
                        </React.Fragment>
                    ))}
                </div>

                <Button
                    className={`${this.props.classes.btn_border} add-grade-btn`}
                    onClick={this.addTermClick}
                >
                    + Add Term
                </Button>

                <div className="grade-next-btn-div mb-20">
                    <Button className={`${this.props.classes.button_color} done-btn`}
                        disabled={this.checkForAddedTerms()}
                        onClick={this.saveTermDetails}>
                        Save
                    </Button>
                </div>

                <TermAlertModal
                    openTermAlertModal={openTermAlertModal}
                    onCloseModal={this.onCloseTermAlertModal}
                    submitYesClick={this.enableEditChanges}
                />
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export default withStyles(themeCreateStyle)(TermDetails as React.ComponentType<any>);
// Customizable Area End
