//@ts-nocheck
// Customizable Area Start
import React from "react";
import InputBase from "@material-ui/core/InputBase";
import {
  IconButton,
  NativeSelect} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import SettingsIcon from "@material-ui/icons/Settings";
import { ArrowDropDown, EditOutlined } from "@material-ui/icons";
import { Box, Checkbox, Button, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CreateUnitController, { Props } from "./CreateUnitController.web";
import "./CreateUnit.web.css";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Spinner from "../../../shared/SideBar/src/Spinner";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { ColorPicker, createColor } from "material-ui-color";
import { ColorButton } from "material-ui-color";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from "lodash";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
// Customizable Area Start
export default class CreateUnit extends CreateUnitController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleClose = () => {
    this.setState({ openModal: false });
    this.setState({ rows: [] })
  };

  handleRowDelete = (idx: any) => {
    const index = idx;
    if (index > -1) {
      this.state.rows.splice(index, 1);
    }
    this.setState({ rows: [...this.state.rows] })
  };

  handleEditGradeDelete = (index: any, id) => {
    let data = [...this.state.isDestoryData]
    data = data.map((item, i) => {

      if (item.id === id) {
        return { ...item, _destroy: true }
      }
      return item;
    })

    this.setState({ isDestoryData: data }, () => {
      let arr = [];
      this.state.isDestoryData?.map((item) => {
        if (item._destroy === false) {
          arr.push(false)
        }
      })

      if (arr.length === 1) {
        this.setState({
          deleteIconHidden: false
        })
      }
    })
  }

  editDetail = () => {
    this.state.createGradeScale && this.state.createGradeScale.map((elem: any) => {
      if (elem.id == this.state.selectedGradeId) {
        this.setState({ bandName: elem.name })
      }
    })
  }
  addScale = () => {
    const item = {
      name: "",
      grade: "",
      to: "",
      from: "",
      color_code: "#1569a8"
    };
    this.setState({
      rows: [...this.state.rows, item]
    });
  };

  handleChange = (e: any, idx: any) => {
    let arr = []
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    const list = [...rows];
    list[idx][name] = value;
    this.setState({ rows: list }, () => {
      rows?.map((row) => { if (row.from !== "") { arr.push(row.from) } })

      let i = arr.length;

      let range = _.inRange(rows[i - 1]?.from, rows[i - 2]?.from, rows[i - 2]?.to)
      if (range) {
        toast.error(`${rows[i - 1]?.from} is already used in the previous range.`)
      }
    })
  }


  defaultAdd = (e: any, idx: any) => {
    this.setState({ showColor: true });
  };
  handleAdd = (e: any, idx: any) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    rows[idx] = {
      [name]: value
    };
    this.setState({
      rows
    });
  };

  closeColor = () => {
    this.setState({ showColor: false })
  }

  handleGradeScale = (event: any, id: number, data: any, index: any) => {
    let selectedGrades: any = []
    let selectgrade = event.target.value
    this.setState({
      selectedGradeData: selectgrade,
      selectedGradeIds: [...this.state.selectedGradeIds, event.target.value]
    });
    selectedGrades = [...this.state.gradeN];
    selectedGrades[index] = event.target.value;
    this.setState({
      gradeN: selectedGrades
    })
  }

  handleDelete = () => {
    this.setState({ showDeleteAlert: true });
  }

  render() {
    const BootstrapInput3 = withStyles((theme) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: "relative",
        border: this.state.classerror ? "1px solid red" : "solid 1px #ebebeb",
        backgroundColor: "#fff",
        padding: "13px 22px",
        width: 244,
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: "Open sans",
        color: "#000",
        fontWeight: 600,
        fontSize: 18,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);

    const BootstrapInput2 = withStyles((theme) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: "relative",
        border: this.state.subjecterror ? "1px solid red" : "solid 1px #ebebeb",
        backgroundColor: "#fff",
        padding: "13px 22px",
        width: 244,
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: "Open sans",
        color: "#000",
        fontWeight: 600,
        fontSize: 18,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);
    const BootstrapInput = withStyles((theme) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 8,
        position: "relative",
        border: this.state.hourerror ? "1px solid red" : "solid 1px #ebebeb",
        backgroundColor: "#fff",
        padding: "13px 22px",
        width: 244,
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: "Open sans",
        color: "#000",
        fontWeight: 600,
        fontSize: 18,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);
    const BootstrapInput5 = withStyles((theme: any) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 9,
        position: "relative",
        border: "solid 1px #e6e6e6",
        backgroundColor: "#fff",
        padding: "10px 23.2px 11px 17.5px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: theme.props.textFont,
        color: theme.props.textColor,
        fontWeight: 600,
        fontSize: 18,
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);

    const today = moment().subtract(1, "day");
    const startdate = moment(this.state.startdate, "DD/MM/YYYY");
    const { cattegoriesArray, openModalNewCat, categoeryName, createGradeScale, isDestoryData } = this.state;
    return (
      <>
        <Modal className="manageBoxContent"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.props.unitModal}
          onClose={this.modalhandleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={this.props.unitModal} style={{ border: "none" }}>
            <div className="managePopup">
              {this.state.showSpinner && (
                <Spinner spinnerModal={this.state.showSpinner} />
              )}
              <Box className="manageTitle">
                <ArrowBackIcon className="manageIcon" onClick={this.modalhandleClose} />
                <Box className="center">
                  <Typography className="manageCategoryTitle">
                    Manage Category
                  </Typography>
                </Box>
                <Box className="flexBox">
                  {cattegoriesArray?.length !== 0 ?
                    <IconButton disabled={this.state.gradeSelected === true ? false : true}>
                      <DeleteOutlineIcon className={`deleteIcon ${this.state.gradeSelected === true ? "gradeSelectTrue" : "gradeSelectFalse"}`}
                        onClick={() => { this.setState({ showDeleteAlert: true, deleteWarning: false }, () => { this.deleteCategoryText() }) }}
                      />
                    </IconButton>
                    :
                    ""
                  }
                  <IconButton>
                    <SettingsIcon className="settingIcon"
                      onClick={() => {
                        this.setState({ openSettingModal: true });
                      }}
                    />
                  </IconButton>
                </Box>
              </Box>
              {cattegoriesArray?.length !== 0 ?
                <Box className="taskTypeBox">
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                      <Box className="taskTypeBoxText">
                        <Typography className="taskTypeText">
                          Task Type
                        </Typography>
                        {cattegoriesArray.map((data: any, index: number) => (
                          <Grid className="checkboxContent"
                            container spacing={2}>
                            <Grid item xs={1}>
                              <Checkbox className="p0"
                                checked={data.selectedCateogry}
                                onChange={(event: any) => {
                                  let arr = this.state.cattegoriesArray;
                                  arr[index].selectedCateogry =
                                    event.target.checked;
                                  this.setState({ cattegoriesArray: arr });
                                  this.setState({ gradeSelected: arr[index].selectedCateogry })
                                }}
                              />
                            </Grid>
                            <Grid item xs={11}>
                              <TextField className="taskCategoryTextfield"
                                disabled
                                id="standard-basic"
                                variant="outlined"
                                value={data.attributes.name}
                                onChange={(event: any) => {
                                  let arr = this.state.cattegoriesArray;
                                  if (data.type === "task_category") {
                                    arr[index].attributes.name =
                                      event.target.value;
                                    arr[index].error = false;
                                    arr[index].type = "edit";
                                    this.setState({ cattegoriesArray: arr });
                                  } else {
                                    arr[index].attributes.name =
                                      event.target.value;
                                    arr[index].error = false;
                                    this.setState({ cattegoriesArray: arr });
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="selectGradeBox">
                        <Typography className="selectGradeBoxTitle">
                          Select Grade Scale
                        </Typography>
                        {cattegoriesArray &&
                          cattegoriesArray.map((data: any, index: number) => (
                            <Grid
                              key={index}
                              container
                              spacing={2} className="selectGrid">
                              <Grid item xs={11}>
                                <Select
                                  id="standard-basic"
                                  className="selectanyGradeMenu"
                                  variant="outlined"
                                  value={this.state.gradeN[index]}
                                  onChange={(e) => {
                                    this.handleGradeScale(
                                      e,
                                      data.attributes.grade.grade_id,
                                      data,
                                      index
                                    );
                                  }}
                                >
                                  <MenuItem value="select grade" disabled>
                                    Select any grade
                                  </MenuItem>
                                  {createGradeScale &&
                                    createGradeScale.map((index: any) => (
                                      <MenuItem key={index.id} value={index.id}>
                                        {index.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </Grid>
                            </Grid>
                          ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                :
                <Box className="center">
                  <Typography className="noRecordText">
                    No record(s) found
                  </Typography>
                </Box>
              }
              <Box>
                <Grid className="addNewCategoryContainer" item xs={12} spacing={2} container/>
              </Box>
              <Box>
                <Grid className="addNewCategoryContainer" item xs={12} spacing={2} container/>
              </Box>
              <Box className="overFlowxy">
                <Grid className="addNewBoxGrid" container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button className="addNewCategoryButton"
                      onClick={() => {
                        this.setState({
                          openModalNewCat: true,
                        });
                      }}>
                      Add New Category
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button className="addNewCategoryButton createNewButton"
                      onClick={() => {
                        this.setState({ openModal: true });
                      }}
                    >
                      Create New Scale
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              {cattegoriesArray?.length !== 0 ?
                <Box className="overFlowxy">
                  <Grid className="centerM10" container spacing={2}>
                    <Grid item xs={6}>
                      <Button className={`saveButton ${this.state.gradeSelected === true ? "activeBtn" : "disableBtn"}`}
                        disabled={this.state.gradeSelected === true ? false : true} onClick={this.taskCategoeryGradeScales}>
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button className="cancelButton" onClick={this.modalhandleClose}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                :
                ''
              }
            </div>
          </Fade>
        </Modal>
        <Modal className="createNewScaleContent"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openModal}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          >
          <Fade in={this.state.openModal} style={{ border: "none" }}>
            <div className="managePopup">
              {this.state.showSpinner && (
                <Spinner spinnerModal={this.state.showSpinner} />
              )}
              <Box className="manageTitle">
                <Box className="center">
                  <Typography className="cNewScaleTitle">
                    Create New Scale
                  </Typography>
                </Box>
                <Box className="closeBox">
                  <CloseIcon onClick={this.handleClose} className="closeIcon" />
                </Box>
              </Box>
              <Box>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="performanceBandsTitle">
                      Add New Performance Bands
                    </Typography>
                    <TextField
                      id="standard-basic"
                      placeholder="Enter"
                      variant="outlined"
                      onChange={(e) => {
                        this.setState({ gradeName: e.target.value });
                      }}
                    />
                    <Box className="tabularBox">
                      <TableContainer className="tableContent">
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell className="cellTitle">Name</TableCell>
                              <TableCell align="center" className="cellTitle">Grade Scale</TableCell>
                              <TableCell align="center" className="cellTitle">From</TableCell>
                              <TableCell align="center" className="cellTitle">To</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state && this.state.rows.map((item: any, idx: any) => (
                              <TableRow>
                                <TableCell className="tableCellBorder">
                                  <Grid className="flexCenter" container item xs={12} spacing={1}>
                                    <Grid item xs={3}>
                                      <Box className="clearBox">
                                        <ClearIcon className="clearIcon" onClick={this.closeColor}
                                        />
                                        <input className="clearInput"
                                          type="color"
                                          name="color_code"
                                          value={item.color_code}
                                          onChange={(e: any) => { this.handleChange(e, idx) }}
                                        />
                                      </Box>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <TextField
                                        id="standard-basic"
                                        placeholder="Add name"
                                        name="name"
                                        value={item.name}
                                        onChange={(e: any) => { this.handleChange(e, idx) }}
                                        className="form-control "
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell align="center"  className="tableCellBorder">
                                  <TextField
                                    id="standard-basic"
                                    placeholder="Add grade"
                                    name="grade"
                                    value={item.grade}
                                    onChange={(e: any) => { this.handleChange(e, idx) }}
                                    className="form-control"
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center" className="tableCellBorder">
                                  <TextField
                                    id="standard-basic"
                                    placeholder="Enter number"
                                    name="from"
                                    value={item.from}
                                    onChange={(e: any) => { this.handleChange(e, idx) }}
                                    className="form-control"
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    type="number"
                                  />
                                </TableCell>
                                <TableCell align="center" className="tableCellBorder">
                                  <Grid container className="flexCenter" item xs={12} spacing={1}>
                                    <Grid item xs={10}>
                                      <TextField
                                        id="standard-basic"
                                        placeholder="Enter number"
                                        name="to"
                                        value={item.to}
                                        onChange={(e: any) => { this.handleChange(e, idx) }}
                                        className="form-control"
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                        type="number"
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <CloseIcon className="closeIcon" onClick={() => { this.handleRowDelete(idx) }} />
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" className="tableCellBorder">
                                <Grid xs={12} className="flexCenter">
                                  <Grid xs={2} item>
                                    <Box className="center">
                                      <img src="plus_dashed_icon.webp" height="50px" width="55px" />
                                    </Box>
                                  </Grid>
                                  <Grid xs={10}>
                                    <AddIcon className="closeIcon" onClick={this.addScale} />
                                  </Grid>
                                </Grid>
                              </TableCell >
                              <TableCell className="tableCellBorder" align="center">
                                <AddIcon className="closeIcon" onClick={this.addScale} />
                              </TableCell >
                              <TableCell className="tableCellBorder" align="center">
                                <AddIcon className="closeIcon" onClick={this.addScale} />
                              </TableCell>
                              <TableCell className="tableCellBorder" align="center">
                                <AddIcon onClick={this.addScale} className="closeIcon" />
                              </TableCell >
                            </TableRow >
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Grid className="saveCancelRow" container spacing={2}>
                <Grid item xs={6}>
                  <Button className="saveBtn" onClick={this.createGradeScales} variant="contained">
                    save
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button className="cancelBtn" onClick={this.handleClose}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
        <Modal className="gradeScaleContainer"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openSettingModal}
          onClose={() => this.setState({ openSettingModal: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={this.state.openSettingModal} style={{ border: "none" }}>
            <div className="managePopup">
              {this.state.showSpinner && (
                <Spinner spinnerModal={this.state.showSpinner} />
              )}
              <Box className="manageTitle">
                <Box className="center">
                  <Typography className="gradeTitle">
                    Grade Scale Settings
                  </Typography>
                </Box>
                <Box className="fCenter">
                  <CloseIcon className="pointer" onClick={() => this.setState({ openSettingModal: false })} />
                </Box>
              </Box>
              <Box className="performanceBandBox">
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Grid className="performanceBoxGrid"
                      container
                      item
                      xs={12}
                      spacing={2}
                    >
                      <Grid item xs={7}>
                        <Typography className="performanceTitle">
                          Performance Bands
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <NativeSelect
                          className="selectMenu"
                          id="select"
                          value={this.state.selectedGradeId}
                          input={<BootstrapInput5 />}
                          IconComponent={ArrowDropDown}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            this.setState({ selectedGradeId: e.target.value });
                            this.getGradeScaleByGradeId(e.target.value)
                          }}
                        >
                          <option value="">Please Select</option>
                          {
                            createGradeScale && createGradeScale?.map((grade: any, index: any) => (
                              <option value={grade.id}>{grade.name}</option>
                            ))
                          }
                        </NativeSelect>
                      </Grid>
                      <Grid item xs={1} className="fCenter">
                        <Box className="editBtnBox" onClick={() => this.setState({ openEditModal: true })}>
                          <EditOutlined className="editOutlineIcon" onClick={this.editDetail} />
                        </Box>

                        <Box className="deleteIconBox">
                          <DeleteOutlineIcon className="deleteOutlineIcon"
                            onClick={() => { this.setState({ scaleSettingDelete: true }) }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box className="gradeScaleTableBox">
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className="tableCellHead">
                                Name
                              </TableCell>
                              <TableCell className="tableCellHead" align="center">
                                Grade Scale
                              </TableCell>
                              <TableCell className="tableCellHead" align="center">
                                From
                              </TableCell>
                              <TableCell className="tableCellHead" align="center">
                                To
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {this.state.performaceBands?.attributes?.settings?.data?.length > 0 ?
                            this.state.performaceBands?.attributes?.settings?.data?.map((item: any) => {
                              return (
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <Grid container item xs={12} spacing={1} className="flexCenter">
                                        <Grid item xs={2}>
                                          <ColorButton color={item?.attributes?.color_code} />
                                        </Grid>
                                        <Grid item xs={10}>
                                          {` ${item?.attributes?.name}`}
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                    <TableCell align="center" className="grayBorder">
                                      {item?.attributes?.grade ? item?.attributes?.grade : "No data found"}
                                    </TableCell>
                                    <TableCell align="center" className="grayBorder">
                                      {item?.attributes?.from}
                                    </TableCell>
                                    <TableCell align="center" className="greyBorder">{item?.attributes?.to}</TableCell>
                                  </TableRow>
                                </TableBody>
                              )
                            })
                            :
                            (
                              <div className="noDataFoundText">No Data Found</div>
                            )}

                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Grid>
                <Grid className="okBtnRow" container spacing={2}>
                  <Grid item xs={6}>
                    <Button className="okBtn" onClick={() => { this.setState({ openSettingModal: false }) }} variant="contained">
                      Ok
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button onClick={() => this.setState({ openSettingModal: false })}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Fade>
        </Modal>
        <Modal className="editModalPopup"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openEditModal}
          onClose={() => this.setState({ openEditModal: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openEditModal} style={{ border: "none" }}>
            <div className="managePopup">
              {this.state.showSpinner && (
                <Spinner spinnerModal={this.state.showSpinner} />
              )}
              <Box className="manageTitle">
                <Box className="center">
                  <Typography className="editTitle">
                    Edit
                  </Typography>
                </Box>
                <Box className="fCenter">
                  <CloseIcon className="pointer" onClick={() => this.setState({ openEditModal: false, deleteIconHidden: true }, () => this.selectGradeScale())} />
                </Box>
              </Box>
              <Box className="editAttirbuteBox">
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Grid className="editAttributerGrid"
                      container
                      item
                      xs={12}
                      spacing={2}
                      >
                      <Grid item xs={7}>
                        <Typography className="editAttributeName">
                          {this.state.bandName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box className="editTableBox">
                      {this.state.performaceBands?.attributes?.settings?.data?.length > 0 ?
                        <TableContainer className="editTableContent">
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell className="tableCellTitle">Name</TableCell>
                                <TableCell align="center" className="tableCellTitle">Grade Scale</TableCell>
                                <TableCell align="center" className="tableCellTitle">From</TableCell>
                                <TableCell align="center" className="tableCellTitle">To</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {isDestoryData?.length ?
                                isDestoryData?.filter((item) => !item._destroy).map((item: any, index: any) => {
                                  return (
                                    <TableRow >
                                      <TableCell className="tableCellRow">
                                        <Grid container item xs={12} spacing={1} className="fCenter">
                                          <Grid item xs={2}>
                                            {item.id === this.state.editSelected ?
                                              <ColorPicker
                                                value={item.id == this.state.editSelected && !this.state.fieldDisabled ? createColor(this.state.color_code) : createColor(item?.attributes?.color_code)}
                                                hideTextfield
                                                onChange={color => {
                                                  const dataObj = isDestoryData.find((each: any) => each.id === this.state.editSelected)
                                                  dataObj.attributes.color_code = color.css.backgroundColor
                                                  this.setState({ color_code: color.css.backgroundColor })
                                                }}
                                              />
                                              :
                                              <ColorButton color={item?.attributes?.color_code} />
                                            }
                                          </Grid>
                                          <Grid item xs={8}>
                                            <TextField
                                              id="standard-basic"
                                              placeholder="Add name"
                                              variant="outlined"
                                              disabled={item.id == this.state.editSelected && !this.state.fieldDisabled ? false : true}
                                              value={item.id == this.state.editSelected && !this.state.fieldDisabled ? this.state.name : ` ${item?.attributes?.name}`}
                                              onChange={(e) => {
                                                const dataObj = isDestoryData.find((each: any) => each.id === this.state.editSelected)
                                                dataObj.attributes.name = e.target.value
                                                this.setState({ name: e.target.value })
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={2}>
                                            <EditOutlined className="editOutlineIcon"
                                              onClick={() => {
                                                this.setState({
                                                  fieldDisabled: false,
                                                  editSelected: item.id,
                                                  color_code: item?.attributes?.color_code,
                                                  from: item?.attributes?.from,
                                                  grade: item?.attributes?.grade,
                                                  grade_scale_id: item.attributes.grade_scale_id,
                                                  name: item?.attributes?.name,
                                                  to: item?.attributes?.to,
                                                })
                                              }} />
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                      <TableCell align="center" className="tableCellRow">
                                        <TextField
                                          id="standard-basic"
                                          placeholder="Add grade"
                                          variant="outlined"
                                          disabled={item.id == this.state.editSelected && !this.state.fieldDisabled ? false : true}
                                          value={item.id == this.state.editSelected && !this.state.fieldDisabled ? this.state.grade : ` ${item?.attributes?.grade ? item?.attributes?.grade : ""}`}
                                          onChange={(e) => {
                                            const dataObj = isDestoryData.find((each: any) => each.id === this.state.editSelected)
                                            dataObj.attributes.grade = e.target.value
                                            this.setState({ grade: e.target.value })
                                          }}
                                       />
                                      </TableCell>
                                      <TableCell className="tableCellRow">
                                        <TextField
                                          id="standard-basic"
                                          placeholder="Enter number"
                                          variant="outlined"
                                          disabled={item.id == this.state.editSelected && !this.state.fieldDisabled ? false : true}
                                          value={item.id == this.state.editSelected && !this.state.fieldDisabled ? this.state.from : ` ${item?.attributes?.from ? item?.attributes?.from : ""}`}
                                          onChange={(e) => {
                                            const dataObj = isDestoryData.find((each: any) => each.id === this.state.editSelected)
                                            dataObj.attributes.from = parseInt(e.target.value)
                                            this.setState({ from: e.target.value }, () => {
                                              if (isNaN(this.state.from)) { toast.error("Input must a number only") }
                                            })
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell align="center" className="tableCellRow">
                                        <Grid container item xs={12} spacing={1} className="fCenter">
                                          <Grid item xs={10}>
                                            <TextField
                                              id="standard-basic"
                                              placeholder="Enter number"
                                              variant="outlined"
                                              disabled={item.id == this.state.editSelected && !this.state.fieldDisabled ? false : true}
                                              value={item.id == this.state.editSelected && !this.state.fieldDisabled ? this.state.to : ` ${item?.attributes?.to ? item?.attributes?.to : ""}`}
                                              onChange={(e) => {
                                                const dataObj = isDestoryData.find((each: any) => each.id === this.state.editSelected)
                                                dataObj.attributes.to = parseInt(e.target.value)
                                                this.setState({ to: e.target.value }, () => {
                                                  if (isNaN(this.state.to)) { toast.error("Input must a number only") }
                                                })
                                              }}
                                            />
                                          </Grid>
                                          {
                                            ((this.state.isDestoryData.length > 1) && (this.state.deleteIconHidden)) ?
                                              <Grid item xs={2}>
                                                <CloseIcon className="closeIcon"
                                                  onClick={() => { this.handleEditGradeDelete(index, item.id) }} />
                                              </Grid>
                                              :
                                              ""
                                          }
                                        </Grid>
                                      </TableCell>
                                    </TableRow>
                                  )
                                })
                                :
                                (
                                  <div className="noDataFoundText">No Data Found</div>
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        :
                        <div className="noDataFoundText">No Data Found</div>
                      }
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="editSaveCancelRow">
                  <Grid item xs={6}>
                    <Button onClick={this.editGradeScales} variant='contained' className="editSaveBtn">
                      Save
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button className="editCancelBtn"
                      onClick={() => {
                        this.setState({
                          openEditModal: false,
                          fieldDisabled: true,
                          editSelected: null,
                          color_code: "",
                          from: 0,
                          grade: "",
                          grade_scale_id: 0,
                          name: "",
                          to: 0,
                          deleteIconHidden: true
                        }, () => this.selectGradeScale())
                        this.tempData = JSON.parse(JSON.stringify(this.state.performaceBands))
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Fade>
        </Modal>
        <Modal open={this.state.openModalNewCat} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Fade in={this.state.openModalNewCat}>
            <Box className="commonPopup">
              <Box className={"titleMain"}>Add New Category</Box>
              <TextField
                id="standard-basic"
                placeholder="Enter New Category"
                variant="outlined"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.setState({ categoeryName: event.target.value });
                  if (event.target.value === null) {
                    this.setState({ categoryError: true, categoryErrorText: 'Please enter category name' });
                  } else { this.setState({ categoryError: false, categoryErrorText: '' }) }
                }}
                error={this.state.categoryError}
                helperText={this.state.categoryErrorText}
              />
              <Box className="bottomButton reverseFlex">
                <Button onClick={this.add_Category}> Create </Button>
                <Button className="greyButton" onClick={() =>
                    this.setState({
                      openModalNewCat: false,
                    })}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <Dialog className="deleteTaskDialog" open={this.state.showDeleteAlert} fullWidth={true}>
          <DialogTitle>Delete Task</DialogTitle>
          <DialogContent className="msgTitle">Are you sure you want to delete category ?</DialogContent>
          {this.state.deleteWarning &&
            <DialogContent className="redMessage">If you delete this category, all tasks associated with this category will be deleted.
            </DialogContent>
          }
          <DialogActions className="dialogAction">
            <Button onClick={this.deleteCategory} className="deleteOkBtn">ok</Button>
            <Button onClick={() => { this.setState({ showDeleteAlert: false }) }} >
              cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog className="deleteTaskDialog" open={this.state.scaleSettingDelete} fullWidth={true}>
          <DialogTitle>Delete Task</DialogTitle>
          <DialogContent className="msgTitle">Are you sure you want to delete grade ?</DialogContent>
          {this.state.gradeDeleteWarning == true ?
            <DialogContent className="redMessage">If you delete this grade, all tasks associated with this task will be deleted.</DialogContent>
            : ""
          }
          <DialogActions className="dialogAction">
            <Button className="deleteOkBtn" onClick={this.gradeScaleDelete}>ok</Button>
            <Button className="okCancelBtn" onClick={() => { this.setState({ scaleSettingDelete: false }) }}>
              cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area End