// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { nounCheckSVG, nounCloseSVG } from "../assets";
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End
interface Props {
    // Customizable Area Start
    questionData: any;
    isTeacher: boolean;
    classes: any;
    // Customizable Area End
}

// Customizable Area Start
export const LabelImageQuestionType = ({
    questionData,
    isTeacher,
    classes,
}: Props) => {
    const [selectedImageBlobUrl, setSelectedImageBlobUrl] = useState<string>("");
    const [boxCoordinates, setBoxCoordinates] = useState<any>([]);

    useEffect(() => {
        const imageUrl = questionData?.attachements?.data?.[0]?.attributes?.url
        setSelectedImageBlobUrl(imageUrl)
        let savedInputBlocks: any = [];
        if (questionData.question_type === "label_image_with_drag_and_drop" || questionData.question_type === "label_image_with_dropdown") {
            const studentAnswers = questionData?.selected_options?.data?.attributes?.student_label_questions_answer;
            const answersList = questionData.answers?.filter((item: any) => !item.is_right)
            const rightAnswersList = questionData.answers?.filter((item: any) => item.is_right)
            questionData?.box_coordinates?.map((boxCoordinate: any) => {
                const answerItem = rightAnswersList?.[0]?.label_question_answer?.find((rightAnswer: any) => rightAnswer.box_uniq === boxCoordinate.box_uniq_key)
                const correctAnswer = answersList?.find((answer: any) => answer?.ans_uniq_id === answerItem?.answer_uniq_id)
                const studentAnswerItem = studentAnswers?.find((rightAnswer: any) => rightAnswer.box_uniq === boxCoordinate.box_uniq_key)
                const studentAnswer = answersList?.find((answer: any) => answer?.ans_uniq_id === studentAnswerItem?.answer_uniq_id)

                const item = {
                    id: boxCoordinate?.id,
                    uniqId: boxCoordinate?.box_uniq_key,
                    top: parseInt(boxCoordinate?.margin_top),
                    left: parseInt(boxCoordinate?.margin_left),
                    answer: correctAnswer?.answer,
                    attemptedAnswer: studentAnswer?.answer,
                    isAttemptedCorrect: !!(correctAnswer?.ans_uniq_id === studentAnswer?.ans_uniq_id)
                };
                savedInputBlocks.push(item);
            });

        } else if (questionData.question_type === "label_image_with_text") {
            const studentAnswers = questionData?.selected_options?.data?.attributes?.student_label_questions_answer;
            questionData?.box_coordinates?.map((boxCoordinate: any) => {
                const answerItem = questionData.answers?.[0]?.label_question_answer.find((answer: any) => answer.box_uniq === boxCoordinate.box_uniq_key);
                const studentAnswerItem = studentAnswers?.find((answer: any) => answer.box_uniq === boxCoordinate.box_uniq_key)
                const item = {
                    id: boxCoordinate?.id,
                    uniqId: boxCoordinate?.box_uniq_key,
                    top: parseInt(boxCoordinate?.margin_top),
                    left: parseInt(boxCoordinate?.margin_left),
                    answer: answerItem?.text_answer,
                    attemptedAnswer: studentAnswerItem?.text_answer,
                    isAttemptedCorrect: !!(answerItem?.text_answer.toLowerCase() === studentAnswerItem?.text_answer.toLowerCase())
                };
                savedInputBlocks.push(item);

            });
        }

        setBoxCoordinates(savedInputBlocks)
    }, [])

    return (
        <Grid container>
            <Grid item xs={12}>
                {selectedImageBlobUrl && (
                    <div className="label-image-div">
                        <div className="relative-position">
                            <img
                                src={selectedImageBlobUrl}
                                className="label-bg-image"
                            ></img>
                            {boxCoordinates?.length > 0 &&
                                boxCoordinates
                                    .filter((element: any) => !element._destroy)
                                    .map((item: any, index: number) => (
                                        <div
                                            key={item?.uniqId || index}
                                            style={{
                                                backgroundColor: item.isAttemptedCorrect ? "#def4df" : "#f7dee5",
                                                top: `${item.top}%`,
                                                left: `${item.left}%`,
                                            }}
                                            className={`label-image-answer ${classes.bodyText_font}`}
                                            id={`answer-${item.correctAnswerId}`}
                                        >

                                            <span className={`label-image-answer-text ${classes.bodyText_font}`}>
                                                {item.attemptedAnswer}
                                            </span>
                                            {item.isAttemptedCorrect ?
                                                <img src={nounCheckSVG} alt="check" className="right-check-icon absolute-icon" />
                                                :
                                                <img src={nounCloseSVG} alt="close" className="close-red-icon absolute-icon" />}

                                        </div>
                                    ))}
                        </div>
                    </div>
                )}
            </Grid>
            <div className="correct_answer_section">
                <Grid container>
                    <Grid item xs={12} sm={6} className="correct_answer_left">
                        {!questionData?.selected_options?.data &&
                            <Typography className={`not_attemped_text ${classes.bodyText_font}`}>Not attempted</Typography>
                        }
                        <Typography className={`correct_answer_text ${classes.heading_font}`}>Correct Answer</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className="score_section">
                        <Typography className={`correct_answer_text ${classes.heading_font}`}>Score</Typography>
                        <div className="correct_answer_rectangle">
                            <Typography className="score_text">
                                {questionData?.selected_options?.data?.attributes?.score ? questionData?.selected_options?.data?.attributes?.score : 0}
                            </Typography>
                        </div>
                        <Typography className="score_text"> / {questionData?.marks}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="sorting-correct-block">
                            {selectedImageBlobUrl && (
                                <div className="label-image-div">
                                    <div className="relative-position">
                                        <img
                                            src={selectedImageBlobUrl}
                                            className="label-bg-image"
                                        ></img>
                                        {boxCoordinates?.length > 0 &&
                                            boxCoordinates
                                                .filter((element: any) => !element._destroy)
                                                .map((item: any, index: number) => (
                                                    <div
                                                        key={item?.uniqId || index}
                                                        style={{
                                                            top: `${item.top}%`,
                                                            left: `${item.left}%`,
                                                        }}
                                                        className={`label-image-correct-ans ${classes.bodyText_font}`}
                                                        id={`answer-${item.correctAnswerId}`}
                                                    >
                                                        <span className={`label-image-answer-text ${classes.bodyText_font}`}>
                                                            {item.answer}
                                                        </span>
                                                    </div>
                                                ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Grid>
                    {(!isTeacher && questionData?.selected_options?.data?.attributes?.comments) &&
                        <Grid item xs={12}>
                            <Typography className={`title_teachercomment ${classes.bodyText_font}`}>Teacher Comment:
                                <span className={`textlabel_teachercomment ${classes.heading_font}`} dangerouslySetInnerHTML={{
                                    __html: questionData?.selected_options?.data?.attributes?.comments,
                                }}></span></Typography>
                        </Grid>
                    }
                </Grid>
            </div>
        </Grid>
    );
};
// Customizable Area End

// Customizable Area Start

export default withStyles(themeCreateStyle)(LabelImageQuestionType);
// Customizable Area End