// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    addDialog: any;
    handleDialogClose: any;
    isEdit: any;
    id: any;
    isPending: boolean;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start

    loading: boolean;
    dialogOpen: any;
    firstName: any;
    firstNameError: boolean;
    firstNameErrorMes: any;
    lastName: any;
    lastNameError: boolean;
    lastNameErrorMes: any;
    DOB: any;
    DOBError: boolean;
    DOBErrorMes: any;
    gender: any;
    genderError: boolean;
    genderErrorMes: any;
    phoneNo: any;
    phoneNoError: boolean;
    phoneNoErrorMes: any;
    emailId: any;
    emailIdError: boolean;
    emailIdErrorMes: any;
    classs: any;
    classsError: boolean;
    classsErrorMes: any;
    division: any;
    divisionError: boolean;
    divisionErrorMes: any;
    dialCode: any;
    classList: any;
    divisionList: any;
    loadingSpinner: boolean;

    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AddStudentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apigetStudentId: string = "";
    apigetSearchSubjectsId: string = "";
    apigetSubjectRemoveId: string = "";
    apigetTeacherClassesId: string = "";
    apigetStudentClasslId: string = "";
    apigetClasslId: any;
    apigetClassDivisionlId: any;
    addStudentApiId: any;
    editStudentApiId: any;
    apiresetPasswordId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {

            loading: false,
            dialogOpen: false,
            firstName: "",
            firstNameError: false,
            firstNameErrorMes: "",
            lastName: "",
            lastNameError: false,
            lastNameErrorMes: "",
            DOB: '',
            DOBError: false,
            DOBErrorMes: "",
            gender: "",
            genderError: false,
            genderErrorMes: "",
            phoneNo: "",
            phoneNoError: false,
            phoneNoErrorMes: "",
            emailId: "",
            emailIdError: false,
            emailIdErrorMes: "",
            classs: "",
            classsError: false,
            classsErrorMes: "",
            division: "",
            divisionError: false,
            divisionErrorMes: "",
            dialCode: 'in',
            classList: [],
            divisionList: [],
            loadingSpinner: false


        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.get_classes();
        if (this.props.isEdit) {
            this.get_student()
        }
    }

    get_classes = () => {
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");
        const header = {
            "Content-Type": configJSON.enrollContentType,
            school: data.school_id,
            "token": localStorage.getItem("token")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetClasslId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getGradesEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.enrollApiMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    get_division = (id: any) => {
        console.clear()
        const school_data = localStorage.getItem("user_data");
        const data = JSON.parse(school_data || "{}");

        const header = {
            "Content-Type": configJSON.enrollContentType,
            "token": localStorage.getItem("token")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetClassDivisionlId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDivisionEndPoint + `?grade_id=${id}&school_id=${data.school_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.instituteURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.enrollApiMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    onAddStudent = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const school_data = localStorage.getItem("user_data");
        const schoolData = JSON.parse(school_data || "{}");
        const adminId = localStorage.getItem("id")

        const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const { firstName, lastName, DOB, classs, division, gender, phoneNo, emailId } = this.state;
        if (firstName.length === 0 || emailId.length === 0 || lastName.length === 0 || DOB.length === 0 || division.length === 0 || classs.length === 0 || gender.length === 0 || phoneNo.length === 0) {
            if (
                this.state.firstName === "" || this.state.firstName.length === 0) {
                this.setState({ firstNameError: true, firstNameErrorMes: 'First name is required.' });
            }
            if (this.state.lastName === '' || this.state.lastName.length === 0) {
                this.setState({ lastNameError: true, lastNameErrorMes: 'Last name is required.' });
            }

            if (this.state.gender === '' || this.state.gender.length === 0) {
                this.setState({ genderError: true, genderErrorMes: 'Please select gender.' });
            }
            if (this.state.DOB === '' || this.state.DOB.length === 0) {
                this.setState({ DOBError: true, DOBErrorMes: 'DOB is required.' });
            }
            if (this.state.division === '' || this.state.division.length === 0) {
                this.setState({ divisionError: true, divisionErrorMes: 'Please select division.' });
            }
            if (this.state.classs === '' || this.state.classs.length === 0) {
                this.setState({ classsError: true, classsErrorMes: 'Please select class.' });
            }

            if (this.state.phoneNo === '' || this.state.phoneNo.length === 0) {
                this.setState({ phoneNoError: true, phoneNoErrorMes: 'Phone number is required.' });
            } else if (phoneNo.length < 7) {
                this.setState({ phoneNoError: true, phoneNoErrorMes: ' Enter a valid phone number.' });
            }

            if (this.state.emailId === '' || this.state.emailId.length === 0) {
                this.setState({ emailIdError: true, emailIdErrorMes: 'Email is required.' });
            } else if (!emailReg.test(emailId)) {
                this.setState({ emailIdError: true, emailIdErrorMes: ' Enter a valid email.' });
            }



            return false;
        }

        if (this.props.isEdit) {
            this.setState({ loadingSpinner: true })
            const data = {
                data: {
                    type: "student_account",
                    attributes:
                    {
                        school_id: schoolData.school_id,
                        // approved_by: adminId,
                        first_name: firstName,
                        last_name: lastName,
                        gender: gender,
                        // full_phone_number: phoneNo,
                        // email: emailId,
                        date_of_birth: DOB,
                        class_id: parseInt(classs),
                        grade_id: parseInt(classs),
                        division_id: parseInt(division)
                    }
                }
            }


            const header = {
                "Content-Type": configJSON.enrollContentType,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.editStudentApiId = requestMessage.messageId;

            const httpBody = data;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.editStudentEndPoint + `/${this.props.id}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.enrollApiPutMethodType
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestbaseURLMessage),
                configJSON.baseUrl
            );

            console.log("REQUEST: ", requestMessage);

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
        else {
            console.log("+++++")
            this.setState({ loadingSpinner: true })

            let arr = "";

            this.state.classList.filter((x: any) => x.id === classs)
                .map((x: any) => {
                    arr = x.attributes?.display_name 
                })

            const data = {
                data: {
                    type: "student_account",
                    attributes:
                    {
                        approved_by: adminId,
                        school_id: schoolData.school_id,
                        first_name: firstName,
                        last_name: lastName,
                        gender: gender,
                        full_phone_number: phoneNo,
                        email: emailId,
                        date_of_birth: moment(DOB, "DD/MM/YYYY").format("YYYY-MM-DD"),
                        grade_id: classs,
                        division_id: division,
                        display_name: arr,
                        role_name:"student"
                    }
                }
            }

            this.setState({ loading: true })
            const header = {
                "Content-Type": configJSON.enrollContentType,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.addStudentApiId = requestMessage.messageId;

            const httpBody = data;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.addStudentEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.enrollApiPostMethodType
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestbaseURLMessage),
                configJSON.baseUrl
            );

            console.log("REQUEST: ", requestMessage);

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }
    
    // Customizable Area End

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if (apiRequestCallId === this.apigetClasslId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        this.setState({ classList: responseJson.data })
                        console.log(responseJson)

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
            else if (apiRequestCallId === this.apigetClassDivisionlId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        this.setState({ divisionList: responseJson.data })
                        console.log(responseJson)

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
            else if (apiRequestCallId === this.apigetStudentClasslId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        console.log(responseJson)
                        if (responseJson.data != null) {
                            this.setState({
                                firstName: responseJson?.data.attributes.first_name,
                                lastName: responseJson?.data.attributes.last_name,
                                DOB: moment(responseJson?.data.attributes.date_of_birth).format("DD/MM/YYYY"),
                                gender: responseJson?.data.attributes.gender,
                                phoneNo: responseJson?.data.attributes.full_phone_number,
                                emailId: responseJson?.data.attributes.email,
                                classs: responseJson?.data.attributes.school_account.data === null ? "" : responseJson?.data.attributes?.school_account?.data[0]?.attributes?.grade?.id,
                                division: responseJson?.data.attributes.school_account.data === null ? "" : responseJson?.data.attributes?.school_account?.data[0]?.attributes?.division === null ? "" : responseJson?.data.attributes?.school_account?.data[0]?.attributes?.division.id

                            })
                            if (responseJson?.data.attributes.school_account.data !== null) {
                                if (responseJson?.data.attributes?.school_account?.data[0]?.attributes?.division !== null) {
                                    this.get_division(responseJson?.data.attributes?.school_account?.data[0]?.attributes?.class?.grade_id)
                                }
                            }
                            // this.get_student_subjects(responseJson?.data[0]?.id)
                        }

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                    this.setState({ loadingSpinner: false })
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
            else if (apiRequestCallId === this.addStudentApiId) {
                console.log("ID Match")
                if (responseJson != null) {
                    this.setState({ loadingSpinner: false })
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        console.log(responseJson)
                        if (responseJson.data != null) {
                            toast.success("Student added successfully.")
                            this.props.handleDialogClose()
                            // this.get_student_subjects(responseJson?.data[0]?.id)
                        }

                    } else {
                        console.log(responseJson.errors[0])
                        toast.error(responseJson.errors[0].message)
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
            else if (apiRequestCallId === this.editStudentApiId) {
                console.log("ID Match")
                if (responseJson != null) {

                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        console.log(responseJson)
                        if (responseJson.data != null) {
                            toast.success("Student updated successfully.")
                            this.props.handleDialogClose()
                            // this.get_student_subjects(responseJson?.data[0]?.id)
                        }

                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
            else if (apiRequestCallId === this.apiresetPasswordId) {
                console.log("ID Match")
                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        console.log(responseJson)
                        console.log(responseJson.message)
                        if (responseJson != null) {
                            toast.success(responseJson.message)

                            // this.get_student_subjects(responseJson?.data[0]?.id)
                        }

                    } else {
                        toast.error(responseJson.message)
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                console.log("responseJson nulll")
                this.parseApiCatchErrorResponse(errorReponse);
                this.setState({ loadingSpinner: false })
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    get_student = () => {
        this.setState({ loadingSpinner: true })
        const header = {
            "Content-Type": configJSON.enrollContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apigetStudentClasslId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAccountDetailsById + `${this.props.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.baseURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.enrollApiMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    reset_student_password = () => {
        let formData = new FormData();
        formData.append("account_id", this.props.id);
        const header = {
            // "Content-Type": configJSON.formDataContentType,
            token: localStorage.getItem('token'),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiresetPasswordId = requestMessage.messageId;
        // const httpBody = {
        //     formData,
        // }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.resetPasswordEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestbaseURLMessage),
            configJSON.baseUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.enrollApiPutMethodType
        );
        console.log('REQUEST: ', requestMessage);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}
