// Customizable Area Start
import React from "react";
import {
  Box,
  Modal,
  Divider,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import { robotWebcam, uploadSvg, uploadFile } from "./assets";
import { Close } from "@material-ui/icons";
import { FileDrop } from "react-file-drop";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
interface Props {
  // Customizable Area Start
  isTeacher: boolean;
  onCloseModal: any;
  openCreateVideoModal: boolean;
  recordVideoClick: any;
  openVideoBoothClick: any;
  showOpenVideoFileDlg: any;
  inputOpenVideoFileRef: any;
  inputOnChange: any;
  onFileDrop: any;
  videoDuration: number;
  // Customizable Area End
}

export const CreateVideoModal = ({
  // Customizable Area Start
  isTeacher,
  onCloseModal,
  openCreateVideoModal,
  recordVideoClick,
  openVideoBoothClick,
  showOpenVideoFileDlg,
  inputOpenVideoFileRef,
  inputOnChange,
  onFileDrop,
  videoDuration,
  // Customizable Area End
}: Props) => {
  // Customizable Area Start
  return (
    <>
      <Modal
        open={openCreateVideoModal}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="CreateVideoModal"
      >
        <Box className="create_video_modal">
          <Box display="flex" justifyContent="space-between">
            <Typography className="create_video_title">Create Video</Typography>

            <Typography
              id="modal-modal-title"
              component="h2"
              className="close_icon"
            >
              <Close onClick={onCloseModal} className="CreateVideoModalClose" />
            </Typography>
          </Box>

          <Divider className="divider_spacing" />
          <Grid className="upload_file_dashed_block">
            <FileDrop onDrop={onFileDrop}>
              <Box className="VideoDropDiv">
                <img
                  src={uploadFile}
                  className="upload_icon"
                  onClick={showOpenVideoFileDlg}
                />
                <input
                  ref={inputOpenVideoFileRef}
                  accept="video/*"
                  className="VideoHiddenInput"
                  id="contained-button-file"
                  type="file"
                  onChange={inputOnChange}
                />
                <Typography className="upload_file_text">
                  Upload File
                </Typography>

                <Typography className="drag_and_drop_instruction">
                  Drag and drop files here or browse them from your computer.
                  Supported videos format: MP4 or MOV (choose only 1 file),
                  which should not exceed 500MB.
                </Typography>
              </Box>
            </FileDrop>
          </Grid>

          <Grid className="CreateVideoDividerGrid">
            <Divider className="left_divider" />
            <Typography className="CreateVideoOrText">
              OR
            </Typography>
            <Divider className="right_divider" />
          </Grid>

          <Grid className="record_video_dashed_block">
            <Box className="RecordVideoBox">
              <img
                src={robotWebcam}
                className="record_icon"
                onClick={recordVideoClick}
              />
              <Typography className="record_your_video_text">
                Record your own video
              </Typography>

              <Typography className="video_length_text">
                Video length should be{" "}
                {videoDuration % 60 === 0
                  ? videoDuration / 60
                  : (videoDuration / 60).toFixed(1)}{" "}
                min long.
              </Typography>

              <Button
                variant="outlined"
                className="open_video_booth_btn"
                onClick={openVideoBoothClick}
              >
                Open video booth
              </Button>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
export default CreateVideoModal;
// Customizable Area End
