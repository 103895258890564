import React from "react";
// Customizable Area Start
import {
    StyleSheet,
    Platform,
} from "react-native";
import Picker from "emoji-picker-react";
import Grid from '@material-ui/core/Grid';
import Datetime from "react-datetime";
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    IconButton,
    Link,
    MenuItem,
    Select,
    NativeSelect,
    TextField,
    Typography,
    Popover
} from "@material-ui/core";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
    Search,
    Replay,
    NotificationsNone,
    HeadsetMicOutlined,
    Title,
    TitleOutlined,
    KeyboardArrowLeft,
    EditOutlined,
    MoreVertOutlined,
    KeyboardArrowDown,
    DesktopAccessDisabledOutlined,
    ArrowDropDown
} from "@material-ui/icons";
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import DateCalendarScreenController, { Props } from "./DateCalendarScreenController.web";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FileDrop } from 'react-file-drop';
import { grid } from "../../ClassesAndSections/src/Attendance/assets";
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    EmailShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    EmailIcon,
    WhatsappIcon,
} from "react-share";
import {
    facebook,
    sharechat,
    twitter,
    email,
    copylink,
    linkedin
} from './assets';
// Customizable Area End
// Customizable Area Start
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
// Customizable Area End
export default class DateCalendarScreen extends DateCalendarScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { date } = this.state
        return (
            <>
                <Popover
                    id="1"
                    anchorEl={this.props.datecalendarAnchorEl}
                    open={Boolean(this.props.datecalendarAnchorEl)}
                    onClose={this.props.popoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{
                        marginTop: '25px',
                    }}
                >
                    <Box style={{
                        padding: "20px 26.4px 25.7px 37px",
                        borderRadius: 8,
                        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
                        backgroundColor: "#fff"
                    }}>
                        <Typography
                            style={{
                                fontFamily: "Open sans",
                                fontSize: "1.25rem",
                                fontWeight: 600,
                                color: "#3f526d",
                            }}>
                            Calendar
                        </Typography>
                        <Calendar className='noborder'
                            value={moment(date).toDate()}
                            onChange={(date: any) => {
                                this.props.popoverClose(date)
                            }}
                            next2Label={null}
                            prev2Label={null}
                        />
                    </Box>
                </Popover>
            </>
        )
    }
    // Customizable Area End
}
