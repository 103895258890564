// Customizable Area Start
import React from "react";
import "./AdminReportCardTemplate.web.css";
import Button from "@material-ui/core/Button";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import { profile_logo } from "../assets";
import {
  FormControl,
  InputLabel,
  TextField,
  Box,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { HISTORY } from "../../../../components/src/comman";
import HOCAuth from "../../../shared/Header/src/HOCAuth.web";
import GradingSystemController, { Props } from './GradingSystemController.web';
import './AdminReportCardTemplate.web.css';
import Spinner from '../../../shared/SideBar/src/Spinner';
import { themeCreateStyle } from "../../../../components/src/CustomThemeStyles.web";
// Customizable Area End

class GradingSystem extends GradingSystemController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  render() {
    const handleBack = () => {
      HISTORY.goBack();
    }
    const { isLoader } = this.state
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          display: "flex",
          flexWrap: "wrap",
        },
        margin: {
          margin: theme.spacing(1),
        },
        withoutLabel: {
          marginTop: theme.spacing(3),
        },
        table: {
          minWidth: 700,
        },
      })
    );

    const StyledTableCell = withStyles((theme: Theme) =>
      createStyles({
        // head: {
        //   backgroundColor: theme.palette.primary.main,
        //   color: theme.palette.common.white,
        // },
        // body: {
        //   fontSize: 14,
        // },
      })
    )(TableCell);

    const StyledTableRow = withStyles((theme: Theme) =>
      createStyles({
        root: {
          "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      })
    )(TableRow);

    function createData(title: string, grade: string, achievement: string) {
      return { title, grade, achievement };
    }

    const rows = [
      createData("90% and above", "A+", "Outstanding"),
      createData("75% to 89%", "A", "Excellent"),
      createData("56% and 74%", "B", "Very Good"),
      createData("35% to 55%", "C", "Good"),
      createData("34% to below", "D", "Scope for improvement")
    ];
    const { reportGradeScaleData } = this.state
    const handleClick = () => {
      HISTORY.push({
        pathname: "/AdminReportCardTemplate",

      });
    };
    return (
      <>
        {isLoader && <Spinner spinnerModal={isLoader} />}
        <div>
          <div className="profileTitle">
          <Button className={`backBtn ${this.props.classes.bodyText_font}`} variant="text" onClick={() => handleBack()}><KeyboardBackspaceOutlinedIcon /> Back</Button>
            <h2 className={this.props.classes.heading_font}>Grading System</h2>
            <Button className={`editBtn ${this.props.classes.bodyText_font}`} onClick={() => handleClick()}>Done</Button>
          </div>
          <p className={this.props.classes.bodyText_font}>
            Grading scale for this report card:
          </p>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {
              reportGradeScaleData?.attributes?.report_grade_scales?.data?.length > 0 &&
              <TableContainer style={{ borderRadius: 6, border: 0 }}>
                <Table style={{ width: "calc(100% - 1px)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${this.props.classes.background_color} border-radius-0`} style={{ padding: "17px 20px", fontSize: 20, fontWeight: "bold" }}>Label</TableCell>
                      <TableCell className={this.props.classes.background_color} style={{ padding: "17px 20px", fontSize: 20, fontWeight: "bold" }}>Score</TableCell>
                      <TableCell className={this.props.classes.background_color} style={{ padding: "17px 20px", fontSize: 20, fontWeight: "bold" }}>Max Marks</TableCell>
                      <TableCell className={`${this.props.classes.background_color} border-radius-0`} style={{ padding: "17px 20px", fontSize: 20, fontWeight: "bold" }}>Min Marks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ marginTop: 10, backgroundColor: "#f9f8fd" }}>
                    {reportGradeScaleData?.attributes?.report_grade_scales?.data?.map((data: any, index: number) => (
                      <TableRow >
                        <TableCell className={`${this.props.classes.subTitle_font} border-radius-0`} style={{ padding: "17px 20px", border: "1px solid lightgray", fontSize: 18, fontWeight: 600 }}>{data.attributes.name}</TableCell>
                        <TableCell className={this.props.classes.subTitle_font} style={{ padding: "17px 20px", border: "1px solid lightgray", fontSize: 18, fontWeight: 600, textTransform: "capitalize" }}>{data.attributes.score}</TableCell>
                        <TableCell className={this.props.classes.subTitle_font} style={{ padding: "17px 20px", border: "1px solid lightgray", fontSize: 18, fontWeight: 600 }}>{data.attributes.max_marks}</TableCell>
                        <TableCell className={`${this.props.classes.subTitle_font} border-radius-0`} style={{ padding: "17px 20px", border: "1px solid lightgray", fontSize: 18, fontWeight: 600 }}>{data.attributes.min_marks}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Grid>
        </div>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(themeCreateStyle)(
  HOCAuth(GradingSystem as React.ComponentType<any>, "AdminAccount")
);
// Customizable Area End
